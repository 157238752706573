<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="173"
    height="173"
    viewBox="0 0 173 173"
  >
    <defs>
      <linearGradient id="viral-graph-1-c" x1="50%" x2="50%" y1="0%" y2="100%">
        <stop offset="0%" stop-color="#58A0F8" class="linear-gradient-color1" />
        <stop
          offset="100%"
          stop-color="#2176DD"
          class="linear-gradient-color2"
        />
      </linearGradient>
      <path
        id="viral-graph-1-b"
        d="M29.496754,77.5190212 L39.1321043,89.1874178 C31.6220229,95.3820418 25.5872277,103.201909 21.5252968,112.041095 L7.76926295,105.726763 C12.7863195,94.8091229 20.2321365,85.1608587 29.496754,77.5190212 Z"
      />
      <filter
        id="viral-graph-1-a"
        width="163.8%"
        height="157.9%"
        x="-31.9%"
        y="-23.2%"
        filterUnits="objectBoundingBox"
      >
        <feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur
          in="shadowOffsetOuter1"
          result="shadowBlurOuter1"
          stdDeviation="3"
        />
        <feComposite
          in="shadowBlurOuter1"
          in2="SourceAlpha"
          operator="out"
          result="shadowBlurOuter1"
        />
        <feColorMatrix
          in="shadowBlurOuter1"
          values="0 0 0 0 0.333333333 0 0 0 0 0.623529412 0 0 0 0 0.976470588 0 0 0 0.5 0"
          class="color-matrix"
        />
      </filter>
      <linearGradient id="viral-graph-1-f" x1="50%" x2="50%" y1="0%" y2="100%">
        <stop offset="0%" stop-color="#64A9FE" class="linear-gradient-color3" />
        <stop
          offset="100%"
          stop-color="#1865C4"
          class="linear-gradient-color2"
        />
      </linearGradient>
      <path
        id="viral-graph-1-e"
        d="M93.3375,134.83871 C124.986868,134.83871 150.64375,109.19618 150.64375,77.5645161 C150.64375,45.9328525 124.986868,20.2903226 93.3375,20.2903226 C61.6881321,20.2903226 36.03125,45.9328525 36.03125,77.5645161 C36.03125,109.19618 61.6881321,134.83871 93.3375,134.83871 Z M93.3375,134.83871 C124.986868,134.83871 150.64375,109.19618 150.64375,77.5645161 C150.64375,45.9328525 124.986868,20.2903226 93.3375,20.2903226 C61.6881321,20.2903226 36.03125,45.9328525 36.03125,77.5645161 C36.03125,109.19618 61.6881321,134.83871 93.3375,134.83871 Z M93.3375,134.83871 C124.986868,134.83871 150.64375,109.19618 150.64375,77.5645161 C150.64375,45.9328525 124.986868,20.2903226 93.3375,20.2903226 C61.6881321,20.2903226 36.03125,45.9328525 36.03125,77.5645161 C36.03125,109.19618 61.6881321,134.83871 93.3375,134.83871 Z"
      />
      <filter
        id="viral-graph-1-d"
        width="141%"
        height="141%"
        x="-20.5%"
        y="-18.8%"
        filterUnits="objectBoundingBox"
      >
        <feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur
          in="shadowOffsetOuter1"
          result="shadowBlurOuter1"
          stdDeviation="7.5"
        />
        <feComposite
          in="shadowBlurOuter1"
          in2="SourceAlpha"
          operator="out"
          result="shadowBlurOuter1"
        />
        <feColorMatrix
          in="shadowBlurOuter1"
          values="0 0 0 0 0.325490196 0 0 0 0 0.584313725 0 0 0 0 0.894117647 0 0 0 0.5 0"
          class="color-matrix"
        />
      </filter>
      <linearGradient
        id="viral-graph-1-g"
        x1="50%"
        x2="50%"
        y1="0%"
        y2="98.69%"
      >
        <stop offset="0%" stop-color="#FFF" />
        <stop
          offset="100%"
          stop-color="#D5E3F4"
          stop-opacity="0"
          class="linear-gradient-color2"
        />
      </linearGradient>
    </defs>
    <g fill="none" fill-rule="evenodd" transform="translate(-7 18)">
      <path
        fill="#E8EAEF"
        fill-rule="nonzero"
        d="M154.962437,102.814507 C157.828928,95.7192801 159.454218,88.1517636 159.738297,80.3612907 L170.442824,80.3612903 C170.152535,89.5832105 168.239939,98.5543929 164.828602,106.953543 L154.962438,102.814507 Z M159.781261,77.3612903 C159.720385,69.5150278 158.302303,61.9916682 155.75005,55.0141512 L165.647864,50.9253432 C168.715506,59.1648413 170.417822,68.0683892 170.479931,77.3612903 L159.781262,77.3612903 Z M154.655638,52.220353 C151.188296,43.927862 146.091598,36.4852917 139.765395,30.2923204 L146.9226,22.3293228 C154.425481,29.5886875 160.462329,38.3526146 164.546238,48.1345253 L154.655638,52.220353 Z M137.574755,28.2417643 C130.624775,22.0220638 122.361094,17.2385464 113.250836,14.3581347 L116.069899,4.0273505 C126.812476,7.35791717 136.552902,12.965229 144.726431,20.284919 L137.574755,28.2417643 Z M110.370208,13.5187103 C104.868431,12.0499399 99.0863514,11.2669355 93.12125,11.2669355 C88.4777243,11.2669355 83.9204192,11.7456186 79.5041736,12.6635718 L76.9693804,2.26339977 C82.2034369,1.14983439 87.6101239,0.568548387 93.12125,0.568548387 C100.061288,0.568548387 106.787912,1.48194542 113.187333,3.19502951 L110.370208,13.5187103 Z M76.5812781,13.3402443 C67.9018226,15.5570751 59.8322281,19.4871653 52.8078542,24.8176203 L46.1254037,16.4591513 C54.3591544,10.1652434 63.8417163,5.53452719 74.0476011,2.9446518 L76.5812781,13.3402443 Z M50.4593928,26.6843822 C43.3018636,32.6411167 37.3635829,40.1181868 33.156365,48.7477493 L23.5399717,44.0594164 C28.4525302,33.9831065 35.4004821,25.2603872 43.7776995,18.3268603 L50.4593928,26.6843822 Z M26.4793393,76.3612903 L15.7726297,76.3612903 C15.9685435,66.2734357 18.1038638,56.4735095 22.014142,47.381399 L31.7916895,51.738701 C28.4880662,59.4665557 26.6721876,67.7848544 26.479344,76.3612902 Z M26.4781335,79.3612904 C26.6796208,88.6565824 28.7874977,97.6491932 32.6287259,105.915639 L22.9202494,110.4219 C18.4266051,100.751434 15.9749426,90.2230804 15.7715882,79.3612904 L26.4781279,79.3612903 Z"
      />
      <g fill-rule="nonzero" transform="rotate(-51 23.45 94.78)">
        <use
          fill="#000"
          filter="url(#viral-graph-1-a)"
          xlink:href="#viral-graph-1-b"
        />
        <use fill="url(#viral-graph-1-c)" xlink:href="#viral-graph-1-b" />
      </g>
      <use
        fill="#000"
        filter="url(#viral-graph-1-d)"
        xlink:href="#viral-graph-1-e"
      />
      <ellipse
        cx="93.338"
        cy="77.565"
        rx="56.819"
        ry="56.79"
        fill="url(#viral-graph-1-f)"
        stroke="#181818"
        stroke-linejoin="round"
        stroke-opacity=".2"
      />
      <ellipse
        cx="93.338"
        cy="77.565"
        stroke="url(#viral-graph-1-g)"
        stroke-width="2"
        opacity=".402"
        rx="49.819"
        ry="49.79"
      />
      <path
        fill="#FFF"
        d="M92.0727539,97 L92.0727539,65.3154297 L80.5986328,65.3154297 L80.5986328,59.378418 L87.7353516,59.378418 C90.6884766,59.378418 92.3803711,57.7480469 92.3803711,54.7333984 L92.3803711,53.5336914 L99.7631836,53.5336914 L99.7631836,97 L92.0727539,97 Z"
      />
    </g>
  </svg>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "Level1",
});
</script>
