<template>
  <div class="supporter-registration-form__wrapper">
    <FormPresentationLayout
      :description-image-link="viewCopy.imageLink"
      :description-image-title="viewCopy.imageTitle"
      :description-text="viewCopy.imageDescription"
      :has-return-button="hasPreviousPage"
      content-vertical-alignment="top"
      @previous-page="onReturnButtonClick"
    >
      <template #content>
        <div class="supporter-registration-form__container">
          <ElForm
            ref="supporterRegistrationForm"
            :model="fields"
            class="supporter-registration-form__body"
            @validate="updateSubmitState"
          >
            <ElFormItem prop="additional_criteria">
              <CriteriaMultiSelector
                v-model="fields.additional_criteria"
                :values="eligibleAdditionalCriteriaValues"
                label="supporters.form.additionalInterests.label"
                placeholder="supporters.form.additionalInterests.placeholder"
                tip="supporters.form.additionalInterests.tip"
              />
            </ElFormItem>
          </ElForm>
        </div>
      </template>
    </FormPresentationLayout>
    <NavigationFooter>
      <template #left>
        <PxButton
          :loading="formLoading || viewLoading"
          class="navigation-footer__main-cta"
          type="primary"
          @click="onNextButtonClick"
        >
          {{ ctaCopy.next }}
        </PxButton>
        <PxButton v-if="skipEnabled" @click="onSkipButtonClick">
          {{ ctaCopy.skip }}
        </PxButton>
      </template>
      <template #right>
        <PxButton v-if="skipEnabled" type="link" @click="onSkipAllButtonClick">
          {{ ctaCopy.setupCriteriaLater }}
        </PxButton>
      </template>
    </NavigationFooter>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import SupportersFlowMixin from "@/modules/supporters/mixins/supporters-flow.mixin";
import FormPresentationLayout from "@/modules/supporters/components/form-presentation-layout/form-presentation-layout.vue";
import NavigationFooter from "@/modules/supporters/components/navigation-footer/navigation-footer.vue";
import CriteriaMultiSelector from "@/modules/supporters/components/criteria-multi-selector/criteria-multi-selector.vue";

import { IMultiSelectorItem } from "@/components/multi-selector/multi-selector.interface";
import { EAdditionalCriteriaGetter } from "@/modules/supporters/services/store/additional-criteria/additional-criteria.interface";
import { IMatchingResponse } from "@/services/data/matching-responses/matching-response.interface";

export default defineComponent({
  name: "CriteriaAdditionalInterests",

  components: {
    FormPresentationLayout,
    CriteriaMultiSelector,
    NavigationFooter,
  },

  mixins: [SupportersFlowMixin],

  data() {
    return {
      fields: {
        additional_criteria: [] as Array<IMatchingResponse>,
      },
    };
  },

  computed: {
    viewCopy() {
      return this.$tm("supporters.view.criteriaAdditionalInterests") as {
        imageTitle: string;
        imageDescription: string;
        imageLink: {
          original: string;
          retina: string;
        };
      };
    },

    additionalCriteriaValues(): Array<IMultiSelectorItem> {
      return this.$store.getters[
        EAdditionalCriteriaGetter.MULTI_SELECTOR_VALUES
      ];
    },

    eligibleAdditionalCriteriaValues(): Array<IMultiSelectorItem> {
      // Questions that haven't been made on the Question Bundle:
      const existingCriteria = this.flowData.criteria.map(
        (criteria) => criteria.question,
      );
      return this.additionalCriteriaValues.map((item) => ({
        ...item,
        children: (item?.children || []).filter(
          (child) => !existingCriteria.includes(Number(child.value)),
        ),
      }));
    },
  },

  mounted() {
    if (this.fields && this.flowData) {
      this.fields.additional_criteria = [...this.flowData.additional_criteria];
    }
  },
});
</script>

<style lang="scss" scoped>
.supporter-registration-form__wrapper :deep() {
  .form-presentation-layout__content-wrapper--with-return {
    padding: 43px 0 30px 59px;
  }

  .form-presentation-layout__content-body {
    margin-top: 32px;

    /* Add extra height to cautious for the popups */
    margin-bottom: 392px;
  }

  .form-presentation-layout__content-header-wrapper {
    padding-left: 0;
  }

  .form-presentation-layout__content-header {
    margin: 0;
  }

  .form-presentation-layout__description-wrapper {
    top: 0;
  }
}

.supporter-registration-form__container {
  display: grid;
  grid-gap: 40px;
  max-width: 470px;

  /* Allow popup overflow in peace */
  margin-bottom: 400px;
}

.supporter-registration-form__body {
  display: grid;
  grid-gap: 30px;
}

.supporter-registration-form__body :deep() {
  .el-form-item__label {
    padding: 0 12px 4px 0;
  }

  .el-input__inner {
    margin: 0;
    line-height: 40px;
  }

  .multi-selector__container {
    max-width: 360px;
  }
}
</style>
