import { GenericProvider } from "@/services/data/generic-provider";
import { getGenericInitialState } from "@/services/store/generic/generic-state";

import { GenericState } from "@/services/store/generic/generic-state.module";
import {
  EQuestionBundlesAction,
  EQuestionBundlesState,
  IQuestionBundlesState,
} from "@/modules/milestone-planner/services/store/milestone-planner/sub-modules/question-bundles/question-bundles.interface";
import { IQuestionBundle } from "@/services/data/question-bundle/question-bundle.interface";
import {
  EQuestionBundlesGetters,
  getters,
} from "@/modules/milestone-planner/services/store/milestone-planner/sub-modules/question-bundles/question-bundles.getters";
import {
  EQuestionBundlesMutation,
  mutations,
} from "@/modules/milestone-planner/services/store/milestone-planner/sub-modules/question-bundles/question-bundles.mutations";
import { actions } from "@/modules/milestone-planner/services/store/milestone-planner/sub-modules/question-bundles/question-bundles.actions";
import { questionBundleProvider } from "@/services/data/question-bundle/question-bundle.provider";

// initial state of the module
const initialState: IQuestionBundlesState = {
  ...getGenericInitialState<IQuestionBundle>(),
  currentPage: 1,
};

export class QuestionBundlesState extends GenericState<
  IQuestionBundlesState,
  IQuestionBundle
> {
  static Action = GenericState.mapNamespaceEnum(
    EQuestionBundlesAction,
    EQuestionBundlesState.NAMESPACE,
  );

  static Getter = GenericState.mapNamespaceEnum(
    EQuestionBundlesGetters,
    EQuestionBundlesState.NAMESPACE,
  );

  static Mutation = GenericState.mapNamespaceEnum(
    EQuestionBundlesMutation,
    EQuestionBundlesState.NAMESPACE,
  );

  constructor(provider: GenericProvider<IQuestionBundle>) {
    super({ provider });

    this.state = initialState;
    this.actions = actions;
    this.mutations = mutations;
    this.getters = getters;
  }
}

export const questionBundlesModule = new QuestionBundlesState(
  questionBundleProvider,
);
