import {
  GenericProvider,
  EProviderFeatures,
  IOptionsType,
} from "@/services/data/generic-provider";
import { baseAPIUrl } from "@/services/utils/utils";
import { IProgramAssessment } from "@/modules/affiliate-program/services/data/program-assessment/program-assessment.interface";

class ProgramAssessmentProvider extends GenericProvider<IProgramAssessment> {
  constructor() {
    super(`${baseAPIUrl}/program/assessment/`, [EProviderFeatures.CREATE]);
  }

  public create(data: IProgramAssessment, options?: IOptionsType) {
    // When there is no level selected the API expects to receive a
    // level null and not zero, so we need a conversion here.
    data.levels = data.levels.map((entry: any) => {
      entry.level = entry.level !== 0 ? entry.level : null;
      return entry;
    });

    return super.create(data, options);
  }
}

export const programAssessmentProvider = new ProgramAssessmentProvider();
