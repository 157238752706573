import { matchingModule } from "./matching/matching.module";
import { matchingQuestionaryModule } from "./matching-questionary/matching-questionary.module";
import { matchingInterestModule } from "./matching-interest/matching-interest.module";
import { matchingResponsesModule } from "@/modules/matching/services/store/matching-responses/matching-responses.module";

import { EMatchingCriteriaWeightState } from "@/modules/matching/services/store/matching-criteria-weight/matching-criteria-weight.interface";
import { matchingCriteriaWeightModule } from "@/modules/matching/services/store/matching-criteria-weight/matching-criteria-weight.module";
import { matchScoreDetailsModule } from "@/modules/matching/services/store/match-score-details/match-score-details.module";
import { EMatchScoreDetailsState } from "@/modules/matching/services/store/match-score-details/match-score-details.types";

export const modules = {
  matching: matchingModule,
  matchingQuestionary: matchingQuestionaryModule,
  matchingInterest: matchingInterestModule,
  matchingResponses: matchingResponsesModule,
  [EMatchScoreDetailsState.NAMESPACE]: matchScoreDetailsModule,
  [EMatchingCriteriaWeightState.NAMESPACE]: matchingCriteriaWeightModule,
};

// Modules wish the data must persist across sessions
export const toPersist = [
  "matchingResponses",
  EMatchingCriteriaWeightState.NAMESPACE,
];
