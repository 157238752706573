import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-fa1f4992"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "date-select__wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ElOption = _resolveComponent("ElOption")!
  const _component_ElSelect = _resolveComponent("ElSelect")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ElSelect, {
      modelValue: _ctx.innerValue.day,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.innerValue.day) = $event)),
      class: "date-select__day"
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.dayLimit, (day) => {
          return (_openBlock(), _createBlock(_component_ElOption, {
            key: day,
            value: day
          }, {
            default: _withCtx(() => [
              _createElementVNode("span", null, _toDisplayString(day), 1)
            ]),
            _: 2
          }, 1032, ["value"]))
        }), 128))
      ]),
      _: 1
    }, 8, ["modelValue"]),
    _createVNode(_component_ElSelect, {
      modelValue: _ctx.innerValue.month,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.innerValue.month) = $event)),
      class: "date-select__month",
      "popper-class": "date-select__popper"
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.monthLimit, (month, index) => {
          return (_openBlock(), _createBlock(_component_ElOption, {
            key: index,
            label: month,
            value: index
          }, {
            default: _withCtx(() => [
              _createElementVNode("span", null, _toDisplayString(month), 1)
            ]),
            _: 2
          }, 1032, ["label", "value"]))
        }), 128))
      ]),
      _: 1
    }, 8, ["modelValue"]),
    _createVNode(_component_ElSelect, {
      modelValue: _ctx.innerValue.year,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.innerValue.year) = $event)),
      class: "date-select__year",
      "popper-class": "date-select__popper"
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.years, (year) => {
          return (_openBlock(), _createBlock(_component_ElOption, {
            key: year,
            value: year
          }, {
            default: _withCtx(() => [
              _createElementVNode("span", null, _toDisplayString(year), 1)
            ]),
            _: 2
          }, 1032, ["value"]))
        }), 128))
      ]),
      _: 1
    }, 8, ["modelValue"])
  ]))
}