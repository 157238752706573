import { ActionTree } from "vuex";
import {
  EAuthLatestAssessmentActions,
  EAuthLatestAssessmentMutations,
  IAuthLatestAssessmentState,
} from "./auth-latest-assessment.types";
import { RootState } from "@/services/store/root-state";
import { getBaseName } from "@/services/store/utils/get-base-name";
import { latestAssessmentProvider } from "@/modules/profile/services/data/latest-assessment/latest-assessment.provider";

export const actions: ActionTree<IAuthLatestAssessmentState, RootState> = {
  /**
   * Get the latest assessment for the given company.
   *
   * @param param0 store context
   * @param authCompanyId
   */
  async [getBaseName(EAuthLatestAssessmentActions.FETCH)](
    { commit },
    authCompanyId: number,
  ) {
    commit(EAuthLatestAssessmentMutations.SET_LOADING, true);
    commit(EAuthLatestAssessmentMutations.SET_ERROR, null);

    try {
      const data = await latestAssessmentProvider.get(authCompanyId);
      commit(EAuthLatestAssessmentMutations.SET_DATA, data);
    } catch (error) {
      commit(EAuthLatestAssessmentMutations.SET_ERROR, error);
      commit(EAuthLatestAssessmentMutations.SET_DATA, null);
    } finally {
      commit(EAuthLatestAssessmentMutations.SET_LOADING, false);
    }
  },

  /**
   * Reset latest assessment values.
   * @param commit
   */
  async [getBaseName(EAuthLatestAssessmentActions.RESET)]({ commit }) {
    commit(EAuthLatestAssessmentMutations.SET_DATA, null);
  },
};
