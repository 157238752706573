import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-7c23561b"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "locations-selector" }
const _hoisted_2 = {
  key: 0,
  class: "el-form-item__label locations-selector__title"
}
const _hoisted_3 = { class: "locations-selector__entry-name" }
const _hoisted_4 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PxInputPlaces = _resolveComponent("PxInputPlaces")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass([{
        'locations-selector__list--with-entries': _ctx.selectedLocations.length,
      }, "locations-selector__list"])
    }, [
      (!_ctx.noLabel)
        ? (_openBlock(), _createElementBlock("label", _hoisted_2, _toDisplayString(_ctx.$t(_ctx.label)), 1))
        : _createCommentVNode("", true),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.selectedLocations, (location, key) => {
        return (_openBlock(), _createElementBlock("div", {
          key: key,
          class: "locations-selector__entry"
        }, [
          _createElementVNode("span", _hoisted_3, _toDisplayString(location.formatted_address), 1),
          _createElementVNode("i", {
            class: "icon icon-trash--dark",
            onClick: ($event: any) => (_ctx.onClickRemoveLocation(key))
          }, null, 8, _hoisted_4)
        ]))
      }), 128))
    ], 2),
    _createVNode(_component_PxInputPlaces, {
      id: "locationsInput",
      modelValue: _ctx.tempLocation,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.tempLocation) = $event)),
      "autocomplete-type": _ctx.autocompleteType,
      clearable: true,
      placeholder: _ctx.$t(_ctx.placeholder),
      "use-geolocation": true,
      class: "locations-input",
      onInput: _ctx.onLocationSelect
    }, null, 8, ["modelValue", "autocomplete-type", "placeholder", "onInput"])
  ]))
}