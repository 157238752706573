import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-4c73a980"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "px-list-header__badge"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("header", {
    style: _normalizeStyle({ backgroundColor: _ctx.backgroundColor, color: _ctx.textColor }),
    class: "px-list-header"
  }, [
    _createElementVNode("h4", null, _toDisplayString(_ctx.title), 1),
    (_ctx.badgeNumber)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("p", null, _toDisplayString(_ctx.badgeNumber), 1)
        ]))
      : _createCommentVNode("", true)
  ], 4))
}