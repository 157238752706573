import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-88177b4a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "insights-card" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("header", null, [
      _createElementVNode("h1", null, _toDisplayString(_ctx.$t(_ctx.title)), 1)
    ]),
    _createElementVNode("main", null, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.insights, (insight, index) => {
        return (_openBlock(), _createElementBlock("div", {
          key: index,
          class: "insights-card__item"
        }, [
          _createElementVNode("h3", null, [
            _createElementVNode("span", null, _toDisplayString(insight.emoji), 1),
            _createTextVNode(" " + _toDisplayString(insight.title), 1)
          ]),
          _createElementVNode("p", null, _toDisplayString(insight.description), 1)
        ]))
      }), 128))
    ])
  ]))
}