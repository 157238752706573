import { RouteLocationNormalized } from "vue-router";
import { ERROR_ROUTE } from "@/services/router/router-names";
import { userManager } from "@/modules/authentication/user-manager";
import { ERROR_TYPES } from "@/modules/common/components/error-page/constants";
import { defaultScreenService } from "@/modules/common/services/screen/screen.service";

/**
 * Guard to deny access of supporters to Milestone Planner owner route.
 *
 * @param {RouteLocationNormalized} to
 * @param {RouteLocationNormalized} from
 * @param {Function} next
 */
export const milestonePlannerOwnerGuard = async (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: Function,
) => {
  if (userManager.isLogged() && userManager.isSupporter()) {
    next({
      name: ERROR_ROUTE,
    });
  } else if (defaultScreenService.isDown("sm")) {
    next({
      name: ERROR_ROUTE,
      query: {
        code: ERROR_TYPES.MOBILE,
      },
    });
  } else {
    next();
  }
};
