<template>
  <img
    :alt="`Icon - ${name}`"
    :src="iconFilePath"
    :style="iconStyle"
    class="px-icon"
  />
</template>

<script lang="ts">
import { defineComponent } from "vue";

/**
 * Render an SVG icon based on the given name.
 */
export default defineComponent({
  name: "PxIcon",

  props: {
    /**
     * Icon name.
     */
    name: {
      type: String,
      required: true,
    },

    /**
     * Allows to define the icon size.
     */
    size: {
      type: [Number, String],
      default: 32,
    },

    /**
     * Used to select a custom version of the original
     * icon, eg. `--dark` for a dark mode version.
     */
    modifier: {
      type: String as () => string | null,
      default: null,
    },

    useInlineStyle: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    /**
     * Generate the icon path.
     */
    iconFilePath(): string {
      const fileName = this.modifier
        ? `${this.name}--${this.modifier}`
        : this.name;
      return `/img/icons/${fileName}.svg`;
    },

    /**
     * Generate the styles for the icon.
     */
    iconStyle(): any {
      return this.useInlineStyle
        ? {
            width: `${this.size}px`,
            height: `${this.size}px`,
          }
        : {};
    },
  },
});
</script>

<style lang="scss" scoped>
.px-icon {
  display: inline-block;
  vertical-align: baseline;
  transition: $--filter-transition-base;
}
</style>
