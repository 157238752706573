<template>
  <div class="supporter-registration-form__wrapper">
    <FormPresentationLayout
      :description-image-link="viewCopy.imageLink"
      :description-image-title="viewCopy.imageTitle"
      :description-text="viewCopy.imageDescription"
      :has-return-button="hasPreviousPage"
      :has-sticky-header="true"
      content-vertical-alignment="top"
      @previous-page="onReturnButtonClick"
    >
      <template #content>
        <div class="supporter-registration-form__container">
          <ElForm
            ref="supporterRegistrationForm"
            :model="fields"
            :rules="rules"
            class="supporter-registration-form__body"
            @validate="updateBasicInterestsSubmitState"
          >
            <ElFormItem prop="meta.sectors">
              <MultiSelector
                v-model="fields.meta.sectors"
                :fetch-on-created="true"
                :remote-method="searchSectors"
                :subtext-label-list="[
                  'common.components.multiSelector.sectors.subLabel',
                  'common.components.multiSelector.sectors.subLabelPlural',
                ]"
                label="supporters.signup.form.fields.sector"
                placeholder="supporters.signup.form.placeholders.sector"
                tip="supporters.signup.form.tips.sector"
                @input="onInterestsChange"
              />
            </ElFormItem>
            <div class="supporter-registration-form__locations-wrapper">
              <ElFormItem
                :label="$t('supporters.signup.form.fields.locations')"
                prop="meta.locations"
              >
                <PxRadioGroup v-model="fields.meta.hasSpecificLocations">
                  <ElRadio :value="true">
                    {{ $t("supporters.signup.form.locationOptions.specific") }}
                  </ElRadio>
                  <ElRadio :value="false">
                    {{ $t("supporters.signup.form.locationOptions.all") }}
                  </ElRadio>
                </PxRadioGroup>
                <MultiSelector
                  v-show="fields.meta.hasSpecificLocations"
                  v-model="fields.meta.locations"
                  :no-label="true"
                  :remote-method="searchLocations"
                  :subtext-label-list="[
                    'common.components.multiSelector.locations.subLabel',
                    'common.components.multiSelector.locations.subLabelPlural',
                  ]"
                  :validate-event="true"
                  class="locations-selector"
                  input-icon="location"
                  placeholder="supporters.signup.form.placeholders.locations"
                  tip="supporters.signup.form.tips.location"
                  @input="formValidateLocations"
                />
              </ElFormItem>
            </div>
          </ElForm>
        </div>
      </template>
    </FormPresentationLayout>
    <NavigationFooter>
      <template #left>
        <PxButton
          :disabled="isSubmissionDisabled"
          :loading="formLoading"
          class="navigation-footer__main-cta"
          type="primary"
          @click="onNextButtonClick"
        >
          {{ ctaCopy.next }}
        </PxButton>
        <PxButton v-if="skipEnabled" @click="onSkipButtonClick">
          {{ ctaCopy.skip }}
        </PxButton>
      </template>
      <template #right>
        <PxButton v-if="skipEnabled" type="link" @click="onSkipAllButtonClick">
          {{ ctaCopy.setupCriteriaLater }}
        </PxButton>
      </template>
    </NavigationFooter>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import SupportersFlowMixin from "@/modules/supporters/mixins/supporters-flow.mixin";
import FormPresentationLayout from "@/modules/supporters/components/form-presentation-layout/form-presentation-layout.vue";
import MultiSelector from "@/components/multi-selector/multi-selector.vue";
import NavigationFooter from "@/modules/supporters/components/navigation-footer/navigation-footer.vue";

import {
  formatMultiSectorsSelection,
  IGroupedSectorsPayload,
  multiSelectorSectorsProvider,
} from "@/components/multi-selector/multi-selector-sectors.provider";
import {
  formatMultiLocationsSelection,
  IGroupedLocationsPayload,
  IMultiLocationsPayload,
  multiSelectorLocationsProvider,
} from "@/components/multi-selector/multi-selector-locations.providers";
import { IMultiSelection } from "@/components/multi-selector/multi-selector.interface";
import { ILocationGoogleAutocomplete } from "@/services/data/location/location-search.interface";
import { ISupporterFlowSupporter } from "../../services/data/supporter-flow/supporter-flow.interface";

export default defineComponent({
  name: "CriteriaBasicInterests",

  components: {
    FormPresentationLayout,
    MultiSelector,
    NavigationFooter,
  },

  mixins: [SupportersFlowMixin],

  data() {
    return {
      fields: {
        supporter: {
          sectors: [] as Array<number>,
          grouped_sectors: [] as Array<IGroupedSectorsPayload>,
          grouped_locations: [] as Array<IGroupedLocationsPayload>,
          locations: [] as Array<number>,
          places: [] as Array<ILocationGoogleAutocomplete["place_id"]>,
        } as ISupporterFlowSupporter,

        meta: {
          locations: [] as Array<IMultiSelection>,
          sectors: [] as Array<IMultiSelection>,
          hasSpecificLocations: false,
        },
      },
    };
  },

  computed: {
    viewCopy() {
      return this.$tm("supporters.view.criteriaBasicInterests") as {
        imageTitle: string;
        imageDescription: string;
        imageLink: {
          original: string;
          retina: string;
        };
      };
    },
  },

  watch: {
    "fields.meta.hasSpecificLocations": {
      handler(value: boolean) {
        // If no specific location, we should not pass any location in the meta data
        if (!value) {
          this.fields.meta.locations = [];
        }

        this.formValidateLocations();
      },
    },
  },

  created() {
    if (this.flowData) {
      this.fields.supporter = {
        ...this.fields.supporter,
        ...this.flowData.supporter,
      };

      this.restoreMetadata();
    }
  },

  mounted() {
    this.currentForm?.validate();
  },

  methods: {
    // Reuse provider for this component
    searchSectors: multiSelectorSectorsProvider,

    searchLocations: multiSelectorLocationsProvider,

    restoreMetadata() {
      // If there is a meta for locations and/or sectors, restore it
      if (this.flowData.meta?.locations?.length) {
        this.fields.meta.locations = [...this.flowData.meta.locations];
        this.fields.meta.hasSpecificLocations = true;
      } else {
        this.fields.meta.hasSpecificLocations = false;
      }

      if (this.flowData.meta?.sectors) {
        this.fields.meta.sectors = [...this.flowData.meta.sectors];
      }
    },

    onInterestsChange() {
      this.currentForm?.validate();
    },

    updateBasicInterestsSubmitState() {
      this.updateSubmitState();

      // Delete previous values
      const fieldsToDelete: (keyof ISupporterFlowSupporter)[] = [
        "grouped_sectors",
        "sectors",
        "grouped_locations",
        "places",
      ];

      fieldsToDelete.forEach((field) => {
        delete this.fields.supporter[field];
      });

      // If specific locations, divide location's selection into grouped locations and google places
      const locations = (
        this.fields.meta?.hasSpecificLocations
          ? formatMultiLocationsSelection(this.fields.meta?.locations)
          : {
              places: [],
              locations: [],
            }
      ) as IMultiLocationsPayload;

      // Format data
      this.fields.supporter = {
        ...this.fields.supporter,

        ...locations,

        // Divide sector's selection into sectors and grouped_sectors
        ...formatMultiSectorsSelection(this.fields.meta?.sectors),
      } as ISupporterFlowSupporter;
    },

    async formValidateLocations() {
      await this.$nextTick();

      this.currentForm?.validateField("meta.locations", this.updateSubmitState);
    },
  },
});
</script>

<style lang="scss" scoped>
.supporter-registration-form__wrapper :deep() {
  .form-presentation-layout__content-wrapper--with-return {
    padding: 30px 0 30px 59px;
  }

  .form-presentation-layout__content-body {
    /* Add extra height to cautious for the popups */
    margin-bottom: 392px;
  }

  .form-presentation-layout__content-header {
    margin: 0;
  }

  .form-presentation-layout__description-wrapper {
    top: 0;
  }
}

.supporter-registration-form__container {
  display: grid;
  grid-gap: 40px;
  max-width: 360px;
  padding-top: 33px;
}

.supporter-registration-form__body {
  display: grid;
  grid-gap: 30px;
}

.supporter-registration-form__body :deep() {
  .el-form-item__label {
    padding: 0 12px 4px 0;
  }

  .el-form-item__bottom-text {
    padding-top: 5px;
    padding-left: 0;
  }

  .el-input__inner {
    margin: 0;
    line-height: 40px;
  }

  .el-radio-group {
    display: inline-flex;
    flex-flow: row wrap;
    width: 100%;
    margin-top: 8px;
  }

  .el-radio {
    flex-basis: 50%;
    margin-left: 0;
    color: $ebony-clay;

    &:nth-child(n + 3) {
      margin-top: 10px;
    }
  }

  .locations-input {
    margin-top: 2px;
  }

  .locations-selector {
    margin-top: 12px;
  }

  .locations-selector__list:empty + .locations-input {
    margin-top: 11px;
  }

  .locations-selector__list--with-entries {
    margin-top: 11px;
  }
}
</style>
