import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-a43ddd7c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "navigation-footer" }
const _hoisted_2 = { class: "navigation-footer__wrapper" }
const _hoisted_3 = { class: "navigation-footer__container" }
const _hoisted_4 = { class: "navigation-footer__left-container" }
const _hoisted_5 = { class: "navigation-footer__center-container" }
const _hoisted_6 = { class: "navigation-footer__right-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _renderSlot(_ctx.$slots, "left")
        ]),
        _createElementVNode("div", _hoisted_5, [
          _renderSlot(_ctx.$slots, "center")
        ]),
        _createElementVNode("div", _hoisted_6, [
          _renderSlot(_ctx.$slots, "right")
        ])
      ])
    ])
  ]))
}