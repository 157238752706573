<template>
  <MatchingCardCompactWrapper class="matching-compact-placeholder__wrapper">
    <div class="matching-compact-placeholder__container">
      <ContentPlaceholders :rounded="true">
        <ContentPlaceholdersHeading :img="true" />
      </ContentPlaceholders>
      <ContentPlaceholders
        :rounded="true"
        class="matching-compact-placeholder__centered"
      >
        <ContentPlaceholdersText :lines="1" />
      </ContentPlaceholders>
      <ContentPlaceholders
        v-if="showPercentagePill"
        :rounded="true"
        class="matching-compact-placeholder__centered"
      >
        <ContentPlaceholdersText :lines="1" class="is-pill" />
      </ContentPlaceholders>
    </div>
  </MatchingCardCompactWrapper>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import MatchingCardCompactWrapper from "@/components/matching-card/matching-card-compact-wrapper.vue";

export default defineComponent({
  name: "MatchingCardCompactPlaceholder",

  components: {
    MatchingCardCompactWrapper,
  },

  props: {
    showPercentagePill: {
      type: Boolean,
      default: true,
    },
  },
});
</script>

<style lang="scss" scoped>
.matching-compact-placeholder__wrapper {
  pointer-events: none;
  user-select: none;
  padding: 15px 48px;
}

.matching-compact-placeholder__container {
  display: grid;
  grid-template-columns: 272px 130px 146px;

  @include breakpoint-up(xl) {
    grid-template-columns: 272px 477px 192px;
  }
}

.matching-card-compact :deep() {
  .vue-content-placeholders-heading__img {
    width: 48px;
    height: 48px;
    margin-right: 2px;
    background: $athens-gray;
  }

  .vue-content-placeholders-heading__title {
    width: 130px;
    height: 18px;
    margin-bottom: 8px;
    margin-left: 14px;
    background: rgba($manatee, 0.4);
    border-radius: 3px;
  }

  .vue-content-placeholders-heading__subtitle {
    width: 83px;
    height: 19px;
    margin-left: 14px;
    background: rgba($pale-lilac, 0.5);
    border-radius: 3px;
  }

  .matching-compact-placeholder__centered {
    display: flex;
    align-items: flex-start;
    justify-content: center;
  }
}

.matching-compact-placeholder__centered :deep() {
  .vue-content-placeholders-text {
    width: 100%;
    height: 29px;
  }

  .vue-content-placeholders-text__line {
    width: 100%;
    height: 100%;
    top: 9px;
  }

  .vue-content-placeholders-text.is-pill {
    width: 55px;

    .vue-content-placeholders-text__line {
      border-radius: 17px;
    }
  }

  .vue-content-placeholders-text:not(.is-pill) {
    padding: 5px 0;
  }
}
</style>
