import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-0f11895f"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "features-showcase-cta" }
const _hoisted_2 = { class: "features-showcase-cta__teaser" }
const _hoisted_3 = { class: "features-showcase-cta__description" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PxButton = _resolveComponent("PxButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h3", _hoisted_2, _toDisplayString(_ctx.$t("supporters.results.featuresShowcase.cta.teaser")), 1),
    _createVNode(_component_PxButton, {
      class: "features-showcase-cta__button",
      size: "large",
      type: "green",
      onClick: _ctx.onClickSignUp
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$t("supporters.results.featuresShowcase.cta.button")), 1)
      ]),
      _: 1
    }, 8, ["onClick"]),
    _createElementVNode("h4", _hoisted_3, _toDisplayString(_ctx.$t("supporters.results.featuresShowcase.cta.description")), 1)
  ]))
}