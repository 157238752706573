import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "milestone-level-navigation" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MilestoneTooltip = _resolveComponent("MilestoneTooltip")!
  const _component_MilestoneLevelCell = _resolveComponent("MilestoneLevelCell")!
  const _component_ElPopover = _resolveComponent("ElPopover")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.milestonesSortedByLevel, (milestone) => {
      return (_openBlock(), _createBlock(_component_ElPopover, {
        key: milestone.level,
        "close-delay": 0,
        placement: "right",
        "popper-class": "px-popover--light milestone-level-navigation-popover milestone-cell-popover"
      }, {
        reference: _withCtx(() => [
          _createVNode(_component_MilestoneLevelCell, {
            active: _ctx.selectedLevel === milestone.level,
            color: _ctx.categoryColor,
            "has-evidence": milestone.evidenceProvided,
            "is-future-milestone": milestone.future,
            "is-milestone-complete": milestone.completed,
            "is-milestone-critical": milestone.critical,
            "is-milestone-in-progress": milestone.inProgress,
            "is-milestone-planned": milestone.planned,
            number: milestone.level,
            onClick: ($event: any) => (_ctx.changeLevel(milestone.level))
          }, null, 8, ["active", "color", "has-evidence", "is-future-milestone", "is-milestone-complete", "is-milestone-critical", "is-milestone-in-progress", "is-milestone-planned", "number", "onClick"])
        ]),
        default: _withCtx(() => [
          _createVNode(_component_MilestoneTooltip, {
            "category-color": _ctx.categoryColor,
            "category-name": _ctx.categoryName,
            "is-future-milestone": milestone.future,
            "is-milestone-complete": milestone.completed,
            "is-milestone-in-progress": milestone.inProgress,
            "is-milestone-planned": milestone.planned,
            "level-number": milestone.level,
            "milestone-description": milestone.description,
            simplified: true
          }, null, 8, ["category-color", "category-name", "is-future-milestone", "is-milestone-complete", "is-milestone-in-progress", "is-milestone-planned", "level-number", "milestone-description"])
        ]),
        _: 2
      }, 1024))
    }), 128))
  ]))
}