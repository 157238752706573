import {
  ICompanyListInvitedGuest,
  ICompanyListInvitedUser,
} from "@/modules/company-lists/services/data/company-list/company-list.interface";
import { IMilestone } from "@/modules/milestone-planner/services/data/milestones/milestone.interface";
import { IGenericState } from "@/services/store/generic/generic-state.interface";

export type IMilestonePlanner = {
  uid: string;
  company?: number;
  milestones?: IMilestone[];
  // Optional access key to protect a milestone planner
  passcode?: string | null;
  // Users with whom a milestone planner was shared
  invited_users?: Array<IMilestonePlannerInvitedUser>;
  invited_guests?: Array<ICompanyListInvitedGuest>;
};

export interface IMilestonePlannerInvitedUser extends ICompanyListInvitedUser {
  is_form_owner: boolean;
}

/**
 * Represents the state structure for the Milestone Planner module.
 */
export interface IMilestonePlannerState
  extends IGenericState<IMilestonePlanner> {
  /**
   * Holds a Milestone Planner with parsed milestones
   */
  parsedValue: null;
}

export enum EMilestonePlannerState {
  NAMESPACE = "milestonePlanner",
}

export enum EMilestonePlannerActions {
  FETCH_QUESTION_BUNDLES = "milestonePlanner/fetchQuestionBundles",
  GET_VALUE = "milestonePlanner/getValue",
  GET_VALUES = "milestonePlanner/getValues",
  PARSE_VALUE = "milestonePlanner/parseValue",
}

export enum EMilestonePlannerMutations {
  SET_ERROR = "SET_ERROR",
  SET_LOADING = "SET_LOADING",
  SET_VALUE = "SET_VALUE",
  SET_PARSED_VALUE = "SET_PARSED_VALUE",
}
