import { GenericState } from "@/services/store/generic/generic-state.module";
import {
  EGenericAction,
  EGenericGetter,
  IGenericAction,
  IGenericGetter,
} from "@/services/store/generic/generic-state.interface";
import {
  EMatchingCriteriaWeightState,
  IMatchingCriteriaWeightState,
} from "@/modules/matching/services/store/matching-criteria-weight/matching-criteria-weight.interface";
import { IMatchingCriteriaWeight } from "@/modules/matching/services/data/matching-criteria/matching-criteria.interface";
import { matchingCriteriaWeightProvider } from "@/modules/matching/services/data/matching-criteria/matching-criteria-weight.provider";

/**
 * Boilerplate module instantiation
 */
export class MatchingCriteriaWeightState extends GenericState<
  IMatchingCriteriaWeightState,
  IMatchingCriteriaWeight
> {
  static Action = GenericState.mapNamespaceEnum(
    EGenericAction,
    EMatchingCriteriaWeightState.NAMESPACE,
  ) as IGenericAction;

  static Getter = GenericState.mapNamespaceEnum(
    EGenericGetter,
    EMatchingCriteriaWeightState.NAMESPACE,
  ) as IGenericGetter;
}

/**
 * Boilerplate module instantiation
 */
export const matchingCriteriaWeightModule = new MatchingCriteriaWeightState({
  provider: matchingCriteriaWeightProvider,
});
