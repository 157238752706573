<template>
  <div :class="`px-steps--${theme}`" class="px-steps">
    <div class="px-steps__items">
      <div
        v-for="(step, index) in steps"
        :key="step.value"
        :class="{
          'is-active': index === active,
          'is-checked': index < active,
        }"
        class="px-steps-item"
      >
        <svg
          class="px-steps-item__circle"
          height="24"
          viewbox="0 0 24 24"
          width="24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <defs>
            <linearGradient id="a" x1="50%" x2="50%" y1="0%" y2="100%">
              <stop :stop-color="firstGradientColor" offset="0%" />
              <stop :stop-color="lastGradientColor" offset="100%" />
            </linearGradient>
          </defs>
          <g fill="none" fill-rule="nonzero">
            <circle
              class="px-steps-item__circle-outer"
              cx="12"
              cy="12"
              fill="url(#a)"
              r="8"
            />
            <circle
              v-if="index === active"
              cx="12"
              cy="12"
              r="11"
              stroke="url(#a)"
            />
            <image
              v-if="index < active"
              height="16"
              transform="translate(4 4)"
              width="16"
              xlink:href="/img/icons/check--white.svg"
            />
          </g>
        </svg>
        <span class="px-steps-item__label">
          {{ step.label }}
        </span>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import get from "lodash/get";

export interface IPxStepsItem {
  /**
   * Name to be displayed.
   */
  label: string;

  /**
   * Value that corresponds to the step item.
   */
  value: string;
}

/**
 * This component is a component to represent steps.
 *
 * This can be used to define a complex workflow into
 * a set of smaller steps.
 */
export default defineComponent({
  name: "PxSteps",

  props: {
    /**
     * List of steps.
     *
     * Example:
     * ```
     * { label: "Company Details", value: "company" }
     * ```
     */
    steps: {
      type: Array as () => IPxStepsItem[],
      default: () => [],
    },

    /**
     * Index of the current active step.
     */
    active: {
      type: Number,
      default: 1,
    },

    theme: {
      type: String,
      default: "blue",
      validator(value: string) {
        return ["blue", "orange"].includes(value);
      },
    },
  },

  data() {
    return {
      gradient: {
        blue: ["#64A9FE", "#1865C4"],
        orange: ["#FFA272", "#FF8141"],
      },
    };
  },

  computed: {
    firstGradientColor(): string {
      return get(this.gradient, [this.theme, "0"], "#64A9FE");
    },
    lastGradientColor(): string {
      return get(this.gradient, [this.theme, "1"], "#1865C4");
    },
  },
});
</script>

<style lang="scss" scoped>
.px-steps {
  position: relative;
  display: block;

  &__items {
    position: relative;
    left: 2px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    max-width: 290px;
    margin: 0 auto;

    @include breakpoint-up(sm) {
      left: auto;
    }

    @include breakpoint-up(md) {
      max-width: none;
    }
  }
}

.px-steps-item {
  position: relative;
  width: 130px;
  margin-left: 9px;

  text-align: center;

  opacity: 0.5;
  transition: opacity 0.3s ease-in-out;

  @include breakpoint-up(sm) {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: auto;
    margin-left: 11px;
  }

  &:first-child {
    margin-left: 0;
  }

  &::before {
    position: absolute;
    top: 12px;
    right: 60px;
    display: inline-block;
    width: calc(100% - 20px);
    height: 1px;
    vertical-align: middle;

    content: "";

    background-color: $malibu;

    @include breakpoint-up(sm) {
      position: relative;
      top: 0;
      right: auto;
      left: -2px;
      width: 36px;
    }
  }

  &:first-child::before,
  &:last-child::after {
    content: none;
  }

  &__circle {
    display: block;
    flex-shrink: 0;
    margin: 0 auto 7px;
    opacity: 0.6;

    @include breakpoint-up(sm) {
      margin: 0 6px 0 0;
    }
  }

  &__label {
    @include grotesk(semiBold);

    display: block;

    font-size: to-rem(15px);
    line-height: to-rem(15px);

    @include breakpoint-up(sm) {
      padding-top: 1px;
    }
  }
}

.px-steps-item.is-active {
  opacity: 1;

  &::before {
    background-image: linear-gradient(to bottom, #64a9fe, $nice-blue);
  }

  .px-steps-item__circle {
    opacity: 1;
  }
}

.px-steps .px-steps-item.is-checked {
  opacity: 1;

  &::before {
    background: #53af72;
  }

  .px-steps-item__circle {
    opacity: 1;
  }

  .px-steps-item__circle-outer {
    fill: #53af72;
  }
}

/* Themes */
.px-steps--orange {
  .px-steps-item::before {
    background-color: $atomic-tangerine;
  }

  .px-steps-item.is-active::before {
    background-image: linear-gradient(to bottom, $apricot, $orangish);
  }
}
</style>
