import {
  GenericProvider,
  Provider,
  EProviderFeatures,
} from "@/services/data/generic-provider";
import { IMatchingScore } from "./matching-score.interface";
import { baseAPIUrl } from "@/services/utils/utils";

@Provider(`${baseAPIUrl}/matching/scores`, [
  EProviderFeatures.LIST,
  EProviderFeatures.GET,
])
class MatchingScoreProvider extends GenericProvider<IMatchingScore> {}

export const matchingScoreProvider = new MatchingScoreProvider();
