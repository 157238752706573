import { IBaseState } from "@/services/store/store-types";
import { IUser } from "@/services/data/user/user.interface";
import { IProfileCompanyState } from "@/modules/profile/services/store/factory/company/company.types";
import { IGenericSupporterState } from "@/modules/profile/services/store/factory/supporter/supporter.types";
import { IAuthLatestAssessmentState } from "./sub-modules/auth-latest-assessment/auth-latest-assessment.types";
import { IUserMetadataState } from "./sub-modules/user-metadata/user-metadata.types";
import { IAuthMilestonesState } from "./sub-modules/auth-milestones/auth-milestones.types";
import { ISubscription } from "@/modules/authentication/services/data/subscription/subscription.interface";

/**
 * Interface that describes the store for the auth module.
 */
export interface IAuthState extends IBaseState {
  /**
   * User data.
   */
  user: IUser | null;

  /**
   * ID of the user profile.
   */
  profileId: number | null;

  /**
   * UID of the user profile.
   */
  profileUid: string | null;

  /**
   * Logged admin as user
   */
  isAdmin: boolean;

  /**
   * User email has been verified.
   */
  verifiedAccount: boolean;

  /**
   * User company
   */
  company?: IProfileCompanyState;

  /**
   * Supporter data
   */
  supporter?: IGenericSupporterState;

  /**
   * User's latest assessment
   */
  latestAssessment?: IAuthLatestAssessmentState;

  /**
   * User metadata
   */
  metadata?: IUserMetadataState;

  /**
   * User milestones
   */
  milestones?: IAuthMilestonesState;

  subscription: ISubscription | null;
}

/**
 * Supported action by the auth module.
 */
export enum EAuthActions {
  LOGIN = "auth/login",
  LOGIN_ADMIN_AS_USER = "auth/adminAsUser",
  LOGIN_WITH_HASH = "auth/loginWithHash",
  LOGOUT = "auth/logout",
  CHECK_SESSION = "auth/checkSession",
  RECOVER = "auth/recover",
  VALID_EMAIL = "auth/validateEmail",
  FETCH_COMPANY = "auth/fetchCompany",
  FETCH_SUPPORTER = "auth/fetchSupporter",
  CREATE_AFFILIATE_LINK = "auth/createAffiliateLink",
  FETCH_LATEST_ASSESSMENT = "auth/fetchLatestAssessment",
  FETCH_RESPONSES = "auth/fetchResponses",
  FETCH_SUBSCRIPTION = "auth/fetchSubscription",
}

/**
 * Supported mutations by the auth module.
 */
export enum EAuthMutation {
  SET_ERROR = "SET_ERROR",
  SET_LOADING = "SET_LOADING",
  SET_USER = "SET_USER",
  SET_PROFILE_ID = "SET_PROFILE_ID",
  SET_PROFILE_UID = "SET_PROFILE_UID",
  SET_IS_ADMIN = "SET_IS_ADMIN",
  SET_VERIFIED_ACCOUNT = "SET_VERIFIED_ACCOUNT",
  SET_SUBSCRIPTION = "SET_SUBSCRIPTION",
}

export enum EAuthCompanyActions {
  FETCH = "auth/company/fetch",
  PATCH = "auth/company/patch",
  RESET = "auth/company/reset",
}

export enum EAuthSupporterActions {
  FETCH = "auth/supporter/fetch",
  PATCH = "auth/supporter/patch",
  RESET = "auth/supporter/reset",
  CREATE_AFFILIATE_LINK = "auth/supporter/createAffiliateLink",
}
