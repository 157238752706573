import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, mergeProps as _mergeProps, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-38d0e09e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "submissions-info-badges" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PxStatusBadge = _resolveComponent("PxStatusBadge")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.badges, (badge, index) => {
      return (_openBlock(), _createBlock(_component_PxStatusBadge, _mergeProps({ ref_for: true }, _ctx.$options.static[badge], {
        key: badge,
        label: _ctx.badgeLabel[index],
        "label-size": 14,
        uppercase: false
      }), null, 16, ["label"]))
    }), 128))
  ]))
}