import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-689b44eb"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "level-update-modal-footer" }
const _hoisted_2 = { class: "level-update-modal-footer__popover-discard-title" }
const _hoisted_3 = { class: "level-update-modal-footer__popover-discard-description" }
const _hoisted_4 = { class: "level-update-modal-footer__popover-discard-buttons" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PxButton = _resolveComponent("PxButton")!
  const _component_ElPopover = _resolveComponent("ElPopover")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ElPopover, {
      visible: _ctx.isPopoverVisible,
      "onUpdate:visible": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.isPopoverVisible) = $event)),
      width: _ctx.$screen.xsOnly ? 260 : 272,
      placement: "top",
      trigger: "click"
    }, {
      reference: _withCtx(() => [
        _createVNode(_component_PxButton, {
          class: _normalizeClass([{ 'el-button--block': _ctx.$screen.xsOnly }, "level-update-modal-levels-info-footer__discard-btn"]),
          disabled: _ctx.isLoading
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t("common.components.levelUpdateModal.buttons.discard")), 1)
          ]),
          _: 1
        }, 8, ["class", "disabled"])
      ]),
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t("common.components.levelUpdateModal.popover.title")), 1),
        _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t("common.components.levelUpdateModal.popover.description")), 1),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_PxButton, {
            ref: "cancelButton",
            class: "el-button--link",
            size: "small",
            type: "link",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isPopoverVisible = false))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("common.components.levelUpdateModal.popover.cancel")), 1)
            ]),
            _: 1
          }, 512),
          _createVNode(_component_PxButton, {
            ref: "discardButton",
            class: "level-update-modal-footer__popover-discard-btn",
            type: "red",
            onClick: _ctx.onClickDiscard
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("common.components.levelUpdateModal.popover.discard")), 1)
            ]),
            _: 1
          }, 8, ["onClick"])
        ])
      ]),
      _: 1
    }, 8, ["visible", "width"]),
    _createVNode(_component_PxButton, {
      class: _normalizeClass({ 'el-button--block': _ctx.$screen.xsOnly }),
      loading: _ctx.isLoading,
      type: "primary",
      onClick: _ctx.onClickSaveAssessment
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.updateButtonCopy), 1)
      ]),
      _: 1
    }, 8, ["class", "loading", "onClick"])
  ]))
}