<template>
  <div class="submission-company-panel">
    <div class="submission-company__left-wrapper">
      <div class="submission-company__grid-container">
        <div class="submission-company__field-container">
          <div>
            <span class="submission-company__title">
              {{ company.name }}
            </span>
            <span class="submission-company__profile-link" @click="goToProfile">
              {{ $t("program.submission.seeProfile") }}
            </span>
          </div>
          <h3 class="submission-company__subtitle">
            {{ formattedLocation }}
          </h3>
        </div>
        <template v-if="$screen.smDown">
          <div
            v-if="userCanMatch && $features.isEnabled('match')"
            class="submission-company__field-container"
          >
            <PxMatchingInterest
              :id="company.id"
              :name="company.name"
              :with-description="false"
            />
            <PxMatchingInterestTip :id="company.id" :name="company.name" />
          </div>
          <div v-if="hasMatching" class="submission-company__field-container">
            <MatchingRibbon :type="$options.static.matchingRibbonType" />
          </div>
        </template>
        <div
          class="submission-company__field-container submission-company__field-tags"
        >
          <h4 class="submission-company__field-caption">
            {{ $t("program.submission.company.sector.title") }}
          </h4>
          <ElTag
            v-for="sector in company.sectors"
            :key="sector.id"
            class="text--capitalize"
            effect="plain"
            v-text="sector.name"
          />
        </div>
        <div class="submission-company__field-container">
          <h4 class="submission-company__field-caption">
            {{ $t("program.submission.company.about.title") }}
          </h4>
          <div class="submission-company__field-content">
            <span v-if="company.about">
              {{ company.about }}
            </span>
            <span v-else class="submission-company__field-content--empty">
              {{ $t("program.submission.company.emptyState") }}
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="submission-company__right-wrapper">
      <div class="submission-company__grid-container">
        <template v-if="$screen.smUp">
          <div v-if="userCanMatch" class="submission-company__field-container">
            <PxMatchingInterest
              v-if="$features.isEnabled('match')"
              :id="company.id"
              :name="company.name"
              :with-description="false"
            />
            <PxButton
              v-if="showAddToListButton"
              class="submission-company__cta--addToList"
              v-bind="addToListButtonProps"
              @click.stop="showListModal"
            />
            <PxMatchingInterestTip :id="company.id" :name="company.name" />
          </div>
          <div v-if="hasMatching" class="submission-company__field-container">
            <MatchingRibbon :type="$options.static.matchingRibbonType" />
          </div>
        </template>
        <div class="submission-company__field-container">
          <h4 class="submission-company__field-caption">
            {{ $t("program.submission.company.website.title") }}
          </h4>
          <div class="submission-company__field-content">
            <a
              v-if="company.website"
              :href="companyWebsite"
              class="el-button el-button--link el-button--link-dark"
              target="_blank"
            >
              <span>
                {{ company.website }}
              </span>
            </a>
            <span v-else class="submission-company__field-content--empty">
              {{ $t("program.submission.company.emptyState") }}
            </span>
          </div>
        </div>
        <div class="submission-company__field-container">
          <h4 class="submission-company__field-caption">
            {{ $t("program.submission.company.email.title") }}
          </h4>
          <div class="submission-company__field-content">
            <span
              v-if="!company.email"
              class="submission-company__field-content--empty"
            >
              {{ $t("program.submission.company.emptyState") }}
            </span>
            <template v-else>
              <PxButton
                v-if="isPublicView"
                class="el-button--link el-button--link-green"
                type="link"
                @click="onClickSignUp"
              >
                {{ $t("program.submission.company.email.visitor") }}
              </PxButton>
              <a
                v-else
                :href="`mailto:${company.email}`"
                class="el-button el-button--link el-button--link-dark"
              >
                <span>
                  {{ company.email }}
                </span>
              </a>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { activeModules } from "@/services/utils/utils";
import { ROUTE_AUTHENTICATION_SIGNUP } from "@/modules/authentication/services/router/routes-names";
import { locationFormatter } from "@/utils/location-formatter";

import { EMatchingRibbonType } from "@/modules/profile/services/data/matching-ribbon/matching-ribbon.types";
import MatchingRibbon from "@/modules/profile/components/matching-ribbon/matching-ribbon.vue";
import { ROUTE_PROFILE } from "@/modules/profile/services/router/routes-names";

import PxMatchingInterestTip from "@/components/px-matching-interest/px-matching-interest-tip.vue";

import {
  EPxButtonSize,
  EPxButtonType,
} from "@/components/px-button/px-button.types";

export default defineComponent({
  name: "SubmissionCompanyPanel",

  components: {
    MatchingRibbon,
    PxMatchingInterestTip,
  },

  props: {
    company: {
      type: Object as () => any,
      required: true,
    },
    userCanMatch: {
      type: Boolean,
      default: false,
    },
    userIsConnected: {
      type: Boolean,
      default: false,
    },
    userIsInterested: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    addToListButtonProps(): any {
      return {
        ...this.$options.static.addToListButton,
        label: this.$t("common.components.default.cta.addToList"),
      };
    },

    isPublicView(): boolean {
      return !this.$user.isLogged();
    },

    formattedLocation(): string {
      const locationComponent =
        this.company && this.company.locations && this.company.locations[0]
          ? this.company.locations[0]
          : null;

      return locationFormatter(locationComponent);
    },

    hasMatching(): boolean {
      return this.$features.isEnabled("matchingInterest");
    },

    showAddToListButton(): boolean {
      return (
        this.$screen.lgUp &&
        activeModules().includes("company-lists") &&
        this.$features.isEnabled("companyLists")
      );
    },

    companyWebsite(): string {
      const companyWebsite = this.company.website || "";

      const pattern =
        /(ftp|http|https):\/\/(\w+:?\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@\-/]))?/;

      return pattern.test(companyWebsite)
        ? companyWebsite
        : `//${companyWebsite}`;
    },
  },

  static: {
    matchingRibbonType: EMatchingRibbonType.SLIM,
    addToListButton: {
      icon: "icon-add-to-list",
      size: EPxButtonSize.EXTRA_SMALL,
      type: EPxButtonType.DARK_BLUE,
    },
  },

  methods: {
    onClickSignUp() {
      this.$router.push({ name: ROUTE_AUTHENTICATION_SIGNUP });
    },

    goToProfile() {
      this.$router.push({
        name: ROUTE_PROFILE,
        params: {
          id: this.company.id,
        },
      });
    },

    showListModal() {
      this.$emit("show-list-modal");
    },
  },
});
</script>

<style lang="scss" scoped>
.submission-company-panel {
  display: grid;
  grid-template-columns: auto;
  grid-row-gap: 30px;
  padding: 32px 15px;

  @include breakpoint-up(md) {
    grid-template-columns: auto 307px;
    grid-column-gap: 16px;
    padding: 40px;
  }
}

.submission-company__cta--addToList {
  float: right;
  max-width: 110px;
  height: 32px;
  margin-left: 8px;

  :deep() .px-icon + div {
    margin-left: 7px;
  }

  @include breakpoint-down(sm) {
    float: left;
  }
}

.submission-company__title {
  @include grotesk(semiBold);

  margin-right: 8px;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: -0.2px;

  @include breakpoint-up(md) {
    font-size: 22px;
    line-height: 27px;
  }
}

.submission-company__profile-link {
  @include grotesk(semiBold);

  font-size: 15px;
  color: $ebony-clay;
  text-decoration: underline;
  cursor: pointer;
  transition: color 0.25s ease-in-out;

  &:hover {
    color: $manatee;
  }
}

.submission-company__subtitle {
  @include grotesk(regular);

  margin: 6px 0 0;
  font-size: to-rem(15px);
  line-height: 18px;
  letter-spacing: -0.2px;

  @include breakpoint-up(md) {
    margin: 3px 0 0;
  }
}

.submission-company__grid-container {
  display: grid;
  grid-row-gap: 30px;

  .submission-company__field-container:first-of-type {
    padding-left: 4px;
  }
}

.submission-company__grid-container .px-matching-interest {
  :deep() .el-button {
    min-width: 182px;
    height: 32px;

    span {
      font-size: 12px;
    }

    @include breakpoint-down(md) {
      width: 100%;
      height: 46px;
    }
  }

  @include breakpoint-up(lg) {
    display: inline-block;
  }

  &__tip {
    float: right;
    margin: 12px 0 4px 0;

    @include breakpoint-down(sm) {
      float: left;
    }
  }
}

.submission-company__field-caption {
  @include grotesk(semiBold);

  margin: 0 0 10px;
  font-size: to-rem(13px);
  color: $manatee;
  text-transform: uppercase;

  @include breakpoint-up(md) {
    line-height: 16px;
  }
}

.submission-company__field-content {
  @include grotesk(regular);

  font-size: to-rem(15px);
  line-height: 24px;
  letter-spacing: -0.2px;

  @include breakpoint-up(md) {
    line-height: 25px;
  }

  .el-button--link {
    height: auto;
    padding: 0;
  }
}

.submission-company__field-content--empty {
  @include grotesk(regular, italic);

  font-size: to-rem(15px);
  line-height: 25px;
  letter-spacing: -0.2px;
}

.submission-company__field-tags .el-tag {
  margin-right: 10px;
  margin-bottom: 10px;
  white-space: inherit;
}
</style>
