import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-1a898d42"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "level-update-modal-first-tab" }
const _hoisted_2 = {
  key: 0,
  class: "level-update-modal-first-tab__cards-wrapper"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LevelUpdateCard = _resolveComponent("LevelUpdateCard")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.currentLevel && _ctx.newLevel)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_LevelUpdateCard, {
            "current-assessment": _ctx.staticCurrentAssessment,
            level: _ctx.staticPrevLevel,
            "level-description": _ctx.getLevelDescription(_ctx.staticPrevLevel),
            "only-prev-assessment": true,
            "prev-assessment": _ctx.staticPrevAssessment,
            "type-of-update": _ctx.typeOfUpdate,
            class: "level-update-modal-first-tab__current-card"
          }, null, 8, ["current-assessment", "level", "level-description", "prev-assessment", "type-of-update"]),
          _createVNode(_component_LevelUpdateCard, {
            "current-assessment": _ctx.staticCurrentAssessment,
            level: _ctx.staticNewLevel,
            "level-description": _ctx.getLevelDescription(_ctx.staticNewLevel),
            "prev-assessment": _ctx.staticPrevAssessment,
            "type-of-update": _ctx.typeOfUpdate,
            class: "level-update-modal-first-tab__new-card",
            type: "blue"
          }, null, 8, ["current-assessment", "level", "level-description", "prev-assessment", "type-of-update"])
        ]))
      : _createCommentVNode("", true)
  ]))
}