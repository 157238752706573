import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-3c42da7a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "visitor-join-bar__col-start" }
const _hoisted_2 = { class: "visitor-join-bar__title" }
const _hoisted_3 = { class: "visitor-join-bar__subtitle" }
const _hoisted_4 = { class: "visitor-join-bar__col-middle" }
const _hoisted_5 = { class: "visitor-join-bar__col-end" }
const _hoisted_6 = { class: "visitor-join-bar__content" }
const _hoisted_7 = { class: "visitor-join-bar__cta-link" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PxButton = _resolveComponent("PxButton")!
  const _component_PxStickyBar = _resolveComponent("PxStickyBar")!

  return (_openBlock(), _createBlock(_component_PxStickyBar, { class: "visitor-join-bar" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("h2", _hoisted_2, _toDisplayString(_ctx.$t("profile.visitor.stickyBar.title")), 1),
        _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t("profile.visitor.stickyBar.subtitle")), 1)
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_PxButton, {
          class: "visitor-join-bar__cta-button",
          size: "extra-large",
          type: "green",
          onClick: _ctx.onClickSignUp
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t("profile.visitor.stickyBar.button")), 1)
          ]),
          _: 1
        }, 8, ["onClick"])
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.$t("profile.visitor.stickyBar.content")), 1),
        _createElementVNode("div", _hoisted_7, [
          _createVNode(_component_PxButton, {
            class: "el-button--link el-button--link-white",
            icon: "info",
            type: "link",
            onClick: _ctx.onClickOpenHowItWorksLink
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("profile.visitor.stickyBar.link")), 1)
            ]),
            _: 1
          }, 8, ["onClick"])
        ])
      ])
    ]),
    _: 1
  }))
}