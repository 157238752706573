<template>
  <div class="milestone-form-footer">
    <MilestoneFormFooterPlan
      v-if="isPlanTab"
      :form-has-changes="planFormHasChanges"
      :form-is-complete="planFormIsComplete"
      :form-is-invalid="planFormIsInvalid"
      :has-plan="hasPlan"
      :is-future-milestone="isFutureMilestone"
      :is-plan-published="isPlanPublished"
      data-testid="milestone-form-footer-plan"
    />
    <MilestoneFormFooterComplete
      v-else
      :above-milestones-completed="aboveMilestonesCompleted"
      :below-milestones-not-completed="belowMilestonesNotCompleted"
      :form-has-changes="completeFormHasChanges"
      :form-is-invalid="completeFormIsInvalid"
      :has-completion-info="hasCompletionInfo"
      :is-form-published="isCompletePublished"
      :user-adds-evidence="userAddsEvidence"
      data-testid="milestone-form-footer-complete"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import MilestoneFormFooterPlan from "@/modules/milestone-planner/components/milestone-form-footer/milestone-form-footer-plan.vue";
import MilestoneFormFooterComplete from "@/modules/milestone-planner/components/milestone-form-footer/milestone-form-footer-complete.vue";
import {
  MILESTONE_DETAILS_TABS,
  TMilestoneDetailsTab,
} from "../../services/data/milestones/milestone.interface";

export default defineComponent({
  name: "MilestoneFormFooter",

  components: {
    MilestoneFormFooterPlan,
    MilestoneFormFooterComplete,
  },

  props: {
    activeTab: {
      type: String as () => TMilestoneDetailsTab,
      required: true,
    },
    isFutureMilestone: {
      type: Boolean,
      default: false,
    },
    hasPlan: {
      type: Boolean,
      default: false,
    },
    isPlanPublished: {
      type: Boolean,
      default: false,
    },
    planFormHasChanges: {
      type: Boolean,
      default: false,
    },
    planFormIsInvalid: {
      type: Boolean,
      default: false,
    },
    planFormIsComplete: {
      type: Boolean,
      default: false,
    },
    userAddsEvidence: {
      type: Boolean,
      default: false,
    },
    hasCompletionInfo: {
      type: Boolean,
      default: false,
    },
    isCompletePublished: {
      type: Boolean,
      default: false,
    },
    completeFormHasChanges: {
      type: Boolean,
      default: false,
    },
    completeFormIsInvalid: {
      type: Boolean,
      default: false,
    },
    belowMilestonesNotCompleted: {
      type: Boolean,
      default: false,
    },
    aboveMilestonesCompleted: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    isPlanTab(): boolean {
      return this.activeTab === MILESTONE_DETAILS_TABS.PLAN;
    },
  },
});
</script>

<style lang="scss">
.milestone-form-footer {
  position: sticky;
  bottom: 0;
  z-index: z("default");
  width: 100%;
  height: 76px;
  padding: 1px 31px 0 33px;
  background-color: $white;
  border-top: 1px solid $athens-gray;

  &__tooltip {
    max-width: 340px;
    text-transform: none;
  }
}
</style>
