import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createBlock as _createBlock, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-12a5d2a4"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "matching-sidebar__update-btn"
}
const _hoisted_2 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PxIcon = _resolveComponent("PxIcon")!
  const _component_i18n_t = _resolveComponent("i18n-t")!
  const _component_PxQuestionary = _resolveComponent("PxQuestionary")!
  const _component_PxButton = _resolveComponent("PxButton")!
  const _component_PxNotice = _resolveComponent("PxNotice")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([{ 'matching-sidebar-closed': !_ctx.isSidebarOpen }, "matching-sidebar"])
  }, [
    _createElementVNode("div", {
      class: "matching-sidebar__headline",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleSidebar && _ctx.toggleSidebar(...args)))
    }, [
      _createVNode(_component_PxIcon, {
        name: _ctx.menuIcon,
        size: 14
      }, null, 8, ["name"]),
      _createElementVNode("h3", null, _toDisplayString(_ctx.title), 1),
      _createVNode(_component_PxIcon, {
        name: _ctx.arrowIcon,
        size: 14,
        class: "matching-sidebar__arrow-icon"
      }, null, 8, ["name"])
    ]),
    (_ctx.$user.isSupporter())
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_i18n_t, {
            keypath: "matching.matchingSidebar.editCriteria.text",
            tag: "p"
          }, {
            linkText: _withCtx(() => [
              _createElementVNode("a", {
                href: _ctx.url,
                target: "_blank"
              }, _toDisplayString(_ctx.$t("matching.matchingSidebar.editCriteria.linkText")), 9, _hoisted_2)
            ]),
            _: 1
          })
        ]))
      : _createCommentVNode("", true),
    _createVNode(_component_PxQuestionary, {
      criteria: _ctx.currentCriteria,
      "criteria-weights": _ctx.criteriaWeights,
      "final-message": _ctx.questionary.final.message,
      "final-title": _ctx.questionary.final.title,
      loading: _ctx.loadingQuestion,
      question: _ctx.currentQuestion,
      onSkip: _ctx.skipQuestion,
      onSubmit: _ctx.submitResponse,
      onSubmitCriteria: _ctx.submitCriteriaSelection
    }, null, 8, ["criteria", "criteria-weights", "final-message", "final-title", "loading", "question", "onSkip", "onSubmit", "onSubmitCriteria"]),
    (_ctx.showNotice && !_ctx.loadingQuestion)
      ? (_openBlock(), _createBlock(_component_PxNotice, {
          key: 1,
          title: _ctx.$t('matching.matchingSidebar.notice.title'),
          class: "matching-sidebar__notice"
        }, {
          cta: _withCtx(() => [
            _createVNode(_component_PxButton, {
              size: "small",
              type: "default",
              onClick: _ctx.onClickOpenFeedbackPage
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t("matching.matchingSidebar.notice.button")), 1)
              ]),
              _: 1
            }, 8, ["onClick"])
          ]),
          _: 1
        }, 8, ["title"]))
      : _createCommentVNode("", true)
  ], 2))
}