import { ActionTree } from "vuex";
import {
  IAssessmentHistoryState,
  EAssessmentHistoryActions,
  EAssessmentHistoryMutations,
} from "./assessment-history.types";
import { RootState } from "@/services/store/root-state";
import { getBaseName } from "@/services/store/utils/get-base-name";
import { assessmentsHistoryProvider } from "@/modules/profile/services/data/assessment-history/assessment-history.provider";

export const actions: ActionTree<IAssessmentHistoryState, RootState> = {
  /**
   * Get the assessment history of a company.
   *
   * @param param0 store context
   * @param companyId Id of the company which the assessment history must
   * be fetched.
   */
  async [getBaseName(EAssessmentHistoryActions.FETCH)](
    { commit },
    companyId: number,
  ) {
    commit(EAssessmentHistoryMutations.SET_LOADING, true);
    commit(EAssessmentHistoryMutations.SET_ERROR, null);

    try {
      const data = await assessmentsHistoryProvider.getHistory(companyId);
      commit(EAssessmentHistoryMutations.SET_DATA, data);
    } catch (error) {
      commit(EAssessmentHistoryMutations.SET_ERROR, error);
    } finally {
      commit(EAssessmentHistoryMutations.SET_LOADING, false);
    }
  },
};
