import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "px-list-modal__group"
}
const _hoisted_2 = ["onClick"]
const _hoisted_3 = { class: "px-list-modal__group-subtitle" }
const _hoisted_4 = ["onScroll"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ElDialog = _resolveComponent("ElDialog")!

  return (_openBlock(), _createBlock(_component_ElDialog, {
    ref: "modal",
    modelValue: _ctx.innerVisibility,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.innerVisibility) = $event)),
    "append-to-body": true,
    class: _normalizeClass([{ 'text--capitalize': _ctx.capitalize }, "px-list-modal"]),
    "destroy-on-close": true,
    "lock-scroll": true,
    title: _ctx.title,
    width: "420px"
  }, {
    default: _withCtx(() => [
      _createElementVNode("ul", null, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, index) => {
          return (_openBlock(), _createElementBlock("li", {
            key: index,
            ref_for: true,
            ref: "item",
            class: _normalizeClass([{
          'px-list-modal__item--opened': _ctx.hasItemsOpened(index),
          'px-list-modal__item--scrolling': _ctx.isScrollingList(index),
        }, "px-list-modal__item"])
          }, [
            (typeof item === 'string')
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  _createTextVNode(_toDisplayString(item), 1)
                ], 64))
              : (_openBlock(), _createElementBlock("div", _hoisted_1, [
                  _createElementVNode("div", {
                    class: "px-list-modal__group-title",
                    onClick: () => _ctx.onClickToggleItems(index)
                  }, [
                    _createTextVNode(_toDisplayString(item.name) + " ", 1),
                    _createElementVNode("span", _hoisted_3, " (" + _toDisplayString(item.items.length) + ") ", 1),
                    _createElementVNode("i", {
                      class: _normalizeClass([{ 'icon--invert-y': _ctx.hasItemsOpened(index) }, "icon icon-caret--gray"])
                    }, null, 2)
                  ], 8, _hoisted_2),
                  _createElementVNode("div", {
                    class: _normalizeClass([{
              'px-list-modal__group-items--hidden': !_ctx.hasItemsOpened(index),
            }, "px-list-modal__group-items"]),
                    onScroll: ($event: any) => (_ctx.listGroupScrollHandler(index))
                  }, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.items, (child, childIndex) => {
                      return (_openBlock(), _createElementBlock("p", { key: childIndex }, _toDisplayString(child), 1))
                    }), 128))
                  ], 42, _hoisted_4)
                ]))
          ], 2))
        }), 128))
      ])
    ]),
    _: 1
  }, 8, ["modelValue", "class", "title"]))
}