import { ICompanyList } from "@/modules/company-lists/services/data/company-list/company-list.interface";
import { getGenericStateGetters } from "@/services/store/generic/generic-state.getters";
import { RootState } from "@/services/store/root-state";
import { GetterTree } from "vuex";
import { ICompanySharedListState } from "@/modules/company-lists/services/store/company-shared-list/company-shared-list.interface";

/**
 * Available getters to get data from the company list store.
 */
export enum ECompanySharedListGetters {
  VALUE = "getValue",
  VALUES = "getValues",
  IS_LOADING = "isLoading",
  ERROR = "getError",
  HAS_ERROR = "hasError",
  CURRENT_PAGE = "currentPage",
}

export const getters: GetterTree<ICompanySharedListState, RootState> = {
  ...getGenericStateGetters<ICompanyList>(),
};
