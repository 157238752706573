import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-0acd6e64"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "team-member-demographic-data__header" }
const _hoisted_2 = { class: "team-member-demographic-data__title" }
const _hoisted_3 = { class: "team-member-demographic-data__info" }
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = { class: "team-member-demographic-data__subtitle" }
const _hoisted_6 = { class: "team-member-demographic-data__questions" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PxIcon = _resolveComponent("PxIcon")!
  const _component_CollapsibleQuestion = _resolveComponent("CollapsibleQuestion")!
  const _component_ElCollapse = _resolveComponent("ElCollapse")!
  const _directive_loading = _resolveDirective("loading")!

  return _withDirectives((_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([{ 'team-member-demographic-data--loading': _ctx.isLoading }, "team-member-demographic-data"])
  }, [
    (!_ctx.isLoading)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("h4", null, _toDisplayString(_ctx.headerCopy.title), 1),
              _createElementVNode("div", _hoisted_3, [
                _createVNode(_component_PxIcon, {
                  size: 16,
                  name: "icon-information"
                }),
                _createElementVNode("p", {
                  innerHTML: _ctx.$t('teamManagement.settings.demographicData.info')
                }, null, 8, _hoisted_4)
              ])
            ]),
            _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.headerCopy.subtitle), 1)
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createVNode(_component_ElCollapse, { accordion: true }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.questions, (question, index) => {
                  return (_openBlock(), _createElementBlock(_Fragment, null, [
                    (question.id)
                      ? (_openBlock(), _createBlock(_component_CollapsibleQuestion, {
                          key: question.id,
                          question: question,
                          response: _ctx.currentResponses ? _ctx.currentResponses[index] : null,
                          onHandleNewResponse: _ctx.updateResponses,
                          onResponseFormatted: _ctx.handleFormattedResponse
                        }, null, 8, ["question", "response", "onHandleNewResponse", "onResponseFormatted"]))
                      : _createCommentVNode("", true)
                  ], 64))
                }), 256))
              ]),
              _: 1
            })
          ])
        ], 64))
      : _createCommentVNode("", true)
  ], 2)), [
    [_directive_loading, _ctx.isLoading]
  ])
}