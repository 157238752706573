<template>
  <div class="list-share">
    <PxButton
      :label="$t('companyLists.list.share')"
      class="list-share__toggler"
      size="medium"
      @click.stop="openShareModalClickHandler"
    />

    <ListShareModal
      v-model:visibility="shareModalVisible"
      :name="value.title"
      :passcode="listPasscode"
      :uid="value.uid"
      :users="formatAllUsers"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import {
  ICompanyList,
  ICompanyListInvitedGuest,
  ICompanyListInvitedUser,
} from "@/modules/company-lists/services/data/company-list/company-list.interface";
import ListShareModal from "@/modules/company-lists/components/list-share/list-share-modal.vue";

export default defineComponent({
  name: "ListShare",

  components: {
    ListShareModal,
  },

  props: {
    value: {
      type: Object as () => ICompanyList,
      required: true,
    },
  },

  data() {
    return {
      shareModalVisible: false,
    };
  },

  computed: {
    listPasscode(): string {
      return this.value.passcode || "";
    },

    allUsers(): Array<ICompanyListInvitedUser | ICompanyListInvitedGuest> {
      return [...this.value.invited_users, ...this.value.invited_guests].sort(
        (userA, userB) => userA.name.localeCompare(userB.name),
      );
    },

    formatAllUsers(): Array<ICompanyListInvitedUser> {
      return this.allUsers.map(
        (user: ICompanyListInvitedUser | ICompanyListInvitedGuest) => {
          return {
            user_profile:
              (user as ICompanyListInvitedUser).user_profile || null,
            email: user.email || "",
            name: user.name || "",
            photo: (user as ICompanyListInvitedUser).photo || null,
          };
        },
      );
    },
  },

  methods: {
    openShareModalClickHandler() {
      this.shareModalVisible = true;
    },

    onVisibleChange(state: boolean) {
      this.shareModalVisible = state;
    },
  },
});
</script>

<style lang="scss" scoped>
.list-share__toggler {
  @include grotesk(bold);

  min-width: 72px;
  padding: 0 16px 1px 15px;

  :deep() span {
    font-size: 13px;
    line-height: 16px;
  }

  :deep() .px-icon {
    margin-top: 1px;
    margin-right: 5px;
  }

  :deep() &::after {
    opacity: 0.6;
  }
}
</style>
