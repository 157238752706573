import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, createElementVNode as _createElementVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "milestone-form-complete" }
const _hoisted_2 = {
  key: 0,
  class: "milestone-form-complete__description"
}
const _hoisted_3 = { class: "milestone-form-complete__fields" }
const _hoisted_4 = { class: "milestone-form-complete__question" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PxIcon = _resolveComponent("PxIcon")!
  const _component_ElTooltip = _resolveComponent("ElTooltip")!
  const _component_PxTextarea = _resolveComponent("PxTextarea")!
  const _component_ElFormItem = _resolveComponent("ElFormItem")!
  const _component_PxDatePicker = _resolveComponent("PxDatePicker")!
  const _component_ElForm = _resolveComponent("ElForm")!
  const _component_MilestonePlanConfirmationDialog = _resolveComponent("MilestonePlanConfirmationDialog")!
  const _component_MilestonePlanLevelUpDialog = _resolveComponent("MilestonePlanLevelUpDialog")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ElForm, {
      ref: "form",
      model: _ctx.form,
      rules: _ctx.rules
    }, {
      default: _withCtx(() => [
        (_ctx.evidenceQuestions.length)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.$t("milestonePlanner.milestonePlan.completeForm.description")), 1))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_3, [
          (_ctx.evidenceQuestions.length)
            ? (_openBlock(), _createBlock(_component_ElFormItem, {
                key: 0,
                class: "milestone-form-complete__textarea milestone-form-complete__evidence",
                prop: "evidence"
              }, {
                label: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t("milestonePlanner.milestonePlan.completeForm.fields.evidence")) + " ", 1),
                  _createVNode(_component_ElTooltip, {
                    content: _ctx.evidenceTooltip,
                    placement: "top",
                    "popper-class": "milestone-form-complete__tooltip el-abaca-tooltip"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_PxIcon, {
                        size: 14,
                        name: "question"
                      })
                    ]),
                    _: 1
                  }, 8, ["content"])
                ]),
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.evidenceQuestions, (question, key) => {
                    return (_openBlock(), _createElementBlock("div", { key: key }, [
                      _createElementVNode("div", _hoisted_4, [
                        _createElementVNode("ul", null, [
                          _createElementVNode("li", null, [
                            _createElementVNode("span", null, _toDisplayString(question.entrepreneur_question), 1)
                          ])
                        ])
                      ]),
                      _createVNode(_component_PxTextarea, {
                        ref_for: true,
                        ref: "evidence",
                        modelValue: _ctx.form.evidence,
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.form.evidence) = $event)),
                        "max-chars": _ctx.rules.evidence.max,
                        placeholder: 
                _ctx.$t(
                  'milestonePlanner.milestonePlan.completeForm.placeholders.evidence',
                )
              ,
                        "show-counter": ""
                      }, null, 8, ["modelValue", "max-chars", "placeholder"])
                    ]))
                  }), 128))
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          _createVNode(_component_ElFormItem, { prop: "dateOfCompletion" }, {
            default: _withCtx(() => [
              _createVNode(_component_PxDatePicker, {
                value: _ctx.form.dateOfCompletion,
                "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.form.dateOfCompletion) = $event)),
                label: 
              _ctx.$t(
                'milestonePlanner.milestonePlan.completeForm.fields.dateOfCompletion',
              )
            ,
                tooltip: _ctx.dateOfCompletionTooltip,
                class: "milestone-form-complete__date"
              }, null, 8, ["value", "label", "tooltip"])
            ]),
            _: 1
          })
        ])
      ]),
      _: 1
    }, 8, ["model", "rules"]),
    _createVNode(_component_MilestonePlanConfirmationDialog, {
      ref: "discardChangesDialog",
      visibility: _ctx.isDiscardingChanges,
      "onUpdate:visibility": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.isDiscardingChanges) = $event)),
      onConfirmed: _ctx.resetForm
    }, null, 8, ["visibility", "onConfirmed"]),
    (_ctx.milestone)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _createVNode(_component_MilestonePlanConfirmationDialog, {
            ref: "deleteMilestoneCompletionDialog",
            visibility: _ctx.isDeleting,
            "onUpdate:visibility": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.isDeleting) = $event)),
            completed: true,
            "is-complete-published": _ctx.milestone.evidence_published,
            "is-form-invalid": _ctx.formIsInvalid,
            onConfirmed: _ctx.deleteMilestoneCompletion,
            onSaveAsDraft: _cache[4] || (_cache[4] = ($event: any) => (_ctx.submitEvidence({ toPublish: false })))
          }, null, 8, ["visibility", "is-complete-published", "is-form-invalid", "onConfirmed"]),
          _createVNode(_component_MilestonePlanLevelUpDialog, {
            ref: "levelUpDialog",
            visibility: _ctx.hasLeveledUp,
            "onUpdate:visibility": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.hasLeveledUp) = $event)),
            "category-color": _ctx.milestone.category_level.category.color,
            "category-name": _ctx.milestone.category_level.category.name,
            description: _ctx.milestone.category_level.description,
            level: _ctx.getCurrentCategoryLevel() || 1
          }, null, 8, ["visibility", "category-color", "category-name", "description", "level"])
        ], 64))
      : _createCommentVNode("", true)
  ]))
}