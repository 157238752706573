import { resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-115dfa22"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "matching-list-interests" }
const _hoisted_2 = {
  key: 0,
  class: "matching-list__loading"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MatchingListInterestsTargets = _resolveComponent("MatchingListInterestsTargets")!
  const _component_ElTabPane = _resolveComponent("ElTabPane")!
  const _component_MatchingListInterestsUser = _resolveComponent("MatchingListInterestsUser")!
  const _component_ElTabs = _resolveComponent("ElTabs")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.isInterestLoading)
      ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_2, null, 512)), [
          [_directive_loading, _ctx.isInterestLoading]
        ])
      : (_openBlock(), _createBlock(_component_ElTabs, {
          key: 1,
          modelValue: _ctx.activeTab,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.activeTab) = $event)),
          class: _normalizeClass([{
        'el-tabs--dark-line': _ctx.$screen.smDown,
        'el-tabs--blue-line': _ctx.$screen.mdUp,
        'el-tabs--sticky': _ctx.needsStickyTabBar,
      }, "el-tabs--nav-left"]),
          onTabClick: _ctx.clickTabHandler
        }, {
          default: _withCtx(() => [
            _createVNode(_component_ElTabPane, {
              label: _ctx.interestTabs[_ctx.EMatchingListPendingTabs.INTERESTED_IN_ME].label,
              name: _ctx.EMatchingListPendingTabs.INTERESTED_IN_ME
            }, {
              default: _withCtx(() => [
                _createVNode(_component_MatchingListInterestsTargets)
              ]),
              _: 1
            }, 8, ["label", "name"]),
            _createVNode(_component_ElTabPane, {
              label: _ctx.interestTabs[_ctx.EMatchingListPendingTabs.IM_INTERESTED_IN].label,
              name: _ctx.EMatchingListPendingTabs.IM_INTERESTED_IN
            }, {
              default: _withCtx(() => [
                _createVNode(_component_MatchingListInterestsUser)
              ]),
              _: 1
            }, 8, ["label", "name"])
          ]),
          _: 1
        }, 8, ["modelValue", "class", "onTabClick"]))
  ]))
}