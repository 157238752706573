<template>
  <div :class="{ 'is-navigation-bar': $screen.mdOnly }" class="assessment">
    <div class="container-fluid assessment__container">
      <PxButton
        v-if="showBackButton"
        class="assessment__back-button"
        v-bind="$options.static.backButton"
        @click="$router.back()"
      />
      <div v-if="currentCategory && selectedStep" class="assessment__main">
        <div class="assessment__level-slider-column">
          <LevelSlider
            :color="currentCategory.color"
            :levels="currentCategory.categoryDetails.length"
            :value="selectedStep.level"
            height="393px"
            @input="onLevelChange"
          />
          <div
            v-if="selectedStep.level === 0 && isFirstInteraction"
            class="assessment-level-slider-tip"
          >
            <img
              alt="Drag Tip Icon"
              class="assessment-level-slider-tip__icon"
              src="/img/arrow-tip.svg"
            />
            <p class="assessment-level-slider-tip__text">
              {{ $t("selfAssessment.assessment.dragTip") }}
            </p>
          </div>
        </div>
        <LevelsDescription
          :category-details="currentCategory.categoryDetails"
          :current-level="selectedStep.level"
          class="assessment__levels-description"
          height="342px"
        />
        <div class="assessment__requirements-column">
          <CategoryRequirements
            :category="currentCategory"
            :level="selectedStep.level"
            class="assessment__category-requirements"
          />
          <div v-if="selectedStep.level === 0" class="assessment-skip-tip">
            <PxIcon :size="16" name="information" />
            <p class="assessment-skip-tip__text">
              {{ $t("selfAssessment.assessment.noLevelTip") }}
            </p>
          </div>
        </div>
      </div>
      <div v-if="!$screen.mdOnly" class="assessment__footer">
        <div class="assessment__footer-left">
          <p class="assessment__footer-copyright">
            {{
              $t("common.footer.copyright", {
                year: currentYear,
              })
            }}
          </p>
        </div>
        <div class="assessment__footer-right">
          <PxButton
            v-if="selectedCategory > 0"
            :disabled="loading"
            type="link"
            @click="onGoPrev"
          >
            {{ $t("selfAssessment.assessment.prevButton") }}
          </PxButton>
          <PxButton
            :loading="loading"
            :type="nextButtonType"
            class="assessment__next-btn"
            size="medium"
            @click="onGoNext"
          >
            {{ nextButtonText }}
            <i v-if="!isLastStep" :class="nextButtonIconClass" />
          </PxButton>
        </div>
      </div>
    </div>
    <MobileFooterNav
      v-if="$screen.mdOnly"
      :loading="false"
      @next="onGoNext"
      @prev="onGoPrev"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { TranslateResult } from "vue-i18n";

import LevelSlider from "@/modules/self-assessment/components/level-slider/level-slider.vue";
import LevelsDescription from "@/modules/self-assessment/components/levels-description/levels-description.vue";
import CategoryRequirements from "@/modules/self-assessment/components/category-requirements/category-requirements.vue";
import MobileFooterNav from "@/modules/self-assessment/components/mobile-footer-nav/mobile-footer-nav.vue";
import {
  EPxButtonSize,
  EPxButtonVariant,
} from "@/components/px-button/px-button.types";
import { IAssessment } from "@/services/data/assessment/assessment.interface";

export default defineComponent({
  name: "AssessmentDesktop",

  components: {
    LevelSlider,
    LevelsDescription,
    CategoryRequirements,
    MobileFooterNav,
  },

  static: {
    backButton: {
      icon: "arrow--left-dark",
      size: EPxButtonSize.SMALL,
      variant: EPxButtonVariant.COMPACT,
    },
  },

  props: {
    currentCategory: {
      type: Object as () => any,
      default: () => null,
    },

    selectedStep: {
      type: Object as () => any,
      default: () => null,
    },

    onLevelChange: {
      type: Function as () => any,
      required: true,
    },

    selectedCategory: {
      type: Number,
      default: () => null,
    },

    levels: {
      type: Array as () => any[],
      default: () => [],
    },

    loading: {
      type: Boolean,
      default: false,
    },

    onGoNext: {
      type: Function as () => any,
      required: true,
    },

    onGoPrev: {
      type: Function as () => any,
      required: true,
    },

    isFirstInteraction: {
      type: Boolean,
      default: true,
    },

    showBackButton: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      currentYear: new Date().getFullYear(),
    };
  },

  computed: {
    /**
     * Informs if this is the last step.
     */
    isLastStep(): boolean {
      return this.selectedCategory === this.levels.length - 1;
    },

    hasLatestAssessment(): IAssessment | null {
      return this.$store.get("auth/latestAssessment/data");
    },

    /**
     * Get the text for the next button.
     */
    nextButtonText(): TranslateResult {
      if (!this.selectedStep) {
        return this.$t("selfAssessment.assessment.nextButton.skip");
      }

      if (this.isLastStep) {
        return this.$user.isLogged() &&
          this.hasLatestAssessment &&
          this.selectedStep.level !== 0
          ? this.$t("selfAssessment.assessment.nextButton.nextFinalUpdate")
          : this.selectedStep.level === 0
            ? this.$t("selfAssessment.assessment.nextButton.skipFinal")
            : this.$t("selfAssessment.assessment.nextButton.nextFinal");
      }

      return this.selectedStep.level === 0
        ? this.$t("selfAssessment.assessment.nextButton.skip")
        : this.$t("selfAssessment.assessment.nextButton.next");
    },

    /**
     * Returns what type of button must be used for the next button.
     */
    nextButtonType(): string {
      return !this.selectedStep || this.selectedStep.level === 0
        ? "default"
        : "green";
    },

    nextButtonIconClass(): string {
      const iconClass =
        !this.selectedStep || this.selectedStep.level === 0
          ? "arrow--right-grey"
          : "arrow--right-white";
      return `${iconClass} icon--right`;
    },
  },
});
</script>

<style lang="scss">
$--assessment-max-tablet-width: 978px;

.assessment {
  &.is-navigation-bar {
    padding: 80px 0 120px;
  }

  & > div:first-child {
    max-width: 1140px;
  }

  &__container {
    position: relative;
  }

  &__back-button {
    position: absolute;
    top: -34px;
    left: -1px;
    border-radius: 50%;

    .px-icon {
      position: relative;
      left: -1px;
    }
  }

  &__main {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding: 26px 53px 0 76px;

    @media (max-width: $--assessment-max-tablet-width) {
      flex-wrap: wrap;
    }
  }

  &__levels-description {
    position: relative;
    bottom: 37px;
    flex: 1 1 0;
    align-self: flex-end;
    min-width: 0;
  }

  &__requirements-column {
    align-self: flex-start;
    min-width: 330px;
    margin-top: 40px;

    @media (max-width: $--assessment-max-tablet-width) {
      width: 100%;
      padding: 0 48px 0 25px;
    }
  }

  &__level-slider-column {
    position: relative;
  }
}

.assessment-level-slider-tip {
  position: absolute;
  bottom: -18px;
  left: 64px;
  display: flex;

  &__icon {
    position: relative;
    top: 3px;
    left: 1px;
    flex-shrink: 0;
  }

  &__text {
    flex-shrink: 0;
    max-width: 191px;
    margin-left: 10px;

    font-size: 0.8667rem;
    line-height: 1.2rem;
  }
}

.assessment-skip-tip {
  display: flex;
  padding-top: 21px;

  &__text {
    max-width: 302px;
    padding-left: 6px;

    font-size: 0.8667rem;
    font-style: italic;
    line-height: 1.3333rem;
  }
}

.assessment__footer {
  @include container-fluid;

  position: relative;
  display: flex;
  justify-content: space-between;
  max-width: 1139px;
  padding-top: 17px;
  margin: 55px 0 51px;

  &::before {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    display: block;
    height: 1px;

    content: "";

    background: $mischka;
  }

  &-copyright {
    @include grotesk(light);

    padding-top: 11px;
    margin-left: -1px;

    font-size: 0.8667rem;
    line-height: 1.4667rem;
    color: $manatee;
  }

  .el-button {
    margin-left: 20px;
  }

  .assessment__next-btn {
    min-width: 164px;
  }
}
</style>
