import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "level-range-slider__labels" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ElSlider = _resolveComponent("ElSlider")!

  return (_openBlock(), _createElementBlock("div", {
    style: _normalizeStyle({ height: _ctx.height }),
    class: "level-range-slider"
  }, [
    _createVNode(_component_ElSlider, {
      ref: "slider",
      modelValue: _ctx.innerValue,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.innerValue) = $event)),
      max: _ctx.levels,
      min: 1,
      "show-tooltip": false,
      step: 1,
      class: "level-range-slider__inner",
      height: "338px",
      range: "",
      "show-stops": "",
      vertical: "",
      onInput: _cache[1] || (_cache[1] = () => _ctx.handleRangeSliderInputChange)
    }, null, 8, ["modelValue", "max"]),
    _createElementVNode("div", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.stops, (item, index) => {
        return (_openBlock(), _createElementBlock(_Fragment, null, [
          (index !== 0)
            ? (_openBlock(), _createElementBlock("div", {
                key: item,
                class: _normalizeClass([_ctx.currentLevelState(index), "level-range-slider__level-label"]),
                style: _normalizeStyle({ bottom: item + '%' })
              }, [
                _createTextVNode(_toDisplayString(index) + " ", 1),
                _createElementVNode("span", {
                  style: _normalizeStyle({ color: `#${_ctx.color}` }),
                  class: "level-range-slider__level-state"
                }, null, 4)
              ], 6))
            : _createCommentVNode("", true),
          (index !== 0)
            ? (_openBlock(), _createElementBlock("div", {
                key: 'stop-' + item,
                class: _normalizeClass([{
            'is-active': _ctx.isLevelBeingSelected(index),
          }, "level-range-slider__stop"]),
                style: _normalizeStyle({ bottom: item + '%' })
              }, null, 6))
            : _createCommentVNode("", true)
        ], 64))
      }), 256))
    ])
  ], 4))
}