import { Module } from "vuex";

import { IAuthState } from "./auth-types";
import { RootState } from "@/services/store/root-state";

import { actions } from "./auth-actions";
import { make } from "vuex-pathify";
import { companyModuleFactory } from "@/modules/profile/services/store/factory/company/company.module";
import { userMetadataModule } from "./sub-modules/user-metadata/user-metadata.module";
import { supporterModuleFactory } from "@/modules/profile/services/store/factory/supporter/supporter.module";
import { authLatestAssessment } from "./sub-modules/auth-latest-assessment/auth-latest-assessment.module";
import { authMilestones } from "./sub-modules/auth-milestones/auth-milestones.module";
import { authMilestonePlanners } from "./sub-modules/auth-milestone-planners/auth-milestone-planners.module";

/**
 * Initial state for the user store.
 */
const state: IAuthState = {
  error: null,
  loading: false,
  user: null,
  profileId: null,
  profileUid: null,
  isAdmin: false,
  verifiedAccount: false,
  subscription: null,
};

export const authModule: Module<IAuthState, RootState> = {
  namespaced: true,
  state,
  mutations: make.mutations(state),
  actions,
  modules: {
    company: companyModuleFactory(),
    supporter: supporterModuleFactory(),
    latestAssessment: authLatestAssessment,
    metadata: userMetadataModule,
    milestones: authMilestones,
    milestonePlanners: authMilestonePlanners,
  },
};
