<template>
  <div class="share-planner">
    <PxButton
      class="share-planner__toggler"
      v-bind="shareButtonProps"
      @click.stop="openShareModalClickHandler"
    />
    <SharePlannerModal
      v-model:visibility="shareModalVisible"
      :passcode="plannerPasscode"
      :uid="milestonePlanner.uid"
      :users="allInvitedUsers"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import {
  IMilestonePlanner,
  IMilestonePlannerInvitedUser,
} from "@/modules/milestone-planner/services/store/milestone-planner/milestone-planner.types";
import SharePlannerModal from "@/modules/milestone-planner/components/share-planner/share-planner-modal.vue";
import {
  EPxButtonSize,
  EPxButtonVariant,
} from "@/components/px-button/px-button.types";
import { ICompanyListInvitedGuest } from "@/modules/company-lists/services/data/company-list/company-list.interface";

export default defineComponent({
  name: "SharePlanner",

  components: {
    SharePlannerModal,
  },

  props: {
    milestonePlanner: {
      type: Object as () => IMilestonePlanner,
      required: true,
    },
  },

  static: {
    shareButton: {
      size: EPxButtonSize.SMALL,
      variant: EPxButtonVariant.FLUID,
    },
  },

  data() {
    return {
      shareModalVisible: false,
    };
  },

  computed: {
    shareButtonProps(): any {
      return {
        ...this.$options.static.shareButton,
        label: this.$t("milestonePlanner.share"),
      };
    },

    plannerPasscode(): string {
      return this.milestonePlanner.passcode || "";
    },

    allInvitedUsers(): Array<
      IMilestonePlannerInvitedUser | ICompanyListInvitedGuest
    > {
      return [
        ...(this.milestonePlanner?.invited_users || []),
        ...(this.milestonePlanner?.invited_guests || []),
      ].sort((userA, userB) => userA.name.localeCompare(userB.name));
    },
  },

  methods: {
    openShareModalClickHandler() {
      this.shareModalVisible = true;
    },
  },
});
</script>

<style lang="scss" scoped>
.share-planner__toggler {
  @include grotesk(bold);

  width: 66px;
  padding: 0;

  :deep() span {
    font-size: 13px;
    line-height: 16px;
  }
}
</style>
