import { vModelCheckbox as _vModelCheckbox, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-57b6436b"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "level-card__header" }
const _hoisted_2 = { class: "level-card__title" }
const _hoisted_3 = { class: "level-card__content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PxButton = _resolveComponent("PxButton")!

  return (_openBlock(), _createElementBlock("div", {
    style: _normalizeStyle(`--categoryColor: #${_ctx.categoryColor}`),
    class: "level-card"
  }, [
    _createElementVNode("div", _hoisted_1, [
      _withDirectives(_createElementVNode("input", {
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.innerValue) = $event)),
        class: "level-card-checkbox",
        type: "checkbox",
        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onCheckboxClick && _ctx.onCheckboxClick(...args)))
      }, null, 512), [
        [_vModelCheckbox, _ctx.innerValue]
      ]),
      _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.$t("selfAssessment.mobileAssessment.levelCard.level", {
            level: _ctx.details.level.value,
          })), 1),
      _createVNode(_component_PxButton, {
        class: "level-card__criteria",
        type: "link",
        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('see-criteria')))
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t("selfAssessment.mobileAssessment.seeCriteria")), 1)
        ]),
        _: 1
      })
    ]),
    _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.details.achievements), 1)
  ], 4))
}