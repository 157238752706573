<template>
  <div
    :class="`px-matching-interest--${size}`"
    class="px-matching-interest"
    @mouseover="mouseOverCTAHandler"
  >
    <div
      v-if="matchingTip && withDescription && $screen.lgDown"
      class="px-matching-interest__tip"
    >
      <PxIcon
        :size="14"
        class="px-matching-interest__tipIcon"
        name="star--yellow"
      />
      {{ matchingTip }}
    </div>
    <PxButton
      :icon="ctaIcon"
      :label="ctaText"
      :size="size"
      :type="ctaType"
      class="px-matching-interest__cta"
      @click="clickInterestCTAHandler"
      @mouseleave="() => setCtaHoverState(false)"
      @mouseover="() => setCtaHoverState(true)"
    ></PxButton>
    <div
      v-if="matchingTip && withDescription && $screen.xlUp"
      class="px-matching-interest__tip"
    >
      <PxIcon
        :size="14"
        class="px-matching-interest__tipIcon"
        name="star--yellow"
      />
      {{ matchingTip }}
    </div>
    <PxMatchingInterestRemovalModal
      v-if="isRemoveInterestModalVisible"
      v-model:has-server-error="hasServerError"
      v-model:is-loading="isLoading"
      v-model:visibility="isRemoveInterestModalVisible"
      :interest-name="name"
      :interest-state="interestState"
      @removed-interest="onRemovedInterest"
    />
    <PxMatchingInterestDirectoryMemberModal
      v-if="isDirectoryMemberModalVisible"
      v-model:has-server-error="hasServerError"
      v-model:is-loading="isLoading"
      v-model:visibility="isDirectoryMemberModalVisible"
      :company-uid="uid"
      @connect="clickInterestCTAHandler"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import get from "lodash/get";
import PxMatchingInterestMixin from "@/mixins/px-matching-interest.mixin";

export default defineComponent({
  name: "PxMatchingInterest",

  mixins: [PxMatchingInterestMixin],

  props: {
    withDescription: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      needsToHideTooltip: false,
    };
  },

  computed: {
    matchingTip(): string {
      if (this.targetInterest)
        return this.$t(
          `matching.matchingInterest.cta[${this.currentCTAState}].tip`,
          { company: this.name },
        ) as string;

      return "";
    },
    ctaIconSize(): number {
      return get(
        (this as any).iconSizes,
        this.size,
        this.cta.iconSizes.default,
      );
    },
  },

  watch: {
    isLoading() {
      this.needsToHideTooltip = true;
    },
  },

  methods: {
    mouseOverCTAHandler() {
      this.needsToHideTooltip = false;
    },

    setCtaHoverState(state: boolean) {
      this.isCtaHover = state;
    },
  },
});
</script>

<style lang="scss" scoped>
.px-matching-interest :deep() {
  display: flex;
  flex-flow: column wrap;
  align-items: flex-end;

  .px-matching-interest__ctaTip {
    @include grotesk(regular, italic);

    margin-top: 8px;
    font-size: 14px;
    line-height: 17px;
    color: $manatee;

    @include breakpoint-up(md) {
      max-width: 230px;
      text-align: right;
    }

    @include breakpoint-up(lg) {
      max-width: 290px;
    }
  }

  .el-button span {
    transition: none;
  }

  .el-button--ghost-green:hover span {
    color: $apple;
  }

  .el-button--ghost:hover span {
    color: $manatee;
  }
}

.px-matching-interest__tip {
  @include grotesk(medium);

  max-width: 290px;
  margin-bottom: 8px;
  font-size: 14px;
  line-height: 17px;
  color: $golden;
  text-align: center;

  @include breakpoint-up(md) {
    text-align: right;
  }

  @include breakpoint-up(xl) {
    max-width: 230px;
    margin: 9px 0 0;
  }
}

.px-matching-interest__tipIcon {
  position: relative;
  top: 2px;
  margin-right: 1px;
}

/* Custom "Interested" CTA styles */
.px-matching-interest__cta.el-button--ghost-green :deep() {
  .px-icon {
    max-width: 12px;
    max-height: 12px;
  }

  &:hover .px-icon {
    filter: brightness(75%);
  }
}
</style>

<style lang="scss">
.px-matching-interest__cta-tooltip {
  max-width: 275px;
  padding: 5px 10px;
  text-transform: none;
  pointer-events: none;
}
</style>
