import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, openBlock as _openBlock, createBlock as _createBlock, mergeProps as _mergeProps, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-60e086c4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "list-detail-panel-header" }
const _hoisted_2 = { class: "list-detail-panel-header__wrapper" }
const _hoisted_3 = { class: "list-detail-panel-header__content" }
const _hoisted_4 = { class: "list-detail-panel-header__top" }
const _hoisted_5 = ["textContent"]
const _hoisted_6 = ["textContent"]
const _hoisted_7 = { class: "list-detail-panel-header__column-heading" }
const _hoisted_8 = { class: "list-detail-panel-header__heading" }
const _hoisted_9 = { class: "list-detail-panel-header__subtitle" }
const _hoisted_10 = {
  key: 0,
  class: "list-detail-panel-header__column-heading"
}
const _hoisted_11 = { class: "list-detail-panel-header__heading" }
const _hoisted_12 = {
  key: 1,
  class: "list-detail-panel-header__tip"
}
const _hoisted_13 = {
  key: 1,
  class: "list-detail-panel-header__actions"
}
const _hoisted_14 = {
  key: 0,
  class: "list-detail-panel-header__ribbon"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PxStatusBadge = _resolveComponent("PxStatusBadge")!
  const _component_PxIcon = _resolveComponent("PxIcon")!
  const _component_PxAvatarSet = _resolveComponent("PxAvatarSet")!
  const _component_ListShare = _resolveComponent("ListShare")!
  const _component_ListOptions = _resolveComponent("ListOptions")!
  const _component_i18n_t = _resolveComponent("i18n-t")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("h3", {
            class: "list-detail-panel-header__title",
            textContent: _toDisplayString(_ctx.title)
          }, null, 8, _hoisted_5),
          (_ctx.isSmartList)
            ? (_openBlock(), _createBlock(_component_PxStatusBadge, _normalizeProps(_mergeProps({ key: 0 }, _ctx.smartListBadge)), null, 16))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("p", {
          class: "list-detail-panel-header__description",
          textContent: _toDisplayString(_ctx.description)
        }, null, 8, _hoisted_6)
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("h4", _hoisted_8, [
          _createVNode(_component_PxIcon, {
            size: 14,
            name: "hashtag"
          }),
          _createTextVNode(" " + _toDisplayString(_ctx.$t("companyLists.list.members")), 1)
        ]),
        _createElementVNode("h3", _hoisted_9, _toDisplayString(_ctx.$tc(
              `companyLists.list.membersCount[${_ctx.listUserType}]`,
              _ctx.memberQuantity,
            )), 1)
      ]),
      (_ctx.isOwner)
        ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
            _createElementVNode("h4", _hoisted_11, [
              _createVNode(_component_PxIcon, {
                size: 14,
                name: "arrow-share"
              }),
              _createTextVNode(" " + _toDisplayString(_ctx.$t("companyLists.list.sharedWith")), 1)
            ]),
            (_ctx.userAvatars.length)
              ? (_openBlock(), _createBlock(_component_PxAvatarSet, {
                  key: 0,
                  items: _ctx.userAvatars,
                  size: 21,
                  "visible-limit": 5,
                  class: "list-detail-panel-header__logo-set"
                }, null, 8, ["items"]))
              : (_openBlock(), _createElementBlock("h3", _hoisted_12, [
                  _createVNode(_component_PxIcon, {
                    size: 16,
                    name: "lock"
                  }),
                  _createTextVNode(" " + _toDisplayString(_ctx.$t("companyLists.list.sharedEmpty")), 1)
                ]))
          ]))
        : _createCommentVNode("", true),
      (!!_ctx.value && _ctx.$user.isLogged())
        ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
            (_ctx.isOwner)
              ? (_openBlock(), _createBlock(_component_ListShare, {
                  key: 0,
                  value: _ctx.value,
                  class: "list-detail-panel-header__actions-item"
                }, null, 8, ["value"]))
              : _createCommentVNode("", true),
            _createVNode(_component_ListOptions, {
              value: _ctx.value,
              class: "list-detail-panel-header__actions-item"
            }, null, 8, ["value"])
          ]))
        : _createCommentVNode("", true)
    ]),
    (!_ctx.$user.isLogged())
      ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
          _createVNode(_component_i18n_t, {
            class: "list-detail-panel-header__ribbon-text",
            keypath: "companyLists.detail.visitor.teaser",
            tag: "span"
          }, {
            cta: _withCtx(() => [
              _createElementVNode("a", {
                class: "list-detail-panel-header__ribbon-cta",
                onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.joinClickHandler && _ctx.joinClickHandler(...args)))
              }, _toDisplayString(_ctx.joinCta), 1)
            ]),
            _: 1
          })
        ]))
      : _createCommentVNode("", true)
  ]))
}