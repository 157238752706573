<template>
  <div class="pxMoney">
    <ElInput
      v-model="innerValue"
      :name="name"
      :placeholder="placeholderText"
      class="pxMoney-input"
      :formatter="
        (value: string) => {
          // Remove non-numeric characters and format with commas
          return value.replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        }
      "
      :parser="
        (value: string) => {
          // Remove non-numeric characters
          return value.replace(/\D/g, '');
        }
      "
    >
      <template #prefix>
        <span>$</span>
      </template>
    </ElInput>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import emitter from "@/mixins/emitter";

import { TranslateResult } from "vue-i18n";

export default defineComponent({
  name: "PxMoney",

  mixins: [emitter],

  props: {
    /**
     * Value for the money field.
     */
    modelValue: {
      type: String,
      default: "",
    },

    /**
     * Optional name for the field.
     */
    name: {
      type: String,
      default: "money",
    },

    /**
     * Placeholder for the field.
     */
    placeholder: {
      type: String,
      default: "affiliateProgram.questions.numericPlaceholder",
    },
  },

  data() {
    return {
      innerValue: "" as string,
    };
  },

  computed: {
    placeholderText(): TranslateResult {
      return this.$t(this.placeholder, {
        number: "50,000",
      });
    },
  },

  watch: {
    modelValue(newVal) {
      if (newVal !== this.innerValue) {
        this.dispatch("ElFormItem", "el.form.change", [newVal]);
        this.innerValue = newVal;
      }
    },

    innerValue(newVal) {
      if (newVal !== this.modelValue) {
        this.$emit("update:modelValue", newVal);
        this.$emit("change", newVal);
        this.dispatch("ElFormItem", "el.form.change", [newVal]);
      }
    },
  },

  created() {
    // Default value at start
    this.innerValue = this.modelValue;
  },
});
</script>

<style lang="scss" scoped>
$--height: 38px;
$--background-color: rgba(255, 255, 255, 0.2);
$--placeholder-color: $manatee;
$--border-color: rgba(white, 0.3);

.pxMoney {
  position: relative;
  display: block;

  &-input {
    background-color: $white;
    border: 1px solid $mischka;
    border-radius: 2px;
    box-shadow: inset 0 0 5px 0 rgba(0, 0, 0, 0.07);
  }

  :deep(.el-input__prefix) {
    top: 5px;
    left: 11px;

    span {
      @include grotesk(semiBold);

      font-size: to-rem(16px);
      color: rgba(139, 143, 161, 0.6);
    }
  }

  :deep(.el-input__prefix::after) {
    right: -11px;
    top: 1px;
  }

  :deep(.el-input__inner) {
    @include grotesk(regular);

    box-sizing: border-box;
    display: inline-block;
    width: 100%;
    height: $--height;
    padding-left: 42px;

    font-size: to-rem(15px);
    line-height: $--height;
    letter-spacing: -0.25px;

    background-color: $--background-color;

    border: 1px solid $--border-color;
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;

    &:focus {
      box-shadow: none;
    }

    &::placeholder {
      font-size: to-rem(15px);
      color: $--placeholder-color;
    }
  }
}
</style>
