import { ActionTree } from "vuex";
import { ICategoryState } from "@/services/store/category/category-types";
import { RootState } from "@/services/store/root-state";
import { generateBaseActionsFor } from "@/services/store/utils/base-generators";
import { ICategory } from "@/services/data/category/category.interface";
import { categoryProvider } from "@/services/data/category/category.provider";

export const actions: ActionTree<ICategoryState, RootState> =
  generateBaseActionsFor<ICategoryState, ICategory>(
    "categories",
    categoryProvider,
  );
