<template>
  <div
    v-loading="isLoading"
    :class="{ 'team-member-demographic-data--loading': isLoading }"
    class="team-member-demographic-data"
  >
    <template v-if="!isLoading">
      <div class="team-member-demographic-data__header">
        <div class="team-member-demographic-data__title">
          <h4>
            {{ headerCopy.title }}
          </h4>
          <div class="team-member-demographic-data__info">
            <PxIcon :size="16" name="icon-information" />
            <p v-html="$t('teamManagement.settings.demographicData.info')" />
          </div>
        </div>
        <p class="team-member-demographic-data__subtitle">
          {{ headerCopy.subtitle }}
        </p>
      </div>
      <div class="team-member-demographic-data__questions">
        <ElCollapse :accordion="true">
          <template v-for="(question, index) in questions">
            <CollapsibleQuestion
              v-if="question.id"
              :key="question.id"
              :data-testid="`team-member-demographic-data-question--${question.id}`"
              :question="question"
              :response="currentResponses ? currentResponses[index] : null"
              @handle-new-response="updateResponses"
              @response-formatted="handleFormattedResponse"
            />
          </template>
        </ElCollapse>
      </div>
    </template>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import cloneDeep from "lodash/cloneDeep";

import CollapsibleQuestion from "@/components/collapsible-question/collapsible-question.vue";

import { IMatchingResponse } from "@/services/data/matching-responses/matching-response.interface";
import { IMatchingQuestion } from "@/services/data/matching-questionary/matching-question.interface";

export default defineComponent({
  name: "TeamMemberDemographicData",

  components: {
    CollapsibleQuestion,
  },

  props: {
    responses: {
      type: Array as () => Array<IMatchingResponse>,
      default: () => [],
    },

    questions: {
      type: Array as () => Array<IMatchingQuestion>,
      default: () => [],
    },

    isLoading: {
      type: Boolean,
      default: false,
    },

    shouldFormatResponses: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      currentResponses: [] as Array<IMatchingResponse> | null,
      responsesState: [] as any | null,
      responsesAreFormatted: false,
    };
  },

  computed: {
    headerCopy() {
      return this.$tm("teamManagement.settings.demographicData") as {
        title: string;
        subtitle: string;
        info: string;
      };
    },
  },

  watch: {
    responses: {
      immediate: true,
      deep: true,
      handler(newResponses: Array<IMatchingResponse>) {
        this.currentResponses = cloneDeep(newResponses);

        this.responsesState = this.currentResponses.map(
          (response: IMatchingResponse) => ({
            question: response.question,
            is_valid: this.checkValidResponse(response),
            is_formatted: false,
          }),
        );
      },
    },

    shouldFormatResponses: {
      handler(shouldFormat: boolean) {
        if (shouldFormat) {
          this.responsesAreFormatted = false;
        }
      },
    },
  },

  beforeUnmount() {
    this.currentResponses = null;
    this.responsesState = null;
  },

  methods: {
    checkValidResponse(response: IMatchingResponse) {
      return (
        !!response.answers?.length ||
        !!response.value?.value ||
        !!response.value?.date ||
        (!!response?.value?.max && !!response?.value?.min) ||
        !!response.value?.text ||
        !response.answers.length ||
        !response.value
      );
    },

    handleFormattedResponse(questionId: number) {
      // Update responses state
      this.responsesState = this.responsesState.map((response: any) => {
        return response.question === questionId
          ? { ...response, is_formatted: true }
          : response;
      });
    },

    updateResponses(isValid: boolean, newResponse: IMatchingResponse) {
      // Update responses state
      this.responsesState = this.responsesState.map((response: any) => {
        return response.question === newResponse.question
          ? { ...response, is_valid: isValid }
          : response;
      });

      // Update responses
      this.currentResponses =
        this.currentResponses?.map((response: IMatchingResponse) => {
          return response.question === newResponse.question
            ? newResponse
            : response;
        }) || [];

      const hasInvalidResponse = this.responsesState.some(
        (response: any) => !response.is_valid,
      );

      const allResponsesFormatted = this.responsesState.every(
        (response: any) => response.is_formatted,
      );

      if (allResponsesFormatted && !this.responsesAreFormatted) {
        this.$emit("responses-formatted", this.currentResponses);
        this.$emit("update:shouldFormatResponses", false);
        this.responsesAreFormatted = true;
      }

      if (this.responsesAreFormatted) {
        this.$emit("has-new-responses", this.currentResponses);
      }

      this.$emit("form-is-invalid", hasInvalidResponse);
    },
  },
});
</script>

<style lang="scss" scoped>
.team-member-demographic-data {
  background-color: $alabaster;
  border: 1px solid $turkish-angora;
  border-radius: 4px;
  box-shadow: 0 4px 8px -4px rgba(139, 143, 161, 0.2);

  &--loading {
    min-height: 400px;
  }

  &__header {
    padding: 22px 24px 31px 23px;
  }

  &__title {
    display: flex;
    justify-content: space-between;

    margin-bottom: 8px;
    margin-left: 1px;

    h4 {
      font-size: 20px;
      line-height: 32px;
      margin: 0;

      color: $ebony-clay;
    }
  }

  &__info {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 6px;

    p {
      margin-top: 4px;
      font-size: 13px;
      color: $ebony-clay;
      font-style: italic;
    }
  }

  &__subtitle {
    color: $manatee;
    font-size: 14px;
    letter-spacing: -0.28px;
    line-height: 1.44;
  }

  &__questions {
    padding: 0 23px 23px;
  }

  :deep(.el-collapse-item__content) {
    padding: 0 23px 20px;
  }
}
</style>
