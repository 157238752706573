import { actions } from "@/modules/capital-explorer/services/store/capital-explorer/capital-explorer.actions";
import { ICapitalExplorerState } from "@/modules/capital-explorer/services/store/capital-explorer/capital-explorer.types";
import { RootState } from "@/services/store/root-state";
import { Module } from "vuex";
import { make } from "vuex-pathify";

/**
 * Initial state for the capital explorer
 */
export const state: ICapitalExplorerState = {
  loading: false,
  error: null,
  data: null,
  visitorData: null,
  termsAccepted: false,
  passcodes: {},
};

export const capitalExplorerModule: Module<ICapitalExplorerState, RootState> = {
  namespaced: true,
  state,
  actions,
  mutations: make.mutations(state),
};
