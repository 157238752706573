<template>
  <div class="criteria-multi-selector__container">
    <MultiSelectorWrapper :label="label" :no-label="noLabel" :tip="tip">
      <template #selected>
        <div>
          <MultiSelectorEntry
            v-for="item in entryItems"
            :key="item.value"
            :item="item"
            :show-parent-label="true"
            @edit="openQuestionPanel"
            @remove="onRemoveItemClick"
          />
        </div>
      </template>
      <template #input>
        <div>
          <ElSelect
            ref="select"
            v-model="innerSelectedItem"
            :filter-method="searchByQuery"
            :filterable="true"
            :placeholder="$t(placeholder)"
            :popper-append-to-body="false"
            automatic-dropdown
            class="multi-selector__select"
            popper-class="criteria-multi-selector__popper"
            @blur="blurHandler"
            @focus="focusHandler"
            @visible-change="handleCriteriaPanelVisibleChange"
          >
            <template #prefix>
              <PxIcon :size="24" :name="inputIcon" />
            </template>
            <CriteriaMultiSelectorOptionPanel
              v-model:highlight-item="highlightedItem"
              :highlight-text="searchQuery"
              :items="searchedItems"
              :selected-items="innerValue"
              @update="addSelectedItem"
            />
          </ElSelect>
        </div>
      </template>
    </MultiSelectorWrapper>
    <div class="criteria-multi-selector__common-container">
      <div
        v-for="category in mostCommonCriteria"
        :key="`criteria-category--${category.label}`"
      >
        <i18n-t
          class="criteria-multi-selector__common-label"
          keypath="supporters.form.additionalInterests.mostCommonCriteriaTitle"
          tag="p"
        >
          <template #category>
            {{ category.label }}
          </template>
        </i18n-t>
        <span
          v-for="item in category.children"
          :key="`criteria-item--${item.label}`"
          class="criteria-multi-selector__common-item"
          @click="openQuestionPanel(+item.value)"
        >
          {{ item.label }}
        </span>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import cloneDeep from "lodash/cloneDeep";
import isEqual from "lodash/isEqual";
import moment from "moment";

import MultiSelectorWrapper from "@/components/multi-selector-wrapper/multi-selector-wrapper.vue";
import MultiSelectorEntry from "@/components/multi-selector-entry/multi-selector-entry.vue";
import CriteriaMultiSelectorOptionPanel from "@/modules/supporters/components/criteria-multi-selector/criteria-multi-selector-option-panel.vue";
import { IMultiSelectorItem } from "@/components/multi-selector/multi-selector.interface";
import { IMatchingResponse } from "@/services/data/matching-responses/matching-response.interface";
import { IMatchingQuestion } from "@/services/data/matching-questionary/matching-question.interface";
import { IMatchingAnswer } from "@/services/data/matching-questionary/matching-answer.interface";

export default defineComponent({
  name: "CriteriaMultiSelector",

  components: {
    MultiSelectorWrapper,
    MultiSelectorEntry,
    CriteriaMultiSelectorOptionPanel,
  },

  props: {
    modelValue: {
      type: Array as () => Array<IMatchingResponse>,
      required: true,
    },

    values: {
      type: Array as () => Array<IMultiSelectorItem>,
      default: () => [],
    },

    /**
     * When set to `true` the label won't be rendered.
     */
    noLabel: {
      type: Boolean,
      default: false,
    },

    label: {
      type: String,
      default: "common.components.default.input.label",
    },

    placeholder: {
      type: String,
      default: "common.components.default.input.placeholder",
    },

    tip: {
      type: String,
      default: "common.components.default.input.tip",
    },

    subtextLabelList: {
      type: Array as () => Array<string>,
      default: () => [
        "common.components.default.input.subLabel",
        "common.components.default.input.subLabelPlural",
      ],
    },

    inputIcon: {
      type: String,
      default: "magnifier",
      validator: (value: string) => ["magnifier", "location"].includes(value),
    },
  },

  data() {
    return {
      // Will store the list of selected items
      innerValue: [] as Array<IMatchingResponse>,

      searchedItems: [] as Array<IMultiSelectorItem>,
      innerSelectedItem: "" as string | IMultiSelectorItem,
      searchQuery: "",

      // Tracks how many search results exist
      searchCount: null as null | number,

      // Store latest search query and items to avoid repetitive requests
      latestSearch: null as null | {
        query: string;
        items: Array<IMultiSelectorItem>;
      },

      highlightedItem: null as IMultiSelectorItem | null,
      mostCommonCriteria: [] as Array<IMultiSelectorItem>,
    };
  },

  computed: {
    isSearchRemote(): boolean {
      return this.searchQuery !== "";
    },

    /**
     * Returns flatten array with child items based on values array.
     */
    flattenValues(): Array<IMultiSelectorItem> {
      return this.values.reduce(
        (accumulator: Array<IMultiSelectorItem>, item: IMultiSelectorItem) => [
          ...accumulator,
          ...(item?.children || []),
        ],
        [],
      );
    },

    eligibleCriteria(): Array<IMatchingResponse> {
      // Criteria that impacts matching results:
      return this.innerValue.filter(
        (criteria) =>
          !!criteria?.answers ||
          (!!criteria?.value &&
            ("value" in criteria.value ||
              ("min" in criteria.value && "max" in criteria.value))),
      );
    },

    entryItems(): Array<IMultiSelectorItem> {
      return this.eligibleCriteria.map((item: IMatchingResponse) => {
        // 1. Fetch associated criteria represented as multi selector item
        const criteria = this.flattenValues.find(
          (criteriaItem: IMultiSelectorItem) =>
            criteriaItem.value === item.question,
        );

        // 2. Map response as child label
        const children = [
          {
            value: item.question,
            label: this.getAnswer({ ...item, question: criteria?.meta || {} }),
            selected: true,
          },
        ];

        // 3. Stitch all together
        return {
          ...criteria,
          children,
        } as IMultiSelectorItem;
      });
    },
  },

  watch: {
    modelValue: {
      deep: true,
      immediate: true,
      handler(newValue: Array<IMatchingResponse>) {
        this.innerValue = newValue;
      },
    },

    innerValue: {
      deep: true,
      handler(newInnerValue: Array<IMatchingResponse>) {
        // Emit new selected values
        if (!isEqual(newInnerValue, this.modelValue)) {
          this.$emit("update:modelValue", newInnerValue);
          this.$emit("change", newInnerValue);
        }
      },
    },

    searchedItems: {
      deep: true,
      handler(newSearchedItems) {
        if (
          newSearchedItems.length &&
          (!this.latestSearch ||
            !isEqual(newSearchedItems, this.latestSearch.items))
        ) {
          this.latestSearch = {
            query: this.searchQuery,
            items: cloneDeep(newSearchedItems),
          };
        }
      },
    },

    latestSearch: {
      deep: true,
      handler(search: any) {
        if (search?.items?.length) {
          this.innerValue = this.modelValue;
        }
      },
    },

    values: {
      deep: true,
      immediate: true,
      handler(newValues: Array<IMultiSelectorItem>) {
        // Reset common array
        this.mostCommonCriteria = [];

        // Filter common children to know if we must shown parent as common criteria category
        newValues.forEach((item: IMultiSelectorItem) => {
          const commonChildren = item.children?.filter(
            (child: IMultiSelectorItem) => child.meta?.is_common,
          );

          if (commonChildren?.length) {
            this.mostCommonCriteria.push({
              ...item,
              children: commonChildren,
            });
          }
        });
      },
    },
  },

  created() {
    // Fetch all top results first
    this.searchByQuery();
  },

  methods: {
    /**
     * Wrap search method.
     *
     * @param query search text
     */
    async searchByQuery(query = "") {
      // Do a temporary hack to allow search for all top results.
      this.searchQuery = query;

      // If not remote search method, set values directly and exit
      this.searchCount = this.values.length;
      this.searchedItems = this.values;
    },

    /**
     * Overriding method to remove selected item to show on
     * select component input
     */
    persistSearchQuery() {
      this.innerSelectedItem = this.searchQuery;
    },

    /**
     * Update selected items
     */
    updateSelectedItems(items: Array<IMatchingResponse>) {
      this.persistSearchQuery();
      this.innerValue = [...items];
    },

    /**
     * Resets current select values
     */
    resetSearch() {
      this.searchedItems = [];
      this.innerSelectedItem = "";

      // Reset search to all top results
      this.searchByQuery();
    },

    /**
     * Remove the given entry from the list of selected items.
     *
     * @param value removed item value
     */
    onRemoveItemClick(value: number) {
      const indexToRemove = this.innerValue.findIndex(
        (option: IMatchingResponse) => option.question === value,
      );
      this.innerValue.splice(indexToRemove, 1);

      // Prevent leftovers
      this.resetSearch();
    },

    /**
     * Override for select blur handler
     * @param event
     */
    blurHandler(event: any) {
      this.$emit("blur", event);
      this.$emit("change", this.innerValue);

      // TODO: Find a better way to trigger form validaton on blur
      // eslint-disable-next-line vue/custom-event-name-casing
      (this as any).$refs.select.$parent.$parent.$emit("el.form.blur");
    },

    focusHandler(event: any) {
      if (
        !event.target.value &&
        !this.innerSelectedItem &&
        this.isSearchRemote
      ) {
        this.resetSearch();
      }
    },

    /**
     * Update selected items based on selected criteria items.
     */
    addSelectedItem(newItem: IMatchingResponse) {
      // Filter previously given answer for same question
      const filteredItems = this.innerValue.filter(
        (item: IMatchingResponse) => item.question !== newItem.question,
      );

      // Format value
      const formattedResponse = (
        !newItem.answers.length
          ? {
              question: newItem.question,
              value: newItem.value,
            }
          : {
              question: newItem.question,
              answers: newItem.answers,
            }
      ) as IMatchingResponse;

      this.updateSelectedItems([...filteredItems, { ...formattedResponse }]);
    },

    openQuestionPanel(value: number) {
      // Update highlighted item to trigger question load within question panel
      this.highlightedItem =
        this.flattenValues.find(
          (item: IMultiSelectorItem) => item.value === value,
        ) || null;

      (this.$refs.select as any).toggleMenu();
    },

    handleCriteriaPanelVisibleChange(visible: boolean) {
      if (!visible) {
        this.resetSearch();
        this.highlightedItem = null;
      }
    },

    getAnswer(response: IMatchingResponse): string {
      if (response.answers?.length > 0) {
        return response.answers.reduce(
          (previousValue: string, currentValue: number) => {
            const question = response.question as IMatchingQuestion;
            const questionAnswer = question.answers.find(
              (answer: IMatchingAnswer) => answer.id === currentValue,
            );
            const separator = previousValue !== "" ? ", " : "";

            return questionAnswer
              ? `${previousValue}${separator}${questionAnswer.value}`
              : `${previousValue}`;
          },
          "" as string,
        );
      }

      const answerValue = response.value as IMatchingResponse["value"];

      // If no value was found, as it didn't as well for answers, return an empty string
      if (!answerValue) {
        return "";
      }

      // Date format
      if (answerValue.date) {
        return moment(answerValue.date, "YYYY-MM-DD").format("D MMMM YYYY");
      }

      // Numeric format
      if (
        (answerValue.min !== undefined && answerValue.max !== undefined) ||
        answerValue.value !== undefined
      ) {
        const question = response.question as IMatchingQuestion;
        const numericCurrency = question.question_type.meta.currency ? "$" : "";

        return answerValue.min !== undefined && answerValue.max !== undefined
          ? `${numericCurrency}${(
              answerValue.min as number
            ).toLocaleString()} - ${numericCurrency}${(
              answerValue.max as number
            ).toLocaleString()}`
          : `${numericCurrency}${(
              answerValue.value as number
            ).toLocaleString()}`;
      }

      // No property was found return empty string fallback value
      return "";
    },
  },
});
</script>

<style lang="scss" scoped>
.criteria-multi-selector__common-container {
  display: grid;
  grid-gap: 15px;
  margin-top: 37px;
}

.criteria-multi-selector__common-label {
  @include grotesk(semiBold);

  margin-bottom: 4px;
  font-size: 14px;
  color: rgba($manatee, 0.7);
}

.criteria-multi-selector__common-item {
  @include grotesk(semiBold);

  display: inline-block;
  padding: 2px 14px;
  margin: 0 14px 7px 0;
  font-size: 14px;
  color: $manatee;
  cursor: pointer;
  border: solid 1px $mischka;
  border-radius: 16px;
}

.criteria-multi-selector__container :deep() {
  .multi-selector-entry__info-container {
    display: block;
    word-break: break-word;
  }

  .multi-selector-entry__container {
    gap: 12px;
  }

  .multi-selector-entry__action-container {
    align-items: start;
    padding-top: 6px;
  }
}

.multi-selector__select {
  :deep(.el-select__wrapper) {
    height: 40px;
    background: $white;
    padding: 4px 8px;
  }

  :deep(.el-select__prefix::after) {
    top: 50%;
    left: 32px;
    width: 1px;
    height: 28px;

    transform: translateY(-50%);
  }

  :deep(.el-select__selection) {
    padding-right: 15px;
    padding-left: 15px;
  }

  :deep(.el-select__placeholder span) {
    color: $manatee;
    line-height: 1;
  }
}
</style>
<style lang="scss">
.criteria-multi-selector__popper {
  // Required to follow design guidelines
  min-width: 592px !important;

  .el-select-dropdown__wrap {
    max-height: max-content;
  }

  .el-select-dropdown__list {
    padding: 0;
  }
}
</style>
