import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-568427d0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "lists-directory page-body" }
const _hoisted_2 = { class: "page-container" }
const _hoisted_3 = { class: "lists-directory__wrapper" }
const _hoisted_4 = { class: "lists-directory__actions" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PxIcon = _resolveComponent("PxIcon")!
  const _component_ElInput = _resolveComponent("ElInput")!
  const _component_PxButton = _resolveComponent("PxButton")!
  const _component_CompanyLists = _resolveComponent("CompanyLists")!
  const _component_ElTabPane = _resolveComponent("ElTabPane")!
  const _component_ElTabs = _resolveComponent("ElTabs")!
  const _component_ListManagementBar = _resolveComponent("ListManagementBar")!
  const _component_MatchingConfirmModal = _resolveComponent("MatchingConfirmModal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_ElInput, {
            modelValue: _ctx.filters.list.value,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.filters.list.value) = $event)),
            disabled: _ctx.noListsToDisplay,
            placeholder: _ctx.$t('matching.matchingList.companiesSelector'),
            class: "lists-directory__search",
            clearable: "",
            onInput: _ctx.removeInitialWhiteSpaces
          }, {
            prefix: _withCtx(() => [
              _createVNode(_component_PxIcon, {
                size: 24,
                name: "magnifier"
              })
            ]),
            _: 1
          }, 8, ["modelValue", "disabled", "placeholder", "onInput"]),
          _createVNode(_component_PxButton, {
            class: "lists__main-cta",
            size: "medium",
            type: "green",
            onClick: _ctx.newListClickHandler
          }, {
            default: _withCtx(() => [
              _createVNode(_component_PxIcon, {
                size: 16,
                name: "plus-white"
              }),
              _createTextVNode(" " + _toDisplayString(_ctx.$t("companyLists.directory.createListButton")), 1)
            ]),
            _: 1
          }, 8, ["onClick"])
        ]),
        _createVNode(_component_ElTabs, {
          modelValue: _ctx.selectedTab,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectedTab) = $event)),
          class: _normalizeClass([_ctx.tabsStyle, "el-tabs--nav-left"])
        }, {
          default: _withCtx(() => [
            _createVNode(_component_ElTabPane, {
              label: _ctx.$t(`companyLists.directory.tabs.${_ctx.listTypes.OWNED}`),
              name: _ctx.listTypes.OWNED
            }, {
              default: _withCtx(() => [
                _createVNode(_component_CompanyLists, {
                  ref: "ownedLists",
                  "empty-lists-subtitle": _ctx.ownedEmptyListsSubtitle,
                  "empty-lists-title": _ctx.ownedEmptyListsTitle,
                  "has-filters": _ctx.hasStoredSelectedFilters,
                  "is-loading": _ctx.isLoading,
                  lists: _ctx.ownedListsToDisplay,
                  type: _ctx.listTypes.OWNED
                }, null, 8, ["empty-lists-subtitle", "empty-lists-title", "has-filters", "is-loading", "lists", "type"])
              ]),
              _: 1
            }, 8, ["label", "name"]),
            _createVNode(_component_ElTabPane, {
              label: _ctx.$t(`companyLists.directory.tabs.${_ctx.listTypes.INVITED_TO}`),
              name: _ctx.listTypes.INVITED_TO
            }, {
              default: _withCtx(() => [
                _createVNode(_component_CompanyLists, {
                  ref: "sharedLists",
                  "empty-lists-subtitle": _ctx.sharedEmptyListsSubtitle,
                  "empty-lists-title": _ctx.sharedEmptyListsTitle,
                  "has-filters": _ctx.hasStoredSelectedFilters,
                  "is-loading": _ctx.isSharedLoading,
                  lists: _ctx.sharedListsToDisplay,
                  type: _ctx.listTypes.INVITED_TO
                }, null, 8, ["empty-lists-subtitle", "empty-lists-title", "has-filters", "is-loading", "lists", "type"])
              ]),
              _: 1
            }, 8, ["label", "name"])
          ]),
          _: 1
        }, 8, ["modelValue", "class"])
      ])
    ]),
    _createVNode(_component_ListManagementBar),
    (_ctx.isMatchingConfirmModalVisible)
      ? (_openBlock(), _createBlock(_component_MatchingConfirmModal, {
          key: 0,
          visibility: _ctx.isMatchingConfirmModalVisible,
          "onUpdate:visibility": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.isMatchingConfirmModalVisible) = $event))
        }, null, 8, ["visibility"]))
      : _createCommentVNode("", true)
  ]))
}