import {
  GenericProvider,
  EProviderFeatures,
  Provider,
} from "@/services/data/generic-provider";
import { IPasswordReset } from "@/modules/authentication/services/data/password-reset/password-reset.interface";
import { baseAPIUrl } from "@/services/utils/utils";

/**
 * Provider used to submit a password reset.
 */
@Provider(`${baseAPIUrl}/user/password/reset`, [EProviderFeatures.CREATE])
class PasswordResetProvider extends GenericProvider<any, IPasswordReset> {}

export const passwordResetProvider = new PasswordResetProvider();
