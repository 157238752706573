import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, withModifiers as _withModifiers, withCtx as _withCtx, createBlock as _createBlock, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-6f8a40f9"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "question-panel__wrapper" }
const _hoisted_2 = {
  key: 0,
  class: "question-panel__container"
}
const _hoisted_3 = {
  key: 0,
  class: "question-panel__header"
}
const _hoisted_4 = { class: "question-panel__step" }
const _hoisted_5 = { class: "question-panel__description" }
const _hoisted_6 = { class: "question-panel__content" }
const _hoisted_7 = {
  key: 0,
  class: "question-panel__content-wrapper"
}
const _hoisted_8 = { class: "question-panel__answer-wrapper" }
const _hoisted_9 = ["textContent"]
const _hoisted_10 = { class: "question-panel__answer-wrapper" }
const _hoisted_11 = ["textContent"]
const _hoisted_12 = {
  key: 0,
  class: "question-panel__content-wrapper question-panel__content-wrapper--two-column"
}
const _hoisted_13 = { class: "question-panel__range" }
const _hoisted_14 = { class: "question-panel__range-label" }
const _hoisted_15 = { class: "question-panel__range" }
const _hoisted_16 = { class: "question-panel__range-label" }
const _hoisted_17 = {
  key: 1,
  class: "question-panel__content-wrapper"
}
const _hoisted_18 = {
  key: 0,
  class: "question-panel__content-wrapper"
}
const _hoisted_19 = {
  key: 1,
  class: "question-panel__content-wrapper question-panel__content-wrapper--two-column"
}
const _hoisted_20 = { class: "question-panel__range" }
const _hoisted_21 = { class: "question-panel__range-label" }
const _hoisted_22 = { class: "question-panel__range" }
const _hoisted_23 = { class: "question-panel__range-label" }
const _hoisted_24 = {
  key: 5,
  class: "question-panel__content-wrapper"
}
const _hoisted_25 = {
  key: 1,
  class: "question-panel__info"
}
const _hoisted_26 = { class: "question-panel__info-wrapper" }
const _hoisted_27 = { class: "question-panel__info-title" }
const _hoisted_28 = {
  key: 0,
  class: "question-panel__info-notice"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PxTextarea = _resolveComponent("PxTextarea")!
  const _component_ElTooltip = _resolveComponent("ElTooltip")!
  const _component_ElRadio = _resolveComponent("ElRadio")!
  const _component_PxRadioGroup = _resolveComponent("PxRadioGroup")!
  const _component_ElCheckbox = _resolveComponent("ElCheckbox")!
  const _component_ElCheckboxGroup = _resolveComponent("ElCheckboxGroup")!
  const _component_PxMoney = _resolveComponent("PxMoney")!
  const _component_PxNumeric = _resolveComponent("PxNumeric")!
  const _component_PxDateSelect = _resolveComponent("PxDateSelect")!
  const _component_PxIcon = _resolveComponent("PxIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.innerValue)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (!_ctx.showOnlyQuestionContent)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.currentStepText), 1),
                _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.questionTitle), 1)
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_6, [
            (_ctx.isQuestionType(_ctx.freeResponse))
              ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                  _createVNode(_component_PxTextarea, {
                    modelValue: _ctx.innerValue.value.text,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.innerValue.value.text) = $event)),
                    "max-chars": _ctx.textAreaLimit,
                    placeholder: 
                _ctx.$t('affiliateProgram.questions.freeTextPlaceholder')
              ,
                    "show-counter": true,
                    resize: "none",
                    onFocus: _ctx.onTextareaFocus
                  }, null, 8, ["modelValue", "max-chars", "placeholder", "onFocus"])
                ]))
              : (_ctx.isQuestionType(_ctx.singleSelect))
                ? (_openBlock(), _createBlock(_component_PxRadioGroup, {
                    key: 1,
                    modelValue: _ctx.innerValue.answers[0],
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.innerValue.answers[0]) = $event)),
                    class: "question-panel__content-wrapper"
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.question.answers, (option, index) => {
                        return (_openBlock(), _createElementBlock("div", {
                          key: `question-panel__option-${index}`,
                          class: "question-panel__answer"
                        }, [
                          _createVNode(_component_ElRadio, {
                            label: option.id,
                            value: option.id
                          }, {
                            default: _withCtx(() => [
                              _createElementVNode("div", _hoisted_8, [
                                _createTextVNode(_toDisplayString(option.value) + " ", 1),
                                (option.instructions)
                                  ? (_openBlock(), _createBlock(_component_ElTooltip, {
                                      key: 0,
                                      placement: "top",
                                      "popper-class": "question-panel-answer-tooltip el-abaca-tooltip"
                                    }, {
                                      content: _withCtx(() => [
                                        _createElementVNode("p", {
                                          textContent: _toDisplayString(option.instructions)
                                        }, null, 8, _hoisted_9)
                                      ]),
                                      default: _withCtx(() => [
                                        _createElementVNode("i", {
                                          class: "icon icon-question",
                                          onClick: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["prevent"]))
                                        })
                                      ]),
                                      _: 2
                                    }, 1024))
                                  : _createCommentVNode("", true)
                              ])
                            ]),
                            _: 2
                          }, 1032, ["label", "value"])
                        ]))
                      }), 128))
                    ]),
                    _: 1
                  }, 8, ["modelValue"]))
                : (_ctx.isQuestionType(_ctx.multiSelect))
                  ? (_openBlock(), _createBlock(_component_ElCheckboxGroup, {
                      key: 2,
                      modelValue: _ctx.innerValue.answers,
                      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.innerValue.answers) = $event)),
                      class: "question-panel__content-wrapper"
                    }, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.question.answers, (option, index) => {
                          return (_openBlock(), _createElementBlock("div", {
                            key: `question-panel__option-${index}`,
                            class: "question-panel__answer"
                          }, [
                            _createVNode(_component_ElCheckbox, {
                              value: option.id
                            }, {
                              default: _withCtx(() => [
                                _createElementVNode("div", _hoisted_10, [
                                  _createTextVNode(_toDisplayString(option.value) + " ", 1),
                                  (option.instructions)
                                    ? (_openBlock(), _createBlock(_component_ElTooltip, {
                                        key: 0,
                                        placement: "top",
                                        "popper-class": "question-panel-answer-tooltip el-abaca-tooltip"
                                      }, {
                                        content: _withCtx(() => [
                                          _createElementVNode("p", {
                                            textContent: _toDisplayString(option.instructions)
                                          }, null, 8, _hoisted_11)
                                        ]),
                                        default: _withCtx(() => [
                                          _createElementVNode("i", {
                                            class: "icon icon-question",
                                            onClick: _cache[3] || (_cache[3] = _withModifiers(() => {}, ["prevent"]))
                                          })
                                        ]),
                                        _: 2
                                      }, 1024))
                                    : _createCommentVNode("", true)
                                ])
                              ]),
                              _: 2
                            }, 1032, ["value"])
                          ]))
                        }), 128))
                      ]),
                      _: 1
                    }, 8, ["modelValue"]))
                  : (_ctx.isMoneyType)
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 3 }, [
                        (_ctx.needsCurrencyRange)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                              _createElementVNode("div", _hoisted_13, [
                                _createElementVNode("span", _hoisted_14, _toDisplayString(_ctx.$t("affiliateProgram.questions.rangeFrom")), 1),
                                _createVNode(_component_PxMoney, {
                                  modelValue: _ctx.innerValue.value.min,
                                  "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.innerValue.value.min) = $event))
                                }, null, 8, ["modelValue"])
                              ]),
                              _createElementVNode("div", _hoisted_15, [
                                _createElementVNode("span", _hoisted_16, _toDisplayString(_ctx.$t("affiliateProgram.questions.rangeTo")), 1),
                                _createVNode(_component_PxMoney, {
                                  modelValue: _ctx.innerValue.value.max,
                                  "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.innerValue.value.max) = $event))
                                }, null, 8, ["modelValue"])
                              ])
                            ]))
                          : (_openBlock(), _createElementBlock("div", _hoisted_17, [
                              _createVNode(_component_PxMoney, {
                                modelValue: _ctx.innerValue.value.value,
                                "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.innerValue.value.value) = $event))
                              }, null, 8, ["modelValue"])
                            ]))
                      ], 64))
                    : (_ctx.isQuestionType(_ctx.numeric))
                      ? (_openBlock(), _createElementBlock(_Fragment, { key: 4 }, [
                          (_ctx.$user.isEntrepreneur())
                            ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
                                _createVNode(_component_PxNumeric, {
                                  modelValue: _ctx.innerValue.value.value,
                                  "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.innerValue.value.value) = $event))
                                }, null, 8, ["modelValue"])
                              ]))
                            : _createCommentVNode("", true),
                          (_ctx.$user.isSupporter())
                            ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
                                _createElementVNode("div", _hoisted_20, [
                                  _createElementVNode("span", _hoisted_21, _toDisplayString(_ctx.$t("affiliateProgram.questions.rangeFrom")), 1),
                                  _createVNode(_component_PxNumeric, {
                                    modelValue: _ctx.innerValue.value.min,
                                    "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.innerValue.value.min) = $event))
                                  }, null, 8, ["modelValue"])
                                ]),
                                _createElementVNode("div", _hoisted_22, [
                                  _createElementVNode("span", _hoisted_23, _toDisplayString(_ctx.$t("affiliateProgram.questions.rangeTo")), 1),
                                  _createVNode(_component_PxNumeric, {
                                    modelValue: _ctx.innerValue.value.max,
                                    "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.innerValue.value.max) = $event))
                                  }, null, 8, ["modelValue"])
                                ])
                              ]))
                            : _createCommentVNode("", true)
                        ], 64))
                      : _createCommentVNode("", true),
            (_ctx.isQuestionType(_ctx.dateResponse))
              ? (_openBlock(), _createElementBlock("div", _hoisted_24, [
                  _createVNode(_component_PxDateSelect, {
                    modelValue: _ctx.innerValue.value.date,
                    "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.innerValue.value.date) = $event))
                  }, null, 8, ["modelValue"])
                ]))
              : _createCommentVNode("", true)
          ])
        ]))
      : _createCommentVNode("", true),
    (!_ctx.showOnlyQuestionContent)
      ? (_openBlock(), _createElementBlock("div", _hoisted_25, [
          _createElementVNode("div", _hoisted_26, [
            _createVNode(_component_PxIcon, {
              size: _ctx.noticeIconSize,
              class: "question-panel__info-icon",
              name: "icon-information"
            }, null, 8, ["size"]),
            _createElementVNode("p", _hoisted_27, _toDisplayString(_ctx.skipQuestionCopy), 1),
            (_ctx.$screen.mdUp && _ctx.showNotice)
              ? (_openBlock(), _createElementBlock("p", _hoisted_28, _toDisplayString(_ctx.noticeText), 1))
              : _createCommentVNode("", true)
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}