export const PROFILE_META_UPDATE_FROM_SUPPORTER_MATCHING =
  "profileUpdateFromSupporterMatching";
export const PROFILE_META_ACTIVE_VIEW = "profileActiveView";
export const PROFILE_META_TAB_MENU_ITEMS = "profileTabMenuItems";
export const PROFILE_META_OPEN_ASSESSMENT_MODAL = "profileOpenAssessmentModal";
export const PROFILE_META_SUBSCRIPTION_MODAL = "profileSubscriptionModal";

export const PROFILE_AFFILIATE_LINK_QUERY = "?a=";

export const PROFILE_SUPPORTER_OFFER_HAS_CHANGED = 1;
export const PROFILE_SUPPORTER_OFFER_NOT_CHANGED = 0;
