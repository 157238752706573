import {
  GenericProvider,
  Provider,
  EProviderFeatures,
  IOptionsType,
  IPaginationResult,
} from "@/services/data/generic-provider";
import { baseAPIUrl } from "@/services/utils/utils";
import { IMatchingQuestion } from "@/services/data/matching-questionary/matching-question.interface";

@Provider(`${baseAPIUrl}/matching/questions`, [EProviderFeatures.LIST])
class MatchingQuestionaryProvider extends GenericProvider<
  IPaginationResult<IMatchingQuestion>
> {
  public async getQuestions(
    options: IOptionsType = {},
  ): Promise<IPaginationResult<IMatchingQuestion>> {
    return (await this.list(options)) as any;
  }
}

export const matchingQuestionaryProvider = new MatchingQuestionaryProvider();
