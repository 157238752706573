import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-96442470"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "pricing-switch" }
const _hoisted_2 = { class: "pricing-switch__annual-discount" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ElSwitch = _resolveComponent("ElSwitch")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ElSwitch, {
      modelValue: _ctx.isActive,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.isActive) = $event)),
      "active-text": _ctx.$t('profile.accountSettingsPage.pricingSwitch.labels[1]'),
      "inactive-text": _ctx.$t('profile.accountSettingsPage.pricingSwitch.labels[0]'),
      width: 44,
      onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('update:modelValue', !_ctx.modelValue)))
    }, null, 8, ["modelValue", "active-text", "inactive-text"]),
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t("profile.accountSettingsPage.pricingSwitch.annualDiscount")), 1)
  ]))
}