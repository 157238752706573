import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withModifiers as _withModifiers, mergeProps as _mergeProps, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-e16b94da"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "validating-panel" }
const _hoisted_2 = {
  key: 0,
  class: "validating-panel__container"
}
const _hoisted_3 = { class: "validating-panel__print-title" }
const _hoisted_4 = { class: "validating-panel__score-wrapper" }
const _hoisted_5 = { class: "validating-panel__title" }
const _hoisted_6 = { class: "validating-panel__tag-wrapper" }
const _hoisted_7 = { class: "validating-panel__description" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PxButton = _resolveComponent("PxButton")!
  const _component_PxIcon = _resolveComponent("PxIcon")!
  const _component_ElTag = _resolveComponent("ElTag")!
  const _component_PxGlobalError = _resolveComponent("PxGlobalError")!
  const _component_PxPanel = _resolveComponent("PxPanel")!
  const _directive_markdown = _resolveDirective("markdown")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_ctx.isLoading)
      ? (_openBlock(), _createBlock(_component_PxPanel, {
          key: 0,
          class: "px-panel--mobile-no-side-borders"
        }, {
          default: _withCtx(() => [
            (!_ctx.hasError)
              ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                  _createElementVNode("h2", _hoisted_3, _toDisplayString(_ctx.$t("profile.assessment.levelPanel.title")), 1),
                  _createVNode(_component_PxButton, _mergeProps({ class: "validating-panel__cta" }, _ctx.$options.static.printButton, {
                    onClick: _withModifiers(_ctx.onPrintButtonClick, ["stop"])
                  }), null, 16, ["onClick"]),
                  _createElementVNode("div", _hoisted_4, [
                    _createVNode(_component_PxIcon, {
                      name: `level-graph/${_ctx.finalLevel.value}`,
                      size: 173,
                      class: "validating-panel__level"
                    }, null, 8, ["name"])
                  ]),
                  _createElementVNode("h2", _hoisted_5, [
                    _createTextVNode(_toDisplayString(_ctx.finalLevel.title) + " ", 1),
                    _createElementVNode("span", _hoisted_6, [
                      _createVNode(_component_ElTag, {
                        effect: "plain",
                        size: "default",
                        "data-tag-type": "grey"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t("common.levelTag", { level: _ctx.finalLevel.value })), 1)
                        ]),
                        _: 1
                      })
                    ])
                  ]),
                  _withDirectives(_createElementVNode("div", _hoisted_7, null, 512), [
                    [_directive_markdown, _ctx.finalLevel.description]
                  ])
                ]))
              : _createCommentVNode("", true),
            (_ctx.hasError)
              ? (_openBlock(), _createBlock(_component_PxGlobalError, {
                  key: 1,
                  text: _ctx.$t('common.errors.wrongLevel'),
                  class: "validating-panel-error"
                }, null, 8, ["text"]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ]))
}