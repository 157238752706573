import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, mergeProps as _mergeProps, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-c4377a64"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "pricing-card" }
const _hoisted_2 = { class: "pricing-card__title" }
const _hoisted_3 = { class: "pricing-card__price" }
const _hoisted_4 = {
  key: 0,
  class: "pricing-card__price-note"
}
const _hoisted_5 = { class: "pricing-card__features-title" }
const _hoisted_6 = { class: "pricing-card__features-list" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PxButton = _resolveComponent("PxButton")!
  const _component_PxIcon = _resolveComponent("PxIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h2", _hoisted_2, _toDisplayString(_ctx.title), 1),
    _createElementVNode("h1", _hoisted_3, [
      _createTextVNode(" $" + _toDisplayString(_ctx.price) + " ", 1),
      (_ctx.priceNote)
        ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.priceNote), 1))
        : _createCommentVNode("", true)
    ]),
    (_ctx.ctaLabel)
      ? (_openBlock(), _createBlock(_component_PxButton, _mergeProps({ key: 0 }, _ctx.buttonProps, {
          disabled: _ctx.disabled,
          label: _ctx.ctaLabel,
          class: "pricing-card__cta",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('click')))
        }), null, 16, ["disabled", "label"]))
      : _createCommentVNode("", true),
    _createElementVNode("h4", _hoisted_5, _toDisplayString(_ctx.featuresTitle), 1),
    _createElementVNode("ul", _hoisted_6, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.features, (feature, index) => {
        return (_openBlock(), _createElementBlock("li", {
          key: index,
          class: "pricing-card__feature"
        }, [
          _createVNode(_component_PxIcon, {
            name: feature.icon,
            size: feature.iconSize ? feature.iconSize : 20
          }, null, 8, ["name", "size"]),
          _createTextVNode(" " + _toDisplayString(feature.label), 1)
        ]))
      }), 128))
    ])
  ]))
}