import { IGenericState } from "@/services/store/generic/generic-state.interface";
import { IMilestoneReports } from "@/modules/charts-dashboard/services/data/milestone-reports/milestone.interface";

export interface IMilestoneReportsState
  extends IGenericState<IMilestoneReports> {}

export enum EMilestoneReportsState {
  NAMESPACE = "milestoneReports",
}

export enum EMilestoneReportsActions {
  GET_VALUE = "getValue",
  GET_VALUE_DETAIL = "getValueDetail",
  GET_VALUES = "getValues",
  CREATE_VALUE = "createValue",
  PATCH_VALUE = "patchValue",
  REMOVE_VALUE = "removeValue",
}
