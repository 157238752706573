import { IBarChartDataset } from "@/modules/charts-dashboard/components/process-reports/process-reports-chart.vue";
import { Chart } from "chart.js";

const getOrCreateTooltip = (chart: Chart) => {
  if (chart.canvas.parentNode) {
    let tooltipEl = chart.canvas.parentNode.querySelector("div");

    if (!tooltipEl) {
      tooltipEl = document.createElement("div");
      chart.canvas.parentNode.appendChild(tooltipEl);
    }

    return tooltipEl;
  }
};

const updateTooltipContent = (
  tooltip: any,
  tooltipEl: any,
  compareData: {
    labels: string[];
    datasets: IBarChartDataset[];
  } | null,
) => {
  if (!tooltip.body) return;

  const tooltipBodyLines = tooltip.body.map((b: any) => b.lines);

  const tooltipContent = document.createElement("div");

  // tooltip for compare steps
  if (compareData && compareData.labels?.length === 2) {
    const indexOfCurrentData = compareData.labels.indexOf(tooltip.title[0]);

    tooltipBodyLines.forEach((body: any) => {
      const title = body[0].split(": ")[0];

      const nextDataset = compareData.datasets.filter(
        (d: any) => d.label === title,
      );

      const indexToCompare = indexOfCurrentData === 0 ? 1 : 0;
      const valueToCompare = nextDataset[0].data[indexToCompare] as number;
      const value = nextDataset[0].data[indexOfCurrentData] as number;
      const difference = value - valueToCompare;

      const header = document.createElement("header");
      const main = document.createElement("main");

      const titleEl = document.createElement("p");
      titleEl.textContent = title;

      const valueEl = document.createElement("p");
      valueEl.textContent = `${value}%`;

      const compareBadge = document.createElement("p");
      compareBadge.innerHTML = `
          <span>${Math.abs(difference).toFixed(1)}%</span> 
          compared to ${
            compareData.labels && compareData.labels[indexToCompare]
          }
        `;
      const classToAdd =
        difference > 0
          ? "positive-badge"
          : difference < 0
            ? "negative-badge"
            : null;

      if (classToAdd) {
        compareBadge.classList.add(classToAdd);
        compareBadge.classList.add("badge");
      }

      header.appendChild(titleEl);
      header.appendChild(valueEl);
      main.appendChild(compareBadge);
      tooltipContent.appendChild(header);
      tooltipContent.appendChild(main);
    });
  }
  // tooltip for locked steps
  else if (tooltipBodyLines[0][0].includes("locked")) {
    const titleEl = document.createElement("i");
    titleEl.textContent =
      "Locked: This data is hidden due to not meeting privacy policy requirements.";

    tooltipContent.appendChild(titleEl);
    tooltipContent.classList.add("locked-tooltip");
  } else {
    tooltipBodyLines.forEach((body: any) => {
      const [title, value] = body[0].split(": ");

      const titleEl = document.createElement("p");
      titleEl.textContent = title;

      const valueEl = document.createElement("p");
      valueEl.textContent = `${value}%`;

      tooltipContent.appendChild(titleEl);
      tooltipContent.appendChild(valueEl);
      tooltipContent.classList.add("single-tooltip");
    });
  }

  const tableRoot = tooltipEl;

  // Remove old children
  while (tableRoot.firstChild) {
    tableRoot.firstChild.remove();
  }

  tableRoot.appendChild(tooltipContent);
};

const updateTooltipPosition = (chart: any, tooltip: any, tooltipEl: any) => {
  const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;

  tooltipEl.style.left = positionX + tooltip.caretX - 18 + "px";
  tooltipEl.style.top = positionY + tooltip.caretY - 18 + "px";
};

export const externalTooltipHandler = (
  context: any,
  compareData: {
    labels: string[];
    datasets: IBarChartDataset[];
  } | null,
) => {
  const { chart, tooltip } = context;
  const tooltipEl = getOrCreateTooltip(chart);

  if (tooltipEl) {
    // Hide if no tooltip
    if (tooltip.opacity === 0) {
      tooltipEl.style.opacity = "0";
      return;
    }

    updateTooltipContent(tooltip, tooltipEl, compareData);

    tooltipEl.classList.add("chart-tooltip");
    tooltipEl.style.opacity = "1";

    updateTooltipPosition(chart, tooltip, tooltipEl);
  }
};
