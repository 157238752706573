import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-24b74c80"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "investment-level__container" }
const _hoisted_2 = { class: "submission-tab__print-title" }
const _hoisted_3 = { class: "investment-level__section" }
const _hoisted_4 = { class: "investment-level__section" }
const _hoisted_5 = { class: "investment-level__section" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AssessmentResume = _resolveComponent("AssessmentResume")!
  const _component_PxGrid = _resolveComponent("PxGrid")!
  const _component_ResultsReport = _resolveComponent("ResultsReport")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h2", _hoisted_2, _toDisplayString(_ctx.$t("profile.assessment.levelPanel.title")), 1),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_AssessmentResume, {
        "current-level": _ctx.level.value,
        description: _ctx.level.description,
        title: _ctx.level.title
      }, null, 8, ["current-level", "description", "title"])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_PxGrid, {
        categories: _ctx.categories,
        "current-level": _ctx.level.value,
        levels: _ctx.numOfLevels,
        results: _ctx.results,
        size: _ctx.gridSize
      }, null, 8, ["categories", "current-level", "levels", "results", "size"])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createVNode(_component_ResultsReport, {
        categories: _ctx.categories,
        levels: _ctx.results
      }, null, 8, ["categories", "levels"])
    ])
  ]))
}