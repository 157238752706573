import {
  GenericProvider,
  EProviderFeatures,
  Provider,
  IOptionsType,
  IPaginationResult,
} from "@/services/data/generic-provider";
import { baseAPIUrl } from "@/services/utils/utils";

import { IQuestionBundle } from "./question-bundle.interface";

@Provider(`${baseAPIUrl}/matching/question-bundles`, [EProviderFeatures.LIST])
class QuestionBundleProvider extends GenericProvider<IQuestionBundle> {
  /**
   * Paginate question bundles.
   */
  public async paginate(
    options: IOptionsType = {},
  ): Promise<IPaginationResult<IQuestionBundle>> {
    return super.list(options) as unknown as IPaginationResult<IQuestionBundle>;
  }
}

export const questionBundleProvider = new QuestionBundleProvider();
