<template>
  <div class="match-details-table-legend">
    <ElCollapse :accordion="true">
      <ElCollapseItem>
        <template #title>
          <div class="match-details-table-legend__headline">
            <span>
              {{ $t("matching.matchDetails.table.legend.title") }}
            </span>
          </div>
        </template>
        <div class="match-details-table-legend__wrapper">
          <div
            v-for="(card, index) in legendCards"
            :key="index"
            class="match-details-table-legend__card"
          >
            <div>
              <PxIcon :name="card.icon" :size="16" />
              <h3>
                {{ card.title }}
              </h3>
            </div>
            <p>
              {{ card.description }}
            </p>
            <p v-if="card.secondDescription">
              {{ card.secondDescription }}
            </p>
          </div>
        </div>
      </ElCollapseItem>
    </ElCollapse>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export interface ILegendCard {
  icon: string;
  title: string;
  description: string;
  secondDescription?: string;
}

export default defineComponent({
  name: "MatchDetailsTableLegend",

  props: {
    legendCards: {
      type: Array as () => ILegendCard[],
      required: true,
    },
  },
});
</script>

<style lang="scss">
.match-details-table-legend {
  margin-top: 11px;

  &__headline {
    width: 100%;
    text-align: right;

    span {
      @include grotesk(semiBold);

      color: $ebony-clay;
      text-decoration: underline;
      transition: $--all-transition;
    }
  }

  &__wrapper {
    display: flex;
    justify-content: space-between;
    gap: 11px;
  }

  &__card {
    padding: 10px 13px 14px 10px;
    flex: 1;
    border: 1px solid $athens-gray;
    border-radius: 4px;

    div {
      padding-bottom: 8px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 8px;
    }

    div .px-icon {
      position: relative;
      top: 1px;
    }

    h3 {
      @include grotesk(semiBold);

      letter-spacing: -0.15px;
    }

    h3,
    p {
      font-size: 14px;
      line-height: 20px;
      color: $ebony-clay;
      margin: 0;
    }

    p {
      padding-left: 24px;
      letter-spacing: -0.33px;
    }
  }

  .el-collapse-item__wrap,
  .el-collapse-item__content {
    padding: 0;
  }

  .el-collapse-item__wrap {
    margin-top: 13px;
    margin-left: 3px;
    padding-bottom: 4px;
  }

  .el-collapse-item {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    align-items: flex-end;
  }

  .el-collapse-item__header {
    max-width: fit-content;

    min-height: 0;
    padding: 0 20px 1px 0;

    &.is-active::before {
      transform: rotate(180deg);
    }

    &::before {
      top: 1px;
      right: 0;
      bottom: 0;
      width: 16px;
      height: 16px;
      margin: auto 0;
      background-color: transparent;
      background-image: url("#{$assetsPath}/img/icons/arrow.svg");
      background-repeat: no-repeat;
      background-position: center;
      transition: $--all-transition;
    }

    &::after {
      display: none;
    }
  }
}
</style>
