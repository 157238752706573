import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-6d73a0f4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "team-member-mandatory-data" }
const _hoisted_2 = { class: "team-member-mandatory-data__info" }
const _hoisted_3 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ElInput = _resolveComponent("ElInput")!
  const _component_ElFormItem = _resolveComponent("ElFormItem")!
  const _component_ElForm = _resolveComponent("ElForm")!
  const _component_PxIcon = _resolveComponent("PxIcon")!
  const _directive_loading = _resolveDirective("loading")!

  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ElForm, {
      ref: "mandatoryDataForm",
      model: _ctx.formData,
      rules: _ctx.rules,
      class: "team-member-mandatory-data__form",
      onValidate: _ctx.checkFieldsValidState
    }, {
      default: _withCtx(() => [
        _createVNode(_component_ElFormItem, {
          label: _ctx.fieldsLabel.firstName,
          class: "team-member-mandatory-data__first-name",
          prop: "first_name"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_ElInput, {
              modelValue: _ctx.formData.first_name,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formData.first_name) = $event))
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }, 8, ["label"]),
        _createVNode(_component_ElFormItem, {
          label: _ctx.fieldsLabel.lastName,
          class: "team-member-mandatory-data__last-name",
          prop: "last_name"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_ElInput, {
              modelValue: _ctx.formData.last_name,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.formData.last_name) = $event))
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }, 8, ["label"]),
        _createVNode(_component_ElFormItem, {
          label: _ctx.fieldsLabel.email,
          class: "team-member-mandatory-data__email",
          prop: "email"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_ElInput, {
              modelValue: _ctx.formData.email,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.formData.email) = $event))
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }, 8, ["label"]),
        _createVNode(_component_ElFormItem, {
          label: _ctx.fieldsLabel.position,
          class: "team-member-mandatory-data__position",
          prop: "position"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_ElInput, {
              modelValue: _ctx.formData.position,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.formData.position) = $event))
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }, 8, ["label"])
      ]),
      _: 1
    }, 8, ["model", "rules", "onValidate"]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_PxIcon, {
        size: 16,
        name: "icon-information"
      }),
      _createElementVNode("p", { innerHTML: _ctx.infoCopy }, null, 8, _hoisted_3)
    ])
  ])), [
    [_directive_loading, _ctx.isLoading]
  ])
}