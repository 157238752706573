<template>
  <ElDialog
    ref="modal"
    v-model="innerVisibility"
    :before-close="onClickCloseModal"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    class="support-modal"
  >
    <PxModalMessage :timeout="5000" :visible="hasError" />
    <SupportModalSuccess
      v-if="reportSent"
      :email="userEmail || ''"
      data-testid="support-modal__success"
    />
    <template v-else>
      <h1 class="support-modal__title">
        {{ $t("common.components.supportModal.title") }}
      </h1>
      <p class="support-modal__description">
        {{ $t("common.components.supportModal.description") }}
      </p>
      <ElForm ref="form" :model="form" :rules="rules">
        <ElFormItem
          v-if="!isUserLogged"
          :label="$t('supporters.assessment.rangeLevelsModal.labels.email')"
          data-testid="support-modal__email"
          prop="email"
        >
          <ElInput
            v-model="form.email"
            :placeholder="
              $t('supporters.assessment.rangeLevelsModal.placeholders.email')
            "
          />
        </ElFormItem>
        <ElFormItem
          :label="
            $t('common.components.supportModal.fields.detailedReport.label')
          "
          prop="detailedReport"
        >
          <PxTextarea
            ref="detailedReport"
            v-model="form.detailedReport"
            :max-chars="rules.detailedReport.max"
            :placeholder="
              $t(
                'common.components.supportModal.fields.detailedReport.placeholder',
              )
            "
            show-counter
          />
        </ElFormItem>
      </ElForm>
    </template>
    <template #footer>
      <div v-if="!reportSent" class="support-modal__footer">
        <PxButton
          ref="closeButton"
          class="support-modal__footer-link"
          size="small"
          type="link"
          @click="onClickCloseModal"
        >
          {{ $t("common.cancel") }}
        </PxButton>
        <PxButton
          ref="sendButton"
          :disabled="!canSendReport || formIsInvalid"
          class="support-modal__footer-button"
          type="green"
          @click="onClickSendHandler"
        >
          {{ $t("common.send") }}
        </PxButton>
      </div>
    </template>
  </ElDialog>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import ElDialogMixin from "@/mixins/el-dialog.mixin";
import SupportModalSuccess from "@/modules/common/components/support-modal/support-modal-success.vue";

import {
  allFormFieldsValid,
  generateEmailValidator,
} from "@/services/errors/validator-generators";
import { supportProvider } from "@/services/data/support/support.provider";
import { SUPPORT_SUBJECT_ACCESS_DENIED } from "@/services/data/support/support.interface";
import { ICompany } from "@/modules/profile/services/data/company/company.types";
import { FormInstance } from "element-plus";

export default defineComponent({
  name: "SupportModal",

  components: {
    SupportModalSuccess,
  },

  mixins: [ElDialogMixin],

  data() {
    return {
      form: {
        detailedReport: "",
        email: "",
      },
      rules: {
        detailedReport: {
          max: 500,
          message: this.$t("common.errors.textarea.max", {
            fieldLabel: this.$t(
              "common.components.supportModal.fields.detailedReport.label",
            ),
            maxValue: 500,
          }),
        },
        email: generateEmailValidator(
          this,
          "supporters.assessment.rangeLevelsModal.fields.email",
          true,
        ),
      },
      formInvalid: true,
      hasError: false,
      reportSent: false,
    };
  },

  computed: {
    company(): ICompany | null {
      return this.$store.get("profile/company/data") || null;
    },

    canSendReport(): boolean {
      return !!this.form.detailedReport.length;
    },

    formIsInvalid(): boolean {
      return !allFormFieldsValid(this.$refs.form, this.rules);
    },

    isUserLogged(): boolean {
      return this.$user.isLogged();
    },

    userEmail(): string | undefined {
      return this.isUserLogged ? this.company?.email : this.form.email;
    },
  },

  methods: {
    onClickCloseModal() {
      this.innerVisibility = false;
      (this.$refs.form as FormInstance).resetFields();
      (this.$refs.form as FormInstance).clearValidate();
    },

    async onClickSendHandler() {
      try {
        await supportProvider.postSuggestion({
          subject: SUPPORT_SUBJECT_ACCESS_DENIED,
          message: this.form.detailedReport,
          author: this.userEmail,
        });
        this.reportSent = true;
      } catch (error) {
        this.hasError = true;
        return;
      }
    },
  },
});
</script>

<style lang="scss">
.support-modal {
  .px-modal-message {
    z-index: z("floaters");
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }

  &.el-dialog {
    width: 492px;

    @include breakpoint-down(md) {
      width: 290px;
    }
  }

  .el-dialog__header {
    padding: 0;
    text-align: center;
    background-color: transparent;
    border: none;
  }

  .el-dialog__body {
    padding: 25px 30px 40px;

    @include breakpoint-down(md) {
      padding: 46px 15px 0;
    }
  }

  .el-dialog__headerbtn {
    top: 20px;
    right: 14px;
    z-index: z("floaters");
    width: 22px;
    height: 22px;

    @include breakpoint-down(md) {
      top: 17px;
      right: 17px;
    }
  }

  .el-dialog__footer {
    padding: 17px 29px 16px;
    margin-top: 8px;

    .support-modal__footer-button {
      min-width: 66px;
      margin-left: 13px;

      @include breakpoint-down(md) {
        height: 36px;
      }
    }

    .support-modal__footer-link {
      bottom: 1px;

      @include breakpoint-down(md) {
        bottom: 5px;
      }
    }

    @include breakpoint-down(md) {
      padding: 22px 29px 25px;
    }
  }

  textarea {
    height: 124px;
    padding-top: 7px;
    padding-left: 15px;
    line-height: 21px;
    letter-spacing: -0.3px;
    resize: none !important;

    @include breakpoint-down(md) {
      padding-top: 8px;
      font-size: 14px;
      line-height: 20px;
    }
  }

  .el-form-item__label {
    margin-bottom: 3px;
  }

  :deep() .px-textarea-counter {
    margin-top: 3px;
  }

  &__title {
    @include grotesk(semiBold);

    padding-top: 7px;
    font-size: 20px;

    color: $ebony-clay;
    text-align: center;

    @include breakpoint-down(md) {
      font-size: 18px;
    }
  }

  &__description {
    padding: 13px 6px 13px 6px;
    margin-bottom: -1px;
    font-size: 15px;
    line-height: 22px;
    color: $ebony-clay;
    text-align: center;
    letter-spacing: -0.38px;

    @include breakpoint-down(md) {
      padding: 14px 6px 13px 6px;
    }
  }
}
</style>
