import { IAffiliateSubmissions } from "@/modules/submissions/services/data/submissions/submissions.interface";
import {
  GenericProvider,
  Provider,
  EProviderFeatures,
} from "@/services/data/generic-provider";
import { baseAPIUrl } from "@/services/utils/utils";

@Provider(`${baseAPIUrl}/supporter-owned-affiliates-submissions`, [
  EProviderFeatures.LIST,
])
class SupporterOwnedAffiliatesSubmissionsProvider extends GenericProvider<IAffiliateSubmissions> {}

export const supporterOwnedAffiliatesSubmissionsProvider =
  new SupporterOwnedAffiliatesSubmissionsProvider();
