<template>
  <div :style="`--categoryColor: #${categoryColor}`" class="level-card">
    <div class="level-card__header">
      <input
        v-model="innerValue"
        class="level-card-checkbox"
        data-testid="level-card-checkbox"
        type="checkbox"
        @click.stop="onCheckboxClick"
      />
      <span class="level-card__title">
        {{
          $t("selfAssessment.mobileAssessment.levelCard.level", {
            level: details.level.value,
          })
        }}
      </span>
      <PxButton
        class="level-card__criteria"
        type="link"
        @click="$emit('see-criteria')"
      >
        {{ $t("selfAssessment.mobileAssessment.seeCriteria") }}
      </PxButton>
    </div>
    <div class="level-card__content">
      {{ details.achievements }}
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "LevelCard",

  props: {
    /**
     * CategoryLevel details
     */
    details: {
      type: Object as () => any,
      required: true,
    },

    /**
     * Category color to be used on the checkbox.
     */
    categoryColor: {
      type: String,
      required: true,
    },

    /**
     * Checkbox value
     */
    value: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      innerValue: false,
    };
  },

  watch: {
    /**
     * Every time that the value changes the innerValue
     * must be updated as well.
     */
    value: {
      immediate: true,
      handler(newVal: boolean) {
        this.innerValue = newVal;
      },
    },
  },

  methods: {
    onCheckboxClick() {
      this.$emit("change-level", !this.value);
    },
  },
});
</script>

<style lang="scss" scoped>
.level-card {
  position: relative;
  display: block;
  padding: 10px 15px 2px;

  background-color: white;

  border: solid 1px $pale-grey;
  border-radius: 3px;

  box-shadow: 0 2px 11px 0 $black-5;

  &__header {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
  }

  &__title {
    @include grotesk(bold);

    flex-grow: 1;
    padding-left: 12px;

    font-size: 1rem;
    line-height: 24px;
    color: rgba(139, 143, 161, 0.5);
    text-transform: uppercase;
  }

  &__criteria {
    position: relative;
    top: -6px;
    flex-shrink: 0;

    & :deep() span {
      font-size: 0.9333rem;
    }
  }

  &__content {
    @include grotesk(bold);

    position: relative;
    top: -6px;
    padding-left: 37px;

    font-size: 1rem;
    line-height: 24px;

    color: $ebony-clay;
  }
}

.level-card-checkbox {
  position: relative;
  top: -1px;
  left: -2px;
  display: inline-block;
  width: 24px;
  height: 24px;
  margin-top: 2px;

  /* This is needed to prevent loaded checkbox on Chrome and Firefox */
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  background-color: white;
  border: solid 2px rgba($manatee, 0.2);
  border-radius: 50%;

  &:focus {
    outline: none;
  }

  &:checked {
    background-color: var(--categoryColor);
    border-color: var(--categoryColor);
  }

  &:checked::before {
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    width: 14px;
    height: 14px;

    content: "";

    background-image: url("#{$assetsPath}/img/icons/check--white-14.svg");
    background-repeat: no-repeat;
    background-size: cover;

    transform: translate(-50%, -50%);
  }
}
</style>
