<template>
  <div
    :class="[
      `px-panel--${theme}`,
      {
        'px-panel--with-icon': !!icon,
        'px-panel--no-borders': noBorders,
        'px-panel--scrollable': scrollable,
        'px-panel--has-shadow': showShadow,
      },
    ]"
    class="px-panel"
  >
    <div class="px-panel__container">
      <div v-if="showHeader" class="px-panel__header">
        <slot name="header">
          <PxIcon v-if="icon" :name="icon" />
          <h1 class="px-panel__title">
            {{ title }}
          </h1>
        </slot>
      </div>
      <div :style="customBodyStyle" class="px-panel__body">
        <slot />
      </div>
      <div v-if="$slots.footer" class="px-panel__footer">
        <slot name="footer" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

const PX_SCROLL_SELECTOR = ".px-panel__container";

export default defineComponent({
  name: "PxPanel",
  props: {
    /**
     * Panel title.
     *
     * When the title is present the header sections
     * becomes visible.
     */
    title: {
      type: String as () => string | null,
      default: null,
    },

    /**
     * Allows to use an icon on the header.
     *
     * The icon is aligned to the left followed by the title,
     * aligned in the same way.
     */
    icon: {
      type: String as () => string | null,
      default: null,
    },

    theme: {
      type: String,
      default: "default",
    },

    /**
     * Remove panel borders.
     */
    noBorders: {
      type: Boolean,
      default: false,
    },

    /**
     * Make the panel having the same height and the
     * content becomes scrollable.
     */
    scrollable: {
      type: Boolean,
      default: false,
    },

    /**
     * Max height for the scrollable area.
     */
    maxHeight: {
      type: Number as () => number | null,
      default: null,
    },
  },

  data() {
    return {
      showShadow: false,
    };
  },

  computed: {
    showHeader(): boolean {
      return !!this.title || !!this.$slots.header;
    },

    customBodyStyle(): { [key: string]: string } {
      if (!this.maxHeight) {
        return {};
      }

      return { maxHeight: `${this.maxHeight}px` };
    },
  },

  watch: {
    async maxHeight() {
      await this.$nextTick();
      this.onScrollHandler();
    },
  },

  mounted() {
    if (this.scrollable) {
      this.trackScroll();
    }
  },

  methods: {
    trackScroll() {
      const target = this.$el.querySelector(PX_SCROLL_SELECTOR) as HTMLElement;
      target.addEventListener("scroll", this.onScrollHandler, {
        passive: true,
        capture: false,
      });
      window.addEventListener("resize", this.onScrollHandler, {
        passive: true,
        capture: false,
      });

      this.onScrollHandler();
    },

    /**
     * This is the handler that will be used for the scroll event.
     *
     * Here is made an evaluation if the bottom shadow is needed,
     * for that be true the scroll area less the element height
     * must be bigger than the current scroll made from top.
     */
    onScrollHandler() {
      const target = this.$el.querySelector(PX_SCROLL_SELECTOR) as HTMLElement;
      const maxScroll = target.scrollHeight - target.clientHeight;
      this.showShadow = target.scrollTop !== maxScroll;
    },
  },
});
</script>

<style lang="scss" scoped>
.px-panel {
  position: relative;

  background: $white;
  border: solid 1px $pale-grey;
  border-radius: 2px;
  box-shadow: 0 2px 11px 0 $black-5;

  &::after {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: z("default") + 1;
    width: 100%;
    height: 8px;

    content: "";

    background-image: linear-gradient(
      to bottom,
      rgba(39, 39, 64, 0),
      rgba(39, 39, 64, 0.08)
    );
    border-bottom: 1px solid $mischka;

    opacity: 0;

    transition: opacity 0.2s ease-out;
  }

  &__header {
    padding: 20px 0 19px;

    background-color: $alabaster;
    border-bottom: solid 1px $athens-gray;

    @include breakpoint-up(md) {
      padding: 18px 0 16px;
    }

    @include breakpoint-down(sm) {
      padding: 20px 0 16px;
    }
  }

  &__title,
  :slotted(.px-panel__title) {
    @include grotesk(semiBold);

    margin: 0;

    font-size: 1.1333rem;
    line-height: 26px;
    color: $ebony-clay;
    text-align: center;

    @include breakpoint-up(md) {
      font-size: 1.4667rem;
      line-height: 1.82;
    }
  }

  &__body {
    padding: 21px 15px;

    @include breakpoint-up(md) {
      padding: 21px;
    }
  }

  &__footer {
    padding: 19px 21px 20px;

    background-color: $alabaster;
    border-top: solid 1px $athens-gray;
    border-bottom-right-radius: 2px;
    border-bottom-left-radius: 2px;
  }
}

.px-panel--dark-footer {
  .px-panel__footer {
    color: $white;

    background-image: linear-gradient(to bottom, #464673, $ebony-clay);
  }
}

.px-panel--with-icon {
  .px-panel__header {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 15px 22px 13px 18px;
  }

  .px-panel__title,
  :slotted(.px-panel__title) {
    @include grotesk(semiBold);

    padding-left: 15px;

    font-size: 1.0667rem;
    line-height: 1.4667rem;
    text-align: left;
  }

  .px-panel__body {
    padding: 19px 21px 13px 20px;
  }
}

.px-panel--no-borders {
  padding: 0;
  margin: 0;
  border: none;
  box-shadow: none;
}

.px-panel--has-shadow::after {
  opacity: 1;
}

.px-panel--scrollable .px-panel__container {
  position: relative;
  overflow: auto;
}

.px-panel.px-panel--xs-no-side-borders {
  @include breakpoint-only(xs) {
    border-right: none;
    border-left: none;
  }
}

.px-panel.px-panel--mobile-no-side-borders {
  @include breakpoint-down(sm) {
    border-right: none;
    border-left: none;
  }
}

.px-panel--stripped {
  background: transparent;
  border-color: transparent;
  box-shadow: none;
}

.px-panel--with-sections {
  background: transparent;

  .px-panel__header {
    @include breakpoint-up(md) {
      background-color: white;
    }
  }

  .px-panel__body {
    padding: 0;
  }
}
</style>
<style lang="scss">
.px-panel--compact {
  .px-panel__header {
    padding: 16px 10px 15px;

    @include breakpoint-up(md) {
      padding: 19px 10px 17px;
    }
  }

  .px-panel__body {
    padding: 12px 19px 29px;

    @include breakpoint-up(md) {
      padding: 7px 54px 35px;
    }
  }

  .px-panel__header .px-panel__title,
  .px-panel__header :slotted(.px-panel__title) {
    font-size: 1.0667rem;
    line-height: 24px;
    letter-spacing: -0.05px;
  }
}
</style>
