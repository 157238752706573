<template>
  <div>
    <ElDialog
      ref="modal"
      v-model="innerVisibility"
      :append-to-body="true"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="false"
      :title="modalTitle"
      class="passcode-access-modal"
      width="466px"
    >
      <div class="passcode-access-modal__wrapper">
        <PxIcon :size="16" name="icon-password-grey" />
        <i18n-t
          class="passcode-access-modal__form-description"
          keypath="common.components.passcodeModal.subtitle.please"
          tag="span"
        >
          <template #enterPasscode>
            <span>
              {{ $t("common.components.passcodeModal.subtitle.enterPasscode") }}
            </span>
          </template>
        </i18n-t>
      </div>
      <ElForm
        ref="passcodeForm"
        :model="passcodeForm.data"
        :rules="rules"
        @submit.prevent
      >
        <div data-testid="passcode-access-modal-passcode-form">
          <ElFormItem
            :error="errors.getMessage('passcode') || ''"
            :label="$t('common.components.passcodeModal.form.field')"
            data-testid="passcode-access-modal-errors"
            prop="passcode"
          >
            <PxInputPassword
              v-model="passcodeForm.data.passcode"
              :label="$t('common.components.passcodeModal.form.field')"
              :placeholder="$t('common.components.passcodeModal.form.field')"
              autocomplete="new-password"
            />
          </ElFormItem>
          <div
            v-if="noPasscodeMessage"
            class="passcode-access-modal__no-passcode-info"
            v-html="$t(noPasscodeMessage)"
          />
        </div>
        <PxButton
          :loading="isDataLoading"
          class="el-button--block passcode-access-modal__submit-button"
          type="primary"
          @click="makeRequest"
        >
          {{ modalSubmitButtonText }}
        </PxButton>
      </ElForm>
    </ElDialog>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import ElDialogMixin from "@/mixins/el-dialog.mixin";
import { ErrorsManager } from "@/services/errors-manager";
import { generateRequiredValidator } from "@/services/errors/validator-generators";
import { TranslateResult } from "vue-i18n";
import { FormInstance } from "element-plus";

export default defineComponent({
  name: "PasscodeAccessModal",

  mixins: [ElDialogMixin],

  props: {
    requestedDataUid: {
      type: String,
      required: true,
    },

    hasErrors: {
      type: Boolean,
      default: false,
    },

    isDataLoading: {
      type: Boolean,
      default: false,
    },

    needsIdentification: {
      type: Boolean,
      default: false,
    },

    noPasscodeMessage: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      passcodeForm: {
        data: {
          passcode: "",
        },
      },

      errors: new ErrorsManager({
        passcode: {
          invalid_passcode: this.$t(
            "common.components.passcodeModal.errors.invalidPasscode",
          ),
        },
      }),

      rules: {
        passcode: generateRequiredValidator(
          this,
          "common.components.passcodeModal.form.field",
        ),
      },
    };
  },

  computed: {
    modalTitle(): TranslateResult {
      return this.$t("common.components.passcodeModal.title");
    },

    modalSubmitButtonText(): TranslateResult {
      return this.needsIdentification
        ? this.$t("common.next")
        : this.$t("companyLists.accessModal.buttons.enter");
    },
  },

  watch: {
    requestedDataUid() {
      // reset all form errors
      this.errors.clear();
    },

    hasErrors: {
      handler(hasError: boolean) {
        if (hasError) {
          this.displayErrorMessage();
        }
      },
    },
  },

  methods: {
    async makeRequest() {
      // validate form data
      const form = this.$refs.passcodeForm as FormInstance;
      try {
        await form.validate();
      } catch (_) {
        return;
      }

      // Clear errors
      this.errors.clear();
      this.$emit("update:hasErrors", false);

      // Emit passcode to make request
      this.$emit("set-passcode", this.passcodeForm.data.passcode);
    },

    displayErrorMessage() {
      this.errors.record({
        passcode: [{ code: "invalid_passcode", message: "" }],
      });
    },
  },
});
</script>

<style lang="scss">
.passcode-access-modal {
  .el-dialog__header {
    padding: 14px 56px 17px 29px;
  }

  .el-dialog__title {
    letter-spacing: -0.3px;
  }

  .el-dialog__body {
    padding: 19px 30px 24px 30px;
  }

  .el-form-item__label {
    margin-bottom: 4px;
  }

  .el-form-item .el-input__inner {
    letter-spacing: -0.4px;
  }

  .el-form-item.is-error {
    margin-bottom: 33px;
  }

  .el-form-item__error {
    padding-top: 9px;
    letter-spacing: -0.12px;
  }

  .el-input--password .el-input__suffix {
    top: -1px;
  }

  &__wrapper {
    padding-top: 2px;
    margin-bottom: 2px;

    img {
      position: relative;
      top: 3px;
      margin-right: 4px;
    }
  }

  &__form-description {
    font-size: 15px;
    color: $ebony-clay;
    letter-spacing: -0.2px;

    span {
      @include grotesk(semiBold);
    }
  }

  &__no-passcode-info {
    padding-top: 14px;
    margin-bottom: 41px;

    font-size: 12px;
    font-style: italic;
    line-height: 1;
    letter-spacing: -0.13px;
  }

  &__submit-button {
    margin-top: 31px;
  }
}
</style>
