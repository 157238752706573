import { MutationTree } from "vuex";
import { getGenericStateMutations } from "@/services/store/generic/generic-state.mutations";

import { IQuestionBundlesState } from "@/modules/milestone-planner/services/store/milestone-planner/sub-modules/question-bundles/question-bundles.interface";
import { IQuestionBundle } from "@/services/data/question-bundle/question-bundle.interface";

/**
 * Available mutations to manage question bundles sub-module.
 */
export enum EQuestionBundlesMutation {
  SET_ERROR = "setError",
  SET_LOADING = "setLoading",
  SET_VALUE = "setValue",
  SET_VALUES = "setValues",
  APPEND_VALUES = "appendValues",
  SET_PAGE = "setPage",
}

export const mutations: MutationTree<IQuestionBundlesState> = {
  ...getGenericStateMutations<IQuestionBundle>(),

  /**
   * Append new items to the list of values.
   */
  [EQuestionBundlesMutation.APPEND_VALUES](
    state,
    payload: Array<IQuestionBundle>,
  ) {
    state.values = [...state.values, ...payload];
  },

  [EQuestionBundlesMutation.SET_PAGE](state, newPageNumber: number | null) {
    state.currentPage = newPageNumber;
  },
};
