import store from "@/services/store";
import { ICompany } from "./services/data/company/company.types";

import { i18n } from "@/services/i18n/i18n";

import profileMeta from "./meta";

/**
 * Manager with all needed methods to manage the profile.
 */
class ProfileManager {
  public company(): ICompany | null {
    // Fetch company data from current profile if available or fallback to authenticated user data
    return store.get("profile/company/data") || store.get("auth/company/data");
  }

  public type(): number {
    const company = this.company();
    return company !== null ? company.type : 0;
  }

  public userType(): string {
    return i18n.global.t(`common.userTypes[${this.type()}]`) as string;
  }

  public isEntrepreneur(): boolean {
    return this.type() === 0;
  }

  public isSupporter(): boolean {
    return this.type() === 1;
  }

  public get(key: string) {
    const metaExists =
      profileMeta &&
      (profileMeta as any)[this.type()] &&
      (profileMeta as any)[this.type()][key];

    return metaExists ? (profileMeta as any)[this.type()][key] : null;
  }
  public getByType(key: string, type: number) {
    const metaExists =
      profileMeta &&
      (profileMeta as any)[type] &&
      (profileMeta as any)[type][key];

    return metaExists ? (profileMeta as any)[type][key] : null;
  }
}

/**
 * Create a singleton for the profile manager.
 */
export const profileManager = new ProfileManager();
