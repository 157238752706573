import { ActionTree } from "vuex";
import {
  EUserMetadataActions,
  EUserMetadataMutations,
  IUserMetadataSetPayload,
  IUserMetadataState,
} from "./user-metadata.types";
import { RootState } from "@/services/store/root-state";
import { getBaseName } from "@/services/store/utils/get-base-name";
import { userMetadataProvider } from "@/modules/authentication/services/data/user-metadata/user-metadata.provider";

/**
 * Actions for the user metadata module.
 */
export const actions: ActionTree<IUserMetadataState, RootState> = {
  /**
   * Get all metadata for the logged user.
   *
   * @param param0 Vuex context
   */
  async [getBaseName(EUserMetadataActions.FETCH)]({ commit }) {
    commit(EUserMetadataMutations.SET_ERROR, null);
    commit(EUserMetadataMutations.SET_LOADING, true);

    try {
      const data = await userMetadataProvider.list();
      commit(EUserMetadataMutations.SET_DATA, data);
    } catch (error) {
      commit(EUserMetadataMutations.SET_ERROR, error);
    } finally {
      commit(EUserMetadataMutations.SET_LOADING, false);
    }
  },

  /**
   * Create or update a metadata entry.
   *
   * @param param0 Vuex context
   * @param payload Payload with the metadata entry to create or update.
   */
  async [getBaseName(EUserMetadataActions.SET)](
    { commit },
    payload: IUserMetadataSetPayload,
  ) {
    commit(EUserMetadataMutations.SET_ERROR, null);
    commit(EUserMetadataMutations.SET_LOADING, true);

    try {
      const data = await userMetadataProvider.create(payload);
      commit(EUserMetadataMutations.APPEND, data);
    } catch (error) {
      commit(EUserMetadataMutations.SET_ERROR, error);
    } finally {
      commit(EUserMetadataMutations.SET_LOADING, false);
    }
  },

  /**
   * Resets metadata values on store.
   */
  async [getBaseName(EUserMetadataActions.RESET)]({ commit }) {
    commit(EUserMetadataMutations.SET_DATA, null);
  },
};
