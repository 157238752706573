import { renderSlot as _renderSlot } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return _renderSlot(_ctx.$slots, "default", {
    latestAssessment: _ctx.latestAssessment,
    latestAssessmentResults: _ctx.latestAssessmentResults,
    isLoading: _ctx.isLoading,
    levels: _ctx.levels,
    latestAssessmentLevel: _ctx.latestAssessmentLevel,
    latestAssessmentLevelValue: _ctx.latestAssessmentLevelValue,
    isInactive: _ctx.isInactive,
    allowLevelUpdate: _ctx.allowLevelUpdate,
    companyName: _ctx.companyName,
    makeNewAssessment: _ctx.makeNewAssessment
  })
}