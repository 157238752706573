<template>
  <nav class="px-minimal-navbar">
    <div class="px-minimal-navbar__wrapper">
      <div class="px-minimal-navbar__center">
        <RouterLink
          v-if="$user.isLogged()"
          :to="logoLink"
          class="px-minimal-navbar__logo-link"
        >
          <img
            :src="mainLogo"
            alt="VIRAL Logo"
            class="px-minimal-navbar__logo"
            data-testid="px-minimal-navbar__logo"
            height="32"
            width="92"
          />
        </RouterLink>
        <a v-else :href="logoLink" class="px-minimal-navbar__logo-link">
          <img
            :src="mainLogo"
            alt="VIRAL Logo"
            class="px-minimal-navbar__logo"
            data-testid="px-minimal-navbar__logo"
            height="32"
            width="92"
          />
        </a>
      </div>
    </div>
  </nav>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import { HOME } from "@/services/router/router-names";
import { isProduction } from "@/services/utils/utils";

export default defineComponent({
  name: "PxNavbarMinimal",

  computed: {
    mainLogo(): string {
      return "/img/logos/abaca-logo--white.svg";
    },

    logoLink(): any {
      return !this.$user.isLogged() && isProduction
        ? "https://abaca.app"
        : HOME;
    },
  },
});
</script>

<style lang="scss" scoped>
.px-minimal-navbar {
  @include gradient("blue-gradient-1", 273deg);

  position: sticky;
  top: 0;
  z-index: z("navigation");
  display: block;
  width: 100%;
  text-align: left;

  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.22);

  &::after {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;

    height: 1px;

    content: "";

    background: #004871;
  }

  &__wrapper {
    @include make-container();
    @include make-container-max-widths();

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 66px;
    min-height: auto;

    padding: 0;
  }

  &__left-side {
    position: relative;
    display: flex;
    flex-basis: 0;
    flex-direction: row;
    flex-grow: 1;
    align-items: center;
    justify-content: flex-start;

    & > * {
      position: relative;
      z-index: z("navigation") + 2;
    }
  }

  &__right-side {
    display: flex;
    flex-basis: 0;
    flex-grow: 1;
    justify-content: flex-end;
  }

  &__center {
    margin: 0 auto;
  }

  &__logo {
    width: 92px;
    margin: 17px 0;
  }

  &__headline {
    @include grotesk(semiBold);

    margin: auto;
    font-size: 22px;
    line-height: 41px;
    color: $white;
    letter-spacing: -0.05px;
  }
}
</style>
