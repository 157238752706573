import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-4ba9686c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "offerings-selector" }
const _hoisted_2 = { class: "offerings-selector__entry-name" }
const _hoisted_3 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ElOption = _resolveComponent("ElOption")!
  const _component_ElSelect = _resolveComponent("ElSelect")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass([{
        'offerings-selector__list--with-entries': _ctx.selectedOfferingTypes.length,
      }, "offerings-selector__list"])
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.selectedOfferingTypes, (offeringType) => {
        return (_openBlock(), _createElementBlock("div", {
          key: offeringType.id,
          class: "offerings-selector__entry"
        }, [
          _createElementVNode("span", _hoisted_2, _toDisplayString(offeringType.name), 1),
          _createElementVNode("i", {
            class: "icon icon-trash--dark",
            onClick: ($event: any) => (_ctx.onClickRemoveOfferingType(offeringType.id))
          }, null, 8, _hoisted_3)
        ]))
      }), 128))
    ], 2),
    _createVNode(_component_ElSelect, {
      disabled: !_ctx.listOfOfferingTypes.length,
      placeholder: _ctx.$t('profile.supporter.offerings.form.select.types'),
      class: "offerings-edit-modal__select",
      onBlur: _ctx.blurHandler,
      onChange: _ctx.onOfferingTypeSelect
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.listOfOfferingTypes, (type, key) => {
          return (_openBlock(), _createBlock(_component_ElOption, {
            key: key,
            label: type.name,
            value: type,
            "value-key": type.id
          }, null, 8, ["label", "value", "value-key"]))
        }), 128))
      ]),
      _: 1
    }, 8, ["disabled", "placeholder", "onBlur", "onChange"])
  ]))
}