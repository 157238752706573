<template>
  <div class="milestones-full-grid">
    <div class="milestones-full-grid__levels">
      <div
        v-for="level in invertedSortLevels"
        :key="level.id"
        :data-testid="`level-number-${level.value}`"
        class="milestones-full-grid__level"
      >
        <div class="milestones-full-grid__level-title">
          {{ level.title }}
        </div>
        <PxIcon
          :name="`level-graph/${level.value}`"
          :size="42"
          class="milestones-full-grid__level-icon"
        />
      </div>
      <div class="milestones-full-grid__levels-progress-bar">
        <div
          :style="{
            height: `${currentLevelLinePosition}px`,
          }"
          class="milestones-full-grid__levels-progress-bar-fill"
          data-testid="levels-progress-bar-fill"
        />
      </div>
    </div>
    <div class="milestones-full-grid__grid">
      <div
        v-for="(category, index) in categories"
        :key="category.name"
        class="milestones-full-grid__category"
      >
        <div
          :data-testid="`category-name-${index}`"
          class="milestones-full-grid__category-name"
        >
          {{ category.name }}
        </div>
        <div class="milestones-full-grid__category-milestones">
          <MilestonesFullGridCell
            v-for="milestone in getSortedMilestones(category.milestones)"
            :key="milestone.level"
            :color="category.color"
            :completed="milestone.completed"
            :critical="milestone.critical"
            :data-testid="`milestone-${index}-${milestone.level}-cell`"
            :description="milestone.description"
            :evidence-provided="milestone.evidenceProvided"
            :future="milestone.future"
            :in-progress="milestone.inProgress"
            :planned="milestone.planned"
            class="milestones-full-grid__milestone"
          />
        </div>
      </div>
      <div
        class="milestones-full-grid__category milestones-full-grid__category--typical-funding"
      >
        <div class="milestones-full-grid__category-name">
          {{ $t("milestonePlanner.milestoneGrid.typicalFunding") }}
        </div>
        <div class="milestones-full-grid__category-milestones">
          <div
            v-for="level in invertedSortLevels"
            :key="level.id"
            :data-testid="`typical-funding-level-${level.value}`"
            class="milestones-full-grid__milestone milestones-full-grid__typical-funding"
          >
            <span>
              {{ level.typical_funding }}
            </span>
          </div>
        </div>
      </div>
      <div
        :style="{
          transform: `translateY(${-currentLevelLinePosition}px)`,
        }"
        class="milestones-full-grid__current-level-line"
        data-testid="current-level-line"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { orderBy } from "lodash";

import { defineComponent } from "vue";
import {
  IGridCategory,
  IGridMilestone,
} from "@/modules/milestone-planner/components/milestones-grid/milestones-grid.interface";
import MilestonesFullGridCell from "@/modules/milestone-planner/components/milestones-full-grid/milestones-full-grid-cell.vue";
import { ILevel } from "@/services/data/level/level.interface";

export default defineComponent({
  name: "MilestonesFullGrid",

  components: {
    MilestonesFullGridCell,
  },

  props: {
    categories: {
      type: Array as () => IGridCategory[],
      required: true,
    },

    levels: {
      type: Array as () => ILevel[],
      required: true,
    },

    currentLevel: {
      type: Number,
      required: true,
    },
  },

  computed: {
    currentLevelLinePosition(): number {
      const cellHeightPx = 74;
      const cellUpperMarginPx = 7;
      const totalCellHeight = cellHeightPx + cellUpperMarginPx;

      return this.currentLevel * totalCellHeight - cellUpperMarginPx / 2;
    },

    invertedSortLevels(): ILevel[] {
      return orderBy(this.levels, ["value"], ["desc"]);
    },
  },

  methods: {
    getSortedMilestones(
      unsortedMilestones: IGridMilestone[],
    ): IGridMilestone[] {
      return [...unsortedMilestones].reverse();
    },
  },
});
</script>

<style lang="scss" scoped>
.milestones-full-grid {
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;

  &__levels {
    position: relative;
    display: flex;
    flex: 0 0 169px;
    flex-direction: column;
    gap: 7px;
    align-self: flex-end;
    margin-top: 44px;
  }

  &__level {
    @include grotesk(semiBold);

    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    height: 74px;
    font-size: 14px;
    line-height: 20px;
    color: $denim;
    text-align: right;
  }

  &__level-icon {
    margin: 1px 16px 0 12px;
  }

  &__levels-progress-bar {
    position: absolute;
    right: 31px;
    z-index: z("bellow");
    width: 12px;
    height: 100%;
    overflow: hidden;
    background-color: rgba($malibu, 0.12);
    border-radius: 6px;
  }

  &__levels-progress-bar-fill {
    position: absolute;
    bottom: 0;
    width: 12px;
    background-color: rgba($malibu, 0.5);
  }

  &__grid {
    position: relative;
    display: flex;
    flex-basis: 0;
    flex-grow: 1;
    gap: 10px;
  }

  &__category {
    width: 100%;
  }

  &__category-name {
    @include grotesk(semiBold);

    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px 0;
    overflow: hidden;
    font-size: 14px;
    line-height: 20px;
    color: $manatee;
    text-align: center;
  }

  &__milestone {
    margin-bottom: 7px;
    outline: none;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__current-level-line {
    position: absolute;
    right: -2px;
    bottom: -1px;
    width: calc(100% + 158px);
    height: 2px;
    background-color: $ebony-clay;
  }

  &__category--typical-funding {
    flex: 0 0 114px;

    .milestones-full-grid__category-name {
      color: $malibu;
    }

    .milestones-full-grid__category-milestones {
      flex-direction: column;
    }

    .milestones-full-grid__milestone {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      width: 100%;
      height: 74px;
      padding: 0 8px;
      color: $denim;
      text-align: right;
      background-color: #eff6ff;
      border-radius: 4px;

      &:first-child {
        margin-bottom: 7px;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    .milestones-full-grid__milestone span {
      @include grotesk(semiBold);

      font-size: 13px;
      line-height: 20px;
      white-space: pre-line;
    }
  }
}
</style>
