import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "update-range-level page-full-height" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MobileTopNavbar = _resolveComponent("MobileTopNavbar")!
  const _component_PxNavbar = _resolveComponent("PxNavbar")!
  const _component_PxGlobalError = _resolveComponent("PxGlobalError")!
  const _component_Assessment = _resolveComponent("Assessment")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.$screen.mdDown)
      ? (_openBlock(), _createBlock(_component_MobileTopNavbar, { key: 0 }))
      : (_openBlock(), _createBlock(_component_PxNavbar, {
          key: 1,
          items: _ctx.activeNavBarItems,
          "show-account-dropdown": _ctx.$user.isLogged()
        }, null, 8, ["items", "show-account-dropdown"])),
    (_ctx.hasError)
      ? (_openBlock(), _createBlock(_component_PxGlobalError, {
          key: 2,
          "auto-refresh": true,
          loading: _ctx.isLoading,
          "use-action": true,
          class: "page-body is-center",
          onDoAction: _ctx.fetchCategories
        }, null, 8, ["loading", "onDoAction"]))
      : _withDirectives((_openBlock(), _createElementBlock("div", {
          key: 3,
          class: _normalizeClass([{ 'is-loading': _ctx.isLoading }, "update-range-level__container"]),
          "element-loading-background": "#fafafa"
        }, [
          _createVNode(_component_Assessment, { "end-action": _ctx.onEndAction }, null, 8, ["end-action"])
        ], 2)), [
          [_directive_loading, _ctx.isLoading]
        ])
  ]))
}