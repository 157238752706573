<template>
  <div v-loading="loading" class="auth-base-password auth-base-password--reset">
    <div class="page-wrapper">
      <div class="page-container">
        <h1 class="page-headline">
          {{ $t("authentication.passwordReset.title") }}
        </h1>
        <div
          class="page-subtitle"
          v-html="$t('authentication.passwordReset.instructions')"
        />
        <div class="page-block page-block--with-separator">
          <PxPanel class="px-panel--compact px-panel--stripped">
            <ElForm
              ref="resetPasswordForm"
              :model="fields"
              :rules="rules"
              class="auth-base-password__form"
              @validate="updateSubmitState"
            >
              <ElFormItem
                :label="$t('authentication.passwordReset.fields.newPassword')"
                prop="password1"
              >
                <span
                  class="el-form-item__top-text el-form-item__requirement"
                  v-text="$t('authentication.passwordReset.requirement')"
                />
                <PxInputPassword
                  v-model="fields.password1"
                  :placeholder="
                    $t('authentication.passwordReset.fields.newPassword')
                  "
                  :show-success-state="true"
                  @input="validateConfirmationField"
                />
                <span
                  class="el-form-item__bottom-text el-form-item__tip"
                  v-html="$t('authentication.passwordReset.tip')"
                />
              </ElFormItem>
              <ElFormItem
                :label="
                  $t('authentication.passwordReset.fields.confirmPassword')
                "
                prop="password2"
              >
                <ElInput
                  v-model="fields.password2"
                  :disabled="passwordIsInvalid"
                  :placeholder="
                    $t('authentication.passwordReset.fields.confirmPassword')
                  "
                  class="el-input--password"
                  type="password"
                />
              </ElFormItem>
              <ElFormItem class="el-form__cta">
                <PxButton
                  :disabled="submissionDisabled"
                  :loading="loading"
                  class="el-button--block"
                  size="medium"
                  type="primary"
                  @click="submitForm"
                >
                  {{ $t("authentication.passwordReset.submitButton") }}
                </PxButton>
              </ElFormItem>
            </ElForm>
          </PxPanel>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import { passwordResetProvider } from "@/modules/authentication/services/data/password-reset/password-reset.provider";
import { IPasswordReset } from "@/modules/authentication/services/data/password-reset/password-reset.interface";

import { confirmResetTokenProvider } from "@/modules/authentication/services/data/password-reset/confirm-reset-token.provider";
import { IConfirmResetToken } from "@/modules/authentication/services/data/password-reset/confirm-reset-token.interface";

import {
  allFormFieldsValid,
  generatePasswordCheck,
  generatePasswordValidator,
} from "@/services/errors/validator-generators";
import { ROUTE_AUTHENTICATION_LOGIN } from "@/modules/authentication/services/router/routes-names";
import { FormInstance } from "element-plus";
import { IFormField } from "@/modules/company-lists/components/list-management/list-management-modal.vue";

export default defineComponent({
  name: "AuthBasePasswordReset",

  data() {
    return {
      loading: false,
      hasServerError: false,
      submissionDisabled: true,
      formEl: null as null | FormInstance,

      fields: {
        password1: "",
        password2: "",
      },

      rules: {
        password1: generatePasswordValidator(
          this,
          "authentication.signup.form.fields.password",
          {
            minimum: 8,
            enforceCharRequirements: true,
          },
        ),
        password2: generatePasswordCheck(this, "fields.password1", {
          minimum: 8,
        }),
      },
    };
  },

  computed: {
    formFields(): IFormField[] | null {
      return this.formEl ? ((this.formEl as any).fields as IFormField[]) : null;
    },

    passwordIsInvalid(): boolean {
      if (this.formFields) {
        const formFields = this.formFields;

        const passwordField = formFields.find(
          (field) => field.prop === "password1",
        );

        return passwordField?.validateState !== "success";
      }
      return true;
    },
  },

  created() {
    this.confirmResetToken();
  },

  mounted() {
    (this as any).formEl = this.$refs.resetPasswordForm;
  },

  methods: {
    updateSubmitState() {
      this.submissionDisabled = !allFormFieldsValid(this.formEl, this.rules);
    },

    async confirmResetToken() {
      const dataToSend = {
        user: this.$route.params.uid,
        key: this.$route.params.token,
      } as IConfirmResetToken;

      this.loading = true;

      try {
        await confirmResetTokenProvider.create(dataToSend);
      } catch (error) {
        this.$message({
          message: this.$root?.$t(
            "authentication.passwordReset.invalidTokenMessage",
          ) as string,
          type: "error",
          customClass: "is-full",
        });

        this.$router.replace({ name: ROUTE_AUTHENTICATION_LOGIN });
      } finally {
        this.loading = false;
      }
    },

    async submitForm() {
      const dataToSend = {
        ...this.fields,
        user: this.$route.params.uid,
        key: this.$route.params.token,
      } as IPasswordReset;

      this.hasServerError = false;
      this.loading = true;

      try {
        await passwordResetProvider.create(dataToSend);
      } catch (error) {
        this.hasServerError = true;
        return;
      } finally {
        this.loading = false;
      }

      this.$message({
        message: this.$t(
          "authentication.passwordReset.successMessage",
        ) as string,
        type: "success",
        customClass: "is-full",
      });

      this.$router.push({ name: ROUTE_AUTHENTICATION_LOGIN });
    },

    validateConfirmationField() {
      if (!this.passwordIsInvalid) {
        this.formEl?.validateField("password2", this.updateSubmitState);
      }
    },
  },
});
</script>

<style lang="scss" scoped>
.auth-base-password .page-wrapper {
  padding-bottom: 32px;

  @include breakpoint-up(md) {
    padding-bottom: 40px;
  }
}
</style>
