import { EMilestonePlannerState } from "@/modules/milestone-planner/services/store/milestone-planner/milestone-planner.types";
import { milestonePlannerModule } from "@/modules/milestone-planner/services/store/milestone-planner/milestone-planner.module";

// Modules to register on the Vuex instance
export const modules = {
  [EMilestonePlannerState.NAMESPACE]: milestonePlannerModule,
};

// Modules to persist cross sessions
export const toPersist = [
  `${EMilestonePlannerState.NAMESPACE}.passcodes`,
  `${EMilestonePlannerState.NAMESPACE}.onboardings`,
];
