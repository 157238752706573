<template>
  <div
    :class="{
      'is-small': isSmall,
      'mobile-top-navbar--affiliate': showAffiliateLogo,
      'mobile-top-navbar--partner': isPartner,
    }"
    :style="{ minHeight: `${rootHeight}px` }"
    class="mobile-top-navbar"
  >
    <div
      v-observer:subtree.childlist="updateNavHeight"
      class="mobile-top-navbar__wrapper"
    >
      <div class="mobile-top-navbar__logo-wrapper">
        <img
          :src="mainLogo"
          alt="VIRAL Logo"
          class="mobile-top-navbar__logo"
          data-testid="mobile-top-navbar__logo"
        />
        <div
          v-if="showAffiliateLogo"
          class="mobile-top-navbar__logo-affiliate-wrapper"
        >
          <img
            :src="affiliateLogo"
            alt="Affiliate Logo"
            class="mobile-top-navbar__logo-affiliate"
          />
        </div>
        <div
          v-else-if="isPartner"
          class="mobile-top-navbar--partner__description-wrapper"
        >
          <p class="mobile-top-navbar--partner__description-text">
            {{ getPartnerName }}
          </p>
        </div>
      </div>
      <div v-if="!isPartner" class="mobile-top-navbar__menu-wrapper">
        <PxIcon
          v-touch="toggleMenu"
          :name="`menu-hamburguer`"
          :size="24"
          class="mobile-top-navbar__menu-open"
          data-testid="mobile-top-navbar__menu-open"
        />
        <div
          ref="mobileMenu"
          v-touch:start.end.move.moving="touchMobileMenuHandler"
          :class="{
            'mobile-top-navbar__menu-overlay--active': isMobileMenuOpen,
          }"
          class="mobile-top-navbar__menu-overlay"
        >
          <img
            alt="abaca logo"
            class="mobile-top-navbar__menu-logo"
            src="/img/logos/abaca-logo--dark.svg"
          />
          <ul class="mobile-top-navbar__menu-links">
            <li>
              <a
                :href="howItWorksLink.url"
                class="mobile-top-navbar__link"
                target="_blank"
              >
                {{ howItWorksLink.title }}
              </a>
            </li>
          </ul>
          <a
            v-if="isVisitorNavigation"
            v-touch="onClickLogin"
            class="mobile-top-navbar__link mobile-top-navbar__link--login"
            data-testid="mobile-top-navbar__login-button"
            href="javascript:void(0)"
          >
            {{ $t("common.navBar.login") }}
          </a>
          <PxIcon
            v-touch="toggleMenu"
            :name="`menu-cross`"
            :size="24"
            class="mobile-top-navbar__menu-close"
          />
        </div>
      </div>
      <div v-if="isToShowSubTitle" class="mobile-top-navbar__find-level">
        <template v-if="isAssessment">
          {{ $t("selfAssessment.assessment.headerCopy") }}
        </template>
        <template v-else>
          {{ $t("selfAssessment.results.headerCopy") }}
        </template>
      </div>
      <div class="mobile-top-navbar__content">
        <slot name="default" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import {
  SELF_ASSESSMENT_ASSESSMENT,
  SELF_ASSESSMENT_RESULTS,
} from "@/modules/self-assessment/services/router/routes-names";

import { SUPPORTERS_ASSESSMENT } from "@/modules/supporters/services/router/routes-names";
import { IViralLevelState } from "@/services/store/viral-level/viral-level-types";
import { ROUTE_AUTHENTICATION_LOGIN } from "@/modules/authentication/services/router/routes-names";
import { getAppPartner, isPartner } from "@/services/utils/utils";
import { ICompany } from "@/modules/profile/services/data/company/company.types";

// Distance that the user needs to scroll to collapse the header
const COLLAPSE_DISTANCE = 47;

// Distance in milliseconds that a scroll event must occur
const DEBOUNCE_TIME = 300;

// Store the last computed scroll position. This will be used to
// decide if the menu should be collapsed of fully presented.
let scrollState = 0;
let scrollDelta = 0;
let isGoingDown = true;

// Informs it's currently waiting for a scroll event to be called
let isWaitingCall = false;

const TRIGGER_EVENT = "ontouchstart" in window ? "touchmove" : "wheel";

const FIXED_CONTAINER_SELECTOR = ".mobile-top-navbar__wrapper";
const LOGO_SELECTOR = ".mobile-top-navbar__logo";
const CONTENT_SELECTOR = ".mobile-top-navbar__content";

export default defineComponent({
  name: "MobileTopNavbar",

  props: {
    /**
     * Affiliate logo.
     */
    affiliateLogo: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      // Height that the root element must fill
      rootHeight: 0,
      // This informs if the navbar is collapsed
      isSmall: false,
      // his informs if the mobile menu is open
      isMobileMenuOpen: false,
    };
  },

  computed: {
    /**
     * Informs if the mobile menu should collapse on scroll.
     */
    isToCollapse(): boolean {
      // TODO: check the router where we are in to decide how to behave
      return false;
    },

    /**
     * Check if the navbar subtitle must be visible on this page.
     */
    isToShowSubTitle(): boolean {
      return [
        SELF_ASSESSMENT_ASSESSMENT,
        SUPPORTERS_ASSESSMENT,
        SELF_ASSESSMENT_RESULTS,
      ].includes(this.$route.name as string);
    },

    /**
     * Check we are in the assessment page.
     */
    isAssessment(): boolean {
      return (
        this.$route.name === SELF_ASSESSMENT_ASSESSMENT ||
        this.$route.name === SUPPORTERS_ASSESSMENT
      );
    },

    /**
     * Gets the viralLevelStore
     */
    viralLevelStore(): IViralLevelState {
      return this.$store.state.viralLevel as IViralLevelState;
    },

    /**
     * Informs if the affiliate logo must be visible.
     */
    showAffiliateLogo(): boolean {
      return !!this.affiliateLogo && !this.viralLevelStore.loading;
    },

    /**
     * Gives the main logo that must be used.
     */
    mainLogo(): string {
      return this.showAffiliateLogo
        ? "/img/logos/abaca-logo--dark.svg"
        : "/img/logos/abaca-logo--white.svg";
    },

    howItWorksLink(): any {
      return {
        url: "https://abaca.app/how-it-works",
        title: this.$t("common.navBar.howItWorks"),
      };
    },

    isPartner(): boolean {
      return isPartner;
    },

    getPartnerName(): string {
      return getAppPartner();
    },

    company(): ICompany | null {
      return this.$store.get("auth/company/data");
    },

    isVisitorNavigation(): boolean {
      return !this.company || !this.$user.isLogged();
    },
  },

  /**
   * Add some DOM listeners to control the collapse and height
   * of the navbar.
   */
  async mounted() {
    // Add a listener to compute the navbar height after
    // the logo loading.
    const logoElement = this.$el.querySelector(LOGO_SELECTOR);

    if (logoElement) {
      logoElement.addEventListener("load", this.updateNavHeight);
    }

    // Add a listener to the wheel in order to we compute the scroll
    // distances and see if the navbar state must be toggled.
    // https://github.com/vuejs/vue/pull/6856
    document.addEventListener(TRIGGER_EVENT, this.onScroll.bind(this), {
      capture: true,
      passive: true,
    });
  },

  /**
   * Remove all DOM listeners before destroying the component.
   */
  beforeUnmount() {
    document.removeEventListener(TRIGGER_EVENT, this.onScroll.bind(this));
  },

  methods: {
    /**
     * Update the navbar root height.
     *
     * With this technic we avoid to need to setup a manual padding
     * in each page that this component is in. The base component
     * will always fill out the space occupied by the fixed element.
     */
    updateNavHeight() {
      const containerElement = this.$el.querySelector(FIXED_CONTAINER_SELECTOR);

      if (!containerElement) {
        return;
      }

      const contentElement = containerElement.querySelector(CONTENT_SELECTOR);

      if (!contentElement) {
        return;
      }

      this.rootHeight =
        containerElement.offsetHeight - contentElement.offsetHeight;
    },

    /**
     * Determines if the tolerance has been exceeded.
     */
    toleranceExceeded(): boolean {
      return Math.abs(scrollDelta) >= COLLAPSE_DISTANCE;
    },

    /**
     * Determines if is appropriate to expand the tab bar.
     */
    shouldExpand(isToleranceExceeded: boolean): boolean {
      return isGoingDown && isToleranceExceeded;
    },

    /**
     * Determines if is appropriate to retract the tab bar.
     */
    shouldRetract(isToleranceExceeded: boolean): boolean {
      return !isGoingDown && isToleranceExceeded;
    },

    scrollTrigger() {
      const currentScroll = window.pageYOffset;

      // If the scroll is near the top the tab bar must expand
      if (currentScroll <= COLLAPSE_DISTANCE) {
        this.isSmall = false;
        scrollDelta = 0;
      }

      const isToleranceExceeded = this.toleranceExceeded();
      const immediateDelta = scrollState - currentScroll;
      isGoingDown = immediateDelta > 0;

      // Reset scroll delta when the user changes the scroll direction
      if (
        (isGoingDown && scrollDelta < 0) ||
        (!isGoingDown && scrollDelta > 0)
      ) {
        scrollDelta = 0;
      } else {
        scrollDelta += immediateDelta;
      }

      if (this.shouldRetract(isToleranceExceeded)) {
        this.isSmall = true;
      } else if (this.shouldExpand(isToleranceExceeded)) {
        this.isSmall = false;
      }

      // Save current scroll position and the scroll direction
      scrollState = currentScroll;
    },

    /**
     * Handle browser scroll event call.
     *
     * Use a throttle strategy to minimize the performance costs.
     */
    onScroll(event: TouchEvent | MouseEvent) {
      const isScrollingMobileMenu = event.target === this.$refs.mobileMenu;
      if (isWaitingCall || isScrollingMobileMenu) {
        return;
      }

      isWaitingCall = true;
      setTimeout(() => {
        this.scrollTrigger();
        isWaitingCall = false;
        this.isMobileMenuOpen = false;
      }, DEBOUNCE_TIME);
    },

    onClickLogin() {
      this.$router.push({ name: ROUTE_AUTHENTICATION_LOGIN });
    },

    toggleMenu(event?: TouchEvent) {
      // Prevent Chrome Desktop from firing a follow-up mouse event
      if (event) {
        event.preventDefault();
      }

      this.isMobileMenuOpen = !this.isMobileMenuOpen;
    },

    touchMobileMenuHandler(event: TouchEvent) {
      event.stopImmediatePropagation();
      return false;
    },
  },
});
</script>

<style lang="scss" scoped>
.mobile-top-navbar {
  position: relative;
  display: block;
  width: 100vw;

  &__wrapper {
    @include gradient("blue-gradient-1", 293deg);

    position: fixed;
    top: 0;
    z-index: z("navigation");
    width: 100vw;

    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.19);

    transition: transform 0.2s ease-in-out;
  }

  &__logo-wrapper {
    position: relative;
    z-index: z("navigation");
    display: flex;
    justify-content: center;
  }

  &__logo {
    z-index: z("navigation") + 2;
    max-width: 74px;
    padding: 14px 0 15px;
  }

  &__find-level {
    @include grotesk(medium);

    padding: 9px 0 12px;

    font-size: 1.1333rem;
    color: white;
    text-align: center;
    letter-spacing: 0.15px;
  }

  &__logo-affiliate-wrapper {
    position: relative;
    z-index: z("navigation") + 2;
    display: flex;
    align-items: center;
    margin-left: 12px;

    &::before {
      position: absolute;
      top: 50%;
      width: 1px;
      height: 19px;

      content: "";

      background-color: rgba(39, 39, 64, 0.4);
      opacity: 0.24;
      transform: translateY(-50%);
    }
  }

  &__logo-affiliate {
    position: relative;
    max-width: 112px;
    max-height: 20px;
    padding-left: 12px;
  }
}

/* Affiliate Program Theme */
.mobile-top-navbar.mobile-top-navbar--affiliate {
  .mobile-top-navbar__logo-wrapper {
    justify-content: left;
    width: 315px;
    padding: 2px 15px 3px 20px;
  }

  .mobile-top-navbar__logo-wrapper::before {
    position: absolute;
    top: 0;
    left: -55px;
    z-index: z("navigation") + 1;
    width: 315px;
    height: 51px;

    content: "";

    background-image: url("#{$assetsPath}/img/menu-background-affiliated.svg");
    background-repeat: no-repeat;
    background-size: 100% 58px;
  }

  .mobile-top-navbar__logo {
    left: auto;
    padding: 10px 0;
    transform: none;
  }
}

/* Partner Theme */
.mobile-top-navbar.mobile-top-navbar--partner {
  .mobile-top-navbar__wrapper {
    @include gradient("green-gradient-2", 273deg);
  }

  .mobile-top-navbar--partner__description-wrapper {
    padding: 10px 8px;
  }

  .mobile-top-navbar--partner__description-text {
    margin: 0;
    font-size: 17px;
    font-weight: bold;
    line-height: 35px;
    color: $white;
    text-transform: uppercase;
  }
}

.mobile-top-navbar.is-small {
  .mobile-top-navbar__wrapper {
    transform: translateY(-43px);
  }
}

.px-navbar--partner__description-wrapper {
  padding: 10px 24px;
}

.px-navbar--partner__description-text {
  margin: 0;
  font-size: 17px;
  font-weight: bold;
  color: $white;
  text-transform: uppercase;
}

.px-navbar--partner__alpha-text {
  margin: 0;
  font-size: 15px;
  font-style: italic;
  color: $white;
  text-transform: uppercase;
}

.mobile-top-navbar__menu-wrapper {
  position: absolute;
  top: 1px;
  right: 2px;
  z-index: z("navigation") + 1;
}

.mobile-top-navbar__menu-open {
  box-sizing: content-box;
  padding: 13px;
}

.mobile-top-navbar__menu-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  text-align: center;
  pointer-events: none;
  touch-action: none;
  background-color: white;
  box-shadow: 0 2px 16px 0 rgba(39, 39, 64, 0.2);
  opacity: 0;
  transition: opacity 0.23s ease-in-out;
  will-change: opacity;

  &--active {
    pointer-events: auto;
    opacity: 1;
  }

  &::before {
    position: absolute;
    top: 53px;
    right: 0;
    left: 0;
    width: 290px;
    height: 1px;
    margin: auto;

    content: "";
    background-color: rgba($ebony-clay, 0.05);
  }
}

.mobile-top-navbar__menu-logo {
  padding: 11px;
}

.mobile-top-navbar__menu-close {
  position: absolute;
  top: 0;
  right: 0;
  box-sizing: content-box;
  padding: 15px;
}

.mobile-top-navbar__menu-links {
  padding: 0;
  margin: 0;
  list-style: none;

  li {
    position: relative;
    padding: 11px 0;

    &::after {
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      width: 290px;
      height: 1px;
      margin: auto;

      content: "";
      background-color: rgba($ebony-clay, 0.05);
    }
  }
}

.mobile-top-navbar__link {
  @include grotesk(medium);

  display: inline-block;
  padding: 13px;

  font-size: to-rem(17px);
  color: $ebony-clay;
  text-align: center;
  text-decoration: none;
  transition: opacity 0.23s ease-in-out;
  will-change: opacity;

  &:hover {
    opacity: 0.7;
  }
}

.mobile-top-navbar__link--login {
  display: inline-block;
  margin: 8px 0;
}

.mobile-top-navbar__loginButton {
  margin: 25px 0;
}
</style>
