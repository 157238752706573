import {
  EProviderFeatures,
  GenericProvider,
  Provider,
} from "@/services/data/generic-provider";
import { IMatchingResponse } from "./matching-response.interface";
import { baseAPIUrl } from "@/services/utils/utils";

@Provider(`${baseAPIUrl}/matching/responses`, [
  EProviderFeatures.CREATE,
  EProviderFeatures.LIST,
])
class MatchingResponsesProvider extends GenericProvider<IMatchingResponse> {
  /**
   * Fetch user latest responses
   *
   * @returns {Promise<IMatchingResponse[]>}
   * @memberof MatchingResponsesProvider
   */
  public async getLatest(): Promise<IMatchingResponse[]> {
    const url = super.buildEndPointUrl({}, "latest");
    const request = this.httpClient.get(url);
    const { data } = await this.wrapRequest(request);

    return data as IMatchingResponse[];
  }
}

export const matchingResponsesProvider = new MatchingResponsesProvider();
