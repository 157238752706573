import {
  EProviderFeatures,
  GenericProvider,
} from "@/services/data/generic-provider";
import { baseAPIUrl } from "@/services/utils/utils";
import { IAdditionalCriteria } from "@/modules/supporters/services/data/additional-criteria/additional-criteria.interface";

class AdditionalCriteriaProvider extends GenericProvider<IAdditionalCriteria> {
  constructor() {
    super(`${baseAPIUrl}/matching/questions/additional-criteria`, [
      EProviderFeatures.LIST,
    ]);
  }
}

export const additionalCriteriaProvider = new AdditionalCriteriaProvider();
