<template>
  <div class="px-avatar-grid">
    <div
      v-for="(member, index) in visibleItems"
      :key="index"
      :style="itemDimensions"
      class="px-avatar-grid__item"
    >
      <PxAvatar :name="member.name" :photo="member.logo" :size="size" />
    </div>
    <div
      v-if="hasItemsOverLimit"
      :style="itemDimensions"
      class="px-avatar-grid__item px-avatar-grid__item--count"
      v-text="`+${remainingItemsCount}`"
    />
    <div
      v-for="(placeholder, index) in emptyPlaceholders"
      :key="index"
      :style="itemDimensions"
      class="px-avatar-grid__item px-avatar-grid__item--empty"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export interface IAvatarGridItem {
  name: string;
  logo: string;
}

export default defineComponent({
  name: "PxAvatarGrid",

  props: {
    // By default, having zero will show all items.
    visibleLimit: {
      type: Number,
      default: 0,
    },
    items: {
      type: Array as () => Array<IAvatarGridItem>,
      required: true,
    },
    size: {
      type: [Number, String],
      required: true,
    },
    showEmptyPlaceholders: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    itemDimensions(): { [key: string]: string } {
      return { width: `${this.size}px`, height: `${this.size}px` };
    },

    itemsLimit(): number {
      return this.visibleLimit || this.items.length;
    },

    hasItemsOverLimit(): boolean {
      return this.items.length > this.itemsLimit;
    },

    amountOfAvatarsToShow(): number {
      // If limit is reached, we need to leave space (- 1) to show the remaining items count.
      return this.hasItemsOverLimit ? this.itemsLimit - 1 : this.itemsLimit;
    },

    visibleItems(): Array<IAvatarGridItem> {
      return this.items.slice(0, this.amountOfAvatarsToShow);
    },

    remainingItemsCount(): number {
      return this.items.length - this.visibleItems.length;
    },

    emptyPlaceholders(): Array<number> {
      return this.showEmptyPlaceholders && !this.items.length
        ? Array(this.visibleLimit)
        : [];
    },
  },
});
</script>

<style lang="scss" scoped>
.px-avatar-grid {
  display: flex;
  flex-flow: row wrap;
}

.px-avatar-grid__item {
  overflow: hidden;
  border-radius: 1.5px;
  box-shadow: inset 0 0 2px 0 rgba(139, 143, 161, 0.3);

  &:nth-child(3n + 2) {
    margin: 0 4px;
  }

  &:nth-child(n + 4) {
    margin-top: 4px;
  }
}

.px-avatar-grid__item--count {
  @include grotesk(semiBold);

  position: relative;
  bottom: 2px;

  display: flex;
  align-items: center;
  justify-content: center;

  overflow: visible;
  font-size: 12px;
  color: $manatee;
  text-align: center;
  // For pixel-perfect alignment:
  text-indent: 1px;
  box-shadow: none;
}

.px-avatar-grid__item :deep() img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
</style>
