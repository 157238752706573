import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-65d27797"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "overall-level"
}
const _hoisted_2 = { class: "overall-level__wrapper" }
const _hoisted_3 = { class: "overall-level__title" }
const _hoisted_4 = { class: "overall-level__description" }
const _hoisted_5 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PxIcon = _resolveComponent("PxIcon")!
  const _component_PxButton = _resolveComponent("PxButton")!
  const _component_ElCollapseItem = _resolveComponent("ElCollapseItem")!
  const _component_ElCollapse = _resolveComponent("ElCollapse")!

  return (_ctx.level)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_ElCollapse, { accordion: "" }, {
          default: _withCtx(() => [
            _createVNode(_component_ElCollapseItem, null, {
              title: _withCtx(() => [
                _createElementVNode("div", _hoisted_2, [
                  _createVNode(_component_PxIcon, {
                    name: `level-graph/${_ctx.level.value}`,
                    size: 100
                  }, null, 8, ["name"]),
                  _createElementVNode("div", null, [
                    _createElementVNode("h2", _hoisted_3, _toDisplayString(_ctx.$t("milestonePlanner.sidebar.level.title")), 1),
                    _createElementVNode("div", _hoisted_4, [
                      _createElementVNode("h3", null, [
                        _createTextVNode(_toDisplayString(_ctx.$t("profile.assessment.levelPanel.label")) + " " + _toDisplayString(_ctx.level.value) + " ", 1),
                        (_ctx.$user.isOwner())
                          ? (_openBlock(), _createBlock(_component_PxButton, _mergeProps({
                              key: 0,
                              class: "overall-level__quick-update-button"
                            }, _ctx.quickUpdateButtonProps, { onClick: _ctx.onQuickUpdateClick }), null, 16, ["onClick"]))
                          : _createCommentVNode("", true)
                      ]),
                      _createElementVNode("p", null, _toDisplayString(_ctx.level.title), 1)
                    ])
                  ])
                ])
              ]),
              default: _withCtx(() => [
                _createElementVNode("div", {
                  innerHTML: _ctx.level.description
                }, null, 8, _hoisted_5)
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]))
    : _createCommentVNode("", true)
}