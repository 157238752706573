import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, withCtx as _withCtx, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-377a797c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "results-board" }
const _hoisted_2 = { class: "results-board__wrapper" }
const _hoisted_3 = {
  key: 0,
  class: "results-board__container"
}
const _hoisted_4 = { class: "results-board__score-wrapper" }
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = { class: "results-board__levels" }
const _hoisted_7 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PxIcon = _resolveComponent("PxIcon")!
  const _component_ElTooltip = _resolveComponent("ElTooltip")!
  const _component_PxPanel = _resolveComponent("PxPanel")!
  const _component_ResultsBoardJoin = _resolveComponent("ResultsBoardJoin")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_PxPanel, { class: "results-board__panel px-panel--mobile-no-side-borders" }, {
        default: _withCtx(() => [
          (_ctx.investingLevel.length)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, [
                  _createVNode(_component_PxIcon, {
                    name: `level-range-graph/level-${_ctx.investingLevelRangeInterval}`,
                    size: 174,
                    class: "results-board__level"
                  }, null, 8, ["name"])
                ]),
                _createElementVNode("h2", {
                  class: "results-board__title",
                  innerHTML: _ctx.investingLevelRangeTitle
                }, null, 8, _hoisted_5),
                _createElementVNode("ol", _hoisted_6, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.investingLevelsSelection, (level, key) => {
                    return (_openBlock(), _createElementBlock("li", {
                      key: key,
                      class: "results-board__levels-item"
                    }, [
                      _createElementVNode("b", null, _toDisplayString(_ctx.$t("supporters.results.levelPanel.label")) + " " + _toDisplayString(level.value), 1),
                      _createTextVNode(" - " + _toDisplayString(level.title) + " ", 1),
                      _createVNode(_component_ElTooltip, {
                        enterable: false,
                        placement: "top",
                        "popper-class": "results-board__levels-tooltip el-abaca-tooltip"
                      }, {
                        content: _withCtx(() => [
                          _createElementVNode("div", {
                            innerHTML: level.description
                          }, null, 8, _hoisted_7)
                        ]),
                        default: _withCtx(() => [
                          _createVNode(_component_PxIcon, {
                            size: 17,
                            class: "results-board__levels-tooltip-icon",
                            name: "question"
                          })
                        ]),
                        _: 2
                      }, 1024)
                    ]))
                  }), 128))
                ])
              ]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }),
      _createVNode(_component_ResultsBoardJoin)
    ])
  ]))
}