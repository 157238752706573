<template>
  <PxNavbar
    :external-link="externalLink"
    :loading="loading"
    :logo="logo"
    :show-login-button="showLoginButton"
    class="affiliate-navigation-bar"
  >
    <template v-if="showSteps && steps.length" #navbar-right>
      <div
        v-for="(step, index) in steps"
        :key="`affiliate-navigation-bar:${index}`"
        :class="{
          'affiliate-navigation-bar__list-item--active': step.id === activeStep,
          'affiliate-navigation-bar__list-item--disabled': disabled,
        }"
        class="affiliate-navigation-bar__list-item"
        @click="onStepClick(step.id)"
      >
        <p class="affiliate-navigation-bar__list-item-index">
          {{ index + 1 }}
        </p>
        <p class="affiliate-navigation-bar__list-item-title">
          <span>
            {{ $t(step.title) }}
          </span>
        </p>
      </div>
    </template>
    <template #login-button>
      <PxButton
        v-if="showLoginButton"
        class="el-button--darker"
        data-testid="affiliate-navigation-bar-login-button"
        type="primary"
        @click="onClickLogin"
      >
        {{ $t("common.navBar.login") }}
      </PxButton>
    </template>
  </PxNavbar>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import { IAffiliateProgramNavBarStep } from "@/modules/affiliates/views/affiliate-types/affiliate-question-bundle.view.vue";
import { ROUTE_AUTHENTICATION_LOGIN } from "@/modules/authentication/services/router/routes-names";

export default defineComponent({
  name: "AffiliateNavigationBar",

  props: {
    logo: {
      type: String as () => string | null,
      default: null,
    },

    externalLink: {
      type: String as () => string | null,
      default: null,
    },

    steps: {
      type: Array as () => Array<IAffiliateProgramNavBarStep>,
      default: () => [],
    },

    loading: {
      type: Boolean,
      default: false,
    },

    activeStep: {
      type: Number,
      default: 1,
    },

    showLoginButton: {
      type: Boolean,
      default: false,
    },

    showSteps: {
      type: Boolean,
      default: true,
    },

    disabled: {
      type: Boolean,
      default: false,
    },
  },

  computed: {},

  methods: {
    onStepClick(stepId: number) {
      this.$emit("update:activeStep", stepId);
      this.$emit("reset-steps");
    },

    onClickLogin() {
      this.$router.push({ name: ROUTE_AUTHENTICATION_LOGIN });
    },
  },
});
</script>

<style lang="scss" scoped>
.affiliate-navigation-bar {
  &__list-item {
    position: relative;
    display: grid;
    grid-template-columns: 23px auto;
    align-items: center;
    padding: 18px 27px;
    cursor: pointer;
    user-select: none;

    &:not(:last-child)::after {
      position: absolute;
      width: 1px;
      height: 100%;
      top: 0;
      right: 0;
      content: "";
      background-image: linear-gradient(
        to bottom,
        rgba($ebony-clay, 0),
        #004871 52%,
        rgba($ebony-clay, 0)
      );
    }

    &-title {
      display: grid;
      grid-template-columns: 0fr;
      white-space: nowrap;

      transition: all 0.25s;
    }

    &-title span {
      @include grotesk(semiBold);

      font-size: 16px;
      color: $white;
      overflow: hidden;
    }

    &-index {
      @include grotesk(bold);

      position: relative;
      display: inline-block;
      width: 23px;
      height: 23px;

      font-size: 15px;
      line-height: 23px;
      color: $white;
      text-align: center;
      background: $ebony-clay;
      border-radius: 50%;

      transition: background-color 0.3s linear;
    }
  }

  &__list-item--active {
    padding: 18px 26px;
    grid-template-columns: 29px auto;
  }

  &__list-item--disabled {
    pointer-events: none;
  }

  &__list-item--active > .affiliate-navigation-bar__list-item-index {
    @include grotesk(bold);

    color: $nice-blue;
    background: #fff;

    &::after {
      position: absolute;
      top: -3px;
      right: -3px;
      bottom: -3px;
      left: -3px;
      content: "";
      border: $white 1px solid;
      border-radius: 50%;
    }
  }

  &__list-item--active > .affiliate-navigation-bar__list-item-title {
    grid-template-columns: 1fr;
    margin-left: 10px;
  }
}
</style>
