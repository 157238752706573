<template>
  <div class="share-results">
    <PxButton
      class="share-results__button"
      v-bind="shareButtonProps"
      @click.stop="openShareModalClickHandler"
    />
    <ShareResultsModal
      :id="capitalExplorerSubmission.id"
      v-model:visibility="shareModalVisible"
      :passcode="capitalExplorerSubmissionPasscode"
      :uid="capitalExplorerSubmission.uid"
      :users="allInvitedUsers"
      data-testid="share-results-modal"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import ShareResultsModal from "@/modules/capital-explorer/components/share-results/share-results-modal.vue";

import {
  EPxButtonSize,
  EPxButtonVariant,
} from "@/components/px-button/px-button.types";
import { ICapitalExplorerInvitedUser } from "@/modules/capital-explorer/services/data/capital-explorer/capital-explorer.interface";

export default defineComponent({
  name: "ShareResults",

  components: {
    ShareResultsModal,
  },

  data() {
    return {
      shareModalVisible: false,
    };
  },

  computed: {
    shareButtonProps(): any {
      return {
        label: this.$t("common.shareResults"),
        icon: "share",
        iconPosition: "right",
        size: EPxButtonSize.SMALL,
        variant: EPxButtonVariant.FLUID,
      };
    },

    capitalExplorerSubmission(): any {
      return this.$store.get("capitalExplorer/data").submission;
    },

    capitalExplorerSubmissionPasscode(): string {
      return this.capitalExplorerSubmission.passcode || "";
    },

    allInvitedUsers(): Array<ICapitalExplorerInvitedUser> {
      return [
        ...(this.capitalExplorerSubmission?.invited_users || []),
        ...(this.capitalExplorerSubmission?.invited_guests || []),
      ].sort((userA, userB) => userA.name.localeCompare(userB.name));
    },
  },

  methods: {
    openShareModalClickHandler() {
      this.shareModalVisible = true;
    },
  },
});
</script>

<style lang="scss" scoped>
.share-results__button {
  padding: 0 9px 0 12px;

  :deep(span) {
    font-size: 13px;
    line-height: 16px;
  }

  &.icon-right :deep(span div) {
    margin-right: 5px;
  }
}
</style>
