import {
  GenericProvider,
  Provider,
  EProviderFeatures,
} from "@/services/data/generic-provider";
import { IAssessment } from "@/services/data/assessment/assessment.interface";
import { baseAPIUrl } from "@/services/utils/utils";

@Provider(`${baseAPIUrl}/assessments/latest`, [EProviderFeatures.GET])
class LatestAssessmentProvider extends GenericProvider<IAssessment> {}

export const latestAssessmentProvider = new LatestAssessmentProvider();
