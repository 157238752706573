import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = ["textContent"]
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = ["href"]
const _hoisted_4 = ["href"]
const _hoisted_5 = { class: "el-form__cta" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PxModalMessage = _resolveComponent("PxModalMessage")!
  const _component_SignUpModalSuccess = _resolveComponent("SignUpModalSuccess")!
  const _component_ElInput = _resolveComponent("ElInput")!
  const _component_ElFormItem = _resolveComponent("ElFormItem")!
  const _component_PxInputPassword = _resolveComponent("PxInputPassword")!
  const _component_i18n_t = _resolveComponent("i18n-t")!
  const _component_ElCheckbox = _resolveComponent("ElCheckbox")!
  const _component_PxButton = _resolveComponent("PxButton")!
  const _component_ElForm = _resolveComponent("ElForm")!
  const _component_ElDialog = _resolveComponent("ElDialog")!

  return (_openBlock(), _createBlock(_component_ElDialog, {
    ref: "modal",
    modelValue: _ctx.innerVisibility,
    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.innerVisibility) = $event)),
    title: _ctx.$t(`authentication.signup.modal.title`),
    class: "signup-modal"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_PxModalMessage, { visible: _ctx.hasServerErrors }, null, 8, ["visible"]),
      (_ctx.submittedSuccessfully)
        ? (_openBlock(), _createBlock(_component_SignUpModalSuccess, {
            key: 0,
            "registration-email": _ctx.userEmail
          }, null, 8, ["registration-email"]))
        : (_openBlock(), _createBlock(_component_ElForm, {
            key: 1,
            ref: "signUpForm",
            model: _ctx.fields,
            rules: _ctx.rules,
            class: "signup-modal__form",
            onValidate: _ctx.updateSubmitState
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ElFormItem, {
                error: _ctx.errors.getMessage('email') || '',
                label: _ctx.$t('authentication.login.form.fields.email'),
                prop: "email"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ElInput, {
                    modelValue: _ctx.fields.email,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.fields.email) = $event)),
                    placeholder: _ctx.$t('authentication.signup.form.placeholders.email'),
                    type: "email"
                  }, null, 8, ["modelValue", "placeholder"])
                ]),
                _: 1
              }, 8, ["error", "label"]),
              _createVNode(_component_ElFormItem, {
                label: _ctx.$t('authentication.signup.form.fields.password'),
                prop: "new_password1"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("span", {
                    class: "el-form-item__top-text el-form-item__requirement",
                    textContent: _toDisplayString(_ctx.$t('authentication.signup.form.requirement'))
                  }, null, 8, _hoisted_1),
                  _createVNode(_component_PxInputPassword, {
                    modelValue: _ctx.fields.new_password1,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.fields.new_password1) = $event)),
                    placeholder: _ctx.$t('authentication.signup.form.fields.password'),
                    "show-success-state": true,
                    onInput: _ctx.validateConfirmationField
                  }, null, 8, ["modelValue", "placeholder", "onInput"]),
                  _createElementVNode("span", {
                    class: "el-form-item__bottom-text el-form-item__tip",
                    innerHTML: _ctx.$t('authentication.signup.form.tip')
                  }, null, 8, _hoisted_2)
                ]),
                _: 1
              }, 8, ["label"]),
              _createVNode(_component_ElFormItem, {
                label: _ctx.$t('authentication.signup.form.fields.confirmPassword'),
                prop: "new_password2"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ElInput, {
                    modelValue: _ctx.fields.new_password2,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.fields.new_password2) = $event)),
                    disabled: _ctx.passwordIsInvalid,
                    placeholder: _ctx.$t('authentication.signup.form.fields.confirmPassword'),
                    class: "el-input--password",
                    type: "password"
                  }, null, 8, ["modelValue", "disabled", "placeholder"])
                ]),
                _: 1
              }, 8, ["label"]),
              _createVNode(_component_ElFormItem, {
                "show-message": false,
                prop: "terms",
                required: ""
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ElCheckbox, {
                    modelValue: _ctx.fields.terms,
                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.fields.terms) = $event)),
                    class: "signup-modal__form--selector"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_i18n_t, {
                        class: "signup-modal__form--selector-text",
                        keypath: "authentication.signup.form.fields.terms",
                        tag: "p"
                      }, {
                        terms: _withCtx(() => [
                          _createElementVNode("a", {
                            href: _ctx.$options.static.termsOfUseUrl,
                            class: "text--nice-blue",
                            target: "_blank"
                          }, _toDisplayString(_ctx.$t("authentication.signup.form.fields.termsOfUse")), 9, _hoisted_3)
                        ]),
                        privacy: _withCtx(() => [
                          _createElementVNode("a", {
                            href: _ctx.$options.static.privacyPolicyUrl,
                            class: "text--nice-blue",
                            target: "_blank"
                          }, _toDisplayString(_ctx.$t("authentication.signup.form.fields.privacyPolicy")), 9, _hoisted_4)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 8, ["modelValue"])
                ]),
                _: 1
              }),
              _createElementVNode("div", _hoisted_5, [
                _createVNode(_component_PxButton, {
                  disabled: _ctx.isSubmissionDisabled,
                  loading: _ctx.isLoadingSubmission,
                  size: "medium",
                  type: "primary",
                  onClick: _ctx.submitForm
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t("authentication.signup.form.submitButton")), 1)
                  ]),
                  _: 1
                }, 8, ["disabled", "loading", "onClick"])
              ])
            ]),
            _: 1
          }, 8, ["model", "rules", "onValidate"]))
    ]),
    _: 1
  }, 8, ["modelValue", "title"]))
}