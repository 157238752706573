import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-4a1bd22d"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "initiative-details page-full-height" }
const _hoisted_2 = { class: "initiative-details__header" }
const _hoisted_3 = { class: "initiative-details__header-wrapper" }
const _hoisted_4 = { class: "initiative-details__header-title" }
const _hoisted_5 = {
  key: 0,
  class: "initiative-details__header-actions"
}
const _hoisted_6 = { class: "initiative-details__content" }
const _hoisted_7 = ["innerHTML"]
const _hoisted_8 = ["innerHTML"]
const _hoisted_9 = ["innerHTML"]
const _hoisted_10 = ["innerHTML"]
const _hoisted_11 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PxTabBar = _resolveComponent("PxTabBar")!
  const _component_PxNavbar = _resolveComponent("PxNavbar")!
  const _component_PxButton = _resolveComponent("PxButton")!
  const _component_PxFooter = _resolveComponent("PxFooter")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.$screen.smDown)
      ? (_openBlock(), _createBlock(_component_PxTabBar, { key: 0 }))
      : (_openBlock(), _createBlock(_component_PxNavbar, {
          key: 1,
          "show-account-dropdown": _ctx.$user.isLogged(),
          "show-login-button": false
        }, null, 8, ["show-account-dropdown"])),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_PxButton, _mergeProps({ class: "initiative-details__back-button" }, _ctx.$options.static.backButton, { onClick: _ctx.goToPreviousPage }), null, 16, ["onClick"]),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("h3", null, _toDisplayString(_ctx.$t(_ctx.initiativeCopy.title)), 1),
          _createElementVNode("p", null, _toDisplayString(_ctx.$t(_ctx.initiativeCopy.subtitle)), 1)
        ]),
        (_ctx.showAddTeamMembersCta)
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
              _createVNode(_component_PxButton, {
                size: "medium",
                type: "green",
                onClick: _ctx.goToPreviousPage
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t(_ctx.initiativeCopy.cta)), 1)
                ]),
                _: 1
              }, 8, ["onClick"]),
              _createElementVNode("p", null, _toDisplayString(_ctx.$t(_ctx.initiativeCopy.note)), 1)
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("p", null, _toDisplayString(_ctx.$t(_ctx.initiativeCopy.content.text[0])), 1),
      _createElementVNode("p", null, _toDisplayString(_ctx.$t(_ctx.initiativeCopy.content.text[1])), 1),
      _createElementVNode("p", null, _toDisplayString(_ctx.$t(_ctx.initiativeCopy.content.text[2])), 1),
      _createElementVNode("ul", null, [
        _createElementVNode("li", {
          innerHTML: _ctx.initiativeCopy.content.firstList[0]
        }, null, 8, _hoisted_7),
        _createElementVNode("li", {
          innerHTML: _ctx.initiativeCopy.content.firstList[1]
        }, null, 8, _hoisted_8)
      ]),
      _createElementVNode("p", {
        innerHTML: _ctx.initiativeCopy.content.text[3]
      }, null, 8, _hoisted_9),
      _createElementVNode("p", {
        innerHTML: _ctx.initiativeCopy.content.text[4]
      }, null, 8, _hoisted_10),
      _createElementVNode("p", {
        innerHTML: _ctx.initiativeCopy.content.text[5]
      }, null, 8, _hoisted_11)
    ]),
    (_ctx.$screen.mdUp)
      ? (_openBlock(), _createBlock(_component_PxFooter, { key: 2 }))
      : _createCommentVNode("", true)
  ]))
}