import { GetterTree } from "vuex";
import { RootState } from "@/services/store/root-state";
import {
  IUserMetadataState,
  EUserMetadataGetters,
} from "./user-metadata.types";
import { getBaseName } from "@/services/store/utils/get-base-name";
import { IUserMetadata } from "@/modules/authentication/services/data/user-metadata/user-metadata.types";

export const getters: GetterTree<IUserMetadataState, RootState> = {
  /**
   * Get a meta entry by key.
   *
   * If the key doesn't exists, return `null`
   *
   * @param state Module state
   */
  [getBaseName(EUserMetadataGetters.GET)](state) {
    return (key: string) =>
      state.data.find((entry: IUserMetadata) => entry.key === key);
  },
};
