<template>
  <ElTooltip
    :content="saveAsDraftTooltipCopy"
    :disabled="!showTooltip"
    data-testid="save-as-draft-button-tooltip"
    placement="top"
    popper-class="milestone-form-footer__tooltip el-abaca-tooltip"
  >
    <div>
      <PxButton
        v-bind="saveAsDraftButtonProps"
        :disabled="buttonDisabled"
        class="milestone-form-footer__save-as-draft-button"
        data-testid="save-as-draft-button"
        @click="$emit('click')"
      />
    </div>
  </ElTooltip>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import {
  EPxButtonSize,
  EPxButtonVariant,
  EPxButtonType,
} from "@/components/px-button/px-button.types";
import { TranslateResult } from "vue-i18n";

export default defineComponent({
  name: "MilestoneFormFooterSaveAsDraftButton",

  props: {
    showTooltip: {
      type: Boolean,
      default: false,
    },
    buttonDisabled: {
      type: Boolean,
      default: false,
    },
  },

  static: {
    saveAsDraftButton: {
      type: EPxButtonType.DEFAULT,
      size: EPxButtonSize.LARGE,
      variant: EPxButtonVariant.FLUID,
    },
  },

  computed: {
    saveAsDraftTooltipCopy(): TranslateResult {
      return this.$t("milestonePlanner.milestonePlan.tooltips.saveAsDraft");
    },

    saveAsDraftButtonProps(): any {
      return {
        ...this.$options.static.saveAsDraftButton,
        label: this.$t("milestonePlanner.milestonePlan.buttons.saveAsDraft"),
      };
    },
  },
});
</script>

<style lang="scss" scoped>
.milestone-form-footer__save-as-draft-button {
  position: relative;
  bottom: 1px;
  left: 1px;
  height: 36px;
}
</style>
