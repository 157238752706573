import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-6c032d14"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "support-modal-success" }
const _hoisted_2 = { class: "support-modal-success__title" }
const _hoisted_3 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PxIcon = _resolveComponent("PxIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_PxIcon, {
      size: 90,
      class: "support-modal-success__icon",
      name: "email-sent--orange"
    }),
    _createElementVNode("h3", _hoisted_2, _toDisplayString(_ctx.$t("common.components.supportModal.successMessage.title")), 1),
    _createElementVNode("p", {
      class: "support-modal-success__message",
      innerHTML: 
        _ctx.$t(`common.components.supportModal.successMessage.message`, {
          email: _ctx.email,
        })
      
    }, null, 8, _hoisted_3)
  ]))
}