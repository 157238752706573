import { affiliatesModule } from "@/modules/affiliates/services/store/affiliates/affiliates.module";
import { EAffiliatesState } from "@/modules/affiliates/services/store/affiliates/affiliates.types";

// Modules to register on the Vuex instance
export const modules = {
  [EAffiliatesState.NAMESPACE]: affiliatesModule,
};

// Modules to persist cross sessions
export const toPersist = [EAffiliatesState.NAMESPACE];
