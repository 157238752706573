/**
 * Data structure to hold a passcode for a list.
 */
export interface IListPasscodePayload {
  /**
   * List identifier.
   */
  listUid: string;

  /**
   * Passcode for the list.
   */
  passcode: string;
}

/**
 * Represents the store structure to store passcodes for the lists.
 */
export interface IListPasscodeState {
  passcodes: { [key: string]: string };
}

/**
 * Actions to manager list passcodes.
 */
export enum EListPasscodeActions {
  SET = "listPasscode/set",
  RESET = "listPasscode/reset",
}

/**
 * Mutations for list passcode store module.
 */
export enum EListPasscodeMutations {
  SET = "set",
  RESET = "reset",
}

/**
 * Getters for list passcode store module.
 */
export enum EListPasscodeGetters {
  GET = "listPasscode/get",
}
