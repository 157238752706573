<template>
  <div v-if="!isLoading" class="submission-panel__wrapper">
    <div class="submission-panel__matching-link-wrapper">
      <a
        v-if="showMatchingButton"
        class="submission-view__matching-link"
        @click="goToMatching"
      >
        {{ $t("program.submission.backToMatching") }}
      </a>
    </div>
    <PxPanel class="submission-panel__container px-panel--xs-no-side-borders">
      <div
        :class="{
          'submission-main__container--mobile': $screen.smDown,
          'submission-main__container--desktop': $screen.smUp,
        }"
        class="submission-main__container"
      >
        <div class="submission-affiliate__container">
          <div class="submission-affiliate__head-container">
            <img
              :height="30"
              :src="affiliate.logo"
              alt="Affiliate Logo"
              class="submission-affiliate__logo"
              @click="externalLinkClickHandler"
            />
            <span class="submission-affiliate__title">
              {{ affiliate.name }}
            </span>
          </div>
          <div class="submission-affiliate__date-wrapper">
            <ElSelect
              v-if="canShowSubmissionsList"
              v-model="submissionUid"
              :data-printable-value="selectedPrintableValue"
              class="submission-affiliate__date-dropdown"
            >
              <ElOption
                v-for="mapSubmission in mappedSubmissionList"
                :key="mapSubmission.uid"
                :label="mapSubmission.date"
                :value="mapSubmission.uid"
              />
            </ElSelect>
            <i18n-t
              v-else
              class="submission-affiliate__date-text"
              keypath="program.submission.submittedAt"
              tag="span"
            >
              <template #date>
                {{ submissionFormattedDate }}
              </template>
            </i18n-t>
          </div>
          <PxButton
            class="submission-affiliate__cta"
            v-bind="$options.static.printButton"
            @click.stop="onPrintButtonClick"
          />
        </div>
        <SubmissionCompanyPanel
          :company="profileCompany"
          :user-can-match="canMatch"
          :user-is-connected="isConnected"
          :user-is-interested="isInterested"
          @show-list-modal="showListModal"
        />
        <div class="submission-tab__nav-menu">
          <span
            v-for="(tab, index) in visiblePanelTabs"
            :key="index"
            :class="{
              'submission-tab__nav-item--active': isActiveTab(tab.key),
            }"
            class="submission-tab__nav-item"
            @click="changeTab(tab.key)"
          >
            {{ tab.label }}
          </span>
        </div>
      </div>
      <div class="submission-tab__container">
        <h2 class="submission-tab__print-title">
          {{ $t("program.submission.tabs.questions") }}
        </h2>
        <SubmissionResponsesPanel
          v-if="responsesWithoutProfileFields.length"
          v-show="isActiveTab(ESubmissionPanelTab.QUESTIONS)"
          :responses="responsesWithoutProfileFields"
          class="submission-tab__item"
        />
        <InvestmentLevelPanel
          v-if="profileCompany"
          v-show="isActiveTab(ESubmissionPanelTab.VENTURE_INVESTMENT_LEVEL)"
          :assessment="assessment"
          :company="profileCompany"
          class="submission-tab__item"
        />
        <SubmissionTabTeamMembers
          v-if="submission.team_members && submission.team_members.length"
          v-show="isActiveTab(ESubmissionPanelTab.TEAM_MEMBERS)"
          :team-members="submission.team_members"
        />
      </div>
    </PxPanel>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { activeModules } from "@/services/utils/utils";

import SubmissionCompanyPanel from "@/modules/program/components/submission-company-panel/submission-company-panel.vue";
import InvestmentLevelPanel from "@/modules/program/components/investment-level-panel/investment-level-panel.vue";
import SubmissionResponsesPanel from "@/modules/program/components/submission-responses-panel/submission-responses-panel.vue";

import { ICompany } from "@/modules/profile/services/data/company/company.types";
import { IMatchingInterest } from "@/modules/matching/services/data/matching-interest/matching-interest.interface";
import {
  MATCHING_CONNECTED_INTEREST,
  MATCHING_HAS_INTEREST,
  MATCHING_INITIAL_INTEREST,
  MATCHING_USER_TYPES,
} from "@/modules/matching/constants";
import { EMatchingInterestActions } from "@/modules/matching/services/store/matching-interest/matching-interest.types";
import {
  IAffiliateProgramSubmission,
  IAffiliateListProgramSubmission,
} from "@/modules/program/services/data/affiliate-program-submission/affiliate-program-submission";
import { IAffiliate } from "@/services/data/affiliate/affiliate.interface";
import { IAssessment } from "@/services/data/assessment/assessment.interface";
import { IMatchingResponse } from "@/services/data/matching-responses/matching-response.interface";
import moment from "moment";
import { ROUTE_MATCHING_LIST } from "@/modules/matching/services/router/routes-names";

import { EPxButtonVariant } from "@/components/px-button/px-button.types";
import SubmissionTabTeamMembers from "@/modules/supporters/components/submission/submission-tab-team-members.vue";

enum ESubmissionPanelTab {
  QUESTIONS = 1,
  VENTURE_INVESTMENT_LEVEL = 2,
  TEAM_MEMBERS = 3,
}

interface ISubmissionPanelTab {
  key: number;
  label: string;
  visible: boolean;
}

export default defineComponent({
  components: {
    SubmissionCompanyPanel,
    InvestmentLevelPanel,
    SubmissionResponsesPanel,
    SubmissionTabTeamMembers,
  },

  props: {
    submission: {
      type: Object as () => IAffiliateProgramSubmission,
      required: true,
    },
    submissionList: {
      type: Array as () => IAffiliateListProgramSubmission[],
      required: true,
    },
  },

  static: {
    printButton: {
      variant: EPxButtonVariant.COMPACT,
      icon: "print",
    },
  },

  data() {
    return {
      selectedTab: null as null | number,
      ESubmissionPanelTab,
      submissionUid: "",
    };
  },

  computed: {
    isLoading(): boolean {
      return (
        !this.profileCompany || this.$store.get("matchingInterest/loading")
      );
    },

    submissionFormattedDate(): string {
      const date = this.submission
        ? moment(this.submission.assessment.created_at)
        : moment();
      return date.format("MMMM D, YYYY - HH:mmA");
    },

    ventureInvestmentLevelLabel(): string {
      return (
        this.$screen.mdUp
          ? this.$t("program.submission.tabs.ventureInvestLevel")
          : this.$t("program.submission.tabs.ventureInvestLevelMobile")
      ) as string;
    },

    affiliate(): IAffiliate {
      return this.submission.affiliate;
    },

    assessment(): IAssessment {
      return this.submission.assessment;
    },

    responses(): Array<IMatchingResponse> {
      return this.submission.responses;
    },

    /**
     * Exclude responses that override profile fields
     * and which may appear duplicated on the submission (about, website, email)
     *
     * Questions excluded are the ones:
     * - that do not explicitly have a `profile_field` associated or;
     * - that do not have a value or;
     * - whose value does not match any of the company properties.
     */
    responsesWithoutProfileFields(): Array<IMatchingResponse> {
      if (!this.profileCompany) {
        return this.responses;
      }

      const profileFieldsValues = [
        this.profileCompany.about,
        this.profileCompany.website,
        this.profileCompany.email,
      ];

      if (this.responses && this.responses.length) {
        return this.responses.filter((response) => {
          const questionOverridesProfileField =
            typeof response.question === "object" &&
            !!response.question?.profile_field;

          const responseValue = response.value
            ? Object.values(response.value)
            : null;

          return (
            !questionOverridesProfileField ||
            !responseValue ||
            !profileFieldsValues.some((fieldValue) =>
              responseValue.includes(fieldValue),
            )
          );
        });
      }

      return [];
    },

    profileCompany(): ICompany | null {
      return this.$store.state.profile.company.data;
    },

    profileCompanyId(): number | null {
      return this.profileCompany ? this.profileCompany.id : null;
    },

    /**
     * Get auth user interests
     */
    authCompany(): ICompany | null {
      return this.$store.state.auth.company.data;
    },

    authCompanyId(): number | null {
      return this.authCompany ? this.authCompany.id : null;
    },

    /**
     * Get auth user interests
     */
    matchingInterests(): Array<IMatchingInterest> {
      return this.$store.state.matchingInterest.data;
    },

    /**
     * Check if store value is set and that it has values
     */
    hasMatchingInterests(): boolean {
      return this.matchingInterests && !!this.matchingInterests.length;
    },

    /**
     * Get auth user type
     */
    userType(): string {
      return MATCHING_USER_TYPES[this.$user.getUserAccountType()];
    },

    /**
     * Get target interest user type
     */
    targetType(): string {
      const oppositeUserType = +!this.$user.getUserAccountType();
      return MATCHING_USER_TYPES[oppositeUserType];
    },

    /**
     * Return current interest state if available
     */
    currentMatchingInterest(): IMatchingInterest | null {
      if (this.hasMatchingInterests) {
        return (
          this.matchingInterests.find(
            (interest: IMatchingInterest) =>
              interest[this.userType] === this.authCompanyId &&
              interest[this.targetType] === this.profileCompanyId,
          ) || null
        );
      }

      return null;
    },

    /**
     * Return final interest between both parties
     */
    interestState(): number {
      return this.currentMatchingInterest
        ? this.currentMatchingInterest.state_of_interest
        : MATCHING_INITIAL_INTEREST;
    },

    isConnected(): boolean {
      return this.interestState === MATCHING_CONNECTED_INTEREST;
    },

    isInterested(): boolean {
      return this.interestState === MATCHING_HAS_INTEREST;
    },

    canMatch(): boolean {
      return (
        (this.$store.state.matchingInterest.canMatch &&
          this.$user.isLogged()) ||
        false
      );
    },

    mappedSubmissionList(): any {
      return this.submissionList.map(
        (submission: IAffiliateListProgramSubmission, index) => {
          const current =
            index === 0
              ? `(${this.$t("profile.assessment.historyPanel.current")}) `
              : "";

          return {
            uid: submission.uid,
            date: `${current} ${this.formattedDate(submission.created_at)}`,
            printableValue: this.$t("program.submission.submittedAt", {
              date: moment(submission.created_at).format(
                "MMMM D, YYYY - HH:mmA",
              ),
            }),
          };
        },
      );
    },

    selectedPrintableValue(): string {
      return (
        this.mappedSubmissionList.find(
          (submission: any) => submission.uid === this.submissionUid,
        ).printableValue || ""
      );
    },

    canShowSubmissionsList(): boolean {
      return this.$user.isLogged() && !!this.submissionList.length;
    },

    panelTabs(): Array<ISubmissionPanelTab> {
      return [
        {
          label: this.$t("program.submission.tabs.questions"),
          key: ESubmissionPanelTab.QUESTIONS,
          visible:
            !!this.responsesWithoutProfileFields &&
            !!this.responsesWithoutProfileFields.length,
        },
        {
          label: this.$t(
            "program.submission.tabs.ventureInvestLevel",
          ) as string,
          key: ESubmissionPanelTab.VENTURE_INVESTMENT_LEVEL,
          visible: !!this.submission.assessment,
        },
        {
          label: this.$t("program.submission.tabs.teamMembers"),
          key: ESubmissionPanelTab.TEAM_MEMBERS,
          visible:
            !!this.submission.team_members &&
            !!this.submission.team_members.length,
        },
      ];
    },

    visiblePanelTabs(): Array<ISubmissionPanelTab> {
      return this.panelTabs.filter((tab: ISubmissionPanelTab) => tab.visible);
    },

    showMatchingButton(): boolean {
      return (
        this.$user.isLogged() &&
        this.$features.isEnabled("match") &&
        activeModules().includes("matching")
      );
    },
  },

  watch: {
    submissionUid: {
      handler(newSubmission: string) {
        if (!this.submission || newSubmission === this.submission.uid) {
          return;
        }

        this.$emit("on-submission-change", newSubmission);
      },
    },
  },

  created() {
    // If  store not filled, fetch interests
    if (!this.hasMatchingInterests) {
      this.fetchAuthUserInterests();
    }

    if (this.submission) {
      this.submissionUid = this.submission.uid;

      this.selectedTab = this.visiblePanelTabs[0].key;
    }
  },

  methods: {
    goToMatching() {
      this.$router.push({ name: ROUTE_MATCHING_LIST });
    },

    fetchAuthUserInterests() {
      if (this.$user.isLogged()) {
        this.$store.dispatch(EMatchingInterestActions.FETCH);
      }
    },

    externalLinkClickHandler() {
      if (this.affiliate && this.affiliate.website) {
        window.open(this.affiliate.website);
      }
    },

    isActiveTab(tab: ESubmissionPanelTab) {
      return this.selectedTab === tab;
    },

    changeTab(selectedTab: ESubmissionPanelTab) {
      this.selectedTab = selectedTab;
    },

    formattedDate(date: string) {
      return moment(date).format("MMMM D, YYYY - HH:mmA");
    },

    showListModal() {
      this.$emit("show-list-modal");
    },

    onPrintButtonClick() {
      window.print();
    },
  },
});
</script>

<style lang="scss" scoped>
.submission-panel__matching-link-wrapper {
  @include grotesk(semiBold);

  padding: 18px 16px;
  color: $manatee;
  text-decoration: underline;
  cursor: pointer;
  transition: color 0.25s ease-in-out;

  @include breakpoint-up(md) {
    padding: 40px 16px 18px;
  }

  &:hover {
    color: $ebony-clay;
  }
}

.submission-panel__container,
.submission-tab__container {
  width: 100vw;
  margin: 0;
  overflow: hidden;

  @include breakpoint-up(lg) {
    width: 938px;
  }
}

.submission-panel__container {
  @include breakpoint-up(lg) {
    border-radius: 4px;
  }
}

.submission-tab__container {
  background: $white;
  border-top: 1px solid $mischka;

  @include breakpoint-up(lg) {
    border-radius: 0 0 4px 4px;
  }
}

.submission-panel__container :deep() .px-panel__body {
  padding: 0;
}

.submission-main__container {
  background-image: linear-gradient(to bottom, #7bb7ff00, #7bb7ff0a);
}

.submission-affiliate__container {
  display: grid;
  grid-template-columns: auto;
  background-color: $white;
  border-bottom: solid 1px $pale-grey;
  box-shadow: 0 4px 11px 0 rgba(0, 0, 0, 0.06);

  @include breakpoint-up(md) {
    grid-template-columns: auto 1fr;
    grid-column-gap: 16px;
    overflow: hidden;
    background-color: $pale-grey-two;
    border-bottom: solid 1px $pale-grey;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }
}

.submission-affiliate__head-container {
  display: grid;
  grid-template-columns: auto 1fr;
  padding: 12px 10px;

  @include breakpoint-up(md) {
    position: relative;
    padding: 18px 100px 15px 40px;
    margin-bottom: -1px;
    background: $white;
    border: solid 1px $pale-grey;
    border-top: none;
    border-left: none;
    border-top-left-radius: 4px;
    border-bottom-right-radius: 150px 100px;
    box-shadow: 0 4px 11px 0 rgba(0, 0, 0, 0.06);
  }
}

.submission-affiliate__logo {
  padding-right: 20px;
  margin-right: 20px;
  cursor: pointer;
  border-right: 1px solid $athens-gray;
}

.submission-affiliate__title {
  @include grotesk(medium);

  font-size: 18px;
  color: $manatee;
}

.submission-affiliate__date-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px 15px;

  @include breakpoint-up(md) {
    align-items: flex-start;
    justify-content: flex-end;
    padding: 12px 80px 12px 0;
  }
}

.submission-affiliate__date-dropdown {
  min-width: 100%;

  @include breakpoint-up(md) {
    min-width: 310px;
    width: auto;
  }
}

.submission-affiliate__date-text {
  margin-top: 8px;
  margin-right: 1px;
  font-size: 13px;
  font-style: italic;
  color: $manatee;
}

.submission-tab__print-title {
  display: none;
}

.submission-affiliate__cta {
  position: absolute;
  top: 11px;
  right: 16px;
  min-width: 40px;
  min-height: 40px;
}

.submission-tab__nav-menu {
  padding: 0 24px;

  @include breakpoint-up(md) {
    padding: 0 40px;
  }
}

.submission-tab__nav-item {
  @include grotesk(bold);

  position: relative;
  display: inline-block;
  padding: 8px 0;
  margin-right: 26px;
  font-size: 18px;
  color: $gun-powder;
  cursor: pointer;
}

.submission-tab__nav-item--active {
  color: $bluish;

  &::after {
    position: absolute;
    right: -6px;
    bottom: 0;
    left: -6px;
    height: 3px;
    content: "";
    background-color: $bluish;
    border-radius: 2px 2px 0 0;
  }
}
</style>
