import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, vShow as _vShow, withCtx as _withCtx, createVNode as _createVNode, withDirectives as _withDirectives, Transition as _Transition, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-3d41ac00"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "page-full-height" }
const _hoisted_2 = { class: "page-body" }
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = {
  key: 0,
  id: "supporters-dashboard",
  class: "page-container"
}
const _hoisted_5 = {
  key: 1,
  id: "entrepreneurs-dashboard",
  class: "page-container"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PxCallout = _resolveComponent("PxCallout")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_Transition, {
        mode: "out-in",
        name: "slide-fade"
      }, {
        default: _withCtx(() => [
          _withDirectives(_createVNode(_component_PxCallout, {
            ref: "capital-explorer-callout",
            ctas: _ctx.ctas,
            description: _ctx.copy.description,
            label: _ctx.label,
            title: _ctx.copy.title,
            class: "capital-explorer-callout",
            onClick: _ctx.handleCalloutClick
          }, {
            note: _withCtx(() => [
              _createElementVNode("p", {
                innerHTML: _ctx.copy.note
              }, null, 8, _hoisted_3)
            ]),
            _: 1
          }, 8, ["ctas", "description", "label", "title", "onClick"]), [
            [_vShow, _ctx.showCallout]
          ])
        ]),
        _: 1
      }),
      (_ctx.$user.isSupporter())
        ? (_openBlock(), _createElementBlock("div", _hoisted_4))
        : (_openBlock(), _createElementBlock("div", _hoisted_5))
    ])
  ]))
}