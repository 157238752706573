<template>
  <div class="affiliate-review-page-questions">
    <header v-if="$screen.xlUp">
      <h3 class="affiliate-review-page-questions__title">
        {{ $t("affiliates.reviewPage.navigation.questionsTab") }}
      </h3>
      <div
        v-if="notAnswered.length"
        class="affiliate-review-page-questions__missing-info-label"
        data-testid="affiliate-review-page-questions-missing-info-label"
      >
        <PxIcon :size="16" name="warning--white" />
        <p v-html="missingQuestionsCopy" />
      </div>
    </header>
    <div class="affiliate-review-page-questions__wrapper">
      <template v-if="notAnswered.length">
        <div class="affiliate-review-page-questions__info-divider">
          <span>
            {{ $t("affiliates.reviewPage.content.notAnswered") }}
          </span>
        </div>
        <template v-for="answer in notAnswered" :key="answer.question">
          <PxInformationCard
            v-bind="notAnsweredCardStyles"
            :description="$t('common.notAnswered')"
            :title="
              getQuestion(answer.question as number)?.entrepreneur_question
            "
            class="affiliate-review-page-questions__info-card"
            data-testid="affiliate-review-page-questions-not-answered-card"
            @click="navigateToQuestion(answer.question as number)"
          />
        </template>
      </template>
      <template v-if="answered.length">
        <div class="affiliate-review-page-questions__info-divider">
          <span>
            {{ $t("affiliates.reviewPage.content.answered") }}
          </span>
        </div>
        <template v-for="answer in answered" :key="answer.question">
          <PxInformationCard
            v-bind="answeredCardStyles"
            :description="getAnswer(answer)"
            :title="
              getQuestion(answer.question as number)?.entrepreneur_question
            "
            class="affiliate-review-page-questions__info-card"
            data-testid="affiliate-review-page-questions-answered-card"
            @click="navigateToQuestion(answer.question as number)"
          />
        </template>
      </template>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import FormatMatchingResponseValuesMixin from "@/mixins/format-matching-response-values.mixin";

import PxInformationCard from "@/components/px-information-card/px-information-card.vue";

import { IMatchingResponse } from "@/services/data/matching-responses/matching-response.interface";
import {
  IAffiliatesState,
  EAffiliatesActions,
} from "@/modules/affiliates/services/store/affiliates/affiliates.types";

import {
  DATE,
  FREE_RESPONSE,
  IMatchingQuestion,
  MULTI_SELECT,
  NUMERIC,
  RANGE,
  SINGLE_SELECT,
} from "@/services/data/matching-questionary/matching-question.interface";

export default defineComponent({
  name: "AffiliateReviewPageQuestions",

  components: {
    PxInformationCard,
  },

  mixins: [FormatMatchingResponseValuesMixin],

  data() {
    return {
      responseTypes: {
        freeResponse: FREE_RESPONSE,
        singleSelect: SINGLE_SELECT,
        multiSelect: MULTI_SELECT,
        numeric: NUMERIC,
        range: RANGE,
        dateResponse: DATE,
      } as any | null,
    };
  },

  computed: {
    affiliatesData(): IAffiliatesState {
      return this.$store.state.affiliates;
    },

    responses(): Array<IMatchingResponse> {
      return this.affiliatesData.draftSubmission
        ? this.affiliatesData.draftSubmission.data.responses
        : [];
    },

    questions(): Array<IMatchingQuestion> {
      return this.affiliatesData.affiliateQuestionBundle || [];
    },

    notAnswered(): IMatchingResponse[] {
      return this.responses.filter((response) => !response.isValid);
    },

    answered(): IMatchingResponse[] {
      return this.responses.filter((response) => response.isValid);
    },

    missingQuestionsCopy(): any {
      return this.$tc(
        "affiliates.reviewPage.content.missingQuestions",
        this.notAnswered.length,
      );
    },

    notAnsweredCardStyles(): any {
      return {
        label: {
          text: this.$t("common.required"),
          icon: "warning--blue",
          iconSize: 16,
          textColor: "#7bb7ff",
          backgroundColor: "rgba(123, 183, 255, 0.16)",
        },
        cta: {
          label: this.$t("common.answer"),
          type: "default",
          size: "small",
        },
        backgroundColor: "rgba(123, 183, 255, 0.05)",
        border: "1px solid rgba(123,183, 255, 0.8)",
        descriptionStyles: "italic",
      };
    },

    answeredCardStyles(): any {
      return {
        label: {
          text: this.$t("common.required"),
          icon: "milestone-check",
          iconSize: 16,
          textColor: "#53af72",
          backgroundColor: "rgba(83, 175, 114, 0.12)",
        },
        cta: {
          label: this.$t("common.update"),
          type: "default",
          size: "small",
        },
        backgroundColor: "#fafafa",
        border: "1px solid rgba(220, 221, 226, 0.8)",
        descriptionStyles: "default",
      };
    },
  },

  methods: {
    getQuestion(questionId: number): IMatchingQuestion | null {
      return (
        this.questions.find((question) => question.id === questionId) || null
      );
    },

    getAnswer(response: IMatchingResponse): string {
      let formattedValue = "";

      const question = this.getQuestion(response.question as number);

      if (question) {
        if (
          (this.getQuestionType(question, this.responseTypes.numeric) &&
            this.$user.isEntrepreneur()) ||
          (this.getQuestionType(question, this.responseTypes.range) &&
            this.$user.isSupporter())
        ) {
          formattedValue = this.formatNumericValue(response);
        } else if (
          (this.getQuestionType(question, this.responseTypes.numeric) &&
            this.$user.isSupporter()) ||
          (this.getQuestionType(question, this.responseTypes.range) &&
            this.$user.isEntrepreneur())
        ) {
          formattedValue = this.formatRangeValue(response);
        } else if (
          this.getQuestionType(question, this.responseTypes.freeResponse)
        ) {
          formattedValue = this.formatFreeResponseValue(response);
        } else if (
          this.getQuestionType(question, this.responseTypes.dateResponse)
        ) {
          formattedValue = this.formatDateValue(response);
        } else if (
          this.getQuestionType(question, this.responseTypes.singleSelect) ||
          this.getQuestionType(question, this.responseTypes.multiSelect)
        ) {
          formattedValue =
            response?.answers && response?.answers.length
              ? this.formatMultipleAnswersValue(question, response)
              : "";
        }
      }

      return formattedValue;
    },

    getQuestionType(question: IMatchingQuestion, type: string): boolean {
      return question.question_type && question.question_type.type === type;
    },

    navigateToQuestion(id: number) {
      const questionIndex = this.questions.findIndex(
        (question) => question.id === id,
      );

      this.$store.dispatch(
        EAffiliatesActions.SET_USER_NAVIGATING_FROM_REVIEW_PAGE,
        true,
      );

      this.$emit("go-to-question", questionIndex);
    },
  },
});
</script>

<style lang="scss" scoped>
.affiliate-review-page-questions {
  padding: 0 0 22px 0;

  header {
    padding: 22px 0 0 23px;
  }

  &__title {
    margin: 0;
    padding: 0 0 17px 0;

    @include grotesk(bold);

    font-size: 20px;
    line-height: 1.6;
    color: $ebony-clay;
    letter-spacing: -0.2px;
  }

  &__missing-info-label {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 7px;
    width: max-content;
    padding: 4px 15px 4px 14px;
    border-radius: 20px;
    background-color: $malibu;

    p {
      margin: 0;
      font-size: 13px;
      color: $white;
      letter-spacing: -0.2px;
    }
  }

  &__info-divider {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 6px 0 9px 0;
    border-top: 1px solid $mischka;

    span {
      @include grotesk(semiBold);

      position: relative;
      top: -8px;
      padding: 0 6px;
      font-size: 13px;
      line-height: 1.23;
      letter-spacing: -0.2px;
      background-color: $white;
      color: $manatee;
      text-transform: uppercase;
    }
  }

  &__info-divider:not(:first-of-type) {
    margin-top: 23px;
  }

  &__wrapper {
    overflow-y: scroll;
    margin: 0 10px 0 23px;
    padding: 0 10px 0 0;

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
      margin: 30px;
    }

    &::-webkit-scrollbar-thumb {
      background: $mischka;
      border-radius: 10px;
    }
  }

  &__info-card {
    width: 100%;

    &:not(:last-of-type) {
      margin-bottom: 16px;
    }
  }
}
</style>
