<template>
  <ElBadge v-bind="$props" />
</template>

<script lang="ts">
import { defineComponent } from "vue";

/**
 * Badge used to identify the selected level on the
 * results details.
 */
export default defineComponent({
  name: "PxBadge",
  props: {
    /**
     * Number to render inside the badge.
     */
    value: {
      required: true,
      type: [String, Number],
    },
    /**
     * Custom color.
     */
    color: {
      type: String as () => string | null,
      default: null,
    },
    max: {
      type: Number as () => number | null,
      default: null,
    },
    isDot: {
      type: Boolean,
      default: false,
    },
    hidden: {
      type: Boolean,
      default: false,
    },
  },

  watch: {
    color() {
      if (this.$el) {
        this.setBackground();
      }
    },
  },

  mounted() {
    this.setBackground();
  },

  methods: {
    setBackground() {
      const contentEl = this.$el.querySelector(
        ".el-badge__content",
      ) as HTMLElement;
      contentEl.style.backgroundColor = this.color || "";
    },
  },
});
</script>

<style></style>
