import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toHandlers as _toHandlers, mergeProps as _mergeProps, createElementBlock as _createElementBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-a86231be"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "px-card__content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PxIcon = _resolveComponent("PxIcon")!
  const _directive_loading = _resolveDirective("loading")!

  return _withDirectives((_openBlock(), _createElementBlock("div", {
    style: _normalizeStyle(_ctx.cardStyles),
    class: "px-card",
    "element-loading-background": "#fafafa"
  }, [
    (_ctx.icon)
      ? (_openBlock(), _createBlock(_component_PxIcon, {
          key: 0,
          name: _ctx.icon,
          size: _ctx.iconSize
        }, null, 8, ["name", "size"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _mergeProps({
        class: [{ 'px-card__title-action': _ctx.action }, "px-card__title"]
      }, _toHandlers(_ctx.cardAction, true)), [
        _createElementVNode("h1", {
          style: _normalizeStyle({ color: _ctx.titleColor })
        }, _toDisplayString(_ctx.title), 5),
        (_ctx.action)
          ? (_openBlock(), _createBlock(_component_PxIcon, {
              key: 0,
              name: _ctx.actionIcon,
              size: 16
            }, null, 8, ["name"]))
          : _createCommentVNode("", true)
      ], 16),
      (_ctx.subtitle)
        ? (_openBlock(), _createElementBlock("p", {
            key: 0,
            style: _normalizeStyle({ color: _ctx.subtitleColor })
          }, _toDisplayString(_ctx.subtitle), 5))
        : _createCommentVNode("", true)
    ])
  ], 4)), [
    [_directive_loading, _ctx.isLoading]
  ])
}