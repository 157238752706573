import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-1a5cb1ca"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "page-container" }
const _hoisted_2 = { class: "team-management__wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PxButton = _resolveComponent("PxButton")!
  const _component_TeamMembersList = _resolveComponent("TeamMembersList")!
  const _component_TeamMemberSettings = _resolveComponent("TeamMemberSettings")!
  const _component_DiscardModal = _resolveComponent("DiscardModal")!
  const _component_InitiativeDialog = _resolveComponent("InitiativeDialog")!
  const _component_NavigationFooter = _resolveComponent("NavigationFooter")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([{
      'team-management--supporters-affiliate': _ctx.isAffiliateView && _ctx.isSupporter,
    }, "team-management page-body"])
  }, [
    _createElementVNode("div", _hoisted_1, [
      (!_ctx.isAffiliateView)
        ? (_openBlock(), _createBlock(_component_PxButton, _mergeProps({ key: 0 }, _ctx.backToProfileButtonProps, {
            class: "team-management__back-button",
            onClick: _ctx.goToProfilePage
          }), null, 16, ["onClick"]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_TeamMembersList, {
          scroll: _ctx.scroll,
          "onUpdate:scroll": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.scroll) = $event)),
          "form-invalid": _ctx.formIsInvalid,
          "is-loading": _ctx.teamMembersLoading,
          "members-list": _ctx.members,
          "selected-member-index": _ctx.selectedTeamMemberIndex,
          class: "team-management__members-list",
          onAddMember: _ctx.addNewTeamMember,
          onChangeSelectedMember: _ctx.updateSelectedMember,
          onRemoveMember: _ctx.showRemoveModal
        }, null, 8, ["scroll", "form-invalid", "is-loading", "members-list", "selected-member-index", "onAddMember", "onChangeSelectedMember", "onRemoveMember"]),
        (_openBlock(), _createBlock(_component_TeamMemberSettings, {
          key: _ctx.selectedTeamMemberIndex,
          "has-changes": _ctx.membersHaveChanges,
          "is-loading": _ctx.teamMembersLoading,
          "member-data": _ctx.selectedTeamMember,
          "member-index": _ctx.selectedTeamMemberIndex,
          questions: _ctx.teamMemberQuestions,
          onFormIsInvalid: _ctx.checkFormInvalid,
          onSaveMembers: _ctx.saveTeamMembers,
          onUpdateMember: _ctx.updateMemberInfo
        }, null, 8, ["has-changes", "is-loading", "member-data", "member-index", "questions", "onFormIsInvalid", "onSaveMembers", "onUpdateMember"])),
        (_ctx.isDiscardModalVisible)
          ? (_openBlock(), _createBlock(_component_DiscardModal, _mergeProps({ key: 0 }, _ctx.removeModalProps, {
              visibility: _ctx.isDiscardModalVisible,
              "onUpdate:visibility": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.isDiscardModalVisible) = $event)),
              onDiscardAction: _ctx.removeTeamMember
            }), null, 16, ["visibility", "onDiscardAction"]))
          : _createCommentVNode("", true),
        (_ctx.isAffiliateView && _ctx.initiativeDialogVisible)
          ? (_openBlock(), _createBlock(_component_InitiativeDialog, {
              key: 1,
              visibility: _ctx.initiativeDialogVisible,
              "onUpdate:visibility": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.initiativeDialogVisible) = $event))
            }, null, 8, ["visibility"]))
          : _createCommentVNode("", true)
      ]),
      (_ctx.isAffiliateView && _ctx.isSupporter)
        ? (_openBlock(), _createBlock(_component_NavigationFooter, {
            key: 1,
            class: "team-management__navigation-footer"
          }, {
            center: _withCtx(() => [
              _createVNode(_component_PxButton, {
                disabled: _ctx.hasNoMember || _ctx.formIsInvalid,
                loading: _ctx.teamMembersLoading,
                type: "primary",
                onClick: _ctx.saveSupporterAffiliate
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t("teamManagement.footer.nextCategory")), 1)
                ]),
                _: 1
              }, 8, ["disabled", "loading", "onClick"])
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ])
  ], 2))
}