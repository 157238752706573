import { GetterTree } from "vuex";
import {
  IMatchingQuestionaryState,
  EMatchingQuestionaryGetters,
} from "./matching-questionary.types";
import { RootState } from "@/services/store/root-state";
import { getBaseName } from "@/services/store/utils/get-base-name";

export const matchingQuestionaryGetters: GetterTree<
  IMatchingQuestionaryState,
  RootState
> = {
  /**
   * Get current question.
   *
   * If there are no more questions, returns `null`
   *
   * @param state Module state
   */
  [getBaseName(EMatchingQuestionaryGetters.QUESTION)](state) {
    return state.data[state.currentQuestionIndex] || null;
  },

  /**
   * Get current question index.
   *
   * @param state Module state
   */
  [getBaseName(EMatchingQuestionaryGetters.QUESTION_INDEX)](state) {
    return state.currentQuestionIndex;
  },

  /**
   * Get total questions count.
   *
   * @param state Module state
   */
  [getBaseName(EMatchingQuestionaryGetters.TOTAL_QUESTIONS)](state) {
    return state.totalQuestions;
  },
};
