<template>
  <div class="validating-panel">
    <PxPanel v-if="!isLoading" class="px-panel--mobile-no-side-borders">
      <div v-if="!hasError" class="validating-panel__container">
        <h2 class="validating-panel__print-title">
          {{ $t("profile.assessment.levelPanel.title") }}
        </h2>
        <PxButton
          class="validating-panel__cta"
          v-bind="$options.static.printButton"
          @click.stop="onPrintButtonClick"
        />
        <div class="validating-panel__score-wrapper">
          <PxIcon
            :name="`level-graph/${finalLevel.value}`"
            :size="173"
            class="validating-panel__level"
          />
        </div>
        <h2 class="validating-panel__title">
          {{ finalLevel.title }}
          <span class="validating-panel__tag-wrapper">
            <ElTag effect="plain" size="default" data-tag-type="grey">
              {{ $t("common.levelTag", { level: finalLevel.value }) }}
            </ElTag>
          </span>
        </h2>
        <div
          v-markdown="finalLevel.description"
          class="validating-panel__description"
        />
      </div>
      <PxGlobalError
        v-if="hasError"
        :text="$t('common.errors.wrongLevel')"
        class="validating-panel-error"
      />
    </PxPanel>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import { IViralLevelState } from "@/services/store/viral-level/viral-level-types";
import { ILevel } from "@/services/data/level/level.interface";

import { EPxButtonVariant } from "@/components/px-button/px-button.types";

export default defineComponent({
  name: "ValidatingPanel",

  static: {
    printButton: {
      variant: EPxButtonVariant.COMPACT,
      icon: "print",
    },
  },

  data() {
    return {
      modalVisibility: false,
    };
  },

  computed: {
    viralStore(): IViralLevelState {
      return this.$store.state.viralLevel;
    },

    isLoading(): boolean {
      return this.viralStore.loading;
    },

    hasError(): boolean {
      return !!this.viralStore.error;
    },

    finalLevel(): ILevel {
      return this.viralStore.finalLevel as ILevel;
    },
  },

  methods: {
    onPrintButtonClick() {
      window.print();
    },
  },
});
</script>

<style lang="scss" scoped>
.validating-panel > .px-panel {
  width: 100%;
  max-width: unset;
}

.validating-panel > .px-panel :deep() .px-panel__body {
  padding: 2px 15px 17px;

  @include breakpoint-up(md) {
    padding: 25px 110px 11px 116px;
  }

  &:last-child {
    @include breakpoint-up(md) {
      padding-bottom: 35px;
    }
  }
}

.validating-panel__backLink {
  margin: 0 0 6px 16px;

  &--out {
    @include breakpoint-down(md) {
      display: none;
    }
  }

  &--in {
    position: relative;
    top: -4px;
    margin: -10px 0 1px 0;

    @include breakpoint-up(md) {
      display: none;
    }
  }
}

.validating-panel__color-bar {
  margin: 0 -15px 21px;
}

.validating-panel__container {
  display: grid;
  grid-template-areas:
    "level title"
    "description description";
  grid-template-columns: 140px auto;
  grid-column-gap: 17px;

  @include breakpoint-up(sm) {
    grid-template-areas:
      "level title"
      "level description";
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 173px auto;
    grid-column-gap: 25px;
  }

  @include breakpoint-up(lg) {
    grid-template-rows: min-content 1fr;
  }
}

.validating-panel__score-wrapper {
  grid-area: level;
  max-height: 146px;

  @include breakpoint-up(md) {
    max-height: unset;
  }
}

.validating-panel__title {
  grid-area: title;

  font-size: 1.2667rem;
  line-height: 26px;

  @include breakpoint-down(sm) {
    margin-top: 14px;
  }

  @include breakpoint-up(md) {
    margin-top: 15px;

    font-size: 1.6rem;
    line-height: 1.67;
  }

  .el-tag {
    margin: 7px 0 0 -1px;

    vertical-align: top;

    @include breakpoint-up(md) {
      margin: 7px 0 0 10px;
    }
  }
}

.validating-panel__tag-wrapper {
  @include breakpoint-down(sm) {
    display: block;
  }
}

.validating-panel__description {
  grid-area: description;

  p {
    font-size: 1rem;
    line-height: 25px;

    @include breakpoint-up(md) {
      padding-bottom: 32px;

      font-size: 1.1333rem;
      line-height: 1.88;
      letter-spacing: -0.2px;
    }
  }
}

.validating-panel__level {
  max-width: 140px;

  margin-right: 16px;

  @include breakpoint-down(sm) {
    position: relative;
    top: -24px;
    right: 1px;
  }

  @include breakpoint-up(md) {
    max-width: unset;
    margin-right: 25px;
  }
}

.validating-panel__print-title {
  display: none;
}

.validating-panel__cta {
  position: absolute;
  top: 19px;
  right: 19px;
  min-width: 40px;
  min-height: 40px;
}

.validating-panel-error {
  margin-top: -4px;
}

.validating-panel-error :deep() .px-global-error__notice {
  padding-top: 1px;
  margin-bottom: 6px;

  font-size: 0.9333rem;
}

.validating-panel-error :deep() .px-global-error__icon {
  margin-bottom: 2px;
}

.validating-panel-footer {
  display: flex;
  justify-content: center;
  width: 100%;

  & > .el-button:first-child {
    margin-right: 22px;
  }

  .validating-panel-footer__submit {
    min-width: 164px;
  }
}

.validating-panel-footer-thanks {
  &__wrap {
    position: relative;
    top: -1px;
    left: -24px;

    @include breakpoint-up(md) {
      top: auto;
      left: auto;
      width: 100%;
    }
  }

  &__title {
    @include grotesk(semiBold);

    display: inline-block;
    width: 173px;
    margin-right: 21px;
    margin-left: 99px;

    font-size: 1.4667rem;
    text-align: center;
  }

  &__description {
    display: inline-block;
    max-width: 580px;
    padding-top: 7px;

    font-size: 1.0667rem;
    vertical-align: top;
  }

  &__description :deep() a {
    @include grotesk(medium);

    color: white;
  }
}
</style>
