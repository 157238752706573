import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, createElementVNode as _createElementVNode, mergeProps as _mergeProps, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "list-management-modal__body"
}
const _hoisted_2 = {
  key: 0,
  class: "list-management-modal__tip"
}
const _hoisted_3 = {
  key: 2,
  class: "list-management-modal__empty-container"
}
const _hoisted_4 = { class: "list-management-modal__empty-text" }
const _hoisted_5 = { class: "list-management-modal__empty-cta" }
const _hoisted_6 = { class: "list-management-modal__footer-left" }
const _hoisted_7 = { class: "list-management-modal__footer-right" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ElInput = _resolveComponent("ElInput")!
  const _component_ElFormItem = _resolveComponent("ElFormItem")!
  const _component_PxTextarea = _resolveComponent("PxTextarea")!
  const _component_ElForm = _resolveComponent("ElForm")!
  const _component_PxIcon = _resolveComponent("PxIcon")!
  const _component_ListManagementModalItem = _resolveComponent("ListManagementModalItem")!
  const _component_PxButton = _resolveComponent("PxButton")!
  const _component_PanelEmptyState = _resolveComponent("PanelEmptyState")!
  const _component_ElDialog = _resolveComponent("ElDialog")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createBlock(_component_ElDialog, {
    ref: "modal",
    modelValue: _ctx.innerVisibility,
    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.innerVisibility) = $event)),
    "append-to-body": true,
    "close-on-click-modal": false,
    "show-close": true,
    title: _ctx.modalTitle,
    class: "list-management-modal__container",
    width: "659px"
  }, {
    default: _withCtx(() => [
      (_ctx.showFormTab)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(_component_ElForm, {
              ref: "listForm",
              model: _ctx.listFormData,
              rules: _ctx.listFormRules,
              onSubmit: _cache[2] || (_cache[2] = _withModifiers(() => {}, ["prevent"])),
              onValidate: _ctx.updateSubmitState
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ElFormItem, {
                  label: _ctx.formCopy.title,
                  prop: "title"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ElInput, {
                      modelValue: _ctx.listFormData.title,
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.listFormData.title) = $event)),
                      maxlength: _ctx.titleFieldMaxSize,
                      placeholder: _ctx.formCopy.title
                    }, null, 8, ["modelValue", "maxlength", "placeholder"])
                  ]),
                  _: 1
                }, 8, ["label"]),
                _createVNode(_component_ElFormItem, {
                  label: _ctx.formCopy.description,
                  required: false,
                  "show-message": false,
                  prop: "description"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_PxTextarea, {
                      modelValue: _ctx.listFormData.description,
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.listFormData.description) = $event)),
                      "max-chars": _ctx.$options.static.descriptionFieldMaxSize,
                      placeholder: _ctx.formCopy.description,
                      "show-counter": ""
                    }, null, 8, ["modelValue", "max-chars", "placeholder"])
                  ]),
                  _: 1
                }, 8, ["label"])
              ]),
              _: 1
            }, 8, ["model", "rules", "onValidate"])
          ]))
        : _createCommentVNode("", true),
      (_ctx.isCurrentTab(_ctx.EListManagementModalTab.SELECT))
        ? _withDirectives((_openBlock(), _createElementBlock("div", {
            key: 1,
            class: _normalizeClass([{ 'is-loading': _ctx.isLoading }, "list-management-modal__body"]),
            "element-loading-background": "#fff"
          }, [
            (_ctx.showTip)
              ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                  _createVNode(_component_PxIcon, {
                    size: 16,
                    class: "list-management-modal__tip-icon",
                    name: "information"
                  }),
                  _createTextVNode(" " + _toDisplayString(_ctx.tipCopy), 1)
                ]))
              : _createCommentVNode("", true),
            (_ctx.hasExistingLists)
              ? (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.availableLists, (list, index) => {
                  return (_openBlock(), _createBlock(_component_ListManagementModalItem, {
                    key: `list-${index}`,
                    value: list
                  }, null, 8, ["value"]))
                }), 128))
              : (_openBlock(), _createElementBlock("div", _hoisted_3, [
                  _createVNode(_component_PanelEmptyState, { icon: "lists" }, {
                    default: _withCtx(() => [
                      _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.listsEmptyStateCopy), 1),
                      _createElementVNode("div", _hoisted_5, [
                        _createVNode(_component_PxButton, _mergeProps(_ctx.newListButtonProps, { onClick: _ctx.onOpenCreateListTabButtonClick }), null, 16, ["onClick"])
                      ])
                    ]),
                    _: 1
                  })
                ]))
          ], 2)), [
            [_directive_loading, _ctx.isLoading]
          ])
        : _createCommentVNode("", true),
      _createElementVNode("div", {
        class: _normalizeClass([{
        'list-management-modal__footer--shadowless': _ctx.isFooterShadowless,
      }, "list-management-modal__footer"])
      }, [
        _createElementVNode("div", _hoisted_6, [
          (_ctx.showCreateTabButton)
            ? (_openBlock(), _createBlock(_component_PxButton, _mergeProps({
                key: 0,
                ref: "createButton"
              }, _ctx.createNewButtonProps, { onClick: _ctx.onOpenCreateListTabButtonClick }), null, 16, ["onClick"]))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createVNode(_component_PxButton, _mergeProps({ ref: "cancelButton" }, _ctx.cancelButtonProps, { onClick: _ctx.onCancelButtonClick }), null, 16, ["onClick"]),
          (_ctx.isCurrentTab(_ctx.EListManagementModalTab.SELECT))
            ? (_openBlock(), _createBlock(_component_PxButton, _mergeProps({
                key: 0,
                ref: "addButton"
              }, _ctx.addToListButtonProps, {
                class: "list-management-modal__select-cta",
                onClick: _ctx.onListSelect
              }), null, 16, ["onClick"]))
            : _createCommentVNode("", true),
          (_ctx.showMoveButton)
            ? (_openBlock(), _createBlock(_component_PxButton, _mergeProps({
                key: 1,
                ref: "moveButton"
              }, _ctx.moveToListButtonProps, {
                class: "list-management-modal__move-cta",
                onClick: _ctx.onMoveAction
              }), null, 16, ["onClick"]))
            : _createCommentVNode("", true),
          (_ctx.isCurrentTab(_ctx.EListManagementModalTab.CREATE))
            ? (_openBlock(), _createBlock(_component_PxButton, _mergeProps({
                key: 2,
                ref: "createButton"
              }, _ctx.submissionButtonProps, { onClick: _ctx.onCreateSubmission }), null, 16, ["onClick"]))
            : _createCommentVNode("", true),
          (_ctx.isCurrentTab(_ctx.EListManagementModalTab.EDIT))
            ? (_openBlock(), _createBlock(_component_PxButton, _mergeProps({
                key: 3,
                ref: "editButton"
              }, _ctx.submissionButtonProps, { onClick: _ctx.onEditSubmission }), null, 16, ["onClick"]))
            : _createCommentVNode("", true)
        ])
      ], 2)
    ]),
    _: 1
  }, 8, ["modelValue", "title"]))
}