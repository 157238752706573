import {
  SUPPORTERS_CRITERIA_ADDITIONAL_INTERESTS_PROGRAM,
  SUPPORTERS_CRITERIA_ASSESSMENT_PROGRAM,
  SUPPORTERS_CRITERIA_BASIC_INTERESTS_PROGRAM,
  SUPPORTERS_CRITERIA_IMPORTANCE_PROGRAM,
  SUPPORTERS_CRITERIA_QUESTIONS_PROGRAM,
  SUPPORTERS_PASSWORD_PROGRAM,
  SUPPORTERS_PROGRAM_RESULTS,
  SUPPORTERS_STARTING_POINT_PROGRAM,
  SUPPORTERS_TEAM_MANAGEMENT_PROGRAM,
} from "@/modules/supporters/services/router/routes-names";

import ParentSimplifiedView from "@/modules/supporters/views/parent/parent-simplified.vue";
import StartingPointAffiliateView from "@/modules/supporters/views/starting-point/starting-point-affiliate.vue";
import SetupPasswordView from "@/modules/supporters/views/setup-password/setup-password.vue";
import CriteriaAssessmentView from "@/modules/supporters/views/criteria-assessment/criteria-assessment.vue";
import CriteriaQuestionsView from "@/modules/supporters/views/criteria-questions/criteria-questions.vue";
import CriteriaAdditionalInterestsView from "@/modules/supporters/views/criteria-additional-interests/criteria-additional-interests.vue";
import CriteriaImportanceView from "@/modules/supporters/views/criteria-importance/criteria-importance.vue";
import TeamManagementView from "@/modules/team-management/views/team-management.view.vue";

import { nestedRoutesWithRenderlessParent } from "@/utils/router";
import CriteriaBasicInterestsView from "@/modules/supporters/views/criteria-basic-interests/criteria-basic-interests.vue";
import Results from "@/modules/supporters/views/results/results.vue";
import { RouteLocationNormalized } from "vue-router";
import {
  changeLocale,
  DEFAULT_LOCALE,
  loadedLocales,
} from "@/services/i18n/locale-utils";
import { userManager } from "@/modules/authentication/user-manager";
import { ERROR_ROUTE } from "@/services/router/router-names";
import { ERROR_TYPES } from "@/modules/common/components/error-page/constants";

export default {
  path: "/supporters/program",
  component: ParentSimplifiedView,
  children: [
    {
      path: "",
      name: SUPPORTERS_STARTING_POINT_PROGRAM,
      component: StartingPointAffiliateView,
      meta: {
        nextPage: SUPPORTERS_CRITERIA_ASSESSMENT_PROGRAM,
      },
    },

    ...nestedRoutesWithRenderlessParent({
      options: {
        suffixPath: "criteria",
        meta: {
          isNavbarWizard: true,
          navbarHeadline: "supporters.component.criteriaWizard.title",
          innerNavigation: {
            assessment: SUPPORTERS_CRITERIA_ASSESSMENT_PROGRAM,
            questions: SUPPORTERS_CRITERIA_QUESTIONS_PROGRAM,
            basicInterests: SUPPORTERS_CRITERIA_BASIC_INTERESTS_PROGRAM,
            additionalInterests:
              SUPPORTERS_CRITERIA_ADDITIONAL_INTERESTS_PROGRAM,
          },
        },
      },
      children: [
        {
          path: "assessment",
          name: SUPPORTERS_CRITERIA_ASSESSMENT_PROGRAM,
          component: CriteriaAssessmentView,
          meta: {
            previousPage: SUPPORTERS_STARTING_POINT_PROGRAM,
            nextPage: SUPPORTERS_CRITERIA_QUESTIONS_PROGRAM,
          },
        },
        {
          path: "questions",
          name: SUPPORTERS_CRITERIA_QUESTIONS_PROGRAM,
          component: CriteriaQuestionsView,
          meta: {
            previousPage: SUPPORTERS_CRITERIA_ASSESSMENT_PROGRAM,
            nextPage: [
              SUPPORTERS_TEAM_MANAGEMENT_PROGRAM,
              SUPPORTERS_CRITERIA_BASIC_INTERESTS_PROGRAM,
            ],
          },
        },
        {
          path: "team-management",
          name: SUPPORTERS_TEAM_MANAGEMENT_PROGRAM,
          component: TeamManagementView,
          meta: {
            previousPage: SUPPORTERS_CRITERIA_QUESTIONS_PROGRAM,
            nextPage: SUPPORTERS_CRITERIA_BASIC_INTERESTS_PROGRAM,
          },
          props: { isAffiliateView: true },
        },
        {
          path: "basic-interests",
          name: SUPPORTERS_CRITERIA_BASIC_INTERESTS_PROGRAM,
          component: CriteriaBasicInterestsView,
          meta: {
            previousPage: [
              SUPPORTERS_TEAM_MANAGEMENT_PROGRAM,
              SUPPORTERS_CRITERIA_QUESTIONS_PROGRAM,
            ],
            nextPage: SUPPORTERS_CRITERIA_ADDITIONAL_INTERESTS_PROGRAM,
          },
        },
        {
          path: "additional-interests",
          name: SUPPORTERS_CRITERIA_ADDITIONAL_INTERESTS_PROGRAM,
          component: CriteriaAdditionalInterestsView,
          meta: {
            previousPage: SUPPORTERS_CRITERIA_BASIC_INTERESTS_PROGRAM,
            nextPage: SUPPORTERS_CRITERIA_IMPORTANCE_PROGRAM,
          },
        },
        {
          path: "importance",
          name: SUPPORTERS_CRITERIA_IMPORTANCE_PROGRAM,
          component: CriteriaImportanceView,
          meta: {
            previousPage: SUPPORTERS_CRITERIA_ADDITIONAL_INTERESTS_PROGRAM,
            nextPage: SUPPORTERS_PROGRAM_RESULTS,
            isExistingUserSubmitPage: true,
          },
        },
      ],
    }),

    {
      path: "results",
      name: SUPPORTERS_PROGRAM_RESULTS,
      component: Results,
      meta: {
        showFooter: true,
        isNavbarWizard: true,
        nextPage: SUPPORTERS_PASSWORD_PROGRAM,
      },
    },

    {
      path: "password",
      name: SUPPORTERS_PASSWORD_PROGRAM,
      component: SetupPasswordView,
      meta: {
        previousPage: SUPPORTERS_PROGRAM_RESULTS,
      },
    },
  ],
  // To be able to persist the selected language when refreshing the page
  // we setted a local storage variable with the locale selected by the user.
  // When entering in the route we need to validate if the variable is available or not and change the locale accordingly
  // This is only needed because the application is not prepared to display all the pages translated in spanish
  // This code should be removed when the whole application is prepared to be in spanish
  beforeEnter: async (
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: Function,
  ) => {
    if (userManager.isLogged() && userManager.isEntrepreneur()) {
      next({
        name: ERROR_ROUTE,
        query: {
          code: ERROR_TYPES.FORBIDDEN,
        },
      });
    }

    const locale = localStorage.getItem("userLocale") || DEFAULT_LOCALE;
    await changeLocale(locale, loadedLocales, document);

    next();
  },
};
