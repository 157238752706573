<template>
  <ElTooltip
    :content="criticalTooltipCopy"
    :disabled="!isVisitor"
    data-testid="critical-milestone-indicator-tooltip"
    placement="bottom"
    popper-class="el-tooltip__popper--small critical-milestone-indicator__tooltip el-abaca-tooltip"
  >
    <div class="critical-milestone-indicator">
      <PxIcon
        class="critical-milestone-indicator__icon"
        name="critical-milestone"
        size="20"
      />
      <div>
        {{ $t("milestonePlanner.milestone.critical") }}
      </div>
    </div>
  </ElTooltip>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { TranslateResult } from "vue-i18n";

export default defineComponent({
  name: "CriticalMilestoneIndicator",

  props: {
    isVisitor: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    criticalTooltipCopy(): TranslateResult {
      return this.$t(
        "milestonePlanner.milestonePlan.planForm.tooltips.critical.guest",
      );
    },
  },
});
</script>

<style lang="scss" scoped>
.critical-milestone-indicator {
  @include grotesk(medium);

  display: grid;
  grid-template-columns: 22px auto;
  width: 143px;
  margin: 8px auto;
  font-size: 14px;
  line-height: 19px;
  color: $light-orange;
  background-color: transparentize($light-orange, 0.92);
  border-radius: $--border-radius-small;

  &__icon {
    position: relative;
    top: -3px;
    left: 4px;
  }
}
</style>

<style lang="scss">
.critical-milestone-indicator__tooltip {
  max-width: 340px;
  text-transform: none;
}
</style>
