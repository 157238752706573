import { ActionTree } from "vuex";
import last from "lodash/last";
import {
  EMatchingInterestActions,
  EMatchingInterestMutations,
  IMatchingInterestPayload,
  IMatchingInterestState,
} from "./matching-interest.types";
import { RootState } from "@/services/store/root-state";
import { getBaseName } from "@/services/store/utils/get-base-name";
import { matchingInterestProvider } from "@/modules/matching/services/data/matching-interest/matching-interest.provider";
import { IMatchingInterest } from "@/modules/matching/services/data/matching-interest/matching-interest.interface";
import { matchingInterestResultsProvider } from "@/modules/matching/services/data/matching-interest-results/matching-interest-results.provider";
import { IMatchingScore } from "@/modules/matching/services/data/matching-score/matching-score.interface";
import { EMatchingMutations } from "@/modules/matching/services/store/matching/matching.types";
import {
  MATCHING_HAS_INTEREST,
  MATCHING_INITIAL_INTEREST,
} from "@/modules/matching/constants";
import { userManager } from "@/modules/authentication/user-manager";

/**
 * Check if is a match score corresponds to a
 * specific entrepreneur or supporter
 *
 * @param interest
 * @param score
 */
const isInterestScore = (
  interest: IMatchingInterestPayload,
  score: IMatchingScore,
): boolean => {
  return !!(
    (score.company && score.company.id === interest.entrepreneur) ||
    (score.supporter &&
      score.supporter.user_profile.company.id === interest.supporter)
  );
};

/**
 * Check if a interest doesn't exist in list
 *
 * @param interestList
 * @param newInterest
 */
const hasNewInterest = (
  interestList: IMatchingInterest[],
  newInterest: IMatchingInterestPayload,
): boolean => {
  return !interestList.some(
    (item: IMatchingInterest) =>
      item.entrepreneur === newInterest.entrepreneur &&
      item.supporter === newInterest.supporter,
  );
};

/**
 * Obtain previous and updated lists from which a
 * matching score transitions
 *
 * @param state
 * @param previousInterest
 * @param updatedInterest
 */
const getTransitionInterests = (
  state: any,
  previousInterest: IMatchingInterest,
  updatedInterest: IMatchingInterest,
): any => {
  const transitionInterests = {
    previous: {
      interest: previousInterest,
    },
    updated: {
      interest: updatedInterest,
    },
  };

  return Object.keys(transitionInterests).reduce(
    (accumulator: any, key: string) => {
      const transitionInterest = accumulator[key];
      const { interest } = transitionInterest;

      // Check if contains a lowest result, if not result return null
      transitionInterest.lowestScore =
        state.matching.data && state.matching.data.length > 0
          ? (last(state.matching.data) as IMatchingScore).score
          : null;

      if (interest.state_of_interest > MATCHING_HAS_INTEREST) {
        transitionInterest.list = state.matchingInterest.mutualMatchScores;
        transitionInterest.mutation = `matchingInterest/${EMatchingInterestMutations.SET_MUTUAL_MATCH_SCORES}`;
      } else if (
        interest.entrepreneur_is_interested === MATCHING_INITIAL_INTEREST &&
        interest.supporter_is_interested === MATCHING_HAS_INTEREST
      ) {
        if (userManager.isEntrepreneur()) {
          transitionInterest.list = state.matchingInterest.targetMatchScores;
          transitionInterest.mutation = `matchingInterest/${EMatchingInterestMutations.SET_TARGET_MATCH_SCORES}`;
        } else {
          transitionInterest.list = state.matchingInterest.userMatchScores;
          transitionInterest.mutation = `matchingInterest/${EMatchingInterestMutations.SET_USER_MATCH_SCORES}`;
        }
      } else if (
        interest.entrepreneur_is_interested === MATCHING_HAS_INTEREST &&
        interest.supporter_is_interested === MATCHING_INITIAL_INTEREST
      ) {
        if (userManager.isEntrepreneur()) {
          transitionInterest.list = state.matchingInterest.userMatchScores;
          transitionInterest.mutation = `matchingInterest/${EMatchingInterestMutations.SET_USER_MATCH_SCORES}`;
        } else {
          transitionInterest.list = state.matchingInterest.targetMatchScores;
          transitionInterest.mutation = `matchingInterest/${EMatchingInterestMutations.SET_TARGET_MATCH_SCORES}`;
        }
      } else {
        transitionInterest.list = state.matching.data;
        transitionInterest.mutation = `matching/${EMatchingMutations.SET_DATA}`;
      }

      return accumulator;
    },
    transitionInterests,
  );
};

export const matchingInterestActions: ActionTree<
  IMatchingInterestState,
  RootState
> = {
  /**
   * Fetch matching interests.
   *
   * @param param0 VUEX context
   */
  async [getBaseName(EMatchingInterestActions.FETCH)]({ commit }) {
    commit(EMatchingInterestMutations.SET_ERROR, null);
    commit(EMatchingInterestMutations.SET_LOADING, true);

    try {
      const interestList =
        (await matchingInterestProvider.list()) as IMatchingInterest[];
      commit(EMatchingInterestMutations.SET_DATA, interestList);
    } catch (e) {
      commit(EMatchingInterestMutations.SET_ERROR, e);
    } finally {
      commit(EMatchingInterestMutations.SET_LOADING, false);
    }
  },

  /**
   * Fetch matching interests without triggering error and loading.
   *
   * @param param0 VUEX context
   */
  async [getBaseName(EMatchingInterestActions.FETCH_INTERESTS)]({ commit }) {
    let response;

    try {
      const interestList =
        (await matchingInterestProvider.list()) as IMatchingInterest[];
      commit(EMatchingInterestMutations.SET_DATA, interestList);
    } catch (e) {
      response = e;
    }

    return response;
  },

  /**
   * Fetch logged user interest scores.
   *
   * @param commit
   * @param state
   * @param payload
   */
  async [getBaseName(EMatchingInterestActions.FETCH_USER_SCORES)](
    { commit, state },
    payload: { [key: string]: string },
  ) {
    const currentPage = +payload.page || 1;
    let response;

    try {
      response = await matchingInterestResultsProvider.user.list(payload);

      // Set pagination state
      commit(
        EMatchingInterestMutations.SET_USER_MATCH_SCORES_PAGE,
        !response.next ? null : currentPage + 1,
      );

      if (currentPage === 1) {
        commit(
          EMatchingInterestMutations.SET_USER_MATCH_SCORES,
          response.results,
        );
      } else {
        commit(EMatchingInterestMutations.SET_USER_MATCH_SCORES, [
          ...state.userMatchScores,
          ...response.results,
        ]);
      }

      // Set total count
      commit(
        EMatchingInterestMutations.SET_USER_MATCH_SCORES_COUNT,
        response.count,
      );
    } catch (e) {
      commit(EMatchingInterestMutations.SET_USER_MATCH_SCORES_PAGE, null);
      response = e;
    }

    return response;
  },

  /**
   * Fetch other users' interest scores in logged user.
   *
   * @param commit
   * @param state
   * @param payload
   */
  async [getBaseName(EMatchingInterestActions.FETCH_TARGET_SCORES)](
    { commit, state },
    payload: { [key: string]: string },
  ) {
    const currentPage = +payload.page || 1;
    let response;

    try {
      response = await matchingInterestResultsProvider.targets.list(payload);

      // Set pagination state
      commit(
        EMatchingInterestMutations.SET_TARGET_MATCH_SCORES_PAGE,
        !response.next ? null : currentPage + 1,
      );

      if (currentPage === 1) {
        commit(
          EMatchingInterestMutations.SET_TARGET_MATCH_SCORES,
          response.results,
        );
      } else {
        commit(EMatchingInterestMutations.SET_TARGET_MATCH_SCORES, [
          ...state.targetMatchScores,
          ...response.results,
        ]);
      }

      // Set total count
      commit(
        EMatchingInterestMutations.SET_TARGET_MATCH_SCORES_COUNT,
        response.count,
      );
    } catch (e) {
      commit(EMatchingInterestMutations.SET_TARGET_MATCH_SCORES_PAGE, null);
      response = e;
    }

    return response;
  },

  /**
   * Fetch mutual interest scores.
   *
   * @param commit
   * @param state
   * @param payload
   */
  async [getBaseName(EMatchingInterestActions.FETCH_MUTUAL_SCORES)](
    { commit, state },
    payload: { [key: string]: string },
  ) {
    const currentPage = +payload.page || 1;
    let response;

    try {
      response = await matchingInterestResultsProvider.mutual.list(payload);

      // Set pagination state
      commit(
        EMatchingInterestMutations.SET_MUTUAL_MATCH_SCORES_PAGE,
        !response.next ? null : currentPage + 1,
      );

      if (currentPage === 1) {
        commit(
          EMatchingInterestMutations.SET_MUTUAL_MATCH_SCORES,
          response.results,
        );
      } else {
        commit(EMatchingInterestMutations.SET_MUTUAL_MATCH_SCORES, [
          ...state.mutualMatchScores,
          ...response.results,
        ]);
      }

      // Set total count
      commit(
        EMatchingInterestMutations.SET_MUTUAL_MATCH_SCORES_COUNT,
        response.count,
      );
    } catch (e) {
      commit(EMatchingInterestMutations.SET_MUTUAL_MATCH_SCORES_PAGE, null);
      response = e;
    }

    return response;
  },

  /**
   * Fetch matching interests and scores.
   *
   * @param param0 VUEX context
   * @param payload
   */
  async [getBaseName(EMatchingInterestActions.FETCH_ALL)](
    { commit, dispatch },
    payload: { [key: string]: string },
  ) {
    commit(EMatchingInterestMutations.SET_ERROR, null);
    commit(EMatchingInterestMutations.SET_LOADING, true);

    try {
      const responses = await Promise.all([
        dispatch(getBaseName(EMatchingInterestActions.FETCH_INTERESTS)),

        dispatch(getBaseName(EMatchingInterestActions.FETCH_USER_SCORES), {
          page: 1,
          ...payload,
        }),

        dispatch(getBaseName(EMatchingInterestActions.FETCH_TARGET_SCORES), {
          page: 1,
          ...payload,
        }),

        dispatch(getBaseName(EMatchingInterestActions.FETCH_MUTUAL_SCORES), {
          page: 1,
          ...payload,
        }),
      ]);

      // Register Interest fetch errors, since other requests have pagination
      // and are prone to launch false positive errors.
      if (!!responses[0]) {
        commit(EMatchingInterestMutations.SET_ERROR, responses[0]);
      }
    } catch (e) {
      commit(EMatchingInterestMutations.SET_ERROR, e);
    } finally {
      commit(EMatchingInterestMutations.SET_LOADING, false);
    }
  },

  /**
   * Allow to append directly the company scores, with pagination
   *
   * @param param0 VUEX context
   * @param payload
   */
  async [getBaseName(EMatchingInterestActions.APPEND)](
    { commit, state },
    {
      interests,
      userInterestScoreList,
      targetInterestScoreList,
      mutualInterestScoreList,
    }: {
      interests: Array<IMatchingInterest>;
      userInterestScoreList: Array<IMatchingScore>;
      targetInterestScoreList: Array<IMatchingScore>;
      mutualInterestScoreList: Array<IMatchingScore>;
      page: number;
    },
  ) {
    commit(EMatchingInterestMutations.SET_DATA, [...state.data, ...interests]);

    commit(EMatchingInterestMutations.SET_USER_MATCH_SCORES, [
      ...state.userMatchScores,
      ...userInterestScoreList,
    ]);

    commit(EMatchingInterestMutations.SET_TARGET_MATCH_SCORES, [
      ...state.targetMatchScores,
      ...targetInterestScoreList,
    ]);

    commit(EMatchingInterestMutations.SET_MUTUAL_MATCH_SCORES, [
      ...state.mutualMatchScores,
      ...mutualInterestScoreList,
    ]);
  },

  /**
   * Register interest.
   *
   * @param param0
   * @param payload Interest to be registered
   *
   * TODO: Reduce business and presentation logic from REGISTER action
   */
  [getBaseName(EMatchingInterestActions.REGISTER)](
    { commit, state, rootState },
    payload: IMatchingInterestPayload,
  ) {
    commit(EMatchingInterestMutations.SET_ERROR, null);
    commit(EMatchingInterestMutations.SET_LOADING, true);

    const currentInterestData = [...state.data];

    try {
      // Apply optimistic request
      matchingInterestProvider
        .create(payload)
        .catch((requestError: any) =>
          commit(EMatchingInterestMutations.SET_ERROR, requestError),
        );

      // Add entry on matching interest if it doesn't exist on interest
      if (hasNewInterest(currentInterestData, payload)) {
        currentInterestData.push({
          ...payload,
          entrepreneur_is_interested: 0,
          supporter_is_interested: 0,
        } as IMatchingInterest);
      }

      const updatedInterests = currentInterestData.map(
        (item: IMatchingInterest) => {
          // Either payload contains entrepreneur id or supporter id
          if (
            item.entrepreneur === payload.entrepreneur &&
            item.supporter === payload.supporter
          ) {
            // Update new interest state binary values
            const supporterInterest =
              typeof payload.supporter_is_interested !== "undefined"
                ? payload.supporter_is_interested
                : item.supporter_is_interested;
            const entrepreneurInterest =
              typeof payload.entrepreneur_is_interested !== "undefined"
                ? payload.entrepreneur_is_interested
                : item.entrepreneur_is_interested;
            const stateOfInterest = entrepreneurInterest + supporterInterest;

            // Update new interest state
            const updatedInterest = {
              ...item,
              supporter_is_interested: supporterInterest,
              entrepreneur_is_interested: entrepreneurInterest,
              state_of_interest: stateOfInterest,
            } as IMatchingInterest;

            // Obtain store score list transition, from > to
            const transitionStates = getTransitionInterests(
              rootState,
              item,
              updatedInterest,
            );

            // Get the value from the previous state list
            const transitionValue = transitionStates.previous.list.find(
              (score: any) => isInterestScore(payload, score),
            ) as IMatchingScore;

            // After, filter value on previous state list
            commit(
              transitionStates.previous.mutation,
              transitionStates.previous.list.filter(
                (score: any) => !isInterestScore(payload, score),
              ),
              { root: true },
            );

            // Push value to updated state list
            const updatedLowestScore = transitionStates.updated.lowestScore;

            // Just add if no lowest score is set or, if set, the lowest score is lower than the updated value score
            if (
              !updatedLowestScore ||
              (!!updatedLowestScore &&
                transitionStates.updated.lowestScore <= transitionValue.score)
            ) {
              const pushedToArray = [
                ...transitionStates.updated.list,
                transitionValue,
              ] as IMatchingScore[];

              commit(
                transitionStates.updated.mutation,
                pushedToArray.sort(
                  (scoreOne: IMatchingScore, scoreTwo: IMatchingScore) =>
                    scoreTwo.score - scoreOne.score,
                ),
                { root: true },
              );
            }

            return updatedInterest;
          }

          return item;
        },
      );

      const {
        userInterestScoreList,
        targetInterestScoreList,
        mutualInterestScoreList,
      } = updatedInterests.reduce(
        (accumulated: any, interest: IMatchingInterest) => {
          const {
            userInterestScoreList: accumUser,
            targetInterestScoreList: accumTarget,
            mutualInterestScoreList: accumMutual,
          } = accumulated;

          const hasUserInterest =
            (userManager.isEntrepreneur() &&
              !!interest.entrepreneur_is_interested) ||
            (userManager.isSupporter() && !!interest.supporter_is_interested);

          const hasTargetInterest =
            (userManager.isEntrepreneur() &&
              !!interest.supporter_is_interested) ||
            (userManager.isSupporter() &&
              !!interest.entrepreneur_is_interested);

          const hasMutualInterest = hasUserInterest && hasTargetInterest;

          if (hasMutualInterest) {
            return {
              ...accumulated,
              mutualInterestScoreList: [...accumMutual, interest],
            };
          }

          if (hasUserInterest) {
            return {
              ...accumulated,
              userInterestScoreList: [...accumUser, interest],
            };
          }

          if (hasTargetInterest) {
            return {
              ...accumulated,
              targetInterestScoreList: [...accumTarget, interest],
            };
          }

          return {
            ...accumulated,
          };
        },
        {
          userInterestScoreList: [] as Array<IMatchingInterest>,
          targetInterestScoreList: [] as Array<IMatchingInterest>,
          mutualInterestScoreList: [] as Array<IMatchingInterest>,
        },
      );

      // Set counts
      commit(
        EMatchingInterestMutations.SET_TARGET_MATCH_SCORES_COUNT,
        targetInterestScoreList.length,
      );

      commit(
        EMatchingInterestMutations.SET_USER_MATCH_SCORES_COUNT,
        userInterestScoreList.length,
      );

      commit(
        EMatchingInterestMutations.SET_MUTUAL_MATCH_SCORES_COUNT,
        mutualInterestScoreList.length,
      );

      // Update matching interest state stored values based on payload
      commit(EMatchingInterestMutations.SET_DATA, updatedInterests);
    } catch (e) {
      commit(EMatchingInterestMutations.SET_ERROR, e);
    } finally {
      commit(EMatchingInterestMutations.SET_LOADING, false);
    }
  },

  /**
   * Set value for "can match" flag.
   *
   * @param param0
   * @param payload Binary flag state
   */
  [getBaseName(EMatchingInterestActions.SET_CAN_MATCH)](
    { commit },
    payload: boolean,
  ) {
    commit(EMatchingInterestMutations.SET_CAN_MATCH, payload);
  },
};
