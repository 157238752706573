<template>
  <div class="pricing-switch">
    <ElSwitch
      v-model="isActive"
      :active-text="$t('profile.accountSettingsPage.pricingSwitch.labels[1]')"
      :inactive-text="$t('profile.accountSettingsPage.pricingSwitch.labels[0]')"
      :width="44"
      data-testid="pricing-switch-element"
      @change="$emit('update:modelValue', !modelValue)"
    />
    <div class="pricing-switch__annual-discount">
      {{ $t("profile.accountSettingsPage.pricingSwitch.annualDiscount") }}
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "PricingSwitch",

  props: {
    modelValue: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      isActive: false,
    };
  },

  watch: {
    modelValue: {
      immediate: true,
      handler(newValue: boolean) {
        this.isActive = newValue;
      },
    },

    isActive(newValue: boolean) {
      if (this.modelValue !== newValue) {
        this.$emit("update:modelValue", newValue);
      }
    },
  },
});
</script>

<style lang="scss" scoped>
.pricing-switch .el-switch {
  height: auto;

  :deep() .el-switch__label {
    text-transform: capitalize;

    span {
      @include grotesk(regular);

      font-size: 14px;
      line-height: 1.43;
      color: $manatee;
    }

    &.is-active span {
      @include grotesk(semiBold);

      color: $ebony-clay;
    }

    &--left,
    &--right {
      line-height: 1;
      letter-spacing: -0.2px;
    }

    &--left {
      margin-right: 13px;
    }

    &--right {
      margin-left: 12px;
    }
  }

  :deep() .el-switch__core {
    border-radius: 16px;
    height: 24px;

    .el-switch__action {
      width: 18px;
      height: 18px;
      background: $manatee;
    }
  }

  &.is-checked :deep() {
    .el-switch__core {
      background: $soft-blue;
    }

    .el-switch__core .el-switch__action {
      background: $white;
      left: 5px;
    }
  }
}

.pricing-switch {
  display: grid;
  grid-template-columns: repeat(2, max-content);
  grid-gap: 5px;

  &__annual-discount {
    padding: 2px 4px;

    display: flex;
    align-items: center;
    justify-content: center;

    border-radius: 4px;
    background-color: #e3f1fc;

    font-size: 12px;
    line-height: 1;
    color: $bluish;
    letter-spacing: 0.4px;
  }
}
</style>
