<template>
  <div class="level-update-modal-levels-example">
    <div class="level-update-modal-levels-example__wrapper">
      <div>
        <h1 class="level-update-modal-levels-example__title">
          {{ modalTitle }}
        </h1>
        <PxButton
          ref="backButton"
          class="el-button--link-dark"
          data-testid="back-button"
          size="small"
          type="link"
          @click="$emitter.emit('change-level-example-modal-visibility')"
        >
          {{ $t("common.back") }}
        </PxButton>
      </div>
      <PxNavigationTab
        v-model:selected-tab="innerActiveTab"
        :navigation-tabs="navigationTabs"
        :show-number="false"
        data-testid="navigation-tab"
      />
    </div>
    <div class="level-update-modal-levels-example__main-content">
      <LevelUpdateModalFirstTab
        v-if="innerActiveTab === 1"
        :current-level="currentLevel"
        :level-up="isLevelUp"
        :new-level="newLevel"
        :same-level="isSameLevel"
        data-testid="level-update-first-tab"
      />
      <img
        v-else
        :src="tabImage"
        alt="assessment-results"
        data-testid="level-update-modal-image"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { ILevel } from "@/services/data/level/level.interface";
import { TranslateResult } from "vue-i18n";
import PxNavigationTab from "@/components/px-navigation-tab/px-navigation-tab.vue";
import LevelUpdateModalFirstTab from "@/modules/common/components/level-update-modal/level-update-modal-first-tab.vue";

export default defineComponent({
  name: "LevelUpdateModalLevelsExample",

  components: {
    PxNavigationTab,
    LevelUpdateModalFirstTab,
  },

  props: {
    currentLevel: {
      type: Object as () => ILevel,
      required: true,
    },
    newLevel: {
      type: Object as () => ILevel,
      required: true,
    },
  },

  data() {
    return {
      navigationTabs: [
        {
          id: 1,
          title: this.$t(
            "common.components.levelUpdateModal.examplePage.navigationTabs.1",
          ),
        },
        {
          id: 2,
          title: this.$t(
            "common.components.levelUpdateModal.examplePage.navigationTabs.2",
          ),
        },
        {
          id: 3,
          title: this.$t(
            "common.components.levelUpdateModal.examplePage.navigationTabs.3",
          ),
        },
      ],
      innerActiveTab: 1,
    };
  },

  computed: {
    isSameLevel(): boolean {
      return this.currentLevel.value === this.newLevel.value;
    },

    isLevelUp(): boolean {
      return this.currentLevel.value < this.newLevel.value;
    },

    modalTitle(): TranslateResult {
      return this.isSameLevel
        ? this.$t(
            "common.components.levelUpdateModal.examplePage.sameLevelTitle",
          )
        : this.isLevelUp
          ? this.$t(
              "common.components.levelUpdateModal.examplePage.levelUpTitle",
            )
          : this.$t(
              "common.components.levelUpdateModal.examplePage.levelDownTitle",
            );
    },

    levelUpdateType(): TranslateResult {
      return this.isSameLevel
        ? this.$t("common.components.levelUpdateModal.typeOfUpdate.sameLevel")
        : this.isLevelUp
          ? this.$t("common.components.levelUpdateModal.typeOfUpdate.levelUp")
          : this.$t(
              "common.components.levelUpdateModal.typeOfUpdate.levelDown",
            );
    },

    tabImage(): string {
      return `/img/illustrations/milestone-planner/${this.levelUpdateType}-${this.innerActiveTab}.svg`;
    },
  },
});
</script>

<style lang="scss" scoped>
.level-update-modal-levels-example {
  min-height: 552px;

  .el-button.el-button--link {
    position: relative;
    bottom: 35px;
    left: 34px;

    &::before {
      position: relative;
      top: 4px;
      left: -7px;
      display: block;
      width: 20px;
      height: 20px;

      content: "";
      background: url("#{$assetsPath}/img/icons/arrow--right-dark.svg")
        no-repeat;
      border: none;
      opacity: 1;
      transform: scaleX(-1);
    }
  }

  &__title {
    position: relative;
    bottom: 1px;
    padding: 0 90px;
    margin-bottom: 5px;
    margin-left: 1px;
    font-size: 20px;
    line-height: 29px;
    color: $ebony-clay;
    text-align: center;
  }

  &__wrapper {
    position: relative;

    &::before {
      position: absolute;
      bottom: 0;
      left: 50%;
      width: calc(100% - 9px);
      height: 1px;
      content: "";
      background-color: #f0f1f5;
      transform: translateX(-50%);
    }
  }

  &__main-content {
    display: flex;
    justify-content: center;
  }

  img {
    margin: 48px auto 0;
  }

  .px-navigation-tab {
    position: relative;
    margin: 0 auto;
  }

  :deep() .px-navigation-tab-item__title {
    position: relative;
    bottom: 2px;
    letter-spacing: -0.2px;
  }
}
</style>
