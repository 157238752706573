import { IGenericState } from "@/services/store/generic/generic-state.interface";
import { IQuestionBundle } from "@/services/data/question-bundle/question-bundle.interface";

/**
 * Represents the store structure for the question bundles sub-module.
 */
export interface IQuestionBundlesState extends IGenericState<IQuestionBundle> {
  /**
   * Pagination current page.
   */
  currentPage: number | null;
}

export enum EQuestionBundlesState {
  NAMESPACE = "milestonePlanner/questionBundles",
}

export enum EQuestionBundlesAction {
  GET_VALUE = "getValue",
  GET_VALUE_DETAIL = "getValueDetail",
  GET_VALUES = "getValues",
  CREATE_VALUE = "createValue",
  PATCH_VALUE = "patchValue",
  REMOVE_VALUE = "removeValue",
}
