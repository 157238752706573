import { ActionTree } from "vuex";

import {
  ESupporterInvestingLevelActions,
  ESupporterInvestingLevelMutations,
  ISupporterInvestingLevelState,
} from "./supporter-investing-level-types";
import { RootState } from "@/services/store/root-state";
import { getBaseName } from "@/services/store/utils/get-base-name";
import { ICategory } from "@/services/data/category/category.interface";
import { viralLevelProvider } from "@/services/data/viral-level/viral-level.provider";
import { ISupporterMetadataEntry } from "./types/supporter-metadata.interface";
import { affiliateProvider } from "@/services/data/affiliate/affiliate.provider";
import { assessmentProvider } from "@/services/data/assessment/assessment.provider";
import {
  ELevelActions,
  ELevelGetters,
} from "@/services/store/levels/levels-types";
import { ILevel } from "@/services/data/level/level.interface";
import { EProfileCompanyActions } from "@/modules/profile/services/store/profile/profile-types";
import { ICategoryLevelSelection } from "@/services/data/viral-level/viral-level.interface";
import { IAssessmentLevel } from "@/services/data/assessment/assessment.interface";

export const actions: ActionTree<ISupporterInvestingLevelState, RootState> = {
  /**
   * Reset the module state.
   */
  [getBaseName(ESupporterInvestingLevelActions.RESET)]({ rootState, commit }) {
    const categories = (rootState as any).categories.data as Array<ICategory>;

    const levels = categories.map((category: ICategory) => {
      return {
        category: category.id,
        level: [1, 1],
      };
    });

    commit(ESupporterInvestingLevelMutations.SET_LEVELS, levels);
    commit(ESupporterInvestingLevelMutations.SET_SELECTED_CATEGORY, 0);
    commit(ESupporterInvestingLevelMutations.SET_SUBMITTED, false);
  },

  /**
   * Set level for the given category.
   */
  [getBaseName(ESupporterInvestingLevelActions.SET_LEVEL)](
    { state, commit },
    { category, level },
  ) {
    // Filter values to extract updated level
    const levels = state.levels.filter(
      (level: ICategoryLevelSelection) => level.category !== category,
    );

    // Create updated level object
    const updatedLevel = {
      category,
      level: level,
    };

    commit(ESupporterInvestingLevelMutations.SET_LEVEL, [
      ...levels,
      updatedLevel,
    ]);
  },

  /**
   * Action used to set the current selected level.
   */
  [getBaseName(ESupporterInvestingLevelActions.SET_SELECTED_CATEGORY)](
    { commit },
    newLevel,
  ) {
    commit(ESupporterInvestingLevelMutations.SET_SELECTED_CATEGORY, newLevel);
  },

  /**
   * This must be called when the user first interact with the level slider.
   */
  [getBaseName(ESupporterInvestingLevelActions.INTERACTION)]({ commit }) {
    commit(ESupporterInvestingLevelMutations.SET_INTERACTION, false);
  },

  /**
   * Set the loading state.
   */
  [getBaseName(ESupporterInvestingLevelActions.SET_LOADING)](
    { commit },
    newVal,
  ) {
    commit(ESupporterInvestingLevelMutations.SET_LOADING, newVal);
  },

  /**
   * Compute the final level for the self assessment and store it
   * on the store.
   */
  async [getBaseName(ESupporterInvestingLevelActions.COMPUTE_LEVEL)]({
    commit,
    state,
  }) {
    commit(ESupporterInvestingLevelMutations.SET_LOADING, true);
    commit(ESupporterInvestingLevelMutations.SET_ERROR, null);

    const levels = state.levels.map((item: ICategoryLevelSelection) => {
      return {
        ...item,
        level: item.level === 0 ? null : item.level,
      };
    });

    try {
      const { level } = (await viralLevelProvider.create({
        levels,
      })) as any;

      commit(ESupporterInvestingLevelMutations.SET_FINAL_LEVEL, level);
    } catch (error) {
      commit(ESupporterInvestingLevelMutations.SET_ERROR, error || true);
    } finally {
      commit(ESupporterInvestingLevelMutations.SET_LOADING, false);
    }
  },

  /**
   * Fetch affiliate and store it.
   */
  async [getBaseName(ESupporterInvestingLevelActions.FETCH_AFFILIATE)](
    { commit },
    affiliateId,
  ) {
    commit(ESupporterInvestingLevelMutations.SET_LOADING, true);

    try {
      const affiliate = await affiliateProvider.get(affiliateId);
      commit(ESupporterInvestingLevelMutations.SET_AFFILIATE, affiliate);
    } catch (error) {
      commit(ESupporterInvestingLevelMutations.SET_AFFILIATE, null);
      commit(ESupporterInvestingLevelMutations.SET_LOADING, false);
    }

    commit(ESupporterInvestingLevelMutations.SET_LOADING, false);
  },

  async [getBaseName(ESupporterInvestingLevelActions.FETCH_ASSESSMENT)](
    { commit, dispatch, rootGetters },
    token: string,
  ) {
    // Fetch levels from the API
    await dispatch(ELevelActions.FETCH, null, {
      root: true,
    });

    commit(ESupporterInvestingLevelMutations.SET_LOADING, true);
    commit(ESupporterInvestingLevelMutations.SET_ERROR, false);

    const assessment = await assessmentProvider.getByToken(token);

    // Set levels data
    const levels = assessment.data.map((entry: IAssessmentLevel) => ({
      category: entry.category,
      level: entry.level || 0,
    }));
    commit(ESupporterInvestingLevelMutations.SET_LEVELS, levels);

    // Set final level
    const allLevels = rootGetters[ELevelGetters.LEVELS] as Array<ILevel>;
    const finalLevel = allLevels.find(
      (entry: ILevel) => entry.id === assessment.level.id,
    );
    commit(ESupporterInvestingLevelMutations.SET_FINAL_LEVEL, finalLevel);

    await dispatch(EProfileCompanyActions.FETCH, assessment.evaluated, {
      root: true,
    });

    commit(ESupporterInvestingLevelMutations.SET_LOADING, false);
  },

  /**
   * Set self-assessment as submitted.
   */
  [getBaseName(ESupporterInvestingLevelActions.SET_AS_SUBMITTED)]({ commit }) {
    commit(ESupporterInvestingLevelMutations.SET_SUBMITTED, true);
  },

  /**
   * Set metadata based on a key and value object.
   *
   * Example:
   * ```
   *  { key: "footerClass", value: "is-assessment-results" }
   * ```
   *
   * @param param0 Context
   * @param payload Metadata to be set.
   */
  [getBaseName(ESupporterInvestingLevelActions.SET_METADATA)](
    { commit },
    payload: ISupporterMetadataEntry,
  ) {
    commit(ESupporterInvestingLevelMutations.SET_METADATA, payload);
  },

  /**
   * Allows to remove a key from the set of metadata.
   *
   * @param param0 Context
   * @param key Key to be removed.
   */
  [getBaseName(ESupporterInvestingLevelActions.REMOVE_METADATA)](
    { commit },
    key: string,
  ) {
    commit(ESupporterInvestingLevelMutations.REMOVE_METADATA, key);
  },

  /**
   * Set the final level.
   */
  [getBaseName(ESupporterInvestingLevelActions.SET_FINAL_LEVEL)](
    { commit },
    level: ILevel,
  ) {
    commit(ESupporterInvestingLevelMutations.SET_FINAL_LEVEL, level);
  },
};
