<template>
  <ElDialog
    ref="modal"
    v-model="innerVisibility"
    :append-to-body="true"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :show-close="showClose"
    :title="$t('common.loginModal.title')"
    class="loginModal"
    data-testid="login-modal"
    width="466px"
  >
    <PxModalMessage :visible="hasServerErrors" />
    <slot name="before-form" />
    <div
      ref="formWrapper"
      :class="{ 'scroll-at-bottom': formWrapperScrollAtEnd }"
      class="loginModal__form-wrapper"
      @scroll="checkFormWrapperScroll"
    >
      <ElForm ref="form" :model="data" :rules="rules" class="loginModal__form">
        <ElFormItem
          :class="{ 'is-error': showEmailAsError }"
          :label="$t('common.loginModal.form.fields.email')"
          class="loginModal__emailFieldWrapper"
          prop="email"
        >
          <ElInput
            v-model="data.email"
            :placeholder="$t('common.loginModal.form.fields.email')"
            data-testid="loginModal__email-input"
          />
        </ElFormItem>
        <ElFormItem
          :error="
            errors.getMessage('non_field_errors', 'email_not_verified') || ''
          "
          :label="$t('common.loginModal.form.fields.password')"
          prop="password"
        >
          <PxInputPassword
            v-model="data.password"
            :label="$t('common.loginModal.form.fields.password')"
            :placeholder="$t('common.loginModal.form.fields.password')"
            data-testid="loginModal__password-input"
          />
        </ElFormItem>
        <ElFormItem>
          <div class="loginModal__remember-wrapper">
            <ElCheckbox v-model="data.remember" class="loginModal__remember">
              {{ $t("common.loginModal.form.fields.remember") }}
            </ElCheckbox>
            <PxButton
              class="el-button--link-blue loginModal__forget"
              size="small"
              type="link"
              @click="onClickPasswordRecover"
            >
              {{ $t("common.loginModal.forgetPasswordLink") }}
            </PxButton>
          </div>
        </ElFormItem>
      </ElForm>
      <PxButton
        ref="loginButton"
        :disabled="isLoadingButtonDisabled"
        :loading="loading || isDataLoading"
        class="el-button--block loginModal__login-button"
        type="primary"
        @click="onClickLogin"
      >
        {{ $t("common.loginModal.form.submitButton") }}
      </PxButton>
    </div>
  </ElDialog>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import SignInModalMixin from "@/mixins/sign-in-modal.mixin";
import {
  EAuthActions,
  IAuthState,
} from "@/modules/authentication/services/store/auth/auth-types";
import { MessageInstance } from "element-plus";

export default defineComponent({
  name: "LoginModal",

  mixins: [SignInModalMixin],

  props: {
    showClose: {
      type: Boolean,
      default: false,
    },

    visibility: {
      type: Boolean,
      default: false,
    },

    isDataLoading: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      infoMessage: null as null | MessageInstance,
    };
  },

  computed: {
    authState(): IAuthState {
      return this.$store.state.auth as IAuthState;
    },
  },

  methods: {
    async onClickLogin() {
      // remove the information message
      this.infoMessage?.close();

      await this.$store.dispatch(EAuthActions.LOGIN, {
        ...this.data,
        redirectUser: false,
      });

      await this.$nextTick();
      if (!this.authState.error) {
        this.$emit("after-login");
      }
    },
  },
});
</script>

<style lang="scss">
.loginModal {
  .el-dialog__body {
    padding: 12px 30px 30px;

    @include breakpoint-down(sm) {
      max-height: calc(100vh - 100px);
      overflow: auto;
    }
  }

  &__form {
    padding-top: 34px;
  }

  &__emailFieldWrapper {
    margin-bottom: 5px;
  }

  &__remember-wrapper {
    display: flex;
    flex-wrap: wrap;
    margin: 18px -2px 0;

    & > * {
      flex: 1 0 50%;
      padding: 0;
    }
  }

  &__forget {
    padding-top: 2px;
  }

  &__forget span {
    @include breakpoint-down(sm) {
      display: inline-block;
      text-align: left;
    }

    @include breakpoint-up(md) {
      transform: translate(30px, -5px);
    }
  }

  &__login-button {
    margin: 29px 0 2px;
  }

  &__no-account,
  &__tell-more-link {
    font-size: 14px;
    line-height: 19px;
    color: $ebony-clay;
  }

  &__no-account {
    display: inline-block;
    width: 100%;
    text-align: center;
  }

  &__tell-more-link {
    @include grotesk(semiBold);

    text-decoration: underline;
    cursor: pointer;
  }
}
</style>
