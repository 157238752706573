import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-cba45ea8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "px-switch" }
const _hoisted_2 = { class: "px-switch__toggle" }
const _hoisted_3 = ["textContent"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ElSwitch = _resolveComponent("ElSwitch")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_ElSwitch, {
        ref: "switch",
        modelValue: _ctx.isActive,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.isActive) = $event)),
        width: _ctx.width,
        onChange: _ctx.switchChangeHandler
      }, null, 8, ["modelValue", "width", "onChange"])
    ]),
    _createElementVNode("div", {
      class: "px-switch__label",
      onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.labelClickHandler && _ctx.labelClickHandler(...args)))
    }, [
      (_ctx.label)
        ? (_openBlock(), _createElementBlock("span", {
            key: 0,
            textContent: _toDisplayString(_ctx.label)
          }, null, 8, _hoisted_3))
        : _createCommentVNode("", true),
      _renderSlot(_ctx.$slots, "label", {}, undefined, true)
    ]),
    _renderSlot(_ctx.$slots, "default", {}, undefined, true)
  ]))
}