export enum EEntrepreneurAffiliateSteps {
  REVIEW = 4,
}

export enum EAffiliateSteps {
  STARTING_PAGE = 0,
  ASSESSMENT = 1,
  QUESTIONS = 2,
  TEAM_MANAGEMENT = 3,
}

export enum ESupporterAffiliateSteps {
  INTERESTS = 4,
  REVIEW = 5,
}

export interface ICategoryStepItem {
  value: number;
  label: string;
  color: string;
}
