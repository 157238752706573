<template>
  <div class="milestone-planner-for-visitors">
    <AuthenticatedWithNavigationView
      v-if="hasRetrievedMilestones"
      data-testid="milestone-for-visitors-navigation-view"
    />
    <AccessPlannerGuard
      v-else
      :requires-passcode="requiresPasscode"
      data-testid="milestone-for-visitors-access-planner-guard"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import AuthenticatedWithNavigationView from "@/modules/common/views/authenticated-with-navigation.view.vue";
import AccessPlannerGuard from "@/modules/milestone-planner/components/access-planner-guard/access-planner-guard.vue";
import { AxiosError } from "axios";
import { IMilestonePlanner } from "@/modules/milestone-planner/services/store/milestone-planner/milestone-planner.types";
import { EProfileCompanyActions } from "@/modules/profile/services/store/profile/profile-types";
import { EProfileLatestAssessmentActions } from "@/modules/profile/services/store/profile/submodules/profile-latest-assessment/profile-latest-assessment-types";

export default defineComponent({
  name: "MilestonePlannerForVisitorsView",

  components: {
    AuthenticatedWithNavigationView,
    AccessPlannerGuard,
  },

  computed: {
    hasRetrievedMilestones(): boolean {
      return !!this.$store.get("milestonePlanner/value");
    },

    milestonePlanner(): IMilestonePlanner {
      return this.$store.get("milestonePlanner/value");
    },

    milestonePlannerErrors(): null | AxiosError {
      return this.$store.get("milestonePlanner.error");
    },

    milestonePlannerErrorCode(): undefined | number {
      return this.milestonePlannerErrors?.response?.status;
    },

    requiresPasscode(): boolean {
      return this.milestonePlannerErrorCode === 403;
    },
  },

  watch: {
    milestonePlanner: {
      immediate: true,
      handler(newValue) {
        if (!newValue) {
          return;
        }
        this.fetchCompanyOfMilestonePlanner();
        this.fetchLatestAssessmentOfMilestonePlanner();
      },
    },
  },

  methods: {
    fetchCompanyOfMilestonePlanner() {
      this.$store.dispatch(
        EProfileCompanyActions.FETCH,
        this.milestonePlanner.company,
        {
          root: true,
        },
      );
    },

    fetchLatestAssessmentOfMilestonePlanner() {
      this.$store.dispatch(
        EProfileLatestAssessmentActions.FETCH,
        this.milestonePlanner.company,
        {
          root: true,
        },
      );
    },
  },
});
</script>
