import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-ebbbb178"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "auth-base page-full-height" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PxTabBar = _resolveComponent("PxTabBar")!
  const _component_PxNavbar = _resolveComponent("PxNavbar")!
  const _component_RouterView = _resolveComponent("RouterView")!
  const _component_PxFooter = _resolveComponent("PxFooter")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.$screen.mdDown)
      ? (_openBlock(), _createBlock(_component_PxTabBar, { key: 0 }))
      : (_openBlock(), _createBlock(_component_PxNavbar, {
          key: 1,
          theme: "centered"
        })),
    _createVNode(_component_RouterView, { class: "page-body" }),
    _createVNode(_component_PxFooter)
  ]))
}