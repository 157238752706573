<template>
  <ElDialog
    ref="modal"
    v-model="innerVisibility"
    :append-to-body="true"
    :before-close="beforeCloseHandler"
    class="el-dialog--ghost-header list-options-delete-modal"
    width="500px"
  >
    <PxModalMessage :visible="hasServerError" />
    <h2 class="list-options-delete-modal__title">
      {{ deleteModalCopy.title }}
    </h2>
    <p
      class="list-options-delete-modal__description"
      v-text="deleteModalCopy.subtitle"
    />
    <template #footer>
      <PxButton
        class="list-options-delete-modal__cancel-btn"
        size="small"
        type="link"
        @click="onClickCancelButton"
      >
        {{ $t("common.cancel") }}
      </PxButton>
      <PxButton
        :loading="isLoading"
        class="list-options-delete-modal__submit-btn"
        type="red"
        @click="onClickDeleteListButton"
      >
        {{ deleteModalCopy.button }}
      </PxButton>
    </template>
  </ElDialog>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import ElDialogMixin from "@/mixins/el-dialog.mixin";

export default defineComponent({
  name: "ListOptionsDeleteModal",

  mixins: [ElDialogMixin],

  props: {
    hasServerError: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    deleteModalCopy() {
      return this.$tm("companyLists.list.deleteModal") as {
        title: string;
        subtitle: string;
        button: string;
      };
    },
  },

  methods: {
    updateClosedState() {
      this.$emit("update:visibility", false);
    },
    beforeCloseHandler() {
      this.updateClosedState();
    },
    onClickCancelButton() {
      this.updateClosedState();
    },
    onClickDeleteListButton() {
      this.$emit("delete-list");
    },
  },
});
</script>

<style lang="scss">
.list-options-delete-modal {
  /* Override no header default styles */
  margin-top: 15vh !important;

  .el-dialog__body {
    max-width: 410px;
    padding-bottom: 14px;
    margin: auto;
  }

  .el-dialog__footer {
    position: relative;
    padding-top: 16px;
    padding-bottom: 17px;

    &::after {
      position: absolute;
      top: -1px;
      right: 40px;
      left: 40px;
      height: 1px;

      content: "";
      background-color: $athens-gray;

      /* Hide line separator instead of removing since it might be useful in the future */
      opacity: 0;
    }

    @include breakpoint-up(md) {
      display: flex;
      justify-content: center;
    }
  }
}

.list-options-delete-modal__title {
  @include grotesk(semiBold);

  margin-bottom: 25px;
  font-size: 18px;
  line-height: 28px;
  color: $ebony-clay;
  text-align: center;

  @include breakpoint-up(md) {
    font-size: 20px;
  }
}

.list-options-delete-modal__description {
  margin-bottom: 17px;
  font-size: 13px;
  line-height: 22px;
  color: $ebony-clay;
  text-align: center;
  letter-spacing: -0.3px;

  @include breakpoint-up(md) {
    font-size: 15px;
    white-space: pre-line;
  }
}

.list-options-delete-modal__cancel-btn {
  @include breakpoint-up(md) {
    position: relative;
    left: 1px;
    padding-bottom: 10px;
  }
}

.list-options-delete-modal__submit-btn {
  padding-right: 16px;
  padding-left: 15px;
  // Override default
  margin-left: 16px !important;

  span {
    top: 0;
    font-size: 13px;
    letter-spacing: -0.1px;
  }
}
</style>
