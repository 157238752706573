import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, mergeProps as _mergeProps, normalizeClass as _normalizeClass, Fragment as _Fragment, resolveDirective as _resolveDirective, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-2e06a1e7"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "account-settings page-container" }
const _hoisted_2 = { class: "account-settings__navigation" }
const _hoisted_3 = {
  key: 0,
  class: "account-settings__account-info-panel"
}
const _hoisted_4 = {
  key: 1,
  class: "account-settings__subscription-panel",
  "element-loading-background": "#fff"
}
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { class: "account-settings__card-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AccountSettingsNavigation = _resolveComponent("AccountSettingsNavigation")!
  const _component_AccountSettingsPassword = _resolveComponent("AccountSettingsPassword")!
  const _component_AccountSettingsEmail = _resolveComponent("AccountSettingsEmail")!
  const _component_PxButton = _resolveComponent("PxButton")!
  const _component_PricingSwitch = _resolveComponent("PricingSwitch")!
  const _component_PricingCard = _resolveComponent("PricingCard")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_AccountSettingsNavigation, {
        "selected-tab": _ctx.selectedTab,
        "onUpdate:selectedTab": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedTab) = $event))
      }, null, 8, ["selected-tab"])
    ]),
    (_ctx.showAccountInfoPanel)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode(_component_AccountSettingsPassword),
          _createVNode(_component_AccountSettingsEmail)
        ]))
      : _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_4, [
          (_ctx.subscription)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                _createElementVNode("header", {
                  class: _normalizeClass({
            'account-settings__legacy-plan': _ctx.isOnLegacyPlan,
          })
                }, [
                  _createElementVNode("div", null, [
                    _createElementVNode("h1", null, _toDisplayString(_ctx.settingsCopy.title), 1),
                    _createElementVNode("p", null, _toDisplayString(_ctx.settingsCopy.description), 1)
                  ]),
                  _createVNode(_component_PxButton, _mergeProps({ class: "account-settings__cta" }, _ctx.subscriptionCta, { onClick: _ctx.ctaAction }), null, 16, ["onClick"])
                ], 2),
                _createElementVNode("div", {
                  class: _normalizeClass([{
            'account-settings__pricing-switch-legacy': _ctx.isOnLegacyPlan,
          }, "account-settings__pricing-switch"])
                }, [
                  (_ctx.isOnLegacyPlan)
                    ? (_openBlock(), _createElementBlock("h2", _hoisted_5, _toDisplayString(_ctx.$t("profile.accountSettingsPage.legacyPlan.availablePlans")), 1))
                    : _createCommentVNode("", true),
                  _createVNode(_component_PricingSwitch, {
                    modelValue: _ctx.yearly,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.yearly) = $event))
                  }, null, 8, ["modelValue"])
                ], 2),
                _createElementVNode("div", _hoisted_6, [
                  _createVNode(_component_PricingCard, _mergeProps(_ctx.freePlanCardProps, {
                    onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.changeSubscription(_ctx.plans.FREE)))
                  }), null, 16),
                  _createVNode(_component_PricingCard, _mergeProps(_ctx.proPlanCardProps, {
                    onClick: _cache[3] || (_cache[3] = ($event: any) => (
              _ctx.changeSubscription(_ctx.yearly ? _ctx.plans.PRO_YEARLY : _ctx.plans.PRO_MONTHLY)
            ))
                  }), null, 16)
                ])
              ], 64))
            : _createCommentVNode("", true)
        ])), [
          [_directive_loading, !_ctx.subscription]
        ])
  ]))
}