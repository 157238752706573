import { resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-3cc9d5a0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "list-share-users" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PxModalMessage = _resolveComponent("PxModalMessage")!
  const _component_ListShareUsersSearch = _resolveComponent("ListShareUsersSearch")!
  const _component_ListShareUsersIndex = _resolveComponent("ListShareUsersIndex")!
  const _component_ListShareActions = _resolveComponent("ListShareActions")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_PxModalMessage, {
      visible: _ctx.listHasBeenShared,
      "onUpdate:visible": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.listHasBeenShared) = $event)),
      timeout: 5000,
      title: _ctx.successShareMessage,
      type: "success"
    }, null, 8, ["visible", "title"]),
    _createVNode(_component_ListShareUsersSearch, {
      "has-saved-users": _ctx.listHasBeenShared,
      "onUpdate:hasSavedUsers": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.listHasBeenShared) = $event)),
      users: _ctx.users,
      class: "list-share-users__header",
      onSendEmail: _ctx.sendEmailHandler
    }, null, 8, ["has-saved-users", "users", "onSendEmail"]),
    _withDirectives(_createVNode(_component_ListShareUsersIndex, {
      users: _ctx.users,
      class: "list-share-users__body"
    }, null, 8, ["users"]), [
      [_directive_loading, _ctx.listLoading]
    ]),
    _createVNode(_component_ListShareActions, {
      link: _ctx.link,
      passcode: _ctx.passcode,
      class: "list-share-users__footer"
    }, null, 8, ["link", "passcode"])
  ]))
}