<template>
  <ElDialog
    ref="modal"
    v-model="innerVisibility"
    :append-to-body="true"
    :before-close="beforeCloseHandler"
    :class="{ 'share-results-modal--users': !settingsVisibility }"
    :title="shareModalTitle"
    class="share-results-modal"
    width="660px"
  >
    <DialogConfirmation
      ref="dialog-confirmation"
      class="share-results-modal__overlay-confirm"
    />
    <!-- Server error message -->
    <PxModalMessage
      :visible="hasServerError"
      data-testid="share-results-modal-error-message"
    />
    <!-- Reset link message -->
    <PxModalMessage
      v-model:visible="linkWasReset"
      :timeout="5000"
      :title="$t(`capitalExplorer.shareModal.messages.linkChanged`)"
      data-testid="share-results-modal-reset-link-message"
      type="success"
    />
    <!-- Passcode changed message -->
    <PxModalMessage
      v-model:visible="passcodeWasChanged"
      :timeout="5000"
      :title="$t(`capitalExplorer.shareModal.messages.passcodeChanged`)"
      data-testid="share-results-modal-reset-passcode-message"
      type="success"
    />
    <!-- User removed message -->
    <PxModalMessage
      v-model:visible="userHasBeenRemoved"
      :timeout="5000"
      :title="successUserRemovalMessage"
      type="success"
    />
    <!-- Shared message -->
    <PxModalMessage
      v-model:visible="capitalExplorerHasBeenShared"
      :timeout="5000"
      :title="successShareMessage"
      type="success"
    />
    <template #header>
      <span class="el-dialog__title" v-text="shareModalTitle" />
    </template>
    <div class="share-results-modal__body">
      <ShareModalContent
        v-if="!settingsVisibility"
        :filter-by="supporters"
        :link="shareableLink"
        :passcode="passcode"
        :share-succeeded="capitalExplorerHasBeenShared"
        :users="users"
        actions-copy="capitalExplorer.shareModal.footer"
        data-testid="share-results-modal__users"
        users-copy="capitalExplorer.shareModal.users"
        @send-email="sendEmail"
      />
      <ShareModalSettings
        v-else
        :is-loading="capitalExplorerLoading"
        :link="shareableLink"
        :passcode="passcode"
        data-testid="share-results-modal__settings"
        passcode-copy="capitalExplorer.shareModal.settings.passcode"
        @save="saveSettingsHandler"
      />
    </div>
  </ElDialog>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import ElDialogMixin from "@/mixins/el-dialog.mixin";
import DialogConfirmationMixin from "@/mixins/dialog-confirmation.mixin";

import ShareModalContent from "@/components/share-modal/share-modal-content.vue";
import ShareModalSettings from "@/components/share-modal/share-modal-settings.vue";

import { AxiosError } from "axios";
import { SHARE_MODAL_EVENTS } from "@/components/share-modal/share-modal.types";
import { CAPITAL_EXPLORER_VISITORS_VIEW } from "@/modules/capital-explorer/services/router/routes-names";
import { ECapitalExplorerActions } from "@/modules/capital-explorer/services/store/capital-explorer/capital-explorer.types";
import { ICapitalExplorerInvitedUser } from "@/modules/capital-explorer/services/data/capital-explorer/capital-explorer.interface";
import { SUPPORTER_USER_TYPE } from "@/modules/authentication/constants";

export default defineComponent({
  name: "ShareResultsModal",

  components: {
    ShareModalContent,
    ShareModalSettings,
  },

  mixins: [ElDialogMixin, DialogConfirmationMixin],

  props: {
    /**
     * Represents the capital explorer unique id
     */
    id: {
      type: String,
      required: true,
    },

    /**
     * Represents the capital explorer unique identifiable
     * slug used for the shareable link.
     */
    uid: {
      type: String,
      required: true,
    },

    /**
     * Optional passcode where empty means
     * having no protection.
     */
    passcode: {
      type: String,
      default: "",
    },

    users: {
      type: Array as () => Array<ICapitalExplorerInvitedUser>,
      default: () => [],
    },
  },

  data() {
    return {
      settingsVisibility: false,
      hasServerError: false,
      linkWasReset: false,
      passcodeWasChanged: false,
      capitalExplorerHasBeenShared: false,
      supporters: SUPPORTER_USER_TYPE,
      usersToInvite: [] as Array<ICapitalExplorerInvitedUser["user_profile"]>,
      userToRemove: null as null | any,
      userHasBeenRemoved: false,
    };
  },

  computed: {
    shareModalTitle() {
      return this.$t("capitalExplorer.shareModal.title");
    },

    shareModalUsersCopy() {
      return this.$tm("capitalExplorer.shareModal.users") as {
        title: string;
        description: string;
        tip: string;
        empty: string;
      };
    },

    shareableLink(): string {
      const linkPath = this.$router.resolve({
        name: CAPITAL_EXPLORER_VISITORS_VIEW,
        params: { uid: this.uid },
      }).href;

      return location.origin + linkPath;
    },

    confirmationDialogs(): any {
      return [
        {
          event: SHARE_MODAL_EVENTS.UNSHARE_FROM_USER,
          key: "unshareFromUser",
          callback: () => this.onConfirmUnshare(),
        },
        {
          event: SHARE_MODAL_EVENTS.RESET_SHAREABLE_LINK,
          key: "resetLink",
          callback: () => this.onConfirmResetLink(),
        },
        {
          event: SHARE_MODAL_EVENTS.RESET_PASSCODE,
          key: "resetPasscode",
          callback: () => this.onConfirmResetPasscode(),
        },
      ];
    },

    dialogConfirmationOptions(): any {
      return this.confirmationDialogs.map((confirmation: any) => ({
        eventName: confirmation.event,
        message: this.$t(
          `capitalExplorer.shareModal.confirmationDialogs.${confirmation.key}.title`,
          {
            username: this.userToRemoveName,
          },
        ),
        tip: this.$t(
          `capitalExplorer.shareModal.confirmationDialogs.${confirmation.key}.subtitle`,
          {
            username: this.userToRemoveName,
          },
        ),
        confirmButtonText: this.$t(
          `capitalExplorer.shareModal.confirmationDialogs.${confirmation.key}.cta`,
        ),
        cancelButtonText: this.$t("common.cancel"),
        confirmFn: confirmation.callback,
      }));
    },

    capitalExplorerErrors(): AxiosError {
      return this.$store.get("capitalExplorer/error");
    },

    capitalExplorerLoading(): boolean {
      return this.$store.get("capitalExplorer/loading");
    },

    userToInviteName(): string | undefined {
      return this.users.find((user: ICapitalExplorerInvitedUser) =>
        this.usersToInvite.includes(user.user_profile),
      )?.name;
    },

    successShareMessage() {
      return this.usersToInvite.length > 1
        ? this.$t("capitalExplorer.shareModal.messages.sharedMultipleUsers")
        : this.$t(`capitalExplorer.shareModal.messages.shared`, {
            users: this.userToInviteName,
          });
    },

    successUserRemovalMessage() {
      return this.$t(`capitalExplorer.shareModal.messages.accessRemoved`, {
        user: this.userToRemoveName,
      });
    },

    userToRemoveName(): string {
      return this.userToRemove?.name || "";
    },
  },

  watch: {
    innerVisibility(isVisible) {
      // Workaround since mounted and destroyed
      // hooks are not called when modals get toggled.
      if (isVisible) {
        this.listenForGlobalSettingsEvent();
        this.listenForGlobalConfirmationEvents();
      } else {
        this.disableGlobalSettingsEvent();
        this.disableGlobalConfirmationEvents();
      }
    },
  },

  methods: {
    beforeCloseHandler() {
      this.$emit("update:visibility", false);
      this.settingsVisibility = false;
    },

    listenForGlobalSettingsEvent() {
      this.$emitter.on("toggle-settings", (visibility: boolean) => {
        this.settingsVisibility = visibility;
      });
    },

    listenForGlobalConfirmationEvents() {
      // Workaround for the dialog confirmation
      // directive to work with global events:

      this.$emitter.on(
        "share-modal-action",
        ({
          event,
          payload,
        }: {
          event: string;
          payload: { [key: string]: any };
        }) => {
          if (payload) {
            this.userToRemove =
              this.users.find(
                (user: ICapitalExplorerInvitedUser) =>
                  user.user_profile === payload.user_profile,
              ) || null;
          }
          this.showOverlay(event);
        },
      );
    },

    showOverlay(event: string) {
      const options = this.dialogConfirmationOptions.find(
        (confirmation: any) => confirmation.eventName === event,
      );

      this.showConfirmOverlay(options);
    },

    disableGlobalSettingsEvent() {
      this.$emitter.off("toggle-settings");
    },

    disableGlobalConfirmationEvents() {
      this.$emitter.off("share-modal-action");
    },

    onConfirmResetLink() {
      this.$store
        .dispatch(ECapitalExplorerActions.PATCH, {
          submissionId: this.id,
          queryParams: {
            reset: "link",
          },
        })
        .then(() => {
          if (!this.capitalExplorerErrors) {
            this.linkWasReset = true;
            return;
          }
          // Show generic error message
          this.hasServerError = true;
        });
    },

    onConfirmResetPasscode() {
      this.$store
        .dispatch(ECapitalExplorerActions.PATCH, {
          submissionId: this.id,
          queryParams: {
            reset: "passcode",
          },
        })
        .then(() => {
          if (!this.capitalExplorerErrors) {
            this.passcodeWasChanged = true;
            return;
          }
          // Show generic error message
          this.hasServerError = true;
        });
    },

    onConfirmUnshare() {
      const usersStillInvited = this.users
        .filter(
          (user) =>
            !!user?.user_profile &&
            user.user_profile !== this.userToRemove.user_profile,
        )
        .map((user) => user.user_profile);

      this.$store
        .dispatch(ECapitalExplorerActions.PATCH, {
          submissionId: this.id,
          payload: {
            invited_users: usersStillInvited,
          },
        })
        .then(() => {
          if (!this.capitalExplorerErrors) {
            this.userHasBeenRemoved = true;
            return;
          }
          // Show generic error message
          this.hasServerError = true;
        });
    },

    saveSettingsHandler(newPasscode: string | null) {
      // Disable/enable passcode restriction
      this.$store
        .dispatch(ECapitalExplorerActions.PATCH, {
          submissionId: this.id,
          payload: {
            passcode: newPasscode,
          },
        })
        .then(() => {
          if (!this.capitalExplorerErrors) {
            this.passcodeWasChanged = true;
            return;
          }
          // Show generic error message
          this.hasServerError = true;
        });
    },

    sendEmail(
      invitedUsers: Array<ICapitalExplorerInvitedUser["user_profile"]>,
    ) {
      this.usersToInvite = invitedUsers;

      const allInvitedUsers = [
        ...this.usersToInvite,
        ...this.users
          .filter((user: any) => !!user?.user_profile)
          .map((user: any) => user.user_profile),
      ];

      this.$store
        .dispatch(ECapitalExplorerActions.PATCH, {
          submissionId: this.id,
          payload: {
            invited_users: allInvitedUsers,
          },
        })
        .then(() => {
          if (!this.capitalExplorerErrors) {
            this.capitalExplorerHasBeenShared = true;
            return;
          }

          // Show generic error message
          this.$message({
            message: this.$t("common.errors.global.alertTitle"),
            type: "error",
            customClass: "is-full",
          });
        });
    },
  },
});
</script>

<style lang="scss">
.share-results-modal {
  &.el-dialog {
    overflow-y: initial !important;
  }

  .el-dialog__header {
    @include breakpoint-up(md) {
      padding: 12px 56px 15px 30px;
    }
  }

  .el-dialog__headerbtn {
    right: 27px;
    top: 24px;
  }

  .el-dialog__title {
    font-size: 22px;
    letter-spacing: -0.33px;
  }

  .el-dialog__subtitle {
    margin-left: 6px;
    letter-spacing: -0.35px;
    vertical-align: 2px;

    &::before,
    &::after {
      content: '"';
    }
  }

  &__overlay-confirm.overlay-confirm {
    // modal header height
    top: 65px;

    .overlay-confirm__button {
      position: relative;
      top: 1px;
    }
  }

  &__overlay-confirm.overlay-confirm .overlay-confirm__content {
    max-width: 574px;
    padding: 2px 41px 55px 40px;
    top: 157px;

    transform: (translate(-50%, 0));

    h2 {
      margin-bottom: 18px;
      line-height: 29px;
    }

    h2 + p {
      margin-top: 0;
      margin-left: 3px;
      margin-bottom: 30px;
      letter-spacing: -0.3px;
    }

    .el-button {
      padding: 0 16px 0 17px;
      margin-left: 0;
      position: relative;
      top: 1px;
      left: 0;
    }

    .el-button--link {
      padding: 0;
      margin-right: 15px;
    }

    .el-button.el-button--link span {
      font-size: 14px;
    }
  }

  .px-modal-message {
    z-index: z("navigation");
  }

  .el-dialog__body {
    height: 568px;
    overflow: hidden;
  }

  &--users .el-dialog__body {
    @include breakpoint-up(md) {
      padding: 21px 30px 0 30px;
    }
  }
}
</style>
