import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-0c46c15d"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "multi-selector__container" }
const _hoisted_2 = { class: "multi-selector__selected-container" }
const _hoisted_3 = {
  key: 0,
  class: "el-form-item__label multi-selector__title"
}
const _hoisted_4 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (!_ctx.noLabel)
        ? (_openBlock(), _createElementBlock("label", _hoisted_3, _toDisplayString(_ctx.$t(_ctx.label)), 1))
        : _createCommentVNode("", true),
      _renderSlot(_ctx.$slots, "selected", {}, undefined, true)
    ]),
    _renderSlot(_ctx.$slots, "input", {}, undefined, true),
    _createElementVNode("span", {
      class: "el-form-item__bottom-text el-form-item__tip",
      innerHTML: _ctx.$t(_ctx.tip)
    }, null, 8, _hoisted_4)
  ]))
}