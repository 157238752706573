<template>
  <ElRadioGroup :bind="$attrs">
    <slot />
  </ElRadioGroup>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "PxRadioGroup",

  mounted() {
    this.$el.addEventListener("click", (e: Event) => {
      if ((e.target as HTMLElement).classList.contains("el-radio__inner")) {
        const input = (e.target as HTMLInputElement).parentNode?.querySelector(
          "input",
        );

        if (input?.value == this.$attrs.modelValue) {
          e.preventDefault();
          this.$emit("update:modelValue", undefined);
          this.$emit("change");
        }
      }
    });
  },
});
</script>
