// TODO: Migrate this logic to store

import unionBy from "lodash/unionBy";

import { locationSearchProvider } from "@/services/data/location/location-search.provider";
import { ILocationGoogleAutocomplete } from "@/services/data/location/location-search.interface";
import { ILocationGroup } from "@/services/data/location/location-group.interface";

import {
  IMultiSelection,
  IMultiSelector,
  IMultiSelectorItem,
} from "@/components/multi-selector/multi-selector.interface";
import { ISupporter } from "@/modules/matching/services/data/matching-score/supporter.interface";
import { ILocation } from "@/services/data/location/location.interface";

export interface IGroupedLocationsPayload {
  group: ILocationGroup["id"];
  locations: Array<ILocation["id"]>;
}

export interface IMultiLocationsPayload {
  // Selected groups or multiple locations inside a group
  grouped_locations: IGroupedLocationsPayload[];

  // Single locations:
  // (add new) Selected google places addresses
  places: ILocationGoogleAutocomplete["place_id"][];
  // (update existing) Selected single locations outside of a group
  locations: IGroupedLocationsPayload["locations"];
}

/**
 * Map location groups as multi-selector items.
 * @param grouped_locations List of location groups
 * @param selected Set location groups as selected/checked
 * @param with_children Choose to include parent children
 */
export const multiSelectorMapLocationGroups = (
  grouped_locations: ILocationGroup[],
  selected = false,
  with_children = true,
): IMultiSelectorItem[] => {
  return grouped_locations.map((group) => {
    const group_item = {
      value: group.id,
      label: group.name,
      selected,
    } as IMultiSelectorItem;

    // Gather locations and filtered locations
    let locations = group.locations || [];
    if (group.filtered_locations && group.locations) {
      locations = unionBy(
        group.filtered_locations,
        group.locations,
        "formatted_address",
      );
    }

    // Locations mapping for MultiSelector
    if (locations && with_children) {
      // TEMP: Until linting configuration isn't unified.
      /* eslint-disable @typescript-eslint/no-use-before-define */
      group_item.children = multiSelectorMapLocations(
        locations,
        selected,
        group,
      );
    }

    return group_item;
  });
};

/**
 * Map locations as multi-selector items.
 * @param locations List of locations
 * @param selected Set locations as selected/checked
 * @param group Optionally include group for each sector
 */
export const multiSelectorMapLocations = (
  locations: ILocation[],
  selected = false,
  group?: ILocationGroup,
): IMultiSelectorItem[] => {
  const mapped_group = group
    ? multiSelectorMapLocationGroups([group], selected, false)[0]
    : null;
  const mapped_locations = locations.map((location) => {
    const location_item = {
      value: location.id,
      label: location.formatted_address,
      selected,
    } as IMultiSelectorItem;

    if (mapped_group) {
      // Set parent from specified group
      location_item.parent = mapped_group;
    } else if (location.groups && location.groups.length) {
      // Or fallback to location's group
      location_item.parent = multiSelectorMapLocationGroups(
        location.groups,
        selected,
        false,
      )[0];
    }

    return location_item;
  });

  if (mapped_group) {
    // Remove any duplicate location, that is a location group
    return mapped_locations.filter(
      (child: IMultiSelectorItem) => child.label !== mapped_group.label,
    );
  }

  return mapped_locations;
};

/**
 * Map google places as multi-selector items.
 * @param places List of places
 */
export const multiSelectorMapGooglePlaces = (
  places: ILocationGoogleAutocomplete[],
): IMultiSelectorItem[] => {
  return places.map(
    (location) =>
      ({
        value: location.place_id,
        label: location.description,
      }) as IMultiSelectorItem,
  );
};

/**
 * Search for google places or grouped locations
 * with results mapped for the multi-selector.
 *
 * @param options Search keyword (filter)
 */
export const multiSelectorLocationsProvider = async (options: {
  filter: string;
}): Promise<IMultiSelector | null> => {
  try {
    const data = await locationSearchProvider.search(options);

    // Location mapping for MultiSelector
    const grouped_locations = multiSelectorMapLocationGroups(
      data.grouped_locations,
    );
    let locations = multiSelectorMapGooglePlaces(data.locations);

    // Remove google places duplicates of grouped locations
    locations = locations.filter((location) => {
      return grouped_locations.every(
        (grouped_location) =>
          grouped_location.label !== location.label &&
          grouped_location.children &&
          grouped_location.children.every(
            (child) => child.label !== location.label,
          ),
      );
    });

    return {
      results: [...grouped_locations, ...locations],
    };
  } catch {
    // If provider fails, return null
    return null;
  }
};

/**
 * Format selection from multi-selector
 * to match API payload schema that
 * separates the selection in two:
 * locations - selected group or multiple locations inside a group
 * addresses - selected a google place address
 *
 * @param multiselection List of multi selected options
 * @returns Payload for adding/updating sector and grouped sectors
 */
export const formatMultiLocationsSelection = (
  multiselection: Partial<IMultiSelection>[],
): IMultiLocationsPayload => {
  const payload: IMultiLocationsPayload = {
    grouped_locations: [],
    locations: [],
    places: [],
  };

  (multiselection || []).forEach((selection) => {
    const children =
      selection.selected_children || selection.selected_parent?.children || [];
    const parent_group = selection.selected_parent
      ? selection.selected_parent
      : children.length && children[0] && children[0].parent
        ? children[0].parent
        : null;

    if (parent_group && children.length) {
      const grouped_locations = {
        group: parent_group.value as number,
        locations: children.map((child) => child.value as number),
      };

      // Populate grouped locations
      if (payload.grouped_locations) {
        payload.grouped_locations.push(grouped_locations);
      } else {
        payload.grouped_locations = [grouped_locations];
      }
    } else if (
      selection.selected_parent &&
      typeof selection.selected_parent.value === "string"
    ) {
      // Otherwise, it's a google place address
      const google_place_id = selection.selected_parent.value as string;
      payload.places = payload.places
        ? [...payload.places, google_place_id]
        : [google_place_id];
    } else {
      // Or a single existing location
      const location_values = selection.selected_children
        ? selection.selected_children.map((location) => location.value)
        : [];
      payload.locations = payload.locations
        ? [...payload.locations, ...(location_values as number[])]
        : (location_values as number[]);
    }
  });

  return payload;
};

/**
 * Format supporter sectors and grouped sectors
 * to feed the multi-selector.
 *
 * @param supporter
 */
export const formatSupporterLocations = (
  supporter: ISupporter,
): Partial<IMultiSelection>[] => {
  const multiSelection: Partial<IMultiSelection>[] = [];

  if (supporter.grouped_locations) {
    // Adjust supporter locations structure to be mapped for multi-selector
    const location_groups = supporter.grouped_locations.map(
      (grouped_location) => ({
        ...grouped_location.group,
        locations: grouped_location.locations,
      }),
    ) as ILocationGroup[];

    const mapped_location_groups = multiSelectorMapLocationGroups(
      location_groups,
      true,
    );

    // Add supporter groups as multi-selector parents
    mapped_location_groups.forEach((location_group) => {
      multiSelection.push({
        selected_parent: location_group,
      });
    });
  }

  if (supporter.locations) {
    // Map locations for multi-selector
    const mapped_locations = multiSelectorMapLocations(
      supporter.locations,
      true,
    );

    // Add supporter locations as multi-selector children
    mapped_locations.forEach((locations) => {
      multiSelection.push({
        selected_children: [locations],
      });
    });
  }

  return multiSelection;
};
