<template>
  <div class="profile-panel__row assessment-level">
    <div class="profile-headline">
      <h2 class="profile-headline__title">
        {{ $t("profile.assessment.levelPanel.title") }}
      </h2>
      <PxButton
        v-if="allowLevelUpdate"
        :disabled="!$features.isEnabled('updateLevel')"
        class="profile-headline__updateBtn"
        size="small"
        @click="onClickUpdateLevelBtn"
      >
        {{ $t("profile.assessment.update") }}
      </PxButton>
    </div>
    <img
      class="assessment-level__indicator assessment-level__indicator--disabled"
      src="/img/placeholders/level.svg"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "AssessmentPanelLevelInactive",

  props: {
    allowLevelUpdate: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    onClickUpdateLevelBtn() {
      this.$emit("request-new-assessment");
    },
  },
});
</script>

<style lang="scss" scoped>
.assessment-level {
  padding: 8px 4px;
  text-align: center;
}

.assessment-level__indicator {
  display: block;
  margin: 5px auto 0;

  &--disabled {
    height: 173px;
    opacity: 0.7;
  }
}

.profile-headline__updateBtn {
  position: relative;
  top: -4px;
}
</style>
