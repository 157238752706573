import { getCompaniesCountForSpecificLabels } from "@/modules/charts-dashboard/utils/count-companies-with-label";
import {
  IListReportsDemographicData,
  IDemographicDataResponse,
  IDemographicDataCompany,
} from "@/modules/charts-dashboard/services/data/lists-reports-data/lists-reports-data.interface";
import {
  IProcessReportsDemographicData,
  IProcessReportsChartData,
} from "@/modules/charts-dashboard/services/data/process-reports-data/process-reports-data.interface";

export const formatCompaniesGenderData = (
  data: IListReportsDemographicData | IProcessReportsDemographicData,
): IDemographicDataResponse[] => {
  const companiesWithGenderResponses = data.companies.filter(
    (company: IDemographicDataCompany) => company.responses.length,
  );

  const possibleResponses = data.responses.map((response) => response.value);

  const genderCounts = getCompaniesCountForSpecificLabels(
    companiesWithGenderResponses,
    possibleResponses,
  );

  const totalCompaniesWithSingleGender = Object.values(genderCounts).reduce(
    (acc, count) => acc + count,
    0,
  );

  const mixed =
    companiesWithGenderResponses.length - totalCompaniesWithSingleGender;

  const formattedLabels = possibleResponses.map((response) => {
    const lowercaseResponse = response.toLowerCase();

    const mappedResponses = {
      man: "Men",
      woman: "Women",
    };

    for (const [key, value] of Object.entries(mappedResponses)) {
      if (lowercaseResponse === key) {
        return `${value} led`;
      }
    }

    if (!response.toLowerCase().includes("prefer not to say")) {
      return `${response} led`;
    }

    return response;
  });

  formattedLabels.push("Mixed gender leadership");

  const chartValues = [...Object.values(genderCounts), mixed];

  const aggregatedData = [] as IDemographicDataResponse[];

  for (let i = 0; i < chartValues.length; i++) {
    aggregatedData.push({
      value: formattedLabels[i] as string,
      count: chartValues[i],
      percentage: chartValues[i]
        ? chartValues[i] / companiesWithGenderResponses.length
        : 0,
    });
  }

  return aggregatedData;
};

export const formatProcessReportsGenderData = (
  data: IProcessReportsDemographicData[],
): IProcessReportsChartData[] => {
  return data.map((list: IProcessReportsDemographicData) => {
    return {
      step: list.step,
      responses: !list.is_locked ? formatCompaniesGenderData(list) : [],
      is_locked: list.is_locked,
    };
  });
};
