import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-c0db1c6a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "assessment-panel" }
const _hoisted_2 = { class: "assessment-panel__title-section" }
const _hoisted_3 = { class: "assessment-panel__title-section assessment-panel__title-section-grid" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { class: "assessment-panel__title-section" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PxButton = _resolveComponent("PxButton")!
  const _component_AssessmentPanelLevelInactive = _resolveComponent("AssessmentPanelLevelInactive")!
  const _component_AssessmentPanelLevel = _resolveComponent("AssessmentPanelLevel")!
  const _component_PxPanel = _resolveComponent("PxPanel")!
  const _component_AssessmentPanelGridInactive = _resolveComponent("AssessmentPanelGridInactive")!
  const _component_AssessmentPanelGrid = _resolveComponent("AssessmentPanelGrid")!
  const _component_AssessmentPanelHistoryInactive = _resolveComponent("AssessmentPanelHistoryInactive")!
  const _component_AssessmentPanelHistory = _resolveComponent("AssessmentPanelHistory")!
  const _component_RenderlessAssessmentPanel = _resolveComponent("RenderlessAssessmentPanel")!

  return (_openBlock(), _createBlock(_component_RenderlessAssessmentPanel, null, {
    default: _withCtx((scope) => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("span", null, _toDisplayString(_ctx.$t("profile.assessment.levelPanel.title")), 1),
          (_ctx.$user.isOwner())
            ? (_openBlock(), _createBlock(_component_PxButton, {
                key: 0,
                class: "profile-headline__updateBtn",
                size: "small",
                onClick: scope.makeNewAssessment
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t("profile.assessment.update")), 1)
                ]),
                _: 2
              }, 1032, ["onClick"]))
            : _createCommentVNode("", true)
        ]),
        _createVNode(_component_PxPanel, { class: "assessment-panel__panel px-panel--xs-no-side-borders" }, {
          default: _withCtx(() => [
            (scope.isInactive)
              ? (_openBlock(), _createBlock(_component_AssessmentPanelLevelInactive, { key: 0 }))
              : (_openBlock(), _createBlock(_component_AssessmentPanelLevel, {
                  key: 1,
                  "latest-assessment": scope.latestAssessment,
                  level: scope.latestAssessmentLevel,
                  levels: scope.levels,
                  class: "assessment-panel-level",
                  onRequestNewAssessment: scope.makeNewAssessment
                }, null, 8, ["latest-assessment", "level", "levels", "onRequestNewAssessment"]))
          ]),
          _: 2
        }, 1024),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("span", null, _toDisplayString(_ctx.$t("profile.assessment.gridPanel.title")), 1),
          (!_ctx.isHowItWorksLinkDisabled)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createVNode(_component_PxButton, {
                  class: "el-button--link el-button--link-dark el-button--small",
                  icon: "icon-information",
                  type: "link",
                  onClick: _ctx.onClickOpenHowItWorksLink
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t("profile.assessment.gridPanel.moreLink")), 1)
                  ]),
                  _: 1
                }, 8, ["onClick"])
              ]))
            : _createCommentVNode("", true)
        ]),
        _createVNode(_component_PxPanel, { class: "assessment-panel__panel px-panel--xs-no-side-borders" }, {
          default: _withCtx(() => [
            (scope.isInactive)
              ? (_openBlock(), _createBlock(_component_AssessmentPanelGridInactive, { key: 0 }))
              : (_openBlock(), _createBlock(_component_AssessmentPanelGrid, {
                  key: 1,
                  "latest-assessment": scope.latestAssessment,
                  "level-value": scope.latestAssessmentLevelValue,
                  levels: scope.levels,
                  class: "assessment-panel-grid"
                }, null, 8, ["latest-assessment", "level-value", "levels"]))
          ]),
          _: 2
        }, 1024),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("span", null, _toDisplayString(_ctx.$t("profile.assessment.historyPanel.title")), 1),
          (_ctx.$features.isEnabled('updateLevel') && _ctx.$user.isOwner())
            ? (_openBlock(), _createBlock(_component_PxButton, {
                key: 0,
                class: "profile-headline__updateBtn",
                size: "small",
                onClick: scope.makeNewAssessment
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t("profile.assessment.update")), 1)
                ]),
                _: 2
              }, 1032, ["onClick"]))
            : _createCommentVNode("", true)
        ]),
        _createVNode(_component_PxPanel, { class: "assessment-panel__panel px-panel--xs-no-side-borders" }, {
          default: _withCtx(() => [
            (scope.isInactive)
              ? (_openBlock(), _createBlock(_component_AssessmentPanelHistoryInactive, { key: 0 }))
              : (_openBlock(), _createBlock(_component_AssessmentPanelHistory, { key: 1 }))
          ]),
          _: 2
        }, 1024)
      ])
    ]),
    _: 1
  }))
}