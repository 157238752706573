import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-efeb6b3c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "affiliate-review-page" }
const _hoisted_2 = { class: "affiliate-review-page__left-panel" }
const _hoisted_3 = { class: "affiliate-review-page__left-panel-wrapper" }
const _hoisted_4 = { class: "affiliate-review-page__right-panel" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PxNavigationTab = _resolveComponent("PxNavigationTab")!
  const _component_AffiliateReviewPageSelfAssessment = _resolveComponent("AffiliateReviewPageSelfAssessment")!
  const _component_AffiliateReviewPageQuestions = _resolveComponent("AffiliateReviewPageQuestions")!
  const _component_AffiliateReviewPageTeamMembers = _resolveComponent("AffiliateReviewPageTeamMembers")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h3", null, _toDisplayString(_ctx.$t("affiliates.reviewPage.navigation.title")), 1),
        _createElementVNode("p", null, _toDisplayString(_ctx.$t("affiliates.reviewPage.navigation.subtitle")), 1)
      ]),
      _createVNode(_component_PxNavigationTab, {
        "selected-tab": _ctx.innerActiveTab,
        "onUpdate:selectedTab": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.innerActiveTab) = $event)),
        "list-format": _ctx.$screen.xlUp,
        "navigation-tabs": _ctx.visibleNavigationTabs,
        "show-number": false
      }, null, 8, ["selected-tab", "list-format", "navigation-tabs"])
    ]),
    _createElementVNode("div", _hoisted_4, [
      (_ctx.innerActiveTab === 1)
        ? (_openBlock(), _createBlock(_component_AffiliateReviewPageSelfAssessment, {
            key: 0,
            "assessment-incomplete": _ctx.isMissingAssessment,
            "level-description": _ctx.levelDescription,
            "viral-level": _ctx.viralLevel,
            onUpdate: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('go-to-page', 1)))
          }, null, 8, ["assessment-incomplete", "level-description", "viral-level"]))
        : _createCommentVNode("", true),
      (_ctx.innerActiveTab === 2)
        ? (_openBlock(), _createBlock(_component_AffiliateReviewPageQuestions, {
            key: 1,
            onGoToQuestion: _ctx.goToQuestion
          }, null, 8, ["onGoToQuestion"]))
        : _createCommentVNode("", true),
      (_ctx.innerActiveTab === 3)
        ? (_openBlock(), _createBlock(_component_AffiliateReviewPageTeamMembers, {
            key: 2,
            onGoToTeamMembers: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('go-to-page', 3)))
          }))
        : _createCommentVNode("", true)
    ])
  ]))
}