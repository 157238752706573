import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ElInput = _resolveComponent("ElInput")!

  return (_openBlock(), _createBlock(_component_ElInput, {
    modelValue: _ctx.innerValue,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.innerValue) = $event)),
    autocomplete: _ctx.autocomplete,
    class: _normalizeClass([{
      'el-input--password-visible': _ctx.isTextPasswordVisible,
      'el-input--success-state': _ctx.showSuccessState,
    }, "el-input--password"]),
    disabled: _ctx.disabled,
    "aria-label": _ctx.label,
    placeholder: _ctx.placeholder,
    type: _ctx.fieldType
  }, {
    suffix: _withCtx(() => [
      _createElementVNode("span", {
        class: _normalizeClass([{ 'px-input-password__suffix--transparent': !_ctx.hasInnerValue }, "px-input-password__suffix"]),
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onPasswordShowClick && _ctx.onPasswordShowClick(...args)))
      }, _toDisplayString(_ctx.showCopy), 3)
    ]),
    _: 1
  }, 8, ["modelValue", "autocomplete", "class", "disabled", "aria-label", "placeholder", "type"]))
}