<template>
  <div v-if="hasBadges" class="matching-badge-list__wrapper">
    <div :class="typeClass">
      <div
        v-for="(badge, index) in formattedBadges"
        :key="index"
        class="matching-badge-list__item"
        @click.stop="onBadgeClick(badge)"
      >
        <ElTooltip
          :disabled="!$screen.mdUp || isModalVisible"
          :enterable="false"
          placement="top"
          popper-class="matching-badge-list__tooltip el-abaca-tooltip"
        >
          <PxIcon
            :name="badge.icon"
            :size="badgeIconSize"
            class="matching-badge-list__icon"
          />
          <template #content>
            <div>
              <p class="matching-badge-list__tooltip-title">
                {{ badge.title }}
              </p>
              <p class="matching-badge-list__tooltip-description">
                {{ badge.shortDescription }}
              </p>
            </div>
          </template>
        </ElTooltip>
      </div>
    </div>
    <MatchingBadgeModal
      ref="matchingBadgeModal"
      v-model:items="formattedBadges"
      v-model:title="modalTitle"
      v-model:visibility="isModalVisible"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import MatchingBadgeModal from "@/modules/matching/components/matching-badge-modal/matching-badge-modal.vue";
import {
  MATCHING_BADGE_COMPLETED_QUESTION,
  MATCHING_BADGES_LIST,
  MATCHING_BADGE_MILESTONE_PLANNER,
} from "@/modules/matching/constants";
import { IMatchingBadge } from "@/modules/matching/services/data/matching-badge/matching-badge.interface";
import { ROUTE_MILESTONE_PLANNER_OVERVIEW_VISITORS } from "@/modules/milestone-planner/services/router/routes-names";

export default defineComponent({
  name: "MatchingBadgeList",
  components: { MatchingBadgeModal },
  props: {
    badges: {
      type: Array as () => IMatchingBadge[],
      default: () => [],
      validator: (value: IMatchingBadge[]) => {
        return value.every((badge: IMatchingBadge) =>
          MATCHING_BADGES_LIST.includes(badge.name),
        );
      },
    },
    companyName: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      default: "expanded",
    },
  },

  data() {
    return {
      isModalVisible: false,
    };
  },

  computed: {
    hasBadges(): boolean {
      return this.badges && !!this.badges.length;
    },

    badgeIconSize(): number {
      return this.$screen.mdUp ? 24 : 28;
    },

    typeClass(): string {
      return this.type === "compact"
        ? "matching-badge-list--compact"
        : "matching-badge-list";
    },

    modalTitle(): string {
      return this.$t("matching.matchingBadges.modalTitle", {
        companyName: this.companyName,
      }) as string;
    },

    formattedBadges(): any[] {
      return this.badges.map((badge: IMatchingBadge) => {
        let affiliatesCount = null;

        // If contains affiliate
        if (badge.name == MATCHING_BADGE_COMPLETED_QUESTION) {
          affiliatesCount = badge.meta.affiliatesCount;
        }

        return {
          name: badge.name,
          icon: this.$t(`matching.matchingBadges.${badge.name}.icon`),
          title: this.$t(`matching.matchingBadges.${badge.name}.title`),
          description: this.$t(
            `matching.matchingBadges.${badge.name}.description`,
          ),
          shortDescription: this.$tc(
            `matching.matchingBadges.${badge.name}.shortDescription`,
            affiliatesCount,
            {
              affiliatesCount,
            },
          ),
          meta: badge.meta,
        };
      });
    },
  },

  methods: {
    onBadgeClick(badge: IMatchingBadge) {
      if (badge.name === MATCHING_BADGE_COMPLETED_QUESTION) {
        return this.$emit("click");
      }

      if (badge.name === MATCHING_BADGE_MILESTONE_PLANNER) {
        const route = this.$router.resolve({
          name: ROUTE_MILESTONE_PLANNER_OVERVIEW_VISITORS,
          params: {
            uid: badge.meta.uid,
          },
        });
        return window.open(route.href, "_blank");
      }

      this.isModalVisible = true;
    },
  },
});
</script>

<style lang="scss" scoped>
.matching-badge-list {
  display: flex;
  justify-content: flex-end;

  .matching-badge-list__item {
    position: relative;
    z-index: z("floaters");
    width: 24px;
    height: 28px;

    @include breakpoint-up(md) {
      height: 24px;
    }
  }
}

.matching-badge-list .matching-badge-list__icon {
  position: absolute;
  right: 0;
  transition: filter 0.1s ease-in-out;
}

.matching-badge-list--compact {
  position: relative;
  display: flex;
  justify-content: flex-start;
  min-height: 28px;
  margin-bottom: 14px;

  @include breakpoint-up(md) {
    position: absolute;
    top: 58px;
    right: 15px;
    justify-content: flex-end;
    min-height: 24px;
    margin-bottom: 0;
    transform: translateX(3px);
  }

  .matching-badge-list__item {
    position: relative;
    z-index: z("floaters");
    width: 20px;
    height: 28px;

    @include breakpoint-up(md) {
      width: 16px;
      height: 24px;
    }
  }
}

.matching-badge-list--compact .matching-badge-list__icon {
  position: absolute;
  left: 0;
  transition: filter 0.1s ease-in-out;

  @include breakpoint-up(md) {
    right: 0;
    left: auto;
  }
}

.matching-badge-list__item:hover .matching-badge-list__icon {
  filter: brightness(0.9);
}
</style>

<style lang="scss">
.matching-badge-list__tooltip.el-abaca-tooltip {
  max-width: 340px;
  padding: 10px 15px;
  text-transform: none;
  pointer-events: none;

  /*
     * This will remove the standard behaviour of changing the cursor selector to text selector, even if the tooltips
     * remain with selectable to false
     */
  & > * {
    cursor: default;
  }

  .matching-badge-list__tooltip-title {
    @include grotesk(bold);

    font-size: 17px;
    color: $white;
    letter-spacing: 0;
    margin-bottom: 1px;
  }

  .matching-badge-list__tooltip-description {
    font-size: 14px;
    line-height: 1.74;
    color: $white;
    letter-spacing: -0.18px;
  }
}
</style>
