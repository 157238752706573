import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PxStepByStepDialogNavigation = _resolveComponent("PxStepByStepDialogNavigation")!
  const _component_ElDialog = _resolveComponent("ElDialog")!

  return (_openBlock(), _createBlock(_component_ElDialog, {
    modelValue: _ctx.innerVisibility,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.innerVisibility) = $event)),
    "close-on-click-modal": false,
    "close-on-press-escape": false,
    "show-close": true,
    class: "el-dialog--no-header el-dialog--compact-footer px-step-by-step-dialog",
    width: "740px",
    onClose: _ctx.onModalClose
  }, {
    header: _withCtx(() => [
      _renderSlot(_ctx.$slots, "title")
    ]),
    footer: _withCtx(() => [
      _createVNode(_component_PxStepByStepDialogNavigation, {
        "current-page": _ctx.currentPage,
        "page-count": _ctx.pageCount,
        onCloseDialog: _ctx.onModalClose,
        onPageChange: _ctx.onPageChange
      }, null, 8, ["current-page", "page-count", "onCloseDialog", "onPageChange"])
    ]),
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default")
    ]),
    _: 3
  }, 8, ["modelValue", "onClose"]))
}