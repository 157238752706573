import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withModifiers as _withModifiers, withCtx as _withCtx, createBlock as _createBlock, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-51a28941"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "chart-locked" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "chart-locked__wrapper" }
const _hoisted_4 = { class: "chart-locked__content" }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = {
  key: 2,
  class: "chart-locked__note"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PxIcon = _resolveComponent("PxIcon")!
  const _component_PxButton = _resolveComponent("PxButton")!
  const _component_i18n_t = _resolveComponent("i18n-t")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.content.image)
      ? (_openBlock(), _createElementBlock("img", {
          key: 0,
          class: "chart-locked__image",
          src: _ctx.content.image,
          alt: "locked state mock data"
        }, null, 8, _hoisted_2))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", null, [
        _createVNode(_component_PxIcon, {
          name: "private",
          size: "60"
        }),
        _createElementVNode("div", _hoisted_4, [
          (_ctx.content.title)
            ? (_openBlock(), _createElementBlock("h3", _hoisted_5, _toDisplayString(_ctx.$t(_ctx.content.title)), 1))
            : _createCommentVNode("", true),
          (_ctx.content.description)
            ? (_openBlock(), _createBlock(_component_i18n_t, {
                key: 1,
                keypath: `${_ctx.content.description}`,
                tag: "p",
                class: "chart-locked__description"
              }, {
                privacy: _withCtx(() => [
                  _createVNode(_component_PxButton, {
                    class: "el-button--link-blue el-button--small",
                    type: "link",
                    onClick: _withModifiers(_ctx.onClick, ["stop"])
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t("chartsDashboard.lockedState.privacy")), 1)
                    ]),
                    _: 1
                  }, 8, ["onClick"])
                ]),
                _: 1
              }, 8, ["keypath"]))
            : _createCommentVNode("", true),
          (_ctx.content.note)
            ? (_openBlock(), _createElementBlock("p", _hoisted_6, _toDisplayString(_ctx.$t(_ctx.content.note)), 1))
            : _createCommentVNode("", true)
        ])
      ])
    ])
  ]))
}