<template>
  <div class="px-corner-ribbon">
    <div v-if="!hidden" :data-label="label" class="px-corner-ribbon__label" />
    <slot />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "PxComingSoon",

  props: {
    /**
     * Label that will be presented on ribbon.
     */
    label: {
      type: String,
      default: "Coming Soon",
    },

    /**
     * Allow to hide the label, but to keep this component around
     * the child.
     */
    hidden: {
      type: Boolean,
      default: false,
    },
  },
});
</script>

<style lang="scss" scoped>
.px-corner-ribbon {
  position: relative;
  display: inline-flex;

  &__label {
    position: absolute;
    top: 0;
    left: 0;
    z-index: z("default") + 1;
    width: 100%;
    height: 100%;
    pointer-events: none;

    &::before {
      position: absolute;
      top: 9px;
      left: -7px;
      width: 7px;
      height: 7px;

      content: "";
      background-color: $ebony-clay;
      opacity: 0.5;
      transform: skewY(35deg);
    }

    &::after {
      @include grotesk(bold);

      position: absolute;
      bottom: calc(100% - 14px);
      left: -7px;
      z-index: z("default");
      padding: 4px 9px;

      font-size: to-rem(11px);
      line-height: 13px;
      color: white;
      text-transform: uppercase;

      content: attr(data-label);
      background-color: $ebony-clay;
      border-radius: 2px;
    }
  }
}

.px-corner-ribbon--center-xs .px-corner-ribbon__label {
  @include breakpoint-only("xs") {
    position: relative;
    width: 100%;
  }

  &::before {
    @include breakpoint-only("xs") {
      content: none;
    }
  }

  &::after {
    @include breakpoint-only("xs") {
      position: relative;
      right: auto;
      bottom: auto;
      left: auto;
      display: block;
      width: 100%;

      text-align: center;

      border-radius: 0;
    }
  }
}
</style>
