<template>
  <div class="matching-list-interests-mutual">
    <div class="matching-list__cards-header">
      <h3 class="matching-list__cards-headline">
        {{ matchCountTitle }}
      </h3>
      <MatchingFilters class="matching-list__cards-filters" />
    </div>
    <div
      v-if="isInterestLoading"
      v-loading="true"
      class="matching-list__loading"
    />
    <VirtualGrid
      v-else-if="hasMatchScores"
      :columns="virtualGridColumns"
      :grid-gap="$options.static.virtualGridGap"
      :item-height="$options.static.virtualRowHeight"
      :items="matchCards"
      :load-on-mount="true"
      @scroll="fetchMoreInterests"
    >
      <template #items="items">
        <MatchingCard
          v-if="items.item"
          :key="`matching-card--${items.item.index}`"
          :about="items.item.about"
          :affiliates="items.item.affiliates"
          :badges="items.item.badges"
          :company-id="items.item.id"
          :company-uid="items.item.uid"
          :level="items.item.level"
          :location="items.item.location"
          :locations="items.item.locations"
          :match-percentage="items.item.percentage"
          :name="items.item.name"
          :offerings="items.item.offerings"
          :sectors="items.item.sectors"
          :show-list-button="showListFeature"
          :thumbnail="items.item.thumbnail"
          @click="clickMatchingCardHandler(items.item.id)"
        />
      </template>
      <template #loading="loading">
        <MatchingCardPlaceholder
          :key="`virtual-list-placeholder-${loading.item}`"
        />
      </template>
    </VirtualGrid>
    <MatchingEmptyResults
      v-else
      :notice="false"
      icon="matching.matchingInterest.matchingList.mutualEmptyState.icon"
      subtitle="matching.matchingInterest.matchingList.mutualEmptyState.subtitle"
      title="matching.matchingInterest.matchingList.mutualEmptyState.title"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import MatchingCard from "@/components/matching-card/matching-card.vue";
import MatchingEmptyResults from "@/modules/matching/components/matching-empty-results/matching-empty-results.vue";
import MatchingFilters from "@/modules/matching/components/matching-filters/matching-filters.vue";
import { IMatchingScore } from "@/modules/matching/services/data/matching-score/matching-score.interface";
import MatchingListMixin from "@/mixins/matching-list.mixin";
import VirtualGrid, {
  IStatusChanger,
} from "@/modules/matching/components/virtual-grid/virtual-grid.vue";
import { EMatchingInterestActions } from "@/modules/matching/services/store/matching-interest/matching-interest.types";
import MatchingCardPlaceholder from "@/components/matching-card/matching-card-placeholder.vue";

export default defineComponent({
  name: "MatchingListInterestsMutual",

  components: {
    MatchingCard,
    MatchingEmptyResults,
    MatchingFilters,
    VirtualGrid,
    MatchingCardPlaceholder,
  },

  mixins: [MatchingListMixin],

  static: {
    virtualRowHeight: 276,
    virtualGridGap: 40,
  },

  computed: {
    matchScores(): IMatchingScore[] {
      return this.$store.state.matchingInterest.mutualMatchScores;
    },

    nextPage(): number | null {
      return this.$store.state.matchingInterest.mutualMatchScoresPage;
    },

    matchCount(): number {
      return this.$store.state.matchingInterest.mutualMatchScoresCount;
    },

    hasMatchScores(): boolean {
      return !!this.matchScores.length;
    },

    matchCountTitle(): string {
      let title = "";
      const hasOnlyOneMatch = this.matchCount === 1;

      if (hasOnlyOneMatch) {
        title = `1 ${this.$t(`common.userTypes[${this.targetUserType}]`)}`;
      } else {
        title = `${this.matchCount} ${this.$t(
          `common.userTypesPlural[${this.targetUserType}]`,
        )}`;
      }

      return title;
    },

    virtualGridColumns(): number {
      if (this.$screen.xlUp) {
        return 4;
      }

      if (this.$screen.lgUp) {
        return 3;
      }

      if (this.$screen.mdUp) {
        return 2;
      }

      return 1;
    },
  },

  methods: {
    async fetchMoreInterests($status: IStatusChanger) {
      // If not next page flagged previously, skip it
      if (!this.nextPage) {
        $status.complete();
        return;
      }

      await this.$store.dispatch(EMatchingInterestActions.FETCH_MUTUAL_SCORES, {
        page: this.nextPage,
        ...this.globalFilters,
      });

      !this.nextPage ? $status.complete() : $status.ready();
    },
  },
});
</script>

<style lang="scss" scoped>
.matching-list-interests-mutual :deep() {
  padding: 30px 15px 30px;

  @include breakpoint-up(md) {
    padding: 0;
  }
}

.matching-empty-results {
  padding-top: 30px;
  margin: 0 auto;

  @include breakpoint-up(md) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 310px;
  }
}

.matching-empty-results :deep() .px-notice {
  margin: 16px auto 0;

  @include breakpoint-up(md) {
    max-width: 470px;
  }
}

.matching-list__loading {
  height: 200px;

  @include breakpoint-up(md) {
    height: 290px;
  }
}

.matching-list__cards-header {
  position: relative;
  display: flex;
  flex-flow: row wrap;
  padding-top: 4px;
  padding-bottom: 4px;

  @include breakpoint-up(md) {
    flex-direction: column;
    padding: 19px 0 0;
  }
}

.matching-list__cards-headline {
  padding-top: 12px;
  margin: 0;
  font-size: 18px;
  font-weight: 600;

  @include breakpoint-up(md) {
    padding-top: 0;
    font-size: 20px;
    line-height: 25px;
  }

  &:only-child {
    @include breakpoint-down(md) {
      width: 290px;
    }
  }

  &:not(:only-child) {
    @include breakpoint-down(md) {
      flex: 1 1 40%;
      padding-right: 30px;
    }
  }
}
</style>
