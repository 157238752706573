import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-906e80f6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "profile-base page-full-height" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VerifyAccountBanner = _resolveComponent("VerifyAccountBanner")!
  const _component_ProfileDesktop = _resolveComponent("ProfileDesktop")!
  const _component_ProfileMobile = _resolveComponent("ProfileMobile")!
  const _component_VisitorJoinBar = _resolveComponent("VisitorJoinBar")!
  const _component_VisitorJoinBarMobile = _resolveComponent("VisitorJoinBarMobile")!
  const _component_UpgradeModal = _resolveComponent("UpgradeModal")!
  const _component_LevelUpdateModal = _resolveComponent("LevelUpdateModal")!
  const _component_VerifyAccountModal = _resolveComponent("VerifyAccountModal")!
  const _component_SubscriptionModal = _resolveComponent("SubscriptionModal")!
  const _component_ListManagementBar = _resolveComponent("ListManagementBar")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.verifyAccountBannerVisibility)
      ? (_openBlock(), _createBlock(_component_VerifyAccountBanner, {
          key: 0,
          onClose: _cache[0] || (_cache[0] = ($event: any) => (_ctx.verifyAccountBannerVisibility = false))
        }))
      : _createCommentVNode("", true),
    (_ctx.$screen.mdUp)
      ? (_openBlock(), _createBlock(_component_ProfileDesktop, {
          key: 1,
          onShowModal: _ctx.onShowListManagementModal,
          onOpenModal: _ctx.setModalVisible
        }, null, 8, ["onShowModal", "onOpenModal"]))
      : (_openBlock(), _createBlock(_component_ProfileMobile, { key: 2 })),
    (_ctx.showJoinUsBar)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 3 }, [
          (_ctx.$screen.mdUp)
            ? (_openBlock(), _createBlock(_component_VisitorJoinBar, { key: 0 }))
            : _createCommentVNode("", true),
          (_ctx.$screen.smDown)
            ? (_openBlock(), _createBlock(_component_VisitorJoinBarMobile, { key: 1 }))
            : _createCommentVNode("", true)
        ], 64))
      : _createCommentVNode("", true),
    _createVNode(_component_UpgradeModal, {
      visibility: _ctx.isUpgradeModalVisibility,
      "onUpdate:visibility": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.isUpgradeModalVisibility) = $event)),
      "is-level-up": _ctx.isLevelUp,
      onClose: _ctx.cleanQuickLevelMeta
    }, null, 8, ["visibility", "is-level-up", "onClose"]),
    (_ctx.showLevelUpdateModal)
      ? (_openBlock(), _createBlock(_component_LevelUpdateModal, {
          key: 4,
          visibility: _ctx.showLevelUpdateModal
        }, null, 8, ["visibility"]))
      : _createCommentVNode("", true),
    (_ctx.needsToVerifyAccount && _ctx.$user.isEntrepreneur())
      ? (_openBlock(), _createBlock(_component_VerifyAccountModal, {
          key: 5,
          visibility: _ctx.needsToVerifyAccount
        }, null, 8, ["visibility"]))
      : _createCommentVNode("", true),
    (_ctx.subscriptionModalVisibility)
      ? (_openBlock(), _createBlock(_component_SubscriptionModal, {
          key: 6,
          visibility: _ctx.subscriptionModalVisibility,
          "onUpdate:visibility": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.subscriptionModalVisibility) = $event))
        }, null, 8, ["visibility"]))
      : _createCommentVNode("", true),
    _createVNode(_component_ListManagementBar)
  ]))
}