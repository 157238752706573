import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-35021dd7"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "submission-tab-empty" }
const _hoisted_2 = { class: "submission-tab-empty__title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PxIcon = _resolveComponent("PxIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass([[`submission-tab-empty__icon--${_ctx.icon}`], "submission-tab-empty__icon"])
    }, [
      _createVNode(_component_PxIcon, {
        name: _ctx.icon,
        size: _ctx.iconSize
      }, null, 8, ["name", "size"])
    ], 2),
    _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.$t(_ctx.title)), 1)
  ]))
}