<template>
  <div class="process-reports">
    <header>
      <div class="process-reports__select">
        <p>{{ $t(`chartsDashboard.select[1]`) }}</p>
        <ElTooltip
          v-if="showProcessTooltip"
          placement="top"
          :enterable="false"
          popper-class="process-reports__tooltip-popper el-abaca-tooltip"
          data-testid="process-reports-tooltip"
        >
          <template #content>
            {{ tooltipText }}
          </template>
          <PxIcon :size="17" name="question" />
        </ElTooltip>
        <ElSelect
          v-model="selectedProcessUid"
          filterable
          default-first-option
          popper-class="process-reports__select-list"
          :disabled="selectDisabled"
          data-testid="process-reports-select"
        >
          <ElOption
            v-for="option in userProcesses"
            :key="option.uid"
            :value="option.uid"
            :label="option.title"
          />
        </ElSelect>
      </div>
    </header>
    <div
      v-loading="loading"
      element-loading-background="#fafafa"
      class="process-reports__content"
      :class="{
        'process-reports__loading': loading,
      }"
    >
      <template v-if="!loading">
        <DemographicCharts
          :theme="theme"
          :process-reports-data="data"
          :view="view"
        />
        <template v-if="showEmptyState">
          <RequestProcessesModal v-model:visibility="showEmptyState" />
          <div class="process-reports__overlay"></div>
        </template>
      </template>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import DemographicCharts from "@/modules/charts-dashboard/components/demographic-charts/demographic-charts.vue";
import RequestProcessesModal from "@/modules/charts-dashboard/components/common/request-processes-modal/request-processes-modal.vue";

import { IReportsViewThemes } from "./charts-dashboard.view.vue";
import {
  IProcessData,
  IProcessReportsDemographicData,
} from "../services/data/process-reports-data/process-reports-data.interface";
import { PROCESS_REPORTS_MOCK_DATA } from "./supporter-free-chart-mock-data";
import { processReportsDataProvider } from "../services/data/process-reports-data/process-reports-data.provider";
import { EDashboardPages } from "./charts-dashboard.interface";

export default defineComponent({
  name: "ProcessReportsView",

  components: {
    DemographicCharts,
    RequestProcessesModal,
  },

  props: {
    theme: {
      type: Object as () => IReportsViewThemes,
      required: true,
    },
  },

  data() {
    return {
      data: [] as IProcessReportsDemographicData[],
      loading: false,
      selectedProcessUid: "" as string,
      userProcesses: [] as IProcessData[],
      view: EDashboardPages.PROCESS_REPORTS,
      showEmptyState: false,
    };
  },

  computed: {
    questionSlug(): string {
      return this.theme?.questionSlug || "";
    },

    isFreeUser(): boolean {
      return !this.$user.isProUser();
    },

    tooltipText(): string {
      return this.$t("chartsDashboard.processTooltip") as string;
    },

    showProcessTooltip(): boolean {
      return this.userProcesses?.length === 1;
    },

    selectDisabled(): boolean {
      return this.isFreeUser || this.userProcesses.length <= 1 || this.loading;
    },
  },

  watch: {
    selectedProcessUid: {
      async handler() {
        await this.fetchDemographicData();
      },
    },

    theme: {
      async handler(newValue, oldValue) {
        if (newValue === oldValue) {
          return;
        }

        await this.fetchDemographicData();
      },
    },

    loading(newValue, oldValue) {
      if (newValue === oldValue) return;

      this.$emit("loading", newValue);
    },
  },

  async created() {
    // If it's free user we show mock data
    if (this.isFreeUser) {
      this.data = PROCESS_REPORTS_MOCK_DATA;
      return;
    }

    this.loading = true;

    await this.setupViewInitialData();
  },

  unmounted() {
    this.$emit("show-mock-data", false);
  },

  methods: {
    async setupViewInitialData() {
      await this.fetchProcesses();

      if (!this.userProcesses.length) {
        this.data = PROCESS_REPORTS_MOCK_DATA;
        this.loading = false;
        this.showEmptyState = true;
        this.$emit("show-mock-data", true);
        return;
      }

      this.$emit("show-mock-data", false);

      this.selectedProcessUid = this.userProcesses[0].uid;
    },

    async fetchDemographicData() {
      if (!this.selectedProcessUid) return;

      this.loading = true;

      this.data = await processReportsDataProvider.getDemographicStats(
        this.selectedProcessUid,
        this.questionSlug,
      );

      this.loading = false;
    },

    async fetchProcesses() {
      this.userProcesses = await processReportsDataProvider.fetch();
    },
  },
});
</script>

<style lang="scss" scoped>
.process-reports {
  header {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    margin-bottom: 40px;

    @include breakpoint-down(lg) {
      flex-direction: column;
      align-items: flex-start;
      gap: 20px;
    }
  }

  &__loading {
    min-height: 600px;
  }

  &__select {
    display: flex;
    align-items: center;
    gap: 16px;

    p {
      margin: 1px 0 0;

      text-transform: uppercase;
      font-size: 12px;
      font-weight: 600;
      line-height: 14px;
      letter-spacing: -0.2px;
    }

    :deep(.el-select) {
      width: 310px;
      top: 1px;
    }

    :deep(.el-select__placeholder span) {
      color: $manatee;
    }

    :deep(.el-select__wrapper) {
      background-image: linear-gradient(to bottom, $white, #f6f6f6);

      font-size: 14px;
      font-weight: 400;
      line-height: 20px;

      text-overflow: ellipsis;
      letter-spacing: -0.4px;
      padding: 4px 16px 4px 15px;
    }
  }

  &__overlay {
    background-color: rgba(250, 250, 250, 0.8);
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: z("floaters");
  }
}
</style>

<style lang="scss">
.process-reports {
  &__select-list {
    .el-select-dropdown__item.is-selected span {
      font-weight: 600;
    }
  }

  &__tooltip-popper {
    width: 340px;
    text-transform: none;
    padding: 10px 15px;
    letter-spacing: -0.2px;
  }
}
</style>
