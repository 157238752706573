import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PxButton = _resolveComponent("PxButton")!
  const _component_i18n_t = _resolveComponent("i18n-t")!
  const _component_PxModalMessage = _resolveComponent("PxModalMessage")!
  const _component_ElInput = _resolveComponent("ElInput")!
  const _component_ElFormItem = _resolveComponent("ElFormItem")!
  const _component_ElForm = _resolveComponent("ElForm")!
  const _component_ElDialog = _resolveComponent("ElDialog")!

  return (_openBlock(), _createBlock(_component_ElDialog, {
    ref: "modal",
    modelValue: _ctx.innerVisibility,
    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.innerVisibility) = $event)),
    "append-to-body": true,
    "close-on-click-modal": false,
    "close-on-press-escape": false,
    "show-close": false,
    title: _ctx.$t(_ctx.title),
    class: "guest-access-modal",
    width: "466px"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_PxModalMessage, {
        center: false,
        "show-icon": false,
        visible: true,
        class: "guest-access-modal__modal-message",
        type: "info"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_i18n_t, {
            class: "guest-access-modal__login",
            keypath: "companyLists.accessModal.haveAnAccount",
            tag: "span"
          }, {
            link: _withCtx(() => [
              _createVNode(_component_PxButton, {
                ref: "loginButton",
                class: "el-button--link el-button--small guest-access-modal__login-button",
                type: "link",
                onClick: _ctx.goToLogin
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t("companyLists.accessModal.haveAnAccountLink")), 1)
                ]),
                _: 1
              }, 8, ["onClick"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_ElForm, {
        ref: "guestForm",
        model: _ctx.guestForm.data,
        rules: _ctx.rules
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", null, [
            _createVNode(_component_ElFormItem, {
              label: _ctx.$t('companyLists.accessModal.form.fields.name'),
              class: "guest-access-modal__name-field",
              prop: "name"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ElInput, {
                  modelValue: _ctx.guestForm.data.name,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.guestForm.data.name) = $event)),
                  placeholder: _ctx.$t('companyLists.accessModal.form.placeholders.name'),
                  type: "text"
                }, null, 8, ["modelValue", "placeholder"])
              ]),
              _: 1
            }, 8, ["label"]),
            _createVNode(_component_ElFormItem, {
              label: _ctx.$t('companyLists.accessModal.form.fields.email'),
              class: "guest-access-modal__email-field",
              prop: "email"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ElInput, {
                  modelValue: _ctx.guestForm.data.email,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.guestForm.data.email) = $event)),
                  placeholder: 
              _ctx.$t('companyLists.accessModal.form.placeholders.email')
            ,
                  type: "email"
                }, null, 8, ["modelValue", "placeholder"])
              ]),
              _: 1
            }, 8, ["label"])
          ]),
          _createVNode(_component_PxButton, {
            ref: "submitButton",
            loading: _ctx.loading,
            class: "el-button--block guest-access-modal__submit-button",
            type: "primary",
            onClick: _ctx.makeRequest
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t(_ctx.ctaText)), 1)
            ]),
            _: 1
          }, 8, ["loading", "onClick"])
        ]),
        _: 1
      }, 8, ["model", "rules"])
    ]),
    _: 1
  }, 8, ["modelValue", "title"]))
}