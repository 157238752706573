import { IMilestonePlanner } from "@/modules/milestone-planner/services/store/milestone-planner/milestone-planner.types";
import { IBaseState } from "@/services/store/store-types";

/**
 * Interface that represents the store structure for the
 * milestone planners module for the authenticated user.
 */
export interface IAuthMilestonePlannersState extends IBaseState {
  data: IMilestonePlanner[];
  defaultPlanner: null | IMilestonePlanner;
}

export enum EAuthMilestonePlannersActions {
  FETCH = "auth/milestonePlanners/fetch",
  FETCH_DEFAULT_PLANNER = "auth/milestonePlanners/fetchDefaultPlanner",
  PATCH = "auth/milestonePlanners/patch",
}

export enum EAuthMilestonePlannersMutations {
  SET_LOADING = "SET_LOADING",
  SET_ERROR = "SET_ERROR",
  SET_DATA = "SET_DATA",
  SET_DEFAULT_PLANNER = "SET_DEFAULT_PLANNER",
}
