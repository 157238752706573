import {
  GenericProvider,
  EProviderFeatures,
  Provider,
} from "@/services/data/generic-provider";
import { IViralLevelRange } from "@/modules/supporters/services/data/viral-level-range/viral-level-range.interface";
import { baseAPIUrl } from "@/services/utils/utils";

@Provider(`${baseAPIUrl}/request-viral-level-range`, [EProviderFeatures.CREATE])
class ViralLevelRangeProvider extends GenericProvider<IViralLevelRange> {}

export const viralLevelRangeProvider = new ViralLevelRangeProvider();
