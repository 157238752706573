import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-38292218"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "px-questionary-placeholder__criteria"
}
const _hoisted_2 = { class: "px-questionary-placeholder__footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ContentPlaceholdersHeading = _resolveComponent("ContentPlaceholdersHeading")!
  const _component_ContentPlaceholdersImg = _resolveComponent("ContentPlaceholdersImg")!
  const _component_ContentPlaceholdersText = _resolveComponent("ContentPlaceholdersText")!
  const _component_ContentPlaceholders = _resolveComponent("ContentPlaceholders")!

  return (_openBlock(), _createBlock(_component_ContentPlaceholders, {
    rounded: true,
    class: "px-questionary-placeholder"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_ContentPlaceholdersHeading),
      _createVNode(_component_ContentPlaceholdersImg, { class: "px-questionary-placeholder__options" }),
      (_ctx.$user.isSupporter())
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(_component_ContentPlaceholdersText, { lines: 2 }),
            _createVNode(_component_ContentPlaceholdersImg)
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_ContentPlaceholdersImg)
      ])
    ]),
    _: 1
  }))
}