import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-0f05e0ea"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "starting-point" }
const _hoisted_2 = { class: "starting-point-panel__title" }
const _hoisted_3 = { class: "starting-point-panel__content" }
const _hoisted_4 = { class: "starting-point__feature" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LangSwitcher = _resolveComponent("LangSwitcher")!
  const _component_PxButton = _resolveComponent("PxButton")!
  const _component_PxPanel = _resolveComponent("PxPanel")!
  const _component_FeatureCard = _resolveComponent("FeatureCard")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_LangSwitcher),
    _createVNode(_component_PxPanel, { class: "starting-point-panel" }, {
      default: _withCtx(() => [
        _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.$t("supporters.startingPoint.panel.title")), 1),
        _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.$t("supporters.startingPoint.panel.content")), 1),
        _createVNode(_component_PxButton, {
          icon: _ctx.startButtonIcon,
          loading: _ctx.isLoading,
          type: _ctx.startButtonType,
          class: "starting-point-panel__button",
          size: "large",
          onClick: _ctx.onClickTakeAssessmentButton
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.startButtonText), 1)
          ]),
          _: 1
        }, 8, ["icon", "loading", "type", "onClick"])
      ]),
      _: 1
    }),
    _createElementVNode("div", _hoisted_4, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.$tm('supporters.startingPoint.keyPoints'), (item, index) => {
        return (_openBlock(), _createBlock(_component_FeatureCard, {
          key: index,
          description: item['description'],
          icon: item['value'],
          title: item['title']
        }, null, 8, ["description", "icon", "title"]))
      }), 128))
    ])
  ]))
}