<template>
  <div
    class="demographic-charts"
    :class="{
      'demographic-charts--full-width': !isListsReportsView,
    }"
  >
    <StatsCard
      v-for="(stats, index) in cards"
      v-bind="stats"
      :key="`demographic-stats-${index}`"
    />
    <!-- Companies data chart -->
    <CompaniesDataChart
      :data="data"
      :process-reports-data="processReportsData"
      :view="view"
      :theme="theme"
    />
    <!-- Team members data charts -->
    <template v-if="isListsReportsView">
      <ListReportsChart
        :data="data"
        :chart-title="chartsTitle"
        :chart-description="chartsDescription"
        :show-locked-state="showLockedState"
        :locked-state="lockedStateCopy"
        :theme="theme.title"
        :note="chartNote"
      />
    </template>
    <template v-else>
      <ProcessReportsChart
        :data="processReportsData"
        :chart-title="chartsTitle"
        :chart-description="chartsDescription"
        :locked-state="lockedStateCopy"
        :theme="theme.title"
      />
    </template>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import StatsCard from "@/components/stats-card/stats-card.vue";
import CompaniesDataChart from "@/modules/charts-dashboard/components/companies-data-chart/companies-data-chart.vue";

import ListReportsChart from "@/modules/charts-dashboard/components/list-reports/list-reports-chart.vue";
import ProcessReportsChart from "@/modules/charts-dashboard/components/process-reports/process-reports-chart.vue";

import {
  IListReportsDemographicData,
  IDemographicDataCompany,
} from "@/modules/charts-dashboard/services/data/lists-reports-data/lists-reports-data.interface";
import { TranslateResult } from "vue-i18n";
import { IChartLockedContent } from "@/modules/charts-dashboard/components/common/chart-locked/chart-locked.vue";
import {
  EDashboardPages,
  EDemographicThemes,
} from "@/modules/charts-dashboard/views/charts-dashboard.interface";
import { IProcessReportsDemographicData } from "@/modules/charts-dashboard/services/data/process-reports-data/process-reports-data.interface";
import { IReportsViewThemes } from "@/modules/charts-dashboard/views/charts-dashboard.view.vue";

export default defineComponent({
  name: "DemographicCharts",

  components: {
    StatsCard,
    CompaniesDataChart,
    ListReportsChart,
    ProcessReportsChart,
  },

  props: {
    data: {
      type: Object as () => IListReportsDemographicData,
      default: () => ({}) as IListReportsDemographicData,
    },

    processReportsData: {
      type: Array as () => IProcessReportsDemographicData[],
      default: () => [] as IProcessReportsDemographicData[],
    },

    view: {
      type: Number,
      default: EDashboardPages.LIST_REPORTS,
      validator: (view: EDashboardPages) =>
        Object.values(EDashboardPages).includes(view),
    },

    theme: {
      type: Object as () => IReportsViewThemes,
      required: true,
    },
  },

  computed: {
    chartsTitle(): TranslateResult {
      return this.$t("chartsDashboard.charts.totalResults.title", {
        theme: this.theme.title,
      });
    },

    chartsDescription(): TranslateResult {
      const themeCopy = this.showEthnicityData
        ? this.$t("chartsDashboard.charts.aggregation.ethnicities")
        : (this.theme.title as string).toLowerCase();

      return this.$t("chartsDashboard.charts.totalResults.description", {
        theme: themeCopy,
      });
    },

    totalOfCompanies(): number {
      return this.isListsReportsView
        ? this.data.companies.length
        : this.processReportsData.reduce(
            (acc: number, list: IProcessReportsDemographicData) =>
              acc + list.companies.length,
            0,
          );
    },

    companiesWithData(): number {
      return this.isListsReportsView
        ? this.data.companies_with_responses
        : this.processReportsData.reduce(
            (acc: number, list: IProcessReportsDemographicData) =>
              acc + list.companies_with_responses,
            0,
          );
    },

    totalOfTeamMembers(): number {
      return this.isListsReportsView
        ? this.data.companies.reduce(
            (acc: number, company: IDemographicDataCompany) =>
              acc + company.team_members_count,
            0,
          )
        : this.processReportsData.reduce(
            (acc: number, list: IProcessReportsDemographicData) =>
              acc +
              list.companies.reduce(
                (acc: number, company: IDemographicDataCompany) =>
                  acc + company.team_members_count,
                0,
              ),
            0,
          );
    },

    teamMembersWithData(): number {
      return this.isListsReportsView
        ? this.data.companies.reduce(
            (acc: number, company: IDemographicDataCompany) =>
              acc + company.team_members_with_responses_count,
            0,
          )
        : this.processReportsData.reduce(
            (acc: number, list: IProcessReportsDemographicData) =>
              acc +
              list.companies.reduce(
                (acc: number, company: IDemographicDataCompany) =>
                  acc + company.team_members_with_responses_count,
                0,
              ),
            0,
          );
    },

    cards(): any {
      return [
        {
          title: this.$t("chartsDashboard.charts.cards[0].title", {
            theme: this.theme.title,
          }),
          statValue: this.companiesWithData,
          statTotal: this.totalOfCompanies,
          description: this.$tc(
            "chartsDashboard.charts.cards[0].description",
            this.companiesWithData,
            {
              companiesWithData: this.companiesWithData,
              companiesTotal: this.totalOfCompanies,
              listOrProcess: this.isListsReportsView
                ? this.$t("chartsDashboard.list")
                : this.$t("chartsDashboard.process"),
              theme: this.theme.title,
            },
          ),
        },
        {
          title: this.$t("chartsDashboard.charts.cards[1].title", {
            theme: this.theme.title,
          }),
          statValue: this.teamMembersWithData,
          statTotal: this.totalOfTeamMembers,
          description: this.$tc(
            "chartsDashboard.charts.cards[1].description",
            this.teamMembersWithData,
            {
              teamMembersWithData: this.teamMembersWithData,
              teamMembersTotal: this.totalOfTeamMembers,
              listOrProcess: this.isListsReportsView
                ? this.$t("chartsDashboard.list")
                : this.$t("chartsDashboard.process"),
              theme: this.theme.title,
            },
          ),
        },
      ];
    },

    lockedStateCopy(): IChartLockedContent {
      return {
        title: "chartsDashboard.lockedState.title[1]",
        description: this.isListsReportsView
          ? "chartsDashboard.lockedState.description[1]"
          : "chartsDashboard.lockedState.description[4]",
        note: "chartsDashboard.lockedState.note",
        image: this.isListsReportsView
          ? `/img/illustrations/charts-dashboard/list-reports/${this.theme.image_path}_team_members.png`
          : `/img/illustrations/charts-dashboard/process-reports/${this.theme.image_path}.png`,
      };
    },

    showLockedState(): boolean {
      return this.isListsReportsView && this.data.companies_with_responses < 5;
    },

    isListsReportsView(): boolean {
      return this.view === EDashboardPages.LIST_REPORTS;
    },

    showGenderData(): boolean {
      return this.theme.title === EDemographicThemes.GENDER;
    },

    showEthnicityData(): boolean {
      return this.theme.title === EDemographicThemes.ETHNICITY;
    },

    showOrientationChart(): boolean {
      return this.theme.title === EDemographicThemes.SEXUAL_ORIENTATION;
    },

    chartNote(): TranslateResult {
      return this.theme.title === EDemographicThemes.ETHNICITY
        ? this.$t("chartsDashboard.ethnicitiesCharts.totalResults.note")
        : "";
    },
  },
});
</script>

<style lang="scss" scoped>
.demographic-charts {
  display: grid;
  grid-template-columns: repeat(2, minmax(300px, 1fr));
  gap: 30px;

  @include breakpoint-down(lg) {
    grid-template-columns: repeat(1, minmax(300px, 1fr));
  }

  &--full-width {
    grid-template-columns: repeat(1, minmax(300px, 1fr));
    gap: 15px;

    div:first-of-type {
      order: 1;
    }

    div:nth-of-type(2) {
      order: 3;
    }

    div:nth-of-type(3) {
      order: 2;
      margin-bottom: 41px;
    }

    div:nth-of-type(4) {
      order: 4;
    }
  }
}
</style>
