import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-98da2c84"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "category-color-bar" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.steps, (step, index) => {
      return (_openBlock(), _createElementBlock("div", {
        key: step.value,
        class: _normalizeClass([{
        'is-active': _ctx.activeStep === index,
        'is-checked': _ctx.isStepChecked(step),
      }, "category-color-bar__color"]),
        style: _normalizeStyle(`--stepColor: #${step.color}`)
      }, null, 6))
    }), 128))
  ]))
}