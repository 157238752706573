import { DirectiveBinding } from "vue";
import PxLoading from "@/components/px-loading/px-loading.vue";
import { render } from "vue";
import { h } from "vue";

const toggle = (el: HTMLElement, binding: DirectiveBinding) => {
  const mask = el.querySelector(":scope > .px-loading-mask") as HTMLElement;

  if (!mask) {
    console.log("No mask found");
    return;
  }

  if (binding.value) {
    if (
      el.dataset.originalPosition !== "absolute" &&
      el.dataset.originalPosition !== "fixed"
    ) {
      el.classList.add("px-loading-parent--relative");
    }
    mask.style.display = "flex";
  } else {
    el.style.position = el.dataset.originalPosition || "";
    el.classList.remove("px-loading-parent--relative");
    mask.style.display = "none";
  }
};

export default {
  mounted(el: HTMLElement, binding: DirectiveBinding) {
    el.dataset.originalPosition = el.style.position;
    render(
      h(PxLoading, {
        background: el.getAttribute("element-loading-background") || "",
      }),
      el,
    );
    toggle(el, binding);
  },
  updated(el: HTMLElement, binding: DirectiveBinding) {
    toggle(el, binding);
  },
  beforeUnmount(el: HTMLElement) {
    el.querySelector(":scope > .px-loading-mask")?.remove();
  },
};
