import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-59d785bd"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "matching-compact-placeholder__container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ContentPlaceholdersHeading = _resolveComponent("ContentPlaceholdersHeading")!
  const _component_ContentPlaceholders = _resolveComponent("ContentPlaceholders")!
  const _component_ContentPlaceholdersText = _resolveComponent("ContentPlaceholdersText")!
  const _component_MatchingCardCompactWrapper = _resolveComponent("MatchingCardCompactWrapper")!

  return (_openBlock(), _createBlock(_component_MatchingCardCompactWrapper, { class: "matching-compact-placeholder__wrapper" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_ContentPlaceholders, { rounded: true }, {
          default: _withCtx(() => [
            _createVNode(_component_ContentPlaceholdersHeading, { img: true })
          ]),
          _: 1
        }),
        _createVNode(_component_ContentPlaceholders, {
          rounded: true,
          class: "matching-compact-placeholder__centered"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_ContentPlaceholdersText, { lines: 1 })
          ]),
          _: 1
        }),
        (_ctx.showPercentagePill)
          ? (_openBlock(), _createBlock(_component_ContentPlaceholders, {
              key: 0,
              rounded: true,
              class: "matching-compact-placeholder__centered"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ContentPlaceholdersText, {
                  lines: 1,
                  class: "is-pill"
                })
              ]),
              _: 1
            }))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }))
}