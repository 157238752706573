import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "supporters-sign-in-modal__remember-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PxModalMessage = _resolveComponent("PxModalMessage")!
  const _component_i18n_t = _resolveComponent("i18n-t")!
  const _component_ElInput = _resolveComponent("ElInput")!
  const _component_ElFormItem = _resolveComponent("ElFormItem")!
  const _component_PxInputPassword = _resolveComponent("PxInputPassword")!
  const _component_ElCheckbox = _resolveComponent("ElCheckbox")!
  const _component_PxButton = _resolveComponent("PxButton")!
  const _component_ElForm = _resolveComponent("ElForm")!
  const _component_ElDialog = _resolveComponent("ElDialog")!

  return (_openBlock(), _createBlock(_component_ElDialog, {
    ref: "modal",
    modelValue: _ctx.innerVisibility,
    "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.innerVisibility) = $event)),
    "close-on-click-modal": false,
    "close-on-press-escape": false,
    "show-close": _ctx.isSkippable,
    title: _ctx.componentCopy.title,
    width: "468px",
    class: "supporters-sign-in-modal",
    onClose: _ctx.onModalClose
  }, {
    default: _withCtx(() => [
      _createVNode(_component_PxModalMessage, {
        title: _ctx.modalError,
        visible: _ctx.hasServerErrors || _ctx.hasInvalidAccount
      }, null, 8, ["title", "visible"]),
      _createVNode(_component_i18n_t, {
        class: "supporters-sign-in-modal__sign-up-panel",
        keypath: "supporters.component.signInModal.noAccount",
        tag: "div"
      }, {
        tellMore: _withCtx(() => [
          _createElementVNode("span", {
            class: "supporters-sign-in-modal__sign-up-panel-link",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.gotoRegisterModal && _ctx.gotoRegisterModal(...args)))
          }, _toDisplayString(_ctx.componentCopy.tellMore), 1)
        ]),
        _: 1
      }),
      _createElementVNode("div", {
        ref: "formWrapper",
        class: _normalizeClass([{ 'scroll-at-bottom': _ctx.formWrapperScrollAtEnd }, "supporters-sign-in-modal__form-wrapper"]),
        onScroll: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.checkFormWrapperScroll && _ctx.checkFormWrapperScroll(...args)))
      }, [
        _createVNode(_component_ElForm, {
          ref: "form",
          model: _ctx.data,
          rules: _ctx.rules,
          class: "supporters-sign-in-modal__form"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_ElFormItem, {
              class: _normalizeClass({ 'is-error': _ctx.showEmailAsError }),
              label: _ctx.componentCopy.form.fields.email,
              prop: "email"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ElInput, {
                  modelValue: _ctx.data.email,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.data.email) = $event)),
                  disabled: _ctx.hasInvalidAccount,
                  placeholder: _ctx.componentCopy.form.fields.email
                }, null, 8, ["modelValue", "disabled", "placeholder"])
              ]),
              _: 1
            }, 8, ["class", "label"]),
            _createVNode(_component_ElFormItem, {
              error: 
            _ctx.errors.getMessage('non_field_errors', 'email_not_verified') || ''
          ,
              label: _ctx.componentCopy.form.fields.password,
              prop: "password"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_PxInputPassword, {
                  modelValue: _ctx.data.password,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.data.password) = $event)),
                  disabled: _ctx.hasInvalidAccount,
                  placeholder: _ctx.componentCopy.form.fields.password
                }, null, 8, ["modelValue", "disabled", "placeholder"])
              ]),
              _: 1
            }, 8, ["error", "label"]),
            _createVNode(_component_ElFormItem, null, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_1, [
                  _createVNode(_component_ElCheckbox, {
                    modelValue: _ctx.data.remember,
                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.data.remember) = $event)),
                    class: "supporters-sign-in-modal__remember"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.componentCopy.form.fields.remember), 1)
                    ]),
                    _: 1
                  }, 8, ["modelValue"]),
                  _createVNode(_component_PxButton, {
                    class: "el-button--link-blue supporters-sign-in-modal__forget",
                    size: "small",
                    type: "link",
                    onClick: _ctx.onClickPasswordRecover
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.componentCopy.forgetPasswordLink), 1)
                    ]),
                    _: 1
                  }, 8, ["onClick"])
                ])
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["model", "rules"]),
        _createVNode(_component_PxButton, {
          disabled: _ctx.isLoadingButtonDisabled,
          loading: _ctx.loading,
          class: "el-button--block supporters-sign-in-modal__login-button",
          type: "primary",
          onClick: _ctx.onClickLogin
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.componentCopy.form.submitButton), 1)
          ]),
          _: 1
        }, 8, ["disabled", "loading", "onClick"])
      ], 34)
    ]),
    _: 1
  }, 8, ["modelValue", "show-close", "title", "onClose"]))
}