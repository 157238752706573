import { GenericProvider, Provider } from "@/services/data/generic-provider";
import { baseAPIUrl } from "@/services/utils/utils";

import {
  IProcessReportsDemographicData,
  IProcessData,
} from "@/modules/charts-dashboard/services/data/process-reports-data/process-reports-data.interface";

@Provider(`${baseAPIUrl}/user/processes`)
class ProcessReportsDataProvider extends GenericProvider<IProcessData[]> {
  public async fetch(): Promise<IProcessData[]> {
    const url = super.buildEndPointUrl();
    const request = this.httpClient.get(url);
    const { data } = await this.wrapRequest(request);
    return data;
  }

  public async getDemographicStats(
    id: string,
    question_slug: string,
  ): Promise<IProcessReportsDemographicData[]> {
    const url = super.buildEndPointUrl(
      {
        question_slug,
      },
      `${id}/demographic-stats`,
    );
    const request = this.httpClient.get(url);
    const { data } = await this.wrapRequest(request);

    return data;
  }
}

export const processReportsDataProvider = new ProcessReportsDataProvider();
