<template>
  <Level1 v-if="level == 1" class="px-level" />
  <Level2 v-else-if="level == 2" class="px-level" />
  <Level3 v-else-if="level == 3" class="px-level" />
  <Level4 v-else-if="level == 4" class="px-level" />
  <Level5 v-else-if="level == 5" class="px-level" />
  <Level6 v-else-if="level == 6" class="px-level" />
  <Level7 v-else-if="level == 7" class="px-level" />
  <Level8 v-else-if="level == 8" class="px-level" />
  <Level9 v-else-if="level == 9" class="px-level" />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import Level1 from "@/components/svg-levels/level-1.vue";
import Level2 from "@/components/svg-levels/level-2.vue";
import Level3 from "@/components/svg-levels/level-3.vue";
import Level4 from "@/components/svg-levels/level-4.vue";
import Level5 from "@/components/svg-levels/level-5.vue";
import Level6 from "@/components/svg-levels/level-6.vue";
import Level7 from "@/components/svg-levels/level-7.vue";
import Level8 from "@/components/svg-levels/level-8.vue";
import Level9 from "@/components/svg-levels/level-9.vue";

export default defineComponent({
  name: "PxLevel",

  components: {
    Level1,
    Level2,
    Level3,
    Level4,
    Level5,
    Level6,
    Level7,
    Level8,
    Level9,
  },

  props: {
    level: {
      type: Number,
      required: true,
    },
    categoryColor: {
      type: String,
      required: true,
    },
  },

  computed: {
    getColorMatrix(): number[] {
      // prettier-ignore
      const colorMatrixValues = [
        0, 0, 0, 0,
        0, 0, 0, 0,
        0, 0, 0, 0,
        0, 0, 0, 0,
        0, 0, 0.8, 0,
      ];

      const hex = this.categoryColor;

      // Transform hex value to RGB format
      const r = parseInt(hex.slice(0, 2), 16),
        g = parseInt(hex.slice(2, 4), 16),
        b = parseInt(hex.slice(4, 6), 16);

      const rFloatNumber = Number((r / 255).toFixed(2)),
        gFloatNumber = Number((g / 255).toFixed(2)),
        bFloatNumber = Number((b / 255).toFixed(2));

      colorMatrixValues[4] = rFloatNumber;
      colorMatrixValues[9] = gFloatNumber;
      colorMatrixValues[14] = bFloatNumber;

      return colorMatrixValues;
    },
  },

  watch: {
    categoryColor() {
      this.addImageColor(document.querySelector(".px-level"));
    },
  },

  mounted() {
    this.addImageColor(document.querySelector(".px-level"));
  },

  updated() {
    this.addImageColor(document.querySelector(".px-level"));
  },

  methods: {
    hexToRGB(hex: string) {
      // Exclude the "#" on position 0
      const r = parseInt(hex.slice(1, 3), 16),
        g = parseInt(hex.slice(3, 5), 16),
        b = parseInt(hex.slice(5, 7), 16);
      return `${r}, ${g}, ${b}`;
    },

    addImageColor(levelImageWrapper: HTMLElement | null) {
      if (levelImageWrapper) {
        this.setColorMatrix(levelImageWrapper);
        this.applyStyles(levelImageWrapper);
      }
    },

    setColorMatrix(levelImageWrapper: HTMLElement) {
      const feColorMatrix = levelImageWrapper.querySelectorAll(".color-matrix");

      feColorMatrix.forEach((el) =>
        el.setAttribute("values", this.getColorMatrix.join(" ")),
      );
    },

    applyStyles(levelImageWrapper: HTMLElement) {
      const hexColor = `#${this.categoryColor}`;
      const rgbaColor = this.hexToRGB(hexColor);

      const gradientColor1 = levelImageWrapper.querySelectorAll(
        ".linear-gradient-color1",
      );
      const gradientColor2 = levelImageWrapper.querySelectorAll(
        ".linear-gradient-color2",
      );
      const gradientColor3 = levelImageWrapper.querySelectorAll(
        ".linear-gradient-color3",
      );
      const gradientColor4 = levelImageWrapper.querySelectorAll(
        ".linear-gradient-color4",
      );
      const gradientColor5 = levelImageWrapper.querySelectorAll(
        ".linear-gradient-color5",
      );

      gradientColor1.forEach((el) =>
        el.setAttribute("stop-color", `rgba(${rgbaColor}, 0.8)`),
      );
      gradientColor2.forEach((el) => el.setAttribute("stop-color", hexColor));
      gradientColor3.forEach((el) =>
        el.setAttribute("stop-color", `rgba(${rgbaColor}, 0.9)`),
      );
      gradientColor4.forEach((el) =>
        el.setAttribute("fill", `rgba(${rgbaColor}, 0.5)`),
      );
      gradientColor5.forEach((el) =>
        el.setAttribute("stroke", `rgba(${rgbaColor}, 0.5)`),
      );
    },
  },
});
</script>

<style lang="scss" scoped>
svg {
  position: relative;
  top: 0;
  right: 15px;
  bottom: 0;
  margin: 0;
  transform: scale(0.58);
}
</style>
