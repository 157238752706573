import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, mergeProps as _mergeProps, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-3355c4c9"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "lang-switcher" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PxIcon = _resolveComponent("PxIcon")!
  const _component_PxButton = _resolveComponent("PxButton")!
  const _directive_feature = _resolveDirective("feature")!

  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_PxIcon, {
      size: 14,
      class: "lang-switcher__icon",
      name: "info"
    }),
    _createElementVNode("p", null, [
      _createTextVNode(_toDisplayString(_ctx.$t("common.languageSwitcher.availableLanguages")) + " ", 1),
      _createVNode(_component_PxButton, _mergeProps(_ctx.switchLanguageButtonProps, { onClick: _ctx.localeChange }), null, 16, ["onClick"])
    ])
  ])), [
    [_directive_feature, 'i18n']
  ])
}