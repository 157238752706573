import {
  GenericProvider,
  EProviderFeatures,
} from "@/services/data/generic-provider";
import { ISignup } from "@/modules/authentication/services/data/signup/signup.interface";
import { baseAPIUrl } from "@/services/utils/utils";

/**
 * Provider used to perform a sign up.
 */
class SignUpProvider extends GenericProvider<any, ISignup> {
  constructor() {
    super(`${baseAPIUrl}/user/register`, [EProviderFeatures.CREATE]);
  }
}

export const signUpProvider = new SignUpProvider();
