<template>
  <div class="px-expand">
    <div class="px-expand__container">
      <div class="px-expand__wrapper px-expand__wrapper--visible">
        <slot name="visible" />
      </div>
      <Transition name="expand">
        <div
          v-if="expanded"
          class="px-expand__wrapper px-expand__wrapper--hidden"
        >
          <slot name="hidden" />
        </div>
      </Transition>
    </div>
    <div
      v-if="triggerVisible"
      class="px-expand__trigger"
      @click="expanded = !expanded"
    >
      <slot name="trigger" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "PxExpand",

  props: {
    /**
     * Set the expand trigger as a toggler
     * or hide it upon the expand event
     */
    toggle: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      expanded: false,
    };
  },

  computed: {
    triggerVisible(): boolean {
      return !(!this.toggle && this.expanded);
    },
  },
});
</script>
<style lang="scss" scoped>
.expand-enter-active,
.expand-leave-active {
  transition: max-height 0.5s ease-in-out;
}

.expand-enter,
.expand-leave-to {
  max-height: 0;
  overflow: hidden;
}
</style>
