import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "auth-signup__step signup-step-account" }
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = ["textContent"]
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = { class: "el-form__bottom-message" }
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = { class: "el-form__cta" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ElInput = _resolveComponent("ElInput")!
  const _component_ElFormItem = _resolveComponent("ElFormItem")!
  const _component_PxInputPassword = _resolveComponent("PxInputPassword")!
  const _component_PxButton = _resolveComponent("PxButton")!
  const _component_ElForm = _resolveComponent("ElForm")!
  const _component_PxPanel = _resolveComponent("PxPanel")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_PxPanel, { class: "px-panel--compact" }, {
      header: _withCtx(() => [
        _createElementVNode("h2", {
          class: "px-panel__title",
          innerHTML: _ctx.$t('supporters.signup.form.account')
        }, null, 8, _hoisted_2)
      ]),
      default: _withCtx(() => [
        _createVNode(_component_ElForm, {
          ref: "signupForm",
          model: _ctx.innerAccount,
          rules: _ctx.rules,
          class: "auth-signup__form",
          onValidate: _ctx.updateSubmitState
        }, {
          default: _withCtx(() => [
            _createVNode(_component_ElFormItem, {
              error: _ctx.errors.getMessage('email'),
              label: _ctx.$t('supporters.signup.form.fields.email'),
              prop: "email"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ElInput, {
                  modelValue: _ctx.innerAccount.email,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.innerAccount.email) = $event)),
                  placeholder: _ctx.$t('supporters.signup.form.placeholders.email'),
                  type: "email"
                }, null, 8, ["modelValue", "placeholder"])
              ]),
              _: 1
            }, 8, ["error", "label"]),
            _createVNode(_component_ElFormItem, {
              label: _ctx.$t('supporters.signup.form.fields.password'),
              prop: "password1"
            }, {
              default: _withCtx(() => [
                _createElementVNode("span", {
                  class: "el-form-item__top-text el-form-item__requirement",
                  textContent: _toDisplayString(_ctx.$t('supporters.signup.form.requirement'))
                }, null, 8, _hoisted_3),
                _createVNode(_component_PxInputPassword, {
                  modelValue: _ctx.innerAccount.password1,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.innerAccount.password1) = $event)),
                  placeholder: _ctx.$t('supporters.signup.form.fields.password'),
                  "show-success-state": true,
                  onInput: _ctx.validateConfirmationField
                }, null, 8, ["modelValue", "placeholder", "onInput"]),
                _createElementVNode("span", {
                  class: "el-form-item__bottom-text el-form-item__tip",
                  innerHTML: _ctx.$t('supporters.signup.form.tip')
                }, null, 8, _hoisted_4)
              ]),
              _: 1
            }, 8, ["label"]),
            _createVNode(_component_ElFormItem, {
              label: _ctx.$t('supporters.signup.form.fields.confirmPassword'),
              prop: "password2"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ElInput, {
                  modelValue: _ctx.innerAccount.password2,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.innerAccount.password2) = $event)),
                  disabled: _ctx.passwordIsInvalid,
                  placeholder: _ctx.$t('supporters.signup.form.fields.confirmPassword'),
                  class: "el-input--password",
                  type: "password"
                }, null, 8, ["modelValue", "disabled", "placeholder"])
              ]),
              _: 1
            }, 8, ["label"]),
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("p", {
                class: "el-form__notice",
                innerHTML: _ctx.$t('supporters.signup.form.notice')
              }, null, 8, _hoisted_6)
            ]),
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("button", {
                class: "el-button el-button--link el-button--small",
                type: "button",
                onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.goToPreviousStep && _ctx.goToPreviousStep(...args)))
              }, [
                _createElementVNode("span", null, _toDisplayString(_ctx.$t("supporters.signup.form.previousButton")), 1)
              ]),
              _createVNode(_component_PxButton, {
                disabled: _ctx.submissionDisabled,
                loading: _ctx.loading,
                size: "medium",
                type: "primary",
                onClick: _ctx.submitForm
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t("authentication.signup.form.submitButton")), 1)
                ]),
                _: 1
              }, 8, ["disabled", "loading", "onClick"])
            ])
          ]),
          _: 1
        }, 8, ["model", "rules", "onValidate"])
      ]),
      _: 1
    })
  ]))
}