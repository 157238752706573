<template>
  <ElDialog
    v-model="innerVisibility"
    :append-to-body="true"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :destroy-on-close="true"
    :title="$t('matching.matchDetails.title')"
    class="match-details-modal"
    width="903px"
  >
    <PxModalMessage
      v-if="!hasMatch"
      :title="$t(`matching.matchDetails.noMatch[${userType}]`)"
      :visible="!hasMatch"
      data-testid="match-details-modal-no-match-message"
      type="info"
    />
    <MatchDetailsModalHeader
      :class="{ 'match-details-modal__no-match': !hasMatch }"
      :company-logo="companyLogo"
      :company-name="companyName"
      :has-match="hasMatch"
      :match-percentage="matchPercentage"
      :user-type="userType"
      data-testid="match-details-modal-header"
    />
    <MatchDetailsModalTable
      :table-data="matchDetails.details"
      :user-type="userType"
      class="match-details-modal__table"
    />
    <MatchDetailsTableLegend
      :legend-cards="tableLegend"
      data-testid="match-details-modal-table-legend"
    />
    <template v-if="$user.isSupporter()" #footer>
      <MatchDetailsModalFooter
        data-testid="match-details-modal-footer"
        @close-modal="innerVisibility = false"
      />
    </template>
  </ElDialog>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import ElDialogMixin from "@/mixins/el-dialog.mixin";

import MatchDetailsModalHeader from "@/components/match-details-modal/match-details-modal-header.vue";
import MatchDetailsModalTable from "@/components/match-details-modal/match-details-modal-table.vue";
import MatchDetailsTableLegend, {
  ILegendCard,
} from "@/components/match-details-table/match-details-table-legend.vue";
import MatchDetailsModalFooter from "@/components/match-details-modal/match-details-modal-footer.vue";
import { IMatchScoreDetails } from "@/modules/matching/services/store/match-score-details/match-score-details.types";

export default defineComponent({
  name: "MatchDetailsModal",

  components: {
    MatchDetailsModalHeader,
    MatchDetailsModalTable,
    MatchDetailsTableLegend,
    MatchDetailsModalFooter,
  },

  mixins: [ElDialogMixin],

  props: {
    companyName: {
      type: String,
      required: true,
    },

    companyLogo: {
      type: String,
      default: "",
    },

    matchDetails: {
      type: Object as () => IMatchScoreDetails,
      required: true,
    },
  },

  computed: {
    userType(): number {
      return this.$user.getUserAccountType();
    },

    matchPercentage(): number {
      return Math.trunc(this.matchDetails.score);
    },

    hasMatch(): boolean {
      return this.matchPercentage > 0;
    },

    tableLegend() {
      return this.$tm(
        `matching.matchDetails.table.legend.content[${this.userType}].cards`,
      ) as ILegendCard[];
    },
  },
});
</script>

<style lang="scss">
.match-details-modal {
  &__no-match.match-details-modal-header {
    margin-top: 33px;
  }

  &__table {
    padding-top: 27px;
  }

  .px-modal-message .el-alert--info {
    background-color: $malibu;
    color: $white;

    .el-alert__icon {
      filter: none;
    }

    .el-alert__title {
      @include grotesk(medium);

      margin-left: 8px;
      font-size: 13px;
      letter-spacing: -0.2px;
    }

    .el-alert__content {
      padding: 1px 0 0;
    }
  }

  .el-dialog__header {
    padding: 14px 56px 13px 30px;
    letter-spacing: -0.33px;
  }

  .el-dialog__headerbtn {
    top: 24px;
    right: 20px;
    z-index: z("floaters");
    width: 24px;
    height: 24px;
  }

  .el-dialog__title {
    font-size: 22px;
  }

  .el-dialog__body {
    padding: 26px 30px 13px;
  }

  .el-dialog__footer {
    padding: 0;
    background-color: $alabaster;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;

    &::before {
      width: 100%;
    }
  }
}
</style>
