import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ElAlert = _resolveComponent("ElAlert")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([{ 'is-visible': _ctx.visible }, "px-modal-message"])
  }, [
    _createVNode(_component_ElAlert, {
      center: _ctx.center,
      "show-icon": _ctx.showIcon,
      title: _ctx.title || _ctx.$t('common.errors.global.alertTitle'),
      type: _ctx.type
    }, {
      title: _withCtx(() => [
        _renderSlot(_ctx.$slots, "default", {}, undefined, true)
      ]),
      _: 3
    }, 8, ["center", "show-icon", "title", "type"])
  ], 2))
}