export const SUBSCRIPTIONS_DATA = {
  free: {
    title: "Free",
    price: 0,
    priceNote: "",
    features: [
      {
        label: "See top matches",
        icon: "check--dark",
        iconSize: 9,
      },
      {
        label: "Appear in search",
        icon: "check--dark",
        iconSize: 9,
      },
      {
        label: "See shared milestone plans",
        icon: "check--dark",
        iconSize: 9,
      },
      {
        label: "Weekly recommended companies",
        icon: "check--dark",
        iconSize: 9,
      },
    ],
    featuresTitle: "What’s in this plan",
  },
  pro: {
    title: "Pro",
    price: 100,
    priceNote: "per month",
    features: [
      {
        label: "Assess company readiness for investment",
        icon: "check--dark",
        iconSize: 11,
      },
      {
        label: "Experience Capital Explorer like an entrepreneur",
        icon: "check--dark",
        iconSize: 11,
      },
      {
        label: "Review and track milestone plans progress",
        icon: "check--dark",
        iconSize: 11,
      },
      {
        label: "Guide founders through mentoring effectively",
        icon: "check--dark",
        iconSize: 11,
      },
      {
        label: "Screen and accept applications",
        icon: "check--dark",
        iconSize: 11,
      },
      {
        label: "Access demographic reports",
        icon: "check--dark",
        iconSize: 11,
      },
      {
        label: "Create and share lists",
        icon: "check--dark",
        iconSize: 11,
      },
    ],
    featuresTitle: "Everything in Free, plus",
  },
};

export const PRO_SUBSCRIPTION_DATA = {
  ...SUBSCRIPTIONS_DATA.pro,
  features: [
    {
      label: "Benchmark investment readiness of your companies",
      icon: "success--blue-dark",
    },
    {
      label:
        "Innovative financing options assessed for your companies via Capital Explorer",
      icon: "success--blue-dark",
    },
    {
      label: "Collect and review milestone plans",
      icon: "success--blue-dark",
    },
    {
      label: "Monitor milestone progress",
      icon: "success--blue-dark",
    },
    {
      label: "Structure effective mentoring conversations with founders",
      icon: "success--blue-dark",
    },
    {
      label: "Accept & screen applications",
      icon: "success--blue-dark",
    },
    {
      label: "Responsible demographic data collection & reporting",
      icon: "success--blue-dark",
    },
    {
      label: "Integrations & data feeds",
      icon: "success--blue-dark",
    },
    {
      label: "Create & share lists",
      icon: "success--blue-dark",
    },
    {
      label: "Co-branding",
      icon: "success--blue-dark",
    },
    {
      label: "Charts & reports on your companies and pipeline",
      icon: "alarm",
    },
  ],
};
