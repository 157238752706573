<template>
  <div class="supporter-criteria-list">
    <ElCollapse v-model="activeDrawer" accordion>
      <ElCollapseItem
        v-for="(item, index) in supporterCriteriaByQuestionCategories"
        :key="index"
        :name="index"
      >
        <template #title>
          <div class="supporter-criteria-list__headline">
            <h3>
              {{ item.name }}
              <span>
                {{ $t("profile.supporter.criteria.requirements") }}
              </span>
            </h3>
          </div>
        </template>
        <ul v-if="item.criteria" class="supporter-criteria-list__items">
          <li v-for="(itemCriteria, key) in item.criteria" :key="key">
            <div class="supporter-criteria-list__question">
              {{ itemCriteria.question.resource_question }}
            </div>
            <div class="supporter-criteria-list__answer">
              <span>
                {{ itemCriteria.formattedValue }}
              </span>
            </div>
          </li>
        </ul>
      </ElCollapseItem>
    </ElCollapse>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import uniqWith from "lodash/uniqWith";
import isEqual from "lodash/isEqual";
import sortBy from "lodash/sortBy";

import { IMatchingCriteria } from "@/modules/matching/services/data/matching-score/matching-criteria.interface";
import { IMatchingQuestionCategory } from "@/services/data/matching-questionary/matching-question.interface";
import GetQuestionAnswerMixin from "@/mixins/get-question-answer.mixin";

export default defineComponent({
  name: "SupporterCriteriaList",

  mixins: [GetQuestionAnswerMixin],

  props: {
    criteria: {
      type: Array as () => IMatchingCriteria[],
      required: true,
    },
  },

  data() {
    return {
      activeDrawer: [0],
    };
  },

  computed: {
    supporterCriteriaByQuestionCategories(): Array<any> {
      // Remove questions with no answers
      const filteredCriteria = this.criteria.filter(
        (criteria: IMatchingCriteria) =>
          !!criteria.answers.length || !!criteria.desired,
      );

      // Remove duplicates and Order by id
      const questionCategories = sortBy(
        uniqWith(
          filteredCriteria.map(
            (criteria: IMatchingCriteria) =>
              criteria.question.question_category,
          ),
          isEqual,
        ),
        ["id"],
      );

      return questionCategories.map((category: IMatchingQuestionCategory) => {
        const criteriaByCategory = filteredCriteria
          .filter(
            (criteria: IMatchingCriteria) =>
              criteria.question.question_category.id === category.id,
          )
          .map((criteria) => ({
            ...criteria,
            // Custom property with a formatted answer
            formattedValue: this.getAnswer(criteria),
          }));

        return { ...category, criteria: criteriaByCategory };
      });
    },
  },
});
</script>

<style lang="scss" scoped>
.supporter-criteria-list :deep() {
  .el-collapse-item__header {
    padding-left: 0;

    &::before {
      right: 2px;
    }

    &::after {
      right: 12px;
    }
  }

  .el-collapse-item__content {
    position: relative;
    top: -2px;
  }
}

.supporter-criteria-list__items {
  padding: 0 25px 0 17px;
  margin: auto;

  font-size: 15px;
  line-height: 22px;
  color: $scarpa-flow;
  letter-spacing: -0.2px;
  list-style: none;

  li {
    position: relative;

    &:not(:first-child) {
      margin-top: 14px;
    }

    &::before {
      position: absolute;
      top: 9px;
      left: -16px;
      width: 6px;
      height: 6px;

      content: "";
      background-color: $manatee;
      border-radius: 100%;
    }
  }
}

.supporter-criteria-list__question {
  @include grotesk(regular);
}

.supporter-criteria-list__answer {
  display: flex;
  flex-flow: column wrap;
  margin-top: 5px;

  span {
    @include grotesk(bold);

    flex-grow: 1;

    font-size: 15px;
    line-height: 22px;
    color: $scarpa-flow;

    &:not(:first-child) {
      margin-top: 2px;
    }
  }
}
</style>
