import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "milestone-plan-onboarding-dialog__content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MilestonePlanOnboardingDialogContentFirstPage = _resolveComponent("MilestonePlanOnboardingDialogContentFirstPage")!
  const _component_MilestonePlanOnboardingDialogContentSecondPage = _resolveComponent("MilestonePlanOnboardingDialogContentSecondPage")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.activePage === 1)
      ? (_openBlock(), _createBlock(_component_MilestonePlanOnboardingDialogContentFirstPage, {
          key: 0,
          "is-visitor": _ctx.isVisitor
        }, null, 8, ["is-visitor"]))
      : (_openBlock(), _createBlock(_component_MilestonePlanOnboardingDialogContentSecondPage, {
          key: 1,
          "is-visitor": _ctx.isVisitor
        }, null, 8, ["is-visitor"]))
  ]))
}