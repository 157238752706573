import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-44c21089"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "px-matching-interest__tip"
}
const _hoisted_2 = {
  key: 1,
  class: "px-matching-interest__tip"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PxIcon = _resolveComponent("PxIcon")!
  const _component_PxButton = _resolveComponent("PxButton")!
  const _component_PxMatchingInterestRemovalModal = _resolveComponent("PxMatchingInterestRemovalModal")!
  const _component_PxMatchingInterestDirectoryMemberModal = _resolveComponent("PxMatchingInterestDirectoryMemberModal")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([`px-matching-interest--${_ctx.size}`, "px-matching-interest"]),
    onMouseover: _cache[8] || (_cache[8] = 
//@ts-ignore
(...args) => (_ctx.mouseOverCTAHandler && _ctx.mouseOverCTAHandler(...args)))
  }, [
    (_ctx.matchingTip && _ctx.withDescription && _ctx.$screen.lgDown)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_PxIcon, {
            size: 14,
            class: "px-matching-interest__tipIcon",
            name: "star--yellow"
          }),
          _createTextVNode(" " + _toDisplayString(_ctx.matchingTip), 1)
        ]))
      : _createCommentVNode("", true),
    _createVNode(_component_PxButton, {
      icon: _ctx.ctaIcon,
      label: _ctx.ctaText,
      size: _ctx.size,
      type: _ctx.ctaType,
      class: "px-matching-interest__cta",
      onClick: _ctx.clickInterestCTAHandler,
      onMouseleave: _cache[0] || (_cache[0] = () => _ctx.setCtaHoverState(false)),
      onMouseover: _cache[1] || (_cache[1] = () => _ctx.setCtaHoverState(true))
    }, null, 8, ["icon", "label", "size", "type", "onClick"]),
    (_ctx.matchingTip && _ctx.withDescription && _ctx.$screen.xlUp)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_PxIcon, {
            size: 14,
            class: "px-matching-interest__tipIcon",
            name: "star--yellow"
          }),
          _createTextVNode(" " + _toDisplayString(_ctx.matchingTip), 1)
        ]))
      : _createCommentVNode("", true),
    (_ctx.isRemoveInterestModalVisible)
      ? (_openBlock(), _createBlock(_component_PxMatchingInterestRemovalModal, {
          key: 2,
          "has-server-error": _ctx.hasServerError,
          "onUpdate:hasServerError": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.hasServerError) = $event)),
          "is-loading": _ctx.isLoading,
          "onUpdate:isLoading": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.isLoading) = $event)),
          visibility: _ctx.isRemoveInterestModalVisible,
          "onUpdate:visibility": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.isRemoveInterestModalVisible) = $event)),
          "interest-name": _ctx.name,
          "interest-state": _ctx.interestState,
          onRemovedInterest: _ctx.onRemovedInterest
        }, null, 8, ["has-server-error", "is-loading", "visibility", "interest-name", "interest-state", "onRemovedInterest"]))
      : _createCommentVNode("", true),
    (_ctx.isDirectoryMemberModalVisible)
      ? (_openBlock(), _createBlock(_component_PxMatchingInterestDirectoryMemberModal, {
          key: 3,
          "has-server-error": _ctx.hasServerError,
          "onUpdate:hasServerError": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.hasServerError) = $event)),
          "is-loading": _ctx.isLoading,
          "onUpdate:isLoading": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.isLoading) = $event)),
          visibility: _ctx.isDirectoryMemberModalVisible,
          "onUpdate:visibility": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.isDirectoryMemberModalVisible) = $event)),
          "company-uid": _ctx.uid,
          onConnect: _ctx.clickInterestCTAHandler
        }, null, 8, ["has-server-error", "is-loading", "visibility", "company-uid", "onConnect"]))
      : _createCommentVNode("", true)
  ], 34))
}