<template>
  <ElDialog
    ref="modal"
    v-model="innerVisibility"
    :append-to-body="true"
    :close-on-click-modal="false"
    :show-close="false"
    :title="title"
    class="action-modal"
  >
    <div class="action-modal__body">
      <slot name="content" />
    </div>
    <template #footer>
      <div class="action-modal__footer">
        <slot name="actions" />
      </div>
    </template>
  </ElDialog>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import ElDialogMixin from "@/mixins/el-dialog.mixin";

export default defineComponent({
  name: "ActionModal",

  mixins: [ElDialogMixin],

  props: {
    title: {
      type: String,
      required: true,
    },
  },
});
</script>

<style lang="scss">
.action-modal {
  &.el-dialog {
    @include breakpoint-up(md) {
      // override default
      width: 492px;
      margin-top: 15vh !important;
    }
  }

  .el-dialog__header,
  .el-dialog__body {
    box-sizing: content-box;
    width: 392px;
    padding-right: 50px;
    padding-left: 50px;
  }

  .el-dialog__header {
    padding: 30px 50px 0;
    background: none;
    border: none;
  }

  .el-dialog__title {
    @include grotesk(semiBold);

    display: block;
    font-size: 20px;
    line-height: 28px;
    color: $ebony-clay;
    text-align: center;
  }

  .el-dialog__body {
    padding: 12px 50px 26px;
  }

  .el-dialog__footer {
    padding: 24px 30px;
  }

  .action-modal__body p {
    @include grotesk(regular);

    font-size: 14px;
    line-height: 24px;
    color: $ebony-clay;
    text-align: center;
  }

  .action-modal__footer {
    text-align: center;

    .el-button span {
      font-size: 14px;
    }
  }
}
</style>
