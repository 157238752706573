import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-007ba2e1"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "px-expand" }
const _hoisted_2 = { class: "px-expand__container" }
const _hoisted_3 = { class: "px-expand__wrapper px-expand__wrapper--visible" }
const _hoisted_4 = {
  key: 0,
  class: "px-expand__wrapper px-expand__wrapper--hidden"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _renderSlot(_ctx.$slots, "visible", {}, undefined, true)
      ]),
      _createVNode(_Transition, { name: "expand" }, {
        default: _withCtx(() => [
          (_ctx.expanded)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _renderSlot(_ctx.$slots, "hidden", {}, undefined, true)
              ]))
            : _createCommentVNode("", true)
        ]),
        _: 3
      })
    ]),
    (_ctx.triggerVisible)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "px-expand__trigger",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.expanded = !_ctx.expanded))
        }, [
          _renderSlot(_ctx.$slots, "trigger", {}, undefined, true)
        ]))
      : _createCommentVNode("", true)
  ]))
}