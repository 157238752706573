import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-4dd52401"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "assessment-mobile-placeholder" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ContentPlaceholdersImg = _resolveComponent("ContentPlaceholdersImg")!
  const _component_ContentPlaceholders = _resolveComponent("ContentPlaceholders")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ContentPlaceholders, null, {
      default: _withCtx(() => [
        (_openBlock(), _createElementBlock(_Fragment, null, _renderList(5, (i) => {
          return _createVNode(_component_ContentPlaceholdersImg, {
            key: i,
            class: "assessment-mobile-placeholder__card"
          })
        }), 64))
      ]),
      _: 1
    })
  ]))
}