<template>
  <div :style="{ backgroundColor, border }" class="px-information-card">
    <div class="px-information-card__left">
      <h3 v-if="title" data-testid="px-information-card-title">
        {{ title }}
      </h3>
      <p
        v-if="description"
        :class="{
          'px-information-card__description--default':
            descriptionStyles === 'default',
          'px-information-card__description--italic':
            descriptionStyles === 'italic',
        }"
        :title="description"
        data-testid="px-information-card-description"
      >
        {{ description }}
      </p>
    </div>
    <div class="px-information-card__right">
      <PxStatusBadge
        v-if="label && $screen.xlUp"
        :background-color="label.backgroundColor"
        :icon="label.icon"
        :icon-size="label.iconSize"
        :label="label.text"
        :label-color="label.textColor"
        :label-size="13"
        :uppercase="false"
        class="px-information-card__label"
        data-testid="px-information-card-label"
        icon-position="left"
      />
      <PxButton
        v-if="cta"
        :label="cta.label"
        :size="cta.size"
        :type="cta.type"
        class="px-information-card__cta"
        data-testid="px-information-card-cta"
        @click="$emit('click')"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import PxStatusBadge from "@/components/px-status-badge/px-status-badge.vue";
import PxButton from "@/components/px-button/px-button.vue";
import {
  IComponentLabel,
  IComponentCta,
} from "@/modules/affiliates/components/common.interface";

export default defineComponent({
  name: "PxInformationCard",

  components: {
    PxStatusBadge,
    PxButton,
  },

  props: {
    title: {
      type: String,
      default: "",
    },

    description: {
      type: String,
      default: "",
    },

    descriptionStyles: {
      type: String,
      default: "default",
      validator: (value: string) => {
        return ["default", "italic"].includes(value);
      },
    },

    border: {
      type: String,
      default: "1px solid rgba(123, 183, 255, 0.8)",
    },

    backgroundColor: {
      type: String,
      default: "rgba(123, 183, 255, 0.05)",
    },

    label: {
      type: Object as () => IComponentLabel | null,
      default: null,
    },

    cta: {
      type: Object as () => IComponentCta | null,
      default: null,
    },
  },
});
</script>

<style lang="scss" scoped>
.px-information-card {
  padding: 13px 23px 24px 19px;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;

  &__left {
    max-width: 500px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 1px;

    h3 {
      margin: 0;

      @include grotesk(semiBold);

      font-size: 16px;
      line-height: 1.2;
    }

    p {
      margin: 0;
      font-size: 16px;
      line-height: 1.2;
      letter-spacing: -0.2px;

      @include multiLineEllipsis(
        $font-size: 16px,
        $line-height: 1.2,
        $lines-to-show: 3
      );
    }
  }

  &__right {
    display: flex;
    gap: 15px;

    .px-button {
      min-width: 72px;
    }

    .px-button span {
      font-size: 13px;
    }
  }

  &__label {
    max-height: 24px;
    gap: 3px;
    margin-top: 3px;
    padding: 0 1px 0 4px;
  }

  &__description--default {
    @include grotesk(semiBold);

    color: $manatee;
  }

  &__description--italic {
    color: $malibu;
    font-style: italic;
  }
}
</style>
