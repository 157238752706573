import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, withCtx as _withCtx, createCommentVNode as _createCommentVNode, createBlock as _createBlock, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-a635529a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "offerings-form__item-column" }
const _hoisted_2 = { class: "offerings-form__item-column" }
const _hoisted_3 = {
  key: 0,
  class: "offerings-form__types"
}
const _hoisted_4 = { class: "offerings-form__item-column" }
const _hoisted_5 = {
  key: 1,
  class: "offerings-form__item-description"
}
const _hoisted_6 = {
  key: 0,
  class: "offerings-form__item-footer"
}
const _hoisted_7 = {
  key: 1,
  class: "offerings-form__item-column"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_OfferingTypesSelect = _resolveComponent("OfferingTypesSelect")!
  const _component_ElTag = _resolveComponent("ElTag")!
  const _component_PxTextarea = _resolveComponent("PxTextarea")!
  const _component_PxButton = _resolveComponent("PxButton")!
  const _component_ElFormItem = _resolveComponent("ElFormItem")!

  return (_openBlock(), _createBlock(_component_ElFormItem, {
    ref: "formItem",
    class: _normalizeClass([{ 'is-editing': _ctx.isEditing }, "offerings-form__item"]),
    prop: "offer"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("label", null, _toDisplayString(_ctx.$t("profile.supporter.offerings.form.label.categories")), 1),
        _createElementVNode("h3", null, _toDisplayString(_ctx.category.name), 1)
      ]),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("label", null, _toDisplayString(_ctx.$t("profile.supporter.offerings.form.label.types")), 1),
        _withDirectives(_createVNode(_component_OfferingTypesSelect, {
          ref: "typesSelect",
          modelValue: _ctx.offerCategoryTypes,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.offerCategoryTypes) = $event)),
          types: _ctx.category.category_types,
          onChange: _ctx.changedTypesHandler
        }, null, 8, ["modelValue", "types", "onChange"]), [
          [_vShow, _ctx.isEditing && _ctx.offerCategoryTypes]
        ]),
        (!_ctx.isEditing && _ctx.offerCategoryTypes && _ctx.offerCategoryTypes.length)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.offerCategoryTypes, (item) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: item.id
                }, [
                  _createVNode(_component_ElTag, { effect: "plain" }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(item.name), 1)
                    ]),
                    _: 2
                  }, 1024)
                ]))
              }), 128))
            ]))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("label", null, _toDisplayString(_ctx.$t("profile.supporter.offerings.form.label.description")), 1),
        (_ctx.isEditing)
          ? (_openBlock(), _createBlock(_component_PxTextarea, {
              key: 0,
              ref: "description",
              modelValue: _ctx.offer.description,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.offer.description) = $event)),
              autosize: { minRows: 4, maxRows: 10 },
              "max-chars": 400,
              placeholder: 
          _ctx.$t('profile.supporter.offerings.form.placeholder.description')
        ,
              "show-counter": ""
            }, null, 8, ["modelValue", "placeholder"]))
          : (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(_ctx.offer.description), 1))
      ]),
      (_ctx.isEditing)
        ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
            _createVNode(_component_PxButton, {
              class: "offerings-form__cancel-btn",
              type: "link",
              onClick: _ctx.onClickCancel
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t("common.cancel")), 1)
              ]),
              _: 1
            }, 8, ["onClick"]),
            _createVNode(_component_PxButton, {
              disabled: !_ctx.offerChanged,
              loading: _ctx.isLoading,
              class: "offerings-form__save-btn",
              type: "green",
              onClick: _ctx.onClickSave
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t("common.save")), 1)
              ]),
              _: 1
            }, 8, ["disabled", "loading", "onClick"])
          ]))
        : _createCommentVNode("", true),
      (!_ctx.isEditing)
        ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
            _createVNode(_component_PxButton, {
              class: "offerings-form__edit-btn",
              size: "small",
              type: "default",
              onClick: _ctx.onClickEdit
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t("common.edit")), 1)
              ]),
              _: 1
            }, 8, ["onClick"]),
            _createVNode(_component_PxButton, {
              loading: _ctx.isLoading,
              class: "offerings-form__remove-btn",
              size: "small",
              type: "default",
              onClick: _ctx.onClickRemove
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t("common.remove")), 1)
              ]),
              _: 1
            }, 8, ["loading", "onClick"])
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["class"]))
}