<template>
  <div class="milestone-plan-navigation-bar">
    <MilestoneCategoryNavigation
      :categories="categories"
      :current-category="selectedCategory"
      @change="changeCategory"
    />
    <MilestoneLevelNavigation
      :active-level="selectedLevel"
      :category-color="selectedCategory.color"
      :category-name="selectedCategory.name"
      :milestones="milestones"
      @change="changeLevel"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import MilestoneLevelNavigation from "@/modules/milestone-planner/components/milestone-level-navigation/milestone-level-navigation.vue";
import MilestoneCategoryNavigation from "@/modules/milestone-planner/components/milestone-category-navigation/milestone-category-navigation.vue";
import {
  IGridCategory,
  IGridMilestone,
} from "@/modules/milestone-planner/components/milestones-grid/milestones-grid.interface";

export default defineComponent({
  name: "MilestonePlanNavigationBar",

  components: {
    MilestoneLevelNavigation,
    MilestoneCategoryNavigation,
  },

  props: {
    categories: {
      type: Array as () => IGridCategory[],
      required: true,
    },
    currentCategory: {
      type: Object as () => IGridCategory,
      required: true,
    },
    activeLevel: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      selectedLevel: this.activeLevel,
      selectedCategory: this.currentCategory,
    };
  },

  computed: {
    milestones(): IGridMilestone[] {
      const category = this.categories.filter(
        (category) => category.name === this.selectedCategory.name,
      );
      return category[0].milestones;
    },
  },

  watch: {
    currentCategory: function (category: IGridCategory) {
      this.selectedCategory = category;
    },

    activeLevel: function (level: number) {
      this.selectedLevel = level;
    },
  },

  methods: {
    changeCategory(category: IGridCategory) {
      if (category !== this.selectedCategory) {
        this.selectedCategory = category;
        this.$emit("update:currentCategory", category);
      }
    },

    changeLevel(level: number) {
      if (level !== this.selectedLevel) {
        this.selectedLevel = level;
        this.$emit("update:activeLevel", level);
      }
    },
  },
});
</script>

<style lang="scss" scoped>
.milestone-plan-navigation-bar {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 136px;
}
</style>
