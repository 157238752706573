import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, mergeProps as _mergeProps, withModifiers as _withModifiers, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "matching-directory-member-modal__description" }
const _hoisted_2 = { class: "matching-directory-member-modal__text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PxModalMessage = _resolveComponent("PxModalMessage")!
  const _component_PxIcon = _resolveComponent("PxIcon")!
  const _component_i18n_t = _resolveComponent("i18n-t")!
  const _component_ElDivider = _resolveComponent("ElDivider")!
  const _component_PxButton = _resolveComponent("PxButton")!
  const _component_ElDialog = _resolveComponent("ElDialog")!

  return (_openBlock(), _createBlock(_component_ElDialog, {
    ref: "modal",
    modelValue: _ctx.innerVisibility,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.innerVisibility) = $event)),
    "append-to-body": true,
    "before-close": _ctx.beforeCloseHandler,
    class: "el-dialog--no-header el-dialog--compact-footer matching-directory-member-modal",
    width: "500px"
  }, {
    footer: _withCtx(() => [
      _createVNode(_component_PxButton, _mergeProps(_ctx.interestButtonProps, { onClick: _ctx.onClickAddInterestButton }), null, 16, ["onClick"]),
      (_ctx.$screen.lgUp)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.$t("common.or")), 1),
            _createVNode(_component_PxButton, _mergeProps(_ctx.addToListButtonProps, {
              onClick: _withModifiers(_ctx.onShowListManagementModal, ["stop"])
            }), null, 16, ["onClick"])
          ], 64))
        : _createCommentVNode("", true)
    ]),
    default: _withCtx(() => [
      _createVNode(_component_PxModalMessage, { visible: _ctx.hasServerError }, null, 8, ["visible"]),
      _createVNode(_component_i18n_t, {
        class: "matching-directory-member-modal__title",
        keypath: "matching.matchingInterest.directoryMemberModal.title",
        tag: "h2"
      }, {
        directoryIcon: _withCtx(() => [
          _createVNode(_component_PxIcon, {
            size: _ctx.$options.static.iconSize,
            class: "matching-directory-member-modal__icon",
            name: "icon-directory-default"
          }, null, 8, ["size"])
        ]),
        _: 1
      }),
      _createElementVNode("p", _hoisted_1, _toDisplayString(_ctx.$t(`matching.matchingInterest.directoryMemberModal.description`)), 1),
      _createVNode(_component_ElDivider)
    ]),
    _: 1
  }, 8, ["modelValue", "before-close"]))
}