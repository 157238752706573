import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-515b95fc"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "team-member-settings-header__content" }
const _hoisted_2 = {
  key: 0,
  class: "team-member-settings-header__actions"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PxButton = _resolveComponent("PxButton")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([{
      'team-member-settings-header--from-profile': _ctx.isFromProfile,
    }, "team-member-settings-header"])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("h4", null, _toDisplayString(_ctx.title), 1),
      _createElementVNode("p", null, _toDisplayString(_ctx.subtitle), 1)
    ]),
    (_ctx.isFromProfile)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_PxButton, _mergeProps(_ctx.saveButtonProps, {
            disabled: _ctx.isSaveButtonDisabled,
            class: "team-member-settings-header__save-button",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('save-members')))
          }), null, 16, ["disabled"])
        ]))
      : _createCommentVNode("", true)
  ], 2))
}