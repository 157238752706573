import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-168461b2"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  ref: "datePicker",
  class: "px-date-picker"
}
const _hoisted_2 = {
  key: 0,
  class: "px-date-picker__label"
}
const _hoisted_3 = ["textContent"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PxIcon = _resolveComponent("PxIcon")!
  const _component_ElTooltip = _resolveComponent("ElTooltip")!
  const _component_ElDatePicker = _resolveComponent("ElDatePicker")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.label)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createTextVNode(_toDisplayString(_ctx.label) + " ", 1),
          (_ctx.tooltip)
            ? (_openBlock(), _createBlock(_component_ElTooltip, {
                key: 0,
                "popper-class": "px-date-picker__tooltip-popper el-abaca-tooltip"
              }, {
                content: _withCtx(() => [
                  _createElementVNode("div", {
                    textContent: _toDisplayString(_ctx.tooltip)
                  }, null, 8, _hoisted_3)
                ]),
                default: _withCtx(() => [
                  _createVNode(_component_PxIcon, {
                    size: 14,
                    class: "px-date-picker__tooltip-icon",
                    name: "question"
                  })
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    _createVNode(_component_ElDatePicker, {
      ref: "elDatePicker",
      modelValue: _ctx.innerValue,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.innerValue) = $event)),
      clearable: false,
      format: _ctx.dateFormat,
      placeholder: _ctx.$t('common.components.datePicker.placeholder'),
      align: "center",
      "popper-class": "px-date-picker__picker-popper",
      type: "date",
      onBlur: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onDatePickerBlur())),
      onFocus: _cache[2] || (_cache[2] = ($event: any) => (_ctx.onDatePickerFocus()))
    }, null, 8, ["modelValue", "format", "placeholder"])
  ], 512))
}