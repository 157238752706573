<template>
  <ElOption ref="option" :value="innerItem" class="multi-selector__option">
    <ElCheckbox
      ref="sticky"
      :model-value="isSelected"
      :class="{
        'multi-selector__checkbox-sticky--shadow': showStickyShadow,
        'multi-selector__checkbox--collapsed': isCollapsed,
      }"
      :indeterminate="isIndeterminate"
      class="multi-selector__option-item multi-selector__checkbox multi-selector__checkbox-sticky"
      @change="onItemClick(innerItem)"
    >
      <span
        class="multi-selector__label"
        @click="labelClickHandler"
        v-html="highlightLabelByQuery(innerItem.label, hasChildren)"
      />
      <span
        v-if="hasChildren"
        class="multi-selector__label-subtext"
        @click.prevent="childrenCountClickHandler"
      >
        {{ childrenCountCopy(innerItem.children) }}
      </span>
    </ElCheckbox>
    <ElCollapseTransition>
      <div v-if="!isCollapsed" class="multi-selector__option-container">
        <template
          v-for="child in visibleChildren"
          :key="`${innerItem.value}-${child.value}`"
        >
          <ElCheckbox
            :model-value="child.selected"
            class="multi-selector__option-item multi-selector__checkbox"
            @change="onItemClick(child)"
          >
            <span v-html="highlightLabelByQuery(child.label)" />
          </ElCheckbox>
        </template>
        <span
          v-if="visibleShowAll"
          class="multi-selector__option-item multi-selector__show-all-text"
          @click="onShowAllItemsClick"
        >
          {{ $t("common.components.multiSelector.showAll") }}
        </span>
      </div>
    </ElCollapseTransition>
  </ElOption>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { ElCollapseTransition } from "element-plus";

import { IMultiSelectorItem } from "@/components/multi-selector/multi-selector.interface";
import throttle from "lodash/throttle";

export default defineComponent({
  name: "MultiSelectorOption",

  components: { ElCollapseTransition },

  props: {
    item: {
      type: Object as () => IMultiSelectorItem,
      required: true,
    },
    selectedItems: {
      type: Array as () => Array<IMultiSelectorItem>,
      required: true,
    },
    highlightText: {
      type: String,
      required: true,
    },
    subtextLabelList: {
      type: Array as () => Array<string>,
      default: () => [
        "common.components.default.input.subLabel",
        "common.components.default.input.subLabelPlural",
      ],
    },
    startsCollapsed: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      innerItem: {} as IMultiSelectorItem,
      externalSelectedItems: [] as Array<IMultiSelectorItem>,
      isSelected: false,
      isCollapsed: true,

      visibleChildLimit: 3,
      showAllItems: false,
      showStickyShadow: false,
      optionElementHeight: 0,
    };
  },

  computed: {
    hasChildren(): boolean {
      return !!this.innerItem.children && !!this.innerItem.children.length;
    },

    hasSelectedChildren(): boolean {
      return (
        !!this.innerItem.children &&
        this.innerItem.children.some(
          (item: IMultiSelectorItem) => item.selected,
        )
      );
    },

    isIndeterminate(): boolean {
      return !this.isSelected && this.hasSelectedChildren;
    },

    remainSelectedItems(): Array<IMultiSelectorItem> {
      return this.selectedItems.filter(
        (selItem: IMultiSelectorItem) => selItem.value !== this.item.value,
      );
    },

    visibleChildren(): Array<IMultiSelectorItem> {
      return this.innerItem.children
        ? this.showAllItems
          ? this.innerItem.children
          : this.innerItem.children.slice(0, this.visibleChildLimit)
        : [];
    },

    visibleShowAll(): boolean {
      return (
        !!this.innerItem.children &&
        this.innerItem.children.length > this.visibleChildLimit &&
        !this.showAllItems
      );
    },
  },

  watch: {
    item: {
      deep: true,
      handler() {
        // Update inner item with current selection
        this.syncSelectedItems(this.selectedItems);
      },
    },

    selectedItems: {
      deep: true,
      handler(newSelection: Array<IMultiSelectorItem>) {
        // Update inner item when selected items change
        this.syncSelectedItems(newSelection);
      },
    },
  },

  created() {
    // Set collapsed state
    this.isCollapsed = this.startsCollapsed;

    // Update inner item with current selection
    this.syncSelectedItems(this.selectedItems);
  },

  mounted() {
    const selectScrollerElement: HTMLElement | null = document.querySelector(
      ".el-scrollbar__wrap",
    );
    const optionContainerElement = this.$refs.option as any;
    const stickyElement = this.$refs.sticky as any;

    if (
      !!selectScrollerElement &&
      !!optionContainerElement &&
      !!stickyElement
    ) {
      selectScrollerElement.addEventListener(
        "scroll",
        throttle(() => {
          this.onSelectScroll(selectScrollerElement);
          this.onOptionScroll(optionContainerElement.$el, stickyElement.$el);
        }, 100),
        {
          passive: true,
          capture: false,
        },
      );
    }
  },

  beforeUnmount() {
    const selectScrollerElement = document.querySelector(".el-scrollbar__wrap");

    if (selectScrollerElement) {
      window.removeEventListener(
        "scroll",
        throttle(() => this.onOptionScroll, 100),
      );
    }
  },

  methods: {
    async onSelectScroll(selectEl: HTMLElement) {
      // margin to trigger right before scrolling the last two options
      const triggerMargin = this.optionElementHeight * 2;
      const scrollAtBottom =
        selectEl.scrollTop >
        selectEl.scrollHeight - selectEl.offsetHeight - triggerMargin;

      if (scrollAtBottom) {
        this.$emit("scrolled-bottom");
      }
    },

    onOptionScroll(container: any, stickyElement: any) {
      this.showStickyShadow =
        container.getBoundingClientRect().top <
        stickyElement.getBoundingClientRect().top;

      if (!this.optionElementHeight) {
        this.optionElementHeight = container.offsetHeight;
      }
    },

    /**
     * Updates inner item value and current selected state
     */
    updateInnerItem() {
      // Get item external selected state for pre-fill
      const initSelectState = this.item.children
        ? false
        : this.getExternalSelectedState(this.item);

      // Map children items, with external selected state pre-fill
      const initChildren = this.item.children
        ? this.item.children.map((child: IMultiSelectorItem) => ({
            ...child,
            selected: this.getExternalSelectedState(child),
          }))
        : [];

      // Check if all children items are selected
      const allChildrenSelected = this.item.children
        ? !initChildren.some((child: IMultiSelectorItem) => !child.selected)
        : false;

      this.innerItem = {
        ...this.item,
        children: [...initChildren],
        selected: allChildrenSelected || initSelectState || false,
      };
      this.isSelected = this.innerItem.selected;
    },

    /**
     * Synchronizes selected items with current item and children
     *
     * @param selectedItems
     */
    syncSelectedItems(selectedItems: Array<IMultiSelectorItem>) {
      this.externalSelectedItems = selectedItems.reduce(
        (items: Array<IMultiSelectorItem>, item: IMultiSelectorItem) => {
          return [
            ...items,
            ...(item.selected ? [item] : []),
            ...(item.children
              ? item.children.filter(
                  (children: IMultiSelectorItem) => children.selected,
                )
              : []),
          ];
        },
        [],
      );

      this.updateInnerItem();
    },

    /**
     * Fetches parent component current selected state, allowing to
     * pre-fill the selections
     */
    getExternalSelectedState(item: IMultiSelectorItem) {
      return this.externalSelectedItems.some(
        (externalItem: IMultiSelectorItem) =>
          externalItem.label === item.label && externalItem.selected,
      );
    },

    /**
     * When a new item is selected it is pushed into the
     * array of selected items and the autocomplete fill
     * cleared.
     *
     * @param clickedItem
     */
    onItemClick(clickedItem: IMultiSelectorItem) {
      // If the selected item is the main one, invert selected state
      if (
        this.innerItem.value === clickedItem.value &&
        this.innerItem.label === clickedItem.label
      ) {
        this.isSelected = !this.isSelected;
        this.innerItem.selected = this.isSelected;

        // Update child items selected state
        if (this.innerItem.children) {
          this.innerItem.children = [
            ...this.innerItem.children.map((child: IMultiSelectorItem) => ({
              ...child,
              selected: this.isSelected,
            })),
          ];
        }
      } else if (this.innerItem.children) {
        this.innerItem.children = [
          ...this.innerItem.children.map((child: IMultiSelectorItem) =>
            child.value === clickedItem.value
              ? {
                  ...child,
                  selected: !child.selected,
                }
              : child,
          ),
        ];

        // If one child is not selected, then unselect item
        this.isSelected = !this.innerItem.children.some(
          (child: IMultiSelectorItem) => !child.selected,
        );
      }

      const remainingItems = this.remainSelectedItems.map((item) => {
        if (!item.children) return item;
        const uniqueChildren = item.children.filter(
          (child) =>
            child.value !== this.innerItem.value ||
            (this.innerItem.children &&
              this.innerItem.children.every(
                (innerChild) => innerChild.value !== child.value,
              )),
        );
        return { ...item, children: uniqueChildren };
      });

      if (this.isSelected || this.hasSelectedChildren) {
        this.$emit("update", [...remainingItems, this.innerItem]);
      } else {
        this.$emit("update", remainingItems);
      }
    },

    /**
     * Flag if the user wants to see all items
     */
    onShowAllItemsClick() {
      this.showAllItems = true;
    },

    labelClickHandler(event: any) {
      if (
        event.srcElement &&
        event.srcElement.className.includes("multi-selector__collapse")
      ) {
        // Prevent side effects like selecting options
        event.preventDefault();
        event.stopImmediatePropagation();

        // Toggle collapse
        this.isCollapsed = !this.isCollapsed;
      }
    },

    childrenCountClickHandler() {
      // Toggle full list of children either when collapsed or not.
      if (this.isCollapsed) {
        this.isCollapsed = !this.isCollapsed;
        this.showAllItems = true;
      } else {
        this.showAllItems = !this.showAllItems;
      }
    },

    /**
     * Wrap the last word of a label with the
     * collapse icon in a span to avoid
     * line-breaks containing only the icon.
     *
     * @param label
     */
    wrapLabelWithCollapseIcon(label: string): string {
      const words = label.split(" ");
      const lastWord = words.pop();
      return `${words.join(" ")} <span class="multi-selector__label-suffix">
          ${lastWord} <img alt="option-collapse" src="/img/icons/caret--gray.svg" class="multi-selector__collapse"/>
        </span>`;
    },

    /**
     * Highlight option text.
     *
     * @param label
     * @param hasChildren
     */
    highlightLabelByQuery(label: string, hasChildren = false): string {
      if (hasChildren) {
        label = this.wrapLabelWithCollapseIcon(label);
      }

      if (this.highlightText.length < 2) {
        return label;
      }

      return label.replace(
        new RegExp(this.highlightText, "gi"),
        `<span class="multi-selector__highlight-text">$&</span>`,
      );
    },

    /**
     * Fetches a compose copy with the current children count, if applicable
     * @param children
     */
    childrenCountCopy(children: Array<IMultiSelectorItem> | undefined) {
      if (children === undefined) {
        return "";
      }

      return `${children.length} ${
        children.length > 1
          ? this.$t(this.subtextLabelList[1])
          : this.$t(this.subtextLabelList[0])
      }`;
    },
  },
});
</script>

<style lang="scss" scoped>
.multi-selector__option {
  display: flex;
  flex-direction: column;
  height: auto;
  padding: 0;
  overflow: initial;
  text-transform: capitalize;

  &::before {
    display: none;
  }

  &.hover {
    height: auto;
    background: $alabaster;
  }
}

.multi-selector__option-item {
  margin-bottom: 0;
  background: $white;
}

.multi-selector__option-container {
  display: flex;
  flex-direction: column;

  :deep() .el-checkbox {
    margin-left: 30px;
  }
}

.multi-selector__option.hover .multi-selector__option-item {
  background: $alabaster;
}

.multi-selector__checkbox {
  position: relative;
  z-index: z("default") + 9;
  padding: 0 15px;

  &:last-child {
    padding-bottom: 7px;
  }

  .multi-selector__label {
    display: inline-block;
    max-width: 60%;
  }

  .multi-selector__label-subtext {
    position: absolute;
    right: 10px;
    font-size: 13px;
    color: $manatee;
    text-transform: lowercase;
  }

  :deep() .multi-selector__label-suffix {
    display: inline-block;
  }

  :deep() .multi-selector__collapse {
    display: inline;
    width: 14px;
    height: 14px;
    vertical-align: -3px;
    transition: transform 100ms ease-in-out;
    transform: rotate(180deg);
  }

  :deep() .el-checkbox__inner {
    width: 18px;
    height: 18px;
  }

  :deep() .el-checkbox__label {
    top: -1px;
    display: inline-block;
    width: calc(100% - 10px);
    white-space: normal;
    vertical-align: text-top;
  }

  :deep() .multi-selector__highlight-text {
    @include grotesk(bold);
  }
}

.multi-selector__checkbox.multi-selector__checkbox-sticky {
  position: sticky;
  top: 0;
  z-index: z("default") + 10;
  padding: 4px 15px;
  margin: 0;
  box-shadow: 1px 2px 1px -1px rgba($mischka, 0);

  transition: box-shadow 100ms ease-in-out;

  &.multi-selector__checkbox-sticky--shadow {
    box-shadow: 1px 2px 1px -1px rgba($mischka, 1);
  }

  &.multi-selector__checkbox--collapsed :deep() .multi-selector__collapse {
    transform: rotate(0);
  }
}

.multi-selector__show-all-text {
  @include grotesk(semiBold);

  position: relative;
  z-index: z("default") + 9;
  padding: 4px 0 12px 30px;
  color: $manatee;
}
</style>
