import { defineComponent } from "vue";

import {
  allFormFieldsValid,
  generateEmailValidator,
  generatePasswordCheck,
  generatePasswordValidator,
  generateRequiredValidator,
  generateSupporterLocationsValidator,
  generateSupporterTypesValidator,
  generateCheckboxValidator,
} from "@/services/errors/validator-generators";
import { SUPPORTERS_AUTHENTICATION_LOGIN } from "@/modules/supporters/services/router/routes-names";
import { ErrorsManager } from "@/services/errors-manager";
import { FormInstance } from "element-plus";
import { ISupporterFlow } from "@/modules/supporters/services/data/supporter-flow/supporter-flow.interface";
import {
  ISupporterCtasCopy,
  ISupporterFormCopy,
} from "@/modules/supporters/services/data/copy-types/supporter-copy-types";

export default defineComponent({
  props: {
    hasPreviousPage: {
      type: Boolean,
      required: true,
    },

    viewLoading: {
      type: Boolean,
      required: true,
    },

    formLoading: {
      type: Boolean,
      required: true,
    },

    skipEnabled: {
      type: Boolean,
      default: false,
    },

    flowData: {
      type: Object as () => ISupporterFlow,
      required: true,
    },
  },

  data() {
    return {
      isSubmissionDisabled: true,

      currentForm: null as null | FormInstance,

      // Will be overridden in each sub-views
      fields: null as ISupporterFlow | null,

      errors: new ErrorsManager({
        email: {
          unique: this.$t("authentication.signup.form.errors.email.unique"),
        },
      }),

      rules: {
        email: generateEmailValidator(
          this,
          "authentication.signup.form.fields.email",
          true,
        ),

        password1: generatePasswordValidator(
          this,
          "supporters.form.password.label",
          {
            minimum: 8,
            enforceCharRequirements: true,
          },
        ),

        password2: generatePasswordCheck(this, "fields.password1", {
          minimum: 8,
        }),

        "company.name": generateRequiredValidator(
          this,
          "supporters.signup.form.fields.supporterName",
        ),

        "company.location": generateRequiredValidator(
          this,
          "supporters.signup.form.fields.location",
        ),

        "supporter.types": generateSupporterTypesValidator(
          this,
          "fields.supporter.other_type",
        ),

        "meta.locations": generateSupporterLocationsValidator(
          this,
          "fields.meta.hasSpecificLocations",
        ),

        terms: generateCheckboxValidator(),
      },
    };
  },

  computed: {
    formCopy() {
      return this.$tm("supporters.form") as ISupporterFormCopy;
    },

    ctaCopy() {
      return this.$tm("supporters.cta") as ISupporterCtasCopy;
    },
  },

  mounted() {
    (this as any).currentForm = this.$refs.supporterRegistrationForm;
  },

  methods: {
    /**
     * Handle the Google sign up button click.
     */
    onGoogleSignUpButtonClick() {
      // TODO: sign up with Google logic
    },

    /**
     * Handle the login button click.
     */
    onLoginButtonClick() {
      this.$router.push({ name: SUPPORTERS_AUTHENTICATION_LOGIN });
    },

    /**
     * Handle the return button click.
     */
    onReturnButtonClick() {
      this.$emit("previous-page");
    },

    /**
     * Handle the next button click.
     */
    onNextButtonClick() {
      // Spread this fields to avoid store injection via memory reference
      const submissionData = this.fields ? { ...this.fields } : undefined;

      this.$emit("next-page", submissionData, this.errors);
    },

    /**
     * Handle skip criteria button click.
     */
    onSkipButtonClick() {
      // TODO: add skip logic

      this.$emit("skip-page");
    },

    /**
     * Handle skip all criteria button click.
     */
    onSkipAllButtonClick() {
      // TODO: add skip logic

      this.$emit("skip-all");
    },

    /**
     * Checks if form is ready to be submitted
     */
    updateSubmitState() {
      this.isSubmissionDisabled = !allFormFieldsValid(
        this.currentForm,
        this.rules,
      );
    },
  },
});
