import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "charts-table-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ChartsTable = _resolveComponent("ChartsTable")!
  const _component_ChartsComparisonTable = _resolveComponent("ChartsComparisonTable")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_ctx.dataToCompare)
      ? (_openBlock(), _createBlock(_component_ChartsTable, {
          key: 0,
          labels: _ctx.labels,
          data: _ctx.tableTotal,
          percentages: _ctx.tablePercentages,
          colors: _ctx.colors,
          "first-column-header": _ctx.theme,
          average: _ctx.tableAverage
        }, null, 8, ["labels", "data", "percentages", "colors", "first-column-header", "average"]))
      : (_openBlock(), _createBlock(_component_ChartsComparisonTable, {
          key: 1,
          headers: _ctx.comparisonTableHeaders,
          labels: _ctx.labels,
          colors: _ctx.colors,
          data: _ctx.dataToCompare.datasets
        }, null, 8, ["headers", "labels", "colors", "data"]))
  ]))
}