<template>
  <div class="match-details-modal-table">
    <PxTableHeader :items="tableHeaderWithUserType" />
    <div
      ref="table"
      class="match-details-modal-table__wrapper"
      @scroll="checkTableScroll"
    >
      <template v-for="(data, index) in tableData">
        <MatchDetailsTableRow
          v-if="data.criteria"
          :key="index"
          :auth-user-answer="data.my_answer"
          :criteria="data.criteria"
          :has-match="data.match"
          :impact="data.impact"
          :profile-user-answer="data.matched_answer"
          class="match-details-modal-table__row"
        />
      </template>
    </div>
    <div v-if="isGradientVisible" class="match-details-modal-table__gradient" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import MatchDetailsTableRow from "@/components/match-details-table/match-details-table-row.vue";
import { IMatchDetails } from "@/modules/matching/services/store/match-score-details/match-score-details.types";
import PxTableHeader, {
  ITableHeaderItems,
} from "@/components/px-table-header/px-table-header.vue";

export default defineComponent({
  name: "MatchDetailsModalTable",

  components: {
    MatchDetailsTableRow,
    PxTableHeader,
  },

  props: {
    userType: {
      type: Number,
      default: 0,
    },

    tableData: {
      type: Array as () => Array<IMatchDetails>,
      required: true,
    },
  },

  data() {
    return {
      visibleRows: 5,
      tableScrollAtEnd: false,
      table: (null as any) || null,
    };
  },

  computed: {
    isGradientVisible(): boolean {
      return this.tableData.length > this.visibleRows && !this.tableScrollAtEnd;
    },

    userTypeCopy(): string {
      return this.$t(`common.userTypes[${+!this.userType}]`);
    },

    tableHeaders() {
      return this.$tm(
        "matching.matchDetails.table.header",
      ) as ITableHeaderItems[];
    },

    tableHeaderWithUserType(): any {
      return this.tableHeaders.map((headers: ITableHeaderItems) => {
        return {
          ...headers,
          title: headers.title.replace("{userType}", this.userTypeCopy),
        };
      });
    },
  },

  unmounted() {
    this.table = null;
  },

  methods: {
    checkTableScroll() {
      this.table = this.$refs.table as HTMLElement;

      if (!this.table) return;

      this.tableScrollAtEnd =
        this.table.scrollTop ===
        this.table.scrollHeight - this.table.offsetHeight;
    },
  },
});
</script>

<style lang="scss" scoped>
.match-details-modal-table {
  position: relative;

  :deep() .px-table-header {
    padding: 2px 0 10px 0;

    &__item {
      max-width: 201px;
    }

    &__item:nth-child(-n + 3) {
      flex: 2;
    }

    &__item:nth-last-child(-n + 2) {
      flex: 1;
      padding: 0;
      justify-content: center;
      text-align: center;
    }

    &__item .icon-question {
      position: relative;
      right: 2px;
      bottom: 1px;
    }
  }

  &__wrapper {
    max-height: 280px;
    overflow-x: hidden;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background: $mischka;
      border-radius: 10px;
    }

    .match-details-modal-table__row {
      min-height: 56px;
      box-sizing: border-box;
    }

    div:not(:last-of-type) {
      border-bottom: 1px solid $athens-gray;
    }
  }

  &__loading {
    margin-bottom: 10px;
  }

  &__gradient {
    position: absolute;
    bottom: 0;
    width: 842px;
    height: 25px;
    background: linear-gradient(
      to top,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 1) 20%,
      rgba(247, 249, 249, 0) 100%
    );
  }
}
</style>
