<template>
  <div class="px-sticky-bar">
    <div class="px-sticky-bar__wrapper">
      <slot />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "PxStickyBar",
});
</script>

<style lang="scss" scoped>
.px-sticky-bar {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: z("navigation") + 1;

  color: white;
  background-color: $ebony-clay;
}

.px-sticky-bar__wrapper {
  @include container();

  display: flex;
  flex-flow: row wrap;
  align-items: center;
}
</style>
