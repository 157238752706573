<template>
  <ElDialog
    ref="onboarding-modal"
    v-model="innerVisibility"
    :append-to-body="true"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :show-close="false"
    class="capital-explorer-onboarding-modal"
    data-testid="capital-explorer-onboarding-modal"
    top="9vh"
    width="740px"
  >
    <template #header>
      <h3>
        {{ copy.title }}
      </h3>
      <p>
        {{ copy.subtitle }}
      </p>
    </template>
    <p
      class="capital-explorer-onboarding-modal__description"
      v-html="copy.description"
    />
    <img
      :src="copy.image"
      alt="onboarding-modal-image"
      class="capital-explorer-onboarding-modal__image"
    />
    <ElForm
      ref="form"
      :model="form"
      :rules="rules"
      data-testid="onboarding-modal-form"
      @validate="updateContinueState"
    >
      <ElFormItem :show-message="false" prop="terms" required>
        <ElCheckbox
          v-model="form.terms"
          class="capital-explorer-onboarding-modal__terms"
          data-testid="capital-explorer-onboarding-modal-terms"
        >
          {{ copy.terms }}
        </ElCheckbox>
      </ElFormItem>
    </ElForm>
    <div class="capital-explorer-onboarding-modal__cta">
      <PxButton
        v-bind="buttonProps"
        :disabled="!continueToFeature"
        data-testid="capital-explorer-onboarding-modal-cta"
        @click="submit"
      />
    </div>
  </ElDialog>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import ElDialogMixin from "@/mixins/el-dialog.mixin";

import { EPxButtonType } from "@/components/px-button/px-button.types";
import {
  generateCheckboxValidator,
  allFormFieldsValid,
} from "@/services/errors/validator-generators";
import { ECapitalExplorerActions } from "@/modules/capital-explorer/services/store/capital-explorer/capital-explorer.types";

export default defineComponent({
  name: "OnboardingModal",

  mixins: [ElDialogMixin],

  data() {
    return {
      copy: (this.$user.isSupporter()
        ? this.$tm("capitalExplorer.supportersOnboardingModal")
        : this.$tm("capitalExplorer.onboardingModal")) as {
        title: string;
        subtitle: string;
        description: string;
        image: string;
        terms: string;
        action?: string;
      },
      form: {
        terms: false,
      },
      rules: {
        terms: generateCheckboxValidator(),
      },
      continueToFeature: false,
    };
  },

  computed: {
    buttonProps(): any {
      return {
        type: EPxButtonType.GREEN,
        label: this.$user.isSupporter()
          ? this.copy.action
          : this.$t("common.continue"),
      };
    },

    termsForm(): HTMLElement {
      return this.$refs.form as HTMLElement;
    },
  },

  methods: {
    updateContinueState() {
      this.continueToFeature = allFormFieldsValid(this.termsForm, this.rules);
    },

    submit() {
      this.$store.dispatch(ECapitalExplorerActions.GRANT_CONSENT, true);
    },
  },
});
</script>

<style lang="scss">
.capital-explorer-onboarding-modal {
  .el-dialog__header {
    padding: 30px 0 33px 0;
    border-bottom: 1px solid rgb(206, 219, 210);
    text-align: center;
    background-image: url("#{$assetsPath}/img/illustrations/capital-explorer/onboarding-header.svg");

    h3 {
      margin: 0;

      font-size: 20px;
      line-height: 30px;
      letter-spacing: -0.18px;
    }

    p {
      margin: 2px 0 0 0;
      font-size: 17px;
      line-height: 22px;
      letter-spacing: -0.37px;
    }
  }

  .el-dialog__body {
    padding: 33px 40px 32px 40px;
  }

  &__description {
    color: #000;
    font-size: 17px;
    line-height: 27.2px;
    letter-spacing: -0.36px;

    strong {
      letter-spacing: -0.12px;
    }
  }

  &__image {
    display: block;
    margin: 15px auto 23px auto;
    width: auto;
    height: auto;
  }

  &__terms {
    display: flex;
    white-space: initial;

    .el-checkbox__label {
      padding-left: 18px;
      color: $manatee;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: -0.27px;
    }

    .el-checkbox__inner {
      top: 2px;
      left: 2px;
      width: 22px;
      height: 22px;
    }
  }

  &__cta {
    display: flex;
    justify-content: center;
    margin-top: 25px;

    .px-button {
      padding: 0;
      width: 178px;
    }
  }
}
</style>
