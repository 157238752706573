import { ActionTree } from "vuex";
import { RootState } from "@/services/store/root-state";
import { getBaseName } from "@/services/store/utils/get-base-name";
import { milestonePlannersProvider } from "@/modules/milestone-planner/services/data/milestone-planners/milestone-planners.provider";
import { IMilestonePlanner } from "@/modules/milestone-planner/services/store/milestone-planner/milestone-planner.types";

import {
  EAuthMilestonePlannersActions,
  EAuthMilestonePlannersMutations,
  IAuthMilestonePlannersState,
} from "@/modules/authentication/services/store/auth/sub-modules/auth-milestone-planners/auth-milestone-planners.types";

export const actions: ActionTree<IAuthMilestonePlannersState, RootState> = {
  /**
   * Get authenticated user's milestone planners.
   *
   * @param {*} { commit }
   */
  async [getBaseName(EAuthMilestonePlannersActions.FETCH)]({ commit }) {
    commit(EAuthMilestonePlannersMutations.SET_LOADING, true);
    commit(EAuthMilestonePlannersMutations.SET_ERROR, null);

    try {
      const data = await milestonePlannersProvider.list();
      commit(EAuthMilestonePlannersMutations.SET_DATA, data);
    } catch (error) {
      commit(EAuthMilestonePlannersMutations.SET_ERROR, error);
      commit(EAuthMilestonePlannersMutations.SET_DATA, null);
    } finally {
      commit(EAuthMilestonePlannersMutations.SET_LOADING, false);
    }
  },

  /**
   * Get authenticated user's first and only milestone planner.
   *
   * @param {*} {
   *     commit,
   *     dispatch,
   *     state,
   *   }
   */
  async [getBaseName(EAuthMilestonePlannersActions.FETCH_DEFAULT_PLANNER)]({
    commit,
    dispatch,
    state,
  }) {
    await dispatch(EAuthMilestonePlannersActions.FETCH, null, {
      root: true,
    });

    // Since, for now, we only have one milestone planner, we are setting the default as the only one that exists
    const defaultMilestonePlanner = state.data.length ? state.data[0] : [];
    commit(
      EAuthMilestonePlannersMutations.SET_DEFAULT_PLANNER,
      defaultMilestonePlanner,
    );
  },

  /**
   * Reset a milestone planner's data.
   *
   * @param {*} { commit, state }
   * @param {{ milestonePlannerUid: string; payload: any; queryParams: any }} {
   *       milestonePlannerUid,
   *       payload,
   *       queryParams = {},
   *     }
   */
  async [getBaseName(EAuthMilestonePlannersActions.PATCH)](
    { commit, state },
    {
      milestonePlannerUid,
      payload,
      queryParams = {},
    }: { milestonePlannerUid: string; payload: any; queryParams: any },
  ) {
    commit(EAuthMilestonePlannersMutations.SET_LOADING, true);
    commit(EAuthMilestonePlannersMutations.SET_ERROR, null);

    try {
      const previousMilestonePlanners = state.data.filter(
        (milestonePlanner: IMilestonePlanner) =>
          milestonePlanner.uid !== milestonePlannerUid,
      );

      const updatedMilestonePlanner = await milestonePlannersProvider.patch(
        milestonePlannerUid,
        payload,
        queryParams,
      );

      commit(EAuthMilestonePlannersMutations.SET_DATA, [
        ...previousMilestonePlanners,
        updatedMilestonePlanner,
      ]);

      commit(
        EAuthMilestonePlannersMutations.SET_DEFAULT_PLANNER,
        updatedMilestonePlanner,
      );
    } catch (error) {
      commit(EAuthMilestonePlannersMutations.SET_ERROR, error);
    } finally {
      commit(EAuthMilestonePlannersMutations.SET_LOADING, false);
    }
  },
};
