import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, vShow as _vShow, mergeProps as _mergeProps, withDirectives as _withDirectives, withModifiers as _withModifiers, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-c8d3a5a6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "comparison-table-visitors__header" }
const _hoisted_3 = { class: "comparison-table-visitors__header-top" }
const _hoisted_4 = { class: "comparison-table-visitors__header-title" }
const _hoisted_5 = { class: "comparison-table-visitors__header-bottom" }
const _hoisted_6 = { class: "comparison-table-visitors__wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PxAvatar = _resolveComponent("PxAvatar")!
  const _component_PxButton = _resolveComponent("PxButton")!
  const _component_PxIcon = _resolveComponent("PxIcon")!
  const _component_UserResponsesTable = _resolveComponent("UserResponsesTable")!
  const _component_CompareResponsesTable = _resolveComponent("CompareResponsesTable")!
  const _component_MatchDetailsTableLegend = _resolveComponent("MatchDetailsTableLegend")!
  const _directive_loading = _resolveDirective("loading")!

  return _withDirectives((_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([{ 'comparison-table-visitors--no-labels': !_ctx.allQuestionsAnswered }, "comparison-table-visitors"]),
    "element-loading-background": "#fafafa"
  }, [
    (!_ctx.loading && _ctx.capitalExplorerTilesResults && _ctx.dataPrefilled)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              (_ctx.companyData)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: "comparison-table-visitors__header-content",
                    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.goToOwnerProfile && _ctx.goToOwnerProfile(...args)))
                  }, [
                    _createVNode(_component_PxAvatar, {
                      name: _ctx.companyData.name,
                      photo: _ctx.companyData.logo,
                      size: 24,
                      class: "comparison-table-visitors__header-content-logo"
                    }, null, 8, ["name", "photo"]),
                    _createVNode(_component_PxButton, {
                      class: "el-button--link el-button--link-dark comparison-table-visitors__header-content-title",
                      type: "link"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.companyData.name), 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_PxIcon, {
                      class: "comparison-table-visitors__header-content-arrow-separator",
                      name: "arrow--right-black",
                      size: "12"
                    })
                  ]))
                : _createCommentVNode("", true),
              _createElementVNode("h1", _hoisted_4, _toDisplayString(_ctx.$t("capitalExplorer.comparisonTable.visitorTitle")), 1)
            ]),
            _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.subtitleCopy), 1)
          ]),
          _createElementVNode("div", _hoisted_6, [
            _withDirectives(_createVNode(_component_PxButton, _mergeProps({ class: "comparison-table-visitors__left-button" }, _ctx.$options.static.backButton, {
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.scroll('left')))
            }), null, 16), [
              [_vShow, _ctx.showLeftButton]
            ]),
            _withDirectives(_createVNode(_component_PxButton, _mergeProps({ class: "comparison-table-visitors__right-button" }, _ctx.$options.static.backButton, {
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.scroll('right')))
            }), null, 16), [
              [_vShow, _ctx.showRightButton]
            ]),
            _createElementVNode("div", {
              ref: "scrollContainer",
              class: "comparison-table-visitors__scroll",
              onScroll: _cache[3] || (_cache[3] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.handleScroll && _ctx.handleScroll(...args)), ["stop"]))
            }, [
              _createVNode(_component_UserResponsesTable, {
                "all-questions-answered": _ctx.allQuestionsAnswered,
                "table-data": _ctx.userResponsesTableData,
                class: "comparison-table-visitors__left-table"
              }, null, 8, ["all-questions-answered", "table-data"]),
              _createVNode(_component_CompareResponsesTable, {
                "all-questions-answered": _ctx.allQuestionsAnswered,
                "answers-data": _ctx.compareResponsesTableData,
                "match-details-modal-data": _ctx.capitalExplorerTilesOrdered,
                "table-headers": _ctx.tableHeaders,
                class: "comparison-table-visitors__right-table"
              }, null, 8, ["all-questions-answered", "answers-data", "match-details-modal-data", "table-headers"])
            ], 544)
          ]),
          _createVNode(_component_MatchDetailsTableLegend, {
            "legend-cards": _ctx.tableLegend,
            class: "comparison-table-visitors__legend"
          }, null, 8, ["legend-cards"])
        ]))
      : _createCommentVNode("", true)
  ], 2)), [
    [_directive_loading, _ctx.loading || !_ctx.capitalExplorerTilesResults || !_ctx.dataPrefilled]
  ])
}