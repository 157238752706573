<template>
  <ElDialog
    ref="modal"
    v-model="innerVisibility"
    :before-close="beforeCloseHandler"
    :title="$t('profile.accountSettingsModal.title')"
    class="account-settings-modal"
  >
    <DialogConfirmation ref="dialog-confirmation" />
    <PxModalMessage :visible="hasServerError" />
    <PxModalMessage
      v-model:visible="isPasswordSuccessVisible"
      :timeout="5000"
      :title="$t('profile.accountSettingsModal.changePassword.successMessage')"
      type="success"
    />
    <PxModalMessage
      v-model:visible="isEmailSuccessVisible"
      :timeout="5000"
      :title="$t('profile.accountSettingsModal.changeEmail.successMessage')"
      type="success"
    />
    <ElTabs
      v-model="selectedView"
      class="el-tabs--blue-line"
      @tab-click="tabClickHandler"
    >
      <ElTabPane
        :label="$t('profile.accountSettingsModal.tabs.email')"
        name="email"
      >
        <span />
      </ElTabPane>
      <ElTabPane
        :label="$t('profile.accountSettingsModal.tabs.password')"
        name="password"
      >
        <span />
      </ElTabPane>
    </ElTabs>
    <PxPanel :max-height="1090" no-borders scrollable>
      <ElForm
        v-show="selectedView === 'password'"
        :ref="forms.password"
        :model="fields"
        :rules="rules"
        class="profile-edit-form"
        @validate="updateSubmitState"
      >
        <ElFormItem
          :label="
            $t('profile.accountSettingsModal.changePassword.fields.oldPassword')
          "
          prop="old_password"
        >
          <ElInput
            v-model="fields.old_password"
            :placeholder="
              $t(
                'profile.accountSettingsModal.changePassword.fields.oldPassword',
              )
            "
            type="password"
          />
        </ElFormItem>
        <ElFormItem
          :label="
            $t('profile.accountSettingsModal.changePassword.fields.newPassword')
          "
          prop="new_password1"
        >
          <span
            class="el-form-item__top-text el-form-item__requirement"
            v-text="
              $t('profile.accountSettingsModal.changePassword.requirement')
            "
          />
          <PxInputPassword
            v-model="fields.new_password1"
            :placeholder="
              $t(
                'profile.accountSettingsModal.changePassword.fields.newPassword',
              )
            "
            :show-success-state="true"
            @input="validateConfirmationField"
          />
          <span
            class="el-form-item__bottom-text el-form-item__tip"
            v-html="$t('profile.accountSettingsModal.changePassword.tip')"
          />
        </ElFormItem>
        <ElFormItem
          :label="
            $t(
              'profile.accountSettingsModal.changePassword.fields.confirmPassword',
            )
          "
          prop="new_password2"
        >
          <ElInput
            v-model="fields.new_password2"
            :disabled="passwordIsInvalid"
            :placeholder="
              $t(
                'profile.accountSettingsModal.changePassword.fields.confirmPassword',
              )
            "
            class="el-input--password"
            type="password"
          />
        </ElFormItem>
      </ElForm>
      <ElForm
        v-show="selectedView === 'email'"
        :ref="forms.email"
        :model="emailFields"
        :rules="rules"
        class="profile-edit-form"
        @validate="updateSubmitState"
      >
        <ElFormItem
          :label="
            $t('profile.accountSettingsModal.changeEmail.fields.newEmail')
          "
          prop="email"
        >
          <ElInput
            v-model="emailFields.email"
            :placeholder="
              $t('profile.accountSettingsModal.changeEmail.fields.newEmail')
            "
            type="email"
          />
          <div
            v-if="submittedEmailChange"
            class="profile-edit-form__instructions"
          >
            <div
              class="profile-edit-form__notice"
              v-html="
                $t('profile.accountSettingsModal.changeEmail.notice', {
                  email: newEmail,
                })
              "
            />
            <div v-if="!resentEmailConfirmation" class="profile-edit-form__cta">
              {{ $t("profile.accountSettingsModal.changeEmail.help") }}
              <PxButton
                class="el-button--link-blue"
                type="link"
                @click="resendEmailClickHandler"
              >
                {{ $t("profile.accountSettingsModal.changeEmail.resendLink") }}
              </PxButton>
            </div>
          </div>
        </ElFormItem>
        <ElFormItem
          v-show="emailHasChanged"
          :label="
            $t('profile.accountSettingsModal.changeEmail.fields.password')
          "
          prop="password"
        >
          <ElInput
            v-model="emailFields.password"
            :placeholder="
              $t('profile.accountSettingsModal.changeEmail.fields.password')
            "
            type="password"
          />
        </ElFormItem>
      </ElForm>
    </PxPanel>
    <template #footer>
      <div class="dialog-footer">
        <PxButton
          class="el-button--small profile-edit-form__reset-btn"
          type="link"
          @click="beforeCloseHandler"
        >
          {{ $t("profile.accountSettingsModal.changePassword.resetButton") }}
        </PxButton>
        <PxButton
          :disabled="isSubmissionDisabled"
          :loading="isLoading"
          class="profile-edit-form__submit-btn"
          type="green"
          @click="onClickUpdate"
        >
          {{ submitButtons[selectedView] }}
        </PxButton>
      </div>
    </template>
  </ElDialog>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import get from "lodash/get";
import isEqual from "lodash/isEqual";

import { passwordChangeProvider } from "@/modules/authentication/services/data/password-change/password-change.provider";
import { emailChangeProvider } from "@/modules/authentication/services/data/email-change/email-change.provider";
import { resendEmailProvider } from "@/modules/authentication/services/data/resend-email/resend-email.provider";

import {
  allFormFieldsValid,
  generateEmailValidator,
  generatePasswordCheck,
  generatePasswordValidator,
  generateRequiredValidator,
} from "@/services/errors/validator-generators";
import { isDevelopment } from "@/services/utils/utils";
import { FormInstance } from "element-plus";
import { IFormField } from "@/modules/company-lists/components/list-management/list-management-modal.vue";

import DialogConfirmationMixin from "@/mixins/dialog-confirmation.mixin";
import { IDialogConfirmationOptions } from "@/components/dialog-confirmation/dialog-confirmation.types";

const EMAIL_ORIGINAL_DATA = {
  email: "",
  password: "",
};

const PASSWORD_ORIGINAL_DATA = {
  old_password: "",
  new_password1: "",
  new_password2: "",
};

export default defineComponent({
  name: "AccountSettingsModal",

  mixins: [DialogConfirmationMixin],

  props: {
    visibility: {
      type: Boolean,
      required: true,
    },
    openView: {
      type: String,
      default: "email",
    },
  },

  data() {
    return {
      isLoading: false,
      hasServerError: false,
      isPasswordSuccessVisible: false,
      isEmailSuccessVisible: false,
      submittedEmailChange: false,
      resentEmailConfirmation: false,

      selectedView: this.openView,

      innerVisibility: false,
      isSubmissionDisabled: true,

      passwordFormEl: null as null | FormInstance,

      forms: {
        email: "changeEmailForm",
        password: "changePasswordForm",
      },

      fields: {
        old_password: "",
        new_password1: "",
        new_password2: "",
      },

      newEmail: "",

      emailFields: {
        email: this.$store.get("auth/user.email"),
        password: "",
      },

      rules: {
        old_password: generateRequiredValidator(
          this,
          "profile.accountSettingsModal.changePassword.fields.oldPassword",
        ),

        new_password1: generatePasswordValidator(
          this,
          "profile.accountSettingsModal.changePassword.fields.newPassword",
          {
            minimum: 8,
            enforceCharRequirements: true,
          },
        ),

        new_password2: generatePasswordCheck(this, "fields.new_password1", {
          minimum: 8,
        }),

        email: generateEmailValidator(
          this,
          "profile.accountSettingsModal.changeEmail.fields.newEmail",
          true,
        ),

        password: generateRequiredValidator(
          this,
          "profile.accountSettingsModal.changeEmail.fields.password",
        ),
      },
    };
  },

  computed: {
    dialogConfirmationOptions(): IDialogConfirmationOptions {
      return {
        message: this.$t("common.dialogConfirmation.discardMessage"),
        confirmFn: () => this.onConfirmCancel(),
      };
    },

    /**
     * Object with the text that the update button can have.
     */
    submitButtons(): { [key: string]: any } {
      return {
        email: this.$t("profile.accountSettingsModal.changeEmail.submitButton"),
        password: this.$t(
          "profile.accountSettingsModal.changePassword.submitButton",
        ),
      };
    },

    /**
     * Object with the update methods for each tab.
     */
    updateMethods(): { [key: string]: Function } {
      return {
        email: this.updateEmail,
        password: this.updatePassword,
      };
    },

    currentForm(): any {
      const currentForm = get(this.forms, this.selectedView, "email") as string;
      return this.$refs[currentForm];
    },

    emailHasChanged(): boolean {
      return this.emailFields.email !== EMAIL_ORIGINAL_DATA.email;
    },

    passwordFormFields(): IFormField[] | null {
      return this.passwordFormEl
        ? ((this.passwordFormEl as any).fields as IFormField[])
        : null;
    },

    passwordIsInvalid(): boolean {
      if (this.passwordFormFields) {
        const formFields = this.passwordFormFields;

        const passwordField = formFields.find(
          (field) => field.prop === "new_password1",
        );

        return passwordField?.validateState !== "success";
      }
      return true;
    },

    isFormChanged(): boolean {
      return (
        !isEqual(this.fields, PASSWORD_ORIGINAL_DATA) ||
        !isEqual(this.emailFields, EMAIL_ORIGINAL_DATA)
      );
    },
  },

  watch: {
    openView(newVal) {
      this.selectedView = newVal;
    },

    selectedView(newVal) {
      if (newVal === "password") {
        (this as any).passwordFormEl = this.$refs.changePasswordForm;
      }
    },

    visibility(newVal, oldVal) {
      if (newVal === oldVal) {
        return;
      }

      this.innerVisibility = newVal;
    },

    innerVisibility(newVal) {
      this.$emit("update:visibility", newVal);
    },
  },

  created() {
    EMAIL_ORIGINAL_DATA.email = this.$store.get("auth/user.email");
  },

  methods: {
    updateSubmitState() {
      this.isSubmissionDisabled = !allFormFieldsValid(
        this.currentForm,
        this.rules,
      );
    },

    /**
     * Handler when the user click on the update button.
     */
    async onClickUpdate() {
      this.isLoading = true;
      this.hasServerError = false;

      const updateMethod = this.updateMethods[this.selectedView];

      try {
        await updateMethod();
      } catch (error) {
        this.hasServerError = true;
        if (isDevelopment) {
          throw error;
        }
        return;
      } finally {
        this.isLoading = false;
      }

      this.updateSubmitState();
    },

    /**
     * Request an email update.
     */
    async updateEmail() {
      await emailChangeProvider.create(this.emailFields);

      this.submittedEmailChange = true;
      this.isEmailSuccessVisible = true;
      this.newEmail = this.emailFields.email;
      const form = this.$refs.changeEmailForm as FormInstance;
      form.resetFields();
    },

    /**
     * Request an password update.
     */
    async updatePassword() {
      await passwordChangeProvider.create(this.fields);

      this.isPasswordSuccessVisible = true;
      this.passwordFormEl?.resetFields();
    },

    beforeCloseHandler() {
      if (this.isFormChanged) {
        this.showConfirmOverlay(this.dialogConfirmationOptions);
      } else {
        this.innerVisibility = false;
      }
    },

    onConfirmCancel() {
      this.innerVisibility = false;

      const formElement = this.currentForm as FormInstance;
      formElement.resetFields();
    },

    tabClickHandler() {
      this.updateSubmitState();
    },

    async resendEmailClickHandler() {
      await resendEmailProvider.create({
        email: this.newEmail,
      });

      this.submittedEmailChange = true;
      this.resentEmailConfirmation = true;
      this.isEmailSuccessVisible = true;
    },

    validateConfirmationField() {
      if (!this.passwordIsInvalid) {
        this.passwordFormEl?.validateField(
          "new_password2",
          this.updateSubmitState,
        );
      }
    },
  },
});
</script>

<style lang="scss">
.account-settings-modal.el-dialog {
  width: 560px;

  .el-dialog__wrapper {
    @include breakpoint-down(sm) {
      top: 56px;
    }
  }

  .el-dialog__body {
    padding: 0 0 30px;
  }

  .el-dialog__header {
    padding: 15px 56px 14px 30px;
  }

  .el-dialog__title {
    letter-spacing: -0.3px;
  }

  .px-modal-message {
    pointer-events: none;
  }

  .profile-edit-form {
    max-width: 310px;
    margin: auto;
  }

  .profile-edit-form__reset-btn + .profile-edit-form__submit-btn {
    padding: 0 16px;
    margin-left: 9px;

    span {
      font-size: 13px;
    }
  }

  .profile-edit-form__reset-btn {
    position: relative;
    bottom: 1px;
  }

  .profile-edit-form__instructions {
    margin: 17px auto 0 -1px;
  }

  .profile-edit-form__notice {
    @include grotesk(medium);

    font-size: 13px;
    line-height: 16px;
    color: $ebony-clay;
    letter-spacing: -0.2px;

    strong {
      font-size: 13px;
      letter-spacing: -0.1px;
    }
  }

  .profile-edit-form__cta {
    @include grotesk(medium);

    font-size: 13px;
    line-height: 25px;

    &:not(:first-child) {
      display: flex;
      align-items: center;
      gap: 4px;

      margin-top: 7px;
      letter-spacing: -0.2px;
    }

    .el-button--link {
      position: relative;
      top: 0;
      height: auto;
      padding: 0;
    }

    .el-button--link span {
      font-size: 13px;
      line-height: 11px;
      letter-spacing: -0.2px;
    }
  }

  .el-tabs__item {
    padding-top: 20px;
    padding-bottom: 12px;
  }

  .el-tabs--blue-line .el-tabs__item:first-of-type {
    margin-right: 19px;
  }

  .el-tabs--blue-line .el-tabs__item:last-child {
    position: relative;
    right: 2px;
    letter-spacing: -0.3px;
  }

  .px-panel {
    margin-right: auto;
    margin-left: auto;

    @include breakpoint-down(sm) {
      margin: -22px 0 -30px;
    }

    .px-panel__body {
      padding: 40px 10px 0;

      @include breakpoint-up(sm) {
        padding: 43px 0 0;
      }
    }
  }
}
</style>
