<template>
  <div class="last-assessment-date">
    {{
      $t("milestonePlanner.milestoneGrid.footer.lastAssessment", {
        assessmentDate: date,
      })
    }}
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "LastAssessmentDate",

  props: {
    date: {
      type: String,
      required: true,
    },
  },
});
</script>

<style lang="scss" scoped>
.last-assessment-date {
  font-size: 14px;
  font-style: italic;
  color: $manatee;
}
</style>
