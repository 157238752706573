<template>
  <ElDialog
    ref="modal"
    v-model="innerVisibility"
    :title="$t(`authentication.signup.modal.title`)"
    class="signup-modal"
  >
    <PxModalMessage :visible="hasServerErrors" />
    <SignUpModalSuccess
      v-if="submittedSuccessfully"
      :registration-email="userEmail"
    />
    <ElForm
      v-else
      ref="signUpForm"
      :model="fields"
      :rules="rules"
      class="signup-modal__form"
      @validate="updateSubmitState"
    >
      <ElFormItem
        :error="errors.getMessage('email') || ''"
        :label="$t('authentication.login.form.fields.email')"
        prop="email"
      >
        <ElInput
          v-model="fields.email"
          :placeholder="$t('authentication.signup.form.placeholders.email')"
          type="email"
        />
      </ElFormItem>
      <ElFormItem
        :label="$t('authentication.signup.form.fields.password')"
        prop="new_password1"
      >
        <span
          class="el-form-item__top-text el-form-item__requirement"
          v-text="$t('authentication.signup.form.requirement')"
        />
        <PxInputPassword
          v-model="fields.new_password1"
          :placeholder="$t('authentication.signup.form.fields.password')"
          :show-success-state="true"
          @input="validateConfirmationField"
        />
        <span
          class="el-form-item__bottom-text el-form-item__tip"
          v-html="$t('authentication.signup.form.tip')"
        />
      </ElFormItem>
      <ElFormItem
        :label="$t('authentication.signup.form.fields.confirmPassword')"
        prop="new_password2"
      >
        <ElInput
          v-model="fields.new_password2"
          :disabled="passwordIsInvalid"
          :placeholder="$t('authentication.signup.form.fields.confirmPassword')"
          class="el-input--password"
          type="password"
        />
      </ElFormItem>
      <ElFormItem :show-message="false" prop="terms" required>
        <ElCheckbox v-model="fields.terms" class="signup-modal__form--selector">
          <i18n-t
            class="signup-modal__form--selector-text"
            keypath="authentication.signup.form.fields.terms"
            tag="p"
          >
            <template #terms>
              <a
                :href="$options.static.termsOfUseUrl"
                class="text--nice-blue"
                target="_blank"
              >
                {{ $t("authentication.signup.form.fields.termsOfUse") }}
              </a>
            </template>
            <template #privacy>
              <a
                :href="$options.static.privacyPolicyUrl"
                class="text--nice-blue"
                target="_blank"
              >
                {{ $t("authentication.signup.form.fields.privacyPolicy") }}
              </a>
            </template>
          </i18n-t>
        </ElCheckbox>
      </ElFormItem>
      <div class="el-form__cta">
        <PxButton
          :disabled="isSubmissionDisabled"
          :loading="isLoadingSubmission"
          size="medium"
          type="primary"
          @click="submitForm"
        >
          {{ $t("authentication.signup.form.submitButton") }}
        </PxButton>
      </div>
    </ElForm>
  </ElDialog>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import { ErrorsManager } from "@/services/errors-manager";
import {
  allFormFieldsValid,
  generateEmailValidator,
  generatePasswordCheck,
  generatePasswordValidator,
  generateCheckboxValidator,
} from "@/services/errors/validator-generators";
import { ErrorsProviderException } from "@/services/data/exceptions/errors-provider.exception";
import { pendingUserPasswordProvider } from "@/modules/authentication/services/data/pending-user-password/pending-user-password.provider";

import {
  AUTHENTICATION_TERMS_OF_USE_URL,
  AUTHENTICATION_PRIVACY_POLICY_URL,
} from "@/modules/authentication/constants";

import SignUpModalSuccess from "@/modules/authentication/components/signup-modal/signup-modal-success.vue";
import { IPendingUser } from "@/services/store/viral-level/types/pending-user.interface";
import { FormInstance } from "element-plus";
import { IFormField } from "@/modules/company-lists/components/list-management/list-management-modal.vue";
import { EAffiliatesActions } from "@/modules/affiliates/services/store/affiliates/affiliates.types";

export default defineComponent({
  name: "SignupModal",

  components: {
    SignUpModalSuccess,
  },

  static: {
    termsOfUseUrl: AUTHENTICATION_TERMS_OF_USE_URL,
    privacyPolicyUrl: AUTHENTICATION_PRIVACY_POLICY_URL,
  },

  data() {
    return {
      innerVisibility: false,
      hasServerErrors: false,
      isLoadingSubmission: false,
      isSubmissionDisabled: true,
      submittedSuccessfully: false,
      formEl: null as null | FormInstance,

      fields: {
        email: "",
        new_password1: "",
        new_password2: "",
        terms: false,
      },

      rules: {
        email: generateEmailValidator(
          this,
          "authentication.signup.form.fields.email",
          true,
        ),
        new_password1: generatePasswordValidator(
          this,
          "authentication.signup.form.fields.password",
          {
            minimum: 8,
            enforceCharRequirements: true,
          },
        ),
        new_password2: generatePasswordCheck(this, "fields.new_password1", {
          minimum: 8,
        }),
        terms: generateCheckboxValidator(),
      },

      errors: new ErrorsManager({
        email: {
          unique: this.$t("authentication.signup.form.errors.email.unique"),
        },
      }),
      userEmail: "",
    };
  },

  computed: {
    pendingUser(): IPendingUser | null {
      return this.$store.state.affiliates.pendingUser;
    },

    pendingUserEmail(): string {
      return this.pendingUser ? this.pendingUser.email : "";
    },

    formFields(): IFormField[] | null {
      return this.formEl ? ((this.formEl as any).fields as IFormField[]) : null;
    },

    passwordIsInvalid(): boolean {
      if (this.formFields) {
        const formFields = this.formFields;

        const passwordField = formFields.find(
          (field) => field.prop === "new_password1",
        );

        return passwordField?.validateState !== "success";
      }
      return true;
    },
  },

  watch: {
    innerVisibility(visible: boolean) {
      if (visible && this.fields.email) {
        this.$nextTick(() => {
          (this as any).formEl = this.$refs.signUpForm;
          this.formEl?.validateField("email", this.updateSubmitState);
        });

        return;
      }

      // Reset submission state
      this.submittedSuccessfully = false;
      this.errors.clear();
      this.userEmail = "";
    },

    pendingUserEmail(currentEmail: string) {
      this.fields.email = currentEmail;
    },
  },

  created() {
    this.$emitter.on("open-signup-modal", () => {
      if (this.$screen.mdDown) window.scrollTo(0, 0);
      this.innerVisibility = true;
    });

    // Set pending user email
    this.fields.email = this.pendingUserEmail;
  },

  methods: {
    updateSubmitState() {
      if (!this.innerVisibility || this.submittedSuccessfully) return;

      this.isSubmissionDisabled = !allFormFieldsValid(this.formEl, this.rules);
    },

    async submitForm() {
      this.isLoadingSubmission = true;
      this.hasServerErrors = false;

      try {
        const submitData = { ...this.fields, new_email: "" };

        if (this.fields.email !== this.pendingUserEmail) {
          submitData.email = this.pendingUserEmail;
          submitData.new_email = this.fields.email;
        }

        await pendingUserPasswordProvider.create(submitData);
      } catch (error) {
        if (error instanceof ErrorsProviderException) {
          this.errors.record(error.response.data.errors);
        } else {
          this.hasServerErrors = true;
        }
        return;
      } finally {
        this.isLoadingSubmission = false;
      }

      this.userEmail = this.fields.email;
      this.submittedSuccessfully = true;
      this.formEl?.resetFields();
      // Reset Pending User
      this.$store.dispatch(EAffiliatesActions.SET_PENDING_USER, null);
    },

    validateConfirmationField() {
      if (!this.passwordIsInvalid) {
        this.formEl?.validateField("new_password2", this.updateSubmitState);
      }
    },
  },
});
</script>

<style lang="scss">
.signup-modal {
  &.el-dialog {
    width: 467px;
  }

  .el-dialog__body {
    @include breakpoint-up(md) {
      padding: 13px 30px 32px;
    }
  }
  .el-form__cta {
    @include breakpoint-down(md) {
      margin-top: 11px;
    }
  }

  &__form--selector {
    padding-top: 21px;
    word-wrap: break-word;
    white-space: normal;

    .el-checkbox__input {
      bottom: 19px;
    }

    .el-checkbox__label {
      max-width: 380px;
      padding-left: 12px;

      @include breakpoint-down(md) {
        max-width: 300px;
      }
    }

    .text--nice-blue {
      text-decoration: none;
    }
  }

  &__form--selector-text {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: $ebony-clay;
  }
}
</style>
