import { normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-ddbbf172"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "milestone-tooltip-progress-indicator" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.isMilestoneInProgress)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          style: _normalizeStyle(_ctx.backgroundStyles),
          class: "milestone-tooltip-progress-indicator__background"
        }, null, 4))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      style: _normalizeStyle(_ctx.foregroundStyles),
      class: "milestone-tooltip-progress-indicator__foreground"
    }, null, 4)
  ]))
}