import { renderSlot as _renderSlot } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return _renderSlot(_ctx.$slots, "default", {
    currentCategory: _ctx.currentCategory,
    selectedStep: _ctx.selectedStepData,
    onLevelChange: _ctx.handleLevelSliderChange,
    selectedCategory: _ctx.selectedCategory,
    levels: _ctx.levels,
    goNextCategoryHandler: _ctx.goNextCategoryHandler,
    goPrevCategoryHandler: _ctx.goPrevCategoryHandler,
    isFirstInteraction: _ctx.isFirstInteraction,
    hasUserEntry: _ctx.hasUserEntry
  })
}