<template>
  <div class="px-textarea">
    <ElInput
      ref="input"
      v-model="innerValue"
      :autosize="autosize"
      :disabled="disabled"
      :max-chars="maxChars"
      :name="name"
      :placeholder="placeholder"
      :readonly="readonly"
      :resize="resize"
      :rows="rows"
      type="textarea"
      @blur="$emit('blur')"
      @focus="$emit('focus')"
      @keyup="$emit('keyup', $event)"
    />
    <PxCounter
      v-if="showCounter"
      :remaining-chars="remainingChars"
      class="px-textarea-counter"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "PxTextarea",

  props: {
    /**
     * Textarea content.
     */
    modelValue: {
      type: String,
      default: "",
    },

    /**
     * Text to be presented when there is no text on the textarea.
     */
    placeholder: {
      type: String,
      default: "",
    },

    /**
     * Control the resizability: none, vertical, horizontal, and both.
     */
    resize: {
      type: String,
      default: "vertical",
      validator(val: string) {
        return ["none", "vertical", "horizontal", "both"].includes(val);
      },
    },

    /**
     * Same as `readonly` in native.
     */
    readonly: {
      type: Boolean,
      default: false,
    },

    /**
     * Whether textarea is disabled.
     */
    disabled: {
      type: Boolean,
      default: false,
    },

    /**
     * Same as `name` in native.
     */
    name: {
      type: String as () => string | null,
      default: null,
    },

    /**
     * Number of rows that the textarea must have.
     */
    rows: {
      type: Number,
      default: 4,
    },

    /**
     * Whether textarea must have a counter of the
     * remaining characters.
     */
    showCounter: {
      type: Boolean,
      default: false,
    },

    /**
     * Define a max number of the characters.
     */
    maxChars: {
      type: Number,
      default: null,
    },

    /**
     * Whether textarea has an adaptative heigh. Can be an object
     * `{minRows: 2, maxRows: 6}`
     */
    autosize: {
      type: [Boolean, Object],
      default: false,
    },
  },

  data() {
    return {
      innerValue: "",
    };
  },

  computed: {
    /**
     * Get the remaining chars.
     */
    remainingChars(): number {
      return this.modelValue
        ? this.maxChars - this.modelValue.length
        : this.maxChars;
    },
  },

  watch: {
    innerValue: {
      handler(newInnerValue: string) {
        if (newInnerValue !== this.modelValue) {
          this.$emit("update:modelValue", newInnerValue);
          this.$emit("change", newInnerValue);
        }
      },
    },

    modelValue: {
      immediate: true,
      handler(newValue: string) {
        if (newValue !== this.innerValue) {
          this.innerValue = newValue;
        }
      },
    },
  },

  methods: {
    focus() {
      (this.$refs.input as any).focus();
    },
  },
});
</script>

<style lang="scss" scoped>
.px-textarea :deep() textarea {
  outline: none;
}

.px-textarea-counter {
  display: block;
  width: 100%;
  margin-top: 5px;

  text-align: right;
  letter-spacing: -0.1px;
}
</style>
