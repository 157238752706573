<template>
  <ElButton
    :class="{
      'is-compact': isCompact,
      'is-fluid': isFluid,
      'icon-right': iconPosition === 'right',
      [`el-button--${size}`]: true,
      [`el-button--${type}`]: true,
      'px-button': true,
    }"
    :disabled="disabled"
    :loading="loading"
  >
    <template v-if="!!icon" #icon>
      <PxIcon :name="icon" :use-inline-style="false" />
    </template>
    <template v-if="!isCompact" #default>
      <div v-if="label">
        {{ label }}
      </div>
      <slot></slot>
    </template>
  </ElButton>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { ElButton } from "element-plus";

import {
  EPxButtonSize,
  EPxButtonType,
  EPxButtonVariant,
} from "./px-button.types";

/**
 *
 * Apart from using the base structure from Element UI Button, it allows
 * to have custom icons and takes advantage of defined styles using enums.
 */
export default defineComponent({
  name: "PxButton",

  components: {
    ElButton,
  },

  props: {
    size: {
      type: String,
      default: EPxButtonSize.MEDIUM,
      validator(value: EPxButtonSize) {
        return Object.values(EPxButtonSize).includes(value);
      },
    },

    variant: {
      type: String,
      default: EPxButtonVariant.NORMAL,
      validator(value: EPxButtonVariant) {
        return Object.values(EPxButtonVariant).includes(value);
      },
    },

    type: {
      type: String,
      default: EPxButtonType.DEFAULT,
      validator(value: EPxButtonType) {
        return Object.values(EPxButtonType).includes(value);
      },
    },

    icon: {
      type: String as () => string | null,
      default: null,
    },

    label: {
      type: String,
      default: "",
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    loading: {
      type: Boolean,
      default: false,
    },

    iconPosition: {
      type: String,
      default: "left",
      validator: (value: string) => {
        return ["left", "right"].includes(value);
      },
    },
  },

  computed: {
    isCompact(): boolean {
      return this.variant === EPxButtonVariant.COMPACT;
    },

    isFluid(): boolean {
      return this.variant === EPxButtonVariant.FLUID;
    },
  },
});
</script>

<style lang="scss" scoped>
.px-button :deep(.el-icon),
.px-button :deep(.px-icon) {
  width: 16px;
  height: 16px;
}

.px-button.icon-right {
  flex-direction: row-reverse;
}
</style>
