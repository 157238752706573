import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createTextVNode as _createTextVNode, withModifiers as _withModifiers, mergeProps as _mergeProps, createVNode as _createVNode, normalizeClass as _normalizeClass, vShow as _vShow, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-35c77431"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "submission-panel__wrapper"
}
const _hoisted_2 = { class: "submission-panel__matching-link-wrapper" }
const _hoisted_3 = { class: "submission-affiliate__container" }
const _hoisted_4 = { class: "submission-affiliate__head-container" }
const _hoisted_5 = ["src"]
const _hoisted_6 = { class: "submission-affiliate__title" }
const _hoisted_7 = { class: "submission-affiliate__date-wrapper" }
const _hoisted_8 = { class: "submission-tab__nav-menu" }
const _hoisted_9 = ["onClick"]
const _hoisted_10 = { class: "submission-tab__container" }
const _hoisted_11 = { class: "submission-tab__print-title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ElOption = _resolveComponent("ElOption")!
  const _component_ElSelect = _resolveComponent("ElSelect")!
  const _component_i18n_t = _resolveComponent("i18n-t")!
  const _component_PxButton = _resolveComponent("PxButton")!
  const _component_SubmissionCompanyPanel = _resolveComponent("SubmissionCompanyPanel")!
  const _component_SubmissionResponsesPanel = _resolveComponent("SubmissionResponsesPanel")!
  const _component_InvestmentLevelPanel = _resolveComponent("InvestmentLevelPanel")!
  const _component_SubmissionTabTeamMembers = _resolveComponent("SubmissionTabTeamMembers")!
  const _component_PxPanel = _resolveComponent("PxPanel")!

  return (!_ctx.isLoading)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          (_ctx.showMatchingButton)
            ? (_openBlock(), _createElementBlock("a", {
                key: 0,
                class: "submission-view__matching-link",
                onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.goToMatching && _ctx.goToMatching(...args)))
              }, _toDisplayString(_ctx.$t("program.submission.backToMatching")), 1))
            : _createCommentVNode("", true)
        ]),
        _createVNode(_component_PxPanel, { class: "submission-panel__container px-panel--xs-no-side-borders" }, {
          default: _withCtx(() => [
            _createElementVNode("div", {
              class: _normalizeClass([{
          'submission-main__container--mobile': _ctx.$screen.smDown,
          'submission-main__container--desktop': _ctx.$screen.smUp,
        }, "submission-main__container"])
            }, [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, [
                  _createElementVNode("img", {
                    height: 30,
                    src: _ctx.affiliate.logo,
                    alt: "Affiliate Logo",
                    class: "submission-affiliate__logo",
                    onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.externalLinkClickHandler && _ctx.externalLinkClickHandler(...args)))
                  }, null, 8, _hoisted_5),
                  _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.affiliate.name), 1)
                ]),
                _createElementVNode("div", _hoisted_7, [
                  (_ctx.canShowSubmissionsList)
                    ? (_openBlock(), _createBlock(_component_ElSelect, {
                        key: 0,
                        modelValue: _ctx.submissionUid,
                        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.submissionUid) = $event)),
                        "data-printable-value": _ctx.selectedPrintableValue,
                        class: "submission-affiliate__date-dropdown"
                      }, {
                        default: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.mappedSubmissionList, (mapSubmission) => {
                            return (_openBlock(), _createBlock(_component_ElOption, {
                              key: mapSubmission.uid,
                              label: mapSubmission.date,
                              value: mapSubmission.uid
                            }, null, 8, ["label", "value"]))
                          }), 128))
                        ]),
                        _: 1
                      }, 8, ["modelValue", "data-printable-value"]))
                    : (_openBlock(), _createBlock(_component_i18n_t, {
                        key: 1,
                        class: "submission-affiliate__date-text",
                        keypath: "program.submission.submittedAt",
                        tag: "span"
                      }, {
                        date: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.submissionFormattedDate), 1)
                        ]),
                        _: 1
                      }))
                ]),
                _createVNode(_component_PxButton, _mergeProps({ class: "submission-affiliate__cta" }, _ctx.$options.static.printButton, {
                  onClick: _withModifiers(_ctx.onPrintButtonClick, ["stop"])
                }), null, 16, ["onClick"])
              ]),
              _createVNode(_component_SubmissionCompanyPanel, {
                company: _ctx.profileCompany,
                "user-can-match": _ctx.canMatch,
                "user-is-connected": _ctx.isConnected,
                "user-is-interested": _ctx.isInterested,
                onShowListModal: _ctx.showListModal
              }, null, 8, ["company", "user-can-match", "user-is-connected", "user-is-interested", "onShowListModal"]),
              _createElementVNode("div", _hoisted_8, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.visiblePanelTabs, (tab, index) => {
                  return (_openBlock(), _createElementBlock("span", {
                    key: index,
                    class: _normalizeClass([{
              'submission-tab__nav-item--active': _ctx.isActiveTab(tab.key),
            }, "submission-tab__nav-item"]),
                    onClick: ($event: any) => (_ctx.changeTab(tab.key))
                  }, _toDisplayString(tab.label), 11, _hoisted_9))
                }), 128))
              ])
            ], 2),
            _createElementVNode("div", _hoisted_10, [
              _createElementVNode("h2", _hoisted_11, _toDisplayString(_ctx.$t("program.submission.tabs.questions")), 1),
              (_ctx.responsesWithoutProfileFields.length)
                ? _withDirectives((_openBlock(), _createBlock(_component_SubmissionResponsesPanel, {
                    key: 0,
                    responses: _ctx.responsesWithoutProfileFields,
                    class: "submission-tab__item"
                  }, null, 8, ["responses"])), [
                    [_vShow, _ctx.isActiveTab(_ctx.ESubmissionPanelTab.QUESTIONS)]
                  ])
                : _createCommentVNode("", true),
              (_ctx.profileCompany)
                ? _withDirectives((_openBlock(), _createBlock(_component_InvestmentLevelPanel, {
                    key: 1,
                    assessment: _ctx.assessment,
                    company: _ctx.profileCompany,
                    class: "submission-tab__item"
                  }, null, 8, ["assessment", "company"])), [
                    [_vShow, _ctx.isActiveTab(_ctx.ESubmissionPanelTab.VENTURE_INVESTMENT_LEVEL)]
                  ])
                : _createCommentVNode("", true),
              (_ctx.submission.team_members && _ctx.submission.team_members.length)
                ? _withDirectives((_openBlock(), _createBlock(_component_SubmissionTabTeamMembers, {
                    key: 2,
                    "team-members": _ctx.submission.team_members
                  }, null, 8, ["team-members"])), [
                    [_vShow, _ctx.isActiveTab(_ctx.ESubmissionPanelTab.TEAM_MEMBERS)]
                  ])
                : _createCommentVNode("", true)
            ])
          ]),
          _: 1
        })
      ]))
    : _createCommentVNode("", true)
}