<template>
  <div class="questions-panel-header">
    <div class="questions-panel-header__title">
      <h3>
        {{ $t("capitalExplorer.questionsPanel.title") }}
      </h3>
    </div>
    <div class="questions-panel-header__counter">
      <div class="questions-panel-header__progress">
        <ElProgress
          :percentage="answeredPercentage"
          :show-text="false"
          :stroke-width="6"
          :width="66"
          color="#4caf50"
          data-testid="questions-panel-header-progress-circle"
          type="circle"
        />
        <p>
          {{ `${questionsAnswered}/${questionsTotal}` }}
        </p>
      </div>
      <div class="questions-panel-header__answers">
        <p
          :class="{
            'questions-panel-header__answers-title--all-answered':
              answeredPercentage === 100,
          }"
          class="questions-panel-header__answers-title"
          v-html="questionsTitle"
        />
        <p
          class="questions-panel-header__answers-description"
          v-html="questionsDescription"
        />
      </div>
    </div>
    <transition mode="out-in" name="fade">
      <div v-if="questionsAnswered" class="questions-panel-header__actions">
        <PxButton
          class="questions-panel-header__clear"
          v-bind="clearButtonProps"
          @click="$emit('clear-answers')"
        />
      </div>
    </transition>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import { ElProgress } from "element-plus";
import { EPxButtonType } from "@/components/px-button/px-button.types";

export default defineComponent({
  name: "QuestionsPanelHeader",

  components: {
    ElProgress,
  },

  props: {
    questionsAnswered: {
      type: Number,
      required: true,
      validator(value: number) {
        return value >= 0;
      },
    },

    questionsTotal: {
      type: Number,
      required: true,
      validator(value: number) {
        return value >= 0;
      },
    },
  },

  computed: {
    answeredPercentage(): number {
      return (
        Math.round((this.questionsAnswered / this.questionsTotal) * 100) || 0
      );
    },

    questionsTitle() {
      return this.answeredPercentage === 0
        ? this.$t("capitalExplorer.questionsPanel.questionsTitle.0")
        : this.answeredPercentage === 100
          ? this.$t("capitalExplorer.questionsPanel.questionsTitle.2")
          : this.$t("capitalExplorer.questionsPanel.questionsTitle.1", {
              answered: this.questionsAnswered,
              total: this.questionsTotal,
            });
    },

    questionsDescription() {
      return this.answeredPercentage === 100
        ? this.$t("capitalExplorer.questionsPanel.questionsDescription.1")
        : this.$t("capitalExplorer.questionsPanel.questionsDescription.0");
    },

    clearButtonProps(): object {
      return {
        type: EPxButtonType.LINK,
        label: this.$t("common.clearAnswers"),
      };
    },
  },
});
</script>

<style lang="scss" scoped>
.questions-panel-header {
  display: flex;
  flex-direction: column;
  margin-bottom: 1px;

  &__title {
    position: relative;
    padding: 31px 0 9px 24px;

    h3 {
      font-size: 20px;
      font-weight: 600;
      line-height: 1.5;
    }
  }

  &__counter {
    display: flex;
    gap: 13px;
    padding: 23px 20px 23px 21px;

    border-top: 1px solid #dcdde2;
  }

  &__answers {
    display: flex;
    flex-direction: column;
    gap: 2px;

    &-title {
      font-size: 14px;
      font-weight: 600;
      line-height: 1.43;
      color: $ebony-clay;
    }

    &-title--all-answered {
      color: $fern;
    }

    &-title :deep() span {
      font-size: 14px;
      font-weight: 600;
      line-height: 1.43;
      color: $fern;
    }

    &-description {
      font-size: 14px;
      line-height: 1.43;
      color: $manatee;
      letter-spacing: -0.3px;
      padding-right: 8px;
    }
  }

  &__progress {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    :deep() .el-progress-circle__track {
      stroke: #eaf5ee;
    }

    p {
      position: absolute;
      margin: 0;
      font-size: 15px;
      font-weight: 600;
      line-height: 1.47;
      color: $fern;
    }
  }

  &__actions {
    padding: 0 24px;
    margin-bottom: 21px;
  }

  &__clear {
    bottom: 1px;

    width: 100%;
    height: auto;
    padding: 0;
    justify-content: flex-start;

    :deep(span) {
      font-size: 14px;
    }
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: all 0.4s ease-in-out;
  }

  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }
}
</style>
