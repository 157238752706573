import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-761abe18"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "investing-level-panel-placeholder__level" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ContentPlaceholdersHeading = _resolveComponent("ContentPlaceholdersHeading")!
  const _component_ContentPlaceholdersImg = _resolveComponent("ContentPlaceholdersImg")!
  const _component_ContentPlaceholders = _resolveComponent("ContentPlaceholders")!
  const _component_ContentPlaceholdersText = _resolveComponent("ContentPlaceholdersText")!
  const _component_PxPanel = _resolveComponent("PxPanel")!

  return (_openBlock(), _createBlock(_component_PxPanel, { class: "panel-grid__block investing-level-panel-placeholder" }, {
    default: _withCtx(() => [
      _createVNode(_component_ContentPlaceholders, { rounded: true }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_ContentPlaceholdersHeading, { class: "investing-level-panel-placeholder__title" }),
            _createVNode(_component_ContentPlaceholdersImg, { class: "investing-level-panel-placeholder__thumbnail" }),
            _createVNode(_component_ContentPlaceholders, {
              centered: true,
              class: "investing-level-panel-placeholder__content"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ContentPlaceholdersHeading)
              ]),
              _: 1
            }),
            _createVNode(_component_ContentPlaceholdersText, {
              lines: 1,
              class: "investing-level-panel-placeholder__cta"
            })
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}