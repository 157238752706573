import { ISignUpWithAssessment } from "./signup-with-assessment.interface";
import {
  GenericProvider,
  Provider,
  IOptionsType,
} from "@/services/data/generic-provider";
import { baseAPIUrl } from "@/services/utils/utils";

/**
 * Provider that allows to create a new user account
 * with a self-assessment.
 */
@Provider(`${baseAPIUrl}/user/register-with-assessment`)
class SignUpWithAssessmentProvider extends GenericProvider<
  any,
  ISignUpWithAssessment
> {
  public create(data: ISignUpWithAssessment, options?: IOptionsType) {
    // When there is no level selected the API expects to receive a
    // level null and not zero, so we need a conversion here.
    data.levels = data.levels.map((entry: any) => {
      entry.level = entry.level !== 0 ? entry.level : null;
      return entry;
    });

    return super.create(data, options);
  }
}

export const signUpWithAssessmentProvider = new SignUpWithAssessmentProvider();
