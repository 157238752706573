import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-562c5e1b"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "milestones-section" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PxSidebarDivisor = _resolveComponent("PxSidebarDivisor")!
  const _component_ContentPlaceholdersText = _resolveComponent("ContentPlaceholdersText")!
  const _component_ContentPlaceholders = _resolveComponent("ContentPlaceholders")!
  const _component_MilestoneDetails = _resolveComponent("MilestoneDetails")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createVNode(_component_PxSidebarDivisor, {
      icon: _ctx.icon,
      title: _ctx.title
    }, null, 8, ["icon", "title"]),
    (_ctx.isLoading)
      ? (_openBlock(), _createBlock(_component_ContentPlaceholders, {
          key: 0,
          animated: true
        }, {
          default: _withCtx(() => [
            _createVNode(_component_ContentPlaceholdersText, { lines: 3 })
          ]),
          _: 1
        }))
      : (_openBlock(), _createBlock(_component_MilestoneDetails, {
          key: 1,
          milestones: _ctx.milestones
        }, null, 8, ["milestones"]))
  ]))
}