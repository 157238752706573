import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-c94135fa"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "submission-panel__supporter-info" }
const _hoisted_2 = { class: "submission-panel__title" }
const _hoisted_3 = { class: "submission-panel__subtitle" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", null, [
      _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.title), 1),
      _createElementVNode("span", {
        class: "submission-panel__profile-link",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.goToProfile && _ctx.goToProfile(...args)))
      }, _toDisplayString(_ctx.$t("program.submission.seeProfile")), 1)
    ]),
    _createElementVNode("h3", _hoisted_3, _toDisplayString(_ctx.subtitle), 1)
  ]))
}