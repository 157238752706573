import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withModifiers as _withModifiers, withCtx as _withCtx, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-21c5223e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "process-reports-locked" }
const _hoisted_2 = {
  key: 1,
  class: "process-reports-locked__privacy-message"
}
const _hoisted_3 = {
  key: 3,
  class: "process-reports-locked--overlay"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ChartLocked = _resolveComponent("ChartLocked")!
  const _component_PxIcon = _resolveComponent("PxIcon")!
  const _component_PxButton = _resolveComponent("PxButton")!
  const _component_i18n_t = _resolveComponent("i18n-t")!
  const _component_ChartsLockedModal = _resolveComponent("ChartsLockedModal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.allStepsLocked)
      ? (_openBlock(), _createBlock(_component_ChartLocked, {
          key: 0,
          content: _ctx.copy,
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.privacyModalVisible = true))
        }, null, 8, ["content"]))
      : (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_PxIcon, {
            name: "information--dark-blue",
            size: 16
          }),
          _createElementVNode("div", null, [
            _createVNode(_component_i18n_t, {
              keypath: `chartsDashboard.lockedState.description[2]`,
              tag: "p"
            }, {
              privacy: _withCtx(() => [
                _createVNode(_component_PxButton, {
                  class: "el-button--link-blue el-button--small",
                  type: "link",
                  onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.privacyModalVisible = true), ["stop"]))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t("chartsDashboard.lockedState.privacy")), 1)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createElementVNode("p", null, _toDisplayString(_ctx.$t("chartsDashboard.lockedState.description[3]")), 1)
          ])
        ])),
    (_ctx.privacyModalVisible)
      ? (_openBlock(), _createBlock(_component_ChartsLockedModal, {
          key: 2,
          visibility: _ctx.privacyModalVisible,
          "onUpdate:visibility": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.privacyModalVisible) = $event)),
          onNavigate: _ctx.goToInitiativePage
        }, null, 8, ["visibility", "onNavigate"]))
      : _createCommentVNode("", true),
    (_ctx.allStepsLocked)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3))
      : _createCommentVNode("", true)
  ]))
}