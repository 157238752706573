import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createVNode as _createVNode, withCtx as _withCtx, createSlots as _createSlots } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PxModalMessage = _resolveComponent("PxModalMessage")!
  const _component_MatchDetailsModalHeader = _resolveComponent("MatchDetailsModalHeader")!
  const _component_MatchDetailsModalTable = _resolveComponent("MatchDetailsModalTable")!
  const _component_MatchDetailsTableLegend = _resolveComponent("MatchDetailsTableLegend")!
  const _component_MatchDetailsModalFooter = _resolveComponent("MatchDetailsModalFooter")!
  const _component_ElDialog = _resolveComponent("ElDialog")!

  return (_openBlock(), _createBlock(_component_ElDialog, {
    modelValue: _ctx.innerVisibility,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.innerVisibility) = $event)),
    "append-to-body": true,
    "close-on-click-modal": false,
    "close-on-press-escape": false,
    "destroy-on-close": true,
    title: _ctx.$t('matching.matchDetails.title'),
    class: "match-details-modal",
    width: "903px"
  }, _createSlots({
    default: _withCtx(() => [
      (!_ctx.hasMatch)
        ? (_openBlock(), _createBlock(_component_PxModalMessage, {
            key: 0,
            title: _ctx.$t(`matching.matchDetails.noMatch[${_ctx.userType}]`),
            visible: !_ctx.hasMatch,
            type: "info"
          }, null, 8, ["title", "visible"]))
        : _createCommentVNode("", true),
      _createVNode(_component_MatchDetailsModalHeader, {
        class: _normalizeClass({ 'match-details-modal__no-match': !_ctx.hasMatch }),
        "company-logo": _ctx.companyLogo,
        "company-name": _ctx.companyName,
        "has-match": _ctx.hasMatch,
        "match-percentage": _ctx.matchPercentage,
        "user-type": _ctx.userType
      }, null, 8, ["class", "company-logo", "company-name", "has-match", "match-percentage", "user-type"]),
      _createVNode(_component_MatchDetailsModalTable, {
        "table-data": _ctx.matchDetails.details,
        "user-type": _ctx.userType,
        class: "match-details-modal__table"
      }, null, 8, ["table-data", "user-type"]),
      _createVNode(_component_MatchDetailsTableLegend, { "legend-cards": _ctx.tableLegend }, null, 8, ["legend-cards"])
    ]),
    _: 2
  }, [
    (_ctx.$user.isSupporter())
      ? {
          name: "footer",
          fn: _withCtx(() => [
            _createVNode(_component_MatchDetailsModalFooter, {
              onCloseModal: _cache[0] || (_cache[0] = ($event: any) => (_ctx.innerVisibility = false))
            })
          ]),
          key: "0"
        }
      : undefined
  ]), 1032, ["modelValue", "title"]))
}