import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-0ce28fea"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "milestone-plan-state-badge" }
const _hoisted_2 = {
  key: 0,
  class: "milestone-plan-state-badge__last-update"
}
const _hoisted_3 = { class: "milestone-plan-state-badge__label" }
const _hoisted_4 = {
  key: 1,
  class: "milestone-plan-state-badge__bullet"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PxIcon = _resolveComponent("PxIcon")!
  const _component_ElTooltip = _resolveComponent("ElTooltip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.lastUpdate)
      ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.$t("milestonePlanner.milestonePlan.planForm.badges.lastUpdate", {
          updateDate: _ctx.lastUpdate,
        })), 1))
      : _createCommentVNode("", true),
    _createVNode(_component_ElTooltip, {
      content: _ctx.draftTooltipCopy,
      disabled: !_ctx.isDraft,
      placement: "top",
      "popper-class": "milestone-plan-state-badge__tooltip el-tooltip__popper--small el-abaca-tooltip"
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", {
          class: _normalizeClass([{
          'milestone-plan-state-badge__wrapper--draft': _ctx.isDraft,
          'milestone-plan-state-badge__wrapper--not-planned': _ctx.isNotPlanned,
        }, "milestone-plan-state-badge__wrapper"])
        }, [
          (!_ctx.isNotPlanned)
            ? (_openBlock(), _createBlock(_component_PxIcon, {
                key: 0,
                name: _ctx.icon.name,
                size: _ctx.icon.size,
                class: "milestone-plan-state-badge__icon"
              }, null, 8, ["name", "size"]))
            : _createCommentVNode("", true),
          _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.label), 1),
          (_ctx.isDraft || _ctx.isNotPlanned)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4))
            : _createCommentVNode("", true)
        ], 2)
      ]),
      _: 1
    }, 8, ["content", "disabled"])
  ]))
}