<template>
  <div
    :class="{
      'submissions-table-row__owner-submissions-view': isOwnerSubmissionsView,
      'submissions-table-row__simplified-view': isModalView,
    }"
    class="submissions-table-row"
  >
    <!-- Name -->
    <div class="submissions-table-row__first-col submissions-table-row__col">
      <p :title="submissionData.affiliate.name">
        {{ submissionData.affiliate.name }}
      </p>
    </div>
    <!-- Owners -->
    <div v-if="ownersLength" class="submissions-table-row__col">
      <template v-if="isUserSubmissionsView">
        <PxAvatarSet
          v-if="ownersLength > 1"
          :items="submissionData.owners"
          :size="21"
          :visible-limit="5"
          @click="goToCompanyProfile"
        ></PxAvatarSet>
        <div
          v-else
          class="submissions-table-row__owner"
          @click="goToCompanyProfile(submissionData.owners[0])"
        >
          <PxAvatar
            :name="submissionData.owners[0].name"
            :photo="submissionData.owners[0].logo"
            :size="24"
            class="submissions-table-row__owner-photo"
            styling="neutral"
            type="round"
          />
          <p :title="submissionData.owners[0].name">
            {{ submissionData.owners[0].name }}
          </p>
        </div>
      </template>
      <template v-else>
        <MatchingCardThumbnail
          :level="[]"
          :name="submissionData.owners[0].name"
          :size="34"
          :thumbnail="submissionData.owners[0].logo"
        />
        <p
          :title="submissionData.owners[0].name"
          @click="goToCompanyProfile(submissionData.owners[0])"
        >
          {{ submissionData.owners[0].name }}
        </p>
      </template>
    </div>
    <!-- Labels -->
    <div class="submissions-table-row__col">
      <SubmissionsInfoBadges :badges="submissionData.shared_info" />
    </div>
    <!-- Last Update date -->
    <div class="submissions-table-row__last-col submissions-table-row__col">
      <p v-if="lastUpdateDate">
        {{ lastUpdateDate }}
      </p>
      <PxButton v-bind="actionButtonProps" @click="onActionButtonClick" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import moment from "moment";

import SubmissionsInfoBadges from "@/modules/submissions/components/submissions-info-badges/submissions-info-badges.vue";
import MatchingCardThumbnail from "@/components/matching-card/matching-card-thumbnail.vue";

import { AFFILIATES_BASE } from "@/modules/affiliates/services/router/routes-names";
import {
  ROUTE_ENTREPRENEUR_SUBMISSION,
  ROUTE_SUPPORTER_SUBMISSION,
} from "@/modules/program/services/router/routes-names";
import {
  ROUTE_PROFILE,
  ROUTE_OWNED_AFFILIATES_SUBMISSIONS,
} from "@/modules/profile/services/router/routes-names";
import {
  EAffiliateSubmissionType,
  IAffiliateSubmission,
  IAffiliateSubmissionsOwners,
} from "@/modules/submissions/services/data/submissions/submissions.interface";
import {
  EPxButtonSize,
  EPxButtonType,
} from "@/components/px-button/px-button.types";
import { ENTREPRENEUR_USER_TYPE } from "@/modules/authentication/constants";
import { baseUrl } from "@/services/utils/utils";

export default defineComponent({
  name: "SubmissionsTableRow",

  components: {
    SubmissionsInfoBadges,
    MatchingCardThumbnail,
  },

  static: {
    draftButton: {
      size: EPxButtonSize.SMALL,
      type: EPxButtonType.BLUE,
      label: "userSubmissions.table.ctas.resume",
    },

    submittedButton: {
      size: EPxButtonSize.SMALL,
      type: EPxButtonType.DEFAULT,
      label: "userSubmissions.table.ctas.view",
    },
  },

  props: {
    submissionData: {
      type: Object as () => IAffiliateSubmission,
      required: true,
    },

    type: {
      type: String,
      default: EAffiliateSubmissionType.DRAFT,
      validator(value: EAffiliateSubmissionType) {
        return Object.values(EAffiliateSubmissionType).includes(value);
      },
    },

    isUserSubmissionsView: {
      type: Boolean,
      default: false,
    },

    isModalView: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    actionButtonProps(): any {
      return this.type === EAffiliateSubmissionType.DRAFT
        ? {
            ...this.$options.static.draftButton,
            label: this.isUserSubmissionsView
              ? this.$t(`userSubmissions.table.actions[${0}]`)
              : (this.$t(
                  `ownedAffiliatesSubmissions.table.actions[${0}]`,
                ) as string),
          }
        : {
            ...this.$options.static.submittedButton,
            label: this.isUserSubmissionsView
              ? this.$t(`userSubmissions.table.actions[${1}]`)
              : (this.$t(
                  `ownedAffiliatesSubmissions.table.actions[${1}]`,
                ) as string),
          };
    },

    ownersLength(): number {
      return this.submissionData.owners ? this.submissionData.owners.length : 0;
    },

    lastUpdateDate(): string {
      return this.submissionData.last_update
        ? moment(this.submissionData.last_update).format("YYYY-MM-DD")
        : "";
    },

    isOwnerSubmissionsView(): boolean {
      return this.$route.name === ROUTE_OWNED_AFFILIATES_SUBMISSIONS;
    },
  },

  methods: {
    async onActionButtonClick() {
      // If the submission is a draft
      if (this.type === EAffiliateSubmissionType.DRAFT) {
        // If user is seeing is own submissions, we need to redirect to the affiliates page
        if (this.isUserSubmissionsView) {
          return this.$router.push({
            name: AFFILIATES_BASE,
            query: {
              a: this.submissionData.affiliate.id.toString(),
            },
          });
        } else {
          // If user is seeing other user submissions, we need to send a email to resume the draft
          this.sendEmailToResumeDraft();
        }
      }

      // If the submission is submitted, we need to redirect to the submission page
      if (this.submissionData.submission_uid) {
        // If user is seeing is own submissions, we need to redirect to the submission page
        if (this.isUserSubmissionsView) {
          return this.$router.push({
            name: this.$user.isEntrepreneur()
              ? ROUTE_ENTREPRENEUR_SUBMISSION
              : ROUTE_SUPPORTER_SUBMISSION,
            params: { uid: this.submissionData.submission_uid },
          });
        } else {
          // If user is seeing other user submissions, we need to redirect to the correct submission page
          return this.$router.push({
            name:
              this.submissionData.company_type === ENTREPRENEUR_USER_TYPE
                ? ROUTE_ENTREPRENEUR_SUBMISSION
                : ROUTE_SUPPORTER_SUBMISSION,
            params: { uid: this.submissionData.submission_uid },
          });
        }
      }
    },

    sendEmailToResumeDraft() {
      if (this.submissionData.owners?.length) {
        const company = this.submissionData.owners[0];
        const email = company.email;
        const supporter = this.$store.get("auth/supporter").data;
        const affiliate = this.submissionData.affiliate;
        const submissionLink = `${baseUrl}/?a=${affiliate.id}`;
        const subject = this.$t("submissions.email.subject");
        const body = this.$t("submissions.email.body", {
          supporter: supporter.name,
          company: company.name,
          affiliate: affiliate.name,
          submissionLink,
        }) as string;

        const mailtoLink = `mailto:${email}?subject=${encodeURIComponent(
          subject,
        )}&body=${encodeURIComponent(body)}`;

        // Open the email client
        window.location.href = mailtoLink;
      }
    },

    goToCompanyProfile(member: IAffiliateSubmissionsOwners) {
      if (!member.id) return;

      this.$router.push({
        name: ROUTE_PROFILE,
        params: {
          id: member.id.toString(),
        },
      });
    },
  },
});
</script>

<style lang="scss" scoped>
.submissions-table-row {
  display: grid;
  grid-template-columns: 38% 16.7% repeat(2, 1fr);

  align-items: center;

  padding: 15px 0 15px 0;

  &__col {
    padding-left: 12px;

    :deep() .px-avatar-set__item {
      cursor: pointer;
    }
  }

  &__first-col {
    max-width: 374px;

    p {
      @include grotesk(bold);

      padding-top: 1px;

      color: $ebony-clay;
      font-size: 15px;
      line-height: 1.47;
      margin: 0;
    }
  }

  &__last-col {
    display: flex;
    align-items: center;
    justify-content: space-between;

    p {
      @include grotesk(semiBold);

      font-size: 15px;
      color: $manatee;
      line-height: 15px;
      margin: 0;
    }

    @include breakpoint-down(md) {
      flex-direction: column;
      align-items: flex-start;
      gap: 5px;
    }
  }

  &__owner {
    display: grid;
    grid-template-columns: 24px 1fr;
    align-items: center;
    gap: 8px;
    padding-right: 10px;
    cursor: pointer;

    &-photo.px-avatar {
      top: 1px;
      user-select: none;
      box-shadow: inset 0 0 3px 0 rgba(139, 143, 161, 0.3);
    }

    &-photo :deep() .px-avatar__letter {
      font-size: 12px;
      line-height: 2;
    }

    p {
      margin: 0;
      font-size: 14px;
      line-height: 1.43;
      color: $manatee;
      letter-spacing: -0.36px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    p:hover {
      text-decoration: underline;
      text-decoration-color: $manatee;
    }
  }

  :deep() .px-button {
    min-width: 0;
    position: relative;
    top: 1px;
    right: 12px;

    @include breakpoint-down(md) {
      top: 0;
      right: 0;
    }
  }
}

.submissions-table-row__simplified-view {
  grid-template-columns: 61.2% 24% 1fr;
  padding: 16px 0 15px 0;

  @include breakpoint-down(md) {
    grid-template-columns: 61.2% 1fr 1fr;
  }

  .submissions-table-row__col:first-of-type p {
    @include grotesk(bold);

    @include multiLineEllipsis(
      $font-size: 15px,
      $line-height: 1.47,
      $lines-to-show: 1
    );
  }
}

.submissions-table-row__owner-submissions-view {
  grid-template-columns: 18.3% 38% repeat(2, 1fr);

  .submissions-table-row__col:first-of-type {
    order: 2;

    p {
      @include grotesk(bold);

      @include multiLineEllipsis(
        $font-size: 15px,
        $line-height: 1.47,
        $lines-to-show: 1
      );
    }
  }

  .submissions-table-row__col:nth-child(2) {
    order: 1;

    display: flex;
    gap: 6px;
    align-items: center;

    p {
      font-weight: 600;
      color: $ebony-clay;
      cursor: pointer;
      margin: 0;

      &:hover {
        text-decoration: underline;
      }

      @include multiLineEllipsis(
        $font-size: 15px,
        $line-height: 1.47,
        $lines-to-show: 1
      );
    }

    .matching-card-thumbnail {
      top: 1px;
      right: 1px;
    }
  }

  .submissions-table-row__col:nth-child(3) {
    order: 3;
  }

  .submissions-table-row__col:nth-child(4) {
    order: 4;
  }

  .submissions-table-row__col:nth-child(5) {
    order: 5;
  }
}
</style>
