import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-2c4487fe"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "visitor-join-bar__col-start" }
const _hoisted_2 = { class: "visitor-join-bar__title" }
const _hoisted_3 = { class: "visitor-join-bar__col-middle" }
const _hoisted_4 = { class: "visitor-join-bar__col-end" }
const _hoisted_5 = {
  key: 0,
  class: "visitor-join-bar__content"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PxIcon = _resolveComponent("PxIcon")!
  const _component_PxButton = _resolveComponent("PxButton")!
  const _component_i18n_t = _resolveComponent("i18n-t")!
  const _component_PxStickyBar = _resolveComponent("PxStickyBar")!

  return (_openBlock(), _createBlock(_component_PxStickyBar, { class: "visitor-join-bar" }, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: "visitor-join-bar__menu-handle",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleMenu && _ctx.toggleMenu(...args)))
      }, [
        _createVNode(_component_PxIcon, {
          name: _ctx.menuIcon,
          size: 16
        }, null, 8, ["name"])
      ]),
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("h2", _hoisted_2, _toDisplayString(_ctx.$t("profile.visitor.stickyBar.titleMobile")), 1),
        _createVNode(_component_PxButton, {
          class: "visitor-join-bar__cta-button",
          size: "extra-large",
          type: "green",
          onClick: _ctx.onClickSignUp
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t("profile.visitor.stickyBar.button")), 1)
          ]),
          _: 1
        }, 8, ["onClick"])
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_i18n_t, {
          class: "visitor-join-bar__subtitle",
          keypath: "profile.visitor.stickyBar.subtitleMobile",
          tag: "div"
        }, {
          link: _withCtx(() => [
            _createVNode(_component_PxButton, {
              class: "visitor-join-bar__subtitle-link el-button--link el-button--link-white",
              type: "link",
              onClick: _ctx.onClickOpenHowItWorksLink
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t("profile.visitor.stickyBar.link")), 1)
              ]),
              _: 1
            }, 8, ["onClick"])
          ]),
          _: 1
        })
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_Transition, { name: "fade" }, {
          default: _withCtx(() => [
            (_ctx.menuOpen)
              ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(_ctx.$t("profile.visitor.stickyBar.content")), 1))
              : _createCommentVNode("", true)
          ]),
          _: 1
        })
      ])
    ]),
    _: 1
  }))
}