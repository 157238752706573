<template>
  <div class="account-settings page-container">
    <div class="account-settings__navigation">
      <AccountSettingsNavigation v-model:selected-tab="selectedTab" />
    </div>
    <div
      v-if="showAccountInfoPanel"
      class="account-settings__account-info-panel"
    >
      <AccountSettingsPassword />
      <AccountSettingsEmail />
    </div>
    <div
      v-else
      v-loading="!subscription"
      class="account-settings__subscription-panel"
      element-loading-background="#fff"
    >
      <template v-if="subscription">
        <header
          :class="{
            'account-settings__legacy-plan': isOnLegacyPlan,
          }"
        >
          <div>
            <h1>
              {{ settingsCopy.title }}
            </h1>
            <p>
              {{ settingsCopy.description }}
            </p>
          </div>
          <PxButton
            class="account-settings__cta"
            v-bind="subscriptionCta"
            @click="ctaAction"
          />
        </header>
        <div
          :class="{
            'account-settings__pricing-switch-legacy': isOnLegacyPlan,
          }"
          class="account-settings__pricing-switch"
        >
          <h2 v-if="isOnLegacyPlan">
            {{ $t("profile.accountSettingsPage.legacyPlan.availablePlans") }}
          </h2>
          <PricingSwitch v-model="yearly" />
        </div>
        <div class="account-settings__card-wrapper">
          <PricingCard
            v-bind="freePlanCardProps"
            @click="changeSubscription(plans.FREE)"
          />
          <PricingCard
            v-bind="proPlanCardProps"
            @click="
              changeSubscription(yearly ? plans.PRO_YEARLY : plans.PRO_MONTHLY)
            "
          />
        </div>
      </template>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import AccountSettingsNavigation, {
  EAccountSettingsNavigationTabs,
} from "@/modules/profile/components/account-settings/account-settings-navigation.vue";
import AccountSettingsPassword from "@/modules/profile/components/account-settings/account-settings-password.vue";
import AccountSettingsEmail from "@/modules/profile/components/account-settings/account-settings-email.vue";
import PricingSwitch from "@/modules/profile/components/account-settings/pricing-switch.vue";
import PricingCard, {
  IPricingCardFeature,
} from "@/modules/profile/components/account-settings/pricing-card.vue";

import {
  EPxButtonType,
  EPxButtonSize,
} from "@/components/px-button/px-button.types";
import {
  ESubscriptionPlanID,
  ISubscription,
} from "@/modules/authentication/services/data/subscription/subscription.interface";

import { EAuthActions } from "@/modules/authentication/services/store/auth/auth-types";
import { subscriptionProvider } from "@/modules/authentication/services/data/subscription/subscription.provider";
import {
  SUBSCRIPTIONS_DATA,
  PRO_SUBSCRIPTION_DATA,
} from "@/modules/profile/pricing-cards-data";

export default defineComponent({
  name: "AccountSettingsView",

  components: {
    AccountSettingsNavigation,
    AccountSettingsPassword,
    AccountSettingsEmail,
    PricingSwitch,
    PricingCard,
  },

  data() {
    return {
      selectedTab:
        (this.$route.query.tab as string) ||
        EAccountSettingsNavigationTabs.INFO,
      yearly: true,
      plans: {
        FREE: ESubscriptionPlanID.FREE,
        PRO_MONTHLY: ESubscriptionPlanID.PRO_MONTHLY,
        PRO_YEARLY: ESubscriptionPlanID.PRO_YEARLY,
      },
      subscription: undefined as ISubscription | undefined,
    };
  },

  computed: {
    subscriptionCta(): any {
      return {
        label: this.settingsCopy.cta,
        type: EPxButtonType.GREEN,
        size: EPxButtonSize.LARGE,
      };
    },

    freePlanCardProps(): any {
      return {
        ...SUBSCRIPTIONS_DATA.free,
        features: SUBSCRIPTIONS_DATA.free.features.map(
          (feature: IPricingCardFeature) => ({
            ...feature,
            icon: "success--blue-dark",
            iconSize: 20,
          }),
        ),
        ctaLabel:
          this.subscription?.planId === ESubscriptionPlanID.FREE
            ? this.subscriptionPanelCopy.currentPlanCta
            : this.subscriptionPanelCopy.pricingCards.free.cta,
        disabled:
          this.subscription?.planId === ESubscriptionPlanID.FREE ||
          this.isOnLegacyPlan,
      };
    },

    proPlanCardProps(): any {
      return {
        ...PRO_SUBSCRIPTION_DATA,
        price: this.yearly ? 100 : 120,
        priceNote: this.yearly
          ? this.subscriptionPanelCopy.pricingCards.pro.priceNoteYearly
          : this.subscriptionPanelCopy.pricingCards.pro.priceNoteMonthly,
        ctaLabel:
          this.subscription?.planId === ESubscriptionPlanID.PRO_MONTHLY ||
          this.subscription?.planId === ESubscriptionPlanID.PRO_YEARLY
            ? this.subscriptionPanelCopy.currentPlanCta
            : this.subscriptionPanelCopy.pricingCards.pro.cta,
        disabled: this.subscription?.planId !== ESubscriptionPlanID.FREE,
      };
    },

    isOnLegacyPlan(): boolean {
      return (
        this.subscription?.planId !== this.plans.FREE &&
        this.subscription?.planId !== this.plans.PRO_MONTHLY &&
        this.subscription?.planId !== this.plans.PRO_YEARLY
      );
    },

    subscriptionPanelCopy() {
      return this.$tm("profile.accountSettingsPage.subscriptionPanel") as {
        title: string;
        description: string;
        cta: string;
        currentPlanCta: string;
        pricingCards: {
          free: {
            cta: string;
          };
          pro: {
            priceNoteMonthly: string;
            priceNoteYearly: string;
            cta: string;
          };
        };
        successMessage: {
          free: string;
          pro: string;
        };
      };
    },

    settingsCopy() {
      return this.isOnLegacyPlan
        ? (this.$tm("profile.accountSettingsPage.legacyPlan") as {
            title: string;
            description: string;
            cta: string;
            emailSubject: string;
            availablePlans: string;
          })
        : this.subscriptionPanelCopy;
    },

    showAccountInfoPanel(): boolean {
      return this.selectedTab === EAccountSettingsNavigationTabs.INFO;
    },
  },

  watch: {
    selectedTab: {
      handler(newTab: string, oldTab: string) {
        if (newTab === oldTab) return;

        this.$router.push({
          query: {
            tab: newTab,
          },
        });
      },
    },
  },

  mounted() {
    Chargebee.getInstance().setPortalSession(
      async () => await subscriptionProvider.getPortalSession(),
    );

    this.fetchSubscription();
  },

  methods: {
    async fetchSubscription() {
      await this.$store.dispatch(EAuthActions.FETCH_SUBSCRIPTION);

      this.subscription = this.$store.get("auth/subscription");
    },

    contactSales() {
      const subject = this.$t(
        "profile.accountSettingsPage.legacyPlan.emailSubject",
      ) as string;

      // Open the email client
      window.location.href = `mailto:sales@abaca.app?subject=${encodeURIComponent(
        subject,
      )}`;
    },

    ctaAction() {
      this.isOnLegacyPlan ? this.contactSales() : this.manageSubscription();
    },

    manageSubscription() {
      Chargebee.getInstance().createChargebeePortal().open({
        subscriptionChanged: this.onSubscriptionChange,
      });
    },

    changeSubscription(plan: ESubscriptionPlanID) {
      Chargebee.getInstance().openCheckout({
        hostedPage: async () => await subscriptionProvider.getCheckout(plan),
        success: this.onSubscriptionChange,
      });
    },

    async onSubscriptionChange() {
      const previousPlan = this.subscription?.planId;

      await this.fetchSubscription();

      // If we are changing between pro plans, we don't want to show the success message
      if (
        previousPlan !== this.plans.FREE &&
        this.subscription?.planId !== this.plans.FREE
      ) {
        return;
      }

      const message =
        this.subscription?.planId === this.plans.FREE
          ? this.subscriptionPanelCopy.successMessage.free
          : this.subscriptionPanelCopy.successMessage.pro;

      this.$message({
        message: message as string,
        type: "success",
        customClass: "is-navbar account-settings-success-message",
        duration: 10000,
      });
    },
  },
});
</script>

<style lang="scss" scoped>
.account-settings {
  @include breakpoint-up(lg) {
    grid-template-columns: 300px 1fr;
  }

  @include breakpoint-up(sm) {
    display: grid;
    gap: 30px;
    margin-top: 60px;
    align-items: flex-start;
  }

  @include breakpoint-down(lg) {
    grid-template-columns: 1fr;
    padding: 0 100px;
    margin-bottom: 40px;
    flex: 0;
  }

  @include breakpoint-down(sm) {
    padding: 0;
    margin-top: 0;
    gap: 0;
  }

  &__account-info-panel {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }

  &__subscription-panel {
    padding: 22px 23px 24px 23px;

    border-radius: 4px;
    box-shadow: 0 2px 11px 0 rgba(0, 0, 0, 0.05);
    border: 1px solid $mischka;
    background-color: $white;
    min-height: 600px;

    @include breakpoint-down(sm) {
      border-left: none;
      border-right: none;
      border-radius: 0;

      padding: 22px 16px 24px 16px;
    }

    header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      @include breakpoint-down(sm) {
        flex-direction: column;
        gap: 15px;
      }
    }

    h1 {
      @include grotesk(semiBold);

      margin: 0;

      font-size: 20px;
      line-height: 1.6;
      color: $ebony-clay;
    }

    p {
      font-size: 14px;
      line-height: 1.43;
      color: $manatee;
      letter-spacing: -0.33px;
    }
  }

  &__cta {
    margin-top: 1px;

    height: 40px;
    padding: 0 16px;
  }

  &__legacy-plan {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 16px;

    padding: 21px 23px 22px 23px;
    border-radius: 5px;
    border: solid 1px $mischka;
    background-color: $alabaster;

    h1 {
      letter-spacing: -0.24px;
    }

    p {
      margin-top: 7px;

      color: $ebony-clay;
      letter-spacing: -0.3px;
    }
  }

  &__pricing-switch {
    display: flex;
    justify-content: space-between;
    align-items: center;

    margin-top: 30px;
    margin-bottom: 17px;

    @include breakpoint-down(sm) {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 20px;

      margin-top: 47px;
    }

    &-legacy {
      margin-top: 51px;
      margin-bottom: 29px;

      @include breakpoint-down(sm) {
        margin-top: 47px;
      }
    }

    h2 {
      margin: 0;

      font-size: 20px;
      font-weight: 600;
      line-height: 1;
      color: $ebony-clay;
    }
  }

  &__card-wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;

    @include breakpoint-down(sm) {
      grid-template-columns: 1fr;
    }
  }
}
</style>
<style lang="scss">
.account-settings-success-message.el-message.is-navbar {
  padding: 7px 0;

  @include breakpoint-down(sm) {
    top: 94px;
    padding: 10px;
  }

  .el-message__content {
    letter-spacing: -0.2px;
  }

  .el-message__icon {
    margin-right: 7px;
  }
}
</style>
