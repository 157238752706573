<template>
  <ElDialog
    v-model="innerVisibility"
    :append-to-body="true"
    class="charts-locked-modal"
    :width="width"
    :close-on-click-modal="closable"
    :close-on-press-escape="closable"
    :show-close="closable"
    :modal="showMask"
    :top="marginTop"
  >
    <PxIcon :size="60" :name="icon" />
    <h1>{{ $t(title) }}</h1>
    <p v-html="$t(description)"></p>
    <template #footer>
      <PxButton
        v-bind="buttonProps"
        data-testid="charts-locked-modal-cta"
        @click="onButtonClick"
      />
    </template>
  </ElDialog>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import ElDialogMixin from "@/mixins/el-dialog.mixin";

import { EPxButtonType } from "@/components/px-button/px-button.types";

export default defineComponent({
  name: "ChartsLockedModal",

  mixins: [ElDialogMixin],

  props: {
    icon: {
      type: String,
      default: "private",
    },

    title: {
      type: String,
      default: "chartsDashboard.privacyModal.title",
    },

    description: {
      type: String,
      default: "chartsDashboard.privacyModal.description",
    },

    ctaLabel: {
      type: String,
      default: "chartsDashboard.privacyModal.cta",
    },

    width: {
      type: String,
      default: "660px",
    },

    closable: {
      type: Boolean,
      default: true,
    },

    showMask: {
      type: Boolean,
      default: true,
    },

    marginTop: {
      type: String,
      default: "15vh",
    },
  },

  computed: {
    buttonProps() {
      return {
        type: EPxButtonType.BLUE,
        label: this.$t(this.ctaLabel),
      };
    },
  },

  methods: {
    onButtonClick() {
      return this.$emit("navigate");
    },
  },
});
</script>

<style lang="scss">
.charts-locked-modal {
  .el-dialog__header {
    background: none;
    border: none;
    padding: 60px 0 0;
  }

  .el-dialog__body {
    padding: 0 50px 31px;
    text-align: center;
  }

  .el-dialog__headerbtn {
    top: 20px;
    right: 14px;
    width: 22px;
    height: 22px;
  }

  .el-dialog__footer {
    display: flex;
    justify-content: center;

    padding: 16px 0;

    &::before {
      width: calc(100% - 224px);
    }
  }

  h1 {
    margin: 8px 0 9px 0;

    font-size: to-rem(20px);
    color: $ebony-clay;
    line-height: 1.5;
    letter-spacing: -0.2px;
  }

  p {
    line-height: 1.6;
  }

  b {
    font-weight: 600;
  }

  .el-button {
    position: relative;
    left: 1px;

    padding: 0 15px;
  }
}
</style>
