import { IGenericState } from "@/services/store/generic/generic-state.interface";
import { IMatchingCriteriaWeight } from "@/modules/matching/services/data/matching-criteria/matching-criteria.interface";

/**
 * Represents the store structure for the supporter type store data.
 */
export type IMatchingCriteriaWeightState =
  IGenericState<IMatchingCriteriaWeight>;

export enum EMatchingCriteriaWeightState {
  NAMESPACE = "matchingCriteriaWeight",
}
