import { ICompanyList } from "@/modules/company-lists/services/data/company-list/company-list.interface";
import { ICompanyListState } from "@/modules/company-lists/services/store/company-list/company-list.interface";
import { getGenericStateGetters } from "@/services/store/generic/generic-state.getters";
import { RootState } from "@/services/store/root-state";
import { GetterTree } from "vuex";

/**
 * Available getters to get data from the company list store.
 */
export enum ECompanyListGetters {
  VALUE = "getValue",
  VALUES = "getValues",
  IS_LOADING = "isLoading",
  ERROR = "getError",
  HAS_ERROR = "hasError",
  CURRENT_DETAIL_PAGE = "currentDetailPage",
  IS_DETAIL_LOADING = "isDetailLoading",
}

export const getters: GetterTree<ICompanyListState, RootState> = {
  ...getGenericStateGetters<ICompanyList>(),

  [ECompanyListGetters.CURRENT_DETAIL_PAGE](state) {
    return state.currentDetailPage;
  },

  [ECompanyListGetters.IS_DETAIL_LOADING](state) {
    return state.detailLoading;
  },
};
