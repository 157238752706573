import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "matching-empty-results" }
const _hoisted_2 = { class: "matching-empty-results__icon" }
const _hoisted_3 = { class: "matching-empty-results__title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PxIcon = _resolveComponent("PxIcon")!
  const _component_PxButton = _resolveComponent("PxButton")!
  const _component_i18n_t = _resolveComponent("i18n-t")!
  const _component_PxNotice = _resolveComponent("PxNotice")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_PxIcon, {
        name: _ctx.$t(_ctx.icon),
        size: "92"
      }, null, 8, ["name"])
    ]),
    _createElementVNode("h2", _hoisted_3, _toDisplayString(_ctx.$t(_ctx.title, { targetUser: _ctx.targetUser })), 1),
    _createVNode(_component_i18n_t, {
      keypath: _ctx.subtitle,
      class: "matching-empty-results__subtitle",
      tag: "p"
    }, {
      discoverButton: _withCtx(() => [
        _createVNode(_component_PxButton, {
          class: "link",
          type: "link",
          onClick: _ctx.goToMatches
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t("matching.matchingInterest.matchingList.linkToDiscover")), 1)
          ]),
          _: 1
        }, 8, ["onClick"])
      ]),
      questionsButton: _withCtx(() => [
        (_ctx.$screen.lgUp)
          ? (_openBlock(), _createBlock(_component_PxButton, {
              key: 0,
              class: "link",
              type: "link",
              onClick: _ctx.openQuestionsSidebar
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t("matching.matchingInterest.matchingList.linkToQuestions")), 1)
              ]),
              _: 1
            }, 8, ["onClick"]))
          : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
              _createTextVNode(_toDisplayString(_ctx.$t("matching.matchingInterest.matchingList.linkToQuestions")), 1)
            ], 64))
      ]),
      targetUser: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.targetUser), 1)
      ]),
      _: 1
    }, 8, ["keypath"]),
    (_ctx.notice)
      ? (_openBlock(), _createBlock(_component_PxNotice, {
          key: 0,
          icon: _ctx.emptyStateIcon,
          title: _ctx.$t(_ctx.noticeTitle),
          direction: "horizontal"
        }, {
          cta: _withCtx(() => [
            _createVNode(_component_PxButton, {
              size: "small",
              type: "default",
              onClick: _ctx.onClickOpenFeedbackPage
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t(_ctx.noticeButton)), 1)
              ]),
              _: 1
            }, 8, ["onClick"])
          ]),
          _: 1
        }, 8, ["icon", "title"]))
      : _createCommentVNode("", true)
  ]))
}