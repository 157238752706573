<template>
  <ElDialog
    v-model="innerVisibility"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :show-close="true"
    class="el-dialog--no-header el-dialog--compact-footer px-step-by-step-dialog"
    data-testid="px-step-by-step-dialog"
    width="740px"
    @close="onModalClose"
  >
    <template #header>
      <slot name="title" />
    </template>

    <slot />
    <template #footer>
      <PxStepByStepDialogNavigation
        :current-page="currentPage"
        :page-count="pageCount"
        data-testid="px-step-by-step-dialog-navigation"
        @close-dialog="onModalClose"
        @page-change="onPageChange"
      />
    </template>
  </ElDialog>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import ElDialogMixin from "@/mixins/el-dialog.mixin";
import PxStepByStepDialogNavigation from "./px-step-by-step-dialog-navigation.vue";

export default defineComponent({
  name: "PxStepByStepDialog",

  components: {
    PxStepByStepDialogNavigation,
  },

  mixins: [ElDialogMixin],

  props: {
    pageCount: { type: Number, required: true },
    currentPage: { type: Number, required: true },
  },

  methods: {
    onPageChange(page: number) {
      this.$emit("update:currentPage", page);
    },

    onModalClose() {
      this.$emit("close-dialog");
      this.$emit("update:currentPage", 1);
    },
  },
});
</script>

<style lang="scss">
.px-step-by-step-dialog.el-dialog {
  .el-dialog__headerbtn {
    top: -32px;
    right: 16px;
  }

  .el-dialog__footer {
    padding: 14px 32px 16px;
    border-top: 1px solid rgba($malibu, 0.2);
  }
}
</style>
