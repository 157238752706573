<template>
  <div
    :class="{
      'team-member-settings-header--from-profile': isFromProfile,
    }"
    class="team-member-settings-header"
  >
    <div class="team-member-settings-header__content">
      <h4 data-testid="team-member-settings-header-title">
        {{ title }}
      </h4>
      <p data-testid="team-member-settings-header-subtitle">
        {{ subtitle }}
      </p>
    </div>
    <div
      v-if="isFromProfile"
      class="team-member-settings-header__actions"
      data-testid="team-member-settings-header-actions"
    >
      <PxButton
        v-bind="saveButtonProps"
        :disabled="isSaveButtonDisabled"
        class="team-member-settings-header__save-button"
        data-testid="team-member-settings-save-button"
        @click="$emit('save-members')"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import { TranslateResult } from "vue-i18n";
import {
  EPxButtonSize,
  EPxButtonVariant,
  EPxButtonType,
} from "@/components/px-button/px-button.types";

export default defineComponent({
  name: "TeamMemberSettingsHeader",

  static: {
    saveButton: {
      size: EPxButtonSize.MEDIUM,
      variant: EPxButtonVariant.FLUID,
      type: EPxButtonType.BLUE,
    },
  },

  props: {
    isSaveButtonDisabled: {
      type: Boolean,
      default: false,
    },

    isFromProfile: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    title(): TranslateResult {
      return this.isFromProfile
        ? this.$t("teamManagement.settings.profileTitle")
        : this.$t("teamManagement.settings.title");
    },

    subtitle(): TranslateResult {
      return this.$t("teamManagement.settings.subtitle");
    },

    saveButtonProps(): any {
      return {
        ...this.$options.static.saveButton,
        label: this.$t("common.saveChanges"),
      };
    },
  },
});
</script>

<style lang="scss" scoped>
.team-member-settings-header {
  display: flex;
  justify-content: flex-start;

  padding: 0 1px 19px 23px;

  border-bottom: 1px solid $turkish-angora;

  &--from-profile {
    justify-content: space-between;
  }

  h4 {
    font-size: 20px;
    line-height: 32px;
    margin: 0;

    color: $ebony-clay;
  }

  p {
    color: $manatee;
    font-size: 14px;
    letter-spacing: -0.3px;
    line-height: 1.3;
    margin: 0;
  }

  &__actions {
    display: flex;
    gap: 14px;
  }

  &__save-button {
    padding: 0 15px;
  }
}
</style>
