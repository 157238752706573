<template>
  <div
    :class="{
      'vue-content-placeholders': true,
      'vue-content-placeholders-is-rounded': rounded,
      'vue-content-placeholders-is-centered': centered,
      'vue-content-placeholders-is-animated': animated,
    }"
  >
    <slot></slot>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "ContentPlaceholders",
  props: {
    rounded: {
      type: Boolean,
      default: false,
    },
    centered: {
      type: Boolean,
      default: false,
    },
    animated: {
      type: Boolean,
      default: true,
    },
  },
});
</script>
