import {
  GenericProvider,
  Provider,
  IOptionsType,
} from "@/services/data/generic-provider";
import { baseAPIUrl } from "@/services/utils/utils";

import { ILocationSearchResults } from "./location-search.interface";

@Provider(`${baseAPIUrl}/location-search/`)
class LocationSearchProvider extends GenericProvider<ILocationSearchResults> {
  public async search(options: IOptionsType): Promise<ILocationSearchResults> {
    const url = this.buildEndPointUrl(options);
    const request = this.httpClient.get(url);
    const { data } = await this.wrapRequest(request);

    return data;
  }
}

export const locationSearchProvider = new LocationSearchProvider();
