<template>
  <div
    :class="{
      'submissions-table-row-placeholder__owner-submissions-view': !userView,
    }"
    class="submissions-table-row-placeholder"
  >
    <ContentPlaceholders :rounded="true">
      <ContentPlaceholdersText :lines="1" />
    </ContentPlaceholders>
    <ContentPlaceholders :rounded="true" class="is-circle">
      <ContentPlaceholdersText :lines="1" />
      <ContentPlaceholdersText :lines="1" />
    </ContentPlaceholders>
    <ContentPlaceholders :rounded="true">
      <ContentPlaceholdersText :lines="1" class="is-pill" />
    </ContentPlaceholders>
    <ContentPlaceholders :rounded="true" class="is-row">
      <ContentPlaceholdersText :lines="1" />
      <ContentPlaceholdersText :lines="1" />
    </ContentPlaceholders>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "SubmissionsTableRowPlaceholder",

  props: {
    userView: {
      type: Boolean,
      default: false,
    },
  },
});
</script>

<style lang="scss" scoped>
.submissions-table-row-placeholder {
  display: grid;
  grid-template-columns: 38% 16.7% repeat(2, 1fr);
  align-items: center;

  max-height: 64px;
  padding: 20px 0;

  :deep() .vue-content-placeholders {
    padding-left: 12px;
  }

  :deep() .vue-content-placeholders-text {
    margin: 0;
  }

  :deep() .vue-content-placeholders-text__line {
    margin: 0;
    max-width: 150px;
  }

  :deep() .vue-content-placeholders-text.is-pill {
    .vue-content-placeholders-text__line {
      border-radius: 17px;
      max-width: 160px;
    }
  }
  :deep()
    .vue-content-placeholders.is-row
    .vue-content-placeholders-text__line {
    max-width: 76px;
    width: 100%;
  }

  :deep()
    .vue-content-placeholders.is-circle
    .vue-content-placeholders-text__line {
    max-width: 80px;
  }

  :deep() .vue-content-placeholders.is-circle {
    display: grid;
    grid-template-columns: 24px auto;
    gap: 8px;
    align-items: center;

    .vue-content-placeholders-text:first-of-type
      .vue-content-placeholders-text__line {
      width: 24px;
      height: 24px;
      border-radius: 100%;
    }
  }

  :deep() .vue-content-placeholders.is-row {
    display: grid;
    grid-template-columns: auto auto;
    gap: 58px;

    .vue-content-placeholders-text:first-of-type
      .vue-content-placeholders-text__line {
      max-width: 82px;
      width: 100%;
    }
  }
}

.submissions-table-row-placeholder__owner-submissions-view {
  grid-template-columns: 18.3% 36.7% 1fr 22.5%;

  .vue-content-placeholders:first-of-type {
    order: 2;
  }

  .vue-content-placeholders:nth-of-type(2) {
    order: 3;
  }

  .vue-content-placeholders:nth-of-type(3) {
    order: 2;
  }

  .vue-content-placeholders:nth-of-type(4) {
    order: 4;
  }

  .vue-content-placeholders:nth-of-type(5) {
    order: 5;
  }
}
</style>
