<template>
  <div v-if="isLoading" class="page-full-height">
    <PxNavbar v-if="$screen.mdUp" />
    <PxTabBar v-if="$screen.smDown" />
    <div
      v-loading="isLoading"
      :class="{ 'is-loading': isLoading }"
      element-loading-background="#fafafa"
    />
  </div>
  <RouterView v-else />
</template>

<script lang="ts">
import { defineComponent } from "vue";

import { EAuthActions } from "@/modules/authentication/services/store/auth/auth-types";
import { featureManager } from "@/services/feature-manager";
import { userManager } from "@/modules/authentication/user-manager";

export default defineComponent({
  computed: {
    isAuthRoute(): boolean {
      return (
        "auth" in this.$router.currentRoute.value.meta &&
        !!this.$router.currentRoute.value.meta.auth
      );
    },
    isLoadingAuthRoute(): boolean {
      return this.$store.state.auth.loading && this.isAuthRoute;
    },
    isLoading(): boolean {
      return this.isLoadingAuthRoute;
    },
  },
  mounted() {
    // TODO: Check if dispatching the Check Session is required
    if (featureManager.isEnabled("authentication") && userManager.isLogged()) {
      this.$store.dispatch(EAuthActions.CHECK_SESSION);

      if (userManager.isEntrepreneur()) return;

      this.$store.dispatch(EAuthActions.FETCH_SUBSCRIPTION);
    }
  },
});
</script>

<style lang="scss" scoped>
.is-loading {
  height: calc(100vh - 54px);
  overflow: hidden;
}

:deep() .px-tab-bar__container .px-tab-bar-logo {
  width: inherit;
  padding-bottom: 10px;
}
</style>
