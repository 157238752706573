<template>
  <PxPanel
    :class="[
      `level-update-card--${type}`,
      `level-update-card--${typeOfUpdate}`,
    ]"
    class="level-update-card"
  >
    <p class="level-update-card__title">
      {{ title }}
    </p>
    <div class="level-update-card__content-wrapper">
      <div class="level-update-card__description-wrapper">
        <span class="level-update-card__description">
          {{ levelDescription }}
        </span>
      </div>
      <PxIcon
        :name="`level-graph/${level}`"
        :size="$screen.xsOnly ? 49 : 65"
        class="level-update-card__level"
      />
      <AssessmentChanges
        v-if="prevAssessment && currentAssessment"
        :current-assessment="currentAssessment"
        :only-prev-assessment="onlyPrevAssessment"
        :prev-assessment="prevAssessment"
      />
    </div>
  </PxPanel>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { TranslateResult } from "vue-i18n";
import AssessmentChanges from "@/modules/common/components/level-update-modal/assessment-changes.vue";
import { IAssessmentLevel } from "@/services/data/assessment/assessment.interface";

export default defineComponent({
  name: "LevelUpdateCard",

  components: { AssessmentChanges },

  props: {
    type: {
      type: String,
      default: "grey",
    },
    typeOfUpdate: {
      type: String,
      default: "same-level",
    },
    levelDescription: {
      type: String,
      default: "",
    },
    level: {
      type: Number,
      default: 1,
    },
    prevAssessment: {
      type: Array as () => IAssessmentLevel[] | null,
      required: true,
    },
    currentAssessment: {
      type: Array as () => IAssessmentLevel[],
      required: true,
    },
    onlyPrevAssessment: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    title(): TranslateResult {
      return this.type === "grey"
        ? this.$t("profile.levelCard.titleCurrent")
        : this.$t("profile.levelCard.titleNew");
    },
  },
});
</script>

<style lang="scss" scoped>
.px-panel.level-update-card {
  width: 240px;
  background-color: $alabaster;
  box-shadow: none;

  & :deep() .px-panel__body {
    padding: 12px 14px 14px;
  }

  .level-update-card__title {
    @include grotesk(bold);

    margin-bottom: 2px;
    font-size: 12px;
    color: $ebony-clay;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 0.3px;
    opacity: 0.5;
  }

  .level-update-card__content-wrapper {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }

  .level-update-card__description-wrapper {
    flex-grow: 1;
    padding-top: 8px;
    text-align: center;
  }

  .level-update-card__description {
    @include grotesk(bold);

    font-size: 14px;
    line-height: 20px;
    color: rgba($ebony-clay, 0.8);
  }

  .level-update-card__level {
    position: relative;
    left: 1px;
    margin-top: 10px;
    margin-bottom: 16px;
  }
}

:not(.level-update-card--blue).level-update-card--level-down
  :deep()
  .px-panel__body {
  padding-top: 19px;
  padding-bottom: 20px;
}

.px-panel.level-update-card.level-update-card--same-level
  :deep()
  .px-panel__body {
  padding: 20px 14px 21px;
}

.px-panel.level-update-card--blue.level-update-card--level-up
  :deep()
  .px-panel__body {
  padding-top: 20px;
  padding-bottom: 23px;
}

.px-panel.level-update-card--blue {
  background-color: #ebf4ff;

  border-color: rgba($manatee, 0.4);

  .level-update-card__title {
    opacity: 1;
  }

  .level-update-card__description {
    position: relative;
    right: 1px;
    color: $nice-blue;
  }

  .level-update-card__level {
    left: 4px;
  }
}
</style>
<style lang="scss">
.px-panel.level-update-card--blue .assessment-changes__line {
  top: -1px;
  right: -1px;
  gap: 2px;
}
</style>
