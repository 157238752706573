import {
  ESupporterTypeState,
  ISupporterTypeState,
} from "./supporter-type.interface";
import { ISupporterType } from "@/modules/supporters/services/data/supporter-type/supporter-type.interface";
import { supporterTypeProvider } from "@/modules/supporters/services/data/supporter-type/supporter-type.provider";
import { GenericState } from "@/services/store/generic/generic-state.module";
import {
  EGenericAction,
  EGenericGetter,
  IGenericAction,
  IGenericGetter,
} from "@/services/store/generic/generic-state.interface";

/**
 * Boilerplate module instantiation
 */
export class SupporterTypeState extends GenericState<
  ISupporterTypeState,
  ISupporterType
> {
  static Action = GenericState.mapNamespaceEnum(
    EGenericAction,
    ESupporterTypeState.NAMESPACE,
  ) as IGenericAction;

  static Getter = GenericState.mapNamespaceEnum(
    EGenericGetter,
    ESupporterTypeState.NAMESPACE,
  ) as IGenericGetter;
}

/**
 * Boilerplate module instantiation
 */
export const supporterTypeModule = new SupporterTypeState({
  provider: supporterTypeProvider,
});
