import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-1b7a9dd8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "supporters-view__container" }
const _hoisted_2 = { class: "supporter-registration-form__container" }
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = { class: "supporter-registration-form__cta" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ElInput = _resolveComponent("ElInput")!
  const _component_ElFormItem = _resolveComponent("ElFormItem")!
  const _component_PxIcon = _resolveComponent("PxIcon")!
  const _component_ElTooltip = _resolveComponent("ElTooltip")!
  const _component_ElCheckbox = _resolveComponent("ElCheckbox")!
  const _component_ElCheckboxGroup = _resolveComponent("ElCheckboxGroup")!
  const _component_PxInputPlaces = _resolveComponent("PxInputPlaces")!
  const _component_PxButton = _resolveComponent("PxButton")!
  const _component_ElForm = _resolveComponent("ElForm")!
  const _component_NetworksList = _resolveComponent("NetworksList")!
  const _component_ActionModal = _resolveComponent("ActionModal")!
  const _component_FormPresentationLayout = _resolveComponent("FormPresentationLayout")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_FormPresentationLayout, {
      "content-description": _ctx.viewCopy.contentDescription,
      "content-title": _ctx.viewCopy.contentTitle,
      "description-image-link": _ctx.viewCopy.imageLink,
      "description-image-title": _ctx.viewCopy.imageTitle,
      "description-text": _ctx.viewCopy.imageDescription,
      "has-description-background-image": true,
      "has-return-button": _ctx.hasPreviousPage,
      onPreviousPage: _ctx.onReturnButtonClick
    }, {
      content: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_ElForm, {
            ref: "supporterRegistrationForm",
            model: _ctx.fields,
            rules: _ctx.rules,
            class: "supporter-registration-form__body",
            onValidate: _ctx.updateSubmitState
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ElFormItem, {
                label: _ctx.$t('supporters.signup.form.fields.supporterName'),
                prop: "company.name"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ElInput, {
                    modelValue: _ctx.fields.company.name,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.fields.company.name) = $event)),
                    placeholder: _ctx.$t('supporters.signup.form.fields.supporterName')
                  }, null, 8, ["modelValue", "placeholder"])
                ]),
                _: 1
              }, 8, ["label"]),
              _createVNode(_component_ElFormItem, {
                label: _ctx.$t('supporters.signup.form.fields.supporterType'),
                prop: "supporter.types"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ElCheckboxGroup, {
                    modelValue: _ctx.selectedSupporterTypes,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.selectedSupporterTypes) = $event))
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.availableSupporterTypes, (type, key) => {
                        return (_openBlock(), _createBlock(_component_ElCheckbox, {
                          key: key,
                          value: type.id
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(type.name) + " ", 1),
                            (type.label)
                              ? (_openBlock(), _createBlock(_component_ElTooltip, {
                                  key: 0,
                                  placement: "top",
                                  "popper-class": "supporter-registration-form__type-tooltip el-abaca-tooltip"
                                }, {
                                  content: _withCtx(() => [
                                    _createElementVNode("div", {
                                      innerHTML: type.label
                                    }, null, 8, _hoisted_3)
                                  ]),
                                  default: _withCtx(() => [
                                    _createVNode(_component_PxIcon, {
                                      size: 16,
                                      class: "supporter-registration-form__type-question-icon",
                                      name: "icon-question"
                                    })
                                  ]),
                                  _: 2
                                }, 1024))
                              : _createCommentVNode("", true)
                          ]),
                          _: 2
                        }, 1032, ["value"]))
                      }), 128)),
                      _createVNode(_component_ElCheckbox, {
                        value: "",
                        onChange: _cache[1] || (_cache[1] = (value) => _ctx.otherTypeChangeHandler(value))
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t("supporters.signup.form.fields.otherSupporterType")), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 8, ["modelValue"]),
                  (_ctx.hasOtherSupporterType)
                    ? (_openBlock(), _createBlock(_component_ElInput, {
                        key: 0,
                        modelValue: _ctx.otherType,
                        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.otherType) = $event)),
                        placeholder: 
                  _ctx.$t('supporters.signup.form.placeholders.supporterType')
                ,
                        class: "supporter-registration-form__other-type-input"
                      }, null, 8, ["modelValue", "placeholder"]))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }, 8, ["label"]),
              _createVNode(_component_ElFormItem, {
                label: _ctx.$t('supporters.signup.form.fields.location'),
                prop: "company.location"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_PxInputPlaces, {
                    id: "supporter-registration-form__location-input",
                    modelValue: _ctx.fields.company.location,
                    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.fields.company.location) = $event)),
                    placeholder: _ctx.$t('supporters.signup.form.fields.location'),
                    "use-geolocation": true
                  }, null, 8, ["modelValue", "placeholder"])
                ]),
                _: 1
              }, 8, ["label"]),
              _createElementVNode("div", _hoisted_4, [
                _createVNode(_component_PxButton, {
                  disabled: _ctx.isSubmissionDisabled,
                  loading: _ctx.formLoading,
                  type: "primary",
                  onClick: _ctx.onNextButtonClick
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.ctaCopy.next), 1)
                  ]),
                  _: 1
                }, 8, ["disabled", "loading", "onClick"])
              ])
            ]),
            _: 1
          }, 8, ["model", "rules", "onValidate"]),
          (_ctx.showNetworkModal)
            ? (_openBlock(), _createBlock(_component_ActionModal, {
                key: 0,
                ref: "networkModal",
                title: _ctx.networkModalTitle,
                visibility: _ctx.showNetworkModal,
                class: "networks-modal__wrapper"
              }, {
                content: _withCtx(() => [
                  _createVNode(_component_NetworksList, {
                    items: _ctx.affiliateNetwork,
                    class: "networks-panel__list"
                  }, null, 8, ["items"]),
                  _createElementVNode("p", null, _toDisplayString(_ctx.$t("supporters.component.networkModal.description")), 1)
                ]),
                actions: _withCtx(() => [
                  _createVNode(_component_PxButton, {
                    class: "network-modal__cta",
                    onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.submitNetworkModal(false)))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t("supporters.component.networkModal.secondaryCta")), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_PxButton, {
                    class: "network-modal__cta",
                    type: "green",
                    onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.submitNetworkModal(true)))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t("supporters.component.networkModal.mainCta")), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["title", "visibility"]))
            : _createCommentVNode("", true)
        ])
      ]),
      _: 1
    }, 8, ["content-description", "content-title", "description-image-link", "description-image-title", "description-text", "has-return-button", "onPreviousPage"])
  ]))
}