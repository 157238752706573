import { ActionTree } from "vuex";
import uniq from "lodash/uniq";
import omitBy from "lodash/omitBy";
import isNull from "lodash/isNull";
import {
  IGenericSupporterState,
  EGenericSupporterMutations,
  EGenericSupporterActions,
} from "./supporter.types";

import router from "@/services/router";
import { HOME } from "@/services/router/router-names";
import { ROUTE_PROFILE } from "@/modules/profile/services/router/routes-names";

import { RootState } from "@/services/store/root-state";
import { getBaseName } from "@/services/store/utils/get-base-name";
import { userManager } from "@/modules/authentication/user-manager";
import { NotFoundProviderException } from "@/services/data/exceptions/not-found-provider.exception";
import { supporterProvider } from "@/modules/matching/services/data/matching-score/supporter.provider";
import { ISupporter } from "@/modules/matching/services/data/matching-score/supporter.interface";
import { supporterAffiliateProvider } from "@/services/data/affiliate/supporter-affiliate.provider";

const parseInvestingLevelRange = {
  request(range: Array<number | null>) {
    const levelRange = uniq(range);
    // Add null value to support ranges with a single value
    if (levelRange.length === 1) {
      levelRange.push(null);
    }
    return levelRange;
  },
  response(range: Array<number | null>) {
    const levelRange = range
      .filter((val: number | null) => !!val)
      .map((val: number | null) => Number(val));

    // Ensure level range has two values
    if (levelRange.length === 1) {
      levelRange.push(levelRange[0]);
    }
    return levelRange;
  },
};

export const supporterActions: ActionTree<IGenericSupporterState, RootState> = {
  /**
   * Fetch a supporter.
   *
   * @param param0
   * @param queryParams
   */
  async [getBaseName(EGenericSupporterActions.FETCH)](
    { commit },
    queryParams = {},
  ) {
    commit(EGenericSupporterMutations.SET_ERROR, null);
    commit(EGenericSupporterMutations.SET_LOADING, true);

    try {
      const data = await supporterProvider.get(null, queryParams);
      data.investing_level_range = parseInvestingLevelRange.response(
        data.investing_level_range,
      );

      if (userManager.isOwner()) {
        const affiliateList = await supporterAffiliateProvider.list();
        const affiliate =
          !!affiliateList && affiliateList.length > 0 ? affiliateList[0] : null;
        commit(EGenericSupporterMutations.SET_DATA, { ...data, affiliate });
      } else {
        commit(EGenericSupporterMutations.SET_DATA, { ...data });
      }

      commit(EGenericSupporterMutations.SET_LOADING, false);
    } catch (error) {
      commit(EGenericSupporterMutations.SET_ERROR, error);
      commit(EGenericSupporterMutations.SET_LOADING, false);

      // Redirect the user if company profile does not exist
      if (error instanceof NotFoundProviderException) {
        const fallbackRoute = userManager.isLogged() ? ROUTE_PROFILE : HOME;
        if (router.currentRoute.value.name !== fallbackRoute) {
          router.replace({ name: fallbackRoute });
        }
      }
    }
  },

  /**
   * Apply a partial update to the supporter information.
   *
   * @param param0 vuex methods
   * @param partialData Updated data
   */
  async [getBaseName(EGenericSupporterActions.PATCH)](
    { commit, state },
    partialData: ISupporter,
  ) {
    if (!state.data) {
      throw new Error("There is not supporter set");
    }

    commit(EGenericSupporterMutations.SET_LOADING, true);

    const filteredData = omitBy(partialData, isNull) as ISupporter;
    if (
      Object.prototype.hasOwnProperty.call(
        filteredData,
        "investing_level_range",
      )
    ) {
      // Set data as any to allow null values that maybe exceptionally needed for the request
      (filteredData as any).investing_level_range =
        parseInvestingLevelRange.request(filteredData.investing_level_range);
    }

    try {
      await supporterProvider.patch(state.data.id, filteredData);
      commit(EGenericSupporterMutations.SET_LOADING, false);
    } catch (error) {
      commit(EGenericSupporterMutations.SET_ERROR, error);
      commit(EGenericSupporterMutations.SET_LOADING, false);
      throw error;
    }
  },

  /**
   * Create a new affiliate with a given slug
   * for an authenticated user as supporter.
   *
   * @param param0
   * @param slug
   */
  async [getBaseName(EGenericSupporterActions.CREATE_AFFILIATE_LINK)](
    { commit, state },
    { slug },
  ) {
    commit(EGenericSupporterMutations.SET_ERROR, null);
    commit(EGenericSupporterMutations.SET_LOADING, true);
    let affiliate = null;

    try {
      const currentState = state as IGenericSupporterState;
      affiliate = await supporterAffiliateProvider.create({ slug });

      commit(EGenericSupporterMutations.SET_DATA, {
        ...currentState.data,
        affiliate,
      });
      commit(EGenericSupporterMutations.SET_LOADING, false);
    } catch (error) {
      commit(EGenericSupporterMutations.SET_ERROR, error);
      commit(EGenericSupporterMutations.SET_LOADING, false);
    }

    return affiliate;
  },

  /**
   * Reset company value stored.
   *
   * @param param0
   */
  async [getBaseName(EGenericSupporterActions.RESET)]({ commit }) {
    commit(EGenericSupporterMutations.SET_DATA, null);
  },
};
