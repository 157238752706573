import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-61b7e980"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "milestone-form-guest-view" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = {
  key: 0,
  class: "milestone-form-guest-view__plan"
}
const _hoisted_4 = { class: "milestone-form-guest-view__plan-description" }
const _hoisted_5 = { class: "milestone-form-guest-view__label" }
const _hoisted_6 = { class: "milestone-form-guest-view__content" }
const _hoisted_7 = {
  key: 1,
  class: "milestone-form-guest-view__complete"
}
const _hoisted_8 = {
  key: 0,
  class: "milestone-form-guest-view__item"
}
const _hoisted_9 = { class: "milestone-form-guest-view__label" }
const _hoisted_10 = { class: "milestone-form-guest-view__content" }
const _hoisted_11 = { class: "milestone-form-guest-view__item" }
const _hoisted_12 = { class: "milestone-form-guest-view__label" }
const _hoisted_13 = { class: "milestone-form-guest-view__content" }
const _hoisted_14 = {
  key: 1,
  class: "milestone-form-guest-view__empty"
}
const _hoisted_15 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.showPlannerInfo)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_ctx.isPlanTabActive)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t("milestonePlanner.milestonePlan.planForm.description.guest")), 1),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.planForm, (field, fieldLabel) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: fieldLabel,
                    class: "milestone-form-guest-view__item"
                  }, [
                    _createElementVNode("h3", _hoisted_5, _toDisplayString(_ctx.$t(`milestonePlanner.milestonePlan.planForm.fields.${fieldLabel}`)), 1),
                    _createElementVNode("p", _hoisted_6, _toDisplayString(field), 1)
                  ]))
                }), 128))
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_7, [
                (_ctx.completeForm.evidence)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                      _createElementVNode("h3", _hoisted_9, _toDisplayString(_ctx.$t(`milestonePlanner.milestonePlan.completeForm.fields.evidence`)), 1),
                      _createElementVNode("ul", null, [
                        _createElementVNode("li", null, [
                          _createElementVNode("span", null, _toDisplayString(_ctx.formEvidenceQuestion), 1)
                        ])
                      ]),
                      _createElementVNode("p", _hoisted_10, _toDisplayString(_ctx.completeForm.evidence), 1)
                    ]))
                  : _createCommentVNode("", true),
                _createElementVNode("div", _hoisted_11, [
                  _createElementVNode("h3", _hoisted_12, _toDisplayString(_ctx.$t(
                `milestonePlanner.milestonePlan.completeForm.fields.dateOfCompletion`,
              )), 1),
                  _createElementVNode("p", _hoisted_13, _toDisplayString(_ctx.completeForm.dateOfCompletion), 1)
                ])
              ]))
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_14, [
          _createElementVNode("img", {
            src: _ctx.emptyState.image,
            alt: "milestone detail empty state"
          }, null, 8, _hoisted_15),
          _createElementVNode("h3", null, _toDisplayString(_ctx.emptyState.title), 1),
          _createElementVNode("p", null, _toDisplayString(_ctx.emptyState.description), 1)
        ]))
  ]))
}