<template>
  <div class="submission-tab-questions">
    <h2 class="submission-tab__print-title">
      {{ $t("program.submission.tabs.questions") }}
    </h2>
    <div
      v-for="(response, index) in criteria"
      :key="`response-${index}`"
      class="submissions-responses__item"
    >
      <div class="submissions-responses__question">
        {{ getQuestion(response) }}
      </div>
      <div class="submissions-responses__answer">
        {{ getAnswer(response) }}
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import { IMatchingCriteria } from "@/modules/matching/services/data/matching-score/matching-criteria.interface";
import { IMatchingQuestion } from "@/services/data/matching-questionary/matching-question.interface";
import GetQuestionAnswerMixin from "@/mixins/get-question-answer.mixin";

export default defineComponent({
  mixins: [GetQuestionAnswerMixin],

  props: {
    criteria: {
      type: Array as () => Array<IMatchingCriteria>,
      required: true,
    },
  },

  methods: {
    getQuestion(criteria: IMatchingCriteria): string {
      return (criteria.question as IMatchingQuestion).resource_question;
    },
  },
});
</script>

<style lang="scss" scoped>
.submission-tab-questions.submission-tab__item {
  padding: 24px 15px 40px;

  @include breakpoint-up(md) {
    padding: 24px 70px 56px;
  }
}

.submissions-responses__item {
  padding: 19px 0 21px;
  word-break: break-word;
  word-wrap: break-word;
  border-bottom: 1px solid $athens-gray;
}

.submissions-responses__item:last-child {
  border-bottom: none;
}

.submissions-responses__question {
  @include grotesk(semiBold);

  font-size: 17px;
  line-height: 1.76;
  color: $ebony-clay;
}

.submissions-responses__answer {
  margin-top: 5px;
  font-size: 15px;
  line-height: 1.67;
  color: $ebony-clay;
}

.submission-tab__print-title {
  display: none;
}
</style>
