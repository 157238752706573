export enum EMatchingListTabs {
  DISCOVER = "discover",
  PENDING = "pending",
  CONNECTED = "connected",
}

export enum EMatchingListPendingTabs {
  INTERESTED_IN_ME = "interestedInMe",
  IM_INTERESTED_IN = "imInterestedIn",
}
