import { Module } from "vuex";
import { make } from "vuex-pathify";

import { RootState } from "@/services/store/root-state";

import { actions } from "./assessment-history.actions";
import { IAssessmentHistoryState } from "./assessment-history.types";

const state: IAssessmentHistoryState = {
  loading: false,
  error: null,
  data: [],
};

export const profileAssessmentHistory: Module<
  IAssessmentHistoryState,
  RootState
> = {
  namespaced: true,
  state,
  actions,
  mutations: make.mutations(state),
  getters: make.getters(state),
};
