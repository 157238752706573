import { ActionTree } from "vuex";
import {
  IMetaState,
  EMetaActions,
  IMetaPayload,
  EMetaMutations,
} from "./meta-types";
import { RootState } from "../root-state";
import { getBaseName } from "../utils/get-base-name";

/**
 * Actions for the metadata module.
 */
export const actions: ActionTree<IMetaState, RootState> = {
  /**
   * Add a new metadata entry using a key/value.
   */
  [getBaseName(EMetaActions.SET)]({ commit }, payload: IMetaPayload) {
    commit(EMetaMutations.SET, payload);
  },

  /**
   * Remove a metadata entry by key.
   */
  [getBaseName(EMetaActions.REMOVE)]({ commit }, keyToRemove: string) {
    commit(EMetaMutations.REMOVE, keyToRemove);
  },
};
