import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementVNode as _createElementVNode, vShow as _vShow, normalizeClass as _normalizeClass, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-65fee86a"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "el-form-item__label networks-selector__title"
}
const _hoisted_2 = {
  key: 0,
  class: "networks-selector__entry-picture"
}
const _hoisted_3 = ["alt", "src"]
const _hoisted_4 = { class: "networks-selector__entry-content" }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { key: 1 }
const _hoisted_7 = ["onClick"]
const _hoisted_8 = {
  key: 1,
  class: "networks-selector__gradient"
}
const _hoisted_9 = { key: 0 }
const _hoisted_10 = { key: 1 }
const _hoisted_11 = { class: "networks-selector__select-options__list-item" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PxIcon = _resolveComponent("PxIcon")!
  const _component_ElOption = _resolveComponent("ElOption")!
  const _component_ElSelect = _resolveComponent("ElSelect")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([{ 'networks-selector--modal': _ctx.isModal }, "networks-selector"])
  }, [
    _withDirectives(_createElementVNode("div", {
      class: _normalizeClass([{
        'networks-selector__list--with-entries': _ctx.selectedNetworks.length,
      }, "networks-selector__list"])
    }, [
      (!_ctx.noLabel)
        ? (_openBlock(), _createElementBlock("label", _hoisted_1, _toDisplayString(_ctx.labelCopy), 1))
        : _createCommentVNode("", true),
      _createElementVNode("div", {
        class: "networks-selector__wrapper",
        onScroll: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.checkNetworksScroll && _ctx.checkNetworksScroll(...args)))
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.selectedNetworks, (network) => {
          return (_openBlock(), _createElementBlock("div", {
            key: network.id,
            class: "networks-selector__entry"
          }, [
            (!_ctx.isModal)
              ? (_openBlock(), _createElementBlock("picture", _hoisted_2, [
                  _createElementVNode("img", {
                    alt: network.name,
                    src: network.logo || ''
                  }, null, 8, _hoisted_3)
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("span", null, _toDisplayString(network.name), 1),
              (!_ctx.noLocation)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                    (network.locations && network.locations.length)
                      ? (_openBlock(), _createElementBlock("ul", _hoisted_5, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(network.locations, (location, index) => {
                            return (_openBlock(), _createElementBlock("li", { key: index }, _toDisplayString(location.formatted_address), 1))
                          }), 128))
                        ]))
                      : (_openBlock(), _createElementBlock("ul", _hoisted_6, [
                          _createElementVNode("li", null, _toDisplayString(_ctx.$t("common.everywhere")), 1)
                        ]))
                  ], 64))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("i", {
              class: "icon icon-trash--dark",
              onClick: ($event: any) => (_ctx.onClickRemoveNetwork(network.id))
            }, null, 8, _hoisted_7)
          ]))
        }), 128))
      ], 32),
      (_ctx.isGradientVisible)
        ? (_openBlock(), _createElementBlock("div", _hoisted_8))
        : _createCommentVNode("", true)
    ], 2), [
      [_vShow, _ctx.isSelectedVisible]
    ]),
    _createVNode(_component_ElSelect, {
      ref: "select",
      loading: _ctx.networkSelectLoading,
      placeholder: _ctx.placeholderCopy,
      "popper-class": _ctx.selectPopperClass,
      "remote-method": _ctx.searchNetworks,
      class: "networks-selector__select",
      filterable: "",
      remote: "",
      onBlur: _ctx.blurHandler,
      onChange: _ctx.onNetworkSelect
    }, {
      prefix: _withCtx(() => [
        _createVNode(_component_PxIcon, {
          size: 24,
          name: "magnifier"
        })
      ]),
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.listOfNetworks, (item) => {
          return (_openBlock(), _createBlock(_component_ElOption, {
            key: item.id,
            class: _normalizeClass([{ 'networks-selector--modal__select-options': _ctx.isModal }, "networks-selector__select-options"]),
            label: item.name,
            value: item
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", null, [
                _createElementVNode("span", null, _toDisplayString(item.name), 1),
                (item.locations && item.locations.length)
                  ? (_openBlock(), _createElementBlock("ul", _hoisted_9, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.locations, (location, index) => {
                        return (_openBlock(), _createElementBlock("li", {
                          key: index,
                          class: "networks-selector__select-options__list-item"
                        }, _toDisplayString(location.formatted_address), 1))
                      }), 128))
                    ]))
                  : (_openBlock(), _createElementBlock("ul", _hoisted_10, [
                      _createElementVNode("li", _hoisted_11, _toDisplayString(_ctx.$t("common.everywhere")), 1)
                    ]))
              ])
            ]),
            _: 2
          }, 1032, ["class", "label", "value"]))
        }), 128))
      ]),
      _: 1
    }, 8, ["loading", "placeholder", "popper-class", "remote-method", "onBlur", "onChange"])
  ], 2))
}