import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AssessmentDesktop = _resolveComponent("AssessmentDesktop")!
  const _component_AssessmentMobile = _resolveComponent("AssessmentMobile")!
  const _component_RenderlessEntrepreneurAssessment = _resolveComponent("RenderlessEntrepreneurAssessment")!

  return (_openBlock(), _createBlock(_component_RenderlessEntrepreneurAssessment, {
    "end-action": _ctx.endAction || _ctx.defaultEndAction,
    "needs-submission-confirmation": _ctx.needsSubmissionConfirmation
  }, {
    default: _withCtx((scope) => [
      _createElementVNode("div", null, [
        (_ctx.$screen.mdUp)
          ? (_openBlock(), _createBlock(_component_AssessmentDesktop, {
              key: 0,
              "current-category": scope.currentCategory,
              "is-first-interaction": scope.isFirstInteraction,
              levels: scope.levels,
              loading: _ctx.isLoading,
              "on-go-next": scope.goNextCategoryHandler,
              "on-go-prev": scope.goPrevCategoryHandler,
              "on-level-change": scope.onLevelChange,
              "selected-category": scope.selectedCategory,
              "selected-step": scope.selectedStep,
              "show-back-button": _ctx.needsSubmissionConfirmation
            }, null, 8, ["current-category", "is-first-interaction", "levels", "loading", "on-go-next", "on-go-prev", "on-level-change", "selected-category", "selected-step", "show-back-button"]))
          : _createCommentVNode("", true),
        (_ctx.$screen.smDown)
          ? (_openBlock(), _createBlock(_component_AssessmentMobile, {
              key: 1,
              "current-category": scope.currentCategory,
              levels: scope.levels,
              "on-go-next": scope.goNextCategoryHandler,
              "on-go-prev": scope.goPrevCategoryHandler,
              "on-level-change": scope.onLevelChange,
              "selected-category": scope.selectedCategory,
              "selected-step": scope.selectedStep
            }, null, 8, ["current-category", "levels", "on-go-next", "on-go-prev", "on-level-change", "selected-category", "selected-step"]))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }, 8, ["end-action", "needs-submission-confirmation"]))
}