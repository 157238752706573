<template>
  <div class="mobile-footer-nav">
    <div class="mobile-footer-nav__top-wrapper safe-horizontal-container">
      <div class="mobile-footer-nav__top">
        <template v-if="loading">
          <ContentPlaceholders class="mobile-footer-nav-placeholder" rounded>
            <div class="placeholder-block" />
            <div class="placeholder-block placeholder-block--important" />
          </ContentPlaceholders>
        </template>
        <template v-else>
          <PxButton
            v-if="selectedCategory > 0"
            class="mobile-footer-nav__back-btn"
            type="link"
            @click="$emit('prev')"
          >
            {{ $t("selfAssessment.assessment.prevButton") }}
          </PxButton>
          <PxButton
            :class="{ 'is-last': isLastStep }"
            :type="nextButtonType"
            class="mobile-footer-nav__next-btn"
            @click="$emit('next')"
          >
            {{ nextButtonText }}
            <i v-if="showNextButtonIcon" :class="nextButtonClass" />
          </PxButton>
        </template>
      </div>
    </div>
    <div class="mobile-footer-nav__bottom">
      <span>
        {{
          $t("common.footer.copyright", {
            year: currentYear,
          })
        }}
      </span>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import {
  IViralLevelState,
  EViralLevelGetters,
} from "@/services/store/viral-level/viral-level-types";
import { TranslateResult } from "vue-i18n";
import { ICategoryLevelSelection } from "@/services/data/viral-level/viral-level.interface";

export default defineComponent({
  name: "MobileFooterNav",

  props: {
    loading: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      currentYear: new Date().getFullYear(),
    };
  },

  computed: {
    viralLevelState(): IViralLevelState {
      return this.$store.state.viralLevel;
    },

    /**
     * Index of the current selected category.
     */
    selectedCategory(): number {
      return this.viralLevelState.selectedCategory;
    },

    /**
     * Get the data for the selected step.
     */
    selectedStepData(): ICategoryLevelSelection | null {
      return this.$store.getters[EViralLevelGetters.SELECTED_STEP_DATA];
    },

    levels(): Array<ICategoryLevelSelection> {
      return this.viralLevelState.levels;
    },

    isLastStep(): boolean {
      return this.selectedCategory === this.levels.length - 1;
    },

    /**
     * Get the text for the next button.
     */
    nextButtonText(): TranslateResult {
      if (!this.selectedStepData) {
        return this.$t("selfAssessment.assessment.nextButton.skip");
      }

      if (this.isLastStep) {
        return this.selectedStepData.level === 0
          ? this.$t("selfAssessment.assessment.nextButton.skipFinal")
          : this.$t("selfAssessment.assessment.nextButton.nextFinalMobile");
      }

      return this.selectedStepData.level === 0
        ? this.$t("selfAssessment.assessment.nextButton.skip")
        : this.$t("selfAssessment.assessment.nextButton.next");
    },

    /**
     * Returns what type of button must be used for the next button.
     */
    nextButtonType(): string {
      return !this.selectedStepData || this.selectedStepData.level === 0
        ? "default"
        : "green";
    },

    nextButtonClass(): string {
      const iconClass =
        !this.selectedStepData || this.selectedStepData.level === 0
          ? "icon-arrow--right-grey"
          : "icon-arrow--right-white";
      return `${iconClass} icon--right`;
    },

    showNextButtonIcon(): boolean {
      return !this.isLastStep;
    },
  },
});
</script>

<style lang="scss" scoped>
.mobile-footer-nav {
  position: fixed;
  bottom: 0;
  z-index: z("navigation");
  width: 100vw;

  background-color: white;
  box-shadow: 0 -3px 15px 0 rgba(0, 0, 0, 0.08);

  &::before {
    display: block;
    width: 100%;
    height: 1px;

    content: "";

    background-color: $mischka;
  }

  &__top {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    padding: 12px 14px;
  }

  &__bottom {
    padding: 3px 15px;

    background-color: $alabaster;
  }

  &__bottom > span {
    @include grotesk(light);

    display: block;
    width: 100%;
    padding-bottom: var(--safe-area-inset-bottom);

    font-size: 0.6667rem;
    text-align: center;
  }

  &__back-btn {
    margin-right: 19px;
  }

  &__next-btn {
    height: 38px;

    @include breakpoint-up(md) {
      padding-top: 12px;
      padding-bottom: 11px;
    }
  }

  &__next-btn.el-button--green {
    min-width: 152px;
    padding: 0 11px 0 10px;
  }

  &__next-btn.el-button--green.is-last {
    max-width: 153px;
  }
}

.mobile-footer-nav-placeholder {
  display: flex;
  flex-direction: row;
  align-items: center;

  .placeholder-block {
    height: 38px;

    &:first-child {
      width: 126px;
      height: 18px;
    }

    &:last-child {
      width: 152px;
      margin-left: 13px;
    }
  }
}
</style>
