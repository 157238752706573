import { generateBaseMutationsFor } from "@/services/store/utils/base-generators";
import { EViralLevelMutations, IViralLevelState } from "./viral-level-types";
import { IViralLevelSelection } from "@/services/data/viral-level/viral-level.interface";
import { getBaseName } from "@/services/store/utils/get-base-name";
import { MutationTree } from "vuex";
import { IMetadataEntry } from "./types/metadata.interface";

export const mutations: MutationTree<IViralLevelState> = {
  ...generateBaseMutationsFor<IViralLevelState, IViralLevelSelection>("levels"),
  /**
   * Update the array that stores all level details.
   */
  [getBaseName(EViralLevelMutations.SET_LEVELS)](state, levels) {
    state.levels = levels;
  },
  /**
   * Update the levels state.
   */
  [getBaseName(EViralLevelMutations.SET_LEVEL)](state, newValue) {
    state.levels = newValue;
  },
  /**
   * Updated the current selected level.
   */
  [getBaseName(EViralLevelMutations.SET_SELECTED_CATEGORY)](state, newLevel) {
    state.selectedCategory = newLevel;
  },
  /**
   * Set the interaction variable.
   */
  [getBaseName(EViralLevelMutations.SET_INTERACTION)](state, newValue) {
    state.firstInteraction = newValue;
  },
  /**
   * Changes the value of the final level property.
   */
  [getBaseName(EViralLevelMutations.SET_FINAL_LEVEL)](state, level) {
    state.finalLevel = level;
  },
  /**
   * Changes the affiliate fields.
   */
  [getBaseName(EViralLevelMutations.SET_AFFILIATE)](state, affiliate) {
    state.affiliate = affiliate;
  },

  /**
   * Sets the affiliate question bundle
   */
  [getBaseName(EViralLevelMutations.SET_AFFILIATE_PROGRAM_QUESTION_BUNDLE)](
    state,
    affiliateQuestionBundle,
  ) {
    state.affiliateQuestionBundle = affiliateQuestionBundle;
  },

  /**
   * Sets the team members question bundle
   */
  [getBaseName(EViralLevelMutations.SET_TEAM_MEMBERS_QUESTION_BUNDLE)](
    state,
    teamMembersQuestionBundle,
  ) {
    state.teamMembersQuestionBundle = teamMembersQuestionBundle;
  },

  /**
   * Allows to set the `submitted` field.
   *
   * @param state Store state
   * @param newVal New value
   */
  [getBaseName(EViralLevelMutations.SET_SUBMITTED)](state, newVal) {
    state.submitted = newVal;
  },
  /**
   * Set metadata based on given key and value.
   *
   * @param state Viral level state.
   * @param entry Payload with the new metadata.
   */
  [getBaseName(EViralLevelMutations.SET_METADATA)](
    state,
    entry: IMetadataEntry,
  ) {
    state.metadata = { ...state.metadata, [entry.key]: entry.value };
  },
  /**
   * Remove the given key from the set of metadata.
   *
   * @param state Viral level state.
   * @param key Key to be removed.
   */
  [getBaseName(EViralLevelMutations.REMOVE_METADATA)](state, key: string) {
    delete state.metadata[key];
    state.metadata = { ...state.metadata };
  },
  /**
   * Set the pending user data.
   */
  [getBaseName(EViralLevelMutations.SET_PENDING_USER)](state, pendingUser) {
    state.pendingUser = pendingUser;
  },
  /**
   * Set the pending user's assessment data.
   */
  [getBaseName(EViralLevelMutations.SET_PENDING_ASSESSMENT)](
    state,
    pendingAssessment,
  ) {
    if (state.pendingUser) {
      state.pendingUser.assessment = pendingAssessment;
    }
  },
};
