<template>
  <div class="px-empty-message">
    <PxIcon v-if="icon" :name="icon" :size="iconSize" />
    <h4 v-if="title" :style="titleStyles">
      {{ title }}
    </h4>
    <div class="px-empty-message__wrapper">
      <p v-if="subtitle" :style="subtitleStyles" v-html="subtitle" />
      <slot name="action" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "PxEmptyMessage",

  props: {
    icon: {
      type: String,
      default: null,
    },

    iconSize: {
      type: [Number, String],
      default: 36,
    },

    title: {
      type: String,
      default: null,
    },

    subtitle: {
      type: String,
      default: null,
    },

    titleSize: {
      type: Number,
      default: 15,
    },

    subtitleSize: {
      type: Number,
      default: 14,
    },

    subtitleStyle: {
      type: String,
      default: "italic",
    },
  },

  computed: {
    titleStyles(): { [key: string]: string | number } {
      return {
        fontSize: `${this.titleSize}px`,
      };
    },

    subtitleStyles(): { [key: string]: string | number } {
      return {
        fontSize: `${this.subtitleSize}px`,
        fontStyle: this.subtitleStyle,
      };
    },
  },
});
</script>

<style lang="scss" scoped>
.px-empty-message {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  text-align: center;

  &__wrapper {
    display: flex;
  }

  h4 {
    padding: 8px 20px 0;
    line-height: 1.47;
  }

  p {
    padding: 3px 0;
    margin: 0;

    color: $manatee;
    line-height: 1.2;
    letter-spacing: -0.1px;
  }
}
</style>
