export const ROUTE_PROFILE = "profile";
export const ROUTE_USER_PROFILE = "userProfile";
export const ROUTE_USER_ACCOUNT = "userAccount";
export const ROUTE_UPDATE_LEVEL = "profile.updateLevel";
export const ROUTE_UPDATE_RANGE_LEVEL = "profile.updateRangeLevel";
export const ROUTE_GUEST_VIEW = "profile.guestView";
export const ROUTE_TEAM_MANAGEMENT = "teamManagement";
export const ROUTE_USER_SUBMISSIONS = "userSubmissions";
export const ROUTE_OWNED_AFFILIATES_SUBMISSIONS = "ownedAffiliatesSubmissions";
export const ROUTE_ACCOUNT_SETTINGS = "accountSettings";
