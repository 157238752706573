import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["innerHTML"]
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "capital-explorer-onboarding-modal__cta" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ElCheckbox = _resolveComponent("ElCheckbox")!
  const _component_ElFormItem = _resolveComponent("ElFormItem")!
  const _component_ElForm = _resolveComponent("ElForm")!
  const _component_PxButton = _resolveComponent("PxButton")!
  const _component_ElDialog = _resolveComponent("ElDialog")!

  return (_openBlock(), _createBlock(_component_ElDialog, {
    ref: "onboarding-modal",
    modelValue: _ctx.innerVisibility,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.innerVisibility) = $event)),
    "append-to-body": true,
    "close-on-click-modal": false,
    "close-on-press-escape": false,
    "show-close": false,
    class: "capital-explorer-onboarding-modal",
    top: "9vh",
    width: "740px"
  }, {
    header: _withCtx(() => [
      _createElementVNode("h3", null, _toDisplayString(_ctx.copy.title), 1),
      _createElementVNode("p", null, _toDisplayString(_ctx.copy.subtitle), 1)
    ]),
    default: _withCtx(() => [
      _createElementVNode("p", {
        class: "capital-explorer-onboarding-modal__description",
        innerHTML: _ctx.copy.description
      }, null, 8, _hoisted_1),
      _createElementVNode("img", {
        src: _ctx.copy.image,
        alt: "onboarding-modal-image",
        class: "capital-explorer-onboarding-modal__image"
      }, null, 8, _hoisted_2),
      _createVNode(_component_ElForm, {
        ref: "form",
        model: _ctx.form,
        rules: _ctx.rules,
        onValidate: _ctx.updateContinueState
      }, {
        default: _withCtx(() => [
          _createVNode(_component_ElFormItem, {
            "show-message": false,
            prop: "terms",
            required: ""
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ElCheckbox, {
                modelValue: _ctx.form.terms,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.form.terms) = $event)),
                class: "capital-explorer-onboarding-modal__terms"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.copy.terms), 1)
                ]),
                _: 1
              }, 8, ["modelValue"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["model", "rules", "onValidate"]),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_PxButton, _mergeProps(_ctx.buttonProps, {
          disabled: !_ctx.continueToFeature,
          onClick: _ctx.submit
        }), null, 16, ["disabled", "onClick"])
      ])
    ]),
    _: 1
  }, 8, ["modelValue"]))
}