import {
  EAffiliatesMutations,
  IAffiliatesState,
} from "@/modules/affiliates/services/store/affiliates/affiliates.types";
import { IViralLevelSelection } from "@/services/data/viral-level/viral-level.interface";
import { generateBaseMutationsFor } from "@/services/store/utils/base-generators";
import { getBaseName } from "@/services/store/utils/get-base-name";
import { MutationTree } from "vuex";

export const mutations: MutationTree<IAffiliatesState> = {
  ...generateBaseMutationsFor<IAffiliatesState, IViralLevelSelection>("levels"),

  [getBaseName(EAffiliatesMutations.SET_SAVING)](state, newValue) {
    state.saving = newValue;
  },

  [getBaseName(EAffiliatesMutations.SET_SELECTED_ASSESSMENT_CATEGORY)](
    state,
    newCategory,
  ) {
    state.selectedAssessmentCategory = newCategory;
  },

  [getBaseName(EAffiliatesMutations.SET_SELECTED_QUESTION)](
    state,
    newQuestion,
  ) {
    state.selectedQuestion = newQuestion;
  },

  [getBaseName(EAffiliatesMutations.SET_CURRENT_STEP)](state, newValue) {
    state.currentStep = newValue;
  },

  [getBaseName(EAffiliatesMutations.SET_INTERACTION)](state, newValue) {
    state.firstInteraction = newValue;
  },

  [getBaseName(EAffiliatesMutations.SET_AFFILIATE)](state, affiliate) {
    state.affiliate = affiliate;
  },

  [getBaseName(EAffiliatesMutations.SET_DRAFT_SUBMISSION)](state, submission) {
    state.draftSubmission = submission;
  },

  [getBaseName(EAffiliatesMutations.SET_AFFILIATE_PROGRAM_QUESTION_BUNDLE)](
    state,
    affiliateQuestionBundle,
  ) {
    state.affiliateQuestionBundle = affiliateQuestionBundle;
  },

  [getBaseName(EAffiliatesMutations.SET_TEAM_MEMBERS_QUESTION_BUNDLE)](
    state,
    teamMembersQuestionBundle,
  ) {
    state.teamMembersQuestionBundle = teamMembersQuestionBundle;
  },

  [getBaseName(EAffiliatesMutations.SET_PENDING_USER)](state, pendingUser) {
    state.pendingUser = pendingUser;
  },

  [getBaseName(EAffiliatesMutations.SET_SUBMISSION_ASSESSMENT_LEVELS)](
    state,
    newLevels,
  ) {
    if (state.draftSubmission)
      state.draftSubmission.data.assessment = newLevels;
  },

  [getBaseName(EAffiliatesMutations.SET_SUBMISSION_RESPONSES)](
    state,
    newResponses,
  ) {
    if (state.draftSubmission)
      state.draftSubmission.data.responses = newResponses;
  },

  [getBaseName(EAffiliatesMutations.SET_SUBMISSION_TEAM_MEMBERS)](
    state,
    newTeamMembers,
  ) {
    if (state.draftSubmission)
      state.draftSubmission.data.teamMembers = newTeamMembers;
  },

  [getBaseName(EAffiliatesMutations.SET_USER_SUBMISSIONS)](
    state,
    userSubmissions,
  ) {
    state.userSubmissions = userSubmissions;
  },

  [getBaseName(EAffiliatesMutations.SET_USER_NAVIGATING_FROM_REVIEW_PAGE)](
    state,
    newValue,
  ) {
    state.userNavigatingFromReviewPage = newValue;
  },
};
