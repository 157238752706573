<template>
  <div class="level-update-modal-first-tab">
    <div
      v-if="currentLevel && newLevel"
      class="level-update-modal-first-tab__cards-wrapper"
    >
      <LevelUpdateCard
        :current-assessment="staticCurrentAssessment"
        :level="staticPrevLevel"
        :level-description="getLevelDescription(staticPrevLevel)"
        :only-prev-assessment="true"
        :prev-assessment="staticPrevAssessment"
        :type-of-update="typeOfUpdate"
        class="level-update-modal-first-tab__current-card"
      />
      <LevelUpdateCard
        :current-assessment="staticCurrentAssessment"
        :level="staticNewLevel"
        :level-description="getLevelDescription(staticNewLevel)"
        :prev-assessment="staticPrevAssessment"
        :type-of-update="typeOfUpdate"
        class="level-update-modal-first-tab__new-card"
        type="blue"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import LevelUpdateCard from "@/modules/common/components/level-update-modal/level-update-card.vue";

import { ILevel } from "@/services/data/level/level.interface";

export default defineComponent({
  name: "LevelUpdateModalFirstTab",

  components: {
    LevelUpdateCard,
  },

  static: {
    staticSameLevelPrevAssessment: [
      {
        category: 28,
        level: 2,
      },
      {
        category: 30,
        level: 4,
      },
    ],
    staticLevelDownPrevAssessment: [
      {
        category: 26,
        level: 3,
      },
      {
        category: 31,
        level: 3,
      },
    ],
    staticLevelUpPrevAssessment: [
      {
        category: 26,
        level: 2,
      },
    ],
    staticSameLevelCurrentAssessment: [
      {
        category: 28,
        level: 4,
      },
      {
        category: 30,
        level: 2,
      },
    ],
    staticLevelDownCurrentAssessment: [
      {
        category: 26,
        level: 2,
      },
      {
        category: 31,
        level: 4,
      },
    ],
    staticLevelUpCurrentAssessment: [
      {
        category: 26,
        level: 5,
      },
    ],
  },

  props: {
    currentLevel: {
      type: Object as () => ILevel | null,
      default: null,
    },
    newLevel: {
      type: Object as () => ILevel,
      required: true,
    },
    sameLevel: {
      type: Boolean,
      default: false,
    },
    levelUp: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    levels(): ILevel[] {
      return this.$store.get("levels.data");
    },

    staticPrevLevel(): number {
      return this.sameLevel || this.levelUp ? 2 : 3;
    },

    staticNewLevel(): number {
      return this.levelUp ? 3 : 2;
    },

    staticPrevAssessment(): any[] {
      return this.sameLevel
        ? this.$options.static.staticSameLevelPrevAssessment
        : this.levelUp
          ? this.$options.static.staticLevelUpPrevAssessment
          : this.$options.static.staticLevelDownPrevAssessment;
    },

    staticCurrentAssessment(): any[] {
      return this.sameLevel
        ? this.$options.static.staticSameLevelCurrentAssessment
        : this.levelUp
          ? this.$options.static.staticLevelUpCurrentAssessment
          : this.$options.static.staticLevelDownCurrentAssessment;
    },

    typeOfUpdate(): string {
      return this.sameLevel
        ? "same-level"
        : this.levelUp
          ? "level-up"
          : "level-down";
    },
  },

  methods: {
    getLevelDescription(level: number) {
      const currentLevel = this.levels.find((lvl: any) => lvl.value == level);

      return currentLevel?.title;
    },
  },
});
</script>

<style lang="scss" scoped>
.level-update-modal-first-tab {
  margin-top: 46px;

  &__cards-wrapper {
    padding: 19px 25px;

    @include breakpoint-up(sm) {
      display: flex;
      align-items: center;
      max-width: 500px;
      padding-right: 0;
      padding-bottom: 26px;
      padding-left: 0;
      margin: 0 auto;
    }
  }

  &__current-card {
    margin-bottom: 12px;

    @include breakpoint-up(sm) {
      margin: 0 20px 0 0;
    }
  }

  .px-panel.level-update-modal-first-tab__current-card::after {
    position: absolute;
    bottom: -28px;
    left: 50%;
    display: block;
    width: 40px;
    height: 45px;

    content: "";

    background: url("/img/blue-arrow.svg");
    border: none;

    opacity: 1;

    transform: translateX(-50%);

    @include breakpoint-up(sm) {
      top: 51%;
      right: -38px;
      bottom: auto;
      left: auto;
      width: 54px;
      height: 34px;

      background: url("/img/blue-arrow--right.svg") no-repeat;

      transform: translateY(-50%);
    }
  }
}
</style>
