<template>
  <div :class="{ 'is-no-time': remainingTime === 0 }" class="px-global-error">
    <img alt="Error Image" class="px-global-error__icon" src="/img/error.svg" />
    <h1 class="px-global-error__title">
      {{ $t("common.errors.global.title") }}
    </h1>
    <p class="px-global-error__notice">
      {{ text || $t("common.errors.global.notice") }}
    </p>
    <PxButton
      v-if="useAction"
      :loading="loading"
      class="px-global-error__button"
      icon="refresh"
      @click="dispatchActionEvent"
    >
      {{ $t("common.errors.global.refreshButton") }}
    </PxButton>
    <p v-if="autoRefresh" class="px-global-error__timeout">
      {{ $t("common.errors.global.timeout", { seconds: remainingTime }) }}
    </p>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

/**
 * Components to be used for all global errors.
 *
 * This adds a error image and also allows auto retries and
 * with a countdown and also adds a button for manually
 * retry the task that leaded to an error.
 */
export default defineComponent({
  name: "PxGlobalError",

  props: {
    /**
     * Loading state.
     */
    loading: {
      type: Boolean,
      default: false,
    },
    /**
     * Allows customize the error text.
     */
    text: {
      type: String,
      default: "",
    },
    /**
     * When true show a button.
     */
    useAction: {
      type: Boolean,
      default: false,
    },
    /**
     * When true the `doAction` event is dispatched each 10 seconds.
     */
    autoRefresh: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      timerIntervalIdentifier: null as any,
      remainingTime: 10,
    };
  },

  mounted() {
    if (this.autoRefresh) {
      this.initTimerInterval();
    }
  },

  methods: {
    dispatchActionEvent() {
      this.$emit("do-action");
    },

    initTimerInterval() {
      this.timerIntervalIdentifier = setInterval(() => {
        this.remainingTime--;

        // When timer gets to zero, throw event and stops the
        // timer interval.
        if (this.remainingTime === 0) {
          this.dispatchActionEvent();
          return clearInterval(this.timerIntervalIdentifier);
        }
      }, 1000);
    },
  },
});
</script>

<style lang="scss" scoped>
.px-global-error {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  transition: transform 0.3s ease-in-out;

  &__icon {
    width: 140px;
    margin-bottom: 5px;

    @include breakpoint-up(md) {
      width: 156px;
    }
  }

  &__title {
    @include grotesk("semiBold");

    margin-bottom: 1px;

    font-size: 1.2rem;
    line-height: 40px;
  }

  &__notice {
    @include grotesk("medium");

    position: relative;
    z-index: z("default") + 4;
    margin-bottom: 29px;

    font-size: 0.9333rem;
    line-height: 1;
    color: $manatee;

    @include breakpoint-up(md) {
      font-size: 1.0667rem;
    }
  }

  &__notice::before {
    position: absolute;
    top: 8px;
    right: -27px;
    display: block;
    width: 19px;
    height: 17px;

    content: "";

    background-image: url("#{$assetsPath}/img/icons/curve-arrow-down.svg");
    background-repeat: no-repeat;
    background-size: contain;
  }

  &__button {
    min-width: 292px;
  }

  &__timeout {
    padding-top: 10px;

    font-size: 0.8667rem;
    color: $manatee;

    transition: opacity 0.3s ease-in-out;

    @include breakpoint-up(md) {
      font-size: 0.9333rem;
    }
  }
}

.px-global-error.is-no-time {
  transform: translateY(20px);

  .px-global-error__timeout {
    opacity: 0;
  }
}
</style>
