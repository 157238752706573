<template>
  <div class="submission-tab-empty">
    <div
      :class="[`submission-tab-empty__icon--${icon}`]"
      class="submission-tab-empty__icon"
    >
      <PxIcon :name="icon" :size="iconSize" />
    </div>
    <p class="submission-tab-empty__title">
      {{ $t(title) }}
    </p>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    icon: {
      type: String,
      required: true,
    },
    iconSize: {
      type: [String, Number],
      required: false,
      default: 92,
    },
    title: {
      type: String,
      required: true,
    },
  },
});
</script>

<style lang="scss" scoped>
.submission-tab-empty {
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  justify-content: center;
  padding: 63px 0 31px;
}

.submission-tab-empty__icon--empty-additional-criteria {
  position: relative;
  right: 2px;
}

.submission-tab-empty__title {
  @include grotesk(medium);

  font-size: 15px;
  line-height: 22px;
  color: $spun-pearl;
  letter-spacing: -0.25px;

  &:not(:first-child) {
    margin-top: 13px;
  }
}
</style>
