import { resolveComponent as _resolveComponent, withModifiers as _withModifiers, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, mergeProps as _mergeProps, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-0b57ad40"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "matching-card-compact-selector__wrapper"
}
const _hoisted_2 = { class: "matching-card-compact-options__wrapper" }
const _hoisted_3 = { class: "matching-card-compact__section-container" }
const _hoisted_4 = { class: "matching-card-compact__supporter-container" }
const _hoisted_5 = { class: "matching-card-compact__headline-container" }
const _hoisted_6 = { class: "matching-card-compact__headline" }
const _hoisted_7 = { class: "matching-card-compact__title" }
const _hoisted_8 = { class: "matching-card-compact__subtitle" }
const _hoisted_9 = { class: "matching-badge-list__wrapper" }
const _hoisted_10 = ["textContent"]
const _hoisted_11 = {
  key: 0,
  class: "matching-card-compact__description-container"
}
const _hoisted_12 = {
  key: 0,
  class: "matching-card-compact__description"
}
const _hoisted_13 = { class: "matching-card-compact__sectors-container" }
const _hoisted_14 = {
  key: 1,
  class: "matching-card-compact__right-side"
}
const _hoisted_15 = { class: "matching-card-compact__cta-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ElCheckbox = _resolveComponent("ElCheckbox")!
  const _component_ElTooltip = _resolveComponent("ElTooltip")!
  const _component_MatchingCardOptions = _resolveComponent("MatchingCardOptions")!
  const _component_MatchingCardThumbnail = _resolveComponent("MatchingCardThumbnail")!
  const _component_MatchingBadgeList = _resolveComponent("MatchingBadgeList")!
  const _component_MatchingCardTagList = _resolveComponent("MatchingCardTagList")!
  const _component_MatchingCardPercentagePill = _resolveComponent("MatchingCardPercentagePill")!
  const _component_PxMatchingInterestCompact = _resolveComponent("PxMatchingInterestCompact")!
  const _component_PxButton = _resolveComponent("PxButton")!
  const _component_MatchDetailsModal = _resolveComponent("MatchDetailsModal")!
  const _component_ListManagementConfirmationDialog = _resolveComponent("ListManagementConfirmationDialog")!
  const _component_SubmissionsModal = _resolveComponent("SubmissionsModal")!
  const _component_MatchingCardCompactWrapper = _resolveComponent("MatchingCardCompactWrapper")!

  return (_openBlock(), _createBlock(_component_MatchingCardCompactWrapper, {
    "is-disabled": _ctx.disabled,
    "is-faded": _ctx.isFaded,
    "is-options-open": _ctx.toggleOptions,
    "is-selected": _ctx.isSelected,
    onClick: _ctx.onCardClick
  }, {
    action: _withCtx(() => [
      (_ctx.canShowSelectMode)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(_component_ElTooltip, {
              content: _ctx.checkboxTooltipCopy,
              disabled: _ctx.isInnerSelected,
              placement: "top",
              "popper-class": "px-matching-interest-compact__tooltip el-abaca-tooltip"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ElCheckbox, {
                  modelValue: _ctx.isInnerSelected,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.isInnerSelected) = $event)),
                  class: "matching-card-compact-selector",
                  onClick: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["stop"]))
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }, 8, ["content", "disabled"])
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_2, [
        (_ctx.canShowOptions)
          ? (_openBlock(), _createBlock(_component_MatchingCardOptions, {
              key: 0,
              class: "matching-card-compact-options",
              onDelete: _ctx.deleteClickHandler,
              onIsOptionsOpen: _ctx.toogleOptionsOpen
            }, null, 8, ["onDelete", "onIsOptionsOpen"]))
          : _createCommentVNode("", true)
      ])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_MatchingCardThumbnail, {
              level: _ctx.matchLevel,
              name: _ctx.name,
              size: 48,
              thumbnail: _ctx.thumbnail
            }, null, 8, ["level", "name", "thumbnail"]),
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("h3", _hoisted_7, _toDisplayString(_ctx.name), 1),
              _createElementVNode("p", _hoisted_8, _toDisplayString(_ctx.location), 1),
              _createElementVNode("div", _hoisted_9, [
                (!_ctx.showPublicView && !_ctx.disabled)
                  ? (_openBlock(), _createBlock(_component_MatchingBadgeList, {
                      key: 0,
                      badges: _ctx.badges,
                      "company-name": _ctx.name,
                      onClick: _ctx.openSubmissionsModal
                    }, null, 8, ["badges", "company-name", "onClick"]))
                  : _createCommentVNode("", true),
                (!_ctx.showPublicView && _ctx.disabled)
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 1,
                      class: "matching-card-compact-selector--label",
                      textContent: _toDisplayString(_ctx.labelText)
                    }, null, 8, _hoisted_10))
                  : _createCommentVNode("", true)
              ])
            ])
          ])
        ]),
        (_ctx.showDescription)
          ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
              (!!_ctx.about)
                ? (_openBlock(), _createElementBlock("span", _hoisted_12, _toDisplayString(_ctx.about), 1))
                : _createCommentVNode("", true)
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_13, [
          _createVNode(_component_MatchingCardTagList, {
            items: _ctx.mappedSectors,
            "max-lines": 2
          }, null, 8, ["items"])
        ]),
        (_ctx.showMatchingElements)
          ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
              (_ctx.showPercentage)
                ? (_openBlock(), _createBlock(_component_MatchingCardPercentagePill, {
                    key: 0,
                    percentage: _ctx.matchPercentage,
                    "show-tooltip": !_ctx.isInterestDetailModalVisible,
                    class: "matching-card-compact__percentage",
                    onClick: _withModifiers(_ctx.onClickShowModal, ["stop"])
                  }, null, 8, ["percentage", "show-tooltip", "onClick"]))
                : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_15, [
                (_ctx.showConnectButton)
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                      (!_ctx.showPublicView && _ctx.$features.isEnabled('match'))
                        ? (_openBlock(), _createBlock(_component_PxMatchingInterestCompact, _mergeProps({ key: 0 }, _ctx.$options.static.interestButton, {
                            id: _ctx.companyId,
                            "is-directory-member": _ctx.isDirectoryMember,
                            name: _ctx.name,
                            uid: _ctx.companyUid,
                            class: "matching-card__interest",
                            onClick: _cache[2] || (_cache[2] = _withModifiers(() => {}, ["stop"]))
                          }), null, 16, ["id", "is-directory-member", "name", "uid"]))
                        : (!_ctx.$user.isLogged())
                          ? (_openBlock(), _createBlock(_component_PxButton, _mergeProps({ key: 1 }, _ctx.$options.static.publicInterestButton, {
                              onClick: _withModifiers(_ctx.onRegistration, ["stop"])
                            }), null, 16, ["onClick"]))
                          : _createCommentVNode("", true)
                    ], 64))
                  : _createCommentVNode("", true),
                (_ctx.showListButton || _ctx.showPublicView)
                  ? (_openBlock(), _createBlock(_component_PxButton, _mergeProps({ key: 1 }, _ctx.$options.static.addToListButton, {
                      onClick: _withModifiers(_ctx.onShowListManagementModal, ["stop"])
                    }), null, 16, ["onClick"]))
                  : _createCommentVNode("", true)
              ]),
              (_ctx.showInterestDetailModalVisible)
                ? (_openBlock(), _createBlock(_component_MatchDetailsModal, {
                    key: 1,
                    visibility: _ctx.isInterestDetailModalVisible,
                    "onUpdate:visibility": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.isInterestDetailModalVisible) = $event)),
                    "company-logo": _ctx.thumbnail,
                    "company-name": _ctx.name,
                    "match-details": _ctx.matchScoreDetails
                  }, null, 8, ["visibility", "company-logo", "company-name", "match-details"]))
                : _createCommentVNode("", true)
            ]))
          : _createCommentVNode("", true)
      ]),
      _createVNode(_component_ListManagementConfirmationDialog, {
        visibility: _ctx.isDeletePanelVisible,
        "onUpdate:visibility": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.isDeletePanelVisible) = $event)),
        "selected-company-name": _ctx.name,
        onCloseOptions: _cache[5] || (_cache[5] = ($event: any) => (_ctx.toggleOptions = false)),
        onRemoveCompanies: _ctx.onRemoveConfirmationButtonClick
      }, null, 8, ["visibility", "selected-company-name", "onRemoveCompanies"]),
      (_ctx.submissionsModalVisible)
        ? (_openBlock(), _createBlock(_component_SubmissionsModal, {
            key: 0,
            visibility: _ctx.submissionsModalVisible,
            "onUpdate:visibility": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.submissionsModalVisible) = $event)),
            company: _ctx.name,
            data: _ctx.affiliates
          }, null, 8, ["visibility", "company", "data"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["is-disabled", "is-faded", "is-options-open", "is-selected", "onClick"]))
}