export enum EDemographicThemes {
  GENDER = "Gender",
  ETHNICITY = "Ethnicity",
  SEXUAL_ORIENTATION = "Sexual Orientation",
}

export enum EDashboardPages {
  LIST_REPORTS = 0,
  PROCESS_REPORTS = 1,
  MILESTONE_PROGRESS = 2,
}
