import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-4dc029e2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "assessment__container" }
const _hoisted_2 = { class: "assessment__header" }
const _hoisted_3 = { class: "assessment__header-tip" }
const _hoisted_4 = { class: "assessment__header-tooltip-container" }
const _hoisted_5 = {
  key: 0,
  class: "assessment__main"
}
const _hoisted_6 = { class: "assessment__level-slider-column" }
const _hoisted_7 = {
  key: 0,
  class: "assessment-level-slider-tip"
}
const _hoisted_8 = ["src"]
const _hoisted_9 = ["innerHTML"]
const _hoisted_10 = { class: "assessment__requirements-column" }
const _hoisted_11 = {
  ref: "rangeLimitTip",
  class: "assessment-range-tip"
}
const _hoisted_12 = ["innerHTML"]
const _hoisted_13 = { class: "assessment-range-tip__teaser" }
const _hoisted_14 = {
  key: 0,
  class: "assessment__footer"
}
const _hoisted_15 = { class: "assessment__container" }
const _hoisted_16 = { class: "assessment__footer-left" }
const _hoisted_17 = { class: "assessment__footer-copyright" }
const _hoisted_18 = { class: "assessment__footer-right" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PxButton = _resolveComponent("PxButton")!
  const _component_PxIcon = _resolveComponent("PxIcon")!
  const _component_ElTooltip = _resolveComponent("ElTooltip")!
  const _component_LevelRangeSlider = _resolveComponent("LevelRangeSlider")!
  const _component_LevelsDescription = _resolveComponent("LevelsDescription")!
  const _component_CompanyRequirements = _resolveComponent("CompanyRequirements")!
  const _component_RangeLevelsModal = _resolveComponent("RangeLevelsModal")!
  const _component_MobileFooterNav = _resolveComponent("MobileFooterNav")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([{
      'is-navigation-bar': _ctx.$screen.mdOnly,
      'has-fixed-navigation': !_ctx.$screen.mdOnly && _ctx.showIntegratedFooter,
    }, "assessment"])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        (_ctx.hasPreviousPage)
          ? (_openBlock(), _createBlock(_component_PxButton, _mergeProps({
              key: 0,
              class: "assessment__back-button"
            }, _ctx.$options.static.backButton, { onClick: _ctx.goToPreviousPage }), null, 16, ["onClick"]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_3, [
          _createTextVNode(_toDisplayString(_ctx.$t("supporters.cta.ventureInvestmentLevel")) + " ", 1),
          _createVNode(_component_ElTooltip, {
            placement: "top",
            "popper-class": "assessment__header-tooltip-wrapper el-abaca-tooltip"
          }, {
            content: _withCtx(() => [
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("p", null, _toDisplayString(_ctx.$t("supporters.view.criteriaAssessment.tip")), 1),
                _createElementVNode("p", null, _toDisplayString(_ctx.$t("supporters.view.criteriaAssessment.tip2")), 1)
              ])
            ]),
            default: _withCtx(() => [
              _createVNode(_component_PxIcon, {
                size: 16,
                class: "assessment__header-tip-icon",
                name: "question"
              })
            ]),
            _: 1
          })
        ])
      ]),
      (_ctx.currentCategory && _ctx.selectedStep)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createVNode(_component_LevelRangeSlider, {
                color: _ctx.currentCategory.color,
                levels: _ctx.currentCategory.categoryDetails.length,
                "range-restriction": _ctx.rangeRestriction,
                value: _ctx.selectedStepLevel,
                height: "372px",
                onInput: _ctx.onLevelChange,
                onRangeLimitOverreached: _ctx.rangeLimitOverreachedHandler
              }, null, 8, ["color", "levels", "range-restriction", "value", "onInput", "onRangeLimitOverreached"]),
              (_ctx.levelAtStart && _ctx.isFirstInteraction)
                ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                    _createElementVNode("img", {
                      src: _ctx.sliderTipIcon,
                      alt: "Drag Tip Icon",
                      class: "assessment-level-slider-tip__icon"
                    }, null, 8, _hoisted_8),
                    _createElementVNode("p", {
                      class: "assessment-level-slider-tip__text",
                      innerHTML: 
                _ctx.$t(`supporters.assessment.dragTip`, { range: _ctx.rangeRestriction })
              
                    }, null, 8, _hoisted_9)
                  ]))
                : _createCommentVNode("", true)
            ]),
            _createVNode(_component_LevelsDescription, {
              "category-details": _ctx.currentCategory.categoryDetails,
              "current-level": _ctx.currentSelectedStepLevel,
              "range-first-level": _ctx.firstSelectedRangeLevel,
              class: "assessment__levels-description",
              height: "356px"
            }, null, 8, ["category-details", "current-level", "range-first-level"]),
            _createElementVNode("div", _hoisted_10, [
              _createVNode(_component_CompanyRequirements, {
                category: _ctx.currentCategory,
                level: _ctx.currentSelectedStepLevel,
                class: "assessment__category-requirements"
              }, null, 8, ["category", "level"]),
              _createElementVNode("div", _hoisted_11, [
                _createVNode(_component_PxIcon, {
                  size: 16,
                  name: "information"
                }),
                _createElementVNode("p", {
                  class: "assessment-range-tip__text",
                  innerHTML: 
                _ctx.$t(`supporters.assessment.rangeLimitNotice.title`, {
                  range: _ctx.rangeRestriction,
                })
              
                }, null, 8, _hoisted_12),
                _createElementVNode("p", _hoisted_13, [
                  _createTextVNode(_toDisplayString(_ctx.$t("supporters.assessment.rangeLimitNotice.content", {
                  range: _ctx.rangeRestriction,
                })) + " ", 1),
                  _createVNode(_component_PxButton, {
                    type: "link",
                    onClick: _ctx.rangeTipButtonClickHandler
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t("supporters.assessment.rangeLimitNotice.cta")), 1)
                    ]),
                    _: 1
                  }, 8, ["onClick"])
                ])
              ], 512)
            ])
          ]))
        : _createCommentVNode("", true)
    ]),
    (!_ctx.$screen.mdOnly && _ctx.showIntegratedFooter)
      ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
          _createElementVNode("div", _hoisted_15, [
            _createElementVNode("div", _hoisted_16, [
              _createElementVNode("p", _hoisted_17, _toDisplayString(_ctx.$t("common.footer.copyright", {
                year: _ctx.currentYear,
              })), 1)
            ]),
            _createElementVNode("div", _hoisted_18, [
              _createVNode(_component_PxButton, {
                class: "assessment__next-btn",
                size: "medium",
                type: "green",
                onClick: _ctx.onSave
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t("supporters.assessment.saveButton")), 1)
                ]),
                _: 1
              }, 8, ["onClick"])
            ])
          ])
        ]))
      : _createCommentVNode("", true),
    _createVNode(_component_RangeLevelsModal, {
      visibility: _ctx.rangeLevelsModalVisible,
      "onUpdate:visibility": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.rangeLevelsModalVisible) = $event))
    }, null, 8, ["visibility"]),
    (_ctx.$screen.mdOnly && _ctx.showIntegratedFooter)
      ? (_openBlock(), _createBlock(_component_MobileFooterNav, {
          key: 1,
          loading: false,
          onOnSave: _ctx.onSave
        }, null, 8, ["onOnSave"]))
      : _createCommentVNode("", true)
  ], 2))
}