import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, Fragment as _Fragment, mergeProps as _mergeProps, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-6838f25c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "submissions-table-row__first-col submissions-table-row__col" }
const _hoisted_2 = ["title"]
const _hoisted_3 = {
  key: 0,
  class: "submissions-table-row__col"
}
const _hoisted_4 = ["title"]
const _hoisted_5 = ["title"]
const _hoisted_6 = { class: "submissions-table-row__col" }
const _hoisted_7 = { class: "submissions-table-row__last-col submissions-table-row__col" }
const _hoisted_8 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PxAvatarSet = _resolveComponent("PxAvatarSet")!
  const _component_PxAvatar = _resolveComponent("PxAvatar")!
  const _component_MatchingCardThumbnail = _resolveComponent("MatchingCardThumbnail")!
  const _component_SubmissionsInfoBadges = _resolveComponent("SubmissionsInfoBadges")!
  const _component_PxButton = _resolveComponent("PxButton")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([{
      'submissions-table-row__owner-submissions-view': _ctx.isOwnerSubmissionsView,
      'submissions-table-row__simplified-view': _ctx.isModalView,
    }, "submissions-table-row"])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("p", {
        title: _ctx.submissionData.affiliate.name
      }, _toDisplayString(_ctx.submissionData.affiliate.name), 9, _hoisted_2)
    ]),
    (_ctx.ownersLength)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          (_ctx.isUserSubmissionsView)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                (_ctx.ownersLength > 1)
                  ? (_openBlock(), _createBlock(_component_PxAvatarSet, {
                      key: 0,
                      items: _ctx.submissionData.owners,
                      size: 21,
                      "visible-limit": 5,
                      onClick: _ctx.goToCompanyProfile
                    }, null, 8, ["items", "onClick"]))
                  : (_openBlock(), _createElementBlock("div", {
                      key: 1,
                      class: "submissions-table-row__owner",
                      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.goToCompanyProfile(_ctx.submissionData.owners[0])))
                    }, [
                      _createVNode(_component_PxAvatar, {
                        name: _ctx.submissionData.owners[0].name,
                        photo: _ctx.submissionData.owners[0].logo,
                        size: 24,
                        class: "submissions-table-row__owner-photo",
                        styling: "neutral",
                        type: "round"
                      }, null, 8, ["name", "photo"]),
                      _createElementVNode("p", {
                        title: _ctx.submissionData.owners[0].name
                      }, _toDisplayString(_ctx.submissionData.owners[0].name), 9, _hoisted_4)
                    ]))
              ], 64))
            : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                _createVNode(_component_MatchingCardThumbnail, {
                  level: [],
                  name: _ctx.submissionData.owners[0].name,
                  size: 34,
                  thumbnail: _ctx.submissionData.owners[0].logo
                }, null, 8, ["name", "thumbnail"]),
                _createElementVNode("p", {
                  title: _ctx.submissionData.owners[0].name,
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.goToCompanyProfile(_ctx.submissionData.owners[0])))
                }, _toDisplayString(_ctx.submissionData.owners[0].name), 9, _hoisted_5)
              ], 64))
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_6, [
      _createVNode(_component_SubmissionsInfoBadges, {
        badges: _ctx.submissionData.shared_info
      }, null, 8, ["badges"])
    ]),
    _createElementVNode("div", _hoisted_7, [
      (_ctx.lastUpdateDate)
        ? (_openBlock(), _createElementBlock("p", _hoisted_8, _toDisplayString(_ctx.lastUpdateDate), 1))
        : _createCommentVNode("", true),
      _createVNode(_component_PxButton, _mergeProps(_ctx.actionButtonProps, { onClick: _ctx.onActionButtonClick }), null, 16, ["onClick"])
    ])
  ], 2))
}