import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-852c9bc2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "supporters-view__container" }
const _hoisted_2 = { class: "supporter-registration-form__container" }
const _hoisted_3 = ["href"]
const _hoisted_4 = ["href"]
const _hoisted_5 = { class: "supporter-registration-form__cta" }
const _hoisted_6 = { class: "supporter-registration-form__cta supporter-registration-form__google-cta" }
const _hoisted_7 = { class: "supporter-registration-form__footer" }
const _hoisted_8 = { class: "supporter-registration-form__footer-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LangSwitcher = _resolveComponent("LangSwitcher")!
  const _component_ElInput = _resolveComponent("ElInput")!
  const _component_ElFormItem = _resolveComponent("ElFormItem")!
  const _component_i18n_t = _resolveComponent("i18n-t")!
  const _component_ElCheckbox = _resolveComponent("ElCheckbox")!
  const _component_PxButton = _resolveComponent("PxButton")!
  const _component_ElDivider = _resolveComponent("ElDivider")!
  const _component_PxIcon = _resolveComponent("PxIcon")!
  const _component_ElForm = _resolveComponent("ElForm")!
  const _component_FormPresentationLayout = _resolveComponent("FormPresentationLayout")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_LangSwitcher),
    _createVNode(_component_FormPresentationLayout, {
      "content-description": _ctx.viewCopy.contentDescription,
      "content-title": _ctx.viewCopy.contentTitle,
      "description-image-link": _ctx.viewCopy.imageLink,
      "description-image-title": _ctx.viewCopy.imageTitle,
      "description-text": _ctx.viewCopy.imageDescription,
      "has-description-background-image": true,
      "has-return-button": _ctx.hasPreviousPage,
      onPreviousPage: _ctx.onReturnButtonClick
    }, {
      content: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_ElForm, {
            ref: "supporterRegistrationForm",
            model: _ctx.fields,
            rules: _ctx.rules,
            class: "supporter-registration-form__body",
            onValidate: _ctx.updateSubmitState
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ElFormItem, {
                error: _ctx.errors.getMessage('email') || '',
                label: _ctx.formCopy.email.label,
                prop: "email"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ElInput, {
                    modelValue: _ctx.fields.email,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.fields.email) = $event)),
                    placeholder: _ctx.formCopy.email.placeholder,
                    type: "email"
                  }, null, 8, ["modelValue", "placeholder"])
                ]),
                _: 1
              }, 8, ["error", "label"]),
              _createVNode(_component_ElFormItem, {
                "show-message": false,
                prop: "terms",
                required: ""
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ElCheckbox, {
                    modelValue: _ctx.fields.terms,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.fields.terms) = $event)),
                    class: "supporter-registration-form__selector"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_i18n_t, {
                        class: "supporter-registration-form__selector-text",
                        keypath: "supporters.form.terms",
                        tag: "p"
                      }, {
                        terms: _withCtx(() => [
                          _createElementVNode("a", {
                            href: _ctx.$options.static.termsOfUseUrl,
                            class: "text--nice-blue",
                            target: "_blank"
                          }, _toDisplayString(_ctx.$t("supporters.form.termsOfUse")), 9, _hoisted_3)
                        ]),
                        privacy: _withCtx(() => [
                          _createElementVNode("a", {
                            href: _ctx.$options.static.privacyPolicyUrl,
                            class: "text--nice-blue",
                            target: "_blank"
                          }, _toDisplayString(_ctx.$t("supporters.form.privacyPolicy")), 9, _hoisted_4)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 8, ["modelValue"])
                ]),
                _: 1
              }),
              _createElementVNode("div", _hoisted_5, [
                _createVNode(_component_PxButton, {
                  disabled: _ctx.isSubmissionDisabled,
                  loading: _ctx.formLoading,
                  type: "primary",
                  onClick: _ctx.onNextButtonClick
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.ctaCopy.signUp), 1)
                  ]),
                  _: 1
                }, 8, ["disabled", "loading", "onClick"])
              ]),
              (_ctx.showGoogleSignUp)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                    _createVNode(_component_ElDivider, null, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.viewCopy.separatorText), 1)
                      ]),
                      _: 1
                    }),
                    _createElementVNode("div", _hoisted_6, [
                      _createVNode(_component_PxButton, { onClick: _ctx.onGoogleSignUpButtonClick }, {
                        default: _withCtx(() => [
                          _createVNode(_component_PxIcon, {
                            size: 18,
                            class: "supporter-registration-form__google-logo",
                            name: "google-logo"
                          }),
                          _createTextVNode(" " + _toDisplayString(_ctx.ctaCopy.googleSignUp), 1)
                        ]),
                        _: 1
                      }, 8, ["onClick"])
                    ])
                  ], 64))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["model", "rules", "onValidate"]),
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("p", _hoisted_8, [
              _createTextVNode(_toDisplayString(_ctx.ctaCopy.alreadyHaveAccount) + " ", 1),
              _createVNode(_component_PxButton, {
                class: "el-button--inline el-button--link-blue",
                type: "link",
                onClick: _ctx.onLoginButtonClick
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.ctaCopy.login), 1)
                ]),
                _: 1
              }, 8, ["onClick"])
            ])
          ])
        ])
      ]),
      _: 1
    }, 8, ["content-description", "content-title", "description-image-link", "description-image-title", "description-text", "has-return-button", "onPreviousPage"])
  ]))
}