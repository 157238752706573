<template>
  <div
    :class="{
      'is-navigation-bar': $screen.mdOnly,
      'has-fixed-navigation': !$screen.mdOnly && showIntegratedFooter,
    }"
    class="assessment"
  >
    <div class="assessment__container">
      <div class="assessment__header">
        <PxButton
          v-if="hasPreviousPage"
          class="assessment__back-button"
          v-bind="$options.static.backButton"
          @click="goToPreviousPage"
        />
        <div class="assessment__header-tip">
          {{ $t("supporters.cta.ventureInvestmentLevel") }}
          <ElTooltip
            placement="top"
            popper-class="assessment__header-tooltip-wrapper el-abaca-tooltip"
          >
            <template #content>
              <div class="assessment__header-tooltip-container">
                <p>
                  {{ $t("supporters.view.criteriaAssessment.tip") }}
                </p>
                <p>
                  {{ $t("supporters.view.criteriaAssessment.tip2") }}
                </p>
              </div>
            </template>
            <PxIcon
              :size="16"
              class="assessment__header-tip-icon"
              name="question"
            />
          </ElTooltip>
        </div>
      </div>
      <div v-if="currentCategory && selectedStep" class="assessment__main">
        <div class="assessment__level-slider-column">
          <LevelRangeSlider
            :color="currentCategory.color"
            :levels="currentCategory.categoryDetails.length"
            :range-restriction="rangeRestriction"
            :value="selectedStepLevel"
            height="372px"
            @input="onLevelChange"
            @range-limit-overreached="rangeLimitOverreachedHandler"
          />
          <div
            v-if="levelAtStart && isFirstInteraction"
            class="assessment-level-slider-tip"
          >
            <img
              :src="sliderTipIcon"
              alt="Drag Tip Icon"
              class="assessment-level-slider-tip__icon"
            />
            <p
              class="assessment-level-slider-tip__text"
              v-html="
                $t(`supporters.assessment.dragTip`, { range: rangeRestriction })
              "
            />
          </div>
        </div>
        <LevelsDescription
          :category-details="currentCategory.categoryDetails"
          :current-level="currentSelectedStepLevel"
          :range-first-level="firstSelectedRangeLevel"
          class="assessment__levels-description"
          height="356px"
        />
        <div class="assessment__requirements-column">
          <CompanyRequirements
            :category="currentCategory"
            :level="currentSelectedStepLevel"
            class="assessment__category-requirements"
          />
          <div ref="rangeLimitTip" class="assessment-range-tip">
            <PxIcon :size="16" name="information" />
            <p
              class="assessment-range-tip__text"
              v-html="
                $t(`supporters.assessment.rangeLimitNotice.title`, {
                  range: rangeRestriction,
                })
              "
            />
            <p class="assessment-range-tip__teaser">
              {{
                $t("supporters.assessment.rangeLimitNotice.content", {
                  range: rangeRestriction,
                })
              }}
              <PxButton type="link" @click="rangeTipButtonClickHandler">
                {{ $t("supporters.assessment.rangeLimitNotice.cta") }}
              </PxButton>
            </p>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="!$screen.mdOnly && showIntegratedFooter"
      class="assessment__footer"
    >
      <div class="assessment__container">
        <div class="assessment__footer-left">
          <p class="assessment__footer-copyright">
            {{
              $t("common.footer.copyright", {
                year: currentYear,
              })
            }}
          </p>
        </div>
        <div class="assessment__footer-right">
          <PxButton
            class="assessment__next-btn"
            size="medium"
            type="green"
            @click="onSave"
          >
            {{ $t("supporters.assessment.saveButton") }}
          </PxButton>
        </div>
      </div>
    </div>
    <RangeLevelsModal v-model:visibility="rangeLevelsModalVisible" />
    <MobileFooterNav
      v-if="$screen.mdOnly && showIntegratedFooter"
      :loading="false"
      @on-save="onSave"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import LevelRangeSlider from "@/modules/supporters/components/level-range-slider/level-range-slider.vue";
import LevelsDescription from "@/modules/supporters/components/levels-description/levels-description.vue";
import CompanyRequirements from "@/modules/supporters/components/company-requirements/company-requirements.vue";
import MobileFooterNav from "@/modules/supporters/components/mobile-footer-nav/mobile-footer-nav.vue";
import RangeLevelsModal from "@/modules/supporters/components/range-levels-modal/range-levels-modal.vue";
import { ICategoryLevelSelection } from "@/services/data/viral-level/viral-level.interface";
import { ICategory } from "@/services/data/category/category.interface";
import {
  EPxButtonSize,
  EPxButtonVariant,
} from "@/components/px-button/px-button.types";

export default defineComponent({
  name: "AssessmentDesktop",

  static: {
    backButton: {
      icon: "arrow--left-dark",
      size: EPxButtonSize.EXTRA_SMALL,
      variant: EPxButtonVariant.COMPACT,
    },
  },

  components: {
    LevelRangeSlider,
    LevelsDescription,
    CompanyRequirements,
    MobileFooterNav,
    RangeLevelsModal,
  },

  props: {
    currentCategory: {
      type: Object as () => ICategory,
      default: null,
    },

    rangeRestriction: {
      type: Number,
      default: null,
    },

    selectedStep: {
      type: Object as () => any,
      default: null,
    },

    onLevelChange: {
      type: Function as () => any,
      required: true,
    },

    selectedCategory: {
      type: Number,
      default: () => null,
    },

    levels: {
      type: Array as () => any[],
      default: () => [],
    },

    isFirstInteraction: {
      type: Boolean,
      default: true,
    },

    onSave: {
      type: Function as () => any,
      default: () => null,
    },

    showIntegratedFooter: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      defaultStartLevel: 1,
      selectedStepLevel: [1, 1],
      currentYear: new Date().getFullYear(),
      rangeLevelsModalVisible: false,
    };
  },

  computed: {
    levelAtStart(): boolean {
      return this.currentSelectedStepLevel === this.defaultStartLevel;
    },

    currentCategoryIsTypeRange(): boolean {
      return (
        (!!this.currentCategory &&
          this.currentCategory.value_type === "RANGE") ||
        false
      );
    },

    currentSelectedStepLevel(): number {
      if (this.currentCategoryIsTypeRange) {
        return this.selectedStep.level[1] || this.defaultStartLevel;
      }

      return this.selectedStep.level !== 0
        ? this.selectedStep.level
        : this.defaultStartLevel;
    },

    firstSelectedRangeLevel(): number {
      return this.currentCategoryIsTypeRange
        ? this.selectedStep.level[0]
        : this.selectedStep.level;
    },

    rangeTipElement(): HTMLElement {
      return this.$refs.rangeLimitTip as HTMLElement;
    },

    sliderTipIcon(): string {
      return "/img/arrow-tip.svg";
    },

    hasPreviousPage(): boolean {
      return !!window.history.length;
    },
  },

  watch: {
    levels: {
      immediate: true,
      deep: true,
      handler(values: Array<ICategoryLevelSelection>) {
        const levelRange = values.find(
          (level: ICategoryLevelSelection) =>
            level.category === this.currentCategory?.id,
        )?.level as Array<number>;

        this.selectedStepLevel = levelRange || this.selectedStepLevel;
      },
    },
  },

  methods: {
    goToPreviousPage() {
      const previousPage = this.$route.meta.previousPage as string;
      const hasPreviousPage = !!previousPage;

      hasPreviousPage
        ? this.$router.push({ name: previousPage })
        : this.$router.back();
    },

    rangeTipToggleShakeAnimation() {
      this.rangeTipElement.classList.toggle("shake-animation");
    },

    rangeLimitOverreachedHandler() {
      this.rangeTipToggleShakeAnimation();

      setTimeout(this.rangeTipToggleShakeAnimation, 1000);
    },

    rangeTipButtonClickHandler() {
      this.rangeLevelsModalVisible = true;
    },
  },
});
</script>

<style lang="scss" scoped>
$--assessment-max-tablet-width: 978px;

.assessment {
  position: relative;

  &__container {
    max-width: 1170px !important;
    padding: 0 15px;
    margin: 0 auto;
  }

  &.is-navigation-bar {
    padding: 80px 0 120px;

    @include breakpoint-up(md) {
      padding: 0;
    }
  }

  &.has-fixed-navigation {
    padding-bottom: 100px;
  }

  & > div:first-child {
    max-width: 1140px;
  }

  &__header {
    @include make-container-max-widths();

    position: relative;
    padding: 43px 0 9px;
    margin: 0 auto;
    text-align: center;
  }

  &__back-button {
    position: absolute;
    top: 44px;
    left: 0;
    border-radius: 50%;

    .px-icon {
      position: relative;
      left: -1px;
    }
  }

  &__header-tip {
    @include grotesk(semiBold);

    position: relative;
    display: inline-block;
    font-size: 20px;
    line-height: 28px;
    color: $manatee;

    &-icon {
      position: absolute;
      top: 50%;
      right: -24px;
      margin-top: 1px;
      transform: translateY(-50%);
    }
  }

  &__main {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding: 0 0 50px 74px;

    @media (max-width: $--assessment-max-tablet-width) {
      flex-wrap: wrap;
    }
  }

  &__levels-description {
    position: relative;
    top: 15px;
    bottom: 0;
    flex: 1 1 0;
    align-self: flex-start;
    min-width: 0;
    margin-bottom: 5px;
  }

  &__requirements-column {
    align-self: flex-start;
    min-width: 330px;
    margin-top: 14px;

    @media (max-width: $--assessment-max-tablet-width) {
      width: 100%;
      padding: 0 48px 0 25px;
    }

    @include breakpoint-up(md) {
      margin-top: 30px;
    }

    @include breakpoint-up(lg) {
      margin-top: 26px;
    }
  }

  &__level-slider-column {
    position: relative;

    @include breakpoint-up(md) {
      flex: 0 1 70px;
    }
  }
}

.assessment-level-slider-tip {
  position: absolute;
  bottom: -56px;
  left: 42px;
  display: flex;

  &__icon {
    position: relative;
    top: -15px;
    left: 0;
    flex-shrink: 0;
    transform: rotate(19deg);
  }

  &__text {
    flex-shrink: 0;
    max-width: none;
    margin-left: 10px;

    font-size: 0.8667rem;
    line-height: 1.2rem;
  }
}

.assessment__footer .el-button {
  margin-left: 20px;
}

.assessment-range-tip {
  position: relative;
  padding-top: 21px;
  padding-left: 22px;

  .px-icon {
    position: absolute;
    left: 0;
  }

  &__text {
    font-size: 0.8667rem;
    font-style: italic;
    line-height: 1.3333rem;

    :deep() strong {
      font-size: 0.8667rem;
      line-height: 1.3333rem;
      letter-spacing: 0.1px;
    }
  }

  &__teaser {
    font-size: 0.8667rem;
    font-style: italic;
    color: $manatee;

    :deep() .el-button {
      height: auto;
      padding: 0;
    }

    :deep() .el-button span {
      @include grotesk(semiBold);

      font-size: 0.8667rem;
      font-style: italic;
      letter-spacing: 0.1px;
    }

    :deep() .el-button span::after {
      bottom: 1px;
    }
  }
}

.assessment__footer {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: z("navigation");
  display: block;
  width: 100%;
  max-width: none;
  padding: 22px 0;
  margin: 0;
  background: $white;
  box-shadow:
    -1px 0 0 0 rgba(220, 221, 226, 0.3),
    0 -6px 6px 0 rgba(0, 0, 0, 0.02);

  .assessment__container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &-copyright {
    @include grotesk(light);

    padding: 0;
    margin: 0;
    font-size: 13px;
    line-height: 22px;
    color: $manatee;
  }

  .assessment__next-btn {
    min-width: 204px;
  }
}

.shake-animation {
  animation: shake 800ms;
}
</style>

<style lang="scss">
.assessment__header-tooltip-wrapper {
  max-width: 497px;
  font-size: 14px;
  text-transform: none;
}

.assessment__header-tooltip-container {
  display: grid;
  grid-gap: 24px;
}
</style>
