<template>
  <div class="auth-base-password auth-base-password--recover">
    <div class="page-wrapper">
      <div v-if="!hasServerError" class="page-container">
        <h1 class="page-headline">
          {{ $t("authentication.passwordRecover.title") }}
        </h1>
        <div
          class="page-subtitle"
          v-html="$t('authentication.passwordRecover.instructions')"
        />
        <div class="page-block page-block--with-separator">
          <PxPanel class="px-panel--compact px-panel--stripped">
            <ElForm
              ref="recoverPasswordForm"
              :model="fields"
              :rules="rules"
              class="auth-base-password__form"
              @validate="updateSubmitState"
            >
              <ElFormItem
                :error="errors.getMessage('email')"
                :label="$t('authentication.passwordRecover.fields.email')"
                prop="email"
              >
                <ElInput
                  v-model="fields.email"
                  :placeholder="
                    $t('authentication.passwordRecover.fields.email')
                  "
                  type="email"
                />
              </ElFormItem>
              <ElFormItem class="el-form__cta">
                <PxButton
                  :disabled="submissionDisabled"
                  :loading="loading"
                  class="el-button--block"
                  size="medium"
                  type="primary"
                  @click="submitForm"
                >
                  {{ $t("authentication.passwordRecover.submitButton") }}
                </PxButton>
              </ElFormItem>
            </ElForm>
          </PxPanel>
        </div>
      </div>
      <PxGlobalError
        v-if="hasServerError"
        :loading="loading"
        :use-action="true"
        class="is-center"
        @do-action="submitForm"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import {
  allFormFieldsValid,
  generateEmailValidator,
} from "@/services/errors/validator-generators";

import { passwordRecoverProvider } from "@/modules/authentication/services/data/password-recover/password-recover.provider";
import { IPasswordRecover } from "@/modules/authentication/services/data/password-recover/password-recover.interface";

import { ErrorsManager } from "@/services/errors-manager";
import { ROUTE_AUTHENTICATION_LOGIN } from "@/modules/authentication/services/router/routes-names";

export default defineComponent({
  name: "AuthBasePasswordRecover",

  data() {
    return {
      loading: false,
      hasServerError: false,
      submissionDisabled: true,

      fields: {
        email: "",
      },

      rules: {
        email: generateEmailValidator(
          this,
          "authentication.passwordRecover.errors.emptyEmail",
          true,
        ),
      },

      errors: new ErrorsManager(),
    };
  },

  computed: {
    recoverPasswordFormRef(): HTMLElement {
      return this.$refs.recoverPasswordForm as HTMLElement;
    },
  },

  methods: {
    updateSubmitState() {
      this.submissionDisabled = !allFormFieldsValid(
        this.recoverPasswordFormRef,
        this.rules,
      );
    },

    async submitForm() {
      const dataToSend = {
        email: this.fields.email,
      } as IPasswordRecover;

      this.hasServerError = false;
      this.loading = true;

      try {
        await passwordRecoverProvider.create(dataToSend);
      } catch (error) {
        this.hasServerError = true;
      } finally {
        this.loading = false;
      }

      this.$message({
        message: this.$t("authentication.passwordRecover.successMessage", {
          email: this.fields.email,
        }) as string,
        type: "success",
        customClass: "is-full",
      });

      this.$router.push({ name: ROUTE_AUTHENTICATION_LOGIN });
    },
  },
});
</script>

<style lang="scss" scoped>
.auth-base-password .page-wrapper {
  padding-bottom: 32px;

  @include breakpoint-up(md) {
    padding-bottom: 40px;
  }
}
</style>
