<template>
  <div
    v-click-outside="outsideClickHandler"
    :class="{
      'is-open': isOpen,
      'is-reverse': isReverse,
      'is-disabled': disabled,
    }"
    class="px-tabs-mobile"
  >
    <div ref="container" class="px-tabs-mobile__container">
      <span class="px-tabs-mobile__trigger" @click="triggerClickHandler">
        {{ label }}
      </span>
      <div ref="list" class="px-tabs-mobile__list">
        <span
          v-for="item in nonSelectedElements"
          :key="item.value"
          class="px-tabs-mobile__item"
          @click="itemClickHandler(item)"
        >
          {{ item.label }}
        </span>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import anime from "animejs";
import ClickOutside from "vue-click-outside";

interface Item {
  value: string;
  label: string;
}

const ELEMENT_HEIGHT = 44;

export default defineComponent({
  name: "PxTabsMobile",

  directives: {
    ClickOutside,
  },

  props: {
    modelValue: {
      type: String,
      required: true,
    },

    items: {
      type: Array as () => any[],
      required: true,
    },

    /**
     * Disable the component.
     */
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isOpen: false,
      isReverse: false,
      innerValue: null,
    };
  },

  computed: {
    /**
     * Label of the current selected element.
     */
    label(): string {
      const selectedItem = this.items.find(
        (item: Item) => item.value === this.modelValue,
      );
      return selectedItem.label;
    },

    nonSelectedElements(): any[] {
      return this.items.filter((item: Item) => item.value !== this.modelValue);
    },
  },

  methods: {
    /**
     * Toggle the dropdown state.
     */
    triggerClickHandler() {
      const listElement = this.$refs.list as HTMLElement;
      const containerElement = this.$refs.container as HTMLElement;

      // Toggle dropdown state
      this.isOpen = !this.isOpen;

      let newHeight = 0;
      if (this.isOpen) {
        newHeight = ELEMENT_HEIGHT + listElement.offsetHeight;

        const newBottomLimit =
          containerElement.getBoundingClientRect().bottom +
          listElement.offsetHeight;

        this.isReverse = newBottomLimit > window.innerHeight;
      } else {
        newHeight = ELEMENT_HEIGHT;
      }

      // Animate container element
      return anime({
        targets: containerElement,
        minHeight: `${newHeight}px`,
        easing: "easeInOutQuart",
        duration: 300,
      });
    },

    /**
     * Change the selected item.
     */
    async itemClickHandler(item: Item) {
      await this.triggerClickHandler().finished;

      this.$emit("change", item.value);
      this.$emit("update:modelValue", item.value);
    },

    /**
     * Handle clicks outside of element.
     *
     * This is used to close the element when the users
     * clicks outside of the element.
     */
    outsideClickHandler() {
      if (!this.isOpen) {
        return;
      }

      this.triggerClickHandler();
    },
  },
});
</script>

<style lang="scss">
.px-tabs-mobile {
  position: relative;
  display: inline-block;
  width: 292px;
  height: 44px;

  &::after {
    position: absolute;
    top: 44px;
    left: 50%;
    width: calc(100% - 40px);
    height: 1px;

    content: "";

    background-color: $manatee;
    opacity: 0;

    transition: opacity 0.3s ease-out;
    transform: translateX(-50%);
  }

  &__container {
    @include gradient(light);

    position: absolute;
    right: 0;
    left: 0;
    height: 44px;
    overflow: hidden;

    border: 1px solid $silver;
    border-radius: 2px;

    will-change: min-height;
  }

  &__trigger {
    @include grotesk(semiBold);

    display: block;
    padding: 11px 22px 10px;

    font-size: 0.8667rem;
    text-transform: uppercase;

    cursor: pointer;
    user-select: none;
  }

  &__trigger::after {
    position: absolute;
    top: 9px;
    right: 15px;
    display: block;
    width: 24px;
    height: 24px;

    pointer-events: none;
    content: "";

    background: url("#/img/icons/arrow.svg") no-repeat;
    background-size: 24px;

    transition: transform 0.3s ease-in-out;
    transform-origin: center center;
  }

  &__item {
    @include grotesk(semiBold);

    display: block;
    padding: 13px 22px 0;

    font-size: 0.8667rem;
    color: $manatee;
    text-transform: uppercase;

    cursor: pointer;

    transition: color 0.25s ease-in-out;
  }

  &__item:hover {
    color: $ebony-clay;
  }

  &__item:last-child {
    padding-bottom: 13px;
  }
}

.px-tabs-mobile.is-open {
  &::after {
    opacity: 0.2;
  }

  .px-tabs-mobile__trigger::after {
    transform: rotate(-180deg);
  }
}

.px-tabs-mobile.is-reverse {
  &::after {
    top: 0;
  }

  .px-tabs-mobile__container {
    bottom: 0;
    display: flex;
    flex-direction: column-reverse;
  }

  .px-tabs-mobile__trigger::after {
    top: auto;
    bottom: 9px;
  }
}

.px-tabs-mobile.is-disabled {
  pointer-events: none;

  .px-tabs-mobile__trigger,
  .px-tabs-mobile__trigger::after {
    opacity: 0.7;
  }
}
</style>
