<template>
  <div
    :class="{
      'px-navigation-tab--list-format': listFormat,
    }"
    class="px-navigation-tab"
  >
    <div v-for="(tab, index) in navigationTabs" :key="tab.id">
      <PxNavigationTabItem
        :active="tab.id === selectedTab"
        :data-testid="`navigation-tab-${tab.id}`"
        :icon="tab.icon"
        :icon-position="tab.iconPosition"
        :icon-size="iconSize"
        :list-format="listFormat"
        :number="index + 1"
        :show-number="showNumber"
        :title="tab.title"
        @click="clickNavigationTab(tab.id)"
      />
    </div>
  </div>
</template>

<script lang="ts">
import PxNavigationTabItem from "@/components/px-navigation-tab/px-navigation-tab-item.vue";
import { TMilestoneDetailsTab } from "@/modules/milestone-planner/services/data/milestones/milestone.interface";
import { defineComponent } from "vue";

export interface INavigationTab {
  id: TMilestoneDetailsTab | number;
  title: string;
  icon?: string;
  visible?: boolean;
  iconPosition?: string;
}

export default defineComponent({
  name: "PxNavigationTab",

  components: {
    PxNavigationTabItem,
  },

  props: {
    navigationTabs: {
      type: Array as () => Array<INavigationTab>,
      required: true,
    },
    selectedTab: {
      type: [Number, String as () => TMilestoneDetailsTab],
      required: true,
    },
    showNumber: {
      type: Boolean,
      default: true,
    },
    iconSize: {
      type: Number,
      default: 17,
    },
    listFormat: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    /**
     * Handle navigation between tabs
     */
    clickNavigationTab(tab: TMilestoneDetailsTab | number) {
      this.$emit("update:selectedTab", tab);
    },
  },
});
</script>

<style lang="scss" scoped>
.px-navigation-tab {
  &:not(.px-navigation-tab--list-format) {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    max-width: 672px;
  }

  &--list-format {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }

  &__item:nth-child(2) {
    position: relative;
    right: 2px;
  }
}
</style>
