<template>
  <ElDialog
    ref="modal"
    v-model="innerVisibility"
    :append-to-body="true"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    class="initiative-dialog"
  >
    <template #header>
      <h3>
        {{ modalCopy.title }}
      </h3>
      <p>
        {{ modalCopy.subtitle }}
      </p>
      <PxIcon :size="120" name="equality-icon" />
    </template>
    <p v-html="modalCopy.content[0]"></p>
    <p v-html="modalCopy.content[1]"></p>
    <p v-html="modalCopy.content[2]"></p>
    <ul>
      <li v-html="modalCopy.content[3]"></li>
      <li v-html="modalCopy.content[4]"></li>
      <li v-html="modalCopy.content[5]"></li>
      <li v-html="modalCopy.content[6]"></li>
    </ul>
    <PxButton
      v-bind="buttonProps"
      data-testid="go-to-full-details-cta"
      @click="goToInitiativePage"
    />
  </ElDialog>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import ElDialogMixin from "@/mixins/el-dialog.mixin";
import { EPxButtonType } from "@/components/px-button/px-button.types";
import { ROUTE_TEAM_MANAGEMENT_INITIATIVE_DETAILS } from "@/modules/team-management/services/router/routes-names";
import { EMetaActions } from "@/services/store/meta/meta-types";
import { INITIATIVE_ONBOARDED } from "@/modules/team-management/constants";

export default defineComponent({
  name: "InitiativeDialog",

  mixins: [ElDialogMixin],

  static: {
    linkButton: {
      type: EPxButtonType.LINK,
    },
  },

  computed: {
    modalCopy() {
      return this.$tm("teamManagement.initiativeModal") as {
        title: string;
        subtitle: string;
        content: {
          0: string;
          1: string;
          2: string;
          3: string;
          4: string;
          5: string;
          6: string;
        };
        link: string;
      };
    },

    buttonProps(): any {
      return {
        ...this.$options.static.linkButton,
        label: this.modalCopy.link,
      };
    },
  },

  methods: {
    goToInitiativePage() {
      this.$store.dispatch(EMetaActions.SET, {
        key: INITIATIVE_ONBOARDED,
        value: true,
      });

      return this.$router.push({
        name: ROUTE_TEAM_MANAGEMENT_INITIATIVE_DETAILS,
      });
    },
  },
});
</script>

<style lang="scss">
.initiative-dialog {
  width: 100%;
  max-width: 740px;

  .el-dialog__header {
    @include grotesk(regular);

    padding: 31px 0 27px 0;
    margin-bottom: 38px;
    color: $ebony-clay;
    text-align: center;
    background-image: url("#{$assetsPath}/img/illustrations/team-management/initiative-modal-header.svg");
    border: none;

    p {
      margin-bottom: 7px;
      font-size: 17px;
      line-height: 22px;
      letter-spacing: -0.38px;
    }

    h3 {
      margin-bottom: 2px;
      font-size: 20px;
      font-weight: bold;
      line-height: 30px;
    }
  }

  .el-dialog__headerbtn {
    top: 28px;
    right: 22px;
    width: 22px;
    height: 22px;
  }

  .el-dialog__body {
    min-height: 213px;
    padding: 0 40px 23px 39px;

    p,
    li {
      font-size: 17px;
      color: $ebony-clay;
    }

    p {
      margin-top: 19px;
      line-height: 23px;
      letter-spacing: -0.19px;
    }

    ul {
      position: relative;
      bottom: 3px;

      padding: 0;
      margin-bottom: 0;
      margin-left: 26px;
    }
  }

  .px-button {
    margin-top: 11px;
    margin-left: 1px;
  }

  .px-icon {
    position: absolute;
    top: 40px;
    right: 41px;
  }
}
</style>
