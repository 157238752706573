import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-1355bc9f"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "px-notice-bar__wrapper" }
const _hoisted_2 = { class: "px-notice-bar__content" }
const _hoisted_3 = { class: "px-notice-bar__title" }
const _hoisted_4 = { class: "px-notice-bar__cta" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(`px-notice-bar px-notice-bar--${_ctx.type}`)
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _renderSlot(_ctx.$slots, "content", {}, () => [
          _createElementVNode("h3", _hoisted_3, _toDisplayString(_ctx.title), 1)
        ], true)
      ]),
      _createElementVNode("div", _hoisted_4, [
        _renderSlot(_ctx.$slots, "cta", {}, undefined, true)
      ])
    ])
  ], 2))
}