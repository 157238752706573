<template>
  <div class="px-table">
    <el-table
      :data="props.data.rows"
      style="width: 100%"
      :row-style="rowStyle"
      header-row-class-name="table-header-row"
      header-cell-class-name="table-header-cell"
      :show-summary="props.data.showFooter"
      :summary-method="props.data.footer"
    >
      <el-table-column
        v-for="(col, index) in props.data.columns"
        :key="index"
        :prop="col.prop"
        :label="col.label"
        :width="col.width"
      />
    </el-table>
  </div>
</template>

<script lang="ts" setup>
import { ref } from "vue";
import { defineProps } from "vue";

const props = defineProps<{
  data: {
    rows: Record<string, any>[];
    columns: Record<string, any>[];
    footer: Record<string, any>;
    showFooter: boolean;
  };
}>();

const rowStyle = ref({
  height: "32px",
  fontWeight: 500,
  color: "#0A0A0A",
  size: "14px",
  lineHeight: "20px",
});
</script>

<style lang="scss" scoped>
.px-table {
  ::v-deep .el-table__header {
    color: #8b8fa1;
    height: 32px;
    font-size: 15px;
    font-weight: 500;

    // &-wrapper {
    //   padding-bottom: 6px;
    // }

    .el-table__cell {
      padding-bottom: 4px;
    }
  }

  ::v-deep .el-table__body-wrapper {
    padding: 6px 0;
    border-top: 1px solid #f0f1f5;
    border-bottom: 1px solid #f0f1f5;
  }

  ::v-deep .el-table__row {
    .el-table__cell {
      padding: 0 6px;
    }
    .el-table__cell:nth-child(1) {
      font-weight: 400;
    }
  }

  ::v-deep .el-table__footer-wrapper {
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    color: #8b8fa1;
    padding: 6px 0 6px 0;

    /* stylelint-disable-next-line no-descending-specificity */
    .el-table__cell {
      padding: 0 6px;
    }
  }
}
</style>
