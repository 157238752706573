import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-e80a3a10"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "charts-table__header" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = ["title"]
const _hoisted_4 = { key: 0 }
const _hoisted_5 = {
  key: 0,
  class: "charts-table__gradient"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PxIcon = _resolveComponent("PxIcon")!
  const _component_ElTooltip = _resolveComponent("ElTooltip")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["charts-table", {
      'charts-table--process-reports': _ctx.average,
    }])
  }, [
    _createElementVNode("header", _hoisted_1, [
      _createElementVNode("p", null, _toDisplayString(_ctx.firstColumnHeader), 1),
      _createElementVNode("p", null, _toDisplayString(_ctx.$t("chartsDashboard.table.headers[0]")), 1),
      _createElementVNode("p", null, _toDisplayString(_ctx.$t("chartsDashboard.table.headers[1]")), 1),
      (_ctx.average)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createElementVNode("p", null, _toDisplayString(_ctx.$t("chartsDashboard.table.headers[2]")), 1),
            _createVNode(_component_ElTooltip, {
              placement: "top",
              enterable: false,
              "popper-class": "charts-table__tooltip-popper el-abaca-tooltip"
            }, {
              content: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.tooltipText), 1)
              ]),
              default: _withCtx(() => [
                _createVNode(_component_PxIcon, {
                  size: 14,
                  name: "question"
                })
              ]),
              _: 1
            })
          ]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("main", {
      ref: "chartsTable",
      onScroll: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.checkTableScroll && _ctx.checkTableScroll(...args)))
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.labels, (label, index) => {
        return (_openBlock(), _createElementBlock("div", {
          key: index,
          class: "charts-table__rows"
        }, [
          _createElementVNode("div", { title: label }, [
            _createElementVNode("span", {
              style: _normalizeStyle({
              backgroundColor: _ctx.colors[index],
            })
            }, null, 4),
            _createElementVNode("p", null, _toDisplayString(label), 1)
          ], 8, _hoisted_3),
          _createElementVNode("p", null, _toDisplayString(_ctx.data[index]), 1),
          _createElementVNode("p", null, _toDisplayString(_ctx.percentages[index] + "%"), 1),
          (_ctx.average)
            ? (_openBlock(), _createElementBlock("p", _hoisted_4, _toDisplayString(_ctx.average[index] + "%"), 1))
            : _createCommentVNode("", true)
        ]))
      }), 128))
    ], 544),
    (_ctx.isGradientVisible)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5))
      : _createCommentVNode("", true)
  ], 2))
}