import {
  GenericProvider,
  EProviderFeatures,
  Provider,
  IOptionsType,
} from "@/services/data/generic-provider";
import { baseAPIUrl } from "@/services/utils/utils";
import { ISupporter } from "./supporter.interface";

@Provider(`${baseAPIUrl}/supporters`, [EProviderFeatures.GET])
class SupporterProvider extends GenericProvider<ISupporter> {
  public async get(_id: any, options: IOptionsType = {}): Promise<ISupporter> {
    if (options.id) {
      return super.get(options.id);
    }

    const url = this.buildEndPointUrl(options);
    const request = this.httpClient.get(url);
    const { data } = await this.wrapRequest(request);

    return data.length ? data[0] : data;
  }
}

export const supporterProvider = new SupporterProvider();
