const oneTrustCookieGroups: any = {
  "strictly-necessary": "1",
  performance: "2",
  functional: "3",
  targeting: "4",
};

export const isAllowedCookieGroup = (group: string) => {
  const oneTrustActiveGroups = (window as any).OnetrustActiveGroups || null;
  const cookieGroup = Object.prototype.hasOwnProperty.call(
    oneTrustCookieGroups,
    group,
  )
    ? oneTrustCookieGroups[group]
    : null;

  return oneTrustActiveGroups && cookieGroup
    ? oneTrustActiveGroups.split(",").includes(cookieGroup)
    : false;
};
