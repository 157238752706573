import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, normalizeClass as _normalizeClass, withCtx as _withCtx } from "vue"

const _hoisted_1 = ["textContent"]
const _hoisted_2 = { class: "share-planner-modal__body" }
const _hoisted_3 = {
  key: 0,
  class: "share-planner-modal__header"
}
const _hoisted_4 = ["textContent"]
const _hoisted_5 = ["textContent"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DialogConfirmation = _resolveComponent("DialogConfirmation")!
  const _component_PxModalMessage = _resolveComponent("PxModalMessage")!
  const _component_SharePlannerUsers = _resolveComponent("SharePlannerUsers")!
  const _component_SharePlannerSettings = _resolveComponent("SharePlannerSettings")!
  const _component_ElDialog = _resolveComponent("ElDialog")!

  return (_openBlock(), _createBlock(_component_ElDialog, {
    ref: "modal",
    modelValue: _ctx.innerVisibility,
    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.innerVisibility) = $event)),
    "append-to-body": true,
    "before-close": _ctx.beforeCloseHandler,
    class: _normalizeClass([{ 'share-planner-modal--users': !_ctx.settingsVisibility }, "share-planner-modal"]),
    title: _ctx.shareModalTitle,
    width: "659px"
  }, {
    header: _withCtx(() => [
      _createElementVNode("span", {
        class: "el-dialog__title",
        textContent: _toDisplayString(_ctx.shareModalTitle)
      }, null, 8, _hoisted_1)
    ]),
    default: _withCtx(() => [
      _createVNode(_component_DialogConfirmation, {
        ref: "dialog-confirmation",
        class: "share-planner-modal__overlay-confirm"
      }, null, 512),
      _createVNode(_component_PxModalMessage, { visible: _ctx.hasServerError }, null, 8, ["visible"]),
      _createVNode(_component_PxModalMessage, {
        visible: _ctx.linkWasReset,
        "onUpdate:visible": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.linkWasReset) = $event)),
        timeout: 5000,
        title: _ctx.$t(`companyLists.list.shareModal.messages.linkChanged`),
        type: "success"
      }, null, 8, ["visible", "title"]),
      _createVNode(_component_PxModalMessage, {
        visible: _ctx.passcodeWasChanged,
        "onUpdate:visible": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.passcodeWasChanged) = $event)),
        timeout: 5000,
        title: _ctx.$t(`companyLists.list.shareModal.messages.passcodeChanged`),
        type: "success"
      }, null, 8, ["visible", "title"]),
      _createVNode(_component_PxModalMessage, {
        visible: _ctx.userHasBeenRemoved,
        "onUpdate:visible": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.userHasBeenRemoved) = $event)),
        timeout: 5000,
        title: _ctx.successUserRemovalMessage,
        type: "success"
      }, null, 8, ["visible", "title"]),
      _createElementVNode("div", _hoisted_2, [
        (!_ctx.settingsVisibility)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createElementVNode("h3", {
                class: "share-planner-modal__subtitle",
                textContent: _toDisplayString(_ctx.shareModalUsersCopy.title)
              }, null, 8, _hoisted_4),
              (_ctx.users.length)
                ? (_openBlock(), _createElementBlock("p", {
                    key: 0,
                    class: "share-planner-modal__description",
                    textContent: _toDisplayString(_ctx.shareModalUsersCopy.description)
                  }, null, 8, _hoisted_5))
                : _createCommentVNode("", true)
            ]))
          : _createCommentVNode("", true),
        (!_ctx.settingsVisibility)
          ? (_openBlock(), _createBlock(_component_SharePlannerUsers, {
              key: 1,
              link: _ctx.shareableLink,
              passcode: _ctx.passcode,
              users: _ctx.users
            }, null, 8, ["link", "passcode", "users"]))
          : _createCommentVNode("", true),
        (_ctx.settingsVisibility)
          ? (_openBlock(), _createBlock(_component_SharePlannerSettings, {
              key: 2,
              link: _ctx.shareableLink,
              passcode: _ctx.passcode,
              class: "share-planner-modal__appendix",
              onSave: _ctx.saveSettingsHandler
            }, null, 8, ["link", "passcode", "onSave"]))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }, 8, ["modelValue", "before-close", "class", "title"]))
}