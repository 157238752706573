import {
  GenericProvider,
  EProviderFeatures,
} from "@/services/data/generic-provider";

import { IOfferingCategory } from "./offering.interface";

class OfferingCategoryProvider extends GenericProvider<IOfferingCategory> {
  constructor() {
    super(`/offering/categoriestypes`, [EProviderFeatures.LIST]);
  }
}

export const offeringCategoryProvider = new OfferingCategoryProvider();
