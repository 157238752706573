import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AssessmentDesktop = _resolveComponent("AssessmentDesktop")!
  const _component_AssessmentMobile = _resolveComponent("AssessmentMobile")!
  const _component_RenderlessSupporterAssessment = _resolveComponent("RenderlessSupporterAssessment")!

  return (_openBlock(), _createBlock(_component_RenderlessSupporterAssessment, {
    "end-action": _ctx.endAction || _ctx.defaultEndAction
  }, {
    default: _withCtx((scope) => [
      _createElementVNode("div", null, [
        (_ctx.$screen.mdUp)
          ? (_openBlock(), _createBlock(_component_AssessmentDesktop, {
              key: 0,
              "current-category": scope.currentCategory,
              "is-first-interaction": scope.isFirstInteraction,
              levels: scope.levels,
              "on-level-change": scope.onLevelChange,
              "on-save": scope.onSaveHandler,
              "range-restriction": scope.rangeRestriction,
              "selected-category": scope.selectedCategory,
              "selected-step": scope.selectedStep
            }, null, 8, ["current-category", "is-first-interaction", "levels", "on-level-change", "on-save", "range-restriction", "selected-category", "selected-step"]))
          : _createCommentVNode("", true),
        (_ctx.$screen.smDown)
          ? (_openBlock(), _createBlock(_component_AssessmentMobile, {
              key: 1,
              "current-category": scope.currentCategory,
              "is-first-interaction": scope.isFirstInteraction,
              levels: scope.levels,
              "on-level-change": scope.onLevelChange,
              "on-save": scope.onSaveHandler,
              "range-restriction": scope.rangeRestriction,
              "selected-category": scope.selectedCategory,
              "selected-step": scope.selectedStep
            }, null, 8, ["current-category", "is-first-interaction", "levels", "on-level-change", "on-save", "range-restriction", "selected-category", "selected-step"]))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }, 8, ["end-action"]))
}