<template>
  <div class="account-settings-navigation">
    <template v-if="$screen.mdUp">
      <PxListHeader
        :title="$t('profile.accountSettingsPage.navigation.title')"
      />
      <ul>
        <li
          v-for="tab in tabs"
          :key="tab.key"
          :class="{
            'account-settings-navigation__tabs--active':
              selectedTab === tab.key,
          }"
          :data-testid="`account-settings-navigation-tab-${tab.key}`"
          class="account-settings-navigation__tabs"
          @click="updateSelectedTab(tab.key)"
        >
          {{ tab.label }}
        </li>
      </ul>
    </template>
    <template v-else>
      <PxTabBar
        v-model="innerSelectedTab"
        :items="tabs"
        data-testid="account-settings-navigation-tabs-mobile"
      />
      <PxButton
        class="account-settings-navigation__back-button"
        v-bind="backButtonProps"
        @click="onBackButtonClick"
      />
    </template>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import PxListHeader from "@/components/px-list-header/px-list-header.vue";
import PxTabBar from "@/components/px-tab-bar/px-tab-bar.vue";
import {
  EPxButtonSize,
  EPxButtonVariant,
} from "@/components/px-button/px-button.types";
import { ROUTE_USER_ACCOUNT } from "@/modules/profile/services/router/routes-names";

export enum EAccountSettingsNavigationTabs {
  INFO = "info",
  SUBSCRIPTION = "subscription",
}

export default defineComponent({
  name: "AccountSettingsNavigation",

  components: {
    PxListHeader,
    PxTabBar,
  },

  props: {
    selectedTab: {
      type: String,
      default: EAccountSettingsNavigationTabs.INFO,
      validator: (value: EAccountSettingsNavigationTabs) => {
        return Object.values(EAccountSettingsNavigationTabs).includes(value);
      },
    },
  },
  data() {
    return {
      innerSelectedTab: this.selectedTab,
      tabs: [
        {
          key: EAccountSettingsNavigationTabs.INFO,
          label: this.$t("profile.accountSettingsPage.navigation.tabs[0]"),
        },
        {
          key: EAccountSettingsNavigationTabs.SUBSCRIPTION,
          label: this.$t("profile.accountSettingsPage.navigation.tabs[1]"),
        },
      ],
    };
  },

  computed: {
    backButtonProps(): any {
      return {
        icon: "arrow--left-dark",
        size: EPxButtonSize.XX_SMALL,
        variant: EPxButtonVariant.COMPACT,
      };
    },
  },

  watch: {
    innerSelectedTab: {
      handler(newTab: string, oldTab: string) {
        if (newTab === oldTab) return;

        this.$emit("update:selected-tab", newTab);
      },
    },

    selectedTab: {
      handler(newTab: string, oldTab: string) {
        if (newTab === oldTab) return;

        this.innerSelectedTab = newTab;
      },
    },
  },

  methods: {
    updateSelectedTab(tabKey: string) {
      this.innerSelectedTab = tabKey;
    },

    onBackButtonClick() {
      this.$router.push({
        name: ROUTE_USER_ACCOUNT,
      });
    },
  },
});
</script>

<style lang="scss" scoped>
.account-settings-navigation {
  min-height: 344px;

  @include breakpoint-down(lg) {
    min-height: auto;
  }

  @include breakpoint-up(md) {
    background-color: $white;

    border: 1px solid $turkish-angora;
    border-radius: 4px;
    box-shadow: 0 4px 8px -4px rgba(139, 143, 161, 0.2);
  }

  .px-list-header {
    padding: 13px 0 17px 23px;
  }

  :deep() .px-menu__item {
    padding: 8px 0 16px 0;

    font-size: 16px;
    letter-spacing: -0.6px;

    &-link {
      position: relative;
      right: 2px;
      top: 1px;
    }
  }

  ul {
    padding: 16px 0;
  }

  &__tabs {
    @include grotesk(regular);

    padding: 10px 0 10px 23px;
    margin: 0;
    list-style-type: none;
    font-size: 16px;
    line-height: 1.25;
    color: $gun-powder;
    letter-spacing: -0.4px;

    cursor: pointer;

    &--active {
      position: relative;

      pointer-events: none;

      @include grotesk(semiBold);
      color: $bluish;
      letter-spacing: -0.1px;
    }

    &--active::before {
      position: absolute;
      left: 0;
      top: 0;
      content: "";
      display: block;
      width: 4px;
      height: 100%;
      background-color: $bluish;
      border-top-right-radius: 2px;
      border-bottom-right-radius: 2px;
    }
  }

  &__back-button.is-compact {
    min-width: 32px;
    margin: 24px 0 25px 16px;
    border-radius: 50%;
    background: transparent;

    :deep() .px-icon {
      width: 14px;
      height: 14px;
    }
  }
}
</style>
