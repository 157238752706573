import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, renderSlot as _renderSlot, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-00d3b3e6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "px-page-header" }
const _hoisted_2 = { class: "px-page-header__wrapper" }
const _hoisted_3 = { class: "px-page-header__content" }
const _hoisted_4 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PxButton = _resolveComponent("PxButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.showBackButton)
        ? (_openBlock(), _createBlock(_component_PxButton, _mergeProps({
            key: 0,
            class: "px-page-header__back-button"
          }, _ctx.$options.static.backButton, {
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('back-button-clicked')))
          }), null, 16))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h1", null, _toDisplayString(_ctx.title), 1),
        (_ctx.subtitle)
          ? (_openBlock(), _createElementBlock("p", _hoisted_4, _toDisplayString(_ctx.subtitle), 1))
          : _createCommentVNode("", true)
      ])
    ]),
    _renderSlot(_ctx.$slots, "action", { class: "px-page-header__action" }, undefined, true)
  ]))
}