import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-75835a9e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "px-navigation-tab-item__title" }
const _hoisted_2 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PxIcon = _resolveComponent("PxIcon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([{
      'px-navigation-tab-item--list-format': _ctx.listFormat,
    }, "px-navigation-tab-item"])
  }, [
    _createElementVNode("div", {
      class: _normalizeClass([{
        'px-navigation-tab-item--active': _ctx.active,
        'px-navigation-tab-item--icon-right': _ctx.iconPosition === 'right',
      }, "px-navigation-tab-item__container"]),
      onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onTabClick && _ctx.onTabClick(...args)), ["stop"]))
    }, [
      (_ctx.icon)
        ? (_openBlock(), _createBlock(_component_PxIcon, {
            key: 0,
            name: _ctx.icon,
            size: _ctx.iconSize
          }, null, 8, ["name", "size"]))
        : _createCommentVNode("", true),
      _createElementVNode("h3", _hoisted_1, [
        (_ctx.showNumber)
          ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.number) + ".", 1))
          : _createCommentVNode("", true),
        _createTextVNode(" " + _toDisplayString(_ctx.title), 1)
      ])
    ], 2),
    (_ctx.active)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass([{
        'px-navigation-tab-item__border-left': _ctx.listFormat,
      }, "px-navigation-tab-item__border"])
        }, null, 2))
      : _createCommentVNode("", true)
  ], 2))
}