import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LevelUpdateModalLevelsInfo = _resolveComponent("LevelUpdateModalLevelsInfo")!
  const _component_LevelUpdateModalLevelsExample = _resolveComponent("LevelUpdateModalLevelsExample")!
  const _component_ElDialog = _resolveComponent("ElDialog")!

  return (_openBlock(), _createBlock(_component_ElDialog, {
    ref: "modal",
    modelValue: _ctx.innerVisibility,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.innerVisibility) = $event)),
    "close-on-click-modal": false,
    "show-close": false,
    width: _ctx.modalWidth,
    class: "level-update-modal"
  }, {
    default: _withCtx(() => [
      (!_ctx.examplePageVisible)
        ? (_openBlock(), _createBlock(_component_LevelUpdateModalLevelsInfo, {
            key: 0,
            "current-level": _ctx.currentLevel,
            "new-level": _ctx.newLevel,
            onCloseModal: _cache[0] || (_cache[0] = ($event: any) => (_ctx.innerVisibility = false))
          }, null, 8, ["current-level", "new-level"]))
        : (_openBlock(), _createBlock(_component_LevelUpdateModalLevelsExample, {
            key: 1,
            "current-level": _ctx.currentLevel,
            "new-level": _ctx.newLevel
          }, null, 8, ["current-level", "new-level"]))
    ]),
    _: 1
  }, 8, ["modelValue", "width"]))
}