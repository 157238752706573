import { IBaseState } from "@/services/store/store-types";
import { IUserMetadata } from "@/modules/authentication/services/data/user-metadata/user-metadata.types";

export interface IUserMetadataState extends IBaseState {
  data: Array<IUserMetadata>;
}

export enum EUserMetadataActions {
  FETCH = "auth/metadata/fetch",
  SET = "auth/metadata/set",
  RESET = "auth/metadata/reset",
}

export enum EUserMetadataMutations {
  SET_ERROR = "SET_ERROR",
  SET_LOADING = "SET_LOADING",
  SET_DATA = "SET_DATA",
  APPEND = "APPEND",
}

export enum EUserMetadataGetters {
  GET = "auth/metadata/get",
}

/**
 * Interface that represents the payload structure to create/update
 * a user metadata entry.
 */
export interface IUserMetadataSetPayload {
  /**
   * Name of the key which the value must be store.
   */
  key: string;

  /**
   * Value of the metadata entry.
   */
  value: string;
}
