<template>
  <div ref="datePicker" class="px-date-picker">
    <div v-if="label" class="px-date-picker__label">
      {{ label }}
      <ElTooltip
        v-if="tooltip"
        popper-class="px-date-picker__tooltip-popper el-abaca-tooltip"
      >
        <template #content>
          <div v-text="tooltip" />
        </template>
        <PxIcon
          :size="14"
          class="px-date-picker__tooltip-icon"
          name="question"
        />
      </ElTooltip>
    </div>
    <ElDatePicker
      ref="elDatePicker"
      v-model="innerValue"
      :clearable="false"
      :format="dateFormat"
      :placeholder="$t('common.components.datePicker.placeholder')"
      align="center"
      popper-class="px-date-picker__picker-popper"
      type="date"
      @blur="onDatePickerBlur()"
      @focus="onDatePickerFocus()"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";

export default defineComponent({
  name: "PxDatePicker",

  props: {
    value: {
      type: [Date, null] as PropType<Date | null>,
      default: null,
    },

    label: {
      type: String as () => string | null,
      default: null,
    },

    tooltip: {
      type: String as () => string | null,
      default: null,
    },

    dateFormat: {
      type: String,
      default: "D MMMM, YYYY",
    },
  },

  data() {
    return {
      innerValue: null as null | Date,
    };
  },

  watch: {
    value(newValue: Date | null) {
      if (this.innerValue !== newValue) {
        this.innerValue = newValue;
      }
    },

    innerValue(newValue: Date | null) {
      if (this.value !== newValue) {
        this.$emit("change", newValue);
        this.$emit("update:value", newValue);
      }
    },
  },

  created() {
    this.innerValue = this.value;
  },

  methods: {
    onDatePickerFocus() {
      (this.$refs.datePicker as HTMLElement).classList.add(
        "px-date-picker--open",
      );
    },

    onDatePickerBlur() {
      (this.$refs.datePicker as HTMLElement).classList.remove(
        "px-date-picker--open",
      );
    },
  },
});
</script>

<style lang="scss">
.px-date-picker__tooltip-popper {
  width: 340px;
  text-transform: none;
}

.px-date-picker .el-date-editor.el-input input:hover {
  cursor: pointer;
}

.px-date-picker__picker-popper.el-popper {
  margin: 0;
  border-color: $mischka;
  border-radius: $--border-radius-small;
  box-shadow: 0 0 4px 0 rgba(59, 135, 230, 0.1);

  .el-popper__arrow {
    display: none;
  }

  .el-date-picker__header {
    padding: 13px 15px;
    margin: 0;
  }

  .el-date-picker__prev-btn button {
    transform: rotate(90deg);
  }

  .el-date-picker__next-btn button {
    transform: rotate(-90deg);
  }

  .el-picker-panel__icon-btn {
    width: 24px;
    height: 24px;
    padding: 0;
    margin: 2px 0 0;
    background-image: url("#{$assetsPath}/img/icons/arrow.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 24px;
    outline: none;

    &.d-arrow-left,
    &.d-arrow-right {
      background-image: url("#{$assetsPath}/img/icons/double-arrow.svg");
    }

    i {
      display: none;
    }
  }

  .el-picker-panel__content {
    margin-top: 0;
  }

  .el-date-table th {
    padding-top: 4px;
    padding-bottom: 6px;
    font-size: 14px;
    color: $manatee;
  }

  .el-date-table td {
    color: $ebony-clay;

    span {
      font-size: 13px;
    }

    &.next-month,
    &.prev-month {
      color: $manatee;
    }

    &.today:not(.current) span {
      color: $malibu;
    }

    &.current:not(.disabled) span {
      background-color: $malibu;
      @include grotesk(bold);
    }
  }

  .el-date-table tr:not(.el-date-table__row) + tr.el-date-table__row td {
    padding-top: 15px;
  }

  .el-year-table,
  .el-month-table {
    font-size: 13px;
    color: $ebony-clay;

    td .cell:hover,
    td.current:not(.disabled) .cell {
      color: $malibu;
    }

    td.today:not(.current) .cell {
      color: $malibu;
    }
  }
}
</style>

<style lang="scss" scoped>
.px-date-picker {
  &__label {
    @include grotesk(semiBold);

    display: flex;
    margin-bottom: 6px;
    font-size: 12px;
    text-transform: uppercase;
  }

  &__tooltip-icon {
    margin-left: 8px;
  }

  :deep(.el-date-editor.el-input) {
    width: 100%;

    .el-input__inner {
      padding: 0 46px 0 48px;
      font-size: 14px;
    }

    .el-input__prefix {
      background-image: url("#{$assetsPath}/img/icons/calendar.svg");
      background-repeat: no-repeat;
      background-position: center;
    }

    .el-input__prefix > i {
      visibility: hidden;
    }

    .el-input__suffix {
      top: 12px;
      right: 15px;
      width: 16px;
      height: 16px;
      background-image: url("#{$assetsPath}/img/icons/arrow.svg");
      background-repeat: no-repeat;
      background-position: center;
      transition: $--all-transition;
    }

    .el-input__suffix-inner {
      display: none;
    }
  }

  &--open :deep(.el-date-editor.el-input .el-input__suffix) {
    transform: rotate(180deg);
  }
}
</style>
