<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="173"
    height="173"
    viewBox="0 0 173 173"
  >
    <defs>
      <linearGradient id="viral-graph-8-c" x1="50%" x2="50%" y1="0%" y2="100%">
        <stop offset="0%" stop-color="#58A0F8" class="linear-gradient-color1" />
        <stop
          offset="100%"
          stop-color="#2176DD"
          class="linear-gradient-color2"
        />
      </linearGradient>
      <path
        id="viral-graph-8-b"
        d="M154.496754,45.5190212 L164.132104,57.1874178 C156.622023,63.3820418 150.587228,71.2019088 146.525297,80.0410954 L132.769263,73.7267629 C137.78632,62.8091229 145.232137,53.1608587 154.496754,45.5190212 Z"
      />
      <filter
        id="viral-graph-8-a"
        width="163.8%"
        height="157.9%"
        x="-31.9%"
        y="-23.2%"
        filterUnits="objectBoundingBox"
      >
        <feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur
          in="shadowOffsetOuter1"
          result="shadowBlurOuter1"
          stdDeviation="3"
        />
        <feComposite
          in="shadowBlurOuter1"
          in2="SourceAlpha"
          operator="out"
          result="shadowBlurOuter1"
        />
        <feColorMatrix
          in="shadowBlurOuter1"
          values="0 0 0 0 0.333333333 0 0 0 0 0.623529412 0 0 0 0 0.976470588 0 0 0 0.5 0"
          class="color-matrix"
        />
      </filter>
      <linearGradient id="viral-graph-8-f" x1="50%" x2="50%" y1="0%" y2="100%">
        <stop offset="0%" stop-color="#64A9FE" class="linear-gradient-color3" />
        <stop
          offset="100%"
          stop-color="#1865C4"
          class="linear-gradient-color2"
        />
      </linearGradient>
      <path
        id="viral-graph-8-e"
        d="M78.3375,134.83871 C109.986868,134.83871 135.64375,109.19618 135.64375,77.5645161 C135.64375,45.9328525 109.986868,20.2903226 78.3375,20.2903226 C46.6881321,20.2903226 21.03125,45.9328525 21.03125,77.5645161 C21.03125,109.19618 46.6881321,134.83871 78.3375,134.83871 Z M78.3375,134.83871 C109.986868,134.83871 135.64375,109.19618 135.64375,77.5645161 C135.64375,45.9328525 109.986868,20.2903226 78.3375,20.2903226 C46.6881321,20.2903226 21.03125,45.9328525 21.03125,77.5645161 C21.03125,109.19618 46.6881321,134.83871 78.3375,134.83871 Z M78.3375,134.83871 C109.986868,134.83871 135.64375,109.19618 135.64375,77.5645161 C135.64375,45.9328525 109.986868,20.2903226 78.3375,20.2903226 C46.6881321,20.2903226 21.03125,45.9328525 21.03125,77.5645161 C21.03125,109.19618 46.6881321,134.83871 78.3375,134.83871 Z"
      />
      <filter
        id="viral-graph-8-d"
        width="141%"
        height="141%"
        x="-20.5%"
        y="-18.8%"
        filterUnits="objectBoundingBox"
      >
        <feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur
          in="shadowOffsetOuter1"
          result="shadowBlurOuter1"
          stdDeviation="7.5"
        />
        <feComposite
          in="shadowBlurOuter1"
          in2="SourceAlpha"
          operator="out"
          result="shadowBlurOuter1"
        />
        <feColorMatrix
          in="shadowBlurOuter1"
          values="0 0 0 0 0.325490196 0 0 0 0 0.584313725 0 0 0 0 0.894117647 0 0 0 0.5 0"
          class="color-matrix"
        />
      </filter>
      <linearGradient
        id="viral-graph-8-g"
        x1="50%"
        x2="50%"
        y1="0%"
        y2="98.69%"
      >
        <stop offset="0%" stop-color="#FFF" />
        <stop
          offset="100%"
          stop-color="#D5E3F4"
          stop-opacity="0"
          class="linear-gradient-color2"
        />
      </linearGradient>
    </defs>
    <g fill="none" fill-rule="evenodd" transform="translate(8 18)">
      <path
        fill="#E5E8EF"
        fill-rule="nonzero"
        d="M139.962437,102.814507 C142.583007,96.3279936 144.166209,89.4467517 144.63563,82.3612903 L155.354248,82.3612903 C154.867579,90.8799289 152.992826,99.1628157 149.828602,106.953543 L139.962438,102.814507 Z M144.767362,79.3612904 C144.777983,78.8712457 144.783306,78.3803649 144.783306,77.8887097 L155.481694,77.8887097 C155.481694,78.380211 155.477096,78.8710873 155.46792,79.3612904 L144.767366,79.3612903 Z"
      />
      <path
        class="linear-gradient-color4"
        fill="#7CB8FF"
        fill-rule="nonzero"
        d="M137.554254,47.6761031 C133.805185,40.3139166 128.765664,33.8057491 122.766957,28.4031678 L129.918533,20.4464324 C137.036575,26.8553871 142.991417,34.6057075 147.372174,43.3854668 L137.554257,47.676108 Z M138.855917,50.3812259 L148.659877,46.0966896 C149.11483,47.1045739 149.549456,48.1250095 149.963174,49.1575564 L140.03229,53.1366305 C139.659656,52.2066197 139.267335,51.2880096 138.855899,50.3812334 Z M120.492719,26.4456217 C113.336503,20.5604304 104.96942,16.1744648 95.8931969,13.6687438 L98.7099171,3.34654687 C109.407937,6.29504228 119.258048,11.4946365 127.65026,18.4822505 L120.492719,26.4456217 Z M92.9835317,12.9357302 C88.1835918,11.8411151 83.2059077,11.2669355 78.12125,11.2669355 C73.1439521,11.2669355 68.2657152,11.8169064 63.5540738,12.8683106 L61.0201274,2.47161303 C66.5487934,1.22239088 72.2762841,0.568548387 78.12125,0.568548387 C84.1751495,0.568548387 90.0984359,1.27006829 95.8023769,2.60574636 L92.9835317,12.9357302 Z M60.6415625,13.5875893 C52.3192358,15.8433088 44.5780691,19.6800342 37.8078542,24.8176203 L31.1254037,16.4591513 C39.1057292,10.3589625 48.2591751,5.8211588 58.1072967,3.18958083 L60.6415625,13.5875893 Z M35.4593928,26.6843822 C28.4839439,32.4895835 22.6664995,39.7387526 18.4807993,48.091411 L8.83547913,43.4598448 C13.7294518,33.6368661 20.5670849,25.1224959 28.7776995,18.3268603 L35.4593928,26.6843822 Z M11.46423,77.3612903 L0.759577506,77.3612903 C0.832511447,66.4706172 3.16383828,55.8981972 7.54777313,46.1694499 L17.1997224,50.8041992 C13.5046547,59.0924146 11.5361331,68.0840275 11.4642307,77.3612903 Z M11.5071998,80.3612907 C11.8036338,88.4902137 13.5602883,96.3748525 16.6605955,103.729926 L6.80777614,107.911659 C3.14929041,99.2420233 1.09817923,89.9380488 0.796686673,80.3612906 L11.5071899,80.3612903 Z"
      />
      <g fill-rule="nonzero" transform="scale(-1 1) rotate(-25 0 732.398)">
        <use
          fill="#000"
          filter="url(#viral-graph-8-a)"
          xlink:href="#viral-graph-8-b"
        />
        <use fill="url(#viral-graph-8-c)" xlink:href="#viral-graph-8-b" />
      </g>
      <use
        fill="#000"
        filter="url(#viral-graph-8-d)"
        xlink:href="#viral-graph-8-e"
      />
      <ellipse
        cx="78.338"
        cy="77.565"
        rx="56.819"
        ry="56.79"
        fill="url(#viral-graph-8-f)"
        stroke="#181818"
        stroke-linejoin="round"
        stroke-opacity=".2"
      />
      <ellipse
        cx="78.338"
        cy="77.565"
        stroke="url(#viral-graph-8-g)"
        stroke-width="2"
        opacity=".402"
        rx="49.819"
        ry="49.79"
      />
      <path
        fill="#FFF"
        d="M78.2724609,97.6152344 C69.9667969,97.6152344 62.2148438,93.277832 62.2148438,84.5107422 C62.2148438,79.6196289 64.6757812,75.7744141 68.4902344,73.4980469 C65.7832031,71.2216797 64.3989258,68.1455078 64.3989258,64.730957 C64.3989258,57.1943359 71.0742188,52.8876953 78.2724609,52.8876953 C85.4399414,52.8876953 92.1152344,57.2558594 92.1152344,64.6694336 C92.1152344,68.2685547 90.5463867,71.3447266 88.0239258,73.4980469 C92.1152344,75.8974609 94.3300781,79.7426758 94.3300781,84.4492188 C94.3300781,93.3393555 86.5166016,97.6152344 78.2724609,97.6152344 Z M78.3955078,70.6064453 C82.0561523,70.6064453 84.7016602,68.5761719 84.7016602,65.0078125 C84.7016602,61.4086914 81.9946289,59.378418 78.3955078,59.378418 C74.980957,59.378418 72.0893555,61.1625977 72.0893555,64.8847656 C72.0893555,68.3300781 74.550293,70.6064453 78.3955078,70.6064453 Z M78.2109375,90.9399414 C82.7944336,90.9399414 86.578125,88.7558594 86.578125,84.1416016 C86.578125,79.6811523 83.0097656,77.2202148 78.2109375,77.2202148 C73.3505859,77.2202148 69.8129883,79.6811523 69.8129883,84.0800781 C69.8129883,88.7558594 73.6582031,90.9399414 78.2109375,90.9399414 Z"
      />
    </g>
  </svg>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "Level8",
});
</script>
