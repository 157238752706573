import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-b7b04c1e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "px-minimal-navbar" }
const _hoisted_2 = { class: "px-minimal-navbar__wrapper" }
const _hoisted_3 = { class: "px-minimal-navbar__center" }
const _hoisted_4 = ["src"]
const _hoisted_5 = ["href"]
const _hoisted_6 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RouterLink = _resolveComponent("RouterLink")!

  return (_openBlock(), _createElementBlock("nav", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (_ctx.$user.isLogged())
          ? (_openBlock(), _createBlock(_component_RouterLink, {
              key: 0,
              to: _ctx.logoLink,
              class: "px-minimal-navbar__logo-link"
            }, {
              default: _withCtx(() => [
                _createElementVNode("img", {
                  src: _ctx.mainLogo,
                  alt: "VIRAL Logo",
                  class: "px-minimal-navbar__logo",
                  height: "32",
                  width: "92"
                }, null, 8, _hoisted_4)
              ]),
              _: 1
            }, 8, ["to"]))
          : (_openBlock(), _createElementBlock("a", {
              key: 1,
              href: _ctx.logoLink,
              class: "px-minimal-navbar__logo-link"
            }, [
              _createElementVNode("img", {
                src: _ctx.mainLogo,
                alt: "VIRAL Logo",
                class: "px-minimal-navbar__logo",
                height: "32",
                width: "92"
              }, null, 8, _hoisted_6)
            ], 8, _hoisted_5))
      ])
    ])
  ]))
}