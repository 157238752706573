export const MATCHING_ONBOARDING_COMPLETION = "matching.onboardingCompletion";
export const MATCHING_FROM_ONBOARDING = "matching.fromOnboarding";
export const MATCHING_SELECTED_TAB = "matching.selectedTab";
export const MATCHING_PENDING_SELECTED_TAB = "matchingPending.selectedTab";
export const MATCHING_SELECTED_FILTERS = "matching.selectedFilters";
export const MATCHING_HAS_SYNCED_FILTERS = "matching.hasSyncedFilters";

export const MATCHING_INITIAL_INTEREST = 0;
export const MATCHING_HAS_INTEREST = 1;
export const MATCHING_CONNECTED_INTEREST = 2;
export const MATCHING_HAD_CONNECTED_INTEREST = 3;

export const MATCHING_BADGE_MEMBER = "member";
export const MATCHING_BADGE_DIRECTORY_LISTING = "directoryListing";
export const MATCHING_BADGE_COMPLETED_QUESTION = "completedQuestionsFlow";
export const MATCHING_BADGE_MILESTONE_PLANNER = "milestonePlanner";

export const MATCHING_USER_TYPES = ["entrepreneur", "supporter"];
export const MATCHING_LOWEST_LEVEL = 1;
export const MATCHING_BADGES_LIST = [
  MATCHING_BADGE_MEMBER,
  MATCHING_BADGE_DIRECTORY_LISTING,
  MATCHING_BADGE_COMPLETED_QUESTION,
  MATCHING_BADGE_MILESTONE_PLANNER,
];
