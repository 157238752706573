import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "milestone-tooltip__header" }
const _hoisted_2 = { class: "level-number" }
const _hoisted_3 = { class: "category-name" }
const _hoisted_4 = { class: "milestone-tooltip__content" }
const _hoisted_5 = { class: "milestone-description" }
const _hoisted_6 = { class: "date" }
const _hoisted_7 = {
  key: 2,
  class: "future-milestone-note"
}
const _hoisted_8 = {
  key: 2,
  class: "future-milestone-note"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MilestoneTooltipProgressIndicator = _resolveComponent("MilestoneTooltipProgressIndicator")!
  const _component_CompleteMilestoneInfoIndicator = _resolveComponent("CompleteMilestoneInfoIndicator")!
  const _component_CriticalMilestoneIndicator = _resolveComponent("CriticalMilestoneIndicator")!
  const _component_MilestoneTooltipButtons = _resolveComponent("MilestoneTooltipButtons")!

  return (_openBlock(), _createElementBlock("div", {
    style: _normalizeStyle({ '--category-color': _ctx.categoryColor }),
    class: "milestone-tooltip"
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", {
        style: _normalizeStyle({ backgroundColor: _ctx.categoryColor }),
        class: "category-indicator"
      }, null, 4),
      _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.levelNumber), 1),
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.categoryName), 1)
    ]),
    _createVNode(_component_MilestoneTooltipProgressIndicator, {
      "category-color": _ctx.categoryColor,
      "is-future-milestone": _ctx.isFutureMilestone,
      "is-milestone-complete": _ctx.isMilestoneComplete,
      "is-milestone-in-progress": _ctx.isMilestoneInProgress,
      "is-milestone-planned": _ctx.isMilestonePlanned
    }, null, 8, ["category-color", "is-future-milestone", "is-milestone-complete", "is-milestone-in-progress", "is-milestone-planned"]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.milestoneDescription), 1),
      (!_ctx.simplified)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            (_ctx.isMilestoneComplete)
              ? (_openBlock(), _createBlock(_component_CompleteMilestoneInfoIndicator, {
                  key: 0,
                  "has-evidence": _ctx.hasEvidence,
                  "is-visitor": _ctx.isVisitor,
                  "has-action": "",
                  onActionClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('complete')))
                }, null, 8, ["has-evidence", "is-visitor"]))
              : _createCommentVNode("", true),
            (_ctx.showButtons)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                  (_ctx.isMilestoneCritical)
                    ? (_openBlock(), _createBlock(_component_CriticalMilestoneIndicator, { key: 0 }))
                    : _createCommentVNode("", true),
                  _createVNode(_component_MilestoneTooltipButtons, {
                    "is-future-milestone": _ctx.isFutureMilestone,
                    "is-milestone-in-progress": _ctx.isMilestoneInProgress,
                    "is-milestone-planned": _ctx.isMilestonePlanned,
                    "is-visitor": _ctx.isVisitor,
                    onComplete: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('complete'))),
                    onPlan: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('plan')))
                  }, null, 8, ["is-future-milestone", "is-milestone-in-progress", "is-milestone-planned", "is-visitor"]),
                  (_ctx.targetDate)
                    ? (_openBlock(), _createElementBlock("div", {
                        key: 1,
                        class: _normalizeClass([{ 'target-date--critical': _ctx.isMilestoneCritical }, "target-date"])
                      }, [
                        _createTextVNode(_toDisplayString(_ctx.$t("milestonePlanner.milestone.targetDate")) + ": ", 1),
                        _createElementVNode("strong", _hoisted_6, _toDisplayString(_ctx.targetDate), 1)
                      ], 2))
                    : _createCommentVNode("", true),
                  (_ctx.isFutureMilestone && !_ctx.isVisitor)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_7, _toDisplayString(_ctx.$t("milestonePlanner.milestone.futureMilestoneTooltipNote")), 1))
                    : _createCommentVNode("", true)
                ], 64))
              : _createCommentVNode("", true),
            (_ctx.isVisitor && !_ctx.isMilestoneComplete && !_ctx.isMilestonePlanned)
              ? (_openBlock(), _createElementBlock("div", _hoisted_8, _toDisplayString(_ctx.$t("milestonePlanner.milestone.noPlanOrCompletionTooltipNote")), 1))
              : _createCommentVNode("", true)
          ], 64))
        : _createCommentVNode("", true)
    ])
  ], 4))
}