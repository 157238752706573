<template>
  <div class="features-showcase">
    <h2 class="features-showcase__headline">
      {{ $t("supporters.results.featuresShowcase.headline") }}
    </h2>
    <div class="features-showcase__list">
      <div
        v-for="(item, feature) in featuresList"
        :key="feature"
        :class="`features-showcase__item--${feature}`"
        class="features-showcase__item"
      >
        <div class="features-showcase__content">
          <h3 class="features-showcase__item-title">
            {{ item.title }}
          </h3>
          <div
            class="features-showcase__item-description"
            v-text="item.description"
          />
        </div>
        <picture v-if="item.thumbnail" class="features-showcase__thumbnail">
          <source
            v-if="item.thumbnail.mobile"
            :srcset="`${item.thumbnail.mobile} 1x`"
            media="(max-width: 767px)"
          />
          <source
            v-if="item.thumbnail.mobileRetina"
            :srcset="`${item.thumbnail.mobileRetina} 2x`"
            media="(max-width: 767px)"
          />
          <source
            v-if="item.thumbnail.retina"
            :srcset="`${item.thumbnail.original} 1x, ${item.thumbnail.retina} 2x`"
          />
          <img :alt="item.title" :src="item.thumbnail.original" />
        </picture>
      </div>
    </div>
    <FeaturesShowcaseCTA />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import FeaturesShowcaseCTA from "@/modules/supporters/components/features-showcase-cta/features-showcase-cta.vue";

export interface IFeature {
  title: string;
  description: string;
  thumbnail: {
    original: string;
    retina?: string;
    mobile: string;
    mobileRetina?: string;
  };
}

export default defineComponent({
  name: "FeaturesShowcase",

  components: {
    FeaturesShowcaseCTA,
  },

  computed: {
    featuresList() {
      return this.$tm("supporters.results.featuresShowcase.list") as {
        "entrep-detailed-results": IFeature;
        "match-with-companies": IFeature;
        "track-companies": IFeature;
      };
    },
  },
});
</script>

<style lang="scss" scoped>
.features-showcase__headline {
  padding: 0 60px;
  font-size: to-rem(22px);
  line-height: to-rem(26px);
  text-align: center;

  @include breakpoint-up(md) {
    font-size: to-rem(30px);
    line-height: to-rem(40px);
  }
}

.features-showcase__list {
  margin: 17px auto 0;
  counter-reset: item;
  border: solid 1px $pale-grey;
  border-radius: 2px;
  box-shadow: 0 2px 11px 0 $black-5;

  @include breakpoint-up(md) {
    margin: 30px auto 0;
  }
}

.features-showcase__item {
  position: relative;
  display: flex;
  flex-flow: column-reverse wrap;
  justify-content: space-between;
  padding: 40px 5px 22px;

  text-align: center;
  background-color: #fff;

  @include breakpoint-up(sm) {
    padding-bottom: 0;
  }

  @include breakpoint-up(md) {
    flex-flow: row wrap;
    padding: 40px 30px 0;
    text-align: left;
  }

  @include breakpoint-up(lg) {
    padding: 40px 70px 0;
  }

  &:nth-child(even) {
    background-color: #f8fbff;

    @include breakpoint-up(md) {
      flex-direction: row-reverse;
    }
  }

  &:last-child {
    padding-bottom: 42px;
  }
}

.features-showcase__content {
  max-width: 390px;
  padding-top: 22px;
  margin: auto;

  @include breakpoint-up(md) {
    flex-basis: 320px;
    margin: 0;
  }

  @include breakpoint-up(lg) {
    flex-basis: 390px;
    padding-top: 19px;
  }
}

.features-showcase__thumbnail img {
  max-width: 100%;
}

.features-showcase__item:not(:first-child) {
  padding-top: 68px;

  @include breakpoint-up(sm) {
    padding-top: 100px;
  }

  @include breakpoint-up(md) {
    padding-top: 60px;
  }

  .features-showcase__content {
    padding-top: 25px;

    @include breakpoint-up(md) {
      padding-top: 67px;
    }
  }
}

.features-showcase__item:nth-child(even) .features-showcase__content {
  @include breakpoint-up(md) {
    padding-left: 15px;
  }
}

.features-showcase__item:not(:last-child)::after {
  position: absolute;
  right: -1px;
  bottom: -87px;
  left: -1px;
  z-index: z("default");
  height: 90px;
  content: "";
  background-repeat: no-repeat;
  background-size: 100%;

  @include breakpoint-up(md) {
    background-size: contain;
  }
}

.features-showcase__item:not(:last-child):nth-child(odd)::after {
  background-image: url("#{$assetsPath}/img/shapes/bottom-section-triangle-mobile.svg");

  @include breakpoint-up(md) {
    background-image: url("#{$assetsPath}/img/shapes/bottom-section-triangle.svg");
  }
}

.features-showcase__item:not(:last-child):nth-child(even)::after {
  background-image: url("#{$assetsPath}/img/shapes/bottom-section-triangle-mobile--blue.svg");

  @include breakpoint-up(md) {
    background-image: url("#{$assetsPath}/img/shapes/bottom-section-triangle--blue.svg");
  }
}

.features-showcase__item-title {
  @include grotesk(semiBold);

  position: relative;
  margin-bottom: 8px;

  font-size: to-rem(20px);
  line-height: to-rem(40px);
  color: $ebony-clay;

  counter-increment: item;

  @include breakpoint-up(md) {
    margin-bottom: 11px;
    font-size: to-rem(22px);
  }

  &::before {
    @include grotesk(bold);

    position: relative;
    top: -1px;
    display: none;
    width: 30px;
    height: 30px;
    margin-right: 12px;

    font-size: to-rem(20px);
    line-height: 30px;
    color: white;
    text-align: center;
    content: counter(item);
    background-color: $ebony-clay;
    border-radius: 100%;

    @include breakpoint-up(md) {
      display: inline-block;
    }
  }
}

.features-showcase__item-description {
  max-width: 376px;
  font-size: to-rem(15px);
  line-height: to-rem(25px);
  color: $tundora;
  letter-spacing: -0.35px;
  white-space: pre-line;

  @include breakpoint-up(md) {
    line-height: to-rem(30px);
  }
}

/*
 * Custom Items UI
*/
.features-showcase__item--match-with-companies {
  @include breakpoint-up(md) {
    padding-bottom: 6px;
  }
}

.features-showcase__item--track-companies {
  &:not(:first-child) {
    @include breakpoint-only(sm) {
      padding-top: 70px;
    }
  }

  .features-showcase__thumbnail {
    position: relative;
    top: 19px;
  }
}
</style>
