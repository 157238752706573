import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-080ef344"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "criteria-assessment__container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AssessmentDesktop = _resolveComponent("AssessmentDesktop")!
  const _component_RenderlessSupporterAssessment = _resolveComponent("RenderlessSupporterAssessment")!
  const _component_PxButton = _resolveComponent("PxButton")!
  const _component_NavigationFooter = _resolveComponent("NavigationFooter")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_RenderlessSupporterAssessment, {
      data: _ctx.fields.supporter.investing_level_range
    }, {
      default: _withCtx((scope) => [
        (!_ctx.viewLoading)
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              _createElementVNode("div", _hoisted_2, [
                _createVNode(_component_AssessmentDesktop, {
                  "current-category": scope.currentCategory,
                  "is-first-interaction": scope.isFirstInteraction,
                  levels: scope.levels,
                  "on-level-change": scope.onLevelChange,
                  "on-save": scope.onSaveHandler,
                  "range-restriction": scope.rangeRestriction,
                  "selected-category": scope.selectedCategory,
                  "selected-step": scope.selectedStep,
                  "show-integrated-footer": false
                }, null, 8, ["current-category", "is-first-interaction", "levels", "on-level-change", "on-save", "range-restriction", "selected-category", "selected-step"])
              ])
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["data"]),
    _createVNode(_component_NavigationFooter, { class: "criteria-assessment__navigation-footer" }, {
      center: _withCtx(() => [
        _createVNode(_component_PxButton, {
          loading: _ctx.formLoading || _ctx.viewLoading,
          class: "navigation-footer__main-cta",
          type: "primary",
          onClick: _ctx.onNextButtonClick
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.ctaCopy.next), 1)
          ]),
          _: 1
        }, 8, ["loading", "onClick"]),
        (_ctx.skipEnabled)
          ? (_openBlock(), _createBlock(_component_PxButton, {
              key: 0,
              onClick: _ctx.onSkipButtonClick
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.ctaCopy.skip), 1)
              ]),
              _: 1
            }, 8, ["onClick"]))
          : _createCommentVNode("", true)
      ]),
      right: _withCtx(() => [
        (_ctx.skipEnabled)
          ? (_openBlock(), _createBlock(_component_PxButton, {
              key: 0,
              type: "link",
              onClick: _ctx.onSkipAllButtonClick
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.ctaCopy.setupCriteriaLater), 1)
              ]),
              _: 1
            }, 8, ["onClick"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ]))
}