import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, withModifiers as _withModifiers, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-c0c35c06"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "matching-badge-list__wrapper"
}
const _hoisted_2 = ["onClick"]
const _hoisted_3 = { class: "matching-badge-list__tooltip-title" }
const _hoisted_4 = { class: "matching-badge-list__tooltip-description" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PxIcon = _resolveComponent("PxIcon")!
  const _component_ElTooltip = _resolveComponent("ElTooltip")!
  const _component_MatchingBadgeModal = _resolveComponent("MatchingBadgeModal")!

  return (_ctx.hasBadges)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.typeClass)
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.formattedBadges, (badge, index) => {
            return (_openBlock(), _createElementBlock("div", {
              key: index,
              class: "matching-badge-list__item",
              onClick: _withModifiers(($event: any) => (_ctx.onBadgeClick(badge)), ["stop"])
            }, [
              _createVNode(_component_ElTooltip, {
                disabled: !_ctx.$screen.mdUp || _ctx.isModalVisible,
                enterable: false,
                placement: "top",
                "popper-class": "matching-badge-list__tooltip el-abaca-tooltip"
              }, {
                content: _withCtx(() => [
                  _createElementVNode("div", null, [
                    _createElementVNode("p", _hoisted_3, _toDisplayString(badge.title), 1),
                    _createElementVNode("p", _hoisted_4, _toDisplayString(badge.shortDescription), 1)
                  ])
                ]),
                default: _withCtx(() => [
                  _createVNode(_component_PxIcon, {
                    name: badge.icon,
                    size: _ctx.badgeIconSize,
                    class: "matching-badge-list__icon"
                  }, null, 8, ["name", "size"])
                ]),
                _: 2
              }, 1032, ["disabled"])
            ], 8, _hoisted_2))
          }), 128))
        ], 2),
        _createVNode(_component_MatchingBadgeModal, {
          ref: "matchingBadgeModal",
          items: _ctx.formattedBadges,
          "onUpdate:items": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formattedBadges) = $event)),
          title: _ctx.modalTitle,
          "onUpdate:title": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.modalTitle) = $event)),
          visibility: _ctx.isModalVisible,
          "onUpdate:visibility": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.isModalVisible) = $event))
        }, null, 8, ["items", "title", "visibility"])
      ]))
    : _createCommentVNode("", true)
}