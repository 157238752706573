import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-214efa6c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "affiliate-question-bundle" }
const _hoisted_2 = {
  class: "page-full-height",
  "element-loading-background": "#fafafa"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AffiliateNavigationBar = _resolveComponent("AffiliateNavigationBar")!
  const _component_LangSwitcher = _resolveComponent("LangSwitcher")!
  const _component_QuestionBundleEntrepreneurStartingPage = _resolveComponent("QuestionBundleEntrepreneurStartingPage")!
  const _component_AffiliateAssessment = _resolveComponent("AffiliateAssessment")!
  const _component_AffiliateQuestions = _resolveComponent("AffiliateQuestions")!
  const _component_TeamManagementView = _resolveComponent("TeamManagementView")!
  const _component_AffiliateReviewPage = _resolveComponent("AffiliateReviewPage")!
  const _component_AffiliateEntrepreneurResults = _resolveComponent("AffiliateEntrepreneurResults")!
  const _component_AffiliateFooterBar = _resolveComponent("AffiliateFooterBar")!
  const _component_PxFooter = _resolveComponent("PxFooter")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("header", null, [
      _createVNode(_component_AffiliateNavigationBar, {
        "active-step": _ctx.currentStep,
        "onUpdate:activeStep": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.currentStep) = $event)),
        disabled: _ctx.saving,
        "external-link": _ctx.affiliateWebsite,
        loading: _ctx.affiliateDataLoading,
        logo: _ctx.affiliateLogo,
        "show-login-button": _ctx.isAffiliateLastStep,
        "show-steps": !_ctx.isStartingPage && !_ctx.resultsPageVisible,
        steps: _ctx.navigationSteps,
        onResetSteps: _ctx.resetStepsData
      }, null, 8, ["active-step", "disabled", "external-link", "loading", "logo", "show-login-button", "show-steps", "steps", "onResetSteps"]),
      (_ctx.isStartingPage && !_ctx.affiliateDataLoading)
        ? (_openBlock(), _createBlock(_component_LangSwitcher, { key: 0 }))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("main", {
      class: _normalizeClass({
        'affiliate-question-bundle__starting-page': _ctx.isStartingPage,
      })
    }, [
      _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_2, [
        (!_ctx.resultsPageVisible)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              (_ctx.isStartingPage)
                ? (_openBlock(), _createBlock(_component_QuestionBundleEntrepreneurStartingPage, {
                    key: 0,
                    "is-entrepreneur-affiliate": _ctx.isEntrepreneurAffiliate,
                    onNextStep: _ctx.goToNextStep
                  }, null, 8, ["is-entrepreneur-affiliate", "onNextStep"]))
                : _createCommentVNode("", true),
              (_ctx.isAssessmentPage)
                ? (_openBlock(), _createBlock(_component_AffiliateAssessment, {
                    key: 1,
                    onGoBack: _ctx.goToPreviousStep
                  }, null, 8, ["onGoBack"]))
                : _createCommentVNode("", true),
              (_ctx.isQuestionsPage)
                ? (_openBlock(), _createBlock(_component_AffiliateQuestions, {
                    key: 2,
                    onGoToReviewPage: _cache[1] || (_cache[1] = ($event: any) => (_ctx.goToPage(4)))
                  }))
                : _createCommentVNode("", true),
              (_ctx.isTeamManagementPage)
                ? (_openBlock(), _createBlock(_component_TeamManagementView, {
                    key: 3,
                    "is-affiliate-view": true
                  }))
                : _createCommentVNode("", true),
              (_ctx.isReviewPage)
                ? (_openBlock(), _createBlock(_component_AffiliateReviewPage, {
                    key: 4,
                    onGoToPage: _ctx.goToPage,
                    onMissingData: _ctx.checkDisableState
                  }, null, 8, ["onGoToPage", "onMissingData"]))
                : _createCommentVNode("", true)
            ], 64))
          : (_openBlock(), _createBlock(_component_AffiliateEntrepreneurResults, { key: 1 }))
      ])), [
        [_directive_loading, _ctx.affiliateDataLoading]
      ]),
      (!_ctx.isStartingPage && !_ctx.resultsPageVisible)
        ? (_openBlock(), _createBlock(_component_AffiliateFooterBar, {
            key: 0,
            date: _ctx.updatedDate,
            "is-last-page": _ctx.isAffiliateLastStep,
            "is-saving": _ctx.saving,
            "question-bundle-affiliate": true,
            "submit-disabled": _ctx.submitDisabled,
            onNextStep: _ctx.goToNextStep,
            onPreviousStep: _ctx.goToPreviousStep,
            onShowSavedDraftMessage: _ctx.showSavedDraftMessage
          }, null, 8, ["date", "is-last-page", "is-saving", "submit-disabled", "onNextStep", "onPreviousStep", "onShowSavedDraftMessage"]))
        : _createCommentVNode("", true),
      (_ctx.isEntrepreneurAffiliate && _ctx.isStartingPage)
        ? (_openBlock(), _createBlock(_component_PxFooter, { key: 1 }))
        : _createCommentVNode("", true)
    ], 2)
  ]))
}