import {
  EProviderFeatures,
  GenericProvider,
} from "@/services/data/generic-provider";
import {
  IAffiliateListProgramSubmission,
  IAffiliateProgramSubmission,
} from "@/modules/program/services/data/affiliate-program-submission/affiliate-program-submission";

class AffiliateProgramSubmissionProvider<T> extends GenericProvider<T> {
  constructor() {
    super(`${process.env.VUE_APP_API_URL}/affiliate-program-submissions`, [
      EProviderFeatures.GET,
    ]);
  }
}

export const affiliateProgramSubmissionEntryProvider =
  new AffiliateProgramSubmissionProvider<IAffiliateProgramSubmission>();

export const affiliateProgramSubmissionListProvider =
  new AffiliateProgramSubmissionProvider<IAffiliateListProgramSubmission[]>();
