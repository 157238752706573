import { GetterTree } from "vuex";

import { RootState } from "@/services/store/root-state";
import { getGenericStateGetters } from "@/services/store/generic/generic-state.getters";
import { IQuestionBundlesState } from "@/modules/milestone-planner/services/store/milestone-planner/sub-modules/question-bundles/question-bundles.interface";
import { IQuestionBundle } from "@/services/data/question-bundle/question-bundle.interface";

/**
 * Available getters to get data from the question bundles sub-module.
 */
export enum EQuestionBundlesGetters {
  VALUE = "getValue",
  VALUES = "getValues",
  IS_LOADING = "isLoading",
  ERROR = "getError",
  HAS_ERROR = "hasError",
}

export const getters: GetterTree<IQuestionBundlesState, RootState> = {
  ...getGenericStateGetters<IQuestionBundle>(),
};
