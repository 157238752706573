import { ISupporterType } from "@/modules/supporters/services/data/supporter-type/supporter-type.interface";
import { IGenericState } from "@/services/store/generic/generic-state.interface";

/**
 * Represents the store structure for the supporter type store data.
 */
export type ISupporterTypeState = IGenericState<ISupporterType>;

export enum ESupporterTypeState {
  NAMESPACE = "supporterType",
}
