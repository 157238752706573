<template>
  <div class="matching-start page-body">
    <PxPanel class="matching-start-panel">
      <h1 class="matching-start-panel__title">
        {{ $t("matching.matchingStart.title") }}
      </h1>
      <div class="matching-start-panel__teaser">
        {{ $t(`matching.matchingStart.teaser[${$profile.type()}]`) }}
      </div>
      <p
        class="matching-start-panel__content"
        v-html="$t(`matching.matchingStart.content[${$profile.type()}]`)"
      />
      <PxButton
        class="matching-start-panel__button"
        size="large"
        type="green"
        @click="onClickGoToMatchingList"
      >
        {{ $t("matching.matchingStart.button") }}
      </PxButton>
    </PxPanel>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import { ROUTE_MATCHING_LIST } from "@/modules/matching/services/router/routes-names";
import { EMetaActions } from "@/services/store/meta/meta-types";
import { MATCHING_FROM_ONBOARDING } from "@/modules/matching/constants";

export default defineComponent({
  name: "MatchingStart",

  methods: {
    /**
     * Add meta to know that the user already visited this page and
     * redirect them to the matching view.
     */
    onClickGoToMatchingList() {
      this.$store.dispatch(EMetaActions.SET, {
        key: MATCHING_FROM_ONBOARDING,
        value: true,
      });
      this.$router.push({
        name: ROUTE_MATCHING_LIST,
        query: this.$route.query,
      });
    },
  },
});
</script>

<style lang="scss" scoped>
.matching-start {
  @include container;

  padding: 20px 0 0;

  @include breakpoint-up(md) {
    padding: 57px 0 0;
  }

  @include breakpoint-up(lg) {
    display: flex;
    align-items: center;
    max-width: 811px;
    padding-top: 0;
    margin: 50px auto;
  }
}

.matching-start .matching-start-panel {
  flex-grow: 0;
  width: 100%;

  text-align: center;

  @include breakpoint-only(xs) {
    border-right: none;
    border-left: none;
  }

  &__title {
    @include grotesk(semiBold);

    padding-top: 7px;
    margin-bottom: 6px;

    font-size: 2rem;
    line-height: 40px;

    @include breakpoint-down(sm) {
      padding-top: 9px;
      margin-bottom: 8px;

      font-size: 1.4667rem;
      line-height: 32px;
    }
  }

  &__teaser {
    margin-bottom: 24px;
    font-size: 18px;
    line-height: 40px;
    color: $manatee;

    @include breakpoint-down(sm) {
      margin-bottom: 19px;
      font-size: 17px;
      line-height: 27px;
      letter-spacing: -0.2px;
    }
  }

  &__content {
    max-width: 630px;
    margin: 0 auto 40px;

    font-size: 1.1333rem;
    line-height: 32px;
    letter-spacing: -0.2px;

    @include breakpoint-down(sm) {
      font-size: 1rem;
      line-height: 25px;
    }
  }

  &__button {
    min-width: 140px;
    margin-bottom: 8px;

    @include breakpoint-down(sm) {
      min-width: 290px;
    }
  }
}
</style>
