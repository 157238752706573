import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-6bb0c45e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "critical-milestone-indicator" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PxIcon = _resolveComponent("PxIcon")!
  const _component_ElTooltip = _resolveComponent("ElTooltip")!

  return (_openBlock(), _createBlock(_component_ElTooltip, {
    content: _ctx.criticalTooltipCopy,
    disabled: !_ctx.isVisitor,
    placement: "bottom",
    "popper-class": "el-tooltip__popper--small critical-milestone-indicator__tooltip el-abaca-tooltip"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_PxIcon, {
          class: "critical-milestone-indicator__icon",
          name: "critical-milestone",
          size: "20"
        }),
        _createElementVNode("div", null, _toDisplayString(_ctx.$t("milestonePlanner.milestone.critical")), 1)
      ])
    ]),
    _: 1
  }, 8, ["content", "disabled"]))
}