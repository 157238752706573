import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "px-grid__levels" }
const _hoisted_2 = { class: "px-grid__main-area" }
const _hoisted_3 = { class: "px-grid__categories" }
const _hoisted_4 = { class: "px-grid-matrix" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([[`px-grid--${_ctx.size}`, { 'px-grid--inactive': _ctx.inactive }], "px-grid"])
  }, [
    _createElementVNode("div", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.levels, (n) => {
        return (_openBlock(), _createElementBlock("span", {
          key: n,
          class: _normalizeClass([{ 'is-active': n === _ctx.currentLevel }, "px-grid__level-text"])
        }, _toDisplayString(n), 3))
      }), 128))
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.categories, (category) => {
          return (_openBlock(), _createElementBlock("span", {
            key: category.id,
            class: "px-grid__category"
          }, [
            (_ctx.size === 'default')
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  _createTextVNode(_toDisplayString(category.name), 1)
                ], 64))
              : _createCommentVNode("", true),
            (_ctx.size !== 'default')
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                  _createTextVNode(_toDisplayString(category.abbreviation), 1)
                ], 64))
              : _createCommentVNode("", true)
          ]))
        }), 128))
      ]),
      _createElementVNode("div", _hoisted_4, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.categories, (category) => {
          return (_openBlock(), _createElementBlock("div", {
            key: category.id,
            class: "px-grid-matrix__column"
          }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.levels, (n) => {
              return (_openBlock(), _createElementBlock("div", {
                key: n,
                style: _normalizeStyle(_ctx.generateStylesFor(category, n)),
                class: "px-grid-matrix__cell"
              }, null, 4))
            }), 128))
          ]))
        }), 128))
      ])
    ]),
    _createElementVNode("div", {
      style: _normalizeStyle(`transform: translateY(${_ctx.levelBarPosition})`),
      class: "px-grid__current-level"
    }, [
      (_ctx.size === 'default')
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _createTextVNode("Level " + _toDisplayString(_ctx.currentLevel), 1)
          ], 64))
        : (_ctx.inactive)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
              _createTextVNode("0")
            ], 64))
          : (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
              _createTextVNode(_toDisplayString(_ctx.currentLevel), 1)
            ], 64))
    ], 4)
  ], 2))
}