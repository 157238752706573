import { ICompanyList } from "@/modules/company-lists/services/data/company-list/company-list.interface";
import {
  EProviderFeatures,
  GenericProvider,
  IOptionsType,
  IPaginationResult,
  Provider,
} from "@/services/data/generic-provider";
import { baseAPIUrl } from "@/services/utils/utils";

@Provider(`${baseAPIUrl}/user/company-lists/shared`, [
  EProviderFeatures.GET,
  EProviderFeatures.LIST,
  EProviderFeatures.DESTROY,
])
class SharedCompanyListProvider extends GenericProvider<ICompanyList> {
  /**
   * Paginate company lists.
   */
  public async paginate(
    options: IOptionsType = {},
  ): Promise<IPaginationResult<ICompanyList>> {
    return super.list(options) as unknown as IPaginationResult<ICompanyList>;
  }
}

/**
 * Provider to get the shared lists with the current logged user.
 */
export const sharedCompanyListProvider = new SharedCompanyListProvider();
