export interface IAffiliateSubmission {
  affiliate: {
    name: string;
    id: number;
  };
  owners?: Array<IAffiliateSubmissionsOwners>;
  shared_info: Array<number>;
  last_update?: string;
  submission_uid?: string;
  company_type?: number;
}

export interface IAffiliateSubmissions {
  drafts: Array<IAffiliateSubmission>;
  submitted: Array<IAffiliateSubmission>;
}

export interface IAffiliateSubmissionsOwners {
  name: string;
  logo: string;
  id: number;
  email?: string;
}

export enum EAffiliateSubmissionType {
  DRAFT = "draft-submissions",
  SUBMITTED = "submitted-submissions",
}
