import { IBaseState } from "@/services/store/store-types";
import { ISupporter } from "@/modules/matching/services/data/matching-score/supporter.interface";

/**
 * Structure of the store of the company profile module.
 */
export interface IGenericSupporterState extends IBaseState {
  data: ISupporter | null;
}

export enum EGenericSupporterActions {
  FETCH = "supporter/fetch",
  PATCH = "supporter/patch",
  RESET = "supporter/reset",
  CREATE_AFFILIATE_LINK = "supporter/createAffiliateLink",
}

export enum EGenericSupporterMutations {
  SET_LOADING = "SET_LOADING",
  SET_ERROR = "SET_ERROR",
  SET_DATA = "SET_DATA",
}
