<template>
  <div class="milestone-form-footer-plan">
    <div class="milestone-form-footer-plan__left">
      <PxButton
        v-if="hasPlan"
        ref="deleteButton"
        v-bind="deleteButtonProps"
        class="milestone-form-footer-plan__delete-button"
        data-testid="delete-button"
        @click="$emitter.emit('delete-milestone-plan')"
      />
      <div
        v-if="isFutureMilestone && !hasPlan"
        class="milestone-form-footer-plan__future-tip"
      >
        <PxIcon
          :size="16"
          class="milestone-form-footer-plan__future-tip-icon"
          name="warning--grey"
        />
        {{ $t("milestonePlanner.milestonePlan.planView.futureMilestoneTip") }}
      </div>
    </div>
    <div class="milestone-form-footer-plan__right">
      <PxButton
        ref="discardButton"
        v-bind="discardButtonProps"
        :disabled="!formHasChanges"
        class="milestone-form-footer-plan__discard-button"
        data-testid="discard-button"
        @click.stop="$emitter.emit('discard-milestone-plan')"
      />
      <PxButton
        ref="saveAsDraftButton"
        v-bind="saveAsDraftButtonProps"
        :disabled="saveAsDraftDisabled"
        :loading="isLoadingMilestones"
        class="milestone-form-footer-plan__save-as-draft-button"
        data-testid="save-as-draft-button"
        @click.stop="
          $emitter.emit('submit-milestone-plan', { toPublish: false })
        "
      />
      <PxButton
        ref="submitPlanButton"
        v-bind="submitPlanButtonProps"
        :disabled="submitPlanDisabled"
        :loading="isLoadingMilestones"
        class="milestone-form-footer-plan__submit-plan-button"
        data-testid="submit-plan-button"
        @click.stop="
          $emitter.emit('submit-milestone-plan', { toPublish: true })
        "
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import {
  EPxButtonSize,
  EPxButtonVariant,
  EPxButtonType,
} from "@/components/px-button/px-button.types";

export default defineComponent({
  name: "MilestoneFormFooterPlan",

  props: {
    hasPlan: {
      type: Boolean,
      default: false,
    },

    isPlanPublished: {
      type: Boolean,
      default: false,
    },

    isFutureMilestone: {
      type: Boolean,
      default: false,
    },

    formHasChanges: {
      type: Boolean,
      default: false,
    },

    formIsInvalid: {
      type: Boolean,
      default: false,
    },

    formIsComplete: {
      type: Boolean,
      default: false,
    },
  },

  static: {
    discardButton: {
      type: EPxButtonType.LINK,
    },
    deleteButton: {
      icon: "trash--red",
      type: EPxButtonType.LINK_DANGER,
    },
    saveAsDraftButton: {
      type: EPxButtonType.DEFAULT,
      size: EPxButtonSize.LARGE,
      variant: EPxButtonVariant.FLUID,
    },
    submitPlanButton: {
      size: EPxButtonSize.LARGE,
      variant: EPxButtonVariant.FLUID,
    },
  },

  computed: {
    deleteButtonProps(): any {
      return {
        ...this.$options.static.deleteButton,
        label: !this.isPlanPublished
          ? this.$t("milestonePlanner.milestonePlan.buttons.deleteDraft")
          : this.$t("milestonePlanner.milestonePlan.buttons.deletePlan"),
      };
    },

    discardButtonProps(): any {
      return {
        ...this.$options.static.discardButton,
        label: this.$t("milestonePlanner.milestonePlan.buttons.discardChanges"),
      };
    },

    saveAsDraftButtonProps(): any {
      return {
        ...this.$options.static.saveAsDraftButton,
        label: this.$t("milestonePlanner.milestonePlan.buttons.saveAsDraft"),
      };
    },

    submitPlanButtonProps(): any {
      return {
        ...this.$options.static.submitPlanButton,
        type: EPxButtonType.GREEN,
        label: this.isPlanPublished
          ? this.$t("milestonePlanner.milestonePlan.buttons.savePlan")
          : this.$t("milestonePlanner.milestonePlan.buttons.submitPlan"),
      };
    },

    saveAsDraftDisabled(): any {
      return (
        (!this.formHasChanges && !this.isPlanPublished) ||
        (this.formHasChanges && this.formIsInvalid)
      );
    },

    submitPlanDisabled(): any {
      return (
        !this.formIsComplete ||
        (this.formIsComplete && this.isPlanPublished && !this.formHasChanges)
      );
    },

    isLoadingMilestones(): boolean {
      return this.$store.get("auth/milestones.loading");
    },
  },
});
</script>

<style lang="scss" scoped>
.milestone-form-footer-plan {
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;
  justify-content: space-between;
  height: 100%;

  &__right {
    display: grid;
    grid-template-columns: auto auto auto;
    gap: 16px;

    .el-button {
      height: 36px;
    }
  }

  &__submit-plan-button {
    position: relative;
    bottom: 1px;
  }

  &__save-as-draft-button {
    position: relative;
    bottom: 1px;
    left: 1px;
  }

  &__future-tip {
    @include grotesk(semiBold);

    position: relative;
    bottom: 1px;
    max-width: 540px;
    padding-right: 60px;
    padding-left: 24px;
    font-size: 14px;
    font-style: italic;
    line-height: 17px;
    color: $manatee;
    letter-spacing: 0.01px;
  }

  &__future-tip-icon {
    position: absolute;
    left: -1px;
  }
}
</style>
