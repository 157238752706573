import { defineComponent } from "vue";

export default defineComponent({
  props: {
    visibility: {
      type: Boolean,
      required: false,
    },
  },

  data() {
    return {
      innerVisibility: false,
    };
  },

  watch: {
    visibility: {
      immediate: true,
      handler(newVal: boolean) {
        // Prevent reactivity loop
        if (this.innerVisibility !== newVal) {
          this.innerVisibility = newVal;
        }
      },
    },

    innerVisibility(newVal: boolean, oldVal: boolean) {
      // Prevent reactivity loop
      if (newVal !== oldVal && this.visibility !== newVal) {
        this.$emit("update:visibility", newVal);

        if (!newVal) {
          this.onModalClose();
        }
      }
    },
  },

  methods: {
    onModalClose() {
      // Placeholder to be overriden, if needed
    },
  },
});
