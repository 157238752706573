<template>
  <div class="grid-legend">
    <ElCollapse>
      <ElCollapseItem>
        <template #title>
          <div class="grid-legend__headline">
            <span>
              {{ $t("milestonePlanner.milestoneGrid.gridLegend.title") }}
            </span>
          </div>
        </template>
        <div class="grid-legend-wrapper">
          <GridLegendContainer
            :legend="completedStatus"
            :tooltip-text="completedTooltip"
          />
          <GridLegendContainer
            :legend="notCompletedStatus"
            :tooltip-text="notCompletedTooltip"
          />
        </div>
      </ElCollapseItem>
    </ElCollapse>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import GridLegendContainer from "@/modules/milestone-planner/components/grid-legend/grid-legend-container.vue";
import { EMilestoneStatusType } from "@/modules/milestone-planner/services/data/milestones/milestone.interface";

export default defineComponent({
  name: "GridLegend",

  components: {
    GridLegendContainer,
  },

  data() {
    return {
      completedStatus: [
        {
          title: this.$t(
            "milestonePlanner.milestoneGrid.gridLegend.completedMilestones",
          ),
          icon: EMilestoneStatusType.COMPLETED,
          iconSize: 30,
        },
        {
          title: this.$t(
            "milestonePlanner.milestoneGrid.gridLegend.selfAssessed",
          ),
          icon: EMilestoneStatusType.SELF_ASSESSED,
          iconSize: 17,
        },
        {
          title: this.$t(
            "milestonePlanner.milestoneGrid.gridLegend.evidenceProvided",
          ),
          icon: EMilestoneStatusType.EVIDENCE_PROVIDED,
          iconSize: 20,
        },
      ],
      notCompletedStatus: [
        {
          title: this.$t(
            "milestonePlanner.milestoneGrid.stageStatus.toBePlanned",
          ),
          icon: EMilestoneStatusType.TO_BE_PLANNED,
          iconSize: 26,
        },
        {
          title: this.$t("milestonePlanner.milestoneGrid.stageStatus.planned"),
          icon: EMilestoneStatusType.PLANNED,
          iconSize: 26,
        },
        {
          title: this.$t(
            "milestonePlanner.milestoneGrid.stageStatus.inProgress",
          ),
          icon: EMilestoneStatusType.IN_PROGRESS,
          iconSize: 26,
        },
        {
          title: this.$t("milestonePlanner.milestoneGrid.gridLegend.critical"),
          icon: EMilestoneStatusType.CRITICAL,
          iconSize: 22,
        },
      ],
      completedTooltip: this.$t(
        "milestonePlanner.milestoneGrid.gridLegend.completedTooltip",
      ),
      notCompletedTooltip: this.$t(
        "milestonePlanner.milestoneGrid.gridLegend.notCompletedTooltip",
      ),
    };
  },
});
</script>

<style lang="scss">
.grid-legend {
  &__headline {
    width: 100%;
    text-align: right;

    span {
      @include grotesk(medium);

      font-weight: 500;
      color: $ebony-clay;
      text-decoration: underline;
    }
  }

  .el-collapse-item__wrap,
  .el-collapse-item__content {
    padding: 0;
    background-color: $white;
  }

  .el-collapse-item__wrap {
    margin-top: 15px;
  }

  .el-collapse-item__header {
    display: flex;
    justify-content: flex-end;

    min-height: 0;
    padding: 0 20px 0 0;
    margin-right: 5px;
    background-color: transparent;

    &.is-active::before {
      transform: rotate(180deg);
    }

    &::before {
      top: 0;
      right: 0;
      bottom: 0;
      width: 16px;
      height: 16px;
      margin: auto 0;
      background-color: transparent;
      background-image: url("#{$assetsPath}/img/icons/arrow.svg");
      background-repeat: no-repeat;
      background-position: center;
      transition: $--all-transition;
    }

    &::after {
      display: none;
    }
  }
}

.grid-legend .grid-legend-wrapper {
  display: flex;
  justify-content: space-between;
}

.grid-legend .grid-legend-container:nth-child(2) {
  grid-template-areas:
    "top top"
    "bottom bottom";
  grid-template-columns: 145px auto;
  grid-row-gap: 1px;
  grid-column-gap: 18px;
  width: 352px;
  padding: 7px 2px 4px 4px;

  div:nth-child(5) {
    img {
      position: relative;
      right: 3px;
    }
    h3 {
      position: relative;
      top: 3px;
      right: 13px;
    }
  }

  .px-stage-status-item__title {
    margin: 0 0 0 9px;
  }
}
</style>
