import {
  IConsentResponse,
  IConsentType,
} from "@/modules/authentication/services/data/consent/consent.interface";
import { consentProvider } from "@/modules/authentication/services/data/consent/consent.provider";
import { capitalExplorerSubmissionProvider } from "@/modules/capital-explorer/services/data/capital-explorer/capital-explorer-submission.provider";
import { capitalExplorerDataProvider } from "@/modules/capital-explorer/services/data/capital-explorer/capital-explorer.provider";
import {
  ICapitalExplorerData,
  ICapitalExplorerSubmission,
  ICapitalExplorerVisitorData,
} from "@/modules/capital-explorer/services/data/capital-explorer/capital-explorer.interface";
import {
  ECapitalExplorerActions,
  ECapitalExplorerMutations,
  ICapitalExplorerState,
} from "@/modules/capital-explorer/services/store/capital-explorer/capital-explorer.types";
import { RootState } from "@/services/store/root-state";
import { getBaseName } from "@/services/store/utils/get-base-name";
import { ActionTree } from "vuex";

export const actions: ActionTree<ICapitalExplorerState, RootState> = {
  async [getBaseName(ECapitalExplorerActions.FETCH_DATA)]({
    commit,
  }): Promise<ICapitalExplorerData | null> {
    commit(ECapitalExplorerMutations.SET_LOADING, true);
    commit(ECapitalExplorerMutations.SET_ERROR, null);

    let capitalExplorerData: ICapitalExplorerData | null = null;

    try {
      capitalExplorerData = await capitalExplorerDataProvider.fetch();

      commit(ECapitalExplorerMutations.SET_DATA, capitalExplorerData);
      commit(ECapitalExplorerMutations.SET_ERROR, null);
    } catch (error) {
      commit(ECapitalExplorerMutations.SET_DATA, null);
      commit(ECapitalExplorerMutations.SET_ERROR, error);
    } finally {
      commit(ECapitalExplorerMutations.SET_LOADING, false);
    }

    return capitalExplorerData;
  },

  // Fetch another user submission data by uid
  async [getBaseName(ECapitalExplorerActions.FETCH_USER_SUBMISSION)](
    { commit, state },
    queryParams = {},
  ): Promise<ICapitalExplorerVisitorData | null> {
    commit(ECapitalExplorerMutations.SET_ERROR, null);
    commit(ECapitalExplorerMutations.SET_LOADING, true);

    const submissionUid = queryParams.uid;
    delete queryParams.uid;

    let submissionData = null;

    // If a passcode was provided, store it in the Vuex store for future use.
    // Otherwise, check if there is already one there and, if so, use it.
    if (queryParams.passcode) {
      commit(ECapitalExplorerMutations.SET_PASSCODES, {
        ...state.passcodes,
        [submissionUid]: queryParams.passcode,
      });
    } else {
      if (state.passcodes[submissionUid]) {
        queryParams.passcode = state.passcodes[submissionUid];
      }
    }

    try {
      submissionData = (await capitalExplorerSubmissionProvider.get(
        submissionUid,
        queryParams,
      )) as ICapitalExplorerVisitorData;

      commit(ECapitalExplorerMutations.SET_VISITOR_DATA, submissionData);
      commit(ECapitalExplorerMutations.SET_ERROR, null);
    } catch (error) {
      commit(ECapitalExplorerMutations.SET_VISITOR_DATA, null);
      commit(ECapitalExplorerMutations.SET_ERROR, error);
    } finally {
      commit(ECapitalExplorerMutations.SET_LOADING, false);
    }

    return submissionData;
  },

  // Fetch authenticated user submission data
  async [getBaseName(ECapitalExplorerActions.FETCH_AUTH_USER_SUBMISSION)]({
    state,
    commit,
  }): Promise<ICapitalExplorerSubmission | null> {
    commit(ECapitalExplorerMutations.SET_ERROR, null);

    let submissionData: ICapitalExplorerSubmission | null = null;

    try {
      submissionData = (
        await capitalExplorerSubmissionProvider.list()
      )[0] as ICapitalExplorerSubmission;

      commit(ECapitalExplorerMutations.SET_DATA, {
        ...state.data,
        submission: submissionData,
      });
      commit(ECapitalExplorerMutations.SET_ERROR, null);
    } catch (error) {
      commit(ECapitalExplorerMutations.SET_DATA, null);
      commit(ECapitalExplorerMutations.SET_ERROR, error);
    }

    return submissionData;
  },

  async [getBaseName(ECapitalExplorerActions.CHECK_CONSENT)]({
    commit,
  }): Promise<IConsentResponse> {
    commit(ECapitalExplorerMutations.SET_ERROR, null);

    try {
      const consent = await consentProvider.check(
        IConsentType.CAPITAL_EXPLORER,
      );
      commit(ECapitalExplorerMutations.SET_TERMS_ACCEPTED, consent);
      commit(ECapitalExplorerMutations.SET_ERROR, null);
      return consent;
    } catch (error) {
      commit(ECapitalExplorerMutations.SET_TERMS_ACCEPTED, false);
      commit(ECapitalExplorerMutations.SET_ERROR, error);
    }

    return false;
  },

  async [getBaseName(ECapitalExplorerActions.GRANT_CONSENT)]({
    commit,
  }): Promise<void> {
    commit(ECapitalExplorerMutations.SET_LOADING, true);
    commit(ECapitalExplorerMutations.SET_ERROR, null);

    try {
      await consentProvider.grant(IConsentType.CAPITAL_EXPLORER);
      commit(ECapitalExplorerMutations.SET_TERMS_ACCEPTED, true);
      commit(ECapitalExplorerMutations.SET_ERROR, null);
    } catch (error) {
      commit(ECapitalExplorerMutations.SET_TERMS_ACCEPTED, false);
      commit(ECapitalExplorerMutations.SET_ERROR, error);
    } finally {
      commit(ECapitalExplorerMutations.SET_LOADING, false);
    }
  },

  // Patch capital explorer submission data
  async [getBaseName(ECapitalExplorerActions.PATCH)](
    { commit, state },
    {
      submissionId,
      payload,
      queryParams = {},
    }: { submissionId: string; payload: any; queryParams: any },
  ) {
    commit(ECapitalExplorerMutations.SET_ERROR, null);

    try {
      const newSubmission = await capitalExplorerSubmissionProvider.patch(
        submissionId,
        payload,
        queryParams,
      );

      if (!payload?.responses) {
        commit(ECapitalExplorerMutations.SET_DATA, {
          ...state.data,
          submission: newSubmission,
        });
      }
    } catch (error) {
      commit(ECapitalExplorerMutations.SET_ERROR, error);
    }
  },
};
