<template>
  <div class="profile-panel__row assessment-level">
    <div class="profile-headline">
      <h2 class="profile-headline__title">
        {{ $t("profile.assessment.levelPanel.title") }}
      </h2>
    </div>
    <div class="level-wrapper">
      <img
        v-if="isInactive"
        alt="level indicator"
        class="assessment-level__indicator assessment-level__indicator--disabled"
        src="/img/placeholders/level.svg"
      />
      <PxIcon
        v-else
        :name="`level-graph/${level.value}`"
        :size="$screen.mdUp ? 173 : 140"
        class="assessment-level__indicator"
      />
      <div v-if="!isInactive" class="assessment-level__description">
        <h3>
          {{ $t("profile.assessment.levelPanel.label") }} {{ level.value }}
        </h3>
        <ElCollapse accordion>
          <ElCollapseItem>
            <template #title>
              <p>
                {{ level.title }}
              </p>
            </template>
            <div v-html="level.description" />
          </ElCollapseItem>
        </ElCollapse>
      </div>
    </div>
    <div
      v-if="$user.isOwner() && $features.isEnabled('match')"
      class="assessment-level__cta"
    >
      <PxButton size="small" type="blue" @click="onClickMatching">
        {{ $t("profile.assessment.levelPanel.matchButton") }}
      </PxButton>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import { ROUTE_MATCHING_LIST } from "@/modules/matching/services/router/routes-names";
import { ILevel } from "@/services/data/level/level.interface";
import { IAssessment } from "@/services/data/assessment/assessment.interface";

export default defineComponent({
  name: "AssessmentPanelLevel",

  props: {
    latestAssessment: {
      type: Object as () => IAssessment | null,
      required: true,
    },
    levels: {
      type: Array as () => Array<ILevel>,
      required: true,
    },
    level: {
      type: Object as () => ILevel,
      required: true,
    },
  },

  computed: {
    isSpecialGuest(): boolean {
      return !!this.$route.meta?.specialGuest;
    },

    isInactive(): boolean {
      return (
        (!this.$user.isLogged() && !this.isSpecialGuest) ||
        !this.latestAssessment
      );
    },
  },

  methods: {
    onClickUpdateLevel() {
      this.$emit("request-new-assessment");
    },

    onClickMatching() {
      this.$router.push({ name: ROUTE_MATCHING_LIST });
    },
  },
});
</script>

<style lang="scss" scoped>
.assessment-level {
  padding: 8px 4px;
  text-align: center;

  @include breakpoint-down(sm) {
    padding: 0;
  }
}

.assessment-level__indicator {
  display: block;
  margin: -1px auto 0;

  &--disabled {
    opacity: 0.7;
  }
}

.assessment-level__description {
  @include breakpoint-down(sm) {
    padding-left: 17px;

    font-size: to-rem(16px);
    line-height: 23px;

    p,
    h3 {
      text-align: left;
    }
  }

  :deep(.el-collapse-item__header) {
    gap: 5px;
    justify-content: center;
    min-height: 0;
    padding: 0;
  }

  :deep(.el-collapse-item div[role="tab"]::before),
  :deep(.el-collapse-item__header::before),
  :deep(.el-collapse-item__header::after) {
    display: none;
  }

  :deep(.el-collapse-item__arrow) {
    display: block;
    position: relative;
    left: 4px;
    z-index: z("default");
    width: 22px;
    height: 22px;
    margin: 0;
    pointer-events: none;

    content: "";
    background-image: linear-gradient(to top, $athens-gray, $white 85%);
    border: solid 1px $mischka;
    border-radius: 100%;

    &::before,
    &::after {
      position: absolute;
      z-index: z("default");
      pointer-events: none;
      content: "";
      background-color: $ebony-clay;
      border-radius: 1px;
    }

    &::before {
      top: 9px;
      right: 5px;
      width: 10px;
      height: 2px;
    }

    &::after {
      top: 5px;
      right: 9px;
      width: 2px;
      height: 10px;
      transition: opacity 200ms ease-in;
      will-change: opacity;
    }

    &.is-active {
      transform: none;
    }

    &.is-active::after {
      opacity: 0;
    }
  }

  :deep(.el-collapse-item__content) {
    padding: 10px 0 2px;
    font-size: 14px;
    line-height: 20px;
    color: $manatee;
    letter-spacing: -0.28px;
    text-align: left;
  }
}

.assessment-level__indicator + .assessment-level__description {
  margin-top: -5px;
}

.assessment-level__description h3 {
  @include grotesk(semiBold);

  margin-bottom: 3px;
  font-size: to-rem(22px);
  line-height: 26px;

  @include breakpoint-down(sm) {
    font-size: to-rem(16px);
  }
}

.assessment-level__description p {
  @include grotesk(light, italic);

  display: flex;
  align-items: center;
  justify-content: center;

  font-size: to-rem(17px);
  line-height: 26px;
  color: $manatee;
  letter-spacing: -0.15px;

  @include breakpoint-down(sm) {
    font-size: to-rem(16px);
    line-height: 23px;
  }
}

.assessment-level__description + .assessment-level__cta {
  margin-top: 15px;
}

.assessment-level__cta .el-button.el-button--small {
  top: 1px;
  padding: 0 18px;
}

.assessment-level__cta .el-button :deep(span) {
  @include breakpoint-down(sm) {
    font-size: 13px;
    letter-spacing: -0.46px;
  }
}

.level-wrapper {
  margin-top: 6px;

  &:not(:last-child) {
    margin-bottom: 15px;
  }

  @include breakpoint-down(sm) {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 6px;
  }
}
</style>
