<template>
  <ElTag effect="plain" :color="color" class="px-label">
    {{ text }}
  </ElTag>
</template>

<script lang="ts">
import { defineComponent } from "vue";

/**
 * Label used to display desired text
 */
export default defineComponent({
  name: "PxLabel",

  props: {
    color: {
      required: true,
      type: String,
    },
    text: {
      required: true,
      type: String,
    },
  },
});
</script>

<style lang="scss" scoped>
.px-label {
  padding: 1px 7px;
  margin: 0 !important;

  :deep(.el-tag__content) {
    font-size: 12px;
    line-height: 12px;
    letter-spacing: 0.1px;
  }
}
</style>
