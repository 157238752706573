<template>
  <div class="px-table-header">
    <div
      v-for="(header, index) in items"
      :key="index"
      class="px-table-header__item"
    >
      <p>
        {{ header.title }}
      </p>
      <ElTooltip
        v-if="header.tooltip"
        :content="header.tooltip"
        placement="top"
        popper-class="px-table-header__tooltip el-abaca-tooltip"
      >
        <i class="icon icon-question" />
      </ElTooltip>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export interface ITableHeaderItems {
  title: string;
  tooltip?: string;
}

export default defineComponent({
  name: "PxTableHeader",

  props: {
    items: {
      type: Array as () => Array<ITableHeaderItems>,
      required: true,
    },
  },
});
</script>

<style lang="scss" scoped>
.px-table-header {
  display: flex;
  border-bottom: 1px solid $athens-gray;
}

.px-table-header__item {
  display: flex;
  align-items: center;
  padding-left: 12px;

  p {
    @include grotesk(semiBold);

    font-size: 12px;
    color: $manatee;
    text-transform: uppercase;
    letter-spacing: 0.3px;
  }

  .icon-question {
    margin-left: 8px;
    width: 12px;
    height: 12px;
  }
}
</style>

<style lang="scss">
.px-table-header__tooltip {
  padding: 10px 15px;
  width: 340px;
  letter-spacing: -0.17px;
  text-transform: none;
}
</style>
