<template>
  <div :class="`px-notice px-notice--${type} px-notice--${direction}`">
    <div v-if="icon" class="px-notice__icon">
      <PxIcon :name="icon" :size="15" />
    </div>
    <div class="px-notice__wrapper">
      <h3 class="px-notice__title">
        {{ title }}
      </h3>
      <div class="px-notice__cta">
        <slot name="cta" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "PxNotice",

  props: {
    title: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      default: "speech-bubble",
    },
    type: {
      type: String,
      default: "default",
      validator: (val: string) => {
        return ["default"].includes(val);
      },
    },
    direction: {
      type: String,
      default: "vertical",
      validator: (val: string) => {
        return ["vertical", "horizontal"].includes(val);
      },
    },
  },
});
</script>

<style lang="scss" scoped>
.px-notice {
  position: relative;
  padding-top: 17px;
}

.px-notice__icon {
  position: absolute;
  top: 1px;
  right: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 33px;
  height: 33px;
  margin: auto;
  background-color: $ebony-clay;
  border: solid 3px $white;
  border-radius: 100%;
}

.px-notice__wrapper {
  padding: 19px 15px 15px;
  text-align: center;
  background-color: $athens-gray;
  border: solid 1px rgba($manatee, 0.1);
  border-radius: 4px;
}

.px-notice__title {
  @include grotesk(regular);

  font-size: 15px;
  line-height: 22px;

  @include breakpoint-up(md) {
    letter-spacing: -0.15px;
  }
}

.px-notice__cta {
  margin-top: 12px;
}

/* Horizontal */
.px-notice--horizontal {
  @include breakpoint-up(md) {
    padding-top: 0;
  }

  .px-notice__wrapper {
    @include breakpoint-up(md) {
      display: flex;
      flex-flow: row wrap;
      align-items: center;
      justify-content: space-between;
      min-height: 60px;
      padding: 8px 14px 8px 34px;
    }
  }

  .px-notice__title {
    @include breakpoint-up(md) {
      flex-basis: calc(100% - 185px);
      text-align: left;
    }
  }

  .px-notice__title,
  .px-notice__cta {
    @include breakpoint-up(md) {
      margin: 0;
    }
  }

  .px-notice__icon {
    @include breakpoint-up(md) {
      top: 5px;
      right: auto;
      left: -17px;
    }
  }
}
</style>
