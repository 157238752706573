import {
  EProviderFeatures,
  GenericProvider,
} from "@/services/data/generic-provider";
import { IAffiliate } from "@/services/data/affiliate/affiliate.interface";
import { IQuestionBundle } from "@/services/data/question-bundle/question-bundle.interface";

class AffiliateProvider extends GenericProvider<IAffiliate> {
  constructor() {
    super(`/affiliates`, [EProviderFeatures.GET, EProviderFeatures.LIST]);
  }

  /**
   * Get a question bundle by affiliate.
   *
   * @param affiliate Affiliate id.
   */
  public async getQuestionBundles(
    affiliate: number,
  ): Promise<Array<IQuestionBundle>> {
    const url = super.buildEndPointUrl({}, `${affiliate}/question-bundles`);
    const request = this.httpClient.get(url);
    const { data } = await this.wrapRequest(request);

    if (!data || data.length === 0) {
      return [];
    }

    return data;
  }

  /**
   * Get the default supporter affiliate.
   */
  public async getSupporterDefault(): Promise<IAffiliate> {
    const url = super.buildEndPointUrl({}, "supporter/default/program");
    const request = this.httpClient.get(url);
    const { data } = await this.wrapRequest(request);

    return data;
  }

  /**
   * Get the supporter affiliate.
   *
   * @param affiliate Affiliate id.
   */
  public async getSupporter(affiliate: number): Promise<IAffiliate> {
    const url = super.buildEndPointUrl({}, `supporter/${affiliate}`);
    const request = this.httpClient.get(url);
    const { data } = await this.wrapRequest(request);

    return data;
  }
}

export const affiliateProvider = new AffiliateProvider();
