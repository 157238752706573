import groupBy from "lodash/groupBy";
import sortBy from "lodash/sortBy";

import {
  IGridCategory,
  IGridMilestone,
} from "../../components/milestones-grid/milestones-grid.interface";
import { IMilestone } from "../data/milestones/milestone.interface";
import { ICategory } from "@/services/data/category/category.interface";

/**
 * Convert list of milestones into list of grid milestones.
 *
 * Initially, we need to create milestones for all levels of all categories as
 * future milestones.
 *
 * This is due to the fact that only milestones that have planning or
 * completion information are represented in the database and are therefore
 * returned by the backend API, so we need to setup the mentioned boilderplate
 * so that afterwards we can replace some of it with the corresponding database
 * represented milestones.
 *
 * @param {ICategory} category
 * @param {IMilestone[]} milestones
 * @return {*}  {IGridMilestone[]}
 */
const getGridCategoryMilestones = (
  category: ICategory,
  milestones: IMilestone[],
): IGridMilestone[] => {
  /**
   * Initial boilerplate consisting of future milestones for all levels of all
   * categories, sorted by level.
   */
  const futureMilestones: IGridMilestone[] = sortBy(category.categoryDetails, [
    (categoryMilestone) => categoryMilestone.level.value,
  ]).map((categoryMilestone) => ({
    completed: false,
    critical: false,
    description: categoryMilestone.achievements,
    evidenceProvided: false,
    future: true,
    hasCompletionInfo: false,
    inProgress: false,
    level: categoryMilestone.level.value,
    planned: false,
    targetDate: null,
  }));

  // Milestones represented in the database.
  const userMilestones: IGridMilestone[] = milestones.map((milestone) => ({
    completed: milestone.completed,
    critical: milestone.critical,
    description: milestone.category_level.achievements,
    evidenceProvided: milestone.evidence_provided,
    future: false,
    hasCompletionInfo:
      !!milestone.evidence.length || !!milestone.date_of_completion,
    inProgress: milestone.in_progress,
    level: milestone.category_level.level,
    planned: milestone.plan_published,
    targetDate: milestone.target_date,
  }));

  /**
   * Consolidating the milestones represented in the database within
   * the boilerplate.
   */
  return futureMilestones.reduce(
    (gridMilestones: IGridMilestone[], milestone, index) => {
      const correspondingMilestone = userMilestones.find(
        (providedMilestone) => providedMilestone.level === milestone.level,
      );
      const isFirstLevelOrAfterLastCompleteMilestone =
        index === 0 || gridMilestones[index - 1].completed;

      if (correspondingMilestone) {
        gridMilestones.push({
          ...correspondingMilestone,
          future: !isFirstLevelOrAfterLastCompleteMilestone,
        });
      } else {
        gridMilestones.push({
          ...milestone,
          future: !isFirstLevelOrAfterLastCompleteMilestone,
        });
      }

      return gridMilestones;
    },
    [],
  );
};

/**
 * Parse list of milestones and group them into a list of grid categories.
 *
 * @param {IMilestone[]} milestones
 * @param {ICategory[]} categories
 * @return {*}  {IGridCategory[]}
 */
export const parseMilestonesIntoGridCategories = (
  milestones: IMilestone[],
  categories: ICategory[],
): IGridCategory[] => {
  const milestonesByCategoryName = groupBy(
    milestones,
    (milestone) => milestone.category_level.category.name,
  );

  return categories.map(
    (category): IGridCategory => ({
      abbreviation: category.abbreviation,
      color: `#${category.color}`,
      id: category.id,
      milestones: getGridCategoryMilestones(
        category,
        milestonesByCategoryName[category.name] || [],
      ),
      name: category.name,
      order: category.order,
    }),
  );
};
