<template>
  <div class="milestone-stage-status-selector">
    <div class="milestone-stage-status-selector__wrapper">
      <div
        v-for="(status, index) in stageStatus"
        :key="index"
        class="milestone-stage-status-selector__item"
      >
        <MilestoneStageStatusItem
          :active="index === activeState"
          :icon="status.icon"
          :title="status.title"
          @status-click="clickStageStatusHandler(status.title, index)"
        />
        <div class="milestone-stage-status-selector__arrow" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import MilestoneStageStatusItem from "@/modules/milestone-planner/components/milestone-stage-status-item/milestone-stage-status-item.vue";
import { EMilestoneStatusType } from "@/modules/milestone-planner/services/data/milestones/milestone.interface";

/**
 * Selector used to iterate over the cell state on the milestone grid represented in the onboarding modal
 */
export default defineComponent({
  name: "MilestoneStageStatusSelector",

  components: {
    MilestoneStageStatusItem,
  },

  props: {
    selectedStatus: {
      type: Number,
      default: 0,
    },
  },

  data() {
    return {
      stageStatus: [
        {
          title: this.$t(
            "milestonePlanner.milestoneGrid.stageStatus.toBePlanned",
          ),
          icon: EMilestoneStatusType.TO_BE_PLANNED,
        },
        {
          title: this.$t("milestonePlanner.milestoneGrid.stageStatus.planned"),
          icon: EMilestoneStatusType.PLANNED,
        },
        {
          title: this.$t(
            "milestonePlanner.milestoneGrid.stageStatus.inProgress",
          ),
          icon: EMilestoneStatusType.IN_PROGRESS,
        },
        {
          title: this.$t(
            "milestonePlanner.milestoneGrid.stageStatus.completed",
          ),
          icon: EMilestoneStatusType.COMPLETED,
        },
      ],
      activeState: this.selectedStatus,
    };
  },

  watch: {
    selectedStatus(newValue) {
      this.activeState = newValue;
    },
  },

  methods: {
    /**
     * Handle stage status click
     */
    clickStageStatusHandler(status: string, index: number) {
      this.activeState = index;

      // We need to add 3 to the index because of the previous pages in the onboarding dialog
      this.$emit("selected-status", index + 3, status);
    },
  },
});
</script>

<style lang="scss" scoped>
.milestone-stage-status-selector {
  position: relative;
  width: 666px;
  height: 40px;
  padding-top: 5px;
  padding-left: 9px;
  margin: 0;
  background-color: $athens-gray;
  border-radius: 4px;

  &__wrapper {
    display: grid;
    grid-template-columns: 182px 144px 166px 129px;
    grid-gap: 12px;
  }

  &__arrow {
    position: relative;
    bottom: 1px;
    left: 6px;
    width: 38px;
    height: 1px;
    margin: 0 auto;
    background: $manatee;

    &::before,
    &::after {
      position: absolute;
      width: 6px;
      height: 1px;
      content: "";
      background: $manatee;
    }

    &::before {
      right: -2px;
      bottom: -2px;
      transform: rotate(-45deg);
    }

    &::after {
      top: -2px;
      right: -2px;
      transform: rotate(45deg);
    }
  }

  &__item {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    &:last-of-type {
      right: 4px;

      .milestone-stage-status-selector__arrow {
        display: none;
      }
    }
  }
}

.milestone-stage-status-selector__item:nth-child(1) :deep() img {
  right: 2px;
}
</style>
