import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ElTag = _resolveComponent("ElTag")!

  return (_openBlock(), _createBlock(_component_ElTag, {
    effect: "plain",
    color: _ctx.color,
    class: "px-label"
  }, {
    default: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.text), 1)
    ]),
    _: 1
  }, 8, ["color"]))
}