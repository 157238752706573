import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, mergeProps as _mergeProps, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-3300ed75"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "px-menu__dropdown-toggler" }
const _hoisted_2 = { class: "px-menu__item-link" }
const _hoisted_3 = { class: "px-menu__item-link" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = {
  class: "px-menu__item-link",
  href: "javascript:void(0)"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PxStatusBadge = _resolveComponent("PxStatusBadge")!
  const _component_ElDropdownItem = _resolveComponent("ElDropdownItem")!
  const _component_ElDropdownMenu = _resolveComponent("ElDropdownMenu")!
  const _component_ElDropdown = _resolveComponent("ElDropdown")!
  const _component_RouterLink = _resolveComponent("RouterLink")!
  const _component_PxIcon = _resolveComponent("PxIcon")!

  return (_openBlock(), _createElementBlock("ul", {
    class: _normalizeClass([`px-menu--${_ctx.mode}`, "px-menu"])
  }, [
    (_ctx.useRouter)
      ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.items, (item) => {
          return (_openBlock(), _createElementBlock(_Fragment, {
            key: item.key
          }, [
            (_ctx.showSubMenuItemsDropdown(item))
              ? (_openBlock(), _createBlock(_component_ElDropdown, {
                  key: 0,
                  class: _normalizeClass([[{ 'is-active': _ctx.checkItemShouldBeActive(item) }], "px-menu__dropdown"]),
                  "popper-class": "px-menu__dropdown-menu"
                }, {
                  dropdown: _withCtx(() => [
                    _createVNode(_component_ElDropdownMenu, null, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.subMenuItems, (subitem) => {
                          return (_openBlock(), _createBlock(_component_ElDropdownItem, {
                            key: subitem.key,
                            onClick: ($event: any) => (_ctx.goToPage(subitem.to))
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(subitem.label) + " ", 1),
                              (subitem.newFeature)
                                ? (_openBlock(), _createBlock(_component_PxStatusBadge, _mergeProps({
                                    key: 0,
                                    ref_for: true
                                  }, _ctx.$options.static.newFeatureLabel), null, 16))
                                : _createCommentVNode("", true)
                            ]),
                            _: 2
                          }, 1032, ["onClick"]))
                        }), 128))
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_1, [
                      _createElementVNode("p", null, _toDisplayString(item.label), 1),
                      (item.newFeature)
                        ? (_openBlock(), _createBlock(_component_PxStatusBadge, _mergeProps({
                            key: 0,
                            ref_for: true
                          }, _ctx.$options.static.newFeatureLabel), null, 16))
                        : _createCommentVNode("", true)
                    ])
                  ]),
                  _: 2
                }, 1032, ["class"]))
              : (_ctx.showSubMenuItem(item) && item.subMenuItems)
                ? (_openBlock(), _createBlock(_component_RouterLink, {
                    key: 1,
                    class: _normalizeClass([{ 'is-active': _ctx.checkItemShouldBeActive(item) }, "px-menu__item"]),
                    to: { name: item.subMenuItems[0].to }
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("li", null, [
                        _createElementVNode("a", _hoisted_2, _toDisplayString(item.subMenuItems[0].label), 1)
                      ])
                    ]),
                    _: 2
                  }, 1032, ["class", "to"]))
                : (_openBlock(), _createBlock(_component_RouterLink, {
                    key: 2,
                    class: _normalizeClass([{ 'is-active': _ctx.checkItemShouldBeActive(item) }, "px-menu__item"]),
                    to: { name: item.to }
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("li", null, [
                        _createElementVNode("a", _hoisted_3, _toDisplayString(item.label), 1)
                      ]),
                      (item.icon)
                        ? (_openBlock(), _createBlock(_component_PxIcon, {
                            key: 0,
                            name: item.icon,
                            size: "12"
                          }, null, 8, ["name"]))
                        : _createCommentVNode("", true)
                    ]),
                    _: 2
                  }, 1032, ["class", "to"]))
          ], 64))
        }), 128))
      : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.items, (item) => {
          return (_openBlock(), _createElementBlock("li", {
            key: item.key,
            class: _normalizeClass([{
          'is-active': _ctx.checkItemShouldBeActive(item) || _ctx.innerValue === item.key,
        }, "px-menu__item"]),
            onClick: ($event: any) => (_ctx.onClickItem(item.key))
          }, [
            _createElementVNode("a", _hoisted_5, _toDisplayString(item.label), 1),
            (item.icon)
              ? (_openBlock(), _createBlock(_component_PxIcon, {
                  key: 0,
                  name: item.icon,
                  class: "px-menu__icon",
                  size: "12"
                }, null, 8, ["name"]))
              : _createCommentVNode("", true)
          ], 10, _hoisted_4))
        }), 128))
  ], 2))
}