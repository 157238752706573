import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-6cb0c854"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([{ 'is-negative': _ctx.remainingChars < 0 }, "counter"])
  }, [
    _createElementVNode("span", null, _toDisplayString(_ctx.remainingChars), 1),
    (_ctx.showLabel)
      ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.remainingCharsCopy), 1))
      : _createCommentVNode("", true)
  ], 2))
}