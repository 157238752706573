import { ICompanyList } from "@/modules/company-lists/services/data/company-list/company-list.interface";
import { IGenericState } from "@/services/store/generic/generic-state.interface";

/**
 * Represents the store structure for the shared company list type store data.
 */
export type ICompanySharedListState = IGenericState<ICompanyList>;

export enum ECompanySharedListState {
  NAMESPACE = "sharedList",
}

export enum ECompanySharedListAction {
  GET_VALUE = "getValue",
  GET_VALUES = "getValues",
  CREATE_VALUE = "createValue",
  PATCH_VALUE = "patchValue",
  REMOVE_VALUE = "removeValue",
}
