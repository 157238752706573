import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-03698fce"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "compare-dropdowns" }
const _hoisted_2 = { class: "compare-dropdowns__header-left" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PxIcon = _resolveComponent("PxIcon")!
  const _component_ElTooltip = _resolveComponent("ElTooltip")!
  const _component_PxButton = _resolveComponent("PxButton")!
  const _component_ElOption = _resolveComponent("ElOption")!
  const _component_ElSelect = _resolveComponent("ElSelect")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("header", null, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("p", null, _toDisplayString(_ctx.$t("chartsDashboard.compare.title")), 1),
        _createVNode(_component_ElTooltip, {
          placement: "top",
          enterable: false,
          "popper-class": "compare-dropdowns__tooltip-popper el-abaca-tooltip"
        }, {
          content: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.tooltipText), 1)
          ]),
          default: _withCtx(() => [
            _createVNode(_component_PxIcon, {
              size: 15,
              name: "question"
            })
          ]),
          _: 1
        })
      ]),
      (_ctx.firstStage !== '' || _ctx.secondStage !== '')
        ? (_openBlock(), _createBlock(_component_PxButton, {
            key: 0,
            class: "el-button--link-blue el-button--small",
            type: "link",
            onClick: _ctx.clear
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("chartsDashboard.compare.clear")), 1)
            ]),
            _: 1
          }, 8, ["onClick"]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("main", null, [
      _createVNode(_component_ElSelect, {
        modelValue: _ctx.firstStage,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.firstStage) = $event)),
        placeholder: _ctx.$t('chartsDashboard.compare.selectPlaceholder'),
        disabled: _ctx.isSelectDisabled,
        class: _normalizeClass({ empty: _ctx.firstStage === '' }),
        onChange: _ctx.change
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option) => {
            return (_openBlock(), _createBlock(_component_ElOption, {
              key: `first-stage--${option.label}`,
              value: option.index,
              label: option.label,
              disabled: option.index === _ctx.secondStage || option.disabled
            }, null, 8, ["value", "label", "disabled"]))
          }), 128))
        ]),
        _: 1
      }, 8, ["modelValue", "placeholder", "disabled", "class", "onChange"]),
      _createVNode(_component_ElSelect, {
        modelValue: _ctx.secondStage,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.secondStage) = $event)),
        placeholder: _ctx.$t('chartsDashboard.compare.selectPlaceholder'),
        disabled: _ctx.isSelectDisabled,
        class: _normalizeClass({ empty: _ctx.secondStage === '' }),
        onChange: _ctx.change
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option) => {
            return (_openBlock(), _createBlock(_component_ElOption, {
              key: `second-stage--${option.label}`,
              value: option.index,
              label: option.label,
              disabled: option.index === _ctx.firstStage || option.disabled
            }, null, 8, ["value", "label", "disabled"]))
          }), 128))
        ]),
        _: 1
      }, 8, ["modelValue", "placeholder", "disabled", "class", "onChange"])
    ])
  ]))
}