import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-542bd4f4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "px-step-by-step-dialog-navigation" }
const _hoisted_2 = { class: "px-step-by-step-dialog-navigation__section px-step-by-step-dialog-navigation__section--left" }
const _hoisted_3 = { class: "px-step-by-step-dialog-navigation__steps" }
const _hoisted_4 = { class: "px-step-by-step-dialog-navigation__section px-step-by-step-dialog-navigation__section--right" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PxButton = _resolveComponent("PxButton")!
  const _component_PxNavigationStep = _resolveComponent("PxNavigationStep")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.currentPage > 1)
        ? (_openBlock(), _createBlock(_component_PxButton, _mergeProps({
            key: 0,
            ref: "previousButton"
          }, _ctx.previousButtonProps, {
            class: "px-step-by-step-dialog-navigation__button",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.goToPage(_ctx.currentPage - 1)))
          }), null, 16))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_3, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.pageCount, (page) => {
        return (_openBlock(), _createBlock(_component_PxNavigationStep, {
          key: page,
          active: page === _ctx.currentPage,
          onClick: ($event: any) => (_ctx.goToPage(page))
        }, null, 8, ["active", "onClick"]))
      }), 128))
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_PxButton, _mergeProps({ ref: "nextButton" }, _ctx.nextButtonProps, {
        class: "px-step-by-step-dialog-navigation__button",
        onClick: _ctx.nextButtonClick
      }), null, 16, ["onClick"])
    ])
  ]))
}