<template>
  <div class="auth-signup__step signup-step-supporter">
    <PxPanel class="px-panel--compact">
      <template #header>
        <h2
          class="px-panel__title"
          v-html="$t('supporters.signup.form.supporterInterests')"
        />
      </template>
      <ElForm
        ref="signupForm"
        :model="data"
        :rules="rules"
        class="auth-signup__form"
        @validate="updateSubmitState"
      >
        <ElFormItem prop="sectors">
          <MultiSelector
            v-model="data.sectors"
            :fetch-on-created="true"
            :remote-method="searchSectors"
            :subtext-label-list="[
              'common.components.multiSelector.sectors.subLabel',
              'common.components.multiSelector.sectors.subLabelPlural',
            ]"
            label="supporters.signup.form.fields.sector"
            placeholder="supporters.signup.form.placeholders.sector"
            tip="supporters.signup.form.tips.sector"
          />
        </ElFormItem>
        <ElFormItem
          :label="$t('supporters.signup.form.fields.locations')"
          prop="locations"
        >
          <PxRadioGroup v-model="hasSpecificLocations">
            <ElRadio :label="true">
              {{ $t("supporters.signup.form.locationOptions.specific") }}
            </ElRadio>
            <ElRadio :label="false">
              {{ $t("supporters.signup.form.locationOptions.all") }}
            </ElRadio>
          </PxRadioGroup>
          <MultiSelector
            v-show="hasSpecificLocations"
            v-model="data.locations"
            :no-label="true"
            :remote-method="searchLocations"
            :subtext-label-list="[
              'common.components.multiSelector.locations.subLabel',
              'common.components.multiSelector.locations.subLabelPlural',
            ]"
            :validate-event="true"
            class="locations-selector"
            input-icon="location"
            placeholder="supporters.signup.form.placeholders.locations"
            tip="supporters.signup.form.tips.location"
          />
        </ElFormItem>
        <ElFormItem prop="networks">
          <NetworksSelector
            ref="networkSelector"
            v-model="data.networks"
            :label-copy="$t(`profile.networks.modal.label`)"
            :placeholder-copy="
              $t(`supporters.signup.form.placeholders.network`)
            "
          />
          <span
            class="el-form-item__bottom-text el-form-item__tip"
            v-html="$t('supporters.signup.form.tips.network')"
          />
        </ElFormItem>
        <div class="el-form__cta">
          <button
            class="el-button el-button--link el-button--small"
            type="button"
            @click="goToPreviousStep"
          >
            <span>
              {{ $t("authentication.signup.form.previousButton") }}
            </span>
          </button>
          <PxButton
            :disabled="stepDisabled"
            size="medium"
            type="primary"
            @click="goToNextStep"
          >
            {{ $t("supporters.signup.form.stepButton") }}
          </PxButton>
        </div>
      </ElForm>
    </PxPanel>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import cloneDeep from "lodash/cloneDeep";

import NetworksSelector from "@/components/networks-selector/networks-selector.vue";

import MultiSelector from "@/components/multi-selector/multi-selector.vue";
import { IMultiSelection } from "@/components/multi-selector/multi-selector.interface";
import {
  formatMultiSectorsSelection,
  multiSelectorSectorsProvider,
} from "@/components/multi-selector/multi-selector-sectors.provider";
import {
  formatMultiLocationsSelection,
  multiSelectorLocationsProvider,
} from "@/components/multi-selector/multi-selector-locations.providers";

import { networkProvider } from "@/services/data/network/network.provider";
import { INetwork } from "@/services/data/network/network.interface";
import { ESupporterInvestingLevelGetters } from "@/modules/supporters/services/store/supporter-investing-level/supporter-investing-level-types";
import {
  allFormFieldsValid,
  generateSupporterLocationsValidator,
} from "@/services/errors/validator-generators";

export default defineComponent({
  name: "SignUpStepSupporterInterests",

  components: {
    NetworksSelector,
    MultiSelector,
  },

  props: {
    supporter: {
      type: Object as () => any,
      required: true,
    },
  },

  data() {
    return {
      stepDisabled: false,
      hasSpecificLocations: false,

      data: {
        locations: [] as Array<IMultiSelection>,
        sectors: [] as Array<IMultiSelection>,
        networks: [] as Array<INetwork>,
      },
      rules: {
        locations: generateSupporterLocationsValidator(
          this,
          "hasSpecificLocations",
        ),
      },
    };
  },

  computed: {
    signupFormRef(): any {
      return this.$refs.signupForm;
    },

    networkMetadata(): string | null {
      return this.$store.getters[
        ESupporterInvestingLevelGetters.SINGLE_METADATA
      ]("network");
    },

    supporterInterests(): any {
      return {
        networks: this.data.networks,
        // Divide location's selection into grouped locations and google places
        ...formatMultiLocationsSelection(this.data.locations),
        // Divide sector's selection into sectors and grouped_sectors
        ...formatMultiSectorsSelection(this.data.sectors),
      };
    },
  },

  watch: {
    hasSpecificLocations(enabled: boolean) {
      const hasSelectedLocations = !enabled && this.data.locations.length;

      if (hasSelectedLocations) {
        this.data.locations = [];
      }
    },

    "supporterInterests.sectors": {
      deep: true,
      handler() {
        this.updateSubmitState();
      },
    },

    "supporterInterests.networks": {
      deep: true,
      handler() {
        this.updateSubmitState();
      },
    },
  },

  async created() {
    // Add network pre-fill using metadata
    if (this.networkMetadata) {
      const networks = (await networkProvider.list({
        search: this.networkMetadata,
      })) as Array<INetwork>;

      await this.$nextTick();
      const networkSelector = this.$refs.networkSelector as any;
      if (networkSelector && networks) {
        networkSelector.setActiveNetworks(cloneDeep(networks));
      }
    }
  },

  mounted() {
    this.signupFormRef.validateField("locations");
  },

  methods: {
    // Reuse provider for this component
    searchSectors: multiSelectorSectorsProvider,

    searchLocations: multiSelectorLocationsProvider,

    updateSubmitState() {
      this.stepDisabled = !allFormFieldsValid(this.signupFormRef, this.rules);

      if (!this.stepDisabled) {
        this.$emit("update:supporter", this.supporterInterests);
      }
    },

    goToPreviousStep() {
      this.$emit("previous-step");
    },

    goToNextStep() {
      this.$emit("next-step");
    },
  },
});
</script>

<style lang="scss" scoped>
.auth-signup__form :deep() {
  .el-radio-group {
    display: inline-flex;
    flex-flow: row wrap;
    width: 100%;
    margin-top: 7px;
  }

  .el-radio {
    flex-basis: 50%;
    margin-left: 0;

    &:nth-child(n + 3) {
      min-width: 122px;
      margin-top: 10px;
    }
  }

  .locations-selector {
    margin-top: 4px;
  }

  .locations-selector__list:empty + .locations-input {
    margin-top: 11px;
  }

  .locations-selector__list--with-entries {
    margin-top: 11px;
  }
}
</style>
