<template>
  <ElDialog
    v-model="innerVisibility"
    :append-to-body="true"
    :title="$t('common.components.submissionsModal.title', { company })"
    class="submissions-modal"
  >
    <PxTableHeader
      :items="tableHeaders"
      class="submissions-modal__table-header"
    />
    <div ref="table" class="submissions-modal__table">
      <SubmissionsTableRow
        v-for="(submission, index) in mappedSubmissionsData"
        :key="index"
        :is-modal-view="true"
        :submission-data="submission"
        data-testid="submissions-modal-table-row"
        type="submitted-submissions"
      />
    </div>
    <template #footer>
      <PxButton
        v-bind="actionButtonProps"
        data-testid="submissions-modal-cta"
        @click="goToSubmissionsTrackerPage"
      />
    </template>
  </ElDialog>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import ElDialogMixin from "@/mixins/el-dialog.mixin";
import SubmissionsTableRow from "@/modules/submissions/components/submissions-table/submissions-table-row.vue";
import PxTableHeader, {
  ITableHeaderItems,
} from "@/components/px-table-header/px-table-header.vue";

import { EPxButtonType } from "@/components/px-button/px-button.types";
import { ROUTE_OWNED_AFFILIATES_SUBMISSIONS } from "@/modules/profile/services/router/routes-names";
import { ENTREPRENEUR_USER_TYPE } from "@/modules/authentication/constants";

export default defineComponent({
  name: "SubmissionsModal",

  components: {
    SubmissionsTableRow,
    PxTableHeader,
  },

  mixins: [ElDialogMixin],

  props: {
    data: {
      type: Array as () => Array<any>,
      required: true,
    },

    company: {
      type: String,
      required: true,
    },
  },

  computed: {
    actionButtonProps(): object {
      return {
        label: this.$t("common.components.submissionsModal.cta"),
        type: EPxButtonType.BLUE,
      };
    },

    tableHeaders() {
      return this.$tm(
        "common.components.submissionsModal.table.headers",
      ) as ITableHeaderItems[];
    },

    mappedSubmissionsData(): Array<any> {
      return this.data.map((item: any) => ({
        ...item,
        submission_uid: item.uid,
        company_type: ENTREPRENEUR_USER_TYPE,
      }));
    },
  },

  methods: {
    goToSubmissionsTrackerPage() {
      return this.$router.push({
        name: ROUTE_OWNED_AFFILIATES_SUBMISSIONS,
        query: {
          company: decodeURIComponent(this.company),
        },
      });
    },
  },
});
</script>

<style lang="scss">
.submissions-modal {
  &.el-dialog {
    max-width: 903px;
    width: 100%;
  }

  .el-dialog__body {
    padding: 30px 30px 0 30px;
  }

  .el-dialog__header {
    padding: 13px 56px 14px 30px;
  }

  .el-dialog__title {
    font-size: to-rem(22px);
    letter-spacing: -0.3px;
  }

  .el-dialog__headerbtn {
    right: 28px;
    top: 23px;
  }

  .el-dialog__footer {
    background: $alabaster;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
    text-align: center;

    &::before {
      width: 100%;
    }
  }

  .px-button {
    padding: 0 15px;
    top: 0;
  }

  .submissions-table-row:not(:last-of-type) {
    border-bottom: 1px solid $athens-gray;
  }

  .submissions-info-badges {
    grid-template-columns: repeat(3, 1fr);
    margin-right: 0;

    @include breakpoint-down(md) {
      grid-template-columns: 1fr;
    }
  }

  .submissions-info-badges .px-status-badge {
    width: max-content;
    padding: 4px 9px;

    @include breakpoint-down(md) {
      width: 100%;
    }
  }

  .submissions-table-row__last-col {
    justify-content: flex-end;
    flex-direction: row;
    padding-right: 12px;

    .px-button {
      padding: 0 12px 0 13px;
      right: 0;
    }
  }

  &__table {
    height: 273px;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
      margin: 10px;
    }

    &::-webkit-scrollbar-thumb {
      background: $mischka;
      border-radius: 10px;
    }
  }

  &__table-header.px-table-header {
    display: grid;
    grid-template-columns: 61.2% 1fr;
    padding: 0 0 12px;

    .px-table-header__item p {
      letter-spacing: 0;
    }

    .px-table-header__item .icon-question {
      width: 14px;
      height: 14px;
      margin-left: 5px;
    }
  }
}
</style>
