import { Module } from "vuex";
import { IMatchingResponsesState } from "./matching-responses.types";
import { RootState } from "@/services/store/root-state";
import { make } from "vuex-pathify";
import { matchingResponsesActions } from "./matching-responses.actions";
import { matchingResponsesGetters } from "@/modules/matching/services/store/matching-responses/matching-responses.getters";

const state: IMatchingResponsesState = {
  data: [],
  meta: {},
  error: null,
  loading: false,
};

export const matchingResponsesModule: Module<
  IMatchingResponsesState,
  RootState
> = {
  namespaced: true,
  state,
  actions: matchingResponsesActions,
  mutations: {
    ...make.mutations(state),
  },
  getters: matchingResponsesGetters,
};
