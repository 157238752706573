<template>
  <div class="compare-dropdowns">
    <header>
      <div class="compare-dropdowns__header-left">
        <p>{{ $t("chartsDashboard.compare.title") }}</p>
        <ElTooltip
          placement="top"
          :enterable="false"
          popper-class="compare-dropdowns__tooltip-popper el-abaca-tooltip"
        >
          <template #content>
            {{ tooltipText }}
          </template>
          <PxIcon :size="15" name="question" />
        </ElTooltip>
      </div>
      <PxButton
        v-if="firstStage !== '' || secondStage !== ''"
        class="el-button--link-blue el-button--small"
        type="link"
        @click="clear"
      >
        {{ $t("chartsDashboard.compare.clear") }}
      </PxButton>
    </header>
    <main>
      <ElSelect
        v-model="firstStage"
        :placeholder="$t('chartsDashboard.compare.selectPlaceholder')"
        data-testid="compare-dropdowns-first-stage"
        :disabled="isSelectDisabled"
        :class="{ empty: firstStage === '' }"
        @change="change"
      >
        <ElOption
          v-for="option in options"
          :key="`first-stage--${option.label}`"
          :value="option.index"
          :label="option.label"
          :disabled="option.index === secondStage || option.disabled"
          data-testid="compare-dropdowns-first-stage-option"
        />
      </ElSelect>
      <ElSelect
        v-model="secondStage"
        :placeholder="$t('chartsDashboard.compare.selectPlaceholder')"
        data-testid="compare-dropdowns-second-stage"
        :disabled="isSelectDisabled"
        :class="{ empty: secondStage === '' }"
        @change="change"
      >
        <ElOption
          v-for="option in options"
          :key="`second-stage--${option.label}`"
          :value="option.index"
          :label="option.label"
          :disabled="option.index === firstStage || option.disabled"
          data-testid="compare-dropdowns-second-stage-option"
        />
      </ElSelect>
    </main>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { TranslateResult } from "vue-i18n";

export interface ICompareDropdownOption {
  label: string;
  index: number;
  disabled: boolean;
}

export default defineComponent({
  name: "CompareDropdowns",

  props: {
    options: {
      type: Array as () => ICompareDropdownOption[],
      required: true,
    },
  },
  data() {
    return {
      firstStage: "" as string | number,
      secondStage: "" as string | number,
    };
  },

  computed: {
    tooltipText(): TranslateResult {
      return this.$t("chartsDashboard.compare.tooltip");
    },

    isSelectDisabled(): boolean {
      return this.options.filter((option) => !option.disabled).length === 1;
    },
  },

  methods: {
    change() {
      this.$emit("change", [this.firstStage, this.secondStage]);
    },

    clear() {
      this.firstStage = "";
      this.secondStage = "";

      this.$emit("change", [null, null]);
    },
  },
});
</script>

<style lang="scss" scoped>
.compare-dropdowns {
  display: flex;
  flex-direction: column;
  gap: 8px;
  min-width: 377px;

  header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__header-left {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
  }

  p {
    margin: 0;

    font-size: 12px;
    line-height: 1;
    font-weight: 600;
    color: $ebony-clay;
    text-transform: uppercase;
  }

  .px-icon:hover {
    filter: brightness(0.55);
  }

  .el-button--link {
    height: auto;
    margin-bottom: 1px;
    padding: 0;

    :deep(span) {
      line-height: 1;
    }
  }

  main {
    display: flex;
    justify-content: space-between;
    gap: 15px;

    :deep(.el-select__wrapper) {
      padding-right: 16px;
    }
  }

  :deep(.el-select__wrapper.is-disabled) {
    border-color: #e4e7ed;
  }

  :deep(.el-select.empty .el-select__placeholder span) {
    color: $manatee;
  }
}
</style>
<style lang="scss">
.compare-dropdowns__tooltip-popper {
  width: 451px;
  text-transform: none;
  padding: 10px 15px;
  letter-spacing: -0.2px;
}
</style>
