<template>
  <div
    :class="{
      'matching-card-compact--selected': isSelected,
      'matching-card-compact--faded': isFaded && !isDisabled,
      'matching-card-compact--toggle': isOptionsOpen,
      'matching-card-compact--disabled': isDisabled,
    }"
    class="matching-card-compact"
  >
    <slot name="action" />
    <div class="matching-card-compact__section-wrapper">
      <slot />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "MatchingCardCompactWrapper",

  props: {
    isSelected: {
      type: Boolean,
      default: false,
    },

    isFaded: {
      type: Boolean,
      default: false,
    },

    isOptionsOpen: {
      type: Boolean,
      default: false,
    },

    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
});
</script>

<style lang="scss" scoped>
.matching-card-compact {
  position: relative;
  z-index: z("floaters");
  width: 290px;
  height: 104px;
  padding: 15px 50px;
  overflow: hidden;
  cursor: pointer;
  border: 1px solid rgba($mischka, 0);
  border-radius: 4px;

  @include breakpoint-up(md) {
    width: 1096px;
    transition:
      border-color 0.1s ease-in-out,
      transform 0.1s ease-in-out;
  }

  &::after {
    @include breakpoint-up(md) {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      content: "";
      box-shadow: 0 2px 4px 0 rgba($manatee, 0.3);
      opacity: 0;
      transition: opacity 0.1s ease-in-out;
    }
  }
}
.matching-card-compact--toggle,
.matching-card-compact:hover {
  @include breakpoint-up(md) {
    transform: translateY(-2px);
  }

  &::after {
    @include breakpoint-up(md) {
      opacity: 1;
    }
  }
}
.matching-card-compact--toggle:not(.matching-card-compact--selected),
.matching-card-compact:not(.matching-card-compact--selected):hover {
  border-color: $mischka;
}

.matching-card-compact--selected {
  border: 1px solid $malibu;
}

.matching-card-compact--selected,
.matching-card-compact--faded {
  cursor: default;
}

.matching-card-compact--faded:not(.matching-card:hover) {
  opacity: 0.7;
}

.matching-card-compact--disabled {
  pointer-events: none;

  @include breakpoint-up(md) {
    &::after {
      position: absolute;
      top: -1px;
      right: -1px;
      bottom: -1px;
      left: -1px;
      z-index: z("floaters") + 10;

      background-color: $white;
      border: 1px solid rgba($mischka, 0);
      border-radius: 4px;
      opacity: 0.4;
    }
  }
}

.matching-card-compact__section-wrapper {
  display: flex;
  height: 72px;
  overflow: visible;
}

.matching-card-compact--selected .matching-card-compact__section-wrapper,
.matching-card-compact--faded .matching-card-compact__section-wrapper {
  pointer-events: none;
  user-select: none;
}
</style>
