<template>
  <div class="category-levels-assessment-mobile">
    <div class="container-fluid">
      <CategoryLevelsAssessmentMobilePlaceholder v-if="isLoading" />
      <MobileLevelSelect
        v-if="!isLoading && currentCategory && selectedStep"
        :category-details="currentCategory.categoryDetails"
        :current-level="selectedStep.level"
        @change-level="onLevelChange"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import CategoryLevelsAssessmentMobilePlaceholder from "@/modules/affiliates/components/category-levels-assessment/category-levels-assessment-mobile-placeholder.vue";
import MobileLevelSelect from "@/modules/affiliates/components/mobile-level-select/mobile-level-select.vue";

import { ICategory } from "@/services/data/category/category.interface";
import { ICategoryLevelSelection } from "@/services/data/viral-level/viral-level.interface";
import { ILevel } from "@/services/data/level/level.interface";

export default defineComponent({
  name: "CategoryLevelsAssessmentMobile",

  components: {
    MobileLevelSelect,
    CategoryLevelsAssessmentMobilePlaceholder,
  },

  props: {
    /**
     * Current selected category.
     */
    currentCategory: {
      type: Object as () => ICategory,
      default: () => null,
    },

    /**
     * Current selected category level.
     */
    selectedStep: {
      type: Object as () => ICategoryLevelSelection & {
        level: ILevel["value"];
      },
      default: () => null,
    },

    /**
     * Handler to be executed when the category
     * level changes.
     */
    onLevelChange: {
      type: Function as () => any,
      required: true,
    },
  },

  computed: {
    isLoading(): boolean {
      return this.$store.state.affiliates.loading;
    },
  },
});
</script>

<style lang="scss" scoped>
.category-levels-assessment-mobile {
  padding-top: 75px;
}
</style>
