import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-92d23e22"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "milestone-category-navigation" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", null, [
      (_ctx.currentCategoryIndex !== 1)
        ? (_openBlock(), _createElementBlock("span", {
            key: 0,
            class: "milestone-category-navigation__arrow milestone-category-navigation__arrow--prev",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.changeCurrentCategoryIndexBy(-1)))
          }))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", null, [
      _createElementVNode("p", null, _toDisplayString(_ctx.selectedCategory), 1)
    ]),
    _createElementVNode("div", null, [
      (_ctx.currentCategoryIndex < _ctx.categories.length)
        ? (_openBlock(), _createElementBlock("span", {
            key: 0,
            class: "milestone-category-navigation__arrow milestone-category-navigation__arrow--next",
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.changeCurrentCategoryIndexBy(1)))
          }))
        : _createCommentVNode("", true)
    ])
  ]))
}