<template>
  <div class="list-detail-panel">
    <ListDetailPanelHeader
      :list-user-type="listUserType"
      :value="value"
      @register="onRegistration"
    />
    <div class="list-detail-panel__body">
      <div v-if="showCompanies">
        <MatchingCardCompactListHeader
          :is-same-type-of-users="isSameTypeOfUsers"
          :user-type="listUserType"
        />
        <VirtualGrid
          :columns="$options.static.virtualGridColumns"
          :grid-gap="$options.static.virtualGridGap"
          :item-height="$options.static.virtualRowHeight"
          :items="membersList"
          :load-on-mount="true"
          @scroll="fetchMoreMatches"
        >
          <template #items="items">
            <MatchingCardCompact
              :key="`compact-card-${items.item.id}`"
              :about="items.item.about"
              :affiliates="items.item.affiliates"
              :badges="items.item.badges"
              :company-id="items.item.id"
              :company-uid="items.item.uid"
              :is-directory-member="items.item.isDirectoryMember"
              :level="items.item.level"
              :location="items.item.location"
              :locations="items.item.locations"
              :match-percentage="items.item.percentage"
              :name="items.item.name"
              :offerings="items.item.offerings"
              :sectors="items.item.sectors"
              :show-connect-button="!isSameTypeOfUsers"
              :show-list-button="isAuthUser && !isSameTypeOfUsers"
              :show-options="showCardOptions"
              :show-percentage="!isSameTypeOfUsers"
              :show-public-view="!isAuthUser"
              :thumbnail="items.item.thumbnail"
              @click="clickMatchingCardHandler(items.item.id)"
              @register="onRegistration"
              @remove="onCompanyRemoval"
            />
          </template>
          <template #loading="loading">
            <MatchingCardCompactPlaceholder
              :key="`virtual-list-placeholder-${loading.item}`"
              :show-percentage-pill="!isSameTypeOfUsers"
            />
          </template>
        </VirtualGrid>
      </div>
      <ListDetailPanelEmpty
        v-else
        :subtitle="emptyListSubtitle"
        :title="emptyListTitle"
        icon="matching-no-matches"
      >
        <PxButton
          v-if="showAddMembersButton"
          class="lists__main-cta"
          size="medium"
          type="green"
          @click="addMembersClickHandler"
        >
          <PxIcon :size="16" name="plus-white" />
          {{ $t("companyLists.detail.addMembersButton") }}
        </PxButton>
      </ListDetailPanelEmpty>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import {
  ICompanyList,
  ISimpleCompanyOfList,
} from "@/modules/company-lists/services/data/company-list/company-list.interface";
import ListDetailPanelHeader from "@/modules/company-lists/components/list-detail-panel/list-detail-panel-header.vue";
import ListDetailPanelEmpty from "@/modules/company-lists/components/list-detail-panel/list-detail-panel-empty.vue";
import { COMPANY_LIST_TYPES } from "@/modules/company-lists/constants";
import MatchingCardCompactListHeader from "@/components/matching-card/matching-card-compact-list-header.vue";
import MatchingCardCompact from "@/components/matching-card/matching-card-compact.vue";
import MatchingCardCompactPlaceholder from "@/components/matching-card/matching-card-compact-placeholder.vue";
import VirtualGrid, {
  IStatusChanger,
} from "@/modules/matching/components/virtual-grid/virtual-grid.vue";
import { ROUTE_PROFILE } from "@/modules/profile/services/router/routes-names";
import { IMatchingCard } from "@/modules/matching/services/data/matching-card/matching-card.interface";
import { EListManagementActions } from "@/modules/company-lists/services/store/list-management/list-management.types";
import { ROUTE_MATCHING_LIST } from "@/modules/matching/services/router/routes-names";
import { CompanyListState } from "@/modules/company-lists/services/store/company-list/company-list.modules";
import { ROUTE_AUTHENTICATION_SIGNUP } from "@/modules/authentication/services/router/routes-names";
import { SUPPORTERS_STARTING_POINT } from "@/modules/supporters/services/router/routes-names";
import {
  ENTREPRENEUR_USER_TYPE,
  SUPPORTER_USER_TYPE,
} from "@/modules/authentication/constants";
import { MATCHING_BADGE_DIRECTORY_LISTING } from "@/modules/matching/constants";
import { EUserGuestGetters } from "@/services/store/user-guest/user-guest.types";
import { IUserGuest } from "@/services/data/user-guest/user-guest.interface";
import { activeModules } from "@/services/utils/utils";

export default defineComponent({
  name: "ListDetailPanel",

  components: {
    ListDetailPanelHeader,
    ListDetailPanelEmpty,
    MatchingCardCompactListHeader,
    MatchingCardCompact,
    MatchingCardCompactPlaceholder,
    VirtualGrid,
  },

  props: {
    value: {
      type: Object as () => ICompanyList | null,
      default: null,
    },

    members: {
      type: Array as () => Array<IMatchingCard>,
      default: () => [],
    },
  },

  static: {
    virtualGridGap: 20,
    virtualRowHeight: 104,
    virtualGridColumns: 1,
  },

  data() {
    return {
      // Freeze property to avoid un-necessary reactivity.
      listTypes: Object.freeze(COMPANY_LIST_TYPES),
    };
  },

  computed: {
    listUid(): string {
      return this.$route.params.uid as string;
    },

    nextDetailPage(): number | null {
      return this.$store.get(CompanyListState.Getter.CURRENT_DETAIL_PAGE);
    },

    isLoading(): boolean {
      return this.$store.get(CompanyListState.Getter.IS_LOADING);
    },

    isOwner(): boolean {
      return !!this.value?.is_owner;
    },

    isSmartList(): boolean {
      return this.value ? this.value.is_smart_list : false;
    },

    hasCompanies(): boolean {
      return !!this.value?.companies.length;
    },

    showCompanies(): boolean {
      return this.hasCompanies || this.isLoading;
    },

    listUserType(): number {
      return this.value?.type || ENTREPRENEUR_USER_TYPE;
    },

    authUserAccountType(): number {
      return this.$user.getUserAccountType();
    },

    isSameTypeOfUsers(): boolean {
      return this.listUserType === this.authUserAccountType;
    },

    profileUid(): string | null {
      return this.$store.state.auth.profileUid;
    },

    emptyListTitle(): string {
      return this.isOwner && !this.isSmartList
        ? this.$t(`companyLists.detail.empty.${[this.listTypes.OWNED]}`)
        : this.$t(`companyLists.detail.empty.${[this.listTypes.INVITED_TO]}`);
    },

    emptyListSubtitle(): string {
      return this.isSmartList
        ? this.$t(`companyLists.detail.empty.${[this.listTypes.SMART_LIST]}`)
        : "";
    },

    isAuthUser(): boolean {
      return this.$user.isLogged();
    },

    showCardOptions(): boolean {
      return this.isOwner && !this.isSmartList;
    },

    membersList(): Array<IMatchingCard> {
      return this.members.map((matchingCard) => {
        if (matchingCard?.badges) {
          matchingCard.isDirectoryMember = matchingCard.badges.some(
            (badge) => badge.name === MATCHING_BADGE_DIRECTORY_LISTING,
          );
        }
        return matchingCard;
      });
    },

    userGuest(): IUserGuest | null {
      return this.$store.get(EUserGuestGetters.GET);
    },

    isMatchingEnabled(): boolean {
      return (
        this.$features.isEnabled("match") &&
        activeModules().includes("matching")
      );
    },

    showAddMembersButton(): boolean {
      return this.isOwner && !this.isSmartList && this.isMatchingEnabled;
    },
  },

  methods: {
    /**
     * Fetch the next page of the matching score list.
     */
    async fetchMoreMatches($status: IStatusChanger) {
      if (!this.nextDetailPage) {
        $status.complete();
        return;
      }
      const listRequestParams = {
        // If user guest exists in store, we include the guest email in the list request:
        ...(this.userGuest && { email: this.userGuest.email }),
      };

      await this.$store.dispatch(CompanyListState.Action.GET_VALUE_DETAIL, {
        id: this.listUid,
        page: this.nextDetailPage,
        ...listRequestParams,
      });

      !this.nextDetailPage ? $status.complete() : $status.ready();
    },

    /**
     * Handle matching card click
     */
    clickMatchingCardHandler(companyId: number) {
      const id = companyId.toString();

      this.$router.push({
        name: ROUTE_PROFILE,
        params: { id },
      });
    },

    addMembersClickHandler() {
      if (!this.value) {
        return;
      }

      // Set selected list
      this.$store.dispatch(EListManagementActions.SET_LISTS, [this.value]);

      // Move to matching
      this.$router.push({
        name: ROUTE_MATCHING_LIST,
      });
    },

    async onCompanyRemoval(companyUid: string) {
      if (!this.value) {
        return;
      }

      const updatedCompanies = this.value.allCompaniesCompacted
        ? this.value.allCompaniesCompacted
            .map(
              (companyOfList: ISimpleCompanyOfList) => companyOfList.uid || "",
            )
            .filter((innerUid: string) => innerUid !== companyUid)
        : [];

      await this.$store.dispatch(CompanyListState.Action.PATCH_VALUE, {
        uid: this.value.uid,
        shouldReload: true,
        payload: {
          companies: [...updatedCompanies],
        },
      });

      // Success toast message for single company removal
      const message = this.$tc(
        "matching.matchingList.successToast.removedFromList",
        1,
        {
          company: this.$tc("matching.matchingList.successToast.members", 1, {
            quantity: 1,
          }),
        },
      );

      // Launch success navbar
      this.$message({
        message,
        type: "success",
        customClass: "is-navbar",
      });
    },

    /**
     * Specific registration action based on list user type
     */
    onRegistration() {
      if (this.$user.isLogged()) {
        return;
      }

      // For Entrepreneurs, when list contains supporters
      if (this.value?.type === SUPPORTER_USER_TYPE) {
        this.$router.push({
          name: ROUTE_AUTHENTICATION_SIGNUP,
        });
        return;
      }

      // For Supporters, when list contains entrepreneurs
      this.$router.push({
        name: SUPPORTERS_STARTING_POINT,
      });
    },
  },
});
</script>

<style lang="scss" scoped>
.list-detail-panel {
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;

  background-color: $white;
  border: solid 1px $pale-grey;
  border-radius: 4px;

  &:first-child {
    margin-top: 20px;
  }

  :deep() &-header {
    position: sticky;
    top: 0;
    z-index: z("default") + 1;
    width: 100%;
  }
}

.list-detail-panel__body {
  position: relative;
  z-index: z("default");
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  min-height: calc(100vh - 557px);
  padding: 30px 21px 56px;
  overflow: auto;

  :deep() .matching-card-compact:not(.matching-card-compact--selected):hover {
    box-shadow: 0 2px 4px 0 rgba($ebony-clay, 0.08);
  }
}
</style>
