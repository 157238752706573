import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createVNode as _createVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "upgrade-modal__animation"
}
const _hoisted_2 = {
  key: 2,
  class: "upgrade-modal__quote"
}
const _hoisted_3 = { class: "upgrade-modal__description-level" }
const _hoisted_4 = { class: "upgrade-modal__level-description" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PxButton = _resolveComponent("PxButton")!
  const _component_i18n_t = _resolveComponent("i18n-t")!
  const _component_PxIcon = _resolveComponent("PxIcon")!
  const _component_ElDialog = _resolveComponent("ElDialog")!

  return (_ctx.finalLevel)
    ? (_openBlock(), _createBlock(_component_ElDialog, {
        key: 0,
        ref: "modal",
        modelValue: _ctx.innerVisibility,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.innerVisibility) = $event)),
        "before-close": _ctx.onBeforeCloseModal,
        "close-on-click-modal": false,
        class: "upgrade-modal",
        width: "492px"
      }, {
        default: _withCtx(() => [
          (_ctx.isLevelUp)
            ? (_openBlock(), _createElementBlock("canvas", _hoisted_1))
            : _createCommentVNode("", true),
          (_ctx.isBackToMatching)
            ? (_openBlock(), _createBlock(_component_PxButton, {
                key: 1,
                class: "upgrade-modal__back-to-matching",
                type: "link",
                onClick: _ctx.onClickBackToMatching
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t("common.components.updateModal.backToMatching")), 1)
                ]),
                _: 1
              }, 8, ["onClick"]))
            : _createCommentVNode("", true),
          _createElementVNode("h1", {
            class: _normalizeClass([{
        'upgrade-modal__title--down': !_ctx.isFirstAssessment && !_ctx.isLevelUp,
      }, "upgrade-modal__title"])
          }, _toDisplayString(_ctx.congratsMsg), 3),
          (!_ctx.isFirstAssessment && !_ctx.isLevelUp)
            ? (_openBlock(), _createElementBlock("h3", _hoisted_2, " \"" + _toDisplayString(_ctx.$t("common.components.updateModal.levelDown.quote")) + "\" ", 1))
            : _createCommentVNode("", true),
          _createVNode(_component_i18n_t, {
            class: "upgrade-modal__description",
            keypath: "common.components.updateModal.description",
            tag: "p"
          }, {
            level: _withCtx(() => [
              _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.$t("common.level")) + " " + _toDisplayString(_ctx.finalLevel.value), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_PxIcon, {
            name: `level-graph/${_ctx.finalLevel.value}`,
            size: 123,
            class: "upgrade-modal__level"
          }, null, 8, ["name"]),
          _createElementVNode("p", {
            class: _normalizeClass([{
        'upgrade-modal__level-title--down': !_ctx.isFirstAssessment && !_ctx.isLevelUp,
      }, "upgrade-modal__level-title"])
          }, _toDisplayString(_ctx.finalLevel.title), 3),
          _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.finalLevel.description), 1)
        ]),
        _: 1
      }, 8, ["modelValue", "before-close"]))
    : _createCommentVNode("", true)
}