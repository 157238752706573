import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "milestone-form-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MilestoneFormFooterPlan = _resolveComponent("MilestoneFormFooterPlan")!
  const _component_MilestoneFormFooterComplete = _resolveComponent("MilestoneFormFooterComplete")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.isPlanTab)
      ? (_openBlock(), _createBlock(_component_MilestoneFormFooterPlan, {
          key: 0,
          "form-has-changes": _ctx.planFormHasChanges,
          "form-is-complete": _ctx.planFormIsComplete,
          "form-is-invalid": _ctx.planFormIsInvalid,
          "has-plan": _ctx.hasPlan,
          "is-future-milestone": _ctx.isFutureMilestone,
          "is-plan-published": _ctx.isPlanPublished
        }, null, 8, ["form-has-changes", "form-is-complete", "form-is-invalid", "has-plan", "is-future-milestone", "is-plan-published"]))
      : (_openBlock(), _createBlock(_component_MilestoneFormFooterComplete, {
          key: 1,
          "above-milestones-completed": _ctx.aboveMilestonesCompleted,
          "below-milestones-not-completed": _ctx.belowMilestonesNotCompleted,
          "form-has-changes": _ctx.completeFormHasChanges,
          "form-is-invalid": _ctx.completeFormIsInvalid,
          "has-completion-info": _ctx.hasCompletionInfo,
          "is-form-published": _ctx.isCompletePublished,
          "user-adds-evidence": _ctx.userAddsEvidence
        }, null, 8, ["above-milestones-completed", "below-milestones-not-completed", "form-has-changes", "form-is-invalid", "has-completion-info", "is-form-published", "user-adds-evidence"]))
  ]))
}