import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-471a5dff"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "px-matching-interest-compact" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PxButton = _resolveComponent("PxButton")!
  const _component_ElTooltip = _resolveComponent("ElTooltip")!
  const _component_PxMatchingInterestRemovalModal = _resolveComponent("PxMatchingInterestRemovalModal")!
  const _component_PxMatchingInterestDirectoryMemberModal = _resolveComponent("PxMatchingInterestDirectoryMemberModal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ElTooltip, {
      content: _ctx.ctaTip,
      enterable: false,
      class: "item",
      effect: "dark",
      placement: "top",
      "popper-class": "px-matching-interest-compact__tooltip el-abaca-tooltip"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_PxButton, {
          icon: _ctx.ctaIcon,
          label: _ctx.ctaText,
          size: _ctx.size,
          type: _ctx.ctaType,
          variant: _ctx.variant,
          class: "px-matching-interest__cta",
          onClick: _ctx.clickInterestCTAHandler
        }, null, 8, ["icon", "label", "size", "type", "variant", "onClick"])
      ]),
      _: 1
    }, 8, ["content"]),
    _createVNode(_component_PxMatchingInterestRemovalModal, {
      "has-server-error": _ctx.hasServerError,
      "onUpdate:hasServerError": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.hasServerError) = $event)),
      "is-loading": _ctx.isLoading,
      "onUpdate:isLoading": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.isLoading) = $event)),
      visibility: _ctx.isRemoveInterestModalVisible,
      "onUpdate:visibility": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.isRemoveInterestModalVisible) = $event)),
      "interest-name": _ctx.name,
      "interest-state": _ctx.interestState,
      onRemovedInterest: _ctx.onRemovedInterest
    }, null, 8, ["has-server-error", "is-loading", "visibility", "interest-name", "interest-state", "onRemovedInterest"]),
    (_ctx.isDirectoryMemberModalVisible)
      ? (_openBlock(), _createBlock(_component_PxMatchingInterestDirectoryMemberModal, {
          key: 0,
          "has-server-error": _ctx.hasServerError,
          "onUpdate:hasServerError": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.hasServerError) = $event)),
          "is-loading": _ctx.isLoading,
          "onUpdate:isLoading": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.isLoading) = $event)),
          visibility: _ctx.isDirectoryMemberModalVisible,
          "onUpdate:visibility": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.isDirectoryMemberModalVisible) = $event)),
          "company-uid": _ctx.uid,
          onConnect: _ctx.clickInterestCTAHandler
        }, null, 8, ["has-server-error", "is-loading", "visibility", "company-uid", "onConnect"]))
      : _createCommentVNode("", true)
  ]))
}