<template>
  <div class="panel-grid">
    <div class="panel-grid__col panel-grid__col--left">
      <slot name="left" />
    </div>
    <div class="panel-grid__col panel-grid__col--right">
      <slot name="right" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "PanelGrid",
});
</script>

<style lang="scss" scoped>
.panel-grid {
  display: flex;
  flex-flow: column wrap;
  align-items: center;

  @media (min-width: 1200px) {
    flex-flow: row wrap;
    align-items: flex-start;
    justify-content: space-between;
  }
}

.panel-grid__col {
  flex-grow: 1;

  @include breakpoint-up(lg) {
    max-width: calc(50% - 15px);
  }

  &:not(:first-child) {
    @include breakpoint-up(md) {
      margin-top: 30px;
    }

    @include breakpoint-up(xl) {
      margin-top: 0;
    }
  }
}
</style>
