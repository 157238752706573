import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-a990c3f0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "milestone-details" }
const _hoisted_2 = { class: "milestone-details__headline" }
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PxLabel = _resolveComponent("PxLabel")!
  const _component_ElCollapseItem = _resolveComponent("ElCollapseItem")!
  const _component_ElCollapse = _resolveComponent("ElCollapse")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ElCollapse, { accordion: "" }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.milestones, (milestone, index) => {
          return (_openBlock(), _createBlock(_component_ElCollapseItem, {
            key: index,
            disabled: !milestone.description,
            name: index
          }, {
            title: _withCtx(() => [
              _createElementVNode("div", _hoisted_2, [
                _createElementVNode("span", {
                  class: "milestone-details__headline-title",
                  innerHTML: milestone.title
                }, null, 8, _hoisted_3),
                _createVNode(_component_PxLabel, {
                  color: `#${milestone.color}`,
                  text: `${milestone.category}: ${milestone.level}`
                }, null, 8, ["color", "text"])
              ])
            ]),
            default: _withCtx(() => [
              _createElementVNode("div", {
                innerHTML: milestone.description
              }, null, 8, _hoisted_4)
            ]),
            _: 2
          }, 1032, ["disabled", "name"]))
        }), 128))
      ]),
      _: 1
    })
  ]))
}