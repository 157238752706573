<template>
  <div class="grid-levels">
    <div
      v-for="levelNumber in levelsCount"
      :key="levelNumber"
      :class="{
        'grid-levels__level--active': levelNumber === currentLevel,
      }"
      :data-testid="`level-number-${levelNumber}`"
      class="grid-levels__level"
    >
      {{ levelNumber }}
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "GridLevels",

  props: {
    levelsCount: {
      type: Number,
      required: true,
    },

    currentLevel: {
      type: Number,
      required: true,
    },
  },
});
</script>

<style lang="scss" scoped>
.grid-levels {
  z-index: z("floaters");
  display: flex;
  flex-direction: column-reverse;
  align-self: flex-end;
  height: 100%;

  &__level {
    @include grotesk(regular);

    width: 30px;
    padding-right: 2px;
    margin-top: 8px;
    font-size: 14px;
    line-height: 22px;
    text-align: center;

    &--active {
      @include grotesk(bold);
    }
  }
}
</style>
