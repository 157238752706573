<template>
  <div :style="{ height }" class="levels-description__wrapper">
    <div
      v-for="item in sortedDetails"
      :key="item.id"
      :class="getItemClasses(item)"
      class="levels-description__item"
    >
      <div
        class="levels-description__item-text"
        v-html="getItemTextContent(item.achievements)"
      />
      <ElTooltip
        v-if="itemHasLongText(item)"
        :enterable="false"
        data-testid="levels-description-item-tooltip"
        placement="top"
        popper-class="levels-description__item-tooltip el-abaca-tooltip"
      >
        <div class="levels-description__item-tooltip-trigger">
          {{ $t("common.more") }}
        </div>
        <template #content>
          <div v-html="item.achievements" />
        </template>
      </ElTooltip>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { ICategoryDetail } from "@/services/data/category/category.interface";

const MAX_TEXT_LENGTH = 63;

export default defineComponent({
  name: "LevelsDescription",

  props: {
    /**
     * List of details for the current category.
     */
    categoryDetails: {
      type: Array as () => ICategoryDetail[],
      required: true,
    },

    /**
     * Current active level.
     */
    currentLevel: {
      type: Number,
      required: true,
    },

    /**
     * The component needs the height to distribute the
     * elements;
     */
    height: {
      type: String,
      required: true,
    },

    rangeFirstLevel: {
      type: Number,
      default: null,
    },
  },

  computed: {
    sortedDetails(): ICategoryDetail[] {
      return [...this.categoryDetails].sort(
        (a, b) => b.level.value - a.level.value,
      );
    },
  },

  methods: {
    getItemClasses(item: ICategoryDetail) {
      return {
        "levels-description__item--visible": this.isItemVisible(item),
        "levels-description__item--checked": this.isItemChecked(item),
        "levels-description__item--truncated": this.itemHasLongText(item),
      };
    },

    isItemVisible(item: ICategoryDetail): boolean {
      return item.level.value <= this.currentLevel + 1;
    },

    isItemChecked(item: ICategoryDetail): boolean {
      return (
        item.level.value <= this.currentLevel &&
        item.level.value >= this.rangeFirstLevel
      );
    },

    itemHasLongText(item: ICategoryDetail): boolean {
      return this.getDiffBetweenItemTextAndMaxTextLength(item.achievements) > 0;
    },

    getItemTextContent(rawText: string) {
      const diffBetweenItemTextAndMaxTextLength =
        this.getDiffBetweenItemTextAndMaxTextLength(rawText);

      // If the total text length surpasses the maximum defined length for the
      // item text, cut the difference from the regular text.
      if (diffBetweenItemTextAndMaxTextLength > 0) {
        return `${rawText.slice(0, -diffBetweenItemTextAndMaxTextLength)}…`;
      }

      return rawText;
    },

    getDiffBetweenItemTextAndMaxTextLength(rawText: string) {
      // Create temporary HTML element to be able to extract the text from the
      // raw HTML string.
      const el = document.createElement("div");
      el.innerHTML = rawText;
      const parsedText = el.textContent || "";

      return parsedText.length - MAX_TEXT_LENGTH;
    },
  },
});
</script>

<style lang="scss" scoped>
.levels-description__wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-right: 30px;
  padding-left: 8px;

  @include breakpoint-up(md) {
    flex: 1;
  }
}

.levels-description__item {
  position: relative;
  display: flex;
  justify-content: flex-start;
  width: 100%;
  font-size: 15px;
  line-height: 20px;
  color: $manatee;
  letter-spacing: -0.23px;
  opacity: 0;
  transition: all 0.2s ease-in;

  &::before {
    position: absolute;
    top: 0;
    bottom: 0;
    left: -25px;
    width: 14px;
    height: 14px;
    margin: auto 0;
    content: "";
    background-image: url("#{$assetsPath}/img/icons/check--dark.svg");
    background-repeat: no-repeat;
    background-position: 1px;
    background-size: 11px 11px;
    opacity: 0;
    transition: all 0.3s ease-in-out;
  }

  &--visible {
    opacity: 1;
  }

  &--checked {
    padding-left: 25px;
    color: $ebony-clay;

    &::before {
      left: 0;
      opacity: 1;
    }
  }
}

.levels-description__item-text {
  .levels-description__item--truncated & {
    padding-right: 15px;
  }
}

.levels-description__item-tooltip-trigger {
  @include grotesk(semiBold);

  font-size: 14px;
  color: $ebony-clay;
  text-decoration: underline;

  &:hover {
    cursor: default;
    user-select: none;
  }
}
</style>

<style lang="scss">
.levels-description__item-tooltip {
  max-width: 340px;
  text-transform: none;
}
</style>
