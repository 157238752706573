<template>
  <MultiSelectorWrapper :label="label" :no-label="noLabel" :tip="tip">
    <template #selected>
      <div>
        <MultiSelectorEntry
          v-for="item in selectedItems"
          :key="item.value"
          :item="item"
          @edit="onEditItemClick"
          @remove="onRemoveItemClick"
        />
      </div>
    </template>
    <template #input>
      <div>
        <ElSelect
          ref="select"
          v-model="innerSelectedItem"
          :filter-method="selectRemoteMethod"
          :filterable="hasRemoteSearch"
          :loading="isLoadingResults"
          :placeholder="$t(placeholder)"
          :popper-append-to-body="false"
          :remote="hasRemoteSearch"
          automatic-dropdown
          class="multi-selector__select"
          popper-class="multi-selector__popper"
          @blur="blurHandler"
          @focus="focusHandler"
          @visible-change="handleVisibleChange"
        >
          <template #prefix>
            <PxIcon :size="24" :name="inputIcon" />
          </template>
          <MultiSelectorOption
            v-for="(item, index) in searchedItems"
            :key="item.value"
            :highlight-text="searchQuery"
            :item="item"
            :selected-items="selectedItems"
            :starts-collapsed="index !== 0"
            :subtext-label-list="subtextLabelList"
            @update="updateSelectedItems"
            @scrolled-bottom="onSelectScrolledBottom"
          />
        </ElSelect>
      </div>
    </template>
  </MultiSelectorWrapper>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import MultiSelectorMixin from "@/components/multi-selector/multi-selector.mixin";

import MultiSelectorWrapper from "@/components/multi-selector-wrapper/multi-selector-wrapper.vue";
import MultiSelectorOption from "@/components/multi-selector-option/multi-selector-option.vue";
import MultiSelectorEntry from "@/components/multi-selector-entry/multi-selector-entry.vue";

export default defineComponent({
  name: "MultiSelector",

  components: {
    MultiSelectorWrapper,
    MultiSelectorEntry,
    MultiSelectorOption,
  },

  mixins: [MultiSelectorMixin],
});
</script>
<style lang="scss" scoped>
.multi-selector__select {
  :deep(.el-select__wrapper) {
    height: 40px;
    background: $white;
    padding: 4px 8px;
  }

  :deep(.el-select__prefix::after) {
    top: 50%;
    left: 32px;
    width: 1px;
    height: 28px;

    transform: translateY(-50%);
  }

  :deep(.el-select__selection) {
    padding-right: 15px;
    padding-left: 15px;
  }

  :deep(.el-select__placeholder span) {
    color: $manatee;
    line-height: 1;
  }
}
</style>
