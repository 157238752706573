<template>
  <div v-if="!isDataMissing" class="assessment-changes">
    <template v-if="!onlyPrevAssessment">
      <div class="assessment-changes__line-wrapper">
        <div class="assessment-changes__line">
          <p
            v-for="(change, index) in changes"
            :key="index"
            :class="{
              'assessment-changes__line--up': change.raised,
              'assessment-changes__line--down': !change.raised,
            }"
            :data-testid="`assessment-changes-values-${index}`"
          >
            {{ change.category.name }} {{ change.level }}
          </p>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="assessment-changes__line-wrapper">
        <div class="assessment-changes__line">
          <p
            v-for="(change, index) in prevAssessmentData"
            :key="index"
            :data-testid="`prev-assessment-changes-values-${index}`"
          >
            {{ change.category.name }} {{ change.level }}
          </p>
        </div>
      </div>
    </template>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { IAssessmentLevel } from "@/services/data/assessment/assessment.interface";
import { ICategory } from "@/services/data/category/category.interface";

export default defineComponent({
  name: "AssessmentChanges",

  props: {
    prevAssessment: {
      type: Array as () => IAssessmentLevel[],
      required: true,
    },
    currentAssessment: {
      type: Array as () => IAssessmentLevel[],
      required: true,
    },
    onlyPrevAssessment: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    categories(): Array<ICategory> {
      return this.$store.get("categories.data");
    },

    prevAssessmentData(): any {
      return this.prevAssessment
        .map((entry: IAssessmentLevel, index: number) => {
          const curEntry = this.currentAssessment[index] as IAssessmentLevel;

          if (curEntry.level === entry.level) {
            return null;
          }

          return {
            ...entry,
            level: entry.level,
          };
        })
        .filter((entry: any) => entry !== null)
        .map((entry: any) => {
          const category = this.categories.find(
            (category: any) => category.id === entry.category,
          );

          return {
            ...entry,
            category,
          };
        });
    },

    isDataMissing(): boolean {
      return (
        this.prevAssessment.length === 0 ||
        this.currentAssessment.length === 0 ||
        this.categories.length === 0
      );
    },

    changes(): any {
      return this.prevAssessment
        .map((entry: IAssessmentLevel, index: number) => {
          const curEntry = this.currentAssessment[index] as IAssessmentLevel;

          if (curEntry.level === entry.level) {
            return null;
          } else if ((curEntry as any).level > (entry as any).level) {
            return {
              ...curEntry,
              raised: true,
            };
          }

          return {
            ...curEntry,
            level: curEntry.level !== null ? curEntry.level : 1,
            raised: false,
          };
        })
        .filter((entry: any) => {
          return entry !== null;
        })
        .map((entry: any) => {
          const category = this.categories.find(
            (category: any) => category.id === entry.category,
          );

          return {
            ...entry,
            category,
          };
        });
    },
  },
});
</script>

<style lang="scss" scoped>
.assessment-changes {
  display: flex;
  align-items: center;

  &__line-wrapper {
    display: flex;
    align-items: flex-start;

    margin-bottom: 5px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__line {
    position: relative;
    right: 8px;
    display: flex;
    flex-direction: column;
    gap: 3px;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    line-height: 21px;
    color: $ebony-clay;
    letter-spacing: -0.4px;

    p {
      display: flex;
      font-size: 14px;
      line-height: 20px;
      color: $ebony-clay;
    }

    p::before {
      position: relative;
      top: 2px;
      right: 4px;
      display: block;
      flex-shrink: 0;
      width: 16px;
      height: 16px;

      content: "";
    }

    &--up::before {
      background: url("/img/icons/arrow--up-green.svg");
    }

    &--down::before {
      background: url("/img/icons/arrow--down-red.svg");
    }
  }
}
</style>
