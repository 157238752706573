import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-5e0668ae"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "list-share-settings-passcode" }
const _hoisted_2 = ["textContent"]
const _hoisted_3 = { class: "list-share-settings-passcode__toggle-wrapper" }
const _hoisted_4 = { class: "list-share-settings-passcode__footer" }
const _hoisted_5 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PxIcon = _resolveComponent("PxIcon")!
  const _component_ElTooltip = _resolveComponent("ElTooltip")!
  const _component_PxSwitch = _resolveComponent("PxSwitch")!
  const _component_PxInputPassword = _resolveComponent("PxInputPassword")!
  const _component_ClipboardButton = _resolveComponent("ClipboardButton")!
  const _component_ElFormItem = _resolveComponent("ElFormItem")!
  const _component_PxButton = _resolveComponent("PxButton")!
  const _component_ElForm = _resolveComponent("ElForm")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ElForm, {
      ref: "form",
      class: "list-share-settings-passcode__form"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_PxSwitch, {
          value: _ctx.isRestrictedByPasscode,
          "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.isRestrictedByPasscode) = $event)),
          label: _ctx.shareModalPasscodeCopy.label,
          onChange: _ctx.changeRestrictionHandler
        }, {
          label: _withCtx(() => [
            _createVNode(_component_ElTooltip, {
              enterable: false,
              placement: "top",
              "popper-class": "list-share-settings-passcode__popper el-abaca-tooltip"
            }, {
              content: _withCtx(() => [
                _createElementVNode("div", {
                  textContent: _toDisplayString(_ctx.shareModalPasscodeCopy.tooltip)
                }, null, 8, _hoisted_2)
              ]),
              default: _withCtx(() => [
                _createVNode(_component_PxIcon, {
                  size: 17,
                  class: "list-share-settings-passcode__tooltip",
                  name: "question"
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["value", "label", "onChange"]),
        _withDirectives(_createElementVNode("div", _hoisted_3, [
          _createVNode(_component_ElFormItem, {
            class: "list-share-settings-passcode__input-wrapper",
            prop: "email"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_PxInputPassword, {
                modelValue: _ctx.currentPasscode,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.currentPasscode) = $event)),
                class: "list-share-settings-passcode__input",
                disabled: ""
              }, null, 8, ["modelValue"]),
              _createVNode(_component_ClipboardButton, _normalizeProps(_guardReactiveProps(_ctx.clipboardButton)), null, 16)
            ]),
            _: 1
          }),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("span", {
              class: "el-form-item__bottom-text el-form-item__tip",
              innerHTML: _ctx.shareModalPasscodeCopy.tip
            }, null, 8, _hoisted_5),
            _createVNode(_component_PxButton, {
              icon: _ctx.$options.static.resetButton.icon,
              label: _ctx.shareModalPasscodeCopy.resetButton,
              size: _ctx.$options.static.resetButton.size,
              type: _ctx.$options.static.resetButton.type,
              class: "list-share-settings-passcode__cta",
              onClick: _ctx.clickResetPasscodeHandler
            }, null, 8, ["icon", "label", "size", "type", "onClick"])
          ])
        ], 512), [
          [_vShow, _ctx.isRestrictedByPasscode]
        ])
      ]),
      _: 1
    }, 512)
  ]))
}