<template>
  <div class="multi-selector__container">
    <div class="multi-selector__selected-container">
      <label v-if="!noLabel" class="el-form-item__label multi-selector__title">
        {{ $t(label) }}
      </label>
      <slot name="selected" />
    </div>
    <slot name="input" />
    <span
      class="el-form-item__bottom-text el-form-item__tip"
      v-html="$t(tip)"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "MultiSelectorWrapper",

  props: {
    /**
     * When set to `true` the label won't be rendered.
     */
    noLabel: {
      type: Boolean,
      default: false,
    },

    label: {
      type: String,
      default: "common.components.default.input.label",
    },

    tip: {
      type: String,
      default: "common.components.default.input.tip",
    },
  },
});
</script>

<style lang="scss" scoped>
.multi-selector__title {
  display: block;
}

.multi-selector__container :deep(.el-select__caret) {
  display: none;
}
</style>

<style lang="scss">
.criteria-multi-selector__popper,
.multi-selector__popper {
  max-width: 310px;
}
</style>
