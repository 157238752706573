import {
  EProviderFeatures,
  GenericProvider,
  IDataType,
  IOptionsType,
} from "@/services/data/generic-provider";
import { IPendingSelfAssessment } from "@/modules/self-assessment/services/data/pending-self-assessment/pending-self-assessment.interface";

class PendingSelfAssessmentProvider extends GenericProvider<IPendingSelfAssessment> {
  constructor() {
    super(`${process.env.VUE_APP_API_URL}/pending-user/self_assessment/`, [
      EProviderFeatures.CREATE,
    ]);
  }

  public create(data: IDataType, options?: IOptionsType) {
    // When there is no level selected the API expects to receive a
    // level null and not zero, so we need a conversion here.
    data.levels = data.levels.map((entry: any) => {
      entry.level = entry.level || null;
      return entry;
    });
    return super.create(data, options);
  }
}

export const pendingSelfAssessmentProvider =
  new PendingSelfAssessmentProvider();
