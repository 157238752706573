<template>
  <div>
    <PasscodeAccessModal
      v-if="requiresPasscode"
      v-model:has-errors="invalidPasscode"
      :is-data-loading="isLoading"
      :needs-identification="isIdentificationRequired"
      :requested-data-uid="milestonePlannerUid"
      :visibility="requiresPasscode"
      data-testid="passcode-access-modal"
      no-passcode-message="milestonePlanner.accessModal.noPasscode"
      @set-passcode="makeRequestWithPasscode"
    />
    <RegistrationModals
      v-else
      :is-data-loading="isLoading"
      :requires-passcode="!!passcode"
      data-testid="registration-access-modal"
      @submit-guest-form="enterAsGuestUser"
      @submit-logged-form="enterAsLoggedUser"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import RegistrationModals from "@/components/registration-modals/registration-modals.vue";
import PasscodeAccessModal from "@/components/passcode-access-modal/passcode-access-modal.vue";

import { IUserGuest } from "@/services/data/user-guest/user-guest.interface";
import {
  EUserGuestGetters,
  EUserGuestActions,
} from "@/services/store/user-guest/user-guest.types";
import { AxiosError } from "axios";
import { EMilestonePlannerActions } from "@/modules/milestone-planner/services/store/milestone-planner/milestone-planner.types";

export default defineComponent({
  name: "MilestonePlannerAccessModal",

  components: {
    RegistrationModals,
    PasscodeAccessModal,
  },

  props: {
    requiresPasscode: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      passcode: "",
      invalidPasscode: false,
    };
  },

  computed: {
    milestonePlannerErrors(): AxiosError | null {
      return this.$store.get("milestonePlanner.error");
    },

    milestonePlannerUid(): string {
      return this.$route.params.uid as string;
    },

    userGuest(): IUserGuest | null {
      return this.$store.get(EUserGuestGetters.GET);
    },

    isIdentificationRequired(): boolean {
      return !this.isLogged && !this.userGuest;
    },

    isLogged(): boolean {
      return !!this.$store.get("auth/user");
    },

    isLoading(): boolean {
      return this.$store.get("milestonePlanner.loading");
    },
  },

  methods: {
    createGuestUser(guestFormData: IUserGuest): Promise<IUserGuest> {
      const newGuestUser: IUserGuest = {
        name: guestFormData.name,
        email: guestFormData.email,
      };

      return this.$store.dispatch(EUserGuestActions.CREATE, newGuestUser);
    },

    async makeRequestWithPasscode(passcode: string) {
      this.passcode = passcode;

      let milestonePlannerRequestParams = {};

      // If user guest exists in store, we make the milestone planner request with passcode and guest email
      if (this.userGuest) {
        milestonePlannerRequestParams = {
          email: this.userGuest.email,
        };
      }

      // Request milestone planner data
      await this.requestMilestonePlannerData(milestonePlannerRequestParams);

      this.checkForAnyPasscodeErrors();
    },

    async enterAsGuestUser(guestFormData: IUserGuest) {
      // Create guest user
      const userGuest = await this.createGuestUser(guestFormData);
      const milestonePlannerRequestParams: object = {
        email: userGuest.email,
      };

      // Request milestone planner data
      await this.requestMilestonePlannerData(milestonePlannerRequestParams);
    },

    enterAsLoggedUser() {
      this.requestMilestonePlannerData();

      // An entrepreneur should only be able to access his own MP.
      // Therefore we need to reload the page so that we enter in the MP guard and validate the type of user
      if (this.$user.isEntrepreneur()) {
        location.reload();
      }
    },

    async requestMilestonePlannerData(requestParams?: object) {
      let params: object = {
        ...requestParams,
        id: this.milestonePlannerUid,
      };

      if (this.passcode) {
        params = {
          ...params,
          passcode: this.passcode,
        };
      }

      await this.$store.dispatch(EMilestonePlannerActions.GET_VALUE, params);
    },

    checkForAnyPasscodeErrors() {
      const response = this.milestonePlannerErrors?.response;

      // When the passcode is wrong.
      this.invalidPasscode =
        response?.status === 403 &&
        (response?.data.errors.code === "invalid_passcode" ||
          response?.data.errors.code === "passcode_updated");
    },
  },
});
</script>
