<template>
  <div class="list-share-actions">
    <h3 class="list-share-actions__title" v-text="shareModalFooterCopy.title" />
    <i18n-t
      :keypath="`companyLists.list.shareModal.footer.subtitle.content[${isRestrictedByPasscode}]`"
      class="list-share-actions__subtitle"
      tag="p"
    >
      <template #link>
        <strong v-text="shareModalFooterCopy.subtitle.placeholders[0]" />
      </template>
      <template #name>
        <strong
          class="text--capitalize"
          v-text="shareModalFooterCopy.subtitle.placeholders[1]"
        />
      </template>
      <template #email>
        <strong v-text="shareModalFooterCopy.subtitle.placeholders[2]" />
      </template>
      <template #password>
        <strong v-text="shareModalFooterCopy.subtitle.placeholders[3]" />
      </template>
    </i18n-t>
    <PxButton
      class="list-share-actions__link"
      size="small"
      type="link"
      @click="onClickSettingHandler"
    >
      {{ $t("companyLists.list.linkSettings") }}
    </PxButton>
    <div class="list-share-actions__buttons">
      <ClipboardButton
        v-if="isRestrictedByPasscode"
        v-bind="clipboardButtons[0]"
      />
      <ClipboardButton v-bind="clipboardButtons[1]" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import ClipboardButton from "@/components/clipboard-button/clipboard-button.vue";
export default defineComponent({
  name: "ListShareActions",

  components: {
    ClipboardButton,
  },

  props: {
    link: {
      type: String,
      required: true,
    },

    passcode: {
      type: String,
      required: true,
    },
  },

  computed: {
    shareModalFooterCopy() {
      return this.$tm("companyLists.list.shareModal.footer") as {
        title: string;
        subtitle: {
          content: string[];
          placeholders: string[];
        };
      };
    },

    isRestrictedByPasscode(): number {
      // Convert passcode length to a numeric boolean (empty = 0 / not-empty = 1)
      return +!!this.passcode.length;
    },

    clipboardButtons() {
      return [
        {
          clipboard: this.passcode,
          buttonLabel: this.$t("common.copyPasscode"),
          tooltipCopy: this.$t("common.copiedToClipboard"),
          buttonIcon: "password-lock",
        },
        {
          clipboard: this.link,
          buttonLabel: this.$t("common.copyLink"),
          tooltipCopy: this.$t("common.copiedToClipboard"),
        },
      ];
    },
  },

  methods: {
    onClickSettingHandler() {
      this.$emitter.emit("list-settings", true);
    },
  },
});
</script>

<style lang="scss" scoped>
.list-share-actions {
  display: grid;
  grid-template-areas:
    "title link"
    "subtitle buttons";
  grid-template-rows: auto auto;
  grid-template-columns: 48% max-content;
  grid-gap: 0 27px;
  align-items: flex-end;
  justify-content: space-between;
}

.list-share-actions__title {
  @include grotesk(semiBold);

  grid-area: title;
  margin-bottom: 4px;
  font-size: 12px;
  color: $gun-powder;
  text-transform: uppercase;
}

.list-share-actions__subtitle {
  grid-area: subtitle;
  margin-bottom: 1px;
  font-size: 14px;
  line-height: 22px;
  color: $manatee;
  letter-spacing: -0.25px;
  white-space: pre-wrap;
}

.list-share-actions__link {
  top: 2px;
  right: 5px;
  grid-area: link;
  justify-self: end;
}

.list-share-actions__buttons :deep() {
  grid-area: buttons;

  .el-button {
    padding: 0 11px;
    margin-left: 13px;
  }

  .el-button span {
    position: relative;
    top: -1px;
    right: 1px;
    font-size: 13px;
  }

  .el-button i {
    width: 17px;
    height: 17px;
  }
}
</style>
