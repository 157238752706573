<template>
  <div class="px-questionary-range">
    <div class="px-questionary-range__field-wrapper">
      <span class="px-questionary-range__label">
        {{ $t("common.from") }}
      </span>
      <PxNumeric v-model="values.min" />
    </div>
    <div class="px-questionary-range__field-wrapper">
      <span class="px-questionary-range__label">
        {{ $t("common.to") }}
      </span>
      <PxNumeric v-model="values.max" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";

export default defineComponent({
  name: "PxQuestionaryRange",

  props: {
    modelValue: {
      type: [Object, Array, null] as PropType<object | null>,
      default: () => null,
    },

    isValid: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      values: {
        min: "",
        max: "",
      },
    };
  },

  watch: {
    values: {
      deep: true,
      handler(newVal) {
        const newValues = {
          min: parseFloat(newVal.min.replace(",", "")),
          max: parseFloat(newVal.max.replace(",", "")),
        };

        this.emitValueChanges(newValues);
        this.validateField(newValues);
      },
    },
  },

  methods: {
    /**
     * Emit value changes to the parent component.
     */
    emitValueChanges(values: any) {
      this.$emit("update:modelValue", values);
      this.$emit("change", values);
    },

    validateField(values: any) {
      const isValid =
        values.min !== undefined &&
        values.max !== undefined &&
        values.min < values.max;

      this.$emit("update:isValid", isValid);
    },
  },
});
</script>

<style lang="scss" scoped>
.px-questionary-range__label {
  @include grotesk(semiBold);

  display: block;
  margin-bottom: 1px;

  font-size: 15px;
}

.px-questionary-range__field-wrapper + .px-questionary-range__field-wrapper {
  margin-top: 10px;
}
</style>
