<template>
  <div class="networks-list__wrapper">
    <div class="networks-list" @scroll="checkNetworksListScroll">
      <div
        v-for="network in items"
        :key="network.id"
        class="networks-list__item"
      >
        <picture class="networks-list__logo">
          <img v-if="network.logo" :alt="network.name" :src="network.logo" />
        </picture>
        <div class="networks-list__content">
          <h3>
            {{ network.name }}
          </h3>
          <ul v-if="network.locations.length">
            <li :title="network.locations[0].formatted_address">
              {{ network.locations[0].formatted_address }}
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div v-if="isGradientVisible" class="networks-list__gradient" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import { INetwork } from "@/services/data/network/network.interface";

export default defineComponent({
  name: "NetworksList",

  props: {
    items: {
      type: Array as () => Array<INetwork>,
      required: true,
    },
  },

  data() {
    return {
      visibleNetworks: 3,
      networksScrollAtEnd: false,
    };
  },

  computed: {
    isGradientVisible(): boolean {
      return (
        this.items.length > this.visibleNetworks && !this.networksScrollAtEnd
      );
    },
  },

  methods: {
    checkNetworksListScroll($event: any) {
      if (!$event.target) return;

      this.networksScrollAtEnd =
        $event.target.scrollTop ===
        $event.target.scrollHeight - $event.target.offsetHeight;
    },
  },
});
</script>

<style lang="scss" scoped>
.networks-list {
  max-height: 172px;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0 4px;

  @include breakpoint-up(lg) {
    margin: 0 11px 28px;
  }

  &__wrapper {
    position: relative;
    width: 100%;
  }

  &::-webkit-scrollbar {
    width: 4px;
    padding-right: 10px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: $mischka;
    border-radius: 10px;
  }

  &__gradient {
    position: absolute;
    bottom: 0;
    width: calc(100% - 15px);
    height: 40px;
    background: linear-gradient(
      to top,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 1) 20%,
      rgba(247, 249, 249, 0) 100%
    );
  }
}

.networks-list__item {
  @include breakpoint-up(lg) {
    max-width: 398px;
  }

  display: flex;
  flex-flow: row wrap;
  width: 100%;
  margin: 0 auto;

  &:not(:first-child) {
    padding-top: 14px;
    margin-top: 14px;
    border-top: solid 1px $athens-gray;
  }
}

.networks-list__logo {
  display: block;
  width: 38px;
  height: 38px;
  padding: 4px;
  overflow: hidden;

  background-color: white;
  border-radius: 100%;
  box-shadow: inset 0 0 4px 0 rgba(39, 39, 64, 0.2);

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
  }
}

.networks-list__content {
  display: flex;
  flex: 1 1 calc(100% - 50px);
  flex-flow: row wrap;
  padding-left: 13px;

  @include breakpoint-up(md) {
    padding-top: 8px;
  }

  h3 {
    min-width: 100%;

    @include breakpoint-up(md) {
      min-width: auto;
    }

    @include grotesk(semiBold);

    font-size: 15px;
    line-height: 20px;
    color: $gun-powder;
  }

  ul {
    display: flex;
    flex-flow: row wrap;
    padding: 0;
    list-style: none;
  }

  li {
    position: relative;

    font-size: 15px;
    line-height: 20px;
    color: rgba($manatee, 0.9);
    letter-spacing: 0.3px;

    @include breakpoint-up(md) {
      padding-left: 12px;
      margin-left: 8px;
    }

    &::before {
      position: absolute;
      top: 8px;
      left: 0;

      @include breakpoint-up(md) {
        width: 4px;
        height: 4px;
      }

      content: "";
      background-color: $mischka;
      border-radius: 100%;
    }
  }
}
</style>
