import { MutationTree } from "vuex";
import { getBaseName } from "@/services/store/utils/get-base-name";

import {
  EMilestonePlannerPasscodeMutations,
  IMilestonePlannerPasscodePayload,
  IMilestonePlannersPasscodeState,
} from "@/modules/milestone-planner/services/store/milestone-planner/sub-modules/passcode/passcode.interface";

export const mutations: MutationTree<IMilestonePlannersPasscodeState> = {
  [getBaseName(EMilestonePlannerPasscodeMutations.SET)](
    state,
    payload: IMilestonePlannerPasscodePayload,
  ) {
    state.passcodes = {
      ...state.passcodes,
      [payload.milestonePlannerUid]: payload.passcode,
    };
  },

  [getBaseName(EMilestonePlannerPasscodeMutations.RESET)](state) {
    state.passcodes = {};
  },
};
