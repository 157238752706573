<template>
  <div class="panel-grid__block">
    <PxPanel
      v-if="!!latestAssessmentLevels && hasUserAccess"
      class="profile-panel profile-panel--investors"
      theme="with-sections"
    >
      <template #header>
        <h2 class="px-panel__title" v-html="$t('profile.investors.title')" />
      </template>
      <div v-if="$screen.smDown" class="profile-panel__mobile-section">
        <PxTabsMobile v-model="selectedTab" :items="tabsMobileItems" />
        <ContentPlaceholders v-if="isLoading" :animated="true">
          <ContentPlaceholdersText
            :lines="3"
            class="profile-panel__mobile-text"
          />
        </ContentPlaceholders>
        <InvestorsPanelAccordion
          v-else-if="nextMilestones.length && selectedTab === 'next'"
          :items="nextMilestones"
        />
        <ContentPlaceholders v-if="isLoading" :animated="true">
          <ContentPlaceholdersText
            :lines="3"
            class="profile-panel__mobile-text"
          />
        </ContentPlaceholders>
        <InvestorsPanelAccordion
          v-else-if="achievedMilestones.length && selectedTab === 'achieved'"
          :items="achievedMilestones"
        />
      </div>
      <div v-if="$screen.mdUp" class="profile-panel__desktop-section">
        <PxPanelSection
          v-if="isLoading || nextMilestones.length"
          :title="$t('profile.investors.nextMilestones')"
          icon="flag--outlined"
        >
          <ContentPlaceholders v-if="isLoading" :animated="true">
            <ContentPlaceholdersText :lines="4" />
          </ContentPlaceholders>
          <InvestorsPanelAccordion
            v-if="nextMilestones.length"
            :items="nextMilestones"
          />
        </PxPanelSection>
        <PxPanelSection
          v-if="isLoading || achievedMilestones.length"
          :title="$t('profile.investors.achievedMilestones')"
          icon="flag--checked"
        >
          <ContentPlaceholders v-if="isLoading" :animated="true">
            <ContentPlaceholdersText :lines="4" />
          </ContentPlaceholders>
          <InvestorsPanelAccordion
            v-if="achievedMilestones.length"
            :items="achievedMilestones"
          />
        </PxPanelSection>
      </div>
    </PxPanel>
    <InvestorsPanelInactive v-else />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import PxPanelSection from "@/components/px-panel-section/px-panel-section.vue";
import InvestorsPanelAccordion from "@/modules/profile/components/investors-panel/investors-panel-accordion.vue";
import InvestorsPanelInactive from "@/modules/profile/components/investors-panel/investors-panel--inactive.vue";

import MilestonesMixin from "@/mixins/milestones.mixin";

export default defineComponent({
  name: "InvestorsPanel",

  components: {
    PxPanelSection,
    InvestorsPanelAccordion,
    InvestorsPanelInactive,
  },

  mixins: [MilestonesMixin],

  data() {
    return {
      selectedTab: "next",
      tabsMobileItems: [
        {
          value: "next",
          label: this.$t("profile.investors.nextMilestones"),
        },
        {
          value: "achieved",
          label: this.$t("profile.investors.achievedMilestones"),
        },
      ],
    };
  },

  computed: {
    isSpecialGuest(): boolean {
      return !!this.$route.meta?.specialGuest;
    },

    hasUserAccess(): boolean {
      return this.$user.isLogged() || this.isSpecialGuest;
    },
  },
});
</script>

<style lang="scss" scoped>
.panel-grid__block,
.px-corner-ribbon {
  display: block;
}

.panel-grid__ribbon :deep(.px-corner-ribbon__label) {
  @include breakpoint-only("xs") {
    position: relative;
    width: 100%;
  }
}

:deep(.px-panel-section__header) {
  padding: 14px 48px;
}

.px-tabs-mobile {
  z-index: z("default") + 1;
}

.panel-grid__block .px-panel :deep(.px-panel__title) {
  @include breakpoint-down(sm) {
    padding-left: 17px;

    font-size: to-rem(18px);
    line-height: 1;
    text-align: left;
  }
}

:deep(.px-panel__header) {
  @include breakpoint-down(sm) {
    padding: 20px 0 16px;
  }
}

:deep(.px-panel-section__body) {
  padding: 10px 40px;

  @include breakpoint-up(md) {
    padding: 8px 40px 11px;
  }
}

.profile-panel__desktop-section :deep() {
  .vue-content-placeholders-text {
    padding: 30px 7px;
  }

  .vue-content-placeholders-text__line {
    height: 6px;
    margin-bottom: 0;

    background-color: $alto;
    border-radius: 5.5px;

    &:nth-child(2n) {
      margin-left: 10px;
      background-color: $pale-grey-two;
    }

    &:nth-child(4n + 1) {
      width: 77%;
    }

    &:nth-child(4n + 2) {
      width: 70%;
    }

    &:nth-child(4n + 3) {
      width: 89%;
    }

    &:nth-child(4n + 4) {
      width: 76%;
    }

    &:not(:first-child) {
      margin-top: 30px;
    }
  }
}

.profile-panel__mobile-section {
  padding: 21px 15px;

  background: white;

  & .profile-panel__mobile-text {
    margin-top: 24px;
  }

  .profile-panel__mobile-text :deep() {
    .vue-content-placeholders-text__line:first-child {
      width: 99.4%;
    }

    .vue-content-placeholders-text__line:nth-child(2) {
      width: 84%;
      margin-top: 24px;
    }

    .vue-content-placeholders-text__line:nth-child(3) {
      width: 70%;
      margin-top: 19px;
      margin-left: 10px;

      background-color: #f4f4f5;
    }
  }

  .profile-panel__divider {
    width: 100%;
    height: 1px;
    margin: 26px 0;

    background-color: $athens-gray;
  }

  .px-tabs-mobile {
    width: 100%;
  }
}
</style>
