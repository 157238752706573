<template>
  <div class="team-members-list">
    <PxListHeader :badge-number="membersList.length" :title="listHeaderTitle" />
    <div v-loading="isLoading">
      <div
        ref="membersList"
        class="team-members-list__members"
        data-testid="team-members-list-members"
        @scroll="checkListScroll"
      >
        <TeamMembersListMemberEntry
          v-for="(member, index) in membersList"
          :key="index"
          :data-testid="`team-members-list-members-${index}`"
          :is-selected="selectedMemberIndex === index"
          :member="member"
          :show-remove-button="membersList.length > 1"
          @click.stop="updateSelectedMember(index)"
          @remove-member="removeMember(index)"
        >
          >
        </TeamMembersListMemberEntry>
      </div>
      <div v-if="isGradientVisible" class="team-members-list__gradient" />
    </div>
    <TeamMembersListFooter
      :disabled="isLoading || formInvalid"
      class="team-members-list__footer"
      data-testid="team-members-list-footer"
      @add-member="addMember"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import TeamMembersListMemberEntry from "@/modules/team-management/components/team-members-list/team-members-list-member-entry.vue";
import TeamMembersListFooter from "@/modules/team-management/components/team-members-list/team-members-list-footer.vue";
import PxListHeader from "@/components/px-list-header/px-list-header.vue";
import { ITeamMember } from "@/modules/team-management/services/data/team-members/team-members.interface";
import { TranslateResult } from "vue-i18n";

export default defineComponent({
  name: "TeamMembersList",

  components: {
    TeamMembersListMemberEntry,
    TeamMembersListFooter,
    PxListHeader,
  },

  props: {
    membersList: {
      type: Array as () => Array<ITeamMember>,
      default: null,
    },

    selectedMemberIndex: {
      type: Number,
      default: 0,
    },

    isLoading: {
      type: Boolean,
      default: false,
    },

    scroll: {
      type: String,
      default: "top",
      validator(value: string) {
        return Object.values(["top", "bottom"]).includes(value);
      },
    },

    formInvalid: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      listScrollAtEnd: false,
      list: (null as any) || null,
      visibleMembers: 6,
    };
  },

  computed: {
    isGradientVisible(): boolean {
      return (
        this.membersList.length > this.visibleMembers && !this.listScrollAtEnd
      );
    },

    listHeaderTitle(): TranslateResult {
      return this.$t("teamManagement.membersList.title");
    },
  },

  watch: {
    scroll: {
      immediate: true,
      handler(type: string) {
        if (!type) return;

        const listEl = this.$refs.membersList as HTMLElement;

        if (listEl) {
          listEl.scrollTop = type === "top" ? 0 : listEl.scrollHeight;
        }

        this.$emit("update:scroll", null);
      },
    },
  },

  methods: {
    addMember() {
      this.$emit("add-member");
    },

    removeMember(index: number) {
      this.$emit("remove-member", index);
    },

    updateSelectedMember(index: number) {
      if (this.formInvalid) return;

      this.$emit("change-selected-member", index);
    },

    checkListScroll() {
      this.list = this.$refs.membersList as HTMLElement;

      if (!this.list) return;

      this.listScrollAtEnd =
        this.list.scrollTop === this.list.scrollHeight - this.list.offsetHeight;
    },
  },
});
</script>

<style lang="scss" scoped>
.team-members-list {
  position: relative;

  display: flex;
  flex-direction: column;

  max-width: 309px;
  width: 100%;
  height: 520px;

  background-color: $white;

  border: 1px solid $turkish-angora;
  border-radius: 4px;
  box-shadow: 0 4px 8px -4px rgba(139, 143, 161, 0.2);

  &__members {
    padding: 16px 0;

    max-height: 388px;
    overflow-x: hidden;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background: $mischka;
      border-radius: 10px;
    }
  }

  &__footer {
    position: absolute;
    bottom: 0;

    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  &__gradient {
    position: absolute;
    bottom: 65px;
    width: 100%;
    height: 25px;
    background: linear-gradient(
      to top,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 1) 20%,
      rgba(247, 249, 249, 0) 100%
    );
  }
}
</style>
