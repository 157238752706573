import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-41e82316"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "results-board" }
const _hoisted_2 = { class: "results-board__wrapper" }
const _hoisted_3 = {
  key: 0,
  class: "results-board__panel-submit"
}
const _hoisted_4 = { class: "results-board__headline-title" }
const _hoisted_5 = {
  key: 1,
  class: "results-board__container"
}
const _hoisted_6 = { class: "results-board__score-wrapper" }
const _hoisted_7 = { class: "results-board__title" }
const _hoisted_8 = { class: "results-board__tag-wrapper" }
const _hoisted_9 = { class: "results-board__description" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PxIcon = _resolveComponent("PxIcon")!
  const _component_i18n_t = _resolveComponent("i18n-t")!
  const _component_ElTag = _resolveComponent("ElTag")!
  const _component_PxPanel = _resolveComponent("PxPanel")!
  const _component_ResultsBoardPendingUser = _resolveComponent("ResultsBoardPendingUser")!
  const _directive_markdown = _resolveDirective("markdown")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_PxPanel, { class: "results-board__panel px-panel--mobile-no-side-borders" }, {
        default: _withCtx(() => [
          (_ctx.$screen.smDown)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createElementVNode("h2", _hoisted_4, [
                  _createVNode(_component_PxIcon, {
                    size: 18,
                    name: "check--white"
                  }),
                  _createTextVNode(" " + _toDisplayString(_ctx.$t(`selfAssessment.results.resultsBoardCTA.pendingUser.headline`)), 1)
                ]),
                _createVNode(_component_i18n_t, {
                  class: "results-board__headline-subtitle",
                  keypath: "selfAssessment.results.resultsBoardCTA.pendingUser.notice",
                  tag: "h4"
                }, {
                  company: _withCtx(() => [
                    _createElementVNode("span", null, _toDisplayString(_ctx.affiliateName), 1)
                  ]),
                  _: 1
                })
              ]))
            : _createCommentVNode("", true),
          (!_ctx.hasError && _ctx.finalLevel)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _createElementVNode("div", _hoisted_6, [
                  _createVNode(_component_PxIcon, {
                    name: `level-graph/${_ctx.finalLevel.value}`,
                    size: 173,
                    class: "results-board__level"
                  }, null, 8, ["name"])
                ]),
                _createElementVNode("h2", _hoisted_7, [
                  _createTextVNode(_toDisplayString(_ctx.finalLevel.title) + " ", 1),
                  _createElementVNode("span", _hoisted_8, [
                    _createVNode(_component_ElTag, {
                      effect: "plain",
                      size: "default",
                      "data-tag-type": "grey"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t("common.levelTag", { level: _ctx.finalLevel.value })), 1)
                      ]),
                      _: 1
                    })
                  ])
                ]),
                _withDirectives(_createElementVNode("div", _hoisted_9, null, 512), [
                  [_directive_markdown, _ctx.finalLevel.description]
                ])
              ]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }),
      _createVNode(_component_ResultsBoardPendingUser)
    ])
  ]))
}