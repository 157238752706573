import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-14697d5e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "role-playing-banner" }
const _hoisted_2 = { class: "role-playing-banner__wrapper" }
const _hoisted_3 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PxIcon = _resolveComponent("PxIcon")!
  const _component_PxButton = _resolveComponent("PxButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_PxIcon, {
        name: _ctx.$t('capitalExplorer.rolePlayingBanner.icon'),
        size: 16
      }, null, 8, ["name"]),
      _createElementVNode("div", {
        class: "role-playing-banner__message",
        innerHTML: _ctx.$t('capitalExplorer.rolePlayingBanner.message')
      }, null, 8, _hoisted_3),
      _createVNode(_component_PxButton, {
        label: _ctx.$t('capitalExplorer.rolePlayingBanner.action'),
        type: _ctx.buttonType,
        class: "role-playing-banner__action",
        icon: "arrow--right-black",
        "icon-position": "right",
        onClick: _ctx.goToDashboardPage
      }, null, 8, ["label", "type", "onClick"])
    ])
  ]))
}