<template>
  <div v-if="hasOnBoarded" class="matching-list__cards">
    <div
      v-show="hasFilters || hasDiscoverCards"
      class="matching-list__cards-header"
    >
      <h3 class="matching-list__cards-headline">
        {{
          $t(
            `matching.matchingList.discoverInterest[${$user.getUserAccountType()}]`,
          )
        }}
      </h3>
      <MatchingFilters
        :has-companies-search="true"
        class="matching-list__cards-filters"
        @has-filters-change="hasFilters = $event"
      />
    </div>
    <MatchingResultsLoading
      v-if="isLoadingNewMatches || (isLoadingInitialMatches && !hasFilters)"
    >
      <template #body>
        <MatchingCardPlaceholder
          v-for="index in expandedMatchesQuantity"
          :key="`virtual-list-placeholder-${index}`"
        />
      </template>
      <template v-if="isCalculatingInitialResults" #action>
        <div class="matching-results-loading--info">
          <h4>
            {{ $t(`matching.matchingList.loadingResultsState.title`) }}
          </h4>
          <p>
            {{ $t(`matching.matchingList.loadingResultsState.subtitle`) }}
          </p>
        </div>
      </template>
    </MatchingResultsLoading>
    <template v-else-if="hasDiscoverCards">
      <div
        v-if="expandedMatches.length > 0"
        class="matching-list__cards-wrapper"
      >
        <TransitionGroup
          class="matching-list__cards-grid"
          name="fade"
          tag="div"
        >
          <template v-for="(entry, index) in expandedMatches">
            <MatchingCard
              v-if="entry"
              :key="`matching-card--${index}`"
              :about="entry.about"
              :affiliates="entry.affiliates"
              :badges="entry.badges"
              :company-id="entry.id"
              :company-uid="entry.uid || ''"
              :disabled="entry.disabled"
              :is-directory-member="entry.isDirectoryMember"
              :level="entry.level"
              :location="entry.location"
              :locations="entry.locations"
              :match-percentage="entry.percentage"
              :name="entry.name"
              :offerings="entry.offerings"
              :sectors="entry.sectors"
              :show-list-button="showListFeature"
              :thumbnail="entry.thumbnail"
              @click="clickMatchingCardHandler(entry.id)"
            />
          </template>
        </TransitionGroup>
      </div>
      <div v-if="hasCompactMatches" class="matching-list__cards-wrapper">
        <h3 class="matching-list__cards-headline--more">
          {{ $t(`matching.matchingList.more[${$user.getUserAccountType()}]`) }}
        </h3>
        <MatchingCardCompactListHeader
          v-if="showListFeature"
          :user-type="matchUserType"
        />
        <VirtualGrid
          :columns="virtualGridColumns"
          :grid-gap="virtualGridGap"
          :item-height="virtualRowHeight"
          :items="compactMatches"
          :load-on-mount="true"
          @scroll="fetchMoreMatches"
        >
          <template #items="items">
            <MatchingCardCompact
              v-if="showListFeature"
              :key="`compact-card-${items.item.index}`"
              :about="items.item.about"
              :badges="items.item.badges"
              :company-id="items.item.id"
              :company-uid="items.item.uid"
              :disabled="items.item.disabled"
              :is-directory-member="items.item.isDirectoryMember"
              :level="items.item.level"
              :location="items.item.location"
              :locations="items.item.locations"
              :match-percentage="items.item.percentage"
              :name="items.item.name"
              :offerings="items.item.offerings"
              :sectors="items.item.sectors"
              :thumbnail="items.item.thumbnail"
              @click="clickMatchingCardHandler(items.item.id)"
            />
            <MatchingCardSimplified
              v-else
              :key="`simplified-card-${items.item.index}`"
              :about="items.item.about"
              :badges="items.item.badges"
              :company-id="items.item.id"
              :company-uid="items.item.uid"
              :level="items.item.level"
              :location="items.item.location"
              :locations="items.item.locations"
              :match-percentage="items.item.percentage"
              :name="items.item.name"
              :offerings="items.item.offerings"
              :sectors="items.item.sectors"
              :thumbnail="items.item.thumbnail"
              @click="clickMatchingCardHandler(items.item.id)"
            />
          </template>
          <template #loading="loading">
            <MatchingCardCompactPlaceholder
              :key="`virtual-list-placeholder-${loading.item}`"
            />
          </template>
        </VirtualGrid>
      </div>
    </template>
    <template v-else-if="hasNoCurrentMatches">
      <MatchingEmptyResults
        :notice="!hasFilters"
        :notice-button="emptyNoticeButton"
        :notice-subtitle="emptyNoticeSubtitle"
        :notice-title="emptyNoticeTitle"
        :subtitle="emptySubtitle"
        :title="emptyTitle"
        icon="matching.matchingList.emptyState.icon"
      />
    </template>
  </div>
</template>

<script lang="ts">
import {
  h,
  VNode,
  defineComponent,
  resolveComponent,
  markRaw,
  Component,
} from "vue";

import chunk from "lodash/chunk";
import VirtualGrid, {
  IStatusChanger,
} from "@/modules/matching/components/virtual-grid/virtual-grid.vue";

import MatchingCard from "@/components/matching-card/matching-card.vue";
import MatchingCardSimplified from "@/components/matching-card/matching-card--simplified.vue";
import MatchingCardCompactListHeader from "@/components/matching-card/matching-card-compact-list-header.vue";
import MatchingCardCompact from "@/components/matching-card/matching-card-compact.vue";
import MatchingCardCompactPlaceholder from "@/components/matching-card/matching-card-compact-placeholder.vue";
import MatchingCardPlaceholder from "@/components/matching-card/matching-card-placeholder.vue";

import MatchingEmptyResults from "@/modules/matching/components/matching-empty-results/matching-empty-results.vue";
import MatchingFilters from "@/modules/matching/components/matching-filters/matching-filters.vue";
import MatchingResultsLoading from "@/modules/matching/components/matching-results-loading/matching-results-loading.vue";

import { ICompany } from "@/modules/profile/services/data/company/company.types";
import { EMatchingActions } from "@/modules/matching/services/store/matching/matching.types";
import { EUserMetadataGetters } from "@/modules/authentication/services/store/auth/sub-modules/user-metadata/user-metadata.types";
import { EProfileLatestAssessmentActions } from "@/modules/profile/services/store/profile/submodules/profile-latest-assessment/profile-latest-assessment-types";
import { IMatchingCard } from "@/modules/matching/services/data/matching-card/matching-card.interface";
import { MATCHING_BADGE_DIRECTORY_LISTING } from "@/modules/matching/constants";
import MatchingListMixin from "@/mixins/matching-list.mixin";

import {
  MATCHING_ONBOARDING_COMPLETION,
  MATCHING_USER_TYPES,
} from "@/modules/matching/constants";
import {
  ENTREPRENEUR_USER_TYPE,
  SUPPORTER_USER_TYPE,
} from "@/modules/authentication/constants";
import { ICompanyList } from "@/modules/company-lists/services/data/company-list/company-list.interface";

export default defineComponent({
  name: "MatchingListCards",

  components: {
    MatchingCard,
    MatchingCardSimplified,
    MatchingCardCompactListHeader,
    MatchingCardCompact,
    MatchingCardCompactPlaceholder,
    MatchingEmptyResults,
    MatchingFilters,
    VirtualGrid,
    MatchingCardPlaceholder,
    MatchingResultsLoading,
  },

  mixins: [MatchingListMixin],

  data() {
    return {
      minCompactedMatchesQuantity: 6,
      infiniteLoadingPlaceholderQuantity: 3,
      routeParams: this.$route.params,
      hasFilters: false,
      loadingMessage: null as any,
      calculationsPolling: null as NodeJS.Timeout | null,
      PxButton: markRaw(resolveComponent("PxButton") as Component),
    };
  },

  computed: {
    isLoading(): boolean {
      return (
        this.isDiscoverLoading &&
        (!this.hasDiscoverCards || this.hasChangedDiscoverFilters)
      );
    },

    isLoadingNewMatches(): boolean {
      return (
        (this.isLoading && (!this.expandedMatches.length || this.hasFilters)) ||
        // Force loading state when the user clears all filters to avoid showing a premature empty state:
        (this.hasNoCurrentMatches &&
          !this.hasFilters &&
          this.hasAlreadyRetrievedInitialResults)
      );
    },

    isLoadingInitialMatches(): boolean {
      return this.hasOnGoingCalculations && !this.hasDiscoverCards;
    },

    isCalculatingInitialResults(): boolean {
      return (
        this.isLoadingInitialMatches &&
        !this.isLoadingNewMatches &&
        !this.hasAlreadyRetrievedInitialResults
      );
    },

    /**
     * Verify the existence of ongoing matching calculations.
     *
     * @return {boolean}
     */
    hasOnGoingCalculations(): boolean {
      return this.$store.get("matching/recalculating") || false;
    },

    hasNoCurrentMatches(): boolean {
      return (
        !this.isLoading &&
        !this.hasDiscoverCards &&
        (!this.hasOnGoingCalculations || this.hasFilters)
      );
    },

    company(): ICompany | null {
      return this.$store.get("auth/company.data");
    },

    companyId(): number | null {
      return this.company ? this.company.id : null;
    },

    /**
     * Get target interest user type
     */
    targetType(): string {
      const oppositeUserType = +!this.$user.getUserAccountType();
      return MATCHING_USER_TYPES[oppositeUserType];
    },

    selectedLists(): Array<ICompanyList> {
      return this.$store.get("listManagement.data.lists") || [];
    },

    /**
     * Get companies uids that are already in the selected list
     */
    listsCompaniesUid(): Array<string> {
      if (!this.selectedLists.length) {
        return [];
      }

      const list = this.selectedLists[0];
      const companies = list.allCompaniesCompacted || list.companies;

      return companies.map(({ uid }) => uid as string);
    },

    /**
     * List of expanded matches.
     */
    expandedMatches(): Array<IMatchingCard | null> {
      const paginatedMatches = this.matchCards.slice(
        0,
        this.expandedMatchesQuantity,
      );

      return this.hasDiscoverCards ? this.mapMatches(paginatedMatches) : [];
    },

    /**
     * List of compact version matches.
     *
     * This is only used when there is more than 6 matches.
     */
    compactMatches(): Array<IMatchingCard | null> {
      const paginatedMatches = this.matchCards.slice(
        this.expandedMatchesQuantity,
        this.matchCards.length,
      );

      return this.hasDiscoverCards ? this.mapMatches(paginatedMatches) : [];
    },

    hasCompactMatches(): boolean {
      return this.compactMatches.length > 0;
    },

    /**
     * Get the latest loaded page.
     */
    currentPage(): number {
      return this.$store.get("matching.page");
    },

    hasOnBoarded(): boolean {
      return (
        // Skip matching onboarding for Supporters as requested here:
        // https://pixelmatters.atlassian.net/browse/VIR-1198
        this.$user.isSupporter() ||
        // Else, keep onboarding for Entrepreneurs:
        !!this.$store.get(
          EUserMetadataGetters.GET,
          MATCHING_ONBOARDING_COMPLETION,
        )
      );
    },

    expandedMatchesQuantity(): number {
      if (this.$screen.xlUp) {
        return 8;
      }

      if (this.$screen.lgUp) {
        return 6;
      }

      return 4;
    },

    virtualListRowValue(): IMatchingCard[][] {
      return this.split(this.compactMatches, 2);
    },

    virtualGridGap(): number {
      return this.$screen.lgUp ? 16 : 40;
    },

    virtualRowHeight(): number {
      return this.$screen.lgUp ? 104 : 276;
    },

    virtualGridColumns(): number {
      // Matching Card and Compact view
      if (this.$screen.lgUp) {
        return 1;
      }

      // Matching Card and Card Simplified view (Tablet view and lower)
      if (this.$screen.mdUp) {
        return 2;
      }

      return 1;
    },

    matchUserType(): number {
      return this.$user.isEntrepreneur()
        ? SUPPORTER_USER_TYPE
        : ENTREPRENEUR_USER_TYPE;
    },

    emptyTitle(): string {
      return this.hasFilters
        ? "matching.matchingList.emptyState.filteringTitle"
        : "matching.matchingList.emptyState.title";
    },

    emptySubtitle(): string {
      return this.hasFilters
        ? "matching.matchingList.emptyState.filteringSubtitle"
        : "matching.matchingList.emptyState.subtitle";
    },

    emptyNoticeTitle(): string {
      return this.$user.isEntrepreneur()
        ? "matching.matchingList.emptyState.notice.title"
        : "matching.matchingList.emptyState.noticeSupporter.title";
    },

    emptyNoticeSubtitle(): string {
      return this.$user.isEntrepreneur()
        ? "matching.matchingList.emptyState.notice.subtitle"
        : "matching.matchingList.emptyState.noticeSupporter.subtitle";
    },

    emptyNoticeButton(): string {
      return this.$user.isEntrepreneur()
        ? "matching.matchingList.emptyState.notice.button"
        : "matching.matchingList.emptyState.noticeSupporter.button";
    },
  },

  watch: {
    hasOnGoingCalculations: {
      immediate: true,
      handler(isCalculating) {
        if (isCalculating) {
          this.longPollCalculations();

          this.$nextTick(() => {
            if (this.hasAlreadyRetrievedInitialResults || this.hasFilters) {
              this.showRecalculationInfo();
            }
          });
        } else if (this.calculationsPolling) {
          this.stopPollingCalculations();

          if (this.hasAlreadyRetrievedInitialResults || this.hasFilters) {
            // If this is a recalculation, then will deal with the toast messages:
            this.hideRecalculationInfo();
            this.displaySuccessfulRecalculationMessage();
          } else {
            // Fetch initial results:
            this.$store.dispatch(EMatchingActions.FETCH, this.discoverFilters);
          }
        }
      },
    },
  },

  created() {
    if (this.$user.isEntrepreneur() && this.companyId) {
      this.$store.dispatch(
        EProfileLatestAssessmentActions.FETCH,
        this.companyId,
      );
    }
  },

  beforeUnmount() {
    this.$message.closeAll();
  },

  unmounted() {
    this.stopPollingCalculations();
  },

  methods: {
    /**
     * Fetch the next page of the matching score list.
     */
    async fetchMoreMatches($status: IStatusChanger) {
      const response = await this.$store.dispatch(EMatchingActions.FETCH, {
        ...this.discoverFilters,
        page: this.currentPage + 1,
      });

      response && !response.length ? $status.complete() : $status.ready();
    },

    /**
     * Split list into requested size
     * https://lodash.com/docs/4.17.15#chunk
     */
    split(list: Array<any>, size: number): any[][] {
      return chunk(list, size);
    },

    /**
     * Map matches:
     * - With their current disabled state
     */
    mapMatches(
      matches: Array<IMatchingCard | null>,
    ): Array<IMatchingCard | null> {
      return matches.map((matchingCard) => {
        if (matchingCard) {
          matchingCard.disabled = this.listsCompaniesUid.includes(
            matchingCard?.uid || "",
          );

          if (matchingCard.badges) {
            matchingCard.isDirectoryMember = matchingCard.badges.some(
              (badge) => badge.name === MATCHING_BADGE_DIRECTORY_LISTING,
            );
          }
        }

        return matchingCard;
      });
    },

    longPollCalculations() {
      this.calculationsPolling = setInterval(() => {
        this.$store.dispatch(EMatchingActions.FETCH_RECALCULATIONS_STATE);
      }, 6000);
    },

    stopPollingCalculations() {
      if (this.calculationsPolling) {
        clearInterval(this.calculationsPolling);
      }
    },

    /**
     * Display ongoing recalculation toast message.
     * Create poll for tracking recalculation completion.
     * Display successful recalculation toast on completion.
     */
    showRecalculationInfo() {
      this.loadingMessage = this.displayLoadingCalculationMessage();
    },

    hideRecalculationInfo() {
      this.loadingMessage.close();
    },

    /**
     * Display loading recalculation toast message.
     *
     * @return {*}
     */
    displayLoadingCalculationMessage(): any {
      return this.$message({
        message: this.$t(
          "matching.matchingList.recalculateCriteriaState.loading",
        ) as string,
        customClass: "is-navbar is-loading",
        duration: 0,
      });
    },

    /**
     * Display successful recalculation toast message.
     */
    displaySuccessfulRecalculationMessage() {
      this.$message({
        message: this.getSuccessfulRecalculationMessageContent(),
        type: "success",
        customClass: "is-navbar",
        duration: 0,
      });
    },

    /**
     * Get content for successful recalculation toast message.
     *
     * @return {VNode}
     */
    getSuccessfulRecalculationMessageContent(): VNode {
      return h("p", { class: "el-message__content" }, [
        this.$t("matching.matchingList.recalculateCriteriaState.loaded"),
        h(
          this.PxButton,
          {
            type: "link",
            onClick: this.refreshPage,
            class: "el-button--link-white",
          },
          {
            default: () => this.$t("common.refresh"),
          },
        ),
      ]);
    },
  },
});
</script>

<style lang="scss" scoped>
.matching-list__cards {
  padding-top: 27px;

  @include breakpoint-up(lg) {
    padding-top: 8px;
  }
}

.matching-list__cards-filters {
  @include breakpoint-down(md) {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .matching-list__cards & {
    @include breakpoint-up(md) {
      top: -30px;
    }
  }
}

.matching-list__cards-wrapper {
  max-width: 290px;
  margin: 0 auto 66px;

  &:last-child {
    margin-bottom: 0;
  }

  @include breakpoint-up(md) {
    max-width: none;
    margin-right: 0;
    margin-left: 0;
  }
}

.matching-list__cards-grid {
  position: relative;
  top: 0;
  left: 0;
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: auto;
  grid-row-gap: 50px;
  grid-column-gap: 22px;
  justify-content: center;
  justify-items: center;

  @include breakpoint-up(md) {
    grid-template-columns: repeat(2, auto);
    grid-row-gap: 40px;
    justify-content: left;
  }

  @include breakpoint-up(lg) {
    grid-template-columns: repeat(3, auto);
  }

  @include breakpoint-up(xl) {
    grid-template-columns: repeat(4, auto);
  }

  .fade-enter {
    opacity: 0;
  }

  .fade-enter-active {
    transition:
      transform 1s,
      opacity 1s;
  }

  .fade-leave-active {
    position: absolute;
    opacity: 0;
    transition:
      transform 0.3s,
      opacity 0.3s;
    transform: translateY(0);
  }

  .fade-move {
    transition:
      transform 0.5s cubic-bezier(0.77, 0, 0.175, 1),
      opacity 0.5s cubic-bezier(0.77, 0, 0.175, 1);

    @for $i from 1 through 6 {
      &:nth-child(#{$i}) {
        z-index: z("floaters") - $i;
        transition: transform 0.4s cubic-bezier(0.77, 0, 0.175, 1);
      }
    }
  }
}

.matching-list__row {
  position: relative;
  top: 0;
  left: 0;
  box-sizing: border-box;
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: auto;
  grid-row-gap: 50px;
  grid-column-gap: 24px;
  justify-content: center;
  justify-items: center;
  margin-bottom: 50px;

  @include breakpoint-up(md) {
    grid-row-gap: 24px;
    margin-bottom: 24px;
  }

  @include breakpoint-up(xl) {
    grid-template-columns: repeat(2, auto);
    justify-content: left;
  }
}

.matching-empty-results {
  padding: 34px 0;
  margin: 0 auto;

  @include breakpoint-up(md) {
    padding: 40px 0;
  }
}

.matching-list__cards-grid:empty + .matching-empty-results {
  margin-top: 0;
}

.matching-list__cards-header {
  display: flex;
  flex-flow: row wrap;

  max-width: 290px;
  margin: 0 auto;

  @include breakpoint-up(md) {
    flex-direction: column;
    max-width: none;
    padding-top: 4px;
    padding-bottom: 5px;
  }
}

.matching-list__cards-headline {
  margin-top: 4px;
}

.matching-list__cards-headline,
.matching-list__cards-headline-more {
  @include grotesk(semiBold);

  margin-bottom: 6px;
  font-size: 18px;
  line-height: 25px;
  text-align: left;

  &:only-child {
    @include breakpoint-down(md) {
      width: 290px;
    }
  }

  &:not(:only-child) {
    @include breakpoint-down(md) {
      flex: 1 1 40%;
      padding-right: 30px;
    }
  }

  @include breakpoint-up(md) {
    width: auto;
    max-width: 100%;
    padding: 0;
    margin-bottom: 0;
    margin-left: -1px;
    font-size: 20px;
  }
}

.matching-list__cards .matching-list__cards-headline:not(:only-child) {
  @include breakpoint-down(md) {
    padding-right: 0;
  }
}

.matching-list__cards-headline--more {
  @include grotesk(semiBold);

  margin-bottom: 22px;
  font-size: 20px;
  color: $manatee;

  @include breakpoint-up(md) {
    margin-bottom: 32px;
  }
}

.matching-empty-results :deep(.px-notice) {
  position: relative;
  margin: 16px auto 0;

  .px-notice__wrapper {
    @include breakpoint-up(md) {
      padding: 8px 14px 8px 32px;
    }
  }

  @include breakpoint-up(md) {
    left: 8px;
    max-width: 512px;
    margin-top: 30px;
  }
}
</style>
