<template>
  <div class="px-slider-card">
    <div class="px-slider-card__header">
      <h3 class="px-slider-card__title">
        {{ title }}
      </h3>
      <slot name="sub-header" />
    </div>
    <div class="px-slider-card__body">
      <div class="px-slider-card__body-wrapper">
        <slot name="body" />
      </div>
      <div class="px-slider-card__slider">
        <ElSlider
          v-model="selectedStop"
          :format-tooltip="formatTooltip"
          :max="sliderCount"
          :min="1"
          :step="1"
          show-stops
          tooltip-class="px-slider-card__tooltip el-abaca-tooltip"
          @change="sliderChangeHandler"
        />
        <div class="px-slider-card__stops">
          <span
            v-for="index in sliderCount"
            :key="`${index}`"
            class="px-slider-card__tooltip-text"
          />
        </div>
        <div class="px-slider-card__labels">
          <span
            v-for="oddStop in sliderOddStops"
            :key="oddStop.value"
            :class="{ 'is-active': oddStop.value === currentStopValue }"
          >
            {{ oddStop.label }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export interface ISliderCardStop {
  label: string;
  value: number;
}

export default defineComponent({
  name: "PxSliderCard",

  props: {
    modelValue: {
      type: Number as () => number | null,
      default: null,
    },
    title: {
      type: String,
      default: "",
    },
    sliderStops: {
      type: Array as () => ISliderCardStop[],
      required: true,
    },
    // Place the slider button on the middle if stops count is odd.
    startOnMiddle: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      selectedStop: 0,
    };
  },

  computed: {
    sliderCount(): number {
      return this.sliderStops.length;
    },
    sliderOddStops(): Array<ISliderCardStop> {
      // Get only odd indexed items
      return this.sliderStops.filter((value, index) => !(index % 2));
    },
    currentStopValue(): number | null {
      const selectedStopIndex = this.selectedStop - 1;

      return this.sliderStops[selectedStopIndex]
        ? this.sliderStops[selectedStopIndex].value
        : null;
    },
  },

  watch: {
    /**
     * Update slider value when the prop value changes.
     */
    currentStopValue(currentValue) {
      if (!!currentValue && typeof this.modelValue !== "number") {
        this.$emit("update:modelValue", this.currentStopValue);
      }
    },
  },

  mounted() {
    if (this.modelValue) {
      this.selectedStop = this.modelValue;
      return;
    }

    const sliderCountIsOdd = this.sliderStops.length % 2 !== 0;

    if (this.startOnMiddle && sliderCountIsOdd) {
      // Find middle index of slider stops
      this.selectedStop = Math.round(this.sliderStops.length / 2);
    }
  },

  methods: {
    formatTooltip(stopIndex: number): string | number {
      const selectedStopIndex = stopIndex - 1;

      return this.sliderStops[selectedStopIndex]
        ? this.sliderStops[selectedStopIndex].label
        : stopIndex;
    },

    sliderChangeHandler() {
      this.$emit("update:modelValue", this.currentStopValue);
      this.$emit("changed", this.currentStopValue);
    },
  },
});
</script>

<style lang="scss" scoped>
.px-slider-card {
  position: relative;
  padding: 0 0 24px;
  background-color: white;
  border: solid 1px rgba(220, 221, 226, 0.8);
  border-radius: 6px;
}

.px-slider-card__header {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-between;
  min-height: 37px;
  padding: 0 15px;
  background-color: $alabaster;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.px-slider-card__title {
  @include grotesk(semiBold);

  max-width: 90%;
  padding: 10px 0;
  margin-bottom: 0;
  font-size: 14px;
  color: $gun-powder;
}

.px-slider-card__body-wrapper {
  padding-top: 10px;

  &:empty {
    display: none;
  }
}

.px-slider-card__slider :deep() {
  position: relative;
  margin-top: -6px;

  .el-slider {
    margin: auto 71px;
  }

  .el-slider__runway {
    position: relative;
    height: 16px;
    background: none;

    &::before {
      position: absolute;
      top: 0;
      right: -20px;
      left: -20px;
      height: 100%;
      content: "";
      background-color: rgba(39, 39, 64, 0.9);
      border: solid 1px rgba(250, 250, 250, 0.08);
      border-radius: 15px;
    }
  }

  .el-slider__stop {
    top: 5px;
    opacity: 0;
  }

  .el-slider__button-wrapper {
    top: -6px;
    width: 28px;
    height: 28px;
  }

  .el-slider__button {
    position: relative;
    width: 28px;
    height: 28px;
    background-color: $alabaster;
    border: none;
    box-shadow: 0 0 4px 0 rgba(39, 39, 64, 0.5);

    &::before,
    &::after {
      position: absolute;
      top: 9px;
      width: 2px;
      height: 10px;
      content: "";
      background-color: $ebony-clay;
      border-radius: 2px;
    }

    &::before {
      left: 10px;
    }

    &::after {
      right: 10px;
    }
  }

  .el-slider__bar {
    opacity: 0;
  }
}

.px-slider-card__labels {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0 8px;
  margin-top: -4px;

  span {
    @include grotesk(medium);

    flex: 0 1 120px;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    opacity: 0.7;
    will-change: opacity;
    transition: opacity 120ms ease-in;

    &.is-active {
      @include grotesk(semiBold);

      opacity: 1;
    }
  }
}

.px-slider-card__stops {
  position: absolute;
  top: -2px;
  right: 0;
  left: 0;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  width: calc(100% - 70px);
  height: 20px;
  margin: auto;
  pointer-events: none;

  span {
    position: relative;
    top: 6px;
    width: 3px;
    height: 8px;
    background-color: white;
    border-radius: 2px;

    &:first-child,
    &:last-child {
      top: 5px;
      height: 10px;
    }
  }
}
</style>

<style lang="scss">
.px-slider-card__tooltip {
  padding: 4px 10px;
  background-color: #777784;

  .px-slider-card__tooltip-text {
    @include grotesk(semiBold);

    font-size: 14px;
  }
}

.px-slider-card__tooltip[data-popper-placement^="top"] .el-popper__arrow {
  border-top-color: #777784;

  &::after {
    border-top-color: #777784;
  }
}
</style>
