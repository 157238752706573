import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-12853762"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "feature-card" }
const _hoisted_2 = { class: "feature-card__content" }
const _hoisted_3 = { class: "feature-card__title" }
const _hoisted_4 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PxIcon = _resolveComponent("PxIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.icon)
      ? (_openBlock(), _createBlock(_component_PxIcon, {
          key: 0,
          name: _ctx.icon,
          size: _ctx.iconSize,
          class: "feature-card__icon"
        }, null, 8, ["name", "size"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h2", _hoisted_3, _toDisplayString(_ctx.title), 1),
      _createElementVNode("p", {
        class: "feature-card__description",
        innerHTML: _ctx.description
      }, null, 8, _hoisted_4)
    ])
  ]))
}