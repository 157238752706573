import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-8b4d4d98"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PxButton = _resolveComponent("PxButton")!
  const _component_PxMessageBar = _resolveComponent("PxMessageBar")!

  return (_openBlock(), _createBlock(_component_PxMessageBar, {
    modelValue: _ctx.showMessage,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.showMessage) = $event)),
    class: "submissions-notification-message"
  }, {
    content: _withCtx(() => [
      _createElementVNode("p", { innerHTML: _ctx.pendingDraftSubmissionsCopy }, null, 8, _hoisted_1)
    ]),
    action: _withCtx(() => [
      _createVNode(_component_PxButton, {
        label: _ctx.$t('profile.submissionsNotification.action'),
        size: "small",
        type: "link-white",
        onClick: _ctx.goToSubmissionsPage
      }, null, 8, ["label", "onClick"])
    ]),
    _: 1
  }, 8, ["modelValue"]))
}