<template>
  <div class="criteria-multi-selector__panel-wrapper">
    <div
      v-if="!isCriteriaQuestionVisible"
      :class="{
        'criteria-multi-selector__panel-container--one-column':
          isHighlightTextActive,
      }"
      class="criteria-multi-selector__panel-container"
    >
      <template v-if="!isHighlightTextActive">
        <div class="criteria-multi-selector__option-container">
          <div
            v-for="item in items"
            :key="item.value"
            class="criteria-multi-selector__item"
            @click="onCriteriaCategoryClick(item)"
          >
            <span class="criteria-multi-selector__item-label">
              {{ item.label }}
            </span>
            <PxIcon :size="14" name="arrow--right-dark" />
          </div>
        </div>
        <div class="criteria-multi-selector__child-container">
          <div
            v-for="child in visibleChildren"
            :key="child.value"
            class="criteria-multi-selector__item"
            @click="onCriteriaSelect(child)"
          >
            <span class="criteria-multi-selector__item-label">
              {{ child.label }}
            </span>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="criteria-multi-selector__search-container">
          <div class="criteria-multi-selector__action-header-wrapper">
            <div class="criteria-multi-selector__action-header-container">
              <span class="criteria-multi-selector__action-header-text">
                {{ highlightedCountCopy }}
              </span>
            </div>
          </div>
          <template v-if="hasHighlightedTextItems">
            <div
              v-for="item in highlightedTextItems"
              :key="item.value"
              class="criteria-multi-selector__item"
              @click="onCriteriaSelect(item)"
            >
              <span
                class="criteria-multi-selector__item-label"
                v-html="
                  highlightLabelByQuery(`${item.parent?.label} > ${item.label}`)
                "
              ></span>
            </div>
          </template>
          <div class="criteria-multi-selector__empty-wrapper">
            <div class="criteria-multi-selector__empty-container">
              <PxIcon
                :size="33"
                class="criteria-multi-selector__empty-icon"
                name="icon-feedback"
              />
              <div>
                <div class="criteria-multi-selector__empty-text">
                  {{
                    $t(
                      "supporters.component.criteriaSelector.emptyState.description[0]",
                    )
                  }}
                </div>
                <div class="criteria-multi-selector__empty-text">
                  {{
                    $t(
                      "supporters.component.criteriaSelector.emptyState.description[1]",
                    )
                  }}
                </div>
              </div>
              <div class="criteria-multi-selector__empty-button-container">
                <PxButton size="small" @click="onModalVisibilityToggle">
                  {{
                    $t("supporters.component.criteriaSelector.emptyState.cta")
                  }}
                </PxButton>
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>
    <div v-else class="criteria-multi-selector__question-container">
      <div class="criteria-multi-selector__action-header-wrapper">
        <div
          class="criteria-multi-selector__action-header-container"
          @click="onQuestionClose"
        >
          <PxIcon name="arrow--left-dark" size="14" />
          <span class="criteria-multi-selector__action-header-text">
            {{ $t("supporters.signup.form.previousButton") }}
          </span>
        </div>
      </div>
      <CriteriaQuestionPanel
        v-if="!!currentQuestion"
        v-model="currentResponse"
        :question="currentQuestion"
        class="question-flow__panel"
        @validate="validateQuestionSubmitState"
      />
      <div class="criteria-multi-selector__question-cta-container">
        <PxButton
          class="el-button--small criteria-multi-selector__question-cta"
          type="link"
          @click="onQuestionClear"
        >
          {{ $t("supporters.signup.form.clearButton") }}
        </PxButton>
        <PxButton
          :disabled="isSubmitButtonDisabled"
          class="criteria-multi-selector__question-cta criteria-multi-selector__question-cta--submit"
          type="green"
          @click="onQuestionSubmit"
        >
          {{ $t("supporters.signup.form.addButton") }}
        </PxButton>
      </div>
    </div>
    <ActionModal
      v-if="showCriteriaSuggestionModal"
      ref="criteriaSuggestionModal"
      :title="$t('supporters.component.criteriaSuggestionModal.title')"
      :visibility="showCriteriaSuggestionModal"
      class="criteria-multi-selector__modal-wrapper"
    >
      <template #content>
        <p class="criteria-multi-selector__modal-description">
          {{ $t("supporters.component.criteriaSuggestionModal.description") }}
        </p>
        <ElForm>
          <ElFormItem
            :label="
              $t('supporters.component.criteriaSuggestionModal.input.label')
            "
            prop="suggestion"
          >
            <PxTextarea
              v-model="suggestionValue"
              :max-chars="maxSuggestionSize"
              :placeholder="
                $t(
                  'supporters.component.criteriaSuggestionModal.input.placeholder',
                )
              "
              :show-counter="true"
              resize="none"
            />
          </ElFormItem>
        </ElForm>
      </template>
      <template #actions>
        <PxButton
          class="el-button--link"
          type="link"
          @click="onModalVisibilityToggle"
        >
          {{ $t("supporters.component.criteriaSuggestionModal.secondaryCta") }}
        </PxButton>
        <PxButton
          :disabled="!hasSuggestionValue"
          size="small"
          type="green"
          @click="onSuggestionSubmit"
        >
          {{ $t("supporters.component.criteriaSuggestionModal.mainCta") }}
        </PxButton>
      </template>
    </ActionModal>
    <div v-show="!forceElementSelectBehaviour">
      <ElOption :value="0" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import ActionModal from "@/modules/supporters/components/action-modal/action-modal.vue";
import CriteriaQuestionPanel from "@/modules/supporters/components/criteria-question-panel/criteria-question-panel.vue";

import { IMultiSelectorItem } from "@/components/multi-selector/multi-selector.interface";
import { IMatchingResponse } from "@/services/data/matching-responses/matching-response.interface";
import { IMatchingQuestion } from "@/services/data/matching-questionary/matching-question.interface";
import { supportProvider } from "@/services/data/support/support.provider";
import { SUPPORT_SUBJECT_CRITERIA_SUGGESTION } from "@/services/data/support/support.interface";

export default defineComponent({
  name: "CriteriaMultiSelectorOptionPanel",

  components: { ActionModal, CriteriaQuestionPanel },

  props: {
    items: {
      type: Array as () => Array<IMultiSelectorItem>,
      required: true,
    },
    selectedItems: {
      type: Array as () => Array<IMatchingResponse>,
      required: true,
    },
    highlightText: {
      type: String,
      required: true,
    },
    highlightItem: {
      type: Object as () => IMultiSelectorItem | null,
      default: null,
    },
  },

  data() {
    return {
      // Used to force hide a single option component, which is required to the Element UI select component
      // to work as it is expected
      forceElementSelectBehaviour: true,

      isCriteriaQuestionVisible: false,
      focusedCriteriaCategory: null as IMultiSelectorItem | null,
      focusedCriteria: null as IMultiSelectorItem | null,

      // Response fields
      currentResponse: {} as IMatchingResponse,
      isSubmitButtonDisabled: true,
      innerSelectedItems: [] as Array<IMultiSelectorItem>,

      // Empty state suggestion modal
      showCriteriaSuggestionModal: false,
      suggestionValue: "",
      maxSuggestionSize: 47,
    };
  },

  computed: {
    visibleChildren(): Array<IMultiSelectorItem> {
      return this.focusedCriteriaCategory?.children || [];
    },

    currentQuestion(): IMatchingQuestion | null {
      return this.focusedCriteria?.meta || null;
    },

    flattenItems(): Array<IMultiSelectorItem> {
      return this.items.reduce(
        (accumulator: Array<IMultiSelectorItem>, item: IMultiSelectorItem) => {
          const flatItemChildren =
            item.children?.map((child: IMultiSelectorItem) => ({
              ...child,
              meta: {
                ...child.meta,
                flatLabel: `${item.label} ${child.label}`.toLowerCase(),
              },
            })) || [];

          return [...accumulator, ...flatItemChildren];
        },
        [],
      );
    },

    isHighlightTextActive(): boolean {
      return this.highlightText.length >= 2;
    },

    highlightedTextItems(): Array<IMultiSelectorItem> {
      return this.isHighlightTextActive
        ? this.flattenItems.filter((item: IMultiSelectorItem) =>
            item.meta.flatLabel
              .toString()
              .includes(this.highlightText.toLowerCase()),
          )
        : [];
    },

    hasHighlightedTextItems(): boolean {
      return !!this.highlightedTextItems.length;
    },

    highlightedCountCopy(): string {
      const unityCopy =
        this.highlightedTextItems.length === 1
          ? this.$t("supporters.component.criteriaSelector.result")
          : this.$t("supporters.component.criteriaSelector.results");

      return `${this.highlightedTextItems.length} ${unityCopy}`;
    },

    hasSuggestionValue(): boolean {
      return (
        this.suggestionValue !== "" &&
        this.suggestionValue.length <= this.maxSuggestionSize
      );
    },
  },

  watch: {
    items: {
      immediate: true,
      deep: true,
      handler(values: Array<IMultiSelectorItem>) {
        // Select first category is none is selected
        this.focusedCriteriaCategory = !this.focusedCriteriaCategory?.value
          ? values[0] || null
          : this.focusedCriteriaCategory;
      },
    },

    highlightItem: {
      immediate: true,
      deep: true,
      handler(item: IMultiSelectorItem) {
        if (item?.value) {
          this.onCriteriaSelect(item);
        } else {
          this.focusedCriteria = null;
          this.isCriteriaQuestionVisible = false;
        }
      },
    },

    highlightedTextItems: {
      deep: true,
      handler(values: Array<IMultiSelectorItem>) {
        if (values.length) {
          this.focusedCriteria = null;
          this.isCriteriaQuestionVisible = false;
        }
      },
    },
  },

  methods: {
    /**
     * Method to show a list of criteria based on the clicked category.
     */
    onCriteriaCategoryClick(category: IMultiSelectorItem) {
      this.focusedCriteriaCategory = category;
    },

    /**
     * Method to show a specific criteria question visible.
     */
    onCriteriaSelect(criteria: IMultiSelectorItem) {
      this.focusedCriteria = criteria;
      const selectedItem = this.selectedItems.find(
        (item: IMatchingResponse) =>
          item.question === this.focusedCriteria?.value,
      );

      if (selectedItem) {
        // Prefill current response from a previously selected item
        this.currentResponse = selectedItem;
      } else if (this.currentResponse) {
        // If the chosen criteria hasn't been selected before
        // and the current response has been previously prefilled
        // we need to make sure to clear it!!
        this.onQuestionClear();
      }

      this.$emit("update:highlightItem", criteria);
      this.isCriteriaQuestionVisible = true;
    },

    /**
     * Set question visibility to false.
     */
    onQuestionClose() {
      this.isCriteriaQuestionVisible = false;
      this.$emit("update:highlightItem", {});
    },

    /**
     * Clear current response.
     */
    onQuestionClear() {
      this.currentResponse = {} as IMatchingResponse;
    },

    /**
     * Emit response to saved responses.
     */
    onQuestionSubmit() {
      // In this component we work with three hierarchy degrees (criteria category, question, answer)
      // Only question (child) and answers (grandchildren) are emitted, we don't need to store selected parents as they are not required
      this.$emit("update", { ...this.currentResponse });

      // Trigger clear and return
      this.onQuestionClear();
      this.onQuestionClose();
    },

    /**
     * Runs on response change, fetches submit state.
     */
    validateQuestionSubmitState(isValid: boolean) {
      this.isSubmitButtonDisabled = !isValid;
    },

    /**
     * Highlight option text.
     *
     * @param label
     */
    highlightLabelByQuery(label: string): string {
      if (!this.isHighlightTextActive) {
        return label;
      }

      return label.replace(
        new RegExp(this.highlightText, "gi"),
        `<div class="criteria-multi-selector__highlighted-text">$&</div>`,
      );
    },

    onModalVisibilityToggle() {
      this.showCriteriaSuggestionModal = !this.showCriteriaSuggestionModal;
    },

    async onSuggestionSubmit() {
      await supportProvider.postSuggestion({
        subject: SUPPORT_SUBJECT_CRITERIA_SUGGESTION,
        message: this.suggestionValue,
      });

      // TODO: Maybe add a success message here? Design not specified

      // Toggle modal visibility
      this.onModalVisibilityToggle();
    },
  },
});
</script>

<style lang="scss" scoped>
.criteria-multi-selector__panel-wrapper,
.criteria-multi-selector__question-container {
  position: relative;
  min-height: 243px;
  max-height: 400px;
}

.criteria-multi-selector__panel-container {
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: repeat(2, 1fr);
  min-height: inherit;

  &--one-column {
    grid-template-columns: 1fr;
  }
}

.criteria-multi-selector__option-container {
  display: flex;
  flex-direction: column;
  border-right: 1px solid $mischka;
}

.criteria-multi-selector__children-container {
  position: absolute;
  right: 0;
  width: 50%;
}

.criteria-multi-selector__item {
  display: grid;
  grid-template-columns: auto 14px;
  align-items: center;
  height: auto;
  padding: 13px 30px;
  overflow: initial;
  cursor: pointer;

  &::before {
    display: none;
  }

  &:hover {
    background: rgba($mischka, 0.3);
  }
}

.criteria-multi-selector__item-label {
  @include grotesk(regular);

  display: block;
  font-size: 14px;

  :deep() .criteria-multi-selector__highlighted-text {
    @include grotesk(bold);

    display: inline-block;
  }
}

.criteria-multi-selector__question-container {
  display: grid;
  grid-template-rows: 37px auto 47px;
}

.criteria-multi-selector__action-header-container .px-icon {
  // Pixel-perfect alignment:
  margin-right: 2px;
  margin-left: -1px;
  vertical-align: -1px;
}

.criteria-multi-selector__action-header {
  &-wrapper {
    padding: 8px 30px;
    border-bottom: 1px solid $athens-gray;
  }

  &-container {
    cursor: pointer;
  }

  &-text {
    @include grotesk(semiBold);

    font-size: 12px;
    color: rgba($ebony-clay, 0.6);
    text-transform: uppercase;
    vertical-align: text-bottom;
    transition: color 150ms ease-in-out;
  }

  &-text:hover {
    color: $ebony-clay;
  }
}

.criteria-multi-selector__question-cta {
  min-width: 64px;
  height: 32px;

  &--submit :deep() span {
    font-size: 12px;
  }

  &-container {
    display: grid;
    grid-template-columns: repeat(2, auto);
    grid-gap: 1px;
    justify-content: flex-end;
    padding: 7px 19px;
    background: $alabaster;
  }
}

.criteria-multi-selector__empty {
  &-wrapper {
    display: flex;
    justify-content: center;
    padding: 32px 0 48px;
  }

  &-container {
    position: relative;
    display: grid;
    grid-template-columns: 33px 271px auto;
    width: 500px;
    height: 62px;
    padding: 9px 0;
    background-color: $athens-gray;
    border-radius: 4px;
  }

  &-text {
    font-size: 14px;
    line-height: 22px;
    color: $ebony-clay;
  }

  &-icon {
    position: relative;
    top: -4px;
    left: -18px;
  }

  &-button-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 16px;
  }
}

.criteria-multi-selector__feedback {
  font-size: 14px;
  line-height: 22px;
}

.criteria-multi-selector__modal-wrapper :deep() {
  .el-dialog__body {
    padding-bottom: 37px;
  }

  .el-dialog__footer {
    padding: 16px 30px 15px;
  }

  .action-modal__footer {
    text-align: end;
  }
}

.criteria-multi-selector__modal-description {
  margin-bottom: 24px;
}
</style>
