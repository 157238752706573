<template>
  <div class="initiative-details page-full-height">
    <PxTabBar v-if="$screen.smDown" />
    <PxNavbar
      v-else
      :show-account-dropdown="$user.isLogged()"
      :show-login-button="false"
    />
    <div class="initiative-details__header">
      <div class="initiative-details__header-wrapper">
        <PxButton
          class="initiative-details__back-button"
          v-bind="$options.static.backButton"
          @click="goToPreviousPage"
        />
        <div class="initiative-details__header-title">
          <h3>
            {{ $t(initiativeCopy.title) }}
          </h3>
          <p>
            {{ $t(initiativeCopy.subtitle) }}
          </p>
        </div>
        <div
          v-if="showAddTeamMembersCta"
          class="initiative-details__header-actions"
        >
          <PxButton
            data-testid="initiative-details-header-cta"
            size="medium"
            type="green"
            @click="goToPreviousPage"
          >
            {{ $t(initiativeCopy.cta) }}
          </PxButton>
          <p>
            {{ $t(initiativeCopy.note) }}
          </p>
        </div>
      </div>
    </div>
    <div class="initiative-details__content">
      <p>
        {{ $t(initiativeCopy.content.text[0]) }}
      </p>
      <p>
        {{ $t(initiativeCopy.content.text[1]) }}
      </p>
      <p>
        {{ $t(initiativeCopy.content.text[2]) }}
      </p>
      <ul>
        <li v-html="initiativeCopy.content.firstList[0]"></li>
        <li v-html="initiativeCopy.content.firstList[1]"></li>
      </ul>
      <p v-html="initiativeCopy.content.text[3]"></p>
      <p v-html="initiativeCopy.content.text[4]"></p>
      <p v-html="initiativeCopy.content.text[5]"></p>
    </div>
    <PxFooter v-if="$screen.mdUp" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import {
  EPxButtonSize,
  EPxButtonVariant,
} from "@/components/px-button/px-button.types";

export default defineComponent({
  name: "InitiativeDetails",

  static: {
    backButton: {
      icon: "arrow--left-dark",
      size: EPxButtonSize.EXTRA_SMALL,
      variant: EPxButtonVariant.COMPACT,
    },
  },

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      (vm.$data as any).previousRoute = from.path;
    });
  },

  data() {
    return {
      previousRoute: "",
    };
  },

  computed: {
    initiativeCopy() {
      return this.$tm("teamManagement.initiativePage") as {
        title: string;
        subtitle: string;
        cta: string;
        note: string;
        content: {
          text: string[];
          firstList: string[];
        };
      };
    },

    showAddTeamMembersCta(): boolean {
      return this.previousRoute.includes("program");
    },
  },

  methods: {
    goToPreviousPage() {
      return this.$router.back();
    },
  },
});
</script>

<style lang="scss" scoped>
.initiative-details {
  &__header {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 45px 0 23px;
    background: $white;
    border-bottom: 1px solid $turkish-angora;
  }

  &__header-wrapper {
    width: 100%;
    max-width: 1184px;
    display: grid;
    grid-template-columns: 32px auto 175px;
  }

  &__header-title {
    margin-bottom: 23px;
    margin-left: 40px;

    p {
      font-size: 20px;
      color: $ebony-clay;
      line-height: 22px;
      letter-spacing: -0.5px;
    }

    h3 {
      margin-bottom: 22px;
      font-size: 30px;
      font-weight: bold;
      color: $ebony-clay;
      line-height: 30px;
      letter-spacing: -0.2px;
    }
  }

  &__header-actions {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    p {
      font-size: 16px;
      color: #000;
      letter-spacing: -0.2px;
    }

    .el-button {
      height: 40px;
      min-width: 175px;
      padding: 0 16px;
      margin-bottom: 8px;
    }
  }

  &__content {
    max-width: 1108px;
    margin: 0 auto;
    margin-bottom: 50px;

    p {
      letter-spacing: -0.47px;
    }

    p,
    li {
      font-size: 20px;

      line-height: 1.5;
    }

    p:first-of-type {
      margin-top: 45px;
    }

    p:nth-child(2),
    p:nth-child(5),
    p:nth-child(6) {
      margin: 29px 0;
    }

    ul {
      margin-top: 8px;
      padding: 0 0 0 38px;
    }

    li {
      padding-bottom: 26px;
      padding-left: 9px;
      letter-spacing: -0.23px;
    }

    ::marker {
      font-size: 24px;
    }

    b {
      letter-spacing: -0.11px;
    }
  }

  &__back-button {
    position: relative;
    bottom: 2px;
    border-radius: 50%;

    .px-icon {
      position: relative;
      left: -1px;
    }
  }
}
</style>
