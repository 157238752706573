import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-3da28677"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "supporters-view__container" }
const _hoisted_2 = { class: "supporter-registration-criteria__container" }
const _hoisted_3 = { class: "supporter-registration-criteria__step-wrapper" }
const _hoisted_4 = { class: "supporter-registration-criteria__step-number" }
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = { class: "supporter-registration-criteria__cta" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PxButton = _resolveComponent("PxButton")!
  const _component_FormPresentationLayout = _resolveComponent("FormPresentationLayout")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_FormPresentationLayout, {
      "content-title": _ctx.viewCopy.contentTitle,
      "description-image-link": _ctx.viewCopy.imageLink,
      "description-image-title": _ctx.viewCopy.imageTitle,
      "has-description-background-image": true,
      "has-return-button": _ctx.hasPreviousPage,
      onPreviousPage: _ctx.onReturnButtonClick
    }, {
      content: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.viewCopy.steps, (step, key) => {
              return (_openBlock(), _createElementBlock("div", {
                key: `criteria-description-step--${key}`,
                class: "supporter-registration-criteria__step-container"
              }, [
                _createElementVNode("div", _hoisted_4, _toDisplayString(key + 1), 1),
                _createElementVNode("div", {
                  class: "supporter-registration-criteria__step-text",
                  innerHTML: step
                }, null, 8, _hoisted_5)
              ]))
            }), 128))
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createVNode(_component_PxButton, {
              class: "supporter-registration-criteria__main-cta",
              type: "primary",
              onClick: _ctx.onNextButtonClick
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.ctaCopy.setupCriteria), 1)
              ]),
              _: 1
            }, 8, ["onClick"]),
            (_ctx.skipEnabled)
              ? (_openBlock(), _createBlock(_component_PxButton, {
                  key: 0,
                  type: "link",
                  onClick: _ctx.onSkipAllButtonClick
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.ctaCopy.setupCriteriaLater), 1)
                  ]),
                  _: 1
                }, 8, ["onClick"]))
              : _createCommentVNode("", true)
          ])
        ])
      ]),
      _: 1
    }, 8, ["content-title", "description-image-link", "description-image-title", "has-return-button", "onPreviousPage"])
  ]))
}