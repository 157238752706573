import {
  GenericProvider,
  Provider,
  EProviderFeatures,
} from "@/services/data/generic-provider";
import { baseAPIUrl } from "@/services/utils/utils";
import { IMilestone } from "@/modules/milestone-planner/services/data/milestones/milestone.interface";

@Provider(`${baseAPIUrl}/milestones`, [
  EProviderFeatures.LIST,
  EProviderFeatures.CREATE,
  EProviderFeatures.GET,
  EProviderFeatures.PATCH,
  EProviderFeatures.DESTROY,
])
class MilestonesProvider extends GenericProvider<IMilestone> {}

export const milestonesProvider = new MilestonesProvider();
