import { ICompany } from "@/modules/profile/services/data/company/company.types";
import { ITeamMember } from "@/modules/team-management/services/data/team-members/team-members.interface";
import { IMatchingResponse } from "@/services/data/matching-responses/matching-response.interface";
import { INetwork } from "@/services/data/network/network.interface";
import { IQuestionBundle } from "@/services/data/question-bundle/question-bundle.interface";
import { ICategoryLevelSelection } from "@/services/data/viral-level/viral-level.interface";

/**
 * This interface represents an affiliate.
 */
export interface IAffiliate {
  id?: number;
  name: string;
  shortcode: string;
  email: string;
  website: string;
  logo: string;
  spreadsheet: string;
  company: ICompany;
  flow_type: number;
  flow_target: number;
  default_flow: boolean;
  slug: string;
  supporters: number[];
  networks: INetwork[];
  webhooks: number[];

  question_bundles?: Array<IQuestionBundle>;
  show_team_section: boolean;

  summary: string;
  disclaimer_heading: string;
  disclaimer_body: string;
  self_assessment_step_description: string;
  self_assessment_step_note: string;
  questions_step_description: string;
  questions_step_note: string;
  team_members_step_description: string;
  team_members_step_note: string;
}

export interface IAffiliateDraft {
  id?: number;
  affiliate_id: number | null;
  created_at: string | null;
  updated_at: string | null;
  data: {
    assessment: Array<ICategoryLevelSelection>;
    responses: Array<IMatchingResponse>;
    teamMembers: Array<ITeamMember>;
  };
}

export const AFFILIATE_TYPE_SELF_ASSESSMENT = 0;
export const AFFILIATE_TYPE_PROGRAM = 1;
export const AFFILIATE_TARGET_ENTREPRENEUR = 0;
export const AFFILIATE_TARGET_SUPPORTER = 1;
