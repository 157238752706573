<template>
  <div class="share-modal-actions">
    <h3 class="share-modal-actions__title" v-text="actionsCopy.title" />
    <i18n-t
      :keypath="`${copy}.subtitle.content[${isRestrictedByPasscode}]`"
      class="share-modal-actions__subtitle"
      tag="p"
    >
      <template #link>
        <strong v-text="actionsCopy.subtitle.placeholders[0]" />
      </template>
      <template #name>
        <strong
          class="text--capitalize"
          v-text="actionsCopy.subtitle.placeholders[1]"
        />
      </template>
      <template #email>
        <strong v-text="actionsCopy.subtitle.placeholders[2]" />
      </template>
      <template #password>
        <strong v-text="actionsCopy.subtitle.placeholders[3]" />
      </template>
    </i18n-t>
    <PxButton
      class="share-modal-actions__link"
      size="small"
      type="link"
      @click="onClickSettingHandler"
    >
      {{ $t(settingsLinkCopy) }}
    </PxButton>
    <div class="share-modal-actions__buttons">
      <ClipboardButton
        v-if="isRestrictedByPasscode"
        v-bind="clipboardButtons[0]"
      />
      <ClipboardButton v-bind="clipboardButtons[1]" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import ClipboardButton from "@/components/clipboard-button/clipboard-button.vue";

export default defineComponent({
  name: "ShareModalActions",

  components: {
    ClipboardButton,
  },

  props: {
    link: {
      type: String,
      required: true,
    },

    passcode: {
      type: String,
      required: true,
    },

    actionsLabel: {
      type: Array as () => string[],
      default: () => ["Copy Passcode", "Copy Link"],
    },

    settingsLinkCopy: {
      type: String,
      default: "common.linkSettings",
    },

    copyLinkButtonCopy: {
      type: String,
      default: "common.copyLink",
    },

    passcodeButtonCopy: {
      type: String,
      default: "common.copyPasscode",
    },

    copy: {
      type: String,
      default: "milestonePlanner.shareModal.footer",
    },
  },

  computed: {
    isRestrictedByPasscode(): number {
      // Convert passcode length to a numeric boolean (empty = 0 / not-empty = 1)
      return +!!this.passcode.length;
    },

    actionsCopy() {
      return this.$tm(this.copy) as {
        title: string;
        subtitle: {
          content: string[];
          placeholders: string[];
        };
      };
    },

    clipboardButtons() {
      return [
        {
          clipboard: this.passcode,
          buttonLabel: this.$t(this.passcodeButtonCopy),
          tooltipCopy: this.$t("common.copiedToClipboard"),
          buttonIcon: "password-lock",
        },
        {
          clipboard: this.link,
          buttonLabel: this.$t("common.copyLink"),
          tooltipCopy: this.$t("common.copiedToClipboard"),
        },
      ];
    },
  },

  methods: {
    onClickSettingHandler() {
      this.$emitter.emit("toggle-settings", true);
    },
  },
});
</script>

<style lang="scss" scoped>
.share-modal-actions {
  display: grid;
  grid-template-areas:
    "title link"
    "subtitle buttons";
  grid-template-rows: auto auto;
  grid-template-columns: 49% max-content;
  align-items: flex-end;
  justify-content: space-between;

  &__title {
    @include grotesk(semiBold);

    grid-area: title;
    margin-bottom: 5px;
    font-size: 12px;
    color: $gun-powder;
    text-transform: uppercase;
  }

  &__subtitle {
    grid-area: subtitle;
    margin-bottom: 0;
    font-size: 14px;
    line-height: 20px;
    color: $manatee;
    letter-spacing: -0.25px;
    white-space: pre-wrap;
  }

  &__link {
    top: 1px;
    right: 1px;
    grid-area: link;
    justify-self: end;
  }

  &__buttons {
    grid-area: buttons;
    display: flex;
    gap: 16px;

    :deep(.el-button) {
      padding: 0 13px 0 12px;
    }

    :deep(.el-button span) {
      font-size: 12px;
    }
  }
}
</style>
