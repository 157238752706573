<template>
  <div class="affiliate-review-page-self-assessment">
    <h3
      v-if="$screen.xlUp"
      class="affiliate-review-page-self-assessment__title"
    >
      {{ $t("affiliateProgram.components.flowNavbar.assessmentTabTitle") }}
    </h3>
    <div
      :class="{
        'assessment-incomplete': assessmentIncomplete,
      }"
      class="affiliate-review-page-self-assessment__wrapper"
    >
      <AssessmentResume
        v-if="!assessmentIncomplete && levelDescription"
        :current-level="viralLevel"
        :description="levelDescription.description"
        :icon-size="100"
        :show-label="false"
        :title="levelDescription.title"
        data-testid="affiliate-review-page-self-assessment-resume"
      />
      <h3 v-else>
        {{ $t("affiliates.reviewPage.content.missingAssessment") }}
      </h3>
      <PxButton
        v-bind="updateButtonProps"
        data-testid="affiliate-review-page-self-assessment-update-button"
        @click="$emit('update')"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import AssessmentResume from "@/modules/program/components/assessment-resume/assessment-resume.vue";

import { EPxButtonType } from "@/components/px-button/px-button.types";
import { ILevel } from "@/services/data/level/level.interface";

export default defineComponent({
  name: "AffiliateReviewPageSelfAssessment",

  components: {
    AssessmentResume,
  },

  static: {
    updateButton: {
      type: EPxButtonType.DEFAULT,
    },
  },

  props: {
    viralLevel: {
      type: Number,
      required: true,
    },

    levelDescription: {
      type: Object as () => ILevel | null,
      default: null,
    },

    assessmentIncomplete: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    updateButtonProps(): any {
      return {
        ...this.$options.static.updateButton,
        label: this.$t("common.update"),
      };
    },
  },
});
</script>

<style lang="scss" scoped>
.affiliate-review-page-self-assessment {
  padding: 0 22px 22px 23px;

  &__title {
    margin: 0;
    padding: 22px 0 0;

    @include grotesk(bold);

    font-size: 20px;
    line-height: 1.6;
    color: $ebony-clay;
    letter-spacing: -0.2px;
  }

  &__wrapper {
    display: flex;

    border-radius: 4px;
    border: solid 1px rgba(220, 221, 226, 0.8);
    background-color: $alabaster;

    @include breakpoint-down(lg) {
      flex-direction: column;
      gap: 20px;
    }

    h3 {
      margin: 0;

      @include grotesk(semiBold);

      font-size: 16px;
    }

    :deep() .validating-panel__container {
      padding-top: 6px;
      grid-template-columns: 100px auto;
      grid-column-gap: 23px;
      grid-row-gap: 0;
    }

    :deep() .validating-panel__score-wrapper {
      justify-content: flex-start;
    }

    :deep() .validating-panel__title-wrapper {
      padding-top: 8px;
      margin-bottom: 12px;
    }

    :deep() .validating-panel__title {
      margin-right: 15px;
      font-size: 20px;
      line-height: 1.5;
    }

    :deep() .validating-panel__description p {
      font-size: 14px;
      line-height: 1.43;
      letter-spacing: -0.3px;
    }

    :deep() .validating-panel__tag-wrapper span {
      padding: 2px 7px;
    }

    :deep() .px-button {
      height: 32px;
      min-width: 72px;
      padding: 0 5px;
    }

    :deep() .px-button span {
      font-size: 13px;
    }
  }

  &__wrapper.assessment-incomplete {
    justify-content: space-between;
    align-items: center;
    padding: 15px 24px;
  }

  &__wrapper:not(.assessment-incomplete) {
    padding: 14px 24px 28px 20px;
  }
}
</style>
