<template>
  <div class="level-update-modal-levels-info">
    <PxModalMessage :visible="hasServerError" />
    <div>
      <h1 class="level-update-modal-levels-info__title">
        {{ modalTitle }}
      </h1>
    </div>
    <div class="level-update-modal-levels-info__wrapper">
      <div class="level-update-modal-levels-info__main-content">
        <div
          v-if="currentLevel && newLevel"
          class="level-update-modal-levels-info__cards-wrapper"
        >
          <LevelUpdateCard
            :current-assessment="currentAssessment"
            :level="currentLevel.value"
            :level-description="currentLevel.title"
            :only-prev-assessment="true"
            :prev-assessment="formattedPrevAssessment"
            :type-of-update="typeOfUpdate"
            class="level-update-modal-levels-info__current-card"
          />
          <LevelUpdateCard
            :current-assessment="currentAssessment"
            :level="newLevel.value"
            :level-description="newLevel.title"
            :prev-assessment="formattedPrevAssessment"
            :type-of-update="typeOfUpdate"
            class="level-update-modal-levels-info__new-card"
            type="blue"
          />
        </div>
      </div>
      <LevelUpdateModalNote :loading="isLoading" />
    </div>
    <div class="level-update-modal-levels-info__footer">
      <LevelUpdateModalFooter
        v-model:has-error="hasServerError"
        v-model:loading="isLoading"
        :current-assessment="currentAssessment"
        :current-level="currentLevel.value"
        :new-level="newLevel.value"
        :same-level="isSameLevel"
        @close-modal="$emit('close-modal')"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import LevelUpdateCard from "@/modules/common/components/level-update-modal/level-update-card.vue";
import LevelUpdateModalNote from "@/modules/common/components/level-update-modal/level-update-modal-note.vue";
import LevelUpdateModalFooter from "@/modules/common/components/level-update-modal/level-update-modal-footer.vue";

import { ILevel } from "@/services/data/level/level.interface";
import { IAssessmentLevel } from "@/services/data/assessment/assessment.interface";

import { TranslateResult } from "vue-i18n";

export default defineComponent({
  name: "LevelUpdateModalLevelsInfo",

  components: {
    LevelUpdateCard,
    LevelUpdateModalNote,
    LevelUpdateModalFooter,
  },

  props: {
    currentLevel: {
      type: Object as () => ILevel,
      required: true,
    },
    newLevel: {
      type: Object as () => ILevel,
      required: true,
    },
  },

  data() {
    return {
      hasServerError: false,
      isLoading: false,
    };
  },

  computed: {
    modalTitle(): TranslateResult {
      return this.isSameLevel
        ? this.$t("common.components.levelUpdateModal.sameLevelTitle")
        : this.$t("common.components.levelUpdateModal.newLevelTitle", {
            current: this.currentLevel?.value,
            new: this.newLevel.value,
          });
    },

    prevAssessment(): Array<IAssessmentLevel> | null {
      return this.$store.get("auth/latestAssessment.data.data");
    },

    formattedPrevAssessment(): Array<IAssessmentLevel> | null {
      return this.prevAssessment
        ? this.prevAssessment.map((assessment: IAssessmentLevel) => ({
            ...assessment,
            level: assessment.level || 0,
          }))
        : null;
    },

    currentAssessment(): Array<IAssessmentLevel> {
      return this.$store.get("viralLevel.levels");
    },

    isSameLevel(): boolean {
      return this.currentLevel?.value === this.newLevel.value;
    },

    isLevelUp(): boolean {
      return this.currentLevel.value < this.newLevel.value;
    },

    typeOfUpdate(): TranslateResult {
      return this.isSameLevel
        ? this.$t("common.components.levelUpdateModal.typeOfUpdate.sameLevel")
        : this.isLevelUp
          ? this.$t("common.components.levelUpdateModal.typeOfUpdate.levelUp")
          : this.$t(
              "common.components.levelUpdateModal.typeOfUpdate.levelDown",
            );
    },
  },
});
</script>

<style lang="scss">
.level-update-modal-levels-info {
  :deep() .px-modal-message {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }

  &__wrapper {
    max-height: 461px;
    padding: 0;
    overflow-y: auto;
    line-height: normal;
  }

  &__title {
    padding: 0 90px;
    margin-bottom: 22px;
    font-size: 20px;
    line-height: 29px;
    color: $ebony-clay;
    text-align: center;
  }

  &__main-content {
    display: flex;
    justify-content: center;
    padding-bottom: 7px;
  }

  &__content-wrapper {
    padding: 0 15px;
  }

  &__cards-wrapper {
    padding: 19px 25px;

    @include breakpoint-up(sm) {
      display: flex;
      align-items: center;
      max-width: 500px;
      padding-right: 0;
      padding-bottom: 26px;
      padding-left: 0;
      margin: 0 auto;
    }
  }

  &__current-card {
    margin-bottom: 12px;

    @include breakpoint-up(sm) {
      margin: 0 20px 0 0;
    }
  }

  .px-panel.level-update-modal-levels-info__current-card::after {
    position: absolute;
    bottom: -28px;
    left: 50%;
    display: block;
    width: 40px;
    height: 45px;

    content: "";

    background: url("/img/blue-arrow.svg");
    border: none;

    opacity: 1;

    transform: translateX(-50%);

    @include breakpoint-up(sm) {
      top: 51%;
      right: -38px;
      bottom: auto;
      left: auto;
      width: 54px;
      height: 34px;

      background: url("/img/blue-arrow--right.svg") no-repeat;

      transform: translateY(-50%);
    }
  }
}
</style>
