<template>
  <div class="affiliate-review-page-team-members-details">
    <div
      :style="{
        'border-bottom':
          questions && questions.length ? '1px solid #f0f1f5' : 'none',
      }"
      class="affiliate-review-page-team-members-details__top"
    >
      <div class="affiliate-review-page-team-members-details__wrapper">
        <div class="affiliate-review-page-team-members-details__headers">
          <h3>
            {{ $t("teamManagement.settings.mandatoryData.firstName") }}
          </h3>
          <h3>
            {{ $t("teamManagement.settings.mandatoryData.lastName") }}
          </h3>
          <h3>
            {{ $t("teamManagement.settings.mandatoryData.position") }}
          </h3>
          <h3>
            {{ $t("common.email") }}
          </h3>
        </div>
        <div class="affiliate-review-page-team-members-details__info">
          <p
            :class="{
              'affiliate-review-page-team-members-details__missing-info':
                !teamMember.first_name,
            }"
            :title="firstName"
            class="affiliate-review-page-team-members-details__info-content"
          >
            {{ firstName }}
          </p>
          <p
            :class="{
              'affiliate-review-page-team-members-details__missing-info':
                !teamMember.last_name,
            }"
            :title="lastName"
            class="affiliate-review-page-team-members-details__info-content"
          >
            {{ lastName }}
          </p>
          <p
            :class="{
              'affiliate-review-page-team-members-details__missing-info':
                !teamMember.position,
            }"
            :title="position"
            class="affiliate-review-page-team-members-details__info-content"
          >
            {{ position }}
          </p>
          <p
            :class="{
              'affiliate-review-page-team-members-details__missing-info':
                !teamMember.email,
            }"
            :title="email"
            class="affiliate-review-page-team-members-details__info-content"
          >
            {{ email }}
          </p>
        </div>
      </div>
      <PxStatusBadge
        v-if="label"
        :background-color="label.backgroundColor"
        :icon="label.icon"
        :icon-size="label.iconSize"
        :label="label.text"
        :label-color="label.textColor"
        :label-size="13"
        :uppercase="false"
        class="affiliate-review-page-team-members-details__label"
        icon-position="left"
      />
    </div>
    <div class="affiliate-review-page-team-members-details__bottom">
      <div
        v-if="questions && questions.length"
        class="affiliate-review-page-team-members-details__questions-wrapper"
      >
        <div
          v-for="question in questions"
          :key="question.id"
          class="affiliate-review-page-team-members-details__question"
        >
          <h3 :title="question.entrepreneur_question">
            {{ question.entrepreneur_question }}
          </h3>
          <p
            :title="formattedResponse(question)"
            v-html="formattedResponse(question)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import FormatMatchingResponseValuesMixin from "@/mixins/format-matching-response-values.mixin";

import PxStatusBadge from "@/components/px-status-badge/px-status-badge.vue";

import { ITeamMember } from "@/modules/team-management/services/data/team-members/team-members.interface";
import { IComponentLabel } from "@/modules/affiliates/components/common.interface";
import { TranslateResult } from "vue-i18n";

import {
  IMatchingQuestion,
  FREE_RESPONSE,
  SINGLE_SELECT,
  MULTI_SELECT,
  NUMERIC,
  RANGE,
  DATE,
} from "@/services/data/matching-questionary/matching-question.interface";
import { IAffiliatesState } from "@/modules/affiliates/services/store/affiliates/affiliates.types";
import { IMatchingResponse } from "@/services/data/matching-responses/matching-response.interface";

export default defineComponent({
  name: "AffiliateReviewPageTeamMembersDetails",

  components: {
    PxStatusBadge,
  },

  mixins: [FormatMatchingResponseValuesMixin],

  props: {
    teamMember: {
      type: Object as () => ITeamMember,
      required: true,
    },

    label: {
      type: Object as () => IComponentLabel,
      default: null,
    },
  },

  data() {
    return {
      responseTypes: {
        freeResponse: FREE_RESPONSE,
        singleSelect: SINGLE_SELECT,
        multiSelect: MULTI_SELECT,
        numeric: NUMERIC,
        range: RANGE,
        dateResponse: DATE,
      } as any | null,
    };
  },

  computed: {
    firstName(): TranslateResult {
      return (
        this.teamMember.first_name ||
        this.$t("affiliates.reviewPage.content.notAnswered")
      );
    },

    lastName(): TranslateResult {
      return (
        this.teamMember.last_name ||
        this.$t("affiliates.reviewPage.content.notAnswered")
      );
    },

    email(): TranslateResult {
      return (
        this.teamMember.email ||
        this.$t("affiliates.reviewPage.content.notAnswered")
      );
    },

    position(): TranslateResult {
      return (
        this.teamMember.position ||
        this.$t("affiliates.reviewPage.content.notAnswered")
      );
    },

    affiliatesData(): IAffiliatesState {
      return this.$store.state.affiliates;
    },

    questions(): Array<IMatchingQuestion> {
      return this.affiliatesData.teamMembersQuestionBundle || [];
    },
  },

  methods: {
    formattedResponse(question: IMatchingQuestion): string {
      let formattedValue = "";

      const response = (this.teamMember.responses as IMatchingResponse[]).find(
        (response) => response.question === question.id,
      );

      if (response) {
        if (
          (this.getQuestionType(question, this.responseTypes.numeric) &&
            this.$user.isEntrepreneur()) ||
          (this.getQuestionType(question, this.responseTypes.range) &&
            this.$user.isSupporter())
        ) {
          formattedValue = this.formatNumericValue(response);
        } else if (
          (this.getQuestionType(question, this.responseTypes.numeric) &&
            this.$user.isSupporter()) ||
          (this.getQuestionType(question, this.responseTypes.range) &&
            this.$user.isEntrepreneur())
        ) {
          formattedValue = this.formatRangeValue(response);
        } else if (
          this.getQuestionType(question, this.responseTypes.freeResponse)
        ) {
          formattedValue = this.formatFreeResponseValue(response);
        } else if (
          this.getQuestionType(question, this.responseTypes.dateResponse)
        ) {
          formattedValue = this.formatDateValue(response);
        } else if (
          this.getQuestionType(question, this.responseTypes.singleSelect) ||
          this.getQuestionType(question, this.responseTypes.multiSelect)
        ) {
          formattedValue =
            response?.answers && response?.answers.length
              ? this.formatMultipleAnswersValue(question, response)
              : "";
        }
      }

      return (
        formattedValue ||
        `<i>${this.$t("affiliates.reviewPage.content.notAnswered")}</i>`
      );
    },

    getQuestionType(question: IMatchingQuestion, type: string): boolean {
      return question.question_type && question.question_type.type === type;
    },
  },
});
</script>

<style lang="scss" scoped>
.affiliate-review-page-team-members-details {
  padding: 16px 8px 32px 9px;

  &__top {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 19px;
  }

  &__wrapper {
    padding-top: 3px;
  }

  &__headers {
    margin-bottom: 3px;
  }

  h3 {
    margin: 0;
    @include grotesk(semiBold);

    font-size: 17px;
    line-height: 1.88;
    color: $ebony-clay;
  }

  h3::first-letter {
    text-transform: uppercase;
  }

  &__info,
  &__headers {
    display: grid;
    gap: 16px;
    grid-template-columns: repeat(4, 120px);

    h3,
    p {
      margin: 0;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  &__info-content {
    font-size: 15px;
    line-height: 1.47;
    color: $manatee;
    letter-spacing: -0.3px;
  }

  &__missing-info {
    color: $malibu;
    font-style: italic;
    letter-spacing: -0.2px;
  }

  &__label {
    height: 24px;
    max-width: max-content;
    gap: 3px;
    margin-top: 3px;
    padding: 0 2px 0 4px;
  }

  &__question {
    padding: 19px 0;

    &:not(:last-of-type) {
      border-bottom: 1px solid $athens-gray;
    }

    p {
      letter-spacing: -0.3px;
      overflow-wrap: anywhere;
    }
  }
}
</style>
