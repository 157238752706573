<template>
  <div class="company-lists">
    <template v-if="isLoading">
      <CompanyListsItemPlaceholder
        v-for="placeholder in defaultLoadingPlaceholders"
        :key="`virtual-list-placeholder-${placeholder}`"
        data-testid="company-lists-items-loading"
      />
    </template>
    <template v-if="hasListItems && !isLoading">
      <CompanyListsItem
        v-for="list in listsOrdered"
        :key="`list-item--${list.uid}`"
        :show-pin-to-top="isOwnedList"
        :value="list"
        data-testid="company-lists-items"
        @pinned-item="pinnedListHandler"
      />
    </template>
    <CompanyListsEmpty
      v-if="showEmptyState"
      :can-create-lists="isOwnedList && !emptyListsTitle"
      :icon="emptyListsIcon"
      :subtitle="emptyListsSubtitle"
      :title="emptyListsTitle"
      data-testid="company-lists-empty-state"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import cloneDeep from "lodash/cloneDeep";

import { COMPANY_LIST_TYPES } from "@/modules/company-lists/constants";
import { ICompanyList } from "@/modules/company-lists/services/data/company-list/company-list.interface";

import CompanyListsItem from "@/modules/company-lists/components/company-lists-item/company-lists-item.vue";
import CompanyListsEmpty from "@/modules/company-lists/components/company-lists/company-lists-empty.vue";
import { CompanyListState } from "@/modules/company-lists/services/store/company-list/company-list.modules";
import { AxiosError } from "axios";
import CompanyListsItemPlaceholder from "@/modules/company-lists/components/company-lists-item/company-lists-item-placeholder.vue";

export default defineComponent({
  components: {
    CompanyListsItem,
    CompanyListsItemPlaceholder,
    CompanyListsEmpty,
  },

  props: {
    type: {
      type: String,
      required: true,
      validator(type: string) {
        return Object.values(COMPANY_LIST_TYPES).includes(type);
      },
    },

    lists: {
      type: Array as () => Array<ICompanyList>,
      default: () => [],
    },

    emptyListsTitle: {
      type: String,
      required: true,
    },

    emptyListsSubtitle: {
      type: String,
      required: true,
    },

    emptyListsIcon: {
      type: String,
      default: "lists-empty-state",
    },

    isLoading: {
      type: Boolean,
      default: false,
    },

    hasFilters: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      companyLists: [] as Array<ICompanyList>,
      defaultLoadingPlaceholders: 5,
    };
  },

  computed: {
    isOwnedList(): boolean {
      return this.type === COMPANY_LIST_TYPES.OWNED;
    },

    listsOrdered(): Array<ICompanyList> {
      // Ordered by pinned and latest updated:
      return [...this.companyLists].sort((listA, listB) => {
        if (listA.pinned && !listB.pinned) {
          return -1;
        } else if (!listA.pinned && listB.pinned) {
          return 1;
        } else if (listA.is_smart_list && !listB.is_smart_list) {
          return -1;
        } else if (!listA.is_smart_list && listB.is_smart_list) {
          return 1;
        } else if (listA.updated_at > listB.updated_at) {
          return -1;
        } else if (listA.updated_at < listB.updated_at) {
          return 1;
        }

        return 0;
      });
    },

    listErrors(): AxiosError {
      return this.$store.get(CompanyListState.Getter.ERROR);
    },

    hasListItems(): boolean {
      return !!this.lists.length;
    },

    onlySmartListsCreated(): boolean {
      return (
        !!this.lists.length &&
        !this.lists.some((list: ICompanyList) => list.is_smart_list !== true)
      );
    },

    showEmptyState(): boolean {
      return (
        (!this.hasListItems && !this.isLoading) ||
        (this.hasListItems &&
          this.type === COMPANY_LIST_TYPES.OWNED &&
          this.onlySmartListsCreated &&
          !this.isLoading &&
          !this.hasFilters)
      );
    },
  },

  watch: {
    lists: {
      deep: true,
      immediate: true,
      handler(newLists) {
        this.companyLists = cloneDeep(newLists);
      },
    },
  },

  methods: {
    pinList(listToPin: ICompanyList) {
      listToPin.pinned = !listToPin.pinned;

      this.$store
        .dispatch(CompanyListState.Action.PATCH_VALUE, {
          uid: listToPin.uid,
          payload: {
            pinned: listToPin.pinned,
          },
        })
        .then(() => {
          if (!this.listErrors) {
            return;
          }

          // Revert pin
          listToPin.pinned = !listToPin.pinned;

          // Show generic error message
          this.$message({
            message: this.$t("common.errors.global.alertTitle"),
            type: "error",
            customClass: "is-full",
          });
        });
    },

    pinnedListHandler(pinnedList: ICompanyList) {
      const listToPin = this.companyLists.find(
        (list) => list.uid === pinnedList.uid,
      );

      if (!listToPin) {
        return;
      }

      this.pinList(listToPin);
    },
  },
});
</script>

<style lang="scss" scoped>
.company-lists {
  width: 100%;
  padding: 42px 0 32px;
  display: flex;
  flex-direction: column;
  justify-items: center;
  grid-row-gap: 24px;

  &.is-loading {
    height: 429px;
    margin-top: 1px;
    overflow: hidden;
  }
}

.company-lists :deep() .company-lists-item {
  width: 100%;
}
</style>
