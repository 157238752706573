<template>
  <div class="visitors-guard">
    <PxNavbar
      v-if="$screen.mdUp"
      :items="activeNavBarItems"
      :show-account-dropdown="$user.isLogged()"
    />
    <main />
    <VisitorsAccessModal
      :requires-passcode="requiresPasscode"
      data-testid="visitors-guard-modal"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import VisitorsAccessModal from "@/modules/capital-explorer/components/visitors-access-modal/visitors-access-modal.vue";

import { IAuthState } from "@/modules/authentication/services/store/auth/auth-types";
import { INavBarItem } from "@/modules/profile/meta/entrepreneurs";
import { navBarItemsByFeaturesToggleOrPermissions } from "@/utils/navbar-items-enabled";

/**
 * This is used to store the page element to add blur effect while this component is active.
 */
let pageElement: HTMLDivElement | null = null;
const PAGE_ELEMENT_SELECTOR = ".capital-explorer-for-visitors";
const PAGE_BLUR_CLASS = "page-full-height--blur";

export default defineComponent({
  name: "VisitorsGuard",

  components: {
    VisitorsAccessModal,
  },

  props: {
    requiresPasscode: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    authState(): IAuthState {
      return this.$store.state.auth as IAuthState;
    },

    navBarItems(): Array<INavBarItem> {
      const company = this.$store.get("auth/company/data");
      const companyType = company !== null ? company.type : null;
      if (companyType !== null) {
        return this.$profile.getByType("navBarItems", companyType);
      }
      return this.$profile.get("navBarItems");
    },

    itemsByEnabledModulesOrFeatures(): Array<INavBarItem> {
      return navBarItemsByFeaturesToggleOrPermissions(this.navBarItems);
    },

    activeNavBarItems(): Array<INavBarItem> {
      return this.$user.isLogged() || this.authState.company?.data !== null
        ? this.itemsByEnabledModulesOrFeatures
        : [];
    },
  },

  mounted() {
    // get page element from dom and add a blur modifier
    pageElement = document.querySelector(PAGE_ELEMENT_SELECTOR);
    pageElement?.classList.add(PAGE_BLUR_CLASS);
  },

  beforeUnmount() {
    // remove the blur effect for the case of we move away form this page
    pageElement?.classList.remove(PAGE_BLUR_CLASS);
  },
});
</script>

<style lang="scss" scoped>
.visitors-guard {
  display: flex;
  flex-direction: column;
  flex: 1;

  main {
    flex: 1;

    background-image: url("#{$assetsPath}/img/illustrations/capital-explorer/access-background.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }
}
</style>
