<template>
  <div
    v-if="isRibbonVisible"
    :class="{
      'matching-ribbon--public__wrapper-outer': isPublicView,
      'matching-ribbon--slim__wrapper-outer': isSlimVersion,
      'matching-ribbon--no-match__wrapper-outer': !hasMatch,
    }"
    class="matching-ribbon__wrapper-outer"
  >
    <ContentPlaceholders v-if="isLoading" :rounded="true">
      <ContentPlaceholdersText
        :lines="1"
        class="matching-ribbon__placeholder"
      />
    </ContentPlaceholders>
    <div v-else class="matching-ribbon__wrapper-inner">
      <PxIcon
        v-if="!isSlimVersion"
        :size="18"
        class="matching-ribbon__icon"
        name="icon-matching"
      />
      <div class="matching-ribbon__content">
        <span class="matching-ribbon__title">
          {{ ribbonTitle }}
        </span>
        <span
          v-if="$screen.mdUp && !hasMatchScoreDetailsError"
          class="matching-ribbon__subtitle"
          @click="onRibbonClick"
        >
          {{ ribbonSubtitle }}
        </span>
      </div>
      <div class="matching-ribbon__percentage">
        <span class="matching-ribbon__percentage-text">
          {{ formattedMatchPercentage }}
        </span>
      </div>
      <MatchDetailsModal
        v-if="isInterestDetailModalVisible"
        v-model:visibility="isInterestDetailModalVisible"
        :company-logo="profileCompanyLogo"
        :company-name="profileCompanyName"
        :match-details="matchScoreDetails"
        data-testid="matching-ribbon-details-modal"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { ICompany } from "@/modules/profile/services/data/company/company.types";

import { ROUTE_AUTHENTICATION_SIGNUP } from "@/modules/authentication/services/router/routes-names";
import { EMatchingRibbonType } from "@/modules/profile/services/data/matching-ribbon/matching-ribbon.types";
import MatchDetailsModal from "@/components/match-details-modal/match-details-modal.vue";
import {
  EMatchScoreDetailsActions,
  IMatchScoreDetails,
} from "@/modules/matching/services/store/match-score-details/match-score-details.types";

export default defineComponent({
  name: "MatchingRibbon",

  components: {
    MatchDetailsModal,
  },

  props: {
    type: {
      type: Number as () => EMatchingRibbonType,
      default: EMatchingRibbonType.NORMAL,
    },
  },

  data() {
    return {
      isInterestDetailModalVisible: false,
      matchScoreDetails: {} as IMatchScoreDetails,
    };
  },

  computed: {
    isLoading(): boolean {
      return this.isProfileLoading || this.isMatchScoreDetailsLoading;
    },

    isProfileLoading(): boolean {
      return (
        this.$store.get("profile/loading") ||
        this.$store.get("profile/company/loading")
      );
    },

    isMatchScoreDetailsLoading(): boolean {
      return this.$store.get("matchScoreDetails/loading");
    },

    hasMatchScoreDetailsError(): boolean {
      return !!this.$store.get("matchScoreDetails/error");
    },

    isRibbonVisible(): boolean {
      return this.isPublicView || this.isMatchable;
    },

    isPublicView(): boolean {
      return !this.$user.isLogged();
    },

    isSlimVersion(): boolean {
      return this.type === EMatchingRibbonType.SLIM;
    },

    isMatchable(): boolean {
      return (
        !this.$user.isOwner() &&
        this.$profile.type() !== this.$user.getUserAccountType()
      );
    },

    profileCompany(): ICompany | null {
      return this.$profile.company();
    },

    profileCompanyUid(): string | null {
      return this.profileCompany?.uid || null;
    },

    profileCompanyName(): string {
      return this.profileCompany?.name || "";
    },

    profileCompanyLogo(): string {
      return this.profileCompany?.logo || "";
    },

    ribbonTitle(): string {
      return (
        this.isPublicView
          ? this.$t("profile.company.publicMatchedWith.title", {
              name: this.profileCompanyName,
            })
          : this.hasMatch
            ? this.$t("profile.company.matchedWith.title", {
                name: this.profileCompanyName,
              })
            : this.$t("profile.company.zeroMatched.title")
      ) as string;
    },

    matchScore(): number | null {
      return this.matchScoreDetails ? this.matchScoreDetails.score : null;
    },

    hasMatch(): boolean {
      return this.matchScore ? this.matchScore > 0 : false;
    },

    ribbonSubtitle(): string {
      return (
        this.isPublicView
          ? this.isSlimVersion
            ? this.$t("profile.company.publicMatchedWith.subtitleSlim")
            : this.$t("profile.company.publicMatchedWith.subtitle")
          : this.$t("profile.company.matchedWith.subtitle")
      ) as string;
    },

    formattedMatchPercentage(): string | null {
      if (this.isPublicView || this.matchScore === null) {
        return "?%";
      }

      if (this.matchScore) {
        return `${Math.trunc(this.matchScore)}%`;
      }

      return "0%";
    },
  },

  watch: {
    isProfileLoading: {
      immediate: true,
      async handler(isProfileLoading: boolean) {
        if (isProfileLoading) return;

        if (!this.isPublicView && this.isMatchable) {
          await this.getMatchScoreDetails();

          this.matchScoreDetails = this.$store.get("matchScoreDetails/data");
        }
      },
    },
  },

  beforeUnmount() {
    this.matchScoreDetails = {} as IMatchScoreDetails;
  },

  methods: {
    async getMatchScoreDetails() {
      await this.$store.dispatch(
        EMatchScoreDetailsActions.FETCH,
        this.profileCompanyUid,
      );
    },

    onRibbonClick(event: MouseEvent) {
      event.preventDefault();

      if (this.isPublicView) {
        // On public view, redirect to sign up
        return this.$router.push({
          name: ROUTE_AUTHENTICATION_SIGNUP,
          query: {
            fromAssessment: "true",
          },
        });
      }

      this.isInterestDetailModalVisible = true;
    },
  },
});
</script>

<style lang="scss" scoped>
.matching-ribbon__icon.px-icon {
  vertical-align: top;
  transform: translateY(3px);

  @include breakpoint-up(md) {
    display: none;
  }
}

.matching-ribbon__wrapper {
  &-outer {
    position: relative;
    min-height: 50px;
    padding: 0;
    overflow: hidden;
    background-color: $parakeetblue;
    border: solid 1px rgba(41, 114, 202, 0.6);

    @include breakpoint-up(md) {
      width: 457px;
      border-radius: 4px;
    }
  }

  &-inner {
    padding: 9px 16px;

    @include breakpoint-up(md) {
      padding: 10px 14px;
    }
  }
}

.matching-ribbon__content {
  width: 340px;

  @include breakpoint-down(sm) {
    display: inline-block;
    width: 204px;
    margin-left: 10px;
  }
}

.matching-ribbon__title {
  @include grotesk(semiBold);

  display: block;
  margin-right: 4px;
  font-size: 16px;
  color: $white;

  @include breakpoint-down(sm) {
    font-size: 15px;
    line-height: 1.5;
  }
}

.matching-ribbon__subtitle {
  @include grotesk(semiBold);

  display: inline-block;
  margin-top: 1px;
  font-size: 14px;
  color: $white;
  text-decoration: underline;
  cursor: pointer;
}

.matching-ribbon__percentage {
  position: absolute;
  top: -18px;
  right: -68px;
  bottom: -18px;
  display: flex;
  align-items: center;
  width: 138px;
  background-color: $black-10;
  border-radius: 50%;

  @include breakpoint-up(md) {
    top: -92px;
    right: -92px;
    bottom: -92px;
    width: 184px;
    border: solid 1px rgba(39, 39, 64, 0.08);
  }

  &-text {
    @include grotesk(bold);

    margin-left: 22px;
    font-size: 20px;
    color: $white;

    @include breakpoint-up(md) {
      margin-left: 28px;
      font-size: 22px;
    }
  }
}

.matching-ribbon__placeholder {
  width: 100%;
  height: 48px;

  :deep(.vue-content-placeholders-text__line) {
    width: 100%;
    height: 100%;
    background-color: $parakeetblue;

    &::before {
      background-image: linear-gradient(
        to right,
        transparent 0%,
        $dark-sky-blue 15%,
        transparent 30%
      );
    }
  }
}

.matching-ribbon__content-placeholder {
  width: 100%;
}

.matching-ribbon--public__wrapper-outer {
  box-shadow: 0 0 10px 0 rgba(95, 168, 255, 0.48);

  .matching-ribbon__percentage {
    @include breakpoint-down(md) {
      right: -78px;
    }
  }
}

.matching-ribbon--no-match__wrapper-outer:not(
    .matching-ribbon--public__wrapper-outer
  ) {
  background-color: $manatee;
  border: solid 1px rgba(39, 39, 64, 0.08);
}

.matching-ribbon--slim__wrapper-outer {
  border-radius: 4px;

  @include breakpoint-up(md) {
    width: 100%;
  }

  .matching-ribbon__wrapper-inner {
    padding: 3px;

    @include breakpoint-up(md) {
      padding: 3px 14px;
      border-radius: 4px;
    }
  }

  .matching-ribbon__title {
    font-size: 15px;
  }

  .matching-ribbon__title,
  .matching-ribbon__subtitle {
    display: inline;
  }

  .matching-ribbon__content {
    width: 100%;
    padding-right: 90px;

    @include breakpoint-up(md) {
      padding-right: 60px;
    }
  }

  .matching-ribbon__placeholder {
    height: 50px;
  }

  .matching-ribbon__percentage {
    @include breakpoint-up(md) {
      top: -31px;
      right: -62px;
      bottom: -31px;
      width: 124px;
    }

    &-text {
      @include breakpoint-up(md) {
        margin-left: 18px;
        font-size: 18px;
      }
    }
  }
}
</style>
