<template>
  <div class="process-reports-locked">
    <ChartLocked
      v-if="allStepsLocked"
      :content="copy"
      @click="privacyModalVisible = true"
    />
    <div v-else class="process-reports-locked__privacy-message">
      <PxIcon name="information--dark-blue" :size="16" />
      <div>
        <i18n-t :keypath="`chartsDashboard.lockedState.description[2]`" tag="p">
          <template #privacy>
            <PxButton
              class="el-button--link-blue el-button--small"
              type="link"
              @click.stop="privacyModalVisible = true"
            >
              {{ $t("chartsDashboard.lockedState.privacy") }}
            </PxButton>
          </template>
        </i18n-t>
        <p>{{ $t("chartsDashboard.lockedState.description[3]") }}</p>
      </div>
    </div>
    <ChartsLockedModal
      v-if="privacyModalVisible"
      v-model:visibility="privacyModalVisible"
      @navigate="goToInitiativePage"
    />
    <div v-if="allStepsLocked" class="process-reports-locked--overlay"></div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import ChartsLockedModal from "@/modules/charts-dashboard/components/common/charts-locked-modal/charts-locked-modal.vue";
import ChartLocked, {
  IChartLockedContent,
} from "@/modules/charts-dashboard/components/common/chart-locked/chart-locked.vue";

import { ROUTE_TEAM_MANAGEMENT_INITIATIVE_DETAILS } from "@/modules/team-management/services/router/routes-names";

export default defineComponent({
  name: "ProcessReportsLocked",

  components: {
    ChartsLockedModal,
    ChartLocked,
  },

  props: {
    allStepsLocked: {
      type: Boolean,
      required: true,
    },

    copy: {
      type: Object as () => IChartLockedContent,
      required: true,
    },
  },

  data() {
    return {
      privacyModalVisible: false,
    };
  },

  methods: {
    goToInitiativePage() {
      return this.$router.push({
        name: ROUTE_TEAM_MANAGEMENT_INITIATIVE_DETAILS,
      });
    },
  },
});
</script>

<style lang="scss" scoped>
.process-reports-locked {
  &__privacy-message {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: stretch;
    gap: 10px;
    padding: 16px 23px 17px;
    background-color: rgba(123, 183, 255, 0.1);

    .px-icon {
      position: relative;
      top: 4px;
    }

    p {
      @include grotesk(regular);
      margin: 0;

      font-size: to-rem(15px);
      line-height: 1.44;
      color: rgba($ebony-clay, 0.7);
      letter-spacing: -0.33px;
    }

    :deep() .el-button {
      height: auto;
      padding: 0;
    }
  }

  :deep() .chart-locked__wrapper {
    padding: 0 0 6px 49px;

    p {
      max-width: 680px;
    }
  }

  &--overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;

    background: rgba(255, 255, 255, 0.8);
    border-radius: 4px;
  }
}
</style>
