import { isDevelopment } from "./utils";

interface IHeapObject {
  name?: string;
  properties: { [key: string]: any };
  next?: Function;
}

const heapGuard = ({
  name,
  properties = {},
  next = () => null,
}: IHeapObject) => {
  if (!(window as any).heap) {
    return;
  }

  if (isDevelopment) {
    console.log(`HEAP: Event "${name}" sent with =>`, properties);
  }

  return next({ name, properties });
};

/**
 * Track an event using the Heap.
 *
 * @param name Event to send
 * @param properties Event properties to send along side with the event.
 */
export const heapTrack = (
  name: string,
  properties: { [key: string]: any } = {},
) =>
  heapGuard({
    name,
    properties,
    next: ({ name: _name, properties: _props }: IHeapObject) => {
      heap.track(_name, _props);
    },
  });

/**
 *
 * @param properties
 */
export const heapLogin = (properties: { [key: string]: any } = {}) =>
  heapGuard({
    name: "Heap Login",
    properties,
    next: ({ properties: _props }: IHeapObject) => {
      const { userId, ...userProps } = _props;

      heap.identify(userId);
      heap.addUserProperties(userProps);
    },
  });

export const heapLogout = (properties: { [key: string]: any } = {}) =>
  heapGuard({
    name: "Heap Logout",
    properties,
    next: () => {
      heap.resetIdentity();
    },
  });
