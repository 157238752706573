<template>
  <div class="matching-list-mobile">
    <div class="container">
      <div class="matching-list-mobile__results">
        <VWindow v-model="selectedView">
          <VWindowItem :value="'discover'">
            <MatchingListCards />
          </VWindowItem>
          <VWindowItem
            v-if="$features.isEnabled(`matchingInterest`)"
            :value="'pending'"
          >
            <MatchingListInterests />
          </VWindowItem>
          <VWindowItem
            v-if="$features.isEnabled(`matchingInterest`)"
            :value="'connected'"
          >
            <MatchingListInterestMutual />
          </VWindowItem>
        </VWindow>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import { PROFILE_META_ACTIVE_VIEW } from "@/modules/profile/constants";
import { EMetaActions } from "@/services/store/meta/meta-types";

import MatchingListCards from "@/modules/matching/components/matching-list-cards/matching-list-cards.vue";
import MatchingListInterests from "@/modules/matching/components/matching-list-interests/matching-list-interests.vue";
import MatchingListInterestMutual from "@/modules/matching/components/matching-list-interests/matching-list-interests-mutual.vue";
import { VWindow, VWindowItem } from "vuetify/components";

export default defineComponent({
  name: "MatchingListMobile",

  components: {
    MatchingListCards,
    MatchingListInterests,
    MatchingListInterestMutual,
    VWindow,
    VWindowItem,
  },

  data() {
    return {
      selectedView: "discover",
    };
  },

  computed: {
    storeActiveView(): string {
      const storeValue = this.$store.get("meta/get", PROFILE_META_ACTIVE_VIEW);
      return storeValue ? storeValue : "discover";
    },
  },

  watch: {
    selectedView(newVal, oldVal) {
      if (newVal === oldVal || this.storeActiveView === newVal) {
        return;
      }

      this.$store.dispatch(EMetaActions.SET, {
        key: PROFILE_META_ACTIVE_VIEW,
        value: newVal,
      });
    },

    storeActiveView(newVal) {
      this.selectedView = newVal;
    },
  },
});
</script>

<style lang="scss" scoped>
.matching-list-mobile .container {
  width: 100vw;
  padding: 0;

  @include breakpoint-up(md) {
    width: 100%;
  }

  @include breakpoint-down(sm) {
    .v-window-item {
      min-height: calc(100vh - 144px);
    }
  }
}

.matching-list-mobile :deep() .matching-list__cards-header {
  margin-bottom: 20px;
}
</style>
