import { IDialogConfirmationOptions } from "@/components/dialog-confirmation/dialog-confirmation.types";
import { defineComponent } from "vue";

export default defineComponent({
  name: "DialogConfirmationMixin",

  methods: {
    showConfirmOverlay(confirmOverlayOptions: IDialogConfirmationOptions) {
      if (this.$refs["dialog-confirmation"]) {
        return (this.$refs["dialog-confirmation"] as any).show(
          confirmOverlayOptions,
        );
      }
    },
  },
});
