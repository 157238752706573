<template>
  <div class="milestone-plan-state-badge">
    <span
      v-if="lastUpdate"
      class="milestone-plan-state-badge__last-update"
      data-testid="milestone-plan-state-last-update"
    >
      {{
        $t("milestonePlanner.milestonePlan.planForm.badges.lastUpdate", {
          updateDate: lastUpdate,
        })
      }}
    </span>
    <ElTooltip
      :content="draftTooltipCopy"
      :disabled="!isDraft"
      data-testid="milestone-plan-state-badge__tooltip"
      placement="top"
      popper-class="milestone-plan-state-badge__tooltip el-tooltip__popper--small el-abaca-tooltip"
    >
      <div
        :class="{
          'milestone-plan-state-badge__wrapper--draft': isDraft,
          'milestone-plan-state-badge__wrapper--not-planned': isNotPlanned,
        }"
        class="milestone-plan-state-badge__wrapper"
      >
        <PxIcon
          v-if="!isNotPlanned"
          :name="icon.name"
          :size="icon.size"
          class="milestone-plan-state-badge__icon"
        />
        <span class="milestone-plan-state-badge__label">
          {{ label }}
        </span>
        <div
          v-if="isDraft || isNotPlanned"
          class="milestone-plan-state-badge__bullet"
        />
      </div>
    </ElTooltip>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { TranslateResult } from "vue-i18n";

export default defineComponent({
  name: "MilestonePlanStateBadge",

  props: {
    lastUpdate: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      required: true,
    },
    isVisitor: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    isDraft(): boolean {
      return (
        this.label ===
        this.$t("milestonePlanner.milestonePlan.planForm.badges.draft")
      );
    },

    isNotPlanned(): boolean {
      return (
        this.label ===
        this.$t("milestonePlanner.milestonePlan.planForm.badges.notPlanned")
      );
    },

    icon(): { [key: string]: string | number } {
      return {
        name: !this.isDraft ? "milestone-check" : "locker",
        size: !this.isDraft ? 16 : 12,
      };
    },

    draftTooltipCopy(): TranslateResult {
      return !this.isVisitor
        ? this.$t(
            "milestonePlanner.milestonePlan.planForm.draftBadgeTooltip.owner",
          )
        : this.$t(
            "milestonePlanner.milestonePlan.planForm.draftBadgeTooltip.guest",
            {
              company: this.ownerName,
            },
          );
    },

    ownerName(): string {
      return this.$store.get("profile/company.data")?.name || "";
    },
  },
});
</script>

<style lang="scss">
.milestone-plan-state-badge__tooltip {
  text-transform: none;
}
</style>

<style lang="scss" scoped>
.milestone-plan-state-badge {
  display: flex;
  align-items: center;

  &__last-update {
    margin-right: 9px;
    font-size: 12px;
    font-style: italic;
    color: $manatee;
  }

  &__wrapper {
    position: relative;
    bottom: 1px;
    display: flex;
    align-items: center;
    max-width: 113px;
    height: 24px;
    padding-right: 8px;
    padding-left: 3px;
    color: $fern;
    cursor: default;
    background-color: rgba(83, 175, 114, 0.16);
    border-radius: 12px;
  }

  &__wrapper--draft {
    flex-direction: row-reverse;
    color: $manatee;
    background-color: $athens-gray;

    span {
      margin-right: 6px;
      margin-left: 0;
    }

    .px-icon {
      margin-right: 1px;
      filter: invert(63%) sepia(15%) saturate(342%) hue-rotate(192deg)
        brightness(89%) contrast(83%);
    }
  }

  &__wrapper--not-planned {
    flex-direction: row-reverse;
    padding-right: 7px;
    color: $manatee;
    background-color: $white;
    border: 1px solid #f0f1f5;

    span {
      margin-left: 0;
    }
  }

  &__label {
    margin-left: 3px;
    font-size: 15px;
    font-weight: 500;
    line-height: 24px;
  }

  &__bullet {
    width: 6px;
    height: 6px;
    margin-right: 8px;
    margin-left: 5px;
    background-color: $manatee;
    border-radius: 50%;
  }
}
</style>
