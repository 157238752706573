<template>
  <div
    :class="{ 'complete-milestone-info-indicator--has-evidence': hasEvidence }"
    class="complete-milestone-info-indicator"
  >
    <PxIcon
      :name="iconName"
      :size="17"
      class="complete-milestone-info-indicator__icon"
      data-testid="icon"
    />
    <div>
      <span
        class="complete-milestone-info-indicator__label"
        data-testid="label"
      >
        {{ label }}{{ hasAction ? "." : "" }}
      </span>
      <span
        v-if="hasAction"
        class="complete-milestone-info-indicator__action"
        data-testid="action"
        @click="$emit('action-click')"
      >
        {{ actionLabel }}
      </span>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { TranslateResult } from "vue-i18n";
import { EMilestoneStatusType } from "@/modules/milestone-planner/services/data/milestones/milestone.interface";

export default defineComponent({
  name: "CompleteMilestoneInfoIndicator",

  props: {
    hasEvidence: { type: Boolean, default: false },
    hasAction: { type: Boolean, default: false },
    isVisitor: { type: Boolean, default: false },
  },

  computed: {
    label(): TranslateResult {
      return this.hasEvidence
        ? this.$t("milestonePlanner.milestone.evidenceProvided")
        : this.$t("milestonePlanner.milestone.selfAssessed");
    },

    actionLabel(): TranslateResult {
      return this.isVisitor
        ? this.$t("milestonePlanner.milestone.actions.view")
        : this.hasEvidence
          ? this.$t("milestonePlanner.milestone.actions.review")
          : this.$t("milestonePlanner.milestone.actions.addEvidence");
    },

    iconName(): string {
      return this.hasEvidence
        ? EMilestoneStatusType.EVIDENCE_PROVIDED
        : EMilestoneStatusType.SELF_ASSESSED;
    },
  },
});
</script>

<style lang="scss" scoped>
.complete-milestone-info-indicator {
  display: grid;
  grid-template-columns: auto auto auto;
  align-items: center;
  justify-content: center;
  max-height: 20px;
  padding: 0 6px;
  margin: 16px auto 2px auto;
  color: $manatee;
  background-color: transparentize($manatee, 0.92);
  border-radius: $--border-radius-small;

  &__icon {
    position: relative;
    bottom: 1px;
    margin-right: 6px;
  }

  &__label,
  &__action {
    position: relative;
    right: 1px;
    bottom: 1px;
    font-size: 14px;
    line-height: 20px;
  }

  &__label {
    @include grotesk(medium);

    letter-spacing: -0.2px;
  }

  &__action {
    @include grotesk(semiBold);

    text-decoration: underline;
    cursor: pointer;

    &:hover {
      color: $ebony-clay;
    }
  }

  &--has-evidence {
    max-width: 199px;
    color: $denim;
    background-color: transparentize($denim, 0.92);

    .complete-milestone-info-indicator__action:hover {
      color: $dark-blue;
    }
  }
}
</style>
