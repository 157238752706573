import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, renderList as _renderList, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-25b17116"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "multi-selector-entry__wrapper" }
const _hoisted_2 = { class: "multi-selector-entry__info-container" }
const _hoisted_3 = { class: "multi-selector-entry__name" }
const _hoisted_4 = { class: "multi-selector-entry__child-label" }
const _hoisted_5 = { class: "multi-selector-entry__action-container" }
const _hoisted_6 = { class: "multi-selector-entry__child-label" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass([{
        'multi-selector-entry__container--child-scrolled':
          _ctx.showAllItems && _ctx.hasChildContainerBeenScrolled,
      }, "multi-selector-entry__container"]),
      onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.onShowAllItemsClick && _ctx.onShowAllItemsClick(...args)))
    }, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.formattedItem.label), 1),
        _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.formattedChildCopy), 1),
        (_ctx.hasChildItems)
          ? (_openBlock(), _createElementBlock("i", {
              key: 0,
              class: _normalizeClass([{ 'icon--invert-y': _ctx.showAllItems }, "icon icon-caret--gray"])
            }, null, 2))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("i", {
          class: "icon icon-pencil--dark",
          onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onEditItemClick && _ctx.onEditItemClick(...args)), ["stop"]))
        }),
        _createElementVNode("i", {
          class: "icon icon-trash--dark",
          onClick: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onRemoveItemClick && _ctx.onRemoveItemClick(...args)), ["stop"]))
        })
      ])
    ], 2),
    _createElementVNode("div", {
      ref: "childContainer",
      class: _normalizeClass([{
        'multi-selector-entry__child-container--hidden': !_ctx.showAllItems,
      }, "multi-selector-entry__child-container"])
    }, [
      (_ctx.hasChildItems)
        ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.formattedItem.children, (child) => {
            return (_openBlock(), _createElementBlock("div", {
              key: child.value,
              class: "multi-selector-entry__child"
            }, [
              _createElementVNode("span", _hoisted_6, _toDisplayString(child.label), 1)
            ]))
          }), 128))
        : _createCommentVNode("", true)
    ], 2)
  ]))
}