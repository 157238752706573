<template>
  <ElDialog
    ref="modal"
    v-model="innerVisibility"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :show-close="false"
    class="el-dialog--no-header el-dialog--compact-footer matching-confirm-modal"
    width="661px"
  >
    <PxModalMessage :visible="hasServerError" />
    <PxModalMessage
      :title="errorMessage"
      :visible="!hasMatchingMandatoryData"
    />
    <p
      :class="{
        'matching-confirm-modal__title-error':
          hasServerError || !hasMatchingMandatoryData,
      }"
      class="matching-confirm-modal__title"
    >
      {{ $t("matching.confirmModal.title") }}
    </p>
    <PxEditPanel
      :is-save-button-disabled="!newLocation"
      :title="$t('matching.confirmModal.location.title')"
      @cancel="resetLocationValues"
      @submit="onSubmitLocation"
    >
      <template v-if="location" #normal>
        <span class="matching-confirm-modal__location-text">
          {{ location.formatted_address }}
        </span>
      </template>
      <template v-else #normal>
        <span class="matching-confirm-modal__location-text">
          {{ $t("matching.confirmModal.location.noLocation") }}
        </span>
      </template>
      <template #edit>
        <PxInputPlaces
          id="companyEditLocation"
          v-model="newLocation"
          :placeholder="$t('matching.confirmModal.location.placeholder')"
          :use-geolocation="true"
        />
      </template>
    </PxEditPanel>
    <PxEditPanel
      :is-save-button-disabled="!newSectors.length"
      :title="$t('matching.confirmModal.sectors.title')"
      @edit="onEditSectors"
      @submit="onSubmitSector"
    >
      <template v-if="hasSector" #normal>
        <div class="matching-confirm-modal__sectors-list">
          <ElTag
            v-for="sector in sectors"
            :key="sector.id"
            class="text--capitalize"
            effect="plain"
            v-text="sector.name"
          />
        </div>
      </template>
      <template v-else #normal>
        <span class="matching-confirm-modal__location-text">
          {{ $t("matching.confirmModal.sectors.noSectors") }}
        </span>
      </template>
      <template #edit>
        <SectorsSelector ref="sectorSelector" v-model="newSectors" no-label />
      </template>
    </PxEditPanel>
    <PxEditPanel
      ref="matching-assessment"
      :class="{ 'has-error': hasAssessmentLevel }"
      :is-redirecting="isViralLevelLoading"
      :title="$t('matching.confirmModal.level.title')"
      data-testid="matching-confirm-modal__assessment-panel"
      @edit="onEditViralLevel"
    >
      <template #normal>
        <div
          v-if="latestAssessmentLevel"
          class="matching-confirm-modal__level-card"
        >
          <PxIcon
            :name="`level-graph/${latestAssessmentLevel.value}`"
            :size="$screen.mdUp ? 34 : 42"
            class="matching-confirm-modal__level-card-indicator"
          />
          <span class="matching-confirm-modal__level-card-title">
            {{ latestAssessmentLevel.title }}
          </span>
        </div>
        <div v-else class="matching-confirm-modal__empty-level">
          <PxIcon
            :size="34"
            class="matching-confirm-modal__empty-level-icon"
            name="warning"
          />
          <span class="matching-confirm-modal__empty-level-title">
            {{ $t(`matching.confirmModal.level.empty`) }}
          </span>
        </div>
      </template>
    </PxEditPanel>
    <template #footer>
      <PxButton
        :disabled="!hasMatchingMandatoryData"
        :loading="isLoading"
        class="matching-confirm-modal__submit-btn"
        type="green"
        @click="onClickProceedButton"
      >
        {{ nextButtonLabel }}
      </PxButton>
    </template>
  </ElDialog>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import PxEditPanel from "@/components/px-edit-panel/px-edit-panel.vue";
import SectorsSelector from "@/components/sectors-selector/sectors-selector.vue";

import ElDialogMixin from "@/mixins/el-dialog.mixin";
import { ICompany } from "@/modules/profile/services/data/company/company.types";
import { ILocation } from "@/services/data/location/location.interface";
import { ILevel } from "@/services/data/level/level.interface";
import {
  EAuthActions,
  EAuthCompanyActions,
} from "@/modules/authentication/services/store/auth/auth-types";
import { EViralLevelActions } from "@/services/store/viral-level/viral-level-types";
import { ROUTE_UPDATE_LEVEL } from "@/modules/profile/services/router/routes-names";
import { EMetaActions } from "@/services/store/meta/meta-types";
import {
  EUserMetadataActions,
  EUserMetadataGetters,
} from "@/modules/authentication/services/store/auth/sub-modules/user-metadata/user-metadata.types";
import {
  MATCHING_ONBOARDING_COMPLETION,
  MATCHING_FROM_ONBOARDING,
} from "@/modules/matching/constants";
import { ISector } from "@/services/data/sector/sector.interface";
import { IAssessment } from "@/services/data/assessment/assessment.interface";
import { TranslateResult } from "vue-i18n";

export default defineComponent({
  name: "MatchingConfirmModal",

  components: {
    PxEditPanel,
    SectorsSelector,
  },

  mixins: [ElDialogMixin],

  data() {
    return {
      hasServerError: false,
      isLoading: false,

      newLocation: {} as any,
      newSectors: [],
    };
  },

  computed: {
    isMilestonePlannerRoute(): boolean {
      return this.$route.path.includes("milestone-planner");
    },

    isListsRoute(): boolean {
      return this.$route.path.includes("lists");
    },

    currentPageIndex(): number {
      return this.isListsRoute ? 1 : this.isMilestonePlannerRoute ? 2 : 0;
    },

    nextButtonLabel(): TranslateResult {
      return this.$t("matching.confirmModal.buttons.proceed", {
        page: this.$t(`common.pages[${this.currentPageIndex}]`),
      });
    },

    company(): ICompany {
      return this.$store.get("auth/company.data");
    },

    hasSector(): boolean {
      return !!this.sectors.length;
    },

    hasLocation(): boolean {
      return this.location !== null;
    },

    errorMessage(): TranslateResult {
      return !this.hasLocation
        ? this.$t("matching.confirmModal.errors.location")
        : !this.hasSector
          ? this.$t("matching.confirmModal.errors.sector")
          : this.$t("matching.confirmModal.errors.level");
    },

    hasMatchingMandatoryData(): boolean {
      return (
        !!this.hasLocation && !!this.hasSector && !!this.hasAssessmentLevel
      );
    },

    location(): ILocation | null {
      if (
        !this.company ||
        !this.company.locations ||
        !this.company.locations[0]
      ) {
        return null;
      }

      return this.company.locations[0];
    },

    sectors(): Array<ISector> {
      return this.company && this.company.sectors ? this.company.sectors : [];
    },

    latestAssessment(): IAssessment | null {
      return this.$store.get("auth/latestAssessment/data");
    },

    latestAssessmentLevel(): ILevel | null {
      return this.latestAssessment ? this.latestAssessment.level : null;
    },

    hasAssessmentLevel(): boolean {
      return this.latestAssessmentLevel !== null;
    },

    isViralLevelLoading(): boolean {
      return this.$store.state.viralLevel.loading;
    },

    hasAlreadyOnBoarded(): boolean {
      return !!this.$store.get(
        EUserMetadataGetters.GET,
        MATCHING_ONBOARDING_COMPLETION,
      );
    },
  },

  created() {
    this.fetchLevel();

    // Init company location edit data
    this.newLocation = this.location;
  },

  methods: {
    resetLocationValues() {
      this.newLocation = this.location || {};
    },

    /**
     * Mark the matching onboarding as finished.
     */
    async onClickProceedButton() {
      this.hasServerError = false;
      this.isLoading = true;

      try {
        if (!this.hasAlreadyOnBoarded) {
          await this.$store.dispatch(EUserMetadataActions.SET, {
            key: MATCHING_ONBOARDING_COMPLETION,
            value: "true",
          });
        }
        this.$emit("update:visibility", false);
      } catch (_) {
        this.hasServerError = true;
        return;
      } finally {
        this.isLoading = false;
      }

      // Cleanup meta module
      await this.$store.dispatch(EMetaActions.REMOVE, MATCHING_FROM_ONBOARDING);
    },

    /**
     * Initialize edit sectors component
     */
    onEditSectors() {
      const sectorSelector = this.$refs.sectorSelector as any;
      sectorSelector.setSectors(this.sectors);
    },

    /**
     * Request a new Viral Level.
     *
     * - Reset the assessment flow data;
     * - Redirect to the assessment flow page;
     * - Set a new flag in order to the profile know that on the end
     * of the assessment a link must be provided to the user return
     * to matching page.
     */
    async onEditViralLevel() {
      await this.$store.dispatch(EViralLevelActions.RESET);
      this.$router.push({
        name: ROUTE_UPDATE_LEVEL,
      });
    },

    /**
     * Fetch the company level for the user company.
     */
    async fetchLevel() {
      if (this.company) {
        await this.$store.dispatch(
          EAuthActions.FETCH_LATEST_ASSESSMENT,
          this.company.id,
        );
      }
    },

    /**
     * Save the changes on the location field.
     */
    onSubmitLocation(success: Function) {
      this.partialUpdate({ location: this.newLocation }, success);
    },

    /**
     * Save the changes made to the sectors.
     */
    onSubmitSector(success: Function) {
      this.partialUpdate({ sectors: this.newSectors }, success);
    },

    async partialUpdate(newData: any, success: Function) {
      this.hasServerError = false;

      try {
        await this.$store.dispatch(EAuthCompanyActions.PATCH, {
          id: this.company.id,
          ...newData,
        });
      } catch (error) {
        this.hasServerError = true;
      }

      success(!this.hasServerError);

      await this.fetchCompany();
    },

    async fetchCompany() {
      await this.$store.dispatch(EAuthCompanyActions.FETCH, this.company.id);
    },
  },
});
</script>

<style lang="scss">
.matching-confirm-modal {
  .el-dialog--no-header {
    z-index: z("overlay");
  }

  .px-modal-message {
    top: -9px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }

  .el-dialog__footer {
    @include breakpoint-up(md) {
      display: flex;
      justify-content: center;
    }
  }

  &__title {
    @include grotesk(semiBold);

    margin-bottom: 20px;

    font-size: to-rem(18px);
    line-height: 28px;
    color: $ebony-clay;
    text-align: center;
    letter-spacing: -0.2px;

    @include breakpoint-up(md) {
      margin-bottom: 26px;

      font-size: to-rem(20px);
    }
  }

  &__title-error {
    margin-top: 10px;
  }

  &__location-text {
    @include grotesk(medium);

    display: block;
    padding-top: 3px;

    font-size: to-rem(16px);
    line-height: 25px;
    color: $ebony-clay;

    @include breakpoint-up(md) {
      padding-top: 2px;
    }
  }

  &__sectors-list {
    padding-top: 9px;
    margin-bottom: -8px;

    @include breakpoint-up(md) {
      padding-top: 4px;
    }

    .el-tag {
      margin-right: 8px;
      margin-bottom: 2px;
    }
  }

  &__level-card {
    display: flex;
    padding-top: 3px;

    @include breakpoint-up(md) {
      align-items: center;
      padding-top: 2px;
    }
  }

  &__level-card-indicator {
    margin-right: 6px;
    margin-left: 3px;

    @include breakpoint-up(md) {
      margin-left: 1px;
    }
  }

  &__level-card-title {
    @include grotesk(medium);

    font-size: to-rem(16px);
    line-height: 23px;
    color: $ebony-clay;
  }

  &__empty-level {
    display: flex;
    padding-top: 3px;

    @include breakpoint-up(md) {
      align-items: center;
      padding-top: 2px;
    }
  }

  &__empty-level-title {
    @include grotesk(medium);

    margin-left: 6px;
    font-size: to-rem(16px);
    line-height: 23px;
    color: $ebony-clay;
  }

  &__empty-level-icon {
    height: 28px !important;
  }

  &__submit-btn {
    span {
      padding: 2px;
      font-size: 13px;
    }

    @include breakpoint-down(sm) {
      display: block;
      width: 100%;
    }
  }

  .pxEditPanel .pxEditPanel-actions {
    padding-top: 18px;

    span {
      font-size: 12px;
    }
  }
}
</style>
