import { IBaseState } from "@/services/store/store-types";
import { IMatchingScore } from "@/modules/matching/services/data/matching-score/matching-score.interface";

/**
 * Represents the state structure for the matching module.
 */
export interface IMatchingState extends IBaseState {
  /**
   * Array with all matching entries.
   */
  data: Array<IMatchingScore>;

  /**
   * Last page fetched.
   */
  page: number;

  /**
   * Signals when new matching entries are being calculated.
   */
  recalculating: boolean;
}

export enum EMatchingActions {
  FETCH = "matching/fetch",
  FETCH_RECALCULATIONS_STATE = "matching/fetchRecalculationsState",
  SET = "matching/set",
  APPEND = "matching/append",
}

export enum EMatchingMutations {
  SET_LOADING = "SET_LOADING",
  SET_PAGE = "SET_PAGE",
  SET_DATA = "SET_DATA",
  SET_ERROR = "SET_ERROR",
  SET_APPEND_DATA = "SET_APPEND_DATA",
  SET_RECALCULATING = "SET_RECALCULATING",
}

export interface IMatchingFetchPayload {
  page: number;
  exclude?: Array<string>;
  name?: string;
}
