import { ICompanyList } from "@/modules/company-lists/services/data/company-list/company-list.interface";
import { companyListProvider } from "@/modules/company-lists/services/data/company-list/company-list.provider";
import {
  ECompanyListAction,
  ECompanyListState,
  ICompanyListState,
} from "@/modules/company-lists/services/store/company-list/company-list.interface";
import { GenericProvider } from "@/services/data/generic-provider";
import { GenericState } from "@/services/store/generic/generic-state.module";
import { actions } from "@/modules/company-lists/services/store/company-list/company-list.actions";
import {
  getters,
  ECompanyListGetters,
} from "@/modules/company-lists/services/store/company-list/company-list.getters";
import {
  mutations,
  ECompanyListMutation,
} from "@/modules/company-lists/services/store/company-list/company-list.mutations";
import { getGenericInitialState } from "@/services/store/generic/generic-state";

// initial state of the module
const initialState: ICompanyListState = {
  ...getGenericInitialState<ICompanyList>(),
  currentDetailPage: 1,

  detailLoading: false,
  detailError: null,
};

export class CompanyListState extends GenericState<
  ICompanyListState,
  ICompanyList
> {
  static Action = GenericState.mapNamespaceEnum(
    ECompanyListAction,
    ECompanyListState.NAMESPACE,
  );

  static Getter = GenericState.mapNamespaceEnum(
    ECompanyListGetters,
    ECompanyListState.NAMESPACE,
  );

  static Mutation = GenericState.mapNamespaceEnum(
    ECompanyListMutation,
    ECompanyListState.NAMESPACE,
  );

  constructor(provider: GenericProvider<ICompanyList>) {
    super({ provider });

    this.state = initialState;
    this.actions = actions;
    this.mutations = mutations;
    this.getters = getters;
  }
}

export const companyListModule = new CompanyListState(companyListProvider);
