<template>
  <ElDialog
    ref="modal"
    v-model="innerVisibility"
    :append-to-body="true"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    class="milestone-plan-level-up-dialog"
    width="492px"
  >
    <canvas ref="canvas" class="milestone-plan-level-up-dialog__animation" />
    <template #header>
      <h1
        :style="`color: #${categoryColor}`"
        class="milestone-plan-level-up-dialog__title"
      >
        {{
          $t("milestonePlanner.milestonePlan.levelUpDialog.title", {
            category: categoryName,
          })
        }}
      </h1>
      <i18n-t
        class="milestone-plan-level-up-dialog__subtitle"
        keypath="milestonePlanner.milestonePlan.levelUpDialog.subtitle"
        tag="p"
      >
        <template #level>
          <p class="milestone-plan-level-up-dialog__level">
            {{
              $t("common.levelTag", {
                level,
              })
            }}
          </p>
        </template>
      </i18n-t>
    </template>
    <PxLevel :category-color="categoryColor" :level="level" />
    <template #footer>
      <div class="milestone-plan-level-up-dialog__category-level">
        <div
          :style="`backgroundColor: #${categoryColor}`"
          class="milestone-plan-level-up-dialog__category-indicator"
        />
        <div class="milestone-plan-level-up-dialog__level-number">
          {{ level }}
        </div>
        <div class="milestone-plan-level-up-dialog__category-name">
          {{ categoryName }}
        </div>
      </div>
      <div class="milestone-plan-level-up-dialog__category-level-description">
        {{ description }}
      </div>
    </template>
  </ElDialog>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import * as particleRender from "@/utils/particle.render";
import ElDialogMixin from "@/mixins/el-dialog.mixin";
import PxLevel from "@/components/px-level/px-level.vue";
import { ElDialog } from "element-plus";

export default defineComponent({
  name: "MilestonePlanLevelUpDialog",
  components: {
    PxLevel,
  },

  mixins: [ElDialogMixin],

  props: {
    categoryName: {
      type: String,
      required: true,
    },
    categoryColor: {
      type: String,
      required: true,
    },
    level: {
      type: Number,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
  },

  watch: {
    async innerVisibility(newVal) {
      if (!newVal) {
        return;
      }

      await this.$nextTick();
      this.playAnimation();
    },
  },

  methods: {
    hexToRGB(hex: string) {
      // Exclude the "#" on position 0
      const r = parseInt(hex.slice(1, 3), 16),
        g = parseInt(hex.slice(3, 5), 16),
        b = parseInt(hex.slice(5, 7), 16);
      return `${r}, ${g}, ${b}`;
    },

    playAnimation() {
      const canvasElement = this.$refs.canvas as HTMLCanvasElement;
      const dialogElement = (this.$refs.modal as typeof ElDialog)
        .dialogContentRef.$el;
      const bodyElement = dialogElement.querySelector(".el-dialog__body");
      const elementWidth = bodyElement.clientWidth;
      const elementHeight = bodyElement.clientHeight;
      const context = particleRender.createContext(
        canvasElement,
        elementWidth,
        elementHeight,
      );
      const rgbColor = this.hexToRGB(`#${this.categoryColor}`);
      const categoryColors = [
        `rgba(${rgbColor}, 0.6)`,
        `rgba(${rgbColor}, 0.2)`,
        `rgba(${rgbColor}, 0.4)`,
        `rgba(${rgbColor}, 0.603)`,
        `rgba(${rgbColor}, 0.3)`,
      ];
      const centerPoint = particleRender.getCanvasCenterPoint(context);
      particleRender.addConfettiParticles(
        context,
        30,
        centerPoint.x - 10,
        centerPoint.y,
        categoryColors,
      );
      particleRender.render(context);
    },
  },
});
</script>

<style lang="scss">
.milestone-plan-level-up-dialog {
  svg {
    right: 0;
    display: block;
    margin: auto;
    transform: none;
  }

  .el-dialog__header {
    padding: 29px 0 0 0;
    text-align: center;
    background-color: transparent;
    border: none;
  }

  .el-dialog__headerbtn {
    top: 20px;
    right: 14px;
    width: 22px;
    height: 22px;
  }

  .el-dialog__body {
    min-height: 213px;
    padding: 8px 0 32px;
  }

  .el-dialog__footer {
    padding: 23px 0 18px;
    margin-top: 13px;
    background-color: #fcfcfc;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;

    &::before {
      width: 100%;
    }
  }

  &__animation {
    position: absolute;
    top: 0;
    left: 0;
  }

  &__title {
    position: relative;
    right: 2px;
    margin-bottom: 2px;
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;
    letter-spacing: -0.17px;
  }

  &__subtitle {
    font-size: 17px;
    line-height: 22px;
    color: $ebony-clay;
    letter-spacing: -0.3px;
  }

  &__level {
    display: inline-block;
    font-size: 17px;
    font-weight: 600;
    line-height: 32px;
    color: $ebony-clay;
    text-transform: lowercase;
  }

  &__level::first-letter {
    text-transform: uppercase;
  }

  &__category-level {
    display: flex;
    gap: 8px;
    align-items: center;
    justify-content: center;
  }

  &__category-indicator {
    position: relative;
    bottom: 1px;
    width: 6px;
    height: 6px;
    border-radius: 50%;
  }

  &__level-number {
    margin-right: 3px;
  }

  &__level-number,
  &__category-name {
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;
    color: $ebony-clay;
    letter-spacing: 0.1px;
  }

  &__category-name {
    position: relative;
    right: 6px;
  }

  &__category-level-description {
    padding: 1px 60px 6px;
    line-height: 22px;
    text-align: center;
    letter-spacing: -0.35px;
  }
}
</style>
