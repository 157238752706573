<template>
  <div class="auth-base-signup__step signup-step-company">
    <PxPanel class="px-panel--compact">
      <template #header>
        <h2
          class="px-panel__title"
          v-html="$t('authentication.signup.form.company')"
        />
      </template>
      <ElForm
        ref="signupForm"
        :model="data"
        :rules="rules"
        class="auth-base-signup__form"
        @validate="updateSubmitState"
      >
        <ElFormItem
          :label="$t('authentication.signup.form.fields.companyName')"
          prop="company.name"
        >
          <ElInput
            v-model="data.company.name"
            :placeholder="$t('authentication.signup.form.fields.companyName')"
            data-testid="authentication-signup-input-name"
          />
        </ElFormItem>
        <ElFormItem
          :label="$t('authentication.signup.form.fields.location')"
          prop="company.location"
        >
          <PxInputPlaces
            id="companyInfoLocation"
            v-model="data.company.location"
            :placeholder="$t('authentication.signup.form.fields.location')"
            :use-geolocation="true"
            data-testid="authentication-signup-input-location"
          />
        </ElFormItem>
        <ElFormItem
          :label="$t('authentication.signup.form.fields.website')"
          prop="company.website"
        >
          <ElInput
            v-model.trim="data.company.website"
            :placeholder="
              $t('authentication.signup.form.fields.websitePlaceholder')
            "
            data-testid="authentication-signup-input-website"
          >
            <template #prefix>
              <PxIcon :size="24" name="website" />
            </template>
          </ElInput>
        </ElFormItem>
        <ElFormItem prop="company.sectors">
          <SectorsSelector
            v-model="data.company.sectors"
            data-testid="authentication-signup-input-sectors"
            @change="handleSectorsChange"
          />
        </ElFormItem>
        <div class="el-form__cta">
          <PxButton
            :disabled="stepDisabled"
            data-testid="authentication-signup-next-button"
            size="medium"
            type="primary"
            @click="goToNextStep"
          >
            {{ $t("authentication.signup.form.stepButton") }}
          </PxButton>
        </div>
      </ElForm>
    </PxPanel>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import PxInputPlaces from "@/components/px-input-places/px-input-places.vue";
import SectorsSelector from "@/components/sectors-selector/sectors-selector.vue";

import {
  allFormFieldsValid,
  generateRequiredSetValidator,
  generateRequiredValidator,
  generateURLValidator,
} from "@/services/errors/validator-generators";
import { FormContext } from "element-plus";

export default defineComponent({
  name: "SignUpStepCompany",

  components: {
    PxInputPlaces,
    SectorsSelector,
  },

  props: {
    company: {
      type: Object as () => any,
      required: true,
    },
  },

  data() {
    return {
      stepDisabled: true,

      data: {
        company: {
          name: "",
          location: null,
          sectors: [] as Array<number>,
          website: "",
        },
      },
      rules: {
        "company.name": generateRequiredValidator(
          this,
          "authentication.signup.form.fields.companyName",
        ),
        "company.location": generateRequiredValidator(
          this,
          "authentication.signup.form.fields.location",
        ),
        "company.website": generateURLValidator(
          this,
          "authentication.signup.form.fields.website",
        ),
        "company.sectors": generateRequiredSetValidator(
          this,
          "selfAssessment.companyInfoModal.errors.sector",
        ),
      },
    };
  },

  computed: {
    signupFormRef(): FormContext {
      return this.$refs.signupForm as FormContext;
    },
  },

  methods: {
    updateSubmitState() {
      this.stepDisabled = !allFormFieldsValid(this.signupFormRef, this.rules);
      this.$emit("update:company", this.data.company);
    },

    goToNextStep() {
      this.$emit("next-step");
    },

    handleSectorsChange() {
      this.signupFormRef.validateField(
        "company.sectors",
        this.updateSubmitState,
      );
    },
  },
});
</script>
