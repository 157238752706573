<template>
  <RenderlessAssessmentPanel v-slot="scope">
    <div class="assessment-panel">
      <div class="assessment-panel__title-section">
        <span>
          {{ $t("profile.assessment.levelPanel.title") }}
        </span>
        <PxButton
          v-if="$user.isOwner()"
          class="profile-headline__updateBtn"
          size="small"
          @click="scope.makeNewAssessment"
        >
          {{ $t("profile.assessment.update") }}
        </PxButton>
      </div>
      <PxPanel class="assessment-panel__panel px-panel--xs-no-side-borders">
        <AssessmentPanelLevelInactive v-if="scope.isInactive" />
        <AssessmentPanelLevel
          v-else
          :latest-assessment="scope.latestAssessment"
          :level="scope.latestAssessmentLevel"
          :levels="scope.levels"
          class="assessment-panel-level"
          @request-new-assessment="scope.makeNewAssessment"
        />
      </PxPanel>
      <div
        class="assessment-panel__title-section assessment-panel__title-section-grid"
      >
        <span>
          {{ $t("profile.assessment.gridPanel.title") }}
        </span>
        <div v-if="!isHowItWorksLinkDisabled">
          <PxButton
            class="el-button--link el-button--link-dark el-button--small"
            icon="icon-information"
            type="link"
            @click="onClickOpenHowItWorksLink"
          >
            {{ $t("profile.assessment.gridPanel.moreLink") }}
          </PxButton>
        </div>
      </div>
      <PxPanel class="assessment-panel__panel px-panel--xs-no-side-borders">
        <AssessmentPanelGridInactive v-if="scope.isInactive" />
        <AssessmentPanelGrid
          v-else
          :latest-assessment="scope.latestAssessment"
          :level-value="scope.latestAssessmentLevelValue"
          :levels="scope.levels"
          class="assessment-panel-grid"
        />
      </PxPanel>
      <div class="assessment-panel__title-section">
        <span>
          {{ $t("profile.assessment.historyPanel.title") }}
        </span>
        <PxButton
          v-if="$features.isEnabled('updateLevel') && $user.isOwner()"
          class="profile-headline__updateBtn"
          size="small"
          @click="scope.makeNewAssessment"
        >
          {{ $t("profile.assessment.update") }}
        </PxButton>
      </div>
      <PxPanel class="assessment-panel__panel px-panel--xs-no-side-borders">
        <AssessmentPanelHistoryInactive v-if="scope.isInactive" />
        <AssessmentPanelHistory v-else />
      </PxPanel>
    </div>
  </RenderlessAssessmentPanel>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import RenderlessAssessmentPanel from "@/modules/profile/components/renderless-assessment-panel/renderless-assessment-panel.vue";
import AssessmentPanelLevel from "@/modules/profile/components/assessment-panel/assessment-panel--level.vue";
import AssessmentPanelLevelInactive from "@/modules/profile/components/assessment-panel/assessment-panel--level-inactive.vue";
import AssessmentPanelGrid from "@/modules/profile/components/assessment-panel/assessment-panel--grid.vue";
import AssessmentPanelGridInactive from "@/modules/profile/components/assessment-panel/assessment-panel--grid-inactive.vue";
import AssessmentPanelHistory from "@/modules/profile/components/assessment-panel/assessment-panel--history.vue";
import AssessmentPanelHistoryInactive from "@/modules/profile/components/assessment-panel/assessment-panel--history-inactive.vue";

export default defineComponent({
  components: {
    RenderlessAssessmentPanel,
    AssessmentPanelLevel,
    AssessmentPanelLevelInactive,
    AssessmentPanelGrid,
    AssessmentPanelGridInactive,
    AssessmentPanelHistory,
    AssessmentPanelHistoryInactive,
  },

  computed: {
    isHowItWorksLinkDisabled(): boolean {
      return this.$features.isEnabled("disabledHowItWorksLink");
    },
  },

  methods: {
    onClickOpenHowItWorksLink() {
      window.open(
        "https://medium.com/village-capital/entrepreneurs-and-vcs-need-to-be-more-precise-in-the-way-they-talk-to-each-other-3e714e7a5245",
      );
    },
  },
});
</script>

<style lang="scss" scoped>
.assessment-panel__title-section {
  display: flex;
  justify-content: space-between;
  padding: 26px 16px 9px;

  span {
    @include grotesk(semiBold);

    font-size: to-rem(18px);
  }
}

.assessment-panel__title-section-grid {
  display: grid;

  :deep(.el-button) {
    gap: 5px;
  }

  :deep(.px-icon) {
    width: 16px;
    height: 16px;
  }
}

.assessment-panel__panel :deep() .px-panel__body {
  padding: 4px 15px 15px;
}

.assessment-panel__panel :deep() .profile-headline {
  display: none !important;
}

.assessment-panel-grid {
  @include breakpoint-down(sm) {
    padding-bottom: 7px;
  }
}
</style>
