<template>
  <div class="capital-tile">
    <transition name="fade">
      <div
        v-if="showMatchingBadge && matchProperties.matchingBadge"
        class="capital-tile__matching-badge"
      >
        <PxStatusBadge
          :background-color="matchProperties.matchingBadge.backgroundColor"
          :border-color="matchProperties.matchingBadge.borderColor"
          :icon="matchProperties.matchingBadge.icon"
          :icon-size="13"
          :label="matchingBadgeLabel"
          :label-color="matchProperties.matchingBadge.textColor"
          :label-size="14"
          :style="matchingBadgeStyles"
          :uppercase="false"
          :weight="600"
          data-testid="matching-badge"
        />
      </div>
    </transition>
    <div
      :style="tileStyles"
      class="capital-tile__wrapper"
      @click="detailsModalVisible = true"
    >
      <div class="capital-tile__header">
        <div>
          <h2 :title="capitalInfo.title" class="capital-tile__title">
            {{ capitalInfo.title }}
          </h2>
          <p :title="capitalInfo.subtitle" class="capital-tile__subtitle">
            {{ capitalInfo.subtitle }}
          </p>
        </div>
        <div class="capital-tile__badges">
          <div
            v-for="(badge, index) in badges"
            :key="index"
            class="capital-tile__badges-item"
          >
            <PxIcon
              :name="badge.icon"
              :size="16"
              data-testid="capital-tiles-badge-icon"
            />
            <p
              :title="badge.description"
              data-testid="capital-tiles-badge-title"
            >
              {{ badge.description }}
            </p>
          </div>
        </div>
      </div>
      <div
        v-if="capitalInfo.company_stages"
        :style="footerStyles"
        class="capital-tile__footer"
      >
        <p class="capital-tile__footer-title">
          {{ $t("capitalExplorer.tiles.companyStage") }}
        </p>
        <div class="capital-tile__stages">
          <MatchingCardTagList
            :items="capitalInfo.company_stages"
            :max-items-to-display="3"
            :should-sort-array="false"
            data-testid="capital-explorer-company-stages"
            title="capitalExplorer.tiles.companyStage"
          />
        </div>
      </div>
    </div>
    <DetailsModal
      v-if="detailsModalVisible"
      v-model:visibility="detailsModalVisible"
      :capital-info="capitalInfo"
      :match-details="matchDetails"
      :match-properties="matchProperties"
      :show-matching-badge="showMatchingBadge"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import PxStatusBadge from "@/components/px-status-badge/px-status-badge.vue";
import {
  ICapitalTile,
  ICapitalMatchProperties,
  ICapitalMatchingDetails,
} from "@/modules/capital-explorer/services/data/capital-explorer/capital-explorer.interface";
import MatchingCardTagList from "@/components/matching-card/matching-card-tag-list.vue";
import DetailsModal from "@/modules/capital-explorer/components/details-modal/details-modal.vue";
import { DEFAULT_MATCH } from "@/modules/capital-explorer/capital-structures-matching.types";

export default defineComponent({
  name: "CapitalTile",

  components: {
    PxStatusBadge,
    MatchingCardTagList,
    DetailsModal,
  },

  props: {
    capitalInfo: {
      type: Object as () => ICapitalTile,
      required: true,
    },

    matchProperties: {
      type: Object as () => ICapitalMatchProperties,
      required: true,
    },

    matchDetails: {
      type: Array as () => ICapitalMatchingDetails[],
      required: true,
    },

    showMatchingBadge: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      detailsModalVisible: false,
    };
  },

  computed: {
    tileStyles(): { [key: string]: string | number } {
      const matchProperties = this.showMatchingBadge
        ? this.matchProperties
        : DEFAULT_MATCH;
      return {
        backgroundColor: matchProperties.backgroundColor || "",
        backgroundImage: matchProperties.backgroundImage || "",
        border: `1px solid ${matchProperties.borderColor}`,
      };
    },

    footerStyles(): { [key: string]: string | number } {
      const matchProperties = this.showMatchingBadge
        ? this.matchProperties
        : DEFAULT_MATCH;
      return {
        borderTop: `1px solid ${matchProperties.borderColor}`,
      };
    },

    matchingBadgeStyles(): { [key: string]: string | number } {
      return {
        boxShadow: this.matchProperties.matchingBadge?.boxShadow || "",
      };
    },

    matchingBadgeLabel(): string {
      return this.matchProperties.matchingBadge
        ? this.$t(this.matchProperties.matchingBadge?.title)
        : "";
    },

    badges(): any {
      let fundingTypesIcon = "icon-half-chart";
      let fundingTypesCopy = this.capitalInfo.funding_types
        .map((type) => type.name)
        .join(" & ");

      if (this.capitalInfo.funding_types.length == 1) {
        if (this.capitalInfo.funding_types[0].id === 1) {
          fundingTypesIcon = "icon-pie-chart";
        }
        if (this.capitalInfo.funding_types[0].id === 2) {
          fundingTypesIcon = "icon-circle";
        }

        fundingTypesCopy = this.$t("capitalExplorer.tiles.structureType", {
          type: fundingTypesCopy,
        }) as string;
      }

      return [
        {
          icon: fundingTypesIcon,
          description: fundingTypesCopy,
        },
        {
          icon: "icon-dollar-sign",
          description: this.capitalInfo.funding_stages
            .map((stage) => stage.name)
            .join(" & "),
        },
      ];
    },
  },
});
</script>

<style lang="scss" scoped>
.capital-tile {
  position: relative;
  min-width: 305px;

  cursor: pointer;
  transition: all 0.3s ease-in-out;

  &:hover {
    transform: translate(0, -3px);
  }

  .fade-leave-active {
    transition: all 0.5s ease-in-out;
  }

  .fade-enter-active {
    transition: all 0.5s 0.5s ease-in-out;
  }

  .fade-enter,
  .fade-leave-to {
    transform: translateX(-3px);
    opacity: 0;
  }

  &__matching-badge {
    position: absolute;
    left: 16px;
    top: -13px;
    max-width: 137px;
    z-index: 1;

    :deep() .px-status-badge {
      padding: 5px 9px;
      gap: 5px;

      width: max-content;
      transition: all 0.5s;
    }

    :deep() .px-status-badge__label {
      margin: 0;
      transition: all 0.5s;
    }
  }

  &__wrapper {
    position: relative;
    top: 0;

    height: 100%;
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 4px;
    box-shadow: 0 1px 2px rgba(48, 54, 60, 0.15);

    transition: box-shadow 0.3s;

    &:hover {
      box-shadow:
        0 10px 15px -5px rgba(48, 54, 60, 0.15),
        0 1px 2px 0 rgba(48, 54, 60, 0.15);
    }
  }

  &__header {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    height: 100%;
    min-height: 191px;

    padding: 23px 15px 13px 15px;
  }

  &__footer {
    padding: 15px 15px 17px 15px;

    width: 100%;
  }

  &__title {
    margin: 0;

    font-size: 18px;
    font-weight: 600;
    line-height: 1.56;

    @include multiLineEllipsis(
      $font-size: 18px,
      $line-height: 1.56,
      $lines-to-show: 1
    );
  }

  &__subtitle {
    margin-top: 2px;

    font-size: 15px;
    line-height: 1.47;
    letter-spacing: -0.3px;

    @include multiLineEllipsis(
      $font-size: 15px,
      $line-height: 1.47,
      $lines-to-show: 3
    );
  }

  &__badges {
    display: flex;
    flex-direction: column;
    gap: 2px;
  }

  &__badges-item {
    display: flex;
    align-items: center;
    gap: 6px;

    p {
      @include grotesk(semiBold);

      color: $manatee;
      font-size: 12px;
      text-transform: uppercase;

      @include multiLineEllipsis(
        $font-size: 12px,
        $line-height: 1.67,
        $lines-to-show: 1
      );
    }
  }
  &__footer-title {
    @include grotesk(semiBold);

    margin-bottom: 6px;

    text-transform: uppercase;
    color: $manatee;
    font-size: 13px;
    line-height: 16px;
  }

  &__stages {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;

    letter-spacing: -0.3px;
  }

  &__stages :deep() .matching-card-tag-list__container {
    @include grotesk(semiBold);

    position: relative;
    margin: 0;
    color: $ebony-clay;
    font-size: 15px;
  }

  &__stages :deep() .matching-card-tag-list__more {
    position: relative;
    bottom: 1px;
  }
}
</style>
