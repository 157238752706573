import {
  GenericProvider,
  Provider,
  EProviderFeatures,
} from "@/services/data/generic-provider";
import { IEmailChangeRequest } from "./email-change.types";
import { baseAPIUrl } from "@/services/utils/utils";

@Provider(`${baseAPIUrl}/user/email/update`, [EProviderFeatures.CREATE])
class EmailChangeProvider extends GenericProvider<IEmailChangeRequest> {}

export const emailChangeProvider = new EmailChangeProvider();
