<template>
  <div
    :class="{
      'matching-compact-list-header__container--small': !showDescription,
    }"
    class="matching-compact-list-header__container"
  >
    <div class="matching-compact-list-header__item">
      {{ userTypeCopy }}
    </div>
    <div v-if="showDescription" class="matching-compact-list-header__item">
      {{ $t("common.components.matchingListHeader.description") }}
    </div>
    <div class="matching-compact-list-header__item">
      {{ sectorsCopy }}
    </div>
    <div v-if="!isSameTypeOfUsers" class="matching-compact-list-header__item">
      {{ $t("common.components.matchingListHeader.percentage") }}
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { ENTREPRENEUR_USER_TYPE } from "@/modules/authentication/constants";

export default defineComponent({
  name: "MatchingCardCompactListHeader",

  props: {
    userType: {
      type: Number,
      required: true,
    },

    isSameTypeOfUsers: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    isEntrepreneur(): boolean {
      return this.userType === ENTREPRENEUR_USER_TYPE;
    },

    userTypeCopy(): string {
      return (
        this.isEntrepreneur
          ? this.$t("common.components.matchingListHeader.entrepreneur")
          : this.$t("common.components.matchingListHeader.supporter")
      ) as string;
    },

    sectorsCopy(): string {
      return (
        this.isEntrepreneur
          ? this.$t("common.components.matchingListHeader.sectors")
          : this.$t("common.components.matchingListHeader.sectorsInterest")
      ) as string;
    },

    // Due to size constraints, screens lower than 1440px must hide description
    showDescription(): boolean {
      return this.$screen.xlUp;
    },
  },
});
</script>

<style lang="scss" scoped>
.matching-compact-list-header__container {
  display: grid;
  grid-template-columns: 270px 373px 172px 180px;

  height: 31px;
  padding: 8px 50px;
  margin-bottom: 16px;
  border-bottom: 1px solid $athens-gray;
}

.matching-compact-list-header__container--small {
  grid-template-columns: 270px 172px 180px;
}

.matching-compact-list-header__item {
  @include grotesk(semiBold);

  font-size: 12px;
  color: $manatee;
  text-transform: uppercase;
  opacity: 0.8;
}
</style>
