import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, mergeProps as _mergeProps, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-7e082672"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["textContent"]
const _hoisted_2 = ["textContent"]
const _hoisted_3 = ["textContent"]
const _hoisted_4 = ["textContent"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_i18n_t = _resolveComponent("i18n-t")!
  const _component_PxButton = _resolveComponent("PxButton")!
  const _component_MilestoneStageStatusItem = _resolveComponent("MilestoneStageStatusItem")!
  const _component_PxIcon = _resolveComponent("PxIcon")!

  return (_openBlock(), _createElementBlock("ul", {
    class: _normalizeClass([`milestone-plan-onboarding-dialog__content--first-page-${_ctx.userType}`, "milestone-plan-onboarding-dialog__content--first-page"])
  }, [
    (!_ctx.isVisitor)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _createElementVNode("li", null, [
            _createVNode(_component_i18n_t, {
              keypath: `milestonePlanner.milestonePlan.onboardingModal.${_ctx.userType}.content.firstTab.0`,
              tag: "p"
            }, {
              planTab: _withCtx(() => [
                _createElementVNode("strong", {
                  textContent: _toDisplayString(_ctx.onboardingCopy.planTab)
                }, null, 8, _hoisted_1)
              ]),
              _: 1
            }, 8, ["keypath"])
          ]),
          _createElementVNode("li", null, [
            _createVNode(_component_i18n_t, {
              keypath: `milestonePlanner.milestonePlan.onboardingModal.${_ctx.userType}.content.firstTab.1`,
              tag: "p"
            }, {
              breakText: _withCtx(() => [
                _createVNode(_component_i18n_t, {
                  keypath: `milestonePlanner.milestonePlan.onboardingModal.${_ctx.userType}.content.firstTab.2`,
                  tag: "p"
                }, {
                  optionalStep: _withCtx(() => [
                    _createElementVNode("strong", {
                      textContent: _toDisplayString(_ctx.onboardingCopy.optionalStep)
                    }, null, 8, _hoisted_2)
                  ]),
                  _: 1
                }, 8, ["keypath"])
              ]),
              _: 1
            }, 8, ["keypath"])
          ]),
          _createElementVNode("li", null, [
            _createVNode(_component_i18n_t, {
              keypath: `milestonePlanner.milestonePlan.onboardingModal.${_ctx.userType}.content.firstTab.3`,
              tag: "p"
            }, {
              firstIcon: _withCtx(() => [
                _createVNode(_component_PxButton, _normalizeProps(_guardReactiveProps(_ctx.saveAsDraftButtonProps)), null, 16)
              ]),
              secondIcon: _withCtx(() => [
                _createVNode(_component_PxButton, _normalizeProps(_guardReactiveProps(_ctx.submitPlanButtonProps)), null, 16)
              ]),
              _: 1
            }, 8, ["keypath"])
          ]),
          _createElementVNode("li", null, [
            _createVNode(_component_i18n_t, {
              keypath: `milestonePlanner.milestonePlan.onboardingModal.${_ctx.userType}.content.firstTab.4`,
              tag: "p"
            }, {
              firstIcon: _withCtx(() => [
                _createVNode(_component_MilestoneStageStatusItem, _mergeProps(_ctx.toBePlannedIconProps, {
                  "is-clickable": false,
                  class: "to-be-planned-milestone"
                }), null, 16)
              ]),
              secondIcon: _withCtx(() => [
                _createVNode(_component_MilestoneStageStatusItem, _mergeProps(_ctx.plannedIconProps, {
                  "is-clickable": false,
                  class: "planned-milestone"
                }), null, 16)
              ]),
              thirdIcon: _withCtx(() => [
                _createVNode(_component_PxIcon, {
                  size: 16,
                  class: "milestone-plan-onboarding-dialog__content--filtered-icon",
                  name: "plan--planned"
                })
              ]),
              _: 1
            }, 8, ["keypath"])
          ])
        ], 64))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          _createElementVNode("li", null, [
            _createVNode(_component_i18n_t, {
              keypath: `milestonePlanner.milestonePlan.onboardingModal.${_ctx.userType}.content.firstTab.0`,
              tag: "p"
            }, {
              planTab: _withCtx(() => [
                _createElementVNode("strong", {
                  textContent: _toDisplayString(_ctx.onboardingCopy.planTab)
                }, null, 8, _hoisted_3)
              ]),
              _: 1
            }, 8, ["keypath"])
          ]),
          _createElementVNode("li", null, [
            _createVNode(_component_i18n_t, {
              keypath: `milestonePlanner.milestonePlan.onboardingModal.${_ctx.userType}.content.firstTab.1`,
              tag: "p"
            }, {
              breakText: _withCtx(() => [
                _createVNode(_component_i18n_t, {
                  keypath: `milestonePlanner.milestonePlan.onboardingModal.${_ctx.userType}.content.firstTab.2`,
                  tag: "p"
                }, {
                  optionalStep: _withCtx(() => [
                    _createElementVNode("strong", {
                      textContent: _toDisplayString(_ctx.onboardingCopy.optionalStep)
                    }, null, 8, _hoisted_4)
                  ]),
                  _: 1
                }, 8, ["keypath"])
              ]),
              _: 1
            }, 8, ["keypath"])
          ]),
          _createElementVNode("li", null, [
            _createVNode(_component_i18n_t, {
              keypath: `milestonePlanner.milestonePlan.onboardingModal.${_ctx.userType}.content.firstTab.3`,
              tag: "p"
            }, {
              toBePlannedIcon: _withCtx(() => [
                _createVNode(_component_MilestoneStageStatusItem, _mergeProps(_ctx.toBePlannedIconProps, {
                  "is-clickable": false,
                  class: "to-be-planned-milestone"
                }), null, 16)
              ]),
              breakText: _withCtx(() => [
                _createVNode(_component_i18n_t, {
                  keypath: `milestonePlanner.milestonePlan.onboardingModal.${_ctx.userType}.content.firstTab.4`,
                  tag: "p"
                }, {
                  plannedIcon: _withCtx(() => [
                    _createVNode(_component_MilestoneStageStatusItem, _mergeProps(_ctx.plannedIconProps, {
                      "is-clickable": false,
                      class: "planned-milestone"
                    }), null, 16)
                  ]),
                  planIcon: _withCtx(() => [
                    _createVNode(_component_PxIcon, {
                      size: 16,
                      class: "milestone-plan-onboarding-dialog__content--filtered-icon",
                      name: "plan--planned"
                    })
                  ]),
                  _: 1
                }, 8, ["keypath"])
              ]),
              _: 1
            }, 8, ["keypath"])
          ])
        ], 64))
  ], 2))
}