import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-2372379f"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "capital-explorer-for-visitors" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AuthenticatedWithNavigationView = _resolveComponent("AuthenticatedWithNavigationView")!
  const _component_VisitorsGuard = _resolveComponent("VisitorsGuard")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.hasRetrievedData)
      ? (_openBlock(), _createBlock(_component_AuthenticatedWithNavigationView, { key: 0 }))
      : (_openBlock(), _createBlock(_component_VisitorsGuard, {
          key: 1,
          "requires-passcode": _ctx.requiresPasscode
        }, null, 8, ["requires-passcode"]))
  ]))
}