<template>
  <div class="submission-panel__supporter-contacts">
    <div class="submission-panel-row">
      <h4 class="submission-panel-caption">
        {{ $t("profile.company.email") }}
      </h4>
      <div class="submission-panel-data">
        <span v-if="!hasEmail" class="submission-panel-data__field--empty">
          {{ $t("program.submission.company.emptyState") }}
        </span>
        <a
          v-else
          :href="`mailto:${email}`"
          class="el-button el-button--link el-button--link-dark"
        >
          <span>
            {{ email }}
          </span>
        </a>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    email: {
      type: String,
      required: true,
    },
  },

  computed: {
    hasEmail(): boolean {
      return !!this.email;
    },
  },
});
</script>

<style lang="scss" scoped>
.submission-panel-data__field--empty {
  @include grotesk(regular, italic);

  font-size: to-rem(15px);
  line-height: 25px;
  letter-spacing: -0.2px;
}
</style>
