import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-578add25"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "profile-mobile" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MatchingRibbon = _resolveComponent("MatchingRibbon")!
  const _component_CompanyPanel = _resolveComponent("CompanyPanel")!
  const _component_AffiliateLinkPanel = _resolveComponent("AffiliateLinkPanel")!
  const _component_NetworksPanel = _resolveComponent("NetworksPanel")!
  const _component_SupporterInfoPanel = _resolveComponent("SupporterInfoPanel")!
  const _component_VWindowItem = _resolveComponent("VWindowItem")!
  const _component_InvestmentLevelPanel = _resolveComponent("InvestmentLevelPanel")!
  const _component_SupporterCriteriaPanel = _resolveComponent("SupporterCriteriaPanel")!
  const _component_AssessmentPanelMobile = _resolveComponent("AssessmentPanelMobile")!
  const _component_InvestorsPanel = _resolveComponent("InvestorsPanel")!
  const _component_VWindow = _resolveComponent("VWindow")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_VWindow, {
      modelValue: _ctx.selectedView,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedView) = $event))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_VWindowItem, { value: 'about' }, {
          default: _withCtx(() => [
            (_ctx.hasMatching)
              ? (_openBlock(), _createBlock(_component_MatchingRibbon, { key: 0 }))
              : _createCommentVNode("", true),
            _createVNode(_component_CompanyPanel, { class: "profile-mobile__about-window" }),
            (_ctx.hasAffiliateLink)
              ? (_openBlock(), _createBlock(_component_AffiliateLinkPanel, { key: 1 }))
              : _createCommentVNode("", true),
            (_ctx.$profile.isSupporter())
              ? (_openBlock(), _createBlock(_component_NetworksPanel, { key: 2 }))
              : _createCommentVNode("", true),
            (_ctx.$profile.isSupporter())
              ? (_openBlock(), _createBlock(_component_SupporterInfoPanel, { key: 3 }))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }),
        (_ctx.$profile.isSupporter())
          ? (_openBlock(), _createBlock(_component_VWindowItem, {
              key: 0,
              value: 'criteria'
            }, {
              default: _withCtx(() => [
                (_ctx.hasMatching)
                  ? (_openBlock(), _createBlock(_component_MatchingRibbon, { key: 0 }))
                  : _createCommentVNode("", true),
                _createVNode(_component_InvestmentLevelPanel),
                (_ctx.$user.isLogged())
                  ? (_openBlock(), _createBlock(_component_SupporterCriteriaPanel, { key: 1 }))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        (_ctx.$profile.isEntrepreneur())
          ? (_openBlock(), _createBlock(_component_VWindowItem, {
              key: 1,
              value: 'viral'
            }, {
              default: _withCtx(() => [
                (_ctx.hasMatching)
                  ? (_openBlock(), _createBlock(_component_MatchingRibbon, { key: 0 }))
                  : _createCommentVNode("", true),
                _createVNode(_component_AssessmentPanelMobile)
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        (_ctx.$profile.isEntrepreneur())
          ? (_openBlock(), _createBlock(_component_VWindowItem, {
              key: 2,
              value: 'expectations'
            }, {
              default: _withCtx(() => [
                (_ctx.hasMatching)
                  ? (_openBlock(), _createBlock(_component_MatchingRibbon, { key: 0 }))
                  : _createCommentVNode("", true),
                _createVNode(_component_InvestorsPanel)
              ]),
              _: 1
            }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["modelValue"])
  ]))
}