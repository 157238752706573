import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, renderList as _renderList, createBlock as _createBlock, withModifiers as _withModifiers, mergeProps as _mergeProps, createTextVNode as _createTextVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-38b6f08e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "submission-company-panel" }
const _hoisted_2 = { class: "submission-company__left-wrapper" }
const _hoisted_3 = { class: "submission-company__grid-container" }
const _hoisted_4 = { class: "submission-company__field-container" }
const _hoisted_5 = { class: "submission-company__title" }
const _hoisted_6 = { class: "submission-company__subtitle" }
const _hoisted_7 = {
  key: 0,
  class: "submission-company__field-container"
}
const _hoisted_8 = {
  key: 1,
  class: "submission-company__field-container"
}
const _hoisted_9 = { class: "submission-company__field-container submission-company__field-tags" }
const _hoisted_10 = { class: "submission-company__field-caption" }
const _hoisted_11 = { class: "submission-company__field-container" }
const _hoisted_12 = { class: "submission-company__field-caption" }
const _hoisted_13 = { class: "submission-company__field-content" }
const _hoisted_14 = { key: 0 }
const _hoisted_15 = {
  key: 1,
  class: "submission-company__field-content--empty"
}
const _hoisted_16 = { class: "submission-company__right-wrapper" }
const _hoisted_17 = { class: "submission-company__grid-container" }
const _hoisted_18 = {
  key: 0,
  class: "submission-company__field-container"
}
const _hoisted_19 = {
  key: 1,
  class: "submission-company__field-container"
}
const _hoisted_20 = { class: "submission-company__field-container" }
const _hoisted_21 = { class: "submission-company__field-caption" }
const _hoisted_22 = { class: "submission-company__field-content" }
const _hoisted_23 = ["href"]
const _hoisted_24 = {
  key: 1,
  class: "submission-company__field-content--empty"
}
const _hoisted_25 = { class: "submission-company__field-container" }
const _hoisted_26 = { class: "submission-company__field-caption" }
const _hoisted_27 = { class: "submission-company__field-content" }
const _hoisted_28 = {
  key: 0,
  class: "submission-company__field-content--empty"
}
const _hoisted_29 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PxMatchingInterest = _resolveComponent("PxMatchingInterest")!
  const _component_PxMatchingInterestTip = _resolveComponent("PxMatchingInterestTip")!
  const _component_MatchingRibbon = _resolveComponent("MatchingRibbon")!
  const _component_ElTag = _resolveComponent("ElTag")!
  const _component_PxButton = _resolveComponent("PxButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", null, [
            _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.company.name), 1),
            _createElementVNode("span", {
              class: "submission-company__profile-link",
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.goToProfile && _ctx.goToProfile(...args)))
            }, _toDisplayString(_ctx.$t("program.submission.seeProfile")), 1)
          ]),
          _createElementVNode("h3", _hoisted_6, _toDisplayString(_ctx.formattedLocation), 1)
        ]),
        (_ctx.$screen.smDown)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              (_ctx.userCanMatch && _ctx.$features.isEnabled('match'))
                ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                    _createVNode(_component_PxMatchingInterest, {
                      id: _ctx.company.id,
                      name: _ctx.company.name,
                      "with-description": false
                    }, null, 8, ["id", "name"]),
                    _createVNode(_component_PxMatchingInterestTip, {
                      id: _ctx.company.id,
                      name: _ctx.company.name
                    }, null, 8, ["id", "name"])
                  ]))
                : _createCommentVNode("", true),
              (_ctx.hasMatching)
                ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                    _createVNode(_component_MatchingRibbon, {
                      type: _ctx.$options.static.matchingRibbonType
                    }, null, 8, ["type"])
                  ]))
                : _createCommentVNode("", true)
            ], 64))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_9, [
          _createElementVNode("h4", _hoisted_10, _toDisplayString(_ctx.$t("program.submission.company.sector.title")), 1),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.company.sectors, (sector) => {
            return (_openBlock(), _createBlock(_component_ElTag, {
              key: sector.id,
              class: "text--capitalize",
              effect: "plain",
              textContent: _toDisplayString(sector.name)
            }, null, 8, ["textContent"]))
          }), 128))
        ]),
        _createElementVNode("div", _hoisted_11, [
          _createElementVNode("h4", _hoisted_12, _toDisplayString(_ctx.$t("program.submission.company.about.title")), 1),
          _createElementVNode("div", _hoisted_13, [
            (_ctx.company.about)
              ? (_openBlock(), _createElementBlock("span", _hoisted_14, _toDisplayString(_ctx.company.about), 1))
              : (_openBlock(), _createElementBlock("span", _hoisted_15, _toDisplayString(_ctx.$t("program.submission.company.emptyState")), 1))
          ])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_16, [
      _createElementVNode("div", _hoisted_17, [
        (_ctx.$screen.smUp)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              (_ctx.userCanMatch)
                ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
                    (_ctx.$features.isEnabled('match'))
                      ? (_openBlock(), _createBlock(_component_PxMatchingInterest, {
                          key: 0,
                          id: _ctx.company.id,
                          name: _ctx.company.name,
                          "with-description": false
                        }, null, 8, ["id", "name"]))
                      : _createCommentVNode("", true),
                    (_ctx.showAddToListButton)
                      ? (_openBlock(), _createBlock(_component_PxButton, _mergeProps({
                          key: 1,
                          class: "submission-company__cta--addToList"
                        }, _ctx.addToListButtonProps, {
                          onClick: _withModifiers(_ctx.showListModal, ["stop"])
                        }), null, 16, ["onClick"]))
                      : _createCommentVNode("", true),
                    _createVNode(_component_PxMatchingInterestTip, {
                      id: _ctx.company.id,
                      name: _ctx.company.name
                    }, null, 8, ["id", "name"])
                  ]))
                : _createCommentVNode("", true),
              (_ctx.hasMatching)
                ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
                    _createVNode(_component_MatchingRibbon, {
                      type: _ctx.$options.static.matchingRibbonType
                    }, null, 8, ["type"])
                  ]))
                : _createCommentVNode("", true)
            ], 64))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_20, [
          _createElementVNode("h4", _hoisted_21, _toDisplayString(_ctx.$t("program.submission.company.website.title")), 1),
          _createElementVNode("div", _hoisted_22, [
            (_ctx.company.website)
              ? (_openBlock(), _createElementBlock("a", {
                  key: 0,
                  href: _ctx.companyWebsite,
                  class: "el-button el-button--link el-button--link-dark",
                  target: "_blank"
                }, [
                  _createElementVNode("span", null, _toDisplayString(_ctx.company.website), 1)
                ], 8, _hoisted_23))
              : (_openBlock(), _createElementBlock("span", _hoisted_24, _toDisplayString(_ctx.$t("program.submission.company.emptyState")), 1))
          ])
        ]),
        _createElementVNode("div", _hoisted_25, [
          _createElementVNode("h4", _hoisted_26, _toDisplayString(_ctx.$t("program.submission.company.email.title")), 1),
          _createElementVNode("div", _hoisted_27, [
            (!_ctx.company.email)
              ? (_openBlock(), _createElementBlock("span", _hoisted_28, _toDisplayString(_ctx.$t("program.submission.company.emptyState")), 1))
              : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                  (_ctx.isPublicView)
                    ? (_openBlock(), _createBlock(_component_PxButton, {
                        key: 0,
                        class: "el-button--link el-button--link-green",
                        type: "link",
                        onClick: _ctx.onClickSignUp
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t("program.submission.company.email.visitor")), 1)
                        ]),
                        _: 1
                      }, 8, ["onClick"]))
                    : (_openBlock(), _createElementBlock("a", {
                        key: 1,
                        href: `mailto:${_ctx.company.email}`,
                        class: "el-button el-button--link el-button--link-dark"
                      }, [
                        _createElementVNode("span", null, _toDisplayString(_ctx.company.email), 1)
                      ], 8, _hoisted_29))
                ], 64))
          ])
        ])
      ])
    ])
  ]))
}