import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-3fa11d53"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "milestone-detail page-full-height" }
const _hoisted_2 = { class: "page-body" }
const _hoisted_3 = {
  key: 0,
  class: "page-wrapper"
}
const _hoisted_4 = { class: "page-container milestone-detail__top" }
const _hoisted_5 = { class: "page-container milestone-detail__page-container" }
const _hoisted_6 = { class: "milestone-detail__panel" }
const _hoisted_7 = {
  key: 0,
  class: "milestone-detail__navigation-bar"
}
const _hoisted_8 = {
  key: 1,
  class: "milestone-detail__main"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PxButton = _resolveComponent("PxButton")!
  const _component_MilestonePlanNavigationBar = _resolveComponent("MilestonePlanNavigationBar")!
  const _component_MilestonePlanHeader = _resolveComponent("MilestonePlanHeader")!
  const _component_MilestoneForm = _resolveComponent("MilestoneForm")!
  const _component_MilestoneFormFooter = _resolveComponent("MilestoneFormFooter")!
  const _component_PxPanel = _resolveComponent("PxPanel")!
  const _component_MilestonePlanOnboardingDialog = _resolveComponent("MilestonePlanOnboardingDialog")!
  const _component_MatchingConfirmModal = _resolveComponent("MatchingConfirmModal")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_2, [
      (!_ctx.isLoadingView)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_PxButton, _mergeProps(_ctx.backToOverviewButton, { onClick: _ctx.goToOverviewPage }), null, 16, ["onClick"]),
              _createVNode(_component_PxButton, _mergeProps(_ctx.openDialogButtonCopy, {
                class: "milestone-detail__button",
                onClick: _ctx.openOnboardingDialog
              }), null, 16, ["onClick"])
            ]),
            _createElementVNode("div", _hoisted_5, [
              _createVNode(_component_PxPanel, null, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_6, [
                    (_ctx.selectedCategory)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                          _createVNode(_component_MilestonePlanNavigationBar, {
                            "active-level": _ctx.selectedLevel,
                            "onUpdate:activeLevel": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedLevel) = $event)),
                            "current-category": _ctx.selectedCategory,
                            "onUpdate:currentCategory": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectedCategory) = $event)),
                            categories: _ctx.gridCategories
                          }, null, 8, ["active-level", "current-category", "categories"])
                        ]))
                      : _createCommentVNode("", true),
                    (_ctx.selectedCategory && _ctx.selectedGridMilestone)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                          _createVNode(_component_MilestonePlanHeader, {
                            "category-color": _ctx.selectedCategory.color,
                            "category-description": _ctx.selectedGridMilestone.description,
                            "category-name": _ctx.selectedCategory.name,
                            "has-evidence": _ctx.selectedGridMilestone.evidenceProvided,
                            "is-future-milestone": _ctx.selectedGridMilestone.future,
                            "is-milestone-complete": _ctx.selectedGridMilestone.completed,
                            "is-milestone-critical": _ctx.selectedGridMilestone.critical,
                            "is-milestone-in-progress": _ctx.selectedGridMilestone.inProgress,
                            "is-milestone-planned": _ctx.selectedGridMilestone.planned,
                            "level-number": _ctx.selectedLevel,
                            "milestone-planner": _ctx.defaultMilestonePlanner,
                            "target-date": _ctx.selectedGridMilestone.targetDate
                          }, null, 8, ["category-color", "category-description", "category-name", "has-evidence", "is-future-milestone", "is-milestone-complete", "is-milestone-critical", "is-milestone-in-progress", "is-milestone-planned", "level-number", "milestone-planner", "target-date"]),
                          _withDirectives(_createVNode(_component_MilestoneForm, {
                            "active-tab": _ctx.activeTab,
                            "onUpdate:activeTab": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.activeTab) = $event)),
                            "complete-form-has-changes": _ctx.completeFormHasChanges,
                            "onUpdate:completeFormHasChanges": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.completeFormHasChanges) = $event)),
                            "complete-form-is-invalid": _ctx.completeFormIsInvalid,
                            "onUpdate:completeFormIsInvalid": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.completeFormIsInvalid) = $event)),
                            "plan-form-has-changes": _ctx.planFormHasChanges,
                            "onUpdate:planFormHasChanges": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.planFormHasChanges) = $event)),
                            "plan-form-is-complete": _ctx.planFormIsComplete,
                            "onUpdate:planFormIsComplete": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.planFormIsComplete) = $event)),
                            "plan-form-is-invalid": _ctx.planFormIsInvalid,
                            "onUpdate:planFormIsInvalid": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.planFormIsInvalid) = $event)),
                            category: _ctx.selectedCategory,
                            "category-level": _ctx.selectedCategoryLevel,
                            "complete-last-update": _ctx.completeLastUpdate,
                            "has-completion-info": _ctx.selectedGridMilestone.hasCompletionInfo,
                            "is-future-milestone": _ctx.selectedGridMilestone.future,
                            "is-milestone-complete": _ctx.selectedGridMilestone.completed,
                            "is-milestone-planned": _ctx.milestoneHasPlan,
                            "is-plan-published": _ctx.selectedGridMilestone.planned,
                            milestone: _ctx.currentMilestone,
                            "plan-last-update": _ctx.planLastUpdate,
                            "question-bundles": _ctx.currentQuestionBundles
                          }, null, 8, ["active-tab", "complete-form-has-changes", "complete-form-is-invalid", "plan-form-has-changes", "plan-form-is-complete", "plan-form-is-invalid", "category", "category-level", "complete-last-update", "has-completion-info", "is-future-milestone", "is-milestone-complete", "is-milestone-planned", "is-plan-published", "milestone", "plan-last-update", "question-bundles"]), [
                            [_directive_loading, _ctx.isLoadingForm]
                          ]),
                          _createVNode(_component_MilestoneFormFooter, {
                            "above-milestones-completed": _ctx.hasMilestonesCompletedAbove,
                            "active-tab": _ctx.activeTab,
                            "below-milestones-not-completed": 
                    _ctx.hasMilestonesNotCompletedBelow
                  ,
                            "complete-form-has-changes": _ctx.completeFormHasChanges,
                            "complete-form-is-invalid": _ctx.completeFormIsInvalid,
                            "has-completion-info": _ctx.selectedGridMilestone.hasCompletionInfo,
                            "has-plan": _ctx.milestoneHasPlan,
                            "is-complete-published": _ctx.selectedGridMilestone.completed,
                            "is-future-milestone": _ctx.selectedGridMilestone.future,
                            "is-plan-published": _ctx.selectedGridMilestone.planned,
                            "plan-form-has-changes": _ctx.planFormHasChanges,
                            "plan-form-is-complete": _ctx.planFormIsComplete,
                            "plan-form-is-invalid": _ctx.planFormIsInvalid,
                            "user-adds-evidence": false
                          }, null, 8, ["above-milestones-completed", "active-tab", "below-milestones-not-completed", "complete-form-has-changes", "complete-form-is-invalid", "has-completion-info", "has-plan", "is-complete-published", "is-future-milestone", "is-plan-published", "plan-form-has-changes", "plan-form-is-complete", "plan-form-is-invalid"])
                        ]))
                      : _createCommentVNode("", true)
                  ])
                ]),
                _: 1
              })
            ])
          ]))
        : _createCommentVNode("", true)
    ])), [
      [_directive_loading, _ctx.isLoadingView]
    ]),
    _createVNode(_component_MilestonePlanOnboardingDialog, {
      visibility: _ctx.onboardingDialogVisibility,
      "onUpdate:visibility": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.onboardingDialogVisibility) = $event)),
      onCloseDialog: _ctx.closeOnboardingDialog
    }, null, 8, ["visibility", "onCloseDialog"]),
    (_ctx.isMatchingConfirmModalVisible)
      ? (_openBlock(), _createBlock(_component_MatchingConfirmModal, {
          key: 0,
          visibility: _ctx.isMatchingConfirmModalVisible,
          "onUpdate:visibility": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.isMatchingConfirmModalVisible) = $event))
        }, null, 8, ["visibility"]))
      : _createCommentVNode("", true)
  ]))
}