import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, withModifiers as _withModifiers, mergeProps as _mergeProps, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "user-responses-table" }
const _hoisted_2 = { class: "user-responses-table__table" }
const _hoisted_3 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PxButton = _resolveComponent("PxButton")!
  const _component_MatchingCardTagList = _resolveComponent("MatchingCardTagList")!
  const _component_ElDialog = _resolveComponent("ElDialog")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: _normalizeClass([{
          'user-responses-table__headers--no-results': !_ctx.allQuestionsAnswered,
        }, "user-responses-table__headers"])
      }, [
        _createElementVNode("div", null, _toDisplayString(_ctx.$t("capitalExplorer.detailsModal.tableHeader[0].title")), 1),
        _createElementVNode("div", null, _toDisplayString(_ctx.answerHeaderCopy), 1)
      ], 2),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tableData, (data, index) => {
        return (_openBlock(), _createElementBlock("div", {
          key: index,
          class: "user-responses-table__content"
        }, [
          _createElementVNode("div", null, [
            _createElementVNode("p", null, _toDisplayString(data.question), 1),
            (data.instructions)
              ? (_openBlock(), _createBlock(_component_PxButton, _mergeProps({
                  key: 0,
                  class: "user-responses-table__learn-more",
                  ref_for: true
                }, _ctx.learnMoreButtonProps, {
                  onClick: _withModifiers(($event: any) => (_ctx.showLearnMoreModal(data.question, data.instructions)), ["stop"])
                }), null, 16, ["onClick"]))
              : _createCommentVNode("", true)
          ]),
          (data.response.length)
            ? (_openBlock(), _createBlock(_component_MatchingCardTagList, {
                key: 0,
                capitalize: false,
                items: data.response,
                "list-format": true,
                "max-items-to-display": 2,
                "should-sort-array": false,
                title: _ctx.answerHeaderCopy
              }, null, 8, ["items", "title"]))
            : (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createElementVNode("ul", null, [
                  _createElementVNode("li", null, [
                    _createElementVNode("i", null, _toDisplayString(_ctx.$t("common.noAnswer")), 1)
                  ])
                ])
              ]))
        ]))
      }), 128))
    ]),
    (_ctx.learnMoreModalVisible)
      ? (_openBlock(), _createBlock(_component_ElDialog, {
          key: 0,
          modelValue: _ctx.learnMoreModalVisible,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.learnMoreModalVisible) = $event)),
          "append-to-body": true,
          title: _ctx.learnMoreModalTitle,
          class: "user-responses-table__modal",
          top: "0"
        }, {
          default: _withCtx(() => [
            _createElementVNode("p", null, _toDisplayString(_ctx.learnMoreModalText), 1)
          ]),
          _: 1
        }, 8, ["modelValue", "title"]))
      : _createCommentVNode("", true)
  ]))
}