import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DialogConfirmation = _resolveComponent("DialogConfirmation")!
  const _component_PxModalMessage = _resolveComponent("PxModalMessage")!
  const _component_CompanyEditModalEntrepreneur = _resolveComponent("CompanyEditModalEntrepreneur")!
  const _component_CompanyEditModalSupporter = _resolveComponent("CompanyEditModalSupporter")!
  const _component_PxButton = _resolveComponent("PxButton")!
  const _component_ElDialog = _resolveComponent("ElDialog")!

  return (_openBlock(), _createBlock(_component_ElDialog, {
    ref: "modal",
    modelValue: _ctx.innerVisibility,
    "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.innerVisibility) = $event)),
    "before-close": _ctx.beforeCloseHandler,
    title: _ctx.$t(`profile.companyEditModal.title[${_ctx.$profile.type()}]`),
    class: "company-edit-modal el-dialog--px-10",
    width: "903px",
    onClose: _ctx.closeHandler
  }, {
    footer: _withCtx(() => [
      _createElementVNode("div", null, [
        _createVNode(_component_PxButton, {
          size: "small",
          type: "link",
          onClick: _ctx.beforeCloseHandler
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t("common.cancel")), 1)
          ]),
          _: 1
        }, 8, ["onClick"]),
        _createVNode(_component_PxButton, {
          disabled: _ctx.submissionDisabled,
          loading: _ctx.isLoadingSubmission || _ctx.isLoadingCompany,
          type: "green",
          onClick: _ctx.onClickSave
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t("common.saveChanges")), 1)
          ]),
          _: 1
        }, 8, ["disabled", "loading", "onClick"])
      ])
    ]),
    default: _withCtx(() => [
      _createVNode(_component_DialogConfirmation, { ref: "dialog-confirmation" }, null, 512),
      _createVNode(_component_PxModalMessage, { visible: _ctx.hasServerErrors }, null, 8, ["visible"]),
      (_ctx.$profile.isEntrepreneur())
        ? (_openBlock(), _createBlock(_component_CompanyEditModalEntrepreneur, {
            key: 0,
            ref: "modalForm",
            "form-changed": _ctx.hasFormChanged,
            "onUpdate:formChanged": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.hasFormChanged) = $event)),
            "form-submit": _ctx.clickedSaveChanges,
            "onUpdate:formSubmit": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.clickedSaveChanges) = $event)),
            "loading-submission": _ctx.isLoadingSubmission,
            "onUpdate:loadingSubmission": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.isLoadingSubmission) = $event)),
            "modal-visibility": _ctx.innerVisibility,
            "onUpdate:modalVisibility": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.innerVisibility) = $event)),
            "reset-form": _ctx.resetFormData,
            "onUpdate:resetForm": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.resetFormData) = $event)),
            "server-errors": _ctx.hasServerErrors,
            "onUpdate:serverErrors": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.hasServerErrors) = $event)),
            "submission-disabled": _ctx.submissionDisabled,
            "onUpdate:submissionDisabled": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.submissionDisabled) = $event)),
            company: _ctx.company
          }, null, 8, ["form-changed", "form-submit", "loading-submission", "modal-visibility", "reset-form", "server-errors", "submission-disabled", "company"]))
        : _createCommentVNode("", true),
      (_ctx.$profile.isSupporter())
        ? (_openBlock(), _createBlock(_component_CompanyEditModalSupporter, {
            key: 1,
            ref: "modalForm",
            "form-changed": _ctx.hasFormChanged,
            "onUpdate:formChanged": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.hasFormChanged) = $event)),
            "form-submit": _ctx.clickedSaveChanges,
            "onUpdate:formSubmit": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.clickedSaveChanges) = $event)),
            "loading-submission": _ctx.isLoadingSubmission,
            "onUpdate:loadingSubmission": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.isLoadingSubmission) = $event)),
            "modal-visibility": _ctx.innerVisibility,
            "onUpdate:modalVisibility": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.innerVisibility) = $event)),
            "reset-form": _ctx.resetFormData,
            "onUpdate:resetForm": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.resetFormData) = $event)),
            "selected-view": _ctx.selectedView,
            "onUpdate:selectedView": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.selectedView) = $event)),
            "server-errors": _ctx.hasServerErrors,
            "onUpdate:serverErrors": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.hasServerErrors) = $event)),
            "submission-disabled": _ctx.submissionDisabled,
            "onUpdate:submissionDisabled": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.submissionDisabled) = $event)),
            company: _ctx.company
          }, null, 8, ["form-changed", "form-submit", "loading-submission", "modal-visibility", "reset-form", "selected-view", "server-errors", "submission-disabled", "company"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["modelValue", "before-close", "title", "onClose"]))
}