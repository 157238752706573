import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, withCtx as _withCtx, createSlots as _createSlots, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-79471bc6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PxIcon = _resolveComponent("PxIcon")!
  const _component_ElButton = _resolveComponent("ElButton")!

  return (_openBlock(), _createBlock(_component_ElButton, {
    class: _normalizeClass({
      'is-compact': _ctx.isCompact,
      'is-fluid': _ctx.isFluid,
      'icon-right': _ctx.iconPosition === 'right',
      [`el-button--${_ctx.size}`]: true,
      [`el-button--${_ctx.type}`]: true,
      'px-button': true,
    }),
    disabled: _ctx.disabled,
    loading: _ctx.loading
  }, _createSlots({ _: 2 }, [
    (!!_ctx.icon)
      ? {
          name: "icon",
          fn: _withCtx(() => [
            _createVNode(_component_PxIcon, {
              name: _ctx.icon,
              "use-inline-style": false
            }, null, 8, ["name"])
          ]),
          key: "0"
        }
      : undefined,
    (!_ctx.isCompact)
      ? {
          name: "default",
          fn: _withCtx(() => [
            (_ctx.label)
              ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(_ctx.label), 1))
              : _createCommentVNode("", true),
            _renderSlot(_ctx.$slots, "default", {}, undefined, true)
          ]),
          key: "1"
        }
      : undefined
  ]), 1032, ["class", "disabled", "loading"]))
}