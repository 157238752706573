import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-f6c01444"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "submission-panel__supporter-interests" }
const _hoisted_2 = { class: "submission-panel-row submission-panel__tags" }
const _hoisted_3 = { class: "submission-panel-caption" }
const _hoisted_4 = { class: "submission-panel__tags-wrapper" }
const _hoisted_5 = {
  key: 0,
  class: "el-tag__subtitle"
}
const _hoisted_6 = {
  key: 0,
  class: "el-tag__subtitle"
}
const _hoisted_7 = { key: 0 }
const _hoisted_8 = {
  key: 1,
  class: "submission-panel__label-wrapper"
}
const _hoisted_9 = { key: 0 }
const _hoisted_10 = { key: 1 }
const _hoisted_11 = { class: "submission-panel-row submission-panel__tags" }
const _hoisted_12 = { class: "submission-panel-caption" }
const _hoisted_13 = {
  key: 0,
  class: "el-tag__subtitle"
}
const _hoisted_14 = { key: 0 }
const _hoisted_15 = {
  key: 1,
  class: "submission-panel__label-wrapper"
}
const _hoisted_16 = { key: 0 }
const _hoisted_17 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ElTag = _resolveComponent("ElTag")!
  const _component_PxButton = _resolveComponent("PxButton")!
  const _component_PxMoreList = _resolveComponent("PxMoreList")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h4", _hoisted_3, _toDisplayString(_ctx.supporterSectorsTitle), 1),
      _createElementVNode("div", _hoisted_4, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.limitVisible(_ctx.supporterSectorsOfInterest), (sector, index) => {
          return (_openBlock(), _createBlock(_component_ElTag, {
            key: index,
            class: "text--capitalize",
            effect: "plain"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(sector.name) + " ", 1),
              (sector.items)
                ? (_openBlock(), _createElementBlock("span", _hoisted_5, " (" + _toDisplayString(sector.items.length) + ") ", 1))
                : _createCommentVNode("", true)
            ]),
            _: 2
          }, 1024))
        }), 128)),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.selectedPrintableValue, (sector, index) => {
          return (_openBlock(), _createBlock(_component_ElTag, {
            key: index,
            class: "text--capitalize submission-panel__printable-values",
            effect: "plain"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(sector.name) + " ", 1),
              (sector.items)
                ? (_openBlock(), _createElementBlock("span", _hoisted_6, " (" + _toDisplayString(sector.items.length) + ") ", 1))
                : _createCommentVNode("", true)
            ]),
            _: 2
          }, 1024))
        }), 128))
      ]),
      (_ctx.hasMore(_ctx.supporterSectorsOfInterest))
        ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
            _createVNode(_component_PxMoreList, {
              "modal-title": _ctx.supporterSectorsTitle,
              values: _ctx.getListOfNamesWithCount(_ctx.supporterSectorsOfInterest),
              "values-item-list": _ctx.supporterSectorsOfInterest
            }, {
              content: _withCtx(() => [
                _createVNode(_component_PxButton, {
                  class: "el-button--link el-button--small el-button--link-dark",
                  type: "link"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t("common.seeAll", {
                  number: _ctx.getTotal(_ctx.supporterSectorsOfInterest),
                })), 1)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["modal-title", "values", "values-item-list"])
          ]))
        : _createCommentVNode("", true),
      (!_ctx.supporterSectorsOfInterest.length)
        ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
            (_ctx.$user.isOwner())
              ? (_openBlock(), _createElementBlock("span", _hoisted_9, _toDisplayString(_ctx.$t("profile.company.emptyState.sectorsOfInterest[0]")), 1))
              : (_ctx.$user.isLogged())
                ? (_openBlock(), _createElementBlock("span", _hoisted_10, _toDisplayString(_ctx.$t("profile.company.emptyState.sectorsOfInterest[2]")), 1))
                : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_11, [
      _createElementVNode("h4", _hoisted_12, _toDisplayString(_ctx.supporterLocationsTitle), 1),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.limitVisible(_ctx.supporterLocationsOfInterest), (location, index) => {
        return (_openBlock(), _createElementBlock("div", {
          key: index,
          class: "submission-panel__tags-wrapper"
        }, [
          _createVNode(_component_ElTag, {
            effect: "plain",
            class: "text--capitalize"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(location.name) + " ", 1),
              (location.items)
                ? (_openBlock(), _createElementBlock("span", _hoisted_13, " (" + _toDisplayString(location.items.length) + ") ", 1))
                : _createCommentVNode("", true)
            ]),
            _: 2
          }, 1024)
        ]))
      }), 128)),
      (_ctx.hasMore(_ctx.supporterLocationsOfInterest))
        ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
            _createVNode(_component_PxMoreList, {
              "modal-title": _ctx.supporterLocationsTitle,
              values: _ctx.getListOfNamesWithCount(_ctx.supporterLocationsOfInterest),
              "values-item-list": _ctx.supporterLocationsOfInterest
            }, {
              content: _withCtx(() => [
                _createVNode(_component_PxButton, {
                  class: "el-button--link el-button--small el-button--link-dark",
                  type: "link"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t("common.seeAll", {
                  number: _ctx.getTotal(_ctx.supporterLocationsOfInterest),
                })), 1)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["modal-title", "values", "values-item-list"])
          ]))
        : _createCommentVNode("", true),
      (!_ctx.supporterLocationsOfInterest.length)
        ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
            (_ctx.$user.isOwner())
              ? (_openBlock(), _createElementBlock("span", _hoisted_16, _toDisplayString(_ctx.$t("profile.company.emptyState.locationsOfInterest[0]")), 1))
              : (_ctx.$user.isLogged())
                ? (_openBlock(), _createElementBlock("span", _hoisted_17, _toDisplayString(_ctx.$t("profile.company.emptyState.locationsOfInterest[2]")), 1))
                : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}