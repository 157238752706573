<template>
  <div class="auth-base-signup">
    <div class="page-wrapper">
      <div class="page-container">
        <h1 class="page-headline">
          {{ $t("authentication.signup.startMessage") }}
        </h1>
        <div class="page-block">
          <PxSteps :active="currentStep" :steps="items" />
          <div class="auth-base-signup__step signup-success">
            <PxPanel>
              <div class="auth-base-signup__announcement">
                <div class="auth-base-signup__announcement-icon">
                  <img alt="email sent icon" src="/img/icons/email-sent.svg" />
                </div>
                <h2 class="auth-base-signup__announcement-title">
                  {{ $t("authentication.signup.success.title") }}
                </h2>
                <div
                  class="auth-base-signup__announcement-message"
                  v-html="
                    $t('authentication.signup.success.message', {
                      registrationEmail,
                    })
                  "
                />
              </div>
            </PxPanel>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { EMetaGetters } from "@/services/store/meta/meta-types";
import { AUTHENTICATION_META_REGISTRATION_EMAIL } from "@/modules/authentication/constants";

export default defineComponent({
  name: "AuthBaseSignUpSuccess",

  data() {
    return {
      currentStep: 2,

      items: [
        {
          label: this.$t("authentication.signup.steps.company"),
          value: "company",
        },
        {
          label: this.$t("authentication.signup.steps.account"),
          value: "user",
        },
      ],
    };
  },

  computed: {
    registrationEmail(): string {
      return this.$store.getters[EMetaGetters.GET](
        AUTHENTICATION_META_REGISTRATION_EMAIL,
      );
    },
  },
});
</script>

<style lang="scss" scoped>
.auth-base-signup .page-block {
  margin-top: 15px;

  @include breakpoint-up(md) {
    margin-top: 34px;
  }
}

.auth-base-signup__step {
  margin-top: 31px;

  @include breakpoint-up(md) {
    margin-top: 27px;
  }
}

.auth-base-signup__announcement {
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  justify-content: center;
  padding: 15px 10px;
  text-align: center;

  @include breakpoint-up(md) {
    padding: 34px 40px 31px;
  }

  &-icon {
    max-width: 100%;
  }

  &-title {
    @include grotesk(semiBold);

    margin-bottom: 0;
    font-size: 1.5333rem;
    line-height: 40px;
    color: $malibu;
    letter-spacing: 0;

    @include breakpoint-up(md) {
      font-size: 1.4667rem;
    }
  }

  .auth-base-signup__announcement-icon + .auth-base-signup__announcement-title {
    margin-top: 10px;

    @include breakpoint-up(md) {
      margin-top: 24px;
    }
  }

  &-message {
    margin-top: 14px;
    line-height: 22px;
    letter-spacing: -0.15px;

    @include breakpoint-up(md) {
      margin-top: 19px;
      line-height: 24px;
    }

    strong {
      @include grotesk(semiBold);

      letter-spacing: 0;
    }
  }
}
</style>
