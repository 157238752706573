import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, openBlock as _openBlock, createBlock as _createBlock, mergeProps as _mergeProps, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-52f8d983"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "share-modal-actions" }
const _hoisted_2 = ["textContent"]
const _hoisted_3 = ["textContent"]
const _hoisted_4 = ["textContent"]
const _hoisted_5 = ["textContent"]
const _hoisted_6 = ["textContent"]
const _hoisted_7 = { class: "share-modal-actions__buttons" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_i18n_t = _resolveComponent("i18n-t")!
  const _component_PxButton = _resolveComponent("PxButton")!
  const _component_ClipboardButton = _resolveComponent("ClipboardButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h3", {
      class: "share-modal-actions__title",
      textContent: _toDisplayString(_ctx.actionsCopy.title)
    }, null, 8, _hoisted_2),
    _createVNode(_component_i18n_t, {
      keypath: `${_ctx.copy}.subtitle.content[${_ctx.isRestrictedByPasscode}]`,
      class: "share-modal-actions__subtitle",
      tag: "p"
    }, {
      link: _withCtx(() => [
        _createElementVNode("strong", {
          textContent: _toDisplayString(_ctx.actionsCopy.subtitle.placeholders[0])
        }, null, 8, _hoisted_3)
      ]),
      name: _withCtx(() => [
        _createElementVNode("strong", {
          class: "text--capitalize",
          textContent: _toDisplayString(_ctx.actionsCopy.subtitle.placeholders[1])
        }, null, 8, _hoisted_4)
      ]),
      email: _withCtx(() => [
        _createElementVNode("strong", {
          textContent: _toDisplayString(_ctx.actionsCopy.subtitle.placeholders[2])
        }, null, 8, _hoisted_5)
      ]),
      password: _withCtx(() => [
        _createElementVNode("strong", {
          textContent: _toDisplayString(_ctx.actionsCopy.subtitle.placeholders[3])
        }, null, 8, _hoisted_6)
      ]),
      _: 1
    }, 8, ["keypath"]),
    _createVNode(_component_PxButton, {
      class: "share-modal-actions__link",
      size: "small",
      type: "link",
      onClick: _ctx.onClickSettingHandler
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$t(_ctx.settingsLinkCopy)), 1)
      ]),
      _: 1
    }, 8, ["onClick"]),
    _createElementVNode("div", _hoisted_7, [
      (_ctx.isRestrictedByPasscode)
        ? (_openBlock(), _createBlock(_component_ClipboardButton, _normalizeProps(_mergeProps({ key: 0 }, _ctx.clipboardButtons[0])), null, 16))
        : _createCommentVNode("", true),
      _createVNode(_component_ClipboardButton, _normalizeProps(_guardReactiveProps(_ctx.clipboardButtons[1])), null, 16)
    ])
  ]))
}