import { GenericProvider, Provider } from "@/services/data/generic-provider";
import { ICheckSessionResponse } from "./check-session.interface";
import { baseAPIUrl } from "@/services/utils/utils";

@Provider(`${baseAPIUrl}/auth/validate`)
class CheckSessionProvider extends GenericProvider<ICheckSessionResponse> {
  /**
   * Check if the user has a valid session.
   */
  public async checkSession(): Promise<ICheckSessionResponse> {
    const url = this.buildEndPointUrl();
    const request = this.httpClient.get(url);
    const { data } = await this.wrapRequest(request);

    return data as ICheckSessionResponse;
  }
}

export const checkSessionProvider = new CheckSessionProvider();
