<template>
  <div class="share-modal-settings-link">
    <ElForm ref="form" class="share-modal-settings-link__form">
      <ElFormItem
        :label="settingsCopy.label"
        class="share-modal-settings-link__input-wrapper"
        prop="link"
      >
        <ElInput :disabled="true" :value="link" data-testid="link" />
        <ClipboardButton v-bind="clipboardButton" />
      </ElFormItem>
      <div class="share-modal-settings-link__footer">
        <span
          class="el-form-item__bottom-text el-form-item__tip"
          v-html="settingsCopy.tip"
        />
        <PxButton
          class="share-modal-settings-link__cta"
          v-bind="resetButtonProps"
          @click="clickResetLinkHandler"
        />
      </div>
    </ElForm>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import ClipboardButton from "@/components/clipboard-button/clipboard-button.vue";

import {
  EPxButtonSize,
  EPxButtonType,
} from "@/components/px-button/px-button.types";
import { SHARE_MODAL_EVENTS } from "@/components/share-modal/share-modal.types";

export default defineComponent({
  name: "ShareModalSettingsLink",

  components: {
    ClipboardButton,
  },

  props: {
    link: {
      type: String,
      required: true,
    },

    copy: {
      type: String,
      default: "common.shareModal.settings.link",
    },
  },

  static: {
    resetButton: {
      icon: "link--red",
      size: EPxButtonSize.SMALL,
      type: EPxButtonType.GHOST_RED,
    },
  },

  computed: {
    settingsCopy() {
      return this.$tm(this.copy) as {
        label: string;
        tip: string;
        resetButton: string;
      };
    },

    resetButtonProps(): any {
      return {
        ...this.$options.static.resetButton,
        label: this.settingsCopy.resetButton,
      };
    },

    clipboardButton() {
      return {
        clipboard: this.link,
        buttonLabel: this.$t("common.copyLink"),
        tooltipCopy: this.$t("common.copiedToClipboard"),
      };
    },
  },

  methods: {
    clickResetLinkHandler() {
      this.$emitter.emit("share-modal-action", {
        event: SHARE_MODAL_EVENTS.RESET_SHAREABLE_LINK,
      });
    },
  },
});
</script>

<style lang="scss" scoped>
.share-modal-settings-link {
  &__input-wrapper :deep() {
    position: relative;

    .el-form-item__label {
      padding-top: 2px;
      margin-bottom: 3px;
    }

    .el-input.is-disabled input {
      // Add safe space for CTA overlapping input:
      padding-right: 130px;
      background-color: $alabaster;
      border: solid 1px $mischka;
      box-shadow: inset 0 0 5px 0 rgba(#000, 0.03);
      color: $ebony-clay;
      opacity: 0.7;
    }
  }

  :deep(.clipboard-button) {
    position: absolute;
    right: 4px;
    bottom: 4px;
    padding: 0 13px 0 12px;

    span {
      font-size: 12px;
    }
  }

  &__cta {
    padding: 2px 13px 0 12px;
  }

  &__footer {
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    margin-top: 16px;
    margin-right: 2px;

    .el-form-item__tip {
      flex: 1 1 50%;
      max-width: 330px;
      padding-top: 0;
      line-height: 14px;
      letter-spacing: -0.11px;
    }
  }
}
</style>
