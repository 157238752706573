import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-8b0990f6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "matching-compact-list-header__item" }
const _hoisted_2 = {
  key: 0,
  class: "matching-compact-list-header__item"
}
const _hoisted_3 = { class: "matching-compact-list-header__item" }
const _hoisted_4 = {
  key: 1,
  class: "matching-compact-list-header__item"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([{
      'matching-compact-list-header__container--small': !_ctx.showDescription,
    }, "matching-compact-list-header__container"])
  }, [
    _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.userTypeCopy), 1),
    (_ctx.showDescription)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.$t("common.components.matchingListHeader.description")), 1))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.sectorsCopy), 1),
    (!_ctx.isSameTypeOfUsers)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_ctx.$t("common.components.matchingListHeader.percentage")), 1))
      : _createCommentVNode("", true)
  ], 2))
}