<template>
  <div class="share-planner-users">
    <SharePlannerUsersIndex :users="users" class="share-planner-users__body" />
    <SharePlannerActions
      :link="link"
      :passcode="passcode"
      class="share-planner-users__footer"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import SharePlannerUsersIndex from "@/modules/milestone-planner/components/share-planner/share-planner-users-index.vue";
import SharePlannerActions from "@/modules/milestone-planner/components/share-planner/share-planner-actions.vue";

import { IMilestonePlannerInvitedUser } from "@/modules/milestone-planner/services/store/milestone-planner/milestone-planner.types";
import { ICompanyListInvitedGuest } from "@/modules/company-lists/services/data/company-list/company-list.interface";

export default defineComponent({
  name: "SharePlannerUsers",

  components: {
    SharePlannerUsersIndex,
    SharePlannerActions,
  },

  props: {
    users: {
      type: Array as () => Array<
        IMilestonePlannerInvitedUser | ICompanyListInvitedGuest
      >,
      required: true,
    },

    link: {
      type: String,
      required: true,
    },

    passcode: {
      type: String,
      required: true,
    },
  },
});
</script>

<style lang="scss" scoped>
$headerMaxHeight: 100px;

.share-planner-users {
  display: flex;
  flex-flow: column wrap;
  align-items: flex-start;
  height: 100%;
  max-height: 363px;
  overflow: hidden;

  &__header {
    flex: 0 0 auto;
    width: 100%;
    max-height: $headerMaxHeight;
  }

  &__body :deep() {
    flex: 1;
    width: calc(100% + 20px);
    min-height: 370px;
    max-height: calc(100% - #{$headerMaxHeight});
    padding-right: 5px;
    // To avoid overlap with footer:
    padding-bottom: 100px;
    overflow: auto;

    .px-loading-mask {
      bottom: -10px;
      z-index: z("floaters") - 1;
    }
  }

  &__footer {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: z("floaters");
    width: 100%;
    min-height: 118px;
    padding: 10px 26px 25px 32px;
    background-color: $alabaster;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
    box-shadow: inset 0 1px 0 0 $mischka;
  }
}
</style>
