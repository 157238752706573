import { IViralLevelState } from "./viral-level-types";

export const state: IViralLevelState = {
  levels: [],
  loading: false,
  error: null,
  selectedCategory: 0,
  firstInteraction: true,
  finalLevel: undefined,
  affiliate: null,
  affiliateQuestionBundle: [],
  teamMembersQuestionBundle: [],
  submitted: false,
  pendingUser: null,
  metadata: {},
};
