import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-3fb29f96"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "px-panel__container" }
const _hoisted_2 = {
  key: 0,
  class: "px-panel__header"
}
const _hoisted_3 = { class: "px-panel__title" }
const _hoisted_4 = {
  key: 1,
  class: "px-panel__footer"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PxIcon = _resolveComponent("PxIcon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([[
      `px-panel--${_ctx.theme}`,
      {
        'px-panel--with-icon': !!_ctx.icon,
        'px-panel--no-borders': _ctx.noBorders,
        'px-panel--scrollable': _ctx.scrollable,
        'px-panel--has-shadow': _ctx.showShadow,
      },
    ], "px-panel"])
  }, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.showHeader)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _renderSlot(_ctx.$slots, "header", {}, () => [
              (_ctx.icon)
                ? (_openBlock(), _createBlock(_component_PxIcon, {
                    key: 0,
                    name: _ctx.icon
                  }, null, 8, ["name"]))
                : _createCommentVNode("", true),
              _createElementVNode("h1", _hoisted_3, _toDisplayString(_ctx.title), 1)
            ])
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", {
        style: _normalizeStyle(_ctx.customBodyStyle),
        class: "px-panel__body"
      }, [
        _renderSlot(_ctx.$slots, "default")
      ], 4),
      (_ctx.$slots.footer)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _renderSlot(_ctx.$slots, "footer")
          ]))
        : _createCommentVNode("", true)
    ])
  ], 2))
}