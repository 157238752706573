<template>
  <ElTooltip
    :content="completeTooltipCopy"
    :disabled="!showTooltip"
    data-testid="complete-button-tooltip"
    placement="top"
    popper-class="milestone-form-footer__tooltip el-abaca-tooltip"
  >
    <div>
      <PxButton
        v-bind="completeButtonProps"
        :disabled="buttonDisabled"
        class="milestone-form-footer__complete-button"
        data-testid="complete-button"
        @click="$emit('click')"
      />
    </div>
  </ElTooltip>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import {
  EPxButtonSize,
  EPxButtonVariant,
  EPxButtonType,
} from "@/components/px-button/px-button.types";
import { TranslateResult } from "vue-i18n";

export default defineComponent({
  name: "MilestoneFormFooterCompleteButton",

  props: {
    showTooltip: {
      type: Boolean,
      default: false,
    },
    buttonDisabled: {
      type: Boolean,
      default: false,
    },
    buttonLabel: {
      type: String,
      required: true,
    },
  },

  static: {
    completeButton: {
      size: EPxButtonSize.LARGE,
      variant: EPxButtonVariant.FLUID,
    },
  },

  computed: {
    completeTooltipCopy(): TranslateResult {
      return this.$t(
        "milestonePlanner.milestonePlan.tooltips.completeMilestone",
      );
    },

    completeButtonProps(): any {
      return {
        ...this.$options.static.completeButton,
        type: EPxButtonType.BLUE,
        label: this.buttonLabel,
      };
    },
  },
});
</script>

<style lang="scss" scoped>
.milestone-form-footer__complete-button {
  position: relative;
  bottom: 1px;
  height: 36px;
}
</style>
