import { GetterTree } from "vuex";
import { RootState } from "@/services/store/root-state";
import {
  EAffiliateGetter,
  IAffiliateState,
} from "@/modules/supporters/services/store/affiliate/affiliate.interface";
import { IMatchingQuestion } from "@/services/data/matching-questionary/matching-question.interface";
import { getBaseName } from "@/services/store/utils/get-base-name";
import { IQuestionBundle } from "@/services/data/question-bundle/question-bundle.interface";

export const getters: GetterTree<IAffiliateState, RootState> = {
  /**
   * Generic action to get value of store.
   * @param state
   */
  [getBaseName(EAffiliateGetter.QUESTION_BUNDLE)](
    state: IAffiliateState,
  ): Array<IMatchingQuestion> {
    if (!state.value || !state.value.question_bundles) {
      return [];
    }

    const questionIds = [] as Array<number>;
    const questions = [] as Array<IMatchingQuestion>;

    state.value.question_bundles.forEach((questionBundle: IQuestionBundle) => {
      questionBundle.questions.forEach((question: IMatchingQuestion) => {
        if (!questionIds.includes(question.id)) {
          questions.push(question);
          questionIds.push(question.id);
        }
      });
    });

    return questions;
  },
};
