/**
 * Generate a range of years.
 *
 * @param init Initial year
 * @param end End year
 */
export const generateYearsRange = (
  init: number,
  end: number = new Date().getFullYear(),
) => [...new Array(end + 1 - init).keys()].map((v: number) => v + init);
