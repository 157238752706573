import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, Fragment as _Fragment, createElementVNode as _createElementVNode, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-03f270ad"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "px-questionary__wrapper" }
const _hoisted_2 = {
  key: 0,
  class: "px-questionary__content"
}
const _hoisted_3 = { class: "px-questionary__question-list" }
const _hoisted_4 = { class: "px-questionary__question-item" }
const _hoisted_5 = {
  key: 0,
  class: "px-questionary__question-title"
}
const _hoisted_6 = {
  key: 1,
  class: "px-questionary__question-title"
}
const _hoisted_7 = {
  key: 0,
  class: "px-questionary__criteria-wrapper"
}
const _hoisted_8 = {
  key: 1,
  class: "px-questionary__actions"
}
const _hoisted_9 = {
  key: 2,
  class: "px-questionary__final"
}
const _hoisted_10 = { class: "px-questionary__final-title" }
const _hoisted_11 = { class: "px-questionary__final-message" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PxQuestionaryPlaceholder = _resolveComponent("PxQuestionaryPlaceholder")!
  const _component_PxQuestionarySelect = _resolveComponent("PxQuestionarySelect")!
  const _component_PxQuestionaryCurrencyRange = _resolveComponent("PxQuestionaryCurrencyRange")!
  const _component_PxQuestionaryCurrency = _resolveComponent("PxQuestionaryCurrency")!
  const _component_PxQuestionaryNumeric = _resolveComponent("PxQuestionaryNumeric")!
  const _component_PxQuestionaryRange = _resolveComponent("PxQuestionaryRange")!
  const _component_PxQuestionaryCriteria = _resolveComponent("PxQuestionaryCriteria")!
  const _component_PxIcon = _resolveComponent("PxIcon")!
  const _component_PxButton = _resolveComponent("PxButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.loading)
      ? (_openBlock(), _createBlock(_component_PxQuestionaryPlaceholder, { key: 0 }))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          (!_ctx.finishedQuestionary)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createElementVNode("div", _hoisted_3, [
                  _createElementVNode("div", _hoisted_4, [
                    (_ctx.$user.isEntrepreneur())
                      ? (_openBlock(), _createElementBlock("h4", _hoisted_5, _toDisplayString(_ctx.question.entrepreneur_question), 1))
                      : _createCommentVNode("", true),
                    (_ctx.$user.isSupporter())
                      ? (_openBlock(), _createElementBlock("h4", _hoisted_6, _toDisplayString(_ctx.question.resource_question), 1))
                      : _createCommentVNode("", true),
                    (_ctx.isSelectType)
                      ? (_openBlock(), _createBlock(_component_PxQuestionarySelect, {
                          key: 2,
                          modelValue: _ctx.selectedOptions,
                          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedOptions) = $event)),
                          "is-valid": _ctx.isFieldValid,
                          "onUpdate:isValid": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.isFieldValid) = $event)),
                          multiple: _ctx.isMultiSelect,
                          options: _ctx.question.answers
                        }, null, 8, ["modelValue", "is-valid", "multiple", "options"]))
                      : _createCommentVNode("", true),
                    (_ctx.isMoneyType)
                      ? (_openBlock(), _createElementBlock(_Fragment, { key: 3 }, [
                          (_ctx.needsCurrencyRange)
                            ? (_openBlock(), _createBlock(_component_PxQuestionaryCurrencyRange, {
                                key: 0,
                                modelValue: _ctx.selectedValue,
                                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.selectedValue) = $event)),
                                "is-valid": _ctx.isFieldValid,
                                "onUpdate:isValid": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.isFieldValid) = $event))
                              }, null, 8, ["modelValue", "is-valid"]))
                            : (_openBlock(), _createBlock(_component_PxQuestionaryCurrency, {
                                key: 1,
                                modelValue: _ctx.selectedValue,
                                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.selectedValue) = $event)),
                                "is-valid": _ctx.isFieldValid,
                                "onUpdate:isValid": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.isFieldValid) = $event))
                              }, null, 8, ["modelValue", "is-valid"]))
                        ], 64))
                      : (_ctx.isNumeric)
                        ? (_openBlock(), _createElementBlock(_Fragment, { key: 4 }, [
                            (_ctx.$user.isEntrepreneur())
                              ? (_openBlock(), _createBlock(_component_PxQuestionaryNumeric, {
                                  key: 0,
                                  modelValue: _ctx.selectedValue,
                                  "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.selectedValue) = $event)),
                                  "is-valid": _ctx.isFieldValid,
                                  "onUpdate:isValid": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.isFieldValid) = $event))
                                }, null, 8, ["modelValue", "is-valid"]))
                              : _createCommentVNode("", true),
                            (_ctx.$user.isSupporter())
                              ? (_openBlock(), _createBlock(_component_PxQuestionaryRange, {
                                  key: 1,
                                  modelValue: _ctx.selectedValue,
                                  "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.selectedValue) = $event)),
                                  "is-valid": _ctx.isFieldValid,
                                  "onUpdate:isValid": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.isFieldValid) = $event))
                                }, null, 8, ["modelValue", "is-valid"]))
                              : _createCommentVNode("", true)
                          ], 64))
                        : _createCommentVNode("", true)
                  ])
                ]),
                (_ctx.$user.isSupporter() && _ctx.hasCriteria)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                      _createElementVNode("h5", null, _toDisplayString(_ctx.$t("matching.matchingSidebar.questionary.criteria")), 1),
                      _createVNode(_component_PxQuestionaryCriteria, {
                        modelValue: _ctx.selectedCriteriaWeightId,
                        "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.selectedCriteriaWeightId) = $event)),
                        weights: _ctx.criteriaWeights
                      }, null, 8, ["modelValue", "weights"])
                    ]))
                  : _createCommentVNode("", true)
              ]))
            : _createCommentVNode("", true),
          (!_ctx.finishedQuestionary)
            ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                _createElementVNode("div", {
                  class: "px-questionary__skip",
                  onClick: _cache[11] || (_cache[11] = 
//@ts-ignore
(...args) => (_ctx.onClickSkipQuestion && _ctx.onClickSkipQuestion(...args)))
                }, [
                  _createVNode(_component_PxIcon, {
                    size: 20,
                    name: "skip"
                  }),
                  _createTextVNode(" " + _toDisplayString(_ctx.$t("common.skip")), 1)
                ]),
                _createVNode(_component_PxButton, {
                  disabled: !_ctx.isFieldValid,
                  class: "px-questionary__next",
                  size: "medium",
                  type: "primary",
                  onClick: _ctx.onClickSubmitAnswer
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t("common.nextQuestion")), 1)
                  ]),
                  _: 1
                }, 8, ["disabled", "onClick"])
              ]))
            : _createCommentVNode("", true),
          (_ctx.finishedQuestionary)
            ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                _createElementVNode("h4", _hoisted_10, _toDisplayString(_ctx.finalTitle), 1),
                _createElementVNode("p", _hoisted_11, _toDisplayString(_ctx.finalMessage), 1)
              ]))
            : _createCommentVNode("", true)
        ], 64))
  ]))
}