import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "matching-badge-modal__title" }
const _hoisted_2 = { class: "matching-badge-modal__description" }
const _hoisted_3 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PxIcon = _resolveComponent("PxIcon")!
  const _component_ElDialog = _resolveComponent("ElDialog")!

  return (_openBlock(), _createBlock(_component_ElDialog, {
    ref: "modal",
    modelValue: _ctx.innerVisibility,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.innerVisibility) = $event)),
    "append-to-body": true,
    "destroy-on-close": true,
    "lock-scroll": true,
    title: _ctx.title,
    class: "matching-badge-modal el-dialog--px-10"
  }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, index) => {
        return (_openBlock(), _createElementBlock("div", {
          key: index,
          class: "matching-badge-modal__row"
        }, [
          _createElementVNode("div", null, [
            _createVNode(_component_PxIcon, {
              name: item.icon,
              size: 56,
              class: "matching-badge-modal__badge-icon"
            }, null, 8, ["name"])
          ]),
          _createElementVNode("div", null, [
            _createElementVNode("p", _hoisted_1, _toDisplayString(item.title), 1),
            _createElementVNode("p", _hoisted_2, _toDisplayString(item.description), 1),
            (item.link)
              ? (_openBlock(), _createElementBlock("p", {
                  key: 0,
                  class: "matching-badge-modal__link",
                  onClick: ($event: any) => (_ctx.onLinkClick(item.link))
                }, _toDisplayString(item.link.title), 9, _hoisted_3))
              : _createCommentVNode("", true)
          ])
        ]))
      }), 128))
    ]),
    _: 1
  }, 8, ["modelValue", "title"]))
}