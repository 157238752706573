import { ITeamMember } from "@/modules/team-management/services/data/team-members/team-members.interface";
import { IGenericState } from "@/services/store/generic/generic-state.interface";

/**
 * Represents the state structure for the Team Members module.
 */
export type ITeamMembersState = IGenericState<ITeamMember>;

export enum ETeamMembersState {
  NAMESPACE = "teamMembers",
}

export enum ETeamMembersActions {
  GET_VALUES = "teamMembers/getValues",
  CREATE_VALUES = "teamMembers/createValues",
}

export enum ETeamMembersMutations {
  SET_VALUES = "SET_VALUES",
  SET_ERROR = "SET_ERROR",
  SET_LOADING = "SET_LOADING",
}
