import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, Fragment as _Fragment, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "support-modal__title" }
const _hoisted_2 = { class: "support-modal__description" }
const _hoisted_3 = {
  key: 0,
  class: "support-modal__footer"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PxModalMessage = _resolveComponent("PxModalMessage")!
  const _component_SupportModalSuccess = _resolveComponent("SupportModalSuccess")!
  const _component_ElInput = _resolveComponent("ElInput")!
  const _component_ElFormItem = _resolveComponent("ElFormItem")!
  const _component_PxTextarea = _resolveComponent("PxTextarea")!
  const _component_ElForm = _resolveComponent("ElForm")!
  const _component_PxButton = _resolveComponent("PxButton")!
  const _component_ElDialog = _resolveComponent("ElDialog")!

  return (_openBlock(), _createBlock(_component_ElDialog, {
    ref: "modal",
    modelValue: _ctx.innerVisibility,
    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.innerVisibility) = $event)),
    "before-close": _ctx.onClickCloseModal,
    "close-on-click-modal": false,
    "close-on-press-escape": false,
    class: "support-modal"
  }, {
    footer: _withCtx(() => [
      (!_ctx.reportSent)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createVNode(_component_PxButton, {
              ref: "closeButton",
              class: "support-modal__footer-link",
              size: "small",
              type: "link",
              onClick: _ctx.onClickCloseModal
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t("common.cancel")), 1)
              ]),
              _: 1
            }, 8, ["onClick"]),
            _createVNode(_component_PxButton, {
              ref: "sendButton",
              disabled: !_ctx.canSendReport || _ctx.formIsInvalid,
              class: "support-modal__footer-button",
              type: "green",
              onClick: _ctx.onClickSendHandler
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t("common.send")), 1)
              ]),
              _: 1
            }, 8, ["disabled", "onClick"])
          ]))
        : _createCommentVNode("", true)
    ]),
    default: _withCtx(() => [
      _createVNode(_component_PxModalMessage, {
        timeout: 5000,
        visible: _ctx.hasError
      }, null, 8, ["visible"]),
      (_ctx.reportSent)
        ? (_openBlock(), _createBlock(_component_SupportModalSuccess, {
            key: 0,
            email: _ctx.userEmail || ''
          }, null, 8, ["email"]))
        : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
            _createElementVNode("h1", _hoisted_1, _toDisplayString(_ctx.$t("common.components.supportModal.title")), 1),
            _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.$t("common.components.supportModal.description")), 1),
            _createVNode(_component_ElForm, {
              ref: "form",
              model: _ctx.form,
              rules: _ctx.rules
            }, {
              default: _withCtx(() => [
                (!_ctx.isUserLogged)
                  ? (_openBlock(), _createBlock(_component_ElFormItem, {
                      key: 0,
                      label: _ctx.$t('supporters.assessment.rangeLevelsModal.labels.email'),
                      prop: "email"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ElInput, {
                          modelValue: _ctx.form.email,
                          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.form.email) = $event)),
                          placeholder: 
              _ctx.$t('supporters.assessment.rangeLevelsModal.placeholders.email')
            
                        }, null, 8, ["modelValue", "placeholder"])
                      ]),
                      _: 1
                    }, 8, ["label"]))
                  : _createCommentVNode("", true),
                _createVNode(_component_ElFormItem, {
                  label: 
            _ctx.$t('common.components.supportModal.fields.detailedReport.label')
          ,
                  prop: "detailedReport"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_PxTextarea, {
                      ref: "detailedReport",
                      modelValue: _ctx.form.detailedReport,
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.form.detailedReport) = $event)),
                      "max-chars": _ctx.rules.detailedReport.max,
                      placeholder: 
              _ctx.$t(
                'common.components.supportModal.fields.detailedReport.placeholder',
              )
            ,
                      "show-counter": ""
                    }, null, 8, ["modelValue", "max-chars", "placeholder"])
                  ]),
                  _: 1
                }, 8, ["label"])
              ]),
              _: 1
            }, 8, ["model", "rules"])
          ], 64))
    ]),
    _: 1
  }, 8, ["modelValue", "before-close"]))
}