<template>
  <div :style="{ height }" class="level-range-slider">
    <ElSlider
      ref="slider"
      v-model="innerValue"
      :max="levels"
      :min="1"
      :show-tooltip="false"
      :step="1"
      class="level-range-slider__inner"
      height="338px"
      range
      show-stops
      vertical
      @input="() => handleRangeSliderInputChange"
    />
    <div class="level-range-slider__labels">
      <template v-for="(item, index) in stops">
        <div
          v-if="index !== 0"
          :key="item"
          :class="currentLevelState(index)"
          :style="{ bottom: item + '%' }"
          class="level-range-slider__level-label"
        >
          {{ index }}
          <span
            :style="{ color: `#${color}` }"
            class="level-range-slider__level-state"
          />
        </div>
        <div
          v-if="index !== 0"
          :key="'stop-' + item"
          :class="{
            'is-active': isLevelBeingSelected(index),
          }"
          :style="{ bottom: item + '%' }"
          class="level-range-slider__stop"
        />
      </template>
    </div>
  </div>
</template>

<script lang="ts">
import { ComponentPublicInstance, defineComponent } from "vue";
import xor from "lodash/xor";

const SLIDER_BAR_SELECTOR = ".el-slider__bar";
const SLIDER_BUTTON_SELECTOR = ".el-slider__button";

export default defineComponent({
  name: "LevelRangeSlider",

  props: {
    rangeRestriction: {
      type: Number,
      default: null,
    },
    /**
     * Number of available levels.
     */
    levels: {
      type: Number,
      required: true,
    },
    /**
     * Current selected level.
     */
    value: {
      type: Array as () => any[],
      default: () => [1, 1],
    },
    color: {
      type: String,
      default: "ff8e54",
    },
    /**
     * Component height.
     */
    height: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      innerValue: [1, 1],
      rangeHasOverreachedLimit: false,
      levelsBeingSelected: this.value,
    };
  },

  computed: {
    stops(): Array<number> {
      const stopCount = this.levels;
      const stepWidth = 105 / this.levels;

      const result = [0];
      for (let i = 0; i < stopCount; i++) {
        result.push(i * stepWidth);
      }

      return result;
    },

    firstStop(): number {
      return this.innerValue[0];
    },

    lastStop(): number {
      return this.innerValue[1];
    },

    firstBeingSelectedLevel(): number {
      return this.levelsBeingSelected[0];
    },

    lastBeingSelectedLevel(): number {
      return this.levelsBeingSelected[1];
    },
  },

  watch: {
    /**
     * Inform the outside would that the value changed.
     */
    innerValue: {
      deep: true,
      handler(newVal: Array<number>, oldVal: Array<number>) {
        const newRangeRestricted = this.applyRangeRestrictions(newVal, oldVal);

        const newRange = this.rangeRestriction ? newRangeRestricted : newVal;
        const hasNewValue = !!xor(newVal, this.value).length;

        if (hasNewValue) {
          this.$emit("input", newRange);
          this.$emit("changed", newRange);
        }

        if (this.rangeHasOverreachedLimit) {
          this.$emit("range-limit-overreached");
          this.rangeHasOverreachedLimit = false;
        }
      },
    },

    value: {
      immediate: true,
      deep: true,
      handler(newVal: Array<number>) {
        if (xor(newVal, this.innerValue).length) {
          this.innerValue = newVal;
        }
      },
    },

    /**
     * Set the slider color when the prop `color` changes.
     */
    color() {
      this.setSliderColor();
    },
  },

  mounted() {
    // When the component is rendered for the first time
    // the slider color must be set with the color
    // presented on the prop `color`
    this.setSliderColor();
  },

  methods: {
    handleRangeSliderInputChange(newVal: Array<number>) {
      this.levelsBeingSelected = newVal;
    },
    /**
     * Sets the slider color based on the color present on the
     * `color` prop.
     */
    setSliderColor() {
      const sliderComponent = this.$refs.slider as ComponentPublicInstance;
      const sliderElement = sliderComponent.$el;
      const progressBar = sliderElement.querySelector(
        SLIDER_BAR_SELECTOR,
      ) as HTMLElement;
      const buttonElements = sliderElement.querySelectorAll(
        SLIDER_BUTTON_SELECTOR,
      ) as NodeListOf<HTMLElement>;

      [progressBar, ...buttonElements].forEach((element: HTMLElement) => {
        element.style.backgroundColor = `#${this.color}`;
      });
    },

    applyRangeRestrictions(
      newRangeValue: Array<number>,
      oldRangeValue: Array<number>,
    ) {
      if (!oldRangeValue) return newRangeValue;

      const updatedRangeValue: Array<number> = [...newRangeValue];

      const rangeDifference = this.rangeRestriction - 1;
      const rangeGotHigher = newRangeValue[1] > oldRangeValue[1];
      const rangeGotLower = newRangeValue[0] < oldRangeValue[0];

      const rangeHasGrown =
        newRangeValue[0] + rangeDifference < newRangeValue[1];
      const rangeHasShrink =
        newRangeValue[1] - newRangeValue[0] <
        oldRangeValue[1] - oldRangeValue[0];
      const rangeReachedLimit = rangeHasGrown || rangeHasShrink;

      if (rangeReachedLimit) {
        this.rangeHasOverreachedLimit = rangeHasGrown;

        if (rangeGotHigher) {
          // Pull first range selector up
          updatedRangeValue[0] = newRangeValue[1] - rangeDifference;
        } else if (rangeGotLower) {
          // Pull last range selector down
          updatedRangeValue[1] = newRangeValue[0] + rangeDifference;
        }
      }

      return updatedRangeValue;
    },

    isLevelSelected(index: number): boolean {
      return index >= this.firstStop && index <= this.lastStop;
    },

    isLevelBeingSelected(index: number): boolean {
      return (
        index >= this.firstBeingSelectedLevel &&
        index <= this.lastBeingSelectedLevel
      );
    },

    isLevelSelectable(index: number): boolean {
      const rangeDifference = this.rangeRestriction - 1;
      const selectedRangeDifference = this.lastStop - this.firstStop;
      const rangeAvailable = rangeDifference - selectedRangeDifference;

      const canSelectLowerLevel =
        index < this.firstStop && index >= this.firstStop - rangeAvailable;
      const canSelectHigherLevel =
        index > this.lastStop && index <= this.lastStop + rangeAvailable;

      return canSelectLowerLevel || canSelectHigherLevel;
    },

    currentLevelState(index: number) {
      return {
        "is-selected": this.isLevelSelected(index),
        "is-selectable": this.isLevelSelectable(index),
      };
    },
  },
});
</script>

<style lang="scss">
$slider-width: 24px;
$slider-border-radius: 2px;
$slider-stop-bg-color: rgba(139, 143, 161, 0.6);
$slider-stop-active-bg-color: rgba(39, 39, 64, 0.4);
$slider-button-size: 36px;

.level-range-slider {
  position: relative;
  margin-bottom: 9px;
}

.el-slider.level-range-slider__inner.is-vertical {
  top: 17px;

  .el-slider__runway {
    width: $slider-width;

    background: none;
  }

  .el-slider__runway::before {
    position: absolute;
    top: calc(50% + 9px);
    right: 0;
    left: 0;
    height: 372px;

    content: "";

    background-image: linear-gradient(to top, $mischka, $mischka);
    border: solid 1px rgba(0, 0, 0, 0.06);
    border-radius: 100px;

    transform: translateY(-50%);
  }

  .el-slider__bar {
    left: -1px;
    width: $slider-width;

    border: solid 1px $black-20;
    border-radius: 100px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;

    transform: translate(1px, 20px);
  }

  .el-slider__stop {
    display: none;
  }

  .el-slider__button-wrapper {
    left: -6px;

    &[style*="bottom: 0%"] + .el-slider__button-wrapper[style*="bottom: 0%"] {
      transform: translateY(-5px);
    }
  }

  .el-slider__button {
    position: relative;
    width: $slider-button-size;
    height: $slider-button-size;

    background-image: url("#{$assetsPath}/img/icons/slider-shapes.svg");
    background-position: -2px -2px;
    background-size: 38px 38px;
    border: solid 1px rgba(0, 0, 0, 0.24);
  }

  .el-slider__button:hover,
  .el-slider__button.hover,
  .el-slider__button.dragging {
    transform: translateZ(0) scale(1.07);
  }

  .el-slider__button::before {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 42px;
    height: 42px;

    content: "";

    background-color: transparent;
    border: solid 3px $alabaster;
    border-radius: 50%;

    transform: translate(-50%, -50%);
  }

  .el-slider__button::after {
    position: absolute;
    top: -1px;
    left: -1px;
    width: $slider-button-size;
    height: $slider-button-size;

    content: "";

    background-color: transparent;
    border-radius: 50%;

    box-shadow: 0 0 5px 0 $black-20;
  }
}

.level-range-slider__labels {
  position: absolute;
  top: 6px;
  left: -20px;
  height: 360px;
}

.level-range-slider__stop {
  position: absolute;
  left: 43px;
  width: 10px;
  height: 2px;

  background-color: $slider-stop-bg-color;
  border-radius: 2px;
  transform: translateY(-3px);

  &.is-active {
    background-color: $slider-stop-active-bg-color;

    box-shadow: inset 0 1px 2px 0 rgba(39, 39, 64, 0.4);
  }
}

.level-range-slider__level-state {
  position: relative;

  &::before {
    position: absolute;
    top: 2px;
    left: -32px;
    width: 10px;
    height: 10px;
    content: "";
    background-color: $mischka;
    border-radius: 100%;
    opacity: 0;
  }
}

.level-range-slider__level-label {
  @include grotesk(medium);

  position: absolute;
  height: 17px;

  font-size: 0.9333rem;

  &:not(:first-child) .level-range-slider__level-state::after {
    position: absolute;
    top: 5px;
    left: -28px;
    width: 2px;
    height: 40px;
    content: "";
    background-color: currentColor;
    opacity: 0;
  }

  &.is-selected {
    @include grotesk(semiBold);

    font-size: 1.0667rem;

    .level-range-slider__level-state::before {
      background-color: currentColor;
      opacity: 1;
    }

    & ~ .is-selected .level-range-slider__level-state::after {
      opacity: 1;
    }
  }

  &.is-selectable .level-range-slider__level-state::before {
    opacity: 1;
  }
}
</style>
