<template>
  <RenderlessEntrepreneurAssessment
    v-slot="scope"
    :end-action="endAction || defaultEndAction"
    :needs-submission-confirmation="needsSubmissionConfirmation"
  >
    <div>
      <AssessmentDesktop
        v-if="$screen.mdUp"
        :current-category="scope.currentCategory"
        :is-first-interaction="scope.isFirstInteraction"
        :levels="scope.levels"
        :loading="isLoading"
        :on-go-next="scope.goNextCategoryHandler"
        :on-go-prev="scope.goPrevCategoryHandler"
        :on-level-change="scope.onLevelChange"
        :selected-category="scope.selectedCategory"
        :selected-step="scope.selectedStep"
        :show-back-button="needsSubmissionConfirmation"
      />
      <AssessmentMobile
        v-if="$screen.smDown"
        :current-category="scope.currentCategory"
        :levels="scope.levels"
        :on-go-next="scope.goNextCategoryHandler"
        :on-go-prev="scope.goPrevCategoryHandler"
        :on-level-change="scope.onLevelChange"
        :selected-category="scope.selectedCategory"
        :selected-step="scope.selectedStep"
      />
    </div>
  </RenderlessEntrepreneurAssessment>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import RenderlessEntrepreneurAssessment from "@/modules/common/components/renderless-entrepreneur-assessment/renderless-entrepreneur-assessment.vue";
import AssessmentDesktop from "@/modules/self-assessment/components/assessment-desktop/assessment-desktop.vue";
import AssessmentMobile from "@/modules/self-assessment/components/assessment-mobile/assessment-mobile.vue";
import { SELF_ASSESSMENT_RESULTS } from "@/modules/self-assessment/services/router/routes-names";
import { PropType } from "vue";

export default defineComponent({
  name: "Assessment",
  components: {
    RenderlessEntrepreneurAssessment,
    AssessmentDesktop,
    AssessmentMobile,
  },

  props: {
    /**
     * This prop allows to the the end action for when the
     * user riches the end of the assessment.
     */
    endAction: {
      type: Function as PropType<() => any>,
      default: null,
    },

    needsSubmissionConfirmation: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    isLoading(): boolean {
      return this.$store.state.viralLevel.loading;
    },
  },

  methods: {
    defaultEndAction() {
      this.$router.push({ name: SELF_ASSESSMENT_RESULTS });
    },
  },
});
</script>
