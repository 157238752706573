/**
 * Given a namespaced name this returns the base name for
 * the operation.
 *
 * If the provided name isn't doesn't contain a namespace
 * it will be returned without any modification.
 *
 * @param name Namespaced action/module name.
 */
export const getBaseName = (name: string): string => {
  const splittedName = name.split("/");
  const basename = splittedName.pop();

  return basename || name;
};
