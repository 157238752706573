<template>
  <ElDialog
    ref="modal"
    v-model="innerVisibility"
    :append-to-body="true"
    :class="{ 'text--capitalize': capitalize }"
    :destroy-on-close="true"
    :lock-scroll="true"
    :title="title"
    class="px-list-modal"
    width="420px"
  >
    <ul>
      <li
        v-for="(item, index) in items"
        :key="index"
        ref="item"
        :class="{
          'px-list-modal__item--opened': hasItemsOpened(index),
          'px-list-modal__item--scrolling': isScrollingList(index),
        }"
        class="px-list-modal__item"
      >
        <template v-if="typeof item === 'string'">
          {{ item }}
        </template>
        <div v-else class="px-list-modal__group">
          <div
            class="px-list-modal__group-title"
            @click="() => onClickToggleItems(index)"
          >
            {{ item.name }}
            <span class="px-list-modal__group-subtitle">
              ({{ item.items.length }})
            </span>
            <i
              :class="{ 'icon--invert-y': hasItemsOpened(index) }"
              class="icon icon-caret--gray"
            />
          </div>
          <div
            :class="{
              'px-list-modal__group-items--hidden': !hasItemsOpened(index),
            }"
            class="px-list-modal__group-items"
            @scroll="listGroupScrollHandler(index)"
          >
            <p v-for="(child, childIndex) in item.items" :key="childIndex">
              {{ child }}
            </p>
          </div>
        </div>
      </li>
    </ul>
  </ElDialog>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import throttle from "lodash/throttle";

export interface IModalListGroup {
  name: string;
  items: Array<string>;
}

export default defineComponent({
  name: "PxListModal",

  props: {
    visibility: {
      type: Boolean,
      required: true,
    },
    title: {
      type: String,
      required: true,
      default: "",
    },
    items: {
      type: Array as () => string[] | IModalListGroup[],
      required: true,
    },
    capitalize: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      innerVisibility: false,
      itemsOpened: [] as number[],
      itemsScrolling: [] as number[],
    };
  },

  watch: {
    visibility: {
      immediate: true,
      async handler(newVal) {
        this.innerVisibility = newVal;

        if (!newVal) {
          return;
        }
      },
    },

    innerVisibility(newVal, oldVal) {
      if (newVal === oldVal) {
        return;
      }

      if (!newVal) {
        this.$emit("update:visibility", newVal);
      }
    },
  },

  methods: {
    onClickToggleItems(itemKey: number) {
      const index = +itemKey;
      const itemsElement = this.$refs.item
        ? (this.$refs.item as Array<HTMLElement>)
        : [];

      if (itemsElement.length && itemsElement[index]) {
        if (this.hasItemsOpened(index)) {
          this.itemsOpened = this.itemsOpened.filter(
            (itemIndex) => itemIndex !== index,
          );
        } else {
          this.itemsOpened.push(index);
        }
      }
    },

    hasItemsOpened(itemIndex: string | number) {
      return this.itemsOpened.includes(+itemIndex);
    },

    listGroupScrollHandler(itemKey: string) {
      throttle(() => {
        const index = +itemKey;
        const itemsElement = this.$refs.item
          ? (this.$refs.item as Array<HTMLElement>)
          : [];
        const currentListElement =
          itemsElement.length && itemsElement[index]
            ? itemsElement[index]
            : null;

        if (currentListElement) {
          const scrollingContainer = currentListElement.querySelector(
            ".px-list-modal__group-items",
          );

          if (scrollingContainer && scrollingContainer.scrollTop) {
            this.itemsScrolling.push(index);
          } else {
            this.itemsScrolling = this.itemsScrolling.filter(
              (itemIndex) => itemIndex !== index,
            );
          }
        }
      }, 200)();
    },

    isScrollingList(itemIndex: string | number) {
      return this.itemsScrolling.includes(+itemIndex);
    },
  },
});
</script>

<style lang="scss">
.px-list-modal {
  ul {
    padding: 0;
    margin: 0;
    list-style: none;
  }

  li {
    font-size: to-rem(16px);
    line-height: 25px;
    color: $ebony-clay;

    &:not(:first-child) {
      padding-top: 10px;
      margin-top: 10px;
      border-top: solid 1px $athens-gray;
    }

    &::first-letter {
      text-transform: uppercase;
    }
  }

  &.el-dialog {
    height: 100vh;
    max-height: 561px;
  }

  .el-dialog__body {
    max-height: 491px;
    overflow: auto;
    margin-right: 5px;

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
      margin: 10px;
    }

    &::-webkit-scrollbar-thumb {
      background: $mischka;
      border-radius: 10px;
    }
  }

  &__group-title {
    cursor: pointer;
  }

  &__group-title .icon {
    display: inline-block;
    width: 14px;
    height: 14px;
    vertical-align: -2px;
    cursor: pointer;
    transition:
      background 150ms ease-in-out,
      transform 250ms ease-in-out;

    &.icon--invert-y {
      transform: rotateZ(180deg);
    }
  }

  &__group-subtitle {
    color: $manatee;
  }

  &__group-items {
    max-height: 150px;
    padding: 0 0 20px;
    overflow-y: scroll;
    visibility: visible;
    opacity: 1;
    transition:
      visibility 150ms ease-in-out,
      opacity 150ms ease-in-out;

    &--hidden {
      max-height: 0;
      padding-bottom: 0;
      visibility: visible;
      opacity: 0;
    }

    p {
      font-size: 16px;
      color: $manatee;

      &:not(:last-child) {
        margin-bottom: 4px;
      }
    }

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
      margin: 10px;
    }

    &::-webkit-scrollbar-thumb {
      background: $mischka;
      border-radius: 10px;
    }
  }

  &__item--opened {
    .px-list-modal__group-title {
      padding-bottom: 9px;
      border-bottom: solid 1px transparent;
    }

    & + .px-list-modal__item {
      margin-top: 0;
    }
  }

  &__item--scrolling .px-list-modal__group-title {
    border-color: $athens-gray;
  }
}
</style>
