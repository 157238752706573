<template>
  <div class="panel-grid__block">
    <InvestmentLevelPanelPlaceholder v-if="isLoading" />
    <div v-if="!isLoading && $screen.smDown" class="profile-headline">
      <div class="profile-headline__text-wrapper">
        <h2 class="profile-headline__title">
          {{ $t("profile.investors.panel.title") }}
        </h2>
      </div>
      <PxButton
        v-if="allowLevelUpdate"
        :disabled="!$features.isEnabled('updateLevel')"
        class="profile-headline__updateBtn"
        size="small"
        @click="onClickUpdateLevel"
      >
        {{ $t("profile.investors.panel.update") }}
      </PxButton>
    </div>
    <PxPanel
      v-if="!isLoading"
      :class="{ 'profile-panel--disabled': isInactive }"
      class="profile-panel"
    >
      <div class="profile-panel__row investment-level">
        <div v-if="!$screen.smDown" class="profile-headline">
          <div class="profile-headline__text-wrapper">
            <h2 class="profile-headline__title">
              {{ $t("profile.investors.panel.title") }}
            </h2>
          </div>
          <PxButton
            v-if="allowLevelUpdate"
            :disabled="!$features.isEnabled('updateLevel')"
            class="profile-headline__updateBtn"
            size="small"
            @click="onClickUpdateLevel"
          >
            {{ $t("profile.investors.panel.update") }}
          </PxButton>
        </div>
        <div class="level-wrapper">
          <img
            v-if="isInactive"
            alt="level indicator"
            class="investment-level__indicator investment-level__indicator--disabled"
            src="/img/placeholders/level.svg"
          />
          <PxIcon
            v-else
            :name="`level-range-graph/level-${investingLevelRangeInterval}`"
            :size="$screen.mdUp ? 173 : 140"
            class="investment-level__indicator"
          />
          <div v-if="!isInactive" class="investment-level__description">
            <h3>
              {{ investingLevelRangeTitle }}
              <ElTooltip
                placement="top"
                popper-class="investment-level__tooltip el-abaca-tooltip"
              >
                <template #content>
                  <div v-html="investingLevelRangeDescription" />
                </template>
                <i class="icon icon-question" />
              </ElTooltip>
            </h3>
          </div>
        </div>
        <div
          v-if="$user.isOwner() && $features.isEnabled('match')"
          class="investment-level__cta"
        >
          <PxButton size="large" type="green" @click="onClickMatching">
            {{ $t("profile.investors.panel.matchButton") }}
          </PxButton>
        </div>
      </div>
    </PxPanel>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import uniq from "lodash/uniq";

import { ISupporter } from "@/modules/matching/services/data/matching-score/supporter.interface";
import { ECategoryActions } from "@/services/store/category/category-types";
import {
  ICategory,
  ICategoryDetail,
} from "@/services/data/category/category.interface";

import { SUPPORTERS_INVESTING_RANGE_LIMIT } from "@/modules/supporters/constants";

import { EViralLevelActions } from "@/services/store/viral-level/viral-level-types";

import { ROUTE_UPDATE_RANGE_LEVEL } from "@/modules/profile/services/router/routes-names";

import InvestmentLevelPanelPlaceholder from "@/modules/profile/components/investment-level-panel/investment-level-panel-placeholder.vue";
import { ROUTE_MATCHING_LIST } from "@/modules/matching/services/router/routes-names";
import { SUPPORTER_USER_GROUP_ID } from "@/modules/common/constants";

export default defineComponent({
  name: "InvestmentLevelPanel",

  components: {
    InvestmentLevelPanelPlaceholder,
  },

  computed: {
    supporter(): ISupporter | null {
      return this.$store.get("profile/supporter/data");
    },

    categories(): Array<ICategory> {
      return this.$store.state.categories.data;
    },

    supporterCategory(): ICategory | null {
      return this.categories.length ? this.categories[0] : null;
    },

    isLoading(): boolean {
      return (
        this.$store.get("profile/company/loading") ||
        this.$store.get("profile/supporter/loading")
      );
    },

    isSpecialGuest(): boolean {
      return !!this.$route.meta?.specialGuest;
    },

    isInactive(): boolean {
      return !this.$user.isLogged() && !this.isSpecialGuest;
    },

    allowLevelUpdate(): boolean {
      return this.$user.isOwner();
    },

    investingLevelRangeValue(): Array<number> {
      if (!this.supporter) {
        return [];
      }

      const levelRange = uniq(this.supporter.investing_level_range);

      return levelRange.map((entry: number) => {
        if (entry < 1) {
          return 1;
        }

        if (entry > 9) {
          return 9;
        }

        return entry;
      });
    },

    investingLevels(): Array<number> {
      if (!this.investingLevelRangeValue) {
        return [];
      }

      const levelsRange = this.investingLevelRangeValue;
      const startLevel = levelsRange[0];

      if (!levelsRange[1]) {
        return levelsRange;
      }

      // Create number sequence from the levels range
      return Array.from(
        new Array(SUPPORTERS_INVESTING_RANGE_LIMIT),
        (val, index) => index + startLevel,
      ).filter((level: number) => level <= levelsRange[1]);
    },

    investingLevelRangeTitle(): string {
      return this.$t(
        `profile.investors.panel.label[${this.investingLevelRangeSize}]`,
        [...this.investingLevelRangeValue],
      ) as string;
    },

    investingLevelRangeDescription(): string {
      let levelsDescription = "";

      this.investingLevels.forEach((level: number) => {
        const category = this.getCategoryDetailsByLevel(level);
        const description = category ? category.achievements : "";

        levelsDescription += `Level ${level} - ${description}<br>`;
      });

      return levelsDescription;
    },

    investingLevelRangeInterval(): string {
      return this.investingLevelRangeValue.join("-");
    },

    investingLevelRangeSize(): number {
      return this.investingLevelRangeValue[1] ? 1 : 0;
    },
  },

  created() {
    // Fetch categories
    this.$store.dispatch(ECategoryActions.FETCH, {
      group: SUPPORTER_USER_GROUP_ID,
    });
  },

  methods: {
    getCategoryDetailsByLevel(level: number) {
      if (this.supporterCategory) {
        return this.supporterCategory.categoryDetails.find(
          (category: ICategoryDetail) => category.level.value === level,
        );
      }
    },

    onClickUpdateLevel() {
      this.$store.dispatch(EViralLevelActions.RESET);
      this.$router.push({
        name: ROUTE_UPDATE_RANGE_LEVEL,
      });
    },

    onClickMatching() {
      this.$router.push({ name: ROUTE_MATCHING_LIST });
    },
  },
});
</script>

<style lang="scss" scoped>
.investment-level {
  padding: 8px 4px 4px;
  text-align: center;

  @include breakpoint-down(sm) {
    padding: 0;
  }
}

.investment-level .profile-headline__text-wrapper {
  @include breakpoint-up(md) {
    padding-right: 25px;
    text-align: left;
  }
}

.investment-level__indicator {
  display: block;
  margin: 2px auto 0;

  &--disabled {
    opacity: 0.7;
  }
}

.investment-level__description {
  @include breakpoint-down(sm) {
    padding-left: 17px;

    font-size: to-rem(16px);
    line-height: 23px;
    text-align: left;
  }
}

.investment-level__indicator + .investment-level__description {
  margin-top: -1px;
}

.investment-level__description h3 {
  @include grotesk(semiBold);

  margin-bottom: 2px;
  font-size: to-rem(22px);
  line-height: 26px;

  @include breakpoint-down(sm) {
    font-size: to-rem(16px);
  }
}

.investment-level__description .icon {
  position: relative;
  top: 2px;
  display: inline-block;

  width: 18px;
  height: 17px;
  margin-left: 4px;
}

.investment-level__cta {
  margin-top: 19px;
}

.investment-level__cta .el-button :deep() span {
  @include breakpoint-down(sm) {
    font-size: 13px;
    letter-spacing: -0.46px;
  }
}

.level-wrapper {
  &:not(:last-child) {
    margin-bottom: 15px;
  }

  @include breakpoint-down(sm) {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 6px;
  }
}
</style>

<style lang="scss">
.investment-level__tooltip {
  width: 240px;
  white-space: pre-line;

  @include breakpoint-up(md) {
    width: auto;
  }
}
</style>
