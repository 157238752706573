import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-2799c016"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "submission-panel__tabs" }
const _hoisted_2 = { class: "submission-tab__nav-menu" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = { class: "submission-tab__container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SubmissionTabInvestRange = _resolveComponent("SubmissionTabInvestRange")!
  const _component_SubmissionTabQuestions = _resolveComponent("SubmissionTabQuestions")!
  const _component_SubmissionTabTeamMembers = _resolveComponent("SubmissionTabTeamMembers")!
  const _component_SubmissionTabInterests = _resolveComponent("SubmissionTabInterests")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.visiblePanelTabs, (tab, index) => {
        return (_openBlock(), _createElementBlock("span", {
          key: index,
          class: _normalizeClass([{
          'submission-tab__nav-item--active': _ctx.isActiveTab(tab.key),
        }, "submission-tab__nav-item"]),
          onClick: ($event: any) => (_ctx.changeTab(tab.key))
        }, _toDisplayString(tab.label), 11, _hoisted_3))
      }), 128))
    ]),
    _createElementVNode("div", _hoisted_4, [
      _withDirectives(_createVNode(_component_SubmissionTabInvestRange, {
        "invest-range": _ctx.submission.investing_level_range,
        class: "submission-tab__item"
      }, null, 8, ["invest-range"]), [
        [_vShow, _ctx.isActiveTab(_ctx.ESubmissionPanelTab.VENTURE_INVESTMENT_LEVEL)]
      ]),
      _withDirectives(_createVNode(_component_SubmissionTabQuestions, {
        criteria: _ctx.submission.criteria,
        class: "submission-tab__item"
      }, null, 8, ["criteria"]), [
        [_vShow, _ctx.isActiveTab(_ctx.ESubmissionPanelTab.QUESTIONS)]
      ]),
      (_ctx.submission.team_members && _ctx.submission.team_members.length)
        ? _withDirectives((_openBlock(), _createBlock(_component_SubmissionTabTeamMembers, {
            key: 0,
            "team-members": _ctx.submission.team_members
          }, null, 8, ["team-members"])), [
            [_vShow, _ctx.isActiveTab(_ctx.ESubmissionPanelTab.TEAM_MEMBERS)]
          ])
        : _createCommentVNode("", true),
      _withDirectives(_createVNode(_component_SubmissionTabInterests, {
        "additional-criteria": _ctx.submission.additional_criteria,
        class: "submission-tab__item"
      }, null, 8, ["additional-criteria"]), [
        [_vShow, _ctx.isActiveTab(_ctx.ESubmissionPanelTab.INTERESTS)]
      ])
    ])
  ]))
}