<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="173"
    height="173"
    viewBox="0 0 173 173"
  >
    <defs>
      <linearGradient id="viral-graph-6-c" x1="50%" x2="50%" y1="0%" y2="100%">
        <stop offset="0%" stop-color="#58A0F8" class="linear-gradient-color1" />
        <stop
          offset="100%"
          stop-color="#2176DD"
          class="linear-gradient-color2"
        />
      </linearGradient>
      <path
        id="viral-graph-6-b"
        d="M120.496754,1.51902116 L130.132104,13.1874178 C122.622023,19.3820418 116.587228,27.2019088 112.525297,36.0410954 L98.7692629,29.7267629 C103.78632,18.8091229 111.232137,9.16085872 120.496754,1.51902116 Z"
      />
      <filter
        id="viral-graph-6-a"
        width="163.8%"
        height="157.9%"
        x="-31.9%"
        y="-23.2%"
        filterUnits="objectBoundingBox"
      >
        <feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur
          in="shadowOffsetOuter1"
          result="shadowBlurOuter1"
          stdDeviation="3"
        />
        <feComposite
          in="shadowBlurOuter1"
          in2="SourceAlpha"
          operator="out"
          result="shadowBlurOuter1"
        />
        <feColorMatrix
          in="shadowBlurOuter1"
          values="0 0 0 0 0.333333333 0 0 0 0 0.623529412 0 0 0 0 0.976470588 0 0 0 0.5 0"
          class="color-matrix"
        />
      </filter>
      <linearGradient id="viral-graph-6-f" x1="50%" x2="50%" y1="0%" y2="100%">
        <stop offset="0%" stop-color="#64A9FE" class="linear-gradient-color3" />
        <stop
          offset="100%"
          stop-color="#1865C4"
          class="linear-gradient-color2"
        />
      </linearGradient>
      <path
        id="viral-graph-6-e"
        d="M78.3375,137.83871 C109.986868,137.83871 135.64375,112.19618 135.64375,80.5645161 C135.64375,48.9328525 109.986868,23.2903226 78.3375,23.2903226 C46.6881321,23.2903226 21.03125,48.9328525 21.03125,80.5645161 C21.03125,112.19618 46.6881321,137.83871 78.3375,137.83871 Z M78.3375,137.83871 C109.986868,137.83871 135.64375,112.19618 135.64375,80.5645161 C135.64375,48.9328525 109.986868,23.2903226 78.3375,23.2903226 C46.6881321,23.2903226 21.03125,48.9328525 21.03125,80.5645161 C21.03125,112.19618 46.6881321,137.83871 78.3375,137.83871 Z M78.3375,137.83871 C109.986868,137.83871 135.64375,112.19618 135.64375,80.5645161 C135.64375,48.9328525 109.986868,23.2903226 78.3375,23.2903226 C46.6881321,23.2903226 21.03125,48.9328525 21.03125,80.5645161 C21.03125,112.19618 46.6881321,137.83871 78.3375,137.83871 Z"
      />
      <filter
        id="viral-graph-6-d"
        width="141%"
        height="141%"
        x="-20.5%"
        y="-18.8%"
        filterUnits="objectBoundingBox"
      >
        <feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur
          in="shadowOffsetOuter1"
          result="shadowBlurOuter1"
          stdDeviation="7.5"
        />
        <feComposite
          in="shadowBlurOuter1"
          in2="SourceAlpha"
          operator="out"
          result="shadowBlurOuter1"
        />
        <feColorMatrix
          in="shadowBlurOuter1"
          values="0 0 0 0 0.325490196 0 0 0 0 0.584313725 0 0 0 0 0.894117647 0 0 0 0.5 0"
          class="color-matrix"
        />
      </filter>
      <linearGradient
        id="viral-graph-6-g"
        x1="50%"
        x2="50%"
        y1="0%"
        y2="98.69%"
      >
        <stop offset="0%" stop-color="#FFF" />
        <stop
          offset="100%"
          stop-color="#D5E3F4"
          stop-opacity="0"
          class="linear-gradient-color2"
        />
      </linearGradient>
    </defs>
    <g fill="none" fill-rule="evenodd" transform="translate(8 15)">
      <path
        fill="#E8E9EF"
        fill-rule="nonzero"
        d="M139.401086,107.161058 C142.622318,99.6675697 144.442186,91.6201275 144.739784,83.3201617 L155.444117,83.3201613 C155.142908,93.0511559 153.035434,102.503542 149.26683,111.299918 L139.401086,107.161058 C139.335143,107.314458 139.268614,107.467627 139.201498,107.62056 L148.998018,111.919826 C149.08854,111.713556 149.178145,111.506919 149.26683,111.299918 L139.401086,107.161058 Z M144.78156,80.4024194 C144.725618,72.6140099 143.326514,65.0494451 140.736619,57.9751993 L150.631874,53.8874487 C153.743891,62.240092 155.423081,71.1889251 155.480187,80.4024194 L144.781561,80.4024194 Z M139.673165,55.2576129 C136.342936,47.2711112 131.463161,39.9789446 125.265752,33.7866885 L132.425972,25.8203367 C139.81518,33.0972036 145.623067,41.7163694 149.561328,51.172792 L139.673165,55.2576129 Z M123.156563,31.7685658 C123.13938,31.7528224 123.122189,31.737087 123.104988,31.7213596 L128.63488,25.6734731 L123.156563,31.7685657 Z"
      />
      <path
        class="linear-gradient-color4"
        fill="#7CB8FF"
        fill-rule="nonzero"
        d="M95.4103029,16.5280107 C89.8251593,15.0334732 84.0276353,14.2669355 78.12125,14.2669355 C73.4636022,14.2669355 68.8927002,14.7485346 64.4638849,15.6719592 L61.929137,5.27197294 C67.1757134,4.15281891 72.595994,3.56854839 78.12125,3.56854839 C84.9827588,3.56854839 91.7271551,4.46287278 98.2267307,6.20688585 L95.4103028,16.5280111 Z M98.2119611,17.3443625 L101.030223,7.01651591 C101.703099,7.22476078 102.373088,7.44225104 103.040043,7.66893537 L99.5973002,17.7982496 C99.1371867,17.6418666 98.6753865,17.4905633 98.2119611,17.3443625 Z M61.6211333,16.3300779 C52.9130241,18.5484881 44.8178749,22.4912291 37.7750881,27.8425008 L31.0927268,19.4841433 C39.3447785,13.1693004 48.8529059,8.52589078 59.0874709,5.93454562 L61.6211333,16.3300779 Z M35.4910201,29.6580774 C28.39736,35.5542625 22.4993459,42.9428061 18.2944642,51.4661627 L8.62366972,46.8882662 C13.5375347,36.8716308 20.4654933,28.1991318 28.8093952,21.300641 L35.4910201,29.6580774 Z M17.063593,54.1116399 C13.4509179,62.3268537 11.5295002,71.2252028 11.4639244,80.4024194 L0.75931288,80.4024194 C0.825814134,69.6318612 3.1010954,59.1718821 7.38614972,49.530596 L17.063592,54.1116394 Z M16.676581,106.767821 L6.82377768,110.949548 C3.15002694,102.256725 1.09274216,92.925364 0.795402671,83.3201616 L11.5057125,83.3201613 C11.7982133,91.4775816 13.5610908,99.3895363 16.6765827,106.76782 Z"
      />
      <g fill-rule="nonzero" transform="rotate(82 114.45 18.78)">
        <use
          fill="#000"
          filter="url(#viral-graph-6-a)"
          xlink:href="#viral-graph-6-b"
        />
        <use fill="url(#viral-graph-6-c)" xlink:href="#viral-graph-6-b" />
      </g>
      <use
        fill="#000"
        filter="url(#viral-graph-6-d)"
        xlink:href="#viral-graph-6-e"
      />
      <ellipse
        cx="78.338"
        cy="80.565"
        rx="56.819"
        ry="56.79"
        fill="url(#viral-graph-6-f)"
        stroke="#181818"
        stroke-linejoin="round"
        stroke-opacity=".2"
      />
      <ellipse
        cx="78.338"
        cy="80.565"
        stroke="url(#viral-graph-6-g)"
        stroke-width="2"
        opacity=".402"
        rx="49.819"
        ry="49.79"
      />
      <path
        fill="#FFF"
        d="M78.3339844,100.615234 C69.5668945,100.615234 62.5224609,93.9399414 62.5224609,85.6342773 C62.5224609,82.7426758 63.3530273,80.1586914 64.8603516,77.9438477 L77.9033203,56.5336914 L86.8242188,56.5336914 L77.4418945,70.8378906 L78.3955078,70.8378906 C87.0395508,70.8378906 94.2070312,76.9287109 94.2070312,85.3881836 C94.2070312,93.4477539 87.5317383,100.615234 78.3339844,100.615234 Z M78.3955078,93.324707 C82.855957,93.324707 86.578125,89.7871094 86.578125,85.449707 C86.578125,81.1123047 82.855957,77.574707 78.3955078,77.574707 C73.8735352,77.574707 70.1513672,81.1123047 70.1513672,85.449707 C70.1513672,89.7871094 73.8735352,93.324707 78.3955078,93.324707 Z"
      />
    </g>
  </svg>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "Level6",
});
</script>
