<template>
  <div class="supporter-registration-form__wrapper">
    <FormPresentationLayout
      :description-image-link="viewCopy.imageLink"
      :description-image-title="viewCopy.imageTitle"
      :description-text="viewCopy.imageDescription"
      :has-return-button="hasPreviousPage"
      :has-reversed-backgrounds="true"
      :has-sticky-header="true"
      content-vertical-alignment="top"
      @previous-page="onReturnButtonClick"
    >
      <template #header>
        <i18n-t
          class="form-presentation-layout__content-headline"
          keypath="supporters.form.importances.headline.title"
          tag="h2"
        >
          <template #highlight>
            <mark>
              {{ formCopy.importances.headline.highlight }}
            </mark>
          </template>
        </i18n-t>
      </template>
      <template #content>
        <div class="supporter-registration-form__container">
          <ElForm
            ref="supporterRegistrationForm"
            :model="fields"
            class="supporter-registration-form__body"
            @validate="updateSubmitState"
          >
            <ElFormItem v-if="criteriaImportances.length" prop="importances">
              <!-- Level Importance -->
              <PxSliderCard
                v-model="fields.importances.level_weight"
                :slider-stops="criteriaImportances"
                :start-on-middle="true"
                :title="formCopy.importances.level"
                class="supporter-registration-form__card"
              >
                <template #sub-header>
                  <div>
                    <PxButton
                      class="supporter-registration-form__card-link el-button--link-dark"
                      type="link"
                      @click="onClickGoTo('assessment')"
                    >
                      {{ $t("common.edit") }}
                    </PxButton>
                  </div>
                </template>
                <template #body>
                  <div class="supporter-registration-form__card-content">
                    <PxIcon
                      :name="`level-range-graph/level-${investingLevelRangeInterval}`"
                      :size="50"
                      class="supporter-registration-form__card-icon"
                    />
                    <span class="supporter-registration-form__card-title">
                      {{ investingLevelRangeTitle }}
                    </span>
                  </div>
                </template>
              </PxSliderCard>
              <!-- Sectors Importance -->
              <PxSliderCard
                v-if="supporterSectorsOfInterest"
                v-model="fields.importances.sectors_weight"
                :slider-stops="criteriaImportances"
                :start-on-middle="true"
                :title="formCopy.importances.sector"
                class="supporter-registration-form__card"
              >
                <template #sub-header>
                  <div>
                    <PxButton
                      v-if="hasMoreItemsOrNone(supporterSectorsOfInterest)"
                      :disabled="!supporterSectorsOfInterest.length"
                      class="supporter-registration-form__card-link"
                      type="link"
                      @click="
                        onClickMoreHandler(
                          formCopy.importances.sector,
                          supporterSectorsOfInterest,
                        )
                      "
                    >
                      <template v-if="hasMoreItems(supporterSectorsOfInterest)">
                        {{
                          $t("common.seeAll", {
                            number: getTotalItems(supporterSectorsOfInterest),
                          })
                        }}
                      </template>
                      <template v-else>
                        {{ formCopy.importances.emptyState.sector }}
                      </template>
                    </PxButton>
                    <PxButton
                      class="supporter-registration-form__card-link el-button--link-dark"
                      type="link"
                      @click="onClickGoTo('basicInterests')"
                    >
                      {{ $t("common.edit") }}
                    </PxButton>
                  </div>
                </template>
                <template #body>
                  <div class="supporter-registration-form__card-content">
                    <ul
                      class="supporter-registration-form__card-list is-trucanted"
                    >
                      <li
                        v-for="(item, index) in supporterSectorsOfInterest"
                        :key="index"
                        class="supporter-registration-form__card-list-item is-capitalize"
                      >
                        <template v-if="item.subitems">
                          {{ item.name }}
                          <span>({{ item.subitems.length }})</span>
                        </template>
                        <template v-else>
                          {{ item.name }}
                        </template>
                      </li>
                    </ul>
                  </div>
                </template>
              </PxSliderCard>
              <!-- Locations Importance -->
              <PxSliderCard
                v-if="supporterLocationsOfInterest"
                v-model="fields.importances.locations_weight"
                :slider-stops="criteriaImportances"
                :start-on-middle="true"
                :title="formCopy.importances.location"
                class="supporter-registration-form__card"
              >
                <template #sub-header>
                  <div>
                    <PxButton
                      v-if="hasMoreItemsOrNone(supporterLocationsOfInterest)"
                      :disabled="!supporterLocationsOfInterest.length"
                      class="supporter-registration-form__card-link"
                      type="link"
                      @click="
                        onClickMoreHandler(
                          formCopy.importances.location,
                          supporterLocationsOfInterest,
                        )
                      "
                    >
                      <template
                        v-if="hasMoreItems(supporterLocationsOfInterest)"
                      >
                        {{
                          $t("common.seeAll", {
                            number: getTotalItems(supporterLocationsOfInterest),
                          })
                        }}
                      </template>
                      <template v-else>
                        {{ formCopy.importances.emptyState.location }}
                      </template>
                    </PxButton>
                    <PxButton
                      class="supporter-registration-form__card-link el-button--link-dark"
                      type="link"
                      @click="onClickGoTo('basicInterests')"
                    >
                      {{ $t("common.edit") }}
                    </PxButton>
                  </div>
                </template>
                <template #body>
                  <div class="supporter-registration-form__card-content">
                    <ul
                      class="supporter-registration-form__card-list is-trucanted"
                    >
                      <li
                        v-for="(item, index) in supporterLocationsOfInterest"
                        :key="index"
                        class="supporter-registration-form__card-list-item is-capitalize"
                      >
                        <template v-if="item.subitems">
                          {{ item.name }}
                          <span>({{ item.subitems.length }})</span>
                        </template>
                        <template v-else>
                          {{ item.name }}
                        </template>
                      </li>
                    </ul>
                  </div>
                </template>
              </PxSliderCard>
              <template v-if="fields.importances.questions.length">
                <!-- Criteria Importance -->
                <PxSliderCard
                  v-for="(criteria, index) in criteriaResponses"
                  :key="`criteria-importance:${index}`"
                  v-model="fields.importances.questions[index].criteria_weight"
                  :slider-stops="criteriaImportances"
                  :start-on-middle="true"
                  :title="`${(criteria.response.question as IMatchingQuestion).resource_question}`"
                  class="supporter-registration-form__card"
                >
                  <template #sub-header>
                    <div>
                      <PxButton
                        v-if="hasMoreItemsOrNone(criteria.items)"
                        :disabled="!criteria.items.length"
                        class="supporter-registration-form__card-link"
                        type="link"
                        @click="
                          onClickMoreHandler(
                            `${(criteria.response.question as IMatchingQuestion).resource_question}`,
                            criteria.items,
                          )
                        "
                      >
                        <template v-if="hasMoreItems(criteria.items)">
                          {{
                            $t("common.seeAll", {
                              number: getTotalItems(criteria.items),
                            })
                          }}
                        </template>
                      </PxButton>
                      <PxButton
                        class="supporter-registration-form__card-link el-button--link-dark"
                        type="link"
                        @click="onClickGoTo('questions', criteria.order)"
                      >
                        {{ $t("common.edit") }}
                      </PxButton>
                    </div>
                  </template>
                  <template #body>
                    <div class="supporter-registration-form__card-content">
                      <ul
                        class="supporter-registration-form__card-list is-truncated--multiline"
                      >
                        <li
                          v-for="(item, key) in criteria.items"
                          :key="key"
                          class="supporter-registration-form__card-list-item"
                          v-text="item.name"
                        />
                      </ul>
                    </div>
                  </template>
                </PxSliderCard>
                <!-- Additional Criteria Importance -->
                <PxSliderCard
                  v-for="(
                    additionalCriteria, index
                  ) in supporterAdditionalCriteria"
                  :key="`additional-criteria-importance:${index}`"
                  v-model="
                    fields.importances.questions[
                      criteriaResponses.length + index
                    ].criteria_weight
                  "
                  :slider-stops="criteriaImportances"
                  :start-on-middle="true"
                  :title="`${additionalCriteria.response.category} ${additionalCriteria.response.shortName}`"
                  class="supporter-registration-form__card"
                >
                  <template #sub-header>
                    <div>
                      <PxButton
                        v-if="hasMoreItemsOrNone(additionalCriteria.items)"
                        :disabled="!additionalCriteria.items.length"
                        class="supporter-registration-form__card-link"
                        type="link"
                        @click="
                          onClickMoreHandler(
                            `${additionalCriteria.response.category} ${additionalCriteria.response.shortName}`,
                            additionalCriteria.items,
                          )
                        "
                      >
                        <template v-if="hasMoreItems(additionalCriteria.items)">
                          {{
                            $t("common.seeAll", {
                              number: getTotalItems(additionalCriteria.items),
                            })
                          }}
                        </template>
                      </PxButton>
                      <PxButton
                        class="supporter-registration-form__card-link el-button--link-dark"
                        type="link"
                        @click="onClickGoTo('additionalInterests')"
                      >
                        {{ $t("common.edit") }}
                      </PxButton>
                    </div>
                  </template>
                  <template #body>
                    <div class="supporter-registration-form__card-content">
                      <ul
                        class="supporter-registration-form__card-list is-truncated--multiline"
                      >
                        <li
                          v-for="(item, key) in additionalCriteria.items"
                          :key="key"
                          class="supporter-registration-form__card-list-item"
                          v-text="item.name"
                        />
                      </ul>
                    </div>
                  </template>
                </PxSliderCard>
              </template>
            </ElFormItem>
          </ElForm>
        </div>
      </template>
    </FormPresentationLayout>
    <NavigationFooter>
      <template #center>
        <PxButton
          :loading="formLoading || viewLoading"
          class="navigation-footer__main-cta"
          type="green"
          @click="onNextButtonClick"
        >
          {{ ctaCopy.finish }}
        </PxButton>
      </template>
      <template #right>
        <PxButton v-if="skipEnabled" type="link" @click="onSkipAllButtonClick">
          {{ ctaCopy.setupCriteriaLater }}
        </PxButton>
      </template>
    </NavigationFooter>
    <PxListModal
      v-model:items="listModal.items"
      v-model:title="listModal.title"
      v-model:visibility="listModal.visibility"
      :capitalize="true"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import FormPresentationLayout from "@/modules/supporters/components/form-presentation-layout/form-presentation-layout.vue";
import SupportersFlowMixin from "@/modules/supporters/mixins/supporters-flow.mixin";
import NavigationFooter from "@/modules/supporters/components/navigation-footer/navigation-footer.vue";
import { ISliderCardStop } from "@/components/px-slider-card/px-slider-card.vue";
import { IMatchingCriteriaWeight } from "@/modules/matching/services/data/matching-criteria/matching-criteria.interface";
import { IModalListGroup } from "@/components/px-list-modal/px-list-modal.vue";
import {
  IMultiSelection,
  IMultiSelectorItem,
} from "@/components/multi-selector/multi-selector.interface";
import { IMatchingResponse } from "@/services/data/matching-responses/matching-response.interface";
import { EAdditionalCriteriaGetter } from "@/modules/supporters/services/store/additional-criteria/additional-criteria.interface";
import { MatchingCriteriaWeightState } from "@/modules/matching/services/store/matching-criteria-weight/matching-criteria-weight.module";
import {
  DATE,
  FREE_RESPONSE,
  IMatchingQuestion,
  MULTI_SELECT,
  NUMERIC,
  RANGE,
  SINGLE_SELECT,
} from "@/services/data/matching-questionary/matching-question.interface";
import { EAffiliateGetter } from "@/modules/supporters/services/store/affiliate/affiliate.interface";
import { IMatchingAnswer } from "@/services/data/matching-questionary/matching-answer.interface";
import uniq from "lodash/uniq";
import { ISupporterFlowImportances } from "@/modules/supporters/services/data/supporter-flow/supporter-flow.interface";
import { IMatchingQuestionaryCriteria } from "@/services/data/matching-questionary-criteria/matching-questionary-criteria.interface";

interface ICardListItemResponse {
  response: IMatchingResponse & {
    shortName?: string;
    category?: string;
  };

  // Registered to know, in case of sorting, the original index order
  order: number;

  items: Array<ICardListItem>;
}

interface ICardListItem {
  name: string;
  subitems?: Array<string>;
}

const VISIBLE_CARD_ITEMS_TEXT_TRESHOLD = 48;

export default defineComponent({
  name: "CriteriaImportance",

  components: {
    FormPresentationLayout,
    NavigationFooter,
  },

  mixins: [SupportersFlowMixin],

  data() {
    return {
      fields: {
        importances: {
          level_weight: null,
          sectors_weight: null,
          locations_weight: null,
          questions: [],
        } as ISupporterFlowImportances,
      },

      listModal: {
        title: "",
        items: [] as Array<IModalListGroup | string>,
        visibility: false,
      },
    };
  },

  computed: {
    viewCopy() {
      return this.$tm("supporters.view.criteriaImportance") as {
        imageTitle: string;
        imageDescription: string;
        imageLink: {
          original: string;
          retina: string;
        };
      };
    },

    innerNavigation(): { [key: string]: string } | null {
      return (
        (this.$route?.meta?.innerNavigation as { [key: string]: string }) ||
        null
      );
    },

    criteriaImportances(): Array<ISliderCardStop> {
      return this.criteriaWeights.map((weight) => ({
        label: weight.name,
        value: weight.id,
      }));
    },

    supporterInvestingLevelRange(): Array<number> {
      return this.flowData.supporter.investing_level_range || [1, 1];
    },

    investingLevelRangeInterval(): string {
      if (this.supporterInvestingLevelRange.length > 1) {
        return this.supporterInvestingLevelRange[0] ===
          this.supporterInvestingLevelRange[1]
          ? this.supporterInvestingLevelRange[0].toString()
          : this.supporterInvestingLevelRange.join("-");
      }

      return this.supporterInvestingLevelRange[0].toString();
    },

    investingLevelRangeTitle(): string {
      const uniqRange = uniq(this.supporterInvestingLevelRange);
      const rangeSize = uniqRange[1] ? 1 : 0;
      return this.$t(
        `profile.investors.panel.label[${rangeSize}]`,
        uniqRange,
      ) as string;
    },

    supporterSectorsOfInterest(): Array<ICardListItem> {
      return this.mapMultiSelectorItems(this.flowData.meta?.sectors || []);
    },

    supporterLocationsOfInterest(): Array<ICardListItem> {
      return this.mapMultiSelectorItems(this.flowData.meta?.locations || []);
    },

    criteriaWeights(): Array<IMatchingCriteriaWeight> {
      return this.$store.getters[MatchingCriteriaWeightState.Getter.VALUES];
    },

    additionalCriteriaValues() {
      return this.$store.getters[
        EAdditionalCriteriaGetter.MULTI_SELECTOR_VALUES
      ];
    },

    additionalCriteriaFlattenValues(): Array<IMultiSelectorItem> {
      return this.additionalCriteriaValues.reduce(
        (accumulator: Array<IMultiSelectorItem>, item: IMultiSelectorItem) => [
          ...accumulator,
          ...(item?.children || []),
        ],
        [],
      );
    },

    eligibleCriteria(): IMatchingResponse[] {
      // Criteria that impacts matching results:
      return this.flowData.criteria.filter(
        (criteria) =>
          !!criteria?.answers ||
          (!!criteria?.value &&
            ("value" in criteria.value ||
              ("min" in criteria.value && "max" in criteria.value))),
      );
    },

    eligibleAdditionalCriteria(): IMatchingResponse[] {
      return (this.flowData.additional_criteria || []).filter(
        (criteria) =>
          !!criteria?.answers ||
          (!!criteria?.value &&
            ("value" in criteria.value ||
              ("min" in criteria.value && "max" in criteria.value))),
      );
    },

    supporterAdditionalCriteria(): Array<ICardListItemResponse> {
      return this.eligibleAdditionalCriteria.map(
        (criteria: IMatchingResponse, index: number) => {
          // Fetch selector item that corresponds to selected question in value
          const valueFound = this.additionalCriteriaFlattenValues.find(
            (item: IMultiSelectorItem) => criteria.question === item.value,
          ) as IMultiSelectorItem;

          // Matching question is available on meta data for additional criteria
          const response = {
            ...criteria,
            question: valueFound.meta as IMatchingQuestion,
            shortName: valueFound.label,
            category: valueFound.parent?.label || "",
          } as IMatchingResponse;

          return {
            response,
            order: index,
            items: this.getResponseValues(response),
          };
        },
      );
    },

    questions(): Array<IMatchingQuestion> {
      return this.$store.getters[EAffiliateGetter.QUESTION_BUNDLE];
    },

    criteriaResponses(): Array<ICardListItemResponse> {
      // Map questions to responses and filter undefined responses
      return (
        this.eligibleCriteria
          .map((criteria: IMatchingResponse, index: number) => {
            const question =
              this.questions.find(
                (question: IMatchingQuestion) =>
                  question.id === criteria.question,
              ) || ({} as IMatchingQuestion);

            const response = {
              ...criteria,
              question,
            } as IMatchingResponse;

            return {
              response,
              order: index,
              items: this.getResponseValues(response),
            };
          })
          .filter(
            (item: ICardListItemResponse) =>
              !!(item.response.question as IMatchingQuestion)?.id,
          )
          // Move free-response questions to bottom since they, as of now, aren't relevant for matching:
          // https://pixelmatters.atlassian.net/browse/VIR-950
          .sort(
            (itemA: ICardListItemResponse, itemB: ICardListItemResponse) => {
              const typeA = Number(
                (itemA.response.question as IMatchingQuestion)?.question_type
                  .type === FREE_RESPONSE,
              );
              const typeB = Number(
                (itemB.response.question as IMatchingQuestion)?.question_type
                  .type === FREE_RESPONSE,
              );
              return typeA > typeB ? 1 : typeB > typeA ? -1 : 0;
            },
          )
      );
    },
  },

  created() {
    this.setCriteriaImportances();
  },

  methods: {
    setCriteriaImportances() {
      // Level Range, Locations, and Sectors
      this.fields.importances.level_weight =
        this.flowData.importances?.level_weight || null;
      this.fields.importances.locations_weight =
        this.flowData.importances?.locations_weight || null;
      this.fields.importances.sectors_weight =
        this.flowData.importances?.sectors_weight || null;

      // Criteria and additional criteria
      this.fields.importances.questions = [
        ...this.criteriaResponses.map((item: ICardListItemResponse) => ({
          question: (item.response.question as IMatchingQuestion).id,
          criteria_weight:
            this.flowData.importances?.questions.find(
              (criteria: IMatchingQuestionaryCriteria) =>
                criteria.question ===
                (item.response.question as IMatchingQuestion).id,
            )?.criteria_weight || null,
        })),

        ...this.supporterAdditionalCriteria.map(
          (item: ICardListItemResponse) => ({
            question: (item.response.question as IMatchingQuestion).id,
            criteria_weight:
              this.flowData.importances?.questions.find(
                (criteria: IMatchingQuestionaryCriteria) =>
                  criteria.question ===
                  (item.response.question as IMatchingQuestion).id,
              )?.criteria_weight || null,
          }),
        ),
      ];
    },

    onClickGoTo(routeName: string, step: number | string = "0") {
      const route = this.innerNavigation
        ? this.innerNavigation[routeName]
        : null;

      if (!route) return;

      this.$router.push({ name: routeName, params: { step } });
    },

    onClickMoreHandler(modalTitle: string, modalItems: Array<ICardListItem>) {
      this.listModal.title = modalTitle;
      this.listModal.items = modalItems.map((list) => {
        return !list.subitems
          ? list.name
          : {
              name: list.name,
              items: list.subitems,
            };
      });
      this.listModal.visibility = true;
    },

    getTotalItems(items: Array<ICardListItem>) {
      return items.reduce((count, item) => {
        // Count own item
        count++;

        // Count group children if they exist
        if ("subitems" in item && !!item.subitems && item.subitems.length) {
          count += item.subitems.length;
        }

        return count;
      }, 0);
    },

    getTotalOfSubItems(items: Array<ICardListItem>) {
      return items.reduce((count, item) => {
        // Count group children if they exist
        if ("subitems" in item && !!item.subitems && item.subitems.length) {
          count += item.subitems.length;
        }

        return count;
      }, 0);
    },

    getTotalItemsTextLength(items: Array<ICardListItem>) {
      return items.reduce((count, item) => {
        count += item.name.length;
        return count;
      }, 0);
    },

    hasMoreItems(items: Array<ICardListItem>) {
      return (
        this.getTotalOfSubItems(items) ||
        this.getTotalItemsTextLength(items) > VISIBLE_CARD_ITEMS_TEXT_TRESHOLD
      );
    },

    hasMoreItemsOrNone(items: Array<ICardListItem>) {
      return !items.length || this.hasMoreItems(items);
    },

    getResponseValues(response: IMatchingResponse): Array<ICardListItem> {
      const question = response.question as IMatchingQuestion;
      const numericCurrency = question.question_type.meta?.currency ? "$" : "";

      switch (question.question_type.type) {
        case RANGE: {
          const rangeValue = (response.value?.value as number).toLocaleString();
          return [{ name: `${numericCurrency}${rangeValue}` }];
        }

        case NUMERIC: {
          const numericMin = (response.value?.min as number).toLocaleString();
          const numericMax = (response.value?.max as number).toLocaleString();
          return [
            {
              name: `${numericCurrency}${numericMin} - ${numericCurrency}${numericMax}`,
            },
          ];
        }
        case FREE_RESPONSE: {
          return [{ name: `${response.value?.text || ""}` }];
        }

        case SINGLE_SELECT:
        case MULTI_SELECT: {
          // Map answer value
          return response.answers.map((answer: number) => {
            const answerText =
              question.answers.find(
                (innerAnswer: IMatchingAnswer) => innerAnswer.id === answer,
              )?.value || "";

            return { name: answerText };
          });
        }

        case DATE: {
          return [{ name: `${response.value?.date || ""}` }];
        }

        default: {
          return [{ name: `${response.value?.value || ""}` }];
        }
      }
    },

    mapMultiSelectorItems(
      selections: Array<IMultiSelection>,
    ): Array<ICardListItem> {
      return selections.map((selection: IMultiSelection) => {
        if (selection.selected_parent) {
          const parent = selection.selected_parent;

          // No children found, at least return parent name
          if (!parent.children || parent.children.length === 0) {
            return { name: parent.label };
          }

          const selectedChildren = (parent.children || []).filter(
            (child: IMultiSelectorItem) => child.selected,
          );

          if (selectedChildren.length > 1) {
            return {
              name: parent.label,
              subitems:
                selectedChildren.map(
                  (child: IMultiSelectorItem) => child.label,
                ) || [],
            };
          }
        }

        // Just one selected child found, return it
        return {
          name: selection.selected_children[0].label,
        };
      });
    },
  },
});
</script>

<style lang="scss" scoped>
// Custom positioning for description
.supporter-registration-form__wrapper :deep() {
  .form-presentation-layout__content-wrapper {
    padding: 46px 0 30px 59px;
  }

  .form-presentation-layout__return-button {
    position: relative;
    top: -15px;
    left: -1px;
    margin-bottom: 8px;
  }

  .form-presentation-layout__description-container {
    position: relative;
    top: -13px;
  }

  .form-presentation-layout__description-text {
    max-width: 340px;
    padding-left: 9px;
    margin: 11px auto 0;
    letter-spacing: -0.3px;
  }
}

// Custom style for sticky header
.supporter-registration-form__wrapper
  :deep()
  .form-presentation-layout__content-header-wrapper--sticky {
  display: flex;
  flex-flow: row wrap;

  .form-presentation-layout__return-button {
    top: 2px;
    margin-right: 10px;
    margin-bottom: 0;
  }
}

.supporter-registration-form__container {
  display: grid;
  grid-gap: 51px;
  max-width: 395px;
  padding-top: 28px;
  padding-bottom: 80px;
}

.supporter-registration-form__body {
  display: grid;
  grid-gap: 30px;
}

.supporter-registration-form__body :deep() {
  .el-form-item__label {
    padding: 0 12px 4px 0;
  }

  .el-input__inner {
    margin: 0;
    line-height: 40px;
  }

  .el-radio-group {
    display: inline-flex;
    flex-flow: row wrap;
    width: 100%;
    margin-top: 7px;
  }

  .el-radio {
    flex-basis: 50%;
    margin-left: 0;
    color: $ebony-clay;

    &:nth-child(n + 3) {
      margin-top: 10px;
    }
  }

  .locations-input {
    margin-top: 2px;
  }

  .locations-selector {
    margin-top: 4px;
  }

  .locations-selector__list:empty + .locations-input {
    margin-top: 11px;
  }

  .locations-selector__list--with-entries {
    margin-top: 11px;
  }
}

.supporter-registration-form__card {
  width: 395px;

  &:not(:first-child) {
    margin-top: 40px;
  }
}

.supporter-registration-form__card-content {
  padding: 8px 15px;
}

.supporter-registration-form__card-title {
  display: inline-block;
  font-size: 14px;
  line-height: 25px;
  vertical-align: middle;
}

.supporter-registration-form__card-icon {
  margin-right: 13px;
  vertical-align: middle;
}

.supporter-registration-form__card-list {
  padding: 0;

  &.is-trucanted {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    list-style: none;
  }

  &.is-truncated--multiline {
    @include multiLineEllipsis(
      $font-size: 14px,
      $line-height: 25px,
      $lines-to-show: 2
    );
  }

  &-item {
    display: inline;
    font-size: 14px;
    line-height: 25px;

    &:not(:first-of-type)::before {
      color: $manatee;
      content: " • ";
      opacity: 0.6;
    }

    span {
      color: $manatee;
    }
  }

  &-item.is-capitalize {
    text-transform: capitalize;
  }
}

.supporter-registration-form__card-link {
  &:not(:first-child) {
    margin-left: 14px;
  }

  :deep(span) {
    font-size: 14px;
  }
}

.supporter-registration-form__card-link.is-disabled {
  opacity: 1;

  :deep(span) {
    text-decoration: none;
  }
}

.supporter-registration-form__wrapper .navigation-footer__main-cta :deep(span) {
  margin-bottom: 2px;
  font-size: 13px;
}

.form-presentation-layout__content-headline :deep() {
  @include grotesk(semiBold);

  max-width: 395px;
  margin-bottom: 0;
  font-size: 20px;
  line-height: 30px;

  mark {
    position: relative;
    z-index: z("default") + 1;
    margin-right: -1px;

    color: currentColor;
    background-color: transparent;
  }

  mark::before {
    position: absolute;
    top: 3px;
    left: -5px;
    z-index: z("bellow");
    width: calc(100% + 4px);
    height: 100%;

    content: "";
    background-image: url("#{$assetsPath}/img/text-marker-square.svg");
    background-repeat: no-repeat;
  }
}

.form-presentation-layout__content-header-wrapper--sticky
  .form-presentation-layout__content-headline
  :deep() {
  font-size: 14px;
  line-height: 30px;
  color: $manatee;

  mark {
    margin-right: 1px;
  }

  mark::before {
    display: none;
  }
}
</style>
