import { Module } from "vuex";
import { RootState } from "@/services/store/root-state";
import { make } from "vuex-pathify";

import { mutations } from "@/modules/milestone-planner/services/store/milestone-planner/sub-modules/onboardings/onboardings.mutations";
import { IMilestonePlannersOnboardingsState } from "@/modules/milestone-planner/services/store/milestone-planner/sub-modules/onboardings/onboardings.interface";

/**
 * Initial module state.
 */
const state: IMilestonePlannersOnboardingsState = {
  data: {},
};

/**
 * Milestone Planners passcode store module.
 */
export const onboardingsModule: Module<
  IMilestonePlannersOnboardingsState,
  RootState
> = {
  namespaced: true,
  state,
  mutations,
  actions: make.actions(state),
  getters: make.getters(state),
};
