<template>
  <div class="matching-card-options" data-testid="matching-card-options">
    <ElDropdown
      :class="{ 'is-open': optionsVisible }"
      class="matching-card-options__dropdown"
      trigger="click"
      @visible-change="onVisibleChange"
    >
      <PxButton
        :class="{ 'is-active': optionsVisible }"
        class="matching-card-options__dropdown-toggler"
        size="medium"
        @click.stop
      >
        <PxIcon :size="20" name="ellipsis--ebony-clay" />
      </PxButton>
      <template #dropdown>
        <ElDropdownMenu class="matching-card-options__dropdown-menu">
          <ElDropdownItem
            class="matching-card-options__danger-item"
            @click="deleteClickHandler"
            v-text="ctaCopy"
          />
        </ElDropdownMenu>
      </template>
    </ElDropdown>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "MatchingCardOptions",

  data() {
    return {
      optionsVisible: false,
    };
  },

  computed: {
    ctaCopy(): string {
      return this.$t("common.components.default.cta.removeFromList");
    },
  },

  methods: {
    onVisibleChange(state: boolean) {
      this.optionsVisible = state;
      this.$emit("is-options-open", this.optionsVisible);
    },

    deleteClickHandler() {
      this.$emit("delete");
    },
  },
});
</script>

<style lang="scss" scoped>
.matching-card-options__dropdown-toggler {
  min-width: 22px;
  padding: 0;
  background: none;

  :deep() &::before,
  :deep() &::after {
    opacity: 0;
  }
}

.matching-card-options__dropdown-menu {
  padding: 6px 8px 7px;
  margin-top: 7px;
  border-color: $turkish-angora;
  border-radius: 4px;
  box-shadow: 0 4px 12px -4px rgba($manatee, 0.2);

  &::before,
  &::after {
    display: none;
  }

  :deep() .el-dropdown-menu__item {
    @include grotesk(regular);

    min-width: 133px;
    padding: 7px 15px;
    font-size: 14px;
  }

  :deep(.matching-card-options__danger-item) {
    @include grotesk(medium);

    color: $wild-watermelon;
  }
}
</style>
