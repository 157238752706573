import { GenericProvider, Provider } from "@/services/data/generic-provider";
import { baseAPIUrl } from "@/services/utils/utils";
import { IMatchingScoreLoading } from "@/modules/matching/services/data/matching-score/matching-score-loading.interface";

@Provider(`${baseAPIUrl}/matching/scores/loading`)
class MatchingLoadingProvider extends GenericProvider<IMatchingScoreLoading> {
  public async fetch() {
    const url = this.buildEndPointUrl();
    const request = this.httpClient.get(url);
    const {
      data: { loading },
    } = await this.wrapRequest(request);

    return loading as boolean;
  }
}

export const matchingLoadingProvider = new MatchingLoadingProvider();
