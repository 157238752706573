<template>
  <div :class="{ 'is-editing': isEditing }" class="pxEditPanel">
    <div class="pxEditPanel-mainContainer">
      <div class="pxEditPanel-content">
        <span class="pxEditPanel-title">
          {{ title }}
        </span>
        <div
          v-show="!isEditing || isRedirecting"
          class="pxEditPanel-normalPane"
        >
          <slot name="normal" />
        </div>
        <div v-show="isEditing" class="pxEditPanel-editPane">
          <slot name="edit" />
        </div>
      </div>
      <div v-show="!isEditing || isRedirecting" class="pxEditPanel-actions">
        <PxButton
          class="pxEditPanel-editBtn"
          size="small"
          @click="onClickStartEdit"
        >
          {{ $t("common.edit") }}
        </PxButton>
      </div>
    </div>
    <div v-if="isEditing && !isRedirecting" class="pxEditPanel-footer">
      <div class="pxEditPanel-footerNotice">
        <i />
        <p>
          {{ $t("common.notices.changeProfile") }}
        </p>
      </div>
      <div class="pxEditPanel-footerButtons">
        <PxButton
          class="pxEditPanel-cancelBtn"
          type="link"
          @click="onClickCancel"
        >
          {{ $t("common.cancel") }}
        </PxButton>
        <PxButton
          :disabled="isSaveButtonDisabled"
          :loading="isLoading"
          class="pxEditPanel-saveBtn"
          type="green"
          @click="onClickSave"
        >
          {{ $t("common.save") }}
        </PxButton>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
  props: {
    title: {
      type: String,
      required: true,
    },

    isSaveButtonDisabled: {
      type: Boolean,
      default: false,
    },

    isRedirecting: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isEditing: false,
      isLoading: false,
    };
  },

  methods: {
    onClickStartEdit() {
      this.isEditing = true;
      this.$emit("edit");
    },

    afterSave(success = true) {
      this.isLoading = false;

      if (success) {
        this.isEditing = false;
      }
    },

    onClickCancel() {
      this.isEditing = false;
      this.$emit("cancel");
    },

    onClickSave() {
      this.isLoading = true;
      this.$emit("submit", this.afterSave);
    },
  },
});
</script>

<style lang="scss" scoped>
.pxEditPanel {
  padding: 9px 14px 13px;
  background-color: $alabaster;

  border: 1px solid rgba($mischka, 0.8);
  border-radius: 4px;

  @include breakpoint-up(md) {
    padding: 6px 19px 17px;
  }
}

.pxEditPanel-title {
  @include grotesk(semiBold);

  font-size: to-rem(12px);
  color: $manatee;
  text-transform: uppercase;
  letter-spacing: 0.6px;

  @include breakpoint-up(md) {
    font-size: to-rem(13px);

    letter-spacing: 0;
  }
}

.pxEditPanel-editBtn {
  min-width: 51px;
}

.pxEditPanel-mainContainer {
  display: flex;
  justify-content: space-between;
}

.pxEditPanel-actions {
  padding-top: 11px;

  @include breakpoint-up(md) {
    padding-top: 16px;
  }
}

.pxEditPanel-footer {
  margin-top: 15px;

  @include breakpoint-up(md) {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
  }
}

.pxEditPanel-footerNotice {
  display: flex;
  padding-bottom: 13px;

  border-bottom: 1px solid $mischka;

  @include breakpoint-up(md) {
    padding-bottom: 0;
    border: none;
  }
}

.pxEditPanel-footerNotice > p {
  @include grotesk(regular, italic);

  font-size: to-rem(13px);
  line-height: 18px;
}

.pxEditPanel-footerNotice > i {
  display: inline-block;
  flex-grow: 0;
  flex-shrink: 0;
  width: 14px;
  height: 14px;
  margin-right: 8px;

  background-image: url("#{$assetsPath}/img/icons/information--light-gray.svg");
  background-repeat: no-repeat;
}

.pxEditPanel-footerButtons {
  display: flex;
  justify-content: center;
  padding-top: 16px;
}

.pxEditPanel-saveBtn {
  min-width: 64px;
  margin-left: 15px;
}

.pxEditPanel + .pxEditPanel {
  margin-top: 15px;

  @include breakpoint-up(md) {
    margin-top: 20px;
  }
}

.pxEditPanel.is-editing {
  @include breakpoint-up(md) {
    padding-bottom: 13px;
  }

  .pxEditPanel-title {
    color: $gun-powder;
  }
}
</style>
