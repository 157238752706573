<template>
  <PxNavbar
    :loading="loading"
    :logo="logo"
    :show-headline="!logo"
    class="px-navbar-wizard__container"
    theme="wizard"
  >
    <template #navbar-right>
      <div class="px-navbar-wizard__menu">
        <a
          v-for="(step, index) in steps"
          :key="`flow-navbar__tab:${index}`"
          :class="{
            'px-navbar-wizard__menu-item--disabled': isStepDisabled(step),
            'px-navbar-wizard__menu-item--active': isStepActive(step),
            'px-navbar-wizard__menu-item--done': isStepCompleted(step),
          }"
          class="px-navbar-wizard__menu-item"
          @click="onStepClick(step)"
        >
          <span class="px-navbar-wizard__menu-item-number">
            {{ index + 1 }}
          </span>
          <span class="px-navbar-wizard__menu-item-label">
            {{ $t(step.labelKey) }}
          </span>
        </a>
      </div>
    </template>
  </PxNavbar>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { INavbarStep } from "@/components/px-navbar-wizard/px-navbar-wizard.interface";

export default defineComponent({
  name: "PxNavbarWizard",

  props: {
    steps: {
      type: Array as () => INavbarStep[],
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
    logo: {
      type: String,
      default: null,
    },
  },

  computed: {
    currentStep(): INavbarStep | undefined {
      return this.steps.find((step: INavbarStep) =>
        step.routes.some((route) => route === this.$route.name),
      );
    },

    isFinalStep(): boolean {
      const lastStep = [...this.steps].pop() as INavbarStep;

      if (!lastStep) {
        return false;
      }

      return (
        !!this.currentStep && lastStep.labelKey === this.currentStep.labelKey
      );
    },
  },

  methods: {
    getStepIndex(step: INavbarStep): number {
      return this.steps.findIndex(
        (innerStep: INavbarStep) => innerStep.labelKey === step.labelKey,
      );
    },

    isStepDisabled(step: INavbarStep): boolean {
      const stepIndex = this.getStepIndex(step);
      const isPreviousStepCompleted =
        stepIndex > 0 && this.isStepCompleted(this.steps[stepIndex - 1]);

      return (
        !!this.currentStep &&
        !isPreviousStepCompleted &&
        this.getStepIndex(step) > this.getStepIndex(this.currentStep)
      );
    },

    isStepActive(step: INavbarStep): boolean {
      return !!this.currentStep && step.labelKey === this.currentStep.labelKey;
    },

    isStepCompleted(step: INavbarStep): boolean {
      return !!step.isCompleted;
    },

    /**
     * On click go to first route of step
     * @param step
     */
    onStepClick(step: INavbarStep) {
      if (!this.isStepDisabled(step)) {
        this.$router.push({
          name: step.routes[0],
        });
      }
    },
  },
});
</script>

<style lang="scss" scoped>
.px-navbar-wizard__container {
  position: sticky;
  top: 0;
  height: 66px;
  overflow: hidden;
  z-index: 101;

  :deep() .px-navbar__wrapper {
    height: 66px;
    min-height: auto;
  }
}

.px-navbar-wizard__container :deep() {
  .px-navbar__right-side {
    flex-basis: auto;
    flex-grow: 1;
  }

  .px-navbar__logo {
    margin-right: 30px;
  }

  .px-navbar__headline {
    padding-left: 30px;
    margin: auto 0;
    font-size: 22px;
    line-height: 40px;
    letter-spacing: -0.18px;
    border-left: 1px solid $white;
  }
}

.px-navbar-wizard__menu {
  .px-navbar-wizard__menu-item:not(:last-child)::after {
    position: absolute;
    width: 1px;
    height: 69px;
    top: 0;
    right: 0;
    content: "";
    background-image: linear-gradient(
      to bottom,
      rgba($ebony-clay, 0),
      #004871 52%,
      rgba($ebony-clay, 0)
    );
  }
}

.px-navbar-wizard__menu-item {
  position: relative;
  display: inline-block;
  padding: 14px 30px;
  cursor: pointer;

  &--disabled {
    cursor: default;
  }

  &:not(&--disabled) + .px-navbar-wizard__menu-item--disabled {
    position: relative;

    &::before {
      position: absolute;
      width: 100vw;
      height: 100%;
      overflow: hidden;
      cursor: default;
      content: "";
      background-image: linear-gradient(
        to bottom,
        rgba($ebony-clay, 0),
        rgba($ebony-clay, 0.15)
      );
      transform: translate(-30px, -15px);
    }
  }

  &-number,
  &-label {
    @include grotesk(semiBold);

    font-size: 16px;
    color: $white;
  }

  &-label {
    line-height: 40px;
  }

  &-number {
    @include grotesk(bold);

    position: relative;
    display: inline-block;
    width: 23px;
    height: 23px;
    margin-right: 14px;
    font-size: 15px;
    line-height: 23px;
    color: rgba($white, 0.8);
    text-align: center;
    background: $ebony-clay;
    border-radius: 50%;
    opacity: 0.5;
  }

  &--disabled &-number {
    color: $cool-grey;
    opacity: 1;
  }

  &--disabled &-label {
    color: $ebony-clay;
  }
}

.px-navbar-wizard__menu-item--active {
  cursor: default;

  .px-navbar-wizard__menu-item-number {
    @include grotesk(bold);

    position: relative;
    display: inline-block;
    color: $nice-blue;
    background: #fff;
    opacity: 1;

    &::after {
      position: absolute;
      top: -3px;
      right: -3px;
      bottom: -3px;
      left: -3px;
      content: "";
      border: $white 1px solid;
      border-radius: 50%;
    }
  }
}

.px-navbar-wizard__menu-item--done {
  .px-navbar-wizard__menu-item-number {
    color: transparent;
    background-image: url("#{$assetsPath}/img/icons/icon-check-white-dark-blue.svg");
    background-position: center;
    background-size: 23px 23px;
    border: 1px solid $ebony-clay;
    opacity: 1;
  }

  &.px-navbar-wizard__menu-item--active {
    .px-navbar-wizard__menu-item-number::after {
      position: absolute;
      top: -4px;
      right: -4px;
      bottom: -4px;
      left: -4px;
      border: $ebony-clay 2px solid;
    }
  }
}
</style>
