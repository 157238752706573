export enum EListManagementContext {
  MATCHING = "matching",
  LIST_DIRECTORY = "listDirectory",
  LIST_DETAIL = "listDetail",
  PROFILE = "profile",
  SUBMISSION = "submission",
}

export enum EListManagementModalTab {
  SELECT = "select",
  CREATE = "create",
  EDIT = "edit",
}
