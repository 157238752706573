<template>
  <div
    v-loading="loading || !capitalExplorerTilesResults || !dataPrefilled"
    :class="{ 'comparison-table--no-labels': !allQuestionsAnswered }"
    class="comparison-table"
    element-loading-background="#fafafa"
  >
    <template v-if="!loading && capitalExplorerTilesResults && dataPrefilled">
      <header v-if="$user.isSupporter()">
        <RolePlayingBanner data-testid="comparison-table-role-playing-banner" />
      </header>
      <main>
        <div class="comparison-table__header">
          <PxPageHeader
            :subtitle="headerCopy.subtitle"
            :title="headerCopy.title"
            data-testid="comparison-table-page-header"
            @back-button-clicked="goToCapitalExplorer"
          >
            <template #action>
              <ShareResults
                v-if="$user.isEntrepreneur()"
                data-testid="comparison-table-share-button"
              />
            </template>
          </PxPageHeader>
        </div>
        <div class="comparison-table__wrapper">
          <PxButton
            v-show="showLeftButton"
            v-bind="$options.static.backButton"
            ref="leftButton"
            class="comparison-table__left-button"
            @click="scroll('left')"
          />
          <PxButton
            v-show="showRightButton"
            v-bind="$options.static.backButton"
            ref="rightButton"
            class="comparison-table__right-button"
            @click="scroll('right')"
          />
          <div
            ref="scrollContainer"
            class="comparison-table__scroll"
            @mousedown="handleMouseDown"
            @scroll.stop="handleScroll"
          >
            <UserResponsesTable
              :all-questions-answered="allQuestionsAnswered"
              :table-data="userResponsesTableData"
              class="comparison-table__left-table"
            />
            <CompareResponsesTable
              :all-questions-answered="allQuestionsAnswered"
              :answers-data="compareResponsesTableData"
              :match-details-modal-data="capitalExplorerTilesOrdered"
              :table-headers="tableHeaders"
              class="comparison-table__right-table"
            />
          </div>
        </div>
        <MatchDetailsTableLegend
          :legend-cards="tableLegend"
          class="comparison-table__legend"
        />
      </main>
    </template>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import PxPageHeader from "@/components/px-page-header/px-page-header.vue";
import ShareResults from "@/modules/capital-explorer/components/share-results/share-results.vue";
import UserResponsesTable from "@/modules/capital-explorer/components/comparison-tables/user-responses-table.vue";
import CompareResponsesTable from "@/modules/capital-explorer/components/comparison-tables/compare-responses-table.vue";
import MatchDetailsTableLegend, {
  ILegendCard,
} from "@/components/match-details-table/match-details-table-legend.vue";
import RolePlayingBanner from "@/modules/capital-explorer/components/role-playing-banner/role-playing-banner.vue";

import { CAPITAL_EXPLORER } from "@/modules/capital-explorer/services/router/routes-names";
import {
  ICapitalTileInfo,
  ICapitalExplorerData,
  ICapitalExplorerSubmission,
} from "@/modules/capital-explorer/services/data/capital-explorer/capital-explorer.interface";
import { IMatchingQuestion } from "@/services/data/matching-questionary/matching-question.interface";
import { IMatchingResponse } from "@/services/data/matching-responses/matching-response.interface";
import { ECapitalExplorerActions } from "@/modules/capital-explorer/services/store/capital-explorer/capital-explorer.types";
import { calculateResults } from "@/modules/capital-explorer/calculate-results";
import cloneDeep from "lodash/cloneDeep";
import {
  EPxButtonSize,
  EPxButtonVariant,
} from "@/components/px-button/px-button.types";

export default defineComponent({
  name: "ComparisonTableView",

  components: {
    PxPageHeader,
    UserResponsesTable,
    CompareResponsesTable,
    MatchDetailsTableLegend,
    ShareResults,
    RolePlayingBanner,
  },

  static: {
    backButton: {
      icon: "arrow--right-manatee",
      size: EPxButtonSize.XX_SMALL,
      variant: EPxButtonVariant.COMPACT,
    },
  },

  data() {
    return {
      showLeftButton: false,
      showRightButton: true,
      scrollAmount: 992,
      dataPrefilled: false,
      originX: 0,
      originLeft: 0,
      scrollContainer: null as null | HTMLElement,
    };
  },

  computed: {
    headerCopy() {
      return this.$tm("capitalExplorer.comparisonTable") as {
        title: string;
        subtitle: string;
        visitorTitle: string;
        visitorSubtitle: string;
      };
    },

    capitalExplorerData(): ICapitalExplorerData | null {
      return this.$store.get("capitalExplorer/data") || null;
    },

    capitalExplorerTilesResults(): ICapitalTileInfo[] | null {
      return this.capitalExplorerData?.results || null;
    },

    capitalExplorerTilesOrdered(): ICapitalTileInfo[] {
      const capitalExplorerResults = cloneDeep(
        this.capitalExplorerTilesResults,
      );

      return (
        capitalExplorerResults?.sort(
          (a, b) =>
            (b.matchProperties.score || 0) - (a.matchProperties.score || 0),
        ) || []
      );
    },

    loading(): boolean {
      return this.$store.get("capitalExplorer/loading");
    },

    questions(): IMatchingQuestion[] {
      return this.capitalExplorerData?.questions || [];
    },

    submission(): ICapitalExplorerSubmission {
      return this.capitalExplorerData?.submission as ICapitalExplorerSubmission;
    },

    responses(): IMatchingResponse[] {
      return this.submission?.responses || [];
    },

    tableHeaders(): Array<string> {
      return (
        this.capitalExplorerTilesOrdered?.map((result: ICapitalTileInfo) => {
          return result.capitalInfo.title;
        }) || []
      );
    },

    userResponsesTableData(): any {
      return this.questions.map((question) => {
        const questionResponse = this.responses.find(
          (response) => response.question === question.id,
        );

        return {
          id: question.id,
          question: question.entrepreneur_question,
          response:
            questionResponse?.answers.map(
              (answer) =>
                question?.answers.find(({ id }) => id === answer)?.value || "",
            ) || [],
          instructions: question.instructions,
        };
      });
    },

    compareResponsesTableData(): Array<any> {
      return this.questions.map((question) => {
        return this.capitalExplorerTilesOrdered?.map((tile) => {
          const tileDetails = tile.matchDetails.find(
            (detail) => detail.question.id === question.id,
          );

          return {
            responses: tileDetails?.desiredAnswers,
            matchIcon: !tileDetails?.userAnswers.length
              ? "unanswered"
              : tileDetails.match
                ? "match--green"
                : "no-match",
          };
        });
      });
    },

    tableLegend() {
      return this.$tm(`capitalExplorer.detailsModal.legend`) as ILegendCard[];
    },

    allQuestionsAnswered(): boolean {
      return this.responses.length === this.questions.length;
    },
  },

  async created() {
    if (!this.capitalExplorerTilesResults?.length) {
      await this.$store.dispatch(ECapitalExplorerActions.FETCH_DATA);
      await this.$store.dispatch(
        ECapitalExplorerActions.FETCH_AUTH_USER_SUBMISSION,
      );

      calculateResults();
    }

    this.dataPrefilled = true;
  },

  mounted() {
    this.scrollContainer = this.$refs.scrollContainer as HTMLElement;
  },

  methods: {
    goToCapitalExplorer() {
      return this.$router.push({ name: CAPITAL_EXPLORER });
    },

    handleScroll() {
      if (!this.scrollContainer) return;

      const maxScroll =
        this.scrollContainer.scrollWidth - this.scrollContainer.clientWidth;

      this.showLeftButton = this.scrollContainer.scrollLeft > 0;
      this.showRightButton =
        Math.ceil(this.scrollContainer.scrollLeft) < maxScroll;
    },

    scroll(direction: string) {
      const scrollAmount =
        direction === "left" ? -this.scrollAmount : this.scrollAmount;

      this.scrollContainer?.scrollBy({
        left: scrollAmount,
        behavior: "smooth",
      });
    },

    handleMouseDown(event: MouseEvent) {
      // Add active class to change cursor style
      this.scrollContainer?.classList.add("active");

      this.originX = event.pageX;
      this.originLeft = this.scrollContainer?.scrollLeft || 0;

      window.addEventListener("mouseup", this.onMouseUp);
      window.addEventListener("mousemove", this.onMouseMove);
    },

    onMouseUp() {
      // Remove previously added active class
      this.scrollContainer?.classList.remove("active");

      window.removeEventListener("mouseup", this.onMouseUp);
      window.removeEventListener("mousemove", this.onMouseMove);
    },

    onMouseMove(event: MouseEvent) {
      if (!this.scrollContainer) return;

      event.preventDefault();

      const offset = event.pageX - this.originX;
      const scrollSpeed = 1.5;

      this.scrollContainer.scrollLeft = this.originLeft - offset * scrollSpeed;
    },
  },
});
</script>

<style lang="scss" scoped>
.comparison-table {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;
  max-height: calc(100vh - 66px);

  header {
    flex-shrink: 0;
  }

  main {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: hidden;
  }

  &__header {
    border-bottom: 1px solid $turkish-angora;
    background-color: $white;
    flex-shrink: 0;

    :deep(.px-page-header) {
      max-width: 100%;
      margin: 0 104px;
      padding: 28px 0 29px;
    }

    :deep(.px-page-header h1) {
      margin-top: 4px;
      margin-bottom: 2px;

      font-size: 20px;
      line-height: 1.5;
      letter-spacing: -0.2px;
    }
  }

  &__wrapper {
    flex: 1;
    position: relative;
    display: flex;
    flex-direction: column;

    width: 100%;
    margin: 0 auto;
    overflow: hidden;
  }

  &__scroll {
    display: grid;
    grid-template-columns: 496px auto;

    overflow: scroll;
    margin: 30px;
    box-shadow: 0 4px 12px 0 rgba(139, 143, 161, 0.08);
    border: 1px solid rgba(0, 0, 0, 0.06);
    border-radius: 8px;
    flex: 1;
    cursor: pointer;

    &.active {
      cursor: grabbing;
    }

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__right-button,
  &__left-button {
    position: absolute;
    top: 101px;
    z-index: z("floaters") + 5;

    border-radius: 50%;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);
  }

  &__left-button {
    left: 513px;

    :deep(.px-icon) {
      transform: rotate(180deg);
    }
  }

  &__right-button {
    right: 20px;
  }

  &--no-labels {
    .comparison-table__right-button,
    .comparison-table__left-button {
      top: 72px;
    }
  }

  &__left-table {
    position: sticky;
    top: 0;
    left: 0;
    z-index: z("floaters") + 3;
    max-width: 496px;
    box-shadow: 4px 0 10px 0 rgba(39, 39, 64, 0.1);
  }

  &__legend {
    position: absolute;
    right: 24px;
    bottom: 24px;
    z-index: z("floaters") + 4;

    :deep(.el-collapse-item) {
      display: flex;
      flex-direction: column;
    }

    :deep(.el-collapse-item__header) {
      display: flex;
      align-self: flex-end;
      padding: 5px 32px 5px 12px;
      border-radius: 8px;
      box-shadow: 0 2px 8px 0 rgba(39, 39, 64, 0.12);
    }

    :deep(.el-collapse-item.is-active .el-collapse-item__header) {
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
      box-shadow: 0 -7px 8px 0 rgba(39, 39, 64, 0.03);
    }

    :deep(.el-collapse-item__header::before) {
      top: 2px;
      right: 12px;
    }

    :deep(.el-collapse-item__header:hover::before) {
      filter: invert(60%);
    }

    :deep(.el-collapse-item__header:hover span) {
      color: $manatee;
    }

    :deep(.el-collapse-item__wrap) {
      max-width: 864px;
      margin: 0;
      padding: 12px;
      border-radius: 8px 0 8px 8px;
      box-shadow: 0 2px 8px 0 rgba(39, 39, 64, 0.12);
    }

    :deep(.match-details-table-legend-card) {
      padding: 10px 13px 13px 11px;
    }
  }
}
</style>
