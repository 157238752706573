import { resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-02c189dc"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "capital-tile" }
const _hoisted_2 = {
  key: 0,
  class: "capital-tile__matching-badge"
}
const _hoisted_3 = { class: "capital-tile__header" }
const _hoisted_4 = ["title"]
const _hoisted_5 = ["title"]
const _hoisted_6 = { class: "capital-tile__badges" }
const _hoisted_7 = ["title"]
const _hoisted_8 = { class: "capital-tile__footer-title" }
const _hoisted_9 = { class: "capital-tile__stages" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PxStatusBadge = _resolveComponent("PxStatusBadge")!
  const _component_PxIcon = _resolveComponent("PxIcon")!
  const _component_MatchingCardTagList = _resolveComponent("MatchingCardTagList")!
  const _component_DetailsModal = _resolveComponent("DetailsModal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_Transition, { name: "fade" }, {
      default: _withCtx(() => [
        (_ctx.showMatchingBadge && _ctx.matchProperties.matchingBadge)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createVNode(_component_PxStatusBadge, {
                "background-color": _ctx.matchProperties.matchingBadge.backgroundColor,
                "border-color": _ctx.matchProperties.matchingBadge.borderColor,
                icon: _ctx.matchProperties.matchingBadge.icon,
                "icon-size": 13,
                label: _ctx.matchingBadgeLabel,
                "label-color": _ctx.matchProperties.matchingBadge.textColor,
                "label-size": 14,
                style: _normalizeStyle(_ctx.matchingBadgeStyles),
                uppercase: false,
                weight: 600
              }, null, 8, ["background-color", "border-color", "icon", "label", "label-color", "style"])
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createElementVNode("div", {
      style: _normalizeStyle(_ctx.tileStyles),
      class: "capital-tile__wrapper",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.detailsModalVisible = true))
    }, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", null, [
          _createElementVNode("h2", {
            title: _ctx.capitalInfo.title,
            class: "capital-tile__title"
          }, _toDisplayString(_ctx.capitalInfo.title), 9, _hoisted_4),
          _createElementVNode("p", {
            title: _ctx.capitalInfo.subtitle,
            class: "capital-tile__subtitle"
          }, _toDisplayString(_ctx.capitalInfo.subtitle), 9, _hoisted_5)
        ]),
        _createElementVNode("div", _hoisted_6, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.badges, (badge, index) => {
            return (_openBlock(), _createElementBlock("div", {
              key: index,
              class: "capital-tile__badges-item"
            }, [
              _createVNode(_component_PxIcon, {
                name: badge.icon,
                size: 16
              }, null, 8, ["name"]),
              _createElementVNode("p", {
                title: badge.description
              }, _toDisplayString(badge.description), 9, _hoisted_7)
            ]))
          }), 128))
        ])
      ]),
      (_ctx.capitalInfo.company_stages)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            style: _normalizeStyle(_ctx.footerStyles),
            class: "capital-tile__footer"
          }, [
            _createElementVNode("p", _hoisted_8, _toDisplayString(_ctx.$t("capitalExplorer.tiles.companyStage")), 1),
            _createElementVNode("div", _hoisted_9, [
              _createVNode(_component_MatchingCardTagList, {
                items: _ctx.capitalInfo.company_stages,
                "max-items-to-display": 3,
                "should-sort-array": false,
                title: "capitalExplorer.tiles.companyStage"
              }, null, 8, ["items"])
            ])
          ], 4))
        : _createCommentVNode("", true)
    ], 4),
    (_ctx.detailsModalVisible)
      ? (_openBlock(), _createBlock(_component_DetailsModal, {
          key: 0,
          visibility: _ctx.detailsModalVisible,
          "onUpdate:visibility": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.detailsModalVisible) = $event)),
          "capital-info": _ctx.capitalInfo,
          "match-details": _ctx.matchDetails,
          "match-properties": _ctx.matchProperties,
          "show-matching-badge": _ctx.showMatchingBadge
        }, null, 8, ["visibility", "capital-info", "match-details", "match-properties", "show-matching-badge"]))
      : _createCommentVNode("", true)
  ]))
}