<template>
  <section class="px-panel-section">
    <div class="px-panel-section__header">
      <div class="px-panel-section__col">
        <slot name="header">
          <PxIcon v-if="icon" :name="icon" :size="iconSize" />
          <h2 class="px-panel-section__title">
            {{ title }}
          </h2>
        </slot>
      </div>
      <div class="px-panel-section__col">
        <slot name="cta" />
      </div>
    </div>
    <div class="px-panel-section__body">
      <slot />
    </div>
  </section>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "PxPanelSection",

  props: {
    /**
     * Section title.
     */
    title: {
      type: String,
      required: true,
    },

    /**
     * Allows to use an icon on the title.
     *
     * The icon is aligned to the left followed by the title,
     * aligned in the same way.
     */
    icon: {
      type: String as () => string | null,
      default: null,
    },

    iconSize: {
      type: Number,
      default: 20,
    },
  },
});
</script>

<style lang="scss" scoped>
.px-panel-section__header {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  padding: 15px;

  background-image: linear-gradient(to bottom, white, white 50%, $alabaster);
  border-bottom: solid 1px $athens-gray;
}

.px-panel-section:not(:first-of-type) .px-panel-section__header {
  border-top: solid 1px $athens-gray;
}

.px-panel-section__col {
  display: flex;
  flex-flow: row wrap;
  align-items: center;

  &:not(:first-child):last-child {
    margin-left: auto;
  }
}

.px-panel-section__title {
  @include grotesk(semiBold);

  margin: 0;
  font-size: to-rem(15px);
  line-height: 20px;
  color: $manatee;
  text-transform: uppercase;
}

.px-icon + .px-panel-section__title {
  margin-left: 10px;
}

.px-panel-section__body {
  padding: 20px;
  background-color: white;
}
</style>
