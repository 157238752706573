import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, resolveDirective as _resolveDirective, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-160b0c40"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "validating-panel__container" }
const _hoisted_2 = { class: "validating-panel__score-wrapper" }
const _hoisted_3 = {
  key: 0,
  class: "validating-panel__label"
}
const _hoisted_4 = { class: "validating-panel__title-wrapper" }
const _hoisted_5 = { class: "validating-panel__title" }
const _hoisted_6 = { class: "validating-panel__tag-wrapper" }
const _hoisted_7 = { class: "validating-panel__description" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PxIcon = _resolveComponent("PxIcon")!
  const _component_ElTag = _resolveComponent("ElTag")!
  const _directive_markdown = _resolveDirective("markdown")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_PxIcon, {
        name: `level-graph/${_ctx.currentLevel}`,
        size: _ctx.levelIconSize,
        class: "validating-panel__level"
      }, null, 8, ["name", "size"])
    ]),
    (_ctx.showLabel)
      ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.$t("program.submission.selfAssessment")), 1))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.title), 1),
      _createElementVNode("span", _hoisted_6, [
        _createVNode(_component_ElTag, {
          effect: "plain",
          size: "default",
          "data-tag-type": "grey"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t("common.levelTag", { level: _ctx.currentLevel })), 1)
          ]),
          _: 1
        })
      ])
    ]),
    _withDirectives(_createElementVNode("div", _hoisted_7, null, 512), [
      [_directive_markdown, _ctx.description]
    ])
  ]))
}