import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-be9e7028"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "supporter-offerings" }
const _hoisted_2 = { class: "supporter-offerings__headline" }
const _hoisted_3 = {
  key: 0,
  class: "supporter-offerings__types"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ElTag = _resolveComponent("ElTag")!
  const _component_ElCollapseItem = _resolveComponent("ElCollapseItem")!
  const _component_ElCollapse = _resolveComponent("ElCollapse")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ElCollapse, {
      modelValue: _ctx.activeDrawer,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.activeDrawer) = $event)),
      accordion: ""
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.offerings, (offering, index) => {
          return (_openBlock(), _createBlock(_component_ElCollapseItem, {
            key: index,
            disabled: !offering.description,
            name: index
          }, {
            title: _withCtx(() => [
              _createElementVNode("div", _hoisted_2, [
                _createElementVNode("h3", null, _toDisplayString(offering.category.name), 1),
                (offering.types && offering.types.length)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(offering.types, (item) => {
                        return (_openBlock(), _createBlock(_component_ElTag, {
                          key: item.id,
                          effect: "plain"
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(item.name), 1)
                          ]),
                          _: 2
                        }, 1024))
                      }), 128))
                    ]))
                  : _createCommentVNode("", true)
              ])
            ]),
            default: _withCtx(() => [
              _createElementVNode("p", null, _toDisplayString(offering.description), 1)
            ]),
            _: 2
          }, 1032, ["disabled", "name"]))
        }), 128))
      ]),
      _: 1
    }, 8, ["modelValue"])
  ]))
}