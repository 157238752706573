import { ComponentPublicInstance } from "vue";
import debounce from "lodash/debounce";
import get from "lodash/get";
import has from "lodash/has";

/**
 * Generate a required validator.
 *
 * @param context Vue instance
 * @param fieldName Field name, translatable string.
 */
export const generateRequiredValidator = (
  context: ComponentPublicInstance,
  fieldName: string,
): any => {
  return {
    validator: debounce((rule: any, value: string, callback: any) => {
      return !!value ? callback() : callback(new Error());
    }, 300),
    trigger: "change",
    message: context.$t("common.errors.required", {
      fieldName: context.$t(fieldName),
    }),
  };
};

/**
 * Generate a required validator for lists.
 *
 * @param context Vue instance
 * @param fieldName Field name, translatable string.
 * @param options Field validation options (e.g. index)
 */
export const generateRequiredListsValidator = (
  context: ComponentPublicInstance,
  fieldName: string,
  options: { index: number } = {
    index: 0,
  },
): any => {
  return {
    validator: debounce((rule: any, value: string, callback: any) => {
      return !!value.length && !!value[options.index]
        ? callback()
        : callback(new Error());
    }, 300),
    trigger: "change",
    message: context.$t("common.errors.required", {
      fieldName: context.$t(fieldName),
    }),
  };
};

/**
 * Generate a required and valid email validator.
 * @param context Vue instance
 * @param fieldName Field name, translatable stirng.
 * @param required When present the field also includes the required error.
 */
export const generateEmailValidator = (
  context: ComponentPublicInstance,
  fieldName: string,
  required = false,
) => {
  return [
    {
      ...generateRequiredValidator(context, fieldName),
      required,
    },
    {
      validator: debounce((rule: any, value: string, callback: any) => {
        const matchResult =
          !/^(?:[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?\.)+[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[A-Za-z0-9-]*[A-Za-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)])$/.test(
            value,
          )
            ? new Error()
            : undefined;
        callback(matchResult);
      }, 300),
      trigger: "change",
      message: context.$t("common.errors.email"),
    },
  ];
};

export const generateRequiredSetValidator = (
  context: ComponentPublicInstance,
  fieldName: string,
) => {
  return {
    validator: debounce((rule: any, value: string, callback: any) => {
      return !!value && value.length > 0 ? callback() : callback(new Error());
    }, 300),
    trigger: "change",
    message: context.$t("common.errors.requiredSet", {
      fieldName: context.$t(fieldName),
    }),
  };
};

/**
 * Generate a required and valid password validator.
 * @param context Vue instance
 * @param fieldName Field name, translatable string.
 * @param options Field validation options (e.g. min, max)
 * @param required When present the field also includes the required error.
 */
export const generatePasswordValidator = (
  context: ComponentPublicInstance,
  fieldName: string,
  options: { minimum: number; enforceCharRequirements?: boolean } = {
    minimum: 0,
    enforceCharRequirements: false,
  },
  required = true,
) => {
  return [
    {
      ...generateRequiredValidator(context, fieldName),
      required,
    },
    {
      validator: debounce((rule: any, value: string, callback: any) => {
        // Regex for passwords which must contain at least 1 uppercase letter, 1 number, and 1 special character
        const passwordRequirementsRegex =
          /(?=^.{8,}$)(?=.*\d)(?=.*[~`!@#$%^&*()_\-+={}[\]|\\/:;"'<,>.?]+)(?![.\n])(?=.*[A-Z]).*$/g;

        if (value.length < options.minimum) {
          return callback(
            new Error(
              context.$t("common.errors.passwordSizeInvalid", {
                requiredMinimum: options.minimum,
              }) as string,
            ),
          );
        }

        if (!options.enforceCharRequirements) {
          return callback();
        }

        return passwordRequirementsRegex.test(value)
          ? callback()
          : callback(
              new Error(
                context.$t("common.errors.passwordCharsInvalid") as string,
              ),
            );
      }, 200),
      trigger: "change",
    },
  ];
};

/**
 * Generate a password confirmation checker.
 * @param context Vue instance
 * @param fieldToMatch Nested property name string using dot notation for password matching.
 * @param options
 */
export const generatePasswordCheck = (
  context: ComponentPublicInstance,
  fieldToMatch: string,
  options: { minimum: number },
) => {
  const min = options && options.minimum ? options.minimum : 0;

  return [
    {
      validator: debounce((rule: any, value: string, callback: any) => {
        const comparisonField = get(context, fieldToMatch);
        // Only check password confirmation when comparison field has proper value
        if (comparisonField.length < min) {
          return;
        }
        // Match result needs to be set to undefined in case of success
        // to execute the callback without arguments
        const noValue = !value.length;
        const noMatch = value !== comparisonField;
        const matchResult = noValue || noMatch ? new Error() : undefined;

        return callback(matchResult);
      }, 300),
      trigger: "change",
      message: context.$t("common.errors.passwordConfirmation"),
    },
  ];
};

/**
 * Generate a URL validator.
 * -> Scheme is optional
 * -> Supports query strings
 *
 * @param context Vue instance
 * @param fieldName Field name, translatable string.
 * @param required When present the field also includes the required error.
 */
export const generateURLValidator = (
  context: ComponentPublicInstance,
  fieldName: string,
  required = false,
) => {
  return [
    {
      ...generateRequiredValidator(context, fieldName),
      required,
    },
    {
      validator: debounce((rule: any, value: string, callback: any) => {
        const urlPattern =
          /^(?:(ftp|http|https):\/\/)?(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[A-Za-z0-9\u00a1-\uffff][A-Za-z0-9\u00a1-\uffff_-]{0,62})?[A-Za-z0-9\u00a1-\uffff]\.)+(?:[A-Za-z\u00a1-\uffff]{2,}\.?))(?::\d{2,5})?(?:[/?#]\S*)?$/;

        const hasNoValue = !required && (!value || !value.length);
        const hasValueAndValid = !!value && urlPattern.test(value);

        const matchResult =
          hasNoValue || hasValueAndValid ? undefined : new Error();

        callback(matchResult);
      }, 300),
      trigger: "change",
      message: context.$t("common.errors.invalidField", {
        fieldName: context.$t(fieldName),
      }),
    },
  ];
};

/**
 * Generate a Supporter type validator, for multiple and other type values.
 * @param context Vue instance
 * @param otherTypeField Other type field
 */
export const generateSupporterTypesValidator = (
  context: ComponentPublicInstance,
  otherTypeField: string,
) => {
  return [
    {
      validator: debounce((rule: any, value: Array<number>, callback: any) => {
        const otherType = get(context, otherTypeField);

        if ((!value || value.length === 0) && !otherType) {
          callback(new Error());
        }

        callback();
      }, 300),
      trigger: "change",
      message: context.$t("common.errors.requiredSet", {
        fieldName: context.$t("supporters.signup.form.fields.supporterType"),
      }),
    },
    {
      validator: debounce((rule: any, value: Array<number>, callback: any) => {
        const otherType = get(context, otherTypeField);

        if (value.length === 0 && !otherType) {
          callback(new Error());
        }

        callback();
      }, 300),
      trigger: "blur",
      message: context.$t("common.errors.requiredSet", {
        fieldName: context.$t("supporters.signup.form.fields.supporterType"),
      }),
    },
  ];
};

/**
 * Generate a Supporter locations of interest validator, for multiple and other type values.
 * @param context Vue instance
 * @param hasSpecificLocationsField Other type field
 */
export const generateSupporterLocationsValidator = (
  context: ComponentPublicInstance,
  hasSpecificLocationsField: string,
) => {
  return [
    {
      validator: debounce((rule: any, value: Array<number>, callback: any) => {
        const hasSpecificLocations = get(context, hasSpecificLocationsField);

        const selectedAnywhereLocations = !hasSpecificLocations;
        const selectedSpecificLocations =
          !!value.length && hasSpecificLocations;
        const emptySpecificLocations = !value.length && hasSpecificLocations;

        if (selectedAnywhereLocations || selectedSpecificLocations) {
          callback();
        } else if (emptySpecificLocations) {
          callback(new Error());
        }
      }, 300),
      trigger: "change",
      message: context.$t("common.errors.requiredSet", {
        fieldName: context.$t("supporters.signup.form.fields.locations"),
      }),
    },
    {
      validator: debounce((rule: any, value: Array<number>, callback: any) => {
        const hasSpecificLocations = get(context, hasSpecificLocationsField);

        !value.length && hasSpecificLocations
          ? callback(new Error())
          : callback();
      }, 300),
      trigger: "blur",
      message: context.$t("common.errors.requiredSet", {
        fieldName: context.$t("supporters.signup.form.fields.locations"),
      }),
    },
  ];
};

/**
 * Generate a max size validator.
 *
 * @param max
 */
export const generateMaxSizeValidator = (max: number): any => {
  return {
    max,
  };
};

export const generateCheckboxValidator = () => {
  return [
    {
      validator: (rule: any, value: boolean, callback: any) => {
        return !!value ? callback() : callback(new Error());
      },
    },
  ];
};

/**
 * Check form submit state, according to validation state.
 * @param formElement HTML element
 * @param rules form fields' rules
 */
export const allFormFieldsValid = (formElement: any, rules: any): boolean => {
  const formFields = formElement.fields as any;

  // Check if field contains rule and if doesn't have a success result from it
  const fieldsAreInvalid = (field: {
    validateState: string;
    prop: string;
    fieldValue: any;
    required: boolean;
  }) => {
    let fieldValueHasOnlySpaces = false;

    if (
      !!field.fieldValue &&
      field.fieldValue.length &&
      typeof field.fieldValue === "string"
    ) {
      fieldValueHasOnlySpaces = !field.fieldValue.trim();
    }

    return (
      (field.required === false && field.validateState === "error") ||
      (field.required !== false &&
        has(rules, field.prop) &&
        field.validateState !== "success") ||
      fieldValueHasOnlySpaces
    );
  };

  return !formFields.some(fieldsAreInvalid);
};
