import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-ee2a49ae"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "owned-affiliate-submissions-view page-body" }
const _hoisted_2 = { class: "owned-affiliate-submissions-view__header" }
const _hoisted_3 = { class: "owned-affiliate-submissions-view__filter" }
const _hoisted_4 = { class: "page-container owned-affiliate-submissions-view__panels" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PxIcon = _resolveComponent("PxIcon")!
  const _component_ElInput = _resolveComponent("ElInput")!
  const _component_PxPageHeader = _resolveComponent("PxPageHeader")!
  const _component_SubmissionsPanel = _resolveComponent("SubmissionsPanel")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_PxPageHeader, {
        subtitle: _ctx.headerCopy.subtitle,
        title: _ctx.headerCopy.title,
        onBackButtonClicked: _ctx.goToPreviousPage
      }, {
        action: _withCtx(() => [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_ElInput, {
              modelValue: _ctx.submissionFilter,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.submissionFilter) = $event)),
              disabled: _ctx.noSubmissions,
              placeholder: _ctx.$t('ownedAffiliatesSubmissions.search'),
              clearable: "",
              onInput: _ctx.filterSubmissions
            }, {
              prefix: _withCtx(() => [
                _createVNode(_component_PxIcon, {
                  size: 24,
                  name: "magnifier"
                })
              ]),
              _: 1
            }, 8, ["modelValue", "disabled", "placeholder", "onInput"])
          ])
        ]),
        _: 1
      }, 8, ["subtitle", "title", "onBackButtonClicked"])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_SubmissionsPanel, {
        "is-loading": _ctx.dataLoading,
        "is-user-submissions-view": false,
        "show-empty-results-copy": !!_ctx.draftSubmissions.length,
        "submissions-data": _ctx.filteredDraftSubmissions,
        type: "draft-submissions"
      }, null, 8, ["is-loading", "show-empty-results-copy", "submissions-data"]),
      _createVNode(_component_SubmissionsPanel, {
        "is-loading": _ctx.dataLoading,
        "is-user-submissions-view": false,
        "show-empty-results-copy": !!_ctx.submittedSubmissions.length,
        "submissions-data": _ctx.filteredSubmittedSubmissions,
        type: "submitted-submissions"
      }, null, 8, ["is-loading", "show-empty-results-copy", "submissions-data"])
    ])
  ]))
}