import { MutationTree } from "vuex";
import {
  IUserMetadataState,
  EUserMetadataMutations,
} from "./user-metadata.types";
import { getBaseName } from "@/services/store/utils/get-base-name";
import { IUserMetadata } from "@/modules/authentication/services/data/user-metadata/user-metadata.types";

export const mutations: MutationTree<IUserMetadataState> = {
  /**
   * Append a new entry to the module.
   *
   * @param state Module state
   * @param payload New metadata entry to add to the module.
   */
  [getBaseName(EUserMetadataMutations.APPEND)](state, payload: IUserMetadata) {
    state.data = [...state.data, payload];
  },
};
