import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-7f83470b"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "company-lists" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CompanyListsItemPlaceholder = _resolveComponent("CompanyListsItemPlaceholder")!
  const _component_CompanyListsItem = _resolveComponent("CompanyListsItem")!
  const _component_CompanyListsEmpty = _resolveComponent("CompanyListsEmpty")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.isLoading)
      ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.defaultLoadingPlaceholders, (placeholder) => {
          return (_openBlock(), _createBlock(_component_CompanyListsItemPlaceholder, {
            key: `virtual-list-placeholder-${placeholder}`
          }))
        }), 128))
      : _createCommentVNode("", true),
    (_ctx.hasListItems && !_ctx.isLoading)
      ? (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.listsOrdered, (list) => {
          return (_openBlock(), _createBlock(_component_CompanyListsItem, {
            key: `list-item--${list.uid}`,
            "show-pin-to-top": _ctx.isOwnedList,
            value: list,
            onPinnedItem: _ctx.pinnedListHandler
          }, null, 8, ["show-pin-to-top", "value", "onPinnedItem"]))
        }), 128))
      : _createCommentVNode("", true),
    (_ctx.showEmptyState)
      ? (_openBlock(), _createBlock(_component_CompanyListsEmpty, {
          key: 2,
          "can-create-lists": _ctx.isOwnedList && !_ctx.emptyListsTitle,
          icon: _ctx.emptyListsIcon,
          subtitle: _ctx.emptyListsSubtitle,
          title: _ctx.emptyListsTitle
        }, null, 8, ["can-create-lists", "icon", "subtitle", "title"]))
      : _createCommentVNode("", true)
  ]))
}