import { i18n } from "@/services/i18n/i18n";

import {
  ROUTE_PROFILE,
  ROUTE_TEAM_MANAGEMENT,
  ROUTE_UPDATE_RANGE_LEVEL,
} from "@/modules/profile/services/router/routes-names";
import { ROUTE_MATCHING_LIST } from "@/modules/matching/services/router/routes-names";
import {
  ROUTE_COMPANY_LISTS_DETAIL,
  ROUTE_COMPANY_LISTS_DIRECTORY,
} from "@/modules/company-lists/services/router/routes-names";
import { INavBarItem } from "@/modules/profile/meta/entrepreneurs";
import { ROUTE_GETTING_STARTED_DASHBOARD } from "@/modules/getting-started-dashboard/services/router/routes-names";
import { CHARTS_DASHBOARD } from "@/modules/charts-dashboard/services/router/routes-names";

export default {
  navBarItems: [
    {
      key: "home",
      label: i18n.global.t("common.navigation.navBarItems.home"),
      to: ROUTE_GETTING_STARTED_DASHBOARD,
      includedRoutes: [ROUTE_GETTING_STARTED_DASHBOARD, CHARTS_DASHBOARD],
      requiredModules: ["getting-started-dashboard", "charts-dashboard"],
      requiredFeatures: ["gettingStartedDashboard", "chartsDashboard"],
      subMenuItems: [
        {
          key: "getting_started",
          label: i18n.global.t("common.navigation.navBarItems.gettingStarted"),
          to: ROUTE_GETTING_STARTED_DASHBOARD,
          requiredModules: ["getting-started-dashboard"],
          requiredFeatures: ["gettingStartedDashboard"],
          includedRoutes: [ROUTE_GETTING_STARTED_DASHBOARD],
        },
        {
          key: "dashboard",
          label: i18n.global.t("common.navigation.navBarItems.dashboard"),
          to: CHARTS_DASHBOARD,
          requiredModules: ["charts-dashboard"],
          requiredFeatures: ["chartsDashboard"],
          newFeature: true,
        },
      ],
    },
    {
      key: "my_company",
      label: i18n.global.t("common.navigation.navBarItems.myCompany"),
      to: ROUTE_PROFILE,
      includedRoutes: [
        ROUTE_PROFILE,
        ROUTE_TEAM_MANAGEMENT,
        ROUTE_UPDATE_RANGE_LEVEL,
      ],
    },
    {
      key: "my_network",
      label: i18n.global.t("common.navigation.navBarItems.myNetwork"),
      to: ROUTE_MATCHING_LIST,
      requiredFeatures: ["match"],
      includedRoutes: [ROUTE_MATCHING_LIST],
    },
    {
      key: "lists",
      label: i18n.global.t("common.navigation.navBarItems.lists"),
      to: ROUTE_COMPANY_LISTS_DIRECTORY,
      requiredFeatures: ["companyLists"],
      includedRoutes: [
        ROUTE_COMPANY_LISTS_DIRECTORY,
        ROUTE_COMPANY_LISTS_DETAIL,
      ],
    },
  ] as Array<INavBarItem>,
  tabMenuItems: [
    {
      key: "about",
      label: i18n.global.t("common.navigation.tabBarItems.about"),
      to: ROUTE_PROFILE,
      hasVisitorIcon: false,
    },
    {
      key: "criteria",
      label: i18n.global.t("common.navigation.tabBarItems.criteria"),
      to: ROUTE_PROFILE,
      hasVisitorIcon: true,
    },
  ],
};
