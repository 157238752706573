<template>
  <div
    v-loading="isLoading"
    :class="{
      'is-loading': isLoading,
      'supporter-results--affiliate': !isDefaultAffiliate,
    }"
    class="supporter-results"
  >
    <template v-if="showSubmissionView">
      <Submission
        :show-notification="isLoggedSubmissionFlow"
        :submission="submission"
      />
    </template>
    <template v-else>
      <ResultsBoard />
      <FeaturesShowcase />
    </template>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import Submission from "@/modules/supporters/components/submission/submission.vue";
import ResultsBoard from "@/modules/supporters/components/results-board/results-board.vue";
import FeaturesShowcase from "@/modules/supporters/components/features-showcase/features-showcase.vue";

import { IAffiliate } from "@/services/data/affiliate/affiliate.interface";
import { SupporterFlowState } from "@/modules/supporters/services/store/supporter-flow/supporter-flow.module";
import { affiliateProgramProgramSubmissionProvider } from "@/modules/supporters/services/data/affiliate-program-supporter-submission/affiliate-program-supporter-submission.provider";
import { IAffiliateProgramSupporterSubmission } from "@/modules/supporters/services/data/affiliate-program-supporter-submission/affiliate-program-supporter-submission.interface";

export default defineComponent({
  name: "SupporterResults",
  components: {
    Submission,
    ResultsBoard,
    FeaturesShowcase,
  },

  data() {
    return {
      submission: null as null | IAffiliateProgramSupporterSubmission,
      resultsVariation: null,
      modalVisibility: false,
    };
  },

  computed: {
    uidFromRoute(): string | null {
      return this.$route.params?.uid as string;
    },

    uidFromSubmission(): string | null {
      return this.$store.getters[SupporterFlowState.Getter.VALUE].options
        .submissionUid;
    },

    affiliate(): IAffiliate | null {
      return this.$store.state.affiliate.value;
    },

    isLoading(): boolean {
      return (
        this.$store.getters[SupporterFlowState.Getter.IS_LOADING] ||
        (this.showSubmissionView && !this.submission)
      );
    },

    hasError(): boolean {
      return this.$store.getters[SupporterFlowState.Getter.HAS_ERROR];
    },

    /**
     * Check if the user is assessing by an Affiliate.
     */
    isDefaultAffiliate(): boolean {
      return !this.affiliate || this.affiliate.default_flow;
    },

    /**
     * Define a theme variation for this view.
     */
    resultsVariationTheme(): { [key: string]: boolean } {
      return {
        "supporter-results--affiliate": !this.isDefaultAffiliate,
      };
    },

    isUserLogged(): boolean {
      return this.$user.isLogged();
    },

    isLoggedSubmissionFlow(): boolean {
      return this.isUserLogged && !!this.uidFromSubmission;
    },

    showSubmissionView(): boolean {
      return this.isLoggedSubmissionFlow || !!this.uidFromRoute;
    },
  },

  async created() {
    // Basic results without submission page, carry on
    if (!this.showSubmissionView) {
      return;
    }

    // For Supporters Affiliate flow, where user is logged
    if (this.isLoggedSubmissionFlow) {
      // Applied "" for linting reasons
      await this.fetchSubmission(this.uidFromSubmission || "");
      return;
    }

    // For outside flow, both on logged or public view
    // Applied "" for linting reasons
    await this.fetchSubmission(this.uidFromRoute || "");
  },

  methods: {
    async fetchSubmission(submissionUid: string) {
      try {
        this.submission =
          await affiliateProgramProgramSubmissionProvider.get(submissionUid);
      } catch {
        this.notifyErrorWhileFetchingSubmission();
      }
    },

    notifyErrorWhileFetchingSubmission() {
      this.$message({
        message: this.$t("program.submission.errors.loading"),
        type: "error",
        duration: 10000,
        customClass: "is-full",
      });
    },
  },
});
</script>

<style lang="scss" scoped>
.supporter-results {
  max-width: 940px;
  min-height: 70vh;
  margin: 0 auto;

  @include breakpoint-up(md) {
    padding-top: 65px;
  }

  &__confirmation-panel {
    display: flex;
    min-height: 92px;

    @include breakpoint-down(sm) {
      flex-direction: column;
      padding: 30px 15px 22px;
    }

    @include breakpoint-up(md) {
      padding: 8px;
      margin-bottom: 30px;
      background-color: $athens-gray;
      border-radius: 4px;
      box-shadow: inset 0 0 3px 0 rgba(139, 143, 161, 0.16);
    }

    & > div {
      display: flex;
      flex: 1 0 100%;
      flex-direction: column;
      justify-content: center;
      font-size: 16px;

      @include breakpoint-up(md) {
        flex: 1 0 50%;
        padding: 0 22px;
      }
    }
  }

  &__validating-panel {
    margin-bottom: 24px;

    @include breakpoint-up(md) {
      margin-bottom: 52px;
    }
  }

  &__details-panel {
    margin-bottom: 29px;

    @include breakpoint-up(md) {
      margin-bottom: 98px;
    }
  }

  & :deep() .features-showcase {
    margin: 88px auto 50px;

    @include breakpoint-up(md) {
      margin: 80px auto 15px;
    }
  }
}

.supporter-results :deep() .px-loading-mask {
  background-color: transparent;
}

.supporter-results--affiliate {
  @include breakpoint-up(md) {
    padding-top: 40px;
  }
}
</style>
