import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-58579d68"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "form-grid form-grid--centered" }
const _hoisted_2 = { class: "offerings-form__header" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ElOption = _resolveComponent("ElOption")!
  const _component_ElSelect = _resolveComponent("ElSelect")!
  const _component_OfferingsFormItem = _resolveComponent("OfferingsFormItem")!
  const _component_ElForm = _resolveComponent("ElForm")!

  return (_openBlock(), _createBlock(_component_ElForm, {
    ref: "form",
    model: _ctx.form,
    class: "offerings-form"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("h2", null, _toDisplayString(_ctx.$t("profile.supporter.offerings.modal.headline")), 1),
          _createVNode(_component_ElSelect, {
            modelValue: _ctx.selectedOfferingCategories,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedOfferingCategories) = $event)),
            disabled: !_ctx.availableOfferingCategories.length,
            placeholder: 
            _ctx.$t('profile.supporter.offerings.form.select.categories')
          ,
            class: "offerings-form__select",
            onChange: _ctx.onOfferingCategorySelect
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.availableOfferingCategories, (category, key) => {
                return (_openBlock(), _createBlock(_component_ElOption, {
                  key: key,
                  label: category.name,
                  value: category,
                  "value-key": category.id
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("span", null, _toDisplayString(category.name), 1)
                  ]),
                  _: 2
                }, 1032, ["label", "value", "value-key"]))
              }), 128))
            ]),
            _: 1
          }, 8, ["modelValue", "disabled", "placeholder", "onChange"])
        ]),
        (_ctx.currentOfferingCategories)
          ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.currentOfferingCategories, (offeringCategory, index) => {
              return (_openBlock(), _createBlock(_component_OfferingsFormItem, {
                key: index,
                ref_for: true,
                ref: "offeringItem",
                offering: _ctx.form.offerings[index],
                "onUpdate:offering": ($event: any) => ((_ctx.form.offerings[index]) = $event),
                category: offeringCategory,
                onOfferChanged: _ctx.offerChangedHandler,
                onRemoveOffer: ($event: any) => (_ctx.onRemoveOfferHandler(offeringCategory.id)),
                onSubmissionErrors: _ctx.offerSubmissionHandler
              }, null, 8, ["offering", "onUpdate:offering", "category", "onOfferChanged", "onRemoveOffer", "onSubmissionErrors"]))
            }), 128))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }, 8, ["model"]))
}