import { IGenericState } from "@/services/store/generic/generic-state.interface";
import { GetterTree } from "vuex";
import { RootState } from "@/services/store/root-state";
import { GenericState } from "@/services/store/generic/generic-state.module";

/**
 * Returns default getters for generic state.
 */
export const getGenericStateGetters = <T>(): GetterTree<
  IGenericState<T>,
  RootState
> => ({
  /**
   * Generic action to get value of store.
   * @param state
   */
  [GenericState.Getter.VALUE](state: IGenericState<T>): T | null {
    return state.value;
  },

  /**
   * Generic action to get values of store.
   * @param state
   */
  [GenericState.Getter.VALUES](state: IGenericState<T>): Array<T> {
    return state.values;
  },

  /**
   * Generic getter to check if model is loading.
   * @param state
   */
  [GenericState.Getter.IS_LOADING](state: IGenericState<T>): boolean {
    return state.loading;
  },

  /**
   * Generic getter to fetch error value.
   * @param state
   */
  [GenericState.Getter.ERROR](state: IGenericState<T>): Error | null {
    return state.error;
  },

  /**
   * Generic action to fetch if has error.
   * @param state
   */
  [GenericState.Getter.HAS_ERROR](state: IGenericState<T>): boolean {
    return !!state.error;
  },
});
