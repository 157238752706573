import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-e576f656"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "px-questionary-select" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ElCheckbox = _resolveComponent("ElCheckbox")!
  const _component_ElCheckboxGroup = _resolveComponent("ElCheckboxGroup")!
  const _component_ElRadio = _resolveComponent("ElRadio")!
  const _component_PxRadioGroup = _resolveComponent("PxRadioGroup")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.multiple)
      ? (_openBlock(), _createBlock(_component_ElCheckboxGroup, {
          key: 0,
          modelValue: _ctx.checkBoxOptionsSelected,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.checkBoxOptionsSelected) = $event)),
          onChange: _ctx.submitAnswer
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option, index) => {
              return (_openBlock(), _createBlock(_component_ElCheckbox, {
                key: index,
                value: option.id
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(option.value), 1)
                ]),
                _: 2
              }, 1032, ["value"]))
            }), 128))
          ]),
          _: 1
        }, 8, ["modelValue", "onChange"]))
      : (_openBlock(), _createBlock(_component_PxRadioGroup, {
          key: 1,
          modelValue: _ctx.radioOptionSelected,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.radioOptionSelected) = $event)),
          onChange: _ctx.submitAnswer
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option, index) => {
              return (_openBlock(), _createBlock(_component_ElRadio, {
                key: index,
                value: option.id,
                border: ""
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(option.value), 1)
                ]),
                _: 2
              }, 1032, ["value"]))
            }), 128))
          ]),
          _: 1
        }, 8, ["modelValue", "onChange"]))
  ]))
}