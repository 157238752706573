<template>
  <div class="list-share-settings-passcode">
    <ElForm ref="form" class="list-share-settings-passcode__form">
      <PxSwitch
        v-model:value="isRestrictedByPasscode"
        :label="shareModalPasscodeCopy.label"
        @change="changeRestrictionHandler"
      >
        <template #label>
          <ElTooltip
            :enterable="false"
            placement="top"
            popper-class="list-share-settings-passcode__popper el-abaca-tooltip"
          >
            <template #content>
              <div v-text="shareModalPasscodeCopy.tooltip" />
            </template>
            <PxIcon
              :size="17"
              class="list-share-settings-passcode__tooltip"
              name="question"
            />
          </ElTooltip>
        </template>
      </PxSwitch>
      <div
        v-show="isRestrictedByPasscode"
        class="list-share-settings-passcode__toggle-wrapper"
      >
        <ElFormItem
          class="list-share-settings-passcode__input-wrapper"
          prop="email"
        >
          <PxInputPassword
            v-model="currentPasscode"
            class="list-share-settings-passcode__input"
            disabled
          />
          <ClipboardButton v-bind="clipboardButton" />
        </ElFormItem>
        <div class="list-share-settings-passcode__footer">
          <span
            class="el-form-item__bottom-text el-form-item__tip"
            v-html="shareModalPasscodeCopy.tip"
          />
          <PxButton
            :icon="$options.static.resetButton.icon"
            :label="shareModalPasscodeCopy.resetButton"
            :size="$options.static.resetButton.size"
            :type="$options.static.resetButton.type"
            class="list-share-settings-passcode__cta"
            @click="clickResetPasscodeHandler"
          />
        </div>
      </div>
    </ElForm>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { generateRandomString } from "@/utils/generate-random-string";

import ClipboardButton from "@/components/clipboard-button/clipboard-button.vue";
import {
  EPxButtonSize,
  EPxButtonType,
} from "@/components/px-button/px-button.types";

import { LIST_SHARE_EVENTS } from "@/modules/company-lists/components/list-share/list-share-modal.vue";

export default defineComponent({
  name: "ListShareSettingsPasscode",

  components: {
    ClipboardButton,
  },

  props: {
    value: {
      type: String,
      required: true,
    },

    needsReset: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      currentPasscode: "",
      isRestrictedByPasscode: false,
    };
  },

  static: {
    resetButton: {
      icon: "password-lock--red",
      size: EPxButtonSize.SMALL,
      type: EPxButtonType.GHOST_RED,
    },
  },

  computed: {
    shareModalPasscodeCopy() {
      return this.$tm("companyLists.list.shareModal.settings.passcode") as {
        label: string;
        tip: string;
        resetButton: string;
        tooltip: string;
      };
    },

    hasCurrentlyPasscode(): boolean {
      return !!this.value.length;
    },

    clipboardButton() {
      return {
        clipboard: this.value,
        buttonLabel: this.$t("common.copyPasscode"),
        tooltipCopy: this.$t("common.copiedToClipboard"),
        buttonIcon: "password-lock",
      };
    },
  },

  watch: {
    value(newValue: string) {
      if (this.currentPasscode !== newValue) {
        this.currentPasscode = newValue;
      }
    },
    currentPasscode(newPasscode: string) {
      if (this.value !== newPasscode) {
        this.$emit("update:value", newPasscode);
      }
    },
    needsReset(newValue: boolean) {
      if (newValue) {
        this.isRestrictedByPasscode = this.hasCurrentlyPasscode;
        this.$emit("update:needsReset", !newValue);
      }
    },
  },

  created() {
    this.isRestrictedByPasscode = this.hasCurrentlyPasscode;

    if (this.hasCurrentlyPasscode) {
      this.currentPasscode = this.value;
    }
  },

  methods: {
    changeRestrictionHandler(isRestricted: boolean) {
      // If non-existing passcode, generate a new one:
      if (isRestricted && !this.hasCurrentlyPasscode && !this.currentPasscode) {
        this.currentPasscode = generateRandomString();
      }

      this.$emit("change", isRestricted, this.currentPasscode);
    },

    clickResetPasscodeHandler() {
      this.$emitter.emit("list-share-modal-confirmation", {
        event: LIST_SHARE_EVENTS.RESET_PASSWORD,
      });
    },
  },
});
</script>

<style lang="scss" scoped>
.list-share-settings-passcode {
  padding: 16px 15px 17px;
  border: solid 1px $athens-gray;
  border-radius: 4px;

  &__toggle-wrapper {
    margin-top: 29px;

    &[style="display:none"] {
      margin-top: 0;
    }
  }

  &__input-wrapper {
    position: relative;

    :deep(.el-form-item__label) {
      padding-top: 0;
    }

    :deep(.el-input.is-disabled .el-input__inner) {
      // Add safe space for CTA overlapping input:
      padding-right: 130px;
      background-color: $alabaster;
      border: solid 1px $mischka;
      box-shadow: inset 0 0 5px 0 rgba(#000, 0.03);
      opacity: 1;
    }
  }

  &__input.el-input--password :deep(.el-input__suffix) {
    right: 160px;
    left: auto;
  }

  :deep(.clipboard-button) {
    position: absolute;
    right: 4px;
    bottom: 4px;
  }

  &__footer {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    margin-top: 16px;

    .el-form-item__tip {
      flex: 1 1 50%;
      max-width: 320px;
      padding-top: 0;
    }
  }

  &__tooltip {
    margin-left: 5px;
    vertical-align: -4px;
    transition: $--filter-transition-base;

    &:hover {
      filter: brightness(0.5) hue-rotate(380deg);
    }
  }
}
</style>

<style lang="scss">
.list-share-settings-passcode__popper {
  max-width: 199px;
  padding: 10px 15px 10px;
  margin-top: 0;
  text-transform: none;
  letter-spacing: -0.2px;
}
</style>
