import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-7e432784"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "profile-panel__row assessment-history" }
const _hoisted_2 = { class: "profile-headline" }
const _hoisted_3 = { class: "profile-headline__title" }
const _hoisted_4 = { class: "assessment-timeline" }
const _hoisted_5 = {
  key: 0,
  class: "assessment-timeline__image-placeholder",
  src: "/img/placeholders/timeline.svg"
}
const _hoisted_6 = {
  key: 1,
  class: "assessment-timeline__image-placeholder",
  src: "/img/placeholders/timeline-mobile.svg"
}
const _hoisted_7 = { class: "assessment-timeline__content-placeholder" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ContentPlaceholdersText = _resolveComponent("ContentPlaceholdersText")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h2", _hoisted_3, _toDisplayString(_ctx.$t("profile.assessment.historyPanel.title")), 1)
    ]),
    _createElementVNode("div", _hoisted_4, [
      (_ctx.$screen.lgUp)
        ? (_openBlock(), _createElementBlock("img", _hoisted_5))
        : (_openBlock(), _createElementBlock("img", _hoisted_6)),
      _createElementVNode("div", _hoisted_7, [
        _createVNode(_component_ContentPlaceholdersText, { lines: 3 }),
        _createVNode(_component_ContentPlaceholdersText, { lines: 2 }),
        _createVNode(_component_ContentPlaceholdersText, { lines: 2 }),
        _createVNode(_component_ContentPlaceholdersText, { lines: 2 })
      ])
    ])
  ]))
}