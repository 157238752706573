<template>
  <div
    :class="{
      'match-details-table-row__wrapper--no-instructions': !instructions,
    }"
    class="match-details-table-row__wrapper"
  >
    <div class="match-details-table-row">
      <!-- Questions -->
      <div
        class="match-details-table-row__item"
        data-testid="match-details-table-row-criteria"
      >
        {{ criteria }}
      </div>
      <!-- Auth user Answer -->
      <div class="match-details-table-row__item">
        <MatchingCardTagList
          v-if="showAuthAnswerTagList && Array.isArray(authUserAnswer)"
          :capitalize="false"
          :is-simplified-version="true"
          :items="authUserAnswer"
          data-testid="match-details-table-row-sector-tags"
        />
        <div v-else-if="!formattedAuthUserAnswer && listFormat">
          <p class="match-details-table-row__not-answered">
            {{ $t("common.noAnswer") }}
          </p>
        </div>
        <hr v-else-if="!formattedAuthUserAnswer" />
        <MatchingCardTagList
          v-else-if="listFormat && Array.isArray(authUserAnswer)"
          :capitalize="false"
          :items="authUserAnswer"
          :list-format="true"
          :max-items-to-display="3"
          :should-sort-array="false"
          :title="answerHeaderCopy"
        />
        <div v-else data-testid="match-details-table-row-auth-answer">
          {{ formattedAuthUserAnswer }}
        </div>
      </div>
      <!-- Profile user Answer -->
      <div class="match-details-table-row__item">
        <MatchingCardTagList
          v-if="showProfileAnswerTagList && Array.isArray(profileUserAnswer)"
          :capitalize="false"
          :is-simplified-version="true"
          :items="profileUserAnswer"
        />
        <hr v-else-if="!formattedProfileUserAnswer" />
        <MatchingCardTagList
          v-else-if="listFormat && Array.isArray(profileUserAnswer)"
          :capitalize="false"
          :items="profileUserAnswer"
          :list-format="true"
          :max-items-to-display="3"
          :should-sort-array="false"
          title="capitalExplorer.detailsModal.tableHeader[2].title"
        />
        <div v-else data-testid="match-details-table-row-profile-answer">
          {{ formattedProfileUserAnswer }}
        </div>
      </div>
      <!-- Match Icon -->
      <div class="match-details-table-row__item--center">
        <PxIcon
          ref="icon"
          :name="matchIcon"
          :size="24"
          data-testid="match-details-table-row-match-icon"
        />
      </div>
      <!-- Impact value -->
      <div v-if="impact !== null" class="match-details-table-row__item--center">
        <h3
          :class="{
            'match-details-table-row--grey': !hasMatch || impact <= 0,
          }"
          data-testid="match-details-table-row-impact"
        >
          {{ formattedImpactValue }}
        </h3>
      </div>
    </div>
    <ElCollapse v-if="showLearnMoreLink && instructions">
      <ElCollapseItem>
        <template #title>
          <div class="match-details-table-row__learn-more">
            <span>
              {{ $t("common.learnMore") }}
            </span>
          </div>
        </template>
        <p>
          {{ instructions }}
        </p>
      </ElCollapseItem>
    </ElCollapse>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import MatchingCardTagList from "@/components/matching-card/matching-card-tag-list.vue";

import { TranslateResult } from "vue-i18n";
import { EPxButtonType } from "@/components/px-button/px-button.types";
import { CAPITAL_EXPLORER_VISITORS_VIEW } from "@/modules/capital-explorer/services/router/routes-names";

export default defineComponent({
  name: "MatchDetailsTableRow",

  components: {
    MatchingCardTagList,
  },

  props: {
    criteria: {
      type: String,
      required: true,
    },

    authUserAnswer: {
      type: [
        String,
        Number,
        Array as () => Array<string>,
        Array as () => Array<number>,
        Object as () => object,
      ],
      default: "",
    },

    profileUserAnswer: {
      type: [
        String,
        Number,
        Array as () => Array<string>,
        Array as () => Array<number>,
        Object as () => object,
      ],
      default: "",
    },

    hasMatch: {
      type: Boolean,
      required: true,
    },

    impact: {
      type: Number,
      default: null,
    },

    iconMatch: {
      type: String,
      default: "match",
    },

    listFormat: {
      type: Boolean,
      default: false,
    },

    showLearnMoreLink: {
      type: Boolean,
      default: false,
    },

    instructions: {
      type: String,
      default: null,
    },
  },

  computed: {
    matchIcon(): string {
      return !this.formattedAuthUserAnswer || !this.formattedProfileUserAnswer
        ? "unanswered"
        : this.hasMatch
          ? this.iconMatch
          : "no-match";
    },

    isSectorCriteria(): boolean {
      return this.criteria == "Sector";
    },

    isLocationCriteria(): boolean {
      return this.criteria == "Location";
    },

    allLocationsOrSectorsText(): TranslateResult {
      return this.isLocationCriteria
        ? this.$t("profile.company.emptyState.locationsOfInterest[0]")
        : this.$t("profile.company.emptyState.sectorsOfInterest[0]");
    },

    formattedAuthUserAnswer(): string | number | object {
      return this.formatAnswers(this.authUserAnswer);
    },

    formattedProfileUserAnswer(): string | number | object {
      return this.formatAnswers(this.profileUserAnswer);
    },

    formattedImpactValue(): string {
      return `${Math.trunc(this.impact)}%`;
    },

    learnMoreButtonProps(): object {
      return {
        type: EPxButtonType.LINK,
        label: this.$t("common.learnMore"),
      };
    },

    answerHeaderCopy(): TranslateResult {
      const isVisitorsView =
        this.$route.name === CAPITAL_EXPLORER_VISITORS_VIEW;

      return isVisitorsView
        ? this.$t(`capitalExplorer.detailsModal.tableHeader[1].title[${1}]`)
        : this.$t(`capitalExplorer.detailsModal.tableHeader[1].title[${0}]`);
    },

    showAuthAnswerTagList(): boolean {
      return this.isSectorCriteria && !!this.authUserAnswer;
    },

    showProfileAnswerTagList(): boolean {
      return this.isSectorCriteria && !!this.profileUserAnswer;
    },
  },

  methods: {
    formatAnswers(answer: string | number | object) {
      // When there's no location or sectors answer it means that the user is searching for all sectors or all locations
      if ((this.isLocationCriteria || this.isSectorCriteria) && !answer) {
        return this.allLocationsOrSectorsText;
      }

      if (!answer) return "";

      // When the answer is an array we should join the text
      if (Array.isArray(answer)) return answer.join(" ");

      // When the answer is an object we should format the values
      if (typeof answer === "object") {
        return this.formatObjectValues(answer);
      }

      return answer;
    },

    formatObjectValues(answer: object) {
      let answerValues = answer;

      // This is used when we get one object with min and max key/values
      if (Object.keys(answerValues).length > 1) {
        // We should reverse the array to start in the min value
        const reverseValues = Object.values(answer).reverse();

        const formattedValues = reverseValues.map((value: number) =>
          parseInt(String(value)).toLocaleString(),
        );

        return formattedValues.join(" - ");
      }

      // When we only get one key/value pair
      answerValues = Object.values(answer)[0];

      return parseInt(String(answerValues)).toLocaleString();
    },
  },
});
</script>

<style lang="scss" scoped>
.match-details-table-row__wrapper {
  display: flex;
  flex-direction: column;
  padding: 16px 0 0 0;
  gap: 4px;

  &--no-instructions {
    padding-bottom: 16px;
  }

  :deep(.el-collapse-item__wrap),
  :deep(.el-collapse-item__content) {
    padding: 0;
  }

  :deep(.el-collapse-item__content p) {
    margin: 0;
    padding-right: 10px;

    letter-spacing: -0.33px;
    color: $ebony-clay;
    font-size: 15px;
    line-height: 1.47;
  }

  :deep(.el-collapse-item__wrap) {
    margin: 0 0 1px 0;
    background: $alabaster;
    border-top: 1px solid $athens-gray;
  }

  :deep(.el-collapse-item__content) {
    padding: 22px 0 23px 12px;
  }

  :deep(.el-collapse-item__header) {
    min-height: 0;
    padding: 0 0 13px 12px;
    background: none;

    &.is-active::before {
      transform: rotate(270deg);
    }

    &::before {
      top: 1px;
      left: 87px;
      bottom: 13px;
      width: 16px;
      height: 16px;
      margin: auto 0;
      background-color: transparent;
      background-image: url("#{$assetsPath}/img/icons/arrow--right-grey.svg");
      background-repeat: no-repeat;
      transform: rotate(90deg);
      filter: brightness(0.6);

      transition: $--all-transition;
    }

    &::after {
      display: none;
    }
  }
}

.match-details-table-row {
  display: grid;
  grid-template-columns: 201px 201px 201px 120px 120px;
  align-items: center;

  padding: 0;

  hr {
    margin: 0;
    height: 1px;
    width: 20px;
    background-color: $manatee;
    border: 0;
  }

  &__item,
  :deep(.matching-card-tag-list__container) {
    @include grotesk(medium);

    font-size: 14px;
    color: $manatee;
  }

  &__item {
    display: flex;
    align-items: center;
    padding-left: 12px;
    letter-spacing: 0.2px;
    word-break: break-word;
    align-self: flex-start;
  }

  &__item--center {
    display: flex;
    justify-content: center;
  }

  &__learn-more span {
    font-size: 14px;
    color: $manatee;
    text-decoration: underline;
    font-weight: 600;
  }

  h3.match-details-table-row--grey {
    color: $manatee;
  }

  h3 {
    @include grotesk(semiBold);

    margin: 0;
    font-size: 18px;
    color: $bluish;
  }

  &__no-access span {
    @include grotesk(semiBold);

    margin-left: 5px;
    font-size: 15px;
    color: $ebony-clay;
    text-decoration: underline;
    letter-spacing: -0.2px;
  }

  &__not-answered {
    position: relative;

    padding: 0 10px 0 2px;
    margin-left: 10px;
    letter-spacing: -0.1px;
    line-height: 19px;

    font-size: 14px;
    font-style: italic;

    &::before {
      position: absolute;
      top: 49%;
      left: -9px;
      width: 3px;
      height: 3px;
      content: "";
      background-color: $manatee;
      border-radius: 50%;
    }
  }

  :deep() .matching-card-tag-list__more {
    text-decoration: underline;
  }

  :deep() .matching-card-tag-list__container:not(.px-more-list) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    letter-spacing: -0.22px;
    line-height: 1.3;
  }

  :deep() .matching-card-tag-list__container.px-more-list {
    margin-top: 5px;
    letter-spacing: -0.1px;
    line-height: 1;
  }

  > div:nth-child(1),
  > div:nth-child(3),
  > div:nth-child(1) :deep() .matching-card-tag-list__container,
  > div:nth-child(3) div :deep() .matching-card-tag-list__container {
    color: $ebony-clay;
  }

  > div :deep() .matching-card-tag-list__container li::before {
    background-color: $manatee;
  }

  > div:nth-child(3) div :deep() .matching-card-tag-list__container li::before {
    background-color: $ebony-clay;
  }
}
</style>

<style lang="scss">
.match-details-table-row__tooltip {
  padding: 10px 15px;
  width: 340px;
  letter-spacing: -0.17px;
  text-transform: none;
}
</style>
