import { IGenericState } from "@/services/store/generic/generic-state.interface";
import { ISupporterFlow } from "@/modules/supporters/services/data/supporter-flow/supporter-flow.interface";

/**
 * Represents the store structure for the supporter type store data.
 */
export interface ISupporterFlowState extends IGenericState<ISupporterFlow> {
  value: ISupporterFlow;
}

export enum ESupporterFlowState {
  NAMESPACE = "supporterFlow",
}

export enum ESupporterFlowAction {
  SET = "supporterFlow/set",
  RESET = "supporterFlow/reset",
  CREATE_PENDING_SUPPORTER = "supporterFlow/createPendingSupporter",
  UPDATE_PENDING_SUPPORTER = "supporterFlow/updatePendingSupporter",
  SUBMIT_PENDING_SUPPORTER = "supporterFlow/submitPendingSupporter",
  SUBMIT_EXISTING_SUPPORTER = "supporterFlow/submitExistingSupporter",
}
