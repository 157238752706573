<template>
  <div
    v-if="hasTags"
    ref="matchingTagList"
    class="matching-card-tag-list__wrapper"
  >
    <div class="matching-card-tag-list__container">
      <template v-if="!listFormat">
        {{ mappedVisibleTags }}
      </template>
      <ul v-else>
        <template v-if="truncate">
          <li
            v-for="(tag, index) in truncatedVisibleTags"
            :key="index"
            :title="tag.originalValue"
          >
            {{ tag.value }}
          </li>
        </template>
        <template v-else>
          <li v-for="(tag, index) in visibleTags" :key="index" :title="tag">
            {{ tag }}
          </li>
        </template>
      </ul>
      <template v-if="!!moreTagsCopy">
        <PxMoreList
          :capitalize="capitalize"
          :modal-title="modalTitle"
          :values="tagsToDisplay"
          class="matching-card-tag-list__container"
        >
          <template #content>
            <div class="matching-card-tag-list__more">
              {{ moreTagsCopy }}
            </div>
          </template>
        </PxMoreList>
      </template>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { getTextWidth } from "@/services/utils/string.utils";

export default defineComponent({
  name: "MatchingCardTagList",

  props: {
    items: {
      type: Array as () => Array<string>,
      default: () => [],
    },

    maxLines: {
      type: Number,
      default: 1,
    },

    isSimplifiedVersion: {
      type: Boolean,
      default: false,
    },

    maxItemsToDisplay: {
      type: Number,
      default: null,
    },

    shouldSortArray: {
      type: Boolean,
      default: true,
    },

    title: {
      type: String,
      default: "matching.confirmModal.sectors.title",
    },

    listFormat: {
      type: Boolean,
      default: false,
    },

    capitalize: {
      type: Boolean,
      default: true,
    },

    truncate: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      tagFontStyle: "bold 14px HKGrotesk",
      defaultWrapperWidth: 129,
      moreTagsExpectedWidth: 70,
    };
  },

  computed: {
    truncatedItems(): any {
      return this.items.map((item: string) => {
        return {
          value: item.length > 54 ? `${item.slice(0, 54)}...` : item,
          originalValue: item,
        };
      });
    },

    truncatedVisibleTags(): any {
      if (this.maxItemsToDisplay) {
        return this.truncatedItems.slice(0, this.maxItemsToDisplay);
      }

      return [];
    },

    hasTags(): boolean {
      return this.items && !!this.items.length;
    },

    modalTitle(): string {
      return this.$t(this.title);
    },

    sortedTags(): string[] {
      return this.sortArrayByLength(this.items);
    },

    tagsToDisplay(): string[] {
      return this.shouldSortArray ? this.sortedTags : this.items;
    },

    visibleTags(): string[] {
      if (this.isSimplifiedVersion) {
        return [this.tagsToDisplay[0]];
      }

      if (this.maxItemsToDisplay) {
        return this.tagsToDisplay.slice(0, this.maxItemsToDisplay);
      }

      return this.getValuesOnLimit(this.tagsToDisplay).words;
    },

    moreTagsCopy(): string | null {
      if (this.items.length === 0) return null;

      const visibleTagsCount = this.visibleTags.length;

      const tagsOverLimit =
        !!this.items && this.tagsToDisplay.length > visibleTagsCount;

      return !visibleTagsCount
        ? (this.$tc("common.moreSectors", this.items.length, {
            number: this.items.length,
          }) as string)
        : tagsOverLimit
          ? (this.$t("common.symbolMoreNumber", {
              symbol: this.isSimplifiedVersion || this.listFormat ? "and " : "",
              number: this.items.length - visibleTagsCount,
            }) as string)
          : null;
    },

    mappedVisibleTags(): string {
      return this.visibleTags.reduce(
        (
          accumulated: string,
          currentValue: string,
          currentIndex: number,
          array: string[],
        ) => {
          const isLastValue = currentIndex === array.length - 1;
          const hasMoreTags = !!this.moreTagsCopy;

          return `${accumulated}${currentValue}${
            !isLastValue || hasMoreTags
              ? this.isSimplifiedVersion
                ? ","
                : " • "
              : ""
          }`;
        },
        "",
      );
    },
  },

  methods: {
    sortArrayByLength(array: string[]) {
      return [...array].sort((firstElem: string, secondElem: string) => {
        return firstElem.length - secondElem.length;
      });
    },

    /**
     * This method allows to return a list of string text values which size would fit in a specific
     * max width limit, with a given font style.
     * @param array
     * @param maxWidth
     * @param fontStyle
     */
    getValuesOnLimit(array: string[]) {
      const extraChars = ", ";

      const wrapperElement = this.$refs.matchingTagList as HTMLElement;
      // Set dynamic width based on wrapper
      const maxWidth = wrapperElement?.offsetWidth || this.defaultWrapperWidth;
      // Fetches tag list element style (containing font style), or it fallback to initial values
      // This will be applied to visible text calculations, as it varies due to font size and variant
      const fontStyle = wrapperElement?.style.toString() || this.tagFontStyle;

      return array.reduce(
        (
          accumulator: {
            words: string[];
            currentWidth: number;
            currentLine: number;
          },
          tag: string,
        ) => {
          // Last line can have extra more tags copy, thus we should calculate it here
          let isLastLine = accumulator.currentLine === this.maxLines;
          let lineMaxWidth = isLastLine
            ? maxWidth - this.moreTagsExpectedWidth
            : maxWidth;
          let willOverflow = accumulator.currentWidth > lineMaxWidth;

          // If limit surpassed, just skip
          if (willOverflow && isLastLine) {
            return accumulator;
          }

          // Calculate text width including current iterated tag
          const textWidth = getTextWidth(tag + extraChars, fontStyle);
          willOverflow = accumulator.currentWidth + textWidth > lineMaxWidth;

          // If cannot fit more tags
          if (willOverflow) {
            // Cannot use more lines, skip
            if (isLastLine) {
              return accumulator;
            }

            // Re-calculate overflow state by using next line
            isLastLine = accumulator.currentLine + 1 === this.maxLines;
            lineMaxWidth = isLastLine
              ? maxWidth - this.moreTagsExpectedWidth
              : maxWidth;
            willOverflow = accumulator.currentWidth + textWidth > lineMaxWidth;

            // If still overflowing, reached limit so skip
            if (willOverflow) {
              return accumulator;
            }
          }

          return {
            words: [...accumulator.words, tag],
            currentWidth: accumulator.currentWidth + textWidth,
            currentLine: accumulator.currentLine,
          };
        },
        { words: [], currentWidth: 0, currentLine: 1 },
      );
    },
  },
});
</script>

<style lang="scss" scoped>
.matching-card-tag-list__wrapper {
  position: relative;
  width: 100%;
  z-index: z("floaters");
}

.matching-card-tag-list__container {
  @include grotesk(semiBold);

  display: inline-block;
  height: 17px;
  font-size: 14px;
  color: $ebony-clay;
  vertical-align: middle;
  text-transform: capitalize;
}

.matching-card-tag-list__container.px-more-list {
  text-transform: none;
}

.matching-card-tag-list__more {
  @include grotesk(semiBold);

  height: 17px;
  font-size: 14px;
}
</style>
