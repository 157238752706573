import { IGenericState } from "@/services/store/generic/generic-state.interface";

/**
 * Returns initial values for generic state.
 */
export const getGenericInitialState = <T>(): IGenericState<T> => ({
  value: null,
  values: [],
  loading: false,
  error: null,
});
