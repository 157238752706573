import { App } from "vue";
import {
  ElAlert,
  ElAutocomplete,
  ElBadge,
  ElCheckbox,
  ElCheckboxGroup,
  ElCollapse,
  ElCollapseItem,
  ElDatePicker,
  ElDialog,
  ElDivider,
  ElDropdown,
  ElDropdownItem,
  ElDropdownMenu,
  ElForm,
  ElFormItem,
  ElInput,
  ElOption,
  ElPopover,
  ElRadio,
  ElRadioGroup,
  ElSelect,
  ElSlider,
  ElSwitch,
  ElTabPane,
  ElTabs,
  ElTable,
  ElTableColumn,
  ElTag,
  ElTooltip,
  ElUpload,
  ElCarousel,
  ElCarouselItem,
  ElMessage,
} from "element-plus";

export const ElementPlus = (app: App) => {
  app.component(ElAutocomplete.name as string, ElAutocomplete);
  app.component(ElBadge.name as string, ElBadge);
  app.component(ElCollapse.name as string, ElCollapse);
  app.component(ElCollapseItem.name as string, ElCollapseItem);
  app.component(ElDatePicker.name as string, ElDatePicker);
  app.component(ElDialog.name as string, ElDialog);
  app.component(ElDropdown.name as string, ElDropdown);
  app.component(ElDropdownMenu.name as string, ElDropdownMenu);
  app.component(ElDropdownItem.name as string, ElDropdownItem);
  app.component(ElSelect.name as string, ElSelect);
  app.component(ElForm.name as string, ElForm);
  app.component(ElFormItem.name as string, ElFormItem);
  app.component(ElInput.name as string, ElInput);
  app.component(ElSlider.name as string, ElSlider);
  app.component(ElSwitch.name as string, ElSwitch);
  app.component(ElTag.name as string, ElTag);
  app.component(ElTooltip.name as string, ElTooltip);
  app.component(ElOption.name as string, ElOption);
  app.component(ElAlert.name as string, ElAlert);
  app.component(ElCheckbox.name as string, ElCheckbox);
  app.component(ElCheckboxGroup.name as string, ElCheckboxGroup);
  app.component(ElUpload.name as string, ElUpload);
  app.component(ElPopover.name as string, ElPopover);
  app.component(ElTabs.name as string, ElTabs);
  app.component(ElTabPane.name as string, ElTabPane);
  app.component(ElRadio.name as string, ElRadio);
  app.component(ElRadioGroup.name as string, ElRadioGroup);
  app.component(ElDivider.name as string, ElDivider);
  app.component(ElCarousel.name as string, ElCarousel);
  app.component(ElCarouselItem.name as string, ElCarouselItem);
  app.component(ElTable.name as string, ElTable);
  app.component(ElTableColumn.name as string, ElTableColumn);
  app.use(ElMessage);
};
