<template>
  <div class="details-panel">
    <PxPanel
      v-if="!isLoading"
      :title="$t('selfAssessment.results.detailsPanel.title')"
      class="px-panel--mobile-no-side-borders"
    >
      <PxGrid
        :categories="categories"
        :current-level="finalLevel.value"
        :levels="numOfLevels"
        :results="results"
        :size="gridSize"
        class="details-panel__grid"
      />
      <hr />
      <ResultsReport :categories="categories" :levels="results" />
    </PxPanel>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import PxGrid from "@/components/px-grid/px-grid.vue";
import ResultsReport from "@/modules/affiliates/components/results-report/results-report.vue";

import { ICategory } from "@/services/data/category/category.interface";
import {
  ICategoryState,
  ECategoryActions,
} from "@/services/store/category/category-types";
import { ICategoryLevelSelection } from "@/services/data/viral-level/viral-level.interface";
import { IViralLevelState } from "@/services/store/viral-level/viral-level-types";
import { ILevel } from "@/services/data/level/level.interface";
import { IAffiliatesState } from "@/modules/affiliates/services/store/affiliates/affiliates.types";
import { ENTREPRENEUR_USER_GROUP_ID } from "@/modules/common/constants";
import { ELevelActions } from "@/services/store/levels/levels-types";

export default defineComponent({
  name: "DetailsPanel",

  components: {
    PxGrid,
    ResultsReport,
  },

  computed: {
    affiliates(): IAffiliatesState {
      return this.$store.state.affiliates;
    },

    viralState(): IViralLevelState {
      return this.$store.state.viralLevel as IViralLevelState;
    },

    isLoading(): boolean {
      return this.viralState.loading || this.affiliates.loading;
    },

    finalLevel(): ILevel {
      return this.viralState.finalLevel as ILevel;
    },

    /**
     * Get the categories in a ordered way.
     *
     * This will sort the categories by their order, from the
     * lowest to the higher order.
     */
    categories(): Array<ICategory> {
      const categoriesStore = this.$store.state.categories as ICategoryState;
      return [...categoriesStore.data].sort((a, b) => a.order - b.order);
    },

    /**
     * Return the number of levels.
     */
    numOfLevels(): number {
      return this.$store.get("levels/data").length;
    },

    /**
     * Get the user selections for the assessment.
     */
    results(): Array<ICategoryLevelSelection> {
      return this.viralState.levels;
    },

    gridSize(): string {
      return this.$screen.smDown ? "small" : "default";
    },
  },

  async created() {
    if (!this.categories.length) {
      await this.$store.dispatch(ECategoryActions.FETCH, {
        group: ENTREPRENEUR_USER_GROUP_ID,
      });
    }

    if (!this.numOfLevels) {
      await this.$store.dispatch(ELevelActions.FETCH, {
        group: ENTREPRENEUR_USER_GROUP_ID,
      });
    }
  },
});
</script>

<style lang="scss" scoped>
.details-panel {
  & > .px-panel {
    width: 100%;
  }

  .px-panel :deep() .px-panel__body {
    padding: 21px 9px;

    @include breakpoint-up(md) {
      padding: 21px 30px;
    }
  }

  &__grid {
    margin: 29px 0 39px;
  }

  hr {
    display: none;
    height: 1px;

    background-color: $athens-gray;
    border: 0;

    @include breakpoint-up(md) {
      display: block;
    }
  }
}
</style>
