import { ICompanyList } from "@/modules/company-lists/services/data/company-list/company-list.interface";
import { ICompanyListState } from "@/modules/company-lists/services/store/company-list/company-list.interface";
import { getGenericStateMutations } from "@/services/store/generic/generic-state.mutations";
import { MutationTree } from "vuex";

/**
 * Available mutations to manage company list store.
 */
export enum ECompanyListMutation {
  SET_ERROR = "setError",
  SET_LOADING = "setLoading",
  SET_VALUE = "setValue",
  SET_VALUES = "setValues",
  APPEND_VALUES = "appendValues",
  SET_PAGE = "SET_PAGE",
  SET_DETAIL_PAGE = "SET_DETAIL_PAGE",
  SET_DETAIL_LOADING = "SET_DETAIL_LOADING",
  SET_DETAIL_ERROR = "SET_DETAIL_ERROR",
}

export const mutations: MutationTree<ICompanyListState> = {
  ...getGenericStateMutations<ICompanyList>(),

  [ECompanyListMutation.SET_DETAIL_PAGE](state, newPageNumber: number | null) {
    state.currentDetailPage = newPageNumber;
  },

  [ECompanyListMutation.SET_DETAIL_LOADING](state, value: boolean) {
    state.detailLoading = value;
  },

  [ECompanyListMutation.SET_DETAIL_ERROR](state, value: Error | null) {
    state.detailError = value;
  },
};
