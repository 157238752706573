import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-016bd0cb"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "list-share-settings" }
const _hoisted_2 = { class: "list-share-settings__body" }
const _hoisted_3 = { class: "list-share-settings__footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ListShareSettingsLink = _resolveComponent("ListShareSettingsLink")!
  const _component_ListShareSettingsPasscode = _resolveComponent("ListShareSettingsPasscode")!
  const _component_PxButton = _resolveComponent("PxButton")!
  const _directive_loading = _resolveDirective("loading")!

  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_ListShareSettingsLink, {
        value: _ctx.link,
        class: "list-share-settings__body-entry"
      }, null, 8, ["value"]),
      _createVNode(_component_ListShareSettingsPasscode, {
        "needs-reset": _ctx.hasChangedPasscodeSetting,
        "onUpdate:needsReset": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.hasChangedPasscodeSetting) = $event)),
        value: _ctx.currentPasscode,
        "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.currentPasscode) = $event)),
        class: "list-share-settings__body-entry",
        onChange: _ctx.changeRestrictionHandler
      }, null, 8, ["needs-reset", "value", "onChange"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_PxButton, {
        class: "list-share-settings__footer-link",
        size: "small",
        type: "link",
        onClick: _ctx.onClickBackHandler
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t("common.back")), 1)
        ]),
        _: 1
      }, 8, ["onClick"]),
      _createVNode(_component_PxButton, {
        disabled: !_ctx.canSaveChanges || _ctx.isSavingChanges,
        class: "list-share-settings__footer-button",
        type: "green",
        onClick: _ctx.onClickSaveHandler
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t("common.saveChanges")), 1)
        ]),
        _: 1
      }, 8, ["disabled", "onClick"])
    ])
  ])), [
    [_directive_loading, _ctx.isSavingChanges]
  ])
}