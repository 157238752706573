import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, mergeProps as _mergeProps, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-01035e60"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "affiliate-review-page-team-members" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "affiliate-review-page-team-members__title" }
const _hoisted_4 = {
  key: 0,
  class: "affiliate-review-page-team-members__missing-info-label"
}
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = { class: "affiliate-review-page-team-members__wrapper" }
const _hoisted_7 = { class: "affiliate-review-page-team-members__info-divider" }
const _hoisted_8 = { class: "affiliate-review-page-team-members__info-divider" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PxIcon = _resolveComponent("PxIcon")!
  const _component_AffiliateReviewPageTeamMembersDetails = _resolveComponent("AffiliateReviewPageTeamMembersDetails")!
  const _component_PxInformationCardCollapsible = _resolveComponent("PxInformationCardCollapsible")!
  const _component_ElCollapse = _resolveComponent("ElCollapse")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.$screen.xlUp)
      ? (_openBlock(), _createElementBlock("header", _hoisted_2, [
          _createElementVNode("h3", _hoisted_3, _toDisplayString(_ctx.$t("affiliateProgram.components.flowNavbar.teamManagementTabTitle")), 1),
          (_ctx.teamMembersWithMissingMandatoryData.length)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createVNode(_component_PxIcon, {
                  size: 16,
                  name: "warning--white"
                }),
                _createElementVNode("p", { innerHTML: _ctx.missingTeamMembersInfoCopy }, null, 8, _hoisted_5)
              ]))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_6, [
      (_ctx.teamMembersWithMissingData.length)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("span", null, _toDisplayString(_ctx.$t("affiliates.reviewPage.content.notAnswered")), 1)
            ]),
            _createVNode(_component_ElCollapse, { accordion: true }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.teamMembersWithMissingData, (teamMember, index) => {
                  return (_openBlock(), _createBlock(_component_PxInformationCardCollapsible, _mergeProps({
                    key: index,
                    ref_for: true
                  }, _ctx.missingDataCardStyles(teamMember), {
                    description: _ctx.cardDescription(teamMember),
                    title: _ctx.getTeamMemberName(teamMember),
                    "title-styles": _ctx.cardTitleStyles(teamMember),
                    class: "affiliate-review-page-team-members__info-card",
                    onNavigate: _ctx.navigateToTeamMembersPage
                  }), {
                    content: _withCtx(() => [
                      _createVNode(_component_AffiliateReviewPageTeamMembersDetails, {
                        label: _ctx.missingDataCardStyles(teamMember)['label'],
                        "team-member": teamMember
                      }, null, 8, ["label", "team-member"])
                    ]),
                    _: 2
                  }, 1040, ["description", "title", "title-styles", "onNavigate"]))
                }), 128))
              ]),
              _: 1
            })
          ], 64))
        : _createCommentVNode("", true),
      (_ctx.validTeamMembers.length)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
            _createElementVNode("div", _hoisted_8, [
              _createElementVNode("span", null, _toDisplayString(_ctx.$t("affiliates.reviewPage.content.answered")), 1)
            ]),
            _createVNode(_component_ElCollapse, { accordion: true }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.validTeamMembers, (teamMember, index) => {
                  return (_openBlock(), _createBlock(_component_PxInformationCardCollapsible, _mergeProps({
                    key: index + _ctx.teamMembersWithMissingData.length,
                    ref_for: true
                  }, _ctx.answeredCardStyles, {
                    title: teamMember.first_name + ' ' + teamMember.last_name,
                    class: "affiliate-review-page-team-members__info-card",
                    onNavigate: _ctx.navigateToTeamMembersPage
                  }), {
                    content: _withCtx(() => [
                      _createVNode(_component_AffiliateReviewPageTeamMembersDetails, {
                        label: _ctx.answeredCardStyles['label'],
                        "team-member": teamMember
                      }, null, 8, ["label", "team-member"])
                    ]),
                    _: 2
                  }, 1040, ["title", "onNavigate"]))
                }), 128))
              ]),
              _: 1
            })
          ], 64))
        : _createCommentVNode("", true)
    ])
  ]))
}