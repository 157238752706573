import { MutationTree } from "vuex";

import { getBaseName } from "@/services/store/utils/get-base-name";
import {
  EMatchingQuestionaryMutations,
  IMatchingQuestionaryState,
} from "./matching-questionary.types";

export const matchingQuestionaryMutations: MutationTree<IMatchingQuestionaryState> =
  {
    /**
     * Increment current question index
     *
     * @param state Module state
     */
    [getBaseName(EMatchingQuestionaryMutations.INCREMENT)](state) {
      state.currentQuestionIndex += 1;
    },
  };
