import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createBlock as _createBlock, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-7dbf0180"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "process-reports-chart" }
const _hoisted_2 = { class: "process-reports-chart__header-wrapper" }
const _hoisted_3 = { class: "process-reports-chart__header" }
const _hoisted_4 = { class: "process-reports-chart__header-content" }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { key: 1 }
const _hoisted_7 = {
  key: 0,
  class: "process-reports-chart__content"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CompareDropdowns = _resolveComponent("CompareDropdowns")!
  const _component_ProcessReportsLocked = _resolveComponent("ProcessReportsLocked")!
  const _component_BarChart = _resolveComponent("BarChart")!
  const _component_InsightsCard = _resolveComponent("InsightsCard")!
  const _component_ChartsTableWrapper = _resolveComponent("ChartsTableWrapper")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          (_ctx.chartTitle)
            ? (_openBlock(), _createElementBlock("h1", _hoisted_5, _toDisplayString(_ctx.chartTitle), 1))
            : _createCommentVNode("", true),
          (_ctx.chartDescription)
            ? (_openBlock(), _createElementBlock("p", _hoisted_6, _toDisplayString(_ctx.chartDescription), 1))
            : _createCommentVNode("", true)
        ]),
        _createVNode(_component_CompareDropdowns, {
          options: _ctx.selectOptions,
          onChange: _ctx.setSelectedSteps
        }, null, 8, ["options", "onChange"])
      ]),
      (_ctx.hasLockedSteps)
        ? (_openBlock(), _createBlock(_component_ProcessReportsLocked, {
            key: 0,
            "all-steps-locked": _ctx.allStepsLocked,
            copy: _ctx.lockedState
          }, null, 8, ["all-steps-locked", "copy"]))
        : _createCommentVNode("", true)
    ]),
    (!_ctx.allStepsLocked)
      ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
          _createElementVNode("div", {
            class: _normalizeClass(["process-reports-chart__wrapper", {
          'process-reports-chart__wrapper--no-card': !_ctx.showInsightsCard,
        }])
          }, [
            _createVNode(_component_BarChart, {
              data: _ctx.barChartFinalData,
              "compare-data": _ctx.dataToCompare
            }, null, 8, ["data", "compare-data"]),
            (_ctx.showInsightsCard)
              ? (_openBlock(), _createBlock(_component_InsightsCard, {
                  key: 0,
                  insights: _ctx.insights
                }, null, 8, ["insights"]))
              : _createCommentVNode("", true)
          ], 2),
          _createVNode(_component_ChartsTableWrapper, {
            labels: _ctx.responsesLabels,
            colors: _ctx.backgroundColors,
            theme: _ctx.theme,
            data: _ctx.data,
            "data-to-compare": _ctx.dataToCompare
          }, null, 8, ["labels", "colors", "theme", "data", "data-to-compare"])
        ]))
      : _createCommentVNode("", true)
  ]))
}