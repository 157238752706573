import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-65741e88"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "affiliate-assessment-entrepreneur" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CategoryWizard = _resolveComponent("CategoryWizard")!
  const _component_CategoryWizardMobile = _resolveComponent("CategoryWizardMobile")!
  const _component_CategoryLevelsAssessment = _resolveComponent("CategoryLevelsAssessment")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.showCategoryWizard)
      ? (_openBlock(), _createBlock(_component_CategoryWizard, {
          key: 0,
          "current-step": _ctx.currentSelectedCategory,
          "onUpdate:currentStep": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.currentSelectedCategory) = $event)),
          checked: _ctx.checkedSteps,
          steps: _ctx.formattedCategories,
          onChanged: _ctx.onCategoryChange
        }, null, 8, ["current-step", "checked", "steps", "onChanged"]))
      : (_openBlock(), _createBlock(_component_CategoryWizardMobile, {
          key: 1,
          "current-step": _ctx.currentSelectedCategory,
          "onUpdate:currentStep": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.currentSelectedCategory) = $event)),
          checked: _ctx.checkedSteps,
          steps: _ctx.formattedCategories
        }, null, 8, ["current-step", "checked", "steps"])),
    _createVNode(_component_CategoryLevelsAssessment, { "is-self-assessment": _ctx.isSelfAssessment }, null, 8, ["is-self-assessment"])
  ]))
}