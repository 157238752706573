import { MutationTree } from "vuex";

import type { IMilestoneReportsState } from "@/modules/charts-dashboard/services/store/milestone/milestone.interface";
import { getGenericStateMutations } from "@/services/store/generic/generic-state.mutations";
import { IMilestoneReports } from "@/modules/charts-dashboard/services/data/milestone-reports/milestone.interface";

export enum EMilestoneReportsMutation {
  SET_ERROR = "setError",
  SET_LOADING = "setLoading",
  SET_VALUE = "setValue",
  SET_VALUES = "setValues",
}

export const mutations: MutationTree<IMilestoneReportsState> = {
  ...getGenericStateMutations<IMilestoneReports>(),

  [EMilestoneReportsMutation.SET_VALUE](state, value: IMilestoneReports) {
    state.value = value;
  },
};
