<template>
  <PxPanel class="panel-grid__block investing-level-panel-placeholder">
    <ContentPlaceholders :rounded="true">
      <div class="investing-level-panel-placeholder__level">
        <ContentPlaceholdersHeading
          class="investing-level-panel-placeholder__title"
        />
        <ContentPlaceholdersImg
          class="investing-level-panel-placeholder__thumbnail"
        />
        <ContentPlaceholders
          :centered="true"
          class="investing-level-panel-placeholder__content"
        >
          <ContentPlaceholdersHeading />
        </ContentPlaceholders>
        <ContentPlaceholdersText
          :lines="1"
          class="investing-level-panel-placeholder__cta"
        />
      </div>
    </ContentPlaceholders>
  </PxPanel>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "InvestmentLevelPanelPlaceholder",
});
</script>

<style lang="scss" scoped>
.px-panel__body {
  padding-bottom: 0;
}

.investing-level-panel-placeholder {
  padding: 13px 0;
}

.investing-level-panel-placeholder__level :deep() {
  .investing-level-panel-placeholder__title {
    .vue-content-placeholders-heading__title {
      width: 30%;
    }

    .vue-content-placeholders-heading__subtitle {
      display: none;
    }
  }

  .investing-level-panel-placeholder__thumbnail {
    width: 173px;
    height: 140px;
    margin: 18px auto 0;
  }

  .investing-level-panel-placeholder__content {
    margin-top: 17px;

    .vue-content-placeholders-heading__title {
      width: 20%;
    }

    .vue-content-placeholders-heading__subtitle {
      width: 60%;
      margin-top: 5px;
    }
  }

  .investing-level-panel-placeholder__cta {
    margin-top: 20px;

    .vue-content-placeholders-text__line {
      width: 305px;
      height: 42px;
      margin: auto;
    }
  }
}

.investing-level-panel-placeholder__grid :deep() {
  margin-top: 70px;

  .investing-level-panel-placeholder__title {
    .vue-content-placeholders-heading__title {
      width: 30%;
    }

    .vue-content-placeholders-heading__subtitle {
      display: none;
    }
  }

  .investing-level-panel-placeholder__thumbnail {
    width: 415px;
    height: 215px;
    margin: 28px auto 0;
  }

  .investing-level-panel-placeholder__thumbnail-caption {
    margin: 12px auto 0;

    .vue-content-placeholders-text {
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
      padding: 0 27px;
    }

    .vue-content-placeholders-text__line {
      width: auto;

      &:first-child {
        flex-basis: 45%;
      }

      &:last-child {
        flex-basis: 30%;
      }
    }
  }

  .investing-level-panel-placeholder__list {
    column-count: 2;
    column-gap: 50px;
    margin: 16px 40px 0;
  }
}
</style>
