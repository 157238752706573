<template>
  <div
    :class="`px-avatar px-avatar--${type} px-avatar--${styling}`"
    :style="imageStyle"
  >
    <template v-if="loading">
      <ContentPlaceholders>
        <ContentPlaceholdersText :lines="1" :style="imageStyle" />
      </ContentPlaceholders>
    </template>
    <img
      v-if="photo"
      :alt="name"
      :src="photo"
      :style="imageStyle"
      class="px-avatar__image"
    />
    <div
      v-if="!photo && !loading"
      :style="emptyAvatarStyle"
      class="px-avatar__empty"
    >
      <span class="px-avatar__letter">
        {{ firstLetter }}
      </span>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "PxAvatar",

  props: {
    /**
     * Link to the photo.
     */
    photo: {
      type: String,
      default: null,
    },

    /**
     * Avatar name
     */
    name: {
      type: String,
      default: " ",
    },

    /**
     * Avatar image size
     */
    size: {
      type: Number,
      default: 95,
    },

    /**
     * Loading state
     */
    loading: {
      type: Boolean,
      default: false,
    },

    type: {
      type: String,
      default: "default",
      validator: (value: string) => ["default", "round"].includes(value),
    },

    styling: {
      type: String,
      default: "colorful",
      validator: (value: string) => ["colorful", "neutral"].includes(value),
    },
  },

  data() {
    return {
      backgroundColors: ["#7bb7ff", "#72cc72", "#f6a34b"],
    };
  },

  computed: {
    /**
     * First letter of the avatar name.
     */
    firstLetter(): string {
      return this.name.charAt(0).toUpperCase();
    },

    /**
     * Image styles based on the image size.
     */
    imageStyle(): { [key: string]: string } {
      const imgSize = `${this.size}px`;
      return {
        width: imgSize,
        height: imgSize,
      };
    },

    isColorful(): boolean {
      return this.styling === "colorful";
    },

    backgroundGradientColor(): string {
      const backgroundColor =
        this.backgroundColors[
          this.name.charCodeAt(0) % this.backgroundColors.length
        ];

      return `linear-gradient(
          to bottom,
          rgba(39, 39, 64, 0),
          rgba(39, 39, 64, 0.1)
        ),
        linear-gradient(to bottom, ${backgroundColor}, ${backgroundColor})`;
    },

    emptyAvatarStyle(): { [key: string]: string } {
      const fontSize = (this.size * 65) / 95;
      const fallbackBackground = this.isColorful
        ? this.backgroundGradientColor
        : "";

      return {
        ...this.imageStyle,
        lineHeight: `${this.size}px`,
        fontSize: `${fontSize}px`,
        backgroundImage: fallbackBackground,
      };
    },
  },
});
</script>

<style lang="scss" scoped>
.px-avatar {
  position: relative;
  overflow: hidden;

  background-color: white;
  border-radius: 3px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.21);

  &__image {
    display: block;
    border-radius: 3px;
    object-fit: contain;
  }

  &__empty {
    @include grotesk(bold);

    position: relative;
    overflow: hidden;
    color: white;
    text-align: center;
  }

  &__letter {
    position: relative;
    top: 0;
    z-index: z("default") + 2;
    display: block;
    height: auto;

    font-size: inherit;
    font-weight: inherit;
    line-height: 1.45;
  }

  .vue-content-placeholders {
    position: absolute;
    z-index: z("default") + 3;
    opacity: 0.48;
  }

  .vue-content-placeholders-text {
    position: relative;
  }

  :deep() .vue-content-placeholders-text__line {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: auto;
    height: 100%;

    border-radius: $--border-radius-base;
  }
}

.px-avatar--round {
  border-radius: 100%;

  :deep() img {
    object-fit: cover;
  }
}

.px-avatar--neutral {
  background-color: $mischka;

  .px-avatar__empty {
    @include grotesk(medium);

    color: rgba($ebony-clay, 0.7);
  }
}
</style>
