import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-44bc1152"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "milestone-plan-navigation-bar" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MilestoneCategoryNavigation = _resolveComponent("MilestoneCategoryNavigation")!
  const _component_MilestoneLevelNavigation = _resolveComponent("MilestoneLevelNavigation")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_MilestoneCategoryNavigation, {
      categories: _ctx.categories,
      "current-category": _ctx.selectedCategory,
      onChange: _ctx.changeCategory
    }, null, 8, ["categories", "current-category", "onChange"]),
    _createVNode(_component_MilestoneLevelNavigation, {
      "active-level": _ctx.selectedLevel,
      "category-color": _ctx.selectedCategory.color,
      "category-name": _ctx.selectedCategory.name,
      milestones: _ctx.milestones,
      onChange: _ctx.changeLevel
    }, null, 8, ["active-level", "category-color", "category-name", "milestones", "onChange"])
  ]))
}