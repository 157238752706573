import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-16955682"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["innerHTML"]
const _hoisted_2 = {
  key: 0,
  class: "multi-selector__option-container"
}
const _hoisted_3 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ElCheckbox = _resolveComponent("ElCheckbox")!
  const _component_ElCollapseTransition = _resolveComponent("ElCollapseTransition")!
  const _component_ElOption = _resolveComponent("ElOption")!

  return (_openBlock(), _createBlock(_component_ElOption, {
    ref: "option",
    value: _ctx.innerItem,
    class: "multi-selector__option"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_ElCheckbox, {
        ref: "sticky",
        "model-value": _ctx.isSelected,
        class: _normalizeClass([{
        'multi-selector__checkbox-sticky--shadow': _ctx.showStickyShadow,
        'multi-selector__checkbox--collapsed': _ctx.isCollapsed,
      }, "multi-selector__option-item multi-selector__checkbox multi-selector__checkbox-sticky"]),
        indeterminate: _ctx.isIndeterminate,
        onChange: _cache[2] || (_cache[2] = ($event: any) => (_ctx.onItemClick(_ctx.innerItem)))
      }, {
        default: _withCtx(() => [
          _createElementVNode("span", {
            class: "multi-selector__label",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.labelClickHandler && _ctx.labelClickHandler(...args))),
            innerHTML: _ctx.highlightLabelByQuery(_ctx.innerItem.label, _ctx.hasChildren)
          }, null, 8, _hoisted_1),
          (_ctx.hasChildren)
            ? (_openBlock(), _createElementBlock("span", {
                key: 0,
                class: "multi-selector__label-subtext",
                onClick: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.childrenCountClickHandler && _ctx.childrenCountClickHandler(...args)), ["prevent"]))
              }, _toDisplayString(_ctx.childrenCountCopy(_ctx.innerItem.children)), 1))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["model-value", "class", "indeterminate"]),
      _createVNode(_component_ElCollapseTransition, null, {
        default: _withCtx(() => [
          (!_ctx.isCollapsed)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.visibleChildren, (child) => {
                  return (_openBlock(), _createBlock(_component_ElCheckbox, {
                    key: `${_ctx.innerItem.value}-${child.value}`,
                    "model-value": child.selected,
                    class: "multi-selector__option-item multi-selector__checkbox",
                    onChange: ($event: any) => (_ctx.onItemClick(child))
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("span", {
                        innerHTML: _ctx.highlightLabelByQuery(child.label)
                      }, null, 8, _hoisted_3)
                    ]),
                    _: 2
                  }, 1032, ["model-value", "onChange"]))
                }), 128)),
                (_ctx.visibleShowAll)
                  ? (_openBlock(), _createElementBlock("span", {
                      key: 0,
                      class: "multi-selector__option-item multi-selector__show-all-text",
                      onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.onShowAllItemsClick && _ctx.onShowAllItemsClick(...args)))
                    }, _toDisplayString(_ctx.$t("common.components.multiSelector.showAll")), 1))
                  : _createCommentVNode("", true)
              ]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["value"]))
}