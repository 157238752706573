<template>
  <div class="investors-panel-accordion">
    <ElCollapse v-model="activeDrawer" accordion>
      <ElCollapseItem
        v-for="(item, index) in items"
        :key="index"
        :disabled="!item.description"
        :name="index"
      >
        <template #title>
          <div class="investors-panel-accordion__headline">
            <h3>
              <span v-html="item.title" />
              <ElTag effect="plain" :color="`#${item.color}`">
                {{ item.category.toUpperCase() }}: {{ item.level }}
              </ElTag>
            </h3>
          </div>
        </template>
        <div v-html="item.description" />
      </ElCollapseItem>
    </ElCollapse>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { IInvestorsPanelAccordionItem } from "@/modules/profile/components/investors-panel/investors-panel-accordion.interface";

export default defineComponent({
  name: "InvestorsPanelAccordion",

  props: {
    items: {
      type: Array as () => IInvestorsPanelAccordionItem[],
      required: true,
    },
  },

  data() {
    return {
      activeDrawer: [0],
    };
  },
});
</script>

<style lang="scss" scoped>
.investors-panel-accordion :deep() {
  transform: translate3d(0, 0, 0);

  @include breakpoint-down(sm) {
    padding-top: 15px;
  }

  .el-collapse-item__content {
    position: relative;
    top: -5px;
    left: -1px;
    padding-right: 23px;
    padding-bottom: 13px;
    letter-spacing: -0.15px;

    @include breakpoint-down(sm) {
      padding-top: 10px;
    }
  }
}

.investors-panel-accordion :deep(.el-collapse-item.is-disabled) {
  .el-collapse-item__header {
    min-height: 63px;
    padding-left: 9px;
    pointer-events: none;

    &::before,
    &::after {
      display: none;
    }
  }
}

.investors-panel-accordion__headline {
  padding: 10px 0 14px;

  @include breakpoint-up(md) {
    padding: 6px 0 10px;
  }
}

.investors-panel-accordion__headline h3 {
  @include grotesk(regular);

  position: relative;
  top: 4px;
  flex: 0 1 auto;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  gap: 0 10px;

  span:first-of-type,
  span :deep(p) {
    display: block;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.15px;
    text-align: left;

    @include breakpoint-up(md) {
      display: inline-block;
    }
  }

  span :deep(p) {
    margin-right: 5px;
    max-width: 502px;
  }

  :deep(.el-tag) {
    @include grotesk(bold);

    position: relative;
    height: 26px;
    margin: 8px 0;

    font-size: 13px;
    text-transform: uppercase;

    @include breakpoint-up(md) {
      top: -2px;
      height: 22px;
      padding: 4px 7px;
      font-size: 11px;
      letter-spacing: 0;
    }
  }
}
</style>
