<template>
  <div class="results-board">
    <div class="results-board__wrapper">
      <PxPanel class="results-board__panel px-panel--mobile-no-side-borders">
        <div v-if="investingLevel.length" class="results-board__container">
          <div class="results-board__score-wrapper">
            <PxIcon
              :name="`level-range-graph/level-${investingLevelRangeInterval}`"
              :size="174"
              class="results-board__level"
            />
          </div>
          <h2 class="results-board__title" v-html="investingLevelRangeTitle" />
          <ol class="results-board__levels">
            <li
              v-for="(level, key) in investingLevelsSelection"
              :key="key"
              class="results-board__levels-item"
            >
              <b>
                {{ $t("supporters.results.levelPanel.label") }}
                {{ level.value }}
              </b>
              - {{ level.title }}
              <ElTooltip
                :enterable="false"
                placement="top"
                popper-class="results-board__levels-tooltip el-abaca-tooltip"
              >
                <template #content>
                  <div v-html="level.description" />
                </template>
                <PxIcon
                  :size="17"
                  class="results-board__levels-tooltip-icon"
                  name="question"
                />
              </ElTooltip>
            </li>
          </ol>
        </div>
      </PxPanel>
      <ResultsBoardJoin />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import uniqBy from "lodash/uniqBy";
import sortBy from "lodash/sortBy";

import ResultsBoardJoin from "@/modules/supporters/components/results-board/results-board-join.vue";
import { SupporterFlowState } from "@/modules/supporters/services/store/supporter-flow/supporter-flow.module";
import { ISupporterFlow } from "@/modules/supporters/services/data/supporter-flow/supporter-flow.interface";
import { ISupporter } from "@/modules/matching/services/data/matching-score/supporter.interface";
import { ELevelActions } from "@/services/store/levels/levels-types";
import { SUPPORTER_USER_GROUP_ID } from "@/modules/common/constants";
import { ILevel } from "@/services/data/level/level.interface";

export default defineComponent({
  name: "ResultsBoard",

  components: {
    ResultsBoardJoin,
  },

  computed: {
    /**
     * When present that means that registration feature is allowed.
     */
    allowsRegistration(): boolean {
      return this.$features.isEnabled("assessmentResultsPageAllowRegistration");
    },

    flowData(): ISupporterFlow {
      return this.$store.getters[SupporterFlowState.Getter.VALUE];
    },

    hasError(): boolean {
      return this.$store.getters[SupporterFlowState.Getter.HAS_ERROR];
    },

    levels(): Array<ILevel> {
      return this.$store.get("levels/data");
    },

    investingLevel(): ISupporter["investing_level_range"] {
      return this.flowData.supporter.investing_level_range;
    },

    investingLevelMinimum(): number {
      return this.investingLevel.length ? this.investingLevel[0] : 1;
    },

    investingLevelMaximum(): number | null {
      return this.investingLevel.length > 1 ? this.investingLevel[1] : null;
    },

    investingLevelsSelection(): Array<ILevel> {
      if (!this.investingLevelMaximum) {
        return this.levels.filter(
          (level) => level.value === this.investingLevelMinimum,
        );
      }

      const uniqAscendingLevels = sortBy(uniqBy(this.levels, "value"), "value");
      return uniqAscendingLevels.filter(
        (level) =>
          level.value >= this.investingLevelMinimum &&
          !!this.investingLevelMaximum &&
          level.value <= this.investingLevelMaximum,
      );
    },

    investingLevelRangeInterval(): string {
      if (this.investingLevelMaximum) {
        return this.investingLevelMinimum === this.investingLevelMaximum
          ? this.investingLevelMinimum.toString()
          : this.investingLevel.join("-");
      }

      return this.investingLevelMinimum.toString();
    },

    investingLevelRangeTitle(): string {
      const minimumLevel = this.levels.find(
        (level) => level.value === this.investingLevelMinimum,
      );
      let rangeTitle = minimumLevel
        ? `${minimumLevel.title} <span>(${this.$t(
            "supporters.results.levelPanel.label",
          )} ${minimumLevel.value})</span>`
        : "";

      if (this.investingLevelMaximum !== this.investingLevelMinimum) {
        const maximumLevel = this.levels.find(
          (level) => level.value === this.investingLevelMaximum,
        );
        rangeTitle += maximumLevel
          ? `<br><span>to</span> ${maximumLevel.title} <span>(${this.$t(
              "supporters.results.levelPanel.label",
            )} ${maximumLevel.value})</span>`
          : "";
      }

      return rangeTitle;
    },
  },

  created() {
    if (!this.levels.length) {
      // Fetch levels if user lands on the results page directly.
      this.fetchLevels();
    }
  },

  methods: {
    async fetchLevels() {
      await this.$store.dispatch(ELevelActions.FETCH, {
        group: SUPPORTER_USER_GROUP_ID,
      });
    },
  },
});
</script>

<style lang="scss" scoped>
.results-board__wrapper {
  display: flex;
  flex-flow: row wrap;

  @include breakpoint-up(lg) {
    display: grid;
    grid-template-columns: 488px 450px;
  }
}

.results-board__panel {
  z-index: z("default");
  width: 100%;
  max-width: unset;

  @include breakpoint-up(lg) {
    min-height: 567px;
  }

  & :deep() .px-panel__body {
    padding: 2px 15px 17px;

    @include breakpoint-up(md) {
      padding: 30px 30px 37px;
    }
  }
}

.results-board .results-board__cta-panel {
  @include breakpoint-down(sm) {
    padding: 30px 0;
    margin: 0;
    border-top: none;
  }
}

.results-board :deep() .results-board__footer {
  @include breakpoint-down(sm) {
    margin-top: 36px;
  }
}

.results-board__container {
  display: grid;
  grid-template-areas:
    "level title"
    "description description";
  grid-template-columns: 140px auto;
  grid-column-gap: 17px;

  @include breakpoint-up(sm) {
    display: block;
    text-align: center;
  }
}

.results-board__score-wrapper {
  grid-area: level;
  max-height: 146px;

  @include breakpoint-up(md) {
    max-height: unset;
  }
}

.results-board__level {
  max-width: 140px;

  @include breakpoint-down(sm) {
    position: relative;
    top: -24px;
    right: 1px;
  }

  @include breakpoint-up(md) {
    max-width: unset;
  }
}

.results-board-error {
  margin-top: -4px;

  & :deep() .px-global-error__icon {
    margin-bottom: 2px;
  }

  & :deep() .px-global-error__notice {
    padding-top: 1px;
    margin-bottom: 6px;

    font-size: 0.9333rem;
  }
}

.results-board :deep() {
  .results-board__title {
    grid-area: title;

    font-size: 1.2667rem;
    line-height: 26px;

    @include breakpoint-up(md) {
      margin-top: 6px;
      margin-bottom: 0;

      font-size: 22px;
      line-height: 32px;
    }

    span {
      display: inline-block;
      font-size: 22px;
      line-height: 32px;
      letter-spacing: -0.4px;
    }
  }

  .results-board__levels {
    padding: 0;
    margin: 31px auto 15px;
    list-style: none;

    &-item {
      font-size: 15px;
      line-height: 26px;

      &:not(:first-child) {
        margin-top: 8px;
      }
    }

    b {
      font-weight: 600;
    }
  }

  .results-board__levels-tooltip-icon {
    display: inline-block;
    // override square size
    width: 16px !important;
    margin-left: 2px;
    vertical-align: -3px;
  }

  .results-board__list {
    text-align: left;
    list-style-type: none;

    li {
      position: relative;
      padding-right: 5px;
      padding-left: 28px;
      font-size: 15px;
      line-height: 25px;
      color: $tundora;
      letter-spacing: -0.2px;

      @include breakpoint-up(md) {
        font-size: 17px;
        line-height: 30px;
        letter-spacing: -0.25px;
      }

      &:not(:first-child) {
        margin-top: 6px;

        @include breakpoint-up(md) {
          margin-top: 10px;
        }
      }
    }

    li::before {
      position: absolute;
      top: 5px;
      left: 2px;
      width: 14px;
      height: 14px;
      content: "";
      background-image: url("#{$assetsPath}/img/icons/check--blue.svg");
      background-repeat: no-repeat;
      background-size: contain;

      @include breakpoint-up(md) {
        top: 8px;
        left: 0;
        width: 16px;
        height: 14px;
      }
    }
  }
}
</style>

<style lang="scss">
.results-board__levels-tooltip {
  max-width: 260px;
}
</style>
