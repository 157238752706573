import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-04a7f058"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "team-member-settings" }
const _hoisted_2 = { class: "team-member-settings__forms" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TeamMemberSettingsHeader = _resolveComponent("TeamMemberSettingsHeader")!
  const _component_TeamMemberMandatoryData = _resolveComponent("TeamMemberMandatoryData")!
  const _component_TeamMemberDemographicData = _resolveComponent("TeamMemberDemographicData")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_TeamMemberSettingsHeader, {
      "is-from-profile": _ctx.isFromProfile,
      "is-save-button-disabled": _ctx.mandatoryFormInvalid || !_ctx.hasChanges,
      onSaveMembers: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('save-members')))
    }, null, 8, ["is-from-profile", "is-save-button-disabled"]),
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(), _createBlock(_component_TeamMemberMandatoryData, {
        key: _ctx.memberIndex,
        "is-from-profile": _ctx.isFromProfile,
        "is-loading": _ctx.isLoading,
        "member-index": _ctx.memberIndex,
        "member-info": _ctx.memberMandatoryData,
        class: "team-member-settings__form-mandatory",
        onFormIsInvalid: _ctx.checkMandatoryFormIsInvalid,
        onNewMemberInfo: _ctx.updateMemberInfo
      }, null, 8, ["is-from-profile", "is-loading", "member-index", "member-info", "onFormIsInvalid", "onNewMemberInfo"])),
      (_ctx.questions.length && !_ctx.isFromProfile)
        ? (_openBlock(), _createBlock(_component_TeamMemberDemographicData, {
            key: 0,
            "should-format-responses": _ctx.shouldFormatResponses,
            "onUpdate:shouldFormatResponses": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.shouldFormatResponses) = $event)),
            "is-loading": _ctx.isLoading,
            questions: _ctx.questions,
            responses: 
          _ctx.memberDataUpdated
            ? (_ctx.memberDataUpdated.responses as IMatchingResponse[])
            : []
        ,
            onFormIsInvalid: _ctx.checkDemographicFormIsInvalid,
            onHasNewResponses: _ctx.updateResponses,
            onResponsesFormatted: _ctx.updateOriginalResponses
          }, null, 8, ["should-format-responses", "is-loading", "questions", "responses", "onFormIsInvalid", "onHasNewResponses", "onResponsesFormatted"]))
        : _createCommentVNode("", true)
    ])
  ]))
}