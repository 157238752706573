import { ActionTree } from "vuex";
import {
  ECompanyListAction,
  ICompanyListState,
} from "@/modules/company-lists/services/store/company-list/company-list.interface";
import { RootState } from "@/services/store/root-state";
import { getGenericStateActions } from "@/services/store/generic/generic-state.actions";
import {
  ICompanyList,
  ICompanyOfList,
} from "@/modules/company-lists/services/data/company-list/company-list.interface";
import { companyListProvider } from "@/modules/company-lists/services/data/company-list/company-list.provider";
import { GenericState } from "@/services/store/generic/generic-state.module";
import clone from "lodash/clone";
import {
  EListPasscodeActions,
  EListPasscodeGetters,
  IListPasscodePayload,
} from "@/modules/company-lists/services/store/passcode/passcode.interface";
import { ECompanyListMutation } from "@/modules/company-lists/services/store/company-list/company-list.mutations";
import { IPaginationResult } from "@/services/data/generic-provider";
import { EMatchingActions } from "@/modules/matching/services/store/matching/matching.types";
import { IMatchingInterest } from "@/modules/matching/services/data/matching-interest/matching-interest.interface";
import { IMatchingScore } from "@/modules/matching/services/data/matching-score/matching-score.interface";
import { EMatchingInterestActions } from "@/modules/matching/services/store/matching-interest/matching-interest.types";
import { userManager } from "@/modules/authentication/user-manager";
import { featureManager } from "@/services/feature-manager";
import { activeModules } from "@/services/utils/utils";

export const actions: ActionTree<ICompanyListState, RootState> = {
  ...getGenericStateActions<ICompanyList>(companyListProvider),

  /**
   * Get list of company lists owned by the user.
   *
   * @returns the servers response.
   */
  async [ECompanyListAction.GET_VALUES](
    { commit },
    setLoading = true,
  ): Promise<Array<ICompanyList>> {
    if (setLoading) {
      commit(GenericState.Mutation.SET_LOADING, true);
    }

    commit(GenericState.Mutation.SET_ERROR, null);

    let response: ICompanyList[] = [];
    try {
      response = await companyListProvider.list();
      commit(ECompanyListMutation.SET_VALUES, response);
    } catch (error) {
      commit(ECompanyListMutation.SET_VALUES, null);
      commit(GenericState.Mutation.SET_ERROR, error || true);
    } finally {
      if (setLoading) {
        commit(GenericState.Mutation.SET_LOADING, false);
      }
    }

    return response;
  },

  /**
   * Fetch a single company list.
   */
  async [ECompanyListAction.GET_VALUE](
    { commit, dispatch, state, rootGetters },
    queryParams = {},
  ): Promise<ICompanyList | null> {
    commit(GenericState.Mutation.SET_LOADING, true);
    commit(GenericState.Mutation.SET_ERROR, null);
    commit(ECompanyListMutation.SET_DETAIL_LOADING, true);
    commit(ECompanyListMutation.SET_DETAIL_ERROR, null);

    let list = state.value as ICompanyList;

    try {
      // remove id from the query params to prevent an ID to be sent as a query string
      const params = clone(queryParams);
      delete params["id"];

      // when the passcode isn't provided search one on the store and if exists append it to request
      if (!params.passcode) {
        const storedPasscode = rootGetters[EListPasscodeGetters.GET](
          queryParams.id,
        );
        if (storedPasscode) {
          params.passcode = storedPasscode;
        }
      }

      list = await companyListProvider.get(queryParams.id, params);

      // On new detail fetch, page should be reset to 1
      try {
        const response = await companyListProvider.getDetailedCompanies(
          queryParams.id,
          { ...params, page: 1 },
        );

        list = {
          ...list,
          companyCount: response.count,
          companies: response.results,
          allCompaniesCompacted: list.companies,
        };

        commit(ECompanyListMutation.SET_DETAIL_PAGE, 1);
        commit(ECompanyListMutation.SET_VALUE, list);

        // Set matching interests
        if (
          userManager.isLogged() &&
          featureManager.isEnabled("match") &&
          activeModules().includes("matching")
        ) {
          // TODO: Extract this to util method and apply unit tests
          const { discoverScores, ...remainingData } = response.results.reduce(
            (accumulated: any, company: ICompanyOfList) => {
              const {
                discoverScores: accumDiscover,
                interests: accumInterest,
                userInterestScoreList: accumUser,
                targetInterestScoreList: accumTarget,
                mutualInterestScoreList: accumMutual,
              } = accumulated;

              // Discover score, no interest
              if (!company.interests) {
                return {
                  ...accumulated,
                  discoverScores: [...accumDiscover, company],
                };
              }

              const hasUserInterest =
                (userManager.isEntrepreneur() &&
                  !!company.interests.entrepreneur_is_interested) ||
                (userManager.isSupporter() &&
                  !!company.interests.supporter_is_interested);

              const hasTargetInterest =
                (userManager.isEntrepreneur() &&
                  !!company.interests.supporter_is_interested) ||
                (userManager.isSupporter() &&
                  !!company.interests.entrepreneur_is_interested);

              const hasMutualInterest = hasUserInterest && hasTargetInterest;

              if (hasMutualInterest) {
                return {
                  ...accumulated,
                  mutualInterestScoreList: [...accumMutual, company],
                  interests: [...accumInterest, company.interests],
                };
              }

              if (hasUserInterest) {
                return {
                  ...accumulated,
                  userInterestScoreList: [...accumUser, company],
                  interests: [...accumInterest, company.interests],
                };
              }

              if (hasTargetInterest) {
                return {
                  ...accumulated,
                  targetInterestScoreList: [...accumTarget, company],
                  interests: [...accumInterest, company.interests],
                };
              }

              return {
                ...accumulated,
                interests: [...accumInterest, company.interests],
              };
            },
            {
              discoverScores: [] as Array<IMatchingScore>,
              interests: [] as Array<IMatchingInterest>,
              userInterestScoreList: [] as Array<IMatchingScore>,
              targetInterestScoreList: [] as Array<IMatchingScore>,
              mutualInterestScoreList: [] as Array<IMatchingScore>,
            },
          );

          dispatch(
            EMatchingActions.APPEND,
            { data: discoverScores },
            { root: true },
          );
          dispatch(EMatchingInterestActions.APPEND, remainingData, {
            root: true,
          });
        }
      } catch (error) {
        commit(ECompanyListMutation.SET_DETAIL_PAGE, null);
        commit(ECompanyListMutation.SET_DETAIL_ERROR, error || true);
      }

      // persist list passcode to be used on future requests to the same list
      const passcodeActionPayload: IListPasscodePayload = {
        listUid: list.uid,
        passcode: params.passcode,
      };
      dispatch(EListPasscodeActions.SET, passcodeActionPayload, { root: true });
    } catch (error) {
      commit(GenericState.Mutation.SET_VALUE, null);
      commit(GenericState.Mutation.SET_ERROR, error || true);
    } finally {
      commit(GenericState.Mutation.SET_LOADING, false);
      commit(ECompanyListMutation.SET_DETAIL_LOADING, false);
    }

    return list;
  },

  /**
   * Fetch a list detail.
   */
  async [ECompanyListAction.GET_VALUE_DETAIL](
    { commit, state, dispatch, rootGetters },
    queryParams = {},
  ): Promise<IPaginationResult<ICompanyOfList>> {
    commit(ECompanyListMutation.SET_DETAIL_LOADING, true);
    commit(ECompanyListMutation.SET_DETAIL_ERROR, null);

    let list = state.value as ICompanyList;
    const companies = state.value?.companies || ([] as Array<ICompanyOfList>);

    let response: any;
    const requestedPage = +queryParams.page || 1;

    try {
      // remove id from the query params to prevent an ID to be sent as a query string
      const params = clone(queryParams);
      delete params["id"];

      const storedPasscode = rootGetters[EListPasscodeGetters.GET](
        queryParams.id,
      );
      if (storedPasscode) {
        params.passcode = storedPasscode;
      }

      response = await companyListProvider.getDetailedCompanies(
        queryParams.id,
        { ...params, page: requestedPage },
      );

      commit(
        ECompanyListMutation.SET_DETAIL_PAGE,
        !response.next ? null : requestedPage + 1,
      );

      // Check if there is an append
      const currentDetailedCompanies =
        requestedPage === 1
          ? [...response.results]
          : [...companies, ...response.results];

      list = {
        ...list,
        companyCount: response.count,
        companies: currentDetailedCompanies,
      };

      commit(ECompanyListMutation.SET_VALUE, list);

      // Set matching interests
      if (
        userManager.isLogged() &&
        featureManager.isEnabled("match") &&
        activeModules().includes("matching")
      ) {
        const { discoverScores, ...remainingData } = response.results.reduce(
          (accumulated: any, company: ICompanyOfList) => {
            const {
              discoverScores: accumDiscover,
              interests: accumInterest,
              userInterestScoreList: accumUser,
              targetInterestScoreList: accumTarget,
              mutualInterestScoreList: accumMutual,
            } = accumulated;

            // Discover score, no interest
            if (!company.interests) {
              return {
                ...accumulated,
                discoverScores: [...accumDiscover, company],
              };
            }

            const hasUserInterest =
              (userManager.isEntrepreneur() &&
                !!company.interests.entrepreneur_is_interested) ||
              (userManager.isSupporter() &&
                !!company.interests.supporter_is_interested);

            const hasTargetInterest =
              (userManager.isEntrepreneur() &&
                !!company.interests.supporter_is_interested) ||
              (userManager.isSupporter() &&
                !!company.interests.entrepreneur_is_interested);

            const hasMutualInterest = hasUserInterest && hasTargetInterest;

            if (hasMutualInterest) {
              return {
                ...accumulated,
                mutualInterestScoreList: [...accumMutual, company],
                interests: [...accumInterest, company.interests],
              };
            }

            if (hasUserInterest) {
              return {
                ...accumulated,
                userInterestScoreList: [...accumUser, company],
                interests: [...accumInterest, company.interests],
              };
            }

            if (hasTargetInterest) {
              return {
                ...accumulated,
                targetInterestScoreList: [...accumTarget, company],
                interests: [...accumInterest, company.interests],
              };
            }

            return {
              ...accumulated,
              interests: [...accumInterest, company.interests],
            };
          },
          {
            discoverScores: [] as Array<IMatchingScore>,
            interests: [] as Array<IMatchingInterest>,
            userInterestScoreList: [] as Array<IMatchingScore>,
            targetInterestScoreList: [] as Array<IMatchingScore>,
            mutualInterestScoreList: [] as Array<IMatchingScore>,
          },
        );

        dispatch(
          EMatchingActions.APPEND,
          { data: discoverScores },
          { root: true },
        );
        dispatch(EMatchingInterestActions.APPEND, remainingData, {
          root: true,
        });
      }
    } catch (error) {
      response = error;
      commit(ECompanyListMutation.SET_DETAIL_PAGE, null);
      commit(ECompanyListMutation.SET_DETAIL_ERROR, error || true);
    } finally {
      commit(ECompanyListMutation.SET_DETAIL_LOADING, false);
    }

    return response;
  },

  /**
   * Create value.
   *
   * @param context
   * @param payload
   */
  async [ECompanyListAction.CREATE_VALUE](
    { commit, state },
    payload = {},
  ): Promise<ICompanyList | null> {
    commit(GenericState.Mutation.SET_LOADING, true);
    commit(GenericState.Mutation.SET_ERROR, null);

    let value = null;

    try {
      value = await companyListProvider.create(payload);
      commit(GenericState.Mutation.SET_VALUES, [...state.values, value]);
    } catch (error) {
      commit(GenericState.Mutation.SET_ERROR, error || true);
    } finally {
      commit(GenericState.Mutation.SET_LOADING, false);
    }

    // Return create value or null
    return value;
  },

  /**
   * Patch value.
   *
   * @param commit
   * @param state
   * @param uid
   * @param payload
   * @param shouldReload
   * @param queryParams
   */
  async [ECompanyListAction.PATCH_VALUE](
    { commit, state },
    {
      uid,
      payload,
      shouldReload = false,
      queryParams = {},
      setLoading = true,
    }: {
      uid: string;
      payload: any;
      shouldReload: boolean;
      queryParams: any;
      setLoading: boolean;
    },
  ): Promise<ICompanyList | null> {
    if (setLoading) {
      commit(GenericState.Mutation.SET_LOADING, true);
    }

    commit(GenericState.Mutation.SET_ERROR, null);

    let value = null;

    // This is a pagination result, type isn't overriding
    const filteredValues = state.values.filter(
      (item: ICompanyList) => item.uid !== uid,
    );

    try {
      value = await companyListProvider.patch(uid, payload, queryParams);
      commit(GenericState.Mutation.SET_VALUES, [...filteredValues, value]);

      if (shouldReload) {
        // On new detail fetch, page should be reset to 1
        const response = await companyListProvider.getDetailedCompanies(
          value.uid,
          {
            page: 1,
          },
        );

        // Append fetched detailed companies
        value = {
          ...value,
          companyCount: response.count,
          companies: response.results,
          allCompaniesCompacted: value.companies,
        };

        commit(ECompanyListMutation.SET_DETAIL_PAGE, 1);
        commit(GenericState.Mutation.SET_VALUE, value);
      }
    } catch (error) {
      commit(GenericState.Mutation.SET_ERROR, error || true);
    } finally {
      if (setLoading) {
        commit(GenericState.Mutation.SET_LOADING, false);
      }
    }

    // Return create value or null
    return value;
  },
};
