import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-126de2e9"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "account-settings-email" }
const _hoisted_2 = {
  key: 0,
  class: "account-settings-email__instructions"
}
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = {
  key: 0,
  class: "account-settings-email__cta"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ElInput = _resolveComponent("ElInput")!
  const _component_PxButton = _resolveComponent("PxButton")!
  const _component_ElFormItem = _resolveComponent("ElFormItem")!
  const _component_ElForm = _resolveComponent("ElForm")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h1", null, _toDisplayString(_ctx.$t("profile.accountSettingsPage.emailForm.title")), 1),
    _createElementVNode("p", null, _toDisplayString(_ctx.$t("profile.accountSettingsPage.emailForm.description")), 1),
    _createVNode(_component_ElForm, {
      ref: "email",
      model: _ctx.fields,
      rules: _ctx.rules,
      class: "account-settings-email__form",
      onValidate: _ctx.updateSubmitState
    }, {
      default: _withCtx(() => [
        _createVNode(_component_ElFormItem, {
          label: _ctx.$t('profile.accountSettingsPage.emailForm.labels[0]'),
          prop: "email"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_ElInput, {
              modelValue: _ctx.fields.email,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.fields.email) = $event)),
              placeholder: _ctx.$t('profile.accountSettingsPage.emailForm.labels[0]'),
              type: "email"
            }, null, 8, ["modelValue", "placeholder"]),
            (_ctx.emailChangeRequested)
              ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                  _createElementVNode("div", {
                    class: "account-settings-email__notice",
                    innerHTML: 
              _ctx.$t('profile.accountSettingsModal.changeEmail.notice', {
                email: _ctx.newEmail,
              })
            
                  }, null, 8, _hoisted_3),
                  (!_ctx.resentEmailConfirmation)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                        _createTextVNode(_toDisplayString(_ctx.$t("profile.accountSettingsModal.changeEmail.help")) + " ", 1),
                        _createVNode(_component_PxButton, {
                          class: "el-button--link-blue",
                          type: "link",
                          onClick: _ctx.resendEmailClickHandler
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t("profile.accountSettingsModal.changeEmail.resendLink")), 1)
                          ]),
                          _: 1
                        }, 8, ["onClick"])
                      ]))
                    : _createCommentVNode("", true)
                ]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["label"]),
        _withDirectives(_createVNode(_component_ElFormItem, {
          label: _ctx.$t('profile.accountSettingsPage.emailForm.labels[1]'),
          prop: "password"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_ElInput, {
              modelValue: _ctx.fields.password,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.fields.password) = $event)),
              placeholder: _ctx.$t('profile.accountSettingsPage.emailForm.labels[1]'),
              autocomplete: "new-password",
              type: "password"
            }, null, 8, ["modelValue", "placeholder"])
          ]),
          _: 1
        }, 8, ["label"]), [
          [_vShow, _ctx.emailHasChanged]
        ])
      ]),
      _: 1
    }, 8, ["model", "rules", "onValidate"]),
    _createElementVNode("footer", null, [
      _createVNode(_component_PxButton, {
        disabled: !_ctx.emailHasChanged,
        class: "el-button--small account-settings-email__cancel-btn",
        type: "link",
        onClick: _ctx.cancelEmailChange
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t("profile.accountSettingsModal.changePassword.resetButton")), 1)
        ]),
        _: 1
      }, 8, ["disabled", "onClick"]),
      _createVNode(_component_PxButton, {
        disabled: _ctx.isSubmissionDisabled || !_ctx.emailHasChanged,
        loading: _ctx.isLoading,
        class: "account-settings-email__submit-btn",
        type: "green",
        onClick: _ctx.updateEmail
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t("profile.accountSettingsPage.emailForm.submitButton")), 1)
        ]),
        _: 1
      }, 8, ["disabled", "loading", "onClick"])
    ])
  ]))
}