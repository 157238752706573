<template>
  <div class="QuestionaryCurrencyRange">
    <div class="QuestionaryCurrencyRange-fieldWrapper">
      <span class="QuestionaryCurrencyRange-label">
        {{ $t("common.from") }}
      </span>
      <PxMoney v-model="values.min" />
    </div>
    <div
      class="QuestionaryCurrencyRange-fieldWrapper QuestionaryCurrencyRange-fieldWrapper--two"
    >
      <span class="QuestionaryCurrencyRange-label">
        {{ $t("common.to") }}
      </span>
      <PxMoney v-model="values.max" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";

const DEFAULT_STATE = { min: "", max: "" };

export default defineComponent({
  props: {
    modelValue: {
      type: [Object, Array, null] as PropType<object | null>,
      default: () => null,
    },

    isValid: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      values: { ...DEFAULT_STATE },
    };
  },

  watch: {
    values: {
      deep: true,
      handler(newVal) {
        const newValues = {
          min: parseFloat(newVal.min.replace(/,/g, "")),
          max: parseFloat(newVal.max.replace(/,/g, "")),
        };

        this.emitValueChanges(newValues);
        this.validateField(newValues);
      },
    },
  },

  methods: {
    /**
     * Emit value changes to the parent component.
     */
    emitValueChanges(values: any) {
      this.$emit("update:modelValue", values);
      this.$emit("change", values);
    },

    validateField(values: any) {
      const isValid =
        values.max !== undefined &&
        values.min !== undefined &&
        values.max > values.min;

      this.$emit("update:isValid", isValid);
    },
  },
});
</script>

<style lang="scss" scoped>
.QuestionaryCurrencyRange-label {
  @include grotesk(semiBold);

  display: block;
  margin-bottom: 1px;

  font-size: to-rem(15px);
}

.QuestionaryCurrencyRange-fieldWrapper--two {
  margin-top: 10px;
}
</style>
