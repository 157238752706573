import {
  EProviderFeatures,
  GenericProvider,
} from "@/services/data/generic-provider";
import { IProgramQuestionBundles } from "@/modules/affiliate-program/services/data/program-question-bundles/program-question-bundles.interface";

class ProgramQuestionBundlesProvider extends GenericProvider<IProgramQuestionBundles> {
  constructor() {
    super(`${process.env.VUE_APP_API_URL}/program/question-bundles`, [
      EProviderFeatures.CREATE,
    ]);
  }
}

export const programQuestionBundlesProvider =
  new ProgramQuestionBundlesProvider();
