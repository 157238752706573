<template>
  <div class="stats-card">
    <div class="stats-card__content">
      <div class="stats-card__header">
        <h3 v-if="title">
          {{ title }}
        </h3>
        <h2>{{ statValue }} of {{ statTotal }}</h2>
      </div>
      <div
        v-if="icon"
        :style="{
          backgroundColor: iconBackgroundColor,
        }"
        class="stats-card__icon"
      >
        <PxIcon :name="icon" :size="iconSize" />
      </div>
    </div>
    <p v-if="description">
      {{ description }}
    </p>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "StatsCard",

  props: {
    title: {
      type: String as () => string | null,
      default: null,
    },

    statValue: {
      type: Number,
      default: 0,
    },

    statTotal: {
      type: Number,
      default: 0,
    },

    description: {
      type: String as () => string | null,
      default: null,
    },

    icon: {
      type: String as () => string | null,
      default: null,
    },

    iconSize: {
      type: Number,
      default: 40,
    },

    iconBackgroundColor: {
      type: String,
      default: "rgba(168, 111, 245, 0.8)",
    },
  },
});
</script>

<style lang="scss" scoped>
.stats-card {
  display: flex;
  flex-direction: column;

  border-radius: 4px;
  border: 1px solid $pale-grey;
  background-color: $white;
  box-shadow: 0 2px 11px 0 rgba(0, 0, 0, 0.05);

  padding: 22px 28px 16px 23px;

  &__header {
    h3 {
      color: $ebony-clay;
      font-size: to-rem(20px);
      font-weight: 600;
      line-height: to-rem(30px);
      margin-bottom: 15px;
      letter-spacing: -0.2px;
    }

    h2 {
      margin-bottom: 10px;

      color: $ebony-clay;
      font-size: to-rem(24px);
      line-height: to-rem(28px);
      font-weight: 700;
    }
  }

  &__icon {
    border-radius: 10px;
    width: 40px;
    height: 40px;

    display: flex;
    align-items: center;
    justify-content: center;

    :deep() .px-icon {
      filter: brightness(0) invert(1);
    }
  }

  &__content {
    display: flex;
    gap: 10px;
    justify-content: space-between;
  }

  p {
    margin: 0;
    color: $manatee;
    font-size: to-rem(15px);
    line-height: 1.47;
    letter-spacing: -0.29px;
    text-transform: lowercase;
  }
}
</style>
