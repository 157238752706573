<template>
  <div class="question-bundle-entrepreneur-starting-page">
    <div
      :class="{
        'question-bundle-entrepreneur-starting-page-panel--simplified':
          !withTeamSection,
      }"
      class="question-bundle-entrepreneur-starting-page-panel"
    >
      <div class="question-bundle-entrepreneur-starting-page-panel--left">
        <div class="question-bundle-entrepreneur-starting-page-panel__wrapper">
          <h1 class="question-bundle-entrepreneur-starting-page-panel__title">
            {{ affiliateName }}
          </h1>
          <div
            v-if="affiliate.summary"
            class="question-bundle-entrepreneur-starting-page-panel__content"
            v-html="affiliate.summary"
          />
          <template v-else>
            <p
              class="question-bundle-entrepreneur-starting-page-panel__content"
            >
              {{ contentAffiliate }}
            </p>
            <p
              class="question-bundle-entrepreneur-starting-page-panel__content"
            >
              {{ contentBonus }}
            </p>
          </template>
          <PxButton
            :icon="startButtonIcon"
            :type="startButtonType"
            class="question-bundle-entrepreneur-starting-page-panel__button"
            data-testid="question-bundle-entrepreneur-starting-page-panel__button"
            size="large"
            @click="onSelfAssessmentButtonClick"
          >
            {{ startButtonText }}
          </PxButton>
        </div>
        <div class="question-bundle-entrepreneur-starting-page__feature">
          <FeatureCard
            :key="affiliateFlowKeyPoint.value"
            :description="affiliateFlowKeyPoint.description"
            :icon="affiliateFlowKeyPoint.value"
            :title="affiliateFlowKeyPoint.title"
          />
        </div>
      </div>
      <div
        class="question-bundle-entrepreneur-starting-page-panel--right affiliate-description-panel"
      >
        <template v-for="(item, index) in descriptionToDisplay" :key="index">
          <div class="affiliate-description-panel__title">
            <span>
              {{ index + 1 }}
            </span>
            {{ item.title }}
          </div>
          <div
            class="affiliate-description-panel__description"
            v-html="item.description"
          />
          <div
            v-if="item.note"
            :key="`note-${index}`"
            class="affiliate-description-panel__note"
            v-html="item.note"
          />
          <picture v-if="item.thumbnail" :key="`thumbnail-${index}`">
            <img :alt="item.thumbnail.alt" :src="item.thumbnail.src" />
          </picture>
        </template>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import FeatureCard from "@/modules/affiliates/components/feature-card/feature-card.vue";
import startingPagesMixin from "@/modules/affiliates/mixins/starting-pages.mixin";

interface IFeaturesDescription {
  title: string;
  description: string;
  note?: string;
  thumbnail: { alt: string; src: string };
}

export default defineComponent({
  name: "QuestionBundleEntrepreneurStartingPage",

  components: {
    FeatureCard,
  },

  mixins: [startingPagesMixin],

  computed: {
    affiliateFlowKeyPoint(): any {
      return {
        value: this.$t("affiliateProgram.startingPoint.keyPoint.value"),
        title:
          this.affiliate?.disclaimer_heading ||
          this.$t("affiliateProgram.startingPoint.keyPoint.title"),
        description:
          this.affiliate?.disclaimer_body ||
          this.$t("affiliateProgram.startingPoint.keyPoint.description"),
      };
    },

    affiliateName(): string {
      return this.affiliate ? this.affiliate.name : "";
    },

    withTeamSection(): boolean {
      return (
        (this.affiliate?.show_team_section &&
          this.$features.isEnabled("teamMembers")) ||
        false
      );
    },

    selfAssessmentStepCopy(): IFeaturesDescription {
      return {
        title: this.$t(
          `affiliateProgram.results.affiliateFlowDescription[0].title`,
        ),
        description: this.affiliate?.self_assessment_step_description
          ? this.affiliate.self_assessment_step_description
          : this.$t(
              `affiliateProgram.results.affiliateFlowDescription[0].description`,
            ),
        note: this.affiliate?.self_assessment_step_note
          ? this.affiliate.self_assessment_step_note
          : this.$te(
                `affiliateProgram.results.affiliateFlowDescription[0].note`,
              )
            ? this.$t(
                `affiliateProgram.results.affiliateFlowDescription[0].note`,
              )
            : "",
        thumbnail: this.$tm(
          `affiliateProgram.results.affiliateFlowDescription[0].thumbnail`,
        ) as IFeaturesDescription["thumbnail"],
      };
    },

    questionsStepCopy(): IFeaturesDescription {
      return {
        title: this.$t(
          `affiliateProgram.results.affiliateFlowDescription[1].title`,
        ),
        description: this.affiliate?.questions_step_description
          ? this.affiliate.questions_step_description
          : this.$t(
              `affiliateProgram.results.affiliateFlowDescription[1].description`,
            ),
        note: this.affiliate?.questions_step_note
          ? this.affiliate.questions_step_note
          : this.$te(
                `affiliateProgram.results.affiliateFlowDescription[1].note`,
              )
            ? this.$t(
                `affiliateProgram.results.affiliateFlowDescription[1].note`,
              )
            : "",
        thumbnail: this.$tm(
          `affiliateProgram.results.affiliateFlowDescription[1].thumbnail`,
        ) as IFeaturesDescription["thumbnail"],
      };
    },

    teamMembersStepCopy(): IFeaturesDescription {
      return {
        title: this.$t(
          `affiliateProgram.results.affiliateFlowDescription[2].title`,
        ),
        description: this.affiliate?.team_members_step_description
          ? this.affiliate.team_members_step_description
          : this.$t(
              `affiliateProgram.results.affiliateFlowDescription[2].description`,
            ),
        note: this.affiliate?.team_members_step_note
          ? this.affiliate.team_members_step_note
          : this.$te(
                `affiliateProgram.results.affiliateFlowDescription[2].note`,
              )
            ? this.$t(
                `affiliateProgram.results.affiliateFlowDescription[2].note`,
              )
            : "",
        thumbnail: this.$tm(
          `affiliateProgram.results.affiliateFlowDescription[2].thumbnail`,
        ) as IFeaturesDescription["thumbnail"],
      };
    },

    descriptionToDisplay(): Array<IFeaturesDescription> {
      return this.withTeamSection
        ? [
            this.selfAssessmentStepCopy,
            this.questionsStepCopy,
            this.teamMembersStepCopy,
          ]
        : [this.selfAssessmentStepCopy, this.questionsStepCopy];
    },

    startButtonText(): string {
      return this.hasError
        ? this.$t("affiliateProgram.startingPoint.panel.button.error")
        : this.submissionInProgress
          ? this.$t("affiliateProgram.startingPoint.panel.button.resume")
          : this.$t("affiliateProgram.startingPoint.panel.button.normal");
    },

    contentAffiliate(): string {
      return this.withTeamSection
        ? this.$t(
            "affiliateProgram.startingPoint.panel.contentAffiliateTeamMembers",
            {
              affiliateName: this.affiliateName,
            },
          )
        : this.$t("affiliateProgram.startingPoint.panel.contentAffiliate", {
            affiliateName: this.affiliateName,
          });
    },

    contentBonus(): string {
      return this.$t("affiliateProgram.startingPoint.panel.contentBonus");
    },
  },

  methods: {
    onSelfAssessmentButtonClick() {
      this.$emit("next-step");
    },
  },
});
</script>

<style lang="scss" scoped>
.question-bundle-entrepreneur-starting-page {
  @include container;

  position: relative;

  @include breakpoint-down(md) {
    margin: 0 auto;
  }
}

.question-bundle-entrepreneur-starting-page__feature {
  @include container;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 14px 42px 64px 37px;

  background-image: linear-gradient(
    to bottom,
    rgba(250, 250, 250, 0),
    rgba(123, 183, 255, 0.1)
  );

  @include breakpoint-down(md) {
    padding: 14px 42px 21px 37px;
  }

  @include breakpoint-down(sm) {
    padding: 14px 30px 21px;
  }

  & :deep() .feature-card {
    &__icon {
      position: relative;
      bottom: 1px;
      left: 1px;
      align-self: normal;
      transform: scale(0.9);

      @include breakpoint-down(sm) {
        display: none;
      }
    }

    &__content {
      text-align: left;
      padding-left: 19px;

      @include breakpoint-down(sm) {
        padding-left: 0;
      }
    }

    &__title {
      @include grotesk(regular);

      font-size: 17px;
      line-height: 1.88;
      letter-spacing: -0.3px;
      max-width: 100%;
    }

    &__description {
      max-width: 368px;
      margin-bottom: 3px;
      line-height: 1.67;
      letter-spacing: -0.3px;
    }
  }
}

.affiliate-description-panel {
  &__title {
    @include gradient(primary);
    @include grotesk(semiBold);

    display: flex;
    align-items: center;

    width: 265px;
    height: 32px;
    padding-left: 64px;
    font-size: 16px;
    color: $white;
    border-radius: 0 26px 26px 0;
    box-shadow: 0 3px 3px 0 rgba(123, 183, 255, 0.3);

    & > span {
      @include grotesk(bold);

      display: inline-block;
      width: 20px;
      height: 20px;
      margin-right: 10px;
      font-size: 15px;
      line-height: 21px;
      color: #74acf0;
      text-align: center;
      background: $white;
      border-radius: 50%;
    }
  }

  &__title:first-of-type {
    position: absolute;
    top: -18px;
    left: 0;
    width: 252px;
  }

  &__description {
    padding: 26px 33px 12px;
    letter-spacing: -0.3px;
    margin: 0;
    font-size: 14px;
    line-height: 1.7;

    @include breakpoint-down(md) {
      text-align: center;
    }
  }

  &__description :deep() p,
  &__description :deep() span {
    letter-spacing: -0.3px;
    margin: 0;
    font-size: 14px;
    line-height: 1.7;
  }

  &__description :deep() ul,
  &__description :deep() ol {
    padding: 0;
    margin: 0 0 0 9px;
  }

  &__description :deep() li {
    position: relative;

    padding: 0 10px 0 2px;
    letter-spacing: -0.3px;
    margin: 0;
    font-size: 14px;
    line-height: 1.7;
  }

  &__description :deep() ul li::marker {
    font-size: 10px;
    color: $ebony-clay;
  }

  &__description:first-of-type {
    margin-top: 41px;
    padding: 0 33px 18px;
  }

  & picture {
    display: block;
    margin-bottom: 32px;
    margin-left: 2px;
  }

  & picture > img {
    display: block;
    margin: 0 auto;
  }

  & picture:first-of-type {
    margin-bottom: 25px;
  }

  & picture:nth-of-type(3) {
    margin-right: 24px;
  }

  & picture:nth-of-type(1) > img {
    max-width: 284px;
    max-height: 77px;
  }

  & picture:nth-of-type(2) > img {
    max-width: 189px;
    max-height: 54px;
  }

  &__note {
    font-size: 1rem;
    font-style: italic;
    line-height: 25px;
    color: $manatee;
    letter-spacing: -0.16px;
    padding: 0 40px 40px;

    @include breakpoint-down(md) {
      text-align: center;
    }
  }
}

.question-bundle-entrepreneur-starting-page-panel {
  display: grid;
  grid-template-columns: 57.4% auto;
  align-items: center;
  margin: 0 auto;
  width: 100%;
  max-width: 947px;

  @include breakpoint-down(md) {
    grid-template-columns: auto;
    gap: 40px;
    max-width: 700px;
    padding-top: 30px;
  }

  &__wrapper {
    padding: 51px 44px 21px;

    @include breakpoint-down(md) {
      padding: 21px 30px 21px;
    }
  }

  &--left {
    background-image: linear-gradient(
      to bottom,
      rgba(250, 250, 250, 0),
      rgba(123, 183, 255, 0.1)
    );
    background-color: $white;
    border: solid 1px $pale-grey;
    border-radius: 2px;
    box-shadow: 0 2px 11px 0 $black-5;
    z-index: 1;
  }

  &--right {
    min-height: 88.5%;
    max-height: 100%;
    position: relative;
    top: 9px;
    background-image: linear-gradient(
      to bottom,
      rgba($malibu, 0.06),
      rgba($malibu, 0.1)
    );
    border: solid 1px rgba($malibu, 0.15);
    border-left: none;
    border-radius: 0 6px 6px 0;

    @include breakpoint-down(md) {
      border-left: solid 1px rgba($malibu, 0.15);
      height: 100%;
    }
  }

  &__title,
  &__content {
    text-align: left;
  }

  &__title {
    @include grotesk(semiBold);

    margin-bottom: 17px;

    font-size: 2rem;
    line-height: 1.33;
    letter-spacing: -0.5px;

    @include breakpoint-down(md) {
      font-size: 1.8rem;
    }
  }

  &__content {
    max-width: 603px;
    margin: 0 auto 16px;

    font-size: 1.1333rem;
    line-height: 1.88;
    letter-spacing: -0.37px;

    @include breakpoint-down(md) {
      margin: 0;
    }

    :deep() p,
    :deep() span {
      font-size: inherit;
      line-height: inherit;
      letter-spacing: inherit;
      margin: 0;
    }
  }

  &__button {
    margin-top: 25px;
    min-width: 100%;

    @include breakpoint-down(sm) {
      & :deep() span {
        font-size: 13px;
      }
    }
  }
}

.question-bundle-entrepreneur-starting-page-panel--simplified {
  .question-bundle-entrepreneur-starting-page-panel--right {
    top: 12px;

    @include breakpoint-down(md) {
      height: 100%;
    }
  }

  .question-bundle-entrepreneur-starting-page-panel__wrapper {
    padding: 27px 44px 14px;
  }

  .question-bundle-entrepreneur-starting-page-panel__button {
    margin-top: 11px;
  }

  .question-bundle-entrepreneur-starting-page__feature {
    padding: 14px 42px 30px 37px;
  }

  .affiliate-description-panel__title {
    height: 38px;
    padding-left: 62px;
  }

  .affiliate-description-panel__title:first-of-type {
    top: -25px;

    @include breakpoint-down(md) {
      left: 0;
    }
  }

  .affiliate-description-panel__description {
    padding: 22px 40px 17px;
    font-size: 15px;
    line-height: 1.87;
  }

  .affiliate-description-panel__description:first-of-type {
    margin-top: 35px;
    padding: 0 40px 19px;
  }

  .affiliate-description-panel picture {
    margin-left: 36px;
  }

  .affiliate-description-panel picture:first-of-type {
    margin-bottom: 32px;
    margin-left: 39px;
  }

  .affiliate-description-panel picture:nth-of-type(1) > img,
  .affiliate-description-panel picture:nth-of-type(2) > img {
    max-width: 100%;
    max-height: 100%;
  }
}
</style>
