import { ActionTree } from "vuex";

import { RootState } from "@/services/store/root-state";
import { getGenericStateActions } from "@/services/store/generic/generic-state.actions";

import { questionBundleProvider } from "@/services/data/question-bundle/question-bundle.provider";
import {
  EQuestionBundlesAction,
  IQuestionBundlesState,
} from "@/modules/milestone-planner/services/store/milestone-planner/sub-modules/question-bundles/question-bundles.interface";
import { IQuestionBundle } from "@/services/data/question-bundle/question-bundle.interface";
import { IPaginationResult } from "@/services/data/generic-provider";
import { GenericState } from "@/services/store/generic/generic-state.module";
import { EQuestionBundlesMutation } from "@/modules/milestone-planner/services/store/milestone-planner/sub-modules/question-bundles/question-bundles.mutations";

export const actions: ActionTree<IQuestionBundlesState, RootState> = {
  ...getGenericStateActions<IQuestionBundle>(questionBundleProvider),

  /**
   * Get paginated question bundles.
   * It will always append new response values to avoid re-fetching unnecessarily.
   *
   * @returns the servers response. This is useful since we can use it to stop infinity loading.
   */
  async [EQuestionBundlesAction.GET_VALUES](
    { commit },
    queryParams = {},
  ): Promise<IPaginationResult<IQuestionBundle>> {
    commit(GenericState.Mutation.SET_LOADING, true);
    commit(GenericState.Mutation.SET_ERROR, null);

    const requestedPage = +queryParams.page || 1;
    queryParams = { ...queryParams, page: requestedPage };

    let response;

    try {
      response = await questionBundleProvider.paginate(queryParams);
      commit(
        EQuestionBundlesMutation.SET_PAGE,
        !response.next ? null : requestedPage + 1,
      );

      commit(EQuestionBundlesMutation.APPEND_VALUES, response.results);
    } catch (error) {
      response = error;
      commit(EQuestionBundlesMutation.SET_PAGE, null);
      commit(GenericState.Mutation.SET_ERROR, error || true);
    } finally {
      commit(GenericState.Mutation.SET_LOADING, false);
    }

    return response as any;
  },
};
