import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-061211c7"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "submission-tab-invest-range" }
const _hoisted_2 = { class: "submission-tab__print-title" }
const _hoisted_3 = { class: "submission-tab-invest-range__container" }
const _hoisted_4 = { class: "submission-tab__score-wrapper" }
const _hoisted_5 = { class: "submission-tab__content-wrapper" }
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = { class: "submission-tab__subtitle" }
const _hoisted_8 = { class: "submission-tab__levels" }
const _hoisted_9 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PxIcon = _resolveComponent("PxIcon")!
  const _component_ElTooltip = _resolveComponent("ElTooltip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h2", _hoisted_2, _toDisplayString(_ctx.$t("profile.assessment.levelPanel.title")), 1),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_PxIcon, {
          name: `level-range-graph/level-${_ctx.investingLevelRangeInterval}`,
          size: 140,
          class: "submission-tab__level"
        }, null, 8, ["name"])
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("h2", {
          class: "submission-tab__title",
          innerHTML: _ctx.investingLevelRangeTitle
        }, null, 8, _hoisted_6),
        _createElementVNode("p", _hoisted_7, [
          (_ctx.investingLevelMaximum)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                _createTextVNode(_toDisplayString(_ctx.$t("supporters.results.submissionPanel.tabs.levelsTip", {
                min: _ctx.investingLevelMinimum,
                max: _ctx.investingLevelMaximum,
              })), 1)
              ], 64))
            : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                _createTextVNode(_toDisplayString(_ctx.$t("supporters.results.submissionPanel.tabs.levelTip", {
                level: _ctx.investingLevelMinimum,
              })), 1)
              ], 64))
        ]),
        _createElementVNode("ol", _hoisted_8, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.investingLevelsSelection, (level, key) => {
            return (_openBlock(), _createElementBlock("li", {
              key: key,
              class: "submission-tab__levels-item"
            }, [
              _createElementVNode("b", null, _toDisplayString(_ctx.$t("supporters.results.levelPanel.label")) + " " + _toDisplayString(level.value), 1),
              _createTextVNode(" - " + _toDisplayString(level.title) + " ", 1),
              _createVNode(_component_ElTooltip, {
                enterable: false,
                placement: "top",
                "popper-class": "submission-tab__levels-tooltip el-abaca-tooltip"
              }, {
                content: _withCtx(() => [
                  _createElementVNode("div", {
                    innerHTML: level.description
                  }, null, 8, _hoisted_9)
                ]),
                default: _withCtx(() => [
                  _createVNode(_component_PxIcon, {
                    size: 17,
                    class: "submission-tab__levels-tooltip-icon",
                    name: "question"
                  })
                ]),
                _: 2
              }, 1024)
            ]))
          }), 128))
        ])
      ])
    ])
  ]))
}