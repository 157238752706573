<template>
  <div class="error-page">
    <img :src="`/img/error${errorCode}.svg`" alt="Error Image" />
    <h1 class="error-page__title">
      {{ $t(`common.errors.${errorCode}.title`) }}
    </h1>
    <p class="error-page__notice">
      <template v-if="showDefaultErrorMessage">
        {{ $t(`common.errors.${errorCode}.notice`) }}
      </template>
      <template v-else>
        {{ $t(`common.errors.${errorCode}.noticeSupporter`) }}
      </template>
      <span v-if="showDefaultErrorMessage" class="error-page__actions-wrapper">
        {{ actionText }}
        <PxButton
          ref="returnToHomepageButton"
          :label="actionLink"
          class="error-page__action"
          data-testid="error-page__return-homepage"
          type="link"
          @click="returnHomepage"
        />
        <i18n-t
          v-if="showSupportModal"
          data-testid="error-page__contact-support"
          keypath="common.errors.actions.contactSupport.text"
          tag="span"
        >
          <template #action>
            <PxButton
              ref="contactSupportButton"
              :label="$t(`common.errors.actions.contactSupport.action`)"
              class="error-page__action"
              data-testid="error-page__show-modal"
              type="link"
              @click="showModal"
            />
          </template>
        </i18n-t>
        <span class="error-page__end-point">.</span>
      </span>
    </p>
    <SupportModal
      v-model:visibility="supportModalVisibility"
      data-testid="support-modal"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { ROUTE_AUTHENTICATION_LOGIN } from "@/modules/authentication/services/router/routes-names";
import { HOME } from "@/services/router/router-names";
import SupportModal from "@/modules/common/components/support-modal/support-modal.vue";
import { TranslateResult } from "vue-i18n";
import { ERROR_TYPES } from "@/modules/common/components/error-page/constants";
import { AFFILIATE_FORBIDDEN } from "@/modules/affiliates/constants";
import { EMetaActions } from "@/services/store/meta/meta-types";

export default defineComponent({
  name: "ErrorPage",

  components: { SupportModal },

  props: {
    errorCode: {
      type: String,
      default: ERROR_TYPES.NOT_FOUND,
    },

    showSupportModal: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      supportModalVisibility: false,
    };
  },

  computed: {
    actionText(): TranslateResult {
      return this.errorCode === "mobile"
        ? this.$t(`common.errors.actions.returnProfile.text`)
        : this.$t(`common.errors.actions.returnHomepage.text`);
    },

    actionLink(): TranslateResult {
      return this.errorCode === "mobile"
        ? this.$t(`common.errors.actions.returnProfile.action`)
        : this.$t(`common.errors.actions.returnHomepage.action`);
    },

    showDefaultErrorMessage(): boolean {
      return !(
        this.errorCode === ERROR_TYPES.FORBIDDEN &&
        this.supporterForbiddenVariation
      );
    },

    supporterForbiddenVariation(): boolean {
      return this.$store.get("meta/get", AFFILIATE_FORBIDDEN);
    },
  },

  beforeUnmount() {
    if (!this.supporterForbiddenVariation) return;

    this.$store.dispatch(EMetaActions.SET, {
      key: AFFILIATE_FORBIDDEN,
      value: false,
    });
  },

  methods: {
    returnHomepage() {
      this.$user.isLogged()
        ? this.$router.push({ name: HOME })
        : this.$router.push({ name: ROUTE_AUTHENTICATION_LOGIN });
    },

    showModal() {
      this.supportModalVisibility = true;
    },
  },
});
</script>

<style lang="scss" scoped>
.error-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding-top: 145px;

  @include breakpoint-down(md) {
    padding-top: 88px;
  }

  img {
    @include breakpoint-down(md) {
      width: 114px;
    }
  }

  &__title {
    @include grotesk(semiBold);

    margin-top: 48px;
    margin-bottom: 14px;

    font-size: 30px;
    line-height: normal;
    color: $ebony-clay;

    @include breakpoint-down(md) {
      margin-top: 18px;
      margin-bottom: 18px;
      font-size: 22px;
      letter-spacing: 0.4px;
    }
  }

  &__notice {
    max-width: 490px;
    text-align: center;
    letter-spacing: -0.3px;

    @include breakpoint-down(md) {
      padding: 0 60px;
      line-height: 26px;
      letter-spacing: 0;
    }
  }

  &__notice,
  &__actions-wrapper {
    @include grotesk(regular);

    font-size: 15px;
    line-height: 25px;
    color: $scarpa-flow;
  }

  &__action {
    @include grotesk(semiBold);

    position: relative;
    top: 1px;
    height: auto;
    padding: 0;
    color: $bluish;
    text-decoration: underline;
    cursor: pointer;
  }

  &__end-point {
    position: relative;
    right: 4px;
  }
}
</style>
