import { RouteLocationNormalized, RouteRecordRaw } from "vue-router";

import { removeTabsItems } from "@/services/router/mobile-tab-menu";

import { ROUTE_GETTING_STARTED_DASHBOARD } from "@/modules/getting-started-dashboard/services/router/routes-names";

import AuthenticatedWithNavigationView from "@/modules/common/views/authenticated-with-navigation.view.vue";
import Dashboard from "@/modules/getting-started-dashboard/views/dashboard/dashboard.vue";
import { userManager } from "@/modules/authentication/user-manager";
import { ERROR_ROUTE } from "@/services/router/router-names";
import { ERROR_TYPES } from "@/modules/common/components/error-page/constants";
import { featureManager } from "@/services/feature-manager";

export const routes: Array<RouteRecordRaw> = [
  {
    path: "/getting-started",
    component: AuthenticatedWithNavigationView,
    beforeEnter: async (
      to: RouteLocationNormalized,
      from: RouteLocationNormalized,
      next: Function,
    ) => {
      if (
        userManager.isEntrepreneur() &&
        !featureManager.isEnabled("gettingStartedDashboard")
      ) {
        next({
          name: ERROR_ROUTE,
          query: {
            code: ERROR_TYPES.FORBIDDEN,
          },
        });
      }

      removeTabsItems(to, from, next);
      next();
    },
    meta: {
      auth: true,
    },
    children: [
      {
        path: "",
        name: ROUTE_GETTING_STARTED_DASHBOARD,
        component: Dashboard,
      },
    ],
  },
];
