import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createSlots as _createSlots, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-1f050250"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["onClick"]
const _hoisted_2 = { class: "affiliate-navigation-bar__list-item-index" }
const _hoisted_3 = { class: "affiliate-navigation-bar__list-item-title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PxButton = _resolveComponent("PxButton")!
  const _component_PxNavbar = _resolveComponent("PxNavbar")!

  return (_openBlock(), _createBlock(_component_PxNavbar, {
    "external-link": _ctx.externalLink,
    loading: _ctx.loading,
    logo: _ctx.logo,
    "show-login-button": _ctx.showLoginButton,
    class: "affiliate-navigation-bar"
  }, _createSlots({
    "login-button": _withCtx(() => [
      (_ctx.showLoginButton)
        ? (_openBlock(), _createBlock(_component_PxButton, {
            key: 0,
            class: "el-button--darker",
            type: "primary",
            onClick: _ctx.onClickLogin
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("common.navBar.login")), 1)
            ]),
            _: 1
          }, 8, ["onClick"]))
        : _createCommentVNode("", true)
    ]),
    _: 2
  }, [
    (_ctx.showSteps && _ctx.steps.length)
      ? {
          name: "navbar-right",
          fn: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.steps, (step, index) => {
              return (_openBlock(), _createElementBlock("div", {
                key: `affiliate-navigation-bar:${index}`,
                class: _normalizeClass([{
          'affiliate-navigation-bar__list-item--active': step.id === _ctx.activeStep,
          'affiliate-navigation-bar__list-item--disabled': _ctx.disabled,
        }, "affiliate-navigation-bar__list-item"]),
                onClick: ($event: any) => (_ctx.onStepClick(step.id))
              }, [
                _createElementVNode("p", _hoisted_2, _toDisplayString(index + 1), 1),
                _createElementVNode("p", _hoisted_3, [
                  _createElementVNode("span", null, _toDisplayString(_ctx.$t(step.title)), 1)
                ])
              ], 10, _hoisted_1))
            }), 128))
          ]),
          key: "0"
        }
      : undefined
  ]), 1032, ["external-link", "loading", "logo", "show-login-button"]))
}