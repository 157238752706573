import { IAffiliateDraft } from "@/services/data/affiliate/affiliate.interface";
import {
  GenericProvider,
  Provider,
  EProviderFeatures,
} from "@/services/data/generic-provider";
import { baseAPIUrl } from "@/services/utils/utils";

@Provider(`${baseAPIUrl}/affiliate-submission-drafts`, [
  EProviderFeatures.CREATE,
])
class AffiliateSubmissionsDraftsProvider extends GenericProvider<IAffiliateDraft> {
  public async getAffiliateSubmissionDraft(
    affiliateId: string,
  ): Promise<IAffiliateDraft> {
    const url = super.buildEndPointUrl({}, `${affiliateId}`);
    const request = this.httpClient.get(url);
    const { data } = await this.wrapRequest(request);

    return data;
  }
}

export const affiliateSubmissionsDraftsProvider =
  new AffiliateSubmissionsDraftsProvider();
