import {
  GenericProvider,
  EProviderFeatures,
} from "@/services/data/generic-provider";
import { baseAPIUrl } from "@/services/utils/utils";
import { IMatchingScore } from "@/modules/matching/services/data/matching-score/matching-score.interface";

class MatchingInterestResultsProvider extends GenericProvider<IMatchingScore> {
  constructor(listing_option: string) {
    super(`${baseAPIUrl}/matching/interested/${listing_option}`, [
      EProviderFeatures.LIST,
    ]);
  }
}

export const matchingInterestResultsProvider: {
  [key: string]: any;
} = ["user", "targets", "mutual"].reduce(
  (prev, cur) => ({
    ...prev,
    [cur]: new MatchingInterestResultsProvider(cur),
  }),
  {},
);
