<template>
  <div :style="cellStyles" class="milestone-cell" data-testid="milestone-cell">
    <template v-if="completed">
      <PxIcon
        v-if="evidenceProvided"
        data-testid="evidence-provided"
        name="evidence-provided"
        size="18"
      />
      <PxIcon
        v-else
        data-testid="self-assessed"
        name="self-assessed"
        size="16"
      />
    </template>
    <PxIcon
      v-else-if="critical"
      class="milestone-cell__critical"
      data-testid="critical"
      name="critical-milestone"
      size="22"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "MilestoneCell",

  props: {
    color: { type: String, required: true },
    completed: { type: Boolean, default: false },
    critical: { type: Boolean, default: false },
    evidenceProvided: { type: Boolean, default: false },
    future: { type: Boolean, default: false },
    inProgress: { type: Boolean, default: false },
    planned: { type: Boolean, default: false },
  },

  computed: {
    cellStyles(): { [key: string]: string | undefined } {
      const backgroundColor = this.color;
      const backgroundImage = this.backgroundImage;

      return {
        ...(this.hasBorder && this.border),
        ...(this.completed && { backgroundColor }),
        ...(this.inProgress && { backgroundImage }),
      };
    },

    hasBorder(): boolean {
      return (
        !this.completed &&
        (this.planned || this.inProgress || (!this.planned && !this.future))
      );
    },

    border(): {
      borderColor?: string;
      borderStyle: string;
    } {
      const isBorderDashed = !this.planned && !this.future && !this.inProgress;
      const borderStyle = isBorderDashed ? "dashed" : "solid";

      const isBorderColored = !this.future;
      const borderColor = this.color;

      return {
        borderStyle,
        ...(isBorderColored && { borderColor }),
      };
    },

    colorAsRGB(): string {
      // Exclude the "#" on position 0
      const r = parseInt(this.color.slice(1, 3), 16);
      const g = parseInt(this.color.slice(3, 5), 16);
      const b = parseInt(this.color.slice(5, 7), 16);

      return `${r}, ${g}, ${b}`;
    },

    backgroundImage(): string {
      const gradientColor = this.future
        ? "#e7e8eb"
        : `rgba(${this.colorAsRGB}, 0.3)`;

      return `linear-gradient(110deg, ${gradientColor} 50%, #f4f4f5 50%)`;
    },
  },
});
</script>

<style lang="scss" scoped>
.milestone-cell {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 68px;
  height: 22px;
  cursor: pointer;
  background-color: $pale-grey-two;
  border-color: rgba($manatee, 0.4);
  border-width: 1px;
  border-radius: 4px;
  outline: none;
  transition: $--all-transition;

  &__critical {
    position: absolute;
    top: -4px;
    right: 0;
  }

  &:hover {
    box-shadow: 0 2px 2px 0 rgba($ebony-clay, 0.16);
  }
}
</style>
