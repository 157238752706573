<template>
  <div class="overview-sidebar">
    <OverallLevel v-if="latestAssessmentLevel" :level="latestAssessmentLevel" />
    <MilestonesSection
      v-if="nextMilestones.length"
      :is-loading="isLoading"
      :milestones="nextMilestones"
      :title="$t('profile.investors.nextMilestones')"
      icon="next-milestone"
    />
    <MilestonesSection
      v-if="achievedMilestones.length"
      :is-loading="isLoading"
      :milestones="achievedMilestones"
      :title="$t('milestonePlanner.sidebar.milestones.completed')"
      icon="completed-milestone"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import OverallLevel from "@/modules/milestone-planner/components/overall-level/overall-level.vue";
import MilestonesSection from "@/modules/milestone-planner/components/milestones-section/milestones-section.vue";
import { ILevel } from "@/services/data/level/level.interface";
import MilestonesMixin from "@/mixins/milestones.mixin";

export default defineComponent({
  name: "OverviewSidebar",

  components: {
    OverallLevel,
    MilestonesSection,
  },

  mixins: [MilestonesMixin],

  props: {
    latestAssessmentLevel: {
      type: Object as () => ILevel,
      required: true,
    },
  },
});
</script>

<style lang="scss" scoped>
.overview-sidebar {
  overflow-y: auto;
  border-left: 1px solid $athens-gray;
}
</style>
