<template>
  <div :class="{ 'is-visible': visible }" class="px-modal-message">
    <ElAlert
      :center="center"
      :show-icon="showIcon"
      :title="title || $t('common.errors.global.alertTitle')"
      :type="type"
    >
      <template #title>
        <slot />
      </template>
    </ElAlert>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "PxModalMessage",

  props: {
    /**
     * Text to be shown.
     */
    title: {
      type: String,
      default: "",
    },

    /**
     * Set the message visibility.
     */
    visible: {
      type: Boolean,
      default: true,
    },

    /**
     * Accepted types by the modal message.
     */
    type: {
      type: String,
      default: "error",
      validator(val: string) {
        return ["error", "success", "info"].includes(val);
      },
    },

    /**
     * Alignment of text and icon.
     */
    center: {
      type: Boolean,
      default: true,
    },

    showIcon: {
      type: Boolean,
      default: true,
    },

    /**
     * Timeout for the alert be automatically hide.
     *
     * This value is defined in milliseconds.
     */
    timeout: {
      type: Number as () => number | null,
      default: null,
    },
  },

  watch: {
    visible: {
      immediate: true,
      handler() {
        if (!this.timeout) {
          return;
        }

        setTimeout(() => this.$emit("update:visible", false), this.timeout);
      },
    },
  },
});
</script>

<style lang="scss" scoped>
.px-modal-message {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: z("default") + 2;

  overflow: hidden;
  pointer-events: none;
}

.px-modal-message .el-alert {
  user-select: none;
  border-radius: 0;

  transition: transform 0.5s;
  transform: translateY(-100%);
}

.px-modal-message.is-visible {
  pointer-events: auto;

  .el-alert {
    transform: translateY(0%);
  }
}
</style>
