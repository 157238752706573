import { ILocation } from "@/services/data/location/location.interface";

/**
 * This function takes a location object and formats
 * it to the following structure:
 * City, Region, Country
 *
 * -> The region will appear if the location
 * country is on the regionCountries array
 *
 * -> It may also display the continent if it's
 * the only data available
 *
 * @param location Location object
 */
export const locationFormatter = (location: ILocation | null) => {
  const regionCountries = ["United States", "Canada"];
  let formattedLocation = "";

  if (!location) return "";

  formattedLocation += location.city ? `${location.city}, ` : "";

  if (
    regionCountries.includes(location.country) &&
    location.region_abbreviation
  ) {
    formattedLocation += `${location.region_abbreviation}, `;
  }

  formattedLocation += location.country || "";

  if (!formattedLocation) {
    return location.continent || "";
  }

  return formattedLocation;
};
