<template>
  <div v-if="category" class="milestone-sidebar">
    <img
      alt="minimize"
      data-testid="hide-carousel"
      src="/img/icons/minimize--black-bigger.svg"
      @click="$emit('hide-carousel')"
    />
    <MilestoneLevel
      :category="category"
      :level="categoryLevel"
      data-testid="milestone-level"
    />
    <PxSidebarDivisor
      :title="
        $t('milestonePlanner.sidebar.milestonePlan.whyCategoryMatters', {
          category: category.name,
        })
      "
    />
    <p data-testid="category-description">
      {{ categoryDescription }}
    </p>
    <!-- To be implement in the future -->
    <!-- <template v-if="questions.length">
      <PxSidebarDivisor
        :title="$t('milestonePlanner.sidebar.milestonePlan.commonQuestions')"
      />
      <div class="milestone-sidebar__questions">
        <MatchingAnsweredQuestionsList
          v-for="(question, index) in questions"
          :key="index"
          :question="question"
        />
      </div>
    </template> -->
  </div>
</template>

<script lang="ts">
import PxSidebarDivisor from "@/components/px-sidebar-divisor/px-sidebar-divisor.vue";
import MilestoneLevel from "@/modules/milestone-planner/components/milestone-level/milestone-level.vue";
import { IMatchingAnswer } from "@/services/data/matching-questionary/matching-answer.interface";
import { IMatchingResponse } from "@/services/data/matching-responses/matching-response.interface";
import { defineComponent } from "vue";
import { IGridCategory } from "@/modules/milestone-planner/components/milestones-grid/milestones-grid.interface";
import { IAssessmentLevel } from "@/services/data/assessment/assessment.interface";
import { ICategory } from "@/services/data/category/category.interface";

export interface IMilestoneResponse extends Omit<IMatchingResponse, "answers"> {
  answers: Array<IMatchingAnswer>;
}

export default defineComponent({
  name: "MilestoneSidebar",
  components: {
    MilestoneLevel,
    PxSidebarDivisor,
  },

  props: {
    category: {
      type: Object as () => IGridCategory,
      required: true,
    },
    lastAssessmentData: {
      type: Array as () => IAssessmentLevel[],
      required: true,
    },
    categories: {
      type: Array as () => ICategory[],
      required: true,
    },
  },

  data() {
    return {
      numberOfQuestions: 3,
    };
  },

  computed: {
    // questions(): Array<IMilestoneResponse> {
    //   // TODO: return matching responses/questions from the store
    //   return questions.slice(0, this.numberOfQuestions);
    // },

    categoryDescription(): string {
      const selectedCategory = this.categories.find(
        (category) => category.name === this.category.name,
      );
      return selectedCategory ? selectedCategory.description : "";
    },

    categoryLevel(): number {
      const categoryLevelData = this.lastAssessmentData.find(
        (data) => data.category === this.category?.id,
      );

      return categoryLevelData?.level ? categoryLevelData.level : 1;
    },
  },
});
</script>

<style lang="scss" scoped>
.milestone-sidebar {
  background-color: $white;
  border-left: 1px solid $athens-gray;
  border-top-right-radius: 4px;

  img {
    position: absolute;
    top: 12px;
    right: 11px;
    z-index: z("floaters");

    &:hover {
      cursor: pointer;
    }
  }

  &__questions {
    padding: 7px 17px;
  }

  > p {
    padding: 22px 44px 23px 24px;
    font-size: 14px;
    line-height: 20px;
    color: $manatee;
    letter-spacing: -0.3px;
  }
}
</style>
