<template>
  <div class="visitors-milestone-overview page-full-height">
    <div class="page-body">
      <div class="page-wrapper">
        <div class="page-container visitors-milestone-overview__page-container">
          <PxPanel v-loading="isLoading">
            <div class="visitors-milestone-overview__panel">
              <template v-if="!isLoading && shouldRenderGrid">
                <div
                  :class="{
                    'visitors-milestone-overview__main--carousel':
                      carouselVisible,
                  }"
                  class="visitors-milestone-overview__main"
                  data-testid="visitors-milestone-overview__main"
                >
                  <div class="visitors-milestone-overview__header">
                    <div class="visitors-milestone-overview__header-left">
                      <div
                        v-if="companyData"
                        class="visitors-milestone-overview__content"
                        @click="goToOwnerProfile"
                      >
                        <PxAvatar
                          :name="companyData.name"
                          :photo="companyData.logo"
                          :size="24"
                          class="visitors-milestone-overview__content-logo"
                        />
                        <PxButton
                          class="el-button--link el-button--link-dark visitors-milestone-overview__content-title"
                          type="link"
                        >
                          {{ companyData.name }}
                        </PxButton>
                        <PxIcon
                          class="visitors-milestone-overview__content-arrow-separator"
                          name="arrow--right-black"
                          size="12"
                        />
                      </div>
                      <h1 class="visitors-milestone-overview__title">
                        {{ $t("milestonePlanner.label") }}
                      </h1>
                      <PxIcon
                        :size="22"
                        class="visitors-milestone-overview__onboarding-dialog-trigger"
                        data-testid="onboarding-dialog-trigger"
                        name="information--light"
                        @click="openOnboardingDialog"
                      />
                    </div>
                    <!-- TODO: Add print feature -->
                    <!-- Consider adding feature flags for each button -->
                    <div class="visitors-milestone-overview__header-right">
                      <!-- <PxButton v-bind="$options.static.printButton" /> -->
                      <PxButton
                        v-bind="$options.static.maximizeButton"
                        ref="showFullGridButton"
                        @click="showFullGrid"
                      />
                    </div>
                  </div>
                  <transition mode="out-in" name="fade">
                    <MilestonesGrid
                      v-if="!carouselVisible"
                      ref="milestonesGrid"
                      :categories="gridCategories"
                      :current-level="latestAssessment.level.value"
                      :is-visitor="!$user.isOwner()"
                      :levels-count="categories[0].categoryDetails.length"
                      class="visitors-milestone-overview__grid"
                      @show-carousel="showCarousel"
                    />
                    <MilestoneCarousel
                      v-else
                      ref="milestonesCarousel"
                      :categories="gridCategories"
                      :is-visitor="!$user.isOwner()"
                      :last-assessment-data="latestAssessment.data"
                      :levels-count="categories[0].categoryDetails.length"
                      :selected-category="carouselSelectedCategory"
                      @change-category-level="changeCarouselCategoryLevel"
                      @hide-carousel="hideCarousel"
                    />
                  </transition>
                  <div class="visitors-milestone-overview__grid-footer">
                    <LastAssessmentDate
                      :date="lastAssessmentDate"
                      class="visitors-milestone-overview__last-assessment"
                    />
                    <div class="visitors-milestone-overview__grid-tip">
                      <PxIcon
                        :size="16"
                        class="visitors-milestone-overview__grid-tip-icon"
                        name="information--malibu"
                      />
                      {{ $t("milestonePlanner.milestoneGrid.footer.tip") }}
                    </div>
                    <GridLegend
                      class="visitors-milestone-overview__grid-legend"
                    />
                  </div>
                </div>
                <transition mode="out-in" name="fade">
                  <MilestoneSidebar
                    v-if="carouselVisible"
                    :categories="categories"
                    :category="carouselSelectedCategory"
                    :last-assessment-data="latestAssessment.data"
                    @hide-carousel="hideCarousel"
                  />
                  <OverviewSidebar
                    v-else
                    :latest-assessment-level="latestAssessment.level"
                    data-testid="visitors-milestone-overview__sidebar"
                  />
                </transition>
              </template>
            </div>
          </PxPanel>
        </div>
      </div>
    </div>
    <MilestoneOnboardingDialog
      v-model:visibility="onboardingDialogVisibility"
      :is-visitor="!$user.isOwner()"
      @close-dialog="closeOnboardingDialog"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import moment from "moment";
import {
  EPxButtonSize,
  EPxButtonVariant,
} from "@/components/px-button/px-button.types";
import { IGridCategory } from "../components/milestones-grid/milestones-grid.interface";
import { ICategory } from "@/services/data/category/category.interface";
import { IAssessment } from "@/services/data/assessment/assessment.interface";
import MilestoneOnboardingDialog from "@/modules/milestone-planner/components/milestone-onboarding-dialog/milestone-onboarding-dialog.vue";
import GridLegend from "@/modules/milestone-planner/components/grid-legend/grid-legend.vue";
import OverviewSidebar from "@/modules/milestone-planner/components/overview-sidebar/overview-sidebar.vue";
import MilestoneCarousel from "@/modules/milestone-planner/components/milestone-carousel/milestone-carousel.vue";
import MilestonesGrid from "@/modules/milestone-planner/components/milestones-grid/milestones-grid.vue";
import MilestoneSidebar from "@/modules/milestone-planner/components/milestone-sidebar/milestone-sidebar.vue";
import LastAssessmentDate from "@/modules/milestone-planner/components/last-assessment-date/last-assessment-date.vue";

import { ROUTE_MILESTONE_PLANNER_FULLGRID_VISITORS } from "../services/router/routes-names";
import { MILESTONE_PLANNER_VISITOR_ONBOARDED } from "@/modules/milestone-planner/constants";
import { ICompany } from "@/modules/profile/services/data/company/company.types";
import { ROUTE_PROFILE } from "@/modules/profile/services/router/routes-names";

export default defineComponent({
  name: "VisitorsMilestonePlannerOverview",

  components: {
    OverviewSidebar,
    MilestoneOnboardingDialog,
    GridLegend,
    MilestoneCarousel,
    MilestonesGrid,
    MilestoneSidebar,
    LastAssessmentDate,
  },

  static: {
    printButton: {
      icon: "print",
      size: EPxButtonSize.SMALL,
      variant: EPxButtonVariant.COMPACT,
    },
    maximizeButton: {
      icon: "maximize",
      size: EPxButtonSize.SMALL,
      variant: EPxButtonVariant.COMPACT,
    },
  },

  data() {
    return {
      onboardingDialogVisibility: false,
      carouselVisible: false,
      carouselSelectedCategory: {} as IGridCategory,
    };
  },

  computed: {
    hasOnboarded(): boolean {
      return this.$store.get("milestonePlanner/onboardings/data")[
        MILESTONE_PLANNER_VISITOR_ONBOARDED
      ];
    },

    companyData(): ICompany {
      return this.$store.get("profile/company.data");
    },

    companyId(): number | null {
      return this.companyData ? this.companyData.id : null;
    },

    isLoading(): boolean {
      return [
        this.$store.get("profile/latestAssessment.loading"),
        this.$store.get("milestonePlanner.loading"),
      ].some((stillLoading) => stillLoading);
    },

    categories(): ICategory[] {
      return this.$store.get("categories.data");
    },

    gridCategories(): IGridCategory[] {
      return this.$store.get("milestonePlanner.parsedValue") || [];
    },

    latestAssessment(): IAssessment {
      return this.$store.get("profile/latestAssessment.data");
    },

    lastAssessmentDate(): string {
      return moment(this.latestAssessment?.updated_at).format(
        "DD MMMM YYYY, HH:MMA",
      );
    },

    shouldRenderGrid(): boolean {
      return (
        !!this.categories.length &&
        !!this.gridCategories.length &&
        !!this.latestAssessment
      );
    },
  },

  async mounted() {
    setTimeout(() => {
      if (!this.hasOnboarded) {
        this.onboardingDialogVisibility = true;
        this.markAsOnboarded();
      }
    }, 2000);
  },

  methods: {
    openOnboardingDialog() {
      this.onboardingDialogVisibility = true;
    },

    closeOnboardingDialog() {
      this.onboardingDialogVisibility = false;
    },

    hideCarousel() {
      this.carouselVisible = false;
    },

    showCarousel(category: IGridCategory) {
      this.carouselSelectedCategory = category;
      this.carouselVisible = true;
    },

    changeCarouselCategoryLevel(category: IGridCategory) {
      this.carouselSelectedCategory = category;
    },

    showFullGrid() {
      this.$router.push({ name: ROUTE_MILESTONE_PLANNER_FULLGRID_VISITORS });
    },

    markAsOnboarded(): void {
      this.$store.commit("milestonePlanner/onboardings/SET_DATA", {
        [MILESTONE_PLANNER_VISITOR_ONBOARDED]: true,
      });
    },

    goToOwnerProfile() {
      this.$router.push({
        name: ROUTE_PROFILE,
        params: { id: String(this.companyId) },
      });
    },
  },
});
</script>

<style lang="scss" scoped>
.visitors-milestone-overview {
  max-width: 1140px;
  margin: 0 auto;

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.2s ease;
  }
  .fade-enter,
  .fade-leave-active {
    opacity: 0;
  }

  &__panel {
    display: grid;
    grid-template-columns: auto 395px;
    height: calc(100vh - 146px);
    min-height: 605px;
  }

  &__main {
    padding: 31px 30px;
  }

  &__header,
  &__header-left,
  &__header-right {
    display: flex;
  }

  &__header {
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 42px;
  }

  &__header-left,
  &__header-right {
    align-items: center;
  }

  &__header-right {
    gap: 16px;
    margin-right: 2px;
  }

  &__content {
    display: flex;
    align-items: center;
    cursor: pointer;

    &-logo {
      width: 26px;
      height: 26px;
    }

    &-title :deep() span {
      margin-right: 9px;
      margin-left: 8px;
      font-size: 20px;
      line-height: 30px;
    }

    &-arrow-separator {
      margin-right: 7px;
    }
  }

  &__title {
    margin-right: 13px;
    margin-bottom: 0;
    font-size: 20px;
    line-height: 28px;
  }

  &__onboarding-dialog-trigger {
    margin-top: 1px;
    cursor: pointer;
  }

  &__grid {
    margin-bottom: 32px;
    margin-left: -11px;
  }

  &__grid-footer {
    position: relative;
    padding-left: 31px;
  }

  &__main--carousel {
    padding: 31px 0 31px 30px;

    .visitors-milestone-overview__grid-footer,
    .visitors-milestone-overview__header {
      margin-right: 30px;
    }
  }

  &__last-assessment {
    margin-bottom: 29px;
  }

  &__grid-tip {
    @include grotesk(medium);

    display: flex;
    font-size: 14px;
    font-style: italic;
    line-height: 16px;
    color: $malibu;
    letter-spacing: 0;
  }

  &__grid-tip-icon {
    margin-right: 8px;
  }

  &__grid-legend {
    position: absolute;
    top: -4px;
    right: 19px;
    width: calc(100% - 42px);
  }
}

.visitors-milestone-overview__page-container {
  padding-top: 40px;

  :deep() .px-panel {
    border-radius: 4px;

    .px-panel__body {
      padding: 0;
    }
  }
}
</style>
