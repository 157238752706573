<template>
  <ElDialog
    ref="modal"
    v-model="innerVisibility"
    :close-on-click-modal="false"
    :show-close="false"
    :width="modalWidth"
    class="level-update-modal"
  >
    <LevelUpdateModalLevelsInfo
      v-if="!examplePageVisible"
      :current-level="currentLevel"
      :new-level="newLevel"
      data-testid="level-update-modal-info"
      @close-modal="innerVisibility = false"
    />
    <LevelUpdateModalLevelsExample
      v-else
      :current-level="currentLevel"
      :new-level="newLevel"
    />
  </ElDialog>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import LevelUpdateModalLevelsInfo from "@/modules/common/components/level-update-modal/level-update-modal-levels-info.vue";
import LevelUpdateModalLevelsExample from "@/modules/common/components/level-update-modal/level-update-modal-levels-example.vue";
import ElDialogMixin from "@/mixins/el-dialog.mixin";
import { ILevel } from "@/services/data/level/level.interface";

export default defineComponent({
  name: "LevelUpdateModal",

  components: {
    LevelUpdateModalLevelsInfo,
    LevelUpdateModalLevelsExample,
  },

  mixins: [ElDialogMixin],

  data() {
    return {
      examplePageVisible: false,
    };
  },

  computed: {
    currentLevel(): ILevel {
      return this.$store.get("auth/latestAssessment.data.level");
    },

    newLevel(): ILevel {
      return this.$store.get("viralLevel.finalLevel");
    },

    modalWidth(): string {
      return this.examplePageVisible ? "800px" : "740px";
    },
  },

  created() {
    this.$emitter.on(
      "change-level-example-modal-visibility",
      this.changeExamplePageVisibility,
    );
  },

  beforeUnmount() {
    this.$emitter.off(
      "change-level-example-modal-visibility",
      this.changeExamplePageVisibility,
    );
  },

  methods: {
    changeExamplePageVisibility() {
      this.examplePageVisible = !this.examplePageVisible;
    },
  },
});
</script>

<style lang="scss">
.level-update-modal {
  .el-dialog__header {
    padding: 0;
    background: none;
    border: none;
  }

  .el-dialog__body {
    padding: 32px 0 0 0;
  }
}
</style>
