<template>
  <div v-show="innerVisibility" class="no-processes-modal">
    <div class="no-processes-modal__wrapper">
      <header class="el-dialog__header">
        <span class="el-dialog__title">{{ $t(titlePath) }}</span>
        <div
          v-if="showClose"
          class="el-dialog__headerbtn"
          @click="innerVisibility = false"
        ></div>
      </header>
      <nav>
        <PxNavigationTab
          v-model:selected-tab="selectedNavbarId"
          :navigation-tabs="navBarItems"
          :show-number="false"
          :list-format="false"
        />
      </nav>
      <main>
        <p v-html="modalCopy.description"></p>
        <div class="no-processes-modal__example">
          <p
            v-if="selectedNavbarId == 1"
            v-html="$t('chartsDashboard.requestProcessesModal.example')"
          ></p>
          <ul v-else>
            <li v-for="(copy, index) in modalExampleCopy" :key="index">
              <b>{{ copy.title }}</b>
              {{ copy.description }}
              <ul v-if="copy.note">
                <li v-html="copy.note"></li>
              </ul>
            </li>
          </ul>
        </div>
        <small v-html="modalCopy.note"></small>
      </main>
      <div class="el-dialog__footer">
        <PxButton
          v-bind="buttonProps"
          data-testid="no-processes-modal-cta"
          @click.stop="openEmailClient"
        />
      </div>
    </div>
  </div>
  <div
    v-if="innerVisibility && showOverlay"
    class="no-processes-modal__overlay el-overlay"
  ></div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import ElDialogMixin from "@/mixins/el-dialog.mixin";

import { EPxButtonType } from "@/components/px-button/px-button.types";
import { INavigationTab } from "@/components/px-navigation-tab/px-navigation-tab.vue";

import PxNavigationTab from "@/components/px-navigation-tab/px-navigation-tab.vue";

export default defineComponent({
  name: "RequestProcessesModal",

  components: {
    PxNavigationTab,
  },

  mixins: [ElDialogMixin],

  props: {
    showOverlay: {
      type: Boolean,
      default: false,
    },

    showClose: {
      type: Boolean,
      default: false,
    },

    titlePath: {
      type: String,
      default: "chartsDashboard.requestProcessesModal.title[0]",
    },
  },

  data() {
    return {
      selectedNavbarId: 1,
    };
  },

  computed: {
    buttonProps(): any {
      return {
        type: EPxButtonType.GREEN,
        label: this.$t("chartsDashboard.requestProcessesModal.cta"),
      };
    },

    modalCopy(): any {
      return this.selectedNavbarId === 1
        ? {
            description: this.$t(
              "chartsDashboard.requestProcessesModal.description[0]",
            ) as string,
            note: this.$t(
              "chartsDashboard.requestProcessesModal.note[0]",
            ) as string,
          }
        : {
            description: this.$t(
              "chartsDashboard.requestProcessesModal.description[1]",
            ) as string,
            note: this.$t(
              "chartsDashboard.requestProcessesModal.note[1]",
            ) as string,
          };
    },

    modalExampleCopy() {
      return this.$tm(
        "chartsDashboard.requestProcessesModal.example2",
      ) as Array<{ title: string; description: string; note?: string }>;
    },

    navBarItems(): Array<INavigationTab> {
      return [
        {
          title: this.$t(
            "chartsDashboard.requestProcessesModal.tabs[0]",
          ) as string,
          id: 1,
        },
        {
          title: this.$t(
            "chartsDashboard.requestProcessesModal.tabs[1]",
          ) as string,
          id: 2,
        },
      ];
    },
  },

  methods: {
    openEmailClient() {
      const email = "support@abaca.app";
      const subject = this.$t(
        "chartsDashboard.requestProcessEmail.subject",
      ) as string;
      const body = this.$t(
        "chartsDashboard.requestProcessEmail.body",
      ) as string;

      const mailtoLink = `mailto:${email}?subject=${encodeURIComponent(
        subject,
      )}&body=${encodeURIComponent(body)}`;

      // Open the email client
      window.location.href = mailtoLink;
    },
  },
});
</script>

<style lang="scss">
.no-processes-modal {
  width: 820px;
  max-width: calc(100vw - 30px);
  height: 690px;
  max-height: calc(100vh - 60px);
  overflow: auto;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: $mischka;
    border-radius: 10px;
  }

  z-index: z("overlay");

  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  &__overlay.el-overlay {
    z-index: z("overlay") - 1;
  }

  &__wrapper {
    margin: 30px;
    background: #fff;
    border-radius: 4px;
    box-shadow: 0 2px 16px 0 rgba(39, 39, 64, 0.18);
  }

  .el-dialog__header {
    position: relative;
    padding: 15px 56px 18px 30px;
  }

  .el-dialog__title {
    font-size: 20px;
    font-weight: 600;
    line-height: 1.5;
    letter-spacing: -0.2px;
    color: $ebony-clay;
  }

  .el-dialog__footer {
    display: flex;
    justify-content: center;

    padding: 21px 0 32px;

    &::before {
      display: none;
    }
  }

  p,
  li {
    margin: 0;

    font-size: to-rem(17px);
    line-height: 1.6;
    color: $ebony-clay;
    letter-spacing: -0.39px;
  }

  small {
    font-size: to-rem(15px);
    line-height: 1.47;
    font-style: italic;
    color: $ebony-clay;
    letter-spacing: -0.17px;
  }

  &__example {
    margin: 15px 0;
    padding: 24px;
    border-radius: 4px;
    background-color: $alabaster;
  }

  b {
    font-weight: 600;
  }

  .el-button {
    padding: 0 15px;
  }

  nav {
    border-bottom: 1px solid $athens-gray;
  }

  nav .px-navigation-tab:not(.px-navigation-tab--list-format) {
    justify-content: flex-start;
    padding: 16px 31px 0;
  }

  .px-navigation-tab-item__title {
    letter-spacing: -0.1px;
    line-height: 1.6rem;
  }

  .px-navigation-tab-item__container:not(.px-navigation-tab-item--active) {
    padding: 0 20px 9px 20px;
  }

  main {
    padding: 31px 40px 0;
  }

  ul {
    padding: 0 24px;
    margin: 0;

    list-style: disc;
  }
}
</style>
