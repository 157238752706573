import { resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, withCtx as _withCtx, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-141a0e22"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "list-options" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PxIcon = _resolveComponent("PxIcon")!
  const _component_PxButton = _resolveComponent("PxButton")!
  const _component_ElDropdownItem = _resolveComponent("ElDropdownItem")!
  const _component_ElDropdownMenu = _resolveComponent("ElDropdownMenu")!
  const _component_ElDropdown = _resolveComponent("ElDropdown")!
  const _component_ListOptionsDeleteModal = _resolveComponent("ListOptionsDeleteModal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ElDropdown, {
      class: _normalizeClass([{ 'is-open': _ctx.optionsVisible }, "list-options__dropdown"]),
      trigger: "click",
      onVisibleChange: _ctx.onVisibleChange
    }, {
      dropdown: _withCtx(() => [
        _createVNode(_component_ElDropdownMenu, { class: "list-options__dropdown-menu" }, {
          default: _withCtx(() => [
            (_ctx.isOwner)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  _createVNode(_component_ElDropdownItem, {
                    onClick: _ctx.exportClickHandler,
                    textContent: _toDisplayString(_ctx.$t('companyLists.list.exportCSV'))
                  }, null, 8, ["onClick", "textContent"]),
                  (_ctx.showAddMembersButton)
                    ? (_openBlock(), _createBlock(_component_ElDropdownItem, {
                        key: 0,
                        onClick: _ctx.addMembersClickHandler,
                        textContent: _toDisplayString(_ctx.$t('companyLists.list.addMembers'))
                      }, null, 8, ["onClick", "textContent"]))
                    : _createCommentVNode("", true),
                  _createVNode(_component_ElDropdownItem, {
                    onClick: _ctx.manageMembersClickHandler,
                    textContent: _toDisplayString(_ctx.$t('companyLists.list.manageMembers'))
                  }, null, 8, ["onClick", "textContent"]),
                  (!_ctx.isSmartList)
                    ? (_openBlock(), _createBlock(_component_ElDropdownItem, {
                        key: 1,
                        onClick: _ctx.settingsClickHandler,
                        textContent: _toDisplayString(_ctx.$t('companyLists.list.settings'))
                      }, null, 8, ["onClick", "textContent"]))
                    : _createCommentVNode("", true),
                  (!_ctx.isSmartList)
                    ? (_openBlock(), _createBlock(_component_ElDropdownItem, {
                        key: 2,
                        class: "list-options__danger-item",
                        onClick: _ctx.deleteClickHandler,
                        textContent: _toDisplayString(_ctx.$t('companyLists.list.delete'))
                      }, null, 8, ["onClick", "textContent"]))
                    : _createCommentVNode("", true)
                ], 64))
              : (_openBlock(), _createBlock(_component_ElDropdownItem, {
                  key: 1,
                  class: "list-options__danger-item",
                  onClick: _ctx.removeFromSharedClickHandler,
                  textContent: _toDisplayString(_ctx.$t('companyLists.list.removeShared'))
                }, null, 8, ["onClick", "textContent"]))
          ]),
          _: 1
        })
      ]),
      default: _withCtx(() => [
        _createVNode(_component_PxButton, {
          class: _normalizeClass([{ 'is-active': _ctx.optionsVisible }, "list-options__dropdown-toggler"]),
          size: "medium",
          onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["stop"]))
        }, {
          default: _withCtx(() => [
            _createVNode(_component_PxIcon, {
              size: 16,
              name: "ellipsis--manatee"
            })
          ]),
          _: 1
        }, 8, ["class"])
      ]),
      _: 1
    }, 8, ["class", "onVisibleChange"]),
    _createVNode(_component_ListOptionsDeleteModal, {
      visibility: _ctx.deleteModalVisible,
      "onUpdate:visibility": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.deleteModalVisible) = $event)),
      onDeleteList: _ctx.deleteConfirmationClickHandler
    }, null, 8, ["visibility", "onDeleteList"])
  ]))
}