import { Module } from "vuex";
import { RootState } from "@/services/store/root-state";

import { getters } from "@/modules/milestone-planner/services/store/milestone-planner/sub-modules/passcode/passcode.getters";
import { mutations } from "@/modules/milestone-planner/services/store/milestone-planner/sub-modules/passcode/passcode.mutations";
import { actions } from "@/modules/milestone-planner/services/store/milestone-planner/sub-modules/passcode/passcode.actions";

import { IMilestonePlannersPasscodeState } from "@/modules/milestone-planner/services/store/milestone-planner/sub-modules/passcode/passcode.interface";

/**
 * Initial module state.
 */
const state: IMilestonePlannersPasscodeState = {
  passcodes: {},
};

/**
 * Milestone Planners passcode store module.
 */
export const passcodeModule: Module<
  IMilestonePlannersPasscodeState,
  RootState
> = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
