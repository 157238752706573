<template>
  <div class="submission-tab-invest-range">
    <h2 class="submission-tab__print-title">
      {{ $t("profile.assessment.levelPanel.title") }}
    </h2>
    <div class="submission-tab-invest-range__container">
      <div class="submission-tab__score-wrapper">
        <PxIcon
          :name="`level-range-graph/level-${investingLevelRangeInterval}`"
          :size="140"
          class="submission-tab__level"
        />
      </div>
      <div class="submission-tab__content-wrapper">
        <h2 class="submission-tab__title" v-html="investingLevelRangeTitle" />
        <p class="submission-tab__subtitle">
          <template v-if="investingLevelMaximum">
            {{
              $t("supporters.results.submissionPanel.tabs.levelsTip", {
                min: investingLevelMinimum,
                max: investingLevelMaximum,
              })
            }}
          </template>
          <template v-else>
            {{
              $t("supporters.results.submissionPanel.tabs.levelTip", {
                level: investingLevelMinimum,
              })
            }}
          </template>
        </p>
        <ol class="submission-tab__levels">
          <li
            v-for="(level, key) in investingLevelsSelection"
            :key="key"
            class="submission-tab__levels-item"
          >
            <b>
              {{ $t("supporters.results.levelPanel.label") }}
              {{ level.value }}
            </b>
            - {{ level.title }}
            <ElTooltip
              :enterable="false"
              placement="top"
              popper-class="submission-tab__levels-tooltip el-abaca-tooltip"
            >
              <template #content>
                <div v-html="level.description" />
              </template>
              <PxIcon
                :size="17"
                class="submission-tab__levels-tooltip-icon"
                name="question"
              />
            </ElTooltip>
          </li>
        </ol>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import sortBy from "lodash/sortBy";
import uniqBy from "lodash/uniqBy";

import { ILevel } from "@/services/data/level/level.interface";
import { ELevelActions } from "@/services/store/levels/levels-types";
import { SUPPORTER_USER_GROUP_ID } from "@/modules/common/constants";

export default defineComponent({
  props: {
    investRange: {
      type: Array as () => Array<number>,
      required: true,
    },
  },

  computed: {
    levels(): Array<ILevel> {
      return this.$store.get("levels/data");
    },

    investingLevelMinimum(): number {
      return this.investRange.length ? this.investRange[0] : 1;
    },

    investingLevelMaximum(): number | null {
      return this.investRange.length > 1 ? this.investRange[1] : null;
    },

    investingLevelsSelection(): Array<ILevel> {
      if (!this.investingLevelMaximum) {
        const uniqLevelValues = uniqBy(this.levels, "value");
        return uniqLevelValues.filter(
          (level) => level.value === this.investingLevelMinimum,
        );
      }

      const uniqAscendingLevels = sortBy(uniqBy(this.levels, "value"), "value");
      return uniqAscendingLevels.filter(
        (level) =>
          level.value >= this.investingLevelMinimum &&
          !!this.investingLevelMaximum &&
          level.value <= this.investingLevelMaximum,
      );
    },

    investingLevelRangeInterval(): string {
      if (this.investingLevelMaximum) {
        return this.investingLevelMinimum === this.investingLevelMaximum
          ? this.investingLevelMinimum.toString()
          : this.investRange.join("-");
      }

      return this.investingLevelMinimum.toString();
    },

    investingLevelRangeTitle(): string {
      const minimumLevel = this.levels.find(
        (level) => level.value === this.investingLevelMinimum,
      );
      let rangeTitle = minimumLevel
        ? `${minimumLevel.title} <span>(${this.$t(
            "supporters.results.levelPanel.label",
          )} ${minimumLevel.value})</span>`
        : "";

      if (this.investingLevelMaximum) {
        const maximumLevel = this.levels.find(
          (level) => level.value === this.investingLevelMaximum,
        );
        rangeTitle += maximumLevel
          ? `<br><span>to</span> ${maximumLevel.title} <span>(${this.$t(
              "supporters.results.levelPanel.label",
            )} ${maximumLevel.value})</span>`
          : "";
      }

      return rangeTitle;
    },
  },

  created() {
    if (!this.levels.length) {
      // Fetch levels if user lands on the results page directly.
      this.fetchLevels();
    }
  },

  methods: {
    async fetchLevels() {
      await this.$store.dispatch(ELevelActions.FETCH, {
        group: SUPPORTER_USER_GROUP_ID,
      });
    },
  },
});
</script>

<style lang="scss" scoped>
.submission-tab-invest-range__container {
  display: grid;
  grid-template-areas: "score content";
  grid-template-columns: max-content 1fr;
  grid-gap: 29px;
  padding-top: 12px;
}

.submission-tab__print-title {
  display: none;
}

.submission-tab__score-wrapper {
  grid-area: score;
  max-height: 146px;

  @include breakpoint-up(md) {
    max-height: unset;
  }
}

.submission-tab__content-wrapper {
  grid-area: content;
}

.submission-tab__title {
  grid-area: header;
  font-size: 1.2667rem;
  line-height: 26px;

  @include breakpoint-up(md) {
    margin-top: 19px;
    margin-bottom: 0;

    font-size: 22px;
    line-height: 32px;
  }

  :deep() span {
    display: inline-block;
    font-size: 22px;
    line-height: 32px;
    letter-spacing: -0.4px;
  }
}

.submission-tab__subtitle {
  margin: 13px 0 0;
  font-size: 15px;
  line-height: 26px;
  letter-spacing: -0.3px;
}

.submission-tab__levels {
  grid-area: content;

  padding: 0;
  margin: 14px 0 25px;
  list-style: none;

  &-item {
    font-size: 15px;
    line-height: 26px;

    &:not(:first-child) {
      margin-top: 8px;
    }
  }

  b {
    font-weight: 600;
  }
}

.submission-tab__level {
  max-width: 140px;

  @include breakpoint-down(sm) {
    position: relative;
    top: -24px;
    right: 1px;
  }

  @include breakpoint-up(md) {
    max-width: unset;
  }
}

.submission-tab-error {
  margin-top: -4px;

  & :deep() .px-global-error__icon {
    margin-bottom: 2px;
  }

  & :deep() .px-global-error__notice {
    padding-top: 1px;
    margin-bottom: 6px;

    font-size: 0.9333rem;
  }
}

.submission-tab__levels-tooltip-icon {
  display: inline-block;
  // override square size
  width: 16px !important;
  margin-left: 2px;
  vertical-align: -3px;
}

.submission-tab__list {
  text-align: left;
  list-style-type: none;

  li {
    position: relative;
    padding-right: 5px;
    padding-left: 28px;
    font-size: 15px;
    line-height: 25px;
    color: $tundora;
    letter-spacing: -0.2px;

    @include breakpoint-up(md) {
      font-size: 17px;
      line-height: 30px;
      letter-spacing: -0.25px;
    }

    &::before {
      position: absolute;
      top: 5px;
      left: 2px;
      width: 14px;
      height: 14px;
      content: "";
      background-image: url("#{$assetsPath}/img/icons/check--blue.svg");
      background-repeat: no-repeat;
      background-size: contain;

      @include breakpoint-up(md) {
        top: 8px;
        left: 0;
        width: 16px;
        height: 14px;
      }
    }

    &:not(:first-child) {
      margin-top: 6px;

      @include breakpoint-up(md) {
        margin-top: 10px;
      }
    }
  }
}
</style>

<style lang="scss">
.submission-tab__levels-tooltip {
  max-width: 260px;
}
</style>
