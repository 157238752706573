import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-1b6df043"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "list-share-users-search__form" }
const _hoisted_2 = ["textContent"]
const _hoisted_3 = ["textContent"]
const _hoisted_4 = ["textContent"]
const _hoisted_5 = ["textContent"]
const _hoisted_6 = ["textContent"]
const _hoisted_7 = { class: "list-share-users-search__option-wrapper" }
const _hoisted_8 = { key: 0 }
const _hoisted_9 = ["alt", "src"]
const _hoisted_10 = ["innerHTML"]
const _hoisted_11 = ["innerHTML"]
const _hoisted_12 = ["textContent"]
const _hoisted_13 = { class: "list-share-users-search__submission" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_i18n_t = _resolveComponent("i18n-t")!
  const _component_ElOption = _resolveComponent("ElOption")!
  const _component_ElSelect = _resolveComponent("ElSelect")!
  const _component_PxButton = _resolveComponent("PxButton")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([{ 'is-active': _ctx.onSearchMode }, "list-share-users-search"])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("label", {
        class: "el-form-item__label",
        for: "user-search",
        textContent: _toDisplayString(_ctx.searchInputCopy.label)
      }, null, 8, _hoisted_2),
      _createVNode(_component_ElSelect, {
        id: "user-search",
        ref: "select",
        modelValue: _ctx.selectedUsers,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedUsers) = $event)),
        class: _normalizeClass([{ 'has-users': _ctx.selectedUsers.length }, "list-share-users-search__input"]),
        "data-suffix": _ctx.searchInputCopy.clear,
        loading: _ctx.isLoading,
        placeholder: _ctx.searchInputCopy.placeholder,
        "remote-method": _ctx.searchForUsers,
        "reserve-keyword": false,
        clearable: "",
        "default-first-option": "",
        filterable: "",
        multiple: "",
        "popper-class": "list-share-users-popper",
        remote: "",
        "filter-method": _ctx.handleSelectChange,
        onClear: _ctx.clearHandler,
        onFocus: _ctx.focusHandler,
        onVisibleChange: _ctx.dropdownChangeHandler
      }, {
        empty: _withCtx(() => [
          _withDirectives((_openBlock(), _createElementBlock("div", null, [
            _createVNode(_component_i18n_t, {
              keypath: "companyLists.list.shareModal.searchSelect.empty.title.content",
              tag: "span"
            }, {
              user: _withCtx(() => [
                _createElementVNode("strong", {
                  textContent: _toDisplayString(_ctx.searchSelectEmptyCopy.title.placeholders[0])
                }, null, 8, _hoisted_3)
              ]),
              account: _withCtx(() => [
                _createElementVNode("strong", {
                  textContent: _toDisplayString(_ctx.searchSelectEmptyCopy.title.placeholders[1])
                }, null, 8, _hoisted_4)
              ]),
              _: 1
            }),
            _createVNode(_component_i18n_t, {
              keypath: "companyLists.list.shareModal.searchSelect.empty.subtitle.content",
              tag: "span"
            }, {
              placeholder: _withCtx(() => [
                _createElementVNode("strong", {
                  textContent: _toDisplayString(_ctx.searchSelectEmptyCopy.subtitle.placeholder)
                }, null, 8, _hoisted_5)
              ]),
              _: 1
            })
          ])), [
            [_directive_loading, _ctx.isLoading]
          ])
        ]),
        default: _withCtx(() => [
          (_ctx.hasEmptyInput)
            ? (_openBlock(), _createBlock(_component_ElOption, {
                key: 0,
                disabled: true,
                value: false,
                class: "list-share-users-search__tip"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_i18n_t, {
                    keypath: "companyLists.list.shareModal.searchSelect.tip.content",
                    tag: "span"
                  }, {
                    name: _withCtx(() => [
                      _createElementVNode("strong", {
                        textContent: _toDisplayString(_ctx.searchSelectTipPlaceholdersCopy)
                      }, null, 8, _hoisted_6)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.searchResults, (item) => {
            return (_openBlock(), _createBlock(_component_ElOption, {
              key: item.user_profile,
              label: item.name,
              value: item.user_profile,
              class: _normalizeClass(["list-share-users-search__option", { 'is-disabled': _ctx.shouldUserBeDisabled(item.user_profile) }])
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_7, [
                  (item.logo)
                    ? (_openBlock(), _createElementBlock("picture", _hoisted_8, [
                        _createElementVNode("img", {
                          alt: item.name,
                          src: item.logo
                        }, null, 8, _hoisted_9)
                      ]))
                    : _createCommentVNode("", true),
                  _createElementVNode("h4", {
                    innerHTML: _ctx.highlightWordMatch(item.name, _ctx.searchNeedle)
                  }, null, 8, _hoisted_10),
                  (item.email)
                    ? (_openBlock(), _createElementBlock("span", {
                        key: 1,
                        innerHTML: _ctx.highlightWordMatch(item.email, _ctx.searchNeedle)
                      }, null, 8, _hoisted_11))
                    : _createCommentVNode("", true)
                ])
              ]),
              _: 2
            }, 1032, ["label", "value", "class"]))
          }), 128))
        ]),
        _: 1
      }, 8, ["modelValue", "class", "data-suffix", "loading", "placeholder", "remote-method", "filter-method", "onClear", "onFocus", "onVisibleChange"]),
      _createVNode(_component_i18n_t, {
        class: "el-form-item__bottom-text el-form-item__tip",
        keypath: "companyLists.list.shareModal.searchInput.tip.content",
        tag: "span"
      }, {
        placeholder: _withCtx(() => [
          _createElementVNode("strong", {
            class: "el-form-item__bottom-text--strong",
            textContent: _toDisplayString(_ctx.searchInputCopy.tip.placeholder)
          }, null, 8, _hoisted_12)
        ]),
        _: 1
      })
    ]),
    _createElementVNode("div", _hoisted_13, [
      _createVNode(_component_PxButton, {
        loading: _ctx.isSendingEmail,
        class: "list-share-users-search__submission-link",
        size: "small",
        type: "link",
        onClick: _ctx.onClickCancelHandler
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t("common.cancel")), 1)
        ]),
        _: 1
      }, 8, ["loading", "onClick"]),
      _createVNode(_component_PxButton, {
        disabled: !_ctx.hasSelectedUsers,
        loading: _ctx.listLoading || _ctx.isSendingEmail,
        class: "list-share-users-search__submission-button",
        type: "green",
        onClick: _ctx.onClickSendEmailHandler
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t("common.sendEmail")), 1)
        ]),
        _: 1
      }, 8, ["disabled", "loading", "onClick"])
    ])
  ], 2))
}