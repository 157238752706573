<template>
  <div class="submissions-responses__container">
    <div
      v-for="(response, index) in responses"
      :key="`response-${index}`"
      class="submissions-responses__item"
    >
      <div class="submissions-responses__question">
        {{ getQuestion(response) }}
      </div>
      <div class="submissions-responses__answer">
        {{ getAnswer(response) }}
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import moment from "moment";
import { IMatchingResponse } from "@/services/data/matching-responses/matching-response.interface";
import { IMatchingQuestion } from "@/services/data/matching-questionary/matching-question.interface";
import { IMatchingAnswer } from "@/services/data/matching-questionary/matching-answer.interface";

export default defineComponent({
  name: "SubmissionResponsesPanel",

  props: {
    responses: {
      type: Array as () => IMatchingResponse[],
      required: true,
    },
  },

  methods: {
    getQuestion(response: IMatchingResponse): string {
      return (response.question as IMatchingQuestion).entrepreneur_question;
    },

    getAnswer(response: IMatchingResponse): string {
      if (response.answers.length > 0) {
        return response.answers.reduce(
          (previousValue: string, currentValue: number) => {
            const question = response.question as IMatchingQuestion;
            const questionAnswer = question.answers.find(
              (answer: IMatchingAnswer) => answer.id === currentValue,
            );
            const separator = previousValue !== "" ? ", " : "";

            return questionAnswer
              ? `${previousValue}${separator}${questionAnswer.value}`
              : previousValue;
          },
          "" as string,
        );
      }

      const answerValue = response.value as any;

      // Date format
      if (answerValue.date) {
        return moment(answerValue.date, "YYYY-MM-DD").format("D MMMM YYYY");
      }

      // Numeric format
      if (
        (answerValue.min !== undefined && answerValue.max !== undefined) ||
        answerValue.value !== undefined
      ) {
        const question = response.question as IMatchingQuestion;
        const numericCurrency = question.question_type.meta.currency ? "$" : "";

        return answerValue.min !== undefined && answerValue.max !== undefined
          ? `${numericCurrency}${(
              answerValue.min as number
            ).toLocaleString()} - ${numericCurrency}${(
              answerValue.max as number
            ).toLocaleString()}`
          : `${numericCurrency}${(
              answerValue.value as number
            ).toLocaleString()}`;
      }

      return answerValue[Object.keys(answerValue)[0]];
    },
  },
});
</script>

<style lang="scss" scoped>
.submissions-responses__container {
  padding: 24px 15px 40px;

  @include breakpoint-up(md) {
    padding: 30px 70px 40px;
  }
}

.submissions-responses__item {
  padding: 18px 0;
  word-break: break-word;
  word-wrap: break-word;
  border-bottom: 1px solid $athens-gray;
}

.submissions-responses__item:last-child {
  border-bottom: none;
}

.submissions-responses__question {
  @include grotesk(bold);

  font-size: 17px;
  line-height: 1.76;
  color: $ebony-clay;
}

.submissions-responses__answer {
  font-size: 15px;
  line-height: 1.67;
  color: $ebony-clay;
}
</style>
