import { capitalExplorerModule } from "@/modules/capital-explorer/services/store/capital-explorer/capital-explorer.module";
import { ECapitalExplorerState } from "@/modules/capital-explorer/services/store/capital-explorer/capital-explorer.types";

// Modules to register on the Vuex instance
export const modules = {
  [ECapitalExplorerState.NAMESPACE]: capitalExplorerModule,
};

// Modules to persist cross sessions
export const toPersist = [`${ECapitalExplorerState.NAMESPACE}.passcodes`];
