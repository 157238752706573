<template>
  <div class="supporters-view__container">
    <FormPresentationLayout
      :content-title="viewCopy.contentTitle"
      :description-image-link="viewCopy.imageLink"
      :description-image-title="viewCopy.imageTitle"
      :has-description-background-image="true"
      :has-return-button="hasPreviousPage"
      @previous-page="onReturnButtonClick"
    >
      <template #content>
        <div class="supporter-registration-criteria__container">
          <div class="supporter-registration-criteria__step-wrapper">
            <div
              v-for="(step, key) in viewCopy.steps"
              :key="`criteria-description-step--${key}`"
              class="supporter-registration-criteria__step-container"
            >
              <div class="supporter-registration-criteria__step-number">
                {{ key + 1 }}
              </div>
              <div
                class="supporter-registration-criteria__step-text"
                v-html="step"
              />
            </div>
          </div>
          <div class="supporter-registration-criteria__cta">
            <PxButton
              class="supporter-registration-criteria__main-cta"
              type="primary"
              @click="onNextButtonClick"
            >
              {{ ctaCopy.setupCriteria }}
            </PxButton>
            <PxButton
              v-if="skipEnabled"
              type="link"
              @click="onSkipAllButtonClick"
            >
              {{ ctaCopy.setupCriteriaLater }}
            </PxButton>
          </div>
        </div>
      </template>
    </FormPresentationLayout>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import SupportersFlowMixin from "@/modules/supporters/mixins/supporters-flow.mixin";
import FormPresentationLayout from "@/modules/supporters/components/form-presentation-layout/form-presentation-layout.vue";

export default defineComponent({
  name: "CriteriaStartPage",

  components: {
    FormPresentationLayout,
  },

  mixins: [SupportersFlowMixin],

  computed: {
    viewCopy() {
      return this.$tm("supporters.view.criteriaStartPage") as {
        contentTitle: string;
        steps: string[];
        imageTitle: string;
        imageLink: {
          original: string;
          retina: string;
        };
      };
    },
  },
});
</script>

<style lang="scss" scoped>
.supporters-view__container :deep() {
  .form-presentation-layout__content-wrapper {
    padding-top: 30px;
  }

  .form-presentation-layout__return-button {
    margin-bottom: 38px;
  }

  .form-presentation-layout__content-header {
    margin-bottom: 35px;
  }
}

.supporter-registration-criteria__container {
  display: grid;
  grid-gap: 48px;
}

.supporter-registration-criteria__step-wrapper {
  display: grid;
  grid-gap: 16px;
}

.supporter-registration-criteria__step-container {
  display: grid;
  grid-template-columns: 22px 1fr;
  grid-gap: 10px;
}

.supporter-registration-criteria__step-number {
  width: 22px;
  height: 22px;
  line-height: 22px;
  color: $white;
  text-align: center;
  background: linear-gradient(to bottom, #bfc2cf, $manatee);
  border-radius: 50%;
  transform: translateY(4px);
}

.supporter-registration-criteria__step-text {
  font-size: 16px;
  line-height: 28px;
  color: $manatee;

  :deep() b {
    font-weight: 600;
  }
}

.supporter-registration-criteria__cta {
  display: flex;
}

.supporter-registration-criteria__cta button:first-child {
  margin-right: 16px;
}

.supporter-registration-criteria__cta :deep() .el-button {
  max-width: 50%;
}

.supporter-registration-criteria__main-cta {
  padding-right: 26px;
  padding-left: 26px;
}
</style>
