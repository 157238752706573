import { ActionTree } from "vuex";
import cloneDeep from "lodash/cloneDeep";
import router from "@/services/router";
import { userManager } from "@/modules/authentication/user-manager";

import {
  EViralLevelActions,
  EViralLevelMutations,
  IViralLevelState,
} from "./viral-level-types";
import { RootState } from "@/services/store/root-state";
import { getBaseName } from "@/services/store/utils/get-base-name";
import { ICategory } from "@/services/data/category/category.interface";
import { viralLevelProvider } from "@/services/data/viral-level/viral-level.provider";
import { IMetadataEntry } from "./types/metadata.interface";
import { affiliateProvider } from "@/services/data/affiliate/affiliate.provider";
import { assessmentProvider } from "@/services/data/assessment/assessment.provider";
import {
  ELevelActions,
  ELevelGetters,
} from "@/services/store/levels/levels-types";
import { ILevel } from "@/services/data/level/level.interface";
import { EProfileCompanyActions } from "@/modules/profile/services/store/profile/profile-types";
import {
  IPendingUser,
  IPendingUserAssessment,
} from "./types/pending-user.interface";
import { IMatchingResponse } from "@/services/data/matching-responses/matching-response.interface";
import { pendingProgramQuestionBundlesProvider } from "@/modules/affiliate-program/services/data/pending-program-question-bundles/pending-program-question-bundles.provider";
import { programQuestionBundlesProvider } from "@/modules/affiliate-program/services/data/program-question-bundles/program-question-bundles.provider";
import {
  SELF_ASSESSMENT_ASSESSMENT,
  SELF_ASSESSMENT_STARTING_POINT,
} from "@/modules/self-assessment/services/router/routes-names";
import {
  AFFILIATE_PROGRAM_ASSESSMENT,
  AFFILIATE_PROGRAM_STARTING_POINT,
} from "@/modules/affiliate-program/services/router/routes-names";
import {
  AFFILIATE_TARGET_SUPPORTER,
  AFFILIATE_TYPE_PROGRAM,
} from "@/services/data/affiliate/affiliate.interface";
import { ECategoryActions } from "@/services/store/category/category-types";
import { ENTREPRENEUR_USER_GROUP_ID } from "@/modules/common/constants";
import { defaultAffiliateId } from "@/services/configs";
import { SELF_ASSESSMENT_SKIP_LANDING_PAGE } from "@/modules/self-assessment/constants";
import { ICategoryLevelSelection } from "@/services/data/viral-level/viral-level.interface";
import { IAssessmentLevel } from "@/services/data/assessment/assessment.interface";
import { SUPPORTERS_STARTING_POINT_PROGRAM } from "@/modules/supporters/services/router/routes-names";
import { IMatchingQuestion } from "@/services/data/matching-questionary/matching-question.interface";
import { IQuestionBundle } from "@/services/data/question-bundle/question-bundle.interface";

export const actions: ActionTree<IViralLevelState, RootState> = {
  /**
   * Reset the module state.
   */
  async [getBaseName(EViralLevelActions.RESET)]({
    rootState,
    commit,
    dispatch,
  }) {
    commit(EViralLevelMutations.SET_LOADING, true);

    let categories = (rootState as any).categories.data as Array<ICategory>;

    if (!categories.length) {
      await dispatch(
        ECategoryActions.FETCH,
        {
          group: ENTREPRENEUR_USER_GROUP_ID,
        },
        { root: true },
      );

      categories = (rootState as any).categories.data as Array<ICategory>;
    }

    const levels = categories.map((category: ICategory) => {
      return {
        category: category.id,
        level: 0,
      };
    });

    // TODO: Rethink this strategy on affiliate flow
    // commit(EViralLevelMutations.SET_AFFILIATE_PROGRAM_QUESTION_BUNDLE, null);
    commit(EViralLevelMutations.SET_LEVELS, levels);
    commit(EViralLevelMutations.SET_SELECTED_CATEGORY, 0);
    commit(EViralLevelMutations.SET_SUBMITTED, false);
    commit(EViralLevelMutations.SET_PENDING_USER, null);
    commit(EViralLevelMutations.SET_ERROR, null);
    commit(EViralLevelMutations.SET_LOADING, false);
  },

  /**
   * Set all levels
   */
  [getBaseName(EViralLevelActions.SET_LEVELS)](
    { commit },
    levels: Array<ICategoryLevelSelection>,
  ) {
    commit(EViralLevelMutations.SET_LEVELS, levels);

    return levels;
  },

  /**
   * Set level for the given category.
   */
  [getBaseName(EViralLevelActions.SET_LEVEL)](
    { state, commit },
    { category, level },
  ) {
    const levels = cloneDeep(state.levels);
    const index = levels.findIndex(
      (item: ICategoryLevelSelection) => item.category === category,
    );

    if (index >= 0) {
      levels[index].level = level || 0;
    } else {
      levels.push({
        category,
        level: level || 0,
      });
    }

    commit(EViralLevelMutations.SET_LEVEL, levels);
    return { levels };
  },

  /**
   * Action used to set the current selected level.
   */
  [getBaseName(EViralLevelActions.SET_SELECTED_CATEGORY)](
    { commit },
    newLevel,
  ) {
    commit(EViralLevelMutations.SET_SELECTED_CATEGORY, newLevel);
  },

  /**
   * This must be called when the user first interact with the level slider.
   */
  [getBaseName(EViralLevelActions.INTERACTION)]({ commit }) {
    commit(EViralLevelMutations.SET_INTERACTION, false);
  },

  /**
   * Set the loading state.
   */
  [getBaseName(EViralLevelActions.SET_LOADING)]({ commit }, newVal) {
    commit(EViralLevelMutations.SET_LOADING, newVal);
  },

  /**
   * Compute the final level for the self assessment and store it
   * on the store.
   *
   * @param commit
   * @param state
   * @param group
   * @param skipLoading
   */
  async [getBaseName(EViralLevelActions.COMPUTE_LEVEL)](
    { commit, state },
    { group = ENTREPRENEUR_USER_GROUP_ID, skipLoading = false },
  ) {
    if (!skipLoading) {
      commit(EViralLevelMutations.SET_LOADING, true);
    }
    commit(EViralLevelMutations.SET_ERROR, null);

    const levels = state.levels.map((item: ICategoryLevelSelection) => {
      return {
        ...item,
        level: item.level === 0 ? null : item.level,
      };
    });

    try {
      const { level } = (await viralLevelProvider.create({
        levels,
        group,
      })) as any;

      commit(EViralLevelMutations.SET_FINAL_LEVEL, level);
    } catch (error) {
      commit(EViralLevelMutations.SET_ERROR, error || true);
    } finally {
      if (!skipLoading) {
        commit(EViralLevelMutations.SET_LOADING, false);
      }
    }
  },

  /**
   * Fetch affiliate and store it.
   */
  async [getBaseName(EViralLevelActions.FETCH_AFFILIATE)](
    { commit, dispatch },
    affiliateId,
  ) {
    commit(EViralLevelMutations.SET_LOADING, true);

    try {
      const affiliate = await affiliateProvider.get(affiliateId);
      commit(EViralLevelMutations.SET_AFFILIATE, affiliate);

      // Load question bundles if affiliate flow
      if (affiliate.flow_type === AFFILIATE_TYPE_PROGRAM) {
        // Load affiliate program questions
        dispatch(
          EViralLevelActions.FETCH_AFFILIATE_QUESTION_BUNDLE,
          affiliate.id,
          {
            root: true,
          },
        );
      }
    } catch (error) {
      commit(EViralLevelMutations.SET_AFFILIATE, null);
      commit(EViralLevelMutations.SET_LOADING, false);
    }

    commit(EViralLevelMutations.SET_LOADING, false);
  },

  /**
   * Clear affiliate register.
   */
  async [getBaseName(EViralLevelActions.CLEAR_AFFILIATE)]({ commit }) {
    commit(EViralLevelMutations.SET_LOADING, true);
    commit(EViralLevelMutations.SET_AFFILIATE, null);
    commit(EViralLevelMutations.SET_AFFILIATE_PROGRAM_QUESTION_BUNDLE, null);
    commit(EViralLevelMutations.SET_LOADING, false);
  },

  /**
   * Fetch affiliate question flow and store it.
   */
  async [getBaseName(EViralLevelActions.FETCH_AFFILIATE_QUESTION_BUNDLE)](
    { commit },
    affiliateId,
  ) {
    commit(EViralLevelMutations.SET_LOADING, true);

    try {
      const questionBundles =
        await affiliateProvider.getQuestionBundles(affiliateId);

      // Combine question bundles questions retrieved from api
      const affiliateQuestionBundles = [] as Array<IMatchingQuestion>;
      const teamMembersQuestionBundles = [] as Array<IMatchingQuestion>;
      const questionIds = [] as Array<number>;

      questionBundles.forEach((questionBundle: IQuestionBundle) => {
        questionBundle.questions.forEach((question: IMatchingQuestion) => {
          if (!questionIds.includes(question.id)) {
            if (questionBundle.has_team_member_questions) {
              teamMembersQuestionBundles.push(question);
            } else {
              affiliateQuestionBundles.push(question);
            }

            questionIds.push(question.id);
          }
        });
      });

      commit(
        EViralLevelMutations.SET_AFFILIATE_PROGRAM_QUESTION_BUNDLE,
        affiliateQuestionBundles,
      );
      commit(
        EViralLevelMutations.SET_TEAM_MEMBERS_QUESTION_BUNDLE,
        teamMembersQuestionBundles,
      );
    } catch (error) {
      commit(EViralLevelMutations.SET_AFFILIATE_PROGRAM_QUESTION_BUNDLE, null);
      commit(EViralLevelMutations.SET_LOADING, false);
    }

    commit(EViralLevelMutations.SET_LOADING, false);
  },

  /**
   * Set affiliate program responses for a specific email
   */
  async [getBaseName(
    EViralLevelActions.SET_PENDING_AFFILIATE_PROGRAM_RESPONSES,
  )]({ commit }, { email, affiliate, responses }) {
    commit(EViralLevelMutations.SET_LOADING, true);
    commit(EViralLevelMutations.SET_ERROR, null);

    const mappedResponses = responses.map(
      (response: Partial<IMatchingResponse>) => {
        if (response.answers?.length) {
          const newResponse = { ...response };
          delete newResponse.value;
          return newResponse;
        }

        return response;
      },
    );

    try {
      await pendingProgramQuestionBundlesProvider.create({
        email,
        affiliate,
        responses: mappedResponses,
      });
    } catch (error) {
      commit(EViralLevelMutations.SET_ERROR, error || true);
    } finally {
      commit(EViralLevelMutations.SET_LOADING, false);
    }
  },

  /**
   * Set affiliate program responses for a logged in user
   */
  async [getBaseName(EViralLevelActions.SET_AFFILIATE_PROGRAM_RESPONSES)](
    { commit },
    { affiliate, responses, team_members },
  ) {
    commit(EViralLevelMutations.SET_LOADING, true);
    commit(EViralLevelMutations.SET_ERROR, null);

    const mappedResponses = responses.map(
      (response: Partial<IMatchingResponse>) => {
        if (response.answers?.length) {
          const newResponse = { ...response };
          delete newResponse.value;
          return newResponse;
        }

        return response;
      },
    );

    try {
      await programQuestionBundlesProvider.create({
        affiliate,
        responses: mappedResponses,
        team_members,
      });
    } catch (error) {
      commit(EViralLevelMutations.SET_ERROR, error || true);
    } finally {
      commit(EViralLevelMutations.SET_LOADING, false);
    }
  },

  async [getBaseName(EViralLevelActions.FETCH_ASSESSMENT)](
    context,
    token: string,
  ) {
    // Fetch levels from the API
    await context.dispatch(
      ELevelActions.FETCH,
      { group: ENTREPRENEUR_USER_GROUP_ID },
      { root: true },
    );

    context.commit(EViralLevelMutations.SET_LOADING, true);
    context.commit(EViralLevelMutations.SET_ERROR, false);

    const assessment = await assessmentProvider.getByToken(token);

    // Set levels data
    const levels = assessment.data.map((entry: IAssessmentLevel) => ({
      category: entry.category,
      level: entry.level || 0,
    }));
    context.commit(EViralLevelMutations.SET_LEVELS, levels);

    // Set final level
    const allLevels = context.rootGetters[
      ELevelGetters.LEVELS
    ] as Array<ILevel>;
    const finalLevel = allLevels.find(
      (entry: ILevel) => entry.id === assessment.level.id,
    );
    context.commit(EViralLevelMutations.SET_FINAL_LEVEL, finalLevel);

    await context.dispatch(EProfileCompanyActions.FETCH, assessment.evaluated, {
      root: true,
    });

    context.commit(EViralLevelMutations.SET_LOADING, false);
  },

  /**
   * Set self-assessment as submitted.
   */
  [getBaseName(EViralLevelActions.SET_AS_SUBMITTED)]({ commit }) {
    commit(EViralLevelMutations.SET_SUBMITTED, true);
  },

  /**
   * Set metadata based on a key and value object.
   *
   * Example:
   * ```
   *  { key: "footerClass", value: "is-assessment-results" }
   * ```
   *
   * @param param0 Context
   * @param payload Metadata to be set.
   */
  [getBaseName(EViralLevelActions.SET_METADATA)](
    { commit },
    payload: IMetadataEntry,
  ) {
    commit(EViralLevelMutations.SET_METADATA, payload);
  },

  /**
   * Allows to remove a key from the set of metadata.
   *
   * @param param0 Context
   * @param key Key to be removed.
   */
  [getBaseName(EViralLevelActions.REMOVE_METADATA)]({ commit }, key: string) {
    commit(EViralLevelMutations.REMOVE_METADATA, key);
  },

  /**
   * Set the final level.
   */
  [getBaseName(EViralLevelActions.SET_FINAL_LEVEL)]({ commit }, level: ILevel) {
    commit(EViralLevelMutations.SET_FINAL_LEVEL, level);
  },

  /**
   * Set the pending user.
   */
  [getBaseName(EViralLevelActions.SET_PENDING_USER)](
    { commit },
    pendingUser: IPendingUser,
  ) {
    commit(EViralLevelMutations.SET_PENDING_USER, pendingUser);
  },

  /**
   * Set the pending user's assessment.
   */
  [getBaseName(EViralLevelActions.SET_PENDING_ASSESSMENT)](
    { commit },
    pendingAssessment: IPendingUserAssessment,
  ) {
    commit(EViralLevelMutations.SET_PENDING_ASSESSMENT, pendingAssessment);
  },

  /**
   * Fetch assessment initial data
   */
  async [getBaseName(EViralLevelActions.FETCH_ASSESSMENT_FLOW_INIT_DATA)]({
    state,
    commit,
    dispatch,
  }) {
    commit(EViralLevelMutations.SET_LOADING, true);
    const currentRoute = router.currentRoute.value;

    // Route treatment
    const queryAffiliateId =
      currentRoute.query &&
      ((currentRoute.query.a || currentRoute.query.affiliate) as string);

    const affiliateId = queryAffiliateId
      ? queryAffiliateId.toLowerCase()
      : defaultAffiliateId();

    const skipStartingPage =
      currentRoute.query.l === SELF_ASSESSMENT_SKIP_LANDING_PAGE;

    const isSelfAssessmentFlowStart =
      currentRoute.name === SELF_ASSESSMENT_STARTING_POINT ||
      currentRoute.name === SELF_ASSESSMENT_ASSESSMENT;
    const isAffiliateFlowStart =
      currentRoute.name === AFFILIATE_PROGRAM_STARTING_POINT;

    // Load categories
    await dispatch(
      ECategoryActions.FETCH,
      {
        group: ENTREPRENEUR_USER_GROUP_ID,
      },
      { root: true },
    );

    // Load levels
    await dispatch(
      ELevelActions.FETCH,
      {
        group: ENTREPRENEUR_USER_GROUP_ID,
      },
      { root: true },
    );

    // Load affiliate
    if (isSelfAssessmentFlowStart || isAffiliateFlowStart) {
      if (
        !(
          state.affiliate &&
          (state.affiliate.id === affiliateId ||
            state.affiliate.slug === affiliateId)
        )
      ) {
        await dispatch(EViralLevelActions.FETCH_AFFILIATE, affiliateId, {
          root: true,
        });
      }

      if (state.affiliate) {
        const isAffiliateProgram =
          state.affiliate.flow_type === AFFILIATE_TYPE_PROGRAM;
        const isAffiliateForSupporters =
          state.affiliate?.flow_target === AFFILIATE_TARGET_SUPPORTER;

        // This will reset the state of the module in order to
        // have a clean state before the user perform a new
        // self-assessment
        // But only reset when there's no pending assessment
        if (!state.pendingUser && !userManager.isLogged()) {
          await dispatch(EViralLevelActions.RESET, null, { root: true });
        } else {
          // Resume assessment at the beginning
          await dispatch(EViralLevelActions.SET_SELECTED_CATEGORY, 0, {
            root: true,
          });
        }

        if (skipStartingPage) {
          // If skip, send to assessment page of specific flow
          const nextPage = isAffiliateProgram
            ? AFFILIATE_PROGRAM_ASSESSMENT
            : SELF_ASSESSMENT_ASSESSMENT;

          delete currentRoute.query.l;

          router.push({
            name: nextPage,
            query: currentRoute.query,
          });
        } else if (isSelfAssessmentFlowStart && isAffiliateProgram) {
          // In normal self-assessment flow and is affiliate flow, redirect to correct affiliate page
          if (isAffiliateForSupporters) {
            return router.push({
              name: SUPPORTERS_STARTING_POINT_PROGRAM,
              query: currentRoute.query,
            });
          } else {
            return router.push({
              name: AFFILIATE_PROGRAM_STARTING_POINT,
              query: currentRoute.query,
            });
          }
        } else if (isAffiliateFlowStart && !isAffiliateProgram) {
          // In normal affiliate flow and is self assessment, redirect
          router.push({
            name: SELF_ASSESSMENT_STARTING_POINT,
            query: currentRoute.query,
          });
        }
      } else {
        if (currentRoute.name !== SELF_ASSESSMENT_STARTING_POINT) {
          // Affiliate not loaded, go to normal assessment
          router.push({
            name: SELF_ASSESSMENT_STARTING_POINT,
          });
        }
      }
    }

    commit(EViralLevelMutations.SET_LOADING, false);
  },
};
