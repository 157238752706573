<template>
  <div class="milestone-carousel-description">
    <div
      v-for="milestone in category.milestones"
      :key="milestone.level"
      :class="[
        {
          'milestone-carousel-description--above-levels': !milestone.completed,
        },
      ]"
      :data-testid="`milestone-description-${milestone.level}`"
    >
      <p
        :class="[
          {
            'milestone-carousel-description__text--long-text':
              milestone.description.length > 72,
          },
        ]"
        class="milestone-carousel-description__text"
      >
        {{ milestone.description }}
      </p>
      <ElTooltip
        v-if="milestone.description.length > 72"
        :content="milestone.description"
        data-testid="milestone-carousel-description-tooltip"
        placement="top"
        popper-class="milestone-carousel-description__tooltip el-abaca-tooltip"
      >
        <p class="milestone-carousel-description__tooltip-text">
          {{ $t("common.more") }}
        </p>
      </ElTooltip>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { IGridCategory } from "@/modules/milestone-planner/components/milestones-grid/milestones-grid.interface";

export default defineComponent({
  name: "MilestoneCarouselDescription",

  props: {
    category: {
      type: Object as () => IGridCategory,
      required: true,
    },
  },
});
</script>

<style lang="scss" scoped>
.milestone-carousel-description {
  display: flex;
  flex-direction: column-reverse;
  align-self: flex-end;
  justify-content: space-between;
  height: 100%;

  div {
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: flex-start;
    max-width: 440px;
  }

  &--above-levels p {
    color: $manatee;
  }

  &__text {
    display: inline-block;
    margin-left: 3px;
    font-size: 14px;
    line-height: 24px;
    color: $ebony-clay;
    letter-spacing: -0.3px;

    &--long-text {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
</style>

<style lang="scss">
.milestone-carousel-description__tooltip {
  max-width: 340px;
  text-transform: none;

  &-text {
    @include grotesk(semiBold);

    display: inline-block;
    font-size: 14px;
    line-height: 18px;
    text-decoration: underline;

    &:hover {
      cursor: pointer;
    }
  }
}
</style>
