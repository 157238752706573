import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-35ca9dc5"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "pxNumeric" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ElInput = _resolveComponent("ElInput")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ElInput, {
      modelValue: _ctx.innerValue,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.innerValue) = $event)),
      name: _ctx.name,
      placeholder: _ctx.placeholderText,
      class: "pxNumeric-input",
      formatter: 
        (value) => {
          // Remove non-numeric characters and format with commas
          return value.replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        }
      ,
      parser: 
        (value) => {
          // Remove non-numeric characters
          return value.replace(/\D/g, '');
        }
      
    }, null, 8, ["modelValue", "name", "placeholder", "formatter", "parser"])
  ]))
}