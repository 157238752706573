<template>
  <RenderlessSupporterAssessment
    v-slot="scope"
    :end-action="endAction || defaultEndAction"
  >
    <div>
      <AssessmentDesktop
        v-if="$screen.mdUp"
        :current-category="scope.currentCategory"
        :is-first-interaction="scope.isFirstInteraction"
        :levels="scope.levels"
        :on-level-change="scope.onLevelChange"
        :on-save="scope.onSaveHandler"
        :range-restriction="scope.rangeRestriction"
        :selected-category="scope.selectedCategory"
        :selected-step="scope.selectedStep"
      />
      <AssessmentMobile
        v-if="$screen.smDown"
        :current-category="scope.currentCategory"
        :is-first-interaction="scope.isFirstInteraction"
        :levels="scope.levels"
        :on-level-change="scope.onLevelChange"
        :on-save="scope.onSaveHandler"
        :range-restriction="scope.rangeRestriction"
        :selected-category="scope.selectedCategory"
        :selected-step="scope.selectedStep"
      />
    </div>
  </RenderlessSupporterAssessment>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import RenderlessSupporterAssessment from "@/modules/common/components/renderless-supporter-assessment/renderless-supporter-assessment.vue";
import AssessmentDesktop from "@/modules/supporters/components/assessment-desktop/assessment-desktop.vue";
import AssessmentMobile from "@/modules/supporters/components/assessment-mobile/assessment-mobile.vue";

import { SUPPORTERS_AUTHENTICATION_SIGNUP } from "@/modules/supporters/services/router/routes-names";
import { PropType } from "vue";

export default defineComponent({
  name: "Assessment",
  components: {
    RenderlessSupporterAssessment,
    AssessmentDesktop,
    AssessmentMobile,
  },

  props: {
    /**
     * This prop allows to the the end action for when the
     * user riches the end of the assessment.
     */
    endAction: {
      type: Function as PropType<() => any>,
      default: null,
    },
  },

  methods: {
    defaultEndAction() {
      this.$router.push({
        name: SUPPORTERS_AUTHENTICATION_SIGNUP,
      });
    },
  },
});
</script>
