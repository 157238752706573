import { Module } from "vuex";
import { IMatchingInterestState } from "./matching-interest.types";
import { RootState } from "@/services/store/root-state";
import { make } from "vuex-pathify";
import { matchingInterestActions } from "./matching-interest.actions";

const state: IMatchingInterestState = {
  data: [],

  userMatchScores: [],
  userMatchScoresPage: 1,
  userMatchScoresCount: 0,

  targetMatchScores: [],
  targetMatchScoresPage: 1,
  targetMatchScoresCount: 0,

  mutualMatchScores: [],
  mutualMatchScoresPage: 1,
  mutualMatchScoresCount: 0,

  error: null,
  loading: false,
  canMatch: false,
};

export const matchingInterestModule: Module<IMatchingInterestState, RootState> =
  {
    namespaced: true,
    state,
    actions: matchingInterestActions,
    mutations: {
      ...make.mutations(state),
    },
  };
