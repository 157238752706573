<template>
  <div class="matching-empty-results">
    <div class="matching-empty-results__icon">
      <PxIcon :name="$t(icon)" size="92" />
    </div>
    <h2 class="matching-empty-results__title">
      {{ $t(title, { targetUser }) }}
    </h2>
    <i18n-t
      :keypath="subtitle"
      class="matching-empty-results__subtitle"
      tag="p"
    >
      <template #discoverButton>
        <PxButton class="link" type="link" @click="goToMatches">
          {{ $t("matching.matchingInterest.matchingList.linkToDiscover") }}
        </PxButton>
      </template>
      <template #questionsButton>
        <PxButton
          v-if="$screen.lgUp"
          class="link"
          type="link"
          @click="openQuestionsSidebar"
        >
          {{ $t("matching.matchingInterest.matchingList.linkToQuestions") }}
        </PxButton>
        <template v-else>
          {{ $t("matching.matchingInterest.matchingList.linkToQuestions") }}
        </template>
      </template>
      <template #targetUser>
        {{ targetUser }}
      </template>
    </i18n-t>
    <PxNotice
      v-if="notice"
      :icon="emptyStateIcon"
      :title="$t(noticeTitle)"
      direction="horizontal"
    >
      <template #cta>
        <PxButton size="small" type="default" @click="onClickOpenFeedbackPage">
          {{ $t(noticeButton) }}
        </PxButton>
      </template>
    </PxNotice>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import { AFFILIATE_SUPPORTER_PROGRAM_UPDATE_URL } from "@/modules/affiliates/constants";

export default defineComponent({
  name: "MatchingEmptyResults",

  props: {
    icon: {
      type: String,
      required: false,
      default: "matching.matchingList.noMoreState.icon",
    },
    title: {
      type: String,
      required: false,
      default: "matching.matchingList.noMoreState.title",
    },
    subtitle: {
      type: String,
      required: false,
      default: "matching.matchingList.noMoreState.subtitle",
    },
    notice: {
      type: Boolean,
      required: false,
      default: true,
    },
    noticeTitle: {
      type: String,
      required: false,
      default: "matching.matchingList.noMoreState.notice.title",
    },
    noticeButton: {
      type: String,
      required: false,
      default: "matching.matchingList.emptyState.notice.button",
    },
  },

  computed: {
    userId(): number | null {
      return this.$store.state.auth.profileId;
    },

    userEmail(): string | undefined {
      return this.$store.state.auth.user?.email;
    },

    targetUser(): string {
      const oppositeUserType = +!this.$user.getUserAccountType();
      return `${this.$t(`common.userTypes[${oppositeUserType}]`)}s` as string;
    },

    emptyStateIcon(): string {
      return this.$user.isEntrepreneur() ? "speech-bubble" : "gear";
    },
  },

  methods: {
    onClickOpenFeedbackPage() {
      this.$user.isSupporter()
        ? window.open(AFFILIATE_SUPPORTER_PROGRAM_UPDATE_URL)
        : window.open(`https://info302232.typeform.com/to/IoMvns`);
    },

    goToMatches() {
      this.$emitter.emit("go-to-matches");
    },

    openQuestionsSidebar() {
      this.$emitter.emit("open-questions-sidebar");
    },
  },
});
</script>

<style lang="scss">
.matching-empty-results {
  text-align: center;

  @include breakpoint-up(md) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 512px;
    min-height: 323px;
  }

  .matching-empty-results__subtitle :deep() .el-button {
    padding: 0;
  }
}

.matching-empty-results__icon {
  padding-bottom: 16px;
}

.matching-empty-results__title {
  @include grotesk(semiBold);

  font-size: 16px;
  line-height: 19px;
  color: $ebony-clay;

  @include breakpoint-up(md) {
    margin: 0;
    font-size: 17px;
    line-height: 20px;
  }
}

.matching-empty-results__subtitle {
  @include grotesk(medium);

  padding: 0 20px;
  margin-top: 9px;
  font-size: 15px;
  line-height: 22px;
  color: $manatee;
  letter-spacing: -0.2px;
  opacity: 0.7;

  .link {
    height: auto;
    padding-top: 0;
    font-weight: 500;
    color: $bluish;
  }

  .link:hover {
    filter: brightness(0);
  }
}
</style>
