import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-5037c253"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "criteria-questions__container" }
const _hoisted_2 = { class: "navigation-header__container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PxIcon = _resolveComponent("PxIcon")!
  const _component_PxButton = _resolveComponent("PxButton")!
  const _component_QuestionPanel = _resolveComponent("QuestionPanel")!
  const _component_NavigationFooter = _resolveComponent("NavigationFooter")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_ctx.viewLoading)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _createElementVNode("div", _hoisted_2, [
            (_ctx.hasPreviousPage)
              ? (_openBlock(), _createBlock(_component_PxButton, {
                  key: 0,
                  circle: "",
                  class: "navigation-header__return-button",
                  onClick: _ctx.onPreviousQuestionButtonClick
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_PxIcon, {
                      name: "arrow--left-dark",
                      size: "14"
                    })
                  ]),
                  _: 1
                }, 8, ["onClick"]))
              : _createCommentVNode("", true)
          ]),
          (_ctx.hasCurrentQuestion)
            ? (_openBlock(), _createBlock(_component_QuestionPanel, {
                key: 0,
                modelValue: _ctx.currentResponse,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.currentResponse) = $event)),
                question: _ctx.currentQuestion,
                step: _ctx.step,
                "text-area-limit": _ctx.textAreaLimit,
                total: _ctx.questions.length,
                class: "question-flow__panel",
                onValidate: _ctx.validateResponseState
              }, null, 8, ["modelValue", "question", "step", "text-area-limit", "total", "onValidate"]))
            : _createCommentVNode("", true)
        ], 64))
      : _createCommentVNode("", true),
    _createVNode(_component_NavigationFooter, null, {
      left: _withCtx(() => [
        (_ctx.skipEnabled)
          ? (_openBlock(), _createBlock(_component_PxButton, {
              key: 0,
              onClick: _ctx.onSkipButtonClick
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.ctaCopy.skip), 1)
              ]),
              _: 1
            }, 8, ["onClick"]))
          : _createCommentVNode("", true)
      ]),
      center: _withCtx(() => [
        _createVNode(_component_PxButton, {
          disabled: _ctx.mainCtaSettings.disabled,
          loading: _ctx.formLoading || _ctx.viewLoading,
          type: _ctx.mainCtaSettings.type,
          class: "navigation-footer__main-cta",
          onClick: _ctx.onMainCtaButtonClick
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.mainCtaSettings.copy) + " ", 1),
            (!!_ctx.mainCtaSettings.icon)
              ? (_openBlock(), _createBlock(_component_PxIcon, {
                  key: 0,
                  name: _ctx.mainCtaSettings.icon,
                  size: _ctx.mainCtaSettings.iconSize
                }, null, 8, ["name", "size"]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["disabled", "loading", "type", "onClick"])
      ]),
      right: _withCtx(() => [
        (_ctx.skipEnabled)
          ? (_openBlock(), _createBlock(_component_PxButton, {
              key: 0,
              type: "link",
              onClick: _ctx.onSkipAllButtonClick
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.ctaCopy.setupCriteriaLater), 1)
              ]),
              _: 1
            }, 8, ["onClick"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ]))
}