import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PxButton = _resolveComponent("PxButton")!
  const _component_ElTooltip = _resolveComponent("ElTooltip")!

  return (_ctx.$screen.mdUp)
    ? (_openBlock(), _createBlock(_component_ElTooltip, {
        key: 0,
        ref: "tooltip",
        content: _ctx.tooltipCopy,
        enterable: false,
        "show-arrow": false,
        "auto-close": 1000,
        trigger: "click",
        placement: "top-end",
        "popper-class": "clipboard-button__tooltip el-abaca-tooltip"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_PxButton, {
            icon: _ctx.buttonIcon,
            class: "clipboard-button",
            size: "small",
            type: "primary",
            label: _ctx.buttonLabel,
            onClick: _ctx.onClickButtonHandler
          }, {
            default: _withCtx(() => [
              _createElementVNode("input", {
                ref: "clipboard",
                value: _ctx.clipboard,
                type: "hidden"
              }, null, 8, _hoisted_1)
            ]),
            _: 1
          }, 8, ["icon", "label", "onClick"])
        ]),
        _: 1
      }, 8, ["content"]))
    : _createCommentVNode("", true)
}