import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-b2293e50"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "match-details-modal-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PxButton = _resolveComponent("PxButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_PxButton, _mergeProps(_ctx.backButtonProps, {
      ref: "backButton",
      class: "match-details-modal-footer__back-button",
      onClick: _ctx.onBackClick
    }), null, 16, ["onClick"]),
    _createVNode(_component_PxButton, _mergeProps(_ctx.editAnswersButtonProps, {
      ref: "editButton",
      class: "match-details-modal-footer__edit-button",
      onClick: _ctx.onEditAnswersClick
    }), null, 16, ["onClick"])
  ]))
}