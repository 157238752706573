<template>
  <ul
    :class="`milestone-plan-onboarding-dialog__content--first-page-${userType}`"
    class="milestone-plan-onboarding-dialog__content--first-page"
  >
    <template v-if="!isVisitor">
      <li>
        <i18n-t
          :keypath="`milestonePlanner.milestonePlan.onboardingModal.${userType}.content.firstTab.0`"
          tag="p"
        >
          <template #planTab>
            <strong v-text="onboardingCopy.planTab" />
          </template>
        </i18n-t>
      </li>
      <li>
        <i18n-t
          :keypath="`milestonePlanner.milestonePlan.onboardingModal.${userType}.content.firstTab.1`"
          tag="p"
        >
          <template #breakText>
            <i18n-t
              :keypath="`milestonePlanner.milestonePlan.onboardingModal.${userType}.content.firstTab.2`"
              tag="p"
            >
              <template #optionalStep>
                <strong v-text="onboardingCopy.optionalStep" />
              </template>
            </i18n-t>
          </template>
        </i18n-t>
      </li>
      <li>
        <i18n-t
          :keypath="`milestonePlanner.milestonePlan.onboardingModal.${userType}.content.firstTab.3`"
          tag="p"
        >
          <template #firstIcon>
            <PxButton v-bind="saveAsDraftButtonProps" />
          </template>
          <template #secondIcon>
            <PxButton v-bind="submitPlanButtonProps" />
          </template>
        </i18n-t>
      </li>
      <li>
        <i18n-t
          :keypath="`milestonePlanner.milestonePlan.onboardingModal.${userType}.content.firstTab.4`"
          tag="p"
        >
          <template #firstIcon>
            <MilestoneStageStatusItem
              v-bind="toBePlannedIconProps"
              :is-clickable="false"
              class="to-be-planned-milestone"
            />
          </template>
          <template #secondIcon>
            <MilestoneStageStatusItem
              v-bind="plannedIconProps"
              :is-clickable="false"
              class="planned-milestone"
            />
          </template>
          <template #thirdIcon>
            <PxIcon
              :size="16"
              class="milestone-plan-onboarding-dialog__content--filtered-icon"
              name="plan--planned"
            />
          </template>
        </i18n-t>
      </li>
    </template>
    <template v-else>
      <li>
        <i18n-t
          :keypath="`milestonePlanner.milestonePlan.onboardingModal.${userType}.content.firstTab.0`"
          tag="p"
        >
          <template #planTab>
            <strong v-text="onboardingCopy.planTab" />
          </template>
        </i18n-t>
      </li>
      <li>
        <i18n-t
          :keypath="`milestonePlanner.milestonePlan.onboardingModal.${userType}.content.firstTab.1`"
          tag="p"
        >
          <template #breakText>
            <i18n-t
              :keypath="`milestonePlanner.milestonePlan.onboardingModal.${userType}.content.firstTab.2`"
              tag="p"
            >
              <template #optionalStep>
                <strong v-text="onboardingCopy.optionalStep" />
              </template>
            </i18n-t>
          </template>
        </i18n-t>
      </li>
      <li>
        <i18n-t
          :keypath="`milestonePlanner.milestonePlan.onboardingModal.${userType}.content.firstTab.3`"
          tag="p"
        >
          <template #toBePlannedIcon>
            <MilestoneStageStatusItem
              v-bind="toBePlannedIconProps"
              :is-clickable="false"
              class="to-be-planned-milestone"
            />
          </template>
          <template #breakText>
            <i18n-t
              :keypath="`milestonePlanner.milestonePlan.onboardingModal.${userType}.content.firstTab.4`"
              tag="p"
            >
              <template #plannedIcon>
                <MilestoneStageStatusItem
                  v-bind="plannedIconProps"
                  :is-clickable="false"
                  class="planned-milestone"
                />
              </template>
              <template #planIcon>
                <PxIcon
                  :size="16"
                  class="milestone-plan-onboarding-dialog__content--filtered-icon"
                  name="plan--planned"
                />
              </template>
            </i18n-t>
          </template>
        </i18n-t>
      </li>
    </template>
  </ul>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import MilestoneStageStatusItem from "@/modules/milestone-planner/components/milestone-stage-status-item/milestone-stage-status-item.vue";
import {
  EPxButtonType,
  EPxButtonSize,
  EPxButtonVariant,
} from "@/components/px-button/px-button.types";
import { EMilestoneStatusType } from "@/modules/milestone-planner/services/data/milestones/milestone.interface";
import { MATCHING_USER_TYPES } from "@/modules/matching/constants";

export default defineComponent({
  name: "MilestonePlanOnboardingDialogContentFirstPage",

  components: {
    MilestoneStageStatusItem,
  },

  props: {
    isVisitor: {
      type: Boolean,
      default: false,
    },
  },

  static: {
    saveAsDraftButton: {
      type: EPxButtonType.DEFAULT,
      size: EPxButtonSize.XXX_SMALL,
      variant: EPxButtonVariant.FLUID,
    },
    submitPlanButton: {
      type: EPxButtonType.GREEN,
      size: EPxButtonSize.XXX_SMALL,
      variant: EPxButtonVariant.FLUID,
    },
  },

  computed: {
    onboardingCopy() {
      return this.$tm(
        `milestonePlanner.milestonePlan.onboardingModal.${this.userType}.content.firstTab`,
      ) as {
        [key: string]: string;
      };
    },

    saveAsDraftButtonProps(): any {
      return {
        ...this.$options.static.saveAsDraftButton,
        label: this.$t("milestonePlanner.milestonePlan.buttons.saveAsDraft"),
      };
    },

    submitPlanButtonProps(): any {
      return {
        ...this.$options.static.submitPlanButton,
        label: this.$t("milestonePlanner.milestonePlan.buttons.submitPlan"),
      };
    },

    toBePlannedIconProps(): any {
      return {
        title: this.$t(
          "milestonePlanner.milestoneGrid.stageStatus.toBePlanned",
        ),
        icon: EMilestoneStatusType.TO_BE_PLANNED,
      };
    },

    plannedIconProps(): any {
      return {
        title: this.$t("milestonePlanner.milestoneGrid.stageStatus.planned"),
        icon: EMilestoneStatusType.PLANNED,
      };
    },

    userType(): string {
      return this.isVisitor ? "visitor" : MATCHING_USER_TYPES[0];
    },
  },
});
</script>

<style lang="scss" scoped>
.milestone-plan-onboarding-dialog__content--first-page {
  :deep() .px-stage-status-item {
    display: inline-flex;
    padding: 0;

    .px-icon {
      filter: brightness(0.2);
    }

    .px-stage-status-item__title {
      color: $ebony-clay;
    }
  }

  :nth-child(2) p {
    letter-spacing: -0.3px;

    strong {
      letter-spacing: -0.08px;
    }
  }

  li:nth-child(3) {
    margin-bottom: 2px;
  }

  :nth-child(3) p {
    line-height: 24px;
    letter-spacing: -0.27px;

    .el-button--default {
      position: relative;
      bottom: 1px;
      width: 96px;
      padding: 0 7px;
      margin-right: 3px;
    }

    .el-button--green {
      position: relative;
      margin-right: 3px;
      margin-left: 3px;
    }
  }

  :nth-child(4) p {
    line-height: 16px;
    letter-spacing: -0.3px;

    .px-stage-status-item {
      position: relative;
      top: 9px;
    }

    .to-be-planned-milestone {
      left: 5px;
    }

    .planned-milestone {
      top: 10px;
      left: 2px;
      height: 10px;
      margin-right: 8px;
    }
  }

  &-visitor {
    li:nth-child(3) {
      position: relative;
      bottom: 10px;
    }

    li:nth-child(3) p {
      line-height: 10px;
      letter-spacing: -0.29px;
    }

    li:nth-child(3) p p {
      position: relative;
      right: 1px;
      bottom: 8px;
    }

    li:nth-child(3) .px-stage-status-item {
      top: 10px;
      left: 4px;
    }

    li:nth-child(3) .px-stage-status-item.planned-milestone {
      left: 3px;
      margin-right: 8px;
    }
  }
}
</style>
