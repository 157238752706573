import { IMilestoneReports } from "@/modules/charts-dashboard/services/data/milestone-reports/milestone.interface";
import { IMilestoneReportsState } from "@/modules/charts-dashboard/services/store/milestone/milestone.interface";
import { getGenericStateGetters } from "@/services/store/generic/generic-state.getters";
import { RootState } from "@/services/store/root-state";
import { GetterTree } from "vuex";

export enum EMilestoneReportsGetters {
  VALUE = "getValue",
  VALUES = "getValues",
  ERROR = "getError",
  HAS_ERROR = "hasError",
  IS_LOADING = "isLoading",
}

export const getters: GetterTree<IMilestoneReportsState, RootState> = {
  ...getGenericStateGetters<IMilestoneReports>(),
};
