<template>
  <PxStickyBar class="visitor-join-bar">
    <div class="visitor-join-bar__menu-handle" @click="toggleMenu">
      <PxIcon :name="menuIcon" :size="16" />
    </div>
    <div class="visitor-join-bar__col-start">
      <h2 class="visitor-join-bar__title">
        {{ $t("profile.visitor.stickyBar.titleMobile") }}
      </h2>
      <PxButton
        class="visitor-join-bar__cta-button"
        size="extra-large"
        type="green"
        @click="onClickSignUp"
      >
        {{ $t("profile.visitor.stickyBar.button") }}
      </PxButton>
    </div>
    <div class="visitor-join-bar__col-middle">
      <i18n-t
        class="visitor-join-bar__subtitle"
        keypath="profile.visitor.stickyBar.subtitleMobile"
        tag="div"
      >
        <template #link>
          <PxButton
            class="visitor-join-bar__subtitle-link el-button--link el-button--link-white"
            type="link"
            @click="onClickOpenHowItWorksLink"
          >
            {{ $t("profile.visitor.stickyBar.link") }}
          </PxButton>
        </template>
      </i18n-t>
    </div>
    <div class="visitor-join-bar__col-end">
      <transition name="fade">
        <div v-if="menuOpen" class="visitor-join-bar__content">
          {{ $t("profile.visitor.stickyBar.content") }}
        </div>
      </transition>
    </div>
  </PxStickyBar>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import PxStickyBar from "@/components/px-sticky-bar/px-sticky-bar.vue";

import { ROUTE_AUTHENTICATION_SIGNUP } from "@/modules/authentication/services/router/routes-names";

export default defineComponent({
  name: "VisitorJoinBarMobile",

  components: {
    PxStickyBar,
  },

  data() {
    return {
      menuOpen: false,
    };
  },

  computed: {
    menuIcon(): string {
      return this.menuOpen ? "arrow--down-grey" : "arrow--up-grey";
    },
  },

  methods: {
    toggleMenu() {
      this.menuOpen = !this.menuOpen;
    },

    onClickSignUp() {
      this.$router.push({ name: ROUTE_AUTHENTICATION_SIGNUP });
    },

    onClickOpenHowItWorksLink() {
      window.open(
        "https://medium.com/village-capital/entrepreneurs-and-vcs-need-to-be-more-precise-in-the-way-they-talk-to-each-other-3e714e7a5245",
      );
    },
  },
});
</script>

<style lang="scss" scoped>
.visitor-join-bar :deep() .px-sticky-bar__wrapper {
  padding: 15px;
}

.visitor-join-bar__menu-handle {
  position: absolute;
  top: -18px;
  left: calc(50% - 20px);
  display: flex;
  justify-content: center;
  width: 40px;
  height: 40px;
  padding-top: 6px;
  background-color: $ebony-clay;
  border-top-left-radius: 50%;
  border-top-right-radius: 50%;
}

.visitor-join-bar__col-start {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: -5px;
}

.visitor-join-bar__col-middle {
  margin: 5px;
}

.visitor-join-bar__col-end {
  margin-right: 0;
  margin-left: auto;
  text-align: right;
}

.visitor-join-bar__cta-button {
  min-width: 103px;

  :deep() span {
    font-size: 14px;
  }
}

.visitor-join-bar__title {
  @include grotesk(semiBold);

  max-width: 167px;
  margin: 0;
  font-size: 18px;
  line-height: 26px;
}

.visitor-join-bar__subtitle,
.visitor-join-bar__subtitle-link :deep() span {
  font-size: 13px;
  line-height: 24px;
}

.visitor-join-bar__content {
  width: 100%;
  font-size: 13px;
  line-height: 28px;
  text-align: center;
}

.fade-enter-active,
.fade-leave-active {
  height: 84px;
  transition:
    height 0.2s,
    opacity 0.2s;
}

.fade-enter,
.fade-leave-to {
  height: 0;
  opacity: 0;
}
</style>
