<template>
  <div
    :class="{
      'criteria-question-panel__wrapper--with-input':
        isMoneyType || isQuestionType(numeric),
    }"
    class="criteria-question-panel__wrapper"
  >
    <div class="criteria-question-panel__container">
      <div class="criteria-question-panel__header">
        <p class="criteria-question-panel__description">
          {{ questionTitle }}
        </p>
      </div>
      <div class="criteria-question-panel__content">
        <template v-if="isQuestionType(singleSelect)">
          <PxRadioGroup
            v-model="innerValue.answers[0]"
            class="criteria-question-panel__content-wrapper"
          >
            <div
              v-for="(option, index) in question.answers"
              :key="`criteria-question-panel__option-${index}`"
              class="criteria-question-panel__answer"
            >
              <ElRadio :value="option.id" class="el-radio--checkmark">
                {{ option.value }}
              </ElRadio>
            </div>
          </PxRadioGroup>
        </template>
        <template v-else-if="isQuestionType(multiSelect)">
          <ElCheckboxGroup
            v-model="innerValue.answers"
            class="criteria-question-panel__content-wrapper"
          >
            <div
              v-for="(option, index) in question.answers"
              :key="`criteria-question-panel__option-${index}`"
              class="criteria-question-panel__answer"
            >
              <ElCheckbox :value="option.id">
                {{ option.value }}
              </ElCheckbox>
            </div>
          </ElCheckboxGroup>
        </template>
        <template v-else-if="isMoneyType">
          <div
            v-if="needsCurrencyRange"
            class="criteria-question-panel__content-wrapper criteria-question-panel__content-wrapper--two-column"
          >
            <div class="criteria-question-panel__range">
              <span class="criteria-question-panel__range-label">
                {{ $t("affiliateProgram.questions.rangeFrom") }}
              </span>
              <PxMoney v-model="innerValue.value!.min" />
            </div>
            <div class="criteria-question-panel__range">
              <span class="criteria-question-panel__range-label">
                {{ $t("affiliateProgram.questions.rangeTo") }}
              </span>
              <PxMoney v-model="innerValue.value!.max" />
            </div>
          </div>
          <div v-else class="criteria-question-panel__content-wrapper">
            <PxMoney v-model="innerValue.value!.value" />
          </div>
        </template>
        <template v-else-if="isQuestionType(numeric)">
          <div
            v-if="$user.isEntrepreneur()"
            class="criteria-question-panel__content-wrapper"
          >
            <PxNumeric
              v-model="innerValue.value!.value"
              :placeholder="numericPlaceholder"
            />
          </div>
          <div
            v-if="$user.isSupporter()"
            class="criteria-question-panel__content-wrapper criteria-question-panel__content-wrapper--two-column"
          >
            <div class="criteria-question-panel__range">
              <span class="criteria-question-panel__range-label">
                {{ $t("affiliateProgram.questions.rangeFrom") }}
              </span>
              <PxNumeric
                v-model="innerValue.value!.min"
                :placeholder="numericRangePlaceholder[0]"
              />
            </div>
            <div class="criteria-question-panel__range">
              <span class="criteria-question-panel__range-label">
                {{ $t("affiliateProgram.questions.rangeTo") }}
              </span>
              <PxNumeric
                v-model="innerValue.value!.max"
                :placeholder="numericRangePlaceholder[1]"
              />
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import QuestionPanelMixin from "@/mixins/question-panel.mixin";

export default defineComponent({
  name: "CriteriaQuestionPanel",

  mixins: [QuestionPanelMixin],
});
</script>

<style lang="scss" scoped>
.criteria-question-panel__container {
  margin: 0 auto;
  background-color: $white;
}

.criteria-question-panel__header {
  padding: 19px 29px 17px;
}

.criteria-question-panel__description {
  @include grotesk(semiBold);

  font-size: 16px;
}

.criteria-question-panel__content {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding: 0 29px 32px;

  :deep() .el-input__inner[type="number"]::-webkit-inner-spin-button,
  :deep() .el-input__inner[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
  }
}

.criteria-question-panel__wrapper--with-input {
  .criteria-question-panel__header {
    padding-bottom: 12px;
  }

  .criteria-question-panel__content {
    padding-bottom: 26px;
  }
}

.criteria-question-panel__content-wrapper {
  display: grid;
  grid-template-columns: repeat(2, 50%);
  grid-gap: 8px;
  width: 100%;

  &.criteria-question-panel__content-wrapper--two-column {
    grid-gap: 14px;

    @include breakpoint-up(md) {
      grid-template-columns: repeat(2, 214px);
    }
  }
}

.pxNumeric,
.pxMoney {
  width: 100%;
  background-color: $white;

  border-radius: 2px;
  box-shadow: inset 0 0 5px 0 rgba(0, 0, 0, 0.07);

  :deep(.el-input__prefix) {
    border: none;
  }

  :deep(.el-input__prefix::after) {
    right: -11px;
    top: 0;
  }

  :deep(.el-input__prefix > span > span) {
    color: rgba(139, 143, 161, 0.6);
  }

  :deep(.el-input__inner) {
    font-size: 14px;
    color: $ebony-clay;
  }

  :deep(.el-input__inner::placeholder) {
    font-size: 14px;
    color: $manatee;
  }
}

.criteria-question-panel__range {
  flex: 1 0 100%;
  margin: 5px 0;

  @include breakpoint-up(md) {
    flex: 1 0 40%;
  }

  &-label {
    @include grotesk(semiBold);

    display: block;
    margin-bottom: 3px;
    font-size: 12px;
    color: $manatee;
  }
}

.criteria-question-panel__answer {
  width: 100%;
  cursor: pointer;

  :deep(.el-checkbox) {
    width: 100%;
  }

  :deep(.el-checkbox__input),
  :deep(.el-radio__input) {
    margin-top: 4px;
    vertical-align: top;
  }

  :deep(.el-checkbox__label),
  :deep(.el-radio__label) {
    width: calc(100% - 20px);
    padding-left: 8px;

    hyphens: auto;
    letter-spacing: -0.32px;
    word-break: break-word;
    word-wrap: break-word;
    overflow-wrap: break-word;
    white-space: normal;
  }
}
</style>
