<template>
  <div class="auth-signup__step signup-step-account">
    <PxPanel class="px-panel--compact">
      <template #header>
        <h2
          class="px-panel__title"
          v-html="$t('supporters.signup.form.account')"
        />
      </template>
      <ElForm
        ref="signupForm"
        :model="innerAccount"
        :rules="rules"
        class="auth-signup__form"
        @validate="updateSubmitState"
      >
        <ElFormItem
          :error="errors.getMessage('email')"
          :label="$t('supporters.signup.form.fields.email')"
          prop="email"
        >
          <ElInput
            v-model="innerAccount.email"
            :placeholder="$t('supporters.signup.form.placeholders.email')"
            type="email"
          />
        </ElFormItem>
        <ElFormItem
          :label="$t('supporters.signup.form.fields.password')"
          prop="password1"
        >
          <span
            class="el-form-item__top-text el-form-item__requirement"
            v-text="$t('supporters.signup.form.requirement')"
          />
          <PxInputPassword
            v-model="innerAccount.password1"
            :placeholder="$t('supporters.signup.form.fields.password')"
            :show-success-state="true"
            @input="validateConfirmationField"
          />
          <span
            class="el-form-item__bottom-text el-form-item__tip"
            v-html="$t('supporters.signup.form.tip')"
          />
        </ElFormItem>
        <ElFormItem
          :label="$t('supporters.signup.form.fields.confirmPassword')"
          prop="password2"
        >
          <ElInput
            v-model="innerAccount.password2"
            :disabled="passwordIsInvalid"
            :placeholder="$t('supporters.signup.form.fields.confirmPassword')"
            class="el-input--password"
            type="password"
          />
        </ElFormItem>
        <div class="el-form__bottom-message">
          <p
            class="el-form__notice"
            v-html="$t('supporters.signup.form.notice')"
          />
        </div>
        <div class="el-form__cta">
          <button
            class="el-button el-button--link el-button--small"
            type="button"
            @click="goToPreviousStep"
          >
            <span>
              {{ $t("supporters.signup.form.previousButton") }}
            </span>
          </button>
          <PxButton
            :disabled="submissionDisabled"
            :loading="loading"
            size="medium"
            type="primary"
            @click="submitForm"
          >
            {{ $t("authentication.signup.form.submitButton") }}
          </PxButton>
        </div>
      </ElForm>
    </PxPanel>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import {
  allFormFieldsValid,
  generateEmailValidator,
  generatePasswordCheck,
  generatePasswordValidator,
} from "@/services/errors/validator-generators";
import { FormInstance } from "element-plus";
import { IFormField } from "@/modules/company-lists/components/list-management/list-management-modal.vue";

export default defineComponent({
  name: "SignUpStepAccount",

  props: {
    account: {
      type: Object as () => any,
      required: true,
    },

    errors: {
      type: Object as () => any,
      required: true,
    },

    loading: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      submissionDisabled: true,
      formEl: null as null | FormInstance,

      innerAccount: {
        email: "",
        password1: "",
        password2: "",
      },

      rules: {
        email: generateEmailValidator(
          this,
          "authentication.signup.form.fields.email",
          true,
        ),
        password1: generatePasswordValidator(
          this,
          "authentication.signup.form.fields.password",
          {
            minimum: 8,
            enforceCharRequirements: true,
          },
        ),
        password2: generatePasswordCheck(this, "innerAccount.password1", {
          minimum: 8,
        }),
      },
    };
  },

  computed: {
    formFields(): IFormField[] | null {
      return this.formEl ? ((this.formEl as any).fields as IFormField[]) : null;
    },

    passwordIsInvalid(): boolean {
      if (this.formFields) {
        const formFields = this.formFields;

        const passwordField = formFields.find(
          (field) => field.prop === "password1",
        );

        return passwordField?.validateState !== "success";
      }
      return true;
    },
  },

  mounted() {
    (this as any).formEl = this.$refs.signupForm;
  },

  methods: {
    updateSubmitState() {
      this.submissionDisabled = !allFormFieldsValid(this.formEl, this.rules);

      if (!this.submissionDisabled) {
        this.$emit("update:account", this.innerAccount);
      }
    },

    goToPreviousStep() {
      this.$emit("previous-step");
    },

    submitForm() {
      this.$emit("submit-form");
    },

    validateConfirmationField() {
      if (!this.passwordIsInvalid) {
        this.formEl?.validateField("password2", this.updateSubmitState);
      }
    },
  },
});
</script>
