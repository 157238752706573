<template>
  <div class="locations-selector">
    <div
      :class="{
        'locations-selector__list--with-entries': selectedLocations.length,
      }"
      class="locations-selector__list"
    >
      <label
        v-if="!noLabel"
        class="el-form-item__label locations-selector__title"
      >
        {{ $t(label) }}
      </label>
      <div
        v-for="(location, key) in selectedLocations"
        :key="key"
        class="locations-selector__entry"
      >
        <span class="locations-selector__entry-name">
          {{ location.formatted_address }}
        </span>
        <i class="icon icon-trash--dark" @click="onClickRemoveLocation(key)" />
      </div>
    </div>
    <PxInputPlaces
      id="locationsInput"
      v-model="tempLocation"
      :autocomplete-type="autocompleteType"
      :clearable="true"
      :placeholder="$t(placeholder)"
      :use-geolocation="true"
      class="locations-input"
      @input="onLocationSelect"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { ILocation } from "@/services/data/location/location.interface";

export default defineComponent({
  name: "LocationsSelector",

  props: {
    modelValue: {
      type: Array as () => any[],
      required: true,
    },

    /**
     * When set to `true` the label won't be rendered.
     */
    noLabel: {
      type: Boolean,
      default: false,
    },

    label: {
      type: String,
      default: "supporters.signup.form.fields.locations",
    },

    placeholder: {
      type: String,
      default: "supporters.signup.form.placeholders.locations",
    },

    autocompleteType: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      tempLocation: null as null | ILocation,

      // Will store the list of selected locations
      selectedLocations: [] as Array<ILocation>,
    };
  },

  watch: {
    modelValue: {
      deep: true,
      immediate: true,
      handler(newVal: Array<ILocation>) {
        if (newVal) {
          this.selectedLocations = newVal;
        }
      },
    },

    selectedLocations: {
      immediate: true,
      deep: true,
      handler(newLocations: Array<ILocation>) {
        this.$emit("input", newLocations);
        this.$emit("update:modelValue", newLocations);
      },
    },
  },

  methods: {
    /**
     * When a new location is selected it is pushed into the
     * array of selected locations and the autocomplete fill
     * cleared.
     */
    onLocationSelect(selectedLocation: ILocation) {
      const newLocationToInsert =
        selectedLocation &&
        !this.selectedLocations.some(
          (location: ILocation) =>
            location.formatted_address === selectedLocation.formatted_address,
        );

      if (newLocationToInsert) {
        this.tempLocation = null;
        this.selectedLocations.push(selectedLocation);
        this.$emit("change", selectedLocation);
      } else {
        this.tempLocation = null;
      }
    },

    /**
     * Remove the given location from the list of selected locations.
     */
    onClickRemoveLocation(locationIndex: number) {
      this.selectedLocations.splice(locationIndex, 1);
    },
  },
});
</script>

<style lang="scss" scoped>
.locations-selector__list--with-entries {
  margin-bottom: 12px;
}

.locations-selector__title {
  display: block;
}

.locations-selector__entry {
  display: flex;
  justify-content: space-between;
  padding: 7px 15px 8px 16px;
  margin-bottom: 12px;
  clear: both;

  font-size: 0.9333rem;
  text-transform: capitalize;

  background-color: $athens-gray;
  border-radius: 2px;

  &-name {
    max-width: 264px;
  }

  &:last-child {
    margin-bottom: 0;
  }

  span {
    color: $gun-powder;
  }

  i {
    position: relative;
    top: 6px;
    width: 14px;
    height: 14px;

    cursor: pointer;
  }

  i:hover {
    background-image: url("#{$assetsPath}/img/icons/trash.svg");
  }
}

.locations-selector__select {
  display: block;

  & :deep() .el-input__inner {
    padding-left: 52px;

    background: $white;
  }

  & :deep() .el-input::before {
    position: absolute;
    top: 50%;
    left: 8px;
    width: 24px;
    height: 24px;

    content: "";

    background-image: url("#{$assetsPath}/img/icons/magnifier.svg");
    background-repeat: no-repeat;
    background-size: contain;

    transform: translateY(-50%);
  }

  & :deep() .el-input::after {
    position: absolute;
    top: 50%;
    left: 40px;
    width: 1px;
    height: 28px;

    content: "";

    background-color: $athens-gray;

    transform: translateY(-50%);
  }

  & :deep() .el-select__caret {
    display: none;
  }
}

.locations-selector__select-options :deep() span {
  text-transform: capitalize;
}
</style>
