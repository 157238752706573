<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="173"
    height="173"
    viewBox="0 0 173 173"
  >
    <defs>
      <linearGradient id="viral-graph-7-c" x1="50%" x2="50%" y1="0%" y2="100%">
        <stop offset="0%" stop-color="#58A0F8" class="linear-gradient-color1" />
        <stop
          offset="100%"
          stop-color="#2176DD"
          class="linear-gradient-color2"
        />
      </linearGradient>
      <path
        id="viral-graph-7-b"
        d="M142.743222,20.5639148 L153.069166,32.0991811 C145.559084,38.2938051 139.524289,46.1136721 135.462358,54.9528587 L121.706324,48.6385262 C126.723381,37.7208862 133.478604,28.2057524 142.743222,20.5639148 Z"
      />
      <filter
        id="viral-graph-7-a"
        width="163.8%"
        height="158.2%"
        x="-31.9%"
        y="-23.3%"
        filterUnits="objectBoundingBox"
      >
        <feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur
          in="shadowOffsetOuter1"
          result="shadowBlurOuter1"
          stdDeviation="3"
        />
        <feComposite
          in="shadowBlurOuter1"
          in2="SourceAlpha"
          operator="out"
          result="shadowBlurOuter1"
        />
        <feColorMatrix
          in="shadowBlurOuter1"
          values="0 0 0 0 0.333333333 0 0 0 0 0.623529412 0 0 0 0 0.976470588 0 0 0 0.5 0"
          class="color-matrix"
        />
      </filter>
      <linearGradient id="viral-graph-7-f" x1="50%" x2="50%" y1="0%" y2="100%">
        <stop offset="0%" stop-color="#64A9FE" class="linear-gradient-color3" />
        <stop
          offset="100%"
          stop-color="#1865C4"
          class="linear-gradient-color2"
        />
      </linearGradient>
      <path
        id="viral-graph-7-e"
        d="M78.30625,134.548387 C109.955618,134.548387 135.6125,108.905857 135.6125,77.2741935 C135.6125,45.6425299 109.955618,20 78.30625,20 C46.6568821,20 21,45.6425299 21,77.2741935 C21,108.905857 46.6568821,134.548387 78.30625,134.548387 Z M78.30625,134.548387 C109.955618,134.548387 135.6125,108.905857 135.6125,77.2741935 C135.6125,45.6425299 109.955618,20 78.30625,20 C46.6568821,20 21,45.6425299 21,77.2741935 C21,108.905857 46.6568821,134.548387 78.30625,134.548387 Z M78.30625,134.548387 C109.955618,134.548387 135.6125,108.905857 135.6125,77.2741935 C135.6125,45.6425299 109.955618,20 78.30625,20 C46.6568821,20 21,45.6425299 21,77.2741935 C21,108.905857 46.6568821,134.548387 78.30625,134.548387 Z"
      />
      <filter
        id="viral-graph-7-d"
        width="141%"
        height="141%"
        x="-20.5%"
        y="-18.8%"
        filterUnits="objectBoundingBox"
      >
        <feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur
          in="shadowOffsetOuter1"
          result="shadowBlurOuter1"
          stdDeviation="7.5"
        />
        <feComposite
          in="shadowBlurOuter1"
          in2="SourceAlpha"
          operator="out"
          result="shadowBlurOuter1"
        />
        <feColorMatrix
          in="shadowBlurOuter1"
          values="0 0 0 0 0.325490196 0 0 0 0 0.584313725 0 0 0 0 0.894117647 0 0 0 0.5 0"
          class="color-matrix"
        />
      </filter>
      <linearGradient
        id="viral-graph-7-g"
        x1="50%"
        x2="50%"
        y1="0%"
        y2="98.69%"
      >
        <stop offset="0%" stop-color="#FFF" />
        <stop
          offset="100%"
          stop-color="#D5E3F4"
          stop-opacity="0"
          class="linear-gradient-color2"
        />
      </linearGradient>
    </defs>
    <g fill="none" fill-rule="evenodd" transform="translate(8 18)">
      <path
        fill="#E6E8EF"
        fill-rule="nonzero"
        d="M144.781264,77.3612903 C144.722765,69.8120855 143.408588,62.4497594 140.925929,55.4962966 L150.852434,51.4855294 C153.841781,59.7091601 155.42013,68.430105 155.479928,77.3612903 L144.781264,77.3612903 Z M144.738308,80.3612903 L155.442824,80.3612903 C155.129758,90.3067662 152.929825,99.9606146 148.998018,108.919826 L139.201498,104.62056 C142.541755,97.0092853 144.429974,88.8166052 144.738297,80.3612907 Z M139.853133,52.6941289 C139.84517,52.6746627 139.837197,52.6552 139.829215,52.6357408 L149.727241,48.5756226 C149.742362,48.6124839 149.757453,48.6493563 149.772515,48.6862397 L139.853133,52.6941289 Z"
      />
      <path
        class="linear-gradient-color4"
        fill="#7CB8FF"
        fill-rule="nonzero"
        d="M121.627217,27.4085662 C114.774401,21.5005622 106.832825,17.0683222 98.250994,14.3575549 L101.069357,4.02933809 C111.25806,7.1862093 120.682235,12.4287436 128.780134,19.4503394 L121.627217,27.4085662 Z M123.855121,29.4176627 L131.00849,21.458932 C131.506968,21.9259393 131.999885,22.4000083 132.487093,22.8810646 L124.970405,30.4938751 C124.602373,30.1304904 124.230584,29.7717389 123.855118,29.4176656 Z M95.3701749,13.5188316 C89.8211804,12.0386842 84.0329931,11.2669355 78.12125,11.2669355 C73.4777243,11.2669355 68.9204192,11.7456186 64.5041736,12.6635718 L61.9693804,2.26339977 C67.2034369,1.14983439 72.6101239,0.568548387 78.12125,0.568548387 C84.9964585,0.568548387 91.7306864,1.46980516 98.1866841,3.19740822 L95.3701749,13.5188316 Z M61.5812781,13.3402443 C52.9018226,15.5570751 44.8322281,19.4871653 37.8078542,24.8176203 L31.1254037,16.4591513 C39.3591544,10.1652434 48.8417163,5.53452719 59.0476011,2.9446518 L61.5812781,13.3402443 Z M35.4593928,26.6843822 C28.4705063,32.5007667 22.6440902,39.7666877 18.4566274,48.1396953 L8.80763242,43.5157939 C13.7034998,33.6691945 20.5515004,25.1353947 28.7776995,18.3268603 L35.4593928,26.6843822 Z M11.46423,77.3612903 L0.759577509,77.3612903 C0.832369057,66.4918794 3.15474387,55.93948 7.52212001,46.2264405 L17.1777561,50.8535244 C13.4968533,59.128084 11.5359905,68.1024282 11.4642307,77.3612903 Z M11.5071998,80.3612907 C11.804143,88.504176 13.5663271,96.4019373 16.6765827,103.76782 L6.82377692,107.949548 C3.15527076,99.2691332 1.09861848,89.9520014 0.796686673,80.3612906 L11.5071899,80.3612903 Z"
      />
      <path
        fill="#FFF"
        fill-rule="nonzero"
        d="M159.827 111.148L158.698 113.839 77.185 79.642 78.313 76.952z"
      />
      <g fill-rule="nonzero" transform="rotate(108 137.388 37.758)">
        <use
          fill="#000"
          filter="url(#viral-graph-7-a)"
          xlink:href="#viral-graph-7-b"
        />
        <use fill="url(#viral-graph-7-c)" xlink:href="#viral-graph-7-b" />
      </g>
      <use
        fill="#000"
        filter="url(#viral-graph-7-d)"
        xlink:href="#viral-graph-7-e"
      />
      <ellipse
        cx="78.338"
        cy="77.565"
        rx="56.819"
        ry="56.79"
        fill="url(#viral-graph-7-f)"
        stroke="#181818"
        stroke-linejoin="round"
        stroke-opacity=".2"
      />
      <ellipse
        cx="78.338"
        cy="77.565"
        stroke="url(#viral-graph-7-g)"
        stroke-width="2"
        opacity=".402"
        rx="49.819"
        ry="49.79"
      />
      <path
        fill="#FFF"
        d="M66.306 97L84.763 60.64 62.83 60.64 62.83 53.534 94.207 53.534 94.207 57.502 74.919 97z"
      />
    </g>
  </svg>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "Level7",
});
</script>
