import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-2b4b1a5a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "features-showcase" }
const _hoisted_2 = { class: "features-showcase__headline" }
const _hoisted_3 = { class: "features-showcase__list" }
const _hoisted_4 = { class: "features-showcase__content" }
const _hoisted_5 = { class: "features-showcase__item-title" }
const _hoisted_6 = ["textContent"]
const _hoisted_7 = {
  key: 0,
  class: "features-showcase__thumbnail"
}
const _hoisted_8 = ["srcset"]
const _hoisted_9 = ["srcset"]
const _hoisted_10 = ["srcset"]
const _hoisted_11 = ["alt", "src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FeaturesShowcaseCTA = _resolveComponent("FeaturesShowcaseCTA")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h2", _hoisted_2, _toDisplayString(_ctx.$t("supporters.results.featuresShowcase.headline")), 1),
    _createElementVNode("div", _hoisted_3, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.featuresList, (item, feature) => {
        return (_openBlock(), _createElementBlock("div", {
          key: feature,
          class: _normalizeClass([`features-showcase__item--${feature}`, "features-showcase__item"])
        }, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("h3", _hoisted_5, _toDisplayString(item.title), 1),
            _createElementVNode("div", {
              class: "features-showcase__item-description",
              textContent: _toDisplayString(item.description)
            }, null, 8, _hoisted_6)
          ]),
          (item.thumbnail)
            ? (_openBlock(), _createElementBlock("picture", _hoisted_7, [
                (item.thumbnail.mobile)
                  ? (_openBlock(), _createElementBlock("source", {
                      key: 0,
                      srcset: `${item.thumbnail.mobile} 1x`,
                      media: "(max-width: 767px)"
                    }, null, 8, _hoisted_8))
                  : _createCommentVNode("", true),
                (item.thumbnail.mobileRetina)
                  ? (_openBlock(), _createElementBlock("source", {
                      key: 1,
                      srcset: `${item.thumbnail.mobileRetina} 2x`,
                      media: "(max-width: 767px)"
                    }, null, 8, _hoisted_9))
                  : _createCommentVNode("", true),
                (item.thumbnail.retina)
                  ? (_openBlock(), _createElementBlock("source", {
                      key: 2,
                      srcset: `${item.thumbnail.original} 1x, ${item.thumbnail.retina} 2x`
                    }, null, 8, _hoisted_10))
                  : _createCommentVNode("", true),
                _createElementVNode("img", {
                  alt: item.title,
                  src: item.thumbnail.original
                }, null, 8, _hoisted_11)
              ]))
            : _createCommentVNode("", true)
        ], 2))
      }), 128))
    ]),
    _createVNode(_component_FeaturesShowcaseCTA)
  ]))
}