<template>
  <div
    :class="{ 'questions-panel--scroll': !scrollAtTop }"
    class="questions-panel"
  >
    <div ref="panel" class="questions-panel__wrapper" @scroll="checkScroll">
      <ElCollapse :model-value="questions[0].id" :accordion="true">
        <template v-for="(question, index) in questions">
          <CollapsibleQuestion
            v-if="question.id"
            :key="question.id"
            :data-testid="`questions-panel--${question.id}`"
            :grouped-options="false"
            :question="question"
            :response="currentResponses[index]"
            :show-success-state="true"
            @handle-new-response="updateResponses"
            @show-instructions-modal="openModal"
          />
        </template>
      </ElCollapse>
    </div>
    <ElDialog
      v-model="showModal"
      :append-to-body="true"
      :title="modalTitle"
      class="questions-panel__modal"
      top="0"
    >
      <p>
        {{ modalText }}
      </p>
    </ElDialog>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import CollapsibleQuestion from "@/components/collapsible-question/collapsible-question.vue";
import { IMatchingResponse } from "@/services/data/matching-responses/matching-response.interface";
import { IMatchingQuestion } from "@/services/data/matching-questionary/matching-question.interface";
import { ICapitalExplorerData } from "@/modules/capital-explorer/services/data/capital-explorer/capital-explorer.interface";

export default defineComponent({
  name: "QuestionsPanel",

  components: {
    CollapsibleQuestion,
  },

  props: {
    questions: {
      type: Array as () => Array<IMatchingQuestion>,
      required: true,
    },

    reset: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      currentResponses: [] as Array<IMatchingResponse>,
      validResponses: [] as Array<IMatchingResponse>,
      scrollAtTop: true,
      panel: (null as any) || null,
      showModal: false,
      modalTitle: "",
      modalText: "",
    };
  },

  watch: {
    reset: {
      handler(shouldReset: boolean) {
        if (!shouldReset) return;

        this.currentResponses = this.questions.map((question) => {
          return {
            question: question.id,
            value: {},
            answers: [],
          };
        });

        this.$emit("update:reset", false);
      },
    },
  },

  created() {
    this.currentResponses = this.questions.map((question) => {
      return {
        question: question.id,
        value: {},
        answers: [],
      };
    });

    const capitalExplorerData = this.$store.get(
      "capitalExplorer/data",
    ) as ICapitalExplorerData;

    for (const response of capitalExplorerData.submission.responses) {
      const currentResponse = this.currentResponses.find(
        ({ question }) => question === response.question,
      );
      if (currentResponse) {
        currentResponse.value = response.value;
        currentResponse.answers = response.answers;
      }
    }
  },

  beforeUnmount() {
    this.currentResponses = [];
    this.panel = null;
  },

  methods: {
    checkValidResponse(response: IMatchingResponse) {
      return (
        !!response.answers?.length ||
        !!response.value?.value ||
        !!response.value?.date ||
        (!!response?.value?.max &&
          !!response?.value?.min &&
          response?.value?.max > response?.value?.min) ||
        (!!response.value?.text && response.value?.text.length < 1500)
      );
    },

    updateResponses(isValid: boolean, newResponse: IMatchingResponse) {
      this.currentResponses =
        this.currentResponses?.map((response: IMatchingResponse) => {
          return response.question === newResponse.question
            ? newResponse
            : response;
        }) || [];

      // filter currentResponses to only include valid responses
      this.validResponses = this.currentResponses?.filter(
        (response: IMatchingResponse) => {
          return this.checkValidResponse(response);
        },
      );

      this.$emit("update-responses", this.validResponses);
    },

    checkScroll() {
      this.panel = this.$refs.panel as HTMLElement;

      if (!this.panel) return;

      this.scrollAtTop = this.panel.scrollTop === 0;
    },

    openModal(question: IMatchingQuestion) {
      this.modalTitle = this.$user.isEntrepreneur()
        ? question.entrepreneur_question
        : question.resource_question;
      this.modalText = question.instructions || "";
      this.showModal = true;
    },
  },
});
</script>

<style lang="scss" scoped>
.questions-panel {
  overflow: hidden;

  &--scroll {
    border-top: 1px solid $mischka;
  }

  &__wrapper {
    margin: 0 6px 0 0;
    padding: 7px 13px 30px 24px;
    height: 100%;

    overflow-y: scroll;

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
      padding: 0 10px;
    }

    &::-webkit-scrollbar-thumb {
      background: $mischka;
      border-radius: 10px;
    }
  }

  :deep(collapsible-question:not(:last-of-type)) {
    margin-bottom: 16px;
  }

  :deep(.collapsible-question__text h4) {
    font-size: 15px;
    line-height: 1.33;
  }

  :deep(.collapsible-question__options .question-panel__container .el-radio) {
    grid-template-columns: auto 27px;
  }

  :deep(.collapsible-question .el-collapse-item__header.is-active) {
    padding: 16px 15px 12px;
    min-height: 100%;
  }

  :deep(.collapsible-question__answer--italic) {
    letter-spacing: -0.2px;
  }

  :deep(.collapsible-question__answer) {
    margin: 4px 0 0 0;
  }

  :deep(.el-radio-group.question-panel__content-wrapper) {
    min-width: 100%;
  }

  :deep(.collapsible-question .el-collapse-item__header) {
    padding: 16px 15px 11px;
    background-color: transparent;
  }

  :deep(.el-collapse-item__wrap) {
    background: transparent;
  }

  :deep(.collapsible-question .el-collapse-item__header.is-active h4) {
    padding: 0;
  }

  :deep(.collapsible-question .el-collapse-item__header::before) {
    margin: 0;
    top: 15px;
  }

  :deep(.collapsible-question .el-collapse-item__header.is-active::before) {
    bottom: 0;
  }

  :deep(.collapsible-question div[role="region"]) {
    margin: 0 0 0 4px;
  }
}
</style>
<style lang="scss">
.questions-panel__modal {
  min-width: 500px;
  margin: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  .el-dialog__header {
    padding: 23px 58px 25px 40px;
  }

  .el-dialog__title {
    font-size: 22px;
    line-height: 1.2;
    letter-spacing: -0.29px;
  }

  .el-dialog__headerbtn {
    top: 28px;
    right: 28px;
  }

  .el-dialog__body p {
    font-size: 15px;
    line-height: 1.47;
    color: $ebony-clay;
    letter-spacing: -0.33px;
  }

  .el-dialog__body {
    padding: 40px 40px 37px;
    max-height: 500px;

    overflow-y: scroll;

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
      margin: 30px;
    }

    &::-webkit-scrollbar-thumb {
      background: $mischka;
      border-radius: 10px;
    }
  }
}
</style>
