import { AxiosResponse } from "axios";
import { BaseProviderException } from "./base-provider.exception";

/**
 * This is a generic error type for the provider.
 */
export class GenericProviderException extends BaseProviderException {
  constructor(
    errorResponse: AxiosResponse,
    message = "Generic Provider Error",
  ) {
    super(errorResponse, message);
  }
}
