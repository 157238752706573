import { IBaseState } from "../store-types";

export interface IMetaPayload {
  key: string;
  value: any;
  group: number;
}

/**
 * Object type to be used to store metadata.
 */
export interface TMeta {
  [key: string]: any;
}

/**
 * Interface that represents the meta module state.
 */
export interface IMetaState extends IBaseState {
  data: TMeta;
}

/**
 * Enumeration with all supported actions by this modules.
 */
export enum EMetaActions {
  SET = "meta/set",
  REMOVE = "meta/remove",
}
/**
 * Enumeration with all supported mutations by this modules.
 */
export enum EMetaMutations {
  SET = "set",
  REMOVE = "remove",
}
/**
 * Enumeration with all supported getters by this modules.
 */
export enum EMetaGetters {
  GET = "meta/get",
}
