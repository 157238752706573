import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, mergeProps as _mergeProps, createBlock as _createBlock, Transition as _Transition, normalizeClass as _normalizeClass, Fragment as _Fragment, resolveDirective as _resolveDirective, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-0ef89aaa"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "visitors-milestone-overview page-full-height" }
const _hoisted_2 = { class: "page-body" }
const _hoisted_3 = { class: "page-wrapper" }
const _hoisted_4 = { class: "page-container visitors-milestone-overview__page-container" }
const _hoisted_5 = { class: "visitors-milestone-overview__panel" }
const _hoisted_6 = { class: "visitors-milestone-overview__header" }
const _hoisted_7 = { class: "visitors-milestone-overview__header-left" }
const _hoisted_8 = { class: "visitors-milestone-overview__title" }
const _hoisted_9 = { class: "visitors-milestone-overview__header-right" }
const _hoisted_10 = { class: "visitors-milestone-overview__grid-footer" }
const _hoisted_11 = { class: "visitors-milestone-overview__grid-tip" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PxAvatar = _resolveComponent("PxAvatar")!
  const _component_PxButton = _resolveComponent("PxButton")!
  const _component_PxIcon = _resolveComponent("PxIcon")!
  const _component_MilestonesGrid = _resolveComponent("MilestonesGrid")!
  const _component_MilestoneCarousel = _resolveComponent("MilestoneCarousel")!
  const _component_LastAssessmentDate = _resolveComponent("LastAssessmentDate")!
  const _component_GridLegend = _resolveComponent("GridLegend")!
  const _component_MilestoneSidebar = _resolveComponent("MilestoneSidebar")!
  const _component_OverviewSidebar = _resolveComponent("OverviewSidebar")!
  const _component_PxPanel = _resolveComponent("PxPanel")!
  const _component_MilestoneOnboardingDialog = _resolveComponent("MilestoneOnboardingDialog")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _withDirectives((_openBlock(), _createBlock(_component_PxPanel, null, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_5, [
                (!_ctx.isLoading && _ctx.shouldRenderGrid)
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                      _createElementVNode("div", {
                        class: _normalizeClass([{
                    'visitors-milestone-overview__main--carousel':
                      _ctx.carouselVisible,
                  }, "visitors-milestone-overview__main"])
                      }, [
                        _createElementVNode("div", _hoisted_6, [
                          _createElementVNode("div", _hoisted_7, [
                            (_ctx.companyData)
                              ? (_openBlock(), _createElementBlock("div", {
                                  key: 0,
                                  class: "visitors-milestone-overview__content",
                                  onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.goToOwnerProfile && _ctx.goToOwnerProfile(...args)))
                                }, [
                                  _createVNode(_component_PxAvatar, {
                                    name: _ctx.companyData.name,
                                    photo: _ctx.companyData.logo,
                                    size: 24,
                                    class: "visitors-milestone-overview__content-logo"
                                  }, null, 8, ["name", "photo"]),
                                  _createVNode(_component_PxButton, {
                                    class: "el-button--link el-button--link-dark visitors-milestone-overview__content-title",
                                    type: "link"
                                  }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(_ctx.companyData.name), 1)
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_PxIcon, {
                                    class: "visitors-milestone-overview__content-arrow-separator",
                                    name: "arrow--right-black",
                                    size: "12"
                                  })
                                ]))
                              : _createCommentVNode("", true),
                            _createElementVNode("h1", _hoisted_8, _toDisplayString(_ctx.$t("milestonePlanner.label")), 1),
                            _createVNode(_component_PxIcon, {
                              size: 22,
                              class: "visitors-milestone-overview__onboarding-dialog-trigger",
                              name: "information--light",
                              onClick: _ctx.openOnboardingDialog
                            }, null, 8, ["onClick"])
                          ]),
                          _createElementVNode("div", _hoisted_9, [
                            _createVNode(_component_PxButton, _mergeProps(_ctx.$options.static.maximizeButton, {
                              ref: "showFullGridButton",
                              onClick: _ctx.showFullGrid
                            }), null, 16, ["onClick"])
                          ])
                        ]),
                        _createVNode(_Transition, {
                          mode: "out-in",
                          name: "fade"
                        }, {
                          default: _withCtx(() => [
                            (!_ctx.carouselVisible)
                              ? (_openBlock(), _createBlock(_component_MilestonesGrid, {
                                  key: 0,
                                  ref: "milestonesGrid",
                                  categories: _ctx.gridCategories,
                                  "current-level": _ctx.latestAssessment.level.value,
                                  "is-visitor": !_ctx.$user.isOwner(),
                                  "levels-count": _ctx.categories[0].categoryDetails.length,
                                  class: "visitors-milestone-overview__grid",
                                  onShowCarousel: _ctx.showCarousel
                                }, null, 8, ["categories", "current-level", "is-visitor", "levels-count", "onShowCarousel"]))
                              : (_openBlock(), _createBlock(_component_MilestoneCarousel, {
                                  key: 1,
                                  ref: "milestonesCarousel",
                                  categories: _ctx.gridCategories,
                                  "is-visitor": !_ctx.$user.isOwner(),
                                  "last-assessment-data": _ctx.latestAssessment.data,
                                  "levels-count": _ctx.categories[0].categoryDetails.length,
                                  "selected-category": _ctx.carouselSelectedCategory,
                                  onChangeCategoryLevel: _ctx.changeCarouselCategoryLevel,
                                  onHideCarousel: _ctx.hideCarousel
                                }, null, 8, ["categories", "is-visitor", "last-assessment-data", "levels-count", "selected-category", "onChangeCategoryLevel", "onHideCarousel"]))
                          ]),
                          _: 1
                        }),
                        _createElementVNode("div", _hoisted_10, [
                          _createVNode(_component_LastAssessmentDate, {
                            date: _ctx.lastAssessmentDate,
                            class: "visitors-milestone-overview__last-assessment"
                          }, null, 8, ["date"]),
                          _createElementVNode("div", _hoisted_11, [
                            _createVNode(_component_PxIcon, {
                              size: 16,
                              class: "visitors-milestone-overview__grid-tip-icon",
                              name: "information--malibu"
                            }),
                            _createTextVNode(" " + _toDisplayString(_ctx.$t("milestonePlanner.milestoneGrid.footer.tip")), 1)
                          ]),
                          _createVNode(_component_GridLegend, { class: "visitors-milestone-overview__grid-legend" })
                        ])
                      ], 2),
                      _createVNode(_Transition, {
                        mode: "out-in",
                        name: "fade"
                      }, {
                        default: _withCtx(() => [
                          (_ctx.carouselVisible)
                            ? (_openBlock(), _createBlock(_component_MilestoneSidebar, {
                                key: 0,
                                categories: _ctx.categories,
                                category: _ctx.carouselSelectedCategory,
                                "last-assessment-data": _ctx.latestAssessment.data,
                                onHideCarousel: _ctx.hideCarousel
                              }, null, 8, ["categories", "category", "last-assessment-data", "onHideCarousel"]))
                            : (_openBlock(), _createBlock(_component_OverviewSidebar, {
                                key: 1,
                                "latest-assessment-level": _ctx.latestAssessment.level
                              }, null, 8, ["latest-assessment-level"]))
                        ]),
                        _: 1
                      })
                    ], 64))
                  : _createCommentVNode("", true)
              ])
            ]),
            _: 1
          })), [
            [_directive_loading, _ctx.isLoading]
          ])
        ])
      ])
    ]),
    _createVNode(_component_MilestoneOnboardingDialog, {
      visibility: _ctx.onboardingDialogVisibility,
      "onUpdate:visibility": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.onboardingDialogVisibility) = $event)),
      "is-visitor": !_ctx.$user.isOwner(),
      onCloseDialog: _ctx.closeOnboardingDialog
    }, null, 8, ["visibility", "is-visitor", "onCloseDialog"])
  ]))
}