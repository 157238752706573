import { MutationTree } from "vuex";
import { IMatchingState, EMatchingMutations } from "./matching.types";
import { getBaseName } from "@/services/store/utils/get-base-name";
import { IMatchingScore } from "@/modules/matching/services/data/matching-score/matching-score.interface";

export const matchingMutations: MutationTree<IMatchingState> = {
  /**
   * Append a list of new scores.
   *
   * @param state Module state
   * @param payload Payload with the list of new scores to be append
   * to the current fetched list.
   */
  [getBaseName(EMatchingMutations.SET_APPEND_DATA)](
    state,
    payload: Array<IMatchingScore>,
  ) {
    state.data = [...state.data, ...payload];
  },
};
