import { RouteLocationNormalized } from "vue-router";
import store from "@/services/store";
import { ENTREPRENEUR_USER_TYPE } from "@/modules/authentication/constants";
import { ERROR_ROUTE } from "@/services/router/router-names";
import { EUserGuestGetters } from "@/services/store/user-guest/user-guest.types";
import { userManager } from "@/modules/authentication/user-manager";
import { defaultScreenService } from "@/modules/common/services/screen/screen.service";
import { ERROR_TYPES } from "@/modules/common/components/error-page/constants";
import { CAPITAL_EXPLORER_COMPARISON_TABLE } from "@/modules/capital-explorer/services/router/routes-names";
import { ECapitalExplorerActions } from "@/modules/capital-explorer/services/store/capital-explorer/capital-explorer.types";

/**
 * Guard for Capital Explorer Visitors route.
 *
 * @param {RouteLocationNormalized} to
 * @param {RouteLocationNormalized} from
 * @param {Function} next
 */
export const capitalExplorerVisitorGuard = async (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: Function,
) => {
  if (defaultScreenService.isDown("sm")) {
    return next({
      name: ERROR_ROUTE,
      query: {
        code: ERROR_TYPES.MOBILE,
      },
    });
  }

  let requestParams: object = {
    uid: to.params.uid,
  };

  // Handle request for user guest persisted on the store
  if (!userManager.isLogged()) {
    const userGuest = await store.get(EUserGuestGetters.GET);

    if (userGuest) {
      requestParams = {
        ...requestParams,
        email: userGuest.email,
      };
    }
  }

  await store.dispatch(
    ECapitalExplorerActions.FETCH_USER_SUBMISSION,
    requestParams,
  );

  const error = store.get("capitalExplorer.error");
  const userCompany = store.get("auth/company.data");
  const userIsEntrepreneur = userCompany?.type === ENTREPRENEUR_USER_TYPE;

  // Submission not found
  if (error && error.response.status !== 403 && error.response.status !== 400) {
    return next({
      name: ERROR_ROUTE,
    });
  }

  // Authenticated entrepreneur accessing its own submission
  if (!error && userIsEntrepreneur) {
    /**
     * User is an entrepreneur and therefore shall be redirected to its own
     * comparison table page.
     */
    return next({
      name: CAPITAL_EXPLORER_COMPARISON_TABLE,
    });
  }

  // Authenticated entrepreneur accessing another entrepreneurs's submission
  if (error && error.response.status === 403 && userIsEntrepreneur) {
    return next({
      name: ERROR_ROUTE,
      query: {
        code: ERROR_TYPES.FORBIDDEN,
      },
    });
  }

  next();
};
