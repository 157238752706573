<template>
  <div class="milestone-carousel">
    <div class="milestone-carousel__grid">
      <ElCarousel
        ref="carousel"
        :autoplay="false"
        :initial-index="currentPosition - 1"
        arrow="always"
        data-testid="milestone-carousel"
        height="310px"
        indicator-position="none"
        type="card"
        @change="changeCarouselPosition"
      >
        <GridLevels
          :current-level="currentLevel"
          :levels-count="levelsCount"
          data-testid="grid-levels"
        />
        <ElCarouselItem
          v-for="(category, index) in categories"
          :key="category.name"
          ref="carousel-items"
          :class="[
            {
              'milestone-carousel__category--right':
                changeRightItemTransformStyles(category.order),
              'milestone-carousel__category--left':
                changeLeftItemTransformStyles(category.order),
            },
          ]"
          :name="category.name"
          class="milestone-carousel__category"
        >
          <div
            class="milestone-carousel__category-name"
            @click="$emit('hide-carousel')"
          >
            <p>
              {{ category.name }}
            </p>
            <img alt="minimize" src="/img/icons/minimize--black.svg" />
          </div>
          <div class="milestone-carousel__wrapper">
            <div class="milestone-carousel__milestone">
              <ElPopover
                v-for="milestone in category.milestones"
                :key="milestone.level"
                :close-delay="0"
                :data-testid="`milestone-${index}-${milestone.level}-popover`"
                placement="top"
                popper-class="px-popover--light milestone-cell-popover"
                width="242px"
                trigger="click"
                @hide="onTooltipHide"
                @show="onTooltipShow(milestone)"
              >
                <MilestoneTooltip
                  :category-color="category.color"
                  :category-name="category.name"
                  :data-testid="`milestone-${index}-${milestone.level}-tooltip`"
                  :has-evidence="milestone.evidenceProvided"
                  :is-future-milestone="milestone.future"
                  :is-milestone-complete="milestone.completed"
                  :is-milestone-critical="milestone.critical"
                  :is-milestone-in-progress="milestone.inProgress"
                  :is-milestone-planned="milestone.planned"
                  :is-visitor="isVisitor"
                  :level-number="milestone.level"
                  :milestone-description="milestone.description"
                  :target-date="milestone.targetDate"
                  @complete="
                    onMilestoneCompleteClick(category.id, milestone.level)
                  "
                  @plan="onMilestonePlanClick(category.id, milestone.level)"
                />
                <template #reference>
                  <MilestoneCell
                    :key="milestone.level"
                    :class="{
                      'milestone-carousel__milestone--blurred':
                        isMilestoneBlurred(milestone),
                    }"
                    :color="category.color"
                    :completed="milestone.completed"
                    :critical="milestone.critical"
                    :data-testid="`milestone-${index}-${milestone.level}-cell`"
                    :evidence-provided="milestone.evidenceProvided"
                    :future="milestone.future"
                    :in-progress="milestone.inProgress"
                    :planned="milestone.planned"
                  />
                </template>
              </ElPopover>
            </div>
            <MilestoneCarouselDescription
              :category="category"
              data-testid="milestone-carousel-description"
            />
          </div>
        </ElCarouselItem>
      </ElCarousel>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import MilestoneCell from "@/modules/milestone-planner/components/milestone-cell/milestone-cell.vue";
import MilestoneTooltip from "@/modules/milestone-planner/components/milestone-tooltip/milestone-tooltip.vue";
import {
  IGridCategory,
  IGridMilestone,
} from "@/modules/milestone-planner/components/milestones-grid/milestones-grid.interface";
import { IAssessmentLevel } from "@/services/data/assessment/assessment.interface";
import MilestoneCarouselDescription from "@/modules/milestone-planner/components/milestone-carousel/milestone-carousel-description.vue";
import GridLevels from "@/modules/milestone-planner/components/grid-levels/grid-levels.vue";
import {
  TMilestoneDetailsTab,
  MILESTONE_DETAILS_TABS,
} from "@/modules/milestone-planner/services/data/milestones/milestone.interface";
import {
  ROUTE_MILESTONE_PLANNER_DETAIL_VISITORS,
  ROUTE_MILESTONE_PLANNER_DETAIL,
} from "@/modules/milestone-planner/services/router/routes-names";

export default defineComponent({
  name: "MilestoneCarousel",

  components: {
    MilestoneCell,
    MilestoneCarouselDescription,
    GridLevels,
    MilestoneTooltip,
  },

  props: {
    categories: {
      type: Array as () => IGridCategory[],
      required: true,
    },

    levelsCount: {
      type: Number,
      required: true,
    },

    selectedCategory: {
      type: Object as () => IGridCategory,
      required: true,
    },

    lastAssessmentData: {
      type: Array as () => IAssessmentLevel[],
      required: true,
    },

    isVisitor: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      currentPosition: 1,
      selectedMilestone: null as IGridMilestone | null,
    };
  },

  computed: {
    currentLevel(): number {
      const categoryLevelData = this.lastAssessmentData.find(
        (data) => data.category === this.currentCategory?.id,
      );

      return categoryLevelData?.level ? categoryLevelData.level : 1;
    },

    currentCategory(): IGridCategory | undefined {
      return this.categories.find(
        (category) => category.order === this.currentPosition,
      );
    },
  },

  created() {
    this.currentPosition = this.selectedCategory.order;
  },

  methods: {
    // We have to add one to the position because the category order property starts at 1
    changeCarouselPosition(index: number) {
      this.currentPosition = index + 1;
      this.$emit(
        "change-category-level",
        this.currentCategory,
        this.currentLevel,
      );
    },

    changeRightItemTransformStyles(categoryOrder: number): boolean {
      return (
        categoryOrder === this.currentPosition + 1 ||
        (categoryOrder === 1 && this.currentPosition === this.categories.length)
      );
    },

    changeLeftItemTransformStyles(categoryOrder: number): boolean {
      return (
        categoryOrder === this.currentPosition - 1 ||
        (categoryOrder === this.categories.length && this.currentPosition === 1)
      );
    },

    /**
     * Check if provided milestone shall be blurred. It shall be blurred
     * when there's a selected milestone which is not the provided one.
     *
     * @param {IGridMilestone} milestone
     */
    isMilestoneBlurred(milestone: IGridMilestone) {
      return !!this.selectedMilestone && this.selectedMilestone !== milestone;
    },

    /**
     * Mark a milestone as selected when its tooltip gets shown.
     *
     * @param {IGridMilestone} milestone
     */
    onTooltipShow(milestone: IGridMilestone) {
      setTimeout(() => {
        this.selectedMilestone = milestone;
      });
    },

    /**
     * Clear selected milestone when a milestone's tooltip gets hidden.
     */
    onTooltipHide() {
      this.selectedMilestone = null;
    },

    /**
     * Handle milestone plan button clicks,
     * redirecting to plan tab on milestone detail page.
     *
     * @param {number} categoryId
     * @param {number} level
     */
    onMilestonePlanClick(categoryId: number, level: number) {
      this.goToMilestoneDetail(categoryId, level, MILESTONE_DETAILS_TABS.PLAN);
    },

    /**
     * Handle milestone complete button clicks,
     * redirecting to complete tab on milestone detail page.
     *
     * @param {number} categoryId
     * @param {number} level
     */
    onMilestoneCompleteClick(categoryId: number, level: number) {
      this.goToMilestoneDetail(
        categoryId,
        level,
        MILESTONE_DETAILS_TABS.COMPLETE,
      );
    },

    /**
     * Navigate to provided tab of milestone detail page.
     *
     * @param {number} categoryId
     * @param {number} level
     * @param {TMilestoneDetailsTab} tab
     */
    goToMilestoneDetail(
      categoryId: number,
      level: number,
      tab: TMilestoneDetailsTab,
    ) {
      this.$router.push({
        name: this.isVisitor
          ? ROUTE_MILESTONE_PLANNER_DETAIL_VISITORS
          : ROUTE_MILESTONE_PLANNER_DETAIL,
        params: {
          categoryId: categoryId.toString(),
          levelValue: level.toString(),
        },
        query: { tab },
      });
    },
  },
});
</script>

<style lang="scss" scoped>
.milestone-carousel {
  position: relative;
  top: 11px;
  right: 5px;
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 38px;

  :deep() .el-carousel__item--card.is-in-stage {
    cursor: text;
  }

  .el-carousel {
    width: 100%;

    &::after,
    &::before {
      position: absolute;
      top: 0;
      z-index: z("floaters");
      height: 100%;
      content: "";
      background: $white;
    }

    &::after {
      right: 0;
      width: 70px;
      background: linear-gradient(
        to left,
        rgba(255, 255, 255, 1) 20%,
        rgba(255, 255, 255, 0.8) 70%,
        rgba(255, 255, 255, 0.7) 80%,
        rgba(255, 255, 255, 0.5) 100%
      );
    }

    &::before {
      left: 0;
      width: 83px;
      background: linear-gradient(
        to right,
        rgba(255, 255, 255, 1) 40%,
        rgba(255, 255, 255, 0.8) 70%,
        rgba(255, 255, 255, 0.7) 80%,
        rgba(255, 255, 255, 0.5) 100%
      );
    }
  }

  .el-carousel__item {
    left: 26px;
  }

  :deep() .el-carousel__arrow {
    position: absolute;
    top: 5px;
    z-index: z("floaters") + 1;
    width: 14px;
    height: 14px;
    background: url("#{$assetsPath}/img/icons/caret--gray.svg");
    background-repeat: no-repeat;
    background-size: contain;
    outline: none;

    &--right {
      right: -1px;
      transform: rotate(270deg);
    }

    &--left {
      left: 14px;
      transform: rotate(90deg);
    }
  }

  &__milestone {
    display: flex;
    flex-direction: column-reverse;
    align-self: flex-end;
    justify-content: space-between;
    min-width: 85px;
    height: 100%;

    &--blurred {
      opacity: 0.4;

      &:hover {
        opacity: 1;
      }
    }
  }

  &__wrapper {
    display: flex;
    height: calc(100% - 46px);
  }

  &__grid {
    position: relative;
    display: flex;
    width: 100%;
    max-width: 705px;
  }

  .grid-levels {
    position: absolute;
    left: 1px;
  }

  &__category-name {
    position: relative;
    top: 2px;
    left: 12px;
    max-width: 160px;
    height: 36px;
    margin-bottom: 4px;

    text-align: left;

    p {
      @include grotesk(semiBold);

      display: inline-block;
      font-size: 14px;
      line-height: 18px;
      text-decoration: underline;
    }

    img {
      position: relative;
      bottom: 1px;
    }

    &:hover {
      cursor: pointer;
    }
  }
}

.milestone-carousel__category {
  background: $white;

  &--right {
    transform: translateX(175%) scale(1) !important;
  }

  &--left {
    transform: translateX(-7%) scale(1) !important;

    .milestone-carousel-description {
      display: none;
    }
  }

  &.is-active {
    left: 30px;
    width: 75%;
    transform: translateX(10%) scale(1) !important;
    z-index: 2;
  }
}
</style>
