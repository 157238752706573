<template>
  <div class="lists-directory page-body">
    <div class="page-container">
      <div class="lists-directory__wrapper">
        <div class="lists-directory__actions">
          <ElInput
            v-model="filters.list.value"
            :disabled="noListsToDisplay"
            :placeholder="$t('matching.matchingList.companiesSelector')"
            class="lists-directory__search"
            clearable
            data-testid="lists-directory-search-input"
            @input="removeInitialWhiteSpaces"
          >
            <template #prefix>
              <PxIcon :size="24" name="magnifier" />
            </template>
          </ElInput>
          <PxButton
            class="lists__main-cta"
            data-testid="lists-directory-add-new-list"
            size="medium"
            type="green"
            @click="newListClickHandler"
          >
            <PxIcon :size="16" name="plus-white" />
            {{ $t("companyLists.directory.createListButton") }}
          </PxButton>
        </div>
        <ElTabs
          v-model="selectedTab"
          :class="tabsStyle"
          class="el-tabs--nav-left"
        >
          <ElTabPane
            :label="$t(`companyLists.directory.tabs.${listTypes.OWNED}`)"
            :name="listTypes.OWNED"
          >
            <CompanyLists
              ref="ownedLists"
              :empty-lists-subtitle="ownedEmptyListsSubtitle"
              :empty-lists-title="ownedEmptyListsTitle"
              :has-filters="hasStoredSelectedFilters"
              :is-loading="isLoading"
              :lists="ownedListsToDisplay"
              :type="listTypes.OWNED"
              data-testid="lists-directory-owned-lists"
            />
          </ElTabPane>
          <ElTabPane
            :label="$t(`companyLists.directory.tabs.${listTypes.INVITED_TO}`)"
            :name="listTypes.INVITED_TO"
          >
            <CompanyLists
              ref="sharedLists"
              :empty-lists-subtitle="sharedEmptyListsSubtitle"
              :empty-lists-title="sharedEmptyListsTitle"
              :has-filters="hasStoredSelectedFilters"
              :is-loading="isSharedLoading"
              :lists="sharedListsToDisplay"
              :type="listTypes.INVITED_TO"
              data-testid="lists-directory-shared-lists"
            />
          </ElTabPane>
        </ElTabs>
      </div>
    </div>
    <ListManagementBar />
    <MatchingConfirmModal
      v-if="isMatchingConfirmModalVisible"
      v-model:visibility="isMatchingConfirmModalVisible"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import {
  COMPANY_LIST_TYPES,
  COMPANY_LIST_FILTERS,
} from "@/modules/company-lists/constants";
import { ICompanyList } from "@/modules/company-lists/services/data/company-list/company-list.interface";

import CompanyLists from "@/modules/company-lists/components/company-lists/company-lists.vue";
import ListManagementBar from "@/modules/company-lists/components/list-management/list-management-bar.vue";
import MatchingConfirmModal from "@/modules/matching/components/matching-confirm-modal.vue";
import { CompanyListState } from "@/modules/company-lists/services/store/company-list/company-list.modules";
import { EListManagementModalTab } from "@/modules/company-lists/components/list-management/list-management.types";
import { CompanySharedListState } from "@/modules/company-lists/services/store/company-shared-list/company-shared-list.module";
import { IAssessment } from "@/services/data/assessment/assessment.interface";
import { ILevel } from "@/services/data/level/level.interface";
import { EMetaActions, EMetaGetters } from "@/services/store/meta/meta-types";
import debounce from "lodash/debounce";
import cloneDeep from "lodash/cloneDeep";

export interface ICompanyListsFilter {
  [key: string]: {
    // Object key to extract filter value(s)
    key?: string;
    // Single value filter
    value?: string;
  };
}

export default defineComponent({
  name: "ListsDirectory",

  components: {
    CompanyLists,
    ListManagementBar,
    MatchingConfirmModal,
  },

  data() {
    return {
      // Freeze property to avoid uneccessary reactivity.
      listTypes: Object.freeze(COMPANY_LIST_TYPES),
      selectedTab: COMPANY_LIST_TYPES.OWNED,
      latestAssessment: null as IAssessment | null,
      hasLocation: false,
      hasSectors: false,
      isMatchingConfirmModalVisible: false,
      filters: {
        list: {
          value: "",
        },
      } as ICompanyListsFilter,
    };
  },

  computed: {
    tabsStyle(): { [key: string]: boolean } {
      return {
        "el-tabs--dark-line": this.$screen.smDown,
        "el-tabs--blue-line": this.$screen.mdUp,
      };
    },

    directoryEmptyCopy() {
      return this.$tm("companyLists.directory.empty") as {
        myLists: string;
        smartList: string;
        sharedWithMe: string;
        filteringTitle: string;
        filteringSubtitle: string;
      };
    },

    /**
     * True if we are loading data from the API.
     */
    isLoading(): boolean {
      return this.$store.get(CompanyListState.Getter.IS_LOADING);
    },

    /**
     * True if we are loading data from the API.
     */
    isSharedLoading(): boolean {
      return this.$store.get(CompanySharedListState.Getter.IS_LOADING);
    },

    /**
     * List of company lists owned by the user.
     */
    ownedCompanyLists(): Array<ICompanyList> {
      return this.$store.get(CompanyListState.Getter.VALUES) || [];
    },

    /**
     * List of company lists shared with the user.
     */
    sharedLists(): Array<ICompanyList> {
      return this.$store.get(CompanySharedListState.Getter.VALUES) || [];
    },

    /**
     * List of company lists owned by the user and filtered by the name search
     */
    ownedFilteredLists(): Array<ICompanyList> {
      return this.ownedCompanyLists.filter((list) =>
        list.title.toLowerCase().includes(this.listNameToSearch.toLowerCase()),
      );
    },

    /**
     * List of company lists shared with the user and filtered by the name search
     */
    sharedFilteredLists(): Array<ICompanyList> {
      return this.sharedLists.filter((list) =>
        list.title.toLowerCase().includes(this.listNameToSearch.toLowerCase()),
      );
    },

    ownedListsToDisplay(): Array<ICompanyList> {
      return this.hasStoredSelectedFilters
        ? this.ownedFilteredLists
        : this.ownedCompanyLists;
    },

    sharedListsToDisplay(): Array<ICompanyList> {
      return this.hasStoredSelectedFilters
        ? this.sharedFilteredLists
        : this.sharedLists;
    },

    listNameToSearch(): string {
      return this.storedSelectedFilters?.list.value || "";
    },

    latestAssessmentLevel(): ILevel | null {
      return this.latestAssessment?.level || null;
    },

    hasAssessmentLevel(): boolean {
      return this.latestAssessmentLevel !== null;
    },

    hasMatchingMandatoryData(): boolean {
      return (
        !!this.hasAssessmentLevel && !!this.hasLocation && !!this.hasSectors
      );
    },

    onlySmartListsCreated(): boolean {
      return (
        !!this.ownedCompanyLists.length &&
        !this.ownedCompanyLists.some(
          (list: ICompanyList) => list.is_smart_list !== true,
        )
      );
    },

    noListsToDisplay(): boolean {
      return (
        !this.hasStoredSelectedFilters &&
        !this.ownedListsToDisplay.length &&
        !this.sharedListsToDisplay.length
      );
    },

    sharedEmptyListsTitle() {
      return this.hasStoredSelectedFilters &&
        !this.sharedFilteredLists.length &&
        this.sharedLists.length
        ? this.directoryEmptyCopy.filteringTitle
        : "";
    },

    ownedEmptyListsTitle() {
      return this.hasStoredSelectedFilters &&
        !this.ownedFilteredLists.length &&
        this.ownedCompanyLists.length
        ? this.directoryEmptyCopy.filteringTitle
        : "";
    },

    sharedEmptyListsSubtitle() {
      return this.hasStoredSelectedFilters &&
        !this.sharedFilteredLists.length &&
        this.sharedLists.length
        ? this.directoryEmptyCopy.filteringSubtitle
        : this.directoryEmptyCopy.sharedWithMe;
    },

    ownedEmptyListsSubtitle() {
      return this.hasStoredSelectedFilters &&
        !this.ownedFilteredLists.length &&
        this.ownedCompanyLists.length
        ? this.directoryEmptyCopy.filteringSubtitle
        : this.onlySmartListsCreated
          ? this.directoryEmptyCopy.smartList
          : this.directoryEmptyCopy.myLists;
    },

    storedSelectedFilters(): ICompanyListsFilter | null {
      return (
        this.$store.getters[EMetaGetters.GET](COMPANY_LIST_FILTERS) || null
      );
    },

    hasStoredSelectedFilters(): boolean {
      return (
        !!this.storedSelectedFilters &&
        Object.keys(this.storedSelectedFilters)
          .map(
            (k: any) => (this.storedSelectedFilters as ICompanyListsFilter)[k],
          )
          .some((k: any) => !!k?.value?.length)
      );
    },
  },

  watch: {
    filters: {
      deep: true,
      handler: debounce(function (this: any, newFilters: ICompanyListsFilter) {
        if (!newFilters) return;

        const newFiltersSelection = newFilters ? cloneDeep(newFilters) : null;
        this.onFiltersChangeHandler(newFiltersSelection);
      }, 600),
    },

    ownedCompanyLists: {
      deep: true,
      handler(ownedLists: Array<ICompanyList>) {
        if (!ownedLists.length && !this.sharedLists.length) {
          this.filters.list.value = "";
        }
      },
    },

    sharedLists: {
      deep: true,
      handler(sharedLists: Array<ICompanyList>) {
        if (!sharedLists.length && !this.ownedCompanyLists.length) {
          this.filters.list.value = "";
        }
      },
    },
  },

  async mounted() {
    const form_open = this.$route?.query.form_open;
    if (form_open) {
      this.newListClickHandler();
    }
    await this.fetchLists();
  },

  created() {
    this.latestAssessment = this.$store.get("auth/latestAssessment/data");

    this.prefillAuthData();

    this.prefillFilters();

    // Matching modal should be visible when the user is an entrepreneur and has not level OR location OR sectors defined
    this.isMatchingConfirmModalVisible =
      !this.hasMatchingMandatoryData && this.$user.isEntrepreneur();
  },

  beforeUnmount() {
    this.latestAssessment = null;
    this.$store.dispatch(EMetaActions.REMOVE, COMPANY_LIST_FILTERS);
  },

  methods: {
    onFiltersChangeHandler(newFilter: ICompanyListsFilter) {
      this.$store.dispatch(EMetaActions.SET, {
        key: COMPANY_LIST_FILTERS,
        value: newFilter,
      });
    },

    prefillFilters() {
      if (!this.storedSelectedFilters) return;

      this.filters = { ...this.filters, ...this.storedSelectedFilters };
    },

    prefillAuthData() {
      this.hasLocation =
        !!this.$store.get("auth/company.data").locations.length;
      this.hasSectors = !!this.$store.get("auth/company.data").sectors.length;
    },

    async fetchLists() {
      await this.$store.dispatch(CompanyListState.Action.GET_VALUES);
      await this.$store.dispatch(CompanySharedListState.Action.GET_VALUES);
    },

    newListClickHandler() {
      // Open New List Modal
      this.$emitter.emit("show-list-management-modal", {
        tab: EListManagementModalTab.CREATE,
      });
    },

    removeInitialWhiteSpaces() {
      if (
        this.filters.list.value &&
        this.filters.list.value.length &&
        !this.filters.list.value.trim()
      ) {
        this.filters.list.value = this.filters.list.value.trim();
      }
    },
  },
});
</script>

<style lang="scss" scoped>
.lists-directory {
  padding: 78px 0 20px;
}

.lists-directory__loading {
  height: 200px;

  @include breakpoint-up(md) {
    height: 535px;
  }
}

.lists-directory__wrapper {
  position: relative;
}

.lists-directory__search :deep() {
  max-width: 310px;

  .el-input__inner {
    height: 36px;
  }

  .el-input__prefix {
    top: 6px;
  }

  .el-input__suffix {
    top: 12px;
    right: 15px;

    .el-input__suffix-inner {
      border: none;
    }

    .el-input__icon {
      width: 13px;
      height: 13px;
      cursor: pointer;
      background: transparent url("#{$assetsPath}/img/icons/cross.svg")
        no-repeat;
      background-size: contain;
      transition: $--filter-transition-base;

      &:hover {
        filter: brightness(50%);
      }
    }
  }
}

.lists-directory__actions {
  display: flex;
  gap: 10px;
  position: absolute;
  top: 19px;
  right: 20px;
  min-width: 117px;
  z-index: z("default") + 2;

  .lists__main-cta :deep() {
    span {
      position: relative;
      top: 1px;
      font-size: 14px;
    }

    .px-icon {
      margin-right: 7px;
    }
  }
}

.lists-directory :deep() .el-tabs {
  padding: 19px 20px 32px;
  background-color: $white;
  border: solid 1px $pale-grey;
  border-radius: 3px;
  box-shadow: 0 2px 11px 0 rgba(black, 0.05);

  &__item {
    @include grotesk(semiBold);

    padding: 24px 20px 15px 19px;
    margin-right: 2px;
    font-size: 17px;
    color: $manatee;
    letter-spacing: -0.2px;
  }

  &__item.is-active {
    @include grotesk(semiBold);

    color: $bluish;
  }

  &__item.is-active::before {
    height: 4px;
  }
}

.lists-directory :deep() .el-tab-pane {
  display: flex;
  min-height: 429px;
}
</style>

<style lang="scss">
.lists__main-cta {
  padding: 0 16px 2px 15px;

  span {
    font-size: 13px;
  }

  .px-icon {
    margin-top: 1px;
    margin-right: 5px;
  }
}
</style>
