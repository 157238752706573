import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PxNavigationTabItem = _resolveComponent("PxNavigationTabItem")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([{
      'px-navigation-tab--list-format': _ctx.listFormat,
    }, "px-navigation-tab"])
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.navigationTabs, (tab, index) => {
      return (_openBlock(), _createElementBlock("div", {
        key: tab.id
      }, [
        _createVNode(_component_PxNavigationTabItem, {
          active: tab.id === _ctx.selectedTab,
          icon: tab.icon,
          "icon-position": tab.iconPosition,
          "icon-size": _ctx.iconSize,
          "list-format": _ctx.listFormat,
          number: index + 1,
          "show-number": _ctx.showNumber,
          title: tab.title,
          onClick: ($event: any) => (_ctx.clickNavigationTab(tab.id))
        }, null, 8, ["active", "icon", "icon-position", "icon-size", "list-format", "number", "show-number", "title", "onClick"])
      ]))
    }), 128))
  ], 2))
}