/**
 * Particle generator library.
 *
 * This library is responsible to generate radial particle
 * explosions on a given point.
 *
 * NOTE: This code was written following a functional paradigm,
 *  there is no classes your references to `this`.
 *  Also this doesn't contemplate air resistance.
 *
 * USAGE:
 * ```
 * const context = createContext(htmlElement, width, height);
 * addConfettiParticles(context, numberOfParticles, originX, originY, colors);
 * render(context);
 * ```
 *
 * You can also get the canvas center point by user the following:
 * ```
 * const centerPoint = getCanvasCenterPoint(context);
 * ```
 */

const GRAVITY = -0.1;
const COLORS = [
  `rgba(168, 111, 245, 0.2)`,
  `rgba(255, 211, 111, 0.6)`,
  `rgba(82, 109, 239, 0.6)`,
  `rgba(255, 142, 84, 0.3)`,
  `rgba(67, 211, 138, 0.4)`,
  `rgba(255, 90, 100, 0.6)`,
];

let lastSpriteId = 0;

/// ---- Utils

/**
 * Generate an integer number random number within in a range.
 *
 * The maximum is exclusive and the minimum is inclusive.
 */
const getRandomInt = (min: number, max: number): number => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min)) + min;
};

/**
 * Generate a random float value.
 *
 * the min value can is inclusive and the max value is exclusive.
 */
const getRandomFloat = (min: number, max: number): number =>
  Math.random() * (max - min) + min;

/// ---- Render

/**
 * Interface that represents a sprite.
 */
export interface ISprite {
  /**
   * Angle of the particle.
   */
  angle: number;

  /**
   * X position of the particle
   */
  x: number;

  /**
   * Y position of the particle.
   */
  y: number;

  /**
   * Horizontal velocity.
   */
  vx: number;

  /**
   * Vertical velocity.
   */
  vy: number;

  /**
   * Width of the particle.
   */
  width: number;

  /**
   * Length of the particle.
   */
  length: number;

  /**
   * Particle tilt.
   */
  tilt: number;

  /**
   * Amount that must be incremented to the particle
   * in each render loop.
   */
  tiltAngleIncremental: number;

  /**
   * Current tilt angle.
   */
  tiltAngle: number;

  /**
   * Particle colors
   */
  color: string;
}

/**
 * Interface that represents a context.
 */
export interface IContext {
  /**
   * Number of rendered frames.
   */
  frame: number;

  /**
   * Reference for the canvas element.
   */
  canvas: HTMLCanvasElement;

  /**
   * Pixel density of the screen here the canvas is running on.
   */
  dpr: number;

  /**
   * 2D canvas context,
   */
  ctx: CanvasRenderingContext2D;

  /**
   * Ids of the confetti that are on the render.
   */
  confettiSpritesIds: Array<number>;

  /**
   * Object that contains all the sprites that are
   * registered to be rendered.
   */
  confettiSprites: { [key: number]: ISprite };
}

/**
 * Creates a new render context.
 *
 * @param element Canvas element
 * @param width Width that the canvas must have
 * @param height Height that the canvas must have
 */
export const createContext = (
  element: HTMLCanvasElement,
  width = 100,
  height = 100,
): IContext => {
  const context: IContext = {
    frame: 0,
    canvas: element,
    dpr: window.devicePixelRatio || 1,
    ctx: element.getContext("2d") as CanvasRenderingContext2D,
    confettiSpritesIds: [],
    confettiSprites: {},
  };

  // Adapt the scale based on the screen pixel ratio
  context.ctx.scale(context.dpr, context.dpr);

  // Setup canvas size
  context.canvas.width = width * context.dpr;
  context.canvas.height = height * context.dpr;
  context.canvas.style.width = `${width}px`;
  context.canvas.style.height = `${height}px`;

  return context;
};

/**
 * Get the center point of the canvas.
 *
 * @param context Render context.
 */
export const getCanvasCenterPoint = (
  context: IContext,
): { x: number; y: number } => ({
  x: context.canvas.width / 2,
  y: context.canvas.height / 2,
});

/**
 * Add a new particle to the render loop.
 *
 * @param context Render context
 * @param amount Amount of particles to be added to the render
 * @param x x position here the particle must be generated
 * @param y y position here the particle must be generated
 */
export const addConfettiParticles = (
  context: IContext,
  amount: number,
  x: number,
  y: number,
  colors: string[] = COLORS,
) => {
  for (let i = 0; i < amount; ++i) {
    const angle = getRandomFloat(0, 360);

    const width = getRandomInt(11, 17) * context.dpr; // r
    const length = getRandomInt(4, 6) * context.dpr; // d

    const color = colors[getRandomInt(0, colors.length)];

    const tilt = getRandomInt(10, -10);
    const tiltAngleIncremental = getRandomInt(0.07, 0.05);
    const tiltAngle = 0;

    // Define velocity vector
    const vx = getRandomFloat(-3, 3);
    const vy = getRandomFloat(-11, 5);

    const id = ++lastSpriteId;
    const sprite = {
      [id]: {
        angle,
        x,
        y,
        vx,
        vy,
        width,
        length,
        tilt,
        tiltAngleIncremental,
        tiltAngle,
        color,
      } as ISprite,
    };

    Object.assign(context.confettiSprites, sprite);
    context.confettiSpritesIds.push(id);
  }
};

/**
 * Check if the given sprite is out of the canvas.
 *
 * @param canvas Render context
 * @param sprite Sprite under evaluation
 */
const isSpriteOutOfCanvas = (
  canvas: HTMLCanvasElement,
  sprite: ISprite,
): boolean => {
  const maxSize = Math.max(sprite.width, sprite.length);
  return (
    sprite.x - maxSize > canvas.width ||
    sprite.x + maxSize < 0 ||
    sprite.y - maxSize > canvas.height
  );
};

/**
 * Update the given particle.
 *
 * @param context Render context
 * @param id Id of the sprite that will be updated.
 */
const updateConfettiParticle = (context: IContext, id: number) => {
  const sprite = context.confettiSprites[id];

  const tiltAngle = 0.0005 * sprite.length;

  sprite.angle += 0.01;
  sprite.tiltAngle += tiltAngle + sprite.tiltAngleIncremental;
  sprite.tilt =
    Math.sin(sprite.tiltAngle - sprite.width / 2) * sprite.width * 2;
  sprite.x = sprite.x + sprite.vx;
  const verticalVelocity = sprite.vy - GRAVITY * context.frame;
  sprite.y = sprite.y + (1 / 2) * verticalVelocity;

  // Add some additional motion to simulate air resistance
  sprite.y += Math.sin(sprite.angle + sprite.width / 2) * 2;
  sprite.x += Math.cos(sprite.angle) / 2;

  // Remove particle when is out of screen
  if (isSpriteOutOfCanvas(context.canvas, sprite)) {
    delete context.confettiSprites[id];
    context.confettiSpritesIds = context.confettiSpritesIds.filter(
      (e: number) => e !== id,
    );
  }
};

/**
 * Draw a particle on the canvas.
 *
 * @param context Render context
 */
const drawConfetti = (context: IContext) => {
  const ctx = context.ctx;
  context.confettiSpritesIds.map((id: number) => {
    const sprite = context.confettiSprites[id];

    ctx.beginPath();
    ctx.strokeStyle = sprite.color;
    ctx.lineWidth = sprite.length;
    ctx.moveTo(sprite.x, sprite.y);
    ctx.quadraticCurveTo(
      sprite.x + sprite.width / 2,
      sprite.y - sprite.length,
      sprite.x + sprite.width,
      sprite.y,
    );
    ctx.stroke();
    ctx.closePath();

    // Rectangular particles
    // ctx.lineWidth = sprite.length / 2;
    // ctx.strokeStyle = sprite.color;
    // ctx.moveTo(sprite.x + sprite.tilt + sprite.width, sprite.y);
    // ctx.lineTo(sprite.x + sprite.tilt, sprite.y + sprite.tilt + sprite.width);
    // ctx.closePath();
    // ctx.stroke();

    updateConfettiParticle(context, id);
  });
};

/**
 * Render the world.
 *
 * When there is no particles left it will stop the render
 * loop.
 *
 * @param context Render context
 */
export const render = (context: IContext) => {
  window.requestAnimationFrame(() => {
    context.frame += 1;

    // Clean previous render
    context.ctx.clearRect(0, 0, context.canvas.width, context.canvas.height);

    // Draw all the confetti on the canvas
    drawConfetti(context);

    // Stop the render when there is no particles left
    if (context.confettiSpritesIds.length > 0) {
      return render(context);
    }
  });
};
