import { Module } from "vuex";

import { IViralLevelState } from "./viral-level-types";
import { RootState } from "@/services/store/root-state";

import { state } from "./viral-level-state";
import { mutations } from "./viral-level-mutations";
import { getters } from "./viral-level-getters";
import { actions } from "./viral-level-actions";

export const viralLevelModule: Module<IViralLevelState, RootState> = {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
