import { getCompaniesCountForSpecificLabels } from "@/modules/charts-dashboard/utils/count-companies-with-label";
import {
  IListReportsDemographicData,
  IDemographicDataResponse,
  IDemographicDataCompany,
} from "@/modules/charts-dashboard/services/data/lists-reports-data/lists-reports-data.interface";
import {
  IProcessReportsDemographicData,
  IProcessReportsChartData,
} from "@/modules/charts-dashboard/services/data/process-reports-data/process-reports-data.interface";

export const formatCompaniesSexualOrientationData = (
  data: IListReportsDemographicData | IProcessReportsDemographicData,
): IDemographicDataResponse[] => {
  const companiesWithOrientationResponses = data.companies.filter(
    (company: IDemographicDataCompany) => company.responses.length,
  );

  const possibleResponses = data.responses.map((response) => response.value);

  const sexualOrientationCounts = getCompaniesCountForSpecificLabels(
    companiesWithOrientationResponses,
    possibleResponses,
  );

  const totalCompaniesWithSingleGender = Object.values(
    sexualOrientationCounts,
  ).reduce((acc, count) => acc + count, 0);

  const mixed =
    companiesWithOrientationResponses.length - totalCompaniesWithSingleGender;

  const formattedLabels = possibleResponses.map((response) => {
    const lowercaseResponse = response.toLowerCase();

    if (
      lowercaseResponse.includes(
        "queer, questioning, asexual, or a sexuality not listed",
      )
    ) {
      return "Led by queer, questioning, asexual, or a sexuality not listed";
    }

    if (!lowercaseResponse.includes("prefer not to say")) {
      return `${response} led`;
    }

    return response;
  });

  formattedLabels.push("Multi-orientation leadership");

  const chartValues = [...Object.values(sexualOrientationCounts), mixed];

  const aggregatedData = [] as IDemographicDataResponse[];

  for (let i = 0; i < chartValues.length; i++) {
    aggregatedData.push({
      value: formattedLabels[i] as string,
      count: chartValues[i],
      percentage: chartValues[i]
        ? chartValues[i] / companiesWithOrientationResponses.length
        : 0,
    });
  }

  return aggregatedData;
};

export const formatProcessReportsSexualOrientationData = (
  data: IProcessReportsDemographicData[],
): IProcessReportsChartData[] => {
  return data.map((list: IProcessReportsDemographicData) => {
    return {
      step: list.step,
      responses: !list.is_locked
        ? formatCompaniesSexualOrientationData(list)
        : [],
      is_locked: list.is_locked,
    };
  });
};
