import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, withCtx as _withCtx, createSlots as _createSlots, createCommentVNode as _createCommentVNode, TransitionGroup as _TransitionGroup, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-15d8e9f4"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "matching-list__cards"
}
const _hoisted_2 = { class: "matching-list__cards-header" }
const _hoisted_3 = { class: "matching-list__cards-headline" }
const _hoisted_4 = { class: "matching-results-loading--info" }
const _hoisted_5 = {
  key: 0,
  class: "matching-list__cards-wrapper"
}
const _hoisted_6 = {
  key: 1,
  class: "matching-list__cards-wrapper"
}
const _hoisted_7 = { class: "matching-list__cards-headline--more" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MatchingFilters = _resolveComponent("MatchingFilters")!
  const _component_MatchingCardPlaceholder = _resolveComponent("MatchingCardPlaceholder")!
  const _component_MatchingResultsLoading = _resolveComponent("MatchingResultsLoading")!
  const _component_MatchingCard = _resolveComponent("MatchingCard")!
  const _component_MatchingCardCompactListHeader = _resolveComponent("MatchingCardCompactListHeader")!
  const _component_MatchingCardCompact = _resolveComponent("MatchingCardCompact")!
  const _component_MatchingCardSimplified = _resolveComponent("MatchingCardSimplified")!
  const _component_MatchingCardCompactPlaceholder = _resolveComponent("MatchingCardCompactPlaceholder")!
  const _component_VirtualGrid = _resolveComponent("VirtualGrid")!
  const _component_MatchingEmptyResults = _resolveComponent("MatchingEmptyResults")!

  return (_ctx.hasOnBoarded)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _withDirectives(_createElementVNode("div", _hoisted_2, [
          _createElementVNode("h3", _hoisted_3, _toDisplayString(_ctx.$t(
            `matching.matchingList.discoverInterest[${_ctx.$user.getUserAccountType()}]`,
          )), 1),
          _createVNode(_component_MatchingFilters, {
            "has-companies-search": true,
            class: "matching-list__cards-filters",
            onHasFiltersChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.hasFilters = $event))
          })
        ], 512), [
          [_vShow, _ctx.hasFilters || _ctx.hasDiscoverCards]
        ]),
        (_ctx.isLoadingNewMatches || (_ctx.isLoadingInitialMatches && !_ctx.hasFilters))
          ? (_openBlock(), _createBlock(_component_MatchingResultsLoading, { key: 0 }, _createSlots({
              body: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.expandedMatchesQuantity, (index) => {
                  return (_openBlock(), _createBlock(_component_MatchingCardPlaceholder, {
                    key: `virtual-list-placeholder-${index}`
                  }))
                }), 128))
              ]),
              _: 2
            }, [
              (_ctx.isCalculatingInitialResults)
                ? {
                    name: "action",
                    fn: _withCtx(() => [
                      _createElementVNode("div", _hoisted_4, [
                        _createElementVNode("h4", null, _toDisplayString(_ctx.$t(`matching.matchingList.loadingResultsState.title`)), 1),
                        _createElementVNode("p", null, _toDisplayString(_ctx.$t(`matching.matchingList.loadingResultsState.subtitle`)), 1)
                      ])
                    ]),
                    key: "0"
                  }
                : undefined
            ]), 1024))
          : (_ctx.hasDiscoverCards)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                (_ctx.expandedMatches.length > 0)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                      _createVNode(_TransitionGroup, {
                        class: "matching-list__cards-grid",
                        name: "fade",
                        tag: "div"
                      }, {
                        default: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.expandedMatches, (entry, index) => {
                            return (_openBlock(), _createElementBlock(_Fragment, null, [
                              entry
                                ? (_openBlock(), _createBlock(_component_MatchingCard, {
                                    key: `matching-card--${index}`,
                                    about: entry.about,
                                    affiliates: entry.affiliates,
                                    badges: entry.badges,
                                    "company-id": entry.id,
                                    "company-uid": entry.uid || '',
                                    disabled: entry.disabled,
                                    "is-directory-member": entry.isDirectoryMember,
                                    level: entry.level,
                                    location: entry.location,
                                    locations: entry.locations,
                                    "match-percentage": entry.percentage,
                                    name: entry.name,
                                    offerings: entry.offerings,
                                    sectors: entry.sectors,
                                    "show-list-button": _ctx.showListFeature,
                                    thumbnail: entry.thumbnail,
                                    onClick: ($event: any) => (_ctx.clickMatchingCardHandler(entry.id))
                                  }, null, 8, ["about", "affiliates", "badges", "company-id", "company-uid", "disabled", "is-directory-member", "level", "location", "locations", "match-percentage", "name", "offerings", "sectors", "show-list-button", "thumbnail", "onClick"]))
                                : _createCommentVNode("", true)
                            ], 64))
                          }), 256))
                        ]),
                        _: 1
                      })
                    ]))
                  : _createCommentVNode("", true),
                (_ctx.hasCompactMatches)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                      _createElementVNode("h3", _hoisted_7, _toDisplayString(_ctx.$t(`matching.matchingList.more[${_ctx.$user.getUserAccountType()}]`)), 1),
                      (_ctx.showListFeature)
                        ? (_openBlock(), _createBlock(_component_MatchingCardCompactListHeader, {
                            key: 0,
                            "user-type": _ctx.matchUserType
                          }, null, 8, ["user-type"]))
                        : _createCommentVNode("", true),
                      _createVNode(_component_VirtualGrid, {
                        columns: _ctx.virtualGridColumns,
                        "grid-gap": _ctx.virtualGridGap,
                        "item-height": _ctx.virtualRowHeight,
                        items: _ctx.compactMatches,
                        "load-on-mount": true,
                        onScroll: _ctx.fetchMoreMatches
                      }, {
                        items: _withCtx((items) => [
                          (_ctx.showListFeature)
                            ? (_openBlock(), _createBlock(_component_MatchingCardCompact, {
                                key: `compact-card-${items.item.index}`,
                                about: items.item.about,
                                badges: items.item.badges,
                                "company-id": items.item.id,
                                "company-uid": items.item.uid,
                                disabled: items.item.disabled,
                                "is-directory-member": items.item.isDirectoryMember,
                                level: items.item.level,
                                location: items.item.location,
                                locations: items.item.locations,
                                "match-percentage": items.item.percentage,
                                name: items.item.name,
                                offerings: items.item.offerings,
                                sectors: items.item.sectors,
                                thumbnail: items.item.thumbnail,
                                onClick: ($event: any) => (_ctx.clickMatchingCardHandler(items.item.id))
                              }, null, 8, ["about", "badges", "company-id", "company-uid", "disabled", "is-directory-member", "level", "location", "locations", "match-percentage", "name", "offerings", "sectors", "thumbnail", "onClick"]))
                            : (_openBlock(), _createBlock(_component_MatchingCardSimplified, {
                                key: `simplified-card-${items.item.index}`,
                                about: items.item.about,
                                badges: items.item.badges,
                                "company-id": items.item.id,
                                "company-uid": items.item.uid,
                                level: items.item.level,
                                location: items.item.location,
                                locations: items.item.locations,
                                "match-percentage": items.item.percentage,
                                name: items.item.name,
                                offerings: items.item.offerings,
                                sectors: items.item.sectors,
                                thumbnail: items.item.thumbnail,
                                onClick: ($event: any) => (_ctx.clickMatchingCardHandler(items.item.id))
                              }, null, 8, ["about", "badges", "company-id", "company-uid", "level", "location", "locations", "match-percentage", "name", "offerings", "sectors", "thumbnail", "onClick"]))
                        ]),
                        loading: _withCtx((loading) => [
                          (_openBlock(), _createBlock(_component_MatchingCardCompactPlaceholder, {
                            key: `virtual-list-placeholder-${loading.item}`
                          }))
                        ]),
                        _: 1
                      }, 8, ["columns", "grid-gap", "item-height", "items", "onScroll"])
                    ]))
                  : _createCommentVNode("", true)
              ], 64))
            : (_ctx.hasNoCurrentMatches)
              ? (_openBlock(), _createBlock(_component_MatchingEmptyResults, {
                  key: 2,
                  notice: !_ctx.hasFilters,
                  "notice-button": _ctx.emptyNoticeButton,
                  "notice-subtitle": _ctx.emptyNoticeSubtitle,
                  "notice-title": _ctx.emptyNoticeTitle,
                  subtitle: _ctx.emptySubtitle,
                  title: _ctx.emptyTitle,
                  icon: "matching.matchingList.emptyState.icon"
                }, null, 8, ["notice", "notice-button", "notice-subtitle", "notice-title", "subtitle", "title"]))
              : _createCommentVNode("", true)
      ]))
    : _createCommentVNode("", true)
}