import { GenericState } from "@/services/store/generic/generic-state.module";
import {
  EGenericAction,
  EGenericGetter,
  IGenericAction,
  IGenericGetter,
} from "@/services/store/generic/generic-state.interface";
import { IAdditionalCriteria } from "@/modules/supporters/services/data/additional-criteria/additional-criteria.interface";
import { additionalCriteriaProvider } from "@/modules/supporters/services/data/additional-criteria/additional-criteria.provider";
import {
  EAdditionalCriteriaState,
  IAdditionalCriteriaState,
} from "@/modules/supporters/services/store/additional-criteria/additional-criteria.interface";
import { getGenericStateGetters } from "@/services/store/generic/generic-state.getters";
import { getters } from "@/modules/supporters/services/store/additional-criteria/additional-criteria.getters";

/**
 * Boilerplate module instantiation
 */
export class AdditionalCriteriaState extends GenericState<
  IAdditionalCriteriaState,
  IAdditionalCriteria
> {
  static Action = GenericState.mapNamespaceEnum(
    EGenericAction,
    EAdditionalCriteriaState.NAMESPACE,
  ) as IGenericAction;

  static Getter = GenericState.mapNamespaceEnum(
    EGenericGetter,
    EAdditionalCriteriaState.NAMESPACE,
  ) as IGenericGetter;
}

/**
 * Boilerplate module instantiation
 */
export const additionalCriteriaModule = new AdditionalCriteriaState({
  provider: additionalCriteriaProvider,
  props: {
    getters: { ...getGenericStateGetters<IAdditionalCriteria>(), ...getters },
  },
});
