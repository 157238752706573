<template>
  <div class="milestone-tooltip-buttons">
    <div>
      <PxButton
        :label="planButtonLabel"
        :size="buttonSizes.SMALL"
        :type="planButtonType"
        class="milestone-tooltip-button"
        data-testid="plan-button"
        @click="$emit('plan')"
      />
    </div>
    <div v-if="!isFutureMilestone && !isVisitor">
      <PxButton
        :label="$t('milestonePlanner.milestone.actions.complete')"
        :size="buttonSizes.SMALL"
        :type="completeButtonType"
        class="milestone-tooltip-button"
        data-testid="complete-button"
        @click="$emit('complete')"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { TranslateResult } from "vue-i18n";
import {
  EPxButtonType,
  EPxButtonSize,
} from "@/components/px-button/px-button.types";

export default defineComponent({
  name: "MilestoneTooltipButtons",

  props: {
    isFutureMilestone: { type: Boolean, default: false },
    isMilestoneInProgress: { type: Boolean, default: false },
    isMilestonePlanned: { type: Boolean, default: false },
    isVisitor: { type: Boolean, default: false },
  },

  data() {
    return {
      buttonSizes: EPxButtonSize,
    };
  },

  computed: {
    planButtonLabel(): TranslateResult {
      return this.isVisitor
        ? this.$t("milestonePlanner.milestone.actions.viewPlan")
        : this.isMilestonePlanned
          ? this.$t("milestonePlanner.milestone.actions.editPlan")
          : this.isFutureMilestone
            ? this.$t("milestonePlanner.milestone.actions.planAnyway")
            : this.$t("milestonePlanner.milestone.actions.plan");
    },

    planButtonType(): EPxButtonType {
      return (!this.isMilestonePlanned && !this.isMilestoneInProgress) ||
        this.isVisitor
        ? EPxButtonType.BLUE
        : EPxButtonType.GHOST_BLUE;
    },

    completeButtonType(): EPxButtonType {
      return this.isMilestonePlanned || this.isMilestoneInProgress
        ? EPxButtonType.BLUE
        : EPxButtonType.GHOST_BLUE;
    },
  },
});
</script>

<style lang="scss" scoped>
.milestone-tooltip-buttons {
  display: flex;
  gap: 10px;
  margin-top: 16px;

  > div {
    flex: 1;
  }

  .milestone-tooltip-button {
    width: 100%;
  }
}
</style>
