/*
 * Loads global application style.
 * --------------------------------------------------- */
import "@/styles/main.scss";

import Bugsnag from "@bugsnag/js";
import BugsnagPluginVue from "@bugsnag/plugin-vue";
import { isAllowedCookieGroup } from "@/services/utils/onetrust";
import { getAppVersion, getAppEnv, getEnv } from "@/services/utils/utils";

Bugsnag.start({
  apiKey: process.env.VUE_APP_BUGSNAG_KEY || "",
  appVersion: getAppVersion(),
  releaseStage: getAppEnv(),
  plugins: [new BugsnagPluginVue()],
  enabledBreadcrumbTypes: ["error", "navigation", "request", "user"],
  onError: (event: any) => {
    // Ignore local development errors
    if (getEnv() !== "production" || !isAllowedCookieGroup("performance")) {
      return false;
    }

    // Make sure FullStory object exists.
    if ((window as any).FS && (window as any).FS.getCurrentSessionURL) {
      event.addMetadata("fullstory", {
        urlAtTime: (window as any).FS.getCurrentSessionURL(true),
      });
    }
  },
});

/*
 * Load Prevent Scale plugin.
 * --------------------------------------------------- */
import preventScale from "@/plugins/prevent-scale.plugin";

/*
 * Initialize active modules.
 * --------------------------------------------------- */
import { initModules } from "@/modules/modules.init";
import { createApp } from "vue";
import App from "@/views/App.vue";
import store from "@/services/store";
import router from "@/services/router";
import { i18n } from "@/services/i18n/i18n";

import { userManager } from "@/modules/authentication/user-manager";
import { featureManager } from "@/services/feature-manager";
import { profileManager } from "@/modules/profile/profile-manager";
import { defaultScreenService } from "@/modules/common/services/screen/screen.service";
import { vuetify } from "@/plugins/vuetify.plugin";
import { ElementPlus } from "@/plugins/element-plus.plugin";
import { globalComponents } from "@/components/components.init";
import Vue3TouchEvents, {
  type Vue3TouchEventsOptions,
} from "vue3-touch-events";
import observer from "vue-mutation-observer";

import feature from "@/directives/feature.directive";
import markdown from "@/directives/markdown.directive";
import loading from "@/directives/px-loading.directive";

import mitt, { Emitter } from "mitt";

// Initialize all modules before continue, otherwise
// it can result in weird behavior
initModules().then(async () => {
  const app = createApp(App);

  app.use(ElementPlus);
  app.use(globalComponents);
  app.use(vuetify);
  app.use(i18n);
  app.use(store);
  app.use(router);
  app.use<Vue3TouchEventsOptions>(Vue3TouchEvents, {
    disableClick: false,
  });
  app.use(observer);
  app.use(preventScale);
  const bugsnagVue = Bugsnag.getPlugin("vue");
  if (bugsnagVue) {
    app.use(bugsnagVue);
  }

  app.directive("feature", feature);
  app.directive("markdown", markdown);
  app.directive("loading", loading);

  const emitter: Emitter<any> = mitt();
  app.config.globalProperties.$emitter = emitter;

  app.config.globalProperties.$features = featureManager;
  app.config.globalProperties.$screen = defaultScreenService.matchState;
  app.config.globalProperties.$user = userManager;
  app.config.globalProperties.$profile = profileManager;

  app.mount("#app");
});

/*
 * Prevent Abaca pages from being served from bfcache
 * --------------------------------------------------- */
window.addEventListener("pageshow", (event) => {
  if (event.persisted) {
    window.location.reload();
  }
});
