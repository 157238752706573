<template>
  <ElDialog
    ref="modal"
    v-model="innerVisibility"
    :before-close="beforeCloseHandler"
    :title="$t(`profile.networks.modal.title`)"
    class="networks-edit-modal el-dialog--px-10"
    width="420px"
  >
    <DialogConfirmation ref="dialog-confirmation" />
    <PxModalMessage :visible="hasServerErrors" />
    <ElForm ref="form" :model="form.data">
      <div class="form-grid form-grid--centered">
        <ElFormItem prop="networks">
          <NetworksSelector
            ref="networkSelector"
            v-model="form.data.networks"
            :label-copy="$t(`profile.networks.modal.label`)"
            :placeholder-copy="
              $t(`supporters.signup.form.placeholders.network`)
            "
          />
          <span
            class="el-form-item__bottom-text el-form-item__tip"
            v-html="$t('supporters.signup.form.tips.network')"
          />
        </ElFormItem>
      </div>
    </ElForm>
    <template #footer>
      <div>
        <PxButton size="small" type="link" @click="beforeCloseHandler">
          {{ $t("common.cancel") }}
        </PxButton>
        <PxButton
          :loading="isLoadingSubmission"
          type="green"
          @click="onClickSave"
        >
          {{ $t("common.saveChanges") }}
        </PxButton>
      </div>
    </template>
  </ElDialog>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import { ICompany } from "@/modules/profile/services/data/company/company.types";
import { INetwork } from "@/services/data/network/network.interface";

import cloneDeep from "lodash/cloneDeep";
import isEqual from "lodash/isEqual";

import NetworksSelector from "@/components/networks-selector/networks-selector.vue";
import { EAuthCompanyActions } from "@/modules/authentication/services/store/auth/auth-types";

import DialogConfirmationMixin from "@/mixins/dialog-confirmation.mixin";
import { IDialogConfirmationOptions } from "@/components/dialog-confirmation/dialog-confirmation.types";

export default defineComponent({
  name: "NetworksEditModal",

  components: {
    NetworksSelector,
  },

  mixins: [DialogConfirmationMixin],

  props: {
    visibility: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      innerVisibility: false,
      hasServerErrors: false,
      isLoadingSubmission: false,
      originalData: {},

      form: {
        data: {
          networks: [] as INetwork[],
        },
      },
    };
  },

  computed: {
    company(): ICompany | null {
      return this.$store.get("profile/company.data");
    },

    networks(): Array<INetwork> | null {
      return this.company ? this.company.networks : null;
    },

    dialogConfirmationOptions(): IDialogConfirmationOptions {
      return {
        message: this.$t("common.dialogConfirmation.discardMessage"),
        confirmFn: () => this.onConfirmCancel(),
      };
    },

    /**
     * Check if the form was changed.
     */
    hasFormChanged(): boolean {
      return !isEqual(this.form.data, this.originalData);
    },
  },

  watch: {
    visibility: {
      immediate: true,
      async handler(newVal) {
        this.innerVisibility = newVal;

        if (!newVal) {
          return;
        }

        this.resetFormData();
      },
    },

    innerVisibility(newVal, oldVal) {
      if (newVal === oldVal) {
        return;
      }

      if (!newVal) {
        this.$emit("update:visibility", newVal);
      }
    },
  },

  methods: {
    onConfirmCancel() {
      this.innerVisibility = false;
    },

    beforeCloseHandler() {
      if (this.hasFormChanged) {
        this.showConfirmOverlay(this.dialogConfirmationOptions);
      } else {
        this.innerVisibility = false;
      }
    },

    async resetFormData() {
      // Set the networks
      await this.$nextTick();
      const networkSelector = this.$refs.networkSelector as any;
      if (networkSelector && this.networks) {
        networkSelector.setActiveNetworks(cloneDeep(this.networks));
      }

      await this.$nextTick();
      this.originalData = cloneDeep(this.form.data);
    },

    async onClickSave() {
      this.isLoadingSubmission = true;
      this.hasServerErrors = false;

      try {
        await this.$store.dispatch(EAuthCompanyActions.PATCH, this.form.data);
      } catch (error) {
        this.hasServerErrors = true;
        return;
      } finally {
        this.isLoadingSubmission = false;
      }

      this.$emit("submit");

      // TODO: Use PxModalMessage instead of Element Message
      // Shows message under Navbar, due to message global context,
      // which will be shown behind modal overlay background
      this.$message({
        message: this.$t("profile.companyEditModal.successMessage"),
        type: "success",
        customClass: "is-full",
      });

      this.innerVisibility = false;
    },
  },
});
</script>

<style lang="scss">
.networks-edit-modal {
  .el-form-item__tip {
    padding: 8px 30px 0 31px;
  }

  .el-dialog__body {
    padding: 22px 0;
  }

  .networks-selector {
    padding: 0 25px 0 30px;
  }

  .networks-selector__list {
    position: relative;
  }

  .networks-selector__wrapper {
    width: 100%;
    max-height: 320px;
    overflow-x: hidden;
    overflow-y: auto;
    padding-right: 5px;

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background: $mischka;
      border-radius: 10px;
    }
  }
}
</style>
