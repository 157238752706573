<template>
  <div class="investment-level__container">
    <h2 class="submission-tab__print-title">
      {{ $t("profile.assessment.levelPanel.title") }}
    </h2>
    <div class="investment-level__section">
      <AssessmentResume
        :current-level="level.value"
        :description="level.description"
        :title="level.title"
      />
    </div>
    <div class="investment-level__section">
      <PxGrid
        :categories="categories"
        :current-level="level.value"
        :levels="numOfLevels"
        :results="results"
        :size="gridSize"
      />
    </div>
    <div class="investment-level__section">
      <ResultsReport :categories="categories" :levels="results" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import PxGrid from "@/components/px-grid/px-grid.vue";
import ResultsReport from "@/modules/program/components/results-report/results-report.vue";
import AssessmentResume from "@/modules/program/components/assessment-resume/assessment-resume.vue";

import { ICategory } from "@/services/data/category/category.interface";
import { ICategoryState } from "@/services/store/category/category-types";
import {
  IAssessment,
  IAssessmentLevel,
} from "@/services/data/assessment/assessment.interface";
import { ILevel } from "@/services/data/level/level.interface";
import { ICompany } from "@/modules/profile/services/data/company/company.types";

export default defineComponent({
  name: "InvestmentLevelPanel",

  components: {
    PxGrid,
    ResultsReport,
    AssessmentResume,
  },

  props: {
    assessment: {
      type: Object as () => IAssessment,
      required: true,
    },
    company: {
      type: Object as () => ICompany,
      required: true,
    },
  },

  computed: {
    categories(): Array<ICategory> {
      const categoriesStore = this.$store.state.categories as ICategoryState;
      return [...categoriesStore.data].sort((a, b) => a.order - b.order);
    },

    numOfLevels(): number {
      return this.$store.get("levels/data").length;
    },

    companyName(): string {
      return this.company ? this.company.name : "";
    },

    level(): ILevel {
      return this.assessment
        ? this.assessment.level
        : { value: 1, title: "", description: "", typical_funding: "" };
    },

    results(): Array<IAssessmentLevel> {
      return this.assessment ? this.assessment.data : [];
    },

    gridSize(): string {
      return this.$screen.smDown
        ? "small"
        : this.$screen.mdDown
          ? "medium"
          : "default";
    },
  },
});
</script>

<style lang="scss" scoped>
.investment-level__container {
  padding: 0 15px;

  @include breakpoint-up(md) {
    padding: 0 70px;
  }
}

.investment-level__section {
  padding: 40px 0;
  border-bottom: none;

  @include breakpoint-up(md) {
    border-bottom: 1px solid $athens-gray;
  }
}

.investment-level__section:first-child {
  border-bottom: 1px solid $athens-gray;
}

.investment-level__section:last-child {
  border-bottom: none;
}

.investment-level__section :deep() {
  .results-report {
    display: grid;
    grid-template-columns: auto;
    grid-gap: 18px 25px;
    padding: 0;

    @include breakpoint-up(md) {
      grid-template-columns: repeat(2, auto);
      padding: 0 40px;
    }

    @include breakpoint-up(lg) {
      grid-template-columns: repeat(3, auto);
    }
  }

  .results-report-entry {
    width: 100%;
    margin: 0;
  }
}

.submission-tab__print-title {
  display: none;
}
</style>
