import { GenericProvider } from "@/services/data/generic-provider";
import { IResendEmailRequest } from "./resend-email.interface";
import { baseAPIUrl } from "@/services/utils/utils";

class ResendEmailProvider extends GenericProvider<any, IResendEmailRequest> {
  constructor() {
    super(`${baseAPIUrl}/user/resend-verification-email`);
  }
}

export const resendEmailProvider = new ResendEmailProvider();
