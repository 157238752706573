<template>
  <div>
    <ErrorPage
      :error-code="errorCode"
      :show-support-modal="shouldShowSupportModal"
      data-testid="error-page"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import ErrorPage from "@/modules/common/components/error-page/error-page.vue";
import { ERROR_TYPES } from "@/modules/common/components/error-page/constants";

export default defineComponent({
  name: "ErrorPageView",

  components: {
    ErrorPage,
  },

  computed: {
    shouldShowSupportModal(): boolean {
      return this.errorCode === ERROR_TYPES.FORBIDDEN;
    },

    errorCode(): string {
      return this.$route.query.code as string;
    },
  },
});
</script>
