export const AUTHENTICATION_META_REGISTRATION_EMAIL = "registrationEmail";
export const AUTHENTICATION_LS_TOKEN_KEY = "sessionToken";
export const AUTHENTICATION_GUEST_USER_TYPE = "guestUserType";

export const AUTHENTICATION_TERMS_OF_USE_URL = "https://abaca.app/terms-of-use";
export const AUTHENTICATION_PRIVACY_POLICY_URL =
  "https://abaca.app/privacy-policy";

export const ENTREPRENEUR_USER_TYPE = 0;
export const SUPPORTER_USER_TYPE = 1;
