/**
 * Get the absolute height of an element.
 *
 * @param el HTMLElement or selector
 */
export const getElementAbsoluteHeight = (el: HTMLElement | string): number => {
  const element = (
    typeof el === "string" ? document.querySelector(el) : el
  ) as HTMLElement;

  if (!element) {
    throw new Error("The given query selector doesn't match with any element.");
  }

  const styles = window.getComputedStyle(element);
  const margin =
    parseFloat(styles.marginTop as string) +
    parseFloat(styles.marginBottom as string);

  return Math.ceil(element.offsetHeight + margin);
};
