import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-4d067b2b"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "investors-panel-accordion" }
const _hoisted_2 = { class: "investors-panel-accordion__headline" }
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ElTag = _resolveComponent("ElTag")!
  const _component_ElCollapseItem = _resolveComponent("ElCollapseItem")!
  const _component_ElCollapse = _resolveComponent("ElCollapse")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ElCollapse, {
      modelValue: _ctx.activeDrawer,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.activeDrawer) = $event)),
      accordion: ""
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, index) => {
          return (_openBlock(), _createBlock(_component_ElCollapseItem, {
            key: index,
            disabled: !item.description,
            name: index
          }, {
            title: _withCtx(() => [
              _createElementVNode("div", _hoisted_2, [
                _createElementVNode("h3", null, [
                  _createElementVNode("span", {
                    innerHTML: item.title
                  }, null, 8, _hoisted_3),
                  _createVNode(_component_ElTag, {
                    effect: "plain",
                    color: `#${item.color}`
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(item.category.toUpperCase()) + ": " + _toDisplayString(item.level), 1)
                    ]),
                    _: 2
                  }, 1032, ["color"])
                ])
              ])
            ]),
            default: _withCtx(() => [
              _createElementVNode("div", {
                innerHTML: item.description
              }, null, 8, _hoisted_4)
            ]),
            _: 2
          }, 1032, ["disabled", "name"]))
        }), 128))
      ]),
      _: 1
    }, 8, ["modelValue"])
  ]))
}