<template>
  <div class="profile-panel__row assessment-grid">
    <div class="profile-headline">
      <div class="profile-headline__left">
        <h2 class="profile-headline__title">
          {{ $t("profile.assessment.gridPanel.title") }}
        </h2>
        <PxIcon
          :size="22"
          class="profile-headline__how-it-works-trigger"
          data-testid="how-it-works-trigger"
          name="information--light"
          @click="onClickOpenHowItWorksLink"
        />
      </div>
      <div
        v-if="$user.isOwner() && $screen.mdUp"
        class="profile-headline__right"
      >
        <PxButton
          class="profile-headline__quick-update-button"
          v-bind="quickUpdateButtonProps"
          @click="onQuickUpdateClick"
        />
        <PxButton
          v-if="isMilestonePlannerModuleActive"
          v-bind="$options.static.showFullGridButton"
          ref="showFullGridButton"
          @click="showFullGrid"
        />
      </div>
    </div>
    <PxGrid
      :categories="categories"
      :current-level="levelValue"
      :levels="numOfLevels"
      :results="results"
      :size="$screen.smUp ? 'medium' : 'small'"
      class="assessment-grid__chart"
    />
    <div v-if="hasUserAccess" class="assessment-grid__chart-caption">
      <time class="assessment-grid__date">
        {{ latestAssessmentDate }}
      </time>
    </div>
    <div
      v-if="hasUserAccess && levelForCategory"
      class="assessment-grid__results"
    >
      <div
        v-for="category in categories"
        :key="category.id"
        :class="{
          'results-report-entry--not-achieved':
            levelForCategory(category) === 0,
        }"
        class="results-report-entry"
      >
        <div
          :style="{ backgroundColor: getCategoryColor(category) }"
          class="results-report-entry__bullet"
        />
        <span class="results-report-entry__number">
          {{ levelForCategory(category) || 0 }}
        </span>
        <span class="results-report-entry__name">
          {{ category.name }}
        </span>
      </div>
    </div>
    <div
      v-if="$user.isOwner() && $screen.mdUp && isMilestonePlannerModuleActive"
      class="assessment-grid__milestone-planner-action"
    >
      <PxButton
        data-testid="go-to-milestone-planner"
        size="small"
        type="green"
        @click="goToMilestonePlannerPage"
      >
        {{ $t("profile.assessment.gridPanel.milestonePlanner.cta") }}
      </PxButton>
      <p>
        {{ $t("profile.assessment.gridPanel.milestonePlanner.description") }}
      </p>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import moment from "moment";
import PxGrid from "@/components/px-grid/px-grid.vue";
import { ICategory } from "@/services/data/category/category.interface";
import {
  ICategoryState,
  ECategoryActions,
} from "@/services/store/category/category-types";
import { ENTREPRENEUR_USER_GROUP_ID } from "@/modules/common/constants";
import { ICompany } from "@/modules/profile/services/data/company/company.types";
import {
  IAssessment,
  IAssessmentLevel,
} from "@/services/data/assessment/assessment.interface";
import { ILevel } from "@/services/data/level/level.interface";
import {
  EPxButtonSize,
  EPxButtonVariant,
} from "@/components/px-button/px-button.types";
import { EViralLevelActions } from "@/services/store/viral-level/viral-level-types";
import { ROUTE_UPDATE_LEVEL } from "@/modules/profile/services/router/routes-names";
import {
  ROUTE_MILESTONE_PLANNER_OVERVIEW,
  ROUTE_MILESTONE_PLANNER_FULLGRID,
} from "@/modules/milestone-planner/services/router/routes-names";
import { activeModules } from "@/services/utils/utils";

export default defineComponent({
  name: "AssessmentPanelGrid",

  components: {
    PxGrid,
  },

  props: {
    latestAssessment: {
      type: Object as () => IAssessment,
      required: true,
    },
    levels: {
      type: Array as () => ILevel[],
      required: true,
    },
    levelValue: {
      type: Number,
      required: true,
    },
  },

  static: {
    showFullGridButton: {
      icon: "print",
      size: EPxButtonSize.SMALL,
      variant: EPxButtonVariant.COMPACT,
    },

    quickUpdateButton: {
      size: EPxButtonSize.SMALL,
      variant: EPxButtonVariant.FLUID,
    },
  },

  computed: {
    results(): Array<IAssessmentLevel> {
      return this.latestAssessment.data;
    },

    /**
     * Get the categories in a ordered way.
     *
     * This will sort the categories by their order, from the
     * lowest to the higher order.
     */
    categories(): Array<ICategory> {
      const categoriesStore = this.$store.state.categories as ICategoryState;
      return [...categoriesStore.data].sort((a, b) => a.order - b.order);
    },

    /**
     * Return the number of levels.
     */
    numOfLevels(): number {
      return this.levels.length;
    },

    company(): ICompany {
      return this.$store.get("auth/company.data");
    },

    companyName(): string {
      return this.company ? this.company.name : "";
    },

    isSpecialGuest(): boolean {
      return !!this.$route.meta?.specialGuest;
    },

    hasUserAccess(): boolean {
      return this.$user.isLogged() || this.isSpecialGuest;
    },

    quickUpdateButtonProps(): any {
      return {
        ...this.$options.static.quickUpdateButton,
        label: this.$t("milestonePlanner.quickUpdate"),
      };
    },

    latestAssessmentDate(): string {
      return moment(this.latestAssessment.created_at).format(
        "DD MMMM YYYY, hh:mmA",
      );
    },

    isMilestonePlannerModuleActive(): boolean {
      return activeModules().includes("milestone-planner");
    },
  },

  created() {
    this.$store.dispatch(ECategoryActions.FETCH, {
      group: ENTREPRENEUR_USER_GROUP_ID,
    });
  },

  methods: {
    /**
     * Get the level for a given category.
     */
    levelForCategory(category: ICategory): number {
      const levelSection = this.results.find(
        (result: IAssessmentLevel) => result.category === category.id,
      );

      return levelSection && levelSection.level ? levelSection.level : 0;
    },

    /**
     * Get the color for the given category.
     */
    getCategoryColor(category: ICategory) {
      const level = this.levelForCategory(category);
      const color: string = level !== 0 ? category.color : "f0f1f5";
      return `#${color}`;
    },

    onClickOpenHowItWorksLink() {
      window.open(
        "https://medium.com/village-capital/entrepreneurs-and-vcs-need-to-be-more-precise-in-the-way-they-talk-to-each-other-3e714e7a5245",
      );
    },

    onQuickUpdateClick() {
      this.$store.dispatch(EViralLevelActions.RESET);

      this.$router.push({
        name: ROUTE_UPDATE_LEVEL,
      });
    },

    goToMilestonePlannerPage() {
      this.$router.push({ name: ROUTE_MILESTONE_PLANNER_OVERVIEW });
    },

    showFullGrid() {
      this.$router.push({ name: ROUTE_MILESTONE_PLANNER_FULLGRID });
    },
  },
});
</script>

<style lang="scss" scoped>
.assessment-grid {
  .profile-headline,
  &__chart,
  &__char-caption,
  &__results {
    margin-right: 4px;
    margin-left: 4px;
  }

  .profile-panel--disabled &:not(:first-child) {
    padding-top: 30px;
    margin-top: -3px;
  }

  .profile-headline {
    display: flex;
    justify-content: space-between;
  }

  .profile-headline__left {
    display: flex;
    gap: 12px;
    justify-content: flex-start;
  }

  .profile-headline__right {
    position: relative;
    right: 1px;
    bottom: 3px;
    display: flex;
    gap: 13px;
    justify-content: space-around;
  }

  .profile-headline__how-it-works-trigger {
    position: relative;
    top: 2px;
    cursor: pointer;
  }

  &__milestone-planner-action {
    position: relative;
    display: block;
    margin-top: 16px;
    margin-bottom: 2px;
    text-align: center;

    .el-button--green {
      width: 100%;
      max-width: 405px;
      padding: 0;
      margin-right: 7px;
    }

    p {
      padding: 0;
      margin: 11px 0 30px;
      font-size: 14px;
      font-style: italic;
      line-height: normal;
      color: $manatee;
      letter-spacing: 0.1px;
    }
  }
}

.assessment-grid__chart {
  margin-top: 28px;

  @include breakpoint-down(sm) {
    margin-top: 12px;
  }

  &:last-child {
    margin-bottom: 10px;
  }
}

.assessment-grid__chart-caption {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0 23px;
  margin-top: 15px;

  @include breakpoint-down(sm) {
    margin-top: 16px;
  }
}

.assessment-grid__date {
  @include grotesk(regular, italic);

  font-size: to-rem(14px);
  letter-spacing: 0;

  @include breakpoint-down(sm) {
    width: 100%;

    font-size: to-rem(15px);
    text-align: center;
  }
}

.assessment-grid__chart-caption .el-button--link {
  padding-right: 2px;
}

.assessment-grid__results {
  column-count: 2;
  padding-left: 34px;
  margin-top: 28px;
  transform: translate3d(0, 0, 0);

  @include breakpoint-down(sm) {
    position: relative;
    left: -5px;
    padding-left: 0;
    margin-top: 23px;
  }
}

.assessment-grid__results .results-report-entry {
  position: relative;
  display: inline-block;
  width: 100%;
  padding-left: 36px;
  margin-bottom: 8px;

  /* Avoid text breaking into another column */
  page-break-inside: avoid;
  -webkit-column-break-inside: avoid;
  break-inside: avoid;

  &:nth-child(n + 5) {
    position: relative;
    top: -1px;
    right: 2px;
  }
}

.results-report-entry__bullet {
  position: absolute;
  top: 5px;
  left: 5px;

  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 100%;
}

.results-report-entry__number {
  @include grotesk(semiBold);

  position: absolute;
  top: 0;
  left: 23px;

  font-size: to-rem(15px);
  line-height: 18px;
}

.results-report-entry__name {
  font-size: to-rem(15px);
  line-height: 18px;
  letter-spacing: -0.2px;
}
</style>
