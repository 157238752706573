import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, normalizeClass as _normalizeClass, withCtx as _withCtx, createSlots as _createSlots } from "vue"

const _hoisted_1 = { class: "details-modal__header" }
const _hoisted_2 = { class: "details-modal__title" }
const _hoisted_3 = {
  key: 0,
  class: "details-modal__matching-badge"
}
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = {
  key: 1,
  class: "details-modal__content-matching"
}
const _hoisted_6 = {
  ref: "table",
  class: "details-modal__table-wrapper"
}
const _hoisted_7 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PxStatusBadge = _resolveComponent("PxStatusBadge")!
  const _component_PxNavigationTab = _resolveComponent("PxNavigationTab")!
  const _component_PxTableHeader = _resolveComponent("PxTableHeader")!
  const _component_MatchDetailsTableRow = _resolveComponent("MatchDetailsTableRow")!
  const _component_MatchDetailsTableLegend = _resolveComponent("MatchDetailsTableLegend")!
  const _component_PxIcon = _resolveComponent("PxIcon")!
  const _component_ElDialog = _resolveComponent("ElDialog")!

  return (_openBlock(), _createBlock(_component_ElDialog, {
    ref: "details-modal",
    modelValue: _ctx.innerVisibility,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.innerVisibility) = $event)),
    "append-to-body": true,
    "data-active-tab": _ctx.activeTab,
    class: "details-modal",
    top: "0"
  }, _createSlots({
    header: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("h3", _hoisted_2, _toDisplayString(_ctx.capitalInfo.title), 1),
        (_ctx.showMatchingBadge && _ctx.matchProperties.matchingBadge)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createVNode(_component_PxStatusBadge, {
                "background-color": _ctx.matchProperties.matchingBadge.backgroundColor,
                "border-color": _ctx.matchProperties.matchingBadge.borderColor,
                icon: _ctx.matchProperties.matchingBadge.icon,
                "icon-size": 13,
                label: _ctx.matchingBadgeLabel,
                "label-color": _ctx.matchProperties.matchingBadge.textColor,
                "label-size": 14,
                style: _normalizeStyle(_ctx.matchingBadgeStyles),
                uppercase: false,
                weight: 600
              }, null, 8, ["background-color", "border-color", "icon", "label", "label-color", "style"])
            ]))
          : _createCommentVNode("", true)
      ]),
      _createVNode(_component_PxNavigationTab, {
        "selected-tab": _ctx.activeTab,
        "onUpdate:selectedTab": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.activeTab) = $event)),
        "icon-size": 15,
        "navigation-tabs": _ctx.visibleTabs,
        "show-number": false
      }, null, 8, ["selected-tab", "navigation-tabs"])
    ]),
    default: _withCtx(() => [
      (_ctx.activeTab !== 5)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: "details-modal__content",
            innerHTML: _ctx.tabContent
          }, null, 8, _hoisted_4))
        : (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createVNode(_component_PxTableHeader, { items: _ctx.tableHeaders }, null, 8, ["items"]),
            _createElementVNode("div", _hoisted_6, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.matchDetailsOrdered, (detail, index) => {
                return (_openBlock(), _createElementBlock(_Fragment, null, [
                  (detail.question)
                    ? (_openBlock(), _createBlock(_component_MatchDetailsTableRow, {
                        key: index,
                        "auth-user-answer": detail.userAnswers,
                        criteria: detail.question.title,
                        "has-match": detail.match,
                        instructions: detail.question.instructions,
                        "list-format": true,
                        "profile-user-answer": detail.desiredAnswers,
                        "show-learn-more-link": true,
                        "icon-match": "match--green"
                      }, null, 8, ["auth-user-answer", "criteria", "has-match", "instructions", "profile-user-answer"]))
                    : _createCommentVNode("", true)
                ], 64))
              }), 256))
            ], 512),
            _createVNode(_component_MatchDetailsTableLegend, {
              "legend-cards": _ctx.legend,
              class: "details-modal__table-legend"
            }, null, 8, ["legend-cards"])
          ]))
    ]),
    _: 2
  }, [
    (_ctx.activeTab !== 5)
      ? {
          name: "footer",
          fn: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.footerButtons, ({ label, icon, type, href }, index) => {
              return (_openBlock(), _createElementBlock("a", {
                key: index,
                class: _normalizeClass(`el-button el-button--${type} el-button--small`),
                href: href,
                target: "_blank"
              }, [
                _createElementVNode("span", null, [
                  _createVNode(_component_PxIcon, {
                    name: icon,
                    size: 16
                  }, null, 8, ["name"]),
                  _createElementVNode("div", null, _toDisplayString(label), 1)
                ])
              ], 10, _hoisted_7))
            }), 128))
          ]),
          key: "0"
        }
      : undefined
  ]), 1032, ["modelValue", "data-active-tab"]))
}