import { Module } from "vuex";
import { IMatchingQuestionaryState } from "./matching-questionary.types";
import { RootState } from "@/services/store/root-state";
import { make } from "vuex-pathify";
import { matchingQuestionaryActions } from "./matching-questionary.actions";
import { matchingQuestionaryGetters } from "./matching-questionary.getters";
import { matchingQuestionaryMutations } from "./matching-questionary.mutations";

const state: IMatchingQuestionaryState = {
  data: [],
  currentQuestionIndex: 0,
  totalQuestions: 0,
  error: null,
  loading: false,
};

export const matchingQuestionaryModule: Module<
  IMatchingQuestionaryState,
  RootState
> = {
  namespaced: true,
  state,
  actions: matchingQuestionaryActions,
  mutations: {
    ...make.mutations(state),
    ...matchingQuestionaryMutations,
  },
  getters: matchingQuestionaryGetters,
};
