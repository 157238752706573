<template>
  <div class="px-questionary-select">
    <template v-if="multiple">
      <ElCheckboxGroup v-model="checkBoxOptionsSelected" @change="submitAnswer">
        <ElCheckbox
          v-for="(option, index) in options"
          :key="index"
          :value="option.id"
        >
          {{ option.value }}
        </ElCheckbox>
      </ElCheckboxGroup>
    </template>
    <template v-else>
      <PxRadioGroup v-model="radioOptionSelected" @change="submitAnswer">
        <ElRadio
          v-for="(option, index) in options"
          :key="index"
          :value="option.id"
          border
        >
          {{ option.value }}
        </ElRadio>
      </PxRadioGroup>
    </template>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "PxQuestionarySelect",

  props: {
    multiple: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Array as () => any[],
      required: true,
    },
  },

  data() {
    return {
      radioOptionSelected: "",
      checkBoxOptionsSelected: [],
    };
  },

  watch: {
    /**
     * Reset component state when changing the options.
     */
    options: {
      deep: true,
      handler() {
        this.radioOptionSelected = "";
        this.checkBoxOptionsSelected = [];
        this.$emit("update:modelValue", []);
      },
    },
  },

  methods: {
    /**
     * Emit changes to the parent component.
     */
    submitAnswer(answer: any) {
      this.$emit("select", answer);
      this.$emit("update:modelValue", answer);
      this.validateField();
    },

    /**
     * Check if the field is valid and emit the change
     * to the parent.
     */
    validateField() {
      const isValid =
        (this.multiple && this.checkBoxOptionsSelected.length) ||
        Number.isInteger(this.radioOptionSelected);
      this.$emit("update:isValid", isValid);
    },
  },
});
</script>

<style lang="scss" scoped>
.px-questionary-select :deep() {
  .el-radio,
  .el-checkbox {
    display: flex;
    flex-flow: row-reverse wrap;
    justify-content: space-between;
    width: 100%;
    padding: 6px 14px 7px;
    margin: 0;

    color: $white;
    cursor: pointer;
    user-select: none;
    background-color: rgba($white, 0.2);
    border-radius: 4px;
    outline: none;

    &:not(:first-child) {
      margin-top: 2px;
    }
  }

  .el-radio__input,
  .el-checkbox__input {
    position: relative;
    height: 25px;
    padding: 4px 0;

    .el-radio__inner::after {
      position: absolute;
      top: 0;
      left: 0;
      z-index: z("default");
      width: 100%;
      height: 100%;

      content: "";

      background-repeat: no-repeat;
      background-position: center;
      background-color: transparent;
      opacity: 0;
      will-change: opacity;
      border-radius: 100%;
      transition: opacity 120ms ease-in;
    }
  }

  .el-radio__original {
    width: 18px;
    height: 18px;
    background: transparent;
    border: solid 1px $white;
    opacity: 1;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    will-change: background-color;
    transition: background-color 120ms ease-in;
    z-index: z("default");
    border-radius: 100%;
  }

  .el-checkbox__inner {
    width: 18px;
    height: 18px;
  }

  .el-checkbox__inner::after {
    transition: opacity 120ms ease-in;
  }

  .el-radio__inner {
    border: none;
    width: 18px;
    height: 18px;
    z-index: z("default") + 1;
    top: 4px;
  }

  .el-radio__label,
  .el-checkbox__label {
    @include grotesk(semiBold);

    flex-basis: calc(100% - 25px);
    padding: 3px 0 0;
    font-size: 15px;
    line-height: 18px;
    color: white;
    letter-spacing: 0;
    white-space: normal;
  }

  .is-checked .el-radio__inner::after,
  .is-checked .el-checkbox__inner::after {
    background-image: url("#{$assetsPath}/img/icons/check--dark--bigger.svg");
    background-size: 12px 12px;
  }

  .is-checked {
    .el-radio__original,
    .el-checkbox__inner {
      background-color: $white;
      border: 1px solid $pale-grey;
    }
  }

  .el-checkbox__inner:hover,
  .el-checkbox__input.is-focus .el-checkbox__inner {
    border: 1px solid $pale-grey;
  }

  .is-checked .el-radio__inner::after,
  .el-radio:hover .el-radio__inner::after {
    opacity: 1;
  }

  .el-checkbox:not(.is-checked) .el-checkbox__inner:hover::after,
  .el-radio:not(.is-checked):hover .el-radio__inner::after {
    background-image: url("#{$assetsPath}/img/icons/check--white.svg");
    background-size: 20px 20px;
  }
}
</style>
