import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-3e7aa6f2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "auth-base-signup" }
const _hoisted_2 = { class: "page-wrapper" }
const _hoisted_3 = { class: "page-container" }
const _hoisted_4 = { class: "page-headline" }
const _hoisted_5 = { class: "page-block" }
const _hoisted_6 = { class: "auth-base-signup__footer" }
const _hoisted_7 = { class: "auth-base-signup__message" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LevelBar = _resolveComponent("LevelBar")!
  const _component_PxSteps = _resolveComponent("PxSteps")!
  const _component_SignUpStepCompany = _resolveComponent("SignUpStepCompany")!
  const _component_SignUpStepAccount = _resolveComponent("SignUpStepAccount")!
  const _component_PxButton = _resolveComponent("PxButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.isFromAssessment && _ctx.assessmentLevel)
      ? (_openBlock(), _createBlock(_component_LevelBar, {
          key: 0,
          level: _ctx.assessmentLevel
        }, null, 8, ["level"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h1", _hoisted_4, _toDisplayString(_ctx.$t("authentication.signup.startMessage")), 1),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_PxSteps, {
            active: _ctx.currentStep,
            steps: _ctx.items
          }, null, 8, ["active", "steps"]),
          _withDirectives(_createVNode(_component_SignUpStepCompany, {
            company: _ctx.company,
            "onUpdate:company": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.company) = $event)),
            onNextStep: _ctx.nextStep
          }, null, 8, ["company", "onNextStep"]), [
            [_vShow, _ctx.currentStep === 0]
          ]),
          _withDirectives(_createVNode(_component_SignUpStepAccount, {
            account: _ctx.formData,
            "onUpdate:account": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.formData) = $event)),
            errors: _ctx.errors,
            loading: _ctx.loading,
            onPreviousStep: _ctx.previousStep,
            onSubmitForm: _ctx.submitForm
          }, null, 8, ["account", "errors", "loading", "onPreviousStep", "onSubmitForm"]), [
            [_vShow, _ctx.currentStep === 1]
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("p", _hoisted_7, [
              _createTextVNode(_toDisplayString(_ctx.$t("authentication.signup.footerMessage")) + " ", 1),
              _createVNode(_component_PxButton, {
                class: "el-button--inline el-button--link-blue",
                type: "link",
                onClick: _ctx.onClickLogin
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t("authentication.signup.loginLink")), 1)
                ]),
                _: 1
              }, 8, ["onClick"])
            ])
          ])
        ])
      ])
    ])
  ]))
}