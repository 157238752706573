<template>
  <MatchingCardWrapper
    :is-disabled="disabled"
    :is-faded="isFaded"
    :is-selected="isSelected"
    @click="onCardClick"
  >
    <template #action>
      <div v-if="showListButton" class="matching-card-selector__wrapper">
        <ElTooltip
          v-if="!disabled"
          :content="checkboxTooltipCopy"
          :disabled="isInnerSelected"
          placement="top"
          popper-class="px-matching-interest-compact__tooltip el-abaca-tooltip"
        >
          <ElCheckbox
            v-model="isInnerSelected"
            class="matching-card-selector"
            @click.stop
          />
        </ElTooltip>
        <div v-else class="matching-card-selector--label" v-text="labelText" />
      </div>
    </template>
    <template #header>
      <MatchingCardBackground :company-name="name" />
      <MatchingCardPercentagePill
        :percentage="matchPercentage"
        :show-tooltip="!isInterestDetailModalVisible && $screen.mdUp"
        @click.stop="onClickShowModal"
      />
      <MatchDetailsModal
        v-if="showInterestDetailModalVisible && $screen.mdUp"
        v-model:visibility="isInterestDetailModalVisible"
        :company-logo="thumbnail"
        :company-name="name"
        :match-details="matchScoreDetails"
        data-testid="matching-card-details-modal"
      />
      <SubmissionsModal
        v-if="submissionsModalVisible"
        v-model:visibility="submissionsModalVisible"
        :company="name"
        :data="affiliates"
      />
    </template>
    <template #body>
      <div class="matching-card-thumbnail__wrapper">
        <MatchingCardThumbnail
          :level="matchLevel"
          :name="name"
          :thumbnail="thumbnail"
        />
      </div>
      <div class="matching-badge-list__wrapper">
        <MatchingBadgeList
          v-if="!showPublicView"
          :badges="badges"
          :company-name="name"
          @click="openSubmissionsModal"
        />
      </div>
      <div class="matching-card__headline">
        <h3 class="matching-card__title">
          {{ name }}
        </h3>
        <p class="matching-card__subtitle">
          {{ location }}
        </p>
        <span v-if="!!about" class="matching-card__description">
          {{ about }}
        </span>
        <MatchingCardTagList :items="mappedSectors" />
      </div>
    </template>
    <template #footer>
      <PxMatchingInterest
        v-if="enabledMatchingInterest"
        v-bind="$options.static.interestButton"
        :id="companyId"
        :is-directory-member="isDirectoryMember"
        :name="name"
        :uid="companyUid"
        :with-description="false"
        class="matching-card__interest"
        @click.stop
      />
      <PxButton
        v-if="showListButton"
        v-bind="addToListButtonProps"
        @click.stop="onShowListManagementModal"
      />
    </template>
  </MatchingCardWrapper>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import MatchingCardMixin from "@/mixins/matching-card.mixin";
import MatchingCardWrapper from "@/components/matching-card/matching-card-wrapper.vue";
import MatchingCardBackground from "@/components/matching-card/matching-card-background.vue";
import MatchDetailsModal from "@/components/match-details-modal/match-details-modal.vue";

import {
  EPxButtonSize,
  EPxButtonType,
} from "@/components/px-button/px-button.types";
import { EListManagementActions } from "@/modules/company-lists/services/store/list-management/list-management.types";

export default defineComponent({
  name: "MatchingCard",

  components: {
    MatchingCardWrapper,
    MatchingCardBackground,
    MatchDetailsModal,
  },

  mixins: [MatchingCardMixin],

  static: {
    interestButton: {
      size: EPxButtonSize.EXTRA_SMALL,
    },

    addToListButton: {
      icon: "icon-add-to-list",
      size: EPxButtonSize.EXTRA_SMALL,
      type: EPxButtonType.DARK_BLUE,
    },
  },

  computed: {
    checkboxTooltipCopy(): string {
      return this.$t(
        "common.components.matchingCard.checkboxTooltipText",
      ) as string;
    },

    addToListButtonProps(): any {
      return {
        ...this.$options.static.addToListButton,
        label: this.$t("common.components.default.cta.addToList"),
      };
    },

    selectionCompanyList(): Array<string> {
      return this.$store.get("listManagement.data.companies") || [];
    },

    isSelectModeActive(): boolean {
      return this.showListButton && this.selectionCompanyList.length > 0;
    },

    labelText(): string {
      if (!this.label && !this.disabled) {
        return "";
      }

      return this.label || this.$t("matching.matchingList.alreadyAdded");
    },

    isSelected(): boolean {
      return (
        this.isSelectModeActive &&
        !!this.selectionCompanyList.find(
          (itemUid: string) => itemUid === this.companyUid,
        )
      );
    },

    isFaded(): boolean {
      // If card is not selected, but select mode is active, show faded card
      return this.isSelectModeActive && !this.isSelected;
    },
  },

  watch: {
    isInnerSelected(newValue: boolean, oldValue: boolean) {
      // Prevent side-effects
      if (newValue === oldValue || newValue === this.isSelected) {
        return;
      }

      // Item was selected, dispatch according action
      if (newValue) {
        this.$store.dispatch(
          EListManagementActions.ADD_COMPANY,
          this.companyUid,
        );
        return;
      }

      this.$store.dispatch(
        EListManagementActions.REMOVE_COMPANY,
        this.companyUid,
      );
    },

    isSelected(newValue: boolean) {
      if (newValue !== this.isInnerSelected) {
        this.isInnerSelected = newValue;
      }
    },
  },

  created() {
    this.isInnerSelected = this.isSelected;
  },

  methods: {
    onCardClick() {
      // Block global component action when select mode is active
      if (!this.isSelectModeActive) {
        this.$emit("click");
      }
    },
  },
});
</script>

<style lang="scss" scoped>
.matching-card-selector :deep() .el-checkbox__inner::before {
  position: absolute;
  top: -6px;
  left: -6px;
  width: calc(100% + 12px);
  height: calc(100% + 12px);
  content: "";
}

.matching-card-selector__wrapper {
  position: absolute;
  top: 10px;
  left: 9px;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
}

.matching-card--disabled .matching-card-selector__wrapper {
  top: 6px;
  left: 11px;
  opacity: 1;
}

.matching-card--disabled .matching-card-selector__wrapper,
.matching-card--selected .matching-card-selector__wrapper,
.matching-card:hover .matching-card-selector__wrapper {
  opacity: 1;
}

.matching-card-selector__wrapper :deep() {
  .el-checkbox__inner,
  .el-checkbox__input {
    width: 22px;
    height: 22px;
  }

  .el-checkbox__input:not(.is-checked) .el-checkbox__inner {
    background-color: $white;
    box-shadow: none;
  }

  .el-checkbox__input.is-checked .el-checkbox__inner {
    background-color: $malibu;
    border-color: $malibu;
  }
}

.matching-card-selector--label {
  width: 107px;
  height: 20px;
  padding: 2px 0;

  font-size: 12px;
  font-weight: bold;
  color: $manatee;
  text-align: center;
  text-transform: uppercase;
  background-color: $athens-gray;
  border-radius: 13px;
}

.matching-card-thumbnail__wrapper {
  position: absolute;
  top: -35px;
  left: calc(50% - 26px);
}

.matching-badge-list__wrapper {
  position: absolute;
  top: 0;
  right: 0;
  z-index: z("floaters");
  transform: translate(-4px, -4px);
}

.matching-card__headline {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 123px;
  margin-top: 2px;
  text-align: center;
  word-break: break-all;
  overflow-wrap: break-word;
}

.matching-card__title {
  @include grotesk(bold);

  margin-bottom: 3px;
  font-size: 16px;
  line-height: 19px;
}

.matching-card__subtitle {
  margin-bottom: 10px;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.2px;
}

.matching-card__title,
.matching-card__subtitle {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.matching-card__description {
  @include multiLineEllipsis(
    $font-size: 14px,
    $line-height: 19px,
    $lines-to-show: 2
  );

  margin-bottom: 10px;
  color: $manatee;
  word-break: break-word;
}
</style>

<style lang="scss">
.matching-card__tooltip.el-abaca-tooltip {
  padding: 5px 10px;
  pointer-events: none;

  /*
     * This will remove the standard behaviour of changing the cursor selector to text selector, even if the tooltips
     * remain with selectable to false
     */
  & > * {
    cursor: default;
  }
}
</style>
