import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, vShow as _vShow, withDirectives as _withDirectives, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-6f8062ea"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "supporter-registration-form__wrapper" }
const _hoisted_2 = { class: "supporter-registration-form__container" }
const _hoisted_3 = { class: "supporter-registration-form__locations-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MultiSelector = _resolveComponent("MultiSelector")!
  const _component_ElFormItem = _resolveComponent("ElFormItem")!
  const _component_ElRadio = _resolveComponent("ElRadio")!
  const _component_PxRadioGroup = _resolveComponent("PxRadioGroup")!
  const _component_ElForm = _resolveComponent("ElForm")!
  const _component_FormPresentationLayout = _resolveComponent("FormPresentationLayout")!
  const _component_PxButton = _resolveComponent("PxButton")!
  const _component_NavigationFooter = _resolveComponent("NavigationFooter")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_FormPresentationLayout, {
      "description-image-link": _ctx.viewCopy.imageLink,
      "description-image-title": _ctx.viewCopy.imageTitle,
      "description-text": _ctx.viewCopy.imageDescription,
      "has-return-button": _ctx.hasPreviousPage,
      "has-sticky-header": true,
      "content-vertical-alignment": "top",
      onPreviousPage: _ctx.onReturnButtonClick
    }, {
      content: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_ElForm, {
            ref: "supporterRegistrationForm",
            model: _ctx.fields,
            rules: _ctx.rules,
            class: "supporter-registration-form__body",
            onValidate: _ctx.updateBasicInterestsSubmitState
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ElFormItem, { prop: "meta.sectors" }, {
                default: _withCtx(() => [
                  _createVNode(_component_MultiSelector, {
                    modelValue: _ctx.fields.meta.sectors,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.fields.meta.sectors) = $event)),
                    "fetch-on-created": true,
                    "remote-method": _ctx.searchSectors,
                    "subtext-label-list": [
                  'common.components.multiSelector.sectors.subLabel',
                  'common.components.multiSelector.sectors.subLabelPlural',
                ],
                    label: "supporters.signup.form.fields.sector",
                    placeholder: "supporters.signup.form.placeholders.sector",
                    tip: "supporters.signup.form.tips.sector",
                    onInput: _ctx.onInterestsChange
                  }, null, 8, ["modelValue", "remote-method", "onInput"])
                ]),
                _: 1
              }),
              _createElementVNode("div", _hoisted_3, [
                _createVNode(_component_ElFormItem, {
                  label: _ctx.$t('supporters.signup.form.fields.locations'),
                  prop: "meta.locations"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_PxRadioGroup, {
                      modelValue: _ctx.fields.meta.hasSpecificLocations,
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.fields.meta.hasSpecificLocations) = $event))
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ElRadio, { value: true }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t("supporters.signup.form.locationOptions.specific")), 1)
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_ElRadio, { value: false }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t("supporters.signup.form.locationOptions.all")), 1)
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }, 8, ["modelValue"]),
                    _withDirectives(_createVNode(_component_MultiSelector, {
                      modelValue: _ctx.fields.meta.locations,
                      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.fields.meta.locations) = $event)),
                      "no-label": true,
                      "remote-method": _ctx.searchLocations,
                      "subtext-label-list": [
                    'common.components.multiSelector.locations.subLabel',
                    'common.components.multiSelector.locations.subLabelPlural',
                  ],
                      "validate-event": true,
                      class: "locations-selector",
                      "input-icon": "location",
                      placeholder: "supporters.signup.form.placeholders.locations",
                      tip: "supporters.signup.form.tips.location",
                      onInput: _ctx.formValidateLocations
                    }, null, 8, ["modelValue", "remote-method", "onInput"]), [
                      [_vShow, _ctx.fields.meta.hasSpecificLocations]
                    ])
                  ]),
                  _: 1
                }, 8, ["label"])
              ])
            ]),
            _: 1
          }, 8, ["model", "rules", "onValidate"])
        ])
      ]),
      _: 1
    }, 8, ["description-image-link", "description-image-title", "description-text", "has-return-button", "onPreviousPage"]),
    _createVNode(_component_NavigationFooter, null, {
      left: _withCtx(() => [
        _createVNode(_component_PxButton, {
          disabled: _ctx.isSubmissionDisabled,
          loading: _ctx.formLoading,
          class: "navigation-footer__main-cta",
          type: "primary",
          onClick: _ctx.onNextButtonClick
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.ctaCopy.next), 1)
          ]),
          _: 1
        }, 8, ["disabled", "loading", "onClick"]),
        (_ctx.skipEnabled)
          ? (_openBlock(), _createBlock(_component_PxButton, {
              key: 0,
              onClick: _ctx.onSkipButtonClick
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.ctaCopy.skip), 1)
              ]),
              _: 1
            }, 8, ["onClick"]))
          : _createCommentVNode("", true)
      ]),
      right: _withCtx(() => [
        (_ctx.skipEnabled)
          ? (_openBlock(), _createBlock(_component_PxButton, {
              key: 0,
              type: "link",
              onClick: _ctx.onSkipAllButtonClick
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.ctaCopy.setupCriteriaLater), 1)
              ]),
              _: 1
            }, 8, ["onClick"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ]))
}