<template>
  <div class="pricing-card">
    <h2 class="pricing-card__title">
      {{ title }}
    </h2>
    <h1 class="pricing-card__price">
      ${{ price }}
      <span v-if="priceNote" class="pricing-card__price-note">
        {{ priceNote }}
      </span>
    </h1>
    <PxButton
      v-if="ctaLabel"
      v-bind="buttonProps"
      :disabled="disabled"
      :label="ctaLabel"
      class="pricing-card__cta"
      @click="$emit('click')"
    />
    <h4 class="pricing-card__features-title">
      {{ featuresTitle }}
    </h4>
    <ul class="pricing-card__features-list">
      <li
        v-for="(feature, index) in features"
        :key="index"
        class="pricing-card__feature"
      >
        <PxIcon
          :name="feature.icon"
          :size="feature.iconSize ? feature.iconSize : 20"
        />
        {{ feature.label }}
      </li>
    </ul>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import {
  EPxButtonType,
  EPxButtonSize,
} from "@/components/px-button/px-button.types";

export interface IPricingCardFeature {
  label: string;
  icon: string;
  iconSize: number;
}

export default defineComponent({
  name: "PricingCard",

  props: {
    title: {
      type: String,
      required: true,
    },
    price: {
      type: Number,
      required: true,
    },
    priceNote: {
      type: String,
      default: null,
    },
    features: {
      type: Array as () => IPricingCardFeature[],
      required: true,
    },
    featuresTitle: {
      type: String,
      required: true,
    },
    ctaLabel: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    buttonProps(): any {
      return {
        type: EPxButtonType.BLUE,
        size: EPxButtonSize.LARGE,
      };
    },
  },
});
</script>

<style lang="scss" scoped>
.pricing-card {
  padding: 30px 23px;

  @include breakpoint-down(sm) {
    padding: 30px 23px 32px 23px;
  }

  border-radius: 5px;
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.04);
  border: 1px solid #eaebf0;
  background-color: $white;

  &__title {
    @include grotesk(semiBold);

    margin-bottom: 23px;

    font-size: 24px;
    line-height: 1.33;
    color: $ebony-clay;
  }

  &__price {
    @include grotesk(bold);

    display: flex;
    align-items: center;
    gap: 3px;
    margin-bottom: 26px;

    font-size: 36px;
    line-height: 1.22;
    letter-spacing: -0.72px;
    color: $ebony-clay;
  }

  &__price-note {
    margin-top: 15px;

    font-size: 14px;
    line-height: 1.43;
    color: #5f6d7e;
    letter-spacing: -0.33px;

    @include breakpoint-down(sm) {
      margin-top: 0;
      margin-bottom: 4px;
    }
  }

  &__cta {
    height: 40px;
    padding: 0 17px;
    margin-bottom: 23px;

    @include breakpoint-down(sm) {
      display: block;
      width: 100%;
    }
  }

  &__features-title {
    @include grotesk(semiBold);

    margin: 0;

    font-size: 16px;
    line-height: 1.15;
    color: $ebony-clay;
    letter-spacing: -0.14px;
  }

  &__features-list {
    padding: 0;
    margin: 0;
  }

  &__feature {
    display: flex;
    align-items: flex-start;
    gap: 12px;

    list-style: none;
    padding: 0;
    margin: 16px 0 0 0;

    font-size: 16px;
    line-height: 1.5;
    letter-spacing: -0.5px;
    color: $ebony-clay;

    :deep() .px-icon {
      position: relative;
      top: 3px;

      @include breakpoint-down(sm) {
        top: 1px;
      }
    }

    @include breakpoint-down(sm) {
      align-items: center;

      &:first-of-type {
        margin-top: 12px;
      }
    }
  }
}
</style>
