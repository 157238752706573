<template>
  <ElDialog
    ref="modal"
    v-model="innerVisibility"
    :close-on-click-modal="false"
    :top="`0`"
    class="subscription-modal el-dialog--compact-footer"
    width="606px"
  >
    <template #header>
      <header>
        <PxIcon :size="40" name="abaca-logo--circle" />
        <h1>
          {{ $t("profile.subscriptionModal.title") }}
        </h1>
        <p>
          {{ $t("profile.subscriptionModal.description[0]") }}
        </p>
        <p>
          {{ $t("profile.subscriptionModal.description[1]") }}
        </p>
      </header>
    </template>
    <main>
      <div class="subscription-modal__card-wrapper">
        <PricingCard v-bind="pricingCardsData.free" />
        <PxButton
          class="subscription-modal__card-cta"
          v-bind="freeButtonProps"
          @click="continueAsFreeUser"
        />
      </div>
      <div class="subscription-modal__card-wrapper">
        <PricingCard v-bind="pricingCardsData.pro" />
        <PxButton
          class="subscription-modal__card-cta"
          v-bind="proButtonProps"
          @click="upgradeToProUser"
        />
      </div>
    </main>
  </ElDialog>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import ElDialogMixin from "@/mixins/el-dialog.mixin";
import PricingCard from "@/modules/profile/components/account-settings/pricing-card.vue";

import {
  EPxButtonType,
  EPxButtonSize,
} from "@/components/px-button/px-button.types";
import { ROUTE_ACCOUNT_SETTINGS } from "@/modules/profile/services/router/routes-names";
import { EAccountSettingsNavigationTabs } from "@/modules/profile/components/account-settings/account-settings-navigation.vue";
import { SUBSCRIPTIONS_DATA } from "@/modules/profile/pricing-cards-data";

export default defineComponent({
  name: "SubscriptionModal",

  components: {
    PricingCard,
  },

  mixins: [ElDialogMixin],

  computed: {
    pricingCardsData(): any {
      return SUBSCRIPTIONS_DATA;
    },

    freeButtonProps(): any {
      return {
        type: EPxButtonType.DEFAULT,
        size: EPxButtonSize.LARGE,
        label: this.$t("profile.subscriptionModal.ctas[0]"),
      };
    },

    proButtonProps(): any {
      return {
        type: EPxButtonType.BLUE,
        size: EPxButtonSize.LARGE,
        label: this.$t("profile.subscriptionModal.ctas[1]"),
      };
    },
  },

  methods: {
    continueAsFreeUser() {
      this.$emit("update:visibility", false);
    },

    upgradeToProUser() {
      this.$router.push({
        name: ROUTE_ACCOUNT_SETTINGS,
        query: { tab: EAccountSettingsNavigationTabs.SUBSCRIPTION },
      });
    },
  },
});
</script>

<style lang="scss">
.subscription-modal {
  padding: 32px;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  @include breakpoint-down(sm) {
    max-height: 700px;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      width: 4px;
      padding: 4px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
      margin: 10px;
    }

    &::-webkit-scrollbar-thumb {
      background: $mischka;
      border-radius: 10px;
    }
  }

  .el-dialog__header {
    padding: 0;
    text-align: center;
    background-color: transparent;
    border: none;

    h1 {
      margin-top: 13px;
      margin-bottom: 9px;

      letter-spacing: -0.2px;
      font-size: 20px;
      line-height: 1.5;
      color: $ebony-clay;
    }

    p {
      margin-bottom: 6px;

      font-size: 15px;
      line-height: 1.6;
      color: $ebony-clay;
      letter-spacing: -0.3px;
    }

    p:first-of-type {
      margin-bottom: 8px;
    }
  }

  .el-dialog__headerbtn {
    top: 20px;
    right: 14px;
    z-index: z("floaters");
    width: 22px;
    height: 22px;
  }

  .el-dialog__body {
    padding: 18px 0 0 0;
  }

  main {
    display: flex;
    flex-direction: row;
    gap: 16px;

    @include breakpoint-down(sm) {
      flex-direction: column;
    }
  }

  &__card-wrapper {
    flex: 1;
    display: flex;
    gap: 24px;
    flex-direction: column;
    justify-content: space-between;

    .pricing-card {
      flex: 1;
      background-image: linear-gradient(to top, #f0f1f5, #fff 15%);
      padding: 22px 23px 30px 23px;
    }

    .pricing-card__title {
      margin-bottom: 15px;
    }

    .pricing-card__price {
      margin-bottom: 20px;
    }

    .pricing-card__price-note {
      margin-top: 18px;
      margin-left: 2px;
    }

    .pricing-card__features-title {
      letter-spacing: -0.15px;
    }

    .pricing-card__features-list {
      margin-top: 12px;
      margin-left: 6px;
    }

    .pricing-card__feature {
      margin-top: 4px;

      font-size: 14px;
      line-height: 19.1px;
      gap: 14px;
      letter-spacing: -0.33px;
      color: #64748b;

      &:not(:first-of-type) {
        margin-top: 2px;
      }
    }

    .pricing-card__feature .px-icon {
      top: 7px;
    }
  }

  &__card-cta {
    height: 40px;
  }
}
</style>
