import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createVNode as _createVNode, withCtx as _withCtx, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-2b267a5b"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container-fluid supporters-container-fluid" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AssessmentMobilePlaceholder = _resolveComponent("AssessmentMobilePlaceholder")!
  const _component_LevelCard = _resolveComponent("LevelCard")!
  const _component_RangeLevelsModal = _resolveComponent("RangeLevelsModal")!
  const _component_MobileLevelRangeSlider = _resolveComponent("MobileLevelRangeSlider")!
  const _component_MobileFooterNav = _resolveComponent("MobileFooterNav")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.isLoading)
        ? (_openBlock(), _createBlock(_component_AssessmentMobilePlaceholder, { key: 0 }))
        : _createCommentVNode("", true),
      (!_ctx.isLoading)
        ? (_openBlock(), _createBlock(_component_LevelCard, {
            key: 1,
            category: _ctx.currentCategory,
            level: _ctx.currentSelectedStepLevel,
            "level-description": _ctx.levelDescription
          }, null, 8, ["category", "level", "level-description"]))
        : _createCommentVNode("", true)
    ]),
    (!_ctx.isLoading)
      ? (_openBlock(), _createBlock(_component_RangeLevelsModal, {
          key: 0,
          visibility: _ctx.rangeLevelsModalVisible,
          "onUpdate:visibility": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.rangeLevelsModalVisible) = $event))
        }, null, 8, ["visibility"]))
      : _createCommentVNode("", true),
    _createVNode(_component_MobileFooterNav, {
      loading: _ctx.isLoading,
      onOnSave: _ctx.onSave
    }, {
      default: _withCtx(() => [
        _createVNode(_component_MobileLevelRangeSlider, {
          color: _ctx.currentCategory.color,
          levels: _ctx.currentCategory.categoryDetails.length,
          "range-restriction": _ctx.rangeRestriction,
          value: _ctx.selectedStepLevel,
          width: "280px",
          onInput: _ctx.onLevelChange,
          onRangeLevelsModal: _ctx.rangeTipButtonClickHandler
        }, null, 8, ["color", "levels", "range-restriction", "value", "onInput", "onRangeLevelsModal"])
      ]),
      _: 1
    }, 8, ["loading", "onOnSave"])
  ]))
}