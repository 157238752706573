import { ActionTree } from "vuex";
import { RootState } from "@/services/store/root-state";
import { IGenericState } from "@/services/store/generic/generic-state.interface";
import { GenericState } from "@/services/store/generic/generic-state.module";
import { GenericProvider } from "@/services/data/generic-provider";

/**
 * Returns default actions for generic state.
 */
export const getGenericStateActions = <T>(
  provider: GenericProvider<T>,
): ActionTree<IGenericState<T>, RootState> => {
  return {
    /**
     * Fetch single object for data model.
     * @param context
     * @param queryParams
     */
    async [GenericState.Action.GET_VALUE](
      { commit, state },
      queryParams = {},
    ): Promise<T | null> {
      commit(GenericState.Mutation.SET_LOADING, true);
      commit(GenericState.Mutation.SET_ERROR, null);

      let value = state.value;

      try {
        // Try to fetch value and update if successful
        value = await provider.get(queryParams);
        commit(GenericState.Mutation.SET_VALUE, value);
      } catch (error) {
        commit(GenericState.Mutation.SET_ERROR, error || true);
      } finally {
        commit(GenericState.Mutation.SET_LOADING, false);
      }

      // Return current value, if error, or updated value if successful
      return value;
    },

    /**
     * Fetch list for data model.
     * @param context
     * @param queryParams
     */
    async [GenericState.Action.GET_VALUES](
      { commit, state },
      queryParams = {},
    ): Promise<Array<T>> {
      commit(GenericState.Mutation.SET_LOADING, true);
      commit(GenericState.Mutation.SET_ERROR, null);

      let values = state.values;

      try {
        // Try to fetch values and update if successful
        values = await provider.list(queryParams);
        commit(GenericState.Mutation.SET_VALUES, values);
      } catch (error) {
        commit(GenericState.Mutation.SET_ERROR, error || true);
      } finally {
        commit(GenericState.Mutation.SET_LOADING, false);
      }

      // Return current values, if error, or updated values if successful
      return values;
    },

    /**
     * Create value.
     * @param context
     * @param payload
     */
    async [GenericState.Action.CREATE_VALUE](
      { commit },
      payload = {},
    ): Promise<T | null> {
      commit(GenericState.Mutation.SET_LOADING, true);
      commit(GenericState.Mutation.SET_ERROR, null);

      let value = null;

      try {
        value = await provider.create(payload);
      } catch (error) {
        commit(GenericState.Mutation.SET_ERROR, error || true);
      } finally {
        commit(GenericState.Mutation.SET_LOADING, false);
      }

      // Return create value or null
      return value;
    },

    /**
     * Patch value.
     *
     * @param commit
     * @param uid
     * @param payload
     */
    async [GenericState.Action.PATCH_VALUE](
      { commit },
      {
        uid,
        payload,
        queryParams = {},
      }: { uid: string | number; payload: any; queryParams: any },
    ): Promise<T | null> {
      commit(GenericState.Mutation.SET_LOADING, true);
      commit(GenericState.Mutation.SET_ERROR, null);

      let value = null;

      try {
        value = await provider.patch(uid, payload, queryParams);
      } catch (error) {
        commit(GenericState.Mutation.SET_ERROR, error || true);
      } finally {
        commit(GenericState.Mutation.SET_LOADING, false);
      }

      // Return create value or null
      return value;
    },

    /**
     * Remove value.
     *
     * @param commit
     * @param id
     */
    async [GenericState.Action.REMOVE_VALUE](
      { commit },
      id: string | number,
    ): Promise<void> {
      commit(GenericState.Mutation.SET_LOADING, true);
      commit(GenericState.Mutation.SET_ERROR, null);

      try {
        await provider.destroy(id);
      } catch (error) {
        commit(GenericState.Mutation.SET_ERROR, error || true);
      } finally {
        commit(GenericState.Mutation.SET_LOADING, false);
      }
    },
  };
};
