<template>
  <PxPanel
    :title="category.requirements_title"
    class="company-requirements"
    icon="know-more--orange"
  >
    <div v-markdown="levelDescription" class="description-cmp" />
  </PxPanel>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "CompanyRequirements",

  props: {
    /**
     * Current category.
     */
    category: {
      type: Object as () => any,
      required: true,
    },

    /**
     * Current level.
     */
    level: {
      type: Number,
      required: true,
    },
  },

  computed: {
    levelDescription(): string {
      const description = this.category.categoryDetails.find(
        (item: any) => item.level.value === this.level,
      );

      const params = {
        description: description.requirements,
        level: this.level,
        category: this.category.name,
      };

      return this.$t(
        "supporters.assessment.rangeDescriptionTemplate",
        params,
      ) as string;
    },
  },
});
</script>

<style lang="scss" scoped>
$--assessment-max-tablet-width: 978px;

.company-requirements {
  max-width: 330px;
  height: auto;

  @media (max-width: $--assessment-max-tablet-width) {
    width: 100%;
    max-width: unset;
  }
}
</style>
