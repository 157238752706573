<template>
  <ElDialog
    ref="modal"
    v-model="innerVisibility"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :show-close="true"
    :title="componentCopy.title"
    class="user-details-modal"
    data-testid="user-details-modal"
    width="466px"
    @close="onModalClose"
  >
    <PxModalMessage :visible="hasServerErrors" />
    <i18n-t
      class="user-details-modal__sign-in-panel"
      keypath="supporters.component.userDetailsModal.existingAccount"
      tag="div"
    >
      <template #signIn>
        <span
          class="user-details-modal__sign-in-panel-link"
          data-testid="user-details-modal__sign-in-panel-link"
          @click="gotoSignInModal"
        >
          {{ componentCopy.signIn }}
        </span>
      </template>
    </i18n-t>
    <div class="supporter-registration-form__container">
      <ElForm
        ref="formWrapper"
        :model="fields"
        :rules="rules"
        class="supporter-registration-form__body"
        @validate="updateSubmitState"
      >
        <ElFormItem
          :error="errors.getMessage('email') || ''"
          :label="formEmailCopy.label"
          prop="email"
        >
          <ElInput
            v-model="fields.email"
            :placeholder="formEmailCopy.placeholder"
            type="email"
          />
        </ElFormItem>
        <ElFormItem
          :label="$t('supporters.signup.form.fields.supporterName')"
          prop="company.name"
        >
          <ElInput
            v-model="fields.company.name"
            :placeholder="$t('supporters.signup.form.fields.supporterName')"
          />
        </ElFormItem>
        <ElFormItem
          :label="$t('supporters.signup.form.fields.supporterType')"
          prop="supporter.types"
        >
          <ElCheckboxGroup v-model="selectedSupporterTypes">
            <ElCheckbox
              v-for="(type, key) in availableSupporterTypes"
              :key="key"
              :value="type.id"
            >
              {{ type.name }}
              <ElTooltip
                v-if="type.label"
                placement="top"
                popper-class="supporter-registration-form__type-tooltip el-abaca-tooltip"
              >
                <template #content>
                  <div v-html="type.label" />
                </template>
                <PxIcon
                  :size="16"
                  class="supporter-registration-form__type-question-icon"
                  name="icon-question"
                />
              </ElTooltip>
            </ElCheckbox>
            <ElCheckbox
              value=""
              @change="(value) => otherTypeChangeHandler(value)"
            >
              {{ $t("supporters.signup.form.fields.otherSupporterType") }}
            </ElCheckbox>
          </ElCheckboxGroup>
          <ElInput
            v-if="hasOtherSupporterType"
            v-model="otherType"
            :placeholder="
              $t('supporters.signup.form.placeholders.supporterType')
            "
            class="supporter-registration-form__other-type-input"
          />
        </ElFormItem>
        <ElFormItem
          :label="$t('supporters.signup.form.fields.location')"
          prop="company.location"
        >
          <PxInputPlaces
            id="supporter-registration-form__location-input"
            v-model="fields.company.location"
            :placeholder="$t('supporters.signup.form.fields.location')"
            :use-geolocation="true"
          />
        </ElFormItem>
        <ElFormItem :show-message="false" prop="terms" required>
          <ElCheckbox
            v-model="fields.terms"
            class="supporter-registration-form__selector"
          >
            <i18n-t
              class="supporter-registration-form__selector-text"
              keypath="supporters.form.terms"
              tag="p"
            >
              <template #terms>
                <a
                  :href="$options.static.termsOfUseUrl"
                  class="text--nice-blue"
                  target="_blank"
                >
                  {{ $t("supporters.form.termsOfUse") }}
                </a>
              </template>
              <template #privacy>
                <a
                  :href="$options.static.privacyPolicyUrl"
                  class="text--nice-blue"
                  target="_blank"
                >
                  {{ $t("supporters.form.privacyPolicy") }}
                </a>
              </template>
            </i18n-t>
          </ElCheckbox>
        </ElFormItem>
        <div class="supporter-registration-form__cta">
          <PxButton
            :disabled="isSubmissionDisabled"
            type="primary"
            @click="onSignUpClick"
          >
            {{ ctaCopy.signUp }}
          </PxButton>
        </div>
      </ElForm>
    </div>
  </ElDialog>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { ErrorsManager } from "@/services/errors-manager";

import {
  allFormFieldsValid,
  generateEmailValidator,
  generateRequiredValidator,
  generateSupporterTypesValidator,
  generateCheckboxValidator,
} from "@/services/errors/validator-generators";
import { ISupporterFlow } from "@/modules/supporters/services/data/supporter-flow/supporter-flow.interface";
import { CheckboxValueType, FormInstance } from "element-plus";
import { ILocation } from "@/services/data/location/location.interface";
import { SupporterTypeState } from "@/modules/supporters/services/store/supporter-type/supporter-type.module";
import { ISupporterType } from "@/modules/supporters/services/data/supporter-type/supporter-type.interface";
import {
  SUPPORTERS_TERMS_OF_USE_URL,
  SUPPORTERS_PRIVACY_POLICY_URL,
} from "@/modules/supporters/constants";
import {
  ISupporterCtasCopy,
  ISupporterUserDetailsModal,
} from "@/modules/supporters/services/data/copy-types/supporter-copy-types";
import ElDialogMixin from "@/mixins/el-dialog.mixin";

export default defineComponent({
  name: "UserDetailsModal",

  mixins: [ElDialogMixin],

  static: {
    termsOfUseUrl: SUPPORTERS_TERMS_OF_USE_URL,
    privacyPolicyUrl: SUPPORTERS_PRIVACY_POLICY_URL,
  },

  data() {
    return {
      currentForm: null as null | FormInstance,
      hasServerErrors: false,
      isLoadingSubmission: false,
      isSubmissionDisabled: true,
      formWrapperScrollAtEnd: false,
      isSubmissionClose: false,

      selectedSupporterTypes: [] as Array<number>,
      hasOtherSupporterType: false as CheckboxValueType,
      otherType: "",

      fields: {
        email: "",

        company: {
          name: "",
          location: {} as Partial<ILocation>,
          networks: [] as Array<number>,
        },
        supporter: {
          types: [] as Array<number>,
        },
        terms: false,
      } as Partial<ISupporterFlow>,

      rules: {
        email: generateEmailValidator(
          this,
          "authentication.signup.form.fields.email",
          true,
        ),

        "company.name": generateRequiredValidator(
          this,
          "supporters.signup.form.fields.supporterName",
        ),

        "company.location": generateRequiredValidator(
          this,
          "supporters.signup.form.fields.location",
        ),

        "supporter.types": generateSupporterTypesValidator(
          this,
          "fields.supporter.other_type",
        ),

        terms: generateCheckboxValidator(),
      },

      errors: new ErrorsManager({
        email: {
          unique: this.$t("authentication.signup.form.errors.email.unique"),
        },
      }),
    };
  },

  computed: {
    componentCopy() {
      return this.$tm(
        "supporters.component.userDetailsModal",
      ) as ISupporterUserDetailsModal;
    },

    formEmailCopy() {
      return this.$tm("supporters.form.email") as {
        label: string;
        placeholder: string;
      };
    },

    ctaCopy() {
      return this.$tm("supporters.cta") as ISupporterCtasCopy;
    },

    availableSupporterTypes(): Array<ISupporterType> {
      return this.$store.getters[SupporterTypeState.Getter.VALUES];
    },
  },

  watch: {
    async visibility() {
      await this.$nextTick();
      this.setupFormRef();
    },

    selectedSupporterTypes: {
      deep: true,
      handler(currentSelectedTypes: Array<number>) {
        // Filter out invalid values
        (this.fields.supporter as any).types = currentSelectedTypes.filter(
          (type: number) => !!type,
        );
      },
    },

    hasOtherSupporterType(state: boolean) {
      if (!state) {
        delete (this.fields.supporter as any).other_type;
      }
    },

    otherType(value: string) {
      if (this.hasOtherSupporterType) {
        (this.fields.supporter as any).other_type = value;
      }
    },
  },

  async created() {
    await this.$nextTick();
    this.setupFormRef();
    this.checkFormWrapperScroll();
  },

  methods: {
    setupFormRef() {
      if (!this.currentForm) {
        this.currentForm = this.$refs.formWrapper as FormInstance;
      }
    },

    /**
     * Allows to add custom other supporter types
     */
    otherTypeChangeHandler(checked: CheckboxValueType) {
      this.hasOtherSupporterType = checked;
    },

    /**
     * Handle the Google sign up button click.
     */
    onGoogleSignUpButtonClick() {
      // TODO: sign up with Google logic
    },

    onSignUpClick() {
      // Flag to prevent side-effect onClose action
      this.isSubmissionClose = true;

      // Spread this fields to avoid store injection via memory reference
      const submissionData = this.fields ? { ...this.fields } : undefined;
      this.$emit("submit", submissionData, this.errors);
    },

    beforeCloseHandler() {
      (this.$refs.modal as any).$emit("confirm-cancel-edit");
    },

    gotoSignInModal() {
      this.$emit("sign-in", true);
    },

    onModalClose() {
      if (this.isSubmissionClose) {
        return;
      }

      this.$router.back();
    },

    checkFormWrapperScroll() {
      // Required unknown first to overlap typings
      const wrapper = this.currentForm as unknown as HTMLElement;

      if (!wrapper) {
        return;
      }

      this.formWrapperScrollAtEnd =
        wrapper.scrollTop === wrapper.scrollHeight - wrapper.offsetHeight;
    },

    updateSubmitState() {
      this.isSubmissionDisabled = !allFormFieldsValid(
        this.currentForm,
        this.rules,
      );
    },
  },
});
</script>

<style lang="scss">
.user-details-modal {
  .el-dialog__header {
    padding-right: 40px;
    padding-bottom: 20px;

    @include breakpoint-up(md) {
      padding-right: 60px;
    }
  }

  .el-dialog__body {
    padding: 0;
    overflow: hidden;
  }

  &__sign-in-panel {
    min-height: 32px;
    padding: 5px 30px;
    font-size: 13px;
    line-height: 22px;
    color: $ebony-clay;
    background-color: $athens-gray;
    transform: translateY(-4px);

    @include breakpoint-down(sm) {
      padding: 5px 15px;
    }

    &-link {
      @include grotesk(semiBold);

      font-size: 13px;
      line-height: 22px;
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .supporter-registration-form__container {
    position: relative;
    top: 8px;
    display: grid;
    grid-gap: 40px;
    padding: 18px 29px 20px 29px;
  }

  .supporter-registration-form__body {
    display: grid;
    grid-gap: 24px;
  }

  .supporter-registration-form__body .el-form-item {
    margin: 0;
  }

  .supporter-registration-form__body .el-form-item:nth-child(2),
  .supporter-registration-form__body .el-form-item:nth-child(3) {
    position: relative;
    top: 2px;
  }

  .supporter-registration-form__body .el-form-item:nth-child(4) {
    position: relative;
    top: 4px;
  }

  .supporter-registration-form__body .el-form-item__label {
    padding: 0 12px 4px 0;
  }

  .supporter-registration-form__body .el-divider {
    margin: 8px 0;
    background-color: rgba($manatee, 0.3);
  }

  .supporter-registration-form__body .el-divider__text {
    @include grotesk(semiBold);

    color: $manatee;
  }

  .supporter-registration-form__body .el-input__inner {
    margin: 0;
    line-height: 40px;
  }

  .supporter-registration-form__body .el-checkbox-group {
    display: inline-flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    width: 100%;
    margin-top: 9px;
  }

  .supporter-registration-form__body .el-checkbox {
    flex-basis: 43%;
    margin-left: 0;

    &:nth-child(n + 3) {
      min-width: 122px;
      margin-top: 10px;
    }
  }

  .supporter-registration-form__body .el-checkbox__label {
    position: relative;
    top: -1px;
    width: 100%;
  }

  .supporter-registration-form__body .other-type-input {
    margin-top: 10px;
  }

  .supporter-registration-form__selector {
    word-wrap: break-word;
    white-space: normal;

    .el-checkbox__input {
      bottom: 22px;
    }

    .el-checkbox__label {
      max-width: 380px;
      padding-left: 12px;
    }

    .text--nice-blue {
      text-decoration: none;
    }
  }

  .supporter-registration-form__selector-text {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: $ebony-clay;
  }

  .supporter-registration-form__cta {
    position: relative;
    bottom: 13px;
  }

  .supporter-registration-form__cta .el-button {
    width: 100%;
  }

  .supporter-registration-form__google-cta .el-button span {
    @include grotesk(medium);

    position: relative;
    color: $ebony-clay;
    text-transform: none;
  }

  .supporter-registration-form__google-logo {
    position: absolute;
    left: 0;
  }

  .supporter-registration-form__footer {
    display: grid;
    grid-gap: 22px;
  }

  .supporter-registration-form__footer-text,
  .supporter-registration-form__footer-text .el-button span {
    font-size: 14px;
    line-height: 20px;
  }

  .supporter-registration-form__footer-text a,
  .supporter-registration-form__footer-text .el-button span {
    @include grotesk(medium);

    text-decoration: none;

    &:hover {
      color: $malibu;
    }
  }

  .supporter-registration-form__footer-text {
    color: $ebony-clay;
  }
}
</style>
