import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createBlock as _createBlock, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-0b9634d2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "list-reports-chart" }
const _hoisted_2 = { class: "list-reports-chart__header" }
const _hoisted_3 = { class: "list-reports-chart__header-content" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { key: 1 }
const _hoisted_6 = { class: "list-reports-chart__content" }
const _hoisted_7 = {
  key: 0,
  class: "list-reports-chart__note"
}
const _hoisted_8 = {
  key: 0,
  class: "list-reports-chart--overlay"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ChartLocked = _resolveComponent("ChartLocked")!
  const _component_ChartsLockedModal = _resolveComponent("ChartsLockedModal")!
  const _component_DoughnutChart = _resolveComponent("DoughnutChart")!
  const _component_ChartsTable = _resolveComponent("ChartsTable")!
  const _component_PxIcon = _resolveComponent("PxIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (_ctx.chartTitle)
          ? (_openBlock(), _createElementBlock("h1", _hoisted_4, _toDisplayString(_ctx.chartTitle), 1))
          : _createCommentVNode("", true),
        (_ctx.chartDescription)
          ? (_openBlock(), _createElementBlock("p", _hoisted_5, _toDisplayString(_ctx.chartDescription), 1))
          : _createCommentVNode("", true)
      ])
    ]),
    _createElementVNode("div", _hoisted_6, [
      (_ctx.showLockedState)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _createVNode(_component_ChartLocked, {
              content: _ctx.lockedState,
              onClick: _ctx.showPrivacyModal
            }, null, 8, ["content", "onClick"]),
            (_ctx.privacyModalVisible)
              ? (_openBlock(), _createBlock(_component_ChartsLockedModal, {
                  key: 0,
                  visibility: _ctx.privacyModalVisible,
                  "onUpdate:visibility": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.privacyModalVisible) = $event)),
                  onNavigate: _ctx.goToInitiativePage
                }, null, 8, ["visibility", "onNavigate"]))
              : _createCommentVNode("", true)
          ], 64))
        : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
            _createVNode(_component_DoughnutChart, {
              "chart-height": _ctx.chartHeight,
              "chart-width": _ctx.chartWidth,
              data: _ctx.doughnutChartData
            }, null, 8, ["chart-height", "chart-width", "data"]),
            _createVNode(_component_ChartsTable, {
              colors: _ctx.backgroundColors,
              data: _ctx.allLabelsValues,
              "first-column-header": _ctx.theme,
              labels: _ctx.allChartLabels,
              percentages: _ctx.allLabelsPercentage
            }, null, 8, ["colors", "data", "first-column-header", "labels", "percentages"]),
            (_ctx.note)
              ? (_openBlock(), _createElementBlock("span", _hoisted_7, [
                  _createVNode(_component_PxIcon, {
                    size: 14,
                    name: "information--light-gray"
                  }),
                  _createElementVNode("small", null, _toDisplayString(_ctx.note), 1)
                ]))
              : _createCommentVNode("", true)
          ], 64))
    ]),
    (_ctx.showLockedState)
      ? (_openBlock(), _createElementBlock("div", _hoisted_8))
      : _createCommentVNode("", true)
  ]))
}