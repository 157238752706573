import {
  GenericProvider,
  Provider,
  EProviderFeatures,
} from "@/services/data/generic-provider";
import { IPasswordChangeRequest } from "./password-change.types";
import { baseAPIUrl } from "@/services/utils/utils";

@Provider(`${baseAPIUrl}/user/password/change`, [EProviderFeatures.CREATE])
class PasswordChangeProvider extends GenericProvider<IPasswordChangeRequest> {}

export const passwordChangeProvider = new PasswordChangeProvider();
