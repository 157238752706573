import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-0d68ef14"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "submission-panel__wrapper"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SubmissionNotification = _resolveComponent("SubmissionNotification")!
  const _component_SubmissionPanel = _resolveComponent("SubmissionPanel")!

  return (_ctx.submission)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_ctx.showNotification)
          ? (_openBlock(), _createBlock(_component_SubmissionNotification, {
              key: 0,
              affiliate: _ctx.submittedAffiliate
            }, null, 8, ["affiliate"]))
          : _createCommentVNode("", true),
        _createVNode(_component_SubmissionPanel, { submission: _ctx.submission }, null, 8, ["submission"])
      ]))
    : _createCommentVNode("", true)
}