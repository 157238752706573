import {
  GenericProvider,
  Provider,
  EProviderFeatures,
} from "@/services/data/generic-provider";
import { IMatchingInterest } from "./matching-interest.interface";
import { baseAPIUrl } from "@/services/utils/utils";

@Provider(`${baseAPIUrl}/interested`, [
  EProviderFeatures.LIST,
  EProviderFeatures.CREATE,
])
class MatchingInterestProvider extends GenericProvider<IMatchingInterest> {}

export const matchingInterestProvider = new MatchingInterestProvider();
