<template>
  <div v-feature="'i18n'" class="lang-switcher">
    <PxIcon :size="14" class="lang-switcher__icon" name="info" />
    <p>
      {{ $t("common.languageSwitcher.availableLanguages") }}
      <PxButton
        v-bind="switchLanguageButtonProps"
        data-testid="switch-language-button"
        @click="localeChange"
      />
    </p>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { loadedLocales, changeLocale } from "@/services/i18n/locale-utils";
import { EPxButtonType } from "@/components/px-button/px-button.types";
import { EViralLevelActions } from "@/services/store/viral-level/viral-level-types";
import { ECategoryActions } from "@/services/store/category/category-types";
import { ENTREPRENEUR_USER_GROUP_ID } from "@/modules/common/constants";
import { ELevelActions } from "@/services/store/levels/levels-types";

export default defineComponent({
  name: "LangSwitcher",

  static: {
    switchLanguageButton: {
      type: EPxButtonType.LINK_WHITE,
    },
  },

  computed: {
    switchLanguageButtonProps(): any {
      return {
        ...this.$options.static.switchLanguageButton,
        label: this.$t("common.languageSwitcher.switchLanguage"),
      };
    },
  },

  methods: {
    async localeChange(): Promise<void> {
      const newLocale = this.$i18n.locale === "en" ? "es" : "en";

      await changeLocale(newLocale, loadedLocales, document);

      this.$emitter.emit("locale-changed");

      // Fetch backend translations
      this.$store.dispatch(EViralLevelActions.COMPUTE_LEVEL, {
        skipLoading: true,
      });
      this.$store.dispatch(ECategoryActions.FETCH, {
        group: ENTREPRENEUR_USER_GROUP_ID,
      });
      this.$store.dispatch(ELevelActions.FETCH, {
        group: ENTREPRENEUR_USER_GROUP_ID,
      });
    },
  },
});
</script>
<style scoped lang="scss">
.lang-switcher {
  position: absolute;
  top: 0;
  left: 50%;
  z-index: z("default") + 1;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 30px;
  padding: 13px 0 15px;
  overflow: hidden;
  background-color: $malibu;

  transform: translateX(-50%);

  @include breakpoint-down(md) {
    height: auto;
    max-height: 69px;
    padding: 6px 20px;
  }

  p,
  span,
  :deep() .el-button.el-button--link-white span {
    font-family: "HKGrotesk", "Helvetica", sans-serif;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    color: $white;
    letter-spacing: -0.2px;
  }

  p {
    position: relative;
    right: 5px;
    left: -1px;
    margin: 0;

    @include breakpoint-down(md) {
      bottom: 1px;
      left: 10px;
      line-height: 19px;
      text-align: center;
    }
  }

  :deep() .el-button.el-button--link-white span {
    font-weight: bold;
    text-decoration: underline;
    cursor: pointer;
  }

  :deep() .el-button.el-button--link-white {
    height: auto;
    padding: 0;
  }

  &__icon {
    position: relative;
    top: 1px;
    right: 7px;
    flex-shrink: 0;
    width: 14px;
    height: 14px;
    padding: 0;

    @include breakpoint-down(md) {
      position: absolute;
      top: 8px;
      left: 3%;
    }
  }
}
</style>
