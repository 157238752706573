<template>
  <div :class="align" class="assessmentFluctuation">
    <div
      v-if="upChanges.length > 0"
      class="assessmentFluctuation-lineWrapper assessmentFluctuation-lineWrapper--up"
    >
      <div class="assessmentFluctuation-line">
        {{ upChanges }}
      </div>
    </div>
    <div
      v-if="downChanges.length > 0"
      class="assessmentFluctuation-lineWrapper assessmentFluctuation-lineWrapper--down"
    >
      <div class="assessmentFluctuation-line">
        {{ downChanges }}
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { IAssessmentLevel } from "@/services/data/assessment/assessment.interface";
import { ICategory } from "@/services/data/category/category.interface";

export default defineComponent({
  props: {
    align: {
      type: String,
      default: "center",
      validator(val: string) {
        return ["center", "left", "right"].includes(val);
      },
    },
    prevAssessment: {
      type: Array as () => any[],
      default: () => [],
    },
    currentAssessment: {
      type: Array as () => any[],
      default: () => [],
    },
  },

  computed: {
    categories(): Array<ICategory> {
      return this.$store.get("categories.data");
    },

    changes() {
      const context = this as any;

      if (
        context.prevAssessment.length === 0 ||
        context.currentAssessment.length === 0 ||
        context.categories.length === 0
      ) {
        return [];
      }

      return context.prevAssessment
        .map((entry: IAssessmentLevel, index: number) => {
          const curEntry = context.currentAssessment[index] as IAssessmentLevel;

          if (curEntry.level === entry.level) {
            return null;
          } else if ((curEntry as any).level > (entry as any).level) {
            return {
              ...curEntry,
              raised: true,
            };
          }

          return {
            ...curEntry,
            level: curEntry.level !== null ? curEntry.level : 0,
            raised: false,
          };
        })
        .filter((entry: any) => entry !== null)
        .map((entry: any) => {
          const category = this.categories.find(
            (e: any) => e.id === entry.category,
          );

          return {
            ...entry,
            category,
          };
        });
    },

    upChanges(): any {
      // Filter changes that are "raised"
      const filteredChanges = this.changes
        ? this.changes.filter((entry: any) => !!entry.raised && entry.category)
        : [];
      const changesAsFormatedText = filteredChanges.length
        ? filteredChanges.reduce(
            (prev: string, cur: any) =>
              `${prev}, ${cur.category.name} ${cur.level}`,
            "",
          )
        : "";

      return typeof changesAsFormatedText === "string"
        ? // Remove unwanted characters at the beginning
          changesAsFormatedText.substr(2)
        : "";
    },

    downChanges(): any {
      // Filter changes that are "raised"
      const filteredChanges = this.changes
        ? this.changes.filter((entry: any) => !entry.raised && entry.category)
        : [];
      const changesAsFormatedText = filteredChanges.length
        ? filteredChanges.reduce(
            (prev: string, cur: any) =>
              `${prev}, ${cur.category.name} ${cur.level}`,
            "",
          )
        : "";

      return typeof changesAsFormatedText === "string"
        ? // Remove unwanted characters at the beginning
          changesAsFormatedText.substr(2)
        : "";
    },
  },
});
</script>

<style lang="scss" scoped>
.assessmentFluctuation {
  @include breakpoint-up(sm) {
    display: flex;
    flex-direction: column;
    align-items: center;

    &.left {
      align-items: flex-start;
    }

    &.right {
      align-items: flex-end;
    }
  }

  &-lineWrapper {
    display: flex;
    align-items: flex-start;

    margin-bottom: 5px;

    &:last-child {
      margin-bottom: 0;
    }

    &::before {
      position: relative;
      top: 2px;
      display: block;
      flex-shrink: 0;
      width: 16px;
      height: 16px;

      content: "";

      background: url("/img/icons/arrow--up-green.svg");
    }

    &--down::before {
      background: url("/img/icons/arrow--down-red.svg");
    }
  }

  &-line {
    padding-left: 1px;

    font-size: to-rem(14px);
    line-height: 21px;
    color: $ebony-clay;
    letter-spacing: -0.17px;

    @include breakpoint-up(sm) {
      font-size: to-rem(13px);
      line-height: 20px;
    }
  }
}
</style>
