import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-29f1c94e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "submission-panel" }
const _hoisted_2 = { class: "submission-panel__grid-container" }
const _hoisted_3 = { class: "submission-panel__tabs-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SubmissionPanelAffiliate = _resolveComponent("SubmissionPanelAffiliate")!
  const _component_SubmissionPanelSupporter = _resolveComponent("SubmissionPanelSupporter")!
  const _component_SubmissionPanelTabs = _resolveComponent("SubmissionPanelTabs")!
  const _component_PxPanel = _resolveComponent("PxPanel")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_PxPanel, { class: "submission-panel__container px-panel--xs-no-side-borders" }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_SubmissionPanelAffiliate, {
            affiliate: _ctx.affiliate,
            "submission-date": _ctx.submissionFormattedDate
          }, null, 8, ["affiliate", "submission-date"]),
          _createVNode(_component_SubmissionPanelSupporter, {
            company: _ctx.company,
            submission: _ctx.submission,
            "user-can-match": _ctx.canMatch,
            onClick: _ctx.showListModal
          }, null, 8, ["company", "submission", "user-can-match", "onClick"])
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_SubmissionPanelTabs, { submission: _ctx.submission }, null, 8, ["submission"])
        ])
      ]),
      _: 1
    })
  ]))
}