import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives, createBlock as _createBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, Fragment as _Fragment, resolveDirective as _resolveDirective, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-772b2d2e"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "capital-explorer",
  "element-loading-background": "#fafafa"
}
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "capital-explorer__results-panel" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RolePlayingBanner = _resolveComponent("RolePlayingBanner")!
  const _component_WeightsPanel = _resolveComponent("WeightsPanel")!
  const _component_QuestionsPanelHeader = _resolveComponent("QuestionsPanelHeader")!
  const _component_PxIcon = _resolveComponent("PxIcon")!
  const _component_QuestionsPanel = _resolveComponent("QuestionsPanel")!
  const _component_ResultsPanel = _resolveComponent("ResultsPanel")!
  const _component_OnboardingModal = _resolveComponent("OnboardingModal")!
  const _directive_loading = _resolveDirective("loading")!

  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.dataPrefilled && _ctx.capitalExplorerTilesResults)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          (_ctx.$user.isSupporter())
            ? (_openBlock(), _createElementBlock("header", _hoisted_2, [
                _createVNode(_component_RolePlayingBanner)
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("main", null, [
            _createElementVNode("div", {
              class: _normalizeClass([{
            'capital-explorer__questions-panel--collapsed':
              _ctx.isQuestionsPanelCollapsed,
          }, "capital-explorer__questions-panel"])
            }, [
              (_ctx.renderWeightsPanel)
                ? _withDirectives((_openBlock(), _createBlock(_component_WeightsPanel, { key: 0 }, null, 512)), [
                    [_vShow, _ctx.elementsVisible]
                  ])
                : _createCommentVNode("", true),
              _withDirectives(_createVNode(_component_QuestionsPanelHeader, {
                "questions-answered": _ctx.totalOfQuestionsAnswered,
                "questions-total": _ctx.totalOfQuestions,
                onClearAnswers: _ctx.clearAnswers
              }, null, 8, ["questions-answered", "questions-total", "onClearAnswers"]), [
                [_vShow, _ctx.elementsVisible]
              ]),
              _createElementVNode("div", {
                class: _normalizeClass([{
              'capital-explorer__questions-panel-icon--collapsed':
                _ctx.isQuestionsPanelCollapsed,
            }, "capital-explorer__questions-panel-icon"]),
                onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleQuestionsPanel && _ctx.toggleQuestionsPanel(...args)))
              }, [
                _createVNode(_component_PxIcon, {
                  size: 18,
                  name: "arrow"
                })
              ], 2),
              _withDirectives(_createVNode(_component_QuestionsPanel, {
                reset: _ctx.shouldResetResponses,
                "onUpdate:reset": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.shouldResetResponses) = $event)),
                questions: _ctx.questions,
                onUpdateResponses: _ctx.updateResponses
              }, null, 8, ["reset", "questions", "onUpdateResponses"]), [
                [_vShow, _ctx.elementsVisible]
              ])
            ], 2),
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_ResultsPanel, {
                "no-matching-data": !_ctx.responses.length,
                results: _ctx.capitalExplorerTilesResults,
                "show-matching-badge": _ctx.showMatchingBadge
              }, null, 8, ["no-matching-data", "results", "show-matching-badge"])
            ])
          ])
        ], 64))
      : _createCommentVNode("", true),
    (!_ctx.termsAccepted && !_ctx.loading && _ctx.dataPrefilled)
      ? (_openBlock(), _createBlock(_component_OnboardingModal, {
          key: 1,
          visibility: !_ctx.termsAccepted && !_ctx.loading && _ctx.dataPrefilled
        }, null, 8, ["visibility"]))
      : _createCommentVNode("", true)
  ])), [
    [_directive_loading, _ctx.loading || !_ctx.dataPrefilled || !_ctx.capitalExplorerTilesResults]
  ])
}