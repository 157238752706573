import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "discard-modal__subtitle"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PxModalMessage = _resolveComponent("PxModalMessage")!
  const _component_PxButton = _resolveComponent("PxButton")!
  const _component_ElDialog = _resolveComponent("ElDialog")!

  return (_openBlock(), _createBlock(_component_ElDialog, {
    ref: "modal",
    modelValue: _ctx.innerVisibility,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.innerVisibility) = $event)),
    "append-to-body": true,
    "before-close": _ctx.beforeCloseHandler,
    class: "el-dialog--ghost-header discard-modal",
    width: "500px"
  }, {
    footer: _withCtx(() => [
      _createVNode(_component_PxButton, {
        class: "discard-modal__cancel-btn",
        size: "small",
        type: "link",
        onClick: _ctx.onClickCancelButton
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t("common.cancel")), 1)
        ]),
        _: 1
      }, 8, ["onClick"]),
      _createVNode(_component_PxButton, {
        loading: _ctx.isLoading,
        class: "discard-modal__submit-btn",
        type: "red",
        onClick: _ctx.onClickDiscardButton
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.actionButtonLabel), 1)
        ]),
        _: 1
      }, 8, ["loading", "onClick"])
    ]),
    default: _withCtx(() => [
      _createVNode(_component_PxModalMessage, { visible: _ctx.hasServerError }, null, 8, ["visible"]),
      (_ctx.title)
        ? (_openBlock(), _createElementBlock("h2", {
            key: 0,
            class: _normalizeClass([{ 'discard-modal__only-title': !_ctx.subtitle }, "discard-modal__title"])
          }, _toDisplayString(_ctx.title), 3))
        : _createCommentVNode("", true),
      (_ctx.subtitle)
        ? (_openBlock(), _createElementBlock("p", _hoisted_1, _toDisplayString(_ctx.subtitle), 1))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["modelValue", "before-close"]))
}