<template>
  <div class="profile-content--entrepreneur">
    <div
      class="info-item info-item--sectors profile-content-row profile-content__tags"
    >
      <h4 class="profile-content__caption">
        {{ $t("profile.company.sector") }}
      </h4>
      <ElTag
        v-for="sector in company.sectors"
        :key="sector.id"
        class="text--capitalize"
        effect="plain"
      >
        {{ sector.name }}
      </ElTag>
    </div>
    <div class="info-item info-item--md-half info-item--founded">
      <h4 class="profile-content__caption">
        {{ $t("profile.company.foundedDate") }}
      </h4>
      <div class="profile-content__data">
        {{ companyFoundedDate }}
        <PxButton
          v-if="!company.founded_date && $user.isOwner()"
          class="el-button--link el-button--link-blue"
          type="link"
          @click="onClickFieldHandler()"
        >
          {{ $t("profile.company.emptyState.foundedDate") }}
        </PxButton>
        <span
          v-if="!company.founded_date && !$user.isOwner()"
          class="profile-content__empty-data"
        >
          {{ $t("profile.company.emptyState.visitor") }}
        </span>
      </div>
    </div>
    <div class="info-item info-item--md-half info-item--about">
      <h4 class="profile-content__caption">
        {{ $t("profile.company.about") }}
      </h4>
      <div class="profile-content__data">
        {{ company.about }}
        <PxButton
          v-if="!company.about && $user.isOwner()"
          class="el-button--link el-button--link-blue"
          type="link"
          @click="onClickFieldHandler('about')"
        >
          {{ $t("profile.company.emptyState.about") }}
        </PxButton>
        <span
          v-if="!company.about && !$user.isOwner()"
          class="profile-content__empty-data"
        >
          {{ $t("profile.company.emptyState.visitor") }}
        </span>
      </div>
    </div>
    <div class="info-item info-item--md-half info-item--website">
      <h4 class="profile-content__caption">
        {{ $t("profile.company.website") }}
      </h4>
      <div class="profile-content__data">
        <a
          v-if="company.website"
          :href="companyWebsite"
          class="el-button el-button--link el-button--link-dark"
          target="_blank"
        >
          <span>
            {{ company.website }}
          </span>
        </a>
        <PxButton
          v-else-if="$user.isOwner()"
          class="el-button--link el-button--link-blue"
          type="link"
          @click="onClickFieldHandler('website')"
        >
          {{ $t("profile.company.emptyState.website") }}
        </PxButton>
        <span v-else class="profile-content__empty-data">
          {{ $t("profile.company.emptyState.visitor") }}
        </span>
      </div>
    </div>
    <div class="info-item info-item--md-half info-item--email">
      <h4 class="profile-content__caption">
        {{ $t("profile.company.email") }}
        <ElTooltip
          v-if="$user.isOwner()"
          placement="top"
          popper-class="profile-content__caption-tooltip el-abaca-tooltip"
        >
          <template #content>
            <div v-text="$t(`profile.company.tooltip.emailVisibility`)" />
          </template>
          <i class="icon icon-question" />
        </ElTooltip>
      </h4>
      <div class="profile-content__data">
        <a
          v-if="canShowEmail"
          :href="`mailto:${company.email}`"
          class="el-button el-button--link el-button--link-dark"
        >
          <span>
            {{ company.email }}
          </span>
        </a>
        <PxButton
          v-else-if="$user.isOwner()"
          class="el-button--link el-button--link-blue"
          type="link"
          @click="onClickFieldHandler('email')"
        >
          {{ $t("profile.company.emptyState.email") }}
        </PxButton>
        <PxButton
          v-else-if="!hasUserAccess"
          class="el-button--link el-button--link-green"
          type="link"
          @click="onClickSignUp"
        >
          {{ $t("profile.company.visitorState.email") }}
        </PxButton>
        <div v-else-if="userCanMatch" class="profile-content__hidden-email">
          <p class="profile-content__hidden-email-title">
            <PxIcon :name="'icon-password'" :size="12" />
            {{
              $t(
                `profile.company.hiddenEmail[${
                  userIsInterested ? 1 : 0
                }].title`,
                { name: company.name },
              )
            }}
          </p>
          <p class="profile-content__hidden-email-subtitle">
            {{
              $t(
                `profile.company.hiddenEmail[${
                  userIsInterested ? 1 : 0
                }].subtitle`,
              )
            }}
          </p>
        </div>
        <span v-else class="profile-content__empty-data">
          {{ $t("profile.company.emptyState.visitor") }}
        </span>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { ROUTE_AUTHENTICATION_SIGNUP } from "@/modules/authentication/services/router/routes-names";

import moment from "moment";

export default defineComponent({
  name: "CompanyPanelEntrepreneur",

  props: {
    company: {
      type: Object as () => any,
      required: true,
    },
    userCanMatch: {
      type: Boolean,
      default: false,
    },
    userIsConnected: {
      type: Boolean,
      default: false,
    },
    userIsInterested: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    canShowEmail(): boolean {
      const hasEmail = this.company.email || null;
      const hasPermission =
        this.$user.isOwner() ||
        (this.$user.isSupporter() && this.userIsConnected);

      return hasEmail && hasPermission;
    },

    isSpecialGuest(): boolean {
      return !!this.$route.meta?.specialGuest;
    },

    hasUserAccess(): boolean {
      return this.$user.isLogged() || this.isSpecialGuest;
    },

    companyWebsite(): string {
      const companyWebsite = this.company.website || "";

      const pattern =
        /(ftp|http|https):\/\/(\w+:?\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@\-/]))?/;

      return pattern.test(companyWebsite)
        ? companyWebsite
        : `//${companyWebsite}`;
    },

    companyFoundedDate(): string {
      return this.company.founded_date
        ? moment(this.company.founded_date).format("D MMMM YYYY")
        : "";
    },
  },

  methods: {
    onClickFieldHandler(field?: string) {
      if (field) {
        this.$emit("focus-modal-field", field);
      } else {
        this.$emit("open-company-modal");
      }
    },
    onClickSignUp() {
      this.$router.push({ name: ROUTE_AUTHENTICATION_SIGNUP });
    },
  },
});
</script>

<style lang="scss" scoped>
.profile-content--entrepreneur {
  display: flex;
  flex-direction: column;

  @include breakpoint-up(md) {
    flex-direction: row;
    flex-wrap: wrap;
  }
}

.profile-content__caption {
  @include grotesk(semiBold);

  margin: 0;
  font-size: to-rem(13px);
  color: $manatee;
  text-transform: uppercase;

  @include breakpoint-up(md) {
    line-height: 16px;
  }

  .icon {
    position: relative;
    top: 3px;
    display: inline-block;

    width: 16px;
    height: 16px;
    margin-left: 4px;
    transition: filter 120ms ease-in-out;

    /* Change icon color to $ebony-clay */
    &:hover {
      filter: hue-rotate(350deg) brightness(0.3);
    }
  }
}

.profile-content__data {
  @include grotesk(regular);

  margin-top: 7px;
  font-size: to-rem(15px);
  line-height: 24px;
  letter-spacing: -0.2px;

  @include breakpoint-up(md) {
    line-height: 25px;
  }
}

.profile-content__empty-data {
  @include grotesk(regular, italic);

  margin-top: 7px;
  font-size: to-rem(15px);
  line-height: 25px;
  letter-spacing: -0.2px;
}

.profile-content-row:not(:first-child) {
  margin-top: 22px;
}

.profile-content__tags .profile-content__caption {
  margin-bottom: 12px;

  @include breakpoint-up(md) {
    margin-bottom: 14px;
  }
}

.profile-content__tags .el-tag {
  margin-right: 10px;
  margin-bottom: 10px;
  white-space: inherit;
}

.info-item {
  margin-bottom: 26px;

  @include breakpoint-up(md) {
    flex-shrink: 0;
  }

  .el-button--link {
    padding: 0 0 15px;
  }
}

.info-item--md-half {
  @include breakpoint-up(md) {
    flex-grow: 0;
    width: 290px;
  }
}

.info-item--sectors {
  order: 1;
  margin-bottom: 14px;

  @include breakpoint-up(md) {
    flex-grow: 1;
    order: 0;
    width: 100%;
    margin-bottom: 21px;
  }
}

.info-item--founded {
  order: 2;
  margin-bottom: 23px;

  @include breakpoint-up(md) {
    order: 1;
    margin-bottom: 26px;
  }
}

.info-item--about {
  order: 0;
  word-break: break-word;
  word-wrap: break-word;

  @include breakpoint-up(md) {
    order: 3;
    margin-bottom: 0;
  }
}

.info-item--website {
  order: 3;
  margin-bottom: 7px;

  @include breakpoint-up(md) {
    order: 2;
  }

  .profile-content__data {
    margin-top: 0;
    word-break: break-word;
    word-wrap: break-word;

    @include breakpoint-up(md) {
      margin-top: 6px;
    }
  }
}

.info-item--email {
  order: 4;
  margin-bottom: 0;

  .profile-content__data {
    margin-top: 0;
    margin-bottom: -4px;
    word-break: break-word;
    word-wrap: break-word;

    @include breakpoint-up(md) {
      margin-top: 6px;
      margin-bottom: 0;
    }
  }
}

.info-item--founded,
.info-item--about {
  margin-right: 20px;
}

.profile-content__hidden-email {
  width: 254px;
  padding: 8px 10px;
  background-color: rgba($golden, 0.05);
  border: solid 1px rgba($golden, 0.31);
  border-radius: 4px;

  &-title {
    @include grotesk(semiBold);

    font-size: 15px;
    line-height: 1.47;
    color: $golden;
  }

  &-subtitle {
    font-size: 14px;
    line-height: 1.57;
    color: rgba($ebony-clay, 0.7);
  }
}
</style>

<style lang="scss">
.profile-content__caption-tooltip {
  max-width: 234px;
  text-transform: none;
}
</style>
