<template>
  <div class="milestone-tooltip-progress-indicator">
    <div
      v-if="isMilestoneInProgress"
      :style="backgroundStyles"
      class="milestone-tooltip-progress-indicator__background"
      data-testid="background"
    />
    <div
      :style="foregroundStyles"
      class="milestone-tooltip-progress-indicator__foreground"
      data-testid="foreground"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, CSSProperties } from "vue";

export default defineComponent({
  name: "MilestoneTooltipProgressIndicator",

  props: {
    isFutureMilestone: { type: Boolean, default: false },
    isMilestoneInProgress: { type: Boolean, default: false },
    isMilestonePlanned: { type: Boolean, default: false },
    isMilestoneComplete: { type: Boolean, default: false },
    categoryColor: { type: String, required: true },
  },

  computed: {
    indicatorColor(): string {
      return !this.isFutureMilestone ? this.categoryColor : "";
    },

    foregroundType(): CSSProperties["borderBottomStyle"] {
      return !this.isFutureMilestone &&
        !this.isMilestoneInProgress &&
        !this.isMilestonePlanned &&
        !this.isMilestoneComplete
        ? "dashed"
        : "solid";
    },

    foregroundWidth(): string {
      return this.isMilestoneInProgress ? "50%" : "100%";
    },

    backgroundStyles(): CSSProperties {
      return {
        backgroundColor: this.indicatorColor,
      };
    },

    foregroundStyles(): CSSProperties {
      return {
        borderBottomColor: this.indicatorColor,
        borderBottomStyle: this.foregroundType,
        width: this.foregroundWidth,
      };
    },
  },
});
</script>

<style lang="scss" scoped>
.milestone-tooltip-progress-indicator {
  position: relative;
  height: 1px;

  &__background {
    height: 1px;
    background-color: $mischka;
    opacity: 0.4;
  }

  &__foreground {
    position: absolute;
    top: 0;
    left: 0;
    border-bottom-color: $mischka;
    border-bottom-width: 1px;
  }
}
</style>
