<template>
  <div class="px-bottom-bar-wrapper">
    <div class="px-bottom-bar">
      <div class="px-bottom-bar__container">
        <div
          v-for="item in items"
          :key="item.value"
          class="px-bottom-bar-item__wrapper"
        >
          <RouterLink :to="{ name: item.to }" class="px-bottom-bar-item">
            <PxIcon
              :modifier="iconModifier(item)"
              :name="item.icon"
              class="px-bottom-bar-item__icon"
              size="38"
            />
            <span class="px-bottom-bar-item__title">
              {{ item.label }}
            </span>
          </RouterLink>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { PROFILE_META_ACTIVE_VIEW } from "@/modules/profile/constants";
import { EMetaActions } from "@/services/store/meta/meta-types";

/**
 * Abaca's footer component.
 */
export default defineComponent({
  name: "PxBottomBar",

  props: {
    /**
     * List of items to be displayed.
     *
     * Example:
     * ```js
     * {
     *   value: "my_company",
     *   label: "My Company",
     *   icon: "company",
     *   to: COMPONENTS,
     * }
     * ```
     *
     */
    items: {
      type: Array as () => any[],
      required: true,
    },
  },

  watch: {
    $route(to, from) {
      // On new route, reset scroll
      window.scrollTo(0, 0);

      if (to.name === "profile" && to.name === from.name && from.params.id) {
        this.$store.dispatch(EMetaActions.SET, {
          key: PROFILE_META_ACTIVE_VIEW,
          value: "",
        });
      }
    },
  },

  methods: {
    iconModifier(routeItem: any): string | null {
      const isActiveRoute = this.$route.name === routeItem.to;
      const isMainPage = Object.keys(this.$route.params).length === 0;

      return isActiveRoute && isMainPage ? "active" : null;
    },
  },
});
</script>

<style lang="scss" scoped>
$bottom-bar-height: 49px;
$text-color--default: $cool-grey;
$text-color--active: $dark-sky-blue;

.px-bottom-bar-wrapper {
  height: $bottom-bar-height;
}

.px-bottom-bar {
  position: fixed;
  bottom: 0;
  z-index: z("navigation");
  width: 100vw;
  max-height: $bottom-bar-height;

  background: $white;
  box-shadow: 0 -3px 10px 0 rgba(0, 0, 0, 0.12);

  &__container {
    position: relative;
    left: 50%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    max-width: 600px;

    transform: translateX(-50%);
  }
}

.px-bottom-bar-item__wrapper {
  display: inline-flex;
  flex-grow: 1;
  justify-content: center;
}

.px-bottom-bar-item {
  position: relative;
  display: block;
  width: 79px;
  max-width: 79px;

  text-decoration: none;

  .px-icon {
    position: relative;
    left: 50%;

    transform: translateX(-50%);
  }

  &__title {
    @include grotesk(semiBold);

    position: relative;
    top: -8px;
    display: block;

    font-size: 0.8rem;
    line-height: 1.08;
    color: $text-color--default;
    text-align: center;
  }

  &.router-link-active .px-bottom-bar-item__title {
    color: $text-color--active;
  }
}
</style>
