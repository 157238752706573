import { GenericProvider } from "@/services/data/generic-provider";
import type { IMilestoneReports } from "./milestone.interface";

class MilestoneReportsProvider extends GenericProvider<IMilestoneReports> {
  constructor() {
    super(`${process.env.VUE_APP_API_URL}/milestone-progress/base`, []);
  }

  public async getById(listId: string): Promise<IMilestoneReports> {
    const url = this.buildEndPointUrl({}, listId);
    const request = this.httpClient.get(url);
    const { data } = await this.wrapRequest(request);
    return data;
  }
}

export const milestoneReportsProvider = new MilestoneReportsProvider();
