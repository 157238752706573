<template>
  <PxCornerRibbon
    :hidden="isInvestorsPanelEnabled || !hasUserAccess"
    class="px-corner-ribbon--center-xs"
  >
    <PxPanel
      class="profile-panel profile-panel--investors profile-panel--inactive"
      theme="with-sections"
    >
      <template #header>
        <h2 class="px-panel__title" v-html="$t('profile.investors.title')" />
      </template>
      <div v-if="$screen.smDown" class="profile-panel__mobile-section">
        <PxTabsMobile v-model="selectedTab" :items="tabsMobileItems" disabled />
        <ContentPlaceholdersText
          :lines="3"
          class="profile-panel__mobile-text"
        />
        <div class="profile-panel__divider" />
        <ContentPlaceholdersText
          :lines="3"
          class="profile-panel__mobile-text"
        />
      </div>
      <template v-if="$screen.mdUp">
        <PxPanelSection
          :title="$t('profile.investors.nextMilestones')"
          icon="flag--outlined"
        >
          <ContentPlaceholdersText :lines="4" />
        </PxPanelSection>
        <PxPanelSection
          :title="$t('profile.investors.achievedMilestones')"
          icon="flag--checked"
        >
          <ContentPlaceholdersText :lines="4" />
        </PxPanelSection>
      </template>
    </PxPanel>
  </PxCornerRibbon>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import PxPanelSection from "@/components/px-panel-section/px-panel-section.vue";

import PxCornerRibbon from "@/components/px-corner-ribbon/px-corner-ribbon.vue";

export default defineComponent({
  name: "InvestorsPanel",

  components: {
    PxPanelSection,
    PxCornerRibbon,
  },

  data() {
    return {
      selectedTab: "next",
      tabsMobileItems: [
        {
          value: "next",
          label: this.$t("profile.investors.nextMilestones"),
        },
      ],
    };
  },

  computed: {
    isSpecialGuest(): boolean {
      return !!this.$route.meta?.specialGuest;
    },

    hasUserAccess(): boolean {
      return this.$user.isLogged() || this.isSpecialGuest;
    },

    isInvestorsPanelEnabled(): boolean {
      return (
        process.env.VUE_APP_PROFILE_INVESTORS_PANEL_ENABLED !== "false" || false
      );
    },
  },
});
</script>

<style lang="scss" scoped>
.panel-grid__block,
.px-corner-ribbon {
  display: block;
}

.panel-grid__ribbon :deep(.px-corner-ribbon__label) {
  @include breakpoint-only("xs") {
    position: relative;
    width: 100%;
  }
}

:deep(.px-panel-section__header) {
  padding: 14px 48px;
}

.panel-grid__block .px-panel :deep(.px-panel__title) {
  @include breakpoint-down(sm) {
    padding-left: 17px;

    font-size: to-rem(18px);
    line-height: 1;
    text-align: left;
  }
}

:deep(.px-panel-section .px-panel-section__body) {
  padding: 40px;
}

.profile-panel__mobile-section {
  padding: 21px 15px;

  background: white;

  & .profile-panel__mobile-text {
    margin-top: 24px;
  }

  .profile-panel__mobile-text :deep() {
    .vue-content-placeholders-text__line:first-child {
      width: 99.4%;
    }

    .vue-content-placeholders-text__line:nth-child(2) {
      width: 84%;
      margin-top: 24px;
    }

    .vue-content-placeholders-text__line:nth-child(3) {
      width: 70%;
      margin-top: 19px;
      margin-left: 10px;

      background-color: #f4f4f5;
    }
  }

  .profile-panel__divider {
    width: 100%;
    height: 1px;
    margin: 26px 0;

    background-color: $athens-gray;
  }

  .px-tabs-mobile {
    width: 100%;
  }
}
</style>
