import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-537e51a9"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "assessment-panel-placeholder__level" }
const _hoisted_2 = { class: "assessment-panel-placeholder__grid" }
const _hoisted_3 = { class: "assessment-panel-placeholder__thumbnail-caption" }
const _hoisted_4 = { class: "assessment-panel-placeholder__list" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ContentPlaceholdersHeading = _resolveComponent("ContentPlaceholdersHeading")!
  const _component_ContentPlaceholdersImg = _resolveComponent("ContentPlaceholdersImg")!
  const _component_ContentPlaceholders = _resolveComponent("ContentPlaceholders")!
  const _component_ContentPlaceholdersText = _resolveComponent("ContentPlaceholdersText")!
  const _component_PxPanel = _resolveComponent("PxPanel")!

  return (_openBlock(), _createBlock(_component_PxPanel, { class: "panel-grid__block assessment-panel-placeholder" }, {
    default: _withCtx(() => [
      _createVNode(_component_ContentPlaceholders, { rounded: true }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_ContentPlaceholdersHeading, { class: "assessment-panel-placeholder__title" }),
            _createVNode(_component_ContentPlaceholdersImg, { class: "assessment-panel-placeholder__thumbnail" }),
            _createVNode(_component_ContentPlaceholders, {
              centered: true,
              class: "assessment-panel-placeholder__content"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ContentPlaceholdersHeading)
              ]),
              _: 1
            }),
            _createVNode(_component_ContentPlaceholdersText, {
              lines: 1,
              class: "assessment-panel-placeholder__cta"
            })
          ]),
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_ContentPlaceholdersHeading, { class: "assessment-panel-placeholder__title" }),
            _createVNode(_component_ContentPlaceholdersImg, { class: "assessment-panel-placeholder__thumbnail" }),
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_ContentPlaceholdersText, { lines: 2 })
            ]),
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_ContentPlaceholdersText, { lines: 4 }),
              _createVNode(_component_ContentPlaceholdersText, { lines: 4 })
            ])
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}