import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-b5c2501e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "px-information-card__left" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = ["title"]
const _hoisted_4 = { class: "px-information-card__right" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PxStatusBadge = _resolveComponent("PxStatusBadge")!
  const _component_PxButton = _resolveComponent("PxButton")!

  return (_openBlock(), _createElementBlock("div", {
    style: _normalizeStyle({ backgroundColor: _ctx.backgroundColor, border: _ctx.border }),
    class: "px-information-card"
  }, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.title)
        ? (_openBlock(), _createElementBlock("h3", _hoisted_2, _toDisplayString(_ctx.title), 1))
        : _createCommentVNode("", true),
      (_ctx.description)
        ? (_openBlock(), _createElementBlock("p", {
            key: 1,
            class: _normalizeClass({
          'px-information-card__description--default':
            _ctx.descriptionStyles === 'default',
          'px-information-card__description--italic':
            _ctx.descriptionStyles === 'italic',
        }),
            title: _ctx.description
          }, _toDisplayString(_ctx.description), 11, _hoisted_3))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_4, [
      (_ctx.label && _ctx.$screen.xlUp)
        ? (_openBlock(), _createBlock(_component_PxStatusBadge, {
            key: 0,
            "background-color": _ctx.label.backgroundColor,
            icon: _ctx.label.icon,
            "icon-size": _ctx.label.iconSize,
            label: _ctx.label.text,
            "label-color": _ctx.label.textColor,
            "label-size": 13,
            uppercase: false,
            class: "px-information-card__label",
            "icon-position": "left"
          }, null, 8, ["background-color", "icon", "icon-size", "label", "label-color"]))
        : _createCommentVNode("", true),
      (_ctx.cta)
        ? (_openBlock(), _createBlock(_component_PxButton, {
            key: 1,
            label: _ctx.cta.label,
            size: _ctx.cta.size,
            type: _ctx.cta.type,
            class: "px-information-card__cta",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('click')))
          }, null, 8, ["label", "size", "type"]))
        : _createCommentVNode("", true)
    ])
  ], 4))
}