<template>
  <div
    :class="{ 'list-detail-panel-empty--with-slot': hasPopulatedSlot }"
    class="list-detail-panel-empty"
  >
    <div class="list-detail-panel-empty__wrapper">
      <div
        :class="[`list-detail-panel-empty__icon--${icon}`]"
        class="list-detail-panel-empty__icon"
      >
        <img :src="`/img/icons/${icon}.svg`" alt />
      </div>
      <p class="list-detail-panel-empty__title">
        {{ title }}
      </p>
      <p v-if="subtitle" class="list-detail-panel-empty__title">
        {{ subtitle }}
      </p>
      <slot />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    title: {
      type: String,
      required: true,
    },

    subtitle: {
      type: String,
      default: "",
    },

    icon: {
      type: String,
      required: true,
    },
  },

  computed: {
    hasPopulatedSlot(): boolean {
      return !!this.$slots.default;
    },
  },
});
</script>

<style lang="scss" scoped>
.list-detail-panel-empty {
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-top: 105px;
  user-select: none;

  .lists__main-cta {
    max-width: 144px;
    margin-top: 24px;
    padding: 0 16px 0 14px;

    :deep() .px-icon {
      margin-top: 1px;
    }
  }
}

.list-detail-panel-empty__wrapper {
  // For pixel-perfect alignment:
  position: relative;
  left: 1px;
  height: 117px;
  text-align: center;

  .list-detail-panel-empty--with-slot & {
    height: 268px;
  }

  // Handle pixel perfect
  img {
    margin-right: 1px;
  }
}

.list-detail-panel-empty__icon {
  margin-bottom: 12px;
}

.list-detail-panel-empty__icon--lists-empty-state :deep() img {
  height: 93px;
}

.list-detail-panel-empty__title {
  @include grotesk(medium);

  font-size: 15px;
  line-height: 1.29;
  color: $manatee;
  letter-spacing: -0.25px;
  margin-bottom: 0;
}
</style>
