<template>
  <div
    v-if="currentStepData"
    :style="`--stepColor: #${currentStepData.color}`"
    class="category-wizard-mobile"
  >
    <CategoryColorBar
      :active-step="currentStep"
      class="category-wizard-mobile__bar"
    />
    <div class="safe-horizontal-container">
      <div class="category-wizard-mobile__category-name-wrapper">
        <span class="category-wizard-mobile__category-name">
          {{ currentStepData.label }}
        </span>
        <ElTag
          :size="$screen.smDown ? 'small' : 'default'"
          class="category-wizard-mobile__category-level"
          data-tag-type="grey"
          effect="plain"
        >
          {{ $t("common.levelTag", { level: selectedStepData.level }) }}
        </ElTag>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import CategoryColorBar from "@/modules/affiliates/components/category-color-bar/category-color-bar.vue";

import { ICategoryLevelSelection } from "@/services/data/viral-level/viral-level.interface";
import { ICategory } from "@/services/data/category/category.interface";
import { ICategoryStepItem } from "@/modules/affiliates/views/affiliate.types";
import { ILevel } from "@/services/data/level/level.interface";

export default defineComponent({
  name: "CategoryWizardMobile",

  components: {
    CategoryColorBar,
  },

  props: {
    steps: {
      type: Array as () => ICategoryStepItem[],
      required: true,
    },

    currentStep: {
      type: Number,
      default: 0,
    },

    active: {
      type: Number,
      default: null,
    },
  },

  computed: {
    assessmentLevels(): Array<ICategoryLevelSelection> {
      return (
        this.$store.state.affiliates.draftSubmission?.data.assessment || []
      );
    },

    /**
     * Get all categories from the store.
     */
    categories(): Array<ICategory> {
      return this.$store.state.categories.data;
    },

    /**
     * Information of the current selected step.
     */
    currentStepData(): ICategoryStepItem {
      return this.steps[this.currentStep];
    },

    /**
     * Get the category for the current step.
     */
    currentCategory(): ICategory | null {
      if (this.categories.length === 0) {
        return null;
      }

      return this.categories[this.currentStep];
    },

    /**
     * Get the data for the selected step.
     */
    selectedStepData(): ICategoryLevelSelection | null {
      if (!this.assessmentLevels || this.assessmentLevels.length === 0) {
        return null;
      }

      const selectedDetail = this.assessmentLevels.find(
        (record: ICategoryLevelSelection): any => {
          return (
            this.currentCategory && record.category === this.currentCategory.id
          );
        },
      );

      return selectedDetail || null;
    },

    /**
     * Informs if the level tag must be shown.
     *
     * The tag only is visible when there is a level selected.
     */
    showLevelTag(): boolean {
      return (
        !!this.selectedStepData &&
        (this.selectedStepData.level as ILevel["value"]) > 0
      );
    },
  },
});
</script>

<style lang="scss" scoped>
.category-wizard-mobile {
  position: fixed;
  display: block;
  z-index: 2;
  width: 100%;

  background: white;

  &::after {
    position: relative;
    display: block;
    width: 100%;
    height: 4px;

    content: "";

    background-color: var(--stepColor);
  }

  &__category-name-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 18px 15px 16px;
  }

  &__category-name {
    @include grotesk(semiBold);

    font-size: 1.4667rem;
    line-height: 1;
  }

  &__category-level {
    position: absolute;
    right: 15px;

    background-color: var(--stepColor);
    border: solid 1px rgba(0, 0, 0, 0.06);
  }
}

.category-wizard-mobile__bar {
  padding-top: 2px;
}
</style>
