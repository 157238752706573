import { IMatchScoreDetails } from "@/modules/matching/services/store/match-score-details/match-score-details.types";
import {
  EProviderFeatures,
  GenericProvider,
  Provider,
} from "@/services/data/generic-provider";
import { baseAPIUrl } from "@/services/utils/utils";

/**
 * Provider for the match score details.
 */
@Provider(`${baseAPIUrl}/matching/scores`, [EProviderFeatures.GET])
class MatchScoreDetailsProvider extends GenericProvider<IMatchScoreDetails> {
  /**
   *
   * @param companyUid
   */
  public async getMatchScoreDetails(
    companyUid: string,
  ): Promise<IMatchScoreDetails> {
    const url = super.buildEndPointUrl({}, `${companyUid}/impact`);
    const request = this.httpClient.get(url);
    const { data } = await this.wrapRequest(request);

    return data;
  }
}

export const matchScoreDetailsProvider = new MatchScoreDetailsProvider();
