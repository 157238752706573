import { IGenericState } from "@/services/store/generic/generic-state.interface";
import { IAdditionalCriteria } from "@/modules/supporters/services/data/additional-criteria/additional-criteria.interface";

/**
 * Represents the store structure for the supporter type store data.
 */
export interface IAdditionalCriteriaState
  extends IGenericState<IAdditionalCriteria> {
  data: Array<IAdditionalCriteria>;
}

export enum EAdditionalCriteriaState {
  NAMESPACE = "additionalCriteria",
}

export enum EAdditionalCriteriaGetter {
  VALUE = "additionalCriteria/getValue",
  VALUES = "additionalCriteria/getValues",
  IS_LOADING = "additionalCriteria/isLoading",
  ERROR = "additionalCriteria/getError",
  HAS_ERROR = "additionalCriteria/hasError",
  MULTI_SELECTOR_VALUES = "additionalCriteria/selectorValues",
}
