<template>
  <div class="matching-list-interests">
    <div
      v-if="isInterestLoading"
      v-loading="isInterestLoading"
      class="matching-list__loading"
    />
    <ElTabs
      v-else
      v-model="activeTab"
      :class="{
        'el-tabs--dark-line': $screen.smDown,
        'el-tabs--blue-line': $screen.mdUp,
        'el-tabs--sticky': needsStickyTabBar,
      }"
      class="el-tabs--nav-left"
      @tab-click="clickTabHandler"
    >
      <ElTabPane
        :label="interestTabs[EMatchingListPendingTabs.INTERESTED_IN_ME].label"
        :name="EMatchingListPendingTabs.INTERESTED_IN_ME"
      >
        <MatchingListInterestsTargets />
      </ElTabPane>
      <ElTabPane
        :label="interestTabs[EMatchingListPendingTabs.IM_INTERESTED_IN].label"
        :name="EMatchingListPendingTabs.IM_INTERESTED_IN"
      >
        <MatchingListInterestsUser />
      </ElTabPane>
    </ElTabs>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import MatchingListInterestsTargets from "@/modules/matching/components/matching-list-interests/matching-list-interests-targets.vue";
import MatchingListInterestsUser from "@/modules/matching/components/matching-list-interests/matching-list-interests-user.vue";

import { IMatchingScore } from "@/modules/matching/services/data/matching-score/matching-score.interface";
import { EMetaActions } from "@/services/store/meta/meta-types";
import {
  MATCHING_SELECTED_TAB,
  MATCHING_PENDING_SELECTED_TAB,
} from "@/modules/matching/constants";
import { EMatchingListPendingTabs } from "@/modules/matching/components/matching-list-desktop/matching-tabs.types";

export default defineComponent({
  name: "MatchingListInterests",

  components: {
    MatchingListInterestsTargets,
    MatchingListInterestsUser,
  },

  data() {
    return {
      EMatchingListPendingTabs,
      interestTabs: {
        [EMatchingListPendingTabs.INTERESTED_IN_ME]: {
          label: this.$t("matching.matchingInterest.tabs.interestedInMe"),
        },

        [EMatchingListPendingTabs.IM_INTERESTED_IN]: {
          label: this.$t("matching.matchingInterest.tabs.imInterestedIn"),
        },
      },
      activeTab: "",
      userInterestsModalVisibility: false,
      needsStickyTabBar: false,
    };
  },

  computed: {
    selectedTab(): string {
      return this.$store.get("meta/get", MATCHING_PENDING_SELECTED_TAB);
    },

    targetUser(): string {
      const oppositeUserType = +!this.$user.getUserAccountType();
      return `${this.$t(`common.userTypes[${oppositeUserType}]`)}s` as string;
    },

    targetScores(): IMatchingScore[] {
      return this.$store.state.matchingInterest.targetMatchScores;
    },

    /**
     * Verify if interest page is loading
     */
    isInterestLoading(): boolean {
      return this.$store.state.matchingInterest.loading;
    },
  },

  watch: {
    selectedTab: {
      immediate: true,
      handler(tab: EMatchingListPendingTabs) {
        if (!tab) return;
        this.activeTab = tab;
      },
    },

    activeTab: {
      immediate: true,
      handler(tab: EMatchingListPendingTabs) {
        this.$emit("on-tab-changed", tab);
      },
    },

    isInterestLoading: {
      immediate: true,
      handler(state: boolean) {
        if (this.selectedTab) return;

        if (state) {
          this.activeTab = EMatchingListPendingTabs.IM_INTERESTED_IN;
          return;
        }

        this.activeTab = this.targetScores.length
          ? EMatchingListPendingTabs.INTERESTED_IN_ME
          : EMatchingListPendingTabs.IM_INTERESTED_IN;
      },
    },
  },

  created() {
    this.$emitter.on("go-to-matches", this.goToMatches);
    this.$emitter.on("tab-bar-small", this.updateStickyTabBarDisplay);
  },

  methods: {
    goToMatches() {
      this.userInterestsModalVisibility = false;
      this.$store.dispatch(EMetaActions.SET, {
        key: MATCHING_SELECTED_TAB,
        value: "discover",
      });
    },

    clickTabHandler() {
      this.$store.dispatch(EMetaActions.SET, {
        key: MATCHING_PENDING_SELECTED_TAB,
        value: this.activeTab,
      });
    },

    updateStickyTabBarDisplay(isVisible: boolean) {
      this.needsStickyTabBar = isVisible;
    },
  },
});
</script>

<style lang="scss" scoped>
.matching-list__loading {
  height: 200px;

  @include breakpoint-up(md) {
    height: 290px;
  }
}

.el-tabs--blue-line :deep() {
  .el-tabs__item {
    @include breakpoint-up(md) {
      min-width: 103px;
      padding: 22px 7px 18px;
      font-size: 18px;
    }
  }
}

.matching-list-interests .el-tabs :deep() {
  .el-tabs__nav-wrap::after {
    height: 3px;

    @include breakpoint-up(md) {
      height: 1px;
    }
  }

  .el-tabs__header {
    position: fixed;
    left: 0;
    z-index: z("navigation");
    width: 100%;
    padding: 17px 15px 0;
    margin: 0 auto;

    background-color: $alabaster;
    transition:
      transform 0.2s ease-in-out,
      padding 0.2s ease-in-out;

    @include breakpoint-up(md) {
      position: relative;
      top: auto;
      width: auto;
      padding: 0;
      margin: auto;
      background-color: transparent;
      transition: none;
    }
  }

  .el-tabs__content {
    padding: 60px 15px 30px;
    transition: padding 0.2s ease-in-out;

    @include breakpoint-up(md) {
      height: auto;
      padding: 0;
    }
  }

  .el-tabs__item {
    flex: 1 1 50%;
    padding: 2px 6px 14px;
    margin-right: 0;
    font-size: 14px;

    @include breakpoint-up(md) {
      flex: 0 0 auto;
      padding: 9px 6px 18px;
      font-size: 18px;
    }
  }
}

.matching-list-interests .el-tabs.el-tabs--sticky :deep() {
  .el-tabs__header {
    @include breakpoint-down(sm) {
      padding-right: 0;
      padding-left: 0;
      transform: translateY(-43px);
    }
  }

  .el-tabs__content {
    @include breakpoint-down(sm) {
      padding-top: 16px;
    }
  }
}
</style>
