<template>
  <div class="visitors-milestone-full-grid page-full-height">
    <div class="visitors-milestone-full-grid__main-wrapper">
      <div class="visitors-milestone-full-grid__header">
        <div class="visitors-milestone-full-grid__header-left">
          <div class="visitors-milestone-full-grid__title">
            <PxAvatar
              :name="company.name"
              :photo="company.logo"
              :size="24"
              class="visitors-milestone-full-grid__company-logo"
            />
            <div class="visitors-milestone-full-grid__title-text">
              {{ company.name }}
            </div>
            <PxIcon
              class="visitors-milestone-full-grid__arrow-separator"
              name="arrow--right-black"
              size="12"
            />
            <div class="visitors-milestone-full-grid__title-text">
              {{ $t("milestonePlanner.label") }}
            </div>
            <div class="visitors-milestone-full-grid__title-separator" />
            <LastAssessmentDate :date="lastAssessmentDate" />
          </div>
        </div>
        <div class="visitors-milestone-full-grid__header-right">
          <PxButton
            v-bind="$options.static.printButton"
            ref="printButton"
            @click="printGrid"
          />
          <PxButton
            v-bind="$options.static.collapseButton"
            ref="collapseFullGridButton"
            @click="collapseFullGrid"
          />
        </div>
      </div>
      <div class="visitors-milestone-full-grid__grid-wrapper">
        <MilestonesFullGrid
          ref="milestonesFullGrid"
          :categories="gridCategories"
          :current-level="lastAssessment.level.value"
          :levels="levels"
        />
      </div>
      <div class="visitors-milestone-full-grid__footer">
        <LastAssessmentDate :date="lastAssessmentDate" />
        <GridLegend class="visitors-milestone-full-grid__grid-legend" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import {
  EPxButtonSize,
  EPxButtonVariant,
} from "@/components/px-button/px-button.types";
import GridLegend from "@/modules/milestone-planner/components/grid-legend/grid-legend.vue";
import LastAssessmentDate from "@/modules/milestone-planner/components/last-assessment-date/last-assessment-date.vue";
import MilestonesFullGrid from "@/modules/milestone-planner/components/milestones-full-grid/milestones-full-grid.vue";
import { IAssessment } from "@/services/data/assessment/assessment.interface";
import moment from "moment";
import { IGridCategory } from "../components/milestones-grid/milestones-grid.interface";
import { ROUTE_MILESTONE_PLANNER_OVERVIEW_VISITORS } from "../services/router/routes-names";
import { ILevel } from "@/services/data/level/level.interface";
import { ICompany } from "@/modules/profile/services/data/company/company.types";

export default defineComponent({
  name: "VisitorsMilestonePlannerFullGrid",

  components: {
    GridLegend,
    LastAssessmentDate,
    MilestonesFullGrid,
  },

  static: {
    printButton: {
      icon: "print",
      size: EPxButtonSize.SMALL,
      variant: EPxButtonVariant.COMPACT,
    },
    collapseButton: {
      icon: "minimize",
      size: EPxButtonSize.SMALL,
      variant: EPxButtonVariant.COMPACT,
    },
  },

  computed: {
    company(): ICompany {
      return this.$store.get("profile/company.data") as ICompany;
    },

    levels(): ILevel[] {
      return this.$store.get("levels.data");
    },

    gridCategories(): IGridCategory[] {
      return this.$store.get("milestonePlanner/parsedValue");
    },

    lastAssessment(): IAssessment {
      return this.$store.get("profile/latestAssessment.data");
    },

    lastAssessmentDate(): string {
      return moment(this.lastAssessment?.updated_at).format(
        "DD MMMM YYYY, HH:MMA",
      );
    },
  },

  methods: {
    collapseFullGrid() {
      this.$router.push({ name: ROUTE_MILESTONE_PLANNER_OVERVIEW_VISITORS });
    },

    printGrid() {
      window.print();
    },
  },
});
</script>

<style lang="scss">
.visitors-milestone-full-grid + .px-footer {
  padding-top: 0;
}
</style>

<style lang="scss" scoped>
.visitors-milestone-full-grid {
  @media not print {
    width: 100%;
    height: 989px;
    overflow-x: auto;

    &__main-wrapper {
      width: 1377px;
      padding: 12px 10px 32px;
      margin: 0 auto;
    }
  }

  &__header,
  &__header-left,
  &__header-right {
    display: flex;
  }

  &__header {
    align-items: flex-start;
    justify-content: space-between;
  }

  &__header-left,
  &__header-right {
    align-items: center;
  }

  &__header-right {
    margin-bottom: 3px;

    button + button {
      margin-left: 16px;
    }
  }

  &__title {
    display: none;
  }

  &__grid-wrapper {
    padding-right: 2px;
  }

  &__footer {
    position: relative;
    width: 1051px;
    margin: 24px auto 0;
  }

  &__grid-legend {
    position: absolute;
    top: -4px;
    width: 100%;

    :deep() .grid-legend-wrapper {
      gap: 8px;
    }

    :deep() .grid-legend-container {
      flex-basis: 0;
      flex-grow: 1;
      max-width: initial;
      background-color: #fff;
    }

    :deep() .el-collapse-item__wrap,
    :deep() .el-collapse-item__content {
      background: none;
    }
  }
}

@media print {
  .visitors-milestone-full-grid {
    width: 1377px;
    padding: 12px 10px 32px;
    margin: 20px auto 0;

    &__company-logo {
      margin-right: 9px;
    }

    &__title {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-bottom: 20px;
    }

    &__title-text {
      @include grotesk(semiBold);

      font-size: 20px;
      line-height: 30px;
    }

    &__arrow-separator {
      margin: 0 8px;
    }

    &__title-separator {
      position: relative;
      top: 1px;
      width: 4px;
      height: 4px;
      margin: 0 10px;
      background: $manatee;
      border-radius: 50%;
    }

    &__header-right,
    &__footer {
      display: none;
    }
  }
}
</style>
