import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-3f9f783f"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "milestone-tooltip-buttons" }
const _hoisted_2 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PxButton = _resolveComponent("PxButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", null, [
      _createVNode(_component_PxButton, {
        label: _ctx.planButtonLabel,
        size: _ctx.buttonSizes.SMALL,
        type: _ctx.planButtonType,
        class: "milestone-tooltip-button",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('plan')))
      }, null, 8, ["label", "size", "type"])
    ]),
    (!_ctx.isFutureMilestone && !_ctx.isVisitor)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_PxButton, {
            label: _ctx.$t('milestonePlanner.milestone.actions.complete'),
            size: _ctx.buttonSizes.SMALL,
            type: _ctx.completeButtonType,
            class: "milestone-tooltip-button",
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('complete')))
          }, null, 8, ["label", "size", "type"])
        ]))
      : _createCommentVNode("", true)
  ]))
}