<template>
  <div
    :class="{ 'list-management-modal-item--selected': innerSelected }"
    class="list-management-modal-item"
    @click="onItemClick"
  >
    <span class="list-management-modal-item__text">
      {{ value.title }}
    </span>
    <ElCheckbox
      v-model="innerSelected"
      class="list-management-modal-item__checkbox"
      @change="onItemClick"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { EListManagementActions } from "@/modules/company-lists/services/store/list-management/list-management.types";
import { ICompanyList } from "@/modules/company-lists/services/data/company-list/company-list.interface";

export default defineComponent({
  name: "ListManagementModalItem",

  props: {
    value: {
      type: Object as () => ICompanyList,
      required: true,
    },
  },

  data() {
    return {
      innerSelected: false,
    };
  },

  computed: {
    selectedLists(): Array<ICompanyList> {
      return this.$store.get("listManagement.data.lists") || [];
    },

    isListSelected(): boolean {
      return this.selectedLists.some(
        (item: ICompanyList) => item.uid === this.value.uid,
      );
    },
  },

  watch: {
    isListSelected: {
      immediate: true,
      handler(newValue: boolean) {
        // Prevent hot reloading loop
        if (newValue === this.innerSelected) {
          return;
        }

        this.innerSelected = newValue;
      },
    },

    innerSelected: {
      immediate: true,
      handler(newValue: boolean) {
        // Prevent hot reloading loop
        if (newValue === this.isListSelected) {
          return;
        }

        if (newValue) {
          this.$store.dispatch(EListManagementActions.ADD_LIST, this.value);
          return;
        }

        this.$store.dispatch(EListManagementActions.REMOVE_LIST, this.value);
      },
    },
  },

  methods: {
    // Allow trigger select after clicking anywhere on the item
    onItemClick() {
      this.innerSelected = !this.innerSelected;
    },
  },
});
</script>

<style lang="scss" scoped>
.list-management-modal-item {
  display: grid;
  grid-template-columns: 1fr 22px;
  width: 100%;
  height: 50px;
  padding: 13px 0;
  cursor: pointer;
  border: 1px solid rgba($white, 0);
  border-bottom-color: $athens-gray;

  &:last-child {
    border-bottom-color: rgba($white, 0);
  }

  &__text {
    overflow: hidden;
    font-size: 14px;
    color: $manatee;
    text-overflow: ellipsis;
    white-space: nowrap;
    transition: $--color-transition-base;
  }

  &--selected &__text,
  &:hover &__text {
    color: $ebony-clay;
  }

  :deep() .el-checkbox,
  :deep() .el-checkbox__inner,
  :deep() .el-checkbox__input {
    width: 22px;
    height: 22px;
    margin: 0;
  }

  :deep() .el-checkbox__inner {
    opacity: 0;
    transition: $--fade-linear-transition;
    transform: translateY(-3px);
  }

  &:hover :deep() .el-checkbox__inner {
    opacity: 1;
  }

  :deep() .el-checkbox__input:not(.is-checked) .el-checkbox__inner {
    background-color: $white;
    box-shadow: none;
  }

  :deep() .el-checkbox__input.is-checked .el-checkbox__inner {
    background-color: $malibu;
    border-color: $malibu;
    opacity: 1;
  }
}
</style>
