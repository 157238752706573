import axios, { CancelTokenSource } from "axios";
import { ActionTree } from "vuex";
import {
  IMatchingState,
  EMatchingActions,
  IMatchingFetchPayload,
  EMatchingMutations,
} from "./matching.types";
import { RootState } from "@/services/store/root-state";
import { getBaseName } from "@/services/store/utils/get-base-name";
import { matchingScoreProvider } from "@/modules/matching/services/data/matching-score/matching-score.provider";
import { IMatchingScore } from "@/modules/matching/services/data/matching-score/matching-score.interface";
import { matchingLoadingProvider } from "@/modules/matching/services/data/matching-score/matching-score-loading.provider";
import isEqual from "lodash/isEqual";

let onGoingRequest: {
  payload: IMatchingFetchPayload;
  cancelRequest: CancelTokenSource;
};

export const matchingActions: ActionTree<IMatchingState, RootState> = {
  /**
   * Allow to fetch a page from the list of matching score.
   *
   * @param param0 VUEX context
   * @param payload Allows to set the page and the number of items per
   * page to fetched.
   */
  async [getBaseName(EMatchingActions.FETCH)](
    { commit, state },
    payload: IMatchingFetchPayload,
  ) {
    commit(EMatchingMutations.SET_ERROR, null);
    commit(EMatchingMutations.SET_LOADING, true);

    const currentPage = payload.page || 1;
    let response;

    try {
      const isExactSameRequest =
        !!onGoingRequest?.payload && isEqual(payload, onGoingRequest.payload);
      const isMakingANewRequest =
        !!onGoingRequest?.payload &&
        !isEqual(
          { ...payload, page: null },
          { ...onGoingRequest.payload, page: null },
        );
      const hasEmptyResults = !state.data.length && currentPage === 1;

      if (isExactSameRequest && !hasEmptyResults) {
        // Skip making the same request
        return;
      }

      if (isMakingANewRequest) {
        // Cancel previous request
        onGoingRequest.cancelRequest.cancel();
      }

      // Update current request
      onGoingRequest = {
        payload,
        cancelRequest: axios.CancelToken.source(),
      };

      response = await matchingScoreProvider.list(payload, {
        cancelToken: onGoingRequest.cancelRequest.token,
      });

      if (currentPage === 1) {
        commit(EMatchingMutations.SET_DATA, response);
      } else {
        commit(EMatchingMutations.SET_APPEND_DATA, response);
      }
      commit(EMatchingMutations.SET_PAGE, currentPage);
      commit(EMatchingMutations.SET_LOADING, false);
    } catch (e) {
      commit(EMatchingMutations.SET_ERROR, e);
    }

    return response;
  },

  /**
   * Allow to set directly the company scores, with pagination
   *
   * @param param0 VUEX context
   * @param payload
   */
  [getBaseName(EMatchingActions.SET)](
    { commit },
    { data, page = 1 }: { data: Array<IMatchingScore>; page: number },
  ) {
    if (page === 1) {
      commit(EMatchingMutations.SET_DATA, data);
    } else {
      commit(EMatchingMutations.SET_APPEND_DATA, data);
    }

    commit(EMatchingMutations.SET_PAGE, page);
  },

  /**
   * Allow to set directly the company scores, with pagination
   *
   * @param param0 VUEX context
   * @param payload
   */
  [getBaseName(EMatchingActions.APPEND)](
    { commit, state },
    { data, page = 1 }: { data: Array<IMatchingScore>; page: number },
  ) {
    commit(EMatchingMutations.SET_APPEND_DATA, [...state.data, ...data]);
    commit(EMatchingMutations.SET_PAGE, page);
  },

  /**
   * Allows fetching the current matching calculations loading state.
   *
   * @param param0 VUEX context
   */
  async [getBaseName(EMatchingActions.FETCH_RECALCULATIONS_STATE)]({ commit }) {
    try {
      const is_recalculating = await matchingLoadingProvider.fetch();
      commit(EMatchingMutations.SET_RECALCULATING, is_recalculating);
    } catch (e) {
      commit(EMatchingMutations.SET_ERROR, e);
    }
  },
};
