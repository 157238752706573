<template>
  <ElDialog
    v-if="finalLevel"
    ref="modal"
    v-model="innerVisibility"
    :before-close="onBeforeCloseModal"
    :close-on-click-modal="false"
    class="upgrade-modal"
    width="492px"
  >
    <canvas
      v-if="isLevelUp"
      class="upgrade-modal__animation"
      data-testid="animation"
    />
    <PxButton
      v-if="isBackToMatching"
      class="upgrade-modal__back-to-matching"
      type="link"
      @click="onClickBackToMatching"
    >
      {{ $t("common.components.updateModal.backToMatching") }}
    </PxButton>
    <h1
      :class="{
        'upgrade-modal__title--down': !isFirstAssessment && !isLevelUp,
      }"
      class="upgrade-modal__title"
    >
      {{ congratsMsg }}
    </h1>
    <h3 v-if="!isFirstAssessment && !isLevelUp" class="upgrade-modal__quote">
      "{{ $t("common.components.updateModal.levelDown.quote") }}"
    </h3>
    <i18n-t
      class="upgrade-modal__description"
      keypath="common.components.updateModal.description"
      tag="p"
    >
      <template #level>
        <span class="upgrade-modal__description-level">
          {{ $t("common.level") }} {{ finalLevel.value }}
        </span>
      </template>
    </i18n-t>
    <PxIcon
      :name="`level-graph/${finalLevel.value}`"
      :size="123"
      class="upgrade-modal__level"
    />
    <p
      :class="{
        'upgrade-modal__level-title--down': !isFirstAssessment && !isLevelUp,
      }"
      class="upgrade-modal__level-title"
    >
      {{ finalLevel.title }}
    </p>
    <p class="upgrade-modal__level-description">
      {{ finalLevel.description }}
    </p>
  </ElDialog>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import { ILevel } from "@/services/data/level/level.interface";
import ElDialogMixin from "@/mixins/el-dialog.mixin";
import * as particleRender from "@/utils/particle.render";
import { ROUTE_MATCHING_LIST } from "@/modules/matching/services/router/routes-names";
import { TranslateResult } from "vue-i18n";
import {
  QUICK_LEVEL_UPDATE_META_FIRST_ASSESSMENT,
  QUICK_LEVEL_FROM,
} from "@/modules/common/constants";

export default defineComponent({
  name: "UpgradeModal",

  mixins: [ElDialogMixin],

  props: {
    isLevelUp: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    finalLevel(): ILevel {
      return this.$store.get("viralLevel.finalLevel");
    },

    isFirstAssessment(): boolean {
      return this.$store.get(
        "meta/get",
        QUICK_LEVEL_UPDATE_META_FIRST_ASSESSMENT,
      );
    },

    congratsMsg(): TranslateResult {
      return this.isFirstAssessment
        ? this.$t("common.components.updateModal.congrats.firstAssessment")
        : this.isLevelUp
          ? this.$t("common.components.updateModal.congrats.levelUp")
          : this.$t("common.components.updateModal.congrats.levelDown");
    },

    /**
     * Check if the user is coming from the matching.
     */
    isBackToMatching(): boolean {
      const toRoute = this.$store.get("meta/get", QUICK_LEVEL_FROM);
      return toRoute === ROUTE_MATCHING_LIST;
    },
  },

  watch: {
    async innerVisibility(newVal) {
      if (!newVal) {
        return;
      }
      await this.$nextTick();
      if (this.isLevelUp) {
        this.playAnimation();
      }
    },
  },

  methods: {
    onClickBackToMatching() {
      this.$router.push({ name: ROUTE_MATCHING_LIST });
    },

    onBeforeCloseModal(close: any) {
      this.$emit("close");
      close();
    },

    playAnimation() {
      const canvasElement = document.querySelector(
        ".upgrade-modal .upgrade-modal__animation",
      ) as HTMLCanvasElement;
      const bodyElement = document.querySelector(
        ".upgrade-modal .el-dialog__body",
      ) as HTMLElement;
      const elementWidth = bodyElement.clientWidth;
      const elementHeight = bodyElement.clientHeight;

      const context = particleRender.createContext(
        canvasElement,
        elementWidth,
        elementHeight,
      );
      const centerPoint = particleRender.getCanvasCenterPoint(context);
      particleRender.addConfettiParticles(
        context,
        30,
        centerPoint.x - 10,
        centerPoint.y,
      );
      particleRender.render(context);
    },
  },
});
</script>

<style lang="scss">
.upgrade-modal {
  .el-dialog__header {
    padding: 0;
    text-align: center;
    background-color: transparent;
    border: none;
  }

  .el-dialog__body {
    padding: 25px 30px 40px;
  }

  .el-dialog__headerbtn {
    top: 20px;
    right: 14px;
    z-index: z("floaters");
    width: 22px;
    height: 22px;
  }

  &__animation {
    position: absolute;
    top: 0;
    left: 0;
  }

  &__title {
    @include grotesk(semiBold);

    padding-top: 9px;
    font-size: 20px;

    color: $bluish;
    text-align: center;
    letter-spacing: -0.11px;

    &--down {
      margin-bottom: 6px;
      font-weight: normal;
      letter-spacing: -0.5px;
    }
  }

  &__description {
    padding-top: 6px;
    padding-right: 6px;
    font-size: 17px;
    line-height: 20px;
    color: $ebony-clay;
    text-align: center;
    letter-spacing: -0.3px;

    &-level {
      @include grotesk(semiBold);
    }

    span {
      font-size: inherit;
    }
  }

  &__quote {
    @include grotesk(bold);

    margin-bottom: 3px;
    font-size: 15px;
    font-style: italic;
    line-height: 25px;
    color: $bluish;
    text-align: center;
    letter-spacing: 0.09px;
  }

  &__level {
    position: relative;
    left: 50%;
    margin-top: 4px;
    margin-bottom: -9px;

    transform: translateX(-50%);

    @include breakpoint-up(sm) {
      margin-top: 14px;
    }
  }

  &__level-title {
    @include grotesk(semiBold);

    margin-top: 11px;
    margin-bottom: 9px;

    font-size: 20px;
    line-height: 30px;
    color: $denim;
    text-align: center;

    &--down {
      font-weight: normal;
      letter-spacing: -0.3px;
    }
  }

  &__level-description {
    margin-bottom: 1px;

    font-size: 15px;
    line-height: 22px;
    color: $ebony-clay;
    text-align: center;
    letter-spacing: -0.3px;
  }
}

.upgrade-modal__back-to-matching {
  @include grotesk(semiBold);

  position: absolute;
  top: -40px;
  left: 12px;

  font-size: to-rem(15px);
  color: white;

  @include breakpoint-up(md) {
    top: -43px;
    left: 11px;

    font-size: to-rem(14px);
  }
}

.upgrade-modal__back-to-matching.el-button span {
  letter-spacing: -0.5px;
}

.upgrade-modal__back-to-matching.el-button:hover span {
  color: rgba(white, 0.8);
}
</style>
