import {
  GenericProvider,
  EProviderFeatures,
} from "@/services/data/generic-provider";
import { ILevel } from "./level.interface";

/**
 * Provider that allows interact with the levels.
 */
class LevelProvider extends GenericProvider<ILevel> {
  constructor() {
    super("/levels/", [EProviderFeatures.LIST]);
  }
}

export const levelProvider = new LevelProvider();
