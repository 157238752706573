import { Module } from "vuex";

import { ISupporterInvestingLevelState } from "./supporter-investing-level-types";
import { RootState } from "@/services/store/root-state";

import { state } from "./supporter-investing-level-state";
import { mutations } from "./supporter-investing-level-mutations";
import { getters } from "./supporter-investing-level-getters";
import { actions } from "./supporter-investing-level-actions";

export const supporterInvestingLevelModule: Module<
  ISupporterInvestingLevelState,
  RootState
> = {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
