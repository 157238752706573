/**
 * This this responsible for register all global components.
 */

import { App } from "vue";

import PxIcon from "@/components/px-icon/px-icon.vue";
import PxResponsiveBitmap from "@/components/px-responsive-bitmap/px-responsive-bitmap.vue";
import PxPanel from "@/components/px-panel/px-panel.vue";
import PxBadge from "@/components/px-badge/px-badge.vue";
import PxGlobalError from "@/components/px-global-error/px-global-error.vue";
import PxModalMessage from "@/components/px-modal-message/px-modal-message.vue";
import PxSteps from "@/components/px-steps/px-steps.vue";
import PxAvatar from "@/components/px-avatar/px-avatar.vue";
import PxAvatarGrid from "@/components/px-avatar/px-avatar-grid.vue";
import PxAvatarSet from "@/components/px-avatar/px-avatar-set.vue";
import PxInputPassword from "@/components/px-input-password/px-input-password.vue";
import PxInputPlaces from "@/components/px-input-places/px-input-places.vue";
import PxCounter from "@/components/px-counter/px-counter.vue";
import PxTextarea from "@/components/px-textarea/px-textarea.vue";
import PxTabBar from "@/components/px-tab-bar/px-tab-bar.vue";
import PxBottomBar from "@/components/px-bottom-bar/px-bottom-bar.vue";
import PxFooter from "@/components/px-footer/px-footer.vue";
import PxNavbar from "@/components/px-navbar/px-navbar.vue";
import PxNavbarMinimal from "@/components/px-navbar-minimal/px-navbar-minimal.vue";
import PxNavbarWizard from "@/components/px-navbar-wizard/px-navbar-wizard.vue";
import PxTabsMobile from "@/components/px-tabs-mobile/px-tabs-mobile.vue";
import PxExpand from "@/components/px-expand/px-expand.vue";
import PxNotice from "@/components/px-notice/px-notice.vue";
import PxNoticeBar from "@/components/px-notice/px-notice-bar.vue";
import PxQuestionary from "@/components/px-questionary/px-questionary.vue";
import PxQuestionarySelect from "@/components/px-questionary/px-questionary-select.vue";
import PxMoney from "@/components/px-money/px-money.vue";
import PxNumeric from "@/components/px-numeric-input/px-numeric-input.vue";
import PxMatchingInterest from "@/components/px-matching-interest/px-matching-interest.vue";
import PxMatchingInterestCompact from "@/components/px-matching-interest/px-matching-interest-compact.vue";
import PxMoreList from "@/components/px-more-list/px-more-list.vue";
import PxListModal from "@/components/px-list-modal/px-list-modal.vue";
import PxFormModal from "@/components/px-form-modal/px-form-modal.vue";
import PxDateSelect from "@/components/px-date-select/px-date-select.vue";
import PxSliderCard from "@/components/px-slider-card/px-slider-card.vue";
import PxSwitch from "@/components/px-switch/px-switch.vue";
import PxButton from "@/components/px-button/px-button.vue";
import PxStepByStepDialog from "@/components/px-step-by-step-dialog/px-step-by-step-dialog.vue";
import PxStepByStepDialogNavigation from "@/components/px-step-by-step-dialog/px-step-by-step-dialog-navigation.vue";
import PxRadioGroup from "@/components/px-radio-group/px-radio-group.vue";
import DialogConfirmation from "@/components/dialog-confirmation/dialog-confirmation.vue";
import ContentPlaceholders from "@/components/content-placeholders/content-placeholders.vue";
import ContentPlaceholdersHeading from "@/components/content-placeholders/content-placeholders-heading.vue";
import ContentPlaceholdersImg from "@/components/content-placeholders/content-placeholders-img.vue";
import ContentPlaceholdersText from "@/components/content-placeholders/content-placeholders-text.vue";

export const globalComponents = (app: App) => {
  app.component(PxTabsMobile.name as string, PxTabsMobile);
  app.component(PxNavbar.name as string, PxNavbar);
  app.component(PxNavbarMinimal.name as string, PxNavbarMinimal);
  app.component(PxNavbarWizard.name as string, PxNavbarWizard);
  app.component(PxFooter.name as string, PxFooter);
  app.component(PxTabBar.name as string, PxTabBar);
  app.component(PxBottomBar.name as string, PxBottomBar);
  app.component(PxIcon.name as string, PxIcon);
  app.component(PxResponsiveBitmap.name as string, PxResponsiveBitmap);
  app.component(PxPanel.name as string, PxPanel);
  app.component(PxBadge.name as string, PxBadge);
  app.component(PxGlobalError.name as string, PxGlobalError);
  app.component(PxModalMessage.name as string, PxModalMessage);
  app.component(PxSteps.name as string, PxSteps);
  app.component(PxAvatar.name as string, PxAvatar);
  app.component(PxAvatarGrid.name as string, PxAvatarGrid);
  app.component(PxAvatarSet.name as string, PxAvatarSet);
  app.component(PxInputPassword.name as string, PxInputPassword);
  app.component(PxInputPlaces.name as string, PxInputPlaces);
  app.component(PxCounter.name as string, PxCounter);
  app.component(PxTextarea.name as string, PxTextarea);
  app.component(PxExpand.name as string, PxExpand);
  app.component(PxNotice.name as string, PxNotice);
  app.component(PxNoticeBar.name as string, PxNoticeBar);
  app.component(PxQuestionary.name as string, PxQuestionary);
  app.component(PxQuestionarySelect.name as string, PxQuestionarySelect);
  app.component(PxMoney.name as string, PxMoney);
  app.component(PxNumeric.name as string, PxNumeric);
  app.component(PxMatchingInterest.name as string, PxMatchingInterest);
  app.component(
    PxMatchingInterestCompact.name as string,
    PxMatchingInterestCompact,
  );
  app.component(PxMoreList.name as string, PxMoreList);
  app.component(PxListModal.name as string, PxListModal);
  app.component(PxFormModal.name as string, PxFormModal);
  app.component(PxDateSelect.name as string, PxDateSelect);
  app.component(PxSliderCard.name as string, PxSliderCard);
  app.component(PxSwitch.name as string, PxSwitch);
  app.component(PxButton.name as string, PxButton);
  app.component(PxStepByStepDialog.name as string, PxStepByStepDialog);
  app.component(
    PxStepByStepDialogNavigation.name as string,
    PxStepByStepDialogNavigation,
  );
  app.component(PxRadioGroup.name as string, PxRadioGroup);
  app.component(DialogConfirmation.name as string, DialogConfirmation);
  app.component("ContentPlaceholders", ContentPlaceholders);
  app.component("ContentPlaceholdersHeading", ContentPlaceholdersHeading);
  app.component("ContentPlaceholdersImg", ContentPlaceholdersImg);
  app.component("ContentPlaceholdersText", ContentPlaceholdersText);
};
