<template>
  <div class="user-responses-table">
    <div class="user-responses-table__table">
      <div
        :class="{
          'user-responses-table__headers--no-results': !allQuestionsAnswered,
        }"
        class="user-responses-table__headers"
      >
        <div>
          {{ $t("capitalExplorer.detailsModal.tableHeader[0].title") }}
        </div>
        <div>
          {{ answerHeaderCopy }}
        </div>
      </div>
      <div
        v-for="(data, index) in tableData"
        :key="index"
        class="user-responses-table__content"
      >
        <div>
          <p>
            {{ data.question }}
          </p>
          <PxButton
            v-if="data.instructions"
            class="user-responses-table__learn-more"
            v-bind="learnMoreButtonProps"
            @click.stop="showLearnMoreModal(data.question, data.instructions)"
          />
        </div>
        <MatchingCardTagList
          v-if="data.response.length"
          :capitalize="false"
          :items="data.response"
          :list-format="true"
          :max-items-to-display="2"
          :should-sort-array="false"
          :title="answerHeaderCopy"
          data-testid="user-responses-table-response"
        />
        <div v-else data-testid="user-responses-table-no-response">
          <ul>
            <li>
              <i>
                {{ $t("common.noAnswer") }}
              </i>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <ElDialog
      v-if="learnMoreModalVisible"
      v-model="learnMoreModalVisible"
      :append-to-body="true"
      :title="learnMoreModalTitle"
      class="user-responses-table__modal"
      top="0"
    >
      <p>
        {{ learnMoreModalText }}
      </p>
    </ElDialog>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import MatchingCardTagList from "@/components/matching-card/matching-card-tag-list.vue";

import { EPxButtonType } from "@/components/px-button/px-button.types";
import { CAPITAL_EXPLORER_VISITORS_VIEW } from "@/modules/capital-explorer/services/router/routes-names";
import { TranslateResult } from "vue-i18n";

export default defineComponent({
  name: "UserResponsesTable",

  components: {
    MatchingCardTagList,
  },

  props: {
    tableData: {
      type: Array as () => any,
      required: true,
    },

    allQuestionsAnswered: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      learnMoreModalVisible: false,
      learnMoreModalTitle: "",
      learnMoreModalText: "",
    };
  },

  computed: {
    learnMoreButtonProps(): object {
      return {
        type: EPxButtonType.LINK,
        label: this.$t("common.learnMore"),
      };
    },

    answerHeaderCopy(): TranslateResult {
      const isVisitorsView =
        this.$route.name === CAPITAL_EXPLORER_VISITORS_VIEW;

      return isVisitorsView
        ? this.$t(`capitalExplorer.detailsModal.tableHeader[1].title[${1}]`)
        : this.$t(`capitalExplorer.detailsModal.tableHeader[1].title[${0}]`);
    },
  },

  methods: {
    showLearnMoreModal(question: string, instructions: string) {
      this.learnMoreModalTitle = question;
      this.learnMoreModalText = instructions;
      this.learnMoreModalVisible = true;
    },
  },
});
</script>

<style lang="scss">
.user-responses-table {
  &__modal {
    min-width: 500px;
    margin: 0;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    .el-dialog__header {
      padding: 23px 58px 25px 40px;
    }

    .el-dialog__title {
      font-size: 22px;
      line-height: 1.2;
      letter-spacing: -0.29px;
    }

    .el-dialog__headerbtn {
      top: 28px;
      right: 28px;
    }
  }

  &__modal .el-dialog__body {
    padding: 40px 40px 37px;
    max-height: 500px;

    overflow-y: scroll;

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
      margin: 30px;
    }

    &::-webkit-scrollbar-thumb {
      background: $mischka;
      border-radius: 10px;
    }
    p {
      font-size: 15px;
      line-height: 1.47;
      color: $ebony-clay;
      letter-spacing: -0.33px;
    }
  }

  &__headers,
  &__content {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  &__headers {
    position: sticky;
    top: 0;
    z-index: 103;
    height: 83px;

    color: $manatee;
    background: $alabaster;
    font-size: 12px;
    font-weight: 600;
    line-height: normal;
    text-transform: uppercase;
    border-bottom: 1px solid rgba(0, 0, 0, 0.06);

    div {
      position: relative;
      display: flex;
      align-items: center;
      padding: 0 16px 1px 15px;
    }
  }

  &__headers--no-results {
    height: 55px;
  }

  &__content {
    height: 161px;
    font-size: 15px;
    font-weight: 400;
    line-height: 22px;

    background: $white;

    &:not(:last-of-type) {
      border-bottom: 1px solid rgba(0, 0, 0, 0.06);
    }

    > div {
      padding: 15px 16px 16px 15px;
      color: $manatee;
    }

    div:first-of-type p {
      color: $ebony-clay;
      letter-spacing: -0.34px;
      line-height: 22px;
      margin-bottom: 0;
    }
  }

  &__learn-more {
    span {
      font-size: 14px;
    }
  }

  &__headers div:first-of-type::before {
    position: absolute;
    top: 50%;
    right: 0;
    display: block;
    width: 1px;
    height: 22px;
    transform: translateY(-50%);

    content: "";

    background: rgba(0, 0, 0, 0.06);
  }

  li::marker,
  .matching-card-tag-list__container li::marker {
    font-size: 12px;
    color: $manatee;
  }

  ul,
  .matching-card-tag-list__container ul {
    padding-left: 11px;
    margin-bottom: 0;
  }

  li,
  .matching-card-tag-list__container li {
    position: relative;
    bottom: 1px;

    color: $manatee;
    letter-spacing: -0.66px;
    line-height: 20px;
  }

  .matching-card-tag-list__container {
    position: relative;
    bottom: 1px;
    right: 1px;

    height: auto;
    font-weight: 400;
    text-transform: none;
  }

  .matching-card-tag-list__more {
    margin-left: 14px;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;

    color: $manatee;
    text-decoration: underline;

    cursor: pointer;
  }
}
</style>
