import { IMilestonePlannersOnboardingsState } from "@/modules/milestone-planner/services/store/milestone-planner/sub-modules/onboardings/onboardings.interface";

export const mutations = {
  SET_DATA: (
    state: IMilestonePlannersOnboardingsState,
    payload: { [key: string]: boolean },
  ) => {
    state.data = { ...state.data, ...payload };
  },
};
