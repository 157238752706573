import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CategoryLevelsAssessmentMobile = _resolveComponent("CategoryLevelsAssessmentMobile")!
  const _component_CategoryLevelsAssessmentDesktop = _resolveComponent("CategoryLevelsAssessmentDesktop")!
  const _component_RenderlessEntrepreneurAssessment = _resolveComponent("RenderlessEntrepreneurAssessment")!

  return (_openBlock(), _createBlock(_component_RenderlessEntrepreneurAssessment, null, {
    default: _withCtx((scope) => [
      (_ctx.$screen.smDown)
        ? (_openBlock(), _createBlock(_component_CategoryLevelsAssessmentMobile, {
            key: 0,
            "current-category": scope.currentCategory,
            "on-level-change": scope.onLevelChange,
            "selected-step": scope.selectedStep
          }, null, 8, ["current-category", "on-level-change", "selected-step"]))
        : (_openBlock(), _createBlock(_component_CategoryLevelsAssessmentDesktop, {
            key: 1,
            "current-category": scope.currentCategory,
            "is-first-interaction": scope.isFirstInteraction,
            "on-level-change": scope.onLevelChange,
            "selected-step": scope.selectedStep
          }, null, 8, ["current-category", "is-first-interaction", "on-level-change", "selected-step"]))
    ]),
    _: 1
  }))
}