import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode, withModifiers as _withModifiers, mergeProps as _mergeProps, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "no-processes-modal" }
const _hoisted_2 = { class: "no-processes-modal__wrapper" }
const _hoisted_3 = { class: "el-dialog__header" }
const _hoisted_4 = { class: "el-dialog__title" }
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = { class: "no-processes-modal__example" }
const _hoisted_7 = ["innerHTML"]
const _hoisted_8 = { key: 1 }
const _hoisted_9 = { key: 0 }
const _hoisted_10 = ["innerHTML"]
const _hoisted_11 = ["innerHTML"]
const _hoisted_12 = { class: "el-dialog__footer" }
const _hoisted_13 = {
  key: 0,
  class: "no-processes-modal__overlay el-overlay"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PxNavigationTab = _resolveComponent("PxNavigationTab")!
  const _component_PxButton = _resolveComponent("PxButton")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _withDirectives(_createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("header", _hoisted_3, [
          _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.$t(_ctx.titlePath)), 1),
          (_ctx.showClose)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: "el-dialog__headerbtn",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.innerVisibility = false))
              }))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("nav", null, [
          _createVNode(_component_PxNavigationTab, {
            "selected-tab": _ctx.selectedNavbarId,
            "onUpdate:selectedTab": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectedNavbarId) = $event)),
            "navigation-tabs": _ctx.navBarItems,
            "show-number": false,
            "list-format": false
          }, null, 8, ["selected-tab", "navigation-tabs"])
        ]),
        _createElementVNode("main", null, [
          _createElementVNode("p", {
            innerHTML: _ctx.modalCopy.description
          }, null, 8, _hoisted_5),
          _createElementVNode("div", _hoisted_6, [
            (_ctx.selectedNavbarId == 1)
              ? (_openBlock(), _createElementBlock("p", {
                  key: 0,
                  innerHTML: _ctx.$t('chartsDashboard.requestProcessesModal.example')
                }, null, 8, _hoisted_7))
              : (_openBlock(), _createElementBlock("ul", _hoisted_8, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.modalExampleCopy, (copy, index) => {
                    return (_openBlock(), _createElementBlock("li", { key: index }, [
                      _createElementVNode("b", null, _toDisplayString(copy.title), 1),
                      _createTextVNode(" " + _toDisplayString(copy.description) + " ", 1),
                      (copy.note)
                        ? (_openBlock(), _createElementBlock("ul", _hoisted_9, [
                            _createElementVNode("li", {
                              innerHTML: copy.note
                            }, null, 8, _hoisted_10)
                          ]))
                        : _createCommentVNode("", true)
                    ]))
                  }), 128))
                ]))
          ]),
          _createElementVNode("small", {
            innerHTML: _ctx.modalCopy.note
          }, null, 8, _hoisted_11)
        ]),
        _createElementVNode("div", _hoisted_12, [
          _createVNode(_component_PxButton, _mergeProps(_ctx.buttonProps, {
            onClick: _withModifiers(_ctx.openEmailClient, ["stop"])
          }), null, 16, ["onClick"])
        ])
      ])
    ], 512), [
      [_vShow, _ctx.innerVisibility]
    ]),
    (_ctx.innerVisibility && _ctx.showOverlay)
      ? (_openBlock(), _createElementBlock("div", _hoisted_13))
      : _createCommentVNode("", true)
  ], 64))
}