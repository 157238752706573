<template>
  <div v-loading="isLoading" class="share-modal-settings">
    <div class="share-modal-settings__body">
      <ShareModalSettingsLink
        :copy="linkCopy"
        :link="link"
        class="share-modal-settings__body-entry"
      />
      <ShareModalSettingsPasscode
        v-model:needs-reset="hasChangedPasscodeSetting"
        v-model:passcode="currentPasscode"
        :copy="passcodeCopy"
        class="share-modal-settings__body-entry"
        data-testid="share-modal-settings__passcode"
        @change="changeRestrictionHandler"
      />
    </div>
    <div class="share-modal-settings__footer">
      <PxButton
        class="share-modal-settings__footer-link"
        size="small"
        type="link"
        @click="onClickBackHandler"
      >
        {{ $t("common.back") }}
      </PxButton>
      <PxButton
        :disabled="!canSaveChanges || isLoading"
        class="share-modal-settings__footer-button"
        type="green"
        @click="onClickSaveHandler"
      >
        {{ $t("common.saveChanges") }}
      </PxButton>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import ShareModalSettingsLink from "@/components/share-modal/share-modal-settings-link.vue";
import ShareModalSettingsPasscode from "@/components/share-modal/share-modal-settings-passcode.vue";

export default defineComponent({
  name: "ShareModalSettings",

  components: {
    ShareModalSettingsLink,
    ShareModalSettingsPasscode,
  },

  props: {
    link: {
      type: String,
      required: true,
    },

    passcode: {
      type: String,
      required: true,
    },

    isLoading: {
      type: Boolean,
      default: false,
    },

    passcodeCopy: {
      type: String,
      default: "milestonePlanner.shareModal.passcode",
    },

    linkCopy: {
      type: String,
      default: "common.shareModal.settings.link",
    },
  },

  data() {
    return {
      currentPasscode: "",
      canSaveChanges: false,
      hasPasscodeRestriction: false,
      hasChangedPasscodeSetting: false,
    };
  },

  watch: {
    passcode(newValue: string, previousValue: string) {
      if (newValue !== this.currentPasscode || !previousValue.length) {
        this.currentPasscode = newValue;
        this.canSaveChanges = false;
      }
    },

    hasChangedPasscodeSetting(hasChanged: boolean) {
      if (!hasChanged) {
        this.canSaveChanges = hasChanged;
      }
    },
  },

  created() {
    this.currentPasscode = this.passcode;
  },

  methods: {
    onClickBackHandler() {
      this.$emitter.emit("toggle-settings", false);
      this.currentPasscode = this.passcode;
      this.hasChangedPasscodeSetting = this.canSaveChanges;
    },

    onClickSaveHandler() {
      const newPasscode = this.hasPasscodeRestriction
        ? this.currentPasscode
        : null;
      this.$emit("save", newPasscode);
      this.canSaveChanges = false;
    },

    changeRestrictionHandler(isRestricted: boolean, newPasscode: string) {
      this.hasPasscodeRestriction = isRestricted;
      this.canSaveChanges =
        (isRestricted && newPasscode !== this.passcode) ||
        (!isRestricted && !!this.passcode);
    },
  },
});
</script>

<style lang="scss" scoped>
.share-modal-settings {
  display: flex;
  flex-flow: row wrap;

  &__body {
    flex: 1 1 auto;
    min-height: 466px;
    padding: 1px 0 0 0;
  }

  &__body-entry:not(:first-child) {
    margin-top: 24px;
  }

  &__footer {
    width: 100%;
    padding: 7px 2px 2px 0;
    text-align: right;
  }

  &__footer-button {
    height: 40px;
    padding: 0 16px 0 17px;
    margin-left: 15px;
  }
}
</style>
