import { isDevelopment } from "@/services/utils/utils";

/**
 * Execute a Google Analytics method when loaded.
 *
 * @param args Arguments to be passed to the ga function.
 */
export const gaEvent = (...args: Array<any>): any => {
  if (!(window as any).gtag) {
    return false;
  }

  if (isDevelopment) {
    console.log("GA:", ...args);
  }

  return gtag.apply(ga, args);
};

export const gaTrackTime = (
  name: string,
  value: number,
  category?: string,
  label?: string,
) => {
  gaEvent("event", "timing_complete", {
    name,
    value,
    event_category: category,
    event_label: label,
  });
};

export const gaTrackEvent = (
  action: string,
  category?: string,
  label?: string,
  value?: number,
) => {
  gaEvent("event", action, {
    event_category: category,
    event_label: label,
    value,
  });
};
