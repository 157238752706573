<template>
  <div class="feature-card">
    <PxIcon
      v-if="icon"
      :name="icon"
      :size="iconSize"
      class="feature-card__icon"
    />
    <div class="feature-card__content">
      <h2 class="feature-card__title">
        {{ title }}
      </h2>
      <p class="feature-card__description" v-html="description" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

const ICON_SIZE_XS = 71;
const ICON_SIZE_ABOVE_XS = 78;

export default defineComponent({
  name: "FeatureCard",
  props: {
    icon: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
  },

  computed: {
    iconSize(): any {
      return this.$screen.xsOnly ? ICON_SIZE_XS : ICON_SIZE_ABOVE_XS;
    },
  },
});
</script>

<style lang="scss" scoped>
.feature-card {
  display: flex;
  flex-direction: row;

  @include breakpoint-up(md) {
    align-items: center;
  }

  &__content {
    padding-left: 9px;

    @include breakpoint-up(md) {
      position: relative;
      top: -1px;

      padding-left: 21px;
    }
  }

  &__title {
    @include grotesk(medium);

    position: relative;
    top: -3px;
    margin-top: 0;
    margin-bottom: -4px;

    font-size: 1.1333rem;
    line-height: 32px;

    @include breakpoint-up(md) {
      max-width: 248px;

      font-size: 1.2rem;
    }
  }

  &__description {
    font-size: 1rem;
    line-height: 25px;
    color: $manatee;
    letter-spacing: -0.16px;

    @include breakpoint-up(md) {
      max-width: 248px;
    }
  }
}
</style>
