<template>
  <ElFormItem
    ref="formItem"
    :class="{ 'is-editing': isEditing }"
    class="offerings-form__item"
    prop="offer"
  >
    <div class="offerings-form__item-column">
      <label>
        {{ $t("profile.supporter.offerings.form.label.categories") }}
      </label>
      <h3>
        {{ category.name }}
      </h3>
    </div>
    <div class="offerings-form__item-column">
      <label>
        {{ $t("profile.supporter.offerings.form.label.types") }}
      </label>
      <OfferingTypesSelect
        v-show="isEditing && offerCategoryTypes"
        ref="typesSelect"
        v-model="offerCategoryTypes"
        :types="category.category_types"
        @change="changedTypesHandler"
      />
      <div
        v-if="!isEditing && offerCategoryTypes && offerCategoryTypes.length"
        class="offerings-form__types"
      >
        <div v-for="item in offerCategoryTypes" :key="item.id">
          <ElTag effect="plain">
            {{ item.name }}
          </ElTag>
        </div>
      </div>
    </div>
    <div class="offerings-form__item-column">
      <label>
        {{ $t("profile.supporter.offerings.form.label.description") }}
      </label>
      <PxTextarea
        v-if="isEditing"
        ref="description"
        v-model="offer.description"
        :autosize="{ minRows: 4, maxRows: 10 }"
        :max-chars="400"
        :placeholder="
          $t('profile.supporter.offerings.form.placeholder.description')
        "
        show-counter
      />
      <div v-else class="offerings-form__item-description">
        {{ offer.description }}
      </div>
    </div>
    <div v-if="isEditing" class="offerings-form__item-footer">
      <PxButton
        class="offerings-form__cancel-btn"
        type="link"
        @click="onClickCancel"
      >
        {{ $t("common.cancel") }}
      </PxButton>
      <PxButton
        :disabled="!offerChanged"
        :loading="isLoading"
        class="offerings-form__save-btn"
        type="green"
        @click="onClickSave"
      >
        {{ $t("common.save") }}
      </PxButton>
    </div>
    <div v-if="!isEditing" class="offerings-form__item-column">
      <PxButton
        class="offerings-form__edit-btn"
        size="small"
        type="default"
        @click="onClickEdit"
      >
        {{ $t("common.edit") }}
      </PxButton>
      <PxButton
        :loading="isLoading"
        class="offerings-form__remove-btn"
        size="small"
        type="default"
        @click="onClickRemove"
      >
        {{ $t("common.remove") }}
      </PxButton>
    </div>
  </ElFormItem>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import OfferingTypesSelect from "@/modules/profile/components/offering-types-select/offering-types-select.vue";
import { offeringProvider } from "@/services/data/offering/offering.provider";

import {
  IOffering,
  IOfferingCategory,
  IOfferingType,
} from "@/services/data/offering/offering.interface";

import cloneDeep from "lodash/cloneDeep";
import isEqual from "lodash/isEqual";
import { ISupporter } from "@/modules/matching/services/data/matching-score/supporter.interface";

export default defineComponent({
  name: "OfferingsFormItem",

  components: {
    OfferingTypesSelect,
  },

  props: {
    offering: {
      type: Object as () => IOffering,
      required: true,
    },

    category: {
      type: Object as () => IOfferingCategory,
      required: true,
    },
  },

  data() {
    return {
      offer: {} as IOffering,
      originalOffer: {} as IOffering,
      offerCategoryTypes: [] as Array<IOfferingType>,
      originalOfferCategoryTypes: [] as Array<IOfferingType>,
      supporter: null as ISupporter | null,
      isEditing: false,
      isLoading: false,
      isNewOffer: false,
    };
  },

  computed: {
    isExistingOffer(): boolean {
      return !!this.offering.id;
    },

    onCreationMode(): boolean {
      return this.isNewOffer && this.isEditing;
    },

    hasOfferTypesChanged(): boolean | undefined {
      if (
        this.offerCategoryTypes?.length !==
        this.originalOfferCategoryTypes?.length
      ) {
        return true;
      }

      return !this.originalOfferCategoryTypes?.every((element) =>
        this.offerCategoryTypes?.some(
          (offer: IOfferingType) => offer.id === element.id,
        ),
      );
    },

    offerChanged(): boolean {
      return (
        !isEqual(this.offer, this.originalOffer) || !!this.hasOfferTypesChanged
      );
    },
  },

  watch: {
    offering: {
      immediate: true,
      deep: true,
      handler(newOffering: IOffering) {
        if (!newOffering) return;

        this.prefillOfferData(newOffering);
      },
    },

    offerChanged: {
      handler(changed: boolean) {
        this.$emit("offer-changed", changed);
      },
    },
  },

  beforeUnmount() {
    this.supporter = null;
    this.offer = {} as IOffering;
    this.originalOffer = {} as IOffering;
    this.offerCategoryTypes = [];
    this.originalOfferCategoryTypes = [];
  },

  beforeMount() {
    this.supporter = this.$store.get("profile/supporter/data");

    this.isEditing = !this.isExistingOffer;
    this.isNewOffer = !this.isExistingOffer;
  },

  methods: {
    prefillOfferData(newOffering: IOffering) {
      this.offer = cloneDeep(newOffering);
      this.originalOffer = cloneDeep(newOffering);
      this.offerCategoryTypes =
        cloneDeep(newOffering.types) ||
        cloneDeep(newOffering.category.category_types) ||
        [];
      this.originalOfferCategoryTypes =
        cloneDeep(newOffering.types) ||
        cloneDeep(newOffering.category.category_types) ||
        [];
    },

    async setActiveTypes(types: Array<IOfferingType>) {
      await this.$nextTick();
      const typesSelect = this.$refs.typesSelect as any;

      if (typesSelect) {
        typesSelect.setActiveOfferingTypes(cloneDeep(types));
      }
    },

    changedTypesHandler() {
      this.isEditing = true;
      this.$emit("offer-changed", true);
    },

    resetOffer() {
      this.offer = cloneDeep(this.originalOffer);
      this.offerCategoryTypes = cloneDeep(this.originalOfferCategoryTypes);

      // Set the active types
      if (this.offerCategoryTypes) {
        this.setActiveTypes(this.offerCategoryTypes);
      }
    },

    onClickCancel() {
      if (this.isNewOffer) {
        this.$emit("remove-offer");
      } else {
        this.resetOffer();
        this.isEditing = false;
      }
    },

    setOfferNewTypes() {
      if (!this.offer?.types && !this.offer?.category.category_types) return;

      if (this.offer?.types) {
        this.offer.types = this.offerCategoryTypes
          ? this.offerCategoryTypes
          : this.offer.types;
        return;
      }

      if (this.offer?.category.category_types) {
        this.offer.category.category_types = this.offerCategoryTypes
          ? this.offerCategoryTypes
          : this.offer.category.category_types;
      }
    },

    async onClickSave() {
      this.isLoading = true;

      this.$emit("submission-errors", false);

      // Since we are initial decopling the offer types from the offer data we need to update the offer data with the new selected types
      this.setOfferNewTypes();

      try {
        const offerCategoryTypes = this.offerCategoryTypes?.map(
          (type: IOfferingType) => type.id,
        );

        if (this.isNewOffer) {
          await offeringProvider.create({
            supporter: this.supporter?.id,
            description: this.offer?.description,
            category: this.offer?.category.id,
            types: offerCategoryTypes,
          });
        } else {
          await offeringProvider.patch(this.supporter?.id, {
            description: this.offer?.description,
            category: this.offer?.category.id,
            types: offerCategoryTypes,
          });
        }

        this.$emit("update:offering", this.offer);

        // Informs the parent that was an eddition. With this the `formChanges`
        // values must be reseted.
        this.$emit("offer-changed", "edit");
        this.isEditing = false;
        this.isNewOffer = false;
      } catch {
        this.$emit("submission-errors", true);
      } finally {
        this.isLoading = false;
      }
    },

    async onClickEdit() {
      this.isEditing = true;

      if (this.offerCategoryTypes) {
        await this.setActiveTypes(this.offerCategoryTypes);
      }
    },

    onClickRemove() {
      this.$emit("remove-offer");
      this.$emit("offer-changed", "remove");
    },
  },
});
</script>

<style lang="scss" scoped>
.offerings-form__item :deep() {
  border: solid 1px transparent;
  border-radius: 4px;

  & + .offerings-form__item {
    margin-top: 20px;
  }

  .offerings-edit-modal__select {
    width: 100%;
  }

  .offerings-form__item-column {
    flex: 0 1 auto;
    max-width: 280px;
  }

  .offerings-form__item-column:first-of-type {
    flex-basis: 120px;
  }

  .el-form-item__content {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
  }

  .el-button + .el-button {
    margin-left: 10px;
  }
}

.offerings-form__item-column label {
  @include grotesk(semiBold);

  font-size: 13px;
  text-transform: uppercase;
}

.offerings-form__item-column h3 {
  @include grotesk(semiBold);

  font-size: 16px;
  color: $ebony-clay;
}

.offerings-form__item-description {
  width: 280px;
  font-size: 14px;
  line-height: 22px;
  color: $ebony-clay;
}

.offerings-form__types :deep(.el-tag) {
  @include grotesk(bold);

  margin: 4px auto;
  font-size: 11px;
  text-transform: uppercase;
  background-image: linear-gradient(to bottom, $apricot, $orangish);
  border: solid 0.9px $black-20;

  @include breakpoint-up(md) {
    padding: 4px 8px;
    line-height: 13px;
  }

  &:not(:last-child) {
    margin-right: 10px;
  }
}

.offerings-form__item-footer {
  flex-basis: 100%;
  flex-grow: 1;
  margin-top: 25px;
  text-align: right;

  :deep(.el-button + .el-button) {
    margin-left: 14px;
  }
}

.offerings-form__item.is-editing :deep() {
  padding: 20px;
  border-color: $mischka;

  .offerings-form__item-column:not(:first-of-type) {
    flex-basis: 310px;
    max-width: 100%;
  }

  .offerings-form__item-column label {
    color: $ebony-clay;
  }

  .offerings-form__item-column:first-of-type label {
    color: $manatee;
  }
}

.offerings-form__save-btn {
  min-width: 62px;
}

.offerings-form__edit-btn {
  min-width: 51px;
}

.offerings-form__remove-btn {
  min-width: 74px;
}
</style>
