import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-7369f7b3"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "self-assessment-starting-page" }
const _hoisted_2 = { class: "self-assessment-starting-page-panel__title" }
const _hoisted_3 = { class: "self-assessment-starting-page-panel__content" }
const _hoisted_4 = { class: "self-assessment-starting-page-panel__content" }
const _hoisted_5 = { class: "self-assessment-starting-page__feature" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PxButton = _resolveComponent("PxButton")!
  const _component_PxPanel = _resolveComponent("PxPanel")!
  const _component_FeatureCard = _resolveComponent("FeatureCard")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_PxPanel, { class: "self-assessment-starting-page-panel" }, {
      default: _withCtx(() => [
        _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.startingPageTitle), 1),
        _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.startingPageContent), 1),
        _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.startingPageContentTwo), 1),
        _createVNode(_component_PxButton, {
          icon: _ctx.startButtonIcon,
          type: _ctx.startButtonType,
          class: "self-assessment-starting-page-panel__button",
          size: "large",
          onClick: _ctx.onSelfAssessmentButtonClick
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.startButtonText), 1)
          ]),
          _: 1
        }, 8, ["icon", "type", "onClick"])
      ]),
      _: 1
    }),
    _createElementVNode("div", _hoisted_5, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.startingPageKeyPoints, (item) => {
        return (_openBlock(), _createBlock(_component_FeatureCard, {
          key: item.value,
          description: item.description,
          icon: item.value,
          title: item.title
        }, null, 8, ["description", "icon", "title"]))
      }), 128))
    ])
  ]))
}