<template>
  <div
    :class="{ 'matching-sidebar-closed': !isSidebarOpen }"
    class="matching-sidebar"
  >
    <div class="matching-sidebar__headline" @click="toggleSidebar">
      <PxIcon :name="menuIcon" :size="14" />
      <h3>
        {{ title }}
      </h3>
      <PxIcon
        :name="arrowIcon"
        :size="14"
        class="matching-sidebar__arrow-icon"
      />
    </div>
    <div v-if="$user.isSupporter()" class="matching-sidebar__update-btn">
      <i18n-t keypath="matching.matchingSidebar.editCriteria.text" tag="p">
        <template #linkText>
          <a
            :href="url"
            data-testid="matching-sidebar-edit-criteria-button"
            target="_blank"
          >
            {{ $t("matching.matchingSidebar.editCriteria.linkText") }}
          </a>
        </template>
      </i18n-t>
    </div>
    <PxQuestionary
      :criteria="currentCriteria"
      :criteria-weights="criteriaWeights"
      :final-message="questionary.final.message"
      :final-title="questionary.final.title"
      :loading="loadingQuestion"
      :question="currentQuestion"
      @skip="skipQuestion"
      @submit="submitResponse"
      @submit-criteria="submitCriteriaSelection"
    />
    <PxNotice
      v-if="showNotice && !loadingQuestion"
      :title="$t('matching.matchingSidebar.notice.title')"
      class="matching-sidebar__notice"
    >
      <template #cta>
        <PxButton size="small" type="default" @click="onClickOpenFeedbackPage">
          {{ $t("matching.matchingSidebar.notice.button") }}
        </PxButton>
      </template>
    </PxNotice>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import {
  EMatchingQuestionaryActions,
  IMatchingQuestionaryFetchPayload,
} from "@/modules/matching/services/store/matching-questionary/matching-questionary.types";
import { IMatchingQuestion } from "@/services/data/matching-questionary/matching-question.interface";

import {
  IMatchingCriteria,
  IMatchingCriteriaWeight,
} from "@/modules/matching/services/data/matching-criteria/matching-criteria.interface";
import { matchingCriteriaProvider } from "@/modules/matching/services/data/matching-criteria/matching-criteria.provider";
import { matchingCriteriaWeightProvider } from "@/modules/matching/services/data/matching-criteria/matching-criteria-weight.provider";

import { AFFILIATE_SUPPORTER_PROGRAM_UPDATE_URL } from "@/modules/affiliates/constants";

export default defineComponent({
  name: "MatchingSidebar",

  data() {
    return {
      title: this.$t("matching.matchingSidebar.questionary.title"),
      menuIcon: "matching--white",
      questionary: {
        final: {
          title: this.$t("matching.matchingSidebar.final.title"),
          message: this.$t("matching.matchingSidebar.final.message"),
        },
      },
      criteria: [] as Array<IMatchingCriteria>,
      criteriaWeights: [] as Array<IMatchingCriteriaWeight>,
      isSidebarOpen: false,

      currentPage: 1,
      resultsPerPage: 5,
      url: AFFILIATE_SUPPORTER_PROGRAM_UPDATE_URL,
    };
  },

  computed: {
    showNotice(): boolean {
      return this.currentQuestion === null;
    },
    currentQuestion(): IMatchingQuestion {
      return this.$store.get("matchingQuestionary/question");
    },
    currentQuestionIndex(): number {
      return this.$store.get("matchingQuestionary/questionIndex");
    },
    totalQuestions(): number {
      return this.$store.get("matchingQuestionary/totalQuestions");
    },
    loadingQuestion(): boolean {
      // Showing loading at first page, the remaining are loaded in the background
      return (
        this.$store.get("matchingQuestionary/loading") && this.currentPage === 0
      );
    },
    userId(): number | null {
      return this.$store.state.auth.profileId;
    },
    userEmail(): string | undefined {
      return this.$store.state.auth.user?.email;
    },
    showCriteria(): boolean {
      return this.$user.isSupporter();
    },
    currentCriteria(): IMatchingCriteria | null {
      return (
        this.criteria.find(
          (entry: IMatchingCriteria) =>
            this.currentQuestion &&
            entry.question_id === this.currentQuestion.id,
        ) || null
      );
    },
    arrowIcon(): string {
      return this.isSidebarOpen ? "arrow--down-grey" : "arrow--up-grey";
    },
  },

  watch: {
    currentQuestionIndex(index: number) {
      if (
        index + this.resultsPerPage >=
        this.currentPage * this.resultsPerPage
      ) {
        // Preload before running out of questions
        this.currentPage += 1;
        this.fetchQuestions();
      }
    },
  },

  created() {
    this.$emitter.on("open-questions-sidebar", this.toggleSidebar);
    this.fetchQuestions();

    if (this.showCriteria) {
      this.fetchCriteriaWeight();
      this.fetchCriteria();
    }
  },

  methods: {
    fetchQuestions() {
      this.$store.dispatch(EMatchingQuestionaryActions.FETCH, {
        page: this.currentPage,
        per_page: this.resultsPerPage,
        exclude: ["free-response", "date"],
        only: "criteria",
      } as IMatchingQuestionaryFetchPayload);
    },

    async fetchCriteriaWeight() {
      this.criteriaWeights = await matchingCriteriaWeightProvider.list();
    },

    async fetchCriteria() {
      this.criteria = await matchingCriteriaProvider.list();
    },

    skipQuestion() {
      this.$store.dispatch(EMatchingQuestionaryActions.SKIP_QUESTION);
    },

    submitResponse(response: Array<number>) {
      let dataToSend = {
        user_profile: this.userId,
        question: this.currentQuestion.id,
      } as any;

      dataToSend =
        this.currentQuestion.question_type.type === "range" ||
        this.currentQuestion.question_type.type === "numeric"
          ? { ...dataToSend, value: response }
          : { ...dataToSend, answers: response };

      this.$store.dispatch(
        EMatchingQuestionaryActions.SUBMIT_RESPONSE,
        dataToSend,
      );
    },

    async submitCriteriaSelection(data: {
      criteria: number;
      values: Array<any> | any;
    }) {
      const valuesKey = ["range", "numeric"].includes(
        this.currentQuestion.question_type.type,
      )
        ? "desired"
        : "answers";
      const dataToSend = {
        criteria_weight: data.criteria,
        [valuesKey]: data.values,
        question: this.currentQuestion.id,
      };

      await this.$store.dispatch(
        EMatchingQuestionaryActions.SUBMIT_CRITERIA,
        dataToSend,
      );
    },

    onClickOpenFeedbackPage() {
      window.open(`https://info302232.typeform.com/to/IoMvns`);
    },

    toggleSidebar() {
      this.isSidebarOpen = !this.isSidebarOpen;
    },
  },
});
</script>

<style lang="scss" scoped>
.matching-sidebar {
  max-width: 263px;
  max-height: 100vh;
  overflow: hidden;
  background-color: $ebony-clay;
  border-radius: 4px;
  box-shadow: 0 2px 11px 0 rgba(0, 0, 0, 0.11);

  transition: max-height 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  transition-delay: 0.2s;

  :deep() .px-questionary__content {
    max-height: calc(100vh - 180px);
    overflow-y: auto;
  }
}

.matching-sidebar-closed {
  max-height: 43px;
}

.matching-sidebar__headline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;

  height: 43px;
  padding: 12px 20px 10px;
  cursor: pointer;
  user-select: none;
  border-bottom: solid 1px rgba($white, 0.2);

  h3 {
    @include grotesk(medium);

    margin-bottom: 0;
    font-size: 14px;
    line-height: 17px;
    color: $white;
    text-transform: uppercase;

    &:not(:first-child) {
      margin-left: 9px;
    }
  }
}

.matching-sidebar__update-btn {
  max-height: 34px;
  padding: 7px 0 11px 19px;
  background-color: #1c1c2f;
  border-radius: 4px;
  box-shadow: inset 0 -1px 0 0 rgba(114, 173, 246, 0.3);

  p {
    font-size: 13px;
    line-height: 17px;
    color: #72adf6;
    letter-spacing: -0.2px;
    transform: translateZ(0);

    :deep() a {
      font-weight: bold;
      color: #72adf6;
      text-decoration: underline;

      &:hover {
        color: #2972ca;
      }
    }
  }
}

.matching-sidebar__arrow-icon {
  margin-left: 14px;
}

.matching-sidebar__notice {
  max-width: 239px;
  margin: 0 20px;
  margin-bottom: 20px;
}
</style>
