import { resolveComponent as _resolveComponent, withModifiers as _withModifiers, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, mergeProps as _mergeProps, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-41ed3f74"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "matching-card-selector__wrapper"
}
const _hoisted_2 = ["textContent"]
const _hoisted_3 = { class: "matching-card-thumbnail__wrapper" }
const _hoisted_4 = { class: "matching-badge-list__wrapper" }
const _hoisted_5 = { class: "matching-card__headline" }
const _hoisted_6 = { class: "matching-card__title" }
const _hoisted_7 = { class: "matching-card__subtitle" }
const _hoisted_8 = {
  key: 0,
  class: "matching-card__description"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ElCheckbox = _resolveComponent("ElCheckbox")!
  const _component_ElTooltip = _resolveComponent("ElTooltip")!
  const _component_MatchingCardBackground = _resolveComponent("MatchingCardBackground")!
  const _component_MatchingCardPercentagePill = _resolveComponent("MatchingCardPercentagePill")!
  const _component_MatchDetailsModal = _resolveComponent("MatchDetailsModal")!
  const _component_SubmissionsModal = _resolveComponent("SubmissionsModal")!
  const _component_MatchingCardThumbnail = _resolveComponent("MatchingCardThumbnail")!
  const _component_MatchingBadgeList = _resolveComponent("MatchingBadgeList")!
  const _component_MatchingCardTagList = _resolveComponent("MatchingCardTagList")!
  const _component_PxMatchingInterest = _resolveComponent("PxMatchingInterest")!
  const _component_PxButton = _resolveComponent("PxButton")!
  const _component_MatchingCardWrapper = _resolveComponent("MatchingCardWrapper")!

  return (_openBlock(), _createBlock(_component_MatchingCardWrapper, {
    "is-disabled": _ctx.disabled,
    "is-faded": _ctx.isFaded,
    "is-selected": _ctx.isSelected,
    onClick: _ctx.onCardClick
  }, {
    action: _withCtx(() => [
      (_ctx.showListButton)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            (!_ctx.disabled)
              ? (_openBlock(), _createBlock(_component_ElTooltip, {
                  key: 0,
                  content: _ctx.checkboxTooltipCopy,
                  disabled: _ctx.isInnerSelected,
                  placement: "top",
                  "popper-class": "px-matching-interest-compact__tooltip el-abaca-tooltip"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ElCheckbox, {
                      modelValue: _ctx.isInnerSelected,
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.isInnerSelected) = $event)),
                      class: "matching-card-selector",
                      onClick: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["stop"]))
                    }, null, 8, ["modelValue"])
                  ]),
                  _: 1
                }, 8, ["content", "disabled"]))
              : (_openBlock(), _createElementBlock("div", {
                  key: 1,
                  class: "matching-card-selector--label",
                  textContent: _toDisplayString(_ctx.labelText)
                }, null, 8, _hoisted_2))
          ]))
        : _createCommentVNode("", true)
    ]),
    header: _withCtx(() => [
      _createVNode(_component_MatchingCardBackground, { "company-name": _ctx.name }, null, 8, ["company-name"]),
      _createVNode(_component_MatchingCardPercentagePill, {
        percentage: _ctx.matchPercentage,
        "show-tooltip": !_ctx.isInterestDetailModalVisible && _ctx.$screen.mdUp,
        onClick: _withModifiers(_ctx.onClickShowModal, ["stop"])
      }, null, 8, ["percentage", "show-tooltip", "onClick"]),
      (_ctx.showInterestDetailModalVisible && _ctx.$screen.mdUp)
        ? (_openBlock(), _createBlock(_component_MatchDetailsModal, {
            key: 0,
            visibility: _ctx.isInterestDetailModalVisible,
            "onUpdate:visibility": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.isInterestDetailModalVisible) = $event)),
            "company-logo": _ctx.thumbnail,
            "company-name": _ctx.name,
            "match-details": _ctx.matchScoreDetails
          }, null, 8, ["visibility", "company-logo", "company-name", "match-details"]))
        : _createCommentVNode("", true),
      (_ctx.submissionsModalVisible)
        ? (_openBlock(), _createBlock(_component_SubmissionsModal, {
            key: 1,
            visibility: _ctx.submissionsModalVisible,
            "onUpdate:visibility": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.submissionsModalVisible) = $event)),
            company: _ctx.name,
            data: _ctx.affiliates
          }, null, 8, ["visibility", "company", "data"]))
        : _createCommentVNode("", true)
    ]),
    body: _withCtx(() => [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_MatchingCardThumbnail, {
          level: _ctx.matchLevel,
          name: _ctx.name,
          thumbnail: _ctx.thumbnail
        }, null, 8, ["level", "name", "thumbnail"])
      ]),
      _createElementVNode("div", _hoisted_4, [
        (!_ctx.showPublicView)
          ? (_openBlock(), _createBlock(_component_MatchingBadgeList, {
              key: 0,
              badges: _ctx.badges,
              "company-name": _ctx.name,
              onClick: _ctx.openSubmissionsModal
            }, null, 8, ["badges", "company-name", "onClick"]))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("h3", _hoisted_6, _toDisplayString(_ctx.name), 1),
        _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.location), 1),
        (!!_ctx.about)
          ? (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(_ctx.about), 1))
          : _createCommentVNode("", true),
        _createVNode(_component_MatchingCardTagList, { items: _ctx.mappedSectors }, null, 8, ["items"])
      ])
    ]),
    footer: _withCtx(() => [
      (_ctx.enabledMatchingInterest)
        ? (_openBlock(), _createBlock(_component_PxMatchingInterest, _mergeProps({ key: 0 }, _ctx.$options.static.interestButton, {
            id: _ctx.companyId,
            "is-directory-member": _ctx.isDirectoryMember,
            name: _ctx.name,
            uid: _ctx.companyUid,
            "with-description": false,
            class: "matching-card__interest",
            onClick: _cache[4] || (_cache[4] = _withModifiers(() => {}, ["stop"]))
          }), null, 16, ["id", "is-directory-member", "name", "uid"]))
        : _createCommentVNode("", true),
      (_ctx.showListButton)
        ? (_openBlock(), _createBlock(_component_PxButton, _mergeProps({ key: 1 }, _ctx.addToListButtonProps, {
            onClick: _withModifiers(_ctx.onShowListManagementModal, ["stop"])
          }), null, 16, ["onClick"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["is-disabled", "is-faded", "is-selected", "onClick"]))
}