<template>
  <div class="px-switch">
    <div class="px-switch__toggle">
      <ElSwitch
        ref="switch"
        v-model="isActive"
        :width="width"
        @change="switchChangeHandler"
      />
    </div>
    <div class="px-switch__label" @click="labelClickHandler">
      <span v-if="label" v-text="label" />
      <slot name="label" />
    </div>
    <slot />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "PxSwitch",

  props: {
    value: {
      type: Boolean,
      required: true,
    },

    label: {
      type: String,
      default: "",
    },

    width: {
      type: Number,
      default: 34,
    },
  },

  data() {
    return {
      isActive: false,
    };
  },

  watch: {
    value(newValue: boolean) {
      if (this.isActive !== newValue) {
        this.isActive = newValue;
      }
    },
    isActive(newValue: boolean) {
      if (this.value !== newValue) {
        this.$emit("update:value", newValue);
      }
    },
  },

  created() {
    this.isActive = this.value;
  },

  methods: {
    labelClickHandler() {
      (this as any).$refs.switch.$el.click();
    },

    switchChangeHandler() {
      this.$emit("change", this.isActive);
      this.$emit("update:value", this.isActive);
    },
  },
});
</script>

<style lang="scss" scoped>
.px-switch {
  display: flex;
  flex-flow: row wrap;
  align-items: center;

  :deep(.el-switch) {
    height: 20px;
  }
}

.px-switch__label :deep() {
  margin-left: 10px;
  cursor: pointer;
  user-select: none;

  span {
    @include grotesk(regular);

    font-size: 14px;
    color: $ebony-clay;
    letter-spacing: -0.35px;
  }
}
</style>
