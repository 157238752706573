import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-6e0701f6"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "px-notice__icon"
}
const _hoisted_2 = { class: "px-notice__wrapper" }
const _hoisted_3 = { class: "px-notice__title" }
const _hoisted_4 = { class: "px-notice__cta" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PxIcon = _resolveComponent("PxIcon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(`px-notice px-notice--${_ctx.type} px-notice--${_ctx.direction}`)
  }, [
    (_ctx.icon)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_PxIcon, {
            name: _ctx.icon,
            size: 15
          }, null, 8, ["name"])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h3", _hoisted_3, _toDisplayString(_ctx.title), 1),
      _createElementVNode("div", _hoisted_4, [
        _renderSlot(_ctx.$slots, "cta", {}, undefined, true)
      ])
    ])
  ], 2))
}