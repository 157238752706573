import { RouteLocationNormalized, RouteRecordRaw } from "vue-router";

import CapitalExplorerView from "@/modules/capital-explorer/views/capital-explorer.view.vue";
import ComparisonTableView from "@/modules/capital-explorer/views/comparison-table.view.vue";
import AuthenticatedWithNavigationView from "@/modules/common/views/authenticated-with-navigation.view.vue";
import CapitalExplorerForVisitorsView from "@/modules/capital-explorer/views/capital-explorer-for-visitors.view.vue";
import ComparisonTableVisitorsView from "@/modules/capital-explorer/views/comparison-table-visitors.view.vue";

import {
  CAPITAL_EXPLORER,
  CAPITAL_EXPLORER_COMPARISON_TABLE,
  CAPITAL_EXPLORER_VISITORS_VIEW,
} from "@/modules/capital-explorer/services/router/routes-names";
import { ERROR_TYPES } from "@/modules/common/components/error-page/constants";
import { defaultScreenService } from "@/modules/common/services/screen/screen.service";
import { featureManager } from "@/services/feature-manager";
import { ERROR_ROUTE } from "@/services/router/router-names";
import { capitalExplorerVisitorGuard } from "@/modules/capital-explorer/services/router/capital-explorer-visitor.guard";

export const routes: RouteRecordRaw[] = [
  {
    path: "/capital-explorer",
    component: AuthenticatedWithNavigationView,
    beforeEnter: (
      to: RouteLocationNormalized,
      from: RouteLocationNormalized,
      next: Function,
    ) => {
      if (!featureManager.isEnabled("capitalExplorer")) {
        next({
          name: ERROR_ROUTE,
          query: {
            code: ERROR_TYPES.NOT_FOUND,
          },
        });
      } else if (defaultScreenService.isDown("sm")) {
        next({
          name: ERROR_ROUTE,
          query: {
            code: ERROR_TYPES.MOBILE,
          },
        });
      } else {
        next();
      }
    },
    meta: {
      auth: true,
    },
    children: [
      {
        path: "",
        name: CAPITAL_EXPLORER,
        component: CapitalExplorerView,
      },
      {
        path: "comparison-table",
        name: CAPITAL_EXPLORER_COMPARISON_TABLE,
        component: ComparisonTableView,
      },
    ],
  },
  {
    path: "/capital-explorer/:uid",
    component: CapitalExplorerForVisitorsView,
    beforeEnter: capitalExplorerVisitorGuard,
    children: [
      {
        path: "",
        name: CAPITAL_EXPLORER_VISITORS_VIEW,
        component: ComparisonTableVisitorsView,
      },
    ],
  },
];
