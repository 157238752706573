import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-48c68900"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "assessment-changes"
}
const _hoisted_2 = {
  key: 0,
  class: "assessment-changes__line-wrapper"
}
const _hoisted_3 = { class: "assessment-changes__line" }
const _hoisted_4 = {
  key: 1,
  class: "assessment-changes__line-wrapper"
}
const _hoisted_5 = { class: "assessment-changes__line" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (!_ctx.isDataMissing)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (!_ctx.onlyPrevAssessment)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.changes, (change, index) => {
                  return (_openBlock(), _createElementBlock("p", {
                    key: index,
                    class: _normalizeClass({
              'assessment-changes__line--up': change.raised,
              'assessment-changes__line--down': !change.raised,
            })
                  }, _toDisplayString(change.category.name) + " " + _toDisplayString(change.level), 3))
                }), 128))
              ])
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.prevAssessmentData, (change, index) => {
                  return (_openBlock(), _createElementBlock("p", { key: index }, _toDisplayString(change.category.name) + " " + _toDisplayString(change.level), 1))
                }), 128))
              ])
            ]))
      ]))
    : _createCommentVNode("", true)
}