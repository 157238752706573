import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-bbbc0274"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "matching-start page-body" }
const _hoisted_2 = { class: "matching-start-panel__title" }
const _hoisted_3 = { class: "matching-start-panel__teaser" }
const _hoisted_4 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PxButton = _resolveComponent("PxButton")!
  const _component_PxPanel = _resolveComponent("PxPanel")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_PxPanel, { class: "matching-start-panel" }, {
      default: _withCtx(() => [
        _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.$t("matching.matchingStart.title")), 1),
        _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t(`matching.matchingStart.teaser[${_ctx.$profile.type()}]`)), 1),
        _createElementVNode("p", {
          class: "matching-start-panel__content",
          innerHTML: _ctx.$t(`matching.matchingStart.content[${_ctx.$profile.type()}]`)
        }, null, 8, _hoisted_4),
        _createVNode(_component_PxButton, {
          class: "matching-start-panel__button",
          size: "large",
          type: "green",
          onClick: _ctx.onClickGoToMatchingList
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t("matching.matchingStart.button")), 1)
          ]),
          _: 1
        }, 8, ["onClick"])
      ]),
      _: 1
    })
  ]))
}