import { GenericProvider, Provider } from "@/services/data/generic-provider";
import { baseAPIUrl } from "@/services/utils/utils";

import { IListReportsDemographicData } from "@/modules/charts-dashboard/services/data/lists-reports-data/lists-reports-data.interface";

@Provider(`${baseAPIUrl}/demographic-composition`)
class ListsReportsDataProvider extends GenericProvider<IListReportsDemographicData> {
  public async fetch(params: {
    list_uid: string;
    question: number | string;
  }): Promise<IListReportsDemographicData> {
    const url = super.buildEndPointUrl(params);
    const request = this.httpClient.get(url);
    const { data } = await this.wrapRequest(request);
    return data;
  }
}

export const listsReportsDataProvider = new ListsReportsDataProvider();
