import { IBaseState } from "@/services/store/store-types";
import { IMilestone } from "@/modules/milestone-planner/services/data/milestones/milestone.interface";
import { IGridCategory } from "@/modules/milestone-planner/components/milestones-grid/milestones-grid.interface";

/**
 * Interface that represents the store structure for the
 * milestones module for the authenticated user.
 */
export interface IAuthMilestonesState extends IBaseState {
  data: IMilestone[];
  parsedData: IGridCategory[];
}

export enum EAuthMilestonesActions {
  FETCH = "auth/milestones/fetch",
  PARSE_DATA = "auth/milestones/parseData",
  FETCH_PARSED_DATA = "auth/milestones/fetchParsedData",
  CREATE = "auth/milestones/create",
  PATCH = "auth/milestones/patch",
  DESTROY = "auth/milestones/destroy",
}

export enum EAuthMilestonesMutations {
  SET_LOADING = "SET_LOADING",
  SET_ERROR = "SET_ERROR",
  SET_DATA = "SET_DATA",
  SET_PARSED_DATA = "SET_PARSED_DATA",
}
