<template>
  <div class="share-planner-settings-link">
    <ElForm ref="form" class="share-planner-settings-link__form">
      <ElFormItem
        :label="shareModalCopy.label"
        class="share-planner-settings-link__input-wrapper"
        prop="email"
      >
        <ElInput :disabled="true" :value="value" data-testid="list-link" />
        <ClipboardButton v-bind="clipboardButton" />
      </ElFormItem>
      <div class="share-planner-settings-link__footer">
        <span
          class="el-form-item__bottom-text el-form-item__tip"
          v-html="shareModalCopy.tip"
        />
        <PxButton
          :icon="$options.static.resetButton.icon"
          :label="shareModalCopy.resetButton"
          :size="$options.static.resetButton.size"
          :type="$options.static.resetButton.type"
          class="share-planner-settings-link__cta"
          @click="clickResetLinkHandler"
        />
      </div>
    </ElForm>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import ClipboardButton from "@/components/clipboard-button/clipboard-button.vue";
import {
  EPxButtonSize,
  EPxButtonType,
} from "@/components/px-button/px-button.types";
import { SHARE_PLANNER_EVENTS } from "@/modules/milestone-planner/components/share-planner/share-planner-modal.types";

export default defineComponent({
  name: "SharePlannerSettingsLink",

  components: {
    ClipboardButton,
  },

  props: {
    value: {
      type: String,
      required: true,
    },
  },

  static: {
    resetButton: {
      icon: "link--red",
      size: EPxButtonSize.SMALL,
      type: EPxButtonType.GHOST_RED,
    },
  },

  computed: {
    shareModalCopy() {
      return this.$tm("companyLists.list.shareModal.settings.link") as {
        label: string;
        tip: string;
        resetButton: string;
      };
    },

    clipboardButton() {
      return {
        clipboard: this.value,
        buttonLabel: this.$t("common.copyLink"),
        tooltipCopy: this.$t("common.copiedToClipboard"),
      };
    },
  },

  methods: {
    clickResetLinkHandler() {
      this.$emitter.emit("share-planner-modal-confirmation", {
        event: SHARE_PLANNER_EVENTS.RESET_SHAREABLE_LINK,
      });
    },
  },
});
</script>

<style lang="scss" scoped>
.share-planner-settings-link {
  &__input-wrapper {
    position: relative;

    :deep(.el-form-item__label) {
      padding-top: 1px;
      margin-bottom: 3px;
    }

    :deep(.el-input.is-disabled .el-input__inner) {
      // Add safe space for CTA overlapping input:
      padding-right: 130px;
      background-color: $alabaster;
      border: solid 1px $mischka;
      box-shadow: inset 0 0 5px 0 rgba(#000, 0.03);
      opacity: 1;
    }
  }

  :deep(.clipboard-button) {
    position: absolute;
    right: 3px;
    bottom: 4px;
    padding: 0 12px;

    span {
      font-size: 12px;
    }
  }

  &__cta {
    padding: 0 12px;
  }

  &__footer {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    margin-top: 16px;
    margin-right: 1px;

    .el-form-item__tip {
      flex: 1 1 50%;
      max-width: 330px;
      padding-top: 0;
      line-height: 14px;
      letter-spacing: -0.12px;
    }
  }
}
</style>
