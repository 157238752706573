import {
  GenericProvider,
  EProviderFeatures,
} from "@/services/data/generic-provider";
import { ISector } from "@/services/data/sector/sector.interface";

class SectorProvider extends GenericProvider<ISector> {
  constructor() {
    super(`/sectors/`, [EProviderFeatures.LIST]);
  }
}

export const sectorProvider = new SectorProvider();
