export enum EPxButtonSize {
  XXX_SMALL = "xxx-small",
  XX_SMALL = "xx-small",
  EXTRA_SMALL = "extra-small",
  SMALL = "small",
  MEDIUM = "medium",
  LARGE = "large",
  EXTRA_LARGE = "extra-large",
}

export enum EPxButtonVariant {
  NORMAL = "normal",
  COMPACT = "compact",
  FLUID = "fluid",
}

export enum EPxButtonType {
  DEFAULT = "default",
  DEFAULT_BLUE = "default-blue",
  GREEN = "green",
  RED = "red",
  BLUE = "blue",
  BLUE_LIGHT = "blue-light",
  BLUE_FADED = "blue-faded",
  BLUE_GREY = "blue-grey",
  DARK_BLUE = "dark-blue",
  PRIMARY = "primary",
  LINK = "link",
  LINK_DARK = "link-dark",
  LINK_WHITE = "link-white",
  LINK_DANGER = "link-danger",
  ICON = "icon",
  GHOST = "ghost",
  GHOST_BLUE = "ghost-blue",
  GHOST_GREEN = "ghost-green",
  GHOST_GREY = "ghost-grey",
  GHOST_YELLOW = "ghost-yellow",
  GHOST_RED = "ghost-red",
  YELLOW = "yellow",
}
