import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "milestone-plan-header" }
const _hoisted_2 = { class: "milestone-plan-header__header" }
const _hoisted_3 = { class: "milestone-plan-header__wrapper" }
const _hoisted_4 = { class: "level-number" }
const _hoisted_5 = { class: "category-name" }
const _hoisted_6 = { class: "category-level-description" }
const _hoisted_7 = { class: "milestone-plan-header__content" }
const _hoisted_8 = {
  key: 0,
  class: "space-bullet"
}
const _hoisted_9 = { class: "date" }
const _hoisted_10 = {
  key: 0,
  class: "milestone-plan-header__buttons"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MilestonePlanState = _resolveComponent("MilestonePlanState")!
  const _component_CompleteMilestoneInfoIndicator = _resolveComponent("CompleteMilestoneInfoIndicator")!
  const _component_CriticalMilestoneIndicator = _resolveComponent("CriticalMilestoneIndicator")!
  const _component_SharePlanner = _resolveComponent("SharePlanner")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        style: _normalizeStyle({ backgroundColor: _ctx.categoryColor }),
        class: "category-indicator"
      }, null, 4),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.levelNumber), 1),
        _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.categoryName), 1),
        _createElementVNode("span", _hoisted_6, " - " + _toDisplayString(_ctx.categoryDescription), 1)
      ])
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createVNode(_component_MilestonePlanState, {
        color: _ctx.categoryColor,
        completed: _ctx.isMilestoneComplete,
        future: _ctx.isFutureMilestone,
        "in-progress": _ctx.isMilestoneInProgress,
        planned: _ctx.isMilestonePlanned
      }, null, 8, ["color", "completed", "future", "in-progress", "planned"]),
      (_ctx.isMilestoneCritical || _ctx.isMilestoneComplete || _ctx.targetDate)
        ? (_openBlock(), _createElementBlock("div", _hoisted_8))
        : _createCommentVNode("", true),
      (_ctx.isMilestoneComplete)
        ? (_openBlock(), _createBlock(_component_CompleteMilestoneInfoIndicator, {
            key: 1,
            "has-evidence": _ctx.hasEvidence
          }, null, 8, ["has-evidence"]))
        : (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
            (_ctx.isMilestoneCritical)
              ? (_openBlock(), _createBlock(_component_CriticalMilestoneIndicator, {
                  key: 0,
                  "is-visitor": _ctx.isVisitor
                }, null, 8, ["is-visitor"]))
              : _createCommentVNode("", true),
            (_ctx.targetDate)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 1,
                  class: _normalizeClass([{ 'target-date--critical': _ctx.isMilestoneCritical }, "target-date"])
                }, [
                  _createTextVNode(_toDisplayString(_ctx.$t("milestonePlanner.milestone.targetDate")) + ": ", 1),
                  _createElementVNode("strong", _hoisted_9, _toDisplayString(_ctx.formattedTargetDate), 1)
                ], 2))
              : _createCommentVNode("", true)
          ], 64))
    ]),
    (!_ctx.isVisitor && _ctx.milestonePlanner)
      ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
          _createVNode(_component_SharePlanner, {
            ref: "sharePlannerButton",
            "milestone-planner": _ctx.milestonePlanner
          }, null, 8, ["milestone-planner"])
        ]))
      : _createCommentVNode("", true)
  ]))
}