import {
  GenericProvider,
  Provider,
  EProviderFeatures,
} from "@/services/data/generic-provider";
import { IMatchingCriteria } from "./matching-criteria.interface";
import { baseAPIUrl } from "@/services/utils/utils";

@Provider(`${baseAPIUrl}/matching/criteria`, [
  EProviderFeatures.LIST,
  EProviderFeatures.CREATE,
])
class MatchingCriteriaProvider extends GenericProvider<IMatchingCriteria> {}

export const matchingCriteriaProvider = new MatchingCriteriaProvider();
