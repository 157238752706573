<template>
  <div class="cookie-policy-page">
    <PxTabBar v-if="$screen.smDown" />
    <PxNavbar v-else />
    <article>
      <header>
        <h1>Cookie Policy</h1>
        <p class="meta">
          Last updated on
          <time pubdate="pubdate">September 17, 2018</time>
          .
        </p>
      </header>
      <p>
        A cookie is a small piece of data (text file) that a website – when
        visited by a user – asks your browser to store on your device in order
        to remember information about you, such as your language preference or
        login information. Those cookies are set by us and called first party
        cookies. We also use third party cookies – which are cookies from a
        domain different than the domain of the website you are visiting – for
        our advertising and marketing efforts.
      </p>
      <p>
        More specifically, we use cookies and other tracking technologies for
        the following purposes:
      </p>
      <ul>
        <li>Assisting you in navigation;</li>
        <li>
          Assisting in registration to our events, login, and your ability to
          provide feedback;
        </li>
        <li>Analyzing your use of our products, services or applications;</li>
        <li>Analyzing your use of our products, services or applications;</li>
        <li>
          Assisting with our promotional and marketing efforts. (including
          behavioral advertising)
        </li>
      </ul>
      <p>
        Below is a detailed list of the cookies we use on our Website. Our
        Website is scanned with our cookie scanning tool regularly to maintain a
        list as accurate as possible. We classify cookies in the following
        categories:
      </p>
      <ul>
        <li>Strictly Necessary Cookies</li>
        <li>Performance Cookies</li>
        <li>Targeting Cookies</li>
      </ul>
      <p>
        You can opt-out of each cookie category (except strictly necessary
        cookies) by clicking on the “cookie settings” button below:
      </p>
      <a class="optanon-show-settings">Cookie Settings</a>
      <div id="optanon-cookie-policy" />
    </article>
    <PxFooter :items="footerItems" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import PxFooter from "@/components/px-footer/px-footer.vue";
import PxTabBar from "@/components/px-tab-bar/px-tab-bar.vue";

export default defineComponent({
  name: "CookiePolicy",

  components: {
    PxTabBar,
    PxFooter,
  },

  data() {
    return {
      footerItems: [],
    };
  },
});
</script>

<style lang="scss" scoped>
.cookie-policy-page article {
  @include container;

  /* Important used to avoid specify the max-width per breakpoint */
  max-width: 810px !important;
  padding: 0 0 30px;
  margin-top: 63px;

  h1 {
    @include grotesk(semiBold);

    margin-bottom: 10px;

    font-size: 2.2667rem;
    line-height: 1;
    letter-spacing: 0.7px;
  }

  p.meta {
    margin-top: 0;

    font-size: 1.0667rem;
    line-height: 1.5;
    color: $manatee;
    letter-spacing: 0.2px;
  }

  p {
    margin-top: 40px;

    font-size: 1.0667rem;
    line-height: 1.88;
    color: $ebony-clay;
    letter-spacing: -0.24px;
  }

  ul {
    padding: 0;
    margin-top: 30px;
    list-style: none;
  }

  li {
    position: relative;
    padding-left: 23px;
    margin-bottom: 10px;

    font-size: 1.0667rem;
    line-height: 1.88;
  }

  li:last-child {
    margin-bottom: 0;
  }

  li::before {
    position: absolute;
    top: 12px;
    left: 1px;
    display: inline-block;
    width: 5px;
    height: 5px;

    content: "";

    background-color: $malibu;
    border-radius: 50%;
  }
}
</style>
