/**
 * Gives the environment here this APP is running.
 */
export const getEnv = (): string => process.env.NODE_ENV as string;

/**
 * Application environment.
 *
 * This environment can be different from the `getEnv`, the
 * `getEnv` function refers to the NODE environment and this
 * one refers to here the APP is running on.
 */
export const getAppEnv = (): string => process.env.VUE_APP_ENV as string;

/**
 * Gives the partner where this APP is running for.
 */
export const getAppPartner = (): string =>
  process.env.VUE_APP_PARTNER as string;

/**
 * Retrieve the application version.
 */
// APP_VERSION is a global variable injected by the build process (webpack)
export const getAppVersion = (): string => APP_VERSION as string;

/**
 * Check if the application is running on a development environment.
 */
export const isDevelopment = getEnv() === "development";

/**
 * Check if the application is running on a production environment.
 */
export const isProduction = getAppEnv() === "production";

/**
 * Check if the application is running for a given partner.
 */
export const isPartner =
  Object.prototype.hasOwnProperty.call(process.env, "VUE_APP_PARTNER") &&
  process.env.VUE_APP_PARTNER !== "";

/**
 * Gives the API url.
 */
export const baseAPIUrl = process.env.VUE_APP_API_URL as string;

/**
 * Gives the APP url.
 */
export const baseUrl = process.env.VUE_APP_URL as string;

/**
 * Returns the list of active modules.
 */
export const activeModules = (): Array<string> => {
  const listOfModules = process.env.VUE_APP_ACTIVE_MODULES as string;
  return listOfModules.split(",");
};
