<template>
  <div class="profile-panel__row assessment-history">
    <div class="profile-headline">
      <h2 class="profile-headline__title">
        {{ $t("profile.assessment.historyPanel.title") }}
      </h2>
      <PxButton
        v-if="allowLevelUpdate"
        :disabled="!$features.isEnabled('updateLevel')"
        class="profile-headline__updateBtn"
        size="small"
        @click="onClickUpdateLevel"
      >
        {{ $t("profile.assessment.update") }}
      </PxButton>
    </div>
    <div
      :class="{ 'assessment-timeline--initial': hasOneAssessment }"
      class="assessment-timeline"
    >
      <PxExpand :toggle="false">
        <template #visible>
          <div>
            <div
              v-for="(entry, key) in visibleResults"
              :key="key"
              :class="{ 'assessment-timeline__entry--current': key === 0 }"
              class="assessment-timeline__entry"
            >
              <div class="assessment-timeline__header">
                {{ assessmentTitle(entry) }}
                <PxButton
                  v-if="$screen.mdUp"
                  class="el-button--link el-button--link-dark el-button--small"
                  type="link"
                  @click="onClickOpenAssessmentDetails(key)"
                >
                  {{ $t("profile.assessment.historyPanel.detailsLink") }}
                </PxButton>
                <PxButton
                  v-else
                  class="el-button--arrow"
                  @click="onClickOpenAssessmentDetails(key)"
                >
                  <i class="icon icon-arrow--right-grey" />
                </PxButton>
              </div>
              <div
                v-if="isAssessmentFluctuationVisible(key, entry)"
                class="assessment-timeline__summary"
              >
                <AssessmentFluctuation
                  :current-assessment="currentAssessmentData(entry)"
                  :prev-assessment="previousAssessmentData(key)"
                  align="left"
                />
              </div>
            </div>
            <div
              v-if="hasOneAssessment && !userIsVisitor"
              class="assessment-timeline__tip"
            >
              {{ $t("profile.assessment.historyPanel.tip") }}
            </div>
            <div
              v-if="hasOneAssessment && userIsVisitor"
              class="assessment-timeline__tip"
            >
              {{
                $t("profile.assessment.historyPanel.tipVisitor", {
                  name: company.name,
                })
              }}
            </div>
            <div v-if="noAssessment" class="assessment-timeline__tip">
              {{ $t("profile.assessment.historyPanel.tipNoAssesments") }}
            </div>
          </div>
        </template>
        <template #hidden>
          <div>
            <div
              v-for="(entry, key) in hiddenResults"
              :key="key"
              class="assessment-timeline__entry"
            >
              <div class="assessment-timeline__header">
                {{ assessmentTitle(entry) }}
                <PxButton
                  v-if="$screen.mdUp"
                  class="el-button--link el-button--link-dark el-button--small"
                  type="link"
                  @click="onClickOpenAssessmentDetails(key + 4)"
                >
                  {{ $t("profile.assessment.historyPanel.detailsLink") }}
                </PxButton>
                <PxButton
                  v-else
                  class="el-button--arrow"
                  @click="onClickOpenAssessmentDetails(key + 4)"
                >
                  <i class="icon icon-arrow--right-grey" />
                </PxButton>
              </div>
              <div
                v-if="isAssessmentFluctuationVisible(key, entry)"
                class="assessment-timeline__summary"
              >
                <AssessmentFluctuation
                  :current-assessment="currentAssessmentData(entry)"
                  :prev-assessment="previousAssessmentData(key)"
                  align="left"
                />
              </div>
            </div>
          </div>
        </template>
        <template #trigger>
          <div
            v-if="hiddenResults.length"
            class="assessment-timeline__expand-btn"
          >
            {{ $t("profile.assessment.historyPanel.expand") }} ({{
              assessments.length
            }})
            <i class="icon icon-caret--green" />
          </div>
        </template>
      </PxExpand>
    </div>
    <AssessmentDetails
      v-model:selected-assessment="currentAssessment"
      v-model:visibility="isAssessmentDetailsVisible"
      :assessment-list="assessments"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import moment from "moment";

import AssessmentFluctuation from "@/modules/profile/components/assessment-fluctuation/assessment-fluctuation.vue";
import AssessmentDetails from "@/modules/profile/components/assessment-details/assessment-details.vue";

import {
  IAssessment,
  IAssessmentLevel,
} from "@/services/data/assessment/assessment.interface";
import { EAssessmentHistoryActions } from "@/modules/profile/services/store/profile/submodules/assessment-history/assessment-history.types";
import { ICompany } from "@/modules/profile/services/data/company/company.types";
import { PROFILE_META_OPEN_ASSESSMENT_MODAL } from "@/modules/profile/constants";
import { EMetaActions } from "@/services/store/meta/meta-types";

export default defineComponent({
  name: "AssessmentPanelHistory",

  components: {
    AssessmentFluctuation,
    AssessmentDetails,
  },

  props: {
    allowLevelUpdate: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      currentAssessment: 0,
      isAssessmentDetailsVisible: false,
    };
  },

  computed: {
    company(): ICompany | null {
      return this.$store.get("profile/company.data");
    },

    assessments(): Array<IAssessment> {
      return this.$store.get("profile/assessmentHistory.data");
    },

    visibleResults(): Array<IAssessment> {
      return this.assessments.slice(0, 4);
    },

    hiddenResults(): Array<IAssessment> {
      return this.assessments.slice(4, this.assessments.length);
    },

    hasOneAssessment(): boolean {
      return this.visibleResults.length === 1;
    },

    noAssessment(): boolean {
      return this.visibleResults.length === 0;
    },

    isSpecialGuest(): boolean {
      return !!this.$route.meta?.specialGuest;
    },

    /**
     * Return if the current user has permissions to see the assessment results.
     */
    userIsVisitor(): boolean {
      return (
        (!this.$user.isLogged() && !this.isSpecialGuest) ||
        !this.$user.isOwner()
      );
    },
  },

  watch: {
    /**
     * Every time that the company changes reload the
     * list of assessments.
     */
    company: {
      immediate: true,
      handler(newVal: ICompany | null) {
        if (newVal === null) {
          return;
        }

        this.fetchData();
      },
    },
  },

  mounted() {
    this.isAssessmentDetailsVisible = this.$store.get(
      "meta/get",
      PROFILE_META_OPEN_ASSESSMENT_MODAL,
    );

    this.$store.dispatch(
      EMetaActions.REMOVE,
      PROFILE_META_OPEN_ASSESSMENT_MODAL,
    );
  },

  methods: {
    formatedDate(date: string) {
      return moment(date).format("MMMM D, YYYY - HH:mmA");
    },

    assessmentTitle(assessment: IAssessment) {
      const formatedDate = this.formatedDate(assessment.created_at);
      const levelText = this.$t("profile.assessment.historyPanel.level");
      return `${levelText} ${assessment.level.value} - ${formatedDate}`;
    },

    previousAssessmentData(key: number): Array<IAssessmentLevel> {
      const prevKey = key + 1;
      const prevAssessment = this.assessments[prevKey];
      return prevAssessment ? prevAssessment.data : [];
    },

    currentAssessmentData(assessment: IAssessment): Array<IAssessmentLevel> {
      return assessment.data || [];
    },

    /**
     * Fetch the assessment history data.
     */
    fetchData() {
      if (this.company === null) {
        return;
      }

      this.$store.dispatch(EAssessmentHistoryActions.FETCH, this.company.id);
    },

    onClickUpdateLevel() {
      this.$emit("request-new-assessment");
    },

    onClickOpenAssessmentDetails(assessmentIndex: number) {
      this.currentAssessment = assessmentIndex;
      this.isAssessmentDetailsVisible = true;
    },

    isAssessmentFluctuationVisible(
      key: number,
      assessment: IAssessment,
    ): boolean {
      return (
        !!this.previousAssessmentData(key).length &&
        !!this.currentAssessmentData(assessment).length
      );
    },
  },
});
</script>

<style lang="scss" scoped>
.assessment-history {
  margin-right: 2px;
  margin-left: 4px;
}

.assessment-timeline {
  position: relative;
  padding-left: 29px;
  margin-top: 11px;

  @include breakpoint-up(lg) {
    padding-left: 26px;
    margin-top: 30px;
  }

  &::before {
    position: absolute;
    top: -4px;
    left: -7px;
    width: 28px;
    height: 28px;
    content: "";
    background-color: rgba(#368cf4, 0.05);
    border-radius: 100%;

    @include breakpoint-down(lg) {
      display: none;
    }
  }

  &::after {
    position: absolute;
    top: 10px;
    left: -3px;
    z-index: z("default") - 1;
    width: 20px;
    height: 20px;
    content: "";
    background-color: #e6f1fd;
    border-radius: 100%;

    @include breakpoint-up(lg) {
      top: 0;
      z-index: z("default");
    }
  }
}

.assessment-timeline__entry {
  position: relative;
  z-index: z("default") + 1;
  padding-bottom: 21px;

  @include breakpoint-up(md) {
    padding-bottom: 0;
  }

  &::before {
    position: absolute;
    top: 15px;
    left: -27px;
    width: 11px;
    height: 11px;
    content: "";
    background-color: #76baff;
    border: solid 2px white;
    border-radius: 100%;

    @include breakpoint-up(lg) {
      top: 3px;
      left: -24px;
    }
  }

  &::after {
    position: absolute;
    bottom: 0;
    left: 0;
    width: calc(100vw - 50px);
    height: 1px;
    content: "";
    background-color: rgba($manatee, 0.1);

    @include breakpoint-up(md) {
      display: none;
    }
  }

  &:not(:first-child) {
    margin-top: 22px;

    @include breakpoint-up(lg) {
      margin-top: 26px;
    }
  }
}

.assessment-timeline__header {
  @include grotesk(medium);

  font-size: 15px;
  line-height: 22px;
  color: $manatee;

  @include breakpoint-up(lg) {
    font-size: 16px;
    line-height: 19px;
  }
}

.assessment-timeline__header .el-button.el-button--link {
  position: relative;
  top: -2px;
  height: 19px;
  padding: 0;
  margin-left: 4px;
}

.assessment-timeline__expand-btn {
  @include grotesk(semiBold);

  margin-top: 25px;
  color: $lightish-green;
  cursor: pointer;
  user-select: none;

  .icon {
    display: inline-block;
    width: 12px;
    height: 12px;
    margin-left: 3px;
    vertical-align: -1px;
  }
}

.assessment-timeline__header .el-button.el-button--arrow {
  position: absolute;
  top: -6px;
  right: -9px;
  width: 30px;
  min-width: auto;
  height: calc(100% - 7px);
  padding: 0;
  appearance: none;
  background: transparent;
  border: none;
  outline: none;

  &::before,
  &::after {
    display: none;
  }

  .icon {
    width: 20px;
    height: 20px;
  }
}

.assessment-timeline__summary {
  position: relative;
  left: -4px;
  padding-right: 30px;
  margin-top: 10px;

  @include breakpoint-up(lg) {
    left: auto;
    padding-right: 0;
    padding-left: 5px;
    margin-top: 6px;
  }
}

.assessment-timeline__tip {
  @include grotesk(regular, italic);

  padding: 15px 0;
  font-size: 15px;
  line-height: 22px;
  color: $manatee;

  @include breakpoint-up(md) {
    padding: 26px 0;
  }
}

.assessment-timeline__entry--current {
  position: relative;

  &::before {
    position: absolute;
    top: 13px;
    left: -29px;
    width: 14px;
    height: 14px;
    content: "";
    background-color: transparent;
    background-image: linear-gradient(to bottom, #76baff, $denim);
    border: none;
    border-radius: 100%;
    box-shadow: 0 1px 4px 0 rgba(83, 149, 228, 0.7);

    @include breakpoint-up(lg) {
      top: 3px;
      left: -26px;
    }
  }

  .assessment-timeline__header {
    @include grotesk(bold);

    color: $ebony-clay;

    .el-button.el-button--arrow {
      top: 7px;
    }
  }
}

.assessment-timeline :deep() {
  .px-expand__container {
    position: relative;

    &::after {
      position: absolute;
      top: 25px;
      left: -23px;
      z-index: z("default");
      width: 2px;
      height: calc(100% - 25px);
      content: "";
      background-color: $denim;

      @include breakpoint-up(lg) {
        top: 5px;
        left: -20px;
        z-index: z("default") - 1;
        height: calc(100% - 5px);
      }
    }
  }

  .px-expand__wrapper {
    &:not(:first-child) {
      margin-top: 26px;
    }

    &:last-child {
      position: relative;

      &::before {
        position: absolute;
        bottom: 0;
        left: -25px;
        z-index: z("default") + 1;
        width: 6px;
        height: 27px;
        content: "";
        background-image: linear-gradient(
          to bottom,
          rgba(248, 248, 248, 0),
          rgba(255, 255, 255, 0.84) 46%,
          white
        );

        @include breakpoint-up(lg) {
          left: -22px;
          z-index: z("default");
        }
      }
    }
  }
}

.assessment-timeline--initial :deep() {
  .assessment-timeline__entry {
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    min-height: 44px;
    padding-right: 20px;
    padding-bottom: 0;

    @include breakpoint-up(lg) {
      justify-content: flex-start;
    }

    &::after {
      display: none;
    }
  }

  .assessment-timeline__header .el-button.el-button--arrow {
    top: 3px;
  }
}
</style>
