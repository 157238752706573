/**
 * Generic structure for a data model state interface.
 */
import { Module } from "vuex";
import { RootState } from "@/services/store/root-state";
import { GenericProvider } from "@/services/data/generic-provider";

export interface IGenericState<T> {
  /**
   * Stores single value for data model.
   */
  value: T | null;

  /**
   * Stores list values for data model.
   */
  values: Array<T>;

  /**
   * Informs there is current request happening.
   *
   * By default is must be set to `true`.
   */
  loading: boolean;

  /**
   * Informs if an error occurred during API request.
   */
  error: Error | null;
}

/**
 * Required values from class constructor.
 */
export interface IGenericStateConstructor<S, T> {
  provider: GenericProvider<T>;
  props?: Module<S, RootState>;
}

/**
 * Mutation typings
 */
export interface IGenericMutation {
  SET_ERROR: string;
  SET_LOADING: string;
  SET_VALUE: string;
  SET_VALUES: string;
}

export enum EGenericMutation {
  SET_ERROR = "setError",
  SET_LOADING = "setLoading",
  SET_VALUE = "setValue",
  SET_VALUES = "setValues",
}

/**
 * Action typings
 */
export interface IGenericAction {
  GET_VALUE: string;
  GET_VALUES: string;
  CREATE_VALUE: string;
  PATCH_VALUE: string;
  REMOVE_VALUE: string;
}

export enum EGenericAction {
  GET_VALUE = "getValue",
  GET_VALUES = "getValues",
  CREATE_VALUE = "createValue",
  PATCH_VALUE = "patchValue",
  REMOVE_VALUE = "removeValue",
}

/**
 * Getter typings
 */
export interface IGenericGetter {
  VALUE: string;
  VALUES: string;
  IS_LOADING: string;
  ERROR: string;
  HAS_ERROR: string;
}

export enum EGenericGetter {
  VALUE = "getValue",
  VALUES = "getValues",
  IS_LOADING = "isLoading",
  ERROR = "getError",
  HAS_ERROR = "hasError",
}
