<template>
  <div class="results-board__cta-panel">
    <div v-if="showSubmitMessage" class="results-board__headline">
      <h2 class="results-board__headline-title">
        <PxIcon name="check--white" size="21" />
        {{ $t(`selfAssessment.results.resultsBoardCTA.pendingUser.headline`) }}
      </h2>
      <i18n-t
        class="results-board__headline-subtitle"
        keypath="selfAssessment.results.resultsBoardCTA.pendingUser.notice"
        tag="h4"
      >
        <template #company>
          <span>
            {{ affiliateName }}
          </span>
        </template>
      </i18n-t>
    </div>
    <h2
      class="results-board__title"
      v-html="$t(`selfAssessment.results.resultsBoardCTA.pendingUser.title`)"
    />
    <ul class="results-board__list">
      <li
        v-for="(item, index) in $tm(
          `selfAssessment.results.resultsBoardCTA.list`,
        )"
        :key="index"
      >
        {{ item }}
      </li>
    </ul>
    <div class="results-board__footer">
      <PxButton
        class="results-board__join-btn"
        size="large"
        type="green"
        @click="onClickHandler"
      >
        {{ $t("selfAssessment.results.resultsBoardCTA.pendingUser.button") }}
      </PxButton>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import { IAffiliate } from "@/services/data/affiliate/affiliate.interface";
import { IAffiliatesState } from "@/modules/affiliates/services/store/affiliates/affiliates.types";

export default defineComponent({
  name: "ResultsBoardPendingUser",

  computed: {
    isPendingUser(): boolean {
      return (
        this.$features.isEnabled("assessmentUserInfoBeforeHand") &&
        this.$user.isPendingUser()
      );
    },

    showSubmitMessage(): boolean {
      return !this.$screen.smDown;
    },

    affiliates(): IAffiliatesState {
      return this.$store.state.affiliates;
    },

    affiliate(): IAffiliate | null {
      return this.affiliates.affiliate;
    },

    affiliateName(): string {
      return this.affiliate ? this.affiliate.name : "";
    },
  },

  methods: {
    onClickHandler() {
      this.$emitter.emit("open-signup-modal");
    },
  },
});
</script>

<style lang="scss" scoped>
.results-board .results-board__cta-panel {
  position: relative;
  flex-grow: 1;
  flex-shrink: 1;
  padding: 25px 20px 16px;
  margin: 0 15px;
  overflow: hidden;

  text-align: center;
  background-image: linear-gradient(
    to bottom,
    rgba(123, 183, 255, 0.05),
    rgba(123, 183, 255, 0.19)
  );
  border: solid 1px rgba(123, 183, 255, 0.2);
  border-radius: 2px;

  @include breakpoint-up(md) {
    max-height: calc(100% - 30px);
    padding: 134px 20px 15px;
    margin: 0 30px;
  }

  @include breakpoint-up(lg) {
    top: 15px;
    left: 0;
    display: flex;
    flex-flow: row wrap;
    align-content: center;
    justify-content: center;
    padding-top: 114px;
    margin: 0;
    border-left: none;
    border-radius: 0 2px 2px 0;
  }

  .results-board__headline {
    @include grotesk(semiBold);

    position: absolute;
    top: 19px;
    right: 0;
    left: 0;
    padding: 0 20px;
    margin: auto;

    &::after {
      position: absolute;
      right: -999px;
      bottom: -18px;
      left: -999px;
      width: 100vw;
      height: 1px;
      margin: auto;

      content: "";
      background-color: rgba($malibu, 0.2);
    }

    .px-icon {
      margin-right: 5px;
      vertical-align: -3px;
      background-color: $fern;
      border-radius: 100%;
    }
  }

  .results-board__headline-title {
    font-size: 22px;
    line-height: 32px;
    color: $fern;
  }

  .results-board__headline-subtitle {
    @include grotesk(semiBold);

    margin-top: 10px;
    font-size: 16px;
    line-height: 19px;
    color: $ebony-clay;

    span {
      @extend .results-board__headline-subtitle;

      text-decoration: underline;
    }
  }

  .results-board__list {
    max-width: 290px;
    padding: 0;
    margin: 30px auto 20px;

    @include breakpoint-up(md) {
      display: inline-flex;
      flex-flow: column wrap;
      max-width: none;
    }

    @include breakpoint-up(lg) {
      max-width: 386px;
      margin: 21px auto 25px;
    }
  }

  .results-board__join-btn {
    height: 42px;

    @include breakpoint-up(md) {
      height: 43px;
      margin-top: 13px;
    }

    :deep() span {
      font-size: to-rem(14px);
      line-height: to-rem(17px);

      @include breakpoint-up(md) {
        font-size: to-rem(16px);
        line-height: to-rem(21px);
      }
    }
  }

  .results-board__login-text {
    display: block;
    margin-top: 15px;
    font-size: to-rem(14px);
    line-height: to-rem(22px);

    @include breakpoint-up(md) {
      margin-top: 10px;
    }

    &::after {
      content: ".";
    }
  }
}

.results-board .results-board__cta-panel .results-board__title :deep() {
  font-size: 22px;

  @include breakpoint-up(lg) {
    padding: 0 25px;
  }

  mark {
    position: relative;
    margin-left: 6px;

    color: currentColor;
    background-color: transparent;

    &::before {
      position: absolute;
      top: 1px;
      left: -5px;
      z-index: z("bellow");
      width: 100%;
      height: 100%;

      content: "";
      background-image: url("#{$assetsPath}/img/text-marker.svg");
    }
  }
}
</style>
