<template>
  <div class="category-levels-assessment-mobile-placeholder">
    <ContentPlaceholders>
      <ContentPlaceholdersImg
        v-for="i in 5"
        :key="i"
        class="category-levels-assessment-mobile-placeholder__card"
      />
    </ContentPlaceholders>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "CategoryLevelsAssessmentMobilePlaceholder",
});
</script>

<style lang="scss" scoped>
.category-levels-assessment-mobile-placeholder__card {
  margin-bottom: 8px;

  border-radius: 3px;
}
</style>
