<template>
  <div class="panel-grid__block">
    <AffiliateLinkEditModal
      ref="affiliateLinkEditModal"
      v-model:visibility="isAffiliateLinkEditModalVisible"
    />
    <div v-if="$screen.smDown" class="profile-headline">
      <div class="profile-headline__text-wrapper">
        <h2 class="profile-headline__title">
          {{ $t("profile.affiliateLink.title") }}
        </h2>
      </div>
    </div>
    <PxPanel class="profile-panel">
      <template v-if="isLoading">
        <ContentPlaceholders :rounded="true">
          <ContentPlaceholdersText :lines="2" />
        </ContentPlaceholders>
      </template>
      <div v-else class="profile-headline">
        <div v-if="!$screen.smDown" class="profile-headline__text-wrapper">
          <h2 class="profile-headline__title">
            {{ $t("profile.affiliateLink.title") }}
          </h2>
          <ElTooltip
            placement="top"
            popper-class="affiliate-panel__tooltip el-abaca-tooltip"
          >
            <template #content>
              <p>
                {{ $t("profile.affiliateLink.hint") }}
              </p>
            </template>
            <i class="icon icon-question" />
          </ElTooltip>
        </div>
        <PxButton
          v-if="!hasAffiliateLink"
          class="affiliate-panel__button"
          type="primary"
          @click="onClickGenerate"
        >
          <PxIcon
            :size="18"
            class="affiliate-panel__button-icon"
            name="link--white"
          />
          {{ $t("profile.affiliateLink.newLink") }}
        </PxButton>
        <ElTooltip
          v-else
          :show-arrow="false"
          :auto-close="2000"
          trigger="click"
          placement="bottom"
          popper-class="affiliate-panel__copied-tooltip el-abaca-tooltip"
        >
          <PxButton
            class="affiliate-panel__button"
            type="ghost-blue"
            @click="onClickCopyLink"
          >
            <PxIcon
              :size="18"
              class="affiliate-panel__button-icon"
              name="link"
            />
            {{ $t("profile.affiliateLink.copyLink") }}
          </PxButton>
          <template #content>
            <p>
              {{ $t("profile.affiliateLink.copyClipboard") }}
            </p>
          </template>
        </ElTooltip>
        <span v-if="$screen.mdDown" class="affiliate-panel__hint">
          {{ $t("profile.affiliateLink.hint") }}
        </span>
      </div>
    </PxPanel>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import AffiliateLinkEditModal from "@/modules/profile/components/affiliate-link-edit-modal/affiliate-link-edit-modal.vue";
import { copyToClipboard } from "@/utils/clipboard";
import { ISupporter } from "@/modules/matching/services/data/matching-score/supporter.interface";
import { IAffiliate } from "@/services/data/affiliate/affiliate.interface";
import { PROFILE_AFFILIATE_LINK_QUERY } from "@/modules/profile/constants";

export default defineComponent({
  name: "AffiliateLinkPanel",

  components: {
    AffiliateLinkEditModal,
  },

  data() {
    return {
      isAffiliateLinkEditModalVisible: false,
    };
  },

  computed: {
    // TODO: understand why Supporter type is being created only on matching module
    supporter(): ISupporter | null {
      return this.$store.get("profile/supporter/data");
    },

    affiliate(): IAffiliate | null {
      return this.supporter?.affiliate || null;
    },

    hasAffiliateLink(): boolean {
      return !!this.affiliate;
    },

    affiliateLink(): string {
      if (!this.affiliate) {
        return "";
      }

      const linkSlug = this.affiliate.slug;
      return `${location.origin}/${PROFILE_AFFILIATE_LINK_QUERY}${linkSlug}`;
    },

    isLoading(): boolean {
      return (
        this.$store.get("profile/supporter/loading") ||
        this.$store.get("profile/company/loading")
      );
    },
  },

  methods: {
    onClickGenerate() {
      this.isAffiliateLinkEditModalVisible = true;
    },

    onClickCopyLink() {
      copyToClipboard(this.affiliateLink);
    },
  },
});
</script>

<style lang="scss" scoped>
.profile-panel :deep(.px-panel__body) {
  padding: 0;

  @include breakpoint-up(md) {
    padding: 25px;
  }

  .vue-content-placeholders-text {
    display: flex;
    justify-content: space-between;

    .vue-content-placeholders-text__line {
      width: 30%;
    }

    .vue-content-placeholders-text__line:first-of-type {
      width: 60%;
    }
  }
}

.affiliate-panel__button {
  @include breakpoint-down(sm) {
    width: 100%;
  }
}

.affiliate-panel__button-icon {
  margin-right: 2px;
}

.profile-headline__text-wrapper {
  display: flex;
  align-items: baseline;

  .icon {
    position: relative;
    top: 2px;
    left: 4px;
    display: inline-block;

    width: 18px;
    height: 17px;
    margin-left: 4px;
  }
}

.affiliate-panel__hint {
  padding-top: 10px;
  padding-left: 6px;
  font-size: 13px;
  font-style: italic;
  color: $manatee;
}
</style>

<style lang="scss">
.affiliate-panel__tooltip {
  width: 314px;
  text-transform: none;
  text-wrap: normal;
}

.affiliate-panel__copied-tooltip.el-abaca-tooltip {
  height: 31px;
  padding: 3px 6px;
  margin: 0;
}
</style>
