import { GenericState } from "@/services/store/generic/generic-state.module";
import { affiliateProvider } from "@/services/data/affiliate/affiliate.provider";
import { IAffiliate } from "@/services/data/affiliate/affiliate.interface";
import { EAffiliateState, IAffiliateState } from "./affiliate.interface";
import { actions } from "@/modules/supporters/services/store/affiliate/affiliate.actions";
import { getters } from "@/modules/supporters/services/store/affiliate/affiliate.getters";
import { getGenericStateGetters } from "@/services/store/generic/generic-state.getters";
import {
  EGenericAction,
  EGenericGetter,
  IGenericAction,
  IGenericGetter,
} from "@/services/store/generic/generic-state.interface";

/**
 * Boilerplate module instantiation
 */
export class AffiliateState extends GenericState<IAffiliateState, IAffiliate> {
  static Action = GenericState.mapNamespaceEnum(
    EGenericAction,
    EAffiliateState.NAMESPACE,
  ) as IGenericAction;

  static Getter = GenericState.mapNamespaceEnum(
    EGenericGetter,
    EAffiliateState.NAMESPACE,
  ) as IGenericGetter;
}

/**
 * Boilerplate module instantiation
 */
export const affiliateModule = new AffiliateState({
  provider: affiliateProvider,
  props: {
    actions,
    getters: { ...getGenericStateGetters<IAffiliate>(), ...getters },
  },
});
