import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, mergeProps as _mergeProps, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-00a0db2e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "milestone-form-footer-complete" }
const _hoisted_2 = { class: "milestone-form-footer-complete__left" }
const _hoisted_3 = {
  key: 1,
  class: "milestone-form-footer-complete__evidence-tip"
}
const _hoisted_4 = { class: "milestone-form-footer-complete__right" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MilestoneFormFooterDeleteButton = _resolveComponent("MilestoneFormFooterDeleteButton")!
  const _component_PxIcon = _resolveComponent("PxIcon")!
  const _component_PxButton = _resolveComponent("PxButton")!
  const _component_MilestoneFormFooterSaveAsDraftButton = _resolveComponent("MilestoneFormFooterSaveAsDraftButton")!
  const _component_MilestoneFormFooterCompleteButton = _resolveComponent("MilestoneFormFooterCompleteButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.hasCompletionInfo)
        ? (_openBlock(), _createBlock(_component_MilestoneFormFooterDeleteButton, {
            key: 0,
            "button-disabled": _ctx.aboveMilestonesCompleted,
            "button-label": _ctx.deleteButtonLabel,
            "show-tooltip": _ctx.aboveMilestonesCompleted,
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emitter.emit('delete-milestone-evidence')))
          }, null, 8, ["button-disabled", "button-label", "show-tooltip"]))
        : _createCommentVNode("", true),
      (!_ctx.userAddsEvidence && !_ctx.hasCompletionInfo)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createVNode(_component_PxIcon, {
              size: 16,
              class: "milestone-form-footer-complete__evidence-tip-icon",
              name: "warning--orange"
            }),
            _createTextVNode(" " + _toDisplayString(_ctx.$t("milestonePlanner.milestonePlan.planView.evidenceTip")), 1)
          ]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_PxButton, _mergeProps(_ctx.discardButtonProps, {
        disabled: !_ctx.formHasChanges,
        class: "milestone-form-footer-complete__discard-button",
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emitter.emit('discard-milestone-evidence')))
      }), null, 16, ["disabled"]),
      _createVNode(_component_MilestoneFormFooterSaveAsDraftButton, {
        "button-disabled": _ctx.saveAsDraftDisabled,
        loading: _ctx.isLoadingMilestones,
        "show-tooltip": _ctx.aboveMilestonesCompleted,
        onClick: _cache[2] || (_cache[2] = ($event: any) => (
          _ctx.$emitter.emit('submit-milestone-evidence', { toPublish: false })
        ))
      }, null, 8, ["button-disabled", "loading", "show-tooltip"]),
      _createVNode(_component_MilestoneFormFooterCompleteButton, {
        "button-disabled": _ctx.completeDisabled,
        "button-label": _ctx.completeButtonLabel,
        loading: _ctx.isLoadingMilestones,
        "show-tooltip": _ctx.belowMilestonesNotCompleted,
        onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emitter.emit('submit-milestone-evidence', { toPublish: true })))
      }, null, 8, ["button-disabled", "button-label", "loading", "show-tooltip"])
    ])
  ]))
}