import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-18bbaab8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "px-navbar-wizard__menu" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = { class: "px-navbar-wizard__menu-item-number" }
const _hoisted_4 = { class: "px-navbar-wizard__menu-item-label" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PxNavbar = _resolveComponent("PxNavbar")!

  return (_openBlock(), _createBlock(_component_PxNavbar, {
    loading: _ctx.loading,
    logo: _ctx.logo,
    "show-headline": !_ctx.logo,
    class: "px-navbar-wizard__container",
    theme: "wizard"
  }, {
    "navbar-right": _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.steps, (step, index) => {
          return (_openBlock(), _createElementBlock("a", {
            key: `flow-navbar__tab:${index}`,
            class: _normalizeClass([{
            'px-navbar-wizard__menu-item--disabled': _ctx.isStepDisabled(step),
            'px-navbar-wizard__menu-item--active': _ctx.isStepActive(step),
            'px-navbar-wizard__menu-item--done': _ctx.isStepCompleted(step),
          }, "px-navbar-wizard__menu-item"]),
            onClick: ($event: any) => (_ctx.onStepClick(step))
          }, [
            _createElementVNode("span", _hoisted_3, _toDisplayString(index + 1), 1),
            _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.$t(step.labelKey)), 1)
          ], 10, _hoisted_2))
        }), 128))
      ])
    ]),
    _: 1
  }, 8, ["loading", "logo", "show-headline"]))
}