import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_GuestAccessModal = _resolveComponent("GuestAccessModal")!
  const _component_PxButton = _resolveComponent("PxButton")!
  const _component_i18n_t = _resolveComponent("i18n-t")!
  const _component_PxModalMessage = _resolveComponent("PxModalMessage")!
  const _component_LoginModal = _resolveComponent("LoginModal")!

  return (_openBlock(), _createElementBlock("div", null, [
    (!_ctx.loginModalVisible)
      ? (_openBlock(), _createBlock(_component_GuestAccessModal, {
          key: 0,
          loading: _ctx.isDataLoading,
          title: _ctx.modalTitle,
          visibility: !_ctx.loginModalVisible,
          onGoToLogin: _ctx.onGoToLoginButtonClick,
          onMakeRequest: _ctx.makeRequestWithGuest
        }, null, 8, ["loading", "title", "visibility", "onGoToLogin", "onMakeRequest"]))
      : (_openBlock(), _createBlock(_component_LoginModal, {
          key: 1,
          "is-data-loading": _ctx.isDataLoading,
          visibility: _ctx.loginModalVisible,
          class: "login-modal",
          onAfterLogin: _ctx.makeRequestWithLogin
        }, {
          "before-form": _withCtx(() => [
            _createElementVNode("div", null, [
              _createVNode(_component_PxModalMessage, {
                center: false,
                "show-icon": false,
                visible: true,
                class: "login-modal__modal-message",
                type: "info"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_i18n_t, {
                    class: "login-modal__fields-message",
                    keypath: "milestonePlanner.accessModal.nameEmailMessage",
                    tag: "span"
                  }, {
                    link: _withCtx(() => [
                      _createVNode(_component_PxButton, {
                        class: "el-button--link el-button--small login-modal__fields-link",
                        type: "link",
                        onClick: _ctx.enterAsGuest
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t("companyLists.accessModal.nameEmailMessageButton")), 1)
                        ]),
                        _: 1
                      }, 8, ["onClick"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ])
          ]),
          _: 1
        }, 8, ["is-data-loading", "visibility", "onAfterLogin"]))
  ]))
}