<template>
  <div class="matching-card-background">
    <picture>
      <img :alt="companyName" :src="backgroundSource" />
    </picture>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "MatchingCardBackground",

  props: {
    companyName: {
      type: String,
      default: "",
    },

    source: {
      type: String,
      default: null,
    },
  },

  static: {
    defaultBackgroundSource: "/img/shapes/matching-card/abaca-cover-cards.svg",
  },

  computed: {
    backgroundSource(): string {
      return this.source || this.$options.static.defaultBackgroundSource;
    },
  },
});
</script>

<style lang="scss" scoped>
.matching-card-background {
  @include gradient(light-4, to bottom);

  position: relative;
  min-width: 100%;
  height: 67px;
  overflow: hidden;
  border-bottom: 1px solid $turkish-angora;
  border-radius: 4px 4px 0 0;
}
</style>
