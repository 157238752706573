import { resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, vShow as _vShow, withCtx as _withCtx, createVNode as _createVNode, Transition as _Transition, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-1f3dd114"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "profile-desktop page-full-height" }
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = { class: "page-body" }
const _hoisted_4 = { class: "page-wrapper" }
const _hoisted_5 = { class: "page-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SubmissionsNotificationMessage = _resolveComponent("SubmissionsNotificationMessage")!
  const _component_PxCallout = _resolveComponent("PxCallout")!
  const _component_CompanyPanel = _resolveComponent("CompanyPanel")!
  const _component_InvestorsPanel = _resolveComponent("InvestorsPanel")!
  const _component_SupporterInfoPanel = _resolveComponent("SupporterInfoPanel")!
  const _component_PxCard = _resolveComponent("PxCard")!
  const _component_MatchingRibbon = _resolveComponent("MatchingRibbon")!
  const _component_AssessmentPanel = _resolveComponent("AssessmentPanel")!
  const _component_InvestmentLevelPanel = _resolveComponent("InvestmentLevelPanel")!
  const _component_AffiliateLinkPanel = _resolveComponent("AffiliateLinkPanel")!
  const _component_NetworksPanel = _resolveComponent("NetworksPanel")!
  const _component_SupporterCriteriaPanel = _resolveComponent("SupporterCriteriaPanel")!
  const _component_PanelGrid = _resolveComponent("PanelGrid")!
  const _directive_feature = _resolveDirective("feature")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.showSubmissionsNotification)
      ? _withDirectives((_openBlock(), _createBlock(_component_SubmissionsNotificationMessage, {
          key: 0,
          visibility: _ctx.showSubmissionsBar,
          "onUpdate:visibility": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.showSubmissionsBar) = $event)),
          "draft-submissions": _ctx.draftSubmissions
        }, null, 8, ["visibility", "draft-submissions"])), [
          [_directive_feature, 'userSubmissions']
        ])
      : _createCommentVNode("", true),
    _createVNode(_Transition, {
      mode: "out-in",
      name: "slide-fade"
    }, {
      default: _withCtx(() => [
        _withDirectives(_createVNode(_component_PxCallout, {
          ctas: _ctx.capitalExplorerCalloutCtas,
          description: _ctx.capitalExplorerCalloutCopy.description,
          label: _ctx.capitalExplorerCalloutLabel,
          title: _ctx.capitalExplorerCalloutCopy.title,
          class: "capital-explorer-callout",
          onClick: _ctx.handleCalloutClick
        }, {
          note: _withCtx(() => [
            _createElementVNode("p", {
              innerHTML: _ctx.capitalExplorerCalloutCopy.note
            }, null, 8, _hoisted_2)
          ]),
          _: 1
        }, 8, ["ctas", "description", "label", "title", "onClick"]), [
          [_vShow, _ctx.showCallout]
        ])
      ]),
      _: 1
    }),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_PanelGrid, {
            class: _normalizeClass({ 'page-container-visitor': _ctx.showVisitorContainer })
          }, {
            left: _withCtx(() => [
              _createVNode(_component_CompanyPanel, { onShowModal: _ctx.showListModal }, null, 8, ["onShowModal"]),
              (_ctx.$profile.isEntrepreneur())
                ? (_openBlock(), _createBlock(_component_InvestorsPanel, { key: 0 }))
                : _createCommentVNode("", true),
              (_ctx.$profile.isSupporter())
                ? (_openBlock(), _createBlock(_component_SupporterInfoPanel, { key: 1 }))
                : _createCommentVNode("", true)
            ]),
            right: _withCtx(() => [
              (_ctx.$user.isOwner() && _ctx.$user.isEntrepreneur())
                ? _withDirectives((_openBlock(), _createBlock(_component_PxCard, {
                    key: 0,
                    action: _ctx.goToSubmissionsPage,
                    "is-loading": _ctx.submissionsLoading,
                    subtitle: _ctx.submissionsCardCopySubtitle,
                    title: _ctx.submissionsCardCopy.title
                  }, null, 8, ["action", "is-loading", "subtitle", "title"])), [
                    [_directive_feature, 'userSubmissions']
                  ])
                : _createCommentVNode("", true),
              (_ctx.hasMatching)
                ? (_openBlock(), _createBlock(_component_MatchingRibbon, { key: 1 }))
                : _createCommentVNode("", true),
              (_ctx.$profile.isEntrepreneur())
                ? (_openBlock(), _createBlock(_component_AssessmentPanel, { key: 2 }))
                : _createCommentVNode("", true),
              (_ctx.$profile.isSupporter())
                ? (_openBlock(), _createBlock(_component_InvestmentLevelPanel, { key: 3 }))
                : _createCommentVNode("", true),
              (_ctx.hasAffiliateLink && _ctx.showAffiliateLinkPanel)
                ? (_openBlock(), _createBlock(_component_AffiliateLinkPanel, { key: 4 }))
                : _createCommentVNode("", true),
              (_ctx.$profile.isSupporter() && _ctx.showNetworksPanel)
                ? (_openBlock(), _createBlock(_component_NetworksPanel, { key: 5 }))
                : _createCommentVNode("", true),
              (_ctx.$profile.isSupporter())
                ? (_openBlock(), _createBlock(_component_SupporterCriteriaPanel, { key: 6 }))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["class"])
        ])
      ])
    ])
  ]))
}