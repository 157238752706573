import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PxIcon = _resolveComponent("PxIcon")!
  const _component_ElInput = _resolveComponent("ElInput")!

  return (_openBlock(), _createBlock(_component_ElInput, {
    id: _ctx.id,
    ref: _ctx.id,
    modelValue: _ctx.autocompleteText,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.autocompleteText) = $event)),
    placeholder: _ctx.placeholder,
    onBlur: _ctx.onBlur,
    onFocus: _ctx.onFocus,
    onInput: _ctx.validateInputDebounce,
    onKeypress: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('keypress', $event))),
    onKeyup: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('keyup', $event)))
  }, {
    prefix: _withCtx(() => [
      _createVNode(_component_PxIcon, {
        size: 24,
        name: "location"
      })
    ]),
    _: 1
  }, 8, ["id", "modelValue", "placeholder", "onBlur", "onFocus", "onInput"]))
}