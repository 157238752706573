import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, renderSlot as _renderSlot, renderList as _renderList, Fragment as _Fragment, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-b5ddb672"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "px-callout__header" }
const _hoisted_2 = {
  key: 1,
  class: "px-callout__title"
}
const _hoisted_3 = { class: "px-callout__content" }
const _hoisted_4 = {
  key: 0,
  class: "px-callout__description"
}
const _hoisted_5 = { class: "px-callout__note" }
const _hoisted_6 = {
  key: 0,
  class: "px-callout__ctas-wrapper"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PxStatusBadge = _resolveComponent("PxStatusBadge")!
  const _component_PxButton = _resolveComponent("PxButton")!

  return (_openBlock(), _createElementBlock("div", {
    style: _normalizeStyle({ backgroundColor: _ctx.backgroundColor }),
    class: "px-callout"
  }, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.label)
        ? (_openBlock(), _createBlock(_component_PxStatusBadge, {
            key: 0,
            "background-color": _ctx.label.backgroundColor,
            icon: _ctx.label.icon,
            "icon-size": _ctx.label.iconSize,
            label: _ctx.label.text,
            "label-color": _ctx.label.textColor,
            class: "px-callout__label",
            "icon-position": "right"
          }, null, 8, ["background-color", "icon", "icon-size", "label", "label-color"]))
        : _createCommentVNode("", true),
      (_ctx.title)
        ? (_openBlock(), _createElementBlock("h3", _hoisted_2, _toDisplayString(_ctx.title), 1))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_3, [
      (_ctx.description)
        ? (_openBlock(), _createElementBlock("p", _hoisted_4, _toDisplayString(_ctx.description), 1))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_5, [
        _renderSlot(_ctx.$slots, "note")
      ])
    ]),
    (_ctx.ctas.length)
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.ctas, (cta, index) => {
            return (_openBlock(), _createBlock(_component_PxButton, {
              key: index,
              label: cta.label,
              size: cta.size,
              type: cta.type,
              class: "px-callout__cta",
              onClick: ($event: any) => (_ctx.$emit('click', cta.action))
            }, null, 8, ["label", "size", "type", "onClick"]))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ], 4))
}