<template>
  <div class="panel-grid__block">
    <OfferingsEditModal
      v-if="$user.isOwner()"
      ref="offeringsEditModal"
      v-model:visibility="isOfferingsEditModalVisible"
    />
    <h2
      v-if="!$screen.mdUp"
      class="px-panel__title"
      v-html="$t('profile.supporter.title')"
    />
    <PxPanel
      class="profile-panel profile-panel--supporter-info"
      theme="with-sections"
    >
      <template v-if="$screen.mdUp" #header>
        <h2 class="px-panel__title" v-html="$t('profile.supporter.title')" />
      </template>
      <template v-if="$screen.mdUp">
        <PxPanelSection
          :icon-size="24"
          :title="$t('profile.supporter.offerings.title')"
          icon="briefcase"
        >
          <template v-if="isSupporterLoading">
            <ContentPlaceholders :rounded="true">
              <ContentPlaceholdersText :lines="2" />
            </ContentPlaceholders>
          </template>
          <template v-else>
            <SupporterOfferings
              v-if="hasUserAccess && hasSupporterOfferings"
              :offerings="supporterOfferings"
            />
            <PanelEmptyState v-else icon="offerings">
              <p>
                {{
                  $t(
                    `profile.supporter.offerings.emptyState.description[${+$user.isOwner()}]`,
                  )
                }}
              </p>
              <PxButton
                v-if="$user.isOwner()"
                size="medium"
                type="primary"
                @click="onClickEditOfferings"
              >
                {{ $t("profile.supporter.offerings.emptyState.button") }}
              </PxButton>
            </PanelEmptyState>
          </template>
          <template v-if="!isSupporterLoading" #cta>
            <PxButton
              v-if="hasSupporterOfferings && $user.isOwner()"
              class="profile-headline__updateBtn"
              size="small"
              @click="onClickEditOfferings"
            >
              {{ $t("profile.supporter.offerings.button") }}
            </PxButton>
          </template>
        </PxPanelSection>
      </template>
      <template v-if="$screen.smDown">
        <PxPanelSection :title="$t('profile.supporter.offerings.title')">
          <!-- When Why us is added we'll use this :
           <PxTabsMobile
            v-model="selectedTab"
            :items="tabsMobileItems" /> -->
          <template v-if="isSupporterLoading">
            <ContentPlaceholders :rounded="true">
              <ContentPlaceholdersText :lines="2" />
            </ContentPlaceholders>
          </template>
          <template v-else>
            <SupporterOfferings
              v-if="hasUserAccess && hasSupporterOfferings"
              :offerings="supporterOfferings"
            />
            <PanelEmptyState v-else icon="offerings">
              <p>
                {{
                  $t(
                    `profile.supporter.offerings.emptyState.description[${+$user.isOwner()}]`,
                  )
                }}
              </p>
            </PanelEmptyState>
          </template>
        </PxPanelSection>
      </template>
    </PxPanel>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import PxPanelSection from "@/components/px-panel-section/px-panel-section.vue";
import SupporterOfferings from "@/modules/profile/components/supporter-offerings/supporter-offerings.vue";
import OfferingsEditModal from "@/modules/profile/components/offerings-edit-modal/offerings-edit-modal.vue";
import PanelEmptyState from "@/modules/profile/components/panel-empty-state/panel-empty-state.vue";

import { ISupporter } from "@/modules/matching/services/data/matching-score/supporter.interface";
import { IOffering } from "@/services/data/offering/offering.interface";

export default defineComponent({
  name: "SupporterInfoPanel",

  components: {
    PxPanelSection,
    SupporterOfferings,
    OfferingsEditModal,
    PanelEmptyState,
  },

  data() {
    return {
      isOfferingsEditModalVisible: false,
      selectedTab: "offer",
      tabsMobileItems: [
        {
          value: "offer",
          label: this.$t("profile.supporter.offerings.title"),
        },
        // {
        //   value: "whyus",
        //   label: this.$t("profile.supporter.offerings.whyus"),
        // },
      ],
    };
  },

  computed: {
    supporter(): ISupporter | null {
      return this.$store.get("profile/supporter/data");
    },

    isSupporterLoading(): boolean {
      return this.$store.get("profile/supporter/loading");
    },

    supporterOfferings(): Array<IOffering> {
      return this.supporter ? this.supporter.offerings : [];
    },

    hasSupporterOfferings(): boolean {
      return !!this.supporterOfferings && this.supporterOfferings.length > 0;
    },

    isSpecialGuest(): boolean {
      return !!this.$route.meta?.specialGuest;
    },

    hasUserAccess(): boolean {
      return this.$user.isLogged() || this.isSpecialGuest;
    },
  },

  methods: {
    onClickEditOfferings() {
      this.isOfferingsEditModalVisible = true;
    },
  },
});
</script>

<style lang="scss" scoped>
:deep() {
  .px-panel__title {
    @include breakpoint-down(sm) {
      margin-top: 30px;
      margin-bottom: 19px;
      font-size: 18px;
      font-weight: bold;
      color: $ebony-clay;

      @include breakpoint-down(md) {
        margin-left: 14px;
      }
    }
  }

  .px-panel-section__header {
    min-height: 0;
    padding: 22px 14px;

    @include breakpoint-up(md) {
      min-height: 49px;
      padding: 8px 26px 8px 48px;
    }
  }

  .px-panel-section__body {
    padding-left: 15px;

    @include breakpoint-up(md) {
      padding: 12px 20px;
    }
  }

  .px-tabs-mobile {
    z-index: z("default");
  }

  .panel-empty-state {
    padding-bottom: 30px;
  }

  .vue-content-placeholders-text {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    width: 61%;
    margin-top: 5px;

    @include breakpoint-up(md) {
      padding: 0 20px;
    }
  }
}
</style>
