import {
  EProviderFeatures,
  GenericProvider,
  IOptionsType,
} from "@/services/data/generic-provider";
import { IPendingUserRegister } from "@/modules/authentication/services/data/pending-user-register/pending-user-register.interface";
import { baseAPIUrl } from "@/services/utils/utils";

/**
 * Provider used to perform a sign up.
 */
class PendingUserRegisterProvider extends GenericProvider<IPendingUserRegister> {
  constructor() {
    super(`${baseAPIUrl}/pending-user/register`, [EProviderFeatures.CREATE]);
  }

  public create(data: IPendingUserRegister, options?: IOptionsType) {
    // When there is no level selected the API expects to receive a
    // level null and not zero, so we need a conversion here.
    data.levels = data.levels.map((entry: any) => {
      entry.level = entry.level !== 0 ? entry.level : null;
      return entry;
    });

    return super.create(data, options);
  }
}

export const pendingUserRegisterProvider = new PendingUserRegisterProvider();
