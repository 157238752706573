import { IBaseState } from "@/services/store/store-types";
import { IMatchingResponse } from "@/services/data/matching-responses/matching-response.interface";

/**
 * Represents the state structure for the matching questionary.
 */
export interface IMatchingResponsesState extends IBaseState {
  /**
   * Array with all questions.
   */
  data: Array<IMatchingResponse>;

  // Persist minor UI data
  meta: any;
}

export enum EMatchingResponsesActions {
  FETCH = "matchingResponses/fetch",
  UPDATE_CACHED_RESPONSES = "matchingResponses/updateCachedResponses",
  SET_CACHED_RESPONSES = "matchingResponses/setCachedResponses",
  RESET = "matchingResponses/reset",
}

export enum EMatchingResponsesMutations {
  SET_LOADING = "SET_LOADING",
  SET_ERROR = "SET_ERROR",
  SET_DATA = "SET_DATA",
  SET_META = "SET_META",
}

export enum EMatchingResponsesGetters {
  IS_LOADING = "matchingResponses/isLoading",
  GET_DATA = "matchingResponses/getData",
  GET_CACHED_RESPONSES = "matchingResponses/getCachedResponses",
}
