import { Module } from "vuex";
import { make } from "vuex-pathify";

import { RootState } from "@/services/store/root-state";
import {
  IMilestonePlannerState,
  IMilestonePlanner,
} from "./milestone-planner.types";
import { questionBundlesModule } from "@/modules/milestone-planner/services/store/milestone-planner/sub-modules/question-bundles/question-bundles.module";
import { getGenericInitialState } from "@/services/store/generic/generic-state";
import { actions } from "@/modules/milestone-planner/services/store/milestone-planner/milestone-planner.actions";
import { passcodeModule } from "@/modules/milestone-planner/services/store/milestone-planner/sub-modules/passcode/passcode.module";
import { onboardingsModule } from "@/modules/milestone-planner/services/store/milestone-planner/sub-modules/onboardings/onboardings.module";

/**
 * Initial state for the Milestone Planner store.
 */
const state: IMilestonePlannerState = {
  ...getGenericInitialState<IMilestonePlanner>(),
  parsedValue: null,
};

export const milestonePlannerModule: Module<IMilestonePlannerState, RootState> =
  {
    namespaced: true,
    state,
    mutations: make.mutations(state),
    actions,
    modules: {
      questionBundles: questionBundlesModule,
      passcodes: passcodeModule,
      onboardings: onboardingsModule,
    },
  };
