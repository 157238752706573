<template>
  <div class="list-share-users-index">
    <div v-if="users.length" class="list-share-users-index__wrapper">
      <div
        v-for="(user, index) in users"
        :key="index"
        class="list-share-users-index__item"
      >
        <PxAvatar
          :name="user.name"
          :photo="user.photo"
          :size="32"
          class="list-share-users-index__item-photo"
          styling="neutral"
          type="round"
        />
        <div class="list-share-users-index__item-content">
          <h3 class="list-share-users-index__title">
            {{ user.name }}
          </h3>
          <p v-if="user.email" class="list-share-users-index__subtitle">
            {{ user.email }}
          </p>
        </div>
        <ElTag
          v-if="!user.user_profile"
          class="list-share-users-index__tag"
          effect="light"
          size="small"
          v-text="$t('common.guest')"
        />
        <ElTooltip
          v-if="!user.user_profile"
          :enterable="false"
          placement="top"
          popper-class="list-share-users-index__tooltip list-share-users-index__tooltip--spacious el-abaca-tooltip"
        >
          <template #content>
            <div v-text="$t('companyLists.list.shareModal.tip')"></div>
          </template>
          <PxIcon
            :size="17"
            class="list-share-users-index__tip"
            name="question"
          />
        </ElTooltip>
        <div class="list-share-users-index__item-actions">
          <ElTooltip
            v-if="$screen.mdUp"
            ref="unshare"
            :disabled="!user.user_profile"
            :enterable="false"
            placement="top"
            popper-class="list-share-users-index__tooltip el-abaca-tooltip"
          >
            <template #content>
              <div v-text="$t('companyLists.list.shareModal.unshare')"></div>
            </template>
            <div
              :class="{ 'is-disabled': !user.user_profile }"
              class="list-share-users-index__unshare-cta"
              @click.stop="unshareClickHandler(user.user_profile)"
            >
              <PxIcon :size="14" name="trash--red" />
              <PxIcon :size="14" class="px-icon--hover" name="trash" />
            </div>
          </ElTooltip>
        </div>
      </div>
    </div>
    <div v-else class="list-share-users-index__empty">
      <picture class="list-share-users-index__empty-illustration">
        <img
          alt="Users illustration"
          src="/img/icons/lists-users-empty-state.svg"
        />
      </picture>
      <p class="list-share-users-index__empty-title">
        {{ $t("companyLists.list.shareModal.empty") }}
      </p>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import { ICompanyListInvitedUser } from "@/modules/company-lists/services/data/company-list/company-list.interface";
import { LIST_SHARE_EVENTS } from "@/modules/company-lists/components/list-share/list-share-modal.vue";

export default defineComponent({
  name: "ListShareUsersIndex",

  props: {
    users: {
      type: Array as () => Array<ICompanyListInvitedUser>,
      required: true,
      default: () => [],
    },
  },

  methods: {
    unshareClickHandler(userProfile: string | null) {
      this.$emitter.emit("list-share-modal-confirmation", {
        event: LIST_SHARE_EVENTS.UNSHARE_FROM_USER,
        payload: { user_profile: userProfile },
      });
    },
  },
});
</script>

<style lang="scss" scoped>
.list-share-users-index__wrapper {
  padding-top: 5px;
}

.list-share-users-index__item {
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  padding: 14px 0 12px;

  &:not(:first-child) {
    border-top: solid 1px $athens-gray;
  }
}

.px-avatar.list-share-users-index__item-photo {
  user-select: none;
  box-shadow: inset 0 0 3px 0 rgba(139, 143, 161, 0.3);

  :deep(.px-avatar__letter) {
    font-size: 16px;
    line-height: 1.9;
    text-indent: 1px;
  }
}

.list-share-users-index__item-content {
  flex: 1 1 auto;

  &:not(:first-child) {
    margin-left: 16px;
  }
}

.list-share-users-index__item-actions {
  margin-right: 0;
  margin-left: auto;
}

.list-share-users-index__title {
  @include grotesk(regular);

  margin-top: -2px;
  margin-bottom: 0;
  font-size: 14px;
  line-height: 20px;
  color: $ebony-clay;
  letter-spacing: -0.35px;

  &:only-child {
    padding-top: 8px;
  }
}

.list-share-users-index__subtitle {
  @include grotesk(regular);

  margin-bottom: 0;
  font-size: 13px;
  line-height: 16px;
  color: $manatee;
  letter-spacing: -0.35px;
}

.list-share-users-index__tag {
  margin-top: 6px;
  margin-right: 58px;
  margin-left: auto;
}

.list-share-users-index__tip {
  margin-top: 8px;
  margin-right: 11px;
  margin-left: auto;
  transition: filter 200ms cubic-bezier(0.23, 1, 0.32, 1);

  &:hover {
    filter: brightness(0.5) hue-rotate(380deg);
  }
}

.list-share-users-index__unshare-cta {
  position: relative;
  width: 32px;
  height: 32px;
  cursor: pointer;
  user-select: none;
  border: solid 1px rgba($wild-watermelon, 0.2);
  border-radius: 3px;

  &::before {
    position: absolute;
    top: -1px;
    left: -1px;
    width: calc(100% + 2px);
    height: calc(100% + 2px);
    content: "";
    background-color: $wild-watermelon;
    border: inset 1px rgba($ebony-clay, 0.2);
    border-radius: 3px;
    opacity: 0;
    transition: $--fade-transition;
  }

  .px-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    vertical-align: top;
    transform: translate(-50%, -50%);
  }

  .px-icon--hover {
    filter: brightness(2);
    opacity: 0;
    transition: $--fade-transition;
  }

  &:hover {
    &::before {
      opacity: 1;
    }

    .px-icon--hover {
      opacity: 1;
    }
  }

  &.is-disabled {
    pointer-events: none;
    opacity: 0.5;
  }
}

.list-share-users-index__empty {
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  justify-content: center;
  height: 100%;
  // Pixel-perfect:
  padding-top: 23px;
  user-select: none;
}

.list-share-users-index__empty-illustration {
  margin-bottom: 7px;

  img {
    height: 93px;
  }
}

.list-share-users-index__empty-title {
  @include grotesk(medium);

  position: relative;
  top: 1px;
  margin-bottom: 0;
  font-size: 15px;
  line-height: normal;
  color: $manatee;
  letter-spacing: -0.25px;
}
</style>

<style lang="scss">
.list-share-users-index__tooltip {
  max-width: 278px;
  padding: 5px 10px;
  margin-top: 3px;
  text-transform: none;
  letter-spacing: -0.3px;
}

.list-share-users-index__tooltip--spacious {
  padding: 10px 15px;
  letter-spacing: -0.2px;
}
</style>
