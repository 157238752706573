import { ActionTree } from "vuex";
import { RootState } from "@/services/store/root-state";
import {
  EAffiliatesActions,
  EAffiliatesMutations,
  IAffiliatesState,
} from "@/modules/affiliates/services/store/affiliates/affiliates.types";
import { getBaseName } from "@/services/store/utils/get-base-name";
import { affiliateProvider } from "@/services/data/affiliate/affiliate.provider";
import {
  AFFILIATE_TYPE_PROGRAM,
  IAffiliateDraft,
} from "@/services/data/affiliate/affiliate.interface";
import { IMatchingQuestion } from "@/services/data/matching-questionary/matching-question.interface";
import { IQuestionBundle } from "@/services/data/question-bundle/question-bundle.interface";
import { userManager } from "@/modules/authentication/user-manager";
import { IPendingUser } from "@/services/store/viral-level/types/pending-user.interface";
import { ICategory } from "@/services/data/category/category.interface";
import { cloneDeep, debounce } from "lodash";
import { ICategoryLevelSelection } from "@/services/data/viral-level/viral-level.interface";
import { affiliateSubmissionsDraftsProvider } from "@/modules/affiliates/services/data/affiliate-submissions-drafts/affiliate-submissions-drafts.provider";
import { ETeamMembersActions } from "@/modules/team-management/services/store/team-members/team-members.types";
import { ECategoryActions } from "@/services/store/category/category-types";
import {
  ENTREPRENEUR_USER_GROUP_ID,
  SUPPORTER_USER_GROUP_ID,
} from "@/modules/common/constants";
import { ELevelActions } from "@/services/store/levels/levels-types";
import { IMatchingResponse } from "@/services/data/matching-responses/matching-response.interface";
import { userAffiliateSubmissionsProvider } from "@/modules/affiliates/services/data/user-affiliate-submissions/user-affiliate-submissions.provider";
import { EAuthLatestAssessmentActions } from "@/modules/authentication/services/store/auth/sub-modules/auth-latest-assessment/auth-latest-assessment.types";
import { EAffiliateSteps } from "@/modules/affiliates/views/affiliate.types";
import { EMatchingResponsesActions } from "@/modules/matching/services/store/matching-responses/matching-responses.types";
import { ITeamMember } from "@/modules/team-management/services/data/team-members/team-members.interface";

export const actions: ActionTree<IAffiliatesState, RootState> = {
  [getBaseName(EAffiliatesActions.SET_SELECTED_ASSESSMENT_CATEGORY)](
    { commit },
    newCategory,
  ) {
    commit(EAffiliatesMutations.SET_SELECTED_ASSESSMENT_CATEGORY, newCategory);
  },

  [getBaseName(EAffiliatesActions.SET_SELECTED_QUESTION)](
    { commit },
    newQuestion,
  ) {
    commit(EAffiliatesMutations.SET_SELECTED_QUESTION, newQuestion);
  },

  /**
   * This must be called when the user first interact with the level slider.
   */
  [getBaseName(EAffiliatesActions.SET_INTERACTION)]({ commit }) {
    commit(EAffiliatesMutations.SET_INTERACTION, false);
  },

  [getBaseName(EAffiliatesActions.SET_PENDING_USER)](
    { commit },
    pendingUser: IPendingUser,
  ) {
    commit(EAffiliatesMutations.SET_PENDING_USER, pendingUser);
  },

  async [getBaseName(EAffiliatesActions.RESET)]({ commit }) {
    commit(EAffiliatesMutations.SET_CURRENT_STEP, 0);
    commit(EAffiliatesMutations.SET_AFFILIATE, null);
    commit(EAffiliatesMutations.SET_SELECTED_ASSESSMENT_CATEGORY, 0);
    commit(EAffiliatesMutations.SET_SELECTED_QUESTION, 0);
    commit(EAffiliatesMutations.SET_PENDING_USER, null);
    commit(EAffiliatesMutations.SET_ERROR, null);
    commit(EAffiliatesMutations.SET_DRAFT_SUBMISSION, null);
    commit(EAffiliatesMutations.SET_AFFILIATE_PROGRAM_QUESTION_BUNDLE, null);
    commit(EAffiliatesMutations.SET_TEAM_MEMBERS_QUESTION_BUNDLE, null);
    commit(EAffiliatesMutations.SET_USER_NAVIGATING_FROM_REVIEW_PAGE, false);
  },

  async [getBaseName(EAffiliatesActions.FETCH_AFFILIATE)](
    { commit },
    affiliateId,
  ) {
    let affiliate = null;

    try {
      affiliate = await affiliateProvider.get(affiliateId);
      commit(EAffiliatesMutations.SET_AFFILIATE, affiliate);
    } catch (error) {
      commit(EAffiliatesMutations.SET_AFFILIATE, null);
      commit(EAffiliatesMutations.SET_ERROR, error);
    }

    return affiliate;
  },

  async [getBaseName(EAffiliatesActions.FETCH_SUBMISSION)](
    { commit },
    affiliateId,
  ) {
    commit(EAffiliatesMutations.SET_ERROR, null);

    try {
      const submission =
        await affiliateSubmissionsDraftsProvider.getAffiliateSubmissionDraft(
          affiliateId,
        );
      commit(EAffiliatesMutations.SET_DRAFT_SUBMISSION, submission);
    } catch {
      // Do nothing
    }
  },

  async [getBaseName(EAffiliatesActions.FETCH_AFFILIATE_QUESTION_BUNDLES)](
    { commit },
    affiliateId,
  ) {
    commit(EAffiliatesMutations.SET_ERROR, null);

    try {
      const questionBundles =
        await affiliateProvider.getQuestionBundles(affiliateId);

      // Get affiliate question bundle and team members specific question
      const affiliateQuestionBundles = [] as Array<IMatchingQuestion>;
      const teamMembersQuestionBundles = [] as Array<IMatchingQuestion>;
      const questionIds = [] as Array<number>;

      questionBundles.forEach((questionBundle: IQuestionBundle) => {
        questionBundle.questions.forEach((question: IMatchingQuestion) => {
          if (!questionIds.includes(question.id)) {
            if (question.is_team_member_question) {
              teamMembersQuestionBundles.push(question);
            } else {
              affiliateQuestionBundles.push(question);
            }

            questionIds.push(question.id);
          }
        });
      });

      commit(
        EAffiliatesMutations.SET_AFFILIATE_PROGRAM_QUESTION_BUNDLE,
        affiliateQuestionBundles,
      );

      commit(
        EAffiliatesMutations.SET_TEAM_MEMBERS_QUESTION_BUNDLE,
        teamMembersQuestionBundles,
      );
    } catch (error) {
      commit(EAffiliatesMutations.SET_AFFILIATE_PROGRAM_QUESTION_BUNDLE, null);
      commit(EAffiliatesMutations.SET_TEAM_MEMBERS_QUESTION_BUNDLE, null);
      commit(EAffiliatesMutations.SET_ERROR, error);
    }
  },

  [getBaseName(EAffiliatesActions.SET_SUBMISSION_ASSESSMENT_LEVELS)](
    { state, commit },
    { category, level },
  ) {
    const assessmentLevels = state.draftSubmission
      ? cloneDeep(state.draftSubmission.data.assessment)
      : null;

    if (assessmentLevels) {
      const index = assessmentLevels.findIndex(
        (item: ICategoryLevelSelection) => item.category === category,
      );

      if (index >= 0) {
        assessmentLevels[index].level = level || 0;
      } else {
        assessmentLevels.push({
          category,
          level: level || 0,
        });
      }

      commit(
        EAffiliatesMutations.SET_SUBMISSION_ASSESSMENT_LEVELS,
        assessmentLevels,
      );
    }

    return { assessmentLevels };
  },

  [getBaseName(EAffiliatesActions.SET_SUBMISSION_RESPONSES)](
    { commit },
    newResponses,
  ) {
    commit(EAffiliatesMutations.SET_SUBMISSION_RESPONSES, newResponses);
  },

  [getBaseName(EAffiliatesActions.SET_SUBMISSION_TEAM_MEMBERS)](
    { commit },
    newTeamMembers,
  ) {
    commit(EAffiliatesMutations.SET_SUBMISSION_TEAM_MEMBERS, newTeamMembers);
  },

  async [getBaseName(EAffiliatesActions.PREFILL_ASSESSMENT)]({
    state,
    rootState,
    commit,
    dispatch,
  }) {
    let latestAssessment = null;

    try {
      await dispatch(
        EAuthLatestAssessmentActions.FETCH,
        (rootState as any).auth.company.data.id,
        {
          root: true,
        },
      );

      latestAssessment = (rootState as any).auth.latestAssessment.data;
    } catch {
      /* Do nothing */
    }

    if (latestAssessment && latestAssessment.data?.length) {
      const assessment = latestAssessment.data.map((item: any) => ({
        category: item.category,
        level: item.level || 0,
      }));

      commit(EAffiliatesMutations.SET_DRAFT_SUBMISSION, {
        ...state.draftSubmission,
        data: {
          ...cloneDeep(state.draftSubmission?.data),
          assessment,
        },
      });
    }
  },

  async [getBaseName(EAffiliatesActions.PREFILL_RESPONSES)]({
    state,
    rootState,
    commit,
    dispatch,
  }) {
    await dispatch(EMatchingResponsesActions.FETCH, null, { root: true });

    const allMatchingResponses = (rootState as any).matchingResponses.data;

    const affiliateQuestions = state.affiliateQuestionBundle;

    if (affiliateQuestions?.length) {
      const responses = [] as any;

      affiliateQuestions.map((question: IMatchingQuestion) => {
        const response = allMatchingResponses.find(
          (response: IMatchingResponse) => response.question === question.id,
        );

        if (response) {
          response["isValid"] = true;
          responses.push(response);
        } else {
          responses.push({
            question: question.id,
            value: {},
            answers: [] as Array<number>,
            isValid: false,
          });
        }
      });

      commit(EAffiliatesMutations.SET_DRAFT_SUBMISSION, {
        ...state.draftSubmission,
        data: { ...cloneDeep(state.draftSubmission?.data), responses },
      });
    }
  },

  async [getBaseName(EAffiliatesActions.PREFILL_TEAM_MEMBERS)]({
    state,
    dispatch,
    commit,
  }) {
    try {
      const teamMembers = await dispatch(ETeamMembersActions.GET_VALUES, null, {
        root: true,
      });

      commit(EAffiliatesMutations.SET_DRAFT_SUBMISSION, {
        ...state.draftSubmission,
        data: { ...cloneDeep(state.draftSubmission?.data), teamMembers },
      });
    } catch {}
  },

  async [getBaseName(EAffiliatesActions.PREFILL_BLANK_SUBMISSION)](
    { state, rootState, commit },
    affiliateId,
  ) {
    const affiliateQuestions = state.affiliateQuestionBundle;
    const responses = [] as any;

    if (affiliateQuestions?.length) {
      affiliateQuestions.forEach((question: IMatchingQuestion) => {
        responses.push({
          question: question.id,
          value: {},
          answers: [] as Array<number>,
          isValid: false,
        });
      });
    }

    commit(EAffiliatesMutations.SET_DRAFT_SUBMISSION, {
      affiliate_id: affiliateId,
      data: {
        assessment: (rootState as any).categories.data.map(
          (category: ICategory) => ({
            category: category.id,
            level: 0,
          }),
        ),
        responses,
        teamMembers: [],
      },
    });
  },

  async [getBaseName(EAffiliatesActions.LOAD_AFFILIATE)](
    { state, dispatch, commit },
    affiliateId,
  ) {
    const dispatchOptions = { root: true };
    const group =
      userManager.isEntrepreneur() || userManager.isPendingUser()
        ? ENTREPRENEUR_USER_GROUP_ID
        : SUPPORTER_USER_GROUP_ID;

    commit(EAffiliatesMutations.SET_ERROR, null);

    // - reset variables
    if (
      state.affiliate?.id != affiliateId &&
      state.affiliate?.slug != affiliateId
    ) {
      commit(EAffiliatesMutations.SET_CURRENT_STEP, 0);
      commit(EAffiliatesMutations.SET_SELECTED_ASSESSMENT_CATEGORY, 0);
      commit(EAffiliatesMutations.SET_SELECTED_QUESTION, 0);
      commit(EAffiliatesMutations.SET_USER_NAVIGATING_FROM_REVIEW_PAGE, false);
    }

    // - get categories & levels
    await dispatch(ECategoryActions.FETCH, { group }, dispatchOptions);
    await dispatch(ELevelActions.FETCH, { group }, dispatchOptions);

    // - get affiliate details
    await dispatch(
      EAffiliatesActions.FETCH_AFFILIATE,
      affiliateId,
      dispatchOptions,
    );

    // - get affiliate questions
    if (state.affiliate?.flow_type == AFFILIATE_TYPE_PROGRAM) {
      await dispatch(
        EAffiliatesActions.FETCH_AFFILIATE_QUESTION_BUNDLES,
        state.affiliate?.id,
        dispatchOptions,
      );
    } else {
      commit(EAffiliatesMutations.SET_AFFILIATE_PROGRAM_QUESTION_BUNDLE, null);
      commit(EAffiliatesMutations.SET_TEAM_MEMBERS_QUESTION_BUNDLE, null);
    }
  },

  async [getBaseName(EAffiliatesActions.LOAD_SUBMISSION)]({
    state,
    dispatch,
    commit,
  }) {
    const dispatchOptions = { root: true };

    await dispatch(
      EAffiliatesActions.PREFILL_BLANK_SUBMISSION,
      state.affiliate?.id,
      dispatchOptions,
    );

    if (userManager.isLogged() || userManager.isPendingUser()) {
      commit(EAffiliatesMutations.SET_LOADING, true);

      // fetch draft
      await dispatch(
        EAffiliatesActions.FETCH_SUBMISSION,
        state.affiliate?.id,
        dispatchOptions,
      );

      if (!state.draftSubmission?.updated_at) {
        if (
          state.currentStep != EAffiliateSteps.STARTING_PAGE &&
          state.currentStep != EAffiliateSteps.ASSESSMENT
        ) {
          commit(
            EAffiliatesMutations.SET_CURRENT_STEP,
            EAffiliateSteps.STARTING_PAGE,
          );
        }

        commit(EAffiliatesMutations.SET_SELECTED_ASSESSMENT_CATEGORY, 0);
        commit(EAffiliatesMutations.SET_SELECTED_QUESTION, 0);
        commit(
          EAffiliatesMutations.SET_USER_NAVIGATING_FROM_REVIEW_PAGE,
          false,
        );

        if (userManager.isLogged()) {
          await dispatch(
            EAffiliatesActions.PREFILL_ASSESSMENT,
            null,
            dispatchOptions,
          );

          if (state.affiliate?.flow_type == AFFILIATE_TYPE_PROGRAM) {
            await dispatch(
              EAffiliatesActions.PREFILL_RESPONSES,
              null,
              dispatchOptions,
            );
          }

          if (state.affiliate?.show_team_section) {
            await dispatch(
              EAffiliatesActions.PREFILL_TEAM_MEMBERS,
              null,
              dispatchOptions,
            );
          }
        }
      }
    }

    commit(EAffiliatesMutations.SET_LOADING, false);
  },

  async [getBaseName(EAffiliatesActions.SAVE_DRAFT)]({ state, commit }) {
    if (userManager.isLogged() || userManager.isPendingUser()) {
      if (state.draftSubmission?.affiliate_id) {
        commit(EAffiliatesMutations.SET_ERROR, null);
        commit(EAffiliatesMutations.SET_SAVING, true);

        const submission = cloneDeep(state.draftSubmission);

        const prepareResponse = (response: Partial<IMatchingResponse>) => {
          if (response.answers?.length) {
            const newResponse = { ...response };
            delete newResponse.value;
            return newResponse;
          }

          return response;
        };

        if (state.affiliate?.flow_type == AFFILIATE_TYPE_PROGRAM) {
          submission.data.responses = submission.data.responses.map(
            prepareResponse,
          ) as IMatchingResponse[];
        }

        if (state.affiliate?.show_team_section) {
          submission.data.teamMembers = submission.data.teamMembers.map(
            (teamMember: Partial<ITeamMember>) => {
              if (!teamMember.id) {
                delete teamMember.id;
              }

              return {
                ...teamMember,
                responses: (
                  teamMember.responses as Array<IMatchingResponse>
                ).map(prepareResponse),
              };
            },
          ) as ITeamMember[];
        }

        try {
          const [savedSubmission] = await Promise.all([
            affiliateSubmissionsDraftsProvider.create(submission),
            new Promise((r) => setTimeout(r, 500)),
          ]);
          commit(EAffiliatesMutations.SET_DRAFT_SUBMISSION, {
            ...state.draftSubmission,
            id: (savedSubmission as IAffiliateDraft).id,
            created_at: (savedSubmission as IAffiliateDraft).created_at,
            updated_at: (savedSubmission as IAffiliateDraft).updated_at,
          });
        } catch (error) {
          commit(EAffiliatesMutations.SET_ERROR, error);
        }

        commit(EAffiliatesMutations.SET_SAVING, false);
      }
    }
  },

  [getBaseName(EAffiliatesActions.DEBOUNCE_SAVE_DRAFT)]: debounce(
    async function ({ dispatch }) {
      await dispatch(EAffiliatesActions.SAVE_DRAFT, null, { root: true });
    },
    1000,
  ),

  async [getBaseName(EAffiliatesActions.FETCH_USER_SUBMISSIONS)]({ commit }) {
    commit(EAffiliatesMutations.SET_ERROR, null);
    commit(EAffiliatesMutations.SET_LOADING, true);

    try {
      const userSubmissions = await userAffiliateSubmissionsProvider.list();
      commit(EAffiliatesMutations.SET_USER_SUBMISSIONS, userSubmissions);
    } catch {
      commit(EAffiliatesMutations.SET_USER_SUBMISSIONS, null);
    }

    commit(EAffiliatesMutations.SET_LOADING, false);
  },

  async [getBaseName(EAffiliatesActions.SUBMIT)]({ state, commit }) {
    commit(EAffiliatesMutations.SET_ERROR, null);
    commit(EAffiliatesMutations.SET_SAVING, true);

    const draft_id = state.draftSubmission?.id;

    if (draft_id) {
      try {
        await userAffiliateSubmissionsProvider.create({ draft_id });
      } catch (error) {
        commit(EAffiliatesMutations.SET_ERROR, error);
      }
    } else {
      commit(EAffiliatesMutations.SET_ERROR, "No draft submission found");
    }
    commit(EAffiliatesMutations.SET_SAVING, false);
  },

  [getBaseName(EAffiliatesActions.SET_USER_NAVIGATING_FROM_REVIEW_PAGE)](
    { commit },
    newValue,
  ) {
    commit(EAffiliatesMutations.SET_USER_NAVIGATING_FROM_REVIEW_PAGE, newValue);
  },
};
