<template>
  <div class="affiliate-self-assessment">
    <div>
      <PxNavbar
        v-if="$screen.mdUp"
        :external-link="affiliateWebsite"
        :loading="affiliateDataLoading"
        :logo="affiliateLogo"
      />
      <MobileTopNavbar
        v-else
        :affiliate-logo="affiliateLogo"
        :is-assessment-page="isAssessmentPage"
        :show-subtitle="!isStartingPage"
      />
      <LangSwitcher v-if="isStartingPage && !affiliateDataLoading" />
    </div>
    <div v-loading="affiliateDataLoading" element-loading-background="#fafafa">
      <SelfAssessmentStartingPage
        v-if="isStartingPage"
        @next-step="goToNextStep"
      />
      <AffiliateAssessment
        v-if="isAssessmentPage && !resultsPageVisible"
        :is-self-assessment="true"
        @go-back="goToPreviousStep"
      />
      <AffiliateEntrepreneurResults v-if="resultsPageVisible" />
    </div>
    <AffiliateFooterBar
      v-if="!isStartingPage && !resultsPageVisible"
      :date="updatedDate"
      :is-last-page="isAssessmentLastCategory"
      :is-saving="saving"
      @next-step="goToNextStep"
      @previous-step="goToPreviousStep"
      @show-saved-draft-message="showSavedDraftMessage"
    />
    <PxFooter v-else />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import AffiliateTypesMixin from "@/modules/affiliates/mixins/affiliate-types.mixin";

import MobileTopNavbar from "@/modules/affiliates/components/mobile-top-navbar/mobile-top-navbar.vue";
import LangSwitcher from "@/modules/common/components/lang-switcher/lang-switcher.vue";
import SelfAssessmentStartingPage from "@/modules/affiliates/views/affiliate-starting-page/self-assessment-starting-page.vue";
import AffiliateAssessment from "@/modules/affiliates/views/affiliate-assessment/affiliate-assessment.vue";
import AffiliateEntrepreneurResults from "@/modules/affiliates/views/affiliate-results/affiliate-entrepreneur-results.vue";
import AffiliateFooterBar from "@/modules/affiliates/components/affiliate-footer-bar/affiliate-footer-bar.vue";

import { EAffiliateSteps } from "@/modules/affiliates/views/affiliate.types";
import { EAffiliatesActions } from "@/modules/affiliates/services/store/affiliates/affiliates.types";

export default defineComponent({
  name: "AffiliateSelfAssessmentView",

  components: {
    LangSwitcher,
    SelfAssessmentStartingPage,
    AffiliateEntrepreneurResults,
    AffiliateAssessment,
    AffiliateFooterBar,
    MobileTopNavbar,
  },

  mixins: [AffiliateTypesMixin],

  computed: {
    isStartingPage(): boolean {
      return this.currentStep === EAffiliateSteps.STARTING_PAGE;
    },

    isAssessmentPage(): boolean {
      return (
        this.currentStep === EAffiliateSteps.ASSESSMENT &&
        !this.resultsPageVisible
      );
    },
  },

  created() {
    if (!this.submissionInProgress) {
      this.currentStep = this.shouldSkipStartingPage
        ? EAffiliateSteps.ASSESSMENT
        : EAffiliateSteps.STARTING_PAGE;
    }
  },

  methods: {
    async goToPreviousStep() {
      if (this.isAssessmentPage && !this.isAssessmentFirstCategory) {
        this.handleCategoryNavigation(false);
      } else {
        this.currentStep--;
      }
    },

    async goToNextStep() {
      if (this.isAssessmentPage) {
        !this.isAssessmentLastCategory
          ? this.handleCategoryNavigation()
          : this.submitAffiliate();
        return;
      }

      this.currentStep++;
    },

    handleCategoryNavigation(shouldNavigateNext = true) {
      const nextCategoryToShow = shouldNavigateNext
        ? this.selectedAssessmentCategory + 1
        : this.selectedAssessmentCategory - 1;

      this.$store.dispatch(
        EAffiliatesActions.SET_SELECTED_ASSESSMENT_CATEGORY,
        nextCategoryToShow,
      );
    },
  },
});
</script>

<style lang="scss" scoped>
.affiliate-self-assessment {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;

  .lang-switcher {
    position: relative;
  }

  @include breakpoint-up(sm) {
    gap: 30px;
  }

  @include breakpoint-down(sm) {
    .affiliate-footer-bar {
      position: sticky;
      bottom: 0;
    }
  }
}
</style>
