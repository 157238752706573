import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "offerings-edit-modal__tip" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DialogConfirmation = _resolveComponent("DialogConfirmation")!
  const _component_PxModalMessage = _resolveComponent("PxModalMessage")!
  const _component_OfferingsForm = _resolveComponent("OfferingsForm")!
  const _component_PxIcon = _resolveComponent("PxIcon")!
  const _component_ElDialog = _resolveComponent("ElDialog")!

  return (_openBlock(), _createBlock(_component_ElDialog, {
    ref: "modal",
    modelValue: _ctx.innerVisibility,
    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.innerVisibility) = $event)),
    "before-close": _ctx.beforeCloseHandler,
    title: _ctx.$t(`profile.supporter.offerings.modal.title`),
    class: "offerings-edit-modal el-dialog--px-10",
    width: "904px"
  }, {
    footer: _withCtx(() => [
      _createElementVNode("div", null, [
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_PxIcon, {
            size: 14,
            name: "information--light-gray"
          }),
          _createTextVNode(" " + _toDisplayString(_ctx.$t("profile.supporter.offerings.modal.tip")), 1)
        ])
      ])
    ]),
    default: _withCtx(() => [
      _createVNode(_component_DialogConfirmation, { ref: "dialog-confirmation" }, null, 512),
      _createVNode(_component_PxModalMessage, { visible: _ctx.hasServerErrors }, null, 8, ["visible"]),
      (_ctx.innerVisibility)
        ? (_openBlock(), _createBlock(_component_OfferingsForm, {
            key: 0,
            ref: "form",
            "form-changes": _ctx.formChanges,
            "onUpdate:formChanges": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formChanges) = $event)),
            "has-errors": _ctx.hasServerErrors,
            "onUpdate:hasErrors": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.hasServerErrors) = $event))
          }, null, 8, ["form-changes", "has-errors"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["modelValue", "before-close", "title"]))
}