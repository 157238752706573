<template>
  <div class="criteria-questions__container">
    <template v-if="!viewLoading">
      <div class="navigation-header__container">
        <PxButton
          v-if="hasPreviousPage"
          circle
          class="navigation-header__return-button"
          @click="onPreviousQuestionButtonClick"
        >
          <PxIcon name="arrow--left-dark" size="14" />
        </PxButton>
      </div>
      <QuestionPanel
        v-if="hasCurrentQuestion"
        v-model="currentResponse"
        :question="currentQuestion"
        :step="step"
        :text-area-limit="textAreaLimit"
        :total="questions.length"
        class="question-flow__panel"
        @validate="validateResponseState"
      />
    </template>
    <NavigationFooter>
      <template #left>
        <PxButton v-if="skipEnabled" @click="onSkipButtonClick">
          {{ ctaCopy.skip }}
        </PxButton>
      </template>
      <template #center>
        <PxButton
          :disabled="mainCtaSettings.disabled"
          :loading="formLoading || viewLoading"
          :type="mainCtaSettings.type"
          class="navigation-footer__main-cta"
          @click="onMainCtaButtonClick"
        >
          {{ mainCtaSettings.copy }}
          <PxIcon
            v-if="!!mainCtaSettings.icon"
            :name="mainCtaSettings.icon"
            :size="mainCtaSettings.iconSize"
          />
        </PxButton>
      </template>
      <template #right>
        <PxButton v-if="skipEnabled" type="link" @click="onSkipAllButtonClick">
          {{ ctaCopy.setupCriteriaLater }}
        </PxButton>
      </template>
    </NavigationFooter>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import SupportersFlowMixin from "@/modules/supporters/mixins/supporters-flow.mixin";
import QuestionPanel from "@/modules/supporters/components/question-panel/question-panel.vue";
import NavigationFooter from "@/modules/supporters/components/navigation-footer/navigation-footer.vue";

import { IMatchingResponse } from "@/services/data/matching-responses/matching-response.interface";
import { IMatchingQuestion } from "@/services/data/matching-questionary/matching-question.interface";
import { EAffiliateGetter } from "@/modules/supporters/services/store/affiliate/affiliate.interface";
import { ISupporterFlow } from "@/modules/supporters/services/data/supporter-flow/supporter-flow.interface";

export default defineComponent({
  name: "CriteriaQuestions",

  components: {
    QuestionPanel,
    NavigationFooter,
  },

  mixins: [SupportersFlowMixin],

  data() {
    return {
      fields: {
        criteria: [],
      } as Partial<ISupporterFlow>,

      currentYear: new Date().getFullYear(),
      step: 0,
      hasResponse: false,
      currentResponse: {} as IMatchingResponse,
      textAreaLimit: 200,
    };
  },

  computed: {
    hasCurrentQuestion(): boolean {
      return !!this.currentQuestion;
    },

    isLastQuestion(): boolean {
      return this.hasCurrentQuestion && this.questions.length === this.step + 1;
    },

    currentQuestion(): IMatchingQuestion {
      return this.questions[this.step] || {};
    },

    nextQuestionButtonSettings(): any {
      const nextButton = {
        copy: (this.ctaCopy as any).continue,
        type: "green",
        icon: "arrow--right-white",
        iconSize: 14,
        disabled: false,
      };

      // If skip is not enabled, use next only
      if (!this.skipEnabled) {
        return this.hasResponse
          ? nextButton
          : {
              ...nextButton,
              disabled: true,
            };
      }

      return this.hasResponse
        ? nextButton
        : {
            copy: (this.ctaCopy as any).skipQuestion,
            type: "",
            icon: "arrow--right-grey",
            iconSize: 14,
            disabled: false,
          };
    },

    lastQuestionButtonSettings(): any {
      const nextButton = {
        copy: (this.ctaCopy as any).next,
        type: "primary",
        icon: "",
        iconSize: 14,
        disabled: false,
      };

      // If skip is not enabled, use next only
      if (!this.skipEnabled) {
        return this.hasResponse
          ? nextButton
          : {
              ...nextButton,
              disabled: true,
            };
      }

      return this.hasResponse
        ? nextButton
        : {
            copy: (this.ctaCopy as any).skipAndNext,
            type: "",
            icon: "arrow--right-grey",
            iconSize: 14,
            disabled: false,
          };
    },

    mainCtaSettings(): any {
      return this.isLastQuestion
        ? this.lastQuestionButtonSettings
        : this.nextQuestionButtonSettings;
    },

    questions(): Array<IMatchingQuestion> {
      return this.$store.getters[EAffiliateGetter.QUESTION_BUNDLE].filter(
        (question: IMatchingQuestion) => !question.is_team_member_question,
      );
    },
  },

  created() {
    if (this.flowData) {
      this.fields.criteria = [...this.flowData.criteria];

      if (this.$route.params?.step) {
        this.step = +this.$route.params?.step;
      }

      this.prefillCurrentResponse();
    }
  },

  methods: {
    /**
     * Method to convert response to field value.
     */
    storeResponseField() {
      if (!this.hasResponse || !this.currentResponse) {
        return;
      }

      // Update response field
      const filteredResponses = this.fields.criteria.filter(
        (response: IMatchingResponse) =>
          this.currentResponse &&
          response.question !== this.currentResponse.question,
      ) as Array<IMatchingResponse>;

      // Format value
      const formattedResponse = (
        !this.currentResponse.answers.length
          ? {
              question: this.currentResponse.question,
              value: this.currentResponse.value,
            }
          : {
              question: this.currentResponse.question,
              answers: this.currentResponse.answers,
            }
      ) as IMatchingResponse;

      this.fields.criteria = [...filteredResponses, formattedResponse];
    },

    /**
     * Handle next question button click.
     */
    onNextQuestionButtonClick() {
      // Advance to next question
      this.step += 1;
      this.prefillCurrentResponse();
    },

    /**
     * Handle previous question button click.
     */
    onPreviousQuestionButtonClick() {
      // If first question, return to previous page
      if (!this.step) {
        this.onReturnButtonClick();
        return;
      }

      this.step -= 1;
      this.prefillCurrentResponse();
    },

    /**
     * Handle main cta button click, which action differs if is last question
     */
    onMainCtaButtonClick() {
      // Add response to fields
      this.storeResponseField();

      if (this.isLastQuestion) {
        this.onNextButtonClick();
        return;
      }

      // Continue to next question
      this.onNextQuestionButtonClick();
    },

    /**
     * Update response check state based state received.
     * @param isValid
     */
    validateResponseState(isValid: boolean) {
      this.hasResponse = isValid;
    },

    /**
     * Prefill current response value, based on current step
     */
    prefillCurrentResponse() {
      this.currentResponse = this.fields.criteria.find(
        (response: IMatchingResponse) =>
          response.question === this.currentQuestion?.id,
      ) as IMatchingResponse;
    },
  },
});
</script>

<style lang="scss" scoped>
.criteria-questions__container {
  padding-bottom: 20px;
}

.navigation-header__container {
  @include make-container-max-widths();

  display: grid;
  grid-template-columns: repeat(2, auto);
  padding-top: 44px;
  margin: 0 auto;
}

.navigation-header__return-button {
  width: 28px;
  min-width: auto;
  height: 28px;
  padding: 0;
  border-radius: 50%;

  &::after {
    border-radius: 50%;
  }

  :deep() img {
    transform: translate(-1px, -1px);
  }
}

.question-flow__panel {
  margin-top: 17px;
}
</style>

<style lang="scss">
.navigation-header__tooltip-wrapper {
  max-width: 497px;
  font-size: 14px;
  text-transform: none;
}

.navigation-header__tooltip-container {
  display: grid;
  grid-gap: 24px;
}
</style>
