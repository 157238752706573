import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-64f13d60"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "px-corner-ribbon" }
const _hoisted_2 = ["data-label"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_ctx.hidden)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          "data-label": _ctx.label,
          class: "px-corner-ribbon__label"
        }, null, 8, _hoisted_2))
      : _createCommentVNode("", true),
    _renderSlot(_ctx.$slots, "default", {}, undefined, true)
  ]))
}