import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-5a6afa92"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "account-settings-password" }
const _hoisted_2 = ["textContent"]
const _hoisted_3 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ElInput = _resolveComponent("ElInput")!
  const _component_ElFormItem = _resolveComponent("ElFormItem")!
  const _component_PxInputPassword = _resolveComponent("PxInputPassword")!
  const _component_ElForm = _resolveComponent("ElForm")!
  const _component_PxButton = _resolveComponent("PxButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h1", null, _toDisplayString(_ctx.$t("profile.accountSettingsPage.passwordForm.title")), 1),
    _createElementVNode("p", null, _toDisplayString(_ctx.$t("profile.accountSettingsPage.passwordForm.description")), 1),
    _createVNode(_component_ElForm, {
      ref: "password",
      model: _ctx.fields,
      rules: _ctx.rules,
      class: "account-settings-password__form",
      onValidate: _ctx.updateSubmitState
    }, {
      default: _withCtx(() => [
        _createVNode(_component_ElFormItem, {
          label: 
          _ctx.$t('profile.accountSettingsModal.changePassword.fields.oldPassword')
        ,
          prop: "old_password"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_ElInput, {
              modelValue: _ctx.fields.old_password,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.fields.old_password) = $event)),
              placeholder: 
            _ctx.$t('profile.accountSettingsModal.changePassword.fields.oldPassword')
          ,
              autocomplete: "new-password",
              type: "password"
            }, null, 8, ["modelValue", "placeholder"])
          ]),
          _: 1
        }, 8, ["label"]),
        _createVNode(_component_ElFormItem, {
          label: 
          _ctx.$t('profile.accountSettingsModal.changePassword.fields.newPassword')
        ,
          prop: "new_password1"
        }, {
          default: _withCtx(() => [
            _createElementVNode("span", {
              class: "el-form-item__top-text el-form-item__requirement",
              textContent: _toDisplayString(_ctx.$t('profile.accountSettingsModal.changePassword.requirement'))
            }, null, 8, _hoisted_2),
            _createVNode(_component_PxInputPassword, {
              modelValue: _ctx.fields.new_password1,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.fields.new_password1) = $event)),
              placeholder: 
            _ctx.$t('profile.accountSettingsModal.changePassword.fields.newPassword')
          ,
              "show-success-state": true,
              autocomplete: "new-password"
            }, null, 8, ["modelValue", "placeholder"]),
            _createElementVNode("p", {
              class: "el-form-item__tip",
              innerHTML: _ctx.$t('profile.accountSettingsModal.changePassword.tip')
            }, null, 8, _hoisted_3)
          ]),
          _: 1
        }, 8, ["label"]),
        _createVNode(_component_ElFormItem, {
          label: 
          _ctx.$t(
            'profile.accountSettingsModal.changePassword.fields.confirmPassword',
          )
        ,
          prop: "new_password2"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_ElInput, {
              modelValue: _ctx.fields.new_password2,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.fields.new_password2) = $event)),
              disabled: _ctx.newPasswordIsInvalid,
              placeholder: 
            _ctx.$t(
              'profile.accountSettingsModal.changePassword.fields.confirmPassword',
            )
          ,
              class: "el-input--password",
              type: "password"
            }, null, 8, ["modelValue", "disabled", "placeholder"])
          ]),
          _: 1
        }, 8, ["label"])
      ]),
      _: 1
    }, 8, ["model", "rules", "onValidate"]),
    _createElementVNode("footer", null, [
      _createVNode(_component_PxButton, {
        disabled: _ctx.formHasNoChanges,
        class: "el-button--small account-settings-password__cancel-btn",
        type: "link",
        onClick: _ctx.cancelPasswordChange
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t("profile.accountSettingsModal.changePassword.resetButton")), 1)
        ]),
        _: 1
      }, 8, ["disabled", "onClick"]),
      _createVNode(_component_PxButton, {
        disabled: _ctx.isSubmissionDisabled,
        loading: _ctx.isLoading,
        class: "account-settings-password__submit-btn",
        type: "green",
        onClick: _ctx.updatePassword
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t("profile.accountSettingsModal.changePassword.submitButton")), 1)
        ]),
        _: 1
      }, 8, ["disabled", "loading", "onClick"])
    ])
  ]))
}