import { IBaseState } from "@/services/store/store-types";
import {
  IMatchingQuestion,
  IMatchingQuestionType,
} from "@/services/data/matching-questionary/matching-question.interface";

/**
 * Represents the state structure for the matching questionary.
 */
export interface IMatchingQuestionaryState extends IBaseState {
  /**
   * Array with all questions.
   */
  data: Array<IMatchingQuestion>;

  /**
   * Current question index
   */
  currentQuestionIndex: number;

  /**
   * Number of total questions
   */
  totalQuestions: number;
}

export enum EMatchingQuestionaryActions {
  FETCH = "matchingQuestionary/fetch",
  SKIP_QUESTION = "matchingQuestionary/skip",
  SUBMIT_RESPONSE = "matchingQuestionary/submit",
  SUBMIT_CRITERIA = "matchingQuestionary/submitCriteria",
}

export enum EMatchingQuestionaryMutations {
  SET_LOADING = "SET_LOADING",
  SET_CURRENT_QUESTION_INDEX = "SET_CURRENT_QUESTION_INDEX",
  SET_TOTAL_QUESTIONS = "SET_TOTAL_QUESTIONS",
  SET_DATA = "SET_DATA",
  SET_ERROR = "SET_ERROR",
  INCREMENT = "INCREMENT",
}

export enum EMatchingQuestionaryGetters {
  QUESTION = "matchingQuestionary/question",
  QUESTION_INDEX = "matchingQuestionary/questionIndex",
  TOTAL_QUESTIONS = "matchingQuestionary/totalQuestions",
}

export interface IMatchingQuestionaryFetchPayload {
  page?: number;
  per_page?: number;

  // Without exclude, defaults to all types
  exclude?: Array<IMatchingQuestionType["type"]>;

  // Without only, defaults to all targets
  only?: "criteria" | "profile";
}
