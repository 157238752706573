import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-5df9953e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "category-levels-assessment-mobile" }
const _hoisted_2 = { class: "container-fluid" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CategoryLevelsAssessmentMobilePlaceholder = _resolveComponent("CategoryLevelsAssessmentMobilePlaceholder")!
  const _component_MobileLevelSelect = _resolveComponent("MobileLevelSelect")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.isLoading)
        ? (_openBlock(), _createBlock(_component_CategoryLevelsAssessmentMobilePlaceholder, { key: 0 }))
        : _createCommentVNode("", true),
      (!_ctx.isLoading && _ctx.currentCategory && _ctx.selectedStep)
        ? (_openBlock(), _createBlock(_component_MobileLevelSelect, {
            key: 1,
            "category-details": _ctx.currentCategory.categoryDetails,
            "current-level": _ctx.selectedStep.level,
            onChangeLevel: _ctx.onLevelChange
          }, null, 8, ["category-details", "current-level", "onChangeLevel"]))
        : _createCommentVNode("", true)
    ])
  ]))
}