import { ActionTree } from "vuex";
import { RootState } from "@/services/store/root-state";
import { getGenericStateActions } from "@/services/store/generic/generic-state.actions";
import { ICompanyList } from "@/modules/company-lists/services/data/company-list/company-list.interface";
import { GenericState } from "@/services/store/generic/generic-state.module";
import {
  ECompanySharedListAction,
  ICompanySharedListState,
} from "@/modules/company-lists/services/store/company-shared-list/company-shared-list.interface";
import { sharedCompanyListProvider } from "@/modules/company-lists/services/data/company-list/shared-company-list.provider";
import { ECompanySharedListMutation } from "@/modules/company-lists/services/store/company-shared-list/company-shared-list.mutations";

export const actions: ActionTree<ICompanySharedListState, RootState> = {
  ...getGenericStateActions<ICompanyList>(sharedCompanyListProvider),

  /**
   * Get list of company lists shared with the user.
   *
   * @returns the servers response.
   */
  async [ECompanySharedListAction.GET_VALUES]({
    commit,
  }): Promise<Array<ICompanyList>> {
    commit(GenericState.Mutation.SET_LOADING, true);
    commit(GenericState.Mutation.SET_ERROR, null);

    let response: ICompanyList[] = [];

    try {
      response = await sharedCompanyListProvider.list();

      commit(ECompanySharedListMutation.SET_VALUES, response);
    } catch (error) {
      commit(ECompanySharedListMutation.SET_VALUES, null);
      commit(GenericState.Mutation.SET_ERROR, error || true);
    } finally {
      commit(GenericState.Mutation.SET_LOADING, false);
    }

    return response;
  },
};
