<template>
  <div :style="{ backgroundColor }" class="px-callout">
    <div class="px-callout__header">
      <PxStatusBadge
        v-if="label"
        :background-color="label.backgroundColor"
        :icon="label.icon"
        :icon-size="label.iconSize"
        :label="label.text"
        :label-color="label.textColor"
        class="px-callout__label"
        icon-position="right"
      />
      <h3 v-if="title" class="px-callout__title">
        {{ title }}
      </h3>
    </div>
    <div class="px-callout__content">
      <p v-if="description" class="px-callout__description">
        {{ description }}
      </p>
      <div class="px-callout__note">
        <slot name="note" />
      </div>
    </div>
    <div v-if="ctas.length" class="px-callout__ctas-wrapper">
      <template v-for="(cta, index) in ctas" :key="index">
        <PxButton
          :label="cta.label"
          :size="cta.size"
          :type="cta.type"
          class="px-callout__cta"
          @click="$emit('click', cta.action)"
        />
      </template>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import PxStatusBadge from "@/components/px-status-badge/px-status-badge.vue";
import {
  IComponentLabel,
  IComponentCta,
  EComponentCtaActionType,
} from "@/modules/affiliates/components/common.interface";

export default defineComponent({
  name: "PxCallout",

  components: {
    PxStatusBadge,
  },

  props: {
    label: {
      type: Object as () => IComponentLabel,
      default: null,
    },

    title: {
      type: String,
      default: "",
    },

    description: {
      type: String,
      default: "",
    },

    ctas: {
      type: Array as () => IComponentCta[],
      default: () => [],
      validator: (ctas: IComponentCta[]) => {
        return ctas.every((cta) => {
          if (cta.action) {
            return Object.values(EComponentCtaActionType).includes(cta.action);
          } else {
            return true;
          }
        });
      },
    },

    backgroundColor: {
      type: String,
      default: "#27273e",
    },
  },
});
</script>

<style lang="scss" scoped>
.px-callout {
  max-width: 430px;
  padding: 20px;

  border-radius: 8px;
  box-shadow: 0 16px 24px 0 rgba(0, 0, 0, 0.15);

  &__header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 17px;
  }

  &__title {
    @include grotesk(semiBold);

    margin: 0;
    color: #fff;
    font-size: 18px;
    line-height: 26px;
    letter-spacing: -0.2px;
  }

  &__label {
    position: relative;
    top: 1px;

    padding: 2px 5px 2px 11px;
    animation: bounce 2s infinite;
  }

  &__content {
    margin-top: 14px;

    p,
    :slotted(p) {
      color: rgba(255, 255, 255, 0.8);
      font-size: 15px;
      line-height: 22px;
      letter-spacing: -0.3px;
    }
  }

  &__description {
    margin: 0;
  }

  &__note {
    margin: 21px 0 0;
  }

  &__ctas-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: wrap;
    gap: 17px;

    margin-top: 21px;

    :deep() .el-button--link-white span {
      font-size: 15px;
    }

    :deep() .el-button--blue span {
      font-size: 13px;
      border-radius: 3px;
    }
  }
}
</style>
