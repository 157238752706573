<template>
  <div class="submission-affiliate">
    <div class="submission-affiliate__container">
      <div class="submission-affiliate__head-container">
        <img
          :height="30"
          :src="affiliate.logo"
          alt="Affiliate Logo"
          class="submission-affiliate__logo"
          @click="externalLinkClickHandler"
        />
        <span class="submission-affiliate__title">
          {{ affiliate.name }}
        </span>
      </div>
      <div class="submission-affiliate__date-wrapper">
        <i18n-t
          class="submission-affiliate__date-text"
          keypath="program.submission.submittedAt"
          tag="span"
        >
          <template #date>
            {{ submissionDate }}
          </template>
        </i18n-t>
      </div>
      <PxButton
        class="submission-affiliate__cta"
        v-bind="$options.static.printButton"
        @click.stop="onPrintButtonClick"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import { EPxButtonVariant } from "@/components/px-button/px-button.types";

import { IAffiliate } from "@/services/data/affiliate/affiliate.interface";

export default defineComponent({
  props: {
    affiliate: {
      type: Object as () => IAffiliate,
      required: true,
    },
    submissionDate: {
      type: String,
      required: true,
    },
  },

  static: {
    printButton: {
      variant: EPxButtonVariant.COMPACT,
      icon: "print",
    },
  },

  methods: {
    externalLinkClickHandler() {
      if (this.affiliate && this.affiliate.website) {
        window.open(this.affiliate.website);
      }
    },

    onPrintButtonClick() {
      window.print();
    },
  },
});
</script>

<style lang="scss" scoped>
.submission-affiliate__container {
  display: grid;
  grid-template-columns: auto;
  background-color: $white;
  border-bottom: solid 1px $pale-grey;
  box-shadow: 0 4px 11px 0 rgba(0, 0, 0, 0.06);

  @include breakpoint-up(md) {
    grid-template-columns: auto 1fr;
    grid-column-gap: 16px;
    background-color: transparent;
    border-bottom: none;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    box-shadow: none;
  }
}

.submission-affiliate__head-container {
  display: grid;
  grid-template-columns: auto 1fr;
  padding: 12px 10px;

  @include breakpoint-up(md) {
    position: relative;
    padding: 21px 100px 17px 40px;
    margin-bottom: -1px;
    background: $white;
    border: solid 1px $pale-grey;
    border-top: none;
    border-left: none;
    border-top-left-radius: 4px;
    border-bottom-right-radius: 150px 100px;
    box-shadow: 0 4px 11px 0 rgba(0, 0, 0, 0.06);
  }
}

.submission-affiliate__logo {
  max-width: 200px;
  padding-right: 20px;
  margin-right: 20px;
  cursor: pointer;
  border-right: 1px solid $athens-gray;
}

.submission-affiliate__title {
  @include grotesk(medium);

  max-width: 250px;
  font-size: 20px;
  line-height: 28px;
  color: $manatee;
}

.submission-affiliate__date-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px 15px;

  @include breakpoint-up(md) {
    align-items: flex-start;
    justify-content: flex-end;
    padding: 22px 80px 12px 0;
  }
}

.submission-affiliate__date-dropdown {
  min-width: 100%;

  @include breakpoint-up(md) {
    min-width: 310px;
  }
}

.submission-affiliate__date-text {
  margin-top: 8px;
  margin-right: 1px;
  font-size: 14px;
  font-style: italic;
  color: $manatee;
}

.submission-affiliate__cta {
  position: absolute;
  top: 21px;
  right: 18px;
  min-width: 40px;
  min-height: 40px;
}
</style>
