<template>
  <div class="affiliates-base page-full-height">
    <div
      v-loading="!ready"
      class="page-full-height"
      element-loading-background="#fafafa"
    >
      <template v-if="ready">
        <AffiliateSelfAssessmentView v-if="isSelfAssessmentAffiliateType" />
        <AffiliateQuestionBundleView v-if="isQuestionBundleAffiliateType" />
      </template>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import AffiliateSelfAssessmentView from "@/modules/affiliates/views/affiliate-types/affiliate-self-assessment.view.vue";
import AffiliateQuestionBundleView from "@/modules/affiliates/views/affiliate-types/affiliate-question-bundle.view.vue";

import {
  IAffiliate,
  AFFILIATE_TYPE_SELF_ASSESSMENT,
  AFFILIATE_TARGET_ENTREPRENEUR,
  AFFILIATE_TYPE_PROGRAM,
} from "@/services/data/affiliate/affiliate.interface";
import { ERROR_ROUTE } from "@/services/router/router-names";
import { ERROR_TYPES } from "@/modules/common/components/error-page/constants";
import {
  helpScoutBeaconDestroy,
  helpScoutBeaconInit,
} from "@/services/utils/helpscout";
import { changeLocale, loadedLocales } from "@/services/i18n/locale-utils";
import { ECategoryActions } from "@/services/store/category/category-types";
import { ENTREPRENEUR_USER_GROUP_ID } from "@/modules/common/constants";
import { ELevelActions } from "@/services/store/levels/levels-types";
import {
  SUPPORTER_USER_TYPE,
  ENTREPRENEUR_USER_TYPE,
} from "@/modules/authentication/constants";
import { EViralLevelActions } from "@/services/store/viral-level/viral-level-types";
import { defaultAffiliateId } from "@/services/configs";
import { EAffiliatesActions } from "@/modules/affiliates/services/store/affiliates/affiliates.types";
import { INetwork } from "@/services/data/network/network.interface";
import { AFFILIATE_FORBIDDEN } from "@/modules/affiliates/constants";
import { EMetaActions } from "@/services/store/meta/meta-types";

export default defineComponent({
  name: "AffiliatesBaseView",

  components: {
    AffiliateQuestionBundleView,
    AffiliateSelfAssessmentView,
  },

  data() {
    return {
      ready: false,
    };
  },

  computed: {
    error(): boolean {
      return this.$store.get("affiliates/error");
    },

    affiliate(): IAffiliate | null {
      return this.$store.get("affiliates/affiliate");
    },

    authUser(): boolean {
      return !!this.$store.get("auth/user");
    },

    isSelfAssessmentAffiliateType(): boolean {
      return this.affiliate?.flow_type === AFFILIATE_TYPE_SELF_ASSESSMENT;
    },

    isQuestionBundleAffiliateType(): boolean {
      return this.affiliate?.flow_type === AFFILIATE_TYPE_PROGRAM;
    },

    isEntrepreneurAffiliate(): boolean {
      return this.affiliate?.flow_target === AFFILIATE_TARGET_ENTREPRENEUR;
    },

    isSupporterInWrongFlow(): boolean {
      return this.$user.isSupporter() && this.isEntrepreneurAffiliate;
    },

    isEntrepreneurInWrongFlow(): boolean {
      return this.$user.isEntrepreneur() && !this.isEntrepreneurAffiliate;
    },

    userAccountType(): number {
      return this.$user.getUserAccountType();
    },

    affiliateId(): number | string {
      // Get affiliate id from query params
      const queryAffiliateId =
        this.$route.query &&
        ((this.$route.query.a || this.$route.query.affiliate) as string);

      // If affiliate id is not provided, use default affiliate id
      return queryAffiliateId
        ? queryAffiliateId.toLowerCase()
        : defaultAffiliateId();
    },

    supporterIsOwnerOfAffiliate(): boolean {
      const supporterId = this.$store.get("auth/supporter.data")?.id;
      const supporterNetworks = this.$store.get("auth/company.data")
        ?.networks as Array<INetwork>;

      if (this.affiliate) {
        const affiliateNetworks = this.affiliate.networks;

        const supporterInAffiliateNetwork = affiliateNetworks.some(
          (affiliateNetwork) => {
            return supporterNetworks.find(
              (supporterNetwork) => supporterNetwork.id === affiliateNetwork.id,
            );
          },
        );

        return (
          this.affiliate.supporters.includes(supporterId) ||
          supporterInAffiliateNetwork ||
          false
        );
      }

      return false;
    },
  },

  async beforeUnmount() {
    // Remove HelpScout chat widget
    helpScoutBeaconDestroy();

    if (this.$i18n.locale != "en") {
      // We're reverting the locale back to English as we're only supposed
      // to provide translations in assessment flow pages for the time being.
      await changeLocale("en", loadedLocales, document);

      // Fetch backend translations
      this.$store.dispatch(EViralLevelActions.COMPUTE_LEVEL, {
        skipLoading: true,
      });
      this.$store.dispatch(ECategoryActions.FETCH, {
        group: ENTREPRENEUR_USER_GROUP_ID,
      });
      this.$store.dispatch(ELevelActions.FETCH, {
        group: ENTREPRENEUR_USER_GROUP_ID,
      });
    }
  },

  async created() {
    await this.loadRequiredData();

    // If affiliate with team section and the feature is not enabled, redirect to error page
    if (
      this.affiliate?.show_team_section &&
      !this.$features.isEnabled("teamMembers")
    ) {
      return this.$router.push({ name: ERROR_ROUTE });
    }

    await this.$store.dispatch(EAffiliatesActions.LOAD_SUBMISSION);

    this.ready = true;

    // Show error page if screen is mobile and is not self assessment affiliate type
    if (this.$screen.mdDown && !this.isSelfAssessmentAffiliateType) {
      this.goToErrorPage(ERROR_TYPES.MOBILE);
      return;
    }

    // Show forbidden page if user is authenticated AND
    // is a supporter entering in an affiliate for entrepreneurs AND
    // is owner of the affiliate
    if (
      this.authUser &&
      this.isSupporterInWrongFlow &&
      this.supporterIsOwnerOfAffiliate
    ) {
      this.goToErrorPage(ERROR_TYPES.FORBIDDEN, true);
      return;
    }

    // Show forbidden page if user is authenticated and entering in wrong affiliate
    if (
      this.authUser &&
      (this.isSupporterInWrongFlow || this.isEntrepreneurInWrongFlow)
    ) {
      this.goToErrorPage(ERROR_TYPES.FORBIDDEN);
      return;
    }

    // Set guest user type
    this.$user.setGuestUserType(
      !this.isEntrepreneurAffiliate
        ? SUPPORTER_USER_TYPE
        : ENTREPRENEUR_USER_TYPE,
    );

    // Load HelpScout chat widget
    helpScoutBeaconInit({
      userType: this.userAccountType,
    });

    // Listen for locale change
    this.$emitter.on("locale-changed", async () => {
      this.ready = false;

      await this.loadRequiredData();

      this.ready = true;
    });
  },

  methods: {
    async loadRequiredData() {
      await this.$store.dispatch(
        EAffiliatesActions.LOAD_AFFILIATE,
        this.affiliateId,
      );

      if (this.error) this.goToErrorPage();
    },

    goToErrorPage(
      errorType = ERROR_TYPES.NOT_FOUND,
      supporterForbiddenVariation = false,
    ) {
      this.$store.dispatch(EMetaActions.SET, {
        key: AFFILIATE_FORBIDDEN,
        value: supporterForbiddenVariation,
      });

      this.$router.push({
        name: ERROR_ROUTE,
        query: {
          code: errorType,
        },
      });
    },
  },
});
</script>

<style lang="scss" scoped>
.affiliates-base {
  :deep() .px-footer {
    padding: 0;
  }
}
</style>
