import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-376b46eb"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "company-edit-modal--entrepreneur" }
const _hoisted_2 = { class: "form-grid" }
const _hoisted_3 = { class: "form-grid__logo" }
const _hoisted_4 = { class: "avatar-section" }
const _hoisted_5 = { class: "avatar-section__actions" }
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = { class: "form-grid__left-fields" }
const _hoisted_8 = { class: "form-grid__right-fields" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PxAvatar = _resolveComponent("PxAvatar")!
  const _component_PxButton = _resolveComponent("PxButton")!
  const _component_ElUpload = _resolveComponent("ElUpload")!
  const _component_ElInput = _resolveComponent("ElInput")!
  const _component_ElFormItem = _resolveComponent("ElFormItem")!
  const _component_PxIcon = _resolveComponent("PxIcon")!
  const _component_SectorsSelector = _resolveComponent("SectorsSelector")!
  const _component_PxInputPlaces = _resolveComponent("PxInputPlaces")!
  const _component_PxDateSelect = _resolveComponent("PxDateSelect")!
  const _component_PxTextarea = _resolveComponent("PxTextarea")!
  const _component_ElForm = _resolveComponent("ElForm")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ElForm, {
      ref: "form",
      model: _ctx.form.data,
      rules: _ctx.rules,
      onValidate: _ctx.updateSubmitState
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_ElUpload, {
                ref: "upload",
                "auto-upload": false,
                "on-change": _ctx.onFileChange,
                "show-file-list": false,
                accept: "image/png, image/jpeg"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_PxAvatar, {
                    name: _ctx.form.data.name,
                    photo: _ctx.avatarUrl
                  }, null, 8, ["name", "photo"]),
                  _createElementVNode("div", _hoisted_5, [
                    _createVNode(_component_PxButton, {
                      class: _normalizeClass(_ctx.classesAvatarBtn),
                      type: _ctx.typeAvatarBtn,
                      icon: "camera",
                      size: "small"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t("profile.companyEditModal.logo.button")), 1)
                      ]),
                      _: 1
                    }, 8, ["class", "type"]),
                    _createElementVNode("p", {
                      class: "avatar-section__text",
                      innerHTML: _ctx.$t('profile.companyEditModal.logo.help')
                    }, null, 8, _hoisted_6)
                  ])
                ]),
                _: 1
              }, 8, ["on-change"])
            ])
          ]),
          _createElementVNode("div", _hoisted_7, [
            _createVNode(_component_ElFormItem, {
              label: 
              _ctx.$t(`profile.companyEditModal.fields.name[${_ctx.$profile.type()}]`)
            ,
              prop: "name"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ElInput, {
                  modelValue: _ctx.form.data.name,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.form.data.name) = $event)),
                  placeholder: 
                _ctx.$t(`profile.companyEditModal.fields.name[${_ctx.$profile.type()}]`)
              
                }, null, 8, ["modelValue", "placeholder"])
              ]),
              _: 1
            }, 8, ["label"]),
            _createVNode(_component_ElFormItem, {
              label: _ctx.$t('profile.companyEditModal.fields.website'),
              prop: "website"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ElInput, {
                  ref: "website",
                  modelValue: _ctx.form.data.website,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.form.data.website) = $event)),
                  modelModifiers: { trim: true },
                  placeholder: 
                _ctx.$t('authentication.signup.form.fields.websitePlaceholder')
              
                }, {
                  prefix: _withCtx(() => [
                    _createVNode(_component_PxIcon, {
                      size: 24,
                      name: "website"
                    })
                  ]),
                  _: 1
                }, 8, ["modelValue", "placeholder"])
              ]),
              _: 1
            }, 8, ["label"]),
            _createVNode(_component_ElFormItem, { prop: "sectors" }, {
              default: _withCtx(() => [
                _createVNode(_component_SectorsSelector, {
                  ref: "sectorSelector",
                  modelValue: _ctx.form.data.sectors,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.form.data.sectors) = $event)),
                  onChange: _ctx.handleSectorsChange
                }, null, 8, ["modelValue", "onChange"])
              ]),
              _: 1
            })
          ]),
          _createElementVNode("div", _hoisted_8, [
            (_ctx.form.data.locations)
              ? (_openBlock(), _createBlock(_component_ElFormItem, {
                  key: 0,
                  label: _ctx.$t('profile.companyEditModal.fields.location'),
                  prop: "locations"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_PxInputPlaces, {
                      id: "companyEditLocation",
                      modelValue: _ctx.form.data.locations[0],
                      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.form.data.locations[0]) = $event)),
                      placeholder: _ctx.$t('profile.companyEditModal.fields.location'),
                      "use-geolocation": true
                    }, null, 8, ["modelValue", "placeholder"])
                  ]),
                  _: 1
                }, 8, ["label"]))
              : _createCommentVNode("", true),
            _createVNode(_component_ElFormItem, {
              label: _ctx.$t('profile.companyEditModal.fields.email'),
              prop: "email"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ElInput, {
                  ref: "email",
                  modelValue: _ctx.form.data.email,
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.form.data.email) = $event)),
                  placeholder: _ctx.$t('profile.companyEditModal.fields.email')
                }, null, 8, ["modelValue", "placeholder"])
              ]),
              _: 1
            }, 8, ["label"]),
            _createVNode(_component_ElFormItem, {
              label: _ctx.$t('profile.companyEditModal.fields.founded'),
              prop: "founded_date"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_PxDateSelect, {
                  modelValue: _ctx.form.data.founded_date,
                  "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.form.data.founded_date) = $event))
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }, 8, ["label"]),
            _createVNode(_component_ElFormItem, {
              label: _ctx.$t('profile.companyEditModal.fields.about'),
              prop: "about"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_PxTextarea, {
                  ref: "about",
                  modelValue: _ctx.form.data.about,
                  "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.form.data.about) = $event)),
                  "max-chars": _ctx.rules.about.max,
                  placeholder: _ctx.$t('profile.companyEditModal.placeholders.about'),
                  "show-counter": ""
                }, null, 8, ["modelValue", "max-chars", "placeholder"])
              ]),
              _: 1
            }, 8, ["label"])
          ])
        ])
      ]),
      _: 1
    }, 8, ["model", "rules", "onValidate"])
  ]))
}