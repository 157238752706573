import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, withCtx as _withCtx, resolveDirective as _resolveDirective, withDirectives as _withDirectives, withModifiers as _withModifiers, mergeProps as _mergeProps, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-01daaae0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "panel-grid__block" }
const _hoisted_2 = { class: "profile-headline" }
const _hoisted_3 = { class: "profile-photo" }
const _hoisted_4 = { class: "profile-headline__text-wrapper" }
const _hoisted_5 = { class: "profile-headline__title" }
const _hoisted_6 = { class: "profile-headline__subtitle" }
const _hoisted_7 = {
  key: 0,
  class: "profile-headline__pin-item"
}
const _hoisted_8 = { class: "profile-headline__cta" }
const _hoisted_9 = { class: "profile-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CompanyEditModal = _resolveComponent("CompanyEditModal")!
  const _component_CompanyPanelPlaceholder = _resolveComponent("CompanyPanelPlaceholder")!
  const _component_PxAvatar = _resolveComponent("PxAvatar")!
  const _component_PxMatchingInterestTip = _resolveComponent("PxMatchingInterestTip")!
  const _component_PxButton = _resolveComponent("PxButton")!
  const _component_PxMatchingInterest = _resolveComponent("PxMatchingInterest")!
  const _component_CompanyPanelEntrepreneur = _resolveComponent("CompanyPanelEntrepreneur")!
  const _component_CompanyPanelSupporter = _resolveComponent("CompanyPanelSupporter")!
  const _component_PxPanel = _resolveComponent("PxPanel")!
  const _directive_feature = _resolveDirective("feature")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.$user.isOwner())
      ? (_openBlock(), _createBlock(_component_CompanyEditModal, {
          key: 0,
          ref: "companyEditModal",
          visibility: _ctx.isCompanyEditModalVisible,
          "onUpdate:visibility": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.isCompanyEditModalVisible) = $event))
        }, null, 8, ["visibility"]))
      : _createCommentVNode("", true),
    (_ctx.isLoading)
      ? (_openBlock(), _createBlock(_component_CompanyPanelPlaceholder, { key: 1 }))
      : (_ctx.company)
        ? (_openBlock(), _createBlock(_component_PxPanel, {
            key: 2,
            class: "profile-panel profile-panel--company px-panel--xs-no-side-borders"
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_2, [
                _createElementVNode("div", _hoisted_3, [
                  _createVNode(_component_PxAvatar, {
                    name: _ctx.company.name,
                    photo: _ctx.company.logo,
                    size: _ctx.avatarSize
                  }, null, 8, ["name", "photo", "size"])
                ]),
                _createElementVNode("div", _hoisted_4, [
                  _createElementVNode("h2", _hoisted_5, _toDisplayString(_ctx.company.name), 1),
                  _createElementVNode("h3", _hoisted_6, [
                    _createTextVNode(_toDisplayString(_ctx.companyType) + " ", 1),
                    (_ctx.company.locations && _ctx.company.locations.length)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(_ctx.formattedLocation), 1))
                      : _createCommentVNode("", true)
                  ]),
                  (_ctx.isMatchingEnabled)
                    ? (_openBlock(), _createBlock(_component_PxMatchingInterestTip, {
                        key: 0,
                        id: _ctx.companyId,
                        name: _ctx.company.name
                      }, null, 8, ["id", "name"]))
                    : _createCommentVNode("", true)
                ]),
                _createElementVNode("div", _hoisted_8, [
                  (_ctx.$user.isOwner())
                    ? (_openBlock(), _createBlock(_component_PxButton, {
                        key: 0,
                        class: "profile-headline__cta--edit-info",
                        size: "small",
                        onClick: _ctx.onClickEdit
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t(`profile.company.editButton[${_ctx.$profile.type()}]`)), 1)
                        ]),
                        _: 1
                      }, 8, ["onClick"]))
                    : _createCommentVNode("", true),
                  (_ctx.$user.isOwner() && _ctx.$screen.mdUp)
                    ? _withDirectives((_openBlock(), _createBlock(_component_PxButton, {
                        key: 1,
                        class: "profile-headline__cta--team-members",
                        size: "small",
                        type: "link",
                        onClick: _ctx.goToTeamMembersManagementPage
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t(`profile.company.editTeamMembers`)), 1)
                        ]),
                        _: 1
                      }, 8, ["onClick"])), [
                        [_directive_feature, 'teamMembers']
                      ])
                    : (_ctx.showMatchingCta)
                      ? (_openBlock(), _createBlock(_component_PxMatchingInterest, {
                          key: 2,
                          id: _ctx.companyId,
                          name: _ctx.company.name,
                          "with-description": false
                        }, null, 8, ["id", "name"]))
                      : _createCommentVNode("", true),
                  (_ctx.canMatch && _ctx.showAddToListButton)
                    ? (_openBlock(), _createBlock(_component_PxButton, _mergeProps({
                        key: 3,
                        class: "px-matching-interest__cta--addToList"
                      }, _ctx.addToListButtonProps, {
                        onClick: _withModifiers(_ctx.showListModal, ["stop"])
                      }), null, 16, ["onClick"]))
                    : _createCommentVNode("", true)
                ])
              ]),
              _createElementVNode("div", _hoisted_9, [
                (_ctx.$profile.isEntrepreneur())
                  ? (_openBlock(), _createBlock(_component_CompanyPanelEntrepreneur, {
                      key: 0,
                      company: _ctx.company,
                      "user-can-match": _ctx.canMatch,
                      "user-is-connected": _ctx.isConnected,
                      "user-is-interested": _ctx.isInterested,
                      onFocusModalField: _ctx.onFocusCompanyModalField,
                      onOpenCompanyModal: _ctx.onOpenCompanyModal
                    }, null, 8, ["company", "user-can-match", "user-is-connected", "user-is-interested", "onFocusModalField", "onOpenCompanyModal"]))
                  : _createCommentVNode("", true),
                (_ctx.$profile.isSupporter())
                  ? (_openBlock(), _createBlock(_component_CompanyPanelSupporter, {
                      key: 1,
                      company: _ctx.company,
                      "user-can-match": _ctx.canMatch,
                      "user-is-connected": _ctx.isConnected,
                      "user-is-interested": _ctx.isInterested,
                      onFocusModalField: _ctx.onFocusCompanyModalField,
                      onShowModalView: _ctx.onShowModalView
                    }, null, 8, ["company", "user-can-match", "user-is-connected", "user-is-interested", "onFocusModalField", "onShowModalView"]))
                  : _createCommentVNode("", true)
              ])
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
  ]))
}