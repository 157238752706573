export const SUPPORTER_USER_GROUP_ID = 1;
export const ENTREPRENEUR_USER_GROUP_ID = 2;

export const EVENT_GO_TO_AFFILIATE_FLOW_ASSESSMENT =
  "go-to-affiliate-flow-assessment";
export const EVENT_GO_TO_SELF_ASSESSMENT = "go-to-self-assessment";

export const QUICK_LEVEL_UPDATE_META_ON_UPDATE = "quickLevelOnUpdate";
export const QUICK_LEVEL_UPDATE_META_UPGRADE = "quickLevelWasUpgrade";
export const QUICK_LEVEL_UPDATE_META_SAME_LEVEL = "quickLevelWasNotChanged";
export const QUICK_LEVEL_UPDATE_META_FIRST_ASSESSMENT =
  "quickLevelFirstAssessment";
export const QUICK_LEVEL_FROM = "quickLevelUpdateFrom";
