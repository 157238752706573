import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-57909628"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "profile-panel__row assessment-grid" }
const _hoisted_2 = { class: "profile-headline" }
const _hoisted_3 = { class: "profile-headline__left" }
const _hoisted_4 = { class: "profile-headline__title" }
const _hoisted_5 = {
  key: 0,
  class: "profile-headline__right"
}
const _hoisted_6 = {
  key: 0,
  class: "assessment-grid__chart-caption"
}
const _hoisted_7 = { class: "assessment-grid__date" }
const _hoisted_8 = {
  key: 1,
  class: "assessment-grid__results"
}
const _hoisted_9 = { class: "results-report-entry__number" }
const _hoisted_10 = { class: "results-report-entry__name" }
const _hoisted_11 = {
  key: 2,
  class: "assessment-grid__milestone-planner-action"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PxIcon = _resolveComponent("PxIcon")!
  const _component_PxButton = _resolveComponent("PxButton")!
  const _component_PxGrid = _resolveComponent("PxGrid")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h2", _hoisted_4, _toDisplayString(_ctx.$t("profile.assessment.gridPanel.title")), 1),
        _createVNode(_component_PxIcon, {
          size: 22,
          class: "profile-headline__how-it-works-trigger",
          name: "information--light",
          onClick: _ctx.onClickOpenHowItWorksLink
        }, null, 8, ["onClick"])
      ]),
      (_ctx.$user.isOwner() && _ctx.$screen.mdUp)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createVNode(_component_PxButton, _mergeProps({ class: "profile-headline__quick-update-button" }, _ctx.quickUpdateButtonProps, { onClick: _ctx.onQuickUpdateClick }), null, 16, ["onClick"]),
            (_ctx.isMilestonePlannerModuleActive)
              ? (_openBlock(), _createBlock(_component_PxButton, _mergeProps({ key: 0 }, _ctx.$options.static.showFullGridButton, {
                  ref: "showFullGridButton",
                  onClick: _ctx.showFullGrid
                }), null, 16, ["onClick"]))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true)
    ]),
    _createVNode(_component_PxGrid, {
      categories: _ctx.categories,
      "current-level": _ctx.levelValue,
      levels: _ctx.numOfLevels,
      results: _ctx.results,
      size: _ctx.$screen.smUp ? 'medium' : 'small',
      class: "assessment-grid__chart"
    }, null, 8, ["categories", "current-level", "levels", "results", "size"]),
    (_ctx.hasUserAccess)
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
          _createElementVNode("time", _hoisted_7, _toDisplayString(_ctx.latestAssessmentDate), 1)
        ]))
      : _createCommentVNode("", true),
    (_ctx.hasUserAccess && _ctx.levelForCategory)
      ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.categories, (category) => {
            return (_openBlock(), _createElementBlock("div", {
              key: category.id,
              class: _normalizeClass([{
          'results-report-entry--not-achieved':
            _ctx.levelForCategory(category) === 0,
        }, "results-report-entry"])
            }, [
              _createElementVNode("div", {
                style: _normalizeStyle({ backgroundColor: _ctx.getCategoryColor(category) }),
                class: "results-report-entry__bullet"
              }, null, 4),
              _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.levelForCategory(category) || 0), 1),
              _createElementVNode("span", _hoisted_10, _toDisplayString(category.name), 1)
            ], 2))
          }), 128))
        ]))
      : _createCommentVNode("", true),
    (_ctx.$user.isOwner() && _ctx.$screen.mdUp && _ctx.isMilestonePlannerModuleActive)
      ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
          _createVNode(_component_PxButton, {
            size: "small",
            type: "green",
            onClick: _ctx.goToMilestonePlannerPage
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("profile.assessment.gridPanel.milestonePlanner.cta")), 1)
            ]),
            _: 1
          }, 8, ["onClick"]),
          _createElementVNode("p", null, _toDisplayString(_ctx.$t("profile.assessment.gridPanel.milestonePlanner.description")), 1)
        ]))
      : _createCommentVNode("", true)
  ]))
}