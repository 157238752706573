<template>
  <div class="milestone-level-navigation">
    <ElPopover
      v-for="milestone in milestonesSortedByLevel"
      :key="milestone.level"
      :close-delay="0"
      :data-testid="`milestone-level-navigation-${milestone.level}-popover`"
      placement="right"
      popper-class="px-popover--light milestone-level-navigation-popover milestone-cell-popover"
    >
      <MilestoneTooltip
        :category-color="categoryColor"
        :category-name="categoryName"
        :is-future-milestone="milestone.future"
        :is-milestone-complete="milestone.completed"
        :is-milestone-in-progress="milestone.inProgress"
        :is-milestone-planned="milestone.planned"
        :level-number="milestone.level"
        :milestone-description="milestone.description"
        :simplified="true"
      />
      <template #reference>
        <MilestoneLevelCell
          :active="selectedLevel === milestone.level"
          :color="categoryColor"
          :data-testid="`milestone-level-cell-${milestone.level}`"
          :has-evidence="milestone.evidenceProvided"
          :is-future-milestone="milestone.future"
          :is-milestone-complete="milestone.completed"
          :is-milestone-critical="milestone.critical"
          :is-milestone-in-progress="milestone.inProgress"
          :is-milestone-planned="milestone.planned"
          :number="milestone.level"
          @click="changeLevel(milestone.level)"
        />
      </template>
    </ElPopover>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import MilestoneLevelCell from "@/modules/milestone-planner/components/milestone-level-cell/milestone-level-cell.vue";
import MilestoneTooltip from "@/modules/milestone-planner/components/milestone-tooltip/milestone-tooltip.vue";
import { IGridMilestone } from "@/modules/milestone-planner/components/milestones-grid/milestones-grid.interface";

export default defineComponent({
  name: "MilestoneLevelNavigation",

  components: {
    MilestoneLevelCell,
    MilestoneTooltip,
  },

  props: {
    categoryColor: {
      type: String,
      required: true,
    },
    categoryName: {
      type: String,
      required: true,
    },
    activeLevel: {
      type: Number,
      required: true,
    },
    milestones: {
      type: Array as () => IGridMilestone[],
      required: true,
    },
  },

  data() {
    return {
      selectedLevel: this.activeLevel,
    };
  },

  computed: {
    milestonesSortedByLevel(): Array<any> {
      return [...this.milestones].sort((a, b) => b.level - a.level);
    },
  },

  watch: {
    activeLevel: function (level) {
      this.selectedLevel = level;
    },
  },

  methods: {
    changeLevel(level: number) {
      if (level !== this.selectedLevel) {
        this.selectedLevel = level;
        this.$emit("change", level);
      }
    },
  },
});
</script>
<style lang="scss">
.milestone-level-navigation {
  box-sizing: content-box;
  width: 80px;
  padding: 0 28px;

  &-popover[data-popper-placement^="right"] {
    margin-top: -1px;
  }

  .milestone-tooltip__header {
    gap: 12px;

    .level-number {
      right: 5px;
    }

    .category-name {
      position: relative;
      right: 10px;
    }
  }

  .milestone-tooltip__content {
    padding: 15px 13px 17px;
  }
}
</style>
