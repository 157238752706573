import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-3112fc60"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "affiliate-review-page-team-members-details" }
const _hoisted_2 = { class: "affiliate-review-page-team-members-details__wrapper" }
const _hoisted_3 = { class: "affiliate-review-page-team-members-details__headers" }
const _hoisted_4 = { class: "affiliate-review-page-team-members-details__info" }
const _hoisted_5 = ["title"]
const _hoisted_6 = ["title"]
const _hoisted_7 = ["title"]
const _hoisted_8 = ["title"]
const _hoisted_9 = { class: "affiliate-review-page-team-members-details__bottom" }
const _hoisted_10 = {
  key: 0,
  class: "affiliate-review-page-team-members-details__questions-wrapper"
}
const _hoisted_11 = ["title"]
const _hoisted_12 = ["title", "innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PxStatusBadge = _resolveComponent("PxStatusBadge")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      style: _normalizeStyle({
        'border-bottom':
          _ctx.questions && _ctx.questions.length ? '1px solid #f0f1f5' : 'none',
      }),
      class: "affiliate-review-page-team-members-details__top"
    }, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("h3", null, _toDisplayString(_ctx.$t("teamManagement.settings.mandatoryData.firstName")), 1),
          _createElementVNode("h3", null, _toDisplayString(_ctx.$t("teamManagement.settings.mandatoryData.lastName")), 1),
          _createElementVNode("h3", null, _toDisplayString(_ctx.$t("teamManagement.settings.mandatoryData.position")), 1),
          _createElementVNode("h3", null, _toDisplayString(_ctx.$t("common.email")), 1)
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("p", {
            class: _normalizeClass([{
              'affiliate-review-page-team-members-details__missing-info':
                !_ctx.teamMember.first_name,
            }, "affiliate-review-page-team-members-details__info-content"]),
            title: _ctx.firstName
          }, _toDisplayString(_ctx.firstName), 11, _hoisted_5),
          _createElementVNode("p", {
            class: _normalizeClass([{
              'affiliate-review-page-team-members-details__missing-info':
                !_ctx.teamMember.last_name,
            }, "affiliate-review-page-team-members-details__info-content"]),
            title: _ctx.lastName
          }, _toDisplayString(_ctx.lastName), 11, _hoisted_6),
          _createElementVNode("p", {
            class: _normalizeClass([{
              'affiliate-review-page-team-members-details__missing-info':
                !_ctx.teamMember.position,
            }, "affiliate-review-page-team-members-details__info-content"]),
            title: _ctx.position
          }, _toDisplayString(_ctx.position), 11, _hoisted_7),
          _createElementVNode("p", {
            class: _normalizeClass([{
              'affiliate-review-page-team-members-details__missing-info':
                !_ctx.teamMember.email,
            }, "affiliate-review-page-team-members-details__info-content"]),
            title: _ctx.email
          }, _toDisplayString(_ctx.email), 11, _hoisted_8)
        ])
      ]),
      (_ctx.label)
        ? (_openBlock(), _createBlock(_component_PxStatusBadge, {
            key: 0,
            "background-color": _ctx.label.backgroundColor,
            icon: _ctx.label.icon,
            "icon-size": _ctx.label.iconSize,
            label: _ctx.label.text,
            "label-color": _ctx.label.textColor,
            "label-size": 13,
            uppercase: false,
            class: "affiliate-review-page-team-members-details__label",
            "icon-position": "left"
          }, null, 8, ["background-color", "icon", "icon-size", "label", "label-color"]))
        : _createCommentVNode("", true)
    ], 4),
    _createElementVNode("div", _hoisted_9, [
      (_ctx.questions && _ctx.questions.length)
        ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.questions, (question) => {
              return (_openBlock(), _createElementBlock("div", {
                key: question.id,
                class: "affiliate-review-page-team-members-details__question"
              }, [
                _createElementVNode("h3", {
                  title: question.entrepreneur_question
                }, _toDisplayString(question.entrepreneur_question), 9, _hoisted_11),
                _createElementVNode("p", {
                  title: _ctx.formattedResponse(question),
                  innerHTML: _ctx.formattedResponse(question)
                }, null, 8, _hoisted_12)
              ]))
            }), 128))
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}