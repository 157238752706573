<template>
  <div class="share-planner-actions">
    <h3 class="share-planner-actions__title" v-text="shareModalTitle" />
    <i18n-t
      :keypath="`milestonePlanner.shareModal.footer.subtitle.content[${isRestrictedByPasscode}]`"
      class="share-planner-actions__subtitle"
      tag="p"
    >
      <template #link>
        <strong v-text="shareModalCopy[0]" />
      </template>
      <template #name>
        <strong class="text--capitalize" v-text="shareModalCopy[1]" />
      </template>
      <template #email>
        <strong v-text="shareModalCopy[2]" />
      </template>
      <template #password>
        <strong v-text="shareModalCopy[3]" />
      </template>
    </i18n-t>
    <PxButton
      class="share-planner-actions__link"
      size="small"
      type="link"
      @click="onClickSettingHandler"
    >
      {{ settingsLinkCopy }}
    </PxButton>
    <div class="share-planner-actions__buttons">
      <ClipboardButton
        v-if="isRestrictedByPasscode"
        data-testid="share-planner-actions__copy-passcode"
        v-bind="clipboardButtons[0]"
      />
      <ClipboardButton
        v-bind="clipboardButtons[1]"
        data-testid="share-planner-actions__copy-link"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import ClipboardButton from "@/components/clipboard-button/clipboard-button.vue";
export default defineComponent({
  name: "SharePlannerActions",

  components: {
    ClipboardButton,
  },

  props: {
    link: {
      type: String,
      required: true,
    },

    passcode: {
      type: String,
      required: true,
    },
  },

  computed: {
    settingsLinkCopy() {
      return this.$t("companyLists.list.linkSettings");
    },

    shareModalTitle() {
      return this.$t("milestonePlanner.shareModal.footer.title");
    },

    shareModalCopy() {
      return this.$tm(
        "milestonePlanner.shareModal.footer.subtitle.placeholders",
      ) as string[];
    },

    isRestrictedByPasscode(): number {
      // Convert passcode length to a numeric boolean (empty = 0 / not-empty = 1)
      return +!!this.passcode.length;
    },

    clipboardButtons() {
      return [
        {
          clipboard: this.passcode,
          buttonLabel: this.$t("common.copyPasscode"),
          tooltipCopy: this.$t("common.copiedToClipboard"),
          buttonIcon: "password-lock",
        },
        {
          clipboard: this.link,
          buttonLabel: this.$t("common.copyLink"),
          tooltipCopy: this.$t("common.copiedToClipboard"),
        },
      ];
    },
  },

  methods: {
    onClickSettingHandler() {
      this.$emitter.emit("planner-settings", true);
    },
  },
});
</script>

<style lang="scss">
.share-planner-actions {
  display: grid;
  grid-template-areas:
    "title link"
    "subtitle buttons";
  grid-template-rows: auto auto;
  grid-template-columns: 51% max-content;
  align-items: flex-end;
  justify-content: space-between;

  &__title {
    @include grotesk(semiBold);

    grid-area: title;
    margin-bottom: 5px;
    font-size: 12px;
    color: $gun-powder;
    text-transform: uppercase;
  }

  &__subtitle {
    grid-area: subtitle;
    margin-bottom: 0;
    font-size: 14px;
    line-height: 20px;
    color: $manatee;
    letter-spacing: -0.25px;
    white-space: pre-wrap;
  }

  &__link {
    top: 2px;
    right: 5px;
    grid-area: link;
    justify-self: end;
  }

  &__buttons {
    grid-area: buttons;

    .el-button {
      padding: 0 13px;
    }

    .el-button + .el-button {
      margin-right: 4px;
      margin-left: 11px;
    }

    .el-button span {
      font-size: 12px;
    }
  }
}
</style>
