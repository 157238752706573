<template>
  <div class="results-board__cta-panel">
    <div class="results-board__headline">
      <h1 class="results-board__headline-title">
        <PxIcon
          :name="`icon-check-green-white`"
          :size="18"
          class="results-board__headline-icon"
        />
        {{ $t("supporters.results.confirmationPanel.title") }}
      </h1>
      <i18n-t
        class="results-board__headline-content"
        keypath="supporters.results.confirmationPanel.sentInfo"
        tag="p"
      >
        <template #company>
          <u>
            {{ affiliateShortcode }}
          </u>
        </template>
      </i18n-t>
    </div>
    <i18n-t
      class="results-board__title"
      keypath="supporters.results.confirmationPanel.subtitle.content"
      tag="h2"
    >
      <template #highlight>
        <mark>
          {{ $t("supporters.results.confirmationPanel.subtitle.highlight") }}
        </mark>
      </template>
    </i18n-t>
    <ul class="results-board__list">
      <li v-for="(item, index) in memberBenefits" :key="index">
        {{ item }}
      </li>
    </ul>
    <div class="results-board__footer">
      <PxButton
        class="results-board__join-btn"
        size="large"
        type="green"
        @click="onClickSignUp"
      >
        {{ $t("supporters.results.confirmationPanel.button") }}
      </PxButton>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import get from "lodash/get";

import { IAffiliate } from "@/services/data/affiliate/affiliate.interface";

export default defineComponent({
  name: "ResultsBoardJoin",

  computed: {
    affiliate(): IAffiliate | null {
      return this.$store.state.affiliate.value;
    },

    affiliateShortcode(): string {
      return get(this, "affiliate.shortcode", "");
    },

    /**
     * List of member benefits.
     */
    memberBenefits() {
      return this.$tm(
        "supporters.results.confirmationPanel.list",
      ) as Array<string>;
    },
  },

  methods: {
    onClickSignUp() {
      // No data to submit hence why we're sending null.
      this.$emitter.emit("next-page", null);
    },
  },
});
</script>

<style lang="scss" scoped>
.results-board .results-board__cta-panel {
  flex-grow: 1;
  flex-shrink: 1;
  padding: 25px 20px 16px;
  margin: 0 15px;
  overflow: hidden;

  text-align: center;
  background-image: linear-gradient(
    to bottom,
    rgba(123, 183, 255, 0.05),
    rgba(123, 183, 255, 0.19)
  );
  border: solid 1px rgba(123, 183, 255, 0.2);
  border-radius: 2px;

  @include breakpoint-up(md) {
    max-height: calc(100% - 40px);
    padding: 149px 20px 34px;
    margin: 0 30px;
  }

  @include breakpoint-up(lg) {
    position: relative;
    top: 20px;
    left: -7px;
    display: flex;
    flex-flow: row wrap;
    align-content: center;
    justify-content: center;
    margin: 0;
  }

  .results-board__title {
    font-size: 22px;
    line-height: 36px;

    @include breakpoint-up(lg) {
      padding: 0 25px;
    }

    mark {
      position: relative;

      color: currentColor;
      background-color: transparent;
    }

    mark::before {
      position: absolute;
      top: 1px;
      left: -5px;
      z-index: z("bellow");
      width: calc(100% + 70px);
      height: 100%;

      content: "";
      background-image: url("#{$assetsPath}/img/text-marker.svg");
      opacity: 0.5;
    }
  }

  .results-board__list {
    max-width: 290px;
    padding: 0;
    margin: 30px auto 20px;

    @include breakpoint-up(md) {
      display: inline-flex;
      flex-flow: column wrap;
      max-width: none;
    }

    @include breakpoint-up(lg) {
      max-width: 386px;
      margin: 24px auto 28px;
    }
  }

  .results-board__headline {
    @include grotesk(semiBold);

    position: absolute;
    top: 19px;
    right: 0;
    left: 0;
    padding: 0 20px;
    margin: auto;

    &::after {
      position: absolute;
      right: -999px;
      bottom: -22px;
      left: -999px;
      width: 100vw;
      height: 1px;
      margin: auto;

      content: "";
      background-color: rgba($malibu, 0.2);
    }

    .px-icon {
      margin-right: 5px;
      vertical-align: -3px;
      background-color: $fern;
      border-radius: 100%;
    }
  }

  .results-board__headline-title {
    font-size: 22px;
    line-height: 32px;
    color: $fern;
  }

  .results-board__headline-content {
    @include grotesk(semiBold);

    margin-top: 10px;
    font-size: 16px;
    line-height: 19px;
    color: $ebony-clay;

    span {
      @extend .results-board__headline-content;

      text-decoration: underline;
    }
  }

  .results-board__join-btn {
    height: 42px;

    @include breakpoint-up(md) {
      height: 43px;
      margin-top: 13px;
    }

    :deep() span {
      font-size: to-rem(14px);
      line-height: to-rem(17px);

      @include breakpoint-up(md) {
        font-size: to-rem(16px);
        line-height: to-rem(21px);
      }
    }
  }
}
</style>
