import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-6ecf6105"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "level-update-modal-levels-example" }
const _hoisted_2 = { class: "level-update-modal-levels-example__wrapper" }
const _hoisted_3 = { class: "level-update-modal-levels-example__title" }
const _hoisted_4 = { class: "level-update-modal-levels-example__main-content" }
const _hoisted_5 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PxButton = _resolveComponent("PxButton")!
  const _component_PxNavigationTab = _resolveComponent("PxNavigationTab")!
  const _component_LevelUpdateModalFirstTab = _resolveComponent("LevelUpdateModalFirstTab")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", null, [
        _createElementVNode("h1", _hoisted_3, _toDisplayString(_ctx.modalTitle), 1),
        _createVNode(_component_PxButton, {
          ref: "backButton",
          class: "el-button--link-dark",
          size: "small",
          type: "link",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emitter.emit('change-level-example-modal-visibility')))
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t("common.back")), 1)
          ]),
          _: 1
        }, 512)
      ]),
      _createVNode(_component_PxNavigationTab, {
        "selected-tab": _ctx.innerActiveTab,
        "onUpdate:selectedTab": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.innerActiveTab) = $event)),
        "navigation-tabs": _ctx.navigationTabs,
        "show-number": false
      }, null, 8, ["selected-tab", "navigation-tabs"])
    ]),
    _createElementVNode("div", _hoisted_4, [
      (_ctx.innerActiveTab === 1)
        ? (_openBlock(), _createBlock(_component_LevelUpdateModalFirstTab, {
            key: 0,
            "current-level": _ctx.currentLevel,
            "level-up": _ctx.isLevelUp,
            "new-level": _ctx.newLevel,
            "same-level": _ctx.isSameLevel
          }, null, 8, ["current-level", "level-up", "new-level", "same-level"]))
        : (_openBlock(), _createElementBlock("img", {
            key: 1,
            src: _ctx.tabImage,
            alt: "assessment-results"
          }, null, 8, _hoisted_5))
    ])
  ]))
}