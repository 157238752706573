<template>
  <ElDialog
    ref="modal"
    v-model="innerVisibility"
    :append-to-body="true"
    :before-close="beforeCloseHandler"
    :class="{ 'share-planner-modal--users': !settingsVisibility }"
    :title="shareModalTitle"
    class="share-planner-modal"
    width="659px"
  >
    <DialogConfirmation
      ref="dialog-confirmation"
      class="share-planner-modal__overlay-confirm"
    />
    <PxModalMessage
      :visible="hasServerError"
      data-testid="share-planner-modal-error-message"
    />
    <PxModalMessage
      v-model:visible="linkWasReset"
      :timeout="5000"
      :title="$t(`companyLists.list.shareModal.messages.linkChanged`)"
      data-testid="share-planner-modal-reset-link-message"
      type="success"
    />
    <PxModalMessage
      v-model:visible="passcodeWasChanged"
      :timeout="5000"
      :title="$t(`companyLists.list.shareModal.messages.passcodeChanged`)"
      data-testid="share-planner-modal-reset-passcode-message"
      type="success"
    />
    <PxModalMessage
      v-model:visible="userHasBeenRemoved"
      :timeout="5000"
      :title="successUserRemovalMessage"
      data-testid="share-planner-modal-unshare-user-message"
      type="success"
    />
    <template #header>
      <span class="el-dialog__title" v-text="shareModalTitle" />
    </template>
    <div class="share-planner-modal__body">
      <div v-if="!settingsVisibility" class="share-planner-modal__header">
        <h3
          class="share-planner-modal__subtitle"
          v-text="shareModalUsersCopy.title"
        />
        <p
          v-if="users.length"
          class="share-planner-modal__description"
          v-text="shareModalUsersCopy.description"
        />
      </div>
      <SharePlannerUsers
        v-if="!settingsVisibility"
        :link="shareableLink"
        :passcode="passcode"
        :users="users"
        data-testid="share-planner-modal__users"
      />
      <SharePlannerSettings
        v-if="settingsVisibility"
        :link="shareableLink"
        :passcode="passcode"
        class="share-planner-modal__appendix"
        data-testid="share-planner-modal__settings"
        @save="saveSettingsHandler"
      />
    </div>
  </ElDialog>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import ElDialogMixin from "@/mixins/el-dialog.mixin";
import DialogConfirmationMixin from "@/mixins/dialog-confirmation.mixin";

import { SHARE_PLANNER_EVENTS } from "@/modules/milestone-planner/components/share-planner/share-planner-modal.types";

import SharePlannerUsers from "@/modules/milestone-planner/components/share-planner/share-planner-users.vue";
import SharePlannerSettings from "@/modules/milestone-planner/components/share-planner/share-planner-settings.vue";
import { ROUTE_MILESTONE_PLANNER_OVERVIEW_VISITORS } from "@/modules/milestone-planner/services/router/routes-names";
import { EAuthMilestonePlannersActions } from "@/modules/authentication/services/store/auth/sub-modules/auth-milestone-planners/auth-milestone-planners.types";
import { AxiosError } from "axios";
import { IMilestonePlannerInvitedUser } from "@/modules/milestone-planner/services/store/milestone-planner/milestone-planner.types";
import { ICompanyListInvitedGuest } from "@/modules/company-lists/services/data/company-list/company-list.interface";

export default defineComponent({
  name: "SharePlannerModal",

  components: {
    SharePlannerUsers,
    SharePlannerSettings,
  },

  mixins: [ElDialogMixin, DialogConfirmationMixin],

  props: {
    /**
     * Represents the planner unique identifiable
     * slug used for the shareable link.
     */
    uid: {
      type: String,
      required: true,
    },

    /**
     * Optional passcode where empty means
     * having no protection.
     */
    passcode: {
      type: String,
      default: "",
      required: true,
    },

    users: {
      type: Array as () => Array<
        IMilestonePlannerInvitedUser | ICompanyListInvitedGuest
      >,
      required: true,
    },
  },

  data() {
    return {
      settingsVisibility: false,
      hasServerError: false,
      linkWasReset: false,
      passcodeWasChanged: false,
      userToRemove: null as null | IMilestonePlannerInvitedUser,
      userHasBeenRemoved: false,
    };
  },

  computed: {
    shareModalTitle() {
      return this.$t("companyLists.list.shareModal.title");
    },

    shareModalUsersCopy() {
      return this.$tm("milestonePlanner.shareModal.users") as {
        title: string;
        description: string;
        tip: string;
        formOwner: string;
        empty: string;
      };
    },

    shareableLink(): string {
      const linkPath = this.$router.resolve({
        name: ROUTE_MILESTONE_PLANNER_OVERVIEW_VISITORS,
        params: { uid: this.uid },
      }).href;

      return location.origin + linkPath;
    },

    confirmationDialogs(): any {
      return [
        {
          event: SHARE_PLANNER_EVENTS.RESET_SHAREABLE_LINK,
          key: "resetLink",
          callback: () => this.onConfirmResetLink(),
        },
        {
          event: SHARE_PLANNER_EVENTS.RESET_PASSCODE,
          key: "resetPasscode",
          callback: () => this.onConfirmResetPasscode(),
        },
        {
          event: SHARE_PLANNER_EVENTS.UNSHARE_FROM_USER,
          key: "unshareFromUser",
          callback: () => this.onConfirmUnshare(),
        },
      ];
    },

    dialogConfirmationOptions(): any {
      return this.confirmationDialogs.map((confirmation: any) => ({
        eventName: confirmation.event,
        message: this.$t(
          `milestonePlanner.shareModal.confirmationDialogs.${confirmation.key}.title`,
          {
            username: this.userToRemove?.name,
          },
        ),
        tip: this.$t(
          `milestonePlanner.shareModal.confirmationDialogs.${confirmation.key}.subtitle`,
          {
            username: this.userToRemove?.name,
          },
        ),

        confirmButtonText: this.$t(
          `milestonePlanner.shareModal.confirmationDialogs.${confirmation.key}.cta`,
        ),
        cancelButtonText: this.$t("common.cancel"),
        confirmFn: confirmation.callback,
      }));
    },

    milestonePlannersErrors(): AxiosError {
      return this.$store.get("auth/milestonePlanners.error");
    },

    successUserRemovalMessage(): string {
      return this.$t(`milestonePlanner.shareModal.accessRemoved`, {
        user: this.userToRemove?.name,
      }) as string;
    },
  },

  watch: {
    innerVisibility(isVisible) {
      // Workaround since mounted and destroyed
      // hooks are not called when modals get toggled.
      if (isVisible) {
        this.listenForGlobalSettingsEvent();
        this.listenForGlobalConfirmationEvents();
      } else {
        this.disableGlobalSettingsEvent();
        this.disableGlobalConfirmationEvents();
      }
    },
  },

  methods: {
    beforeCloseHandler() {
      this.$emit("update:visibility", false);
      this.settingsVisibility = false;
    },

    listenForGlobalSettingsEvent() {
      this.$emitter.on("planner-settings", (visibility: boolean) => {
        this.settingsVisibility = visibility;
      });
    },

    listenForGlobalConfirmationEvents() {
      // Workaround for the dialog confirmation
      // directive to work with global events:
      this.$emitter.on(
        "share-planner-modal-confirmation",
        ({
          event,
          payload,
        }: {
          event: string;
          payload: { [key: string]: IMilestonePlannerInvitedUser };
        }) => {
          this.userToRemove = payload && payload.user;

          this.showOverlay(event);
        },
      );
    },

    showOverlay(event: string) {
      const options = this.dialogConfirmationOptions.find(
        (confirmation: any) => confirmation.eventName === event,
      );

      this.showConfirmOverlay(options);
    },

    disableGlobalSettingsEvent() {
      this.$emitter.off("planner-settings");
    },

    disableGlobalConfirmationEvents() {
      this.$emitter.off("share-planner-modal-confirmation");
    },

    onConfirmResetLink() {
      this.$store
        .dispatch(EAuthMilestonePlannersActions.PATCH, {
          milestonePlannerUid: this.uid,
          queryParams: {
            reset: "link",
          },
        })
        .then(() => {
          if (!this.milestonePlannersErrors) {
            this.linkWasReset = true;
            return;
          }
          // Show generic error message
          this.hasServerError = true;
        });
    },

    onConfirmResetPasscode() {
      this.$store
        .dispatch(EAuthMilestonePlannersActions.PATCH, {
          milestonePlannerUid: this.uid,
          queryParams: {
            reset: "passcode",
          },
        })
        .then(() => {
          if (!this.milestonePlannersErrors) {
            this.passcodeWasChanged = true;
            return;
          }
          // Show generic error message
          this.hasServerError = true;
        });
    },

    onConfirmUnshare() {
      const usersStillInvited = this.users.filter((user) => {
        // Check if the user is of type IMilestonePlannerInvitedUser
        if ("user_profile" in user) {
          return user.user_profile !== this.userToRemove?.user_profile;
        }
      }) as IMilestonePlannerInvitedUser[];

      this.$store
        .dispatch(EAuthMilestonePlannersActions.PATCH, {
          milestonePlannerUid: this.uid,
          payload: {
            invited_users: usersStillInvited.map((user) => user.user_profile),
          },
        })
        .then(() => {
          if (!this.milestonePlannersErrors) {
            this.userHasBeenRemoved = true;
            return;
          }
          // Show generic error message
          this.hasServerError = true;
        });
    },

    saveSettingsHandler(newPasscode: string | null) {
      // Disable/enable passcode restriction
      this.$store
        .dispatch(EAuthMilestonePlannersActions.PATCH, {
          milestonePlannerUid: this.uid,
          payload: {
            passcode: newPasscode,
          },
        })
        .then(() => {
          if (!this.milestonePlannersErrors) {
            this.passcodeWasChanged = true;
            return;
          }
          // Show generic error message
          this.hasServerError = true;
        });
    },
  },
});
</script>

<style lang="scss">
.share-planner-modal {
  &.el-dialog {
    overflow-y: initial !important;
  }

  .el-dialog__header {
    @include breakpoint-up(md) {
      padding: 14px 56px 16px 30px;
    }
  }

  .el-dialog__headerbtn {
    right: 27px;
  }

  .el-dialog__title {
    font-size: 22px;
    letter-spacing: -0.2px;
  }

  .el-dialog__subtitle {
    margin-left: 6px;
    letter-spacing: -0.35px;
    vertical-align: 2px;

    &::before,
    &::after {
      content: '"';
    }
  }

  .overlay-confirm.share-planner-modal__overlay-confirm {
    top: 69px;

    .overlay-confirm__buttons {
      position: relative;
      right: 4px;
      bottom: 3px;
    }
  }

  .overlay-confirm.share-planner-modal__overlay-confirm
    .overlay-confirm__content {
    max-width: 574px;
    padding: 0 41px 55px;

    h2 {
      margin-bottom: 19px;
    }

    h2 + p {
      margin-top: 0;
      letter-spacing: -0.3px;
    }

    .el-button {
      padding: 0 15px;
      margin-left: 0;
    }

    .el-button--link {
      padding: 0 10px;
    }

    .el-button.el-button--link span {
      font-size: 14px;
    }
  }

  .px-modal-message {
    z-index: z("navigation");
  }

  &__header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-right: 2px;
  }

  &__subtitle {
    @include grotesk(semiBold);

    font-size: 12px;
    color: $ebony-clay;
    text-transform: uppercase;
  }

  &__description {
    font-size: 12px;
    font-style: italic;
    color: $manatee;
  }

  &__appendix {
    width: 100%;
    height: 100%;
    max-height: 514px;
    overflow: hidden;
  }

  &--users .el-dialog__body {
    @include breakpoint-up(md) {
      padding-top: 17px;
      padding-right: 28px;
      padding-left: 30px;
    }
  }
}
</style>
