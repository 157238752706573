export enum ESubscriptionPlanID {
  FREE = "supporter-free",
  PRO_MONTHLY = "supporter-pro",
  PRO_YEARLY = "supporter-pro-(annual)",
}
export interface ISubscription {
  customerId: string;
  subscriptionId: string;
  planId: ESubscriptionPlanID;
  startDate: number;
  renewalDate: number;
}
