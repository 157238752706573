<template>
  <div class="vue-content-placeholders-img"></div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "ContentPlaceholdersImg",
});
</script>
