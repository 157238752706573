<template>
  <div class="supporters-view__container">
    <LangSwitcher />
    <FormPresentationLayout
      :content-description="viewCopy.contentDescription"
      :content-title="viewCopy.contentTitle"
      :description-image-link="viewCopy.imageLink"
      :description-image-title="viewCopy.imageTitle"
      :description-text="viewCopy.imageDescription"
      :has-description-background-image="true"
      :has-return-button="hasPreviousPage"
      @previous-page="onReturnButtonClick"
    >
      <template #content>
        <div class="supporter-registration-form__container">
          <ElForm
            ref="supporterRegistrationForm"
            :model="fields"
            :rules="rules"
            class="supporter-registration-form__body"
            @validate="updateSubmitState"
          >
            <ElFormItem
              :error="errors.getMessage('email') || ''"
              :label="formCopy.email.label"
              prop="email"
            >
              <ElInput
                v-model="fields.email"
                :placeholder="formCopy.email.placeholder"
                type="email"
              />
            </ElFormItem>
            <ElFormItem :show-message="false" prop="terms" required>
              <ElCheckbox
                v-model="fields.terms"
                class="supporter-registration-form__selector"
              >
                <i18n-t
                  class="supporter-registration-form__selector-text"
                  keypath="supporters.form.terms"
                  tag="p"
                >
                  <template #terms>
                    <a
                      :href="$options.static.termsOfUseUrl"
                      class="text--nice-blue"
                      target="_blank"
                    >
                      {{ $t("supporters.form.termsOfUse") }}
                    </a>
                  </template>
                  <template #privacy>
                    <a
                      :href="$options.static.privacyPolicyUrl"
                      class="text--nice-blue"
                      target="_blank"
                    >
                      {{ $t("supporters.form.privacyPolicy") }}
                    </a>
                  </template>
                </i18n-t>
              </ElCheckbox>
            </ElFormItem>
            <div class="supporter-registration-form__cta">
              <PxButton
                :disabled="isSubmissionDisabled"
                :loading="formLoading"
                type="primary"
                @click="onNextButtonClick"
              >
                {{ ctaCopy.signUp }}
              </PxButton>
            </div>
            <template v-if="showGoogleSignUp">
              <ElDivider>
                {{ viewCopy.separatorText }}
              </ElDivider>
              <div
                class="supporter-registration-form__cta supporter-registration-form__google-cta"
              >
                <PxButton @click="onGoogleSignUpButtonClick">
                  <PxIcon
                    :size="18"
                    class="supporter-registration-form__google-logo"
                    name="google-logo"
                  />
                  {{ ctaCopy.googleSignUp }}
                </PxButton>
              </div>
            </template>
          </ElForm>
          <div class="supporter-registration-form__footer">
            <p class="supporter-registration-form__footer-text">
              {{ ctaCopy.alreadyHaveAccount }}
              <PxButton
                class="el-button--inline el-button--link-blue"
                type="link"
                @click="onLoginButtonClick"
              >
                {{ ctaCopy.login }}
              </PxButton>
            </p>
          </div>
        </div>
      </template>
    </FormPresentationLayout>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import SupportersFlowMixin from "@/modules/supporters/mixins/supporters-flow.mixin";
import FormPresentationLayout from "@/modules/supporters/components/form-presentation-layout/form-presentation-layout.vue";

import {
  SUPPORTERS_TERMS_OF_USE_URL,
  SUPPORTERS_PRIVACY_POLICY_URL,
} from "@/modules/supporters/constants";
import { ElDivider } from "element-plus";
import { ISupporterFlow } from "@/modules/supporters/services/data/supporter-flow/supporter-flow.interface";
import LangSwitcher from "@/modules/common/components/lang-switcher/lang-switcher.vue";

export default defineComponent({
  name: "StartingPointSimplified",

  components: {
    FormPresentationLayout,
    ElDivider,
    LangSwitcher,
  },

  mixins: [SupportersFlowMixin],

  static: {
    termsOfUseUrl: SUPPORTERS_TERMS_OF_USE_URL,
    privacyPolicyUrl: SUPPORTERS_PRIVACY_POLICY_URL,
  },

  data() {
    return {
      fields: {
        email: "",
        terms: false,
      } as Partial<ISupporterFlow>,
    };
  },

  computed: {
    viewCopy() {
      return this.$tm("supporters.view.startPage") as {
        contentTitle: string;
        contentDescription: string;
        imageTitle: string;
        imageDescription: string;
        separatorText: string;
        imageLink: {
          original: string;
          retina: string;
        };
      };
    },

    showGoogleSignUp(): boolean {
      return this.$features.isEnabled("signUpGoogleSSO");
    },
  },

  mounted() {
    if (this.flowData) {
      this.fields.email = this.flowData.email || this.fields.email;
    }

    // Check if should validate
    if (this.fields.email) {
      this.currentForm?.validate();
    }
  },
});
</script>

<style lang="scss" scoped>
.supporters-view__container :deep() {
  .form-presentation-layout__content-container {
    margin-top: 72px;
  }

  .form-presentation-layout__content-header-wrapper {
    padding: 0 0 52px;
  }
}

.supporter-registration-form__container {
  display: grid;
  grid-gap: 40px;
  max-width: 360px;
}

.supporter-registration-form__body {
  display: grid;
  grid-gap: 24px;
}

.supporter-registration-form__selector {
  word-wrap: break-word;
  white-space: normal;

  :deep(.el-checkbox__input) {
    bottom: 19px;
  }

  :deep(.el-checkbox__label) {
    max-width: 334px;
    padding-left: 12px;

    @include breakpoint-down(lg) {
      max-width: 260px;
    }
  }

  :deep(.text--nice-blue) {
    text-decoration: none;
  }
}

.supporter-registration-form__selector-text {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: $ebony-clay;
}

.supporter-registration-form__body :deep(.el-form-item) {
  margin: 0;
}

.supporter-registration-form__body :deep(.el-form-item__label) {
  padding: 0 12px 4px 0;
}

.supporter-registration-form__body :deep(.el-divider) {
  margin: 8px 0;
  background-color: rgba($manatee, 0.3);
}

.supporter-registration-form__body :deep(.el-divider__text) {
  @include grotesk(semiBold);

  color: $manatee;
}

.supporter-registration-form__cta :deep(.el-button) {
  width: 100%;
}

.supporter-registration-form__google-cta :deep(.el-button span) {
  @include grotesk(medium);

  position: relative;
  color: $ebony-clay;
  text-transform: none;
}

.supporter-registration-form__google-logo {
  position: absolute;
  left: 0;
}

.supporter-registration-form__footer {
  display: grid;
  grid-gap: 22px;
}

.supporter-registration-form__footer-text,
.supporter-registration-form__footer-text :deep(.el-button span) {
  font-size: 14px;
  line-height: 20px;
}

.supporter-registration-form__footer-text :deep(a),
.supporter-registration-form__footer-text :deep(.el-button span) {
  @include grotesk(medium);

  text-decoration: none;

  &:hover {
    color: $malibu;
  }
}

.supporter-registration-form__footer-text {
  color: $ebony-clay;
}
</style>
