import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ErrorPage = _resolveComponent("ErrorPage")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_ErrorPage, {
      "error-code": _ctx.errorCode,
      "show-support-modal": _ctx.shouldShowSupportModal
    }, null, 8, ["error-code", "show-support-modal"])
  ]))
}