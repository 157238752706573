import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-07ef6d41"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "results-board__cta-panel" }
const _hoisted_2 = { class: "results-board__headline" }
const _hoisted_3 = { class: "results-board__headline-title" }
const _hoisted_4 = { class: "results-board__list" }
const _hoisted_5 = { class: "results-board__footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PxIcon = _resolveComponent("PxIcon")!
  const _component_i18n_t = _resolveComponent("i18n-t")!
  const _component_PxButton = _resolveComponent("PxButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h1", _hoisted_3, [
        _createVNode(_component_PxIcon, {
          name: `icon-check-green-white`,
          size: 18,
          class: "results-board__headline-icon"
        }),
        _createTextVNode(" " + _toDisplayString(_ctx.$t("supporters.results.confirmationPanel.title")), 1)
      ]),
      _createVNode(_component_i18n_t, {
        class: "results-board__headline-content",
        keypath: "supporters.results.confirmationPanel.sentInfo",
        tag: "p"
      }, {
        company: _withCtx(() => [
          _createElementVNode("u", null, _toDisplayString(_ctx.affiliateShortcode), 1)
        ]),
        _: 1
      })
    ]),
    _createVNode(_component_i18n_t, {
      class: "results-board__title",
      keypath: "supporters.results.confirmationPanel.subtitle.content",
      tag: "h2"
    }, {
      highlight: _withCtx(() => [
        _createElementVNode("mark", null, _toDisplayString(_ctx.$t("supporters.results.confirmationPanel.subtitle.highlight")), 1)
      ]),
      _: 1
    }),
    _createElementVNode("ul", _hoisted_4, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.memberBenefits, (item, index) => {
        return (_openBlock(), _createElementBlock("li", { key: index }, _toDisplayString(item), 1))
      }), 128))
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createVNode(_component_PxButton, {
        class: "results-board__join-btn",
        size: "large",
        type: "green",
        onClick: _ctx.onClickSignUp
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t("supporters.results.confirmationPanel.button")), 1)
        ]),
        _: 1
      }, 8, ["onClick"])
    ])
  ]))
}