import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = {
  ref: "container",
  class: "px-tabs-mobile__container"
}
const _hoisted_2 = {
  ref: "list",
  class: "px-tabs-mobile__list"
}
const _hoisted_3 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_click_outside = _resolveDirective("click-outside")!

  return _withDirectives((_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([{
      'is-open': _ctx.isOpen,
      'is-reverse': _ctx.isReverse,
      'is-disabled': _ctx.disabled,
    }, "px-tabs-mobile"])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("span", {
        class: "px-tabs-mobile__trigger",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.triggerClickHandler && _ctx.triggerClickHandler(...args)))
      }, _toDisplayString(_ctx.label), 1),
      _createElementVNode("div", _hoisted_2, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.nonSelectedElements, (item) => {
          return (_openBlock(), _createElementBlock("span", {
            key: item.value,
            class: "px-tabs-mobile__item",
            onClick: ($event: any) => (_ctx.itemClickHandler(item))
          }, _toDisplayString(item.label), 9, _hoisted_3))
        }), 128))
      ], 512)
    ], 512)
  ], 2)), [
    [_directive_click_outside, _ctx.outsideClickHandler]
  ])
}