import { ICategory } from "@/services/data/category/category.interface";
import { IBaseState } from "@/services/store/store-types";

/**
 * Represents the store structure for the category module.
 */
export interface ICategoryState extends IBaseState {
  /**
   * Array that stores all categories.
   */
  data: Array<ICategory>;
}

/**
 * Enumeration with all available module actions.
 */
export enum ECategoryActions {
  FETCH = "categories/fetch",
}
