import {
  GenericProvider,
  EProviderFeatures,
} from "@/services/data/generic-provider";
import { ICategory } from "@/services/data/category/category.interface";

class CategoryProvider extends GenericProvider<ICategory> {
  constructor() {
    super(`/categories/`, [EProviderFeatures.LIST]);
  }
}

export const categoryProvider = new CategoryProvider();
