import { resolveDirective as _resolveDirective, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-3487320b"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "description-cmp" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PxPanel = _resolveComponent("PxPanel")!
  const _directive_markdown = _resolveDirective("markdown")!

  return (_openBlock(), _createBlock(_component_PxPanel, {
    title: _ctx.category.requirements_title,
    class: "company-requirements",
    icon: "know-more--orange"
  }, {
    default: _withCtx(() => [
      _withDirectives(_createElementVNode("div", _hoisted_1, null, 512), [
        [_directive_markdown, _ctx.levelDescription]
      ])
    ]),
    _: 1
  }, 8, ["title"]))
}