<template>
  <div class="supporters-view__container">
    <FormPresentationLayout
      :content-title="viewCopy.contentTitle"
      :description-image-link="viewCopy.imageLink"
      :description-image-title="viewCopy.imageTitle"
      :description-text="viewCopy.imageDescription"
      :has-description-background-image="true"
      :has-return-button="hasPreviousPage"
      @previous-page="onReturnButtonClick"
    >
      <template #content>
        <div class="supporter-registration-form__container">
          <ElForm
            ref="supporterRegistrationForm"
            :model="fields"
            :rules="rules"
            class="supporter-registration-form__body"
            @validate="updateSubmitState"
          >
            <ElFormItem :label="formCopy.password.label" prop="password1">
              <PxInputPassword
                v-model="fields.password1"
                :label="formCopy.password.label"
                :placeholder="formCopy.password.placeholder"
                :show-success-state="true"
                @input="validateConfirmationField"
              />
              <span
                class="el-form-item__bottom-text el-form-item__tip"
                v-html="formCopy.password.tip"
              />
            </ElFormItem>
            <ElFormItem
              :label="formCopy.passwordConfirmation.label"
              prop="password2"
            >
              <ElInput
                v-model="fields.password2"
                :disabled="passwordIsInvalid"
                :placeholder="formCopy.passwordConfirmation.placeholder"
                class="el-input--password"
                type="password"
              />
            </ElFormItem>
            <div class="supporter-registration-form__cta">
              <PxButton
                :disabled="isSubmissionDisabled"
                :loading="formLoading"
                type="primary"
                @click="onNextButtonClick"
              >
                {{ ctaCopy.next }}
              </PxButton>
            </div>
          </ElForm>
        </div>
      </template>
    </FormPresentationLayout>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import SupportersFlowMixin from "@/modules/supporters/mixins/supporters-flow.mixin";
import FormPresentationLayout from "@/modules/supporters/components/form-presentation-layout/form-presentation-layout.vue";

import { ISupporterFlow } from "@/modules/supporters/services/data/supporter-flow/supporter-flow.interface";
import { IFormField } from "@/modules/company-lists/components/list-management/list-management-modal.vue";

export default defineComponent({
  name: "SetupPassword",

  components: {
    FormPresentationLayout,
  },

  mixins: [SupportersFlowMixin],

  data() {
    return {
      fields: {
        password1: "",
        password2: "",
      } as Partial<ISupporterFlow>,
    };
  },

  computed: {
    viewCopy() {
      return this.$tm("supporters.view.setupPassword") as {
        contentTitle: string;
        imageTitle: string;
        imageDescription: string;
        imageLink: {
          original: string;
          retina: string;
        };
      };
    },

    formFields(): IFormField[] | null {
      return this.currentForm
        ? ((this.currentForm as any).fields as IFormField[])
        : null;
    },

    passwordIsInvalid(): boolean {
      if (this.formFields) {
        const formFields = this.formFields;

        const passwordField = formFields.find(
          (field) => field.prop === "password1",
        );

        return passwordField?.validateState !== "success";
      }
      return true;
    },
  },

  mounted() {
    if (this.flowData) {
      this.fields.password1 = this.flowData.password1 || this.fields.password1;
      this.fields.password2 = this.flowData.password2 || this.fields.password2;
    }

    // Check if should validate
    if (this.fields.password1 || this.fields.password2) {
      this.currentForm?.validate();
    }
  },

  methods: {
    validateConfirmationField() {
      if (!this.passwordIsInvalid) {
        this.currentForm?.validateField("password2", this.updateSubmitState);
      }
    },
  },
});
</script>

<style lang="scss" scoped>
.supporters-view__container :deep() {
  .form-presentation-layout__content-wrapper {
    padding-top: 44px;
  }

  .form-presentation-layout__return-button {
    margin-bottom: 107px;
  }

  .form-presentation-layout__content-header-wrapper {
    padding-top: 0;
    padding-left: 0;
  }

  .form-presentation-layout__content-header {
    margin-bottom: 37px;
  }
}

.supporter-registration-form__container {
  display: grid;
  grid-gap: 40px;
  max-width: 360px;
}

.supporter-registration-form__body {
  display: grid;
  grid-gap: 30px;
}

.supporter-registration-form__body :deep(.el-form-item__label) {
  padding: 0 12px 4px 0;
}

.supporter-registration-form__body :deep(.el-input__inner) {
  margin: 0;
  line-height: 40px;
}

.supporter-registration-form__cta :deep(.el-button) {
  width: 164px;
}
</style>
