<template>
  <div class="share-modal-users">
    <div v-if="users.length" class="share-modal-users__wrapper">
      <div
        v-for="(user, index) in users"
        :key="index"
        class="share-modal-users__item"
      >
        <PxAvatar
          :name="user.name"
          :photo="user.photo"
          :size="32"
          class="share-modal-users__item-photo"
          styling="neutral"
          type="round"
        />
        <div class="share-modal-users__item-content">
          <h3 class="share-modal-users__content-title">
            {{ user.name }}
          </h3>
          <p v-if="user.email" class="share-modal-users__content-subtitle">
            {{ user.email }}
          </p>
        </div>
        <template v-if="!user.user_profile">
          <ElTag class="share-modal-users__tag" effect="light" size="small">
            {{ $t("common.guest") }}
          </ElTag>
          <ElTooltip
            :enterable="false"
            placement="top"
            popper-class="share-modal-users__tooltip share-modal-users__tooltip--spacious el-abaca-tooltip"
          >
            <template #content>
              <div v-text="usersCopy.tip" />
            </template>
            <PxIcon :size="17" class="share-modal-users__tip" name="question" />
          </ElTooltip>
        </template>
        <div class="share-modal-users__item-actions">
          <ElTooltip
            v-if="$screen.mdUp"
            :disabled="!user.user_profile"
            :enterable="false"
            placement="top"
            popper-class="share-modal-users__tooltip el-abaca-tooltip"
          >
            <template #content>
              <div>
                {{ $t("common.shareModal.unshare") }}
              </div>
            </template>
            <div
              :class="{ 'is-disabled': !user.user_profile }"
              class="share-modal-users__unshare-cta"
              @click.stop="unshareClickHandler(user.user_profile)"
            >
              <PxIcon :size="14" name="trash--red" />
              <PxIcon :size="14" class="px-icon--hover" name="trash" />
            </div>
          </ElTooltip>
        </div>
      </div>
    </div>
    <div v-else class="share-modal-users__empty">
      <picture class="share-modal-users__empty-illustration">
        <img
          alt="Users illustration"
          src="/img/icons/lists-users-empty-state.svg"
        />
      </picture>
      <p class="share-modal-users__empty-title">
        {{ usersCopy.empty }}
      </p>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import { IInvitedUser } from "@/components/share-modal/share-modal.interface";

import { SHARE_MODAL_EVENTS } from "@/components/share-modal/share-modal.types";

export default defineComponent({
  name: "ShareModalUsers",

  props: {
    users: {
      type: Array as () => IInvitedUser[],
      default: () => [],
    },

    copy: {
      type: String,
      default: "milestonePlanner.shareModal.users",
    },
  },

  computed: {
    usersCopy() {
      return this.$tm(this.copy) as {
        title: string;
        description: string;
        tip: string;
        formOwner: string;
        empty: string;
      };
    },
  },

  methods: {
    unshareClickHandler(userProfile: string | null) {
      this.$emitter.emit("share-modal-action", {
        event: SHARE_MODAL_EVENTS.UNSHARE_FROM_USER,
        payload: { user_profile: userProfile },
      });
    },
  },
});
</script>

<style lang="scss" scoped>
.share-modal-users {
  &__wrapper {
    padding-top: 13px;
  }

  &__item {
    display: flex;
    flex-flow: row;
    min-height: 60px;
    align-items: center;

    &:not(:first-child) {
      border-top: solid 1px $athens-gray;
    }
  }

  &__item-photo.px-avatar {
    user-select: none;
    box-shadow: inset 0 0 3px 0 rgba(139, 143, 161, 0.3);

    :deep(.px-avatar__letter) {
      font-size: 16px;
      line-height: 1.9;
      text-indent: 1px;
    }
  }

  &__item-content {
    flex: 1 1 auto;

    &:not(:first-child) {
      margin-left: 16px;
    }
  }

  &__item-actions {
    margin: 0 0 1px auto;
  }

  &__content-title {
    @include grotesk(regular);

    margin-top: -2px;
    margin-bottom: 0;
    font-size: 14px;
    line-height: 20px;
    color: $ebony-clay;
    letter-spacing: -0.35px;

    &:only-child {
      padding-top: 8px;
    }
  }

  &__content-subtitle {
    @include grotesk(regular);

    margin-bottom: 0;
    font-size: 13px;
    line-height: 16px;
    color: $manatee;
    letter-spacing: -0.35px;
  }

  &__tag {
    margin: 0 60px 1px auto;

    :deep(span) {
      font-size: 12px;
      font-weight: bold;
      color: $manatee;
      text-transform: uppercase;
    }
  }

  &__tip {
    margin: 0 11px 1px auto;
    transition: filter 200ms cubic-bezier(0.23, 1, 0.32, 1);

    &:hover {
      filter: brightness(0.5) hue-rotate(380deg);
    }
  }

  &__unshare-cta {
    position: relative;
    width: 32px;
    height: 32px;
    cursor: pointer;
    user-select: none;
    border: solid 1px rgba($wild-watermelon, 0.2);
    border-radius: 3px;

    &::before {
      position: absolute;
      top: -1px;
      left: -1px;
      width: calc(100% + 2px);
      height: calc(100% + 2px);
      content: "";
      background-color: $wild-watermelon;
      border: inset 1px rgba($ebony-clay, 0.2);
      border-radius: 3px;
      opacity: 0;
      transition: $--fade-transition;
    }

    .px-icon {
      position: absolute;
      top: 50%;
      left: 50%;
      vertical-align: top;
      transform: translate(-50%, -50%);
    }

    .px-icon--hover {
      filter: brightness(2);
      opacity: 0;
      transition: $--fade-transition;
    }

    &:hover {
      &::before {
        opacity: 1;
      }

      .px-icon--hover {
        opacity: 1;
      }
    }

    &.is-disabled {
      pointer-events: none;
      opacity: 0.5;
    }
  }

  &__empty {
    position: absolute;
    top: 48%;
    left: 50%;
    transform: translate(-50%, -48%);
  }

  &__empty-illustration {
    display: block;
    height: 93px;
    position: relative;
    top: 6px;
    right: 1px;
    margin-bottom: 15px;
  }

  &__empty-title {
    @include grotesk(medium);

    position: relative;
    top: 1px;
    margin-bottom: 0;
    font-size: 15px;
    line-height: normal;
    color: $manatee;
    letter-spacing: -0.25px;
  }
}
</style>

<style lang="scss">
.share-modal-users__tooltip {
  max-width: 278px;
  padding: 5px 10px;
  margin-top: 3px;
  text-transform: none;
  letter-spacing: -0.3px;
}

.share-modal-users__tooltip--spacious {
  padding: 10px 15px;
  letter-spacing: -0.2px;
}
</style>
