<template>
  <div class="role-playing-banner">
    <div class="role-playing-banner__wrapper">
      <PxIcon :name="$t('capitalExplorer.rolePlayingBanner.icon')" :size="16" />
      <div
        class="role-playing-banner__message"
        v-html="$t('capitalExplorer.rolePlayingBanner.message')"
      />
      <PxButton
        :label="$t('capitalExplorer.rolePlayingBanner.action')"
        :type="buttonType"
        class="role-playing-banner__action"
        icon="arrow--right-black"
        icon-position="right"
        @click="goToDashboardPage"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { EPxButtonType } from "@/components/px-button/px-button.types";
import { ROUTE_GETTING_STARTED_DASHBOARD } from "@/modules/getting-started-dashboard/services/router/routes-names";

export default defineComponent({
  name: "RolePlayingBanner",

  data: function () {
    return {
      buttonType: EPxButtonType.LINK_DARK,
    };
  },

  methods: {
    goToDashboardPage() {
      this.$router.push({ name: ROUTE_GETTING_STARTED_DASHBOARD });
    },
  },
});
</script>

<style lang="scss" scoped>
.role-playing-banner {
  background: #ffca28;

  &__wrapper {
    @include make-container();
    @include make-container-max-widths();
    padding: 0;
    display: flex;
    align-items: center;
    gap: 8px;
    min-height: 44px;
  }

  &__message {
    font-size: 14px;
    letter-spacing: -0.33px;
  }

  &__action {
    margin-left: auto;

    :deep() &:hover span {
      color: inherit;
    }

    :deep() span div {
      margin-right: 6px;
    }

    :deep() .px-icon {
      height: 13px;
    }
  }
}
</style>
