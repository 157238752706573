import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ContentPlaceholdersText = _resolveComponent("ContentPlaceholdersText")!
  const _component_ContentPlaceholders = _resolveComponent("ContentPlaceholders")!

  return (_openBlock(), _createBlock(_component_ContentPlaceholders, {
    class: "levels-description-placeholder",
    rounded: ""
  }, {
    default: _withCtx(() => [
      _createVNode(_component_ContentPlaceholdersText, {
        lines: 2,
        class: "levels-description-placeholder__header"
      }),
      _createVNode(_component_ContentPlaceholdersText, {
        lines: 3,
        class: "levels-description-placeholder__description"
      })
    ]),
    _: 1
  }))
}