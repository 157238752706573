import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, createElementVNode as _createElementVNode, withDirectives as _withDirectives, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-1bc11c82"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "page-full-height"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PxNavbar = _resolveComponent("PxNavbar")!
  const _component_PxTabBar = _resolveComponent("PxTabBar")!
  const _component_RouterView = _resolveComponent("RouterView")!
  const _directive_loading = _resolveDirective("loading")!

  return (_ctx.isLoading)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_ctx.$screen.mdUp)
          ? (_openBlock(), _createBlock(_component_PxNavbar, { key: 0 }))
          : _createCommentVNode("", true),
        (_ctx.$screen.smDown)
          ? (_openBlock(), _createBlock(_component_PxTabBar, { key: 1 }))
          : _createCommentVNode("", true),
        _withDirectives(_createElementVNode("div", {
          class: _normalizeClass({ 'is-loading': _ctx.isLoading }),
          "element-loading-background": "#fafafa"
        }, null, 2), [
          [_directive_loading, _ctx.isLoading]
        ])
      ]))
    : (_openBlock(), _createBlock(_component_RouterView, { key: 1 }))
}