<template>
  <ElDialog
    ref="modal"
    v-model="innerVisibility"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :show-close="true"
    :title="$t(`affiliateProgram.userDetailsModal.title`)"
    top="10px"
    class="entrepreneur-sign-up-modal"
    data-testid="entrepreneur-sign-up-modal"
    @close="onModalClose"
  >
    <PxModalMessage :visible="hasServerErrors" />
    <i18n-t
      class="entrepreneur-sign-up-modal__sign-in-panel"
      keypath="affiliateProgram.userDetailsModal.existingAccount"
      tag="div"
    >
      <template #signIn>
        <span
          class="entrepreneur-sign-up-modal__sign-in-panel-link"
          data-testid="entrepreneur-sign-up-modal__sign-in-panel-link"
          @click="gotoSignInModal"
        >
          {{ $t("affiliateProgram.userDetailsModal.signIn") }}
        </span>
      </template>
    </i18n-t>
    <div
      ref="formWrapper"
      :class="{ 'scroll-at-bottom': formWrapperScrollAtEnd }"
      class="entrepreneur-sign-up-modal__form-wrapper"
      @scroll="checkFormWrapperScroll"
    >
      <ElForm
        ref="form"
        :model="form"
        :rules="rules"
        @validate="updateSubmitState"
      >
        <ElFormItem
          :label="$t('affiliateProgram.userDetailsModal.fields.name')"
          prop="company.name"
        >
          <ElInput
            v-model="form.company.name"
            :placeholder="$t('affiliateProgram.userDetailsModal.fields.name')"
            data-testid="entrepreneur-sign-up-modal__input-name"
          />
        </ElFormItem>
        <ElFormItem
          :error="errors.getMessage('email') || ''"
          :label="$t('affiliateProgram.userDetailsModal.fields.email')"
          prop="email"
        >
          <ElInput
            v-model="form.email"
            :placeholder="$t('affiliateProgram.userDetailsModal.fields.email')"
            data-testid="entrepreneur-sign-up-modal__input-email"
            type="email"
          />
        </ElFormItem>
        <ElFormItem
          :label="$t('authentication.signup.form.fields.website')"
          prop="company.website"
        >
          <ElInput
            v-model.trim="form.company.website"
            :placeholder="
              $t('authentication.signup.form.fields.websitePlaceholder')
            "
            data-testid="entrepreneur-sign-up-modal__input-website"
          >
            <template #prefix>
              <PxIcon :size="24" name="website" />
            </template>
          </ElInput>
        </ElFormItem>
        <ElFormItem
          :label="$t('affiliateProgram.userDetailsModal.fields.location')"
          prop="company.location"
        >
          <PxInputPlaces
            id="companyInfoLocation"
            v-model="form.company.location"
            :placeholder="
              $t('affiliateProgram.userDetailsModal.fields.location')
            "
            :use-geolocation="true"
            data-testid="entrepreneur-sign-up-modal__input-location"
          />
        </ElFormItem>
        <ElFormItem prop="company.sectors">
          <SectorsSelector
            v-model="form.company.sectors"
            data-testid="entrepreneur-sign-up-modal__input-sectors"
            @change="handleSectorsChange"
          />
        </ElFormItem>
        <div class="entrepreneur-sign-up-modal__notice">
          <PxIcon :size="14" name="information--light-gray" />
          <p
            v-html="
              $t('affiliateProgram.userDetailsModal.notice', { affiliateName })
            "
          />
        </div>
        <ElFormItem :show-message="false" prop="terms" required>
          <ElCheckbox
            v-model="form.terms"
            class="entrepreneur-sign-up-modal__selector"
            data-testid="entrepreneur-sign-up-modal__terms-agreement"
          >
            <i18n-t
              class="entrepreneur-sign-up-modal__selector-text"
              keypath="affiliateProgram.userDetailsModal.terms"
              tag="p"
            >
              <template #terms>
                <a
                  :href="$options.static.termsOfUseUrl"
                  class="text--nice-blue"
                  target="_blank"
                >
                  {{ $t("affiliateProgram.userDetailsModal.termsOfUse") }}
                </a>
              </template>
              <template #privacy>
                <a
                  :href="$options.static.privacyPolicyUrl"
                  class="text--nice-blue"
                  target="_blank"
                >
                  {{ $t("affiliateProgram.userDetailsModal.privacyPolicy") }}
                </a>
              </template>
            </i18n-t>
          </ElCheckbox>
        </ElFormItem>
      </ElForm>
    </div>
    <template #footer>
      <div class="entrepreneur-sign-up-modal__button-wrapper">
        <PxButton
          :disabled="isSubmissionDisabled"
          :loading="isLoadingSubmission"
          data-testid="entrepreneur-sign-up-modal__submit-button"
          type="green"
          @click="onSubmitClick"
        >
          {{ $t("affiliateProgram.userDetailsModal.submitBtn") }}
        </PxButton>
      </div>
    </template>
  </ElDialog>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import cloneDeep from "lodash/cloneDeep";

import { IAffiliate } from "@/services/data/affiliate/affiliate.interface";
import { ErrorsManager } from "@/services/errors-manager";
import { ErrorsProviderException } from "@/services/data/exceptions/errors-provider.exception";

import PxInputPlaces from "@/components/px-input-places/px-input-places.vue";
import SectorsSelector from "@/components/sectors-selector/sectors-selector.vue";

import { pendingUserRegisterProvider } from "@/modules/authentication/services/data/pending-user-register/pending-user-register.provider";

import {
  allFormFieldsValid,
  generateEmailValidator,
  generateRequiredSetValidator,
  generateRequiredValidator,
  generateURLValidator,
  generateCheckboxValidator,
} from "@/services/errors/validator-generators";
import {
  AFFILIATE_PROGRAM_TERMS_OF_USE_URL,
  AFFILIATE_PROGRAM_PRIVACY_POLICY_URL,
} from "@/modules/affiliates/constants";
import { SELF_ASSESSMENT_BEGAN_STATE } from "@/modules/self-assessment/constants";
import { ILocation } from "@/services/data/location/location.interface";
import { ICategoryLevelSelection } from "@/services/data/viral-level/viral-level.interface";
import { gaTrackEvent } from "@/services/utils/ga.utils";
import { ENTREPRENEUR_USER_GROUP_ID } from "@/modules/common/constants";
import { companyProvider } from "@/modules/profile/services/data/company/company.provider";
import { pendingUserUpdateProvider } from "@/modules/authentication/services/data/pending-user-update/pending-user-update.provider";
import {
  IAffiliatesState,
  EAffiliatesActions,
} from "@/modules/affiliates/services/store/affiliates/affiliates.types";
import ElDialogMixin from "@/mixins/el-dialog.mixin";
import { FormContext } from "element-plus";

export default defineComponent({
  name: "EntrepreneurSignUpModal",

  components: {
    PxInputPlaces,
    SectorsSelector,
  },

  static: {
    termsOfUseUrl: AFFILIATE_PROGRAM_TERMS_OF_USE_URL,
    privacyPolicyUrl: AFFILIATE_PROGRAM_PRIVACY_POLICY_URL,
  },

  mixins: [ElDialogMixin],

  data() {
    return {
      hasServerErrors: false,
      isLoadingSubmission: false,
      isSubmissionDisabled: true,
      formWrapperScrollAtEnd: false,

      form: {
        email: "",
        company: {
          name: "",
          website: "",
          location: {} as ILocation,
          sectors: [] as Array<number>,
        },
        terms: false,
      },

      rules: {
        email: generateEmailValidator(
          this,
          "authentication.signup.form.fields.email",
          true,
        ),
        "company.name": generateRequiredValidator(
          this,
          "affiliateProgram.companyInfoModal.fields.name",
        ),
        "company.location": generateRequiredValidator(
          this,
          "affiliateProgram.companyInfoModal.fields.location",
        ),
        "company.website": generateURLValidator(
          this,
          "authentication.signup.form.fields.website",
          true,
        ),
        "company.sectors": generateRequiredSetValidator(
          this,
          "affiliateProgram.companyInfoModal.errors.sector",
        ),
        terms: generateCheckboxValidator(),
      },

      errors: new ErrorsManager({
        email: {
          unique: this.$t("authentication.signup.form.errors.email.unique"),
        },
      }),
    };
  },

  computed: {
    affiliatesData(): IAffiliatesState {
      return this.$store.state.affiliates;
    },

    affiliate(): IAffiliate | null {
      return this.affiliatesData.affiliate;
    },

    affiliateName(): string {
      return this.affiliate && this.affiliate.name ? this.affiliate.name : "";
    },

    affiliateId(): number | null {
      return this.affiliate && this.affiliate.id ? this.affiliate.id : null;
    },

    assessmentLevels(): Array<ICategoryLevelSelection> {
      return this.affiliatesData.draftSubmission?.data.assessment || [];
    },

    currentForm(): FormContext {
      return this.$refs.form as FormContext;
    },

    companyToken(): string | null {
      return this.$route.query && (this.$route.query.token as string | null);
    },

    vendor(): string | null {
      return this.$route.query && (this.$route.query.vendor as string | null);
    },
  },

  async created() {
    this.fetchCompanyByToken();

    await this.$nextTick();
    this.checkFormWrapperScroll();
  },

  methods: {
    fetchCompanyByToken() {
      if (this.companyToken || this.vendor) {
        companyProvider
          .get(this.companyToken || this.vendor)
          .then((loadedCompany: any) => {
            this.form.email = loadedCompany.email || "";
            this.form.company = this.setCompanyDataOnForm(loadedCompany);
          });
      }
    },

    setCompanyDataOnForm(loadedCompany: any | object) {
      return {
        ...this.form.company,
        name: loadedCompany.name || "",
        location:
          loadedCompany.locations && loadedCompany.locations.length > 0
            ? loadedCompany.locations[0]
            : ({} as ILocation),
        website: loadedCompany.website || "",
      };
    },

    onConfirmCancel() {
      this.innerVisibility = false;
    },

    beforeCloseHandler() {
      (this.$refs.modal as any).$emit("confirm-cancel-edit");
    },

    gotoSignInModal() {
      this.$emit("sign-in", true);
    },

    onModalClose() {
      this.$emit("close");
    },

    checkFormWrapperScroll() {
      const wrapper = this.$refs.formWrapper as HTMLElement;

      if (!wrapper) {
        return;
      }

      this.formWrapperScrollAtEnd =
        wrapper.scrollTop === wrapper.scrollHeight - wrapper.offsetHeight;
    },

    updateSubmitState() {
      this.isSubmissionDisabled = !allFormFieldsValid(
        this.currentForm,
        this.rules,
      );
    },

    async onSubmitClick() {
      this.isLoadingSubmission = true;
      this.hasServerErrors = false;
      this.errors.clear();

      try {
        const clearedLevels = this.assessmentLevels.map((value: any) => ({
          ...value,
          level: 0,
        }));

        const submitData = {
          ...this.form,
          levels: cloneDeep(clearedLevels),
          affiliate: this.affiliateId,
          group: ENTREPRENEUR_USER_GROUP_ID,
        };

        const response =
          this.companyToken || this.vendor
            ? await pendingUserUpdateProvider.updateCompany({
                ...submitData,
                token: this.companyToken as string,
              })
            : await pendingUserRegisterProvider.create(submitData);

        if (response.email) {
          await this.$store.dispatch(EAffiliatesActions.SET_PENDING_USER, {
            email: response.email,
            auth_token: response.auth_token,
            assessment: {
              levels: cloneDeep(clearedLevels),
              state: SELF_ASSESSMENT_BEGAN_STATE,
            },
          });
        }

        gaTrackEvent("start", "Self-Assessment");
      } catch (error) {
        if (error instanceof ErrorsProviderException) {
          this.errors.record(error.response.data.errors);
        } else {
          this.hasServerErrors = true;
        }
        return;
      } finally {
        this.isLoadingSubmission = false;
        this.$emit("submit");
      }
    },

    handleSectorsChange() {
      this.currentForm.validateField("company.sectors", this.updateSubmitState);
    },
  },
});
</script>

<style lang="scss">
.entrepreneur-sign-up-modal {
  &.el-dialog {
    width: 466px;
    margin: 10px auto;

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .el-dialog__header {
    padding-right: 40px;

    .el-dialog__title {
      letter-spacing: -0.35px;
    }

    @include breakpoint-up(md) {
      padding: 15px 60px 20px 31px;
    }
  }

  .el-dialog__body {
    padding: 0;
  }

  &__form-wrapper {
    position: relative;
    bottom: 4px;
    max-height: calc(100vh - 222px);
    padding: 0 30px;
    overflow: auto;
    overflow-x: hidden;

    @include breakpoint-up(md) {
      width: 466px;
    }

    @include breakpoint-down(lg) {
      padding: 0 15px;
    }

    &.scroll-at-bottom::before {
      opacity: 0;
    }
  }

  &__sign-in-panel {
    min-height: 32px;
    padding: 5px 30px;
    font-size: 13px;
    line-height: 20px;
    color: $ebony-clay;
    background-color: $athens-gray;
    transform: translateY(-4px);

    @include breakpoint-down(sm) {
      padding: 5px 15px;
    }

    &-link {
      @include grotesk(semiBold);

      font-size: 13px;
      text-decoration: underline;
      cursor: pointer;
    }
  }

  &__selector {
    top: 2px;
    word-wrap: break-word;
    white-space: normal;

    .el-checkbox__input {
      @include breakpoint-down(sm) {
        bottom: 24px;
      }
    }

    .el-checkbox__label {
      position: relative;
      top: 19px;
      max-width: 360px;
      padding-left: 12px;

      @include breakpoint-down(sm) {
        max-width: 220px;
      }
    }

    .text--nice-blue {
      text-decoration: none;
    }
  }

  &__selector-text {
    padding-bottom: 16px;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: $ebony-clay;
  }

  &__notice {
    position: relative;
    top: 19px;
    display: flex;
    padding-bottom: 20px;
    margin: 0 auto 0;

    .px-icon {
      position: relative;
      top: 1px;
      right: 2px;
    }

    p {
      @include grotesk(regular, italic);

      padding-left: 5px;
      font-size: 13px;
      line-height: 18px;
    }
  }

  &__button-wrapper {
    width: 100%;

    .el-button {
      width: 100%;
    }
  }

  .sectors-selector__select {
    display: inline-block;
  }
}
</style>
