import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, withModifiers as _withModifiers, mergeProps as _mergeProps, createVNode as _createVNode, normalizeStyle as _normalizeStyle, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-76c9878d"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "compare-responses-table" }
const _hoisted_2 = {
  key: 0,
  class: "compare-responses-table__matching-badge"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PxButton = _resolveComponent("PxButton")!
  const _component_PxStatusBadge = _resolveComponent("PxStatusBadge")!
  const _component_PxIcon = _resolveComponent("PxIcon")!
  const _component_MatchingCardTagList = _resolveComponent("MatchingCardTagList")!
  const _component_DetailsModal = _resolveComponent("DetailsModal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("table", null, [
      _createElementVNode("thead", null, [
        _createElementVNode("tr", null, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tableHeaders, (header, index) => {
            return (_openBlock(), _createElementBlock("th", {
              key: index,
              class: _normalizeClass([{
              'compare-responses-table__headers--no-results':
                !_ctx.allQuestionsAnswered,
            }, "compare-responses-table__headers"])
            }, [
              _createVNode(_component_PxButton, _mergeProps({ ref_for: true }, _ctx.headerButtonProps, {
                label: header,
                icon: "information--light-gray",
                onClick: _withModifiers(($event: any) => (_ctx.showDetailsModal(index)), ["stop"])
              }), null, 16, ["label", "onClick"]),
              (_ctx.labelsStyles[index] && _ctx.allQuestionsAnswered)
                ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                    _createVNode(_component_PxStatusBadge, {
                      "background-color": _ctx.labelsStyles[index].backgroundColor,
                      "border-color": _ctx.labelsStyles[index].borderColor,
                      icon: _ctx.labelsStyles[index].icon,
                      "icon-size": 13,
                      label: _ctx.$t(_ctx.labelsStyles[index].title),
                      "label-color": _ctx.labelsStyles[index].textColor,
                      "label-size": 14,
                      style: _normalizeStyle({ boxShadow: _ctx.labelsStyles[index].boxShadow || '' }),
                      uppercase: false,
                      weight: 600
                    }, null, 8, ["background-color", "border-color", "icon", "label", "label-color", "style"])
                  ]))
                : _createCommentVNode("", true)
            ], 2))
          }), 128))
        ])
      ]),
      _createElementVNode("tbody", null, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.answersData, (data, index) => {
          return (_openBlock(), _createElementBlock("tr", {
            key: `table-answer-${index}`
          }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(data, (answer, answerIndex) => {
              return (_openBlock(), _createElementBlock("td", {
                key: `structure-answer-${answerIndex}`
              }, [
                _createVNode(_component_PxIcon, {
                  ref_for: true,
                  ref: "icon",
                  name: answer.matchIcon,
                  size: 20
                }, null, 8, ["name"]),
                _createVNode(_component_MatchingCardTagList, {
                  capitalize: false,
                  items: answer.responses,
                  "list-format": true,
                  "max-items-to-display": 2,
                  "should-sort-array": false,
                  truncate: true,
                  title: "capitalExplorer.detailsModal.tableHeader[2].title"
                }, null, 8, ["items"])
              ]))
            }), 128))
          ]))
        }), 128))
      ])
    ]),
    (_ctx.detailsModalVisible)
      ? (_openBlock(), _createBlock(_component_DetailsModal, {
          key: 0,
          visibility: _ctx.detailsModalVisible,
          "onUpdate:visibility": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.detailsModalVisible) = $event)),
          "capital-info": _ctx.detailsModalInfo.capitalInfo,
          "match-details": _ctx.detailsModalInfo.matchDetails,
          "match-properties": _ctx.detailsModalInfo.matchProperties
        }, null, 8, ["visibility", "capital-info", "match-details", "match-properties"]))
      : _createCommentVNode("", true)
  ]))
}