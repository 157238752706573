import { IBaseState } from "@/services/store/store-types";
import { ICompanyList } from "@/modules/company-lists/services/data/company-list/company-list.interface";

/**
 * Represents the state structure for the list management module.
 */
export interface IListManagementState extends IBaseState {
  data: {
    /**
     * Array with selected company uids to be added to a list.
     */
    companies: Array<string>;
    lists: Array<ICompanyList>;
  };
}

export enum EListManagementActions {
  ADD_COMPANY = "listManagement/addCompany",
  SET_COMPANIES = "listManagement/setCompanies",
  REMOVE_COMPANY = "listManagement/removeCompany",
  CLEAR_COMPANIES = "listManagement/clearCompanies",
  ADD_LIST = "listManagement/addList",
  REMOVE_LIST = "listManagement/removeList",
  SET_LISTS = "listManagement/setLists",
  CLEAR_LISTS = "listManagement/clearLists",
}

export enum EListManagementMutations {
  SET_DATA = "SET_DATA",
  SET_ERROR = "SET_ERROR",
  SET_LOADING = "SET_LOADING",
}
