import { ActionTree } from "vuex";
import { RootState } from "@/services/store/root-state";
import { getBaseName } from "@/services/store/utils/get-base-name";

import {
  EMilestonePlannerPasscodeActions,
  EMilestonePlannerPasscodeMutations,
  IMilestonePlannerPasscodePayload,
  IMilestonePlannersPasscodeState,
} from "@/modules/milestone-planner/services/store/milestone-planner/sub-modules/passcode/passcode.interface";

export const actions: ActionTree<IMilestonePlannersPasscodeState, RootState> = {
  /**
   * Add a new passcode to the current user store.
   */
  [getBaseName(EMilestonePlannerPasscodeActions.SET)](
    { commit },
    payload: IMilestonePlannerPasscodePayload,
  ) {
    commit(EMilestonePlannerPasscodeMutations.SET, payload);
  },

  /**
   * Reset store by removing all passcodes, this must be done when login-out someone.
   */
  [getBaseName(EMilestonePlannerPasscodeActions.RESET)]({ commit }) {
    commit(EMilestonePlannerPasscodeMutations.RESET);
  },
};
