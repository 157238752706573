<template>
  <div class="submissions-info-badges">
    <PxStatusBadge
      v-for="(badge, index) in badges"
      v-bind="$options.static[badge]"
      :key="badge"
      :label="badgeLabel[index]"
      :label-size="14"
      :uppercase="false"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import PxStatusBadge from "@/components/px-status-badge/px-status-badge.vue";

import { USER_SUBMISSION_INFO } from "@/modules/submissions/constants";

export default defineComponent({
  name: "SubmissionsInfoBadges",

  components: {
    PxStatusBadge,
  },

  static: {
    1: {
      backgroundColor: "#fff3e3",
      labelColor: "#f97316",
    },
    2: {
      backgroundColor: "#e9f2ff",
      labelColor: "#3b82f6",
    },
    3: {
      backgroundColor: "#f3f0ff",
      labelColor: "#8b5cf6",
    },
  },

  props: {
    badges: {
      type: Array as () => Array<number>,
      required: true,
      validator: (value: Array<number>) => {
        return value.every((badge: number) =>
          USER_SUBMISSION_INFO.includes(badge),
        );
      },
    },
  },

  computed: {
    badgeLabel() {
      return this.$tm("userSubmissions.table.badges") as string[];
    },
  },
});
</script>

<style lang="scss" scoped>
.submissions-info-badges {
  display: grid;
  grid-template-columns: 50px 41% auto;
  gap: 4px;
  margin-right: 44px;

  @include breakpoint-down(md) {
    grid-template-columns: auto;
  }

  :deep() .px-status-badge__label {
    line-height: 16px;
    font-weight: 500;
    letter-spacing: -0.3px;
  }
}
</style>
