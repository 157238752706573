export const ROUTE_AUTHENTICATION_ENLIST = "authentication.enlist";
export const ROUTE_AUTHENTICATION_SIGNUP = "authentication.signup";
export const ROUTE_AUTHENTICATION_LOGIN = "authentication.login";
export const ROUTE_AUTHENTICATION_SIGNUP_SUCCESS =
  "authentication.signupSuccess";
export const ROUTE_AUTHENTICATION_PASSWORD_RECOVER =
  "authentication.passwordRecover";
export const ROUTE_AUTHENTICATION_PASSWORD_RESET =
  "authentication.passwordReset";
export const ROUTE_AUTHENTICATION_VERIFY_ACCOUNT =
  "authentication.verifyAccount";
export const ROUTE_AUTHENTICATION_ADMIN_AS_USER = "authentication.adminAsUser";
