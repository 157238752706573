<template>
  <div class="match-details-modal-header">
    <div
      :class="{
        'match-details-modal-header__tip-cta--active': expandedCollapsedItem,
      }"
      class="match-details-modal-header__tip-cta"
    >
      <MatchDetails
        :company-logo="companyLogo"
        :company-name="companyName"
        :has-match="hasMatch"
        :percentage="formattedMatchPercentage"
        data-testid="match-details-modal-header-details"
      />
      <PxButton
        v-bind="improveMatchesButtonProps"
        @click="toogleTipsCollapseItem"
      />
    </div>
    <ElCollapse v-model="expandedCollapsedItem">
      <ElCollapseItem name="tips">
        <div class="match-details-modal-header__tip-wrapper">
          <div
            v-for="(tip, index) in improveTips"
            :key="index"
            class="match-details-modal-header__tips"
          >
            <div>
              <PxIcon :size="14" name="information--light-gray" />
              <span v-html="tip.title" />
            </div>
            <ul v-if="tip.steps">
              <li v-for="(step, stepIndex) in tip.steps" :key="stepIndex">
                <p v-html="step" />
              </li>
            </ul>
          </div>
          <p v-html="improveTipsNote" />
        </div>
      </ElCollapseItem>
    </ElCollapse>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import MatchDetails from "@/components/match-details-modal/match-details.vue";

import {
  EPxButtonSize,
  EPxButtonType,
} from "@/components/px-button/px-button.types";

export default defineComponent({
  name: "MatchDetailsModalHeader",

  components: {
    MatchDetails,
  },

  props: {
    companyName: {
      type: String,
      required: true,
    },

    companyLogo: {
      type: String,
      default: "",
    },

    matchPercentage: {
      type: Number,
      required: true,
    },

    hasMatch: {
      type: Boolean,
      default: false,
    },

    userType: {
      type: Number,
      default: 0,
    },
  },

  static: {
    improveMatchesButton: {
      icon: "icon-matching",
      size: EPxButtonSize.SMALL,
      type: EPxButtonType.DEFAULT,
    },
  },

  data() {
    return {
      expandedCollapsedItem: null as null | string,
    };
  },

  computed: {
    improveMatchesButtonProps(): any {
      return {
        ...this.$options.static.improveMatchesButton,
        label: this.$t("matching.matchDetails.buttons.improveMatches"),
      };
    },

    improveTips() {
      return this.$tm(
        `matching.matchDetails.improveMatches[${this.userType}].tips`,
      ) as Array<{ title: string; steps?: string[] }>;
    },

    improveTipsNote() {
      return this.$t(
        `matching.matchDetails.improveMatches[${this.userType}].note`,
      );
    },

    formattedMatchPercentage(): string {
      return `${Math.trunc(this.matchPercentage)}%`;
    },
  },

  methods: {
    toogleTipsCollapseItem(): string | null {
      return (this.expandedCollapsedItem =
        this.expandedCollapsedItem == null ? "tips" : null);
    },
  },
});
</script>

<style lang="scss" scoped>
.match-details-modal-header {
  margin-top: 2px;

  &__tip-wrapper {
    padding: 23px 80px 22px 25px;
    color: $manatee;
    background-color: $alabaster;
    border-radius: 12px;

    > p {
      font-size: 14px;
      font-style: italic;
      line-height: 20px;
      letter-spacing: -0.06px;
    }
  }

  &__tips {
    margin-bottom: 11px;

    ul {
      margin-top: 7px;
      margin-bottom: 14px;
      padding-inline-start: 42px;
    }

    li {
      margin-bottom: 8px;
      font-size: 15px;
      line-height: 22px;
    }

    li::marker {
      font-size: 18px;
      color: $mischka;
    }

    li > p {
      margin-left: 3px;
      position: relative;
      bottom: 1px;
      line-height: 22px;
      letter-spacing: -0.4px;
    }

    :deep() strong {
      letter-spacing: -0.1px;
    }

    div {
      display: flex;
      align-items: center;
      gap: 10px;
    }

    div span {
      position: relative;
      right: -1px;
      bottom: 1px;
      letter-spacing: -0.3px;
    }
  }

  :deep() .el-collapse-item__wrap {
    margin-bottom: 1px;
    background-color: transparent;

    .el-collapse-item__content {
      padding: 0;
    }
  }

  :deep() .el-collapse-item div[role="tab"] {
    margin-bottom: 30px;

    &::before {
      display: none;
    }
  }

  &__tip-cta :deep() .px-button {
    padding: 0 17px 0 12px;
    margin-top: 2px;

    span {
      position: relative;
      top: 1px;
    }

    span::after {
      content: "";
      position: relative;
      top: 0;
      right: -5px;
      bottom: 0;
      width: 16px;
      height: 16px;
      margin: auto 0;

      background-color: transparent;
      background-image: url("#{$assetsPath}/img/icons/arrow--right-white.svg");
      background-repeat: no-repeat;
      background-position: center;

      transition: $--all-transition;
      filter: brightness(0.55);
      transform: rotate(90deg);
    }

    span div {
      letter-spacing: -0.05px;
    }

    .px-icon {
      filter: brightness(0.55);
    }
  }

  &__tip-cta {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  &__tip-cta--active :deep() .px-button span::after {
    transform: rotate(-90deg);
  }

  :deep() .el-collapse-item__header {
    padding: 0;
    margin: 0;
    background-color: transparent;
    min-height: auto;

    .el-collapse-item__arrow,
    &::before,
    &::after {
      display: none;
    }
  }
}
</style>
