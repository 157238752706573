<template>
  <div class="submission-panel__tabs">
    <div class="submission-tab__nav-menu">
      <span
        v-for="(tab, index) in visiblePanelTabs"
        :key="index"
        :class="{
          'submission-tab__nav-item--active': isActiveTab(tab.key),
        }"
        class="submission-tab__nav-item"
        @click="changeTab(tab.key)"
      >
        {{ tab.label }}
      </span>
    </div>
    <div class="submission-tab__container">
      <SubmissionTabInvestRange
        v-show="isActiveTab(ESubmissionPanelTab.VENTURE_INVESTMENT_LEVEL)"
        :invest-range="submission.investing_level_range"
        class="submission-tab__item"
      />
      <SubmissionTabQuestions
        v-show="isActiveTab(ESubmissionPanelTab.QUESTIONS)"
        :criteria="submission.criteria"
        class="submission-tab__item"
      />
      <SubmissionTabTeamMembers
        v-if="submission.team_members && submission.team_members.length"
        v-show="isActiveTab(ESubmissionPanelTab.TEAM_MEMBERS)"
        :team-members="submission.team_members"
      />
      <SubmissionTabInterests
        v-show="isActiveTab(ESubmissionPanelTab.INTERESTS)"
        :additional-criteria="submission.additional_criteria"
        class="submission-tab__item"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import SubmissionTabInvestRange from "@/modules/supporters/components/submission/submission-tab-invest-range.vue";
import SubmissionTabQuestions from "@/modules/supporters/components/submission/submission-tab-questions.vue";
import SubmissionTabTeamMembers from "@/modules/supporters/components/submission/submission-tab-team-members.vue";
import SubmissionTabInterests from "@/modules/supporters/components/submission/submission-tab-interests.vue";

import { IAffiliateProgramSupporterSubmission } from "@/modules/supporters/services/data/affiliate-program-supporter-submission/affiliate-program-supporter-submission.interface";

enum ESubmissionPanelTab {
  VENTURE_INVESTMENT_LEVEL = 1,
  QUESTIONS = 2,
  TEAM_MEMBERS = 3,
  INTERESTS = 4,
}

interface ISubmissionPanelTab {
  key: number;
  label: string;
  visible: boolean;
}

export default defineComponent({
  components: {
    SubmissionTabInvestRange,
    SubmissionTabQuestions,
    SubmissionTabInterests,
    SubmissionTabTeamMembers,
  },

  props: {
    submission: {
      type: Object as () => IAffiliateProgramSupporterSubmission,
      required: true,
    },
  },

  data() {
    return {
      selectedTab: ESubmissionPanelTab.VENTURE_INVESTMENT_LEVEL,
      ESubmissionPanelTab,
    };
  },

  computed: {
    panelTabs(): Array<ISubmissionPanelTab> {
      return [
        {
          label: this.ventureInvestmentLevelLabel,
          key: ESubmissionPanelTab.VENTURE_INVESTMENT_LEVEL,
          visible: true,
        },
        {
          label: this.$t(
            "supporters.results.submissionPanel.tabs.questions",
          ) as string,
          key: ESubmissionPanelTab.QUESTIONS,
          visible: true,
        },
        {
          label: this.$t(
            "supporters.results.submissionPanel.tabs.teamMembers",
          ) as string,
          key: ESubmissionPanelTab.TEAM_MEMBERS,
          visible:
            !!this.submission.team_members &&
            !!this.submission.team_members.length,
        },
        {
          label: this.$t(
            "supporters.results.submissionPanel.tabs.interests",
          ) as string,
          key: ESubmissionPanelTab.INTERESTS,
          visible: true,
        },
      ];
    },

    visiblePanelTabs(): Array<ISubmissionPanelTab> {
      return this.panelTabs.filter((tab: ISubmissionPanelTab) => tab.visible);
    },

    ventureInvestmentLevelLabel(): string {
      return (
        this.$screen.mdUp
          ? this.$t(
              "supporters.results.submissionPanel.tabs.ventureInvestLevel",
            )
          : this.$t(
              "supporters.results.submissionPanel.tabs.ventureInvestLevelMobile",
            )
      ) as string;
    },
  },

  methods: {
    isActiveTab(tab: ESubmissionPanelTab) {
      return this.selectedTab === tab;
    },

    changeTab(selectedTab: ESubmissionPanelTab) {
      this.selectedTab = selectedTab;
    },
  },
});
</script>

<style lang="scss" scoped>
.submission-panel__tabs {
  position: relative;
}

.submission-tab__item {
  padding: 30px 70px;
}

.submission-tab__nav-menu {
  position: absolute;
  bottom: 100%;
  left: 0;
  padding: 0 24px;

  @include breakpoint-up(md) {
    padding: 0 39px;
  }
}

.submission-tab__nav-item {
  @include grotesk(semiBold);

  position: relative;
  top: 1px;
  display: inline-block;
  padding: 9px 20px;
  font-size: 17px;
  color: $gun-powder;
  cursor: pointer;
}

.submission-tab__nav-item--active {
  color: $bluish;

  &::after {
    position: absolute;
    right: 0;
    bottom: 1px;
    left: 0;
    height: 3px;
    content: "";
    background-color: $bluish;
    border-radius: 2px 2px 0 0;
  }
}
</style>
