import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-2ef944b4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "panel-grid__block" }
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = {
  key: 0,
  class: "profile-panel__mobile-section"
}
const _hoisted_4 = {
  key: 1,
  class: "profile-panel__desktop-section"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PxTabsMobile = _resolveComponent("PxTabsMobile")!
  const _component_ContentPlaceholdersText = _resolveComponent("ContentPlaceholdersText")!
  const _component_ContentPlaceholders = _resolveComponent("ContentPlaceholders")!
  const _component_InvestorsPanelAccordion = _resolveComponent("InvestorsPanelAccordion")!
  const _component_PxPanelSection = _resolveComponent("PxPanelSection")!
  const _component_PxPanel = _resolveComponent("PxPanel")!
  const _component_InvestorsPanelInactive = _resolveComponent("InvestorsPanelInactive")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!!_ctx.latestAssessmentLevels && _ctx.hasUserAccess)
      ? (_openBlock(), _createBlock(_component_PxPanel, {
          key: 0,
          class: "profile-panel profile-panel--investors",
          theme: "with-sections"
        }, {
          header: _withCtx(() => [
            _createElementVNode("h2", {
              class: "px-panel__title",
              innerHTML: _ctx.$t('profile.investors.title')
            }, null, 8, _hoisted_2)
          ]),
          default: _withCtx(() => [
            (_ctx.$screen.smDown)
              ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                  _createVNode(_component_PxTabsMobile, {
                    modelValue: _ctx.selectedTab,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedTab) = $event)),
                    items: _ctx.tabsMobileItems
                  }, null, 8, ["modelValue", "items"]),
                  (_ctx.isLoading)
                    ? (_openBlock(), _createBlock(_component_ContentPlaceholders, {
                        key: 0,
                        animated: true
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ContentPlaceholdersText, {
                            lines: 3,
                            class: "profile-panel__mobile-text"
                          })
                        ]),
                        _: 1
                      }))
                    : (_ctx.nextMilestones.length && _ctx.selectedTab === 'next')
                      ? (_openBlock(), _createBlock(_component_InvestorsPanelAccordion, {
                          key: 1,
                          items: _ctx.nextMilestones
                        }, null, 8, ["items"]))
                      : _createCommentVNode("", true),
                  (_ctx.isLoading)
                    ? (_openBlock(), _createBlock(_component_ContentPlaceholders, {
                        key: 2,
                        animated: true
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ContentPlaceholdersText, {
                            lines: 3,
                            class: "profile-panel__mobile-text"
                          })
                        ]),
                        _: 1
                      }))
                    : (_ctx.achievedMilestones.length && _ctx.selectedTab === 'achieved')
                      ? (_openBlock(), _createBlock(_component_InvestorsPanelAccordion, {
                          key: 3,
                          items: _ctx.achievedMilestones
                        }, null, 8, ["items"]))
                      : _createCommentVNode("", true)
                ]))
              : _createCommentVNode("", true),
            (_ctx.$screen.mdUp)
              ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                  (_ctx.isLoading || _ctx.nextMilestones.length)
                    ? (_openBlock(), _createBlock(_component_PxPanelSection, {
                        key: 0,
                        title: _ctx.$t('profile.investors.nextMilestones'),
                        icon: "flag--outlined"
                      }, {
                        default: _withCtx(() => [
                          (_ctx.isLoading)
                            ? (_openBlock(), _createBlock(_component_ContentPlaceholders, {
                                key: 0,
                                animated: true
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_ContentPlaceholdersText, { lines: 4 })
                                ]),
                                _: 1
                              }))
                            : _createCommentVNode("", true),
                          (_ctx.nextMilestones.length)
                            ? (_openBlock(), _createBlock(_component_InvestorsPanelAccordion, {
                                key: 1,
                                items: _ctx.nextMilestones
                              }, null, 8, ["items"]))
                            : _createCommentVNode("", true)
                        ]),
                        _: 1
                      }, 8, ["title"]))
                    : _createCommentVNode("", true),
                  (_ctx.isLoading || _ctx.achievedMilestones.length)
                    ? (_openBlock(), _createBlock(_component_PxPanelSection, {
                        key: 1,
                        title: _ctx.$t('profile.investors.achievedMilestones'),
                        icon: "flag--checked"
                      }, {
                        default: _withCtx(() => [
                          (_ctx.isLoading)
                            ? (_openBlock(), _createBlock(_component_ContentPlaceholders, {
                                key: 0,
                                animated: true
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_ContentPlaceholdersText, { lines: 4 })
                                ]),
                                _: 1
                              }))
                            : _createCommentVNode("", true),
                          (_ctx.achievedMilestones.length)
                            ? (_openBlock(), _createBlock(_component_InvestorsPanelAccordion, {
                                key: 1,
                                items: _ctx.achievedMilestones
                              }, null, 8, ["items"]))
                            : _createCommentVNode("", true)
                        ]),
                        _: 1
                      }, 8, ["title"]))
                    : _createCommentVNode("", true)
                ]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }))
      : (_openBlock(), _createBlock(_component_InvestorsPanelInactive, { key: 1 }))
  ]))
}