<template>
  <ElInput
    :id="id"
    :ref="id"
    v-model="autocompleteText"
    :placeholder="placeholder"
    @blur="onBlur"
    @focus="onFocus"
    @input="validateInputDebounce"
    @keypress="$emit('keypress', $event)"
    @keyup="$emit('keyup', $event)"
  >
    <template #prefix>
      <PxIcon :size="24" name="location" />
    </template>
  </ElInput>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import debounce from "lodash/debounce";

import emitter from "@/mixins/emitter";

import { ILocation } from "@/services/data/location/location.interface";
import { GEO_COUNTRIES_CONTINENTS } from "@/services/data/location/geo-countries-continents";

export default defineComponent({
  name: "PxInputPlaces",

  mixins: [emitter],

  props: {
    /**
     * Unique identifier to be used with the Google Autocomplete service.
     */
    id: {
      type: String,
      required: true,
    },

    /**
     * Text to be used as a placeholder.
     */
    placeholder: {
      type: String,
      default: null,
    },

    /**
     * When enabled the component will try using the user
     * current location.
     */
    useGeolocation: {
      type: Boolean,
      default: false,
    },

    /**
     * Options to be passed into the geolocation request.
     */
    geolocationOptions: {
      type: Object as () => any,
      default: () => ({}),
    },

    modelValue: {
      type: Object as () => any,
      default: () => null,
    },

    clearable: {
      type: Boolean,
      default: false,
    },

    autocompleteType: {
      type: String as () => string | null,
      default: null,
    },
  },

  data() {
    return {
      /**
       * The Google Autocomplete object.
       *
       * @link https://developers.google.com/maps/documentation/javascript/reference#Autocomplete
       */
      autocomplete: null as any,

      /**
       * Autocomplete input text.
       */
      autocompleteText: "",

      /**
       * Track if user pressed the down key to navigate autocomplete options
       */
      onAutocompleteKeyDownPressed: false,

      location: {
        /**
         * Is a string containing the human-readable address of the selected place.
         */
        formatted_address: "",

        /**
         * Latitude of the current selected place.
         */
        latitude: 0.0,

        /**
         * Longitude of the current selected place.
         */
        longitude: 0.0,

        /**
         * Continent of the selected location belongs to.
         */
        continent: "",

        /**
         * Country of the selected location belongs to.
         */
        country: "",

        /**
         * ISO Country Code of the selected location.
         */
        country_code: "",

        /**
         * Region of the selected location belongs to.
         */
        region: "",

        /**
         * Abbreviation of the region.
         * Needed to differ cities with equal named of different regions (states)
         */
        region_abbreviation: "",

        /**
         * City of the selected location belongs to.
         */
        city: "",

        /**
         * Google Place ID of the selected location.
         */
        google_place_id: "",
      },

      geolocation: {
        /**
         * Google Geocoder Object.
         *
         * @link https://developers.google.com/maps/documentation/javascript/reference#Geocoder
         */
        geocoder: null,

        /**
         * Filled after geolocate result.
         *
         * @link https://developer.mozilla.org/en-US/docs/Web/API/Coordinates
         */
        loc: null,

        /**
         * Filled after geolocate result.
         *
         * @link https://developer.mozilla.org/en-US/docs/Web/API/Position
         */
        position: null,
      },

      validateInputDebounce: () => {
        return;
      },
    };
  },

  watch: {
    modelValue: {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          this.location = newVal;
          this.autocompleteText = newVal.formatted_address;
        }
      },
    },
    location: {
      immediate: true,
      deep: true,
      handler(newLocation) {
        this.dispatch("ElFormItem", "el.form.change", [newLocation]);
      },
    },
    autocompleteText: {
      handler(newVal) {
        if (newVal === "") {
          this.resetLocation();
          this.$emit("input", null);
          this.$emit("update:modelValue", null);
          this.$emit("change", null);
        }
      },
    },
  },

  mounted() {
    this.removeAutocompleteContainers();
    const parentElement = (this.$refs[this.id] as any).$el;
    const inputElement = parentElement.querySelector("input");

    if (!inputElement) {
      return;
    }

    this.autocomplete = new google.maps.places.Autocomplete(inputElement);
    this.applyOnEnterKeypressListener(inputElement);

    if (this.autocompleteType) {
      this.autocomplete.setTypes([this.autocompleteType]);
    }

    this.autocomplete.addListener("place_changed", this.onPlaceChanged);
  },

  created() {
    this.validateInputDebounce = debounce(this.validateInput, 300);
  },

  methods: {
    /**
     * Inform the outside world that when the input
     * is focused.
     */
    onFocus() {
      this.requestAutocompleteBasedOnUserLocation();
      this.$emit("focus");
    },

    onChange() {
      this.$emit("change", this.autocompleteText);
    },

    onBlur() {
      if (this.autocompleteText !== this.location.formatted_address) {
        this.autocompleteText = "";
        this.resetLocation();
        this.$emit("input", null);
        this.$emit("update:modelValue", null);
        this.$emit("change", null);
      }
      this.$emit("blur");
    },

    validateInput() {
      const isLocationDefined =
        this.location && this.location.formatted_address;

      if (
        isLocationDefined &&
        this.autocompleteText !== this.location.formatted_address
      ) {
        this.$emit("input", this.location);
        this.$emit("update:modelValue", this.location);
        this.$emit("change", this.location);
      }
    },

    /**
     * Get an address components based on the given types.
     */
    getAddressComponent(components: any, types: Array<string>) {
      const addressComponents: { [key: string]: any } = {
        // Helper method to fetch address props
        get(prop: string, key?: string) {
          return prop in this && !!this[prop]
            ? this[prop][key || "long_name"]
            : "";
        },
      };

      types.forEach((type: string) => {
        const component = components.find((e: any) => e.types.includes(type));
        addressComponents[type] = component || null;
      });

      return addressComponents;
    },

    getContinentByCountry(components: any) {
      const country = components.find((e: any) => e.types.includes("country"));

      return country.short_name in GEO_COUNTRIES_CONTINENTS
        ? (GEO_COUNTRIES_CONTINENTS as any)[country.short_name]
        : "";
    },

    /**
     * Format result from Geo Google APIs
     */
    formatResult(place: any): { [key: string]: any } {
      const address = this.getAddressComponent(place.address_components, [
        "continent",
        "country",
        "administrative_area_level_1",
        "administrative_area_level_2",
        "locality",
      ]);
      const addressRegion =
        address.get("administrative_area_level_1") ||
        address.get("administrative_area_level_2");
      const addressRegionAbbreviation =
        address.get("administrative_area_level_1", "short_name") ||
        address.get("administrative_area_level_2", "short_name");

      return {
        formatted_address: place.formatted_address,
        latitude: place.geometry.location.lat(),
        longitude: place.geometry.location.lng(),
        continent:
          address.get("continent") ||
          this.getContinentByCountry(place.address_components),
        country: address.get("country"),
        country_code: address.get("country", "short_name"),
        region: addressRegion,
        region_abbreviation:
          addressRegionAbbreviation !== addressRegion
            ? addressRegionAbbreviation
            : "",
        city: address.get("locality"),
        google_place_id: place.place_id,
      };
    },

    onPlaceChanged() {
      const place = this.autocomplete.getPlace();

      // Reset down validator to prevent no blur
      this.onAutocompleteKeyDownPressed = false;

      if (!place.geometry) {
        this.$emit("no-results-found", place);
        return;
      }

      // When the place lack of address components, that means
      // the place doesn't exist.
      if (!place.address_components) {
        return;
      }

      this.location = this.formatResult(place) as ILocation;
      this.$emit("input", this.location);
      this.$emit("update:modelValue", this.location);

      this.autocompleteText = !this.clearable
        ? this.location.formatted_address
        : "";
      this.onChange();
      this.resetLocation();
    },

    /**
     * Reset selected location.
     */
    resetLocation() {
      this.location = (this.$options.data as any).call(this).location;
    },

    /**
     * Update internal location from navigator geolocation.
     */
    async updateGeolocation(): Promise<any> {
      if (!this.useGeolocation) {
        throw new Error("Geolocation not enabled.");
      }

      return new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const geolocation = {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            };

            (this.geolocation as any).loc = geolocation;
            (this.geolocation as any).position = position;

            resolve({ geolocation, position });
          },
          (error) => {
            const errorMessage = "Cannot get Coordinates from navigator";
            this.$emit("error", errorMessage, error);
            reject(new Error(errorMessage));
          },
        );
      });
    },

    async requestAutocompleteBasedOnUserLocation() {
      if (!this.useGeolocation) {
        return;
      }

      // Try getting the current use location and make a autocomplete
      // request to Google Places API
      try {
        const { geolocation, position } = await this.updateGeolocation();

        const circle = new google.maps.Circle({
          center: geolocation,
          radius: position.coords.accuracy,
        });

        this.autocomplete.setBounds(circle.getBounds());
      } catch (_) {
        // This means that the user doesn't the geolocation service
        // active or don't gave us permission.
        return;
      }
    },

    /**
     * Remove previous autocomplete containers from DOM
     */
    removeAutocompleteContainers() {
      document
        .querySelectorAll(".pac-container")
        .forEach(
          (item: Element) =>
            item && item.parentNode && item.parentNode.removeChild(item),
        );
    },

    /**
     * Apply listener to catch first selected element on enter
     * @param inputElement
     */
    applyOnEnterKeypressListener(inputElement: HTMLElement) {
      inputElement.addEventListener("keydown", (event: KeyboardEvent) => {
        if (
          event.key !== "ArrowDown" &&
          event.key !== "Enter" &&
          event.isTrusted
        ) {
          return;
        }

        event.stopPropagation();

        if (event.key === "ArrowDown") {
          this.onAutocompleteKeyDownPressed = true;
        }

        if (event.key === "Enter") {
          // If user isn't navigating using arrows and this hasn't ran yet
          if (!this.onAutocompleteKeyDownPressed) {
            const keyDownEvent = new Event("keydown");
            (keyDownEvent as any).keyCode = 40;

            google.maps.event.trigger(event.target, "keydown", keyDownEvent);
          }
        }
      });
    },
  },
});
</script>

<style lang="scss">
.pac-container {
  z-index: z("overlay");
}
</style>
