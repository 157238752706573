import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PasscodeAccessModal = _resolveComponent("PasscodeAccessModal")!
  const _component_RegistrationModals = _resolveComponent("RegistrationModals")!

  return (_ctx.isGuardVisible)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_ctx.requiresPasscode)
          ? (_openBlock(), _createBlock(_component_PasscodeAccessModal, {
              key: 0,
              "has-errors": _ctx.invalidPasscode,
              "onUpdate:hasErrors": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.invalidPasscode) = $event)),
              "is-data-loading": _ctx.isLoading,
              "needs-identification": _ctx.isIdentificationRequired,
              "requested-data-uid": _ctx.listUid,
              visibility: _ctx.requiresPasscode,
              "no-passcode-message": "companyLists.accessModal.noCodeInfo",
              onSetPasscode: _ctx.makeRequestWithPasscode
            }, null, 8, ["has-errors", "is-data-loading", "needs-identification", "requested-data-uid", "visibility", "onSetPasscode"]))
          : _createCommentVNode("", true),
        (!_ctx.requiresPasscode && !_ctx.isLoading)
          ? (_openBlock(), _createBlock(_component_RegistrationModals, {
              key: 1,
              "is-data-loading": _ctx.isLoading,
              "requires-passcode": !!_ctx.passcode,
              onSubmitGuestForm: _ctx.enterAsGuestUser,
              onSubmitLoggedForm: _ctx.enterAsLoggedUser
            }, null, 8, ["is-data-loading", "requires-passcode", "onSubmitGuestForm", "onSubmitLoggedForm"]))
          : _createCommentVNode("", true)
      ]))
    : _createCommentVNode("", true)
}