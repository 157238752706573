/**
 * Structure for the profile state.
 */
// Removed this for now, can be useful in the future
// export interface IProfileState extends IBaseState {}

export enum EProfileMutations {
  SET_ERROR = "SET_ERROR",
  SET_LOADING = "SET_LOADING",
}

export enum EProfileCompanyActions {
  FETCH = "profile/company/fetch",
  RESET = "profile/company/reset",
}

export enum EProfileSupporterActions {
  FETCH = "profile/supporter/fetch",
  PATCH = "profile/supporter/patch",
}
