<template>
  <div class="sectors-selector">
    <div
      :class="{
        'sectors-selector__list--with-sectors': selectedSectors.length,
      }"
      class="sectors-selector__list"
    >
      <label
        v-if="!noLabel"
        class="el-form-item__label sectors-selector__title"
      >
        {{ $t(labelCopy) }}
      </label>
      <div
        v-for="sector in selectedSectors"
        :key="sector.value"
        class="sectors-selector__sector"
      >
        <span class="sectors-selector__sector-name">
          {{ sector.label }}
        </span>
        <i
          class="icon icon-trash--dark"
          @click="onClickRemoveSector(sector.value)"
        />
      </div>
    </div>
    <ElSelect
      ref="select"
      :loading="sectorSelectLoading"
      :placeholder="$t('selfAssessment.companyInfoModal.placeholders.sector')"
      :remote-method="searchSectors"
      class="sectors-selector__select"
      filterable
      remote
      @blur="blurHandler"
      @change="onSectorSelect"
    >
      <ElOption
        v-for="item in listOfSectors"
        :key="item.value"
        :label="item.label"
        :value="item"
        class="sectors-selector__select-options"
      />
      <template #prefix>
        <PxIcon :size="24" name="magnifier" />
      </template>
    </ElSelect>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import emitter from "@/mixins/emitter";

import { sectorProvider } from "@/services/data/sector/sector.provider";
import { ISector } from "@/services/data/sector/sector.interface";

let requestTimer: any = null;

interface ISectorOption {
  value: number;
  label: string;
}

export default defineComponent({
  name: "SectorsSelector",

  mixins: [emitter],

  props: {
    modelValue: {
      type: Array as () => number[],
      required: true,
    },

    /**
     * When set to `true` the label won't be rendered.
     */
    noLabel: {
      type: Boolean,
      default: false,
    },

    labelCopy: {
      type: String,
      default: "selfAssessment.companyInfoModal.fields.sector",
    },
  },

  data() {
    return {
      listOfSectors: null as null | Array<ISectorOption>,
      sectorSelectLoading: false,

      // Will stores the list of selected sectors
      selectedSectors: [] as Array<ISectorOption>,
    };
  },

  watch: {
    selectedSectors: {
      deep: true,
      handler(newVal: Array<ISectorOption>) {
        const ids = newVal.map((entry: ISectorOption) => entry.value);

        this.$emit("update:modelValue", ids);

        this.$emit("change", newVal);
      },
    },
  },

  methods: {
    /**
     * Allow to set the active sectors.
     */
    setSectors(sectors: Array<ISector>) {
      this.selectedSectors = sectors.map((entry: ISector) => ({
        label: entry.name,
        value: entry.id,
      }));
    },

    async searchSectorsRequest(query: string) {
      // Never make an API request when the query is null
      // or a string with less than 2 characters.
      if (!query || query.length < 2) {
        this.listOfSectors = [];
        return;
      }

      this.sectorSelectLoading = true;

      const data = (await sectorProvider.list({
        filter: query,
      })) as Array<ISector>;

      // ElAutocomplete component needs a value fields,
      // that will be used as the selected value.
      const allSectors = data.map(
        (sector: ISector) =>
          ({
            value: sector.id,
            label: sector.name,
          }) as ISectorOption,
      );
      // Get current selected sectors ids
      const selectedIds = this.selectedSectors.map(
        (item: ISectorOption) => item.value,
      );
      // Remove selected sectors from all sectors
      this.listOfSectors = allSectors.filter(
        (entry: ISectorOption) => !selectedIds.includes(entry.value),
      );

      this.sectorSelectLoading = false;
    },

    /**
     * Search for sectors on the API.
     */
    async searchSectors(query: string) {
      if (requestTimer) {
        clearTimeout(requestTimer);
      }

      requestTimer = setTimeout(() => this.searchSectorsRequest(query), 400);
    },

    /**
     * When a new sector is selected it is pushed into the
     * array of selected sectors and the autocomplete fill
     * cleared.
     */
    onSectorSelect(selectedValue: ISectorOption) {
      this.selectedSectors.push(selectedValue);
      this.listOfSectors = [];

      this.$emit("change", selectedValue);
    },

    /**
     * Remove the given sector from the list of selected sectors.
     */
    onClickRemoveSector(sectorId: number) {
      const indexToRemove = this.selectedSectors.findIndex(
        (sector: ISectorOption) => sector.value === sectorId,
      );
      this.selectedSectors.splice(indexToRemove, 1);
    },

    blurHandler(event: any) {
      this.$emit("blur", event);
      this.$emit("change", this.selectedSectors);
    },
  },
});
</script>

<style lang="scss" scoped>
.sectors-selector__list--with-sectors {
  margin-bottom: 12px;
}

.sectors-selector__title {
  display: block;
}

.sectors-selector__sector {
  display: flex;
  justify-content: space-between;
  padding: 7px 15px 8px 16px;
  margin-bottom: 12px;
  clear: both;

  font-size: 0.9333rem;
  text-transform: capitalize;

  background-color: $athens-gray;
  border-radius: 2px;

  &:last-child {
    margin-bottom: 0;
  }

  &-name {
    max-width: 178px;

    @include breakpoint-up(md) {
      max-width: 100%;
    }
  }

  span {
    color: $gun-powder;
  }

  i {
    position: relative;
    top: 6px;
    width: 14px;
    height: 14px;
    min-width: 14px;

    cursor: pointer;
  }

  i:hover {
    background-image: url("#{$assetsPath}/img/icons/trash.svg");
  }
}

.sectors-selector__select-options :deep(span) {
  text-transform: capitalize;
}

.sectors-selector__select {
  :deep(.el-select__wrapper) {
    height: 40px;
    background: $white;
    padding: 4px 8px;
  }

  :deep(.el-select__prefix::after) {
    top: 50%;
    left: 32px;
    width: 1px;
    height: 28px;

    transform: translateY(-50%);
  }

  :deep(.el-select__selection) {
    padding-right: 15px;
    padding-left: 15px;
  }

  :deep(.el-select__placeholder span) {
    color: $manatee;
    line-height: 1;
  }
}
</style>
