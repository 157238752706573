<template>
  <div class="grid-legend-container">
    <ElTooltip
      v-if="tooltipText"
      placement="top"
      popper-class="grid-legend-container__tooltip el-abaca-tooltip"
    >
      <template #content>
        <div v-text="tooltipText" />
      </template>
      <i class="icon icon-question" />
    </ElTooltip>
    <div v-for="(el, key) in legend" :key="key">
      <MilestoneStageStatusItem
        :icon="el.icon"
        :icon-size="el.iconSize"
        :is-clickable="false"
        :title="el.title"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import MilestoneStageStatusItem from "@/modules/milestone-planner/components/milestone-stage-status-item/milestone-stage-status-item.vue";

export interface ILegendItems {
  title: string;
  icon: string;
  iconSize: number;
}

export default defineComponent({
  name: "GridLegendContainer",

  components: {
    MilestoneStageStatusItem,
  },

  props: {
    legend: {
      required: true,
      type: Array as () => Array<ILegendItems>,
    },
    tooltipText: {
      type: String,
      default: "",
    },
  },
});
</script>

<style lang="scss">
.grid-legend-container__tooltip {
  width: 340px;
  text-transform: none;
}
</style>

<style lang="scss" scoped>
.grid-legend-container {
  position: relative;
  display: grid;
  grid-template-areas:
    "top top"
    "bottom bottom";
  grid-template-columns: 122px auto;
  max-width: 352px;
  padding: 5px 2px 4px 4px;
  border: solid 1px $athens-gray;
  border-radius: 4px;

  :deep() .px-stage-status-item {
    justify-content: left;
    padding: 0 7px;

    &__title {
      margin: 0 0 0 5px;
    }

    h3 {
      font-size: 14px;
      font-weight: normal;
      line-height: 20px;
      color: $ebony-clay;
      text-transform: none;
      letter-spacing: -0.3px;
    }
  }

  // Apply when it has only 3 elements
  div:first-of-type:nth-last-child(3) {
    grid-area: top;

    .px-stage-status-item:nth-child(3) {
      position: relative;
      right: 4px;
    }
  }

  .el-tooltip__trigger {
    position: absolute;
    top: 5px;
    right: 4px;
    z-index: z("default");
    width: 18px;
    height: 18px;
  }
}
</style>
