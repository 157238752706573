import {
  EProviderFeatures,
  GenericProvider,
  Provider,
} from "@/services/data/generic-provider";
import { IMatchingCriteriaWeight } from "./matching-criteria.interface";
import { baseAPIUrl } from "@/services/utils/utils";

@Provider(`${baseAPIUrl}/criteriaweight`, [EProviderFeatures.LIST])
class MatchingCriteriaWeightProvider extends GenericProvider<IMatchingCriteriaWeight> {}

export const matchingCriteriaWeightProvider =
  new MatchingCriteriaWeightProvider();
