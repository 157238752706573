import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-02971924"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "share-modal-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ShareModalUsersSearch = _resolveComponent("ShareModalUsersSearch")!
  const _component_ShareModalUsers = _resolveComponent("ShareModalUsers")!
  const _component_ShareModalActions = _resolveComponent("ShareModalActions")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ShareModalUsersSearch, {
      "filter-by": _ctx.filterBy,
      "share-succeeded": _ctx.shareSucceeded,
      users: _ctx.users,
      class: "share-modal-content__search",
      onSendEmail: _ctx.onSendEmail
    }, null, 8, ["filter-by", "share-succeeded", "users", "onSendEmail"]),
    _createVNode(_component_ShareModalUsers, {
      copy: _ctx.usersCopy,
      users: _ctx.users,
      class: "share-modal-content__users"
    }, null, 8, ["copy", "users"]),
    _createVNode(_component_ShareModalActions, {
      copy: _ctx.actionsCopy,
      link: _ctx.link,
      passcode: _ctx.passcode,
      class: "share-modal-content__actions"
    }, null, 8, ["copy", "link", "passcode"])
  ]))
}