import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, mergeProps as _mergeProps, createBlock as _createBlock, createVNode as _createVNode, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_StatsCard = _resolveComponent("StatsCard")!
  const _component_CompaniesDataChart = _resolveComponent("CompaniesDataChart")!
  const _component_ListReportsChart = _resolveComponent("ListReportsChart")!
  const _component_ProcessReportsChart = _resolveComponent("ProcessReportsChart")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["demographic-charts", {
      'demographic-charts--full-width': !_ctx.isListsReportsView,
    }])
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.cards, (stats, index) => {
      return (_openBlock(), _createBlock(_component_StatsCard, _mergeProps({ ref_for: true }, stats, {
        key: `demographic-stats-${index}`
      }), null, 16))
    }), 128)),
    _createVNode(_component_CompaniesDataChart, {
      data: _ctx.data,
      "process-reports-data": _ctx.processReportsData,
      view: _ctx.view,
      theme: _ctx.theme
    }, null, 8, ["data", "process-reports-data", "view", "theme"]),
    (_ctx.isListsReportsView)
      ? (_openBlock(), _createBlock(_component_ListReportsChart, {
          key: 0,
          data: _ctx.data,
          "chart-title": _ctx.chartsTitle,
          "chart-description": _ctx.chartsDescription,
          "show-locked-state": _ctx.showLockedState,
          "locked-state": _ctx.lockedStateCopy,
          theme: _ctx.theme.title,
          note: _ctx.chartNote
        }, null, 8, ["data", "chart-title", "chart-description", "show-locked-state", "locked-state", "theme", "note"]))
      : (_openBlock(), _createBlock(_component_ProcessReportsChart, {
          key: 1,
          data: _ctx.processReportsData,
          "chart-title": _ctx.chartsTitle,
          "chart-description": _ctx.chartsDescription,
          "locked-state": _ctx.lockedStateCopy,
          theme: _ctx.theme.title
        }, null, 8, ["data", "chart-title", "chart-description", "locked-state", "theme"]))
  ], 2))
}