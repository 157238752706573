<template>
  <ElDialog
    ref="modal"
    v-model="innerVisibility"
    :title="$t('profile.assessmentDetails.title')"
    class="assessment-details"
  >
    <div class="assessment-details__nav">
      <ElSelect
        v-model="currentSelectAssessment"
        :disabled="!hasMultipleAssessments"
        class="assessment-details__select"
      >
        <ElOption
          v-for="(assessment, key) in assessmentList"
          :key="key"
          :label="assessmentTitle(assessment, key)"
          :value="key"
        >
          <span>
            {{ assessmentTitle(assessment, key) }}
          </span>
        </ElOption>
      </ElSelect>
      <div
        v-if="$screen.mdUp && hasMultipleAssessments"
        class="assessment-details__buttons"
      >
        <PxButton
          :disabled="onOldestAssessment"
          class="assessment-details__arrow"
          @click="prevAssessment"
        >
          <i class="icon icon-prev-arrow" />
        </PxButton>
        <PxButton
          :disabled="onLatestAssessment"
          class="assessment-details__arrow"
          @click="nextAssessment"
        >
          <i class="icon icon-next-arrow" />
        </PxButton>
      </div>
    </div>
    <div class="assessment-details__grid">
      <PxGrid
        :categories="categories"
        :current-level="currentAssessmentLevelValue"
        :levels="numOfLevels"
        :results="assessmentResults"
        :size="gridSize"
      />
      <div
        v-if="$screen.mdDown"
        :class="{
          'assessment-details__mobile-nav--center': !hasMultipleAssessments,
        }"
        class="assessment-details__mobile-nav"
      >
        <PxButton
          v-if="hasMultipleAssessments"
          :disabled="onOldestAssessment"
          class="assessment-details__arrow"
          @click="prevAssessment"
        >
          <i class="icon icon-prev-arrow" />
        </PxButton>
        <div class="assessment-details__date">
          {{ $t("profile.assessmentDetails.date") }}
          {{ formattedDate(currentAssessmentDate) }}
        </div>
        <PxButton
          v-if="hasMultipleAssessments"
          :disabled="onLatestAssessment"
          class="assessment-details__arrow"
          @click="nextAssessment"
        >
          <i class="icon icon-next-arrow" />
        </PxButton>
      </div>
      <div class="assessment-details__info">
        <div v-if="$screen.mdUp" class="assessment-details__date">
          {{ $t("profile.assessmentDetails.date") }}
          {{ formattedDate(currentAssessmentDate) }}
        </div>
        <PxButton
          class="el-button--link el-button--link-dark el-button--small"
          icon="icon-information"
          type="link"
          @click="onClickOpenHowItWorksLink"
        >
          {{ $t("profile.assessment.gridPanel.moreLink") }}
        </PxButton>
      </div>
    </div>
    <div class="assessment-details__results">
      <div class="results-report">
        <div
          v-for="category in categories"
          :key="category.id"
          :class="{
            'results-report-entry--not-achieved':
              levelForCategory(category) === 0,
          }"
          class="results-report-entry"
        >
          <div class="results-report-entry__header">
            <PxBadge
              :color="getCategoryColor(category)"
              :value="levelForCategory(category)"
              class="results-report-entry__level"
            />
            <span class="results-report-entry__name">
              {{ category.name }}
            </span>
          </div>
          <div class="results-report-entry__description">
            {{ selectedLevelDescription(category) }}
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="showMilestonePlannerCta"
      class="assessment-details__milestone-planner-action"
    >
      <p>
        {{ $t("profile.milestonePlannerAction.title") }}
      </p>
      <PxButton size="small" type="green" @click="goToMilestonePlannerPage">
        {{ $t("profile.milestonePlannerAction.cta") }}
      </PxButton>
    </div>
  </ElDialog>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import moment from "moment";
import PxGrid from "@/components/px-grid/px-grid.vue";
import {
  IAssessment,
  IAssessmentLevel,
} from "@/services/data/assessment/assessment.interface";
import { ICategoryLevelSelection } from "@/services/data/viral-level/viral-level.interface";
import {
  ICategory,
  ICategoryDetail,
} from "@/services/data/category/category.interface";
import { ICategoryState } from "@/services/store/category/category-types";
import { ICompany } from "@/modules/profile/services/data/company/company.types";
import { ROUTE_MILESTONE_PLANNER_OVERVIEW } from "@/modules/milestone-planner/services/router/routes-names";
import { activeModules } from "@/services/utils/utils";

export default defineComponent({
  name: "AssessmentDetails",

  components: {
    PxGrid,
  },

  props: {
    visibility: {
      type: Boolean,
      required: false,
    },

    assessmentList: {
      type: Array as () => IAssessment[],
      required: true,
    },

    selectedAssessment: {
      type: Number,
      default: 0,
    },
  },

  data() {
    return {
      innerVisibility: false,
      currentSelectAssessment: 0,
    };
  },

  computed: {
    currentAssessment(): IAssessment {
      return this.assessmentList[this.currentSelectAssessment];
    },
    currentAssessmentLevelValue(): number {
      return this.currentAssessment && this.currentAssessment.level
        ? this.currentAssessment.level.value
        : 1;
    },
    currentAssessmentDate(): string {
      return this.currentAssessment ? this.currentAssessment.created_at : "";
    },
    numOfLevels(): number {
      return this.$store.get("levels/data").length;
    },
    assessmentResults(): Array<IAssessmentLevel> {
      return this.currentAssessment ? this.currentAssessment.data : [];
    },
    onLatestAssessment(): boolean {
      return this.currentSelectAssessment === 0;
    },
    onOldestAssessment(): boolean {
      return this.currentSelectAssessment + 1 === this.assessmentList.length;
    },
    categories(): Array<ICategory> {
      const categoriesStore = this.$store.state.categories as ICategoryState;
      return [...categoriesStore.data].sort((a, b) => a.order - b.order);
    },
    gridSize(): string {
      return this.$screen.smDown ? "small" : "default";
    },
    company(): ICompany {
      return this.$store.get("auth/company.data");
    },
    companyName(): string {
      return this.company ? this.company.name : "";
    },
    hasMultipleAssessments(): boolean {
      return this.assessmentList.length > 1;
    },
    isMilestonePlannerModuleActive(): boolean {
      return activeModules().includes("milestone-planner");
    },
    showMilestonePlannerCta(): boolean {
      return (
        this.$user.isEntrepreneur() &&
        this.$user.isOwner() &&
        this.isMilestonePlannerModuleActive
      );
    },
  },

  watch: {
    visibility(newVal, oldVal) {
      if (newVal === oldVal) {
        return;
      }

      this.innerVisibility = newVal;
    },

    innerVisibility(newVal: boolean) {
      this.$emit("update:visibility", newVal);
    },

    selectedAssessment(newAssessment: number) {
      this.currentSelectAssessment = newAssessment;
    },
  },

  methods: {
    formattedDate(date: string) {
      return moment(date).format("MMMM D, YYYY - HH:mmA");
    },

    assessmentTitle(assessment: IAssessment, index: number) {
      const current =
        index === 0
          ? `(${this.$t("profile.assessment.historyPanel.current")}) `
          : "";
      return `${current}${this.$t("profile.assessmentDetails.level")} ${
        assessment.level.value
      } - ${this.formattedDate(assessment.created_at)}`;
    },

    prevAssessment() {
      this.currentSelectAssessment++;
    },

    nextAssessment() {
      this.currentSelectAssessment--;
    },

    levelForCategory(category: ICategory): number {
      const levelSection = this.assessmentResults.find(
        (record: IAssessmentLevel) => record.category === category.id,
      ) as ICategoryLevelSelection;
      return (
        levelSection ? (levelSection.level ? levelSection.level : 0) : 0
      ) as number;
    },

    getCategoryColor(category: ICategory) {
      const level = this.levelForCategory(category);
      const color: string = level !== 0 ? category.color : "f0f1f5";
      return `#${color}`;
    },

    selectedLevelDescription(category: ICategory): string {
      const categoryLevel = this.levelForCategory(category);
      const details = category.categoryDetails.find(
        (entry: ICategoryDetail) => entry.level.value === categoryLevel,
      ) as ICategoryDetail;

      if (!details) {
        return this.$t(
          "selfAssessment.results.detailsPanel.placeholderNotAchieved",
        ) as string;
      }

      return details.description;
    },

    onClickOpenHowItWorksLink() {
      window.open("https://abaca.app/how-it-works/");
    },

    goToMilestonePlannerPage() {
      this.$router.push({ name: ROUTE_MILESTONE_PLANNER_OVERVIEW });
    },
  },
});
</script>

<style lang="scss">
.assessment-details.el-dialog {
  width: 903px;
  max-width: calc(100% - 30px);

  .assessment-details__milestone-planner-action {
    position: relative;
    display: block;
    margin-bottom: 2px;
    text-align: center;

    &::before {
      position: absolute;
      top: -1px;
      left: 50%;
      width: 100%;
      height: 1px;
      content: "";
      background-color: #f0f1f5;
      transform: translateX(-50%);
    }

    p {
      padding-top: 31px;
      margin: 39px 0 17px 1px;
      font-size: 20px;
      font-weight: 600;
      line-height: 30px;
      color: $ebony-clay;
    }
  }

  .px-grid.px-grid--small {
    .px-grid__main-area {
      max-width: 245px;
    }

    .px-grid-matrix__column {
      max-width: 26px;
    }

    .px-grid__current-level::before {
      width: 261px;
    }
  }

  .assessment-details__mobile-nav {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: space-between;
    max-width: 330px;
    margin: 16px auto 0;

    &--center {
      justify-content: center;
    }
  }

  .assessment-details__nav {
    display: flex;
    flex-flow: column wrap;
    align-items: center;
    justify-content: left;
    margin-top: 13px;

    @include breakpoint-up(md) {
      flex-flow: row wrap;
      max-width: 740px;
      padding-left: 22px;
      margin: 7px auto 0;
    }
  }

  .assessment-details__select {
    width: 330px;

    .el-select__wrapper.is-disabled {
      color: $gun-powder;
      background: linear-gradient(to bottom, $white, #f6f6f6);
      opacity: 1;
      border: none;
    }

    .el-select__wrapper {
      padding-right: 7px;
    }

    .el-select__wrapper.is-disabled .el-select__suffix {
      display: none;
    }
  }

  .assessment-details__select input {
    text-overflow: ellipsis;
  }

  .assessment-details__buttons {
    margin-left: 14px;
  }

  .assessment-details__export-tip-icon {
    margin-left: 10px;
  }

  .assessment-details__arrow {
    min-width: 40px;
    height: 40px;

    &:not(:first-child) {
      margin-left: 8px;
    }

    .icon {
      position: relative;
      width: 14px;
      height: 14px;
    }

    .icon-prev-arrow {
      left: -1px;
    }

    .icon-next-arrow {
      left: 1px;
    }
  }

  .assessment-details__grid {
    margin: 21px auto 0;
    color: $ebony-clay;

    @include breakpoint-up(md) {
      position: relative;
      left: 12px;
      margin-top: 29px;
    }
  }

  .assessment-details__info {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    max-width: 659px;
    margin: 11px auto 0;

    @include breakpoint-up(md) {
      justify-content: space-between;
    }

    .px-button {
      gap: 5px;
    }
  }

  .assessment-details__date {
    @include grotesk(regular, italic);

    max-width: calc(100% - 130px);
    font-size: 13px;
    line-height: 20px;
    text-align: center;
    letter-spacing: -0.2px;

    @include breakpoint-up(md) {
      padding-top: 9px;
      font-size: 14px;
      letter-spacing: 0;
    }
  }

  .assessment-details__results {
    margin: 30px auto 0;

    @include breakpoint-up(md) {
      padding-top: 5px;
      margin-top: 24px;
      border-top: solid 1px $athens-gray;
    }
  }

  .results-report {
    padding: 0 6px;
    color: $ebony-clay;

    @include breakpoint-up(md) {
      padding: 0 51px;
    }
  }

  .results-report-entry {
    display: inline-block;
    width: 220px;
    margin-top: 25px;
    margin-right: 40px;
    vertical-align: top;

    @include breakpoint-down(sm) {
      width: 100%;
      padding-right: 3px;
      margin: 0 0 19px -1px;
    }

    &::after {
      display: block;
      width: 100%;
      height: 1px;
      margin-top: 18px;

      content: "";

      background-color: $athens-gray;

      @include breakpoint-up(md) {
        display: none;
      }
    }

    &:nth-child(3n) {
      @include breakpoint-up(md) {
        margin-right: 0;
      }
    }

    &:last-child {
      margin-bottom: 0;

      &::after {
        display: none;
      }
    }

    &__header {
      display: block;
      margin-bottom: 7px;

      @include breakpoint-up(md) {
        margin-bottom: 5px;
      }
    }

    &__level {
      top: 6px;

      @include breakpoint-up(md) {
        top: 5px;
      }
    }

    &__name {
      @include grotesk(semiBold);

      padding-left: 7px;

      font-size: 1rem;

      @include breakpoint-up(md) {
        padding-left: 8px;

        font-size: 0.9333rem;
      }
    }

    &__description {
      font-size: 1rem;
      line-height: 1.6667rem;
      letter-spacing: -0.19px;

      @include breakpoint-up(md) {
        font-size: 0.9333rem;
      }
    }
  }

  .results-report-entry--not-achieved {
    .results-report-entry__name {
      color: $manatee;
    }

    .results-report-entry__description {
      @include grotesk(regular, italic);

      position: relative;
      top: 1px;
      left: 1px;
    }

    .el-badge .el-badge__content {
      color: transparent !important;
    }
  }
}
</style>
