import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["textContent"]
const _hoisted_2 = ["textContent"]
const _hoisted_3 = { class: "list-share-modal__body" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DialogConfirmation = _resolveComponent("DialogConfirmation")!
  const _component_PxModalMessage = _resolveComponent("PxModalMessage")!
  const _component_ListShareUsers = _resolveComponent("ListShareUsers")!
  const _component_ListShareSettings = _resolveComponent("ListShareSettings")!
  const _component_ElDialog = _resolveComponent("ElDialog")!

  return (_openBlock(), _createBlock(_component_ElDialog, {
    ref: "modal",
    modelValue: _ctx.innerVisibility,
    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.innerVisibility) = $event)),
    "append-to-body": true,
    "before-close": _ctx.beforeCloseHandler,
    title: _ctx.shareModalTitle,
    class: "list-share-modal",
    width: "659px"
  }, {
    header: _withCtx(() => [
      _createElementVNode("span", {
        class: "el-dialog__title",
        textContent: _toDisplayString(_ctx.shareModalTitle)
      }, null, 8, _hoisted_1),
      _createElementVNode("span", {
        class: "el-dialog__subtitle",
        textContent: _toDisplayString(_ctx.name)
      }, null, 8, _hoisted_2)
    ]),
    default: _withCtx(() => [
      _createVNode(_component_DialogConfirmation, {
        ref: "dialog-confirmation",
        class: "list-share-modal__overlay-confirm"
      }, null, 512),
      _createVNode(_component_PxModalMessage, {
        visible: _ctx.hasServerError,
        "onUpdate:visible": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.hasServerError) = $event))
      }, null, 8, ["visible"]),
      _createVNode(_component_PxModalMessage, {
        visible: _ctx.userHasBeenRemoved,
        "onUpdate:visible": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.userHasBeenRemoved) = $event)),
        timeout: 5000,
        title: _ctx.successUserRemovalMessage,
        type: "success"
      }, null, 8, ["visible", "title"]),
      _createVNode(_component_PxModalMessage, {
        visible: _ctx.passcodeWasChanged,
        "onUpdate:visible": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.passcodeWasChanged) = $event)),
        timeout: 5000,
        title: _ctx.$t(`companyLists.list.shareModal.messages.passcodeChanged`),
        type: "success"
      }, null, 8, ["visible", "title"]),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_ListShareUsers, {
          link: _ctx.shareableLink,
          passcode: _ctx.passcode,
          uid: _ctx.uid,
          users: _ctx.users
        }, null, 8, ["link", "passcode", "uid", "users"]),
        _withDirectives(_createVNode(_component_ListShareSettings, {
          link: _ctx.shareableLink,
          passcode: _ctx.passcode,
          class: "list-share-modal__appendix",
          onSave: _ctx.saveSettingsHandler
        }, null, 8, ["link", "passcode", "onSave"]), [
          [_vShow, _ctx.settingsVisibility]
        ])
      ])
    ]),
    _: 1
  }, 8, ["modelValue", "before-close", "title"]))
}