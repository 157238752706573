import axios, { AxiosRequestConfig } from "axios";
import { i18n } from "@/services/i18n/i18n";
import { baseAPIUrl } from "@/services/utils/utils";

const axiosInstance = axios.create({
  baseURL: baseAPIUrl,

  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
  withCredentials: true,
});

axiosInstance.interceptors.request.use((config: AxiosRequestConfig) => {
  // TODO: Remove state import on user manager to avoid circular dependency
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const { userManager } = require("@/modules/authentication/user-manager");

  // Add Authorization header to HTTP requests, containing the user token,
  // if the user is authenticated
  if (userManager.isLogged()) {
    config.headers.Authorization = `Token ${userManager.getToken()}`;
  } else if (userManager.isPendingUser()) {
    config.headers.Authorization = `Token ${userManager.getPendingUserAuthToken()}`;
  } else if (userManager.isPendingSupporter()) {
    config.headers.Authorization = `Token ${userManager.getPendingSupporterAuthToken()}`;
  }

  // Add Accept-Language header, with the current locale, to all HTTP requests
  config.headers["Accept-Language"] = i18n.global.locale;

  return config;
});

axiosInstance.interceptors.response.use(undefined, (error: any) => {
  return axios.isCancel(error)
    ? Promise.reject({ cancelled: true })
    : Promise.reject(error.response);
});

export const client = axiosInstance;
