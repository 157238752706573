import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-2523a2eb"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "list-reports" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "list-reports__select" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ElOption = _resolveComponent("ElOption")!
  const _component_ElSelect = _resolveComponent("ElSelect")!
  const _component_CompanyListsEmpty = _resolveComponent("CompanyListsEmpty")!
  const _component_DemographicCharts = _resolveComponent("DemographicCharts")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.isFreeUser || !!_ctx.userLists.length)
      ? (_openBlock(), _createElementBlock("header", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("p", null, _toDisplayString(_ctx.$t(`chartsDashboard.select[0]`)), 1),
            _createVNode(_component_ElSelect, {
              modelValue: _ctx.selectedListUid,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedListUid) = $event)),
              filterable: "",
              "default-first-option": "",
              "popper-class": "list-reports__select-list",
              disabled: _ctx.selectDisabled
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.userLists, (option) => {
                  return (_openBlock(), _createBlock(_component_ElOption, {
                    key: option.uid,
                    value: option.uid,
                    label: option.title
                  }, null, 8, ["value", "label"]))
                }), 128))
              ]),
              _: 1
            }, 8, ["modelValue", "disabled"])
          ])
        ]))
      : _createCommentVNode("", true),
    _withDirectives((_openBlock(), _createElementBlock("div", {
      "element-loading-background": "#fafafa",
      class: _normalizeClass(["list-reports__content", {
        'list-reports__content--empty': !_ctx.userLists.length && !_ctx.isFreeUser,
        'list-reports__loading': _ctx.loading,
      }])
    }, [
      (!_ctx.loading)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            (!_ctx.userLists.length && !_ctx.isFreeUser)
              ? (_openBlock(), _createBlock(_component_CompanyListsEmpty, {
                  key: 0,
                  title: _ctx.$t('chartsDashboard.emptyLists.title'),
                  subtitle: _ctx.$t('chartsDashboard.emptyLists.subtitle'),
                  icon: "lists-empty-state",
                  "can-create-lists": true,
                  "custom-action": true,
                  onNewListClick: _ctx.goToListsPage
                }, null, 8, ["title", "subtitle", "onNewListClick"]))
              : _createCommentVNode("", true),
            (_ctx.isFreeUser || !!_ctx.userLists.length)
              ? (_openBlock(), _createBlock(_component_DemographicCharts, {
                  key: 1,
                  theme: _ctx.theme,
                  data: _ctx.data
                }, null, 8, ["theme", "data"]))
              : _createCommentVNode("", true)
          ], 64))
        : _createCommentVNode("", true)
    ], 2)), [
      [_directive_loading, _ctx.loading]
    ])
  ]))
}