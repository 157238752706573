import { ICompanyList } from "@/modules/company-lists/services/data/company-list/company-list.interface";
import { getGenericStateMutations } from "@/services/store/generic/generic-state.mutations";
import { MutationTree } from "vuex";
import { ICompanySharedListState } from "@/modules/company-lists/services/store/company-shared-list/company-shared-list.interface";

/**
 * Available mutations to manage company list store.
 */
export enum ECompanySharedListMutation {
  SET_ERROR = "setError",
  SET_LOADING = "setLoading",
  SET_VALUE = "setValue",
  SET_VALUES = "setValues",
}

export const mutations: MutationTree<ICompanySharedListState> = {
  ...getGenericStateMutations<ICompanyList>(),
};
