<template>
  <div class="profile-panel__row assessment-grid">
    <div class="profile-headline">
      <h2 class="profile-headline__title">
        {{ $t("profile.assessment.gridPanel.title") }}
      </h2>
    </div>
    <PxGrid
      :categories="categories"
      :current-level="0"
      :inactive="true"
      :levels="numOfLevels"
      :random-results="userCannotSeeResults"
      class="assessment-grid__chart"
      size="medium"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import PxGrid from "@/components/px-grid/px-grid.vue";

import { ICategory } from "@/services/data/category/category.interface";
import {
  ICategoryState,
  ECategoryActions,
} from "@/services/store/category/category-types";
import { ENTREPRENEUR_USER_GROUP_ID } from "@/modules/common/constants";

export default defineComponent({
  name: "AssessmentPanelGridInactive",

  components: {
    PxGrid,
  },

  computed: {
    /**
     * Get the categories in a ordered way.
     *
     * This will sort the categories by their order, from the
     * lowest to the higher order.
     */
    categories(): Array<ICategory> {
      const categoriesStore = this.$store.state.categories as ICategoryState;
      return [...categoriesStore.data].sort((a, b) => a.order - b.order);
    },

    /**
     * Return the number of levels.
     */
    numOfLevels(): number {
      return this.$store.state.levels.data.length;
    },

    isSpecialGuest(): boolean {
      return !!this.$route.meta?.specialGuest;
    },

    /**
     * Return if the current user has permissions to see the assessment results.
     */
    userCannotSeeResults(): boolean {
      return (
        (!this.$user.isLogged() && !this.isSpecialGuest) ||
        !this.$user.isOwner()
      );
    },
  },

  created() {
    this.$store.dispatch(ECategoryActions.FETCH, {
      group: ENTREPRENEUR_USER_GROUP_ID,
    });
  },
});
</script>

<style lang="scss" scoped>
.assessment-grid {
  margin-right: 4px;
  margin-left: 4px;

  .profile-panel--disabled &:not(:first-child) {
    padding-top: 30px;
    margin-top: -3px;
  }
}

.assessment-grid__chart {
  margin-top: 33px;

  &:last-child {
    margin-bottom: 10px;
  }
}
</style>
