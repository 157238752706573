<template>
  <ElInput
    v-model="innerValue"
    :autocomplete="autocomplete"
    :class="{
      'el-input--password-visible': isTextPasswordVisible,
      'el-input--success-state': showSuccessState,
    }"
    :disabled="disabled"
    :aria-label="label"
    :placeholder="placeholder"
    :type="fieldType"
    class="el-input--password"
  >
    <template #suffix>
      <span
        :class="{ 'px-input-password__suffix--transparent': !hasInnerValue }"
        class="px-input-password__suffix"
        @click="onPasswordShowClick"
      >
        {{ showCopy }}
      </span>
    </template>
  </ElInput>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "PxInputPassword",

  props: {
    /**
     * Input password value.
     */
    modelValue: {
      type: String,
      required: true,
    },

    /**
     * Input label.
     */
    label: {
      type: String,
      default: "",
    },

    /**
     * Placeholder text.
     */
    placeholder: {
      type: String,
      default: "",
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    /**
     * We should only show success state on signups with two password fields (password and confirmation)
     */
    showSuccessState: {
      type: Boolean,
      default: false,
    },

    autocomplete: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      innerValue: "",
      isTextPasswordVisible: false,
    };
  },

  computed: {
    fieldType(): string {
      return this.isTextPasswordVisible ? "text" : "password";
    },

    showCopy(): string {
      const visibleCopy = this.isTextPasswordVisible
        ? "common.components.default.input.hide"
        : "common.components.default.input.show";
      return this.$t(visibleCopy);
    },

    hasInnerValue(): boolean {
      return !!this.innerValue;
    },
  },

  watch: {
    innerValue: {
      handler(newInnerValue: string) {
        this.$emit("input", newInnerValue);
        this.$emit("update:modelValue", newInnerValue);

        // If no value, hide text password
        if (!this.hasInnerValue) {
          this.isTextPasswordVisible = false;
        }
      },
    },

    modelValue: {
      immediate: true,
      handler(newValue: string) {
        if (newValue !== this.innerValue) {
          this.innerValue = newValue;
        }
      },
    },
  },

  methods: {
    /**
     * Toggles input field password visibility.
     */
    onPasswordShowClick() {
      if (this.hasInnerValue) {
        this.isTextPasswordVisible = !this.isTextPasswordVisible;
      }
    },
  },
});
</script>

<style lang="scss" scoped>
.el-input--password-visible :deep(.el-input__inner) {
  @include grotesk(regular);

  font-size: 14px;
  letter-spacing: normal;
}

.el-form-item.is-success .el-input--password :deep(input[type="password"]) {
  color: #3c3c53;
  border-color: #dcdde2;
}

.el-form-item.is-success
  .el-input--password.el-input--success-state
  :deep(input[type="password"]) {
  color: $fern;
  border-color: $fern;
}

.el-input--password :deep(.el-input__suffix) {
  top: 0;
  right: 16px;
  display: flex;
  align-items: center;

  .el-input__suffix-inner {
    line-height: 23px;
  }
}

.el-input--password::after {
  display: none;
}

.px-input-password__suffix {
  @include grotesk(medium);

  font-size: 14px;
  color: rgba($ebony-clay, 1);
  cursor: pointer;
  user-select: none;

  transition: color 150ms ease-in-out;
}

.px-input-password__suffix--transparent {
  color: rgba($ebony-clay, 0.5);
  pointer-events: none;
}
</style>
