import {
  GenericProvider,
  EProviderFeatures,
  Provider,
} from "@/services/data/generic-provider";
import { ILoginRequest, ILoginResponse } from "./login.interface";
import { baseAPIUrl } from "@/services/utils/utils";

/**
 * Provider used to perform a login.
 */
@Provider(`${baseAPIUrl}/auth/login`, [EProviderFeatures.CREATE])
class LoginProvider extends GenericProvider<ILoginResponse, ILoginRequest> {}

export const loginProvider = new LoginProvider();
