import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, renderSlot as _renderSlot, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-580e523a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "px-navbar__wrapper" }
const _hoisted_2 = { class: "px-navbar__left-side" }
const _hoisted_3 = ["src"]
const _hoisted_4 = ["href"]
const _hoisted_5 = ["src"]
const _hoisted_6 = {
  key: 2,
  class: "px-navbar__logo-affiliate-wrapper"
}
const _hoisted_7 = ["src"]
const _hoisted_8 = {
  key: 3,
  class: "px-navbar--partner__description-wrapper"
}
const _hoisted_9 = { class: "px-navbar--partner__description-text" }
const _hoisted_10 = {
  key: 4,
  class: "px-navbar__menu"
}
const _hoisted_11 = ["href"]
const _hoisted_12 = {
  key: 5,
  class: "px-navbar__headline"
}
const _hoisted_13 = {
  key: 0,
  class: "px-navbar__headline"
}
const _hoisted_14 = {
  key: 0,
  class: "px-navbar__right-side"
}
const _hoisted_15 = {
  key: 0,
  class: "px-navbar__menu"
}
const _hoisted_16 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RouterLink = _resolveComponent("RouterLink")!
  const _component_PxMenu = _resolveComponent("PxMenu")!
  const _component_AccountDropdown = _resolveComponent("AccountDropdown")!
  const _component_AccountSettingsModal = _resolveComponent("AccountSettingsModal")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("nav", {
      class: _normalizeClass([[
        { 'px-navbar--affiliate': _ctx.logo },
        `px-navbar--${_ctx.theme}`,
        { 'px-navbar--partner': _ctx.isPartner },
      ], "px-navbar"])
    }, [
      _createElementVNode("div", _hoisted_1, [
        (!_ctx.loading)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              _createElementVNode("div", _hoisted_2, [
                (_ctx.$user.isLogged())
                  ? (_openBlock(), _createBlock(_component_RouterLink, {
                      key: 0,
                      to: _ctx.logoLink,
                      class: "px-navbar__logo-link"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("img", {
                          src: _ctx.mainLogo,
                          alt: "VIRAL Logo",
                          class: "px-navbar__logo",
                          height: "32",
                          width: "92"
                        }, null, 8, _hoisted_3)
                      ]),
                      _: 1
                    }, 8, ["to"]))
                  : (_openBlock(), _createElementBlock("a", {
                      key: 1,
                      class: _normalizeClass([{
                'px-navbar__logo-link-disabled':
                  _ctx.$user.isPendingUser() || _ctx.$user.isPendingSupporter(),
              }, "px-navbar__logo-link"]),
                      href: _ctx.logoLink
                    }, [
                      _createElementVNode("img", {
                        src: _ctx.mainLogo,
                        alt: "VIRAL Logo",
                        class: "px-navbar__logo",
                        height: "32",
                        width: "92"
                      }, null, 8, _hoisted_5)
                    ], 10, _hoisted_4)),
                (_ctx.showLogo)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                      _createElementVNode("img", {
                        src: _ctx.logo,
                        alt: "Affiliate Logo",
                        class: "px-navbar__logo-affiliate",
                        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.externalLinkClickHandler && _ctx.externalLinkClickHandler(...args)))
                      }, null, 8, _hoisted_7)
                    ]))
                  : _createCommentVNode("", true),
                (_ctx.isPartner)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                      _createElementVNode("p", _hoisted_9, _toDisplayString(_ctx.getPartnerName), 1)
                    ]))
                  : _createCommentVNode("", true),
                (_ctx.isVisitorNavigation && !_ctx.showLogo && !_ctx.isPartner)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                      _createElementVNode("a", {
                        href: _ctx.howItWorksLink.url,
                        class: "px-navbar__link",
                        target: "_blank"
                      }, _toDisplayString(_ctx.howItWorksLink.title), 9, _hoisted_11)
                    ]))
                  : _createCommentVNode("", true),
                (_ctx.showHeadline && _ctx.headline && _ctx.isThemeWizard)
                  ? (_openBlock(), _createElementBlock("h2", _hoisted_12, _toDisplayString(_ctx.$t(_ctx.headline)), 1))
                  : _createCommentVNode("", true),
                (_ctx.items.length)
                  ? (_openBlock(), _createBlock(_component_PxMenu, {
                      key: 6,
                      items: _ctx.items
                    }, null, 8, ["items"]))
                  : _createCommentVNode("", true)
              ]),
              _createElementVNode("div", {
                class: _normalizeClass([{
              'px-navbar__center--empty':
                !_ctx.hasNavbarCenterTitle && !_ctx.hasNavbarCenterSlot,
            }, "px-navbar__center"])
              }, [
                (_ctx.headline && !_ctx.isThemeWizard)
                  ? (_openBlock(), _createElementBlock("h2", _hoisted_13, _toDisplayString(_ctx.$t(_ctx.headline)), 1))
                  : _createCommentVNode("", true),
                _renderSlot(_ctx.$slots, "navbar-center", {}, undefined, true)
              ], 2),
              (_ctx.showLinks)
                ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                    _renderSlot(_ctx.$slots, "navbar-right", {}, () => [
                      (_ctx.isVisitorNavigation)
                        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                            (_ctx.showLogo && !_ctx.isHowItWorksLinkDisabled)
                              ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                                  _createElementVNode("a", {
                                    href: _ctx.howItWorksLink.url,
                                    class: "px-navbar__link",
                                    target: "_blank"
                                  }, _toDisplayString(_ctx.howItWorksLink.title), 9, _hoisted_16)
                                ]))
                              : _createCommentVNode("", true),
                            (_ctx.showLoginButton)
                              ? _renderSlot(_ctx.$slots, "login-button", { key: 1 }, () => [
                                  _createElementVNode("a", {
                                    class: "px-navbar__link",
                                    href: "javascript:void(0)",
                                    onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onClickLogin && _ctx.onClickLogin(...args)))
                                  }, _toDisplayString(_ctx.$t("common.navBar.login")), 1)
                                ], true)
                              : _createCommentVNode("", true)
                          ], 64))
                        : _createCommentVNode("", true),
                      (_ctx.showAccountDropdown)
                        ? (_openBlock(), _createBlock(_component_AccountDropdown, {
                            key: 1,
                            onOpenModal: _cache[2] || (_cache[2] = ($event: any) => (_ctx.setAccountModalVisible = true))
                          }))
                        : _createCommentVNode("", true)
                    ], true)
                  ]))
                : _createCommentVNode("", true)
            ], 64))
          : _createCommentVNode("", true)
      ])
    ], 2),
    (_ctx.$user.isLogged())
      ? (_openBlock(), _createBlock(_component_AccountSettingsModal, {
          key: 0,
          visibility: _ctx.setAccountModalVisible,
          "onUpdate:visibility": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.setAccountModalVisible) = $event))
        }, null, 8, ["visibility"]))
      : _createCommentVNode("", true)
  ]))
}