import { normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createElementVNode as _createElementVNode, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-2a5017e8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "matching-card-thumbnail__container" }
const _hoisted_2 = ["alt", "src"]
const _hoisted_3 = {
  key: 0,
  class: "matching-card-thumbnail__level"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PxAvatar = _resolveComponent("PxAvatar")!
  const _component_PxIcon = _resolveComponent("PxIcon")!

  return (_openBlock(), _createElementBlock("div", {
    style: _normalizeStyle(_ctx.containerSizeStyle),
    class: "matching-card-thumbnail"
  }, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.thumbnail)
        ? (_openBlock(), _createElementBlock("img", {
            key: 0,
            alt: _ctx.name,
            src: _ctx.thumbnail,
            style: _normalizeStyle(_ctx.avatarSizeStyle),
            class: "matching-card-thumbnail__photo"
          }, null, 12, _hoisted_2))
        : (_openBlock(), _createBlock(_component_PxAvatar, {
            key: 1,
            name: _ctx.name,
            size: _ctx.avatarSize,
            class: "matching-card-thumbnail__avatar"
          }, null, 8, ["name", "size"]))
    ]),
    (_ctx.hasSingleLevel)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode(_component_PxIcon, {
            name: _ctx.levelIcon,
            size: _ctx.$options.static.levelSize,
            class: "matching-card-thumbnail__level-image"
          }, null, 8, ["name", "size"])
        ]))
      : _createCommentVNode("", true)
  ], 4))
}