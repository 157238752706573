<template>
  <PxNoticeBar class="submission-panel__notice" type="info">
    <template #content>
      <h2 class="submission-panel__notice-title">
        <PxIcon name="check--white" size="16" />
        {{ $t(`supporters.results.submissionPanel.notice.title`) }}
      </h2>
      <i18n-t
        class="submission-panel__notice-subtitle"
        keypath="supporters.results.submissionPanel.notice.subtitle"
        tag="h4"
      >
        <template #name>
          <span>
            {{ affiliateShortcode }}
          </span>
        </template>
      </i18n-t>
    </template>
    <template #cta>
      <PxButton
        class="submission-panel__notice-link el-button--link-gray"
        type="link"
        @click="goToProfile"
      >
        {{ $t("supporters.results.submissionPanel.notice.cta") }}
      </PxButton>
    </template>
  </PxNoticeBar>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import { ROUTE_PROFILE } from "@/modules/profile/services/router/routes-names";
import { IAffiliate } from "@/services/data/affiliate/affiliate.interface";

export default defineComponent({
  props: {
    affiliate: {
      type: Object as () => IAffiliate,
      required: true,
    },
  },

  computed: {
    affiliateShortcode(): string {
      return this.affiliate?.shortcode || "";
    },
  },

  methods: {
    goToProfile() {
      this.$router.push({
        name: ROUTE_PROFILE,
      });
    },
  },
});
</script>

<style lang="scss" scoped>
.submission-panel__notice {
  margin: 0 21px;
}

.submission-panel__notice-title {
  @include grotesk(semiBold);

  margin-bottom: 0;
  // Negative margin to match design.
  margin-left: -1px;

  font-size: 17px;
  line-height: 32px;
  color: $fern;
  letter-spacing: -0.6px;

  .px-icon {
    margin-right: 6px;
    vertical-align: -2px;
    background-color: $fern;
    border-radius: 100%;
  }
}

.submission-panel__notice-subtitle {
  @include grotesk(medium);

  position: relative;
  padding-left: 15px;
  margin-bottom: 0;
  margin-left: 11px;
  font-size: 14px;
  line-height: 20px;
  color: $ebony-clay;
  letter-spacing: -0.2px;

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 1px;
    height: 22px;
    content: "";
    background-color: rgba($manatee, 0.14);
  }

  span {
    @include grotesk(medium);

    display: inline-block;
    margin-top: auto;
    font-size: 14px;
    line-height: 20px;
    color: $ebony-clay;
    text-decoration: underline;
    letter-spacing: -0.2px;
  }
}

.submission-panel__notice-link {
  height: 32px;

  @include breakpoint-up(md) {
    padding-bottom: 13px;
  }

  :deep() span {
    @include grotesk(medium);

    font-size: 14px;
  }
}
</style>
