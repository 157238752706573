import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-bee10fca"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "pxQuestionaryNumeric" }
const _hoisted_2 = { class: "pxQuestionaryNumeric-fieldWrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PxNumeric = _resolveComponent("PxNumeric")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_PxNumeric, {
        modelValue: _ctx.innerValue.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.innerValue.value) = $event))
      }, null, 8, ["modelValue"])
    ])
  ]))
}