import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "milestone-plan-onboarding-dialog__wrapper" }
const _hoisted_2 = { class: "milestone-plan-onboarding-dialog__footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MilestoneNavigationTab = _resolveComponent("MilestoneNavigationTab")!
  const _component_MilestonePlanOnboardingDialogContent = _resolveComponent("MilestonePlanOnboardingDialogContent")!
  const _component_MilestonePlanStageStatus = _resolveComponent("MilestonePlanStageStatus")!
  const _component_PxStepByStepDialog = _resolveComponent("PxStepByStepDialog")!

  return (_openBlock(), _createBlock(_component_PxStepByStepDialog, {
    "current-page": _ctx.currentPage,
    "onUpdate:currentPage": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.currentPage) = $event)),
    "page-count": _ctx.pageCount,
    visibility: _ctx.visibility,
    class: "milestone-plan-onboarding-dialog",
    onCloseDialog: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('close-dialog')))
  }, {
    title: _withCtx(() => [
      _createElementVNode("h3", null, _toDisplayString(_ctx.$t(`milestonePlanner.milestonePlan.onboardingModal.${_ctx.userType}.title`)), 1),
      _createElementVNode("p", null, _toDisplayString(_ctx.$t("milestonePlanner.milestonePlan.onboardingModal.subtitle")), 1)
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_MilestoneNavigationTab, {
          "active-tab": _ctx.activeTab,
          "onUpdate:activeTab": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.activeTab) = $event))
        }, null, 8, ["active-tab"]),
        _createVNode(_component_MilestonePlanOnboardingDialogContent, {
          "active-page": _ctx.currentPage,
          "is-visitor": _ctx.isVisitor
        }, null, 8, ["active-page", "is-visitor"])
      ]),
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_MilestonePlanStageStatus, { "selected-status": _ctx.currentPage }, null, 8, ["selected-status"])
      ])
    ]),
    _: 1
  }, 8, ["current-page", "page-count", "visibility"]))
}