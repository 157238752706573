import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "matching-list page-body" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MatchingListDesktop = _resolveComponent("MatchingListDesktop")!
  const _component_MatchingListMobile = _resolveComponent("MatchingListMobile")!
  const _component_MatchingConfirmModal = _resolveComponent("MatchingConfirmModal")!
  const _component_MatchingSupporterConfirmModal = _resolveComponent("MatchingSupporterConfirmModal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.$screen.mdUp)
      ? (_openBlock(), _createBlock(_component_MatchingListDesktop, { key: 0 }))
      : _createCommentVNode("", true),
    (_ctx.$screen.smDown)
      ? (_openBlock(), _createBlock(_component_MatchingListMobile, { key: 1 }))
      : _createCommentVNode("", true),
    (_ctx.$user.isEntrepreneur())
      ? (_openBlock(), _createBlock(_component_MatchingConfirmModal, {
          key: 2,
          visibility: _ctx.isMatchingConfirmModalVisible,
          "onUpdate:visibility": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.isMatchingConfirmModalVisible) = $event))
        }, null, 8, ["visibility"]))
      : _createCommentVNode("", true),
    (_ctx.$user.isSupporter())
      ? (_openBlock(), _createBlock(_component_MatchingSupporterConfirmModal, {
          key: 3,
          visibility: _ctx.isMatchingConfirmModalVisible,
          "onUpdate:visibility": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.isMatchingConfirmModalVisible) = $event))
        }, null, 8, ["visibility"]))
      : _createCommentVNode("", true)
  ]))
}