<template>
  <div class="milestone-form">
    <div class="milestone-form__header">
      <MilestoneNavigationTab
        v-model:active-tab="innerActiveTab"
        :milestone-completed="isMilestoneComplete"
        :planned="isPlanPublished"
        data-testid="milestone-form-navigation-tab"
      />
      <MilestonePlanStateBadge
        v-if="stateLabel"
        :is-visitor="isVisitor"
        :label="stateLabel"
        :last-update="lastUpdate"
        data-testid="milestone-form-state-badge"
      />
    </div>
    <template v-if="!isVisitor">
      <MilestoneFormPlan
        v-if="isPlanTabActive"
        :category-level="categoryLevel"
        :category-name="category.name"
        :evidence-question="evidenceQuestion"
        :has-completion-info="hasCompletionInfo"
        :is-future-milestone="isFutureMilestone"
        :milestone="milestone"
        data-testid="milestone-plan-plan-form"
        @plan-form-has-changes="checkPlanChanges"
        @plan-form-is-complete="checkPlanIsComplete"
        @plan-form-is-invalid="checkPlanIsInvalid"
      />
      <MilestoneFormComplete
        v-else
        :category-level="categoryLevel"
        :is-future-milestone="isFutureMilestone"
        :milestone="milestone"
        :questions="milestoneQuestions"
        data-testid="milestone-form-complete"
        @complete-form-has-changes="checkCompleteFormChanges"
        @complete-form-is-invalid="checkCompleteIsInvalid"
      />
    </template>
    <MilestoneFormGuestView
      v-else
      :is-milestone-complete="isMilestoneComplete"
      :is-milestone-planned="isMilestonePlanned"
      :is-plan-published="isPlanPublished"
      :is-plan-tab-active="isPlanTabActive"
      :milestone="milestone"
      :questions="milestoneQuestions"
      data-testid="milestone-form-guest-view"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import MilestoneFormPlan from "@/modules/milestone-planner/components/milestone-form/milestone-form-plan.vue";
import MilestoneNavigationTab from "@/modules/milestone-planner/components/milestone-navigation-tab/milestone-navigation-tab.vue";
import MilestoneFormComplete from "@/modules/milestone-planner/components/milestone-form/milestone-form-complete.vue";
import MilestonePlanStateBadge from "@/modules/milestone-planner/components/milestone-plan-state-badge/milestone-plan-state-badge.vue";
import MilestoneFormGuestView from "@/modules/milestone-planner/components/milestone-form/milestone-form-guest-view.vue";

import { TranslateResult } from "vue-i18n";
import {
  MILESTONE_DETAILS_TABS,
  TMilestoneDetailsTab,
} from "../../services/data/milestones/milestone.interface";
import { IMilestone } from "@/modules/milestone-planner/services/data/milestones/milestone.interface";
import { IQuestionBundle } from "@/services/data/question-bundle/question-bundle.interface";
import {
  IMatchingQuestion,
  FREE_RESPONSE,
} from "@/services/data/matching-questionary/matching-question.interface";
import { IGridCategory } from "@/modules/milestone-planner/components/milestones-grid/milestones-grid.interface";
import { ICategoryDetail } from "@/services/data/category/category.interface";

export default defineComponent({
  name: "MilestoneForm",

  components: {
    MilestoneFormPlan,
    MilestoneNavigationTab,
    MilestoneFormComplete,
    MilestonePlanStateBadge,
    MilestoneFormGuestView,
  },

  props: {
    milestone: {
      type: Object as () => IMilestone | null,
      default: null,
    },
    categoryLevel: {
      type: Object as () => ICategoryDetail | null,
      default: null,
    },
    questionBundles: {
      type: Array as () => Array<IQuestionBundle>,
      default: null,
      required: true,
    },
    category: {
      type: Object as () => IGridCategory,
      default: null,
      required: true,
    },
    isMilestonePlanned: {
      type: Boolean,
      default: false,
    },
    isMilestoneComplete: {
      type: Boolean,
      default: false,
    },
    isFutureMilestone: {
      type: Boolean,
      default: false,
    },
    isPlanPublished: {
      type: Boolean,
      default: false,
    },
    hasCompletionInfo: {
      type: Boolean,
      default: false,
    },
    completeFormHasChanges: {
      type: Boolean,
      default: false,
    },
    planLastUpdate: {
      type: String,
      default: "",
    },
    completeLastUpdate: {
      type: String,
      default: "",
    },
    activeTab: {
      type: String as () => TMilestoneDetailsTab,
      default: MILESTONE_DETAILS_TABS.PLAN,
    },
    isVisitor: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      innerActiveTab: this.activeTab,
    };
  },

  computed: {
    stateLabel(): TranslateResult {
      return this.isPlanTabActive
        ? this.planStateLabel
        : this.completeStateLabel;
    },

    planStateLabel(): TranslateResult {
      return this.isPlanPublished
        ? this.$t("milestonePlanner.milestoneGrid.stageStatus.planned")
        : this.isMilestonePlanned
          ? this.$t("milestonePlanner.milestonePlan.planForm.badges.draft")
          : this.hasCompletionInfo
            ? this.$t(
                "milestonePlanner.milestonePlan.planForm.badges.notPlanned",
              )
            : "";
    },

    completeStateLabel(): TranslateResult {
      return this.isMilestoneComplete
        ? this.$t("milestonePlanner.milestonePlan.planForm.badges.completed")
        : this.hasCompletionInfo
          ? this.$t("milestonePlanner.milestonePlan.planForm.badges.draft")
          : "";
    },

    lastUpdate(): string {
      return this.isPlanTabActive
        ? this.planLastUpdate
        : this.completeLastUpdate;
    },

    isPlanTabActive() {
      return this.activeTab === MILESTONE_DETAILS_TABS.PLAN;
    },

    milestoneQuestions(): Array<IMatchingQuestion> {
      return this.questionBundles.reduce(
        (bundledQuestions: Array<IMatchingQuestion>, currentBundle) => [
          ...bundledQuestions,
          ...currentBundle.questions.filter(
            // Temporarily allow only questions of type free response:
            (question) => question.question_type.type === FREE_RESPONSE,
          ),
        ],
        [],
      );
    },

    evidenceQuestion(): IMatchingQuestion | null {
      return this.milestoneQuestions.length ? this.milestoneQuestions[0] : null;
    },
  },

  watch: {
    innerActiveTab: function (tab: TMilestoneDetailsTab) {
      this.$emit("update:activeTab", tab);
    },

    activeTab: function (tab: TMilestoneDetailsTab) {
      this.innerActiveTab = tab;
      this.checkPlanChanges(false);
      this.checkCompleteFormChanges(false);
    },
  },

  methods: {
    checkPlanIsComplete(isComplete: boolean) {
      this.$emit("update:planFormIsComplete", isComplete);
    },

    checkPlanChanges(hasChanges: boolean) {
      this.$emit("update:planFormHasChanges", hasChanges);
    },

    checkPlanIsInvalid(isInvalid: boolean) {
      this.$emit("update:planFormIsInvalid", isInvalid);
    },

    checkCompleteFormChanges(hasChanges: boolean) {
      this.$emit("update:completeFormHasChanges", hasChanges);
    },

    checkCompleteIsInvalid(isInvalid: boolean) {
      this.$emit("update:completeFormIsInvalid", isInvalid);
    },
  },
});
</script>

<style lang="scss" scoped>
.milestone-form {
  position: relative;
  min-height: 828px;
  padding: 25px 0 0 0;
  border-top: 1px solid $athens-gray;

  &__header {
    display: grid;
    grid-template-columns: auto auto;
    justify-content: space-between;
    margin-bottom: 31px;
    border-bottom: 1px solid #f0f1f5;

    .milestone-plan-state-badge {
      position: relative;
      right: 31px;
      bottom: 6px;
    }
  }
}

.milestone-form :deep() .px-navigation-tab {
  gap: 2px;
  justify-content: start;
  max-width: 100%;
  padding-left: 37px;

  &-item__container {
    position: relative;
    right: 2px;
    bottom: 1px;
    min-width: 125px;
    padding: 0 22px 16px 22px;
  }

  > :nth-child(1) {
    position: relative;
    right: 4px;

    img {
      position: relative;
      top: 3px;
      left: 3px;
    }

    h3 span {
      position: relative;
      left: 2px;
    }
  }

  > :nth-child(2) {
    position: relative;
    right: 6px;

    img {
      position: relative;
      top: 3px;
      right: 2px;
    }

    h3 {
      position: relative;
      top: 1px;
      right: 5px;
      letter-spacing: -0.1px;
    }

    h3 span {
      position: relative;
      left: 2px;
    }

    .px-navigation-tab-item__container {
      left: 3px;
    }
  }

  &-item--active {
    padding: 0 22px 13px 22px;
  }

  .px-navigation-tab-item__title {
    margin-top: 3px;
  }
}
</style>
