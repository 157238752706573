<template>
  <div class="share-modal-content">
    <ShareModalUsersSearch
      :filter-by="filterBy"
      :share-succeeded="shareSucceeded"
      :users="users"
      class="share-modal-content__search"
      @send-email="onSendEmail"
    />
    <ShareModalUsers
      :copy="usersCopy"
      :users="users"
      class="share-modal-content__users"
    />
    <ShareModalActions
      :copy="actionsCopy"
      :link="link"
      :passcode="passcode"
      class="share-modal-content__actions"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import ShareModalUsersSearch from "@/components/share-modal/share-modal-users-search.vue";
import ShareModalActions from "@/components/share-modal/share-modal-actions.vue";
import ShareModalUsers from "@/components/share-modal/share-modal-users.vue";

import { IInvitedUser } from "@/components/share-modal/share-modal.interface";

export default defineComponent({
  name: "ShareModalContent",

  components: {
    ShareModalActions,
    ShareModalUsers,
    ShareModalUsersSearch,
  },

  props: {
    users: {
      type: Array as () => IInvitedUser[],
      default: () => [],
    },

    link: {
      type: String,
      required: true,
    },

    passcode: {
      type: String,
      required: true,
    },

    actionsCopy: {
      type: String,
      default: "milestonePlanner.shareModal.footer",
    },

    usersCopy: {
      type: String,
      default: "milestonePlanner.shareModal.users",
    },

    filterBy: {
      type: Number,
      default: 0,
    },

    shareSucceeded: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    onSendEmail(invitedUsers: Array<string>) {
      this.$emit("send-email", invitedUsers);
    },
  },
});
</script>

<style lang="scss" scoped>
.share-modal-content {
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  height: 100%;
  overflow: hidden;

  &__search {
    width: 100%;
  }

  &__users {
    flex: 1;
    width: 100%;
    min-height: 460px;
    max-height: 460px;
    padding-right: 0;
    // To avoid overlap with footer:
    padding-bottom: 120px;
    overflow: auto;

    :deep(.px-loading-mask) {
      bottom: -10px;
      z-index: z("floaters") - 1;
    }

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__actions {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: z("floaters");
    width: 100%;
    min-height: 118px;
    padding: 10px 32px 25px 32px;
    background-color: $alabaster;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
    box-shadow: inset 0 1px 0 0 $mischka;
  }
}
</style>
