<template>
  <div>
    <nav
      :class="[
        { 'px-navbar--affiliate': logo },
        `px-navbar--${theme}`,
        { 'px-navbar--partner': isPartner },
      ]"
      class="px-navbar"
    >
      <div class="px-navbar__wrapper">
        <template v-if="!loading">
          <div class="px-navbar__left-side">
            <RouterLink
              v-if="$user.isLogged()"
              :to="logoLink"
              class="px-navbar__logo-link"
            >
              <img
                :src="mainLogo"
                alt="VIRAL Logo"
                class="px-navbar__logo"
                data-testid="px-navbar__logo"
                height="32"
                width="92"
              />
            </RouterLink>
            <a
              v-else
              :class="{
                'px-navbar__logo-link-disabled':
                  $user.isPendingUser() || $user.isPendingSupporter(),
              }"
              :href="logoLink"
              class="px-navbar__logo-link"
            >
              <img
                :src="mainLogo"
                alt="VIRAL Logo"
                class="px-navbar__logo"
                data-testid="px-navbar__logo"
                height="32"
                width="92"
              />
            </a>
            <div v-if="showLogo" class="px-navbar__logo-affiliate-wrapper">
              <img
                :src="logo"
                alt="Affiliate Logo"
                class="px-navbar__logo-affiliate"
                data-testid="px-navbar__logo-affiliate"
                @click="externalLinkClickHandler"
              />
            </div>
            <div
              v-if="isPartner"
              class="px-navbar--partner__description-wrapper"
            >
              <p class="px-navbar--partner__description-text">
                {{ getPartnerName }}
              </p>
            </div>
            <div
              v-if="isVisitorNavigation && !showLogo && !isPartner"
              class="px-navbar__menu"
            >
              <a
                :href="howItWorksLink.url"
                class="px-navbar__link"
                target="_blank"
              >
                {{ howItWorksLink.title }}
              </a>
            </div>
            <h2
              v-if="showHeadline && headline && isThemeWizard"
              class="px-navbar__headline"
            >
              {{ $t(headline) }}
            </h2>
            <PxMenu v-if="items.length" :items="items" />
          </div>
          <div
            :class="{
              'px-navbar__center--empty':
                !hasNavbarCenterTitle && !hasNavbarCenterSlot,
            }"
            class="px-navbar__center"
          >
            <h2 v-if="headline && !isThemeWizard" class="px-navbar__headline">
              {{ $t(headline) }}
            </h2>
            <slot name="navbar-center" />
          </div>
          <div v-if="showLinks" class="px-navbar__right-side">
            <slot name="navbar-right">
              <template v-if="isVisitorNavigation">
                <div
                  v-if="showLogo && !isHowItWorksLinkDisabled"
                  class="px-navbar__menu"
                >
                  <a
                    :href="howItWorksLink.url"
                    class="px-navbar__link"
                    target="_blank"
                  >
                    {{ howItWorksLink.title }}
                  </a>
                </div>
                <slot v-if="showLoginButton" name="login-button">
                  <a
                    class="px-navbar__link"
                    href="javascript:void(0)"
                    @click="onClickLogin"
                  >
                    {{ $t("common.navBar.login") }}
                  </a>
                </slot>
              </template>
              <AccountDropdown
                v-if="showAccountDropdown"
                @open-modal="setAccountModalVisible = true"
              />
            </slot>
          </div>
        </template>
      </div>
    </nav>
    <AccountSettingsModal
      v-if="$user.isLogged()"
      v-model:visibility="setAccountModalVisible"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import PxMenu from "@/components/px-menu/px-menu.vue";
import AccountDropdown from "@/modules/profile/components/account-dropdown/account-dropdown.vue";
import AccountSettingsModal from "@/modules/profile/components/account-settings-modal/account-settings-modal.vue";
import { IViralLevelState } from "@/services/store/viral-level/viral-level-types";
import { ICompany } from "@/modules/profile/services/data/company/company.types";
import { ROUTE_AUTHENTICATION_LOGIN } from "@/modules/authentication/services/router/routes-names";
import { getAppPartner, isPartner, isProduction } from "@/services/utils/utils";
import { RouteRecord } from "vue-router";

export default defineComponent({
  name: "PxNavbar",

  components: {
    PxMenu,
    AccountDropdown,
    AccountSettingsModal,
  },

  props: {
    items: {
      type: Array as () => Array<any>,
      default: () => [],
    },

    logo: {
      type: String,
      default: null,
    },

    externalLink: {
      type: String,
      default: null,
    },

    theme: {
      type: String,
      default: "default",
      validator(value: string) {
        return ["default", "centered", "wizard"].includes(value);
      },
    },

    loading: {
      type: Boolean,
      default: false,
    },

    showLinks: {
      type: Boolean,
      default: true,
    },

    showLoginButton: {
      type: Boolean,
      default: true,
    },

    showHeadline: {
      type: Boolean,
      default: true,
    },

    showAccountDropdown: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      setAccountModalVisible: false,
    };
  },

  computed: {
    company(): ICompany | null {
      return this.$store.get("auth/company/data");
    },

    mainLogo(): string {
      return this.logo
        ? "/img/logos/abaca-logo--dark.svg"
        : "/img/logos/abaca-logo--white.svg";
    },

    showLogo(): boolean {
      const viralLevelStore = this.$store.state.viralLevel as IViralLevelState;
      return !!this.logo && !viralLevelStore.loading;
    },

    isSpecialGuest(): boolean {
      return !!this.$route.meta?.specialGuest;
    },

    logoLink(): any {
      return this.$user.isPendingSupporter() || this.$user.isPendingUser()
        ? null
        : !this.$user.isLogged() && !this.isSpecialGuest && isProduction
          ? "https://abaca.app"
          : "/";
    },

    howItWorksLink(): any {
      return {
        url: "https://abaca.app/how-it-works",
        title: this.$t("common.navBar.howItWorks"),
      };
    },

    headline(): string | null {
      const headlineRoute = this.$route.matched.find(
        (route: RouteRecord) => !!route.meta.navbarHeadline,
      );
      return (headlineRoute?.meta.navbarHeadline as string) || null;
    },

    isThemeDefault(): boolean {
      return this.theme === "default";
    },

    isThemeWizard(): boolean {
      return this.theme === "wizard";
    },

    isVisitorNavigation(): boolean {
      return (!this.company || !this.$user.isLogged()) && this.isThemeDefault;
    },

    isPartner(): boolean {
      return isPartner;
    },

    getPartnerName(): string {
      return getAppPartner();
    },

    hasNavbarCenterTitle(): boolean {
      return !!this.headline && !this.isThemeWizard;
    },

    hasNavbarCenterSlot(): boolean {
      return !!this.$slots["navbar-center"];
    },

    isHowItWorksLinkDisabled(): boolean {
      return this.$features.isEnabled("disabledHowItWorksLink");
    },
  },

  methods: {
    externalLinkClickHandler() {
      if (this.externalLink) {
        window.open(this.externalLink);
      }
    },

    onClickLogin() {
      this.$router.push({ name: ROUTE_AUTHENTICATION_LOGIN });
    },
  },
});
</script>

<style lang="scss" scoped>
.px-navbar {
  @include gradient("blue-gradient-1", 273deg);

  position: relative;
  z-index: z("navigation");
  display: block;
  width: 100%;
  text-align: left;

  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.22);

  &::after {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;

    height: 1px;

    content: "";

    background: #004871;
  }

  &__wrapper {
    @include make-container();
    @include make-container-max-widths();

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    // Any changes to this value need to consider:
    // 1 - If it wasn't an accidental mistake on Design;
    // 2 - That it impacts the whole application and therefore being careful about regressions;
    min-height: 66px;

    padding: 0;
  }

  &__left-side {
    position: relative;
    display: flex;
    flex-basis: auto;
    flex-direction: row;
    flex-grow: 1;
    align-items: center;
    justify-content: flex-start;

    & > * {
      position: relative;
      z-index: z("navigation") + 2;
    }
  }

  &__right-side {
    display: flex;
    flex-basis: 0;
    flex-grow: 1;
    justify-content: flex-end;
  }

  &__logo {
    width: 92px;
    margin: 17px 0;
  }

  &__headline {
    @include grotesk(semiBold);

    margin: auto;
    font-size: 22px;
    line-height: 41px;
    color: $white;
    letter-spacing: -0.05px;
  }
}

.px-navbar__center--empty + .px-navbar__right-side {
  flex-basis: auto;
  flex-grow: 0;
}

.px-navbar__logo-affiliate {
  max-width: 100%;
  max-height: 36px;
  padding-left: 20px;
  cursor: pointer;
}

.px-navbar__logo-affiliate-wrapper {
  position: relative;
  display: inline-block;
  margin-left: 20px;
}

.px-navbar__logo-affiliate-wrapper::before {
  position: absolute;
  top: 50%;
  width: 1px;
  height: 20px;

  content: "";

  background-color: rgba(39, 39, 64, 0.4);

  opacity: 0.24;
  transform: translateY(-50%);
}

.px-navbar.px-navbar--affiliate .px-navbar__left-side {
  flex-grow: 0;
  min-width: 291px;

  &::before {
    position: absolute;
    top: 0;
    right: 110px;
    z-index: z("navigation") + 1;
    width: 100vw;
    height: 100%;

    content: "";

    background-color: white;
  }

  &::after {
    position: absolute;
    top: 0;
    right: -27px;
    z-index: z("navigation") + 1;
    width: 456px;
    height: 100%;

    content: "";

    background-image: url("#{$assetsPath}/img/menu-background-affiliated.svg");
    background-repeat: no-repeat;
    background-size: 100% 79px;
  }
}

/* Centered Navbar Theme: */
.px-navbar.px-navbar--centered {
  .px-navbar__left-side {
    flex-grow: 0;
    margin: auto;
  }

  .px-navbar__right-side {
    flex-grow: 0;
  }
}

/* Partner Theme */
.px-navbar.px-navbar--partner {
  @include gradient("green-gradient-2", 273deg);
}

.px-navbar--partner__description-wrapper {
  padding: 10px 24px 7px;
}

.px-navbar--partner__description-text {
  margin: 0;
  font-size: 17px;
  font-weight: bold;
  color: black;
  text-transform: uppercase;
}

/* Navbar menu */
.px-navbar__menu {
  display: flex;
  flex-flow: row wrap;
  align-items: center;

  &:not(:last-child) {
    position: relative;
    padding-right: 13px;
    margin-right: 26px;

    &::after {
      position: absolute;
      right: -1px;
      width: 1px;
      height: 24px;
      content: "";
      background-color: rgba(white, 0.3);
    }
  }
}

.px-navbar__left-side :deep() {
  .px-menu {
    position: relative;
    top: -1px;
    margin: 0 0 0 60px;
  }

  .px-menu__item::after {
    bottom: -1px;
  }

  a.px-menu__item-link {
    padding: 0 20px;
    margin: 0;
    letter-spacing: -0.22px;
  }
}

.px-navbar__logo-link + .px-navbar__menu {
  margin-left: 44px;
}

.px-navbar__logo-link-disabled {
  cursor: default;
}

.px-navbar__link {
  @include grotesk(medium);

  padding: 13px;

  font-size: to-rem(17px);
  color: $white;
  text-align: center;
  text-decoration: none;
  transition: opacity 0.23s ease-in-out;
  will-change: opacity;

  &:hover {
    opacity: 0.7;
  }
}

.px-navbar__loginButton {
  min-width: 82px;
}
</style>
