<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="173"
    height="173"
    viewBox="0 0 173 173"
  >
    <defs>
      <linearGradient id="viral-graph-4-c" x1="50%" x2="50%" y1="0%" y2="100%">
        <stop offset="0%" stop-color="#58A0F8" class="linear-gradient-color1" />
        <stop
          offset="100%"
          stop-color="#2176DD"
          class="linear-gradient-color2"
        />
      </linearGradient>
      <path
        id="viral-graph-4-b"
        d="M52.496754,5.51902116 L62.1321043,17.1874178 C54.6220229,23.3820418 48.5872277,31.2019088 44.5252968,40.0410954 L30.7692629,33.7267629 C35.7863195,22.8091229 43.2321365,13.1608587 52.496754,5.51902116 Z"
      />
      <filter
        id="viral-graph-4-a"
        width="163.8%"
        height="157.9%"
        x="-31.9%"
        y="-23.2%"
        filterUnits="objectBoundingBox"
      >
        <feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur
          in="shadowOffsetOuter1"
          result="shadowBlurOuter1"
          stdDeviation="3"
        />
        <feComposite
          in="shadowBlurOuter1"
          in2="SourceAlpha"
          operator="out"
          result="shadowBlurOuter1"
        />
        <feColorMatrix
          in="shadowBlurOuter1"
          values="0 0 0 0 0.333333333 0 0 0 0 0.623529412 0 0 0 0 0.976470588 0 0 0 0.5 0"
          class="color-matrix"
        />
      </filter>
      <linearGradient id="viral-graph-4-f" x1="50%" x2="50%" y1="0%" y2="100%">
        <stop offset="0%" stop-color="#64A9FE" class="linear-gradient-color3" />
        <stop
          offset="100%"
          stop-color="#1865C4"
          class="linear-gradient-color2"
        />
      </linearGradient>
      <path
        id="viral-graph-4-e"
        d="M78.30625,145.548387 C109.955618,145.548387 135.6125,119.905857 135.6125,88.2741935 C135.6125,56.6425299 109.955618,31 78.30625,31 C46.6568821,31 21,56.6425299 21,88.2741935 C21,119.905857 46.6568821,145.548387 78.30625,145.548387 Z M78.30625,145.548387 C109.955618,145.548387 135.6125,119.905857 135.6125,88.2741935 C135.6125,56.6425299 109.955618,31 78.30625,31 C46.6568821,31 21,56.6425299 21,88.2741935 C21,119.905857 46.6568821,145.548387 78.30625,145.548387 Z M78.30625,145.548387 C109.955618,145.548387 135.6125,119.905857 135.6125,88.2741935 C135.6125,56.6425299 109.955618,31 78.30625,31 C46.6568821,31 21,56.6425299 21,88.2741935 C21,119.905857 46.6568821,145.548387 78.30625,145.548387 Z"
      />
      <filter
        id="viral-graph-4-d"
        width="141%"
        height="141%"
        x="-20.5%"
        y="-18.8%"
        filterUnits="objectBoundingBox"
      >
        <feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur
          in="shadowOffsetOuter1"
          result="shadowBlurOuter1"
          stdDeviation="7.5"
        />
        <feComposite
          in="shadowBlurOuter1"
          in2="SourceAlpha"
          operator="out"
          result="shadowBlurOuter1"
        />
        <feColorMatrix
          in="shadowBlurOuter1"
          values="0 0 0 0 0.325490196 0 0 0 0 0.584313725 0 0 0 0 0.894117647 0 0 0 0.5 0"
          class="color-matrix"
        />
      </filter>
      <linearGradient
        id="viral-graph-4-g"
        x1="50%"
        x2="50%"
        y1="0%"
        y2="98.69%"
      >
        <stop offset="0%" stop-color="#FFF" />
        <stop
          offset="100%"
          stop-color="#D5E3F4"
          stop-opacity="0"
          class="linear-gradient-color2"
        />
      </linearGradient>
    </defs>
    <g fill="none" fill-rule="evenodd" transform="translate(8 7)">
      <path
        fill="#E8E9EF"
        fill-rule="nonzero"
        d="M144.781261,88.3612903 C144.720385,80.5150278 143.302303,72.9916682 140.75005,66.0141512 L150.647864,61.9253432 C153.715506,70.1648413 155.417822,79.0683892 155.479931,88.3612903 L144.781262,88.3612903 Z M144.738305,91.3612903 L155.442824,91.3612903 C155.152535,100.583211 153.239939,109.554393 149.828602,117.953543 L139.962439,113.814507 C142.828928,106.71928 144.454218,99.1517635 144.738297,91.3612907 Z M139.655638,63.220353 C136.188296,54.927862 131.091598,47.4852917 124.765395,41.2923204 L131.9226,33.3293228 C139.425481,40.5886875 145.462329,49.3526146 149.546238,59.1345253 L139.655638,63.220353 Z M122.574755,39.2417643 C115.624775,33.0220638 107.361094,28.2385464 98.2508358,25.3581347 L101.069899,15.0273505 C111.812476,18.3579172 121.552902,23.965229 129.726431,31.284919 L122.574755,39.2417643 Z M95.370208,24.5187103 C89.8684308,23.0499399 84.0863514,22.2669355 78.12125,22.2669355 C73.8809698,22.2669355 69.6941204,22.6619112 65.5968847,23.4399082 L63.6010998,12.9293264 C68.3554311,12.0265579 73.2104558,11.5685484 78.12125,11.5685484 C85.0612884,11.5685484 91.7879116,12.4819454 98.1873332,14.1950295 L95.370208,24.5187103 Z"
      />
      <path
        class="linear-gradient-color5"
        stroke="#7CB8FF"
        stroke-width="10.698"
        d="M31.4326452,34.0924067 C23.9098299,40.5011681 17.723076,48.4300663 13.3481684,57.4035828"
      />
      <path
        class="linear-gradient-color4"
        fill="#7CB8FF"
        fill-rule="nonzero"
        d="M16.6765808,114.767821 L6.8237774,118.949548 C3.0278928,109.967732 0.957739616,100.304215 0.77158819,90.3612904 L11.4781279,90.3612903 C11.6622991,98.8574707 13.4391611,107.100776 16.6765827,114.76782 Z M11.4793388,87.3612903 L0.772629284,87.3612903 C0.974569387,76.9631481 3.23705727,66.8709194 7.37999819,57.5444405 L17.1565305,61.9012902 C13.6217446,69.8642862 11.6791727,78.4742001 11.479344,87.3612902 Z"
      />
      <g fill-rule="nonzero" transform="rotate(27 46.45 22.78)">
        <use
          fill="#000"
          filter="url(#viral-graph-4-a)"
          xlink:href="#viral-graph-4-b"
        />
        <use fill="url(#viral-graph-4-c)" xlink:href="#viral-graph-4-b" />
      </g>
      <use
        fill="#000"
        filter="url(#viral-graph-4-d)"
        xlink:href="#viral-graph-4-e"
      />
      <ellipse
        cx="78.338"
        cy="88.565"
        rx="56.819"
        ry="56.79"
        fill="url(#viral-graph-4-f)"
        stroke="#181818"
        stroke-linejoin="round"
        stroke-opacity=".2"
      />
      <ellipse
        cx="78.338"
        cy="88.565"
        stroke="url(#viral-graph-4-g)"
        stroke-width="2"
        opacity=".402"
        rx="49.819"
        ry="49.79"
      />
      <path
        fill="#FFF"
        d="M80.3642578,108 L80.3642578,97.7871094 L60.2460938,97.7871094 L60.2460938,93.2651367 L81.4716797,64.5336914 L88.0239258,64.5336914 L88.0239258,90.6811523 L94.9760742,90.6811523 L94.9760742,97.7871094 L88.0239258,97.7871094 L88.0239258,108 L80.3642578,108 Z M70.2744141,90.6811523 L80.3642578,90.6811523 L80.3642578,76.5 L70.2744141,90.6811523 Z"
      />
    </g>
  </svg>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "Level4",
});
</script>
