import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PxIcon = _resolveComponent("PxIcon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([[
      { 'px-status-badge--no-icon': !_ctx.icon },
      { 'px-status-badge--icon-right': _ctx.iconPosition === 'right' },
    ], "px-status-badge"]),
    style: _normalizeStyle(_ctx.badgeStyle)
  }, [
    (_ctx.icon)
      ? (_openBlock(), _createBlock(_component_PxIcon, {
          key: 0,
          name: _ctx.icon,
          size: _ctx.iconSize,
          class: "px-status-badge__icon"
        }, null, 8, ["name", "size"]))
      : _createCommentVNode("", true),
    _createElementVNode("p", {
      style: _normalizeStyle(_ctx.labelStyle),
      class: "px-status-badge__label"
    }, _toDisplayString(_ctx.label), 5),
    _renderSlot(_ctx.$slots, "additionalContent", {}, undefined, true)
  ], 6))
}