import { isDevelopment } from "./utils";
import { isAllowedCookieGroup } from "./onetrust";

interface IUserGuidingObject {
  name?: string;
  attributes: { [key: string]: any };
  next?: Function;

  // Used for applying a retry mechanism when
  // UserGuiding isn't immediately available.
  retries?: number;
}

const userGuidingGuard = ({
  name,
  attributes = {},
  next = () => null,
  retries = 3,
}: IUserGuidingObject) => {
  // TODO: Add better solution to wait & check for userGuiding existance
  if (!(window as any).userGuiding) {
    const needsToWaitForScript = retries && isAllowedCookieGroup("performance");

    if (needsToWaitForScript) {
      // Wait 2 seconds to check again
      setTimeout(() => {
        retries -= 1;
        userGuidingGuard({ name, attributes, next, retries });
      }, 2000);
    }

    return;
  }

  if (isDevelopment) {
    console.log(`UserGuiding: Segment sent with =>`, attributes);
  }

  return next({ name, attributes });
};

/**
 * Track a custom attribute using UserGuiding.
 *
 * @param name Segmentation
 * @param attributes Attributes to send
 */
export const userGuidingTrack = (
  name: string,
  attributes: { [key: string]: any } = {},
) =>
  userGuidingGuard({
    name,
    attributes,
    next: ({ name: _name, attributes: _props }: IUserGuidingObject) => {
      userGuiding.track(_name, _props);
    },
  });
