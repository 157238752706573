import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-b3133a48"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "px-milestone-level" }
const _hoisted_2 = { class: "px-milestone-level__wrapper" }
const _hoisted_3 = { class: "px-milestone-level__image" }
const _hoisted_4 = { class: "px-milestone-level__content" }
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = { class: "px-milestone-level__description" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PxLevel = _resolveComponent("PxLevel")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_PxLevel, {
          "category-color": _ctx.category.color.slice(1),
          level: _ctx.level
        }, null, 8, ["category-color", "level"])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("h2", {
          class: "px-milestone-level__title",
          innerHTML: 
            _ctx.$t('milestonePlanner.sidebar.milestonePlan.title', {
              category: _ctx.category.name,
            })
          
        }, null, 8, _hoisted_5),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("h3", null, _toDisplayString(_ctx.$t("profile.assessment.levelPanel.label")) + " " + _toDisplayString(_ctx.level), 1),
          _createElementVNode("p", null, _toDisplayString(_ctx.categoryLevelDescription), 1)
        ])
      ])
    ])
  ]))
}