<template>
  <ElDialog
    ref="modal"
    v-model="innerVisibility"
    :before-close="beforeCloseHandler"
    :title="$t(`profile.companyEditModal.title[${$profile.type()}]`)"
    class="company-edit-modal el-dialog--px-10"
    width="903px"
    @close="closeHandler"
  >
    <DialogConfirmation ref="dialog-confirmation" />
    <PxModalMessage :visible="hasServerErrors" />
    <CompanyEditModalEntrepreneur
      v-if="$profile.isEntrepreneur()"
      ref="modalForm"
      v-model:form-changed="hasFormChanged"
      v-model:form-submit="clickedSaveChanges"
      v-model:loading-submission="isLoadingSubmission"
      v-model:modal-visibility="innerVisibility"
      v-model:reset-form="resetFormData"
      v-model:server-errors="hasServerErrors"
      v-model:submission-disabled="submissionDisabled"
      :company="company"
    />
    <CompanyEditModalSupporter
      v-if="$profile.isSupporter()"
      ref="modalForm"
      v-model:form-changed="hasFormChanged"
      v-model:form-submit="clickedSaveChanges"
      v-model:loading-submission="isLoadingSubmission"
      v-model:modal-visibility="innerVisibility"
      v-model:reset-form="resetFormData"
      v-model:selected-view="selectedView"
      v-model:server-errors="hasServerErrors"
      v-model:submission-disabled="submissionDisabled"
      :company="company"
    />
    <template #footer>
      <div>
        <PxButton size="small" type="link" @click="beforeCloseHandler">
          {{ $t("common.cancel") }}
        </PxButton>
        <PxButton
          :disabled="submissionDisabled"
          :loading="isLoadingSubmission || isLoadingCompany"
          type="green"
          @click="onClickSave"
        >
          {{ $t("common.saveChanges") }}
        </PxButton>
      </div>
    </template>
  </ElDialog>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import CompanyEditModalEntrepreneur from "@/modules/profile/components/company-edit-modal/company-edit-modal--entrepreneur.vue";
import CompanyEditModalSupporter from "@/modules/profile/components/company-edit-modal/company-edit-modal--supporter.vue";
import DialogConfirmationMixin from "@/mixins/dialog-confirmation.mixin";

import { ICompany } from "@/modules/profile/services/data/company/company.types";
import { IDialogConfirmationOptions } from "@/components/dialog-confirmation/dialog-confirmation.types";

export default defineComponent({
  name: "CompanyEditModal",

  components: {
    CompanyEditModalEntrepreneur,
    CompanyEditModalSupporter,
  },

  mixins: [DialogConfirmationMixin],

  props: {
    visibility: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      selectedView: "general",
      hasFormChanged: false,
      resetFormData: false,
      clickedSaveChanges: false,
      innerVisibility: false,
      hasServerErrors: false,
      isLoadingSubmission: false,
      submissionDisabled: false,
    };
  },

  computed: {
    company(): ICompany | null {
      return this.$store.get("profile/company/data") || null;
    },

    isLoadingCompany(): boolean {
      return this.$store.get("profile/company/loading");
    },

    dialogConfirmationOptions(): IDialogConfirmationOptions {
      return {
        message: this.$t("common.dialogConfirmation.discardMessage"),
        confirmFn: () => this.onConfirmCancel(),
      };
    },
  },

  watch: {
    visibility: {
      immediate: true,
      handler(newVal) {
        this.innerVisibility = newVal;
        this.resetFormData = newVal;
      },
    },

    innerVisibility(newVal, oldVal) {
      if (newVal === oldVal) {
        return;
      }

      if (!newVal) {
        this.$emit("update:visibility", newVal);
      }
    },
  },

  mounted() {
    this.googleOptionsTop();
  },

  methods: {
    googleOptionsTop() {
      const dialogElement = (this.$refs.modal as any).$el as any;
      const googlePickerTop = () => {
        const googleList = document.querySelectorAll(".pac-container");
        const inputLocation = document.querySelector(
          "#companyEditLocation",
        ) as HTMLElement;

        /**
         * Find the Google object that is being displayed
         */
        const googleObject = Array.from(googleList).find(
          (item: any) => item.style.display !== "none",
        ) as HTMLElement;
        if (googleObject && inputLocation) {
          googleObject.style.top =
            inputLocation.getBoundingClientRect().top +
            inputLocation.offsetHeight +
            "px";
        }
      };

      if (dialogElement.PointerEvent) {
        // Add Pointer Event Listener
        dialogElement.addEventListener("pointerdown", googlePickerTop, true);
        dialogElement.addEventListener("pointermove", googlePickerTop, true);
      } else {
        // Add Touch Listener
        dialogElement.addEventListener("touchstart", googlePickerTop, true);
        dialogElement.addEventListener("touchmove", googlePickerTop, true);
      }
    },

    focusInputField(field: string) {
      (this.$refs.modalForm as any).$refs[field].focus();
    },

    setCurrentView(view: string) {
      this.selectedView = view;
    },

    onConfirmCancel() {
      this.innerVisibility = false;
      this.resetFormData = true;
    },

    beforeCloseHandler() {
      if (this.hasFormChanged) {
        this.showConfirmOverlay(this.dialogConfirmationOptions);
      } else {
        this.innerVisibility = false;
      }
    },

    closeHandler() {
      window.scrollTo(0, 0);
    },

    onClickSave() {
      this.clickedSaveChanges = true;
      this.$emit("update:visibility", false);
    },
  },
});
</script>

<style lang="scss">
.company-edit-modal {
  touch-action: none !important;

  /* touch-action: this was added to allow the caption of scroll event */

  @include breakpoint-down(md) {
    max-width: calc(100% - 30px);
    max-height: 698px;
    overflow-y: scroll;
  }

  @include breakpoint-down(sm) {
    max-height: calc(100vh - 24px);
    overflow-y: scroll;
  }

  .el-dialog__body {
    padding-right: 0;
    padding-left: 0;

    @include breakpoint-down(lg) {
      overflow: auto;
    }
  }
}

.company-edit-modal :deep(.px-modal-message) {
  pointer-events: none;
}
</style>
