import {
  GenericProvider,
  EProviderFeatures,
} from "@/services/data/generic-provider";
import { INetwork } from "@/services/data/network/network.interface";

class NetworkProvider extends GenericProvider<INetwork> {
  constructor() {
    super(`/network/`, [EProviderFeatures.LIST]);
  }
}

export const networkProvider = new NetworkProvider();
