<template>
  <div class="category-color-bar">
    <div
      v-for="(step, index) in steps"
      :key="step.value"
      :class="{
        'is-active': activeStep === index,
        'is-checked': isStepChecked(step),
      }"
      :style="`--stepColor: #${step.color}`"
      class="category-color-bar__color"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import { ICategory } from "@/services/data/category/category.interface";
import { ICategoryLevelSelection } from "@/services/data/viral-level/viral-level.interface";
import { ICategoryStepItem } from "@/modules/affiliates/views/affiliate.types";
import { ILevel } from "@/services/data/level/level.interface";

export default defineComponent({
  name: "CategoryColorBar",

  props: {
    /**
     * Allows to mark a step as active.
     */
    activeStep: {
      type: Number,
      default: -1,
    },
  },

  computed: {
    categories(): Array<ICategory> {
      return this.$store.state.categories.data;
    },

    steps(): Array<ICategoryStepItem> {
      return this.categories.map((item: ICategory) => ({
        value: item.id,
        label: item.name.replace(" and ", " & "),
        color: item.color,
      }));
    },

    /**
     * Available levels.
     */
    assessmentLevels(): Array<ICategoryLevelSelection> {
      return (
        this.$store.state.affiliates.draftSubmission?.data.assessment || []
      );
    },

    /**
     * Retrieves an array with all checked categories.
     */
    checkedSteps(): Array<number> {
      return this.assessmentLevels
        .filter(
          (record: ICategoryLevelSelection) =>
            (record.level as ILevel["value"]) > 0,
        )
        .map((record: ICategoryLevelSelection) => record.category);
    },
  },

  methods: {
    /**
     * Checks if the given step is part of the set of
     * current selected steps.
     */
    isStepChecked(step: ICategoryStepItem): boolean {
      return this.checkedSteps.includes(step.value);
    },
  },
});
</script>

<style lang="scss" scoped>
.category-color-bar {
  display: flex;
  justify-content: center;
  min-width: 100%;

  &__color {
    flex-grow: 1;
    width: auto;
    height: 12px;
    margin: 0 1px;

    background-color: $athens-gray;

    &.is-checked,
    &.is-active {
      background-color: var(--stepColor);
    }
  }
}
</style>
