import { IUserGuest } from "@/services/data/user-guest/user-guest.interface";

/**
 * Data structure for the user guest store module.
 */
export interface IUserGuestState {
  userGuest: IUserGuest | null;
}

/**
 * Actions to manager user guest.
 */
export enum EUserGuestActions {
  CREATE = "userGuest/create",
  SET = "userGuest/set",
  RESET = "userGuest/reset",
}

/**
 * Mutations for list passcode store module.
 */
export enum EUserGuestMutations {
  SET = "set",
  RESET = "reset",
}

/**
 * Getters for list passcode store module.
 */
export enum EUserGuestGetters {
  GET = "userGuest/get",
}
