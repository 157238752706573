import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-0535e192"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "matching-list-desktop__wrapper" }
const _hoisted_2 = { class: "matching-list-desktop__container" }
const _hoisted_3 = { class: "matching-list-desktop__sidebar" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PxButton = _resolveComponent("PxButton")!
  const _component_PxIcon = _resolveComponent("PxIcon")!
  const _component_MatchingListCards = _resolveComponent("MatchingListCards")!
  const _component_ElTabPane = _resolveComponent("ElTabPane")!
  const _component_MatchingListInterests = _resolveComponent("MatchingListInterests")!
  const _component_MatchingListInterestMutual = _resolveComponent("MatchingListInterestMutual")!
  const _component_ElTabs = _resolveComponent("ElTabs")!
  const _component_MatchingSidebar = _resolveComponent("MatchingSidebar")!
  const _component_ListManagementBar = _resolveComponent("ListManagementBar")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.$user.isSupporter())
        ? (_openBlock(), _createBlock(_component_PxButton, {
            key: 0,
            icon: _ctx.$options.static.editCriteriaButton.icon,
            label: _ctx.$t(`profile.supporter.criteria.button`),
            size: _ctx.$options.static.editCriteriaButton.size,
            type: _ctx.$options.static.editCriteriaButton.type,
            class: "matching-list-desktop__edit-criteria-btn",
            onClick: _ctx.onClickEditCriteria
          }, null, 8, ["icon", "label", "size", "type", "onClick"]))
        : _createCommentVNode("", true),
      _createVNode(_component_ElTabs, {
        modelValue: _ctx.activeTab,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.activeTab) = $event)),
        type: "card",
        onTabClick: _ctx.clickTabHandler
      }, {
        default: _withCtx(() => [
          _createVNode(_component_ElTabPane, {
            lazy: true,
            name: _ctx.EMatchingListTabs.DISCOVER
          }, {
            label: _withCtx(() => [
              _createVNode(_component_PxIcon, {
                name: _ctx.matchingTabs.discover.icon,
                class: "px-matching-interest__ctaIcon",
                size: "16"
              }, null, 8, ["name"]),
              _createTextVNode(" " + _toDisplayString(_ctx.matchingTabs.discover.label), 1)
            ]),
            default: _withCtx(() => [
              _createVNode(_component_MatchingListCards)
            ]),
            _: 1
          }, 8, ["name"]),
          (_ctx.$features.isEnabled(`matchingInterest`))
            ? (_openBlock(), _createBlock(_component_ElTabPane, {
                key: 0,
                lazy: true,
                name: _ctx.EMatchingListTabs.PENDING
              }, {
                label: _withCtx(() => [
                  _createVNode(_component_PxIcon, {
                    name: _ctx.matchingTabs.pending.icon,
                    class: "px-matching-interest__ctaIcon",
                    size: "16"
                  }, null, 8, ["name"]),
                  _createTextVNode(" " + _toDisplayString(_ctx.matchingTabs.pending.label), 1)
                ]),
                default: _withCtx(() => [
                  _createVNode(_component_MatchingListInterests, { onOnTabChanged: _ctx.updateChildSelectedTab }, null, 8, ["onOnTabChanged"])
                ]),
                _: 1
              }, 8, ["name"]))
            : _createCommentVNode("", true),
          (_ctx.$features.isEnabled(`matchingInterest`))
            ? (_openBlock(), _createBlock(_component_ElTabPane, {
                key: 1,
                lazy: true,
                name: _ctx.EMatchingListTabs.CONNECTED
              }, {
                label: _withCtx(() => [
                  _createVNode(_component_PxIcon, {
                    name: _ctx.matchingTabs.connected.icon,
                    class: "px-matching-interest__ctaIcon",
                    size: "16"
                  }, null, 8, ["name"]),
                  _createTextVNode(" " + _toDisplayString(_ctx.matchingTabs.connected.label), 1)
                ]),
                default: _withCtx(() => [
                  _createVNode(_component_MatchingListInterestMutual)
                ]),
                _: 1
              }, 8, ["name"]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["modelValue", "onTabClick"])
    ]),
    _withDirectives(_createElementVNode("div", _hoisted_3, [
      _createVNode(_component_MatchingSidebar)
    ], 512), [
      [_vShow, _ctx.showSidebar]
    ]),
    (_ctx.isSelectModeActive)
      ? (_openBlock(), _createBlock(_component_ListManagementBar, {
          key: 0,
          "available-items": _ctx.listManagementItems,
          onFooterVisibilityChanged: _ctx.onFooterVisibilityChanged
        }, null, 8, ["available-items", "onFooterVisibilityChanged"]))
      : _createCommentVNode("", true)
  ]))
}