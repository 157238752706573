<template>
  <picture>
    <source
      v-if="link.mobile"
      :srcset="`${link.mobile} 1x`"
      media="(max-width: 767px)"
    />
    <source
      v-if="link.mobileRetina"
      :srcset="`${link.mobileRetina} 2x`"
      media="(max-width: 767px)"
    />
    <source
      v-if="link.retina"
      :srcset="`${link.original} 1x, ${link.retina} 2x`"
    />
    <img :alt="alt" :src="link.original" />
  </picture>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { IBitmapLink } from "@/components/px-responsive-bitmap/px-responsive-bitmap.interface";

export default defineComponent({
  name: "PxResponsiveBitmap",

  props: {
    /**
     * Icon name.
     */
    link: {
      type: Object as () => IBitmapLink,
      required: true,
    },

    alt: {
      type: String,
      required: true,
    },
  },
});
</script>
