import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-1b432fae"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 1,
  class: "matching-ribbon__wrapper-inner"
}
const _hoisted_2 = { class: "matching-ribbon__content" }
const _hoisted_3 = { class: "matching-ribbon__title" }
const _hoisted_4 = { class: "matching-ribbon__percentage" }
const _hoisted_5 = { class: "matching-ribbon__percentage-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ContentPlaceholdersText = _resolveComponent("ContentPlaceholdersText")!
  const _component_ContentPlaceholders = _resolveComponent("ContentPlaceholders")!
  const _component_PxIcon = _resolveComponent("PxIcon")!
  const _component_MatchDetailsModal = _resolveComponent("MatchDetailsModal")!

  return (_ctx.isRibbonVisible)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass([{
      'matching-ribbon--public__wrapper-outer': _ctx.isPublicView,
      'matching-ribbon--slim__wrapper-outer': _ctx.isSlimVersion,
      'matching-ribbon--no-match__wrapper-outer': !_ctx.hasMatch,
    }, "matching-ribbon__wrapper-outer"])
      }, [
        (_ctx.isLoading)
          ? (_openBlock(), _createBlock(_component_ContentPlaceholders, {
              key: 0,
              rounded: true
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ContentPlaceholdersText, {
                  lines: 1,
                  class: "matching-ribbon__placeholder"
                })
              ]),
              _: 1
            }))
          : (_openBlock(), _createElementBlock("div", _hoisted_1, [
              (!_ctx.isSlimVersion)
                ? (_openBlock(), _createBlock(_component_PxIcon, {
                    key: 0,
                    size: 18,
                    class: "matching-ribbon__icon",
                    name: "icon-matching"
                  }))
                : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_2, [
                _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.ribbonTitle), 1),
                (_ctx.$screen.mdUp && !_ctx.hasMatchScoreDetailsError)
                  ? (_openBlock(), _createElementBlock("span", {
                      key: 0,
                      class: "matching-ribbon__subtitle",
                      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onRibbonClick && _ctx.onRibbonClick(...args)))
                    }, _toDisplayString(_ctx.ribbonSubtitle), 1))
                  : _createCommentVNode("", true)
              ]),
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.formattedMatchPercentage), 1)
              ]),
              (_ctx.isInterestDetailModalVisible)
                ? (_openBlock(), _createBlock(_component_MatchDetailsModal, {
                    key: 1,
                    visibility: _ctx.isInterestDetailModalVisible,
                    "onUpdate:visibility": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.isInterestDetailModalVisible) = $event)),
                    "company-logo": _ctx.profileCompanyLogo,
                    "company-name": _ctx.profileCompanyName,
                    "match-details": _ctx.matchScoreDetails
                  }, null, 8, ["visibility", "company-logo", "company-name", "match-details"]))
                : _createCommentVNode("", true)
            ]))
      ], 2))
    : _createCommentVNode("", true)
}