import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, mergeProps as _mergeProps, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-ad449220"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "affiliate-review-page-questions" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "affiliate-review-page-questions__title" }
const _hoisted_4 = {
  key: 0,
  class: "affiliate-review-page-questions__missing-info-label"
}
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = { class: "affiliate-review-page-questions__wrapper" }
const _hoisted_7 = { class: "affiliate-review-page-questions__info-divider" }
const _hoisted_8 = { class: "affiliate-review-page-questions__info-divider" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PxIcon = _resolveComponent("PxIcon")!
  const _component_PxInformationCard = _resolveComponent("PxInformationCard")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.$screen.xlUp)
      ? (_openBlock(), _createElementBlock("header", _hoisted_2, [
          _createElementVNode("h3", _hoisted_3, _toDisplayString(_ctx.$t("affiliates.reviewPage.navigation.questionsTab")), 1),
          (_ctx.notAnswered.length)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createVNode(_component_PxIcon, {
                  size: 16,
                  name: "warning--white"
                }),
                _createElementVNode("p", { innerHTML: _ctx.missingQuestionsCopy }, null, 8, _hoisted_5)
              ]))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_6, [
      (_ctx.notAnswered.length)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("span", null, _toDisplayString(_ctx.$t("affiliates.reviewPage.content.notAnswered")), 1)
            ]),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.notAnswered, (answer) => {
              return (_openBlock(), _createBlock(_component_PxInformationCard, _mergeProps({
                key: answer.question,
                ref_for: true
              }, _ctx.notAnsweredCardStyles, {
                description: _ctx.$t('common.notAnswered'),
                title: 
              _ctx.getQuestion(answer.question as number)?.entrepreneur_question
            ,
                class: "affiliate-review-page-questions__info-card",
                onClick: ($event: any) => (_ctx.navigateToQuestion(answer.question as number))
              }), null, 16, ["description", "title", "onClick"]))
            }), 128))
          ], 64))
        : _createCommentVNode("", true),
      (_ctx.answered.length)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
            _createElementVNode("div", _hoisted_8, [
              _createElementVNode("span", null, _toDisplayString(_ctx.$t("affiliates.reviewPage.content.answered")), 1)
            ]),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.answered, (answer) => {
              return (_openBlock(), _createBlock(_component_PxInformationCard, _mergeProps({
                key: answer.question,
                ref_for: true
              }, _ctx.answeredCardStyles, {
                description: _ctx.getAnswer(answer),
                title: 
              _ctx.getQuestion(answer.question as number)?.entrepreneur_question
            ,
                class: "affiliate-review-page-questions__info-card",
                onClick: ($event: any) => (_ctx.navigateToQuestion(answer.question as number))
              }), null, 16, ["description", "title", "onClick"]))
            }), 128))
          ], 64))
        : _createCommentVNode("", true)
    ])
  ]))
}