<template>
  <RenderlessAssessmentPanel v-slot="scope">
    <div class="panel-grid__block">
      <AssessmentPanelPlaceholder v-if="scope.isLoading" />
      <PxPanel
        v-else
        :class="{ 'profile-panel--disabled': scope.isInactive }"
        class="profile-panel profile-panel--assessment"
      >
        <AssessmentPanelLevelInactive
          v-if="scope.isInactive"
          :allow-level-update="scope.allowLevelUpdate"
          @request-new-assessment="scope.makeNewAssessment"
        />
        <AssessmentPanelLevel
          v-else
          :latest-assessment="scope.latestAssessment"
          :level="scope.latestAssessmentLevel"
          :levels="scope.levels"
          @request-new-assessment="scope.makeNewAssessment"
        />
        <AssessmentPanelGridInactive v-if="scope.isInactive" />
        <AssessmentPanelGrid
          v-else
          :latest-assessment="scope.latestAssessment"
          :level-value="scope.latestAssessmentLevelValue"
          :levels="scope.levels"
        />
        <AssessmentPanelHistoryInactive
          v-if="scope.isInactive"
          v-feature="'assessmentHistory'"
        />
        <AssessmentPanelHistory
          v-else
          v-feature="'assessmentHistory'"
          :allow-level-update="scope.allowLevelUpdate"
          @request-new-assessment="scope.makeNewAssessment"
        />
      </PxPanel>
    </div>
  </RenderlessAssessmentPanel>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import RenderlessAssessmentPanel from "@/modules/profile/components/renderless-assessment-panel/renderless-assessment-panel.vue";
import AssessmentPanelPlaceholder from "@/modules/profile/components/assessment-panel/assessment-panel-placeholder.vue";
import AssessmentPanelLevel from "@/modules/profile/components/assessment-panel/assessment-panel--level.vue";
import AssessmentPanelLevelInactive from "@/modules/profile/components/assessment-panel/assessment-panel--level-inactive.vue";
import AssessmentPanelGrid from "@/modules/profile/components/assessment-panel/assessment-panel--grid.vue";
import AssessmentPanelGridInactive from "@/modules/profile/components/assessment-panel/assessment-panel--grid-inactive.vue";
import AssessmentPanelHistory from "@/modules/profile/components/assessment-panel/assessment-panel--history.vue";
import AssessmentPanelHistoryInactive from "@/modules/profile/components/assessment-panel/assessment-panel--history-inactive.vue";

export default defineComponent({
  name: "AssessmentPanel",

  components: {
    RenderlessAssessmentPanel,
    AssessmentPanelLevel,
    AssessmentPanelLevelInactive,
    AssessmentPanelGrid,
    AssessmentPanelGridInactive,
    AssessmentPanelPlaceholder,
    AssessmentPanelHistory,
    AssessmentPanelHistoryInactive,
  },
});
</script>

<style lang="scss" scoped>
.panel-grid__block {
  @include breakpoint-down(lg) {
    margin-top: 30px;
  }
}

.profile-panel--assessment :deep() .px-panel__body {
  padding-bottom: 16px;
}

.profile-panel__row:not(:first-child) {
  padding-top: 34px;
  margin-top: 22px;
  border-top: solid 1px $athens-gray;
}

.profile-panel__row.assessment-history {
  padding-top: 28px;
}
</style>
