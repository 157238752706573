import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, createElementBlock as _createElementBlock, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-7c1d102e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "affiliate-self-assessment" }
const _hoisted_2 = { "element-loading-background": "#fafafa" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PxNavbar = _resolveComponent("PxNavbar")!
  const _component_MobileTopNavbar = _resolveComponent("MobileTopNavbar")!
  const _component_LangSwitcher = _resolveComponent("LangSwitcher")!
  const _component_SelfAssessmentStartingPage = _resolveComponent("SelfAssessmentStartingPage")!
  const _component_AffiliateAssessment = _resolveComponent("AffiliateAssessment")!
  const _component_AffiliateEntrepreneurResults = _resolveComponent("AffiliateEntrepreneurResults")!
  const _component_AffiliateFooterBar = _resolveComponent("AffiliateFooterBar")!
  const _component_PxFooter = _resolveComponent("PxFooter")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", null, [
      (_ctx.$screen.mdUp)
        ? (_openBlock(), _createBlock(_component_PxNavbar, {
            key: 0,
            "external-link": _ctx.affiliateWebsite,
            loading: _ctx.affiliateDataLoading,
            logo: _ctx.affiliateLogo
          }, null, 8, ["external-link", "loading", "logo"]))
        : (_openBlock(), _createBlock(_component_MobileTopNavbar, {
            key: 1,
            "affiliate-logo": _ctx.affiliateLogo,
            "is-assessment-page": _ctx.isAssessmentPage,
            "show-subtitle": !_ctx.isStartingPage
          }, null, 8, ["affiliate-logo", "is-assessment-page", "show-subtitle"])),
      (_ctx.isStartingPage && !_ctx.affiliateDataLoading)
        ? (_openBlock(), _createBlock(_component_LangSwitcher, { key: 2 }))
        : _createCommentVNode("", true)
    ]),
    _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_2, [
      (_ctx.isStartingPage)
        ? (_openBlock(), _createBlock(_component_SelfAssessmentStartingPage, {
            key: 0,
            onNextStep: _ctx.goToNextStep
          }, null, 8, ["onNextStep"]))
        : _createCommentVNode("", true),
      (_ctx.isAssessmentPage && !_ctx.resultsPageVisible)
        ? (_openBlock(), _createBlock(_component_AffiliateAssessment, {
            key: 1,
            "is-self-assessment": true,
            onGoBack: _ctx.goToPreviousStep
          }, null, 8, ["onGoBack"]))
        : _createCommentVNode("", true),
      (_ctx.resultsPageVisible)
        ? (_openBlock(), _createBlock(_component_AffiliateEntrepreneurResults, { key: 2 }))
        : _createCommentVNode("", true)
    ])), [
      [_directive_loading, _ctx.affiliateDataLoading]
    ]),
    (!_ctx.isStartingPage && !_ctx.resultsPageVisible)
      ? (_openBlock(), _createBlock(_component_AffiliateFooterBar, {
          key: 0,
          date: _ctx.updatedDate,
          "is-last-page": _ctx.isAssessmentLastCategory,
          "is-saving": _ctx.saving,
          onNextStep: _ctx.goToNextStep,
          onPreviousStep: _ctx.goToPreviousStep,
          onShowSavedDraftMessage: _ctx.showSavedDraftMessage
        }, null, 8, ["date", "is-last-page", "is-saving", "onNextStep", "onPreviousStep", "onShowSavedDraftMessage"]))
      : (_openBlock(), _createBlock(_component_PxFooter, { key: 1 }))
  ]))
}