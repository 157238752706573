<template>
  <div class="auth-base-signup__step signup-step-account">
    <PxPanel class="px-panel--compact">
      <template #header>
        <h2
          class="px-panel__title"
          v-html="$t('authentication.signup.form.account')"
        />
      </template>
      <ElForm
        ref="signupForm"
        :model="innerAccount"
        :rules="rules"
        class="auth-base-signup__form"
        @validate="updateSubmitState"
      >
        <ElFormItem
          :error="errors.getMessage('email')"
          :label="$t('authentication.signup.form.fields.email')"
          prop="email"
        >
          <ElInput
            v-model="innerAccount.email"
            :placeholder="$t('authentication.signup.form.placeholders.email')"
            data-testid="authentication-signup-input-email"
            type="email"
          />
        </ElFormItem>
        <ElFormItem
          :label="$t('authentication.signup.form.fields.password')"
          prop="password1"
        >
          <span
            class="el-form-item__top-text el-form-item__requirement"
            v-text="$t('authentication.signup.form.requirement')"
          />
          <PxInputPassword
            v-model="innerAccount.password1"
            :placeholder="$t('authentication.signup.form.fields.password')"
            :show-success-state="true"
            data-testid="authentication-signup-input-password"
            @input="validateConfirmationField"
          />
          <span
            class="el-form-item__bottom-text el-form-item__tip"
            v-html="$t('authentication.signup.form.tip')"
          />
        </ElFormItem>
        <ElFormItem
          :label="$t('authentication.signup.form.fields.confirmPassword')"
          prop="password2"
        >
          <ElInput
            v-model="innerAccount.password2"
            :disabled="passwordIsInvalid"
            :placeholder="
              $t('authentication.signup.form.fields.confirmPassword')
            "
            class="el-input--password"
            data-testid="authentication-signup-input-password2"
            type="password"
          />
        </ElFormItem>
        <ElFormItem :show-message="false" prop="terms" required>
          <ElCheckbox
            v-model="innerAccount.terms"
            class="auth-base-signup__step--selector"
            data-testid="authentication-signup-checkbox-terms"
          >
            <i18n-t
              class="auth-base-signup__step--selector-text"
              keypath="authentication.signup.form.fields.terms"
              tag="p"
            >
              <template #terms>
                <a
                  :href="$options.static.termsOfUseUrl"
                  class="text--nice-blue"
                  target="_blank"
                >
                  {{ $t("authentication.signup.form.fields.termsOfUse") }}
                </a>
              </template>
              <template #privacy>
                <a
                  :href="$options.static.privacyPolicyUrl"
                  class="text--nice-blue"
                  target="_blank"
                >
                  {{ $t("authentication.signup.form.fields.privacyPolicy") }}
                </a>
              </template>
            </i18n-t>
          </ElCheckbox>
        </ElFormItem>
        <div class="el-form__cta">
          <button
            class="el-button el-button--link el-button--small"
            type="button"
            @click="goToPreviousStep"
          >
            <span>
              {{ $t("authentication.signup.form.previousButton") }}
            </span>
          </button>
          <PxButton
            :disabled="submissionDisabled"
            :loading="loading"
            data-testid="authentication-signup-submit-button"
            size="medium"
            type="primary"
            @click="submitForm"
          >
            {{ $t("authentication.signup.form.submitButton") }}
          </PxButton>
        </div>
      </ElForm>
    </PxPanel>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import {
  AUTHENTICATION_TERMS_OF_USE_URL,
  AUTHENTICATION_PRIVACY_POLICY_URL,
} from "@/modules/authentication/constants";

import {
  allFormFieldsValid,
  generateEmailValidator,
  generatePasswordCheck,
  generatePasswordValidator,
  generateCheckboxValidator,
} from "@/services/errors/validator-generators";
import { FormInstance } from "element-plus";
import { IFormField } from "@/modules/company-lists/components/list-management/list-management-modal.vue";

export default defineComponent({
  name: "SignUpStepAccount",

  props: {
    account: {
      type: Object as () => any,
      required: true,
    },

    errors: {
      type: Object as () => any,
      required: true,
    },

    loading: {
      type: Boolean,
      required: true,
    },
  },

  static: {
    termsOfUseUrl: AUTHENTICATION_TERMS_OF_USE_URL,
    privacyPolicyUrl: AUTHENTICATION_PRIVACY_POLICY_URL,
  },

  data() {
    return {
      submissionDisabled: true,
      formEl: null as null | FormInstance,
      innerAccount: {
        email: "",
        password1: "",
        password2: "",
        terms: false,
      },

      rules: {
        email: generateEmailValidator(
          this,
          "authentication.signup.form.fields.email",
          true,
        ),
        password1: generatePasswordValidator(
          this,
          "authentication.signup.form.fields.password",
          {
            minimum: 8,
            enforceCharRequirements: true,
          },
        ),
        password2: generatePasswordCheck(this, "innerAccount.password1", {
          minimum: 8,
        }),
        terms: generateCheckboxValidator(),
      },
    };
  },

  computed: {
    formFields(): IFormField[] | null {
      return this.formEl ? ((this.formEl as any).fields as IFormField[]) : null;
    },

    passwordIsInvalid(): boolean {
      if (this.formFields) {
        const formFields = this.formFields;

        const passwordField = formFields.find(
          (field) => field.prop === "password1",
        );

        return passwordField?.validateState !== "success";
      }
      return true;
    },
  },

  mounted() {
    (this as any).formEl = this.$refs.signupForm;
  },

  methods: {
    updateSubmitState() {
      this.submissionDisabled = !allFormFieldsValid(this.formEl, this.rules);

      if (!this.submissionDisabled) {
        this.$emit("update:account", this.innerAccount);
      }
    },

    goToPreviousStep() {
      this.$emit("previous-step");
    },

    submitForm() {
      this.$emit("submit-form");
    },

    validateConfirmationField() {
      if (!this.passwordIsInvalid) {
        this.formEl?.validateField("password2", this.updateSubmitState);
      }
    },
  },
});
</script>

<style lang="scss" scoped>
.auth-base-signup__step {
  .el-button--link {
    padding: 9px 0 4px 1px;
  }

  .el-form__cta {
    button + button {
      margin-left: 11px;
    }

    @include breakpoint-up(md) {
      margin-top: 30px;
    }
  }
}
.auth-base-signup__step--selector {
  padding-top: 21px;
  word-wrap: break-word;
  white-space: normal;

  :deep(.el-checkbox__input) {
    bottom: 19px;
  }

  :deep(.el-checkbox__label) {
    max-width: 290px;
    padding-left: 12px;

    @include breakpoint-down(md) {
      max-width: 210px;
    }
  }

  :deep(.text--nice-blue) {
    text-decoration: none;
  }
}

.auth-base-signup__step--selector-text {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: $ebony-clay;
}
</style>
