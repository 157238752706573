import { isAllowedCookieGroup } from "./onetrust";
import {
  ENTREPRENEUR_USER_TYPE,
  SUPPORTER_USER_TYPE,
} from "@/modules/authentication/constants";

interface IHelpScoutOptions {
  attributes?: { [key: string]: any };
  next?: Function;

  // Used for applying a retry mechanism when
  // HelpScout isn't immediately available.
  retries?: number;
}

const helpScoutGuard = ({
  attributes = {},
  next = () => null,
  retries = 3,
}: IHelpScoutOptions) => {
  // TODO: Add better solution to wait & check for userGuiding existance
  if (!(window as any).Beacon) {
    const needsToWaitForScript = retries && isAllowedCookieGroup("performance");

    if (needsToWaitForScript) {
      // Wait 2 seconds to check again
      setTimeout(() => {
        retries -= 1;
        helpScoutGuard({ attributes, next, retries });
      }, 2000);
    }

    return;
  }

  return next({ attributes });
};

/**
 * Load HelpScout Beacon.
 *
 * @param attributes Attributes to send
 */
export const helpScoutBeaconInit = (attributes: { [key: string]: any } = {}) =>
  helpScoutGuard({
    attributes,
    next: ({ attributes: _props }: IHelpScoutOptions) => {
      const { userType } = _props || {};

      switch (userType) {
        case ENTREPRENEUR_USER_TYPE:
          (window as any).Beacon(
            "init",
            process.env.VUE_APP_HELPSCOUT_ENTREPRENEUR_BEACON,
          );
          break;
        case SUPPORTER_USER_TYPE:
          (window as any).Beacon(
            "init",
            process.env.VUE_APP_HELPSCOUT_SUPPORTER_BEACON,
          );
          break;
        default:
          (window as any).Beacon(
            "init",
            process.env.VUE_APP_HELPSCOUT_GENERAL_BEACON,
          );
      }
    },
  });

/**
 * Destroy HelpScout Beacon.
 */
export const helpScoutBeaconDestroy = () =>
  helpScoutGuard({
    next: () => {
      (window as any).Beacon("destroy");
    },
  });
