<template>
  <ElDialog v-model="innerVisibility" class="criteria-modal" width="264px">
    <div v-markdown="modalContent" class="criteria-modal__content" />
  </ElDialog>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { ICategory } from "@/services/data/category/category.interface";
import { EViralLevelGetters } from "@/services/store/viral-level/viral-level-types";

export default defineComponent({
  name: "CriteriaModal",

  props: {
    /**
     * Sets the modal visibility
     */
    visibility: {
      type: Boolean,
      required: true,
    },

    /**
     * Category level with the info to be shown.
     */
    categoryLevel: {
      type: Object as () => any,
      required: true,
    },
  },

  data() {
    return {
      innerVisibility: false,
    };
  },

  computed: {
    category(): ICategory {
      return this.$store.getters[EViralLevelGetters.CURRENT_CATEGORY];
    },

    /**
     * Returns the modal content.
     */
    modalContent(): string {
      if (!this.categoryLevel.level) {
        return "";
      }

      return this.$t("selfAssessment.assessment.levelDescriptionTemplate", {
        category: this.category.name,
        level: this.categoryLevel.level.value,
        description: this.categoryLevel.requirements,
      }) as string;
    },
  },

  watch: {
    visibility(newVal, oldVal) {
      if (newVal === oldVal) {
        return;
      }

      this.innerVisibility = newVal;
    },

    innerVisibility(newVal) {
      this.$emit("update:visibility", newVal);
    },
  },
});
</script>

<style lang="scss">
.criteria-modal {
  .el-dialog__header {
    padding: 12px 31px 14px;
    background: transparent;
    border: none;
  }

  .el-dialog__headerbtn {
    top: 0;
    right: 0;
  }

  .el-dialog__body {
    padding: 0 19px 5px 21px;

    color: $ebony-clay;
  }

  .el-dialog__headerbtn,
  .el-dialog__headerbtn::after {
    background-size: 14px;
  }

  &__content p:first-child {
    margin-bottom: 8px;

    strong {
      font-size: 1.0667rem;
    }

    em {
      font-size: 1.0667rem;
    }
  }

  &__content ul {
    padding-left: 11px;

    li {
      margin-bottom: 8px;

      font-size: 1rem;
      line-height: 23px;
    }

    li:last-child {
      margin-bottom: 0;
    }
  }
}
</style>
