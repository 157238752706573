<template>
  <div class="milestone-category-navigation">
    <div>
      <span
        v-if="currentCategoryIndex !== 1"
        class="milestone-category-navigation__arrow milestone-category-navigation__arrow--prev"
        @click="changeCurrentCategoryIndexBy(-1)"
      />
    </div>
    <div>
      <p>
        {{ selectedCategory }}
      </p>
    </div>
    <div>
      <span
        v-if="currentCategoryIndex < categories.length"
        class="milestone-category-navigation__arrow milestone-category-navigation__arrow--next"
        @click="changeCurrentCategoryIndexBy(1)"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { IGridCategory } from "@/modules/milestone-planner/components/milestones-grid/milestones-grid.interface";

export default defineComponent({
  name: "MilestoneCategoryNavigation",

  props: {
    categories: {
      type: Array as () => IGridCategory[],
      required: true,
    },
    currentCategory: {
      type: Object as () => IGridCategory,
      required: true,
    },
  },

  data() {
    return {
      currentCategoryIndex: this.currentCategory.order,
      selectedCategory: this.currentCategory.name,
    };
  },

  watch: {
    currentCategory: function (category: IGridCategory) {
      this.selectedCategory = category.name;
    },
  },

  methods: {
    changeCurrentCategoryIndexBy(direction: number) {
      this.currentCategoryIndex += direction;

      // Get the new category
      const newCategory = this.categories.filter(
        (category) => category.order === this.currentCategoryIndex,
      );

      if (newCategory) {
        this.selectedCategory = newCategory[0].name;
        this.$emit("change", newCategory[0]);
      }
    },
  },
});
</script>

<style lang="scss" scoped>
.milestone-category-navigation {
  display: grid;
  grid-template-columns: 13px 85px 13px;
  align-items: center;
  min-height: 34px;
  margin-bottom: 15px;

  p {
    font-size: 13px;
    font-weight: 600;
    line-height: 16px;
    color: $ebony-clay;
    text-align: center;
    text-transform: uppercase;
  }

  &__arrow {
    position: relative;
    bottom: 2px;
    display: inline-block;
    width: 0;
    height: 0;
    vertical-align: middle;
    border-radius: 2px;

    &:hover {
      cursor: pointer;
    }

    &--next {
      left: 7px;
      border-top: 7px solid transparent;
      border-bottom: 7px solid transparent;
      border-left: 7px solid $manatee;
    }

    &--prev {
      border-top: 7px solid transparent;
      border-right: 7px solid $manatee;
      border-bottom: 7px solid transparent;
    }
  }
}
</style>
