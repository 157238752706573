import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, mergeProps as _mergeProps, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-4f588d5e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "questions-panel-header" }
const _hoisted_2 = { class: "questions-panel-header__title" }
const _hoisted_3 = { class: "questions-panel-header__counter" }
const _hoisted_4 = { class: "questions-panel-header__progress" }
const _hoisted_5 = { class: "questions-panel-header__answers" }
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = ["innerHTML"]
const _hoisted_8 = {
  key: 0,
  class: "questions-panel-header__actions"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ElProgress = _resolveComponent("ElProgress")!
  const _component_PxButton = _resolveComponent("PxButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h3", null, _toDisplayString(_ctx.$t("capitalExplorer.questionsPanel.title")), 1)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_ElProgress, {
          percentage: _ctx.answeredPercentage,
          "show-text": false,
          "stroke-width": 6,
          width: 66,
          color: "#4caf50",
          type: "circle"
        }, null, 8, ["percentage"]),
        _createElementVNode("p", null, _toDisplayString(`${_ctx.questionsAnswered}/${_ctx.questionsTotal}`), 1)
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("p", {
          class: _normalizeClass([{
            'questions-panel-header__answers-title--all-answered':
              _ctx.answeredPercentage === 100,
          }, "questions-panel-header__answers-title"]),
          innerHTML: _ctx.questionsTitle
        }, null, 10, _hoisted_6),
        _createElementVNode("p", {
          class: "questions-panel-header__answers-description",
          innerHTML: _ctx.questionsDescription
        }, null, 8, _hoisted_7)
      ])
    ]),
    _createVNode(_Transition, {
      mode: "out-in",
      name: "fade"
    }, {
      default: _withCtx(() => [
        (_ctx.questionsAnswered)
          ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
              _createVNode(_component_PxButton, _mergeProps({ class: "questions-panel-header__clear" }, _ctx.clearButtonProps, {
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('clear-answers')))
              }), null, 16)
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ]))
}