<template>
  <div class="account-settings-email">
    <h1>
      {{ $t("profile.accountSettingsPage.emailForm.title") }}
    </h1>
    <p>
      {{ $t("profile.accountSettingsPage.emailForm.description") }}
    </p>
    <ElForm
      ref="email"
      :model="fields"
      :rules="rules"
      class="account-settings-email__form"
      @validate="updateSubmitState"
    >
      <ElFormItem
        :label="$t('profile.accountSettingsPage.emailForm.labels[0]')"
        data-testid="account-settings-email"
        prop="email"
      >
        <ElInput
          v-model="fields.email"
          :placeholder="$t('profile.accountSettingsPage.emailForm.labels[0]')"
          data-testid="account-settings-email-input"
          type="email"
        />
        <div
          v-if="emailChangeRequested"
          class="account-settings-email__instructions"
        >
          <div
            class="account-settings-email__notice"
            v-html="
              $t('profile.accountSettingsModal.changeEmail.notice', {
                email: newEmail,
              })
            "
          />
          <div
            v-if="!resentEmailConfirmation"
            class="account-settings-email__cta"
          >
            {{ $t("profile.accountSettingsModal.changeEmail.help") }}
            <PxButton
              class="el-button--link-blue"
              data-testid="account-settings-email-resend-link"
              type="link"
              @click="resendEmailClickHandler"
            >
              {{ $t("profile.accountSettingsModal.changeEmail.resendLink") }}
            </PxButton>
          </div>
        </div>
      </ElFormItem>
      <ElFormItem
        v-show="emailHasChanged"
        :label="$t('profile.accountSettingsPage.emailForm.labels[1]')"
        data-testid="account-settings-password"
        prop="password"
      >
        <ElInput
          v-model="fields.password"
          :placeholder="$t('profile.accountSettingsPage.emailForm.labels[1]')"
          autocomplete="new-password"
          data-testid="account-settings-password-input"
          type="password"
        />
      </ElFormItem>
    </ElForm>
    <footer>
      <PxButton
        :disabled="!emailHasChanged"
        class="el-button--small account-settings-email__cancel-btn"
        type="link"
        @click="cancelEmailChange"
      >
        {{ $t("profile.accountSettingsModal.changePassword.resetButton") }}
      </PxButton>
      <PxButton
        :disabled="isSubmissionDisabled || !emailHasChanged"
        :loading="isLoading"
        class="account-settings-email__submit-btn"
        type="green"
        @click="updateEmail"
      >
        {{ $t("profile.accountSettingsPage.emailForm.submitButton") }}
      </PxButton>
    </footer>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import {
  generateRequiredValidator,
  allFormFieldsValid,
  generateEmailValidator,
} from "@/services/errors/validator-generators";

import { FormInstance } from "element-plus";
import { resendEmailProvider } from "@/modules/authentication/services/data/resend-email/resend-email.provider";
import { emailChangeProvider } from "@/modules/authentication/services/data/email-change/email-change.provider";

export default defineComponent({
  name: "AccountSettingsEmail",

  data() {
    return {
      emailFormEl: null as null | FormInstance,
      isSubmissionDisabled: true,
      emailChangeRequested: false,
      resentEmailConfirmation: false,
      isLoading: false,
      newEmail: "",
      fields: {
        email: this.$store.get("auth/user.email"),
        password: "",
      },
      rules: {
        email: generateEmailValidator(
          this,
          "profile.accountSettingsModal.changeEmail.fields.newEmail",
          true,
        ),

        password: generateRequiredValidator(
          this,
          "profile.accountSettingsModal.changeEmail.fields.password",
        ),
      },
    };
  },

  computed: {
    userEmail(): string {
      return this.$store.get("auth/user.email");
    },

    emailHasChanged(): boolean {
      return this.fields.email !== this.userEmail;
    },
  },

  mounted() {
    (this as any).emailFormEl = this.$refs.email;
  },

  methods: {
    updateSubmitState() {
      this.isSubmissionDisabled = !allFormFieldsValid(
        this.emailFormEl,
        this.rules,
      );
    },

    cancelEmailChange() {
      this.emailFormEl?.resetFields();
      this.updateSubmitState();
    },

    triggerSuccessMessage() {
      this.$message({
        message: this.$t(
          "profile.accountSettingsModal.changeEmail.successMessage",
        ) as string,
        type: "success",
        customClass: "is-full",
      });
    },

    triggerErrorMessage() {
      this.$message({
        message: this.$t(
          "profile.accountSettingsModal.changePassword.errorMessage",
        ) as string,
        type: "error",
        customClass: "is-full",
      });
    },

    async updateEmail() {
      this.isLoading = true;

      try {
        await emailChangeProvider.create(this.fields);

        this.triggerSuccessMessage();
        this.emailChangeRequested = true;
        this.newEmail = this.fields.email;
        this.emailFormEl?.resetFields();
      } catch (error) {
        this.triggerErrorMessage();
      }

      this.isLoading = false;
      this.updateSubmitState();
    },

    async resendEmailClickHandler() {
      this.isLoading = true;

      try {
        await resendEmailProvider.create({
          email: this.newEmail,
        });

        this.triggerSuccessMessage();
        this.resentEmailConfirmation = true;
        this.emailChangeRequested = true;
      } catch (error) {
        this.triggerErrorMessage();
      }

      this.isLoading = false;
    },
  },
});
</script>

<style lang="scss" scoped>
.account-settings-email {
  padding: 22px 24px 23px 23px;
  border-radius: 4px;
  border: solid 1px $mischka;
  background-color: $white;

  @include breakpoint-down(sm) {
    border-left: none;
    border-right: none;
    border-radius: 0;

    padding: 22px 16px 24px 16px;

    :deep() .el-input__inner {
      height: 40px;
    }

    :deep() .el-form-item {
      margin-bottom: 3px;
    }

    &__submit-btn {
      height: 36px;
    }
  }

  h1 {
    font-size: 20px;
    line-height: 1.6;
    margin: 0;
    letter-spacing: -0.2px;
  }

  p {
    font-size: 14px;
    line-height: 1.43;
    margin: 0;
    color: $manatee;
    letter-spacing: -0.33px;
  }

  &__form {
    margin-top: 12px;
  }

  &__instructions {
    margin-top: 15px;
  }

  &__notice {
    color: $ebony-clay;
    margin-bottom: 7px;
  }

  &__cta {
    color: $manatee;
    letter-spacing: -0.2px;
    margin-bottom: 5px;

    :deep() .el-button--link-blue {
      height: auto;
      padding: 1px 0;
    }

    :deep() .el-button--link-blue span {
      font-size: 13px;
      font-weight: 500;
      line-height: 1.46;
      letter-spacing: -0.2px;
    }
  }

  &__notice,
  &__cta {
    font-size: 13px;
    font-weight: 500;
    line-height: 1.46;
  }

  :deep() .el-form-item:nth-child(2) {
    margin-top: 11px;
    margin-bottom: 4px;
  }

  :deep() .el-form-item__label {
    padding-top: 14px;
    margin-bottom: 3px;
  }

  :deep() .el-form-item__content {
    max-width: 509px;
  }

  :deep() .el-form-item__error {
    padding-top: 2px;
  }

  :deep() .el-input__inner {
    box-shadow: inset 0 0 5px 0 rgba(0, 0, 0, 0.07);
  }

  footer {
    display: flex;
    gap: 14px;
    padding: 20px 0 0 0;
  }

  &__cancel-btn {
    padding: 14px 0 11px;
  }

  &__submit-btn {
    padding: 0 15px;
  }
}
</style>
