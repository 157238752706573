import { Module } from "vuex";
import { IMatchingState } from "./matching.types";
import { RootState } from "@/services/store/root-state";
import { make } from "vuex-pathify";
import { matchingActions } from "./matching.actions";
import { matchingMutations } from "./matching.mutations";

const state: IMatchingState = {
  data: [],
  page: 0,
  error: null,
  loading: false,
  recalculating: false,
};

export const matchingModule: Module<IMatchingState, RootState> = {
  namespaced: true,
  state,
  actions: matchingActions,
  mutations: {
    ...make.mutations(state),
    ...matchingMutations,
  },
};
