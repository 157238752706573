<template>
  <div class="navigation-footer">
    <div class="navigation-footer__wrapper">
      <div class="navigation-footer__container">
        <div class="navigation-footer__left-container">
          <slot name="left" />
        </div>
        <div class="navigation-footer__center-container">
          <slot name="center" />
        </div>
        <div class="navigation-footer__right-container">
          <slot name="right" />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

// TODO: Move this to generic components and merge it with px-sticky-bar component. Rename it to StickyFooter.
export default defineComponent({
  name: "NavigationFooter",
});
</script>

<style lang="scss" scoped>
.navigation-footer {
  height: 80px;
}

.navigation-footer__wrapper {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: z("navigation") + 1;
  height: inherit;
  background: $white;
  box-shadow:
    0 -1px 0 0 #dcdde24c,
    0 -6px 6px 0 #00000006;
}

.navigation-footer__container {
  @include make-container-max-widths();

  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: repeat(3, auto);
  align-items: center;
  max-width: 1140px;
  height: inherit;
  margin: 0 auto;
}

.navigation-footer__left-container,
.navigation-footer__center-container,
.navigation-footer__right-container {
  display: flex;

  & > * {
    margin-right: 16px;
  }

  :deep(.el-button--link) {
    margin-top: 4px;
    margin-right: 15px;

    span div {
      font-size: 14px;
    }
  }

  :deep(.el-button--red) {
    padding: 0 16px;
    margin-top: 3px;

    span div {
      font-size: 13px;
    }
  }

  :deep(.el-button--green) {
    padding: 0 14px;
    margin-top: 3px;
    margin-right: 0;

    span div {
      font-size: 13px;
    }
  }
}

:slotted(.navigation-footer__main-cta) {
  width: 164px;

  :deep(.px-icon) {
    position: relative;
    top: 1px;
    left: 3px;
  }
}

.navigation-footer__center-container {
  justify-self: center;
}

.navigation-footer__right-container {
  justify-self: flex-end;
}
</style>
