import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "auth-base-signup__step signup-step-company" }
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = { class: "el-form__cta" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ElInput = _resolveComponent("ElInput")!
  const _component_ElFormItem = _resolveComponent("ElFormItem")!
  const _component_PxInputPlaces = _resolveComponent("PxInputPlaces")!
  const _component_PxIcon = _resolveComponent("PxIcon")!
  const _component_SectorsSelector = _resolveComponent("SectorsSelector")!
  const _component_PxButton = _resolveComponent("PxButton")!
  const _component_ElForm = _resolveComponent("ElForm")!
  const _component_PxPanel = _resolveComponent("PxPanel")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_PxPanel, { class: "px-panel--compact" }, {
      header: _withCtx(() => [
        _createElementVNode("h2", {
          class: "px-panel__title",
          innerHTML: _ctx.$t('authentication.signup.form.company')
        }, null, 8, _hoisted_2)
      ]),
      default: _withCtx(() => [
        _createVNode(_component_ElForm, {
          ref: "signupForm",
          model: _ctx.data,
          rules: _ctx.rules,
          class: "auth-base-signup__form",
          onValidate: _ctx.updateSubmitState
        }, {
          default: _withCtx(() => [
            _createVNode(_component_ElFormItem, {
              label: _ctx.$t('authentication.signup.form.fields.companyName'),
              prop: "company.name"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ElInput, {
                  modelValue: _ctx.data.company.name,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.data.company.name) = $event)),
                  placeholder: _ctx.$t('authentication.signup.form.fields.companyName')
                }, null, 8, ["modelValue", "placeholder"])
              ]),
              _: 1
            }, 8, ["label"]),
            _createVNode(_component_ElFormItem, {
              label: _ctx.$t('authentication.signup.form.fields.location'),
              prop: "company.location"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_PxInputPlaces, {
                  id: "companyInfoLocation",
                  modelValue: _ctx.data.company.location,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.data.company.location) = $event)),
                  placeholder: _ctx.$t('authentication.signup.form.fields.location'),
                  "use-geolocation": true
                }, null, 8, ["modelValue", "placeholder"])
              ]),
              _: 1
            }, 8, ["label"]),
            _createVNode(_component_ElFormItem, {
              label: _ctx.$t('authentication.signup.form.fields.website'),
              prop: "company.website"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ElInput, {
                  modelValue: _ctx.data.company.website,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.data.company.website) = $event)),
                  modelModifiers: { trim: true },
                  placeholder: 
              _ctx.$t('authentication.signup.form.fields.websitePlaceholder')
            
                }, {
                  prefix: _withCtx(() => [
                    _createVNode(_component_PxIcon, {
                      size: 24,
                      name: "website"
                    })
                  ]),
                  _: 1
                }, 8, ["modelValue", "placeholder"])
              ]),
              _: 1
            }, 8, ["label"]),
            _createVNode(_component_ElFormItem, { prop: "company.sectors" }, {
              default: _withCtx(() => [
                _createVNode(_component_SectorsSelector, {
                  modelValue: _ctx.data.company.sectors,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.data.company.sectors) = $event)),
                  onChange: _ctx.handleSectorsChange
                }, null, 8, ["modelValue", "onChange"])
              ]),
              _: 1
            }),
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_PxButton, {
                disabled: _ctx.stepDisabled,
                size: "medium",
                type: "primary",
                onClick: _ctx.goToNextStep
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t("authentication.signup.form.stepButton")), 1)
                ]),
                _: 1
              }, 8, ["disabled", "onClick"])
            ])
          ]),
          _: 1
        }, 8, ["model", "rules", "onValidate"])
      ]),
      _: 1
    })
  ]))
}