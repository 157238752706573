import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "match-details-table-legend" }
const _hoisted_2 = { class: "match-details-table-legend__headline" }
const _hoisted_3 = { class: "match-details-table-legend__wrapper" }
const _hoisted_4 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PxIcon = _resolveComponent("PxIcon")!
  const _component_ElCollapseItem = _resolveComponent("ElCollapseItem")!
  const _component_ElCollapse = _resolveComponent("ElCollapse")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ElCollapse, { accordion: true }, {
      default: _withCtx(() => [
        _createVNode(_component_ElCollapseItem, null, {
          title: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("span", null, _toDisplayString(_ctx.$t("matching.matchDetails.table.legend.title")), 1)
            ])
          ]),
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_3, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.legendCards, (card, index) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: index,
                  class: "match-details-table-legend__card"
                }, [
                  _createElementVNode("div", null, [
                    _createVNode(_component_PxIcon, {
                      name: card.icon,
                      size: 16
                    }, null, 8, ["name"]),
                    _createElementVNode("h3", null, _toDisplayString(card.title), 1)
                  ]),
                  _createElementVNode("p", null, _toDisplayString(card.description), 1),
                  (card.secondDescription)
                    ? (_openBlock(), _createElementBlock("p", _hoisted_4, _toDisplayString(card.secondDescription), 1))
                    : _createCommentVNode("", true)
                ]))
              }), 128))
            ])
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}