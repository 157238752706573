import { isDevelopment } from "./utils";

interface IFullStoryObject {
  name?: string;
  properties: { [key: string]: any };
  next?: Function;
}

const fullStoryGuard = ({
  name,
  properties = {},
  next = () => null,
}: IFullStoryObject) => {
  if (!(window as any).FS) {
    return;
  }

  if (isDevelopment) {
    console.log(`FullStory: Event "${name}" sent with =>`, properties);
  }

  return next({ name, properties });
};

/**
 * FullStory identify and register user data action
 * @param properties
 */
export const fullStoryLogin = (properties: { [key: string]: any } = {}) =>
  fullStoryGuard({
    name: "FullStory Login",
    properties,
    next: ({ properties: _props }: IFullStoryObject) => {
      const { userId, ...userProps } = _props;

      FS.identify(userId, userProps);
    },
  });

/**
 * FullStory anonymize action
 * @param properties
 */
export const fullStoryLogout = (properties: { [key: string]: any } = {}) =>
  fullStoryGuard({
    name: "FullStory Logout",
    properties,
    next: () => {
      FS.anonymize();
    },
  });
