<template>
  <div class="matching-list-desktop__wrapper">
    <div class="matching-list-desktop__container">
      <PxButton
        v-if="$user.isSupporter()"
        :icon="$options.static.editCriteriaButton.icon"
        :label="$t(`profile.supporter.criteria.button`)"
        :size="$options.static.editCriteriaButton.size"
        :type="$options.static.editCriteriaButton.type"
        class="matching-list-desktop__edit-criteria-btn"
        @click="onClickEditCriteria"
      />
      <ElTabs v-model="activeTab" type="card" @tab-click="clickTabHandler">
        <ElTabPane :lazy="true" :name="EMatchingListTabs.DISCOVER">
          <template #label>
            <PxIcon
              :name="matchingTabs.discover.icon"
              class="px-matching-interest__ctaIcon"
              size="16"
            />
            {{ matchingTabs.discover.label }}
          </template>
          <MatchingListCards />
        </ElTabPane>
        <ElTabPane
          v-if="$features.isEnabled(`matchingInterest`)"
          :lazy="true"
          :name="EMatchingListTabs.PENDING"
        >
          <template #label>
            <PxIcon
              :name="matchingTabs.pending.icon"
              class="px-matching-interest__ctaIcon"
              size="16"
            />
            {{ matchingTabs.pending.label }}
          </template>
          <MatchingListInterests @on-tab-changed="updateChildSelectedTab" />
        </ElTabPane>
        <ElTabPane
          v-if="$features.isEnabled(`matchingInterest`)"
          :lazy="true"
          :name="EMatchingListTabs.CONNECTED"
        >
          <template #label>
            <PxIcon
              :name="matchingTabs.connected.icon"
              class="px-matching-interest__ctaIcon"
              size="16"
            />
            {{ matchingTabs.connected.label }}
          </template>
          <MatchingListInterestMutual />
        </ElTabPane>
      </ElTabs>
    </div>
    <div v-show="showSidebar" class="matching-list-desktop__sidebar">
      <MatchingSidebar />
    </div>
    <ListManagementBar
      v-if="isSelectModeActive"
      :available-items="listManagementItems"
      @footer-visibility-changed="onFooterVisibilityChanged"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import MatchingListCards from "@/modules/matching/components/matching-list-cards/matching-list-cards.vue";
import MatchingListInterests from "@/modules/matching/components/matching-list-interests/matching-list-interests.vue";
import MatchingListInterestMutual from "@/modules/matching/components/matching-list-interests/matching-list-interests-mutual.vue";
import MatchingSidebar from "@/modules/matching/components/matching-sidebar/matching-sidebar.vue";
import ListManagementBar from "@/modules/company-lists/components/list-management/list-management-bar.vue";

import { EMetaActions } from "@/services/store/meta/meta-types";
import { MATCHING_SELECTED_TAB } from "@/modules/matching/constants";
import { IMatchingScore } from "@/modules/matching/services/data/matching-score/matching-score.interface";
import { ICompany } from "@/modules/profile/services/data/company/company.types";
import { AFFILIATE_SUPPORTER_PROGRAM_UPDATE_URL } from "@/modules/affiliates/constants";
import {
  EPxButtonSize,
  EPxButtonType,
} from "@/components/px-button/px-button.types";
import {
  EMatchingListTabs,
  EMatchingListPendingTabs,
} from "@/modules/matching/components/matching-list-desktop/matching-tabs.types";
import { RouteParams } from "vue-router";
import { ICompanyList } from "@/modules/company-lists/services/data/company-list/company-list.interface";

export default defineComponent({
  name: "MatchingListDesktop",

  components: {
    MatchingListCards,
    MatchingListInterests,
    MatchingListInterestMutual,
    MatchingSidebar,
    ListManagementBar,
  },

  static: {
    editCriteriaButton: {
      icon: "icon-gear--grey",
      size: EPxButtonSize.SMALL,
      type: EPxButtonType.GHOST_GREY,
    },
  },

  data() {
    return {
      EMatchingListTabs,
      activeTab: EMatchingListTabs.DISCOVER as string,
      childSelectedTab: EMatchingListPendingTabs.INTERESTED_IN_ME as string,
      isListManagementFooterVisible: false,
      routeParams: this.$route.params as RouteParams | null,
      matches: null as Array<IMatchingScore> | null,
    };
  },

  computed: {
    selectedTab(): string {
      return this.$store.get("meta/get", MATCHING_SELECTED_TAB);
    },

    matchingTabs(): any {
      return {
        [EMatchingListTabs.DISCOVER]: {
          label: this.$t(`matching.matchingList.tabs.discover`),
          icon: "icon-discover",
        },

        [EMatchingListTabs.PENDING]: {
          label: this.$t(`matching.matchingList.tabs.pending`),
          icon: "icon-pending",
        },

        [EMatchingListTabs.CONNECTED]: {
          label: this.$t(`matching.matchingList.tabs.connected`),
          icon: "icon-connected",
        },
      };
    },

    // Restrict lists to desktop
    showListFeature(): boolean {
      return this.$screen.lgUp;
    },

    isSelectModeActive(): boolean {
      return this.showListFeature;
    },

    showSidebar(): boolean {
      // Show sidebar only on desktop view, if select mode is de-activated
      return this.$screen.lgUp && !this.isListManagementFooterVisible;
    },

    selectedLists(): Array<ICompanyList> {
      return this.$store.get("listManagement.data.lists") || [];
    },

    /**
     * Get companies uids that are already in the selected list
     */
    listsCompaniesUid(): Array<string> {
      if (!this.selectedLists.length) {
        return [];
      }

      const list = this.selectedLists[0];
      const companies = list.allCompaniesCompacted || list.companies;

      return companies.map(({ uid }) => uid as string);
    },

    listManagementItems(): Array<string> {
      return this.filterListManagementItems();
    },
  },

  watch: {
    selectedTab: {
      immediate: true,
      handler(currentTab) {
        if (currentTab !== this.activeTab) {
          this.activeTab = currentTab;
        }
      },
    },
  },

  unmounted() {
    this.routeParams = null;
    this.matches = null;
  },

  methods: {
    matchScores() {
      switch (this.activeTab) {
        case `${EMatchingListTabs.DISCOVER}`: {
          this.matches = this.$store.state?.matching?.data || [];
          break;
        }

        case `${EMatchingListTabs.PENDING}`: {
          // Based on pending select sub tab
          this.matches =
            (this.childSelectedTab === EMatchingListPendingTabs.INTERESTED_IN_ME
              ? this.$store.state?.matchingInterest?.targetMatchScores
              : this.$store.state?.matchingInterest?.userMatchScores) || [];
          break;
        }

        case `${EMatchingListTabs.CONNECTED}`: {
          this.matches =
            this.$store.state?.matchingInterest?.mutualMatchScores || [];
          break;
        }

        default: {
          this.matches = [];
          break;
        }
      }

      return this.matches;
    },

    clickTabHandler() {
      this.$store.dispatch(EMetaActions.SET, {
        key: MATCHING_SELECTED_TAB,
        value: this.activeTab,
      });
    },

    mapListManagementItems(): Array<string> {
      const matchingLoadedData = this.matchScores() || [];

      // Match matching score to company ids
      return matchingLoadedData.reduce(
        (accumulator: Array<string>, score: IMatchingScore) => {
          const company = (
            score.supporter
              ? score.supporter.user_profile.company
              : score.company
          ) as ICompany;

          if (!company?.uid) {
            return accumulator;
          }

          return [...accumulator, company?.uid];
        },
        [],
      );
    },

    /**
     * Filter companies that are already in the selected list
     */
    filterListManagementItems(): Array<string> {
      return this.mapListManagementItems().filter(
        (item: string) => !this.listsCompaniesUid.includes(item),
      );
    },

    onFooterVisibilityChanged(state: boolean) {
      this.isListManagementFooterVisible = state;
    },

    updateChildSelectedTab(tab: string) {
      this.childSelectedTab = tab;
    },

    onClickEditCriteria() {
      window.open(AFFILIATE_SUPPORTER_PROGRAM_UPDATE_URL);
    },
  },
});
</script>

<style lang="scss" scoped>
.matching-list-desktop__wrapper {
  padding: 60px 0 0;
}

.matching-list-desktop__container {
  max-width: 580px;
  padding: 0;
  margin: 0 auto;

  @include breakpoint-up(md) {
    flex-basis: 847px;
  }

  @include breakpoint-up(lg) {
    position: relative;
    max-width: 860px;
  }

  @include breakpoint-up(xl) {
    max-width: 1140px;
  }
}

.matching-list-desktop__edit-criteria-btn :deep() {
  position: absolute;
  top: 0;
  right: 3px;
  z-index: z("floaters");

  span {
    font-size: 13px;
  }
}

.matching-list-desktop__container :deep() .el-tabs__item {
  padding: 11px 17px 18px 22px;
  letter-spacing: unset; /* This will trigger the right kerning */

  &:nth-child(2) {
    padding: 11px 22px 18px 29px;
  }

  &:nth-child(3) {
    padding: 11px 21px 18px 21px;
  }

  .px-icon {
    margin-right: 3px;
    vertical-align: -2px;
    transition: filter 0.3s;
  }

  &.is-active .px-icon,
  &:hover .px-icon {
    filter: brightness(25%);
  }
}

.matching-list-desktop__container :deep() .el-tabs--card > .el-tabs__content {
  @include breakpoint-up(lg) {
    min-height: 397px;
    padding: 25px 22px 40px;
  }
}

.matching-list-desktop__sidebar {
  position: fixed;
  right: 40px;
  bottom: 30px;
  z-index: z("loading") + 1;
  flex-basis: 264px;
}
</style>
