<template>
  <i18n-t
    :keypath="`matching.matchDetails.details`"
    class="match-details"
    tag="p"
  >
    <template #companyData>
      <PxAvatar
        :name="companyName"
        :photo="companyLogo"
        :size="24"
        class="match-details__company-avatar"
      />
      {{ companyName }}
    </template>
    <template #percentage>
      <span
        :class="{ 'match-details__percentage--grey': !hasMatch }"
        class="match-details__percentage"
      >
        {{ percentage }}
      </span>
    </template>
  </i18n-t>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "MatchDetails",

  props: {
    companyName: {
      type: String,
      required: true,
    },

    companyLogo: {
      type: String,
      default: "",
    },

    percentage: {
      type: String,
      required: true,
    },

    hasMatch: {
      type: Boolean,
      default: false,
    },
  },
});
</script>

<style lang="scss" scoped>
.match-details {
  @include grotesk(semiBold);

  max-width: 420px;
  font-size: 20px;
  line-height: 30px;
  color: #2a2a2a;
  letter-spacing: -0.2px;

  &__company-avatar {
    display: inline-block;
    top: 5px;
    left: 3px;
    margin-right: 6px;
  }

  &__percentage {
    @include grotesk(semiBold);

    margin-left: 1px;
    font-size: 22px;
    line-height: 1;
    color: $bluish;

    &--grey {
      color: $manatee;
    }
  }
}
</style>
