import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-96785728"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "list-management-modal-item__text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ElCheckbox = _resolveComponent("ElCheckbox")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([{ 'list-management-modal-item--selected': _ctx.innerSelected }, "list-management-modal-item"]),
    onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onItemClick && _ctx.onItemClick(...args)))
  }, [
    _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.value.title), 1),
    _createVNode(_component_ElCheckbox, {
      modelValue: _ctx.innerSelected,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.innerSelected) = $event)),
      class: "list-management-modal-item__checkbox",
      onChange: _ctx.onItemClick
    }, null, 8, ["modelValue", "onChange"])
  ], 2))
}