<template>
  <ElCollapseItem
    :class="{
      'collapsible-question--error': !hasValidResponse,
      'collapsible-question--success': successState,
    }"
    :name="question.id"
    class="collapsible-question"
  >
    <template #title>
      <div class="collapsible-question__wrapper">
        <div v-if="successState" class="collapsible-question__success-icon">
          <PxIcon :size="24" name="answered-icon" />
        </div>
        <div class="collapsible-question__text">
          <!-- Title -->
          <h4>
            {{ getQuestionTitle(question) }}
          </h4>
          <!-- Answer -->
          <p
            :class="{
              'collapsible-question__answer--italic': !responseFormattedValue,
            }"
            :title="responseToDisplay"
            class="collapsible-question__answer"
            data-testid="collapsible-question-response"
          >
            {{ responseToDisplay }}
          </p>
        </div>
        <PxButton
          v-if="question.instructions"
          class="collapsible-question__action"
          v-bind="learnMoreButtonProps"
          @click.stop="$emit('show-instructions-modal', question)"
        />
      </div>
    </template>
    <template #default>
      <!-- Question -->
      <div
        :class="{
          'collapsible-question__options--grouped': groupedOptions,
          'collapsible-question__options--ungrouped': !groupedOptions,
        }"
        class="collapsible-question__options"
      >
        <QuestionPanel
          v-if="question"
          v-model="currentResponse"
          :data-testid="`collapsible-question--${question.id}`"
          :fields-are-required="false"
          :question="question"
          :show-only-question-content="true"
          @response-formatted="handleFormattedResponse"
          @validate="checkValidState"
        />
      </div>
      <!-- Note -->
      <div
        v-if="isMultiSelectQuestion"
        class="collapsible-question__info"
        data-testid="collapsible-question-note"
      >
        <PxIcon :size="16" name="icon-information" />
        <p v-html="$t('common.multiSelectInfo')" />
      </div>
    </template>
  </ElCollapseItem>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import FormatMatchingResponseValuesMixin from "@/mixins/format-matching-response-values.mixin";

import { cloneDeep } from "lodash";

import QuestionPanel from "@/modules/affiliates/components/question-panel/question-panel.vue";

import { IMatchingResponse } from "@/services/data/matching-responses/matching-response.interface";
import {
  DATE,
  FREE_RESPONSE,
  IMatchingQuestion,
  MULTI_SELECT,
  NUMERIC,
  RANGE,
  SINGLE_SELECT,
} from "@/services/data/matching-questionary/matching-question.interface";
import { EPxButtonType } from "@/components/px-button/px-button.types";

export default defineComponent({
  name: "CollapsibleQuestion",

  components: {
    QuestionPanel,
  },

  mixins: [FormatMatchingResponseValuesMixin],

  props: {
    response: {
      type: Object as () => IMatchingResponse | null,
      default: null,
    },

    question: {
      type: Object as () => IMatchingQuestion,
      required: true,
    },

    showSuccessState: {
      type: Boolean,
      default: false,
    },

    // To display the radio and checkbox options in a single column, set groupedOptions to false.
    groupedOptions: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      responseTypes: {
        freeResponse: FREE_RESPONSE,
        singleSelect: SINGLE_SELECT,
        multiSelect: MULTI_SELECT,
        numeric: NUMERIC,
        range: RANGE,
        dateResponse: DATE,
      } as any | null,
      currentResponse: {} as IMatchingResponse,
      hasValidResponse: true,
    };
  },

  computed: {
    isMultiSelectQuestion(): boolean {
      return (
        this.question.question_type.type === this.responseTypes.multiSelect
      );
    },

    responseFormattedValue(): string {
      if (!this.response) return "";

      let formattedValue = "";

      if (
        (this.getQuestionType(this.responseTypes.numeric) &&
          this.$user.isEntrepreneur()) ||
        (this.getQuestionType(this.responseTypes.range) &&
          this.$user.isSupporter())
      ) {
        formattedValue = this.formatNumericValue(this.response);
      } else if (
        (this.getQuestionType(this.responseTypes.numeric) &&
          this.$user.isSupporter()) ||
        (this.getQuestionType(this.responseTypes.range) &&
          this.$user.isEntrepreneur())
      ) {
        formattedValue = this.formatRangeValue(this.response);
      } else if (this.getQuestionType(this.responseTypes.freeResponse)) {
        formattedValue = this.formatFreeResponseValue(this.response);
      } else if (this.getQuestionType(this.responseTypes.dateResponse)) {
        formattedValue = this.formatDateValue(this.response);
      } else if (
        this.getQuestionType(this.responseTypes.singleSelect) ||
        this.getQuestionType(this.responseTypes.multiSelect)
      ) {
        formattedValue =
          this.currentResponse?.answers && this.currentResponse?.answers.length
            ? this.formatMultipleAnswersValue(this.question, this.response)
            : "";
      }

      return formattedValue;
    },

    responseToDisplay(): string {
      return this.responseFormattedValue
        ? this.responseFormattedValue
        : this.$t("common.notAnswered");
    },

    successState(): boolean {
      return (
        this.showSuccessState &&
        this.hasValidResponse &&
        !!this.responseFormattedValue.length
      );
    },

    learnMoreButtonProps(): object {
      return {
        type: EPxButtonType.LINK,
        label: this.$t("common.learnMore"),
      };
    },
  },

  watch: {
    response: {
      immediate: true,
      deep: true,
      handler() {
        if (!this.response) return;

        this.currentResponse = cloneDeep(this.response);
      },
    },
  },

  beforeUnmount() {
    this.responseTypes = null;
    this.currentResponse = {} as IMatchingResponse;
  },

  methods: {
    getQuestionTitle(question: IMatchingQuestion): string {
      return this.$user.isEntrepreneur()
        ? question.entrepreneur_question
        : question.resource_question;
    },

    getQuestionType(type: string): boolean {
      return (
        this.question.question_type && this.question.question_type.type === type
      );
    },

    handleFormattedResponse(questionId: number) {
      this.$emit("response-formatted", questionId);
    },

    checkValidState(isValid: boolean) {
      this.hasValidResponse = isValid;

      this.$emit(
        "handle-new-response",
        this.hasValidResponse,
        this.currentResponse,
      );
    },
  },
});
</script>

<style lang="scss" scoped>
$collapse-border-radius: 4px;

.collapsible-question {
  &:not(:last-of-type) {
    margin-bottom: 17px;
  }

  border: 1px solid $mischka;
  border-radius: $collapse-border-radius;
  background: $white;
  padding-right: 15px;

  transition: all 0.2s ease-in-out;

  &__wrapper {
    min-width: 0;
    display: flex;
    justify-content: space-between;
    gap: 10px;
    flex: 1;
  }

  &.is-active .collapsible-question__wrapper {
    flex-direction: column;
    align-items: flex-start;
    gap: 3px;
  }

  &__text {
    max-width: 100%;
    min-width: 0;
    flex: 1;
  }

  &__text h4 {
    @include grotesk(semiBold);

    margin: 0;
    font-size: 17px;
    color: #334155;
  }

  &__answer {
    @include grotesk(semiBold);
    max-width: 100%;

    font-size: 14px;
    color: $manatee;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__answer--italic {
    @include grotesk(regular, italic);
    text-transform: none;
  }

  &__info {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 6px;
    margin-top: 18px;

    p {
      margin-top: 3px;
      font-size: 13px;
      color: $ebony-clay;
      font-style: italic;
    }
  }

  &__options :deep() {
    .question-panel__container {
      width: 100%;

      background-color: transparent;
      border: none;
      box-shadow: none;
    }

    .question-panel__content {
      padding: 0;
    }

    .question-panel__content-wrapper {
      width: 100%;
    }

    .question-panel__answer {
      max-width: 331px;
    }

    .el-radio,
    .el-checkbox {
      letter-spacing: -0.2px;
    }

    .el-radio__input,
    .el-checkbox__input {
      width: 22px;
      height: 22px;
    }
  }

  &__options--grouped :deep() {
    .el-radio-group,
    .el-checkbox-group {
      grid-template-columns: auto auto;
      gap: 10px 17px;
    }
  }

  &__options--ungrouped :deep() {
    .question-panel__content-wrapper {
      grid-template-columns: auto;
      gap: 10px 0;
    }
  }

  &--error,
  &.is-active.collapsible-question--error {
    border: 1px solid $wild-watermelon;
    background: #f6e8e9;
  }

  &--success {
    border: 1px solid $fern;
  }

  &.is-active {
    border: 1px solid $malibu;
    border-radius: $collapse-border-radius;
    background: #eff6ff;
  }

  &__action {
    padding: 0;
    min-width: auto;
    height: auto;

    :deep(span) {
      letter-spacing: -0.7px;
    }
  }

  &.is-active .collapsible-question__success-icon,
  &.is-active .collapsible-question__answer,
  &:not(.is-active) .collapsible-question__action {
    display: none;
  }

  :deep(div[role="tabpanel"]) {
    margin: 0 13px;
  }

  :deep(.el-collapse-item__header) {
    padding: 15px 23px 11px;
    border: none;
    display: flex;
    justify-content: space-between;
    text-align: left;
    border-radius: $collapse-border-radius;

    &.is-active {
      bottom: 1px;
      min-height: 60px;
    }

    &.is-active h4 {
      padding: 16px 0;
    }

    &.is-active::before {
      transform: rotate(180deg);
      bottom: 7px;
    }

    &::before {
      top: 0;
      right: 0;
      bottom: 0;
      width: 16px;
      height: 16px;
      margin: auto 0;
      background-color: transparent;
      background-image: url("#{$assetsPath}/img/icons/arrow.svg");
      background-repeat: no-repeat;
      background-position: center;
      transition: $--all-transition;
    }

    &::after {
      display: none;
    }
  }

  :deep(.el-collapse-item__header),
  :deep(.el-collapse-item__wrap) {
    background: transparent;
    border: 0;
  }

  :deep(.el-collapse-item__content) {
    padding: 0 0 20px 10px;
  }
}
</style>
