import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-81f0f0da"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "overlay-confirm" }
const _hoisted_2 = { class: "text-center" }
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = { class: "overlay-confirm__buttons" }
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PxButton = _resolveComponent("PxButton")!

  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass([{ 'is-fixed': _ctx.fixedOnScroll }, "overlay-confirm__content"])
    }, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("h2", {
          innerHTML: _ctx.options.message
        }, null, 8, _hoisted_3),
        (_ctx.options.tip)
          ? (_openBlock(), _createElementBlock("p", {
              key: 0,
              innerHTML: _ctx.options.tip
            }, null, 8, _hoisted_4))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_PxButton, {
            class: _normalizeClass(_ctx.options.cancelButtonClass),
            disabled: _ctx.isButtonsDisabled,
            type: "link",
            onClick: _ctx.onClickCancel
          }, {
            default: _withCtx(() => [
              _createElementVNode("span", { innerHTML: _ctx.dialogCancelButtonText }, null, 8, _hoisted_6)
            ]),
            _: 1
          }, 8, ["class", "disabled", "onClick"]),
          _createVNode(_component_PxButton, {
            class: _normalizeClass(_ctx.options.confirmButtonClass),
            disabled: _ctx.isButtonsDisabled,
            type: "red",
            onClick: _ctx.onClickConfirm
          }, {
            default: _withCtx(() => [
              _createElementVNode("span", { innerHTML: _ctx.dialogConfirmButtonText }, null, 8, _hoisted_7)
            ]),
            _: 1
          }, 8, ["class", "disabled", "onClick"])
        ])
      ])
    ], 2)
  ], 512)), [
    [_vShow, _ctx.visible]
  ])
}