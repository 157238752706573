import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-9ca540d6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "px-questionary-range" }
const _hoisted_2 = { class: "px-questionary-range__field-wrapper" }
const _hoisted_3 = { class: "px-questionary-range__label" }
const _hoisted_4 = { class: "px-questionary-range__field-wrapper" }
const _hoisted_5 = { class: "px-questionary-range__label" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PxNumeric = _resolveComponent("PxNumeric")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.$t("common.from")), 1),
      _createVNode(_component_PxNumeric, {
        modelValue: _ctx.values.min,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.values.min) = $event))
      }, null, 8, ["modelValue"])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.$t("common.to")), 1),
      _createVNode(_component_PxNumeric, {
        modelValue: _ctx.values.max,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.values.max) = $event))
      }, null, 8, ["modelValue"])
    ])
  ]))
}