import { IUserGuest } from "@/services/data/user-guest/user-guest.interface";
import { userGuestProvider } from "@/services/data/user-guest/user-guest.provider";
import { RootState } from "@/services/store/root-state";
import {
  EUserGuestActions,
  EUserGuestGetters,
  EUserGuestMutations,
  IUserGuestState,
} from "@/services/store/user-guest/user-guest.types";
import { getBaseName } from "@/services/store/utils/get-base-name";
import { ActionTree, GetterTree, Module, MutationTree } from "vuex";

/**
 * Initial module state.
 */
const state: IUserGuestState = {
  userGuest: null,
};

const getters: GetterTree<IUserGuestState, RootState> = {
  [getBaseName(EUserGuestGetters.GET)](state) {
    return state.userGuest;
  },
};

const mutations: MutationTree<IUserGuestState> = {
  [getBaseName(EUserGuestMutations.SET)](state, payload: IUserGuest) {
    state.userGuest = payload;
  },

  [getBaseName(EUserGuestMutations.RESET)](state) {
    state.userGuest = null;
  },
};

const actions: ActionTree<IUserGuestState, RootState> = {
  async [getBaseName(EUserGuestActions.CREATE)](
    { commit },
    payload: IUserGuest,
  ) {
    // TODO: implement error and loading logic

    const userGuest = await userGuestProvider.create(payload);

    commit(EUserGuestMutations.SET, userGuest);

    return userGuest;
  },

  /**
   * Add a new passcode to the current user store.
   */
  [getBaseName(EUserGuestActions.SET)]({ commit }, payload: IUserGuest) {
    commit(EUserGuestMutations.SET, payload);
  },

  /**
   * Reset store by removing all passcodes, this must be done when login-out someone.
   */
  [getBaseName(EUserGuestActions.RESET)]({ commit }) {
    commit(EUserGuestMutations.RESET);
  },
};

/**
 * List passcode store module.
 *
 * TODO: remove user guest data when performing a login
 */
export const userGuestModule: Module<IUserGuestState, RootState> = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
