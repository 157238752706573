import { Module } from "vuex";
import { make } from "vuex-pathify";

import { IUserMetadataState } from "./user-metadata.types";
import { actions } from "./user-metadata.actions";
import { RootState } from "@/services/store/root-state";
import { mutations } from "./user-metadata.mutations";
import { getters } from "./user-metadata.getters";

const state: IUserMetadataState = {
  data: [],
  error: null,
  loading: false,
};

export const userMetadataModule: Module<IUserMetadataState, RootState> = {
  namespaced: true,
  state,
  mutations: {
    ...make.mutations(state),
    ...mutations,
  },
  actions,
  getters,
};
