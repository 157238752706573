/**
 * Supporters' registration
 */
export const SUPPORTERS_STARTING_POINT = "supportersStartingPoint";
export const SUPPORTERS_ASSESSMENT = "supportersAssessment";
export const SUPPORTERS_PASSWORD = "supportersPassword";
export const SUPPORTERS_INFO = "supportersInfo";
export const SUPPORTERS_CRITERIA_STARTING_POINT =
  "supportersCriteriaStartingPoint";
export const SUPPORTERS_CRITERIA_ASSESSMENT = "supportersCriteriaAssessment";
export const SUPPORTERS_CRITERIA_QUESTIONS = "supportersCriteriaQuestions";
export const SUPPORTERS_CRITERIA_BASIC_INTERESTS =
  "supportersCriteriaBasicInterests";
export const SUPPORTERS_CRITERIA_ADDITIONAL_INTERESTS =
  "supportersCriteriaAdditionalInterests";
export const SUPPORTERS_CRITERIA_IMPORTANCE = "supportersCriteriaImportance";
export const SUPPORTERS_RESULTS = "supportersResults";

/**
 * Supporters' account management
 */
export const SUPPORTERS_AUTHENTICATION_SIGNUP = "supportersSignUp";
export const SUPPORTERS_AUTHENTICATION_SIGNUP_SUCCESS =
  "supportersSignUpSuccess";
export const SUPPORTERS_AUTHENTICATION_LOGIN = "supportersLogin";
export const SUPPORTERS_AUTHENTICATION_PASSWORD_RECOVER =
  "supportersPasswordRecover";
export const SUPPORTERS_AUTHENTICATION_PASSWORD_RESET =
  "supportersPasswordReset";
export const SUPPORTERS_AUTHENTICATION_VERIFY_ACCOUNT =
  "supportersVerifyAccount";

/**
 * Supporters' affiliate program
 */
export const SUPPORTERS_STARTING_POINT_PROGRAM =
  "supportersStartingPointProgram";
export const SUPPORTERS_CRITERIA_ASSESSMENT_PROGRAM =
  "supportersCriteriaAssessmentProgram";
export const SUPPORTERS_CRITERIA_QUESTIONS_PROGRAM =
  "supportersCriteriaQuestionsProgram";
export const SUPPORTERS_TEAM_MANAGEMENT_PROGRAM =
  "supportersTeamManagementProgram";
export const SUPPORTERS_CRITERIA_BASIC_INTERESTS_PROGRAM =
  "supportersCriteriaBasicInterestsProgram";
export const SUPPORTERS_CRITERIA_ADDITIONAL_INTERESTS_PROGRAM =
  "supportersCriteriaAdditionalInterestsProgram";
export const SUPPORTERS_CRITERIA_IMPORTANCE_PROGRAM =
  "supportersCriteriaImportanceProgram";
export const SUPPORTERS_PROGRAM_RESULTS = "supportersProgramResults";
export const SUPPORTERS_INFO_PROGRAM = "supportersInfoProgram";
export const SUPPORTERS_PASSWORD_PROGRAM = "supportersPasswordProgram";
