<template>
  <div class="list-share-settings-link">
    <ElForm ref="form" class="list-share-settings-link__form">
      <ElFormItem
        :label="shareModalLinkCopy.label"
        class="list-share-settings-link__input-wrapper"
        prop="email"
      >
        <ElInput :disabled="true" :value="value" data-testid="list-link" />
        <ClipboardButton v-bind="clipboardButton" />
      </ElFormItem>
      <div class="list-share-settings-link__footer">
        <span
          class="el-form-item__bottom-text el-form-item__tip"
          v-html="shareModalLinkCopy.tip"
        />
        <PxButton
          :icon="$options.static.resetButton.icon"
          :label="shareModalLinkCopy.resetButton"
          :size="$options.static.resetButton.size"
          :type="$options.static.resetButton.type"
          class="list-share-settings-link__cta"
          @click="clickResetLinkHandler"
        />
      </div>
    </ElForm>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import ClipboardButton from "@/components/clipboard-button/clipboard-button.vue";
import {
  EPxButtonSize,
  EPxButtonType,
} from "@/components/px-button/px-button.types";

import { LIST_SHARE_EVENTS } from "@/modules/company-lists/components/list-share/list-share-modal.vue";

export default defineComponent({
  name: "ListShareSettingsLink",

  components: {
    ClipboardButton,
  },

  props: {
    value: {
      type: String,
      required: true,
    },
  },

  static: {
    resetButton: {
      icon: "link--red",
      size: EPxButtonSize.SMALL,
      type: EPxButtonType.GHOST_RED,
    },
  },

  computed: {
    shareModalLinkCopy() {
      return this.$tm("companyLists.list.shareModal.settings.link") as {
        label: string;
        tip: string;
        resetButton: string;
      };
    },

    clipboardButton() {
      return {
        clipboard: this.value,
        buttonLabel: this.$t("common.copyLink"),
        tooltipCopy: this.$t("common.copiedToClipboard"),
      };
    },
  },

  methods: {
    clickResetLinkHandler() {
      this.$emitter.emit("list-share-modal-confirmation", {
        event: LIST_SHARE_EVENTS.RESET_SHAREABLE_LINK,
      });
    },
  },
});
</script>

<style lang="scss" scoped>
.list-share-settings-link__input-wrapper {
  position: relative;

  :deep(.el-form-item__label) {
    padding-top: 0;
  }

  :deep(.el-input.is-disabled .el-input__inner) {
    opacity: 1;
    // Add safe space for CTA overlapping input:
    padding-right: 130px;
    background-color: $alabaster;
    border: solid 1px $mischka;
    box-shadow: inset 0 0 5px 0 rgba(#000, 0.03);
  }
}

.list-share-settings-link :deep(.clipboard-button) {
  position: absolute;
  right: 4px;
  bottom: 4px;
}

.list-share-settings-link__footer {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  margin-top: 16px;

  .el-form-item__tip {
    flex: 1 1 50%;
    max-width: 320px;
    padding-top: 0;
  }
}
</style>
