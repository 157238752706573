<template>
  <div class="share-planner-users-index">
    <div v-if="users.length" class="share-planner-users-index__wrapper">
      <div
        v-for="(user, index) in users"
        :key="index"
        class="share-planner-users-index__item"
      >
        <PxAvatar
          :name="user.name"
          :photo="user.photo"
          :size="32"
          class="share-planner-users-index__item-photo"
          styling="neutral"
          type="round"
        />
        <div class="share-planner-users-index__item-content">
          <h3 class="share-planner-users-index__content-title">
            {{ user.name }}
          </h3>
          <p
            v-if="user.email"
            class="share-planner-users-index__content-subtitle"
          >
            {{ user.email }}
          </p>
        </div>
        <ElTag
          v-if="!user.user_profile || user.is_form_owner"
          class="share-planner-users-index__tag"
          effect="light"
          size="small"
        >
          {{ tagCopy(user) }}
        </ElTag>
        <ElTooltip
          :enterable="false"
          placement="top"
          popper-class="share-planner-users-index__tooltip share-planner-users-index__tooltip--spacious el-abaca-tooltip"
        >
          <template #content>
            <div v-text="usersCopy.tip" />
          </template>
          <PxIcon
            :size="17"
            class="share-planner-users-index__tip"
            name="question"
          />
        </ElTooltip>
        <div class="share-planner-users-index__item-actions">
          <div
            :class="{
              'is-disabled': !user.is_form_owner,
            }"
            class="share-planner-users-index__unshare-cta"
            @click.stop="unshareClickHandler(user)"
          >
            <PxIcon :size="14" name="trash--red" />
            <PxIcon :size="14" class="px-icon--hover" name="trash" />
          </div>
        </div>
      </div>
    </div>
    <div v-else class="share-planner-users-index__empty">
      <picture class="share-planner-users-index__empty-illustration">
        <img
          alt="Users illustration"
          src="/img/icons/lists-users-empty-state.svg"
        />
      </picture>
      <p class="share-planner-users-index__empty-title">
        {{ usersCopy.empty }}
      </p>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { TranslateResult } from "vue-i18n";
import { IMilestonePlannerInvitedUser } from "@/modules/milestone-planner/services/store/milestone-planner/milestone-planner.types";
import { SHARE_PLANNER_EVENTS } from "@/modules/milestone-planner/components/share-planner/share-planner-modal.types";
import { ICompanyListInvitedGuest } from "@/modules/company-lists/services/data/company-list/company-list.interface";

export default defineComponent({
  name: "SharePlannerUsersIndex",

  props: {
    users: {
      type: Array as () => Array<
        IMilestonePlannerInvitedUser | ICompanyListInvitedGuest
      >,
      required: true,
    },
  },

  computed: {
    usersCopy() {
      return this.$tm("milestonePlanner.shareModal.users") as {
        title: string;
        description: string;
        tip: string;
        formOwner: string;
        empty: string;
      };
    },
  },

  methods: {
    tagCopy(user: IMilestonePlannerInvitedUser): TranslateResult {
      return !user.user_profile
        ? this.$t("common.guest")
        : this.$t("common.formOwner");
    },

    tooltipCopy(user: IMilestonePlannerInvitedUser): TranslateResult {
      return user.is_form_owner
        ? this.$t("milestonePlanner.shareModal.users.formOwner")
        : this.$t("milestonePlanner.shareModal.users.tip");
    },

    unshareClickHandler(user: IMilestonePlannerInvitedUser) {
      this.$emitter.emit("share-planner-modal-confirmation", {
        event: SHARE_PLANNER_EVENTS.UNSHARE_FROM_USER,
        payload: { user },
      });
    },
  },
});
</script>

<style lang="scss">
.share-planner-users-index {
  &__wrapper {
    max-width: 602px;
    padding-top: 5px;
  }

  &__item {
    display: flex;
    flex-flow: row wrap;
    align-items: flex-start;
    padding: 16px 0 14px;

    &:not(:first-child) {
      border-top: solid 1px $athens-gray;
    }
  }

  .px-avatar.share-planner-users-index__item-photo {
    user-select: none;
    box-shadow: inset 0 0 3px 0 rgba(139, 143, 161, 0.3);

    .px-avatar__letter {
      font-size: 16px;
      line-height: 1.9;
      text-indent: 1px;
    }
  }

  &__item-content {
    flex: 1 1 auto;

    &:not(:first-child) {
      margin-left: 16px;
    }
  }

  &__item-actions {
    margin-right: 1px;
    margin-left: auto;
  }

  &__content-title {
    @include grotesk(regular);

    margin-top: -2px;
    margin-bottom: 0;
    font-size: 14px;
    line-height: 20px;
    color: $ebony-clay;
    letter-spacing: -0.35px;

    &:only-child {
      padding-top: 8px;
    }
  }

  &__content-subtitle {
    @include grotesk(regular);

    margin-bottom: 0;
    font-size: 13px;
    line-height: 16px;
    color: $manatee;
    letter-spacing: -0.35px;
  }

  &__tag {
    margin-top: 8px;
    margin-right: 60px;
    margin-left: auto;

    span {
      font-size: 12px;
      font-weight: bold;
      color: $manatee;
      text-transform: uppercase;
    }
  }

  &__tip {
    margin-top: 8px;
    margin-right: 11px;
    margin-left: auto;
    transition: filter 200ms cubic-bezier(0.23, 1, 0.32, 1);

    &:hover {
      filter: brightness(0.5) hue-rotate(380deg);
    }
  }

  &__unshare-cta {
    position: relative;
    width: 32px;
    height: 32px;
    cursor: pointer;
    user-select: none;
    border: solid 1px rgba($wild-watermelon, 0.2);
    border-radius: 3px;

    &::before {
      position: absolute;
      top: -1px;
      left: -1px;
      width: calc(100% + 2px);
      height: calc(100% + 2px);
      content: "";
      background-color: $wild-watermelon;
      border: inset 1px rgba($ebony-clay, 0.2);
      border-radius: 3px;
      opacity: 0;
      transition: $--fade-transition;
    }

    .px-icon {
      position: absolute;
      top: 50%;
      left: 50%;
      vertical-align: top;
      transform: translate(-50%, -50%);
    }

    .px-icon--hover {
      filter: brightness(2);
      opacity: 0;
      transition: $--fade-transition;
    }

    &:hover {
      &::before {
        opacity: 1;
      }

      .px-icon--hover {
        opacity: 1;
      }
    }

    &.is-disabled {
      pointer-events: none;
      opacity: 0.5;
    }
  }

  &__empty {
    display: flex;
    flex-flow: column wrap;
    align-items: center;
    justify-content: center;
    height: 100%;
    // Pixel-perfect:
    padding-top: 31px;
    padding-bottom: 70px;
    user-select: none;
  }

  &__empty-illustration {
    margin-right: 17px;
    margin-bottom: 11px;

    img {
      height: 93px;
    }
  }

  &__empty-title {
    @include grotesk(medium);

    position: relative;
    top: 1px;
    margin-bottom: 0;
    font-size: 15px;
    line-height: normal;
    color: $manatee;
    letter-spacing: -0.25px;
  }
}
</style>

<style lang="scss">
.share-planner-users-index__tooltip {
  max-width: 278px;
  padding: 5px 10px;
  margin-top: 3px;
  text-transform: none;
  letter-spacing: -0.3px;
}

.share-planner-users-index__tooltip--spacious {
  padding: 10px 15px;
  letter-spacing: -0.2px;
}
</style>
