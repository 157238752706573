<template>
  <div class="capital-explorer-for-visitors">
    <AuthenticatedWithNavigationView
      v-if="hasRetrievedData"
      data-testid="capital-explorer-visitors-navigation-view"
    />
    <VisitorsGuard
      v-else
      :requires-passcode="requiresPasscode"
      data-testid="capital-explorer-for-visitors-access-guard"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import AuthenticatedWithNavigationView from "@/modules/common/views/authenticated-with-navigation.view.vue";
import VisitorsGuard from "@/modules/capital-explorer/components/visitors-guard/visitors-guard.vue";

import { ICapitalExplorerVisitorData } from "@/modules/capital-explorer/services/data/capital-explorer/capital-explorer.interface";
import { AxiosError } from "axios";

export default defineComponent({
  name: "CapitalExplorerForVisitorsView",

  components: {
    AuthenticatedWithNavigationView,
    VisitorsGuard,
  },

  computed: {
    hasRetrievedData(): boolean {
      return !!this.capitalExplorerData;
    },

    capitalExplorerData(): ICapitalExplorerVisitorData | null {
      return this.$store.get("capitalExplorer/visitorData") || null;
    },

    capitalExplorerErrors(): null | AxiosError {
      return this.$store.get("capitalExplorer.error");
    },

    capitalExplorerErrorCode(): undefined | number {
      return this.capitalExplorerErrors?.response?.status;
    },

    requiresPasscode(): boolean {
      return (
        this.capitalExplorerErrorCode === 403 &&
        this.capitalExplorerErrors?.response?.data.errors.code.includes(
          "passcode",
        )
      );
    },
  },
});
</script>

<style lang="scss" scoped>
.capital-explorer-for-visitors {
  display: flex;
  flex-direction: column;
  flex: 1;
}
</style>
