import { generateBaseMutationsFor } from "../utils/base-generators";
import { ILevelState } from "./levels-types";
import { ILevel } from "@/services/data/level/level.interface";

/**
 * Mutations for the levels module.
 */
export const mutations = generateBaseMutationsFor<ILevelState, ILevel>(
  "levels",
);
