import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-4f6ce481"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "submission-panel__notice-title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PxIcon = _resolveComponent("PxIcon")!
  const _component_i18n_t = _resolveComponent("i18n-t")!
  const _component_PxButton = _resolveComponent("PxButton")!
  const _component_PxNoticeBar = _resolveComponent("PxNoticeBar")!

  return (_openBlock(), _createBlock(_component_PxNoticeBar, {
    class: "submission-panel__notice",
    type: "info"
  }, {
    content: _withCtx(() => [
      _createElementVNode("h2", _hoisted_1, [
        _createVNode(_component_PxIcon, {
          name: "check--white",
          size: "16"
        }),
        _createTextVNode(" " + _toDisplayString(_ctx.$t(`supporters.results.submissionPanel.notice.title`)), 1)
      ]),
      _createVNode(_component_i18n_t, {
        class: "submission-panel__notice-subtitle",
        keypath: "supporters.results.submissionPanel.notice.subtitle",
        tag: "h4"
      }, {
        name: _withCtx(() => [
          _createElementVNode("span", null, _toDisplayString(_ctx.affiliateShortcode), 1)
        ]),
        _: 1
      })
    ]),
    cta: _withCtx(() => [
      _createVNode(_component_PxButton, {
        class: "submission-panel__notice-link el-button--link-gray",
        type: "link",
        onClick: _ctx.goToProfile
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t("supporters.results.submissionPanel.notice.cta")), 1)
        ]),
        _: 1
      }, 8, ["onClick"])
    ]),
    _: 1
  }))
}