<template>
  <div class="self-assessment-starting-page">
    <PxPanel class="self-assessment-starting-page-panel">
      <h1 class="self-assessment-starting-page-panel__title">
        {{ startingPageTitle }}
      </h1>
      <p class="self-assessment-starting-page-panel__content">
        {{ startingPageContent }}
      </p>
      <p class="self-assessment-starting-page-panel__content">
        {{ startingPageContentTwo }}
      </p>
      <PxButton
        :icon="startButtonIcon"
        :type="startButtonType"
        class="self-assessment-starting-page-panel__button"
        data-testid="self-assessment-starting-page-panel__start-button"
        size="large"
        @click="onSelfAssessmentButtonClick"
      >
        {{ startButtonText }}
      </PxButton>
    </PxPanel>
    <div class="self-assessment-starting-page__feature">
      <template v-for="item in startingPageKeyPoints" :key="item.value">
        <FeatureCard
          :description="item.description"
          :icon="item.value"
          :title="item.title"
        />
      </template>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import FeatureCard from "@/modules/affiliates/components/feature-card/feature-card.vue";

import startingPagesMixin from "@/modules/affiliates/mixins/starting-pages.mixin";

import { getAppPartner, isPartner } from "@/services/utils/utils";
import { VUE_APP_PARTNER_ESO } from "@/constants/environments";

export default defineComponent({
  name: "SelfAssessmentStartingPage",

  components: {
    FeatureCard,
  },

  mixins: [startingPagesMixin],

  computed: {
    startButtonText() {
      return this.hasError
        ? this.$t("selfAssessment.startingPoint.panel.button.error")
        : this.submissionInProgress
          ? this.$t("selfAssessment.startingPoint.panel.button.resume")
          : this.$t("selfAssessment.startingPoint.panel.button.normal");
    },

    startingPageKeyPoints() {
      return (
        this.isEsoPartner
          ? this.$tm("selfAssessment.startingPoint.esoPartnerKeyPoints")
          : this.$tm("selfAssessment.startingPoint.keyPoints")
      ) as {
        value: string;
        title: string;
        description: string;
      }[];
    },

    isEsoPartner(): boolean {
      return isPartner && getAppPartner() === VUE_APP_PARTNER_ESO;
    },

    startingPageTitle() {
      return this.isEsoPartner
        ? this.$t("selfAssessment.startingPoint.esoPartnerPanel.title")
        : this.$t("selfAssessment.startingPoint.panel.title");
    },

    startingPageContent() {
      return this.isEsoPartner
        ? this.$t("selfAssessment.startingPoint.esoPartnerPanel.content")
        : this.$t("selfAssessment.startingPoint.panel.content");
    },

    startingPageContentTwo() {
      return this.isEsoPartner
        ? this.$t("selfAssessment.startingPoint.esoPartnerPanel.contentTwo")
        : "";
    },
  },

  methods: {
    onSelfAssessmentButtonClick() {
      this.$emit("next-step");
    },
  },
});
</script>

<style lang="scss" scoped>
.self-assessment-starting-page {
  @include container;

  padding: 40px 0 0;

  @include breakpoint-up(sm) {
    padding: 89px 0 0;
  }

  @include breakpoint-up(lg) {
    padding: 0;
    max-width: 811px;
  }
}

.self-assessment-starting-page__feature {
  @include container;

  padding: 34px 12px 28px;

  @include breakpoint-up(md) {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 53px 33px;
  }

  & > .feature-card:not(:last-child) {
    margin-bottom: 36px;

    @include breakpoint-up(md) {
      margin-bottom: 0;
    }
  }
}

.self-assessment-starting-page .self-assessment-starting-page-panel {
  width: 100%;
  text-align: center;

  @include breakpoint-only(xs) {
    border-right: none;
    border-left: none;
  }

  &__title {
    @include grotesk(semiBold);

    padding-top: 7px;
    margin-bottom: 26px;

    font-size: 2rem;
    line-height: 40px;

    @include breakpoint-down(sm) {
      padding: 0;

      font-size: 1.4667rem;
      line-height: 32px;
    }
  }

  &__content {
    max-width: 603px;
    margin: 0 auto 30px;

    font-size: 1.1333rem;
    line-height: 32px;
    letter-spacing: -0.2px;

    @include breakpoint-down(sm) {
      font-size: 1rem;
      line-height: 25px;
      letter-spacing: 0;
    }
  }

  &__button {
    min-width: 295px;
    margin-bottom: 8px;
  }
}
</style>
