<template>
  <div class="px-questionary__wrapper">
    <PxQuestionaryPlaceholder v-if="loading" />
    <template v-else>
      <div v-if="!finishedQuestionary" class="px-questionary__content">
        <div class="px-questionary__question-list">
          <div class="px-questionary__question-item">
            <h4
              v-if="$user.isEntrepreneur()"
              class="px-questionary__question-title"
            >
              {{ question.entrepreneur_question }}
            </h4>
            <h4
              v-if="$user.isSupporter()"
              class="px-questionary__question-title"
            >
              {{ question.resource_question }}
            </h4>
            <PxQuestionarySelect
              v-if="isSelectType"
              v-model="selectedOptions"
              v-model:is-valid="isFieldValid"
              :multiple="isMultiSelect"
              :options="question.answers"
            />
            <template v-if="isMoneyType">
              <PxQuestionaryCurrencyRange
                v-if="needsCurrencyRange"
                v-model="selectedValue"
                v-model:is-valid="isFieldValid"
              />
              <PxQuestionaryCurrency
                v-else
                v-model="selectedValue"
                v-model:is-valid="isFieldValid"
              />
            </template>
            <template v-else-if="isNumeric">
              <PxQuestionaryNumeric
                v-if="$user.isEntrepreneur()"
                v-model="selectedValue"
                v-model:is-valid="isFieldValid"
              />
              <PxQuestionaryRange
                v-if="$user.isSupporter()"
                v-model="selectedValue"
                v-model:is-valid="isFieldValid"
              />
            </template>
          </div>
        </div>
        <div
          v-if="$user.isSupporter() && hasCriteria"
          class="px-questionary__criteria-wrapper"
        >
          <h5>
            {{ $t("matching.matchingSidebar.questionary.criteria") }}
          </h5>
          <PxQuestionaryCriteria
            v-model="selectedCriteriaWeightId"
            :weights="criteriaWeights"
          />
        </div>
      </div>
      <div v-if="!finishedQuestionary" class="px-questionary__actions">
        <div class="px-questionary__skip" @click="onClickSkipQuestion">
          <PxIcon :size="20" name="skip" />
          {{ $t("common.skip") }}
        </div>
        <PxButton
          :disabled="!isFieldValid"
          class="px-questionary__next"
          size="medium"
          type="primary"
          @click="onClickSubmitAnswer"
        >
          {{ $t("common.nextQuestion") }}
        </PxButton>
      </div>
      <div v-if="finishedQuestionary" class="px-questionary__final">
        <h4 class="px-questionary__final-title">
          {{ finalTitle }}
        </h4>
        <p class="px-questionary__final-message">
          {{ finalMessage }}
        </p>
      </div>
    </template>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import {
  IMatchingQuestionType,
  SINGLE_SELECT,
  MULTI_SELECT,
  NUMERIC,
  RANGE,
  IMatchingQuestion,
} from "@/services/data/matching-questionary/matching-question.interface";

import PxQuestionaryCurrency from "@/components/px-questionary/px-questionary-currency.vue";
import PxQuestionaryCurrencyRange from "@/components/px-questionary/px-questionary-currency-range.vue";
import PxQuestionaryCriteria from "@/components/px-questionary/px-questionary-criteria.vue";
import PxQuestionaryNumeric from "@/components/px-questionary/px-questionary-numeric.vue";
import PxQuestionaryRange from "@/components/px-questionary/px-questionary-range.vue";
import PxQuestionaryPlaceholder from "@/components/px-questionary/px-questionary-placeholder.vue";
import {
  IMatchingCriteria,
  IMatchingCriteriaWeight,
} from "@/modules/matching/services/data/matching-criteria/matching-criteria.interface";
import { IMatchingResponse } from "@/services/data/matching-responses/matching-response.interface";

export default defineComponent({
  name: "PxQuestionary",

  components: {
    PxQuestionaryCurrency,
    PxQuestionaryCurrencyRange,
    PxQuestionaryCriteria,
    PxQuestionaryNumeric,
    PxQuestionaryRange,
    PxQuestionaryPlaceholder,
  },

  props: {
    question: {
      type: Object as () => IMatchingQuestion,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    finalTitle: {
      type: String,
      required: true,
    },
    finalMessage: {
      type: String,
      required: true,
    },
    criteria: {
      type: Object as () => IMatchingCriteria,
      default: null,
    },
    criteriaWeights: {
      type: Array as () => IMatchingCriteriaWeight[],
      default: () => [],
    },
  },

  data() {
    return {
      /**
       * Contains the selected values.
       */
      selectedOptions: [] as IMatchingResponse["answers"],
      selectedValue: null as null | IMatchingResponse["value"],
      selectedCriteriaWeightId: null as null | IMatchingCriteriaWeight["id"],
      isFieldValid: false,
    };
  },

  computed: {
    /**
     * Check if there is no more questions.
     */
    finishedQuestionary(): boolean {
      return this.question === null;
    },

    questionType(): IMatchingQuestionType | null {
      return this.question ? this.question.question_type : null;
    },

    /**
     * Check if can show criteria.
     */
    hasCriteria(): boolean {
      return this.criteriaWeights.length > 0 && !this.finishedQuestionary;
    },

    /**
     * Check if the question is a single ou multi-select type question.
     */
    isSelectType(): boolean {
      return (
        !!this.questionType &&
        !!this.questionType.type &&
        [SINGLE_SELECT, MULTI_SELECT].includes(this.questionType.type)
      );
    },

    /**
     * Check if the question is a numeric type question.
     */
    isNumeric(): boolean {
      return (
        !!this.questionType &&
        !!this.questionType.type &&
        this.questionType.type === NUMERIC
      );
    },

    /**
     * Check if the question is a multi-select type question.
     */
    isMultiSelect(): boolean {
      return (
        !!this.questionType &&
        !!this.questionType.type &&
        this.questionType.type === MULTI_SELECT
      );
    },

    /**
     * Check if the question is a range type question.
     */
    isRange(): boolean {
      return (
        !!this.questionType &&
        !!this.questionType.type &&
        this.questionType.type === RANGE
      );
    },

    isMoneyType(): boolean {
      return (
        (!!this.questionType &&
          !!this.questionType.meta &&
          this.questionType.meta.currency) ||
        false
      );
    },

    needsCurrencyRange(): boolean {
      return [
        this.$user.isEntrepreneur() &&
          !!this.questionType &&
          this.isMoneyType &&
          this.isRange,
        this.$user.isSupporter() &&
          !!this.questionType &&
          this.isMoneyType &&
          this.isNumeric,
      ].some((isValid: boolean) => isValid);
    },

    currentResponse():
      | IMatchingResponse["answers"]
      | IMatchingResponse["value"] {
      return this.selectedOptions.length
        ? this.selectedOptions
        : this.selectedValue;
    },
  },

  watch: {
    selectedOptions: {
      deep: true,
      handler(value: number | Array<number>) {
        // TODO: Analyse select component on why it doesn't return an array from the get go, to avoid this watch
        if (typeof value === "number") {
          this.selectedOptions = [value];
        }
      },
    },

    criteria: {
      immediate: true,
      deep: true,
      handler(existingCriteria: IMatchingCriteria | null) {
        if (existingCriteria) {
          this.selectedCriteriaWeightId = existingCriteria.criteria_weight_id;
        }
      },
    },
  },

  methods: {
    /**
     * Clear form data between questions
     * TODO: Should we fetch and prefill already answered questions?
     */
    clearAnswers() {
      // Clear values from next question
      this.selectedOptions = [];
      this.selectedValue = null;
    },

    onClickSkipQuestion() {
      this.clearAnswers();
      this.$emit("skip");
    },

    onClickSubmitAnswer() {
      if (this.$user.isEntrepreneur()) {
        this.$emit("submit", this.currentResponse);
      }

      if (this.$user.isSupporter()) {
        this.$emit("submit-criteria", {
          criteria: this.selectedCriteriaWeightId,
          values: this.currentResponse,
        });
      }

      this.clearAnswers();
    },
  },
});
</script>

<style lang="scss" scoped>
.px-questionary__wrapper {
  position: relative;
  color: $white;
}

.px-questionary__question-list {
  padding: 15px 20px;
}

.px-questionary__question-title {
  @include grotesk(semiBold);

  font-size: 17px;
  line-height: 22px;
  letter-spacing: 0;

  &:not(:last-child) {
    margin-bottom: 18px;
  }
}

.px-questionary__final {
  padding: 15px 20px 24px;

  &-title {
    @include grotesk(medium);

    font-size: 17px;
    line-height: 22px;
    letter-spacing: -0.1px;
  }

  &-message {
    font-size: 15px;
    line-height: 22px;
    letter-spacing: -0.2px;
  }
}

.px-questionary__actions {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  padding: 20px 15px 25px 18px;
}

.px-questionary__skip {
  @include grotesk(bold);

  display: flex;
  flex-flow: row wrap;
  align-items: center;
  margin-right: 23px;
  font-size: 15px;
  cursor: pointer;
  user-select: none;
  transition: opacity 140ms ease-out;
  will-change: opacity;

  &:hover {
    opacity: 0.7;
  }

  img {
    margin-right: 7px;
  }
}

.px-questionary__next {
  min-width: 131px;

  & :deep() span {
    letter-spacing: -0.5px;
  }
}

.px-questionary__criteria-wrapper {
  position: relative;
  z-index: z("default");
  padding: 5px 15px 20px;
  margin-top: 29px;
  background-color: #1c1c2f;
  border-bottom: solid 1px rgba(white, 0.2);

  &::before {
    position: absolute;
    bottom: calc(100% - 8px);
    left: 0;
    z-index: z("default");
    width: 100%;
    height: 28px;
    content: "";
    background-image: url("#{$assetsPath}/img/shapes/top-section-triangle.svg");
  }

  h5 {
    padding: 0 12px;
    margin-bottom: 21px;

    font-size: 16px;
    line-height: 22px;
    color: white;
    text-align: center;
  }
}
</style>
