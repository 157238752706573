import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-eaae57c8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "px-slider-card" }
const _hoisted_2 = { class: "px-slider-card__header" }
const _hoisted_3 = { class: "px-slider-card__title" }
const _hoisted_4 = { class: "px-slider-card__body" }
const _hoisted_5 = { class: "px-slider-card__body-wrapper" }
const _hoisted_6 = { class: "px-slider-card__slider" }
const _hoisted_7 = { class: "px-slider-card__stops" }
const _hoisted_8 = { class: "px-slider-card__labels" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ElSlider = _resolveComponent("ElSlider")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h3", _hoisted_3, _toDisplayString(_ctx.title), 1),
      _renderSlot(_ctx.$slots, "sub-header", {}, undefined, true)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _renderSlot(_ctx.$slots, "body", {}, undefined, true)
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_component_ElSlider, {
          modelValue: _ctx.selectedStop,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedStop) = $event)),
          "format-tooltip": _ctx.formatTooltip,
          max: _ctx.sliderCount,
          min: 1,
          step: 1,
          "show-stops": "",
          "tooltip-class": "px-slider-card__tooltip el-abaca-tooltip",
          onChange: _ctx.sliderChangeHandler
        }, null, 8, ["modelValue", "format-tooltip", "max", "onChange"]),
        _createElementVNode("div", _hoisted_7, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sliderCount, (index) => {
            return (_openBlock(), _createElementBlock("span", {
              key: `${index}`,
              class: "px-slider-card__tooltip-text"
            }))
          }), 128))
        ]),
        _createElementVNode("div", _hoisted_8, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sliderOddStops, (oddStop) => {
            return (_openBlock(), _createElementBlock("span", {
              key: oddStop.value,
              class: _normalizeClass({ 'is-active': oddStop.value === _ctx.currentStopValue })
            }, _toDisplayString(oddStop.label), 3))
          }), 128))
        ])
      ])
    ])
  ]))
}