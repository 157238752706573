import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-2213f982"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "team-members-list-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PxButton = _resolveComponent("PxButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_PxButton, {
      disabled: _ctx.disabled,
      class: "team-members-list-footer__cta",
      size: "small",
      type: "green",
      onClick: _ctx.addTeamMember
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$t(_ctx.buttonLabel)), 1)
      ]),
      _: 1
    }, 8, ["disabled", "onClick"])
  ]))
}