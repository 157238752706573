import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, createBlock as _createBlock, vShow as _vShow, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-f1d6b314"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "criteria-multi-selector__panel-wrapper" }
const _hoisted_2 = { class: "criteria-multi-selector__option-container" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = { class: "criteria-multi-selector__item-label" }
const _hoisted_5 = { class: "criteria-multi-selector__child-container" }
const _hoisted_6 = ["onClick"]
const _hoisted_7 = { class: "criteria-multi-selector__item-label" }
const _hoisted_8 = {
  key: 1,
  class: "criteria-multi-selector__search-container"
}
const _hoisted_9 = { class: "criteria-multi-selector__action-header-wrapper" }
const _hoisted_10 = { class: "criteria-multi-selector__action-header-container" }
const _hoisted_11 = { class: "criteria-multi-selector__action-header-text" }
const _hoisted_12 = ["onClick"]
const _hoisted_13 = ["innerHTML"]
const _hoisted_14 = { class: "criteria-multi-selector__empty-wrapper" }
const _hoisted_15 = { class: "criteria-multi-selector__empty-container" }
const _hoisted_16 = { class: "criteria-multi-selector__empty-text" }
const _hoisted_17 = { class: "criteria-multi-selector__empty-text" }
const _hoisted_18 = { class: "criteria-multi-selector__empty-button-container" }
const _hoisted_19 = {
  key: 1,
  class: "criteria-multi-selector__question-container"
}
const _hoisted_20 = { class: "criteria-multi-selector__action-header-wrapper" }
const _hoisted_21 = { class: "criteria-multi-selector__action-header-text" }
const _hoisted_22 = { class: "criteria-multi-selector__question-cta-container" }
const _hoisted_23 = { class: "criteria-multi-selector__modal-description" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PxIcon = _resolveComponent("PxIcon")!
  const _component_PxButton = _resolveComponent("PxButton")!
  const _component_CriteriaQuestionPanel = _resolveComponent("CriteriaQuestionPanel")!
  const _component_PxTextarea = _resolveComponent("PxTextarea")!
  const _component_ElFormItem = _resolveComponent("ElFormItem")!
  const _component_ElForm = _resolveComponent("ElForm")!
  const _component_ActionModal = _resolveComponent("ActionModal")!
  const _component_ElOption = _resolveComponent("ElOption")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_ctx.isCriteriaQuestionVisible)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass([{
        'criteria-multi-selector__panel-container--one-column':
          _ctx.isHighlightTextActive,
      }, "criteria-multi-selector__panel-container"])
        }, [
          (!_ctx.isHighlightTextActive)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                _createElementVNode("div", _hoisted_2, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: item.value,
                      class: "criteria-multi-selector__item",
                      onClick: ($event: any) => (_ctx.onCriteriaCategoryClick(item))
                    }, [
                      _createElementVNode("span", _hoisted_4, _toDisplayString(item.label), 1),
                      _createVNode(_component_PxIcon, {
                        size: 14,
                        name: "arrow--right-dark"
                      })
                    ], 8, _hoisted_3))
                  }), 128))
                ]),
                _createElementVNode("div", _hoisted_5, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.visibleChildren, (child) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: child.value,
                      class: "criteria-multi-selector__item",
                      onClick: ($event: any) => (_ctx.onCriteriaSelect(child))
                    }, [
                      _createElementVNode("span", _hoisted_7, _toDisplayString(child.label), 1)
                    ], 8, _hoisted_6))
                  }), 128))
                ])
              ], 64))
            : (_openBlock(), _createElementBlock("div", _hoisted_8, [
                _createElementVNode("div", _hoisted_9, [
                  _createElementVNode("div", _hoisted_10, [
                    _createElementVNode("span", _hoisted_11, _toDisplayString(_ctx.highlightedCountCopy), 1)
                  ])
                ]),
                (_ctx.hasHighlightedTextItems)
                  ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.highlightedTextItems, (item) => {
                      return (_openBlock(), _createElementBlock("div", {
                        key: item.value,
                        class: "criteria-multi-selector__item",
                        onClick: ($event: any) => (_ctx.onCriteriaSelect(item))
                      }, [
                        _createElementVNode("span", {
                          class: "criteria-multi-selector__item-label",
                          innerHTML: 
                  _ctx.highlightLabelByQuery(`${item.parent?.label} > ${item.label}`)
                
                        }, null, 8, _hoisted_13)
                      ], 8, _hoisted_12))
                    }), 128))
                  : _createCommentVNode("", true),
                _createElementVNode("div", _hoisted_14, [
                  _createElementVNode("div", _hoisted_15, [
                    _createVNode(_component_PxIcon, {
                      size: 33,
                      class: "criteria-multi-selector__empty-icon",
                      name: "icon-feedback"
                    }),
                    _createElementVNode("div", null, [
                      _createElementVNode("div", _hoisted_16, _toDisplayString(_ctx.$t(
                      "supporters.component.criteriaSelector.emptyState.description[0]",
                    )), 1),
                      _createElementVNode("div", _hoisted_17, _toDisplayString(_ctx.$t(
                      "supporters.component.criteriaSelector.emptyState.description[1]",
                    )), 1)
                    ]),
                    _createElementVNode("div", _hoisted_18, [
                      _createVNode(_component_PxButton, {
                        size: "small",
                        onClick: _ctx.onModalVisibilityToggle
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t("supporters.component.criteriaSelector.emptyState.cta")), 1)
                        ]),
                        _: 1
                      }, 8, ["onClick"])
                    ])
                  ])
                ])
              ]))
        ], 2))
      : (_openBlock(), _createElementBlock("div", _hoisted_19, [
          _createElementVNode("div", _hoisted_20, [
            _createElementVNode("div", {
              class: "criteria-multi-selector__action-header-container",
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onQuestionClose && _ctx.onQuestionClose(...args)))
            }, [
              _createVNode(_component_PxIcon, {
                name: "arrow--left-dark",
                size: "14"
              }),
              _createElementVNode("span", _hoisted_21, _toDisplayString(_ctx.$t("supporters.signup.form.previousButton")), 1)
            ])
          ]),
          (!!_ctx.currentQuestion)
            ? (_openBlock(), _createBlock(_component_CriteriaQuestionPanel, {
                key: 0,
                modelValue: _ctx.currentResponse,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.currentResponse) = $event)),
                question: _ctx.currentQuestion,
                class: "question-flow__panel",
                onValidate: _ctx.validateQuestionSubmitState
              }, null, 8, ["modelValue", "question", "onValidate"]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_22, [
            _createVNode(_component_PxButton, {
              class: "el-button--small criteria-multi-selector__question-cta",
              type: "link",
              onClick: _ctx.onQuestionClear
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t("supporters.signup.form.clearButton")), 1)
              ]),
              _: 1
            }, 8, ["onClick"]),
            _createVNode(_component_PxButton, {
              disabled: _ctx.isSubmitButtonDisabled,
              class: "criteria-multi-selector__question-cta criteria-multi-selector__question-cta--submit",
              type: "green",
              onClick: _ctx.onQuestionSubmit
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t("supporters.signup.form.addButton")), 1)
              ]),
              _: 1
            }, 8, ["disabled", "onClick"])
          ])
        ])),
    (_ctx.showCriteriaSuggestionModal)
      ? (_openBlock(), _createBlock(_component_ActionModal, {
          key: 2,
          ref: "criteriaSuggestionModal",
          title: _ctx.$t('supporters.component.criteriaSuggestionModal.title'),
          visibility: _ctx.showCriteriaSuggestionModal,
          class: "criteria-multi-selector__modal-wrapper"
        }, {
          content: _withCtx(() => [
            _createElementVNode("p", _hoisted_23, _toDisplayString(_ctx.$t("supporters.component.criteriaSuggestionModal.description")), 1),
            _createVNode(_component_ElForm, null, {
              default: _withCtx(() => [
                _createVNode(_component_ElFormItem, {
                  label: 
              _ctx.$t('supporters.component.criteriaSuggestionModal.input.label')
            ,
                  prop: "suggestion"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_PxTextarea, {
                      modelValue: _ctx.suggestionValue,
                      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.suggestionValue) = $event)),
                      "max-chars": _ctx.maxSuggestionSize,
                      placeholder: 
                _ctx.$t(
                  'supporters.component.criteriaSuggestionModal.input.placeholder',
                )
              ,
                      "show-counter": true,
                      resize: "none"
                    }, null, 8, ["modelValue", "max-chars", "placeholder"])
                  ]),
                  _: 1
                }, 8, ["label"])
              ]),
              _: 1
            })
          ]),
          actions: _withCtx(() => [
            _createVNode(_component_PxButton, {
              class: "el-button--link",
              type: "link",
              onClick: _ctx.onModalVisibilityToggle
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t("supporters.component.criteriaSuggestionModal.secondaryCta")), 1)
              ]),
              _: 1
            }, 8, ["onClick"]),
            _createVNode(_component_PxButton, {
              disabled: !_ctx.hasSuggestionValue,
              size: "small",
              type: "green",
              onClick: _ctx.onSuggestionSubmit
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t("supporters.component.criteriaSuggestionModal.mainCta")), 1)
              ]),
              _: 1
            }, 8, ["disabled", "onClick"])
          ]),
          _: 1
        }, 8, ["title", "visibility"]))
      : _createCommentVNode("", true),
    _withDirectives(_createElementVNode("div", null, [
      _createVNode(_component_ElOption, { value: 0 })
    ], 512), [
      [_vShow, !_ctx.forceElementSelectBehaviour]
    ])
  ]))
}