import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-009cca46"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "supporters-base page-full-height" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PxNavbarWizard = _resolveComponent("PxNavbarWizard")!
  const _component_PxNavbar = _resolveComponent("PxNavbar")!
  const _component_PxNavbarMinimal = _resolveComponent("PxNavbarMinimal")!
  const _component_PxGlobalError = _resolveComponent("PxGlobalError")!
  const _component_RouterView = _resolveComponent("RouterView")!
  const _component_PxFooter = _resolveComponent("PxFooter")!
  const _component_PxButton = _resolveComponent("PxButton")!
  const _component_ActionModal = _resolveComponent("ActionModal")!
  const _component_UserDetailsModal = _resolveComponent("UserDetailsModal")!
  const _component_SignInModal = _resolveComponent("SignInModal")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.isNavbarWizard)
      ? (_openBlock(), _createBlock(_component_PxNavbarWizard, {
          key: 0,
          loading: _ctx.isViewLoading,
          logo: _ctx.affiliateLogo,
          steps: _ctx.enabledNavBarSteps
        }, null, 8, ["loading", "logo", "steps"]))
      : (_ctx.isAffiliateFlow)
        ? (_openBlock(), _createBlock(_component_PxNavbar, {
            key: 1,
            logo: _ctx.affiliateLogo,
            "show-links": false
          }, null, 8, ["logo"]))
        : (_openBlock(), _createBlock(_component_PxNavbarMinimal, { key: 2 })),
    (_ctx.hasError)
      ? (_openBlock(), _createBlock(_component_PxGlobalError, {
          key: 3,
          "auto-refresh": true,
          loading: _ctx.isViewLoading,
          "use-action": true,
          class: "page-body is-center",
          onDoAction: _ctx.fetchSupporterTypes
        }, null, 8, ["loading", "onDoAction"]))
      : _createCommentVNode("", true),
    (!_ctx.hasError)
      ? _withDirectives((_openBlock(), _createElementBlock("div", {
          key: 4,
          class: _normalizeClass([{ 'is-loading': _ctx.isViewLoading }, "page-body supporters-base__container"]),
          "element-loading-background": "#fafafa"
        }, [
          _withDirectives((_openBlock(), _createBlock(_component_RouterView, {
            key: _ctx.$i18n.locale,
            "flow-data": _ctx.flowData,
            "form-loading": _ctx.isFormLoading,
            "has-previous-page": !!_ctx.previousPage,
            "skip-enabled": _ctx.isShowSkipButton,
            "view-loading": _ctx.isViewLoading,
            onNextPage: _ctx.nextPageNavigationGuard,
            onPreviousPage: _ctx.previousPageNavigationGuard,
            onSkipAll: _ctx.toggleSkipCriteriaModal,
            onSkipPage: _ctx.skipPageNavigationGuard
          }, null, 8, ["flow-data", "form-loading", "has-previous-page", "skip-enabled", "view-loading", "onNextPage", "onPreviousPage", "onSkipAll", "onSkipPage"])), [
            [_vShow, !_ctx.isViewLoading]
          ])
        ], 2)), [
          [_directive_loading, _ctx.isViewLoading]
        ])
      : _createCommentVNode("", true),
    (_ctx.showFooter)
      ? (_openBlock(), _createBlock(_component_PxFooter, {
          key: 5,
          class: _normalizeClass(_ctx.extraFooterClasses)
        }, null, 8, ["class"]))
      : _createCommentVNode("", true),
    (_ctx.showSkipCriteriaModal && !_ctx.isViewLoading)
      ? (_openBlock(), _createBlock(_component_ActionModal, {
          key: 6,
          ref: "skipCriteriaModal",
          title: _ctx.$t('supporters.component.skipCriteriaModal.title'),
          visibility: _ctx.showSkipCriteriaModal
        }, {
          content: _withCtx(() => [
            _createElementVNode("p", null, _toDisplayString(_ctx.$t("supporters.component.skipCriteriaModal.description")), 1)
          ]),
          actions: _withCtx(() => [
            _createVNode(_component_PxButton, {
              class: "el-button--link",
              type: "link",
              onClick: _ctx.onFlowFinish
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t("supporters.component.skipCriteriaModal.secondaryCta")), 1)
              ]),
              _: 1
            }, 8, ["onClick"]),
            _createVNode(_component_PxButton, {
              type: "primary",
              onClick: _ctx.onSetupCriteriaClick
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t("supporters.component.skipCriteriaModal.mainCta")), 1)
              ]),
              _: 1
            }, 8, ["onClick"])
          ]),
          _: 1
        }, 8, ["title", "visibility"]))
      : _createCommentVNode("", true),
    (!_ctx.isViewLoading)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 7 }, [
          (_ctx.showRegistrationForm)
            ? (_openBlock(), _createBlock(_component_UserDetailsModal, {
                key: 0,
                visibility: _ctx.showUserModals,
                onSignIn: _ctx.updateSignInModalVisibility,
                onSubmit: _ctx.onUserRegister
              }, null, 8, ["visibility", "onSignIn", "onSubmit"]))
            : (_openBlock(), _createBlock(_component_SignInModal, {
                key: 1,
                "has-invalid-account": _ctx.invalidAccount,
                visibility: _ctx.showUserModals,
                onRegister: _ctx.updateSignInModalVisibility,
                onSubmit: _ctx.prefillRegisteredSupporterData
              }, null, 8, ["has-invalid-account", "visibility", "onRegister", "onSubmit"]))
        ], 64))
      : _createCommentVNode("", true)
  ]))
}