import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-4953c67c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "matching-percentage-pill__wrapper" }
const _hoisted_2 = {
  key: 0,
  class: "matching-percentage-pill__container"
}
const _hoisted_3 = { class: "matching-percentage-pill" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ElTooltip = _resolveComponent("ElTooltip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ElTooltip, {
      disabled: !_ctx.$screen.mdUp || !_ctx.showTooltip,
      enterable: false,
      placement: "top",
      "popper-class": "matching-card__tooltip el-abaca-tooltip"
    }, {
      content: _withCtx(() => [
        _createElementVNode("div", null, [
          _createElementVNode("p", null, _toDisplayString(_ctx.$t("matching.matchingList.moreAboutThis")), 1)
        ])
      ]),
      default: _withCtx(() => [
        (_ctx.formattedMatchPercentage)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.formattedMatchPercentage), 1)
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["disabled"])
  ]))
}