import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-e3240d4e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "company-panel-placeholder__header" }
const _hoisted_2 = { class: "company-panel-placeholder__sectors" }
const _hoisted_3 = { class: "company-panel-placeholder__info" }
const _hoisted_4 = { class: "item item--founded" }
const _hoisted_5 = { class: "item item--sectors" }
const _hoisted_6 = { class: "item item--about" }
const _hoisted_7 = { class: "item item--website" }
const _hoisted_8 = { class: "item item--email" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ContentPlaceholdersHeading = _resolveComponent("ContentPlaceholdersHeading")!
  const _component_ContentPlaceholdersText = _resolveComponent("ContentPlaceholdersText")!
  const _component_ContentPlaceholders = _resolveComponent("ContentPlaceholders")!
  const _component_PxPanel = _resolveComponent("PxPanel")!

  return (_openBlock(), _createBlock(_component_PxPanel, { class: "panel-grid__block company-panel-placeholder px-panel--xs-no-side-borders" }, {
    default: _withCtx(() => [
      _createVNode(_component_ContentPlaceholders, { rounded: true }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_ContentPlaceholdersHeading, { img: true }),
            _createVNode(_component_ContentPlaceholdersText, {
              lines: 1,
              class: "company-panel-placeholder__cta"
            })
          ]),
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_ContentPlaceholdersHeading),
            _createVNode(_component_ContentPlaceholdersText, { lines: 3 })
          ]),
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_ContentPlaceholdersHeading)
            ]),
            _createElementVNode("div", _hoisted_5, [
              _createVNode(_component_ContentPlaceholdersHeading),
              _createVNode(_component_ContentPlaceholdersText, { lines: 3 })
            ]),
            _createElementVNode("div", _hoisted_6, [
              _createVNode(_component_ContentPlaceholdersHeading),
              _createVNode(_component_ContentPlaceholdersText, { lines: 4 })
            ]),
            _createElementVNode("div", _hoisted_7, [
              _createVNode(_component_ContentPlaceholdersHeading)
            ]),
            _createElementVNode("div", _hoisted_8, [
              _createVNode(_component_ContentPlaceholdersHeading)
            ])
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}