export interface IComponentLabel {
  text: string;
  icon: string;
  iconSize: string | number;
  textColor: string;
  backgroundColor: string;
}

export interface IComponentCta {
  label: string;
  type: string;
  size: string;
  action?: EComponentCtaActionType;
}

export enum EComponentCtaActionType {
  CANCEL = "cancel",
  CONTINUE = "continue",
}
