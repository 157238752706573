<template>
  <section class="milestones-section">
    <PxSidebarDivisor :icon="icon" :title="title" />
    <ContentPlaceholders v-if="isLoading" :animated="true">
      <ContentPlaceholdersText :lines="3" />
    </ContentPlaceholders>
    <MilestoneDetails v-else :milestones="milestones" />
  </section>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import MilestoneDetails, {
  IMilestoneItem,
} from "@/modules/milestone-planner/components/milestone-details/milestone-details.vue";
import PxSidebarDivisor from "@/components/px-sidebar-divisor/px-sidebar-divisor.vue";

export default defineComponent({
  name: "MilestonesSection",

  components: { PxSidebarDivisor, MilestoneDetails },

  props: {
    title: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      default: null,
    },
    milestones: {
      type: Array as () => IMilestoneItem[],
      required: true,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
});
</script>

<style lang="scss" scoped>
.milestones-section :deep() {
  .vue-content-placeholders-text__line:first-child {
    width: 99.4%;
    margin-top: 19px;
  }

  .vue-content-placeholders-text__line:nth-child(2) {
    width: 84%;
    margin-top: 24px;
  }

  .vue-content-placeholders-text__line:nth-child(3) {
    width: 70%;
    margin-top: 19px;

    background-color: $pale-grey-two;
  }
}
</style>
