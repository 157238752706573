<template>
  <div class="validating-panel__container">
    <div class="validating-panel__score-wrapper">
      <PxIcon
        :name="`level-graph/${currentLevel}`"
        :size="levelIconSize"
        class="validating-panel__level"
      />
    </div>
    <span v-if="showLabel" class="validating-panel__label">
      {{ $t("program.submission.selfAssessment") }}
    </span>
    <div class="validating-panel__title-wrapper">
      <span class="validating-panel__title">
        {{ title }}
      </span>
      <span class="validating-panel__tag-wrapper">
        <ElTag effect="plain" size="default" data-tag-type="grey">
          {{ $t("common.levelTag", { level: currentLevel }) }}
        </ElTag>
      </span>
    </div>
    <div v-markdown="description" class="validating-panel__description" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "AssessmentResume",

  props: {
    currentLevel: {
      type: Number,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    showLabel: {
      type: Boolean,
      default: true,
    },
    iconSize: {
      type: Number,
      default: null,
    },
  },

  computed: {
    levelIconSize(): number {
      return this.iconSize ? this.iconSize : this.$screen.mdDown ? 140 : 160;
    },
  },
});
</script>

<style lang="scss" scoped>
.validating-panel__container {
  display: grid;
  grid-template-areas:
    "label export"
    "level title"
    "description description";
  grid-template-columns: 140px auto;
  grid-row-gap: 14px;
  grid-column-gap: 14px;

  @include breakpoint-up(md) {
    grid-template-areas:
      "level label export"
      "level title title"
      "level description description";
    grid-template-columns: 182px auto;
    grid-row-gap: 8px;
    grid-column-gap: 7px;
  }
}

.validating-panel__score-wrapper {
  display: flex;
  grid-area: level;
  justify-content: center;

  @include breakpoint-down(sm) {
    max-height: 146px;
  }
}

.validating-panel__level {
  max-width: 140px;
  margin-right: 16px;

  @include breakpoint-down(sm) {
    position: relative;
    top: -15px;
    right: -5px;
  }

  @include breakpoint-up(md) {
    max-width: unset;
    margin-right: 25px;
    transform: translateY(-5px);
  }
}

.validating-panel__label {
  @include grotesk(semiBold);

  display: flex;
  align-items: center;
  font-size: 14px;
  color: $manatee;
  text-transform: uppercase;
}

.validating-panel__title-wrapper {
  grid-area: title;
}

.validating-panel__title {
  @include grotesk(semiBold);

  margin-top: 2px;
  margin-right: 7px;
  font-size: 19px;
  line-height: 26px;
  vertical-align: middle;

  @include breakpoint-up(md) {
    font-size: 22px;
    line-height: 28px;
  }
}

.validating-panel__tag-wrapper {
  display: block;

  @include breakpoint-up(md) {
    display: initial;
  }

  .el-tag {
    margin-top: 8px;
    vertical-align: middle;

    @include breakpoint-up(md) {
      margin: 0;
    }
  }
}

.validating-panel__description {
  grid-area: description;

  p {
    font-size: 15px;
    line-height: 25px;

    @include breakpoint-up(md) {
      padding-bottom: 32px;
      line-height: 26px;
    }
  }
}
</style>
