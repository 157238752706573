import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, withCtx as _withCtx, createElementVNode as _createElementVNode, mergeProps as _mergeProps, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-14a4935e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "share-modal-settings-link" }
const _hoisted_2 = { class: "share-modal-settings-link__footer" }
const _hoisted_3 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ElInput = _resolveComponent("ElInput")!
  const _component_ClipboardButton = _resolveComponent("ClipboardButton")!
  const _component_ElFormItem = _resolveComponent("ElFormItem")!
  const _component_PxButton = _resolveComponent("PxButton")!
  const _component_ElForm = _resolveComponent("ElForm")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ElForm, {
      ref: "form",
      class: "share-modal-settings-link__form"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_ElFormItem, {
          label: _ctx.settingsCopy.label,
          class: "share-modal-settings-link__input-wrapper",
          prop: "link"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_ElInput, {
              disabled: true,
              value: _ctx.link
            }, null, 8, ["value"]),
            _createVNode(_component_ClipboardButton, _normalizeProps(_guardReactiveProps(_ctx.clipboardButton)), null, 16)
          ]),
          _: 1
        }, 8, ["label"]),
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("span", {
            class: "el-form-item__bottom-text el-form-item__tip",
            innerHTML: _ctx.settingsCopy.tip
          }, null, 8, _hoisted_3),
          _createVNode(_component_PxButton, _mergeProps({ class: "share-modal-settings-link__cta" }, _ctx.resetButtonProps, { onClick: _ctx.clickResetLinkHandler }), null, 16, ["onClick"])
        ])
      ]),
      _: 1
    }, 512)
  ]))
}