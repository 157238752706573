import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, withModifiers as _withModifiers, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-c5d4a1f2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "milestone-form-footer-plan" }
const _hoisted_2 = { class: "milestone-form-footer-plan__left" }
const _hoisted_3 = {
  key: 1,
  class: "milestone-form-footer-plan__future-tip"
}
const _hoisted_4 = { class: "milestone-form-footer-plan__right" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PxButton = _resolveComponent("PxButton")!
  const _component_PxIcon = _resolveComponent("PxIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.hasPlan)
        ? (_openBlock(), _createBlock(_component_PxButton, _mergeProps({
            key: 0,
            ref: "deleteButton"
          }, _ctx.deleteButtonProps, {
            class: "milestone-form-footer-plan__delete-button",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emitter.emit('delete-milestone-plan')))
          }), null, 16))
        : _createCommentVNode("", true),
      (_ctx.isFutureMilestone && !_ctx.hasPlan)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createVNode(_component_PxIcon, {
              size: 16,
              class: "milestone-form-footer-plan__future-tip-icon",
              name: "warning--grey"
            }),
            _createTextVNode(" " + _toDisplayString(_ctx.$t("milestonePlanner.milestonePlan.planView.futureMilestoneTip")), 1)
          ]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_PxButton, _mergeProps({ ref: "discardButton" }, _ctx.discardButtonProps, {
        disabled: !_ctx.formHasChanges,
        class: "milestone-form-footer-plan__discard-button",
        onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.$emitter.emit('discard-milestone-plan')), ["stop"]))
      }), null, 16, ["disabled"]),
      _createVNode(_component_PxButton, _mergeProps({ ref: "saveAsDraftButton" }, _ctx.saveAsDraftButtonProps, {
        disabled: _ctx.saveAsDraftDisabled,
        loading: _ctx.isLoadingMilestones,
        class: "milestone-form-footer-plan__save-as-draft-button",
        onClick: _cache[2] || (_cache[2] = _withModifiers(($event: any) => (
          _ctx.$emitter.emit('submit-milestone-plan', { toPublish: false })
        ), ["stop"]))
      }), null, 16, ["disabled", "loading"]),
      _createVNode(_component_PxButton, _mergeProps({ ref: "submitPlanButton" }, _ctx.submitPlanButtonProps, {
        disabled: _ctx.submitPlanDisabled,
        loading: _ctx.isLoadingMilestones,
        class: "milestone-form-footer-plan__submit-plan-button",
        onClick: _cache[3] || (_cache[3] = _withModifiers(($event: any) => (
          _ctx.$emitter.emit('submit-milestone-plan', { toPublish: true })
        ), ["stop"]))
      }), null, 16, ["disabled", "loading"])
    ])
  ]))
}