import {
  GenericProvider,
  EProviderFeatures,
} from "@/services/data/generic-provider";
import { IPendingUserPassword } from "@/modules/authentication/services/data/pending-user-password/pending-user-password.interface";
import { baseAPIUrl } from "@/services/utils/utils";

/**
 * Provider used to perform a sign up.
 */
class PendingUserPasswordProvider extends GenericProvider<
  any,
  IPendingUserPassword
> {
  constructor() {
    super(`${baseAPIUrl}/pending-user/password`, [EProviderFeatures.CREATE]);
  }
}

export const pendingUserPasswordProvider = new PendingUserPasswordProvider();
