import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-97a8be7c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "auth-navigation-view page-full-height" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PxNavbar = _resolveComponent("PxNavbar")!
  const _component_PxTabBar = _resolveComponent("PxTabBar")!
  const _component_MobileTopNavbar = _resolveComponent("MobileTopNavbar")!
  const _component_RouterView = _resolveComponent("RouterView")!
  const _component_PxBottomBar = _resolveComponent("PxBottomBar")!
  const _component_PxFooter = _resolveComponent("PxFooter")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.$screen.mdUp)
      ? (_openBlock(), _createBlock(_component_PxNavbar, {
          key: 0,
          items: _ctx.activeNavBarItems,
          "show-account-dropdown": _ctx.$user.isLogged()
        }, null, 8, ["items", "show-account-dropdown"]))
      : _createCommentVNode("", true),
    (_ctx.$screen.smDown && _ctx.shouldShowTabBar)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          (_ctx.hasTabBarItems && !_ctx.isErrorRoute)
            ? (_openBlock(), _createBlock(_component_PxTabBar, {
                key: 0,
                modelValue: _ctx.selectedView,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedView) = $event)),
                items: _ctx.tabBarItems
              }, null, 8, ["modelValue", "items"]))
            : (_openBlock(), _createBlock(_component_MobileTopNavbar, { key: 1 }))
        ], 64))
      : _createCommentVNode("", true),
    (_openBlock(), _createBlock(_component_RouterView, {
      key: _ctx.routerViewKey,
      class: "page-body"
    })),
    (_ctx.$user.isLogged() && _ctx.$screen.smDown)
      ? (_openBlock(), _createBlock(_component_PxBottomBar, {
          key: 2,
          items: _ctx.mobileMenuItems
        }, null, 8, ["items"]))
      : _createCommentVNode("", true),
    (_ctx.$screen.mdUp && !_ctx.hideFooter)
      ? (_openBlock(), _createBlock(_component_PxFooter, {
          key: 3,
          class: _normalizeClass({ 'px-footer--no-padding': _ctx.isTeamManagementPage })
        }, null, 8, ["class"]))
      : _createCommentVNode("", true)
  ]))
}