import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "milestone-planner-for-visitors" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AuthenticatedWithNavigationView = _resolveComponent("AuthenticatedWithNavigationView")!
  const _component_AccessPlannerGuard = _resolveComponent("AccessPlannerGuard")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.hasRetrievedMilestones)
      ? (_openBlock(), _createBlock(_component_AuthenticatedWithNavigationView, { key: 0 }))
      : (_openBlock(), _createBlock(_component_AccessPlannerGuard, {
          key: 1,
          "requires-passcode": _ctx.requiresPasscode
        }, null, 8, ["requires-passcode"]))
  ]))
}