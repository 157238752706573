import { GenericProvider, Provider } from "@/services/data/generic-provider";
import { IAssessment } from "@/services/data/assessment/assessment.interface";
import { baseAPIUrl } from "@/services/utils/utils";

export type TAssessmentHistory = Array<IAssessment>;

const GET_HISTORY_BASE_URL = `${baseAPIUrl}/companies/{id}/assessments`;

@Provider(`${baseAPIUrl}/companies/{id}/assessments`, [])
class AssessmentsHistoryProvider extends GenericProvider<TAssessmentHistory> {
  /**
   * Get the assessment history from a company.
   *
   * @param companyId Id of the company to get history from.
   */
  public async getHistory(companyId: number): Promise<TAssessmentHistory> {
    const url = GET_HISTORY_BASE_URL.replace("{id}", String(companyId));
    const request = this.httpClient.get(url);
    const { data } = await this.wrapRequest(request);

    return data as TAssessmentHistory;
  }
}

export const assessmentsHistoryProvider = new AssessmentsHistoryProvider();
