import { ActionTree } from "vuex";
import { RootState } from "@/services/store/root-state";
import { getBaseName } from "@/services/store/utils/get-base-name";
import {
  EAffiliateAction,
  IAffiliateState,
} from "@/modules/supporters/services/store/affiliate/affiliate.interface";
import { affiliateProvider } from "@/services/data/affiliate/affiliate.provider";
import { AffiliateState } from "@/modules/supporters/services/store/affiliate/affiliate.module";

export const actions: ActionTree<IAffiliateState, RootState> = {
  /**
   * Fetch default affiliate for supporter.
   */
  async [getBaseName(EAffiliateAction.FETCH)]({ commit }, affiliateId) {
    commit(AffiliateState.Mutation.SET_LOADING, true);
    commit(AffiliateState.Mutation.SET_ERROR, null);

    let requestError = null;

    try {
      const affiliate = await affiliateProvider.get(affiliateId);
      commit(AffiliateState.Mutation.SET_VALUE, affiliate);
    } catch (error) {
      commit(AffiliateState.Mutation.SET_ERROR, error || true);
      requestError = error;
    } finally {
      commit(AffiliateState.Mutation.SET_LOADING, false);
    }

    if (!!requestError) {
      throw requestError;
    }
  },

  /**
   * Fetch default affiliate for supporter.
   */
  async [getBaseName(EAffiliateAction.SUPPORTER_FETCH_DEFAULT)]({ commit }) {
    commit(AffiliateState.Mutation.SET_LOADING, true);
    commit(AffiliateState.Mutation.SET_ERROR, null);

    let requestError = null;

    try {
      const affiliate = await affiliateProvider.getSupporterDefault();
      commit(AffiliateState.Mutation.SET_VALUE, affiliate);
    } catch (error) {
      commit(AffiliateState.Mutation.SET_ERROR, error || true);
      requestError = error;
    } finally {
      commit(AffiliateState.Mutation.SET_LOADING, false);
    }

    if (!!requestError) {
      throw requestError;
    }
  },

  /**
   * Fetch custom affiliate for supporter.
   */
  async [getBaseName(EAffiliateAction.SUPPORTER_FETCH)](
    { commit },
    affiliateId: number,
  ) {
    commit(AffiliateState.Mutation.SET_LOADING, true);
    commit(AffiliateState.Mutation.SET_ERROR, null);

    let requestError = null;

    try {
      const affiliate = await affiliateProvider.getSupporter(affiliateId);
      commit(AffiliateState.Mutation.SET_VALUE, affiliate);
    } catch (error) {
      commit(AffiliateState.Mutation.SET_ERROR, error || true);
      requestError = error;
    } finally {
      commit(AffiliateState.Mutation.SET_LOADING, false);
    }

    if (!!requestError) {
      throw requestError;
    }
  },
};
