<template>
  <div class="list-reports-chart">
    <div class="list-reports-chart__header">
      <div class="list-reports-chart__header-content">
        <h1 v-if="chartTitle">{{ chartTitle }}</h1>
        <p v-if="chartDescription">{{ chartDescription }}</p>
      </div>
    </div>
    <div class="list-reports-chart__content">
      <template v-if="showLockedState">
        <ChartLocked :content="lockedState" @click="showPrivacyModal" />
        <ChartsLockedModal
          v-if="privacyModalVisible"
          v-model:visibility="privacyModalVisible"
          @navigate="goToInitiativePage"
        />
      </template>
      <template v-else>
        <DoughnutChart
          :chart-height="chartHeight"
          :chart-width="chartWidth"
          :data="doughnutChartData"
        />
        <ChartsTable
          :colors="backgroundColors"
          :data="allLabelsValues"
          :first-column-header="theme"
          :labels="allChartLabels"
          :percentages="allLabelsPercentage"
        />
        <span v-if="note" class="list-reports-chart__note">
          <PxIcon :size="14" name="information--light-gray" />
          <small>
            {{ note }}
          </small>
        </span>
      </template>
    </div>
    <div v-if="showLockedState" class="list-reports-chart--overlay"></div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import ChartLocked, {
  IChartLockedContent,
} from "@/modules/charts-dashboard/components/common/chart-locked/chart-locked.vue";
import DoughnutChart from "@/components/charts/doughnut-chart/doughnut-chart.vue";
import ChartsTable from "@/modules/charts-dashboard/components/common/charts-table/charts-table.vue";
import ChartsLockedModal from "@/modules/charts-dashboard/components/common/charts-locked-modal/charts-locked-modal.vue";

import { IListReportsDemographicData } from "@/modules/charts-dashboard/services/data/lists-reports-data/lists-reports-data.interface";
import { ROUTE_TEAM_MANAGEMENT_INITIATIVE_DETAILS } from "@/modules/team-management/services/router/routes-names";
import { CHARTS_BACKGROUND_COLORS } from "@/modules/charts-dashboard/components/charts-background-colors";

export default defineComponent({
  name: "TotalResultsChart",

  components: {
    DoughnutChart,
    ChartsTable,
    ChartLocked,
    ChartsLockedModal,
  },

  props: {
    data: {
      type: Object as () => IListReportsDemographicData,
      required: true,
    },

    chartTitle: {
      type: String,
      default: "",
    },

    chartDescription: {
      type: String,
      default: "",
    },

    theme: {
      type: String,
      required: true,
    },

    chartWidth: {
      type: Number,
      default: 240,
    },

    chartHeight: {
      type: Number,
      default: 240,
    },

    showLockedState: {
      type: Boolean,
      default: false,
    },

    lockedState: {
      type: Object as () => IChartLockedContent,
      default: () => ({
        title: "",
        description: "",
        note: "",
        image: "",
      }),
    },

    note: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      backgroundColors: CHARTS_BACKGROUND_COLORS,
      privacyModalVisible: false,
    };
  },

  computed: {
    allChartLabels(): Array<string> {
      return this.data.responses.map((response: any) => response.value);
    },

    allLabelsValues(): Array<number> {
      return this.allChartLabels.map((label: string) => {
        const response = this.data.responses.find(
          (response: any) => response.value === label,
        );

        return response ? response.count : 0;
      });
    },

    allLabelsPercentage(): Array<number> {
      return this.allChartLabels.map((label: string) => {
        const response = this.data.responses.find(
          (response: any) => response.value === label,
        );

        return response ? Math.round(response.percentage * 1000) / 10 : 0;
      });
    },

    doughnutChartData(): any {
      return {
        labels: this.allChartLabels,
        datasets: [
          {
            data: this.allLabelsValues,
            percentage: this.allLabelsPercentage,
            backgroundColor: this.backgroundColors,
          },
        ],
      };
    },
  },

  methods: {
    showPrivacyModal() {
      this.privacyModalVisible = true;
    },

    goToInitiativePage() {
      return this.$router.push({
        name: ROUTE_TEAM_MANAGEMENT_INITIATIVE_DETAILS,
      });
    },
  },
});
</script>
<style lang="scss" scoped>
.list-reports-chart {
  position: relative;
  width: 100%;
  min-height: 570px;

  display: flex;
  flex-direction: column;

  background: $white;
  border-radius: 4px;
  box-shadow: 0 2px 11px 0 rgba(0, 0, 0, 0.05);
  border: 1px solid $pale-grey;

  padding: 21px 23px 28px 23px;

  &--overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;

    background: rgba(255, 255, 255, 0.8);
    border-radius: 4px;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 30px;

    margin-bottom: 40px;

    &-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;

      line-height: 1.2;
    }

    h1 {
      margin-bottom: 5px;

      font-size: to-rem(20px);
      font-weight: 600;
      line-height: 1.5;
      letter-spacing: -0.13px;
    }

    h3 {
      padding: 0;
      margin: 0 0 10px 0;
      color: $ebony-clay;
      letter-spacing: -0.26px;
    }

    p {
      font-size: to-rem(15px);
      line-height: 1.47;
      font-weight: 400;
      color: $manatee;
      letter-spacing: -0.3px;
      padding: 0;
      margin: 0;
    }
  }

  &__note {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 10px;
    margin-top: 22px;

    :deep() .px-icon {
      position: relative;
      top: 1px;
      left: 1px;
    }
  }

  small {
    padding-right: 10px;

    letter-spacing: -0.1px;
    font-style: italic;
    color: $manatee;
    line-height: 16px;
  }

  .charts-table {
    margin-top: 19px;
    margin-bottom: 5px;
  }
}
</style>
