import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-69b9bdd5"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "results-board__cta-panel" }
const _hoisted_2 = {
  key: 0,
  class: "results-board__headline"
}
const _hoisted_3 = { class: "results-board__headline-title" }
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = { class: "results-board__list" }
const _hoisted_6 = { class: "results-board__footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PxIcon = _resolveComponent("PxIcon")!
  const _component_i18n_t = _resolveComponent("i18n-t")!
  const _component_PxButton = _resolveComponent("PxButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.showSubmitMessage)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("h2", _hoisted_3, [
            _createVNode(_component_PxIcon, {
              name: "check--white",
              size: "21"
            }),
            _createTextVNode(" " + _toDisplayString(_ctx.$t(`selfAssessment.results.resultsBoardCTA.pendingUser.headline`)), 1)
          ]),
          _createVNode(_component_i18n_t, {
            class: "results-board__headline-subtitle",
            keypath: "selfAssessment.results.resultsBoardCTA.pendingUser.notice",
            tag: "h4"
          }, {
            company: _withCtx(() => [
              _createElementVNode("span", null, _toDisplayString(_ctx.affiliateName), 1)
            ]),
            _: 1
          })
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("h2", {
      class: "results-board__title",
      innerHTML: _ctx.$t(`selfAssessment.results.resultsBoardCTA.pendingUser.title`)
    }, null, 8, _hoisted_4),
    _createElementVNode("ul", _hoisted_5, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.$tm(
          `selfAssessment.results.resultsBoardCTA.list`,
        ), (item, index) => {
        return (_openBlock(), _createElementBlock("li", { key: index }, _toDisplayString(item), 1))
      }), 128))
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createVNode(_component_PxButton, {
        class: "results-board__join-btn",
        size: "large",
        type: "green",
        onClick: _ctx.onClickHandler
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t("selfAssessment.results.resultsBoardCTA.pendingUser.button")), 1)
        ]),
        _: 1
      }, 8, ["onClick"])
    ])
  ]))
}