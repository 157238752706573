import { IGenericState } from "@/services/store/generic/generic-state.interface";
import { MutationTree } from "vuex";
import { GenericState } from "@/services/store/generic/generic-state.module";

/**
 * Returns default mutations for generic state.
 */
export const getGenericStateMutations = <T>(): MutationTree<
  IGenericState<T>
> => ({
  /**
   * Generic action to update value.
   * @param state
   * @param payload new value
   */
  [GenericState.Mutation.SET_VALUE](state: IGenericState<T>, payload: T) {
    state.value = payload;
  },

  /**
   * Generic action to update values list.
   * @param state
   * @param payload new list values
   */
  [GenericState.Mutation.SET_VALUES](
    state: IGenericState<T>,
    payload: Array<T>,
  ) {
    state.values = payload;
  },

  /**
   * Generic mutation to update loading state.
   * @param state
   * @param payload new loading value
   */
  [GenericState.Mutation.SET_LOADING](
    state: IGenericState<T>,
    payload: boolean,
  ) {
    state.loading = payload;
  },

  /**
   * Generic action to update error state.
   * @param state
   * @param payload new error value
   */
  [GenericState.Mutation.SET_ERROR](
    state: IGenericState<T>,
    payload: Error | null,
  ) {
    state.error = payload;
  },
});
