import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, mergeProps as _mergeProps, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-00117a74"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "affiliate-review-page-self-assessment" }
const _hoisted_2 = {
  key: 0,
  class: "affiliate-review-page-self-assessment__title"
}
const _hoisted_3 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AssessmentResume = _resolveComponent("AssessmentResume")!
  const _component_PxButton = _resolveComponent("PxButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.$screen.xlUp)
      ? (_openBlock(), _createElementBlock("h3", _hoisted_2, _toDisplayString(_ctx.$t("affiliateProgram.components.flowNavbar.assessmentTabTitle")), 1))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: _normalizeClass([{
        'assessment-incomplete': _ctx.assessmentIncomplete,
      }, "affiliate-review-page-self-assessment__wrapper"])
    }, [
      (!_ctx.assessmentIncomplete && _ctx.levelDescription)
        ? (_openBlock(), _createBlock(_component_AssessmentResume, {
            key: 0,
            "current-level": _ctx.viralLevel,
            description: _ctx.levelDescription.description,
            "icon-size": 100,
            "show-label": false,
            title: _ctx.levelDescription.title
          }, null, 8, ["current-level", "description", "title"]))
        : (_openBlock(), _createElementBlock("h3", _hoisted_3, _toDisplayString(_ctx.$t("affiliates.reviewPage.content.missingAssessment")), 1)),
      _createVNode(_component_PxButton, _mergeProps(_ctx.updateButtonProps, {
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update')))
      }), null, 16)
    ], 2)
  ]))
}