<template>
  <ElTooltip
    :content="deletePlanTooltipCopy"
    :disabled="!showTooltip"
    data-testid="delete-button-tooltip"
    placement="top"
    popper-class="milestone-form-footer__tooltip el-abaca-tooltip"
  >
    <div>
      <PxButton
        v-bind="deleteButtonProps"
        :disabled="buttonDisabled"
        class="milestone-form-footer__delete-button"
        data-testid="delete-button"
        @click="$emit('click')"
      />
    </div>
  </ElTooltip>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { EPxButtonType } from "@/components/px-button/px-button.types";
import { TranslateResult } from "vue-i18n";

export default defineComponent({
  name: "MilestoneFormFooterDeleteButton",

  props: {
    showTooltip: {
      type: Boolean,
      default: false,
    },
    buttonDisabled: {
      type: Boolean,
      default: false,
    },
    buttonLabel: {
      type: String,
      required: true,
    },
  },

  static: {
    deleteButton: {
      icon: "trash--red",
      type: EPxButtonType.LINK_DANGER,
    },
  },

  computed: {
    deletePlanTooltipCopy(): TranslateResult {
      return this.$t("milestonePlanner.milestonePlan.tooltips.deleteMilestone");
    },

    deleteButtonProps(): any {
      return {
        ...this.$options.static.deleteButton,
        label: this.buttonLabel,
      };
    },
  },
});
</script>

<style lang="scss" scoped>
.milestone-form-footer__delete-button {
  position: relative;
  bottom: 1px;
  height: 36px;
}
</style>
