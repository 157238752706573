import {
  GenericProvider,
  EProviderFeatures,
} from "@/services/data/generic-provider";
import { ISupportersSignup } from "@/modules/supporters/services/data/supporters-signup/supporters-signup.interface";
import { baseAPIUrl } from "@/services/utils/utils";

/**
 * Provider used to perform a sign up.
 */
class SupportersSignUpProvider extends GenericProvider<any, ISupportersSignup> {
  constructor() {
    super(`${baseAPIUrl}/supporters/register`, [EProviderFeatures.CREATE]);
  }
}

export const supportersSignUpProvider = new SupportersSignUpProvider();
