import { GenericState } from "@/services/store/generic/generic-state.module";
import {
  EGenericGetter,
  IGenericGetter,
} from "@/services/store/generic/generic-state.interface";
import {
  ESupporterFlowState,
  ISupporterFlowState,
} from "@/modules/supporters/services/store/supporter-flow/supporter-flow.interface";
import { pendingSupporterProvider } from "@/modules/supporters/services/data/supporter-flow/pending-supporter.provider";
import {
  ISupporterFlow,
  ISupporterFlowImportances,
} from "@/modules/supporters/services/data/supporter-flow/supporter-flow.interface";
import { actions } from "@/modules/supporters/services/store/supporter-flow/supporter-flow.actions";
import { getGenericInitialState } from "@/services/store/generic/generic-state";
import { IGroupedSectorsPayload } from "@/components/multi-selector/multi-selector-sectors.provider";
import { ILocationGoogleAutocomplete } from "@/services/data/location/location-search.interface";
import { IGroupedLocationsPayload } from "@/components/multi-selector/multi-selector-locations.providers";

/**
 * Boilerplate module instantiation
 */
export class SupporterFlowState extends GenericState<
  ISupporterFlowState,
  ISupporterFlow
> {
  static Getter = GenericState.mapNamespaceEnum(
    EGenericGetter,
    ESupporterFlowState.NAMESPACE,
  ) as IGenericGetter;
}

export const supporterFlowInitialState = {
  token: "",
  auth_token: "",

  email: "",
  password1: "",
  password2: "",

  supporter: {
    investing_level_range: [1, 1],
    types: [],
    sectors: [] as Array<number>,
    grouped_sectors: [] as Array<IGroupedSectorsPayload>,
    grouped_locations: [] as Array<IGroupedLocationsPayload>,
    places: [] as Array<ILocationGoogleAutocomplete["place_id"]>,
    locations: [] as Array<number>,
  },

  company: {
    name: "",
    location: null,
    networks: [],
  },

  criteria: [],
  team_members: [],
  additional_criteria: [],
  importances: {
    level_weight: null,
    locations_weight: null,
    sectors_weight: null,
    questions: [],
  } as ISupporterFlowImportances,

  options: {
    started: false,
    submitted: false,
    type: null,
  },
  meta: {},
};

/**
 * Boilerplate module instantiation
 */
export const supporterFlowModule = new SupporterFlowState({
  // This store works with more than one provider, but the generic state abstract implementation
  // only supports single provider implementation.
  // TODO: Update generic state to allow more than one provider implementation or look into react-query
  provider: pendingSupporterProvider,
  props: {
    actions,
    state: {
      ...getGenericInitialState<ISupporterFlow>(),
      value: { ...supporterFlowInitialState },
    },
  },
});
