import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-53a94205"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "grid-levels" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.levelsCount, (levelNumber) => {
      return (_openBlock(), _createElementBlock("div", {
        key: levelNumber,
        class: _normalizeClass([{
        'grid-levels__level--active': levelNumber === _ctx.currentLevel,
      }, "grid-levels__level"])
      }, _toDisplayString(levelNumber), 3))
    }), 128))
  ]))
}