import { resolveComponent as _resolveComponent, withModifiers as _withModifiers, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, mergeProps as _mergeProps, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-b03b5dc0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "matching-card__headline" }
const _hoisted_2 = { class: "matching-card__title" }
const _hoisted_3 = { class: "matching-card__subtitle" }
const _hoisted_4 = { class: "matching-card__content" }
const _hoisted_5 = {
  key: 0,
  class: "matching-card__description"
}
const _hoisted_6 = { class: "matching-badge-list__wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MatchingCardPercentagePill = _resolveComponent("MatchingCardPercentagePill")!
  const _component_MatchDetailsModal = _resolveComponent("MatchDetailsModal")!
  const _component_MatchingCardThumbnail = _resolveComponent("MatchingCardThumbnail")!
  const _component_MatchingCardTagList = _resolveComponent("MatchingCardTagList")!
  const _component_MatchingBadgeList = _resolveComponent("MatchingBadgeList")!
  const _component_PxMatchingInterest = _resolveComponent("PxMatchingInterest")!
  const _component_MatchingCardWrapper = _resolveComponent("MatchingCardWrapper")!

  return (_openBlock(), _createBlock(_component_MatchingCardWrapper, {
    class: "matching-card--simplified",
    onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('click')))
  }, {
    header: _withCtx(() => [
      _createVNode(_component_MatchingCardPercentagePill, {
        percentage: _ctx.matchPercentage,
        "show-tooltip": !_ctx.isInterestDetailModalVisible && _ctx.$screen.mdUp,
        onClick: _withModifiers(_ctx.onClickShowModal, ["stop"])
      }, null, 8, ["percentage", "show-tooltip", "onClick"]),
      (_ctx.isInterestDetailModalVisible && _ctx.$screen.mdUp)
        ? (_openBlock(), _createBlock(_component_MatchDetailsModal, {
            key: 0,
            visibility: _ctx.isInterestDetailModalVisible,
            "onUpdate:visibility": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.isInterestDetailModalVisible) = $event)),
            "company-logo": _ctx.thumbnail,
            "company-name": _ctx.name,
            "match-details": _ctx.matchScoreDetails
          }, null, 8, ["visibility", "company-logo", "company-name", "match-details"]))
        : _createCommentVNode("", true)
    ]),
    body: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_MatchingCardThumbnail, {
          level: _ctx.matchLevel,
          name: _ctx.name,
          size: 46,
          thumbnail: _ctx.thumbnail
        }, null, 8, ["level", "name", "thumbnail"]),
        _createElementVNode("div", null, [
          _createElementVNode("h3", _hoisted_2, _toDisplayString(_ctx.name), 1),
          _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.location), 1)
        ])
      ]),
      _createElementVNode("div", _hoisted_4, [
        (!!_ctx.about)
          ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_ctx.about), 1))
          : _createCommentVNode("", true),
        _createVNode(_component_MatchingCardTagList, { items: _ctx.mappedSectors }, null, 8, ["items"]),
        _createElementVNode("div", _hoisted_6, [
          (!_ctx.showPublicView)
            ? (_openBlock(), _createBlock(_component_MatchingBadgeList, {
                key: 0,
                badges: _ctx.badges,
                "company-name": _ctx.name
              }, null, 8, ["badges", "company-name"]))
            : _createCommentVNode("", true)
        ])
      ])
    ]),
    footer: _withCtx(() => [
      (_ctx.enabledMatchingInterest)
        ? (_openBlock(), _createBlock(_component_PxMatchingInterest, _mergeProps({ key: 0 }, _ctx.$options.static.interestButton, {
            id: _ctx.companyId,
            name: _ctx.name,
            "with-description": false,
            class: "matching-card__interest",
            onClick: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["stop"]))
          }), null, 16, ["id", "name"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}