import { Module } from "vuex";
import { make } from "vuex-pathify";

import { RootState } from "@/services/store/root-state";

import { IBaseState } from "@/services/store/store-types";
// Removed this for now, can be useful in the future
// import { IProfileState } from "./profile-types";
import { profileLatestAssessment } from "./submodules/profile-latest-assessment/profile-latest-assessment-module";
import { companyModuleFactory } from "@/modules/profile/services/store/factory/company/company.module";
import { profileAssessmentHistory } from "./submodules/assessment-history/assessment-history.module";
import { supporterModuleFactory } from "@/modules/profile/services/store/factory/supporter/supporter.module";

/**
 * Initial state for the profile
 */
const state: IBaseState = {
  loading: false,
  error: null,
};

export const profileModule: Module<IBaseState, RootState> = {
  namespaced: true,
  state,
  mutations: make.mutations(state),
  modules: {
    company: companyModuleFactory(),
    supporter: supporterModuleFactory(),
    latestAssessment: profileLatestAssessment,
    assessmentHistory: profileAssessmentHistory,
  },
};
