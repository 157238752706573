<template>
  <div class="offerings-selector">
    <div
      :class="{
        'offerings-selector__list--with-entries': selectedOfferingTypes.length,
      }"
      class="offerings-selector__list"
    >
      <div
        v-for="offeringType in selectedOfferingTypes"
        :key="offeringType.id"
        class="offerings-selector__entry"
      >
        <span class="offerings-selector__entry-name">
          {{ offeringType.name }}
        </span>
        <i
          class="icon icon-trash--dark"
          @click="onClickRemoveOfferingType(offeringType.id)"
        />
      </div>
    </div>
    <ElSelect
      :disabled="!listOfOfferingTypes.length"
      :placeholder="$t('profile.supporter.offerings.form.select.types')"
      class="offerings-edit-modal__select"
      @blur="blurHandler"
      @change="onOfferingTypeSelect"
    >
      <ElOption
        v-for="(type, key) in listOfOfferingTypes"
        :key="key"
        :label="type.name"
        :value="type"
        :value-key="type.id"
      />
    </ElSelect>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import emitter from "@/mixins/emitter";

import cloneDeep from "lodash/cloneDeep";

import { IOfferingType } from "@/services/data/offering/offering.interface";

export default defineComponent({
  name: "OfferingTypesSelect",

  mixins: [emitter],

  props: {
    types: {
      type: Array as () => IOfferingType[],
      default: () => [],
    },
    modelValue: {
      type: Array as () => IOfferingType[],
      required: true,
    },
  },

  data() {
    return {
      listOfOfferingTypes: [] as Array<IOfferingType>,
      offeringTypesSelectLoading: false,

      // Will stores the list of selected sectors
      selectedOfferingTypes: [] as Array<IOfferingType>,
    };
  },

  watch: {
    types: {
      immediate: true,
      handler(currentTypes) {
        this.listOfOfferingTypes = cloneDeep(currentTypes);
      },
    },
    selectedOfferingTypes: {
      deep: true,
      handler(newVal: Array<IOfferingType>) {
        this.$emit("update:modelValue", newVal);
        this.$emit("change", newVal);
      },
    },
  },

  methods: {
    /**
     * Allow to set the active offering types.
     */
    setActiveOfferingTypes(types: Array<IOfferingType>) {
      this.selectedOfferingTypes = types;
    },

    /**
     * When a new type is selected it is pushed into the
     * array of selected types and the autocomplete fill
     * cleared.
     */
    onOfferingTypeSelect(selectedValue: IOfferingType) {
      this.selectedOfferingTypes.push(selectedValue);

      // Remove element from list
      const indexToRemove = this.listOfOfferingTypes.findIndex(
        (type: IOfferingType) => type.id === selectedValue.id,
      );
      this.listOfOfferingTypes.splice(indexToRemove, 1);
      this.orderListOfOfferingTypes();

      this.$emit("change", selectedValue);
    },

    /**
     * Remove the given type from the list of selected types.
     */
    onClickRemoveOfferingType(typeId: number) {
      const indexOfType = this.selectedOfferingTypes.findIndex(
        (type: IOfferingType) => type.id === typeId,
      );

      // Add to list of available offers
      this.listOfOfferingTypes.push(this.selectedOfferingTypes[indexOfType]);
      this.orderListOfOfferingTypes();

      // Remove from selected offers
      this.selectedOfferingTypes.splice(indexOfType, 1);
    },

    orderListOfOfferingTypes() {
      // Order list alphabetically by name
      this.listOfOfferingTypes.sort((a, b) => a.name.localeCompare(b.name));
    },

    blurHandler(event: any) {
      this.$emit("blur", event);
      this.$emit("change", this.selectedOfferingTypes);
    },
  },
});
</script>

<style lang="scss" scoped>
.offerings-selector__list--with-entries {
  margin-bottom: 12px;
}

.offerings-selector__title {
  display: block;
}

.offerings-selector__entry {
  display: flex;
  justify-content: space-between;
  padding: 7px 15px 8px 16px;
  margin-bottom: 12px;
  clear: both;

  font-size: 0.9333rem;
  text-transform: capitalize;

  background-color: $athens-gray;
  border-radius: 2px;

  &:last-child {
    margin-bottom: 0;
  }

  span {
    color: $gun-powder;
  }

  i {
    position: relative;
    top: 6px;
    width: 14px;
    height: 14px;

    cursor: pointer;
  }

  i:hover {
    background-image: url("#{$assetsPath}/img/icons/trash.svg");
  }
}

.offerings-edit-modal__select {
  display: block;

  :deep(.el-select__placeholder span) {
    color: $manatee;
    line-height: 1;
  }
}
</style>
