import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "form-grid form-grid--centered" }
const _hoisted_2 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DialogConfirmation = _resolveComponent("DialogConfirmation")!
  const _component_PxModalMessage = _resolveComponent("PxModalMessage")!
  const _component_NetworksSelector = _resolveComponent("NetworksSelector")!
  const _component_ElFormItem = _resolveComponent("ElFormItem")!
  const _component_ElForm = _resolveComponent("ElForm")!
  const _component_PxButton = _resolveComponent("PxButton")!
  const _component_ElDialog = _resolveComponent("ElDialog")!

  return (_openBlock(), _createBlock(_component_ElDialog, {
    ref: "modal",
    modelValue: _ctx.innerVisibility,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.innerVisibility) = $event)),
    "before-close": _ctx.beforeCloseHandler,
    title: _ctx.$t(`profile.networks.modal.title`),
    class: "networks-edit-modal el-dialog--px-10",
    width: "420px"
  }, {
    footer: _withCtx(() => [
      _createElementVNode("div", null, [
        _createVNode(_component_PxButton, {
          size: "small",
          type: "link",
          onClick: _ctx.beforeCloseHandler
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t("common.cancel")), 1)
          ]),
          _: 1
        }, 8, ["onClick"]),
        _createVNode(_component_PxButton, {
          loading: _ctx.isLoadingSubmission,
          type: "green",
          onClick: _ctx.onClickSave
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t("common.saveChanges")), 1)
          ]),
          _: 1
        }, 8, ["loading", "onClick"])
      ])
    ]),
    default: _withCtx(() => [
      _createVNode(_component_DialogConfirmation, { ref: "dialog-confirmation" }, null, 512),
      _createVNode(_component_PxModalMessage, { visible: _ctx.hasServerErrors }, null, 8, ["visible"]),
      _createVNode(_component_ElForm, {
        ref: "form",
        model: _ctx.form.data
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_ElFormItem, { prop: "networks" }, {
              default: _withCtx(() => [
                _createVNode(_component_NetworksSelector, {
                  ref: "networkSelector",
                  modelValue: _ctx.form.data.networks,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.form.data.networks) = $event)),
                  "label-copy": _ctx.$t(`profile.networks.modal.label`),
                  "placeholder-copy": 
              _ctx.$t(`supporters.signup.form.placeholders.network`)
            
                }, null, 8, ["modelValue", "label-copy", "placeholder-copy"]),
                _createElementVNode("span", {
                  class: "el-form-item__bottom-text el-form-item__tip",
                  innerHTML: _ctx.$t('supporters.signup.form.tips.network')
                }, null, 8, _hoisted_2)
              ]),
              _: 1
            })
          ])
        ]),
        _: 1
      }, 8, ["model"])
    ]),
    _: 1
  }, 8, ["modelValue", "before-close", "title"]))
}