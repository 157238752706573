import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, mergeProps as _mergeProps, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  ref: "table",
  class: "submissions-modal__table"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PxTableHeader = _resolveComponent("PxTableHeader")!
  const _component_SubmissionsTableRow = _resolveComponent("SubmissionsTableRow")!
  const _component_PxButton = _resolveComponent("PxButton")!
  const _component_ElDialog = _resolveComponent("ElDialog")!

  return (_openBlock(), _createBlock(_component_ElDialog, {
    modelValue: _ctx.innerVisibility,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.innerVisibility) = $event)),
    "append-to-body": true,
    title: _ctx.$t('common.components.submissionsModal.title', { company: _ctx.company }),
    class: "submissions-modal"
  }, {
    footer: _withCtx(() => [
      _createVNode(_component_PxButton, _mergeProps(_ctx.actionButtonProps, { onClick: _ctx.goToSubmissionsTrackerPage }), null, 16, ["onClick"])
    ]),
    default: _withCtx(() => [
      _createVNode(_component_PxTableHeader, {
        items: _ctx.tableHeaders,
        class: "submissions-modal__table-header"
      }, null, 8, ["items"]),
      _createElementVNode("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.mappedSubmissionsData, (submission, index) => {
          return (_openBlock(), _createBlock(_component_SubmissionsTableRow, {
            key: index,
            "is-modal-view": true,
            "submission-data": submission,
            type: "submitted-submissions"
          }, null, 8, ["submission-data"]))
        }), 128))
      ], 512)
    ]),
    _: 1
  }, 8, ["modelValue", "title"]))
}