import { resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-54be6eba"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["alt", "src"]
const _hoisted_2 = { class: "px-avatar__letter" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ContentPlaceholdersText = _resolveComponent("ContentPlaceholdersText")!
  const _component_ContentPlaceholders = _resolveComponent("ContentPlaceholders")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(`px-avatar px-avatar--${_ctx.type} px-avatar--${_ctx.styling}`),
    style: _normalizeStyle(_ctx.imageStyle)
  }, [
    (_ctx.loading)
      ? (_openBlock(), _createBlock(_component_ContentPlaceholders, { key: 0 }, {
          default: _withCtx(() => [
            _createVNode(_component_ContentPlaceholdersText, {
              lines: 1,
              style: _normalizeStyle(_ctx.imageStyle)
            }, null, 8, ["style"])
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    (_ctx.photo)
      ? (_openBlock(), _createElementBlock("img", {
          key: 1,
          alt: _ctx.name,
          src: _ctx.photo,
          style: _normalizeStyle(_ctx.imageStyle),
          class: "px-avatar__image"
        }, null, 12, _hoisted_1))
      : _createCommentVNode("", true),
    (!_ctx.photo && !_ctx.loading)
      ? (_openBlock(), _createElementBlock("div", {
          key: 2,
          style: _normalizeStyle(_ctx.emptyAvatarStyle),
          class: "px-avatar__empty"
        }, [
          _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.firstLetter), 1)
        ], 4))
      : _createCommentVNode("", true)
  ], 6))
}