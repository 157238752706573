import { ActionTree } from "vuex";

import { RootState } from "@/services/store/root-state";
import { getBaseName } from "@/services/store/utils/get-base-name";
import {
  ETeamMembersActions,
  ETeamMembersMutations,
  ITeamMembersState,
} from "@/modules/team-management/services/store/team-members/team-members.types";
import { ITeamMember } from "@/modules/team-management/services/data/team-members/team-members.interface";
import { teamMembersProvider } from "@/modules/team-management/services/data/team-members/team-members.provider";
import { IMatchingResponse } from "@/services/data/matching-responses/matching-response.interface";

export const actions: ActionTree<ITeamMembersState, RootState> = {
  /**
   * Get all members
   *
   * @param {*} { commit}
   * @return {*}  {(Promise<Array<ITeamMember> | null>)}
   */
  async [getBaseName(ETeamMembersActions.GET_VALUES)]({
    commit,
  }): Promise<Array<ITeamMember> | null> {
    commit(ETeamMembersMutations.SET_ERROR, null);
    commit(ETeamMembersMutations.SET_LOADING, true);

    let teamMembers: ITeamMember[] = [];

    try {
      teamMembers = await teamMembersProvider.list();

      commit(ETeamMembersMutations.SET_VALUES, teamMembers);
      commit(ETeamMembersMutations.SET_ERROR, null);
    } catch (error) {
      commit(ETeamMembersMutations.SET_VALUES, null);
      commit(ETeamMembersMutations.SET_ERROR, error);
    } finally {
      commit(ETeamMembersMutations.SET_LOADING, false);
    }

    return teamMembers;
  },

  /**
   * Add new list of members
   */
  async [getBaseName(ETeamMembersActions.CREATE_VALUES)](
    { commit },
    members: Array<Partial<ITeamMember>>,
  ): Promise<ITeamMember | null> {
    commit(ETeamMembersMutations.SET_ERROR, null);
    commit(ETeamMembersMutations.SET_LOADING, true);

    let teamMembers = null;

    members = JSON.parse(JSON.stringify(members));
    members = members.map((teamMember: Partial<ITeamMember>) => {
      if (!teamMember.id) {
        delete teamMember.id;
      }

      return {
        ...teamMember,
        responses: (teamMember.responses as Array<IMatchingResponse>).map(
          (response: IMatchingResponse) => {
            if (response.value && Object.keys(response.value).length === 0) {
              const newResponse = { ...response };
              delete newResponse.value;
              return newResponse;
            }

            return response;
          },
        ),
      };
    });

    try {
      teamMembers = await teamMembersProvider.create(members);
      commit(ETeamMembersMutations.SET_VALUES, teamMembers);
    } catch (error) {
      commit(ETeamMembersMutations.SET_VALUES, null);
      commit(ETeamMembersMutations.SET_ERROR, error);
    }

    commit(ETeamMembersMutations.SET_LOADING, false);

    return teamMembers;
  },
};
