<template>
  <ElDialog
    ref="modal"
    v-model="innerVisibility"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :show-close="true"
    :title="$t('affiliateProgram.components.signInModal.title')"
    :top="`10px`"
    class="sign-in-modal"
    data-testid="sign-in-modal"
    @close="onModalClose"
  >
    <PxModalMessage
      :title="modalError"
      :visible="hasServerErrors || hasInvalidAccount"
    />
    <div
      ref="formWrapper"
      :class="{ 'scroll-at-bottom': formWrapperScrollAtEnd }"
      class="sign-in-modal__form-wrapper"
      @scroll="checkFormWrapperScroll"
    >
      <ElForm
        ref="form"
        :model="data"
        :rules="rules"
        class="sign-in-modal__form"
      >
        <ElFormItem
          :class="{ 'is-error': showEmailAsError }"
          :label="
            $t('affiliateProgram.components.signInModal.form.fields.email')
          "
          prop="email"
        >
          <ElInput
            v-model="data.email"
            :placeholder="
              $t('affiliateProgram.components.signInModal.form.fields.email')
            "
            data-testid="sign-in-modal__email-input"
          />
        </ElFormItem>
        <ElFormItem
          :error="errors.getMessage('non_field_errors', 'email_not_verified')"
          :label="
            $t('affiliateProgram.components.signInModal.form.fields.password')
          "
          prop="password"
        >
          <PxInputPassword
            v-model="data.password"
            :placeholder="
              $t('affiliateProgram.components.signInModal.form.fields.password')
            "
            data-testid="sign-in-modal__password-input"
          />
        </ElFormItem>
        <ElFormItem>
          <div class="sign-in-modal__remember-wrapper">
            <ElCheckbox v-model="data.remember" class="sign-in-modal__remember">
              {{
                $t(
                  "affiliateProgram.components.signInModal.form.fields.remember",
                )
              }}
            </ElCheckbox>
            <PxButton
              class="el-button--link-blue sign-in-modal__forget"
              size="small"
              type="link"
              @click="onClickPasswordRecover"
            >
              {{
                $t("affiliateProgram.components.signInModal.forgetPasswordLink")
              }}
            </PxButton>
          </div>
        </ElFormItem>
      </ElForm>
      <PxButton
        :disabled="isLoadingButtonDisabled"
        :loading="loading"
        class="el-button--block sign-in-modal__login-button"
        type="primary"
        @click="onClickLogin"
      >
        {{ $t("affiliateProgram.components.signInModal.form.submitButton") }}
      </PxButton>
      <i18n-t
        class="sign-in-modal__no-account"
        keypath="affiliateProgram.components.signInModal.noAccount"
        tag="span"
      >
        <template #tellMore>
          <span
            class="sign-in-modal__tell-more-link"
            data-testid="sign-in-modal__tell-more-link"
            @click="gotoRegisterModal"
          >
            {{ $t("affiliateProgram.components.signInModal.tellMore") }}
          </span>
        </template>
      </i18n-t>
    </div>
  </ElDialog>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import SignInModalMixin from "@/mixins/sign-in-modal.mixin";

export default defineComponent({
  name: "SignInModal",

  mixins: [SignInModalMixin],
});
</script>

<style lang="scss">
.sign-in-modal {
  &.el-dialog {
    width: 468px;
    margin: 10px auto;

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .el-dialog__body {
    padding: 12px 30px 30px;

    @include breakpoint-down(sm) {
      max-height: calc(100vh - 100px);
      overflow: auto;
    }
  }

  .el-alert.el-alert--error {
    display: grid;
    grid-template-columns: 14px auto;
  }

  &__remember-wrapper {
    display: flex;
    flex-wrap: wrap;
    margin: 20px 2px 0;

    & > * {
      flex: 1 0 50%;
      padding: 0;
    }
  }

  &__forget span {
    @include breakpoint-down(sm) {
      display: inline-block;
      text-align: left;
    }

    @include breakpoint-up(md) {
      transform: translate(30px, -5px);
    }
  }

  &__login-button {
    margin: 25px 0 16px;
  }

  &__no-account,
  &__tell-more-link {
    font-size: 14px;
    line-height: 19px;
    color: $ebony-clay;
  }

  &__no-account {
    display: inline-block;
    width: 100%;
    text-align: center;
  }

  &__tell-more-link {
    @include grotesk(semiBold);

    text-decoration: underline;
    cursor: pointer;
  }
}
</style>
