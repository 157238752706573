import { Module } from "vuex";
import { make } from "vuex-pathify";

import { RootState } from "@/services/store/root-state";

import { IProfileLatestAssessmentState } from "./profile-latest-assessment-types";
import { actions } from "./profile-latest-assessment-actions";

const state: IProfileLatestAssessmentState = {
  loading: false,
  error: null,
  data: null,
};

export const profileLatestAssessment: Module<
  IProfileLatestAssessmentState,
  RootState
> = {
  namespaced: true,
  state,
  actions,
  mutations: make.mutations(state),
  getters: make.getters(state),
};
