<template>
  <div class="owned-affiliate-submissions-view page-body">
    <div class="owned-affiliate-submissions-view__header">
      <PxPageHeader
        :subtitle="headerCopy.subtitle"
        :title="headerCopy.title"
        @back-button-clicked="goToPreviousPage"
      >
        <template #action>
          <div class="owned-affiliate-submissions-view__filter">
            <ElInput
              v-model="submissionFilter"
              :disabled="noSubmissions"
              :placeholder="$t('ownedAffiliatesSubmissions.search')"
              clearable
              @input="filterSubmissions"
            >
              <template #prefix>
                <PxIcon :size="24" name="magnifier" />
              </template>
            </ElInput>
          </div>
        </template>
      </PxPageHeader>
    </div>
    <div class="page-container owned-affiliate-submissions-view__panels">
      <SubmissionsPanel
        :is-loading="dataLoading"
        :is-user-submissions-view="false"
        :show-empty-results-copy="!!draftSubmissions.length"
        :submissions-data="filteredDraftSubmissions"
        type="draft-submissions"
      />
      <SubmissionsPanel
        :is-loading="dataLoading"
        :is-user-submissions-view="false"
        :show-empty-results-copy="!!submittedSubmissions.length"
        :submissions-data="filteredSubmittedSubmissions"
        type="submitted-submissions"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import PxPageHeader from "@/components/px-page-header/px-page-header.vue";
import SubmissionsPanel from "@/modules/submissions/components/submissions-panel/submissions-panel.vue";

import { supporterOwnedAffiliatesSubmissionsProvider } from "@/modules/submissions/services/data/submissions/supporter-owned-affiliates-submissions.provider";
import {
  IAffiliateSubmissions,
  IAffiliateSubmission,
} from "@/modules/submissions/services/data/submissions/submissions.interface";
import cloneDeep from "lodash/cloneDeep";

export default defineComponent({
  name: "OwnedAffiliatesSubmissionsView",

  components: {
    PxPageHeader,
    SubmissionsPanel,
  },

  data() {
    return {
      submissions: {} as IAffiliateSubmissions | any,
      dataLoading: false,
      submissionFilter: "",
      filteredDraftSubmissions: [] as Array<IAffiliateSubmission>,
      filteredSubmittedSubmissions: [] as Array<IAffiliateSubmission>,
    };
  },

  computed: {
    headerCopy() {
      return this.$tm("ownedAffiliatesSubmissions.header") as {
        title: string;
        subtitle: string;
      };
    },

    draftSubmissions(): Array<IAffiliateSubmission> {
      return this.submissions?.drafts || [];
    },

    submittedSubmissions(): Array<IAffiliateSubmission> {
      return this.submissions?.submitted || [];
    },

    noSubmissions(): boolean {
      return (
        !this.submissions?.drafts?.length &&
        !this.submissions?.submitted?.length
      );
    },
  },

  watch: {
    submissionFilter(newFilter) {
      this.$router.replace({
        ...this.$router.currentRoute.value,
        query: newFilter ? { company: this.submissionFilter } : {},
      });
    },
  },

  async created() {
    this.dataLoading = true;
    this.submissions = await supporterOwnedAffiliatesSubmissionsProvider.list();
    this.filteredDraftSubmissions = cloneDeep(this.draftSubmissions);
    this.filteredSubmittedSubmissions = cloneDeep(this.submittedSubmissions);
    this.dataLoading = false;

    this.submissionFilter = this.$route.query.company as string;
    this.filterSubmissions();
  },

  methods: {
    filterSubmissions() {
      const value = this.submissionFilter.trim();

      if (value.length) {
        this.filteredDraftSubmissions = this.draftSubmissions.filter(
          (submission) => {
            return this.checkAffiliateNameAndOwners(value, submission);
          },
        );

        this.filteredSubmittedSubmissions = this.submittedSubmissions.filter(
          (submission) => {
            return this.checkAffiliateNameAndOwners(value, submission);
          },
        );
      } else {
        this.filteredDraftSubmissions = cloneDeep(this.draftSubmissions);
        this.filteredSubmittedSubmissions = cloneDeep(
          this.submittedSubmissions,
        );
      }
    },

    checkAffiliateNameAndOwners(
      value: string,
      submission: IAffiliateSubmission,
    ) {
      return (
        submission.affiliate.name.toLowerCase().includes(value.toLowerCase()) ||
        submission.owners?.some((owner) =>
          owner.name.toLowerCase().includes(value.toLowerCase()),
        )
      );
    },

    goToPreviousPage() {
      return this.$router.back();
    },
  },
});
</script>

<style lang="scss" scoped>
.owned-affiliate-submissions-view {
  &__header {
    border-bottom: 1px solid $turkish-angora;
    background-color: $white;

    :deep() .px-page-header {
      @include breakpoint-down(md) {
        padding: 36px 30px 32px;
      }
    }
  }

  &__filter {
    position: relative;
    bottom: 2px;
    margin-right: 46px;

    width: 310px;

    :deep() .el-input--prefix .el-input__inner {
      letter-spacing: -0.33px;
      padding: 4px 0 4px 48px;
    }
  }

  &__filter :deep() .el-input__suffix {
    top: 14px;
    right: 15px;

    .el-input__suffix-inner {
      border: none;
    }

    .el-input__icon {
      width: 13px;
      height: 13px;
      cursor: pointer;
      background: transparent url("#{$assetsPath}/img/icons/cross.svg")
        no-repeat;
      background-size: contain;
      transition: $--filter-transition-base;

      &:hover {
        filter: brightness(50%);
      }
    }
  }
}
</style>
