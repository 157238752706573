import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "entrepreneur-sign-up-modal__notice" }
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = ["href"]
const _hoisted_4 = ["href"]
const _hoisted_5 = { class: "entrepreneur-sign-up-modal__button-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PxModalMessage = _resolveComponent("PxModalMessage")!
  const _component_i18n_t = _resolveComponent("i18n-t")!
  const _component_ElInput = _resolveComponent("ElInput")!
  const _component_ElFormItem = _resolveComponent("ElFormItem")!
  const _component_PxIcon = _resolveComponent("PxIcon")!
  const _component_PxInputPlaces = _resolveComponent("PxInputPlaces")!
  const _component_SectorsSelector = _resolveComponent("SectorsSelector")!
  const _component_ElCheckbox = _resolveComponent("ElCheckbox")!
  const _component_ElForm = _resolveComponent("ElForm")!
  const _component_PxButton = _resolveComponent("PxButton")!
  const _component_ElDialog = _resolveComponent("ElDialog")!

  return (_openBlock(), _createBlock(_component_ElDialog, {
    ref: "modal",
    modelValue: _ctx.innerVisibility,
    "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.innerVisibility) = $event)),
    "close-on-click-modal": false,
    "close-on-press-escape": false,
    "show-close": true,
    title: _ctx.$t(`affiliateProgram.userDetailsModal.title`),
    top: "10px",
    class: "entrepreneur-sign-up-modal",
    onClose: _ctx.onModalClose
  }, {
    footer: _withCtx(() => [
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_PxButton, {
          disabled: _ctx.isSubmissionDisabled,
          loading: _ctx.isLoadingSubmission,
          type: "green",
          onClick: _ctx.onSubmitClick
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t("affiliateProgram.userDetailsModal.submitBtn")), 1)
          ]),
          _: 1
        }, 8, ["disabled", "loading", "onClick"])
      ])
    ]),
    default: _withCtx(() => [
      _createVNode(_component_PxModalMessage, { visible: _ctx.hasServerErrors }, null, 8, ["visible"]),
      _createVNode(_component_i18n_t, {
        class: "entrepreneur-sign-up-modal__sign-in-panel",
        keypath: "affiliateProgram.userDetailsModal.existingAccount",
        tag: "div"
      }, {
        signIn: _withCtx(() => [
          _createElementVNode("span", {
            class: "entrepreneur-sign-up-modal__sign-in-panel-link",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.gotoSignInModal && _ctx.gotoSignInModal(...args)))
          }, _toDisplayString(_ctx.$t("affiliateProgram.userDetailsModal.signIn")), 1)
        ]),
        _: 1
      }),
      _createElementVNode("div", {
        ref: "formWrapper",
        class: _normalizeClass([{ 'scroll-at-bottom': _ctx.formWrapperScrollAtEnd }, "entrepreneur-sign-up-modal__form-wrapper"]),
        onScroll: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => (_ctx.checkFormWrapperScroll && _ctx.checkFormWrapperScroll(...args)))
      }, [
        _createVNode(_component_ElForm, {
          ref: "form",
          model: _ctx.form,
          rules: _ctx.rules,
          onValidate: _ctx.updateSubmitState
        }, {
          default: _withCtx(() => [
            _createVNode(_component_ElFormItem, {
              label: _ctx.$t('affiliateProgram.userDetailsModal.fields.name'),
              prop: "company.name"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ElInput, {
                  modelValue: _ctx.form.company.name,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.form.company.name) = $event)),
                  placeholder: _ctx.$t('affiliateProgram.userDetailsModal.fields.name')
                }, null, 8, ["modelValue", "placeholder"])
              ]),
              _: 1
            }, 8, ["label"]),
            _createVNode(_component_ElFormItem, {
              error: _ctx.errors.getMessage('email') || '',
              label: _ctx.$t('affiliateProgram.userDetailsModal.fields.email'),
              prop: "email"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ElInput, {
                  modelValue: _ctx.form.email,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.form.email) = $event)),
                  placeholder: _ctx.$t('affiliateProgram.userDetailsModal.fields.email'),
                  type: "email"
                }, null, 8, ["modelValue", "placeholder"])
              ]),
              _: 1
            }, 8, ["error", "label"]),
            _createVNode(_component_ElFormItem, {
              label: _ctx.$t('authentication.signup.form.fields.website'),
              prop: "company.website"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ElInput, {
                  modelValue: _ctx.form.company.website,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.form.company.website) = $event)),
                  modelModifiers: { trim: true },
                  placeholder: 
              _ctx.$t('authentication.signup.form.fields.websitePlaceholder')
            
                }, {
                  prefix: _withCtx(() => [
                    _createVNode(_component_PxIcon, {
                      size: 24,
                      name: "website"
                    })
                  ]),
                  _: 1
                }, 8, ["modelValue", "placeholder"])
              ]),
              _: 1
            }, 8, ["label"]),
            _createVNode(_component_ElFormItem, {
              label: _ctx.$t('affiliateProgram.userDetailsModal.fields.location'),
              prop: "company.location"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_PxInputPlaces, {
                  id: "companyInfoLocation",
                  modelValue: _ctx.form.company.location,
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.form.company.location) = $event)),
                  placeholder: 
              _ctx.$t('affiliateProgram.userDetailsModal.fields.location')
            ,
                  "use-geolocation": true
                }, null, 8, ["modelValue", "placeholder"])
              ]),
              _: 1
            }, 8, ["label"]),
            _createVNode(_component_ElFormItem, { prop: "company.sectors" }, {
              default: _withCtx(() => [
                _createVNode(_component_SectorsSelector, {
                  modelValue: _ctx.form.company.sectors,
                  "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.form.company.sectors) = $event)),
                  onChange: _ctx.handleSectorsChange
                }, null, 8, ["modelValue", "onChange"])
              ]),
              _: 1
            }),
            _createElementVNode("div", _hoisted_1, [
              _createVNode(_component_PxIcon, {
                size: 14,
                name: "information--light-gray"
              }),
              _createElementVNode("p", {
                innerHTML: 
              _ctx.$t('affiliateProgram.userDetailsModal.notice', { affiliateName: _ctx.affiliateName })
            
              }, null, 8, _hoisted_2)
            ]),
            _createVNode(_component_ElFormItem, {
              "show-message": false,
              prop: "terms",
              required: ""
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ElCheckbox, {
                  modelValue: _ctx.form.terms,
                  "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.form.terms) = $event)),
                  class: "entrepreneur-sign-up-modal__selector"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_i18n_t, {
                      class: "entrepreneur-sign-up-modal__selector-text",
                      keypath: "affiliateProgram.userDetailsModal.terms",
                      tag: "p"
                    }, {
                      terms: _withCtx(() => [
                        _createElementVNode("a", {
                          href: _ctx.$options.static.termsOfUseUrl,
                          class: "text--nice-blue",
                          target: "_blank"
                        }, _toDisplayString(_ctx.$t("affiliateProgram.userDetailsModal.termsOfUse")), 9, _hoisted_3)
                      ]),
                      privacy: _withCtx(() => [
                        _createElementVNode("a", {
                          href: _ctx.$options.static.privacyPolicyUrl,
                          class: "text--nice-blue",
                          target: "_blank"
                        }, _toDisplayString(_ctx.$t("affiliateProgram.userDetailsModal.privacyPolicy")), 9, _hoisted_4)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["modelValue"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["model", "rules", "onValidate"])
      ], 34)
    ]),
    _: 1
  }, 8, ["modelValue", "title", "onClose"]))
}