<template>
  <ElDialog
    ref="modal"
    v-model="innerVisibility"
    class="el-dialog--no-header range-levels-modal"
    @closed="closedModalHandler"
  >
    <div v-if="submissionSuccessful" class="range-levels-modal__success">
      <PxIcon
        :size="90"
        class="range-levels-modal__success-icon"
        name="email-sent--orange"
      />
      <h3 class="range-levels-modal__success-title">
        {{ $t("supporters.assessment.rangeLevelsModal.success.title") }}
      </h3>
      <p
        class="range-levels-modal__success-message"
        v-html="
          $t(`supporters.assessment.rangeLevelsModal.success.message`, {
            email: fields.email,
          })
        "
      />
    </div>
    <template v-else>
      <div class="range-levels-modal__header">
        <h3 class="range-levels-modal__title">
          {{ $t("supporters.assessment.rangeLevelsModal.title") }}
        </h3>
        <p class="range-levels-modal__subtitle">
          {{ $t("supporters.assessment.rangeLevelsModal.subtitle") }}
        </p>
      </div>
      <ElForm
        ref="form"
        :model="fields"
        :rules="rules"
        class="viral-level-range-form"
        @validate="updateSubmitState"
      >
        <ElFormItem
          :label="$t('supporters.assessment.rangeLevelsModal.labels.range')"
          prop="viral_level_range"
        >
          <ElInput
            v-model="fields.viral_level_range"
            :placeholder="
              $t('supporters.assessment.rangeLevelsModal.placeholders.range')
            "
            type="text"
          />
        </ElFormItem>
        <ElFormItem
          :label="$t('supporters.assessment.rangeLevelsModal.labels.email')"
          prop="email"
        >
          <ElInput
            v-model="fields.email"
            :placeholder="
              $t('supporters.assessment.rangeLevelsModal.placeholders.email')
            "
            type="text"
          />
        </ElFormItem>
      </ElForm>
      <div class="range-levels-modal__notice">
        <PxIcon :size="14" name="information--light-gray" />
        <span v-html="$t(`supporters.assessment.rangeLevelsModal.notice`)" />
      </div>
    </template>
    <template #footer>
      <template v-if="!submissionSuccessful">
        <PxButton
          :disabled="isLoading"
          class="range-levels-modal__cancel-button"
          type="link"
          @click="cancelClickHandler"
        >
          {{ $t("common.cancel") }}
        </PxButton>
        <PxButton
          :disabled="isSubmissionDisabled"
          :loading="isLoading"
          class="range-levels-modal__submit-button"
          type="green"
          @click="sendClickHandler"
        >
          {{ $t("common.send") }}
        </PxButton>
      </template>
    </template>
  </ElDialog>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import {
  allFormFieldsValid,
  generateEmailValidator,
  generateRequiredValidator,
} from "@/services/errors/validator-generators";

import { viralLevelRangeProvider } from "@/modules/supporters/services/data/viral-level-range/viral-level-range.provider";

export default defineComponent({
  name: "RangeLevelsModal",

  props: {
    visibility: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      isLoading: false,
      hasServerError: false,
      innerVisibility: false,
      isSubmissionDisabled: true,
      submissionCanceled: false,
      submissionSuccessful: false,

      fields: {
        viral_level_range: "",
        email: "",
      },

      rules: {
        viral_level_range: generateRequiredValidator(
          this,
          "supporters.assessment.rangeLevelsModal.labels.range",
        ),
        email: generateEmailValidator(
          this,
          "supporters.assessment.rangeLevelsModal.fields.email",
          true,
        ),
      },
    };
  },

  computed: {
    formRef(): HTMLElement {
      return this.$refs.form as HTMLElement;
    },
  },

  watch: {
    visibility(newVal, oldVal) {
      if (newVal === oldVal) {
        return;
      }

      this.innerVisibility = newVal;
    },

    innerVisibility(newVal) {
      this.$emit("update:visibility", newVal);
    },
  },

  methods: {
    updateSubmitState() {
      this.isSubmissionDisabled = !allFormFieldsValid(this.formRef, this.rules);
    },

    closeModal() {
      this.$emit("update:visibility", false);
    },

    resetForm() {
      const form: any = this.$refs.form || null;

      if (form) {
        form.resetFields();
      } else if (this.$options.data !== undefined) {
        const initialFieldsData = (this.$options.data as any).call(this).fields;
        // Override directly fields data with initial values when
        // form element isn't immediatly available while the modal it's still opening
        this.fields = {
          ...this.fields,
          ...initialFieldsData,
        };
      }
    },

    cancelClickHandler() {
      this.submissionCanceled = true;
      this.closeModal();
    },

    async sendClickHandler() {
      this.isLoading = true;
      this.hasServerError = false;

      try {
        await viralLevelRangeProvider.create(this.fields);
        this.submissionSuccessful = true;
      } catch (error) {
        this.hasServerError = true;

        // TODO: Use PxModalMessage instead of Element Message
        // Shows message under Navbar, due to message global context,
        // which will be shown behind modal overlay background
        this.$message({
          message: this.$t("common.errors.global.alertTitle"),
          type: "error",
          customClass: "is-full",
        });

        return;
      } finally {
        this.isLoading = false;
      }
    },

    closedModalHandler() {
      if (this.submissionCanceled || this.submissionSuccessful) {
        this.submissionCanceled = false;
        this.submissionSuccessful = false;

        this.resetForm();
      }
    },
  },
});
</script>

<style lang="scss">
.range-levels-modal.el-dialog--no-header.el-dialog {
  width: 492px;
  margin-top: 15vh !important;

  .el-dialog__header {
    padding-bottom: 10px;
  }

  .el-dialog__body {
    padding-bottom: 17px;
  }
}

.range-levels-modal__header {
  margin-bottom: 6px;
  color: $ebony-clay;
  text-align: center;
}

.range-levels-modal__title {
  @include grotesk(semiBold);

  margin-bottom: 12px;
  font-size: 20px;
  line-height: 28px;
}

.range-levels-modal__subtitle {
  @include grotesk(regular);

  padding: 1px 17px 0 19px;

  font-size: 15px;
  line-height: 22px;
  letter-spacing: -0.4px;
}

.viral-level-range-form {
  max-width: 310px;
  padding: 15px 0;
  margin: auto;
}

.range-levels-modal__notice {
  display: flex;
  flex-flow: row;
  justify-content: center;
  margin-top: 7px;

  img {
    position: relative;
    top: 1px;
    margin-right: 7px;
  }

  span {
    @include grotesk(regular, italic);

    flex-grow: 1;
    letter-spacing: -0.1px;
  }

  strong {
    @include grotesk(semiBold, italic);

    letter-spacing: 0.05px;
  }

  span,
  strong {
    font-size: 13px;
    line-height: 18px;
    white-space: pre-wrap;
  }
}

.range-levels-modal__submit-button {
  min-width: 64px;

  span {
    font-size: 13px;
  }

  &.el-button {
    margin-left: 10px;
  }
}

.range-levels-modal__cancel-button span {
  font-size: 14px;
}

.range-levels-modal__success {
  padding: 10px 0 20px;
  text-align: center;

  &-icon {
    margin-bottom: 10px;
  }

  &-title {
    @include grotesk(semiBold);

    margin-bottom: 19px;
    font-size: 22px;
    line-height: 40px;
    color: $atomic-tangerine;
  }

  &-message {
    font-size: 15px;
    line-height: 24px;
    color: $ebony-clay;
    letter-spacing: -0.2px;
    white-space: pre-wrap;
  }
}
</style>
