<template>
  <div class="supporters-view__container">
    <LangSwitcher />
    <FormPresentationLayout
      :class="{ 'supporters-view--simplified': !withTeamSection }"
      :content-description="viewContentDescription"
      :content-title="affiliateName"
      :description-image-link="imageLink"
      :description-image-title="viewCopy.imageTitle"
      :has-description-background-image="true"
      :has-return-button="hasPreviousPage"
      @previous-page="onReturnButtonClick"
    >
      <template #content>
        <div class="supporter-program__container">
          <div class="supporter-program__step-wrapper">
            <div
              v-for="(step, key) in stepsToDisplay"
              :key="`criteria-description-step--${key}`"
              class="supporter-program__step-container"
            >
              <div class="supporter-program__step-number">
                {{ key + 1 }}
              </div>
              <div class="supporter-program__step-text" v-html="step" />
            </div>
          </div>
          <div class="supporter-program__cta">
            <PxButton
              class="supporter-program__main-cta"
              type="green"
              @click="onNextButtonClick"
            >
              {{ ctaCopy.start }}
            </PxButton>
          </div>
          <div class="supporter-program__tip">
            {{ viewCopy.tip }}
          </div>
        </div>
      </template>
    </FormPresentationLayout>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import SupportersFlowMixin from "@/modules/supporters/mixins/supporters-flow.mixin";
import FormPresentationLayout from "@/modules/supporters/components/form-presentation-layout/form-presentation-layout.vue";
import LangSwitcher from "@/modules/common/components/lang-switcher/lang-switcher.vue";

import { AffiliateState } from "@/modules/supporters/services/store/affiliate/affiliate.module";

export default defineComponent({
  name: "StartingPointAffiliate",

  components: {
    FormPresentationLayout,
    LangSwitcher,
  },

  mixins: [SupportersFlowMixin],

  computed: {
    imageLink() {
      return this.$tm("supporters.view.criteriaStartPage.imageLink") as {
        original: string;
        retina: string;
      };
    },

    affiliateName(): string {
      return this.$store.getters[AffiliateState.Getter.VALUE]?.name || "";
    },

    isLogged(): boolean {
      return !!this.$store.get("auth/user");
    },

    withTeamSection(): boolean {
      return (
        (this.$store.getters[AffiliateState.Getter.VALUE]?.show_team_section &&
          this.$features.isEnabled("teamMembers")) ||
        false
      );
    },

    viewCopy() {
      return this.$tm("supporters.view.startPageAffiliate") as {
        contentDescription: string;
        imageTitle: string;
        steps: string[];
        tip: string;
      };
    },

    viewCopySteps() {
      return this.viewCopy.steps;
    },

    viewCopyStepsWithoutTeamSection(): Array<string> {
      return this.viewCopySteps.filter((step, index) => index !== 2);
    },

    stepsToDisplay(): Array<string> {
      return !this.withTeamSection
        ? this.viewCopyStepsWithoutTeamSection
        : this.viewCopySteps;
    },

    viewContentDescription(): string {
      return this.$t("supporters.view.startPageAffiliate.contentDescription", {
        affiliate: this.affiliateName,
      }) as string;
    },
  },
});
</script>

<style lang="scss" scoped>
.supporters-view__container :deep() .form-presentation-layout {
  &__content-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px 0;
  }

  &__content-header {
    width: 100%;
    margin-top: 7px;
    margin-bottom: 25px;
  }

  &__content-title {
    margin-bottom: 7px;
    letter-spacing: -0.3px;

    @include breakpoint-down(md) {
      font-size: 1.8rem;
      line-height: 1.2;
    }
  }

  &__content-description {
    line-height: 28px;
    color: $ebony-clay;
    letter-spacing: -0.34px;
  }

  &__content-container {
    width: 70%;
    max-width: 445px;
  }

  &__description-container {
    max-width: 100%;
  }

  &__description-wrapper {
    position: relative;
    top: 0;
    width: 100%;
  }

  &__description-wrapper--with-background::before {
    background-position: top;
  }

  &__content-header-wrapper {
    padding: 0;
  }
}
.supporters-view__container :deep() {
  @include breakpoint-down(md) {
    .form-presentation-layout__description-wrapper--with-background {
      display: none;
    }

    .form-presentation-layout__container {
      display: grid;
      grid-template-columns: 100%;
    }

    .form-presentation-layout__content-wrapper {
      display: flex;
      align-items: center;
      padding: 70px 0;
    }
  }
}

.supporter-program {
  &__container {
    display: grid;
    grid-gap: 44px;
    width: 100%;
  }

  &__step-wrapper {
    display: grid;
    grid-gap: 16px;
  }

  &__step-container {
    display: grid;
    grid-template-columns: 22px 1fr;
    grid-gap: 10px;
  }

  &__step-number {
    @include grotesk(bold);

    width: 22px;
    height: 22px;
    line-height: 19px;
    color: $white;
    text-align: center;
    font-size: 16px;
    background: linear-gradient(to bottom, #bfc2cf, $manatee);
    border-radius: 50%;
    transform: translateY(3px);
  }

  &__step-text {
    font-size: 16px;
    line-height: 28px;
    color: $manatee;
    letter-spacing: -0.23px;

    :deep(b) {
      font-weight: 600;
    }
  }

  &__cta {
    display: flex;
  }

  &__cta button:first-child {
    width: 100%;
    max-width: 100%;
    height: 42px;
    margin-right: 16px;
  }

  &__cta :deep(.el-button) {
    max-width: 50%;
  }

  &__main-cta {
    padding-right: 26px;
    padding-left: 26px;
  }

  &__tip {
    position: relative;
    bottom: 12px;

    font-size: 16px;
    font-style: italic;
    line-height: 1.75;
    letter-spacing: -0.12px;
  }
}

.supporters-view {
  &--simplified {
    .supporter-program__container {
      gap: 40px;
    }

    .supporter-program__cta {
      margin-bottom: 12px;
    }

    :deep(.form-presentation-layout__content-header) {
      margin-bottom: 40px;
    }
  }
}
</style>
