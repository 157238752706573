<template>
  <ElDialog
    ref="modal"
    v-model="innerVisibility"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :show-close="isSkippable"
    :title="componentCopy.title"
    width="468px"
    class="supporters-sign-in-modal"
    data-testid="sign-in-modal"
    @close="onModalClose"
  >
    <PxModalMessage
      :title="modalError"
      :visible="hasServerErrors || hasInvalidAccount"
    />
    <i18n-t
      class="supporters-sign-in-modal__sign-up-panel"
      keypath="supporters.component.signInModal.noAccount"
      tag="div"
    >
      <template #tellMore>
        <span
          class="supporters-sign-in-modal__sign-up-panel-link"
          data-testid="supporters-sign-in-modal__sign-up-panel-link"
          @click="gotoRegisterModal"
        >
          {{ componentCopy.tellMore }}
        </span>
      </template>
    </i18n-t>
    <div
      ref="formWrapper"
      :class="{ 'scroll-at-bottom': formWrapperScrollAtEnd }"
      class="supporters-sign-in-modal__form-wrapper"
      @scroll="checkFormWrapperScroll"
    >
      <ElForm
        ref="form"
        :model="data"
        :rules="rules"
        class="supporters-sign-in-modal__form"
      >
        <ElFormItem
          :class="{ 'is-error': showEmailAsError }"
          :label="componentCopy.form.fields.email"
          prop="email"
        >
          <ElInput
            v-model="data.email"
            :disabled="hasInvalidAccount"
            :placeholder="componentCopy.form.fields.email"
            data-testid="supporters-sign-in-modal__email-input"
          />
        </ElFormItem>
        <ElFormItem
          :error="
            errors.getMessage('non_field_errors', 'email_not_verified') || ''
          "
          :label="componentCopy.form.fields.password"
          prop="password"
        >
          <PxInputPassword
            v-model="data.password"
            :disabled="hasInvalidAccount"
            :placeholder="componentCopy.form.fields.password"
            data-testid="supporters-sign-in-modal__password-input"
          />
        </ElFormItem>
        <ElFormItem>
          <div class="supporters-sign-in-modal__remember-wrapper">
            <ElCheckbox
              v-model="data.remember"
              class="supporters-sign-in-modal__remember"
            >
              {{ componentCopy.form.fields.remember }}
            </ElCheckbox>
            <PxButton
              class="el-button--link-blue supporters-sign-in-modal__forget"
              size="small"
              type="link"
              @click="onClickPasswordRecover"
            >
              {{ componentCopy.forgetPasswordLink }}
            </PxButton>
          </div>
        </ElFormItem>
      </ElForm>
      <PxButton
        :disabled="isLoadingButtonDisabled"
        :loading="loading"
        class="el-button--block supporters-sign-in-modal__login-button"
        type="primary"
        @click="onClickLogin"
      >
        {{ componentCopy.form.submitButton }}
      </PxButton>
    </div>
  </ElDialog>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import SignInModalMixin from "@/mixins/sign-in-modal.mixin";

export default defineComponent({
  name: "SupporterSignInModal",

  mixins: [SignInModalMixin],
});
</script>

<style lang="scss">
.supporters-sign-in-modal {
  &__form-wrapper {
    padding: 12px 30px 30px;
  }

  .el-dialog__body {
    padding: 0;
    overflow: hidden;

    @include breakpoint-down(sm) {
      max-height: calc(100vh - 100px);
      overflow: auto;
    }
  }

  .el-alert.el-alert--error {
    display: grid;
    grid-template-columns: 14px auto;
  }

  &__sign-up-panel {
    min-height: 32px;
    padding: 5px 30px;
    font-size: 13px;
    line-height: 20px;
    color: $ebony-clay;
    background-color: $athens-gray;
    transform: translateY(-4px);

    @include breakpoint-down(sm) {
      padding: 5px 15px;
    }

    &-link {
      @include grotesk(semiBold);

      font-size: 13px;
      text-decoration: underline;
      cursor: pointer;
    }
  }

  &__remember-wrapper {
    display: flex;
    flex-wrap: wrap;
    margin: 20px 2px 0;

    & > * {
      flex: 1 0 50%;
      padding: 0;
    }
  }

  &__forget span {
    @include breakpoint-down(sm) {
      display: inline-block;
      text-align: left;
    }

    @include breakpoint-up(md) {
      transform: translate(30px, -5px);
    }
  }

  &__login-button {
    margin: 25px 0 16px;
  }

  &__no-account,
  &__tell-more-link {
    font-size: 14px;
    line-height: 19px;
    color: $ebony-clay;
  }

  &__no-account {
    display: inline-block;
    width: 100%;
    text-align: center;
  }

  &__tell-more-link {
    @include grotesk(semiBold);

    text-decoration: underline;
    cursor: pointer;
  }
}
</style>
