import {
  GenericProvider,
  EProviderFeatures,
  Provider,
} from "@/services/data/generic-provider";
import { IPasswordRecover } from "@/modules/authentication/services/data/password-recover/password-recover.interface";
import { baseAPIUrl } from "@/services/utils/utils";

/**
 * Provider used to perform a password recovery request.
 */
@Provider(`${baseAPIUrl}/user/password/reset/send`, [EProviderFeatures.CREATE])
class PasswordRecoverProvider extends GenericProvider<any, IPasswordRecover> {}

export const passwordRecoverProvider = new PasswordRecoverProvider();
