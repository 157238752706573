import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-04bd836d"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "auth-signup__step signup-step-supporter" }
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = { class: "el-form__cta" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MultiSelector = _resolveComponent("MultiSelector")!
  const _component_ElFormItem = _resolveComponent("ElFormItem")!
  const _component_ElRadio = _resolveComponent("ElRadio")!
  const _component_PxRadioGroup = _resolveComponent("PxRadioGroup")!
  const _component_NetworksSelector = _resolveComponent("NetworksSelector")!
  const _component_PxButton = _resolveComponent("PxButton")!
  const _component_ElForm = _resolveComponent("ElForm")!
  const _component_PxPanel = _resolveComponent("PxPanel")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_PxPanel, { class: "px-panel--compact" }, {
      header: _withCtx(() => [
        _createElementVNode("h2", {
          class: "px-panel__title",
          innerHTML: _ctx.$t('supporters.signup.form.supporterInterests')
        }, null, 8, _hoisted_2)
      ]),
      default: _withCtx(() => [
        _createVNode(_component_ElForm, {
          ref: "signupForm",
          model: _ctx.data,
          rules: _ctx.rules,
          class: "auth-signup__form",
          onValidate: _ctx.updateSubmitState
        }, {
          default: _withCtx(() => [
            _createVNode(_component_ElFormItem, { prop: "sectors" }, {
              default: _withCtx(() => [
                _createVNode(_component_MultiSelector, {
                  modelValue: _ctx.data.sectors,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.data.sectors) = $event)),
                  "fetch-on-created": true,
                  "remote-method": _ctx.searchSectors,
                  "subtext-label-list": [
              'common.components.multiSelector.sectors.subLabel',
              'common.components.multiSelector.sectors.subLabelPlural',
            ],
                  label: "supporters.signup.form.fields.sector",
                  placeholder: "supporters.signup.form.placeholders.sector",
                  tip: "supporters.signup.form.tips.sector"
                }, null, 8, ["modelValue", "remote-method"])
              ]),
              _: 1
            }),
            _createVNode(_component_ElFormItem, {
              label: _ctx.$t('supporters.signup.form.fields.locations'),
              prop: "locations"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_PxRadioGroup, {
                  modelValue: _ctx.hasSpecificLocations,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.hasSpecificLocations) = $event))
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ElRadio, { label: true }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t("supporters.signup.form.locationOptions.specific")), 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_ElRadio, { label: false }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t("supporters.signup.form.locationOptions.all")), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["modelValue"]),
                _withDirectives(_createVNode(_component_MultiSelector, {
                  modelValue: _ctx.data.locations,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.data.locations) = $event)),
                  "no-label": true,
                  "remote-method": _ctx.searchLocations,
                  "subtext-label-list": [
              'common.components.multiSelector.locations.subLabel',
              'common.components.multiSelector.locations.subLabelPlural',
            ],
                  "validate-event": true,
                  class: "locations-selector",
                  "input-icon": "location",
                  placeholder: "supporters.signup.form.placeholders.locations",
                  tip: "supporters.signup.form.tips.location"
                }, null, 8, ["modelValue", "remote-method"]), [
                  [_vShow, _ctx.hasSpecificLocations]
                ])
              ]),
              _: 1
            }, 8, ["label"]),
            _createVNode(_component_ElFormItem, { prop: "networks" }, {
              default: _withCtx(() => [
                _createVNode(_component_NetworksSelector, {
                  ref: "networkSelector",
                  modelValue: _ctx.data.networks,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.data.networks) = $event)),
                  "label-copy": _ctx.$t(`profile.networks.modal.label`),
                  "placeholder-copy": 
              _ctx.$t(`supporters.signup.form.placeholders.network`)
            
                }, null, 8, ["modelValue", "label-copy", "placeholder-copy"]),
                _createElementVNode("span", {
                  class: "el-form-item__bottom-text el-form-item__tip",
                  innerHTML: _ctx.$t('supporters.signup.form.tips.network')
                }, null, 8, _hoisted_3)
              ]),
              _: 1
            }),
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("button", {
                class: "el-button el-button--link el-button--small",
                type: "button",
                onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.goToPreviousStep && _ctx.goToPreviousStep(...args)))
              }, [
                _createElementVNode("span", null, _toDisplayString(_ctx.$t("authentication.signup.form.previousButton")), 1)
              ]),
              _createVNode(_component_PxButton, {
                disabled: _ctx.stepDisabled,
                size: "medium",
                type: "primary",
                onClick: _ctx.goToNextStep
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t("supporters.signup.form.stepButton")), 1)
                ]),
                _: 1
              }, 8, ["disabled", "onClick"])
            ])
          ]),
          _: 1
        }, 8, ["model", "rules", "onValidate"])
      ]),
      _: 1
    })
  ]))
}