import { ActionTree } from "vuex";

import { RootState } from "@/services/store/root-state";
import { getBaseName } from "@/services/store/utils/get-base-name";
import {
  EMatchScoreDetailsActions,
  EMatchScoreDetailsMutations,
  IMatchScoreDetailsState,
} from "@/modules/matching/services/store/match-score-details/match-score-details.types";
import { matchScoreDetailsProvider } from "@/modules/matching/services/data/matching-score/match-score-details.provider";

export const actions: ActionTree<IMatchScoreDetailsState, RootState> = {
  /**
   * Fetch match score details.
   *
   */
  async [getBaseName(EMatchScoreDetailsActions.FETCH)](
    { commit },
    companyUid: string,
  ) {
    commit(EMatchScoreDetailsMutations.SET_LOADING, true);
    commit(EMatchScoreDetailsMutations.SET_ERROR, null);

    try {
      const response =
        await matchScoreDetailsProvider.getMatchScoreDetails(companyUid);

      commit(EMatchScoreDetailsMutations.SET_DATA, response);
    } catch (error) {
      commit(EMatchScoreDetailsMutations.SET_ERROR, error);
    } finally {
      commit(EMatchScoreDetailsMutations.SET_LOADING, false);
    }
  },
};
