import { IBaseState } from "../store-types";
import { ILevel } from "@/services/data/level/level.interface";

/**
 * Represents the store structure for the category module.
 */
export interface ILevelState extends IBaseState {
  /**
   * Array that stores all the levels.
   */
  data: Array<ILevel>;
}

/**
 * Enumeration with all available module actions.
 */
export enum ELevelActions {
  FETCH = "levels/fetch",
}

/**
 * Enumeration with all available getters for this module.
 */
export enum ELevelGetters {
  LEVELS = "levels/getLevels",
}
