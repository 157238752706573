<template>
  <div
    :class="{ 'matching-filters--closed': !filtersOpened }"
    class="matching-filters"
  >
    <div class="matching-filters__toggle-wrapper">
      <ElInput
        v-if="hasCompaniesSearch"
        v-model="filters.name.value"
        :placeholder="$t('matching.matchingList.companiesSelector')"
        class="matching-filters__search"
        clearable
      >
        <template #prefix>
          <PxIcon :size="24" name="magnifier" />
        </template>
      </ElInput>
      <PxButton
        ref="toggleBtn"
        :class="{ 'is-active': filtersOpened, 'has-filters': hasFilters }"
        class="matching-filters__toggle-button"
        size="small"
        type="ghost-grey"
        @click="toggleFilters"
      >
        <PxIcon
          :size="16"
          class="matching-filters__toggle-icon"
          name="settings"
        />
        <span v-if="$screen.mdUp">
          {{ filtersTitle }}
        </span>
        <span v-else-if="filtersLength">({{ filtersLength }})</span>
      </PxButton>
      <PxButton
        v-if="$user.isSupporter() && $screen.mdDown"
        :icon="$options.static.editCriteriaButton.icon"
        :size="$options.static.editCriteriaButton.size"
        :type="$options.static.editCriteriaButton.type"
        :variant="$options.static.editCriteriaButton.variant"
        class="matching-filters__toggle-button"
        @click="onClickEditCriteria"
      />
    </div>
    <div v-show="filtersOpened" class="matching-filters__wrapper">
      <ElForm v-if="$screen.mdUp" ref="filters" :model="filters">
        <div class="form-grid form-grid--centered">
          <ElFormItem prop="networks">
            <div class="matching-filters__label">
              <span>
                {{ $t(`matching.matchingFilters.networks.label`) }}
              </span>
              <ElTooltip
                placement="top"
                popper-class="matching-filters__tooltip el-abaca-tooltip"
              >
                <template #content>
                  <div v-html="$t('matching.matchingFilters.networks.tip')" />
                </template>
                <PxIcon
                  :size="16"
                  class="matching-filters__tooltip-icon"
                  name="question"
                />
              </ElTooltip>
            </div>
            <NetworksSelector
              ref="networkSelector"
              v-model="filters.network.values"
              :no-label="true"
              :no-location="true"
              :placeholder-copy="
                $t(`matching.matchingFilters.networks.placeholder`)
              "
              :value-as-instance="true"
              class="matching-filters__selector"
            />
          </ElFormItem>
        </div>
      </ElForm>
      <PxFormModal
        v-else
        :title="filtersTitle"
        :visibility="filtersOpened"
        @close="toggleFilters"
      >
        <template #form>
          <ElForm ref="filters" :model="filters">
            <ElFormItem
              :label="$t(`matching.matchingFilters.networks.label`)"
              prop="networks"
            >
              <NetworksSelector
                ref="networkSelector"
                v-model="filters.network.values"
                :is-modal="true"
                :no-label="true"
                :placeholder-copy="
                  $t(`matching.matchingFilters.networks.placeholder`)
                "
                :value-as-instance="true"
                class="matching-filters--mobile__selector"
              />
            </ElFormItem>
          </ElForm>
        </template>
      </PxFormModal>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import clone from "lodash/clone";
import cloneDeep from "lodash/cloneDeep";
import debounce from "lodash/debounce";
import isEqual from "lodash/isEqual";

import { IMatchingFilters } from "@/mixins/matching-list.mixin";
import { extractValuesFromFilter } from "@/modules/matching/components/matching-filters/matching-filters.utils";

import NetworksSelector from "@/components/networks-selector/networks-selector.vue";

import { EMetaActions, EMetaGetters } from "@/services/store/meta/meta-types";
import {
  MATCHING_SELECTED_FILTERS,
  MATCHING_HAS_SYNCED_FILTERS,
} from "@/modules/matching/constants";
import { AFFILIATE_SUPPORTER_PROGRAM_UPDATE_URL } from "@/modules/affiliates/constants";
import {
  EPxButtonSize,
  EPxButtonType,
  EPxButtonVariant,
} from "@/components/px-button/px-button.types";
import { IMatchingQueryFiltersParams } from "@/modules/matching/components/matching-filters/mathing-filters.interface";
import { INetwork } from "@/services/data/network/network.interface";

export default defineComponent({
  name: "MatchingFilters",

  components: {
    NetworksSelector,
  },

  props: {
    hasCompaniesSearch: {
      type: Boolean,
      default: false,
    },
  },

  static: {
    editCriteriaButton: {
      icon: "icon-gear--grey",
      size: EPxButtonSize.SMALL,
      type: EPxButtonType.GHOST_GREY,
      variant: EPxButtonVariant.COMPACT,
    },
  },

  data() {
    return {
      filtersOpened: false,
      filters: {
        name: {
          value: "",
          onlyOnDiscover: true,
        },
        network: {
          key: "slug",
          values: [] as INetwork[],
        },
      } as IMatchingFilters,
    };
  },

  computed: {
    selectedFilters(): IMatchingFilters | null {
      return (
        this.$store.getters[EMetaGetters.GET](MATCHING_SELECTED_FILTERS) || null
      );
    },

    hasSelectedFilters(): boolean {
      return (
        !!this.selectedFilters &&
        Object.keys(this.selectedFilters)
          .map((k: any) => (this.selectedFilters as IMatchingFilters)[k])
          .some((k: any) => !!k?.values?.length)
      );
    },

    availableQueryParams(): Array<string> {
      return Object.keys(this.filters);
    },

    joinedFiltersParams() {
      const filterParams: { [key: string]: string } = {};

      Object.entries(this.filters).forEach(([key, filter]) => {
        const filterValues = extractValuesFromFilter(filter);
        filterParams[key] = filterValues.join(",");
      });

      return filterParams;
    },

    splittedQueryFilterParams(): IMatchingQueryFiltersParams | null {
      const queryFilters: IMatchingQueryFiltersParams = {};

      this.availableQueryParams.forEach((param: string) => {
        if (param in this.$route.query) {
          const joinedQueryValues = this.$route.query[param] as string;
          queryFilters[param] = joinedQueryValues.split(",");
        }
      });

      return Object.keys(queryFilters).length ? queryFilters : null;
    },

    equalQueryParamsAndFilters(): boolean {
      let areEqual = true;

      for (const filter in this.joinedFiltersParams) {
        if (
          Object.prototype.hasOwnProperty.call(this.joinedFiltersParams, filter)
        ) {
          const filterValues = this.joinedFiltersParams[filter].split(",");

          if (!areEqual) {
            break;
          }

          if (
            this.splittedQueryFilterParams &&
            filter in this.splittedQueryFilterParams
          ) {
            areEqual = isEqual(
              this.splittedQueryFilterParams[filter],
              filterValues,
            );
          }
        }
      }

      return areEqual;
    },

    hasFilters(): boolean {
      return (
        this.filters &&
        Object.keys(this.filters)
          .map((k: any) => this.filters[k])
          .some((k: any) => !!k?.values?.length)
      );
    },

    hasSearchTerm(): boolean {
      return !!this.filters?.name?.value;
    },

    filtersLength(): number {
      return Object.keys(this.filters)
        .map((k: any) => this.filters[k])
        .reduce(
          (total: number, current: any) => total + current?.values?.length || 0,
          0,
        );
    },

    filtersTitle(): string {
      return `${this.$t("matching.matchingFilters.toggleButton")} ${
        this.hasFilters ? `(${this.filtersLength})` : ""
      }`;
    },
  },

  watch: {
    filters: {
      deep: true,
      handler: debounce(function (this: any, newValues: IMatchingFilters) {
        const newSelection = newValues ? cloneDeep(newValues) : null;
        this.onFiltersChangeHandler(newSelection);
      }, 600),
    },

    equalQueryParamsAndFilters(areSynced: boolean) {
      this.$store.dispatch(EMetaActions.SET, {
        key: MATCHING_HAS_SYNCED_FILTERS,
        value: areSynced,
      });
    },

    hasFilters(hasFilters: boolean) {
      // Open all selected filters on desktop
      if (hasFilters && this.$screen.mdUp) {
        this.filtersOpened = hasFilters;
      }

      // Emit event to inform parent component if either there are filters or search term defined or not
      this.$emit("has-filters-change", hasFilters || this.hasSearchTerm);
    },

    hasSearchTerm(hasSearchTerm: boolean) {
      // Emit event to inform parent component if either there are filters or search term defined or not
      this.$emit("has-filters-change", hasSearchTerm || this.hasFilters);
    },
  },

  created() {
    this.prefillFilters();
  },

  methods: {
    setQuery(query: { [key: string]: any }) {
      const obj = Object.assign({}, this.$route.query);

      Object.keys(query).forEach((key: any) => {
        const value = query[key];
        if (value) {
          obj[key] = value;
        } else {
          delete obj[key];
        }
      });

      if (isEqual(this.$route.query, obj)) {
        return;
      }

      this.$router.replace({
        ...this.$router.currentRoute.value,
        query: obj,
      });
    },

    removeQuery(queryNameArray: Array<string>) {
      const obj: { [key: string]: any } = {};
      queryNameArray.forEach((key: any) => {
        obj[key] = null;
      });
      this.setQuery(obj);
    },

    onFiltersChangeHandler(newFilters: IMatchingFilters | null) {
      if (newFilters) {
        this.setQuery(this.joinedFiltersParams);
        this.$store.dispatch(EMetaActions.SET, {
          key: MATCHING_SELECTED_FILTERS,
          value: newFilters,
        });
      } else {
        this.removeQuery(this.availableQueryParams);
        this.$store.dispatch(EMetaActions.REMOVE, MATCHING_SELECTED_FILTERS);
      }
    },

    prefillFilters() {
      if (this.selectedFilters) {
        this.filters = clone(this.selectedFilters);
      }

      // Open all selected filters on desktop
      if (this.hasSelectedFilters && this.$screen.mdUp) {
        this.filtersOpened = true;
      }
    },

    toggleFilters() {
      this.filtersOpened = !this.filtersOpened;
      (this as any).$refs.toggleBtn.$el.blur();
    },

    onClickEditCriteria() {
      window.open(AFFILIATE_SUPPORTER_PROGRAM_UPDATE_URL);
    },
  },
});
</script>

<style lang="scss">
.matching-filters {
  margin-top: 6px;

  @include breakpoint-up(md) {
    position: relative;
    top: -34px;
    right: 1px;
  }
}

.matching-filters__search {
  position: relative;
  flex: 1 1 50%;
  font-size: 14px;
  color: $gun-powder;

  @include breakpoint-down(md) {
    left: 1px;
  }

  @include breakpoint-up(md) {
    top: -3px;
    flex: 0 0 310px;
    margin-left: 0;
  }

  .el-input__inner {
    height: 40px;
    padding-left: 48px;
    color: $manatee;
    letter-spacing: -0.4px;
  }
}

.matching-filters__search .el-input__suffix {
  top: 14px;
  right: 15px;

  .el-input__suffix-inner {
    border: none;

    .el-input__icon {
      width: 13px;
      height: 13px;
      cursor: pointer;
      background: transparent url("#{$assetsPath}/img/icons/cross.svg")
        no-repeat;
      background-size: contain;
      transition: $--filter-transition-base;

      &:hover {
        filter: brightness(50%);
      }
    }
  }
}

.matching-filters--closed {
  margin-bottom: 6px;
}

.matching-filters__label {
  padding-top: 9px;
  padding-right: 11px;

  span {
    @include grotesk(semiBold);

    display: inline-block;
    font-size: 12px;
    text-transform: uppercase;
    vertical-align: middle;
  }

  .matching-filters__tooltip-icon {
    margin-left: 7px;
    vertical-align: middle;
  }
}

.matching-filters__toggle-wrapper {
  display: flex;
  justify-content: flex-end;
  max-height: 33px;
  margin-bottom: 16px;

  .matching-filters--closed & {
    margin-bottom: 0;
  }
}

.matching-filters__toggle-button.el-button {
  min-width: 36px;
  height: 36px;
  padding: 0 10px;
  margin: 2px 1px 11px 12px;

  @include breakpoint-up(md) {
    min-width: 91px;
    height: 33px;
    padding: 0 10px 0 13px;
    margin: 0 0 11px 12px;
  }

  span {
    position: relative;
    top: 0;
  }

  &.has-filters {
    min-width: 56px;
  }

  &:not(.is-active) {
    @include breakpoint-up(md) {
      margin-bottom: 0;
    }
  }

  & + & {
    // Reduce margin between same elements
    margin-left: 8px;
  }
}

.matching-filters__toggle-icon:not(:only-child) {
  margin-right: 4px;
}

.matching-filters__wrapper .el-form-item__content {
  @include breakpoint-up(md) {
    display: flex;
    flex-flow: row wrap;
  }
}

.matching-filters__selector {
  display: flex;
  flex: 1 1 50%;
  flex-direction: row-reverse;

  .networks-selector__select {
    flex: 0 0 auto;
    width: 270px;
  }

  .networks-selector__select .el-input__inner {
    height: 40px;
  }

  .networks-selector__title {
    padding-top: 4px;
    margin-bottom: 0;
  }

  .networks-selector__list {
    display: flex;
    flex: 1 1 70%;
    flex-flow: row wrap;
    margin-bottom: 0;
    margin-left: 12px;
  }

  .networks-selector__wrapper {
    display: flex;
    flex-flow: row wrap;
    align-items: baseline;
  }

  .networks-selector__entry,
  .networks-selector__entry:last-child {
    box-sizing: border-box;
    height: 40px;
    margin-bottom: 12px;
  }

  .networks-selector__entry:not(:last-child) {
    margin-right: 12px;
  }

  .networks-selector__entry-picture {
    display: none;
  }

  .networks-selector__entry-content {
    padding-right: 15px;
    line-height: 24px;
  }

  .networks-selector__entry-content span {
    font-size: 14px;
  }
}
</style>

<style lang="scss">
.matching-filters__tooltip {
  width: 250px;
}
</style>
