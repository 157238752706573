<template>
  <div class="profile-desktop page-full-height">
    <SubmissionsNotificationMessage
      v-if="showSubmissionsNotification"
      v-model:visibility="showSubmissionsBar"
      v-feature="'userSubmissions'"
      :draft-submissions="draftSubmissions"
    />
    <Transition mode="out-in" name="slide-fade">
      <PxCallout
        v-show="showCallout"
        :ctas="capitalExplorerCalloutCtas"
        :description="capitalExplorerCalloutCopy.description"
        :label="capitalExplorerCalloutLabel"
        :title="capitalExplorerCalloutCopy.title"
        class="capital-explorer-callout"
        @click="handleCalloutClick"
      >
        <template #note>
          <p v-html="capitalExplorerCalloutCopy.note" />
        </template>
      </PxCallout>
    </Transition>
    <div class="page-body">
      <div class="page-wrapper">
        <div class="page-container">
          <PanelGrid
            :class="{ 'page-container-visitor': showVisitorContainer }"
          >
            <template #left>
              <CompanyPanel @show-modal="showListModal" />
              <InvestorsPanel v-if="$profile.isEntrepreneur()" />
              <SupporterInfoPanel v-if="$profile.isSupporter()" />
            </template>
            <template #right>
              <PxCard
                v-if="$user.isOwner() && $user.isEntrepreneur()"
                v-feature="'userSubmissions'"
                :action="goToSubmissionsPage"
                :is-loading="submissionsLoading"
                :subtitle="submissionsCardCopySubtitle"
                :title="submissionsCardCopy.title"
              />
              <MatchingRibbon v-if="hasMatching" />
              <AssessmentPanel v-if="$profile.isEntrepreneur()" />
              <InvestmentLevelPanel v-if="$profile.isSupporter()" />
              <AffiliateLinkPanel
                v-if="hasAffiliateLink && showAffiliateLinkPanel"
              />
              <NetworksPanel
                v-if="$profile.isSupporter() && showNetworksPanel"
              />
              <SupporterCriteriaPanel v-if="$profile.isSupporter()" />
            </template>
          </PanelGrid>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import PanelGrid from "@/modules/profile/components/panel-grid/panel-grid.vue";
import CompanyPanel from "@/modules/profile/components/company-panel/company-panel.vue";
import InvestorsPanel from "@/modules/profile/components/investors-panel/investors-panel.vue";
import AssessmentPanel from "@/modules/profile/components/assessment-panel/assessment-panel.vue";
import InvestmentLevelPanel from "@/modules/profile/components/investment-level-panel/investment-level-panel.vue";
import MatchingRibbon from "@/modules/profile/components/matching-ribbon/matching-ribbon.vue";
import NetworksPanel from "@/modules/profile/components/networks-panel/networks-panel.vue";
import SupporterInfoPanel from "@/modules/profile/components/supporter-info-panel/supporter-info-panel.vue";
import SupporterCriteriaPanel from "@/modules/profile/components/supporter-criteria-panel/supporter-criteria-panel.vue";
import AffiliateLinkPanel from "@/modules/profile/components/affiliate-link-panel/affiliate-link-panel.vue";
import PxCard from "@/components/px-card/px-card.vue";
import SubmissionsNotificationMessage from "@/modules/submissions/components/notification-message/submissions-notification-message.vue";
import PxCallout from "@/components/px-callout/px-callout.vue";

import { TranslateResult } from "vue-i18n";
import { ROUTE_USER_SUBMISSIONS } from "@/modules/profile/services/router/routes-names";
import { IAffiliateSubmissions } from "@/modules/submissions/services/data/submissions/submissions.interface";
import {
  EPxButtonType,
  EPxButtonSize,
} from "@/components/px-button/px-button.types";
import { EComponentCtaActionType } from "@/modules/affiliates/components/common.interface";
import { CAPITAL_EXPLORER } from "@/modules/capital-explorer/services/router/routes-names";
import { CAPITAL_EXPLORER_CALLOUT_VISIBLE } from "@/modules/capital-explorer/constants";
import { EMetaActions } from "@/services/store/meta/meta-types";
import { activeModules } from "@/services/utils/utils";

export default defineComponent({
  components: {
    PanelGrid,
    CompanyPanel,
    AssessmentPanel,
    InvestorsPanel,
    InvestmentLevelPanel,
    MatchingRibbon,
    NetworksPanel,
    SupporterInfoPanel,
    SupporterCriteriaPanel,
    AffiliateLinkPanel,
    SubmissionsNotificationMessage,
    PxCard,
    PxCallout,
  },

  static: {
    capitalExplorerCalloutLabel: {
      icon: "stars--white",
      iconSize: 19,
      textColor: "#fff",
      backgroundColor: "#3b82f6",
    },

    capitalExplorerCalloutCancelCta: {
      type: EPxButtonType.LINK_WHITE,
      size: EPxButtonSize.SMALL,
      action: EComponentCtaActionType.CANCEL,
    },

    capitalExplorerCalloutContinueCta: {
      type: EPxButtonType.BLUE,
      size: EPxButtonSize.SMALL,
      action: EComponentCtaActionType.CONTINUE,
    },
  },

  data() {
    return {
      showSubmissionsBar: true,
      showCallout: false,
    };
  },

  computed: {
    hasMatching(): boolean {
      return (
        this.$features.isEnabled("matchingInterest") && !this.$user.isOwner()
      );
    },

    hasAffiliateLink(): boolean {
      return (
        this.$user.isOwner() &&
        this.$user.isSupporter() &&
        this.$features.isEnabled("commercializationAffiliateLink")
      );
    },

    isSpecialGuest(): boolean {
      return !!this.$route.meta?.specialGuest;
    },

    showVisitorContainer(): boolean {
      return !this.$user.isLogged() && !this.isSpecialGuest;
    },

    showAffiliateLinkPanel(): boolean {
      return this.$features.isEnabled("affiliateLinkProfilePanel");
    },

    showNetworksPanel(): boolean {
      return this.$features.isEnabled("networksProfilePanel");
    },

    submissionsCardCopy() {
      return this.$tm("profile.submissionsCard") as {
        title: string;
        subtitle: string;
        emptySubtitle: string;
      };
    },

    submissionsCardCopySubtitle(): TranslateResult {
      return this.userSubmissions?.drafts.length ||
        this.userSubmissions?.submitted.length
        ? this.$t("profile.submissionsCard.subtitle")
        : this.$t("profile.submissionsCard.emptySubtitle");
    },

    userSubmissions(): IAffiliateSubmissions | null {
      return this.$store.state.affiliates.userSubmissions || null;
    },

    draftSubmissions(): number {
      return this.userSubmissions?.drafts.length || 0;
    },

    submissionsLoading(): boolean {
      return this.$store.state.affiliates.loading;
    },

    showSubmissionsNotification(): boolean {
      return (
        this.$user.isOwner() &&
        this.$screen.mdUp &&
        this.showSubmissionsBar &&
        this.draftSubmissions > 0 &&
        this.$user.isEntrepreneur() &&
        !this.submissionsLoading
      );
    },

    capitalExplorerCalloutCopy() {
      return this.$tm("capitalExplorer.callout") as {
        title: string;
        description: string;
        note: string;
        ctas: string[];
      };
    },

    capitalExplorerCalloutLabel(): any {
      return {
        ...this.$options.static.capitalExplorerCalloutLabel,
        text: this.$t("common.new"),
      };
    },

    capitalExplorerCalloutCtas(): any {
      return [
        {
          ...this.$options.static.capitalExplorerCalloutCancelCta,
          label: this.capitalExplorerCalloutCopy.ctas[0],
        },
        {
          ...this.$options.static.capitalExplorerCalloutContinueCta,
          label: this.capitalExplorerCalloutCopy.ctas[1],
        },
      ];
    },

    capitalExplorerCalloutVisible(): boolean {
      return !!this.$store.get("meta/get", CAPITAL_EXPLORER_CALLOUT_VISIBLE);
    },

    showCapitalExplorerCallout(): boolean {
      return (
        this.capitalExplorerCalloutVisible &&
        this.$user.isOwner() &&
        this.$user.isEntrepreneur() &&
        this.$features.isEnabled("capitalExplorer") &&
        activeModules().includes("capital-explorer")
      );
    },
  },

  mounted() {
    setTimeout(() => {
      if (this.showCapitalExplorerCallout) {
        this.showCallout = true;
      }
    }, 2000);
  },

  methods: {
    // TODO: improve the emission of this event to not be repeated in two components
    showListModal() {
      this.$emit("show-modal");
    },

    goToSubmissionsPage() {
      return this.$router.push({ name: ROUTE_USER_SUBMISSIONS });
    },

    handleCalloutClick(actionType: EComponentCtaActionType) {
      this.showCallout = false;

      this.$store.dispatch(EMetaActions.SET, {
        key: CAPITAL_EXPLORER_CALLOUT_VISIBLE,
        value: false,
      });

      if (actionType === EComponentCtaActionType.CONTINUE) {
        return this.$router.push({ name: CAPITAL_EXPLORER });
      }
    },
  },
});
</script>

<style lang="scss" scoped>
.page-container {
  max-width: 1140px;
  margin: 0 auto;
}

.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: all 0.7s ease-in-out;
}

.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateX(50%);
  opacity: 0;
}

.profile-desktop :deep() .panel-grid:not(.page-container-visitor) {
  padding: 42px 0 71px;

  @include breakpoint-up(md) {
    padding: 61px 0 71px;
  }
}

.profile-desktop :deep() .panel-grid.page-container-visitor {
  padding: 42px 0 145px;

  @include breakpoint-up(md) {
    padding: 61px 0 145px;
  }
}

.profile-desktop :deep(.panel-grid__col--right) {
  > .panel-grid__block:not(:first-child) {
    margin-top: 30px;
  }

  @include breakpoint-up(md) {
    width: 650px;
  }

  @include breakpoint-up(xl) {
    width: auto;
    max-width: 458px;
  }
}

.profile-desktop :deep(.panel-grid__col--left) {
  > .panel-grid__block:not(:first-child) {
    margin-top: 30px;
  }

  @include breakpoint-up(lg) {
    max-width: 652px;
  }
}

.capital-explorer-callout {
  position: fixed;
  right: 24px;
  bottom: 24px;

  z-index: z("overlay");
}
</style>
