import { i18n } from "@/services/i18n/i18n";

import {
  ROUTE_PROFILE,
  ROUTE_TEAM_MANAGEMENT,
  ROUTE_UPDATE_LEVEL,
  ROUTE_USER_SUBMISSIONS,
} from "@/modules/profile/services/router/routes-names";
import { ROUTE_MATCHING_LIST } from "@/modules/matching/services/router/routes-names";
import {
  ROUTE_COMPANY_LISTS_DETAIL,
  ROUTE_COMPANY_LISTS_DIRECTORY,
} from "@/modules/company-lists/services/router/routes-names";
import {
  ROUTE_MILESTONE_PLANNER_DETAIL,
  ROUTE_MILESTONE_PLANNER_FULLGRID,
  ROUTE_MILESTONE_PLANNER_OVERVIEW,
} from "@/modules/milestone-planner/services/router/routes-names";
import {
  CAPITAL_EXPLORER,
  CAPITAL_EXPLORER_COMPARISON_TABLE,
} from "@/modules/capital-explorer/services/router/routes-names";
import { ROUTE_GETTING_STARTED_DASHBOARD } from "@/modules/getting-started-dashboard/services/router/routes-names";

export interface INavBarItem {
  key: string;
  label: string;
  to: string;
  requiredFeatures?: Array<string>;
  requiredModules?: Array<string>;
  includedRoutes?: Array<string>;
  subMenuItems?: Array<INavBarItem>;
  newFeature?: boolean;
  proUser?: boolean;
  icon?: string;
}

export default {
  navBarItems: [
    {
      key: "home",
      label: i18n.global.t("common.navigation.navBarItems.home"),
      to: ROUTE_PROFILE,
      includedRoutes: [
        ROUTE_PROFILE,
        ROUTE_TEAM_MANAGEMENT,
        ROUTE_UPDATE_LEVEL,
        ROUTE_USER_SUBMISSIONS,
        ROUTE_GETTING_STARTED_DASHBOARD,
      ],
      subMenuItems: [
        {
          key: "my_company",
          label: i18n.global.t("common.navigation.navBarItems.myCompany"),
          to: ROUTE_PROFILE,
        },
        {
          key: "dashboard",
          label: i18n.global.t("common.navigation.navBarItems.dashboard"),
          to: ROUTE_GETTING_STARTED_DASHBOARD,
          requiredModules: ["getting-started-dashboard"],
          requiredFeatures: ["gettingStartedDashboard"],
          newFeature: true,
        },
      ],
    },
    {
      key: "support",
      label: i18n.global.t("common.navigation.navBarItems.support"),
      includedRoutes: [
        ROUTE_MATCHING_LIST,
        CAPITAL_EXPLORER,
        CAPITAL_EXPLORER_COMPARISON_TABLE,
      ],
      requiredFeatures: ["match", "capitalExplorer"],
      requiredModules: ["matching", "capital-explorer"],
      newFeature: true,
      subMenuItems: [
        {
          key: "my_supporters",
          label: i18n.global.t("common.navigation.navBarItems.mySupporters"),
          to: ROUTE_MATCHING_LIST,
          requiredFeatures: ["match"],
        },
        {
          key: "capital_explorer",
          label: i18n.global.t("common.navigation.navBarItems.capitalExplorer"),
          to: CAPITAL_EXPLORER,
          requiredModules: ["capital-explorer"],
          requiredFeatures: ["capitalExplorer"],
          newFeature: true,
        },
      ],
    },
    {
      key: "lists",
      label: i18n.global.t("common.navigation.navBarItems.lists"),
      to: ROUTE_COMPANY_LISTS_DIRECTORY,
      requiredFeatures: ["companyLists", "companyListsForEntrepreneurs"],
      includedRoutes: [
        ROUTE_COMPANY_LISTS_DIRECTORY,
        ROUTE_COMPANY_LISTS_DETAIL,
      ],
    },
    {
      key: "milestone_planner",
      label: i18n.global.t("common.navigation.navBarItems.milestonePlanner"),
      to: ROUTE_MILESTONE_PLANNER_OVERVIEW,
      requiredModules: ["milestone-planner"],
      includedRoutes: [
        ROUTE_MILESTONE_PLANNER_OVERVIEW,
        ROUTE_MILESTONE_PLANNER_DETAIL,
        ROUTE_MILESTONE_PLANNER_FULLGRID,
      ],
    },
  ] as Array<INavBarItem>,
  tabMenuItems: [
    {
      key: "about",
      label: i18n.global.t("common.navigation.tabBarItems.about"),
      to: ROUTE_PROFILE,
      hasVisitorIcon: false,
    },
    {
      key: "viral",
      label: i18n.global.t("common.navigation.tabBarItems.viral"),
      to: ROUTE_PROFILE,
      hasVisitorIcon: true,
    },
    {
      key: "expectations",
      label: i18n.global.t("common.navigation.tabBarItems.expectations"),
      to: ROUTE_PROFILE,
      hasVisitorIcon: true,
    },
  ],
};
