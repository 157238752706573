import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-4aa0e408"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "matching-card-background" }
const _hoisted_2 = ["alt", "src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("picture", null, [
      _createElementVNode("img", {
        alt: _ctx.companyName,
        src: _ctx.backgroundSource
      }, null, 8, _hoisted_2)
    ])
  ]))
}