import { resolveComponent as _resolveComponent, withModifiers as _withModifiers, mergeProps as _mergeProps, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-6c0e8583"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "share-results" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PxButton = _resolveComponent("PxButton")!
  const _component_ShareResultsModal = _resolveComponent("ShareResultsModal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_PxButton, _mergeProps({ class: "share-results__button" }, _ctx.shareButtonProps, {
      onClick: _withModifiers(_ctx.openShareModalClickHandler, ["stop"])
    }), null, 16, ["onClick"]),
    _createVNode(_component_ShareResultsModal, {
      id: _ctx.capitalExplorerSubmission.id,
      visibility: _ctx.shareModalVisible,
      "onUpdate:visibility": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.shareModalVisible) = $event)),
      passcode: _ctx.capitalExplorerSubmissionPasscode,
      uid: _ctx.capitalExplorerSubmission.uid,
      users: _ctx.allInvitedUsers
    }, null, 8, ["id", "visibility", "passcode", "uid", "users"])
  ]))
}