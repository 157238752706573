<template>
  <PxStickyBar class="visitor-join-bar">
    <div class="visitor-join-bar__col-start">
      <h2 class="visitor-join-bar__title">
        {{ $t("profile.visitor.stickyBar.title") }}
      </h2>
      <div class="visitor-join-bar__subtitle">
        {{ $t("profile.visitor.stickyBar.subtitle") }}
      </div>
    </div>
    <div class="visitor-join-bar__col-middle">
      <PxButton
        class="visitor-join-bar__cta-button"
        size="extra-large"
        type="green"
        @click="onClickSignUp"
      >
        {{ $t("profile.visitor.stickyBar.button") }}
      </PxButton>
    </div>
    <div class="visitor-join-bar__col-end">
      <div class="visitor-join-bar__content">
        {{ $t("profile.visitor.stickyBar.content") }}
      </div>
      <div class="visitor-join-bar__cta-link">
        <PxButton
          class="el-button--link el-button--link-white"
          icon="info"
          type="link"
          @click="onClickOpenHowItWorksLink"
        >
          {{ $t("profile.visitor.stickyBar.link") }}
        </PxButton>
      </div>
    </div>
  </PxStickyBar>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import PxStickyBar from "@/components/px-sticky-bar/px-sticky-bar.vue";

import { ROUTE_AUTHENTICATION_SIGNUP } from "@/modules/authentication/services/router/routes-names";

export default defineComponent({
  name: "VisitorJoinBar",

  components: {
    PxStickyBar,
  },

  methods: {
    onClickSignUp() {
      this.$router.push({ name: ROUTE_AUTHENTICATION_SIGNUP });
    },

    onClickOpenHowItWorksLink() {
      window.open(
        "https://medium.com/village-capital/entrepreneurs-and-vcs-need-to-be-more-precise-in-the-way-they-talk-to-each-other-3e714e7a5245",
      );
    },
  },
});
</script>

<style lang="scss" scoped>
.visitor-join-bar :deep() .px-sticky-bar__wrapper {
  padding: 26px 0 27px;
}

.visitor-join-bar__col-middle {
  margin: auto 40px;
}

.visitor-join-bar__col-end {
  margin-right: 0;
  margin-left: auto;
  text-align: right;
}

.visitor-join-bar__title {
  @include grotesk(semiBold);

  max-width: 360px;
  margin: 0;
  font-size: to-rem(24px);
  line-height: 37px;
}

.visitor-join-bar__subtitle {
  @include breakpoint-up(md) {
    display: none;
  }
}

.visitor-join-bar__content {
  max-width: 472px;
  margin-bottom: 0;
  font-size: to-rem(16px);
  line-height: 28px;
  letter-spacing: -0.17px;
}
</style>
