<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="173"
    height="173"
    viewBox="0 0 173 173"
  >
    <defs>
      <linearGradient id="viral-graph-3-c" x1="50%" x2="50%" y1="0%" y2="100%">
        <stop offset="0%" stop-color="#58A0F8" class="linear-gradient-color1" />
        <stop
          offset="100%"
          stop-color="#2176DD"
          class="linear-gradient-color2"
        />
      </linearGradient>
      <path
        id="viral-graph-3-b"
        d="M27.496754,16.5190212 L37.1321043,28.1874178 C29.6220229,34.3820418 23.5872277,42.2019088 19.5252968,51.0410954 L5.76926295,44.7267629 C10.7863195,33.8091229 18.2321365,24.1608587 27.496754,16.5190212 Z"
      />
      <filter
        id="viral-graph-3-a"
        width="163.8%"
        height="157.9%"
        x="-31.9%"
        y="-23.2%"
        filterUnits="objectBoundingBox"
      >
        <feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur
          in="shadowOffsetOuter1"
          result="shadowBlurOuter1"
          stdDeviation="3"
        />
        <feComposite
          in="shadowBlurOuter1"
          in2="SourceAlpha"
          operator="out"
          result="shadowBlurOuter1"
        />
        <feColorMatrix
          in="shadowBlurOuter1"
          values="0 0 0 0 0.333333333 0 0 0 0 0.623529412 0 0 0 0 0.976470588 0 0 0 0.5 0"
          class="color-matrix"
        />
      </filter>
      <linearGradient id="viral-graph-3-f" x1="50%" x2="50%" y1="0%" y2="100%">
        <stop offset="0%" stop-color="#64A9FE" class="linear-gradient-color3" />
        <stop
          offset="100%"
          stop-color="#1865C4"
          class="linear-gradient-color2"
        />
      </linearGradient>
      <path
        id="viral-graph-3-e"
        d="M78.30625,134.548387 C109.955618,134.548387 135.6125,108.905857 135.6125,77.2741935 C135.6125,45.6425299 109.955618,20 78.30625,20 C46.6568821,20 21,45.6425299 21,77.2741935 C21,108.905857 46.6568821,134.548387 78.30625,134.548387 Z M78.30625,134.548387 C109.955618,134.548387 135.6125,108.905857 135.6125,77.2741935 C135.6125,45.6425299 109.955618,20 78.30625,20 C46.6568821,20 21,45.6425299 21,77.2741935 C21,108.905857 46.6568821,134.548387 78.30625,134.548387 Z M78.30625,134.548387 C109.955618,134.548387 135.6125,108.905857 135.6125,77.2741935 C135.6125,45.6425299 109.955618,20 78.30625,20 C46.6568821,20 21,45.6425299 21,77.2741935 C21,108.905857 46.6568821,134.548387 78.30625,134.548387 Z"
      />
      <filter
        id="viral-graph-3-d"
        width="141%"
        height="141%"
        x="-20.5%"
        y="-18.8%"
        filterUnits="objectBoundingBox"
      >
        <feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur
          in="shadowOffsetOuter1"
          result="shadowBlurOuter1"
          stdDeviation="7.5"
        />
        <feComposite
          in="shadowBlurOuter1"
          in2="SourceAlpha"
          operator="out"
          result="shadowBlurOuter1"
        />
        <feColorMatrix
          in="shadowBlurOuter1"
          values="0 0 0 0 0.325490196 0 0 0 0 0.584313725 0 0 0 0 0.894117647 0 0 0 0.5 0"
          class="color-matrix"
        />
      </filter>
      <linearGradient
        id="viral-graph-3-g"
        x1="50%"
        x2="50%"
        y1="0%"
        y2="98.69%"
      >
        <stop offset="0%" stop-color="#FFF" />
        <stop
          offset="100%"
          stop-color="#D5E3F4"
          stop-opacity="0"
          class="linear-gradient-color2"
        />
      </linearGradient>
    </defs>
    <g fill="none" fill-rule="evenodd" transform="translate(8 18)">
      <path
        fill="#E8E9EF"
        fill-rule="nonzero"
        d="M139.962437,102.814507 C142.828928,95.7192801 144.454218,88.1517636 144.738297,80.3612907 L155.442824,80.3612903 C155.152535,89.5832105 153.239939,98.5543929 149.828602,106.953543 L139.962438,102.814507 Z M144.781261,77.3612903 C144.720385,69.5150278 143.302303,61.9916682 140.75005,55.0141512 L150.647864,50.9253432 C153.715506,59.1648413 155.417822,68.0683892 155.479931,77.3612903 L144.781262,77.3612903 Z M139.655638,52.220353 C136.188296,43.927862 131.091598,36.4852917 124.765395,30.2923204 L131.9226,22.3293228 C139.425481,29.5886875 145.462329,38.3526146 149.546238,48.1345253 L139.655638,52.220353 Z M122.574755,28.2417643 C115.624775,22.0220638 107.361094,17.2385464 98.2508358,14.3581347 L101.069899,4.0273505 C111.812476,7.35791717 121.552902,12.965229 129.726431,20.284919 L122.574755,28.2417643 Z M95.370208,13.5187103 C89.8684308,12.0499399 84.0863514,11.2669355 78.12125,11.2669355 C73.4777243,11.2669355 68.9204192,11.7456186 64.5041736,12.6635718 L61.9693804,2.26339977 C67.2034369,1.14983439 72.6101239,0.568548387 78.12125,0.568548387 C85.0612884,0.568548387 91.7879116,1.48194542 98.1873332,3.19502951 L95.370208,13.5187103 Z M61.5812781,13.3402443 C52.9018226,15.5570751 44.8322281,19.4871653 37.8078542,24.8176203 L31.1254037,16.4591513 C39.3591544,10.1652434 48.8417163,5.53452719 59.0476011,2.9446518 L61.5812781,13.3402443 Z M35.4593928,26.6843822 C28.3018636,32.6411167 22.3635829,40.1181868 18.156365,48.7477493 L8.53997169,44.0594164 C13.4525302,33.9831065 20.4004821,25.2603872 28.7776995,18.3268603 L35.4593928,26.6843822 Z"
      />
      <path
        class="linear-gradient-color4"
        fill="#7CB8FF"
        fill-rule="nonzero"
        d="M11.4642302,77.3612903 L0.759577624,77.3612903 C0.827410893,67.2322509 2.84880341,57.3785073 6.6581957,48.2227738 L16.4383609,52.5812424 C13.2345154,60.3714996 11.5310247,68.7431435 11.4642307,77.3612903 Z M11.5071998,80.3612908 C11.833214,89.3013722 13.9254099,97.9459846 17.6287259,105.915639 L7.92024936,110.4219 C3.56464116,101.048492 1.12749006,90.8690921 0.796686674,80.3612906 L11.5071865,80.3612903 Z"
      />
      <g fill-rule="nonzero">
        <use
          fill="#000"
          filter="url(#viral-graph-3-a)"
          xlink:href="#viral-graph-3-b"
        />
        <use fill="url(#viral-graph-3-c)" xlink:href="#viral-graph-3-b" />
      </g>
      <use
        fill="#000"
        filter="url(#viral-graph-3-d)"
        xlink:href="#viral-graph-3-e"
      />
      <ellipse
        cx="78.338"
        cy="77.565"
        rx="56.819"
        ry="56.79"
        fill="url(#viral-graph-3-f)"
        stroke="#181818"
        stroke-linejoin="round"
        stroke-opacity=".2"
      />
      <ellipse
        cx="78.338"
        cy="77.565"
        stroke="url(#viral-graph-3-g)"
        stroke-width="2"
        opacity=".402"
        rx="49.819"
        ry="49.79"
      />
      <path
        fill="#FFF"
        d="M77.7802734,96.6152344 C69.074707,96.6152344 61.5688477,91.9086914 61.5688477,82.2802734 L69.0131836,81.8188477 C69.0131836,86.6791992 72.796875,89.4477539 77.8417969,89.4477539 C82.3022461,89.4477539 86.3935547,87.2329102 86.3935547,82.5878906 C86.3935547,78.6811523 83.5634766,76.2202148 78.8876953,76.2202148 L73.8735352,76.2202148 L73.8735352,69.6679688 L78.8876953,69.6679688 C82.4868164,69.6679688 83.9941406,67.453125 83.9941406,64.6845703 C83.9941406,60.9624023 81.2255859,59.1474609 77.6879883,59.1474609 C73.7197266,59.1474609 71.0742188,61.4853516 71.0126953,65.3305664 L63.6606445,64.8076172 C63.6606445,56.625 70.0898438,51.8876953 77.5649414,51.8876953 C84.824707,51.8876953 91.5,56.3173828 91.5,63.730957 C91.5,67.2070312 90.0541992,70.2832031 87.7163086,72.375 C91.9306641,74.7128906 93.7148438,78.6811523 93.8378906,83.0185547 C93.8378906,92.1547852 86.2089844,96.6152344 77.7802734,96.6152344 Z"
      />
    </g>
  </svg>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "Level3",
});
</script>
