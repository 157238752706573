<template>
  <ElDialog
    ref="modal"
    v-model="innerVisibility"
    :close-on-click-modal="false"
    class="verify-account-modal el-dialog--no-header el-dialog--compact-footer"
  >
    <div class="verify-account-modal__left">
      <div class="verify-account-modal__header">
        <h3 class="verify-account-modal__headline">
          {{ $t("profile.account.verification.headline") }}
        </h3>
        <h2 class="verify-account-modal__title">
          {{ $t("profile.account.verification.title") }}
        </h2>
        <i18n-t
          class="verify-account-modal__subtitle"
          keypath="profile.account.verification.subtitle"
          tag="p"
        >
          <template #email>
            <span>
              {{ authUserEmail }}
            </span>
          </template>
        </i18n-t>
      </div>
      <div class="verify-account-modal__body">
        <PxButton
          v-for="(client, index) in emailClients"
          :key="index"
          :class="`verify-account-modal__emailBtn--${client.icon}`"
          class="verify-account-modal__emailBtn"
          size="medium"
          type="ghost-grey"
          @click="onClickEmailClient(client.host)"
        >
          <PxIcon
            :name="client.icon"
            class="verify-account-modal__emailBtn-icon"
            size="auto"
          />
          {{ $t("common.open") }} {{ client.name }}
        </PxButton>
      </div>
      <div class="verify-account-modal__footer">
        <i18n-t
          class="verify-account-modal__tip"
          keypath="profile.account.verification.help"
          tag="p"
        >
          <template #link>
            <PxButton
              :disabled="hasJustResentConfirmation"
              class="verify-account-modal__link el-button--link el-button--link-blue"
              type="link"
              @click="onClickResendConfirmation"
            >
              {{ accountModalTip }}
            </PxButton>
          </template>
        </i18n-t>
      </div>
    </div>
    <div class="verify-account-modal__right">
      <div class="verify-account-modal__image">
        <img :alt="modalImage.alt" :src="modalImage.url" />
      </div>
    </div>
  </ElDialog>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import ElDialogMixin from "@/mixins/el-dialog.mixin";
import { resendEmailProvider } from "@/modules/authentication/services/data/resend-email/resend-email.provider";

export default defineComponent({
  name: "VerifyAccountModal",

  mixins: [ElDialogMixin],

  data() {
    return {
      // Time in seconds that a user needs to wait before
      // asking for another email confirmation.
      resendWaitPeriod: 20000,
      hasJustResentConfirmation: false,

      emailClients: [
        {
          name: "Gmail",
          icon: "gmail",
          host: "https://mail.google.com",
        },
        {
          name: "Outlook",
          icon: "outlook",
          host: "https://outlook.live.com",
        },
        {
          name: "Yahoo",
          icon: "yahoo",
          host: "https://mail.yahoo.com",
        },
      ],
    };
  },

  computed: {
    modalImage() {
      return this.$tm("authentication.login.modal.image") as {
        url: string;
        alt: string;
      };
    },

    authUserEmail(): string {
      return this.$store.get("auth/user.email");
    },

    accountModalTip() {
      return this.hasJustResentConfirmation
        ? this.$t("profile.account.verification.linkWait")
        : this.$t("profile.account.verification.link");
    },
  },

  methods: {
    onClickEmailClient(emailClientDomain: string) {
      window.open(emailClientDomain);
    },

    async onClickResendConfirmation() {
      await resendEmailProvider.create({
        email: this.authUserEmail,
      });

      // TODO: Use PxModalMessage instead of Element Message
      // Shows message under Navbar, due to message global context,
      // which will be shown behind modal overlay background
      this.$message({
        message: this.$t(
          "authentication.login.messages.confirmationEmail",
        ) as string,
        type: "success",
        customClass: "is-navbar",
      });

      // Renable email resend after the wait period has finished.
      this.hasJustResentConfirmation = true;
      setTimeout(() => {
        this.hasJustResentConfirmation = false;
      }, this.resendWaitPeriod);
    },
  },
});
</script>

<style lang="scss">
.verify-account-modal {
  @include breakpoint-up(md) {
    // override default
    width: 825px;
    margin-top: 15vh !important;
  }

  .el-dialog__header {
    padding-bottom: 0;
  }

  .el-dialog__body {
    display: grid;
    grid-template-columns: auto;
    box-shadow: 0 2px 16px 0 rgba(39, 39, 64, 0.1);

    @include breakpoint-up(md) {
      grid-template-columns: 482px auto;
      width: 100%;
      max-height: 570px;
      padding: 0;
    }
  }
}

.verify-account-modal__left {
  padding: 55px 18px 21px 50px;
}

.verify-account-modal__right {
  @include breakpoint-down(sm) {
    display: none;
  }

  background-image: linear-gradient(
    to bottom,
    rgba(255, 129, 65, 0.06),
    rgba(255, 129, 65, 0.04) 1%,
    rgba(255, 129, 65, 0.06)
  );
}

.verify-account-modal__image {
  height: 100%;
  background-image: url("#{$assetsPath}/img/illustrations/verify-account/clouds.svg");
  background-repeat: no-repeat;
  background-position: 65% center;

  img {
    position: relative;
    top: 72px;
    left: 35px;
  }
}

.verify-account-modal__headline {
  @include grotesk(regular);

  margin-bottom: 1px;
  font-size: 30px;
  line-height: 40px;
  color: $atomic-tangerine;
  letter-spacing: -0.7px;
}

.verify-account-modal__title {
  @include grotesk(semiBold);

  margin-bottom: 8px;
  font-size: 30px;
  line-height: 40px;
  color: $ebony-clay;
  letter-spacing: -0.26px;
}

.verify-account-modal__subtitle {
  @include grotesk(regular);

  margin-top: 9px;
  font-size: 16px;
  line-height: 22px;
  color: $manatee;
  letter-spacing: -0.36px;

  span {
    @include grotesk(medium);

    word-break: break-all;
  }
}

.verify-account-modal__body {
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  margin-top: 44px;
  margin-bottom: 70px;
}

.verify-account-modal__emailBtn--yahoo img {
  position: relative;
  right: 4px;
  margin-right: 6px;
}

.verify-account-modal__emailBtn--outlook img {
  position: relative;
  top: 1px;
  right: 1px;
  margin-right: 9px;
}

.verify-account-modal__emailBtn--gmail {
  img {
    position: relative;
    top: 1px;
    right: 1px;
    margin-right: 9px;
  }

  span {
    letter-spacing: -0.2px;
  }
}

.verify-account-modal__emailBtn {
  height: 37px;
  padding: 0 16px;
  color: $gun-powder;

  &--gmail:hover img,
  &--gmail:focus img {
    filter: none;
  }

  &::after {
    border-color: $mischka;
  }

  span {
    @include grotesk(medium);

    line-height: 13px;
    text-transform: none;
  }

  &:not(:first-child) {
    margin-top: 15px;

    :deep(span) {
      letter-spacing: -0.2px;
    }
  }

  .verify-account-modal__emailBtn-icon.px-icon {
    margin-right: 11px;
    width: auto;
    height: auto;
  }
}

.verify-account-modal__link.el-button--link span {
  position: relative;
  top: 1px;
  font-size: 14px;
  text-align: left;
  text-decoration: none;
  letter-spacing: -0.2px;
}

.verify-account-modal__tip {
  @include grotesk(regular);

  font-size: 14px;
  line-height: 20px;
  color: $ebony-clay;
  letter-spacing: -0.33px;
  margin: 0;

  display: flex;
  align-items: center;
  gap: 2px;
}
</style>
