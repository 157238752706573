import {
  EProviderFeatures,
  GenericProvider,
  Provider,
} from "@/services/data/generic-provider";
import { baseAPIUrl } from "@/services/utils/utils";
import { ISupporterType } from "./supporter-type.interface";

@Provider(`${baseAPIUrl}/supporterstypes`, [EProviderFeatures.LIST])
class SupporterTypeProvider extends GenericProvider<ISupporterType> {}

export const supporterTypeProvider = new SupporterTypeProvider();
