import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createVNode as _createVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-476dc981"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "match-details-table-row" }
const _hoisted_2 = { class: "match-details-table-row__item" }
const _hoisted_3 = { class: "match-details-table-row__item" }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { class: "match-details-table-row__not-answered" }
const _hoisted_6 = { key: 2 }
const _hoisted_7 = { key: 4 }
const _hoisted_8 = { class: "match-details-table-row__item" }
const _hoisted_9 = { key: 1 }
const _hoisted_10 = { key: 3 }
const _hoisted_11 = { class: "match-details-table-row__item--center" }
const _hoisted_12 = {
  key: 0,
  class: "match-details-table-row__item--center"
}
const _hoisted_13 = { class: "match-details-table-row__learn-more" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MatchingCardTagList = _resolveComponent("MatchingCardTagList")!
  const _component_PxIcon = _resolveComponent("PxIcon")!
  const _component_ElCollapseItem = _resolveComponent("ElCollapseItem")!
  const _component_ElCollapse = _resolveComponent("ElCollapse")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([{
      'match-details-table-row__wrapper--no-instructions': !_ctx.instructions,
    }, "match-details-table-row__wrapper"])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.criteria), 1),
      _createElementVNode("div", _hoisted_3, [
        (_ctx.showAuthAnswerTagList && Array.isArray(_ctx.authUserAnswer))
          ? (_openBlock(), _createBlock(_component_MatchingCardTagList, {
              key: 0,
              capitalize: false,
              "is-simplified-version": true,
              items: _ctx.authUserAnswer
            }, null, 8, ["items"]))
          : (!_ctx.formattedAuthUserAnswer && _ctx.listFormat)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.$t("common.noAnswer")), 1)
              ]))
            : (!_ctx.formattedAuthUserAnswer)
              ? (_openBlock(), _createElementBlock("hr", _hoisted_6))
              : (_ctx.listFormat && Array.isArray(_ctx.authUserAnswer))
                ? (_openBlock(), _createBlock(_component_MatchingCardTagList, {
                    key: 3,
                    capitalize: false,
                    items: _ctx.authUserAnswer,
                    "list-format": true,
                    "max-items-to-display": 3,
                    "should-sort-array": false,
                    title: _ctx.answerHeaderCopy
                  }, null, 8, ["items", "title"]))
                : (_openBlock(), _createElementBlock("div", _hoisted_7, _toDisplayString(_ctx.formattedAuthUserAnswer), 1))
      ]),
      _createElementVNode("div", _hoisted_8, [
        (_ctx.showProfileAnswerTagList && Array.isArray(_ctx.profileUserAnswer))
          ? (_openBlock(), _createBlock(_component_MatchingCardTagList, {
              key: 0,
              capitalize: false,
              "is-simplified-version": true,
              items: _ctx.profileUserAnswer
            }, null, 8, ["items"]))
          : (!_ctx.formattedProfileUserAnswer)
            ? (_openBlock(), _createElementBlock("hr", _hoisted_9))
            : (_ctx.listFormat && Array.isArray(_ctx.profileUserAnswer))
              ? (_openBlock(), _createBlock(_component_MatchingCardTagList, {
                  key: 2,
                  capitalize: false,
                  items: _ctx.profileUserAnswer,
                  "list-format": true,
                  "max-items-to-display": 3,
                  "should-sort-array": false,
                  title: "capitalExplorer.detailsModal.tableHeader[2].title"
                }, null, 8, ["items"]))
              : (_openBlock(), _createElementBlock("div", _hoisted_10, _toDisplayString(_ctx.formattedProfileUserAnswer), 1))
      ]),
      _createElementVNode("div", _hoisted_11, [
        _createVNode(_component_PxIcon, {
          ref: "icon",
          name: _ctx.matchIcon,
          size: 24
        }, null, 8, ["name"])
      ]),
      (_ctx.impact !== null)
        ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
            _createElementVNode("h3", {
              class: _normalizeClass({
            'match-details-table-row--grey': !_ctx.hasMatch || _ctx.impact <= 0,
          })
            }, _toDisplayString(_ctx.formattedImpactValue), 3)
          ]))
        : _createCommentVNode("", true)
    ]),
    (_ctx.showLearnMoreLink && _ctx.instructions)
      ? (_openBlock(), _createBlock(_component_ElCollapse, { key: 0 }, {
          default: _withCtx(() => [
            _createVNode(_component_ElCollapseItem, null, {
              title: _withCtx(() => [
                _createElementVNode("div", _hoisted_13, [
                  _createElementVNode("span", null, _toDisplayString(_ctx.$t("common.learnMore")), 1)
                ])
              ]),
              default: _withCtx(() => [
                _createElementVNode("p", null, _toDisplayString(_ctx.instructions), 1)
              ]),
              _: 1
            })
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ], 2))
}