import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "gender-chart-aggregation" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ListReportsChart = _resolveComponent("ListReportsChart")!
  const _component_ProcessReportsChart = _resolveComponent("ProcessReportsChart")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.isListsReportsView)
      ? (_openBlock(), _createBlock(_component_ListReportsChart, {
          key: 0,
          "chart-title": _ctx.chartsTitle,
          "chart-description": _ctx.chartsDescription,
          data: _ctx.aggregatedData,
          "show-locked-state": _ctx.showLockedState,
          "locked-state": _ctx.lockedStateCopy,
          theme: _ctx.theme.title
        }, null, 8, ["chart-title", "chart-description", "data", "show-locked-state", "locked-state", "theme"]))
      : (_openBlock(), _createBlock(_component_ProcessReportsChart, {
          key: 1,
          data: _ctx.processReportsAggregatedData,
          "chart-title": _ctx.chartsTitle,
          "chart-description": _ctx.chartsDescription,
          "locked-state": _ctx.lockedStateCopy,
          theme: _ctx.theme.title
        }, null, 8, ["data", "chart-title", "chart-description", "locked-state", "theme"]))
  ]))
}