import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-2b6002ac"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "share-planner-users" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SharePlannerUsersIndex = _resolveComponent("SharePlannerUsersIndex")!
  const _component_SharePlannerActions = _resolveComponent("SharePlannerActions")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_SharePlannerUsersIndex, {
      users: _ctx.users,
      class: "share-planner-users__body"
    }, null, 8, ["users"]),
    _createVNode(_component_SharePlannerActions, {
      link: _ctx.link,
      passcode: _ctx.passcode,
      class: "share-planner-users__footer"
    }, null, 8, ["link", "passcode"])
  ]))
}