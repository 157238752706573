<template>
  <div v-if="matchingTip" class="px-matching-interest__tip">
    <PxIcon
      :size="14"
      class="px-matching-interest__tipIcon"
      name="star--yellow"
    />
    {{ matchingTip }}
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import PxMatchingInterestMixin from "@/mixins/px-matching-interest.mixin";

export default defineComponent({
  name: "PxMatchingInterestTip",

  mixins: [PxMatchingInterestMixin],

  computed: {
    matchingTip(): string {
      if (this.targetInterest)
        return this.$t(
          `matching.matchingInterest.cta[${this.currentCTAState}].tip`,
          { company: this.name },
        ) as string;

      return "";
    },
  },
});
</script>

<style lang="scss" scoped>
.px-matching-interest__tip {
  @include grotesk(medium);

  max-width: 290px;
  margin: 4px 0;
  font-size: 14px;
  line-height: 17px;
  color: $golden;
  text-align: left;

  @include breakpoint-up(xl) {
    max-width: 230px;
  }
}

.px-matching-interest__tipIcon {
  position: relative;
  top: 2px;
  margin-right: 1px;
}
</style>
