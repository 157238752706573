import { TranslateResult } from "vue-i18n";

/**
 * Type to be used to define a exclusion list.
 */
type TExclusion = Array<string> | string;

/**
 * Interface that represents the error message.
 */
export interface IErrorMessage {
  /**
   * Error message.
   */
  message: string;

  /**
   * Error code.
   */
  code: string;
}

/**
 * This type represents and error.
 *
 * This can be nested until it gets to the invalid field.
 */
interface IError {
  [key: string]: Array<IErrorMessage> | IError;
}

/**
 * Type that represents the object with error messages.
 */
interface ICustomErrorMessages {
  [key: string]: string | ICustomErrorMessages | TranslateResult;
}

/**
 * Errors manager class.
 *
 * The field parameters supports nested field. In order to
 * reference to one you must use dots to divide the
 * nesting levels:
 *    `company.location.latitude`
 */
export class ErrorsManager {
  /**
   * THis will store the server errors.
   */
  private errors: IError = {};

  /**
   * Custom messages for the field.
   */
  private customMessages: ICustomErrorMessages = {};

  /**
   * Creates a new ErrorManager instance.
   *
   * @param customMessages (Optional) Custom messages.
   */
  constructor(customMessages: ICustomErrorMessages = {}) {
    this.customMessages = customMessages;
  }

  /**
   * Returns stored errors.
   */
  public getErrors(): IError {
    return this.errors;
  }

  /**
   * Store the new set of errors.
   *
   * @param errors New set of errors.
   */
  public record(errors: IError) {
    this.errors = errors;
  }

  /**
   * Set the custom messages for the errors.
   *
   * @param customMessages Custom messages.
   */
  public setCustomMessages(customMessages: ICustomErrorMessages) {
    this.customMessages = customMessages;
  }

  /**
   * Determine if there is any errors.
   */
  public any(): boolean {
    return Object.keys(this.errors).length > 0;
  }

  /**
   * Check if the given field has an error.
   *
   * @param field Field to check.
   * @param exclusion
   */
  public has(field: string, exclusion: TExclusion = []): boolean {
    let fieldErrors = this.getFlash(field);
    if (exclusion.length > 0) {
      fieldErrors = this.applyErrorsFilter(fieldErrors, exclusion, false);
    }

    return !!fieldErrors;
  }

  /**
   * Get the list of all errors for the given field, if any.
   *
   * This returns false if there is no error.
   *
   * @param field Field name.
   * @param exclusion
   */
  public getFlash(
    field: string,
    exclusion: TExclusion = [],
  ): Array<IErrorMessage> | null {
    const levels: Array<string> = field.split(".");
    let qsCount = 0;
    let key: string = levels[qsCount];
    let curLevel: IError = this.errors;

    do {
      // If `key` doesn't exists on the object that
      // means that there is no error for the given
      // field.
      if (!Object.prototype.hasOwnProperty.call(curLevel, key)) {
        return null;
      }

      // When the type of is an array that means that
      // there is errors for the given field.
      if (Array.isArray(curLevel[key])) {
        const errors = curLevel[key] as Array<IErrorMessage>;
        return this.applyErrorsFilter(errors, exclusion);
      }

      ++qsCount;
      curLevel = curLevel[key] as IError;
      key = levels[qsCount];
    } while (qsCount < levels.length);

    return null;
  }

  /**
   * Get the first error for the given field.
   *
   * This returns false if there is no error.
   *
   * @param field Field name.
   * @param exclusion
   */
  public get(field: string, exclusion: TExclusion = []): IErrorMessage | null {
    const result = this.getFlash(field, exclusion);
    return result === null ? null : (result as Array<IErrorMessage>)[0];
  }

  /**
   * Get the first error message for the field.
   *
   * @param field Field to get the message.
   * @param exclusion
   */
  public getMessage(field: string, exclusion: TExclusion = []): string | null {
    const result = this.get(field, exclusion);
    return result === null ? null : this.getCustomError(field, result);
  }

  /**
   * Clear one or all the fields.
   *
   * @param field (Optional) Field to be cleared.
   */
  public clear(field: string | null = null) {
    if (field === null) {
      this.errors = {};
      return;
    }

    const path = field.split(".");
    let obj: IError | Array<IErrorMessage> = this.errors;

    for (let i = 0; i < path.length - 1; i++) {
      obj = this.errors[path[i]];

      if (typeof obj === "undefined") {
        return;
      }
    }

    delete (obj as any)[path.pop() as string];
  }

  /**
   * Retrieves a custom message for the path and error given.
   *
   * @param path Field path
   * @param error Error that is being evaluated.
   */
  private getCustomError(path: string, error: IErrorMessage): string {
    if (!Object.prototype.hasOwnProperty.call(this.customMessages, path)) {
      return error.message;
    }

    // Check if the field uses an unique error message for all errors
    const customMessagesForField = this.customMessages[path];
    if (typeof customMessagesForField === "string") {
      return customMessagesForField;
    }

    // Custom error messages can have specific messages for each error
    // type or fallback message, in the cases that a fallback isn't
    // specified return the original error message.
    return (
      (customMessagesForField[error.code] as string) ||
      (customMessagesForField["*"] as string) ||
      error.message
    );
  }

  /**
   * This will apply a filter to the list of errors.
   *
   * This filter can be inclusive or exclusive, depending
   * on the state of the `isExclusion`.
   *
   * @param errors list of errors here the filter will be applied
   * @param filterList List of errors to filter
   * @param isExclusion By default it will apply a exclusion logic,
   *  to change it you must set it to `false`.
   */
  private applyErrorsFilter(
    errors: Array<IErrorMessage> | null,
    filterList: TExclusion = [],
    isExclusion = true,
  ): Array<IErrorMessage> | null {
    if (!errors) {
      return null;
    }

    // Convert the filter list into an array to simply
    // the verification login
    const typesToExclude = Array.isArray(filterList)
      ? filterList
      : [filterList];

    // Apply a filter to the list of errors, based in the
    // given options
    const filteredErrors = errors.filter(
      (entry: IErrorMessage) =>
        typesToExclude.includes(entry.code) !== isExclusion,
    );

    // When there is no errors a `null` must be returned
    // to simplify the life of the ones using this class 😘
    return filteredErrors.length === 0 ? null : filteredErrors;
  }
}
