import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, withCtx as _withCtx, createElementVNode as _createElementVNode, vShow as _vShow, mergeProps as _mergeProps, withDirectives as _withDirectives, withModifiers as _withModifiers, Fragment as _Fragment, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-293381b6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "comparison-table__header" }
const _hoisted_3 = { class: "comparison-table__wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RolePlayingBanner = _resolveComponent("RolePlayingBanner")!
  const _component_ShareResults = _resolveComponent("ShareResults")!
  const _component_PxPageHeader = _resolveComponent("PxPageHeader")!
  const _component_PxButton = _resolveComponent("PxButton")!
  const _component_UserResponsesTable = _resolveComponent("UserResponsesTable")!
  const _component_CompareResponsesTable = _resolveComponent("CompareResponsesTable")!
  const _component_MatchDetailsTableLegend = _resolveComponent("MatchDetailsTableLegend")!
  const _directive_loading = _resolveDirective("loading")!

  return _withDirectives((_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([{ 'comparison-table--no-labels': !_ctx.allQuestionsAnswered }, "comparison-table"]),
    "element-loading-background": "#fafafa"
  }, [
    (!_ctx.loading && _ctx.capitalExplorerTilesResults && _ctx.dataPrefilled)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          (_ctx.$user.isSupporter())
            ? (_openBlock(), _createElementBlock("header", _hoisted_1, [
                _createVNode(_component_RolePlayingBanner)
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("main", null, [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_PxPageHeader, {
                subtitle: _ctx.headerCopy.subtitle,
                title: _ctx.headerCopy.title,
                onBackButtonClicked: _ctx.goToCapitalExplorer
              }, {
                action: _withCtx(() => [
                  (_ctx.$user.isEntrepreneur())
                    ? (_openBlock(), _createBlock(_component_ShareResults, { key: 0 }))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }, 8, ["subtitle", "title", "onBackButtonClicked"])
            ]),
            _createElementVNode("div", _hoisted_3, [
              _withDirectives(_createVNode(_component_PxButton, _mergeProps(_ctx.$options.static.backButton, {
                ref: "leftButton",
                class: "comparison-table__left-button",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.scroll('left')))
              }), null, 16), [
                [_vShow, _ctx.showLeftButton]
              ]),
              _withDirectives(_createVNode(_component_PxButton, _mergeProps(_ctx.$options.static.backButton, {
                ref: "rightButton",
                class: "comparison-table__right-button",
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.scroll('right')))
              }), null, 16), [
                [_vShow, _ctx.showRightButton]
              ]),
              _createElementVNode("div", {
                ref: "scrollContainer",
                class: "comparison-table__scroll",
                onMousedown: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.handleMouseDown && _ctx.handleMouseDown(...args))),
                onScroll: _cache[3] || (_cache[3] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.handleScroll && _ctx.handleScroll(...args)), ["stop"]))
              }, [
                _createVNode(_component_UserResponsesTable, {
                  "all-questions-answered": _ctx.allQuestionsAnswered,
                  "table-data": _ctx.userResponsesTableData,
                  class: "comparison-table__left-table"
                }, null, 8, ["all-questions-answered", "table-data"]),
                _createVNode(_component_CompareResponsesTable, {
                  "all-questions-answered": _ctx.allQuestionsAnswered,
                  "answers-data": _ctx.compareResponsesTableData,
                  "match-details-modal-data": _ctx.capitalExplorerTilesOrdered,
                  "table-headers": _ctx.tableHeaders,
                  class: "comparison-table__right-table"
                }, null, 8, ["all-questions-answered", "answers-data", "match-details-modal-data", "table-headers"])
              ], 544)
            ]),
            _createVNode(_component_MatchDetailsTableLegend, {
              "legend-cards": _ctx.tableLegend,
              class: "comparison-table__legend"
            }, null, 8, ["legend-cards"])
          ])
        ], 64))
      : _createCommentVNode("", true)
  ], 2)), [
    [_directive_loading, _ctx.loading || !_ctx.capitalExplorerTilesResults || !_ctx.dataPrefilled]
  ])
}