<template>
  <div class="profile-base page-full-height">
    <VerifyAccountBanner
      v-if="verifyAccountBannerVisibility"
      @close="verifyAccountBannerVisibility = false"
    />
    <ProfileDesktop
      v-if="$screen.mdUp"
      @show-modal="onShowListManagementModal"
      @open-modal="setModalVisible"
    />
    <ProfileMobile v-else />
    <template v-if="showJoinUsBar">
      <VisitorJoinBar v-if="$screen.mdUp" />
      <VisitorJoinBarMobile v-if="$screen.smDown" />
    </template>
    <UpgradeModal
      v-model:visibility="isUpgradeModalVisibility"
      :is-level-up="isLevelUp"
      data-testid="upgrade-modal"
      @close="cleanQuickLevelMeta"
    />
    <LevelUpdateModal
      v-if="showLevelUpdateModal"
      :visibility="showLevelUpdateModal"
      data-testid="level-update-modal"
    />
    <VerifyAccountModal
      v-if="needsToVerifyAccount && $user.isEntrepreneur()"
      :visibility="needsToVerifyAccount"
    />
    <SubscriptionModal
      v-if="subscriptionModalVisibility"
      v-model:visibility="subscriptionModalVisibility"
    />
    <ListManagementBar />
  </div>
</template>

<script lang="ts">
import { h, defineComponent, resolveComponent, markRaw, Component } from "vue";
import set from "lodash/set";

import VisitorJoinBar from "@/modules/profile/components/visitor-join-bar/visitor-join-bar.vue";
import VisitorJoinBarMobile from "@/modules/profile/components/visitor-join-bar-mobile/visitor-join-bar-mobile.vue";
import ProfileDesktop from "@/modules/profile/components/profile-desktop/profile-desktop.vue";
import ProfileMobile from "@/modules/profile/components/profile-mobile/profile-mobile.vue";
import UpgradeModal from "@/modules/common/components/upgrade-modal/upgrade-modal.vue";
import LevelUpdateModal from "@/modules/common/components/level-update-modal/level-update-modal.vue";
import VerifyAccountModal from "@/modules/profile/components/verify-account-modal/verify-account-modal.vue";
import ListManagementBar from "@/modules/company-lists/components/list-management/list-management-bar.vue";
import SubscriptionModal from "@/modules/profile/components/subscription-modal/subscription-modal.vue";
import VerifyAccountBanner from "@/modules/profile/components/verify-account-banner/verify-account-banner.vue";

import { EProfileCompanyActions } from "@/modules/profile/services/store/profile/profile-types";
import { ICompany } from "@/modules/profile/services/data/company/company.types";
import {
  PROFILE_META_TAB_MENU_ITEMS,
  PROFILE_META_SUBSCRIPTION_MODAL,
} from "@/modules/profile/constants";
import { EMetaActions } from "@/services/store/meta/meta-types";
import { EMatchingInterestActions } from "@/modules/matching/services/store/matching-interest/matching-interest.types";
import { MessageHandler } from "element-plus";
import { resendEmailProvider } from "@/modules/authentication/services/data/resend-email/resend-email.provider";
import { EListManagementActions } from "@/modules/company-lists/services/store/list-management/list-management.types";
import {
  QUICK_LEVEL_UPDATE_META_ON_UPDATE,
  QUICK_LEVEL_UPDATE_META_UPGRADE,
  QUICK_LEVEL_UPDATE_META_SAME_LEVEL,
  QUICK_LEVEL_UPDATE_META_FIRST_ASSESSMENT,
} from "@/modules/common/constants";
import { activeModules } from "@/services/utils/utils";
import {
  EUserMetadataGetters,
  EUserMetadataActions,
} from "@/modules/authentication/services/store/auth/sub-modules/user-metadata/user-metadata.types";

export default defineComponent({
  name: "ProfileView",

  components: {
    VisitorJoinBar,
    VisitorJoinBarMobile,
    ProfileDesktop,
    ProfileMobile,
    UpgradeModal,
    LevelUpdateModal,
    VerifyAccountModal,
    ListManagementBar,
    SubscriptionModal,
    VerifyAccountBanner,
  },

  data() {
    return {
      modalsVisible: {},
      isUpgradeModalVisibility: false,
      isNewLevelModalVisible: false,
      infoMessage: null as null | MessageHandler,
      subscriptionModalVisibility: false,
      verifyAccountBannerVisibility: false,
      PxButton: markRaw(resolveComponent("PxButton") as Component),
    };
  },

  computed: {
    idFromParams(): string {
      return this.$route.params.id as string;
    },

    companyId(): number {
      const company = this.$store.get("auth/company.data");
      return this.idFromParams
        ? this.idFromParams
        : company
          ? company.id
          : null;
    },

    company(): ICompany | null {
      return this.$store.get("profile/company/data");
    },

    isAdminAsUser(): boolean {
      return this.$store.state.auth.isAdmin;
    },

    canMatch(): boolean {
      return (
        this.$features.isEnabled("matchingInterest") &&
        this.$user.isLogged() &&
        !this.$user.isOwner() &&
        this.$profile.type() !== this.$user.getUserAccountType()
      );
    },

    needsToVerifyAccount(): boolean {
      return this.$user.isOwner() && !this.$user.hasVerifiedAccount();
    },

    authUserEmail(): string {
      return this.$store.get("auth/user.email");
    },

    isAuthUser(): boolean {
      return this.$user.isLogged();
    },

    showPublicView(): boolean {
      return !this.isAuthUser;
    },

    hasRegistrationListener(): boolean {
      return !!this.$attrs?.onRegister;
    },

    isSpecialGuest(): boolean {
      return !!this.$route.meta?.specialGuest;
    },

    showJoinUsBar(): boolean {
      return !this.$user.isLogged() && !this.isSpecialGuest;
    },

    viralLevelNotChanged(): boolean {
      return this.$store.get("meta/get", QUICK_LEVEL_UPDATE_META_SAME_LEVEL);
    },

    isLevelUp(): boolean {
      return this.$store.get("meta/get", QUICK_LEVEL_UPDATE_META_UPGRADE);
    },

    showLevelUpdateModal(): boolean {
      return (
        this.isNewLevelModalVisible &&
        this.$user.isOwner() &&
        this.$user.isEntrepreneur()
      );
    },

    isMatchingEnabled(): boolean {
      return (
        this.$features.isEnabled("match") &&
        activeModules().includes("matching")
      );
    },

    hasSeenSubscriptionModal(): boolean {
      return !!this.$store.get(
        EUserMetadataGetters.GET,
        PROFILE_META_SUBSCRIPTION_MODAL,
      );
    },
  },

  watch: {
    canMatch() {
      if (this.isMatchingEnabled) {
        this.setUserCanMatchWithProfile();
      }
    },

    company() {
      this.setTabMenuItems();
    },

    needsToVerifyAccount: {
      immediate: true,
      handler(stillPending) {
        if (stillPending) {
          if (this.$user.isEntrepreneur()) {
            this.setVerifyAccountMessage();
          } else {
            this.verifyAccountBannerVisibility = true;
          }
        }
      },
    },
  },

  mounted() {
    setTimeout(() => {
      if (
        this.$features.isEnabled("settingsPage") &&
        this.$user.isLogged() &&
        this.$user.isOwner() &&
        this.$user.isSupporter() &&
        !this.hasSeenSubscriptionModal
      ) {
        this.subscriptionModalVisibility = true;
        this.markAsSeenSubscriptionModal();
      }
    }, 2000);
  },

  beforeUnmount() {
    if (this.infoMessage) {
      this.infoMessage.close();
    }
    this.$emitter.off("save-new-assessment", this.onAfterSaveNewLevel);
  },

  created() {
    this.shouldShowNewLevelModal();
    this.setTabMenuItems();
    this.setAdminAsUserMessage();
    if (this.isMatchingEnabled) {
      this.setUserCanMatchWithProfile();
    }
    this.checkQuickLevelUpdate();
  },

  methods: {
    checkQuickLevelUpdate() {
      this.$emitter.on("save-new-assessment", this.onAfterSaveNewLevel);
    },

    /**
     * Define the items for the mobile tabs menu.
     */
    setTabMenuItems() {
      const items = this.$profile.getByType(
        "tabMenuItems",
        this.$profile.type(),
      );

      this.$store.dispatch(EMetaActions.SET, {
        key: PROFILE_META_TAB_MENU_ITEMS,
        value: items,
      });
    },

    /**
     * Set the modal visibility.
     */
    setModalVisible(modal: string) {
      set(this.modalsVisible, modal, true);
    },

    /**
     * Check if the the new level modal or the upgrade modal
     * should be shown based on the quick level update.
     */
    shouldShowNewLevelModal() {
      this.isUpgradeModalVisibility =
        this.$store.get("meta/get", QUICK_LEVEL_UPDATE_META_FIRST_ASSESSMENT) ||
        false;
      this.isNewLevelModalVisible =
        (this.$store.get("meta/get", QUICK_LEVEL_UPDATE_META_ON_UPDATE) &&
          !this.isUpgradeModalVisibility) ||
        false;
    },

    /**
     * If the current level and the previous level are equal
     * should show only a toast message instead of a modal
     */
    shouldShowSameLevelMessage() {
      this.cleanQuickLevelMeta();
      return this.$message({
        message: this.$t(
          "common.components.levelUpdateModal.sameLevelSuccessMessage",
        ) as string,
        type: "success",
        duration: 10000,
        customClass: "is-full",
      });
    },

    /**
     * Handle the user level change.
     *
     * This will request a profile refresh to refresh the page
     * with the new user data.
     */
    onAfterSaveNewLevel() {
      this.$store.dispatch(EProfileCompanyActions.FETCH, this.companyId);
      this.viralLevelNotChanged
        ? this.shouldShowSameLevelMessage()
        : (this.isUpgradeModalVisibility = true);
    },

    /**
     * Cleanup the sore after perform a upgrade operation.
     */
    cleanQuickLevelMeta() {
      this.$store.dispatch(
        EMetaActions.REMOVE,
        QUICK_LEVEL_UPDATE_META_SAME_LEVEL,
      );
      this.$store.dispatch(
        EMetaActions.REMOVE,
        QUICK_LEVEL_UPDATE_META_UPGRADE,
      );
      this.$store.dispatch(
        EMetaActions.REMOVE,
        QUICK_LEVEL_UPDATE_META_ON_UPDATE,
      );
      this.$store.dispatch(
        EMetaActions.REMOVE,
        QUICK_LEVEL_UPDATE_META_FIRST_ASSESSMENT,
      );
    },

    /**
     * Warn admin that is logged as a user.
     */
    setAdminAsUserMessage() {
      if (this.isAdminAsUser) {
        this.$message({
          message: this.$t(
            "authentication.login.messages.adminAsUser",
          ) as string,
          type: "info",
          customClass: "is-navbar",
          duration: 0,
        });
      }
    },

    setUserCanMatchWithProfile() {
      this.$store.dispatch(
        EMatchingInterestActions.SET_CAN_MATCH,
        this.canMatch,
      );
    },

    setVerifyAccountMessage() {
      if (!this.needsToVerifyAccount || !!this.infoMessage) {
        return;
      }

      const message = this.$t(
        "authentication.login.errors.emailNotValidated",
      ) as string;
      const resendLinkText = this.$t(
        "authentication.login.resendLink",
      ) as string;

      const vNode = h("p", { class: "el-message__content" }, [
        message,
        h(
          this.PxButton,
          {
            type: "link",
            onClick: this.onClickResendEmail,
            class: "el-button--link-white",
          },
          {
            default: () => resendLinkText,
          },
        ),
      ]);

      this.infoMessage = this.$message({
        message: vNode,
        type: "info",
        duration: 0,
        customClass: "is-navbar",
      });
    },

    async onClickResendEmail() {
      if (this.infoMessage !== null) {
        this.infoMessage.close();
      }

      await resendEmailProvider.create({
        email: this.authUserEmail,
      });

      this.$message({
        message: this.$t(
          "authentication.login.messages.confirmationEmail",
        ) as string,
        type: "success",
        customClass: "is-navbar",
      });
    },

    onRegistration() {
      this.$emit("register");
    },

    onShowListManagementModal() {
      if (
        !this.company ||
        (this.showPublicView && this.hasRegistrationListener)
      ) {
        this.onRegistration();
        return;
      }

      // Select self
      this.$store.dispatch(
        EListManagementActions.ADD_COMPANY,
        this.company.uid,
      );

      // Emit root action, should be available on context
      this.$emitter.emit("show-list-management-modal");
    },

    markAsSeenSubscriptionModal() {
      this.$store.dispatch(EUserMetadataActions.SET, {
        key: PROFILE_META_SUBSCRIPTION_MODAL,
        value: "true",
      });
    },
  },
});
</script>

<style lang="scss" scoped>
/* TODO: move some of this styles to another component */
.profile-base :deep() .profile-headline {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}

.profile-base :deep() .profile-headline__text-wrapper {
  flex-basis: 45%;
  flex-grow: 1;
  word-break: break-word;

  @include breakpoint-only(lg) {
    flex-basis: 35%;
  }
}

.profile-base :deep() .profile-headline__title {
  margin: 0;
  font-size: to-rem(18px);
  line-height: 21px;
  letter-spacing: -0.2px;

  @include breakpoint-up(md) {
    font-size: to-rem(22px);
    line-height: 27px;
  }
}

.profile-base :deep() .profile-headline__subtitle {
  @include grotesk(regular);

  margin: 6px 0 0;
  font-size: to-rem(15px);
  line-height: 18px;
  letter-spacing: -0.2px;

  @include breakpoint-up(md) {
    margin: 3px 0 0;
  }
}

.profile-base :deep() .profile-headline__pin-item {
  @include grotesk(medium);

  position: relative;
  margin-left: 3px;
  color: $manatee;
  letter-spacing: -0.1px;

  &::before {
    display: inline-block;
    width: 4px;
    height: 4px;
    margin-right: 6px;
    vertical-align: 1px;

    content: "";
    background-color: currentColor;
    border-radius: 100%;

    @include breakpoint-up(md) {
      width: 5px;
      height: 5px;
    }
  }
}

.profile-base :deep() .profile-photo {
  flex-shrink: 0;
  font-size: 0;
  line-height: 0;
  border-radius: 3px;
}

.profile-base :deep() .profile-panel--inactive {
  border-color: rgba($pale-grey, 0.7);
  opacity: 0.7;

  .px-panel__header {
    background-color: white;
  }

  .px-panel__title {
    color: $manatee;
  }

  .vue-content-placeholders-text__line {
    height: 6px;
    margin-bottom: 0;

    background-color: $alto;
    border-radius: 5.5px;

    &:nth-child(2n) {
      margin-left: 10px;
      background-color: $pale-grey-two;
    }

    &:nth-child(4n + 1) {
      width: 77%;
    }

    &:nth-child(4n + 2) {
      width: 70%;
    }

    &:nth-child(4n + 3) {
      width: 89%;
    }

    &:nth-child(4n + 4) {
      width: 76%;
    }

    &:not(:first-child) {
      margin-top: 30px;
    }
  }
}

.profile-base :deep() .profile-panel--disabled {
  background-color: rgba(white, 0.34);
  border-color: rgba($pale-grey, 0.34);

  .profile-headline__title {
    color: $manatee;
    opacity: 0.7;
  }
}
</style>
