<template>
  <div :class="{ 'is-active': onSearchMode }" class="share-modal-users-search">
    <div class="share-modal-users-search__form">
      <label class="el-form-item__label" for="user-search">
        {{ $t(`${commonShareCopy}.searchInput.label`) }}
      </label>
      <ElSelect
        id="user-search"
        ref="select"
        v-model="selectedUsers"
        :class="{ 'has-users': selectedUsers.length }"
        :data-suffix="$t(`${commonShareCopy}.searchInput.clear`)"
        :loading="isLoading"
        :placeholder="$t(`${commonShareCopy}.searchInput.placeholder`)"
        :remote-method="searchForUsers"
        :filter-method="handleSelectChange"
        :reserve-keyword="false"
        class="share-modal-users-search__input"
        clearable
        default-first-option
        filterable
        multiple
        popper-class="share-modal-users-popper"
        remote
        @clear="clearHandler"
        @focus="focusHandler"
        @visible-change="dropdownChangeHandler"
      >
        <template #empty>
          <div v-loading="isLoading">
            <i18n-t
              :keypath="`${commonShareCopy}.searchSelect.empty.title.content`"
              tag="span"
            >
              <template #user>
                <strong>
                  {{
                    $t(
                      `${commonShareCopy}.searchSelect.empty.title.placeholders[0]`,
                    )
                  }}
                </strong>
              </template>
              <template #account>
                <strong>
                  {{
                    $t(
                      `${commonShareCopy}.searchSelect.empty.title.placeholders[1]`,
                    )
                  }}
                </strong>
              </template>
            </i18n-t>
            <span>
              {{ $t(`${copy}.searchSelect.empty.subtitle`) }}
            </span>
          </div>
        </template>
        <ElOption
          v-if="hasEmptyInput"
          :disabled="true"
          :value="false"
          class="share-modal-users-search__tip"
        >
          <i18n-t
            :keypath="`${commonShareCopy}.searchSelect.tip.content`"
            tag="span"
          >
            <template #name>
              <strong>
                {{ $t(`${commonShareCopy}.searchSelect.tip.placeholder`) }}
              </strong>
            </template>
          </i18n-t>
        </ElOption>
        <ElOption
          v-for="item in searchResults"
          :key="item.user_profile"
          :label="item.name"
          :value="item.user_profile"
          class="share-modal-users-search__option"
          :class="{ 'is-disabled': shouldUserBeDisabled(item.user_profile) }"
        >
          <div class="share-modal-users-search__option-wrapper">
            <picture v-if="item.logo">
              <img :alt="item.name" :src="item.logo" />
            </picture>
            <h4 v-html="highlightWordMatch(item.name, searchNeedle)" />
            <span
              v-if="item.email"
              v-html="highlightWordMatch(item.email, searchNeedle)"
            />
          </div>
        </ElOption>
      </ElSelect>
      <i18n-t
        :keypath="`${copy}.searchInput.tip.content`"
        class="el-form-item__bottom-text el-form-item__tip"
        tag="span"
      >
        <template #placeholder>
          <strong class="el-form-item__bottom-text--strong">
            {{ $t(`${copy}.searchInput.tip.placeholder`) }}
          </strong>
        </template>
      </i18n-t>
    </div>
    <div v-if="onSearchMode" class="share-modal-users-search__submission">
      <PxButton
        class="share-modal-users-search__submission-link"
        size="small"
        type="link"
        @click="onClickCancelHandler"
      >
        {{ $t("common.cancel") }}
      </PxButton>
      <PxButton
        :disabled="!hasSelectedUsers"
        class="share-modal-users-search__submission-button"
        type="green"
        @click="onClickSendEmailHandler"
      >
        {{ $t("common.sendEmail") }}
      </PxButton>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import throttle from "lodash/throttle";

import { companySearchProvider } from "@/services/data/company-search/company-search.provider";
import { ICompanySearch } from "@/services/data/company-search/company-search.interface";
import { ICompanyListInvitedUser } from "@/modules/company-lists/services/data/company-list/company-list.interface";

export default defineComponent({
  name: "ShareModalUsersSearch",

  props: {
    shareSucceeded: {
      type: Boolean,
      default: false,
    },

    users: {
      type: Array as () => Array<ICompanyListInvitedUser>,
      required: true,
    },

    filterBy: {
      type: Number,
      default: 0,
    },

    copy: {
      type: String,
      default: "capitalExplorer.shareModal",
    },
  },

  data() {
    return {
      isLoading: false,
      onSearchMode: false,
      searchNeedle: "",
      searchResults: [] as any,
      searchRequests: [] as Array<Promise<void>>,
      selectEl: null,
      selectedUsers: [] as Array<string>,
      isUserDisabled: false,
    };
  },

  computed: {
    authCompanyUid(): string | undefined {
      return this.$store.state.auth.company?.data?.uid;
    },

    commonShareCopy(): string {
      return "common.shareModal";
    },

    alreadyAddedUsersUserProfile(): Array<string | null> {
      return this.users.map((user) => user.user_profile);
    },

    hasEmptyInput(): boolean {
      return !this.searchNeedle;
    },

    hasSearchResults(): boolean {
      return !!this.searchResults.length;
    },

    isMakingRequests(): boolean {
      return !!this.searchRequests.length;
    },

    hasClearedSearch(): boolean {
      return this.isMakingRequests && !this.searchNeedle;
    },

    hasSelectedUsers(): boolean {
      return !!this.selectedUsers.length;
    },
  },

  watch: {
    shareSucceeded(success: boolean) {
      if (success) {
        this.selectedUsers = [];
        this.clearSearchResults();
        this.onSearchMode = false;
      }
    },
  },

  mounted() {
    (this as any).selectEl = this.$refs.select;
  },

  methods: {
    async doCompanySearch(query: string) {
      try {
        const data = await companySearchProvider.search(query, this.filterBy);

        if (!this.hasClearedSearch) {
          // Map backend data and filter out auth company from results
          this.searchResults = data.results
            .map((company) => ({
              uid: company.uid,
              name: company.name,
              email: company.email,
              logo: company.logo,
              user_profile: company.user_profile,
            }))
            .filter((company: ICompanySearch) => {
              return company.uid !== this.authCompanyUid;
            });
        }
      } finally {
        this.isLoading = false;
        this.searchRequests.shift();
      }
    },

    shouldUserBeDisabled(user: string) {
      return (
        this.selectedUsers.includes(user) ||
        this.alreadyAddedUsersUserProfile.includes(user)
      );
    },

    searchForUsers(query: string) {
      if (!query) {
        this.clearSearchResults();
        this.isLoading = false;
        return;
      }

      this.isLoading = true;

      throttle(() => {
        this.searchRequests.push(this.doCompanySearch(query));
      }, 1000)();
    },

    clearSearchResults() {
      this.searchResults = [];
      this.searchNeedle = "";
    },

    focusHandler() {
      this.onSearchMode = true;

      // Reset results:
      if (!this.searchNeedle) {
        this.clearSearchResults();
      }
    },

    clearHandler() {
      this.clearSearchResults();
      // To avoid hiding "search mode":
      (this as any).$refs.select.$el.querySelector("input").click();
    },

    dropdownChangeHandler(isVisible: boolean) {
      // NOTE: Using visible-change event since blur does not work.
      // Hide "search mode" on blur if no users were selected:
      if (!isVisible && !this.hasSelectedUsers) {
        this.onSearchMode = false;
      }
    },

    onClickCancelHandler() {
      this.selectedUsers = [];
      this.clearSearchResults();
      this.onSearchMode = false;
    },

    onClickSendEmailHandler() {
      this.$emit("send-email", this.selectedUsers);
    },

    highlightWordMatch(text: string, query: string) {
      const check = new RegExp(query, "ig");

      // Skip letters.
      if (query.length < 2) return text;

      return text.toString().replace(check, (matchedText) => {
        return "<strong>" + matchedText + "</strong>";
      });
    },

    handleSelectChange(value: string) {
      this.searchNeedle = value;

      if (!value) {
        this.clearSearchResults();
      }

      this.searchForUsers(value);
    },
  },
});
</script>

<style lang="scss" scoped>
.share-modal-users-search {
  padding-top: 4px;

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    z-index: z("floaters") + 1;
    width: 100%;
    height: 100%;
    pointer-events: none;
    content: "";
    background-color: $white;
    opacity: 0;
  }

  .el-form-item__label {
    padding-top: 0;

    @include breakpoint-up(md) {
      margin-bottom: 4px;
    }
  }

  .el-form-item__tip {
    line-height: 18px;
    text-align: right;
  }

  .el-form-item__bottom-text {
    padding-top: 5px;
    letter-spacing: -0.07px;
    align-self: flex-end;
  }

  .el-form-item__bottom-text--strong {
    position: relative;
    right: 1px;
  }

  &__input::after {
    @include grotesk(medium);

    position: absolute;
    top: 11px;
    right: 16px;

    font-size: 14px;
    line-height: 17px;
    color: $ebony-clay;
    // This is an alternative to not having a slot for the suffix:
    pointer-events: none;
    content: attr(data-suffix);
    user-select: none;
    opacity: 0;
  }
}

.share-modal-users-search__form {
  position: relative;
  z-index: z("floaters") + 2;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.share-modal-users-search__input.has-users {
  &::after {
    opacity: 1;
  }

  :deep(.el-input__suffix) {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    width: 65px;
    height: 100%;
    pointer-events: auto;
  }

  :deep(.el-select__caret) {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: transparent;
  }
}

.share-modal-users-search__option {
  min-height: 52px;
  padding-top: 6px;

  &::before {
    height: 100%;
    margin: 0 7px;
    border-radius: 4px;
  }

  &-wrapper {
    position: relative;
    z-index: 1;
    display: grid;
    grid-template-areas:
      "image title"
      "image subtitle";
    grid-template-rows: 1fr auto;
    grid-template-columns: auto 1fr;
    gap: 0 9px;
    align-items: center;
  }

  picture {
    grid-area: image;
    width: 40px;
    height: 40px;
    overflow: hidden;
    background-color: white;
    border-radius: 100%;
  }

  picture > img {
    position: relative;
    top: 50%;
    left: 50%;
    width: 35px;
    height: 35px;
    object-fit: cover;
    object-position: center;
    border-radius: 100%;
    box-shadow: inset 0 0 3px 0 rgba($manatee, 0.3);
    transform: translate(-50%, -50%);
  }

  h4 {
    @include grotesk(regular);

    position: relative;
    top: -2px;
    grid-area: title;
    margin-bottom: 0;
    font-size: 15px;
    line-height: 18px;

    &:first-child {
      padding-top: 5px;
    }

    &:only-child {
      padding-top: 13px;
    }
  }

  h4 strong {
    line-height: 18px;
  }

  span {
    position: relative;
    top: -4px;
    grid-area: subtitle;
    font-size: 13px;
    line-height: 16px;
    color: $manatee;
    letter-spacing: -0.2px;
  }

  span strong {
    line-height: 16px;
  }

  &.is-disabled {
    pointer-events: none;
    opacity: 0.5;

    &::before {
      opacity: 0;
    }
  }
}

.share-modal-users-search__submission {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: z("floaters") + 2;
  width: 100%;
  padding: 32px;
  text-align: right;
  pointer-events: none;
  background-color: $white;
  opacity: 0;

  &-button {
    padding: 0 15px;
    margin-left: 11px;
  }

  &-button span {
    font-size: 13px;
    letter-spacing: -0.1px;
  }
}

.share-modal-users-search.is-active {
  &::before {
    opacity: 1;
  }

  .share-modal-users-search__submission {
    pointer-events: auto;
    opacity: 1;
  }
}

.share-modal-users-search__input {
  position: relative;
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  cursor: text;

  :deep(.el-select__tags) {
    position: absolute;
    top: 50%;
    z-index: 1;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding-right: 30px;
    line-height: normal;
    white-space: normal;
    transform: translateY(-50%);
  }

  :deep(.el-select__wrapper) {
    width: 100%;
    padding: 0 15px;
    background: transparent;
    cursor: text;
  }

  :deep(.el-select__placeholder) span {
    font-size: 14px;
    color: $manatee;
  }

  :deep(.el-input__suffix) {
    // Disable open select button
    pointer-events: none;
    opacity: 0;
  }

  :deep(.el-select__wrapper).is-hovering {
    border-color: $malibu;
    box-shadow: 0 0 6px 0 rgba(59, 135, 230, 0.2);
  }
}
</style>

<style lang="scss">
.share-modal-users-popper {
  min-height: 166px;
  margin-top: 9px;
  transform: translateZ(0);

  .share-modal-users-search__tip {
    height: 152px;
  }

  .el-select-dropdown__list {
    padding: 3px 0;
  }

  .el-select-dropdown__item {
    padding-right: 14px;
    padding-left: 14px;
  }

  .el-select-dropdown__item:not(.share-modal-users-search__tip).is-disabled {
    opacity: 0.5;
  }

  .el-select-dropdown__empty {
    max-width: 596px;
    height: 164px;
    padding-top: 14px;
    padding-right: 30px;
    padding-bottom: 20px;
    opacity: 0;
    animation: fade-in 0.3s 1s forwards;

    div {
      display: flex;
      flex-flow: column wrap;
      justify-content: space-between;
      height: 100%;
    }
  }

  .el-select-dropdown__empty span,
  .el-select-dropdown__item.is-disabled span {
    font-size: 14px;
    letter-spacing: -0.24px;
  }

  .el-select-dropdown__empty span:first-of-type {
    line-height: 1.3;
  }

  .el-select-dropdown__empty.px-loading-parent--relative span {
    opacity: 0;
  }
}
</style>
