import { Module } from "vuex";
import { make } from "vuex-pathify";

import { RootState } from "@/services/store/root-state";

import { IAuthMilestonePlannersState } from "./auth-milestone-planners.types";
import { actions } from "./auth-milestone-planners.actions";

const state: IAuthMilestonePlannersState = {
  loading: false,
  error: null,
  data: [],
  defaultPlanner: null,
};

export const authMilestonePlanners: Module<
  IAuthMilestonePlannersState,
  RootState
> = {
  namespaced: true,
  state,
  actions,
  mutations: make.mutations(state),
  getters: make.getters(state),
};
