import {
  GenericProvider,
  EProviderFeatures,
} from "@/services/data/generic-provider";
import { IAffiliate } from "@/services/data/affiliate/affiliate.interface";

class SupporterAffiliatesProvider extends GenericProvider<IAffiliate> {
  constructor() {
    super(`/supporter/affiliates`, [
      EProviderFeatures.LIST,
      EProviderFeatures.CREATE,
    ]);
  }
}

export const supporterAffiliateProvider = new SupporterAffiliatesProvider();
