import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "auth-base-enlist" }
const _hoisted_2 = {
  key: 0,
  class: "page-wrapper"
}
const _hoisted_3 = { class: "page-container" }
const _hoisted_4 = { class: "page-headline" }
const _hoisted_5 = { class: "page-block" }
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = ["textContent"]
const _hoisted_8 = ["innerHTML"]
const _hoisted_9 = { class: "el-form__bottom-message" }
const _hoisted_10 = ["innerHTML"]
const _hoisted_11 = { class: "el-form__cta" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LevelBar = _resolveComponent("LevelBar")!
  const _component_ElInput = _resolveComponent("ElInput")!
  const _component_ElFormItem = _resolveComponent("ElFormItem")!
  const _component_PxInputPassword = _resolveComponent("PxInputPassword")!
  const _component_PxButton = _resolveComponent("PxButton")!
  const _component_ElForm = _resolveComponent("ElForm")!
  const _component_PxPanel = _resolveComponent("PxPanel")!
  const _component_PxGlobalError = _resolveComponent("PxGlobalError")!
  const _directive_loading = _resolveDirective("loading")!

  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_LevelBar, { level: _ctx.assessmentLevel }, null, 8, ["level"]),
    (!_ctx.hasServerError)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("h1", _hoisted_4, _toDisplayString(_ctx.$t("authentication.enlist.welcomeMessage", { name: _ctx.userName })), 1),
            _createElementVNode("div", _hoisted_5, [
              _createVNode(_component_PxPanel, { class: "px-panel--compact" }, {
                header: _withCtx(() => [
                  _createElementVNode("h2", {
                    class: "px-panel__title",
                    innerHTML: _ctx.$t('authentication.enlist.form.title')
                  }, null, 8, _hoisted_6)
                ]),
                default: _withCtx(() => [
                  _createVNode(_component_ElForm, {
                    ref: "enlistForm",
                    model: _ctx.form.data,
                    rules: _ctx.form.rules,
                    class: "auth-base-enlist__form",
                    onValidate: _ctx.updateSubmitState
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ElFormItem, {
                        error: _ctx.form.errors.getMessage('email'),
                        label: _ctx.$t('authentication.enlist.form.fields.email'),
                        prop: "email"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ElInput, {
                            modelValue: _ctx.form.data.email,
                            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.form.data.email) = $event)),
                            placeholder: _ctx.$t('authentication.enlist.form.fields.email'),
                            type: "email"
                          }, null, 8, ["modelValue", "placeholder"])
                        ]),
                        _: 1
                      }, 8, ["error", "label"]),
                      _createVNode(_component_ElFormItem, {
                        error: _ctx.form.errors.getMessage('password'),
                        label: _ctx.$t('authentication.enlist.form.fields.password'),
                        prop: "password1"
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("span", {
                            class: "el-form-item__top-text el-form-item__requirement",
                            textContent: _toDisplayString(_ctx.$t('authentication.enlist.form.requirement'))
                          }, null, 8, _hoisted_7),
                          _createVNode(_component_PxInputPassword, {
                            modelValue: _ctx.form.data.password1,
                            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.form.data.password1) = $event)),
                            placeholder: 
                    _ctx.$t('authentication.enlist.form.fields.password')
                  ,
                            "show-success-state": true,
                            onInput: _ctx.validateConfirmationField
                          }, null, 8, ["modelValue", "placeholder", "onInput"]),
                          _createElementVNode("span", {
                            class: "el-form-item__bottom-text el-form-item__tip",
                            innerHTML: _ctx.$t('authentication.enlist.form.tip')
                          }, null, 8, _hoisted_8)
                        ]),
                        _: 1
                      }, 8, ["error", "label"]),
                      _createVNode(_component_ElFormItem, {
                        error: _ctx.form.errors.getMessage('confirmPassword'),
                        label: _ctx.$t('authentication.enlist.form.fields.confirmPassword'),
                        prop: "password2"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ElInput, {
                            modelValue: _ctx.form.data.password2,
                            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.form.data.password2) = $event)),
                            disabled: _ctx.passwordIsInvalid,
                            placeholder: 
                    _ctx.$t('authentication.enlist.form.fields.confirmPassword')
                  ,
                            class: "el-input--password",
                            type: "password"
                          }, null, 8, ["modelValue", "disabled", "placeholder"])
                        ]),
                        _: 1
                      }, 8, ["error", "label"]),
                      _createElementVNode("div", _hoisted_9, [
                        _createElementVNode("p", {
                          class: "el-form__notice",
                          innerHTML: _ctx.$t('authentication.enlist.form.notice')
                        }, null, 8, _hoisted_10)
                      ]),
                      _createElementVNode("div", _hoisted_11, [
                        _createVNode(_component_PxButton, {
                          disabled: !_ctx.isSubmitEnabled,
                          loading: _ctx.isSubmitLoading,
                          size: "medium",
                          type: "primary",
                          onClick: _ctx.onClickSubmit
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t("authentication.enlist.form.submitButton")), 1)
                          ]),
                          _: 1
                        }, 8, ["disabled", "loading", "onClick"])
                      ])
                    ]),
                    _: 1
                  }, 8, ["model", "rules", "onValidate"])
                ]),
                _: 1
              })
            ])
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.hasServerError)
      ? (_openBlock(), _createBlock(_component_PxGlobalError, {
          key: 1,
          loading: _ctx.isSubmitLoading,
          "use-action": true,
          class: "is-center",
          onDoAction: _ctx.onClickSubmit
        }, null, 8, ["loading", "onDoAction"]))
      : _createCommentVNode("", true)
  ])), [
    [_directive_loading, _ctx.isLoading]
  ])
}