import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-56480622"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "level-card" }
const _hoisted_2 = { class: "level-card__title" }
const _hoisted_3 = { class: "level-card__content" }
const _hoisted_4 = { class: "level-card__content--title" }
const _hoisted_5 = { class: "level-card__content--levels" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", {
        style: _normalizeStyle(`--categoryColor: #${_ctx.category.color}`),
        class: "level-card__header"
      }, [
        _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.$t("selfAssessment.mobileAssessment.levelCard.level", { level: _ctx.level })), 1)
      ], 4),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.currentDetails.level.title), 1),
        _createElementVNode("ul", _hoisted_5, [
          _createElementVNode("li", null, _toDisplayString(_ctx.currentDetails.level.description), 1),
          _createElementVNode("li", null, _toDisplayString(_ctx.currentDetails.level.description), 1)
        ])
      ])
    ])
  ]))
}