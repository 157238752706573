<template>
  <div class="list-options" data-testid="list-options">
    <ElDropdown
      :class="{ 'is-open': optionsVisible }"
      class="list-options__dropdown"
      trigger="click"
      @visible-change="onVisibleChange"
    >
      <PxButton
        :class="{ 'is-active': optionsVisible }"
        class="list-options__dropdown-toggler"
        size="medium"
        @click.stop
      >
        <PxIcon :size="16" name="ellipsis--manatee" />
      </PxButton>
      <template #dropdown>
        <ElDropdownMenu class="list-options__dropdown-menu">
          <template v-if="isOwner">
            <ElDropdownItem
              @click="exportClickHandler"
              v-text="$t('companyLists.list.exportCSV')"
            />
            <ElDropdownItem
              v-if="showAddMembersButton"
              @click="addMembersClickHandler"
              v-text="$t('companyLists.list.addMembers')"
            />
            <ElDropdownItem
              @click="manageMembersClickHandler"
              v-text="$t('companyLists.list.manageMembers')"
            />
            <ElDropdownItem
              v-if="!isSmartList"
              @click="settingsClickHandler"
              v-text="$t('companyLists.list.settings')"
            />
            <ElDropdownItem
              v-if="!isSmartList"
              class="list-options__danger-item"
              @click="deleteClickHandler"
              v-text="$t('companyLists.list.delete')"
            />
          </template>
          <template v-else>
            <ElDropdownItem
              class="list-options__danger-item"
              @click="removeFromSharedClickHandler"
              v-text="$t('companyLists.list.removeShared')"
            />
          </template>
        </ElDropdownMenu>
      </template>
    </ElDropdown>
    <ListOptionsDeleteModal
      v-model:visibility="deleteModalVisible"
      @delete-list="deleteConfirmationClickHandler"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import axios, { AxiosError } from "axios";

import { ICompanyList } from "@/modules/company-lists/services/data/company-list/company-list.interface";
import ListOptionsDeleteModal from "@/modules/company-lists/components/list-options/list-options-delete-modal.vue";
import { EListManagementActions } from "@/modules/company-lists/services/store/list-management/list-management.types";
import { ROUTE_MATCHING_LIST } from "@/modules/matching/services/router/routes-names";
import {
  ROUTE_COMPANY_LISTS_DETAIL,
  ROUTE_COMPANY_LISTS_DIRECTORY,
} from "@/modules/company-lists/services/router/routes-names";
import { EListManagementModalTab } from "@/modules/company-lists/components/list-management/list-management.types";
import { CompanyListState } from "@/modules/company-lists/services/store/company-list/company-list.modules";
import { baseAPIUrl, activeModules } from "@/services/utils/utils";
import { userManager } from "@/modules/authentication/user-manager";
import { CompanySharedListState } from "@/modules/company-lists/services/store/company-shared-list/company-shared-list.module";

export default defineComponent({
  name: "ListOptions",

  components: {
    ListOptionsDeleteModal,
  },

  props: {
    value: {
      type: Object as () => ICompanyList,
      required: true,
    },
  },

  data() {
    return {
      optionsVisible: false,
      deleteModalVisible: false,
    };
  },

  computed: {
    isOwner(): boolean {
      return this.value.is_owner;
    },

    isSmartList(): boolean {
      return this.value.is_smart_list;
    },

    isListDetailPage(): boolean {
      return this.$route.name === ROUTE_COMPANY_LISTS_DETAIL;
    },

    listSharedErrors(): AxiosError {
      return this.$store.get(CompanySharedListState.Getter.ERROR);
    },

    isMatchingEnabled(): boolean {
      return (
        this.$features.isEnabled("match") &&
        activeModules().includes("matching")
      );
    },

    showAddMembersButton(): boolean {
      return !this.isSmartList && this.isMatchingEnabled;
    },
  },

  methods: {
    onVisibleChange(state: boolean) {
      this.optionsVisible = state;
    },

    exportClickHandler() {
      const url = `${baseAPIUrl}/user/company-lists/${this.value.uid}/companies?format=csv`;

      axios({
        url,
        method: "get",
        responseType: "arraybuffer",
        headers: {
          Authorization: `Token ${userManager.getToken()}`,
        },
      }).then((response) => {
        // Download response:
        const blob = new Blob([response.data], { type: "text/csv" });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = `${this.value.title}.csv`;
        link.click();
      });
    },

    addMembersClickHandler() {
      // Set selected list
      this.$store.dispatch(EListManagementActions.SET_LISTS, [this.value]);

      // Clear selected companies
      this.$store.dispatch(EListManagementActions.CLEAR_COMPANIES);

      // Move to matching
      this.$router.push({
        name: ROUTE_MATCHING_LIST,
      });
    },

    removeFromSharedClickHandler() {
      this.$store
        .dispatch(CompanySharedListState.Action.REMOVE_VALUE, this.value.uid)
        .then(() => {
          if (!this.listSharedErrors) {
            this.$store.dispatch(CompanySharedListState.Action.GET_VALUES);
            return;
          }

          // Show generic error message
          this.$message({
            message: this.$t("common.errors.global.alertTitle"),
            type: "error",
            customClass: "is-full",
          });
        });
    },

    manageMembersClickHandler() {
      // If not on detail page, navigate to there
      if (!this.isListDetailPage) {
        this.$router.push({
          name: ROUTE_COMPANY_LISTS_DETAIL,
          params: { uid: this.value.uid },
          query: {
            editMembers: "true",
          },
        });
      }

      // Triggers list management footer
      this.$emitter.emit("show-list-management-footer");
    },

    settingsClickHandler() {
      this.$emitter.emit("show-list-management-modal", {
        tab: EListManagementModalTab.EDIT,
        list: this.value,
      });
    },

    deleteClickHandler() {
      this.deleteModalVisible = true;
    },

    async deleteConfirmationClickHandler() {
      // Dispatch destroy
      await this.$store.dispatch(
        CompanyListState.Action.REMOVE_VALUE,
        this.value.uid,
      );

      // Hide delete modal
      this.deleteModalVisible = false;

      // Force new fetch
      await this.$store.dispatch(CompanyListState.Action.GET_VALUES);

      // If the route is the list detail, redirect to the directory listing
      if (this.$route.name === ROUTE_COMPANY_LISTS_DETAIL) {
        this.$router.replace({ name: ROUTE_COMPANY_LISTS_DIRECTORY });
      }
    },
  },
});
</script>

<style lang="scss" scoped>
.list-options__dropdown-toggler {
  min-width: 36px;
  padding: 0;

  &::after {
    opacity: 0.6;
  }
}

.list-options__dropdown-menu {
  padding: 10px 8px;
  margin-top: 7px;
  border-color: $turkish-angora;
  border-radius: 4px;
  box-shadow: 0 4px 12px -4px rgba($manatee, 0.2);

  &::before,
  &::after {
    display: none;
  }

  .el-dropdown-menu__item {
    @include grotesk(regular);

    min-width: 138px;
    padding: 7px 15px;
    line-height: 17px;
    letter-spacing: -0.4px;
  }

  :deep(.list-options__danger-item) {
    @include grotesk(medium);

    color: $wild-watermelon;
  }
}
</style>
