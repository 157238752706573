<template>
  <div :style="{ '--category-color': categoryColor }" class="milestone-tooltip">
    <div class="milestone-tooltip__header">
      <div
        :style="{ backgroundColor: categoryColor }"
        class="category-indicator"
      />
      <div class="level-number">
        {{ levelNumber }}
      </div>
      <div class="category-name">
        {{ categoryName }}
      </div>
    </div>
    <MilestoneTooltipProgressIndicator
      :category-color="categoryColor"
      :is-future-milestone="isFutureMilestone"
      :is-milestone-complete="isMilestoneComplete"
      :is-milestone-in-progress="isMilestoneInProgress"
      :is-milestone-planned="isMilestonePlanned"
    />
    <div class="milestone-tooltip__content">
      <div class="milestone-description">
        {{ milestoneDescription }}
      </div>
      <template v-if="!simplified">
        <CompleteMilestoneInfoIndicator
          v-if="isMilestoneComplete"
          :has-evidence="hasEvidence"
          :is-visitor="isVisitor"
          data-testid="complete-indicator"
          has-action
          @action-click="$emit('complete')"
        />
        <template v-if="showButtons">
          <CriticalMilestoneIndicator
            v-if="isMilestoneCritical"
            data-testid="critical-indicator"
          />
          <MilestoneTooltipButtons
            :is-future-milestone="isFutureMilestone"
            :is-milestone-in-progress="isMilestoneInProgress"
            :is-milestone-planned="isMilestonePlanned"
            :is-visitor="isVisitor"
            data-testid="buttons"
            @complete="$emit('complete')"
            @plan="$emit('plan')"
          />
          <div
            v-if="targetDate"
            :class="{ 'target-date--critical': isMilestoneCritical }"
            class="target-date"
            data-testid="target-date"
          >
            {{ $t("milestonePlanner.milestone.targetDate") }}:
            <strong class="date">
              {{ targetDate }}
            </strong>
          </div>
          <div
            v-if="isFutureMilestone && !isVisitor"
            class="future-milestone-note"
            data-testid="future-note"
          >
            {{ $t("milestonePlanner.milestone.futureMilestoneTooltipNote") }}
          </div>
        </template>
        <div
          v-if="isVisitor && !isMilestoneComplete && !isMilestonePlanned"
          class="future-milestone-note"
          data-testid="no-plan-or-completion-note"
        >
          {{ $t("milestonePlanner.milestone.noPlanOrCompletionTooltipNote") }}
        </div>
      </template>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import CompleteMilestoneInfoIndicator from "@/modules/milestone-planner/components/complete-milestone-info-indicator/complete-milestone-info-indicator.vue";
import CriticalMilestoneIndicator from "@/modules/milestone-planner/components/critical-milestone-indicator/critical-milestone-indicator.vue";
import MilestoneTooltipButtons from "./milestone-tooltip-buttons.vue";
import MilestoneTooltipProgressIndicator from "./milestone-tooltip-progress-indicator.vue";

/**
 * Milestone Tooltip
 *
 * For the tooltip to adopt the appropriate look and feel, the <ElPopover>
 * element that wrapps it needs to have both a 'px-popover--light' and a
 * 'milestone-cell-popover' class, as in:
 *
 * `<ElPopover popper-class="px-popover--light milestone-cell-popover">`
 */
export default defineComponent({
  name: "MilestoneTooltip",

  components: {
    CompleteMilestoneInfoIndicator,
    CriticalMilestoneIndicator,
    MilestoneTooltipButtons,
    MilestoneTooltipProgressIndicator,
  },

  props: {
    categoryColor: { type: String, required: true },
    categoryName: { type: String, required: true },
    hasEvidence: { type: Boolean, default: false },
    isFutureMilestone: { type: Boolean, default: false },
    isMilestoneComplete: { type: Boolean, default: false },
    isMilestoneCritical: { type: Boolean, default: false },
    isMilestoneInProgress: { type: Boolean, default: false },
    isMilestonePlanned: { type: Boolean, default: false },
    levelNumber: { type: Number, required: true },
    milestoneDescription: { type: String, required: true },
    targetDate: {
      type: String as () => string | null,
      default: null,
    },
    simplified: { type: Boolean, required: false, default: false },
    isVisitor: { type: Boolean, required: false, default: false },
  },

  computed: {
    showButtons(): boolean {
      return (
        (this.isVisitor &&
          this.isMilestonePlanned &&
          !this.isMilestoneComplete) ||
        (!this.isVisitor && !this.isMilestoneComplete)
      );
    },
  },
});
</script>

<style lang="scss">
.milestone-tooltip {
  border-top: 4px solid var(--category-color);
  border-radius: $--border-radius-medium $--border-radius-medium 0 0;

  &__up-arrow {
    display: none;
  }

  &__header {
    display: grid;
    grid-template-columns: 8px 9px auto;
    gap: 7px;
    align-items: center;
    justify-content: center;
    height: 35px;

    .category-indicator {
      width: 8px;
      height: 8px;
      border-radius: 50%;
    }

    .level-number {
      @include grotesk(semiBold);

      position: relative;
      right: 1px;
    }

    .category-name {
      font-size: 14px;
      line-height: 20px;
      text-align: left;
      letter-spacing: 0.1px;
    }
  }

  &__content {
    padding: 15px 13px;
    text-align: center;

    .milestone-description {
      @include grotesk(medium);

      font-size: 14px;
      line-height: 20px;
      letter-spacing: -0.2px;
    }

    .future-milestone-note,
    .target-date {
      font-size: 12px;
      line-height: 14px;
      color: $manatee;
    }

    .future-milestone-note {
      margin-top: 16px;
      font-style: italic;
      letter-spacing: -0.1px;
    }
  }

  &__content .target-date {
    margin-top: 8px;

    &--critical {
      color: $light-orange;
    }

    .date {
      @include grotesk(semiBold);

      position: relative;
      right: 1px;
    }
  }

  .milestone-tooltip-buttons {
    div:first-of-type {
      padding: 0 0 0 1px;
    }

    div:last-of-type {
      padding: 0 1px 0 0;
    }
  }

  .milestone-tooltip-buttons .el-button--small {
    height: 32px;

    span {
      line-height: 13px;
      letter-spacing: 0.6px;
    }
  }
}
</style>

<style lang="scss">
.milestone-cell-popover.el-popover {
  width: 242px !important;
  padding: 0;
  outline: none;

  &[data-popper-placement="bottom"] {
    .milestone-tooltip::before {
      position: absolute;
      top: -8px;
      left: 50%;
      display: block;
      width: 0;
      height: 0;
      content: "";
      border-right: 8px solid transparent;
      border-bottom-color: var(--category-color);
      border-bottom-style: solid;
      border-bottom-width: 8px;
      border-left: 8px solid transparent;
      transform: translateX(-50%);
    }

    .popper__arrow {
      display: none;
    }
  }
}
</style>
