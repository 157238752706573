<template>
  <div class="supporters-view__container">
    <FormPresentationLayout
      :content-description="viewCopy.contentDescription"
      :content-title="viewCopy.contentTitle"
      :description-image-link="viewCopy.imageLink"
      :description-image-title="viewCopy.imageTitle"
      :description-text="viewCopy.imageDescription"
      :has-description-background-image="true"
      :has-return-button="hasPreviousPage"
      @previous-page="onReturnButtonClick"
    >
      <template #content>
        <div class="supporter-registration-form__container">
          <ElForm
            ref="supporterRegistrationForm"
            :model="fields"
            :rules="rules"
            class="supporter-registration-form__body"
            @validate="updateSubmitState"
          >
            <ElFormItem
              :label="$t('supporters.signup.form.fields.supporterName')"
              prop="company.name"
            >
              <ElInput
                v-model="fields.company.name"
                :placeholder="$t('supporters.signup.form.fields.supporterName')"
              />
            </ElFormItem>
            <ElFormItem
              :label="$t('supporters.signup.form.fields.supporterType')"
              prop="supporter.types"
            >
              <ElCheckboxGroup v-model="selectedSupporterTypes">
                <ElCheckbox
                  v-for="(type, key) in availableSupporterTypes"
                  :key="key"
                  :value="type.id"
                >
                  {{ type.name }}
                  <ElTooltip
                    v-if="type.label"
                    placement="top"
                    popper-class="supporter-registration-form__type-tooltip el-abaca-tooltip"
                  >
                    <template #content>
                      <div v-html="type.label" />
                    </template>
                    <PxIcon
                      :size="16"
                      class="supporter-registration-form__type-question-icon"
                      name="icon-question"
                    />
                  </ElTooltip>
                </ElCheckbox>
                <ElCheckbox
                  value=""
                  @change="(value) => otherTypeChangeHandler(value)"
                >
                  {{ $t("supporters.signup.form.fields.otherSupporterType") }}
                </ElCheckbox>
              </ElCheckboxGroup>
              <ElInput
                v-if="hasOtherSupporterType"
                v-model="otherType"
                :placeholder="
                  $t('supporters.signup.form.placeholders.supporterType')
                "
                class="supporter-registration-form__other-type-input"
              />
            </ElFormItem>
            <ElFormItem
              :label="$t('supporters.signup.form.fields.location')"
              prop="company.location"
            >
              <PxInputPlaces
                id="supporter-registration-form__location-input"
                v-model="fields.company.location"
                :placeholder="$t('supporters.signup.form.fields.location')"
                :use-geolocation="true"
              />
            </ElFormItem>
            <div class="supporter-registration-form__cta">
              <PxButton
                :disabled="isSubmissionDisabled"
                :loading="formLoading"
                type="primary"
                @click="onNextButtonClick"
              >
                {{ ctaCopy.next }}
              </PxButton>
            </div>
          </ElForm>
          <ActionModal
            v-if="showNetworkModal"
            ref="networkModal"
            :title="networkModalTitle"
            :visibility="showNetworkModal"
            class="networks-modal__wrapper"
          >
            <template #content>
              <NetworksList
                :items="affiliateNetwork"
                class="networks-panel__list"
              />
              <p>
                {{ $t("supporters.component.networkModal.description") }}
              </p>
            </template>
            <template #actions>
              <PxButton
                class="network-modal__cta"
                @click="submitNetworkModal(false)"
              >
                {{ $t("supporters.component.networkModal.secondaryCta") }}
              </PxButton>
              <PxButton
                class="network-modal__cta"
                type="green"
                @click="submitNetworkModal(true)"
              >
                {{ $t("supporters.component.networkModal.mainCta") }}
              </PxButton>
            </template>
          </ActionModal>
        </div>
      </template>
    </FormPresentationLayout>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import ActionModal from "@/modules/supporters/components/action-modal/action-modal.vue";
import FormPresentationLayout from "@/modules/supporters/components/form-presentation-layout/form-presentation-layout.vue";
import NetworksList from "@/modules/profile/components/networks-list/networks-list.vue";
import SupportersFlowMixin from "@/modules/supporters/mixins/supporters-flow.mixin";

import { ISupporterType } from "@/modules/supporters/services/data/supporter-type/supporter-type.interface";
import { SupporterTypeState } from "@/modules/supporters/services/store/supporter-type/supporter-type.module";
import { ISupporterFlow } from "@/modules/supporters/services/data/supporter-flow/supporter-flow.interface";
import { ILocation } from "@/services/data/location/location.interface";
import { INetwork } from "@/services/data/network/network.interface";
import { AffiliateState } from "@/modules/supporters/services/store/affiliate/affiliate.module";
import { CheckboxValueType } from "element-plus";

export default defineComponent({
  name: "AccountInfo",

  components: {
    ActionModal,
    FormPresentationLayout,
    NetworksList,
  },

  mixins: [SupportersFlowMixin],

  data() {
    return {
      selectedSupporterTypes: [] as Array<number>,
      hasOtherSupporterType: false as CheckboxValueType,
      otherType: "",
      showNetworkModal: false,

      fields: {
        company: {
          name: "",
          location: {} as Partial<ILocation>,
          networks: [] as Array<number>,
        },
        supporter: {
          types: [] as Array<number>,
        },
        meta: {
          hasShownNetworkModal: false,
        },
      } as Partial<ISupporterFlow>,
    };
  },

  computed: {
    viewCopy() {
      return this.$tm("supporters.view.accountInfo") as {
        contentTitle: string;
        contentDescription: string;
        imageTitle: string;
        imageDescription: string;
        imageLink: {
          original: string;
          retina: string;
        };
      };
    },

    availableSupporterTypes(): Array<ISupporterType> {
      return this.$store.getters[SupporterTypeState.Getter.VALUES];
    },

    affiliateNetwork(): Array<INetwork> {
      const affiliate = this.$store.getters[AffiliateState.Getter.VALUE];
      const networkList = [] as INetwork[];

      // Check if affiliate is set and if it contains at least one network
      if (affiliate && affiliate.networks?.length > 0) {
        // Select first network
        networkList.push(affiliate.networks[0]);
      }

      return networkList;
    },

    networkModalTitle(): string {
      const networkName = this.affiliateNetwork[0]?.name || "";

      return this.$t("supporters.component.networkModal.title", {
        network: networkName,
      }) as string;
    },
  },

  watch: {
    selectedSupporterTypes: {
      deep: true,
      handler(currentSelectedTypes: Array<number>) {
        // Filter out invalid values
        this.fields.supporter.types = currentSelectedTypes.filter(
          (type: number) => !!type,
        );
      },
    },

    hasOtherSupporterType(state: boolean) {
      if (!state) {
        delete this.fields.supporter.other_type;
      }
    },

    otherType(value: string) {
      if (this.hasOtherSupporterType) {
        this.fields.supporter.other_type = value;
      }
    },

    affiliateNetwork: {
      immediate: true,
      deep: true,
      handler(values: Array<INetwork>) {
        // Avoid showing this if already shown
        const hasShownNetworkModal =
          this.flowData.meta?.hasShownNetworkModal || false;

        this.showNetworkModal = values.length > 0 && !hasShownNetworkModal;
      },
    },
  },

  mounted() {
    if (this.flowData) {
      this.fields.company = {
        ...this.fields.company,
        ...this.flowData.company,
      };

      this.fields.supporter = {
        ...this.fields.supporter,
        ...this.flowData.supporter,
      };

      this.fields.meta.hasShownNetworkModal =
        this.flowData.meta?.hasShownNetworkModal ||
        this.fields.meta.hasShownNetworkModal;
    }

    this.prefillInnerFields();
  },

  methods: {
    /**
     * Allows to add custom other supporter types
     */
    otherTypeChangeHandler(checked: CheckboxValueType) {
      this.hasOtherSupporterType = checked;
    },

    prefillInnerFields() {
      // Prefill supporter types
      if (this.fields.supporter.types?.length) {
        this.selectedSupporterTypes = this.fields.supporter.types;
        this.currentForm?.validate();
      }

      if (this.fields.supporter.other_type) {
        this.hasOtherSupporterType = true;
        this.otherType = this.fields.supporter.other_type || "";
        this.currentForm?.validate();
      }

      if (
        !!this.fields.company.name ||
        !!this.fields.company.location?.formatted_address
      ) {
        this.currentForm?.validate();
      }
    },

    /**
     * Sends network data as a batch submission.
     * @param submitNetwork
     */
    async submitNetworkModal(submitNetwork: boolean) {
      this.showNetworkModal = false;
      this.fields.meta.hasShownNetworkModal = true;

      if (submitNetwork) {
        this.fields.company.networks = [this.affiliateNetwork[0].id];
      }
    },
  },
});
</script>

<style lang="scss" scoped>
.supporters-view__container :deep() {
  .form-presentation-layout__wrapper {
    /* Add extra height to cautious for the places popup */
    height: calc(100% + 30px);
  }

  .form-presentation-layout__content-container {
    margin-top: 19px;
  }

  .form-presentation-layout__content-body {
    /* Add extra height to cautious for the popups */
    margin-bottom: 190px;
  }

  .form-presentation-layout__content-header {
    margin-bottom: 32px;
  }

  .supporter-registration-form__container {
    display: grid;
    grid-gap: 40px;
    max-width: 360px;
  }

  .supporter-registration-form__body {
    display: grid;
    grid-gap: 20px;
  }

  .supporter-registration-form__body .el-form-item__label {
    padding: 0 12px 4px 0;
  }

  .supporter-registration-form__body .el-input__inner {
    margin: 0;
    line-height: 40px;
  }

  .supporter-registration-form__body .el-checkbox-group {
    display: inline-flex;
    flex-flow: row wrap;
    justify-content: space-between;
    width: 100%;
    margin-top: 9px;
  }

  .supporter-registration-form__body .el-checkbox {
    flex-basis: 43%;
    margin-left: 0;

    &:nth-child(n + 3) {
      min-width: 122px;
      margin-top: 10px;
    }
  }

  .supporter-registration-form__body .el-checkbox__label {
    position: relative;
    top: -1px;
    width: 100%;
  }

  .supporter-registration-form__body .other-type-input {
    margin-top: 10px;
  }

  .supporter-registration-form__cta .el-button {
    width: 50%;
  }

  .supporter-registration-form__type-question-icon {
    transform: translate(1px, 3px);
  }
}
</style>

<style lang="scss">
.networks-modal__wrapper {
  margin-top: 16.6vh !important;

  .el-dialog__header {
    padding: 29px 50px 0;
  }

  .el-dialog__footer {
    padding: 24px 30px 25px;
  }

  .action-modal__footer {
    display: grid;
    grid-template-columns: 1fr 152px;
    grid-gap: 20px;
    padding: 0 38px 0 36px;
  }

  .network-modal__cta.el-button span {
    font-size: 13px;
  }

  .action-modal__body {
    display: flex;
    flex-direction: column;
    gap: 37px;
    justify-content: center;
  }

  .action-modal__body p {
    letter-spacing: -0.3px;
  }

  .networks-panel__list {
    display: inline-flex;
    justify-content: center;
    width: auto;
    max-width: max-content;

    padding: 9px 41px 11px 17px;
    margin: 31px auto 0;
    background-color: $alabaster;
    border: 1px solid rgba($manatee, 0.28);
    border-radius: 4px;

    .networks-list {
      margin: 0;
      padding: 0;
      max-height: 69px;
      overflow: hidden;
    }

    .networks-list__logo {
      width: 48px;
      height: 48px;
    }

    .networks-list__content {
      padding-top: 13px;
      padding-left: 12px;
    }

    .networks-list__content li {
      letter-spacing: -0.3px;

      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 200px;
    }
  }
}
</style>
