<template>
  <ElDialog
    ref="modal"
    v-model="innerVisibility"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :show-close="true"
    :title="title"
    class="px-form-modal"
    data-testid="px-form-modal"
    @close="onModalClose"
  >
    <div ref="formWrapper" class="px-form-modal__form-wrapper">
      <slot name="form" />
      <slot class="px-form-modal__notice" name="notice" />
    </div>
  </ElDialog>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "PxFormModal",

  props: {
    title: {
      type: String,
      default: () => "",
    },
    visibility: {
      type: Boolean,
      default: () => false,
    },
  },

  data() {
    return {
      innerVisibility: false,
    };
  },

  watch: {
    visibility(value) {
      this.innerVisibility = value;
    },
  },

  methods: {
    onModalClose() {
      this.innerVisibility = false;
      this.$emit("close");
    },
  },
});
</script>

<style lang="scss">
.px-form-modal {
  &.el-dialog {
    width: 100%;
    max-width: 100%;
    height: calc(100% - 48px);
    margin: 48px 0 0 !important;
    border-radius: 12px 12px 0 0;

    @media (max-height: 480px) {
      margin-top: 18px !important;
    }
  }

  .el-dialog__header {
    padding-right: 40px;
    background: none;
    border: none;

    @include breakpoint-up(md) {
      padding-right: 60px;
    }
  }

  .el-dialog__body {
    padding: 4px 0 0;
  }

  &__form-wrapper {
    position: relative;
    max-height: 494px;
    padding: 0 15px;
    overflow: auto;
    overflow-x: hidden;
  }

  &__notice {
    display: flex;
    padding-bottom: 20px;
    margin: 28px auto 0;

    .px-icon {
      position: relative;
      top: 1px;
      right: 2px;
    }

    p {
      @include grotesk(regular, italic);

      padding-left: 5px;
      font-size: 13px;
      line-height: 18px;
    }
  }
}
</style>
