import { resolveDirective as _resolveDirective, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "criteria-modal__content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ElDialog = _resolveComponent("ElDialog")!
  const _directive_markdown = _resolveDirective("markdown")!

  return (_openBlock(), _createBlock(_component_ElDialog, {
    modelValue: _ctx.innerVisibility,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.innerVisibility) = $event)),
    "append-to-body": true,
    class: "criteria-modal",
    width: "264px"
  }, {
    default: _withCtx(() => [
      _withDirectives(_createElementVNode("div", _hoisted_1, null, 512), [
        [_directive_markdown, _ctx.modalContent]
      ])
    ]),
    _: 1
  }, 8, ["modelValue"]))
}