import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, renderSlot as _renderSlot, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    ref: "grid",
    style: _normalizeStyle(`height: ${_ctx.totalContentHeight}px`),
    class: "virtual-grid__wrapper"
  }, [
    _createElementVNode("div", {
      style: _normalizeStyle(_ctx.gridStyles),
      class: "virtual-grid__container"
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.visibleNodes, (item, index) => {
        return _renderSlot(_ctx.$slots, "items", {
          item: { ...item, index }
        })
      }), 256)),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.visibleLoadingNodesCount, (item) => {
        return _renderSlot(_ctx.$slots, "loading", { item: item })
      }), 256))
    ], 4)
  ], 4))
}