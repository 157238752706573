<template>
  <div class="milestone-form-plan">
    <ElForm ref="form" :model="form" :rules="rules">
      <div class="milestone-form-plan__description">
        {{ $t("milestonePlanner.milestonePlan.planForm.description.owner") }}
      </div>
      <div class="milestone-form-plan__fields">
        <ElFormItem v-if="!isFutureMilestone" :required="false" prop="critical">
          <ElCheckbox
            v-model="form.critical"
            class="milestone-form-plan__checkbox"
          >
            <i18n-t
              :keypath="`milestonePlanner.milestonePlan.planForm.fields.critical`"
              tag="p"
            >
              <template #criticalIcon>
                <PxIcon
                  :size="22"
                  class="milestone-form-plan__critical"
                  name="critical-milestone"
                />
              </template>
              <template #text>
                <i18n-t
                  :keypath="`milestonePlanner.milestonePlan.planForm.fields.criticalMilestone`"
                  tag="span"
                />
              </template>
            </i18n-t>
          </ElCheckbox>
          <ElTooltip
            :content="criticalTooltip"
            placement="top"
            popper-class="milestone-form-plan__tooltip el-abaca-tooltip"
          >
            <PxIcon :size="14" name="question" />
          </ElTooltip>
        </ElFormItem>
        <ElFormItem
          :label="$t('milestonePlanner.milestonePlan.planForm.fields.strategy')"
          class="milestone-form-plan__textarea milestone-form-plan__strategy"
          prop="strategy"
        >
          <PxTextarea
            ref="strategy"
            v-model="form.strategy"
            :max-chars="rules.strategy.max"
            :placeholder="
              $t(
                'milestonePlanner.milestonePlan.planForm.placeholders.strategy',
                { categoryName },
              )
            "
            show-counter
          />
        </ElFormItem>
        <ElFormItem
          class="milestone-form-plan__textarea milestone-form-plan__outcomes"
          prop="outcomes"
        >
          <template #label>
            {{ $t("milestonePlanner.milestonePlan.planForm.fields.outcomes") }}
            <ElTooltip
              :content="outcomesTooltip"
              placement="top"
              popper-class="milestone-form-plan__tooltip el-abaca-tooltip"
            >
              <PxIcon :size="14" name="question" />
            </ElTooltip>
          </template>
          <PxTextarea
            ref="outcomes"
            v-model="form.outcomes"
            :max-chars="rules.outcomes.max"
            :placeholder="
              $t(
                'milestonePlanner.milestonePlan.planForm.placeholders.outcomes',
                { question: outcomesQuestion },
              )
            "
            show-counter
          />
        </ElFormItem>
        <ElFormItem
          :label="
            $t('milestonePlanner.milestonePlan.planForm.fields.resources')
          "
          class="milestone-form-plan__textarea milestone-form-plan__resources"
          prop="resources"
        >
          <PxTextarea
            ref="resources"
            v-model="form.resources"
            :max-chars="rules.resources.max"
            :placeholder="
              $t(
                'milestonePlanner.milestonePlan.planForm.placeholders.resources',
                { categoryName },
              )
            "
            show-counter
          />
        </ElFormItem>
        <div class="milestone-form-plan__inputs">
          <ElFormItem
            :label="
              $t(
                'milestonePlanner.milestonePlan.planForm.fields.financesNeeded',
              )
            "
            class="milestone-form-plan__numeric"
            prop="financesNeeded"
          >
            <PxMoney ref="financesNeeded" v-model="form.financesNeeded" />
          </ElFormItem>
          <ElFormItem prop="targetDate">
            <PxDatePicker
              v-model:value="form.targetDate"
              :label="
                $t('milestonePlanner.milestonePlan.planForm.fields.targetDate')
              "
              :tooltip="targetDateTooltip"
              class="milestone-form-plan__date"
            />
          </ElFormItem>
        </div>
      </div>
    </ElForm>
    <MilestonePlanConfirmationDialog
      ref="discardChangesDialog"
      v-model:visibility="isDiscardingChanges"
      @confirmed="resetForm"
    />
    <MilestonePlanConfirmationDialog
      v-if="milestone"
      ref="deletePlanDialog"
      v-model:visibility="isDeleting"
      :is-form-invalid="formIsInvalid"
      :is-plan-published="milestone.plan_published"
      :planned="true"
      @confirmed="deletePlan"
      @save-as-draft="submitPlan({ toPublish: false })"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { TranslateResult } from "vue-i18n";

import moment from "moment";
import camelCase from "lodash/camelCase";
import cloneDeep from "lodash/cloneDeep";
import isEqual from "lodash/isEqual";
import mapKeys from "lodash/mapKeys";
import pick from "lodash/pick";
import snakeCase from "lodash/snakeCase";

import PxDatePicker from "@/components/px-date-picker/px-date-picker.vue";
import MilestonePlanConfirmationDialog from "@/modules/milestone-planner/components/milestone-plan-confirmation-dialog/milestone-plan-confirmation-dialog.vue";
import { IMatchingQuestion } from "@/services/data/matching-questionary/matching-question.interface";
import { IMilestoneForm } from "@/modules/milestone-planner/services/data/milestones/milestone.interface";
import { EAuthMilestonesActions } from "@/modules/authentication/services/store/auth/sub-modules/auth-milestones/auth-milestones.types";
import { IMilestone } from "@/modules/milestone-planner/services/data/milestones/milestone.interface";
import { ICategoryDetail } from "@/services/data/category/category.interface";

export interface IFormField {
  fieldValue: any;
  prop: string;
  validateState: string;
  required: boolean;
}

const MAX_FINANCES_NEEDED = 1000000000;

export default defineComponent({
  name: "MilestoneFormPlan",

  components: {
    PxDatePicker,
    MilestonePlanConfirmationDialog,
  },

  props: {
    categoryLevel: {
      type: Object as () => ICategoryDetail | null,
      required: true,
    },
    categoryName: {
      type: String,
      default: "",
    },
    milestone: {
      type: Object as () => IMilestone | null,
      default: null,
    },
    evidenceQuestion: {
      type: Object as () => IMatchingQuestion | null,
      default: null,
    },
    isFutureMilestone: {
      type: Boolean,
      default: false,
    },
    hasCompletionInfo: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      form: {} as IMilestoneForm["plan"],
      // Will be reused to reset form for future milestones:
      planInitialState: Object.freeze({
        critical: false,
        strategy: "",
        outcomes: "",
        resources: "",
        financesNeeded: "",
        targetDate: null,
      }) as IMilestoneForm["plan"],
      rules: {
        strategy: {
          max: 4000,
        },
        outcomes: {
          max: 4000,
        },
        resources: {
          max: 4000,
        },
        financesNeeded: [
          {
            validator: (_: any, value: string, callback: any) => {
              const myValue: number = parseFloat(value.replace(/,/g, ""));
              const valueCheck =
                myValue > MAX_FINANCES_NEEDED ? new Error() : undefined;
              callback(valueCheck);
            },
            trigger: "change",
            message: this.$t("common.errors.numeric.max", {
              maxValue: Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
                maximumFractionDigits: 0,
              }).format(MAX_FINANCES_NEEDED),
            }),
          },
        ],
      },
      formEl: null as null | Element,
      originalData: {} as IMilestoneForm["plan"],
      isDeleting: false,
      isDiscardingChanges: false,
    };
  },

  computed: {
    criticalTooltip(): TranslateResult {
      return this.$t(
        "milestonePlanner.milestonePlan.planForm.tooltips.critical.owner",
      );
    },

    outcomesTooltip(): TranslateResult {
      return this.$t(
        "milestonePlanner.milestonePlan.planForm.tooltips.outcomes",
        { question: this.outcomesQuestion },
      );
    },

    targetDateTooltip(): TranslateResult {
      return this.$t(
        "milestonePlanner.milestonePlan.planForm.tooltips.targetDate",
      );
    },

    successfullySubmitedMessage(): TranslateResult {
      return this.getSuccessMessage(
        "successfullySubmited",
        !!this.milestone?.plan_published,
      );
    },

    successfullyDeletedMessage(): TranslateResult {
      return this.getSuccessMessage(
        "successfullyDeleted",
        !!this.milestone?.plan_published,
      );
    },

    outcomesQuestion(): string {
      return this.evidenceQuestion?.entrepreneur_question || "";
    },

    formFields(): IFormField[] {
      return (this.formEl as any).fields as IFormField[];
    },

    formIsComplete(): boolean {
      if (this.formEl) {
        const formFields = this.formFields;

        // Check if field is required and has not value or if field has error
        const fieldsAreInvalid = (field: IFormField) => {
          return (
            (Boolean(!field.fieldValue) && field.required !== false) ||
            this.formIsInvalid
          );
        };

        return !formFields?.some(fieldsAreInvalid);
      }
      return false;
    },

    //TODO: we need to add E2E tests to check the invalid state of the form
    formIsInvalid(): boolean {
      if (this.formEl) {
        const formFields = this.formFields;

        // Check if any field has an error state
        const checkFieldError = (field: IFormField) => {
          // Check if the field has only spaces and not content
          if (typeof field.fieldValue === "string" && field.fieldValue.length) {
            return !field.fieldValue.trim();
          }

          return field.validateState === "error";
        };

        let formInvalid = formFields?.some(checkFieldError);

        // * Temporary solution for invalidating the form if the critical field
        // * is checked and everything else is empty.
        // * For the time being, saving the above as draft will result in an
        // * invalid API request, and therefore we will disable the button in
        // * that specific case.
        // * In the future, we shall disregard this and return the line above.

        if (!formInvalid) {
          const criticalField = formFields.find(
            (field) => field.prop === "critical",
          );

          if (criticalField?.fieldValue) {
            const otherFields = formFields.filter(
              (field) => field.prop !== "critical",
            );

            formInvalid = !otherFields.some((field) => field.fieldValue);
          }
        }

        return (
          formInvalid || isEqual((this as any).form, this.planInitialState)
        );
      }
      return false;
    },

    hasFormChanged(): boolean {
      return !isEqual((this as any).form, this.originalData);
    },

    /**
     * Formatted form data to match the expected API schema:
     */
    formattedForm(): any {
      const targetDate = this.form.targetDate
        ? moment(this.form.targetDate).format("YYYY-MM-DD")
        : this.form.targetDate;
      const financesNeeded = this.form.financesNeeded
        ? parseFloat(this.form.financesNeeded.replace(/,/g, ""))
        : null;
      return { ...this.form, targetDate, financesNeeded };
    },

    isExistingMilestone(): boolean {
      return !!this.milestone;
    },

    hasErrorOnMilestones(): boolean {
      return Boolean(this.$store.get("auth/milestones.error"));
    },
  },

  watch: {
    formIsComplete(isComplete: boolean) {
      this.$emit("plan-form-is-complete", isComplete);
    },

    formIsInvalid(isInvalid: boolean) {
      this.$emit("plan-form-is-invalid", isInvalid);
    },

    hasFormChanged(hasChanged: boolean) {
      //TODO: check this emit (is happening twice on initialization because the assignment of the originalData only occurs on mounted)
      this.$emit("plan-form-has-changes", hasChanged);
    },

    categoryLevel() {
      if (!this.isExistingMilestone) {
        this.resetForm();
      }
    },

    milestone: {
      deep: true,
      immediate: true,
      handler(newValue: IMilestone | null) {
        if (newValue) {
          const milestoneToFormValues = this.formatMilestoneValues(newValue);
          const prefillValues = mapKeys(milestoneToFormValues, (v, k) =>
            camelCase(k),
          );
          (this as any).form = pick(
            prefillValues,
            Object.keys(this.planInitialState),
          );
        } else {
          this.form = { ...this.planInitialState };
        }

        this.originalData = cloneDeep(this.form);
      },
    },
  },

  mounted() {
    (this as any).formEl = this.$refs.form;
    this.originalData = cloneDeep(this.form);
    this.$emit("plan-form-is-complete", this.formIsComplete);
  },

  created() {
    this.$emitter.on("delete-milestone-plan", this.showDeletePlanDialog);
    this.$emitter.on("discard-milestone-plan", this.showDiscardChangesDialog);
    this.$emitter.on("submit-milestone-plan", this.submitPlan);
  },

  beforeUnmount() {
    this.$emitter.off("delete-milestone-plan", this.showDeletePlanDialog);
    this.$emitter.off("discard-milestone-plan", this.showDiscardChangesDialog);
    this.$emitter.off("submit-milestone-plan", this.submitPlan);
  },

  methods: {
    convertKeysToSnakeCase(fields: object) {
      // TODO: Move this functionality into the generic provider
      return mapKeys(fields, (v, k) => snakeCase(k));
    },

    formatMilestoneValues(milestone: IMilestone) {
      const targetDate = milestone.target_date
        ? new Date(milestone.target_date)
        : null;
      const financesNeeded = !isNaN(String(milestone.finances_needed) as any)
        ? String(milestone.finances_needed)
        : "";
      return { ...milestone, targetDate, financesNeeded };
    },

    resetForm() {
      this.form = { ...this.originalData };
    },

    showDeletePlanDialog() {
      this.isDeleting = true;
    },

    showDiscardChangesDialog() {
      this.isDiscardingChanges = true;
    },

    async submitPlan({ toPublish }: { [key: string]: boolean }) {
      const fieldsInSnakeCase = this.convertKeysToSnakeCase(this.formattedForm);

      if (!this.isExistingMilestone) {
        await this.$store.dispatch(EAuthMilestonesActions.CREATE, {
          ...fieldsInSnakeCase,
          category_level: this.categoryLevel?.id,
          plan_published: toPublish,
        });
      } else {
        await this.$store.dispatch(EAuthMilestonesActions.PATCH, {
          milestoneToUpdate: this.milestone,
          payload: {
            ...fieldsInSnakeCase,
            plan_published: toPublish,
          },
        });
      }

      this.showMessage(
        this.hasErrorOnMilestones,
        this.successfullySubmitedMessage,
      );
    },

    async deletePlan() {
      const successMessage = this.successfullyDeletedMessage;

      if (this.hasCompletionInfo) {
        await this.$store.dispatch(EAuthMilestonesActions.PATCH, {
          milestoneToUpdate: this.milestone,
          payload: {
            critical: false,
            finances_needed: null,
            outcomes: "",
            resources: "",
            strategy: "",
            target_date: null,
            plan_published: false,
          },
        });
      } else {
        await this.$store.dispatch(EAuthMilestonesActions.DESTROY, {
          milestoneToDelete: this.milestone,
        });
      }

      this.showMessage(this.hasErrorOnMilestones, successMessage);
    },

    showMessage(hasError: boolean, successMessage: TranslateResult) {
      if (hasError) {
        this.$message({
          message: this.$t("common.errors.global.alertTitle"),
          type: "error",
          duration: 10000,
          customClass: "is-full",
        });
      } else {
        this.$message({
          message: successMessage as string,
          type: "success",
          duration: 10000,
          customClass: "is-full",
        });
      }
    },

    getSuccessMessage(
      actionKey: string,
      isPlanPublished: boolean,
    ): TranslateResult {
      return isPlanPublished
        ? this.$t(`milestonePlanner.milestonePlan.planForm.${actionKey}.plan`)
        : this.$t(`milestonePlanner.milestonePlan.planForm.${actionKey}.draft`);
    },
  },
});
</script>

<style lang="scss">
.milestone-form-plan {
  position: relative;
  padding-right: 29px;
  padding-left: 33px;

  &__description {
    padding-right: 30px;
    margin-bottom: 20px;
    font-size: 14px;
    font-weight: normal;
    line-height: 20px;
    color: $manatee;
    letter-spacing: -0.3px;
  }

  &__tooltip {
    max-width: 340px;
    text-transform: none;
  }

  &__textarea {
    textarea {
      height: 124px;
      padding-top: 7px;
      padding-left: 15px;
      line-height: 22px;
      letter-spacing: -0.3px;
      resize: none !important;
    }

    .el-tooltip__trigger {
      top: 3px;
      left: 2px;
    }
  }

  &__textarea .el-tooltip__trigger,
  &__tooltip .px-icon,
  .el-checkbox ~ .el-tooltip__trigger {
    position: relative;
    top: 4px;
    right: 5px;
    display: inline-block;
    width: 14px;
    height: 14px;
  }

  .el-form-item__label {
    float: none;
    padding-top: 0;
    margin-bottom: 3px;
  }

  &__date,
  &__numeric {
    max-width: 280px;
  }

  &__date {
    position: relative;
    bottom: 2px;

    .px-date-picker__label {
      margin-bottom: 6px;
    }

    .px-date-picker__label .px-date-picker__tooltip-icon {
      top: 0;
      right: 1px;
    }
  }

  &__inputs {
    display: grid;
    grid-template-columns: 280px 280px;
    gap: 42px;
    max-width: 645px;

    .el-abaca-tooltip {
      top: -2px;
      right: 5px;
    }
  }

  &__strategy {
    margin-bottom: 4px;
  }

  &__outcomes {
    margin-bottom: 6px;

    .el-form-item__content {
      top: 1px;
    }
  }

  &__resources {
    margin-bottom: 7px;
  }

  .el-form-item__error {
    bottom: 4px;

    &::first-letter {
      text-transform: capitalize;
    }
  }

  .milestone-form-plan__numeric .el-form-item__error {
    bottom: initial;
    margin-top: 4px;
  }
}

.milestone-form-plan .el-checkbox {
  margin-bottom: 24px;
  margin-left: 1px;

  .el-checkbox__inner {
    width: 22px;
    height: 22px;
  }

  .el-checkbox__label {
    padding-left: 8px;

    p {
      font-size: 14px;
      line-height: 20px;
      color: $ebony-clay;
    }

    p > span {
      position: relative;
      top: 2px;
      right: 9px;
      letter-spacing: -0.7px;
    }

    img {
      position: relative;
      top: 4px;
      right: 4px;
    }
  }

  .el-checkbox__input.is-checked .el-checkbox__inner {
    background-color: rgb(123, 183, 255);
    border-color: rgb(123, 183, 255);
  }
}
</style>
