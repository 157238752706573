<template>
  <PxStepByStepDialog
    v-model:current-page="currentPage"
    :class="`milestone-planner-onboarding-dialog--${userType}`"
    :page-count="pageCount"
    :visibility="visibility"
    class="milestone-planner-onboarding-dialog"
    data-testid="milestone-planner-onboarding-dialog"
    @close-dialog="$emit('close-dialog')"
  >
    <template #title>
      <h3>
        {{ $t("milestonePlanner.onboardingModal.title") }}
      </h3>
      <p
        :class="{ 'onboarding-subtitle--compact': currentPage > 2 }"
        data-testid="onboarding-subtitle"
      >
        {{
          $t(
            `milestonePlanner.onboardingModal.${userType}.subtitle.${dialogSubtitleIndex}`,
          )
        }}
      </p>
    </template>
    <div v-show="currentPage === 1">
      <img
        alt="onboarding-modal-confettis"
        class="onboarding-image-confettis"
        src="/img/icons/confettis.svg"
      />
      <img
        :src="`/img/illustrations/milestone-planner/${userType}/onboarding-dialog-page-1.png`"
        :srcset="`
          /img/illustrations/milestone-planner/${userType}/onboarding-dialog-page-1@2x.png
        `"
        alt="onboarding-modal"
        class="onboarding-images onboarding-images--page1"
      />
    </div>
    <img
      v-show="currentPage === 2"
      :src="`/img/illustrations/milestone-planner/${userType}/onboarding-dialog-page-2.png`"
      :srcset="`
          /img/illustrations/milestone-planner/${userType}/onboarding-dialog-page-2@2x.png
        `"
      alt="onboarding-modal"
      class="onboarding-images onboarding-images--page2"
    />
    <img
      v-show="currentPage === 3"
      alt="onboarding-modal"
      class="onboarding-images onboarding-images--page3"
      src="/img/illustrations/milestone-planner/onboarding-dialog-page-3.png"
      srcset="
        /img/illustrations/milestone-planner/onboarding-dialog-page-3@2x.png
      "
    />
    <img
      v-show="currentPage === 4"
      alt="onboarding-modal"
      class="onboarding-images onboarding-images--page4"
      src="/img/illustrations/milestone-planner/onboarding-dialog-page-4.png"
      srcset="
        /img/illustrations/milestone-planner/onboarding-dialog-page-4@2x.png
      "
    />
    <img
      v-show="currentPage === 5"
      alt="onboarding-modal"
      class="onboarding-images onboarding-images--page5"
      src="/img/illustrations/milestone-planner/onboarding-dialog-page-5.png"
      srcset="
        /img/illustrations/milestone-planner/onboarding-dialog-page-5@2x.png
      "
    />
    <img
      v-show="currentPage === 6"
      alt="onboarding-modal"
      class="onboarding-images onboarding-images--page6"
      src="/img/illustrations/milestone-planner/onboarding-dialog-page-6.png"
      srcset="
        /img/illustrations/milestone-planner/onboarding-dialog-page-6@2x.png
      "
    />
    <MilestoneStageStatusSelector
      v-if="currentPage > 2"
      :selected-status="currentPage - pagesWithoutSelector"
      @selected-status="changePage"
    ></MilestoneStageStatusSelector>
  </PxStepByStepDialog>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import MilestoneStageStatusSelector from "@/modules/milestone-planner/components/milestone-stage-status-selector/milestone-stage-status-selector.vue";
import { MATCHING_USER_TYPES } from "@/modules/matching/constants";

export default defineComponent({
  name: "MilestoneOnboardingDialog",

  components: {
    MilestoneStageStatusSelector,
  },

  props: {
    visibility: { type: Boolean, required: true },
    isVisitor: { type: Boolean, default: false },
  },

  data() {
    return {
      currentPage: 1,
      pageCount: 6,
      pagesWithoutSelector: 3,
    };
  },

  computed: {
    dialogSubtitleIndex(): number {
      return this.currentPage < this.pagesWithoutSelector
        ? this.currentPage - 1
        : 2;
    },

    userType(): string {
      return this.isVisitor ? "visitor" : MATCHING_USER_TYPES[0];
    },
  },

  methods: {
    changePage(page: number) {
      this.currentPage = page;
    },
  },
});
</script>

<style lang="scss">
.milestone-planner-onboarding-dialog--entrepreneur {
  .el-dialog__body {
    height: 431px;
  }

  .milestone-stage-status-selector {
    margin-top: 35px;
  }
}

.milestone-planner-onboarding-dialog--visitor {
  .el-dialog__body {
    height: 392px;
  }

  .milestone-stage-status-selector {
    margin-top: 18px;
  }

  .onboarding-images--page1 {
    margin-top: 28px;
    margin-left: 35px;
  }
}

.milestone-planner-onboarding-dialog.el-dialog {
  .el-dialog__header {
    @include grotesk(regular);

    padding: 31px 0 28px 0;
    color: $ebony-clay;
    text-align: center;
    background-image: url("#{$assetsPath}/img/illustrations/milestone-planner/onboarding-dialog-header.svg");

    p {
      font-size: 17px;
      line-height: 22px;
      letter-spacing: -0.4px;
    }

    .onboarding-subtitle--compact {
      letter-spacing: -0.38px;
    }

    h3 {
      margin-bottom: 7px;
      font-size: 20px;
      font-weight: bold;
      line-height: 30px;
    }
  }

  .el-dialog__body {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 0 24px 0;
  }

  .onboarding-images {
    position: relative;
    max-width: 100%;
    margin-bottom: 20px;

    &--page1 {
      right: 11px;
      width: 655px;
      margin-top: 31px;
    }

    &--page2 {
      width: 640px;
      margin-top: 47px;
    }

    &--page3,
    &--page4,
    &--page5,
    &--page6 {
      left: 3px;
      width: 605px;
      margin-top: 23px;
      margin-bottom: -2px;
    }
  }

  .onboarding-image-confettis {
    position: absolute;
    top: 82px;
    right: 15px;
  }
}
</style>
