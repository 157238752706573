import { renderSlot as _renderSlot, withModifiers as _withModifiers, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-52315f18"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "px-more-list" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ElTooltip = _resolveComponent("ElTooltip")!
  const _component_PxListModal = _resolveComponent("PxListModal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.$screen.mdUp)
      ? (_openBlock(), _createBlock(_component_ElTooltip, {
          key: 0,
          placement: "top",
          "popper-class": "px-more-list__tooltip el-abaca-tooltip"
        }, {
          content: _withCtx(() => [
            _createElementVNode("div", null, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.valuesOnLimit, (value, index) => {
                return (_openBlock(), _createElementBlock("p", {
                  key: index,
                  class: "px-more-list__text"
                }, _toDisplayString(value), 1))
              }), 128)),
              (_ctx.hasValuesOverLimit)
                ? (_openBlock(), _createElementBlock("p", {
                    key: 0,
                    class: "px-more-list__text-more",
                    onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onClickMoreHandler && _ctx.onClickMoreHandler(...args)))
                  }, _toDisplayString(_ctx.$t("common.andMoreNumber", { number: _ctx.valuesOverLimitLength })), 1))
                : _createCommentVNode("", true)
            ])
          ]),
          default: _withCtx(() => [
            _createElementVNode("div", {
              class: "px-more-list__content",
              onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onClickMoreHandler && _ctx.onClickMoreHandler(...args)), ["stop"]))
            }, [
              _renderSlot(_ctx.$slots, "content", {}, undefined, true)
            ])
          ]),
          _: 3
        }))
      : (_openBlock(), _createElementBlock("div", {
          key: 1,
          onClick: _cache[2] || (_cache[2] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onClickMoreHandler && _ctx.onClickMoreHandler(...args)), ["stop"]))
        }, [
          _renderSlot(_ctx.$slots, "content", {}, undefined, true)
        ])),
    _createVNode(_component_PxListModal, {
      ref: "pxMoreListModal",
      items: _ctx.fullList,
      "onUpdate:items": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.fullList) = $event)),
      visibility: _ctx.isModalVisible,
      "onUpdate:visibility": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.isModalVisible) = $event)),
      capitalize: _ctx.capitalize,
      title: _ctx.modalTitle
    }, null, 8, ["items", "visibility", "capitalize", "title"])
  ]))
}