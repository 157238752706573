import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-0765d5ae"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "complete-milestone-info-indicator__label" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PxIcon = _resolveComponent("PxIcon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([{ 'complete-milestone-info-indicator--has-evidence': _ctx.hasEvidence }, "complete-milestone-info-indicator"])
  }, [
    _createVNode(_component_PxIcon, {
      name: _ctx.iconName,
      size: 17,
      class: "complete-milestone-info-indicator__icon"
    }, null, 8, ["name"]),
    _createElementVNode("div", null, [
      _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.label) + _toDisplayString(_ctx.hasAction ? "." : ""), 1),
      (_ctx.hasAction)
        ? (_openBlock(), _createElementBlock("span", {
            key: 0,
            class: "complete-milestone-info-indicator__action",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('action-click')))
          }, _toDisplayString(_ctx.actionLabel), 1))
        : _createCommentVNode("", true)
    ])
  ], 2))
}