<template>
  <div class="account-view">
    <PxTabBar>
      <div class="account-view__header">
        <div class="account-view__header-photo">
          <PxAvatar
            :loading="!company"
            :name="companyName"
            :photo="companyLogo"
            :size="74"
          />
        </div>
        <p class="account-view__header-name">
          {{ companyName }}
        </p>
        <p class="account-view__header-account-type">
          {{ $profile.userType() }}
        </p>
      </div>
    </PxTabBar>
    <VList class="account-view__menu">
      <template v-for="(item, index) in items">
        <VDivider
          v-if="item.divider"
          :key="index"
          :inset="item.inset"
          class="account-view__menu-divider"
        />
        <VListItem
          v-else-if="item.visible"
          :key="item.key"
          :class="[{ 'is-dark': item.dark }, `menu__tile--${item.key}`]"
          avatar
          class="account-view__menu-tile"
          @click="onItemClick(item)"
        >
          <template v-if="item.icon" #prepend>
            <i
              :class="`el-icon-${item.icon}`"
              class="icon account-view__menu-icon"
            />
          </template>
          <VListItemTitle
            class="account-view__menu-title"
            v-text="item.title"
          />
          <VListItemAction>
            <i
              class="icon el-icon-arrow--right-grey-big account-view__menu-go-icon"
            />
          </VListItemAction>
        </VListItem>
      </template>
    </VList>
    <div class="account-view__container">
      <PxButton
        class="el-button--block logout-btn"
        size="large"
        type="blue-grey"
        @click="onClickLogout"
      >
        {{ $t("common.logout") }}
      </PxButton>
      <p class="account-view__footer">
        {{
          $t("common.footer.copyright", {
            year: currentYear,
          })
        }}
      </p>
    </div>
    <PxBottomBar :items="mobileMenuItems" />
    <AccountSettingsModal
      v-model:visibility="isAccountSettingsVisible"
      :open-view="accountSettingsView"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import {
  VList,
  VListItem,
  VListItemAction,
  VListItemTitle,
  VDivider,
} from "vuetify/components";

import {
  ROUTE_PROFILE,
  ROUTE_USER_ACCOUNT,
  ROUTE_ACCOUNT_SETTINGS,
} from "@/modules/profile/services/router/routes-names";
import AccountSettingsModal from "@/modules/profile/components/account-settings-modal/account-settings-modal.vue";

import { ICompany } from "@/modules/profile/services/data/company/company.types";
import { EAuthActions } from "@/modules/authentication/services/store/auth/auth-types";
import { ROUTE_MATCHING_LIST } from "@/modules/matching/services/router/routes-names";
import { EAccountSettingsNavigationTabs } from "@/modules/profile/components/account-settings/account-settings-navigation.vue";

export default defineComponent({
  components: {
    AccountSettingsModal,
    VList,
    VListItem,
    VListItemAction,
    VListItemTitle,
    VDivider,
  },

  data() {
    return {
      currentYear: new Date().getFullYear(),
      isAccountSettingsVisible: false,
      accountSettingsView: "email",

      items: [
        {
          key: "email",
          icon: "email",
          dark: true,
          title: this.$t("profile.account.menu.email"),
          visible:
            !this.$features.isEnabled("settingsPage") ||
            this.$user.isEntrepreneur(),
        },
        {
          key: "settings",
          icon: "cog--dark",
          dark: true,
          title: this.$t("profile.account.menu.settings"),
          visible:
            !this.$user.isEntrepreneur() &&
            this.$features.isEnabled("settingsPage"),
        },
        {
          divider:
            !this.$features.isEnabled("settingsPage") ||
            this.$user.isEntrepreneur(),
          inset: true,
        },
        {
          key: "password",
          icon: "locker",
          dark: true,
          title: this.$t("profile.account.menu.password"),
          visible:
            !this.$features.isEnabled("settingsPage") ||
            this.$user.isEntrepreneur(),
        },
        {
          divider: true,
        },
        {
          key: "cookie",
          title: this.$t("profile.account.menu.cookie"),
          visible: true,
        },
        { divider: true, inset: true },
        {
          key: "privacy",
          title: this.$t("profile.account.menu.privacy"),
          visible: true,
        },
        { divider: true, inset: true },
        {
          key: "terms",
          title: this.$t("profile.account.menu.terms"),
          visible: true,
        },
      ],

      menuItems: [
        {
          value: "my_company",
          label: this.$t("common.navigation.menuItems.myCompany"),
          icon: "company",
          to: ROUTE_PROFILE,
        },
        {
          value: "my_supporters",
          label: this.$user.isSupporter()
            ? this.$t("common.navigation.menuItems.myMatches")
            : this.$t("common.navigation.menuItems.mySupporters"),
          icon: "flow-circle",
          to: ROUTE_MATCHING_LIST,
        },
        {
          value: "account",
          label: this.$t("common.navigation.menuItems.account"),
          icon: "gear",
          to: ROUTE_USER_ACCOUNT,
        },
      ],
    };
  },

  computed: {
    isMobile(): boolean {
      return this.$screen.smDown;
    },

    company(): ICompany | null {
      return this.$store.get("auth/company/data");
    },

    companyLogo(): string {
      return this.company ? this.company.logo : "";
    },

    companyName(): string {
      return this.company ? this.company.name : "";
    },

    mobileMenuItemsWithoutMatching(): any {
      return this.menuItems.filter((item) => item.value !== "my_supporters");
    },

    mobileMenuItems(): any {
      return this.isMatchingDisabled
        ? this.mobileMenuItemsWithoutMatching
        : this.menuItems;
    },

    isMatchingDisabled(): boolean {
      return !this.$features.isEnabled("match");
    },
  },

  watch: {
    /**
     * Redirect to the profile if the user is accessing the
     * view on a desktop size screen.
     */
    isMobile: {
      immediate: true,
      handler(isMobile) {
        if (!isMobile) {
          this.$router.replace({ name: ROUTE_PROFILE });
        }
      },
    },
  },

  methods: {
    /**
     * Handle click on an item.
     */
    onItemClick(item: any) {
      switch (item.key) {
        case "cookie":
          if ((window as any).OneTrust) {
            (window as any).OneTrust.ToggleInfoDisplay();
          }
          break;
        case "privacy":
          window.open("https://abaca.app/privacy-policy/");
          break;
        case "terms":
          window.open("https://abaca.app/terms-of-use/");
          break;
        case "email":
        case "password":
          this.accountSettingsView = item.key;
          this.isAccountSettingsVisible = true;
          break;
        case "settings":
          this.$router.push({
            name: ROUTE_ACCOUNT_SETTINGS,
            query: {
              tab: EAccountSettingsNavigationTabs.INFO,
            },
          });
          break;
      }
    },

    onClickLogout() {
      this.$store.dispatch(EAuthActions.LOGOUT);
    },
  },
});
</script>

<style lang="scss" scoped>
.account-view {
  :deep(.px-tab-bar__container) {
    height: 218px;
  }

  &__header {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 10px;

    &-photo {
      position: relative;
      margin-top: 14px;
      overflow: hidden;

      font-size: 0;
      line-height: 0;

      border-radius: 2px;
    }

    &-photo::after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;

      content: "";

      border: solid 1px rgba(white, 0.3);
    }

    &-name {
      @include grotesk(semiBold);

      padding: 13px 10px 0;

      font-size: to-rem(18px);
      line-height: 1.2;
      color: white;
      text-align: center;
    }

    &-account-type {
      font-size: 15px;
      color: white;
      letter-spacing: -0.3px;
    }
  }

  &__menu {
    margin-top: 29px;
    padding: 8px 0 68px 0;

    border-top: 1px solid $pale-grey;
    border-bottom: 1px solid $pale-grey;

    &-icon {
      width: 16px;
      height: 16px;
    }

    &-divider.v-divider--inset:not(.v-divider--vertical) {
      max-width: calc(100% - 30px);
      margin-left: 15px;
      opacity: 1;

      border-color: $athens-gray;
    }

    .v-list-item-title {
      font-size: to-rem(16px);
      color: $ebony-clay;
      height: 28px;
      letter-spacing: -0.33px;
    }

    :deep(.v-ripple__animation),
    :deep(.v-list-item__overlay) {
      background-color: rgba(0, 0, 0, 0.04);
    }

    &-tile.is-dark :deep(.v-list-item-title) {
      @include grotesk(medium);

      font-size: to-rem(17px);
    }

    &-go-icon {
      width: 16px;
      height: 16px;
    }

    & :deep(.v-list-item__prepend) {
      margin-right: 10px;
    }

    & :deep(.v-list-item--density-default.v-list-item--one-line) {
      padding: 0 22px 0 24px;
      min-height: auto;
    }

    & :deep(.v-list-item__content) {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 42px;
    }

    & :deep(.v-list__tile__action) {
      position: relative;
      bottom: 1px;
      min-width: auto;
    }
  }

  .logout-btn {
    margin: 40px auto 0;
    max-width: 288px;
  }

  &__footer {
    @include grotesk(light);

    width: 100%;
    padding-top: 9px;

    font-size: to-rem(13px);
    text-align: center;
    letter-spacing: -0.2px;
  }
}
</style>
