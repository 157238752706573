<template>
  <div class="px-matching-interest-compact">
    <ElTooltip
      :content="ctaTip"
      :enterable="false"
      class="item"
      effect="dark"
      placement="top"
      popper-class="px-matching-interest-compact__tooltip el-abaca-tooltip"
    >
      <PxButton
        :icon="ctaIcon"
        :label="ctaText"
        :size="size"
        :type="ctaType"
        :variant="variant"
        class="px-matching-interest__cta"
        @click="clickInterestCTAHandler"
      />
    </ElTooltip>
    <PxMatchingInterestRemovalModal
      v-model:has-server-error="hasServerError"
      v-model:is-loading="isLoading"
      v-model:visibility="isRemoveInterestModalVisible"
      :interest-name="name"
      :interest-state="interestState"
      @removed-interest="onRemovedInterest"
    />
    <PxMatchingInterestDirectoryMemberModal
      v-if="isDirectoryMemberModalVisible"
      v-model:has-server-error="hasServerError"
      v-model:is-loading="isLoading"
      v-model:visibility="isDirectoryMemberModalVisible"
      :company-uid="uid"
      @connect="clickInterestCTAHandler"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import { EPxButtonVariant } from "@/components/px-button/px-button.types";
import PxMatchingInterestMixin from "@/mixins/px-matching-interest.mixin";

export default defineComponent({
  name: "PxMatchingInterestCompact",

  mixins: [PxMatchingInterestMixin],

  props: {
    variant: {
      type: String,
      default: EPxButtonVariant.COMPACT,
    },
  },
});
</script>

<style lang="scss" scoped>
/* Custom "Connected" CTA styles */
.px-matching-interest__cta.el-button--yellow :deep() {
  .px-icon {
    min-width: 18px;
    min-height: 18px;
    margin-right: 0;
  }
}

/* Custom "Interested" CTA styles */
.px-matching-interest__cta.el-button--ghost-green :deep() {
  background-color: white;

  &:hover .px-icon {
    filter: brightness(75%);
  }
}
</style>

<style lang="scss">
.px-matching-interest-compact__tooltip.el-abaca-tooltip {
  padding: 5px 10px;
  pointer-events: none;
}
</style>
