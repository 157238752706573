<template>
  <div class="level-update-modal-footer">
    <ElPopover
      v-model:visible="isPopoverVisible"
      :width="$screen.xsOnly ? 260 : 272"
      data-testid="level-update-modal-popover"
      placement="top"
      trigger="click"
    >
      <div class="level-update-modal-footer__popover-discard-title">
        {{ $t("common.components.levelUpdateModal.popover.title") }}
      </div>
      <div class="level-update-modal-footer__popover-discard-description">
        {{ $t("common.components.levelUpdateModal.popover.description") }}
      </div>
      <div class="level-update-modal-footer__popover-discard-buttons">
        <PxButton
          ref="cancelButton"
          class="el-button--link"
          data-testid="cancel-button"
          size="small"
          type="link"
          @click="isPopoverVisible = false"
        >
          {{ $t("common.components.levelUpdateModal.popover.cancel") }}
        </PxButton>
        <PxButton
          ref="discardButton"
          class="level-update-modal-footer__popover-discard-btn"
          data-testid="discard-button"
          type="red"
          @click="onClickDiscard"
        >
          {{ $t("common.components.levelUpdateModal.popover.discard") }}
        </PxButton>
      </div>
      <template #reference>
        <PxButton
          :class="{ 'el-button--block': $screen.xsOnly }"
          :disabled="isLoading"
          class="level-update-modal-levels-info-footer__discard-btn"
          data-testid="discard-button"
        >
          {{ $t("common.components.levelUpdateModal.buttons.discard") }}
        </PxButton>
      </template>
    </ElPopover>
    <PxButton
      :class="{ 'el-button--block': $screen.xsOnly }"
      :loading="isLoading"
      data-testid="update-button"
      type="primary"
      @click="onClickSaveAssessment"
    >
      {{ updateButtonCopy }}
    </PxButton>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import { EMetaActions } from "@/services/store/meta/meta-types";
import { IAssessmentLevel } from "@/services/data/assessment/assessment.interface";
import { assessmentProvider } from "@/services/data/assessment/assessment.provider";

import {
  ENTREPRENEUR_USER_GROUP_ID,
  SUPPORTER_USER_GROUP_ID,
  QUICK_LEVEL_UPDATE_META_ON_UPDATE,
  QUICK_LEVEL_UPDATE_META_UPGRADE,
  QUICK_LEVEL_UPDATE_META_SAME_LEVEL,
} from "@/modules/common/constants";
import { EAuthActions } from "@/modules/authentication/services/store/auth/auth-types";
import { ICompany } from "@/modules/profile/services/data/company/company.types";
import { TranslateResult } from "vue-i18n";

export default defineComponent({
  name: "LevelUpdateModalFooter",

  props: {
    currentLevel: {
      type: Number,
      required: true,
    },
    newLevel: {
      type: Number,
      required: true,
    },
    sameLevel: {
      type: Boolean,
      default: false,
    },
    hasError: {
      type: Boolean,
      default: false,
    },
    currentAssessment: {
      type: Array as () => IAssessmentLevel[],
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isLoading: this.loading,
      isPopoverVisible: false,
      hasServerError: this.hasError,
    };
  },

  computed: {
    company(): ICompany {
      return this.$store.get("auth/company.data");
    },

    userGroupId(): number {
      return this.$user.isEntrepreneur()
        ? ENTREPRENEUR_USER_GROUP_ID
        : SUPPORTER_USER_GROUP_ID;
    },

    updateButtonCopy(): TranslateResult {
      return this.sameLevel
        ? this.$t("common.components.levelUpdateModal.buttons.saveChanges")
        : this.$t("common.components.levelUpdateModal.buttons.saveUpdate");
    },
  },

  watch: {
    hasServerError(newValue: boolean) {
      if (this.hasError !== newValue) {
        this.$emit("update:hasError", newValue);
      }
    },

    isLoading(newValue: boolean) {
      if (this.loading !== newValue) {
        this.$emit("update:loading", newValue);
      }
    },
  },

  methods: {
    onClickDiscard() {
      this.cleanAssessmentData();
    },

    /**
     * Hide the modal and remove the after assessment mode.
     */
    cleanAssessmentData() {
      this.$emit("close-modal");
      this.$store.dispatch(
        EMetaActions.REMOVE,
        QUICK_LEVEL_UPDATE_META_ON_UPDATE,
      );
      this.isPopoverVisible = false;
    },

    /**
     * Handle click on the save assessment button.
     *
     * This will save the new assessment on the API and clear
     * the temporary assessment data.
     */
    //TODO: test the assessment provider
    async onClickSaveAssessment() {
      this.isLoading = true;
      this.hasServerError = false;

      if (this.sameLevel) {
        // Create some metadata to help the other components to check if
        // the level was not changed
        this.$store.dispatch(EMetaActions.SET, {
          key: QUICK_LEVEL_UPDATE_META_SAME_LEVEL,
          value: true,
        });
      } else {
        // Create some metadata to help the other components to check if
        // this was an level upgrade or downgrade.
        this.$store.dispatch(EMetaActions.SET, {
          key: QUICK_LEVEL_UPDATE_META_UPGRADE,
          value: this.newLevel > this.currentLevel,
        });
      }

      try {
        await assessmentProvider.register(this.userGroupId, {
          levels: this.currentAssessment.map((e: IAssessmentLevel) => ({
            category: e.category,
            level: e.level === 0 ? null : e.level,
          })),
        });
        // Update assessment on store:
        await this.$store.dispatch(
          EAuthActions.FETCH_LATEST_ASSESSMENT,
          this.company.id,
        );
      } catch (error) {
        this.hasServerError = true;
        return;
      } finally {
        this.isLoading = false;
      }

      this.cleanAssessmentData();
      this.$emitter.emit("save-new-assessment");
    },
  },
});
</script>

<style lang="scss" scoped>
.level-update-modal-footer {
  position: relative;
  width: 100%;
  padding: 16px 0 14px;

  @include breakpoint-up(sm) {
    display: flex;
    gap: 16px;
    justify-content: center;
    padding: 16px 30px 16px;
  }

  &::before {
    position: absolute;
    top: -1px;
    left: 50%;
    width: 100%;
    height: 1px;
    content: "";
    background-color: #f0f1f5;
    transform: translateX(-50%);
  }

  .el-button {
    padding: 0 15px;
    margin: 0;
  }

  &__discard-btn {
    margin-bottom: 16px;

    @include breakpoint-up(sm) {
      margin: 0 20px 0 0;
    }
  }

  &__popover-discard-title {
    @include grotesk(semiBold);

    margin-bottom: 8px;

    font-size: to-rem(18px);
    line-height: 28px;
  }

  &__popover-discard-description {
    margin-top: 9px;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: -0.3px;
  }

  &__popover-discard-buttons {
    margin-top: 22px;

    .el-button.el-button--link {
      margin-right: 2px;
      margin-bottom: 2px;
      margin-left: -1px;
    }

    :deep() .el-button--link:hover span {
      color: $white;
    }
  }

  &__popover-discard-btn {
    min-width: 89px;
    height: 36px;
  }
}
</style>
