import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-510d9101"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "milestone-form" }
const _hoisted_2 = { class: "milestone-form__header" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MilestoneNavigationTab = _resolveComponent("MilestoneNavigationTab")!
  const _component_MilestonePlanStateBadge = _resolveComponent("MilestonePlanStateBadge")!
  const _component_MilestoneFormPlan = _resolveComponent("MilestoneFormPlan")!
  const _component_MilestoneFormComplete = _resolveComponent("MilestoneFormComplete")!
  const _component_MilestoneFormGuestView = _resolveComponent("MilestoneFormGuestView")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_MilestoneNavigationTab, {
        "active-tab": _ctx.innerActiveTab,
        "onUpdate:activeTab": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.innerActiveTab) = $event)),
        "milestone-completed": _ctx.isMilestoneComplete,
        planned: _ctx.isPlanPublished
      }, null, 8, ["active-tab", "milestone-completed", "planned"]),
      (_ctx.stateLabel)
        ? (_openBlock(), _createBlock(_component_MilestonePlanStateBadge, {
            key: 0,
            "is-visitor": _ctx.isVisitor,
            label: _ctx.stateLabel,
            "last-update": _ctx.lastUpdate
          }, null, 8, ["is-visitor", "label", "last-update"]))
        : _createCommentVNode("", true)
    ]),
    (!_ctx.isVisitor)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          (_ctx.isPlanTabActive)
            ? (_openBlock(), _createBlock(_component_MilestoneFormPlan, {
                key: 0,
                "category-level": _ctx.categoryLevel,
                "category-name": _ctx.category.name,
                "evidence-question": _ctx.evidenceQuestion,
                "has-completion-info": _ctx.hasCompletionInfo,
                "is-future-milestone": _ctx.isFutureMilestone,
                milestone: _ctx.milestone,
                onPlanFormHasChanges: _ctx.checkPlanChanges,
                onPlanFormIsComplete: _ctx.checkPlanIsComplete,
                onPlanFormIsInvalid: _ctx.checkPlanIsInvalid
              }, null, 8, ["category-level", "category-name", "evidence-question", "has-completion-info", "is-future-milestone", "milestone", "onPlanFormHasChanges", "onPlanFormIsComplete", "onPlanFormIsInvalid"]))
            : (_openBlock(), _createBlock(_component_MilestoneFormComplete, {
                key: 1,
                "category-level": _ctx.categoryLevel,
                "is-future-milestone": _ctx.isFutureMilestone,
                milestone: _ctx.milestone,
                questions: _ctx.milestoneQuestions,
                onCompleteFormHasChanges: _ctx.checkCompleteFormChanges,
                onCompleteFormIsInvalid: _ctx.checkCompleteIsInvalid
              }, null, 8, ["category-level", "is-future-milestone", "milestone", "questions", "onCompleteFormHasChanges", "onCompleteFormIsInvalid"]))
        ], 64))
      : (_openBlock(), _createBlock(_component_MilestoneFormGuestView, {
          key: 1,
          "is-milestone-complete": _ctx.isMilestoneComplete,
          "is-milestone-planned": _ctx.isMilestonePlanned,
          "is-plan-published": _ctx.isPlanPublished,
          "is-plan-tab-active": _ctx.isPlanTabActive,
          milestone: _ctx.milestone,
          questions: _ctx.milestoneQuestions
        }, null, 8, ["is-milestone-complete", "is-milestone-planned", "is-plan-published", "is-plan-tab-active", "milestone", "questions"]))
  ]))
}