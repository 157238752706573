import { GenericProvider, Provider } from "@/services/data/generic-provider";
import {
  IConsentRequest,
  IConsentResponse,
  IConsentType,
} from "./consent.interface";
import { baseAPIUrl } from "@/services/utils/utils";

/**
 * Provider used to perform a login.
 */
@Provider(`${baseAPIUrl}/consent`)
class ConsentProvider extends GenericProvider<
  IConsentRequest,
  IConsentResponse
> {
  public async check(type: IConsentType): Promise<IConsentResponse> {
    const url = super.buildEndPointUrl({ type });
    const request = this.httpClient.get(url);
    const { data } = await this.wrapRequest(request);
    return data;
  }

  public async grant(type: IConsentType): Promise<IConsentResponse> {
    const url = super.buildEndPointUrl({});
    const request = this.httpClient.post(url, { type });
    const { data } = await this.wrapRequest(request);
    return data;
  }
}

export const consentProvider = new ConsentProvider();
