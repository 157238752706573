import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "grid-legend" }
const _hoisted_2 = { class: "grid-legend__headline" }
const _hoisted_3 = { class: "grid-legend-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_GridLegendContainer = _resolveComponent("GridLegendContainer")!
  const _component_ElCollapseItem = _resolveComponent("ElCollapseItem")!
  const _component_ElCollapse = _resolveComponent("ElCollapse")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ElCollapse, null, {
      default: _withCtx(() => [
        _createVNode(_component_ElCollapseItem, null, {
          title: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("span", null, _toDisplayString(_ctx.$t("milestonePlanner.milestoneGrid.gridLegend.title")), 1)
            ])
          ]),
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_GridLegendContainer, {
                legend: _ctx.completedStatus,
                "tooltip-text": _ctx.completedTooltip
              }, null, 8, ["legend", "tooltip-text"]),
              _createVNode(_component_GridLegendContainer, {
                legend: _ctx.notCompletedStatus,
                "tooltip-text": _ctx.notCompletedTooltip
              }, null, 8, ["legend", "tooltip-text"])
            ])
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}