<template>
  <div class="weights-panel">
    <details>
      <summary>Adjust algorithm weights</summary>
      <main>
        <table>
          <thead>
            <tr>
              <th />
              <th>
                <PxIcon :size="20" name="match" title="Match" />
              </th>
              <th>
                <PxIcon :size="20" name="no-match" title="No Match" />
              </th>
              <th>
                <PxIcon :size="20" name="unanswered" title="Unanswered" />
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(weightName, index) in weightNames" :key="index">
              <th v-text="weightName" />
              <td>
                <input
                  v-model.number="weights['matched'][index]"
                  min="0"
                  type="number"
                />
              </td>
              <td>
                <input
                  v-model.number="weights['unmatched'][index]"
                  min="0"
                  type="number"
                />
              </td>
              <td>
                <input
                  v-model.number="weights['unanswered'][index]"
                  min="0"
                  type="number"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </main>
    </details>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import cloneDeep from "lodash/cloneDeep";
import { ECapitalExplorerMutations } from "@/modules/capital-explorer/services/store/capital-explorer/capital-explorer.types";

export default defineComponent({
  name: "WeightsPanel",
  data() {
    return {
      weights: {
        matched: [0, 0, 0, 0, 0],
        unmatched: [0, 0, 0, 0, 0],
        unanswered: [0, 0, 0, 0, 0],
      },
      weightNames: [
        "Irrelevant",
        "A Little Important",
        "Somewhat Important",
        "Very Important",
        "Extremely Important",
      ],
    };
  },
  computed: {
    capitalExplorerData() {
      return this.$store.get("capitalExplorer/data");
    },
  },
  watch: {
    weights: {
      handler() {
        this.$store.commit(
          `capitalExplorer/${ECapitalExplorerMutations.SET_DATA}`,
          { ...this.capitalExplorerData, weights: cloneDeep(this.weights) },
        );
      },
      deep: true,
    },
  },
  mounted() {
    this.weights = cloneDeep(this.capitalExplorerData.weights);
  },
});
</script>

<style lang="scss" scoped>
.weights-panel {
  padding: 16px 24px 0;
}

summary {
  font-weight: 500;
  user-select: none;
}

main {
  border: 1px solid $mischka;
  border-radius: 4px;
  margin-top: 12px;
}

thead {
  text-align: center;

  th {
    padding: 6px 0;
  }

  td,
  th {
    border-bottom: 1px solid $mischka;
  }
}

tbody {
  th {
    font-size: 0.8em;
    padding: 0 6px;
  }
}

th:not(:last-child),
td:not(:last-child) {
  border-right: 1px solid $mischka;
}

tr:not(:last-child) {
  td,
  th {
    border-bottom: 1px solid $mischka;
  }
}

input {
  width: 100%;
  border: none;
  height: 40px;
  padding-left: 10px;
}
</style>
