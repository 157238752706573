export const GENDER_MOCK_DATA = {
  question: "How would you describe your gender identity?",
  companies: [
    {
      name: "",
      id: 1,
      team_members_count: 1,
      team_members_with_responses_count: 1,
      responses: [{ value: "Non-Binary", count: 1, percentage: 1 }],
    },
    {
      name: "",
      id: 1,
      team_members_count: 4,
      team_members_with_responses_count: 4,
      responses: [
        { value: "Non-Binary", count: 1, percentage: 0.25 },
        { value: "Man", count: 1, percentage: 0.25 },
        { value: "Unsure", count: 1, percentage: 0.25 },
        { value: "Woman", count: 1, percentage: 0.25 },
      ],
    },
    {
      name: "",
      id: 1,
      team_members_count: 1,
      team_members_with_responses_count: 1,
      responses: [{ value: "Man", count: 1, percentage: 1 }],
    },
    {
      name: "",
      id: 1,
      team_members_count: 1,
      team_members_with_responses_count: 0,
      responses: [],
    },
    {
      name: "",
      id: 1,
      team_members_count: 2,
      team_members_with_responses_count: 0,
      responses: [],
    },
    {
      name: "",
      id: 1,
      team_members_count: 1,
      team_members_with_responses_count: 1,
      responses: [{ value: "Prefer not to say", count: 1, percentage: 1 }],
    },
    {
      name: "",
      id: 1,
      team_members_count: 1,
      team_members_with_responses_count: 1,
      responses: [{ value: "Prefer not to say", count: 1, percentage: 1 }],
    },
    {
      name: "",
      id: 1,
      team_members_count: 2,
      team_members_with_responses_count: 2,
      responses: [
        { value: "Unsure", count: 1, percentage: 0.5 },
        { value: "Woman", count: 1, percentage: 0.5 },
      ],
    },
    {
      name: "",
      id: 1,
      team_members_count: 2,
      team_members_with_responses_count: 0,
      responses: [],
    },
    {
      name: "",
      id: 1,
      team_members_count: 1,
      team_members_with_responses_count: 1,
      responses: [{ value: "Unsure", count: 1, percentage: 1 }],
    },
    {
      name: "",
      id: 1,
      team_members_count: 7,
      team_members_with_responses_count: 0,
      responses: [],
    },
    {
      name: "",
      id: 1,
      team_members_count: 1,
      team_members_with_responses_count: 1,
      responses: [{ value: "Woman", count: 1, percentage: 1 }],
    },
  ],
  responses: [
    { value: "Man", count: 2, percentage: 0.17 },
    { value: "Woman", count: 3, percentage: 0.25 },
    { value: "Non-Binary", count: 2, percentage: 0.17 },
    { value: "Unsure", count: 3, percentage: 0.25 },
    { value: "Prefer not to say", count: 2, percentage: 0.17 },
  ],
  companies_with_responses: 8,
};

export const PROCESS_REPORTS_MOCK_DATA = [
  {
    step: "Pipeline",
    is_locked: false,
    ...GENDER_MOCK_DATA,
  },
  {
    step: "Screen",
    companies: [
      {
        name: "",
        id: 1,
        team_members_count: 2,
        team_members_with_responses_count: 2,
        responses: [{ value: "Man", count: 2, percentage: 1 }],
      },
      {
        name: "",
        id: 1,
        team_members_count: 4,
        team_members_with_responses_count: 3,
        responses: [
          { value: "Man", count: 1, percentage: 0.33 },
          { value: "Unsure", count: 1, percentage: 0.33 },
          { value: "Woman", count: 1, percentage: 0.33 },
        ],
      },
    ],
    companies_with_responses: 2,
    responses: [
      { value: "Man", count: 3, percentage: 0.6 },
      { value: "Woman", count: 1, percentage: 0.2 },
      { value: "Non-Binary", count: 0, percentage: 0 },
      { value: "Unsure", count: 1, percentage: 0.2 },
      { value: "Prefer not to say", count: 0, percentage: 0 },
    ],
    is_locked: false,
  },
  {
    step: "Meet",
    companies: [
      {
        name: "",
        id: 1,
        team_members_count: 2,
        team_members_with_responses_count: 2,
        responses: [{ value: "Man", count: 2, percentage: 1 }],
      },
      {
        name: "",
        id: 1,
        team_members_count: 5,
        team_members_with_responses_count: 5,
        responses: [
          { value: "Man", count: 2, percentage: 0.4 },
          { value: "Woman", count: 3, percentage: 0.6 },
        ],
      },
    ],
    companies_with_responses: 2,
    responses: [
      { value: "Man", count: 4, percentage: 0.57 },
      { value: "Woman", count: 3, percentage: 0.43 },
      { value: "Non-Binary", count: 0, percentage: 0 },
      { value: "Unsure", count: 0, percentage: 0 },
      { value: "Prefer not to say", count: 0, percentage: 0 },
    ],
    is_locked: false,
  },
  {
    step: "Due Diligence",
    companies: [
      {
        name: "",
        id: 1,
        team_members_count: 5,
        team_members_with_responses_count: 4,
        responses: [
          { value: "Man", count: 2, percentage: 0.5 },
          { value: "Woman", count: 1, percentage: 0.25 },
          { value: "Non-Binary", count: 0, percentage: 0 },
          { value: "Unsure", count: 1, percentage: 0.25 },
          { value: "Prefer not to say", count: 0, percentage: 0 },
        ],
      },
      {
        name: "Company B",
        id: 2,
        team_members_count: 3,
        team_members_with_responses_count: 2,
        responses: [
          { value: "Man", count: 1, percentage: 0.5 },
          { value: "Woman", count: 1, percentage: 0.5 },
          { value: "Non-Binary", count: 0, percentage: 0 },
          { value: "Unsure", count: 0, percentage: 0 },
          { value: "Prefer not to say", count: 0, percentage: 0 },
        ],
      },
    ],
    companies_with_responses: 2,
    responses: [
      { value: "Man", count: 3, percentage: 0.5 },
      { value: "Woman", count: 2, percentage: 0.33 },
      { value: "Non-Binary", count: 0, percentage: 0 },
      { value: "Unsure", count: 1, percentage: 0.17 },
      { value: "Prefer not to say", count: 0, percentage: 0 },
    ],
    is_locked: false,
  },
  {
    step: "Invest",
    companies: [
      {
        name: "Company C",
        id: 3,
        team_members_count: 4,
        team_members_with_responses_count: 3,
        responses: [
          { value: "Man", count: 1, percentage: 0.33 },
          { value: "Woman", count: 2, percentage: 0.67 },
          { value: "Non-Binary", count: 0, percentage: 0 },
          { value: "Unsure", count: 0, percentage: 0 },
          { value: "Prefer not to say", count: 0, percentage: 0 },
        ],
      },
      {
        name: "Company D",
        id: 4,
        team_members_count: 2,
        team_members_with_responses_count: 2,
        responses: [
          { value: "Man", count: 2, percentage: 1 },
          { value: "Woman", count: 0, percentage: 0 },
          { value: "Non-Binary", count: 0, percentage: 0 },
          { value: "Unsure", count: 0, percentage: 0 },
          { value: "Prefer not to say", count: 0, percentage: 0 },
        ],
      },
    ],
    companies_with_responses: 2,
    responses: [
      { value: "Man", count: 3, percentage: 0.43 },
      { value: "Woman", count: 3, percentage: 0.43 },
      { value: "Non-Binary", count: 0, percentage: 0 },
      { value: "Unsure", count: 1, percentage: 0.14 },
      { value: "Prefer not to say", count: 0, percentage: 0 },
    ],
    is_locked: false,
  },
];
