<template>
  <div class="milestone-form-footer-complete">
    <div class="milestone-form-footer-complete__left">
      <MilestoneFormFooterDeleteButton
        v-if="hasCompletionInfo"
        :button-disabled="aboveMilestonesCompleted"
        :button-label="deleteButtonLabel"
        :show-tooltip="aboveMilestonesCompleted"
        data-testid="milestone-delete-button"
        @click="$emitter.emit('delete-milestone-evidence')"
      />
      <div
        v-if="!userAddsEvidence && !hasCompletionInfo"
        class="milestone-form-footer-complete__evidence-tip"
      >
        <PxIcon
          :size="16"
          class="milestone-form-footer-complete__evidence-tip-icon"
          name="warning--orange"
        />
        {{ $t("milestonePlanner.milestonePlan.planView.evidenceTip") }}
      </div>
    </div>
    <div class="milestone-form-footer-complete__right">
      <PxButton
        v-bind="discardButtonProps"
        :disabled="!formHasChanges"
        class="milestone-form-footer-complete__discard-button"
        data-testid="discard-button"
        @click="$emitter.emit('discard-milestone-evidence')"
      />
      <MilestoneFormFooterSaveAsDraftButton
        :button-disabled="saveAsDraftDisabled"
        :loading="isLoadingMilestones"
        :show-tooltip="aboveMilestonesCompleted"
        data-testid="milestone-save-as-draft-button"
        @click="
          $emitter.emit('submit-milestone-evidence', { toPublish: false })
        "
      />
      <MilestoneFormFooterCompleteButton
        :button-disabled="completeDisabled"
        :button-label="completeButtonLabel"
        :loading="isLoadingMilestones"
        :show-tooltip="belowMilestonesNotCompleted"
        data-testid="milestone-complete-button"
        @click="$emitter.emit('submit-milestone-evidence', { toPublish: true })"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { EPxButtonType } from "@/components/px-button/px-button.types";
import MilestoneFormFooterCompleteButton from "@/modules/milestone-planner/components/milestone-form-footer/milestone-form-footer-complete-button.vue";
import MilestoneFormFooterSaveAsDraftButton from "@/modules/milestone-planner/components/milestone-form-footer/milestone-form-footer-save-as-draft-button.vue";
import MilestoneFormFooterDeleteButton from "@/modules/milestone-planner/components/milestone-form-footer/milestone-form-footer-delete-button.vue";

export default defineComponent({
  name: "MilestoneFormFooterComplete",

  components: {
    MilestoneFormFooterCompleteButton,
    MilestoneFormFooterSaveAsDraftButton,
    MilestoneFormFooterDeleteButton,
  },

  props: {
    userAddsEvidence: {
      type: Boolean,
      default: false,
    },
    hasCompletionInfo: {
      type: Boolean,
      default: false,
    },
    isFormPublished: {
      type: Boolean,
      default: false,
    },
    formHasChanges: {
      type: Boolean,
      default: false,
    },
    belowMilestonesNotCompleted: {
      type: Boolean,
      default: false,
    },
    aboveMilestonesCompleted: {
      type: Boolean,
      default: false,
    },
    formIsInvalid: {
      type: Boolean,
      default: false,
    },
  },

  static: {
    discardButton: {
      type: EPxButtonType.LINK,
    },
  },

  computed: {
    discardButtonProps(): any {
      return {
        ...this.$options.static.discardButton,
        label: this.$t("milestonePlanner.milestonePlan.buttons.discardChanges"),
      };
    },

    deleteButtonLabel(): any {
      return !this.isFormPublished
        ? this.$t("milestonePlanner.milestonePlan.buttons.deleteDraft")
        : this.$t("milestonePlanner.milestonePlan.buttons.deleteMilestone");
    },

    completeButtonLabel(): any {
      return this.isFormPublished
        ? this.$t("milestonePlanner.milestonePlan.buttons.saveMilestone")
        : this.$t("milestonePlanner.milestonePlan.buttons.completeMilestone");
    },

    isLoadingMilestones(): boolean {
      return this.$store.get("auth/milestones.loading");
    },

    saveAsDraftDisabled(): any {
      return (
        this.aboveMilestonesCompleted ||
        (!this.formHasChanges && !this.isFormPublished) ||
        (this.formHasChanges && this.formIsInvalid)
      );
    },

    completeDisabled(): boolean {
      return (
        this.belowMilestonesNotCompleted ||
        (!this.formHasChanges && !this.hasCompletionInfo) ||
        (!this.formHasChanges &&
          this.hasCompletionInfo &&
          this.isFormPublished) ||
        (this.formHasChanges && this.formIsInvalid)
      );
    },
  },
});
</script>

<style lang="scss" scoped>
.milestone-form-footer-complete {
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;
  justify-content: space-between;
  height: 100%;

  &__right {
    display: grid;
    grid-template-columns: auto auto auto;
    gap: 16px;

    .el-button {
      padding: 0;
    }
  }

  &__discard-button {
    position: relative;
    right: 1px;
    bottom: 1px;
  }

  &__evidence-tip {
    @include grotesk(semiBold);

    position: relative;
    bottom: 1px;
    max-width: 450px;
    padding-left: 24px;
    font-size: 14px;
    font-style: italic;
    line-height: 17px;
    color: $light-orange;
    letter-spacing: -0.02px;
  }

  &__evidence-tip-icon {
    position: absolute;
    bottom: 19px;
    left: -1px;
  }
}
</style>
