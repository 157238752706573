<template>
  <RenderlessEntrepreneurAssessment>
    <template #default="scope">
      <CategoryLevelsAssessmentMobile
        v-if="$screen.smDown"
        :current-category="scope.currentCategory"
        :on-level-change="scope.onLevelChange"
        :selected-step="scope.selectedStep"
      />
      <CategoryLevelsAssessmentDesktop
        v-else
        :current-category="scope.currentCategory"
        :is-first-interaction="scope.isFirstInteraction"
        :on-level-change="scope.onLevelChange"
        :selected-step="scope.selectedStep"
      />
    </template>
  </RenderlessEntrepreneurAssessment>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import RenderlessEntrepreneurAssessment from "@/modules/affiliates/components/renderless-entrepreneur-assessment/renderless-entrepreneur-assessment.vue";
import CategoryLevelsAssessmentMobile from "@/modules/affiliates/components/category-levels-assessment/category-levels-assessment-mobile.vue";
import CategoryLevelsAssessmentDesktop from "@/modules/affiliates/components/category-levels-assessment/category-levels-assessment-desktop.vue";

export default defineComponent({
  name: "CategoryLevelsAssessment",

  components: {
    RenderlessEntrepreneurAssessment,
    CategoryLevelsAssessmentMobile,
    CategoryLevelsAssessmentDesktop,
  },

  props: {
    isSelfAssessment: {
      type: Boolean,
      default: false,
    },
  },
});
</script>
