<template>
  <div class="supporter-offerings">
    <ElCollapse v-model="activeDrawer" accordion>
      <ElCollapseItem
        v-for="(offering, index) in offerings"
        :key="index"
        :disabled="!offering.description"
        :name="index"
      >
        <template #title>
          <div class="supporter-offerings__headline">
            <h3>
              {{ offering.category.name }}
            </h3>
            <div
              v-if="offering.types && offering.types.length"
              class="supporter-offerings__types"
            >
              <ElTag
                v-for="item in offering.types"
                :key="item.id"
                effect="plain"
              >
                {{ item.name }}
              </ElTag>
            </div>
          </div>
        </template>
        <p>
          {{ offering.description }}
        </p>
      </ElCollapseItem>
    </ElCollapse>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import { IOffering } from "@/services/data/offering/offering.interface";

export default defineComponent({
  name: "SupporterOfferings",

  props: {
    offerings: {
      type: Array as () => IOffering[],
      required: true,
    },
  },

  data() {
    return {
      activeDrawer: [0],
    };
  },
});
</script>

<style lang="scss" scoped>
.supporter-offerings :deep() {
  padding: 0 1px;

  @include breakpoint-up(md) {
    padding: 0 20px;
  }

  .el-collapse-item__header,
  .el-collapse-item__content {
    padding-right: 0;
    padding-left: 0;
    text-align: left;
  }

  .el-collapse-item__content {
    position: relative;
    top: -5px;
    padding-bottom: 12px;
  }
}

.supporter-offerings :deep(.el-collapse-item.is-disabled) {
  .el-collapse-item__header {
    pointer-events: none;

    &::before,
    &::after {
      display: none;
    }
  }
}

.supporter-offerings__headline {
  display: flex;
  flex-flow: column nowrap;
  padding: 10px 30px 10px 0;

  @include breakpoint-up(md) {
    flex-direction: row;
  }
}

.supporter-offerings__headline h3 {
  position: relative;
  top: 4px;

  @include breakpoint-up(md) {
    flex: 0 1 auto;
  }
}

.supporter-offerings__types {
  position: relative;
  margin-top: 7px;
  text-align: left;

  @include breakpoint-up(md) {
    top: -1px;
    flex: 1 1 50%;
    margin-top: 0;
    margin-left: 11px;
  }

  :deep(.el-tag) {
    @include grotesk(bold);

    margin: 4px auto;
    font-size: 11px;
    text-transform: uppercase;
    background-image: linear-gradient(to bottom, $apricot, $orangish);
    border: solid 0.9px $black-20;
    padding: 7px;

    @include breakpoint-up(md) {
      padding: 4px 8px;
      line-height: 13px;
    }

    &:not(:last-child) {
      margin-right: 10px;
    }
  }
}
</style>
