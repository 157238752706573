<template>
  <div class="milestone-form-guest-view">
    <div v-if="showPlannerInfo">
      <div v-if="isPlanTabActive" class="milestone-form-guest-view__plan">
        <div class="milestone-form-guest-view__plan-description">
          {{ $t("milestonePlanner.milestonePlan.planForm.description.guest") }}
        </div>
        <div
          v-for="(field, fieldLabel) in planForm"
          :key="fieldLabel"
          class="milestone-form-guest-view__item"
        >
          <h3 class="milestone-form-guest-view__label">
            {{
              $t(`milestonePlanner.milestonePlan.planForm.fields.${fieldLabel}`)
            }}
          </h3>
          <p class="milestone-form-guest-view__content">
            {{ field }}
          </p>
        </div>
      </div>
      <div v-else class="milestone-form-guest-view__complete">
        <div
          v-if="completeForm.evidence"
          class="milestone-form-guest-view__item"
          data-testid="complete-form-evidence-question"
        >
          <h3 class="milestone-form-guest-view__label">
            {{
              $t(`milestonePlanner.milestonePlan.completeForm.fields.evidence`)
            }}
          </h3>
          <ul>
            <li>
              <span data-testid="complete-form-evidence-question-text">
                {{ formEvidenceQuestion }}
              </span>
            </li>
          </ul>
          <p class="milestone-form-guest-view__content">
            {{ completeForm.evidence }}
          </p>
        </div>
        <div class="milestone-form-guest-view__item">
          <h3 class="milestone-form-guest-view__label">
            {{
              $t(
                `milestonePlanner.milestonePlan.completeForm.fields.dateOfCompletion`,
              )
            }}
          </h3>
          <p class="milestone-form-guest-view__content">
            {{ completeForm.dateOfCompletion }}
          </p>
        </div>
      </div>
    </div>
    <div v-else class="milestone-form-guest-view__empty">
      <img :src="emptyState.image" alt="milestone detail empty state" />
      <h3>
        {{ emptyState.title }}
      </h3>
      <p>
        {{ emptyState.description }}
      </p>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import {
  IMilestoneForm,
  IMilestone,
} from "@/modules/milestone-planner/services/data/milestones/milestone.interface";
import { IMatchingQuestion } from "@/services/data/matching-questionary/matching-question.interface";

import moment from "moment";
import mapKeys from "lodash/mapKeys";
import camelCase from "lodash/camelCase";
import pick from "lodash/pick";

export default defineComponent({
  name: "MilestoneFormGuestView",

  props: {
    milestone: {
      type: Object as () => IMilestone | null,
      default: null,
    },
    isMilestonePlanned: {
      type: Boolean,
      default: false,
    },
    isMilestoneComplete: {
      type: Boolean,
      default: false,
    },
    isPlanPublished: {
      type: Boolean,
      default: false,
    },
    isPlanTabActive: {
      type: Boolean,
      default: true,
    },
    questions: {
      type: Array as () => Array<IMatchingQuestion>,
      default: () => [],
    },
  },

  data() {
    return {
      planForm: {} as IMilestoneForm["plan"],
      completeForm: {} as any,
      planInitialState: Object.freeze({
        strategy: "",
        outcomes: "",
        resources: "",
        financesNeeded: "",
        targetDate: "",
      }) as IMilestoneForm["plan"],
      evidenceInitialState: Object.freeze({
        // TEMP: Evidence will need to be converted into an array of IMilestoneEvidence
        evidence: "",
        dateOfCompletion: null,
      }),
    };
  },

  computed: {
    showPlannerInfo(): boolean {
      return (
        (this.isPlanTabActive && this.isPlanPublished) ||
        (!this.isPlanTabActive && this.isMilestoneComplete)
      );
    },

    ownerName(): string {
      return this.$store.get("profile/company.data")?.name || "";
    },

    emptyState(): any {
      return this.isPlanTabActive
        ? this.emptyStatePlanTab
        : this.emptyStateCompleteTab;
    },

    emptyStateCompleteTab(): any {
      return {
        image: this.$t(
          `milestonePlanner.milestonePlan.completeView.emptyState.notCompleted.image`,
        ),
        title: this.$t(
          `milestonePlanner.milestonePlan.completeView.emptyState.notCompleted.title`,
        ),
        description: this.$t(
          `milestonePlanner.milestonePlan.completeView.emptyState.notCompleted.description`,
          {
            company: this.ownerName,
          },
        ),
      };
    },

    emptyStatePlanTab(): any {
      return !this.isMilestonePlanned
        ? {
            image: this.$t(
              `milestonePlanner.milestonePlan.planView.emptyState.noPlan.image`,
            ),
            title: this.$t(
              `milestonePlanner.milestonePlan.planView.emptyState.noPlan.title`,
            ),
            description: this.$t(
              `milestonePlanner.milestonePlan.planView.emptyState.noPlan.description`,
              {
                company: this.ownerName,
              },
            ),
          }
        : {
            image: this.$t(
              `milestonePlanner.milestonePlan.planView.emptyState.noDraftAccess.image`,
            ),
            title: this.$t(
              `milestonePlanner.milestonePlan.planView.emptyState.noDraftAccess.title`,
              {
                company: this.ownerName,
              },
            ),
            description: this.$t(
              `milestonePlanner.milestonePlan.planView.emptyState.noDraftAccess.description`,
              {
                company: this.ownerName,
              },
            ),
          };
    },

    /**
     * TEMP: Only show first question, while most common questions is pending specification.
     */
    evidenceQuestions(): Array<IMatchingQuestion> {
      return this.questions.length ? [this.questions[0]] : [];
    },

    formEvidenceQuestion(): string {
      return this.evidenceQuestions.length
        ? this.evidenceQuestions[0]?.entrepreneur_question
        : "";
    },
  },

  watch: {
    milestone: {
      deep: true,
      immediate: true,
      handler(newValue: IMilestone | null) {
        if (newValue) {
          const milestoneToFormValues = this.formatMilestoneValues(newValue);
          const prefillValues = mapKeys(milestoneToFormValues, (v, k) =>
            camelCase(k),
          );
          (this as any).planForm = pick(
            prefillValues,
            Object.keys(this.planInitialState),
          );
          (this as any).completeForm = pick(
            prefillValues,
            Object.keys(this.evidenceInitialState),
          );
        } else {
          this.planForm = { ...this.planInitialState };
          this.completeForm = { ...this.evidenceInitialState };
        }
      },
    },
  },

  methods: {
    formatMilestoneValues(milestone: IMilestone) {
      const targetDate = milestone.target_date
        ? moment(milestone.target_date).format("MMMM D, YYYY")
        : null;
      const financesNeeded = !isNaN(String(milestone.finances_needed) as any)
        ? `$ ${(
            parseInt(String(milestone.finances_needed)) as number
          ).toLocaleString()}`
        : "";
      const evidence = milestone.evidence[0]?.value?.text || "";
      const dateOfCompletion = milestone.date_of_completion
        ? moment(milestone.date_of_completion).format("MMMM D, YYYY")
        : null;

      return {
        ...milestone,
        targetDate,
        financesNeeded,
        evidence,
        dateOfCompletion,
      };
    },
  },
});
</script>

<style lang="scss" scoped>
.milestone-form-guest-view {
  position: relative;
  padding-right: 29px;
  padding-left: 32px;

  &__empty {
    margin-top: 121px;
    text-align: center;

    img {
      margin-bottom: 14px;
    }

    h3 {
      margin-bottom: 0;
      margin-left: 2px;
      font-size: 18px;
      line-height: 28px;
    }

    p {
      margin-top: 6px;
      margin-left: 2px;
      font-size: 15px;
      font-weight: 500;
      line-height: 25px;
      color: $manatee;
      letter-spacing: -0.3px;
    }
  }

  &__plan-description {
    margin-bottom: 44px;
    font-size: 14px;
    font-weight: normal;
    line-height: 20px;
    color: $manatee;
    letter-spacing: -0.3px;
  }

  &__item {
    min-height: 70px;
    border-bottom: 1px solid $athens-gray;
  }

  &__label {
    margin-bottom: 3px;
    font-size: 17px;
    font-weight: 600;
    line-height: 32px;
    color: $ebony-clay;
  }

  &__content {
    padding-bottom: 18px;
    font-size: 15px;
    line-height: 22px;
    color: $ebony-clay;
    letter-spacing: -0.32px;
  }

  &__plan {
    .milestone-form-guest-view__label {
      margin-top: 19px;
    }
  }

  &__complete {
    margin-top: 43px;

    .milestone-form-guest-view__item {
      max-width: 800px;
    }

    .milestone-form-guest-view__label {
      margin-top: 19px;
    }

    ul {
      padding: 0;
      margin: 6px 0 3px 15px;
    }

    span {
      @include grotesk(semiBold);

      position: relative;
      right: 3px;
      bottom: 2px;
      font-size: 14px;
      line-height: 20px;
      color: $ebony-clay;
      letter-spacing: -0.12px;
    }

    ::marker {
      font-size: 18px;
      color: $manatee;
    }
  }
}
</style>
