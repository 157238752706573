import { IBaseState } from "@/services/store/store-types";
import { IViralLevelSelection } from "@/services/data/viral-level/viral-level.interface";
import { IAffiliate } from "@/services/data/affiliate/affiliate.interface";
import { ILevel } from "@/services/data/level/level.interface";

/**
 * Represents the store structure for the level selection for each category.
 */
export interface ISupporterInvestingLevelState
  extends IBaseState,
    IViralLevelSelection {
  /**
   * Store the current selected category.
   */
  selectedCategory: number;

  /**
   * This is used to track if the user already interacted
   * with the component.
   */
  firstInteraction: boolean;

  /**
   * Stores the final assessment level.
   */
  finalLevel?: ILevel;

  /**
   * Stores the affiliate if the used is accessing from a
   * affiliate URL.
   */
  affiliate: IAffiliate | null;

  /**
   * Informs when the company data already was submitted.
   */
  submitted: boolean;

  /**
   * List of metadata specific for the assessment.
   */
  metadata: { [key: string]: any };
}

/**
 * Enumeration with all available module actions.
 */
export enum ESupporterInvestingLevelActions {
  RESET = "supporterInvestingLevel/reset",
  SET_LEVEL = "supporterInvestingLevel/setLevel",
  SET_SELECTED_CATEGORY = "supporterInvestingLevel/setCurrentCategory",
  INTERACTION = "supporterInvestingLevel/interaction",
  SET_LOADING = "supporterInvestingLevel/setLoading",
  COMPUTE_LEVEL = "supporterInvestingLevel/computeLevel",
  FETCH_AFFILIATE = "supporterInvestingLevel/setAffiliate",
  SET_AS_SUBMITTED = "supporterInvestingLevel/setAsSubmitted",
  FETCH_ASSESSMENT = "supporterInvestingLevel/fetchAssessment",
  SET_FINAL_LEVEL = "supporterInvestingLevel/setFinalLevel",

  SET_METADATA = "supporterInvestingLevel/setMetadata",
  REMOVE_METADATA = "supporterInvestingLevel/removeMetadata",
}

/**
 * Enumeration with all available module mutations.
 */
export enum ESupporterInvestingLevelMutations {
  SET_ERROR = "setError",
  SET_LOADING = "setLoading",
  SET_LEVELS = "setLevels",
  SET_LEVEL = "setLevel",
  SET_SELECTED_CATEGORY = "setCurrentCategory",
  SET_INTERACTION = "setInteraction",
  SET_FINAL_LEVEL = "setFinalLevel",
  SET_AFFILIATE = "setAffiliate",
  SET_SUBMITTED = "setSubmitted",
  SET_METADATA = "setMetadata",
  REMOVE_METADATA = "removeMetadata",
}

export enum ESupporterInvestingLevelGetters {
  SELECTED_STEP_DATA = "supporterInvestingLevel/selectedCategory",
  CURRENT_CATEGORY = "supporterInvestingLevel/currentCategory",
  SINGLE_METADATA = "supporterInvestingLevel/singleMetadata",
  IS_LOADING = "supporterInvestingLevel/isLoading",
}
