import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-7c0e6fd7"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "profile-panel__row assessment-grid" }
const _hoisted_2 = { class: "profile-headline" }
const _hoisted_3 = { class: "profile-headline__title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PxGrid = _resolveComponent("PxGrid")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h2", _hoisted_3, _toDisplayString(_ctx.$t("profile.assessment.gridPanel.title")), 1)
    ]),
    _createVNode(_component_PxGrid, {
      categories: _ctx.categories,
      "current-level": 0,
      inactive: true,
      levels: _ctx.numOfLevels,
      "random-results": _ctx.userCannotSeeResults,
      class: "assessment-grid__chart",
      size: "medium"
    }, null, 8, ["categories", "levels", "random-results"])
  ]))
}