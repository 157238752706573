import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createVNode as _createVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-0dd1c3ea"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "panel-grid__block" }
const _hoisted_2 = {
  key: 0,
  class: "profile-headline"
}
const _hoisted_3 = { class: "profile-headline__text-wrapper" }
const _hoisted_4 = { class: "profile-headline__title" }
const _hoisted_5 = {
  key: 0,
  class: "profile-headline"
}
const _hoisted_6 = { class: "profile-headline__text-wrapper" }
const _hoisted_7 = { class: "profile-headline__title" }
const _hoisted_8 = {
  key: 1,
  class: "profile-panel__visitor-wrapper"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PxButton = _resolveComponent("PxButton")!
  const _component_ContentPlaceholdersHeading = _resolveComponent("ContentPlaceholdersHeading")!
  const _component_ContentPlaceholders = _resolveComponent("ContentPlaceholders")!
  const _component_SupporterCriteriaList = _resolveComponent("SupporterCriteriaList")!
  const _component_PanelEmptyState = _resolveComponent("PanelEmptyState")!
  const _component_PxPanel = _resolveComponent("PxPanel")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_ctx.$screen.mdUp)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("h2", _hoisted_4, _toDisplayString(_ctx.$t("profile.supporter.criteria.title")), 1)
          ]),
          (!_ctx.isInactive && _ctx.criteria.length && _ctx.$user.isOwner())
            ? (_openBlock(), _createBlock(_component_PxButton, {
                key: 0,
                class: "profile-headline__update-btn",
                size: "small",
                onClick: _ctx.onClickEditCriteria
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t("profile.supporter.criteria.button")), 1)
                ]),
                _: 1
              }, 8, ["onClick"]))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    _createVNode(_component_PxPanel, {
      class: _normalizeClass([{ 'profile-panel--disabled': _ctx.isInactive }, "profile-panel"])
    }, {
      default: _withCtx(() => [
        (_ctx.$screen.mdUp)
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("h2", _hoisted_7, _toDisplayString(_ctx.$t("profile.supporter.criteria.title")), 1)
              ]),
              (!_ctx.isInactive && _ctx.criteria.length && _ctx.$user.isOwner())
                ? (_openBlock(), _createBlock(_component_PxButton, {
                    key: 0,
                    class: "profile-headline__update-btn",
                    size: "small",
                    onClick: _ctx.onClickEditCriteria
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t("profile.supporter.criteria.button")), 1)
                    ]),
                    _: 1
                  }, 8, ["onClick"]))
                : _createCommentVNode("", true)
            ]))
          : _createCommentVNode("", true),
        (_ctx.isInactive || _ctx.isSupporterLoading)
          ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
              _createVNode(_component_ContentPlaceholders, {
                animated: false,
                rounded: true
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ContentPlaceholdersHeading),
                  _createVNode(_component_ContentPlaceholdersHeading),
                  _createVNode(_component_ContentPlaceholdersHeading)
                ]),
                _: 1
              })
            ]))
          : (!_ctx.isInactive && _ctx.criteria.length)
            ? (_openBlock(), _createBlock(_component_SupporterCriteriaList, {
                key: 2,
                criteria: _ctx.criteria,
                class: "supporter-criteria-panel-list"
              }, null, 8, ["criteria"]))
            : (_openBlock(), _createBlock(_component_PanelEmptyState, {
                key: 3,
                icon: "what-we-look-for"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("p", null, _toDisplayString(_ctx.$t(
              `profile.supporter.criteria.emptyState.description[${+_ctx.$user.isOwner()}]`,
            )), 1),
                  (_ctx.$user.isOwner())
                    ? (_openBlock(), _createBlock(_component_PxButton, {
                        key: 0,
                        size: "medium",
                        type: "primary",
                        onClick: _ctx.onClickGetStarted
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t("profile.supporter.criteria.emptyState.button")), 1)
                        ]),
                        _: 1
                      }, 8, ["onClick"]))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }))
      ]),
      _: 1
    }, 8, ["class"])
  ]))
}