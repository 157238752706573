import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-2921fac0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "page-full-height submission-view" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SubmissionPanelEntrepreneur = _resolveComponent("SubmissionPanelEntrepreneur")!
  const _component_SubmissionPanelSupporter = _resolveComponent("SubmissionPanelSupporter")!
  const _component_ListManagementBar = _resolveComponent("ListManagementBar")!
  const _directive_loading = _resolveDirective("loading")!

  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_ctx.isLoading)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          (_ctx.isEntrepreneurSubmission)
            ? (_openBlock(), _createBlock(_component_SubmissionPanelEntrepreneur, {
                key: 0,
                submission: _ctx.submission,
                "submission-list": _ctx.submissionList,
                onShowListModal: _ctx.onShowListManagementModal,
                onOnSubmissionChange: _ctx.onSubmissionChange
              }, null, 8, ["submission", "submission-list", "onShowListModal", "onOnSubmissionChange"]))
            : (_openBlock(), _createBlock(_component_SubmissionPanelSupporter, {
                key: 1,
                submission: _ctx.submission,
                class: "submission-view__supporter",
                onShowListModal: _ctx.onShowListManagementModal
              }, null, 8, ["submission", "onShowListModal"])),
          _createVNode(_component_ListManagementBar)
        ], 64))
      : _createCommentVNode("", true)
  ])), [
    [_directive_loading, _ctx.isLoading]
  ])
}