import { ISupporterInvestingLevelState } from "./supporter-investing-level-types";

export const state: ISupporterInvestingLevelState = {
  levels: [],
  loading: false,
  error: null,
  selectedCategory: 0,
  firstInteraction: true,
  finalLevel: undefined,
  affiliate: null,
  submitted: false,
  metadata: {},
};
