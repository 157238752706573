import { IMatchingAnswer } from "./matching-answer.interface";

export const NUMERIC = "numeric";
export const RANGE = "range";
export const MULTI_SELECT = "multi-select";
export const SINGLE_SELECT = "single-select";
export const FREE_RESPONSE = "free-response";
export const DATE = "date";

export interface IMatchingQuestionType {
  type:
    | "numeric"
    | "range"
    | "multi-select"
    | "single-select"
    | "free-response"
    | "date";
  meta: any;
}

export interface IMatchingQuestionCategory {
  id: number;
  name: string;
  description: string;
}

export interface IMatchingQuestion {
  id: number;
  short_name?: string;
  is_common?: boolean;
  entrepreneur_question: string;
  resource_question: string;
  ttl: string;
  profile_field: number | null;
  question_type: IMatchingQuestionType;
  question_category: IMatchingQuestionCategory;
  answers: Array<IMatchingAnswer>;
  is_team_member_question: boolean;
  instructions: string | null;
}
