<template>
  <div class="milestone-details">
    <ElCollapse accordion>
      <ElCollapseItem
        v-for="(milestone, index) in milestones"
        :key="index"
        :disabled="!milestone.description"
        :name="index"
      >
        <template #title>
          <div class="milestone-details__headline">
            <span
              class="milestone-details__headline-title"
              v-html="milestone.title"
            />
            <PxLabel
              :color="`#${milestone.color}`"
              :text="`${milestone.category}: ${milestone.level}`"
            />
          </div>
        </template>
        <div v-html="milestone.description" />
      </ElCollapseItem>
    </ElCollapse>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import PxLabel from "@/components/px-label/px-label.vue";

export interface IMilestoneItem {
  title: string;
  description: string;
  level: number;
  category: string;
  color: string;
}

export default defineComponent({
  name: "MilestoneDetails",

  components: {
    PxLabel,
  },

  props: {
    milestones: {
      type: Array as () => IMilestoneItem[],
      required: true,
    },
  },
});
</script>

<style lang="scss" scoped>
.milestone-details {
  padding: 6px 14px 7px;

  &__headline {
    padding: 17px 0;
    text-align: left;

    &-title,
    :deep() span > p {
      font-size: 14px;
      line-height: 20px;
      color: $ebony-clay;
    }

    &-title :deep() * {
      display: inline;
      padding: 0;
      margin: 0;
    }
  }

  .el-collapse-item:last-of-type :deep() .px-milestones__headline {
    padding: 20px 0 32px 0;
  }

  :deep() .el-collapse-item__content p {
    font-size: 14px;
    line-height: 20px;
    color: $manatee;
  }

  :deep() .el-collapse-item div[role="tab"]::before {
    top: 17px;
    right: 2px;
  }

  :deep() .el-collapse-item__header {
    min-height: 0;

    &::before {
      top: 27px;
      right: 8px;
    }

    &::after {
      top: 23px;
      right: 12px;
    }
  }

  :deep() .px-label {
    text-transform: uppercase;
  }
}
</style>
