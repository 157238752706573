<template>
  <div class="affiliate-footer-bar">
    <div class="affiliate-footer-bar__container">
      <div class="affiliate-footer-bar__left-container">
        <PxButton
          v-if="shouldShowElements"
          v-bind="saveAsDraftButtonProps"
          :loading="isSaving"
          class="affiliate-footer-bar__draft-button"
          @click="saveDraft"
        />
        <p
          v-if="date && $screen.mdUp && shouldShowElements"
          class="affiliate-footer-bar__notification"
        >
          {{
            $t("affiliateProgram.footerBar.update", {
              date,
            })
          }}
        </p>
      </div>
      <div class="affiliate-footer-bar__right-container">
        <PxButton
          v-bind="previousButtonProps"
          :loading="isSaving"
          class="affiliate-footer-bar__previous-button"
          @click="goToPreviousStep"
        />
        <PxButton
          v-bind="nextButtonProps"
          :disabled="submitDisabled && questionBundleAffiliate && isLastPage"
          :loading="isSaving"
          class="affiliate-footer-bar__next-button"
          @click="goToNextStep"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import {
  EPxButtonSize,
  EPxButtonVariant,
  EPxButtonType,
} from "@/components/px-button/px-button.types";
import {
  EAffiliatesActions,
  EAffiliatesMutations,
} from "@/modules/affiliates/services/store/affiliates/affiliates.types";

export default defineComponent({
  name: "AffiliateFooterBar",

  static: {
    saveAsDraftButton: {
      size: EPxButtonSize.MEDIUM,
      variant: EPxButtonVariant.FLUID,
    },

    previousButton: {
      type: EPxButtonType.LINK,
    },

    nextButton: {
      size: EPxButtonSize.MEDIUM,
    },
  },

  props: {
    isLastPage: {
      type: Boolean,
      default: false,
    },

    date: {
      type: String,
      default: "",
    },

    isSaving: {
      type: Boolean,
      default: false,
    },

    submitDisabled: {
      type: Boolean,
      default: false,
    },

    questionBundleAffiliate: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    saveAsDraftButtonProps(): any {
      return {
        ...this.$options.static.saveAsDraftButton,
        label: this.$t("common.saveAsDraft"),
      };
    },

    previousButtonProps(): any {
      return {
        ...this.$options.static.previousButton,
        label: this.$t("common.previous"),
      };
    },

    nextButtonProps(): any {
      return {
        ...this.$options.static.nextButton,
        type:
          this.isLastPage && this.questionBundleAffiliate
            ? EPxButtonType.GREEN
            : EPxButtonType.BLUE,
        label: this.isLastPage
          ? this.$t("common.submit")
          : this.$t("common.next"),
      };
    },

    shouldShowElements(): boolean {
      return (
        (this.questionBundleAffiliate && !this.isLastPage) ||
        !this.questionBundleAffiliate
      );
    },
  },

  methods: {
    async saveDraft() {
      this.$store.commit(`affiliates/${EAffiliatesMutations.SET_SAVING}`, true);
      await this.$store.dispatch(EAffiliatesActions.DEBOUNCE_SAVE_DRAFT);
      this.$emit("show-saved-draft-message");
    },

    goToPreviousStep() {
      this.$emit("previous-step");
    },

    goToNextStep() {
      this.$emit("next-step");
    },
  },
});
</script>

<style lang="scss" scoped>
.affiliate-footer-bar {
  height: 80px;
  width: 100%;

  z-index: z("navigation") + 1;
  background: $white;
  box-shadow:
    0 -1px 0 0 #dcdde24c,
    0 -6px 6px 0 #00000006;

  &__container {
    @include make-container-max-widths();

    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 1140px;
    height: inherit;
    margin: 0 auto;

    @include breakpoint-down(md) {
      padding: 0 20px;
    }
  }

  &__left-container {
    display: flex;
    align-items: center;
    gap: 12px;
  }

  &__draft-button {
    padding: 0 15px;
  }

  &__notification {
    @include grotesk(regular, italic);

    margin: 0;
    color: $manatee;
    font-size: 13px;
    line-height: 1.38;
  }

  &__right-container {
    display: flex;
    gap: 14px;
  }

  &__next-button {
    min-width: 66px;
  }

  &__previous-button :deep() span {
    font-size: 14px;
  }
}
</style>
