import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, mergeProps as _mergeProps, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-a9478578"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["textContent"]
const _hoisted_2 = ["textContent"]
const _hoisted_3 = ["textContent"]
const _hoisted_4 = ["textContent"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_i18n_t = _resolveComponent("i18n-t")!
  const _component_PxButton = _resolveComponent("PxButton")!
  const _component_PxIcon = _resolveComponent("PxIcon")!
  const _component_MilestoneStageStatusItem = _resolveComponent("MilestoneStageStatusItem")!

  return (_openBlock(), _createElementBlock("ul", {
    class: _normalizeClass([`milestone-plan-onboarding-dialog__content--second-page-${_ctx.userType}`, "milestone-plan-onboarding-dialog__content--second-page"])
  }, [
    (!_ctx.isVisitor)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _createElementVNode("li", null, [
            _createVNode(_component_i18n_t, {
              keypath: `milestonePlanner.milestonePlan.onboardingModal.${_ctx.userType}.content.secondTab.0`,
              tag: "p"
            }, {
              completeTab: _withCtx(() => [
                _createElementVNode("strong", {
                  textContent: _toDisplayString(_ctx.onboardingCopy.completeTab)
                }, null, 8, _hoisted_1)
              ]),
              _: 1
            }, 8, ["keypath"])
          ]),
          _createElementVNode("li", null, [
            _createVNode(_component_i18n_t, {
              keypath: `milestonePlanner.milestonePlan.onboardingModal.${_ctx.userType}.content.secondTab.1`,
              tag: "p"
            }, {
              firstIcon: _withCtx(() => [
                _createVNode(_component_PxButton, _normalizeProps(_guardReactiveProps(_ctx.completeMilestoneButtonProps)), null, 16)
              ]),
              secondIcon: _withCtx(() => [
                _createVNode(_component_PxIcon, {
                  size: 16,
                  name: "self-assessed"
                })
              ]),
              breakText: _withCtx(() => [
                _createVNode(_component_i18n_t, {
                  keypath: `milestonePlanner.milestonePlan.onboardingModal.${_ctx.userType}.content.secondTab.2`,
                  class: "break-text",
                  tag: "p"
                }, {
                  stronglyRecomend: _withCtx(() => [
                    _createElementVNode("strong", {
                      textContent: _toDisplayString(_ctx.onboardingCopy.stronglyRecomend)
                    }, null, 8, _hoisted_2)
                  ]),
                  firstIcon: _withCtx(() => [
                    _createVNode(_component_PxIcon, {
                      size: 17,
                      class: "evidence-provided",
                      name: "evidence-provided"
                    })
                  ]),
                  _: 1
                }, 8, ["keypath"])
              ]),
              _: 1
            }, 8, ["keypath"])
          ]),
          _createElementVNode("li", null, [
            _createVNode(_component_i18n_t, {
              keypath: `milestonePlanner.milestonePlan.onboardingModal.${_ctx.userType}.content.secondTab.3`,
              tag: "p"
            }, {
              firstIcon: _withCtx(() => [
                _createVNode(_component_PxButton, _normalizeProps(_guardReactiveProps(_ctx.saveAsDraftButtonProps)), null, 16)
              ]),
              breakText: _withCtx(() => [
                _createVNode(_component_i18n_t, {
                  keypath: `milestonePlanner.milestonePlan.onboardingModal.${_ctx.userType}.content.secondTab.4`,
                  class: "break-text",
                  tag: "p"
                }, {
                  firstIcon: _withCtx(() => [
                    _createVNode(_component_MilestoneStageStatusItem, _mergeProps(_ctx.inProgressIconProps, { "is-clickable": false }), null, 16)
                  ]),
                  _: 1
                }, 8, ["keypath"])
              ]),
              _: 1
            }, 8, ["keypath"])
          ]),
          _createElementVNode("li", null, [
            _createVNode(_component_i18n_t, {
              keypath: `milestonePlanner.milestonePlan.onboardingModal.${_ctx.userType}.content.secondTab.5`,
              tag: "p"
            }, {
              firstIcon: _withCtx(() => [
                _createVNode(_component_PxButton, _normalizeProps(_guardReactiveProps(_ctx.completeMilestoneButtonProps)), null, 16)
              ]),
              secondIcon: _withCtx(() => [
                _createVNode(_component_MilestoneStageStatusItem, _mergeProps(_ctx.completedIconProps, { "is-clickable": false }), null, 16)
              ]),
              breakText: _withCtx(() => [
                _createVNode(_component_i18n_t, {
                  keypath: `milestonePlanner.milestonePlan.onboardingModal.${_ctx.userType}.content.secondTab.6`,
                  class: "break-text",
                  tag: "p"
                }, {
                  firstIcon: _withCtx(() => [
                    _createVNode(_component_PxIcon, {
                      size: 16,
                      class: "milestone-plan-onboarding-dialog__content--filtered-icon",
                      name: "completed-milestone--grey"
                    })
                  ]),
                  _: 1
                }, 8, ["keypath"])
              ]),
              _: 1
            }, 8, ["keypath"])
          ])
        ], 64))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          _createElementVNode("li", null, [
            _createVNode(_component_i18n_t, {
              keypath: `milestonePlanner.milestonePlan.onboardingModal.${_ctx.userType}.content.secondTab.0`,
              tag: "p"
            }, {
              completeTab: _withCtx(() => [
                _createElementVNode("strong", {
                  textContent: _toDisplayString(_ctx.onboardingCopy.completeTab)
                }, null, 8, _hoisted_3)
              ]),
              _: 1
            }, 8, ["keypath"])
          ]),
          _createElementVNode("li", null, [
            _createVNode(_component_i18n_t, {
              keypath: `milestonePlanner.milestonePlan.onboardingModal.${_ctx.userType}.content.secondTab.1`,
              tag: "p"
            }, {
              selfAssessedIcon: _withCtx(() => [
                _createVNode(_component_PxIcon, {
                  size: 16,
                  name: "self-assessed"
                })
              ]),
              breakText: _withCtx(() => [
                _createVNode(_component_i18n_t, {
                  keypath: `milestonePlanner.milestonePlan.onboardingModal.${_ctx.userType}.content.secondTab.2`,
                  class: "break-text",
                  tag: "p"
                }, {
                  stronglyRecomend: _withCtx(() => [
                    _createElementVNode("strong", {
                      textContent: _toDisplayString(_ctx.onboardingCopy.stronglyRecomend)
                    }, null, 8, _hoisted_4)
                  ]),
                  evidenceIcon: _withCtx(() => [
                    _createVNode(_component_PxIcon, {
                      size: 17,
                      class: "evidence-provided",
                      name: "evidence-provided"
                    })
                  ]),
                  _: 1
                }, 8, ["keypath"])
              ]),
              _: 1
            }, 8, ["keypath"])
          ]),
          _createElementVNode("li", null, [
            _createVNode(_component_i18n_t, {
              keypath: `milestonePlanner.milestonePlan.onboardingModal.${_ctx.userType}.content.secondTab.3`,
              tag: "p"
            }, {
              breakText: _withCtx(() => [
                _createVNode(_component_i18n_t, {
                  keypath: `milestonePlanner.milestonePlan.onboardingModal.${_ctx.userType}.content.secondTab.4`,
                  tag: "p"
                }, {
                  inProgressIcon: _withCtx(() => [
                    _createVNode(_component_MilestoneStageStatusItem, _mergeProps(_ctx.inProgressIconProps, { "is-clickable": false }), null, 16)
                  ]),
                  _: 1
                }, 8, ["keypath"])
              ]),
              _: 1
            }, 8, ["keypath"])
          ]),
          _createElementVNode("li", null, [
            _createVNode(_component_i18n_t, {
              keypath: `milestonePlanner.milestonePlan.onboardingModal.${_ctx.userType}.content.secondTab.5`,
              tag: "p"
            }, {
              completedIcon: _withCtx(() => [
                _createVNode(_component_MilestoneStageStatusItem, _mergeProps(_ctx.completedIconProps, { "is-clickable": false }), null, 16)
              ]),
              achivedIcon: _withCtx(() => [
                _createVNode(_component_PxIcon, {
                  size: 16,
                  class: "milestone-plan-onboarding-dialog__content--filtered-icon",
                  name: "completed-milestone--grey"
                })
              ]),
              _: 1
            }, 8, ["keypath"])
          ])
        ], 64))
  ], 2))
}