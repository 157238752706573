import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-0873f6d5"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "form-presentation-layout__wrapper" }
const _hoisted_2 = { class: "form-presentation-layout__content-header" }
const _hoisted_3 = { class: "form-presentation-layout__content-title" }
const _hoisted_4 = { class: "form-presentation-layout__content-description" }
const _hoisted_5 = { class: "form-presentation-layout__content-body" }
const _hoisted_6 = { class: "form-presentation-layout__description-container" }
const _hoisted_7 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PxIcon = _resolveComponent("PxIcon")!
  const _component_PxButton = _resolveComponent("PxButton")!
  const _component_PxResponsiveBitmap = _resolveComponent("PxResponsiveBitmap")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass([{
        'form-presentation-layout__container--bkg-reversed':
          _ctx.hasReversedBackgrounds,
      }, "form-presentation-layout__container"])
    }, [
      _createElementVNode("div", {
        class: _normalizeClass([{
          'form-presentation-layout__content-wrapper--with-return':
            _ctx.hasReturnButton,
        }, "form-presentation-layout__content-wrapper"])
      }, [
        _createElementVNode("div", {
          class: _normalizeClass([{
            'form-presentation-layout__content-container--top': _ctx.isTopAlignment,
          }, "form-presentation-layout__content-container"])
        }, [
          _createElementVNode("div", {
            class: _normalizeClass([{
              'form-presentation-layout__content-header-wrapper--sticky':
                _ctx.showStickyHeader,
            }, "form-presentation-layout__content-header-wrapper"])
          }, [
            (_ctx.hasReturnButton)
              ? (_openBlock(), _createBlock(_component_PxButton, {
                  key: 0,
                  circle: "",
                  class: "form-presentation-layout__return-button",
                  onClick: _ctx.onReturnButtonClick
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_PxIcon, {
                      name: "arrow--left-dark",
                      size: "14"
                    })
                  ]),
                  _: 1
                }, 8, ["onClick"]))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_2, [
              _renderSlot(_ctx.$slots, "header", {}, () => [
                _createElementVNode("h1", _hoisted_3, _toDisplayString(_ctx.contentTitle), 1),
                _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.contentDescription), 1)
              ], true)
            ])
          ], 2),
          _createElementVNode("div", _hoisted_5, [
            _renderSlot(_ctx.$slots, "content", {}, undefined, true)
          ])
        ], 2)
      ], 2),
      _createElementVNode("div", {
        class: _normalizeClass([{
          'form-presentation-layout__description-wrapper--with-background':
            _ctx.hasDescriptionBackgroundImage,
        }, "form-presentation-layout__description-wrapper"])
      }, [
        _createElementVNode("div", _hoisted_6, [
          _createVNode(_component_PxResponsiveBitmap, {
            alt: _ctx.descriptionImageTitle,
            link: _ctx.descriptionImageLink,
            class: "form-presentation-layout__description-image"
          }, null, 8, ["alt", "link"]),
          (_ctx.descriptionText)
            ? (_openBlock(), _createElementBlock("span", {
                key: 0,
                class: "form-presentation-layout__description-text",
                innerHTML: _ctx.descriptionText
              }, null, 8, _hoisted_7))
            : _createCommentVNode("", true)
        ])
      ], 2)
    ], 2)
  ]))
}