<template>
  <div class="features-showcase-cta">
    <h3 class="features-showcase-cta__teaser">
      {{ $t("supporters.results.featuresShowcase.cta.teaser") }}
    </h3>
    <PxButton
      class="features-showcase-cta__button"
      size="large"
      type="green"
      @click="onClickSignUp"
    >
      {{ $t("supporters.results.featuresShowcase.cta.button") }}
    </PxButton>
    <h4 class="features-showcase-cta__description">
      {{ $t("supporters.results.featuresShowcase.cta.description") }}
    </h4>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "FeaturesShowcaseCTA",

  methods: {
    onClickSignUp() {
      // No data to submit hence why we're sending null.
      this.$emitter.emit("next-page", null);
    },
  },
});
</script>

<style lang="scss" scoped>
.features-showcase-cta {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  padding: 15px;

  color: white;
  background-image: linear-gradient(to bottom, #464673, $ebony-clay);
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;

  @include breakpoint-up(md) {
    padding: 20px;
  }
}

.features-showcase-cta__teaser {
  @include grotesk(semiBold);

  margin: 0;
  font-size: to-rem(18px);
  letter-spacing: 0;

  @include breakpoint-up(md) {
    @include grotesk(bold);

    font-size: to-rem(20px);
  }
}

.features-showcase-cta__description {
  @include grotesk(regular);

  display: none;
  margin: 0;
  font-size: to-rem(20px);

  @include breakpoint-up(md) {
    display: block;
  }
}

.features-showcase-cta__button {
  min-width: 91px;
  height: 42px;
  margin-left: 10px;

  @include breakpoint-up(md) {
    height: 44px;
    margin: 0 10px 0 19px;
  }

  :deep() span {
    font-size: to-rem(14px);

    @include breakpoint-up(md) {
      font-size: to-rem(16px);
    }
  }
}
</style>
