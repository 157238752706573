import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createBlock as _createBlock, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, toDisplayString as _toDisplayString, createVNode as _createVNode, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-d084715e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "mobile-level-select__wrapper" }
const _hoisted_2 = { class: "mobile-level-select__container" }
const _hoisted_3 = {
  key: 0,
  class: "mobile-level-select__info-wrapper"
}
const _hoisted_4 = { class: "mobile-level-select__behavior" }
const _hoisted_5 = { class: "mobile-assessment-skip-tip" }
const _hoisted_6 = { class: "mobile-assessment-skip-tip__text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LevelCard = _resolveComponent("LevelCard")!
  const _component_PxIcon = _resolveComponent("PxIcon")!
  const _component_CriteriaModal = _resolveComponent("CriteriaModal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      style: _normalizeStyle(_ctx.elementStyles),
      class: "mobile-level-select"
    }, [
      _createElementVNode("div", _hoisted_2, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sortedDetails, (details) => {
          return (_openBlock(), _createBlock(_component_LevelCard, {
            key: details.id,
            details: details,
            "category-color": _ctx.currentCategory.color,
            value: details.level.value <= _ctx.currentLevel,
            class: _normalizeClass([{
            'is-visible': details.level.value <= _ctx.currentLevel + 1,
          }, "mobile-level-select__card"]),
            onChangeLevel: 
            (value) => _ctx.onCardSelectChange(details.level.value, value)
          ,
            onSeeCriteria: ($event: any) => (_ctx.onSeeCriteria(details))
          }, null, 8, ["details", "category-color", "value", "class", "onChangeLevel", "onSeeCriteria"]))
        }), 128))
      ])
    ], 4),
    (_ctx.currentLevel === 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t("selfAssessment.mobileAssessment.info.behavior")), 1),
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_PxIcon, {
              size: 14,
              name: "information--light-gray"
            }),
            _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.$t("selfAssessment.mobileAssessment.info.skipTip")), 1)
          ])
        ]))
      : _createCommentVNode("", true),
    _createVNode(_component_CriteriaModal, {
      visibility: _ctx.criteriaModalVisibility,
      "onUpdate:visibility": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.criteriaModalVisibility) = $event)),
      "category-level": _ctx.selectedCategoryLevel
    }, null, 8, ["visibility", "category-level"])
  ]))
}