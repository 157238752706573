import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, withModifiers as _withModifiers, mergeProps as _mergeProps, createBlock as _createBlock, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-49a6a13c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "collapsible-question__wrapper" }
const _hoisted_2 = {
  key: 0,
  class: "collapsible-question__success-icon"
}
const _hoisted_3 = { class: "collapsible-question__text" }
const _hoisted_4 = ["title"]
const _hoisted_5 = {
  key: 0,
  class: "collapsible-question__info"
}
const _hoisted_6 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PxIcon = _resolveComponent("PxIcon")!
  const _component_PxButton = _resolveComponent("PxButton")!
  const _component_QuestionPanel = _resolveComponent("QuestionPanel")!
  const _component_ElCollapseItem = _resolveComponent("ElCollapseItem")!

  return (_openBlock(), _createBlock(_component_ElCollapseItem, {
    class: _normalizeClass([{
      'collapsible-question--error': !_ctx.hasValidResponse,
      'collapsible-question--success': _ctx.successState,
    }, "collapsible-question"]),
    name: _ctx.question.id
  }, {
    title: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (_ctx.successState)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createVNode(_component_PxIcon, {
                size: 24,
                name: "answered-icon"
              })
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("h4", null, _toDisplayString(_ctx.getQuestionTitle(_ctx.question)), 1),
          _createElementVNode("p", {
            class: _normalizeClass([{
              'collapsible-question__answer--italic': !_ctx.responseFormattedValue,
            }, "collapsible-question__answer"]),
            title: _ctx.responseToDisplay
          }, _toDisplayString(_ctx.responseToDisplay), 11, _hoisted_4)
        ]),
        (_ctx.question.instructions)
          ? (_openBlock(), _createBlock(_component_PxButton, _mergeProps({
              key: 1,
              class: "collapsible-question__action"
            }, _ctx.learnMoreButtonProps, {
              onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.$emit('show-instructions-modal', _ctx.question)), ["stop"]))
            }), null, 16))
          : _createCommentVNode("", true)
      ])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass([{
          'collapsible-question__options--grouped': _ctx.groupedOptions,
          'collapsible-question__options--ungrouped': !_ctx.groupedOptions,
        }, "collapsible-question__options"])
      }, [
        (_ctx.question)
          ? (_openBlock(), _createBlock(_component_QuestionPanel, {
              key: 0,
              modelValue: _ctx.currentResponse,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.currentResponse) = $event)),
              "fields-are-required": false,
              question: _ctx.question,
              "show-only-question-content": true,
              onResponseFormatted: _ctx.handleFormattedResponse,
              onValidate: _ctx.checkValidState
            }, null, 8, ["modelValue", "question", "onResponseFormatted", "onValidate"]))
          : _createCommentVNode("", true)
      ], 2),
      (_ctx.isMultiSelectQuestion)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createVNode(_component_PxIcon, {
              size: 16,
              name: "icon-information"
            }),
            _createElementVNode("p", {
              innerHTML: _ctx.$t('common.multiSelectInfo')
            }, null, 8, _hoisted_6)
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["class", "name"]))
}