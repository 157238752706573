<template>
  <div class="matching-results-loading">
    <div class="matching-results-loading--cards">
      <slot name="body" />
    </div>
    <slot name="action" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "MatchingResultsLoading",
});
</script>

<style lang="scss">
.matching-results-loading--cards {
  position: relative;
  top: 0;
  left: 0;
  z-index: z("floaters");
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: auto;
  grid-row-gap: 50px;
  grid-column-gap: 22px;
  justify-content: center;
  justify-items: center;

  @include breakpoint-up(md) {
    grid-template-columns: repeat(2, auto);
    grid-row-gap: 40px;
    justify-content: left;
  }

  @include breakpoint-up(lg) {
    grid-template-columns: repeat(3, auto);
  }

  @include breakpoint-up(xl) {
    grid-template-columns: repeat(4, auto);
  }
}

.matching-results-loading--info {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: z("floaters") + 1;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 455px;
  height: 114px;
  text-align: center;

  background-color: $white;
  border-radius: 4px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.12);

  transform: translate(-50%, -50%);

  h4 {
    font-size: 17px;
    color: $ebony-clay;
  }

  p {
    position: relative;
    top: 6px;
    left: 1px;
    font-size: 15px;
    font-weight: 500;
    line-height: 22px;
    color: $spun-pearl;
    letter-spacing: -0.2px;
  }

  @include breakpoint-down(md) {
    position: fixed;
    top: 52%;
    width: 100%;
    height: 100%;
    padding: 0 50px;
    background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0) 10%,
      $white 42%
    );

    h4 {
      position: relative;
      top: 6px;
      font-size: 16px;
      line-height: 19px;
    }

    p {
      top: 8px;
      left: 0;
      color: rgba($manatee, 0.7);
    }
  }
}
</style>
