import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "level-update-modal-levels-info" }
const _hoisted_2 = { class: "level-update-modal-levels-info__title" }
const _hoisted_3 = { class: "level-update-modal-levels-info__wrapper" }
const _hoisted_4 = { class: "level-update-modal-levels-info__main-content" }
const _hoisted_5 = {
  key: 0,
  class: "level-update-modal-levels-info__cards-wrapper"
}
const _hoisted_6 = { class: "level-update-modal-levels-info__footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PxModalMessage = _resolveComponent("PxModalMessage")!
  const _component_LevelUpdateCard = _resolveComponent("LevelUpdateCard")!
  const _component_LevelUpdateModalNote = _resolveComponent("LevelUpdateModalNote")!
  const _component_LevelUpdateModalFooter = _resolveComponent("LevelUpdateModalFooter")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_PxModalMessage, { visible: _ctx.hasServerError }, null, 8, ["visible"]),
    _createElementVNode("div", null, [
      _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.modalTitle), 1)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        (_ctx.currentLevel && _ctx.newLevel)
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
              _createVNode(_component_LevelUpdateCard, {
                "current-assessment": _ctx.currentAssessment,
                level: _ctx.currentLevel.value,
                "level-description": _ctx.currentLevel.title,
                "only-prev-assessment": true,
                "prev-assessment": _ctx.formattedPrevAssessment,
                "type-of-update": _ctx.typeOfUpdate,
                class: "level-update-modal-levels-info__current-card"
              }, null, 8, ["current-assessment", "level", "level-description", "prev-assessment", "type-of-update"]),
              _createVNode(_component_LevelUpdateCard, {
                "current-assessment": _ctx.currentAssessment,
                level: _ctx.newLevel.value,
                "level-description": _ctx.newLevel.title,
                "prev-assessment": _ctx.formattedPrevAssessment,
                "type-of-update": _ctx.typeOfUpdate,
                class: "level-update-modal-levels-info__new-card",
                type: "blue"
              }, null, 8, ["current-assessment", "level", "level-description", "prev-assessment", "type-of-update"])
            ]))
          : _createCommentVNode("", true)
      ]),
      _createVNode(_component_LevelUpdateModalNote, { loading: _ctx.isLoading }, null, 8, ["loading"])
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createVNode(_component_LevelUpdateModalFooter, {
        "has-error": _ctx.hasServerError,
        "onUpdate:hasError": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.hasServerError) = $event)),
        loading: _ctx.isLoading,
        "onUpdate:loading": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.isLoading) = $event)),
        "current-assessment": _ctx.currentAssessment,
        "current-level": _ctx.currentLevel.value,
        "new-level": _ctx.newLevel.value,
        "same-level": _ctx.isSameLevel,
        onCloseModal: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('close-modal')))
      }, null, 8, ["has-error", "loading", "current-assessment", "current-level", "new-level", "same-level"])
    ])
  ]))
}