export const CHARTS_BACKGROUND_COLORS = [
  "rgba(79, 143, 247, 1)",
  "rgba(115, 118, 242, 1)",
  "rgba(206, 171, 255, 1)",
  "rgba(164, 122, 240, 1)",
  "rgba(238, 91, 163, 1)",
  "rgba(230, 110, 121, 1)",
  "rgba(253, 163, 86, 1)",
  "rgba(255, 210, 77, 1)",
  "rgba(198, 207, 72, 1)",
  "rgba(121, 163, 144, 1)",
  "rgba(44, 191, 175, 1)",
  "rgba(81, 217, 244, 1)",
  "rgba(140, 174, 227, 1)",
  "rgba(111, 133, 165, 1)",
];
