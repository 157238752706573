<template>
  <div
    :class="{ 'company-lists-empty--with-slot': hasPopulatedSlot }"
    class="company-lists-empty"
  >
    <div class="company-lists-empty__wrapper">
      <div
        :class="[`company-lists-empty__icon--${icon}`]"
        class="company-lists-empty__icon"
      >
        <img :src="`/img/icons/${icon}.svg`" alt />
      </div>
      <h4 class="company-lists-empty__title">
        {{ title }}
      </h4>
      <p class="company-lists-empty__subtitle">
        {{ subtitle }}
      </p>
      <PxButton
        v-if="canCreateLists"
        class="company-lists-empty__main-cta"
        size="medium"
        type="green"
        @click="newListClickHandler"
      >
        <PxIcon :size="16" name="plus-white" />
        {{ $t("companyLists.directory.createListButton") }}
      </PxButton>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { EListManagementModalTab } from "@/modules/company-lists/components/list-management/list-management.types";

export default defineComponent({
  props: {
    title: {
      type: String,
      required: true,
    },

    subtitle: {
      type: String,
      required: true,
    },

    icon: {
      type: String,
      required: true,
    },

    canCreateLists: {
      type: Boolean,
      default: false,
    },

    customAction: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    hasPopulatedSlot(): boolean {
      return !!this.$slots.default;
    },
  },

  methods: {
    newListClickHandler() {
      if (this.customAction) {
        this.$emit("new-list-click");
        return;
      }

      // Open New List Modal
      this.$emitter.emit("show-list-management-modal", {
        tab: EListManagementModalTab.CREATE,
      });
    },
  },
});
</script>

<style lang="scss" scoped>
.company-lists-empty {
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  justify-content: center;
  margin-top: 120px;
}

.company-lists-empty__wrapper {
  // For pixel-perfect alignment:
  height: 154px;
  text-align: center;
}

.company-lists-empty__icon--lists-empty-state :deep() img {
  height: 93px;
}

.company-lists-empty__subtitle {
  @include grotesk(medium);

  margin-top: 3px;
  margin-bottom: 21px;

  font-size: 15px;
  color: $manatee;
  letter-spacing: -0.2px;
}

.company-lists-empty__main-cta {
  position: relative;
  left: 1px;
  padding: 0 15px;

  :deep() span {
    font-size: 13px;
  }

  .px-icon {
    margin-right: 6px;
  }
}
</style>
