<template>
  <div
    v-loading="isLoading"
    :style="cardStyles"
    class="px-card"
    element-loading-background="#fafafa"
  >
    <PxIcon v-if="icon" :name="icon" :size="iconSize" />
    <div class="px-card__content">
      <div
        :class="{ 'px-card__title-action': action }"
        class="px-card__title"
        v-on="cardAction"
      >
        <h1 :style="{ color: titleColor }">
          {{ title }}
        </h1>
        <PxIcon v-if="action" :name="actionIcon" :size="16" />
      </div>
      <p v-if="subtitle" :style="{ color: subtitleColor }">
        {{ subtitle }}
      </p>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import PxIcon from "@/components/px-icon/px-icon.vue";

export default defineComponent({
  name: "PxCard",

  components: {
    PxIcon,
  },

  props: {
    title: {
      type: String,
      required: true,
    },

    subtitle: {
      type: String,
      default: null,
    },

    icon: {
      type: String,
      default: "submission",
    },

    iconSize: {
      type: Number,
      default: 36,
    },

    action: {
      type: Function,
      default: null,
    },

    actionIcon: {
      type: String,
      default: "arrow--right-blue",
    },

    backgroundColor: {
      type: String,
      default: "#fff",
    },

    titleColor: {
      type: String,
      default: "#2972ca",
    },

    subtitleColor: {
      type: String,
      default: "#272740",
    },

    borderColor: {
      type: String,
      default: "#e4e6ea",
    },

    isLoading: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    cardStyles(): { [key: string]: string | number } {
      return {
        backgroundColor: this.backgroundColor,
        border: `1px solid ${this.borderColor}`,
      };
    },

    cardAction(): Function | object {
      return this.action ? { click: this.action } : {};
    },
  },
});
</script>

<style lang="scss" scoped>
.px-card {
  display: flex;
  gap: 10px;
  padding: 22px 23px;

  border-radius: 4px;
  box-shadow: 0 2px 11px 0 $black-5;

  &__title {
    display: flex;
    align-items: center;
    gap: 9px;
    cursor: pointer;

    h1 {
      margin-bottom: 8px;
      font-size: 20px;
      line-height: 1.5;
      letter-spacing: -0.2px;
    }

    .px-icon {
      margin-bottom: 7px;
    }
  }

  &__title-action {
    h1 {
      text-decoration: underline;
      text-decoration-thickness: 1px;
    }

    &:hover {
      filter: brightness(0.7);
    }
  }

  p {
    line-height: 22px;
    letter-spacing: -0.36px;
  }
}
</style>
