import { supporterInvestingLevelModule } from "@/modules/supporters/services/store/supporter-investing-level/supporter-investing-level-module";

import { supporterTypeModule } from "@/modules/supporters/services/store/supporter-type/supporter-type.module";
import { ESupporterTypeState } from "@/modules/supporters/services/store/supporter-type/supporter-type.interface";

import { additionalCriteriaModule } from "@/modules/supporters/services/store/additional-criteria/additional-criteria.module";
import { EAdditionalCriteriaState } from "@/modules/supporters/services/store/additional-criteria/additional-criteria.interface";

import { supporterFlowModule } from "@/modules/supporters/services/store/supporter-flow/supporter-flow.module";
import { ESupporterFlowState } from "@/modules/supporters/services/store/supporter-flow/supporter-flow.interface";

import { affiliateModule } from "@/modules/supporters/services/store/affiliate/affiliate.module";
import { EAffiliateState } from "@/modules/supporters/services/store/affiliate/affiliate.interface";

// Modules to register on the Vuex instance
export const modules = {
  supporterInvestingLevel: supporterInvestingLevelModule,
  [ESupporterTypeState.NAMESPACE]: supporterTypeModule,
  [EAdditionalCriteriaState.NAMESPACE]: additionalCriteriaModule,
  [ESupporterFlowState.NAMESPACE]: supporterFlowModule,
  [EAffiliateState.NAMESPACE]: affiliateModule,
};

// Modules wish the data must persist across sessions
export const toPersist = [
  "supporterInvestingLevel",
  ESupporterTypeState.NAMESPACE,
  EAdditionalCriteriaState.NAMESPACE,
  ESupporterFlowState.NAMESPACE,
  EAffiliateState.NAMESPACE,
];
