import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-06ace82a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "safe-horizontal-container" }
const _hoisted_2 = { class: "category-wizard-mobile__category-name-wrapper" }
const _hoisted_3 = { class: "category-wizard-mobile__category-name" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CategoryColorBar = _resolveComponent("CategoryColorBar")!
  const _component_ElTag = _resolveComponent("ElTag")!

  return (_ctx.curStepData)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        style: _normalizeStyle(`--stepColor: #${_ctx.curStepData.color}`),
        class: "category-wizard-mobile"
      }, [
        _createVNode(_component_CategoryColorBar, {
          "active-step": _ctx.currentStep,
          class: "category-wizard-mobile__bar"
        }, null, 8, ["active-step"]),
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.curStepData.label), 1),
            (_ctx.showLevelTag)
              ? (_openBlock(), _createBlock(_component_ElTag, {
                  key: 0,
                  size: _ctx.$screen.smDown ? 'small' : 'default',
                  class: "category-wizard-mobile__category-level",
                  "data-tag-type": "grey",
                  effect: "plain"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t("common.levelTag", { level: _ctx.selectedStepData.level })), 1)
                  ]),
                  _: 1
                }, 8, ["size"]))
              : _createCommentVNode("", true)
          ])
        ])
      ], 4))
    : _createCommentVNode("", true)
}