import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-2efe9118"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "px-bottom-bar-wrapper" }
const _hoisted_2 = { class: "px-bottom-bar" }
const _hoisted_3 = { class: "px-bottom-bar__container" }
const _hoisted_4 = { class: "px-bottom-bar-item__title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PxIcon = _resolveComponent("PxIcon")!
  const _component_RouterLink = _resolveComponent("RouterLink")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item) => {
          return (_openBlock(), _createElementBlock("div", {
            key: item.value,
            class: "px-bottom-bar-item__wrapper"
          }, [
            _createVNode(_component_RouterLink, {
              to: { name: item.to },
              class: "px-bottom-bar-item"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_PxIcon, {
                  modifier: _ctx.iconModifier(item),
                  name: item.icon,
                  class: "px-bottom-bar-item__icon",
                  size: "38"
                }, null, 8, ["modifier", "name"]),
                _createElementVNode("span", _hoisted_4, _toDisplayString(item.label), 1)
              ]),
              _: 2
            }, 1032, ["to"])
          ]))
        }), 128))
      ])
    ])
  ]))
}