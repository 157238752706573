<template>
  <ElDialog
    ref="modal"
    v-model="innerVisibility"
    :append-to-body="true"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :show-close="false"
    :title="$t(title)"
    class="guest-access-modal"
    width="466px"
  >
    <PxModalMessage
      :center="false"
      :show-icon="false"
      :visible="true"
      class="guest-access-modal__modal-message"
      type="info"
    >
      <i18n-t
        class="guest-access-modal__login"
        keypath="companyLists.accessModal.haveAnAccount"
        tag="span"
      >
        <template #link>
          <PxButton
            ref="loginButton"
            class="el-button--link el-button--small guest-access-modal__login-button"
            type="link"
            @click="goToLogin"
          >
            {{ $t("companyLists.accessModal.haveAnAccountLink") }}
          </PxButton>
        </template>
      </i18n-t>
    </PxModalMessage>
    <ElForm ref="guestForm" :model="guestForm.data" :rules="rules">
      <div data-testid="guest-access-modal-identification-form">
        <ElFormItem
          :label="$t('companyLists.accessModal.form.fields.name')"
          class="guest-access-modal__name-field"
          prop="name"
        >
          <ElInput
            v-model="guestForm.data.name"
            :placeholder="$t('companyLists.accessModal.form.placeholders.name')"
            type="text"
          />
        </ElFormItem>
        <ElFormItem
          :label="$t('companyLists.accessModal.form.fields.email')"
          class="guest-access-modal__email-field"
          prop="email"
        >
          <ElInput
            v-model="guestForm.data.email"
            :placeholder="
              $t('companyLists.accessModal.form.placeholders.email')
            "
            type="email"
          />
        </ElFormItem>
      </div>
      <PxButton
        ref="submitButton"
        :loading="loading"
        class="el-button--block guest-access-modal__submit-button"
        type="primary"
        @click="makeRequest"
      >
        {{ $t(ctaText) }}
      </PxButton>
    </ElForm>
  </ElDialog>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import ElDialogMixin from "@/mixins/el-dialog.mixin";

import {
  generateRequiredValidator,
  generateEmailValidator,
} from "@/services/errors/validator-generators";
import { FormInstance } from "element-plus";

export default defineComponent({
  name: "GuestAccessModal",

  mixins: [ElDialogMixin],

  props: {
    title: {
      type: String,
      default: "companyLists.accessModal.title.noPasscode",
    },

    ctaText: {
      type: String,
      default: "companyLists.accessModal.buttons.enter",
    },

    loading: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      guestForm: {
        data: {
          name: "",
          email: "",
        },
      },

      rules: {
        name: generateRequiredValidator(
          this,
          "companyLists.accessModal.form.fields.name",
        ),
        email: generateEmailValidator(
          this,
          "companyLists.accessModal.form.fields.email",
          true,
        ),
      },
    };
  },
  methods: {
    goToLogin() {
      this.$emit("go-to-login");
    },

    async makeRequest() {
      // validate form data
      const form = this.$refs.guestForm as FormInstance;
      try {
        await form.validate();
      } catch (_) {
        return;
      }

      this.$emit("make-request", this.guestForm.data);
    },
  },
});
</script>

<style lang="scss">
.guest-access-modal {
  .el-dialog__header {
    padding: 14px 56px 15px 29px;
  }

  .el-dialog__title {
    letter-spacing: -0.39px;
  }

  .el-dialog__body {
    padding: 37px 30px 24px 30px;
  }

  .el-form-item__label {
    margin-bottom: 3px;
  }

  .el-form-item .el-input__inner {
    letter-spacing: -0.4px;
  }

  &__modal-message .el-alert {
    padding-top: 1px;
    padding-bottom: 0;
  }

  &__email-field {
    display: block;
    margin-top: 4px;
  }

  &__submit-button {
    margin-top: 32px;
  }

  &__login {
    position: relative;
    top: 0;
    left: 9px;

    font-size: 13px;
    letter-spacing: -0.3px;
  }

  &__login-button.el-button.el-button--link span {
    @include grotesk(semiBold);

    padding: 0;

    font-size: 13px;
    color: $ebony-clay;
  }
}
</style>
