import { Module } from "vuex";

import { ICategoryState } from "@/services/store/category/category-types";
import { RootState } from "@/services/store/root-state";

import { state } from "./category-state";
import { mutations } from "./category-mutations";
import { getters } from "./category-getters";
import { actions } from "./category-actions";

export const categoriesModule: Module<ICategoryState, RootState> = {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
