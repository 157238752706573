<template>
  <PxStepByStepDialog
    v-model:current-page="currentPage"
    :page-count="pageCount"
    :visibility="visibility"
    class="milestone-plan-onboarding-dialog"
    data-testid="milestone-plan-onboarding-dialog"
    @close-dialog="$emit('close-dialog')"
  >
    <template #title>
      <h3>
        {{
          $t(`milestonePlanner.milestonePlan.onboardingModal.${userType}.title`)
        }}
      </h3>
      <p data-testid="onboarding-subtitle">
        {{ $t("milestonePlanner.milestonePlan.onboardingModal.subtitle") }}
      </p>
    </template>
    <div class="milestone-plan-onboarding-dialog__wrapper">
      <MilestoneNavigationTab v-model:active-tab="activeTab" />
      <MilestonePlanOnboardingDialogContent
        :active-page="currentPage"
        :is-visitor="isVisitor"
      />
    </div>
    <div class="milestone-plan-onboarding-dialog__footer">
      <MilestonePlanStageStatus :selected-status="currentPage" />
    </div>
  </PxStepByStepDialog>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import MilestonePlanStageStatus from "@/modules/milestone-planner/components/milestone-plan-stage-status/milestone-plan-stage-status.vue";
import MilestoneNavigationTab from "@/modules/milestone-planner/components/milestone-navigation-tab/milestone-navigation-tab.vue";
import MilestonePlanOnboardingDialogContent from "@/modules/milestone-planner/components/milestone-plan-onboarding-dialog/milestone-plan-onboarding-dialog-content.vue";
import {
  MILESTONE_DETAILS_TABS,
  TMilestoneDetailsTab,
} from "../../services/data/milestones/milestone.interface";
import { MATCHING_USER_TYPES } from "@/modules/matching/constants";

export default defineComponent({
  name: "MilestonePlanOnboardingDialog",

  components: {
    MilestonePlanStageStatus,
    MilestoneNavigationTab,
    MilestonePlanOnboardingDialogContent,
  },

  props: {
    visibility: {
      type: Boolean,
      required: true,
    },
    isVisitor: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      currentPage: 1,
      pageCount: 2,
      activeTab: MILESTONE_DETAILS_TABS.PLAN as TMilestoneDetailsTab,
    };
  },

  computed: {
    userType(): string {
      return this.isVisitor ? "visitor" : MATCHING_USER_TYPES[0];
    },
  },

  watch: {
    currentPage: function (page: number) {
      this.activeTab = Object.values(MILESTONE_DETAILS_TABS)[page - 1];
    },

    activeTab: function (tab: TMilestoneDetailsTab) {
      this.currentPage = Object.values(MILESTONE_DETAILS_TABS).indexOf(tab) + 1;
    },
  },
});
</script>

<style lang="scss">
.milestone-plan-onboarding-dialog.el-dialog {
  .el-dialog__header {
    @include grotesk(regular);

    padding: 31px 0 27px 0;
    margin-bottom: 33px;
    color: $ebony-clay;
    text-align: center;
    background-image: url("#{$assetsPath}/img/illustrations/milestone-planner/onboarding-dialog-header.svg");

    p {
      margin-bottom: 7px;
      font-size: 17px;
      line-height: 22px;
      letter-spacing: -0.38px;
    }

    h3 {
      margin-bottom: 2px;
      font-size: 20px;
      font-weight: bold;
      line-height: 30px;
    }
  }

  .el-dialog__body {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 425px;
    padding: 0;
  }
}

.milestone-plan-onboarding-dialog {
  &__wrapper {
    padding: 0 40px 24px 50px;
  }

  &__footer {
    position: absolute;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 740px;
    height: 80px;

    &::before {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      content: "";
      background-image: linear-gradient(
        to bottom,
        rgba(123, 183, 255, 0),
        rgba(123, 183, 255, 0.5)
      );
      background-size: cover;

      opacity: 0.1;
    }
  }
}
</style>

<style lang="scss">
.milestone-plan-onboarding-dialog .px-navigation-tab {
  gap: 37px;
  min-width: 640px;
  padding-bottom: 2px;
  margin-right: 10px;
  margin-bottom: 27px;
  border-bottom: 1px solid $athens-gray;

  > :nth-child(1) {
    position: relative;
    right: 4px;

    img {
      position: relative;
      bottom: 1px;
    }
  }

  &-item {
    height: 34px;

    &__container {
      min-width: 200px;
      padding: 0 20px 9px 20px;
    }
  }
}
</style>
