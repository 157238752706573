import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, Transition as _Transition, withCtx as _withCtx, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, Fragment as _Fragment, createElementBlock as _createElementBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-9681cd3c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "milestone-overview page-full-height" }
const _hoisted_2 = { class: "page-body" }
const _hoisted_3 = { class: "page-wrapper" }
const _hoisted_4 = { class: "page-container milestone-overview__page-container" }
const _hoisted_5 = { class: "milestone-overview__panel" }
const _hoisted_6 = { class: "milestone-overview__header" }
const _hoisted_7 = { class: "milestone-overview__header-left" }
const _hoisted_8 = { class: "milestone-overview__title" }
const _hoisted_9 = { class: "milestone-overview__header-right" }
const _hoisted_10 = { class: "milestone-overview__grid-footer" }
const _hoisted_11 = { class: "milestone-overview__grid-tip" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PxIcon = _resolveComponent("PxIcon")!
  const _component_SharePlanner = _resolveComponent("SharePlanner")!
  const _component_PxButton = _resolveComponent("PxButton")!
  const _component_MilestonesGrid = _resolveComponent("MilestonesGrid")!
  const _component_MilestoneCarousel = _resolveComponent("MilestoneCarousel")!
  const _component_LastAssessmentDate = _resolveComponent("LastAssessmentDate")!
  const _component_GridLegend = _resolveComponent("GridLegend")!
  const _component_MilestoneSidebar = _resolveComponent("MilestoneSidebar")!
  const _component_OverviewSidebar = _resolveComponent("OverviewSidebar")!
  const _component_PxPanel = _resolveComponent("PxPanel")!
  const _component_MilestoneOnboardingDialog = _resolveComponent("MilestoneOnboardingDialog")!
  const _component_UpgradeModal = _resolveComponent("UpgradeModal")!
  const _component_LevelUpdateModal = _resolveComponent("LevelUpdateModal")!
  const _component_MatchingConfirmModal = _resolveComponent("MatchingConfirmModal")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _withDirectives((_openBlock(), _createBlock(_component_PxPanel, null, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_5, [
                (!_ctx.isLoading && _ctx.shouldRenderGrid)
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                      _createElementVNode("div", {
                        class: _normalizeClass([{
                    'milestone-overview__main--carousel': _ctx.carouselVisible,
                  }, "milestone-overview__main"])
                      }, [
                        _createElementVNode("div", _hoisted_6, [
                          _createElementVNode("div", _hoisted_7, [
                            _createElementVNode("h1", _hoisted_8, _toDisplayString(_ctx.$t("milestonePlanner.label")), 1),
                            _createVNode(_component_PxIcon, {
                              size: 22,
                              class: "milestone-overview__onboarding-dialog-trigger",
                              name: "information--light",
                              onClick: _ctx.openOnboardingDialog
                            }, null, 8, ["onClick"])
                          ]),
                          _createElementVNode("div", _hoisted_9, [
                            (_ctx.defaultMilestonePlanner)
                              ? (_openBlock(), _createBlock(_component_SharePlanner, {
                                  key: 0,
                                  ref: "sharePlannerButton",
                                  "milestone-planner": _ctx.defaultMilestonePlanner
                                }, null, 8, ["milestone-planner"]))
                              : _createCommentVNode("", true),
                            _createVNode(_component_PxButton, _mergeProps(_ctx.$options.static.maximizeButton, {
                              ref: "showFullGridButton",
                              onClick: _ctx.showFullGrid
                            }), null, 16, ["onClick"])
                          ])
                        ]),
                        _createVNode(_Transition, {
                          mode: "out-in",
                          name: "fade"
                        }, {
                          default: _withCtx(() => [
                            (!_ctx.carouselVisible)
                              ? (_openBlock(), _createBlock(_component_MilestonesGrid, {
                                  key: 0,
                                  ref: "milestonesGrid",
                                  categories: _ctx.gridCategories,
                                  "current-level": _ctx.authLastAssessment.level.value,
                                  "levels-count": _ctx.categories[0].categoryDetails.length,
                                  class: "milestone-overview__grid",
                                  onShowCarousel: _ctx.showCarousel
                                }, null, 8, ["categories", "current-level", "levels-count", "onShowCarousel"]))
                              : (_openBlock(), _createBlock(_component_MilestoneCarousel, {
                                  key: 1,
                                  ref: "milestonesCarousel",
                                  categories: _ctx.gridCategories,
                                  "last-assessment-data": _ctx.authLastAssessment.data,
                                  "levels-count": _ctx.categories[0].categoryDetails.length,
                                  "selected-category": _ctx.carouselSelectedCategory,
                                  onChangeCategoryLevel: _ctx.changeCarouselCategoryLevel,
                                  onHideCarousel: _ctx.hideCarousel
                                }, null, 8, ["categories", "last-assessment-data", "levels-count", "selected-category", "onChangeCategoryLevel", "onHideCarousel"]))
                          ]),
                          _: 1
                        }),
                        _createElementVNode("div", _hoisted_10, [
                          _createVNode(_component_LastAssessmentDate, {
                            date: _ctx.lastAssessmentDate,
                            class: "milestone-overview__last-assessment"
                          }, null, 8, ["date"]),
                          _createElementVNode("div", _hoisted_11, [
                            _createVNode(_component_PxIcon, {
                              size: 16,
                              class: "milestone-overview__grid-tip-icon",
                              name: "information--malibu"
                            }),
                            _createTextVNode(" " + _toDisplayString(_ctx.$t("milestonePlanner.milestoneGrid.footer.tip")), 1)
                          ]),
                          _createVNode(_component_GridLegend, { class: "milestone-overview__grid-legend" })
                        ])
                      ], 2),
                      _createVNode(_Transition, {
                        mode: "out-in",
                        name: "fade"
                      }, {
                        default: _withCtx(() => [
                          (_ctx.carouselVisible)
                            ? (_openBlock(), _createBlock(_component_MilestoneSidebar, {
                                key: 0,
                                categories: _ctx.categories,
                                category: _ctx.carouselSelectedCategory,
                                "last-assessment-data": _ctx.authLastAssessment.data,
                                onHideCarousel: _ctx.hideCarousel
                              }, null, 8, ["categories", "category", "last-assessment-data", "onHideCarousel"]))
                            : (_openBlock(), _createBlock(_component_OverviewSidebar, {
                                key: 1,
                                "latest-assessment-level": _ctx.authLastAssessment.level
                              }, null, 8, ["latest-assessment-level"]))
                        ]),
                        _: 1
                      })
                    ], 64))
                  : _createCommentVNode("", true)
              ])
            ]),
            _: 1
          })), [
            [_directive_loading, _ctx.isLoading]
          ])
        ])
      ])
    ]),
    (_ctx.onboardingDialogVisibility)
      ? (_openBlock(), _createBlock(_component_MilestoneOnboardingDialog, {
          key: 0,
          visibility: _ctx.onboardingDialogVisibility,
          "onUpdate:visibility": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.onboardingDialogVisibility) = $event)),
          onCloseDialog: _ctx.closeOnboardingDialog
        }, null, 8, ["visibility", "onCloseDialog"]))
      : _createCommentVNode("", true),
    (_ctx.isUpgradeModalVisibility)
      ? (_openBlock(), _createBlock(_component_UpgradeModal, {
          key: 1,
          visibility: _ctx.isUpgradeModalVisibility,
          "onUpdate:visibility": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.isUpgradeModalVisibility) = $event)),
          "is-level-up": _ctx.isLevelUp,
          onClose: _ctx.cleanQuickLevelMeta
        }, null, 8, ["visibility", "is-level-up", "onClose"]))
      : _createCommentVNode("", true),
    (_ctx.$user.isOwner() && _ctx.isNewLevelModalVisible)
      ? (_openBlock(), _createBlock(_component_LevelUpdateModal, {
          key: 2,
          visibility: _ctx.isNewLevelModalVisible,
          "onUpdate:visibility": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.isNewLevelModalVisible) = $event))
        }, null, 8, ["visibility"]))
      : _createCommentVNode("", true),
    (_ctx.isMatchingConfirmModalVisible)
      ? (_openBlock(), _createBlock(_component_MatchingConfirmModal, {
          key: 3,
          visibility: _ctx.isMatchingConfirmModalVisible,
          "onUpdate:visibility": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.isMatchingConfirmModalVisible) = $event))
        }, null, 8, ["visibility"]))
      : _createCommentVNode("", true)
  ]))
}