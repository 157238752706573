import { Module } from "vuex";
import { RootState } from "@/services/store/root-state";
import { make } from "vuex-pathify";
import { listManagementActions } from "./list-management.actions";
import { IListManagementState } from "@/modules/company-lists/services/store/list-management/list-management.types";

const state: IListManagementState = {
  data: {
    companies: [],
    lists: [],
  },
  error: null,
  loading: false,
};

export const listManagementModule: Module<IListManagementState, RootState> = {
  namespaced: true,
  state,
  actions: listManagementActions,
  mutations: make.mutations(state),
};
