import { resolveComponent as _resolveComponent, withModifiers as _withModifiers, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-322c38eb"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "list-share" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PxButton = _resolveComponent("PxButton")!
  const _component_ListShareModal = _resolveComponent("ListShareModal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_PxButton, {
      label: _ctx.$t('companyLists.list.share'),
      class: "list-share__toggler",
      size: "medium",
      onClick: _withModifiers(_ctx.openShareModalClickHandler, ["stop"])
    }, null, 8, ["label", "onClick"]),
    _createVNode(_component_ListShareModal, {
      visibility: _ctx.shareModalVisible,
      "onUpdate:visibility": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.shareModalVisible) = $event)),
      name: _ctx.value.title,
      passcode: _ctx.listPasscode,
      uid: _ctx.value.uid,
      users: _ctx.formatAllUsers
    }, null, 8, ["visibility", "name", "passcode", "uid", "users"])
  ]))
}