<template>
  <div class="matching-percentage-pill__wrapper">
    <ElTooltip
      :disabled="!$screen.mdUp || !showTooltip"
      :enterable="false"
      placement="top"
      popper-class="matching-card__tooltip el-abaca-tooltip"
    >
      <div
        v-if="formattedMatchPercentage"
        class="matching-percentage-pill__container"
      >
        <div class="matching-percentage-pill">
          {{ formattedMatchPercentage }}
        </div>
      </div>
      <template #content>
        <div>
          <p>
            {{ $t("matching.matchingList.moreAboutThis") }}
          </p>
        </div>
      </template>
    </ElTooltip>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "MatchingCardPercentagePill",

  props: {
    percentage: {
      type: Number,
      required: true,
    },

    showTooltip: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    formattedMatchPercentage(): string {
      const value = this.percentage < 0 ? "?" : this.percentage;
      return `${value}%`;
    },
  },
});
</script>

<style lang="scss" scoped>
.matching-percentage-pill__container {
  position: absolute;
  top: -1px;
  right: -1px;
  z-index: z("floaters") + 1;

  &:hover {
    text-decoration: none;
  }
}

.matching-percentage-pill {
  @include grotesk(semiBold);

  position: relative;
  z-index: z("floaters") + 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 55px;
  height: 30px;
  padding: 4px 9px 5px 11px;
  font-size: 18px;
  color: $white;

  border: solid 1px #6391c8;
  border-radius: 0 4px 0 12px;

  @include gradient(primary, to bottom);
}
</style>
