<template>
  <div class="affiliate-review-page-team-members">
    <header v-if="$screen.xlUp">
      <h3 class="affiliate-review-page-team-members__title">
        {{
          $t("affiliateProgram.components.flowNavbar.teamManagementTabTitle")
        }}
      </h3>
      <div
        v-if="teamMembersWithMissingMandatoryData.length"
        class="affiliate-review-page-team-members__missing-info-label"
        data-testid="affiliate-review-page-team-members-missing-info-label"
      >
        <PxIcon :size="16" name="warning--white" />
        <p v-html="missingTeamMembersInfoCopy" />
      </div>
    </header>
    <div class="affiliate-review-page-team-members__wrapper">
      <template v-if="teamMembersWithMissingData.length">
        <div class="affiliate-review-page-team-members__info-divider">
          <span>
            {{ $t("affiliates.reviewPage.content.notAnswered") }}
          </span>
        </div>
        <ElCollapse :accordion="true">
          <template
            v-for="(teamMember, index) in teamMembersWithMissingData"
            :key="index"
          >
            <PxInformationCardCollapsible
              v-bind="missingDataCardStyles(teamMember)"
              :description="cardDescription(teamMember)"
              :title="getTeamMemberName(teamMember)"
              :title-styles="cardTitleStyles(teamMember)"
              class="affiliate-review-page-team-members__info-card"
              data-testid="affiliate-review-page-team-members-not-answered-card"
              @navigate="navigateToTeamMembersPage"
            >
              <template #content>
                <AffiliateReviewPageTeamMembersDetails
                  :label="missingDataCardStyles(teamMember)['label']"
                  :team-member="teamMember"
                />
              </template>
            </PxInformationCardCollapsible>
          </template>
        </ElCollapse>
      </template>
      <template v-if="validTeamMembers.length">
        <div class="affiliate-review-page-team-members__info-divider">
          <span>
            {{ $t("affiliates.reviewPage.content.answered") }}
          </span>
        </div>
        <ElCollapse :accordion="true">
          <template
            v-for="(teamMember, index) in validTeamMembers"
            :key="index + teamMembersWithMissingData.length"
          >
            <PxInformationCardCollapsible
              v-bind="answeredCardStyles"
              :title="teamMember.first_name + ' ' + teamMember.last_name"
              class="affiliate-review-page-team-members__info-card"
              data-testid="affiliate-review-page-team-members-answered-card"
              @navigate="navigateToTeamMembersPage"
            >
              <template #content>
                <AffiliateReviewPageTeamMembersDetails
                  :label="answeredCardStyles['label']"
                  :team-member="teamMember"
                />
              </template>
            </PxInformationCardCollapsible>
          </template>
        </ElCollapse>
      </template>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import PxInformationCardCollapsible from "@/components/px-information-card/px-information-card-collapsible.vue";

import { ITeamMember } from "@/modules/team-management/services/data/team-members/team-members.interface";
import { IAffiliatesState } from "@/modules/affiliates/services/store/affiliates/affiliates.types";
import { IMatchingResponse } from "@/services/data/matching-responses/matching-response.interface";
import AffiliateReviewPageTeamMembersDetails from "@/modules/affiliates/views/affiliate-review-page/affiliate-review-page-team-members-details.vue";

export default defineComponent({
  name: "AffiliateReviewPageTeamMembers",

  components: {
    PxInformationCardCollapsible,
    AffiliateReviewPageTeamMembersDetails,
  },

  computed: {
    affiliatesData(): IAffiliatesState {
      return this.$store.state.affiliates;
    },

    emptyTeamMembersData(): Array<ITeamMember> {
      return [
        {
          id: "",
          first_name: "",
          last_name: "",
          email: "",
          position: "",
          responses: [],
        },
      ];
    },

    teamMembersData(): Array<ITeamMember> {
      return this.affiliatesData.draftSubmission &&
        this.affiliatesData.draftSubmission.data.teamMembers.length
        ? this.affiliatesData.draftSubmission.data.teamMembers
        : this.emptyTeamMembersData;
    },

    validTeamMembers(): Array<ITeamMember> {
      if (!this.affiliateWithTeamMemberQuestions) {
        return this.teamMembersData.filter(
          (teamMember) =>
            teamMember.first_name &&
            teamMember.last_name &&
            teamMember.email &&
            this.checkValidEmail(teamMember.email) &&
            teamMember.position,
        );
      } else {
        return this.teamMembersData.filter(
          (teamMember) =>
            teamMember.first_name &&
            teamMember.last_name &&
            teamMember.email &&
            this.checkValidEmail(teamMember.email) &&
            teamMember.position &&
            teamMember.responses.length &&
            teamMember.responses?.every((response: any) => {
              return this.checkValidResponse(response);
            }),
        );
      }
    },

    teamMembersWithMissingMandatoryData(): Array<ITeamMember> {
      return this.teamMembersData.filter((teamMember) => {
        return (
          !teamMember.first_name ||
          !teamMember.last_name ||
          !teamMember.email ||
          !this.checkValidEmail(teamMember.email) ||
          !teamMember.position
        );
      });
    },

    affiliateWithTeamMemberQuestions(): boolean {
      return this.affiliatesData.teamMembersQuestionBundle
        ? !!this.affiliatesData.teamMembersQuestionBundle?.length
        : false;
    },

    teamMembersWithMissingData(): Array<ITeamMember> {
      return this.teamMembersData.filter(
        (teamMember) => !this.validTeamMembers.includes(teamMember),
      );
    },

    missingTeamMembersInfoCopy(): any {
      return this.$tc(
        "affiliates.reviewPage.content.missingTeamMembers",
        this.teamMembersWithMissingMandatoryData.length,
      );
    },

    notAnsweredCardStyles(): any {
      return {
        label: {
          text: this.$t("common.required"),
          icon: "warning--blue",
          iconSize: 16,
          textColor: "#7bb7ff",
          backgroundColor: "rgba(123, 183, 255, 0.16)",
        },
        cta: {
          label: this.$t("common.answer"),
          type: "default",
          size: "small",
        },
        backgroundColor: "rgba(123, 183, 255, 0.05)",
        border: "1px solid rgba(123,183, 255, 0.8)",
      };
    },

    answeredCardStyles(): any {
      return {
        label: {
          text: this.$t("common.required"),
          icon: "milestone-check",
          iconSize: 16,
          textColor: "#53af72",
          backgroundColor: "rgba(83, 175, 114, 0.12)",
        },
        cta: {
          label: this.$t("common.update"),
          type: "default",
          size: "small",
        },
        backgroundColor: "#fafafa",
        border: "1px solid rgba(220, 221, 226, 0.8)",
      };
    },
  },

  methods: {
    checkValidResponse(response: IMatchingResponse) {
      return (
        !!response.answers?.length ||
        !!response.value?.value ||
        !!response.value?.date ||
        (!!response?.value?.max &&
          !!response?.value?.min &&
          response?.value?.max > response?.value?.min) ||
        (!!response.value?.text && response.value?.text.length < 1500)
      );
    },

    checkValidEmail(email: string) {
      const emailRegex =
        // eslint-disable-next-line no-control-regex
        /^(?:[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?\.)+[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[A-Za-z0-9-]*[A-Za-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)])$/;
      return emailRegex.test(email);
    },

    isMissingRequiredInformation(teamMember: ITeamMember): boolean {
      return (
        !teamMember.first_name ||
        !teamMember.last_name ||
        !teamMember.email ||
        (teamMember.email && !this.checkValidEmail(teamMember.email)) ||
        !teamMember.position
      );
    },

    getTeamMemberName(teamMember: ITeamMember) {
      if (teamMember.first_name || teamMember.last_name) {
        return `${teamMember.first_name} ${teamMember.last_name}`;
      } else {
        return this.$t("teamManagement.newMember");
      }
    },

    cardTitleStyles(teamMember: ITeamMember): any {
      if (!teamMember.first_name && !teamMember.last_name) {
        return "blue";
      }
    },

    cardDescription(teamMember: ITeamMember): any {
      if (this.isMissingRequiredInformation(teamMember)) {
        return this.$t("affiliates.reviewPage.content.missingUserDetails");
      }
    },

    missingDataCardStyles(teamMember: ITeamMember) {
      if (!this.isMissingRequiredInformation(teamMember)) {
        return {
          ...this.answeredCardStyles,
          cta: {
            ...this.answeredCardStyles.cta,
            label: this.$t("common.answer"),
          },
        };
      }

      return this.notAnsweredCardStyles;
    },

    navigateToTeamMembersPage() {
      this.$emit("go-to-team-members");
    },
  },
});
</script>

<style lang="scss" scoped>
.affiliate-review-page-team-members {
  padding: 0 0 22px 0;

  header {
    padding: 22px 0 0 23px;
  }

  &__title {
    margin: 0;
    padding: 0 0 17px 0;

    @include grotesk(bold);

    font-size: 20px;
    line-height: 1.6;
    color: $ebony-clay;
    letter-spacing: -0.2px;
  }

  &__missing-info-label {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 7px;
    width: max-content;
    padding: 4px 15px 4px 14px;
    border-radius: 20px;
    background-color: $malibu;

    p {
      margin: 0;
      font-size: 13px;
      color: $white;
      letter-spacing: -0.2px;
    }
  }

  &__info-divider {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 7px 0 9px 0;
    border-top: 1px solid $mischka;

    span {
      @include grotesk(semiBold);

      position: relative;
      top: -8px;
      padding: 0 6px;
      font-size: 13px;
      line-height: 1.23;
      letter-spacing: -0.2px;
      background-color: $white;
      color: $manatee;
      text-transform: uppercase;
    }
  }

  &__info-divider:not(:first-of-type) {
    margin-top: 23px;
  }

  &__wrapper {
    margin: 0 9px 0 23px;
    padding: 0 10px 0 0;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
      margin: 30px;
    }

    &::-webkit-scrollbar-thumb {
      background: $mischka;
      border-radius: 10px;
    }
  }

  &__info-card {
    width: 100%;
    margin-bottom: 16px;
  }
}
</style>
