import { resolveComponent as _resolveComponent, vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-b1ae2260"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "lists-detail page-body" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AccessListGuard = _resolveComponent("AccessListGuard")!
  const _component_PxButton = _resolveComponent("PxButton")!
  const _component_ListDetailPanel = _resolveComponent("ListDetailPanel")!
  const _component_PxResponsiveBitmap = _resolveComponent("PxResponsiveBitmap")!
  const _component_ListManagementBar = _resolveComponent("ListManagementBar")!
  const _component_MatchingConfirmModal = _resolveComponent("MatchingConfirmModal")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createVNode(_component_AccessListGuard, { "requires-passcode": _ctx.listRequiresPasscode }, null, 8, ["requires-passcode"]), [
      [_vShow, !_ctx.isDetailLoading]
    ]),
    _withDirectives((_openBlock(), _createElementBlock("div", {
      class: _normalizeClass([{ 'is-loading page-full-height': _ctx.isLoading }, "page-container"]),
      "element-loading-background": "#fafafa"
    }, [
      (_ctx.$user.isLogged())
        ? (_openBlock(), _createBlock(_component_PxButton, {
            key: 0,
            class: "el-button--small lists-detail__back-button",
            type: "link",
            onClick: _ctx.backToListsClickHandler
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("companyLists.detail.backToDirectory")), 1)
            ]),
            _: 1
          }, 8, ["onClick"]))
        : _createCommentVNode("", true),
      _withDirectives(_createVNode(_component_ListDetailPanel, {
        members: _ctx.memberCardList,
        value: _ctx.currentDetailList
      }, null, 8, ["members", "value"]), [
        [_vShow, !_ctx.isLoading]
      ]),
      false
        ? (_openBlock(), _createBlock(_component_PxResponsiveBitmap, {
            key: 1,
            link: _ctx.listPlaceholderBackground,
            alt: "",
            class: "form-presentation-layout__description-image"
          }, null, 8, ["link"]))
        : _createCommentVNode("", true),
      (_ctx.isAuthUser)
        ? (_openBlock(), _createBlock(_component_ListManagementBar, {
            key: 2,
            "available-items": _ctx.listManagementItems,
            "edited-list": _ctx.currentDetailList
          }, null, 8, ["available-items", "edited-list"]))
        : _createCommentVNode("", true),
      (_ctx.isMatchingConfirmModalVisible)
        ? (_openBlock(), _createBlock(_component_MatchingConfirmModal, {
            key: 3,
            visibility: _ctx.isMatchingConfirmModalVisible,
            "onUpdate:visibility": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.isMatchingConfirmModalVisible) = $event))
          }, null, 8, ["visibility"]))
        : _createCommentVNode("", true)
    ], 2)), [
      [_directive_loading, _ctx.isLoading]
    ])
  ]))
}