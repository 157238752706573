<template>
  <div
    :class="{
      'matching-card--selected': isSelected,
      'matching-card--faded': isFaded && !isDisabled,
      'matching-card--disabled': isDisabled,
    }"
    class="matching-card"
  >
    <slot name="action" />
    <div class="matching-card__header">
      <slot name="header" />
    </div>
    <div class="matching-card__body">
      <slot name="body" />
      <div class="matching-card__footer">
        <slot name="footer" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "MatchingCardWrapper",

  props: {
    isSelected: {
      type: Boolean,
      default: false,
    },

    isFaded: {
      type: Boolean,
      default: false,
    },

    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
});
</script>

<style lang="scss" scoped>
.matching-card {
  position: relative;
  z-index: z("floaters");
  display: inline-flex;
  flex-flow: column wrap;
  width: 290px;
  cursor: pointer;

  border: solid 1px $turkish-angora;
  border-radius: 4px;
  box-shadow: 0 4px 12px -4px rgba($manatee, 0.3);

  @include breakpoint-up(md) {
    width: 257px;
    transition:
      opacity 0.1s ease-in-out,
      transform 0.1s ease-in-out;
  }

  &::after {
    @include breakpoint-up(md) {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      content: "";
      box-shadow: 0 8px 12px -4px rgba($manatee, 0.3);
      opacity: 0;
      transition: opacity 0.1s ease-in-out;
    }
  }
}

.matching-card:hover {
  @include breakpoint-up(md) {
    transform: translateY(-2px);
  }

  &::after {
    @include breakpoint-up(md) {
      opacity: 1;
    }
  }
}

.matching-card--selected {
  border: 1px solid $malibu;
}

.matching-card--selected,
.matching-card--faded {
  cursor: default;
}

.matching-card--faded:not(.matching-card:hover) {
  opacity: 0.7;
}

.matching-card--disabled {
  pointer-events: none;

  .matching-card-selector__wrapper {
    position: absolute;
    z-index: z("floaters") + 11;
  }

  @include breakpoint-up(md) {
    &::after {
      position: absolute;
      top: -1px;
      right: -1px;
      bottom: -1px;
      left: -1px;
      z-index: z("floaters") + 10;
      width: 257px;

      background-color: $white;
      border: solid 1px $turkish-angora;
      border-radius: 4px;
      opacity: 0.4;
    }
  }
}

.matching-card__body {
  position: relative;
  width: 100%;
  min-height: 204px;
  padding: 13px 13px 14px;
  background-color: $white;
  border-radius: 0 0 3px 3px;

  @include breakpoint-up(md) {
    padding: 27px 14px 13px;
  }
}

.matching-card--selected .matching-card__header,
.matching-card--selected .matching-card__body,
.matching-card--faded .matching-card__header,
.matching-card--faded .matching-card__body {
  pointer-events: none;
  user-select: none;
}

.matching-card--simplified > .matching-card__body {
  padding: 13px 11px 16px;
  border-radius: 4px;
}

.matching-card__footer {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
  grid-gap: 9px;
  margin-top: 10px;
  margin-bottom: 2px;

  :deep() .el-button {
    width: 100%;
  }
}
</style>
