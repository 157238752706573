import {
  EProviderFeatures,
  GenericProvider,
} from "@/services/data/generic-provider";
import { baseAPIUrl } from "@/services/utils/utils";
import { ISupporterFlow } from "./supporter-flow.interface";
import { ILoginResponse } from "@/modules/authentication/services/data/login/login.interface";

class PendingSupporterProvider extends GenericProvider<ISupporterFlow> {
  constructor() {
    super(`${baseAPIUrl}/pending-supporter/register`, [
      EProviderFeatures.CREATE,
    ]);
  }
}

class PendingSupporterFinishProvider extends GenericProvider<
  ILoginResponse,
  Partial<ISupporterFlow>
> {
  constructor() {
    super(`${baseAPIUrl}/pending-supporter/finished`, [
      EProviderFeatures.CREATE,
    ]);
  }
}

export const pendingSupporterProvider = new PendingSupporterProvider();
export const pendingSupporterFinishedProvider =
  new PendingSupporterFinishProvider();
