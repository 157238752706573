import { Module } from "vuex";

import { RootState } from "../root-state";

import { state } from "./meta-state";
import { mutations } from "./meta-mutations";
import { actions } from "./meta-actions";
import { getters } from "./meta-getters";
import { IMetaState } from "./meta-types";

export const metaModule: Module<IMetaState, RootState> = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
