<template>
  <ElDialog
    ref="modal"
    v-model="innerVisibility"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :show-close="false"
    class="el-dialog--no-header el-dialog--compact-footer matchingConfirmModal"
    width="661px"
  >
    <PxModalMessage :visible="hasServerError" />
    <PxModalMessage
      :title="$t(`matching.matchingSupporter.confirmModal.errorMessage`)"
      :visible="hasInvalidData"
    />
    <PxModalMessage
      :title="
        $t(`matching.matchingSupporter.confirmModal.successUpdatingLevelRange`)
      "
      :visible="hasUpdatedRangeLevel"
      type="success"
    />
    <p class="matchingConfirmModal-title">
      {{ $t("matching.matchingSupporter.confirmModal.title") }}
    </p>
    <PxEditPanel
      :title="supporterLocationsTitle"
      @cancel="onCancelLocations"
      @submit="onSubmitLocations"
    >
      <template v-if="supporterLocationsOfInterest.length" #normal>
        <ElTag
          v-for="(location, index) in supporterLocationsOfInterest"
          :key="index"
          class="matchingConfirmModal-tag text--capitalize"
          effect="plain"
          v-text="location.formatted_address"
        />
      </template>
      <template v-else #normal>
        {{ $t("matching.confirmModal.location.any") }}
      </template>
      <template #edit>
        <PxRadioGroup
          v-model="hasSpecificLocations"
          class="matchingConfirmModal-option"
        >
          <ElRadio :label="true">
            {{ $t("supporters.signup.form.locationOptions.specific") }}
          </ElRadio>
          <ElRadio :label="false">
            {{ $t("supporters.signup.form.locationOptions.all") }}
          </ElRadio>
        </PxRadioGroup>
        <LocationsSelector
          v-show="hasSpecificLocations"
          v-model="newLocations"
          :no-label="true"
          autocomplete-type="(regions)"
          @input="insertedLocationsHandler"
        />
        <span
          class="el-form-item__bottom-text el-form-item__tip"
          v-html="$t('supporters.signup.form.tips.location')"
        />
      </template>
    </PxEditPanel>
    <PxEditPanel
      :title="supporterSectorsTitle"
      @cancel="onCancelSectors"
      @submit="onSubmitSectors"
    >
      <template v-if="supporterSectorsOfInterest.length" #normal>
        <ElTag
          v-for="(sector, index) in supporterSectorsOfInterest"
          :key="index"
          class="matchingConfirmModal-tag text--capitalize"
          effect="plain"
          v-text="sector.name"
        />
      </template>
      <template v-else #normal>
        {{ $t("matching.confirmModal.sectors.any") }}
      </template>
      <template #edit>
        <SectorsSelector
          ref="sectorSelector"
          v-model="newSectors"
          :no-label="true"
        />
        <span
          class="el-form-item__bottom-text el-form-item__tip"
          v-html="$t('supporters.signup.form.tips.sector')"
        />
      </template>
    </PxEditPanel>
    <PxEditPanel
      :title="
        $t('matching.matchingSupporter.confirmModal.investmentLevelRange.title')
      "
      @edit="onEditInvestmentRangeLevel"
    >
      <template #normal>
        <div v-if="supporterInvestingLevelRange.length" class="rangeLevelCard">
          <PxIcon
            :name="`level-range-graph/level-${investingLevelRangeInterval}`"
            :size="$screen.mdUp ? 34 : 42"
            class="rangeLevelCard-indicator"
          />
          <span class="rangeLevelCard-title">
            {{ investingLevelRangeTitle }}
            <ElTooltip
              placement="top"
              popper-class="rangeLevelCard-tooltip el-abaca-tooltip"
            >
              <template #content>
                <div v-html="investingLevelRangeDescription" />
              </template>
              <i class="icon icon-question" />
            </ElTooltip>
          </span>
        </div>
      </template>
    </PxEditPanel>
    <template #footer>
      <PxButton
        class="matchingConfirmModal-update-btn"
        @click="onClickEditCriteria"
      >
        {{ $t("matching.matchingSupporter.confirmModal.buttons.editCriteria") }}
      </PxButton>
      <PxButton
        :loading="isLoading"
        class="matchingConfirmModal-submitBtn"
        type="green"
        @click="onClickProceedButton"
      >
        {{ $t("matching.matchingSupporter.confirmModal.buttons.proceed") }}
      </PxButton>
    </template>
  </ElDialog>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import cloneDeep from "lodash/cloneDeep";
import uniq from "lodash/uniq";

import PxEditPanel from "@/components/px-edit-panel/px-edit-panel.vue";
import LocationsSelector from "@/components/locations-selector/locations-selector.vue";
import SectorsSelector from "@/components/sectors-selector/sectors-selector.vue";

import ElDialogMixin from "@/mixins/el-dialog.mixin";
import { ISupporter } from "@/modules/matching/services/data/matching-score/supporter.interface";
import { ILocation } from "@/services/data/location/location.interface";
import { ISector } from "@/services/data/sector/sector.interface";
import { EUserMetadataActions } from "@/modules/authentication/services/store/auth/sub-modules/user-metadata/user-metadata.types";
import {
  MATCHING_ONBOARDING_COMPLETION,
  MATCHING_FROM_ONBOARDING,
} from "@/modules/matching/constants";
import { EMetaActions } from "@/services/store/meta/meta-types";
import { ECategoryActions } from "@/services/store/category/category-types";
import { ROUTE_UPDATE_RANGE_LEVEL } from "@/modules/profile/services/router/routes-names";
import { PROFILE_META_UPDATE_FROM_SUPPORTER_MATCHING } from "@/modules/profile/constants";
import { SUPPORTERS_INVESTING_RANGE_LIMIT } from "@/modules/supporters/constants";
import { AFFILIATE_SUPPORTER_PROGRAM_UPDATE_URL } from "@/modules/affiliates/constants";
import {
  ICategory,
  ICategoryDetail,
} from "@/services/data/category/category.interface";

import { SUPPORTER_USER_GROUP_ID } from "@/modules/common/constants";
import {
  EAuthActions,
  EAuthSupporterActions,
} from "@/modules/authentication/services/store/auth/auth-types";

export default defineComponent({
  name: "MatchingSupporterConfirmModal",

  components: {
    PxEditPanel,
    LocationsSelector,
    SectorsSelector,
  },

  mixins: [ElDialogMixin],

  data() {
    return {
      hasUpdatedRangeLevel: false,
      hasInvalidData: false,
      hasServerError: false,
      isLoading: false,

      newLocations: [] as Array<ILocation>,
      hasSpecificLocations: false,
      newSectors: [] as Array<number>,
    };
  },

  computed: {
    supporter(): ISupporter | null {
      return this.$store.get("auth/supporter.data");
    },

    supporterSectorsTitle(): string {
      return this.$t("profile.company.sectorsOfInterest");
    },

    supporterSectorsOfInterest(): Array<ISector> {
      return this.supporter && this.supporter.sectors
        ? this.supporter.sectors
        : [];
    },

    supporterLocationsTitle(): string {
      return this.$t("profile.company.locationsOfInterest");
    },

    supporterLocationsOfInterest(): Array<ILocation> {
      return this.supporter && this.supporter.locations
        ? this.supporter.locations
        : [];
    },

    supporterInvestingLevelRange(): Array<number> {
      return this.supporter ? uniq(this.supporter.investing_level_range) : [];
    },

    supporterCategories(): Array<ICategory> {
      return this.$store.state.categories.data;
    },

    supporterCategory(): ICategory | null {
      return this.supporterCategories.length
        ? this.supporterCategories[0]
        : null;
    },

    investingLevels(): Array<number> {
      const levelsRange = this.supporterInvestingLevelRange;
      const startLevel = levelsRange[0];

      if (!levelsRange[1]) {
        return levelsRange;
      }

      // Create number sequence from the levels range
      return Array.from(
        new Array(SUPPORTERS_INVESTING_RANGE_LIMIT),
        (val, index) => index + startLevel,
      ).filter((level: number) => level <= levelsRange[1]);
    },

    investingLevelRangeInterval(): string {
      return this.supporterInvestingLevelRange.join("-");
    },

    investingLevelRangeSize(): number {
      return this.supporterInvestingLevelRange[1] ? 1 : 0;
    },

    investingLevelRangeTitle(): string {
      return this.$t(
        `profile.investors.panel.label[${this.investingLevelRangeSize}]`,
        [...this.supporterInvestingLevelRange],
      ) as string;
    },

    investingLevelRangeDescription(): string {
      let levelsDescription = "";

      this.investingLevels.forEach((level: number) => {
        const category = this.getCategoryDetailsByLevel(level);
        const description = category ? category.achievements : "";

        levelsDescription += `Level ${level} - ${description}<br>`;
      });

      return levelsDescription;
    },

    comingFromLevelRangeUpdate(): boolean {
      return !!this.$route.query.updatedRangeLevel;
    },

    profileId(): number | null {
      return this.$store.state.auth.profileId;
    },
  },

  created() {
    this.hasUpdatedRangeLevel = this.comingFromLevelRangeUpdate;

    // Fetch categories
    this.$store.dispatch(ECategoryActions.FETCH, {
      group: SUPPORTER_USER_GROUP_ID,
    });

    this.setCurrentLocations();
    this.setCurrentSectors();
  },

  methods: {
    /**
     * Fetch current supporter auth data
     */
    fetchSupporter() {
      this.$store.dispatch(EAuthActions.FETCH_SUPPORTER, {
        user_profile: this.profileId,
      });
    },

    /**
     * Set active/selected locations on the selector component
     */
    setCurrentLocations() {
      this.newLocations = cloneDeep(this.supporterLocationsOfInterest);
    },

    onCancelLocations() {
      this.hasInvalidData = false;
      this.setCurrentLocations();
    },

    /**
     * Save the changes on the location field.
     */
    onSubmitLocations(success: Function) {
      if (this.newLocations.length || !this.hasSpecificLocations) {
        // If no specific locations, send empty array but avoid removing new location as an UX approach
        const location = this.hasSpecificLocations ? this.newLocations : [];

        this.partialUpdate({ location }, success);
      } else {
        this.notifyInvalidData(success);
      }
    },

    /**
     * Set active/selected sectors on the selector component
     */
    async setCurrentSectors() {
      this.newSectors = this.supporterSectorsOfInterest.map(
        (sector: ISector) => sector.id,
      );

      // Set active sectors
      await this.$nextTick();
      const sectorSelector = this.$refs.sectorSelector as any;
      if (sectorSelector) {
        sectorSelector.setSectors(this.supporterSectorsOfInterest);
      }
    },

    onCancelSectors() {
      this.hasInvalidData = false;
      this.setCurrentSectors();
    },

    /**
     * Save the changes made to the sectors.
     */
    onSubmitSectors(success: Function) {
      if (this.newSectors.length) {
        this.partialUpdate({ sectors: this.newSectors }, success);
      } else {
        this.notifyInvalidData(success);
      }
    },

    /**
     * Request a new Investment Viral Level Range.
     */
    onEditInvestmentRangeLevel() {
      this.$router.push({
        name: ROUTE_UPDATE_RANGE_LEVEL,
      });
      this.$store.dispatch(EMetaActions.SET, {
        key: PROFILE_META_UPDATE_FROM_SUPPORTER_MATCHING,
        value: true,
      });
    },

    /**
     * Find category data by level
     */
    getCategoryDetailsByLevel(level: number) {
      if (this.supporterCategory) {
        return this.supporterCategory.categoryDetails.find(
          (category: ICategoryDetail) => category.level.value === level,
        );
      }
    },

    /**
     * Validate supporter locations on input
     */
    insertedLocationsHandler(insertedLocations: Array<ILocation>) {
      if (insertedLocations.length) {
        this.hasSpecificLocations = true;
      }
    },

    /**
     * Show error message when there's invalid data.
     */
    notifyInvalidData(success: Function) {
      this.hasInvalidData = true;

      // set final state for the save operation
      success(false);
    },

    /**
     * Perform a partial supporter data update.
     */
    async partialUpdate(newData: any, success: Function) {
      this.hasInvalidData = false;
      this.hasServerError = false;

      try {
        const supporterId = this.supporter ? this.supporter.id : null;

        if (!supporterId) return;

        await this.$store.dispatch(EAuthSupporterActions.PATCH, {
          ...newData,
          id: supporterId,
          locations: newData.location,
        });

        // This will update the supporter auth state after the patch
        this.fetchSupporter();
      } catch (error) {
        this.hasServerError = true;
      }

      success(!this.hasServerError);
    },

    /**
     * Mark the matching onboarding as finished.
     */
    async onClickProceedButton() {
      this.hasInvalidData = false;
      this.hasServerError = false;
      this.isLoading = true;

      try {
        await this.$store.dispatch(EUserMetadataActions.SET, {
          key: MATCHING_ONBOARDING_COMPLETION,
          value: "true",
        });
        this.$emit("update:visibility", false);
      } catch (_) {
        this.hasServerError = true;
        return;
      } finally {
        this.isLoading = false;
      }

      // Cleanup meta module
      this.$store.dispatch(EMetaActions.REMOVE, MATCHING_FROM_ONBOARDING);
    },

    onClickEditCriteria() {
      window.open(AFFILIATE_SUPPORTER_PROGRAM_UPDATE_URL);
    },
  },
});
</script>

<style lang="scss">
.matchingConfirmModal {
  .px-modal-message {
    top: -9px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }

  .pxEditPanel {
    padding-bottom: 10px;
  }

  .pxEditPanel + .pxEditPanel {
    margin-top: 24px;
  }

  .pxEditPanel.is-editing {
    background-color: white;
  }

  .pxEditPanel-editPane {
    width: 310px;
    margin-top: 8px;
  }

  .pxEditPanel-footer {
    padding-bottom: 6px;
    border-top: solid 1px $mischka;
  }

  .pxEditPanel-footerNotice {
    padding-bottom: 8px;
  }

  .locations-selector {
    margin-top: 10px;
  }

  .el-dialog__footer {
    .matchingConfirmModal-update-btn {
      @include breakpoint-up(sm) {
        padding: 0 15px;
        margin-right: 17px;
      }
    }

    span {
      font-size: 13px;
    }

    @include breakpoint-down(sm) {
      flex-wrap: wrap;
      gap: 10px;
    }

    @include breakpoint-up(sm) {
      display: flex;
      justify-content: center;
      padding-top: 25px;
    }
  }

  .matchingConfirmModal-title {
    @include grotesk(semiBold);

    margin-bottom: 20px;

    font-size: to-rem(18px);
    line-height: 28px;
    color: $ebony-clay;
    text-align: center;

    @include breakpoint-up(md) {
      margin-bottom: 26px;

      font-size: to-rem(20px);
    }
  }

  .matchingConfirmModal-tag {
    margin: 4px 8px 4px 0;
  }

  .matchingConfirmModal-option {
    color: $ebony-clay;

    .el-radio:not(:first-child) {
      margin-left: 20px;
    }
  }

  .matchingConfirmModal-submitBtn {
    width: 195px;

    @include breakpoint-up(sm) {
      position: relative;
      right: 1px;
    }
  }

  .rangeLevelCard {
    display: flex;
    padding-top: 3px;

    @include breakpoint-up(md) {
      align-items: center;
      padding-top: 2px;
    }
  }

  .rangeLevelCard-indicator {
    margin-right: 6px;
    margin-left: 3px;

    @include breakpoint-up(md) {
      margin-left: 1px;
    }
  }

  .rangeLevelCard-title {
    @include grotesk(medium);

    font-size: to-rem(16px);
    line-height: 23px;
    color: $ebony-clay;
  }

  .rangeLevelCard .icon {
    position: relative;
    top: 2px;
    display: inline-block;

    width: 16px;
    height: 15px;
    margin-left: 4px;
  }
}
</style>
