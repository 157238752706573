import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "matching-filters__toggle-wrapper" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { class: "matching-filters__wrapper" }
const _hoisted_5 = { class: "form-grid form-grid--centered" }
const _hoisted_6 = { class: "matching-filters__label" }
const _hoisted_7 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PxIcon = _resolveComponent("PxIcon")!
  const _component_ElInput = _resolveComponent("ElInput")!
  const _component_PxButton = _resolveComponent("PxButton")!
  const _component_ElTooltip = _resolveComponent("ElTooltip")!
  const _component_NetworksSelector = _resolveComponent("NetworksSelector")!
  const _component_ElFormItem = _resolveComponent("ElFormItem")!
  const _component_ElForm = _resolveComponent("ElForm")!
  const _component_PxFormModal = _resolveComponent("PxFormModal")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([{ 'matching-filters--closed': !_ctx.filtersOpened }, "matching-filters"])
  }, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.hasCompaniesSearch)
        ? (_openBlock(), _createBlock(_component_ElInput, {
            key: 0,
            modelValue: _ctx.filters.name.value,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.filters.name.value) = $event)),
            placeholder: _ctx.$t('matching.matchingList.companiesSelector'),
            class: "matching-filters__search",
            clearable: ""
          }, {
            prefix: _withCtx(() => [
              _createVNode(_component_PxIcon, {
                size: 24,
                name: "magnifier"
              })
            ]),
            _: 1
          }, 8, ["modelValue", "placeholder"]))
        : _createCommentVNode("", true),
      _createVNode(_component_PxButton, {
        ref: "toggleBtn",
        class: _normalizeClass([{ 'is-active': _ctx.filtersOpened, 'has-filters': _ctx.hasFilters }, "matching-filters__toggle-button"]),
        size: "small",
        type: "ghost-grey",
        onClick: _ctx.toggleFilters
      }, {
        default: _withCtx(() => [
          _createVNode(_component_PxIcon, {
            size: 16,
            class: "matching-filters__toggle-icon",
            name: "settings"
          }),
          (_ctx.$screen.mdUp)
            ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.filtersTitle), 1))
            : (_ctx.filtersLength)
              ? (_openBlock(), _createElementBlock("span", _hoisted_3, "(" + _toDisplayString(_ctx.filtersLength) + ")", 1))
              : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["class", "onClick"]),
      (_ctx.$user.isSupporter() && _ctx.$screen.mdDown)
        ? (_openBlock(), _createBlock(_component_PxButton, {
            key: 1,
            icon: _ctx.$options.static.editCriteriaButton.icon,
            size: _ctx.$options.static.editCriteriaButton.size,
            type: _ctx.$options.static.editCriteriaButton.type,
            variant: _ctx.$options.static.editCriteriaButton.variant,
            class: "matching-filters__toggle-button",
            onClick: _ctx.onClickEditCriteria
          }, null, 8, ["icon", "size", "type", "variant", "onClick"]))
        : _createCommentVNode("", true)
    ]),
    _withDirectives(_createElementVNode("div", _hoisted_4, [
      (_ctx.$screen.mdUp)
        ? (_openBlock(), _createBlock(_component_ElForm, {
            key: 0,
            ref: "filters",
            model: _ctx.filters
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_5, [
                _createVNode(_component_ElFormItem, { prop: "networks" }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_6, [
                      _createElementVNode("span", null, _toDisplayString(_ctx.$t(`matching.matchingFilters.networks.label`)), 1),
                      _createVNode(_component_ElTooltip, {
                        placement: "top",
                        "popper-class": "matching-filters__tooltip el-abaca-tooltip"
                      }, {
                        content: _withCtx(() => [
                          _createElementVNode("div", {
                            innerHTML: _ctx.$t('matching.matchingFilters.networks.tip')
                          }, null, 8, _hoisted_7)
                        ]),
                        default: _withCtx(() => [
                          _createVNode(_component_PxIcon, {
                            size: 16,
                            class: "matching-filters__tooltip-icon",
                            name: "question"
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _createVNode(_component_NetworksSelector, {
                      ref: "networkSelector",
                      modelValue: _ctx.filters.network.values,
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.filters.network.values) = $event)),
                      "no-label": true,
                      "no-location": true,
                      "placeholder-copy": 
                _ctx.$t(`matching.matchingFilters.networks.placeholder`)
              ,
                      "value-as-instance": true,
                      class: "matching-filters__selector"
                    }, null, 8, ["modelValue", "placeholder-copy"])
                  ]),
                  _: 1
                })
              ])
            ]),
            _: 1
          }, 8, ["model"]))
        : (_openBlock(), _createBlock(_component_PxFormModal, {
            key: 1,
            title: _ctx.filtersTitle,
            visibility: _ctx.filtersOpened,
            onClose: _ctx.toggleFilters
          }, {
            form: _withCtx(() => [
              _createVNode(_component_ElForm, {
                ref: "filters",
                model: _ctx.filters
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ElFormItem, {
                    label: _ctx.$t(`matching.matchingFilters.networks.label`),
                    prop: "networks"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_NetworksSelector, {
                        ref: "networkSelector",
                        modelValue: _ctx.filters.network.values,
                        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.filters.network.values) = $event)),
                        "is-modal": true,
                        "no-label": true,
                        "placeholder-copy": 
                  _ctx.$t(`matching.matchingFilters.networks.placeholder`)
                ,
                        "value-as-instance": true,
                        class: "matching-filters--mobile__selector"
                      }, null, 8, ["modelValue", "placeholder-copy"])
                    ]),
                    _: 1
                  }, 8, ["label"])
                ]),
                _: 1
              }, 8, ["model"])
            ]),
            _: 1
          }, 8, ["title", "visibility", "onClose"]))
    ], 512), [
      [_vShow, _ctx.filtersOpened]
    ])
  ], 2))
}