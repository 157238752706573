import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-dffc6506"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "affiliates-base page-full-height" }
const _hoisted_2 = {
  class: "page-full-height",
  "element-loading-background": "#fafafa"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AffiliateSelfAssessmentView = _resolveComponent("AffiliateSelfAssessmentView")!
  const _component_AffiliateQuestionBundleView = _resolveComponent("AffiliateQuestionBundleView")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_2, [
      (_ctx.ready)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            (_ctx.isSelfAssessmentAffiliateType)
              ? (_openBlock(), _createBlock(_component_AffiliateSelfAssessmentView, { key: 0 }))
              : _createCommentVNode("", true),
            (_ctx.isQuestionBundleAffiliateType)
              ? (_openBlock(), _createBlock(_component_AffiliateQuestionBundleView, { key: 1 }))
              : _createCommentVNode("", true)
          ], 64))
        : _createCommentVNode("", true)
    ])), [
      [_directive_loading, !_ctx.ready]
    ])
  ]))
}