<template>
  <div class="results-board">
    <div class="results-board__wrapper">
      <PxPanel class="results-board__panel px-panel--mobile-no-side-borders">
        <div v-if="$screen.smDown" class="results-board__panel-submit">
          <h2 class="results-board__headline-title">
            <PxIcon :size="18" name="check--white" />
            {{
              $t(`selfAssessment.results.resultsBoardCTA.pendingUser.headline`)
            }}
          </h2>
          <i18n-t
            class="results-board__headline-subtitle"
            keypath="selfAssessment.results.resultsBoardCTA.pendingUser.notice"
            tag="h4"
          >
            <template #company>
              <span>
                {{ affiliateName }}
              </span>
            </template>
          </i18n-t>
        </div>
        <div v-if="!hasError && finalLevel" class="results-board__container">
          <div class="results-board__score-wrapper">
            <PxIcon
              :name="`level-graph/${finalLevel.value}`"
              :size="173"
              class="results-board__level"
            />
          </div>
          <h2 class="results-board__title">
            {{ finalLevel.title }}
            <span class="results-board__tag-wrapper">
              <ElTag effect="plain" size="default" data-tag-type="grey">
                {{ $t("common.levelTag", { level: finalLevel.value }) }}
              </ElTag>
            </span>
          </h2>
          <div
            v-markdown="finalLevel.description"
            class="results-board__description"
          />
        </div>
      </PxPanel>
      <ResultsBoardPendingUser />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import { IAffiliate } from "@/services/data/affiliate/affiliate.interface";
import { ILevel } from "@/services/data/level/level.interface";
import { IViralLevelState } from "@/services/store/viral-level/viral-level-types";

import ResultsBoardPendingUser from "@/modules/affiliates/components/results-board/results-board-pending-user.vue";
import { IAffiliatesState } from "@/modules/affiliates/services/store/affiliates/affiliates.types";

export default defineComponent({
  name: "ResultsBoard",

  components: {
    ResultsBoardPendingUser,
  },

  computed: {
    viralStore(): IViralLevelState {
      return this.$store.state.viralLevel;
    },

    affiliates(): IAffiliatesState {
      return this.$store.state.affiliates;
    },

    hasError(): boolean {
      return !!this.viralStore.error || !!this.affiliates.error;
    },

    finalLevel(): ILevel {
      return this.viralStore.finalLevel as ILevel;
    },

    affiliate(): IAffiliate {
      return this.affiliates.affiliate as IAffiliate;
    },

    affiliateName(): string {
      return this.affiliate ? this.affiliate.name : "";
    },
  },
});
</script>

<style lang="scss" scoped>
.results-board__wrapper {
  display: flex;
  flex-flow: row wrap;

  @include breakpoint-up(lg) {
    display: grid;
    grid-template-columns: 488px 450px;
  }
}

.results-board .results-board__panel-submit {
  position: relative;
  display: flex;
  flex-flow: column wrap;
  flex-grow: 1;
  flex-shrink: 1;
  justify-content: center;

  padding: 0 0 0 16px;
  margin: -13px 15px 20px 16px;
  text-align: center;

  &::after {
    position: absolute;
    right: -999px;
    bottom: -9px;
    left: -999px;
    width: 100vw;
    height: 1px;
    margin: auto;

    content: "";
    background-color: rgba($malibu, 0.2);

    @include breakpoint-down(sm) {
      bottom: -14px;
      width: 290px;
    }
  }

  @include breakpoint-down(sm) {
    padding: 0;
    margin: -6px 0 33px 0;
  }

  .results-board__headline-title {
    @include grotesk(semiBold);

    margin-top: 19px;

    font-size: 17px;
    line-height: 32px;
    color: $fern;

    .px-icon {
      margin-right: 5px;
      vertical-align: -3px;
      background-color: $fern;
      border-radius: 100%;
    }
  }

  .results-board__headline-subtitle {
    @include grotesk(semiBold);

    font-size: 16px;
    line-height: 19px;
    color: $ebony-clay;

    span {
      @extend .results-board__headline-subtitle;

      display: inline-block;
      margin-top: auto;
      text-decoration: underline;
    }
  }
}

.results-board__panel {
  z-index: z("default");
  width: 100%;
  max-width: unset;

  @include breakpoint-up(lg) {
    height: 100%;
    min-height: 520px;
  }

  & :deep() .px-panel__body {
    padding: 2px 15px 17px;

    @include breakpoint-up(md) {
      padding: 23px 25px 37px;
    }
  }
}

.results-board .results-board__cta-panel {
  @include breakpoint-down(sm) {
    padding: 30px 0;
    margin: 0;
    border-top: none;
  }
}

.results-board :deep() .results-board__footer {
  @include breakpoint-down(sm) {
    margin-top: 36px;
  }
}

.results-board__backLink {
  margin: 0 0 6px 16px;

  &--out {
    @include breakpoint-down(md) {
      display: none;
    }
  }

  &--in {
    position: relative;
    top: -4px;
    margin: -10px 0 1px 0;

    @include breakpoint-up(md) {
      display: none;
    }
  }
}

.results-board__color-bar {
  margin: 0 -15px 21px;
}

.results-board__container {
  display: grid;
  grid-template-areas:
    "level title"
    "description description";
  grid-template-columns: 140px auto;
  grid-column-gap: 17px;

  @include breakpoint-up(sm) {
    display: block;
    text-align: center;
  }
}

.results-board__score-wrapper {
  grid-area: level;
  max-height: 146px;

  @include breakpoint-up(md) {
    max-height: unset;
  }

  @include breakpoint-up(lg) {
    grid-template-columns: 488px 465px;
  }
}

.results-board__tag-wrapper {
  @include breakpoint-down(sm) {
    display: block;
  }
}

.results-board__description {
  grid-area: description;

  @include breakpoint-up(md) {
    margin-top: 26px;
  }

  & :deep() p {
    font-size: 1rem;
    line-height: 25px;

    @include breakpoint-up(md) {
      font-size: 1.1333rem;
      line-height: 32px;
      letter-spacing: -0.2px;
    }
  }
}

.results-board__level {
  max-width: 140px;

  margin-right: 16px;

  @include breakpoint-down(sm) {
    position: relative;
    top: -24px;
    right: 1px;
  }

  @include breakpoint-up(md) {
    max-width: unset;
  }
}

.results-board-error {
  margin-top: -4px;

  & :deep() .px-global-error__icon {
    margin-bottom: 2px;
  }

  & :deep() .px-global-error__notice {
    padding-top: 1px;
    margin-bottom: 6px;

    font-size: 0.9333rem;
  }
}

.results-board :deep() {
  .results-board__title {
    grid-area: title;

    font-size: 1.2667rem;
    line-height: 26px;

    @include breakpoint-up(md) {
      margin-top: 1px;
      margin-bottom: 0;

      font-size: 1.6rem;
      line-height: 1.67;
    }

    .el-tag {
      margin: 7px 0 0 -1px;

      vertical-align: top;

      @include breakpoint-up(md) {
        margin: 7px 0 0 9px;
        line-height: 14px;
      }
    }
  }
}

.results-board :deep() .results-board__list {
  text-align: left;
  list-style-type: none;

  li {
    position: relative;
    padding-right: 5px;
    padding-left: 28px;
    font-size: 15px;
    line-height: 25px;
    color: $tundora;
    letter-spacing: -0.2px;

    @include breakpoint-up(md) {
      font-size: 17px;
      line-height: 30px;
      letter-spacing: -0.25px;
    }

    &::before {
      position: absolute;
      top: 5px;
      left: 2px;
      width: 14px;
      height: 14px;
      content: "";
      background-image: url("#{$assetsPath}/img/icons/check--blue.svg");
      background-repeat: no-repeat;
      background-size: contain;

      @include breakpoint-up(md) {
        top: 8px;
        left: 0;
        width: 16px;
        height: 14px;
      }
    }

    &:not(:first-child) {
      margin-top: 6px;

      @include breakpoint-up(md) {
        margin-top: 10px;
      }
    }
  }
}
</style>
