import {
  EProviderFeatures,
  GenericProvider,
  Provider,
} from "@/services/data/generic-provider";
import {
  ICapitalExplorerSubmission,
  ICapitalExplorerVisitorData,
} from "@/modules/capital-explorer/services/data/capital-explorer/capital-explorer.interface";
import { baseAPIUrl } from "@/services/utils/utils";
import { IMatchingResponse } from "@/services/data/matching-responses/matching-response.interface";

@Provider(`${baseAPIUrl}/capital-explorer/submission`, [
  EProviderFeatures.LIST,
  EProviderFeatures.GET,
  EProviderFeatures.PATCH,
])
class CapitalExplorerSubmissionProvider extends GenericProvider<
  ICapitalExplorerSubmission | ICapitalExplorerVisitorData,
  IMatchingResponse[]
> {}

export const capitalExplorerSubmissionProvider =
  new CapitalExplorerSubmissionProvider();
