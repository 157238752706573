/**
 * This function takes colors in hex format (i.e. #F06D06, with or without hash)
 * and lightens or darkens them with a value.
 *
 * @see https://css-tricks.com/snippets/javascript/lighten-darken-color/
 *
 * @param col Original color
 * @param amt Allows the specific amount of color variation.
 */
export const colorShade = (col: string, amt: number): string => {
  let usePound = false;

  if (col[0] === "#") {
    col = col.slice(1);
    usePound = true;
  }

  const num = parseInt(col, 16);

  let r = (num >> 16) + amt;

  if (r > 255) r = 255;
  else if (r < 0) r = 0;

  let b = ((num >> 8) & 0x00ff) + amt;

  if (b > 255) b = 255;
  else if (b < 0) b = 0;

  let g = (num & 0x0000ff) + amt;

  if (g > 255) g = 255;
  else if (g < 0) g = 0;

  return (usePound ? "#" : "") + (g | (b << 8) | (r << 16)).toString(16);
};
