import {
  EProviderFeatures,
  GenericProvider,
} from "@/services/data/generic-provider";

import { IAffiliateProgramSupporterSubmission } from "./affiliate-program-supporter-submission.interface";

class AffiliateProgramSupporterSubmissionProvider<
  T,
> extends GenericProvider<T> {
  constructor() {
    super(
      `${process.env.VUE_APP_API_URL}/affiliate/supporter/program/submissions`,
      [EProviderFeatures.CREATE, EProviderFeatures.GET],
    );
  }
}

export const affiliateProgramProgramSubmissionProvider =
  new AffiliateProgramSupporterSubmissionProvider<IAffiliateProgramSupporterSubmission>();
