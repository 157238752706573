<template>
  <ElDialog
    ref="modal"
    v-model="innerVisibility"
    :append-to-body="true"
    :destroy-on-close="true"
    :lock-scroll="true"
    :title="title"
    class="matching-badge-modal el-dialog--px-10"
  >
    <div
      v-for="(item, index) in items"
      :key="index"
      class="matching-badge-modal__row"
    >
      <div>
        <PxIcon
          :name="item.icon"
          :size="56"
          class="matching-badge-modal__badge-icon"
        />
      </div>
      <div>
        <p class="matching-badge-modal__title">
          {{ item.title }}
        </p>
        <p class="matching-badge-modal__description">
          {{ item.description }}
        </p>
        <p
          v-if="item.link"
          class="matching-badge-modal__link"
          @click="onLinkClick(item.link)"
        >
          {{ item.link.title }}
        </p>
      </div>
    </div>
  </ElDialog>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "MatchingBadgeModal",

  props: {
    visibility: {
      type: Boolean,
      required: true,
    },
    title: {
      type: String,
      required: true,
      default: "",
    },
    items: {
      type: Array as () => any[],
      required: true,
      default: () => [],
    },
  },

  data() {
    return {
      innerVisibility: false,
    };
  },

  watch: {
    visibility: {
      immediate: true,
      async handler(newVal: boolean) {
        this.innerVisibility = newVal;

        if (!newVal) {
          return;
        }
      },
    },

    innerVisibility(newVal: boolean, oldVal: boolean) {
      if (newVal === oldVal) {
        return;
      }

      if (!newVal) {
        this.$emit("update:visibility", newVal);
      }
    },
  },

  methods: {
    onLinkClick(link: any) {
      this.$router.push({ name: link.name, params: link.params });
    },
  },
});
</script>

<style lang="scss">
.matching-badge-modal.el-dialog {
  width: 465px;

  .el-dialog__header {
    padding: 19px 45px 18px 15px;

    @include breakpoint-up(md) {
      padding: 14px 60px 17px 31px;
    }
  }

  .el-dialog__body {
    padding: 22px 25px 30px;
  }
}

.matching-badge-modal__row {
  display: flex;
}

.matching-badge-modal .matching-badge-modal__row:not(:last-of-type) {
  margin-bottom: 30px;
}

.matching-badge-modal__badge-icon {
  margin-right: 12px;
}

.matching-badge-modal__title {
  @include grotesk(bold);

  font-size: 16px;
  color: $ebony-clay;
}

.matching-badge-modal__description {
  @include grotesk(regular);

  font-size: 14px;
  color: $ebony-clay;
}

.matching-badge-modal__link {
  @include grotesk(bold);

  font-size: 14px;
  color: $bluish;
  text-decoration: underline;
  cursor: pointer;
  transition: color 0.25s ease-in-out;

  &:hover {
    color: $malibu;
  }
}
</style>
