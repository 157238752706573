import {
  IDemographicDataCompany,
  IDemographicDataResponse,
} from "@/modules/charts-dashboard/services/data/lists-reports-data/lists-reports-data.interface";

export const countCompanies = (
  companies: IDemographicDataCompany[],
  label: string,
): number => {
  return companies.filter((company: IDemographicDataCompany) =>
    company.responses.every(
      (response: IDemographicDataResponse) =>
        response.value.toString().toLowerCase() === label.toLowerCase(),
    ),
  ).length;
};

export const getCompaniesCountForSpecificLabels = (
  companies: IDemographicDataCompany[],
  labels: string[],
): Record<string, number> => {
  const counts: Record<string, number> = {};

  labels.forEach((label) => {
    counts[label] = countCompanies(companies, label);
  });

  return counts;
};
