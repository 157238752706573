import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, Fragment as _Fragment, createTextVNode as _createTextVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "range-levels-modal__success"
}
const _hoisted_2 = { class: "range-levels-modal__success-title" }
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = { class: "range-levels-modal__header" }
const _hoisted_5 = { class: "range-levels-modal__title" }
const _hoisted_6 = { class: "range-levels-modal__subtitle" }
const _hoisted_7 = { class: "range-levels-modal__notice" }
const _hoisted_8 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PxIcon = _resolveComponent("PxIcon")!
  const _component_ElInput = _resolveComponent("ElInput")!
  const _component_ElFormItem = _resolveComponent("ElFormItem")!
  const _component_ElForm = _resolveComponent("ElForm")!
  const _component_PxButton = _resolveComponent("PxButton")!
  const _component_ElDialog = _resolveComponent("ElDialog")!

  return (_openBlock(), _createBlock(_component_ElDialog, {
    ref: "modal",
    modelValue: _ctx.innerVisibility,
    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.innerVisibility) = $event)),
    class: "el-dialog--no-header range-levels-modal",
    onClosed: _ctx.closedModalHandler
  }, {
    footer: _withCtx(() => [
      (!_ctx.submissionSuccessful)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _createVNode(_component_PxButton, {
              disabled: _ctx.isLoading,
              class: "range-levels-modal__cancel-button",
              type: "link",
              onClick: _ctx.cancelClickHandler
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t("common.cancel")), 1)
              ]),
              _: 1
            }, 8, ["disabled", "onClick"]),
            _createVNode(_component_PxButton, {
              disabled: _ctx.isSubmissionDisabled,
              loading: _ctx.isLoading,
              class: "range-levels-modal__submit-button",
              type: "green",
              onClick: _ctx.sendClickHandler
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t("common.send")), 1)
              ]),
              _: 1
            }, 8, ["disabled", "loading", "onClick"])
          ], 64))
        : _createCommentVNode("", true)
    ]),
    default: _withCtx(() => [
      (_ctx.submissionSuccessful)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(_component_PxIcon, {
              size: 90,
              class: "range-levels-modal__success-icon",
              name: "email-sent--orange"
            }),
            _createElementVNode("h3", _hoisted_2, _toDisplayString(_ctx.$t("supporters.assessment.rangeLevelsModal.success.title")), 1),
            _createElementVNode("p", {
              class: "range-levels-modal__success-message",
              innerHTML: 
          _ctx.$t(`supporters.assessment.rangeLevelsModal.success.message`, {
            email: _ctx.fields.email,
          })
        
            }, null, 8, _hoisted_3)
          ]))
        : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("h3", _hoisted_5, _toDisplayString(_ctx.$t("supporters.assessment.rangeLevelsModal.title")), 1),
              _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.$t("supporters.assessment.rangeLevelsModal.subtitle")), 1)
            ]),
            _createVNode(_component_ElForm, {
              ref: "form",
              model: _ctx.fields,
              rules: _ctx.rules,
              class: "viral-level-range-form",
              onValidate: _ctx.updateSubmitState
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ElFormItem, {
                  label: _ctx.$t('supporters.assessment.rangeLevelsModal.labels.range'),
                  prop: "viral_level_range"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ElInput, {
                      modelValue: _ctx.fields.viral_level_range,
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.fields.viral_level_range) = $event)),
                      placeholder: 
              _ctx.$t('supporters.assessment.rangeLevelsModal.placeholders.range')
            ,
                      type: "text"
                    }, null, 8, ["modelValue", "placeholder"])
                  ]),
                  _: 1
                }, 8, ["label"]),
                _createVNode(_component_ElFormItem, {
                  label: _ctx.$t('supporters.assessment.rangeLevelsModal.labels.email'),
                  prop: "email"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ElInput, {
                      modelValue: _ctx.fields.email,
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.fields.email) = $event)),
                      placeholder: 
              _ctx.$t('supporters.assessment.rangeLevelsModal.placeholders.email')
            ,
                      type: "text"
                    }, null, 8, ["modelValue", "placeholder"])
                  ]),
                  _: 1
                }, 8, ["label"])
              ]),
              _: 1
            }, 8, ["model", "rules", "onValidate"]),
            _createElementVNode("div", _hoisted_7, [
              _createVNode(_component_PxIcon, {
                size: 14,
                name: "information--light-gray"
              }),
              _createElementVNode("span", {
                innerHTML: _ctx.$t(`supporters.assessment.rangeLevelsModal.notice`)
              }, null, 8, _hoisted_8)
            ])
          ], 64))
    ]),
    _: 1
  }, 8, ["modelValue", "onClosed"]))
}