import { IBaseState } from "@/services/store/store-types";
import {
  IAffiliate,
  IAffiliateDraft,
} from "@/services/data/affiliate/affiliate.interface";
import { IPendingUser } from "@/services/store/viral-level/types/pending-user.interface";
import { IMatchingQuestion } from "@/services/data/matching-questionary/matching-question.interface";
import { IAffiliateSubmissions } from "@/modules/submissions/services/data/submissions/submissions.interface";

/**
 * Represents the store structure for the affiliates module
 */
export interface IAffiliatesState extends IBaseState {
  saving: boolean;
  selectedAssessmentCategory: number;
  selectedQuestion: number;
  currentStep: number;
  firstInteraction: boolean;
  pendingUser: IPendingUser | null;
  draftSubmission: IAffiliateDraft | null;
  userSubmissions: IAffiliateSubmissions | null;
  affiliate: IAffiliate | null;
  affiliateQuestionBundle: Array<IMatchingQuestion> | null;
  teamMembersQuestionBundle: Array<IMatchingQuestion> | null;
  userNavigatingFromReviewPage: boolean;
}

export enum EAffiliatesState {
  NAMESPACE = "affiliates",
}

export enum EAffiliatesActions {
  SET_SELECTED_ASSESSMENT_CATEGORY = "affiliates/setCurrentCategory",
  SET_SELECTED_QUESTION = "affiliates/setCurrentQuestion",
  SET_INTERACTION = "affiliates/setInteraction",
  SET_PENDING_USER = "affiliates/setPendingUser",
  SET_SUBMISSION_ASSESSMENT_LEVELS = "affiliates/setSubmissionAssessmentLevels",
  SET_SUBMISSION_RESPONSES = "affiliates/setSubmissionResponses",
  SET_SUBMISSION_TEAM_MEMBERS = "affiliates/setSubmissionTeamMembers",
  RESET = "affiliates/reset",
  FETCH_SUBMISSION = "affiliates/setDraftSubmission",
  FETCH_AFFILIATE = "affiliates/setAffiliate",
  FETCH_AFFILIATE_QUESTION_BUNDLES = "affiliates/fetchAffiliateQuestionBundles",
  SAVE_DRAFT = "affiliates/saveDraft",
  DEBOUNCE_SAVE_DRAFT = "affiliates/debounceSaveDraft",
  LOAD_AFFILIATE = "affiliates/loadAffiliate",
  LOAD_SUBMISSION = "affiliates/loadSubmission",
  PREFILL_ASSESSMENT = "affiliates/prefillAssessment",
  PREFILL_RESPONSES = "affiliates/prefillResponses",
  PREFILL_TEAM_MEMBERS = "affiliates/prefillTeamMembers",
  PREFILL_BLANK_SUBMISSION = "affiliates/prefillBlankSubmission",
  FETCH_USER_SUBMISSIONS = "affiliates/fetchUserSubmissions",
  SUBMIT = "affiliates/submit",
  SET_USER_NAVIGATING_FROM_REVIEW_PAGE = "affiliates/setUserNavigatingFromReviewPage",
}

export enum EAffiliatesMutations {
  SET_LOADING = "setLoading",
  SET_ERROR = "setError",
  SET_SAVING = "setSaving",
  SET_CURRENT_STEP = "setCurrentStep",
  SET_SELECTED_ASSESSMENT_CATEGORY = "setCurrentCategory",
  SET_SELECTED_QUESTION = "setCurrentQuestion",
  SET_INTERACTION = "setInteraction",
  SET_PENDING_USER = "setPendingUser",
  SET_DRAFT_SUBMISSION = "setDraftSubmission",
  SET_AFFILIATE = "setAffiliate",
  SET_AFFILIATE_PROGRAM_QUESTION_BUNDLE = "setAffiliateProgramQuestionBundle",
  SET_TEAM_MEMBERS_QUESTION_BUNDLE = "setTeamMembersQuestionBundle",
  SET_SUBMISSION_ASSESSMENT_LEVELS = "setSubmissionAssessmentLevels",
  SET_SUBMISSION_RESPONSES = "setSubmissionResponses",
  SET_SUBMISSION_TEAM_MEMBERS = "setSubmissionTeamMembers",
  SET_USER_SUBMISSIONS = "setUserSubmissions",
  SET_USER_NAVIGATING_FROM_REVIEW_PAGE = "setUserNavigatingFromReviewPage",
}

export enum EAffiliatesGetters {
  SELECTED_STEP_DATA = "affiliates/selectedCategory",
  CURRENT_CATEGORY = "affiliates/currentCategory",
  CURRENT_QUESTION = "affiliates/currentQuestion",
  SET_USER_NAVIGATING_FROM_REVIEW_PAGE = "affiliates/userNavigatingFromReviewPage",
}
