import { ActionTree } from "vuex";
import { RootState } from "@/services/store/root-state";
import { getBaseName } from "@/services/store/utils/get-base-name";
import { milestonesProvider } from "@/modules/milestone-planner/services/data/milestones/milestones.provider";
import {
  EAuthMilestonesActions,
  EAuthMilestonesMutations,
  IAuthMilestonesState,
} from "./auth-milestones.types";
import { parseMilestonesIntoGridCategories } from "@/modules/milestone-planner/services/utils/milestones-parser";
import {
  ICreateMilestonePayload,
  IMilestone,
  TUpdateMilestonePayload,
} from "@/modules/milestone-planner/services/data/milestones/milestone.interface";

export const actions: ActionTree<IAuthMilestonesState, RootState> = {
  /**
   * Get authenticated user's milestones.
   *
   * @param {*} { commit } Store context
   */
  async [getBaseName(EAuthMilestonesActions.FETCH)]({ commit }) {
    commit(EAuthMilestonesMutations.SET_LOADING, true);
    commit(EAuthMilestonesMutations.SET_ERROR, null);

    try {
      const data = await milestonesProvider.list();
      commit(EAuthMilestonesMutations.SET_DATA, data);
    } catch (error) {
      commit(EAuthMilestonesMutations.SET_ERROR, error);
      commit(EAuthMilestonesMutations.SET_DATA, null);
    } finally {
      commit(EAuthMilestonesMutations.SET_LOADING, false);
    }
  },

  /**
   * Create a milestone of the authenticated user.
   *
   * @param {*} { commit } Store context
   * @param payload Payload with the milestone data for creation.
   */
  async [getBaseName(EAuthMilestonesActions.CREATE)](
    { state, commit, dispatch },
    payload: ICreateMilestonePayload,
  ) {
    commit(EAuthMilestonesMutations.SET_LOADING, true);
    commit(EAuthMilestonesMutations.SET_ERROR, null);

    try {
      const createdMilestone = await milestonesProvider.create(payload);
      const dataToRefresh = state.data.filter(
        (value) =>
          value.category_level.id !== createdMilestone.category_level.id,
      );
      commit(EAuthMilestonesMutations.SET_DATA, [
        ...dataToRefresh,
        createdMilestone,
      ]);
      dispatch(EAuthMilestonesActions.PARSE_DATA, {}, { root: true });
    } catch (error) {
      commit(EAuthMilestonesMutations.SET_ERROR, error);
    } finally {
      commit(EAuthMilestonesMutations.SET_LOADING, false);
    }
  },

  /**
   * Update a milestone of the authenticated user.
   *
   * @param {*} { commit } Store context
   * @param payload Payload with the milestone data for update.
   */
  async [getBaseName(EAuthMilestonesActions.PATCH)](
    { state, commit, dispatch },
    {
      milestoneToUpdate,
      payload,
    }: { milestoneToUpdate: IMilestone; payload: TUpdateMilestonePayload },
  ) {
    commit(EAuthMilestonesMutations.SET_LOADING, true);
    commit(EAuthMilestonesMutations.SET_ERROR, null);

    try {
      const updatedMilestone = await milestonesProvider.patch(
        milestoneToUpdate.uid,
        payload,
      );
      const dataToRefresh = state.data.filter(
        (value) =>
          value.category_level.id !== updatedMilestone.category_level.id,
      );
      commit(EAuthMilestonesMutations.SET_DATA, [
        ...dataToRefresh,
        updatedMilestone,
      ]);
      dispatch(EAuthMilestonesActions.PARSE_DATA, {}, { root: true });
    } catch (error) {
      commit(EAuthMilestonesMutations.SET_ERROR, error);
    } finally {
      commit(EAuthMilestonesMutations.SET_LOADING, false);
    }
  },

  [getBaseName(EAuthMilestonesActions.PARSE_DATA)]({
    commit,
    state,
    rootState,
  }) {
    const categories = (rootState as any).categories.data || [];
    const milestones = state.data || [];
    const parsedMilestones = parseMilestonesIntoGridCategories(
      milestones,
      categories,
    );
    commit(EAuthMilestonesMutations.SET_PARSED_DATA, parsedMilestones);
  },

  async [getBaseName(EAuthMilestonesActions.FETCH_PARSED_DATA)]({ dispatch }) {
    await dispatch(EAuthMilestonesActions.FETCH, {}, { root: true });
    dispatch(EAuthMilestonesActions.PARSE_DATA, {}, { root: true });
  },

  /**
   * Delete a milestone.
   *
   * @param {*} { state, commit, dispatch }
   * @param {{ milestoneToDelete: IMilestone }} { milestoneToDelete }
   */
  async [getBaseName(EAuthMilestonesActions.DESTROY)](
    { state, commit, dispatch },
    { milestoneToDelete }: { milestoneToDelete: IMilestone },
  ) {
    commit(EAuthMilestonesMutations.SET_LOADING, true);
    commit(EAuthMilestonesMutations.SET_ERROR, null);

    try {
      await milestonesProvider.destroy(milestoneToDelete.uid);

      const updatedData = state.data.filter(
        (milestone) =>
          milestone.category_level.id !== milestoneToDelete.category_level.id,
      );

      commit(EAuthMilestonesMutations.SET_DATA, updatedData);
      dispatch(EAuthMilestonesActions.PARSE_DATA, {}, { root: true });
    } catch (error) {
      commit(EAuthMilestonesMutations.SET_ERROR, error);
    } finally {
      commit(EAuthMilestonesMutations.SET_LOADING, false);
    }
  },
};
