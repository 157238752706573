<template>
  <div class="profile-content--supporter">
    <div class="info-item info-item--md-half">
      <div class="profile-content-row profile-content__tags">
        <h4 class="profile-content__caption">
          {{ supporterSectorsTitle }}
        </h4>
        <div
          v-for="(sector, index) in limitVisible(supporterSectorsOfInterest)"
          :key="index"
          class="profile-content__tags-wrapper"
        >
          <ElTag effect="plain" class="text--capitalize">
            {{ sector.name }}
            <span v-if="sector.items" class="el-tag__subtitle">
              ({{ sector.items.length }})
            </span>
          </ElTag>
        </div>
        <div v-if="hasMore(supporterSectorsOfInterest)">
          <PxMoreList
            :modal-title="supporterSectorsTitle"
            :values="getListOfNamesWithCount(supporterSectorsOfInterest)"
            :values-item-list="supporterSectorsOfInterest"
          >
            <template #content>
              <PxButton
                class="el-button--link el-button--small el-button--link-dark"
                type="link"
              >
                {{
                  $t("common.seeAll", {
                    number: getTotal(supporterSectorsOfInterest),
                  })
                }}
              </PxButton>
            </template>
          </PxMoreList>
        </div>
        <div
          v-if="!supporterSectorsOfInterest.length"
          class="profile-content__label-wrapper"
        >
          <template v-if="$user.isOwner()">
            <span>
              {{ $t("profile.company.emptyState.sectorsOfInterest[0]") }}
            </span>
            <PxButton
              class="el-button--link el-button--link-blue"
              type="link"
              @click="onClickFieldHandler({ view: 'interests' })"
            >
              {{ $t("profile.company.emptyState.sectorsOfInterest[1]") }}
            </PxButton>
          </template>
          <template v-else-if="hasUserAccess">
            <span>
              {{ $t("profile.company.emptyState.sectorsOfInterest[2]") }}
            </span>
          </template>
        </div>
      </div>
      <div class="profile-content-row profile-content__tags">
        <h4 class="profile-content__caption">
          {{ supporterLocationsTitle }}
        </h4>
        <div
          v-for="(location, index) in limitVisible(
            supporterLocationsOfInterest,
          )"
          :key="index"
          class="profile-content__tags-wrapper"
        >
          <ElTag effect="plain" class="text--capitalize">
            {{ location.name }}
            <span v-if="location.items" class="el-tag__subtitle">
              ({{ location.items.length }})
            </span>
          </ElTag>
        </div>
        <div v-if="hasMore(supporterLocationsOfInterest)">
          <PxMoreList
            :modal-title="supporterLocationsTitle"
            :values="getListOfNamesWithCount(supporterLocationsOfInterest)"
            :values-item-list="supporterLocationsOfInterest"
          >
            <template #content>
              <PxButton
                class="el-button--link el-button--small el-button--link-dark"
                type="link"
              >
                {{
                  $t("common.seeAll", {
                    number: getTotal(supporterLocationsOfInterest),
                  })
                }}
              </PxButton>
            </template>
          </PxMoreList>
        </div>
        <div
          v-if="!supporterLocationsOfInterest.length"
          class="profile-content__label-wrapper"
        >
          <template v-if="$user.isOwner()">
            <span>
              {{ $t("profile.company.emptyState.locationsOfInterest[0]") }}
            </span>
            <PxButton
              class="el-button--link el-button--link-blue"
              type="link"
              @click="onClickFieldHandler({ view: 'interests' })"
            >
              {{ $t("profile.company.emptyState.locationsOfInterest[1]") }}
            </PxButton>
          </template>
          <template v-else-if="hasUserAccess">
            <span>
              {{ $t("profile.company.emptyState.locationsOfInterest[2]") }}
            </span>
          </template>
        </div>
      </div>
    </div>
    <div class="info-item info-item--md-half">
      <div class="profile-content-row">
        <h4 class="profile-content__caption">
          {{ $t("profile.company.supporterTypes") }}
        </h4>
        <div class="profile-content__data">
          {{ supporterTypes }}
          <span
            v-if="!supporterTypes && !$user.isOwner()"
            class="profile-content__empty-data"
          >
            {{ $t("profile.company.emptyState.visitor") }}
          </span>
        </div>
      </div>
      <div class="profile-content-row">
        <h4 class="profile-content__caption">
          {{ $t("profile.company.about") }}
        </h4>
        <div class="profile-content__data">
          {{ company.about }}
          <PxButton
            v-if="!company.about && $user.isOwner()"
            class="el-button--link el-button--link-blue"
            type="link"
            @click="onClickFieldHandler({ view: 'general', field: 'about' })"
          >
            {{ $t("profile.company.emptyState.about") }}
          </PxButton>
          <span
            v-if="!company.about && !$user.isOwner()"
            class="profile-content__empty-data"
          >
            {{ $t("profile.company.emptyState.visitor") }}
          </span>
        </div>
      </div>
      <div class="profile-content-row">
        <h4 class="profile-content__caption">
          {{ $t("profile.company.website") }}
        </h4>
        <div class="profile-content__data">
          <a
            v-if="company.website"
            :href="companyWebsite"
            class="el-button el-button--link el-button--link-dark"
            target="_blank"
          >
            <span>
              {{ company.website }}
            </span>
          </a>
          <PxButton
            v-else-if="$user.isOwner()"
            class="el-button--link el-button--link-blue"
            type="link"
            @click="onClickFieldHandler({ view: 'general', field: 'website' })"
          >
            {{ $t("profile.company.emptyState.website") }}
          </PxButton>
          <span v-else class="profile-content__empty-data">
            {{ $t("profile.company.emptyState.visitor") }}
          </span>
        </div>
      </div>
      <div v-if="hasUserAccess" class="profile-content-row">
        <h4 class="profile-content__caption">
          {{ $t("profile.company.email") }}
          <ElTooltip
            v-if="$user.isOwner()"
            placement="top"
            popper-class="profile-content__caption-tooltip el-abaca-tooltip"
          >
            <template #content>
              <div v-text="$t(`profile.company.tooltip.emailVisibility`)" />
            </template>
            <i class="icon icon-question" />
          </ElTooltip>
        </h4>
        <div class="profile-content__data">
          <a
            v-if="canShowEmail"
            :href="`mailto:${company.email}`"
            class="el-button el-button--link el-button--link-dark"
          >
            <span>
              {{ company.email }}
            </span>
          </a>
          <PxButton
            v-else-if="$user.isOwner()"
            class="el-button--link el-button--link-blue"
            type="link"
            @click="onClickFieldHandler({ view: 'general', field: 'email' })"
          >
            {{ $t("profile.company.emptyState.email") }}
          </PxButton>
          <PxButton
            v-else-if="!hasUserAccess"
            class="el-button--link el-button--link-green"
            type="link"
            @click="onClickSignUp"
          >
            {{ $t("profile.company.visitorState.email") }}
          </PxButton>
          <div v-else-if="userCanMatch" class="profile-content__hidden-email">
            <p class="profile-content__hidden-email-title">
              <PxIcon :name="'icon-password'" :size="12" />
              {{
                $t(
                  `profile.company.hiddenEmail[${
                    userIsInterested ? 1 : 0
                  }].title`,
                  { name: company.name },
                )
              }}
            </p>
            <p class="profile-content__hidden-email-subtitle">
              {{
                $t(
                  `profile.company.hiddenEmail[${
                    userIsInterested ? 1 : 0
                  }].subtitle`,
                )
              }}
            </p>
          </div>
          <span v-else class="profile-content__empty-data">
            {{ $t("profile.company.emptyState.visitor") }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { ISupporter } from "@/modules/matching/services/data/matching-score/supporter.interface";
import { ROUTE_AUTHENTICATION_SIGNUP } from "@/modules/authentication/services/router/routes-names";
import { ISupporterType } from "@/modules/supporters/services/data/supporter-type/supporter-type.interface";
import { IMoreListItem } from "@/components/px-more-list/px-more-list.vue";

export default defineComponent({
  name: "CompanyPanelSupporter",

  props: {
    company: {
      type: Object as () => any,
      required: true,
    },
    userCanMatch: {
      type: Boolean,
      default: false,
    },
    userIsConnected: {
      type: Boolean,
      default: false,
    },
    userIsInterested: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      tagsLimit: 3,
      tooltipLimit: 8,
    };
  },

  computed: {
    isSpecialGuest(): boolean {
      return !!this.$route.meta?.specialGuest;
    },

    hasUserAccess(): boolean {
      return this.$user.isLogged() || this.isSpecialGuest;
    },

    supporter(): ISupporter | null {
      return this.$store.get("profile/supporter/data");
    },

    supporterSectorsTitle(): string {
      return this.$t("profile.company.sectorsOfInterest");
    },

    /**
     * Map supporter sectors and grouped sectors
     * for company panel tags and px-more-list.
     */
    supporterSectorsOfInterest(): Array<IMoreListItem> {
      const tags = [];

      if (this.supporter && this.supporter.sectors) {
        const sectorTags = this.supporter.sectors.map((sector) => ({
          name: sector.name,
        }));
        tags.push(...sectorTags);
      }

      if (this.supporter && this.supporter.grouped_sectors) {
        let sectorGroupedTags: Array<IMoreListItem> = [];

        // 1 - Check if all sub-sectors combined total, is greater than the tooltip limit
        const needsToGroupSectors =
          this.supporter.grouped_sectors.reduce(
            (totalSectors, grouped_sector) =>
              totalSectors + grouped_sector.sectors.length,
            0,
          ) > this.tooltipLimit;

        // 1.1 - If not, sub-sectors will appear as independent sectors
        if (!needsToGroupSectors) {
          this.supporter.grouped_sectors.forEach((grouped_sector) => {
            if (grouped_sector.sectors.length) {
              const sectorTags = grouped_sector.sectors.map((sector) => ({
                name: sector.name,
              }));
              sectorGroupedTags.push(...sectorTags);
            }
          });
        } else {
          // 1.2 - Otherwise, sub-sectors will appear grouped on each sector group
          sectorGroupedTags = this.supporter.grouped_sectors.map(
            (grouped_sector) => {
              if (grouped_sector.sectors.length > 1) {
                return {
                  name: grouped_sector.group.name,
                  items: grouped_sector.sectors.map((sector) => sector.name),
                };
              } else {
                return { name: grouped_sector.sectors[0].name };
              }
            },
          );
        }

        tags.push(...sectorGroupedTags);
      }

      return tags;
    },

    supporterLocationsTitle(): string {
      return this.$t("profile.company.locationsOfInterest");
    },

    /**
     * Map supporter locations and grouped locations
     * for company panel tags and px-more-list.
     */
    supporterLocationsOfInterest(): Array<IMoreListItem> {
      const tags = [];

      if (this.supporter && this.supporter.locations) {
        const locationTags = this.supporter.locations.map((location) => ({
          name: location.formatted_address,
        }));
        tags.push(...locationTags);
      }

      if (this.supporter && this.supporter.grouped_locations) {
        let locationGroupedTags: Array<IMoreListItem> = [];

        // 1 - Check if all sub-locations combined total, is greater than the tooltip limit
        const needsToGroupLocations =
          this.supporter.grouped_locations.reduce(
            (totalLoctions, grouped_location) =>
              totalLoctions + grouped_location.locations.length,
            0,
          ) > this.tooltipLimit;

        // 1.1 - If not, sub-locations will appear as independent locations
        if (!needsToGroupLocations) {
          this.supporter.grouped_locations.forEach((grouped_location) => {
            if (grouped_location.locations.length) {
              const locationTags = grouped_location.locations.map(
                (location) => ({
                  name: location.formatted_address,
                }),
              );
              locationGroupedTags.push(...locationTags);
            }
          });
        } else {
          // 1.2 - Otherwise, sub-location will appear grouped on each location group
          locationGroupedTags = this.supporter.grouped_locations.map(
            (grouped_location) => {
              if (grouped_location.locations.length > 1) {
                return {
                  name: grouped_location.group.name,
                  items: grouped_location.locations.map(
                    (location) => location.formatted_address,
                  ),
                };
              } else {
                return {
                  name: grouped_location.locations[0].formatted_address,
                };
              }
            },
          );
        }

        tags.push(...locationGroupedTags);
      }

      return tags;
    },

    supporterTypes(): string {
      const types = this.supporter ? this.supporter.types : [];
      return types.length
        ? types.map((type: ISupporterType) => type.name).join(", ")
        : "";
    },

    canShowEmail(): boolean {
      const hasEmail = this.company.email || null;
      const hasPermission =
        this.$user.isOwner() ||
        (this.$user.isEntrepreneur() && this.userIsConnected);

      return hasEmail && hasPermission;
    },

    companyWebsite(): string {
      const companyWebsite = this.company.website || "";

      const pattern =
        /(ftp|http|https):\/\/(\w+:?\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@\-/]))?/;

      return pattern.test(companyWebsite)
        ? companyWebsite
        : `//${companyWebsite}`;
    },
  },

  methods: {
    limitVisible(data: Array<any>) {
      return data.slice(0, this.tagsLimit);
    },

    getRemaining(data: Array<any>, limit: number) {
      return data.length - limit;
    },

    /**
     * Sum all items with sub-items (if they exist).
     */
    getTotal(items: Array<IMoreListItem>): number {
      return items.reduce((count, item) => {
        // Count own item
        count++;

        // Count group children if they exist
        if ("items" in item && !!item.items && item.items.length) {
          count += item.items.length;
        }

        return count;
      }, 0);
    },

    /**
     * Check if there are more items (including sub-items)
     * than the visible tags limit.
     */
    hasMore(items: Array<IMoreListItem>, limit?: number): boolean {
      limit = limit || this.tagsLimit;

      return (
        items.length > limit ||
        // Check if there are grouped items
        items.some((item) => {
          return "items" in item && !!item.items && item.items.length;
        })
      );
    },

    /**
     * Map list of item instances to
     * simple string list with names
     * for the px-more-list values.
     */
    getListOfNamesWithCount(items: Array<IMoreListItem>): Array<string> {
      return items.map((item) => {
        if ("items" in item && !!item.items && item.items.length) {
          return `${item.name} (${item.items.length})`;
        }

        return item.name;
      });
    },

    async onClickFieldHandler(options: { field?: string; view?: string }) {
      if ("view" in options) {
        this.$emit("show-modal-view", options.view);
      }

      if ("field" in options) {
        await this.$nextTick();
        this.$emit("focus-modal-field", options.field);
      }
    },

    onClickSignUp() {
      this.$router.push({ name: ROUTE_AUTHENTICATION_SIGNUP });
    },
  },
});
</script>

<style lang="scss" scoped>
.profile-content--supporter {
  display: flex;
  flex-direction: column;

  @include breakpoint-up(md) {
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
  }
}

.profile-content__caption {
  @include grotesk(semiBold);

  margin: 0;
  font-size: to-rem(13px);
  color: $manatee;
  text-transform: uppercase;

  @include breakpoint-up(md) {
    line-height: 16px;
  }
}

.profile-content__data {
  @include grotesk(regular);

  margin-top: 7px;
  font-size: to-rem(15px);
  line-height: 24px;
  letter-spacing: -0.2px;
  word-break: break-word;
  word-wrap: break-word;

  @include breakpoint-up(md) {
    line-height: 25px;
  }
}

.profile-content__empty-data {
  @include grotesk(regular, italic);

  margin-top: 7px;
  font-size: to-rem(15px);
  line-height: 25px;
  letter-spacing: -0.2px;
}

.profile-content-row:not(:first-child) {
  margin-top: 31px;
}

.profile-content__tags {
  @include breakpoint-up(md) {
    padding-right: 20px;
  }
}

.profile-content__tags .profile-content__caption {
  & + .profile-content__tags-wrapper {
    margin-top: 12px;

    @include breakpoint-up(md) {
      margin-top: 14px;
    }
  }

  & + .profile-content__label-wrapper {
    margin-top: 7px;
  }
}

.profile-content__tags .el-tag {
  margin-right: 10px;
  white-space: inherit;
}

.profile-content__tags div:not(:last-child) .el-tag {
  margin-bottom: 13px;
}

.profile-content__tags .el-button {
  height: auto;
  padding: 3px 0;
}

.profile-content__label-wrapper span + .el-button {
  margin-left: 10px;
}

.info-item {
  margin-bottom: 26px;

  @include breakpoint-up(md) {
    flex-shrink: 0;
  }
}

.info-item--md-half {
  @include breakpoint-up(md) {
    flex-grow: 0;
    width: 284px;
    margin-bottom: 0;
  }
}

.profile-content__hidden-email {
  width: 254px;
  padding: 8px 10px;
  background-color: rgba($golden, 0.05);
  border: solid 1px rgba($golden, 0.31);
  border-radius: 4px;

  &-title {
    @include grotesk(semiBold);

    font-size: 15px;
    line-height: 1.47;
    color: $golden;
  }

  &-subtitle {
    font-size: 14px;
    line-height: 1.57;
    color: rgba($ebony-clay, 0.7);
  }
}
</style>
