import { activeModules } from "@/services/utils/utils";

/**
 * Init Modules.
 */
export const initModules = async () => {
  for (const moduleName of activeModules()) {
    try {
      await import(`@/modules/${moduleName}/manifest.ts`);
    } catch (error) {
      // Do nothing! This means the module doesn't have a manifest file.
    }
  }
};
