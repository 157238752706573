import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-2b69d647"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "submission-panel__supporter-contacts" }
const _hoisted_2 = { class: "submission-panel-row" }
const _hoisted_3 = { class: "submission-panel-caption" }
const _hoisted_4 = { class: "submission-panel-data" }
const _hoisted_5 = {
  key: 0,
  class: "submission-panel-data__field--empty"
}
const _hoisted_6 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h4", _hoisted_3, _toDisplayString(_ctx.$t("profile.company.email")), 1),
      _createElementVNode("div", _hoisted_4, [
        (!_ctx.hasEmail)
          ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_ctx.$t("program.submission.company.emptyState")), 1))
          : (_openBlock(), _createElementBlock("a", {
              key: 1,
              href: `mailto:${_ctx.email}`,
              class: "el-button el-button--link el-button--link-dark"
            }, [
              _createElementVNode("span", null, _toDisplayString(_ctx.email), 1)
            ], 8, _hoisted_6))
      ])
    ])
  ]))
}