<template>
  <div :class="{ 'px-avatar-set--round': round }" class="px-avatar-set">
    <div
      v-for="(member, index) in visibleItems"
      :key="index"
      :style="itemDimensions"
      class="px-avatar-set__item"
      @click="$emit('click', member)"
    >
      <PxAvatar :name="member.name" :photo="member.logo" :size="size" />
    </div>
    <div
      v-if="hasItemsOverLimit"
      :style="itemDimensions"
      class="px-avatar-set__item px-avatar-set__item--count"
      v-text="`+${remainingItemsCount}`"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export interface IAvatarSetItem {
  name: string;
  logo: string;
  id?: number;
}

export default defineComponent({
  name: "PxAvatarSet",

  props: {
    // By default, having zero will show all items.
    visibleLimit: {
      type: Number,
      default: 0,
    },

    items: {
      type: Array as () => Array<IAvatarSetItem>,
      required: true,
    },

    size: {
      type: [Number, String],
      required: true,
    },

    round: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    itemDimensions(): { [key: string]: string } {
      return { width: `${this.size}px`, height: `${this.size}px` };
    },

    itemsLimit(): number {
      return this.visibleLimit || this.items.length;
    },

    hasItemsOverLimit(): boolean {
      return this.items.length > this.itemsLimit;
    },

    amountOfAvatarsToShow(): number {
      // If limit is reached, we need to leave space (- 1) to show the remaining items count.
      return this.hasItemsOverLimit ? this.itemsLimit - 1 : this.itemsLimit;
    },

    visibleItems(): Array<IAvatarSetItem> {
      return this.items.slice(0, this.amountOfAvatarsToShow);
    },

    remainingItemsCount(): number {
      return this.items.length - this.visibleItems.length;
    },
  },
});
</script>

<style lang="scss" scoped>
.px-avatar-set {
  display: flex;
  flex-flow: row wrap;
}

.px-avatar-set__item {
  overflow: hidden;
  border-radius: 1.5px;
  box-shadow: inset 0 0 2px 0 rgba(139, 143, 161, 0.3);

  &:not(:first-child) {
    z-index: z("default");
    margin-left: -9px;
  }
}

.px-avatar-set__item--count {
  @include grotesk(semiBold);

  display: flex;
  align-items: center;
  justify-content: center;

  overflow: visible;
  font-size: 12px;
  line-height: 25px;
  color: $manatee;
  text-align: center;
  // For pixel-perfect alignment:
  text-indent: 5px;
  background-color: $white;
  box-shadow: none;
}

.px-avatar-set__item :deep() img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.px-avatar-set--round .px-avatar-set__item {
  box-sizing: content-box;
  border: solid 3px $white;
  border-radius: 100%;
}
</style>
