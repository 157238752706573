import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "level-slider__labels" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ElSlider = _resolveComponent("ElSlider")!

  return (_openBlock(), _createElementBlock("div", {
    style: _normalizeStyle({ height: _ctx.height }),
    class: "level-slider"
  }, [
    _createVNode(_component_ElSlider, {
      ref: "slider",
      modelValue: _ctx.innerValue,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.innerValue) = $event)),
      max: _ctx.levels,
      min: 0,
      "show-tooltip": false,
      step: 1,
      class: "level-slider__inner",
      height: "358px",
      "show-stops": "",
      vertical: ""
    }, null, 8, ["modelValue", "max"]),
    _createElementVNode("div", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.stops, (item, index) => {
        return (_openBlock(), _createElementBlock(_Fragment, { key: item }, [
          (index !== 0)
            ? (_openBlock(), _createElementBlock("div", {
                key: item,
                class: _normalizeClass([{ 'is-selected': index === _ctx.innerValue }, "level-slider__level-label"]),
                style: _normalizeStyle({ bottom: item + '%' })
              }, _toDisplayString(index), 7))
            : _createCommentVNode("", true),
          _createElementVNode("div", {
            class: _normalizeClass([{ 'is-active': index <= _ctx.innerValue }, "level-slider__stop"]),
            style: _normalizeStyle({ bottom: item + '%' })
          }, null, 6)
        ], 64))
      }), 128))
    ])
  ], 4))
}