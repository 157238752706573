<template>
  <div class="pxQuestionaryNumeric">
    <div class="pxQuestionaryNumeric-fieldWrapper">
      <PxNumeric v-model="innerValue.value" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "PxQuestionaryNumeric",

  props: {
    modelValue: {
      type: Object as () => any,
      default: () => null,
    },

    isValid: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      innerValue: { value: "" },
    };
  },

  watch: {
    modelValue: {
      immediate: true,
      deep: true,
      handler(newVal) {
        if (newVal) {
          const currentValue =
            this.innerValue.value === ""
              ? ""
              : parseFloat(this.innerValue.value.replace(/,/g, ""));

          if (newVal.value !== currentValue) {
            this.innerValue = this.modelValue;
          }
        }
      },
    },

    innerValue: {
      deep: true,
      handler(newVal) {
        const newValues = {
          value:
            newVal.value === ""
              ? ""
              : parseFloat(newVal.value.replace(/,/g, "")),
        };
        this.emitValueChanges(newValues);
        this.validateField(newValues);
      },
    },
  },

  methods: {
    /**
     * Emit value changes to the parent component.
     */
    emitValueChanges(values: any) {
      this.$emit("update:modelValue", values);
      this.$emit("change", values);
    },

    validateField(values: any) {
      const isValid = values.value !== null && !!values.value;

      this.$emit("update:isValid", isValid);
    },
  },
});
</script>

<style lang="scss" scoped>
.pxQuestionaryNumeric-label {
  @include grotesk(semiBold);

  display: block;
  margin-bottom: 1px;

  font-size: to-rem(15px);
}

.pxQuestionaryNumeric-fieldWrapper--two {
  margin-top: 10px;
}
</style>
