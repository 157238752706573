<template>
  <div class="profile-panel__row assessment-history">
    <div class="profile-headline">
      <h2 class="profile-headline__title">
        {{ $t("profile.assessment.historyPanel.title") }}
      </h2>
    </div>
    <div class="assessment-timeline">
      <img
        v-if="$screen.lgUp"
        class="assessment-timeline__image-placeholder"
        src="/img/placeholders/timeline.svg"
      />
      <img
        v-else
        class="assessment-timeline__image-placeholder"
        src="/img/placeholders/timeline-mobile.svg"
      />
      <div class="assessment-timeline__content-placeholder">
        <ContentPlaceholdersText :lines="3" />
        <ContentPlaceholdersText :lines="2" />
        <ContentPlaceholdersText :lines="2" />
        <ContentPlaceholdersText :lines="2" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "AssessmentPanelHistoryInactive",
});
</script>

<style lang="scss" scoped>
.assessment-history {
  margin-right: 2px;
  margin-left: 4px;
}

.assessment-timeline {
  display: flex;
  flex-flow: row wrap;
  padding-bottom: 12px;
  margin-top: 19px;

  @include breakpoint-up(md) {
    margin-top: 26px;
  }
}

.assessment-timeline__image-placeholder {
  position: relative;
  left: -15px;
  flex-basis: 44px;
}

.assessment-timeline__content-placeholder {
  position: relative;
  left: -10px;
  flex: 1 1 0;
  padding-top: 17px;
}

:deep() .vue-content-placeholders-text:not(:first-child) {
  margin-top: 33px;
}

:deep() .vue-content-placeholders-text:not(:last-child) {
  position: relative;
  padding-bottom: 30px;

  @include breakpoint-up(md) {
    padding-bottom: 0;
  }

  &::after {
    position: absolute;
    bottom: 0;
    left: 0;
    width: calc(100vw - 53px);
    height: 1px;
    content: "";
    background-color: rgba($manatee, 0.1);

    @include breakpoint-up(md) {
      display: none;
    }
  }
}

:deep() .vue-content-placeholders-text__line {
  width: 90%;
  height: 6px;
  margin-bottom: 0;
  margin-left: 10px;
  background-color: #f4f4f5;
  border-radius: 5.5px;
  opacity: 0.7;

  @include breakpoint-up(md) {
    width: 74%;
  }

  &:not(:first-child) {
    margin-top: 18px;
  }

  &:nth-child(2) {
    margin-top: 20px;
  }

  &:first-child {
    width: 100%;
    margin-left: 0;
    background-color: #d8d8d8;
    border-bottom: solid 2px transparent;

    @include breakpoint-up(md) {
      width: 87%;
    }
  }
}
</style>
