import { Module } from "vuex";
import { make } from "vuex-pathify";
import cloneDeep from "lodash/cloneDeep";

import { RootState } from "@/services/store/root-state";

import { IProfileCompanyState } from "./company.types";
import { companyActions } from "./company.actions";

const state: IProfileCompanyState = {
  loading: false,
  error: null,
  data: null,
};

export const companyModuleFactory = (): Module<
  IProfileCompanyState,
  RootState
> => {
  return {
    namespaced: true,
    state: cloneDeep(state),
    actions: companyActions,
    mutations: make.mutations(state),
    getters: make.getters(state),
  };
};
