<template>
  <ElDialog
    ref="modal"
    v-model="innerVisibility"
    :append-to-body="true"
    :before-close="beforeCloseHandler"
    class="el-dialog--no-header el-dialog--compact-footer matchingInterestRemovalModal"
    width="500px"
  >
    <PxModalMessage :visible="hasServerError" />
    <h2 class="matchingInterestRemovalModal-title">
      {{
        $t(`matching.matchingInterest.removalModal.${removalType}.title`, {
          company: interestName,
        })
      }}
    </h2>
    <p class="matchingInterestRemovalModal-description">
      {{
        $t(
          `matching.matchingInterest.removalModal.${removalType}.description`,
          {
            company: interestName,
          },
        )
      }}
      <i>
        {{ $t(`matching.matchingInterest.removalModal.${removalType}.page`) }}
      </i>
    </p>
    <ElDivider />
    <template #footer>
      <PxButton
        class="matchingInterestRemovalModal-cancelBtn"
        size="small"
        type="link"
        @click="onClickCancelButton"
      >
        {{ $t("common.cancel") }}
      </PxButton>
      <PxButton
        :loading="isLoading"
        class="matchingInterestRemovalModal-submitBtn"
        type="green"
        @click="onClickRemoveInterestButton"
      >
        {{
          $t(
            `matching.matchingInterest.removalModal.${removalType}.submitButton`,
          )
        }}
      </PxButton>
    </template>
  </ElDialog>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import ElDialogMixin from "@/mixins/el-dialog.mixin";

import {
  MATCHING_INITIAL_INTEREST,
  MATCHING_CONNECTED_INTEREST,
  MATCHING_HAS_INTEREST,
} from "@/modules/matching/constants";

export default defineComponent({
  name: "PxMatchingInterestConfirmModal",

  mixins: [ElDialogMixin],

  props: {
    interestState: {
      type: Number,
      required: true,
    },
    interestName: {
      type: String,
      required: true,
    },
    hasServerError: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      removalTypes: {
        [MATCHING_INITIAL_INTEREST as number]: "interest",
        [MATCHING_HAS_INTEREST as number]: "interest",
        [MATCHING_CONNECTED_INTEREST as number]: "connection",
      },
    };
  },

  computed: {
    removalType(): string {
      return this.removalTypes[this.interestState];
    },
  },

  methods: {
    updateClosedState() {
      this.$emit("update:visibility", false);
    },
    beforeCloseHandler() {
      this.updateClosedState();
    },
    onClickCancelButton() {
      this.updateClosedState();
    },
    onClickRemoveInterestButton() {
      this.$emit("removed-interest");
    },
  },
});
</script>

<style lang="scss">
.matchingInterestRemovalModal {
  /* Override no header default styles */
  margin-top: 15vh !important;

  .el-dialog__body {
    max-width: 500px;
    padding: 20px 20px 0 20px;
    margin: auto;

    .el-divider--horizontal {
      margin: 26px 0 9px;
    }
  }

  .el-dialog__footer {
    position: relative;
    padding-top: 17px;
    padding-bottom: 26px;

    &::after {
      position: absolute;
      top: -1px;
      right: 40px;
      left: 40px;
      height: 1px;

      content: "";
      background-color: $athens-gray;

      /* Hide line separator instead of removing since it might be useful in the future */
      opacity: 0;
    }

    @include breakpoint-up(md) {
      display: flex;
      justify-content: center;
    }
  }

  .el-dialog__footer .el-button.el-button--link {
    padding: 13px 0 11px 1px;

    span {
      line-height: 16px;
    }

    + .el-button {
      margin-left: 15px;
      padding: 0 15px;
    }

    + .el-button span {
      font-size: 13px;
    }
  }
}

.matchingInterestRemovalModal-title {
  @include grotesk(semiBold);

  padding: 0 20px;
  font-size: to-rem(18px);
  line-height: 28px;
  color: $ebony-clay;
  text-align: center;

  @include breakpoint-up(md) {
    font-size: to-rem(20px);
  }
}

.matchingInterestRemovalModal-description {
  padding: 0 18px;
  margin: 27px 11px 26px 9px;
  font-size: to-rem(14px);
  line-height: 25px;
  color: $manatee;
  text-align: center;
  letter-spacing: -0.28px;
}
</style>
