import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-ae1eeba0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "level-range-bar" }
const _hoisted_2 = { class: "level-range-bar__label" }
const _hoisted_3 = { class: "level-range-bar__icon" }
const _hoisted_4 = ["alt", "src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.rangeBarLabel), 1),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("img", {
        alt: `Level Range ${_ctx.investingRange}`,
        src: `/img/icons/level-range-graph/level-white-${_ctx.investingRange}.svg`
      }, null, 8, _hoisted_4)
    ])
  ]))
}