<template>
  <div class="multi-selector-entry__wrapper" data-testid="multi-selector-entry">
    <div
      :class="{
        'multi-selector-entry__container--child-scrolled':
          showAllItems && hasChildContainerBeenScrolled,
      }"
      class="multi-selector-entry__container"
      @click="onShowAllItemsClick"
    >
      <div class="multi-selector-entry__info-container">
        <span class="multi-selector-entry__name">
          {{ formattedItem.label }}
        </span>
        <span class="multi-selector-entry__child-label">
          {{ formattedChildCopy }}
        </span>
        <template v-if="hasChildItems">
          <i
            :class="{ 'icon--invert-y': showAllItems }"
            class="icon icon-caret--gray"
          />
        </template>
      </div>
      <div class="multi-selector-entry__action-container">
        <i class="icon icon-pencil--dark" @click.stop="onEditItemClick" />
        <i class="icon icon-trash--dark" @click.stop="onRemoveItemClick" />
      </div>
    </div>
    <div
      ref="childContainer"
      :class="{
        'multi-selector-entry__child-container--hidden': !showAllItems,
      }"
      class="multi-selector-entry__child-container"
    >
      <template v-if="hasChildItems">
        <div
          v-for="child in formattedItem.children"
          :key="child.value"
          class="multi-selector-entry__child"
        >
          <span class="multi-selector-entry__child-label">
            {{ child.label }}
          </span>
        </div>
      </template>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { IMultiSelectorItem } from "@/components/multi-selector/multi-selector.interface";
import throttle from "lodash/throttle";

export default defineComponent({
  name: "MultiSelectorEntry",

  props: {
    item: {
      type: Object as () => IMultiSelectorItem,
      required: true,
    },

    /**
     * Define if will always show parent label.
     */
    showParentLabel: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      showAllItems: false,
      childScrollTop: 0,
    };
  },

  computed: {
    formattedItem(): IMultiSelectorItem {
      if (!this.item.children || this.item.children.length === 0) {
        return {
          ...this.item,
          children: [],
        };
      }

      // Filter selected children
      const selectedChildren = this.item.children.filter(
        (child: IMultiSelectorItem) => child.selected,
      );

      // If only on child and show parent label is disabled, them use only child label
      if (selectedChildren.length === 1 && !this.showParentLabel) {
        return {
          ...selectedChildren[0],
          children: [],
        };
      }

      return { ...this.item, children: [...selectedChildren] };
    },

    hasChildItems(): boolean {
      return (
        !!this.formattedItem.children && this.formattedItem.children.length > 1
      );
    },

    formattedChildCopy(): string {
      // If no children, just return empty string
      if (
        !this.formattedItem.children ||
        this.formattedItem.children.length === 0
      ) {
        return "";
      }

      // If only one item, check for show parent label value
      if (this.formattedItem.children.length === 1 && this.showParentLabel) {
        return `(${this.formattedItem.children[0].label})`;
      }

      return `(${this.formattedItem.children.length})`;
    },

    hasChildContainerBeenScrolled(): boolean {
      return this.childScrollTop > 0;
    },
  },

  mounted() {
    const childContainerRef = this.$refs.childContainer as any;

    childContainerRef.addEventListener(
      "scroll",
      throttle(() => this.onChildContainerScroll(childContainerRef), 100),
      {
        passive: true,
        capture: false,
      },
    );
  },

  beforeUnmount() {
    window.removeEventListener(
      "scroll",
      throttle(() => this.onChildContainerScroll, 100),
    );
  },

  methods: {
    onChildContainerScroll(elementRef: any) {
      this.childScrollTop = elementRef.scrollTop;
    },

    onEditItemClick() {
      this.$emit("edit", this.item.value);
    },

    onRemoveItemClick() {
      this.$emit("remove", this.item.value);
    },

    /**
     * Toggle show all items visibility
     */
    onShowAllItemsClick() {
      if (this.hasChildItems) {
        this.showAllItems = !this.showAllItems;
      }
    },
  },
});
</script>

<style lang="scss" scoped>
.multi-selector-entry__wrapper {
  margin-bottom: 12px;
  clear: both;
  background-color: $athens-gray;
  border-radius: 2px;
}

.multi-selector-entry__container {
  display: flex;
  justify-content: space-between;
  padding: 9px 15px 11px 16px;
  cursor: pointer;
  box-shadow: 1px 2px 1px -1px rgba($mischka, 0);

  transition: box-shadow 100ms ease-in-out;
}

.multi-selector-entry__container--child-scrolled {
  box-shadow: 1px 2px 1px -1px rgba($mischka, 1);
}

.multi-selector-entry__name {
  max-width: 178px;
  font-size: 14px;
  color: $gun-powder;
  text-transform: capitalize;

  @include breakpoint-up(md) {
    max-width: 100%;
  }
}

.multi-selector-entry__info-container {
  display: grid;
  grid-template-columns: 1fr repeat(2, auto);
  grid-column-gap: 4px;
  align-items: center;
}

.multi-selector-entry__action-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 12px;
  align-items: center;
}

.multi-selector-entry__child-container {
  max-height: 150px;
  padding: 0 15px 12px 16px;
  overflow-y: auto;
  visibility: visible;
  opacity: 1;
  transition:
    visibility 150ms ease-in-out,
    opacity 150ms ease-in-out;

  &:empty {
    display: none;
  }

  .multi-selector-entry__child {
    padding-bottom: 8px;
  }

  .multi-selector-entry__child:last-child {
    padding: 0;
  }
}

.multi-selector-entry__child-container--hidden {
  max-height: 0;
  padding-bottom: 0;
  visibility: visible;
  opacity: 0;
}

.multi-selector-entry__child-label {
  font-size: 14px;
  color: $manatee;
  text-transform: capitalize;
}

.icon {
  width: 14px;
  height: 14px;
  cursor: pointer;
  transition:
    background 150ms ease-in-out,
    transform 250ms ease-in-out;

  &.icon--invert-y {
    transform: rotateZ(180deg);
  }
}

.icon-pencil--dark:hover {
  background-image: url("#{$assetsPath}/img/icons/pencil.svg");
}

.trash--dark:hover {
  background-image: url("#{$assetsPath}/img/icons/trash.svg");
}
</style>
