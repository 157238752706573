<template>
  <div
    :class="{ 'is-navigation-bar': $screen.mdOnly }"
    class="category-levels-assessment"
  >
    <div class="container-fluid">
      <div
        v-if="currentCategory && selectedStep"
        class="category-levels-assessment__main"
      >
        <div class="category-levels-assessment__level-slider-column">
          <LevelSlider
            :color="currentCategory.color"
            :levels="currentCategory.categoryDetails.length"
            :value="selectedStep.level"
            height="393px"
            @input="onLevelChange"
          />
          <div
            v-if="selectedStep.level === 0 && isFirstInteraction"
            class="category-levels-assessment-level-slider-tip"
          >
            <img
              alt="Drag Tip Icon"
              class="category-levels-assessment-level-slider-tip__icon"
              src="/img/arrow-tip.svg"
            />
            <p class="category-levels-assessment-level-slider-tip__text">
              {{ $t("affiliateProgram.assessment.dragTip") }}
            </p>
          </div>
        </div>
        <LevelsDescription
          :category-details="currentCategory.categoryDetails"
          :current-level="selectedStep.level"
          class="category-levels-assessment__levels-description"
          height="342px"
        />
        <div class="category-levels-assessment__requirements-column">
          <CategoryRequirements
            :category="currentCategory"
            :level="selectedStep.level"
            class="category-levels-assessment__category-requirements"
          />
          <div
            v-if="selectedStep.level === 0"
            class="category-levels-assessment-skip-tip"
          >
            <PxIcon :size="16" name="information" />
            <p class="category-levels-assessment-skip-tip__text">
              {{ $t("affiliateProgram.assessment.noLevelTip") }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import LevelSlider from "@/modules/affiliates/components/level-slider/level-slider.vue";
import LevelsDescription from "@/modules/affiliates/components/levels-description/levels-description.vue";
import CategoryRequirements from "@/modules/affiliates/components/category-requirements/category-requirements.vue";

export default defineComponent({
  name: "CategoryLevelsAssessmentDesktop",

  components: {
    LevelSlider,
    LevelsDescription,
    CategoryRequirements,
  },

  props: {
    currentCategory: {
      type: Object as () => any,
      default: () => null,
    },

    selectedStep: {
      type: Object as () => any,
      default: () => null,
    },

    onLevelChange: {
      type: Function as () => any,
      required: true,
    },

    isFirstInteraction: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      currentYear: new Date().getFullYear(),
    };
  },
});
</script>

<style lang="scss">
$--assessment-max-tablet-width: 978px;

.category-levels-assessment {
  &.is-navigation-bar {
    padding: 80px 0 120px;
  }

  & > div:first-child {
    max-width: 1140px;
  }

  &__main {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding: 26px 53px 0 76px;

    @media (max-width: $--assessment-max-tablet-width) {
      flex-wrap: wrap;
    }
  }

  &__levels-description {
    position: relative;
    bottom: 37px;
    flex: 1 1 0;
    align-self: flex-end;
    min-width: 0;
  }

  &__requirements-column {
    align-self: flex-start;
    min-width: 330px;
    margin-top: 40px;

    @media (max-width: $--assessment-max-tablet-width) {
      width: 100%;
      padding: 0 48px 0 25px;
    }
  }

  &__level-slider-column {
    position: relative;
  }
}

.category-levels-assessment-level-slider-tip {
  position: absolute;
  bottom: -18px;
  left: 64px;
  display: flex;

  &__icon {
    position: relative;
    top: 3px;
    left: 1px;
    flex-shrink: 0;
  }

  &__text {
    flex-shrink: 0;
    max-width: 191px;
    margin-left: 10px;

    font-size: 0.8667rem;
    line-height: 1.2rem;
  }
}

.category-levels-assessment-skip-tip {
  display: flex;
  padding-top: 21px;

  &__text {
    max-width: 302px;
    padding-left: 6px;

    font-size: 0.8667rem;
    font-style: italic;
    line-height: 1.3333rem;
  }
}
</style>
