import { IMetaState } from "./meta-types";

/**
 * Initial state for the metadata module.
 */
export const state: IMetaState = {
  data: {},
  loading: false,
  error: null,
};
