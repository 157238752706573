import { ActionTree } from "vuex";

import { RootState } from "@/services/store/root-state";
import { getBaseName } from "@/services/store/utils/get-base-name";
import {
  IMilestonePlannerState,
  EMilestonePlannerActions,
  EMilestonePlannerMutations,
  IMilestonePlanner,
} from "@/modules/milestone-planner/services/store/milestone-planner/milestone-planner.types";
import { milestonePlannersProvider } from "@/modules/milestone-planner/services/data/milestone-planners/milestone-planners.provider";
import { parseMilestonesIntoGridCategories } from "@/modules/milestone-planner/services/utils/milestones-parser";
import { clone } from "lodash";
import {
  EMilestonePlannerPasscodeActions,
  EMilestonePlannerPasscodeGetters,
  IMilestonePlannerPasscodePayload,
} from "@/modules/milestone-planner/services/store/milestone-planner/sub-modules/passcode/passcode.interface";
import { ECategoryActions } from "@/services/store/category/category-types";
import { ENTREPRENEUR_USER_GROUP_ID } from "@/modules/common/constants";

export const actions: ActionTree<IMilestonePlannerState, RootState> = {
  /**
   * Get milestone planner by uid
   *
   * @param {*} { commit, dispatch, state, rootGetters }
   * @param {*} [queryParams={}]
   * @return {*}  {(Promise<IMilestonePlanner | null>)}
   */
  async [getBaseName(EMilestonePlannerActions.GET_VALUE)](
    { commit, dispatch, state, rootGetters },
    queryParams = {},
  ): Promise<IMilestonePlanner | null> {
    commit(EMilestonePlannerMutations.SET_LOADING, true);

    let milestonePlanner = state.value as IMilestonePlanner;

    try {
      // remove id from the query params to prevent an ID to be sent as a query string
      const params = clone(queryParams);
      delete params["id"];

      // when the passcode isn't provided search one on the store and if exists append it to request
      if (!params.passcode) {
        const storedPasscode = rootGetters[
          EMilestonePlannerPasscodeGetters.GET
        ](queryParams.id);
        if (storedPasscode) {
          params.passcode = storedPasscode;
        }
      }

      milestonePlanner = await milestonePlannersProvider.get(
        queryParams.id,
        params,
      );

      commit(EMilestonePlannerMutations.SET_VALUE, milestonePlanner);
      commit(EMilestonePlannerMutations.SET_ERROR, null);

      // Fetch categories before parsing milestones:
      await dispatch(
        ECategoryActions.FETCH,
        {
          group: ENTREPRENEUR_USER_GROUP_ID,
        },
        { root: true },
      );

      dispatch(EMilestonePlannerActions.PARSE_VALUE, null, {
        root: true,
      });

      // Persist milestone planner passcode to be used on future requests
      const passcodeActionPayload: IMilestonePlannerPasscodePayload = {
        milestonePlannerUid: milestonePlanner.uid,
        passcode: params.passcode,
      };
      dispatch(EMilestonePlannerPasscodeActions.SET, passcodeActionPayload, {
        root: true,
      });
    } catch (error) {
      commit(EMilestonePlannerMutations.SET_VALUE, null);
      commit(EMilestonePlannerMutations.SET_ERROR, error);
    } finally {
      commit(EMilestonePlannerMutations.SET_LOADING, false);
    }

    return milestonePlanner;
  },

  /**
   * Parse milestones from milestone planner
   *
   * @param {*} { commit } Store context
   */
  [getBaseName(EMilestonePlannerActions.PARSE_VALUE)]({
    commit,
    state,
    rootState,
  }) {
    const categories = (rootState as any).categories.data;
    const milestones = state.value?.milestones || [];
    const parsedMilestones = parseMilestonesIntoGridCategories(
      milestones,
      categories,
    );
    commit(EMilestonePlannerMutations.SET_PARSED_VALUE, parsedMilestones);
  },
};
