import { IGenericState } from "@/services/store/generic/generic-state.interface";
import { IAffiliate } from "@/services/data/affiliate/affiliate.interface";

/**
 * Boilerplate the store structure.
 */
export interface IAffiliateState extends IGenericState<IAffiliate> {
  data: IAffiliate;
}

export enum EAffiliateState {
  NAMESPACE = "affiliate",
}

export enum EAffiliateAction {
  FETCH = "affiliate/fetch",
  SUPPORTER_FETCH_DEFAULT = "affiliate/supporterFetchDefault",
  SUPPORTER_FETCH = "affiliate/supporterFetch",
}

export enum EAffiliateGetter {
  VALUE = "affiliate/getValue",
  VALUES = "affiliate/getValues",
  IS_LOADING = "affiliate/isLoading",
  ERROR = "affiliate/getError",
  HAS_ERROR = "affiliate/hasError",
  QUESTION_BUNDLE = "affiliate/questionBundle",
}
