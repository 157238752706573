<template>
  <div class="px-step-by-step-dialog-navigation">
    <div
      class="px-step-by-step-dialog-navigation__section px-step-by-step-dialog-navigation__section--left"
    >
      <PxButton
        v-if="currentPage > 1"
        ref="previousButton"
        v-bind="previousButtonProps"
        class="px-step-by-step-dialog-navigation__button"
        data-testid="px-step-by-step-dialog-navigation__previous-button"
        @click="goToPage(currentPage - 1)"
      />
    </div>
    <div class="px-step-by-step-dialog-navigation__steps">
      <PxNavigationStep
        v-for="page in pageCount"
        :key="page"
        :active="page === currentPage"
        data-testid="px-step-by-step-dialog-navigation__step"
        @click="goToPage(page)"
      />
    </div>
    <div
      class="px-step-by-step-dialog-navigation__section px-step-by-step-dialog-navigation__section--right"
    >
      <PxButton
        ref="nextButton"
        v-bind="nextButtonProps"
        class="px-step-by-step-dialog-navigation__button"
        data-testid="px-step-by-step-dialog-navigation__next-button"
        @click="nextButtonClick"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { TranslateResult } from "vue-i18n";
import {
  EPxButtonSize,
  EPxButtonType,
  EPxButtonVariant,
} from "@/components/px-button/px-button.types";
import PxNavigationStep from "@/components/px-navigation-step/px-navigation-step.vue";

export default defineComponent({
  name: "PxStepByStepDialogNavigation",

  components: {
    PxNavigationStep,
  },

  props: {
    pageCount: {
      type: Number,
      required: true,
    },
    currentPage: {
      type: Number,
      required: true,
    },
  },

  static: {
    nextButton: {
      type: EPxButtonType.BLUE,
      size: EPxButtonSize.SMALL,
      variant: EPxButtonVariant.FLUID,
    },
    previousButton: {
      type: EPxButtonType.DEFAULT,
      size: EPxButtonSize.SMALL,
      variant: EPxButtonVariant.FLUID,
    },
  },

  computed: {
    previousButtonProps(): any {
      return {
        ...this.$options.static.previousButton,
        label: this.$t("common.previous"),
      };
    },

    nextButtonProps(): any {
      return {
        ...this.$options.static.nextButton,
        label: this.nextButtonLabel,
      };
    },

    nextButtonLabel(): TranslateResult {
      return this.isLastPage ? this.$t("common.gotIt") : this.$t("common.next");
    },

    isLastPage(): boolean {
      return this.currentPage === this.pageCount;
    },
  },

  methods: {
    goToPage(page: number) {
      if (page !== this.currentPage && page >= 1 && page <= this.pageCount) {
        this.$emit("update:currentPage", page);
        this.$emit("page-change", page);
      }
    },

    nextButtonClick() {
      this.isLastPage
        ? this.$emit("close-dialog")
        : this.goToPage(this.currentPage + 1);
    },
  },
});
</script>

<style lang="scss" scoped>
.px-step-by-step-dialog-navigation {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0;

  &__section {
    width: 20%;

    &--left {
      text-align: left;
    }

    &--right {
      text-align: right;
    }
  }

  &__button {
    :deep() span {
      font-size: 13px;
    }
  }

  &__steps {
    display: flex;
    gap: 12px;
  }
}
</style>
