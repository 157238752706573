/**
 * Uses canvas.measureText to compute and return the width of the given text of given font in pixels.
 *
 * @param {String} text The text to be rendered.
 * @param {String} font The css font descriptor that text is to be rendered with (e.g. "bold 14px verdana").
 *
 * @see https://stackoverflow.com/questions/118241/calculate-text-width-with-javascript/21015393#21015393
 */
export const getTextWidth = (text: string, font: string): number => {
  // re-use canvas object for better performance
  const canvas =
    (getTextWidth as any).canvas ||
    ((getTextWidth as any).canvas = document.createElement("canvas"));

  const context = canvas.getContext("2d");
  context.font = font;

  const metrics = context.measureText(text);
  return metrics.width;
};

/**
 * Filter sensitive data from a JSON string.
 *
 * @param {String} json The json string to be filtered.
 * @returns {String} A string where sensitive values have been replaced with a 'SECRET' keyword.
 */
export const filterSensitiveDataFromJSON = (json: string): string => {
  const sensitiveDataMatch =
    /([^"]*)(password|token|access)([^"]*)("\s*:\s*")[^"]*(")/gim;
  return json.replace(sensitiveDataMatch, "$1$2$3$4SECRET$5");
};
