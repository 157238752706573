<template>
  <div class="form-presentation-layout__wrapper">
    <div
      :class="{
        'form-presentation-layout__container--bkg-reversed':
          hasReversedBackgrounds,
      }"
      class="form-presentation-layout__container"
    >
      <div
        :class="{
          'form-presentation-layout__content-wrapper--with-return':
            hasReturnButton,
        }"
        class="form-presentation-layout__content-wrapper"
      >
        <div
          :class="{
            'form-presentation-layout__content-container--top': isTopAlignment,
          }"
          class="form-presentation-layout__content-container"
        >
          <div
            :class="{
              'form-presentation-layout__content-header-wrapper--sticky':
                showStickyHeader,
            }"
            class="form-presentation-layout__content-header-wrapper"
          >
            <PxButton
              v-if="hasReturnButton"
              circle
              class="form-presentation-layout__return-button"
              @click="onReturnButtonClick"
            >
              <PxIcon name="arrow--left-dark" size="14" />
            </PxButton>
            <div class="form-presentation-layout__content-header">
              <slot name="header">
                <h1 class="form-presentation-layout__content-title">
                  {{ contentTitle }}
                </h1>
                <p class="form-presentation-layout__content-description">
                  {{ contentDescription }}
                </p>
              </slot>
            </div>
          </div>
          <div class="form-presentation-layout__content-body">
            <slot name="content" />
          </div>
        </div>
      </div>
      <div
        :class="{
          'form-presentation-layout__description-wrapper--with-background':
            hasDescriptionBackgroundImage,
        }"
        class="form-presentation-layout__description-wrapper"
      >
        <div class="form-presentation-layout__description-container">
          <PxResponsiveBitmap
            :alt="descriptionImageTitle"
            :link="descriptionImageLink"
            class="form-presentation-layout__description-image"
          />
          <span
            v-if="descriptionText"
            class="form-presentation-layout__description-text"
            v-html="descriptionText"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { IBitmapLink } from "@/components/px-responsive-bitmap/px-responsive-bitmap.interface";
import throttle from "lodash/throttle";

export default defineComponent({
  name: "FormPresentationLayout",

  props: {
    contentTitle: {
      type: String,
      default: "",
    },
    contentDescription: {
      type: String,
      default: "",
    },
    contentVerticalAlignment: {
      type: String,
      default: "center",
      validator(value: string) {
        return !value || ["center", "top"].includes(value);
      },
    },
    descriptionText: {
      type: String,
      default: "",
    },
    descriptionImageLink: {
      type: Object as () => IBitmapLink,
      required: true,
    },
    descriptionImageTitle: {
      type: String,
      required: true,
    },
    hasReturnButton: {
      type: Boolean,
      default: true,
    },
    hasDescriptionBackgroundImage: {
      type: Boolean,
      default: false,
    },
    hasStickyHeader: {
      type: Boolean,
      default: false,
    },
    hasReversedBackgrounds: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isWrapperScrolled: false,
    };
  },

  computed: {
    isTopAlignment(): boolean {
      return this.contentVerticalAlignment === "top";
    },

    showStickyHeader(): boolean {
      return this.hasStickyHeader && this.isWrapperScrolled;
    },
  },

  mounted() {
    if (!this.hasStickyHeader) {
      return;
    }

    document.addEventListener(
      "scroll",
      throttle(() => this.onWrapperScroll(), 100),
      {
        passive: true,
        capture: false,
      },
    );
  },

  beforeUnmount() {
    if (!this.hasStickyHeader) {
      return;
    }

    document.removeEventListener(
      "scroll",
      throttle(() => this.onWrapperScroll, 300),
    );
  },

  methods: {
    onWrapperScroll() {
      this.isWrapperScrolled = !!document.scrollingElement?.scrollTop;
    },

    onReturnButtonClick() {
      this.$emit("previous-page");
    },
  },
});
</script>

<style lang="scss" scoped>
// Assigning color variables for dynamic reusage:
$wrapper-content-background: $white;
$wrapper-description-background: linear-gradient(
  to bottom,
  $alabaster,
  $alabaster
);

.form-presentation-layout__container {
  display: grid;
  grid-template-columns: 44.8% 55.2%;
}

.form-presentation-layout__content-container--top {
  align-self: flex-start;
}

.form-presentation-layout__return-button {
  width: 28px;
  min-width: auto;
  height: 28px;
  padding: 0;
  border-radius: 50%;

  &::after {
    border-radius: 50%;
  }

  :deep(img) {
    transform: translate(-1px, -1px);
  }
}

.form-presentation-layout__content {
  &-wrapper {
    position: relative;
    justify-content: flex-end;
    min-height: calc(100vh - 66px);
    padding: 46px 0;
    background: $wrapper-content-background;
    border-right: solid 1px $pale-grey;
    box-shadow: 0 2px 11px 0 $black-5;
  }

  &-wrapper--with-return {
    padding: 90px 0;
  }

  &-container {
    width: 69%;
    margin: 0 auto;
  }

  &-body {
    position: relative;
  }

  &-header-wrapper {
    padding: 13px 0 13px 2px;
    background-color: $white;
    transform: translate3d(0, 0, 0);

    &::before,
    &::after {
      position: absolute;
      right: -2%;
      z-index: -1;
      width: 100vw;
      content: "";
      opacity: 0;
    }

    &::before {
      bottom: 0;
      height: 100vh;
      background-color: white;
      box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.06);
    }

    &::after {
      top: 100%;
      height: 1px;
      background-color: $pale-grey;
    }
  }

  &-header-wrapper--sticky {
    position: sticky;
    top: 66px;
    z-index: z("floaters");
    width: 120%;
  }

  &-header-wrapper--sticky::before,
  &-header-wrapper--sticky::after {
    opacity: 1;
  }

  &-header {
    margin-top: 8px;
  }

  &-header-wrapper--sticky &-header {
    margin-top: 0;
  }

  &-title {
    @include grotesk(semiBold);

    margin: 0;
    font-size: 30px;
    line-height: 40px;
  }

  &-description {
    font-size: 16px;
    line-height: 32px;
    color: $manatee;
  }
}

.form-presentation-layout__description {
  &-wrapper {
    position: fixed;
    top: 66px;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 55.2%;
    background-image: $wrapper-description-background;
  }

  &-wrapper--with-background {
    background-image: linear-gradient(
      to bottom,
      rgba($orangish, 0.06),
      rgba($orangish, 0.04) 1%,
      rgba($orangish, 0.06)
    );

    &::before {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: z("default");
      content: "";
      background-image: url("#{$assetsPath}/img/icons/tags.svg");
      background-position: center;
      background-size: cover;
    }
  }

  &-container {
    z-index: z("default") + 1;
    max-width: 75%;
  }

  &-image,
  &-text {
    display: block;
    width: 100%;
  }

  &-text {
    max-width: 370px;
    padding-left: 18px;
    margin: 12px auto 0;
    font-size: 16px;
    line-height: 28px;
    text-align: center;
  }

  &-text :deep(b) {
    font-weight: 600;
  }
}

.form-presentation-layout__container--bkg-reversed {
  background-color: $wrapper-content-background;

  .form-presentation-layout__content-wrapper,
  .form-presentation-layout__content-header-wrapper {
    background-image: $wrapper-description-background;
  }

  .form-presentation-layout__description-wrapper,
  .form-presentation-layout__content-header-wrapper--sticky {
    background: $wrapper-content-background;
  }
}
</style>
