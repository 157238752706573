<template>
  <div class="auth-navigation-view page-full-height">
    <PxNavbar
      v-if="$screen.mdUp"
      :items="activeNavBarItems"
      :show-account-dropdown="$user.isLogged()"
    />
    <template v-if="$screen.smDown && shouldShowTabBar">
      <PxTabBar
        v-if="hasTabBarItems && !isErrorRoute"
        v-model="selectedView"
        :items="tabBarItems"
      />
      <MobileTopNavbar v-else />
    </template>
    <RouterView :key="routerViewKey" class="page-body" />
    <PxBottomBar
      v-if="$user.isLogged() && $screen.smDown"
      :items="mobileMenuItems"
    />
    <PxFooter
      v-if="$screen.mdUp && !hideFooter"
      :class="{ 'px-footer--no-padding': isTeamManagementPage }"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import MobileTopNavbar from "@/modules/self-assessment/components/mobile-top-navbar/mobile-top-navbar.vue";
import {
  ROUTE_PROFILE,
  ROUTE_USER_ACCOUNT,
  ROUTE_TEAM_MANAGEMENT,
  ROUTE_ACCOUNT_SETTINGS,
} from "@/modules/profile/services/router/routes-names";
import { EMetaActions } from "@/services/store/meta/meta-types";
import { PROFILE_META_ACTIVE_VIEW } from "@/modules/profile/constants";
import { ROUTE_MATCHING_LIST } from "@/modules/matching/services/router/routes-names";

import { IAuthState } from "@/modules/authentication/services/store/auth/auth-types";
import {
  helpScoutBeaconInit,
  helpScoutBeaconDestroy,
} from "@/services/utils/helpscout";

import { ERROR_ROUTE } from "@/services/router/router-names";
import { INavBarItem } from "@/modules/profile/meta/entrepreneurs";
import {
  CAPITAL_EXPLORER,
  CAPITAL_EXPLORER_COMPARISON_TABLE,
  CAPITAL_EXPLORER_VISITORS_VIEW,
} from "@/modules/capital-explorer/services/router/routes-names";
import { navBarItemsByFeaturesToggleOrPermissions } from "@/utils/navbar-items-enabled";

export default defineComponent({
  components: {
    MobileTopNavbar,
  },

  data() {
    return {
      defaultViews: {
        [ROUTE_PROFILE as string]: "about",
        [ROUTE_MATCHING_LIST as string]: "discover",
      },
      selectedView: "",

      menuItems: [
        {
          value: "my_company",
          label: this.$t("common.navigation.menuItems.myCompany"),
          icon: "company",
          to: ROUTE_PROFILE,
        },
        {
          value: "my_supporters",
          label: this.$user.isSupporter()
            ? this.$t("common.navigation.menuItems.myMatches")
            : this.$t("common.navigation.menuItems.mySupporters"),
          icon: "flow-circle",
          to: ROUTE_MATCHING_LIST,
        },
        {
          value: "account",
          label: this.$t("common.navigation.menuItems.account"),
          icon: "gear",
          to: ROUTE_USER_ACCOUNT,
        },
      ],
    };
  },

  computed: {
    isErrorRoute(): boolean {
      return this.$route.name === ERROR_ROUTE;
    },

    shouldShowTabBar(): boolean {
      return this.$route.name !== ROUTE_ACCOUNT_SETTINGS;
    },

    storeActiveView(): string {
      return (
        this.$store.get("meta/get", PROFILE_META_ACTIVE_VIEW) ||
        this.defaultViews[ROUTE_PROFILE as string]
      );
    },

    metaTabItems(): any {
      const metadata = this.$store.get("meta/data");
      return metadata ? metadata.profileTabMenuItems : null;
    },

    tabBarItems(): any {
      const company = this.$store.get("profile/company/data");
      const companyTypeTabs =
        company !== null
          ? this.$profile.getByType("tabMenuItems", company.type)
          : null;
      const tabBarItems = this.metaTabItems || companyTypeTabs;

      if (!tabBarItems) {
        return [];
      }

      return tabBarItems.map((tabItem: any) => {
        // Apply visitor icon if user is not logged
        return {
          ...tabItem,
          icon:
            tabItem.hasVisitorIcon && !this.$user.isLogged()
              ? "icon-password-white"
              : "",
        };
      });
    },

    hasTabBarItems(): boolean {
      return this.tabBarItems.length > 0;
    },

    navBarItems(): Array<INavBarItem> {
      const company = this.$store.get("auth/company/data");
      const companyType = company !== null ? company.type : null;
      if (companyType !== null) {
        return this.$profile.getByType("navBarItems", companyType);
      }
      return this.$profile.get("navBarItems");
    },

    itemsByEnabledModulesOrFeatures(): Array<INavBarItem> {
      return navBarItemsByFeaturesToggleOrPermissions(this.navBarItems);
    },

    activeNavBarItems(): Array<INavBarItem> {
      return this.$user.isLogged() || this.authState.company?.data !== null
        ? this.itemsByEnabledModulesOrFeatures
        : [];
    },

    currentRouteName(): string {
      return (this.$route.name as string) || "";
    },

    authState(): IAuthState {
      return this.$store.state.auth as IAuthState;
    },

    mobileMenuItemsWithoutMatching(): any {
      return this.menuItems.filter((item) => item.value !== "my_supporters");
    },

    mobileMenuItems(): any {
      return this.isMatchingDisabled
        ? this.mobileMenuItemsWithoutMatching
        : this.menuItems;
    },

    isMatchingDisabled(): boolean {
      return !this.$features.isEnabled("match");
    },

    // If we want the route to be rerendered we should pass the route full path as the key. If not, we must pass the route name.
    routerViewKey(): string {
      return this.$route.meta.shouldBeRerendered
        ? this.$route.fullPath
        : (this.$route?.name as string) || "";
    },

    hideFooter(): boolean {
      const routes = [
        CAPITAL_EXPLORER_COMPARISON_TABLE,
        CAPITAL_EXPLORER,
        CAPITAL_EXPLORER_VISITORS_VIEW,
      ];

      return this.$route.name
        ? routes.includes(this.$route.name as string)
        : false;
    },

    isTeamManagementPage(): boolean {
      return this.$route.name === ROUTE_TEAM_MANAGEMENT;
    },
  },

  watch: {
    selectedView(newVal, oldVal) {
      if (newVal === oldVal || this.storeActiveView === newVal) {
        return;
      }

      this.$store.dispatch(EMetaActions.SET, {
        key: PROFILE_META_ACTIVE_VIEW,
        value: newVal,
      });
    },

    storeActiveView: {
      immediate: true,
      handler(newVal: string) {
        this.selectedView = newVal;
      },
    },

    currentRouteName: {
      immediate: true,
      handler(name: string) {
        this.selectedView =
          name && Object.prototype.hasOwnProperty.call(this.defaultViews, name)
            ? this.defaultViews[name]
            : "";
      },
    },
  },

  mounted() {
    this.$store.dispatch(EMetaActions.SET, {
      key: PROFILE_META_ACTIVE_VIEW,
      value: this.selectedView,
    });

    // Load HelpScout chat widget by user type
    helpScoutBeaconInit({
      userType: this.$user.getUserAccountType(),
    });
  },

  unmounted() {
    // Remove HelpScout chat widget
    helpScoutBeaconDestroy();
  },
});
</script>

<style lang="scss" scoped>
.auth-navigation-view .px-footer--no-padding {
  @include breakpoint-up(lg) {
    padding-top: 0;
  }
}
</style>
