import { Module } from "vuex";
import { make } from "vuex-pathify";

import { RootState } from "@/services/store/root-state";
import { ITeamMembersState } from "./team-members.types";

import { actions } from "@/modules/team-management/services/store/team-members/team-members.actions";
import { getGenericInitialState } from "@/services/store/generic/generic-state";
import { ITeamMember } from "@/modules/team-management/services/data/team-members/team-members.interface";

/**
 * Initial state for the Team Members store.
 */
const state: ITeamMembersState = {
  ...getGenericInitialState<ITeamMember>(),
};

export const teamMembersModule: Module<ITeamMembersState, RootState> = {
  namespaced: true,
  state,
  actions,
  mutations: make.mutations(state),
};
