import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createElementBlock as _createElementBlock, renderSlot as _renderSlot, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-300218ee"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "px-empty-message" }
const _hoisted_2 = { class: "px-empty-message__wrapper" }
const _hoisted_3 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PxIcon = _resolveComponent("PxIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.icon)
      ? (_openBlock(), _createBlock(_component_PxIcon, {
          key: 0,
          name: _ctx.icon,
          size: _ctx.iconSize
        }, null, 8, ["name", "size"]))
      : _createCommentVNode("", true),
    (_ctx.title)
      ? (_openBlock(), _createElementBlock("h4", {
          key: 1,
          style: _normalizeStyle(_ctx.titleStyles)
        }, _toDisplayString(_ctx.title), 5))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      (_ctx.subtitle)
        ? (_openBlock(), _createElementBlock("p", {
            key: 0,
            style: _normalizeStyle(_ctx.subtitleStyles),
            innerHTML: _ctx.subtitle
          }, null, 12, _hoisted_3))
        : _createCommentVNode("", true),
      _renderSlot(_ctx.$slots, "action", {}, undefined, true)
    ])
  ]))
}