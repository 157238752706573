<template>
  <div
    :class="{
      'submissions-panel__owner-submissions-view': !isUserSubmissionsView,
      'submissions-panel-empty': !hasSubmissions && !isLoading,
    }"
    class="submissions-panel"
  >
    <PxListHeader :badge-number="numberOfSubmissions" :title="tableTitle" />
    <div class="submissions-panel__table">
      <PxTableHeader
        v-if="showTableHeader"
        :items="tableHeaders"
        class="submissions-panel__table-header"
      />
      <template v-if="isLoading">
        <SubmissionsTableRowPlaceholder
          v-for="(placeholder, index) in numberOfLoadingPlaceholders"
          :key="index"
          :user-view="isUserSubmissionsView"
          class="submissions-panel__table-row"
        />
      </template>
      <template v-else-if="!hasSubmissions && !isLoading">
        <PxEmptyMessage
          :icon="emptyCopy.icon ? emptyCopy.icon : 'lists-empty-state'"
          icon-size="auto"
          :subtitle="emptyCopy.subtitle"
          :subtitle-size="15"
          :title="emptyCopy.title"
          :title-size="17"
          class="submissions-panel-empty__message"
          subtitle-style="normal"
        >
          <template #action>
            <PxButton
              v-if="emptyCopy.action"
              :label="emptyCopy.action"
              class="submissions-panel-empty__action"
              type="link"
              @click="goToProgramsPage"
            />
          </template>
        </PxEmptyMessage>
      </template>
      <div
        v-else
        ref="table"
        :style="{
          minHeight: $refs.table
            ? ($refs.table as any).offsetHeight + 'px'
            : '0px',
        }"
        class="submissions-panel__table-rows"
        @scroll="checkTableScroll"
      >
        <SubmissionsTableRow
          v-for="(submission, index) in submissionsData"
          :key="index"
          :is-user-submissions-view="isUserSubmissionsView"
          :submission-data="submission"
          :type="type"
          class="submissions-panel__table-row"
        />
      </div>
    </div>
    <div v-if="isGradientVisible" class="submissions-panel__gradient" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import PxListHeader from "@/components/px-list-header/px-list-header.vue";
import PxTableHeader, {
  ITableHeaderItems,
} from "@/components/px-table-header/px-table-header.vue";
import PxEmptyMessage from "@/components/px-empty-message/px-empty-message.vue";
import SubmissionsTableRow from "@/modules/submissions/components/submissions-table/submissions-table-row.vue";
import SubmissionsTableRowPlaceholder from "@/modules/submissions/components/submissions-table/submissions-table-row-placeholder.vue";

import { TranslateResult } from "vue-i18n";
import { EAffiliateSubmissionType } from "@/modules/submissions/services/data/submissions/submissions.interface";
import { VILCAP_PROGRAMS_PAGE } from "@/modules/submissions/constants";
import { IAffiliateSubmission } from "@/modules/submissions/services/data/submissions/submissions.interface";

interface IEmptyCopy {
  title: string;
  subtitle?: string;
  icon?: string;
  action?: string;
}

export default defineComponent({
  name: "SubmissionsPanel",

  components: {
    PxListHeader,
    PxTableHeader,
    PxEmptyMessage,
    SubmissionsTableRow,
    SubmissionsTableRowPlaceholder,
  },

  props: {
    type: {
      type: String,
      default: EAffiliateSubmissionType.DRAFT,
      validator(value: EAffiliateSubmissionType) {
        return Object.values(EAffiliateSubmissionType).includes(value);
      },
    },

    submissionsData: {
      type: Array as () => Array<IAffiliateSubmission>,
      default: () => [],
    },

    isLoading: {
      type: Boolean,
      default: false,
    },

    isUserSubmissionsView: {
      type: Boolean,
      default: false,
    },

    showEmptyResultsCopy: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      numberOfLoadingPlaceholders: 4,
      visibleRows: 4,
      tableScrollAtEnd: false,
      table: (null as any) || null,
    };
  },

  computed: {
    tableTitle(): TranslateResult {
      if (this.type === EAffiliateSubmissionType.DRAFT) {
        return this.isUserSubmissionsView
          ? this.$t(`userSubmissions.table.types[${0}]`)
          : this.$t(`ownedAffiliatesSubmissions.table.types[${0}]`);
      } else {
        return this.isUserSubmissionsView
          ? this.$t(`userSubmissions.table.types[${1}]`)
          : this.$t(`ownedAffiliatesSubmissions.table.types[${1}]`);
      }
    },

    emptyCopy(): IEmptyCopy {
      if (this.showEmptyResultsCopy) {
        return this.$tm(
          "ownedAffiliatesSubmissions.table.emptyResults",
        ) as IEmptyCopy;
      }

      const emptyStateKey = this.getEmptyStateKey(
        this.type,
        this.isUserSubmissionsView,
      );

      return this.$tm(emptyStateKey) as IEmptyCopy;
    },

    tableHeaders() {
      return this.isUserSubmissionsView
        ? (this.$tm("userSubmissions.table.headers") as ITableHeaderItems[])
        : (this.$tm(
            "ownedAffiliatesSubmissions.table.headers",
          ) as ITableHeaderItems[]);
    },

    showTableHeader(): boolean {
      return this.isLoading || (!this.isLoading && this.hasSubmissions);
    },

    hasSubmissions(): boolean {
      return !!this.submissionsData.length;
    },

    numberOfSubmissions(): number | null {
      return this.hasSubmissions && !this.isLoading
        ? this.submissionsData.length
        : null;
    },

    isGradientVisible(): boolean {
      return (
        this.submissionsData.length > this.visibleRows && !this.tableScrollAtEnd
      );
    },
  },

  methods: {
    getEmptyStateKey(type: string, isUserView: boolean) {
      const baseKey = isUserView
        ? "userSubmissions.table.emptyState"
        : "ownedAffiliatesSubmissions.table.emptyState";

      return `${baseKey}.${type}`;
    },

    goToProgramsPage() {
      window.open(VILCAP_PROGRAMS_PAGE, "_blank");
    },

    checkTableScroll() {
      this.table = this.$refs.table as HTMLElement;

      if (!this.table) return;

      this.tableScrollAtEnd =
        this.table.scrollTop >=
        this.table.scrollHeight - this.table.offsetHeight;
    },
  },
});
</script>

<style lang="scss" scoped>
.submissions-panel {
  position: relative;
  margin-top: 32px;

  background-color: $white;
  border: 1px solid $pale-grey;
  border-radius: 4px;
  box-shadow: 0 2px 11px 0 rgba(0, 0, 0, 0.05);

  &__table {
    padding: 16px 8px 16px 29px;
  }

  &__table-header {
    display: grid;
    grid-template-columns: 38% 16.7% repeat(2, 1fr);

    padding: 17px 0 15px;
    margin-right: 21px;
  }

  &__table-row:not(:last-of-type) {
    border-bottom: 1px solid $athens-gray;
  }

  :deep(.px-table-header__item) {
    p {
      line-height: 12px;
    }

    .icon-question {
      width: 14px;
      height: 14px;
      margin-left: 5px;
    }
  }

  &__table-rows {
    max-height: 291px;
    overflow-x: hidden;
    overflow-y: auto;
    padding-right: 21px;

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background: $mischka;
      border-radius: 10px;
    }
  }

  &__gradient {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 80px;
    background: linear-gradient(
      to top,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 1) 20%,
      rgba(247, 249, 249, 0) 100%
    );
    z-index: 1;
  }

  :deep(.px-list-header) {
    padding: 15px 0 18px 24px;
  }
}

.submissions-panel-empty {
  display: flex;
  flex-direction: column;
  min-height: 438px;

  &__message {
    :deep(h4) {
      @include grotesk(semiBold);

      padding: 17px 20px 0;
      font-size: 17px;
      line-height: 1.88;
      margin: 0;
    }

    :deep(.px-empty-message__wrapper p) {
      @include grotesk(medium);

      padding: 0;
      font-style: normal;
      font-size: 15px;
      line-height: 1.67;
      letter-spacing: -0.2px;
    }

    @include breakpoint-down(md) {
      :deep(.px-empty-message__wrapper) {
        flex-direction: column;
      }
    }
  }

  &__action {
    @include grotesk(semiBold);

    position: relative;
    bottom: 0;
    left: 2px;
    margin-left: 1px;
    height: auto;
    padding: 0;
    color: $bluish;
    text-decoration: underline;

    :deep(span) {
      letter-spacing: -0.2px;
    }
  }

  .submissions-panel__table {
    padding: 0;
    flex: 1;

    display: flex;
    justify-content: center;
  }
}

.submissions-panel__owner-submissions-view {
  .submissions-panel__table-header {
    grid-template-columns: 18.3% 37.9% auto 21.1%;
  }
}
</style>
