import { normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PxPanel = _resolveComponent("PxPanel")!
  const _directive_markdown = _resolveDirective("markdown")!

  return (_openBlock(), _createBlock(_component_PxPanel, {
    title: _ctx.category.requirements_title,
    class: "category-requirements",
    icon: "know-more"
  }, {
    default: _withCtx(() => [
      _withDirectives(_createElementVNode("div", {
        class: _normalizeClass([{ 'description-cmp--disabled': _ctx.level === 0 }, "description-cmp"])
      }, null, 2), [
        [_directive_markdown, _ctx.levelDescription]
      ])
    ]),
    _: 1
  }, 8, ["title"]))
}