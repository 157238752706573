import {
  GenericProvider,
  Provider,
  EProviderFeatures,
} from "@/services/data/generic-provider";
import { baseAPIUrl } from "@/services/utils/utils";
import { ITeamMember } from "@/modules/team-management/services/data/team-members/team-members.interface";

@Provider(`${baseAPIUrl}/team-members`, [
  EProviderFeatures.LIST,
  EProviderFeatures.CREATE,
])
class TeamMembersProvider extends GenericProvider<ITeamMember> {}

export const teamMembersProvider = new TeamMembersProvider();
