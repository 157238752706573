import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-0f15f8f7"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "submission-tab-team-members" }
const _hoisted_2 = { class: "submission-tab-team-members__print-title" }
const _hoisted_3 = { class: "submission-tab-team-members__data" }
const _hoisted_4 = { class: "submission-tab-team-members__position" }
const _hoisted_5 = { class: "submission-tab-team-members__email" }
const _hoisted_6 = {
  key: 0,
  class: "submission-tab-team-members__responses-wrapper"
}
const _hoisted_7 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ElCollapseItem = _resolveComponent("ElCollapseItem")!
  const _component_ElCollapse = _resolveComponent("ElCollapse")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h2", _hoisted_2, _toDisplayString(_ctx.$t("supporters.results.submissionPanel.tabs.teamMembers")), 1),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.teamMembers, (member) => {
      return (_openBlock(), _createBlock(_component_ElCollapse, {
        key: member.id,
        accordion: true,
        class: "submission-tab-team-members__wrapper"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_ElCollapseItem, {
            disabled: !member.responses.length,
            class: "submission-tab-team-members__item"
          }, {
            title: _withCtx(() => [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("h4", null, _toDisplayString(member.first_name) + " " + _toDisplayString(member.last_name), 1),
                _createElementVNode("p", _hoisted_4, _toDisplayString(member.position), 1),
                _createElementVNode("p", _hoisted_5, _toDisplayString(member.email), 1)
              ])
            ]),
            default: _withCtx(() => [
              (member.responses.length)
                ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(member.responses, (response) => {
                      return (_openBlock(), _createElementBlock("div", {
                        key: response.id,
                        class: "submission-tab-team-members__response"
                      }, [
                        _createElementVNode("h4", null, _toDisplayString(response.question.resource_question), 1),
                        _createElementVNode("p", {
                          innerHTML: _ctx.formattedResponse(response)
                        }, null, 8, _hoisted_7)
                      ]))
                    }), 128))
                  ]))
                : _createCommentVNode("", true)
            ]),
            _: 2
          }, 1032, ["disabled"])
        ]),
        _: 2
      }, 1024))
    }), 128))
  ]))
}