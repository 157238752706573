import { IBaseState } from "@/services/store/store-types";
import { IAssessment } from "@/services/data/assessment/assessment.interface";

/**
 * Interface that represents the store structure for the
 * latest assessment module for the authenticated user.
 */
export interface IAuthLatestAssessmentState extends IBaseState {
  data: IAssessment | null;
}

export enum EAuthLatestAssessmentActions {
  FETCH = "auth/latestAssessment/fetch",
  RESET = "auth/latestAssessment/reset",
}

export enum EAuthLatestAssessmentMutations {
  SET_LOADING = "SET_LOADING",
  SET_ERROR = "SET_ERROR",
  SET_DATA = "SET_DATA",
}
