<template>
  <div class="panel-grid__block">
    <NetworksEditModal
      v-if="$user.isOwner() && isNetworksEditModalVisible"
      ref="networksEditModal"
      v-model:visibility="isNetworksEditModalVisible"
      @submit="fetchCompanyData"
    />
    <!-- Menu outiside card for mobile -->
    <div v-if="!$screen.mdUp" class="profile-headline">
      <div class="profile-headline__text-wrapper">
        <h2 class="profile-headline__title">
          {{ $t("profile.networks.title") }}
        </h2>
      </div>
      <PxButton
        v-if="networks.length && $user.isOwner()"
        class="profile-headline__updateBtn"
        size="small"
        @click="onClickEditNetworks"
      >
        {{ $t("profile.networks.update") }}
      </PxButton>
    </div>
    <PxPanel
      v-loading="isLoading"
      :class="{ 'profile-panel--disabled': !hasUserAccess }"
      class="profile-panel"
    >
      <div v-if="$screen.mdUp" class="profile-headline">
        <div class="profile-headline__text-wrapper">
          <h2 class="profile-headline__title">
            {{ $t("profile.networks.title") }}
          </h2>
        </div>
        <PxButton
          v-if="networks.length && $user.isOwner() && !isLoading"
          class="profile-headline__updateBtn"
          size="small"
          @click="onClickEditNetworks"
        >
          {{ $t("profile.networks.update") }}
        </PxButton>
      </div>
      <NetworksList
        v-if="networks.length"
        :items="networks"
        class="networks-panel__list"
      />
      <PanelEmptyState v-else icon="networks">
        <p>
          {{
            $t(`profile.networks.emptyState.description[${+$user.isOwner()}]`)
          }}
        </p>
        <PxButton
          v-if="$user.isOwner()"
          size="medium"
          type="primary"
          @click="onClickEditNetworks"
        >
          {{ $t("profile.networks.emptyState.button") }}
        </PxButton>
      </PanelEmptyState>
    </PxPanel>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { ICompany } from "@/modules/profile/services/data/company/company.types";
import { INetwork } from "@/services/data/network/network.interface";

import NetworksList from "@/modules/profile/components/networks-list/networks-list.vue";
import NetworksEditModal from "@/modules/profile/components/networks-edit-modal/networks-edit-modal.vue";
import PanelEmptyState from "@/modules/profile/components/panel-empty-state/panel-empty-state.vue";

import { EProfileCompanyActions } from "@/modules/profile/services/store/profile/profile-types";

export default defineComponent({
  name: "NetworksPanel",

  components: {
    NetworksList,
    NetworksEditModal,
    PanelEmptyState,
  },

  data() {
    return {
      isNetworksEditModalVisible: false,
    };
  },

  computed: {
    company(): ICompany | null {
      return this.$store.get("profile/company.data");
    },

    companyId(): number | null {
      return this.company ? this.company.id : null;
    },

    isLoading(): boolean {
      return this.$store.get("profile/company.loading");
    },

    networks(): Array<INetwork> {
      return this.company && this.company.networks ? this.company.networks : [];
    },

    isSpecialGuest(): boolean {
      return !!this.$route.meta?.specialGuest;
    },

    hasUserAccess(): boolean {
      return this.$user.isLogged() || this.isSpecialGuest;
    },
  },

  methods: {
    onClickEditNetworks() {
      this.isNetworksEditModalVisible = true;
    },

    async fetchCompanyData() {
      if (this.companyId) {
        await this.$store.dispatch(
          EProfileCompanyActions.FETCH,
          this.companyId,
        );
      }
    },
  },
});
</script>

<style lang="scss" scoped>
.profile-panel {
  @include breakpoint-only(md) {
    .networks-panel__list {
      padding: 0 26px 10px;
    }
  }

  .profile-headline {
    padding: 25px 26px 30px;
  }

  .profile-headline__text-wrapper .profile-headline__title {
    margin-top: 3px;
  }

  :deep() .px-panel__body {
    @include breakpoint-up(md) {
      padding: 0;
    }
  }
}
</style>
