import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-254deb01"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "auth-base-login" }
const _hoisted_2 = { class: "page-wrapper" }
const _hoisted_3 = { class: "page-container" }
const _hoisted_4 = { class: "page-headline" }
const _hoisted_5 = { class: "page-block" }
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = { class: "auth-base-login__remember-wrapper" }
const _hoisted_8 = { class: "auth-base-login__footer" }
const _hoisted_9 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LevelBar = _resolveComponent("LevelBar")!
  const _component_LevelRangeBar = _resolveComponent("LevelRangeBar")!
  const _component_ElInput = _resolveComponent("ElInput")!
  const _component_ElFormItem = _resolveComponent("ElFormItem")!
  const _component_PxInputPassword = _resolveComponent("PxInputPassword")!
  const _component_ElCheckbox = _resolveComponent("ElCheckbox")!
  const _component_PxButton = _resolveComponent("PxButton")!
  const _component_ElForm = _resolveComponent("ElForm")!
  const _component_PxPanel = _resolveComponent("PxPanel")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.isFromAssessment)
      ? (_openBlock(), _createBlock(_component_LevelBar, {
          key: 0,
          level: _ctx.assessmentLevel
        }, null, 8, ["level"]))
      : (_ctx.isFromInvestingSelection)
        ? (_openBlock(), _createBlock(_component_LevelRangeBar, {
            key: 1,
            range: _ctx.investingRangeLevel
          }, null, 8, ["range"]))
        : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h1", _hoisted_4, _toDisplayString(_ctx.$t("authentication.login.title")), 1),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_PxPanel, { class: "px-panel--compact" }, {
            header: _withCtx(() => [
              _createElementVNode("h2", {
                class: "px-panel__title",
                innerHTML: _ctx.$t('authentication.login.form.title')
              }, null, 8, _hoisted_6)
            ]),
            default: _withCtx(() => [
              _createVNode(_component_ElForm, {
                ref: "form",
                model: _ctx.data,
                rules: _ctx.rules,
                class: "auth-base-login__form"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ElFormItem, {
                    class: _normalizeClass({ 'is-error': _ctx.showEmailAsError }),
                    label: _ctx.$t('authentication.login.form.fields.email'),
                    prop: "email"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ElInput, {
                        modelValue: _ctx.data.email,
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.data.email) = $event)),
                        placeholder: _ctx.$t('authentication.login.form.fields.email')
                      }, null, 8, ["modelValue", "placeholder"])
                    ]),
                    _: 1
                  }, 8, ["class", "label"]),
                  _createVNode(_component_ElFormItem, {
                    error: 
                  _ctx.errors.getMessage('non_field_errors', 'email_not_verified')
                ,
                    label: _ctx.$t('authentication.login.form.fields.password'),
                    prop: "password"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_PxInputPassword, {
                        modelValue: _ctx.data.password,
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.data.password) = $event)),
                        placeholder: _ctx.$t('authentication.login.form.fields.password')
                      }, null, 8, ["modelValue", "placeholder"])
                    ]),
                    _: 1
                  }, 8, ["error", "label"]),
                  _createVNode(_component_ElFormItem, null, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_7, [
                        _createVNode(_component_ElCheckbox, {
                          modelValue: _ctx.data.remember,
                          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.data.remember) = $event)),
                          class: "auth-base-login__remember"
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t("authentication.login.form.fields.remember")), 1)
                          ]),
                          _: 1
                        }, 8, ["modelValue"]),
                        _createVNode(_component_PxButton, {
                          class: "el-button--link-blue auth-base-login__forget",
                          size: "small",
                          type: "link",
                          onClick: _ctx.onClickPasswordRecover
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t("authentication.login.forgetPasswordLink")), 1)
                          ]),
                          _: 1
                        }, 8, ["onClick"])
                      ])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_PxButton, {
                    disabled: _ctx.isLoadingBtnDisabled,
                    loading: _ctx.loading,
                    class: "el-button--block auth-base-login__loginBtn",
                    type: "primary",
                    onClick: _ctx.onClickLogin
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t("authentication.login.form.submitBtn")), 1)
                    ]),
                    _: 1
                  }, 8, ["disabled", "loading", "onClick"])
                ]),
                _: 1
              }, 8, ["model", "rules"])
            ]),
            _: 1
          })
        ]),
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("p", {
            class: "auth-base-login__message",
            innerHTML: _ctx.$t('authentication.login.footerMessage')
          }, null, 8, _hoisted_9),
          _createVNode(_component_PxButton, {
            ref: "signUpButton",
            class: "el-button--inline el-button--link-blue auth-base-login__signUpBtn",
            type: "link",
            onClick: _ctx.onClickSignUp
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("authentication.login.signUpLink")), 1)
            ]),
            _: 1
          }, 8, ["onClick"])
        ])
      ])
    ])
  ]))
}