import {
  GenericProvider,
  EProviderFeatures,
} from "@/services/data/generic-provider";
import { IViralLevelSelection } from "@/services/data/viral-level/viral-level.interface";

class ViralLevelProvider extends GenericProvider<IViralLevelSelection> {
  constructor() {
    super(`/viral_level/`, [EProviderFeatures.CREATE]);
  }
}

export const viralLevelProvider = new ViralLevelProvider();
