<template>
  <ElDialog
    ref="details-modal"
    v-model="innerVisibility"
    :append-to-body="true"
    :data-active-tab="activeTab"
    class="details-modal"
    top="0"
  >
    <template #header>
      <div class="details-modal__header">
        <h3 class="details-modal__title">
          {{ capitalInfo.title }}
        </h3>
        <div
          v-if="showMatchingBadge && matchProperties.matchingBadge"
          class="details-modal__matching-badge"
        >
          <PxStatusBadge
            :background-color="matchProperties.matchingBadge.backgroundColor"
            :border-color="matchProperties.matchingBadge.borderColor"
            :icon="matchProperties.matchingBadge.icon"
            :icon-size="13"
            :label="matchingBadgeLabel"
            :label-color="matchProperties.matchingBadge.textColor"
            :label-size="14"
            :style="matchingBadgeStyles"
            :uppercase="false"
            :weight="600"
            data-testid="details-modal-matching-badge"
          />
        </div>
      </div>
      <PxNavigationTab
        v-model:selected-tab="activeTab"
        :icon-size="15"
        :navigation-tabs="visibleTabs"
        :show-number="false"
        data-testid="details-modal-navigation-tabs"
      />
    </template>
    <div
      v-if="activeTab !== 5"
      class="details-modal__content"
      v-html="tabContent"
    />
    <div v-else class="details-modal__content-matching">
      <PxTableHeader :items="tableHeaders" />
      <div ref="table" class="details-modal__table-wrapper">
        <template v-for="(detail, index) in matchDetailsOrdered">
          <MatchDetailsTableRow
            v-if="detail.question"
            :key="index"
            :auth-user-answer="detail.userAnswers"
            :criteria="detail.question.title"
            :has-match="detail.match"
            :instructions="detail.question.instructions"
            :list-format="true"
            :profile-user-answer="detail.desiredAnswers"
            :show-learn-more-link="true"
            icon-match="match--green"
          />
        </template>
      </div>
      <MatchDetailsTableLegend
        :legend-cards="legend"
        class="details-modal__table-legend"
      />
    </div>
    <template v-if="activeTab !== 5" #footer>
      <a
        v-for="({ label, icon, type, href }, index) in footerButtons"
        :key="index"
        :class="`el-button el-button--${type} el-button--small`"
        :href="href"
        target="_blank"
      >
        <span>
          <PxIcon :name="icon" :size="16" />
          <div>
            {{ label }}
          </div>
        </span>
      </a>
    </template>
  </ElDialog>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import ElDialogMixin from "@/mixins/el-dialog.mixin";
import PxNavigationTab, {
  INavigationTab,
} from "@/components/px-navigation-tab/px-navigation-tab.vue";
import PxStatusBadge from "@/components/px-status-badge/px-status-badge.vue";
import PxTableHeader, {
  ITableHeaderItems,
} from "@/components/px-table-header/px-table-header.vue";
import MatchDetailsTableRow from "@/components/match-details-table/match-details-table-row.vue";
import MatchDetailsTableLegend, {
  ILegendCard,
} from "@/components/match-details-table/match-details-table-legend.vue";

import {
  ICapitalTile,
  ICapitalMatchProperties,
  ICapitalMatchingDetails,
} from "@/modules/capital-explorer/services/data/capital-explorer/capital-explorer.interface";
import cloneDeep from "lodash/cloneDeep";
import { IMatchingQuestion } from "@/services/data/matching-questionary/matching-question.interface";
import { EPxButtonType } from "@/components/px-button/px-button.types";
import { CAPITAL_EXPLORER_VISITORS_VIEW } from "@/modules/capital-explorer/services/router/routes-names";

export default defineComponent({
  name: "DetailsModal",

  components: {
    PxNavigationTab,
    PxStatusBadge,
    PxTableHeader,
    MatchDetailsTableRow,
    MatchDetailsTableLegend,
  },

  mixins: [ElDialogMixin],

  props: {
    capitalInfo: {
      type: Object as () => ICapitalTile,
      required: true,
    },

    matchProperties: {
      type: Object as () => ICapitalMatchProperties,
      required: true,
    },

    matchDetails: {
      type: Array as () => ICapitalMatchingDetails[],
      required: true,
    },

    showMatchingBadge: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      activeTab: 1,
      footerButtons: [
        {
          label: this.$t("capitalExplorer.detailsModal.footerButtons[0].label"),
          icon: "link-grey",
          type: EPxButtonType.DEFAULT,
          href: this.$t(
            "capitalExplorer.detailsModal.footerButtons[0].href",
          ) as string,
        },
        {
          label: this.$t("capitalExplorer.detailsModal.footerButtons[1].label"),
          icon: "icon-briefcase-white",
          type: EPxButtonType.PRIMARY,
          href: this.$t(
            "capitalExplorer.detailsModal.footerButtons[1].href",
          ) as string,
        },
      ],
    };
  },

  computed: {
    questions(): IMatchingQuestion[] {
      return this.$store.get("capitalExplorer/data").questions;
    },

    matchDetailsOrdered(): ICapitalMatchingDetails[] {
      return cloneDeep(this.matchDetails).sort(
        (a, b) =>
          this.questions.findIndex((q) => q.id === a?.question.id) -
          this.questions.findIndex((q) => q.id === b?.question.id),
      );
    },

    tabs(): INavigationTab[] {
      return [
        {
          id: 1,
          title: this.$t("capitalExplorer.detailsModal.tabs[0]"),
          visible: !!this.capitalInfo.about,
        },
        {
          id: 2,
          title: this.$t("capitalExplorer.detailsModal.tabs[1]"),
          visible: !!this.capitalInfo.key_characteristics,
        },
        {
          id: 3,
          title: this.$t("capitalExplorer.detailsModal.tabs[2]"),
          visible: !!this.capitalInfo.key_implications,
        },
        {
          id: 4,
          title: this.$t("capitalExplorer.detailsModal.tabs[3]"),
          visible: !!this.capitalInfo.related_links,
        },
        {
          id: 5,
          title: this.$t("capitalExplorer.detailsModal.tabs[4]"),
          icon: "matching--white",
          visible: true,
        },
      ];
    },

    visibleTabs(): INavigationTab[] {
      return this.tabs.filter((tab) => tab.visible);
    },

    tabContent(): string | null {
      return this.activeTab === 1
        ? this.capitalInfo.about
        : this.activeTab === 2
          ? this.capitalInfo.key_characteristics
          : this.activeTab === 3
            ? this.capitalInfo.key_implications
            : this.capitalInfo.related_links;
    },

    matchingBadgeLabel(): string {
      return this.matchProperties.matchingBadge
        ? this.$t(this.matchProperties.matchingBadge?.title)
        : "";
    },

    matchingBadgeStyles(): { [key: string]: string | number } {
      return {
        boxShadow: this.matchProperties.matchingBadge?.boxShadow || "",
      };
    },

    tableHeaders() {
      const tableHeaders = cloneDeep(
        this.$tm(
          "capitalExplorer.detailsModal.tableHeader",
        ) as ITableHeaderItems[],
      );

      const isVisitorsView =
        this.$route.name === CAPITAL_EXPLORER_VISITORS_VIEW;

      tableHeaders[1].title = tableHeaders[1].title[isVisitorsView ? 1 : 0];

      return tableHeaders;
    },

    legend() {
      return this.$tm(`capitalExplorer.detailsModal.legend`) as ILegendCard[];
    },
  },
});
</script>

<style lang="scss">
.details-modal {
  margin: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  max-width: 903px;
  width: 100%;

  .el-dialog__header {
    @include grotesk(regular);

    padding: 23px 0 0 30px;
    background-color: $white;

    > .px-navigation-tab {
      justify-content: flex-start;
    }

    > .px-navigation-tab .px-navigation-tab-item__container {
      padding: 0 21px 14px 20px;
    }

    > .px-navigation-tab .px-navigation-tab-item--active {
      padding-bottom: 10px;
    }

    > .px-navigation-tab .px-navigation-tab-item__border {
      height: 4px;
    }

    > .px-navigation-tab .px-navigation-tab-item__title {
      letter-spacing: -0.13px;
      white-space: nowrap;
    }

    > .px-navigation-tab
      .px-navigation-tab-item__container:not(.px-navigation-tab-item--active)
      .px-icon {
      filter: invert(41%) sepia(0) saturate(0%) hue-rotate(0deg) brightness(86%)
        contrast(100%);
    }
  }

  .el-dialog__headerbtn {
    top: 27px;
    right: 25px;
    width: 20px;
    height: 20px;
  }

  &__title {
    margin-bottom: 43px;
    padding-left: 10px;

    font-size: 22px;
    font-weight: 600;
    color: $ebony-clay;
    line-height: 26px;
    letter-spacing: -0.22px;
  }

  &__header {
    display: flex;
    gap: 13px;
  }

  &__content {
    max-height: 100%;
    margin-right: 5px;

    padding: 33px 28px 24px 40px;

    overflow-y: scroll;

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
      margin: 10px;
    }

    &::-webkit-scrollbar-thumb {
      background: $mischka;
      border-radius: 10px;
    }

    h4 {
      margin: 0 0 4px 0;

      font-size: 16px;
      color: $ebony-clay;
    }

    a {
      line-height: 2;
    }

    ul {
      padding-left: 42px;
    }

    li {
      margin: 4px 0 0 0;
    }

    p {
      margin: 0 0 19px 24px;
    }

    li,
    p {
      font-size: 16px;
      line-height: 1.8;
      color: $ebony-clay;
      letter-spacing: -0.35px;
    }
  }

  &__content-matching {
    position: relative;
    height: 100%;
    padding: 12px 29px 0 31px;

    background-color: $alabaster;
  }

  &__table-wrapper {
    max-height: 495px;

    overflow-y: scroll;

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
      margin: 10px;
    }

    &::-webkit-scrollbar-thumb {
      background: $mischka;
      border-radius: 10px;
    }
  }

  &__matching-badge {
    max-width: 137px;
    position: relative;
    top: 1px;

    .px-status-badge {
      padding: 5px 9px;
      gap: 5px;

      width: max-content;
    }

    .px-status-badge__label {
      margin: 0;
    }
  }

  &__table-legend {
    position: absolute;
    right: 20px;
    bottom: 21px;
    z-index: z("floaters") + 2;

    .el-collapse-item__header {
      padding: 5px 32px 5px 12px;
      border-radius: 8px;
      box-shadow: 0 2px 8px 0 rgba(39, 39, 64, 0.12);
    }

    .el-collapse-item.is-active .el-collapse-item__header {
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
      box-shadow: 0 -7px 8px 0 rgba(39, 39, 64, 0.03);
    }

    .el-collapse-item__header::before {
      top: 2px;
      right: 12px;
    }

    .el-collapse-item__header:hover::before {
      filter: invert(60%);
    }

    .el-collapse-item__header:hover span {
      color: $manatee;
    }

    .el-collapse-item__wrap {
      max-width: 864px;
      margin: 0;
      padding: 12px;
      border-radius: 8px 0 8px 8px;
      box-shadow: 0 2px 8px 0 rgba(39, 39, 64, 0.12);
    }

    .match-details-table-legend-card {
      padding: 10px 13px 13px 11px;
    }
  }

  .el-dialog__body {
    height: 570px;
    padding: 0;

    border-radius: 4px;
  }

  .el-dialog__body > .details-modal__content {
    height: calc(100% - 33px);
  }

  .el-dialog__footer {
    display: flex;
    justify-content: center;
    gap: 16px;
  }

  .el-dialog__footer a.el-button {
    text-decoration: none;
  }

  .el-dialog__footer a.el-button .px-icon + div {
    margin-left: 6px;
  }

  .px-table-header {
    padding-top: 1px;
  }

  .px-table-header__item {
    min-width: 120px;
    padding: 12px 0;
  }

  .px-table-header__item:not(:last-of-type) {
    flex: 1;
    padding-left: 12px;
  }

  .px-table-header__item:last-of-type {
    justify-content: center;
  }

  .px-table-header__item:nth-of-type(3) p {
    letter-spacing: -0.2px;
  }

  .px-table-header__item .icon-question {
    width: 14px;
    height: 14px;
    margin-left: 6px;
  }

  .match-details-table-row {
    grid-template-columns: 1fr 1fr 1fr 120px;
  }

  .match-details-table-row__wrapper:not(:last-of-type) {
    border-bottom: 1px solid $athens-gray;
  }

  .match-details-table-row__item {
    align-items: flex-start;
    font-size: 15px;
    line-height: 22px;
    letter-spacing: -0.33px;
  }

  .matching-card-tag-list__container {
    flex-direction: column;
    text-transform: none;

    ul {
      padding: 0;
      margin: 0 0 0 9px;
    }

    li {
      position: relative;

      padding: 0 10px 0 2px;
      letter-spacing: -0.3px;
      line-height: 19px;

      list-style-type: none;
    }

    li::before {
      position: absolute;
      top: 49%;
      left: -9px;
      width: 3px;
      height: 3px;
      content: "";
      background-color: inherit;
      border-radius: 50%;
    }
  }

  .matching-card-tag-list__container.px-more-list {
    margin-top: 2px;
    margin-left: 11px;
  }

  .matching-card-tag-list__container.px-list-modal__item {
    text-transform: none;
  }

  &:not([data-active-tab="5"]) .el-dialog__header,
  &:not([data-active-tab="5"]) .el-dialog__footer {
    background-color: $alabaster;
  }
}
</style>
