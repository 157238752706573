export default {
  install() {
    // Disable pinch zoom
    document.addEventListener(
      "gesturestart",
      (event) => {
        event.preventDefault();
      },
      { passive: false },
    );

    document.addEventListener(
      "gesturechange",
      (event) => {
        event.preventDefault();
      },
      { passive: false },
    );

    document.addEventListener(
      "gestureend",
      (event) => {
        event.preventDefault();
      },
      { passive: false },
    );

    // Disable double tap zoom
    let lastTouchEnd = 0;
    document.addEventListener(
      "touchend",
      (event) => {
        const now = new Date().getTime();
        if (now - lastTouchEnd <= 300) {
          event.preventDefault();
        }
        lastTouchEnd = now;
      },
      { passive: false },
    );
  },
};
