export const COOKIE_POLICY = "cookiePolicy";

// Default route for the non-logged users
export const HOME = "home";

// Default route for the logged users
export const ROUTE_LOGGED_HOME = "profile";

// Default route for the error pages
export const ERROR_ROUTE = "error";

// Route generic guest meta
export const ROUTE_ONLY_GUESTS = {
  guest: true,
};
