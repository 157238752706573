import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-2beb2adb"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "share-modal-users-search__form" }
const _hoisted_2 = {
  class: "el-form-item__label",
  for: "user-search"
}
const _hoisted_3 = { class: "share-modal-users-search__option-wrapper" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = ["alt", "src"]
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = ["innerHTML"]
const _hoisted_8 = { class: "el-form-item__bottom-text--strong" }
const _hoisted_9 = {
  key: 0,
  class: "share-modal-users-search__submission"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_i18n_t = _resolveComponent("i18n-t")!
  const _component_ElOption = _resolveComponent("ElOption")!
  const _component_ElSelect = _resolveComponent("ElSelect")!
  const _component_PxButton = _resolveComponent("PxButton")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([{ 'is-active': _ctx.onSearchMode }, "share-modal-users-search"])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("label", _hoisted_2, _toDisplayString(_ctx.$t(`${_ctx.commonShareCopy}.searchInput.label`)), 1),
      _createVNode(_component_ElSelect, {
        id: "user-search",
        ref: "select",
        modelValue: _ctx.selectedUsers,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedUsers) = $event)),
        class: _normalizeClass([{ 'has-users': _ctx.selectedUsers.length }, "share-modal-users-search__input"]),
        "data-suffix": _ctx.$t(`${_ctx.commonShareCopy}.searchInput.clear`),
        loading: _ctx.isLoading,
        placeholder: _ctx.$t(`${_ctx.commonShareCopy}.searchInput.placeholder`),
        "remote-method": _ctx.searchForUsers,
        "filter-method": _ctx.handleSelectChange,
        "reserve-keyword": false,
        clearable: "",
        "default-first-option": "",
        filterable: "",
        multiple: "",
        "popper-class": "share-modal-users-popper",
        remote: "",
        onClear: _ctx.clearHandler,
        onFocus: _ctx.focusHandler,
        onVisibleChange: _ctx.dropdownChangeHandler
      }, {
        empty: _withCtx(() => [
          _withDirectives((_openBlock(), _createElementBlock("div", null, [
            _createVNode(_component_i18n_t, {
              keypath: `${_ctx.commonShareCopy}.searchSelect.empty.title.content`,
              tag: "span"
            }, {
              user: _withCtx(() => [
                _createElementVNode("strong", null, _toDisplayString(_ctx.$t(
                      `${_ctx.commonShareCopy}.searchSelect.empty.title.placeholders[0]`,
                    )), 1)
              ]),
              account: _withCtx(() => [
                _createElementVNode("strong", null, _toDisplayString(_ctx.$t(
                      `${_ctx.commonShareCopy}.searchSelect.empty.title.placeholders[1]`,
                    )), 1)
              ]),
              _: 1
            }, 8, ["keypath"]),
            _createElementVNode("span", null, _toDisplayString(_ctx.$t(`${_ctx.copy}.searchSelect.empty.subtitle`)), 1)
          ])), [
            [_directive_loading, _ctx.isLoading]
          ])
        ]),
        default: _withCtx(() => [
          (_ctx.hasEmptyInput)
            ? (_openBlock(), _createBlock(_component_ElOption, {
                key: 0,
                disabled: true,
                value: false,
                class: "share-modal-users-search__tip"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_i18n_t, {
                    keypath: `${_ctx.commonShareCopy}.searchSelect.tip.content`,
                    tag: "span"
                  }, {
                    name: _withCtx(() => [
                      _createElementVNode("strong", null, _toDisplayString(_ctx.$t(`${_ctx.commonShareCopy}.searchSelect.tip.placeholder`)), 1)
                    ]),
                    _: 1
                  }, 8, ["keypath"])
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.searchResults, (item) => {
            return (_openBlock(), _createBlock(_component_ElOption, {
              key: item.user_profile,
              label: item.name,
              value: item.user_profile,
              class: _normalizeClass(["share-modal-users-search__option", { 'is-disabled': _ctx.shouldUserBeDisabled(item.user_profile) }])
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_3, [
                  (item.logo)
                    ? (_openBlock(), _createElementBlock("picture", _hoisted_4, [
                        _createElementVNode("img", {
                          alt: item.name,
                          src: item.logo
                        }, null, 8, _hoisted_5)
                      ]))
                    : _createCommentVNode("", true),
                  _createElementVNode("h4", {
                    innerHTML: _ctx.highlightWordMatch(item.name, _ctx.searchNeedle)
                  }, null, 8, _hoisted_6),
                  (item.email)
                    ? (_openBlock(), _createElementBlock("span", {
                        key: 1,
                        innerHTML: _ctx.highlightWordMatch(item.email, _ctx.searchNeedle)
                      }, null, 8, _hoisted_7))
                    : _createCommentVNode("", true)
                ])
              ]),
              _: 2
            }, 1032, ["label", "value", "class"]))
          }), 128))
        ]),
        _: 1
      }, 8, ["modelValue", "class", "data-suffix", "loading", "placeholder", "remote-method", "filter-method", "onClear", "onFocus", "onVisibleChange"]),
      _createVNode(_component_i18n_t, {
        keypath: `${_ctx.copy}.searchInput.tip.content`,
        class: "el-form-item__bottom-text el-form-item__tip",
        tag: "span"
      }, {
        placeholder: _withCtx(() => [
          _createElementVNode("strong", _hoisted_8, _toDisplayString(_ctx.$t(`${_ctx.copy}.searchInput.tip.placeholder`)), 1)
        ]),
        _: 1
      }, 8, ["keypath"])
    ]),
    (_ctx.onSearchMode)
      ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
          _createVNode(_component_PxButton, {
            class: "share-modal-users-search__submission-link",
            size: "small",
            type: "link",
            onClick: _ctx.onClickCancelHandler
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("common.cancel")), 1)
            ]),
            _: 1
          }, 8, ["onClick"]),
          _createVNode(_component_PxButton, {
            disabled: !_ctx.hasSelectedUsers,
            class: "share-modal-users-search__submission-button",
            type: "green",
            onClick: _ctx.onClickSendEmailHandler
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("common.sendEmail")), 1)
            ]),
            _: 1
          }, 8, ["disabled", "onClick"])
        ]))
      : _createCommentVNode("", true)
  ], 2))
}