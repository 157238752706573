import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "milestone-plan-confirmation-dialog__title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PxButton = _resolveComponent("PxButton")!
  const _component_ElDialog = _resolveComponent("ElDialog")!

  return (_openBlock(), _createBlock(_component_ElDialog, {
    ref: "modal",
    modelValue: _ctx.innerVisibility,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.innerVisibility) = $event)),
    "append-to-body": true,
    "close-on-click-modal": false,
    "close-on-press-escape": false,
    class: "milestone-plan-confirmation-dialog",
    width: "500px",
    onClose: _ctx.closeModal
  }, {
    header: _withCtx(() => [
      _createElementVNode("h3", _hoisted_1, _toDisplayString(_ctx.dialogTitle), 1),
      _createElementVNode("p", {
        class: _normalizeClass([{
          'milestone-plan-confirmation-dialog__description--draft':
            (_ctx.planned && !_ctx.isPlanPublished) ||
            (_ctx.completed && !_ctx.isCompletePublished),
          'milestone-plan-confirmation-dialog__description--complete':
            _ctx.isCompletePublished,
        }, "milestone-plan-confirmation-dialog__description"])
      }, _toDisplayString(_ctx.dialogDescription), 3)
    ]),
    footer: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass([{
          'milestone-plan-confirmation-dialog__footer--grid':
            !_ctx.isPlanPublished && !_ctx.isCompletePublished,
        }, "milestone-plan-confirmation-dialog__footer"])
      }, [
        _createVNode(_component_PxButton, _mergeProps({ ref: "cancelButton" }, _ctx.cancelButtonProps, { onClick: _ctx.closeModal }), null, 16, ["onClick"]),
        (_ctx.showSaveAsDraftButton)
          ? (_openBlock(), _createBlock(_component_PxButton, _mergeProps({
              key: 0,
              ref: "saveAsDraftButton"
            }, _ctx.saveAsDraftButtonProps, { onClick: _ctx.onClickSaveAsDraft }), null, 16, ["onClick"]))
          : _createCommentVNode("", true),
        _createVNode(_component_PxButton, _mergeProps({ ref: "deleteButton" }, _ctx.deleteButtonProps, { onClick: _ctx.onClickDelete }), null, 16, ["onClick"])
      ], 2)
    ]),
    _: 1
  }, 8, ["modelValue", "onClose"]))
}