import {
  GenericProvider,
  EProviderFeatures,
  Provider,
} from "@/services/data/generic-provider";
import { IPendingUserUpdate } from "@/modules/authentication/services/data/pending-user-update/pending-user-update.interface";
import { baseAPIUrl } from "@/services/utils/utils";

/**
 * Provider used to perform an update.
 */
@Provider(`${baseAPIUrl}/pending-user/update`, [EProviderFeatures.UPDATE])
class PendingUserUpdateProvider extends GenericProvider<IPendingUserUpdate> {
  public async updateCompany(
    data: IPendingUserUpdate,
  ): Promise<IPendingUserUpdate> {
    const url = this.buildEndPointUrl();
    const request = this.httpClient.put(url, data);
    const { data: response } = await this.wrapRequest(request);

    return response as IPendingUserUpdate;
  }
}

export const pendingUserUpdateProvider = new PendingUserUpdateProvider();
