<template>
  <MatchingCardCompactWrapper
    :is-disabled="disabled"
    :is-faded="isFaded"
    :is-options-open="toggleOptions"
    :is-selected="isSelected"
    @click="onCardClick"
  >
    <template #action>
      <div
        v-if="canShowSelectMode"
        class="matching-card-compact-selector__wrapper"
      >
        <ElTooltip
          :content="checkboxTooltipCopy"
          :disabled="isInnerSelected"
          placement="top"
          popper-class="px-matching-interest-compact__tooltip el-abaca-tooltip"
        >
          <ElCheckbox
            v-model="isInnerSelected"
            class="matching-card-compact-selector"
            @click.stop
          />
        </ElTooltip>
      </div>
      <div class="matching-card-compact-options__wrapper">
        <MatchingCardOptions
          v-if="canShowOptions"
          class="matching-card-compact-options"
          @delete="deleteClickHandler"
          @is-options-open="toogleOptionsOpen"
        />
      </div>
    </template>
    <div class="matching-card-compact__section-container">
      <div class="matching-card-compact__supporter-container">
        <div class="matching-card-compact__headline-container">
          <MatchingCardThumbnail
            :level="matchLevel"
            :name="name"
            :size="48"
            :thumbnail="thumbnail"
          />
          <div class="matching-card-compact__headline">
            <h3 class="matching-card-compact__title">
              {{ name }}
            </h3>
            <p class="matching-card-compact__subtitle">
              {{ location }}
            </p>
            <div class="matching-badge-list__wrapper">
              <MatchingBadgeList
                v-if="!showPublicView && !disabled"
                :badges="badges"
                :company-name="name"
                @click="openSubmissionsModal"
              />
              <div
                v-if="!showPublicView && disabled"
                class="matching-card-compact-selector--label"
                v-text="labelText"
              />
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="showDescription"
        class="matching-card-compact__description-container"
      >
        <span v-if="!!about" class="matching-card-compact__description">
          {{ about }}
        </span>
      </div>
      <div class="matching-card-compact__sectors-container">
        <MatchingCardTagList :items="mappedSectors" :max-lines="2" />
      </div>
      <div
        v-if="showMatchingElements"
        class="matching-card-compact__right-side"
      >
        <MatchingCardPercentagePill
          v-if="showPercentage"
          :percentage="matchPercentage"
          :show-tooltip="!isInterestDetailModalVisible"
          class="matching-card-compact__percentage"
          @click.stop="onClickShowModal"
        />
        <div class="matching-card-compact__cta-container">
          <template v-if="showConnectButton">
            <PxMatchingInterestCompact
              v-if="!showPublicView && $features.isEnabled('match')"
              v-bind="$options.static.interestButton"
              :id="companyId"
              :is-directory-member="isDirectoryMember"
              :name="name"
              :uid="companyUid"
              class="matching-card__interest"
              @click.stop
            />
            <PxButton
              v-else-if="!$user.isLogged()"
              v-bind="$options.static.publicInterestButton"
              @click.stop="onRegistration"
            />
          </template>
          <PxButton
            v-if="showListButton || showPublicView"
            v-bind="$options.static.addToListButton"
            @click.stop="onShowListManagementModal"
          />
        </div>
        <MatchDetailsModal
          v-if="showInterestDetailModalVisible"
          v-model:visibility="isInterestDetailModalVisible"
          :company-logo="thumbnail"
          :company-name="name"
          :match-details="matchScoreDetails"
          data-testid="matching-card-compact-details-modal"
        />
      </div>
    </div>
    <ListManagementConfirmationDialog
      v-model:visibility="isDeletePanelVisible"
      :selected-company-name="name"
      data-testid="matching-card-compact__confirmation-modal"
      @close-options="toggleOptions = false"
      @remove-companies="onRemoveConfirmationButtonClick"
    />
    <SubmissionsModal
      v-if="submissionsModalVisible"
      v-model:visibility="submissionsModalVisible"
      :company="name"
      :data="affiliates"
    />
  </MatchingCardCompactWrapper>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import MatchingCardMixin from "@/mixins/matching-card.mixin";
import MatchingCardCompactWrapper from "@/components/matching-card/matching-card-compact-wrapper.vue";
import MatchingCardOptions from "@/components/matching-card/matching-card-options.vue";
import MatchDetailsModal from "@/components/match-details-modal/match-details-modal.vue";

import {
  EPxButtonSize,
  EPxButtonType,
  EPxButtonVariant,
} from "@/components/px-button/px-button.types";
import { EListManagementActions } from "@/modules/company-lists/services/store/list-management/list-management.types";
import ListManagementConfirmationDialog from "@/modules/company-lists/components/list-management/list-management-confirmation-dialog.vue";

export default defineComponent({
  name: "MatchingCardCompact",

  components: {
    MatchingCardCompactWrapper,
    MatchingCardOptions,
    ListManagementConfirmationDialog,
    MatchDetailsModal,
  },

  mixins: [MatchingCardMixin],

  static: {
    interestButton: {
      size: EPxButtonSize.MEDIUM,
    },

    addToListButton: {
      icon: "icon-add-to-list",
      size: EPxButtonSize.MEDIUM,
      type: EPxButtonType.DARK_BLUE,
      variant: EPxButtonVariant.COMPACT,
    },

    publicInterestButton: {
      icon: "connect",
      size: EPxButtonSize.MEDIUM,
      type: EPxButtonType.GREEN,
      variant: EPxButtonVariant.COMPACT,
    },

    cancelButton: {
      type: EPxButtonType.LINK,
    },

    removeButton: {
      type: EPxButtonType.RED,
      size: EPxButtonSize.SMALL,
    },
  },

  computed: {
    // Due to size constraints, screens lower than 1440px must hide description
    showDescription(): boolean {
      return this.$screen.xlUp;
    },

    showMatchingElements(): boolean {
      return (
        this.showPercentage ||
        this.showConnectButton ||
        this.showListButton ||
        this.showPublicView
      );
    },

    selectionCompanyList(): Array<string> {
      return this.$store.get("listManagement.data.companies") || [];
    },

    isSelectModeActive(): boolean {
      return this.showListButton && this.selectionCompanyList.length > 0;
    },

    labelText(): string {
      if (!this.label && !this.disabled) {
        return "";
      }

      return this.label || this.$t("matching.matchingList.alreadyAdded");
    },

    isSelected(): boolean {
      return (
        this.isSelectModeActive &&
        !!this.selectionCompanyList.find(
          (itemUid: string) => itemUid === this.companyUid,
        )
      );
    },

    isFaded(): boolean {
      // If card is not selected, but select mode is active, show faded card
      return this.isSelectModeActive && !this.isSelected;
    },

    cancelButtonProps(): any {
      return {
        ...this.$options.static.cancelButton,
        label: this.$t("common.cancel"),
      };
    },

    removeButtonProps(): any {
      return {
        ...this.$options.static.removeButton,
        label: this.$t("common.components.default.cta.yesRemove"),
      };
    },

    canShowSelectMode(): boolean {
      return !this.isDeletePanelVisible && this.showListButton;
    },

    canShowOptions(): boolean {
      return (
        !this.isDeletePanelVisible &&
        this.showOptions &&
        !(this.isSelected || this.isFaded)
      );
    },

    removalConfirmationCopy(): string {
      return this.$t("common.components.matchingCard.removalConfirmationText", {
        companyName: this.name,
      }) as string;
    },

    checkboxTooltipCopy(): string {
      return this.$t(
        "common.components.matchingCard.checkboxTooltipText",
      ) as string;
    },
  },

  watch: {
    isInnerSelected(newValue: boolean, oldValue: boolean) {
      // Prevent side-effects
      if (newValue === oldValue || newValue === this.isSelected) {
        return;
      }

      // Item was selected, dispatch according action
      if (newValue) {
        this.$store.dispatch(
          EListManagementActions.ADD_COMPANY,
          this.companyUid,
        );
        return;
      }

      this.$store.dispatch(
        EListManagementActions.REMOVE_COMPANY,
        this.companyUid,
      );
    },

    isSelected(newValue: boolean) {
      if (newValue !== this.isInnerSelected) {
        this.isInnerSelected = newValue;
      }
    },
  },

  created() {
    this.isInnerSelected = this.isSelected;
  },

  methods: {
    toogleOptionsOpen(value: boolean) {
      this.toggleOptions = value;
    },

    onCardClick() {
      // Block global component action when select mode is active
      if (this.isSelectModeActive || this.isDeletePanelVisible) {
        return;
      }

      this.$emit("click");
    },

    deleteClickHandler() {
      this.isDeletePanelVisible = true;
    },

    onRemoveConfirmationButtonClick() {
      this.$emit("remove", this.companyUid);
      this.isDeletePanelVisible = false;
    },
  },
});
</script>

<style lang="scss" scoped>
.matching-card-compact-selector :deep() .el-checkbox__inner::before {
  position: absolute;
  top: -6px;
  left: -6px;
  width: calc(100% + 12px);
  height: calc(100% + 12px);
  content: "";
}

.matching-card-compact-selector__wrapper {
  position: absolute;
  top: 28px;
  left: 13px;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
}

.matching-card-compact-options__wrapper {
  position: absolute;
  top: 15px;
  right: 13px;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
}

.matching-card-compact--toggle .matching-card-compact-options__wrapper,
.matching-card-compact--toggle .matching-card-compact-selector__wrapper,
.matching-card-compact--selected .matching-card-compact-selector__wrapper,
.matching-card-compact:hover .matching-card-compact-selector__wrapper,
.matching-card-compact:hover .matching-card-compact-options__wrapper {
  opacity: 1;
}

.matching-card-compact-selector__wrapper :deep() {
  .el-checkbox__inner,
  .el-checkbox__input {
    width: 22px;
    height: 22px;
  }

  .el-checkbox__input:not(.is-checked) .el-checkbox__inner {
    background-color: $white;
    box-shadow: none;
  }

  .el-checkbox__input.is-checked .el-checkbox__inner {
    background-color: $malibu;
    border-color: $malibu;
  }
}

.matching-card-compact__section-container {
  display: flex;
}

.matching-card-compact__supporter-container,
.matching-card-compact__headline-container,
.matching-card-compact__right-side {
  display: flex;
  align-items: center;
  align-self: flex-start;
  justify-content: space-between;
}

.matching-card-compact__supporter-container {
  width: 270px;
  padding-right: 28px;
}

.matching-card-compact__headline-container {
  width: 231px;
}

.matching-card-compact__headline {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 167px;
  padding: 0;
  word-break: break-all;
  overflow-wrap: break-word;
}

.matching-card-compact__title {
  @include grotesk(bold);

  margin-bottom: 1px;
  font-size: 17px;
  line-height: 22px;
  letter-spacing: -0.1px;
}

.matching-card-compact__subtitle {
  margin: 0;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.3px;
}

.matching-card-compact__title,
.matching-card-compact__subtitle {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.matching-badge-list__wrapper {
  display: flex;
  align-items: center;
  justify-items: left;
  width: 100%;
  margin-top: 3px;
}

.matching-card-compact-selector--label {
  width: 107px;
  height: 20px;
  padding: 2px 0;

  font-size: 12px;
  font-weight: bold;
  color: $manatee;
  text-align: center;
  text-transform: uppercase;
  background-color: $athens-gray;
  border-radius: 13px;
}

.matching-card-compact__description-container {
  width: 373px;
  padding-right: 34px;
  margin: 0;
}

.matching-card-compact__description {
  @include multiLineEllipsis(
    $font-size: 14px,
    $line-height: 19px,
    $lines-to-show: 3
  );

  margin-bottom: 10px;
  color: $manatee;
}

.matching-card-compact__sectors-container {
  width: 172px;
  padding-right: 43px;
  margin: 1px 0;
}

.matching-card-compact__right-side {
  width: 180px;
}

.matching-card-compact__percentage {
  position: relative;
  bottom: 2px;
  width: 55px;
  height: 30px;

  :deep() .matching-percentage-pill {
    border-radius: 15px;
  }
}

.matching-card-compact__cta-container {
  display: flex;
  justify-content: space-between;
  width: 84px;
}

.matching-card-compact__remove {
  &-text {
    @include grotesk(bold);

    transform: translateY(-2px);
  }

  &-cta {
    height: 36px;
  }
}
</style>

<style lang="scss">
.matching-card-compact__tooltip.el-abaca-tooltip {
  padding: 5px 10px;
  pointer-events: none;

  /*
   * This will remove the standard behaviour of changing the cursor selector to text selector, even if the tooltips
   * remain with selectable to false
   */
  user-select: none;
}

.matching-badge-list__wrapper .matching-badge-list__icon {
  left: 0;
}
.matching-card-compact .matching-card-thumbnail {
  align-self: flex-start;
}

.matching-card-compact .matching-card-tag-list__container {
  line-height: 1;
}
</style>
