import {
  GenericProvider,
  IOptionsType,
} from "@/services/data/generic-provider";
import { IAssessment, IAssessmentLevel } from "./assessment.interface";
import { ICreateAssessment } from "./create-assessment.interface";
/**
 * Provider to be used to manage the Assessment data.
 */
class AssessmentProvider extends GenericProvider<IAssessment> {
  constructor() {
    super(`${process.env.VUE_APP_API_URL}/assessments`, []);
  }

  /**
   * Create a new assessment .
   *
   * @param group user group
   * @param newData new data for the assessment
   * @param options additional options to append to the URL
   */
  public async register(
    group: number,
    newData: ICreateAssessment,
    options: IOptionsType = {},
  ) {
    const url = this.buildEndPointUrl(options);
    // When there is no level selected, the API expects to receive a
    // null level and not zero, so we need a conversion here.
    const levels = newData.levels.map((entry: IAssessmentLevel) => {
      entry.level = entry.level || null;
      return entry;
    });
    const request = this.httpClient.post(url, { group, levels });
    const { data } = await this.wrapRequest(request);
    return data;
  }

  /**
   * Get an assessment by token.
   *
   * @param token Token to searching for.
   */
  public async getByToken(token: string): Promise<IAssessment> {
    if (token.length === 0) {
      throw new Error("Invalid token given");
    }

    const url = super.buildEndPointUrl({}, `token/${token}`);
    const request = this.httpClient.get(url);
    const { data } = await this.wrapRequest(request);

    return data as IAssessment;
  }
}

export const assessmentProvider = new AssessmentProvider();
