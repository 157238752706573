<template>
  <ElDialog
    ref="modal"
    v-model="innerVisibility"
    :append-to-body="true"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    class="list-management-confirmation-dialog"
    width="500px"
    @close="closeModal"
  >
    <template #header>
      <h3 class="list-management-confirmation-dialog__title">
        {{ dialogTitle }}
      </h3>
    </template>
    <template #footer>
      <div class="list-management-confirmation-dialog__footer">
        <PxButton
          ref="cancelButton"
          v-bind="cancelButtonProps"
          :size="$options.static.ctaSize"
          :type="$options.static.cancelCtaType"
          data-testid="list-management-confirmation-dialog--cancel-button"
          @click="closeModal"
        />
        <PxButton
          ref="removeButton"
          v-bind="removeButtonProps"
          :label="removeConfirmationButtonCopy"
          :size="$options.static.ctaSize"
          data-testid="remove-companies-from-list-button"
          @click="onRemoveConfirmationClick"
        />
      </div>
    </template>
  </ElDialog>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { TranslateResult } from "vue-i18n";
import {
  EPxButtonType,
  EPxButtonSize,
} from "@/components/px-button/px-button.types";

export default defineComponent({
  name: "ListManagementConfirmationDialog",

  static: {
    cancelButton: {
      type: EPxButtonType.LINK,
    },

    removeButton: {
      type: EPxButtonType.RED,
    },

    ctaSize: EPxButtonSize.MEDIUM,

    cancelCtaType: EPxButtonType.LINK,
  },

  props: {
    visibility: {
      type: Boolean,
      default: false,
    },

    selectedCompanyName: {
      type: String || null,
      default: null,
    },

    selectedCompanies: {
      type: Array as () => Array<string>,
      default: () => [],
    },
  },

  data() {
    return {
      innerVisibility: false,
    };
  },

  computed: {
    dialogTitle(): TranslateResult {
      return this.selectedCompanies.length > 1
        ? this.$t("matching.matchingList.areYouSureMembers")
        : (this.$t("matching.matchingList.removalConfirmationText", {
            companyName: this.selectedCompanyName,
          }) as string);
    },

    cancelButtonProps(): any {
      return {
        ...this.$options.static.cancelButton,
        label: this.$t("common.cancel"),
      };
    },

    removeButtonProps(): any {
      return {
        ...this.$options.static.removeButton,
        label: this.$t("common.components.default.cta.removeMembers"),
      };
    },

    removeConfirmationButtonCopy(): string {
      return this.$t("common.components.default.cta.yesRemove");
    },
  },

  watch: {
    visibility(value) {
      this.innerVisibility = value;
    },
  },

  methods: {
    closeModal() {
      this.$emit("close-options");
      this.$emit("update:visibility", false);
    },

    async onRemoveConfirmationClick() {
      // Hide confirmation and footer
      this.closeModal();

      // Emit remove companies from list
      this.$emit("remove-companies");
    },
  },
});
</script>

<style lang="scss">
.list-management-confirmation-dialog {
  .el-dialog__header {
    padding: 0;
    text-align: center;
    background-color: transparent;
    border: none;
  }

  .el-dialog__body {
    padding: 0;
  }

  .el-dialog__headerbtn {
    top: 20px;
    right: 14px;
    width: 22px;
    height: 22px;
  }

  .el-dialog__footer {
    padding: 0;
  }

  &__footer {
    display: flex;
    gap: 17px;
    align-items: center;
    justify-content: center;
    padding: 16px 0;
    margin-top: 34px;

    &::before {
      width: 436px;
    }

    .el-button {
      margin-left: 0;
    }

    .el-button--link {
      position: relative;
      font-size: 14px;
    }

    .el-button--link :deep() span {
      letter-spacing: -0.8px;
    }

    .el-button--red {
      padding: 0 15px;
    }
  }

  &__title {
    padding: 35px 60px 0 60px;
    margin-bottom: 2px;
    font-size: 20px;
    line-height: 30px;
    color: $ebony-clay;
  }
}
</style>
