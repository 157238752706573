<template>
  <div class="vue-content-placeholders-text">
    <div
      v-for="n in lines"
      :key="n"
      class="vue-content-placeholders-text__line"
    ></div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "ContentPlaceholdersText",
  props: {
    lines: {
      type: Number,
      default: 4,
    },
  },
});
</script>
