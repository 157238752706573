import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-5a70dfbb"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "company-lists-empty__wrapper" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "company-lists-empty__title" }
const _hoisted_4 = { class: "company-lists-empty__subtitle" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PxIcon = _resolveComponent("PxIcon")!
  const _component_PxButton = _resolveComponent("PxButton")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([{ 'company-lists-empty--with-slot': _ctx.hasPopulatedSlot }, "company-lists-empty"])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", {
        class: _normalizeClass([[`company-lists-empty__icon--${_ctx.icon}`], "company-lists-empty__icon"])
      }, [
        _createElementVNode("img", {
          src: `/img/icons/${_ctx.icon}.svg`,
          alt: ""
        }, null, 8, _hoisted_2)
      ], 2),
      _createElementVNode("h4", _hoisted_3, _toDisplayString(_ctx.title), 1),
      _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.subtitle), 1),
      (_ctx.canCreateLists)
        ? (_openBlock(), _createBlock(_component_PxButton, {
            key: 0,
            class: "company-lists-empty__main-cta",
            size: "medium",
            type: "green",
            onClick: _ctx.newListClickHandler
          }, {
            default: _withCtx(() => [
              _createVNode(_component_PxIcon, {
                size: 16,
                name: "plus-white"
              }),
              _createTextVNode(" " + _toDisplayString(_ctx.$t("companyLists.directory.createListButton")), 1)
            ]),
            _: 1
          }, 8, ["onClick"]))
        : _createCommentVNode("", true)
    ])
  ], 2))
}