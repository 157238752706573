<template>
  <div class="compare-responses-table">
    <table>
      <thead>
        <tr>
          <th
            v-for="(header, index) in tableHeaders"
            :key="index"
            :class="{
              'compare-responses-table__headers--no-results':
                !allQuestionsAnswered,
            }"
            class="compare-responses-table__headers"
          >
            <PxButton
              v-bind="headerButtonProps"
              :label="header"
              data-testid="compare-responses-table-header-button"
              icon="information--light-gray"
              @click.stop="showDetailsModal(index)"
            />
            <div
              v-if="labelsStyles[index] && allQuestionsAnswered"
              class="compare-responses-table__matching-badge"
            >
              <PxStatusBadge
                :background-color="labelsStyles[index].backgroundColor"
                :border-color="labelsStyles[index].borderColor"
                :icon="labelsStyles[index].icon"
                :icon-size="13"
                :label="$t(labelsStyles[index].title)"
                :label-color="labelsStyles[index].textColor"
                :label-size="14"
                :style="{ boxShadow: labelsStyles[index].boxShadow || '' }"
                :uppercase="false"
                :weight="600"
              />
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(data, index) in answersData"
          :key="`table-answer-${index}`"
          data-testid="compare-responses-table-response"
        >
          <td
            v-for="(answer, answerIndex) in data"
            :key="`structure-answer-${answerIndex}`"
          >
            <PxIcon ref="icon" :name="answer.matchIcon" :size="20" />
            <MatchingCardTagList
              :capitalize="false"
              :items="answer.responses"
              :list-format="true"
              :max-items-to-display="2"
              :should-sort-array="false"
              :truncate="true"
              title="capitalExplorer.detailsModal.tableHeader[2].title"
            />
          </td>
        </tr>
      </tbody>
    </table>
    <DetailsModal
      v-if="detailsModalVisible"
      v-model:visibility="detailsModalVisible"
      :capital-info="detailsModalInfo.capitalInfo"
      :match-details="detailsModalInfo.matchDetails"
      :match-properties="detailsModalInfo.matchProperties"
      data-testid="compare-responses-table-details-modal"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import MatchingCardTagList from "@/components/matching-card/matching-card-tag-list.vue";
import PxStatusBadge from "@/components/px-status-badge/px-status-badge.vue";
import DetailsModal from "@/modules/capital-explorer/components/details-modal/details-modal.vue";

import {
  ICapitalMatchingBadge,
  ICapitalTileInfo,
} from "@/modules/capital-explorer/services/data/capital-explorer/capital-explorer.interface";
import { EPxButtonType } from "@/components/px-button/px-button.types";

export default defineComponent({
  name: "CompareResponsesTable",

  components: {
    MatchingCardTagList,
    PxStatusBadge,
    DetailsModal,
  },

  props: {
    answersData: {
      type: Array as () => any,
      required: true,
    },

    tableHeaders: {
      type: Array as () => string[],
      required: true,
    },

    matchDetailsModalData: {
      type: Array as () => ICapitalTileInfo[],
      required: true,
    },

    allQuestionsAnswered: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      detailsModalVisible: false,
      detailsModalInfo: {} as ICapitalTileInfo,
    };
  },

  computed: {
    labelsStyles(): ICapitalMatchingBadge[] {
      return this.matchDetailsModalData.map((item) => {
        return (
          item.matchProperties.matchingBadge || ({} as ICapitalMatchingBadge)
        );
      });
    },

    headerButtonProps(): object {
      return {
        type: EPxButtonType.LINK_DARK,
      };
    },
  },

  methods: {
    showDetailsModal(index: number) {
      this.detailsModalInfo = this.matchDetailsModalData[index];
      this.detailsModalVisible = true;
    },
  },
});
</script>

<style lang="scss" scoped>
.compare-responses-table {
  tr:not(:last-of-type) {
    border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  }

  &__matching-badge {
    :deep() .px-status-badge {
      padding: 5px 9px;
      gap: 5px;

      width: max-content;
      transition: all 0.5s;
    }

    :deep() .px-status-badge__label {
      margin: 0;
      transition: all 0.5s;
    }
  }

  &__headers {
    position: sticky;
    top: 0;
    z-index: 102;
    min-width: 248px;
    height: 83px;

    padding: 0 14px 0 15px;

    background: linear-gradient(
      to top,
      #0000000f,
      #0000000f 1px,
      #fafafa 1px,
      #fafafa 100%
    );
  }

  &__headers .px-button :deep() span {
    text-transform: uppercase;
    font-size: 12px;
    line-height: 1;
    font-weight: 600;

    div {
      margin-right: 5px;
    }

    img {
      width: 14px;
      height: 14px;
    }
  }

  &__headers--no-results {
    height: 55px;
  }

  th:not(:last-of-type)::before {
    position: absolute;
    top: 50%;
    right: 0;
    display: block;
    width: 1px;
    height: 22px;
    transform: translateY(-50%);

    content: "";

    background: rgba(0, 0, 0, 0.06);
  }

  td {
    height: 161px;
    min-width: 248px;
    vertical-align: top;

    padding: 15px;
    background-color: $white;
  }

  :deep() .matching-card-tag-list__container li::marker {
    font-size: 12px;
    color: #585868;
  }

  :deep() .matching-card-tag-list__container ul {
    padding-left: 11px;
    margin-bottom: 0;
  }

  :deep() .matching-card-tag-list__container li {
    color: #585868;
    letter-spacing: -0.33px;
    line-height: 20px;
    font-weight: 500;
  }

  :deep() .matching-card-tag-list__container {
    margin-top: 4px;

    height: auto;
    font-weight: 400;
    text-transform: none;
  }

  :deep() .matching-card-tag-list__more {
    margin-left: 14px;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;

    color: #585868;
    text-decoration: underline;

    cursor: pointer;
  }
}
</style>
