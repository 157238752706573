import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-8e1df2ea"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "submission-tab-questions" }
const _hoisted_2 = { class: "submission-tab__print-title" }
const _hoisted_3 = { class: "submissions-responses__question" }
const _hoisted_4 = { class: "submissions-responses__answer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h2", _hoisted_2, _toDisplayString(_ctx.$t("program.submission.tabs.questions")), 1),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.criteria, (response, index) => {
      return (_openBlock(), _createElementBlock("div", {
        key: `response-${index}`,
        class: "submissions-responses__item"
      }, [
        _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.getQuestion(response)), 1),
        _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.getAnswer(response)), 1)
      ]))
    }), 128))
  ]))
}