<template>
  <ContentPlaceholders class="levels-description-placeholder" rounded>
    <ContentPlaceholdersText
      :lines="2"
      class="levels-description-placeholder__header"
    />
    <ContentPlaceholdersText
      :lines="3"
      class="levels-description-placeholder__description"
    />
  </ContentPlaceholders>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "LevelsDescriptionPlaceholder",
});
</script>

<style lang="scss" scoped>
.levels-description-placeholder {
  &::after {
    position: relative;
    display: block;
    width: 100%;
    height: 1px;
    margin-top: 20px;

    content: "";

    background-color: $athens-gray;
  }

  &__header {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 6px;
  }

  &__description :deep() .vue-content-placeholders-text__line {
    height: 14px;
    margin-bottom: 11px;

    &:nth-child(1) {
      width: 214px;

      @include breakpoint-down(sm) {
        width: 291px;
      }
    }

    &:nth-child(2) {
      width: 176px;

      @include breakpoint-down(sm) {
        width: 251px;
      }
    }

    &:nth-child(3) {
      width: 136px;

      @include breakpoint-down(sm) {
        display: none;
      }
    }
  }

  &__header :deep() .vue-content-placeholders-text__line:first-child {
    width: 20px;
    height: 20px;

    border-radius: 75px;
  }

  &__header :deep() .vue-content-placeholders-text__line:last-child {
    width: 124px;
    height: 16px;
    margin-left: 10px;

    background-color: $manatee;

    @include breakpoint-down(sm) {
      width: 191px;
    }
  }
}
</style>
