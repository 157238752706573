import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, renderList as _renderList, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-3ccf6ce5"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "supporter-registration-form__wrapper" }
const _hoisted_2 = { class: "supporter-registration-form__container" }
const _hoisted_3 = { class: "supporter-registration-form__card-content" }
const _hoisted_4 = { class: "supporter-registration-form__card-title" }
const _hoisted_5 = { class: "supporter-registration-form__card-content" }
const _hoisted_6 = { class: "supporter-registration-form__card-list is-trucanted" }
const _hoisted_7 = { class: "supporter-registration-form__card-content" }
const _hoisted_8 = { class: "supporter-registration-form__card-list is-trucanted" }
const _hoisted_9 = { class: "supporter-registration-form__card-content" }
const _hoisted_10 = { class: "supporter-registration-form__card-list is-truncated--multiline" }
const _hoisted_11 = ["textContent"]
const _hoisted_12 = { class: "supporter-registration-form__card-content" }
const _hoisted_13 = { class: "supporter-registration-form__card-list is-truncated--multiline" }
const _hoisted_14 = ["textContent"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_i18n_t = _resolveComponent("i18n-t")!
  const _component_PxButton = _resolveComponent("PxButton")!
  const _component_PxIcon = _resolveComponent("PxIcon")!
  const _component_PxSliderCard = _resolveComponent("PxSliderCard")!
  const _component_ElFormItem = _resolveComponent("ElFormItem")!
  const _component_ElForm = _resolveComponent("ElForm")!
  const _component_FormPresentationLayout = _resolveComponent("FormPresentationLayout")!
  const _component_NavigationFooter = _resolveComponent("NavigationFooter")!
  const _component_PxListModal = _resolveComponent("PxListModal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_FormPresentationLayout, {
      "description-image-link": _ctx.viewCopy.imageLink,
      "description-image-title": _ctx.viewCopy.imageTitle,
      "description-text": _ctx.viewCopy.imageDescription,
      "has-return-button": _ctx.hasPreviousPage,
      "has-reversed-backgrounds": true,
      "has-sticky-header": true,
      "content-vertical-alignment": "top",
      onPreviousPage: _ctx.onReturnButtonClick
    }, {
      header: _withCtx(() => [
        _createVNode(_component_i18n_t, {
          class: "form-presentation-layout__content-headline",
          keypath: "supporters.form.importances.headline.title",
          tag: "h2"
        }, {
          highlight: _withCtx(() => [
            _createElementVNode("mark", null, _toDisplayString(_ctx.formCopy.importances.headline.highlight), 1)
          ]),
          _: 1
        })
      ]),
      content: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_ElForm, {
            ref: "supporterRegistrationForm",
            model: _ctx.fields,
            class: "supporter-registration-form__body",
            onValidate: _ctx.updateSubmitState
          }, {
            default: _withCtx(() => [
              (_ctx.criteriaImportances.length)
                ? (_openBlock(), _createBlock(_component_ElFormItem, {
                    key: 0,
                    prop: "importances"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_PxSliderCard, {
                        modelValue: _ctx.fields.importances.level_weight,
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.fields.importances.level_weight) = $event)),
                        "slider-stops": _ctx.criteriaImportances,
                        "start-on-middle": true,
                        title: _ctx.formCopy.importances.level,
                        class: "supporter-registration-form__card"
                      }, {
                        "sub-header": _withCtx(() => [
                          _createElementVNode("div", null, [
                            _createVNode(_component_PxButton, {
                              class: "supporter-registration-form__card-link el-button--link-dark",
                              type: "link",
                              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onClickGoTo('assessment')))
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.$t("common.edit")), 1)
                              ]),
                              _: 1
                            })
                          ])
                        ]),
                        body: _withCtx(() => [
                          _createElementVNode("div", _hoisted_3, [
                            _createVNode(_component_PxIcon, {
                              name: `level-range-graph/level-${_ctx.investingLevelRangeInterval}`,
                              size: 50,
                              class: "supporter-registration-form__card-icon"
                            }, null, 8, ["name"]),
                            _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.investingLevelRangeTitle), 1)
                          ])
                        ]),
                        _: 1
                      }, 8, ["modelValue", "slider-stops", "title"]),
                      (_ctx.supporterSectorsOfInterest)
                        ? (_openBlock(), _createBlock(_component_PxSliderCard, {
                            key: 0,
                            modelValue: _ctx.fields.importances.sectors_weight,
                            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.fields.importances.sectors_weight) = $event)),
                            "slider-stops": _ctx.criteriaImportances,
                            "start-on-middle": true,
                            title: _ctx.formCopy.importances.sector,
                            class: "supporter-registration-form__card"
                          }, {
                            "sub-header": _withCtx(() => [
                              _createElementVNode("div", null, [
                                (_ctx.hasMoreItemsOrNone(_ctx.supporterSectorsOfInterest))
                                  ? (_openBlock(), _createBlock(_component_PxButton, {
                                      key: 0,
                                      disabled: !_ctx.supporterSectorsOfInterest.length,
                                      class: "supporter-registration-form__card-link",
                                      type: "link",
                                      onClick: _cache[2] || (_cache[2] = ($event: any) => (
                        _ctx.onClickMoreHandler(
                          _ctx.formCopy.importances.sector,
                          _ctx.supporterSectorsOfInterest,
                        )
                      ))
                                    }, {
                                      default: _withCtx(() => [
                                        (_ctx.hasMoreItems(_ctx.supporterSectorsOfInterest))
                                          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                              _createTextVNode(_toDisplayString(_ctx.$t("common.seeAll", {
                            number: _ctx.getTotalItems(_ctx.supporterSectorsOfInterest),
                          })), 1)
                                            ], 64))
                                          : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                                              _createTextVNode(_toDisplayString(_ctx.formCopy.importances.emptyState.sector), 1)
                                            ], 64))
                                      ]),
                                      _: 1
                                    }, 8, ["disabled"]))
                                  : _createCommentVNode("", true),
                                _createVNode(_component_PxButton, {
                                  class: "supporter-registration-form__card-link el-button--link-dark",
                                  type: "link",
                                  onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.onClickGoTo('basicInterests')))
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.$t("common.edit")), 1)
                                  ]),
                                  _: 1
                                })
                              ])
                            ]),
                            body: _withCtx(() => [
                              _createElementVNode("div", _hoisted_5, [
                                _createElementVNode("ul", _hoisted_6, [
                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.supporterSectorsOfInterest, (item, index) => {
                                    return (_openBlock(), _createElementBlock("li", {
                                      key: index,
                                      class: "supporter-registration-form__card-list-item is-capitalize"
                                    }, [
                                      (item.subitems)
                                        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                            _createTextVNode(_toDisplayString(item.name) + " ", 1),
                                            _createElementVNode("span", null, "(" + _toDisplayString(item.subitems.length) + ")", 1)
                                          ], 64))
                                        : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                                            _createTextVNode(_toDisplayString(item.name), 1)
                                          ], 64))
                                    ]))
                                  }), 128))
                                ])
                              ])
                            ]),
                            _: 1
                          }, 8, ["modelValue", "slider-stops", "title"]))
                        : _createCommentVNode("", true),
                      (_ctx.supporterLocationsOfInterest)
                        ? (_openBlock(), _createBlock(_component_PxSliderCard, {
                            key: 1,
                            modelValue: _ctx.fields.importances.locations_weight,
                            "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.fields.importances.locations_weight) = $event)),
                            "slider-stops": _ctx.criteriaImportances,
                            "start-on-middle": true,
                            title: _ctx.formCopy.importances.location,
                            class: "supporter-registration-form__card"
                          }, {
                            "sub-header": _withCtx(() => [
                              _createElementVNode("div", null, [
                                (_ctx.hasMoreItemsOrNone(_ctx.supporterLocationsOfInterest))
                                  ? (_openBlock(), _createBlock(_component_PxButton, {
                                      key: 0,
                                      disabled: !_ctx.supporterLocationsOfInterest.length,
                                      class: "supporter-registration-form__card-link",
                                      type: "link",
                                      onClick: _cache[5] || (_cache[5] = ($event: any) => (
                        _ctx.onClickMoreHandler(
                          _ctx.formCopy.importances.location,
                          _ctx.supporterLocationsOfInterest,
                        )
                      ))
                                    }, {
                                      default: _withCtx(() => [
                                        (_ctx.hasMoreItems(_ctx.supporterLocationsOfInterest))
                                          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                              _createTextVNode(_toDisplayString(_ctx.$t("common.seeAll", {
                            number: _ctx.getTotalItems(_ctx.supporterLocationsOfInterest),
                          })), 1)
                                            ], 64))
                                          : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                                              _createTextVNode(_toDisplayString(_ctx.formCopy.importances.emptyState.location), 1)
                                            ], 64))
                                      ]),
                                      _: 1
                                    }, 8, ["disabled"]))
                                  : _createCommentVNode("", true),
                                _createVNode(_component_PxButton, {
                                  class: "supporter-registration-form__card-link el-button--link-dark",
                                  type: "link",
                                  onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.onClickGoTo('basicInterests')))
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.$t("common.edit")), 1)
                                  ]),
                                  _: 1
                                })
                              ])
                            ]),
                            body: _withCtx(() => [
                              _createElementVNode("div", _hoisted_7, [
                                _createElementVNode("ul", _hoisted_8, [
                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.supporterLocationsOfInterest, (item, index) => {
                                    return (_openBlock(), _createElementBlock("li", {
                                      key: index,
                                      class: "supporter-registration-form__card-list-item is-capitalize"
                                    }, [
                                      (item.subitems)
                                        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                            _createTextVNode(_toDisplayString(item.name) + " ", 1),
                                            _createElementVNode("span", null, "(" + _toDisplayString(item.subitems.length) + ")", 1)
                                          ], 64))
                                        : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                                            _createTextVNode(_toDisplayString(item.name), 1)
                                          ], 64))
                                    ]))
                                  }), 128))
                                ])
                              ])
                            ]),
                            _: 1
                          }, 8, ["modelValue", "slider-stops", "title"]))
                        : _createCommentVNode("", true),
                      (_ctx.fields.importances.questions.length)
                        ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.criteriaResponses, (criteria, index) => {
                              return (_openBlock(), _createBlock(_component_PxSliderCard, {
                                key: `criteria-importance:${index}`,
                                modelValue: _ctx.fields.importances.questions[index].criteria_weight,
                                "onUpdate:modelValue": ($event: any) => ((_ctx.fields.importances.questions[index].criteria_weight) = $event),
                                "slider-stops": _ctx.criteriaImportances,
                                "start-on-middle": true,
                                title: `${(criteria.response.question as IMatchingQuestion).resource_question}`,
                                class: "supporter-registration-form__card"
                              }, {
                                "sub-header": _withCtx(() => [
                                  _createElementVNode("div", null, [
                                    (_ctx.hasMoreItemsOrNone(criteria.items))
                                      ? (_openBlock(), _createBlock(_component_PxButton, {
                                          key: 0,
                                          disabled: !criteria.items.length,
                                          class: "supporter-registration-form__card-link",
                                          type: "link",
                                          onClick: ($event: any) => (
                          _ctx.onClickMoreHandler(
                            `${(criteria.response.question as IMatchingQuestion).resource_question}`,
                            criteria.items,
                          )
                        )
                                        }, {
                                          default: _withCtx(() => [
                                            (_ctx.hasMoreItems(criteria.items))
                                              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                                  _createTextVNode(_toDisplayString(_ctx.$t("common.seeAll", {
                              number: _ctx.getTotalItems(criteria.items),
                            })), 1)
                                                ], 64))
                                              : _createCommentVNode("", true)
                                          ]),
                                          _: 2
                                        }, 1032, ["disabled", "onClick"]))
                                      : _createCommentVNode("", true),
                                    _createVNode(_component_PxButton, {
                                      class: "supporter-registration-form__card-link el-button--link-dark",
                                      type: "link",
                                      onClick: ($event: any) => (_ctx.onClickGoTo('questions', criteria.order))
                                    }, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(_ctx.$t("common.edit")), 1)
                                      ]),
                                      _: 2
                                    }, 1032, ["onClick"])
                                  ])
                                ]),
                                body: _withCtx(() => [
                                  _createElementVNode("div", _hoisted_9, [
                                    _createElementVNode("ul", _hoisted_10, [
                                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(criteria.items, (item, key) => {
                                        return (_openBlock(), _createElementBlock("li", {
                                          key: key,
                                          class: "supporter-registration-form__card-list-item",
                                          textContent: _toDisplayString(item.name)
                                        }, null, 8, _hoisted_11))
                                      }), 128))
                                    ])
                                  ])
                                ]),
                                _: 2
                              }, 1032, ["modelValue", "onUpdate:modelValue", "slider-stops", "title"]))
                            }), 128)),
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.supporterAdditionalCriteria, (additionalCriteria, index) => {
                              return (_openBlock(), _createBlock(_component_PxSliderCard, {
                                key: `additional-criteria-importance:${index}`,
                                modelValue: 
                    _ctx.fields.importances.questions[
                      _ctx.criteriaResponses.length + index
                    ].criteria_weight
                  ,
                                "onUpdate:modelValue": ($event: any) => ((
                    _ctx.fields.importances.questions[
                      _ctx.criteriaResponses.length + index
                    ].criteria_weight
                  ) = $event),
                                "slider-stops": _ctx.criteriaImportances,
                                "start-on-middle": true,
                                title: `${additionalCriteria.response.category} ${additionalCriteria.response.shortName}`,
                                class: "supporter-registration-form__card"
                              }, {
                                "sub-header": _withCtx(() => [
                                  _createElementVNode("div", null, [
                                    (_ctx.hasMoreItemsOrNone(additionalCriteria.items))
                                      ? (_openBlock(), _createBlock(_component_PxButton, {
                                          key: 0,
                                          disabled: !additionalCriteria.items.length,
                                          class: "supporter-registration-form__card-link",
                                          type: "link",
                                          onClick: ($event: any) => (
                          _ctx.onClickMoreHandler(
                            `${additionalCriteria.response.category} ${additionalCriteria.response.shortName}`,
                            additionalCriteria.items,
                          )
                        )
                                        }, {
                                          default: _withCtx(() => [
                                            (_ctx.hasMoreItems(additionalCriteria.items))
                                              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                                  _createTextVNode(_toDisplayString(_ctx.$t("common.seeAll", {
                              number: _ctx.getTotalItems(additionalCriteria.items),
                            })), 1)
                                                ], 64))
                                              : _createCommentVNode("", true)
                                          ]),
                                          _: 2
                                        }, 1032, ["disabled", "onClick"]))
                                      : _createCommentVNode("", true),
                                    _createVNode(_component_PxButton, {
                                      class: "supporter-registration-form__card-link el-button--link-dark",
                                      type: "link",
                                      onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.onClickGoTo('additionalInterests')))
                                    }, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(_ctx.$t("common.edit")), 1)
                                      ]),
                                      _: 1
                                    })
                                  ])
                                ]),
                                body: _withCtx(() => [
                                  _createElementVNode("div", _hoisted_12, [
                                    _createElementVNode("ul", _hoisted_13, [
                                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(additionalCriteria.items, (item, key) => {
                                        return (_openBlock(), _createElementBlock("li", {
                                          key: key,
                                          class: "supporter-registration-form__card-list-item",
                                          textContent: _toDisplayString(item.name)
                                        }, null, 8, _hoisted_14))
                                      }), 128))
                                    ])
                                  ])
                                ]),
                                _: 2
                              }, 1032, ["modelValue", "onUpdate:modelValue", "slider-stops", "title"]))
                            }), 128))
                          ], 64))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["model", "onValidate"])
        ])
      ]),
      _: 1
    }, 8, ["description-image-link", "description-image-title", "description-text", "has-return-button", "onPreviousPage"]),
    _createVNode(_component_NavigationFooter, null, {
      center: _withCtx(() => [
        _createVNode(_component_PxButton, {
          loading: _ctx.formLoading || _ctx.viewLoading,
          class: "navigation-footer__main-cta",
          type: "green",
          onClick: _ctx.onNextButtonClick
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.ctaCopy.finish), 1)
          ]),
          _: 1
        }, 8, ["loading", "onClick"])
      ]),
      right: _withCtx(() => [
        (_ctx.skipEnabled)
          ? (_openBlock(), _createBlock(_component_PxButton, {
              key: 0,
              type: "link",
              onClick: _ctx.onSkipAllButtonClick
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.ctaCopy.setupCriteriaLater), 1)
              ]),
              _: 1
            }, 8, ["onClick"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createVNode(_component_PxListModal, {
      items: _ctx.listModal.items,
      "onUpdate:items": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.listModal.items) = $event)),
      title: _ctx.listModal.title,
      "onUpdate:title": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.listModal.title) = $event)),
      visibility: _ctx.listModal.visibility,
      "onUpdate:visibility": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.listModal.visibility) = $event)),
      capitalize: true
    }, null, 8, ["items", "title", "visibility"])
  ]))
}