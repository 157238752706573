import {
  EProviderFeatures,
  GenericProvider,
  IPaginationResult,
} from "@/services/data/generic-provider";
import { baseAPIUrl } from "@/services/utils/utils";
import { ISectorGroup } from "@/services/data/sector-group/sector-group.interface";

class SectorGroupProvider extends GenericProvider<
  IPaginationResult<ISectorGroup>
> {
  constructor() {
    super(`/sector-groups`, [EProviderFeatures.LIST]);
  }

  /**
   * Fetch sector group list with sectors
   * @param options filtering and pagination configuration
   */
  public async listWithSectors(
    options: {
      filter?: string;
      page?: number;
    } = {},
  ): Promise<IPaginationResult<ISectorGroup>> {
    const url = `${baseAPIUrl}/sector-groups/sectors`;
    const request = this.httpClient.get(url, { params: options });
    const { data } = await this.wrapRequest(request);
    return data;
  }
}

export const sectorGroupProvider = new SectorGroupProvider();
