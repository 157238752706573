import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PxButton = _resolveComponent("PxButton")!
  const _component_ElTooltip = _resolveComponent("ElTooltip")!
  const _component_NavigationFooter = _resolveComponent("NavigationFooter")!
  const _component_ListManagementConfirmationDialog = _resolveComponent("ListManagementConfirmationDialog")!
  const _component_ListManagementModal = _resolveComponent("ListManagementModal")!

  return (_openBlock(), _createElementBlock("div", null, [
    _withDirectives(_createVNode(_component_NavigationFooter, null, {
      left: _withCtx(() => [
        _createVNode(_component_ElTooltip, {
          placement: "top",
          "popper-class": "list-management-bar__cta-tooltip el-abaca-tooltip"
        }, {
          content: _withCtx(() => [
            _createElementVNode("div", null, _toDisplayString(_ctx.selectAllButtonTooltipText), 1)
          ]),
          default: _withCtx(() => [
            _createVNode(_component_PxButton, _mergeProps({
              ref: "selectAllButton",
              class: {
              'list-management-bar__select-all-cta': !_ctx.isAllSelected,
              'list-management-bar__select-all-cta--disabled':
                !_ctx.availableItems.length,
            }
            }, _ctx.selectAllButtonProps, { onClick: _ctx.onSelectAllButtonClick }), null, 16, ["class", "onClick"])
          ]),
          _: 1
        }),
        _createElementVNode("span", {
          class: _normalizeClass([{
            'list-management-bar__selected-text--empty': !_ctx.hasSelectedCompanies,
          }, "list-management-bar__selected-text"])
        }, _toDisplayString(_ctx.selectedMembersCopy), 3)
      ]),
      right: _withCtx(() => [
        _createVNode(_component_PxButton, _mergeProps({
          ref: "cancelButton",
          class: "list-management-bar__cancel-cta"
        }, _ctx.cancelButtonProps, { onClick: _ctx.onCancelButtonClick }), null, 16, ["onClick"]),
        _createVNode(_component_PxButton, _mergeProps({ ref: "addToListButton" }, _ctx.addToListButtonProps, {
          disabled: !_ctx.hasSelectedCompanies,
          class: "list-management-bar__add-move-cta",
          onClick: _ctx.onShowListManagementModal
        }), null, 16, ["disabled", "onClick"]),
        (
            _ctx.isCurrentContext(_ctx.EListManagementContext.LIST_DETAIL) && !_ctx.isSmartList
          )
          ? (_openBlock(), _createBlock(_component_PxButton, _mergeProps({
              key: 0,
              ref: "removeButton"
            }, _ctx.removeButtonProps, {
              disabled: _ctx.showRemoveConfirmation || !_ctx.hasSelectedCompanies,
              class: "list-management-bar__remove-cta",
              onClick: _ctx.onRemoveButtonClick
            }), null, 16, ["disabled", "onClick"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 512), [
      [_vShow, _ctx.showNavigationFooter]
    ]),
    _createVNode(_component_ListManagementConfirmationDialog, {
      visibility: _ctx.showRemoveConfirmation,
      "onUpdate:visibility": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.showRemoveConfirmation) = $event)),
      "selected-companies": _ctx.selectionCompanyList,
      "selected-company-name": _ctx.selectedCompanyName || '',
      onRemoveCompanies: _ctx.onRemoveConfirmationClick
    }, null, 8, ["visibility", "selected-companies", "selected-company-name", "onRemoveCompanies"]),
    (_ctx.showAddToListModal)
      ? (_openBlock(), _createBlock(_component_ListManagementModal, {
          key: 0,
          "current-tab": _ctx.currentTab,
          "onUpdate:currentTab": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.currentTab) = $event)),
          visibility: _ctx.showAddToListModal,
          "onUpdate:visibility": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.showAddToListModal) = $event)),
          context: _ctx.context,
          "edited-list": _ctx.innerEditedList,
          "is-smart-list": _ctx.isSmartList,
          onCloseTab: _ctx.onCancelButtonClick
        }, null, 8, ["current-tab", "visibility", "context", "edited-list", "is-smart-list", "onCloseTab"]))
      : _createCommentVNode("", true)
  ]))
}