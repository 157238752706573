import {
  EProviderFeatures,
  GenericProvider,
  IOptionsType,
} from "@/services/data/generic-provider";
import { baseAPIUrl } from "@/services/utils/utils";
import { IPendingProgramAssessment } from "@/modules/affiliate-program/services/data/pending-program-assessment/pending-program-assessment.interface";

class PendingProgramAssessmentProvider extends GenericProvider<IPendingProgramAssessment> {
  constructor() {
    super(`${baseAPIUrl}/pending-user/program/assessment/`, [
      EProviderFeatures.CREATE,
    ]);
  }

  public create(data: IPendingProgramAssessment, options?: IOptionsType) {
    // When there is no level selected the API expects to receive a
    // level null and not zero, so we need a conversion here.
    data.levels = data.levels.map((entry: any) => {
      entry.level = entry.level !== 0 ? entry.level : null;
      return entry;
    });

    return super.create(data, options);
  }
}

export const pendingProgramAssessmentProvider =
  new PendingProgramAssessmentProvider();
