import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-0e2033ab"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "user-submissions-view page-body" }
const _hoisted_2 = { class: "user-submissions-view__header" }
const _hoisted_3 = { class: "page-container user-submissions-view__panels" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PxButton = _resolveComponent("PxButton")!
  const _component_PxPageHeader = _resolveComponent("PxPageHeader")!
  const _component_SubmissionsPanel = _resolveComponent("SubmissionsPanel")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_PxPageHeader, {
        subtitle: _ctx.headerCopy.subtitle,
        title: _ctx.headerCopy.title,
        onBackButtonClicked: _ctx.goToProfilePage
      }, {
        action: _withCtx(() => [
          _createVNode(_component_PxButton, {
            label: _ctx.$t('userSubmissions.programsCta'),
            class: "user-submissions-view__action",
            size: "medium",
            type: "ghost-blue",
            onClick: _ctx.goToProgramsPage
          }, null, 8, ["label", "onClick"])
        ]),
        _: 1
      }, 8, ["subtitle", "title", "onBackButtonClicked"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_SubmissionsPanel, {
        "is-loading": _ctx.dataLoading,
        "is-user-submissions-view": true,
        "submissions-data": _ctx.draftSubmissions,
        type: "draft-submissions"
      }, null, 8, ["is-loading", "submissions-data"]),
      _createVNode(_component_SubmissionsPanel, {
        "is-loading": _ctx.dataLoading,
        "is-user-submissions-view": true,
        "submissions-data": _ctx.submittedSubmissions,
        type: "submitted-submissions"
      }, null, 8, ["is-loading", "submissions-data"])
    ])
  ]))
}