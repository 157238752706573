<template>
  <div>
    <GuestAccessModal
      v-if="!loginModalVisible"
      :loading="isDataLoading"
      :title="modalTitle"
      :visibility="!loginModalVisible"
      data-testid="guest-modal"
      @go-to-login="onGoToLoginButtonClick"
      @make-request="makeRequestWithGuest"
    />
    <LoginModal
      v-else
      :is-data-loading="isDataLoading"
      :visibility="loginModalVisible"
      class="login-modal"
      data-testid="login-modal"
      @after-login="makeRequestWithLogin"
    >
      <template #before-form>
        <div>
          <PxModalMessage
            :center="false"
            :show-icon="false"
            :visible="true"
            class="login-modal__modal-message"
            type="info"
          >
            <i18n-t
              class="login-modal__fields-message"
              keypath="milestonePlanner.accessModal.nameEmailMessage"
              tag="span"
            >
              <template #link>
                <PxButton
                  class="el-button--link el-button--small login-modal__fields-link"
                  type="link"
                  @click="enterAsGuest"
                >
                  {{ $t("companyLists.accessModal.nameEmailMessageButton") }}
                </PxButton>
              </template>
            </i18n-t>
          </PxModalMessage>
        </div>
      </template>
    </LoginModal>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import LoginModal from "@/components/login-modal/login-modal.vue";
import GuestAccessModal from "@/components/guest-access-modal/guest-access-modal.vue";

import { IUserGuest } from "@/services/data/user-guest/user-guest.interface";

export default defineComponent({
  name: "RegistrationModals",

  components: {
    LoginModal,
    GuestAccessModal,
  },

  props: {
    requiresPasscode: {
      type: Boolean,
      default: false,
    },

    isDataLoading: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      loginModalVisible: false,
    };
  },

  computed: {
    modalTitle(): string {
      return this.requiresPasscode
        ? (this.$t(
            "milestonePlanner.accessModal.title.afterPasscode",
          ) as string)
        : this.$t("milestonePlanner.accessModal.title.noPasscode");
    },
  },

  methods: {
    onGoToLoginButtonClick() {
      this.loginModalVisible = true;
    },

    enterAsGuest() {
      this.loginModalVisible = false;
    },

    makeRequestWithLogin() {
      this.$emit("submit-logged-form");
    },

    makeRequestWithGuest(guestData: IUserGuest) {
      this.$emit("submit-guest-form", guestData);
    },
  },
});
</script>

<style lang="scss" scoped>
.login-modal :deep() {
  .loginModal__form {
    padding-top: 47px;
    padding-left: 1px;

    .el-form-item__label {
      margin-bottom: 3px;
    }

    .el-checkbox__inner {
      width: 22px;
      height: 22px;
      margin-left: 3px;
    }

    .el-checkbox__label {
      padding-top: 6px;
      padding-left: 9px;
    }

    .el-form-item:nth-child(3) {
      margin-top: 22px;
    }

    .loginModal__remember-wrapper {
      margin: 18px -5px 0 -2px;
    }

    .loginModal__emailFieldWrapper {
      margin-bottom: 4px;
    }

    .loginModal__forget {
      padding-top: 4px;
      padding-right: 2px;
    }
  }

  .el-dialog__header {
    padding: 14px 56px 15px 30px;
  }

  .el-dialog__body {
    padding: 11px 30px 22px 29px;
  }

  .loginModal__login-button {
    margin: 26px 0 2px 1px;
  }
}

.login-modal {
  &__modal-message :deep() {
    .el-alert {
      padding: 6px 27px 0 18px;
    }

    span {
      letter-spacing: -0.33px;
    }
  }

  &__fields-link.el-button--link :deep() {
    position: relative;
    top: 1px;
    left: 1px;
    height: 22px;
    padding: 0;
    margin-right: 1px;

    span {
      @include grotesk(semiBold);

      padding: 0;
      font-size: 13px;

      color: $ebony-clay;
      letter-spacing: -0.1px;
    }
  }

  &__fields-message {
    position: relative;
    top: -2px;
    left: 8px;

    font-size: 13px;
  }
}
</style>
