/**
 * Interface that represents a supporter flow type,
 * used mostly to persist registration data.
 */
import { ILocation } from "@/services/data/location/location.interface";
import { IGroupedSectorsPayload } from "@/components/multi-selector/multi-selector-sectors.provider";
import { IGroupedLocationsPayload } from "@/components/multi-selector/multi-selector-locations.providers";
import { ILocationGoogleAutocomplete } from "@/services/data/location/location-search.interface";
import { IMatchingResponse } from "@/services/data/matching-responses/matching-response.interface";
import { IMatchingQuestionaryCriteria } from "@/services/data/matching-questionary-criteria/matching-questionary-criteria.interface";
import { ITeamMember } from "@/modules/team-management/services/data/team-members/team-members.interface";

export interface ISupporterFlowImportances {
  level_weight: number | null;
  locations_weight: number | null;
  sectors_weight: number | null;
  questions: Array<IMatchingQuestionaryCriteria>;
}

export interface ISupporterFlowSupporter {
  investing_level_range: Array<number>;
  types: Array<number>;
  other_type?: string;
  sectors: Array<number>;
  grouped_sectors: Array<IGroupedSectorsPayload>;
  grouped_locations: Array<IGroupedLocationsPayload>;
  // References Google places that will be turned into single locations
  places: Array<ILocationGoogleAutocomplete["place_id"]>;
  // References existing single locations created from Google places
  locations: Array<number>;
}

export enum ESupporterFlowType {
  REGISTRATION = "registration",
  AFFILIATE_PROGRAM = "affiliateProgram",
}

export interface ISupporterFlow {
  // Used by non-registered users, to persist a user identification throughout the flow
  token?: string;

  // Used for authenticating Team Members requests
  auth_token?: string;

  // For affiliate program flow
  affiliate?: number;

  email: string;
  password1: string;
  password2: string;

  // Only used for form validation, terms and conditions checked
  terms?: boolean;

  company: {
    name: string;
    location: ILocation | null;
    networks: Array<number>;
  };

  supporter: ISupporterFlowSupporter;

  // Responses given on a Question Bundle
  criteria: Array<IMatchingResponse>;

  // Optional responses outside of a Question Bundle
  additional_criteria: Array<IMatchingResponse>;

  team_members: Array<ITeamMember>;

  importances: ISupporterFlowImportances;

  options: {
    // Transversal state to know the flow state
    started: boolean;
    submitted: boolean;
    type: ESupporterFlowType | null;

    // Used for registered users, to persist after submission
    submissionUid?: number;
  };

  // Persist minor UI data
  meta: any;
}
