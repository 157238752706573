import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-114a2b2c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "affiliate-assessment" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_EntrepreneurSignUpModal = _resolveComponent("EntrepreneurSignUpModal")!
  const _component_SupporterSignUpModal = _resolveComponent("SupporterSignUpModal")!
  const _component_SignInModal = _resolveComponent("SignInModal")!
  const _component_AffiliateAssessmentEntrepreneur = _resolveComponent("AffiliateAssessmentEntrepreneur")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.showModals && !_ctx.affiliatesDataLoading)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          (!_ctx.showRegistrationForm)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                (_ctx.isEntrepreneurAffiliate)
                  ? (_openBlock(), _createBlock(_component_EntrepreneurSignUpModal, {
                      key: 0,
                      visibility: _ctx.showModals,
                      "onUpdate:visibility": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.showModals) = $event)),
                      onClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('go-back'))),
                      onSignIn: _ctx.updateSignInModalVisibility
                    }, null, 8, ["visibility", "onSignIn"]))
                  : (_openBlock(), _createBlock(_component_SupporterSignUpModal, {
                      key: 1,
                      visibility: _ctx.showModals,
                      "onUpdate:visibility": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.showModals) = $event)),
                      onClose: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('go-back'))),
                      onSignIn: _ctx.updateSignInModalVisibility
                    }, null, 8, ["visibility", "onSignIn"]))
              ], 64))
            : (_openBlock(), _createBlock(_component_SignInModal, {
                key: 1,
                "has-invalid-account": _ctx.isInvalidAccount,
                visibility: _ctx.showModals,
                onClose: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$emit('go-back'))),
                onRegister: _ctx.updateSignInModalVisibility,
                onSubmit: _ctx.checkInvalidAccount
              }, null, 8, ["has-invalid-account", "visibility", "onRegister", "onSubmit"]))
        ], 64))
      : _createCommentVNode("", true),
    (_ctx.isEntrepreneurAffiliate)
      ? (_openBlock(), _createBlock(_component_AffiliateAssessmentEntrepreneur, {
          key: 1,
          "is-self-assessment": _ctx.isSelfAssessment
        }, null, 8, ["is-self-assessment"]))
      : _createCommentVNode("", true)
  ]))
}