import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "verify-account-modal__left" }
const _hoisted_2 = { class: "verify-account-modal__header" }
const _hoisted_3 = { class: "verify-account-modal__headline" }
const _hoisted_4 = { class: "verify-account-modal__title" }
const _hoisted_5 = { class: "verify-account-modal__body" }
const _hoisted_6 = { class: "verify-account-modal__footer" }
const _hoisted_7 = { class: "verify-account-modal__right" }
const _hoisted_8 = { class: "verify-account-modal__image" }
const _hoisted_9 = ["alt", "src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_i18n_t = _resolveComponent("i18n-t")!
  const _component_PxIcon = _resolveComponent("PxIcon")!
  const _component_PxButton = _resolveComponent("PxButton")!
  const _component_ElDialog = _resolveComponent("ElDialog")!

  return (_openBlock(), _createBlock(_component_ElDialog, {
    ref: "modal",
    modelValue: _ctx.innerVisibility,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.innerVisibility) = $event)),
    "close-on-click-modal": false,
    class: "verify-account-modal el-dialog--no-header el-dialog--compact-footer"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("h3", _hoisted_3, _toDisplayString(_ctx.$t("profile.account.verification.headline")), 1),
          _createElementVNode("h2", _hoisted_4, _toDisplayString(_ctx.$t("profile.account.verification.title")), 1),
          _createVNode(_component_i18n_t, {
            class: "verify-account-modal__subtitle",
            keypath: "profile.account.verification.subtitle",
            tag: "p"
          }, {
            email: _withCtx(() => [
              _createElementVNode("span", null, _toDisplayString(_ctx.authUserEmail), 1)
            ]),
            _: 1
          })
        ]),
        _createElementVNode("div", _hoisted_5, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.emailClients, (client, index) => {
            return (_openBlock(), _createBlock(_component_PxButton, {
              key: index,
              class: _normalizeClass([`verify-account-modal__emailBtn--${client.icon}`, "verify-account-modal__emailBtn"]),
              size: "medium",
              type: "ghost-grey",
              onClick: ($event: any) => (_ctx.onClickEmailClient(client.host))
            }, {
              default: _withCtx(() => [
                _createVNode(_component_PxIcon, {
                  name: client.icon,
                  class: "verify-account-modal__emailBtn-icon",
                  size: "auto"
                }, null, 8, ["name"]),
                _createTextVNode(" " + _toDisplayString(_ctx.$t("common.open")) + " " + _toDisplayString(client.name), 1)
              ]),
              _: 2
            }, 1032, ["class", "onClick"]))
          }), 128))
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createVNode(_component_i18n_t, {
            class: "verify-account-modal__tip",
            keypath: "profile.account.verification.help",
            tag: "p"
          }, {
            link: _withCtx(() => [
              _createVNode(_component_PxButton, {
                disabled: _ctx.hasJustResentConfirmation,
                class: "verify-account-modal__link el-button--link el-button--link-blue",
                type: "link",
                onClick: _ctx.onClickResendConfirmation
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.accountModalTip), 1)
                ]),
                _: 1
              }, 8, ["disabled", "onClick"])
            ]),
            _: 1
          })
        ])
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("img", {
            alt: _ctx.modalImage.alt,
            src: _ctx.modalImage.url
          }, null, 8, _hoisted_9)
        ])
      ])
    ]),
    _: 1
  }, 8, ["modelValue"]))
}