import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, createElementBlock as _createElementBlock, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-65f2238e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "updateLevel page-full-height" }
const _hoisted_2 = { class: "container-fluid" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LangSwitcher = _resolveComponent("LangSwitcher")!
  const _component_CategoryWizardMobile = _resolveComponent("CategoryWizardMobile")!
  const _component_MobileTopNavbar = _resolveComponent("MobileTopNavbar")!
  const _component_PxNavbar = _resolveComponent("PxNavbar")!
  const _component_PxGlobalError = _resolveComponent("PxGlobalError")!
  const _component_CategoryWizard = _resolveComponent("CategoryWizard")!
  const _component_Assessment = _resolveComponent("Assessment")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.$screen.mdDown)
      ? (_openBlock(), _createBlock(_component_MobileTopNavbar, { key: 0 }, {
          default: _withCtx(() => [
            _createVNode(_component_LangSwitcher),
            (_ctx.$screen.mdDown && !_ctx.hasError)
              ? (_openBlock(), _createBlock(_component_CategoryWizardMobile, {
                  key: 0,
                  checked: _ctx.checkedSteps,
                  "current-step": _ctx.currentStep,
                  steps: _ctx.steps
                }, null, 8, ["checked", "current-step", "steps"]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    (_ctx.$screen.lgUp)
      ? (_openBlock(), _createBlock(_component_PxNavbar, {
          key: 1,
          items: _ctx.activeNavBarItems,
          "show-account-dropdown": _ctx.$user.isLogged()
        }, null, 8, ["items", "show-account-dropdown"]))
      : _createCommentVNode("", true),
    (_ctx.$screen.mdUp)
      ? (_openBlock(), _createBlock(_component_LangSwitcher, { key: 2 }))
      : _createCommentVNode("", true),
    (_ctx.hasError)
      ? (_openBlock(), _createBlock(_component_PxGlobalError, {
          key: 3,
          "auto-refresh": true,
          loading: _ctx.isLoading,
          "use-action": true,
          class: "page-body is-center",
          onDoAction: _ctx.fetchCategories
        }, null, 8, ["loading", "onDoAction"]))
      : _createCommentVNode("", true),
    (!_ctx.hasError)
      ? _withDirectives((_openBlock(), _createElementBlock("div", {
          key: 4,
          class: _normalizeClass([{ 'is-loading': _ctx.isLoading }, "updateLevel-container"]),
          "element-loading-background": "#fafafa"
        }, [
          _createElementVNode("div", _hoisted_2, [
            (_ctx.$screen.lgUp)
              ? (_openBlock(), _createBlock(_component_CategoryWizard, {
                  key: 0,
                  "current-step": _ctx.currentStep,
                  "onUpdate:currentStep": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.currentStep) = $event)),
                  checked: _ctx.checkedSteps,
                  steps: _ctx.steps,
                  class: "updateLevel-wizard",
                  onChanged: _ctx.stepClickHandler
                }, null, 8, ["current-step", "checked", "steps", "onChanged"]))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", null, [
            _createVNode(_component_Assessment, {
              "end-action": _ctx.onEndAction,
              "needs-submission-confirmation": !_ctx.firstAssessment
            }, null, 8, ["end-action", "needs-submission-confirmation"])
          ])
        ], 2)), [
          [_directive_loading, _ctx.isLoading]
        ])
      : _createCommentVNode("", true)
  ]))
}