<template>
  <div class="user-submissions-view page-body">
    <div class="user-submissions-view__header">
      <PxPageHeader
        :subtitle="headerCopy.subtitle"
        :title="headerCopy.title"
        @back-button-clicked="goToProfilePage"
      >
        <template #action>
          <PxButton
            :label="$t('userSubmissions.programsCta')"
            class="user-submissions-view__action"
            size="medium"
            type="ghost-blue"
            @click="goToProgramsPage"
          />
        </template>
      </PxPageHeader>
    </div>
    <div class="page-container user-submissions-view__panels">
      <SubmissionsPanel
        :is-loading="dataLoading"
        :is-user-submissions-view="true"
        :submissions-data="draftSubmissions"
        type="draft-submissions"
      />
      <SubmissionsPanel
        :is-loading="dataLoading"
        :is-user-submissions-view="true"
        :submissions-data="submittedSubmissions"
        type="submitted-submissions"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import PxPageHeader from "@/components/px-page-header/px-page-header.vue";
import SubmissionsPanel from "@/modules/submissions/components/submissions-panel/submissions-panel.vue";

import { VILCAP_PROGRAMS_PAGE } from "@/modules/submissions/constants";
import {
  IAffiliateSubmissions,
  IAffiliateSubmission,
} from "@/modules/submissions/services/data/submissions/submissions.interface";
import { EAffiliatesActions } from "@/modules/affiliates/services/store/affiliates/affiliates.types";
import { ROUTE_PROFILE } from "@/modules/profile/services/router/routes-names";

export default defineComponent({
  name: "UserSubmissionsView",

  components: {
    PxPageHeader,
    SubmissionsPanel,
  },

  computed: {
    headerCopy() {
      return this.$tm("userSubmissions.header") as {
        title: string;
        subtitle: string;
      };
    },

    userSubmissions(): IAffiliateSubmissions | null {
      return this.$store.state.affiliates.userSubmissions || null;
    },

    draftSubmissions(): Array<IAffiliateSubmission> {
      return this.userSubmissions?.drafts || [];
    },

    submittedSubmissions(): Array<IAffiliateSubmission> {
      return this.userSubmissions?.submitted || [];
    },

    dataLoading(): boolean {
      return this.$store.get("affiliates/loading");
    },
  },

  async created() {
    await this.$store.dispatch(
      EAffiliatesActions.FETCH_USER_SUBMISSIONS,
      null,
      {
        root: true,
      },
    );
  },

  methods: {
    goToProgramsPage() {
      window.open(VILCAP_PROGRAMS_PAGE, "_blank");
    },

    goToProfilePage() {
      return this.$router.push({ name: ROUTE_PROFILE });
    },
  },
});
</script>

<style lang="scss" scoped>
.user-submissions-view {
  &__header {
    border-bottom: 1px solid $turkish-angora;
    background-color: $white;

    :deep() .px-page-header {
      @include breakpoint-down(md) {
        padding: 36px 30px 32px;
      }
    }
  }

  &__action {
    position: relative;
    bottom: 1px;
    padding: 0 15px;
    margin-right: 49px;
  }
}
</style>
