import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-41c028e8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "profile-panel__row assessment-level" }
const _hoisted_2 = { class: "profile-headline" }
const _hoisted_3 = { class: "profile-headline__title" }
const _hoisted_4 = { class: "level-wrapper" }
const _hoisted_5 = {
  key: 0,
  alt: "level indicator",
  class: "assessment-level__indicator assessment-level__indicator--disabled",
  src: "/img/placeholders/level.svg"
}
const _hoisted_6 = {
  key: 2,
  class: "assessment-level__description"
}
const _hoisted_7 = ["innerHTML"]
const _hoisted_8 = {
  key: 0,
  class: "assessment-level__cta"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PxIcon = _resolveComponent("PxIcon")!
  const _component_ElCollapseItem = _resolveComponent("ElCollapseItem")!
  const _component_ElCollapse = _resolveComponent("ElCollapse")!
  const _component_PxButton = _resolveComponent("PxButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h2", _hoisted_3, _toDisplayString(_ctx.$t("profile.assessment.levelPanel.title")), 1)
    ]),
    _createElementVNode("div", _hoisted_4, [
      (_ctx.isInactive)
        ? (_openBlock(), _createElementBlock("img", _hoisted_5))
        : (_openBlock(), _createBlock(_component_PxIcon, {
            key: 1,
            name: `level-graph/${_ctx.level.value}`,
            size: _ctx.$screen.mdUp ? 173 : 140,
            class: "assessment-level__indicator"
          }, null, 8, ["name", "size"])),
      (!_ctx.isInactive)
        ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
            _createElementVNode("h3", null, _toDisplayString(_ctx.$t("profile.assessment.levelPanel.label")) + " " + _toDisplayString(_ctx.level.value), 1),
            _createVNode(_component_ElCollapse, { accordion: "" }, {
              default: _withCtx(() => [
                _createVNode(_component_ElCollapseItem, null, {
                  title: _withCtx(() => [
                    _createElementVNode("p", null, _toDisplayString(_ctx.level.title), 1)
                  ]),
                  default: _withCtx(() => [
                    _createElementVNode("div", {
                      innerHTML: _ctx.level.description
                    }, null, 8, _hoisted_7)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]))
        : _createCommentVNode("", true)
    ]),
    (_ctx.$user.isOwner() && _ctx.$features.isEnabled('match'))
      ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
          _createVNode(_component_PxButton, {
            size: "small",
            type: "blue",
            onClick: _ctx.onClickMatching
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("profile.assessment.levelPanel.matchButton")), 1)
            ]),
            _: 1
          }, 8, ["onClick"])
        ]))
      : _createCommentVNode("", true)
  ]))
}