import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-689461a9"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "account-settings-navigation" }
const _hoisted_2 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PxListHeader = _resolveComponent("PxListHeader")!
  const _component_PxTabBar = _resolveComponent("PxTabBar")!
  const _component_PxButton = _resolveComponent("PxButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.$screen.mdUp)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _createVNode(_component_PxListHeader, {
            title: _ctx.$t('profile.accountSettingsPage.navigation.title')
          }, null, 8, ["title"]),
          _createElementVNode("ul", null, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabs, (tab) => {
              return (_openBlock(), _createElementBlock("li", {
                key: tab.key,
                class: _normalizeClass([{
            'account-settings-navigation__tabs--active':
              _ctx.selectedTab === tab.key,
          }, "account-settings-navigation__tabs"]),
                onClick: ($event: any) => (_ctx.updateSelectedTab(tab.key))
              }, _toDisplayString(tab.label), 11, _hoisted_2))
            }), 128))
          ])
        ], 64))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          _createVNode(_component_PxTabBar, {
            modelValue: _ctx.innerSelectedTab,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.innerSelectedTab) = $event)),
            items: _ctx.tabs
          }, null, 8, ["modelValue", "items"]),
          _createVNode(_component_PxButton, _mergeProps({ class: "account-settings-navigation__back-button" }, _ctx.backButtonProps, { onClick: _ctx.onBackButtonClick }), null, 16, ["onClick"])
        ], 64))
  ]))
}