import { INavbarStep } from "@/components/px-navbar-wizard/px-navbar-wizard.interface";
import { featureManager } from "@/services/feature-manager";
import { defaultScreenService } from "@/modules/common/services/screen/screen.service";
import { ROUTE_ONLY_GUESTS } from "@/services/router/router-names";
import { nestedRoutesWithRenderlessParent } from "@/utils/router";

import {
  SUPPORTERS_ASSESSMENT,
  SUPPORTERS_CRITERIA_ADDITIONAL_INTERESTS,
  SUPPORTERS_CRITERIA_ASSESSMENT,
  SUPPORTERS_CRITERIA_BASIC_INTERESTS,
  SUPPORTERS_CRITERIA_IMPORTANCE,
  SUPPORTERS_CRITERIA_QUESTIONS,
  SUPPORTERS_CRITERIA_STARTING_POINT,
  SUPPORTERS_INFO,
  SUPPORTERS_PASSWORD,
  SUPPORTERS_STARTING_POINT,
} from "@/modules/supporters/services/router/routes-names";

import ParentSimplifiedView from "@/modules/supporters/views/parent/parent-simplified.vue";
import ParentView from "@/modules/supporters/views/parent/parent.vue";
import StartingPointSimplifiedView from "@/modules/supporters/views/starting-point/starting-point-simplified.vue";
import StartingPointView from "@/modules/supporters/views/starting-point/starting-point.vue";
import AssessmentView from "@/modules/supporters/views/assessment/assessment.vue";
import SetupPasswordView from "@/modules/supporters/views/setup-password/setup-password.vue";
import AccountInfoView from "@/modules/supporters/views/account-info/account-info.vue";
import CriteriaStartPageView from "@/modules/supporters/views/criteria-start-page/criteria-start-page.vue";
import CriteriaAssessmentView from "@/modules/supporters/views/criteria-assessment/criteria-assessment.vue";
import CriteriaQuestionsView from "@/modules/supporters/views/criteria-questions/criteria-questions.vue";
import CriteriaBasicInterestsView from "@/modules/supporters/views/criteria-basic-interests/criteria-basic-interests.vue";
import CriteriaAdditionalInterestsView from "@/modules/supporters/views/criteria-additional-interests/criteria-additional-interests.vue";
import CriteriaImportanceView from "@/modules/supporters/views/criteria-importance/criteria-importance.vue";
import { RouteLocationNormalized } from "vue-router";
import {
  changeLocale,
  DEFAULT_LOCALE,
  loadedLocales,
} from "@/services/i18n/locale-utils";

// Show this new feature only on desktop screens
const isSignUpSupporterRevamp =
  featureManager.isEnabled("signUpSupporterRevamp") &&
  defaultScreenService.isUp("lg");

export default {
  path: "/supporters",
  component: isSignUpSupporterRevamp ? ParentSimplifiedView : ParentView,
  meta: ROUTE_ONLY_GUESTS,
  children: [
    {
      path: "",
      name: SUPPORTERS_STARTING_POINT,
      component: isSignUpSupporterRevamp
        ? StartingPointSimplifiedView
        : StartingPointView,
      meta: {
        showFooter: !isSignUpSupporterRevamp,
        nextPage: isSignUpSupporterRevamp
          ? SUPPORTERS_PASSWORD
          : SUPPORTERS_ASSESSMENT,
      },
    },
    {
      path: "join",
      name: SUPPORTERS_ASSESSMENT,
      component: AssessmentView,
      meta: {
        navbarHeadline: "supporters.assessment.headerCopy",
      },
    },
    {
      path: "password",
      name: SUPPORTERS_PASSWORD,
      component: SetupPasswordView,
      meta: {
        previousPage: SUPPORTERS_STARTING_POINT,
        nextPage: SUPPORTERS_INFO,
      },
    },
    {
      path: "info",
      name: SUPPORTERS_INFO,
      component: AccountInfoView,
      meta: {
        nextPage: SUPPORTERS_CRITERIA_STARTING_POINT,
      },
    },
    {
      path: "criteria",
      name: SUPPORTERS_CRITERIA_STARTING_POINT,
      component: CriteriaStartPageView,
      meta: {
        previousPage: SUPPORTERS_INFO,
        nextPage: SUPPORTERS_CRITERIA_ASSESSMENT,
      },
    },

    ...nestedRoutesWithRenderlessParent({
      options: {
        suffixPath: "criteria",
        meta: {
          isNavbarWizard: true,
          navbarHeadline: "supporters.component.criteriaWizard.title",
          navbarSteps: [
            {
              routes: [SUPPORTERS_CRITERIA_ASSESSMENT],
              labelKey: "supporters.cta.ventureInvestmentLevel",
            },
            {
              routes: [SUPPORTERS_CRITERIA_QUESTIONS],
              labelKey: "supporters.cta.questions",
            },
            {
              routes: [
                SUPPORTERS_CRITERIA_BASIC_INTERESTS,
                SUPPORTERS_CRITERIA_ADDITIONAL_INTERESTS,
              ],
              labelKey: "supporters.cta.interests",
            },
          ] as INavbarStep[],
          innerNavigation: {
            assessment: SUPPORTERS_CRITERIA_ASSESSMENT,
            questions: SUPPORTERS_CRITERIA_QUESTIONS,
            basicInterests: SUPPORTERS_CRITERIA_BASIC_INTERESTS,
            additionalInterests: SUPPORTERS_CRITERIA_ADDITIONAL_INTERESTS,
          },
        },
      },
      children: [
        {
          path: "assessment",
          name: SUPPORTERS_CRITERIA_ASSESSMENT,
          component: CriteriaAssessmentView,
          meta: {
            previousPage: SUPPORTERS_CRITERIA_STARTING_POINT,
            nextPage: SUPPORTERS_CRITERIA_QUESTIONS,
          },
        },
        {
          path: "questions",
          name: SUPPORTERS_CRITERIA_QUESTIONS,
          component: CriteriaQuestionsView,
          meta: {
            previousPage: SUPPORTERS_CRITERIA_ASSESSMENT,
            nextPage: SUPPORTERS_CRITERIA_BASIC_INTERESTS,
          },
        },
        {
          path: "basic-interests",
          name: SUPPORTERS_CRITERIA_BASIC_INTERESTS,
          component: CriteriaBasicInterestsView,
          meta: {
            previousPage: SUPPORTERS_CRITERIA_QUESTIONS,
            nextPage: SUPPORTERS_CRITERIA_ADDITIONAL_INTERESTS,
          },
        },
        {
          path: "additional-interests",
          name: SUPPORTERS_CRITERIA_ADDITIONAL_INTERESTS,
          component: CriteriaAdditionalInterestsView,
          meta: {
            previousPage: SUPPORTERS_CRITERIA_BASIC_INTERESTS,
            nextPage: SUPPORTERS_CRITERIA_IMPORTANCE,
          },
        },
        {
          path: "importance",
          name: SUPPORTERS_CRITERIA_IMPORTANCE,
          component: CriteriaImportanceView,
          meta: {
            previousPage: SUPPORTERS_CRITERIA_ADDITIONAL_INTERESTS,
          },
        },
      ],
    }),
  ],
  // To be able to persist the selected language when refreshing the page
  // we setted a local storage variable with the locale selected by the user.
  // When entering in the route we need to validate if the variable is available or not and change the locale accordingly
  // This is only needed because the application is not prepared to display all the pages translated in spanish
  // This code should be removed when the whole application is prepared to be in spanish
  beforeEnter: async (
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: Function,
  ) => {
    const locale = localStorage.getItem("userLocale") || DEFAULT_LOCALE;
    await changeLocale(locale, loadedLocales, document);

    next();
  },
};
