<template>
  <div class="milestone-plan-onboarding-dialog__content">
    <MilestonePlanOnboardingDialogContentFirstPage
      v-if="activePage === 1"
      :is-visitor="isVisitor"
    />
    <MilestonePlanOnboardingDialogContentSecondPage
      v-else
      :is-visitor="isVisitor"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import MilestonePlanOnboardingDialogContentFirstPage from "@/modules/milestone-planner/components/milestone-plan-onboarding-dialog/milestone-plan-onboarding-dialog-content--first-page.vue";
import MilestonePlanOnboardingDialogContentSecondPage from "@/modules/milestone-planner/components/milestone-plan-onboarding-dialog/milestone-plan-onboarding-dialog-content--second-page.vue";

export default defineComponent({
  name: "MilestonePlanOnboardingDialogContent",

  components: {
    MilestonePlanOnboardingDialogContentFirstPage,
    MilestonePlanOnboardingDialogContentSecondPage,
  },

  props: {
    activePage: {
      type: Number,
      required: true,
    },

    isVisitor: {
      type: Boolean,
      default: false,
    },
  },
});
</script>

<style lang="scss">
.milestone-plan-onboarding-dialog__content {
  ul {
    padding: 0 16px;
  }

  li {
    padding-left: 3px;
    margin-bottom: 18px;
  }

  p {
    font-size: 14px;
    line-height: 20px;
    color: $ebony-clay;
    letter-spacing: -0.26px;
  }

  &--filtered-icon {
    position: relative;
    top: 3px;
    left: 2px;
    margin: 0 4px 0 0;
    filter: invert(39%) sepia(19%) saturate(6311%) hue-rotate(198deg)
      brightness(86%) contrast(81%);
  }
}
</style>
