import { ICompanyList } from "@/modules/company-lists/services/data/company-list/company-list.interface";
import { IGenericState } from "@/services/store/generic/generic-state.interface";

/**
 * Represents the store structure for the company list type store data.
 */
export interface ICompanyListState extends IGenericState<ICompanyList> {
  /**
   * Items pagination current page.
   */
  currentDetailPage: number | null;

  /**
   * Informs there is current request happening.
   *
   * By default is must be set to `true`.
   */
  detailLoading: boolean;

  /**
   * Informs if an error occurred during API request.
   */
  detailError: Error | null;
}

export enum ECompanyListState {
  NAMESPACE = "companyList",
}

export enum ECompanyListAction {
  GET_VALUE = "getValue",
  GET_VALUE_DETAIL = "getValueDetail",
  GET_VALUES = "getValues",
  CREATE_VALUE = "createValue",
  PATCH_VALUE = "patchValue",
  REMOVE_VALUE = "removeValue",
}
