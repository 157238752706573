import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Level1 = _resolveComponent("Level1")!
  const _component_Level2 = _resolveComponent("Level2")!
  const _component_Level3 = _resolveComponent("Level3")!
  const _component_Level4 = _resolveComponent("Level4")!
  const _component_Level5 = _resolveComponent("Level5")!
  const _component_Level6 = _resolveComponent("Level6")!
  const _component_Level7 = _resolveComponent("Level7")!
  const _component_Level8 = _resolveComponent("Level8")!
  const _component_Level9 = _resolveComponent("Level9")!

  return (_ctx.level == 1)
    ? (_openBlock(), _createBlock(_component_Level1, {
        key: 0,
        class: "px-level"
      }))
    : (_ctx.level == 2)
      ? (_openBlock(), _createBlock(_component_Level2, {
          key: 1,
          class: "px-level"
        }))
      : (_ctx.level == 3)
        ? (_openBlock(), _createBlock(_component_Level3, {
            key: 2,
            class: "px-level"
          }))
        : (_ctx.level == 4)
          ? (_openBlock(), _createBlock(_component_Level4, {
              key: 3,
              class: "px-level"
            }))
          : (_ctx.level == 5)
            ? (_openBlock(), _createBlock(_component_Level5, {
                key: 4,
                class: "px-level"
              }))
            : (_ctx.level == 6)
              ? (_openBlock(), _createBlock(_component_Level6, {
                  key: 5,
                  class: "px-level"
                }))
              : (_ctx.level == 7)
                ? (_openBlock(), _createBlock(_component_Level7, {
                    key: 6,
                    class: "px-level"
                  }))
                : (_ctx.level == 8)
                  ? (_openBlock(), _createBlock(_component_Level8, {
                      key: 7,
                      class: "px-level"
                    }))
                  : (_ctx.level == 9)
                    ? (_openBlock(), _createBlock(_component_Level9, {
                        key: 8,
                        class: "px-level"
                      }))
                    : _createCommentVNode("", true)
}