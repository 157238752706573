<template>
  <div :style="{ height }" class="level-slider">
    <ElSlider
      ref="slider"
      v-model="innerValue"
      :max="levels"
      :min="0"
      :show-tooltip="false"
      :step="1"
      class="level-slider__inner"
      height="358px"
      show-stops
      vertical
    />
    <div class="level-slider__labels">
      <template v-for="(item, index) in stops" :key="item">
        <div
          v-if="index !== 0"
          :key="item"
          :class="{ 'is-selected': index === innerValue }"
          :style="{ bottom: item + '%' }"
          class="level-slider__level-label"
        >
          {{ index }}
        </div>
        <div
          :class="{ 'is-active': index <= innerValue }"
          :style="{ bottom: item + '%' }"
          class="level-slider__stop"
        />
      </template>
    </div>
  </div>
</template>

<script lang="ts">
import { ComponentPublicInstance, defineComponent } from "vue";

const SLIDER_BAR_SELECTOR = ".el-slider__bar";
const SLIDER_BUTTON_SELECTOR = ".el-slider__button";

export default defineComponent({
  name: "LevelSlider",

  props: {
    /**
     * Number of available levels.
     */
    levels: {
      type: Number,
      required: true,
    },
    /**
     * Current selected level.
     */
    value: {
      type: Number,
      required: true,
    },
    color: {
      type: String,
      default: "2873cb",
    },
    /**
     * Component height.
     */
    height: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      innerValue: this.value,
    };
  },

  computed: {
    stops(): Array<number> {
      const stopCount = this.levels;
      const stepWidth = 100 / this.levels;

      const result = [0];
      for (let i = 1; i <= stopCount; i++) {
        result.push(i * stepWidth);
      }

      return result;
    },
  },

  watch: {
    /**
     * Inform the outside would that the value changed.
     */
    innerValue(newVal) {
      this.$emit("input", newVal);
      this.$emit("changed", newVal);
    },

    /**
     * Set the slider color when the prop `color` changes.
     */
    color() {
      this.setSliderColor();
    },

    /**
     * Update innerValue when the prop value changes.
     */
    value(newValue: number) {
      this.innerValue = newValue;
    },
  },

  mounted() {
    // When the component is rendered for the first time
    // the slider color must be set with the color
    // presented on the prop `color`
    this.setSliderColor();
  },

  methods: {
    /**
     * Sets the slider color based on the color present on the
     * `color` prop.
     */
    setSliderColor() {
      const sliderComponent = this.$refs.slider as ComponentPublicInstance;
      const sliderElement = sliderComponent.$el;
      const progressBar = sliderElement.querySelector(
        SLIDER_BAR_SELECTOR,
      ) as HTMLElement;
      const buttonElement = sliderElement.querySelector(
        SLIDER_BUTTON_SELECTOR,
      ) as HTMLElement;

      [progressBar, buttonElement].forEach((element: HTMLElement) => {
        element.style.backgroundColor = `#${this.color}`;
      });
    },
  },
});
</script>

<style lang="scss">
$slider-width: 24px;
$slider-border-radius: 2px;
$slider-stop-bg-color: rgba(139, 143, 161, 0.6);
$slider-stop-active-bg-color: rgba(39, 39, 64, 0.4);
$slider-button-size: 40px;

.level-slider {
  position: relative;
  margin-bottom: 9px;
}

.el-slider.level-slider__inner.is-vertical {
  top: 17px;

  .el-slider__runway {
    width: $slider-width;

    background: none;
  }

  .el-slider__runway::before {
    position: absolute;
    top: 50%;
    right: 0;
    left: 0;
    height: 393px;

    content: "";

    background-image: linear-gradient(to top, $mischka, $mischka);
    border: solid 1px rgba(0, 0, 0, 0.06);
    border-radius: 100px;

    transform: translateY(-50%);
  }

  .el-slider__bar {
    left: -1px;
    width: $slider-width;

    border: solid 1px $black-20;
    border-radius: 100px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;

    transform: translate(1px, 20px);
  }

  .el-slider__stop {
    display: none;
  }

  .el-slider__button-wrapper {
    transform: translateY(-4px);
  }

  .el-slider__button {
    position: relative;
    width: $slider-button-size;
    height: $slider-button-size;

    background-image: url("#{$assetsPath}/img/icons/slider-shapes.svg");
    background-position: -1px 0;
    border: solid 1px rgba(0, 0, 0, 0.24);
  }

  .el-slider__button:hover,
  .el-slider__button.hover,
  .el-slider__button.dragging {
    transform: translateZ(0) scale(1.07);
  }

  .el-slider__button::before {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 46px;
    height: 46px;

    content: "";

    background-color: transparent;
    border: solid 3px $alabaster;
    border-radius: 50%;

    transform: translate(-50%, -50%);
  }

  .el-slider__button::after {
    position: absolute;
    top: -1px;
    left: -1px;
    width: $slider-button-size;
    height: $slider-button-size;

    content: "";

    background-color: transparent;
    border-radius: 50%;

    box-shadow: 0 0 5px 0 $black-20;
  }
}

.level-slider__labels {
  position: absolute;
  top: 20px;
  left: -20px;
  height: 360px;
}

.level-slider__stop {
  position: absolute;
  left: 43px;
  width: 10px;
  height: 2px;

  background-color: $slider-stop-bg-color;
  border-radius: 2px;

  &.is-active {
    background-color: $slider-stop-active-bg-color;

    box-shadow: inset 0 1px 2px 0 rgba(39, 39, 64, 0.4);
  }
}

.level-slider__level-label {
  @include grotesk(medium);

  position: absolute;

  font-size: 0.9333rem;

  transform: translateY(5px);

  &.is-selected {
    @include grotesk(semiBold);

    font-size: 1.0667rem;

    transform: translate(-1px, 7px);
  }
}
</style>
