import { IBaseState } from "@/services/store/store-types";
import { IAssessment } from "@/services/data/assessment/assessment.interface";

/**
 * Interface that represents the store structure for the
 * latest assessment module on the profile page.
 */
export interface IProfileLatestAssessmentState extends IBaseState {
  data: IAssessment | null;
}

export enum EProfileLatestAssessmentActions {
  FETCH = "profile/latestAssessment/fetch",
  RESET = "profile/latestAssessment/reset",
}

export enum EProfileLatestAssessmentMutations {
  SET_LOADING = "SET_LOADING",
  SET_ERROR = "SET_ERROR",
  SET_DATA = "SET_DATA",
}
