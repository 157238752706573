<template>
  <div
    :class="{
      'milestone-plan-stage-status--left-active': selectedStatus === 1,
      'milestone-plan-stage-status--right-active': selectedStatus === 2,
    }"
    class="milestone-plan-stage-status"
  >
    <div class="milestone-plan-stage-status__wrapper">
      <div
        v-for="(status, index) in stageStatus"
        :key="index"
        class="milestone-plan-stage-status__item"
      >
        <MilestoneStageStatusItem
          :icon="status.icon"
          :is-clickable="false"
          :title="status.title"
        />
        <div class="milestone-plan-stage-status__arrow" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import MilestoneStageStatusItem from "@/modules/milestone-planner/components/milestone-stage-status-item/milestone-stage-status-item.vue";
import { EMilestoneStatusType } from "@/modules/milestone-planner/services/data/milestones/milestone.interface";

export default defineComponent({
  name: "MilestonePlanStageStatus",

  components: {
    MilestoneStageStatusItem,
  },

  props: {
    selectedStatus: {
      type: Number,
      default: 1,
    },
  },

  data() {
    return {
      stageStatus: [
        {
          title: this.$t(
            "milestonePlanner.milestoneGrid.stageStatus.toBePlanned",
          ),
          icon: EMilestoneStatusType.TO_BE_PLANNED,
        },
        {
          title: this.$t("milestonePlanner.milestoneGrid.stageStatus.planned"),
          icon: EMilestoneStatusType.PLANNED,
        },
        {
          title: this.$t(
            "milestonePlanner.milestoneGrid.stageStatus.inProgress",
          ),
          icon: EMilestoneStatusType.IN_PROGRESS,
        },
        {
          title: this.$t(
            "milestonePlanner.milestoneGrid.stageStatus.completed",
          ),
          icon: EMilestoneStatusType.COMPLETED,
        },
      ],
      activeState: this.selectedStatus,
    };
  },

  watch: {
    selectedStatus(newValue) {
      this.activeState = newValue;
    },
  },
});
</script>

<style lang="scss" scoped>
.milestone-plan-stage-status {
  position: relative;
  width: 666px;
  height: 40px;
  padding-top: 9px;
  padding-left: 9px;
  margin: 0;
  border-radius: 4px;

  &__wrapper {
    display: grid;
    grid-template-columns: 182px 144px 166px 129px;
    grid-gap: 12px;
  }

  &__arrow {
    position: relative;
    bottom: 1px;
    left: 6px;
    width: 38px;
    height: 1px;
    margin: 0 auto;
    background: $manatee;

    &::before,
    &::after {
      position: absolute;
      width: 6px;
      height: 1px;
      content: "";
      background: $manatee;
    }

    &::before {
      right: -2px;
      bottom: -2px;
      transform: rotate(-45deg);
    }

    &::after {
      top: -2px;
      right: -2px;
      transform: rotate(45deg);
    }
  }

  &__item {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    &:last-of-type {
      right: 4px;

      .milestone-plan-stage-status__arrow {
        display: none;
      }
    }
  }

  &--left-active {
    .milestone-plan-stage-status__item {
      &:nth-child(1) .milestone-plan-stage-status__arrow,
      // Select the first two elements
      &:nth-child(-n + 2) :deep() .px-stage-status-item {
        filter: invert(39%) sepia(19%) saturate(6311%) hue-rotate(198deg)
          brightness(86%) contrast(81%);
      }
    }
  }

  &--right-active {
    .milestone-plan-stage-status__item {
      &:nth-child(2) .milestone-plan-stage-status__arrow,
      &:nth-child(3) .milestone-plan-stage-status__arrow,
      // Select all elements except the first two
      &:not(:nth-child(-n + 2)) :deep() .px-stage-status-item {
        filter: invert(39%) sepia(19%) saturate(6311%) hue-rotate(198deg)
          brightness(86%) contrast(81%);
      }
    }
  }
}

.milestone-plan-stage-status__item:nth-child(1) :deep() img {
  right: 2px;
}

.milestone-plan-stage-status__item:nth-child(4) :deep() img {
  right: 0;
}
</style>
