<template>
  <div class="update-range-level page-full-height">
    <MobileTopNavbar v-if="$screen.mdDown" />
    <PxNavbar
      v-else
      :items="activeNavBarItems"
      :show-account-dropdown="$user.isLogged()"
      data-testid="update-range-level__navbar"
    />
    <PxGlobalError
      v-if="hasError"
      :auto-refresh="true"
      :loading="isLoading"
      :use-action="true"
      class="page-body is-center"
      @do-action="fetchCategories"
    />
    <div
      v-else
      v-loading="isLoading"
      :class="{ 'is-loading': isLoading }"
      class="update-range-level__container"
      element-loading-background="#fafafa"
    >
      <Assessment :end-action="onEndAction" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import MobileTopNavbar from "@/modules/self-assessment/components/mobile-top-navbar/mobile-top-navbar.vue";

import { ICategory } from "@/services/data/category/category.interface";
import { ECategoryActions } from "@/services/store/category/category-types";
import { ELevelActions } from "@/services/store/levels/levels-types";
import { EMetaGetters } from "@/services/store/meta/meta-types";
import {
  EAuthActions,
  EAuthSupporterActions,
} from "@/modules/authentication/services/store/auth/auth-types";

import { SUPPORTERS_INVESTING_RANGE_META } from "@/modules/supporters/constants";
import { ROUTE_PROFILE } from "@/modules/profile/services/router/routes-names";
import { PROFILE_META_UPDATE_FROM_SUPPORTER_MATCHING } from "@/modules/profile/constants";

import Assessment from "@/modules/supporters/views/assessment/assessment.vue";
import { ROUTE_MATCHING_LIST } from "@/modules/matching/services/router/routes-names";
import { SUPPORTER_USER_GROUP_ID } from "@/modules/common/constants";

import {
  helpScoutBeaconInit,
  helpScoutBeaconDestroy,
} from "@/services/utils/helpscout";
import { SUPPORTER_USER_TYPE } from "@/modules/authentication/constants";
import { ICompany } from "@/modules/profile/services/data/company/company.types";
import { INavBarItem } from "@/modules/profile/meta/entrepreneurs";
import { navBarItemsByFeaturesToggleOrPermissions } from "@/utils/navbar-items-enabled";

export default defineComponent({
  components: {
    MobileTopNavbar,
    Assessment,
  },

  computed: {
    hasError(): boolean {
      return this.$store.get("categories.error");
    },

    isLoading(): boolean {
      return (
        this.$store.get("categories.loading") ||
        this.$store.get("auth/supporter/loading")
      );
    },

    categories(): Array<ICategory> {
      return this.$store.get("categories.data");
    },

    newInvestingLevelRange(): Array<number> {
      return this.$store.getters[EMetaGetters.GET](
        SUPPORTERS_INVESTING_RANGE_META,
      );
    },

    isComingFromMatching(): boolean {
      return this.$store.get(
        "meta/get",
        PROFILE_META_UPDATE_FROM_SUPPORTER_MATCHING,
      );
    },

    profileId(): number | null {
      return this.$store.state.auth.profileId;
    },

    company(): ICompany | null {
      return this.$store.get("auth/company/data");
    },

    companyType(): number | null {
      return this.company !== null ? this.company.type : null;
    },

    navBarItems(): Array<INavBarItem> {
      return this.companyType !== null
        ? this.$profile.getByType("navBarItems", this.companyType)
        : this.$profile.get("navBarItems");
    },

    itemsByEnabledModulesOrFeatures(): Array<INavBarItem> {
      return navBarItemsByFeaturesToggleOrPermissions(this.navBarItems);
    },

    activeNavBarItems(): Array<INavBarItem> {
      return this.$user.isLogged() ? this.itemsByEnabledModulesOrFeatures : [];
    },
  },

  created() {
    this.fetchCategories();
    this.fetchLevels();

    // Load HelpScout chat widget for Supporters
    helpScoutBeaconInit({
      userType: SUPPORTER_USER_TYPE,
    });
  },

  unmounted() {
    // Remove HelpScout chat widget
    helpScoutBeaconDestroy();
  },

  methods: {
    fetchCategories() {
      this.$store.dispatch(ECategoryActions.FETCH, {
        group: SUPPORTER_USER_GROUP_ID,
      });
    },

    fetchLevels() {
      this.$store.dispatch(ELevelActions.FETCH);
    },

    /**
     * Fetch current supporter auth data
     */
    async fetchSupporter() {
      await this.$store.dispatch(EAuthActions.FETCH_SUPPORTER, {
        user_profile: this.profileId,
      });
    },

    onEndAction() {
      this.$store
        .dispatch(EAuthSupporterActions.PATCH, {
          investing_level_range: this.newInvestingLevelRange,
        })
        .then(async () => {
          await this.fetchSupporter();

          if (this.isComingFromMatching) {
            this.$router.push({
              name: ROUTE_MATCHING_LIST,
              query: {
                updatedRangeLevel: "true",
              },
            });
          } else {
            this.$router.push({ name: ROUTE_PROFILE });

            this.$message({
              message: this.$t(
                "profile.investors.successUpdatingInvestingRangeLevel",
              ) as string,
              type: "success",
              customClass: "is-full",
            });
          }
        });
    },
  },
});
</script>
