<template>
  <div v-show="visible" class="overlay-confirm">
    <div
      :class="{ 'is-fixed': fixedOnScroll }"
      class="overlay-confirm__content"
    >
      <div class="text-center">
        <h2 v-html="options.message" />
        <p v-if="options.tip" v-html="options.tip" />
        <div class="overlay-confirm__buttons">
          <PxButton
            :class="options.cancelButtonClass"
            :disabled="isButtonsDisabled"
            type="link"
            @click="onClickCancel"
          >
            <span v-html="dialogCancelButtonText" />
          </PxButton>
          <PxButton
            :class="options.confirmButtonClass"
            :disabled="isButtonsDisabled"
            type="red"
            @click="onClickConfirm"
          >
            <span v-html="dialogConfirmButtonText" />
          </PxButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { IDialogConfirmationOptions } from "@/components/dialog-confirmation/dialog-confirmation.types";
import { defineComponent } from "vue";

export default defineComponent({
  name: "DialogConfirmation",
  data() {
    return {
      isLoading: false,
      visible: false,
      isButtonsDisabled: false,
      fixedOnScroll: false,
      options: {
        message: "",
        tip: "",
        cancelButtonType: "link",
        cancelButtonText: "",
        cancelButtonClass: { "el-button--link": true },
        confirmButtonType: "red",
        confirmButtonText: "",
        confirmButtonClass: { "text-uppercase": true },
        confirmFn: () => {},
        cancelFn: () => {},
      } as IDialogConfirmationOptions,
    };
  },

  computed: {
    dialogCancelButtonText(): string {
      return !this.options.cancelButtonText &&
        !this.options.cancelButtonText?.length
        ? this.$t("common.dialogConfirmation.cancel")
        : this.options.cancelButtonText;
    },

    dialogConfirmButtonText(): string {
      return !this.options.confirmButtonText &&
        !this.options.confirmButtonText?.length
        ? this.$t("common.dialogConfirmation.confirm")
        : this.options.confirmButtonText;
    },

    isInsideElDialogBody(): boolean {
      return this.$el?.parentElement?.classList?.contains("el-dialog__body");
    },

    parentElDialogElement(): HTMLElement | null {
      return this.isInsideElDialogBody ? this.$el?.closest(".el-dialog") : null;
    },
  },

  watch: {
    /**
     * When the visible change check if the overlay must be fixed.
     */
    async visible(isVisible: boolean) {
      await this.$nextTick();

      if (
        (this.$el as any).clientHeight + 120 >= window.innerHeight &&
        isVisible
      ) {
        this.fixedOnScroll = true;
      }

      if (isVisible) {
        this.parentElDialogElement?.classList.add("el-dialog--overlay");
      } else {
        this.parentElDialogElement?.classList.remove("el-dialog--overlay");
      }
    },
  },

  mounted() {
    if (this.isInsideElDialogBody && this.parentElDialogElement) {
      this.parentElDialogElement.appendChild(this.$el);
    }
  },

  methods: {
    updateDialogConfirmationOptions(
      dialogConfirmationOptions: IDialogConfirmationOptions,
    ) {
      Object.keys(dialogConfirmationOptions).forEach((optionName: string) => {
        (this.options as any)[optionName] = (dialogConfirmationOptions as any)[
          optionName
        ];
      });
    },

    /**
     * Make the overlay visible
     */
    show(dialogConfirmationOptions: IDialogConfirmationOptions) {
      if (dialogConfirmationOptions) {
        this.updateDialogConfirmationOptions(dialogConfirmationOptions);
      }

      this.visible = true;
    },

    /**
     * Hide dialog overlay.
     */
    hide() {
      this.visible = false;
    },

    /**
     * Execute the cancel routine.
     */
    onClickCancel() {
      if (this.options?.cancelFn) this.options.cancelFn();
      this.hide();
    },

    /**
     * Execute the confirm routine.
     */
    async onClickConfirm() {
      this.isLoading = true;
      this.isButtonsDisabled = true;
      if (this.options?.confirmFn) this.options.confirmFn();

      this.isButtonsDisabled = false;
      this.isLoading = false;
      this.hide();
    },
  },
});
</script>

<style lang="scss" scoped>
$--modal-header-size: 66px;
$--overlay-content-width: 341px;

.overlay-confirm {
  position: absolute;
  top: $--modal-header-size;
  left: 0;
  z-index: z("overlay");
  width: 100%;
  height: calc(100% - 66px);
  padding: 20px;

  background-color: white;
  border-radius: $--border-radius-medium;

  &__content {
    position: relative;
    top: 50%;
    left: 50%;
    max-width: $--overlay-content-width;

    transform: translate(-50%, -50%);

    &--fixed {
      position: fixed;
      top: 45px;
      left: 50%;

      transform: translate(-50%);
    }
  }

  &__buttons {
    margin: 0 auto;
    text-align: center;

    .el-button {
      margin-left: 14px;
    }

    .el-button:first-child {
      margin-left: 0;
    }
  }

  h2 {
    @include grotesk(semiBold);

    margin-bottom: 10px;
    font-size: to-rem(18px);
    line-height: 28px;
    text-align: center;
    letter-spacing: -0.15px;

    @include breakpoint-up(md) {
      font-size: to-rem(20px);
    }
  }

  p {
    margin-bottom: 32px;
    font-size: to-rem(13px);
    line-height: 22px;
    text-align: center;
    letter-spacing: -0.35px;
    white-space: pre-wrap;

    @include breakpoint-up(md) {
      font-size: to-rem(15px);
    }
  }

  h2 + p {
    margin-top: 7px;
  }

  h2 + .overlay-confirm__buttons {
    margin-top: 15px;
  }
}
</style>
