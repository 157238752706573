<template>
  <div class="account-settings-password">
    <h1>
      {{ $t("profile.accountSettingsPage.passwordForm.title") }}
    </h1>
    <p>
      {{ $t("profile.accountSettingsPage.passwordForm.description") }}
    </p>
    <ElForm
      ref="password"
      :model="fields"
      :rules="rules"
      class="account-settings-password__form"
      @validate="updateSubmitState"
    >
      <ElFormItem
        :label="
          $t('profile.accountSettingsModal.changePassword.fields.oldPassword')
        "
        prop="old_password"
      >
        <ElInput
          v-model="fields.old_password"
          :placeholder="
            $t('profile.accountSettingsModal.changePassword.fields.oldPassword')
          "
          autocomplete="new-password"
          data-testid="account-settings-old-password"
          type="password"
        />
      </ElFormItem>
      <ElFormItem
        :label="
          $t('profile.accountSettingsModal.changePassword.fields.newPassword')
        "
        prop="new_password1"
      >
        <span
          class="el-form-item__top-text el-form-item__requirement"
          v-text="$t('profile.accountSettingsModal.changePassword.requirement')"
        />
        <PxInputPassword
          v-model="fields.new_password1"
          :placeholder="
            $t('profile.accountSettingsModal.changePassword.fields.newPassword')
          "
          :show-success-state="true"
          autocomplete="new-password"
          data-testid="account-settings-new-password"
        />
        <p
          class="el-form-item__tip"
          v-html="$t('profile.accountSettingsModal.changePassword.tip')"
        />
      </ElFormItem>
      <ElFormItem
        :label="
          $t(
            'profile.accountSettingsModal.changePassword.fields.confirmPassword',
          )
        "
        prop="new_password2"
      >
        <ElInput
          v-model="fields.new_password2"
          :disabled="newPasswordIsInvalid"
          :placeholder="
            $t(
              'profile.accountSettingsModal.changePassword.fields.confirmPassword',
            )
          "
          class="el-input--password"
          data-testid="account-settings-confirm-password"
          type="password"
        />
      </ElFormItem>
    </ElForm>
    <footer>
      <PxButton
        :disabled="formHasNoChanges"
        class="el-button--small account-settings-password__cancel-btn"
        type="link"
        @click="cancelPasswordChange"
      >
        {{ $t("profile.accountSettingsModal.changePassword.resetButton") }}
      </PxButton>
      <PxButton
        :disabled="isSubmissionDisabled"
        :loading="isLoading"
        class="account-settings-password__submit-btn"
        type="green"
        @click="updatePassword"
      >
        {{ $t("profile.accountSettingsModal.changePassword.submitButton") }}
      </PxButton>
    </footer>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import {
  generateRequiredValidator,
  generatePasswordValidator,
  generatePasswordCheck,
  allFormFieldsValid,
} from "@/services/errors/validator-generators";
import { IFormField } from "@/modules/company-lists/components/list-management/list-management-modal.vue";
import { passwordChangeProvider } from "@/modules/authentication/services/data/password-change/password-change.provider";
import { FormInstance } from "element-plus";

export default defineComponent({
  name: "AccountSettingsPassword",

  data() {
    return {
      passwordFormEl: null as null | FormInstance,
      isSubmissionDisabled: true,
      isLoading: false,
      fields: {
        old_password: "",
        new_password1: "",
        new_password2: "",
      },
      rules: {
        old_password: generateRequiredValidator(
          this,
          "profile.accountSettingsModal.changePassword.fields.oldPassword",
        ),

        new_password1: generatePasswordValidator(
          this,
          "profile.accountSettingsModal.changePassword.fields.newPassword",
          {
            minimum: 8,
            enforceCharRequirements: true,
          },
        ),

        new_password2: generatePasswordCheck(this, "fields.new_password1", {
          minimum: 8,
        }),
      },
    };
  },

  computed: {
    passwordFormFields(): IFormField[] | null {
      return this.passwordFormEl
        ? ((this.passwordFormEl as any).fields as IFormField[])
        : null;
    },

    newPasswordIsInvalid(): boolean {
      if (this.passwordFormFields) {
        const formFields = this.passwordFormFields;

        const passwordField = formFields.find(
          (field) => field.prop === "new_password1",
        );

        return passwordField?.validateState !== "success";
      }
      return true;
    },

    formHasNoChanges(): boolean {
      return (
        this.fields.old_password === "" &&
        this.fields.new_password1 === "" &&
        this.fields.new_password2 === ""
      );
    },
  },

  watch: {
    newPasswordIsInvalid(newVal: boolean, oldVal: boolean) {
      if (newVal === oldVal) return;

      this.passwordFormEl?.validateField(
        "new_password2",
        this.updateSubmitState,
      );
    },
  },

  mounted() {
    (this as any).passwordFormEl = this.$refs.password;
  },

  methods: {
    updateSubmitState() {
      this.isSubmissionDisabled = !allFormFieldsValid(
        this.$refs.password,
        this.rules,
      );
    },

    cancelPasswordChange() {
      this.passwordFormEl?.resetFields();
      this.updateSubmitState();
    },

    async updatePassword() {
      this.isLoading = true;

      try {
        await passwordChangeProvider.create(this.fields);

        this.$message({
          message: this.$t(
            "profile.accountSettingsModal.changePassword.successMessage",
          ) as string,
          type: "success",
          customClass: "is-full",
        });
      } catch (error) {
        this.$message({
          message: this.$t(
            "profile.accountSettingsModal.changePassword.errorMessage",
          ) as string,
          type: "error",
          customClass: "is-full",
        });
      }
      this.passwordFormEl?.resetFields();
      this.isLoading = false;
      this.updateSubmitState();
    },
  },
});
</script>

<style lang="scss" scoped>
.account-settings-password {
  padding: 22px 24px 23px 23px;
  border-radius: 4px;
  border: solid 1px $mischka;
  background-color: $white;

  @include breakpoint-up(sm) {
    :deep() .el-form-item__tip {
      margin-top: 6px;
    }
  }

  @include breakpoint-down(sm) {
    border-left: none;
    border-right: none;
    border-radius: 0;

    padding: 22px 16px 24px 16px;

    :deep() .el-input__inner {
      height: 40px;
    }

    :deep() .el-form-item {
      margin-bottom: 3px;
    }

    :deep() .el-form-item__tip {
      margin-top: 7px;
      line-height: 1.2;
    }

    &__submit-btn {
      height: 36px;
    }
  }

  h1 {
    font-size: 20px;
    line-height: 1.6;
    margin: 0;
    letter-spacing: -0.2px;
  }

  p {
    font-size: 14px;
    line-height: 1.43;
    margin: 0;
    color: $manatee;
    letter-spacing: -0.33px;

    @include breakpoint-down(sm) {
      letter-spacing: -0.35px;
    }
  }

  &__form {
    margin-top: 12px;
  }

  :deep() .el-form-item__label {
    padding-top: 14px;
    margin-bottom: 3px;
  }

  :deep() .el-form-item__top-text {
    height: 27px;
  }

  :deep() .el-form-item__tip {
    font-size: 12px;
    letter-spacing: -0.1px;
  }

  :deep() .el-form-item__content {
    max-width: 509px;
  }

  :deep() .el-form-item__error {
    padding-top: 2px;
  }

  :deep() .el-input__inner {
    box-shadow: inset 0 0 5px 0 rgba(0, 0, 0, 0.07);
  }

  :deep() .is-disabled > .el-input__inner {
    background-color: $alabaster;
    border-color: $mischka;
  }

  footer {
    display: flex;
    gap: 14px;
    padding: 20px 0 0 0;
  }

  &__cancel-btn {
    padding: 14px 0 11px;
  }

  &__submit-btn {
    padding: 0 15px;
  }
}
</style>
