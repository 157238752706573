<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="173"
    height="173"
    viewBox="0 0 173 173"
    class="svg"
  >
    <defs>
      <linearGradient id="viral-graph-5-c" x1="50%" x2="50%" y1="0%" y2="100%">
        <stop offset="0%" stop-color="#58A0F8" class="linear-gradient-color1" />
        <stop
          offset="100%"
          stop-color="#2176DD"
          class="linear-gradient-color2"
        />
      </linearGradient>
      <path
        id="viral-graph-5-b"
        d="M84.7274911,6 L94.3628414,17.6683966 C86.85276,23.8630207 80.8179647,31.6828876 76.7560338,40.5220742 L63,34.2077417 C68.0170566,23.2901017 75.4628736,13.6418376 84.7274911,6 Z"
      />
      <filter
        id="viral-graph-5-a"
        width="163.8%"
        height="157.9%"
        x="-31.9%"
        y="-23.2%"
        filterUnits="objectBoundingBox"
      >
        <feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur
          in="shadowOffsetOuter1"
          result="shadowBlurOuter1"
          stdDeviation="3"
        />
        <feComposite
          in="shadowBlurOuter1"
          in2="SourceAlpha"
          operator="out"
          result="shadowBlurOuter1"
        />
        <feColorMatrix
          in="shadowBlurOuter1"
          values="0 0 0 0 0.333333333 0 0 0 0 0.623529412 0 0 0 0 0.976470588 0 0 0 0.5 0"
          class="color-matrix"
        />
      </filter>
      <linearGradient id="viral-graph-5-f" x1="50%" x2="50%" y1="0%" y2="100%">
        <stop offset="0%" stop-color="#64A9FE" class="linear-gradient-color3" />
        <stop
          offset="100%"
          stop-color="#1865C4"
          class="linear-gradient-color2"
        />
      </linearGradient>
      <path
        id="viral-graph-5-e"
        d="M78.30625,151.548387 C109.955618,151.548387 135.6125,125.905857 135.6125,94.2741935 C135.6125,62.6425299 109.955618,37 78.30625,37 C46.6568821,37 21,62.6425299 21,94.2741935 C21,125.905857 46.6568821,151.548387 78.30625,151.548387 Z M78.30625,151.548387 C109.955618,151.548387 135.6125,125.905857 135.6125,94.2741935 C135.6125,62.6425299 109.955618,37 78.30625,37 C46.6568821,37 21,62.6425299 21,94.2741935 C21,125.905857 46.6568821,151.548387 78.30625,151.548387 Z M78.30625,151.548387 C109.955618,151.548387 135.6125,125.905857 135.6125,94.2741935 C135.6125,62.6425299 109.955618,37 78.30625,37 C46.6568821,37 21,62.6425299 21,94.2741935 C21,125.905857 46.6568821,151.548387 78.30625,151.548387 Z"
      />
      <filter
        id="viral-graph-5-d"
        width="141%"
        height="141%"
        x="-20.5%"
        y="-18.8%"
        filterUnits="objectBoundingBox"
      >
        <feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur
          in="shadowOffsetOuter1"
          result="shadowBlurOuter1"
          stdDeviation="7.5"
        />
        <feComposite
          in="shadowBlurOuter1"
          in2="SourceAlpha"
          operator="out"
          result="shadowBlurOuter1"
        />
        <feColorMatrix
          in="shadowBlurOuter1"
          values="0 0 0 0 0.325490196 0 0 0 0 0.584313725 0 0 0 0 0.894117647 0 0 0 0.5 0"
          class="color-matrix"
        />
      </filter>
      <linearGradient
        id="viral-graph-5-g"
        x1="50%"
        x2="50%"
        y1="0%"
        y2="98.69%"
      >
        <stop offset="0%" stop-color="#FFF" />
        <stop
          offset="100%"
          stop-color="#D5E3F4"
          stop-opacity="0"
          class="linear-gradient-color2"
        />
      </linearGradient>
    </defs>
    <g fill="none" fill-rule="evenodd" transform="translate(8 1)">
      <path
        fill="#E8E9EF"
        fill-rule="nonzero"
        d="M139.977833,119.776364 C143.00868,112.26087 144.647393,104.216233 144.775206,95.9385564 L155.477448,95.7066742 C155.375749,105.49579 153.44915,115.025615 149.844069,123.915431 L139.977834,119.776365 Z M144.755071,92.9382882 C144.526591,85.0542462 142.917418,77.4729257 140.144911,70.4505336 L149.934728,66.1126781 C153.262646,74.4021484 155.190573,83.3718176 155.451252,92.7065374 L144.755079,92.9382883 Z M138.980318,67.685248 C135.364379,59.6135503 130.182141,52.3604537 123.786208,46.3415821 L130.779297,38.2336955 C138.365735,45.2881278 144.503239,53.8289882 148.762951,63.3505762 L138.980318,67.685248 Z M121.555376,44.3347651 C114.253304,38.0568478 105.543733,33.3159307 95.8897919,30.6579165 L98.4775138,20.2743231 C109.794458,23.3486873 119.99966,28.8846502 128.54287,36.2333662 L121.555376,44.3347651 Z"
      />
      <path
        class="linear-gradient-color4"
        fill="#7CB8FF"
        fill-rule="nonzero"
        d="M60.9933893,30.4868991 C52.5268043,32.7321065 44.6660597,36.6157949 37.8108988,41.8214286 L31.1284735,33.462991 C39.1255049,27.3465541 48.3136859,22.7850549 58.2198145,20.153988 L60.9933893,30.4868991 Z M63.9108159,29.7836432 L61.1344825,19.440455 C63.2830549,18.9587153 65.4623869,18.5670308 67.6678428,18.2690799 L69.1001535,28.8711538 C67.3503441,29.1075482 65.6195196,29.4125391 63.9108159,29.7836432 Z M35.46295,43.6888316 C28.4428892,49.5336881 22.6072408,56.8254403 18.43353,65.1859615 L8.65349045,60.8275488 C13.5411298,50.8866714 20.4461534,42.2325437 28.7812061,35.3312464 L35.46295,43.6888316 Z M11.46423,94.3612903 L0.759577529,94.3612903 C0.831575424,83.6103876 3.10436998,73.1696215 7.37999819,63.5444405 L17.1565305,67.9012902 C13.4893154,76.1626162 11.5358524,85.1202412 11.4642307,94.3612903 Z M11.5071998,97.3612907 C11.8036338,105.490214 13.5602883,113.374853 16.6605955,120.729926 L6.80777614,124.911659 C3.14929041,116.242023 1.09817923,106.938049 0.796686673,97.3612906 L11.5071899,97.3612903 Z"
      />
      <g fill-rule="nonzero" transform="rotate(53 78.681 23.261)">
        <use
          fill="#000"
          filter="url(#viral-graph-5-a)"
          xlink:href="#viral-graph-5-b"
        />
        <use fill="url(#viral-graph-5-c)" xlink:href="#viral-graph-5-b" />
      </g>
      <use
        fill="#000"
        filter="url(#viral-graph-5-d)"
        xlink:href="#viral-graph-5-e"
      />
      <path
        fill="url(#viral-graph-5-f)"
        stroke="#181818"
        stroke-linejoin="round"
        stroke-opacity=".2"
        d="M78.30625,151.048387 C109.679538,151.048387 135.1125,125.629652 135.1125,94.2741935 C135.1125,62.9187349 109.679538,37.5 78.30625,37.5 C46.9329618,37.5 21.5,62.9187349 21.5,94.2741935 C21.5,125.629652 46.9329618,151.048387 78.30625,151.048387 Z M78.30625,151.048387 C109.679538,151.048387 135.1125,125.629652 135.1125,94.2741935 C135.1125,62.9187349 109.679538,37.5 78.30625,37.5 C46.9329618,37.5 21.5,62.9187349 21.5,94.2741935 C21.5,125.629652 46.9329618,151.048387 78.30625,151.048387 Z M78.30625,151.048387 C109.679538,151.048387 135.1125,125.629652 135.1125,94.2741935 C135.1125,62.9187349 109.679538,37.5 78.30625,37.5 C46.9329618,37.5 21.5,62.9187349 21.5,94.2741935 C21.5,125.629652 46.9329618,151.048387 78.30625,151.048387 Z"
      />
      <ellipse
        cx="78.338"
        cy="94.565"
        rx="56.819"
        ry="56.79"
        fill="url(#viral-graph-5-f)"
        stroke="#181818"
        stroke-linejoin="round"
        stroke-opacity=".2"
      />
      <ellipse
        cx="78.338"
        cy="94.565"
        stroke="url(#viral-graph-5-g)"
        stroke-width="2"
        opacity=".402"
        rx="49.819"
        ry="49.79"
      />
      <path
        fill="#FFF"
        d="M78.0263672,114.615234 C70.2128906,114.615234 63.9067383,108.708984 62.3378906,101.387695 L69.5668945,99.3881836 C70.3974609,103.356445 72.7353516,107.570801 78.0263672,107.570801 C82.7329102,107.570801 86.0244141,103.356445 86.0244141,99.1420898 C86.0244141,94.8354492 83.1328125,91.144043 78.0263672,91.144043 C75.1962891,91.144043 72.2124023,92.6513672 70.6435547,95.1738281 L63.5991211,93.3896484 L67.8134766,70.5336914 L91.9921875,70.5336914 L91.9921875,77.7011719 L73.996582,77.7011719 L72.3969727,85.8530273 C74.7963867,84.5917969 76.949707,84.1303711 79.1645508,84.1303711 C87.2856445,84.1303711 93.9609375,90.9287109 93.9609375,99.2036133 C93.9609375,107.447754 86.7011719,114.615234 78.0263672,114.615234 Z"
      />
    </g>
  </svg>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "Level5",
});
</script>
