import { GenericProvider } from "@/services/data/generic-provider";
import { ISupport } from "@/services/data/support/support.interface";

class SupportProvider extends GenericProvider<ISupport> {
  constructor() {
    super(`/support`);
  }

  /**
   * Send a suggestion request
   */
  public async postSuggestion(supportData: ISupport): Promise<void> {
    const url = super.buildEndPointUrl({}, "suggestion");
    const request = this.httpClient.post(url, supportData);
    const { data } = await this.wrapRequest(request);

    return data;
  }
}

export const supportProvider = new SupportProvider();
