import {
  GenericProvider,
  EProviderFeatures,
  Provider,
} from "@/services/data/generic-provider";
import { IConfirmResetToken } from "@/modules/authentication/services/data/password-reset/confirm-reset-token.interface";
import { baseAPIUrl } from "@/services/utils/utils";

/**
 * Provider used to request a reset token confirmation.
 */
@Provider(`${baseAPIUrl}/user/password/reset/verify`, [
  EProviderFeatures.CREATE,
])
class ConfirmResetTokenProvider extends GenericProvider<
  any,
  IConfirmResetToken
> {}

export const confirmResetTokenProvider = new ConfirmResetTokenProvider();
