import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withCtx as _withCtx, createSlots as _createSlots, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "matching-confirm-modal__location-text" }
const _hoisted_2 = { class: "matching-confirm-modal__sectors-list" }
const _hoisted_3 = {
  key: 0,
  class: "matching-confirm-modal__level-card"
}
const _hoisted_4 = { class: "matching-confirm-modal__level-card-title" }
const _hoisted_5 = {
  key: 1,
  class: "matching-confirm-modal__empty-level"
}
const _hoisted_6 = { class: "matching-confirm-modal__empty-level-title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PxModalMessage = _resolveComponent("PxModalMessage")!
  const _component_PxInputPlaces = _resolveComponent("PxInputPlaces")!
  const _component_PxEditPanel = _resolveComponent("PxEditPanel")!
  const _component_ElTag = _resolveComponent("ElTag")!
  const _component_SectorsSelector = _resolveComponent("SectorsSelector")!
  const _component_PxIcon = _resolveComponent("PxIcon")!
  const _component_PxButton = _resolveComponent("PxButton")!
  const _component_ElDialog = _resolveComponent("ElDialog")!

  return (_openBlock(), _createBlock(_component_ElDialog, {
    ref: "modal",
    modelValue: _ctx.innerVisibility,
    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.innerVisibility) = $event)),
    "close-on-click-modal": false,
    "close-on-press-escape": false,
    "show-close": false,
    class: "el-dialog--no-header el-dialog--compact-footer matching-confirm-modal",
    width: "661px"
  }, {
    footer: _withCtx(() => [
      _createVNode(_component_PxButton, {
        disabled: !_ctx.hasMatchingMandatoryData,
        loading: _ctx.isLoading,
        class: "matching-confirm-modal__submit-btn",
        type: "green",
        onClick: _ctx.onClickProceedButton
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.nextButtonLabel), 1)
        ]),
        _: 1
      }, 8, ["disabled", "loading", "onClick"])
    ]),
    default: _withCtx(() => [
      _createVNode(_component_PxModalMessage, { visible: _ctx.hasServerError }, null, 8, ["visible"]),
      _createVNode(_component_PxModalMessage, {
        title: _ctx.errorMessage,
        visible: !_ctx.hasMatchingMandatoryData
      }, null, 8, ["title", "visible"]),
      _createElementVNode("p", {
        class: _normalizeClass([{
        'matching-confirm-modal__title-error':
          _ctx.hasServerError || !_ctx.hasMatchingMandatoryData,
      }, "matching-confirm-modal__title"])
      }, _toDisplayString(_ctx.$t("matching.confirmModal.title")), 3),
      _createVNode(_component_PxEditPanel, {
        "is-save-button-disabled": !_ctx.newLocation,
        title: _ctx.$t('matching.confirmModal.location.title'),
        onCancel: _ctx.resetLocationValues,
        onSubmit: _ctx.onSubmitLocation
      }, _createSlots({
        edit: _withCtx(() => [
          _createVNode(_component_PxInputPlaces, {
            id: "companyEditLocation",
            modelValue: _ctx.newLocation,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.newLocation) = $event)),
            placeholder: _ctx.$t('matching.confirmModal.location.placeholder'),
            "use-geolocation": true
          }, null, 8, ["modelValue", "placeholder"])
        ]),
        _: 2
      }, [
        (_ctx.location)
          ? {
              name: "normal",
              fn: _withCtx(() => [
                _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.location.formatted_address), 1)
              ]),
              key: "0"
            }
          : {
              name: "normal",
              fn: _withCtx(() => [
                _createElementVNode("span", { class: "matching-confirm-modal__location-text" }, _toDisplayString(_ctx.$t("matching.confirmModal.location.noLocation")), 1)
              ]),
              key: "1"
            }
      ]), 1032, ["is-save-button-disabled", "title", "onCancel", "onSubmit"]),
      _createVNode(_component_PxEditPanel, {
        "is-save-button-disabled": !_ctx.newSectors.length,
        title: _ctx.$t('matching.confirmModal.sectors.title'),
        onEdit: _ctx.onEditSectors,
        onSubmit: _ctx.onSubmitSector
      }, _createSlots({
        edit: _withCtx(() => [
          _createVNode(_component_SectorsSelector, {
            ref: "sectorSelector",
            modelValue: _ctx.newSectors,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.newSectors) = $event)),
            "no-label": ""
          }, null, 8, ["modelValue"])
        ]),
        _: 2
      }, [
        (_ctx.hasSector)
          ? {
              name: "normal",
              fn: _withCtx(() => [
                _createElementVNode("div", _hoisted_2, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sectors, (sector) => {
                    return (_openBlock(), _createBlock(_component_ElTag, {
                      key: sector.id,
                      class: "text--capitalize",
                      effect: "plain",
                      textContent: _toDisplayString(sector.name)
                    }, null, 8, ["textContent"]))
                  }), 128))
                ])
              ]),
              key: "0"
            }
          : {
              name: "normal",
              fn: _withCtx(() => [
                _createElementVNode("span", { class: "matching-confirm-modal__location-text" }, _toDisplayString(_ctx.$t("matching.confirmModal.sectors.noSectors")), 1)
              ]),
              key: "1"
            }
      ]), 1032, ["is-save-button-disabled", "title", "onEdit", "onSubmit"]),
      _createVNode(_component_PxEditPanel, {
        ref: "matching-assessment",
        class: _normalizeClass({ 'has-error': _ctx.hasAssessmentLevel }),
        "is-redirecting": _ctx.isViralLevelLoading,
        title: _ctx.$t('matching.confirmModal.level.title'),
        onEdit: _ctx.onEditViralLevel
      }, {
        normal: _withCtx(() => [
          (_ctx.latestAssessmentLevel)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createVNode(_component_PxIcon, {
                  name: `level-graph/${_ctx.latestAssessmentLevel.value}`,
                  size: _ctx.$screen.mdUp ? 34 : 42,
                  class: "matching-confirm-modal__level-card-indicator"
                }, null, 8, ["name", "size"]),
                _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.latestAssessmentLevel.title), 1)
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _createVNode(_component_PxIcon, {
                  size: 34,
                  class: "matching-confirm-modal__empty-level-icon",
                  name: "warning"
                }),
                _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.$t(`matching.confirmModal.level.empty`)), 1)
              ]))
        ]),
        _: 1
      }, 8, ["class", "is-redirecting", "title", "onEdit"])
    ]),
    _: 1
  }, 8, ["modelValue"]))
}