<template>
  <div
    :class="{
      'px-navigation-tab-item--list-format': listFormat,
    }"
    class="px-navigation-tab-item"
  >
    <div
      :class="{
        'px-navigation-tab-item--active': active,
        'px-navigation-tab-item--icon-right': iconPosition === 'right',
      }"
      class="px-navigation-tab-item__container"
      @click.stop="onTabClick"
    >
      <PxIcon v-if="icon" :name="icon" :size="iconSize" data-testid="icon" />
      <h3 class="px-navigation-tab-item__title">
        <span v-if="showNumber">{{ number }}.</span>
        {{ title }}
      </h3>
    </div>
    <div
      v-if="active"
      :class="{
        'px-navigation-tab-item__border-left': listFormat,
      }"
      class="px-navigation-tab-item__border"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "PxNavigationTabItem",

  props: {
    title: {
      required: true,
      type: String,
    },
    icon: {
      type: String,
      default: "",
    },
    active: {
      type: Boolean,
      default: false,
    },
    number: {
      type: Number,
      required: true,
    },
    showNumber: {
      type: Boolean,
      default: true,
    },
    iconSize: {
      type: Number,
      default: 17,
    },
    listFormat: {
      type: Boolean,
      default: false,
    },
    iconPosition: {
      type: String,
      default: "left",
      validator: (value: string) => {
        return ["left", "right"].includes(value);
      },
    },
  },

  methods: {
    onTabClick() {
      this.$emit("click");
    },
  },
});
</script>

<style lang="scss" scoped>
.px-navigation-tab-item {
  &__container {
    display: flex;
    gap: 8px;
    align-items: center;
    justify-content: center;
    padding: 0 20px 6px 20px;

    &:hover {
      cursor: pointer;
    }
  }

  &__title {
    @include grotesk(regular);

    margin: 0;
    font-size: 17px;
    font-weight: 600;
    color: $manatee;
    letter-spacing: 0.1px;

    span {
      margin-right: 1px;
      font: inherit;
    }
  }

  &__border:not(.px-navigation-tab-item__border-left) {
    height: 3px;
    background: $bluish;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }

  &__border-left {
    height: 40px;
    width: 4px;
    background: $bluish;
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
  }

  .px-icon {
    transition: none;
  }

  &--active {
    .px-icon {
      filter: invert(39%) sepia(19%) saturate(6311%) hue-rotate(198deg)
        brightness(86%) contrast(81%);
    }

    .px-navigation-tab-item__title {
      color: $bluish;
    }
  }

  &--icon-right {
    flex-direction: row-reverse;
  }

  &--list-format {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    height: 40px;

    .px-navigation-tab-item__container.px-navigation-tab-item--active {
      padding: 0 23px 0 19px;
    }

    .px-navigation-tab-item__container {
      width: 100%;
      justify-content: space-between;
      padding: 0 23px 0 23px;
    }
  }
}
</style>
