import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Submission = _resolveComponent("Submission")!
  const _component_ResultsBoard = _resolveComponent("ResultsBoard")!
  const _component_FeaturesShowcase = _resolveComponent("FeaturesShowcase")!
  const _directive_loading = _resolveDirective("loading")!

  return _withDirectives((_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([{
      'is-loading': _ctx.isLoading,
      'supporter-results--affiliate': !_ctx.isDefaultAffiliate,
    }, "supporter-results"])
  }, [
    (_ctx.showSubmissionView)
      ? (_openBlock(), _createBlock(_component_Submission, {
          key: 0,
          "show-notification": _ctx.isLoggedSubmissionFlow,
          submission: _ctx.submission
        }, null, 8, ["show-notification", "submission"]))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          _createVNode(_component_ResultsBoard),
          _createVNode(_component_FeaturesShowcase)
        ], 64))
  ], 2)), [
    [_directive_loading, _ctx.isLoading]
  ])
}