import { Module } from "vuex";

import { ILevelState } from "./levels-types";
import { RootState } from "../root-state";

import { state } from "./levels-state";
import { mutations } from "./levels-mutations";
import { actions } from "./levels-actions";
import { getters } from "./levels-getters";

export const levelsModule: Module<ILevelState, RootState> = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
