<template>
  <div class="auth-signup__step signup-step-supporter">
    <PxPanel class="px-panel--compact">
      <template #header>
        <h2
          class="px-panel__title"
          v-html="$t('supporters.signup.form.supporterDetails')"
        />
      </template>
      <ElForm
        ref="signupForm"
        :model="data"
        :rules="rules"
        class="auth-signup__form"
        @validate="updateSubmitState"
      >
        <ElFormItem
          :label="$t('supporters.signup.form.fields.supporterName')"
          prop="supporter.name"
        >
          <ElInput
            v-model="data.supporter.name"
            :placeholder="$t('supporters.signup.form.fields.supporterName')"
          />
        </ElFormItem>
        <ElFormItem
          :label="$t('supporters.signup.form.fields.supporterType')"
          prop="supporter.types"
        >
          <ElCheckboxGroup v-model="selectedTypes">
            <ElCheckbox
              v-for="(type, key) in availableTypes"
              :key="key"
              :label="type.id"
            >
              {{ type.name }}
              <ElTooltip
                v-if="type.label"
                placement="top"
                popper-class="auth-signup__tooltip el-abaca-tooltip"
              >
                <template #content>
                  <div v-html="type.label" />
                </template>
                <i class="icon icon-question" />
              </ElTooltip>
            </ElCheckbox>
            <ElCheckbox @change="otherTypeChangeHandler">
              {{ $t("supporters.signup.form.fields.otherSupporterType") }}
            </ElCheckbox>
          </ElCheckboxGroup>
          <ElInput
            v-if="hasOtherType"
            v-model="data.supporter.otherType"
            :placeholder="
              $t('supporters.signup.form.placeholders.supporterType')
            "
            class="other-type-input"
          />
        </ElFormItem>
        <ElFormItem
          :label="$t('supporters.signup.form.fields.location')"
          prop="supporter.location"
        >
          <PxInputPlaces
            id="supporterLocation"
            v-model="data.supporter.location"
            :placeholder="$t('supporters.signup.form.fields.location')"
            :use-geolocation="true"
          />
        </ElFormItem>
        <ElFormItem
          :label="$t('supporters.signup.form.fields.website')"
          prop="supporter.website"
        >
          <ElInput
            v-model.trim="data.supporter.website"
            :placeholder="
              $t('authentication.signup.form.fields.websitePlaceholder')
            "
          >
            <template #prefix>
              <PxIcon :size="24" name="website" />
            </template>
          </ElInput>
        </ElFormItem>
        <div class="el-form__cta">
          <PxButton
            :disabled="stepDisabled"
            size="medium"
            type="primary"
            @click="goToNextStep"
          >
            {{ $t("supporters.signup.form.stepButton") }}
          </PxButton>
        </div>
      </ElForm>
    </PxPanel>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import PxInputPlaces from "@/components/px-input-places/px-input-places.vue";

import { ISupporterType } from "@/modules/supporters/services/data/supporter-type/supporter-type.interface";

import {
  allFormFieldsValid,
  generateRequiredValidator,
  generateSupporterTypesValidator,
  generateURLValidator,
} from "@/services/errors/validator-generators";
import { SupporterTypeState } from "@/modules/supporters/services/store/supporter-type/supporter-type.module";

export default defineComponent({
  name: "SignUpStepSupporterDetails",

  components: {
    PxInputPlaces,
  },

  props: {
    supporter: {
      type: Object as () => any,
      required: true,
    },
  },

  data() {
    return {
      stepDisabled: true,

      hasOtherType: false,
      selectedTypes: [] as Array<number>,

      data: {
        supporter: {
          name: "",
          website: "",
          location: null,
          types: [] as Array<number>,
          otherType: null,
        },
      },
      rules: {
        supporter: {
          name: generateRequiredValidator(
            this,
            "supporters.signup.form.fields.supporterName",
          ),
          location: generateRequiredValidator(
            this,
            "supporters.signup.form.fields.location",
          ),
          website: generateURLValidator(
            this,
            "supporters.signup.form.fields.website",
            true,
          ),
          types: generateSupporterTypesValidator(this, "fields.otherType"),
        },
      },
    };
  },

  computed: {
    signupFormRef(): HTMLElement {
      return this.$refs.signupForm as HTMLElement;
    },

    supporterTypesErrorMessage(): string {
      return this.$t("common.errors.requiredSet", {
        fieldName: this.$t("supporters.signup.form.fields.supporterType"),
      }) as string;
    },

    availableTypes(): Array<ISupporterType> {
      return this.$store.getters[SupporterTypeState.Getter.VALUES];
    },
  },

  watch: {
    selectedTypes: {
      deep: true,
      handler(currentSelectedTypes: Array<number>) {
        // Filter out invalid values
        this.data.supporter.types = currentSelectedTypes.filter(
          (type: number) => !!type,
        );
      },
    },
  },

  methods: {
    otherTypeChangeHandler(checked: boolean) {
      this.hasOtherType = checked;

      if (!checked) {
        this.data.supporter.otherType = null;
      }
    },

    updateSubmitState() {
      this.stepDisabled = !allFormFieldsValid(this.signupFormRef, this.rules);

      this.$emit("update:supporter", this.data.supporter);
    },

    goToNextStep() {
      this.$emit("next-step");
    },
  },
});
</script>

<style lang="scss" scoped>
.auth-signup__form :deep() {
  .el-checkbox-group {
    display: inline-flex;
    flex-flow: row wrap;
    justify-content: space-between;
    width: 100%;
    margin-top: 9px;
  }

  .el-checkbox {
    flex-basis: 43%;
    margin-left: 0;

    &:nth-child(n + 3) {
      min-width: 122px;
      margin-top: 10px;
    }
  }

  .el-checkbox__label {
    position: relative;
    top: -1px;
  }

  .other-type-input {
    margin-top: 10px;
  }
}
</style>
