import { resolveComponent as _resolveComponent, createVNode as _createVNode, mergeProps as _mergeProps, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-08d8533b"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "match-details-modal-header" }
const _hoisted_2 = { class: "match-details-modal-header__tip-wrapper" }
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = { key: 0 }
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MatchDetails = _resolveComponent("MatchDetails")!
  const _component_PxButton = _resolveComponent("PxButton")!
  const _component_PxIcon = _resolveComponent("PxIcon")!
  const _component_ElCollapseItem = _resolveComponent("ElCollapseItem")!
  const _component_ElCollapse = _resolveComponent("ElCollapse")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass([{
        'match-details-modal-header__tip-cta--active': _ctx.expandedCollapsedItem,
      }, "match-details-modal-header__tip-cta"])
    }, [
      _createVNode(_component_MatchDetails, {
        "company-logo": _ctx.companyLogo,
        "company-name": _ctx.companyName,
        "has-match": _ctx.hasMatch,
        percentage: _ctx.formattedMatchPercentage
      }, null, 8, ["company-logo", "company-name", "has-match", "percentage"]),
      _createVNode(_component_PxButton, _mergeProps(_ctx.improveMatchesButtonProps, { onClick: _ctx.toogleTipsCollapseItem }), null, 16, ["onClick"])
    ], 2),
    _createVNode(_component_ElCollapse, {
      modelValue: _ctx.expandedCollapsedItem,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.expandedCollapsedItem) = $event))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_ElCollapseItem, { name: "tips" }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.improveTips, (tip, index) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: index,
                  class: "match-details-modal-header__tips"
                }, [
                  _createElementVNode("div", null, [
                    _createVNode(_component_PxIcon, {
                      size: 14,
                      name: "information--light-gray"
                    }),
                    _createElementVNode("span", {
                      innerHTML: tip.title
                    }, null, 8, _hoisted_3)
                  ]),
                  (tip.steps)
                    ? (_openBlock(), _createElementBlock("ul", _hoisted_4, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(tip.steps, (step, stepIndex) => {
                          return (_openBlock(), _createElementBlock("li", { key: stepIndex }, [
                            _createElementVNode("p", { innerHTML: step }, null, 8, _hoisted_5)
                          ]))
                        }), 128))
                      ]))
                    : _createCommentVNode("", true)
                ]))
              }), 128)),
              _createElementVNode("p", { innerHTML: _ctx.improveTipsNote }, null, 8, _hoisted_6)
            ])
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["modelValue"])
  ]))
}