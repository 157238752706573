<template>
  <div class="list-reports">
    <header v-if="isFreeUser || !!userLists.length">
      <div class="list-reports__select">
        <p>{{ $t(`chartsDashboard.select[0]`) }}</p>
        <ElSelect
          v-model="selectedListUid"
          filterable
          default-first-option
          popper-class="list-reports__select-list"
          :disabled="selectDisabled"
        >
          <ElOption
            v-for="option in userLists"
            :key="option.uid"
            :value="option.uid"
            :label="option.title"
          />
        </ElSelect>
      </div>
    </header>
    <div
      v-loading="loading"
      element-loading-background="#fafafa"
      class="list-reports__content"
      :class="{
        'list-reports__content--empty': !userLists.length && !isFreeUser,
        'list-reports__loading': loading,
      }"
    >
      <template v-if="!loading">
        <CompanyListsEmpty
          v-if="!userLists.length && !isFreeUser"
          :title="$t('chartsDashboard.emptyLists.title')"
          :subtitle="$t('chartsDashboard.emptyLists.subtitle')"
          icon="lists-empty-state"
          :can-create-lists="true"
          :custom-action="true"
          data-testid="company-lists-empty-state"
          @new-list-click="goToListsPage"
        />
        <template v-if="isFreeUser || !!userLists.length">
          <DemographicCharts :theme="theme" :data="data" />
        </template>
      </template>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import DemographicCharts from "@/modules/charts-dashboard/components/demographic-charts/demographic-charts.vue";
import CompanyListsEmpty from "@/modules/company-lists/components/company-lists/company-lists-empty.vue";

import { IReportsViewThemes } from "@/modules/charts-dashboard/views/charts-dashboard.view.vue";
import { IListReportsDemographicData } from "../services/data/lists-reports-data/lists-reports-data.interface";
import { CompanyListState } from "@/modules/company-lists/services/store/company-list/company-list.modules";
import { ICompanyList } from "@/modules/company-lists/services/data/company-list/company-list.interface";
import { ROUTE_COMPANY_LISTS_DIRECTORY } from "@/modules/company-lists/services/router/routes-names";
import { GENDER_MOCK_DATA } from "./supporter-free-chart-mock-data";
import { listsReportsDataProvider } from "../services/data/lists-reports-data/lists-reports-data.provider";

export default defineComponent({
  name: "ListReportsView",

  components: {
    DemographicCharts,
    CompanyListsEmpty,
  },

  props: {
    theme: {
      type: Object as () => IReportsViewThemes,
      required: true,
    },
  },

  data() {
    return {
      data: {} as IListReportsDemographicData,
      loading: false,
      selectedListUid: "" as string,
    };
  },

  computed: {
    userLists(): Array<{ title: string; uid: string }> {
      return (
        this.$store
          .get(CompanyListState.Getter.VALUES)
          .map((list: ICompanyList) => {
            return {
              title: list.title,
              uid: list.uid,
            };
          }) || []
      );
    },

    questionSlug(): string {
      return this.theme?.questionSlug || "";
    },

    isFreeUser(): boolean {
      return !this.$user.isProUser();
    },

    selectDisabled(): boolean {
      return this.isFreeUser || this.userLists.length <= 1 || this.loading;
    },
  },

  watch: {
    selectedListUid: {
      async handler() {
        await this.fetchDemographicData();
      },
    },

    theme: {
      async handler(newValue, oldValue) {
        if (newValue === oldValue) {
          return;
        }

        await this.fetchDemographicData();
      },
    },

    loading(newValue, oldValue) {
      if (newValue === oldValue) return;

      this.$emit("loading", newValue);
    },
  },

  async created() {
    // If it's free user we show mock data
    if (this.isFreeUser) {
      this.data = GENDER_MOCK_DATA;
      return;
    }

    this.loading = true;

    await this.setupViewInitialData();
  },

  methods: {
    async setupViewInitialData() {
      if (!this.userLists.length) {
        await this.fetchLists();
      }

      if (this.userLists.length) {
        this.selectedListUid = this.userLists[0].uid;
        return;
      }

      this.$emit("show-navigation", false);
      this.loading = false;
    },

    async fetchDemographicData() {
      if (!this.selectedListUid) return;

      this.loading = true;

      this.data = await listsReportsDataProvider.fetch({
        list_uid: this.selectedListUid,
        question: this.questionSlug,
      });

      this.loading = false;
    },

    async fetchLists() {
      await this.$store.dispatch(CompanyListState.Action.GET_VALUES);
    },

    goToListsPage() {
      this.$router.push({
        name: ROUTE_COMPANY_LISTS_DIRECTORY,
      });
    },
  },
});
</script>

<style lang="scss" scoped>
.list-reports {
  header {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    margin-bottom: 40px;

    @include breakpoint-down(lg) {
      flex-direction: column;
      align-items: flex-start;
      gap: 20px;
    }
  }

  &__loading {
    min-height: 600px;
  }

  &__select {
    display: flex;
    align-items: center;
    gap: 16px;

    p {
      margin: 1px 0 0;

      text-transform: uppercase;
      font-size: 12px;
      font-weight: 600;
      line-height: 14px;
      letter-spacing: -0.2px;
    }

    :deep(.el-select) {
      width: 310px;
      top: 1px;
    }

    :deep(.el-select__placeholder span) {
      color: $manatee;
    }

    :deep(.el-select__wrapper) {
      background-image: linear-gradient(to bottom, $white, #f6f6f6);

      font-size: 14px;
      font-weight: 400;
      line-height: 20px;

      text-overflow: ellipsis;
      letter-spacing: -0.4px;
      padding: 4px 16px 4px 15px;
    }
  }

  &__content--empty {
    display: flex;
    justify-content: center;
    align-items: center;

    .company-lists-empty {
      margin-top: 0;
    }
  }
}
</style>

<style lang="scss">
.list-reports__select-list {
  max-width: 310px;

  .el-select-dropdown__item.is-selected span {
    font-weight: 600;
  }
}
</style>
