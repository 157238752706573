import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-49517266"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "milestones-full-grid" }
const _hoisted_2 = { class: "milestones-full-grid__levels" }
const _hoisted_3 = { class: "milestones-full-grid__level-title" }
const _hoisted_4 = { class: "milestones-full-grid__levels-progress-bar" }
const _hoisted_5 = { class: "milestones-full-grid__grid" }
const _hoisted_6 = { class: "milestones-full-grid__category-name" }
const _hoisted_7 = { class: "milestones-full-grid__category-milestones" }
const _hoisted_8 = { class: "milestones-full-grid__category milestones-full-grid__category--typical-funding" }
const _hoisted_9 = { class: "milestones-full-grid__category-name" }
const _hoisted_10 = { class: "milestones-full-grid__category-milestones" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PxIcon = _resolveComponent("PxIcon")!
  const _component_MilestonesFullGridCell = _resolveComponent("MilestonesFullGridCell")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.invertedSortLevels, (level) => {
        return (_openBlock(), _createElementBlock("div", {
          key: level.id,
          class: "milestones-full-grid__level"
        }, [
          _createElementVNode("div", _hoisted_3, _toDisplayString(level.title), 1),
          _createVNode(_component_PxIcon, {
            name: `level-graph/${level.value}`,
            size: 42,
            class: "milestones-full-grid__level-icon"
          }, null, 8, ["name"])
        ]))
      }), 128)),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", {
          style: _normalizeStyle({
            height: `${_ctx.currentLevelLinePosition}px`,
          }),
          class: "milestones-full-grid__levels-progress-bar-fill"
        }, null, 4)
      ])
    ]),
    _createElementVNode("div", _hoisted_5, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.categories, (category, index) => {
        return (_openBlock(), _createElementBlock("div", {
          key: category.name,
          class: "milestones-full-grid__category"
        }, [
          _createElementVNode("div", _hoisted_6, _toDisplayString(category.name), 1),
          _createElementVNode("div", _hoisted_7, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getSortedMilestones(category.milestones), (milestone) => {
              return (_openBlock(), _createBlock(_component_MilestonesFullGridCell, {
                key: milestone.level,
                color: category.color,
                completed: milestone.completed,
                critical: milestone.critical,
                description: milestone.description,
                "evidence-provided": milestone.evidenceProvided,
                future: milestone.future,
                "in-progress": milestone.inProgress,
                planned: milestone.planned,
                class: "milestones-full-grid__milestone"
              }, null, 8, ["color", "completed", "critical", "description", "evidence-provided", "future", "in-progress", "planned"]))
            }), 128))
          ])
        ]))
      }), 128)),
      _createElementVNode("div", _hoisted_8, [
        _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.$t("milestonePlanner.milestoneGrid.typicalFunding")), 1),
        _createElementVNode("div", _hoisted_10, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.invertedSortLevels, (level) => {
            return (_openBlock(), _createElementBlock("div", {
              key: level.id,
              class: "milestones-full-grid__milestone milestones-full-grid__typical-funding"
            }, [
              _createElementVNode("span", null, _toDisplayString(level.typical_funding), 1)
            ]))
          }), 128))
        ])
      ]),
      _createElementVNode("div", {
        style: _normalizeStyle({
          transform: `translateY(${-_ctx.currentLevelLinePosition}px)`,
        }),
        class: "milestones-full-grid__current-level-line"
      }, null, 4)
    ])
  ]))
}