<template>
  <div class="px-page-header">
    <div class="px-page-header__wrapper">
      <PxButton
        v-if="showBackButton"
        class="px-page-header__back-button"
        v-bind="$options.static.backButton"
        @click="$emit('back-button-clicked')"
      />
      <div class="px-page-header__content">
        <h1>
          {{ title }}
        </h1>
        <p v-if="subtitle">
          {{ subtitle }}
        </p>
      </div>
    </div>
    <slot class="px-page-header__action" name="action" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import {
  EPxButtonSize,
  EPxButtonVariant,
} from "@/components/px-button/px-button.types";

export default defineComponent({
  name: "PxPageHeader",

  props: {
    showBackButton: {
      type: Boolean,
      default: true,
    },

    title: {
      type: String,
      required: true,
    },

    subtitle: {
      type: String,
      default: null,
    },
  },

  static: {
    backButton: {
      icon: "arrow--left-dark",
      size: EPxButtonSize.SMALL,
      variant: EPxButtonVariant.COMPACT,
    },
  },
});
</script>

<style lang="scss" scoped>
.px-page-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 36px 0 32px;
  max-width: 1236px;
  margin: 0 auto;

  @include breakpoint-down(md) {
    flex-direction: column;
    align-items: flex-start;
    gap: 40px;
  }

  &__back-button {
    margin-top: 6px;
    border-radius: 50%;
  }

  &__wrapper {
    display: flex;
    gap: 40px;
  }

  &__content {
    h1 {
      margin-bottom: 6px;

      font-size: 30px;
      line-height: 40px;
      letter-spacing: -0.3px;
    }

    p {
      font-size: 17px;
      letter-spacing: -0.4px;
    }
  }
}
</style>
