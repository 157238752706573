<template>
  <div class="share-modal-settings-passcode">
    <ElForm ref="form" class="share-modal-settings-passcode__form">
      <PxSwitch
        v-model:value="isRestrictedByPasscode"
        :label="passcodeCopy.label"
        data-testid="share-modal-settings-passcode__switch"
        @change="changeRestrictionHandler"
      >
        <template #label>
          <ElTooltip
            :enterable="false"
            placement="top"
            popper-class="share-modal-settings-passcode__popper el-abaca-tooltip"
          >
            <template #content>
              <div v-text="passcodeCopy.tooltip" />
            </template>
            <template #default>
              <PxIcon
                :size="17"
                class="share-modal-settings-passcode__tooltip"
                name="question"
              />
            </template>
          </ElTooltip>
        </template>
      </PxSwitch>
      <div
        v-if="isRestrictedByPasscode"
        class="share-modal-settings-passcode__toggle-wrapper"
      >
        <ElFormItem
          class="share-modal-settings-passcode__input-wrapper"
          prop="email"
        >
          <PxInputPassword
            v-model="innerPasscode"
            class="share-modal-settings-passcode__input"
            disabled
          />
          <ClipboardButton v-bind="clipboardButton" />
        </ElFormItem>
        <div class="share-modal-settings-passcode__footer">
          <span
            class="el-form-item__bottom-text el-form-item__tip"
            v-html="passcodeCopy.tip"
          />
          <PxButton
            class="share-modal-settings-passcode__cta"
            v-bind="resetButtonProps"
            @click="clickResetPasscodeHandler"
          />
        </div>
      </div>
    </ElForm>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import ClipboardButton from "@/components/clipboard-button/clipboard-button.vue";

import { generateRandomString } from "@/utils/generate-random-string";

import {
  EPxButtonSize,
  EPxButtonType,
} from "@/components/px-button/px-button.types";
import { SHARE_MODAL_EVENTS } from "@/components/share-modal/share-modal.types";

export default defineComponent({
  name: "ShareModalSettingsPasscode",

  components: {
    ClipboardButton,
  },

  props: {
    passcode: {
      type: String,
      required: true,
    },

    needsReset: {
      type: Boolean,
      default: false,
    },

    copy: {
      type: String,
      default: "milestonePlanner.shareModal.passcode",
    },
  },

  data() {
    return {
      innerPasscode: "",
      isRestrictedByPasscode: false,
    };
  },

  static: {
    resetButton: {
      icon: "password-lock--red",
      size: EPxButtonSize.SMALL,
      type: EPxButtonType.GHOST_RED,
    },
  },

  computed: {
    resetButtonProps(): any {
      return {
        ...this.$options.static.resetButton,
        label: this.passcodeCopy.resetButton,
      };
    },

    passcodeCopy() {
      return this.$tm(this.copy) as {
        label: string;
        tooltip: string;
        tip: string;
        resetButton: string;
      };
    },

    hasPasscode(): boolean {
      return !!this.passcode.length;
    },

    clipboardButton() {
      return {
        clipboard: this.passcode,
        buttonLabel: this.$t("common.copyPasscode"),
        tooltipCopy: this.$t("common.copiedToClipboard"),
        buttonIcon: "password-lock",
      };
    },
  },

  watch: {
    passcode(newPasscode: string) {
      if (this.innerPasscode !== newPasscode) {
        this.innerPasscode = newPasscode;
      }
    },

    innerPasscode(newPasscode: string) {
      if (this.passcode !== newPasscode) {
        this.$emit("update:passcode", newPasscode);
      }
    },

    needsReset(needsReset: boolean) {
      if (needsReset) {
        this.isRestrictedByPasscode = this.hasPasscode;
        this.$emit("update:needsReset", !needsReset);
      }
    },
  },

  created() {
    this.isRestrictedByPasscode = this.hasPasscode;

    if (this.hasPasscode) {
      this.innerPasscode = this.passcode;
    }
  },

  methods: {
    changeRestrictionHandler(isRestricted: boolean) {
      // If non-existing passcode, generate a new one:
      if (isRestricted && !this.hasPasscode && !this.innerPasscode) {
        this.innerPasscode = generateRandomString();
      }

      this.$emit("change", isRestricted, this.innerPasscode);
    },

    clickResetPasscodeHandler() {
      this.$emitter.emit("share-modal-action", {
        event: SHARE_MODAL_EVENTS.RESET_PASSCODE,
      });
    },
  },
});
</script>

<style lang="scss" scoped>
.share-modal-settings-passcode {
  padding: 14px 19px 15px 19px;
  border: solid 1px $athens-gray;
  border-radius: 4px;

  &__toggle-wrapper {
    margin-top: 33px;

    &[style="display:none"] {
      margin-top: 0;
    }
  }

  &__input-wrapper {
    position: relative;

    :deep(.el-form-item__label) {
      padding-top: 0;
    }

    :deep(.el-input--password:not(.el-input--password-visible) input) {
      font-size: 15px;
    }

    :deep(.el-input.is-disabled .el-input__inner) {
      // Add safe space for CTA overlapping input:
      padding-right: 130px;
      background-color: $alabaster;
      border: solid 1px $mischka;
      box-shadow: inset 0 0 5px 0 rgba(#000, 0.03);
      opacity: 1;
    }
  }

  &__input.el-input--password :deep(.el-input__suffix) {
    right: 412px;
    left: auto;
  }

  :deep(.clipboard-button) {
    position: absolute;
    right: 4px;
    bottom: 4px;
    padding: 0 12px;

    span {
      font-size: 12px;
    }

    .px-icon {
      margin-right: 6px;
    }
  }

  &__footer {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    margin-top: 16px;

    .px-button {
      padding: 1px 13px 0 12px;
      margin-right: 1px;
    }

    .px-button :deep(.px-icon) {
      position: relative;
      top: 1px;
    }

    .el-form-item__tip {
      flex: 1 1 50%;
      max-width: 270px;
      padding-top: 0;
      line-height: 14px;
      letter-spacing: -0.14px;
    }
  }

  &__tooltip {
    margin-left: 7px;
    vertical-align: -5px;
    transition: $--filter-transition-base;

    &:hover {
      filter: brightness(0.5) hue-rotate(380deg);
    }
  }

  :deep(.px-switch__label span) {
    letter-spacing: -0.33px;
  }
}
</style>

<style lang="scss">
.share-modal-settings-passcode__popper {
  max-width: 340px;
  padding: 10px 15px 10px;
  margin-top: 0;
  text-transform: none;
  letter-spacing: -0.2px;
}
</style>
