import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "list-options-delete-modal__title" }
const _hoisted_2 = ["textContent"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PxModalMessage = _resolveComponent("PxModalMessage")!
  const _component_PxButton = _resolveComponent("PxButton")!
  const _component_ElDialog = _resolveComponent("ElDialog")!

  return (_openBlock(), _createBlock(_component_ElDialog, {
    ref: "modal",
    modelValue: _ctx.innerVisibility,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.innerVisibility) = $event)),
    "append-to-body": true,
    "before-close": _ctx.beforeCloseHandler,
    class: "el-dialog--ghost-header list-options-delete-modal",
    width: "500px"
  }, {
    footer: _withCtx(() => [
      _createVNode(_component_PxButton, {
        class: "list-options-delete-modal__cancel-btn",
        size: "small",
        type: "link",
        onClick: _ctx.onClickCancelButton
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t("common.cancel")), 1)
        ]),
        _: 1
      }, 8, ["onClick"]),
      _createVNode(_component_PxButton, {
        loading: _ctx.isLoading,
        class: "list-options-delete-modal__submit-btn",
        type: "red",
        onClick: _ctx.onClickDeleteListButton
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.deleteModalCopy.button), 1)
        ]),
        _: 1
      }, 8, ["loading", "onClick"])
    ]),
    default: _withCtx(() => [
      _createVNode(_component_PxModalMessage, { visible: _ctx.hasServerError }, null, 8, ["visible"]),
      _createElementVNode("h2", _hoisted_1, _toDisplayString(_ctx.deleteModalCopy.title), 1),
      _createElementVNode("p", {
        class: "list-options-delete-modal__description",
        textContent: _toDisplayString(_ctx.deleteModalCopy.subtitle)
      }, null, 8, _hoisted_2)
    ]),
    _: 1
  }, 8, ["modelValue", "before-close"]))
}