<template>
  <div class="insights-card">
    <header>
      <h1>{{ $t(title) }}</h1>
    </header>
    <main>
      <div
        v-for="(insight, index) in insights"
        :key="index"
        class="insights-card__item"
      >
        <h3>
          <span>{{ insight.emoji }}</span>
          {{ insight.title }}
        </h3>
        <p>
          {{ insight.description }}
        </p>
      </div>
    </main>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export interface IInsightData {
  title: string;
  description: string;
  emoji: string;
}

export default defineComponent({
  name: "InsightsCard",

  props: {
    title: {
      type: String,
      default: "chartsDashboard.insightsCard.title",
    },

    insights: {
      type: Array as () => IInsightData[],
      required: true,
    },
  },
});
</script>

<style lang="scss" scoped>
.insights-card {
  padding: 29px 24px 23px 24px;
  border-radius: 8px;
  border-style: solid;
  border-width: 1px;
  border-image-source: linear-gradient(
    180deg,
    rgba(228, 230, 234, 0.2) 0%,
    rgba(228, 230, 234, 0) 100%
  );
  border-image-slice: 1;
  background: linear-gradient(
      180deg,
      rgba(228, 230, 234, 0.2) 0%,
      rgba(228, 230, 234, 0) 100%
    ),
    linear-gradient(
      180deg,
      rgba(228, 230, 234, 0.2) 0%,
      rgba(228, 230, 234, 0) 100%
    );
  background-origin: border-box;
  background-clip: border-box;

  h1 {
    font-size: to-rem(20px);
    font-weight: 600;
    color: $ebony-clay;
  }

  main {
    margin-top: 22px;
    padding-left: 2px;

    display: flex;
    flex-direction: column;
    gap: 13px;
  }

  &__item {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  h3,
  p,
  h1 {
    margin: 0;
  }

  h3,
  p {
    font-size: to-rem(15px);
    line-height: 1.45;
  }

  h3 {
    display: flex;
    align-items: flex-end;
    gap: 2px;
  }

  p {
    letter-spacing: -0.39px;
  }
}
</style>
