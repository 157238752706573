import { GetterTree } from "vuex";
import { RootState } from "@/services/store/root-state";
import { getBaseName } from "@/services/store/utils/get-base-name";

import {
  EMilestonePlannerPasscodeGetters,
  IMilestonePlannersPasscodeState,
} from "@/modules/milestone-planner/services/store/milestone-planner/sub-modules/passcode/passcode.interface";

export const getters: GetterTree<IMilestonePlannersPasscodeState, RootState> = {
  [getBaseName(EMilestonePlannerPasscodeGetters.GET)](state) {
    return (milestonePlannerUid: string) =>
      state.passcodes[milestonePlannerUid] || null;
  },
};
