<template>
  <div v-if="level" class="overall-level">
    <ElCollapse accordion>
      <ElCollapseItem>
        <template #title>
          <div class="overall-level__wrapper">
            <PxIcon :name="`level-graph/${level.value}`" :size="100" />
            <div>
              <h2 class="overall-level__title">
                {{ $t("milestonePlanner.sidebar.level.title") }}
              </h2>
              <div class="overall-level__description">
                <h3>
                  {{ $t("profile.assessment.levelPanel.label") }}
                  {{ level.value }}
                  <PxButton
                    v-if="$user.isOwner()"
                    class="overall-level__quick-update-button"
                    v-bind="quickUpdateButtonProps"
                    @click="onQuickUpdateClick"
                  />
                </h3>
                <p>
                  {{ level.title }}
                </p>
              </div>
            </div>
          </div>
        </template>
        <div v-html="level.description" />
      </ElCollapseItem>
    </ElCollapse>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { ILevel } from "@/services/data/level/level.interface";
import {
  EPxButtonSize,
  EPxButtonVariant,
} from "@/components/px-button/px-button.types";
import { EViralLevelActions } from "@/services/store/viral-level/viral-level-types";
import { ROUTE_UPDATE_LEVEL } from "@/modules/profile/services/router/routes-names";

export default defineComponent({
  name: "OverallLevel",

  static: {
    quickUpdateButton: {
      size: EPxButtonSize.XXX_SMALL,
      variant: EPxButtonVariant.FLUID,
    },
  },

  props: {
    level: {
      type: Object as () => ILevel,
      required: true,
    },
  },

  computed: {
    quickUpdateButtonProps(): any {
      return {
        ...this.$options.static.quickUpdateButton,
        label: this.$t("milestonePlanner.quickUpdate"),
      };
    },
  },

  methods: {
    onQuickUpdateClick() {
      this.$store.dispatch(EViralLevelActions.RESET);

      this.$router.push({
        name: ROUTE_UPDATE_LEVEL,
      });
    },
  },
});
</script>

<style lang="scss" scoped>
.overall-level {
  padding-bottom: 11px;

  :deep() .el-collapse-item__header {
    padding: 9px 52px 7px 20px;
  }

  :deep() .el-collapse-item__header::before {
    top: auto;
    right: 16px;
    bottom: 14px;
  }

  :deep() .el-collapse-item__content {
    padding: 0 24px 11px;
    font-size: 14px;
    line-height: 20px;
    color: $manatee;
  }

  &__wrapper {
    display: flex;
    gap: 17px;
    align-items: flex-start;
  }

  &__title {
    margin-top: 16px;
    margin-bottom: 6px;
    font-size: 12px;
    line-height: 12px;
    color: rgba($manatee, 0.5);
    text-transform: uppercase;
    letter-spacing: 0.3px;
    text-align: left;
  }

  &__description {
    h3 {
      @include grotesk(semiBold);

      display: flex;
      align-items: center;
      font-size: 20px;
      line-height: 30px;
      color: $ebony-clay;
    }

    p {
      max-width: 200px;
      margin: 4px 0 0;
      font-size: 15px;
      font-style: italic;
      line-height: 25px;
      color: $manatee;
      text-align: left;
    }
  }

  &__quick-update-button {
    margin-left: 12px;
  }
}
</style>
