import { userManager } from "@/modules/authentication/user-manager";
import { INavBarItem } from "@/modules/profile/meta/entrepreneurs";
import { featureManager } from "@/services/feature-manager";
import { activeModules } from "@/services/utils/utils";

export const navBarItemsByFeaturesToggleOrPermissions = (
  navBarItems: Array<INavBarItem>,
) => {
  // Filter menu items based on the currently active modules and features.
  const filteredNavBarItems = navBarItems.filter((menuItem: INavBarItem) => {
    if (!menuItem.subMenuItems?.length) {
      // If menu item has not sub-menu items:
      // 1. If doesn't have required modules and features, display it OR
      // 2. If has required modules and features, all of them should be active/enabled to display the menu item.
      return (
        (!menuItem?.requiredModules ||
          menuItem.requiredModules.every((module: string) =>
            activeModules().includes(module),
          )) &&
        (!menuItem?.requiredFeatures ||
          menuItem.requiredFeatures.every((feature: string) =>
            featureManager.isEnabled(feature),
          ))
      );
    } else {
      // If menu item has sub-menu items:
      // 1. If doesn't have required modules and features, display it OR
      // 2. If has required modules and features, at least one of them should be active/enabled to display the menu item.
      return (
        (!menuItem?.requiredModules ||
          menuItem.requiredModules.some((module: string) =>
            activeModules().includes(module),
          )) &&
        (!menuItem?.requiredFeatures ||
          menuItem.requiredFeatures.some((feature: string) =>
            featureManager.isEnabled(feature),
          ))
      );
    }
  });

  // Filter sub-menu items based on the currently active modules and features.
  filteredNavBarItems.map((menuItem: INavBarItem) => {
    menuItem.subMenuItems = menuItem.subMenuItems?.filter(
      (subMenuItem: INavBarItem) =>
        (!subMenuItem?.requiredModules ||
          subMenuItem.requiredModules.every((module: string) =>
            activeModules().includes(module),
          )) &&
        (!subMenuItem?.requiredFeatures ||
          subMenuItem.requiredFeatures.every((feature: string) =>
            featureManager.isEnabled(feature),
          )),
    );
  });

  // Filter navbar items based on the user subscription plan
  if (userManager.isProUser()) {
    return filteredNavBarItems.filter(
      (menuItem: INavBarItem) => menuItem.proUser !== false,
    );
  } else {
    return filteredNavBarItems.filter(
      (menuItem: INavBarItem) => !menuItem.proUser,
    );
  }
};
