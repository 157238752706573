<template>
  <div :class="{ 'px-tab-bar--small': isSmall }" class="px-tab-bar">
    <div
      :class="{ 'px-tab-bar__navigation': items && items.length > 0 }"
      class="px-tab-bar__container"
    >
      <RouterLink :to="{ name: 'home' }" class="px-tab-bar-logo__wrapper">
        <img
          alt="VIRAL Logo"
          class="px-tab-bar-logo"
          height="24"
          src="/img/logos/abaca-logo--white.svg"
        />
      </RouterLink>
      <slot />
      <div v-if="items && items.length > 0" class="px-tab-bar-menu__wrapper">
        <PxMenu
          v-model="innerValue"
          :items="items"
          :use-router="false"
          class="px-tab-bar-menu"
          mode="even"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import PxMenu from "@/components/px-menu/px-menu.vue";

// Distance that the user needs to scroll to collapse the header
const COLLAPSE_DISTANCE = 47;

// Store the last computed scroll position. This will be used to
// decide if the menu should be collapsed of fully presented.
let scrollState = 0;
let scrollDelta = 0;
let isGoingDown = true;

// Distance in milliseconds that a scroll event must occur
const DEBOUNCE_TIME = 300;

// Informs it's currently waiting for a scroll event to be called
let isWaitingCall = false;

export default defineComponent({
  name: "PxTabBar",

  components: {
    PxMenu,
  },

  props: {
    /**
     * List of items to add to the navbar.
     *
     * Example:
     * ```
     * [
     *  {
     *    key: "about",
     *    label: "About",
     *    to: HOME,
     *  }
     * ]
     * ```
     */
    items: {
      type: Array as () => any[],
      default: () => [],
    },

    /**
     * Current selected option.
     */
    modelValue: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      isSmall: false,
      innerValue: "",
    };
  },

  watch: {
    modelValue: {
      immediate: true,
      handler(newVal) {
        this.innerValue = newVal;
      },
    },

    innerValue(newVal, oldVal) {
      if (newVal === oldVal) {
        return;
      }

      this.changeTabSize(false);
      this.$emit("update:modelValue", newVal);
    },
  },

  mounted() {
    document.addEventListener("scroll", this.onScroll.bind(this), {
      capture: true,
      passive: true,
    });
  },

  updated() {
    // Launch resize to reset tab size
    this.handleTabResize();
  },

  beforeUnmount() {
    document.removeEventListener("scroll", this.onScroll.bind(this));
  },

  methods: {
    /**
     * Determines if the tolerance has been exceeded.
     */
    toleranceExceeded(): boolean {
      return Math.abs(scrollDelta) >= COLLAPSE_DISTANCE;
    },

    /**
     * Updates scroll delta value based on inverted scroll direction
     */
    updateScrollDelta(immediateDelta: number) {
      // Reset scroll delta when the user changes the scroll direction
      return (isGoingDown && scrollDelta < 0) ||
        (!isGoingDown && scrollDelta > 0)
        ? -scrollDelta
        : immediateDelta;
    },

    changeTabSize(isSmall: boolean) {
      const thisElem = this as any;
      thisElem.isSmall = isSmall;
      this.$emitter.emit("tab-bar-small", isSmall);
    },

    handleTabResize() {
      const thisElem = this as any;
      const currentScroll = window.pageYOffset;

      // Calculate scroll direction and scroll delta since last direction change
      const immediateDelta = scrollState - Math.abs(currentScroll);
      isGoingDown = immediateDelta > 0;
      scrollDelta += thisElem.updateScrollDelta(immediateDelta);

      // Save current scroll position and the scroll direction
      scrollState = currentScroll;

      // If the scroll is near the top the tab bar must expand
      if (currentScroll <= COLLAPSE_DISTANCE) {
        thisElem.changeTabSize(false);
        return;
      }

      const isToleranceExceeded = thisElem.toleranceExceeded();
      const isSmall = thisElem.isSmall;

      if (!(isToleranceExceeded && isSmall === isGoingDown)) {
        return;
      }

      thisElem.changeTabSize(!isGoingDown);
    },

    /**
     * Handle browser scroll event call.
     *
     * Use a throttle strategy to minimize the performance costs.
     */
    onScroll() {
      if (isWaitingCall) {
        return;
      }

      isWaitingCall = true;
      setTimeout(() => {
        const thisElem = this as any;
        thisElem.handleTabResize();
        isWaitingCall = false;
      }, DEBOUNCE_TIME);
    },
  },
});
</script>

<style lang="scss">
.px-tab-bar {
  @include gradient("blue-gradient-1", 293deg);

  position: sticky;
  top: 0;
  z-index: z("navigation");
  width: 100vw;

  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.19);

  transition: transform 0.2s ease-in-out;

  &--small {
    transform: translateY(-43px);
  }

  &__container {
    height: 50px;
  }

  &__navigation {
    height: 94px;
  }
}

.px-tab-bar-logo {
  height: 55px;
  padding: 11px 0 15px;

  &__wrapper {
    position: relative;
    bottom: 3px;
    left: 50%;
    display: block;
    width: 84px;
    transform: translateX(-50%);
  }
}

.px-tab-bar-logo__wrapper:only-child .px-tab-bar-logo {
  padding-bottom: 10px;
}
</style>
