import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-dfc82f4e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "matching-placeholder__container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ContentPlaceholdersText = _resolveComponent("ContentPlaceholdersText")!
  const _component_ContentPlaceholders = _resolveComponent("ContentPlaceholders")!
  const _component_ContentPlaceholdersHeading = _resolveComponent("ContentPlaceholdersHeading")!
  const _component_MatchingCardWrapper = _resolveComponent("MatchingCardWrapper")!

  return (_openBlock(), _createBlock(_component_MatchingCardWrapper, { class: "matching-placeholder__wrapper" }, {
    header: _withCtx(() => [
      _createVNode(_component_ContentPlaceholders, { rounded: true }, {
        default: _withCtx(() => [
          _createVNode(_component_ContentPlaceholdersText, {
            lines: 1,
            class: "is-pill"
          })
        ]),
        _: 1
      })
    ]),
    body: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_ContentPlaceholders, { rounded: true }, {
          default: _withCtx(() => [
            _createVNode(_component_ContentPlaceholdersHeading, { img: true })
          ]),
          _: 1
        }),
        _createVNode(_component_ContentPlaceholders, { rounded: true }, {
          default: _withCtx(() => [
            _createVNode(_component_ContentPlaceholdersText, {
              lines: 1,
              class: "matching-placeholder__description"
            })
          ]),
          _: 1
        })
      ])
    ]),
    _: 1
  }))
}