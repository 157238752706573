<template>
  <div class="match-details-modal-footer">
    <PxButton
      v-bind="backButtonProps"
      ref="backButton"
      class="match-details-modal-footer__back-button"
      @click="onBackClick"
    />
    <PxButton
      v-bind="editAnswersButtonProps"
      ref="editButton"
      class="match-details-modal-footer__edit-button"
      @click="onEditAnswersClick"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import {
  EPxButtonSize,
  EPxButtonType,
} from "@/components/px-button/px-button.types";
import { AFFILIATE_SUPPORTER_PROGRAM_UPDATE_URL } from "@/modules/affiliates/constants";

export default defineComponent({
  name: "MatchDetailsModalFooter",

  static: {
    editAnswersButton: {
      size: EPxButtonSize.SMALL,
    },

    backButton: {
      type: EPxButtonType.LINK,
    },
  },

  computed: {
    editAnswersButtonProps(): any {
      return {
        ...this.$options.static.editAnswersButton,
        label: this.$t("matching.matchDetails.buttons.editAnswers"),
      };
    },

    backButtonProps(): any {
      return {
        ...this.$options.static.backButton,
        label: this.$t("common.back"),
      };
    },
  },

  methods: {
    onBackClick() {
      this.$emit("close-modal");
    },

    onEditAnswersClick() {
      window.open(AFFILIATE_SUPPORTER_PROGRAM_UPDATE_URL);
    },
  },
});
</script>

<style lang="scss" scoped>
.match-details-modal-footer {
  padding: 16px 0 13px;
  display: flex;
  justify-content: center;

  &__back-button {
    position: relative;
    right: 2px;
    bottom: 1px;

    :deep() span {
      font-size: 14px;
    }
  }

  &__edit-button {
    padding: 0 15px;
    position: relative;
    left: 1px;

    :deep() span {
      font-size: 14px;
    }
  }
}
</style>
