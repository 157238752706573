import { getBaseName } from "../utils/get-base-name";
import { EMetaMutations, IMetaPayload, IMetaState } from "./meta-types";
import { MutationTree } from "vuex";

/**
 * Mutations for the metadata module.
 */
export const mutations: MutationTree<IMetaState> = {
  /**
   * Add a new metadata entry to the store.
   *
   * @param state metadata state
   * @param payload new metadata entry to be added
   */
  [getBaseName(EMetaMutations.SET)](state, payload: IMetaPayload) {
    state.data = {
      ...state.data,
      [payload.key]: payload.value,
    };
  },

  /**
   * Remove a metadata entry.
   *
   * @param state metadata state
   * @param keyToRemove key to be removed
   */
  [getBaseName(EMetaMutations.REMOVE)](state, keyToRemove: string) {
    delete state.data[keyToRemove];
    state.data = { ...state.data };
  },
};
