import { IBaseState } from "@/services/store/store-types";
import { IMatchingInterest } from "@/modules/matching/services/data/matching-interest/matching-interest.interface";
import { IMatchingScore } from "@/modules/matching/services/data/matching-score/matching-score.interface";

/**
 * Represents the state structure for the matching interest module.
 */
export interface IMatchingInterestState extends IBaseState {
  /**
   * Array with all matching interests.
   */
  data: Array<IMatchingInterest>;

  /**
   * Array with user interest scores.
   */
  userMatchScores: Array<IMatchingScore>;
  userMatchScoresPage: number | null;
  userMatchScoresCount: number;

  /**
   * Array with target interest scores.
   */
  targetMatchScores: Array<IMatchingScore>;
  targetMatchScoresPage: number | null;
  targetMatchScoresCount: number;

  /**
   * Array with mutual scores.
   */
  mutualMatchScores: Array<IMatchingScore>;
  mutualMatchScoresPage: number | null;
  mutualMatchScoresCount: number;

  /**
   * Informs if a match can be made
   */
  canMatch: boolean;
}

export enum EMatchingInterestActions {
  FETCH = "matchingInterest/fetch",
  FETCH_INTERESTS = "matchingInterest/fetchInterests",
  FETCH_ALL = "matchingInterest/fetchAll",
  FETCH_USER_SCORES = "matchingInterest/fetchUserScores",
  FETCH_TARGET_SCORES = "matchingInterest/fetchTargetScores",
  FETCH_MUTUAL_SCORES = "matchingInterest/fetchMutualScores",
  REGISTER = "matchingInterest/register",
  SET_CAN_MATCH = "matchingInterest/setCanMatch",
  APPEND = "matchingInterest/set",
}

export enum EMatchingInterestMutations {
  SET_LOADING = "SET_LOADING",
  SET_DATA = "SET_DATA",
  SET_USER_MATCH_SCORES = "SET_USER_MATCH_SCORES",
  SET_USER_MATCH_SCORES_PAGE = "SET_USER_MATCH_SCORES_PAGE",
  SET_USER_MATCH_SCORES_COUNT = "SET_USER_MATCH_SCORES_COUNT",
  SET_TARGET_MATCH_SCORES = "SET_TARGET_MATCH_SCORES",
  SET_TARGET_MATCH_SCORES_PAGE = "SET_TARGET_MATCH_SCORES_PAGE",
  SET_TARGET_MATCH_SCORES_COUNT = "SET_TARGET_MATCH_SCORES_COUNT",
  SET_MUTUAL_MATCH_SCORES = "SET_MUTUAL_MATCH_SCORES",
  SET_MUTUAL_MATCH_SCORES_PAGE = "SET_MUTUAL_MATCH_SCORES_PAGE",
  SET_MUTUAL_MATCH_SCORES_COUNT = "SET_MUTUAL_MATCH_SCORES_COUNT",
  SET_ERROR = "SET_ERROR",
  SET_CAN_MATCH = "SET_CAN_MATCH",
}

export interface IMatchingInterestPayload {
  supporter: number;
  entrepreneur: number;
  supporter_is_interested?: number;
  entrepreneur_is_interested?: number;
}
