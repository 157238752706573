import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, createSlots as _createSlots, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-531546f0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "panel-grid__block" }
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_OfferingsEditModal = _resolveComponent("OfferingsEditModal")!
  const _component_ContentPlaceholdersText = _resolveComponent("ContentPlaceholdersText")!
  const _component_ContentPlaceholders = _resolveComponent("ContentPlaceholders")!
  const _component_SupporterOfferings = _resolveComponent("SupporterOfferings")!
  const _component_PxButton = _resolveComponent("PxButton")!
  const _component_PanelEmptyState = _resolveComponent("PanelEmptyState")!
  const _component_PxPanelSection = _resolveComponent("PxPanelSection")!
  const _component_PxPanel = _resolveComponent("PxPanel")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.$user.isOwner())
      ? (_openBlock(), _createBlock(_component_OfferingsEditModal, {
          key: 0,
          ref: "offeringsEditModal",
          visibility: _ctx.isOfferingsEditModalVisible,
          "onUpdate:visibility": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.isOfferingsEditModalVisible) = $event))
        }, null, 8, ["visibility"]))
      : _createCommentVNode("", true),
    (!_ctx.$screen.mdUp)
      ? (_openBlock(), _createElementBlock("h2", {
          key: 1,
          class: "px-panel__title",
          innerHTML: _ctx.$t('profile.supporter.title')
        }, null, 8, _hoisted_2))
      : _createCommentVNode("", true),
    _createVNode(_component_PxPanel, {
      class: "profile-panel profile-panel--supporter-info",
      theme: "with-sections"
    }, _createSlots({
      default: _withCtx(() => [
        (_ctx.$screen.mdUp)
          ? (_openBlock(), _createBlock(_component_PxPanelSection, {
              key: 0,
              "icon-size": 24,
              title: _ctx.$t('profile.supporter.offerings.title'),
              icon: "briefcase"
            }, _createSlots({
              default: _withCtx(() => [
                (_ctx.isSupporterLoading)
                  ? (_openBlock(), _createBlock(_component_ContentPlaceholders, {
                      key: 0,
                      rounded: true
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ContentPlaceholdersText, { lines: 2 })
                      ]),
                      _: 1
                    }))
                  : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                      (_ctx.hasUserAccess && _ctx.hasSupporterOfferings)
                        ? (_openBlock(), _createBlock(_component_SupporterOfferings, {
                            key: 0,
                            offerings: _ctx.supporterOfferings
                          }, null, 8, ["offerings"]))
                        : (_openBlock(), _createBlock(_component_PanelEmptyState, {
                            key: 1,
                            icon: "offerings"
                          }, {
                            default: _withCtx(() => [
                              _createElementVNode("p", null, _toDisplayString(_ctx.$t(
                    `profile.supporter.offerings.emptyState.description[${+_ctx.$user.isOwner()}]`,
                  )), 1),
                              (_ctx.$user.isOwner())
                                ? (_openBlock(), _createBlock(_component_PxButton, {
                                    key: 0,
                                    size: "medium",
                                    type: "primary",
                                    onClick: _ctx.onClickEditOfferings
                                  }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(_ctx.$t("profile.supporter.offerings.emptyState.button")), 1)
                                    ]),
                                    _: 1
                                  }, 8, ["onClick"]))
                                : _createCommentVNode("", true)
                            ]),
                            _: 1
                          }))
                    ], 64))
              ]),
              _: 2
            }, [
              (!_ctx.isSupporterLoading)
                ? {
                    name: "cta",
                    fn: _withCtx(() => [
                      (_ctx.hasSupporterOfferings && _ctx.$user.isOwner())
                        ? (_openBlock(), _createBlock(_component_PxButton, {
                            key: 0,
                            class: "profile-headline__updateBtn",
                            size: "small",
                            onClick: _ctx.onClickEditOfferings
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.$t("profile.supporter.offerings.button")), 1)
                            ]),
                            _: 1
                          }, 8, ["onClick"]))
                        : _createCommentVNode("", true)
                    ]),
                    key: "0"
                  }
                : undefined
            ]), 1032, ["title"]))
          : _createCommentVNode("", true),
        (_ctx.$screen.smDown)
          ? (_openBlock(), _createBlock(_component_PxPanelSection, {
              key: 1,
              title: _ctx.$t('profile.supporter.offerings.title')
            }, {
              default: _withCtx(() => [
                (_ctx.isSupporterLoading)
                  ? (_openBlock(), _createBlock(_component_ContentPlaceholders, {
                      key: 0,
                      rounded: true
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ContentPlaceholdersText, { lines: 2 })
                      ]),
                      _: 1
                    }))
                  : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                      (_ctx.hasUserAccess && _ctx.hasSupporterOfferings)
                        ? (_openBlock(), _createBlock(_component_SupporterOfferings, {
                            key: 0,
                            offerings: _ctx.supporterOfferings
                          }, null, 8, ["offerings"]))
                        : (_openBlock(), _createBlock(_component_PanelEmptyState, {
                            key: 1,
                            icon: "offerings"
                          }, {
                            default: _withCtx(() => [
                              _createElementVNode("p", null, _toDisplayString(_ctx.$t(
                    `profile.supporter.offerings.emptyState.description[${+_ctx.$user.isOwner()}]`,
                  )), 1)
                            ]),
                            _: 1
                          }))
                    ], 64))
              ]),
              _: 1
            }, 8, ["title"]))
          : _createCommentVNode("", true)
      ]),
      _: 2
    }, [
      (_ctx.$screen.mdUp)
        ? {
            name: "header",
            fn: _withCtx(() => [
              _createElementVNode("h2", {
                class: "px-panel__title",
                innerHTML: _ctx.$t('profile.supporter.title')
              }, null, 8, _hoisted_3)
            ]),
            key: "0"
          }
        : undefined
    ]), 1024)
  ]))
}