<template>
  <div class="panel-empty-state">
    <PxIcon v-if="icon" :name="`empty-state/${icon}`" size="93" />
    <slot />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "PanelEmptyState",

  props: {
    icon: {
      type: String,
      default: null,
    },
  },
});
</script>

<style lang="scss" scoped>
.panel-empty-state :deep() {
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  padding: 20px 20px 15px;
  text-align: center;

  > .px-icon {
    /* override inlined styles */
    width: 164px !important;

    &:not(:last-child) {
      margin-bottom: 13px;
    }
  }

  p {
    font-size: 15px;
    line-height: 25px;
  }

  p + .el-button {
    margin-top: 12px;
  }
}
</style>
