import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-d76ea44c"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  ref: "matchingTagList",
  class: "matching-card-tag-list__wrapper"
}
const _hoisted_2 = { class: "matching-card-tag-list__container" }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = ["title"]
const _hoisted_5 = ["title"]
const _hoisted_6 = { class: "matching-card-tag-list__more" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PxMoreList = _resolveComponent("PxMoreList")!

  return (_ctx.hasTags)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          (!_ctx.listFormat)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                _createTextVNode(_toDisplayString(_ctx.mappedVisibleTags), 1)
              ], 64))
            : (_openBlock(), _createElementBlock("ul", _hoisted_3, [
                (_ctx.truncate)
                  ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.truncatedVisibleTags, (tag, index) => {
                      return (_openBlock(), _createElementBlock("li", {
                        key: index,
                        title: tag.originalValue
                      }, _toDisplayString(tag.value), 9, _hoisted_4))
                    }), 128))
                  : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.visibleTags, (tag, index) => {
                      return (_openBlock(), _createElementBlock("li", {
                        key: index,
                        title: tag
                      }, _toDisplayString(tag), 9, _hoisted_5))
                    }), 128))
              ])),
          (!!_ctx.moreTagsCopy)
            ? (_openBlock(), _createBlock(_component_PxMoreList, {
                key: 2,
                capitalize: _ctx.capitalize,
                "modal-title": _ctx.modalTitle,
                values: _ctx.tagsToDisplay,
                class: "matching-card-tag-list__container"
              }, {
                content: _withCtx(() => [
                  _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.moreTagsCopy), 1)
                ]),
                _: 1
              }, 8, ["capitalize", "modal-title", "values"]))
            : _createCommentVNode("", true)
        ])
      ], 512))
    : _createCommentVNode("", true)
}