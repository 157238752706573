<template>
  <div class="px-sidebar-divisor">
    <div class="px-sidebar-divisor__content">
      <PxIcon v-if="icon" :name="icon" :size="16" />
      <p
        :class="{ 'px-sidebar-divisor__content--only-text': !icon }"
        class="px-sidebar-divisor__title"
      >
        {{ title }}
        <span v-if="optionalText">
          {{ optionalText }}
        </span>
      </p>
      <p v-if="subtitle" class="px-sidebar-divisor__subtitle">
        {{ subtitle }}
      </p>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "PxSidebarDivisor",

  props: {
    title: {
      required: true,
      type: String,
    },
    optionalText: {
      type: String,
      default: "",
    },
    subtitle: {
      type: String,
      default: "",
    },
    icon: {
      type: String,
      default: "",
    },
  },
});
</script>

<style lang="scss" scoped>
.px-sidebar-divisor {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-height: 33px;
  padding: 3px 16px;
  background-color: $alabaster;
  border-top: 1px solid $athens-gray;
  border-bottom: 1px solid $athens-gray;

  &__content {
    display: flex;
    gap: 12px;
    align-items: center;

    &--only-text {
      padding-left: 8px;
    }
  }

  &__title {
    margin: 0;
    font-size: 15px;
    font-weight: 600;
    line-height: 25px;
    color: $ebony-clay;
  }

  &__subtitle {
    font-size: 14px;
    font-style: italic;
    line-height: 20px;
    color: $manatee;
  }
}
</style>
