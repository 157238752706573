<template>
  <div class="company-edit-modal--entrepreneur">
    <ElForm
      ref="form"
      :model="form.data"
      :rules="rules"
      @validate="updateSubmitState"
    >
      <div class="form-grid">
        <div class="form-grid__logo">
          <div class="avatar-section">
            <ElUpload
              ref="upload"
              :auto-upload="false"
              :on-change="onFileChange"
              :show-file-list="false"
              accept="image/png, image/jpeg"
            >
              <PxAvatar :name="form.data.name" :photo="avatarUrl" />
              <div class="avatar-section__actions">
                <PxButton
                  :class="classesAvatarBtn"
                  :type="typeAvatarBtn"
                  icon="camera"
                  size="small"
                >
                  {{ $t("profile.companyEditModal.logo.button") }}
                </PxButton>
                <p
                  class="avatar-section__text"
                  v-html="$t('profile.companyEditModal.logo.help')"
                />
              </div>
            </ElUpload>
          </div>
        </div>
        <div class="form-grid__left-fields">
          <ElFormItem
            :label="
              $t(`profile.companyEditModal.fields.name[${$profile.type()}]`)
            "
            prop="name"
          >
            <ElInput
              v-model="form.data.name"
              :placeholder="
                $t(`profile.companyEditModal.fields.name[${$profile.type()}]`)
              "
            />
          </ElFormItem>
          <ElFormItem
            :label="$t('profile.companyEditModal.fields.website')"
            prop="website"
          >
            <ElInput
              ref="website"
              v-model.trim="form.data.website"
              :placeholder="
                $t('authentication.signup.form.fields.websitePlaceholder')
              "
            >
              <template #prefix>
                <PxIcon :size="24" name="website" />
              </template>
            </ElInput>
          </ElFormItem>
          <ElFormItem prop="sectors">
            <SectorsSelector
              ref="sectorSelector"
              v-model="form.data.sectors"
              @change="handleSectorsChange"
            />
          </ElFormItem>
        </div>
        <div class="form-grid__right-fields">
          <ElFormItem
            v-if="form.data.locations"
            :label="$t('profile.companyEditModal.fields.location')"
            prop="locations"
          >
            <PxInputPlaces
              id="companyEditLocation"
              v-model="form.data.locations[0]"
              :placeholder="$t('profile.companyEditModal.fields.location')"
              :use-geolocation="true"
            />
          </ElFormItem>
          <ElFormItem
            :label="$t('profile.companyEditModal.fields.email')"
            prop="email"
          >
            <ElInput
              ref="email"
              v-model="form.data.email"
              :placeholder="$t('profile.companyEditModal.fields.email')"
            />
          </ElFormItem>
          <ElFormItem
            :label="$t('profile.companyEditModal.fields.founded')"
            prop="founded_date"
          >
            <PxDateSelect v-model="form.data.founded_date" />
          </ElFormItem>
          <ElFormItem
            :label="$t('profile.companyEditModal.fields.about')"
            prop="about"
          >
            <PxTextarea
              ref="about"
              v-model="form.data.about"
              :max-chars="rules.about.max"
              :placeholder="$t('profile.companyEditModal.placeholders.about')"
              show-counter
            />
          </ElFormItem>
        </div>
      </div>
    </ElForm>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import cloneDeep from "lodash/cloneDeep";

import { ICompany } from "@/modules/profile/services/data/company/company.types";
import { companyProvider } from "@/modules/profile/services/data/company/company.provider";
import { EAuthCompanyActions } from "@/modules/authentication/services/store/auth/auth-types";
import { EProfileCompanyActions } from "@/modules/profile/services/store/profile/profile-types";

import SectorsSelector from "@/components/sectors-selector/sectors-selector.vue";
import CompanyEditModalMixin from "@/mixins/company-edit-modal.mixin";

import {
  generateEmailValidator,
  generateRequiredListsValidator,
  generateRequiredSetValidator,
  generateURLValidator,
} from "@/services/errors/validator-generators";

export default defineComponent({
  name: "CompanyEditModalEntrepreneur",

  components: {
    SectorsSelector,
  },

  mixins: [CompanyEditModalMixin],

  data() {
    return {
      form: {
        data: {
          name: "",
          website: "",
          email: "",
          about: "",
          founded_date: "",
          logo: "",
          locations: [],
          sectors: [] as number[],
        },
        file: null as File | null,
      },

      rules: {
        name: {
          required: true,
          message: this.$t("common.errors.required", {
            fieldName: this.$t(
              `profile.companyEditModal.fields.name[${this.$profile.type()}]`,
            ),
          }),
        },
        website: generateURLValidator(
          this,
          "profile.companyEditModal.fields.website",
        ),
        email: generateEmailValidator(
          this,
          "profile.companyEditModal.fields.email",
        ),
        locations: generateRequiredListsValidator(
          this,
          "profile.companyEditModal.fields.location",
        ),
        sectors: generateRequiredSetValidator(
          this,
          "profile.companyEditModal.fields.sector",
        ),
        about: {
          max: 200,
        },
      },
    };
  },

  computed: {
    companyId(): number {
      const company = this.$store.get("auth/company.data");
      return company ? company.id : null;
    },
  },

  methods: {
    /**
     * Handle the click on the save button.
     */
    async submitForm() {
      this.$emit("update:loadingSubmission", true);
      this.$emit("update:hasServerErrors", false);

      if (this.form.file) {
        await companyProvider.updateLogo(this.companyId, this.form.file);
      }

      try {
        // TODO: when implementing the multiple location feature these next lines
        // must be removed
        const newData = {
          ...this.form.data,
          location: this.form.data.locations
            ? this.form.data.locations[0]
            : undefined,
        };

        /**
         * Guarantee that the user has defined a location, otherwise an error arises
         */
        if (!newData.location) {
          this.$emit("update:hasServerErrors", true);
          return;
        }

        await this.$store.dispatch(EAuthCompanyActions.PATCH, newData);
      } catch (error) {
        this.$emit("update:hasServerErrors", true);
        return;
      } finally {
        this.$emit("update:loadingSubmission", false);
      }

      this.$message({
        message: this.$t("profile.companyEditModal.successMessage"),
        type: "success",
        customClass: "is-full",
      });

      this.$emit("modal-visibility", false);
      await this.fetchCompany();
      this.resetFormData().then(() => null);
    },

    async resetFormData() {
      const company = cloneDeep(this.company) as ICompany;
      const formFields = Object.keys(this.form.data);

      // Get company values for form fields
      this.form.data = Object.keys(company)
        .filter((companyField: any) => formFields.includes(companyField))
        .reduce((obj: any, key: string) => {
          obj[key] = company[key];
          return obj;
        }, {});

      // Clean selected file
      this.form.file = null;

      // Set the sectors
      await this.$nextTick();
      const sectorSelector = this.$refs.sectorSelector as any;
      if (sectorSelector && this.form.data.sectors !== undefined) {
        sectorSelector.setSectors(this.form.data.sectors);
      }

      // Trigger validations
      await this.$nextTick();
      this.originalData = cloneDeep(this.form.data);

      try {
        await this.formRef.validate();
      } catch (_) {
        return;
      }
    },

    async fetchCompany() {
      await this.$store.dispatch(EAuthCompanyActions.FETCH, this.companyId);
      await this.$store.dispatch(EProfileCompanyActions.FETCH, this.companyId);
    },

    handleSectorsChange() {
      this.formRef.validateField("sectors", this.updateSubmitState);
    },
  },
});
</script>

<style lang="scss">
.el-input__suffix {
  right: 10px;

  @include breakpoint-up(md) {
    right: 8px;
  }
}

.el-select .el-input__inner {
  padding-right: 24px;

  @include breakpoint-up(md) {
    padding-right: 16px;
  }
}
</style>

<style lang="scss" scoped>
.avatar-section {
  padding-top: 18px;

  :deep(.el-upload) {
    display: flex;
    flex-direction: column;
    gap: 1px;

    @include breakpoint-down(sm) {
      flex-direction: row;
      gap: 0;
    }
  }

  @include breakpoint-down(sm) {
    display: flex;
    padding: 15px 0 7px 0;
  }
}

.avatar-section__actions {
  margin-top: 6px;

  @include breakpoint-down(sm) {
    margin-left: 14px;
    margin-top: 0;
  }
}

.avatar-section__text {
  @include grotesk(regular, italic);

  position: relative;
  left: -4px;
  padding-top: 4px;

  font-size: to-rem(12px);
  line-height: 18px;
  color: $manatee;

  @include breakpoint-down(sm) {
    padding-top: 7px;
    padding-left: 4px;

    font-size: to-rem(13px);
  }
}

.form-grid {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: calc(100% - 60px);
  padding-left: 3px;
  margin: auto;

  @include breakpoint-down(md) {
    flex-direction: column;
    max-width: calc(100% - 35px);
    padding-left: 0;
  }

  .form-grid__logo {
    margin-right: -3px;
  }

  .form-grid__left-fields,
  .form-grid__right-fields {
    @include breakpoint-up(lg) {
      width: 310px;
    }
  }
}

/* Hide textarea error */
:deep(.px-textarea + .el-form-item__error) {
  display: none;
}
</style>
