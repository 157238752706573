import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, mergeProps as _mergeProps, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["innerHTML"]
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PxIcon = _resolveComponent("PxIcon")!
  const _component_PxButton = _resolveComponent("PxButton")!
  const _component_ElDialog = _resolveComponent("ElDialog")!

  return (_openBlock(), _createBlock(_component_ElDialog, {
    ref: "modal",
    modelValue: _ctx.innerVisibility,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.innerVisibility) = $event)),
    "append-to-body": true,
    "close-on-click-modal": false,
    "close-on-press-escape": false,
    class: "initiative-dialog"
  }, {
    header: _withCtx(() => [
      _createElementVNode("h3", null, _toDisplayString(_ctx.modalCopy.title), 1),
      _createElementVNode("p", null, _toDisplayString(_ctx.modalCopy.subtitle), 1),
      _createVNode(_component_PxIcon, {
        size: 120,
        name: "equality-icon"
      })
    ]),
    default: _withCtx(() => [
      _createElementVNode("p", {
        innerHTML: _ctx.modalCopy.content[0]
      }, null, 8, _hoisted_1),
      _createElementVNode("p", {
        innerHTML: _ctx.modalCopy.content[1]
      }, null, 8, _hoisted_2),
      _createElementVNode("p", {
        innerHTML: _ctx.modalCopy.content[2]
      }, null, 8, _hoisted_3),
      _createElementVNode("ul", null, [
        _createElementVNode("li", {
          innerHTML: _ctx.modalCopy.content[3]
        }, null, 8, _hoisted_4),
        _createElementVNode("li", {
          innerHTML: _ctx.modalCopy.content[4]
        }, null, 8, _hoisted_5),
        _createElementVNode("li", {
          innerHTML: _ctx.modalCopy.content[5]
        }, null, 8, _hoisted_6),
        _createElementVNode("li", {
          innerHTML: _ctx.modalCopy.content[6]
        }, null, 8, _hoisted_7)
      ]),
      _createVNode(_component_PxButton, _mergeProps(_ctx.buttonProps, { onClick: _ctx.goToInitiativePage }), null, 16, ["onClick"])
    ]),
    _: 1
  }, 8, ["modelValue"]))
}