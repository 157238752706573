<template>
  <div class="px-milestone-level">
    <div class="px-milestone-level__wrapper">
      <div class="px-milestone-level__image">
        <PxLevel :category-color="category.color.slice(1)" :level="level" />
      </div>
      <div class="px-milestone-level__content">
        <h2
          class="px-milestone-level__title"
          v-html="
            $t('milestonePlanner.sidebar.milestonePlan.title', {
              category: category.name,
            })
          "
        />
        <div class="px-milestone-level__description">
          <h3>
            {{ $t("profile.assessment.levelPanel.label") }}
            {{ level }}
          </h3>
          <p data-testid="category-level-description">
            {{ categoryLevelDescription }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import PxLevel from "@/components/px-level/px-level.vue";
import { IGridCategory } from "@/modules/milestone-planner/components/milestones-grid/milestones-grid.interface";

export default defineComponent({
  name: "MilestoneLevel",

  components: {
    PxLevel,
  },

  props: {
    level: {
      type: Number,
      required: true,
    },
    category: {
      type: Object as () => IGridCategory,
      required: true,
    },
    showTooltip: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    categoryLevelDescription(): string {
      const selectedCategoryLevel = this.category.milestones.find(
        (milestone) => milestone.level === this.level,
      );
      return selectedCategoryLevel ? selectedCategoryLevel.description : "";
    },
  },
});
</script>

<style lang="scss">
.px-milestone-level__tooltip {
  width: 340px;
}
</style>

<style lang="scss" scoped>
.px-milestone-level {
  &__wrapper {
    position: relative;
    display: flex;
    gap: 17px;
    align-items: flex-start;
    padding: 9px 0 19px 0;
  }

  &__image {
    width: 120px;
    height: 100px;
  }

  &__content {
    position: relative;
    padding-top: 19px;
    padding-right: 20px;
  }

  svg {
    top: -38px;
    right: 16px;
  }

  &__title {
    position: relative;
    bottom: 3px;
    padding: 0;
    font-size: 12px;
    font-weight: 600;
    line-height: 12px;
    color: rgba($manatee, 0.5);
    text-transform: uppercase;
    letter-spacing: 0.3px;
  }

  &__description {
    position: relative;
    bottom: 2px;

    h3 {
      font-size: 20px;
      line-height: 30px;
      color: $ebony-clay;
    }

    p {
      font-size: 14px;
      line-height: 20px;
      color: $manatee;
      letter-spacing: -0.3px;
    }
  }

  .icon-question {
    position: relative;
    bottom: 4px;
    width: 18px;
    height: 17px;
    margin-left: 12px;
  }
}
</style>
