import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PxIcon = _resolveComponent("PxIcon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([{
      'milestone-level-cell--active': _ctx.active,
    }, "milestone-level-cell"])
  }, [
    _createElementVNode("div", {
      style: _normalizeStyle(_ctx.cellStyles),
      class: "milestone-level-cell__container"
    }, [
      _createElementVNode("span", null, _toDisplayString(_ctx.number), 1),
      (_ctx.isMilestoneComplete)
        ? (_openBlock(), _createBlock(_component_PxIcon, {
            key: 0,
            class: _normalizeClass(_ctx.completeMilestoneType.name),
            name: _ctx.completeMilestoneType.name,
            size: _ctx.completeMilestoneType.size
          }, null, 8, ["class", "name", "size"]))
        : (_ctx.isMilestoneCritical)
          ? (_openBlock(), _createBlock(_component_PxIcon, {
              key: 1,
              class: "critical",
              name: "critical-milestone",
              size: "22"
            }))
          : _createCommentVNode("", true)
    ], 4)
  ], 2))
}