import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-68d6438c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "level-bar" }
const _hoisted_2 = { class: "level-bar__label" }
const _hoisted_3 = { class: "level-bar__icon" }
const _hoisted_4 = ["alt", "src"]
const _hoisted_5 = { class: "level-bar__description" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.label || _ctx.$t("selfAssessment.components.levelBar.label")), 1),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("img", {
        alt: `Level ${_ctx.level.value}`,
        src: `/img/icons/level-graph/${_ctx.level.value}-white.svg`
      }, null, 8, _hoisted_4)
    ]),
    _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.level.title), 1)
  ]))
}