<template>
  <ul :class="`px-menu--${mode}`" class="px-menu">
    <template v-if="useRouter">
      <template v-for="item in items" :key="item.key">
        <ElDropdown
          v-if="showSubMenuItemsDropdown(item)"
          :class="[{ 'is-active': checkItemShouldBeActive(item) }]"
          :data-testid="`menu-dropdown--${item.key}`"
          class="px-menu__dropdown"
          popper-class="px-menu__dropdown-menu"
        >
          <div class="px-menu__dropdown-toggler">
            <p>
              {{ item.label }}
            </p>
            <PxStatusBadge
              v-if="item.newFeature"
              v-bind="$options.static.newFeatureLabel"
            />
          </div>
          <template #dropdown>
            <ElDropdownMenu>
              <ElDropdownItem
                v-for="subitem in item.subMenuItems"
                :key="subitem.key"
                @click="goToPage(subitem.to)"
              >
                {{ subitem.label }}
                <PxStatusBadge
                  v-if="subitem.newFeature"
                  v-bind="$options.static.newFeatureLabel"
                />
              </ElDropdownItem>
            </ElDropdownMenu>
          </template>
        </ElDropdown>
        <RouterLink
          v-else-if="showSubMenuItem(item) && item.subMenuItems"
          :class="{ 'is-active': checkItemShouldBeActive(item) }"
          :to="{ name: item.subMenuItems[0].to }"
          class="px-menu__item"
        >
          <li>
            <a class="px-menu__item-link">
              {{ item.subMenuItems[0].label }}
            </a>
          </li>
        </RouterLink>
        <RouterLink
          v-else
          :class="{ 'is-active': checkItemShouldBeActive(item) }"
          :to="{ name: item.to }"
          class="px-menu__item"
        >
          <li>
            <a class="px-menu__item-link">
              {{ item.label }}
            </a>
          </li>
          <PxIcon v-if="item.icon" :name="item.icon" size="12" />
        </RouterLink>
      </template>
    </template>
    <template v-else>
      <li
        v-for="item in items"
        :key="item.key"
        :class="{
          'is-active': checkItemShouldBeActive(item) || innerValue === item.key,
        }"
        class="px-menu__item"
        @click="onClickItem(item.key)"
      >
        <a class="px-menu__item-link" href="javascript:void(0)">
          {{ item.label }}
        </a>
        <PxIcon
          v-if="item.icon"
          :name="item.icon"
          class="px-menu__icon"
          size="12"
        />
      </li>
    </template>
  </ul>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import PxStatusBadge from "@/components/px-status-badge/px-status-badge.vue";

import { INavBarItem } from "@/modules/profile/meta/entrepreneurs";
import { ROUTE_PROFILE } from "@/modules/profile/services/router/routes-names";

export default defineComponent({
  name: "PxMenu",

  components: {
    PxStatusBadge,
  },

  props: {
    /**
     * Items for the menu
     */
    items: {
      type: Array as () => Array<INavBarItem>,
      required: true,
    },

    /**
     * Modifier for the appearance.
     */
    mode: {
      type: String,
      default: "normal",
      validator(value: string) {
        return ["normal", "even"].includes(value);
      },
    },

    /**
     * When this is defined the component will use a `router-link`
     * to handle the menu elements instead of using the v-router.
     */
    useRouter: {
      type: Boolean,
      default: true,
    },

    /**
     * Current selected item.
     *
     * This is only used when the `useRouter` is set to `false`.
     */
    modelValue: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      innerValue: "",
    };
  },

  static: {
    newFeatureLabel: {
      label: "New",
      icon: "stars--white",
      iconSize: 19,
      textColor: "#fff",
      backgroundColor: "#3b82f6",
      iconPosition: "right",
    },
  },

  watch: {
    modelValue: {
      immediate: true,
      handler(newVal) {
        this.innerValue = newVal;
      },
    },

    /**
     * Emit value updated to the outside world.
     */
    innerValue(newVal, oldVal) {
      if (newVal === oldVal) {
        return;
      }

      this.$emit("update:modelValue", newVal);
    },
  },

  methods: {
    /**
     * Handles the click on the item.
     */
    onClickItem(key: string) {
      this.innerValue = key;
    },

    checkItemShouldBeActive(item: INavBarItem) {
      const notProfileOwner =
        this.$route.name === ROUTE_PROFILE && !!this.$route.params.id;

      if (!item.includedRoutes?.length || !this.$route.name || notProfileOwner)
        return false;

      return item.includedRoutes.includes(this.$route.name as string);
    },

    showSubMenuItemsDropdown(item: INavBarItem) {
      return item.subMenuItems && item.subMenuItems.length > 1;
    },

    showSubMenuItem(item: INavBarItem) {
      return item.subMenuItems && item.subMenuItems.length == 1;
    },

    goToPage(to: string) {
      if (to === this.$route.name) return;

      this.$router.push({ name: to });
    },
  },
});
</script>

<style lang="scss" scoped>
.px-menu {
  position: relative;
  z-index: z("navigation") + 1;
  align-self: flex-end;
  padding: 0;
  margin: 0 0 0 58px;
  list-style: none;

  display: flex;
  align-items: flex-end;

  &__item {
    position: relative;
    display: inline-block;
    padding: 0 8px 22px;

    @include breakpoint-up(md) {
      padding: 0;
    }
  }

  &__dropdown::after,
  &__item::after {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    height: 3px;

    content: "";

    background: $white;
    border-top-left-radius: 1px;
    border-top-right-radius: 1px;

    opacity: 0;

    transition: opacity 0.23s ease-in-out;
  }

  &__dropdown::after {
    bottom: -1px;
  }

  &__dropdown.is-active::after,
  &__item.is-active::after,
  &__item.router-link-active::after {
    opacity: 1;
  }

  &__dropdown {
    position: relative;
    display: inline-block;
    padding-right: 20px;
    height: 42px;
  }

  &__dropdown-toggler {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;

    cursor: pointer;

    p {
      position: relative;

      @include grotesk(medium);

      font-size: 1.133rem;
      color: $white;
      text-align: center;
      text-decoration: none;

      padding: 0 20px;
      margin: 0;
      letter-spacing: -0.22px;
    }

    p::after {
      position: absolute;
      content: "";
      display: inline-block;
      right: -6px;
      top: 8px;
      width: 16px;
      height: 16px;

      background-image: url("#{$assetsPath}/img/icons/arrow-filled.svg");
      background-repeat: no-repeat;
      background-size: contain;
      vertical-align: middle;
    }

    .px-status-badge {
      margin-top: 3px;
      padding: 2px 5px 3px 11px;
      box-shadow: 0 1px 2px 0 rgba(39, 39, 64, 0.12);

      animation: bounce 2s infinite;
    }
  }

  a {
    text-decoration: none;
  }

  a.px-menu__item-link {
    @include grotesk(medium);

    font-size: 1.133rem;
    color: $white;
    text-align: center;

    @include breakpoint-up(md) {
      display: block;
      height: 38px;
      margin: 0 8px;
    }
  }

  &__dropdown:not(.is-active) .px-menu__dropdown-toggler p:hover,
  &__item:not(.is-active) .px-menu__item-link:hover,
  &__item:not(.router-link-active) .px-menu__item-link:hover {
    /* Apply hover state only for devices that supporter hover */
    @media not all and (hover: none) {
      opacity: 0.7;
    }
  }
}

.px-menu--even {
  display: flex;
  margin: 0;

  .px-menu__item {
    @include grotesk(medium);

    flex-grow: 1;
    justify-content: center;
    padding-top: 7px;
    padding-bottom: 16px;
    margin: 0;

    text-align: center;

    @include breakpoint-up(md) {
      flex-basis: 0;
    }
  }
}

.px-menu__icon {
  position: relative;
  top: 8px;
}
</style>

<style lang="scss">
.px-menu__dropdown-menu {
  top: 58px !important;

  .el-dropdown-menu {
    padding: 11px 15px 10px 11px;
    border-radius: 4px;
    box-shadow: 0 2px 11px 0 rgba(0, 0, 0, 0.05);
    border: solid 1px #d7d7d7;
    background-color: #fff;

    &::before,
    &::after {
      display: none;
    }
  }

  .el-dropdown-menu__item {
    display: flex;
    gap: 10px;

    padding: 8px 4px 8px 8px;

    font-size: 17px;
    font-weight: 500;
    line-height: 1.29;
    color: $ebony-clay;
    letter-spacing: -0.3px;
    z-index: 1;

    .px-status-badge {
      padding: 2px 5px 3px 11px;
      box-shadow: 0 1px 2px 0 rgba(39, 39, 64, 0.12);

      animation: bounce 2s infinite;
    }

    &::before {
      border-radius: 4px;
      background-color: $athens-gray;
    }
  }

  .el-dropdown-menu__item:not(:first-child) {
    margin-top: 9px;
  }
}
</style>
