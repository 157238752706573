import { AxiosResponse } from "axios";

export abstract class BaseProviderException extends Error {
  /**
   * Error response.
   */
  private readonly _response!: AxiosResponse;

  /**
   * Initialize the base provider exception.
   *
   * @param errorResponse Error response from the HTTP client.
   * @param message Custom error message for the error.
   */
  protected constructor(
    errorResponse: AxiosResponse,
    message = "Generic Error",
  ) {
    super(message);
    this._response = errorResponse;
  }

  /**
   * Get the response error.
   */
  get response() {
    return this._response;
  }
}
