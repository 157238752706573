<template>
  <div v-if="visible" :style="messageBarStyles" class="px-message-bar">
    <PxIcon v-if="icon" :name="icon" :size="16" />
    <slot name="content" />
    <slot name="action" />
    <PxIcon
      v-if="closable"
      :size="10"
      class="px-message-bar__close"
      name="cross--white"
      @click="closeBar"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "PxMessageBar",

  props: {
    visible: {
      type: Boolean,
      default: true,
    },

    center: {
      type: Boolean,
      default: true,
    },

    closable: {
      type: Boolean,
      default: true,
    },

    icon: {
      type: String,
      default: "info",
    },

    backgroundColor: {
      type: String,
      default: "#7bb7ff",
    },

    textColor: {
      type: String,
      default: "#fff",
    },
  },

  computed: {
    messageBarStyles(): { [key: string]: string | number } {
      return {
        backgroundColor: this.backgroundColor,
        color: this.textColor,
        justifyContent: this.center ? "center" : "flex-start",
      };
    },
  },

  methods: {
    closeBar() {
      this.$emit("update:visible", false);
    },
  },
});
</script>

<style lang="scss" scoped>
.px-message-bar {
  display: flex;
  gap: 6px;
  padding: 0 15px 0 15px;

  align-items: center;
  min-width: 100%;
  min-height: 30px;
  z-index: z("floaters") + 1 !important;

  p,
  :slotted(p) {
    @include grotesk(medium);

    margin: 0;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: -0.16px;
  }

  &__close {
    position: absolute;
    right: 8.4%;

    cursor: pointer;
  }
}
</style>
