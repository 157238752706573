import { ActionTree } from "vuex";
import {
  EListManagementActions,
  EListManagementMutations,
  IListManagementState,
} from "./list-management.types";
import { RootState } from "@/services/store/root-state";
import { getBaseName } from "@/services/store/utils/get-base-name";
import { ICompanyList } from "@/modules/company-lists/services/data/company-list/company-list.interface";

export const listManagementActions: ActionTree<
  IListManagementState,
  RootState
> = {
  /**
   * Add company id to list.
   */
  async [getBaseName(EListManagementActions.ADD_COMPANY)](
    { commit, state },
    companyUid: string,
  ) {
    // If company already exists on store, filter out
    const filteredCompanies = state.data.companies.filter(
      (itemId: string) => itemId !== companyUid,
    );

    commit(EListManagementMutations.SET_DATA, {
      ...state.data,
      companies: [...filteredCompanies, companyUid],
    });
  },

  /**
   * Set selected companies.
   */
  async [getBaseName(EListManagementActions.SET_COMPANIES)](
    { commit, state },
    items: Array<string>,
  ) {
    commit(EListManagementMutations.SET_DATA, {
      ...state.data,
      companies: [...items],
    });
  },

  /**
   * Remove company id from list.
   */
  async [getBaseName(EListManagementActions.REMOVE_COMPANY)](
    { commit, state },
    companyUid: string,
  ) {
    // If company already exists on store, filter out
    const filteredCompanies = state.data.companies.filter(
      (itemId: string) => itemId !== companyUid,
    );

    commit(EListManagementMutations.SET_DATA, {
      ...state.data,
      companies: [...filteredCompanies],
    });
  },

  /**
   * Purge companies.
   */
  async [getBaseName(EListManagementActions.CLEAR_COMPANIES)]({
    commit,
    state,
  }) {
    commit(EListManagementMutations.SET_DATA, {
      ...state.data,
      companies: [],
    });
  },

  /**
   * Add list item.
   */
  async [getBaseName(EListManagementActions.ADD_LIST)](
    { commit, state },
    list: ICompanyList,
  ) {
    // If list already exists on store, filter out
    const filteredLists = state.data.lists.filter(
      (item: ICompanyList) => item.uid !== list.uid,
    );

    commit(EListManagementMutations.SET_DATA, {
      ...state.data,
      lists: [...filteredLists, list],
    });
  },

  /**
   * Remove list element.
   */
  async [getBaseName(EListManagementActions.REMOVE_LIST)](
    { commit, state },
    list: ICompanyList,
  ) {
    // If list already exists on store, filter out
    const filteredLists = state.data.lists.filter(
      (item: ICompanyList) => item.uid !== list.uid,
    );

    commit(EListManagementMutations.SET_DATA, {
      ...state.data,
      lists: [...filteredLists],
    });
  },

  /**
   * Set selected lists.
   */
  async [getBaseName(EListManagementActions.SET_LISTS)](
    { commit, state },
    items: Array<ICompanyList>,
  ) {
    commit(EListManagementMutations.SET_DATA, {
      ...state.data,
      lists: [...items],
    });
  },

  /**
   * Purge lists.
   */
  async [getBaseName(EListManagementActions.CLEAR_LISTS)]({ commit, state }) {
    commit(EListManagementMutations.SET_DATA, {
      ...state.data,
      lists: [],
    });
  },
};
