import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-6c19cc75"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "milestone-sidebar"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MilestoneLevel = _resolveComponent("MilestoneLevel")!
  const _component_PxSidebarDivisor = _resolveComponent("PxSidebarDivisor")!

  return (_ctx.category)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("img", {
          alt: "minimize",
          src: "/img/icons/minimize--black-bigger.svg",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('hide-carousel')))
        }),
        _createVNode(_component_MilestoneLevel, {
          category: _ctx.category,
          level: _ctx.categoryLevel
        }, null, 8, ["category", "level"]),
        _createVNode(_component_PxSidebarDivisor, {
          title: 
        _ctx.$t('milestonePlanner.sidebar.milestonePlan.whyCategoryMatters', {
          category: _ctx.category.name,
        })
      
        }, null, 8, ["title"]),
        _createElementVNode("p", null, _toDisplayString(_ctx.categoryDescription), 1)
      ]))
    : _createCommentVNode("", true)
}