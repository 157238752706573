import {
  GenericProvider,
  EProviderFeatures,
} from "@/services/data/generic-provider";
import { IProfile } from "./profile.interface";
import { baseAPIUrl } from "@/services/utils/utils";

/**
 * Provider to manage profile related data.
 */
class ProfileProvider extends GenericProvider<IProfile> {
  constructor() {
    super(`${baseAPIUrl}/userprofiles`, [
      EProviderFeatures.GET,
      EProviderFeatures.UPDATE,
    ]);
  }
}

export const profileProvider = new ProfileProvider();
