import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, mergeProps as _mergeProps, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PxIcon = _resolveComponent("PxIcon")!
  const _component_PxButton = _resolveComponent("PxButton")!
  const _component_ElDialog = _resolveComponent("ElDialog")!

  return (_openBlock(), _createBlock(_component_ElDialog, {
    modelValue: _ctx.innerVisibility,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.innerVisibility) = $event)),
    "append-to-body": true,
    class: "charts-locked-modal",
    width: _ctx.width,
    "close-on-click-modal": _ctx.closable,
    "close-on-press-escape": _ctx.closable,
    "show-close": _ctx.closable,
    modal: _ctx.showMask,
    top: _ctx.marginTop
  }, {
    footer: _withCtx(() => [
      _createVNode(_component_PxButton, _mergeProps(_ctx.buttonProps, { onClick: _ctx.onButtonClick }), null, 16, ["onClick"])
    ]),
    default: _withCtx(() => [
      _createVNode(_component_PxIcon, {
        size: 60,
        name: _ctx.icon
      }, null, 8, ["name"]),
      _createElementVNode("h1", null, _toDisplayString(_ctx.$t(_ctx.title)), 1),
      _createElementVNode("p", {
        innerHTML: _ctx.$t(_ctx.description)
      }, null, 8, _hoisted_1)
    ]),
    _: 1
  }, 8, ["modelValue", "width", "close-on-click-modal", "close-on-press-escape", "show-close", "modal", "top"]))
}