import { resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-050b81ea"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "submission-panel__supporter" }
const _hoisted_2 = { class: "submission-panel__supporter-header" }
const _hoisted_3 = {
  key: 0,
  class: "submission-panel__supporter-field-container"
}
const _hoisted_4 = { class: "submission-panel__supporter-column submission-panel__supporter-column--left" }
const _hoisted_5 = { class: "submission-panel__supporter-column submission-panel__supporter-column--right" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SubmissionPanelSupporterInfo = _resolveComponent("SubmissionPanelSupporterInfo")!
  const _component_PxMatchingInterest = _resolveComponent("PxMatchingInterest")!
  const _component_PxButton = _resolveComponent("PxButton")!
  const _component_PxMatchingInterestTip = _resolveComponent("PxMatchingInterestTip")!
  const _component_SubmissionPanelSupporterInterests = _resolveComponent("SubmissionPanelSupporterInterests")!
  const _component_SubmissionPanelSupporterContacts = _resolveComponent("SubmissionPanelSupporterContacts")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_SubmissionPanelSupporterInfo, {
        id: _ctx.company.id,
        subtitle: _ctx.companyAddress,
        title: _ctx.company.name
      }, null, 8, ["id", "subtitle", "title"]),
      (_ctx.userCanMatch)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createVNode(_component_PxMatchingInterest, {
              id: _ctx.company.id,
              name: _ctx.company.name,
              "with-description": false
            }, null, 8, ["id", "name"]),
            (_ctx.$screen.lgUp)
              ? (_openBlock(), _createBlock(_component_PxButton, _mergeProps({
                  key: 0,
                  class: "submission-panel__supporter-cta--addToList"
                }, _ctx.addToListButtonProps, {
                  onClick: _withModifiers(_ctx.showListModal, ["stop"])
                }), null, 16, ["onClick"]))
              : _createCommentVNode("", true),
            _createVNode(_component_PxMatchingInterestTip, {
              id: _ctx.company.id,
              name: _ctx.company.name
            }, null, 8, ["id", "name"])
          ]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_SubmissionPanelSupporterInterests, {
        "grouped-locations": _ctx.supporterGroupedLocations,
        "grouped-sectors": _ctx.supporterGroupedSectors,
        locations: _ctx.supporterLocations,
        sectors: _ctx.supporterSectors
      }, null, 8, ["grouped-locations", "grouped-sectors", "locations", "sectors"])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createVNode(_component_SubmissionPanelSupporterContacts, { email: _ctx.companyEmail }, null, 8, ["email"])
    ])
  ]))
}