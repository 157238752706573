import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-56a293de"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "affiliate-footer-bar" }
const _hoisted_2 = { class: "affiliate-footer-bar__container" }
const _hoisted_3 = { class: "affiliate-footer-bar__left-container" }
const _hoisted_4 = {
  key: 1,
  class: "affiliate-footer-bar__notification"
}
const _hoisted_5 = { class: "affiliate-footer-bar__right-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PxButton = _resolveComponent("PxButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (_ctx.shouldShowElements)
          ? (_openBlock(), _createBlock(_component_PxButton, _mergeProps({ key: 0 }, _ctx.saveAsDraftButtonProps, {
              loading: _ctx.isSaving,
              class: "affiliate-footer-bar__draft-button",
              onClick: _ctx.saveDraft
            }), null, 16, ["loading", "onClick"]))
          : _createCommentVNode("", true),
        (_ctx.date && _ctx.$screen.mdUp && _ctx.shouldShowElements)
          ? (_openBlock(), _createElementBlock("p", _hoisted_4, _toDisplayString(_ctx.$t("affiliateProgram.footerBar.update", {
              date: _ctx.date,
            })), 1))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_PxButton, _mergeProps(_ctx.previousButtonProps, {
          loading: _ctx.isSaving,
          class: "affiliate-footer-bar__previous-button",
          onClick: _ctx.goToPreviousStep
        }), null, 16, ["loading", "onClick"]),
        _createVNode(_component_PxButton, _mergeProps(_ctx.nextButtonProps, {
          disabled: _ctx.submitDisabled && _ctx.questionBundleAffiliate && _ctx.isLastPage,
          loading: _ctx.isSaving,
          class: "affiliate-footer-bar__next-button",
          onClick: _ctx.goToNextStep
        }), null, 16, ["disabled", "loading", "onClick"])
      ])
    ])
  ]))
}