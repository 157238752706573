<template>
  <div class="team-members-list-footer">
    <PxButton
      :disabled="disabled"
      class="team-members-list-footer__cta"
      data-testid="team-members-list-footer-cta"
      size="small"
      type="green"
      @click="addTeamMember"
    >
      {{ $t(buttonLabel) }}
    </PxButton>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "TeamMembersListFooter",

  props: {
    disabled: {
      type: Boolean,
      default: false,
    },

    buttonLabel: {
      type: String,
      default: "teamManagement.membersList.newMemberButton",
    },
  },

  methods: {
    addTeamMember() {
      this.$emit("add-member");
    },
  },
});
</script>

<style lang="scss" scoped>
.team-members-list-footer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  padding: 16px;

  background: $alabaster;
  border-top: 1px solid $turkish-angora;

  :deep() .el-button {
    width: 100%;
    height: 32px;
  }
}
</style>
