import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-577299db"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "px-loading-stack" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "px-loading-mask",
    style: _normalizeStyle({ backgroundColor: _ctx.background || '' })
  }, [
    _createElementVNode("div", _hoisted_1, [
      (_openBlock(), _createElementBlock(_Fragment, null, _renderList(5, (i) => {
        return _createElementVNode("span", {
          key: i,
          class: _normalizeClass([`px-loading-stack__line--${i}`, "px-loading-stack__line"])
        }, null, 2)
      }), 64))
    ])
  ], 4))
}