<template>
  <PxPanel
    class="validating-panel-placeholder px-panel--mobile-no-side-borders"
  >
    <ContentPlaceholders
      class="validating-panel-placeholder__container"
      rounded
    >
      <div class="validating-panel-placeholder__left">
        <img
          alt="Level Placeholder"
          class="validating-panel-placeholder__level"
          src="/img/placeholders/level.svg"
        />
      </div>
      <div class="validating-panel-placeholder__right">
        <ContentPlaceholdersText :lines="2" />
        <ContentPlaceholdersText :lines="isMobile ? 1 : 3" />
      </div>
    </ContentPlaceholders>
    <ContentPlaceholders rounded>
      <ContentPlaceholdersText
        v-if="isMobile"
        :lines="3"
        class="validating-panel-placeholder__mobile-description"
      />
    </ContentPlaceholders>
    <template v-if="!isMobile" #footer>
      <div class="validating-panel-placeholder__footer">
        <div class="placeholder-block" />
        <div class="placeholder-block placeholder-block--important" />
      </div>
    </template>
  </PxPanel>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "ValidatingPanelPlaceholder",

  computed: {
    isMobile(): boolean {
      return this.$screen.mdUp;
    },
  },
});
</script>

<style lang="scss" scoped>
.validating-panel-placeholder {
  width: 100%;
  max-width: unset;

  &__container {
    display: flex;
    flex-direction: row;
    padding: 3px 0 4px;

    @include breakpoint-up(md) {
      padding: 5px 100px 4px 93px;
    }
  }

  &__left {
    flex-grow: 0;
    padding-top: 14px;
  }

  &__level {
    @include breakpoint-down(sm) {
      position: relative;
      left: -2px;
      max-width: 140px;
    }
  }

  &__mobile-description {
    margin-top: 25px;

    :deep() .vue-content-placeholders-text__line {
      width: 100%;
      height: 18px;
      margin-top: 14px;

      background-color: $pale-lilac;
      border-radius: 3px;

      &:nth-child(1) {
        max-width: 291px;
      }

      &:nth-child(2) {
        max-width: 261px;
      }

      &:nth-child(3) {
        max-width: 231px;
      }
    }
  }
}

.validating-panel-placeholder__right {
  flex-grow: 1;
  padding-top: 22px;
  padding-left: 26px;

  @include breakpoint-down(sm) {
    padding-top: 25px;
    padding-left: 13px;
  }

  &:nth-child(2) :deep() .vue-content-placeholders-text__line {
    margin-bottom: 17px;

    &:nth-child(1) {
      width: 502px;

      @include breakpoint-down(sm) {
        max-width: 67px;
        height: 18px;
        margin-top: 8px;

        background-color: #8b8fa1;

        opacity: 0.4;
      }
    }

    &:nth-child(2) {
      width: 392px;
    }

    &:nth-child(3) {
      width: 262px;
    }
  }

  .vue-content-placeholders-text {
    &:first-child {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;

      @include breakpoint-down(sm) {
        flex-direction: column;
      }

      :deep() .vue-content-placeholders-text__line {
        height: 25px;

        background-color: $manatee;
      }

      :deep() .vue-content-placeholders-text__line:first-child {
        max-width: 242px;
        margin-right: 22px;

        @include breakpoint-down(sm) {
          width: 100%;
          max-width: 130px;
          height: 18px;

          opacity: 0.4;
        }
      }

      :deep() .vue-content-placeholders-text__line:last-child {
        max-width: 72px;

        @include breakpoint-down(sm) {
          max-width: 100px;
          height: 18px;

          opacity: 0.4;
        }
      }
    }

    &:nth-child(2) {
      margin-top: 15px;

      @include breakpoint-down(sm) {
        margin-top: 8px;
      }
    }
  }
}

.validating-panel-placeholder__footer {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.validating-panel-placeholder__footer .placeholder-block {
  height: 36px;

  &:first-child {
    width: 132px;
  }

  &:last-child {
    width: 164px;
    margin-left: 14px;
  }
}
</style>
