import { generateBaseMutationsFor } from "@/services/store/utils/base-generators";
import {
  ISupporterInvestingLevelState,
  ESupporterInvestingLevelMutations,
} from "./supporter-investing-level-types";
import { IViralLevelSelection } from "@/services/data/viral-level/viral-level.interface";
import { getBaseName } from "@/services/store/utils/get-base-name";
import { MutationTree } from "vuex";
import { ISupporterMetadataEntry } from "./types/supporter-metadata.interface";

export const mutations: MutationTree<ISupporterInvestingLevelState> = {
  ...generateBaseMutationsFor<
    ISupporterInvestingLevelState,
    IViralLevelSelection
  >("levels"),

  /**
   * Update the array that stores all level details.
   */
  [getBaseName(ESupporterInvestingLevelMutations.SET_LEVELS)](state, levels) {
    state.levels = levels;
  },

  /**
   * Update the levels state.
   */
  [getBaseName(ESupporterInvestingLevelMutations.SET_LEVEL)](state, newValue) {
    state.levels = newValue;
  },

  /**
   * Updated the current selected level.
   */
  [getBaseName(ESupporterInvestingLevelMutations.SET_SELECTED_CATEGORY)](
    state,
    newLevel,
  ) {
    state.selectedCategory = newLevel;
  },

  /**
   * Set the interaction variable.
   */
  [getBaseName(ESupporterInvestingLevelMutations.SET_INTERACTION)](
    state,
    newValue,
  ) {
    state.firstInteraction = newValue;
  },

  /**
   * Changes the value of the final level property.
   */
  [getBaseName(ESupporterInvestingLevelMutations.SET_FINAL_LEVEL)](
    state,
    level,
  ) {
    state.finalLevel = level;
  },

  /**
   * Changes the affiliate fields.
   */
  [getBaseName(ESupporterInvestingLevelMutations.SET_AFFILIATE)](
    state,
    affiliate,
  ) {
    state.affiliate = affiliate;
  },

  /**
   * Allows to set the `submitted` field.
   *
   * @param state Store state
   * @param newVal New value
   */
  [getBaseName(ESupporterInvestingLevelMutations.SET_SUBMITTED)](
    state,
    newVal,
  ) {
    state.submitted = newVal;
  },

  /**
   * Set metadata based on given key and value.
   *
   * @param state Viral level state.
   * @param entry
   */
  [getBaseName(ESupporterInvestingLevelMutations.SET_METADATA)](
    state,
    entry: ISupporterMetadataEntry,
  ) {
    state.metadata = {
      ...state.metadata,
      [entry.key]: entry.value,
    };
  },

  /**
   * Remove the given key from the set of metadata.
   *
   * @param state Viral level state.
   * @param key Key to be removed.
   */
  [getBaseName(ESupporterInvestingLevelMutations.REMOVE_METADATA)](
    state,
    key: string,
  ) {
    delete state.metadata[key];
    state.metadata = { ...state.metadata };
  },
};
