import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "passcode-access-modal__wrapper" }
const _hoisted_2 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PxIcon = _resolveComponent("PxIcon")!
  const _component_i18n_t = _resolveComponent("i18n-t")!
  const _component_PxInputPassword = _resolveComponent("PxInputPassword")!
  const _component_ElFormItem = _resolveComponent("ElFormItem")!
  const _component_PxButton = _resolveComponent("PxButton")!
  const _component_ElForm = _resolveComponent("ElForm")!
  const _component_ElDialog = _resolveComponent("ElDialog")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_ElDialog, {
      ref: "modal",
      modelValue: _ctx.innerVisibility,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.innerVisibility) = $event)),
      "append-to-body": true,
      "close-on-click-modal": false,
      "close-on-press-escape": false,
      "show-close": false,
      title: _ctx.modalTitle,
      class: "passcode-access-modal",
      width: "466px"
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_PxIcon, {
            size: 16,
            name: "icon-password-grey"
          }),
          _createVNode(_component_i18n_t, {
            class: "passcode-access-modal__form-description",
            keypath: "common.components.passcodeModal.subtitle.please",
            tag: "span"
          }, {
            enterPasscode: _withCtx(() => [
              _createElementVNode("span", null, _toDisplayString(_ctx.$t("common.components.passcodeModal.subtitle.enterPasscode")), 1)
            ]),
            _: 1
          })
        ]),
        _createVNode(_component_ElForm, {
          ref: "passcodeForm",
          model: _ctx.passcodeForm.data,
          rules: _ctx.rules,
          onSubmit: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["prevent"]))
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", null, [
              _createVNode(_component_ElFormItem, {
                error: _ctx.errors.getMessage('passcode') || '',
                label: _ctx.$t('common.components.passcodeModal.form.field'),
                prop: "passcode"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_PxInputPassword, {
                    modelValue: _ctx.passcodeForm.data.passcode,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.passcodeForm.data.passcode) = $event)),
                    label: _ctx.$t('common.components.passcodeModal.form.field'),
                    placeholder: _ctx.$t('common.components.passcodeModal.form.field'),
                    autocomplete: "new-password"
                  }, null, 8, ["modelValue", "label", "placeholder"])
                ]),
                _: 1
              }, 8, ["error", "label"]),
              (_ctx.noPasscodeMessage)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: "passcode-access-modal__no-passcode-info",
                    innerHTML: _ctx.$t(_ctx.noPasscodeMessage)
                  }, null, 8, _hoisted_2))
                : _createCommentVNode("", true)
            ]),
            _createVNode(_component_PxButton, {
              loading: _ctx.isDataLoading,
              class: "el-button--block passcode-access-modal__submit-button",
              type: "primary",
              onClick: _ctx.makeRequest
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.modalSubmitButtonText), 1)
              ]),
              _: 1
            }, 8, ["loading", "onClick"])
          ]),
          _: 1
        }, 8, ["model", "rules"])
      ]),
      _: 1
    }, 8, ["modelValue", "title"])
  ]))
}