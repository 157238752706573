import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-ee49ee16"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "supporters-view__container" }
const _hoisted_2 = { class: "supporter-registration-form__container" }
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = { class: "supporter-registration-form__cta" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PxInputPassword = _resolveComponent("PxInputPassword")!
  const _component_ElFormItem = _resolveComponent("ElFormItem")!
  const _component_ElInput = _resolveComponent("ElInput")!
  const _component_PxButton = _resolveComponent("PxButton")!
  const _component_ElForm = _resolveComponent("ElForm")!
  const _component_FormPresentationLayout = _resolveComponent("FormPresentationLayout")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_FormPresentationLayout, {
      "content-title": _ctx.viewCopy.contentTitle,
      "description-image-link": _ctx.viewCopy.imageLink,
      "description-image-title": _ctx.viewCopy.imageTitle,
      "description-text": _ctx.viewCopy.imageDescription,
      "has-description-background-image": true,
      "has-return-button": _ctx.hasPreviousPage,
      onPreviousPage: _ctx.onReturnButtonClick
    }, {
      content: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_ElForm, {
            ref: "supporterRegistrationForm",
            model: _ctx.fields,
            rules: _ctx.rules,
            class: "supporter-registration-form__body",
            onValidate: _ctx.updateSubmitState
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ElFormItem, {
                label: _ctx.formCopy.password.label,
                prop: "password1"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_PxInputPassword, {
                    modelValue: _ctx.fields.password1,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.fields.password1) = $event)),
                    label: _ctx.formCopy.password.label,
                    placeholder: _ctx.formCopy.password.placeholder,
                    "show-success-state": true,
                    onInput: _ctx.validateConfirmationField
                  }, null, 8, ["modelValue", "label", "placeholder", "onInput"]),
                  _createElementVNode("span", {
                    class: "el-form-item__bottom-text el-form-item__tip",
                    innerHTML: _ctx.formCopy.password.tip
                  }, null, 8, _hoisted_3)
                ]),
                _: 1
              }, 8, ["label"]),
              _createVNode(_component_ElFormItem, {
                label: _ctx.formCopy.passwordConfirmation.label,
                prop: "password2"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ElInput, {
                    modelValue: _ctx.fields.password2,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.fields.password2) = $event)),
                    disabled: _ctx.passwordIsInvalid,
                    placeholder: _ctx.formCopy.passwordConfirmation.placeholder,
                    class: "el-input--password",
                    type: "password"
                  }, null, 8, ["modelValue", "disabled", "placeholder"])
                ]),
                _: 1
              }, 8, ["label"]),
              _createElementVNode("div", _hoisted_4, [
                _createVNode(_component_PxButton, {
                  disabled: _ctx.isSubmissionDisabled,
                  loading: _ctx.formLoading,
                  type: "primary",
                  onClick: _ctx.onNextButtonClick
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.ctaCopy.next), 1)
                  ]),
                  _: 1
                }, 8, ["disabled", "loading", "onClick"])
              ])
            ]),
            _: 1
          }, 8, ["model", "rules", "onValidate"])
        ])
      ]),
      _: 1
    }, 8, ["content-title", "description-image-link", "description-image-title", "description-text", "has-return-button", "onPreviousPage"])
  ]))
}