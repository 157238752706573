<template>
  <div class="question-panel__wrapper">
    <div v-if="innerValue" class="question-panel__container">
      <div v-if="!showOnlyQuestionContent" class="question-panel__header">
        <p class="question-panel__step">
          {{ currentStepText }}
        </p>
        <p class="question-panel__description">
          {{ questionTitle }}
        </p>
      </div>
      <div class="question-panel__content">
        <template v-if="isQuestionType(freeResponse)">
          <div class="question-panel__content-wrapper">
            <PxTextarea
              v-model="innerValue.value.text"
              :max-chars="textAreaLimit"
              :placeholder="
                $t('affiliateProgram.questions.freeTextPlaceholder')
              "
              :show-counter="true"
              resize="none"
              @focus="onTextareaFocus"
            />
          </div>
        </template>
        <template v-else-if="isQuestionType(singleSelect)">
          <PxRadioGroup
            v-model="innerValue.answers[0]"
            class="question-panel__content-wrapper"
          >
            <div
              v-for="(option, index) in question.answers"
              :key="`question-panel__option-${index}`"
              class="question-panel__answer"
            >
              <ElRadio :label="option.id" :value="option.id">
                <div class="question-panel__answer-wrapper">
                  {{ option.value }}
                  <ElTooltip
                    v-if="option.instructions"
                    data-testid="question-panel-answer-tooltip"
                    placement="top"
                    popper-class="question-panel-answer-tooltip el-abaca-tooltip"
                  >
                    <template #content>
                      <p v-text="option.instructions" />
                    </template>
                    <i class="icon icon-question" @click.prevent />
                  </ElTooltip>
                </div>
              </ElRadio>
            </div>
          </PxRadioGroup>
        </template>
        <template v-else-if="isQuestionType(multiSelect)">
          <ElCheckboxGroup
            v-model="innerValue.answers"
            class="question-panel__content-wrapper"
          >
            <div
              v-for="(option, index) in question.answers"
              :key="`question-panel__option-${index}`"
              class="question-panel__answer"
            >
              <ElCheckbox :value="option.id">
                <div class="question-panel__answer-wrapper">
                  {{ option.value }}
                  <ElTooltip
                    v-if="option.instructions"
                    data-testid="question-panel-answer-tooltip"
                    placement="top"
                    popper-class="question-panel-answer-tooltip el-abaca-tooltip"
                  >
                    <template #content>
                      <p v-text="option.instructions" />
                    </template>
                    <i class="icon icon-question" @click.prevent />
                  </ElTooltip>
                </div>
              </ElCheckbox>
            </div>
          </ElCheckboxGroup>
        </template>
        <template v-else-if="isMoneyType">
          <div
            v-if="needsCurrencyRange"
            class="question-panel__content-wrapper question-panel__content-wrapper--two-column"
          >
            <div class="question-panel__range">
              <span class="question-panel__range-label">
                {{ $t("affiliateProgram.questions.rangeFrom") }}
              </span>
              <PxMoney
                v-model="innerValue.value.min"
                data-testid="question-panel__range--min-value"
              />
            </div>
            <div class="question-panel__range">
              <span class="question-panel__range-label">
                {{ $t("affiliateProgram.questions.rangeTo") }}
              </span>
              <PxMoney
                v-model="innerValue.value.max"
                data-testid="question-panel__range--max-value"
              />
            </div>
          </div>
          <div v-else class="question-panel__content-wrapper">
            <PxMoney v-model="innerValue.value.value" />
          </div>
        </template>
        <template v-else-if="isQuestionType(numeric)">
          <div
            v-if="$user.isEntrepreneur()"
            class="question-panel__content-wrapper"
          >
            <PxNumeric v-model="innerValue.value.value" />
          </div>
          <div
            v-if="$user.isSupporter()"
            class="question-panel__content-wrapper question-panel__content-wrapper--two-column"
          >
            <div class="question-panel__range">
              <span class="question-panel__range-label">
                {{ $t("affiliateProgram.questions.rangeFrom") }}
              </span>
              <PxNumeric v-model="innerValue.value.min" />
            </div>
            <div class="question-panel__range">
              <span class="question-panel__range-label">
                {{ $t("affiliateProgram.questions.rangeTo") }}
              </span>
              <PxNumeric v-model="innerValue.value.max" />
            </div>
          </div>
        </template>
        <template v-if="isQuestionType(dateResponse)">
          <div class="question-panel__content-wrapper">
            <PxDateSelect v-model="innerValue.value.date" />
          </div>
        </template>
      </div>
    </div>
    <div v-if="!showOnlyQuestionContent" class="question-panel__info">
      <div class="question-panel__info-wrapper">
        <PxIcon
          :size="noticeIconSize"
          class="question-panel__info-icon"
          name="icon-information"
        />
        <p class="question-panel__info-title">
          {{ skipQuestionCopy }}
        </p>
        <p
          v-if="$screen.mdUp && showNotice"
          class="question-panel__info-notice"
        >
          {{ noticeText }}
        </p>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import QuestionPanel from "@/modules/affiliates/mixins/question-panel.mixin";

export default defineComponent({
  name: "QuestionPanel",

  mixins: [QuestionPanel],
});
</script>

<style lang="scss" scoped>
.question-panel__container {
  margin: 0 auto;

  @include breakpoint-up(md) {
    width: 611px;
    background-color: $white;
    border: solid 1px $pale-grey;
    border-radius: 2px;
    box-shadow: 0 2px 11px 0 $black-5;
  }

  @include breakpoint-down(sm) {
    background-color: $alabaster;
  }
}

.question-panel__header {
  min-height: 98px;
  padding: 18px;

  @include breakpoint-up(sm) {
    padding: 15px 60px;
    background-image: linear-gradient(
      to bottom,
      $white,
      $white 50%,
      $alabaster
    );
    border-bottom: 1px solid $athens-gray;
  }
}

.question-panel__content {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding: 0 15px 120px;

  @include breakpoint-up(md) {
    padding: 35px 80px;
  }

  :deep() .el-input__inner[type="number"]::-webkit-inner-spin-button,
  :deep() .el-input__inner[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
  }
}

.question-panel__content-wrapper {
  display: grid;
  grid-row-gap: 10px;
  width: 100%;

  @include breakpoint-up(md) {
    width: 460px;
  }

  &.question-panel__content-wrapper--two-column {
    @include breakpoint-up(md) {
      grid-template-columns: repeat(2, auto);
    }
  }

  :deep() .el-textarea__inner {
    width: 100%;
    height: 194px;
    padding: 12px 16px;
    font-size: 14px;
    line-height: 1.71;
    color: $gun-powder;
    resize: none;
    border: 1px solid $mischka;
    border-radius: 2px;
    box-shadow: inset 0 0 5px 0 rgba(0, 0, 0, 0.07);
  }

  :deep() .date-select__wrapper {
    justify-content: space-evenly;

    @include breakpoint-up(md) {
      justify-content: center;
    }
  }
}

.question-panel__step {
  margin-bottom: 11px;
  font-size: 15px;
  color: $manatee;
  text-align: center;
}

.question-panel__description {
  @include grotesk(semiBold);

  margin: 0;
  font-size: 17px;
  line-height: 30px;
  text-align: center;
}

.question-panel__range {
  flex: 1 0 100%;
  margin: 5px 0;

  @include breakpoint-up(md) {
    flex: 1 0 40%;
    margin: 5px;
  }

  &-label {
    @include grotesk(bold);

    font-size: 12px;
    color: $manatee;
  }
}

.question-panel__answer {
  flex: 1 0 100%;
  min-height: 50px;

  cursor: pointer;
  background-color: $white;
  border: 1px solid $pale-grey;
  border-radius: 4px;
  box-shadow: 0 2px 11px 0 $black-5;

  @include breakpoint-up(md) {
    min-height: 38px;
    background-image: linear-gradient(to bottom, $white, #f6f6f6 83%),
      linear-gradient(to bottom, $ebony-clay-10, $ebony-clay-10 94%);
    box-shadow: none;
  }
}

.el-radio-group.question-panel__content-wrapper {
  width: auto;
  min-width: 330px;
  margin: 0 auto;
}

.question-panel__answer :deep() .el-radio {
  display: grid;
  grid-template-areas: "label input";
  grid-template-columns: auto 23px;
  grid-column-gap: 10px;
  width: 100%;
  height: 100%;
  padding: 5px 10px 5px 14px;
  margin: 0;

  @include breakpoint-down(sm) {
    padding: 12px 10px 12px 14px;
  }

  span {
    @include grotesk(medium);

    padding: 0;
    font-size: 15px;
    line-height: 24px;
    color: $ebony-clay;
  }

  &__original {
    cursor: pointer;
  }
}

.question-panel__answer :deep() .el-radio__label {
  grid-area: label;
  white-space: normal;
}

.question-panel__answer :deep() .el-radio__input {
  grid-area: input;
  width: 23px;
  height: 23px;
  margin-top: 1px;

  .el-radio__inner {
    width: 22px;
    height: 22px;
    background-color: $white;
    border: solid 1px $ebony-clay-10;
    border-radius: 50%;
    box-shadow: inset 0 0 4px 0 $black-10;

    &::after {
      top: 0;
      left: 0;
      width: 20px;
      height: 20px;
      background-color: transparent;
      background-image: url("#{$assetsPath}/img/icons/icon-check-white-circle.svg");
      background-position: center;
      border: none;
      box-shadow: none;
    }
  }

  &.is-checked .el-radio__inner {
    background: none;
    border-color: transparent;
    box-shadow: none;
  }
}

.question-panel__answer :deep() .el-checkbox {
  display: grid;
  grid-template-areas: "label input";
  grid-template-columns: auto 23px;
  grid-column-gap: 10px;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 5px 10px 5px 14px;
  margin: 0;

  @include breakpoint-down(sm) {
    padding: 12px 10px 12px 14px;
  }

  span {
    @include grotesk(semiBold);

    padding: 0;
    font-size: 15px;
    line-height: 24px;
    color: $ebony-clay;
  }

  .el-checkbox__input span {
    width: 20px;
    height: 20px;
  }

  .is-checked span {
    background-color: #5696f0;
    border-color: #5696f0;
  }
}

.question-panel__answer :deep() .el-tooltip__trigger {
  width: 14px;
  height: 14px;
}

.question-panel__answer-wrapper {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;

  :deep() .icon-question {
    top: 0;
    left: 0;
  }
}

.question-panel__answer :deep() .el-checkbox__label {
  grid-area: label;
  white-space: normal;
}

.question-panel__answer :deep() .el-checkbox__input {
  display: flex;
  grid-area: input;
  align-items: center;
  width: 23px;
  height: 23px;
  margin-top: 1px;

  &__inner {
    width: 22px;
    height: 22px;
    background-color: $white;

    &::after {
      width: 22px;
      height: 22px;
      background-image: url("#{$assetsPath}/img/icons/icon-check-white-square.svg");
      background-position: center;
      opacity: 0;
      transition: opacity 0.15ms;
    }
  }

  &__input.is-checked .el-checkbox__inner::after {
    opacity: 1;
    transition: opacity 0.15ms;
  }
}

.question-panel__info {
  display: flex;
  flex-direction: column;
  width: 611px;
  padding: 16px 0 16px 1px;
  margin: 0 auto;

  &-wrapper {
    display: grid;
    grid-template:
      "icon info1"
      "icon info2";
    gap: 12px 7px;
    justify-content: flex-start;
  }

  :deep() .px-icon {
    grid-area: icon;
  }

  &-title {
    position: relative;
    top: 3px;
    letter-spacing: -0.14px;
    margin-bottom: 0;
    font-size: 13px;
    font-style: italic;
    line-height: 1;
    grid-area: info1;
  }

  &-notice {
    grid-area: info2;
    font-size: 13px;
    font-style: italic;
    line-height: 1;
    color: $manatee;
  }
}
</style>
<style lang="scss">
.question-panel-answer-tooltip {
  max-width: 300px;
  padding: 9px 15px 11px 15px;
  text-transform: none;

  p {
    @include grotesk(semiBold);

    margin: 0;
    line-height: 1.43;
    font-size: 14px;
  }
}
</style>
