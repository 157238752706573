import {
  GenericProvider,
  EProviderFeatures,
} from "@/services/data/generic-provider";
import { baseAPIUrl } from "@/services/utils/utils";
import {
  IUserRecoveryRequest,
  IUserRecoveryResponse,
} from "./user-recovery.interface";

class UserRecoveryProvider extends GenericProvider<
  IUserRecoveryResponse,
  IUserRecoveryRequest
> {
  constructor() {
    super(`${baseAPIUrl}/user/recover`, [EProviderFeatures.UPDATE]);
  }
}

export const userRecoveryProvider = new UserRecoveryProvider();
