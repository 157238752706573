import {
  ICapitalExplorerData,
  ICapitalExplorerVisitorData,
} from "@/modules/capital-explorer/services/data/capital-explorer/capital-explorer.interface";
import { IBaseState } from "@/services/store/store-types";

/**
 * Represents the store structure for the capital-explorer module
 */
export interface ICapitalExplorerState extends IBaseState {
  data: ICapitalExplorerData | null;
  visitorData: ICapitalExplorerVisitorData | null;
  termsAccepted: boolean;
  passcodes: { [key: string]: string };
}

export enum ECapitalExplorerState {
  NAMESPACE = "capitalExplorer",
}

export enum ECapitalExplorerActions {
  FETCH_DATA = "capitalExplorer/fetchData",
  FETCH_AUTH_USER_SUBMISSION = "capitalExplorer/fetchAuthUserSubmission",
  FETCH_USER_SUBMISSION = "capitalExplorer/fetchUserSubmission",
  CHECK_CONSENT = "capitalExplorer/checkConsent",
  GRANT_CONSENT = "capitalExplorer/grantConsent",
  PATCH = "capitalExplorer/patch",
}

export enum ECapitalExplorerMutations {
  SET_DATA = "SET_DATA",
  SET_VISITOR_DATA = "SET_VISITOR_DATA",
  SET_ERROR = "SET_ERROR",
  SET_LOADING = "SET_LOADING",
  SET_TERMS_ACCEPTED = "SET_TERMS_ACCEPTED",
  SET_PASSCODES = "SET_PASSCODES",
}
