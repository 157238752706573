<template>
  <div :class="`px-notice-bar px-notice-bar--${type}`">
    <div class="px-notice-bar__wrapper">
      <div class="px-notice-bar__content">
        <slot name="content">
          <h3 class="px-notice-bar__title">
            {{ title }}
          </h3>
        </slot>
      </div>
      <div class="px-notice-bar__cta">
        <slot name="cta" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
/**
 * TODO: Merge this component into the original PxNotice while being carefull about regressions.
 */
import { defineComponent } from "vue";

export default defineComponent({
  name: "PxNoticeBar",

  props: {
    title: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "default",
      validator: (val: string) => {
        return ["default", "info"].includes(val);
      },
    },
  },
});
</script>

<style lang="scss" scoped>
.px-notice-bar {
  position: relative;
}

.px-notice-bar__wrapper {
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  justify-content: space-between;
  padding: 5px 20px;
  background-color: $athens-gray;
  border: solid 1px rgba($manatee, 0.1);
  border-radius: 4px;
}

.px-notice-bar__content {
  display: inline-flex;
  flex-flow: row wrap;
  align-items: center;
}

.px-notice-bar__title {
  @include grotesk(regular);

  font-size: 15px;
  line-height: 22px;

  @include breakpoint-up(md) {
    letter-spacing: -0.15px;
  }
}

.px-notice-bar--info .px-notice-bar__wrapper {
  background-color: rgba($malibu, 0.08);
  border: solid 1px rgba($malibu, 0.2);
}
</style>
