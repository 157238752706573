<template>
  <header :style="{ backgroundColor, color: textColor }" class="px-list-header">
    <h4>
      {{ title }}
    </h4>
    <div v-if="badgeNumber" class="px-list-header__badge">
      <p>
        {{ badgeNumber }}
      </p>
    </div>
  </header>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";

export default defineComponent({
  name: "PxListHeader",

  props: {
    title: {
      type: String,
      required: true,
    },

    badgeNumber: {
      type: [Number, null] as PropType<number | null>,
      default: null,
    },

    backgroundColor: {
      type: String,
      default: "#fafafa",
    },

    textColor: {
      type: String,
      default: "#272740",
    },
  },
});
</script>

<style lang="scss" scoped>
.px-list-header {
  display: flex;
  align-items: center;
  gap: 8px;

  padding: 16px 0 16px 24px;

  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  border-bottom: 1px solid $turkish-angora;

  h4 {
    font-size: 20px;
    line-height: 32px;
    margin: 0;
  }

  &__badge {
    position: relative;
    top: 1px;

    width: 35px;
    height: 28px;
    display: flex;
    justify-content: center;
    align-items: center;

    background-color: $athens-gray;
    border-radius: 100px;

    p {
      @include grotesk(bold);

      color: $manatee;
      font-size: 12px;
    }
  }
}
</style>
