<template>
  <ElDialog
    ref="modal"
    v-model="innerVisibility"
    :append-to-body="true"
    :before-close="beforeCloseHandler"
    class="el-dialog--no-header el-dialog--compact-footer matching-directory-member-modal"
    width="500px"
  >
    <PxModalMessage :visible="hasServerError" />
    <i18n-t
      class="matching-directory-member-modal__title"
      keypath="matching.matchingInterest.directoryMemberModal.title"
      tag="h2"
    >
      <template #directoryIcon>
        <PxIcon
          :size="$options.static.iconSize"
          class="matching-directory-member-modal__icon"
          name="icon-directory-default"
        />
      </template>
    </i18n-t>
    <p class="matching-directory-member-modal__description">
      {{ $t(`matching.matchingInterest.directoryMemberModal.description`) }}
    </p>
    <ElDivider />
    <template #footer>
      <PxButton
        v-bind="interestButtonProps"
        @click="onClickAddInterestButton"
      />
      <template v-if="$screen.lgUp">
        <span class="matching-directory-member-modal__text">
          {{ $t("common.or") }}
        </span>
        <PxButton
          v-bind="addToListButtonProps"
          @click.stop="onShowListManagementModal"
        />
      </template>
    </template>
  </ElDialog>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import ElDialogMixin from "@/mixins/el-dialog.mixin";
import {
  EPxButtonSize,
  EPxButtonType,
} from "@/components/px-button/px-button.types";

import { EListManagementActions } from "@/modules/company-lists/services/store/list-management/list-management.types";

export default defineComponent({
  name: "PxMatchingInterestDirectoryMemberModal",

  mixins: [ElDialogMixin],

  props: {
    hasServerError: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },

    companyUid: {
      type: String,
      required: true,
    },
  },

  static: {
    interestButton: {
      icon: "connect",
      size: EPxButtonSize.SMALL,
      type: EPxButtonType.GREEN,
    },

    addToListButton: {
      icon: "icon-add-to-list",
      size: EPxButtonSize.SMALL,
      type: EPxButtonType.DARK_BLUE,
    },
    iconSize: 24,
  },

  computed: {
    addToListButtonProps(): any {
      return {
        ...this.$options.static.addToListButton,
        label: this.$t("common.components.default.cta.addToList"),
      };
    },

    interestButtonProps(): any {
      return {
        ...this.$options.static.interestButton,
        label: this.$t(
          `matching.matchingInterest.directoryMemberModal.submitButton`,
        ) as string,
      };
    },
  },

  methods: {
    updateClosedState() {
      this.$emit("update:visibility", false);
    },
    beforeCloseHandler() {
      this.updateClosedState();
    },
    onClickCancelButton() {
      this.updateClosedState();
    },
    onClickAddInterestButton() {
      this.$emit("connect");
    },
    onShowListManagementModal() {
      // Select self
      this.$store.dispatch(EListManagementActions.ADD_COMPANY, this.companyUid);

      // Emit root action, should be available on context
      this.$emitter.emit("show-list-management-modal");
      this.updateClosedState();
    },
  },
});
</script>

<style lang="scss">
.matching-directory-member-modal {
  /* Override no header default styles */
  margin-top: 15vh !important;

  .el-dialog__header .el-dialog__headerbtn {
    top: 0;
    right: 1px;

    z-index: z("floaters");

    background-image: url("/img/icons/cross.svg");

    @include breakpoint-up(md) {
      top: 25px;
      right: 31px;
    }
  }

  .el-dialog__body {
    max-width: 500px;
    padding: 27px 10px 0 10px;
    margin: auto;
    letter-spacing: -0.15px;

    .el-divider--horizontal {
      width: 90%;
      margin: 32px auto 0 auto;
    }
  }

  .el-dialog__footer {
    position: relative;
    padding-top: 17px;
    padding-bottom: 15px;

    &::after {
      position: absolute;
      top: -1px;
      right: 40px;
      left: 40px;
      height: 1px;

      content: "";
      background-color: $athens-gray;

      /* Hide line separator instead of removing since it might be useful in the future */
      opacity: 0;
    }

    @include breakpoint-up(md) {
      display: flex;
      justify-content: center;
    }

    .el-button--dark-blue {
      position: relative;
      left: 8px;
      padding: 0 9px;
    }

    .el-button--green {
      position: relative;
      right: 10px;
      width: 161px;
    }
  }

  &__text {
    position: relative;
    top: 2px;
    right: 3px;
  }

  &__icon {
    position: relative;
    top: 5px;
  }

  &__title {
    @include grotesk(semiBold);

    padding: 0 70px;
    font-size: to-rem(18px);
    line-height: 28px;
    color: $ebony-clay;
    text-align: center;

    @include breakpoint-up(md) {
      font-size: to-rem(20px);
    }
  }

  &__description {
    padding: 0 54px;
    margin: 17px 0 26px 0;
    font-size: to-rem(15px);
    line-height: 22px;
    color: $ebony-clay;
    text-align: center;
    letter-spacing: -0.28px;
  }
}
</style>
