import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-52cafc02"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "px-stage-status-item__title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PxIcon = _resolveComponent("PxIcon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([{
      'px-stage-status-item--active': _ctx.active,
      'px-stage-status-item--clickable': _ctx.isClickable,
    }, "px-stage-status-item"]),
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isClickable ? _ctx.$emit('status-click') : null))
  }, [
    _createVNode(_component_PxIcon, {
      name: _ctx.icon,
      size: _ctx.iconSize
    }, null, 8, ["name", "size"]),
    _createElementVNode("h3", _hoisted_1, _toDisplayString(_ctx.title), 1)
  ], 2))
}