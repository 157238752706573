import { IAffiliateSubmissions } from "@/modules/submissions/services/data/submissions/submissions.interface";
import {
  GenericProvider,
  Provider,
  EProviderFeatures,
} from "@/services/data/generic-provider";
import { baseAPIUrl } from "@/services/utils/utils";

@Provider(`${baseAPIUrl}/affiliate-submissions`, [
  EProviderFeatures.LIST,
  EProviderFeatures.CREATE,
])
class UserAffiliateSubmissionsProvider extends GenericProvider<IAffiliateSubmissions> {}

export const userAffiliateSubmissionsProvider =
  new UserAffiliateSubmissionsProvider();
