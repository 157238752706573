<template>
  <div
    :class="{
      'px-stage-status-item--active': active,
      'px-stage-status-item--clickable': isClickable,
    }"
    class="px-stage-status-item"
    @click="isClickable ? $emit('status-click') : null"
  >
    <PxIcon :name="icon" :size="iconSize" />
    <h3 class="px-stage-status-item__title">
      {{ title }}
    </h3>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

/**
 * Item to identify the state of the cell on the milestone grid
 */
export default defineComponent({
  name: "PxStageStatusItem",

  props: {
    title: {
      required: true,
      type: String,
    },
    icon: {
      required: true,
      type: String,
    },
    active: {
      type: Boolean,
      default: false,
    },
    iconSize: {
      type: Number,
      default: 26,
    },
    isClickable: {
      type: Boolean,
      default: true,
    },
  },
});
</script>

<style lang="scss" scoped>
.px-stage-status-item {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 8px;
  border-radius: 4px;

  &__title {
    @include grotesk(regular);

    position: relative;
    bottom: 1px;
    margin: 0 0 0 5px;
    font-size: 12px;
    font-weight: 600;
    line-height: 28px;
    color: $manatee;
    text-transform: uppercase;
    letter-spacing: 0.1px;
  }

  img {
    position: relative;
    right: 1px;
    bottom: 1px;
  }

  &--active {
    padding: 1px 7px 0 8px;
    background-color: $white;
    box-shadow: 0 2px 4px -1px rgba($ebony-clay, 0.2);

    animation-name: bounce;
    animation-duration: 1.5s;
    animation-iteration-count: infinite;

    .px-icon {
      filter: brightness(0.2);
    }

    .px-stage-status-item__title {
      color: $ebony-clay;
    }
  }

  &--clickable:hover {
    cursor: pointer;
  }
}
</style>
