import { ICompanyList } from "@/modules/company-lists/services/data/company-list/company-list.interface";
import {
  ECompanySharedListAction,
  ECompanySharedListState,
  ICompanySharedListState,
} from "@/modules/company-lists/services/store/company-shared-list/company-shared-list.interface";
import { GenericState } from "@/services/store/generic/generic-state.module";
import { sharedCompanyListProvider } from "@/modules/company-lists/services/data/company-list/shared-company-list.provider";
import { GenericProvider } from "@/services/data/generic-provider";
import { actions } from "@/modules/company-lists/services/store/company-shared-list/company-shared-list.actions";
import {
  ECompanySharedListMutation,
  mutations,
} from "@/modules/company-lists/services/store/company-shared-list/company-shared-list.mutations";
import {
  ECompanySharedListGetters,
  getters,
} from "@/modules/company-lists/services/store/company-shared-list/company-shared-list.getters";
import { getGenericInitialState } from "@/services/store/generic/generic-state";

// initial state of the module
const initialState: ICompanySharedListState = {
  ...getGenericInitialState<ICompanyList>(),
};

export class CompanySharedListState extends GenericState<
  ICompanySharedListState,
  ICompanyList
> {
  static Action = GenericState.mapNamespaceEnum(
    ECompanySharedListAction,
    ECompanySharedListState.NAMESPACE,
  );

  static Getter = GenericState.mapNamespaceEnum(
    ECompanySharedListGetters,
    ECompanySharedListState.NAMESPACE,
  );

  static Mutation = GenericState.mapNamespaceEnum(
    ECompanySharedListMutation,
    ECompanySharedListState.NAMESPACE,
  );

  constructor(provider: GenericProvider<ICompanyList>) {
    super({ provider });

    this.state = initialState;
    this.actions = actions;
    this.mutations = mutations;
    this.getters = getters;
  }
}

export const companySharedListModule = new CompanySharedListState(
  sharedCompanyListProvider,
);
