import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "milestone-form-plan" }
const _hoisted_2 = { class: "milestone-form-plan__description" }
const _hoisted_3 = { class: "milestone-form-plan__fields" }
const _hoisted_4 = { class: "milestone-form-plan__inputs" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PxIcon = _resolveComponent("PxIcon")!
  const _component_i18n_t = _resolveComponent("i18n-t")!
  const _component_ElCheckbox = _resolveComponent("ElCheckbox")!
  const _component_ElTooltip = _resolveComponent("ElTooltip")!
  const _component_ElFormItem = _resolveComponent("ElFormItem")!
  const _component_PxTextarea = _resolveComponent("PxTextarea")!
  const _component_PxMoney = _resolveComponent("PxMoney")!
  const _component_PxDatePicker = _resolveComponent("PxDatePicker")!
  const _component_ElForm = _resolveComponent("ElForm")!
  const _component_MilestonePlanConfirmationDialog = _resolveComponent("MilestonePlanConfirmationDialog")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ElForm, {
      ref: "form",
      model: _ctx.form,
      rules: _ctx.rules
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t("milestonePlanner.milestonePlan.planForm.description.owner")), 1),
        _createElementVNode("div", _hoisted_3, [
          (!_ctx.isFutureMilestone)
            ? (_openBlock(), _createBlock(_component_ElFormItem, {
                key: 0,
                required: false,
                prop: "critical"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ElCheckbox, {
                    modelValue: _ctx.form.critical,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.form.critical) = $event)),
                    class: "milestone-form-plan__checkbox"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_i18n_t, {
                        keypath: `milestonePlanner.milestonePlan.planForm.fields.critical`,
                        tag: "p"
                      }, {
                        criticalIcon: _withCtx(() => [
                          _createVNode(_component_PxIcon, {
                            size: 22,
                            class: "milestone-form-plan__critical",
                            name: "critical-milestone"
                          })
                        ]),
                        text: _withCtx(() => [
                          _createVNode(_component_i18n_t, {
                            keypath: `milestonePlanner.milestonePlan.planForm.fields.criticalMilestone`,
                            tag: "span"
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 8, ["modelValue"]),
                  _createVNode(_component_ElTooltip, {
                    content: _ctx.criticalTooltip,
                    placement: "top",
                    "popper-class": "milestone-form-plan__tooltip el-abaca-tooltip"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_PxIcon, {
                        size: 14,
                        name: "question"
                      })
                    ]),
                    _: 1
                  }, 8, ["content"])
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          _createVNode(_component_ElFormItem, {
            label: _ctx.$t('milestonePlanner.milestonePlan.planForm.fields.strategy'),
            class: "milestone-form-plan__textarea milestone-form-plan__strategy",
            prop: "strategy"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_PxTextarea, {
                ref: "strategy",
                modelValue: _ctx.form.strategy,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.form.strategy) = $event)),
                "max-chars": _ctx.rules.strategy.max,
                placeholder: 
              _ctx.$t(
                'milestonePlanner.milestonePlan.planForm.placeholders.strategy',
                { categoryName: _ctx.categoryName },
              )
            ,
                "show-counter": ""
              }, null, 8, ["modelValue", "max-chars", "placeholder"])
            ]),
            _: 1
          }, 8, ["label"]),
          _createVNode(_component_ElFormItem, {
            class: "milestone-form-plan__textarea milestone-form-plan__outcomes",
            prop: "outcomes"
          }, {
            label: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("milestonePlanner.milestonePlan.planForm.fields.outcomes")) + " ", 1),
              _createVNode(_component_ElTooltip, {
                content: _ctx.outcomesTooltip,
                placement: "top",
                "popper-class": "milestone-form-plan__tooltip el-abaca-tooltip"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_PxIcon, {
                    size: 14,
                    name: "question"
                  })
                ]),
                _: 1
              }, 8, ["content"])
            ]),
            default: _withCtx(() => [
              _createVNode(_component_PxTextarea, {
                ref: "outcomes",
                modelValue: _ctx.form.outcomes,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.form.outcomes) = $event)),
                "max-chars": _ctx.rules.outcomes.max,
                placeholder: 
              _ctx.$t(
                'milestonePlanner.milestonePlan.planForm.placeholders.outcomes',
                { question: _ctx.outcomesQuestion },
              )
            ,
                "show-counter": ""
              }, null, 8, ["modelValue", "max-chars", "placeholder"])
            ]),
            _: 1
          }),
          _createVNode(_component_ElFormItem, {
            label: 
            _ctx.$t('milestonePlanner.milestonePlan.planForm.fields.resources')
          ,
            class: "milestone-form-plan__textarea milestone-form-plan__resources",
            prop: "resources"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_PxTextarea, {
                ref: "resources",
                modelValue: _ctx.form.resources,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.form.resources) = $event)),
                "max-chars": _ctx.rules.resources.max,
                placeholder: 
              _ctx.$t(
                'milestonePlanner.milestonePlan.planForm.placeholders.resources',
                { categoryName: _ctx.categoryName },
              )
            ,
                "show-counter": ""
              }, null, 8, ["modelValue", "max-chars", "placeholder"])
            ]),
            _: 1
          }, 8, ["label"]),
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_ElFormItem, {
              label: 
              _ctx.$t(
                'milestonePlanner.milestonePlan.planForm.fields.financesNeeded',
              )
            ,
              class: "milestone-form-plan__numeric",
              prop: "financesNeeded"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_PxMoney, {
                  ref: "financesNeeded",
                  modelValue: _ctx.form.financesNeeded,
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.form.financesNeeded) = $event))
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }, 8, ["label"]),
            _createVNode(_component_ElFormItem, { prop: "targetDate" }, {
              default: _withCtx(() => [
                _createVNode(_component_PxDatePicker, {
                  value: _ctx.form.targetDate,
                  "onUpdate:value": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.form.targetDate) = $event)),
                  label: 
                _ctx.$t('milestonePlanner.milestonePlan.planForm.fields.targetDate')
              ,
                  tooltip: _ctx.targetDateTooltip,
                  class: "milestone-form-plan__date"
                }, null, 8, ["value", "label", "tooltip"])
              ]),
              _: 1
            })
          ])
        ])
      ]),
      _: 1
    }, 8, ["model", "rules"]),
    _createVNode(_component_MilestonePlanConfirmationDialog, {
      ref: "discardChangesDialog",
      visibility: _ctx.isDiscardingChanges,
      "onUpdate:visibility": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.isDiscardingChanges) = $event)),
      onConfirmed: _ctx.resetForm
    }, null, 8, ["visibility", "onConfirmed"]),
    (_ctx.milestone)
      ? (_openBlock(), _createBlock(_component_MilestonePlanConfirmationDialog, {
          key: 0,
          ref: "deletePlanDialog",
          visibility: _ctx.isDeleting,
          "onUpdate:visibility": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.isDeleting) = $event)),
          "is-form-invalid": _ctx.formIsInvalid,
          "is-plan-published": _ctx.milestone.plan_published,
          planned: true,
          onConfirmed: _ctx.deletePlan,
          onSaveAsDraft: _cache[8] || (_cache[8] = ($event: any) => (_ctx.submitPlan({ toPublish: false })))
        }, null, 8, ["visibility", "is-form-invalid", "is-plan-published", "onConfirmed"]))
      : _createCommentVNode("", true)
  ]))
}