import { Module } from "vuex";
import { make } from "vuex-pathify";
import cloneDeep from "lodash/cloneDeep";

import { RootState } from "@/services/store/root-state";

import { IGenericSupporterState } from "./supporter.types";
import { supporterActions } from "./supporter.actions";

const state: IGenericSupporterState = {
  loading: false,
  error: null,
  data: null,
};

export const supporterModuleFactory = (): Module<
  IGenericSupporterState,
  RootState
> => {
  return {
    namespaced: true,
    state: cloneDeep(state),
    actions: supporterActions,
    mutations: make.mutations(state),
    getters: make.getters(state),
  };
};
