import { featureManager } from "@/services/feature-manager";
import { DirectiveBinding } from "vue";

/**
 * This directive removes a DOM node if the feature
 * isn't enabled.
 */
export default {
  beforeMount(el: HTMLElement, binding: DirectiveBinding, vnode: any) {
    const feature = binding.value;

    if (!featureManager.isEnabled(feature)) {
      if (!vnode.el || !vnode.el.parentElement) {
        return;
      }

      vnode.el.parentElement.removeChild(vnode.el);
    }
  },
};
