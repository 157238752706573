<template>
  <div :class="{ 'is-negative': remainingChars < 0 }" class="counter">
    <span>
      {{ remainingChars }}
    </span>
    <span v-if="showLabel">
      {{ remainingCharsCopy }}
    </span>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

/**
 * Component that show the remaining chars.
 */
export default defineComponent({
  name: "PxCounter",

  props: {
    /**
     * Remaining chars.
     */
    remainingChars: {
      type: Number,
      required: true,
    },

    /**
     * When set to `true` only the number
     * is shown.
     */
    showLabel: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    remainingCharsCopy(): string {
      return ` ${this.$t("common.remainingChars")}`;
    },
  },
});
</script>

<style lang="scss" scoped>
.counter {
  position: relative;
  display: inline-block;

  color: $manatee;

  span {
    @include grotesk(regular, italic);

    font-size: to-rem(13px);
  }
}

.counter.is-negative span {
  color: $wild-watermelon;
}
</style>
