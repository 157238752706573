<template>
  <div class="mobile-level-select__wrapper">
    <div :style="elementStyles" class="mobile-level-select">
      <div class="mobile-level-select__container">
        <LevelCard
          v-for="details in sortedDetails"
          :key="details.id"
          :details="details"
          :category-color="currentCategory.color"
          :value="details.level.value <= currentLevel"
          :class="{
            'is-visible': details.level.value <= currentLevel + 1,
          }"
          class="mobile-level-select__card"
          @change-level="
            (value) => onCardSelectChange(details.level.value, value)
          "
          @see-criteria="onSeeCriteria(details)"
        />
      </div>
    </div>
    <div v-if="currentLevel === 0" class="mobile-level-select__info-wrapper">
      <div class="mobile-level-select__behavior">
        {{ $t("selfAssessment.mobileAssessment.info.behavior") }}
      </div>
      <div class="mobile-assessment-skip-tip">
        <PxIcon :size="14" name="information--light-gray" />
        <p class="mobile-assessment-skip-tip__text">
          {{ $t("selfAssessment.mobileAssessment.info.skipTip") }}
        </p>
      </div>
    </div>
    <CriteriaModal
      v-model:visibility="criteriaModalVisibility"
      :category-level="selectedCategoryLevel"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import anime from "animejs";

import { getElementAbsoluteHeight } from "@/services/utils/html";

import LevelCard from "@/modules/self-assessment/components/level-card/level-card.vue";
import CriteriaModal from "@/modules/self-assessment/components/criteria-modal/criteria-modal.vue";
import {
  ICategoryDetail,
  ICategory,
} from "@/services/data/category/category.interface";
import { EViralLevelGetters } from "@/services/store/viral-level/viral-level-types";

const CARD_SELECTOR = ".mobile-level-select__card";
const CONTAINER_SELECTOR = ".mobile-level-select__container";
const VISIBLE_CARDS_SELECTOR = `${CARD_SELECTOR}.is-visible`;

const ADDITIONAL_TOP_GAP = 20;
const ANIMATION_SCROLL_TIME = 230;

export default defineComponent({
  name: "MobileLevelSelect",

  components: {
    LevelCard,
    CriteriaModal,
  },

  props: {
    /**
     * Current category details.
     */
    categoryDetails: {
      type: Array as () => any[],
      required: true,
    },

    /**
     * Current selected level.
     */
    currentLevel: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      totalHeight: 0,
      maxHeight: 0,

      criteriaModalVisibility: false,
      selectedCategoryLevel: {},

      categoryChanged: false,
    };
  },

  computed: {
    /**
     * Current selected category.
     */
    currentCategory(): ICategory {
      return this.$store.getters[EViralLevelGetters.CURRENT_CATEGORY];
    },

    /**
     * Order the category details by level, in a descendent way.
     */
    sortedDetails(): Array<ICategoryDetail> {
      const rawDetails = [...(this.categoryDetails as Array<ICategoryDetail>)];
      return rawDetails.sort((a, b) => b.level.value - a.level.value);
    },

    elementStyles() {
      return {
        height: `${this.totalHeight}px`,
        maxHeight: `${this.maxHeight}px`,
      };
    },
  },

  watch: {
    /**
     * When the category changes animate the view
     * to positioning the cards correctly.
     */
    categoryDetails: {
      deep: true,
      async handler() {
        this.categoryChanged = true;
        this.animateView();
      },
    },

    /**
     * When the level changes animate the view
     * to positioning the cards correctly.
     */
    async currentLevel(newVal, oldVal) {
      // In situations when the category changes but the level keeps
      // the same, when the level changes the animation won't play,
      // so it's required to cancel the `categoryChanged` flag first.
      if (newVal !== oldVal) {
        this.categoryChanged = false;
      }
      // When the category changes the animation is canceled to
      // prevent it to run twice.
      else if (this.categoryChanged) {
        this.categoryChanged = false;
        return;
      }

      this.animateView();
    },
  },

  /**
   * Compute the total height of the view and register
   * a resize event to adjust the view height.
   */
  mounted() {
    this.computeTotalHeight();
    this.computeMaxHeight();

    window.addEventListener("resize", this.animateView);
  },

  /**
   * Before destroy remove the event listener on the
   * resize event.
   */
  beforeUnmount() {
    window.removeEventListener("resize", this.animateView);
  },

  methods: {
    /**
     * Handle the card select change and set the
     * current level
     */
    onCardSelectChange(level: number, checked: boolean) {
      const newLevel = checked ? level : level - 1;
      this.$emit("change-level", newLevel);
    },

    /**
     * Compute the max height required in order to all
     * visible cards have space on screen.
     */
    computeMaxHeight() {
      if (!this.$el) {
        return;
      }

      const allVisibleCards = [
        ...this.$el.querySelectorAll(VISIBLE_CARDS_SELECTOR),
      ] as Array<HTMLElement>;

      const totalCardsHeight = allVisibleCards.reduce(
        (prevVal, cardsEl) => prevVal + getElementAbsoluteHeight(cardsEl),
        0,
      );

      this.maxHeight = totalCardsHeight + ADDITIONAL_TOP_GAP;
    },

    /**
     * Animate the scroll to go top.
     */
    animateScroll() {
      const scrollCoords = {
        y: window.pageYOffset,
      };

      anime({
        targets: scrollCoords,
        y: 0,
        easing: "linear",
        duration: ANIMATION_SCROLL_TIME,
        update: () => window.scroll(0, scrollCoords.y),
      });
    },

    /**
     * Compute the total element height.
     */
    computeTotalHeight() {
      const containerEl = this.$el.querySelector(
        CONTAINER_SELECTOR,
      ) as HTMLElement;
      this.totalHeight = containerEl.offsetHeight;
    },

    onSeeCriteria(details: ICategoryDetail) {
      this.selectedCategoryLevel = details;
      this.criteriaModalVisibility = true;
    },

    async animateView() {
      await this.$nextTick();

      this.computeMaxHeight();
      this.animateScroll();
    },
  },
});
</script>

<style lang="scss" scoped>
.mobile-level-select__wrapper {
  position: relative;
  padding: 74px 0 94px;
}

.mobile-level-select {
  position: relative;
  overflow-y: visible;

  transition: all 0.3s ease-in-out;

  &__container {
    position: absolute;
    bottom: 0;
    width: 100%;
  }

  &__card {
    margin-bottom: 8px;
  }

  &__card:first-child {
    margin-top: 8px;
  }

  &__card::before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 3;
    pointer-events: none;

    content: "";

    background-color: $pale-grey;

    box-shadow: 0 2px 11px 0 $black-5;

    transition: opacity 0.3s ease-in-out;
  }

  &__card.is-visible::before {
    opacity: 0;
  }

  &__info-wrapper {
    padding-top: 29px;
  }

  &__behavior {
    @include grotesk(regular);

    max-width: calc(100% - 36px);
    margin: 0 auto;

    font-size: 0.9333rem;
    line-height: 1.3333rem;
    color: $ebony-clay;
    text-align: center;
  }
}

.mobile-assessment-skip-tip {
  display: flex;
  padding: 34px 0 0 2px;

  & :deep() .px-icon {
    position: relative;
    top: 2px;
  }

  &__text {
    max-width: 302px;
    padding-left: 6px;

    font-size: 0.8667rem;
    font-style: italic;
    line-height: 1.3333rem;
    color: $manatee;
    letter-spacing: 0.38px;
  }
}
</style>
