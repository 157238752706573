<template>
  <div :style="{ width }" class="level-range-slider-mobile">
    <ElTooltip
      v-model="showTooltipOverreached"
      :visible-arrow="false"
      effect="dark"
      placement="top"
      popper-class="level-range-slider-mobile__tooltip-transform el-abaca-tooltip"
    >
      <template #content>
        <div class="level-range-slider-mobile__tooltip-text">
          <i>
            There’s a limit
            <b>range of 4 levels</b>
            .
            <br />
            For a wider range
            <b>
              <span class="tooltip-link" @click="rangeTipButtonClickHandler">
                get in touch
              </span>
              with us.
            </b>
          </i>
        </div>
      </template>
      <span />
    </ElTooltip>
    <ElSlider
      ref="slider"
      v-model="innerValue"
      :max="levels"
      :min="1"
      :show-tooltip="false"
      :step="1"
      :width="{ width }"
      class="level-range-slider-mobile__inner"
      range
      show-stops
      @change="() => changeLevelHandler"
      @input="() => handleRangeSliderInputChange"
    />
    <div class="level-range-slider-mobile__labels">
      <template v-for="(item, index) in stops">
        <div
          v-if="index !== 0"
          :key="item"
          :class="currentLevelState(index)"
          :style="{ left: 'calc(' + item + '% - 4px)' }"
          class="level-range-slider-mobile__level-label"
        >
          <span>
            {{ index }}
          </span>
        </div>
        <div
          v-if="index !== 0"
          :key="'stop-' + item"
          :class="{
            'is-active': isLevelBeingSelected(index),
          }"
          :style="{ left: item + '%' }"
          class="level-range-slider-mobile__stop"
        >
          <ElTooltip
            v-if="index === 1"
            v-model="showTooltip"
            effect="dark"
            placement="top"
            popper-class="level-range-slider-mobile__tooltip el-abaca-tooltip"
          >
            <template #content>
              <div
                class="level-range-slider-mobile__tooltip-text"
                v-html="entryTooltip"
              />
            </template>
            <span />
          </ElTooltip>
        </div>
      </template>
    </div>
  </div>
</template>

<script lang="ts">
import { ComponentPublicInstance, defineComponent } from "vue";
import xor from "lodash/xor";

const SLIDER_BAR_SELECTOR = ".el-slider__bar";
const SLIDER_BUTTON_SELECTOR = ".el-slider__button";

export default defineComponent({
  name: "LevelRangeSlider",

  props: {
    rangeRestriction: {
      type: Number,
      default: null,
    },
    /**
     * Number of available levels.
     */
    levels: {
      type: Number,
      required: true,
    },
    /**
     * Current selected level.
     */
    value: {
      type: Array as () => any[],
      default: () => [1, 1],
    },
    color: {
      type: String,
      default: "ff8e54",
    },
    /**
     * Component height.
     */
    width: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      innerValue: [1, 1],
      currentDraggedLevel: this.value,
      rangeHasOverreachedLimit: false,
      showTooltipOverreached: false,
      showTooltip: false,
      entryTooltip: this.$t("supporters.assessment.entryTooltip"),
      limitOverreachedTooltip: this.$t(
        "supporters.assessment.limitOverreachedTooltip",
      ),
      levelsBeingSelected: this.value,
    };
  },

  computed: {
    stops(): Array<number> {
      const stopCount = this.levels;
      const stepWidth = 105 / this.levels;

      const result = [0];
      for (let i = 0; i < stopCount; i++) {
        result.push(i * stepWidth);
      }

      return result;
    },

    firstStop(): number {
      return this.innerValue[0];
    },

    lastStop(): number {
      return this.innerValue[1];
    },

    firstBeingSelectedLevel(): number {
      return this.levelsBeingSelected[0];
    },

    lastBeingSelectedLevel(): number {
      return this.levelsBeingSelected[1];
    },
  },

  watch: {
    /**
     * Inform the outside would that the value changed.
     */
    innerValue: {
      deep: true,
      handler(newVal, oldVal) {
        const newRange = this.rangeRestriction
          ? this.applyRangeRestrictions(newVal, oldVal)
          : newVal;
        const hasNewValue = newVal.some((newLevel: number) =>
          oldVal.some((oldLevel: number) => newLevel !== oldLevel),
        );

        if (hasNewValue) {
          this.$emit("input", newRange);
          this.$emit("changed", newRange);
        }
      },
    },

    value: {
      immediate: true,
      deep: true,
      handler(newVal: Array<number>) {
        if (xor(newVal, this.innerValue).length) {
          this.innerValue = newVal;
        }
      },
    },

    /**
     * Hide tolltip after 4 seconds
     */
    showTooltip(val: boolean) {
      if (val) {
        setTimeout(() => {
          this.showTooltip = false;
        }, 4000);
      }
    },

    /**
     * Hide tolltip after 5 seconds
     */
    showTooltipOverreached(val: boolean) {
      if (val) {
        setTimeout(() => {
          this.showTooltipOverreached = false;
        }, 5000);
      }
    },

    /**
     * Set the slider color when the prop `color` changes.
     */
    color() {
      this.setSliderColor();
    },
  },

  mounted() {
    // When the component is rendered for the first time
    // the slider color must be set with the color
    // presented on the prop `color`
    this.setSliderColor();
    this.showTooltip = true;
  },

  methods: {
    handleRangeSliderInputChange(newVal: Array<number>) {
      this.levelsBeingSelected = newVal;
    },

    rangeTipButtonClickHandler() {
      this.$emit("range-levels-modal", true);
    },
    /**
     * Sets the slider color based on the color present on the
     * `color` prop.
     */
    setSliderColor() {
      const sliderComponent = this.$refs.slider as ComponentPublicInstance;
      const sliderElement = sliderComponent.$el;
      const progressBar = sliderElement.querySelector(
        SLIDER_BAR_SELECTOR,
      ) as HTMLElement;
      const buttonElements = sliderElement.querySelectorAll(
        SLIDER_BUTTON_SELECTOR,
      ) as NodeListOf<HTMLElement>;

      [progressBar, ...buttonElements].forEach((element: HTMLElement) => {
        element.style.backgroundColor = `#${this.color}`;
      });
    },

    applyRangeRestrictions(
      newRangeValue: Array<number>,
      oldRangeValue: Array<number>,
    ) {
      if (!oldRangeValue) return newRangeValue;

      const updatedRangeValue: Array<number> = [...newRangeValue];

      const rangeDifference = this.rangeRestriction - 1;
      const rangeGotHigher = newRangeValue[1] > this.currentDraggedLevel[1];
      const rangeGotLower = newRangeValue[0] < this.currentDraggedLevel[0];

      const rangeHasGrown =
        newRangeValue[0] + rangeDifference < newRangeValue[1];
      const rangeHasShrink =
        newRangeValue[1] - newRangeValue[0] <
        oldRangeValue[1] - oldRangeValue[0];
      const rangeReachedLimit = rangeHasGrown || rangeHasShrink;

      if (rangeReachedLimit) {
        this.rangeHasOverreachedLimit = rangeHasGrown;

        if (rangeGotHigher) {
          // Pull first range selector up
          updatedRangeValue[0] = newRangeValue[1] - rangeDifference;
        } else if (rangeGotLower) {
          // Pull last range selector down
          updatedRangeValue[1] = newRangeValue[0] + rangeDifference;
        }
      }

      return updatedRangeValue;
    },

    changeLevelHandler(newLevel: number[]) {
      this.currentDraggedLevel = newLevel;
      this.showTooltip = false;

      if (this.rangeHasOverreachedLimit) {
        this.showTooltipOverreached = true;
        this.rangeHasOverreachedLimit = false;
      }
    },

    isLevelBeingSelected(index: number): boolean {
      return (
        index >= this.firstBeingSelectedLevel &&
        index <= this.lastBeingSelectedLevel
      );
    },

    isLevelSelected(index: number): boolean {
      return index === this.firstStop || index === this.lastStop;
    },
    isInLevelSelectedInterval(index: number): boolean {
      return index > this.firstStop && index < this.lastStop;
    },

    isLevelSelectable(index: number): boolean {
      const rangeDifference = this.rangeRestriction - 1;
      const selectedRangeDifference = this.lastStop - this.firstStop;
      const rangeAvailable = rangeDifference - selectedRangeDifference;

      const canSelectLowerLevel =
        index < this.firstStop && index >= this.firstStop - rangeAvailable;
      const canSelectHigherLevel =
        index > this.lastStop && index <= this.lastStop + rangeAvailable;

      return canSelectLowerLevel || canSelectHigherLevel;
    },

    currentLevelState(index: number) {
      return {
        "is-selected": this.isLevelSelected(index),
        "is-selected-interval": this.isInLevelSelectedInterval(index),
        "is-selectable": this.isLevelSelectable(index),
      };
    },
  },
});
</script>

<style lang="scss">
$slider-width: 270px;
$slider-height: 24px;
$slider-border-radius: 2px;
$slider-stop-bg-color: rgba(139, 143, 161, 0.6);
$slider-stop-active-bg-color: rgba(39, 39, 64, 0.4);
$slider-button-size: 36px;

.level-range-slider-mobile {
  position: relative;
  display: flex;
  flex-direction: row;
  margin-bottom: 51px;
}

.el-slider.level-range-slider-mobile__inner {
  top: 17px;
  width: 100%;

  .el-slider__runway {
    width: $slider-width;

    background: none;
  }

  .el-slider__runway::before {
    position: absolute;
    top: calc(50% + 9px);
    right: 0;
    left: 0;
    width: 280px;
    height: 24px;
    content: "";

    background-image: linear-gradient(to top, $mischka, $mischka);
    border: solid 1px rgba(0, 0, 0, 0.06);
    border-radius: 100px;

    transform: translateY(-50%);
  }

  .el-slider__bar {
    top: -19px;
    left: -1px;
    width: $slider-width;
    height: $slider-height;

    border: solid 1px $black-20;
    border-top-left-radius: 0;
    border-top-right-radius: 0;

    transform: translate(1px, 20px);
  }

  .el-slider__stop {
    display: none;
  }

  .el-slider__button-wrapper {
    left: -6px;

    &[style*="bottom: 0%"] + .el-slider__button-wrapper[style*="bottom: 0%"] {
      transform: translateY(-5px);
    }
  }

  .el-slider__button {
    position: relative;
    width: $slider-button-size;
    height: $slider-button-size;

    background-image: url("#{$assetsPath}/img/icons/slider-shapes.svg");
    background-position: -2px -2px;
    background-size: 38px 38px;
    border: solid 1px rgba(0, 0, 0, 0.24);
  }

  .el-slider__button:hover,
  .el-slider__button.hover,
  .el-slider__button.dragging {
    transform: translateZ(0) scale(1.07);
  }

  .el-slider__button::before {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 42px;
    height: 42px;

    content: "";

    background-color: transparent;
    border: solid 3px $alabaster;
    border-radius: 50%;

    transform: translate(-50%, -50%);
  }

  .el-slider__button::after {
    position: absolute;
    top: -1px;
    left: -1px;
    width: $slider-button-size;
    height: $slider-button-size;

    content: "";

    background-color: transparent;
    border-radius: 50%;

    box-shadow: 0 0 5px 0 $black-20;
  }
}

.level-range-slider-mobile__labels {
  position: absolute;
  top: 34px;
  left: 7px;
  width: 280px;
}

.level-range-slider-mobile__stop {
  position: absolute;
  top: -7px;
  left: 43px;
  width: 2px;
  height: 10px;

  background-color: $slider-stop-bg-color;
  border-radius: 2px;
  transform: translateY(-3px);

  &.is-active {
    background-color: $slider-stop-active-bg-color;

    box-shadow: inset 0 1px 2px 0 rgba(39, 39, 64, 0.4);
  }
}

.level-range-slider-mobile__level-label {
  @include grotesk(medium);

  position: absolute;
  display: flex;
  width: 23px;
  height: 23px;
  margin-top: 13px;
  margin-bottom: 17px;
  margin-left: -6px;

  font-size: 0.9333rem;
  vertical-align: center;
  border-radius: 12.5px;
  transform: translateY(5px);

  > span {
    margin: auto;
  }

  &.is-selected {
    @include grotesk(semiBold);

    width: 23px;
    height: 23px;
    color: $white;
    background-image: linear-gradient(to bottom, $apricot, $orangish);
    border: solid 0.9px $black-20;
    border-radius: 12.5px;
    box-shadow: 0 3px 3px 0 rgba(255, 129, 65, 0.3);
    opacity: 1;

    > span {
      font-weight: bold;
    }

    & ~ .is-selected .level-range-slider-mobile__level::after {
      opacity: 1;
    }
  }

  &.is-selected-interval > span {
    font-weight: bold;
    color: $orangish;
  }

  &.is-selectable .level-range-slider-mobile__level::before {
    opacity: 1;
  }
}

.level-range-slider-mobile__tooltip {
  width: 221px;
  border-radius: 1px;
  box-shadow: 0 2px 8px 0 rgba(39, 39, 64, 0.6);

  &-text {
    font-size: 14px;
    font-weight: 600;
    line-height: 1.43;
  }
}

.level-range-slider-mobile__tooltip-transform {
  left: calc(50% - 130px) !important;
  width: 260px;
  transform: translateY(-12px);

  &-text {
    font-size: 14px;
    font-style: italic;
    line-height: 1.43;
  }
}

.tooltip-link {
  font-size: 14px;
  font-style: italic;
  font-weight: bold;
  line-height: 1.43;
  text-decoration: underline;
  cursor: pointer;
}
</style>
