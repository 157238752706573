import marked from "marked";
import DOMPurify from "dompurify";
import { DirectiveBinding } from "vue";

/**
 * Parse markdown-based input into sanitized HTML.
 *
 * @param {HTMLElement} el
 * @param {DirectiveBinding} binding
 */
const convertMarkdownToHTML = (el: HTMLElement, binding: DirectiveBinding) => {
  const content = marked(binding.value);
  const cleanContent = DOMPurify.sanitize(content);

  el.innerHTML = cleanContent;
};

/**
 * Directive to parse a markdown-based input into sanitized HTML content.
 */
export default {
  beforeMount(el: HTMLElement, binding: DirectiveBinding) {
    convertMarkdownToHTML(el, binding);
  },
  updated(el: HTMLElement, binding: DirectiveBinding) {
    convertMarkdownToHTML(el, binding);
  },
};
