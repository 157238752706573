<template>
  <div class="results-panel">
    <div
      :class="{ 'results-panel__header--scroll': !scrollAtTop }"
      class="results-panel__header"
    >
      <div class="results-panel__header-left">
        <h3>
          {{ $t("capitalExplorer.resultsPanel.title") }}
        </h3>
      </div>
      <div class="results-panel__header-right">
        <PxButton
          v-bind="glossaryButtonProps"
          ref="glossaryButton"
          class="results-panel__glossary-button"
          @click="onGlossaryClick"
        />
        <PxButton
          ref="compareButton"
          class="results-panel__compare-button"
          v-bind="compareButtonProps"
          @click="onCompareButtonClick"
        />
      </div>
    </div>
    <div ref="panel" class="results-panel__wrapper" @scroll="checkScroll">
      <div
        :class="{ visible: noMatchingData }"
        class="results-panel__no-matching"
      >
        <div class="results-panel__no-matching__wrapper">
          <PxEmptyMessage
            :icon-size="92"
            :subtitle="emptySubtitle"
            :subtitle-size="15"
            :title="emptyTitle"
            :title-size="17"
            data-testid="results-panel-empty-message"
            icon="matching-no-matches"
            subtitle-style="none"
          />
          <div class="results-panel__no-matching__badges">
            <PxStatusBadge
              v-for="(badge, index) in matchingBadges"
              :key="index"
              :background-color="badge.backgroundColor"
              :border-color="badge.borderColor"
              :icon="badge.icon"
              :icon-size="13"
              :label="$t(badge.title)"
              :label-color="badge.textColor"
              :label-size="14"
              :style="{ boxShadow: badge.boxShadow }"
              :uppercase="false"
              :weight="600"
              data-testid="matching-badge"
            />
          </div>
          <div class="results-panel__note">
            <span>
              {{ $t("capitalExplorer.resultsPanel.noMatchingData.note") }}
            </span>
          </div>
        </div>
      </div>
      <transition-group
        :class="{
          'results-panel__results--no-matching': noMatchingData,
        }"
        class="results-panel__results"
        name="flip-tiles"
        tag="div"
      >
        <CapitalTile
          v-for="result in results"
          :key="result.capitalInfo.id"
          :capital-info="result.capitalInfo"
          :match-details="result.matchDetails"
          :match-properties="result.matchProperties"
          :show-matching-badge="showMatchingBadge"
          data-testid="results-panel-capital-tile"
        />
      </transition-group>
    </div>
    <PxStatusBadge
      class="results-panel__floating-tag"
      v-bind="floatingTagProps"
    >
      <template #additionalContent>
        <a href="https://www.adventure.finance/" target="_blank">
          Aunnie Patton Power
        </a>
        <span>🤝</span>
      </template>
    </PxStatusBadge>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import CapitalTile from "@/modules/capital-explorer/components/capital-tile/capital-tile.vue";
import PxEmptyMessage from "@/components/px-empty-message/px-empty-message.vue";
import PxStatusBadge from "@/components/px-status-badge/px-status-badge.vue";

import { TranslateResult } from "vue-i18n";
import {
  ICapitalTileInfo,
  ICapitalMatchingBadge,
} from "@/modules/capital-explorer/services/data/capital-explorer/capital-explorer.interface";
import {
  EPxButtonSize,
  EPxButtonVariant,
  EPxButtonType,
} from "@/components/px-button/px-button.types";
import { GLOSSARY_URL } from "@/modules/capital-explorer/constants";
import { CAPITAL_EXPLORER_COMPARISON_TABLE } from "@/modules/capital-explorer/services/router/routes-names";
import {
  FAIR_MATCH,
  POOR_MATCH,
  EXCELLENT_MATCH,
} from "@/modules/capital-explorer/capital-structures-matching.types";

export default defineComponent({
  name: "ResultsPanel",

  components: {
    CapitalTile,
    PxEmptyMessage,
    PxStatusBadge,
  },

  static: {
    glossaryButton: {
      size: EPxButtonSize.MEDIUM,
      variant: EPxButtonVariant.FLUID,
    },
  },

  props: {
    results: {
      type: Array as () => ICapitalTileInfo[],
      required: true,
    },

    noMatchingData: {
      type: Boolean,
      default: false,
    },

    showMatchingBadge: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      scrollAtTop: true,
      panel: (null as any) || null,
      matchingBadges: [EXCELLENT_MATCH, FAIR_MATCH, POOR_MATCH].map(
        ({ matchingBadge }) => matchingBadge as ICapitalMatchingBadge,
      ),
    };
  },

  computed: {
    emptyTitle(): TranslateResult {
      return this.$t("capitalExplorer.resultsPanel.noMatchingData.title");
    },

    emptySubtitle(): TranslateResult {
      return this.$t("capitalExplorer.resultsPanel.noMatchingData.subtitle");
    },

    floatingTagProps(): any {
      return {
        label: this.$t("common.codeveloped"),
        labelSize: 14,
        labelColor: "#272740",
        backgroundColor: "#fafafa",
        weight: 500,
        borderRadius: 8,
        boxShadow: "0 0 4px 0 rgba(39, 39, 64, 0.16)",
        uppercase: false,
      };
    },

    glossaryButtonProps(): any {
      return {
        ...this.$options.static.glossaryButton,
        label: this.$t("common.glossary"),
        icon: "icon-book-open",
        iconPosition: "left",
      };
    },

    compareButtonProps(): any {
      return {
        size: EPxButtonSize.MEDIUM,
        type: EPxButtonType.PRIMARY,
        label: this.$t("common.compare"),
        icon: "icon-matching",
        iconPosition: "left",
      };
    },
  },

  beforeUnmount() {
    this.panel = null;
  },

  methods: {
    checkScroll() {
      this.panel = this.$refs.panel as HTMLElement;

      if (!this.panel) return;

      this.scrollAtTop = this.panel.scrollTop === 0;
    },

    onGlossaryClick() {
      window.open(GLOSSARY_URL);
    },

    onCompareButtonClick() {
      this.$router.push({ name: CAPITAL_EXPLORER_COMPARISON_TABLE });
    },
  },
});
</script>

<style lang="scss" scoped>
.results-panel {
  height: 100%;
  display: flex;
  flex-direction: column;

  &__header {
    margin: 0 25px;
    padding: 33px 5px 27px;

    display: flex;
    justify-content: space-between;

    &-left {
      display: flex;
      gap: 13px;
      align-items: center;
    }

    &-right {
      display: flex;
      gap: 16px;
      align-items: center;
    }

    h3 {
      @include grotesk(medium);

      font-size: 22px;
      line-height: 24px;
      letter-spacing: -0.3px;
      margin: 0;
    }

    &--scroll {
      border-bottom: 1px solid $mischka;
    }

    .px-status-badge {
      margin-top: 1px;
      padding: 4px 8px;
    }
  }

  &__glossary-button {
    padding: 1px 15px 0 10px;

    :deep() .px-icon + div {
      margin-left: 8px;
    }
  }

  &__compare-button {
    padding: 1px 15px 0 16px;

    :deep() .px-icon + div {
      margin-left: 7px;
    }
  }

  &__floating-tag {
    position: fixed;
    right: 32px;
    bottom: 26px;
    padding: 4px 8px;
    z-index: z("floaters") + 2;

    :deep() .px-status-badge__label {
      letter-spacing: -0.3px;
    }

    a {
      margin: 0 3px 0 3px;

      @include grotesk(semiBold);

      font-size: 14px;
      color: $ebony-clay;
      letter-spacing: -0.1px;
    }
  }

  &__no-matching {
    margin: 0 30px;
    display: grid;
    grid-template-rows: 0fr;
    opacity: 0;
    transition:
      grid-template-rows 0.5s ease-in-out,
      opacity 0.5s ease-in-out;

    &__wrapper {
      overflow: hidden;
    }

    &.visible {
      grid-template-rows: 1fr;
      opacity: 1;
    }

    :deep() .px-empty-message h4 {
      position: relative;
      right: 1px;
      padding-top: 16px;
      letter-spacing: -0.1px;
      font-weight: 600;
    }

    :deep() .px-empty-message p {
      letter-spacing: -0.2px;
      max-width: 630px;
      line-height: 22px;
      font-weight: 500;
      color: $spun-pearl;
    }

    &__badges {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 16px;
      margin-top: 14px;
    }

    &__badges .px-status-badge {
      padding: 5px 9px;
      gap: 5px;
      width: max-content;
    }

    :deep() &__badges .px-status-badge__label {
      margin: 0;
    }
  }

  &__note {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 46px;
    border-top: 1px solid $mischka;

    span {
      position: relative;
      top: -11px;
      padding: 0 12px;
      background-color: $alabaster;
      font-size: 14px;
      line-height: 1.43;
      letter-spacing: -0.3px;
      color: $manatee;
    }
  }

  &__wrapper {
    flex: 1;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__results {
    padding: 21px 30px 30px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(305px, 1fr));
    gap: 37px 24px;

    &--no-matching {
      gap: 24px;
    }

    .flip-tiles-move {
      transition: transform 0.5s ease-in-out;
    }
  }
}
</style>
