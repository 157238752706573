<template>
  <MatchingCardWrapper
    class="matching-card--simplified"
    @click="$emit('click')"
  >
    <template #header>
      <MatchingCardPercentagePill
        :percentage="matchPercentage"
        :show-tooltip="!isInterestDetailModalVisible && $screen.mdUp"
        @click.stop="onClickShowModal"
      />
      <MatchDetailsModal
        v-if="isInterestDetailModalVisible && $screen.mdUp"
        v-model:visibility="isInterestDetailModalVisible"
        :company-logo="thumbnail"
        :company-name="name"
        :match-details="matchScoreDetails"
        data-testid="matching-card-details-modal"
      />
    </template>
    <template #body>
      <div class="matching-card__headline">
        <MatchingCardThumbnail
          :level="matchLevel"
          :name="name"
          :size="46"
          :thumbnail="thumbnail"
        />
        <div>
          <h3 class="matching-card__title">
            {{ name }}
          </h3>
          <p class="matching-card__subtitle">
            {{ location }}
          </p>
        </div>
      </div>
      <div class="matching-card__content">
        <span v-if="!!about" class="matching-card__description">
          {{ about }}
        </span>
        <MatchingCardTagList :items="mappedSectors" />
        <div class="matching-badge-list__wrapper">
          <MatchingBadgeList
            v-if="!showPublicView"
            :badges="badges"
            :company-name="name"
          />
        </div>
      </div>
    </template>
    <template #footer>
      <PxMatchingInterest
        v-if="enabledMatchingInterest"
        v-bind="$options.static.interestButton"
        :id="companyId"
        :name="name"
        :with-description="false"
        class="matching-card__interest"
        @click.stop
      />
    </template>
  </MatchingCardWrapper>
</template>

<script lang="ts">
import MatchingCardMixin from "@/mixins/matching-card.mixin";
import MatchingCardWrapper from "@/components/matching-card/matching-card-wrapper.vue";
import MatchDetailsModal from "@/components/match-details-modal/match-details-modal.vue";

import { EPxButtonSize } from "@/components/px-button/px-button.types";

import { defineComponent } from "vue";

export default defineComponent({
  name: "MatchingCardSimplified",

  components: {
    MatchingCardWrapper,
    MatchDetailsModal,
  },

  mixins: [MatchingCardMixin],

  static: {
    interestButton: {
      size: EPxButtonSize.EXTRA_SMALL,
    },
  },
});
</script>

<style lang="scss" scoped>
.matching-card:hover :deep() .matching-card-background::after {
  @include breakpoint-up(md) {
    opacity: 1;
  }
}

.matching-badge-list__wrapper {
  position: relative;
  z-index: z("floaters");

  :deep() .matching-badge-list {
    justify-content: flex-start;
    margin: 11px 7px 19px;
  }
}

.matching-card__headline {
  display: grid;
  grid-template-columns: 46px 173px;
  grid-gap: 14px;
  word-break: break-all;
  overflow-wrap: break-word;
}

.matching-card__title {
  @include grotesk(bold);

  max-width: calc(100% - 55px);
  margin-top: 2px;
  margin-bottom: 3px;
  font-size: 16px;
  line-height: 19px;
}

.matching-card__subtitle {
  margin-bottom: 10px;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.2px;
}

.matching-card__title,
.matching-card__subtitle {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.matching-card__description {
  @include multiLineEllipsis(
    $font-size: 14px,
    $line-height: 19px,
    $lines-to-show: 2
  );

  margin-bottom: 10px;
  color: $manatee;
}

.matching-card__content {
  margin: 8px 2px;
}
</style>

<style lang="scss">
.matching-card__tooltip.el-abaca-tooltip {
  padding: 5px 10px;
  pointer-events: none;

  /*
     * This will remove the standard behaviour of changing the cursor selector to text selector, even if the tooltips
     * remain with selectable to false
     */
  & > * {
    cursor: default;
  }
}
</style>
