<template>
  <footer class="px-footer">
    <div class="px-footer__container">
      <nav class="px-footer__menu">
        <a
          v-for="item in footerItems"
          :key="item.value"
          :class="item.class"
          :href="item.value"
          :target="itemTarget(item)"
          class="px-footer-menu-item"
        >
          {{ item.label }}
        </a>
      </nav>
      <img
        alt="VIRAL Logo"
        class="px-footer__logo"
        src="/img/logos/abaca-logo--dark.svg"
      />
      <p class="px-footer__copyright">
        {{
          $t("common.footer.copyright", {
            year: currentYear,
          })
        }}
      </p>
    </div>
  </footer>
</template>

<script lang="ts">
import { defineComponent } from "vue";

interface IFooterItem {
  label: string;
  value: string;
  class?: string;
  openTab?: string;
}

/**
 * This is the desktop version of the footer.
 */
export default defineComponent({
  name: "PxFooter",

  props: {
    /**
     * Receives a list of items to append to the menu.
     *
     * Structure:
     * {
     *   label: "Label",
     *   value: "https://example.com",
     *   openTab: false,
     *   class: "additional-class-names"
     * }
     */
    items: {
      type: Array as () => IFooterItem[] | null,
      default: null,
    },
  },

  data() {
    return {
      currentYear: new Date().getFullYear(),
    };
  },

  computed: {
    footerItems(): IFooterItem[] {
      return (
        this.items ||
        ([
          {
            label: this.$t("common.footer.cookieSettings"),
            value: "javascript:void(0)",
            class: "optanon-toggle-display",
          },
          {
            label: this.$t("common.footer.privacyPolicy"),
            value: "https://abaca.app/privacy-policy/",
            openTab: true,
          },
          {
            label: this.$t("common.footer.termsOfUse"),
            value: "https://abaca.app/terms-of-use/",
            openTab: true,
          },
        ] as IFooterItem[])
      );
    },
  },

  methods: {
    itemTarget(item: IFooterItem) {
      return item.openTab ? "_blank" : "_self";
    },
  },
});
</script>

<style lang="scss">
.px-footer {
  padding-top: 25px;

  @include breakpoint-up(lg) {
    padding-top: 80px;
  }

  &__container {
    @include container;

    border-top: solid 1px $athens-gray;

    @include breakpoint-up(lg) {
      display: flex;
      flex-direction: row;
      padding: 0 0 4px;
    }
  }

  &__menu {
    flex-grow: 1;
    order: 2;

    @include breakpoint-up(lg) {
      padding-top: 2px;
      padding-left: 48px;
    }
  }

  &__logo {
    position: relative;
    left: 50%;
    order: 1;
    width: 86px;
    padding-top: 20px;

    transform: translateX(-50%);

    @include breakpoint-up(lg) {
      top: -2px;
      left: 0;
      width: 82px;
      padding-top: 0;
      margin-left: 2px;

      transform: none;
    }
  }

  &__copyright {
    display: block;
    order: 3;
    padding: 14px 0 24px;

    font-size: 0.8667rem;
    color: rgba(39, 39, 64, 0.7);
    text-align: center;

    @include breakpoint-up(lg) {
      display: flex;
      align-items: center;

      padding: 0 7px 0 0;

      font-size: to-rem(14px);
      letter-spacing: -0.2px;
    }
  }

  &.is-assessment-results {
    padding-bottom: 75px;
  }

  &.is-assessment-thanks {
    padding-bottom: 123px;
  }
}

.px-footer-menu-item {
  position: relative;
  display: block;
  padding: 19px 0;

  font-size: 1.0667rem;
  line-height: 17px;
  color: rgba($ebony-clay, 0.7);
  text-align: center;
  text-decoration: none;
  letter-spacing: -0.2px;

  @include breakpoint-up(lg) {
    display: inline-block;
    padding: 19px 14px;
    line-height: 28px;
  }

  &::after {
    position: relative;
    bottom: -20px;
    left: 50%;
    display: block;
    height: 1px;

    content: "";

    background-color: $athens-gray;

    transform: translateX(-50%);

    @include breakpoint-up(lg) {
      content: none;
    }
  }
}
</style>
