<template>
  <PxPanel class="details-panel-placeholder px-panel--mobile-no-side-borders">
    <template #header>
      <div
        class="placeholder-block placeholder-block--important details-panel-placeholder__title"
      />
    </template>
    <ContentPlaceholders class="details-panel-placeholder__container" rounded>
      <div class="placeholder-block details-panel-placeholder__grid" />
      <hr />
      <div class="details-panel-placeholder__level-descriptions">
        <LevelsDescriptionPlaceholder
          v-for="i in 8"
          :key="i"
          class="details-panel-placeholder__level-descriptions-entry"
        />
      </div>
    </ContentPlaceholders>
  </PxPanel>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import LevelsDescriptionPlaceholder from "@/modules/affiliates/components/levels-description-placeholder/levels-description-placeholder.vue";

export default defineComponent({
  name: "DetailsPanelPlaceholder",

  components: {
    LevelsDescriptionPlaceholder,
  },
});
</script>

<style lang="scss" scoped>
.details-panel-placeholder {
  width: 100%;
  max-width: unset;
  margin-bottom: 99px;

  & :deep() .px-panel__body {
    padding-bottom: 0;
  }

  &__title {
    width: 224px;
    height: 26px;
    margin: 6px auto 8px;

    background-color: $manatee;
    opacity: 0.4;

    @include breakpoint-down(sm) {
      height: 18px;
      margin: 4px auto 4px;
    }
  }

  &__container {
    padding: 29px 30px 0;

    @include breakpoint-down(sm) {
      padding: 8px 0 0;
    }
  }

  &__grid {
    left: -5px;
    width: 100%;
    max-width: 708px;
    height: 326px;
    margin: 0 auto 40px;

    background-color: $pale-lilac;

    @include breakpoint-down(sm) {
      left: 0;
    }
  }

  hr {
    height: 1px;

    background-color: $athens-gray;
    border: 0;

    @include breakpoint-down(sm) {
      display: none;
    }
  }
}

.details-panel-placeholder__level-descriptions {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 32px 0 0 47px;
  margin: 0 auto;

  @include breakpoint-down(sm) {
    padding: 7px 0 0;
  }
}

.details-panel-placeholder__level-descriptions-entry {
  margin-right: 47px;
  margin-bottom: 43px;

  @include breakpoint-down(sm) {
    margin-right: 0;
    margin-bottom: 21px;
  }
}
</style>
