import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-5820087d"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "company-lists-item company-lists-item-placeholder__wrapper" }
const _hoisted_2 = { class: "company-lists-item-placeholder__container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ContentPlaceholdersHeading = _resolveComponent("ContentPlaceholdersHeading")!
  const _component_ContentPlaceholders = _resolveComponent("ContentPlaceholders")!
  const _component_ContentPlaceholdersText = _resolveComponent("ContentPlaceholdersText")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_ContentPlaceholders, { rounded: true }, {
        default: _withCtx(() => [
          _createVNode(_component_ContentPlaceholdersHeading, { img: true })
        ]),
        _: 1
      }),
      _createVNode(_component_ContentPlaceholders, {
        rounded: true,
        class: "company-lists-item-placeholder__centered"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_ContentPlaceholdersText, { lines: 1 })
        ]),
        _: 1
      })
    ])
  ]))
}