<template>
  <div class="level-bar">
    <div class="level-bar__label">
      {{ label || $t("selfAssessment.components.levelBar.label") }}
    </div>
    <div class="level-bar__icon">
      <img
        :alt="`Level ${level.value}`"
        :src="`/img/icons/level-graph/${level.value}-white.svg`"
      />
    </div>
    <div class="level-bar__description">
      {{ level.title }}
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "LevelBar",

  props: {
    /**
     * Text to be used for the label
     */
    label: {
      type: String,
      default: "",
    },

    /**
     * Contains the user level value and description
     */
    level: {
      type: Object as () => any,
      required: true,
    },
  },
});
</script>

<style lang="scss" scoped>
.level-bar {
  display: grid;
  grid-template-areas:
    "label level"
    "description level";
  grid-template-rows: minmax(24px, auto);
  grid-template-columns: 2fr 1fr;
  grid-gap: 0;

  padding: 7px 15px 6px;

  background-color: #ecf2fa;
  border-bottom: solid 1px #c9dffb;

  @include breakpoint-up(md) {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: center;
    padding: 5px 15px;
  }
}

.level-bar__label {
  @include grotesk(semiBold);

  position: relative;
  top: 3px;
  grid-area: label;

  font-size: 0.9333rem;
  line-height: 20px;
  color: $malibu;
  letter-spacing: -0.05px;

  @include breakpoint-up(md) {
    top: auto;
    font-size: 1rem;
    line-height: 18px;
    text-align: right;
  }
}

.level-bar__icon {
  grid-area: level;
  text-align: right;

  @include breakpoint-up(md) {
    margin: 0 5px 0 11px;
    text-align: center;
  }
}

.level-bar__icon img {
  max-width: 100%;
  height: 41px;

  @include breakpoint-up(md) {
    height: 30px;
  }
}

.level-bar__description {
  @include grotesk(semiBold);

  grid-area: description;

  font-size: 1rem;
  line-height: 20px;
  color: $nice-blue;
  letter-spacing: -0.05px;

  @include breakpoint-up(md) {
    position: relative;
    top: 2px;
    font-size: 1.0667rem;
    line-height: 18px;
  }
}
</style>
