import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-24886645"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "supporter-registration-form__wrapper" }
const _hoisted_2 = { class: "supporter-registration-form__container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CriteriaMultiSelector = _resolveComponent("CriteriaMultiSelector")!
  const _component_ElFormItem = _resolveComponent("ElFormItem")!
  const _component_ElForm = _resolveComponent("ElForm")!
  const _component_FormPresentationLayout = _resolveComponent("FormPresentationLayout")!
  const _component_PxButton = _resolveComponent("PxButton")!
  const _component_NavigationFooter = _resolveComponent("NavigationFooter")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_FormPresentationLayout, {
      "description-image-link": _ctx.viewCopy.imageLink,
      "description-image-title": _ctx.viewCopy.imageTitle,
      "description-text": _ctx.viewCopy.imageDescription,
      "has-return-button": _ctx.hasPreviousPage,
      "content-vertical-alignment": "top",
      onPreviousPage: _ctx.onReturnButtonClick
    }, {
      content: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_ElForm, {
            ref: "supporterRegistrationForm",
            model: _ctx.fields,
            class: "supporter-registration-form__body",
            onValidate: _ctx.updateSubmitState
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ElFormItem, { prop: "additional_criteria" }, {
                default: _withCtx(() => [
                  _createVNode(_component_CriteriaMultiSelector, {
                    modelValue: _ctx.fields.additional_criteria,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.fields.additional_criteria) = $event)),
                    values: _ctx.eligibleAdditionalCriteriaValues,
                    label: "supporters.form.additionalInterests.label",
                    placeholder: "supporters.form.additionalInterests.placeholder",
                    tip: "supporters.form.additionalInterests.tip"
                  }, null, 8, ["modelValue", "values"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["model", "onValidate"])
        ])
      ]),
      _: 1
    }, 8, ["description-image-link", "description-image-title", "description-text", "has-return-button", "onPreviousPage"]),
    _createVNode(_component_NavigationFooter, null, {
      left: _withCtx(() => [
        _createVNode(_component_PxButton, {
          loading: _ctx.formLoading || _ctx.viewLoading,
          class: "navigation-footer__main-cta",
          type: "primary",
          onClick: _ctx.onNextButtonClick
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.ctaCopy.next), 1)
          ]),
          _: 1
        }, 8, ["loading", "onClick"]),
        (_ctx.skipEnabled)
          ? (_openBlock(), _createBlock(_component_PxButton, {
              key: 0,
              onClick: _ctx.onSkipButtonClick
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.ctaCopy.skip), 1)
              ]),
              _: 1
            }, 8, ["onClick"]))
          : _createCommentVNode("", true)
      ]),
      right: _withCtx(() => [
        (_ctx.skipEnabled)
          ? (_openBlock(), _createBlock(_component_PxButton, {
              key: 0,
              type: "link",
              onClick: _ctx.onSkipAllButtonClick
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.ctaCopy.setupCriteriaLater), 1)
              ]),
              _: 1
            }, 8, ["onClick"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ]))
}