import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "supporter-registration-form__container" }
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = ["href"]
const _hoisted_4 = ["href"]
const _hoisted_5 = { class: "supporter-registration-form__cta" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PxModalMessage = _resolveComponent("PxModalMessage")!
  const _component_i18n_t = _resolveComponent("i18n-t")!
  const _component_ElInput = _resolveComponent("ElInput")!
  const _component_ElFormItem = _resolveComponent("ElFormItem")!
  const _component_PxIcon = _resolveComponent("PxIcon")!
  const _component_ElTooltip = _resolveComponent("ElTooltip")!
  const _component_ElCheckbox = _resolveComponent("ElCheckbox")!
  const _component_ElCheckboxGroup = _resolveComponent("ElCheckboxGroup")!
  const _component_PxInputPlaces = _resolveComponent("PxInputPlaces")!
  const _component_PxButton = _resolveComponent("PxButton")!
  const _component_ElForm = _resolveComponent("ElForm")!
  const _component_ElDialog = _resolveComponent("ElDialog")!

  return (_openBlock(), _createBlock(_component_ElDialog, {
    ref: "modal",
    modelValue: _ctx.innerVisibility,
    "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.innerVisibility) = $event)),
    "close-on-click-modal": false,
    "close-on-press-escape": false,
    "show-close": true,
    title: _ctx.componentCopy.title,
    class: "user-details-modal",
    width: "466px",
    onClose: _ctx.onModalClose
  }, {
    default: _withCtx(() => [
      _createVNode(_component_PxModalMessage, { visible: _ctx.hasServerErrors }, null, 8, ["visible"]),
      _createVNode(_component_i18n_t, {
        class: "user-details-modal__sign-in-panel",
        keypath: "supporters.component.userDetailsModal.existingAccount",
        tag: "div"
      }, {
        signIn: _withCtx(() => [
          _createElementVNode("span", {
            class: "user-details-modal__sign-in-panel-link",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.gotoSignInModal && _ctx.gotoSignInModal(...args)))
          }, _toDisplayString(_ctx.componentCopy.signIn), 1)
        ]),
        _: 1
      }),
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_ElForm, {
          ref: "formWrapper",
          model: _ctx.fields,
          rules: _ctx.rules,
          class: "supporter-registration-form__body",
          onValidate: _ctx.updateSubmitState
        }, {
          default: _withCtx(() => [
            _createVNode(_component_ElFormItem, {
              error: _ctx.errors.getMessage('email') || '',
              label: _ctx.formEmailCopy.label,
              prop: "email"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ElInput, {
                  modelValue: _ctx.fields.email,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.fields.email) = $event)),
                  placeholder: _ctx.formEmailCopy.placeholder,
                  type: "email"
                }, null, 8, ["modelValue", "placeholder"])
              ]),
              _: 1
            }, 8, ["error", "label"]),
            _createVNode(_component_ElFormItem, {
              label: _ctx.$t('supporters.signup.form.fields.supporterName'),
              prop: "company.name"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ElInput, {
                  modelValue: _ctx.fields.company.name,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.fields.company.name) = $event)),
                  placeholder: _ctx.$t('supporters.signup.form.fields.supporterName')
                }, null, 8, ["modelValue", "placeholder"])
              ]),
              _: 1
            }, 8, ["label"]),
            _createVNode(_component_ElFormItem, {
              label: _ctx.$t('supporters.signup.form.fields.supporterType'),
              prop: "supporter.types"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ElCheckboxGroup, {
                  modelValue: _ctx.selectedSupporterTypes,
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.selectedSupporterTypes) = $event))
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.availableSupporterTypes, (type, key) => {
                      return (_openBlock(), _createBlock(_component_ElCheckbox, {
                        key: key,
                        value: type.id
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(type.name) + " ", 1),
                          (type.label)
                            ? (_openBlock(), _createBlock(_component_ElTooltip, {
                                key: 0,
                                placement: "top",
                                "popper-class": "supporter-registration-form__type-tooltip el-abaca-tooltip"
                              }, {
                                content: _withCtx(() => [
                                  _createElementVNode("div", {
                                    innerHTML: type.label
                                  }, null, 8, _hoisted_2)
                                ]),
                                default: _withCtx(() => [
                                  _createVNode(_component_PxIcon, {
                                    size: 16,
                                    class: "supporter-registration-form__type-question-icon",
                                    name: "icon-question"
                                  })
                                ]),
                                _: 2
                              }, 1024))
                            : _createCommentVNode("", true)
                        ]),
                        _: 2
                      }, 1032, ["value"]))
                    }), 128)),
                    _createVNode(_component_ElCheckbox, {
                      value: "",
                      onChange: _cache[3] || (_cache[3] = (value) => _ctx.otherTypeChangeHandler(value))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t("supporters.signup.form.fields.otherSupporterType")), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["modelValue"]),
                (_ctx.hasOtherSupporterType)
                  ? (_openBlock(), _createBlock(_component_ElInput, {
                      key: 0,
                      modelValue: _ctx.otherType,
                      "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.otherType) = $event)),
                      placeholder: 
              _ctx.$t('supporters.signup.form.placeholders.supporterType')
            ,
                      class: "supporter-registration-form__other-type-input"
                    }, null, 8, ["modelValue", "placeholder"]))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }, 8, ["label"]),
            _createVNode(_component_ElFormItem, {
              label: _ctx.$t('supporters.signup.form.fields.location'),
              prop: "company.location"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_PxInputPlaces, {
                  id: "supporter-registration-form__location-input",
                  modelValue: _ctx.fields.company.location,
                  "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.fields.company.location) = $event)),
                  placeholder: _ctx.$t('supporters.signup.form.fields.location'),
                  "use-geolocation": true
                }, null, 8, ["modelValue", "placeholder"])
              ]),
              _: 1
            }, 8, ["label"]),
            _createVNode(_component_ElFormItem, {
              "show-message": false,
              prop: "terms",
              required: ""
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ElCheckbox, {
                  modelValue: _ctx.fields.terms,
                  "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.fields.terms) = $event)),
                  class: "supporter-registration-form__selector"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_i18n_t, {
                      class: "supporter-registration-form__selector-text",
                      keypath: "supporters.form.terms",
                      tag: "p"
                    }, {
                      terms: _withCtx(() => [
                        _createElementVNode("a", {
                          href: _ctx.$options.static.termsOfUseUrl,
                          class: "text--nice-blue",
                          target: "_blank"
                        }, _toDisplayString(_ctx.$t("supporters.form.termsOfUse")), 9, _hoisted_3)
                      ]),
                      privacy: _withCtx(() => [
                        _createElementVNode("a", {
                          href: _ctx.$options.static.privacyPolicyUrl,
                          class: "text--nice-blue",
                          target: "_blank"
                        }, _toDisplayString(_ctx.$t("supporters.form.privacyPolicy")), 9, _hoisted_4)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["modelValue"])
              ]),
              _: 1
            }),
            _createElementVNode("div", _hoisted_5, [
              _createVNode(_component_PxButton, {
                disabled: _ctx.isSubmissionDisabled,
                type: "primary",
                onClick: _ctx.onSignUpClick
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.ctaCopy.signUp), 1)
                ]),
                _: 1
              }, 8, ["disabled", "onClick"])
            ])
          ]),
          _: 1
        }, 8, ["model", "rules", "onValidate"])
      ])
    ]),
    _: 1
  }, 8, ["modelValue", "title", "onClose"]))
}