<template>
  <div class="company-lists-item" @click="listClickHandler">
    <div class="company-lists-item__wrapper">
      <PxAvatarGrid
        :items="companyAvatars"
        :size="30"
        :visible-limit="6"
        class="company-lists-item__logo-grid"
      />
      <div class="company-lists-item__content">
        <div class="company-lists-item__header">
          <h3 class="company-lists-item__title" v-text="value.title" />
          <template v-if="isSmarList">
            <ElTooltip
              :content="smartListTooltipCopy"
              placement="top"
              popper-class="company-lists-smart-item__tooltip el-abaca-tooltip"
            >
              <div class="company-lists-item__smart-icon" />
            </ElTooltip>
          </template>
        </div>
        <p class="company-lists-item__description" v-text="value.description" />
      </div>
      <div class="company-lists-item__column-heading">
        <h4 class="company-lists-item__heading">
          <PxIcon :size="15" name="hashtag" />
          {{ $t("companyLists.list.members") }}
        </h4>
        <h3 class="company-lists-item__subtitle">
          {{
            $tc(
              `companyLists.list.membersCount[${listUserType}]`,
              value.companies.length,
            )
          }}
        </h3>
      </div>
      <div
        v-if="isOwner"
        class="company-lists-item__column-heading company-lists-item__column-logos"
      >
        <h4 class="company-lists-item__heading">
          <PxIcon :size="14" name="arrow-share" />
          {{ $t("companyLists.list.sharedWith") }}
        </h4>
        <PxAvatarSet
          v-if="userAvatars.length"
          :items="userAvatars"
          :size="21"
          :visible-limit="5"
          class="company-lists-item__logo-set"
        />
        <h3 v-else class="company-lists-item__tip">
          <PxIcon :size="16" name="lock" />
          {{ $t("companyLists.list.sharedEmpty") }}
        </h3>
      </div>
      <div class="company-lists-item__actions">
        <ListShare
          v-if="isOwner"
          :value="value"
          class="company-lists-item__actions-item"
        />
        <ListOptions :value="value" class="company-lists-item__actions-item" />
      </div>
      <ElTooltip
        v-if="$screen.mdUp && showPinToTop"
        ref="pinTooltip"
        :disabled="listIsPinned"
        :enterable="false"
        placement="top"
        popper-class="company-lists-item__pin-tooltip el-abaca-tooltip"
      >
        <template #content>
          <div v-text="$t('companyLists.list.pinToTop')"></div>
        </template>
        <div
          :class="{ 'company-lists-item__pin-cta--active': listIsPinned }"
          class="company-lists-item__pin-cta"
          @click.stop="pinToTopClickHandler"
        >
          <PxIcon :size="16" name="pin" />
        </div>
      </ElTooltip>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import { ICompanyList } from "@/modules/company-lists/services/data/company-list/company-list.interface";
import { IAvatarGridItem } from "@/components/px-avatar/px-avatar-grid.vue";
import { IAvatarSetItem } from "@/components/px-avatar/px-avatar-set.vue";
import { ROUTE_COMPANY_LISTS_DETAIL } from "@/modules/company-lists/services/router/routes-names";
import ListOptions from "@/modules/company-lists/components/list-options/list-options.vue";
import ListShare from "@/modules/company-lists/components/list-share/list-share.vue";
import { ENTREPRENEUR_USER_TYPE } from "@/modules/authentication/constants";

export default defineComponent({
  name: "CompanyListsItem",

  components: {
    ListOptions,
    ListShare,
  },

  props: {
    value: {
      type: Object as () => ICompanyList,
      required: true,
    },

    showPinToTop: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      listIsPinned: false,
    };
  },

  computed: {
    smartListTooltipCopy() {
      return this.$t("companyLists.smartList.tooltip");
    },

    isOwner(): boolean {
      return this.value.is_owner;
    },

    isSmarList(): boolean {
      return this.value.is_smart_list;
    },

    companyAvatars(): Array<IAvatarGridItem> {
      return this.value.companies.map((member) => ({
        name: member.name,
        logo: member.logo || "",
      }));
    },

    userAvatars(): Array<IAvatarSetItem> {
      return this.value.invited_users.map((user) => ({
        name: user.name,
        logo: user.photo || "",
      }));
    },

    listUserType(): number {
      return this.value?.type || ENTREPRENEUR_USER_TYPE;
    },
  },

  watch: {
    "value.pinned": {
      immediate: true,
      handler(isPinned: boolean) {
        this.listIsPinned = isPinned;
      },
    },
  },

  methods: {
    listClickHandler() {
      this.$router.push({
        name: ROUTE_COMPANY_LISTS_DETAIL,
        params: { uid: this.value.uid },
      });
    },

    pinToTopClickHandler() {
      // Toggle pinned list:
      this.listIsPinned = !this.listIsPinned;

      // Emit pinned list to reorder list items
      this.$emit("pinned-item", this.value);
    },
  },
});
</script>

<style lang="scss" scoped>
.company-lists-item__wrapper {
  position: relative;
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;

  min-height: 103px;
  padding: 15px 31px 17px 31px;
  cursor: pointer;
  user-select: none;

  background-color: $white;
  border: solid 1px $turkish-angora;
  border-radius: 4px;
  box-shadow: 0 4px 8px -4px rgba(139, 143, 161, 0.2);

  &::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    content: "";
    box-shadow: 0 4px 12px -4px rgba(139, 143, 161, 0.6);
    opacity: 0;
    transition: $--fade-transition;
  }

  &:hover::after {
    opacity: 1;
  }
}

.company-lists-item__logo-grid {
  flex: 0 0 100px;
  padding-top: 4px;
}

.company-lists-item__logo-set {
  position: relative;
  left: -4px;
  padding-top: 7px;
}

.company-lists-item__header {
  display: flex;
  align-items: center;
  gap: 7px;
}

.company-lists-item__content {
  position: relative;
  top: -2px;
  flex: 0 1 400px;
  max-width: 393px;

  &:not(:first-child) {
    margin-left: 34px;
  }

  &:not(:last-child) {
    margin-right: 35px;
  }
}

.company-lists-item__title {
  @include grotesk(bold);

  margin: 0;
  overflow: hidden;
  font-size: 16px;
  line-height: 19px;
  text-overflow: ellipsis;
  letter-spacing: -0.05px;
  white-space: nowrap;
}

.company-lists-item__smart-icon {
  width: 26px;
  height: 26px;

  background-image: url("#{$assetsPath}/img/icons/smart-list.svg");

  &:hover {
    background-image: url("#{$assetsPath}/img/icons/smart-list--hover.svg");
  }
}

.company-lists-item__subtitle {
  @include grotesk(medium);

  margin: auto;
  letter-spacing: -0.2px;

  @include multiLineEllipsis(
    $font-size: 15px,
    $line-height: 18px,
    $lines-to-show: 2
  );
}

.company-lists-item__description {
  @include multiLineEllipsis(
    $font-size: 14px,
    $line-height: 19px,
    $lines-to-show: 2
  );

  margin: 5px auto 0;
  font-size: 14px;
  line-height: 19px;
  color: $manatee;
  letter-spacing: -0.3px;
}

.company-lists-item__tip {
  @include grotesk(medium);

  margin: auto;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.2px;

  :deep() .px-icon {
    margin-right: 2px;
    vertical-align: -3px;
  }
}

.company-lists-item__column-heading.company-lists-item__column-logos {
  margin: 0 0 0 19px;
}

.company-lists-item__column-heading {
  flex: 0 0 110px;
  padding-top: 8px;
  margin: 0 26px;

  &:last-child {
    margin: 0;
  }
}

.company-lists-item__heading {
  @include grotesk(semiBold);

  padding-bottom: 5px;
  margin-bottom: auto;
  font-size: 12px;
  color: rgba($manatee, 0.8);
  text-transform: uppercase;

  :deep() .px-icon {
    margin-right: 2px;
    vertical-align: -3px;
  }
}

.company-lists-item__heading + .company-lists-item__subtitle {
  margin-top: 8px;
  margin-left: 1px;
}

.company-lists-item__heading + .company-lists-item__tip {
  position: relative;
  left: -3px;
  margin-top: 9px;
}

.company-lists-item__actions {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  padding-top: 17px;
  margin-right: 0;
  margin-left: auto;

  :deep() &-item:not(:first-child) {
    margin-left: 12px;
  }
}

.company-lists-item__pin-cta {
  position: absolute;
  top: -1px;
  right: -1px;
  width: 26px;
  height: 26px;
  line-height: 29px;
  text-align: center;
  cursor: pointer;
  user-select: none;

  border: solid 1px $turkish-angora;
  border-radius: 4px;
  border-top-left-radius: 0;
  border-bottom-right-radius: 0;
  box-shadow: 0 4px 8px -4px rgba($manatee, 0.2);

  :deep() .px-icon {
    // To match pixel-perfect:
    width: 17px !important;
  }
}

.company-lists-item__pin-cta--active :deep() {
  background-color: $light-orange;
  border-color: rgba($light-orange, 0.4);
  box-shadow: 0 4px 12px -4px rgba($manatee, 0.2);
  // To avoid flickering issue:
  transform: translateZ(0);

  .px-icon {
    filter: brightness(10);
  }
}

.company-lists-item__pin-cta:not(.company-lists-item__pin-cta--active):hover
  :deep() {
  border-color: $light-orange;

  .px-icon {
    filter: invert(86%) sepia(13%) saturate(6883%) hue-rotate(360deg)
      brightness(130%) contrast(93%);
  }
}
</style>

<style lang="scss">
.company-lists-item__pin-tooltip {
  padding: 5px 10px;
  text-transform: none;
}

.company-lists-smart-item__tooltip {
  padding: 10px 15px;
  width: 340px;
  letter-spacing: -0.17px;
  text-transform: none;
}

.company-lists-item .list-share__toggler {
  min-width: 75px;
}
</style>
