<template>
  <div :class="{ 'is-disabled': disabled }" class="category-wizard">
    <div class="category-wizard__container">
      <div class="category-wizard__steps">
        <div class="category-wizard__steps-container">
          <div
            v-for="(step, index) in steps"
            :key="step.value"
            :class="{
              'is-active': currentStep === index,
              'is-checked': isStepChecked(step),
            }"
            class="category-wizard-step is-clickable"
            @click="stepClickHandler(index)"
          >
            <p class="category-wizard-step__label">
              {{ step.label }}
            </p>
            <div class="category-wizard-step__circles">
              <div
                class="category-wizard-step__circle category-wizard-step__circle--point"
              />
              <div
                :class="{
                  'is-visible':
                    index <= currentStep || index <= higherCheckedIndex,
                  'is-checked': isStepChecked(step),
                  'is-active': currentStep === index,
                }"
                :style="stepCircleStyle(step)"
                class="category-wizard-step__circle category-wizard-step__circle--bigger is-clickable"
              />
            </div>
          </div>
        </div>
        <div :style="progressStyle" class="category-wizard__progress" />
        <div class="category-wizard__final-step">
          {{ $t("selfAssessment.assessment.wizard.finalStep") }}
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import { colorShade } from "@/utils/color-shade";
import { ICategoryStepItem } from "@/modules/affiliates/views/affiliate.types";

const COLOR_VARIATION_AMOUNT = -20;
const DESIGN_LEFT_SPACE = 3;

export default defineComponent({
  name: "CategoryWizard",

  props: {
    steps: {
      type: Array as () => ICategoryStepItem[],
      required: true,
    },

    currentStep: {
      type: Number,
      default: 0,
    },

    active: {
      type: Number,
      default: null,
    },

    checked: {
      type: Array as () => any[],
      default: () => [],
    },

    /**
     * Disable the user interaction with the wizard.
     */
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      maxIndexRetched: 0,
    };
  },

  computed: {
    /**
     * Computes the higher checked index.
     */
    higherCheckedIndex() {
      const checkedSteps: Array<number> = this.checked;
      if (checkedSteps.length === 0) {
        return -1;
      }

      let higherIndex = -1;
      checkedSteps.forEach((item: number) => {
        const index = this.steps.findIndex(
          (step: ICategoryStepItem) => step.value === item,
        );
        higherIndex = Math.max(index, higherIndex);
      });

      return higherIndex;
    },

    /**
     * Return the progress bar width based on the higher checked step.
     */
    progressStyle() {
      const perStep = 100 / this.steps.length + 1;
      const target = Math.max(this.higherCheckedIndex, this.currentStep);
      const currentProgress = perStep * target + DESIGN_LEFT_SPACE;

      return {
        width: `${currentProgress}%`,
      };
    },
  },

  methods: {
    /**
     * Checks if the given step is part of the set of
     * current selected steps.
     */
    isStepChecked(step: ICategoryStepItem): boolean {
      return this.checked.includes(step.value);
    },

    /**
     * Generates the circle colors for the given step.
     */
    stepCircleStyle(step: ICategoryStepItem): { [key: string]: string } {
      const stepColor = `#${step.color}`;
      const newBorderColor = colorShade(stepColor, COLOR_VARIATION_AMOUNT);

      return {
        "--lightColor": stepColor,
        "--darkColor": newBorderColor,
      };
    },

    stepClickHandler(index: number) {
      this.$emit("update:currentStep", index);
      this.$emit("changed", index);
    },
  },
});
</script>

<style lang="scss" scoped>
.category-wizard {
  position: relative;
  padding-top: 50px;

  &__container {
    display: flex;
    flex-direction: row;
    align-items: center;

    @include breakpoint-only(md) {
      min-height: 29px;
    }
  }

  &__steps {
    position: relative;
    left: calc(50% - 30px);

    transform: translateX(-50%);

    @media (min-width: 1015px) {
      left: calc(50% - 54px);
    }
  }

  &__steps::before {
    position: absolute;
    right: 0;
    bottom: 8px;
    left: 0;
    z-index: z("bellow") - 1;
    display: block;
    width: calc(100% + 18px);
    height: 3px;

    content: "";

    background-color: $mischka;
    border-radius: 1px;
  }

  &__steps-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }

  &__progress {
    position: absolute;
    bottom: 8px;
    z-index: z("bellow");
    display: block;
    width: 0;
    max-width: calc(100% + 18px);
    height: 3px;

    background-color: #1865c4;
    border-radius: 1px;

    transition: all 0.2s ease-in-out;
  }

  &__final-step {
    @include grotesk(bold);

    position: absolute;

    font-size: 0.938rem;
    line-height: 1.29;
    letter-spacing: 0.4px;

    @include breakpoint-down(md) {
      top: -8px;
      right: -90px;
      max-width: 60px;
    }

    @media (min-width: 955px) {
      right: -140px;
      bottom: 3px;
      width: 90px;
      text-align: left;
      word-break: break-all;
    }
  }
}

.category-wizard-step__label {
  position: absolute;
  top: calc(-100% - 11px);
  left: 50%;
  width: 92px;
  margin-bottom: 24px;

  font-size: 1rem;
  line-height: normal;
  color: $manatee;
  text-align: center;

  transform: translate(-50%, -50%);
}

.category-wizard-step.is-checked {
  .category-wizard-step__label {
    color: $ebony-clay;
  }
}

.category-wizard-step.is-active {
  .category-wizard-step__label {
    @include grotesk(semiBold);

    color: $ebony-clay;
  }
}

.category-wizard-step {
  position: relative;
  bottom: -7px;
  max-width: 92px;
  margin-right: 93px;

  @include breakpoint-down(md) {
    margin-right: 70px;
  }

  &:first-child {
    margin-left: 17px;
  }

  &:last-child {
    margin-right: 0;
  }

  &__circles {
    position: relative;
    bottom: 7px;
    left: 50%;
    width: 20px;
    height: 20px;

    transform: translateX(-50%);
  }

  &.is-clickable {
    cursor: pointer;
  }

  &.is-clickable:hover .category-wizard-step__label {
    @include grotesk(semiBold);
  }
}

.category-wizard-step__circle {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: z("bellow");
  width: 10px;
  height: 10px;

  background-color: $manatee;
  border: solid 2px $alabaster;
  border-radius: 50%;

  opacity: 0;

  transition: opacity 0.2s ease-in-out;
  transform: translate(-50%, -50%);

  &::before {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 20px;
    height: 20px;

    content: "";

    background-color: transparent;
    border: solid 2px $alabaster;
    border-radius: 50%;

    opacity: 0;

    transform: translate(-50%, -50%);
  }

  &--point {
    opacity: 1;
  }

  &--bigger {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 16px;
    height: 16px;

    background-color: var(--lightColor, transparent);
    border: solid 1px var(--darkColor, $manatee);
    border-radius: 50%;

    transform: translate(-50%, -50%);
  }

  &--bigger:not(.is-checked):not(.is-active) {
    background-color: $athens-gray !important;
    border-color: $manatee !important;
  }

  &--active-border {
    position: absolute;
    width: 16px;
    height: 16px;

    background-color: transparent;
    border: solid 2px transparent;
    border-radius: 50%;
  }

  &.is-visible,
  &.is-visible::before {
    opacity: 1;
  }

  &.is-active {
    border-color: rgba(24, 24, 24, 0.2) !important;
  }

  &.is-active::after {
    position: absolute;
    top: -1px;
    left: -1px;
    width: 16px;
    height: 16px;

    content: "";

    background-color: transparent;
    border-radius: 50%;
  }

  &.is-checked::after {
    position: absolute;
    top: -1px;
    left: -1px;
    width: 16px;
    height: 16px;

    content: "";

    background-image: url("#{$assetsPath}/img/icons/check--white.svg");
  }

  &.is-active.is-checked::after {
    background-image: none;
  }

  &--bigger.is-active::before {
    width: 28px;
    height: 28px;

    border-color: var(--darkColor);
    border-width: 3px;
  }

  &--bigger.is-active:not(.is-checked)::before {
    width: 28px;
    height: 28px;

    border-color: var(--darkColor);
    border-width: 3px;
  }

  &--bigger.is-active::after {
    top: 50%;
    left: 50%;
    width: 34px;
    height: 34px;

    background-image: none;
    border: 3px solid #fafafa;
    transform: translate(-50%, -50%);
  }
}

/* --- First circle active */

.category-wizard-step.is-active .category-wizard-step__circle--point::before {
  width: 22px;
  height: 22px;

  border-width: 3px;
  opacity: 1;
}

.category-wizard.is-disabled {
  pointer-events: none;
}
</style>
