<template>
  <ContentPlaceholders :rounded="true" class="px-questionary-placeholder">
    <ContentPlaceholdersHeading />
    <ContentPlaceholdersImg class="px-questionary-placeholder__options" />
    <div
      v-if="$user.isSupporter()"
      class="px-questionary-placeholder__criteria"
    >
      <ContentPlaceholdersText :lines="2" />
      <ContentPlaceholdersImg />
    </div>
    <div class="px-questionary-placeholder__footer">
      <ContentPlaceholdersImg />
    </div>
  </ContentPlaceholders>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "PxQuestionaryPlaceholder",
});
</script>

<style lang="scss" scoped>
.px-questionary-placeholder :deep() {
  padding: 15px 20px 25px;

  .vue-content-placeholders-heading__title,
  .vue-content-placeholders-heading__subtitle,
  .vue-content-placeholders-text__line,
  .vue-content-placeholders-img {
    background-color: $manatee;
  }
}

.px-questionary-placeholder__options {
  margin-top: 20px;
  background-color: $manatee;
}

.px-questionary-placeholder__criteria :deep() {
  margin-top: 40px;

  .vue-content-placeholders-text__line {
    height: 10px;
    margin: 0 auto 10px;
  }

  .vue-content-placeholders-img {
    height: 40px;
  }
}

.px-questionary-placeholder__footer :deep() {
  margin-top: 30px;

  .vue-content-placeholders-img {
    height: 36px;
  }
}
</style>
