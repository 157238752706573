<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="173"
    height="173"
    viewBox="0 0 173 173"
  >
    <defs>
      <linearGradient id="viral-graph-2-c" x1="50%" x2="50%" y1="0%" y2="100%">
        <stop offset="0%" stop-color="#58A0F8" class="linear-gradient-color1" />
        <stop
          offset="100%"
          stop-color="#2176DD"
          class="linear-gradient-color2"
        />
      </linearGradient>
      <path
        id="viral-graph-2-b"
        d="M26.9654172,46.3665113 L36.6007674,58.0349079 C29.7051897,63.7226641 24.0533482,70.7805771 20.0304014,78.7396226 C17.4013707,77.4461242 9.30112009,73.495368 6.42985589,72.0949588 C11.4030969,62.1943486 18.4084804,53.4246247 26.9654172,46.3665113 Z"
      />
      <filter
        id="viral-graph-2-a"
        width="166.3%"
        height="161.8%"
        x="-33.1%"
        y="-24.7%"
        filterUnits="objectBoundingBox"
      >
        <feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur
          in="shadowOffsetOuter1"
          result="shadowBlurOuter1"
          stdDeviation="3"
        />
        <feComposite
          in="shadowBlurOuter1"
          in2="SourceAlpha"
          operator="out"
          result="shadowBlurOuter1"
        />
        <feColorMatrix
          in="shadowBlurOuter1"
          values="0 0 0 0 0.333333333 0 0 0 0 0.623529412 0 0 0 0 0.976470588 0 0 0 0.5 0"
          class="color-matrix"
        />
      </filter>
      <linearGradient id="viral-graph-2-f" x1="50%" x2="50%" y1="0%" y2="100%">
        <stop offset="0%" stop-color="#64A9FE" class="linear-gradient-color3" />
        <stop
          offset="100%"
          stop-color="#1865C4"
          class="linear-gradient-color2"
        />
      </linearGradient>
      <path
        id="viral-graph-2-e"
        d="M92.30625,134.548387 C123.955618,134.548387 149.6125,108.905857 149.6125,77.2741935 C149.6125,45.6425299 123.955618,20 92.30625,20 C60.6568821,20 35,45.6425299 35,77.2741935 C35,108.905857 60.6568821,134.548387 92.30625,134.548387 Z M92.30625,134.548387 C123.955618,134.548387 149.6125,108.905857 149.6125,77.2741935 C149.6125,45.6425299 123.955618,20 92.30625,20 C60.6568821,20 35,45.6425299 35,77.2741935 C35,108.905857 60.6568821,134.548387 92.30625,134.548387 Z M92.30625,134.548387 C123.955618,134.548387 149.6125,108.905857 149.6125,77.2741935 C149.6125,45.6425299 123.955618,20 92.30625,20 C60.6568821,20 35,45.6425299 35,77.2741935 C35,108.905857 60.6568821,134.548387 92.30625,134.548387 Z"
      />
      <filter
        id="viral-graph-2-d"
        width="141%"
        height="141%"
        x="-20.5%"
        y="-18.8%"
        filterUnits="objectBoundingBox"
      >
        <feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur
          in="shadowOffsetOuter1"
          result="shadowBlurOuter1"
          stdDeviation="7.5"
        />
        <feComposite
          in="shadowBlurOuter1"
          in2="SourceAlpha"
          operator="out"
          result="shadowBlurOuter1"
        />
        <feColorMatrix
          in="shadowBlurOuter1"
          values="0 0 0 0 0.325490196 0 0 0 0 0.584313725 0 0 0 0 0.894117647 0 0 0 0.5 0"
          class="color-matrix"
        />
      </filter>
      <linearGradient
        id="viral-graph-2-g"
        x1="50%"
        x2="50%"
        y1="0%"
        y2="98.69%"
      >
        <stop offset="0%" stop-color="#FFF" />
        <stop
          offset="100%"
          stop-color="#D5E3F4"
          stop-opacity="0"
          class="linear-gradient-color2"
        />
      </linearGradient>
    </defs>
    <g fill="none" fill-rule="evenodd" transform="translate(-6 18)">
      <path
        fill="#E5E8EE"
        fill-rule="nonzero"
        d="M158.781261,77.3612903 C158.720385,69.5150278 157.302303,61.9916682 154.75005,55.0141512 L164.647864,50.9253432 C167.715506,59.1648413 169.417822,68.0683892 169.479931,77.3612903 L158.781262,77.3612903 Z M158.738308,80.3612903 L169.442824,80.3612903 C169.129758,90.3067662 166.929825,99.9606146 162.998018,108.919826 L153.201498,104.62056 C156.541755,97.0092853 158.429974,88.8166052 158.738297,80.3612907 Z M153.655638,52.220353 C150.188296,43.927862 145.091598,36.4852917 138.765395,30.2923204 L145.9226,22.3293228 C153.425481,29.5886875 159.462329,38.3526146 163.546238,48.1345253 L153.655638,52.220353 Z M136.574755,28.2417643 C129.624775,22.0220638 121.361094,17.2385464 112.250836,14.3581347 L115.069899,4.0273505 C125.812476,7.35791717 135.552902,12.965229 143.726431,20.284919 L136.574755,28.2417643 Z M109.370208,13.5187103 C103.868431,12.0499399 98.0863514,11.2669355 92.12125,11.2669355 C87.4777243,11.2669355 82.9204192,11.7456186 78.5041736,12.6635718 L75.9693804,2.26339977 C81.2034369,1.14983439 86.6101239,0.568548387 92.12125,0.568548387 C99.0612884,0.568548387 105.787912,1.48194542 112.187333,3.19502951 L109.370208,13.5187103 Z M75.5812781,13.3402443 C66.9018226,15.5570751 58.8322281,19.4871653 51.8078542,24.8176203 L45.1254037,16.4591513 C53.3591544,10.1652434 62.8417163,5.53452719 73.0476011,2.9446518 L75.5812781,13.3402443 Z M49.4593928,26.6843822 C42.3018636,32.6411167 36.3635829,40.1181868 32.156365,48.7477493 L22.5399717,44.0594164 C27.4525302,33.9831065 34.4004821,25.2603872 42.7776995,18.3268603 L49.4593928,26.6843822 Z"
      />
      <path
        class="linear-gradient-color4"
        fill="#7CB8FF"
        fill-rule="nonzero"
        d="M25.4632656,77.3612903 L14.7588588,77.3612903 C14.8029659,66.3381619 16.2476461,57.5219399 20.7835393,47.6513616 L30.5110204,52.1164967 C26.6155272,60.5934976 25.5024162,67.8975559 25.4632683,77.3612897 Z M25.5071998,80.3612908 C25.833214,89.3013722 27.9254099,97.9459846 31.6287259,105.915639 L21.9202494,110.4219 C17.5646412,101.048492 15.1274901,90.8690921 14.7966867,80.3612906 L25.5071865,80.3612903 Z"
      />
      <path
        fill="#FFF"
        fill-rule="nonzero"
        d="M91.9785091,77.7500577 L92.3134621,76.9516348 L93.0910808,77.2778601 L93.8673354,76.9484021 L94.2056076,77.7454244 L173.827155,111.148119 L172.698413,113.838688 L93.0976692,80.4447206 L21.2832896,110.924179 L20.1433618,108.23833 L91.9785091,77.7500577 Z"
      />
      <g fill-rule="nonzero" transform="rotate(-26 21.515 62.553)">
        <use
          fill="#000"
          filter="url(#viral-graph-2-a)"
          xlink:href="#viral-graph-2-b"
        />
        <use fill="url(#viral-graph-2-c)" xlink:href="#viral-graph-2-b" />
      </g>
      <use
        fill="#000"
        filter="url(#viral-graph-2-d)"
        xlink:href="#viral-graph-2-e"
      />
      <ellipse
        cx="93.338"
        cy="77.565"
        rx="56.819"
        ry="56.79"
        fill="url(#viral-graph-2-f)"
        stroke="#181818"
        stroke-linejoin="round"
        stroke-opacity=".2"
      />
      <ellipse
        cx="92.338"
        cy="77.565"
        stroke="url(#viral-graph-2-g)"
        stroke-width="2"
        opacity=".402"
        rx="49.819"
        ry="49.79"
      />
      <path
        fill="#FFF"
        d="M75.4458008,96 L75.4458008,89.8168945 C75.6918945,89.6323242 84.7666016,84.2797852 91.503418,77.6660156 C95.4101562,73.8208008 98.4555664,69.7294922 98.4555664,65.269043 C98.4555664,61.7929688 96.6098633,58.9628906 92.3339844,58.9628906 C86.6738281,58.9628906 84.9511719,63.8847656 84.7666016,68.3452148 L77.1376953,67.8530273 C77.1376953,59.7626953 82.7055664,51.9492188 92.2724609,51.9492188 C100.270508,51.9492188 106.269043,57.394043 106.269043,64.8076172 C106.269043,71.5444336 101.285645,77.8505859 96.3022461,82.6494141 C93.3491211,85.4794922 90.3652344,87.6943359 88.3041992,88.9555664 L108.391602,88.9555664 L108.391602,96 L75.4458008,96 Z"
      />
    </g>
  </svg>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "Level2",
});
</script>
