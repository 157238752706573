import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-ccfe5fb8"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "assessmentFluctuation-lineWrapper assessmentFluctuation-lineWrapper--up"
}
const _hoisted_2 = { class: "assessmentFluctuation-line" }
const _hoisted_3 = {
  key: 1,
  class: "assessmentFluctuation-lineWrapper assessmentFluctuation-lineWrapper--down"
}
const _hoisted_4 = { class: "assessmentFluctuation-line" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([_ctx.align, "assessmentFluctuation"])
  }, [
    (_ctx.upChanges.length > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.upChanges), 1)
        ]))
      : _createCommentVNode("", true),
    (_ctx.downChanges.length > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.downChanges), 1)
        ]))
      : _createCommentVNode("", true)
  ], 2))
}