<template>
  <div class="list-share-users">
    <PxModalMessage
      v-model:visible="listHasBeenShared"
      :timeout="5000"
      :title="successShareMessage"
      data-testid="list-share-users__success-message"
      type="success"
    />
    <ListShareUsersSearch
      v-model:has-saved-users="listHasBeenShared"
      :users="users"
      class="list-share-users__header"
      @send-email="sendEmailHandler"
    />
    <ListShareUsersIndex
      v-loading="listLoading"
      :users="users"
      class="list-share-users__body"
    />
    <ListShareActions
      :link="link"
      :passcode="passcode"
      class="list-share-users__footer"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { TranslateResult } from "vue-i18n";
import { ICompanyListInvitedUser } from "@/modules/company-lists/services/data/company-list/company-list.interface";

import ListShareUsersSearch from "@/modules/company-lists/components/list-share/list-share-users-search.vue";
import ListShareUsersIndex from "@/modules/company-lists/components/list-share/list-share-users-index.vue";
import ListShareActions from "@/modules/company-lists/components/list-share/list-share-actions.vue";
import { CompanyListState } from "@/modules/company-lists/services/store/company-list/company-list.modules";
import { AxiosError } from "axios";

export default defineComponent({
  name: "ListShareUsers",

  components: {
    ListShareUsersSearch,
    ListShareUsersIndex,
    ListShareActions,
  },

  props: {
    uid: {
      type: String,
      required: true,
    },

    users: {
      type: Array as () => Array<ICompanyListInvitedUser>,
      required: true,
    },

    link: {
      type: String,
      required: true,
    },

    passcode: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      usersInvited: [] as Array<ICompanyListInvitedUser["user_profile"]>,
      listHasBeenShared: false,
    };
  },

  computed: {
    listLoading(): boolean {
      return this.$store.get(CompanyListState.Getter.IS_LOADING);
    },

    listErrors(): AxiosError {
      return this.$store.get(CompanyListState.Getter.ERROR);
    },

    newInvitedUser(): string | undefined {
      return this.users.find((user) =>
        this.usersInvited.includes(user.user_profile),
      )?.name;
    },

    successShareMessage(): TranslateResult {
      return this.usersInvited.length > 1
        ? this.$t(
            `companyLists.list.shareModal.messages.listSharedMultipleUsers`,
          )
        : this.$t(`companyLists.list.shareModal.messages.listShared`, {
            users: this.newInvitedUser,
          });
    },
  },

  methods: {
    sendEmailHandler(
      invitedUsers: Array<ICompanyListInvitedUser["user_profile"]>,
    ) {
      this.usersInvited = invitedUsers;

      const usersToBeInvited = [
        ...this.usersInvited,
        ...this.users
          .filter((user) => !!user?.user_profile)
          .map((user) => user.user_profile),
      ];

      this.$store
        .dispatch(CompanyListState.Action.PATCH_VALUE, {
          uid: this.uid,
          shouldReload: true,
          payload: {
            invited_users: usersToBeInvited,
          },
          setLoading: false,
        })
        .then(() => {
          if (!this.listErrors) {
            this.listHasBeenShared = true;
            return;
          }

          // Show generic error message
          this.$message({
            message: this.$t("common.errors.global.alertTitle"),
            type: "error",
            customClass: "is-full",
          });
        });
    },
  },
});
</script>

<style lang="scss" scoped>
$headerMaxHeight: 100px;

.list-share-users {
  display: flex;
  flex-flow: column wrap;
  align-items: flex-start;
  height: 513px;
  overflow: hidden;
}

.list-share-users__header {
  flex: 0 0 auto;
  width: 100%;
  max-height: $headerMaxHeight;
}

.list-share-users__body {
  flex: 1;
  width: 100%;
  max-height: calc(100% - #{$headerMaxHeight});
  padding-right: 0;
  // To avoid overlap with footer:
  padding-bottom: 100px;
  overflow: auto;

  &::-webkit-scrollbar {
    display: none;
  }

  :deep(.px-loading-mask) {
    bottom: -10px;
    z-index: z("floaters") - 1;
  }
}

.list-share-users__footer {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: z("floaters");
  width: 100%;
  min-height: 118px;
  padding: 13px 32px 25px;
  background-color: $alabaster;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  box-shadow: inset 0 1px 0 0 $mischka;
}
</style>
