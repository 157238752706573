import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-6fcf7cd5"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "milestone-carousel-description" }
const _hoisted_2 = { class: "milestone-carousel-description__tooltip-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ElTooltip = _resolveComponent("ElTooltip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.category.milestones, (milestone) => {
      return (_openBlock(), _createElementBlock("div", {
        key: milestone.level,
        class: _normalizeClass([
        {
          'milestone-carousel-description--above-levels': !milestone.completed,
        },
      ])
      }, [
        _createElementVNode("p", {
          class: _normalizeClass([[
          {
            'milestone-carousel-description__text--long-text':
              milestone.description.length > 72,
          },
        ], "milestone-carousel-description__text"])
        }, _toDisplayString(milestone.description), 3),
        (milestone.description.length > 72)
          ? (_openBlock(), _createBlock(_component_ElTooltip, {
              key: 0,
              content: milestone.description,
              placement: "top",
              "popper-class": "milestone-carousel-description__tooltip el-abaca-tooltip"
            }, {
              default: _withCtx(() => [
                _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.$t("common.more")), 1)
              ]),
              _: 2
            }, 1032, ["content"]))
          : _createCommentVNode("", true)
      ], 2))
    }), 128))
  ]))
}