<template>
  <div class="submission-tab-team-members">
    <h2 class="submission-tab-team-members__print-title">
      {{ $t("supporters.results.submissionPanel.tabs.teamMembers") }}
    </h2>
    <ElCollapse
      v-for="member in teamMembers"
      :key="member.id"
      :accordion="true"
      class="submission-tab-team-members__wrapper"
    >
      <ElCollapseItem
        :disabled="!member.responses.length"
        class="submission-tab-team-members__item"
      >
        <template #title>
          <div class="submission-tab-team-members__data">
            <h4>{{ member.first_name }} {{ member.last_name }}</h4>
            <p class="submission-tab-team-members__position">
              {{ member.position }}
            </p>
            <p class="submission-tab-team-members__email">
              {{ member.email }}
            </p>
          </div>
        </template>
        <!-- Responses -->
        <div
          v-if="member.responses.length"
          class="submission-tab-team-members__responses-wrapper"
        >
          <div
            v-for="response in member.responses"
            :key="response.id"
            class="submission-tab-team-members__response"
          >
            <h4>
              {{ response.question.resource_question }}
            </h4>
            <p v-html="formattedResponse(response)" />
          </div>
        </div>
      </ElCollapseItem>
    </ElCollapse>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import FormatMatchingResponseValuesMixin from "@/mixins/format-matching-response-values.mixin";

import { IMatchingResponse } from "@/services/data/matching-responses/matching-response.interface";
import { ITeamMember } from "@/modules/team-management/services/data/team-members/team-members.interface";
import { IMatchingQuestion } from "@/services/data/matching-questionary/matching-question.interface";

export default defineComponent({
  name: "SubmissionTabTeamMembers",

  mixins: [FormatMatchingResponseValuesMixin],

  props: {
    teamMembers: {
      type: Array as () => Array<ITeamMember>,
      required: true,
    },
  },

  methods: {
    formattedResponse(response: IMatchingResponse) {
      let formattedValue = "";

      if (response.value?.value) {
        formattedValue = this.formatNumericValue(response);
      } else if (
        (response.value?.min || response.value?.min === 0) &&
        response.value?.max
      ) {
        formattedValue = this.formatRangeValue(response);
      } else if (response.value?.text) {
        formattedValue = this.formatFreeResponseValue(response);
      } else if (response.value?.date) {
        formattedValue = this.formatDateValue(response);
      } else if (response.answers.length) {
        formattedValue = response.answers.length
          ? this.formatMultipleAnswersValue(
              response.question as IMatchingQuestion,
              response,
            )
          : "";
      }
      return formattedValue || `<i>${this.$t("common.noAnswer")}</i>`;
    },
  },
});
</script>

<style lang="scss" scoped>
.submission-tab-team-members {
  padding: 38px 33px 41px 35px;
  max-height: 800px;
  overflow-x: hidden;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: $mischka;
    border-radius: 10px;
  }

  h4 {
    @include grotesk(bold);

    margin: 0;
    font-size: 17px;
    color: $ebony-clay;
  }

  &__item {
    margin-bottom: 16px;

    border: 1px solid $mischka;
    border-radius: 4px;
    background: $white;

    transition: all 0.2s ease-in-out;
  }

  &__data {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 22px;

    p {
      position: relative;
      top: 1px;
      line-height: 1;
      letter-spacing: -0.4px;
    }

    p::before {
      content: "";
      position: absolute;
      bottom: 3px;
      left: -14px;
      width: 6px;
      height: 6px;
      background-color: $mischka;
      border-radius: 50%;
    }
  }

  &__position {
    @include grotesk(bold);

    color: $manatee;
    font-size: 17px;
  }

  &__email {
    color: $ebony-clay;
    font-size: 14px;
  }

  &__responses-wrapper {
    padding: 20px 23px 9px 23px;
    max-height: 310px;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background: $mischka;
      border-radius: 10px;
    }
  }

  &__response {
    margin-bottom: 20px;

    h4 {
      margin-bottom: 3px;
      letter-spacing: -0.07px;
    }

    p {
      color: $ebony-clay;
      font-size: 15px;
      letter-spacing: -0.3px;
      margin-bottom: 18px;

      word-break: break-all;
      overflow-wrap: break-word;
      hyphens: auto;
    }
  }

  &__response:not(:last-of-type) {
    border-bottom: 1px solid $athens-gray;
  }

  :deep(.el-collapse-item__wrap) {
    margin: 0;
    background-color: $alabaster;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  :deep(.el-collapse-item__header) {
    padding: 0 23px 3px;
    border: none;
    transition: none;
    background: transparent;

    &.is-active {
      border-bottom: 1px solid $mischka;
    }

    &::before {
      top: 0;
      right: 23px;
      bottom: 0;
      width: 16px;
      height: 16px;
      margin: auto 0;
      background-color: transparent;
      background-image: url("#{$assetsPath}/img/icons/arrow.svg");
      background-repeat: no-repeat;
      background-position: center;
      transition: $--all-transition;
    }

    &::after {
      display: none;
    }
  }

  :deep().el-collapse-item__header,
  :deep().el-collapse-item__wrap {
    background: transparent;
    border: 0;
  }

  :deep() .el-collapse-item.is-disabled .el-collapse-item__header::before {
    display: none;
  }

  :deep() .el-collapse-item__content {
    padding: 0;
  }

  &__print-title {
    display: none;
  }
}
</style>
