import { ActionTree } from "vuex";
import { RootState } from "@/services/store/root-state";
import { getBaseName } from "@/services/store/utils/get-base-name";
import {
  ESupporterFlowAction,
  ISupporterFlowState,
} from "@/modules/supporters/services/store/supporter-flow/supporter-flow.interface";
import {
  pendingSupporterFinishedProvider,
  pendingSupporterProvider,
} from "@/modules/supporters/services/data/supporter-flow/pending-supporter.provider";
import {
  supporterFlowInitialState,
  SupporterFlowState,
} from "@/modules/supporters/services/store/supporter-flow/supporter-flow.module";
import { ILoginResponse } from "@/modules/authentication/services/data/login/login.interface";
import { ISupporterFlow } from "@/modules/supporters/services/data/supporter-flow/supporter-flow.interface";
import { EAuthActions } from "@/modules/authentication/services/store/auth/auth-types";
import { affiliateProgramProgramSubmissionProvider } from "@/modules/supporters/services/data/affiliate-program-supporter-submission/affiliate-program-supporter-submission.provider";

export const actions: ActionTree<ISupporterFlowState, RootState> = {
  /**
   * Create new entry on endpoint to retrieve a token.
   */
  async [getBaseName(ESupporterFlowAction.CREATE_PENDING_SUPPORTER)](
    { commit, state },
    payload: {
      email: string;
      affiliate?: number;
    },
  ) {
    commit(SupporterFlowState.Mutation.SET_LOADING, true);
    commit(SupporterFlowState.Mutation.SET_ERROR, null);

    let requestError = null;

    try {
      // Submit new pending supporter creation by email, and expect to receive token if successful
      const data = await pendingSupporterProvider.create(payload);

      // Reset value and set new email and token
      commit(SupporterFlowState.Mutation.SET_VALUE, {
        ...supporterFlowInitialState,
        ...data,
        ...payload,
        options: {
          ...state.value.options,
          started: true,
        },
      });
    } catch (error) {
      commit(SupporterFlowState.Mutation.SET_ERROR, error || true);
      requestError = error;
    } finally {
      commit(SupporterFlowState.Mutation.SET_LOADING, false);
    }

    if (!!requestError) {
      throw requestError;
    }
  },

  /**
   * Update batch data via endpoint.
   */
  async [getBaseName(ESupporterFlowAction.UPDATE_PENDING_SUPPORTER)](
    { state, commit },
    fields,
  ) {
    // If no token found, trigger error and return null
    if (!state.value?.token) {
      commit(SupporterFlowState.Mutation.SET_ERROR, true);
      return null;
    }

    commit(SupporterFlowState.Mutation.SET_LOADING, true);
    commit(SupporterFlowState.Mutation.SET_ERROR, null);

    // Fetch state values
    const value = state.value;
    let requestError = null;

    try {
      commit(SupporterFlowState.Mutation.SET_VALUE, { ...value, ...fields });

      // Remove meta
      const newFields = { ...fields };
      delete newFields.meta;
      delete newFields.options;

      // Send batch of supporter data, with token
      await pendingSupporterProvider.create({
        ...newFields,
        token: state.value.token,
      });
    } catch (error) {
      commit(SupporterFlowState.Mutation.SET_ERROR, error || true);
      requestError = error;
    } finally {
      commit(SupporterFlowState.Mutation.SET_LOADING, false);
    }

    if (!!requestError) {
      throw requestError;
    }
  },

  /**
   * Set new values on store.
   */
  async [getBaseName(ESupporterFlowAction.SET)]({ state, commit }, fields) {
    commit(SupporterFlowState.Mutation.SET_LOADING, false);
    commit(SupporterFlowState.Mutation.SET_ERROR, null);

    commit(SupporterFlowState.Mutation.SET_VALUE, {
      ...state.value,
      ...fields,
    });
  },

  /**
   * Finish pending supporter registration.
   */
  async [getBaseName(ESupporterFlowAction.SUBMIT_PENDING_SUPPORTER)]({
    state,
    commit,
    dispatch,
  }) {
    commit(SupporterFlowState.Mutation.SET_LOADING, true);
    commit(SupporterFlowState.Mutation.SET_ERROR, null);

    // Fetch token
    const token = state.value?.token || "";
    let requestError = null;

    try {
      // Send batch of supporter data, with token, and expect login response with user values
      const data = (await pendingSupporterFinishedProvider.create({
        token,
      } as Partial<ISupporterFlow>)) as ILoginResponse;

      // Update state to include flow submitted
      commit(SupporterFlowState.Mutation.SET_VALUE, {
        ...state.value,
        options: {
          ...state.value.options,
          submitted: true,
        },
      });

      await dispatch(EAuthActions.LOGIN_WITH_HASH, data, { root: true });
    } catch (error) {
      commit(SupporterFlowState.Mutation.SET_ERROR, error || true);
      requestError = error;
    } finally {
      commit(SupporterFlowState.Mutation.SET_LOADING, false);
    }

    if (!!requestError) {
      throw requestError;
    }
  },

  /**
   * Submit submission data from already existing supporter.
   */
  async [getBaseName(ESupporterFlowAction.SUBMIT_EXISTING_SUPPORTER)]({
    state,
    commit,
  }) {
    commit(SupporterFlowState.Mutation.SET_LOADING, true);
    commit(SupporterFlowState.Mutation.SET_ERROR, null);

    // Fetch token
    const {
      affiliate,
      supporter,
      criteria,
      additional_criteria,
      importances,
      team_members,
    } = state.value;
    let requestError = null;

    try {
      const { uid } = await affiliateProgramProgramSubmissionProvider.create({
        affiliate,
        supporter,
        criteria,
        additional_criteria,
        importances,
        team_members,
      });

      // Reset pending value but store submission uid
      commit(SupporterFlowState.Mutation.SET_VALUE, {
        ...state.value,
        options: {
          ...state.value.options,
          submitted: true,
          submissionUid: uid,
        },
      });
    } catch (error) {
      commit(SupporterFlowState.Mutation.SET_ERROR, error || true);
      requestError = error;
    } finally {
      commit(SupporterFlowState.Mutation.SET_LOADING, false);
    }

    if (!!requestError) {
      throw requestError;
    }
  },

  /**
   * Reset store module state.
   */
  async [getBaseName(ESupporterFlowAction.RESET)]({ commit }) {
    commit(SupporterFlowState.Mutation.SET_LOADING, false);
    commit(SupporterFlowState.Mutation.SET_ERROR, null);

    // Set initial state and start flow
    commit(SupporterFlowState.Mutation.SET_VALUE, supporterFlowInitialState);
  },
};
