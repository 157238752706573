import {
  EListPasscodeActions,
  EListPasscodeGetters,
  EListPasscodeMutations,
  IListPasscodePayload,
  IListPasscodeState,
} from "@/modules/company-lists/services/store/passcode/passcode.interface";

import { RootState } from "@/services/store/root-state";
import { getBaseName } from "@/services/store/utils/get-base-name";
import { ActionTree, GetterTree, Module, MutationTree } from "vuex";

/**
 * Initial module state.
 */
const state: IListPasscodeState = {
  passcodes: {},
};

const getters: GetterTree<IListPasscodeState, RootState> = {
  [getBaseName(EListPasscodeGetters.GET)](state) {
    return (listUid: string) => state.passcodes[listUid] || null;
  },
};

const mutations: MutationTree<IListPasscodeState> = {
  [getBaseName(EListPasscodeMutations.SET)](
    state,
    payload: IListPasscodePayload,
  ) {
    state.passcodes = {
      ...state.passcodes,
      [payload.listUid]: payload.passcode,
    };
  },

  [getBaseName(EListPasscodeMutations.RESET)](state) {
    state.passcodes = {};
  },
};

const actions: ActionTree<IListPasscodeState, RootState> = {
  /**
   * Add a new passcode to the current user store.
   */
  [getBaseName(EListPasscodeActions.SET)](
    { commit },
    payload: IListPasscodePayload,
  ) {
    commit(EListPasscodeMutations.SET, payload);
  },

  /**
   * Reset store by removing all passcodes, this must be done when login-out someone.
   */
  [getBaseName(EListPasscodeActions.RESET)]({ commit }) {
    commit(EListPasscodeMutations.RESET);
  },
};

/**
 * List passcode store module.
 *
 * TODO: catch logout and clear stored passcodes
 */
export const listPasscodeModule: Module<IListPasscodeState, RootState> = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
