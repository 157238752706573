import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-2d1eda9f"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "networks-list__wrapper" }
const _hoisted_2 = { class: "networks-list__logo" }
const _hoisted_3 = ["alt", "src"]
const _hoisted_4 = { class: "networks-list__content" }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = ["title"]
const _hoisted_7 = {
  key: 0,
  class: "networks-list__gradient"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "networks-list",
      onScroll: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.checkNetworksListScroll && _ctx.checkNetworksListScroll(...args)))
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (network) => {
        return (_openBlock(), _createElementBlock("div", {
          key: network.id,
          class: "networks-list__item"
        }, [
          _createElementVNode("picture", _hoisted_2, [
            (network.logo)
              ? (_openBlock(), _createElementBlock("img", {
                  key: 0,
                  alt: network.name,
                  src: network.logo
                }, null, 8, _hoisted_3))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("h3", null, _toDisplayString(network.name), 1),
            (network.locations.length)
              ? (_openBlock(), _createElementBlock("ul", _hoisted_5, [
                  _createElementVNode("li", {
                    title: network.locations[0].formatted_address
                  }, _toDisplayString(network.locations[0].formatted_address), 9, _hoisted_6)
                ]))
              : _createCommentVNode("", true)
          ])
        ]))
      }), 128))
    ], 32),
    (_ctx.isGradientVisible)
      ? (_openBlock(), _createElementBlock("div", _hoisted_7))
      : _createCommentVNode("", true)
  ]))
}