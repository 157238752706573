import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-be6a778e"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["onClick"]
const _hoisted_2 = ["textContent"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PxAvatar = _resolveComponent("PxAvatar")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([{ 'px-avatar-set--round': _ctx.round }, "px-avatar-set"])
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.visibleItems, (member, index) => {
      return (_openBlock(), _createElementBlock("div", {
        key: index,
        style: _normalizeStyle(_ctx.itemDimensions),
        class: "px-avatar-set__item",
        onClick: ($event: any) => (_ctx.$emit('click', member))
      }, [
        _createVNode(_component_PxAvatar, {
          name: member.name,
          photo: member.logo,
          size: _ctx.size
        }, null, 8, ["name", "photo", "size"])
      ], 12, _hoisted_1))
    }), 128)),
    (_ctx.hasItemsOverLimit)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          style: _normalizeStyle(_ctx.itemDimensions),
          class: "px-avatar-set__item px-avatar-set__item--count",
          textContent: _toDisplayString(`+${_ctx.remainingItemsCount}`)
        }, null, 12, _hoisted_2))
      : _createCommentVNode("", true)
  ], 2))
}