import moment from "moment";
import sortBy from "lodash/sortBy";
import uniqBy from "lodash/uniqBy";
import { ActionTree } from "vuex";

import {
  EMatchingResponsesActions,
  EMatchingResponsesMutations,
  IMatchingResponsesState,
} from "./matching-responses.types";
import { RootState } from "@/services/store/root-state";
import { getBaseName } from "@/services/store/utils/get-base-name";
import { matchingResponsesProvider } from "@/services/data/matching-responses/matching-response.provider";
import { userManager } from "@/modules/authentication/user-manager";
import { profileFieldProvider } from "@/modules/affiliate-program/services/data/profile-fields/profile-field.provider";
import {
  DATE,
  FREE_RESPONSE,
  IMatchingQuestion,
} from "@/services/data/matching-questionary/matching-question.interface";
import { IMatchingResponse } from "@/services/data/matching-responses/matching-response.interface";

export const matchingResponsesActions: ActionTree<
  IMatchingResponsesState,
  RootState
> = {
  /**
   * Fetch matching responses.
   *
   * @param param0
   */
  async [getBaseName(EMatchingResponsesActions.FETCH)]({ commit }) {
    commit(EMatchingResponsesMutations.SET_LOADING, true);
    commit(EMatchingResponsesMutations.SET_ERROR, null);

    try {
      // Fetch responses
      const responses = await matchingResponsesProvider.getLatest();
      commit(EMatchingResponsesMutations.SET_DATA, responses);
    } catch (error) {
      commit(EMatchingResponsesMutations.SET_ERROR, error);
    } finally {
      commit(EMatchingResponsesMutations.SET_LOADING, false);
    }
  },

  /**
   * Update cached responses with profile field responses and given responses, by created date.
   *
   * @param param0
   * @param questions
   */
  async [getBaseName(EMatchingResponsesActions.UPDATE_CACHED_RESPONSES)](
    { state, commit },
    questions: Array<IMatchingQuestion> = [],
  ) {
    const givenResponses = state.data;
    const cachedResponses = state.meta.responses || [];
    const profileResponses = [];
    let newResponses = [];

    if (!userManager.isLogged() || !questions?.length) {
      // Sort by created date
      newResponses = sortBy(
        [...givenResponses, ...cachedResponses],
        ["created_at"],
      ).reverse();

      // Get unique responses
      newResponses = uniqBy(newResponses, "question");

      commit(EMatchingResponsesMutations.SET_META, {
        ...state.meta,
        responses: newResponses,
      });

      return;
    }

    commit(EMatchingResponsesMutations.SET_LOADING, true);
    commit(EMatchingResponsesMutations.SET_ERROR, null);

    try {
      // If user is logged and contains questions, fetch for profile field responses
      for (const question of questions) {
        if (!question.profile_field) {
          continue;
        }

        const { value } = await profileFieldProvider.get(
          question.profile_field,
        );

        // No value found, not worth storing
        if (value === "") {
          continue;
        }

        let responseType = "";

        // For now, profile fields are either free response or date
        if (question.question_type.type === FREE_RESPONSE) {
          responseType = "text";
        } else if (question.question_type.type === DATE) {
          responseType = "date";
        }

        // TODO: Should return a field created/updated datetime to filter most recent responses
        // Push profile answer with current date to be the most recent response
        profileResponses.push({
          question: question.id,
          value: {
            [responseType]: value,
          },
          answers: [] as number[],
          created_at: moment().format(),
        });
      }

      // Sort by created date
      newResponses = sortBy(
        [...givenResponses, ...cachedResponses, ...profileResponses],
        ["created_at"],
      ).reverse();

      // Get unique responses
      newResponses = uniqBy(newResponses, "question");

      commit(EMatchingResponsesMutations.SET_META, {
        ...state.meta,
        responses: newResponses,
      });
    } catch (error) {
      commit(EMatchingResponsesMutations.SET_ERROR, error);
    } finally {
      commit(EMatchingResponsesMutations.SET_LOADING, false);
    }
  },

  /**
   * Set responses to be stored on meta.
   *
   * @param param0
   * @param responses
   */
  async [getBaseName(EMatchingResponsesActions.SET_CACHED_RESPONSES)](
    { commit, state },
    responses: Array<IMatchingResponse>,
  ) {
    const cachedResponses = state.meta.responses || [];

    // Sort by created date
    let newResponses = sortBy(
      [...cachedResponses, ...responses],
      ["created_at"],
    ).reverse();

    // Get unique responses
    newResponses = uniqBy(newResponses, "question");

    commit(EMatchingResponsesMutations.SET_META, {
      ...state.meta,
      responses: newResponses,
    });
  },

  /**
   * Clear matching responses.
   *
   * @param param0
   */
  async [getBaseName(EMatchingResponsesActions.RESET)]({ commit }) {
    commit(EMatchingResponsesMutations.SET_LOADING, false);
    commit(EMatchingResponsesMutations.SET_ERROR, null);
    commit(EMatchingResponsesMutations.SET_DATA, []);
    commit(EMatchingResponsesMutations.SET_META, {});
  },
};
