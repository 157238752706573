<template>
  <div>
    <div class="level-card">
      <div
        :style="`--categoryColor: #${category.color}`"
        class="level-card__header"
      >
        <span class="level-card__title">
          {{ $t("selfAssessment.mobileAssessment.levelCard.level", { level }) }}
        </span>
      </div>
      <div class="level-card__content">
        <div class="level-card__content--title">
          {{ currentDetails.level.title }}
        </div>
        <ul class="level-card__content--levels">
          <li>
            {{ currentDetails.level.description }}
          </li>
          <li>
            {{ currentDetails.level.description }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { ICategoryDetail } from "@/services/data/category/category.interface";

export default defineComponent({
  name: "LevelCard",

  props: {
    /**
     * Current category.
     */
    category: {
      type: Object as () => any,
      required: true,
    },
    /**
     * Current level.
     */
    level: {
      type: Number,
      required: false,
      default: 0,
    },

    levelDescription: {
      type: String,
      required: false,
      default: "",
    },
  },

  computed: {
    /**
     * Categories details sorted
     */
    sortedDetails(): Array<ICategoryDetail> {
      const rawDetails = [
        ...(this.category.categoryDetails as Array<ICategoryDetail>),
      ];
      return rawDetails.sort((a, b) => b.level.value - a.level.value);
    },
    /**
     * Current Level Category details
     */
    currentDetails(): ICategoryDetail | undefined {
      return this.sortedDetails.find(
        (item: ICategoryDetail) => item.level.value === this.level,
      );
    },
  },

  watch: {},
});
</script>

<style lang="scss" scoped>
.level-card {
  position: relative;
  display: block;
  padding: 10px 15px 2px;
  margin-top: 14px;

  background-color: white;

  border: solid 1px $pale-grey;
  border-radius: 3px;

  box-shadow: 0 2px 11px 0 $black-5;

  &__header {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
  }

  &__title {
    @include grotesk(bold);

    flex-grow: 1;

    font-size: 1rem;
    line-height: 24px;
    color: var(--categoryColor);
    text-transform: uppercase;
  }

  &__criteria {
    position: relative;
    top: -6px;
    flex-shrink: 0;

    & :deep() span {
      font-size: 0.9333rem;
    }
  }

  &__content {
    @include grotesk(bold);

    position: relative;
    top: -6px;

    font-size: 1rem;
    line-height: 24px;

    color: $ebony-clay;

    &--title {
      margin-top: 5px;
      font-size: 15px;
      font-weight: 600;
      line-height: 1.6;
      color: $ebony-clay;
    }

    &--levels {
      padding-left: 15px;
      margin-top: 8px;
      font-size: 15px;
      line-height: 1.53;
      color: $manatee;
    }
  }
}
</style>
