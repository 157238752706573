<template>
  <div class="submission-panel__supporter">
    <div class="submission-panel__supporter-header">
      <SubmissionPanelSupporterInfo
        :id="company.id"
        :subtitle="companyAddress"
        :title="company.name"
      />
      <div
        v-if="userCanMatch"
        class="submission-panel__supporter-field-container"
      >
        <PxMatchingInterest
          :id="company.id"
          :name="company.name"
          :with-description="false"
        />
        <PxButton
          v-if="$screen.lgUp"
          class="submission-panel__supporter-cta--addToList"
          v-bind="addToListButtonProps"
          @click.stop="showListModal"
        />
        <PxMatchingInterestTip :id="company.id" :name="company.name" />
      </div>
    </div>
    <div
      class="submission-panel__supporter-column submission-panel__supporter-column--left"
    >
      <SubmissionPanelSupporterInterests
        :grouped-locations="supporterGroupedLocations"
        :grouped-sectors="supporterGroupedSectors"
        :locations="supporterLocations"
        :sectors="supporterSectors"
      />
    </div>
    <div
      class="submission-panel__supporter-column submission-panel__supporter-column--right"
    >
      <SubmissionPanelSupporterContacts :email="companyEmail" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import SubmissionPanelSupporterInfo from "@/modules/supporters/components/submission/submission-panel-supporter-info.vue";
import SubmissionPanelSupporterInterests from "@/modules/supporters/components/submission/submission-panel-supporter-interests.vue";
import SubmissionPanelSupporterContacts from "@/modules/supporters/components/submission/submission-panel-supporter-contacts.vue";
import PxMatchingInterestTip from "@/components/px-matching-interest/px-matching-interest-tip.vue";

import { ICompany } from "@/modules/profile/services/data/company/company.types";
import { IAffiliateProgramSupporterSubmission } from "@/modules/supporters/services/data/affiliate-program-supporter-submission/affiliate-program-supporter-submission.interface";
import { ISupporter } from "@/modules/matching/services/data/matching-score/supporter.interface";
import { EMatchingInterestActions } from "@/modules/matching/services/store/matching-interest/matching-interest.types";
import { IMatchingInterest } from "@/modules/matching/services/data/matching-interest/matching-interest.interface";

import {
  EPxButtonSize,
  EPxButtonType,
} from "@/components/px-button/px-button.types";

export default defineComponent({
  components: {
    SubmissionPanelSupporterInfo,
    SubmissionPanelSupporterInterests,
    SubmissionPanelSupporterContacts,
    PxMatchingInterestTip,
  },

  props: {
    submission: {
      type: Object as () => IAffiliateProgramSupporterSubmission,
      required: true,
    },
    company: {
      type: Object as () => ICompany,
      required: true,
    },
    userCanMatch: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    supporterSectors(): ISupporter["sectors"] {
      return this.submission?.sectors_of_interest?.sectors || [];
    },

    supporterGroupedSectors(): ISupporter["grouped_sectors"] {
      return this.submission?.sectors_of_interest?.grouped_sectors || [];
    },

    supporterLocations(): ISupporter["locations"] {
      return this.submission?.locations_of_interest?.locations || [];
    },

    supporterGroupedLocations(): ISupporter["grouped_locations"] {
      return this.submission?.locations_of_interest?.grouped_locations || [];
    },

    companyEmail(): string {
      return this.company.email || "";
    },

    companyAddress(): string {
      return this.company && this.company?.locations?.length
        ? this.company.locations[0].formatted_address
        : "";
    },

    addToListButtonProps(): any {
      return {
        ...this.$options.static.addToListButton,
        label: this.$t("common.components.default.cta.addToList"),
      };
    },

    /**
     * Get auth user interests
     */
    matchingInterests(): Array<IMatchingInterest> {
      return this.$store.state.matchingInterest.data;
    },

    /**
     * Check if store value is set and that it has values
     */
    hasMatchingInterests(): boolean {
      return this.matchingInterests && !!this.matchingInterests.length;
    },
  },
  created() {
    // If  store not filled, fetch interests
    if (!this.hasMatchingInterests) {
      this.fetchAuthUserInterests();
    }
  },
  methods: {
    fetchAuthUserInterests() {
      if (this.$user.isLogged()) {
        this.$store.dispatch(EMatchingInterestActions.FETCH);
      }
    },
    showListModal() {
      this.$emit("show-list-modal");
    },
  },
  static: {
    addToListButton: {
      icon: "icon-add-to-list",
      size: EPxButtonSize.EXTRA_SMALL,
      type: EPxButtonType.DARK_BLUE,
    },
  },
});
</script>

<style lang="scss" scoped>
.submission-panel__supporter {
  display: grid;
  grid-template-areas:
    "header header"
    "column-left column-right";
  grid-row-gap: 29px;
  padding: 32px 15px;

  @include breakpoint-up(md) {
    grid-template-columns: 1fr 298px;
    grid-column-gap: 16px;
    padding: 40px;
  }
}

.submission-panel__supporter :deep() {
  .submission-panel-row:not(:first-child) {
    margin-top: 31px;
  }

  .submission-panel-caption {
    @include grotesk(semiBold);

    margin: 0;
    font-size: to-rem(13px);
    color: $manatee;
    text-transform: uppercase;

    @include breakpoint-up(md) {
      line-height: 16px;
    }
  }

  .submission-panel-data {
    @include grotesk(regular);

    margin-top: 7px;
    font-size: to-rem(15px);
    line-height: 24px;
    letter-spacing: -0.2px;
    word-break: break-word;
    word-wrap: break-word;

    @include breakpoint-up(md) {
      line-height: 25px;
    }
  }
}

.submission-panel__supporter-header {
  display: grid;
  grid-area: header;
  grid-row-gap: 30px;

  @include breakpoint-up(md) {
    grid-template-columns: auto 307px;
  }

  .px-matching-interest {
    :deep() .el-button {
      min-width: 182px;
      height: 32px;

      @include breakpoint-down(md) {
        width: 100%;
        height: 46px;
      }
    }

    :deep() .el-button span {
      font-size: 12px;
    }

    @include breakpoint-up(lg) {
      display: inline-block;
    }
  }

  .px-matching-interest__tip {
    float: right;
    margin: 12px 0 4px 0;

    @include breakpoint-down(sm) {
      float: left;
    }
  }

  .submission-panel__supporter-cta--addToList {
    float: right;
    max-width: 110px;
    height: 32px;
    margin-left: 8px;

    :deep() .px-icon + div {
      margin-left: 7px;
    }

    @include breakpoint-down(sm) {
      float: left;
    }
  }
}

.submission-panel__supporter-column--left {
  grid-area: column-left;
}

.submission-panel__supporter-column--right {
  grid-area: column-right;
}
</style>
