<template>
  <div class="milestones-grid">
    <div class="milestones-grid__levels">
      <div
        v-for="levelNumber in levelsCount"
        :key="levelNumber"
        :class="{
          'milestones-grid__level--active': levelNumber === currentLevel,
        }"
        :data-testid="`level-number-${levelNumber}`"
        class="milestones-grid__level"
      >
        {{ levelNumber }}
      </div>
    </div>
    <div class="milestones-grid__grid">
      <div
        v-for="(category, index) in categories"
        :key="category.name"
        class="milestones-grid__category"
      >
        <ElTooltip
          :content="category.name"
          placement="top"
          popper-class="milestones-grid__tooltip el-tooltip__popper--small el-abaca-tooltip"
        >
          <div
            :data-testid="`category-name-${index}`"
            class="milestones-grid__category-name"
            @click="$emit('show-carousel', category)"
          >
            {{ category.abbreviation }}
            <img
              alt="maximize"
              class="milestones-grid__expand"
              src="/img/icons/maximize--grey.svg"
            />
          </div>
        </ElTooltip>
        <div class="milestones-grid__category-milestones">
          <ElPopover
            v-for="milestone in category.milestones"
            :key="milestone.level"
            :hide-after="0"
            :data-testid="`milestone-${index}-${milestone.level}-popover`"
            placement="top"
            popper-class="px-popover--light milestone-cell-popover"
            trigger="click"
            width="242px"
            @hide="onTooltipHide"
            @show="onTooltipShow(milestone)"
          >
            <MilestoneTooltip
              :category-color="category.color"
              :category-name="category.name"
              :data-testid="`milestone-${index}-${milestone.level}-tooltip`"
              :has-evidence="milestone.evidenceProvided"
              :is-future-milestone="milestone.future"
              :is-milestone-complete="milestone.completed"
              :is-milestone-critical="milestone.critical"
              :is-milestone-in-progress="milestone.inProgress"
              :is-milestone-planned="milestone.planned"
              :is-visitor="isVisitor"
              :level-number="milestone.level"
              :milestone-description="milestone.description"
              :target-date="milestone.targetDate"
              @complete="onMilestoneCompleteClick(category.id, milestone.level)"
              @plan="onMilestonePlanClick(category.id, milestone.level)"
            />
            <template #reference>
              <MilestoneCell
                :class="{
                  'milestones-grid__milestone--blurred':
                    isMilestoneBlurred(milestone),
                }"
                :color="category.color"
                :completed="milestone.completed"
                :critical="milestone.critical"
                :data-testid="`milestone-${index}-${milestone.level}-cell`"
                :evidence-provided="milestone.evidenceProvided"
                :future="milestone.future"
                :in-progress="milestone.inProgress"
                :planned="milestone.planned"
                class="milestones-grid__milestone"
              />
            </template>
          </ElPopover>
        </div>
      </div>
      <div
        :style="{
          transform: `translateY(${currentLevelLinePosition}px)`,
        }"
        class="milestones-grid__current-level-line"
        data-testid="current-level-line"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import MilestoneCell from "@/modules/milestone-planner/components/milestone-cell/milestone-cell.vue";
import MilestoneTooltip from "@/modules/milestone-planner/components/milestone-tooltip/milestone-tooltip.vue";
import { IGridCategory, IGridMilestone } from "./milestones-grid.interface";
import {
  MILESTONE_DETAILS_TABS,
  TMilestoneDetailsTab,
} from "../../services/data/milestones/milestone.interface";
import {
  ROUTE_MILESTONE_PLANNER_DETAIL_VISITORS,
  ROUTE_MILESTONE_PLANNER_DETAIL,
} from "@/modules/milestone-planner/services/router/routes-names";

export default defineComponent({
  name: "MilestoneGrid",

  components: {
    MilestoneCell,
    MilestoneTooltip,
  },

  props: {
    categories: {
      type: Array as () => IGridCategory[],
      required: true,
    },

    levelsCount: {
      type: Number,
      required: true,
    },

    currentLevel: {
      type: Number,
      required: true,
    },

    isVisitor: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      selectedMilestone: null as IGridMilestone | null,
    };
  },

  computed: {
    currentLevelLinePosition(): number {
      const cellHeightPx = 22;
      const cellUpperMarginPx = 8;
      const totalCellHeight = cellHeightPx + cellUpperMarginPx;

      return -(this.currentLevel * totalCellHeight - cellUpperMarginPx / 2);
    },
  },

  methods: {
    /**
     * Check if provided milestone shall be blurred. It shall be blurred
     * when there's a selected milestone which is not the provided one.
     *
     * @param {IGridMilestone} milestone
     */
    isMilestoneBlurred(milestone: IGridMilestone) {
      return !!this.selectedMilestone && this.selectedMilestone !== milestone;
    },

    /**
     * Mark a milestone as selected when its tooltip gets shown.
     *
     * @param {IGridMilestone} milestone
     */
    onTooltipShow(milestone: IGridMilestone) {
      setTimeout(() => {
        this.selectedMilestone = milestone;
      });
    },

    /**
     * Clear selected milestone when a milestone's tooltip gets hidden.
     */
    onTooltipHide() {
      this.selectedMilestone = null;
    },

    /**
     * Handle milestone plan button clicks,
     * redirecting to plan tab on milestone detail page.
     *
     * @param {number} categoryId
     * @param {number} level
     */
    onMilestonePlanClick(categoryId: number, level: number) {
      this.goToMilestoneDetail(categoryId, level, MILESTONE_DETAILS_TABS.PLAN);
    },

    /**
     * Handle milestone complete button clicks,
     * redirecting to complete tab on milestone detail page.
     *
     * @param {number} categoryId
     * @param {number} level
     */
    onMilestoneCompleteClick(categoryId: number, level: number) {
      this.goToMilestoneDetail(
        categoryId,
        level,
        MILESTONE_DETAILS_TABS.COMPLETE,
      );
    },

    /**
     * Navigate to provided tab of milestone detail page.
     *
     * @param {number} categoryId
     * @param {number} level
     * @param {TMilestoneDetailsTab} tab
     */
    goToMilestoneDetail(
      categoryId: number,
      level: number,
      tab: TMilestoneDetailsTab,
    ) {
      this.$router.push({
        name: this.isVisitor
          ? ROUTE_MILESTONE_PLANNER_DETAIL_VISITORS
          : ROUTE_MILESTONE_PLANNER_DETAIL,
        params: {
          categoryId: categoryId.toString(),
          levelValue: level.toString(),
        },
        query: { tab },
      });
    },
  },
});
</script>

<style lang="scss">
.milestones-grid__tooltip {
  max-width: 340px;
  text-transform: none;
}
</style>

<style lang="scss" scoped>
.milestones-grid {
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;

  &__levels {
    display: flex;
    flex-direction: column-reverse;
    align-self: flex-end;
    justify-content: space-between;
    height: calc(100% - 40px);
  }

  &__level {
    @include grotesk(regular);

    width: 30px;
    padding-right: 2px;
    margin-top: 8px;
    font-size: 14px;
    line-height: 22px;
    text-align: center;

    &--active {
      @include grotesk(bold);
    }
  }

  &__grid {
    position: relative;
    display: flex;
    max-width: 628px;
  }

  &__category {
    width: 68px;

    & + .milestones-grid__category {
      margin-left: 12px;
    }
  }

  &__category-name {
    @include grotesk(semiBold);

    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 36px;
    margin-bottom: 4px;
    overflow: hidden;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    text-decoration: underline;

    &:hover {
      cursor: pointer;
    }
  }

  &__expand {
    position: absolute;
    right: 5px;

    &:hover {
      cursor: pointer;
    }
  }

  &__category-milestones {
    display: flex;
    flex-direction: column-reverse;
  }

  &__milestone {
    margin-top: 8px;
    outline: none;

    &--blurred {
      opacity: 0.4;

      &:hover {
        opacity: 1;
      }
    }
  }

  &__current-level-line {
    position: absolute;
    bottom: -1px;
    left: -30px;
    width: calc(100% + 48px);
    height: 2px;
    background-color: $ebony-clay;
  }
}
</style>
