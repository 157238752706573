import {
  ISubscription,
  ESubscriptionPlanID,
} from "@/modules/authentication/services/data/subscription/subscription.interface";
import { GenericProvider } from "@/services/data/generic-provider";
import { baseAPIUrl } from "@/services/utils/utils";

/**
 * Provider to manage profile related data.
 */
class SubscriptionProvider extends GenericProvider<ISubscription> {
  constructor() {
    super(`${baseAPIUrl}/subscription`);
  }

  public async getSubscription(): Promise<ISubscription> {
    const request = this.httpClient.get(this.buildEndPointUrl());
    const { data } = await this.wrapRequest(request);

    return {
      customerId: data.customer_id,
      subscriptionId: data.subscription_id,
      planId: data.plan_id,
      startDate: data.start_date,
      renewalDate: data.renewal_date,
    };
  }

  public async getPortalSession() {
    const request = this.httpClient.get(
      `${process.env.VUE_APP_API_URL}/chargebee/portal`,
    );
    const { data } = await this.wrapRequest(request);
    return data;
  }

  public async getCheckout(plan: ESubscriptionPlanID) {
    const request = this.httpClient.get(
      `${process.env.VUE_APP_API_URL}/chargebee/checkout?plan=${plan}`,
    );
    const { data } = await this.wrapRequest(request);
    return data;
  }
}

export const subscriptionProvider = new SubscriptionProvider();
