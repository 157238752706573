import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  ref: "canvas",
  class: "milestone-plan-level-up-dialog__animation"
}
const _hoisted_2 = { class: "milestone-plan-level-up-dialog__level" }
const _hoisted_3 = { class: "milestone-plan-level-up-dialog__category-level" }
const _hoisted_4 = { class: "milestone-plan-level-up-dialog__level-number" }
const _hoisted_5 = { class: "milestone-plan-level-up-dialog__category-name" }
const _hoisted_6 = { class: "milestone-plan-level-up-dialog__category-level-description" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_i18n_t = _resolveComponent("i18n-t")!
  const _component_PxLevel = _resolveComponent("PxLevel")!
  const _component_ElDialog = _resolveComponent("ElDialog")!

  return (_openBlock(), _createBlock(_component_ElDialog, {
    ref: "modal",
    modelValue: _ctx.innerVisibility,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.innerVisibility) = $event)),
    "append-to-body": true,
    "close-on-click-modal": false,
    "close-on-press-escape": false,
    class: "milestone-plan-level-up-dialog",
    width: "492px"
  }, {
    header: _withCtx(() => [
      _createElementVNode("h1", {
        style: _normalizeStyle(`color: #${_ctx.categoryColor}`),
        class: "milestone-plan-level-up-dialog__title"
      }, _toDisplayString(_ctx.$t("milestonePlanner.milestonePlan.levelUpDialog.title", {
            category: _ctx.categoryName,
          })), 5),
      _createVNode(_component_i18n_t, {
        class: "milestone-plan-level-up-dialog__subtitle",
        keypath: "milestonePlanner.milestonePlan.levelUpDialog.subtitle",
        tag: "p"
      }, {
        level: _withCtx(() => [
          _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.$t("common.levelTag", {
                level: _ctx.level,
              })), 1)
        ]),
        _: 1
      })
    ]),
    footer: _withCtx(() => [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", {
          style: _normalizeStyle(`backgroundColor: #${_ctx.categoryColor}`),
          class: "milestone-plan-level-up-dialog__category-indicator"
        }, null, 4),
        _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.level), 1),
        _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.categoryName), 1)
      ]),
      _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.description), 1)
    ]),
    default: _withCtx(() => [
      _createElementVNode("canvas", _hoisted_1, null, 512),
      _createVNode(_component_PxLevel, {
        "category-color": _ctx.categoryColor,
        level: _ctx.level
      }, null, 8, ["category-color", "level"])
    ]),
    _: 1
  }, 8, ["modelValue"]))
}