<template>
  <Bar
    ref="chart"
    :chart-options="chartOptions"
    :chart-data="chartData"
    :chart-id="chartId"
    :css-classes="cssClasses"
    :dataset-id-key="datasetIdKey"
    :height="chartHeight"
    :plugins="chartPlugins"
    :styles="styles"
    :width="chartWidth"
    class="bar-chart"
  />
</template>

<script lang="ts">
import { defineComponent } from "vue";

import { Bar } from "vue-chartjs";
import ChartJsPluginDataLabels from "chartjs-plugin-datalabels";

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
  ChartData,
} from "chart.js";
import { externalTooltipHandler } from "@/components/charts/bar-chart/bar-chart-tooltip";

import isEqual from "lodash/isEqual";
import cloneDeep from "lodash/cloneDeep";
import { IBarChartDataset } from "@/modules/charts-dashboard/components/process-reports/process-reports-chart.vue";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
);

export default defineComponent({
  name: "BarChart",

  components: {
    Bar,
  },

  props: {
    chartId: {
      type: String,
      default: "bar-chart",
    },
    datasetIdKey: {
      type: String,
      default: "label",
    },
    chartWidth: {
      type: Number,
      default: 800,
    },
    chartHeight: {
      type: Number,
      default: 290,
    },
    cssClasses: {
      default: "",
      type: String,
    },
    styles: {
      type: Object as () => any | null,
      default: null,
    },
    plugins: {
      type: Array,
      default: () => [],
    },
    data: {
      type: Object as () => ChartData,
      default: null,
    },
    options: {
      type: Object as () => any | null,
      default: null,
    },
    compareData: {
      type: Object as () => {
        labels: string[];
        datasets: IBarChartDataset[];
      } | null,
      default: null,
    },
  },

  data() {
    return {
      chartData: {} as any,
    };
  },

  computed: {
    chartPlugins(): any {
      // Create a copy of the plugins array to avoid mutating the prop directly
      const plugins = [...this.plugins, ChartJsPluginDataLabels];
      const height = this.chartHeight;
      const width = this.chartWidth;

      plugins.push({
        beforeInit: function (chart: ChartJS) {
          // Plugin to change the canvas height and width to the desired one
          if (chart.canvas) {
            chart.canvas.style.height = `${height}px`;
            chart.canvas.style.width = `${width}px`;
          }
        },
      });

      return plugins;
    },

    chartOptions(): any {
      return this.options ? this.options : this.defaultChartOptions;
    },

    defaultChartOptions(): any {
      return {
        maxBarThickness: 96,
        maintainAspectRatio: false,
        responsive: true,
        scales: {
          y: {
            display: true,
            beginAtZero: true,
            max: 100,
            stacked: true,
            ticks: {
              padding: 2,
              stepSize: 20,
              callback: function (value: number, index: number) {
                if (!index) return value;

                return value + "%";
              },
              font: {
                weight: 500,
                size: 14,
                family: "HkGrotesk",
              },
              color: "#8b8fa1",
            },
            grid: {
              color: ["#f0f1f5"],
              borderDash: function (context: any) {
                if (!context.index) return [];

                return [5];
              },
              borderColor: "transparent",
            },
            gridLines: {
              drawBorder: false,
            },
          },
          x: {
            stacked: true,
            offset: false,
            grid: {
              display: false,
              borderColor: "transparent",
            },
            ticks: {
              font: {
                weight: 500,
                size: 14,
                family: "HkGrotesk",
              },
              padding: 0,
              color: "#8b8fa1",
            },
          },
        },
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            enabled: false,
            position: "nearest",
            external: (context: any) => {
              externalTooltipHandler(context, this.compareData);
            },
          },
          datalabels: {
            display: false,
          },
        },
        elements: {
          bar: {
            borderColor: "#fff",
            borderWidth: 1,
            borderSkipped: false,
          },
        },
        hoverBorderColor: "#fff",
      };
    },
  },

  watch: {
    data: {
      immediate: true,
      deep: true,
      handler(newData: any, oldData: any) {
        if (isEqual(newData, oldData)) return;

        this.chartData = cloneDeep(newData);

        if (this.chartData) {
          // Workaround to add space to the left and right of the chart
          this.chartData.labels?.push("");
          this.chartData.labels?.unshift("");
          this.chartData.datasets?.forEach((dataset: any) => {
            dataset.data?.push(null);
            dataset.data?.unshift(null);
          });
        }
      },
    },
  },
});
</script>
<style lang="scss" scoped>
:deep(.chart-tooltip) {
  min-width: 160px;
  max-width: 400px;
  width: auto;

  border-radius: 4px;
  color: $white;
  line-height: 20px;
  pointer-events: none;

  position: absolute;
  transition: opacity 0.2s ease;

  background-color: rgba(39, 39, 64, 0.8);
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.05);

  .locked-tooltip {
    width: 260px;
  }

  .single-tooltip,
  header,
  main {
    width: 100%;
  }

  .locked-tooltip,
  .single-tooltip,
  header,
  main {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;

    padding: 6px 8px;
  }

  header {
    background-color: rgba(255, 255, 255, 0.15);
  }

  p,
  span {
    @include grotesk(semiBold);

    font-size: 14px;
    margin: 0;
  }

  i {
    font-weight: 500;
    font-size: 14px;
  }

  .badge {
    display: flex;
    gap: 6px;
  }

  .badge span {
    position: relative;

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 2px 7px 2px 20px;
    border-radius: 100px;

    font-size: 14px;
    font-weight: 600;
    line-height: 1.43;

    &::before {
      content: "";
      position: absolute;
      top: 9px;
      left: 7px;

      width: 0;
      height: 0;

      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
    }
  }

  .positive-badge span {
    background-color: #e8ffea;
    color: $fern;

    &::before {
      border-bottom: 5px solid $fern;
    }
  }

  .negative-badge span {
    background-color: #ffece8;
    color: $wild-watermelon;

    &::before {
      border-top: 5px solid $wild-watermelon;
    }
  }
}
</style>
