import { getCompaniesCountForSpecificLabels } from "@/modules/charts-dashboard/utils/count-companies-with-label";
import {
  IListReportsDemographicData,
  IDemographicDataResponse,
  IDemographicDataCompany,
} from "@/modules/charts-dashboard/services/data/lists-reports-data/lists-reports-data.interface";
import {
  IProcessReportsDemographicData,
  IProcessReportsChartData,
} from "@/modules/charts-dashboard/services/data/process-reports-data/process-reports-data.interface";

export const formatCompaniesEthnicityData = (
  data: IListReportsDemographicData | IProcessReportsDemographicData,
): IDemographicDataResponse[] => {
  const companiesWithEthnicityResponses = data.companies.filter(
    (company: IDemographicDataCompany) => company.responses.length,
  );

  const possibleResponses = data.responses.map((response) => response.value);

  const ethnicityCounts = getCompaniesCountForSpecificLabels(
    companiesWithEthnicityResponses,
    possibleResponses,
  );

  const totalCompaniesWithSingleEthnicity = Object.values(
    ethnicityCounts,
  ).reduce((acc, count) => acc + count, 0);

  const mixed =
    companiesWithEthnicityResponses.length - totalCompaniesWithSingleEthnicity;

  const formattedLabels = possibleResponses.map((response) => {
    const lowercaseResponse = response.toLowerCase();

    if (lowercaseResponse.includes("prefer not to say")) {
      return "Prefer not to say";
    } else {
      return `${response} led`;
    }
  });

  formattedLabels.push("Multi-ethnic leadership");

  const chartValues = [...Object.values(ethnicityCounts), mixed];

  const aggregatedData = [] as IDemographicDataResponse[];

  for (let i = 0; i < chartValues.length; i++) {
    aggregatedData.push({
      value: formattedLabels[i] as string,
      count: chartValues[i],
      percentage: chartValues[i]
        ? chartValues[i] / companiesWithEthnicityResponses.length
        : 0,
    });
  }

  return aggregatedData;
};

export const formatProcessReportsEthnicityData = (
  data: IProcessReportsDemographicData[],
): IProcessReportsChartData[] => {
  return data.map((list: IProcessReportsDemographicData) => {
    return {
      step: list.step,
      responses: !list.is_locked ? formatCompaniesEthnicityData(list) : [],
      is_locked: list.is_locked,
    };
  });
};
