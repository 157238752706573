import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, normalizeClass as _normalizeClass, withCtx as _withCtx, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-6287c614"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "panel-grid__block" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AssessmentPanelPlaceholder = _resolveComponent("AssessmentPanelPlaceholder")!
  const _component_AssessmentPanelLevelInactive = _resolveComponent("AssessmentPanelLevelInactive")!
  const _component_AssessmentPanelLevel = _resolveComponent("AssessmentPanelLevel")!
  const _component_AssessmentPanelGridInactive = _resolveComponent("AssessmentPanelGridInactive")!
  const _component_AssessmentPanelGrid = _resolveComponent("AssessmentPanelGrid")!
  const _component_AssessmentPanelHistoryInactive = _resolveComponent("AssessmentPanelHistoryInactive")!
  const _component_AssessmentPanelHistory = _resolveComponent("AssessmentPanelHistory")!
  const _component_PxPanel = _resolveComponent("PxPanel")!
  const _component_RenderlessAssessmentPanel = _resolveComponent("RenderlessAssessmentPanel")!
  const _directive_feature = _resolveDirective("feature")!

  return (_openBlock(), _createBlock(_component_RenderlessAssessmentPanel, null, {
    default: _withCtx((scope) => [
      _createElementVNode("div", _hoisted_1, [
        (scope.isLoading)
          ? (_openBlock(), _createBlock(_component_AssessmentPanelPlaceholder, { key: 0 }))
          : (_openBlock(), _createBlock(_component_PxPanel, {
              key: 1,
              class: _normalizeClass([{ 'profile-panel--disabled': scope.isInactive }, "profile-panel profile-panel--assessment"])
            }, {
              default: _withCtx(() => [
                (scope.isInactive)
                  ? (_openBlock(), _createBlock(_component_AssessmentPanelLevelInactive, {
                      key: 0,
                      "allow-level-update": scope.allowLevelUpdate,
                      onRequestNewAssessment: scope.makeNewAssessment
                    }, null, 8, ["allow-level-update", "onRequestNewAssessment"]))
                  : (_openBlock(), _createBlock(_component_AssessmentPanelLevel, {
                      key: 1,
                      "latest-assessment": scope.latestAssessment,
                      level: scope.latestAssessmentLevel,
                      levels: scope.levels,
                      onRequestNewAssessment: scope.makeNewAssessment
                    }, null, 8, ["latest-assessment", "level", "levels", "onRequestNewAssessment"])),
                (scope.isInactive)
                  ? (_openBlock(), _createBlock(_component_AssessmentPanelGridInactive, { key: 2 }))
                  : (_openBlock(), _createBlock(_component_AssessmentPanelGrid, {
                      key: 3,
                      "latest-assessment": scope.latestAssessment,
                      "level-value": scope.latestAssessmentLevelValue,
                      levels: scope.levels
                    }, null, 8, ["latest-assessment", "level-value", "levels"])),
                (scope.isInactive)
                  ? _withDirectives((_openBlock(), _createBlock(_component_AssessmentPanelHistoryInactive, { key: 4 }, null, 512)), [
                      [_directive_feature, 'assessmentHistory']
                    ])
                  : _withDirectives((_openBlock(), _createBlock(_component_AssessmentPanelHistory, {
                      key: 5,
                      "allow-level-update": scope.allowLevelUpdate,
                      onRequestNewAssessment: scope.makeNewAssessment
                    }, null, 8, ["allow-level-update", "onRequestNewAssessment"])), [
                      [_directive_feature, 'assessmentHistory']
                    ])
              ]),
              _: 2
            }, 1032, ["class"]))
      ])
    ]),
    _: 1
  }))
}