import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-5f6fbbfc"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "level-update-card__title" }
const _hoisted_2 = { class: "level-update-card__content-wrapper" }
const _hoisted_3 = { class: "level-update-card__description-wrapper" }
const _hoisted_4 = { class: "level-update-card__description" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PxIcon = _resolveComponent("PxIcon")!
  const _component_AssessmentChanges = _resolveComponent("AssessmentChanges")!
  const _component_PxPanel = _resolveComponent("PxPanel")!

  return (_openBlock(), _createBlock(_component_PxPanel, {
    class: _normalizeClass([[
      `level-update-card--${_ctx.type}`,
      `level-update-card--${_ctx.typeOfUpdate}`,
    ], "level-update-card"])
  }, {
    default: _withCtx(() => [
      _createElementVNode("p", _hoisted_1, _toDisplayString(_ctx.title), 1),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.levelDescription), 1)
        ]),
        _createVNode(_component_PxIcon, {
          name: `level-graph/${_ctx.level}`,
          size: _ctx.$screen.xsOnly ? 49 : 65,
          class: "level-update-card__level"
        }, null, 8, ["name", "size"]),
        (_ctx.prevAssessment && _ctx.currentAssessment)
          ? (_openBlock(), _createBlock(_component_AssessmentChanges, {
              key: 0,
              "current-assessment": _ctx.currentAssessment,
              "only-prev-assessment": _ctx.onlyPrevAssessment,
              "prev-assessment": _ctx.prevAssessment
            }, null, 8, ["current-assessment", "only-prev-assessment", "prev-assessment"]))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }, 8, ["class"]))
}