import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createBlock as _createBlock, createVNode as _createVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-03dcb289"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "sectors-selector" }
const _hoisted_2 = {
  key: 0,
  class: "el-form-item__label sectors-selector__title"
}
const _hoisted_3 = { class: "sectors-selector__sector-name" }
const _hoisted_4 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ElOption = _resolveComponent("ElOption")!
  const _component_PxIcon = _resolveComponent("PxIcon")!
  const _component_ElSelect = _resolveComponent("ElSelect")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass([{
        'sectors-selector__list--with-sectors': _ctx.selectedSectors.length,
      }, "sectors-selector__list"])
    }, [
      (!_ctx.noLabel)
        ? (_openBlock(), _createElementBlock("label", _hoisted_2, _toDisplayString(_ctx.$t(_ctx.labelCopy)), 1))
        : _createCommentVNode("", true),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.selectedSectors, (sector) => {
        return (_openBlock(), _createElementBlock("div", {
          key: sector.value,
          class: "sectors-selector__sector"
        }, [
          _createElementVNode("span", _hoisted_3, _toDisplayString(sector.label), 1),
          _createElementVNode("i", {
            class: "icon icon-trash--dark",
            onClick: ($event: any) => (_ctx.onClickRemoveSector(sector.value))
          }, null, 8, _hoisted_4)
        ]))
      }), 128))
    ], 2),
    _createVNode(_component_ElSelect, {
      ref: "select",
      loading: _ctx.sectorSelectLoading,
      placeholder: _ctx.$t('selfAssessment.companyInfoModal.placeholders.sector'),
      "remote-method": _ctx.searchSectors,
      class: "sectors-selector__select",
      filterable: "",
      remote: "",
      onBlur: _ctx.blurHandler,
      onChange: _ctx.onSectorSelect
    }, {
      prefix: _withCtx(() => [
        _createVNode(_component_PxIcon, {
          size: 24,
          name: "magnifier"
        })
      ]),
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.listOfSectors, (item) => {
          return (_openBlock(), _createBlock(_component_ElOption, {
            key: item.value,
            label: item.label,
            value: item,
            class: "sectors-selector__select-options"
          }, null, 8, ["label", "value"]))
        }), 128))
      ]),
      _: 1
    }, 8, ["loading", "placeholder", "remote-method", "onBlur", "onChange"])
  ]))
}