<template>
  <div
    :class="{ 'px-navigation-step--active': active }"
    :style="{ backgroundColor: color }"
    class="px-navigation-step"
    @click="$emit('click')"
  />
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "PxNavigationStep",
  props: {
    color: {
      type: String,
      default: "#7bb7ff",
    },
    active: {
      type: Boolean,
      default: false,
    },
  },
});
</script>

<style lang="scss" scoped>
.px-navigation-step {
  width: 14px;
  height: 8px;
  border-radius: 10px;
  opacity: 0.3;
  transition: opacity 0.1s ease-out;

  &--active {
    width: 24px;
    box-shadow: 0 4px 4px 0 rgba(123, 183, 255, 0.2);
    opacity: 1;
  }

  &:hover {
    cursor: pointer;
  }
}
</style>
