<template>
  <div class="company-edit-modal--supporter">
    <ElTabs v-model="currentView" class="el-tabs--blue-line">
      <ElTabPane
        :label="$t('profile.companyEditModal.tabs.general')"
        name="general"
      >
        <span />
      </ElTabPane>
      <ElTabPane
        :label="$t('profile.companyEditModal.tabs.interests')"
        name="interests"
      >
        <span />
      </ElTabPane>
    </ElTabs>
    <ElForm
      ref="form"
      :model="form.data"
      :rules="rules"
      @validate="updateSubmitState"
    >
      <div v-show="currentView === 'general'" class="form-grid">
        <div class="form-grid__logo">
          <div class="avatar-section">
            <ElUpload
              ref="upload"
              :auto-upload="false"
              :on-change="onFileChange"
              :show-file-list="false"
              accept="image/png, image/jpeg"
            >
              <PxAvatar :name="form.data.company.name" :photo="avatarUrl" />
              <div class="avatar-section__actions">
                <PxButton
                  :class="classesAvatarBtn"
                  :type="typeAvatarBtn"
                  icon="camera"
                  size="small"
                >
                  {{ $t("profile.companyEditModal.logo.button") }}
                </PxButton>
                <p
                  class="avatar-section__text"
                  v-html="$t('profile.companyEditModal.logo.help')"
                />
              </div>
            </ElUpload>
          </div>
        </div>
        <div class="form-grid__left-fields">
          <ElFormItem
            :label="
              $t(`profile.companyEditModal.fields.name[${$profile.type()}]`)
            "
            prop="company.name"
          >
            <ElInput
              v-model="form.data.company.name"
              :placeholder="
                $t(`profile.companyEditModal.fields.name[${$profile.type()}]`)
              "
            />
          </ElFormItem>
          <ElFormItem
            :label="$t('profile.companyEditModal.fields.email')"
            prop="company.email"
          >
            <ElInput
              ref="email"
              v-model="form.data.company.email"
              :placeholder="$t('profile.companyEditModal.fields.email')"
            />
          </ElFormItem>
          <ElFormItem
            :label="$t('supporters.signup.form.fields.supporterType')"
            class="el-form-item--types"
            prop="supporter.types"
          >
            <ElCheckboxGroup v-model="selectedTypes">
              <ElCheckbox
                v-for="(type, key) in availableTypes"
                :key="key"
                :value="type.id"
              >
                {{ type.name }}
                <ElTooltip
                  v-if="type.label"
                  placement="top"
                  popper-class="auth-signup__tooltip el-abaca-tooltip"
                >
                  <template #content>
                    <div v-html="type.label" />
                  </template>
                  <i class="icon icon-question" />
                </ElTooltip>
              </ElCheckbox>
              <ElCheckbox
                value=""
                @change="(value) => otherTypeChangeHandler(value)"
              >
                {{ $t("supporters.signup.form.fields.otherSupporterType") }}
              </ElCheckbox>
            </ElCheckboxGroup>
            <ElInput
              v-show="hasOtherType"
              v-model="form.data.supporter.otherType"
              :placeholder="
                $t('supporters.signup.form.placeholders.supporterType')
              "
              class="other-type-input"
            />
          </ElFormItem>
        </div>
        <div class="form-grid__right-fields">
          <ElFormItem
            :label="$t('profile.companyEditModal.fields.location')"
            prop="company.locations"
          >
            <PxInputPlaces
              v-if="form.data.company.locations"
              id="companyEditLocation"
              v-model="form.data.company.locations[0]"
              :placeholder="$t('profile.companyEditModal.fields.location')"
              :use-geolocation="true"
            />
          </ElFormItem>
          <ElFormItem
            :label="$t('profile.companyEditModal.fields.website')"
            prop="company.website"
          >
            <ElInput
              ref="website"
              v-model.trim="form.data.company.website"
              :placeholder="
                $t('authentication.signup.form.fields.websitePlaceholder')
              "
            >
              <template #prefix>
                <PxIcon :size="24" name="website" />
              </template>
            </ElInput>
          </ElFormItem>
          <ElFormItem
            :label="$t('profile.companyEditModal.fields.about')"
            prop="company.about"
          >
            <PxTextarea
              ref="about"
              v-model="form.data.company.about"
              :max-chars="rules['company.about'].max"
              :placeholder="$t('profile.companyEditModal.placeholders.about')"
              show-counter
            />
          </ElFormItem>
        </div>
      </div>
      <div
        v-show="currentView !== 'general'"
        class="form-grid form-grid--centered"
      >
        <div
          class="form-grid__left-fields"
          data-testid="edit-supporter-sectors-of-interest"
        >
          <ElFormItem prop="supporter.sectors">
            <MultiSelector
              v-model="form.data.supporter.sectors"
              :fetch-on-created="true"
              :needs-refresh="resetForm"
              :remote-method="searchSectors"
              :subtext-label-list="[
                'common.components.multiSelector.sectors.subLabel',
                'common.components.multiSelector.sectors.subLabelPlural',
              ]"
              label="supporters.signup.form.fields.sector"
              placeholder="supporters.signup.form.placeholders.sector"
              tip="supporters.signup.form.tips.sector"
            />
          </ElFormItem>
        </div>
        <div
          class="form-grid__right-fields"
          data-testid="edit-supporter-locations-of-interest"
        >
          <ElFormItem
            :label="$t('supporters.signup.form.fields.locations')"
            prop="supporter.locations"
          >
            <PxRadioGroup v-model="hasSpecificLocations">
              <ElRadio :value="true">
                {{ $t("supporters.signup.form.locationOptions.specific") }}
              </ElRadio>
              <ElRadio :value="false">
                {{ $t("supporters.signup.form.locationOptions.all") }}
              </ElRadio>
            </PxRadioGroup>
            <MultiSelector
              v-show="hasSpecificLocations"
              v-model="form.data.supporter.locations"
              :needs-refresh="resetForm"
              :no-label="true"
              :remote-method="searchLocations"
              :subtext-label-list="[
                'common.components.multiSelector.locations.subLabel',
                'common.components.multiSelector.locations.subLabelPlural',
              ]"
              :validate-event="true"
              class="locations-selector"
              input-icon="location"
              placeholder="supporters.signup.form.placeholders.locations"
              tip="supporters.signup.form.tips.location"
              @change="formValidateLocations"
            />
          </ElFormItem>
        </div>
      </div>
    </ElForm>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import cloneDeep from "lodash/cloneDeep";

import MultiSelector from "@/components/multi-selector/multi-selector.vue";
import { IMultiSelection } from "@/components/multi-selector/multi-selector.interface";
import {
  formatMultiSectorsSelection,
  formatSupporterSectors,
  multiSelectorSectorsProvider,
} from "@/components/multi-selector/multi-selector-sectors.provider";
import {
  formatMultiLocationsSelection,
  formatSupporterLocations,
  multiSelectorLocationsProvider,
} from "@/components/multi-selector/multi-selector-locations.providers";

import { ICompany } from "@/modules/profile/services/data/company/company.types";
import { companyProvider } from "@/modules/profile/services/data/company/company.provider";
import CompanyEditModalMixin from "@/mixins/company-edit-modal.mixin";
import { ISupporter } from "@/modules/matching/services/data/matching-score/supporter.interface";
import { ISupporterType } from "@/modules/supporters/services/data/supporter-type/supporter-type.interface";

import {
  generateEmailValidator,
  generateRequiredValidator,
  generateSupporterLocationsValidator,
  generateSupporterTypesValidator,
  generateURLValidator,
  generateRequiredListsValidator,
} from "@/services/errors/validator-generators";
import {
  EProfileSupporterActions,
  EProfileCompanyActions,
} from "@/modules/profile/services/store/profile/profile-types";

import { SupporterTypeState } from "@/modules/supporters/services/store/supporter-type/supporter-type.module";
import {
  EAuthCompanyActions,
  EAuthActions,
} from "@/modules/authentication/services/store/auth/auth-types";
import { CheckboxValueType } from "element-plus";

export default defineComponent({
  name: "CompanyEditModalSupporter",

  components: {
    MultiSelector,
  },

  mixins: [CompanyEditModalMixin],

  props: {
    selectedView: {
      type: String,
      default: "general",
      validator(value: string) {
        return !value || ["general", "interests"].includes(value);
      },
    },
  },

  data() {
    return {
      currentView: "",

      form: {
        data: {
          company: {
            name: "",
            website: "",
            email: "",
            locations: [],
            about: "",
            logo: "",
          } as ICompany,
          supporter: {
            sectors: [] as Partial<IMultiSelection>[],
            locations: [] as Partial<IMultiSelection>[],
            types: [] as Array<number>,
            otherType: null,
          },
        },
        file: null as File | null,
      },
      hasOtherType: false as CheckboxValueType,
      selectedTypes: [] as Array<number>,
      hasSpecificLocations: false,
      rules: {
        "company.name": generateRequiredValidator(
          this,
          `profile.companyEditModal.fields.name[${this.$profile.type()}]`,
        ),
        "company.website": generateURLValidator(
          this,
          "profile.companyEditModal.fields.website",
        ),
        "company.email": generateEmailValidator(
          this,
          "profile.companyEditModal.fields.email",
        ),
        "company.locations": generateRequiredListsValidator(
          this,
          "profile.companyEditModal.fields.location",
          { index: 0 },
        ),
        "company.about": {
          max: 200,
        },
        "supporter.types": generateSupporterTypesValidator(
          this,
          "form.data.supporter.otherType",
        ),
        "supporter.locations": generateSupporterLocationsValidator(
          this,
          "hasSpecificLocations",
        ),
      },
    };
  },

  computed: {
    supporter(): ISupporter | null {
      return this.$store.get("profile/supporter/data");
    },

    supporterTypes(): Array<ISupporterType> {
      return this.supporter ? this.supporter.types : [];
    },

    profileId(): number | null {
      return this.$store.state.auth.profileId;
    },

    availableTypes(): Array<ISupporterType> {
      return this.$store.getters[SupporterTypeState.Getter.VALUES];
    },
  },

  watch: {
    selectedTypes: {
      immediate: true,
      deep: true,
      handler(currentSelectedTypes: Array<number>) {
        // Filter out invalid values
        if (this.form) {
          this.form.data.supporter.types = currentSelectedTypes.filter(
            (type: number) => !!type,
          );
        }
      },
    },

    hasSpecificLocations() {
      this.formValidateLocations();
    },

    selectedView: {
      immediate: true,
      handler(newVal: string) {
        this.currentView = newVal;
      },
    },
  },

  methods: {
    // Reuse provider for this component
    searchSectors: multiSelectorSectorsProvider,

    searchLocations: multiSelectorLocationsProvider,

    /**
     * Handle the click on the save button.
     */
    async submitForm() {
      this.$emit("update:loadingSubmission", true);
      this.$emit("update:hasServerErrors", false);

      if (this.form.file) {
        await companyProvider.updateLogo(this.company.id, this.form.file);
      }

      if (!this.hasSpecificLocations) {
        // Set all locations value to an empty array
        this.form.data.supporter.locations = [];
      }

      try {
        const companyData = cloneDeep(this.form.data.company);
        const supporterData = cloneDeep(this.form.data.supporter);

        const supporterPayload = {
          types: supporterData.types,
          otherType: supporterData.otherType || null,
          // Divide location's selection into grouped locations and google places
          ...formatMultiLocationsSelection(supporterData.locations),
          // Divide sectors selection into sectors and grouped_sectors
          ...formatMultiSectorsSelection(supporterData.sectors),
        };

        const companyPayload = {
          name: companyData.name,
          email: companyData.email,
          about: companyData.about,
          website: companyData.website,
          location: companyData.locations
            ? companyData.locations[0]
            : undefined,
        };

        await this.$store.dispatch(EAuthCompanyActions.PATCH, companyPayload);
        await this.$store.dispatch(
          EProfileSupporterActions.PATCH,
          supporterPayload,
        );
      } catch (error) {
        this.$emit("update:hasServerErrors", true);
        return;
      } finally {
        this.$emit("update:loadingSubmission", false);
      }

      this.$message({
        message: this.$t("profile.companyEditModal.successMessage"),
        type: "success",
        customClass: "is-full",
      });

      this.$emit("modal-visibility", false);
      await this.fetchSupporterData();
      this.resetFormData();
    },

    /**
     * Reset form data to the current profile data
     */
    async resetFormData() {
      const company = cloneDeep(this.company) as ICompany;
      const supporter = cloneDeep(this.supporter) as ISupporter;

      // Update company form data
      this.form.data.company = Object.keys(this.form.data.company).reduce(
        (obj: any, key: string) => {
          obj[key] = company[key];
          return obj;
        },
        this.form.data.company,
      );

      // Merge locations and grouped locations for multi-selector
      this.form.data.supporter.locations = formatSupporterLocations(
        supporter as any,
      );

      // Merge sectors and grouped sectors for multi-selector
      this.form.data.supporter.sectors = formatSupporterSectors(
        supporter as any,
      );

      // Initialize location selector state
      this.hasSpecificLocations = this.form.data.supporter.locations.length > 0;
      await this.$nextTick();

      // Reset selected types
      this.selectedTypes = this.supporterTypes.map(
        (type: ISupporterType) => type.id,
      );
      this.form.data.supporter.otherType = null;
      this.hasOtherType = false;

      // Clean selected file
      this.form.file = null;

      // Store original data
      await this.$nextTick();
      this.originalData = cloneDeep(this.form.data);
      this.formRef.validate(() => this.updateSubmitState());
    },

    async fetchSupporterData() {
      //TODO: we should refactor the usage of two separate store modules (auth and profile) into just one to avoid unnecessary amount of identical requests.
      await Promise.all([
        this.$store.dispatch(EProfileSupporterActions.FETCH, {
          user_profile: this.profileId,
        }),
        this.$store.dispatch(EProfileCompanyActions.FETCH, this.company.id),
        this.$store.dispatch(EAuthCompanyActions.FETCH, this.company.id),
        this.$store.dispatch(EAuthActions.FETCH_SUPPORTER, {
          user_profile: this.profileId,
        }),
        this.$store.dispatch(SupporterTypeState.Action.GET_VALUES),
      ]);
    },

    /**
     * Handle the "other" type checkbox change event
     */
    otherTypeChangeHandler(checked: CheckboxValueType) {
      this.hasOtherType = checked;

      if (!checked) {
        this.form.data.supporter.otherType = null;
      }
    },

    /**
     * Validate locations and then check if form can be submitted
     */
    async formValidateLocations() {
      await this.$nextTick();

      this.formRef.validateField("supporter.locations", this.updateSubmitState);
    },
  },
});
</script>

<style lang="scss" scoped>
.avatar-section {
  padding-top: 18px;

  :deep(.el-upload) {
    display: flex;
    flex-direction: column;
    gap: 1px;

    @include breakpoint-down(sm) {
      flex-direction: row;
      gap: 0;
    }
  }

  @include breakpoint-down(sm) {
    display: flex;
    padding: 15px 0 7px 0;
  }
}

.avatar-section__actions {
  margin-top: 6px;

  @include breakpoint-down(sm) {
    margin-left: 14px;
    margin-top: 0;
  }
}

.avatar-section__text {
  @include grotesk(regular, italic);

  position: relative;
  left: -4px;
  padding-top: 4px;

  font-size: to-rem(12px);
  line-height: 18px;
  color: $manatee;

  @include breakpoint-down(sm) {
    padding-top: 7px;
    padding-left: 4px;

    font-size: to-rem(13px);
  }
}

.form-grid {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: calc(100% - 60px);
  padding-top: 28px;
  margin: auto;

  @include breakpoint-down(md) {
    flex-direction: column;
    max-width: calc(100% - 30px);
    padding-left: 0;
  }

  .form-grid__logo {
    margin-right: -3px;
  }

  .form-grid__left-fields,
  .form-grid__right-fields {
    @include breakpoint-up(lg) {
      width: 310px;
    }
  }
}

.form-grid--centered {
  justify-content: center;

  & > div {
    margin: 0 28px;

    @include breakpoint-down(md) {
      margin: 0;
    }
  }
}

.company-edit-modal--supporter :deep() {
  .el-dialog {
    width: 560px;

    .el-dialog__body {
      padding: 0 0 30px;
    }
  }

  .v-window-item {
    background-color: white;
  }

  .el-form-item--types {
    padding-bottom: 20px;

    .el-checkbox-group {
      display: inline-flex;
      flex-flow: row wrap;
      justify-content: space-between;
      width: 100%;
      margin-top: 9px;
    }

    .el-checkbox {
      flex: 1 1 43%;
      max-width: 50%;
      margin-left: 0;
      white-space: normal;

      &:nth-child(n + 3) {
        margin-top: 10px;
      }
    }

    .el-checkbox__label {
      position: relative;
      top: -4px;
      max-width: 80%;
    }

    .el-checkbox__input {
      vertical-align: top;
    }
  }

  .other-type-input {
    margin-top: 10px;
  }

  .el-radio-group {
    display: inline-flex;
    flex-flow: row wrap;
    width: 100%;
    margin-top: 7px;
  }

  .el-radio {
    flex-basis: 50%;
    margin-left: 0;
    color: $ebony-clay;

    &:nth-child(n + 3) {
      margin-top: 10px;
    }
  }

  .locations-input {
    margin-top: 2px;
  }

  .locations-selector {
    margin-top: 4px;
  }

  .locations-selector__list:empty + .locations-input {
    margin-top: 11px;
  }

  .locations-selector__list--with-entries {
    margin-top: 11px;
  }

  /* Hide textarea error */
  .px-textarea + .el-form-item__error {
    display: none;
  }
}
</style>
