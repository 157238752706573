<template>
  <div class="chart-locked">
    <img
      v-if="content.image"
      class="chart-locked__image"
      :src="content.image"
      alt="locked state mock data"
    />
    <div class="chart-locked__wrapper">
      <div>
        <PxIcon name="private" size="60" />
        <div class="chart-locked__content">
          <h3 v-if="content.title">{{ $t(content.title) }}</h3>
          <i18n-t
            v-if="content.description"
            :keypath="`${content.description}`"
            tag="p"
            class="chart-locked__description"
          >
            <template #privacy>
              <PxButton
                class="el-button--link-blue el-button--small"
                type="link"
                @click.stop="onClick"
              >
                {{ $t("chartsDashboard.lockedState.privacy") }}
              </PxButton>
            </template>
          </i18n-t>
          <p v-if="content.note" class="chart-locked__note">
            {{ $t(content.note) }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export interface IChartLockedContent {
  image: string;
  title: string;
  description: string;
  note: string;
}

export default defineComponent({
  name: "ChartLocked",

  props: {
    content: {
      type: Object as () => IChartLockedContent,
      required: true,
    },
  },

  methods: {
    onClick() {
      this.$emit("click");
    },
  },
});
</script>
<style lang="scss" scoped>
.chart-locked {
  position: relative;
  width: 100%;
  height: 100%;

  &__image {
    position: relative;
    top: 2px;
    width: 100%;
    height: 100%;
  }

  &__wrapper {
    position: absolute;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    padding: 0 18px 12px 18px;

    z-index: z("default") + 1;

    > div {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      gap: 24px;
    }
  }

  &__content {
    h3 {
      font-size: to-rem(18px);
      line-height: 1.56;
    }
  }

  &__note,
  &__description {
    @include grotesk(medium);

    color: $manatee;
    font-size: to-rem(15px);
    line-height: 1.46;
    letter-spacing: -0.19px;
  }

  &__note {
    margin-top: 8px;
    font-style: italic;
  }

  :deep(.el-button) {
    height: auto;
    padding: 0;
  }
}
</style>
