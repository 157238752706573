<template>
  <ElDialog
    ref="modal"
    v-model="innerVisibility"
    :title="$t(`profile.affiliateLink.modal.title`)"
    class="affiliate-link-edit-modal el-dialog--px-10"
    @close="onModalClose"
  >
    <div class="affiliate-link-edit-modal__wrapper">
      <h2>
        {{ $t(`profile.affiliateLink.modal.subtitle`) }}
      </h2>
      <span
        class="affiliate-link-edit-modal__tip"
        v-html="$t('profile.affiliateLink.modal.tip')"
      />
      <div class="affiliate-link-edit-modal__link">
        <span>
          {{ affiliateLinkText }}
        </span>
        <ElTooltip
          :manual="true"
          :value="copiedVisibility"
          :visible-arrow="false"
          placement="bottom"
          popper-class="affiliate-link-edit-modal__copied-tooltip el-abaca-tooltip"
        >
          <ElInput v-model="affiliateLink" />
          <template #content>
            <p>
              {{ $t("profile.affiliateLink.copyClipboard") }}
            </p>
          </template>
        </ElTooltip>
      </div>
      <div class="affiliate-link-edit-modal__hint">
        <div>
          <PxIcon :size="14" name="information--light-gray" />
        </div>
        <span>
          {{ $t("profile.affiliateLink.modal.hint") }}
        </span>
      </div>
    </div>
    <template #footer>
      <div>
        <PxButton
          :disabled="copiedVisibility"
          size="small"
          type="link"
          @click="onModalClose"
        >
          {{ $t("common.cancel") }}
        </PxButton>
        <PxButton
          :disabled="copiedVisibility"
          type="green"
          @click="onClickGenerate"
        >
          {{ $t("profile.affiliateLink.primaryCta") }}
        </PxButton>
      </div>
    </template>
  </ElDialog>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { ICompany } from "@/modules/profile/services/data/company/company.types";
import { copyToClipboard } from "@/utils/clipboard";
import { EAuthActions } from "@/modules/authentication/services/store/auth/auth-types";
import { PROFILE_AFFILIATE_LINK_QUERY } from "@/modules/profile/constants";
import { EProfileSupporterActions } from "@/modules/profile/services/store/profile/profile-types";

export default defineComponent({
  name: "AffiliateLinkEditModal",

  props: {
    visibility: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      affiliateLink: "",
      innerVisibility: false,
      copiedVisibility: false,
    };
  },

  computed: {
    company(): ICompany | null {
      return this.$store.get("profile/company.data");
    },

    companyNameLink(): string {
      return this.company ? this.company.name : "";
    },

    affiliateLinkText(): string {
      return `${location.host}/${PROFILE_AFFILIATE_LINK_QUERY}`;
    },

    profileId(): number | null {
      return this.$store.state.auth.profileId;
    },
  },

  watch: {
    visibility: {
      immediate: true,
      handler(newVal) {
        this.innerVisibility = newVal;

        if (!newVal) {
          return;
        }
      },
    },

    innerVisibility(newVal, oldVal) {
      if (newVal === oldVal) {
        return;
      }

      if (!newVal) {
        this.$emit("update:visibility", newVal);
      }
    },

    affiliateLink: {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal === oldVal) {
          return;
        }

        // Regular expression to match characters that are not letters, numbers, underscores, or hyphens
        // eslint-disable-next-line no-useless-escape
        const regex = /[^A-Za-z0-9_\-]/g;
        this.affiliateLink = this.affiliateLink.replace(regex, "");
      },
    },
  },

  created() {
    this.affiliateLink = this.companyNameLink;
  },

  methods: {
    onModalClose() {
      window.scrollTo(0, 0);
      this.copiedVisibility = false;
      this.innerVisibility = false;
      this.affiliateLink = this.companyNameLink;
    },

    async onClickGenerate() {
      const affiliate = await this.$store.dispatch(
        EAuthActions.CREATE_AFFILIATE_LINK,
        {
          slug: this.affiliateLink,
        },
      );

      if (!affiliate) {
        this.$message({
          message: this.$t(
            "profile.affiliateLink.modal.errorMessage",
          ) as string,
          type: "error",
          duration: 10000,
          customClass: "is-full",
        });
      } else {
        // Save data here and remove temporary affiliate link prop
        this.copiedVisibility = true;

        // fetch updated supporter data
        await this.$store.dispatch(EAuthActions.FETCH_SUPPORTER, {
          user_profile: this.profileId,
        });
        this.$store.dispatch(EProfileSupporterActions.FETCH, {
          user_profile: this.profileId,
        });

        copyToClipboard(
          `${location.origin}/${PROFILE_AFFILIATE_LINK_QUERY}${this.affiliateLink}`,
        );

        this.onModalClose();

        this.$message({
          message: this.$t(
            "profile.affiliateLink.modal.successMessage",
          ) as string,
          type: "success",
          duration: 10000,
          customClass: "is-full",
        });
      }
    },
  },
});
</script>

<style lang="scss">
.affiliate-link-edit-modal {
  &.el-dialog {
    width: 903px;
    touch-action: none !important;

    /* touch-action: this was added to allow the caption of scroll event */

    @include breakpoint-down(md) {
      max-width: calc(100% - 30px);
      max-height: 698px;
      overflow-y: scroll;
    }

    @include breakpoint-down(sm) {
      max-height: calc(100vh - 24px);
    }

    .el-dialog__body {
      padding-right: 0;
      padding-left: 0;

      @include breakpoint-down(lg) {
        overflow: auto;
      }
    }
  }

  &__wrapper {
    padding: 0 30px;
    color: $ebony-clay;

    h2 {
      @include grotesk(semiBold);

      font-size: 20px;
      margin: 0;
    }
  }

  &__hint {
    display: flex;
    padding-top: 40px;

    span {
      padding-left: 6px;
      font-size: 13px;
      font-style: italic;
      color: $manatee;
    }
  }

  .px-modal-message {
    pointer-events: none;
  }

  &__tip {
    margin: 0;

    font-style: italic;
    font-weight: 400;
    font-size: 0.8rem;
    line-height: 15px;
    color: $manatee;
    letter-spacing: -0.05px;
  }

  &__link {
    display: flex;
    align-items: center;
    padding-top: 12px;

    span {
      min-width: 130px;
      padding-right: 10px;
      font-size: 15px;
    }

    .el-input {
      max-width: 328px;
    }
  }

  .affiliate-link-edit-modal__copied-tooltip {
    height: 31px;
    padding: 3px 6px;
  }
}
</style>
