<template>
  <div
    :class="{ 'team-members-list-member-entry--selected': isSelected }"
    class="team-members-list-member-entry"
  >
    <div class="team-members-list-member-entry__wrapper">
      <PxAvatar
        :name="member.first_name"
        :size="32"
        styling="neutral"
        type="round"
      />
      <div class="team-members-list-member-entry__content">
        <p class="team-members-list-member-entry__title">
          {{ memberName }}
        </p>
        <p v-if="member.email" class="team-members-list-member-entry__subtitle">
          {{ member.email }}
        </p>
      </div>
    </div>
    <div
      v-if="showRemoveButton"
      class="team-members-list-member-entry__actions"
    >
      <ElTooltip
        v-if="isSelected"
        ref="remove"
        :enterable="false"
        placement="top"
        popper-class="team-members-list-member-entry__tooltip el-abaca-tooltip"
      >
        <template #content>
          <div v-text="removeCopy" />
        </template>
        <div
          class="team-members-list-member-entry__remove-cta"
          data-testid="team-members-list-member-entry-remove"
          @click.stop="removeClickHandler"
        >
          <PxIcon :size="14" name="trash--red" />
          <PxIcon :size="14" class="px-icon--hover" name="trash" />
        </div>
      </ElTooltip>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import { TranslateResult } from "vue-i18n";
import { ITeamMember } from "@/modules/team-management/services/data/team-members/team-members.interface";

export default defineComponent({
  name: "TeamMembersListMemberEntry",

  props: {
    member: {
      type: Object as () => ITeamMember,
      required: true,
    },

    isSelected: {
      type: Boolean,
      default: false,
    },

    showRemoveButton: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    removeCopy(): TranslateResult {
      return this.$t("common.components.default.cta.remove");
    },

    memberName(): string {
      // If is empty member
      if (!this.member.first_name && !this.member.last_name) {
        return this.$t("teamManagement.newMember");
      }

      //If we have both names
      if (this.member.first_name && this.member.last_name) {
        return `${this.member.first_name} ${this.member.last_name}`;
      }

      return this.member.first_name
        ? this.member.first_name
        : this.member.last_name;
    },
  },

  methods: {
    removeClickHandler() {
      this.$emit("remove-member");
    },
  },
});
</script>

<style lang="scss" scoped>
.team-members-list-member-entry {
  display: flex;
  justify-content: space-between;
  align-items: center;

  height: 60px;
  margin: 0;

  padding-left: 23px;

  cursor: pointer;

  &:hover {
    background-color: #f2f8ff;
  }

  &__wrapper {
    display: flex;
    align-items: center;
    gap: 13px;
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  .px-avatar :deep() {
    box-shadow: none;

    div {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .px-avatar__letter {
      font-size: 16px;
    }
  }

  &__title,
  &__subtitle {
    @include grotesk(regular);

    margin: 0;
    line-height: inherit;
    letter-spacing: -0.4px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 160px;
  }

  &__title {
    font-size: 14px;
    color: $ebony-clay;
  }

  &__subtitle {
    font-size: 13px;
    color: $manatee;
  }

  &--selected {
    position: relative;

    &::before {
      position: absolute;
      left: 0;
      top: 0;
      content: "";
      display: block;
      width: 4px;
      height: 100%;
      background-color: $bluish;
      border-top-right-radius: 2px;
      border-bottom-right-radius: 2px;
    }

    .team-members-list-member-entry__title,
    .team-members-list-member-entry__subtitle,
    .px-avatar :deep() .px-avatar__letter {
      color: $bluish;
    }

    .px-avatar {
      background-color: rgba($malibu, 0.3);
    }

    .team-members-list-member-entry__title {
      @include grotesk(semiBold);
    }
  }

  &__actions {
    padding-right: 20px;
  }

  &__remove-cta {
    position: relative;
    width: 32px;
    height: 32px;
    cursor: pointer;
    user-select: none;
    border: solid 1px rgba($wild-watermelon, 0.2);
    border-radius: 3px;
    opacity: 0.6;

    &::before {
      position: absolute;
      top: -1px;
      left: -1px;
      width: calc(100% + 2px);
      height: calc(100% + 2px);
      content: "";
      background-color: $wild-watermelon;
      border: inset 1px rgba($ebony-clay, 0.2);
      border-radius: 3px;
      opacity: 0;
      transition: $--fade-transition;
    }

    .px-icon {
      position: absolute;
      top: 50%;
      left: 50%;
      vertical-align: top;
      transform: translate(-50%, -50%);
    }

    .px-icon--hover {
      filter: brightness(2);
      opacity: 0;
      transition: $--fade-transition;
    }

    &:hover {
      opacity: 1;

      &::before {
        opacity: 1;
      }

      .px-icon--hover {
        opacity: 1;
      }
    }
  }
}
</style>

<style lang="scss">
.team-members-list-member-entry__tooltip {
  padding: 5px 10px;
  width: 69px;
  letter-spacing: -0.17px;
}
</style>
