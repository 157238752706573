<template>
  <div class="support-modal-success">
    <PxIcon
      :size="90"
      class="support-modal-success__icon"
      name="email-sent--orange"
    />
    <h3 class="support-modal-success__title">
      {{ $t("common.components.supportModal.successMessage.title") }}
    </h3>
    <p
      class="support-modal-success__message"
      v-html="
        $t(`common.components.supportModal.successMessage.message`, {
          email,
        })
      "
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "SupportModalSuccess",

  props: {
    email: {
      type: String,
      required: true,
    },
  },
});
</script>

<style lang="scss" scoped>
.support-modal-success {
  padding: 10px 0 20px;
  text-align: center;

  &__icon {
    margin-bottom: 10px;
  }

  &__title {
    @include grotesk(semiBold);

    margin-bottom: 19px;
    font-size: 22px;
    line-height: 40px;
    color: $atomic-tangerine;
  }

  &__message {
    font-size: 15px;
    line-height: 24px;
    color: $ebony-clay;
    letter-spacing: -0.2px;
    white-space: pre-wrap;
  }
}
</style>
