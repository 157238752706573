import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-2924a8de"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "submissions-responses__container" }
const _hoisted_2 = { class: "submissions-responses__question" }
const _hoisted_3 = { class: "submissions-responses__answer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.responses, (response, index) => {
      return (_openBlock(), _createElementBlock("div", {
        key: `response-${index}`,
        class: "submissions-responses__item"
      }, [
        _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.getQuestion(response)), 1),
        _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.getAnswer(response)), 1)
      ]))
    }), 128))
  ]))
}