import {
  EProviderFeatures,
  GenericProvider,
  Provider,
} from "@/services/data/generic-provider";
import { IUserGuest } from "@/services/data/user-guest/user-guest.interface";
import { baseAPIUrl } from "@/services/utils/utils";

@Provider(`${baseAPIUrl}/users-guest`, [EProviderFeatures.CREATE])
class UserGuestProvider extends GenericProvider<IUserGuest> {}

/**
 * Provider to create new user guests.
 *
 * Keep in mind that user guests are unique based on their `email`. That means that multiple create requests will
 * return the same record.
 */
export const userGuestProvider = new UserGuestProvider();
