import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, withCtx as _withCtx, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-6dae331a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "list-detail-panel" }
const _hoisted_2 = { class: "list-detail-panel__body" }
const _hoisted_3 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ListDetailPanelHeader = _resolveComponent("ListDetailPanelHeader")!
  const _component_MatchingCardCompactListHeader = _resolveComponent("MatchingCardCompactListHeader")!
  const _component_MatchingCardCompact = _resolveComponent("MatchingCardCompact")!
  const _component_MatchingCardCompactPlaceholder = _resolveComponent("MatchingCardCompactPlaceholder")!
  const _component_VirtualGrid = _resolveComponent("VirtualGrid")!
  const _component_PxIcon = _resolveComponent("PxIcon")!
  const _component_PxButton = _resolveComponent("PxButton")!
  const _component_ListDetailPanelEmpty = _resolveComponent("ListDetailPanelEmpty")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ListDetailPanelHeader, {
      "list-user-type": _ctx.listUserType,
      value: _ctx.value,
      onRegister: _ctx.onRegistration
    }, null, 8, ["list-user-type", "value", "onRegister"]),
    _createElementVNode("div", _hoisted_2, [
      (_ctx.showCompanies)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createVNode(_component_MatchingCardCompactListHeader, {
              "is-same-type-of-users": _ctx.isSameTypeOfUsers,
              "user-type": _ctx.listUserType
            }, null, 8, ["is-same-type-of-users", "user-type"]),
            _createVNode(_component_VirtualGrid, {
              columns: _ctx.$options.static.virtualGridColumns,
              "grid-gap": _ctx.$options.static.virtualGridGap,
              "item-height": _ctx.$options.static.virtualRowHeight,
              items: _ctx.membersList,
              "load-on-mount": true,
              onScroll: _ctx.fetchMoreMatches
            }, {
              items: _withCtx((items) => [
                (_openBlock(), _createBlock(_component_MatchingCardCompact, {
                  key: `compact-card-${items.item.id}`,
                  about: items.item.about,
                  affiliates: items.item.affiliates,
                  badges: items.item.badges,
                  "company-id": items.item.id,
                  "company-uid": items.item.uid,
                  "is-directory-member": items.item.isDirectoryMember,
                  level: items.item.level,
                  location: items.item.location,
                  locations: items.item.locations,
                  "match-percentage": items.item.percentage,
                  name: items.item.name,
                  offerings: items.item.offerings,
                  sectors: items.item.sectors,
                  "show-connect-button": !_ctx.isSameTypeOfUsers,
                  "show-list-button": _ctx.isAuthUser && !_ctx.isSameTypeOfUsers,
                  "show-options": _ctx.showCardOptions,
                  "show-percentage": !_ctx.isSameTypeOfUsers,
                  "show-public-view": !_ctx.isAuthUser,
                  thumbnail: items.item.thumbnail,
                  onClick: ($event: any) => (_ctx.clickMatchingCardHandler(items.item.id)),
                  onRegister: _ctx.onRegistration,
                  onRemove: _ctx.onCompanyRemoval
                }, null, 8, ["about", "affiliates", "badges", "company-id", "company-uid", "is-directory-member", "level", "location", "locations", "match-percentage", "name", "offerings", "sectors", "show-connect-button", "show-list-button", "show-options", "show-percentage", "show-public-view", "thumbnail", "onClick", "onRegister", "onRemove"]))
              ]),
              loading: _withCtx((loading) => [
                (_openBlock(), _createBlock(_component_MatchingCardCompactPlaceholder, {
                  key: `virtual-list-placeholder-${loading.item}`,
                  "show-percentage-pill": !_ctx.isSameTypeOfUsers
                }, null, 8, ["show-percentage-pill"]))
              ]),
              _: 1
            }, 8, ["columns", "grid-gap", "item-height", "items", "onScroll"])
          ]))
        : (_openBlock(), _createBlock(_component_ListDetailPanelEmpty, {
            key: 1,
            subtitle: _ctx.emptyListSubtitle,
            title: _ctx.emptyListTitle,
            icon: "matching-no-matches"
          }, {
            default: _withCtx(() => [
              (_ctx.showAddMembersButton)
                ? (_openBlock(), _createBlock(_component_PxButton, {
                    key: 0,
                    class: "lists__main-cta",
                    size: "medium",
                    type: "green",
                    onClick: _ctx.addMembersClickHandler
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_PxIcon, {
                        size: 16,
                        name: "plus-white"
                      }),
                      _createTextVNode(" " + _toDisplayString(_ctx.$t("companyLists.detail.addMembersButton")), 1)
                    ]),
                    _: 1
                  }, 8, ["onClick"]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["subtitle", "title"]))
    ])
  ]))
}