import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-d40d20c4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "px-questionary-criteria" }
const _hoisted_2 = { class: "px-questionary-criteria__stops" }
const _hoisted_3 = { class: "px-questionary-criteria__labels" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ElSlider = _resolveComponent("ElSlider")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ElSlider, {
      modelValue: _ctx.selectedWeight,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedWeight) = $event)),
      "format-tooltip": _ctx.formatTooltip,
      max: _ctx.weightsCount,
      min: 1,
      step: 1,
      "show-stops": "",
      "tooltip-class": "px-questionary-criteria__tooltip",
      onChange: _ctx.sliderChangeHandler
    }, null, 8, ["modelValue", "format-tooltip", "max", "onChange"]),
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.weightsCount, (index) => {
        return (_openBlock(), _createElementBlock("span", {
          key: `${index}`,
          class: "px-questionary-criteria__tooltip-text"
        }))
      }), 128))
    ]),
    _createElementVNode("div", _hoisted_3, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.weightLabels, (label) => {
        return (_openBlock(), _createElementBlock("span", {
          key: label.id,
          class: _normalizeClass({ 'is-active': label.id === _ctx.currentWeightId })
        }, _toDisplayString(label.name), 3))
      }), 128))
    ])
  ]))
}