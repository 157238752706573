<template>
  <ul
    :class="`milestone-plan-onboarding-dialog__content--second-page-${userType}`"
    class="milestone-plan-onboarding-dialog__content--second-page"
  >
    <template v-if="!isVisitor">
      <li>
        <i18n-t
          :keypath="`milestonePlanner.milestonePlan.onboardingModal.${userType}.content.secondTab.0`"
          tag="p"
        >
          <template #completeTab>
            <strong v-text="onboardingCopy.completeTab" />
          </template>
        </i18n-t>
      </li>
      <li>
        <i18n-t
          :keypath="`milestonePlanner.milestonePlan.onboardingModal.${userType}.content.secondTab.1`"
          tag="p"
        >
          <template #firstIcon>
            <PxButton v-bind="completeMilestoneButtonProps" />
          </template>
          <template #secondIcon>
            <PxIcon :size="16" name="self-assessed" />
          </template>
          <template #breakText>
            <i18n-t
              :keypath="`milestonePlanner.milestonePlan.onboardingModal.${userType}.content.secondTab.2`"
              class="break-text"
              tag="p"
            >
              <template #stronglyRecomend>
                <strong v-text="onboardingCopy.stronglyRecomend" />
              </template>
              <template #firstIcon>
                <PxIcon
                  :size="17"
                  class="evidence-provided"
                  name="evidence-provided"
                />
              </template>
            </i18n-t>
          </template>
        </i18n-t>
      </li>
      <li>
        <i18n-t
          :keypath="`milestonePlanner.milestonePlan.onboardingModal.${userType}.content.secondTab.3`"
          tag="p"
        >
          <template #firstIcon>
            <PxButton v-bind="saveAsDraftButtonProps" />
          </template>
          <template #breakText>
            <i18n-t
              :keypath="`milestonePlanner.milestonePlan.onboardingModal.${userType}.content.secondTab.4`"
              class="break-text"
              tag="p"
            >
              <template #firstIcon>
                <MilestoneStageStatusItem
                  v-bind="inProgressIconProps"
                  :is-clickable="false"
                />
              </template>
            </i18n-t>
          </template>
        </i18n-t>
      </li>
      <li>
        <i18n-t
          :keypath="`milestonePlanner.milestonePlan.onboardingModal.${userType}.content.secondTab.5`"
          tag="p"
        >
          <template #firstIcon>
            <PxButton v-bind="completeMilestoneButtonProps" />
          </template>
          <template #secondIcon>
            <MilestoneStageStatusItem
              v-bind="completedIconProps"
              :is-clickable="false"
            />
          </template>
          <template #breakText>
            <i18n-t
              :keypath="`milestonePlanner.milestonePlan.onboardingModal.${userType}.content.secondTab.6`"
              class="break-text"
              tag="p"
            >
              <template #firstIcon>
                <PxIcon
                  :size="16"
                  class="milestone-plan-onboarding-dialog__content--filtered-icon"
                  name="completed-milestone--grey"
                />
              </template>
            </i18n-t>
          </template>
        </i18n-t>
      </li>
    </template>
    <template v-else>
      <li>
        <i18n-t
          :keypath="`milestonePlanner.milestonePlan.onboardingModal.${userType}.content.secondTab.0`"
          tag="p"
        >
          <template #completeTab>
            <strong v-text="onboardingCopy.completeTab" />
          </template>
        </i18n-t>
      </li>
      <li>
        <i18n-t
          :keypath="`milestonePlanner.milestonePlan.onboardingModal.${userType}.content.secondTab.1`"
          tag="p"
        >
          <template #selfAssessedIcon>
            <PxIcon :size="16" name="self-assessed" />
          </template>
          <template #breakText>
            <i18n-t
              :keypath="`milestonePlanner.milestonePlan.onboardingModal.${userType}.content.secondTab.2`"
              class="break-text"
              tag="p"
            >
              <template #stronglyRecomend>
                <strong v-text="onboardingCopy.stronglyRecomend" />
              </template>
              <template #evidenceIcon>
                <PxIcon
                  :size="17"
                  class="evidence-provided"
                  name="evidence-provided"
                />
              </template>
            </i18n-t>
          </template>
        </i18n-t>
      </li>
      <li>
        <i18n-t
          :keypath="`milestonePlanner.milestonePlan.onboardingModal.${userType}.content.secondTab.3`"
          tag="p"
        >
          <template #breakText>
            <i18n-t
              :keypath="`milestonePlanner.milestonePlan.onboardingModal.${userType}.content.secondTab.4`"
              tag="p"
            >
              <template #inProgressIcon>
                <MilestoneStageStatusItem
                  v-bind="inProgressIconProps"
                  :is-clickable="false"
                />
              </template>
            </i18n-t>
          </template>
        </i18n-t>
      </li>
      <li>
        <i18n-t
          :keypath="`milestonePlanner.milestonePlan.onboardingModal.${userType}.content.secondTab.5`"
          tag="p"
        >
          <template #completedIcon>
            <MilestoneStageStatusItem
              v-bind="completedIconProps"
              :is-clickable="false"
            />
          </template>
          <template #achivedIcon>
            <PxIcon
              :size="16"
              class="milestone-plan-onboarding-dialog__content--filtered-icon"
              name="completed-milestone--grey"
            />
          </template>
        </i18n-t>
      </li>
    </template>
  </ul>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import MilestoneStageStatusItem from "@/modules/milestone-planner/components/milestone-stage-status-item/milestone-stage-status-item.vue";
import {
  EPxButtonType,
  EPxButtonSize,
  EPxButtonVariant,
} from "@/components/px-button/px-button.types";
import { EMilestoneStatusType } from "@/modules/milestone-planner/services/data/milestones/milestone.interface";
import { MATCHING_USER_TYPES } from "@/modules/matching/constants";

export default defineComponent({
  name: "MilestonePlanOnboardingDialogContentSecondPage",

  components: {
    MilestoneStageStatusItem,
  },

  props: {
    isVisitor: {
      type: Boolean,
      default: false,
    },
  },

  static: {
    saveAsDraftButton: {
      type: EPxButtonType.DEFAULT,
      size: EPxButtonSize.XXX_SMALL,
      variant: EPxButtonVariant.FLUID,
    },
    completeMilestoneButton: {
      type: EPxButtonType.BLUE,
      size: EPxButtonSize.XXX_SMALL,
      variant: EPxButtonVariant.FLUID,
    },
  },

  computed: {
    onboardingCopy() {
      return this.$tm(
        `milestonePlanner.milestonePlan.onboardingModal.${this.userType}.content.secondTab`,
      ) as {
        [key: string]: string;
      };
    },

    saveAsDraftButtonProps(): any {
      return {
        ...this.$options.static.saveAsDraftButton,
        label: this.$t("milestonePlanner.milestonePlan.buttons.saveAsDraft"),
      };
    },

    completeMilestoneButtonProps(): any {
      return {
        ...this.$options.static.completeMilestoneButton,
        label: this.$t(
          "milestonePlanner.milestonePlan.buttons.completeMilestone",
        ),
      };
    },

    inProgressIconProps(): any {
      return {
        title: this.$t("milestonePlanner.milestoneGrid.stageStatus.inProgress"),
        icon: EMilestoneStatusType.IN_PROGRESS,
      };
    },

    completedIconProps(): any {
      return {
        title: this.$t("milestonePlanner.milestoneGrid.stageStatus.completed"),
        icon: EMilestoneStatusType.COMPLETED,
      };
    },

    userType(): string {
      return this.isVisitor ? "visitor" : MATCHING_USER_TYPES[0];
    },
  },
});
</script>

<style lang="scss" scoped>
.milestone-plan-onboarding-dialog__content--second-page {
  :deep() .px-stage-status-item {
    display: inline-flex;
    padding: 0;

    .px-icon {
      filter: brightness(0.2);
    }

    .px-stage-status-item__title {
      color: $ebony-clay;
    }
  }

  li:nth-child(1) p {
    letter-spacing: -0.25px;
  }

  li:nth-child(2) {
    margin-bottom: 15px;
  }

  li:nth-child(2) p {
    letter-spacing: -0.33px;
    button {
      position: relative;
      bottom: 1px;
      margin-right: 2px;
      margin-bottom: -2px;
    }

    img {
      position: relative;
      top: 4px;
      left: 2px;
      margin-right: 2px;
    }

    .break-text {
      position: relative;
      right: 1px;
    }

    .evidence-provided {
      top: 3px;
      left: 1px;
    }

    strong {
      letter-spacing: -0.11px;
    }
  }

  li:nth-child(3) p {
    button {
      position: relative;
      bottom: 2px;
    }

    .break-text {
      position: relative;
      bottom: 13px;
      letter-spacing: -0.34px;
    }

    .px-stage-status-item {
      position: relative;
      top: 10px;
      left: 1px;
      margin-right: 8px;
    }
  }

  li:nth-child(4) p {
    letter-spacing: -0.3px;

    button {
      position: relative;
      bottom: 2px;
      margin-right: 3px;
    }

    .break-text {
      position: relative;
      bottom: 2px;
    }

    img {
      top: 2px;
      left: 1px;
    }
  }

  &-entrepreneur {
    li:nth-child(2) p .break-text {
      bottom: 3px;
      letter-spacing: -0.33px;
    }

    li:nth-child(3) {
      margin-bottom: -7px;
    }

    li:nth-child(3) p {
      letter-spacing: -0.3px;
    }

    li:nth-child(4) p .px-stage-status-item {
      position: relative;
      top: 10px;
      left: 5px;
    }
  }

  &-visitor {
    li:nth-child(2) p .break-text {
      bottom: 2px;
      letter-spacing: 0;
    }

    li:nth-child(3) {
      margin-bottom: -5px;
    }

    li:nth-child(3) p {
      letter-spacing: -0.33px;
    }

    li:nth-child(4) p .px-stage-status-item {
      top: 11px;
      left: 4px;
    }

    li:nth-child(3) p p {
      position: relative;
      bottom: 12px;
      letter-spacing: -0.3px;
    }

    li:nth-child(3) p p .px-stage-status-item {
      left: 7px;
      margin-right: 11px;
    }
  }
}
</style>
