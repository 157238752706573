<template>
  <div class="process-reports-chart">
    <div class="process-reports-chart__header-wrapper">
      <div class="process-reports-chart__header">
        <div class="process-reports-chart__header-content">
          <h1 v-if="chartTitle">{{ chartTitle }}</h1>
          <p v-if="chartDescription">{{ chartDescription }}</p>
        </div>
        <CompareDropdowns :options="selectOptions" @change="setSelectedSteps" />
      </div>
      <ProcessReportsLocked
        v-if="hasLockedSteps"
        :all-steps-locked="allStepsLocked"
        :copy="lockedState"
      />
    </div>
    <div v-if="!allStepsLocked" class="process-reports-chart__content">
      <div
        class="process-reports-chart__wrapper"
        :class="{
          'process-reports-chart__wrapper--no-card': !showInsightsCard,
        }"
      >
        <BarChart :data="barChartFinalData" :compare-data="dataToCompare" />
        <InsightsCard v-if="showInsightsCard" :insights="insights" />
      </div>
      <ChartsTableWrapper
        :labels="responsesLabels"
        :colors="backgroundColors"
        :theme="theme"
        :data="data"
        :data-to-compare="dataToCompare"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import BarChart from "@/components/charts/bar-chart/bar-chart.vue";
import ChartsTableWrapper from "@/modules/charts-dashboard/components/common/charts-table-wrapper/charts-table-wrapper.vue";
import InsightsCard from "@/modules/charts-dashboard/components/common/insights-card/insights-card.vue";
import CompareDropdowns from "@/modules/charts-dashboard/components/common/compare-dropdowns/compare-dropdowns.vue";
import ProcessReportsLocked from "@/modules/charts-dashboard/components/process-reports/process-reports-locked.vue";

import { CHARTS_BACKGROUND_COLORS } from "@/modules/charts-dashboard/components/charts-background-colors";
import { IProcessReportsChartData } from "@/modules/charts-dashboard/services/data/process-reports-data/process-reports-data.interface";
import { ICompareDropdownOption } from "@/modules/charts-dashboard/components/common/compare-dropdowns/compare-dropdowns.vue";
import { IChartLockedContent } from "@/modules/charts-dashboard/components/common/chart-locked/chart-locked.vue";
import { cloneDeep } from "lodash";

export interface IBarChartDataset {
  label: string;
  data: number[];
  absoluteValues: number[];
  backgroundColor: string;
}

export default defineComponent({
  name: "ProcessReportsChart",

  components: {
    BarChart,
    InsightsCard,
    CompareDropdowns,
    ChartsTableWrapper,
    ProcessReportsLocked,
  },

  props: {
    data: {
      type: Array as () => IProcessReportsChartData[],
      required: true,
    },

    chartTitle: {
      type: String,
      default: "",
    },

    chartDescription: {
      type: String,
      default: "",
    },

    theme: {
      type: String,
      required: true,
    },

    lockedState: {
      type: Object as () => IChartLockedContent,
      default: () => ({
        title: "",
        description: "",
        note: "",
        image: "",
      }),
    },
  },

  data() {
    return {
      backgroundColors: CHARTS_BACKGROUND_COLORS,
      selectedSteps: [] as number[],
      privacyModalVisible: false,
    };
  },

  computed: {
    responsesLabels(): Array<string> {
      return (
        this.data
          .find((data: IProcessReportsChartData) => data.responses.length)
          ?.responses.map((response: any) => response.value) || []
      );
    },

    listsName(): Array<string> {
      return this.data.map((data: IProcessReportsChartData) => data.step);
    },

    selectOptions(): Array<ICompareDropdownOption> {
      const options = [] as ICompareDropdownOption[];

      this.data.map((listData: IProcessReportsChartData, index: number) => {
        options.push({
          label: listData.step,
          index,
          disabled: listData.is_locked,
        });
      });

      return options;
    },

    responsesDataSets(): IBarChartDataset[] {
      const datasets: IBarChartDataset[] = [];

      this.responsesLabels.forEach((label: string, index: number) => {
        const data: number[] = [];
        const absoluteValues: number[] = [];

        this.data.forEach((listData: IProcessReportsChartData) => {
          const response = listData.responses.find(
            (response: any) => response.value === label,
          );
          data.push(
            response
              ? parseFloat(((response.percentage * 1000) / 10).toFixed(1))
              : 0,
          );
          absoluteValues.push(response ? response.count : 0);
        });

        datasets.push({
          label,
          backgroundColor: this.backgroundColors[index],
          data,
          absoluteValues,
        });
      });

      return datasets;
    },

    hasLockedSteps(): boolean {
      return this.data.some(
        (listData: IProcessReportsChartData) => listData.is_locked,
      );
    },

    allStepsLocked(): boolean {
      return this.data.every(
        (listData: IProcessReportsChartData) => listData.is_locked,
      );
    },

    comparisonDatasets(): any {
      return this.selectedSteps.length
        ? this.responsesDataSets.map((dataset: any) => {
            const data = dataset.data.map((value: number, index: number) => {
              return this.selectedSteps.includes(index) ? value : null;
            });

            return {
              ...dataset,
              data,
            };
          })
        : null;
    },

    barChartResponsesData(): any {
      return {
        labels: this.listsName,
        datasets: this.selectedSteps.length
          ? this.comparisonDatasets
          : this.responsesDataSets,
      };
    },

    barChartFinalData(): any {
      const barChartFinalData = cloneDeep(this.barChartResponsesData);

      if (this.hasLockedSteps && !this.selectedSteps.length) {
        barChartFinalData.datasets.push({
          label: "locked",
          backgroundColor: "#dcdde2",
          data: this.data.map((listData: IProcessReportsChartData) => {
            return listData.is_locked ? 100 : 0;
          }),
          absoluteValues: this.data.map(
            (listData: IProcessReportsChartData) => {
              return listData.is_locked ? 1 : 0;
            },
          ),
        });
      }

      return barChartFinalData;
    },

    dataToCompare(): {
      labels: string[];
      datasets: IBarChartDataset[];
    } | null {
      if (this.selectedSteps.length) {
        const datasets = this.responsesDataSets.map((dataset: any) => {
          const data = dataset.data.filter((_: any, index: number) => {
            return this.selectedSteps.includes(index);
          });

          const absoluteValues = dataset.absoluteValues.filter(
            (_: any, index: number) => {
              return this.selectedSteps.includes(index);
            },
          );

          return {
            ...dataset,
            data,
            absoluteValues,
          };
        });

        const labels = this.listsName.filter((label: string, index: number) => {
          return this.selectedSteps.includes(index);
        });

        return {
          labels,
          datasets,
        };
      }

      return null;
    },

    biggestIncrease(): any {
      return this.findMaxChange(
        this.selectedSteps.length
          ? this.dataToCompare
          : this.barChartResponsesData,
        true,
      );
    },

    biggestDecrease(): any {
      return this.findMaxChange(
        this.selectedSteps.length
          ? this.dataToCompare
          : this.barChartResponsesData,
      );
    },

    insights(): any {
      const insightsCardCopy = "chartsDashboard.insightsCard.insight";
      const insights = [] as any;

      if (!this.hasLockedSteps) {
        const insightsData = [
          { condition: this.biggestIncrease, index: 0 },
          { condition: this.biggestDecrease, index: 1 },
        ];

        insightsData.forEach(({ condition, index }) => {
          if (condition.pair.length) {
            insights.push({
              title: this.$t(`${insightsCardCopy}.title[${index}]`),
              emoji: this.$t(`${insightsCardCopy}.emoji[${index}]`),
              description: this.$t(`${insightsCardCopy}.description`, {
                category: condition.category,
                type: this.$t(`${insightsCardCopy}.type[${index}]`),
                value: condition.value,
                step1: condition.pair[0],
                step2: condition.pair[1],
              }),
            });
          }
        });
      }

      return insights;
    },

    showInsightsCard(): boolean {
      return !!this.insights.length && this.$screen.xlUp;
    },
  },

  methods: {
    findMaxChange(chartData: any, isIncrease = false): any {
      let maxChange = 0;
      let maxChangePair: number[] = [];
      let category = "";

      for (const obj of chartData.datasets) {
        const data = obj.data;

        for (let i = 0; i < data.length - 1; i++) {
          const change = isIncrease
            ? data[i + 1] - data[i]
            : data[i] - data[i + 1];

          if (change > maxChange) {
            maxChange = change;
            maxChangePair = [chartData.labels[i], chartData.labels[i + 1]];
            category = obj.label;
          }
        }
      }

      return {
        value: maxChange.toFixed(1),
        category,
        pair: maxChangePair,
      };
    },

    setSelectedSteps(selectedSteps: any) {
      if (selectedSteps.every((value: number | null) => value === null)) {
        this.selectedSteps = [];
      } else {
        this.selectedSteps = selectedSteps;
      }
    },
  },
});
</script>
<style lang="scss" scoped>
.process-reports-chart {
  position: relative;
  width: 100%;

  min-height: 514px;

  display: flex;
  flex-direction: column;

  background: $white;
  border-radius: 4px;
  box-shadow: 0 2px 11px 0 rgba(0, 0, 0, 0.05);
  border: 1px solid $pale-grey;

  padding: 18px 0 24px 0;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 30px;

    padding: 0 23px 22px 23px;
    border-bottom: 1px solid $pale-grey;

    @include breakpoint-down(md) {
      flex-direction: column;
      gap: 30px;
    }

    &-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      margin-top: 3px;

      line-height: 1.2;
    }

    h1 {
      margin-bottom: 5px;

      font-size: to-rem(20px);
      font-weight: 600;
      line-height: 1.5;
      letter-spacing: -0.13px;
    }

    h3 {
      padding: 0;
      margin: 0 0 10px 0;
      color: $ebony-clay;
      letter-spacing: -0.26px;
    }

    p {
      font-size: to-rem(15px);
      line-height: 1.47;
      font-weight: 400;
      color: $manatee;
      letter-spacing: -0.3px;
      padding: 0;
      margin: 0;
    }
  }

  &__content {
    margin-top: 38px;
    padding: 0 23px;
  }

  &__wrapper {
    display: grid;
    grid-template-columns: minmax(0, 73%) minmax(0, 268px);
    gap: 24px;

    @media (max-width: 1200px) {
      grid-template-columns: minmax(0, 1fr);
    }
  }

  &__wrapper--no-card {
    grid-template-columns: minmax(0, 1fr);
  }

  .insights-card {
    margin-top: 10px;
    flex: 0 0 268px;
  }

  .bar-chart {
    flex: 1;
    max-width: 100%;
  }

  .charts-table-wrapper {
    margin-top: 35px;
  }
}
</style>
