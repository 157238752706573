import { ILevelState } from "./levels-types";

/**
 * Initial state for the level module.
 */
export const state: ILevelState = {
  data: [],
  loading: false,
  error: null,
};
