import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "list-management-confirmation-dialog__title" }
const _hoisted_2 = { class: "list-management-confirmation-dialog__footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PxButton = _resolveComponent("PxButton")!
  const _component_ElDialog = _resolveComponent("ElDialog")!

  return (_openBlock(), _createBlock(_component_ElDialog, {
    ref: "modal",
    modelValue: _ctx.innerVisibility,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.innerVisibility) = $event)),
    "append-to-body": true,
    "close-on-click-modal": false,
    "close-on-press-escape": false,
    class: "list-management-confirmation-dialog",
    width: "500px",
    onClose: _ctx.closeModal
  }, {
    header: _withCtx(() => [
      _createElementVNode("h3", _hoisted_1, _toDisplayString(_ctx.dialogTitle), 1)
    ]),
    footer: _withCtx(() => [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_PxButton, _mergeProps({ ref: "cancelButton" }, _ctx.cancelButtonProps, {
          size: _ctx.$options.static.ctaSize,
          type: _ctx.$options.static.cancelCtaType,
          onClick: _ctx.closeModal
        }), null, 16, ["size", "type", "onClick"]),
        _createVNode(_component_PxButton, _mergeProps({ ref: "removeButton" }, _ctx.removeButtonProps, {
          label: _ctx.removeConfirmationButtonCopy,
          size: _ctx.$options.static.ctaSize,
          onClick: _ctx.onRemoveConfirmationClick
        }), null, 16, ["label", "size", "onClick"])
      ])
    ]),
    _: 1
  }, 8, ["modelValue", "onClose"]))
}