import { normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-f133046c"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["alt", "src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("img", {
    alt: `Icon - ${_ctx.name}`,
    src: _ctx.iconFilePath,
    style: _normalizeStyle(_ctx.iconStyle),
    class: "px-icon"
  }, null, 12, _hoisted_1))
}