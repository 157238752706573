import { resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, withCtx as _withCtx, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-78d5583a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "matching-card-options" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PxIcon = _resolveComponent("PxIcon")!
  const _component_PxButton = _resolveComponent("PxButton")!
  const _component_ElDropdownItem = _resolveComponent("ElDropdownItem")!
  const _component_ElDropdownMenu = _resolveComponent("ElDropdownMenu")!
  const _component_ElDropdown = _resolveComponent("ElDropdown")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ElDropdown, {
      class: _normalizeClass([{ 'is-open': _ctx.optionsVisible }, "matching-card-options__dropdown"]),
      trigger: "click",
      onVisibleChange: _ctx.onVisibleChange
    }, {
      dropdown: _withCtx(() => [
        _createVNode(_component_ElDropdownMenu, { class: "matching-card-options__dropdown-menu" }, {
          default: _withCtx(() => [
            _createVNode(_component_ElDropdownItem, {
              class: "matching-card-options__danger-item",
              onClick: _ctx.deleteClickHandler,
              textContent: _toDisplayString(_ctx.ctaCopy)
            }, null, 8, ["onClick", "textContent"])
          ]),
          _: 1
        })
      ]),
      default: _withCtx(() => [
        _createVNode(_component_PxButton, {
          class: _normalizeClass([{ 'is-active': _ctx.optionsVisible }, "matching-card-options__dropdown-toggler"]),
          size: "medium",
          onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["stop"]))
        }, {
          default: _withCtx(() => [
            _createVNode(_component_PxIcon, {
              size: 20,
              name: "ellipsis--ebony-clay"
            })
          ]),
          _: 1
        }, 8, ["class"])
      ]),
      _: 1
    }, 8, ["class", "onVisibleChange"])
  ]))
}