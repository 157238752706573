import { ECompanyListState } from "@/modules/company-lists/services/store/company-list/company-list.interface";
import { companyListModule } from "@/modules/company-lists/services/store/company-list/company-list.modules";
import { ECompanySharedListState } from "@/modules/company-lists/services/store/company-shared-list/company-shared-list.interface";
import { companySharedListModule } from "@/modules/company-lists/services/store/company-shared-list/company-shared-list.module";
import { listManagementModule } from "@/modules/company-lists/services/store/list-management/list-management.module";
import { listPasscodeModule } from "@/modules/company-lists/services/store/passcode/passcode.module";

// Modules to register on the Vuex instance
export const modules = {
  [ECompanyListState.NAMESPACE]: companyListModule,
  [ECompanySharedListState.NAMESPACE]: companySharedListModule,
  listManagement: listManagementModule,
  listPasscode: listPasscodeModule,
};

// Modules wish the data must persist across sessions
export const toPersist = [ECompanySharedListState.NAMESPACE, "listPasscode"];
