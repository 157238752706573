<template>
  <div v-loading="isSavingChanges" class="list-share-settings">
    <div class="list-share-settings__body">
      <ListShareSettingsLink
        :value="link"
        class="list-share-settings__body-entry"
      />
      <ListShareSettingsPasscode
        v-model:needs-reset="hasChangedPasscodeSetting"
        v-model:value="currentPasscode"
        class="list-share-settings__body-entry"
        @change="changeRestrictionHandler"
      />
    </div>
    <div class="list-share-settings__footer">
      <PxButton
        class="list-share-settings__footer-link"
        size="small"
        type="link"
        @click="onClickBackHandler"
      >
        {{ $t("common.back") }}
      </PxButton>
      <PxButton
        :disabled="!canSaveChanges || isSavingChanges"
        class="list-share-settings__footer-button"
        type="green"
        @click="onClickSaveHandler"
      >
        {{ $t("common.saveChanges") }}
      </PxButton>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import ListShareSettingsLink from "@/modules/company-lists/components/list-share/list-share-settings-link.vue";
import ListShareSettingsPasscode from "@/modules/company-lists/components/list-share/list-share-settings-passcode.vue";
import { CompanyListState } from "@/modules/company-lists/services/store/company-list/company-list.modules";

export default defineComponent({
  name: "ListShareSettings",

  components: {
    ListShareSettingsLink,
    ListShareSettingsPasscode,
  },

  props: {
    link: {
      type: String,
      required: true,
    },

    passcode: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      currentPasscode: null as null | string,
      canSaveChanges: false,
      hasPasscodeRestriction: false,
      hasChangedPasscodeSetting: false,
    };
  },

  computed: {
    isSavingChanges(): boolean {
      return this.$store.get(CompanyListState.Getter.IS_LOADING);
    },
  },

  watch: {
    passcode(newValue: string, previousValue: string) {
      if (newValue !== this.currentPasscode || !previousValue.length) {
        this.currentPasscode = newValue;
      }
    },
    hasChangedPasscodeSetting(hasChanged: boolean) {
      if (!hasChanged) {
        this.canSaveChanges = hasChanged;
      }
    },
  },

  created() {
    this.currentPasscode = this.passcode;
  },

  methods: {
    onClickBackHandler() {
      this.$emitter.emit("list-settings", false);
      this.currentPasscode = this.passcode;
      this.hasChangedPasscodeSetting = this.canSaveChanges;
    },

    onClickSaveHandler() {
      const newPasscode = this.hasPasscodeRestriction
        ? this.currentPasscode
        : null;
      this.$emit("save", newPasscode);
      this.canSaveChanges = false;
    },

    changeRestrictionHandler(isRestricted: boolean, newPasscode: string) {
      this.hasPasscodeRestriction = isRestricted;
      this.canSaveChanges =
        (isRestricted && newPasscode !== this.passcode) ||
        (!isRestricted && !!this.passcode);
    },
  },
});
</script>

<style lang="scss" scoped>
.list-share-settings {
  display: flex;
  flex-flow: column wrap;
}

.list-share-settings__body {
  flex: 1 1 auto;
  padding: 33px 32px;
}

.list-share-settings__body-entry:not(:first-child) {
  margin-top: 24px;
}

.list-share-settings__footer {
  padding: 32px;
  text-align: right;
}

.list-share-settings__footer-button {
  margin-left: 10px;
}
</style>
