<template>
  <ElDropdown
    :class="{ 'is-open': isMenuVisible }"
    class="account-dropdown"
    data-testid="account-dropdown"
    trigger="click"
    popper-class="account-dropdown-popper"
    @visible-change="onVisibleChange"
  >
    <div class="account-dropdown__toggler">
      <div class="account-dropdown__photo">
        <PxAvatar
          :loading="!company"
          :name="companyName"
          :photo="companyLogo"
          :size="30"
        />
      </div>
      <span class="account-dropdown__caption">
        <template v-if="$screen.xlUp">
          {{ companyName }}
        </template>
        <i class="account-dropdown__icon icon-arrow-triangle--up-white" />
      </span>
    </div>
    <template #dropdown>
      <ElDropdownMenu class="account-dropdown__menu">
        <ElDropdownItem
          data-testid="account-dropdown-settings-item"
          @click="goToSettings"
        >
          <i class="el-dropdown-menu__item-icon icon-cog--dark" />
          {{ $t(`common.navigation.dropdownItems.account[${userType}]`) }}
        </ElDropdownItem>
        <ElDropdownItem @click="onClickLogout">
          <i class="el-dropdown-menu__item-icon icon-move-out--dark" />
          {{ $t("common.navigation.dropdownItems.logout") }}
        </ElDropdownItem>
      </ElDropdownMenu>
    </template>
  </ElDropdown>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import { EAuthActions } from "@/modules/authentication/services/store/auth/auth-types";
import { ICompany } from "@/modules/profile/services/data/company/company.types";
import { ROUTE_ACCOUNT_SETTINGS } from "@/modules/profile/services/router/routes-names";
import { ENTREPRENEUR_USER_TYPE } from "@/modules/authentication/constants";
import { EAccountSettingsNavigationTabs } from "@/modules/profile/components/account-settings/account-settings-navigation.vue";

export default defineComponent({
  name: "AccountDropdown",

  data() {
    return {
      isMenuVisible: false,
    };
  },

  computed: {
    company(): ICompany | null {
      return this.$store.get("auth/company/data") || null;
    },

    companyLogo(): string {
      const innerCompany = this.company as ICompany | null;
      return innerCompany ? innerCompany.logo : "";
    },

    companyName(): string {
      const innerCompany = this.company as ICompany | null;
      return innerCompany ? innerCompany.name : "";
    },

    userType(): number {
      return this.$user.getUserAccountType();
    },
  },

  methods: {
    onClickLogout() {
      this.$store.dispatch(EAuthActions.LOGOUT);
    },

    onClickOpenModal(modal: string) {
      this.$emit("open-modal", modal);
    },

    onVisibleChange(state: boolean) {
      this.isMenuVisible = state;
    },

    goToSettings() {
      !this.$features.isEnabled("settingsPage") ||
      this.userType === ENTREPRENEUR_USER_TYPE
        ? this.onClickOpenModal("accountSettings")
        : this.$route.name !== ROUTE_ACCOUNT_SETTINGS &&
          this.$router.push({
            name: ROUTE_ACCOUNT_SETTINGS,
            query: {
              tab: EAccountSettingsNavigationTabs.INFO,
            },
          });
    },
  },
});
</script>

<style lang="scss" scoped>
.account-dropdown {
  position: relative;
}

.account-dropdown__toggler {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  padding: 10px 0;

  cursor: pointer;
  user-select: none;
  outline: none;
}

.account-dropdown__photo {
  position: relative;
  width: 30px;
  height: 30px;
  overflow: hidden;
  border-radius: 2px;

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    content: "";
    border: solid 1px rgba(white, 0.3);
  }
}

.account-dropdown__caption {
  @include grotesk(semiBold);

  font-size: to-rem(17px);
  line-height: 20px;
  color: #fff;
  letter-spacing: -0.1px;
}

.account-dropdown__photo + .account-dropdown__caption {
  margin-left: 11px;
}

.account-dropdown__icon {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-left: 6px;

  background-repeat: no-repeat;
  transform: rotate(180deg);

  .is-open & {
    transform: none;
  }
}

.account-dropdown__menu {
  margin-top: 5px;

  &::after {
    top: 1px;
    right: 9px;
  }

  &::before {
    top: 0;
    right: 8px;
  }
}
</style>
<style lang="scss">
.account-dropdown-popper {
  inset: 67px 20px auto auto !important;

  @media (min-width: 1140px) {
    right: calc((100vmax - 1140px) / 2 - 23px) !important;
  }
}
</style>
