<template>
  <div class="supporters-base page-full-height">
    <PxNavbarWizard
      v-if="isNavbarWizard"
      :loading="isViewLoading"
      :logo="affiliateLogo"
      :steps="enabledNavBarSteps"
    />
    <PxNavbar
      v-else-if="isAffiliateFlow"
      :logo="affiliateLogo"
      :show-links="false"
    />
    <PxNavbarMinimal v-else />
    <PxGlobalError
      v-if="hasError"
      :auto-refresh="true"
      :loading="isViewLoading"
      :use-action="true"
      class="page-body is-center"
      @do-action="fetchSupporterTypes"
    />
    <div
      v-if="!hasError"
      v-loading="isViewLoading"
      :class="{ 'is-loading': isViewLoading }"
      class="page-body supporters-base__container"
      element-loading-background="#fafafa"
    >
      <RouterView
        v-show="!isViewLoading"
        :key="$i18n.locale"
        :flow-data="flowData"
        :form-loading="isFormLoading"
        :has-previous-page="!!previousPage"
        :skip-enabled="isShowSkipButton"
        :view-loading="isViewLoading"
        @next-page="nextPageNavigationGuard"
        @previous-page="previousPageNavigationGuard"
        @skip-all="toggleSkipCriteriaModal"
        @skip-page="skipPageNavigationGuard"
      />
    </div>
    <PxFooter v-if="showFooter" :class="extraFooterClasses" />
    <ActionModal
      v-if="showSkipCriteriaModal && !isViewLoading"
      ref="skipCriteriaModal"
      :title="$t('supporters.component.skipCriteriaModal.title')"
      :visibility="showSkipCriteriaModal"
    >
      <template #content>
        <p>
          {{ $t("supporters.component.skipCriteriaModal.description") }}
        </p>
      </template>
      <template #actions>
        <PxButton class="el-button--link" type="link" @click="onFlowFinish">
          {{ $t("supporters.component.skipCriteriaModal.secondaryCta") }}
        </PxButton>
        <PxButton type="primary" @click="onSetupCriteriaClick">
          {{ $t("supporters.component.skipCriteriaModal.mainCta") }}
        </PxButton>
      </template>
    </ActionModal>
    <template v-if="!isViewLoading">
      <UserDetailsModal
        v-if="showRegistrationForm"
        :visibility="showUserModals"
        @sign-in="updateSignInModalVisibility"
        @submit="onUserRegister"
      />
      <SignInModal
        v-else
        :has-invalid-account="invalidAccount"
        :visibility="showUserModals"
        @register="updateSignInModalVisibility"
        @submit="prefillRegisteredSupporterData"
      />
    </template>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import Bugsnag from "@bugsnag/js";
import { RouteRecord } from "vue-router";

import ActionModal from "@/modules/supporters/components/action-modal/action-modal.vue";
import SignInModal from "@/modules/supporters/components/sign-in-modal/sign-in-modal.vue";
import UserDetailsModal from "@/modules/supporters/components/user-details-modal/user-details-modal.vue";

import { INavbarStep } from "@/components/px-navbar-wizard/px-navbar-wizard.interface";
import { ESupporterInvestingLevelGetters } from "@/modules/supporters/services/store/supporter-investing-level/supporter-investing-level-types";
import { ROUTE_PROFILE } from "@/modules/profile/services/router/routes-names";
import {
  SUPPORTERS_CRITERIA_ASSESSMENT_PROGRAM,
  SUPPORTERS_CRITERIA_STARTING_POINT,
  SUPPORTERS_STARTING_POINT,
  SUPPORTERS_STARTING_POINT_PROGRAM,
  SUPPORTERS_CRITERIA_QUESTIONS_PROGRAM,
  SUPPORTERS_CRITERIA_BASIC_INTERESTS_PROGRAM,
  SUPPORTERS_CRITERIA_ADDITIONAL_INTERESTS_PROGRAM,
  SUPPORTERS_TEAM_MANAGEMENT_PROGRAM,
} from "@/modules/supporters/services/router/routes-names";
import { SupporterTypeState } from "@/modules/supporters/services/store/supporter-type/supporter-type.module";

import { SupporterFlowState } from "@/modules/supporters/services/store/supporter-flow/supporter-flow.module";
import { ESupporterFlowAction } from "@/modules/supporters/services/store/supporter-flow/supporter-flow.interface";
import {
  ISupporterFlow,
  ESupporterFlowType,
  ISupporterFlowImportances,
} from "@/modules/supporters/services/data/supporter-flow/supporter-flow.interface";
import { SUPPORTER_USER_TYPE } from "@/modules/authentication/constants";
import {
  EAffiliateAction,
  EAffiliateGetter,
} from "@/modules/supporters/services/store/affiliate/affiliate.interface";
import { EAdditionalCriteriaGetter } from "@/modules/supporters/services/store/additional-criteria/additional-criteria.interface";
import { AdditionalCriteriaState } from "@/modules/supporters/services/store/additional-criteria/additional-criteria.module";
import { ErrorsManager } from "@/services/errors-manager";
import { ErrorsProviderException } from "@/services/data/exceptions/errors-provider.exception";
import { MatchingCriteriaWeightState } from "@/modules/matching/services/store/matching-criteria-weight/matching-criteria-weight.module";
import { ERROR_ROUTE } from "@/services/router/router-names";
import { IAffiliate } from "@/services/data/affiliate/affiliate.interface";
import { AffiliateState } from "@/modules/supporters/services/store/affiliate/affiliate.module";
import { ISupporter } from "@/modules/matching/services/data/matching-score/supporter.interface";
import {
  formatMultiSectorsSelection,
  formatSupporterSectors,
} from "@/components/multi-selector/multi-selector-sectors.provider";
import {
  formatMultiLocationsSelection,
  formatSupporterLocations,
} from "@/components/multi-selector/multi-selector-locations.providers";
import { ICompany } from "@/modules/profile/services/data/company/company.types";
import { IMatchingCriteria } from "@/modules/matching/services/data/matching-score/matching-criteria.interface";
import { IMatchingQuestion } from "@/services/data/matching-questionary/matching-question.interface";
import { EAuthActions } from "@/modules/authentication/services/store/auth/auth-types";
import { IMatchingQuestionaryCriteria } from "@/services/data/matching-questionary-criteria/matching-questionary-criteria.interface";
import { INetwork } from "@/services/data/network/network.interface";
import { userGuidingTrack } from "@/services/utils/userguiding.utils";
import {
  helpScoutBeaconInit,
  helpScoutBeaconDestroy,
} from "@/services/utils/helpscout";
import { changeLocale, loadedLocales } from "@/services/i18n/locale-utils";
import { EViralLevelActions } from "@/services/store/viral-level/viral-level-types";
import { ECategoryActions } from "@/services/store/category/category-types";
import { ENTREPRENEUR_USER_GROUP_ID } from "@/modules/common/constants";
import { ELevelActions } from "@/services/store/levels/levels-types";
import { AxiosError } from "axios";
import { ERROR_TYPES } from "@/modules/common/components/error-page/constants";
import { ETeamMembersActions } from "@/modules/team-management/services/store/team-members/team-members.types";
import { ITeamMember } from "@/modules/team-management/services/data/team-members/team-members.interface";
import { IMatchingResponse } from "@/services/data/matching-responses/matching-response.interface";
import { h } from "vue";

export default defineComponent({
  components: {
    ActionModal,
    SignInModal,
    UserDetailsModal,
  },

  data() {
    return {
      invalidAccount: false,
      showSkipCriteriaModal: false,
      showRegistrationForm: true,
    };
  },

  computed: {
    /**
     * Check if the footer must be shown for the current route.
     */
    showFooter(): boolean {
      const routeMeta = this.$route.meta;
      return !!routeMeta.showFooter;
    },

    hasError(): boolean {
      return (
        this.$store.state.categories.error ||
        this.$store.state.levels.error ||
        this.$store.getters[SupporterTypeState.Getter.HAS_ERROR]
      );
    },

    isViewLoading(): boolean {
      return (
        this.$store.state.categories.loading ||
        this.$store.state.levels.loading ||
        this.$store.getters[ESupporterInvestingLevelGetters.IS_LOADING] ||
        this.$store.getters[SupporterTypeState.Getter.IS_LOADING] ||
        this.$store.getters[EAffiliateGetter.IS_LOADING] ||
        this.$store.getters[EAdditionalCriteriaGetter.IS_LOADING] ||
        this.$store.state.auth.loading
      );
    },

    hasFormError(): boolean {
      return this.$store.getters[SupporterFlowState.Getter.HAS_ERROR];
    },

    isFormLoading(): boolean {
      return this.$store.getters[SupporterFlowState.Getter.IS_LOADING];
    },

    isShowSkipButton(): boolean {
      return this.$features.isEnabled("signUpSupporterSkipEnabled");
    },

    extraFooterClasses(): string {
      return this.$store.getters[
        ESupporterInvestingLevelGetters.SINGLE_METADATA
      ]("footerClass") as string;
    },

    previousPage(): string | null {
      const previousPage = (this.$route.meta.previousPage as string) || null;

      if (Array.isArray(previousPage)) {
        return this.showTeamManagementSection
          ? previousPage[0]
          : previousPage[1];
      }

      return previousPage;
    },

    nextPage(): string | null {
      const nextPage = (this.$route.meta.nextPage as string) || null;

      if (Array.isArray(nextPage)) {
        return this.showTeamManagementSection ? nextPage[0] : nextPage[1];
      }

      return nextPage;
    },

    isNavbarWizard(): boolean {
      return this.$route.matched.some(
        (route: RouteRecord) => !!route.meta.isNavbarWizard,
      );
    },

    navBarSteps(): Array<INavbarStep> {
      return [
        {
          routes: [SUPPORTERS_CRITERIA_ASSESSMENT_PROGRAM],
          labelKey: "supporters.cta.ventureInvestmentLevel",
          enabled: true,
        },
        {
          routes: [SUPPORTERS_CRITERIA_QUESTIONS_PROGRAM],
          labelKey: "supporters.cta.questions",
          enabled: true,
        },
        {
          routes: [SUPPORTERS_TEAM_MANAGEMENT_PROGRAM],
          labelKey: "supporters.cta.teamManagement",
          enabled: this.showTeamManagementSection,
        },
        {
          routes: [
            SUPPORTERS_CRITERIA_BASIC_INTERESTS_PROGRAM,
            SUPPORTERS_CRITERIA_ADDITIONAL_INTERESTS_PROGRAM,
          ],
          labelKey: "supporters.cta.interests",
          enabled: true,
        },
      ];
    },

    navBarStepsToDisplay(): Array<INavbarStep> {
      const matchRoute = this.$route.matched.find(
        (route: RouteRecord) => !!route.meta.isNavbarWizard,
      );

      return !matchRoute
        ? []
        : this.navBarSteps.map(
            (step: INavbarStep) =>
              ({
                ...step,
                isCompleted: step.routes.every((route: string) =>
                  this.submittedPages.some((page: string) => page === route),
                ),
              }) as INavbarStep,
          );
    },

    enabledNavBarSteps(): Array<INavbarStep> {
      return this.navBarStepsToDisplay.filter(
        (step: INavbarStep) => step.enabled,
      );
    },

    flowData(): ISupporterFlow {
      return this.$store.getters[SupporterFlowState.Getter.VALUE];
    },

    submittedPages(): Array<string> {
      return this.flowData?.meta?.submittedPages || [];
    },

    skippedPages(): Array<string> {
      return this.flowData?.meta?.skippedPages || [];
    },

    affiliateSlug(): string {
      return (this.$route.query?.a || this.$route.query?.affiliate || "")
        .toString()
        .toLowerCase();
    },

    affiliate(): IAffiliate {
      return this.$store.getters[AffiliateState.Getter.VALUE];
    },

    affiliateLogo(): string | null {
      return this.affiliate && this.affiliate.logo && this.isAffiliateFlow
        ? this.affiliate.logo
        : null;
    },

    // This affects which supporter flow is shown, based on the route specified

    // TODO: have a broader approach in the router navigation guard so that it can allow a route to be accessible based on the affiliate type and target user
    isAffiliateFlow(): boolean {
      return this.$route.fullPath.includes("program");
    },

    isDefaultAffiliate(): boolean {
      return !this.affiliate || this.affiliate.default_flow;
    },

    startingPointRoute(): string {
      return this.isAffiliateFlow
        ? SUPPORTERS_STARTING_POINT_PROGRAM
        : SUPPORTERS_STARTING_POINT;
    },

    supporter(): ISupporter | null {
      return this.$store.get("auth/supporter.data");
    },

    company(): ICompany | null {
      return this.$store.get("auth/company.data");
    },

    isUserRegistered(): boolean {
      return !!this.flowData?.token || !!this.supporter;
    },

    showUserModals(): boolean {
      return (
        this.isAffiliateFlow &&
        this.$route.name === SUPPORTERS_CRITERIA_ASSESSMENT_PROGRAM &&
        !this.isUserRegistered
      );
    },

    questions(): Array<IMatchingQuestion> {
      return this.$store.getters[EAffiliateGetter.QUESTION_BUNDLE].filter(
        (question: IMatchingQuestion) => !question.is_team_member_question,
      );
    },

    showTeamManagementSection(): boolean {
      return (
        (this.affiliate?.show_team_section &&
          this.$features.isEnabled("teamMembers")) ||
        false
      );
    },
  },

  watch: {
    /**
     * Prevent Supporters from accessing the default Affiliate
     * on the program flow route and instead redirect them to the signup flow;
     */
    affiliate(hasValue) {
      if (!hasValue) {
        return;
      }

      if (
        this.$route.name === SUPPORTERS_STARTING_POINT_PROGRAM &&
        this.isDefaultAffiliate
      ) {
        this.$router.replace({ name: SUPPORTERS_STARTING_POINT });
      }
    },
  },

  async created() {
    // TODO: Remove this after creating mobile version for affiliate and registration flow
    if (this.$screen.mdDown) {
      this.$router.replace({
        name: ERROR_ROUTE,
        query: {
          code: ERROR_TYPES.MOBILE,
        },
      });

      return;
    }

    // Set guest supporter type
    this.$user.setGuestUserType(SUPPORTER_USER_TYPE);

    // Verify and prefill (or reset) user flow data
    this.setupUserData();

    // Setup necessary data for the Affiliate flow
    this.fetchRequiredData();

    // TODO: To time being this will remain commented. We still need to figure out how to fetch a specific first route based on flow type stored, and redirect if needed.
    // Understand if the user is on the right route
    // this.validateFlowType();

    // Listen for navigation of loose pages (e.g. Results)
    this.$emitter.on("next-page", this.nextPageNavigationGuard);

    // Listen for locale change
    this.$emitter.on("locale-changed", this.fetchNecessaryAffiliateData);

    // Load HelpScout chat widget for Supporters
    helpScoutBeaconInit({
      userType: SUPPORTER_USER_TYPE,
    });
  },

  async unmounted() {
    // Remove HelpScout chat widget
    helpScoutBeaconDestroy();

    // We're reverting the locale back to English as we're only supposed
    // to provide translations in assessment flow pages for the time being.
    await changeLocale("en", loadedLocales, document);

    // Fetch backend translations
    this.$store.dispatch(EViralLevelActions.COMPUTE_LEVEL, {
      skipLoading: true,
    });
    this.$store.dispatch(ECategoryActions.FETCH, {
      group: ENTREPRENEUR_USER_GROUP_ID,
    });
    this.$store.dispatch(ELevelActions.FETCH, {
      group: ENTREPRENEUR_USER_GROUP_ID,
    });
  },

  methods: {
    async fetchRequiredData() {
      let requestError: any = null;
      // Check firstly if we are in the correct flow by fetching the affiliate using query params
      await this.handleQueryParameters().catch((error) => {
        requestError = error;
      });

      if (requestError) {
        this.notifyRequestError(requestError);
        return;
      }

      // Handle query parameters and fetch UI required data
      await Promise.all([
        this.fetchSupporterTypes(),
        this.fetchSupporterAdditionalCriteria(),
        this.fetchMatchingCriteriaWeights(),
      ]).catch((error) => {
        requestError = error;
      });

      if (requestError) {
        this.notifyRequestError(requestError);
      }
    },

    notifyRequestError(error: AxiosError) {
      const errorStatus = error.response?.status;

      if (errorStatus?.toString() === ERROR_TYPES.FORBIDDEN) {
        return this.$router.replace({
          name: ERROR_ROUTE,
          query: {
            code: ERROR_TYPES.FORBIDDEN,
          },
        });
      }

      // Send notification
      Bugsnag.notify("Yikes! Missing crucial Supporter data.", (event) => {
        event.addMetadata("context", error);
      });

      this.$router.replace({
        name: ERROR_ROUTE,
        query: {
          code: ERROR_TYPES.NOT_FOUND,
        },
      });
    },

    userGuidingTrackStart() {
      const userType = this.$user.getUserAccountType();
      const type = this.$t(`common.userTypes[${userType}]`);
      userGuidingTrack("segment", {
        user_type: type,
        affiliate_slug: this.affiliateSlug,
      });
    },

    async handleQueryParameters() {
      // If starting point route, check for affiliate query params
      if (this.$route.name === SUPPORTERS_STARTING_POINT_PROGRAM) {
        await this.fetchSupporterAffiliate();
      } else if (this.$route.name === SUPPORTERS_STARTING_POINT) {
        await this.fetchSupporterDefaultAffiliate();
      }
    },

    async fetchNecessaryAffiliateData() {
      if (this.$route.name === SUPPORTERS_STARTING_POINT_PROGRAM) {
        await this.fetchSupporterAffiliate();
      } else if (this.$route.name === SUPPORTERS_STARTING_POINT) {
        await this.fetchSupporterDefaultAffiliate();
      }

      this.fetchSupporterAdditionalCriteria();
    },

    async fetchSupporterAffiliate() {
      if (!this.affiliateSlug) {
        // Redirect to registration flow
        this.$router.replace({ name: SUPPORTERS_STARTING_POINT });
        return;
      }

      // Notify 3rd party services
      this.userGuidingTrackStart();

      // Affiliate identifier, either id or slug
      await this.$store.dispatch(
        EAffiliateAction.SUPPORTER_FETCH,
        this.affiliateSlug,
      );

      // Store affiliate id for later submittion purposes
      await this.$store.dispatch(ESupporterFlowAction.SET, {
        ...this.flowData,
        affiliate: this.affiliate.id,
      });
    },

    async fetchSupporterTypes() {
      await this.$store.dispatch(SupporterTypeState.Action.GET_VALUES);
    },

    async fetchSupporterDefaultAffiliate() {
      await this.$store.dispatch(EAffiliateAction.SUPPORTER_FETCH_DEFAULT);
    },

    async fetchSupporterAdditionalCriteria() {
      await this.$store.dispatch(AdditionalCriteriaState.Action.GET_VALUES);
    },

    async fetchMatchingCriteriaWeights() {
      await this.$store.dispatch(MatchingCriteriaWeightState.Action.GET_VALUES);
    },

    async fetchAuthSupporter() {
      await this.$store.dispatch(EAuthActions.FETCH_SUPPORTER);
    },

    async fetchAuthCompany() {
      await this.$store.dispatch(EAuthActions.FETCH_COMPANY);
    },

    async setupUserData() {
      if (this.flowData.options.submitted || !this.flowData.options.started) {
        // Reset and start new flow
        await this.$store.dispatch(ESupporterFlowAction.RESET);

        // Redirect if not already on starting page
        if (this.$route.name !== this.startingPointRoute) {
          this.$router.replace({
            name: this.startingPointRoute,
          });
        }

        // If logged user, load existing user data
        if (this.$user.isLogged()) {
          await Promise.all([
            this.fetchAuthSupporter(),
            this.fetchAuthCompany(),
          ]).catch((error) => {
            if (error) {
              this.notifyRequestError(error);
            }
          });

          await this.prefillRegisteredSupporterData();
        }
      }
    },

    validateFlowType() {
      // No type still defined, carry on
      if (!this.flowData.options.type) {
        return;
      }

      const onRegistrationFlow =
        this.flowData.options.type === ESupporterFlowType.REGISTRATION &&
        !this.isAffiliateFlow;
      const onAffiliateFlow =
        this.flowData.options.type === ESupporterFlowType.AFFILIATE_PROGRAM &&
        this.isAffiliateFlow;

      if (!onRegistrationFlow && !onAffiliateFlow) {
        this.$router.replace({ name: this.startingPointRoute });
      }
    },

    /**
     * Check if exists a previous page and navigate to it
     */
    previousPageNavigationGuard() {
      if (this.previousPage !== null) {
        this.$router.push({ name: this.previousPage });
      }
    },

    async skipPageNavigationGuard() {
      // Just type override, page names are enforced currently
      const currentPageName = this.$route.name || "";
      const meta = { ...this.flowData.meta };

      // Enforce unique skipped pages with current page
      meta.skippedPages = [...new Set([...this.skippedPages, currentPageName])];

      // Remove from submitted pages
      meta.submittedPages = [
        ...this.submittedPages.filter(
          (page: string) => page !== currentPageName,
        ),
      ];

      // Store page update
      await this.$store.dispatch(ESupporterFlowAction.SET, { meta });

      if (this.nextPage !== null) {
        this.$router.push({ name: this.nextPage });
      }
    },

    /**
     * Toggles skip criteria modal visibility
     */
    toggleSkipCriteriaModal() {
      this.showSkipCriteriaModal = !this.showSkipCriteriaModal;
    },

    /**
     * Handles setup criteria main cta click.
     *
     * @fields updated form fields
     */
    onSetupCriteriaClick(fields: ISupporterFlow) {
      // Toggle modal
      this.toggleSkipCriteriaModal();

      // If is criteria starting point, go to next page
      if (this.$route.name === SUPPORTERS_CRITERIA_STARTING_POINT) {
        this.nextPageNavigationGuard(fields);
      }
    },

    /**
     * Check if exists a next page and navigate to it.
     *
     * @fields updated form fields
     */
    async nextPageNavigationGuard(
      fields: ISupporterFlow,
      errors?: ErrorsManager,
    ) {
      // Just type override, page names are enforced currently
      const currentPageName = this.$route.name || "";
      const meta = { ...this.flowData.meta, ...(fields?.meta || {}) };

      // Enforce unique submitted pages with current page
      meta.submittedPages = [
        ...new Set([...this.submittedPages, currentPageName]),
      ];

      // Remove from skipped pages
      meta.skippedPages = [
        ...this.skippedPages.filter((page: string) => page !== currentPageName),
      ];

      // If the flow type is still not defined, do it here
      if (!this.flowData.options.type) {
        await this.$store.dispatch(ESupporterFlowAction.SET, {
          ...this.flowData,
          options: {
            ...this.flowData.options,
            type: this.isAffiliateFlow
              ? ESupporterFlowType.AFFILIATE_PROGRAM
              : ESupporterFlowType.REGISTRATION,
          },
        });
      }

      if (fields) {
        if (fields.team_members) {
          const teamMembers = await this.$store.dispatch(
            ETeamMembersActions.CREATE_VALUES,
            fields.team_members,
          );

          fields.team_members = teamMembers.map((teamMember: ITeamMember) => ({
            ...teamMember,
            responses: (teamMember.responses as Array<IMatchingResponse>).map(
              ({ id }) => id,
            ),
          }));
        }

        if (this.$user.isLogged()) {
          await this.$store.dispatch(ESupporterFlowAction.SET, {
            ...this.flowData,
            ...fields,
            affiliate: this.affiliate.id,
            options: {
              ...this.flowData.options,
              started: true,
            },
            meta,
          });
        } else {
          if (currentPageName !== SUPPORTERS_TEAM_MANAGEMENT_PROGRAM) {
            await this.onPendingUserSubmit({ ...fields, meta }, errors);
          }
        }
      }

      const isLoggedUserSubmitPage =
        this.$user.isLogged() && this.$route.meta?.isExistingUserSubmitPage;

      if (isLoggedUserSubmitPage) {
        await this.onLoggedUserSubmission();
      }

      // If error found, prevent from adding page to submitted and go to next page
      if (this.hasFormError) {
        return;
      }

      if (this.nextPage !== null) {
        this.$router.push({ name: this.nextPage });
      } else {
        // If reached here, is final page
        // Say bye bye
        await this.onFlowFinish();
      }
    },

    /**
     * Triggers API to submit pending user updated data.
     *
     * @fields updated form fields
     * @errors
     */
    async onPendingUserSubmit(
      fields: Partial<ISupporterFlow>,
      errors?: ErrorsManager,
    ) {
      // Double check if fields exist
      if (fields) {
        const hasToken = !!this.flowData?.token;
        const hasValidEmail = !!fields?.email;
        const hasSameEmail =
          hasValidEmail && this.flowData.email === fields.email;

        // Refresh error manager
        errors?.clear();

        // If has same email, it isn't required to update store
        if (hasSameEmail) {
          return;
        }

        try {
          if (hasToken) {
            await this.$store.dispatch(
              ESupporterFlowAction.UPDATE_PENDING_SUPPORTER,
              fields,
            );
          } else if (hasValidEmail) {
            const payload = this.isAffiliateFlow
              ? { email: fields.email, affiliate: this.affiliate.id }
              : { email: fields.email };
            await this.$store.dispatch(
              ESupporterFlowAction.CREATE_PENDING_SUPPORTER,
              payload,
            );
          }
        } catch (error) {
          if (error instanceof ErrorsProviderException) {
            errors?.record(error.response.data.errors);

            if (!error.response?.data?.errors?.email) {
              // Only show a generic error message to avoid user frustration
              this.$message({
                message: this.$root?.$t(
                  "common.errors.global.alertTitle",
                ) as string,
                type: "error",
                duration: 10000,
                customClass: "is-full",
              });
            }

            // If token has error, must be invalid thus registration should be reset
            if (error.response?.data?.errors?.token) {
              await this.setupUserData();
            }
          }
        }
      }
    },

    /**
     * Trigger flow submission.
     */
    async onFlowFinish() {
      // Dispatch finish to clear pending register data
      await this.$store.dispatch(ESupporterFlowAction.SUBMIT_PENDING_SUPPORTER);

      // Navigates to profile
      this.$router.replace({ name: ROUTE_PROFILE });
    },

    /**
     * Trigger existing supporter submission submit.
     */
    async onLoggedUserSubmission() {
      // Dispatch finish to clear pending register data
      await this.$store.dispatch(
        ESupporterFlowAction.SUBMIT_EXISTING_SUPPORTER,
      );
    },

    updateSignInModalVisibility() {
      this.showRegistrationForm = !this.showRegistrationForm;
    },

    /**
     * Get the supporter & company initial payload needed to be submitted:
     * a) Together, for default Affiliates
     * in order to create an active Supporter.
     *
     * b) Sequentially, for custom Affiliates
     * in order to create a inactive Supporter
     * because visitors can submit a program without completing registration.
     */
    getSupporterRegistrationPayloads(
      supporter: ISupporterFlow["supporter"],
      company: ISupporterFlow["company"],
    ): Array<Partial<ISupporterFlow>> {
      const payload = {
        token: this.flowData.token,
        meta: { ...this.flowData.meta },
      };
      const supporterPayload = {
        supporter: {
          ...this.flowData.supporter,
          ...supporter,
        },
      };
      const companyPayload = {
        company: {
          ...this.flowData.company,
          ...company,
        },
      };
      return this.isDefaultAffiliate
        ? [{ ...payload, ...supporterPayload, ...companyPayload }]
        : [
            { ...payload, ...supporterPayload },
            { ...payload, ...companyPayload },
          ];
    },

    /**
     * Handler for when the supporter submits the initial registration, where:
     * 1 - First, the email is submitted to begin the program;
     * 2 - Secondly, the supporter and company data is submitted
     * accordingly with the needed payload(s) for the appropriate Affiliate;
     */
    async onUserRegister(fields: ISupporterFlow, errors: ErrorsManager) {
      // 1. Get registration data
      const { email, company, supporter } = fields;

      // 2. Use first submission to create a token
      await this.onPendingUserSubmit({ email }, errors);

      // If error found, prevent from sending next batch
      if (this.hasFormError) {
        return;
      }

      // 3. After the initial request to obtain the flow token, we will send the supporter & company data:
      const registrationPayloads = this.getSupporterRegistrationPayloads(
        supporter,
        company,
      );
      for (let i = 0; i < registrationPayloads.length; i++) {
        await this.onPendingUserSubmit(registrationPayloads[i], errors);
        if (this.hasFormError) break;
      }
    },

    async prefillRegisteredSupporterData() {
      if (!this.supporter || !this.company || !!this.flowData?.token) {
        this.invalidAccount = true;
        return;
      }

      this.notifyInfoPrefill();

      const locationMultiSelectionArray = formatSupporterLocations(
        this.supporter,
      );
      const sectorMultiSelectionArray = formatSupporterSectors(this.supporter);

      // TODO: Rework Matching Criteria and Matching Response to avoid misconfusion
      // At the moment and due to tight schedules, we're opting for not working on
      // creating a new standard for Matching Criteria and Matching Response.
      //
      // Supporters don't have own Responses but Criteria, but to allow the maximum
      // compatibility with the components made for Entrepreneurs, we've advanced
      // with using the type IMatchingResponse as a typing for Supporter criteria.
      // This is incorrect, as the types have an similar base but different variable
      // names.
      //
      // Some points for consideration:
      // 1) Criteria values are obtained directly from ISupporter with IMatchingCriteria typing;
      // 2) Supporter flow data payload is expecting IMatchingResponse format for criteria;
      // 3) IMatchingCriteria have two types - one with prop instance references, another with prop id references;
      // 4) IMatchingCriteria desired is the same as IMatchingResponse value;
      // 5) IMatchingCriteria answers is a IMatchingAnswer instance, IMatchingResponse answers is an array of answer ids;
      // 6) IMatchingCriteria question is a IMatchingQuestion instance, IMatchingResponse question is usually represented by id;
      //
      const criteria = this.supporter.criteria
        .filter(
          (criterion: IMatchingCriteria) =>
            this.questions.find(
              (question: IMatchingQuestion) =>
                question.id === criterion.question.id,
            ) || null,
        )
        .map((criterion: any) => {
          return criterion.answers?.length
            ? {
                question: criterion.question.id,
                answers: criterion.answers.map((answer: any) => answer.id),
              }
            : {
                question: criterion.question.id,
                value: { ...criterion.desired },
              };
        });

      const additional_criteria = this.supporter.criteria
        .filter(
          (criterion: IMatchingCriteria) =>
            this.questions.every(
              (question: IMatchingQuestion) =>
                question.id !== criterion.question.id,
            ) && !!criterion.question?.short_name,
        )
        .map((criterion: any) => {
          return criterion.answers?.length
            ? {
                question: criterion.question.id,
                answers: criterion.answers.map((answer: any) => answer.id),
              }
            : {
                question: criterion.question.id,
                value: { ...criterion.desired },
              };
        });

      const mappedSupporterData = {
        ...this.flowData,
        affiliate: this.affiliate.id,
        company: {
          name: this.company.name,
          location: this.company.location,
          networks: this.company.networks.map(
            (network: INetwork) => network.id,
          ),
        },
        criteria,
        additional_criteria,
        supporter: {
          investing_level_range: this.supporter.investing_level_range,

          // The locations and sectors need to be parsed
          // from ISupporter["sectors"]/["locations"] instances to IMultiSelection,
          // and finally to the payload format: IMultiSectorsPayload/IMultiLocationsPayload
          //
          // The reason for this unconventional approach is due to a
          // accidental usage of the ISupporter on this method for submitting data
          // which led to a mismatch of schema between the ISupporter and ISupporterFlowSupporter.
          //
          // TODO: Discuss how the data schema should be handled at the store, component and payload level.
          ...formatMultiLocationsSelection(locationMultiSelectionArray),
          ...formatMultiSectorsSelection(sectorMultiSelectionArray),
        },

        importances: {
          level_weight: this.supporter.level_weight,
          locations_weight: this.supporter.locations_weight,
          sectors_weight: this.supporter.sectors_weight,
          questions: this.supporter.criteria.map(
            (criterion: IMatchingCriteria) =>
              ({
                question: criterion.question.id,
                criteria_weight: criterion.criteria_weight,
              }) as IMatchingQuestionaryCriteria,
          ),
        } as ISupporterFlowImportances,

        meta: {
          ...this.flowData.meta,
          locations: locationMultiSelectionArray,
          sectors: sectorMultiSelectionArray,
        },
      } as ISupporterFlow;

      await this.$store.dispatch(ESupporterFlowAction.SET, mappedSupporterData);
    },

    notifyInfoPrefill() {
      const text = this.$root?.$t(
        "supporters.component.prefillToastMessage.text",
      ) as string;
      const highlight = this.$root?.$t(
        "supporters.component.prefillToastMessage.highlight",
      ) as string;

      const vNode = h(
        "p",
        { class: ["el-message__content", "el-message__content--small"] },
        [h("span", {}, text), h("b", {}, highlight)],
      );

      this.$message({
        message: vNode,
        type: "info",
        customClass: "is-navbar",
        duration: 10000,
      });
    },
  },
});
</script>

<style lang="scss" scoped>
.supporters-base__wizard {
  padding: 63px 0 0;
}

.supporters-base__container {
  position: relative;
}

.supporters-base__container.is-loading {
  @include breakpoint-down(sm) {
    max-height: calc(100vh - 54px);
    overflow: hidden;
  }

  :deep() .starting-point {
    opacity: 0;
  }
}

.supporters-base :deep() .px-global-error.is-no-time {
  transform: none;
}

.networks-panel__list {
  width: fit-content;
  padding: 9px 17px;
  margin: 42px auto 38px;
  background: $alabaster;
  border: solid 1px rgba(139, 143, 161, 0.28);
}

.el-message__content {
  span,
  b {
    line-height: 14px;
  }

  b {
    margin-left: 3px;
  }
}
</style>
