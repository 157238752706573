import { Module } from "vuex";
import { make } from "vuex-pathify";

import { RootState } from "@/services/store/root-state";

import { IAuthMilestonesState } from "./auth-milestones.types";
import { actions } from "./auth-milestones.actions";

const state: IAuthMilestonesState = {
  loading: false,
  error: null,
  data: [],
  parsedData: [],
};

export const authMilestones: Module<IAuthMilestonesState, RootState> = {
  namespaced: true,
  state,
  actions,
  mutations: make.mutations(state),
  getters: make.getters(state),
};
