import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ContentPlaceholdersText = _resolveComponent("ContentPlaceholdersText")!
  const _component_ContentPlaceholders = _resolveComponent("ContentPlaceholders")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([{
      'submissions-table-row-placeholder__owner-submissions-view': !_ctx.userView,
    }, "submissions-table-row-placeholder"])
  }, [
    _createVNode(_component_ContentPlaceholders, { rounded: true }, {
      default: _withCtx(() => [
        _createVNode(_component_ContentPlaceholdersText, { lines: 1 })
      ]),
      _: 1
    }),
    _createVNode(_component_ContentPlaceholders, {
      rounded: true,
      class: "is-circle"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_ContentPlaceholdersText, { lines: 1 }),
        _createVNode(_component_ContentPlaceholdersText, { lines: 1 })
      ]),
      _: 1
    }),
    _createVNode(_component_ContentPlaceholders, { rounded: true }, {
      default: _withCtx(() => [
        _createVNode(_component_ContentPlaceholdersText, {
          lines: 1,
          class: "is-pill"
        })
      ]),
      _: 1
    }),
    _createVNode(_component_ContentPlaceholders, {
      rounded: true,
      class: "is-row"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_ContentPlaceholdersText, { lines: 1 }),
        _createVNode(_component_ContentPlaceholdersText, { lines: 1 })
      ]),
      _: 1
    })
  ], 2))
}