import { GetterTree } from "vuex";
import { IMetaState, EMetaGetters } from "./meta-types";
import { RootState } from "../root-state";
import { getBaseName } from "../utils/get-base-name";

/**
 * Getters for the metadata module.
 */
export const getters: GetterTree<IMetaState, RootState> = {
  /**
   * Get an entry on the metadata module.
   *
   * This returns `undefined` when there is no
   * entry for the given key.
   *
   * @param state Module state
   */
  [getBaseName(EMetaGetters.GET)](state) {
    return (key: string) => state.data[key];
  },
};
