<template>
  <ElDialog
    ref="modal"
    v-model="innerVisibility"
    :append-to-body="true"
    :before-close="beforeCloseHandler"
    class="el-dialog--ghost-header discard-modal"
    width="500px"
  >
    <PxModalMessage :visible="hasServerError" />
    <h2
      v-if="title"
      :class="{ 'discard-modal__only-title': !subtitle }"
      class="discard-modal__title"
    >
      {{ title }}
    </h2>
    <p v-if="subtitle" class="discard-modal__subtitle">
      {{ subtitle }}
    </p>
    <template #footer>
      <PxButton
        class="discard-modal__cancel-btn"
        size="small"
        type="link"
        @click="onClickCancelButton"
      >
        {{ $t("common.cancel") }}
      </PxButton>
      <PxButton
        :loading="isLoading"
        class="discard-modal__submit-btn"
        type="red"
        @click="onClickDiscardButton"
      >
        {{ actionButtonLabel }}
      </PxButton>
    </template>
  </ElDialog>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import ElDialogMixin from "@/mixins/el-dialog.mixin";

export default defineComponent({
  name: "DiscardModal",

  mixins: [ElDialogMixin],

  props: {
    hasServerError: {
      type: Boolean,
      default: false,
    },

    isLoading: {
      type: Boolean,
      default: false,
    },

    title: {
      type: String,
      default: "",
    },

    subtitle: {
      type: String,
      default: "",
    },

    actionButtonLabel: {
      type: String,
      default: "",
    },
  },

  methods: {
    updateClosedState() {
      this.$emit("update:visibility", false);
    },

    beforeCloseHandler() {
      this.updateClosedState();
    },

    onClickCancelButton() {
      this.$emit("cancel-action");
      this.updateClosedState();
    },

    onClickDiscardButton() {
      this.$emit("discard-action");
      this.updateClosedState();
    },
  },
});
</script>

<style lang="scss">
.discard-modal {
  /* Override no header default styles */
  margin-top: 15vh !important;

  .el-dialog__headerbtn {
    top: 20px;
    right: 20px;
  }

  .el-dialog__body {
    max-width: 410px;
    padding: 28px 0 14px;
    margin: auto;
  }

  .el-dialog__footer {
    display: flex;
    justify-content: center;
    position: relative;
    padding: 16px 0;

    &::after {
      position: absolute;
      top: -1px;
      right: 40px;
      left: 40px;
      height: 1px;

      content: "";
      background-color: $athens-gray;

      /* Hide line separator instead of removing since it might be useful in the future */
      opacity: 0;
    }
  }

  &__title {
    @include grotesk(semiBold);

    margin-bottom: 15px;
    font-size: 18px;
    line-height: 30px;
    color: $ebony-clay;
    text-align: center;

    @include breakpoint-up(md) {
      font-size: 20px;
    }
  }

  &__only-title {
    margin-bottom: 20px;
  }

  &__subtitle {
    margin-bottom: 39px;
    font-size: 14px;
    line-height: 22px;
    color: $ebony-clay;
    text-align: center;
    letter-spacing: -0.3px;
  }

  &__cancel-btn {
    position: relative;
    top: 1px;
    left: 1px;
    padding-bottom: 10px;
  }

  &__submit-btn {
    padding: 0 16px;
    // Override default
    margin-left: 17px !important;

    span {
      top: 0;
      font-size: 14px;
      letter-spacing: -0.1px;
    }
  }
}
</style>
