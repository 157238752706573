import { GetterTree } from "vuex";
import { RootState } from "@/services/store/root-state";
import { ICategoryLevelSelection } from "@/services/data/viral-level/viral-level.interface";
import { getBaseName } from "@/services/store/utils/get-base-name";
import { ICategoryState } from "@/services/store/category/category-types";
import { ICategory } from "@/services/data/category/category.interface";
import {
  EAffiliatesGetters,
  IAffiliatesState,
} from "@/modules/affiliates/services/store/affiliates/affiliates.types";

export const getters: GetterTree<IAffiliatesState, RootState> = {
  /**
   * Get current category data.
   *
   * @param state module state
   * @param _ reference for getters
   * @param rootState root state
   */
  [getBaseName(EAffiliatesGetters.CURRENT_CATEGORY)](state, _, rootState) {
    return ((rootState as any).categories as ICategoryState).data[
      state.selectedAssessmentCategory
    ];
  },

  /**
   * Get current selected step data of assessment
   *
   * @param state module state
   * @param mGetters reference for getter
   */
  [getBaseName(EAffiliatesGetters.SELECTED_STEP_DATA)](state, mGetters) {
    if (
      !state.draftSubmission?.data.assessment ||
      state.draftSubmission?.data.assessment.length === 0
    ) {
      return null;
    }

    const currentCategory = mGetters[
      getBaseName(EAffiliatesGetters.CURRENT_CATEGORY)
    ] as ICategory;

    const selectedCategoryAndLevel =
      state.draftSubmission?.data.assessment.find(
        (record: ICategoryLevelSelection): any => {
          return currentCategory && record.category === currentCategory.id;
        },
      );

    return selectedCategoryAndLevel || null;
  },
};
