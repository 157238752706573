import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PxIcon = _resolveComponent("PxIcon")!

  return (_openBlock(), _createElementBlock("div", {
    style: _normalizeStyle(_ctx.cellStyles),
    class: "milestone-cell"
  }, [
    (_ctx.completed)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          (_ctx.evidenceProvided)
            ? (_openBlock(), _createBlock(_component_PxIcon, {
                key: 0,
                name: "evidence-provided",
                size: "18"
              }))
            : (_openBlock(), _createBlock(_component_PxIcon, {
                key: 1,
                name: "self-assessed",
                size: "16"
              }))
        ], 64))
      : (_ctx.critical)
        ? (_openBlock(), _createBlock(_component_PxIcon, {
            key: 1,
            class: "milestone-cell__critical",
            name: "critical-milestone",
            size: "22"
          }))
        : _createCommentVNode("", true)
  ], 4))
}