import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-770558a2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "affiliate-program-results" }
const _hoisted_2 = { class: "affiliate-program-results__confirmation-panel" }
const _hoisted_3 = { class: "affiliate-program-results__confirmation-panel-thanks" }
const _hoisted_4 = { class: "affiliate-program-results__confirmation-panel-title" }
const _hoisted_5 = { class: "affiliate-program-results__confirmation-panel-information" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AffiliateNavigationBar = _resolveComponent("AffiliateNavigationBar")!
  const _component_PxIcon = _resolveComponent("PxIcon")!
  const _component_i18n_t = _resolveComponent("i18n-t")!
  const _component_PxButton = _resolveComponent("PxButton")!
  const _component_ValidatingPanelPlaceholder = _resolveComponent("ValidatingPanelPlaceholder")!
  const _component_ValidatingPanel = _resolveComponent("ValidatingPanel")!
  const _component_DetailsPanelPlaceholder = _resolveComponent("DetailsPanelPlaceholder")!
  const _component_DetailsPanel = _resolveComponent("DetailsPanel")!
  const _component_ResultsBoard = _resolveComponent("ResultsBoard")!
  const _component_FeaturesShowcase = _resolveComponent("FeaturesShowcase")!
  const _component_SignUpModal = _resolveComponent("SignUpModal")!
  const _component_PxFooter = _resolveComponent("PxFooter")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.showNavbar)
      ? (_openBlock(), _createBlock(_component_AffiliateNavigationBar, {
          key: 0,
          "external-link": _ctx.affiliate ? _ctx.affiliate.website : '',
          loading: _ctx.isLoading,
          logo: _ctx.affiliate ? _ctx.affiliate.logo : '',
          "show-steps": false
        }, null, 8, ["external-link", "loading", "logo"]))
      : _createCommentVNode("", true),
    _withDirectives((_openBlock(), _createElementBlock("div", {
      class: _normalizeClass([{ 'affiliate-program-results--variation': _ctx.showNavbar }, "affiliate-program-results__wrapper"])
    }, [
      (_ctx.isUserLogged)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("h1", _hoisted_4, [
                  _createVNode(_component_PxIcon, {
                    name: `icon-check-green-white`,
                    size: 18,
                    class: "affiliate-program-results__confirmation-panel-icon"
                  }),
                  _createTextVNode(" " + _toDisplayString(_ctx.$t("affiliateProgram.results.confirmationPanel.title")), 1)
                ])
              ]),
              _createElementVNode("div", _hoisted_5, [
                (_ctx.affiliate)
                  ? (_openBlock(), _createBlock(_component_i18n_t, {
                      key: 0,
                      class: "affiliate-program-results__confirmation-panel-content",
                      keypath: "affiliateProgram.results.confirmationPanel.sentInfo",
                      tag: "p"
                    }, {
                      company: _withCtx(() => [
                        _createElementVNode("u", null, _toDisplayString(_ctx.affiliate.name), 1)
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                _createVNode(_component_i18n_t, {
                  class: "affiliate-program-results__confirmation-panel-content",
                  keypath: "affiliateProgram.results.confirmationPanel.seeLatest",
                  tag: "p"
                }, {
                  cta: _withCtx(() => [
                    _createVNode(_component_PxButton, {
                      type: "link",
                      onClick: _ctx.goToMilestonePlanner
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t("affiliateProgram.results.confirmationPanel.cta")), 1)
                      ]),
                      _: 1
                    }, 8, ["onClick"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_i18n_t, {
                  class: "affiliate-program-results__confirmation-panel-content",
                  keypath: "affiliateProgram.results.confirmationPanel.affiliateShare",
                  tag: "p"
                }, {
                  submission: _withCtx(() => [
                    _createElementVNode("b", null, _toDisplayString(_ctx.$t("affiliateProgram.results.confirmationPanel.submission")), 1)
                  ]),
                  _: 1
                })
              ])
            ]),
            (_ctx.isLoading)
              ? (_openBlock(), _createBlock(_component_ValidatingPanelPlaceholder, {
                  key: 0,
                  class: "affiliate-program-results__validating-panel"
                }))
              : (_openBlock(), _createBlock(_component_ValidatingPanel, {
                  key: 1,
                  class: "affiliate-program-results__validating-panel"
                })),
            (_ctx.isLoading)
              ? (_openBlock(), _createBlock(_component_DetailsPanelPlaceholder, { key: 2 }))
              : _createCommentVNode("", true),
            (!_ctx.isLoading && !_ctx.hasError)
              ? (_openBlock(), _createBlock(_component_DetailsPanel, {
                  key: 3,
                  class: "affiliate-program-results__details-panel"
                }))
              : _createCommentVNode("", true)
          ], 64))
        : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
            _createVNode(_component_ResultsBoard),
            (!_ctx.isFeaturesShowcaseDisabled)
              ? (_openBlock(), _createBlock(_component_FeaturesShowcase, { key: 0 }))
              : _createCommentVNode("", true)
          ], 64)),
      (
          _ctx.$features.isEnabled('assessmentUserInfoBeforeHand') && !_ctx.isUserLogged
        )
        ? (_openBlock(), _createBlock(_component_SignUpModal, { key: 2 }))
        : _createCommentVNode("", true)
    ], 2)), [
      [_directive_loading, _ctx.isLoading]
    ]),
    (_ctx.showFooter)
      ? (_openBlock(), _createBlock(_component_PxFooter, { key: 1 }))
      : _createCommentVNode("", true)
  ]))
}