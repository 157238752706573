import { defineComponent } from "vue";
import isEqual from "lodash/isEqual";

import { IMatchingResponse } from "@/services/data/matching-responses/matching-response.interface";
import {
  DATE,
  FREE_RESPONSE,
  IMatchingQuestion,
  MULTI_SELECT,
  NUMERIC,
  RANGE,
  SINGLE_SELECT,
} from "@/services/data/matching-questionary/matching-question.interface";
import { IAffiliate } from "@/services/data/affiliate/affiliate.interface";
import { ISupporterInvestingLevelState } from "@/modules/supporters/services/store/supporter-investing-level/supporter-investing-level-types";
import { EAffiliateGetter } from "@/modules/supporters/services/store/affiliate/affiliate.interface";
import moment from "moment";
import { activeModules } from "@/services/utils/utils";

export default defineComponent({
  name: "QuestionPanel",

  props: {
    modelValue: {
      type: Object as () => IMatchingResponse,
      default: () => ({}),
    },

    question: {
      type: Object as () => IMatchingQuestion,
      required: true,
    },

    step: {
      type: Number,
      default: 0,
    },

    total: {
      type: Number,
      default: 1,
    },

    textAreaLimit: {
      type: Number,
      default: 1500,
    },

    showOnlyQuestionContent: {
      type: Boolean,
      default: false,
    },

    fieldsAreRequired: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      freeResponse: FREE_RESPONSE,
      singleSelect: SINGLE_SELECT,
      multiSelect: MULTI_SELECT,
      numeric: NUMERIC,
      range: RANGE,
      dateResponse: DATE,
      innerValue: {} as IMatchingResponse,
      noticeIconSize: 16,
    };
  },

  computed: {
    /**
     * Current step composed text
     */
    currentStepText(): string {
      return this.$t("affiliateProgram.questions.currentState", {
        current: this.step + 1,
        total: this.total,
      }) as string;
    },

    viralLevelState(): ISupporterInvestingLevelState {
      return this.$store.state.viralLevel;
    },

    affiliate(): IAffiliate | null {
      return this.$user.isEntrepreneur()
        ? this.viralLevelState.affiliate
        : this.$store.getters[EAffiliateGetter.VALUE];
    },

    affiliateName(): string {
      return this.affiliate ? this.affiliate.name : "";
    },

    isMoneyType(): boolean {
      return (
        (this.question.question_type.meta &&
          this.question.question_type.meta.currency) ||
        false
      );
    },

    needsCurrencyRange(): boolean {
      return [
        this.$user.isEntrepreneur() &&
          this.isMoneyType &&
          this.isQuestionType(this.range),
        this.$user.isSupporter() &&
          this.isMoneyType &&
          this.isQuestionType(this.numeric),
      ].some((isValid: boolean) => isValid);
    },

    isProfileQuestion(): boolean {
      return !!this.question.profile_field;
    },

    isMatchingQuestion(): boolean {
      return !(
        this.isQuestionType(this.freeResponse) ||
        this.isQuestionType(this.dateResponse)
      );
    },

    showNotice(): boolean {
      return !this.isMatchingQuestion || this.isProfileQuestion;
    },

    profileNoticeText(): string {
      return (
        this.$user.isLogged()
          ? this.$t("affiliateProgram.questions.profileFieldNotice.authUser")
          : this.$t("affiliateProgram.questions.profileFieldNotice.newUser")
      ) as string;
    },

    noticeText(): string {
      if (!this.showNotice) {
        return "";
      }

      let text = "";

      if (this.isProfileQuestion) {
        text = this.profileNoticeText;
      }

      // We should not show notice for matching question if:
      // 1. Is a matching question OR
      // 2. Match feature is not enabled OR
      // 3. Matching module is not active
      if (
        this.isMatchingQuestion ||
        !this.$features.isEnabled("match") ||
        !activeModules().includes("matching")
      ) {
        return text;
      }

      return (
        text === ""
          ? this.$t("affiliateProgram.questions.matchingPurposes.single")
          : `${text} ${this.$t(
              "affiliateProgram.questions.matchingPurposes.composed",
            )}`
      ) as string;
    },

    questionTitle(): string {
      return this.$user.isEntrepreneur()
        ? this.question.entrepreneur_question
        : this.question.resource_question;
    },

    hasRequiredFields(): boolean {
      return !!this.innerValue?.answers || !!this.innerValue?.value;
    },

    hasValidValue(): boolean {
      if (
        this.fieldsAreRequired ||
        (!this.fieldsAreRequired && !!this.innerValue?.value?.value)
      ) {
        return (
          !!this.innerValue?.value?.value &&
          !isNaN(this.parseFloatNumber(this.innerValue.value.value))
        );
      }

      return true;
    },

    hasValidAnswer(): boolean {
      if (
        this.fieldsAreRequired ||
        (!this.fieldsAreRequired && !!this.innerValue?.answers.length)
      ) {
        return !!this.innerValue?.answers?.length;
      }

      return true;
    },

    hasValidDate(): boolean {
      if (
        this.fieldsAreRequired ||
        (!this.fieldsAreRequired && !!this.innerValue?.value?.date)
      ) {
        return moment(
          this.innerValue?.value?.date,
          "YYYY-MM-DD",
          true,
        ).isValid();
      }

      return true;
    },

    hasValidRange(): boolean {
      if (
        this.fieldsAreRequired ||
        (!this.fieldsAreRequired &&
          !!this.innerValue?.value?.min &&
          !!this.innerValue?.value?.max)
      ) {
        if (!this.innerValue?.value?.max || !this.innerValue?.value?.min) {
          return false;
        }

        return (
          this.parseFloatNumber(this.innerValue?.value?.max) >
          this.parseFloatNumber(this.innerValue?.value?.min)
        );
      }

      return true;
    },

    hasValidText(): boolean {
      if (
        this.fieldsAreRequired ||
        (!this.fieldsAreRequired && !!this.innerValue?.value?.text)
      ) {
        return this.innerValue?.value?.text
          ? this.innerValue?.value?.text?.length <= this.textAreaLimit
          : false;
      }

      return true;
    },

    isValid(): boolean {
      if (this.hasRequiredFields) {
        if (
          (this.isQuestionType(this.numeric) && this.$user.isEntrepreneur()) ||
          (this.isQuestionType(this.range) && this.$user.isSupporter())
        ) {
          return this.hasValidValue;
        } else if (
          (this.isQuestionType(this.numeric) && this.$user.isSupporter()) ||
          (this.isQuestionType(this.range) && this.$user.isEntrepreneur())
        ) {
          return this.hasValidRange;
        } else if (this.isQuestionType(this.freeResponse)) {
          return this.hasValidText;
        } else if (this.isQuestionType(this.dateResponse)) {
          return this.hasValidDate;
        } else if (
          this.isQuestionType(this.singleSelect) ||
          this.isQuestionType(this.multiSelect)
        ) {
          return this.hasValidAnswer;
        }
      }
      return false;
    },

    numericPlaceholder(): string {
      return this.$t("affiliateProgram.questions.numericPlaceholder", {
        number: 50,
      }) as string;
    },

    numericRangePlaceholder(): Array<string> {
      return [
        this.$t("affiliateProgram.questions.numericPlaceholder", {
          number: 2,
        }) as string,
        this.$t("affiliateProgram.questions.numericPlaceholder", {
          number: 4,
        }) as string,
      ];
    },
  },

  watch: {
    modelValue: {
      deep: true,
      handler(newValue: IMatchingResponse) {
        const formattedInnerValue = this.formatValue(this.innerValue);

        if (isEqual(newValue, formattedInnerValue)) {
          return;
        }

        this.innerValue = this.formatInnerValue(newValue);
      },
    },

    innerValue: {
      deep: true,
      handler(newInnerValue: IMatchingResponse) {
        if (this.isQuestionType(this.singleSelect)) {
          const formattedInnerValue = this.formatInnerValue(newInnerValue);

          if (!isEqual(newInnerValue, formattedInnerValue)) {
            this.$nextTick(() => {
              this.innerValue = formattedInnerValue;
            });
            return;
          }
        }

        this.$emit("response-formatted", newInnerValue.question);

        const formattedValue = this.formatValue(newInnerValue);

        this.$emit("update:modelValue", formattedValue);
        this.$emit("change", formattedValue);

        // Emit validated state
        this.$emit("validate", this.isValid);
      },
    },

    question: {
      deep: true,
      immediate: true,
      handler() {
        this.innerValue = this.formatInnerValue(this.innerValue);
      },
    },
  },

  created() {
    this.innerValue = this.formatInnerValue(this.modelValue);
  },

  methods: {
    isQuestionType(type: string): boolean {
      return (
        this.question.question_type && this.question.question_type.type === type
      );
    },

    parseFloatNumber(value: any): number {
      return parseFloat((value?.toString() || "").replace(/,/g, ""));
    },

    parseFloatAsString(value: any): string {
      return this.parseFloatNumber(value).toString();
    },

    formatValue(response: any): IMatchingResponse {
      const newResponse = {
        ...response,
      };

      if (
        (this.isQuestionType(this.numeric) && this.$user.isEntrepreneur()) ||
        (this.isQuestionType(this.range) && this.$user.isSupporter())
      ) {
        newResponse.value = {
          value:
            response?.value?.value || response?.value?.value === 0
              ? this.parseFloatNumber(response.value.value)
              : undefined,
        };
      } else if (
        (this.isQuestionType(this.numeric) && this.$user.isSupporter()) ||
        (this.isQuestionType(this.range) && this.$user.isEntrepreneur())
      ) {
        newResponse.value = {
          min:
            response?.value?.min || response?.value?.min === 0
              ? this.parseFloatNumber(response.value.min)
              : undefined,
          max:
            response?.value?.max || response?.value?.max === 0
              ? this.parseFloatNumber(response.value.max)
              : undefined,
        };
      } else if (
        this.isQuestionType(this.singleSelect) ||
        this.isQuestionType(this.multiSelect)
      ) {
        newResponse.answers = !!response?.answers?.length
          ? [...response.answers].filter((answer) => !!answer)
          : [];
      }

      return newResponse;
    },

    formatInnerValue(response: any): IMatchingResponse {
      const newResponse = {
        answers: [] as any[],
        value: {} as any,
        question: this.question.id,
        id: response ? response.id : undefined,
      };

      if (
        (this.isQuestionType(this.numeric) && this.$user.isEntrepreneur()) ||
        (this.isQuestionType(this.range) && this.$user.isSupporter())
      ) {
        newResponse.value = {
          value:
            response?.value?.value || response?.value?.value === 0
              ? this.parseFloatAsString(response.value.value)
              : "",
        };
      } else if (
        (this.isQuestionType(this.numeric) && this.$user.isSupporter()) ||
        (this.isQuestionType(this.range) && this.$user.isEntrepreneur())
      ) {
        newResponse.value = {
          min:
            response?.value?.min || response?.value?.min === 0
              ? this.parseFloatAsString(response.value.min)
              : "",
          max:
            response?.value?.max || response?.value?.max === 0
              ? this.parseFloatAsString(response.value.max)
              : "",
        };
      } else if (this.isQuestionType(this.freeResponse)) {
        newResponse.value = {
          text: response?.value?.text !== undefined ? response.value.text : "",
        };
      } else if (this.isQuestionType(this.dateResponse)) {
        newResponse.value = {
          date:
            response?.value?.date !== undefined
              ? response.value.date
              : moment().format("YYYY-MM-DD"),
        };
      } else if (
        this.isQuestionType(this.singleSelect) ||
        this.isQuestionType(this.multiSelect)
      ) {
        newResponse.answers = !!response?.answers?.length
          ? [...response.answers].filter((answer) => !!answer)
          : [];
      }

      return newResponse;
    },

    selectTextAreaLastCharacter(textAreaElem: HTMLTextAreaElement): void {
      // Select last characters
      const textAreaContentQuantity = textAreaElem.value.length * 2;

      // Timeout seems to be required for Blink
      setTimeout(() => {
        textAreaElem.setSelectionRange(
          textAreaContentQuantity,
          textAreaContentQuantity,
        );
      }, 1);
    },

    scrollToTextAreaCurrentWord(focusAtLastChar: boolean): void {
      const textAreaElem = document.querySelector(
        ".el-textarea__inner",
      ) as HTMLTextAreaElement;
      const textAreaWrapperElem = document.querySelector(
        ".question-panel__content-wrapper",
      );

      if (
        !!document.scrollingElement &&
        !!textAreaWrapperElem &&
        !!textAreaElem
      ) {
        // Check if text area has content to bottom on focus at end
        const textAreaScrollHeight = textAreaElem.scrollHeight;
        const textAreaClientHeight = textAreaElem.clientHeight;

        const textAreaScrollTo =
          textAreaScrollHeight > textAreaClientHeight
            ? textAreaScrollHeight
            : 0;

        textAreaElem.scrollTop = textAreaScrollTo;

        const baseContentElem = document.querySelector(
          ".question-panel__container-header",
        );
        const baseContentScroll = baseContentElem
          ? baseContentElem.clientHeight
          : 0;

        let scrollAmount =
          textAreaWrapperElem.getBoundingClientRect().top +
          document.scrollingElement.scrollTop -
          baseContentScroll;

        if (textAreaScrollTo !== 0 && focusAtLastChar) {
          this.selectTextAreaLastCharacter(textAreaElem);
          scrollAmount += textAreaClientHeight;
        }

        document.scrollingElement.scrollTop = scrollAmount;
      }
    },

    onTextareaFocus(): void {
      this.scrollToTextAreaCurrentWord(true);
    },
  },
});
