<template>
  <div
    v-loading="loading || !capitalExplorerTilesResults || !dataPrefilled"
    :class="{ 'comparison-table-visitors--no-labels': !allQuestionsAnswered }"
    class="comparison-table-visitors"
    element-loading-background="#fafafa"
  >
    <div v-if="!loading && capitalExplorerTilesResults && dataPrefilled">
      <div class="comparison-table-visitors__header">
        <div class="comparison-table-visitors__header-top">
          <div
            v-if="companyData"
            class="comparison-table-visitors__header-content"
            @click="goToOwnerProfile"
          >
            <PxAvatar
              :name="companyData.name"
              :photo="companyData.logo"
              :size="24"
              class="comparison-table-visitors__header-content-logo"
            />
            <PxButton
              class="el-button--link el-button--link-dark comparison-table-visitors__header-content-title"
              type="link"
            >
              {{ companyData.name }}
            </PxButton>
            <PxIcon
              class="comparison-table-visitors__header-content-arrow-separator"
              name="arrow--right-black"
              size="12"
            />
          </div>
          <h1 class="comparison-table-visitors__header-title">
            {{ $t("capitalExplorer.comparisonTable.visitorTitle") }}
          </h1>
        </div>
        <div class="comparison-table-visitors__header-bottom">
          {{ subtitleCopy }}
        </div>
      </div>
      <div class="comparison-table-visitors__wrapper">
        <PxButton
          v-show="showLeftButton"
          class="comparison-table-visitors__left-button"
          v-bind="$options.static.backButton"
          @click="scroll('left')"
        />
        <PxButton
          v-show="showRightButton"
          class="comparison-table-visitors__right-button"
          v-bind="$options.static.backButton"
          @click="scroll('right')"
        />
        <div
          ref="scrollContainer"
          class="comparison-table-visitors__scroll"
          @scroll.stop="handleScroll"
        >
          <UserResponsesTable
            :all-questions-answered="allQuestionsAnswered"
            :table-data="userResponsesTableData"
            class="comparison-table-visitors__left-table"
          />
          <CompareResponsesTable
            :all-questions-answered="allQuestionsAnswered"
            :answers-data="compareResponsesTableData"
            :match-details-modal-data="capitalExplorerTilesOrdered"
            :table-headers="tableHeaders"
            class="comparison-table-visitors__right-table"
          />
        </div>
      </div>
      <MatchDetailsTableLegend
        :legend-cards="tableLegend"
        class="comparison-table-visitors__legend"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import UserResponsesTable from "@/modules/capital-explorer/components/comparison-tables/user-responses-table.vue";
import CompareResponsesTable from "@/modules/capital-explorer/components/comparison-tables/compare-responses-table.vue";
import MatchDetailsTableLegend, {
  ILegendCard,
} from "@/components/match-details-table/match-details-table-legend.vue";

import { TranslateResult } from "vue-i18n";

import {
  ICapitalTileInfo,
  ICapitalExplorerData,
  ICapitalExplorerVisitorData,
  ICapitalExplorerVisitorDataCompany,
} from "@/modules/capital-explorer/services/data/capital-explorer/capital-explorer.interface";
import { IMatchingQuestion } from "@/services/data/matching-questionary/matching-question.interface";
import { IMatchingResponse } from "@/services/data/matching-responses/matching-response.interface";
import { ECapitalExplorerActions } from "@/modules/capital-explorer/services/store/capital-explorer/capital-explorer.types";
import { calculateResults } from "@/modules/capital-explorer/calculate-results";
import cloneDeep from "lodash/cloneDeep";
import {
  EPxButtonSize,
  EPxButtonVariant,
} from "@/components/px-button/px-button.types";
import { ROUTE_PROFILE } from "@/modules/profile/services/router/routes-names";

export default defineComponent({
  name: "ComparisonTableVisitorsView",

  components: {
    UserResponsesTable,
    CompareResponsesTable,
    MatchDetailsTableLegend,
  },

  static: {
    backButton: {
      icon: "arrow",
      size: EPxButtonSize.XX_SMALL,
      variant: EPxButtonVariant.COMPACT,
    },
  },

  data() {
    return {
      showLeftButton: false,
      showRightButton: true,
      scrollAmount: 256,
      dataPrefilled: false,
    };
  },

  computed: {
    capitalExplorerData(): ICapitalExplorerData | null {
      return this.$store.get("capitalExplorer/data") || null;
    },

    capitalExplorerVisitorsData(): ICapitalExplorerVisitorData | null {
      return this.$store.get("capitalExplorer/visitorData") || null;
    },

    capitalExplorerTilesResults(): ICapitalTileInfo[] | null {
      return this.capitalExplorerVisitorsData?.results || null;
    },

    capitalExplorerTilesOrdered(): ICapitalTileInfo[] {
      const capitalExplorerResults = cloneDeep(
        this.capitalExplorerTilesResults,
      );

      return (
        capitalExplorerResults?.sort(
          (a, b) =>
            (b.matchProperties.score || 0) - (a.matchProperties.score || 0),
        ) || []
      );
    },

    loading(): boolean {
      return this.$store.get("capitalExplorer/loading");
    },

    questions(): IMatchingQuestion[] {
      return this.capitalExplorerData?.questions || [];
    },

    responses(): IMatchingResponse[] {
      return this.capitalExplorerVisitorsData?.responses || [];
    },

    tableHeaders(): Array<string> {
      return (
        this.capitalExplorerTilesOrdered?.map((result: ICapitalTileInfo) => {
          return result.capitalInfo.title;
        }) || []
      );
    },

    userResponsesTableData(): any {
      return this.questions.map((question) => {
        const questionResponse = this.responses.find(
          (response) => response.question === question.id,
        );

        return {
          id: question.id,
          question: question.entrepreneur_question,
          response:
            questionResponse?.answers.map(
              (answer) =>
                question?.answers.find(({ id }) => id === answer)?.value || "",
            ) || [],
          instructions: question.instructions,
        };
      });
    },

    compareResponsesTableData(): Array<any> {
      return this.questions.map((question) => {
        return this.capitalExplorerTilesOrdered?.map((tile) => {
          const tileDetails = tile.matchDetails.find(
            (detail) => detail.question.id === question.id,
          );

          return {
            responses: tileDetails?.desiredAnswers,
            matchIcon: !tileDetails?.userAnswers.length
              ? "unanswered"
              : tileDetails.match
                ? "match--green"
                : "no-match",
          };
        });
      });
    },

    tableLegend() {
      return this.$tm(
        `capitalExplorer.detailsModal.legendVisitor`,
      ) as ILegendCard[];
    },

    allQuestionsAnswered(): boolean {
      return this.responses.length === this.questions.length;
    },

    companyData(): ICapitalExplorerVisitorDataCompany | null {
      return this.capitalExplorerVisitorsData?.company || null;
    },

    subtitleCopy(): TranslateResult {
      return this.$t("capitalExplorer.comparisonTable.visitorSubtitle", {
        company: this.companyData?.name,
      });
    },
  },

  async created() {
    if (!this.capitalExplorerTilesResults?.length) {
      await this.$store.dispatch(ECapitalExplorerActions.FETCH_DATA);

      calculateResults();
    }

    this.dataPrefilled = true;
  },

  methods: {
    handleScroll() {
      const scrollContainer = this.$refs.scrollContainer as HTMLElement;

      const maxScroll =
        scrollContainer.scrollWidth - scrollContainer.clientWidth;

      this.showLeftButton = scrollContainer.scrollLeft > 0;
      this.showRightButton = Math.ceil(scrollContainer.scrollLeft) < maxScroll;
    },

    scroll(direction: string) {
      const scrollContainer = this.$refs.scrollContainer as HTMLElement;

      const scrollAmount =
        direction === "left" ? -this.scrollAmount : this.scrollAmount;

      scrollContainer.scrollBy({
        left: scrollAmount,
        behavior: "smooth",
      });
    },

    goToOwnerProfile() {
      this.$router.push({
        name: ROUTE_PROFILE,
        params: { id: String(this.companyData?.id) },
      });
    },
  },
});
</script>

<style lang="scss" scoped>
.comparison-table-visitors {
  width: 100%;
  height: calc(100vh - 66px);

  &__header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    padding: 30px 0 30px 104px;

    border-bottom: 1px solid $turkish-angora;
    background-color: $white;

    &-top {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 8px;
    }

    &-title {
      margin-right: 13px;
      margin-bottom: 0;
      font-size: 20px;
      line-height: 28px;
    }

    &-bottom {
      font-size: 17px;
      line-height: 1.3;
    }
  }

  &__header-content {
    display: flex;
    align-items: center;
    cursor: pointer;

    &-logo {
      width: 26px;
      height: 26px;
    }

    &-title :deep(span) {
      margin-right: 9px;
      margin-left: 8px;
      font-size: 20px;
      line-height: 30px;
      text-decoration: none;
    }

    &-arrow-separator {
      margin-right: 7px;
    }
  }

  &__wrapper {
    position: relative;

    width: 100%;
    margin: 0 auto;
    overflow: hidden;
  }

  &__scroll {
    display: grid;
    grid-template-columns: 496px auto;

    overflow: scroll;
    max-height: calc(100vh - 66px - 132px - 30px - 30px);
    margin: 30px;
    box-shadow: 0 4px 12px 0 rgba(139, 143, 161, 0.08);
    border: 1px solid rgba(0, 0, 0, 0.06);
    border-radius: 8px;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__right-button,
  &__left-button {
    position: absolute;
    top: 101px;
    z-index: z("floaters") + 5;

    border-radius: 50%;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);
  }

  &__left-button {
    left: 513px;

    :deep(.px-icon) {
      transform: rotate(90deg);
    }
  }

  &__right-button {
    right: 20px;

    :deep(.px-icon) {
      transform: rotate(-90deg);
    }
  }

  &--no-labels {
    .comparison-table__right-button,
    .comparison-table__left-button {
      top: 72px;
    }
  }

  &__left-table {
    position: sticky;
    top: 0;
    left: 0;
    z-index: z("floaters") + 3;
    max-width: 496px;
    box-shadow: 4px 0 10px 0 rgba(39, 39, 64, 0.1);
  }

  &__legend {
    position: absolute;
    right: 24px;
    bottom: 24px;
    z-index: z("floaters") + 4;

    :deep(.el-collapse-item) {
      display: flex;
      flex-direction: column;
    }

    :deep(.el-collapse-item__header) {
      display: flex;
      align-self: flex-end;
      padding: 5px 32px 5px 12px;
      border-radius: 8px;
      box-shadow: 0 2px 8px 0 rgba(39, 39, 64, 0.12);
    }

    :deep(.el-collapse-item.is-active .el-collapse-item__header) {
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
      box-shadow: 0 -7px 8px 0 rgba(39, 39, 64, 0.03);
    }

    :deep(.el-collapse-item__header::before) {
      top: 2px;
      right: 12px;
    }

    :deep(.el-collapse-item__header:hover::before) {
      filter: invert(60%);
    }

    :deep(.el-collapse-item__header:hover span) {
      color: $manatee;
    }

    :deep(.el-collapse-item__wrap) {
      max-width: 864px;
      margin: 0;
      padding: 12px;
      border-radius: 8px 0 8px 8px;
      box-shadow: 0 2px 8px 0 rgba(39, 39, 64, 0.12);
    }

    :deep(.match-details-table-legend-card) {
      padding: 10px 13px 13px 11px;
    }
  }
}
</style>
