import { ICapitalMatchProperties } from "@/modules/capital-explorer/services/data/capital-explorer/capital-explorer.interface";

export const DEFAULT_MATCH = {
  backgroundColor: "#fff",
  borderColor: "#dcdde2",
  matchingBadge: null,
  score: null,
} as ICapitalMatchProperties;

export const EXCELLENT_MATCH = {
  backgroundImage: "linear-gradient(131deg, #f5fff6 1%, #fff 100%)",
  borderColor: "#b7dfb9",
  matchingBadge: {
    title: "capitalExplorer.tiles.matchingBadges.excellentMatch",
    icon: "icon-star",
    borderColor: "#4caf50",
    textColor: "#fff",
    backgroundColor: "#4caf50",
    boxShadow: "0 2px 4px 0 rgba(76, 175, 80, 0.25)",
  },
  score: 95,
} as ICapitalMatchProperties;

export const GOOD_MATCH = {
  backgroundColor: "#fff",
  borderColor: "#dcdde2",
  matchingBadge: {
    title: "capitalExplorer.tiles.matchingBadges.goodMatch",
    icon: "icon-smile",
    borderColor: "#4caf50",
    textColor: "#4caf50",
    backgroundColor: "#eaf5ee",
  },
  score: 80,
} as ICapitalMatchProperties;

export const FAIR_MATCH = {
  backgroundColor: "#fff",
  borderColor: "#dcdde2",
  matchingBadge: {
    title: "capitalExplorer.tiles.matchingBadges.fairMatch",
    icon: "icon-meh",
    borderColor: "#e39a00",
    textColor: "#e39a00",
    backgroundColor: "#fff9e7",
  },
  score: 30,
} as ICapitalMatchProperties;

export const POOR_MATCH = {
  backgroundImage: "linear-gradient(128.45deg, #F7F7F7 0.56%, #FAFAFA 100%)",
  borderColor: "#dcdde2",
  matchingBadge: {
    title: "capitalExplorer.tiles.matchingBadges.poorMatch",
    icon: "icon-frown",
    borderColor: "#8b8fa1",
    textColor: "#8b8fa1",
    backgroundColor: "#f0f1f5",
  },
  score: 5,
} as ICapitalMatchProperties;
