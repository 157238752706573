import { ICapitalExplorerData } from "@/modules/capital-explorer/services/data/capital-explorer/capital-explorer.interface";
import { GenericProvider, Provider } from "@/services/data/generic-provider";
import { baseAPIUrl } from "@/services/utils/utils";

@Provider(`${baseAPIUrl}/capital-explorer`)
class CapitalExplorerData extends GenericProvider<ICapitalExplorerData> {
  public async fetch(): Promise<ICapitalExplorerData> {
    const url = super.buildEndPointUrl({});
    const request = this.httpClient.get(url);
    const { data } = await this.wrapRequest(request);
    return data;
  }
}

export const capitalExplorerDataProvider = new CapitalExplorerData();
