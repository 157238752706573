import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = ["srcset"]
const _hoisted_2 = ["srcset"]
const _hoisted_3 = ["srcset"]
const _hoisted_4 = ["alt", "src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("picture", null, [
    (_ctx.link.mobile)
      ? (_openBlock(), _createElementBlock("source", {
          key: 0,
          srcset: `${_ctx.link.mobile} 1x`,
          media: "(max-width: 767px)"
        }, null, 8, _hoisted_1))
      : _createCommentVNode("", true),
    (_ctx.link.mobileRetina)
      ? (_openBlock(), _createElementBlock("source", {
          key: 1,
          srcset: `${_ctx.link.mobileRetina} 2x`,
          media: "(max-width: 767px)"
        }, null, 8, _hoisted_2))
      : _createCommentVNode("", true),
    (_ctx.link.retina)
      ? (_openBlock(), _createElementBlock("source", {
          key: 2,
          srcset: `${_ctx.link.original} 1x, ${_ctx.link.retina} 2x`
        }, null, 8, _hoisted_3))
      : _createCommentVNode("", true),
    _createElementVNode("img", {
      alt: _ctx.alt,
      src: _ctx.link.original
    }, null, 8, _hoisted_4)
  ]))
}