import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, createVNode as _createVNode, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, createElementBlock as _createElementBlock, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-658defdf"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "supporters-base page-full-height" }
const _hoisted_2 = { class: "container-fluid" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CategoryWizardMobile = _resolveComponent("CategoryWizardMobile")!
  const _component_MobileTopNavbar = _resolveComponent("MobileTopNavbar")!
  const _component_PxNavbar = _resolveComponent("PxNavbar")!
  const _component_PxGlobalError = _resolveComponent("PxGlobalError")!
  const _component_CategoryWizard = _resolveComponent("CategoryWizard")!
  const _component_RouterView = _resolveComponent("RouterView")!
  const _component_PxFooter = _resolveComponent("PxFooter")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.$screen.mdDown)
      ? (_openBlock(), _createBlock(_component_MobileTopNavbar, {
          key: 0,
          "affiliate-logo": _ctx.affiliateLogo,
          "has-error": _ctx.hasError
        }, {
          default: _withCtx(() => [
            (_ctx.showMobileWizard && !_ctx.hasError)
              ? (_openBlock(), _createBlock(_component_CategoryWizardMobile, {
                  key: 0,
                  "current-step": _ctx.currentStep,
                  "onUpdate:currentStep": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.currentStep) = $event)),
                  steps: _ctx.steps
                }, null, 8, ["current-step", "steps"]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["affiliate-logo", "has-error"]))
      : _createCommentVNode("", true),
    (_ctx.$screen.lgUp)
      ? (_openBlock(), _createBlock(_component_PxNavbar, {
          key: 1,
          logo: _ctx.getAffiliate('logo')
        }, null, 8, ["logo"]))
      : _createCommentVNode("", true),
    (_ctx.hasError)
      ? (_openBlock(), _createBlock(_component_PxGlobalError, {
          key: 2,
          "auto-refresh": true,
          loading: _ctx.isLoading,
          "use-action": true,
          class: "page-body is-center",
          onDoAction: _ctx.fetchCategories
        }, null, 8, ["loading", "onDoAction"]))
      : _createCommentVNode("", true),
    (!_ctx.hasError)
      ? _withDirectives((_openBlock(), _createElementBlock("div", {
          key: 3,
          class: _normalizeClass([{ 'is-loading': _ctx.isLoading }, "page-body supporters-base__container"]),
          "element-loading-background": "#fafafa"
        }, [
          _createElementVNode("div", _hoisted_2, [
            (_ctx.showDesktopWizard)
              ? (_openBlock(), _createBlock(_component_CategoryWizard, {
                  key: 0,
                  "current-step": _ctx.currentStep,
                  "onUpdate:currentStep": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.currentStep) = $event)),
                  checked: _ctx.checkedSteps,
                  disabled: _ctx.submitted,
                  steps: _ctx.steps,
                  class: "supporters-base__wizard",
                  onChanged: _ctx.stepClickHandler
                }, null, 8, ["current-step", "checked", "disabled", "steps", "onChanged"]))
              : _createCommentVNode("", true)
          ]),
          _createVNode(_component_RouterView)
        ], 2)), [
          [_directive_loading, _ctx.isLoading]
        ])
      : _createCommentVNode("", true),
    (_ctx.showFooter)
      ? (_openBlock(), _createBlock(_component_PxFooter, {
          key: 4,
          class: _normalizeClass(_ctx.extraFooterClasses)
        }, null, 8, ["class"]))
      : _createCommentVNode("", true)
  ]))
}