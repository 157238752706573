<template>
  <div class="affiliate-questions">
    <PxButton
      v-if="showReviewPageButton"
      class="affiliate-questions__back-button"
      v-bind="backButtonProps"
      @click="goToReviewPage"
    />
    <QuestionPanel
      v-if="hasCurrentQuestion"
      v-model="currentResponse"
      :question="currentQuestion"
      :step="currentSelectedQuestion"
      :text-area-limit="textAreaLimit"
      :total="questions.length"
      class="affiliate-questions__panel"
      @validate="validateResponseState"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import QuestionPanel from "@/modules/affiliates/components/question-panel/question-panel.vue";

import { IMatchingResponse } from "@/services/data/matching-responses/matching-response.interface";
import { IMatchingQuestion } from "@/services/data/matching-questionary/matching-question.interface";

import {
  IAffiliatesState,
  EAffiliatesActions,
} from "@/modules/affiliates/services/store/affiliates/affiliates.types";
import { EPxButtonType } from "@/components/px-button/px-button.types";

export default defineComponent({
  name: "AffiliateQuestions",

  components: {
    QuestionPanel,
  },

  static: {
    backButton: {
      type: EPxButtonType.LINK_DARK,
    },
  },

  data() {
    return {
      textAreaLimit: 1500,
      hasResponse: false,
      currentResponse: {} as IMatchingResponse,
      updatedResponses: [] as Array<IMatchingResponse>,
    };
  },

  computed: {
    affiliatesData(): IAffiliatesState {
      return this.$store.state.affiliates;
    },

    questions(): Array<IMatchingQuestion> {
      return this.affiliatesData.affiliateQuestionBundle || [];
    },

    givenResponses(): Array<IMatchingResponse> {
      return this.affiliatesData.draftSubmission?.data.responses || [];
    },

    currentQuestion(): IMatchingQuestion | null {
      return this.questions?.length &&
        this.questions.length > this.currentSelectedQuestion
        ? this.questions[this.currentSelectedQuestion]
        : null;
    },

    hasCurrentQuestion(): boolean {
      return !!this.currentQuestion;
    },

    currentSelectedQuestion(): number {
      return this.affiliatesData.selectedQuestion;
    },

    backButtonProps(): any {
      return {
        ...this.$options.static.backButton,
        label: this.$t("affiliates.backToReview"),
        icon: "arrow--right-black",
      };
    },

    showReviewPageButton(): boolean {
      return this.affiliatesData.userNavigatingFromReviewPage || false;
    },
  },

  watch: {
    currentQuestion: {
      immediate: true,
      handler() {
        this.updateCurrentResponse();
      },
    },

    givenResponses: {
      immediate: true,
      deep: true,
      handler() {
        this.updatedResponses = this.givenResponses;
      },
    },
  },

  methods: {
    validateResponseState(isValid: boolean) {
      this.hasResponse = isValid;

      this.updatedResponses = this.updatedResponses.map(
        (response: IMatchingResponse) => {
          if (response.question === this.currentResponse.question) {
            return {
              ...this.currentResponse,
              isValid: this.hasResponse,
            };
          }
          return response;
        },
      );

      this.$store.dispatch(
        EAffiliatesActions.SET_SUBMISSION_RESPONSES,
        this.updatedResponses,
      );
    },

    updateCurrentResponse() {
      if (!this.currentQuestion?.id) {
        return;
      }

      this.currentResponse = this.givenResponses.find(
        (response: IMatchingResponse) =>
          response.question === this.currentQuestion?.id,
      ) || {
        question: this.currentQuestion?.id,
        value: {},
        answers: [] as Array<number>,
      };
    },

    goToReviewPage() {
      this.$emit("go-to-review-page");
    },
  },
});
</script>

<style lang="scss" scoped>
.affiliate-questions {
  &__panel {
    margin: 90px auto 0;
  }

  &__back-button {
    position: fixed;
    margin-left: 90px;

    :deep() span {
      font-size: 14px;
      margin-left: 9px;
    }

    :deep() .px-icon {
      width: 12px;
      height: 12px;
      transform: rotate(180deg);
    }
  }
}
</style>
