import { GenericState } from "@/services/store/generic/generic-state.module";
import { getGenericStateActions } from "@/services/store/generic/generic-state.actions";
import { milestoneReportsProvider } from "@/modules/charts-dashboard/services/data/milestone-reports/milestone.provider";
import { EMilestoneReportsActions } from "@/modules/charts-dashboard/services/store/milestone/milestone.interface";
import { EMilestoneReportsMutation } from "@/modules/charts-dashboard/services/store/milestone/milestone.mutations";

import type { ActionTree } from "vuex";
import type { RootState } from "@/services/store/root-state";
import type { IMilestoneReports } from "@/modules/charts-dashboard/services/data/milestone-reports/milestone.interface";
import type { IMilestoneReportsState } from "@/modules/charts-dashboard/services/store/milestone/milestone.interface";

export const actions: ActionTree<IMilestoneReportsState, RootState> = {
  ...getGenericStateActions<IMilestoneReports>(milestoneReportsProvider),

  async [EMilestoneReportsActions.GET_VALUE](
    { commit },
    listUid,
  ): Promise<IMilestoneReports | null> {
    commit(GenericState.Mutation.SET_LOADING, true);
    commit(GenericState.Mutation.SET_ERROR, undefined);

    let response: IMilestoneReports | null = null;
    try {
      response = await milestoneReportsProvider.getById(listUid);
      commit(EMilestoneReportsMutation.SET_VALUE, response);
    } catch (error) {
      commit(EMilestoneReportsMutation.SET_VALUE, null);
      commit(EMilestoneReportsMutation.SET_ERROR, error || null);
    } finally {
      commit(GenericState.Mutation.SET_LOADING, true);
    }

    return response;
  },

  [EMilestoneReportsActions.GET_VALUES]() {},
  [EMilestoneReportsActions.GET_VALUE_DETAIL]() {},
  [EMilestoneReportsActions.PATCH_VALUE]() {},
  [EMilestoneReportsActions.REMOVE_VALUE]() {},
};
