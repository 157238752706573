<template>
  <div class="page-full-height">
    <RouterView />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import { EViralLevelActions } from "@/services/store/viral-level/viral-level-types";
import { NotFoundProviderException } from "@/services/data/exceptions/not-found-provider.exception";

import { SELF_ASSESSMENT_STARTING_POINT } from "@/modules/self-assessment/services/router/routes-names";
import {
  ROUTE_GUEST_VIEW,
  ROUTE_PROFILE,
} from "@/modules/profile/services/router/routes-names";

import { EMetaActions } from "@/services/store/meta/meta-types";
import { PROFILE_META_OPEN_ASSESSMENT_MODAL } from "@/modules/profile/constants";

export default defineComponent({
  name: "AssessmentController",

  created() {
    this.fetchAssessmentData();
  },

  methods: {
    /**
     * Fetch assessment data based on the present token.
     */
    async fetchAssessmentData() {
      const token = this.$route.params.token;

      try {
        await this.$store.dispatch(EViralLevelActions.FETCH_ASSESSMENT, token);
        const company = this.$store.get("profile/company.data");

        // Open Assessment Results when entering the profile
        this.$store.dispatch(EMetaActions.SET, {
          key: PROFILE_META_OPEN_ASSESSMENT_MODAL,
          value: true,
        });

        if (company.access_hash === this.$route.params.hash) {
          return;
        }

        this.$router.replace({
          name: ROUTE_GUEST_VIEW,
          params: { hash: company.access_hash },
        });
      } catch (error) {
        // When the given token or company doesn't exists redirect to
        // the start of the self assessment for visitors
        // or profile page for authenticated users
        if (error instanceof NotFoundProviderException) {
          const fallbackRoute = this.$user.isLogged()
            ? ROUTE_PROFILE
            : SELF_ASSESSMENT_STARTING_POINT;
          this.$router.replace({ name: fallbackRoute });
          return;
        }
      }
    },
  },
});
</script>
