<template>
  <div class="starting-point">
    <LangSwitcher />
    <PxPanel class="starting-point-panel">
      <h1 class="starting-point-panel__title">
        {{ $t("supporters.startingPoint.panel.title") }}
      </h1>
      <p class="starting-point-panel__content">
        {{ $t("supporters.startingPoint.panel.content") }}
      </p>
      <PxButton
        :icon="startButtonIcon"
        :loading="isLoading"
        :type="startButtonType"
        class="starting-point-panel__button"
        size="large"
        @click="onClickTakeAssessmentButton"
      >
        {{ startButtonText }}
      </PxButton>
    </PxPanel>
    <div class="starting-point__feature">
      <FeatureCard
        v-for="(item, index) in $tm('supporters.startingPoint.keyPoints')"
        :key="index"
        :description="item['description']"
        :icon="item['value']"
        :title="item['title']"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import FeatureCard from "@/modules/self-assessment/components/feature-card/feature-card.vue";
import { SUPPORTERS_ASSESSMENT } from "@/modules/supporters/services/router/routes-names";
import { ICategory } from "@/services/data/category/category.interface";
import { ESupporterInvestingLevelActions } from "@/modules/supporters/services/store/supporter-investing-level/supporter-investing-level-types";
import { ECategoryActions } from "@/services/store/category/category-types";
import { defaultAffiliateId } from "@/services/configs";

import { MessageHandler } from "element-plus";
import { EViralLevelActions } from "@/services/store/viral-level/viral-level-types";
import { SUPPORTER_USER_GROUP_ID } from "@/modules/common/constants";
import LangSwitcher from "@/modules/common/components/lang-switcher/lang-switcher.vue";

let errorMessage: MessageHandler | null = null;

export default defineComponent({
  name: "StartingPoint",

  components: {
    FeatureCard,
    LangSwitcher,
  },

  computed: {
    categories(): Array<ICategory> {
      return this.$store.state.categories.data;
    },

    /**
     * Check if the categories are being loaded.
     */
    isLoading(): boolean {
      return this.$store.state.supporterInvestingLevel.loading;
    },

    hasError(): boolean {
      return (
        !!this.$store.state.supporterInvestingLevel.error ||
        !!this.$store.state.categories.error
      );
    },

    startButtonType(): string {
      return this.hasError ? "default" : "green";
    },

    startButtonText(): string {
      return this.hasError
        ? this.$t("supporters.startingPoint.panel.button.error")
        : this.$t("supporters.startingPoint.panel.button.normal");
    },

    startButtonIcon(): string | null {
      return this.hasError ? "refresh" : null;
    },

    networkSlug(): string | null {
      return this.$route.query.network as string | null;
    },
  },

  watch: {
    hasError(value, oldVal) {
      if (value === oldVal) {
        return;
      }

      if (value === true) {
        this.showErrorMessage();
      } else if (errorMessage !== null) {
        errorMessage.close();
      }
    },
  },

  created() {
    // Clear affiliate navbar as supporter won't have an affiliate association
    this.resetAffiliate();

    // Clear any metadata present for the footer
    this.$store.dispatch(
      ESupporterInvestingLevelActions.REMOVE_METADATA,
      "footerClass",
    );

    // Add network slug metadata, if exists
    if (this.networkSlug) {
      this.$store.dispatch(ESupporterInvestingLevelActions.SET_METADATA, {
        key: "network",
        value: this.networkSlug,
      });
    } else {
      // Clear any network slug present in store
      this.$store.dispatch(
        ESupporterInvestingLevelActions.REMOVE_METADATA,
        "network",
      );
    }
  },

  methods: {
    /**
     * This will clear the affiliate navbar
     */
    resetAffiliate() {
      this.$store.dispatch(
        EViralLevelActions.FETCH_AFFILIATE,
        defaultAffiliateId(),
      );
    },

    showErrorMessage() {
      errorMessage = this.$message({
        message: this.$t("common.errors.alertTitle"),
        type: "error",
        duration: 0,
        customClass: "is-full",
      });
    },

    /**
     * Handle the click on the self assessment button.
     */
    onClickTakeAssessmentButton() {
      if (this.hasError) {
        this.$store.dispatch(ECategoryActions.FETCH, {
          group: SUPPORTER_USER_GROUP_ID,
        });
        return;
      }

      // This will reset the state of the module in order to
      // have a clean state before the user perform a new
      // self-assessment
      this.$store.dispatch(ESupporterInvestingLevelActions.RESET);
      this.$router.push({ name: SUPPORTERS_ASSESSMENT });
    },
  },
});
</script>

<style lang="scss" scoped>
.starting-point {
  @include container;

  padding: 89px 0 0;

  @include breakpoint-up(md) {
    padding: 57px 0 0;
  }

  @include breakpoint-up(lg) {
    max-width: 811px;
  }
}

.starting-point__feature {
  @include container;

  padding: 34px 12px 28px;

  @include breakpoint-up(md) {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 53px 33px 115px;
  }

  & > .feature-card:not(:last-child) {
    margin-bottom: 36px;

    @include breakpoint-up(md) {
      margin-bottom: 0;
    }
  }
}

.starting-point .starting-point-panel {
  width: 100%;

  text-align: center;

  @include breakpoint-only(xs) {
    border-right: none;
    border-left: none;
  }

  &__title {
    @include grotesk(semiBold);

    padding-top: 7px;
    margin-bottom: 26px;

    font-size: 2rem;
    line-height: 40px;

    @include breakpoint-down(sm) {
      padding-top: 9px;
      margin-bottom: 20px;

      font-size: 1.4667rem;
      line-height: 32px;
    }
  }

  &__content {
    max-width: 603px;
    margin: 0 auto 30px;

    font-size: 1.1333rem;
    line-height: 32px;
    letter-spacing: -0.2px;

    @include breakpoint-down(sm) {
      font-size: 1rem;
      line-height: 25px;
      letter-spacing: 0;
    }
  }

  &__button {
    min-width: 295px;
    margin-bottom: 8px;
  }
}
</style>
