import {
  GenericProvider,
  Provider,
  EProviderFeatures,
} from "@/services/data/generic-provider";
import { baseAPIUrl } from "@/services/utils/utils";
import { IMilestonePlanner } from "@/modules/milestone-planner/services/store/milestone-planner/milestone-planner.types";

@Provider(`${baseAPIUrl}/milestone-planners`, [
  EProviderFeatures.LIST,
  EProviderFeatures.GET,
  EProviderFeatures.PATCH,
])
class MilestonePlannersProvider extends GenericProvider<IMilestonePlanner> {}

export const milestonePlannersProvider = new MilestonePlannersProvider();
