import { AxiosResponse } from "axios";
import { BaseProviderException } from "./base-provider.exception";

/**
 * Exception to be used when a resource isn't found.
 */
export class NotFoundProviderException extends BaseProviderException {
  constructor(response: AxiosResponse, message = "Not Found") {
    super(response, message);
  }
}
