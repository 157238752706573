<template>
  <div
    :class="[
      { 'px-status-badge--no-icon': !icon },
      { 'px-status-badge--icon-right': iconPosition === 'right' },
    ]"
    :style="badgeStyle"
    class="px-status-badge"
  >
    <PxIcon
      v-if="icon"
      :name="icon"
      :size="iconSize"
      class="px-status-badge__icon"
    />
    <p :style="labelStyle" class="px-status-badge__label">
      {{ label }}
    </p>
    <slot name="additionalContent" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "PxStatusBadge",

  props: {
    label: {
      required: true,
      type: String,
    },

    labelSize: {
      type: Number,
      default: 12,
    },

    backgroundColor: {
      type: String,
      default: "#000",
    },

    labelColor: {
      type: String,
      default: "#fff",
    },

    borderColor: {
      type: String as () => string | null,
      default: null,
    },

    icon: {
      type: String,
      default: "",
    },

    iconSize: {
      type: [Number, String],
      default: 20,
    },

    uppercase: {
      type: Boolean,
      default: true,
    },

    weight: {
      type: Number,
      default: 700,
    },

    iconPosition: {
      type: String,
      default: "left",
      validator: (value: string) => ["left", "right"].includes(value),
    },

    borderRadius: {
      type: Number,
      default: 100,
    },

    boxShadow: {
      type: String as () => string | null,
      default: null,
    },
  },

  computed: {
    badgeStyle(): { [key: string]: string | number } {
      return {
        backgroundColor: this.backgroundColor,
        border: this.borderColor ? `1px solid ${this.borderColor}` : 0,
        textTransform: this.uppercase ? "uppercase" : "none",
        fontWeight: this.weight,
        borderRadius: `${this.borderRadius}px`,
        boxShadow: this.boxShadow || "",
      };
    },

    labelStyle(): { [key: string]: string | number } {
      return { color: this.labelColor, fontSize: `${this.labelSize}px` };
    },
  },
});
</script>

<style lang="scss" scoped>
.px-status-badge {
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;

  &__label {
    font-weight: inherit;
    margin: 0 8px 0 0;
    line-height: 1;
  }

  &--no-icon {
    padding: 4px 0;

    .px-status-badge__label {
      margin: 0;
    }
  }

  &--icon-right {
    flex-direction: row-reverse;

    .px-status-badge__label {
      margin: 0;
    }
  }
}
</style>
