<template>
  <MatchingCardWrapper class="matching-placeholder__wrapper">
    <template #header>
      <ContentPlaceholders :rounded="true">
        <ContentPlaceholdersText :lines="1" class="is-pill" />
      </ContentPlaceholders>
    </template>
    <template #body>
      <div class="matching-placeholder__container">
        <ContentPlaceholders :rounded="true">
          <ContentPlaceholdersHeading :img="true" />
        </ContentPlaceholders>
        <ContentPlaceholders :rounded="true">
          <ContentPlaceholdersText
            :lines="1"
            class="matching-placeholder__description"
          />
        </ContentPlaceholders>
      </div>
    </template>
  </MatchingCardWrapper>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import MatchingCardWrapper from "@/components/matching-card/matching-card-wrapper.vue";

export default defineComponent({
  name: "MatchingCardPlaceholder",

  components: {
    MatchingCardWrapper,
  },
});
</script>

<style lang="scss" scoped>
.matching-placeholder__wrapper {
  overflow: hidden;
  pointer-events: none;
  user-select: none;

  :deep() .matching-card__body {
    height: 274px;
    padding: 44px 14px 13px;
  }
}

.matching-placeholder__container {
  display: grid;
  grid-gap: 44px;
  align-items: center;
}

.is-pill {
  position: absolute;
  top: 0;
  right: 0;
  z-index: z("default");

  :deep() .vue-content-placeholders-text__line {
    width: 61px;
    height: 34px;
    background: rgba($manatee, 0.4);
    border-radius: 0 4px 0 12px;
  }
}

.matching-placeholder__description {
  width: 217px;
  margin: 0 auto;

  :deep() .vue-content-placeholders-text__line {
    width: 100%;
    margin: 0;
    background: rgba($pale-lilac, 0.5);
  }
}

.matching-placeholder__container :deep() {
  .vue-content-placeholders-heading {
    display: grid;
    grid-gap: 20px;
    justify-items: center;

    &__img {
      width: 50px;
      height: 50px;
      margin: 0;
      background: $athens-gray;
    }

    &__content {
      display: grid;
      grid-gap: 12px;
      justify-items: center;
    }

    &__title {
      width: 150px;
      height: 18px;
      margin: 0;
      background: rgba($manatee, 0.4);
      border-radius: 3px;
    }

    &__subtitle {
      width: 103px;
      height: 18px;
      margin: 0;
      background: rgba($pale-lilac, 0.5);
      border-radius: 3px;
    }
  }
}
</style>
