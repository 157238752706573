<template>
  <ElDialog
    ref="modal"
    v-model="innerVisibility"
    :before-close="beforeCloseHandler"
    :title="$t(`profile.supporter.offerings.modal.title`)"
    class="offerings-edit-modal el-dialog--px-10"
    width="904px"
  >
    <DialogConfirmation ref="dialog-confirmation" />
    <PxModalMessage :visible="hasServerErrors" />
    <OfferingsForm
      v-if="innerVisibility"
      ref="form"
      v-model:form-changes="formChanges"
      v-model:has-errors="hasServerErrors"
    />
    <template #footer>
      <div>
        <div class="offerings-edit-modal__tip">
          <PxIcon :size="14" name="information--light-gray" />
          {{ $t("profile.supporter.offerings.modal.tip") }}
        </div>
      </div>
    </template>
  </ElDialog>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import OfferingsForm from "@/modules/profile/components/offerings-form/offerings-form.vue";
import DialogConfirmationMixin from "@/mixins/dialog-confirmation.mixin";
import { IDialogConfirmationOptions } from "@/components/dialog-confirmation/dialog-confirmation.types";

export default defineComponent({
  name: "OfferingsEditModal",

  components: {
    OfferingsForm,
  },

  mixins: [DialogConfirmationMixin],

  props: {
    visibility: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      formChanges: 0,
      innerVisibility: false,
      hasServerErrors: false,
    };
  },

  computed: {
    hasFormChanged(): boolean {
      return !!this.formChanges;
    },

    dialogConfirmationOptions(): IDialogConfirmationOptions {
      return {
        message: this.$t("common.dialogConfirmation.discardMessage"),
        confirmFn: () => this.onConfirmCancel(),
      };
    },
  },

  watch: {
    visibility: {
      immediate: true,
      async handler(newVal) {
        this.innerVisibility = newVal;

        if (!newVal) {
          return;
        }
      },
    },

    innerVisibility(newVal, oldVal) {
      if (newVal === oldVal) {
        return;
      }

      if (!newVal) {
        this.$emit("update:visibility", newVal);
      }
    },
  },

  methods: {
    onConfirmCancel() {
      this.innerVisibility = false;
      this.formChanges = 0;
    },

    beforeCloseHandler() {
      if (this.hasFormChanged) {
        this.showConfirmOverlay(this.dialogConfirmationOptions);
      } else {
        this.innerVisibility = false;
      }
    },
  },
});
</script>

<style lang="scss">
.offerings-edit-modal {
  .el-dialog__body {
    padding-bottom: 5px;
  }

  .el-dialog__footer::before {
    display: none;
  }
}

.offerings-edit-modal__tip {
  @include grotesk(regular, italic);

  display: flex;
  flex-flow: row wrap;

  font-size: 13px;
  line-height: 18px;
  color: $manatee;
  text-align: left;

  .px-icon {
    margin-right: 5px;
  }
}
</style>
