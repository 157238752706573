import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CollapsibleQuestion = _resolveComponent("CollapsibleQuestion")!
  const _component_ElCollapse = _resolveComponent("ElCollapse")!
  const _component_ElDialog = _resolveComponent("ElDialog")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([{ 'questions-panel--scroll': !_ctx.scrollAtTop }, "questions-panel"])
  }, [
    _createElementVNode("div", {
      ref: "panel",
      class: "questions-panel__wrapper",
      onScroll: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.checkScroll && _ctx.checkScroll(...args)))
    }, [
      _createVNode(_component_ElCollapse, {
        "model-value": _ctx.questions[0].id,
        accordion: true
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.questions, (question, index) => {
            return (_openBlock(), _createElementBlock(_Fragment, null, [
              (question.id)
                ? (_openBlock(), _createBlock(_component_CollapsibleQuestion, {
                    key: question.id,
                    "grouped-options": false,
                    question: question,
                    response: _ctx.currentResponses[index],
                    "show-success-state": true,
                    onHandleNewResponse: _ctx.updateResponses,
                    onShowInstructionsModal: _ctx.openModal
                  }, null, 8, ["question", "response", "onHandleNewResponse", "onShowInstructionsModal"]))
                : _createCommentVNode("", true)
            ], 64))
          }), 256))
        ]),
        _: 1
      }, 8, ["model-value"])
    ], 544),
    _createVNode(_component_ElDialog, {
      modelValue: _ctx.showModal,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.showModal) = $event)),
      "append-to-body": true,
      title: _ctx.modalTitle,
      class: "questions-panel__modal",
      top: "0"
    }, {
      default: _withCtx(() => [
        _createElementVNode("p", null, _toDisplayString(_ctx.modalText), 1)
      ]),
      _: 1
    }, 8, ["modelValue", "title"])
  ], 2))
}