<template>
  <div class="lists-detail page-body">
    <AccessListGuard
      v-show="!isDetailLoading"
      :requires-passcode="listRequiresPasscode"
    />
    <div
      v-loading="isLoading"
      :class="{ 'is-loading page-full-height': isLoading }"
      class="page-container"
      element-loading-background="#fafafa"
    >
      <PxButton
        v-if="$user.isLogged()"
        class="el-button--small lists-detail__back-button"
        type="link"
        @click="backToListsClickHandler"
      >
        {{ $t("companyLists.detail.backToDirectory") }}
      </PxButton>
      <ListDetailPanel
        v-show="!isLoading"
        :members="memberCardList"
        :value="currentDetailList"
      />
      <PxResponsiveBitmap
        v-if="false"
        :link="listPlaceholderBackground"
        alt
        class="form-presentation-layout__description-image"
      />
      <ListManagementBar
        v-if="isAuthUser"
        :available-items="listManagementItems"
        :edited-list="currentDetailList"
      />
      <MatchingConfirmModal
        v-if="isMatchingConfirmModalVisible"
        v-model:visibility="isMatchingConfirmModalVisible"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import AccessListGuard from "@/modules/company-lists/components/access-list-guard/access-list-guard.vue";
import ListDetailPanel from "@/modules/company-lists/components/list-detail-panel/list-detail-panel.vue";
import ListManagementBar from "@/modules/company-lists/components/list-management/list-management-bar.vue";
import MatchingConfirmModal from "@/modules/matching/components/matching-confirm-modal.vue";

import { ROUTE_COMPANY_LISTS_DIRECTORY } from "@/modules/company-lists/services/router/routes-names";
import { ICompanyList } from "@/modules/company-lists/services/data/company-list/company-list.interface";
import { IMatchingCard } from "@/modules/matching/services/data/matching-card/matching-card.interface";
import { mapMatchingScoresToCards } from "@/utils/matching";
import { CompanyListState } from "@/modules/company-lists/services/store/company-list/company-list.modules";
import { HOME } from "@/services/router/router-names";
import { AxiosError } from "axios";
import { CompanySharedListState } from "@/modules/company-lists/services/store/company-shared-list/company-shared-list.module";
import { IAssessment } from "@/services/data/assessment/assessment.interface";
import { ILevel } from "@/services/data/level/level.interface";

export default defineComponent({
  name: "ListsDetail",

  components: {
    ListDetailPanel,
    ListManagementBar,
    AccessListGuard,
    MatchingConfirmModal,
  },

  data() {
    return {
      latestAssessment: null as IAssessment | null,
      hasLocation: false,
      hasSectors: false,
      isMatchingConfirmModalVisible: false,
    };
  },

  computed: {
    listPlaceholderBackground() {
      return this.$tm("companyLists.detail.placeholderBackground") as {
        original: string;
        retina: string;
      };
    },
    /**
     * True if we are loading data from the API.
     */
    isLoading(): boolean {
      return (
        this.$store.get(CompanyListState.Getter.IS_LOADING) ||
        this.$store.get(CompanySharedListState.Getter.IS_LOADING)
      );
    },

    isDetailLoading(): boolean {
      return this.$store.get(CompanyListState.Getter.IS_DETAIL_LOADING);
    },

    currentDetailList(): ICompanyList {
      return this.$store.get(CompanyListState.Getter.VALUE);
    },

    uidFromParams(): string {
      return this.$route.params.uid as string;
    },

    selectionCompanyList(): Array<string> {
      return this.$store.get("listManagement.data.companies") || [];
    },

    hasSelectedCompanies(): boolean {
      return this.selectionCompanyList.length > 0;
    },

    selectedMembersCopy(): string {
      if (!this.hasSelectedCompanies) {
        return "";
      }

      return this.$tc(
        "matching.matchingList.memberSelected",
        this.selectionCompanyList.length,
        { number: this.selectionCompanyList.length },
      );
    },

    memberCardList(): Array<IMatchingCard> {
      return mapMatchingScoresToCards(this.currentDetailList?.companies || []);
    },

    listManagementItems(): Array<string> {
      return this.mapListManagementItems();
    },

    listInStore(): null | ICompanyList {
      return this.$store.get(CompanyListState.Getter.VALUE);
    },

    error(): null | AxiosError {
      return this.$store.get(CompanyListState.Getter.ERROR);
    },

    isAuthUser(): boolean {
      return this.$user.isLogged();
    },

    listRequiresPasscode(): boolean {
      return (
        this.error?.response?.status === 403 &&
        this.error?.response?.data?.errors?.code?.includes("passcode")
      );
    },

    latestAssessmentLevel(): ILevel | null {
      return this.latestAssessment?.level || null;
    },

    hasAssessmentLevel(): boolean {
      return this.latestAssessmentLevel !== null;
    },

    hasMatchingMandatoryData(): boolean {
      return (
        !!this.hasAssessmentLevel && !!this.hasLocation && !!this.hasSectors
      );
    },
  },

  watch: {
    /**
     * When the list doesn't exists redirect home and show a temporary error message
     */
    error(newVal: null | AxiosError) {
      if (!newVal) {
        return;
      }

      if (newVal.response?.status === 404) {
        this.$message({
          message: this.$t("companyLists.errors.notFound"),
          type: "error",
          customClass: "is-full",
        });
        this.$router.push({ name: HOME });
      }
    },
  },

  created() {
    this.latestAssessment = this.$store.get("auth/latestAssessment/data");

    if (this.$user.isLogged()) {
      this.prefillAuthData();

      // Matching modal should be visible when the user is an entrepreneur and has not level OR location OR sectors defined
      this.isMatchingConfirmModalVisible =
        !this.hasMatchingMandatoryData && this.$user.isEntrepreneur();
    }
  },

  beforeUnmount() {
    this.latestAssessment = null;
  },

  methods: {
    prefillAuthData() {
      this.hasLocation =
        !!this.$store.get("auth/company.data").locations.length;
      this.hasSectors = !!this.$store.get("auth/company.data").sectors.length;
    },

    backToListsClickHandler() {
      this.$router.push({
        name: ROUTE_COMPANY_LISTS_DIRECTORY,
      });
    },

    mapListManagementItems(): Array<string> {
      const matchingLoadedData = (
        this.memberCardList || ([] as Array<string>)
      ).map((card: IMatchingCard) => card.uid) as Array<string>;

      // Match matching score to company ids
      return matchingLoadedData.reduce(
        (accumulator: Array<string>, cardUid: string) => {
          return [...accumulator, cardUid];
        },
        [] as string[],
      );
    },
  },
});
</script>

<style lang="scss" scoped>
.lists-detail {
  padding: 32px 0 20px;

  &.is-loading {
    z-index: z("floaters") - 1;
    min-height: calc(100vh - 270px);
  }
}

.lists-detail .page-container {
  // Needed for loading mask:
  min-height: calc(100vh - 270px);
}

.lists-detail__back-button {
  height: 46px;
  padding-top: 0;
  padding-bottom: 10px;
  padding-left: 16px;

  :deep() span {
    position: relative;
    top: -1px;
    letter-spacing: 0.3px;
  }
}
</style>
