<template>
  <div class="list-detail-panel-header">
    <div class="list-detail-panel-header__wrapper">
      <div class="list-detail-panel-header__content">
        <div class="list-detail-panel-header__top">
          <h3 class="list-detail-panel-header__title" v-text="title" />
          <PxStatusBadge v-if="isSmartList" v-bind="smartListBadge" />
        </div>
        <p class="list-detail-panel-header__description" v-text="description" />
      </div>
      <div class="list-detail-panel-header__column-heading">
        <h4 class="list-detail-panel-header__heading">
          <PxIcon :size="14" name="hashtag" />
          {{ $t("companyLists.list.members") }}
        </h4>
        <h3 class="list-detail-panel-header__subtitle">
          {{
            $tc(
              `companyLists.list.membersCount[${listUserType}]`,
              memberQuantity,
            )
          }}
        </h3>
      </div>
      <div v-if="isOwner" class="list-detail-panel-header__column-heading">
        <h4 class="list-detail-panel-header__heading">
          <PxIcon :size="14" name="arrow-share" />
          {{ $t("companyLists.list.sharedWith") }}
        </h4>
        <PxAvatarSet
          v-if="userAvatars.length"
          :items="userAvatars"
          :size="21"
          :visible-limit="5"
          class="list-detail-panel-header__logo-set"
        />
        <h3 v-else class="list-detail-panel-header__tip">
          <PxIcon :size="16" name="lock" />
          {{ $t("companyLists.list.sharedEmpty") }}
        </h3>
      </div>
      <div
        v-if="!!value && $user.isLogged()"
        class="list-detail-panel-header__actions"
      >
        <ListShare
          v-if="isOwner"
          :value="value"
          class="list-detail-panel-header__actions-item"
        />
        <ListOptions
          :value="value"
          class="list-detail-panel-header__actions-item"
        />
      </div>
    </div>
    <div v-if="!$user.isLogged()" class="list-detail-panel-header__ribbon">
      <i18n-t
        class="list-detail-panel-header__ribbon-text"
        keypath="companyLists.detail.visitor.teaser"
        tag="span"
      >
        <template #cta>
          <a
            class="list-detail-panel-header__ribbon-cta"
            @click="joinClickHandler"
          >
            {{ joinCta }}
          </a>
        </template>
      </i18n-t>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import {
  ICompanyList,
  ICompanyOfList,
} from "@/modules/company-lists/services/data/company-list/company-list.interface";
import { IAvatarSetItem } from "@/components/px-avatar/px-avatar-set.vue";
import ListOptions from "@/modules/company-lists/components/list-options/list-options.vue";
import ListShare from "@/modules/company-lists/components/list-share/list-share.vue";
import PxStatusBadge from "@/components/px-status-badge/px-status-badge.vue";

export default defineComponent({
  name: "ListDetailPanelHeader",

  components: {
    ListOptions,
    ListShare,
    PxStatusBadge,
  },

  static: {
    smartListIcon: {
      backgroundColor: "rgba(241, 182, 0, 0.1)",
      labelColor: "#f1b600",
      icon: "stars",
    },
  },

  props: {
    value: {
      type: Object as () => ICompanyList | null,
      default: null,
    },

    listUserType: {
      type: Number,
      required: true,
    },
  },

  computed: {
    smartListBadge(): any {
      return {
        ...this.$options.static.smartListIcon,
        label: this.$t("companyLists.smartList.label"),
      };
    },

    joinCta() {
      return this.$t("companyLists.detail.visitor.cta");
    },

    isOwner(): boolean {
      return !!this.value?.is_owner;
    },

    isSmartList(): boolean {
      return this.value ? this.value.is_smart_list : false;
    },

    userAvatars(): Array<IAvatarSetItem> {
      return (this.value?.invited_users || []).map((user) => ({
        name: user.name,
        logo: user.photo || "",
      }));
    },

    title(): string {
      return this.value?.title || "";
    },

    description(): string {
      return this.value?.description || "";
    },

    companies(): Array<ICompanyOfList> {
      return this.value?.companies || [];
    },

    memberQuantity(): number {
      return this.value?.companyCount || this.companies.length;
    },
  },

  methods: {
    joinClickHandler() {
      this.$emit("register");
    },
  },
});
</script>

<style lang="scss" scoped>
.list-detail-panel-header__wrapper {
  position: relative;
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;

  min-height: 146px;
  padding: 33px 32px 53px;

  background-image: linear-gradient(to bottom, $white 35%, $alabaster);
  border-bottom: solid 1px $pale-grey;
  box-shadow: 0 4px 6px -2px rgba(0, 0, 0, 0.08);
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.list-detail-panel-header__content {
  position: relative;
  top: -2px;
  flex: 0 1 537px;
  max-width: 537px;

  &:not(:last-child) {
    margin-right: 72px;
  }
}

.list-detail-panel-header__top {
  display: flex;
  align-items: center;
  gap: 9px;

  :deep() .px-status-badge {
    min-width: 98px;
  }
}

.list-detail-panel-header__title {
  @include grotesk(semiBold);

  margin: 0;
  overflow: hidden;
  font-size: 19px;
  line-height: 23px;
  text-overflow: ellipsis;
  letter-spacing: -0.2px;
  white-space: nowrap;
}

.list-detail-panel-header__description {
  @include multiLineEllipsis(
    $font-size: 15px,
    $line-height: 25px,
    $lines-to-show: 2
  );

  margin: 8px auto 0;
  letter-spacing: -0.3px;
  word-break: break-all;
}

.list-detail-panel-header__column-heading {
  flex: 0 0 110px;
  padding-top: 4px;
  margin: 0 23px 0 20px;

  &:last-child {
    margin: 0;
  }
}

.list-detail-panel-header__heading {
  @include grotesk(semiBold);

  padding-bottom: 5px;
  margin-bottom: auto;
  font-size: 12px;
  color: rgba($manatee, 0.8);
  text-transform: uppercase;

  :deep() .px-icon {
    margin-right: 2px;
    vertical-align: -3px;
  }
}

.list-detail-panel-header__subtitle {
  @include grotesk(medium);

  margin: auto;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: -0.2px;
}

.list-detail-panel-header__heading + .list-detail-panel-header__subtitle {
  margin-top: 8px;
}

.list-detail-panel-header__tip {
  @include grotesk(medium);

  margin: auto;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.2px;

  :deep() .px-icon {
    margin-right: 1px;
    margin-left: 2px;
    vertical-align: -3px;
  }
}

.list-detail-panel-header__heading + .list-detail-panel-header__tip {
  position: relative;
  left: -3px;
  margin-top: 9px;
}

.list-detail-panel-header__logo-set {
  position: relative;
  left: -2px;
  padding-top: 8px;
}

.list-detail-panel-header__actions {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  padding-top: 14px;
  margin-right: 0;
  margin-left: auto;

  :deep() &-item:not(:first-child) {
    margin-left: 12px;
  }
}

.list-detail-panel-header__shared-cta {
  @include grotesk(bold);

  min-width: 72px;
  padding: 0 16px 1px 15px;
  font-size: 13px;
  line-height: 16px;

  :deep() .px-icon {
    margin-top: 1px;
    margin-right: 5px;
  }
}

.list-detail-panel-header__options-cta {
  min-width: 36px;
  padding: 0;
}

.list-detail-panel-header__ribbon {
  position: absolute;
  top: 32px;
  right: -9px;
  width: 290px;
  padding: 10px 15px 12px;
  background-color: $parakeetblue;
  border: solid 1px rgba(41, 114, 202, 0.6);
  border-radius: 4px;

  &::before {
    position: absolute;
    top: -3px;
    right: 0;
    z-index: -1;
    width: 7px;
    height: 7px;

    content: "";
    background-color: $bluish;
    transform: skewY(40deg);
  }
}

.list-detail-panel-header__ribbon-text {
  @include grotesk(semiBold);

  font-size: 16px;
  line-height: 23px;
  color: $white;
}

.list-detail-panel-header__ribbon-cta {
  @extend .list-detail-panel-header__ribbon-text;

  // For pixel-perfect alignment:
  position: relative;
  left: 2px;
  font-size: 15px;
  // override default anchor style without href
  text-decoration: underline !important;
  white-space: nowrap;
  cursor: pointer;
}
</style>
