<template>
  <ElTooltip
    v-if="$screen.mdUp"
    ref="tooltip"
    :content="tooltipCopy"
    :enterable="false"
    :show-arrow="false"
    :auto-close="1000"
    trigger="click"
    placement="top-end"
    popper-class="clipboard-button__tooltip el-abaca-tooltip"
  >
    <PxButton
      :icon="buttonIcon"
      class="clipboard-button"
      size="small"
      type="primary"
      :label="buttonLabel"
      @click="onClickButtonHandler"
    >
      <input ref="clipboard" :value="clipboard" type="hidden" />
    </PxButton>
  </ElTooltip>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "ClipboardButton",

  props: {
    clipboard: {
      type: [String, Number],
      required: true,
    },
    buttonLabel: {
      type: String,
      required: true,
    },
    buttonIcon: {
      type: String,
      default: "link--white",
    },
    tooltipCopy: {
      type: String,
      required: true,
    },
  },

  methods: {
    copyToClipboard() {
      const clipboardElement = this.$refs.clipboard as HTMLInputElement;
      clipboardElement.setAttribute("type", "text");
      clipboardElement.select();
      document.execCommand("copy");
      clipboardElement.setAttribute("type", "hidden");
      (window as any).getSelection().removeAllRanges();
    },

    onClickButtonHandler() {
      // Copy to clipboard:
      // As seen at: https://codepen.io/PJCHENder/pen/jamJpj
      this.copyToClipboard();
    },
  },
});
</script>

<style lang="scss">
.clipboard-button__tooltip {
  max-width: 278px;
  padding: 3px 10px;
  text-transform: none;
  letter-spacing: -0.2px;
}
</style>
