import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, withCtx as _withCtx } from "vue"

const _hoisted_1 = ["textContent"]
const _hoisted_2 = { class: "share-results-modal__body" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DialogConfirmation = _resolveComponent("DialogConfirmation")!
  const _component_PxModalMessage = _resolveComponent("PxModalMessage")!
  const _component_ShareModalContent = _resolveComponent("ShareModalContent")!
  const _component_ShareModalSettings = _resolveComponent("ShareModalSettings")!
  const _component_ElDialog = _resolveComponent("ElDialog")!

  return (_openBlock(), _createBlock(_component_ElDialog, {
    ref: "modal",
    modelValue: _ctx.innerVisibility,
    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.innerVisibility) = $event)),
    "append-to-body": true,
    "before-close": _ctx.beforeCloseHandler,
    class: _normalizeClass([{ 'share-results-modal--users': !_ctx.settingsVisibility }, "share-results-modal"]),
    title: _ctx.shareModalTitle,
    width: "660px"
  }, {
    header: _withCtx(() => [
      _createElementVNode("span", {
        class: "el-dialog__title",
        textContent: _toDisplayString(_ctx.shareModalTitle)
      }, null, 8, _hoisted_1)
    ]),
    default: _withCtx(() => [
      _createVNode(_component_DialogConfirmation, {
        ref: "dialog-confirmation",
        class: "share-results-modal__overlay-confirm"
      }, null, 512),
      _createVNode(_component_PxModalMessage, { visible: _ctx.hasServerError }, null, 8, ["visible"]),
      _createVNode(_component_PxModalMessage, {
        visible: _ctx.linkWasReset,
        "onUpdate:visible": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.linkWasReset) = $event)),
        timeout: 5000,
        title: _ctx.$t(`capitalExplorer.shareModal.messages.linkChanged`),
        type: "success"
      }, null, 8, ["visible", "title"]),
      _createVNode(_component_PxModalMessage, {
        visible: _ctx.passcodeWasChanged,
        "onUpdate:visible": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.passcodeWasChanged) = $event)),
        timeout: 5000,
        title: _ctx.$t(`capitalExplorer.shareModal.messages.passcodeChanged`),
        type: "success"
      }, null, 8, ["visible", "title"]),
      _createVNode(_component_PxModalMessage, {
        visible: _ctx.userHasBeenRemoved,
        "onUpdate:visible": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.userHasBeenRemoved) = $event)),
        timeout: 5000,
        title: _ctx.successUserRemovalMessage,
        type: "success"
      }, null, 8, ["visible", "title"]),
      _createVNode(_component_PxModalMessage, {
        visible: _ctx.capitalExplorerHasBeenShared,
        "onUpdate:visible": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.capitalExplorerHasBeenShared) = $event)),
        timeout: 5000,
        title: _ctx.successShareMessage,
        type: "success"
      }, null, 8, ["visible", "title"]),
      _createElementVNode("div", _hoisted_2, [
        (!_ctx.settingsVisibility)
          ? (_openBlock(), _createBlock(_component_ShareModalContent, {
              key: 0,
              "filter-by": _ctx.supporters,
              link: _ctx.shareableLink,
              passcode: _ctx.passcode,
              "share-succeeded": _ctx.capitalExplorerHasBeenShared,
              users: _ctx.users,
              "actions-copy": "capitalExplorer.shareModal.footer",
              "users-copy": "capitalExplorer.shareModal.users",
              onSendEmail: _ctx.sendEmail
            }, null, 8, ["filter-by", "link", "passcode", "share-succeeded", "users", "onSendEmail"]))
          : (_openBlock(), _createBlock(_component_ShareModalSettings, {
              key: 1,
              "is-loading": _ctx.capitalExplorerLoading,
              link: _ctx.shareableLink,
              passcode: _ctx.passcode,
              "passcode-copy": "capitalExplorer.shareModal.settings.passcode",
              onSave: _ctx.saveSettingsHandler
            }, null, 8, ["is-loading", "link", "passcode", "onSave"]))
      ])
    ]),
    _: 1
  }, 8, ["modelValue", "before-close", "class", "title"]))
}