import { IMatchingCriteria } from "@/modules/matching/services/data/matching-score/matching-criteria.interface";
import { IMatchingAnswer } from "@/services/data/matching-questionary/matching-answer.interface";
import { IMatchingQuestion } from "@/services/data/matching-questionary/matching-question.interface";
import moment from "moment";
import { defineComponent } from "vue";

export default defineComponent({
  methods: {
    getAnswer(criteria: IMatchingCriteria): string {
      if (criteria.answers.length > 0) {
        return criteria.answers.reduce(
          (previousValue: string, currentValue: IMatchingAnswer) => {
            const question = criteria.question as IMatchingQuestion;
            const questionAnswer = question.answers.find(
              (answer: IMatchingAnswer) => answer.id === currentValue.id,
            );
            const separator = previousValue !== "" ? ", " : "";

            return questionAnswer
              ? `${previousValue}${separator}${questionAnswer.value}`
              : previousValue;
          },
          "" as string,
        );
      }

      const answerValue = criteria.desired as any;

      // Date format
      if (answerValue.date) {
        return moment(answerValue.date, "YYYY-MM-DD").format("D MMMM YYYY");
      }

      // Numeric format
      if (
        (answerValue.min !== undefined && answerValue.max !== undefined) ||
        answerValue.value !== undefined
      ) {
        const question = criteria.question as IMatchingQuestion;
        const numericCurrency = question.question_type.meta.currency ? "$" : "";

        return answerValue.min !== undefined && answerValue.max !== undefined
          ? `${numericCurrency}${(
              answerValue.min as number
            ).toLocaleString()} - ${numericCurrency}${(
              answerValue.max as number
            ).toLocaleString()}`
          : `${numericCurrency}${(
              answerValue.value as number
            ).toLocaleString()}`;
      }

      return answerValue[Object.keys(answerValue)[0]];
    },
  },
});
