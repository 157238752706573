import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createElementVNode as _createElementVNode, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-2c7c15fa"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "criteria-multi-selector__container" }
const _hoisted_2 = { class: "criteria-multi-selector__common-container" }
const _hoisted_3 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MultiSelectorEntry = _resolveComponent("MultiSelectorEntry")!
  const _component_PxIcon = _resolveComponent("PxIcon")!
  const _component_CriteriaMultiSelectorOptionPanel = _resolveComponent("CriteriaMultiSelectorOptionPanel")!
  const _component_ElSelect = _resolveComponent("ElSelect")!
  const _component_MultiSelectorWrapper = _resolveComponent("MultiSelectorWrapper")!
  const _component_i18n_t = _resolveComponent("i18n-t")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_MultiSelectorWrapper, {
      label: _ctx.label,
      "no-label": _ctx.noLabel,
      tip: _ctx.tip
    }, {
      selected: _withCtx(() => [
        _createElementVNode("div", null, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.entryItems, (item) => {
            return (_openBlock(), _createBlock(_component_MultiSelectorEntry, {
              key: item.value,
              item: item,
              "show-parent-label": true,
              onEdit: _ctx.openQuestionPanel,
              onRemove: _ctx.onRemoveItemClick
            }, null, 8, ["item", "onEdit", "onRemove"]))
          }), 128))
        ])
      ]),
      input: _withCtx(() => [
        _createElementVNode("div", null, [
          _createVNode(_component_ElSelect, {
            ref: "select",
            modelValue: _ctx.innerSelectedItem,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.innerSelectedItem) = $event)),
            "filter-method": _ctx.searchByQuery,
            filterable: true,
            placeholder: _ctx.$t(_ctx.placeholder),
            "popper-append-to-body": false,
            "automatic-dropdown": "",
            class: "multi-selector__select",
            "popper-class": "criteria-multi-selector__popper",
            onBlur: _ctx.blurHandler,
            onFocus: _ctx.focusHandler,
            onVisibleChange: _ctx.handleCriteriaPanelVisibleChange
          }, {
            prefix: _withCtx(() => [
              _createVNode(_component_PxIcon, {
                size: 24,
                name: _ctx.inputIcon
              }, null, 8, ["name"])
            ]),
            default: _withCtx(() => [
              _createVNode(_component_CriteriaMultiSelectorOptionPanel, {
                "highlight-item": _ctx.highlightedItem,
                "onUpdate:highlightItem": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.highlightedItem) = $event)),
                "highlight-text": _ctx.searchQuery,
                items: _ctx.searchedItems,
                "selected-items": _ctx.innerValue,
                onUpdate: _ctx.addSelectedItem
              }, null, 8, ["highlight-item", "highlight-text", "items", "selected-items", "onUpdate"])
            ]),
            _: 1
          }, 8, ["modelValue", "filter-method", "placeholder", "onBlur", "onFocus", "onVisibleChange"])
        ])
      ]),
      _: 1
    }, 8, ["label", "no-label", "tip"]),
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.mostCommonCriteria, (category) => {
        return (_openBlock(), _createElementBlock("div", {
          key: `criteria-category--${category.label}`
        }, [
          _createVNode(_component_i18n_t, {
            class: "criteria-multi-selector__common-label",
            keypath: "supporters.form.additionalInterests.mostCommonCriteriaTitle",
            tag: "p"
          }, {
            category: _withCtx(() => [
              _createTextVNode(_toDisplayString(category.label), 1)
            ]),
            _: 2
          }, 1024),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(category.children, (item) => {
            return (_openBlock(), _createElementBlock("span", {
              key: `criteria-item--${item.label}`,
              class: "criteria-multi-selector__common-item",
              onClick: ($event: any) => (_ctx.openQuestionPanel(+item.value))
            }, _toDisplayString(item.label), 9, _hoisted_3))
          }), 128))
        ]))
      }), 128))
    ])
  ]))
}