import { IBaseState } from "@/services/store/store-types";
import { ICompany } from "@/modules/profile/services/data/company/company.types";

/**
 * Structure of the store of the company profile module.
 */
export interface IProfileCompanyState extends IBaseState {
  data: ICompany | null;
}

export enum EGenericCompanyActions {
  FETCH = "company/fetch",
  PATCH = "company/patch",
  RESET = "company/reset",
}

export enum EGenericCompanyMutations {
  SET_LOADING = "SET_LOADING",
  SET_ERROR = "SET_ERROR",
  SET_DATA = "SET_DATA",
}
