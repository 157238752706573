<template>
  <div class="results-report">
    <div
      v-for="category in categories"
      :key="category.id"
      :class="{
        'results-report-entry--not-achieved': levelForCategory(category) === 0,
      }"
      class="results-report-entry"
    >
      <div class="results-report-entry__header">
        <PxBadge
          :color="getCategoryColor(category)"
          :value="levelForCategory(category)"
          class="results-report-entry__level"
        />
        <span class="results-report-entry__name">
          {{ category.name }}
        </span>
      </div>
      <div class="results-report-entry__description">
        {{ selectedLevelDescription(category) }}
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import {
  ICategory,
  ICategoryDetail,
} from "@/services/data/category/category.interface";
import { ICategoryLevelSelection } from "@/services/data/viral-level/viral-level.interface";
import { IAssessmentLevel } from "@/services/data/assessment/assessment.interface";

export default defineComponent({
  name: "ResultsReport",

  props: {
    levels: {
      type: Array as () => IAssessmentLevel[],
      required: true,
    },
    categories: {
      type: Array as () => ICategory[],
      required: true,
    },
  },

  methods: {
    /**
     * Get the description for the category on the selected level.
     */
    selectedLevelDescription(category: ICategory): string {
      const categoryLevel = this.levelForCategory(category);
      const details = category.categoryDetails.find(
        (entry: ICategoryDetail) => entry.level.value === categoryLevel,
      ) as ICategoryDetail;

      if (!details) {
        return this.$t(
          "selfAssessment.results.detailsPanel.placeholderNotAchieved",
        ) as string;
      }

      return details.description;
    },

    /**
     * Get the level for a given category.
     */
    levelForCategory(category: ICategory): number {
      const levelSection = this.levels.find(
        (record: IAssessmentLevel) => record.category === category.id,
      ) as ICategoryLevelSelection;
      return (
        levelSection ? (levelSection.level ? levelSection.level : 0) : 0
      ) as number;
    },

    /**
     * Get the color for the given category.
     */
    getCategoryColor(category: ICategory) {
      const level = this.levelForCategory(category);
      const color: string = level !== 0 ? category.color : "f0f1f5";
      return `#${color}`;
    },
  },
});
</script>

<style lang="scss" scoped>
.results-report {
  padding: 0 6px;

  @include breakpoint-up(md) {
    padding: 32px 0 0 69px;
  }
}

.results-report-entry {
  display: inline-block;
  width: 220px;
  margin-right: 40px;
  margin-bottom: 25px;
  vertical-align: top;

  @include breakpoint-down(sm) {
    width: 100%;
    padding-right: 3px;
    margin: 0 0 19px -1px;
  }

  &::after {
    display: block;
    width: 100%;
    height: 1px;
    margin-top: 18px;

    content: "";

    background-color: $athens-gray;

    @include breakpoint-up(md) {
      display: none;
    }
  }

  &:last-child {
    margin-bottom: 0;

    &::after {
      display: none;
    }
  }

  &__header {
    display: block;
    margin-bottom: 7px;

    @include breakpoint-up(md) {
      margin-bottom: 5px;
    }
  }

  &__level {
    top: 6px;

    @include breakpoint-up(md) {
      top: 5px;
    }
  }

  &__name {
    @include grotesk(semiBold);

    padding-left: 7px;

    font-size: 1rem;

    @include breakpoint-up(md) {
      padding-left: 8px;

      font-size: 0.9333rem;
    }
  }

  &__description {
    font-size: 1rem;
    line-height: 1.6667rem;
    letter-spacing: -0.19px;

    @include breakpoint-up(md) {
      font-size: 0.9333rem;
    }
  }
}

.results-report-entry--not-achieved {
  .results-report-entry__name {
    color: $manatee;
  }

  .results-report-entry__description {
    @include grotesk(regular, italic);

    position: relative;
    top: 1px;
    left: 1px;
  }

  .el-badge :deep() .el-badge__content {
    color: transparent !important;
  }
}
</style>
