<template>
  <div v-loading="isLoading" class="team-member-mandatory-data">
    <ElForm
      ref="mandatoryDataForm"
      :model="formData"
      :rules="rules"
      class="team-member-mandatory-data__form"
      data-testid="team-member-mandatory-data-form"
      @validate="checkFieldsValidState"
    >
      <ElFormItem
        :label="fieldsLabel.firstName"
        class="team-member-mandatory-data__first-name"
        prop="first_name"
      >
        <ElInput
          v-model="formData.first_name"
          data-testid="team-member-mandatory-data-first-name-input"
        />
      </ElFormItem>
      <ElFormItem
        :label="fieldsLabel.lastName"
        class="team-member-mandatory-data__last-name"
        prop="last_name"
      >
        <ElInput
          v-model="formData.last_name"
          data-testid="team-member-mandatory-data-last-name-input"
        />
      </ElFormItem>
      <ElFormItem
        :label="fieldsLabel.email"
        class="team-member-mandatory-data__email"
        prop="email"
      >
        <ElInput
          v-model="formData.email"
          data-testid="team-member-mandatory-data-email-input"
        />
      </ElFormItem>
      <ElFormItem
        :label="fieldsLabel.position"
        class="team-member-mandatory-data__position"
        prop="position"
      >
        <ElInput
          v-model="formData.position"
          data-testid="team-member-mandatory-data-position-input"
        />
      </ElFormItem>
    </ElForm>
    <div class="team-member-mandatory-data__info">
      <PxIcon :size="16" name="icon-information" />
      <p
        data-testid="team-member-mandatory-data-info-message"
        v-html="infoCopy"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import {
  generateEmailValidator,
  allFormFieldsValid,
  generateRequiredValidator,
} from "@/services/errors/validator-generators";

import { ITeamMember } from "@/modules/team-management/services/data/team-members/team-members.interface";
import cloneDeep from "lodash/cloneDeep";
import {
  SUPPORTER_USER_TYPE,
  ENTREPRENEUR_USER_TYPE,
} from "@/modules/authentication/constants";

export interface IFormField {
  fieldValue: any;
  prop: string;
  validateState: string;
  required: boolean;
}

export default defineComponent({
  name: "TeamMemberMandatoryData",

  props: {
    memberInfo: {
      type: Object as () => Partial<ITeamMember> | null,
      default: null,
    },

    memberIndex: {
      type: Number,
      default: 0,
    },

    isLoading: {
      type: Boolean,
      default: false,
    },

    isFromProfile: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      formData: {} as ITeamMember,
      entrepreneurAffiliateRules: {
        first_name: [{ required: false }],
        last_name: [{ required: false }],
        email: generateEmailValidator(
          this,
          "teamManagement.settings.mandatoryData.email",
        ),
        position: [{ required: false }],
      },
      profileRules: {
        first_name: generateRequiredValidator(
          this,
          "teamManagement.settings.mandatoryData.firstName",
        ),
        last_name: generateRequiredValidator(
          this,
          "teamManagement.settings.mandatoryData.lastName",
        ),
        email: generateEmailValidator(
          this,
          "teamManagement.settings.mandatoryData.email",
          true,
        ),
        position: generateRequiredValidator(
          this,
          "teamManagement.settings.mandatoryData.position",
        ),
      },
      submissionDisabled: false,
    };
  },

  computed: {
    fieldsLabel() {
      return this.$tm("teamManagement.settings.mandatoryData") as {
        firstName: string;
        lastName: string;
        email: string;
        position: string;
        info: string[];
        required: string;
      };
    },

    isSupporter(): boolean {
      return this.$user.isSupporter() || this.$user.isPendingSupporter();
    },

    userType(): number {
      return this.isSupporter ? SUPPORTER_USER_TYPE : ENTREPRENEUR_USER_TYPE;
    },

    userTypeCopy(): string {
      return this.$t(
        `teamManagement.settings.mandatoryData.info[${this.userType}]`,
      ) as string;
    },

    infoCopy(): string {
      return this.isFromProfile
        ? this.$t(`teamManagement.settings.mandatoryData.required`)
        : this.userTypeCopy;
    },

    rules(): any {
      return this.isFromProfile || this.isSupporter
        ? this.profileRules
        : this.entrepreneurAffiliateRules;
    },

    formRef(): any {
      return this.$refs.mandatoryDataForm;
    },

    formFields(): IFormField[] {
      return this.formRef.fields as IFormField[];
    },
  },

  watch: {
    memberInfo: {
      deep: true,
      immediate: true,
      handler(newMemberInfo: ITeamMember) {
        this.formData = cloneDeep(newMemberInfo);
      },
    },

    memberIndex: {
      handler() {
        this.checkFieldsValidState();
      },
    },
  },

  mounted() {
    this.checkFieldsValidState();
  },

  methods: {
    checkFieldsValidState() {
      if (this.formRef) {
        const formFields = this.formFields;

        if (this.isFromProfile || this.isSupporter) {
          // When the fields are preffiled with data that already exists
          // Element UI doesn't consider the success state so we need
          // to check if the fields have value and change the state to success
          formFields.forEach((field: IFormField) => {
            if (!!field.fieldValue && field.prop === "email") {
              const matchResult = field.fieldValue.match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
              );

              return (field.validateState = matchResult ? "success" : "error");
            }

            if (
              !!field.fieldValue &&
              field.validateState !== "error" &&
              field.prop !== "email"
            ) {
              return (field.validateState = "success");
            }
          });

          this.submissionDisabled = !allFormFieldsValid(
            this.formRef,
            this.rules,
          );

          this.$emit("form-is-invalid", this.submissionDisabled);
        }

        this.$emit("new-member-info", this.formData);
      }
    },
  },
});
</script>

<style lang="scss" scoped>
.team-member-mandatory-data {
  background-color: $white;

  border: 1px solid $turkish-angora;
  border-radius: 4px;
  box-shadow: 0 4px 8px -4px rgba(139, 143, 161, 0.2);

  &__info {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 6px;

    padding: 0 0 19px 23px;

    p {
      margin-top: 4px;
      margin-right: 2px;
      font-size: 13px;
      color: $ebony-clay;
      font-style: italic;
      letter-spacing: -0.13px;
    }
  }

  &__form :deep() {
    display: grid;
    grid-template-columns: auto auto;
    gap: 13px 16px;

    padding: 24px 24px 7px;

    .el-form-item__label {
      margin-bottom: 3px;
      padding-top: 0;
    }

    .el-input__inner {
      color: $manatee;
      font-size: 14px;
    }

    .el-form-item__error {
      padding-top: 2px;
    }
  }
}
</style>
