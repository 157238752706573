import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, withCtx as _withCtx, createBlock as _createBlock, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-6eeaeed2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "team-members-list-member-entry__wrapper" }
const _hoisted_2 = { class: "team-members-list-member-entry__content" }
const _hoisted_3 = { class: "team-members-list-member-entry__title" }
const _hoisted_4 = {
  key: 0,
  class: "team-members-list-member-entry__subtitle"
}
const _hoisted_5 = {
  key: 0,
  class: "team-members-list-member-entry__actions"
}
const _hoisted_6 = ["textContent"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PxAvatar = _resolveComponent("PxAvatar")!
  const _component_PxIcon = _resolveComponent("PxIcon")!
  const _component_ElTooltip = _resolveComponent("ElTooltip")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([{ 'team-members-list-member-entry--selected': _ctx.isSelected }, "team-members-list-member-entry"])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_PxAvatar, {
        name: _ctx.member.first_name,
        size: 32,
        styling: "neutral",
        type: "round"
      }, null, 8, ["name"]),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.memberName), 1),
        (_ctx.member.email)
          ? (_openBlock(), _createElementBlock("p", _hoisted_4, _toDisplayString(_ctx.member.email), 1))
          : _createCommentVNode("", true)
      ])
    ]),
    (_ctx.showRemoveButton)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          (_ctx.isSelected)
            ? (_openBlock(), _createBlock(_component_ElTooltip, {
                key: 0,
                ref: "remove",
                enterable: false,
                placement: "top",
                "popper-class": "team-members-list-member-entry__tooltip el-abaca-tooltip"
              }, {
                content: _withCtx(() => [
                  _createElementVNode("div", {
                    textContent: _toDisplayString(_ctx.removeCopy)
                  }, null, 8, _hoisted_6)
                ]),
                default: _withCtx(() => [
                  _createElementVNode("div", {
                    class: "team-members-list-member-entry__remove-cta",
                    onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.removeClickHandler && _ctx.removeClickHandler(...args)), ["stop"]))
                  }, [
                    _createVNode(_component_PxIcon, {
                      size: 14,
                      name: "trash--red"
                    }),
                    _createVNode(_component_PxIcon, {
                      size: 14,
                      class: "px-icon--hover",
                      name: "trash"
                    })
                  ])
                ]),
                _: 1
              }, 512))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true)
  ], 2))
}