<template>
  <div class="px-more-list">
    <ElTooltip
      v-if="$screen.mdUp"
      placement="top"
      popper-class="px-more-list__tooltip el-abaca-tooltip"
    >
      <div class="px-more-list__content" @click.stop="onClickMoreHandler">
        <slot name="content" />
      </div>
      <template #content>
        <div>
          <p
            v-for="(value, index) in valuesOnLimit"
            :key="index"
            class="px-more-list__text"
          >
            {{ value }}
          </p>
          <p
            v-if="hasValuesOverLimit"
            class="px-more-list__text-more"
            @click="onClickMoreHandler"
          >
            {{ $t("common.andMoreNumber", { number: valuesOverLimitLength }) }}
          </p>
        </div>
      </template>
    </ElTooltip>
    <div v-else @click.stop="onClickMoreHandler">
      <slot name="content" />
    </div>
    <PxListModal
      ref="pxMoreListModal"
      v-model:items="fullList"
      v-model:visibility="isModalVisible"
      :capitalize="capitalize"
      :title="modalTitle"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { IModalListGroup } from "@/components/px-list-modal/px-list-modal.vue";

export interface IMoreListItem {
  name: string;
  items?: Array<string>;
}

export default defineComponent({
  name: "PxMoreList",

  props: {
    modalTitle: {
      type: String,
      default: "",
    },
    // Optionally specify items
    // used for list modal
    // otherwise fallsback to (values).
    valuesItemList: {
      type: Array as () => IMoreListItem[],
      default: () => [],
    },
    values: {
      type: Array as () => string[],
      default: () => [],
    },
    valuesLimit: {
      type: Number,
      default: 8,
    },
    capitalize: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      isModalVisible: false,
    };
  },

  computed: {
    fullList(): Array<IModalListGroup | string> {
      return this.valuesItemList.length
        ? this.valuesItemList.map((list) => {
            return !list.items ? list.name : (list as IModalListGroup);
          })
        : this.values.length
          ? this.values
          : [];
    },

    fullListCount(): number {
      return this.fullList.reduce((count, list) => {
        count++;

        if (typeof list !== "string" && !!list.items && list.items.length) {
          count += list.items.length;
        }

        return count;
      }, 0);
    },

    hasValuesOverLimit(): boolean {
      return (
        this.fullList.length < this.fullListCount ||
        this.fullListCount > this.valuesLimit
      );
    },

    valuesOnLimit(): string[] {
      return this.hasValuesOverLimit
        ? this.values.slice(0, this.valuesLimit)
        : this.values;
    },

    valuesOverLimitLength(): number {
      return this.fullListCount - this.valuesOnLimit.length;
    },
  },

  methods: {
    onClickMoreHandler() {
      this.isModalVisible = true;
    },
  },
});
</script>

<style lang="scss" scoped>
.px-more-list__content {
  display: inline-block;
}

.px-more-list__text,
.px-more-list__text-more {
  cursor: default;
  user-select: none;
}

.px-more-list__text-more {
  text-transform: none;
  cursor: pointer;
}

.el-abaca-tooltip {
  position: relative;
  z-index: z("floaters") + 1;

  &:hover {
    text-decoration: underline;
  }
}
</style>

<style lang="scss">
.px-more-list__tooltip.el-abaca-tooltip {
  min-width: 184px;
  max-width: 250px;
  padding: 5px 10px;
  text-transform: none;

  p::first-letter {
    text-transform: uppercase;
  }

  /*
   * This will remove the standard behaviour of changing the cursor selector to text selector, even if the tooltips
   * remain with selectable to false
   */
  & > * {
    cursor: default;
  }
}
</style>
