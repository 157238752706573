import {
  GenericProvider,
  EProviderFeatures,
} from "@/services/data/generic-provider";
import { IProfileField } from "@/modules/affiliate-program/services/data/profile-fields/profile-field";

class ProfileFieldProvider extends GenericProvider<IProfileField> {
  constructor() {
    super(`${process.env.VUE_APP_API_URL}/profile-id-fields`, [
      EProviderFeatures.GET,
    ]);
  }
}

export const profileFieldProvider = new ProfileFieldProvider();
