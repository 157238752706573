import { Module } from "vuex";
import { make } from "vuex-pathify";

import { RootState } from "@/services/store/root-state";

import { IAuthLatestAssessmentState } from "./auth-latest-assessment.types";
import { actions } from "./auth-latest-assessment.actions";

const state: IAuthLatestAssessmentState = {
  loading: false,
  error: null,
  data: null,
};

export const authLatestAssessment: Module<
  IAuthLatestAssessmentState,
  RootState
> = {
  namespaced: true,
  state,
  actions,
  mutations: make.mutations(state),
  getters: make.getters(state),
};
