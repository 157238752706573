<template>
  <div class="level-update-modal-note">
    <p>
      {{ $t("common.components.levelUpdateModal.note.0") }}
    </p>
    <ul>
      <li class="level-update-modal-note--first">
        <i18n-t
          :keypath="`common.components.levelUpdateModal.note.1`"
          tag="span"
        >
          <template #firstIcon>
            <PxIcon
              :size="17"
              class="evidence-provided"
              name="evidence-provided"
            />
          </template>
          <template #text1>
            <i18n-t
              :keypath="`common.components.levelUpdateModal.note.2`"
              tag="p"
            >
              <template #firstIcon>
                <PxIcon :size="16" class="self-assessed" name="self-assessed" />
              </template>
            </i18n-t>
          </template>
        </i18n-t>
      </li>
      <li class="level-update-modal-note--second">
        <i18n-t :keypath="`common.components.levelUpdateModal.note.3`" tag="p">
          <template #text1>
            <i18n-t
              :keypath="`common.components.levelUpdateModal.note.5`"
              tag="span"
            >
              <template #firstIcon>
                <MilestoneStageStatusItem
                  v-bind="inProgressIconProps"
                  :is-clickable="false"
                />
              </template>
            </i18n-t>
          </template>
          <template #text2>
            <i18n-t
              :keypath="`common.components.levelUpdateModal.note.6`"
              tag="span"
            />
          </template>
          <template #firstIcon>
            <PxIcon
              :size="17"
              class="evidence-provided"
              name="evidence-provided"
            />
          </template>
          <template #breakText>
            <i18n-t
              :keypath="`common.components.levelUpdateModal.note.4`"
              class="level-update-modal-note--break-text"
              tag="span"
            />
          </template>
        </i18n-t>
      </li>
      <li class="level-update-modal-note--third">
        <i18n-t :keypath="`common.components.levelUpdateModal.note.7`" tag="p">
          <template #text1>
            <i18n-t
              :keypath="`common.components.levelUpdateModal.note.8`"
              tag="span"
            />
          </template>
          <template #breakText>
            <i18n-t
              :keypath="`common.components.levelUpdateModal.note.9`"
              class="level-update-modal-note--break-text"
              tag="p"
            >
              <template #firstIcon>
                <MilestoneStageStatusItem
                  v-bind="inProgressIconProps"
                  :is-clickable="false"
                />
              </template>
            </i18n-t>
          </template>
        </i18n-t>
      </li>
    </ul>
    <PxButton
      :disabled="loading"
      class="el-button--link-dark"
      data-testid="view-example-button"
      size="small"
      type="link"
      @click="$emitter.emit('change-level-example-modal-visibility')"
    >
      {{ $t("common.components.levelUpdateModal.note.link") }}
    </PxButton>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { EMilestoneStatusType } from "@/modules/milestone-planner/services/data/milestones/milestone.interface";
import MilestoneStageStatusItem from "@/modules/milestone-planner/components/milestone-stage-status-item/milestone-stage-status-item.vue";

export default defineComponent({
  name: "LevelUpdateModalNote",

  components: {
    MilestoneStageStatusItem,
  },

  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    inProgressIconProps(): any {
      return {
        title: this.$t("milestonePlanner.milestoneGrid.stageStatus.inProgress"),
        icon: EMilestoneStatusType.IN_PROGRESS,
      };
    },
  },
});
</script>

<style lang="scss" scoped>
.level-update-modal-note {
  max-width: 620px;
  max-height: 182px;
  padding: 12px 0 16px 17px;
  margin: 0 auto 16px auto;
  background-color: $alabaster;

  p {
    display: inline;
    font-style: italic;
  }

  span,
  p {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.03px;
  }

  ul {
    position: relative;
    bottom: 6px;
    padding-left: 14px;
    margin: 0;

    li::marker {
      font-size: 10px;
    }
  }

  &--first > :first-child,
  &--second > :first-child {
    position: relative;
    top: 1px;
    right: 5px;
  }

  &--third > :first-child {
    position: relative;
    top: 1px;
    right: 1px;
  }

  span {
    @include grotesk(semiBold);

    font-style: italic;
  }

  .px-stage-status-item {
    position: relative;
    top: 10px;
    left: 1px;
    display: inline-flex;
    padding: 0;
    margin-right: 8px;
  }

  &--second {
    position: relative;
    bottom: 14px;

    .px-stage-status-item {
      left: 5px;
      margin-right: 5px;
    }

    .evidence-provided {
      position: relative;
      top: 3px;
      left: 2px;
      margin-right: 1px;
    }
  }

  &--break-text {
    position: relative;
    right: 9px;
    bottom: 4px;
    font-weight: normal;
    letter-spacing: -0.13px !important;
  }

  &--third {
    position: relative;
    bottom: 22px;

    p {
      letter-spacing: -0.15px;
    }

    .px-stage-status-item {
      left: 5px;
      margin-right: 7px;
    }

    .level-update-modal-note--break-text {
      right: 19px;
      bottom: 14px;
    }
  }

  &--first {
    .evidence-provided {
      position: relative;
      top: 3px;
      left: 2px;
      margin-right: 1px;
    }

    .self-assessed {
      position: relative;
      top: 3px;
      left: 3px;
      margin-right: 3px;
    }

    span p {
      position: relative;
      right: 2px;
      letter-spacing: -0.3px;
    }
  }

  .el-button.el-button--link {
    position: relative;
    right: 1px;
    bottom: 35px;

    &::after {
      position: relative;
      top: 4px;
      left: 2%;
      display: block;
      width: 20px;
      height: 20px;

      content: "";
      background: url("#{$assetsPath}/img/icons/arrow--right-dark.svg")
        no-repeat;
      border: none;
    }
  }

  > p:first-of-type {
    letter-spacing: -0.1px;
  }
}
</style>
