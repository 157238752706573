// TODO: Migrate this logic to store

import unionBy from "lodash/unionBy";

import { sectorGroupProvider } from "@/services/data/sector-group/sector-group.provider";
import { ISectorGroup } from "@/services/data/sector-group/sector-group.interface";
import { ISector } from "@/services/data/sector/sector.interface";

import {
  IMultiSelection,
  IMultiSelector,
  IMultiSelectorItem,
} from "@/components/multi-selector/multi-selector.interface";
import { ISupporter } from "@/modules/matching/services/data/matching-score/supporter.interface";

export interface IGroupedSectorsPayload {
  group: ISectorGroup["id"];
  sectors: Array<ISector["id"]>;
}

export interface IMultiSectorsPayload {
  // Individually selected sectors
  sectors: IGroupedSectorsPayload["sectors"];

  // Selected groups or multiple sectors inside a group
  grouped_sectors: IGroupedSectorsPayload[];
}

/**
 * Map sector groups as multi-selector items.
 * @param grouped_sectors List of sector groups
 * @param selected Set sector groups as selected/checked
 * @param with_children Choose to include parent children
 */
export const multiSelectorMapSectorGroups = (
  grouped_sectors: ISectorGroup[],
  selected = false,
  with_children = true,
): IMultiSelectorItem[] => {
  return grouped_sectors.map((group) => {
    const group_item = {
      value: group.id,
      label: group.name,
      selected,
    } as IMultiSelectorItem;

    // Gather sectors and filtered sectors
    let sectors = group.sectors || [];
    if (group.filtered_sectors && group.sectors) {
      sectors = unionBy(group.filtered_sectors, group.sectors, "name");
    }

    // Sectors mapping for MultiSelector
    if (sectors && with_children) {
      // TEMP: Until linting configuration isn't unified.
      /* eslint-disable @typescript-eslint/no-use-before-define */
      group_item.children = multiSelectorMapSectors(sectors, selected, group);
    }

    return group_item;
  });
};

/**
 * Map sectors as multi-selector items.
 * @param sectors List of sectors
 * @param selected Set sectors as selected/checked
 * @param group Optionally include group for each sector
 */
export const multiSelectorMapSectors = (
  sectors: ISector[],
  selected = false,
  group?: ISectorGroup,
): IMultiSelectorItem[] => {
  const mapped_group = group
    ? multiSelectorMapSectorGroups([group], selected, false)[0]
    : null;
  const mapped_sectors = sectors.map((sector) => {
    const sector_item = {
      value: sector.id,
      label: sector.name,
      selected,
    } as IMultiSelectorItem;

    if (mapped_group) {
      // Set parent from specified group
      sector_item.parent = mapped_group;
    } else if (sector.group) {
      // Or fallback to sector's group
      sector_item.parent = multiSelectorMapSectorGroups(
        sector.group,
        selected,
        false,
      )[0];
    }

    return sector_item;
  });

  if (mapped_group) {
    // Remove any duplicate sector, that is a sector group
    return mapped_sectors.filter(
      (child: IMultiSelectorItem) => child.value !== mapped_group.value,
    );
  }

  return mapped_sectors;
};

/**
 * Search for sectors or grouped sectors
 * with results mapped for the multi-selector.
 *
 * @param options Optionally specify search keyword (filter)
 * and (page) when there are paginated results.
 */
export const multiSelectorSectorsProvider = async (
  options: {
    filter?: string;
    page?: number;
  } = {},
): Promise<IMultiSelector | null> => {
  try {
    const data = await sectorGroupProvider.listWithSectors(options);

    // Sector group mapping for MultiSelector
    const results = multiSelectorMapSectorGroups(data.results);

    return {
      results,
      count: data.count,
    };
  } catch {
    // If provider fails, return null
    return null;
  }
};

/**
 * Format selection from multi-selector
 * to match API payload schema that
 * separates the selection in two:
 * sectors - selected a single sector individually
 * grouped_sectors - selected group or multiple sectors inside a group
 *
 * @param multiselection List of multi selected options
 * @returns Payload for adding/updating sector and grouped sectors
 */
export const formatMultiSectorsSelection = (
  multiselection: Partial<IMultiSelection>[],
): Partial<IMultiSectorsPayload> => {
  const payload: Partial<IMultiSectorsPayload> = {};

  (multiselection || []).forEach((selection) => {
    const sector_values = selection.selected_children
      ? selection.selected_children.map((sector) => sector.value)
      : [];

    // Check if selected sector group
    if (selection.selected_parent) {
      const grouped_sectors = {
        group: selection.selected_parent.value as number,
        sectors: sector_values as number[],
      };

      // Populate grouped sectors
      if (payload.grouped_sectors) {
        payload.grouped_sectors.push(grouped_sectors);
      } else {
        payload.grouped_sectors = [grouped_sectors];
      }
    } else {
      // Otherwise populate just individual sectors
      payload.sectors = payload.sectors
        ? [...payload.sectors, ...(sector_values as number[])]
        : (sector_values as number[]);
    }
  });

  return payload;
};

/**
 * Format supporter sectors and grouped sectors
 * to feed the multi-selector.
 *
 * @param supporter
 */
export const formatSupporterSectors = (
  supporter: ISupporter,
): Partial<IMultiSelection>[] => {
  const multiSelection: Partial<IMultiSelection>[] = [];

  if (supporter.grouped_sectors) {
    // Adjust supporter sectors structure to be mapped for multi-selector
    const sector_groups = supporter.grouped_sectors.map((grouped_sector) => ({
      ...grouped_sector.group,
      sectors: grouped_sector.sectors,
    })) as ISectorGroup[];

    const mapped_sector_groups = multiSelectorMapSectorGroups(
      sector_groups,
      true,
    );

    // Add supporter groups as multi-selector parents
    mapped_sector_groups.forEach((sector_group) => {
      multiSelection.push({
        selected_parent: sector_group,
      });
    });
  }

  if (supporter.sectors) {
    // Map sectors for multi-selector
    const mapped_sectors = multiSelectorMapSectors(supporter.sectors, true);

    // Add supporter sectors as multi-selector children
    mapped_sectors.forEach((sectors) => {
      multiSelection.push({
        selected_children: [sectors],
      });
    });
  }

  return multiSelection;
};
