import { ActionTree } from "vuex";
import {
  EProfileLatestAssessmentActions,
  EProfileLatestAssessmentMutations,
  IProfileLatestAssessmentState,
} from "./profile-latest-assessment-types";
import { RootState } from "@/services/store/root-state";
import { getBaseName } from "@/services/store/utils/get-base-name";
import { latestAssessmentProvider } from "@/modules/profile/services/data/latest-assessment/latest-assessment.provider";

export const actions: ActionTree<IProfileLatestAssessmentState, RootState> = {
  /**
   * Get the latest assessment for the given company.
   *
   * @param param0 store context
   * @param companyId Id of the company where the assessment must be fetched.
   */
  async [getBaseName(EProfileLatestAssessmentActions.FETCH)](
    { commit },
    companyId: number,
  ) {
    commit(EProfileLatestAssessmentMutations.SET_LOADING, true);
    commit(EProfileLatestAssessmentMutations.SET_ERROR, null);

    try {
      const data = await latestAssessmentProvider.get(companyId);
      commit(EProfileLatestAssessmentMutations.SET_DATA, data);
    } catch (error) {
      commit(EProfileLatestAssessmentMutations.SET_ERROR, error);
      commit(EProfileLatestAssessmentMutations.SET_DATA, null);
    } finally {
      commit(EProfileLatestAssessmentMutations.SET_LOADING, false);
    }
  },

  /**
   * Reset profile latest assessment value from store.
   *
   * @param param0 store context
   */
  async [getBaseName(EProfileLatestAssessmentActions.RESET)]({ commit }) {
    commit(EProfileLatestAssessmentMutations.SET_DATA, null);
  },
};
