import { GetterTree } from "vuex";
import { EViralLevelGetters, IViralLevelState } from "./viral-level-types";
import { RootState } from "@/services/store/root-state";
import { generateBaseGettersFor } from "@/services/store/utils/base-generators";
import { ICategoryLevelSelection } from "@/services/data/viral-level/viral-level.interface";
import { getBaseName } from "@/services/store/utils/get-base-name";
import { ICategoryState } from "@/services/store/category/category-types";
import { ICategory } from "@/services/data/category/category.interface";

export const getters: GetterTree<IViralLevelState, RootState> = {
  ...generateBaseGettersFor<IViralLevelState, ICategoryLevelSelection>(
    "levels",
  ),
  /**
   * Get current category data.
   *
   * @param state module state
   * @param _ reference for getters
   * @param rootState root state
   */
  [getBaseName(EViralLevelGetters.CURRENT_CATEGORY)](state, _, rootState) {
    return ((rootState as any).categories as ICategoryState).data[
      state.selectedCategory
    ];
  },
  /**
   * Get current selected step data.
   *
   * @param state module state
   * @param mGetters reference for getter
   */
  [getBaseName(EViralLevelGetters.SELECTED_STEP_DATA)](state, mGetters) {
    if (!state.levels || state.levels.length === 0) {
      return null;
    }

    const currentCategory = mGetters[
      getBaseName(EViralLevelGetters.CURRENT_CATEGORY)
    ] as ICategory;

    const selectedDetail = state.levels.find(
      (record: ICategoryLevelSelection): any => {
        return currentCategory && record.category === currentCategory.id;
      },
    );

    return selectedDetail || null;
  },
  /**
   * Returns a function that allows to get a specific metadata
   * entry.
   *
   * @param state Viral level state.
   */
  [getBaseName(EViralLevelGetters.SINGLE_METADATA)](state) {
    return (key: string): any => state.metadata[key];
  },
};
