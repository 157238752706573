import { ICompany } from "./company.types";
import {
  GenericProvider,
  Provider,
  IOptionsType,
} from "@/services/data/generic-provider";
import { baseAPIUrl, isDevelopment } from "@/services/utils/utils";

@Provider(`${baseAPIUrl}/companies`)
class CompanyProvider extends GenericProvider<ICompany> {
  /**
   * Update a company profile logo.
   *
   * @param id Company id
   * @param file File that contains the new logo
   * @param options Additional options that can be passed by URL params.
   */
  public async updateLogo(
    id: number,
    file: File,
    options: IOptionsType = {},
  ): Promise<ICompany> {
    const dataToSend = new FormData();
    dataToSend.append("logo", file);

    const url = this.buildEndPointUrl(options, String(id));
    const request = this.httpClient.patch(url, dataToSend);
    const { data } = await this.wrapRequest(request);

    return data as ICompany;
  }

  public async get<IDT = number>(
    id: IDT,
    options: IOptionsType = {},
  ): Promise<ICompany> {
    const data = await super.get(id, options);

    // TODO: maybe move this to the API, since DIVIO appends the URL
    if (data.logo && isDevelopment) {
      data.logo = `${baseAPIUrl}${data.logo}`;
    }

    return data;
  }

  public async patch<IDT = number>(
    id: IDT,
    companyData: Partial<ICompany>,
    options: IOptionsType = {},
  ): Promise<ICompany> {
    // Set any empty strings as null in order to save fields as "empty"
    Object.keys(companyData).forEach((key: string) => {
      const isEmptyString =
        typeof companyData[key] === "string" && !companyData[key].length;

      if (isEmptyString) {
        companyData[key] = null;
      }
    });

    return await super.patch(id, companyData, options);
  }
}

export const companyProvider = new CompanyProvider();
