import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PxAvatar = _resolveComponent("PxAvatar")!
  const _component_i18n_t = _resolveComponent("i18n-t")!

  return (_openBlock(), _createBlock(_component_i18n_t, {
    keypath: `matching.matchDetails.details`,
    class: "match-details",
    tag: "p"
  }, {
    companyData: _withCtx(() => [
      _createVNode(_component_PxAvatar, {
        name: _ctx.companyName,
        photo: _ctx.companyLogo,
        size: 24,
        class: "match-details__company-avatar"
      }, null, 8, ["name", "photo"]),
      _createTextVNode(" " + _toDisplayString(_ctx.companyName), 1)
    ]),
    percentage: _withCtx(() => [
      _createElementVNode("span", {
        class: _normalizeClass([{ 'match-details__percentage--grey': !_ctx.hasMatch }, "match-details__percentage"])
      }, _toDisplayString(_ctx.percentage), 3)
    ]),
    _: 1
  }))
}