<template>
  <div class="px-questionary-criteria">
    <ElSlider
      v-model="selectedWeight"
      :format-tooltip="formatTooltip"
      :max="weightsCount"
      :min="1"
      :step="1"
      show-stops
      tooltip-class="px-questionary-criteria__tooltip"
      @change="sliderChangeHandler"
    />
    <div class="px-questionary-criteria__stops">
      <span
        v-for="index in weightsCount"
        :key="`${index}`"
        class="px-questionary-criteria__tooltip-text"
      />
    </div>
    <div class="px-questionary-criteria__labels">
      <span
        v-for="label in weightLabels"
        :key="label.id"
        :class="{ 'is-active': label.id === currentWeightId }"
      >
        {{ label.name }}
      </span>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";

import { IMatchingCriteriaWeight } from "@/modules/matching/services/data/matching-criteria/matching-criteria.interface";

export default defineComponent({
  name: "PxQuestionaryCriteria",

  props: {
    modelValue: {
      type: [Number, null] as PropType<number | null>,
      default: null,
    },
    weights: {
      type: Array as () => IMatchingCriteriaWeight[],
      required: true,
    },
  },

  data() {
    return {
      selectedWeight: 0,
    };
  },

  computed: {
    weightsCount(): number {
      return this.weights.length;
    },
    weightLabels(): Array<IMatchingCriteriaWeight> {
      // Get only odd indexed items
      return this.weights.filter((weight, index) => !(index % 2));
    },
    currentWeightId(): number | null {
      const selectedWeightIndex = this.selectedWeight - 1;

      return this.weights[selectedWeightIndex]
        ? this.weights[selectedWeightIndex].id
        : null;
    },
  },

  watch: {
    /**
     * Update selected value when the prop value changes.
     */
    modelValue: {
      immediate: true,
      handler(newValue: number) {
        if (newValue) {
          this.selectedWeight = newValue;
        }
      },
    },

    currentWeightId(currentId) {
      if (!!currentId && typeof this.modelValue !== "number") {
        this.$emit("input", this.currentWeightId);
      }
    },
  },

  methods: {
    formatTooltip(weightIndex: number): string | number {
      const selectedWeightIndex = weightIndex - 1;

      return this.weights[selectedWeightIndex]
        ? this.weights[selectedWeightIndex].name
        : weightIndex;
    },
    sliderChangeHandler() {
      this.$emit("update:modelValue", this.currentWeightId);
      this.$emit("changed", this.currentWeightId);
    },
  },
});
</script>

<style lang="scss" scoped>
.px-questionary-criteria :deep() {
  position: relative;

  .el-slider {
    margin: auto 30px;
  }

  .el-slider__runway {
    position: relative;
    height: 20px;
    background: none;

    &::before {
      position: absolute;
      top: 0;
      right: -20px;
      left: -20px;
      height: 100%;
      content: "";
      background-color: rgba(250, 250, 250, 0.2);
      border: solid 1px rgba(250, 250, 250, 0.08);
      border-radius: 15px;
    }
  }

  .el-slider__stop {
    top: 5px;
    opacity: 0;
  }

  .px-questionary-criteria__stops {
    position: absolute;
    top: 5px;
    right: 0;
    left: 0;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    width: calc(100% - 10px);
    height: 20px;
    margin: auto;
    pointer-events: none;

    span {
      top: 4px;
      width: 3px;
      height: 10px;
      background-color: white;
      border-radius: 2px;
    }
  }

  .el-slider__button-wrapper {
    top: -4px;
    width: 28px;
    height: 28px;
  }

  .el-slider__button {
    position: relative;
    width: 28px;
    height: 28px;
    background-color: $alabaster;
    border: none;
    box-shadow: 0 0 4px 0 rgba(39, 39, 64, 0.5);

    &::before,
    &::after {
      position: absolute;
      top: 9px;
      width: 2px;
      height: 10px;
      content: "";
      background-color: $ebony-clay;
      border-radius: 2px;
    }

    &::before {
      left: 10px;
    }

    &::after {
      right: 10px;
    }
  }

  .el-slider__bar {
    opacity: 0;
  }

  .px-questionary-criteria__labels {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: space-between;
  }

  .px-questionary-criteria__labels span {
    @include grotesk(medium);

    flex: 0 1 61px;
    font-size: 13px;
    line-height: 15px;
    text-align: center;
    opacity: 0.7;
    will-change: opacity;
    transition: opacity 120ms ease-in;

    &.is-active {
      opacity: 1;
    }
  }
}
</style>

<style lang="scss">
.px-questionary-criteria__tooltip {
  padding: 4px 10px;
  background-color: #777784;

  .px-questionary-criteria__tooltip-text {
    @include grotesk(semiBold);

    font-size: 14px;
  }
}

.px-questionary-criteria__tooltip[data-popper-placement^="top"] .popper__arrow {
  border-top-color: #777784;

  &::after {
    border-top-color: #777784;
  }
}
</style>
