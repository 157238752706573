<template>
  <div class="affiliate-program-results">
    <AffiliateNavigationBar
      v-if="showNavbar"
      :external-link="affiliate ? affiliate.website : ''"
      :loading="isLoading"
      :logo="affiliate ? affiliate.logo : ''"
      :show-steps="false"
      data-testid="affiliate-program-results-navbar"
    />
    <div
      v-loading="isLoading"
      :class="{ 'affiliate-program-results--variation': showNavbar }"
      class="affiliate-program-results__wrapper"
    >
      <template v-if="isUserLogged">
        <div class="affiliate-program-results__confirmation-panel">
          <div class="affiliate-program-results__confirmation-panel-thanks">
            <h1 class="affiliate-program-results__confirmation-panel-title">
              <PxIcon
                :name="`icon-check-green-white`"
                :size="18"
                class="affiliate-program-results__confirmation-panel-icon"
              />
              {{ $t("affiliateProgram.results.confirmationPanel.title") }}
            </h1>
          </div>
          <div
            class="affiliate-program-results__confirmation-panel-information"
          >
            <i18n-t
              v-if="affiliate"
              class="affiliate-program-results__confirmation-panel-content"
              keypath="affiliateProgram.results.confirmationPanel.sentInfo"
              tag="p"
              data-testid="affiliate-program-results-affiliate-name"
            >
              <template #company>
                <u>
                  {{ affiliate.name }}
                </u>
              </template>
            </i18n-t>
            <i18n-t
              class="affiliate-program-results__confirmation-panel-content"
              keypath="affiliateProgram.results.confirmationPanel.seeLatest"
              tag="p"
            >
              <template #cta>
                <PxButton
                  data-testid="affiliate-program-results-milestone-planner-button"
                  type="link"
                  @click="goToMilestonePlanner"
                >
                  {{ $t("affiliateProgram.results.confirmationPanel.cta") }}
                </PxButton>
              </template>
            </i18n-t>
            <i18n-t
              class="affiliate-program-results__confirmation-panel-content"
              keypath="affiliateProgram.results.confirmationPanel.affiliateShare"
              tag="p"
            >
              <template #submission>
                <b>
                  {{
                    $t("affiliateProgram.results.confirmationPanel.submission")
                  }}
                </b>
              </template>
            </i18n-t>
          </div>
        </div>
        <ValidatingPanelPlaceholder
          v-if="isLoading"
          class="affiliate-program-results__validating-panel"
          data-testid="affiliate-program-results-validating-panel-placeholder"
        />
        <ValidatingPanel
          v-else
          class="affiliate-program-results__validating-panel"
          data-testid="affiliate-program-results-validating-panel"
        />
        <DetailsPanelPlaceholder
          v-if="isLoading"
          data-testid="affiliate-program-results-details-panel-placeholder"
        />
        <DetailsPanel
          v-if="!isLoading && !hasError"
          class="affiliate-program-results__details-panel"
          data-testid="affiliate-program-results-details-panel"
        />
      </template>
      <template v-else>
        <ResultsBoard data-testid="affiliate-program-results-board" />
        <FeaturesShowcase
          v-if="!isFeaturesShowcaseDisabled"
          data-testid="affiliate-program-results-features-showcase"
        />
      </template>
      <SignUpModal
        v-if="
          $features.isEnabled('assessmentUserInfoBeforeHand') && !isUserLogged
        "
      />
    </div>
    <PxFooter
      v-if="showFooter"
      data-testid="affiliate-program-results-footer"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import ValidatingPanel from "@/components/validating-panel/validating-panel.vue";
import ValidatingPanelPlaceholder from "@/components/validating-panel-placeholder/validating-panel-placeholder.vue";
import DetailsPanel from "@/modules/affiliates/components/details-panel/details-panel.vue";
import DetailsPanelPlaceholder from "@/modules/affiliates/components/details-panel-placeholder/details-panel-placeholder.vue";
import ResultsBoard from "@/modules/affiliates/components/results-board/results-board.vue";
import FeaturesShowcase from "@/modules/affiliates/components/features-showcase/features-showcase.vue";
import SignUpModal from "@/modules/authentication/components/signup-modal/signup-modal.vue";

import { IViralLevelState } from "@/services/store/viral-level/viral-level-types";
import { ROUTE_MILESTONE_PLANNER_OVERVIEW } from "@/modules/milestone-planner/services/router/routes-names";
import { AFFILIATE_PROGRAM_FINISHED } from "@/modules/affiliates/constants";
import { EMetaActions } from "@/services/store/meta/meta-types";
import { IAffiliatesState } from "@/modules/affiliates/services/store/affiliates/affiliates.types";
import { IAffiliate } from "@/services/data/affiliate/affiliate.interface";
import AffiliateNavigationBar from "@/modules/affiliates/components/affiliate-navigation-bar/affiliate-navigation-bar.vue";

export default defineComponent({
  name: "AffiliateEntrepreneurResults",

  components: {
    DetailsPanel,
    ValidatingPanel,
    ValidatingPanelPlaceholder,
    DetailsPanelPlaceholder,
    ResultsBoard,
    FeaturesShowcase,
    SignUpModal,
    AffiliateNavigationBar,
  },

  props: {
    showFooter: {
      type: Boolean,
      default: false,
    },

    showNavbar: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    viralLevelStore(): IViralLevelState {
      return this.$store.state.viralLevel as IViralLevelState;
    },

    affiliatesData(): IAffiliatesState {
      return this.$store.state.affiliates;
    },

    affiliate(): IAffiliate | null {
      return this.affiliatesData.affiliate;
    },

    isLoading(): boolean {
      return this.viralLevelStore.loading || this.affiliatesData.loading;
    },

    hasError(): boolean {
      return !!this.viralLevelStore.error || !!this.affiliatesData.error;
    },

    isUserLogged(): boolean {
      return this.$user.isLogged();
    },

    isFeaturesShowcaseDisabled(): boolean {
      return this.$features.isEnabled("disabledFeaturesShowcase");
    },
  },

  unmounted() {
    this.$store.dispatch(EMetaActions.SET, {
      key: AFFILIATE_PROGRAM_FINISHED,
      value: false,
    });
  },

  mounted() {
    this.$store.dispatch(EMetaActions.SET, {
      key: AFFILIATE_PROGRAM_FINISHED,
      value: true,
    });
  },

  methods: {
    goToMilestonePlanner() {
      this.$router.push({ name: ROUTE_MILESTONE_PLANNER_OVERVIEW });
    },
  },
});
</script>

<style lang="scss" scoped>
.affiliate-program-results {
  &--variation {
    @include breakpoint-up(md) {
      padding-top: 64px;
    }
  }

  &__wrapper {
    max-width: 940px;
    min-height: 70vh;
    margin: 0 auto;
  }

  &__confirmation-panel {
    display: flex;
    min-height: 92px;
    max-width: 813px;
    margin: 0 auto;

    @include breakpoint-down(sm) {
      flex-direction: column;
      align-items: center;
      padding: 30px 15px 22px;
    }

    @include breakpoint-up(md) {
      padding: 24px 0;
      margin-bottom: 50px;
      background-color: $athens-gray;
      border-radius: 4px;
      box-shadow: inset 0 0 3px 0 rgba(139, 143, 161, 0.16);
    }

    & > div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      flex: 1;

      @include breakpoint-up(md) {
        flex: 1 0 50%;
      }

      @include breakpoint-down(md) {
        align-items: center;
      }
    }
  }

  &__confirmation-panel-information {
    @include breakpoint-up(md) {
      padding-left: 22px;
    }
  }

  &__confirmation-panel-thanks {
    max-width: 303px;
    padding-left: 30px;

    @include breakpoint-up(md) {
      border-right: 1px solid rgba($manatee, 0.14);
    }
  }

  &__confirmation-panel-title {
    display: flex;
    align-items: center;
    gap: 13px;

    font-size: to-rem(22px);
    line-height: to-rem(24px);
    margin: 0;
    letter-spacing: -0.2px;

    @include breakpoint-down(sm) {
      margin-bottom: 18px;
      font-size: 17px;
      color: $fern;
    }
  }

  &__confirmation-panel-content {
    font-size: 16px;
    line-height: 1;
    text-align: left;
    color: $ebony-clay;

    @include breakpoint-down(sm) {
      text-align: center;
    }

    b {
      font-weight: 500;
      letter-spacing: -0.2px;
    }
  }

  &__confirmation-panel-content:nth-child(2) {
    margin-bottom: 21px;
    letter-spacing: -0.33px;
  }

  &__confirmation-panel-content:last-child {
    line-height: 1.11;
    letter-spacing: -0.36px;
  }

  &__confirmation-panel-content:first-child {
    @include grotesk(bold);
    margin-bottom: 11px;
  }

  &__confirmation-panel-content :deep() .el-button {
    height: auto;
    padding: 0;
    color: $ebony-clay;
  }

  &__validating-panel {
    margin-bottom: 52px;

    @include breakpoint-down(sm) {
      margin-bottom: 40px;
    }
  }

  &__validating-panel :deep() .el-tag {
    padding: 3px 8px;
    font-size: 13px;
  }

  & &__details-panel {
    margin-bottom: 29px;

    @include breakpoint-up(md) {
      margin-bottom: 98px;
    }
  }

  & :deep() .features-showcase {
    margin: 40px auto 50px;

    @include breakpoint-up(md) {
      margin: 80px auto 100px;
    }

    @include breakpoint-up(lg) {
      margin: 80px auto 160px;
    }
  }
}

.affiliate-program-results--authentication {
  @include breakpoint-up(md) {
    padding-top: 26px;
  }
}
</style>
