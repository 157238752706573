import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Bar = _resolveComponent("Bar")!

  return (_openBlock(), _createBlock(_component_Bar, {
    ref: "chart",
    "chart-options": _ctx.chartOptions,
    "chart-data": _ctx.chartData,
    "chart-id": _ctx.chartId,
    "css-classes": _ctx.cssClasses,
    "dataset-id-key": _ctx.datasetIdKey,
    height: _ctx.chartHeight,
    plugins: _ctx.chartPlugins,
    styles: _ctx.styles,
    width: _ctx.chartWidth,
    class: "bar-chart"
  }, null, 8, ["chart-options", "chart-data", "chart-id", "css-classes", "dataset-id-key", "height", "plugins", "styles", "width"]))
}