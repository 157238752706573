import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PxButton = _resolveComponent("PxButton")!
  const _component_ElTooltip = _resolveComponent("ElTooltip")!

  return (_openBlock(), _createBlock(_component_ElTooltip, {
    content: _ctx.saveAsDraftTooltipCopy,
    disabled: !_ctx.showTooltip,
    placement: "top",
    "popper-class": "milestone-form-footer__tooltip el-abaca-tooltip"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", null, [
        _createVNode(_component_PxButton, _mergeProps(_ctx.saveAsDraftButtonProps, {
          disabled: _ctx.buttonDisabled,
          class: "milestone-form-footer__save-as-draft-button",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('click')))
        }), null, 16, ["disabled"])
      ])
    ]),
    _: 1
  }, 8, ["content", "disabled"]))
}