<template>
  <PxPanel :no-borders="true" class="signup-modal">
    <div class="signup-modal__announcement">
      <div class="signup-modal__announcement-icon">
        <img alt="email sent icon" src="/img/icons/email-sent.svg" />
      </div>
      <h2 class="signup-modal__announcement-title">
        {{ $t("authentication.signup.success.title") }}
      </h2>
      <div
        class="signup-modal__announcement-message"
        v-html="
          $t('authentication.signup.success.message', { registrationEmail })
        "
      />
    </div>
  </PxPanel>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { gaTrackEvent } from "@/services/utils/ga.utils";

export default defineComponent({
  name: "SignUpModalSuccess",

  props: {
    registrationEmail: {
      type: String,
      required: true,
    },
  },

  mounted() {
    // Informs GA the user has registered
    gaTrackEvent("registered", "Self-Assessment");
  },
});
</script>

<style lang="scss" scoped>
.signup-modal.px-panel {
  margin: auto;

  :deep() .px-panel__body {
    @include breakpoint-only(xs) {
      padding: 10px 9px 0;
    }

    @include breakpoint-up(md) {
      padding: 0 10px;
    }

    &::after {
      @include breakpoint-only(xs) {
        height: 10px;
      }
    }
  }
}

.signup-modal__announcement {
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  justify-content: center;
  text-align: center;

  @include breakpoint-up(md) {
    padding: 27px 40px 15px;
  }

  &-icon {
    max-width: 100%;
  }

  &-title {
    @include grotesk(semiBold);

    margin-bottom: 0;
    font-size: 1.5333rem;
    line-height: 40px;
    color: $malibu;
    letter-spacing: 0;

    @include breakpoint-up(md) {
      font-size: to-rem(30px);
      color: $ebony-clay;
    }
  }

  .signup-modal__announcement-icon + .signup-modal__announcement-title {
    margin-top: 10px;

    @include breakpoint-up(md) {
      margin-top: 24px;
    }
  }

  &-message {
    margin-top: 14px;
    font-size: 15px;
    line-height: 22px;
    color: $ebony-clay;
    letter-spacing: -0.15px;

    /* Firefox does not support break-word */
    word-break: break-all;
    word-break: break-word;

    @include breakpoint-up(md) {
      margin-top: 19px;
      line-height: 24px;
    }

    :deep(strong) {
      @include grotesk(semiBold);

      line-height: inherit;
      letter-spacing: 0;
    }
  }
}
</style>
