import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "affiliate-link-edit-modal__wrapper" }
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = { class: "affiliate-link-edit-modal__link" }
const _hoisted_4 = { class: "affiliate-link-edit-modal__hint" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ElInput = _resolveComponent("ElInput")!
  const _component_ElTooltip = _resolveComponent("ElTooltip")!
  const _component_PxIcon = _resolveComponent("PxIcon")!
  const _component_PxButton = _resolveComponent("PxButton")!
  const _component_ElDialog = _resolveComponent("ElDialog")!

  return (_openBlock(), _createBlock(_component_ElDialog, {
    ref: "modal",
    modelValue: _ctx.innerVisibility,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.innerVisibility) = $event)),
    title: _ctx.$t(`profile.affiliateLink.modal.title`),
    class: "affiliate-link-edit-modal el-dialog--px-10",
    onClose: _ctx.onModalClose
  }, {
    footer: _withCtx(() => [
      _createElementVNode("div", null, [
        _createVNode(_component_PxButton, {
          disabled: _ctx.copiedVisibility,
          size: "small",
          type: "link",
          onClick: _ctx.onModalClose
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t("common.cancel")), 1)
          ]),
          _: 1
        }, 8, ["disabled", "onClick"]),
        _createVNode(_component_PxButton, {
          disabled: _ctx.copiedVisibility,
          type: "green",
          onClick: _ctx.onClickGenerate
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t("profile.affiliateLink.primaryCta")), 1)
          ]),
          _: 1
        }, 8, ["disabled", "onClick"])
      ])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("h2", null, _toDisplayString(_ctx.$t(`profile.affiliateLink.modal.subtitle`)), 1),
        _createElementVNode("span", {
          class: "affiliate-link-edit-modal__tip",
          innerHTML: _ctx.$t('profile.affiliateLink.modal.tip')
        }, null, 8, _hoisted_2),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("span", null, _toDisplayString(_ctx.affiliateLinkText), 1),
          _createVNode(_component_ElTooltip, {
            manual: true,
            value: _ctx.copiedVisibility,
            "visible-arrow": false,
            placement: "bottom",
            "popper-class": "affiliate-link-edit-modal__copied-tooltip el-abaca-tooltip"
          }, {
            content: _withCtx(() => [
              _createElementVNode("p", null, _toDisplayString(_ctx.$t("profile.affiliateLink.copyClipboard")), 1)
            ]),
            default: _withCtx(() => [
              _createVNode(_component_ElInput, {
                modelValue: _ctx.affiliateLink,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.affiliateLink) = $event))
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }, 8, ["value"])
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", null, [
            _createVNode(_component_PxIcon, {
              size: 14,
              name: "information--light-gray"
            })
          ]),
          _createElementVNode("span", null, _toDisplayString(_ctx.$t("profile.affiliateLink.modal.hint")), 1)
        ])
      ])
    ]),
    _: 1
  }, 8, ["modelValue", "title", "onClose"]))
}