import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, withModifiers as _withModifiers, mergeProps as _mergeProps, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-848d63a8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "submission-affiliate" }
const _hoisted_2 = { class: "submission-affiliate__container" }
const _hoisted_3 = { class: "submission-affiliate__head-container" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "submission-affiliate__title" }
const _hoisted_6 = { class: "submission-affiliate__date-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_i18n_t = _resolveComponent("i18n-t")!
  const _component_PxButton = _resolveComponent("PxButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("img", {
          height: 30,
          src: _ctx.affiliate.logo,
          alt: "Affiliate Logo",
          class: "submission-affiliate__logo",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.externalLinkClickHandler && _ctx.externalLinkClickHandler(...args)))
        }, null, 8, _hoisted_4),
        _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.affiliate.name), 1)
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_component_i18n_t, {
          class: "submission-affiliate__date-text",
          keypath: "program.submission.submittedAt",
          tag: "span"
        }, {
          date: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.submissionDate), 1)
          ]),
          _: 1
        })
      ]),
      _createVNode(_component_PxButton, _mergeProps({ class: "submission-affiliate__cta" }, _ctx.$options.static.printButton, {
        onClick: _withModifiers(_ctx.onPrintButtonClick, ["stop"])
      }), null, 16, ["onClick"])
    ])
  ]))
}