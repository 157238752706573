import { baseUrl } from "@/services/utils/utils";

export const AFFILIATE_PROGRAM_START_TIME = "program.startTime";
export const AFFILIATE_PROGRAM_FINISHED = "program.finished";

export const AFFILIATE_PROGRAM_TERMS_OF_USE_URL =
  "https://abaca.app/terms-of-use";
export const AFFILIATE_PROGRAM_PRIVACY_POLICY_URL =
  "https://abaca.app/privacy-policy";

export const AFFILIATE_SUPPORTER_PROGRAM_UPDATE_URL = `${baseUrl}/supporters/program?a=update`;
export const AFFILIATE_FORBIDDEN = "affiliate.forbidden";
