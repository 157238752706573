<template>
  <div class="px-loading-mask" :style="{ backgroundColor: background || '' }">
    <div class="px-loading-stack">
      <span
        v-for="i in 5"
        :key="i"
        :class="`px-loading-stack__line--${i}`"
        class="px-loading-stack__line"
      />
    </div>
  </div>
</template>

<script lang="ts">
import anime from "animejs";
import { defineComponent } from "vue";

export default defineComponent({
  name: "PxLoading",

  props: {
    background: {
      type: String,
      default: null,
    },
  },

  mounted() {
    const lines = this.$el.querySelectorAll(".px-loading-stack__line");
    anime({
      targets: lines,
      direction: "alternate",
      duration: 1000,
      delay(target, index) {
        return (lines.length - index) * 100;
      },
      opacity: 1,
      loop: true,
    });
  },
});
</script>

<style lang="scss" scoped>
$--loading-fullscreen-spinner-size--mobile: 34px !default;
$--loading-fullscreen-spinner-size--desktop: 42px !default;

/* Stack variables */
$--loading-stack-line-width--mobile: 20px !default;
$--loading-stack-line-width--desktop: 24px !default;
$--loading-stack-line-height--mobile: 4px !default;
$--loading-stack-line-height--desktop: 5px !default;
$--loading-stack-line-space-between--mobile: 4px !default;
$--loading-stack-line-space-between--desktop: 5px !default;

.px-loading-mask {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: z("loading");
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  overflow: hidden;
  background-color: rgba(255, 255, 255, 0.9);
  transition: opacity 0.3s;
}

.px-loading-stack {
  position: relative;
  display: block;
  width: $--loading-stack-line-width--mobile;
  margin: 0 auto;

  @include breakpoint-up(md) {
    width: $--loading-stack-line-width--desktop;
  }

  &__line {
    display: block;
    width: 100%;
    height: $--loading-stack-line-height--mobile;
    margin-top: $--loading-stack-line-space-between--mobile;
    background-color: rgb(155, 188, 231);
    border-radius: 2px;
    opacity: 0;
    animation: px-loading-stack-line-1 5s infinite alternate-reverse;

    @include breakpoint-up(md) {
      margin-top: $--loading-stack-line-space-between--desktop;
    }
  }

  &__line:first-child {
    margin-top: 0;
    background-color: rgb(29, 78, 183);
  }
}

.px-loading-fade-enter,
.px-loading-fade-leave-active {
  opacity: 0;
}

.px-loading-text {
  @include grotesk(medium);

  padding: 0 20px;
  margin-top: 20px;
  font-size: 15px;
  line-height: 19px;
  color: $ebony-clay;
  letter-spacing: -0.2px;
  opacity: 0.7;
}
</style>
