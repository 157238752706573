import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-591b1418"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "px-panel-section" }
const _hoisted_2 = { class: "px-panel-section__header" }
const _hoisted_3 = { class: "px-panel-section__col" }
const _hoisted_4 = { class: "px-panel-section__title" }
const _hoisted_5 = { class: "px-panel-section__col" }
const _hoisted_6 = { class: "px-panel-section__body" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PxIcon = _resolveComponent("PxIcon")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _renderSlot(_ctx.$slots, "header", {}, () => [
          (_ctx.icon)
            ? (_openBlock(), _createBlock(_component_PxIcon, {
                key: 0,
                name: _ctx.icon,
                size: _ctx.iconSize
              }, null, 8, ["name", "size"]))
            : _createCommentVNode("", true),
          _createElementVNode("h2", _hoisted_4, _toDisplayString(_ctx.title), 1)
        ], true)
      ]),
      _createElementVNode("div", _hoisted_5, [
        _renderSlot(_ctx.$slots, "cta", {}, undefined, true)
      ])
    ]),
    _createElementVNode("div", _hoisted_6, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ])
  ]))
}