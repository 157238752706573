/**
 * Data structure to hold a passcode for a milestone planner.
 */
export interface IMilestonePlannerPasscodePayload {
  /**
   * Milestone planner identifier.
   */
  milestonePlannerUid: string;

  /**
   * Passcode for the milestone planner.
   */
  passcode: string;
}

/**
 * Represents the store structure to store inserted passcodes for the milestone planners.
 */
export interface IMilestonePlannersPasscodeState {
  passcodes: { [key: string]: string };
}

/**
 * Actions to manage milestone planners passcodes.
 */
export enum EMilestonePlannerPasscodeActions {
  SET = "milestonePlanner/passcodes/set",
  RESET = "milestonePlanner/passcodes/reset",
}

/**
 * Mutations for milestone planners passcode store module.
 */
export enum EMilestonePlannerPasscodeMutations {
  SET = "set",
  RESET = "reset",
}

/**
 * Getters for milestone planners passcode store module.
 */
export enum EMilestonePlannerPasscodeGetters {
  GET = "milestonePlanner/passcodes/get",
}
