import { Module } from "vuex";
import { RootState } from "@/services/store/root-state";
import { actions } from "./match-score-details.actions";
import { IMatchScoreDetailsState } from "@/modules/matching/services/store/match-score-details/match-score-details.types";
import { make } from "vuex-pathify";

const state: IMatchScoreDetailsState = {
  data: [],
  error: null,
  loading: false,
};

export const matchScoreDetailsModule: Module<
  IMatchScoreDetailsState,
  RootState
> = {
  namespaced: true,
  state,
  actions,
  mutations: {
    ...make.mutations(state),
  },
};
