<template>
  <div
    :class="[`px-grid--${size}`, { 'px-grid--inactive': inactive }]"
    class="px-grid"
  >
    <div class="px-grid__levels">
      <span
        v-for="n in levels"
        :key="n"
        :class="{ 'is-active': n === currentLevel }"
        class="px-grid__level-text"
      >
        {{ n }}
      </span>
    </div>
    <div class="px-grid__main-area">
      <div class="px-grid__categories">
        <span
          v-for="category in categories"
          :key="category.id"
          class="px-grid__category"
        >
          <template v-if="size === 'default'">
            {{ category.name }}
          </template>
          <template v-if="size !== 'default'">
            {{ category.abbreviation }}
          </template>
        </span>
      </div>
      <div class="px-grid-matrix">
        <div
          v-for="category in categories"
          :key="category.id"
          class="px-grid-matrix__column"
        >
          <div
            v-for="n in levels"
            :key="n"
            :style="generateStylesFor(category, n)"
            class="px-grid-matrix__cell"
          />
        </div>
      </div>
    </div>
    <div
      :style="`transform: translateY(${levelBarPosition})`"
      class="px-grid__current-level"
    >
      <template v-if="size === 'default'">Level {{ currentLevel }}</template>
      <template v-else-if="inactive">0</template>
      <template v-else>
        {{ currentLevel }}
      </template>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import get from "lodash/get";

import { ICategory } from "@/services/data/category/category.interface";
import { ICategoryLevelSelection } from "@/services/data/viral-level/viral-level.interface";

export default defineComponent({
  name: "PxGrid",

  props: {
    /**
     * Grid size
     */
    size: {
      type: String,
      default: "default",
    },
    /**
     * Grid inactive state
     */
    inactive: {
      type: Boolean,
      default: false,
    },
    /**
     * Used for generating random results
     * (e.g. For unauthorized users)
     */
    randomResults: {
      type: Boolean,
      default: false,
    },
    /**
     * Array with all available categories.
     */
    categories: {
      type: Array as () => any[],
      required: true,
    },
    /**
     * Number of levels.
     */
    levels: {
      type: Number,
      required: true,
    },
    /**
     * Current level.
     */
    currentLevel: {
      type: Number,
      required: true,
    },
    /**
     * Results
     */
    results: {
      type: Array as () => any[],
      default: () => [],
    },
  },

  data() {
    return {
      sizes: {
        default: 32,
        medium: 19,
      },
    };
  },

  computed: {
    /**
     * Computed the level bar position.
     */
    levelBarPosition(): string {
      const isMobile = this.$screen.smDown;
      const isMediumSize = this.size === "medium";
      const PX_PER_STEP = isMobile ? 16 : this.getStepSize;

      let newPosition = 0;

      if (!this.inactive) {
        if (isMobile) {
          newPosition = -this.currentLevel * PX_PER_STEP - 1;
        } else if (isMediumSize) {
          newPosition =
            -this.currentLevel * PX_PER_STEP - 3 * (this.currentLevel - 1);
        } else {
          newPosition = -this.currentLevel * PX_PER_STEP;
        }
      }

      return `${newPosition}px`;
    },

    /**
     * Computed the step size
     */
    getStepSize(): number {
      return get(this.sizes, this.size, this.sizes.default);
    },

    /**
     * Get the results for the grid.
     */
    gridResults(): any[] {
      if (this.inactive) {
        const min = 2;
        const max = this.levels;

        return this.categories.map((category: ICategory) => ({
          category: category.id,
          // Random number between "min" and "max" (inclusive)
          level: Math.floor(Math.random() * (max - min + 1)) + min,
        }));
      }

      return this.results;
    },
  },

  methods: {
    generateStylesFor(
      category: ICategory,
      level: number,
    ): { [key: string]: string } {
      // Get the selected level or the given category
      const selectedLevel = this.gridResults.find(
        (entry: ICategoryLevelSelection) => entry.category === category.id,
      );

      let backgroundColor;
      const cellHasValue = !selectedLevel || level <= selectedLevel.level;
      const isInactive = this.inactive;
      const hasRandomResults = this.randomResults;

      if (cellHasValue && !isInactive) {
        backgroundColor = `#${category.color}`;
      } else if (cellHasValue && hasRandomResults) {
        backgroundColor = `rgba(139, 143, 161, 0.4)`;
      } else {
        backgroundColor = `rgba(39, 39, 64, 0.05)`;
      }

      return {
        backgroundColor,
      };
    },
  },
});
</script>

<style lang="scss">
.px-grid {
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 327px;

  &__levels {
    display: flex;
    flex-direction: column-reverse;
    align-self: flex-end;
    justify-content: space-between;
    height: calc(100% - 54px);
    padding: 0 16px 4px 24px;
  }

  &__levels span {
    @include grotesk(medium);

    font-size: 0.9333rem;
    line-height: 1;
  }

  &__levels span.is-active {
    @include grotesk(bold);
  }

  &__main-area {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    max-width: 658px;
  }

  &__categories {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 658px;
    padding-bottom: 2px;
  }

  &__category {
    @include grotesk(medium);

    width: 71px;
    font-size: 0.9333rem;
    line-height: 1.29;
    text-align: center;
  }

  &__current-level {
    @include grotesk(bold, italic);

    position: relative;
    left: 14px;
    align-self: flex-end;
    font-size: 1.0667rem;
    text-align: right;

    transition: all 0.2s ease-in-out;
    transform: translateY(0);
  }

  &__current-level::before {
    position: absolute;
    right: -1px;
    bottom: -5px;
    width: 717px;
    height: 2px;

    content: "";

    background-color: $ebony-clay;

    @media (min-width: 816px) {
      width: 756px;
    }
  }
}

.px-grid-matrix {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  &__column {
    display: flex;
    flex-direction: column-reverse;
    flex-grow: 1;
    justify-content: space-between;
    max-width: 70px;
  }

  &__cell {
    display: block;
    flex: 0 0 22px;
    max-width: 70px;
    margin-top: 10px;

    border-radius: 4px;
  }
}

.px-grid__level-text:first-child,
.px-grid__level-text:last-child {
  @include grotesk(medium);

  display: block;
}

.px-grid.px-grid--small {
  height: 159px;

  .px-grid__levels {
    position: relative;
    left: -7px;
    height: calc(100% - 16px);
    padding: 0 1px 2px 0;
  }

  .px-grid__level-text {
    display: none;
    font-size: 0.8rem;
  }

  .px-grid__categories {
    padding-bottom: 0;
  }

  .px-grid__category {
    width: 29px;

    font-size: 0.8rem;
    line-height: 1;
  }

  .px-grid__main-area {
    max-width: 274px;
  }

  .px-grid-matrix__column {
    max-width: 29px;
  }

  .px-grid-matrix__cell {
    flex-basis: 12px;
    margin-top: 4px;

    border-radius: 1.5px;
  }

  .px-grid__current-level {
    left: 6px;
    height: 15px;
    font-size: 0.8rem;
    font-style: normal;
    line-height: 1;
  }

  .px-grid__current-level::before {
    right: 0;
    bottom: -1px;
    width: 292px;
    height: 2px;
  }
}

.px-grid.px-grid--medium {
  height: 212px;

  .px-grid__levels {
    position: relative;
    left: -11px;
    height: calc(100% - 22px);
    padding: 0 1px 2px 0;
  }

  .px-grid__level-text {
    display: none;
    font-size: to-rem(14px);
  }

  .px-grid__categories {
    padding-bottom: 0;
  }

  .px-grid__category {
    width: 40px;

    font-size: to-rem(14px);
    line-height: 1;
  }

  .px-grid__main-area {
    max-width: 375px;
  }

  .px-grid-matrix {
    margin-top: 1px;
  }

  .px-grid-matrix__column {
    max-width: 40px;
  }

  .px-grid-matrix__cell {
    flex-basis: 16px;
    margin-top: 6px;

    border-radius: 2.3px;
  }

  .px-grid__current-level {
    left: 11px;
    height: 15px;
    font-size: 0.8rem;
    font-style: normal;
    line-height: 1;
  }

  .px-grid__current-level::before {
    right: 0;
    bottom: -1px;
    width: 402px;
    height: 2px;
  }
}

.px-grid.px-grid--inactive {
  .px-grid__category,
  .px-grid__level-text {
    opacity: 0.35;
  }

  .px-grid-matrix__cell {
    opacity: 0.7;
  }

  .px-grid__current-level {
    opacity: 0;
  }
}
</style>
