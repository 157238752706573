import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PxStageStatusItem = _resolveComponent("PxStageStatusItem")!

  return (_openBlock(), _createBlock(_component_PxStageStatusItem, {
    active: _ctx.active,
    icon: _ctx.icon,
    "icon-size": _ctx.iconSize,
    "is-clickable": _ctx.isClickable,
    title: _ctx.title,
    onStatusClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isClickable ? _ctx.$emit('status-click') : null))
  }, null, 8, ["active", "icon", "icon-size", "is-clickable", "title"]))
}