import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-2ef7fe23"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "px-textarea" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ElInput = _resolveComponent("ElInput")!
  const _component_PxCounter = _resolveComponent("PxCounter")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ElInput, {
      ref: "input",
      modelValue: _ctx.innerValue,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.innerValue) = $event)),
      autosize: _ctx.autosize,
      disabled: _ctx.disabled,
      "max-chars": _ctx.maxChars,
      name: _ctx.name,
      placeholder: _ctx.placeholder,
      readonly: _ctx.readonly,
      resize: _ctx.resize,
      rows: _ctx.rows,
      type: "textarea",
      onBlur: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('blur'))),
      onFocus: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('focus'))),
      onKeyup: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('keyup', $event)))
    }, null, 8, ["modelValue", "autosize", "disabled", "max-chars", "name", "placeholder", "readonly", "resize", "rows"]),
    (_ctx.showCounter)
      ? (_openBlock(), _createBlock(_component_PxCounter, {
          key: 0,
          "remaining-chars": _ctx.remainingChars,
          class: "px-textarea-counter"
        }, null, 8, ["remaining-chars"]))
      : _createCommentVNode("", true)
  ]))
}