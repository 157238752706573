<template>
  <PxPanel
    class="panel-grid__block company-panel-placeholder px-panel--xs-no-side-borders"
  >
    <ContentPlaceholders :rounded="true">
      <div class="company-panel-placeholder__header">
        <ContentPlaceholdersHeading :img="true" />
        <ContentPlaceholdersText
          :lines="1"
          class="company-panel-placeholder__cta"
        />
      </div>
      <div class="company-panel-placeholder__sectors">
        <ContentPlaceholdersHeading />
        <ContentPlaceholdersText :lines="3" />
      </div>
      <div class="company-panel-placeholder__info">
        <div class="item item--founded">
          <ContentPlaceholdersHeading />
        </div>
        <div class="item item--sectors">
          <ContentPlaceholdersHeading />
          <ContentPlaceholdersText :lines="3" />
        </div>
        <div class="item item--about">
          <ContentPlaceholdersHeading />
          <ContentPlaceholdersText :lines="4" />
        </div>
        <div class="item item--website">
          <ContentPlaceholdersHeading />
        </div>
        <div class="item item--email">
          <ContentPlaceholdersHeading />
        </div>
      </div>
    </ContentPlaceholders>
  </PxPanel>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "CompanyPanelPlaceholder",
});
</script>

<style lang="scss" scoped>
.company-panel-placeholder {
  padding: 0 4px;
}

.company-panel-placeholder :deep() .px-panel__body {
  @include breakpoint-down(sm) {
    padding: 21px 10px 13px;
  }
}

.company-panel-placeholder__sectors :deep() {
  margin-top: 31px;

  @include breakpoint-down(sm) {
    display: none;
  }

  .vue-content-placeholders-heading__title {
    width: 10%;
  }

  .vue-content-placeholders-heading__subtitle {
    display: none;
  }

  .vue-content-placeholders-text {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    width: 61%;
    margin-top: 5px;
  }
}

.company-panel-placeholder__header :deep() {
  position: relative;

  .company-panel-placeholder__cta {
    position: absolute;
    top: -16px;
    right: 0;

    @include breakpoint-down(sm) {
      position: relative;
      top: 7px;
    }
  }

  .company-panel-placeholder__cta .vue-content-placeholders-text__line {
    width: 143px;
    height: 32px;

    @include breakpoint-down(sm) {
      width: 100%;
    }
  }

  .vue-content-placeholders-heading__img {
    width: 95px;
    height: 95px;
    margin-top: -36px;

    @include breakpoint-down(sm) {
      width: 74px;
      height: 74px;
      margin-top: -34px;
    }
  }

  .vue-content-placeholders-heading__content {
    position: relative;
    top: -5px;
  }

  .vue-content-placeholders-heading__title {
    width: 60%;
  }

  .vue-content-placeholders-heading__subtitle {
    position: relative;
    top: 5px;
    width: 45%;
  }
}

.company-panel-placeholder__info :deep() {
  margin-top: 22px;
  column-count: 2;

  @include breakpoint-down(sm) {
    display: flex;
    flex-direction: column;
    column-count: 1;
  }

  .vue-content-placeholders-heading__subtitle {
    margin-top: 5px;
  }

  .item {
    /* Avoid text breaking into another column */
    page-break-inside: avoid;
    -webkit-column-break-inside: avoid;
    break-inside: avoid;
  }

  .item:nth-child(even) {
    margin-top: 28px;
  }

  .item--founded {
    @include breakpoint-down(sm) {
      order: 2;
      margin-top: 14px;
    }

    .vue-content-placeholders-heading__title {
      width: 35%;
    }

    .vue-content-placeholders-heading__subtitle {
      width: 45%;

      @include breakpoint-down(sm) {
        margin-top: 2px;
      }
    }
  }

  .item--website {
    @include breakpoint-down(sm) {
      order: 3;
      margin-top: 26px !important;
    }

    .vue-content-placeholders-heading__title {
      width: 20%;
    }

    .vue-content-placeholders-heading__subtitle {
      width: 55%;

      @include breakpoint-down(sm) {
        width: 53%;
        margin-top: 1px;
      }
    }
  }

  .item--about {
    @include breakpoint-down(sm) {
      order: 0;
      margin-top: 7px;
    }

    .vue-content-placeholders-heading__title {
      width: 15%;

      @include breakpoint-down(sm) {
        width: 28%;
      }
    }

    .vue-content-placeholders-heading__subtitle {
      display: none;
    }

    .vue-content-placeholders-text {
      padding-right: 20px;
      margin-top: 2px;

      @include breakpoint-down(sm) {
        padding-right: 0;
      }
    }

    .vue-content-placeholders-text__line {
      height: 15px;
      margin-bottom: 11px;

      @include breakpoint-down(sm) {
        margin-bottom: 9px;
      }
    }
  }

  .item--email {
    margin-top: 23px;

    @include breakpoint-down(sm) {
      order: 4;
    }

    .vue-content-placeholders-heading__title {
      width: 15%;
    }

    .vue-content-placeholders-heading__subtitle {
      width: 80%;

      @include breakpoint-down(sm) {
        width: 70%;
        margin-top: 1px;
      }
    }
  }

  .item--sectors {
    @include breakpoint-down(sm) {
      order: 1;
      margin-top: 20px !important;
    }

    .vue-content-placeholders-heading__title {
      width: 17%;
    }

    .vue-content-placeholders-heading__subtitle {
      display: none;
    }

    .vue-content-placeholders-text {
      display: flex;
      flex-flow: row wrap;
      margin-top: 3px;
    }

    .vue-content-placeholders-text__line {
      width: auto;
      height: 26px;
      margin-right: 10px;

      &:nth-child(1) {
        width: 154px;
      }

      &:nth-child(2) {
        width: 174px;
      }

      &:nth-child(3) {
        width: 74px;
      }
    }
  }
}
</style>
