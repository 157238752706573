import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PxNavigationTab = _resolveComponent("PxNavigationTab")!

  return (_openBlock(), _createBlock(_component_PxNavigationTab, {
    "selected-tab": _ctx.innerActiveTab,
    "onUpdate:selectedTab": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.innerActiveTab) = $event)),
    "navigation-tabs": _ctx.navigationTabs
  }, null, 8, ["selected-tab", "navigation-tabs"]))
}