import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-7c6ff280"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "match-details-modal-table" }
const _hoisted_2 = {
  key: 0,
  class: "match-details-modal-table__gradient"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PxTableHeader = _resolveComponent("PxTableHeader")!
  const _component_MatchDetailsTableRow = _resolveComponent("MatchDetailsTableRow")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_PxTableHeader, { items: _ctx.tableHeaderWithUserType }, null, 8, ["items"]),
    _createElementVNode("div", {
      ref: "table",
      class: "match-details-modal-table__wrapper",
      onScroll: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.checkTableScroll && _ctx.checkTableScroll(...args)))
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tableData, (data, index) => {
        return (_openBlock(), _createElementBlock(_Fragment, null, [
          (data.criteria)
            ? (_openBlock(), _createBlock(_component_MatchDetailsTableRow, {
                key: index,
                "auth-user-answer": data.my_answer,
                criteria: data.criteria,
                "has-match": data.match,
                impact: data.impact,
                "profile-user-answer": data.matched_answer,
                class: "match-details-modal-table__row"
              }, null, 8, ["auth-user-answer", "criteria", "has-match", "impact", "profile-user-answer"]))
            : _createCommentVNode("", true)
        ], 64))
      }), 256))
    ], 544),
    (_ctx.isGradientVisible)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2))
      : _createCommentVNode("", true)
  ]))
}