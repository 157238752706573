import { IBaseState } from "@/services/store/store-types";
import { IViralLevelSelection } from "@/services/data/viral-level/viral-level.interface";
import { IAffiliate } from "@/services/data/affiliate/affiliate.interface";
import { ILevel } from "@/services/data/level/level.interface";
import { IPendingUser } from "./types/pending-user.interface";
import { IMatchingQuestion } from "@/services/data/matching-questionary/matching-question.interface";

/**
 * Represents the store structure for the level selection for each category.
 */
export interface IViralLevelState extends IBaseState, IViralLevelSelection {
  /**
   * Store the current selected category.
   */
  selectedCategory: number;

  /**
   * This is used to track if the user already interacted
   * with the component.
   */
  firstInteraction: boolean;

  /**
   * Stores the final assessment level.
   */
  finalLevel?: ILevel;

  /**
   * Stores the affiliate if the used is accessing from a
   * affiliate URL.
   */
  affiliate: IAffiliate | null;

  /**
   * Stores the affiliate question bundle
   */
  affiliateQuestionBundle: Array<IMatchingQuestion> | null;

  /**
   * Stores the team members question bundle
   */
  teamMembersQuestionBundle: Array<IMatchingQuestion> | null;

  /**
   * Informs when the company data already was submitted.
   */
  submitted: boolean;

  /**
   * Stores the pending user data.
   */
  pendingUser: IPendingUser | null;

  /**
   * List of metadata specific for the assessment.
   */
  metadata: { [key: string]: any };
}

/**
 * Enumeration with all available module actions.
 */
export enum EViralLevelActions {
  RESET = "viralLevel/reset",
  SET_LEVEL = "viralLevel/setLevel",
  SET_LEVELS = "viralLevel/setLevels",
  SET_SELECTED_CATEGORY = "viralLevel/setCurrentCategory",
  INTERACTION = "viralLevel/interaction",
  SET_LOADING = "viralLevel/setLoading",
  COMPUTE_LEVEL = "viralLevel/computeLevel",
  FETCH_AFFILIATE = "viralLevel/setAffiliate",
  CLEAR_AFFILIATE = "viralLevel/clearAffiliate",
  FETCH_AFFILIATE_QUESTION_BUNDLE = "viralLevel/fetchAffiliateQuestionBundle",
  SET_PENDING_AFFILIATE_PROGRAM_RESPONSES = "viralLevel/setPendingAffiliateProgramResponses",
  SET_AFFILIATE_PROGRAM_RESPONSES = "viralLevel/setAffiliateProgramResponses",
  SET_AS_SUBMITTED = "viralLevel/setAsSubmitted",
  FETCH_ASSESSMENT = "viralLevel/fetchAssessment",
  SET_FINAL_LEVEL = "viralLevel/setFinalLevel",
  SET_PENDING_USER = "viralLevel/setPendingUser",
  SET_PENDING_ASSESSMENT = "viralLevel/setPendingAssessment",
  SET_METADATA = "viralLevel/setMetadata",
  REMOVE_METADATA = "viralLevel/removeMetadata",
  FETCH_ASSESSMENT_FLOW_INIT_DATA = "viralLevel/fetchAssessmentFlowInitData",
}

/**
 * Enumeration with all available module mutations.
 */
export enum EViralLevelMutations {
  SET_ERROR = "setError",
  SET_LOADING = "setLoading",
  SET_LEVELS = "setLevels",
  SET_LEVEL = "setLevel",
  SET_SELECTED_CATEGORY = "setCurrentCategory",
  SET_INTERACTION = "setInteraction",
  SET_FINAL_LEVEL = "setFinalLevel",
  SET_AFFILIATE = "setAffiliate",
  SET_AFFILIATE_PROGRAM_QUESTION_BUNDLE = "setAffiliateProgramQuestionBundle",
  SET_TEAM_MEMBERS_QUESTION_BUNDLE = "setTeamMembersQuestionBundle",
  SET_SUBMITTED = "setSubmitted",
  SET_METADATA = "setMetadata",
  REMOVE_METADATA = "removeMetadata",
  SET_PENDING_USER = "setPendingUser",
  SET_PENDING_ASSESSMENT = "setPendingAssessment",
}

export enum EViralLevelGetters {
  SELECTED_STEP_DATA = "viralLevel/selectedCategory",
  CURRENT_CATEGORY = "viralLevel/currentCategory",
  SINGLE_METADATA = "viralLevel/singleMetadata",
}
