<template>
  <div class="verify-account-banner">
    <div class="verify-account-banner__wrapper">
      <div class="verify-account-banner__left">
        <PxIcon :size="20" name="information--dark-blue" />
        <div class="verify-account-banner__text">
          <p>
            <strong>
              {{ $t("profile.verifyAccountBanner.title") }}
            </strong>
          </p>
          <p>
            {{
              $t("profile.verifyAccountBanner.description", {
                email: authUserEmail,
              })
            }}
            <span>
              <PxButton
                class="el-button--link-blue"
                data-testid="verify-account-banner-resend-link"
                type="link"
                @click="resendEmail"
              >
                {{ $t("profile.verifyAccountBanner.cta") }}
              </PxButton>
            </span>
          </p>
        </div>
      </div>
      <div class="verify-account-banner__right">
        <PxIcon
          :size="24"
          data-testid="verify-account-banner-close-button"
          name="cancel--gray"
          @click="closeBanner"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { resendEmailProvider } from "@/modules/authentication/services/data/resend-email/resend-email.provider";

export default defineComponent({
  name: "VerifyAccountBanner",

  computed: {
    authUserEmail(): string {
      return this.$store.get("auth/user.email");
    },
  },

  methods: {
    async resendEmail() {
      try {
        await resendEmailProvider.create({
          email: this.authUserEmail,
        });

        this.$message({
          message: this.$t(
            "authentication.login.messages.confirmationEmail",
          ) as string,
          type: "success",
          customClass: "is-navbar",
        });

        this.closeBanner();
      } catch {
        this.$message({
          message: this.$t("common.errors.global.alertTitle"),
          type: "error",
          duration: 3000,
          customClass: "is-navbar",
        });
      }
    },

    closeBanner() {
      this.$emit("close");
    },
  },
});
</script>

<style lang="scss" scoped>
.verify-account-banner {
  width: 100%;

  border: 1px solid rgba(41, 114, 202, 0.2);
  background-color: #f2f8ff;

  &__wrapper {
    width: 100%;
    margin: 0 auto;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;

    padding: 15px 152px 15px 149px;

    max-width: 1440px;

    @include breakpoint-down(md) {
      padding: 15px;
    }
  }

  &__left {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;

    gap: 16px;

    p {
      margin: 0;
      line-height: 1;
      font-size: 14px;

      @include breakpoint-down(md) {
        line-height: 1.2;
      }
    }

    p:first-of-type {
      margin-top: 2px;

      @include breakpoint-down(md) {
        margin-bottom: 2px;
      }
    }

    p:last-of-type {
      letter-spacing: -0.33px;
      color: rgba(39, 39, 64, 0.7);
    }

    :deep() .el-button {
      padding: 0;
      height: auto;
      left: 2px;
    }

    :deep() .el-button span {
      font-size: 14px;
    }
  }

  &__right :deep() .px-icon {
    padding-top: 1px;

    cursor: pointer;
  }
}
</style>
