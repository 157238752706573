import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-b36fb0c2"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "px-matching-interest__tip"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PxIcon = _resolveComponent("PxIcon")!

  return (_ctx.matchingTip)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_PxIcon, {
          size: 14,
          class: "px-matching-interest__tipIcon",
          name: "star--yellow"
        }),
        _createTextVNode(" " + _toDisplayString(_ctx.matchingTip), 1)
      ]))
    : _createCommentVNode("", true)
}