<template>
  <div class="milestone-plan-header">
    <div class="milestone-plan-header__header">
      <div
        :style="{ backgroundColor: categoryColor }"
        class="category-indicator"
      />
      <div class="milestone-plan-header__wrapper">
        <span class="level-number">
          {{ levelNumber }}
        </span>
        <span class="category-name">
          {{ categoryName }}
        </span>
        <span class="category-level-description">
          - {{ categoryDescription }}
        </span>
      </div>
    </div>
    <div class="milestone-plan-header__content">
      <MilestonePlanState
        :color="categoryColor"
        :completed="isMilestoneComplete"
        :future="isFutureMilestone"
        :in-progress="isMilestoneInProgress"
        :planned="isMilestonePlanned"
      />
      <div
        v-if="isMilestoneCritical || isMilestoneComplete || targetDate"
        class="space-bullet"
      />
      <CompleteMilestoneInfoIndicator
        v-if="isMilestoneComplete"
        :has-evidence="hasEvidence"
        data-testid="complete-indicator"
      />
      <template v-else>
        <CriticalMilestoneIndicator
          v-if="isMilestoneCritical"
          :is-visitor="isVisitor"
          data-testid="critical-indicator"
        />
        <div
          v-if="targetDate"
          :class="{ 'target-date--critical': isMilestoneCritical }"
          class="target-date"
          data-testid="target-date"
        >
          {{ $t("milestonePlanner.milestone.targetDate") }}:
          <strong class="date">
            {{ formattedTargetDate }}
          </strong>
        </div>
      </template>
    </div>
    <!-- TODO: Add print features -->
    <!-- Consider adding feature flags for each button -->
    <div
      v-if="!isVisitor && milestonePlanner"
      class="milestone-plan-header__buttons"
    >
      <SharePlanner
        ref="sharePlannerButton"
        :milestone-planner="milestonePlanner"
        data-testid="milestone-planner-header-share-button"
      />
      <!-- <PxButton v-bind="$options.static.printButton" /> -->
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import MilestonePlanState from "@/modules/milestone-planner/components/milestone-plan-state/milestone-plan-state.vue";
import CompleteMilestoneInfoIndicator from "@/modules/milestone-planner/components/complete-milestone-info-indicator/complete-milestone-info-indicator.vue";
import CriticalMilestoneIndicator from "@/modules/milestone-planner/components/critical-milestone-indicator/critical-milestone-indicator.vue";
import SharePlanner from "@/modules/milestone-planner/components/share-planner/share-planner.vue";

import {
  EPxButtonSize,
  EPxButtonVariant,
} from "@/components/px-button/px-button.types";
import moment from "moment";
import { IMilestonePlanner } from "@/modules/milestone-planner/services/store/milestone-planner/milestone-planner.types";

export default defineComponent({
  name: "MilestonePlanHeader",

  components: {
    MilestonePlanState,
    CompleteMilestoneInfoIndicator,
    CriticalMilestoneIndicator,
    SharePlanner,
  },

  props: {
    categoryColor: {
      type: String,
      required: true,
    },
    categoryName: {
      type: String,
      required: true,
    },
    categoryDescription: {
      type: String,
      required: true,
    },
    hasEvidence: {
      type: Boolean,
      default: false,
    },
    isFutureMilestone: {
      type: Boolean,
      default: false,
    },
    isMilestoneComplete: {
      type: Boolean,
      default: false,
    },
    isMilestoneCritical: {
      type: Boolean,
      default: false,
    },
    isMilestoneInProgress: {
      type: Boolean,
      default: false,
    },
    isMilestonePlanned: {
      type: Boolean,
      default: false,
    },
    levelNumber: {
      type: Number,
      required: true,
    },
    targetDate: {
      type: String as () => string | null,
      default: null,
    },
    isVisitor: {
      type: Boolean,
      default: false,
    },
    milestonePlanner: {
      type: Object as () => IMilestonePlanner,
      default: () => null,
    },
  },

  static: {
    shareButton: {
      size: EPxButtonSize.SMALL,
      variant: EPxButtonVariant.FLUID,
    },
    printButton: {
      icon: "print",
      size: EPxButtonSize.SMALL,
      variant: EPxButtonVariant.COMPACT,
    },
  },

  computed: {
    shareButtonProps(): any {
      return {
        ...this.$options.static.shareButton,
        label: this.$t("milestonePlanner.share"),
      };
    },

    formattedTargetDate(): string {
      return moment(this.targetDate).format("DD MMMM, YYYY");
    },
  },
});
</script>

<style lang="scss">
.milestone-plan-header {
  display: grid;
  grid-template-areas:
    "header buttons"
    "content buttons";
  grid-template-rows: min-content auto;
  grid-template-columns: auto 145px;
  width: 100%;
  height: 123px;
  background-image: linear-gradient(to top, $alabaster, #fff);

  &__header {
    display: grid;
    grid-area: header;
    grid-template-columns: 6px auto;
    gap: 8px;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 18px 30px 0 33px;
  }

  &__wrapper {
    max-height: 60px;
    overflow: hidden;
  }

  &__buttons {
    display: flex;
    grid-area: buttons;
    gap: 17px;
    align-items: center;
    justify-content: flex-start;
  }

  .category-indicator {
    position: relative;
    top: 13px;
    width: 6px;
    height: 6px;
    border-radius: 50%;
  }

  .level-number {
    @include grotesk(semiBold);

    font-size: 20px;
    line-height: 30px;
  }

  .category-name {
    position: relative;
    left: 4px;
    margin-right: 8px;
    font-size: 18px;
    font-weight: 600;
    line-height: 28px;
    color: $ebony-clay;
    text-align: left;
    letter-spacing: 0.1px;
  }

  .category-level-description {
    position: relative;
    margin-left: -4px;
    font-size: 17px;
    line-height: 30px;
    color: $ebony-clay;
    letter-spacing: -0.39px;
  }

  &__content {
    position: relative;
    display: flex;
    grid-area: content;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 11px 0 0 33px;

    .space-bullet {
      position: relative;
      top: 7px;
      left: 11px;
      width: 4px;
      height: 4px;
      margin-right: 23px;
      background-color: $manatee;
      border-radius: 50%;
    }

    .complete-milestone-info-indicator {
      margin: 0;
    }

    .critical-milestone-indicator {
      margin: 0;
      margin-right: 5px;
    }

    .critical-milestone-indicator div {
      margin-left: 5px;
    }

    .critical-milestone-indicator img {
      top: -4px;
    }

    .complete-milestone-info-indicator__label {
      right: 0;
      letter-spacing: -0.3px;
    }
  }

  &__content .target-date {
    margin-top: 2px;
    font-size: 12px;
    line-height: 14px;
    color: $manatee;

    &--critical {
      color: $light-orange;
    }

    .date {
      @include grotesk(semiBold);

      position: relative;
      right: 1px;
    }
  }
}
</style>
