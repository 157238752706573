import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, mergeProps as _mergeProps, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "subscription-modal__card-wrapper" }
const _hoisted_2 = { class: "subscription-modal__card-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PxIcon = _resolveComponent("PxIcon")!
  const _component_PricingCard = _resolveComponent("PricingCard")!
  const _component_PxButton = _resolveComponent("PxButton")!
  const _component_ElDialog = _resolveComponent("ElDialog")!

  return (_openBlock(), _createBlock(_component_ElDialog, {
    ref: "modal",
    modelValue: _ctx.innerVisibility,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.innerVisibility) = $event)),
    "close-on-click-modal": false,
    top: `0`,
    class: "subscription-modal el-dialog--compact-footer",
    width: "606px"
  }, {
    header: _withCtx(() => [
      _createElementVNode("header", null, [
        _createVNode(_component_PxIcon, {
          size: 40,
          name: "abaca-logo--circle"
        }),
        _createElementVNode("h1", null, _toDisplayString(_ctx.$t("profile.subscriptionModal.title")), 1),
        _createElementVNode("p", null, _toDisplayString(_ctx.$t("profile.subscriptionModal.description[0]")), 1),
        _createElementVNode("p", null, _toDisplayString(_ctx.$t("profile.subscriptionModal.description[1]")), 1)
      ])
    ]),
    default: _withCtx(() => [
      _createElementVNode("main", null, [
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_PricingCard, _normalizeProps(_guardReactiveProps(_ctx.pricingCardsData.free)), null, 16),
          _createVNode(_component_PxButton, _mergeProps({ class: "subscription-modal__card-cta" }, _ctx.freeButtonProps, { onClick: _ctx.continueAsFreeUser }), null, 16, ["onClick"])
        ]),
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_PricingCard, _normalizeProps(_guardReactiveProps(_ctx.pricingCardsData.pro)), null, 16),
          _createVNode(_component_PxButton, _mergeProps({ class: "subscription-modal__card-cta" }, _ctx.proButtonProps, { onClick: _ctx.upgradeToProUser }), null, 16, ["onClick"])
        ])
      ])
    ]),
    _: 1
  }, 8, ["modelValue"]))
}