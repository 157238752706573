import {
  GenericProvider,
  EProviderFeatures,
  Provider,
} from "@/services/data/generic-provider";
import { baseAPIUrl } from "@/services/utils/utils";

import { IOffering } from "./offering.interface";

@Provider(`${baseAPIUrl}/offering`, [
  EProviderFeatures.CREATE,
  EProviderFeatures.PATCH,
  EProviderFeatures.DESTROY,
])
class OfferingProvider extends GenericProvider<IOffering> {}

export const offeringProvider = new OfferingProvider();
