import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-8ff035c8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "milestones-full-grid-cell__description" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PxIcon = _resolveComponent("PxIcon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([{ 'milestones-full-grid-cell--completed': _ctx.completed }, "milestones-full-grid-cell"]),
    style: _normalizeStyle(_ctx.cellStyles)
  }, [
    _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.description), 1),
    (_ctx.completed)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          (_ctx.evidenceProvided)
            ? (_openBlock(), _createBlock(_component_PxIcon, {
                key: 0,
                class: "milestones-full-grid-cell__icon",
                name: "evidence-provided",
                size: "18"
              }))
            : (_openBlock(), _createBlock(_component_PxIcon, {
                key: 1,
                class: "milestones-full-grid-cell__icon",
                name: "self-assessed",
                size: "16"
              }))
        ], 64))
      : (_ctx.critical)
        ? (_openBlock(), _createBlock(_component_PxIcon, {
            key: 1,
            class: "milestones-full-grid-cell__icon milestones-full-grid-cell__icon--critical",
            name: "critical-milestone",
            size: "22"
          }))
        : _createCommentVNode("", true)
  ], 6))
}