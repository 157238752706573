<template>
  <div class="submission-panel__supporter-info">
    <div>
      <span class="submission-panel__title">
        {{ title }}
      </span>
      <span class="submission-panel__profile-link" @click="goToProfile">
        {{ $t("program.submission.seeProfile") }}
      </span>
    </div>
    <h3 class="submission-panel__subtitle">
      {{ subtitle }}
    </h3>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import { ROUTE_PROFILE } from "@/modules/profile/services/router/routes-names";

export default defineComponent({
  props: {
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      required: true,
    },
    id: {
      type: Number,
      required: true,
    },
  },

  methods: {
    goToProfile() {
      this.$router.push({
        name: ROUTE_PROFILE,
        params: {
          id: this.id.toString(),
        },
      });
    },
  },
});
</script>

<style lang="scss" scoped>
.submission-panel__title {
  @include grotesk(semiBold);

  margin-right: 8px;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: -0.2px;

  @include breakpoint-up(md) {
    font-size: 22px;
    line-height: 27px;
  }
}

.submission-panel__profile-link {
  @include grotesk(semiBold);

  font-size: 15px;
  color: $ebony-clay;
  text-decoration: underline;
  cursor: pointer;
  transition: color 0.25s ease-in-out;

  &:hover {
    color: $manatee;
  }
}

.submission-panel__subtitle {
  @include grotesk(medium);

  margin: 6px 0 0;
  font-size: to-rem(15px);
  line-height: 18px;
  color: $manatee;
  letter-spacing: -0.2px;

  @include breakpoint-up(md) {
    margin: 7px 0 0;
  }
}
</style>
