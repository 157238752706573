import { h, defineComponent, resolveComponent, markRaw, Component } from "vue";
import { ErrorsManager } from "@/services/errors-manager";
import { MessageHandler, FormInstance } from "element-plus";
import {
  generateEmailValidator,
  generateRequiredValidator,
} from "@/services/errors/validator-generators";
import {
  EAuthActions,
  IAuthState,
} from "@/modules/authentication/services/store/auth/auth-types";
import { GenericProviderException } from "@/services/data/exceptions/generic-provider.exception";
import { ErrorsProviderException } from "@/services/data/exceptions/errors-provider.exception";
import { resendEmailProvider } from "@/modules/authentication/services/data/resend-email/resend-email.provider";
import { ROUTE_AUTHENTICATION_PASSWORD_RECOVER } from "@/modules/authentication/services/router/routes-names";
import { ROUTE_PROFILE } from "@/modules/profile/services/router/routes-names";

export default defineComponent({
  name: "SignInModal",

  props: {
    hasInvalidAccount: {
      type: Boolean,
      default: false,
    },

    visibility: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      errors: new ErrorsManager({
        non_field_errors: {
          invalid: this.$t("authentication.login.errors.invalidCredentials"),
        },
      }),

      innerVisibility: false,
      isSubmissionClose: false,
      hasServerErrors: false,
      formWrapperScrollAtEnd: false,

      // This field is used to show an empty error when
      // the credentials are invalid
      emailErrorMsg: null as null | string,

      infoMessage: null as null | MessageHandler,

      data: {
        email: "",
        password: "",
        remember: false,
        redirectUser: false,
      },

      rules: {
        email: generateEmailValidator(
          this,
          "authentication.login.form.fields.email",
          true,
        ),
        password: generateRequiredValidator(
          this,
          "authentication.login.form.fields.password",
        ),
      },
      PxButton: markRaw(resolveComponent("PxButton") as Component),
    };
  },

  computed: {
    /**
     * Gets the state of the auth module.
     */
    authState(): IAuthState {
      return this.$store.state.auth as IAuthState;
    },

    /**
     * Get the loading state of the auth module.
     */
    loading(): boolean {
      return this.authState.loading;
    },

    /**
     * Disable login button when one or more fields
     * are empty.
     */
    isLoadingButtonDisabled(): boolean {
      return (
        this.hasInvalidAccount ||
        this.data.email.length === 0 ||
        this.data.password.length === 0
      );
    },

    /**
     * Retrieve any existing error that can occur during
     * the login process.
     */
    loginError(): GenericProviderException | null {
      return this.authState.error as GenericProviderException | null;
    },

    showEmailAsError(): boolean {
      return this.errors.has("non_field_errors", "invalid");
    },

    isSkippable(): boolean {
      return !this.hasServerErrors && !this.hasInvalidAccount;
    },

    modalError(): string {
      return this.hasInvalidAccount
        ? (this as any).componentCopy.form.errors.invalidAccount
        : "";
    },

    componentCopy() {
      return this.$tm("supporters.component.signInModal") as {
        title: string;
        form: {
          fields: {
            email: string;
            password: string;
            remember: string;
          };
          errors: {
            invalidAccount: string;
          };
          submitButton: string;
        };
        forgetPasswordLink: string;
        noAccount: string;
        tellMore: string;
      };
    },
  },

  watch: {
    /**
     * Watch for changes with the login error state.
     *
     * When the newVal is defined that means that an error
     * occurred and it's necessary to set the errors,
     * otherwise clean up the errors manager.
     */
    loginError(newVal) {
      if (newVal && newVal instanceof ErrorsProviderException) {
        this.errors.record(newVal.response.data.errors);
        this.handleEmailValidationError();
      } else if (newVal) {
        this.handleAccountError();
      } else {
        this.errors.clear();
      }
    },

    /**
     * Clean errors when any form field change.
     */
    data: {
      deep: true,
      handler() {
        this.errors.clear();
      },
    },

    visibility: {
      immediate: true,
      async handler(newVal: boolean) {
        this.innerVisibility = newVal;

        if (!newVal) {
          return;
        }
      },
    },

    innerVisibility(newVal: boolean, oldVal: boolean) {
      if (newVal === oldVal) {
        return;
      }

      if (!newVal) {
        this.$emit("update:visibility", newVal);
      }
    },

    hasInvalidAccount(newValue: boolean) {
      if (newValue) {
        setTimeout(() => {
          this.$router.replace({ name: ROUTE_PROFILE });
        }, 10000);
      }
    },
  },

  methods: {
    /**
     * Close modal and return to previous route
     */
    onModalClose() {
      if (this.isSubmissionClose) {
        return;
      }

      this.$router.back();
      this.$emit("close");
    },

    /**
     * When a email validation error occurs show an message
     * to inform the user to revisit his/her email to  check
     * for the validation email.
     */
    handleEmailValidationError() {
      if (!this.errors.has("non_field_errors", "email_not_verified")) {
        return;
      }

      const message = this.$t(
        "authentication.login.errors.emailNotValidated",
      ) as string;
      const resendLinkText = this.$t(
        "authentication.login.resendLink",
      ) as string;

      const vNode = h("p", { class: "el-message__content" }, [
        message,
        h(
          this.PxButton,
          {
            type: "link",
            onClick: this.onClickResendEmail,
            class: "el-button--link-white",
          },
          {
            default: () => resendLinkText,
          },
        ),
      ]);

      // TODO: Use PxModalMessage instead of Element Message
      // Shows message under Navbar, due to message global context,
      // which will be shown behind modal overlay background
      this.infoMessage = this.$message({
        message: vNode,
        type: "info",
        duration: 0,
        customClass: "is-full",
      });
    },

    async onClickResendEmail() {
      if (this.infoMessage !== null) {
        this.infoMessage.close();
      }

      await resendEmailProvider.create({
        email: this.data.email,
      });

      // TODO: Use PxModalMessage instead of Element Message
      // Shows message under Navbar, due to message global context,
      // which will be shown behind modal overlay background
      this.$message({
        message: this.$t(
          "authentication.login.messages.confirmationEmail",
        ) as string,
        type: "success",
        customClass: "is-full",
      });
    },

    handleAccountError() {
      // TODO: Use PxModalMessage instead of Element Message
      // Shows message under Navbar, due to message global context,
      // which will be shown behind modal overlay background
      this.$message({
        message: this.$t(
          "authentication.login.errors.invalidAccount",
        ) as string,
        type: "error",
        duration: 10000,
        customClass: "is-full",
      });
    },

    checkFormWrapperScroll() {
      const wrapper = this.$refs.formWrapper as HTMLElement;

      this.formWrapperScrollAtEnd =
        wrapper.scrollTop === wrapper.scrollHeight - wrapper.offsetHeight;
    },

    /**
     * Handler for the click on the Forgot your password button.
     */
    onClickPasswordRecover() {
      this.$router.push({ name: ROUTE_AUTHENTICATION_PASSWORD_RECOVER });
    },

    /**
     * Handler for the click on the login button.
     */
    async onClickLogin() {
      // Remove the informational message
      if (this.infoMessage) {
        this.infoMessage.close();
      }

      const form = this.$refs.form as FormInstance;

      try {
        await form.validate();

        // Flag to prevent side-effect onClose action
        this.isSubmissionClose = true;

        // Proceed to login
        await this.$store.dispatch(EAuthActions.LOGIN, this.data);

        // Check if login occurs
        await this.$nextTick();
        if (!this.authState.error) {
          this.$emit("submit");
        }
      } catch (_) {
        this.isSubmissionClose = false;
        return;
      }
    },

    gotoRegisterModal() {
      this.$emit("register", false);
    },
  },
});
