import { ActionTree } from "vuex";
import {
  IMatchingQuestionaryState,
  EMatchingQuestionaryActions,
  IMatchingQuestionaryFetchPayload,
  EMatchingQuestionaryMutations,
} from "./matching-questionary.types";
import { RootState } from "@/services/store/root-state";
import { getBaseName } from "@/services/store/utils/get-base-name";
import { matchingQuestionaryProvider } from "@/services/data/matching-questionary/matching-questionary.provider";
import { matchingResponsesProvider } from "@/services/data/matching-responses/matching-response.provider";
import { IMatchingResponse } from "@/services/data/matching-responses/matching-response.interface";
import { IMatchingQuestionaryCriteria } from "@/services/data/matching-questionary-criteria/matching-questionary-criteria.interface";
import { matchingCriteriaProvider } from "@/modules/matching/services/data/matching-criteria/matching-criteria.provider";
import { IMatchingQuestion } from "@/services/data/matching-questionary/matching-question.interface";

export const matchingQuestionaryActions: ActionTree<
  IMatchingQuestionaryState,
  RootState
> = {
  /**
   * Fetch questionary.
   *
   * @param param0
   * @param payload Page and per-page parameters
   */
  async [getBaseName(EMatchingQuestionaryActions.FETCH)](
    { commit, state },
    payload: IMatchingQuestionaryFetchPayload,
  ) {
    commit(EMatchingQuestionaryMutations.SET_ERROR, null);
    commit(EMatchingQuestionaryMutations.SET_LOADING, true);

    try {
      const data = await matchingQuestionaryProvider.getQuestions(payload);
      const results = data.results.filter(
        (question: IMatchingQuestion) => !question.is_team_member_question,
      ) as Array<IMatchingQuestion>;
      const questions = [...state.data, ...results];
      commit(
        EMatchingQuestionaryMutations.SET_CURRENT_QUESTION_INDEX,
        state.data.length === 0 ? 0 : state.currentQuestionIndex,
      );
      commit(EMatchingQuestionaryMutations.SET_DATA, questions);
      commit(EMatchingQuestionaryMutations.SET_TOTAL_QUESTIONS, data.count);
    } catch (e) {
      commit(EMatchingQuestionaryMutations.SET_ERROR, e);
    } finally {
      commit(EMatchingQuestionaryMutations.SET_LOADING, false);
    }
  },

  /**
   * Skip question.
   *
   * @param param0
   */
  [getBaseName(EMatchingQuestionaryActions.SKIP_QUESTION)]({ commit }) {
    commit(EMatchingQuestionaryMutations.INCREMENT);
  },

  /**
   * Submit Entrepreneur response.
   *
   * @param param0
   * @param payload Response to be submited
   */
  async [getBaseName(EMatchingQuestionaryActions.SUBMIT_RESPONSE)](
    { commit },
    payload: IMatchingResponse,
  ) {
    commit(EMatchingQuestionaryMutations.SET_ERROR, null);
    commit(EMatchingQuestionaryMutations.SET_LOADING, true);

    try {
      await matchingResponsesProvider.create(payload);

      commit(EMatchingQuestionaryMutations.INCREMENT);
    } catch (e) {
      commit(EMatchingQuestionaryMutations.SET_ERROR, e);
    } finally {
      commit(EMatchingQuestionaryMutations.SET_LOADING, false);
    }
  },

  /**
   * Submit Supporter criteria.
   *
   * @param param0
   * @param payload Criteria to be submited
   */
  async [getBaseName(EMatchingQuestionaryActions.SUBMIT_CRITERIA)](
    { commit },
    payload: IMatchingQuestionaryCriteria,
  ) {
    commit(EMatchingQuestionaryMutations.SET_ERROR, null);
    commit(EMatchingQuestionaryMutations.SET_LOADING, true);

    try {
      await matchingCriteriaProvider.create(payload);

      commit(EMatchingQuestionaryMutations.INCREMENT);
    } catch (e) {
      commit(EMatchingQuestionaryMutations.SET_ERROR, e);
    } finally {
      commit(EMatchingQuestionaryMutations.SET_LOADING, false);
    }
  },
};
