import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-60c78658"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "supporters-view__container" }
const _hoisted_2 = { class: "supporter-program__container" }
const _hoisted_3 = { class: "supporter-program__step-wrapper" }
const _hoisted_4 = { class: "supporter-program__step-number" }
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = { class: "supporter-program__cta" }
const _hoisted_7 = { class: "supporter-program__tip" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LangSwitcher = _resolveComponent("LangSwitcher")!
  const _component_PxButton = _resolveComponent("PxButton")!
  const _component_FormPresentationLayout = _resolveComponent("FormPresentationLayout")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_LangSwitcher),
    _createVNode(_component_FormPresentationLayout, {
      class: _normalizeClass({ 'supporters-view--simplified': !_ctx.withTeamSection }),
      "content-description": _ctx.viewContentDescription,
      "content-title": _ctx.affiliateName,
      "description-image-link": _ctx.imageLink,
      "description-image-title": _ctx.viewCopy.imageTitle,
      "has-description-background-image": true,
      "has-return-button": _ctx.hasPreviousPage,
      onPreviousPage: _ctx.onReturnButtonClick
    }, {
      content: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.stepsToDisplay, (step, key) => {
              return (_openBlock(), _createElementBlock("div", {
                key: `criteria-description-step--${key}`,
                class: "supporter-program__step-container"
              }, [
                _createElementVNode("div", _hoisted_4, _toDisplayString(key + 1), 1),
                _createElementVNode("div", {
                  class: "supporter-program__step-text",
                  innerHTML: step
                }, null, 8, _hoisted_5)
              ]))
            }), 128))
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createVNode(_component_PxButton, {
              class: "supporter-program__main-cta",
              type: "green",
              onClick: _ctx.onNextButtonClick
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.ctaCopy.start), 1)
              ]),
              _: 1
            }, 8, ["onClick"])
          ]),
          _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.viewCopy.tip), 1)
        ])
      ]),
      _: 1
    }, 8, ["class", "content-description", "content-title", "description-image-link", "description-image-title", "has-return-button", "onPreviousPage"])
  ]))
}