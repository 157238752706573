import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-0624e93b"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "submission-tab-interests" }
const _hoisted_2 = { class: "submission-tab__print-title" }
const _hoisted_3 = { class: "submissions-responses__question" }
const _hoisted_4 = { class: "submissions-responses__answer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SubmissionTabEmpty = _resolveComponent("SubmissionTabEmpty")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.additionalCriteria.length)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _createElementVNode("h2", _hoisted_2, _toDisplayString(_ctx.$t("supporters.form.additionalInterests.label")), 1),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.additionalCriteria, (response, index) => {
            return (_openBlock(), _createElementBlock("div", {
              key: `response-${index}`,
              class: "submissions-responses__item"
            }, [
              _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.getQuestion(response)), 1),
              _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.getAnswer(response)), 1)
            ]))
          }), 128))
        ], 64))
      : (_openBlock(), _createBlock(_component_SubmissionTabEmpty, {
          key: 1,
          icon: "empty-additional-criteria",
          "icon-size": "100",
          title: "supporters.results.submissionPanel.tabs.emptyInterests"
        }))
  ]))
}