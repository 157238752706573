import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-429c0d55"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "panel-grid__block" }
const _hoisted_2 = {
  key: 1,
  class: "profile-headline"
}
const _hoisted_3 = { class: "profile-headline__text-wrapper" }
const _hoisted_4 = { class: "profile-headline__title" }
const _hoisted_5 = {
  key: 0,
  class: "profile-headline"
}
const _hoisted_6 = { class: "profile-headline__text-wrapper" }
const _hoisted_7 = { class: "profile-headline__title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NetworksEditModal = _resolveComponent("NetworksEditModal")!
  const _component_PxButton = _resolveComponent("PxButton")!
  const _component_NetworksList = _resolveComponent("NetworksList")!
  const _component_PanelEmptyState = _resolveComponent("PanelEmptyState")!
  const _component_PxPanel = _resolveComponent("PxPanel")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.$user.isOwner() && _ctx.isNetworksEditModalVisible)
      ? (_openBlock(), _createBlock(_component_NetworksEditModal, {
          key: 0,
          ref: "networksEditModal",
          visibility: _ctx.isNetworksEditModalVisible,
          "onUpdate:visibility": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.isNetworksEditModalVisible) = $event)),
          onSubmit: _ctx.fetchCompanyData
        }, null, 8, ["visibility", "onSubmit"]))
      : _createCommentVNode("", true),
    (!_ctx.$screen.mdUp)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("h2", _hoisted_4, _toDisplayString(_ctx.$t("profile.networks.title")), 1)
          ]),
          (_ctx.networks.length && _ctx.$user.isOwner())
            ? (_openBlock(), _createBlock(_component_PxButton, {
                key: 0,
                class: "profile-headline__updateBtn",
                size: "small",
                onClick: _ctx.onClickEditNetworks
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t("profile.networks.update")), 1)
                ]),
                _: 1
              }, 8, ["onClick"]))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    _withDirectives((_openBlock(), _createBlock(_component_PxPanel, {
      class: _normalizeClass([{ 'profile-panel--disabled': !_ctx.hasUserAccess }, "profile-panel"])
    }, {
      default: _withCtx(() => [
        (_ctx.$screen.mdUp)
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("h2", _hoisted_7, _toDisplayString(_ctx.$t("profile.networks.title")), 1)
              ]),
              (_ctx.networks.length && _ctx.$user.isOwner() && !_ctx.isLoading)
                ? (_openBlock(), _createBlock(_component_PxButton, {
                    key: 0,
                    class: "profile-headline__updateBtn",
                    size: "small",
                    onClick: _ctx.onClickEditNetworks
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t("profile.networks.update")), 1)
                    ]),
                    _: 1
                  }, 8, ["onClick"]))
                : _createCommentVNode("", true)
            ]))
          : _createCommentVNode("", true),
        (_ctx.networks.length)
          ? (_openBlock(), _createBlock(_component_NetworksList, {
              key: 1,
              items: _ctx.networks,
              class: "networks-panel__list"
            }, null, 8, ["items"]))
          : (_openBlock(), _createBlock(_component_PanelEmptyState, {
              key: 2,
              icon: "networks"
            }, {
              default: _withCtx(() => [
                _createElementVNode("p", null, _toDisplayString(_ctx.$t(`profile.networks.emptyState.description[${+_ctx.$user.isOwner()}]`)), 1),
                (_ctx.$user.isOwner())
                  ? (_openBlock(), _createBlock(_component_PxButton, {
                      key: 0,
                      size: "medium",
                      type: "primary",
                      onClick: _ctx.onClickEditNetworks
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t("profile.networks.emptyState.button")), 1)
                      ]),
                      _: 1
                    }, 8, ["onClick"]))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }))
      ]),
      _: 1
    }, 8, ["class"])), [
      [_directive_loading, _ctx.isLoading]
    ])
  ]))
}