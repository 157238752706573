import { RouteRecordRaw } from "vue-router";

import AuthBase from "@/modules/authentication/views/auth-base.vue";
import AuthBaseEnlist from "@/modules/authentication/views/auth-base-enlist.vue";
import AuthBaseLogin from "@/modules/authentication/views/auth-base-login.vue";
import AuthBaseSignUp from "@/modules/authentication/views/auth-base-signup.vue";
import AuthBaseSignUpSuccess from "@/modules/authentication/views/auth-base-signup-success.vue";
import AuthBasePasswordRecover from "@/modules/authentication/views/auth-base-password-recover.vue";
import AuthBasePasswordReset from "@/modules/authentication/views/auth-base-password-reset.vue";
import AuthVerifyAccount from "@/modules/authentication/views/auth-verify-account.vue";
import AuthAdminAsUser from "@/modules/authentication/views/auth-admin-as-user.vue";

import {
  ROUTE_AUTHENTICATION_ENLIST,
  ROUTE_AUTHENTICATION_SIGNUP,
  ROUTE_AUTHENTICATION_LOGIN,
  ROUTE_AUTHENTICATION_SIGNUP_SUCCESS,
  ROUTE_AUTHENTICATION_PASSWORD_RECOVER,
  ROUTE_AUTHENTICATION_PASSWORD_RESET,
  ROUTE_AUTHENTICATION_VERIFY_ACCOUNT,
  ROUTE_AUTHENTICATION_ADMIN_AS_USER,
} from "./routes-names";

const ROUTE_ONLY_GUESTS = {
  guest: true,
};

export const routes: RouteRecordRaw[] = [
  {
    path: "/auth",
    component: AuthBase,
    children: [
      {
        path: "enlist/:token",
        name: ROUTE_AUTHENTICATION_ENLIST,
        component: AuthBaseEnlist,
        meta: ROUTE_ONLY_GUESTS,
      },
      {
        path: "sign-up",
        name: ROUTE_AUTHENTICATION_SIGNUP,
        component: AuthBaseSignUp,
        meta: ROUTE_ONLY_GUESTS,
      },
      {
        path: "login",
        name: ROUTE_AUTHENTICATION_LOGIN,
        component: AuthBaseLogin,
        meta: ROUTE_ONLY_GUESTS,
      },
      {
        path: "sign-up-success",
        name: ROUTE_AUTHENTICATION_SIGNUP_SUCCESS,
        component: AuthBaseSignUpSuccess,
        meta: ROUTE_ONLY_GUESTS,
      },
      {
        path: "forgot-password",
        name: ROUTE_AUTHENTICATION_PASSWORD_RECOVER,
        component: AuthBasePasswordRecover,
        meta: ROUTE_ONLY_GUESTS,
      },
      {
        path: "reset-password/:uid/:token",
        name: ROUTE_AUTHENTICATION_PASSWORD_RESET,
        component: AuthBasePasswordReset,
        meta: ROUTE_ONLY_GUESTS,
      },
      {
        path: "verify-account/:hash",
        name: ROUTE_AUTHENTICATION_VERIFY_ACCOUNT,
        component: AuthVerifyAccount,
      },
      {
        path: "admin/:hash",
        name: ROUTE_AUTHENTICATION_ADMIN_AS_USER,
        component: AuthAdminAsUser,
      },
    ],
  },
];
