import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-5c89bad0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "affiliate-questions" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PxButton = _resolveComponent("PxButton")!
  const _component_QuestionPanel = _resolveComponent("QuestionPanel")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.showReviewPageButton)
      ? (_openBlock(), _createBlock(_component_PxButton, _mergeProps({
          key: 0,
          class: "affiliate-questions__back-button"
        }, _ctx.backButtonProps, { onClick: _ctx.goToReviewPage }), null, 16, ["onClick"]))
      : _createCommentVNode("", true),
    (_ctx.hasCurrentQuestion)
      ? (_openBlock(), _createBlock(_component_QuestionPanel, {
          key: 1,
          modelValue: _ctx.currentResponse,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.currentResponse) = $event)),
          question: _ctx.currentQuestion,
          step: _ctx.currentSelectedQuestion,
          "text-area-limit": _ctx.textAreaLimit,
          total: _ctx.questions.length,
          class: "affiliate-questions__panel",
          onValidate: _ctx.validateResponseState
        }, null, 8, ["modelValue", "question", "step", "text-area-limit", "total", "onValidate"]))
      : _createCommentVNode("", true)
  ]))
}