import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-cac973a8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "verify-account-banner" }
const _hoisted_2 = { class: "verify-account-banner__wrapper" }
const _hoisted_3 = { class: "verify-account-banner__left" }
const _hoisted_4 = { class: "verify-account-banner__text" }
const _hoisted_5 = { class: "verify-account-banner__right" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PxIcon = _resolveComponent("PxIcon")!
  const _component_PxButton = _resolveComponent("PxButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_PxIcon, {
          size: 20,
          name: "information--dark-blue"
        }),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("p", null, [
            _createElementVNode("strong", null, _toDisplayString(_ctx.$t("profile.verifyAccountBanner.title")), 1)
          ]),
          _createElementVNode("p", null, [
            _createTextVNode(_toDisplayString(_ctx.$t("profile.verifyAccountBanner.description", {
                email: _ctx.authUserEmail,
              })) + " ", 1),
            _createElementVNode("span", null, [
              _createVNode(_component_PxButton, {
                class: "el-button--link-blue",
                type: "link",
                onClick: _ctx.resendEmail
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t("profile.verifyAccountBanner.cta")), 1)
                ]),
                _: 1
              }, 8, ["onClick"])
            ])
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_PxIcon, {
          size: 24,
          name: "cancel--gray",
          onClick: _ctx.closeBanner
        }, null, 8, ["onClick"])
      ])
    ])
  ]))
}