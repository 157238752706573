import { toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, renderSlot as _renderSlot, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-00aa316e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "px-information-card-collapsible__wrapper" }
const _hoisted_2 = { class: "px-information-card-collapsible__left" }
const _hoisted_3 = ["title"]
const _hoisted_4 = {
  key: 1,
  class: "px-information-card-collapsible__description"
}
const _hoisted_5 = { class: "px-information-card-collapsible__right" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PxStatusBadge = _resolveComponent("PxStatusBadge")!
  const _component_PxButton = _resolveComponent("PxButton")!
  const _component_ElCollapseItem = _resolveComponent("ElCollapseItem")!

  return (_openBlock(), _createBlock(_component_ElCollapseItem, {
    style: _normalizeStyle({ backgroundColor: _ctx.backgroundColor, border: _ctx.border }),
    class: "px-information-card-collapsible"
  }, {
    title: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          (_ctx.title)
            ? (_openBlock(), _createElementBlock("h3", {
                key: 0,
                style: _normalizeStyle({ color: _ctx.titleStyles === 'blue' ? '#7bb7ff' : '#272740' }),
                title: _ctx.title
              }, _toDisplayString(_ctx.title), 13, _hoisted_3))
            : _createCommentVNode("", true),
          (_ctx.description && _ctx.$screen.xlUp)
            ? (_openBlock(), _createElementBlock("p", _hoisted_4, _toDisplayString(_ctx.description), 1))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_5, [
          (_ctx.label && _ctx.$screen.xlUp)
            ? (_openBlock(), _createBlock(_component_PxStatusBadge, {
                key: 0,
                "background-color": _ctx.label.backgroundColor,
                icon: _ctx.label.icon,
                "icon-size": _ctx.label.iconSize,
                label: _ctx.label.text,
                "label-color": _ctx.label.textColor,
                "label-size": 13,
                uppercase: false,
                class: "px-information-card-collapsible__label",
                "icon-position": "left"
              }, null, 8, ["background-color", "icon", "icon-size", "label", "label-color"]))
            : _createCommentVNode("", true),
          (_ctx.cta)
            ? (_openBlock(), _createBlock(_component_PxButton, {
                key: 1,
                label: _ctx.cta.label,
                size: _ctx.cta.size,
                type: _ctx.cta.type,
                class: "px-information-card-collapsible__cta",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('navigate')))
              }, null, 8, ["label", "size", "type"]))
            : _createCommentVNode("", true)
        ])
      ])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", null, [
        _renderSlot(_ctx.$slots, "content", {}, undefined, true)
      ])
    ]),
    _: 3
  }, 8, ["style"]))
}