import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-7cd2b29c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "overview-sidebar" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_OverallLevel = _resolveComponent("OverallLevel")!
  const _component_MilestonesSection = _resolveComponent("MilestonesSection")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.latestAssessmentLevel)
      ? (_openBlock(), _createBlock(_component_OverallLevel, {
          key: 0,
          level: _ctx.latestAssessmentLevel
        }, null, 8, ["level"]))
      : _createCommentVNode("", true),
    (_ctx.nextMilestones.length)
      ? (_openBlock(), _createBlock(_component_MilestonesSection, {
          key: 1,
          "is-loading": _ctx.isLoading,
          milestones: _ctx.nextMilestones,
          title: _ctx.$t('profile.investors.nextMilestones'),
          icon: "next-milestone"
        }, null, 8, ["is-loading", "milestones", "title"]))
      : _createCommentVNode("", true),
    (_ctx.achievedMilestones.length)
      ? (_openBlock(), _createBlock(_component_MilestonesSection, {
          key: 2,
          "is-loading": _ctx.isLoading,
          milestones: _ctx.achievedMilestones,
          title: _ctx.$t('milestonePlanner.sidebar.milestones.completed'),
          icon: "completed-milestone"
        }, null, 8, ["is-loading", "milestones", "title"]))
      : _createCommentVNode("", true)
  ]))
}