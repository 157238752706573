import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-3408c7f9"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "matching-list-mobile" }
const _hoisted_2 = { class: "container" }
const _hoisted_3 = { class: "matching-list-mobile__results" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MatchingListCards = _resolveComponent("MatchingListCards")!
  const _component_VWindowItem = _resolveComponent("VWindowItem")!
  const _component_MatchingListInterests = _resolveComponent("MatchingListInterests")!
  const _component_MatchingListInterestMutual = _resolveComponent("MatchingListInterestMutual")!
  const _component_VWindow = _resolveComponent("VWindow")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_VWindow, {
          modelValue: _ctx.selectedView,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedView) = $event))
        }, {
          default: _withCtx(() => [
            _createVNode(_component_VWindowItem, { value: 'discover' }, {
              default: _withCtx(() => [
                _createVNode(_component_MatchingListCards)
              ]),
              _: 1
            }),
            (_ctx.$features.isEnabled(`matchingInterest`))
              ? (_openBlock(), _createBlock(_component_VWindowItem, {
                  key: 0,
                  value: 'pending'
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_MatchingListInterests)
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            (_ctx.$features.isEnabled(`matchingInterest`))
              ? (_openBlock(), _createBlock(_component_VWindowItem, {
                  key: 1,
                  value: 'connected'
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_MatchingListInterestMutual)
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["modelValue"])
      ])
    ])
  ]))
}