<template>
  <div class="vue-content-placeholders-heading">
    <div v-if="img" class="vue-content-placeholders-heading__img"></div>
    <div class="vue-content-placeholders-heading__content">
      <div class="vue-content-placeholders-heading__title"></div>
      <div class="vue-content-placeholders-heading__subtitle"></div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "ContentPlaceholdersHeading",
  props: {
    img: {
      type: Boolean,
      default: false,
    },
  },
});
</script>
