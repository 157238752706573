<template>
  <ElDialog
    ref="modal"
    v-model="innerVisibility"
    :append-to-body="true"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    class="milestone-plan-confirmation-dialog"
    width="500px"
    @close="closeModal"
  >
    <template #header>
      <h3 class="milestone-plan-confirmation-dialog__title">
        {{ dialogTitle }}
      </h3>
      <p
        :class="{
          'milestone-plan-confirmation-dialog__description--draft':
            (planned && !isPlanPublished) ||
            (completed && !isCompletePublished),
          'milestone-plan-confirmation-dialog__description--complete':
            isCompletePublished,
        }"
        class="milestone-plan-confirmation-dialog__description"
      >
        {{ dialogDescription }}
      </p>
    </template>
    <template #footer>
      <div
        :class="{
          'milestone-plan-confirmation-dialog__footer--grid':
            !isPlanPublished && !isCompletePublished,
        }"
        class="milestone-plan-confirmation-dialog__footer"
      >
        <PxButton
          ref="cancelButton"
          v-bind="cancelButtonProps"
          data-testid="cancel-button"
          @click="closeModal"
        />
        <PxButton
          v-if="showSaveAsDraftButton"
          ref="saveAsDraftButton"
          v-bind="saveAsDraftButtonProps"
          data-testid="save-as-draft-button"
          @click="onClickSaveAsDraft"
        />
        <PxButton
          ref="deleteButton"
          v-bind="deleteButtonProps"
          data-testid="delete-button"
          @click="onClickDelete"
        />
      </div>
    </template>
  </ElDialog>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { TranslateResult } from "vue-i18n";
import {
  EPxButtonSize,
  EPxButtonType,
  EPxButtonVariant,
} from "@/components/px-button/px-button.types";

export default defineComponent({
  name: "MilestonePlanConfirmationDialog",

  static: {
    deleteButton: {
      size: EPxButtonSize.LARGE,
      type: EPxButtonType.RED,
      variant: EPxButtonVariant.FLUID,
    },
    saveAsDraftButton: {
      type: EPxButtonType.DEFAULT,
      size: EPxButtonSize.LARGE,
      variant: EPxButtonVariant.FLUID,
    },
    cancelButton: {
      type: EPxButtonType.LINK,
    },
  },

  props: {
    visibility: {
      type: Boolean,
      default: false,
    },
    completed: {
      type: Boolean,
      default: false,
    },
    planned: {
      type: Boolean,
      default: false,
    },
    isPlanPublished: {
      type: Boolean,
      default: false,
    },
    isCompletePublished: {
      type: Boolean,
      default: false,
    },
    isFormInvalid: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      innerVisibility: false,
    };
  },

  computed: {
    cancelButtonProps(): any {
      return {
        ...this.$options.static.cancelButton,
        label: this.$t("common.cancel"),
      };
    },

    deleteButtonProps(): any {
      return {
        ...this.$options.static.deleteButton,
        label:
          this.completed || this.planned
            ? this.$t(
                "milestonePlanner.milestonePlan.confirmationDialogs.buttons.delete",
              )
            : this.$t(
                "milestonePlanner.milestonePlan.confirmationDialogs.buttons.discard",
              ),
      };
    },

    saveAsDraftButtonProps(): any {
      return {
        ...this.$options.static.saveasDraftButton,
        label: this.$t("milestonePlanner.milestonePlan.buttons.saveAsDraft"),
      };
    },

    dialogTitle(): TranslateResult {
      return this.completed && this.isCompletePublished
        ? this.$t(
            "milestonePlanner.milestonePlan.confirmationDialogs.deleteCompletePublished.title",
          )
        : this.completed
          ? this.$t(
              "milestonePlanner.milestonePlan.confirmationDialogs.deleteCompleteDraft.title",
            )
          : this.planned && this.isPlanPublished
            ? this.$t(
                "milestonePlanner.milestonePlan.confirmationDialogs.deletePlanPublished.title",
              )
            : this.planned
              ? this.$t(
                  "milestonePlanner.milestonePlan.confirmationDialogs.deletePlanDraft.title",
                )
              : this.$t(
                  "milestonePlanner.milestonePlan.confirmationDialogs.discardChanges.title",
                );
    },

    dialogDescription(): TranslateResult {
      return this.completed && this.isCompletePublished
        ? this.$t(
            "milestonePlanner.milestonePlan.confirmationDialogs.deleteCompletePublished.description",
          )
        : this.completed
          ? this.$t(
              "milestonePlanner.milestonePlan.confirmationDialogs.deleteCompleteDraft.description",
            )
          : this.planned && this.isPlanPublished
            ? this.$t(
                "milestonePlanner.milestonePlan.confirmationDialogs.deletePlanPublished.description",
              )
            : this.planned
              ? this.$t(
                  "milestonePlanner.milestonePlan.confirmationDialogs.deletePlanDraft.description",
                )
              : this.$t(
                  "milestonePlanner.milestonePlan.confirmationDialogs.discardChanges.description",
                );
    },

    showSaveAsDraftButton(): boolean {
      return (
        !this.isFormInvalid &&
        (this.isPlanPublished || this.isCompletePublished)
      );
    },
  },

  watch: {
    visibility: {
      immediate: true,
      handler(value) {
        this.innerVisibility = value;
      },
    },
  },

  methods: {
    onClickDelete() {
      this.closeModal();
      this.$emit("confirmed");
    },

    onClickSaveAsDraft() {
      this.closeModal();
      this.$emit("save-as-draft");
    },

    closeModal() {
      this.$emit("update:visibility", false);
    },
  },
});
</script>

<style lang="scss">
.milestone-plan-confirmation-dialog {
  .el-dialog__header {
    padding: 0;
    text-align: center;
    background-color: transparent;
    border: none;
  }

  .el-dialog__body {
    padding: 0;
  }

  .el-dialog__headerbtn {
    top: 20px;
    right: 14px;
    width: 22px;
    height: 22px;
  }

  .el-dialog__footer {
    padding: 0;
  }

  &__footer {
    display: flex;
    gap: 15px;
    align-items: center;
    justify-content: center;
    padding: 16px 0;
    margin-top: 50px;

    &::before {
      width: 436px;
    }

    .el-button {
      margin-left: 0;
    }

    .el-button--link {
      position: relative;
      left: -1px;
      font-size: 14px;
    }

    .el-button--link span {
      letter-spacing: -0.8px;
    }

    &--grid {
      gap: 19px;
      justify-content: center;
      margin-top: 50px;
    }

    &--grid .el-button--link {
      left: 1px;
    }

    &--grid .el-button--red {
      left: 0;
    }

    .el-button--red {
      height: 36px;
    }

    &:not(&--grid) .el-button--red {
      position: relative;
      left: 2px;
      height: 36px;
    }

    .el-button--default {
      position: relative;
      left: 1px;
      padding: 0 15px;
    }
  }

  &__title {
    padding: 39px 60px 14px 60px;
    margin-bottom: 2px;
    font-size: 20px;
    line-height: 30px;
    color: $ebony-clay;
  }

  &__description {
    padding: 0 107px;
    font-size: 14px;
    line-height: 20px;
    color: $ebony-clay;
    letter-spacing: -0.3px;

    &--draft {
      padding: 0;
    }

    &--complete {
      padding: 0 97px;
    }
  }
}
</style>
