<template>
  <div class="auth-signup">
    <LevelRangeBar v-if="investingRangeLevel" :range="investingRangeLevel" />
    <div class="page-wrapper">
      <div class="page-container">
        <h1 class="page-headline">
          {{ $t("supporters.signup.startMessage") }}
        </h1>
        <div class="page-block">
          <PxSteps :active="currentStep" :steps="items" theme="orange" />
          <SignUpStepSupporterDetails
            v-show="currentStep === 0"
            v-model:supporter="supporter.details"
            @next-step="nextStep"
          />
          <SignUpStepSupporterInterests
            v-show="currentStep === 1"
            v-model:supporter="supporter.interests"
            @next-step="nextStep"
            @previous-step="previousStep"
          />
          <SignUpStepAccount
            v-show="currentStep === 2"
            v-model:account="account"
            :errors="errors"
            :loading="loading"
            @previous-step="previousStep"
            @submit-form="submitForm"
          />
          <div class="auth-signup__footer">
            <p class="auth-signup__message">
              {{ $t("supporters.signup.footerMessage") }}
              <PxButton
                class="el-button--inline el-button--link-blue"
                type="link"
                @click="onClickLogin"
              >
                {{ $t("supporters.signup.loginLink") }}
              </PxButton>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import PxSteps from "@/components/px-steps/px-steps.vue";
import SignUpStepSupporterDetails from "@/modules/supporters/components/signup-step-supporter-details/signup-step-supporter-details.vue";
import SignUpStepSupporterInterests from "@/modules/supporters/components/signup-step-supporter-interests/signup-step-supporter-interests.vue";
import SignUpStepAccount from "@/modules/supporters/components/signup-step-account/signup-step-account.vue";
import LevelRangeBar from "@/modules/supporters/components/level-range-bar/level-range-bar.vue";

import { ISupportersSignup } from "@/modules/supporters/services/data/supporters-signup/supporters-signup.interface";
import { supportersSignUpProvider } from "@/modules/supporters/services/data/supporters-signup/supporters-signup.provider";

import { ErrorsManager } from "@/services/errors-manager";
import { EMetaActions, EMetaGetters } from "@/services/store/meta/meta-types";
import { SUPPORTERS_INVESTING_RANGE_META } from "@/modules/supporters/constants";
import {
  SUPPORTERS_ASSESSMENT,
  SUPPORTERS_AUTHENTICATION_LOGIN,
  SUPPORTERS_AUTHENTICATION_SIGNUP_SUCCESS,
} from "@/modules/supporters/services/router/routes-names";
import { ErrorsProviderException } from "@/services/data/exceptions/errors-provider.exception";
import { AUTHENTICATION_META_REGISTRATION_EMAIL } from "@/modules/authentication/constants";
import { defaultAffiliateId } from "@/services/configs";
import { IGroupedSectorsPayload } from "@/components/multi-selector/multi-selector-sectors.provider";
import { SupporterTypeState } from "@/modules/supporters/services/store/supporter-type/supporter-type.module";

export default defineComponent({
  name: "AuthSignup",

  components: {
    PxSteps,
    SignUpStepSupporterDetails,
    SignUpStepSupporterInterests,
    SignUpStepAccount,
    LevelRangeBar,
  },

  data() {
    return {
      currentStep: 0,
      hasServerError: false,
      loading: false,

      supporter: {
        details: {
          name: "",
          website: "",
          types: [] as Array<number>,
          otherType: "",
          location: {
            formatted_address: "",
          },
        },
        interests: {
          sectors: [] as Array<number>,
          grouped_sectors: [] as Array<IGroupedSectorsPayload>,
          locations: [] as Array<number>,
          places: [] as Array<string>,
          networks: [] as Array<number>,
        },
        investing_range: [] as Array<number>,
      },

      account: {
        email: "",
        password1: "",
        password2: "",
      },

      errors: new ErrorsManager({
        email: {
          unique: this.$t("authentication.signup.form.errors.email.unique"),
        },
      }),

      items: [
        {
          label: this.$t("supporters.signup.steps.supporterDetails"),
          value: "supporterDetails",
        },
        {
          label: this.$t("supporters.signup.steps.supporterInterests"),
          value: "supporterInterests",
        },
        {
          label: this.$t("supporters.signup.steps.account"),
          value: "user",
        },
      ],
    };
  },

  computed: {
    investingRangeLevel(): Array<number> {
      return this.$store.getters[EMetaGetters.GET](
        SUPPORTERS_INVESTING_RANGE_META,
      );
    },

    /**
     * Get affiliate ID.
     */
    affiliate(): number {
      const storeAffiliate = this.$store.get("viralLevel/affiliate");
      return storeAffiliate ? storeAffiliate.id : defaultAffiliateId();
    },
  },

  watch: {
    investingRangeLevel: {
      immediate: true,
      deep: true,
      handler(newRange) {
        this.supporter.investing_range = newRange;
      },
    },
  },

  async created() {
    if (!this.investingRangeLevel) {
      this.$router.replace({ name: SUPPORTERS_ASSESSMENT });
    }

    // Fetch supporter type data
    await this.$store.dispatch(SupporterTypeState.Action.GET_VALUES);
  },

  methods: {
    nextStep() {
      this.currentStep++;
    },

    previousStep() {
      this.currentStep--;
    },

    onClickLogin() {
      this.$router.push({ name: SUPPORTERS_AUTHENTICATION_LOGIN });
    },

    async submitForm() {
      const dataToSend = {
        ...this.account,
        affiliate: this.affiliate,
        company: {
          name: this.supporter.details.name,
          website: this.supporter.details.website,
          location: this.supporter.details.location,
          networks: this.supporter.interests.networks,
        },
        supporter: {
          name: this.supporter.details.name,
          types: this.supporter.details.types,
          otherType: this.supporter.details.otherType,
          investing_level_range: this.supporter.investing_range,
          sectors: this.supporter.interests.sectors,
          grouped_sectors: this.supporter.interests.grouped_sectors,
          locations: this.supporter.interests.locations,
          places: this.supporter.interests.places,
        },
      } as ISupportersSignup;

      this.errors.clear();
      this.loading = true;

      try {
        await supportersSignUpProvider.create(dataToSend);
      } catch (error) {
        if (error instanceof ErrorsProviderException) {
          this.errors.record(error.response.data.errors);
        }

        return;
      } finally {
        this.loading = false;
      }

      // Store user email in the metadata module in order
      // to be used on the success screen
      this.$store.dispatch(EMetaActions.SET, {
        key: AUTHENTICATION_META_REGISTRATION_EMAIL,
        value: dataToSend.email,
      });

      this.$router.push({
        name: SUPPORTERS_AUTHENTICATION_SIGNUP_SUCCESS,
      });
    },
  },
});
</script>

<style lang="scss" scoped>
.auth-signup .page-block {
  margin-top: 15px;

  @include breakpoint-up(md) {
    margin-top: 34px;
  }
}

.auth-signup .page-wrapper {
  width: 100%;
  padding-bottom: 52px;

  @include breakpoint-up(md) {
    padding-bottom: 113px;
  }
}

.auth-signup .page-container {
  max-width: 520px;
}

.auth-signup__step {
  max-width: 420px;
  margin: 31px auto 0;

  @include breakpoint-up(md) {
    margin-top: 27px;
  }
}

.auth-signup__footer {
  margin-top: 5px;
  text-align: center;

  @include breakpoint-up(md) {
    margin-top: 12px;
  }
}

.auth-signup__message {
  font-size: to-rem(15px);
  line-height: 20px;
  color: $ebony-clay;
  letter-spacing: -0.2px;

  :deep() .el-button.el-button--link span {
    font-weight: 600;
  }
}

.auth-signup__announcement {
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  justify-content: center;
  padding: 15px 10px;
  text-align: center;

  @include breakpoint-up(md) {
    padding: 34px 40px 31px;
  }

  &-icon {
    max-width: 100%;
  }

  &-title {
    @include grotesk(semiBold);

    margin-bottom: 0;
    font-size: 1.5333rem;
    line-height: 40px;
    color: $malibu;
    letter-spacing: 0;

    @include breakpoint-up(md) {
      font-size: 1.4667rem;
    }
  }

  .auth-signup__announcement-icon + .auth-signup__announcement-title {
    margin-top: 10px;

    @include breakpoint-up(md) {
      margin-top: 24px;
    }
  }

  &-message {
    margin-top: 14px;

    @include breakpoint-up(md) {
      margin-top: 19px;
    }

    p {
      line-height: 22px;
      letter-spacing: -0.15px;

      @include breakpoint-up(md) {
        line-height: 24px;
      }
    }

    strong {
      @include grotesk(semiBold);

      letter-spacing: 0;
    }
  }
}
</style>
