import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-655dcd06"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "auth-base-password auth-base-password--reset" }
const _hoisted_2 = { class: "page-wrapper" }
const _hoisted_3 = { class: "page-container" }
const _hoisted_4 = { class: "page-headline" }
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = { class: "page-block page-block--with-separator" }
const _hoisted_7 = ["textContent"]
const _hoisted_8 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PxInputPassword = _resolveComponent("PxInputPassword")!
  const _component_ElFormItem = _resolveComponent("ElFormItem")!
  const _component_ElInput = _resolveComponent("ElInput")!
  const _component_PxButton = _resolveComponent("PxButton")!
  const _component_ElForm = _resolveComponent("ElForm")!
  const _component_PxPanel = _resolveComponent("PxPanel")!
  const _directive_loading = _resolveDirective("loading")!

  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h1", _hoisted_4, _toDisplayString(_ctx.$t("authentication.passwordReset.title")), 1),
        _createElementVNode("div", {
          class: "page-subtitle",
          innerHTML: _ctx.$t('authentication.passwordReset.instructions')
        }, null, 8, _hoisted_5),
        _createElementVNode("div", _hoisted_6, [
          _createVNode(_component_PxPanel, { class: "px-panel--compact px-panel--stripped" }, {
            default: _withCtx(() => [
              _createVNode(_component_ElForm, {
                ref: "resetPasswordForm",
                model: _ctx.fields,
                rules: _ctx.rules,
                class: "auth-base-password__form",
                onValidate: _ctx.updateSubmitState
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ElFormItem, {
                    label: _ctx.$t('authentication.passwordReset.fields.newPassword'),
                    prop: "password1"
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("span", {
                        class: "el-form-item__top-text el-form-item__requirement",
                        textContent: _toDisplayString(_ctx.$t('authentication.passwordReset.requirement'))
                      }, null, 8, _hoisted_7),
                      _createVNode(_component_PxInputPassword, {
                        modelValue: _ctx.fields.password1,
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.fields.password1) = $event)),
                        placeholder: 
                    _ctx.$t('authentication.passwordReset.fields.newPassword')
                  ,
                        "show-success-state": true,
                        onInput: _ctx.validateConfirmationField
                      }, null, 8, ["modelValue", "placeholder", "onInput"]),
                      _createElementVNode("span", {
                        class: "el-form-item__bottom-text el-form-item__tip",
                        innerHTML: _ctx.$t('authentication.passwordReset.tip')
                      }, null, 8, _hoisted_8)
                    ]),
                    _: 1
                  }, 8, ["label"]),
                  _createVNode(_component_ElFormItem, {
                    label: 
                  _ctx.$t('authentication.passwordReset.fields.confirmPassword')
                ,
                    prop: "password2"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ElInput, {
                        modelValue: _ctx.fields.password2,
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.fields.password2) = $event)),
                        disabled: _ctx.passwordIsInvalid,
                        placeholder: 
                    _ctx.$t('authentication.passwordReset.fields.confirmPassword')
                  ,
                        class: "el-input--password",
                        type: "password"
                      }, null, 8, ["modelValue", "disabled", "placeholder"])
                    ]),
                    _: 1
                  }, 8, ["label"]),
                  _createVNode(_component_ElFormItem, { class: "el-form__cta" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_PxButton, {
                        disabled: _ctx.submissionDisabled,
                        loading: _ctx.loading,
                        class: "el-button--block",
                        size: "medium",
                        type: "primary",
                        onClick: _ctx.submitForm
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t("authentication.passwordReset.submitButton")), 1)
                        ]),
                        _: 1
                      }, 8, ["disabled", "loading", "onClick"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["model", "rules", "onValidate"])
            ]),
            _: 1
          })
        ])
      ])
    ])
  ])), [
    [_directive_loading, _ctx.loading]
  ])
}