import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-75c70f66"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "matching-card-compact__section-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([{
      'matching-card-compact--selected': _ctx.isSelected,
      'matching-card-compact--faded': _ctx.isFaded && !_ctx.isDisabled,
      'matching-card-compact--toggle': _ctx.isOptionsOpen,
      'matching-card-compact--disabled': _ctx.isDisabled,
    }, "matching-card-compact"])
  }, [
    _renderSlot(_ctx.$slots, "action", {}, undefined, true),
    _createElementVNode("div", _hoisted_1, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ])
  ], 2))
}