<template>
  <div
    :class="{ 'networks-selector--modal': isModal }"
    class="networks-selector"
  >
    <div
      v-show="isSelectedVisible"
      :class="{
        'networks-selector__list--with-entries': selectedNetworks.length,
      }"
      class="networks-selector__list"
    >
      <label
        v-if="!noLabel"
        class="el-form-item__label networks-selector__title"
      >
        {{ labelCopy }}
      </label>
      <div class="networks-selector__wrapper" @scroll="checkNetworksScroll">
        <div
          v-for="network in selectedNetworks"
          :key="network.id"
          class="networks-selector__entry"
        >
          <picture v-if="!isModal" class="networks-selector__entry-picture">
            <img :alt="network.name" :src="network.logo || ''" />
          </picture>
          <div class="networks-selector__entry-content">
            <span>
              {{ network.name }}
            </span>
            <template v-if="!noLocation">
              <ul v-if="network.locations && network.locations.length">
                <li v-for="(location, index) in network.locations" :key="index">
                  {{ location.formatted_address }}
                </li>
              </ul>
              <ul v-else>
                <li>
                  {{ $t("common.everywhere") }}
                </li>
              </ul>
            </template>
          </div>
          <i
            class="icon icon-trash--dark"
            @click="onClickRemoveNetwork(network.id)"
          />
        </div>
      </div>
      <div v-if="isGradientVisible" class="networks-selector__gradient" />
    </div>
    <ElSelect
      ref="select"
      :loading="networkSelectLoading"
      :placeholder="placeholderCopy"
      :popper-class="selectPopperClass"
      :remote-method="searchNetworks"
      class="networks-selector__select"
      filterable
      remote
      @blur="blurHandler"
      @change="onNetworkSelect"
    >
      <template #prefix>
        <PxIcon :size="24" name="magnifier" />
      </template>
      <ElOption
        v-for="item in listOfNetworks"
        :key="item.id"
        :class="{ 'networks-selector--modal__select-options': isModal }"
        :label="item.name"
        :value="item"
        class="networks-selector__select-options"
      >
        <div>
          <span>
            {{ item.name }}
          </span>
          <ul v-if="item.locations && item.locations.length">
            <li
              v-for="(location, index) in item.locations"
              :key="index"
              class="networks-selector__select-options__list-item"
            >
              {{ location.formatted_address }}
            </li>
          </ul>
          <ul v-else>
            <li class="networks-selector__select-options__list-item">
              {{ $t("common.everywhere") }}
            </li>
          </ul>
        </div>
      </ElOption>
    </ElSelect>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import cloneDeep from "lodash/cloneDeep";
import isEqual from "lodash/isEqual";
import emitter from "@/mixins/emitter";

import { networkProvider } from "@/services/data/network/network.provider";
import { INetwork } from "@/services/data/network/network.interface";
import { ROUTE_PROFILE } from "@/modules/profile/services/router/routes-names";

let requestTimer: any = null;

export default defineComponent({
  name: "NetworksSelector",

  mixins: [emitter],

  props: {
    modelValue: {
      type: Array as () => Array<INetwork>,
      default: () => [],
    },

    /**
     * When set to `true` the value will be INetwork instances.
     * Otherwise, it will default to network ids'
     */
    valueAsInstance: {
      type: Boolean,
      default: false,
    },

    /**
     * When set to `true` the label won't be rendered.
     */
    noLabel: {
      type: Boolean,
      default: false,
    },

    noLocation: {
      type: Boolean,
      default: false,
    },

    labelCopy: {
      type: String,
      default: "",
    },

    placeholderCopy: {
      type: String,
      default: "",
    },

    isModal: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      listOfNetworks: [] as Array<INetwork>,
      networkSelectLoading: false,

      // Will stores the list of selected sectors
      selectedNetworks: [] as Array<INetwork>,
      isSelectedVisible: true,
      visibleNetworks: 4,
      networksScrollAtEnd: false,
    };
  },

  computed: {
    networkQueryParams(): Array<string> {
      const queryParams =
        this.$route.query && (this.$route.query.network as string | null);
      return queryParams ? queryParams.split(",") : [];
    },

    selectPopperClass(): string {
      return this.isModal
        ? "networks-selector--modal__popper"
        : "networks-selector__popper";
    },

    isGradientVisible(): boolean {
      return (
        this.modelValue.length > this.visibleNetworks &&
        !this.networksScrollAtEnd &&
        this.$route.name === ROUTE_PROFILE
      );
    },
  },

  watch: {
    selectedNetworks: {
      deep: true,
      handler(newVal: Array<INetwork>) {
        let selection: Array<INetwork> = newVal;

        if (!this.valueAsInstance) {
          selection = selection.map((network: any) => network.id);
        }

        this.$emit("update:modelValue", selection);
        this.$emit("change", selection);
      },
    },

    modelValue: {
      deep: true,
      handler(newValue, oldValue) {
        if (!isEqual(newValue, oldValue) && this.valueAsInstance) {
          this.setActiveNetworks(newValue);
        }
      },
    },
  },

  async created() {
    if (this.networkQueryParams.length) {
      // Populate network selector via query params...
      for (const query of this.networkQueryParams) {
        await this.searchNetworksRequest(query);
      }
    } else if (this.modelValue.length) {
      // ...or from pre-existing values (store)
      this.setActiveNetworks(this.modelValue);
    }
  },

  methods: {
    /**
     * Allow to set the active sectors.
     */
    setActiveNetworks(networks: Array<INetwork>) {
      this.selectedNetworks = networks;
    },

    async searchNetworksRequest(query?: string) {
      const search = query || "";

      if (!search.length) {
        this.listOfNetworks = [];
      }

      const data = (await networkProvider.list({
        search,
      })) as Array<INetwork>;

      // Get current selected networks ids
      const selectedIds = this.selectedNetworks.map(
        (item: INetwork) => item.id,
      );

      if (this.networkSelectLoading) {
        // Remove user selected networks from all networks
        this.listOfNetworks =
          selectedIds.length > 0
            ? data.filter((entry: INetwork) => !selectedIds.includes(entry.id))
            : data;
      } else if (data.length > 0) {
        // If it's not a user selection (e.g. prefill networks)
        // Simply add request results to selected networks
        this.listOfNetworks.push(data[0]);

        if (this.networkQueryParams.length === this.listOfNetworks.length) {
          this.selectedNetworks = cloneDeep(this.listOfNetworks);
        }
      }

      // Remove duplicates
      this.listOfNetworks = this.listOfNetworks.filter(
        (item: INetwork, index: number, self: Array<INetwork>) => {
          return (
            index ===
            self.findIndex(
              (t: INetwork) => t.name === item.name && t.slug === item.slug,
            )
          );
        },
      );

      this.networkSelectLoading = false;
    },

    /**
     * Search for sectors on the API.
     */
    async searchNetworks(query: string) {
      this.networkSelectLoading = true;
      if (requestTimer) {
        clearTimeout(requestTimer);
      }

      requestTimer = setTimeout(() => this.searchNetworksRequest(query), 400);
    },

    /**
     * When a new sector is selected it is pushed into the
     * array of selected sectors and the autocomplete fill
     * cleared.
     */
    onNetworkSelect(selectedValue: INetwork) {
      this.selectedNetworks.push(selectedValue);
      this.listOfNetworks = this.listOfNetworks.filter(
        (network: INetwork) => network !== selectedValue,
      );

      this.$emit("change", selectedValue);
    },

    /**
     * Remove the given network from the list of selected networks.
     */
    onClickRemoveNetwork(networkId: number) {
      const indexToRemove = this.selectedNetworks.findIndex(
        (network: INetwork) => network.id === networkId,
      );
      this.selectedNetworks.splice(indexToRemove, 1);
    },

    blurHandler(event: any) {
      this.$emit("blur", event);
      this.$emit("change", this.selectedNetworks);
    },

    checkNetworksScroll($event: any) {
      if (!$event.target) return;

      this.networksScrollAtEnd =
        $event.target.scrollTop ===
        $event.target.scrollHeight - $event.target.offsetHeight;
    },
  },
});
</script>

<style lang="scss" scoped>
.networks-selector__list--with-entries {
  margin-bottom: 12px;
}

.networks-selector__title {
  display: block;
}

.networks-selector__gradient {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  max-width: 360px;
  height: 25px;
  background: linear-gradient(
    to top,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 1) 20%,
    rgba(247, 249, 249, 0) 100%
  );
}

.networks-selector__entry {
  box-sizing: content-box;
  display: flex;
  min-height: 36px;
  padding: 7px 15px 8px 8px;
  margin-bottom: 12px;
  clear: both;

  font-size: 0.9333rem;
  text-transform: capitalize;

  background-color: $athens-gray;
  border-radius: 2px;

  &:last-child {
    margin-bottom: 0;
  }

  span {
    color: $gun-powder;
  }

  i {
    position: relative;
    top: 5px;
    flex-shrink: 0;
    width: 14px;
    height: 14px;

    cursor: pointer;
  }

  i:hover {
    background-image: url("#{$assetsPath}/img/icons/trash.svg");
  }
}

.networks-selector__entry-picture {
  position: relative;
  top: 4px;
  flex-shrink: 0;
  width: 36px;
  height: 36px;
  padding: 3px;
  overflow: hidden;
  background-color: white;
  border-radius: 100%;
  box-shadow: inset 0 0 4px 0 rgba(39, 39, 64, 0.2);

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
  }
}

.networks-selector__entry-content {
  flex-grow: 1;
  max-width: 100%;
  padding-left: 8px;

  ul {
    display: flex;
    flex-flow: row wrap;
    padding: 0;
    margin: 0;
    list-style: none;
  }

  li {
    font-size: 14px;
    line-height: 20px;
    color: $manatee;

    &:not(:last-child) {
      padding-right: 5px;
    }

    &:not(:first-child)::before {
      position: relative;
      top: -1px;
      display: inline;
      font-size: 15px;
      color: $mischka;
      letter-spacing: 1.2px;
      white-space: pre-wrap;
      content: "\2022";
    }
  }
}

.networks-selector__select {
  display: block;

  :deep(.el-select__wrapper) {
    height: 45px;
    background: $white;
    padding: 4px 7px;
  }

  :deep(.el-select__prefix::after) {
    top: 50%;
    left: 32px;
    width: 1px;
    height: 28px;

    transform: translateY(-50%);
  }

  :deep(.el-select__selection) {
    padding-right: 15px;
    padding-left: 15px;
  }

  :deep(.el-select__placeholder span) {
    color: $manatee;
    line-height: 1;
  }
}

.networks-selector__select-options {
  height: auto;
  padding-bottom: 3px;
  line-height: 25px;

  &::before {
    z-index: z("bellow");
    height: 100%;
  }

  &:not(:first-child) {
    margin-top: 4px;
  }

  & :deep() {
    span {
      text-transform: capitalize;
    }

    ul {
      display: flex;
      flex-flow: row wrap;
      padding: 0;
      list-style: none;
    }

    .networks-selector__select-options__list-item {
      font-size: 12px;
      line-height: 15px;
      color: $manatee;

      &:not(:last-child) {
        padding-right: 5px;
      }

      &:not(:first-child)::before {
        position: relative;
        top: -1px;
        display: inline;
        font-size: 15px;
        color: $mischka;
        letter-spacing: 1.2px;
        white-space: pre-wrap;
        content: "\2022";
      }
    }
  }
}

.networks-selector--modal {
  display: flex;
  flex-direction: column-reverse;
  width: 100%;

  .networks-selector__entry {
    margin: 12px 0 0;

    i {
      top: 0;
      align-self: center;
      width: 18px;
      height: 18px;
    }
  }

  .networks-selector__entry-content {
    align-self: center;
  }
}

.networks-selector--modal__select-options {
  min-height: 36px;
  padding: 0 10px;
  margin-bottom: 12px;
}
</style>

<style lang="scss">
.networks-selector--modal__popper {
  border: none;
  box-shadow: none;

  .el-select-dropdown__wrap {
    @include breakpoint-down(sm) {
      max-height: calc(100vh - 220px);
    }
  }
}
</style>
