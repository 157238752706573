<template>
  <div class="auth-base page-full-height">
    <PxTabBar v-if="$screen.mdDown" />
    <PxNavbar v-else theme="centered" />
    <RouterView class="page-body" />
    <PxFooter />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import PxTabBar from "@/components/px-tab-bar/px-tab-bar.vue";
import PxNavbar from "@/components/px-navbar/px-navbar.vue";
import PxFooter from "@/components/px-footer/px-footer.vue";

import {
  helpScoutBeaconInit,
  helpScoutBeaconDestroy,
} from "@/services/utils/helpscout";
import { RouteLocationNormalized } from "vue-router";
import { changeLocale, loadedLocales } from "@/services/i18n/locale-utils";
import store from "@/services/store";
import { EViralLevelActions } from "@/services/store/viral-level/viral-level-types";
import { ECategoryActions } from "@/services/store/category/category-types";
import { ENTREPRENEUR_USER_GROUP_ID } from "@/modules/common/constants";
import { ELevelActions } from "@/services/store/levels/levels-types";
import {
  SELF_ASSESSMENT_ASSESSMENT,
  SELF_ASSESSMENT_RESULTS,
} from "@/modules/self-assessment/services/router/routes-names";
import { SUPPORTERS_STARTING_POINT } from "@/modules/supporters/services/router/routes-names";
import { ROUTE_AUTHENTICATION_PASSWORD_RECOVER } from "@/modules/authentication/services/router/routes-names";

export default defineComponent({
  name: "AuthBase",

  components: {
    PxTabBar,
    PxNavbar,
    PxFooter,
  },

  beforeRouteLeave: async (
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: Function,
  ) => {
    if (
      to.name === SELF_ASSESSMENT_ASSESSMENT ||
      to.name === SELF_ASSESSMENT_RESULTS ||
      to.name === SUPPORTERS_STARTING_POINT ||
      to.name === ROUTE_AUTHENTICATION_PASSWORD_RECOVER
    ) {
      next();
    } else {
      // We're reverting the locale back to English as we're only supposed
      // to provide translations in assessment flow pages for the time being.
      await changeLocale("en", loadedLocales, document);
      // Fetch backend translations
      store.dispatch(EViralLevelActions.COMPUTE_LEVEL, {
        skipLoading: true,
      });
      store.dispatch(ECategoryActions.FETCH, {
        group: ENTREPRENEUR_USER_GROUP_ID,
      });
      store.dispatch(ELevelActions.FETCH, {
        group: ENTREPRENEUR_USER_GROUP_ID,
      });
      next();
    }
  },

  mounted() {
    // Load HelpScout chat widget
    helpScoutBeaconInit();
  },

  unmounted() {
    // Remove HelpScout chat widget
    helpScoutBeaconDestroy();
  },
});
</script>

<style lang="scss" scoped>
.auth-base :deep() .page-wrapper {
  padding: 26px 15px 70px;

  @include breakpoint-up(md) {
    padding: 55px 20px 126px;
  }
}

.auth-base :deep() .page-container {
  max-width: 420px;
  margin: auto;
}

.auth-base :deep() .page-headline {
  @include grotesk(semiBold);

  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 40px;
  margin: auto;
  font-size: 1.5333rem;
  line-height: 34px;
  text-align: center;

  @include breakpoint-up(md) {
    font-size: 2rem;
    line-height: 40px;
  }
}

.auth-base :deep() .page-subtitle {
  margin: 16px auto 0;
  line-height: 22px;
  text-align: center;
  letter-spacing: -0.13px;

  @include breakpoint-up(md) {
    margin-top: 23px;
    line-height: 24px;
  }

  strong {
    line-height: 1em;
  }
}

.auth-base :deep() .page-block {
  margin: 30px auto 0;

  @include breakpoint-up(md) {
    margin-top: 36px;
  }
}

.auth-base :deep() .page-block--with-separator {
  margin: 21px auto 0;
  border-top: solid 1px $athens-gray;

  @include breakpoint-up(md) {
    margin-top: 24px;
  }
}
</style>
