<template>
  <div class="affiliate-review-page">
    <div class="affiliate-review-page__left-panel">
      <div class="affiliate-review-page__left-panel-wrapper">
        <h3>
          {{ $t("affiliates.reviewPage.navigation.title") }}
        </h3>
        <p>
          {{ $t("affiliates.reviewPage.navigation.subtitle") }}
        </p>
      </div>
      <PxNavigationTab
        v-model:selected-tab="innerActiveTab"
        :list-format="$screen.xlUp"
        :navigation-tabs="visibleNavigationTabs"
        :show-number="false"
        data-testid="affiliate-review-page-navigation"
      />
    </div>
    <div class="affiliate-review-page__right-panel">
      <AffiliateReviewPageSelfAssessment
        v-if="innerActiveTab === 1"
        :assessment-incomplete="isMissingAssessment"
        :level-description="levelDescription"
        :viral-level="viralLevel"
        data-testid="affiliate-review-page-self-assessment"
        @update="$emit('go-to-page', 1)"
      />
      <AffiliateReviewPageQuestions
        v-if="innerActiveTab === 2"
        data-testid="affiliate-review-page-questions"
        @go-to-question="goToQuestion"
      />
      <AffiliateReviewPageTeamMembers
        v-if="innerActiveTab === 3"
        data-testid="affiliate-review-page-team-members"
        @go-to-team-members="$emit('go-to-page', 3)"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import PxNavigationTab, {
  INavigationTab,
} from "@/components/px-navigation-tab/px-navigation-tab.vue";
import AffiliateReviewPageSelfAssessment from "@/modules/affiliates/views/affiliate-review-page/affiliate-review-page-self-assessment.vue";
import AffiliateReviewPageQuestions from "@/modules/affiliates/views/affiliate-review-page/affiliate-review-page-questions.vue";
import AffiliateReviewPageTeamMembers from "@/modules/affiliates/views/affiliate-review-page/affiliate-review-page-team-members.vue";

import { IAffiliatesState } from "@/modules/affiliates/services/store/affiliates/affiliates.types";
import { AFFILIATE_TYPE_PROGRAM } from "@/services/data/affiliate/affiliate.interface";
import { ICategoryLevelSelection } from "@/services/data/viral-level/viral-level.interface";
import { ILevel } from "@/services/data/level/level.interface";
import { IMatchingResponse } from "@/services/data/matching-responses/matching-response.interface";
import { ITeamMember } from "@/modules/team-management/services/data/team-members/team-members.interface";
import { MATCHING_LOWEST_LEVEL } from "@/modules/matching/constants";

export default defineComponent({
  name: "AffiliateReviewPage",

  components: {
    PxNavigationTab,
    AffiliateReviewPageSelfAssessment,
    AffiliateReviewPageQuestions,
    AffiliateReviewPageTeamMembers,
  },

  data() {
    return {
      innerActiveTab: 1,
    };
  },

  computed: {
    levels(): ILevel[] {
      return this.$store.state.levels.data;
    },

    affiliatesData(): IAffiliatesState {
      return this.$store.state.affiliates;
    },

    responses(): Array<IMatchingResponse> {
      return this.affiliatesData.draftSubmission
        ? this.affiliatesData.draftSubmission.data.responses
        : [];
    },

    isMissingResponses(): boolean {
      return !!this.responses.filter((response) => !response.isValid).length;
    },

    teamMembersData(): Array<ITeamMember> {
      return this.affiliatesData.draftSubmission
        ? this.affiliatesData.draftSubmission.data.teamMembers
        : [];
    },

    isMissingTeamMembersRequiredInformation(): boolean {
      return this.teamMembersData.some(
        (member) =>
          !member.first_name ||
          !member.last_name ||
          !member.email ||
          (member.email && !this.checkValidEmail(member.email)) ||
          !member.position,
      );
    },

    activeTab(): any {
      return (
        this.visibleNavigationTabs.find(
          (tab: INavigationTab) => tab.id === this.innerActiveTab,
        ) || this.visibleNavigationTabs[0]
      );
    },

    navigationTabs(): any {
      return [
        {
          id: 1,
          title: this.$t(
            "affiliateProgram.components.flowNavbar.assessmentTabTitle",
          ),
          icon: this.isMissingAssessment ? "warning--blue" : "success--green",
          iconPosition: "right",
          visible: true,
        },
        {
          id: 2,
          title: this.$t("affiliates.reviewPage.navigation.questionsTab"),
          icon: this.isMissingResponses ? "warning--blue" : "success--green",
          iconPosition: "right",
          visible: this.showQuestionsTab,
        },
        {
          id: 3,
          title: this.$t(
            "affiliateProgram.components.flowNavbar.teamManagementTabTitle",
          ),
          icon: this.isMissingTeamMembersRequiredInformation
            ? "warning--blue"
            : "success--green",
          iconPosition: "right",
          visible: this.showTeamMembersTab,
        },
      ];
    },

    visibleNavigationTabs(): INavigationTab[] {
      return this.navigationTabs.filter((tab: any) => tab.visible);
    },

    showQuestionsTab(): boolean {
      return (
        this.affiliatesData.affiliate?.flow_type === AFFILIATE_TYPE_PROGRAM
      );
    },

    showTeamMembersTab(): boolean {
      return (
        (this.affiliatesData.affiliate?.show_team_section &&
          this.$features.isEnabled("teamMembers")) ||
        false
      );
    },

    assessmentLevels(): Array<ICategoryLevelSelection> {
      return this.affiliatesData.draftSubmission?.data.assessment || [];
    },

    viralLevel(): number {
      const assessments = this.assessmentLevels;
      const currentAssessment = assessments.reduce(
        (current, assessment) =>
          assessment.level < current.level ? assessment : current,
        assessments[0],
      );

      return currentAssessment.level
        ? (currentAssessment.level as number)
        : MATCHING_LOWEST_LEVEL;
    },

    isMissingAssessment(): boolean {
      return this.assessmentLevels.every(
        (assessment) => assessment.level === 0,
      );
    },

    levelDescription(): ILevel | null {
      return (
        this.levels.find((level: ILevel) => level.value === this.viralLevel) ||
        null
      );
    },
  },

  watch: {
    affiliatesData: {
      immediate: true,
      deep: true,
      handler() {
        this.checkMissingData();
      },
    },
  },

  methods: {
    goToQuestion(id: number) {
      this.$emit("go-to-page", 2, id);
    },

    checkValidEmail(email: string) {
      const emailRegex =
        // eslint-disable-next-line no-control-regex
        /^(?:[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?\.)+[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[A-Za-z0-9-]*[A-Za-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)])$/;
      return emailRegex.test(email);
    },

    checkMissingData() {
      const isMissingData =
        (this.showQuestionsTab && this.isMissingResponses) ||
        (this.showTeamMembersTab &&
          this.isMissingTeamMembersRequiredInformation);

      this.$emit("missing-data", isMissingData);
    },
  },
});
</script>

<style lang="scss" scoped>
.affiliate-review-page {
  display: flex;
  gap: 30px;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  max-width: 1140px;
  height: 100%;
  padding: 0;
  margin: 0 auto;

  @media (max-height: 768px) {
    padding: 0;
  }

  @include breakpoint-down(lg) {
    flex-direction: column;
    justify-content: flex-start;
    gap: 0;
    margin: 0 30px;

    :deep()
      .px-navigation-tab-item__container:not(.px-navigation-tab-item--active) {
      padding: 0 20px 9px 20px;
    }
  }

  &__left-panel {
    width: 300px;
    max-height: 256px;
    padding-bottom: 55px;

    border-radius: 4px;
    box-shadow: 0 2px 11px 0 rgba(0, 0, 0, 0.05);
    border: 1px solid $mischka;
    background-color: $white;

    @include breakpoint-down(lg) {
      width: 100%;
      padding-bottom: 0;
      border-bottom: none;
      box-shadow: none;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;

      :deep() .px-navigation-tab {
        justify-content: flex-start;
        padding-left: 19px;
      }
    }

    &-wrapper {
      padding: 14px 10px 15px 19px;
    }

    h3 {
      @include grotesk(bold);

      margin: 0;

      font-size: 18px;
      line-height: 1.56;
      color: $ebony-clay;
    }

    p {
      font-size: 14px;
      line-height: 1.43;
      color: $manatee;
      letter-spacing: -0.3px;
    }

    :deep() .px-navigation-tab-item__title {
      font-size: 16px;
      line-height: 20px;
      letter-spacing: -0.2px;
    }

    :deep()
      .px-navigation-tab-item__container.px-navigation-tab-item--active
      .px-icon {
      filter: none;
    }
  }

  &__right-panel {
    display: flex;
    flex-direction: column;
    width: 810px;
    max-height: 100%;
    border-radius: 4px;
    box-shadow: 0 2px 11px 0 rgba(0, 0, 0, 0.05);
    border: 1px solid $mischka;
    background-color: $white;

    > div {
      max-height: 100%;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      gap: 17px;
    }

    @include breakpoint-down(lg) {
      width: 100%;
      max-width: 100%;
      height: calc(100% - 109px);
      border-top: none;
      border-top-right-radius: 0;
      border-top-left-radius: 0;
      box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.05);
      padding-top: 30px;
    }
  }
}
</style>
