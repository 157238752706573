import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-2fe08f1a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "stats-card" }
const _hoisted_2 = { class: "stats-card__content" }
const _hoisted_3 = { class: "stats-card__header" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PxIcon = _resolveComponent("PxIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (_ctx.title)
          ? (_openBlock(), _createElementBlock("h3", _hoisted_4, _toDisplayString(_ctx.title), 1))
          : _createCommentVNode("", true),
        _createElementVNode("h2", null, _toDisplayString(_ctx.statValue) + " of " + _toDisplayString(_ctx.statTotal), 1)
      ]),
      (_ctx.icon)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            style: _normalizeStyle({
          backgroundColor: _ctx.iconBackgroundColor,
        }),
            class: "stats-card__icon"
          }, [
            _createVNode(_component_PxIcon, {
              name: _ctx.icon,
              size: _ctx.iconSize
            }, null, 8, ["name", "size"])
          ], 4))
        : _createCommentVNode("", true)
    ]),
    (_ctx.description)
      ? (_openBlock(), _createElementBlock("p", _hoisted_5, _toDisplayString(_ctx.description), 1))
      : _createCommentVNode("", true)
  ]))
}