import { getGenericInitialState } from "@/services/store/generic/generic-state";
import { milestoneReportsProvider } from "@/modules/charts-dashboard/services/data/milestone-reports/milestone.provider";

import { GenericState } from "@/services/store/generic/generic-state.module";
import { GenericProvider } from "@/services/data/generic-provider";
import { EMilestoneReportsState } from "@/modules/charts-dashboard/services/store/milestone/milestone.interface";
import { EMilestoneReportsActions } from "@/modules/charts-dashboard/services/store/milestone/milestone.interface";
import { EMilestoneReportsGetters } from "@/modules/charts-dashboard/services/store/milestone/milestone.getters";
import { EMilestoneReportsMutation } from "@/modules/charts-dashboard/services/store/milestone/milestone.mutations";

import { actions } from "@/modules/charts-dashboard/services/store/milestone/milestone.actions";
import { getters } from "@/modules/charts-dashboard/services/store/milestone/milestone.getters";
import { mutations } from "@/modules/charts-dashboard/services/store/milestone/milestone.mutations";

import type { IMilestoneReports } from "@/modules/charts-dashboard/services/data/milestone-reports/milestone.interface";
import type { IMilestoneReportsState } from "@/modules/charts-dashboard/services/store/milestone/milestone.interface";

const initialState: IMilestoneReportsState = {
  ...getGenericInitialState<IMilestoneReports>(),
};

export class MilestoneReportsState extends GenericState<
  IMilestoneReportsState,
  IMilestoneReports
> {
  static Action = GenericState.mapNamespaceEnum(
    EMilestoneReportsActions,
    EMilestoneReportsState.NAMESPACE,
  );
  static Getter = GenericState.mapNamespaceEnum(
    EMilestoneReportsGetters,
    EMilestoneReportsState.NAMESPACE,
  );
  static Mutation = GenericState.mapNamespaceEnum(
    EMilestoneReportsMutation,
    EMilestoneReportsState.NAMESPACE,
  );

  constructor(provider: GenericProvider<IMilestoneReports>) {
    super({ provider });

    this.state = initialState;
    this.actions = actions;
    this.getters = getters;
    this.mutations = mutations;
  }
}

export const milestoneReportsModule = new MilestoneReportsState(
  milestoneReportsProvider,
);
