import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, normalizeStyle as _normalizeStyle, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PxIcon = _resolveComponent("PxIcon")!

  return (_ctx.visible)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        style: _normalizeStyle(_ctx.messageBarStyles),
        class: "px-message-bar"
      }, [
        (_ctx.icon)
          ? (_openBlock(), _createBlock(_component_PxIcon, {
              key: 0,
              name: _ctx.icon,
              size: 16
            }, null, 8, ["name"]))
          : _createCommentVNode("", true),
        _renderSlot(_ctx.$slots, "content"),
        _renderSlot(_ctx.$slots, "action"),
        (_ctx.closable)
          ? (_openBlock(), _createBlock(_component_PxIcon, {
              key: 1,
              size: 10,
              class: "px-message-bar__close",
              name: "cross--white",
              onClick: _ctx.closeBar
            }, null, 8, ["onClick"]))
          : _createCommentVNode("", true)
      ], 4))
    : _createCommentVNode("", true)
}