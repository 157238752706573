import { IBaseState } from "@/services/store/store-types";
import { IAssessment } from "@/services/data/assessment/assessment.interface";

/**
 * Interface that represents the store structure to hold
 * the company's assessments history.
 */
export interface IAssessmentHistoryState extends IBaseState {
  data: Array<IAssessment>;
}

export enum EAssessmentHistoryActions {
  FETCH = "profile/assessmentHistory/fetch",
}

export enum EAssessmentHistoryMutations {
  SET_LOADING = "SET_LOADING",
  SET_ERROR = "SET_ERROR",
  SET_DATA = "SET_DATA",
}
