import { Module } from "vuex";
import { RootState } from "@/services/store/root-state";
import { IAffiliatesState } from "@/modules/affiliates/services/store/affiliates/affiliates.types";
import { actions } from "@/modules/affiliates/services/store/affiliates/affiliates.actions";
import { mutations } from "@/modules/affiliates/services/store/affiliates/affiliates.mutations";
import { getters } from "@/modules/affiliates/services/store/affiliates/affiliates.getters";
import { EAffiliateSteps } from "@/modules/affiliates/views/affiliate.types";

/**
 * Initial state for the affiliates
 */
export const state: IAffiliatesState = {
  loading: false,
  error: null,
  saving: false,
  userSubmissions: null,
  draftSubmission: null,
  affiliate: null,
  currentStep: EAffiliateSteps.STARTING_PAGE,
  selectedAssessmentCategory: 0,
  selectedQuestion: 0,
  userNavigatingFromReviewPage: false,
  firstInteraction: true,
  pendingUser: null,
  affiliateQuestionBundle: null,
  teamMembersQuestionBundle: null,
};

export const affiliatesModule: Module<IAffiliatesState, RootState> = {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
