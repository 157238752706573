<template>
  <div :style="{ height }" class="levels-description__wrapper">
    <div
      v-for="item in sortedDetails"
      :key="item.id"
      :class="getItemClasses(item)"
      class="levels-description__item"
    >
      <div class="levels-description__item-text">
        {{ getItemText(item) }}
      </div>
      <ElTooltip
        v-if="itemHasLongText(item)"
        :content="item.achievements"
        :enterable="false"
        data-testid="levels-description-item-tooltip"
        placement="top"
        popper-class="levels-description__item-tooltip el-abaca-tooltip"
      >
        <div class="levels-description__item-tooltip-trigger">
          {{ $t("common.more") }}
        </div>
      </ElTooltip>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { ICategoryDetail } from "@/services/data/category/category.interface";

const MAX_DESCRIPTION_LENGTH = 65;

export default defineComponent({
  name: "LevelsDescription",

  props: {
    /**
     * List of details for the current category.
     */
    categoryDetails: {
      type: Array as () => ICategoryDetail[],
      required: true,
    },

    /**
     * Current level.
     */
    currentLevel: {
      type: Number,
      required: true,
    },

    /**
     * Levels wrapper total height to help vertically distributing the levels.
     */
    height: {
      type: String,
      required: true,
    },
  },

  computed: {
    sortedDetails(): ICategoryDetail[] {
      return [...this.categoryDetails].sort(
        (a, b) => b.level.value - a.level.value,
      );
    },
  },

  methods: {
    getItemClasses(item: ICategoryDetail): { [key: string]: boolean } {
      return {
        "levels-description__item--visible": this.isItemVisible(item),
        "levels-description__item--checked": this.isItemChecked(item),
        "levels-description__item--selected": this.isItemSelected(item),
        "levels-description__item--truncated": this.itemHasLongText(item),
      };
    },

    isItemVisible(item: ICategoryDetail): boolean {
      return item.level.value <= this.currentLevel + 1;
    },

    isItemChecked(item: ICategoryDetail): boolean {
      return item.level.value <= this.currentLevel;
    },

    isItemSelected(item: ICategoryDetail): boolean {
      return item.level.value === this.currentLevel;
    },

    itemHasLongText(item: ICategoryDetail): boolean {
      return item.achievements.length > MAX_DESCRIPTION_LENGTH;
    },

    getItemText(item: ICategoryDetail): string {
      return this.itemHasLongText(item)
        ? `${item.achievements.substring(0, MAX_DESCRIPTION_LENGTH - 1)}…`
        : item.achievements;
    },
  },
});
</script>

<style lang="scss" scoped>
.levels-description__wrapper {
  position: relative;
  top: 7px;
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  justify-content: space-between;
  padding-right: 30px;
  padding-left: 8px;
}

.levels-description__item {
  position: relative;
  display: flex;
  justify-content: flex-start;
  width: 100%;
  font-size: 14px;
  line-height: 1.4rem;
  color: $manatee;
  letter-spacing: -0.23px;
  opacity: 0;
  transition: all 0.2s ease-in;

  &::before {
    position: absolute;
    top: 0;
    bottom: 0;
    left: -25px;
    width: 14px;
    height: 14px;
    margin: auto 0;
    content: "";
    background-image: url("#{$assetsPath}/img/icons/check--dark.svg");
    background-repeat: no-repeat;
    background-position: 1px;
    background-size: 11px 11px;
    opacity: 0;
    transition: all 0.3s ease-in-out;
  }

  &--visible {
    opacity: 1;

    &:not(.levels-description__item--checked) {
      font-size: 15px;
    }
  }

  &--checked {
    padding-left: 25px;
    color: $ebony-clay;

    &::before {
      left: 0;
      opacity: 1;
    }
  }

  &--selected {
    @include grotesk(bold);

    font-size: 15px;
    letter-spacing: 0;

    &::before {
      width: 16px;
      background-image: url("#{$assetsPath}/img/icons/check--dark--bigger.svg");
      background-size: 14px 12px;
    }
  }
}

.levels-description__item-text {
  .levels-description__item--truncated & {
    padding-right: 15px;
  }
}

.levels-description__item-tooltip-trigger {
  @include grotesk(semiBold);

  font-size: 14px;
  text-decoration: underline;

  &:hover {
    cursor: default;
    user-select: none;
  }
}
</style>

<style lang="scss">
.levels-description__item-tooltip {
  max-width: 340px;
  text-transform: none;
}
</style>
