import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-6e8343a8"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["innerHTML"]
const _hoisted_2 = { class: "levels-description__item-tooltip-trigger" }
const _hoisted_3 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ElTooltip = _resolveComponent("ElTooltip")!

  return (_openBlock(), _createElementBlock("div", {
    style: _normalizeStyle({ height: _ctx.height }),
    class: "levels-description__wrapper"
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sortedDetails, (item) => {
      return (_openBlock(), _createElementBlock("div", {
        key: item.id,
        class: _normalizeClass([_ctx.getItemClasses(item), "levels-description__item"])
      }, [
        _createElementVNode("div", {
          class: "levels-description__item-text",
          innerHTML: _ctx.getItemTextContent(item.achievements)
        }, null, 8, _hoisted_1),
        (_ctx.itemHasLongText(item))
          ? (_openBlock(), _createBlock(_component_ElTooltip, {
              key: 0,
              enterable: false,
              placement: "top",
              "popper-class": "levels-description__item-tooltip el-abaca-tooltip"
            }, {
              content: _withCtx(() => [
                _createElementVNode("div", {
                  innerHTML: item.achievements
                }, null, 8, _hoisted_3)
              ]),
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t("common.more")), 1)
              ]),
              _: 2
            }, 1024))
          : _createCommentVNode("", true)
      ], 2))
    }), 128))
  ], 4))
}