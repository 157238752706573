<template>
  <div class="panel-grid__block">
    <!-- Title outside card -->
    <div v-if="!$screen.mdUp" class="profile-headline">
      <div class="profile-headline__text-wrapper">
        <h2 class="profile-headline__title">
          {{ $t("profile.supporter.criteria.title") }}
        </h2>
      </div>
      <PxButton
        v-if="!isInactive && criteria.length && $user.isOwner()"
        class="profile-headline__update-btn"
        size="small"
        @click="onClickEditCriteria"
      >
        {{ $t("profile.supporter.criteria.button") }}
      </PxButton>
    </div>
    <PxPanel
      :class="{ 'profile-panel--disabled': isInactive }"
      class="profile-panel"
    >
      <div v-if="$screen.mdUp" class="profile-headline">
        <div class="profile-headline__text-wrapper">
          <h2 class="profile-headline__title">
            {{ $t("profile.supporter.criteria.title") }}
          </h2>
        </div>
        <PxButton
          v-if="!isInactive && criteria.length && $user.isOwner()"
          class="profile-headline__update-btn"
          size="small"
          @click="onClickEditCriteria"
        >
          {{ $t("profile.supporter.criteria.button") }}
        </PxButton>
      </div>
      <div
        v-if="isInactive || isSupporterLoading"
        class="profile-panel__visitor-wrapper"
      >
        <ContentPlaceholders :animated="false" :rounded="true">
          <ContentPlaceholdersHeading />
          <ContentPlaceholdersHeading />
          <ContentPlaceholdersHeading />
        </ContentPlaceholders>
      </div>
      <SupporterCriteriaList
        v-else-if="!isInactive && criteria.length"
        :criteria="criteria"
        class="supporter-criteria-panel-list"
      />
      <PanelEmptyState v-else icon="what-we-look-for">
        <p>
          {{
            $t(
              `profile.supporter.criteria.emptyState.description[${+$user.isOwner()}]`,
            )
          }}
        </p>
        <PxButton
          v-if="$user.isOwner()"
          size="medium"
          type="primary"
          @click="onClickGetStarted"
        >
          {{ $t("profile.supporter.criteria.emptyState.button") }}
        </PxButton>
      </PanelEmptyState>
    </PxPanel>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import { ISupporter } from "@/modules/matching/services/data/matching-score/supporter.interface";
import { IMatchingCriteria } from "@/modules/matching/services/data/matching-score/matching-criteria.interface";

import SupporterCriteriaList from "@/modules/profile/components/supporter-criteria-list/supporter-criteria-list.vue";
import PanelEmptyState from "@/modules/profile/components/panel-empty-state/panel-empty-state.vue";

import { ROUTE_MATCHING_LIST } from "@/modules/matching/services/router/routes-names";

import { AFFILIATE_SUPPORTER_PROGRAM_UPDATE_URL } from "@/modules/affiliates/constants";

export default defineComponent({
  name: "SupporterCriteriaPanel",

  components: {
    SupporterCriteriaList,
    PanelEmptyState,
  },

  computed: {
    supporter(): ISupporter | null {
      return this.$store.get("profile/supporter/data");
    },

    isSupporterLoading(): boolean {
      return this.$store.get("profile/supporter/loading");
    },

    criteria(): Array<IMatchingCriteria> | null {
      return this.supporter && this.supporter.criteria
        ? this.supporter.criteria
        : [];
    },

    isSpecialGuest(): boolean {
      return !!this.$route.meta?.specialGuest;
    },

    isInactive(): boolean {
      return !this.$user.isLogged() && !this.isSpecialGuest;
    },

    isMatchingDisabled(): boolean {
      return !this.$features.isEnabled("match");
    },
  },

  methods: {
    onClickEditCriteria() {
      window.open(AFFILIATE_SUPPORTER_PROGRAM_UPDATE_URL);
    },

    onClickGetStarted() {
      return this.isMatchingDisabled
        ? this.onClickEditCriteria()
        : this.$router.push({ name: ROUTE_MATCHING_LIST });
    },
  },
});
</script>

<style lang="scss" scoped>
.profile-panel :deep() .px-panel__body {
  padding-top: 24px;

  @include breakpoint-up(md) {
    padding: 30px 25px 5px;
  }
}

.profile-headline__update-btn {
  position: relative;
  right: 1px;
  bottom: 3px;

  @include breakpoint-down(md) {
    right: 0;
    bottom: 7px;
  }
}

.profile-panel :deep() .panel-empty-state {
  @include breakpoint-up(md) {
    padding-bottom: 35px;
  }
}

:deep() .profile-headline {
  @include breakpoint-down(sm) {
    margin: 30px 13px 14px 14px;

    &__title {
      max-width: 1670px;
    }

    &__updateBtn {
      max-width: 107px;
    }
  }
}

.supporter-criteria-panel-list {
  padding: 0 3px;
  margin-top: 13px;
}

.profile-panel__visitor-wrapper :deep() .vue-content-placeholders {
  margin: 41px 0 14px;

  .vue-content-placeholders-heading__title {
    height: 6px;
  }

  .vue-content-placeholders-heading__subtitle {
    height: 6px;
    margin: 20px 0 10px;
  }
}
</style>
