<template>
  <div :style="containerSizeStyle" class="matching-card-thumbnail">
    <div class="matching-card-thumbnail__container">
      <img
        v-if="thumbnail"
        :alt="name"
        :src="thumbnail"
        :style="avatarSizeStyle"
        class="matching-card-thumbnail__photo"
      />
      <PxAvatar
        v-else
        :name="name"
        :size="avatarSize"
        class="matching-card-thumbnail__avatar"
      />
    </div>
    <template v-if="hasSingleLevel">
      <div class="matching-card-thumbnail__level">
        <PxIcon
          :name="levelIcon"
          :size="$options.static.levelSize"
          class="matching-card-thumbnail__level-image"
        />
      </div>
    </template>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "MatchingCardThumbnail",

  props: {
    thumbnail: {
      type: String,
      default: "",
    },

    level: {
      type: Array as () => Array<number>,
      required: true,
    },

    name: {
      type: String,
      required: true,
    },

    size: {
      type: Number,
      default: 53,
    },
  },

  static: {
    levelSize: 30,
    padding: 3,
  },

  computed: {
    hasSingleLevel(): boolean {
      return this.level?.length === 1;
    },

    levelIcon(): string | null {
      if (!this.hasSingleLevel) {
        return null;
      }

      return this.level[0]
        ? `level-graph/${this.level[0]}`
        : `empty-state/level`;
    },

    containerSizeStyle(): string {
      return `width: ${this.size}px; height: ${this.size}px;`;
    },

    avatarSizeStyle(): string {
      return `width: ${this.avatarSize}px; height: ${this.avatarSize}px;`;
    },

    /*
     * This allow us to get the avatar real size, by excluding the white space
     * paddings around the avatar image. The avatar is rectangular so the size
     * here is transversal (width and height are equal).
     *
     * (padding | real size | padding) = size
     */
    avatarSize(): number {
      return this.size - this.$options.static.padding * 2;
    },
  },
});
</script>

<style lang="scss" scoped>
.matching-card-thumbnail,
.matching-card-thumbnail__container {
  position: relative;
}

.matching-card-thumbnail {
  overflow: visible;
  background: $white;
  border: 3px solid $white;
  border-radius: 4px;
}

.matching-card-thumbnail__container::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: "";
  border-radius: 3px;
  box-shadow: inset 0 0 4px 0 rgba(139, 143, 161, 0.3);
}

.matching-card-thumbnail__photo {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
  border-radius: 3px;
}

.matching-card-thumbnail__avatar {
  box-shadow: none;
}

.matching-card-thumbnail__level {
  position: absolute;
  right: -14px;
  bottom: -14px;
  z-index: z("floaters") + 1;
  background-color: $white;
  border: 2px solid $white;
  border-radius: 50%;

  &-image {
    display: block;
    transform: translate(0, -2px);
  }
}
</style>
