import { MutationTree, GetterTree, ActionTree } from "vuex";
import { GenericProvider } from "@/services/data/generic-provider";
import { RootState } from "@/services/store/root-state";

const capitalizeSetName = (name: string): string => {
  return name.charAt(0).toUpperCase() + name.slice(1);
};

/**
 * Generate a set of default mutations for the modules that
 * uses the generic store structure.
 *
 * @param moduleName Name of the array to be managed.
 */
export const generateBaseMutationsFor = <S, T>(
  moduleName: string,
): MutationTree<S> => {
  const capitalizedName = capitalizeSetName(moduleName);

  return {
    [`set${capitalizedName}`](state: any, payload: Array<T>) {
      state.data = payload;
    },

    setLoading(state: any, payload: boolean) {
      state.loading = payload;
    },

    setError(state: any, payload: Error | null) {
      state.error = payload;
    },
  };
};

/**
 * Generate a set of default getters for the modules that
 * uses the generic store structure.
 *
 * @param moduleName Name of the array to be managed.
 */
export const generateBaseGettersFor = <S, T>(
  moduleName: string,
): GetterTree<S, RootState> => {
  const capitalizedName = capitalizeSetName(moduleName);

  return {
    [`get${capitalizedName}`](state: any): Array<T> {
      return state.data;
    },

    isLoading(state: any): boolean {
      return state.loading;
    },

    hasError(state: any): boolean {
      return state.error !== null;
    },

    error(state: any): Error | null {
      return state.error;
    },
  };
};

export const generateBaseActionsFor = <S, T>(
  moduleName: string,
  provider: GenericProvider<T>,
): ActionTree<S, RootState> => {
  const capitalizedName = capitalizeSetName(moduleName);

  return {
    async fetch({ commit }, queryParams = {}): Promise<Array<T>> {
      commit("setLoading", true);
      commit("setError", null);

      let data = null;
      try {
        data = await provider.list(queryParams);
      } catch (error) {
        commit("setLoading", false);
        commit("setError", error || true);

        throw error;
      }

      commit("setLoading", false);
      commit(`set${capitalizedName}`, data);

      return data;
    },
  };
};
