import { GetterTree } from "vuex";
import {
  EMatchingResponsesGetters,
  IMatchingResponsesState,
} from "./matching-responses.types";
import { RootState } from "@/services/store/root-state";
import { generateBaseGettersFor } from "@/services/store/utils/base-generators";
import { IMatchingResponse } from "@/services/data/matching-responses/matching-response.interface";
import { getBaseName } from "@/services/store/utils/get-base-name";

export const matchingResponsesGetters: GetterTree<
  IMatchingResponsesState,
  RootState
> = {
  ...generateBaseGettersFor<IMatchingResponsesState, IMatchingResponse>(
    "matchingResponses",
  ),

  /**
   * Obtain responses data array.
   * @param state
   */
  [getBaseName(EMatchingResponsesGetters.GET_DATA)](
    state: IMatchingResponsesState,
  ): Array<IMatchingResponse> {
    return state.data;
  },

  /**
   * Obtain cached responses array.
   * @param state
   */
  [getBaseName(EMatchingResponsesGetters.GET_CACHED_RESPONSES)](
    state: IMatchingResponsesState,
  ): Array<IMatchingResponse> {
    return state.meta?.responses || [];
  },
};
