<template>
  <div class="mobile-footer-nav">
    <div class="mobile-footer-nav__top-wrapper safe-horizontal-container">
      <div class="mobile-footer-nav__top">
        <template v-if="loading">
          <ContentPlaceholders class="mobile-footer-n9av-placeholder" rounded>
            <div class="placeholder-block" />
            <div class="placeholder-block placeholder-block--important" />
          </ContentPlaceholders>
        </template>
        <template v-else>
          <slot @input="onLevelChange" />
          <div>
            <PxButton size="medium" type="green" @click="$emit('on-save')">
              {{ $t("supporters.assessment.saveButton") }}
            </PxButton>
          </div>
        </template>
      </div>
    </div>
    <div class="mobile-footer-nav__bottom">
      <span>
        {{
          $t("common.footer.copyright", {
            year: currentYear,
          })
        }}
      </span>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "MobileFooterNav",

  props: {
    loading: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      currentYear: new Date().getFullYear(),
    };
  },
});
</script>

<style lang="scss" scoped>
.mobile-footer-nav {
  position: fixed;
  bottom: 0;
  z-index: z("navigation");
  width: 100vw;

  background-color: white;
  box-shadow: 0 -3px 15px 0 rgba(0, 0, 0, 0.08);

  &::before {
    display: block;
    width: 100%;
    height: 1px;

    content: "";

    background-color: $mischka;
  }

  &__top {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 12px 14px;

    &--slider {
      display: flex;
      flex-direction: row;
      align-content: center;
    }
  }

  &__bottom {
    padding: 3px 15px;

    background-color: $alabaster;
  }

  &__bottom > span {
    @include grotesk(light);

    display: block;
    width: 100%;
    padding-bottom: var(--safe-area-inset-bottom);

    font-size: 0.6667rem;
    text-align: center;
  }

  &__next-btn {
    height: 38px;

    @include breakpoint-up(md) {
      padding-top: 12px;
      padding-bottom: 11px;
    }
  }

  &__next-btn.el-button--green {
    min-width: 152px;
    padding: 0 11px 0 10px;
  }
}

.mobile-footer-nav-placeholder {
  display: flex;
  flex-direction: row;
  align-items: center;

  .placeholder-block {
    height: 38px;

    &:first-child {
      width: 126px;
      height: 18px;
    }

    &:last-child {
      width: 152px;
      margin-left: 13px;
    }
  }
}
</style>
