import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-508fd5cb"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "list-detail-panel-empty__wrapper" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "list-detail-panel-empty__title" }
const _hoisted_4 = {
  key: 0,
  class: "list-detail-panel-empty__title"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([{ 'list-detail-panel-empty--with-slot': _ctx.hasPopulatedSlot }, "list-detail-panel-empty"])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", {
        class: _normalizeClass([[`list-detail-panel-empty__icon--${_ctx.icon}`], "list-detail-panel-empty__icon"])
      }, [
        _createElementVNode("img", {
          src: `/img/icons/${_ctx.icon}.svg`,
          alt: ""
        }, null, 8, _hoisted_2)
      ], 2),
      _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.title), 1),
      (_ctx.subtitle)
        ? (_openBlock(), _createElementBlock("p", _hoisted_4, _toDisplayString(_ctx.subtitle), 1))
        : _createCommentVNode("", true),
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ])
  ], 2))
}