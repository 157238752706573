<template>
  <div class="assessment-mobile">
    <div class="container-fluid">
      <AssessmentMobilePlaceholder v-if="isLoading" />
      <MobileLevelSelect
        v-if="!isLoading && currentCategory && selectedStep"
        :category-details="currentCategory.categoryDetails"
        :current-level="selectedStep.level"
        @change-level="onLevelChange"
      />
    </div>
    <MobileFooterNav :loading="isLoading" @next="onGoNext" @prev="onGoPrev" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import MobileFooterNav from "@/modules/self-assessment/components/mobile-footer-nav/mobile-footer-nav.vue";
import MobileLevelSelect from "@/modules/self-assessment/components/mobile-level-select/mobile-level-select.vue";
import AssessmentMobilePlaceholder from "@/modules/self-assessment/components/assessment-mobile-placeholder/assessment-mobile-placeholder.vue";

export default defineComponent({
  name: "AssessmentMobile",

  components: {
    AssessmentMobilePlaceholder,
    MobileFooterNav,
    MobileLevelSelect,
  },

  props: {
    /**
     * Handler to be executed when the user clicks
     * on the next button.
     */
    onGoNext: {
      type: Function as () => any,
      required: true,
    },

    /**
     * Handler to be executed when the user clicks
     * on the previous button
     */
    onGoPrev: {
      type: Function as () => any,
      required: true,
    },

    /**
     * Current selected category.
     */
    currentCategory: {
      type: Object as () => any,
      default: () => null,
    },

    /**
     * Current selected category level.
     */
    selectedStep: {
      type: Object as () => any,
      default: () => null,
    },

    /**
     * Handler to be executed when the category
     * level changes.
     */
    onLevelChange: {
      type: Function as () => any,
      required: true,
    },
  },

  data() {
    return {
      criteriaModalVisibility: false,
    };
  },

  computed: {
    isLoading(): boolean {
      return this.$store.state.viralLevel.loading;
    },
  },
});
</script>

<style lang="scss" scoped>
.assessment-mobile__select {
  padding: 0 15px;
}
</style>
