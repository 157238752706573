import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createElementVNode as _createElementVNode, createVNode as _createVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MultiSelectorEntry = _resolveComponent("MultiSelectorEntry")!
  const _component_PxIcon = _resolveComponent("PxIcon")!
  const _component_MultiSelectorOption = _resolveComponent("MultiSelectorOption")!
  const _component_ElSelect = _resolveComponent("ElSelect")!
  const _component_MultiSelectorWrapper = _resolveComponent("MultiSelectorWrapper")!

  return (_openBlock(), _createBlock(_component_MultiSelectorWrapper, {
    label: _ctx.label,
    "no-label": _ctx.noLabel,
    tip: _ctx.tip
  }, {
    selected: _withCtx(() => [
      _createElementVNode("div", null, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.selectedItems, (item) => {
          return (_openBlock(), _createBlock(_component_MultiSelectorEntry, {
            key: item.value,
            item: item,
            onEdit: _ctx.onEditItemClick,
            onRemove: _ctx.onRemoveItemClick
          }, null, 8, ["item", "onEdit", "onRemove"]))
        }), 128))
      ])
    ]),
    input: _withCtx(() => [
      _createElementVNode("div", null, [
        _createVNode(_component_ElSelect, {
          ref: "select",
          modelValue: _ctx.innerSelectedItem,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.innerSelectedItem) = $event)),
          "filter-method": _ctx.selectRemoteMethod,
          filterable: _ctx.hasRemoteSearch,
          loading: _ctx.isLoadingResults,
          placeholder: _ctx.$t(_ctx.placeholder),
          "popper-append-to-body": false,
          remote: _ctx.hasRemoteSearch,
          "automatic-dropdown": "",
          class: "multi-selector__select",
          "popper-class": "multi-selector__popper",
          onBlur: _ctx.blurHandler,
          onFocus: _ctx.focusHandler,
          onVisibleChange: _ctx.handleVisibleChange
        }, {
          prefix: _withCtx(() => [
            _createVNode(_component_PxIcon, {
              size: 24,
              name: _ctx.inputIcon
            }, null, 8, ["name"])
          ]),
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.searchedItems, (item, index) => {
              return (_openBlock(), _createBlock(_component_MultiSelectorOption, {
                key: item.value,
                "highlight-text": _ctx.searchQuery,
                item: item,
                "selected-items": _ctx.selectedItems,
                "starts-collapsed": index !== 0,
                "subtext-label-list": _ctx.subtextLabelList,
                onUpdate: _ctx.updateSelectedItems,
                onScrolledBottom: _ctx.onSelectScrolledBottom
              }, null, 8, ["highlight-text", "item", "selected-items", "starts-collapsed", "subtext-label-list", "onUpdate", "onScrolledBottom"]))
            }), 128))
          ]),
          _: 1
        }, 8, ["modelValue", "filter-method", "filterable", "loading", "placeholder", "remote", "onBlur", "onFocus", "onVisibleChange"])
      ])
    ]),
    _: 1
  }, 8, ["label", "no-label", "tip"]))
}