import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-f064e900"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "matching-list-interests-user" }
const _hoisted_2 = { class: "matching-list__cards-header" }
const _hoisted_3 = { class: "matching-list__cards-headline" }
const _hoisted_4 = {
  key: 0,
  class: "matching-list__loading"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MatchingFilters = _resolveComponent("MatchingFilters")!
  const _component_MatchingCard = _resolveComponent("MatchingCard")!
  const _component_MatchingCardPlaceholder = _resolveComponent("MatchingCardPlaceholder")!
  const _component_VirtualGrid = _resolveComponent("VirtualGrid")!
  const _component_MatchingEmptyResults = _resolveComponent("MatchingEmptyResults")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h3", _hoisted_3, _toDisplayString(_ctx.matchCountTitle), 1),
      _createVNode(_component_MatchingFilters, { class: "matching-list__cards-filters" })
    ]),
    (_ctx.isInterestLoading)
      ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_4, null, 512)), [
          [_directive_loading, true]
        ])
      : (_ctx.hasMatchScores)
        ? (_openBlock(), _createBlock(_component_VirtualGrid, {
            key: 1,
            columns: _ctx.virtualGridColumns,
            "grid-gap": _ctx.$options.static.virtualGridGap,
            "item-height": _ctx.$options.static.virtualRowHeight,
            items: _ctx.matchCards,
            "load-on-mount": true,
            onScroll: _ctx.fetchMoreInterests
          }, {
            items: _withCtx((items) => [
              (items.item)
                ? (_openBlock(), _createBlock(_component_MatchingCard, {
                    key: `matching-card--${items.item.index}`,
                    about: items.item.about,
                    affiliates: items.item.affiliates,
                    badges: items.item.badges,
                    "company-id": items.item.id,
                    "company-uid": items.item.uid,
                    level: items.item.level,
                    location: items.item.location,
                    locations: items.item.locations,
                    "match-percentage": items.item.percentage,
                    name: items.item.name,
                    offerings: items.item.offerings,
                    sectors: items.item.sectors,
                    "show-list-button": _ctx.showListFeature,
                    thumbnail: items.item.thumbnail,
                    onClick: ($event: any) => (_ctx.clickMatchingCardHandler(items.item.id))
                  }, null, 8, ["about", "affiliates", "badges", "company-id", "company-uid", "level", "location", "locations", "match-percentage", "name", "offerings", "sectors", "show-list-button", "thumbnail", "onClick"]))
                : _createCommentVNode("", true)
            ]),
            loading: _withCtx((loading) => [
              (_openBlock(), _createBlock(_component_MatchingCardPlaceholder, {
                key: `virtual-list-placeholder-${loading.item}`
              }))
            ]),
            _: 1
          }, 8, ["columns", "grid-gap", "item-height", "items", "onScroll"]))
        : (_openBlock(), _createBlock(_component_MatchingEmptyResults, {
            key: 2,
            notice: false,
            icon: "matching.matchingInterest.matchingList.modalEmptyState.icon",
            subtitle: "matching.matchingInterest.matchingList.modalEmptyState.subtitle",
            title: "matching.matchingInterest.matchingList.modalEmptyState.title"
          }))
  ]))
}