<template>
  <div class="company-lists-item company-lists-item-placeholder__wrapper">
    <div class="company-lists-item-placeholder__container">
      <ContentPlaceholders :rounded="true">
        <ContentPlaceholdersHeading :img="true" />
      </ContentPlaceholders>
      <ContentPlaceholders
        :rounded="true"
        class="company-lists-item-placeholder__centered"
      >
        <ContentPlaceholdersText :lines="1" />
      </ContentPlaceholders>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "CompanyListsItemPlaceholder",
});
</script>

<style lang="scss" scoped>
.company-lists-item-placeholder__wrapper {
  position: relative;
  display: flex;
  flex-flow: row wrap;
  align-items: center;

  min-height: 103px;
  padding: 18px 31px 17px 33px;
  pointer-events: none;
  user-select: none;

  background-color: $white;
  border: solid 1px $turkish-angora;
  border-radius: 4px;
  box-shadow: 0 4px 8px -4px rgba(139, 143, 161, 0.2);

  &::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    content: "";
    box-shadow: 0 4px 12px -4px rgba(139, 143, 161, 0.6);
    opacity: 0;
    transition: $--fade-transition;
  }

  &:hover::after {
    opacity: 1;
  }
}

.company-lists-item-placeholder__container {
  display: grid;
  grid-template-columns: 580px 320px;
}

.company-lists-item :deep() {
  .vue-content-placeholders-heading__img {
    width: 42px;
    height: 42px;
    margin-right: 10px;
    background: $athens-gray;
  }

  .vue-content-placeholders-heading__title {
    width: 230px;
    height: 18px;
    margin-bottom: 8px;
    margin-left: 14px;
    background: rgba($manatee, 0.4);
    border-radius: 3px;
  }

  .vue-content-placeholders-heading__subtitle {
    width: 183px;
    height: 18px;
    margin-left: 14px;
    background: rgba($pale-lilac, 0.5);
    border-radius: 3px;
  }
}

.company-lists-item-placeholder__centered {
  display: flex;
  align-items: flex-start;
}

.company-lists-item-placeholder__centered :deep() {
  .vue-content-placeholders-text {
    width: 100%;
    height: 18px;
  }
}
</style>
