<template>
  <ElCollapseItem
    :style="{ backgroundColor, border }"
    class="px-information-card-collapsible"
  >
    <template #title>
      <div class="px-information-card-collapsible__wrapper">
        <div class="px-information-card-collapsible__left">
          <h3
            v-if="title"
            :style="{ color: titleStyles === 'blue' ? '#7bb7ff' : '#272740' }"
            :title="title"
            data-testid="px-information-card-collapsible-title"
          >
            {{ title }}
          </h3>
          <p
            v-if="description && $screen.xlUp"
            class="px-information-card-collapsible__description"
            data-testid="px-information-card-collapsible-description"
          >
            {{ description }}
          </p>
        </div>
        <div class="px-information-card-collapsible__right">
          <PxStatusBadge
            v-if="label && $screen.xlUp"
            :background-color="label.backgroundColor"
            :icon="label.icon"
            :icon-size="label.iconSize"
            :label="label.text"
            :label-color="label.textColor"
            :label-size="13"
            :uppercase="false"
            class="px-information-card-collapsible__label"
            data-testid="px-information-card-collapsible-label"
            icon-position="left"
          />
          <PxButton
            v-if="cta"
            :label="cta.label"
            :size="cta.size"
            :type="cta.type"
            class="px-information-card-collapsible__cta"
            data-testid="px-information-card-collapsible-cta"
            @click="$emit('navigate')"
          />
        </div>
      </div>
    </template>
    <div>
      <slot name="content" />
    </div>
  </ElCollapseItem>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import PxStatusBadge from "@/components/px-status-badge/px-status-badge.vue";
import PxButton from "@/components/px-button/px-button.vue";
import {
  IComponentLabel,
  IComponentCta,
} from "@/modules/affiliates/components/common.interface";

export default defineComponent({
  name: "PxInformationCardCollapsible",

  components: {
    PxStatusBadge,
    PxButton,
  },

  props: {
    title: {
      type: String,
      default: "",
    },

    description: {
      type: String,
      default: "",
    },

    titleStyles: {
      type: String,
      default: null,
      validator: (value: string) => {
        return ["blue"].includes(value);
      },
    },

    border: {
      type: String,
      default: "1px solid rgba(123, 183, 255, 0.8)",
    },

    backgroundColor: {
      type: String,
      default: "rgba(123, 183, 255, 0.05)",
    },

    label: {
      type: Object as () => IComponentLabel | null,
      default: null,
    },

    cta: {
      type: Object as () => IComponentCta | null,
      default: null,
    },
  },
});
</script>

<style lang="scss" scoped>
.px-information-card-collapsible {
  border-radius: 4px;

  &__wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 8px;
  }

  &__left {
    max-width: 500px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 22px;

    cursor: pointer;

    h3 {
      margin: 0;

      @include grotesk(semiBold);

      font-size: 17px;
      line-height: 1.88;

      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 300px;

      @include breakpoint-down(lg) {
        max-width: 100px;
      }
    }

    p::before {
      position: absolute;
      content: "";
      top: 50%;
      left: -15px;
      bottom: 2px;
      width: 6px;
      height: 6px;
      background-color: $mischka;
      border-radius: 50%;

      transform: translateY(-50%);
    }

    p {
      position: relative;

      margin: 0;
      font-size: 16px;
      line-height: 1.25;
      letter-spacing: -0.2px;
    }
  }

  &__right {
    display: flex;
    gap: 14px;

    .px-button {
      min-width: 72px;
    }

    .px-button span {
      font-size: 13px;
    }
  }

  &__label {
    max-height: 24px;
    gap: 2px;
    margin-top: 4px;
    padding: 0 2px 0 4px;
  }

  &__description {
    color: $malibu;
    font-style: italic;
  }

  :deep(.el-collapse-item__header) {
    padding: 13px 15px 15px 55px;
    border: none;
    transition: none;

    &.is-active {
      border-bottom: inherit;
    }

    &.is-active::before {
      transform: rotate(180deg);
    }

    &::before {
      top: 0;
      left: 23px;
      bottom: 2px;
      width: 16px;
      height: 16px;
      margin: auto 0;
      background-color: transparent;
      background-image: url("#{$assetsPath}/img/icons/arrow.svg");
      background-repeat: no-repeat;
      background-position: center;
      transition: $--all-transition;
    }
  }

  :deep(.el-collapse-item__header),
  :deep(.el-collapse-item__wrap) {
    background: transparent;
    border: 0;
  }

  :deep(.el-collapse-item__content) {
    padding: 0;
    margin: 0 13px;
  }
}
</style>
