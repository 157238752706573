import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-5f6bba9c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "criteria-question-panel__container" }
const _hoisted_2 = { class: "criteria-question-panel__header" }
const _hoisted_3 = { class: "criteria-question-panel__description" }
const _hoisted_4 = { class: "criteria-question-panel__content" }
const _hoisted_5 = {
  key: 0,
  class: "criteria-question-panel__content-wrapper criteria-question-panel__content-wrapper--two-column"
}
const _hoisted_6 = { class: "criteria-question-panel__range" }
const _hoisted_7 = { class: "criteria-question-panel__range-label" }
const _hoisted_8 = { class: "criteria-question-panel__range" }
const _hoisted_9 = { class: "criteria-question-panel__range-label" }
const _hoisted_10 = {
  key: 1,
  class: "criteria-question-panel__content-wrapper"
}
const _hoisted_11 = {
  key: 0,
  class: "criteria-question-panel__content-wrapper"
}
const _hoisted_12 = {
  key: 1,
  class: "criteria-question-panel__content-wrapper criteria-question-panel__content-wrapper--two-column"
}
const _hoisted_13 = { class: "criteria-question-panel__range" }
const _hoisted_14 = { class: "criteria-question-panel__range-label" }
const _hoisted_15 = { class: "criteria-question-panel__range" }
const _hoisted_16 = { class: "criteria-question-panel__range-label" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ElRadio = _resolveComponent("ElRadio")!
  const _component_PxRadioGroup = _resolveComponent("PxRadioGroup")!
  const _component_ElCheckbox = _resolveComponent("ElCheckbox")!
  const _component_ElCheckboxGroup = _resolveComponent("ElCheckboxGroup")!
  const _component_PxMoney = _resolveComponent("PxMoney")!
  const _component_PxNumeric = _resolveComponent("PxNumeric")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([{
      'criteria-question-panel__wrapper--with-input':
        _ctx.isMoneyType || _ctx.isQuestionType(_ctx.numeric),
    }, "criteria-question-panel__wrapper"])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.questionTitle), 1)
      ]),
      _createElementVNode("div", _hoisted_4, [
        (_ctx.isQuestionType(_ctx.singleSelect))
          ? (_openBlock(), _createBlock(_component_PxRadioGroup, {
              key: 0,
              modelValue: _ctx.innerValue.answers[0],
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.innerValue.answers[0]) = $event)),
              class: "criteria-question-panel__content-wrapper"
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.question.answers, (option, index) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: `criteria-question-panel__option-${index}`,
                    class: "criteria-question-panel__answer"
                  }, [
                    _createVNode(_component_ElRadio, {
                      value: option.id,
                      class: "el-radio--checkmark"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(option.value), 1)
                      ]),
                      _: 2
                    }, 1032, ["value"])
                  ]))
                }), 128))
              ]),
              _: 1
            }, 8, ["modelValue"]))
          : (_ctx.isQuestionType(_ctx.multiSelect))
            ? (_openBlock(), _createBlock(_component_ElCheckboxGroup, {
                key: 1,
                modelValue: _ctx.innerValue.answers,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.innerValue.answers) = $event)),
                class: "criteria-question-panel__content-wrapper"
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.question.answers, (option, index) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: `criteria-question-panel__option-${index}`,
                      class: "criteria-question-panel__answer"
                    }, [
                      _createVNode(_component_ElCheckbox, {
                        value: option.id
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(option.value), 1)
                        ]),
                        _: 2
                      }, 1032, ["value"])
                    ]))
                  }), 128))
                ]),
                _: 1
              }, 8, ["modelValue"]))
            : (_ctx.isMoneyType)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                  (_ctx.needsCurrencyRange)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                        _createElementVNode("div", _hoisted_6, [
                          _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.$t("affiliateProgram.questions.rangeFrom")), 1),
                          _createVNode(_component_PxMoney, {
                            modelValue: _ctx.innerValue.value!.min,
                            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.innerValue.value!.min) = $event))
                          }, null, 8, ["modelValue"])
                        ]),
                        _createElementVNode("div", _hoisted_8, [
                          _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.$t("affiliateProgram.questions.rangeTo")), 1),
                          _createVNode(_component_PxMoney, {
                            modelValue: _ctx.innerValue.value!.max,
                            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.innerValue.value!.max) = $event))
                          }, null, 8, ["modelValue"])
                        ])
                      ]))
                    : (_openBlock(), _createElementBlock("div", _hoisted_10, [
                        _createVNode(_component_PxMoney, {
                          modelValue: _ctx.innerValue.value!.value,
                          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.innerValue.value!.value) = $event))
                        }, null, 8, ["modelValue"])
                      ]))
                ], 64))
              : (_ctx.isQuestionType(_ctx.numeric))
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 3 }, [
                    (_ctx.$user.isEntrepreneur())
                      ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                          _createVNode(_component_PxNumeric, {
                            modelValue: _ctx.innerValue.value!.value,
                            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.innerValue.value!.value) = $event)),
                            placeholder: _ctx.numericPlaceholder
                          }, null, 8, ["modelValue", "placeholder"])
                        ]))
                      : _createCommentVNode("", true),
                    (_ctx.$user.isSupporter())
                      ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                          _createElementVNode("div", _hoisted_13, [
                            _createElementVNode("span", _hoisted_14, _toDisplayString(_ctx.$t("affiliateProgram.questions.rangeFrom")), 1),
                            _createVNode(_component_PxNumeric, {
                              modelValue: _ctx.innerValue.value!.min,
                              "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.innerValue.value!.min) = $event)),
                              placeholder: _ctx.numericRangePlaceholder[0]
                            }, null, 8, ["modelValue", "placeholder"])
                          ]),
                          _createElementVNode("div", _hoisted_15, [
                            _createElementVNode("span", _hoisted_16, _toDisplayString(_ctx.$t("affiliateProgram.questions.rangeTo")), 1),
                            _createVNode(_component_PxNumeric, {
                              modelValue: _ctx.innerValue.value!.max,
                              "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.innerValue.value!.max) = $event)),
                              placeholder: _ctx.numericRangePlaceholder[1]
                            }, null, 8, ["modelValue", "placeholder"])
                          ])
                        ]))
                      : _createCommentVNode("", true)
                  ], 64))
                : _createCommentVNode("", true)
      ])
    ])
  ], 2))
}