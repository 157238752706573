import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-40c1f585"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "results-report" }
const _hoisted_2 = { class: "results-report-entry__header" }
const _hoisted_3 = { class: "results-report-entry__name" }
const _hoisted_4 = { class: "results-report-entry__description" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PxBadge = _resolveComponent("PxBadge")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.categories, (category) => {
      return (_openBlock(), _createElementBlock("div", {
        key: category.id,
        class: _normalizeClass([{
        'results-report-entry--not-achieved': _ctx.levelForCategory(category) === 0,
      }, "results-report-entry"])
      }, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_PxBadge, {
            color: _ctx.getCategoryColor(category),
            value: _ctx.levelForCategory(category),
            class: "results-report-entry__level"
          }, null, 8, ["color", "value"]),
          _createElementVNode("span", _hoisted_3, _toDisplayString(category.name), 1)
        ]),
        _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.selectedLevelDescription(category)), 1)
      ], 2))
    }), 128))
  ]))
}