/**
 * This interface represents a support request.
 */
export interface ISupport {
  author?: string;
  subject: string;
  message: string;
}

export const SUPPORT_SUBJECT_CRITERIA_SUGGESTION = "Criteria Suggestion";
export const SUPPORT_SUBJECT_ACCESS_DENIED = "Access Denied";
