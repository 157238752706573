import { GetterTree } from "vuex";
import { RootState } from "@/services/store/root-state";
import { getBaseName } from "@/services/store/utils/get-base-name";
import {
  EAdditionalCriteriaGetter,
  IAdditionalCriteriaState,
} from "@/modules/supporters/services/store/additional-criteria/additional-criteria.interface";
import { IAdditionalCriteria } from "@/modules/supporters/services/data/additional-criteria/additional-criteria.interface";
import { IMultiSelectorItem } from "@/components/multi-selector/multi-selector.interface";
import { IMatchingQuestion } from "@/services/data/matching-questionary/matching-question.interface";
import { IMatchingAnswer } from "@/services/data/matching-questionary/matching-answer.interface";

export const getters: GetterTree<IAdditionalCriteriaState, RootState> = {
  /**
   * Get multi selector value from stored values.
   *
   * @param state module state
   */
  [getBaseName(EAdditionalCriteriaGetter.MULTI_SELECTOR_VALUES)](
    state,
  ): Array<IMultiSelectorItem> {
    // Big O3, but required for now
    // Map results to multi selector
    return state.values.map((criteria: IAdditionalCriteria) => {
      // Create mapped criteria to be used as parent reference in question
      const mappedCriteriaCategory = {
        label: criteria.name,
        value: criteria.name,
        selected: false,
      } as IMultiSelectorItem;

      const questions = criteria.questions.map(
        // Map questions as children, with entire object as meta
        (question: IMatchingQuestion) => {
          const mappedQuestion = {
            label: question.short_name,
            value: question.id,
            selected: false,
            parent: mappedCriteriaCategory,
            meta: {
              ...question,
            },
          };

          // Map answers as grandchildren
          const answers = question.answers.map((answer: IMatchingAnswer) => {
            return {
              label: answer.value,
              value: answer.id,
              selected: false,
            };
          });

          return {
            ...mappedQuestion,
            children: answers,
          };
        },
      );

      return {
        ...mappedCriteriaCategory,
        children: questions,
      };
    }) as Array<IMultiSelectorItem>;
  },
};
