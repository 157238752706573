<template>
  <div class="level-range-bar">
    <div class="level-range-bar__label">
      {{ rangeBarLabel }}
    </div>
    <div class="level-range-bar__icon">
      <img
        :alt="`Level Range ${investingRange}`"
        :src="`/img/icons/level-range-graph/level-white-${investingRange}.svg`"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "LevelBar",

  props: {
    /**
     * Text to be used for the label
     */
    label: {
      type: String,
      default: () => "",
    },

    /**
     * Contains the user range investing level value and description
     */
    range: {
      type: Array as () => any[],
      required: true,
    },
  },

  computed: {
    rangeBarLabel(): string {
      return this.label === ""
        ? this.$t("supporters.signup.levelBarRange")
        : this.label;
    },

    investingRange(): string {
      const uniqueValueRange = [...new Set(this.range)];
      return uniqueValueRange.join("-");
    },
  },
});
</script>

<style lang="scss" scoped>
.level-range-bar {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-between;
  padding: 7px 15px 6px;

  background-color: rgba($atomic-tangerine, 0.15);
  border-bottom: solid 1px rgba($atomic-tangerine, 0.3);

  @include breakpoint-up(md) {
    justify-content: center;
    padding: 5px 15px;
  }
}

.level-range-bar__label {
  @include grotesk(semiBold);

  position: relative;
  top: 3px;
  grid-area: label;

  font-size: 0.9333rem;
  line-height: 20px;
  color: $atomic-tangerine;
  letter-spacing: -0.05px;

  @include breakpoint-up(md) {
    top: auto;
    font-size: 1rem;
    line-height: 18px;
    text-align: right;
  }
}

.level-range-bar__icon {
  grid-area: level;
  text-align: right;

  @include breakpoint-up(md) {
    margin: 0 5px 0 11px;
    text-align: center;
  }
}

.level-range-bar__icon img {
  max-width: 100%;
  height: 48px;

  @include breakpoint-up(md) {
    height: 37px;
  }
}
</style>
