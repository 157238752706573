import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-d15e7cea"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "px-sidebar-divisor" }
const _hoisted_2 = { class: "px-sidebar-divisor__content" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = {
  key: 1,
  class: "px-sidebar-divisor__subtitle"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PxIcon = _resolveComponent("PxIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.icon)
        ? (_openBlock(), _createBlock(_component_PxIcon, {
            key: 0,
            name: _ctx.icon,
            size: 16
          }, null, 8, ["name"]))
        : _createCommentVNode("", true),
      _createElementVNode("p", {
        class: _normalizeClass([{ 'px-sidebar-divisor__content--only-text': !_ctx.icon }, "px-sidebar-divisor__title"])
      }, [
        _createTextVNode(_toDisplayString(_ctx.title) + " ", 1),
        (_ctx.optionalText)
          ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.optionalText), 1))
          : _createCommentVNode("", true)
      ], 2),
      (_ctx.subtitle)
        ? (_openBlock(), _createElementBlock("p", _hoisted_4, _toDisplayString(_ctx.subtitle), 1))
        : _createCommentVNode("", true)
    ])
  ]))
}