import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, mergeProps as _mergeProps, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-02fd7d1d"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "level-update-modal-note" }
const _hoisted_2 = { class: "level-update-modal-note--first" }
const _hoisted_3 = { class: "level-update-modal-note--second" }
const _hoisted_4 = { class: "level-update-modal-note--third" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PxIcon = _resolveComponent("PxIcon")!
  const _component_i18n_t = _resolveComponent("i18n-t")!
  const _component_MilestoneStageStatusItem = _resolveComponent("MilestoneStageStatusItem")!
  const _component_PxButton = _resolveComponent("PxButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("p", null, _toDisplayString(_ctx.$t("common.components.levelUpdateModal.note.0")), 1),
    _createElementVNode("ul", null, [
      _createElementVNode("li", _hoisted_2, [
        _createVNode(_component_i18n_t, {
          keypath: `common.components.levelUpdateModal.note.1`,
          tag: "span"
        }, {
          firstIcon: _withCtx(() => [
            _createVNode(_component_PxIcon, {
              size: 17,
              class: "evidence-provided",
              name: "evidence-provided"
            })
          ]),
          text1: _withCtx(() => [
            _createVNode(_component_i18n_t, {
              keypath: `common.components.levelUpdateModal.note.2`,
              tag: "p"
            }, {
              firstIcon: _withCtx(() => [
                _createVNode(_component_PxIcon, {
                  size: 16,
                  class: "self-assessed",
                  name: "self-assessed"
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _createElementVNode("li", _hoisted_3, [
        _createVNode(_component_i18n_t, {
          keypath: `common.components.levelUpdateModal.note.3`,
          tag: "p"
        }, {
          text1: _withCtx(() => [
            _createVNode(_component_i18n_t, {
              keypath: `common.components.levelUpdateModal.note.5`,
              tag: "span"
            }, {
              firstIcon: _withCtx(() => [
                _createVNode(_component_MilestoneStageStatusItem, _mergeProps(_ctx.inProgressIconProps, { "is-clickable": false }), null, 16)
              ]),
              _: 1
            })
          ]),
          text2: _withCtx(() => [
            _createVNode(_component_i18n_t, {
              keypath: `common.components.levelUpdateModal.note.6`,
              tag: "span"
            })
          ]),
          firstIcon: _withCtx(() => [
            _createVNode(_component_PxIcon, {
              size: 17,
              class: "evidence-provided",
              name: "evidence-provided"
            })
          ]),
          breakText: _withCtx(() => [
            _createVNode(_component_i18n_t, {
              keypath: `common.components.levelUpdateModal.note.4`,
              class: "level-update-modal-note--break-text",
              tag: "span"
            })
          ]),
          _: 1
        })
      ]),
      _createElementVNode("li", _hoisted_4, [
        _createVNode(_component_i18n_t, {
          keypath: `common.components.levelUpdateModal.note.7`,
          tag: "p"
        }, {
          text1: _withCtx(() => [
            _createVNode(_component_i18n_t, {
              keypath: `common.components.levelUpdateModal.note.8`,
              tag: "span"
            })
          ]),
          breakText: _withCtx(() => [
            _createVNode(_component_i18n_t, {
              keypath: `common.components.levelUpdateModal.note.9`,
              class: "level-update-modal-note--break-text",
              tag: "p"
            }, {
              firstIcon: _withCtx(() => [
                _createVNode(_component_MilestoneStageStatusItem, _mergeProps(_ctx.inProgressIconProps, { "is-clickable": false }), null, 16)
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ])
    ]),
    _createVNode(_component_PxButton, {
      disabled: _ctx.loading,
      class: "el-button--link-dark",
      size: "small",
      type: "link",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emitter.emit('change-level-example-modal-visibility')))
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$t("common.components.levelUpdateModal.note.link")), 1)
      ]),
      _: 1
    }, 8, ["disabled"])
  ]))
}