import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-7cdeee5c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "supporter-criteria-list" }
const _hoisted_2 = { class: "supporter-criteria-list__headline" }
const _hoisted_3 = {
  key: 0,
  class: "supporter-criteria-list__items"
}
const _hoisted_4 = { class: "supporter-criteria-list__question" }
const _hoisted_5 = { class: "supporter-criteria-list__answer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ElCollapseItem = _resolveComponent("ElCollapseItem")!
  const _component_ElCollapse = _resolveComponent("ElCollapse")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ElCollapse, {
      modelValue: _ctx.activeDrawer,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.activeDrawer) = $event)),
      accordion: ""
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.supporterCriteriaByQuestionCategories, (item, index) => {
          return (_openBlock(), _createBlock(_component_ElCollapseItem, {
            key: index,
            name: index
          }, {
            title: _withCtx(() => [
              _createElementVNode("div", _hoisted_2, [
                _createElementVNode("h3", null, [
                  _createTextVNode(_toDisplayString(item.name) + " ", 1),
                  _createElementVNode("span", null, _toDisplayString(_ctx.$t("profile.supporter.criteria.requirements")), 1)
                ])
              ])
            ]),
            default: _withCtx(() => [
              (item.criteria)
                ? (_openBlock(), _createElementBlock("ul", _hoisted_3, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.criteria, (itemCriteria, key) => {
                      return (_openBlock(), _createElementBlock("li", { key: key }, [
                        _createElementVNode("div", _hoisted_4, _toDisplayString(itemCriteria.question.resource_question), 1),
                        _createElementVNode("div", _hoisted_5, [
                          _createElementVNode("span", null, _toDisplayString(itemCriteria.formattedValue), 1)
                        ])
                      ]))
                    }), 128))
                  ]))
                : _createCommentVNode("", true)
            ]),
            _: 2
          }, 1032, ["name"]))
        }), 128))
      ]),
      _: 1
    }, 8, ["modelValue"])
  ]))
}