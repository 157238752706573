<template>
  <div class="page-full-height">
    <div class="page-body">
      <Transition mode="out-in" name="slide-fade">
        <PxCallout
          v-show="showCallout"
          ref="capital-explorer-callout"
          :ctas="ctas"
          :description="copy.description"
          :label="label"
          :title="copy.title"
          class="capital-explorer-callout"
          @click="handleCalloutClick"
        >
          <template #note>
            <p v-html="copy.note" />
          </template>
        </PxCallout>
      </Transition>
      <div
        v-if="$user.isSupporter()"
        id="supporters-dashboard"
        class="page-container"
      />
      <div v-else id="entrepreneurs-dashboard" class="page-container" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import PxCallout from "@/components/px-callout/px-callout.vue";
import { activeModules } from "@/services/utils/utils";
import { CAPITAL_EXPLORER_CALLOUT_VISIBLE } from "@/modules/capital-explorer/constants";
import {
  EPxButtonType,
  EPxButtonSize,
} from "@/components/px-button/px-button.types";
import {
  EComponentCtaActionType,
  IComponentLabel,
} from "@/modules/affiliates/components/common.interface";
import { EMetaActions } from "@/services/store/meta/meta-types";
import { CAPITAL_EXPLORER } from "@/modules/capital-explorer/services/router/routes-names";

export default defineComponent({
  name: "Dashboard",

  components: {
    PxCallout,
  },

  data: function () {
    return {
      showCallout: false,
      copy: this.$tm("capitalExplorer.supportersCallout") as {
        title: string;
        description: string;
        note: string;
        ctas: string[];
      },
      label: {
        icon: "stars--white",
        iconSize: 19,
        textColor: "#fff",
        backgroundColor: "#3b82f6",
        text: this.$t("common.new"),
      } as IComponentLabel,
      ctas: [
        {
          type: EPxButtonType.LINK_WHITE,
          size: EPxButtonSize.SMALL,
          action: EComponentCtaActionType.CANCEL,
          label: this.$t(
            `capitalExplorer.supportersCallout.ctas[${0}]`,
          ) as string,
        },
        {
          type: EPxButtonType.BLUE,
          size: EPxButtonSize.SMALL,
          action: EComponentCtaActionType.CONTINUE,
          label: this.$t(
            `capitalExplorer.supportersCallout.ctas[${1}]`,
          ) as string,
        },
      ],
    };
  },

  computed: {
    capitalExplorerCalloutVisible(): boolean {
      return !!this.$store.get("meta/get", CAPITAL_EXPLORER_CALLOUT_VISIBLE);
    },

    showCapitalExplorerCallout(): boolean {
      return (
        this.capitalExplorerCalloutVisible &&
        this.$user.isSupporter() &&
        this.$features.isEnabled("capitalExplorer") &&
        activeModules().includes("capital-explorer")
      );
    },
  },

  mounted() {
    if (this.showCapitalExplorerCallout) {
      setTimeout(() => {
        this.showCallout = true;
      }, 2000);
    }
  },

  methods: {
    handleCalloutClick(actionType: EComponentCtaActionType) {
      this.showCallout = false;

      this.$store.dispatch(EMetaActions.SET, {
        key: CAPITAL_EXPLORER_CALLOUT_VISIBLE,
        value: false,
      });

      if (actionType === EComponentCtaActionType.CONTINUE) {
        return this.$router.push({ name: CAPITAL_EXPLORER });
      }
    },
  },
});
</script>

<style lang="scss" scoped>
.page-container {
  @include make-container();
  @include make-container-max-widths();

  margin: 0 auto;
  padding: 42px 0 71px;

  @include breakpoint-up(md) {
    padding: 61px 0 71px;
  }
}

.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: all 0.7s ease-in-out;
}

.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateX(50%);
  opacity: 0;
}

.capital-explorer-callout {
  position: fixed;
  right: 24px;
  bottom: 24px;
  max-width: 462px;

  z-index: z("overlay");

  :deep() .px-callout__note {
    margin-top: 8px;
  }

  :deep() .px-callout__ctas-wrapper {
    margin-top: 12px;
  }
}
</style>
