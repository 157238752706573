import {
  GenericProvider,
  EProviderFeatures,
} from "@/services/data/generic-provider";
import { IUserByTokenResponse } from "./user-by-token.interface";
import { baseAPIUrl } from "@/services/utils/utils";

class UserByTokenProvider extends GenericProvider<IUserByTokenResponse> {
  constructor() {
    super(`${baseAPIUrl}/user/by-token`, [EProviderFeatures.GET]);
  }
}

export const userByTokenProvider = new UserByTokenProvider();
