import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, renderList as _renderList, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-58cbcf06"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "question-bundle-entrepreneur-starting-page" }
const _hoisted_2 = { class: "question-bundle-entrepreneur-starting-page-panel--left" }
const _hoisted_3 = { class: "question-bundle-entrepreneur-starting-page-panel__wrapper" }
const _hoisted_4 = { class: "question-bundle-entrepreneur-starting-page-panel__title" }
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = { class: "question-bundle-entrepreneur-starting-page-panel__content" }
const _hoisted_7 = { class: "question-bundle-entrepreneur-starting-page-panel__content" }
const _hoisted_8 = { class: "question-bundle-entrepreneur-starting-page__feature" }
const _hoisted_9 = { class: "question-bundle-entrepreneur-starting-page-panel--right affiliate-description-panel" }
const _hoisted_10 = { class: "affiliate-description-panel__title" }
const _hoisted_11 = ["innerHTML"]
const _hoisted_12 = ["innerHTML"]
const _hoisted_13 = ["alt", "src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PxButton = _resolveComponent("PxButton")!
  const _component_FeatureCard = _resolveComponent("FeatureCard")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass([{
        'question-bundle-entrepreneur-starting-page-panel--simplified':
          !_ctx.withTeamSection,
      }, "question-bundle-entrepreneur-starting-page-panel"])
    }, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("h1", _hoisted_4, _toDisplayString(_ctx.affiliateName), 1),
          (_ctx.affiliate.summary)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: "question-bundle-entrepreneur-starting-page-panel__content",
                innerHTML: _ctx.affiliate.summary
              }, null, 8, _hoisted_5))
            : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.contentAffiliate), 1),
                _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.contentBonus), 1)
              ], 64)),
          _createVNode(_component_PxButton, {
            icon: _ctx.startButtonIcon,
            type: _ctx.startButtonType,
            class: "question-bundle-entrepreneur-starting-page-panel__button",
            size: "large",
            onClick: _ctx.onSelfAssessmentButtonClick
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.startButtonText), 1)
            ]),
            _: 1
          }, 8, ["icon", "type", "onClick"])
        ]),
        _createElementVNode("div", _hoisted_8, [
          (_openBlock(), _createBlock(_component_FeatureCard, {
            key: _ctx.affiliateFlowKeyPoint.value,
            description: _ctx.affiliateFlowKeyPoint.description,
            icon: _ctx.affiliateFlowKeyPoint.value,
            title: _ctx.affiliateFlowKeyPoint.title
          }, null, 8, ["description", "icon", "title"]))
        ])
      ]),
      _createElementVNode("div", _hoisted_9, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.descriptionToDisplay, (item, index) => {
          return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
            _createElementVNode("div", _hoisted_10, [
              _createElementVNode("span", null, _toDisplayString(index + 1), 1),
              _createTextVNode(" " + _toDisplayString(item.title), 1)
            ]),
            _createElementVNode("div", {
              class: "affiliate-description-panel__description",
              innerHTML: item.description
            }, null, 8, _hoisted_11),
            (item.note)
              ? (_openBlock(), _createElementBlock("div", {
                  key: `note-${index}`,
                  class: "affiliate-description-panel__note",
                  innerHTML: item.note
                }, null, 8, _hoisted_12))
              : _createCommentVNode("", true),
            (item.thumbnail)
              ? (_openBlock(), _createElementBlock("picture", {
                  key: `thumbnail-${index}`
                }, [
                  _createElementVNode("img", {
                    alt: item.thumbnail.alt,
                    src: item.thumbnail.src
                  }, null, 8, _hoisted_13)
                ]))
              : _createCommentVNode("", true)
          ], 64))
        }), 128))
      ])
    ], 2)
  ]))
}